import pcdApi from '../../service/Api/pcd/pcdApi';
import { PlaneControlDeliverable } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PCDApi } from '../../service/Api/pcd/types';
import { pcdSlice } from './pcdSlice';
import { settingsSlice } from '../globalSettings/settingsSlice';
import { getSandBoxCounters } from '../request/requestLogic(HOLD)';
import { errorsSlice } from '../errors/errorsSlice';
import { getFeed } from '../comments/commentsThunk';
import FileHelper from '../../helpers/FileHelper';
import { FileModel } from '../../models';

const { filesUploadClean } = settingsSlice.actions;
const { setShowErrorData } = errorsSlice.actions;

export const getPCDCard = createAsyncThunk<PlaneControlDeliverable.PCDCardWithFilters, PlaneControlDeliverable.GetCardPayload>(
  'pcdReducer/getPCDCard',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    const { closePcdCard } = pcdSlice.actions;
    try {
      const { id, callback, isPreview, urlProject } = payload;
      const currentState = getState();
      const { sandBoxFilters } = currentState.requestReducer;
      const project_id = +sessionStorage.getItem('active_project_id');

      if (!id) {
        throw null;
      }

      const response = await pcdApi.getPCDCard({ id });

      if (response.data?.status === false || response.data?.status === 400 || response.data?.status === 404) {
        throw response;
      }

      if (urlProject[1] === 'command-center') {
        const queryData = {
          project_id,
          ...(sandBoxFilters || {}),
        };

        dispatch(getSandBoxCounters(queryData));
      }

      if (callback) {
        callback(response.data.card);
      }

      return { ...response.data, isPreview };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      dispatch(closePcdCard());
      return rejectWithValue(error);
    }
  },
);

export const updatePCDCard = createAsyncThunk<PCDApi.PCDCard, PlaneControlDeliverable.UpdatePCDCardPayload>(
  'pcdReducer/updatePCDCard',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const { deliverable_id, deliverable_parent_id, data, callback } = payload;
      const upload_files = data['upload_files'];

      const bodyParams = { ...data, project_id: projectId };

      let response;
      const files = FileHelper.separateByMaxSize(upload_files);
      // Separate to many request, if request size > FILE_SIZE_LIMIT
      if (files && files.length > 1) {
        const responses = await Promise.all(
          files.map(
            async (f, fIndex) =>
              await pcdApi.updatePCDCard({
                deliverable_id,
                data: { ...bodyParams, upload_files: f, quietly: fIndex !== files.length - 1 ? 1 : 0 },
                upload_files,
              }),
          ),
        );
        response = responses[responses.length - 1];
      } else {
        response = await pcdApi.updatePCDCard({ deliverable_id, data: bodyParams, upload_files });
      }

      // TODO change to thunk
      dispatch(getFeed({ owner_id: deliverable_parent_id || deliverable_id, type: 'deliverable' }));
      dispatch(filesUploadClean({ type: 'request' }));
      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createPCDCard = createAsyncThunk<PCDApi.PCDCard, PlaneControlDeliverable.CreatePCDCardPayload>(
  'pcdReducer/createPCDCard',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const { data, callback } = payload;
      const upload_files = data['upload_files'];

      const bodyParams = { ...data, project_id: projectId };

      const response = await pcdApi.createPCDCard({ data: bodyParams, upload_files });

      if (callback) {
        callback(response);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getLinkingSuggestions = createAsyncThunk<PCDApi.LinkSuggestionItem[], PlaneControlDeliverable.ListForLinkingPayload>(
  'pcdReducer/getLinkingSuggestions',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, building_id, entity, callback } = payload;
    try {
      const response = await pcdApi.getListForLinking({ project_id, building_id, entity });

      if (callback) {
        callback(Object.values(response.data));
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const linkDeliverableToNf = createAsyncThunk<
  PlaneControlDeliverable.PCDCardWithFilters,
  PlaneControlDeliverable.LinkUnlinkRequestItem
>('pcdReducer/linkDeliverableToNf', async (payload, { dispatch, rejectWithValue }) => {
  const { pcd_id, parent_id, request_id, project_id, callback } = payload;
  try {
    const response = await pcdApi.linkDeliverableToNf({ pcd_id, request_id, project_id });

    // TODO change to thunk
    dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

    if (callback) {
      callback();
    }

    return { card: response.data, check_due_date: response?.response?.check_due_date };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const changeNfValidityStatus = createAsyncThunk<
  PlaneControlDeliverable.PCDCardWithFilters,
  PlaneControlDeliverable.ChangeNfValidityStatus
>('pcdReducer/changeNfValidityStatus', async (payload, { dispatch, rejectWithValue }) => {
  const { owner_id, parent_id, data, callback } = payload;
  try {
    const response = await pcdApi.changeNfValidityStatus({ owner_id, data });

    // // TODO change to thunk
    dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

    if (callback) {
      callback();
    }

    return { card: response.data };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const unLinkDeliverableToNf = createAsyncThunk<
  PlaneControlDeliverable.PCDCardWithFilters,
  PlaneControlDeliverable.LinkUnlinkRequestItem
>('pcdReducer/unLinkDeliverableToNf', async (payload, { dispatch, rejectWithValue }) => {
  const { pcd_id, parent_id, request_id, project_id, unlink_previous, callback } = payload;
  try {
    const response = await pcdApi.unLinkDeliverableToNf({ pcd_id, request_id, project_id, unlink_previous });

    // TODO change to thunk
    dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

    if (callback) {
      callback();
    }

    return { card: response.data };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const linkDeliverableToDeliverable = createAsyncThunk<
  PlaneControlDeliverable.PCDCardWithFilters,
  PlaneControlDeliverable.LinkUnlinkDeliverableItem
>('pcdReducer/linkDeliverableToDeliverable', async (payload, { dispatch, rejectWithValue }) => {
  const { pcd_id, parent_id, deliverable_id, project_id, callback } = payload;
  try {
    const response = await pcdApi.linkDeliverableToDeliverable({ pcd_id, deliverable_id, project_id });

    // TODO change to thunk
    dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

    if (callback) {
      callback();
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const unLinkDeliverableToDeliverable = createAsyncThunk<
  PlaneControlDeliverable.PCDCardWithFilters,
  PlaneControlDeliverable.LinkUnlinkDeliverableItem
>('pcdReducer/unLinkDeliverableToDeliverable', async (payload, { dispatch, rejectWithValue }) => {
  const { pcd_id, parent_id, deliverable_id, project_id, callback } = payload;
  try {
    const response = await pcdApi.unLinkDeliverableToDeliverable({ pcd_id, deliverable_id, project_id });

    // TODO change to thunk
    dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

    if (callback) {
      callback();
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getListPCD = createAsyncThunk<PCDApi.GetPCDListResponse, PlaneControlDeliverable.GetPCDList>(
  'pcdReducer/getListPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, callback } = payload;
    try {
      const response = await pcdApi.getPCDList({ project_id });

      if (callback) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const editDeliverableReviewsPCD = createAsyncThunk<PCDApi.PCDCard, PlaneControlDeliverable.EditDeliverableReviewsPCD>(
  'pcdReducer/editDeliverableReviewsPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const deliverable = data.owner_id;
      const parentId = data.parent_id;
      const newData = { ...data };
      delete newData.parent_id;
      delete data.parent_id;

      const response = await pcdApi.editDeliverableReviewsPCD(newData);

      if (callback) {
        callback(response.data);
      }

      // TODO change to thunk
      dispatch(getFeed({ owner_id: parentId || deliverable, type: 'deliverable' }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateReviewPCD = createAsyncThunk<PlaneControlDeliverable.PCDCardWithFilters, PlaneControlDeliverable.UpdateReviewPCD>(
  'pcdReducer/updateReviewPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const review = data.id;
      const { parent_id, deliverable_id } = data;
      const newData = { ...data };
      delete newData.id;
      delete newData.parent_id;
      const upload_files = data['upload_files'];

      let response;
      const files = FileHelper.separateByMaxSize(upload_files as unknown as FileModel[]);
      // Separate to many request, if request size > FILE_SIZE_LIMIT
      if (files && files.length > 1) {
        const responses = await Promise.all(
          files.map(
            async (f, fIndex) =>
              await pcdApi.updateReviewPCD({ ...newData, upload_files: f, quietly: fIndex !== files.length - 1 ? 1 : 0 }, review),
          ),
        );
        response = responses[responses.length - 1];
      } else {
        response = await pcdApi.updateReviewPCD(newData, review);
      }

      if (callback) {
        callback(response.data);
      }

      // TODO change to thunk
      dispatch(getFeed({ owner_id: parent_id || deliverable_id, type: 'deliverable' }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteDeliverablePCD = createAsyncThunk<any, PlaneControlDeliverable.DeleteDeliverablePayload>(
  'pcdReducer/deleteDeliverablePCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, deliverable_id, callback } = payload;
    try {
      const response = await pcdApi.deleteDeliverablePCD({ project_id, deliverable_id });

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const changePCDCardStatus = createAsyncThunk<PCDApi.PCDCard, PlaneControlDeliverable.ChangePCDCardStatusPayload>(
  'pcdReducer/changePCDCardStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, status, parent_id, callback } = payload;
    try {
      const response = await pcdApi.changePCDCardStatus({ id, status });

      if (callback) {
        callback();
      }

      dispatch(getFeed({ owner_id: parent_id || id, type: 'deliverable' }));

      return response.data.card;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updatePcdReasons = createAsyncThunk<PCDApi.PCDCard, PlaneControlDeliverable.RevisionReasonPayload>(
  'pcdReducer/updatePcdReasons',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, reason_comment, reason, caused_nfs, caused_deliverables, parent_id, callback } = payload;
    try {
      const response = await pcdApi.updatePcdReasons({ id, reason_comment, reason, caused_nfs, caused_deliverables });

      if (callback) {
        callback();
      }

      dispatch(getFeed({ owner_id: parent_id, type: 'deliverable' }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const pcdHistoryRecordSend = createAsyncThunk<boolean, PCDApi.PCDHistoryRecordInterface>(
  'pcdReducer/pcdHistoryRecordSend',
  async (payload, { dispatch, rejectWithValue }) => {
    const { type, deliverableId, fileId } = payload;
    try {
      await pcdApi.pcdHistoryRecordSend({ type, deliverableId, fileId });
      return true;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
