import React, { Component } from 'react';

class Updates_20230713_4 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}></div>
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-57x57.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-72x72.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-76x76.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-114x114.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-120x120.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-144x144.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-152x152.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/apple-touch-icon-180x180.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/favicon-16x16.png')}
          alt=""
        />
        <img
          style={{ visibility: 'hidden' }}
          src={require('../../../../../../assets/images/new-favicons/icons-png/favicon-32x32.png')}
          alt=""
        />
        <img style={{ visibility: 'hidden' }} src={require('../../../../../../assets/images/new-favicons/icons-png/icon-192.png')} alt="" />
        <img style={{ visibility: 'hidden' }} src={require('../../../../../../assets/images/new-favicons/icons-png/icon-512.png')} alt="" />
        <img style={{ visibility: 'hidden' }} src={require('../../../../../../assets/images/new-favicons/icons-svg/icon.svg')} alt="" />
      </>
    );
  }
}

export default Updates_20230713_4;
