import React from 'react';
import { SimpleTitleModel } from '../../../../../models';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import { useGetThemeClass } from '../../../../../helpers/designTokens';

import './BuildingSpacesItemStyles.scss';

interface IProps {
  BuildingSpace: SimpleTitleModel;
  handleBuildingSpaceItemClick?: (level: SimpleTitleModel) => void;
}

const BuildingSpacesItem: React.FC<IProps> = ({ BuildingSpace, handleBuildingSpaceItemClick }) => {
  const themeClass = useGetThemeClass('b-buildingSpacesItem');

  return (
    <div className={`${themeClass}_container`} onClick={() => handleBuildingSpaceItemClick(BuildingSpace)}>
      <div className={`${themeClass}_item`}>
        <div className={`${themeClass}_item_checkBox`}>
          <CheckBox checked={BuildingSpace.isSelected} onClick={null} />
        </div>
        <div className={`${themeClass}_item_title`}>{BuildingSpace.title}</div>
      </div>
    </div>
  );
};

export default BuildingSpacesItem;
