import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { useGetThemeClass } from '../../../helpers/designTokens';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import cn from 'classnames';
import CardSubmittal from '../CardSubmittal/CardSubmittal';
import { CustomButton } from '../../controls/ButtonComponents';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { submittalSlice } from '../../../store/submittal/submittalSlice';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import { pcdSlice } from '../../../store/pcd/pcdSlice';
import CardPcd from '../CardPcd/CardPcd';
import CardRequest from '../CardRequest/CardRequest';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { getRequestByNf, singleRequestLoading } from '../../../store/request/requestLogic(HOLD)';
import { commentSlice } from '../../../store/comments/commentsReducer';
import { incorporationSlice } from '../../../store/incorporation/incorporationSlice';
import { deliverableCardTabsNames } from '../CardPcd/constans/constants';
import { SubmittalCardTabs } from '../CardSubmittal/constants/submittalTabs';
import { relatedItemsSlice } from '../../../store/relatedItems/relatedItemsSlice';
import { IncorrectNeedForm } from '../../pages/ErrorsPopUp/ErrorsPopUpConstants';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';
import useRouter from '../../../hooks/useRouter/useRouter';
import { getSubmittalCard } from '../../../store/submittal/submittalThunk';

import '../../pages/ComponentCheckPage/components/ButtonComponent(PLN-3419)/ButtonComponentStyles(PLN-3419).scss';
import './CardGlobalDialogStyles.scss';

const { openSubmittalCard, closeSubmittalCard, closeNewSubmittalCard } = submittalSlice.actions;
const { clearBreadcrumbs, setNewBreadcrumbs } = breadcrumbsSlice.actions;
const { setPcdOpenId, closePcdCard } = pcdSlice.actions;
const { closeNfCard, closeNewNfCard, setNewNfCardSubmittalId } = requestSlice.actions;
const { clearFeed } = commentSlice.actions;
const { clearIncorporation } = incorporationSlice.actions;
const { clearRelatedItemsTab } = relatedItemsSlice.actions;

interface IProps {
  isSeparateTab: boolean;
  isUrlSubmittalCardTab: boolean;
  isUrlPCDCardTab: boolean;
  isUrlNfCardTab: boolean;
  isPreviewCard?: boolean;
  selectRequestIdForPreview?: number;
  selectRequestNfForPreview?: number;
}

type CardTypes = 'nf' | 'pcd' | 'submittal';

function CardGlobalDialog({
  isSeparateTab,
  isUrlSubmittalCardTab,
  isUrlPCDCardTab,
  isUrlNfCardTab,
  isPreviewCard,
  selectRequestIdForPreview,
  selectRequestNfForPreview,
}: IProps) {
  const dispatch = useAppDispatch();
  const {
    getCardParamsFromURL,
    setUrlPCD,
    setUrlNF,
    setUrlSubmittal,
    removeParams,
    redirectToCommandCenter,
    getPCDParamsFromURL,
    getNfParamsFromURL,
    getPreviewNfParamsFromURL,
    removeUrlPreviewNF,
  } = useRoutingHelper();
  const { location, searchParams } = useRouter();
  const { nfParam, pcdIdParam, buildingIdParam, submittalIdParam, tabParam, nfPreviewData } = getCardParamsFromURL();

  const themeClass = useGetThemeClass('b-globalCardDialog');
  const { is1440Resolution } = GetResolution();

  // submittal
  const errorTitle = useAppSelector(state => state.errorsReducer.title);

  // submittal
  const { isNewSubmittal } = useAppSelector(state => state.submittalSlice);
  const breadcrumbs = useAppSelector(state => state.breadcrumbsReducer.firstCardBreadcrumbs);

  // deliverable
  const resizablePCCardPart = useAppSelector(state => state.commonReducer.resizablePCCardPart);

  // nf
  const isNewNfCard = useAppSelector(state => state.requestReducer.isNewNfCard);
  const idLoadedRequestByNf = useAppSelector(state => state.requestReducer.idLoadedRequestByNf);
  const resizableCardPart = useAppSelector(state => state.commonReducer.resizableCardPart);

  const nfNumberLoadedRequestByNf = useAppSelector(state => state.requestReducer.nfNumberLoadedRequestByNf);
  const newNfCardSubmittalInfo = useAppSelector(state => state.requestReducer.newNfCardSubmittalInfo);

  const [cardClickOutSide, setCardClickOutSide] = useState<boolean>(false);
  const [escapeKeyDown, setEscapeKeyDown] = useState<boolean>(false);
  const [currentCardType, setCurrentCardType] = useState<CardTypes>(null);

  useEffect(() => {
    const newSubmittalParam = searchParams.get('submittal');
    const newBuildingParam = searchParams.get('building');
    const newDeliverableParam = searchParams.get('deliverable');
    const newNfParam = searchParams.get('nf');
    const previewCardParam = searchParams.get('previewCard');

    const isPCDCard = newBuildingParam && newDeliverableParam;

    if (newSubmittalParam && !previewCardParam) {
      dispatch(openSubmittalCard());
      dispatch(getSubmittalCard({ id: Number(newSubmittalParam) }));
      setCurrentCardType('submittal');
    } else if (!isNewSubmittal) {
      dispatch(closeSubmittalCard());
    }

    if (newBuildingParam || newDeliverableParam) {
      const params = getPCDParamsFromURL();
      localStorage.setItem('pcd_current_building_id', String(params.buildingId));
      dispatch(setPcdOpenId({ id: params.pcdId, buildingId: params.buildingId }));
      setCurrentCardType('pcd');
    } else {
      dispatch(closePcdCard());
    }

    if (newNfParam && !previewCardParam) {
      const params = getNfParamsFromURL();
      if (Number(newNfParam) !== nfNumberLoadedRequestByNf && errorTitle !== IncorrectNeedForm) {
        dispatch(getRequestByNf({ projectId: params.project_id, nf: params.nf }));
      }
      setCurrentCardType('nf');

      if (isNewNfCard) {
        dispatch(closeNewNfCard());
      }
    } else if (!isNewNfCard) {
      dispatch(closeNfCard());
    }

    if (previewCardParam) {
      const params = getPreviewNfParamsFromURL();

      if (params.nf !== nfNumberLoadedRequestByNf && errorTitle !== IncorrectNeedForm) {
        dispatch(getRequestByNf({ projectId: params.project_id, nf: params.nf }));
      }
    }

    if (!newSubmittalParam && !isPCDCard && !newNfParam) {
      dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
      setTimeout(() => {
        setCurrentCardType(null);
      }, 250);
    }

    if (isNewNfCard) {
      dispatch(clearFeed());
      dispatch(clearIncorporation());
      dispatch(clearRelatedItemsTab());
    }
  }, [location]);

  useEffect(() => {
    if (!nfPreviewData) {
      if (pcdIdParam) {
        setUrlPCD(pcdIdParam, buildingIdParam, deliverableCardTabsNames.includes(tabParam) ? tabParam : 'deliverable');
      }

      if (submittalIdParam) {
        setUrlSubmittal(submittalIdParam, SubmittalCardTabs.includes(tabParam) ? tabParam : 'general');
        dispatch(closeNewSubmittalCard());
      }

      if (localStorage.getItem(getResizableCardValue()) === 'small') {
        document.addEventListener('keydown', upHandler);
      }
    }

    return () => {
      if (localStorage.getItem(getResizableCardValue()) === 'small') {
        document.removeEventListener('keydown', upHandler);
      }
    };
  }, [submittalIdParam, pcdIdParam, nfParam, tabParam, nfPreviewData]);

  const getResizableCardValue = () => {
    if (currentCardType === 'submittal' || isNewSubmittal) {
      return 'resizableSubmittalCard';
    }
    if (currentCardType === 'pcd') {
      return 'resizablePCCard';
    }

    if (currentCardType === 'nf' || isNewNfCard) {
      return 'resizableCard';
    }
  };

  const upHandler = ({ key }) => {
    if (key === 'Escape') {
      closeCardEscapeKeyDown();
    }
  };

  const closeCard = (isRequestClickOutSide?: boolean) => {
    if (isPreviewCard) {
      removeUrlPreviewNF();
      dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'previewCardBreadcrumbs' }));
      return;
    }

    if (!isSeparateTab) {
      if (!isRequestClickOutSide && breadcrumbs?.length > 1) {
        const newBreadcrumbs = [...breadcrumbs];
        const deletedCardId = pcdIdParam || submittalIdParam;
        const currentCardIndex = newBreadcrumbs.findIndex(item => (nfParam ? nfParam === item?.nf : deletedCardId === item?.id));
        let breadcrumbToOpen;

        if (currentCardIndex === 0) {
          breadcrumbToOpen = newBreadcrumbs[1];
        } else {
          breadcrumbToOpen = newBreadcrumbs[0];
        }

        if (breadcrumbToOpen.breadcrumbType === 'nf') {
          setUrlNF(String(breadcrumbToOpen.nf), breadcrumbToOpen?.activeTab || 'request');
        }

        if (breadcrumbToOpen.breadcrumbType === 'pcd') {
          setUrlPCD(breadcrumbToOpen.id, breadcrumbToOpen.buildingId, breadcrumbToOpen?.activeTab || 'deliverable');
        }

        if (breadcrumbToOpen.breadcrumbType === 'submittal') {
          setUrlSubmittal(breadcrumbToOpen.id, breadcrumbToOpen?.activeTab || 'general');
        }

        newBreadcrumbs.splice(currentCardIndex, 1);

        dispatch(setNewBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs', array: newBreadcrumbs?.length === 1 ? [] : newBreadcrumbs }));
        closeCardByType();
      } else {
        closeCardByType();
        dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
        if (!newNfCardSubmittalInfo.submittalId) {
          removeParams();
        } else {
          dispatch(setNewNfCardSubmittalId({ submittalId: null, is_generate_nf_rfi: 0 }));
        }
      }
    }
  };

  const closeCardByType = () => {
    if (currentCardType === 'submittal' || isNewSubmittal) {
      dispatch(closeSubmittalCard());
      dispatch(clearFeed());
      dispatch(clearRelatedItemsTab());
    }
    if (currentCardType === 'pcd') {
      dispatch(closePcdCard());
      dispatch(clearFeed());
      dispatch(clearRelatedItemsTab());
    }
    if (currentCardType === 'nf' || isNewNfCard) {
      dispatch(closeNfCard());
      dispatch(clearFeed());
      dispatch(clearIncorporation());
      dispatch(clearRelatedItemsTab());
    }
    if (isNewNfCard) {
      dispatch(closeNewNfCard());
    }
  };

  const closeCardClickOutSide = () => {
    setCardClickOutSide(true);
  };

  const closeCardClickOutSideR = () => {
    if (isPreviewCard) {
      removeUrlPreviewNF();
      dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'previewCardBreadcrumbs' }));
      setCardClickOutSide(false);
      return;
    }

    if (!isSeparateTab) {
      if (!newNfCardSubmittalInfo.submittalId) {
        removeParams();
      } else {
        dispatch(setNewNfCardSubmittalId({ submittalId: null, is_generate_nf_rfi: 0 }));
      }
      setCardClickOutSide(false);
    }
  };

  const closeCardEscapeKeyDown = () => {
    setEscapeKeyDown(true);
  };
  const closeCardEscapeKeyDownR = () => {
    if (!isSeparateTab) {
      removeParams();
      setEscapeKeyDown(false);
    }
  };

  const handleNavigateToSandbox = () => {
    redirectToCommandCenter();
  };

  const calculateXRate = () => {
    let rw = 640;

    if (window.innerWidth <= 640) {
      rw = window.innerWidth;
    }

    return window.innerWidth - rw;
  };

  const cancelLastNfRequest = () => {
    dispatch(closeNfCard());
  };

  const handleOpenRequest = (requestId: number, nf: number) => {
    if (nf && !isPreviewCard) {
      setUrlNF(String(nf));
      return;
    }

    if (requestId !== idLoadedRequestByNf && !isPreviewCard) {
      dispatch(singleRequestLoading({ requestId: requestId, unSetNewRequestProject: true }));
      return;
    }
  };

  const renderCurrentCard = () => {
    if ((nfParam || isNewNfCard) && !isPreviewCard) {
      return (
        <CardRequest
          selectRequestId={isNewNfCard ? null : idLoadedRequestByNf}
          closeRequest={closeCard}
          requestClickOutSide={cardClickOutSide}
          closeRequestClickOutSide={() => closeCardClickOutSideR()}
          escapeKeyDown={escapeKeyDown}
          closeRequestEscapeKeyDown={() => closeCardEscapeKeyDownR()}
          isCreateRequest={isNewNfCard}
          handleSelectedRequest={handleOpenRequest}
          cancelLastNfRequest={cancelLastNfRequest}
          isDragRequestCard={is_rw || is1440Resolution}
          isSeparateTab={isSeparateTab}
          isUrlNfCardTab={isUrlNfCardTab}
        />
      );
    }
    if ((submittalIdParam || isNewSubmittal) && !isPreviewCard) {
      return (
        <CardSubmittal
          isDragRequestCard={is_rw}
          closeRequest={closeCard}
          requestClickOutSide={cardClickOutSide}
          closeRequestClickOutSide={closeCardClickOutSideR}
          escapeKeyDown={escapeKeyDown}
          closeRequestEscapeKeyDown={closeCardEscapeKeyDownR}
          isUrlSubmittalCardTab={isUrlSubmittalCardTab}
          isSeparateTab={isSeparateTab}
          selectRequestId={submittalIdParam}
          isCreateSubmittal={isNewSubmittal}
        />
      );
    }
    if (pcdIdParam && !isPreviewCard) {
      return (
        <CardPcd
          isDragRequestCard={is_rw}
          selectRequestIdProps={pcdIdParam}
          closePcd={closeCard}
          requestClickOutSide={cardClickOutSide}
          closeRequestClickOutSide={closeCardClickOutSideR}
          escapeKeyDown={escapeKeyDown}
          closeRequestEscapeKeyDown={closeCardEscapeKeyDownR}
          isUrlPCDCardTab={isUrlPCDCardTab}
          isSeparateTab={isSeparateTab}
        />
      );
    }
    if (isPreviewCard) {
      return (
        <CardRequest
          closeRequest={closeCard}
          requestClickOutSide={cardClickOutSide}
          closeRequestClickOutSide={() => closeCardClickOutSideR()}
          escapeKeyDown={escapeKeyDown}
          closeRequestEscapeKeyDown={() => closeCardEscapeKeyDownR()}
          isCreateRequest={false}
          handleSelectedRequest={handleOpenRequest}
          cancelLastNfRequest={cancelLastNfRequest}
          isDragRequestCard={is_rw || is1440Resolution}
          isSeparateTab={false}
          isUrlNfCardTab={false}
          previewCard
          selectRequestId={selectRequestIdForPreview}
          selectRequestNf={selectRequestNfForPreview}
        />
      );
    }
  };

  const is_rw = localStorage.getItem(getResizableCardValue()) === 'small' && !isSeparateTab && !isPreviewCard;
  const isSidePeek = (is_rw || is1440Resolution) && !isSeparateTab && !isPreviewCard;

  return (
    <>
      <DialogPopUp
        open={!!submittalIdParam || !!pcdIdParam || !!nfParam || !!isNewNfCard || !!isNewSubmittal}
        aria-labelledby={`Global Card ${isPreviewCard ? 'previewCard' : ''}`}
        disableBackdropClick
        disableEscapeKeyDown={false}
        disableEnforceFocus
        hideBackdrop={isSeparateTab}
        onBackdropClick={closeCardClickOutSide}
        onEscapeKeyDown={closeCardEscapeKeyDown}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            closeCardEscapeKeyDown();
          }
        }}
        classes={{
          paper: cn(`${themeClass}_paper`, {
            ['-isSeparateTab']: isSeparateTab,
          }),
        }}
        customRootClassname={cn(`${themeClass}_dialog`, {
          ['-sidePeek']: isSidePeek,
        })}
        isOverlay={!isSidePeek}
      >
        {isSeparateTab ? (
          <div className={`${themeClass}_separateColumn`}>
            <CustomButton
              type={'secondary'}
              size={'md'}
              title={'Go to Command center'}
              iconClass={`${themeClass}_separateIcon`}
              icon={<TokenIcon iconName="sandbox" size={24} />}
              clickHandler={handleNavigateToSandbox}
            />
            {renderCurrentCard()}
          </div>
        ) : (
          renderCurrentCard()
        )}
      </DialogPopUp>
    </>
  );
}

export default CardGlobalDialog;
