import React, { FC, ReactElement, ReactNode } from 'react';
import Divider from '../../Divider/Divider';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { useGetThemeClass } from '../../../../helpers/designTokens';

import '../ContainedListStyles.module.scss';

interface IProps {
  virtualItem: VirtualItem;
  virtualizer: Virtualizer<HTMLElement, Element>;
  renderItem: (item: any, alias: string) => ReactElement | ReactNode;
  accordion: any;
  offset: number;
  isShortView: boolean;
}

const ContainerListItemSnippet: FC<IProps> = ({ virtualItem, renderItem, accordion, offset, virtualizer }) => {
  const themeClass = useGetThemeClass('b-containedList');
  return (
    <div
      data-index={virtualItem.index}
      ref={virtualizer.measureElement}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        transform: `translateY(${virtualItem.start - offset >= 0 ? virtualItem.start - offset : virtualItem.start}px)`,
      }}
      className={`${themeClass}_table_group_container_onClickSnippetClass`}
    >
      {renderItem(accordion.data[virtualItem.index], accordion.alias)}
      <Divider direction={'horizontal'} type={'srf-5'} />
    </div>
  );
};

export default React.memo(ContainerListItemSnippet, (prevProps, nextProps) => {
  if (
    prevProps.virtualItem.start !== nextProps.virtualItem.start ||
    prevProps.virtualItem.key !== nextProps.virtualItem.key ||
    prevProps.offset !== nextProps.offset ||
    prevProps.accordion !== nextProps.accordion ||
    prevProps.isShortView !== nextProps.isShortView
  ) {
    return false;
  } else {
    return true;
  }
});
