import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { CustomButton, SystemButton } from '../../../../controls/ButtonComponents';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { SwipeableDrawer } from '@mui/material';

import './CommentEditorMobileStyles.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  type: 'reply' | 'edit' | 'add';
  handleOpenFileUpload: () => void;
  handleSend: () => void;
  loading: boolean;
  disabled: boolean;
}

const titleInterpreter: Record<IProps['type'], string> = {
  reply: 'Reply to a comment',
  edit: 'Edit a comment',
  add: 'Add a comment',
};

const CommentEditorMobile: FC<IProps> = ({ isOpen, handleClose, type, handleOpenFileUpload, disabled, loading, handleSend, children }) => {
  const { isMobile } = GetResolution();
  const themeClass = useGetThemeClass('b-commentEditorMobile');

  return (
    <SwipeableDrawer
      onOpen={() => {}}
      disableSwipeToOpen
      open={isOpen}
      onClose={handleClose}
      className={`${themeClass}_backdrop`}
      classes={{
        modal: `${themeClass}_modal`,
        paper: `${themeClass}_paper`,
      }}
      anchor={'bottom'}
    >
      <div className={themeClass}>
        <div className={`${themeClass}_swipable`}>
          <div className={`${themeClass}_puller`} />
        </div>
        <div className={`${themeClass}_header`}>
          <div className={`${themeClass}_header_left`}>
            <div className={`${themeClass}_header_left_floatIcon`}>
              <TokenIcon iconName={'comment-2'} size={24} />
            </div>
            <span className={`${themeClass}_header_left_title`}>{titleInterpreter[type]}</span>
          </div>
          <div className={`${themeClass}_header_right`}>
            <SystemButton type={'attachment'} size={'md'} variant={'transparent'} clickHandler={handleOpenFileUpload} isMobile={isMobile} />
            <CustomButton
              type={'text-plain'}
              size={'md'}
              title={'Done'}
              clickHandler={handleSend}
              isMobile={isMobile}
              loading={loading}
              disabled={disabled}
            />
          </div>
        </div>
        <div className={`${themeClass}_content`}>{children}</div>
      </div>
    </SwipeableDrawer>
  );
};

export default CommentEditorMobile;
