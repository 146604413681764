import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import { CodeSelector, Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import BuildingItem from '../BuildingItem/BuildingItem';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import { LocationCollectionModel, RequestBuildingModel } from '../../../../../models';
import LevelExpandItem from './LevelExpandItem/LevelExpandItem';
import TypicalLayoutsExpandItem from './TypicalLayoutsExpandItem/TypicalLayoutsExpandItem';
import LevelItem from './LevelItem/LevelItem';
import TypicalLayoutsItem from './TypicalLayoutsItem/TypicalLayoutsItem';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { Badge } from '../../../TeamTab/Badge';
import { createTypical, getListDisciplines } from '../../../../../store/project/projectLogic';
import { useAppDispatch } from '../../../../../store/configure/configureStore';
import { useSelector } from 'react-redux';

import './LevelsSelectStyles.scss';

const createNewRangeOption = {
  title: 'Create a new range',
  value: 'Create a new range',
};

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface LevelRenderSelectProps<T, G, IsMultiValue extends boolean> {
  isHeader?: boolean;
  isFooter?: boolean;
  selectHandler: (selected: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  menuItemSize: 'sm' | 'md';
  basisPlaceholder: string;
  customBasisFieldClass?: string;
  isFullWidth?: boolean;
  buildings: RequestBuildingModel[];
  levels: LocationCollectionModel[];
  typicalLayouts: any[];
  selectedBuildings: RequestBuildingModel[];
  customMenuClass?: string;
  setLevelsSelected: (value: LocationCollectionModel[]) => void;
  levelsSelected: LocationCollectionModel[];
  setTypicalLayoutsSelected: (value: any) => void;
  typicalLayoutsSelected: any[];
  setBuildingsSelected: (value: RequestBuildingModel[]) => void;
  disabled: boolean;
  isOpenMenu?: boolean;
  onLevelMenuClose?: () => void;
  isMobile?: boolean;
  isHighResolution?: boolean;
  renderCustomBasis?: (
    isActive: boolean,
    disabled: boolean,
    isLoading: boolean,
    selected: SelectedValue<T, IsMultiValue>,
  ) => ReactElement | ReactNode;
}

function LevelsSelect<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  isHeader,
  isFooter,
  selectHandler,
  selected,
  isLoading,
  customBasisFieldClass,
  basisPlaceholder,
  menuItemSize,
  isFullWidth,
  buildings,
  levels,
  typicalLayouts,
  selectedBuildings,
  customMenuClass,
  setLevelsSelected,
  levelsSelected,
  setTypicalLayoutsSelected,
  typicalLayoutsSelected,
  setBuildingsSelected,
  disabled,
  isOpenMenu,
  onLevelMenuClose,
  isMobile,
  renderCustomBasis,
  isHighResolution,
}: LevelRenderSelectProps<T, G, IsMultiValue>) {
  const themeClass = useGetThemeClass('b-levelsSelect');

  const activeProjectId: string = useSelector(state => state.userReducer.active_project_id);

  const [openedBuildingIds, setOpenedBuildingIds] = useState<number[]>([]);
  const [openedLevelsIds, setOpenedLevelsIds] = useState<string[]>([]);
  const [openedTypicalLayoutsIds, setOpenedTypicalLayoutsIds] = useState<string[]>([]);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [isCreateTypicalRange, setIsCreateTypicalRange] = useState<boolean>(false);
  const [typicalRangePopUpData, setTypicalRangePopUpData] = useState<any>(null);
  const [typicalLayoutsItems, setTypicalLayoutsItems] = useState<any>([createNewRangeOption]);
  const [selectedTypicalLayout, setSelectedTypicalLayout] = useState<any>(createNewRangeOption);
  const [selectedUf, setSelectedUf] = useState<any>(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState<any>(null);
  const [disciplines, setDisciplines] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState<string>('');
  const [isCustomOpenMenu, setIsCustomOpenMenu] = useState<boolean>(false);
  const [openBuildingAndTypicalLayouts, setOpenBuildingAndTypicalLayouts] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    generateLevelsMenuItems();

    if (openBuildingAndTypicalLayouts) {
      const buildingArray = [...selectedBuildings];

      buildingArray.forEach((building, index) => {
        const isCurrentItem: boolean = building.id === openBuildingAndTypicalLayouts;

        if (isCurrentItem) {
          const typicalLayoutExpandId = `TypicalLayoutsExpandItem-${index}`;
          setOpenedTypicalLayoutsIds([typicalLayoutExpandId]);
        }
      });

      setOpenBuildingAndTypicalLayouts(0);
    }
  }, [
    buildings,
    selectedBuildings,
    levelsSelected,
    typicalLayoutsSelected,
    openedBuildingIds,
    openedLevelsIds,
    openedTypicalLayoutsIds,
    typicalLayouts,
    localSearchValue.length,
  ]);

  useEffect(() => {
    if (!selectedBuildings?.length) {
      setLevelsSelected([]);
      setTypicalLayoutsSelected([]);
      setOpenedTypicalLayoutsIds([]);
      setOpenedLevelsIds([]);
      setOpenedBuildingIds([]);
    }
    if (selectedBuildings?.length) {
      const selectedBuildingsIds = selectedBuildings.map(item => item.id);

      const typicalLayouts = typicalLayoutsSelected?.length
        ? typicalLayoutsSelected.filter(item => selectedBuildingsIds.includes(item.building_id))
        : [];
      setTypicalLayoutsSelected(typicalLayouts);

      const levels = levelsSelected?.length ? levelsSelected.filter(item => selectedBuildingsIds.includes(item.building_id)) : [];
      setLevelsSelected(levels);
    }
  }, [selectedBuildings]);

  useEffect(() => {
    if (isCreateTypicalRange) {
      dispatch(getListDisciplines()).then(res => {
        setDisciplines([...Object.values(res.payload)]);
      });
    }
  }, [isCreateTypicalRange]);

  useEffect(() => {
    if (isCreateTypicalRange && typicalRangePopUpData.buildingId) {
      const typicalLayoutsItemsList = typicalLayouts.filter(item => item.building_id === typicalRangePopUpData.buildingId);
      setTypicalLayoutsItems([...typicalLayoutsItemsList]);
    }
  }, [isCreateTypicalRange, typicalRangePopUpData]);

  useEffect(() => {
    if (isCreateTypicalRange) {
      setSelectedDiscipline(selectedTypicalLayout?.discipline);
      setSelectedUf(selectedTypicalLayout?.ufc);
    }
  }, [selectedTypicalLayout, isCreateTypicalRange]);

  const generateLevelsMenuItems = () => {
    const result = [];

    const buildingArray = selectedBuildings?.length ? [...selectedBuildings] : [...buildings];

    buildingArray.forEach((building, index) => {
      const isItemOpened: boolean = openedBuildingIds.includes(building.id);

      if (localSearchValue) {
        if (building.locations.some(location => location.title.toLowerCase().includes(localSearchValue))) {
          result.push({
            ...building,
            itemType: 'building',
            isItemOpened,
          });
        } else {
          return;
        }
      } else {
        result.push({
          ...building,
          itemType: 'building',
          isItemOpened,
        });
      }

      if (isItemOpened) {
        const levelExpandItemId = `LevelExpandItem-${index}`;
        const typicalLayoutExpandId = `TypicalLayoutsExpandItem-${index}`;
        const isLevelOpened: boolean = openedLevelsIds.includes(levelExpandItemId);
        const isTypicalLayoutOpened: boolean = openedTypicalLayoutsIds.includes(typicalLayoutExpandId);
        let selectedLevelsCounter = 0;

        const buildingLevels = building.locations?.map(level => {
          if (levelsSelected.some(item => item.id === level.id)) selectedLevelsCounter += 1;
          return level;
        });

        result.push({
          id: levelExpandItemId,
          itemType: 'LevelExpandItem',
          isItemOpened: isLevelOpened,
          title: 'Levels',
          levelsInItem: buildingLevels,
          selectedLevelsCounter,
        });

        if (isLevelOpened) {
          buildingLevels?.forEach(level => {
            const isLevelSelected = levelsSelected.find(item => item.id === level.id);

            if (localSearchValue) {
              if (level.title.toLowerCase().includes(localSearchValue)) {
                result.push({
                  ...level,
                  itemType: 'LevelItem',
                  isSelected: isLevelSelected,
                });
              }
            } else {
              result.push({
                ...level,
                itemType: 'LevelItem',
                isSelected: isLevelSelected,
              });
            }
          });
        }

        result.push({
          id: typicalLayoutExpandId,
          itemType: 'TypicalLayoutsExpandItem',
          isItemOpened: isTypicalLayoutOpened,
          title: 'Typical ranges',
        });

        if (isTypicalLayoutOpened) {
          building.typical_layouts?.forEach(typicalLayout => {
            if (typicalLayout.building_id === building.id) {
              const isTypicalLayoutSelected = typicalLayoutsSelected.find(item => item.id === typicalLayout.id);

              if (localSearchValue) {
                if (typicalLayout.locations.some(item => item.title.toLowerCase().includes(localSearchValue))) {
                  result.push({
                    ...typicalLayout,
                    itemType: 'TypicalLayoutsItem',
                    isSelected: isTypicalLayoutSelected,
                  });
                }
              } else {
                result.push({
                  ...typicalLayout,
                  itemType: 'TypicalLayoutsItem',
                  isSelected: isTypicalLayoutSelected,
                });
              }
            }
          });
        }
      }
    });

    setMenuItems(result);
  };

  const handleBuildingItemClick = (building: PCDApi.PCDListBuildingInterface) => {
    const result = [...openedBuildingIds];

    if (result?.includes(building.id)) {
      result.splice(
        result.findIndex(id => id === building.id),
        1,
      );
    } else {
      result.push(building.id);
    }

    setOpenedBuildingIds(result);
  };

  const handleTypicalLayoutsExpandItemClick = (typicalLayouts: any) => {
    const result = [...openedTypicalLayoutsIds];

    if (result?.includes(typicalLayouts.id)) {
      result.splice(
        result.findIndex(id => id === typicalLayouts.id),
        1,
      );
    } else {
      result.push(typicalLayouts.id);
    }

    setOpenedTypicalLayoutsIds(result);
  };

  const handleLevelExpandItemItemClick = (level: LocationCollectionModel) => {
    const result = [...openedLevelsIds];

    if (result?.includes(level.id)) {
      result.splice(
        result.findIndex(id => id === level.id),
        1,
      );
    } else {
      result.push(level.id);
    }

    setOpenedLevelsIds(result);
  };
  const handleLevelItemClick = (level: LocationCollectionModel) => {
    const result = [...levelsSelected];
    const isLevelExist = result.some(item => item.id === level.id);

    // get building for selected level
    const buildingObj = buildings.find(building => building.id === level.building_id);

    // Check building selected if not add to selected array
    if (!selectedBuildings.some(building => building.id === buildingObj.id)) {
      setBuildingsSelected([...selectedBuildings, buildingObj]);
    }

    if (isLevelExist) {
      result.splice(
        result.findIndex(item => item.id === level.id),
        1,
      );
    } else {
      result.push({
        building_id: level.building_id,
        building_spaces: level.building_spaces,
        id: level.id,
        position: level.position,
        title: level.title,
        value: level.value,
      });
    }

    setLevelsSelected(result);
  };
  const handleTypicalLayoutsItemClick = (typicalLayouts: any) => {
    const result = [...typicalLayoutsSelected];
    const isTypicalLayoutExist = result.some(item => item.id === typicalLayouts.id);

    // get building for selected typical layout
    const buildingObj = buildings.find(building => building.id === typicalLayouts.building_id);

    // Check building selected if not add to selected array
    if (!selectedBuildings.some(building => building.id === buildingObj.id)) {
      setBuildingsSelected([...selectedBuildings, buildingObj]);
    }

    if (isTypicalLayoutExist) {
      result.splice(
        result.findIndex(item => item.id === typicalLayouts.id),
        1,
      );
    } else {
      result.push({
        building_id: typicalLayouts.building_id,
        building_spaces: typicalLayouts.building_spaces,
        id: typicalLayouts.id,
        discipline: typicalLayouts.position,
        title: typicalLayouts.title,
        value: typicalLayouts.value,
        ufc: typicalLayouts.value,
        locations: typicalLayouts.locations || [],
      });
    }

    setTypicalLayoutsSelected(result);
  };

  const findConsecutiveRanges = arr => {
    const ranges = [];
    let start = arr[0];
    let end = arr[0];
    let count = 1;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] === arr[i - 1] + 1) {
        // Элементы подряд идут, обновляем конечную позицию и счетчик
        end = arr[i];
        count++;
      } else {
        // Если встречается диапазон с более чем 2 элементами, добавляем его
        if (count >= 3) {
          ranges.push([start, end]);
        }
        // Начинаем новый диапазон с текущего элемента
        start = arr[i];
        end = arr[i];
        count = 1;
      }
    }
    // Добавляем последний диапазон, если он имеет более 2 элементов
    if (count >= 3) {
      ranges.push([start, end]);
    }
    return ranges;
  };
  const handleMenuClose = () => {
    onLevelMenuClose && onLevelMenuClose();

    selectedBuildings.forEach(building => {
      const levels = levelsSelected.filter(level => {
        if (level.building_id === building.id) {
          return level;
        }
      });

      if (levels.length > 2) {
        const sortedLevels = levels.sort((level1, level2) => level1.position - level2.position);
        const positionArray = sortedLevels.map(level => level.position);

        const rangesArray = findConsecutiveRanges(positionArray);
        const firstRange = rangesArray[0];

        if (rangesArray.length) {
          setIsCreateTypicalRange(true);
          setTypicalRangePopUpData({
            range: firstRange,
            buildingId: building.id,
            levelsInRange: sortedLevels.slice(
              sortedLevels.findIndex(level => level.position === firstRange[0]),
              sortedLevels.findIndex(level => level.position === firstRange[1]) + 1,
            ),
          });
        }
      }
    });

    setLocalSearchValue('');
    setIsCustomOpenMenu(false);
  };
  const handleTransformToTypical = () => {
    if (selectedTypicalLayout.title === 'Create a new range') {
      setOpenBuildingAndTypicalLayouts(0);
      dispatch(
        createTypical({
          id: typicalRangePopUpData.buildingId,
          projectId: activeProjectId,
          typical_layouts: [
            {
              discipline_id: selectedDiscipline?.id || null,
              start_position: typicalRangePopUpData.range[0],
              end_position: typicalRangePopUpData.range[1],
              id: null,
              ufc_id: selectedUf?.id || null,
            },
          ],
          callback: newTypicalLayouts => {
            setTypicalLayoutsSelected([...typicalLayoutsSelected, newTypicalLayouts[0]]);

            setTimeout(() => {
              const buildingArray = [...selectedBuildings];

              buildingArray.forEach(building => {
                const isCurrentItem: boolean = building.id === newTypicalLayouts[0].building_id;

                if (isCurrentItem) {
                  setOpenBuildingAndTypicalLayouts(building.id);
                  setOpenedLevelsIds([]);
                }
                setIsCustomOpenMenu(true);
              });
            }, 200);
          },
        }),
      );
    } else {
      const typicalLayoutsSelectedIds = typicalLayoutsSelected?.map(item => item.id) || [];
      const buildingArray = [...selectedBuildings];
      buildingArray.forEach(building => {
        const isCurrentItem: boolean = building.id === selectedTypicalLayout.building_id;

        if (isCurrentItem) {
          setOpenBuildingAndTypicalLayouts(building.id);
          setOpenedLevelsIds([]);
        }
        setIsCustomOpenMenu(true);
      });

      if (!typicalLayoutsSelectedIds.includes(selectedTypicalLayout.id)) {
        setTypicalLayoutsSelected([...typicalLayoutsSelected, selectedTypicalLayout]);
      }
    }

    const newLevelsArray = [...levelsSelected];

    typicalRangePopUpData.levelsInRange?.forEach(location => {
      newLevelsArray.splice(
        newLevelsArray.findIndex(level => level.id === location.id),
        1,
      );
    });

    setLevelsSelected(newLevelsArray);
    handleCloseTRCreactionPopUp();
  };

  const handleCloseTRCreactionPopUp = () => {
    setIsCreateTypicalRange(false);
    setSelectedTypicalLayout(createNewRangeOption);
  };

  const renderSelectItem = (item: DropdownItemModel) => {
    if (item.itemType === 'building') {
      return (
        <>
          <BuildingItem building={item} handleBuildingItemClick={handleBuildingItemClick} />
        </>
      );
    }
    if (item.itemType === 'LevelExpandItem') {
      return (
        <>
          <LevelExpandItem
            level={item}
            handleLevelExpandItemItemClick={handleLevelExpandItemItemClick}
            handleLevelExpandSelectAll={handleLevelExpandSelectAll}
            handleLevelExpandClearAll={handleLevelExpandClearAll}
          />
        </>
      );
    }
    if (item.itemType === 'TypicalLayoutsExpandItem') {
      return (
        <>
          <TypicalLayoutsExpandItem typicalLayout={item} handleTypicalLayoutsExpandItemClick={handleTypicalLayoutsExpandItemClick} />
        </>
      );
    }
    if (item.itemType === 'LevelItem') {
      return (
        <>
          <LevelItem level={item} handleLevelItemClick={handleLevelItemClick} />
        </>
      );
    }
    if (item.itemType === 'TypicalLayoutsItem') {
      return (
        <>
          <TypicalLayoutsItem typicalLayout={item} handleTypicalLayoutsItemClick={handleTypicalLayoutsItemClick} />
        </>
      );
    }

    return (
      <>
        <div>{item.title}</div>
      </>
    );
  };

  const handleClearAll = () => {
    setLevelsSelected([]);
    setTypicalLayoutsSelected([]);
  };

  const handleSelectAll = () => {
    if (selectedBuildings.length) {
      const newLevels = [];
      const newTypicalLayouts = [];

      selectedBuildings.map(building => {
        if (building.locations.length) {
          building.locations.map(lvl => newLevels.push(lvl));
        }

        if (building.typical_layouts.length) {
          building.typical_layouts.map(tl => newTypicalLayouts.push(tl));
        }
      });

      setLevelsSelected(newLevels);
      setTypicalLayoutsSelected(newTypicalLayouts);
    } else {
      setLevelsSelected([...levels]);
      setBuildingsSelected([...buildings]);
      setTypicalLayoutsSelected([...typicalLayouts]);
    }
  };

  const handleLevelExpandSelectAll = (e: React.SyntheticEvent, levelsArr: LocationCollectionModel[]) => {
    e.stopPropagation();
    e.preventDefault();

    const result = [...levelsSelected];

    levelsArr.forEach((level, index) => {
      if (index === 0) {
        const buildingObj = buildings.find(building => building.id === level.building_id);

        // Check building selected if not add to selected array
        if (!selectedBuildings.some(building => building.id === buildingObj.id)) {
          setBuildingsSelected([...selectedBuildings, buildingObj]);
        }
      }

      const levelExist = levelsSelected.find(levelSelected => level.id === levelSelected.id);

      if (!levelExist) {
        result.push(level);
      }
    });

    setLevelsSelected(result);
  };

  const handleLevelExpandClearAll = (e: React.SyntheticEvent, levelsArr: LocationCollectionModel[]) => {
    e.stopPropagation();
    e.preventDefault();

    const result = [...levelsSelected];

    levelsArr.forEach(level => {
      const levelIndex = result.findIndex(levelSelected => level.id === levelSelected.id);

      if (levelIndex !== -1) {
        result.splice(levelIndex, 1);
      }
    });

    setLevelsSelected(result);
  };

  const handleChangeSearch = searchValue => {
    setLocalSearchValue(searchValue.toLowerCase());
  };

  return (
    <>
      <Select<T, G, IsMultiValue>
        isMulti={true}
        isCustomMulti={true}
        isHeader={isHeader}
        isFooter={isFooter}
        menuItems={menuItems}
        selectHandler={selectHandler}
        selected={selected}
        isLoading={isLoading}
        basisPlaceholder={basisPlaceholder}
        menuItemSize={menuItemSize}
        menuItemTitleRenderer={renderSelectItem}
        customBasisFieldClass={customBasisFieldClass}
        isFullWidth={isFullWidth}
        customMenuClass={customMenuClass}
        onMenuClose={handleMenuClose}
        disabled={disabled}
        customClearAll={handleClearAll}
        customSelectAll={handleSelectAll}
        isHeaderNoMulti
        isOpenMenu={isOpenMenu || isCustomOpenMenu}
        isMobile={isMobile}
        basisMaxItems={1}
        onChangeSearch={handleChangeSearch}
        renderCustomBasis={renderCustomBasis}
        isHighResolution={isHighResolution}
      />
      {isCreateTypicalRange && (
        <DialogPopUp
          open={isCreateTypicalRange}
          aria-labelledby="Manage statuses"
          disableBackdropClick
          disableEscapeKeyDown
          disableEnforceFocus
          hideBackdrop={false}
          onBackdropClick={() => handleCloseTRCreactionPopUp()}
          onEscapeKeyDown={() => handleCloseTRCreactionPopUp()}
          onClose={() => handleCloseTRCreactionPopUp()}
          customClassname={`${themeClass}_createTRPopUp`}
          primaryText={'Transform to typical'}
          handleOnPrimary={() => handleTransformToTypical()}
          secondaryText={'Use selection'}
          handleOnSecondary={() => handleCloseTRCreactionPopUp()}
          title={'Create typical range'}
          renderModalContent={() => (
            <div className={`${themeClass}_content`}>
              <div className={`${themeClass}_content_text`}>
                You selected {typicalRangePopUpData.levelsInRange.length} consecutive levels from{' '}
                <span className="marked">{typicalRangePopUpData.levelsInRange[0].title}</span> to{' '}
                <span className="marked">{typicalRangePopUpData.levelsInRange[typicalRangePopUpData.levelsInRange.length - 1].title}</span>.{' '}
                Do you want to use this selection? You can also transform it into an existing typical range or create a new one.
              </div>
              <div className={`${themeClass}_content_select`}>
                <div className={`${themeClass}_content_select_text`}>Typical range</div>
                <Select<DropdownItemModel, null, true>
                  isMulti={false}
                  menuItems={typicalLayoutsItems || []}
                  selectHandler={item => setSelectedTypicalLayout(item)}
                  selected={selectedTypicalLayout || []}
                  isLoading={false}
                  basisPlaceholder={'Create a new range'}
                  renderBasisSelectedItem={item => item.title}
                  customBasisFieldClass={'customClassProjectTypeWidth'}
                  menuItemSize={'md'}
                  menuItemTitleRenderer={item => item.title}
                  isHighResolution={isHighResolution}
                />
              </div>
              <div className={`${themeClass}_content_select`}>
                <div className={`${themeClass}_content_select_text`}>UF</div>
                <CodeSelector
                  isMulti={false}
                  codeType={'uf'}
                  handleFor={'content-plan'}
                  selected={selectedUf}
                  selectHandler={setSelectedUf}
                  basisPlaceholder={`Uniformat`}
                  menuItemSize={'md'}
                  basisMaxItems={5}
                  disabled={selectedTypicalLayout?.title !== 'Create a new range'}
                  isHighResolution={isHighResolution}
                />
              </div>
              <div className={`${themeClass}_content_select`}>
                <div className={`${themeClass}_content_select_text`}>Discipline</div>
                <Select
                  basisPlaceholder="Discipline"
                  isMulti={false}
                  selected={selectedDiscipline}
                  menuItems={disciplines}
                  menuItemTitleRenderer={item => <Badge item={item} type={'discipline'} render={item => item.title} />}
                  renderBasisSelectedItem={item => <Badge item={item} type={'discipline'} render={item => item.title} />}
                  selectHandler={item => setSelectedDiscipline(item)}
                  menuItemSize="md"
                  disabled={selectedTypicalLayout?.title !== 'Create a new range'}
                  isHighResolution={isHighResolution}
                />
              </div>
            </div>
          )}
        />
      )}
    </>
  );
}

export default LevelsSelect;
