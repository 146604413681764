import React, { FC, useMemo } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import CustomTooltip from '../../Tooltip/Tooltip';
import cn from 'classnames';

import './StatusNFValidationStyles.module.scss';

const StatusNFValidation: FC<{
  type: 'valid-outlined' | 'valid-fill' | 'void' | 'not-valid' | 'outdated' | 'no-action-taken' | 'not-verified' | 'new-valid' | 'ignore';
  size: 'sm' | 'm';
  isVisibleLabel?: boolean;
  tooltip?: string;
}> = ({ type, size, isVisibleLabel = false, tooltip }) => {
  const themeClass = useGetThemeClass('b-statusNFValidation');
  const ucFirst = str => {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  };

  const getSetStatus = (status: string, sizeNumber: 12 | 16) => {
    switch (status) {
      case 'valid-outlined': {
        return <TokenIcon iconName={'valid'} size={sizeNumber} />;
      }
      case 'new-valid': {
        return <TokenIcon iconName={'1st-valid'} size={sizeNumber} />;
      }
      case 'valid-fill': {
        return (
          <div className={`valid-fill -${size}`}>
            <TokenIcon iconName={'check'} size={sizeNumber} />
          </div>
        );
      }
      case 'not-valid': {
        return <TokenIcon iconName={'void'} size={sizeNumber} />;
      }
      case 'outdated': {
        return <TokenIcon iconName={'not-valid'} size={sizeNumber} />;
      }
      case 'not-verified': {
        return <TokenIcon iconName={'not-verified-triangle'} size={sizeNumber} />;
      }
      case 'void': {
        return <TokenIcon iconName={'void'} size={sizeNumber} />;
      }
      case 'no-action-taken': {
        return <TokenIcon iconName={'not-valid'} size={sizeNumber} />;
      }
      case 'ignore': {
        return <TokenIcon iconName={'cancel-right'} size={sizeNumber} />;
      }
      default:
        return null;
    }
  };

  const statusName = useMemo(() => {
    if (type === 'valid-fill' || 'valid-outlined') {
      return 'Valid';
    }

    if (type === 'not-valid') {
      return 'Not valid';
    }

    if (type === 'not-verified') {
      return 'Not verified';
    }

    if (type === 'void') {
      return 'Void';
    }

    if (type === 'new-valid') {
      return '1st valid';
    }

    if (type === 'ignore') {
      return 'Ignore';
    }
  }, [type]);

  return (
    <>
      <CustomTooltip title={tooltip || ''} placement={'bottom'}>
        <div className={`${themeClass} test`}>
          {size === 'sm' && getSetStatus(type, 12)}
          {size === 'm' && getSetStatus(type, 16)}

          {isVisibleLabel && (
            <div
              className={cn('label_text', {
                ['sys-success']: type === 'valid-outlined',
                ['secondary']: type === 'not-valid',
                ['sys-warning']: type === 'not-verified',
              })}
            >
              {ucFirst(statusName)}
            </div>
          )}
        </div>
      </CustomTooltip>
    </>
  );
};

export default React.memo(StatusNFValidation);
