import React, { ReactElement, MouseEvent, ReactNode } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import CustomTooltip from '../../Tooltip/Tooltip';
import cn from 'classnames';

import './CardLinkButtonStyles.scss';

interface CardLinkButtonProps {
  type: 'nf' | 'deliverable' | 'submittal';
  size: 'sm' | 'md';
  title: string | ReactElement;
  clickHandler?: (e: MouseEvent) => void;
  tooltip?: string;
  icon?: () => ReactNode;
  customClassName?: string;
  isMobile?: boolean;
}

const CardLinkButton: React.FC<CardLinkButtonProps> = ({ type, size, title, clickHandler, tooltip, icon, customClassName, isMobile }) => {
  const themeClass = useGetThemeClass('b-cardLinkButton');

  const handleClick = (e: MouseEvent) => {
    if (clickHandler) {
      e.stopPropagation();
      clickHandler(e);
    }
  };

  return (
    <>
      <CustomTooltip title={tooltip || ''} placement={'bottom'}>
        <div onClick={handleClick} className={cn(`${themeClass} ${themeClass}_${type} -${size}`, { ['-mobile']: isMobile })}>
          {icon && icon()}
          <span
            className={cn(`buttonTitle -${size} ${themeClass}_${type}_title`, {
              [`${customClassName}`]: customClassName,
            })}
          >
            {title}
          </span>
        </div>
      </CustomTooltip>
    </>
  );
};

export default CardLinkButton;
