export const ScreensRoutes = {
  needsList: 'needs-list',
  card: 'card',
  phasePlan: 'phase-plan',
  invite: 'invite',
  whiteBoard: 'white-board',
  reporting: 'reporting',
  inputDataPage: 'input-data',
  outputData: 'output-data',
  deliverables: 'deliverables',
  projects: 'projects',
  profile: 'profile',
  notificationsList: 'notifications-list',
  newProject: 'new-project',
  projectSettings: 'project-settings',
  team: 'team',
  commandCenter: 'command-center',
  sandbox: 'sand-box',
  componentCheckPage: 'component-check',
};

export const UnauthorizedRoutes = {
  unsubscribe: 'unsubscribe',
  invite: 'invite/:inviteKey?',
  forgotPassword: 'forgot-password',
  restorePassword: 'restore-password',
};

export const root = '/:project';

export const AuthorizedRoutes = {
  phasePlan: `${root}/${ScreensRoutes.phasePlan}`,
  needsList: `${root}/${ScreensRoutes.needsList}/:filter?`,
  commandCenter: `${root}/${ScreensRoutes.commandCenter}`,
  commandCenterRequest: `${root}/${ScreensRoutes.commandCenter}/:companyId`,
  sandBoxOld: `${root}/${ScreensRoutes.sandbox}`,
  sandBoxOldRequest: `${root}/${ScreensRoutes.sandbox}/:companyId`,
  card: `${root}/${ScreensRoutes.card}`,
  createProjectIdSheet: `${root}/${ScreensRoutes.projectSettings}/:id/:name`,
  createProjectId: `${root}/${ScreensRoutes.projectSettings}/:id`,
  createProject: `${root}/${ScreensRoutes.newProject}`,
  whiteBoard: `${root}/${ScreensRoutes.whiteBoard}`,
  reporting: `${root}/${ScreensRoutes.reporting}/:companyId?/:userId?`,
  inputData: `${root}/${ScreensRoutes.inputDataPage}/:name`,
  outputData: `${root}/${ScreensRoutes.outputData}/:name`,
  pcDeliverables: `${root}/${ScreensRoutes.deliverables}`,
  pcDeliverablesOld: `${root}/pc-deliverables`,
  team: `${root}/${ScreensRoutes.team}`,
  teamEditCompany: `${root}/${ScreensRoutes.team}/:popup/:companyId`,
  project: `${root}/${ScreensRoutes.projects}`,
  profile: `${root}/${ScreensRoutes.profile}`,
  notificationsList: `${root}/${ScreensRoutes.notificationsList}`,
  componentCheckPage: `${root}/${ScreensRoutes.componentCheckPage}`,
  changeCompany: 'change-company',
  changeProject: 'change-project',
  unsubscribe: 'unsubscribe',
};

export const defaultRoute = `${root}/`;
export const needsList = `${root}/${ScreensRoutes.needsList}`;
export const card = `${root}/${ScreensRoutes.card}`;
export const phasePlan = `${root}/${ScreensRoutes.phasePlan}`;
export const loginP = `/`;
export const invite = `${root}/${ScreensRoutes.invite}`;
export const forgotPassword = `/forgot-password`;
export const restorePassword = `/restore-password`;
export const whiteBoard = `${root}/${ScreensRoutes.whiteBoard}`;
export const reporting = `${root}/${ScreensRoutes.reporting}/:companyId?/:userId?`;
export const inputDataPage = `${root}/${ScreensRoutes.inputDataPage}/:name`;
export const outputData = `${root}/${ScreensRoutes.outputData}/:name`;
export const pcDeliverablesOld = `${root}/pc-deliverables/`;

export const pcDeliverables = `${root}/${ScreensRoutes.deliverables}/`;

export const project = `${root}/${ScreensRoutes.projects}`;
export const profile = `${root}/${ScreensRoutes.profile}`;
export const notificationsList = `${root}/${ScreensRoutes.notificationsList}`;
export const sandBoxRequest = `${root}/command-center/:id`;
export const createProject = `${root}/${ScreensRoutes.newProject}`;
export const createProjectId = `${root}/${ScreensRoutes.projectSettings}/:id`;
export const createProjectIdSheet = `${root}/${ScreensRoutes.projectSettings}/:id/:name`;

export const sandBoxOld = `${root}/${ScreensRoutes.sandbox}`;
export const sandBoxNew = `${root}/${ScreensRoutes.commandCenter}`;
export const sandBoxNewRequest = `${root}/${ScreensRoutes.commandCenter}/:companyId`;

export const team = `${root}/${ScreensRoutes.team}`;
export const teamEditCompany = `${root}/${ScreensRoutes.team}/:popup(company)?/:companyId(\\d+)?`;

export const componentCheck = `${root}/component-check`;

export const getSandBoxNew = project_name => {
  return `${project_name ? `/${project_name}` : root}/command-center`;
};

export const getNeedsList = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.needsList}`;
export const getPhasePlan = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.phasePlan}`;
export const getWhiteBoard = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.whiteBoard}`;
export const getReporting = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.reporting}`;
export const getInputDataPage = (project_name, name) =>
  `${project_name ? `/${project_name}` : root}/${ScreensRoutes.inputDataPage}/${name}`;
export const getOutputDataPage = (project_name, name) => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.outputData}/${name}`;
export const getInvite = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.invite}`;
export const getTeam = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.team}`;
export const getProject = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.projects}`;
export const getProfile = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.profile}`;
export const getNotificationsList = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.notificationsList}`;
export const getCreateProject = project_name => `${project_name ? `/${project_name}` : root}/${ScreensRoutes.newProject}`;
export const getCreateProjectId = (project_name, id) =>
  `${project_name ? `/${project_name}` : root}/${ScreensRoutes.projectSettings}/${id}`;
export const getCreateProjectIdSheet = (project_name, id, name) =>
  `${project_name ? `/${project_name}` : root}/${ScreensRoutes.projectSettings}/${id}/${name}`;
export const getComponentCheck = project_name => `${project_name ? `/${project_name}` : root}/component-check`;
