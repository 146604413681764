import { actionCreater } from '../configure/actionCreater';
//import { createRequestTypes } from '../../service/utils';

//login
export const LOGIN = 'LOGIN';
export const login = actionCreater(LOGIN);

export const LOGIN_FAIL = 'LOGIN_FAIL';
export const loginFailed = actionCreater(LOGIN_FAIL);

export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const loginSucces = actionCreater(LOGIN_SUCCES);

//Get User Info
export const USER_INFO = 'USER_INFO';
export const userInfo = actionCreater(USER_INFO);

export const USER_INFO_FAIL = 'USER_INFO_FAIL';
export const userInfoFailed = actionCreater(USER_INFO_FAIL);

export const USER_INFO_SUCCES = 'USER_INFO_SUCCES';
export const userInfoSucces = actionCreater(USER_INFO_SUCCES);

export const INCREMENT_NOTIFICATIONS_COUNTER = 'INCREMENT_NOTIFICATIONS_COUNTER';
export const incrementNotificationsCounter = actionCreater(INCREMENT_NOTIFICATIONS_COUNTER);

export const LOGOUT = 'LOGOUT';
export const logout = actionCreater(LOGOUT);

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logoutSuccess = actionCreater(LOGOUT_SUCCESS);

export const GET_FORBIDDEN = 'GET_FORBIDDEN';
export const getForbidden = actionCreater(GET_FORBIDDEN);

export const SET_FORBIDDEN_STATUS = 'SET_FORBIDDEN_STATUS';
export const setForbiddenStatus = actionCreater(SET_FORBIDDEN_STATUS);

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = actionCreater(FORGOT_PASSWORD);

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const forgotPasswordSuccess = actionCreater(FORGOT_PASSWORD_SUCCESS);

export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const forgotPasswordFail = actionCreater(FORGOT_PASSWORD_FAIL);

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPassword = actionCreater(RESET_PASSWORD);

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const resetPasswordSuccess = actionCreater(RESET_PASSWORD_SUCCESS);

export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const resetPasswordFail = actionCreater(RESET_PASSWORD_FAIL);

//Set active_project
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const setActiveProject = actionCreater(SET_ACTIVE_PROJECT);

// Set active_client

export const GET_CLIENTS_PROJECTS = 'GET_CLIENTS_PROJECTS';
export const getClientsProjects = actionCreater(GET_CLIENTS_PROJECTS);

export const GET_CLIENTS_PROJECTS_SUCCESS = 'GET_CLIENTS_PROJECTS_SUCCESS';
export const getClientsProjectSuccess = actionCreater(GET_CLIENTS_PROJECTS_SUCCESS);

export const GET_CLIENTS_PROJECTS_FAIL = 'GET_CLIENTS_PROJECTS_FAIL';
export const getClientsProjectFail = actionCreater(GET_CLIENTS_PROJECTS_FAIL);

// Set client_from_project

export const GET_CLIENT_FROM_PROJECT = 'GET_CLIENT_FROM_PROJECT';
export const getClientFromProject = actionCreater(GET_CLIENT_FROM_PROJECT);

export const GET_CLIENT_FROM_PROJECT_SUCCESS = 'GET_CLIENT_FROM_PROJECT_SUCCESS';
export const getClientFromProjectSuccess = actionCreater(GET_CLIENT_FROM_PROJECT_SUCCESS);

export const GET_CLIENT_FROM_PROJECT_FAIL = 'GET_CLIENT_FROM_PROJECT_FAIL';
export const getClientFromProjectFail = actionCreater(GET_CLIENT_FROM_PROJECT_FAIL);

// Create client

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const createClient = actionCreater(CREATE_CLIENT);

export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const createClientSuccess = actionCreater(CREATE_CLIENT_SUCCESS);

export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';
export const createClientFail = actionCreater(CREATE_CLIENT_FAIL);

// Update client

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const updateClient = actionCreater(UPDATE_CLIENT);

export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const updateClientSuccess = actionCreater(UPDATE_CLIENT_SUCCESS);

export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const updateClientFail = actionCreater(UPDATE_CLIENT_FAIL);

//inviteUser

export const INVITE_USER = 'INVITE_USER';
export const inviteUser = actionCreater(INVITE_USER);

export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const inviteUserSuccess = actionCreater(INVITE_USER_SUCCESS);

export const INVITE_USER_FAILE = 'INVITE_USER_FAILE';
export const inviteUserFaile = actionCreater(INVITE_USER_FAILE);

//updateUser

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = actionCreater(UPDATE_USER);

export const UPDATE_USER_FAILE = 'UPDATE_USER_FAILE';
export const updateUserFaile = actionCreater(UPDATE_USER_FAILE);

//watcherAdd
export const WATCHER_ADD = 'WATCHER_ADD';
export const watcherAdd = actionCreater(WATCHER_ADD);

export const WATCHER_ADD_SUCCESS = 'WATCHER_ADD_SUCCESS';
export const watcherAddSuccess = actionCreater(WATCHER_ADD_SUCCESS);

export const WATCHER_ADD_FAIL = 'WATCHER_ADD_FAIL';
export const watcherAddFail = actionCreater(WATCHER_ADD_FAIL);

export const WATCHER_ADD_CLEAN = 'WATCHER_ADD_CLEAN';
export const watcherAddClean = actionCreater(WATCHER_ADD_CLEAN);

export const WATCHER_REMOVE = 'WATCHER_REMOVE';
export const watcherRemove = actionCreater(WATCHER_REMOVE);

export const WATCHER_REMOVE_SUCCESS = 'WATCHER_REMOVE_SUCCESS';
export const watcherRemoveSuccess = actionCreater(WATCHER_REMOVE_SUCCESS);

export const ADDED_NEW_PROJECT = 'ADDED_NEW_PROJECT';
export const addedNewProject = actionCreater(ADDED_NEW_PROJECT);

export const GET_IP_V_4 = 'GET_IP_V_4';
export const getIPv4 = actionCreater(GET_IP_V_4);

export const REDUCE_NEWS_COUNT = 'REDUCE_NEWS_COUNT';
export const reduceNewsCount = actionCreater(REDUCE_NEWS_COUNT);

export const ZERO_NEWS_COUNT = 'ZERO_NEWS_COUNT';
export const zeroNewsCount = actionCreater(ZERO_NEWS_COUNT);

export const SET_USER_BANNER = 'SET_USER_BANNER';
export const setUserBanner = actionCreater(SET_USER_BANNER);

export const SET_USER_BANNER_SUCCESS = 'SET_USER_BANNER_SUCCESS';
export const setUserBannerSuccess = actionCreater(SET_USER_BANNER_SUCCESS);

export const SET_USER_SEEN_RELEASE_NOTES = 'SET_USER_SEEN_RELEASE_NOTES';
export const setUserSeenReleaseNotes = actionCreater(SET_USER_SEEN_RELEASE_NOTES);

export const SET_USER_SEEN_RELEASE_NOTES_SUCCESS = 'SET_USER_SEEN_RELEASE_NOTES_SUCCESS';
export const setUserSeenReleaseNotesSuccess = actionCreater(SET_USER_SEEN_RELEASE_NOTES_SUCCESS);

export const SET_DEFAULT_COLUMN_ORDER = 'SET_DEFAULT_COLUMN_ORDER';
export const setDefaultColumnOrder = actionCreater(SET_DEFAULT_COLUMN_ORDER);

export const GET_PROMPTS_ALERTS = 'GET_PROMPTS_ALERTS';
export const getPromptsAlerts = actionCreater(GET_PROMPTS_ALERTS);

export const GET_PROMPTS_ALERTS_SUCCESS = 'GET_PROMPTS_ALERTS_SUCCESS';
export const getPromptsAlertsSuccess = actionCreater(GET_PROMPTS_ALERTS_SUCCESS);

export const GET_PROMPTS_ALERTS_FAIL = 'GET_PROMPTS_ALERTS_FAIL';
export const getPromptsAlertsFail = actionCreater(GET_PROMPTS_ALERTS_FAIL);

export const SET_PROMPTS_ALERTS = 'SET_PROMPTS_ALERTS';
export const setPromptsAlerts = actionCreater(SET_PROMPTS_ALERTS);

export const UNSUBSCRIBE_RELEASE_USER = 'UNSUBSCRIBE_RELEASE_USER';
export const unsubscribeReleaseUser = actionCreater(UNSUBSCRIBE_RELEASE_USER);

export const UNSUBSCRIBE_RELEASE_USER_SUCCESS = 'UNSUBSCRIBE_RELEASE_USER_SUCCESS';
export const unsubscribeReleaseUserSuccess = actionCreater(UNSUBSCRIBE_RELEASE_USER_SUCCESS);

export const UNSUBSCRIBE_RELEASE_USER_FAILE = 'UNSUBSCRIBE_RELEASE_USER_FAILE';
export const unsubscribeReleaseUserFaile = actionCreater(UNSUBSCRIBE_RELEASE_USER_FAILE);

// Set user avatar

export const CHANGE_USER_AVATAR = 'CHANGE_USER_AVATAR';
export const changeUserAvatar = actionCreater(CHANGE_USER_AVATAR);

// Set project notification settings

export const SET_PROJECT_NOTIFICATION_SETTINGS = 'SET_PROJECT_NOTIFICATION_SETTINGS';
export const setProjectNotificationSettings = actionCreater(SET_PROJECT_NOTIFICATION_SETTINGS);

export const SET_PROJECT_NOTIFICATION_SETTINGS_SUCCESS = 'SET_PROJECT_NOTIFICATION_SETTINGS_SUCCESS';
export const setProjectNotificationSettingsSuccess = actionCreater(SET_PROJECT_NOTIFICATION_SETTINGS_SUCCESS);

export const SET_PROJECT_NOTIFICATION_SETTINGS_FAIL = 'SET_PROJECT_NOTIFICATION_SETTINGS_FAIL';
export const setProjectNotificationSettingsFail = actionCreater(SET_PROJECT_NOTIFICATION_SETTINGS_FAIL);

// Set user profile

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const getUserProfile = actionCreater(GET_USER_PROFILE);

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const getUserProfileSuccess = actionCreater(GET_USER_PROFILE_SUCCESS);
