import { actionCreater } from '../configure/actionCreater';

// Handle help menu

export const HANDLE_HELP_MENU = 'HANDLE_HELP_MENU';
export const handleHelpMenu = actionCreater(HANDLE_HELP_MENU);

// Handle open help submenu

export const HANDLE_HELP_SUB_MENU = 'HANDLE_HELP_SUB_MENU';
export const handleHelpSubMenu = actionCreater(HANDLE_HELP_SUB_MENU);

//Get Getting started
// export const GET_GETTING_STARTED = 'GET_GETTING_STARTED';
// export const getGettingStarted = actionCreater(GET_GETTING_STARTED);

// export const GET_GETTING_STARTED_SUCCESS = 'GET_GETTING_STARTED_SUCCESS';
// export const getGettingStartedSuccess = actionCreater(GET_GETTING_STARTED_SUCCESS);

// export const GET_GETTING_STARTED_FAIL = 'GET_GETTING_STARTED_FAIL';
// export const getGettingStartedFail = actionCreater(GET_GETTING_STARTED_FAIL);

//Get quick tour
export const GET_QUICK_TOUR = 'GET_QUICK_TOUR';
export const getQuickTour = actionCreater(GET_QUICK_TOUR);

export const GET_QUICK_TOUR_SUCCESS = 'GET_QUICK_TOUR_SUCCESS';
export const getQuickTourSuccess = actionCreater(GET_QUICK_TOUR_SUCCESS);

export const GET_QUICK_TOUR_FAIL = 'GET_QUICK_TOUR_FAIL';
export const getQuickTourFail = actionCreater(GET_QUICK_TOUR_FAIL);

//Get Getting started
export const SET_SEEN_GETTING_STARTED_STATUS = 'SET_SEEN_GETTING_STARTED_STATUS';
export const setSeenGettingStartedStatus = actionCreater(SET_SEEN_GETTING_STARTED_STATUS);

export const SET_SEEN_GETTING_STARTED_STATUS_SUCCESS = 'SET_SEEN_GETTING_STARTED_STATUS_SUCCESS';
export const setSeenGettingStartedStatusSuccess = actionCreater(SET_SEEN_GETTING_STARTED_STATUS_SUCCESS);

//Get What's news
export const GET_WHAT_NEWS = 'GET_WHAT_NEWS';
export const getWhatNews = actionCreater(GET_WHAT_NEWS);

export const GET_WHAT_NEWS_SUCCESS = 'GET_WHAT_NEWS_SUCCESS';
export const getWhatNewsSuccess = actionCreater(GET_WHAT_NEWS_SUCCESS);

export const GET_WHAT_NEWS_FAIL = 'GET_WHAT_NEWS_FAIL';
export const getWhatNewsFail = actionCreater(GET_WHAT_NEWS_FAIL);

//Get What's news
export const SET_READ_NEWS_STATUS = 'SET_READ_NEWS_STATUS';
export const setReadNewsStatus = actionCreater(SET_READ_NEWS_STATUS);

export const SET_READ_NEWS_STATUS_SUCCESS = 'SET_READ_NEWS_STATUS_SUCCESS';
export const setReadNewsStatusSuccess = actionCreater(SET_READ_NEWS_STATUS_SUCCESS);

export const SET_READ_NEWS_STATUS_FAIL = 'SET_READ_NEWS_STATUS_FAIL';
export const setReadNewsStatusFail = actionCreater(SET_READ_NEWS_STATUS_FAIL);

export const SET_READ_NEWS_STATUS_ALL = 'SET_READ_NEWS_STATUS_ALL';
export const setReadAllNewsStatus = actionCreater(SET_READ_NEWS_STATUS_ALL);

export const SET_READ_NEWS_STATUS_ALL_SUCCESS = 'SET_READ_NEWS_STATUS_ALL_SUCCESS';
export const setReadAllNewsStatusSuccess = actionCreater(SET_READ_NEWS_STATUS_ALL_SUCCESS);
