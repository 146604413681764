import { UserApi } from '../../service/Api/Api';
import settingsApi from '../../service/Api/settingsApi';
import userApi from '../../service/Api/userApi';
import { notificationsSlice } from '../notifications/notificationsReducer';
import { filterSlice } from '../filters/filtersReducer';
import { requestSlice } from '../request/requestReducer(HOLD)';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { teamSlice } from '../team/teamSlice';
import {
  CreateClientPayloadModel,
  CreateClientResponseModel,
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  GetClientFromProjectPayloadModel,
  GetClientFromProjectResponseModel,
  GetClientProjectsPayloadModel,
  GetClientProjectsResponseModel,
  GetPromptsAlertsResponseModel,
  GetUserProfilePayloadModel,
  GetUserProfileResponseModel,
  InviteUserPayload,
  LoginPayloadModel,
  ProjectSettingsModel,
  UpdateClientPayloadModel,
  UpdateUserPayloadModel,
  UpdateUserPayloadThemeModel,
  UserResponseModel,
  WatcherAddPayload,
} from '../../models';
import { BaseResponse, ErrorResponseModel } from '../../models/Api/response.model';
import { asideSlice } from '../aside/asideSlice';
import { errorsSlice } from '../errors/errorsSlice';
import { getFeed } from '../comments/commentsThunk';
import MixpanelHelper from '../../service/Mixpanel/Mixpanel';
import { clearNeedListCacheLogOut } from '../../service/IndexedDB/Helper';
import { userSlice } from './userSlice';
import { submittalSlice } from '../submittal/submittalSlice';
import { pcdSlice } from '../pcd/pcdSlice';
const { setUnreadNotificationCount } = notificationsSlice.actions;
const { hardResetFiltersReducer } = filterSlice.actions;
const { clearTeamState } = teamSlice.actions;
const { setShowErrorData } = errorsSlice.actions;
const { handleSubmittalChangeWatchlist } = submittalSlice.actions;
const { handleDeliverableChangeWatchlist } = pcdSlice.actions;

export const login = createAsyncThunk<
  UserResponseModel,
  LoginPayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/login', async (payload, { dispatch, rejectWithValue }) => {
  const { email, password, callback } = payload;

  try {
    const response = await UserApi.getLogin(email, password);

    callback && callback();

    localStorage.setItem('isShowNews1', JSON.stringify(true));

    response.loading = false;

    dispatch(setUnreadNotificationCount(response.data.new_notifications_count));

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const logout = createAsyncThunk<null, { callback: () => void; isSocket?: boolean }>(
  'userReducer/logout',
  async ({ callback, isSocket }, { dispatch, rejectWithValue }) => {
    try {
      let response;
      await clearNeedListCacheLogOut(dispatch);
      if (!isSocket) {
        response = await UserApi.getLogout();
      }
      const { clearNeedListQueryParams, setIsNeedListLoadingStatus } = requestSlice.actions;
      const { setActiveModule } = asideSlice.actions;
      const { setToDefault } = userSlice.actions;

      dispatch(setToDefault());
      dispatch(setIsNeedListLoadingStatus(false));
      dispatch(clearTeamState());
      dispatch(hardResetFiltersReducer());
      dispatch(clearNeedListQueryParams());
      dispatch(setActiveModule({ module: '' }));

      callback && callback();

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const userInfo = createAsyncThunk<
  UserResponseModel,
  { withoutUserData: boolean },
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/userInfo', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { userReducer } = getState();

  if (userReducer.user_id || (payload && payload?.withoutUserData)) {
    try {
      let response;

      if (payload && payload?.withoutUserData) {
        response = await UserApi.getUserAllInfoWithoutId();
      } else {
        response = await UserApi.getUserAllInfo(userReducer.user_id);
      }

      if (!response) {
        throw {
          message: '',
          loading: false,
          status: false,
        };
      }

      response.loading = false;
      const { first_name, last_name, email, theme, roles } = response.data;

      MixpanelHelper.identify(response.data.id);

      MixpanelHelper.people.set({
        $name: `${first_name} ${last_name}`,
        $email: email,
        Theme: theme,
        Role: Object.values(roles)[0],
        Resolution: `${window.outerWidth} x ${window.outerHeight}`,
      });

      dispatch(setUnreadNotificationCount(response.data.new_notifications_count));

      return response;
    } catch (error) {
      error.loading = false;
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  }
});

export const inviteUser = createAsyncThunk<
  UserResponseModel,
  InviteUserPayload,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/inviteUser', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { token, password, callback } = payload;
  try {
    const response = await UserApi.inviteUser(token, password);

    dispatch(login({ email: response.data.email, password }));

    callback && callback();

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const forgotPassword = createAsyncThunk<
  ForgotPasswordResponse,
  ForgotPasswordPayload,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/forgotPassword', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { email } = payload;
  try {
    const response = await UserApi.forgotPassword(email);

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk<
  UserResponseModel,
  InviteUserPayload,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/resetPassword', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { password, token } = payload;
  try {
    const response = await UserApi.resetPassword(password, token);

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const updateUser = createAsyncThunk<
  BaseResponse<null>,
  Partial<UpdateUserPayloadModel> | UpdateUserPayloadThemeModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/updateUser', async (payload, { dispatch, getState, rejectWithValue }) => {
  const data = { ...payload };

  try {
    const projectId = sessionStorage.getItem('active_project_id');

    data.project_id = +projectId;

    if (!data.user_id) {
      data.user_id = +localStorage.getItem('user_id');
    }

    if (payload.image_to_upload) {
      const fileUploadResult = await settingsApi.filesUpload(payload.image_to_upload);

      if (fileUploadResult.status) {
        data.image = fileUploadResult.data.filepath;
      }
    }

    const updateUserResult = await settingsApi.updateMember(data);

    if (updateUserResult.status === false || updateUserResult.status === 400) {
      throw updateUserResult;
    }

    dispatch(userInfo());

    return updateUserResult;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const watcherAdd = createAsyncThunk<
  BaseResponse<null>,
  WatcherAddPayload,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/watcherAdd', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { owner_id, user_ids, type, callback } = payload;
  const { handleRequestChangeWatchlist } = requestSlice.actions;
  try {
    const response = await userApi.watcherAdd(type, { owner_id, user_ids });

    dispatch(getFeed({ owner_id, type }));

    if (callback) {
      callback();
    }

    switch (type) {
      case 'request':
        return dispatch(handleRequestChangeWatchlist({ watchers: response?.response?.resource }));
      case 'deliverable':
        return dispatch(handleDeliverableChangeWatchlist({ watchers: response?.response?.resource }));
      case 'submittal':
        return dispatch(handleSubmittalChangeWatchlist({ watchers: response?.response?.resource }));
      default:
        break;
    }

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const setUserBanner = createAsyncThunk<BaseResponse<null>, null>(
  'userReducer/setUserBanner',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userApi.userSetBanner();

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setUserSeenReleaseNotes = createAsyncThunk<BaseResponse<null>, null>(
  'userReducer/setUserSeenReleaseNotes',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userApi.setUserSeenReleaseNotes();

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
export const setUserSeenReleaseSandboxTour = createAsyncThunk<BaseResponse<null>, null>(
  'userReducer/setUserSeenReleaseSandboxTour',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await userApi.setUserSeenReleaseSandboxTour();

      if (payload.callback) {
        payload.callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getClientFromProject = createAsyncThunk<GetClientFromProjectResponseModel, GetClientFromProjectPayloadModel>(
  'userReducer/getClientFromProject',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const response: GetClientFromProjectResponseModel = await userApi.getClientFromProject(payload);

      dispatch(getClientsProjects({ clientId: +response.data.client.id, code: payload.code }));

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getClientsProjects = createAsyncThunk<
  GetClientProjectsResponseModel,
  GetClientProjectsPayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/getClientsProjects', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const { clientId, urlProject, code, callback } = payload;
    const client_Id = clientId;

    if (client_Id) {
      const response = await userApi.getClientsProjects(client_Id);

      let projectId;
      if (code) {
        projectId = response.data.projects.find(p => p.code === code)?.id;
        response.active_project_id = projectId;
      }

      response.selected_client_id = client_Id;
      response.code = code;
      response.url_project = urlProject;

      if (callback) {
        callback(response.data);
      }

      return response;
    } else {
      return rejectWithValue(null);
    }
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const createClient = createAsyncThunk<
  CreateClientResponseModel,
  CreateClientPayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/createClient', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { bodyParams, callback } = payload;

  try {
    const response = await userApi.createClient(bodyParams);

    callback && callback();

    return response;
  } catch (err) {
    return rejectWithValue(err.data);
  }
});

export const updateClient = createAsyncThunk<
  CreateClientResponseModel,
  UpdateClientPayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/updateClient', async (payload, { dispatch, rejectWithValue }) => {
  const { bodyParams, clientId, callback } = payload;
  try {
    const response = await userApi.updateClient(bodyParams, clientId);

    callback && callback();

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error.data);
  }
});

export const getPromptsAlerts = createAsyncThunk<
  GetPromptsAlertsResponseModel,
  null,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/getPromptsAlerts', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await userApi.getPromptsAlerts();

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const setPromptsAlerts = createAsyncThunk<
  null,
  string,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/setPromptsAlerts', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await userApi.setPromptsAlerts({ settingPromptsAlerts: payload });

    return null;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const unsubscribeReleaseUser = createAsyncThunk<
  null,
  string,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/unsubscribeReleaseUser', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await userApi.unsubscribeReleaseUser({ hash: payload });

    return null;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const setProjectNotificationSettings = createAsyncThunk<
  null,
  Partial<ProjectSettingsModel>,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/setProjectNotificationSettings', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await userApi.setNotificationSettings(payload);

    return null;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getUserProfile = createAsyncThunk<
  GetUserProfileResponseModel,
  GetUserProfilePayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('userReducer/getUserProfile', async (payload, { dispatch, getState, rejectWithValue }) => {
  const { projectId, user_id } = payload;
  try {
    const response = await userApi.getUserProfile(projectId, user_id);

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});
