import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import HelpDrawerView from './HelpDrawerView';
import SVG from 'react-inlinesvg';
import { renderMedia } from '../MediaContent/MediaContent';
import { MoreInformationHelp, PlanitModulesHelp, UserRolesHelp, WhatIsNfHelp, WhatIsPlanitHelp } from './parts/helpContents/helpContents';
import { getQuickTour, getWhatNews, setSeenGettingStartedStatus } from '../../../store/support/supportThunk';
import { supportSlice } from '../../../store/support/supportSlice';
import { asideSlice } from '../../../store/aside/asideSlice';
import { setUserSeenReleaseSandboxTour, userInfo } from '../../../store/user/userThunk';
const { setProductTourIndex, setAsideMenuState } = asideSlice.actions;
const { handleHelpSubMenu, handleRunQuickTourNewUser, handleRunSandBoxQuickTour } = supportSlice.actions;

const mainMenu = {
  getting_started: {
    title: 'Getting started',
    alias: 'getting_started',
    subTitle: 'Everything you need to know to start working with Planit Scheduler .',
    img: <SVG src={require('../../../assets/icons/rocket.svg')} />,
  },
  quick_product_tour: {
    title: `Quick product tour`,
    alias: 'quick_product_tour',
    subTitle: 'Step-by-step guide through main functions of Planit Scheduler.',
    img: <SVG src={require('../../../assets/icons/product_tour.svg')} />,
  },
  glossary: {
    title: 'Glossary',
    alias: 'glossary',
    subTitle: 'Explanation of terminology used when working with Planit Scheduler.',
    img: <SVG src={require('../../../assets/icons/glossary.svg')} />,
  },
  user_manual: {
    title: "Users's manual",
    alias: 'user_manual',
    subTitle: 'Link to full documentation of all functions available in Planit Scheduler.',
    img: <SVG src={require('../../../assets/icons/manual.svg')} />,
  },
  faq: {
    title: 'FAQ',
    alias: 'faq',
    subTitle: 'Frequently asked questions.',
    img: <SVG src={require('../../../assets/icons/faq.svg')} />,
  },
  what_new: {
    title: `What's new`,
    alias: 'what_new',
    subTitle: 'Report of new features implemented in the latest updates.',
    img: <SVG src={require('../../../assets/icons/synchronize.svg')} />,
  },
  get_help: {
    title: 'Share feedback',
    alias: 'get_help',
    subTitle: 'Fill this form to share your feedback, ask a question or get support.',
    img: <SVG src={require('../../../assets/icons/feedback.svg')} />,
  },
};

class HelpDrawerContainer extends Component {
  state = {
    items: mainMenu,
    activeMenu: '',
    isRunQuickTour: false,
    steps: [],
    openedGetStartItem: null,
  };

  gettingStartedList = [
    {
      id: 0,
      name: 'What is Planit Scheduler?',
      title: 'What is Planit Scheduler?',
      component: <WhatIsPlanitHelp />,
    },
    {
      id: 1,
      name: 'User roles',
      title: 'User roles',
      component: <UserRolesHelp />,
    },
    {
      id: 2,
      name: 'Planit Scheduler modules',
      title: 'Planit Scheduler modules',
      component: <PlanitModulesHelp />,
    },
    {
      id: 3,
      name: 'What is NF?',
      title: 'What is NF',
      component: <WhatIsNfHelp />,
    },
    {
      id: 4,
      name: 'More information about Planit Scheduler',
      title: 'More information about Planit Scheduler',
      component: <MoreInformationHelp />,
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isRunQuickTourNewUser !== prevProps.isRunQuickTourNewUser) {
      this.setState({
        isRunQuickTour: this.props.isRunQuickTourNewUser,
      });
    }

    if (this.props.quickTour !== prevProps.quickTour) {
      let quickTour = Object.values(this.props.quickTour);
      quickTour = quickTour.map((tour, index) => {
        return {
          title: tour.title,
          target: tour.target,
          content: (
            <div>
              <div className={'b-helperDrawer__quickTourContent'} dangerouslySetInnerHTML={{ __html: tour.content }}></div>
              {renderMedia(tour.media)}
            </div>
          ),
          placement: index < 5 ? 'right-start' : 'bottom',
        };
      });
      this.setState({
        steps: quickTour,
      });
    }

    if (this.props.helpSubMenuToOpen && this.props.helpSubMenuToOpen !== prevProps.helpSubMenuToOpen) {
      this.openItem(this.props.helpSubMenuToOpen);
    }
  }

  openGetStartPopup = (item, index) => {
    this.setState({
      openedGetStartItem: item,
    });
  };

  closeGetStartPopup = () => {
    this.setState({
      openedGetStartItem: null,
    });
  };

  handleCloseDrawer = () => {
    this.props.handleCloseDrawer();
  };

  openItem = openedItem => {
    if (openedItem === 'getting_started') {
      // this.props.getGettingStarted();
    }
    if (openedItem === 'what_new') {
      this.props.getWhatNews();
    }
    this.setState({
      activeMenu: openedItem,
    });
  };

  openMoreItem = item => {
    this.handleReturnHome();
    this.openItem(item);
  };

  handleReturnHome = () => {
    this.setState({
      activeMenu: '',
      items: mainMenu,
    });
    this.handleChangeGettingStarted(false);
    this.closeGetStartPopup();
  };

  handleCloseSupport = () => {
    this.setState({
      activeMenu: '',
    });

    if (this.props.helpSubMenuToOpen === 'get_help') {
      this.props.handleCloseDrawer();
    }
    this.props.handleHelpSubMenu('');
  };

  joyrideCallback = async data => {
    if (data.action === 'reset' && this.props.isRunSandboxQuickTour) {
      this.props.handleRunSandBoxQuickTour(false);
      this.props.setUserSeenReleaseSandboxTour({ callback: () => this.props.userInfo() });
      //dispatch done tour
    }
    if (data.action === 'update') {
      const firstId = document.getElementsByClassName('react-joyride__beacon');
      firstId?.[0] && firstId[0].click();
    }
    if (this.state.isRunQuickTour && (data.action === 'close' || data.type === 'tour:end')) {
      await this.setState({
        isRunQuickTour: false,
      });
      // this.props.setSeenGettingStartedStatus();
      this.props.handleRunQuickTourNewUser(false);
    }
    this.setState({
      selectedJoyrideItemIndex: data.index,
    });
    if (!this.state.isRunQuickTour) {
      this.props.setProductTourIndex(null);
      this.props.setAsideMenuState(false);
    } else {
      this.props.setProductTourIndex(data.index);
    }
  };

  handleOpenProductTour = () => {
    this.setState({
      isRunQuickTour: true,
    });
    this.helpers.open();
    this.helpers.reset();

    if (window.innerWidth < 767 || window.innerWidth < 980) {
      this.handleCloseDrawer();
    }
  };

  getHelpers = helpers => {
    this.helpers = helpers;
  };

  handleChangeGettingStarted = item => {
    this.setState({
      isOpenGettingMenu: !!item,
    });
  };

  onBackdropClick = async () => {
    const { isOpenGettingMenu } = this.state;
    if (isOpenGettingMenu) {
      await this.setState({
        activeMenu: null,
      });
      await this.setState({
        activeMenu: 'getting_started',
        isOpenGettingMenu: false,
      });
      this.closeGetStartPopup();
    } else {
      this.handleCloseDrawer();
    }
  };

  openProductTourLinks = (item, index) => {
    this.openItem('getting_started');

    this.setState({
      isRunQuickTour: false,
      selectedJoyrideItemIndex: 0,
    });
    this.props.setProductTourIndex(null);
    this.props.handleRunQuickTourNewUser(false);
    if (index === 2) {
      this.openGetStartPopup(item);
      this.handleChangeGettingStarted(item);
    } else if (index === 3) {
      this.openGetStartPopup(item);
      this.handleChangeGettingStarted(item);
    }
  };

  render() {
    const { open, getGettingStartedStatus, isRunSandboxQuickTour } = this.props;
    const {
      activeMenu,
      items,
      isRunQuickTour,
      //   steps,
      selectedJoyrideItemIndex,
      isOpenGettingMenu,
      openedGetStartItem,
    } = this.state;

    const quickTour = [
      {
        target: '#selectProject-tourBlock',
        title: 'Active project selection',
        content: (
          <span>
            In the Planit Scheduler you can be assigned to multiple projects at once. Use this dropdown to switch between active projects.
          </span>
        ),
        placement: 'bottom-start',
      },
      {
        target: '#project-tourBlock',
        title: 'Project',
        content: <span>You can browse and set up available projects after clicking this button.</span>,
        placement: 'bottom-start',
      },
      {
        target: '#team-tourBlock',
        title: 'Teams',
        content: <span>Access and manage users, companies and disciplines assigned to your currently active project.</span>,
        placement: 'bottom-start',
      },
      {
        target: '#group-tourBlock',
        title: 'Module selection',
        content: (
          <span>
            Select one of five Scheduler’s modules for the highest efficiency of your work. Get to know more about the modules{' '}
            <span onClick={() => this.openProductTourLinks(this.gettingStartedList[2], 2)} className={'highlightedWord'}>
              here
            </span>
            .
          </span>
        ),
        placement: 'right',
      },
      {
        target: '#newNF-tourBlock',
        title: 'Adding new NF',
        content: (
          <span>
            Click here to create a new NF item. More about what are NFs you can read{' '}
            <span onClick={() => this.openProductTourLinks(this.gettingStartedList[3], 3)} className={'highlightedWord'}>
              here
            </span>
            .
          </span>
        ),
        placement: 'right-start',
      },
    ];
    const steps = quickTour.map((tour, index) => {
      return {
        title: tour.title,
        target: tour.target,
        content: (
          <div>
            <div className={'b-helperDrawer__quickTourContent'}>{tour.content}</div>
          </div>
        ),
        placement: tour.placement,
      };
    });

    const quickTourSandBox = [
      {
        target: '#sandBoxSections-tourBlock',
        title: 'Command center Updated UI',
        content: (
          <div>
            <p>Always find key NFs and deliverables at a glance.</p>
            <br />
            <p style={{ fontSize: '16px' }}>Categories (1/5)</p> <br />
            <p>Explore the primary categories, divided into two groups: NFs and Deliverables, for easy navigation.</p>
          </div>
        ),
        placement: 'right-start',
      },
      {
        target: '#sandBoxContainerTable-tourBlock',
        title: "'Hotlist' and 'In my court' (2/5)",
        content: (
          <div>
            <p style={{ fontSize: '16px' }}>🔥 Hotlist</p>
            <p>Keep an eye on the Hotlist.It houses items that demand immediate attention and action.</p>
            <br />
            <p style={{ fontSize: '16px' }}>🏀 In my court</p>
            <br />
            <p>Discover content relevant to you and your company.</p>
            <br />
            <p>Feel free to explore other categories as well!</p>
          </div>
        ),
        placement: 'right-start',
      },
      {
        target: '#sandBoxRequestsListHeader-tourBlock',
        title: 'Tabs (3/5)',
        content: (
          <span>
            <p>Utilize the additional subcategories for enhanced organization and management of your items.</p>
          </span>
        ),
        placement: 'bottom-start',
      },
      {
        target: '#sandBoxRelation-tourBlock',
        title: 'My items only (4/5)',
        content: (
          <span>
            <p>
              Toggle this button to switch between items exclusive <b>to you</b> or belonging <b>to your entire company.</b>
            </p>
          </span>
        ),
        placement: 'bottom-start',
      },
      {
        target: '#sandBoxGraph-tourBlock',
        title: 'Alerts and heatmaps (5/5)',
        content: (
          <span>
            <p>Stay informed with alerts about your items and visualize your calendar using the heatmap feature.</p>
          </span>
        ),
        placement: 'right-start',
      },
    ];
    const stepsSandbox = quickTourSandBox.map((tour, index) => {
      return {
        title: tour.title,
        target: tour.target,
        content: (
          <div>
            <div className={'b-helperDrawer__quickTourContent'}>{tour.content}</div>
          </div>
        ),
        placement: tour.placement,
      };
    });

    return (
      <HelpDrawerView
        handleCloseDrawer={this.handleCloseDrawer}
        open={open}
        openItem={this.openItem}
        items={items}
        activeMenu={activeMenu}
        handleReturnHome={this.handleReturnHome}
        mainMenu={mainMenu}
        handleCloseSupport={this.handleCloseSupport}
        getGettingStartedStatus={getGettingStartedStatus}
        joyrideCallback={this.joyrideCallback}
        isRunQuickTour={false /*isRunQuickTour || isRunSandboxQuickTour*/}
        handleOpenProductTour={this.handleOpenProductTour}
        steps={isRunSandboxQuickTour ? stepsSandbox : steps}
        getHelpers={this.getHelpers}
        selectedJoyrideItemIndex={selectedJoyrideItemIndex}
        handleChangeGettingStarted={this.handleChangeGettingStarted}
        isOpenGettingMenu={isOpenGettingMenu}
        onBackdropClick={this.onBackdropClick}
        openGetStartPopup={this.openGetStartPopup}
        openedGetStartItem={openedGetStartItem}
        gettingStartedList={this.gettingStartedList}
        closeGetStartPopup={this.closeGetStartPopup}
        openMoreItem={this.openMoreItem}
        isRunSandboxQuickTour={isRunSandboxQuickTour}
      />
    );
  }
}

const mapStateToProps = ({ commonReducer, userReducer, supportReducer, asideReducer }) => ({
  userData: userReducer,
  getGettingStartedStatus: supportReducer.getGettingStartedStatus,
  quickTour: supportReducer.quickTour,
  helpSubMenuToOpen: supportReducer.helpSubMenuToOpen,
  isRunQuickTourNewUser: supportReducer.isRunQuickTourNewUser,
  activeModule: asideReducer.activeModule,
  isRunSandboxQuickTour: supportReducer.isRunSandboxQuickTour,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // getGettingStarted,
      getWhatNews,
      setSeenGettingStartedStatus,
      setProductTourIndex,
      setAsideMenuState,
      handleHelpSubMenu,
      handleRunQuickTourNewUser,
      handleRunSandBoxQuickTour,
      setUserSeenReleaseSandboxTour,
      //getQuickTour,
      userInfo,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpDrawerContainer);
