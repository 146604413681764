import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import EmptyStatePlaceholder from '../../../../controls/EmptyStatePlaceholder/EmptyStatePlaceholder';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { CustomButton, SystemButton } from '../../../../controls/ButtonComponents';
import StatusRevision from '../../../../controls/Status/StatusRevision/StatusRevision';
import Divider from '../../../../controls/Divider/Divider';
import ReviewerSnippet from '../../../../controls/ReviewSnippet/ReviewSnippet';
import ReviewModel = PCDApi.ReviewModel;
import ReviewEditModel = PCDApi.ReviewEditModel;
import AccessHelper from '../../../../../helpers/AccessHelper';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import ReviewerEditTemplate from '../../../../controls/ReviewerEditTemplate/ReviewerEditTemplate';
import { SelectedRequestCompanyModel, FileModel } from '../../../../../models';
import { useCheckIfElementHaveScroll } from '../../../../../helpers/commonHooks';
import { isArrayEqual, isEmptyObject } from '../../../../../helpers/commonHelpers';
import * as validator from '../../../../../service/validator';
import { KeyValueNumberModel } from '../../../../../models/key-value.model';
import BaseHelper from '../../../../../helpers/BaseHelper';
import { FILE_SIZE_LIMIT } from '../../../../../service/file';
import { isEqual } from 'lodash';
import { ISubmittal, ISubmittalUpdateModel } from '../../../../../models/submittals/submittals.model';
import { editSubmittalReviews, submittalSave, updateReviewSubmittal } from '../../../../../store/submittal/submittalThunk';
import DateField from '../../../../controls/DateField/DateField';
import CustomCalendar from '../../../../controls/CustomCalendar/CustomCalendar';
import moment from 'moment/moment';
import Popover from '@material-ui/core/Popover';

import './ReviewStyles.scss';

interface IProps {
  selectedRequest: ISubmittal;
  isDragRequestCard: boolean;
  isAccess: boolean;
  isDeactivated: boolean;
  isCreateSubmittal: boolean;
  isHiddenFields: boolean;
  onCreateNewRevision: () => void;
  handleCloseAndDistribute: () => void;
  isPreviousRevisionDeactivated: boolean;
  isAnyReturnedReviews: boolean;
}

const disableEditStatuses: Array<string> = ['draft', 'closed', 'deactivated'];

const validationPatternReviewEdit = {
  required: ['party_id'],
  requiredOne: [],
};

const reviewToEditTemplate: ReviewEditModel = {
  id: 0,
  priority: 1,
  partySelectorData: null,
  note: '',
  days: 2,
  status: 'response_required',
  isNew: true,
};

const priorityOptions = [
  {
    value: 1,
    title: '1st reviewer',
  },
  {
    value: 2,
    title: '2nd reviewer',
  },
  {
    value: 3,
    title: '3rd reviewer',
  },
];

const ReviewTab = ({
  selectedRequest,
  isDragRequestCard,
  isAccess,
  isDeactivated,
  isCreateSubmittal,
  onCreateNewRevision,
  isHiddenFields,
  handleCloseAndDistribute,
  isPreviousRevisionDeactivated,
  isAnyReturnedReviews,
}: IProps) => {
  const dispatch = useAppDispatch();

  const themeClass = useGetThemeClass('b-cardSubmittal-reviewTab');

  const { is1440Resolution, isMobile } = GetResolution();

  const reviewListRef = useRef(null);

  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const editSubmittalReviewsLoading = useAppSelector<boolean>(state => state.submittalSlice.editSubmittalReviewsLoading);
  const updateReviewSubmittalLoading = useAppSelector<boolean>(state => state.submittalSlice.updateReviewSubmittalLoading);
  const submittalLoading = useAppSelector<boolean>(state => state.submittalSlice.isCardLoading);
  const updateReviewSubmittalId = useAppSelector(state => state.submittalSlice.updateReviewSubmittalId);
  const projectData = useAppSelector(state => state.phasePlan.projectData);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [moreLimitFiles, setMoreLimitFiles] = useState<FileModel[] | FileList | any>([]);

  const [reviewsToEdit, setReviewsToEdit] = useState<ReviewEditModel[] | null>(null);
  const [reviewsToEditErrors, setReviewsToEditErrors] = useState<KeyValueNumberModel<string[]> | null>(null);
  const [initialReviewsToEdit, setInitialReviewsToEdit] = useState<ReviewEditModel[] | null>(null);

  const [isOpenedCalendar, setIsOpenedCalendar] = useState(null);
  const [reviewRequiredDate, setReviewRequiredDate] = useState(null);

  const [editingReviews, setEditingReviews] = useState<KeyValueNumberModel<ReviewModel> | null>(null);
  const [initialEditingReviews, setInitialEditingReviews] = useState<KeyValueNumberModel<ReviewModel> | null>(null);

  const [loadingFilesName, setLoadingFilesName] = useState<SetStateAction<string>[]>([]);

  const reviewListHaveScroll = useCheckIfElementHaveScroll(reviewListRef);

  const reviews = useMemo(() => {
    return selectedRequest?.reviews || [];
  }, [selectedRequest]);

  const isSidePeek = useMemo(() => {
    return isDragRequestCard || is1440Resolution;
  }, [isDragRequestCard, is1440Resolution]);

  const isStatusAllowReviewEdit = useMemo(() => {
    return selectedRequest?.status && !disableEditStatuses.includes(selectedRequest.status.alias);
  }, [selectedRequest]);

  const isEmptyDueDate = useMemo(() => {
    return reviews?.some(item => item.hierarchy_priority === 1 && item.status === 'response_required');
  }, [reviews]);

  const hasReviews = useMemo(() => {
    return !!reviews?.length;
  }, [reviews]);

  const actualReviewsCount = useMemo(() => {
    return reviews?.filter(f => f.status !== 'no_action').length || 0;
  }, [reviews]);

  const pendingReviewsCount = useMemo(() => {
    return reviews?.filter(f => f.status === 'response_required').length || 0;
  }, [reviews]);

  const returnedReviewsCount = useMemo(() => {
    return reviews?.filter(f => f.status === 'revise_resubmit').length || 0;
  }, [reviews]);

  const approvedReviewsCount = useMemo(() => {
    return reviews?.filter(f => f.status === 'no_exceptions' || f.status === 'exceptions').length || 0;
  }, [reviews]);

  const snippetTypeByResolution = useMemo(() => {
    if (isMobile) {
      return 'mobile';
    }

    if (isSidePeek) {
      return 'portable';
    }

    return 'card';
  }, [isMobile, isSidePeek]);

  const isReviewDateChanged = useMemo(() => {
    return moment(selectedRequest?.review_required_due_date).format('MM/DD/YYYY') !== moment(reviewRequiredDate).format('MM/DD/YYYY');
  }, [reviewRequiredDate, selectedRequest]);

  const isOverdueDueDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const dueDate = new Date(reviewRequiredDate);
    dueDate.setHours(0, 0, 0, 0);

    return dueDate <= currentDate;
  }, [reviewRequiredDate]);

  const isEditReviewsChanged = useMemo(() => {
    if (reviewsToEdit && initialReviewsToEdit) {
      const sortedEditArray = [...reviewsToEdit].sort((a, b) => a.id - b.id);
      const sortedInitialEditArray = [...initialReviewsToEdit].sort((a, b) => a.id - b.id);

      return !isArrayEqual(sortedEditArray, sortedInitialEditArray);
    }
  }, [initialReviewsToEdit, reviewsToEdit]);

  useEffect(() => {
    if (selectedRequest) {
      setReviewRequiredDate(selectedRequest.review_required_due_date);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (isPreviousRevisionDeactivated) {
      setIsEditMode(false);
    }
  }, [isPreviousRevisionDeactivated]);

  useEffect(() => {
    let newReviewsToEdit;
    if (reviews?.length) {
      newReviewsToEdit = reviews.map(item => {
        return formatReviewForEdit(item);
      });
    } else {
      const editTemplate = {
        ...reviewToEditTemplate,
        id: `template-0`,
      };

      newReviewsToEdit = [editTemplate];
    }

    setReviewsToEdit(newReviewsToEdit);
    setInitialReviewsToEdit(newReviewsToEdit);
  }, [reviews]);

  const handleCloseCalendar = () => {
    setIsOpenedCalendar(null);
  };

  const handleOpenCalendar = e => {
    setIsOpenedCalendar(e.currentTarget);
  };

  const createReviewsTemplate = () => {
    let newIdNumber = 0;
    if (reviewsToEdit.length && typeof reviewsToEdit[reviewsToEdit.length - 1].id === 'string') {
      const currentIdNumber = parseInt(reviewsToEdit[reviewsToEdit.length - 1].id.split('-')[1]);
      newIdNumber = currentIdNumber + 1;
    } else {
      const newItemsLength = reviewsToEdit?.filter(item => item.isNew)?.length;
      newIdNumber = (newItemsLength || 0) + 1;
    }

    return {
      ...reviewToEditTemplate,
      id: `template-${newIdNumber}`,
    };
  };

  const getPartySelectorData = (review: ReviewModel): SelectedRequestCompanyModel => {
    return {
      company: review.party
        ? {
            ...review.party,
            id: review.party.id,
            title: review.party.company,
          }
        : null,
      discipline: review.discipline
        ? {
            id: review.discipline.id,
            title: review.discipline.title || '',
            text_color: review.discipline.text_color || '',
          }
        : null,
      user: review.user
        ? {
            ...review.user,
            id: review.user.id,
            title: `${review.user.profile.first_name} ${review.user.profile.last_name}`,
          }
        : null,
    };
  };

  const formatReviewForEdit = (review: ReviewModel): ReviewEditModel => {
    return {
      id: review.id || 0,
      priority: review.hierarchy_priority || 1,
      partySelectorData: getPartySelectorData(review),
      note: review.note || '',
      days: review.days || 2,
      status: review.status,
    };
  };

  const onChangeEditReview = (id: number | string, fieldName: string, value) => {
    const newReviewsToEdit = [...reviewsToEdit];
    const reviewIndex = reviewsToEdit.findIndex(item => item.id === id);
    let reviewObject = { ...reviewsToEdit[reviewIndex] };

    if (fieldName === 'party') {
      reviewObject = {
        ...reviewObject,
        partySelectorData: value,
      };
    } else {
      reviewObject = {
        ...reviewObject,
        [fieldName]: value,
      };
    }

    setReviewsToEditErrors({
      ...(reviewsToEditErrors || {}),
      [id]: null,
    });

    newReviewsToEdit.splice(reviewIndex, 1, reviewObject);

    setReviewsToEdit(newReviewsToEdit);
  };

  const addReview = () => {
    const editTemplate = createReviewsTemplate();

    setReviewsToEdit([...reviewsToEdit, editTemplate]);

    setTimeout(() => {
      if (reviewListRef.current) {
        reviewListRef.current.scrollTo({ top: reviewListRef.current.scrollHeight, behavior: 'smooth' });
      }
    }, 200);
  };

  const deleteReview = (id: number) => {
    const newReviewsToEdit = [...reviewsToEdit];
    const reviewIndex = reviewsToEdit.findIndex(item => item.id === id);

    newReviewsToEdit.splice(reviewIndex, 1);

    setReviewsToEdit(newReviewsToEdit);
  };

  const handleSaveReviewsToEdit = async () => {
    await handleSaveReviewDate();

    const errorsObject = {};

    const reviews = reviewsToEdit.map(item => {
      const validationData = {
        id: item.isNew ? 0 : item.id,
        discipline_id: item.partySelectorData?.discipline?.id,
        party_id: item.partySelectorData?.company?.id,
        user_id: item.partySelectorData?.user?.id,
        note: item.note,
        hierarchy_priority: !item.priority ? 1 : item.priority,
        days: item.days === 0 ? item.days : item.days || 2,
      };

      if (!validationData.discipline_id) {
        delete validationData.discipline_id;
      }

      const { errors } = validator.validate(validationPatternReviewEdit, validationData);
      const errorsKeys = Object.keys(errors);
      const partyErrorIndex = errorsKeys.findIndex(key => key === 'party_id');

      if (partyErrorIndex !== -1) {
        errorsKeys.splice(partyErrorIndex, 1, 'company');
      }

      if (!isEmptyObject(errors)) {
        errorsObject[item.id] = errorsKeys;
      }

      return validationData;
    });

    if (!isEmptyObject(errorsObject)) {
      setReviewsToEditErrors(errorsObject);
      return;
    }

    const data = {
      reviews: reviews,
      owner_id: selectedRequest.id,
      owner_type: 'submittal',
      project_id: Number(sessionStorage.getItem('active_project_id')),
    };

    dispatch(
      editSubmittalReviews({
        data,
        callback: () => {
          setIsEditMode(false);
          setReviewsToEditErrors(null);
        },
      }),
    );
  };

  const handleSaveReviewDate = async () => {
    if (isReviewDateChanged) {
      await dispatch(
        submittalSave({
          submittalId: selectedRequest?.id,
          bodyParams: {
            mf_id: selectedRequest.mf_id,
            status: selectedRequest.status.alias,
            type_id: selectedRequest?.type?.id,
            review_required_due_date: moment(reviewRequiredDate).format('YYYY-MM-DD'),
          },
          isNotFormData: true,
        }),
      );
    }
  };

  const onChangeEditingReview = (review: ReviewModel, fieldName: 'desc' | 'status', value: string) => {
    const isInEditArray = !!initialEditingReviews?.[review.id];

    let reviewObj = { ...review };

    if (!isInEditArray) {
      setInitialEditingReviews({
        ...(initialEditingReviews || {}),
        [review.id]: reviewObj,
      });
    }

    reviewObj = {
      ...reviewObj,
      [fieldName]: value,
    };

    // This check updating initial state of the desc with accurate quill html text
    if (fieldName === 'desc' && !reviewObj.isDescChanged) {
      reviewObj = {
        ...reviewObj,
        isDescChanged: true,
      };

      setInitialEditingReviews({
        ...(initialEditingReviews || {}),
        [review.id]: reviewObj,
      });

      setEditingReviews({
        ...(editingReviews || {}),
        [review.id]: reviewObj,
      });

      return;
    }

    setEditingReviews({
      ...(editingReviews || {}),
      [review.id]: reviewObj,
    });
  };

  const saveEditingReview = (editingReview: ReviewModel, files?, filesToDelete?: number[]) => {
    const data = {
      id: editingReview.id,
      status: editingReview.status,
      desc: editingReview.desc,
      deliverable_id: selectedRequest.id,
      owner_type: 'submittal',
    };

    if (files) {
      data['upload_files'] = Array.from(files).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    }

    if (filesToDelete) {
      data['delete_files'] = filesToDelete;
    }

    dispatch(
      updateReviewSubmittal({
        data,
        callback: () => {
          const newEditingReviews = { ...(editingReviews || {}) };
          const newInitialEditingReviews = { ...(initialEditingReviews || {}) };

          delete newEditingReviews[editingReview.id];
          delete newInitialEditingReviews[editingReview.id];

          setEditingReviews(newEditingReviews);
          setInitialEditingReviews(newInitialEditingReviews);
          setLoadingFilesName(null);
        },
      }),
    );
  };

  const handleMoreLimitFiles = files => {
    setMoreLimitFiles(files);
  };

  const handleFile = (e, review: ReviewModel, isDrag?, isRestrict?) => {
    let filesEl;

    if (isDrag) {
      if (isRestrict) {
        return;
      }
      filesEl = e;
    } else {
      filesEl = e.target.files;
    }

    if (!filesEl?.length) {
      return;
    }

    const files: any = Array.from(filesEl).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    const moreLimitFiles: any = Array.from(filesEl).filter((f: any) => f.size > FILE_SIZE_LIMIT);

    files.map(f => (f.isNew = true));

    if (files?.length > 0) {
      setEditingReviews({
        ...(editingReviews || {}),
        [review.id]: {
          ...review,
          files: [...(review.files || []), ...(files || [])],
        },
      });
      setLoadingFilesName(Array.from(filesEl).map(el => el.name));

      saveEditingReview(review, filesEl);
    }
    if (moreLimitFiles?.length > 0) {
      handleMoreLimitFiles(moreLimitFiles);
    }
  };

  const delSavedFile = (editingReview: ReviewModel, file_id: number | number[], isBulk?) => {
    saveEditingReview(editingReview, null, isBulk ? file_id : [file_id]);
  };

  const handleDownloadAll = (e: MouseEvent, review: ReviewModel) => {
    e.preventDefault();
    review.files.map((file, i) => {
      setTimeout(async () => {
        const fileName = `revision#${selectedRequest?.id}_reviewer#${review.id}_req_${file.title}`;
        await BaseHelper.downloadFile(file.file, fileName);
      }, i * 50);
    });
  };

  const getReviewsByPriority = (priority: number) => {
    if (reviews?.length) {
      return reviews.filter(item => item.hierarchy_priority === priority);
    }

    return [];
  };

  const getIsReviewEditable = (review: ReviewModel) => {
    const isReviewEdit =
      (review.hierarchy_priority === 3 && review.due_date && !isEmptyDueDate && isStatusAllowReviewEdit) ||
      (review.hierarchy_priority !== 3 && review.due_date && isStatusAllowReviewEdit);

    const isAccessToEditReview = AccessHelper.isAccessToEditReview(userInfo, review);

    return isReviewEdit && isAccessToEditReview && !isDeactivated && !isPreviousRevisionDeactivated;
  };

  const getReviewDisabledTooltip = (isReviewEditable: boolean) => {
    let disabledReviewToolTipText = '';

    if (!isReviewEditable) {
      disabledReviewToolTipText = `Can't save response. Waiting for higher-level reviewers to complete their review.`;
    }

    return disabledReviewToolTipText;
  };

  const renderEmptyContainer = () => {
    const subText = isMobile ? (
      'There are no reviewers assigned to this submittal yet.'
    ) : (
      <>
        {`There are no reviewers assigned`}
        <br />
        to this submittal yet.
      </>
    );

    return (
      <div className={`${themeClass}_emptyContent`}>
        <div className={`${themeClass}_emptyContent_container`}>
          <EmptyStatePlaceholder type={'nf-card-linked-nfs'} isMobile={isMobile} />
          <div className={`${themeClass}_emptyContent_placeholderContent`}>
            <div className={`${themeClass}_emptyContent_placeholderContent_header`}>
              <div className={`${themeClass}_emptyContent_placeholderContent_header_title`}>Reviewers</div>
              <div className={`${themeClass}_emptyContent_placeholderContent_header_subtitle`}>{subText}</div>
            </div>
            <div className={`${themeClass}_emptyContent_placeholderContent_header_footer`}>
              <CustomButton
                type={'secondary'}
                size={'sm'}
                title={'Add Reviewer'}
                clickHandler={() => setIsEditMode(true)}
                disabled={!isAccess || isDeactivated || isPreviousRevisionDeactivated}
                isMobile={isMobile}
                customClassName={`${themeClass}_emptyContent_placeholderContent_header_footer_button`}
                customTooltipBasisClassName={`${themeClass}_emptyContent_placeholderContent_header_footer_buttonTooltip`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isReviewDateInvalid =
    (selectedRequest?.status?.alias === 'draft' ||
      (selectedRequest?.status?.alias === 'open' && selectedRequest?.review_state === 'pending')) &&
    isOverdueDueDate;

  return (
    <div
      className={cn(`${themeClass}`, {
        [`-sidePeek`]: isSidePeek,
        [`-isHiddenFields`]: isHiddenFields,
        [`-isMobile`]: isMobile,
      })}
    >
      {hasReviews || isEditMode ? (
        <div className={`${themeClass}_container`}>
          {isEditMode ? (
            <div className={`${themeClass}_editHeader`}>
              <div className={`${themeClass}_editHeader_left`}>
                <CustomButton
                  type={'tertiary'}
                  size={'sm'}
                  title={'Add Reviewer'}
                  clickHandler={addReview}
                  isMobile={isMobile}
                  customClassName={`${themeClass}_editHeader_button`}
                  customTooltipBasisClassName={`${themeClass}_editHeader_buttonTooltip`}
                />
                <div className={`${themeClass}_header_date`}>
                  <span className={`${themeClass}_header_date_text`}>Required approval date</span>
                  <DateField
                    selectedDate={moment(reviewRequiredDate).format('MM/DD/YYYY')}
                    valueIsDate={!!reviewRequiredDate}
                    clickHandler={handleOpenCalendar}
                    isOpenCalendar={!!isOpenedCalendar}
                    info={isReviewDateInvalid}
                  />
                  <Popover
                    open={!!isOpenedCalendar}
                    anchorEl={isOpenedCalendar}
                    onClose={handleCloseCalendar}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <CustomCalendar value={reviewRequiredDate} work_week={projectData?.work_week} handleChange={setReviewRequiredDate} />
                  </Popover>
                </div>
              </div>
              <div className={`${themeClass}_editHeader_buttonsContainer`}>
                <CustomButton
                  type={'tertiary'}
                  size={'sm'}
                  title={'Cancel'}
                  clickHandler={() => setIsEditMode(false)}
                  isMobile={isMobile}
                  customClassName={`${themeClass}_editHeader_button`}
                  customTooltipBasisClassName={`${themeClass}_editHeader_buttonTooltip`}
                />
                <CustomButton
                  loading={editSubmittalReviewsLoading}
                  type={'secondary'}
                  size={'sm'}
                  title={'Save'}
                  clickHandler={handleSaveReviewsToEdit}
                  disabled={!isEditReviewsChanged && !isReviewDateChanged}
                  isMobile={isMobile}
                  customClassName={`${themeClass}_editHeader_button`}
                  customTooltipBasisClassName={`${themeClass}_editHeader_buttonTooltip`}
                />
              </div>
            </div>
          ) : (
            <div className={`${themeClass}_header`}>
              <div className={`${themeClass}_header_date`}>
                <span className={`${themeClass}_header_date_text`}>Required approval date</span>
                <DateField
                  selectedDate={moment(reviewRequiredDate).format('MM/DD/YYYY')}
                  valueIsDate={!!reviewRequiredDate}
                  isDisableBorder
                  info={isReviewDateInvalid}
                />
              </div>
              <CustomButton
                type={'text-plain'}
                size={'sm'}
                title={'Edit Reviewers'}
                clickHandler={() => setIsEditMode(true)}
                disabled={!isAccess || selectedRequest?.status?.alias === 'closed' || isDeactivated || isPreviousRevisionDeactivated}
                isMobile={isMobile}
                customClassName={`${themeClass}_header_button`}
                customTooltipBasisClassName={`${themeClass}_header_buttonTooltip`}
              />
            </div>
          )}
          <div
            ref={reviewListRef}
            className={cn(`${themeClass}_reviewList`, {
              ['-edit']: isEditMode,
              ['-scroll']: reviewListHaveScroll,
            })}
          >
            {isEditMode ? (
              <>
                {reviewsToEdit?.map(review => {
                  return (
                    <div key={review.id} className={`${themeClass}_reviewList_editItem`}>
                      <ReviewerEditTemplate
                        type={snippetTypeByResolution}
                        reviewId={review.id}
                        selectPriority={value => onChangeEditReview(review.id, 'priority', value)}
                        selectedPriority={review.priority}
                        selectedParty={review.partySelectorData}
                        selectParty={selected => onChangeEditReview(review.id, 'party', selected)}
                        daysToRespond={review.days}
                        setDaysToRespond={value => onChangeEditReview(review.id, 'days', value)}
                        status={review.status}
                        noteToReviewer={review.note}
                        setNoteToReviewer={value => onChangeEditReview(review.id, 'note', value)}
                        errors={reviewsToEditErrors?.[review.id]}
                        deleteReview={() => deleteReview(review.id)}
                        isSubmittal
                      />
                      {!isMobile ? (
                        <SystemButton type={'delete'} size={'md'} variant={'transparent'} clickHandler={() => deleteReview(review.id)} />
                      ) : null}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {priorityOptions.map(option => {
                  const reviewsByPriority = getReviewsByPriority(option.value);
                  const paddingValue = isMobile ? 12 : 40;
                  const paddingByPriority = (option.value - 1) * paddingValue;

                  if (!reviewsByPriority.length) {
                    return null;
                  }

                  return (
                    <div key={option.value} className={`${themeClass}_reviewList_group`} style={{ paddingLeft: paddingByPriority }}>
                      <div className={`${themeClass}_reviewList_group_header`}>
                        <span className={`${themeClass}_reviewList_group_header_label`}>{option.title}</span>
                        <Divider direction={'horizontal'} type={'srf-5'} />
                      </div>
                      <div className={`${themeClass}_reviewList_group_content`}>
                        {reviewsByPriority.map(review => {
                          const isReviewEditable = getIsReviewEditable(review);
                          const reviewDisabledTooltip = getReviewDisabledTooltip(isReviewEditable);
                          const editingReview = editingReviews?.[review.id];
                          const initialEditingReview = initialEditingReviews?.[review.id];
                          const showSaveButton = editingReview && initialEditingReview && !isEqual(editingReview, initialEditingReview);
                          const currentReview = editingReview ? editingReview : review;

                          return (
                            <ReviewerSnippet
                              key={currentReview.id}
                              type={snippetTypeByResolution}
                              selectedPriority={currentReview.hierarchy_priority}
                              selectedUserProfile={currentReview.user?.profile}
                              reviewerUserProfile={currentReview.updated_by_user?.profile}
                              updateDate={currentReview.update_date}
                              companyInfo={currentReview.party}
                              disciplineInfo={currentReview.discipline}
                              dueDate={currentReview.due_date}
                              reviewNote={currentReview.note}
                              selectedReviewStatus={currentReview.status}
                              setSelectedReviewStatus={value => onChangeEditingReview(currentReview, 'status', value)}
                              isReviewEditable={isReviewEditable}
                              selectedFiles={currentReview.files}
                              handleFile={(e, isDrag, isRestrict) => handleFile(e, currentReview, isDrag, isRestrict)}
                              handleDownloadAll={e => handleDownloadAll(e, currentReview)}
                              delSavedFile={(file_id, isBulk) => delSavedFile(currentReview, file_id, isBulk)}
                              loadingFilesName={loadingFilesName}
                              loadingReview={updateReviewSubmittalLoading && updateReviewSubmittalId === currentReview.id}
                              reviewDescription={currentReview.desc}
                              setReviewDescription={value => onChangeEditingReview(currentReview, 'desc', value)}
                              tooltip={reviewDisabledTooltip}
                              showSaveButton={showSaveButton}
                              saveReview={() => saveEditingReview(currentReview)}
                              selectedRequest={selectedRequest}
                              moreLimitFiles={moreLimitFiles}
                              handleMoreLimitFiles={handleMoreLimitFiles}
                              isSidePeek={isSidePeek}
                              isSubmittal
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {reviewListHaveScroll ? (
              <div className={`${themeClass}_contentDivider`}>
                <Divider direction={'horizontal'} type={'srf-5'} />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        renderEmptyContainer()
      )}
    </div>
  );
};

export default ReviewTab;
