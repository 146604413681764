import { BaseApi } from './baseApi';

class DocumentsApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getMainSetDDL(id, data) {
    return await this._post({
      route: `/ddl/${id}/main-ddl`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { ...data },
    });
  }

  async getTypeDDLSet(id, data) {
    return await this._get({
      route: `/ddl/get-type-document-set`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { ...data },
    });
  }

  async setMainSetDDLFilters(data, id) {
    return await this._post({
      route: `/ddl/${id}/main-ddl`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { ...data },
    });
  }

  async getDDLlistRequest(id_doc) {
    return await this._get({
      route: `/ddl/${id_doc}/list-request`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getDDLlistDocuments(id, selectedRequestId) {
    return await this._get({
      route: `/ddl/${id}/${selectedRequestId}/list-documents`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getDDLlistDocumentsNoNF(id) {
    return await this._get({
      route: `/ddl/${id}/list-documents`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async setStatusNF(data, selectedRequestId) {
    return await this._post({
      route: `/ddl/${selectedRequestId}/set-status-nf`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async getDDLColumnSettings() {
    return await this._get({
      route: `/ddl/get-column-settings`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async setDDLColumnSettings(data) {
    return await this._post({
      route: `/ddl/set-column-settings`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async getFilePost(url, body) {
    return await this._getFilePost(url, body);
  }

  async deleteDocument(data) {
    return this._delete({
      route: `/ddl/delete-document`,
      needAuth: true,
      bodyParams: data,
    });
  }

  async createDocument(data) {
    return await this._post({
      route: `/ddl/create-document`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async updateDocument(data) {
    return await this._post({
      route: `/ddl/update-document`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async deleteSet(data) {
    return this._delete({
      route: `/ddl/delete-set`,
      needAuth: true,
      bodyParams: data,
    });
  }

  async createSet(data) {
    return await this._post({
      route: `/ddl/create-set`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async updateSet(data) {
    return await this._post({
      route: `/ddl/update-set`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async publicSet(data) {
    return await this._post({
      route: `/ddl/public-set`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }
}

export default new DocumentsApi({ devMode: false });
