import React, { ChangeEvent, Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { TextInputNew } from '../../../../../../controls';
import DataImport from '../../../../../../controls/DataImport/DataImport';
import { DataGridBasic } from '../../../../../DataGridBasic';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import { CustomButton } from '../../../../../../controls/ButtonComponents';
import TokenIcon from '../../../../../../controls/TokenIcon/TokenIcon';
import TypicalEditor from '../TypicalEditor/TypicalEditor';
import { BuildingModel, LocationModel, ProjectModel, SimpleTitleModel, TypicalLayoutModel } from '../../../../../../../models';
import DialogPopUp from '../../../../../../controls/DialogPopUp/DialogPopUp';

import './LocationEditorStyles.scss';

interface IProps {
  locations: LocationModel[];
  handleTypicalLayout: (e) => void;
  isTypicalLayout: boolean;
  handleCloseTypicalLayout: () => void;
  typical_layouts: TypicalLayoutModel[];
  updateTypical: (ranges) => void;
  building: BuildingModel<SimpleTitleModel[]>;
  columns: any[];
  selectedRows: any[];
  setSelectedRows: Dispatch<SetStateAction<any[]>>;
  typicalLayoutCross: TypicalLayoutModel[];
  closeTypicalLayoutCross: () => void;
  removeCrossedTypical: () => Promise<any>;
  actionChangeTypicalCross: 'add' | 'remove';
  gridRef: MutableRefObject<any>;
  project: ProjectModel;
  changedFields: { [key: string]: any };
  countNewFloors: {
    title: string;
    count: string;
  };
  onChangeNewFloors: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAddFloors: () => void;
  isDataImportOpen: boolean;
  openDataImport: () => void;
  closeDataImport: () => void;
}

const LocationEditorView: FC<IProps> = ({
  locations,
  handleTypicalLayout,
  isTypicalLayout,
  handleCloseTypicalLayout,
  typical_layouts,
  updateTypical,
  building,
  columns,
  selectedRows,
  setSelectedRows,
  typicalLayoutCross,
  closeTypicalLayoutCross,
  removeCrossedTypical,
  actionChangeTypicalCross,
  gridRef,
  project,
  changedFields,
  countNewFloors,
  onChangeNewFloors,
  handleAddFloors,
  isDataImportOpen,
  openDataImport,
  closeDataImport,
}) => {
  const themeClass = useGetThemeClass('b-locationEditorView');
  const dialogClass = useGetThemeClass('dialogPopUp');

  return (
    <div className={themeClass}>
      <div className={`${themeClass}__header`}>
        <div className={`${themeClass}__headerLeft`}>
          <div className={`${themeClass}__levelInput`}>
            <TextInputNew
              type={'on-bgd'}
              name="title"
              inputType={'text'}
              placeholder={'Level'}
              value={countNewFloors.title}
              onChange={value => onChangeNewFloors('title', value)}
            />
          </div>
          <div className={`${themeClass}__countInput`}>
            <TextInputNew
              type={'on-bgd'}
              name="count"
              inputType={'number'}
              placeholder={'1'}
              value={countNewFloors.count}
              onChange={value => onChangeNewFloors('count', value)}
            />
          </div>
          <CustomButton size={'sm'} type={'tertiary'} title={`Create New`} clickHandler={handleAddFloors} />
        </div>
        <div className={`${themeClass}__headerRight`}>
          {building.id && (
            <CustomButton
              size={'sm'}
              type={'text-plain'}
              title={`Import Custom Data`}
              iconClass={`${themeClass}__btnIcon`}
              icon={<TokenIcon iconName={'upload'} size={12} />}
              clickHandler={openDataImport}
            />
          )}
          <CustomButton
            size={'sm'}
            type={'tertiary'}
            title={`Typical layouts`}
            clickHandler={handleTypicalLayout}
            disabled={!locations.filter(loc => loc.id).length}
            tooltip={!locations.filter(loc => loc.id).length ? 'Create building first' : ''}
          />
        </div>
      </div>
      <div className={`${themeClass}__tableWrap`}>
        <DataGridBasic
          rowKeyGetter={(row: any) => row.id || row.custom_id}
          columns={columns}
          rows={locations || []}
          tabelOutRef={gridRef}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowHeight={52}
          headerRowHeight={36}
          editingRowIds={selectedRows}
          setEditingRowsAction={({ editingRowIds }) => setSelectedRows(editingRowIds)}
          isBulkEditMode
          changedFields={changedFields}
          isDeletable={false}
        />
      </div>
      <TypicalEditor
        locations={locations}
        typical_layouts={typical_layouts}
        updateTypical={updateTypical}
        isTypicalLayout={isTypicalLayout}
        handleCloseTypicalLayout={handleCloseTypicalLayout}
        building={building}
      />
      <DialogPopUp
        title={`${actionChangeTypicalCross === 'add' ? 'Add' : 'Remove'} a level?`}
        dividedHeader
        open={!!typicalLayoutCross?.length}
        onClose={closeTypicalLayoutCross}
        secondaryText={'Cancel'}
        handleOnSecondary={closeTypicalLayoutCross}
        primaryText={'Remove'}
        handleOnPrimary={removeCrossedTypical}
        renderModalContent={() => (
          <div className={`${dialogClass}_content_modalText`}>
            You are trying to {actionChangeTypicalCross} a level that will affect typical ranges:
            <br />
            <br />
            {locations.length &&
              typicalLayoutCross &&
              typicalLayoutCross.map((t, i) => {
                return (
                  <div key={`cross-${i}`}>
                    {locations[t.start_position]?.title} - {locations[t.end_position]?.title};
                  </div>
                );
              })}
            <br />
            Do you want to proceed?
          </div>
        )}
      />
      <DataImport
        isImportOpen={isDataImportOpen}
        closeImportPopUp={closeDataImport}
        importName={'Building Spaces'}
        importType={'building-spaces'}
        building={building}
        project_id={project.id}
      />
    </div>
  );
};

export default LocationEditorView;
