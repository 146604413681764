import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';

import './StatusUniformatStyles.module.scss';

const StatusUniformat: FC<{
  status: 'included' | 'excluded';
}> = ({ status }) => {
  const themeClass = useGetThemeClass('b-statusUniformat');

  return (
    <>
      <div className={`${themeClass} ${status}`}>
        {status === 'included' && <TokenIcon iconName={'check'} size={16} />}
        {status === 'excluded' && <TokenIcon iconName={'dash'} size={16} />}
      </div>
    </>
  );
};

export default React.memo(StatusUniformat);
