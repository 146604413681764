import React, { FC, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useGetThemeClass } from '../../../helpers/designTokens';
import ProfileView from '../ProfileView/ProfileView';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { getUserProfile } from '../../../store/user/userThunk';
import ProfileEdit from '../ProfileEdit/ProfileEdit';
import { UserReducerModel } from '../../../store/user/userReducerModel';

import './ProfilePopoverStyles.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  type: 'view' | 'edit';
  projectId: number;
  user_id: number;
  onSaveSettings?: () => void;
  isOnClose?: boolean;
  handleOnClose?: () => void;
}

const ProfilePopover: FC<IProps> = ({ open, onClose, type, projectId, user_id, onSaveSettings, isOnClose, handleOnClose }) => {
  const themeClass = useGetThemeClass('b-profilePopover');
  const dispatch = useAppDispatch();
  const user_data = useAppSelector(state => state.userReducer);
  const is_get_user_profile = useAppSelector(state => state.userReducer.is_get_user_profile);
  const [selectedUser, setSelectedUser] = useState<UserReducerModel>(null);

  useEffect(() => {
    setSelectedUser(null);
    dispatch(getUserProfile({ projectId, user_id }));
  }, []);

  useEffect(() => {
    if (!is_get_user_profile) {
      // setSelectedUser(null);
      if (user_data?.user_profile && user_data?.user_profile.id === user_id) {
        setSelectedUser(user_data);
      }
    } else {
      isOnClose && setSelectedUser(null);
    }
  }, [user_data, is_get_user_profile]);

  if (selectedUser && selectedUser.user_profile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor={'right'}
        disableEnforceFocus
        classes={{
          paper: `${themeClass} ${type === 'view' ? '-view' : '-edit'}`,
        }}
      >
        {type === 'view' ? (
          <ProfileView handleClose={onClose} userData={selectedUser.user_profile} />
        ) : (
          <ProfileEdit
            isPopover={true}
            handleClose={onClose}
            userData={selectedUser}
            onSaveSettings={onSaveSettings}
            handleOnClose={handleOnClose}
          />
        )}
      </Drawer>
    );
  } else {
    return <></>;
  }
};
export default ProfilePopover;
