import React, { Component } from 'react';

class Update_20211125_7 extends Component {
  render() {
    return (
      <>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <img
            style={{ margin: '0 0 10px 15px' }}
            className={'asideBigImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4f21cde1fe55004e062911616fdba2f9/images/image8.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20211125_7;
