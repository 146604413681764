import React, { FC } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';

import './ColorPickerStyles.module.scss';

const ColorPicker: FC<{
  type?: 'colorTags' | null;
  onChangeColor?: (color: string) => void;
}> = ({ type, onChangeColor }) => {
  const themeClass = useGetThemeClass('b-colorPicker');

  const handleChangeColor = (color: string) => {
    onChangeColor && onChangeColor(color);
  };

  const getClassName = (color: string) => {
    return cn(`${themeClass}_item ${color}`, { ['-colorTag']: type === 'colorTags' });
  };

  return (
    <>
      <div className={`${themeClass}`}>
        <div className={getClassName('pink')} onClick={() => handleChangeColor('pink')}></div>
        <div className={getClassName('peach')} onClick={() => handleChangeColor('peach')}></div>
        <div className={getClassName('sand')} onClick={() => handleChangeColor('sand')}></div>
        <div className={getClassName('lime')} onClick={() => handleChangeColor('lime')}></div>
        <div className={getClassName('blue')} onClick={() => handleChangeColor('blue')}></div>
        <div className={getClassName('indigo')} onClick={() => handleChangeColor('indigo')}></div>
        <div className={getClassName('purple')} onClick={() => handleChangeColor('purple')}></div>
        <div className={getClassName('gray')} onClick={() => handleChangeColor('gray')}></div>
      </div>
    </>
  );
};

export default React.memo(ColorPicker);
