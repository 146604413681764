import React, { FC, useMemo } from 'react';
import { PCDApi } from '../../../../../../../service/Api/pcd/types';
import PCDOpenNfsModel = PCDApi.PCDOpenNfsModel;
import DialogPopUp from '../../../../../../controls/DialogPopUp/DialogPopUp';
import { DataGridBasic } from '../../../../../DataGridBasic';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import { statusNFConst } from '../../../../../../../constants/cardTypeConstants';

import './OpenedNfsPopupStyles.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  openedNfs: PCDOpenNfsModel[];
  isReview: boolean;
}

const OpenedNfsPopup: FC<IProps> = ({ openedNfs, isOpen, handleClose, isReview }) => {
  const themeClass = useGetThemeClass('b-openedNfsPopup');

  const getStatusName = status => {
    if (status === statusNFConst.submitted || status === statusNFConst.submitted_under) {
      return 'Commitment submitted';
    } else if (status === statusNFConst.drafted) {
      return 'Request drafted';
    } else if (status === statusNFConst.sent || status === statusNFConst.new) {
      return 'Request sent';
    } else if (status === statusNFConst.deactivated) {
      return 'Request deactivated';
    } else if (status === statusNFConst.provided) {
      return 'Response submitted';
    } else if (status === statusNFConst.declined) {
      return 'Response declined';
    } else if (status === statusNFConst.reOpened) {
      return 'Request Re-opened';
    } else if (status === statusNFConst.accepted) {
      return 'Response accepted';
    } else if (status === statusNFConst.closed) {
      return 'Closed';
    }
  };

  const renderFormatter = p => {
    if (p.column.key === 'nf') {
      return <div className={`${themeClass}_table_cell_text`}>{`NF#${p.row.nf}`}</div>;
    }
    if (p.column.key === 'status') {
      return <div className={`${themeClass}_table_cell_text`}>{getStatusName(p.row.status)}</div>;
    }
    return <div className={`${themeClass}_table_cell_text`}>{p.row[p.column.key]}</div>;
  };

  const columns = useMemo(() => {
    return [
      {
        key: 'nf',
        name: 'NF',
        width: 64,
        formatter: renderFormatter,
      },
      {
        key: 'subject',
        name: 'Subject',
        width: 237,
        formatter: renderFormatter,
      },
      {
        key: 'status',
        name: 'NF status',
        width: 190,
        formatter: renderFormatter,
      },
    ];
  }, [openedNfs]);

  return (
    <DialogPopUp
      open={isOpen}
      onClose={handleClose}
      dividedHeader
      paperMaxWidth={'539px'}
      title={
        isReview ? 'Outstanding NFs linked to Deliverable at time of review' : 'Outstanding NFs linked to Deliverable at time of issue'
      }
      renderModalContent={() => (
        <div className={`${themeClass}_table`}>
          <DataGridBasic rows={openedNfs || []} columns={columns} borderType={'on-srf'} isManageStatusesPopUp />
        </div>
      )}
      secondaryText={'Exit'}
      handleOnSecondary={handleClose}
    />
  );
};

export default OpenedNfsPopup;
