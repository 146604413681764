import { createLogic } from 'redux-logic';
import * as actions from './supportAction';
import * as userActions from '../user/userActions';
import requestApi from '../../service/Api/requestApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { KeyValueNumberModel } from '../../models/key-value.model';
import { ISupportWhatNew } from './supportReducer.model';
import { userSlice } from '../user/userSlice';
import { errorsSlice } from '../errors/errorsSlice';
const { reduceNewsCount, zeroNewsCount } = userSlice.actions;
const { setShowErrorData } = errorsSlice.actions;

const getQuickTourLogic = createLogic({
  type: actions.GET_QUICK_TOUR,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .getQuickTour()
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(actions.getQuickTourFail(response.data));
          throw response;
        }
        dispatch(actions.getQuickTourSuccess(response.data));
      })
      .catch(err => {
        dispatch(actions.getQuickTourFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getQuickTour = createAsyncThunk<null, null>('supportReducer/getQuickTour', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await requestApi.getQuickTour();

    return null;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const getWhatNewsLogic = createLogic({
  type: actions.GET_WHAT_NEWS,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .getWhatNews()
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(actions.getWhatNewsFail(response.data));
          throw response;
        }
        dispatch(actions.getWhatNewsSuccess(response));
      })
      .catch(err => {
        dispatch(actions.getWhatNewsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getWhatNews = createAsyncThunk<KeyValueNumberModel<ISupportWhatNew>, null>(
  'supportReducer/getWhatNews',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.getWhatNews();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setReadNewsStatusLogic = createLogic({
  type: actions.SET_READ_NEWS_STATUS,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .setReadNewsStatus(action.payload)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(actions.setReadNewsStatusFail(response.data));
          throw response;
        }
        dispatch(actions.setReadNewsStatusSuccess(response));
        dispatch(reduceNewsCount(response));
        dispatch(actions.getWhatNews(response));
      })
      .catch(err => {
        dispatch(actions.setReadNewsStatusFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const setReadNewsStatus = createAsyncThunk<ISupportWhatNew, number>(
  'supportReducer/setReadNewsStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.setReadNewsStatus(payload);

      dispatch(getWhatNews());
      dispatch(reduceNewsCount(response));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setReadAllNewsStatusLogic = createLogic({
  type: actions.SET_READ_NEWS_STATUS_ALL,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .setReadAllNewsStatus(action.payload)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(actions.setReadAllNewsStatusSuccess(response));
        dispatch(zeroNewsCount(response));
        dispatch(actions.getWhatNews(response));
      })
      .catch(err => {
        console.error(err);
      })
      .then(() => {
        done();
      });
  },
});

export const setReadAllNewsStatus = createAsyncThunk<null, null>(
  'supportReducer/setReadAllNewsStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.setReadAllNewsStatus();

      dispatch(getWhatNews());
      dispatch(zeroNewsCount(response));

      return null;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setSeenGettingStartedStatusLogic = createLogic({
  type: actions.SET_SEEN_GETTING_STARTED_STATUS,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .setSeenGettingStartedStatus(action.payload)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(actions.setSeenGettingStartedStatusSuccess(response));
      })
      .catch(err => {
        console.error(err);
      })
      .then(() => {
        done();
      });
  },
});

export const setSeenGettingStartedStatus = createAsyncThunk<null, null>(
  'supportReducer/setSeenGettingStartedStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.setSeenGettingStartedStatus();

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export default [
  // getWhatNewsLogic,
  // setReadNewsStatusLogic,
  // setReadAllNewsStatusLogic,
  // setSeenGettingStartedStatusLogic,
  // getQuickTourLogic
];
