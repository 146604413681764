import React, { MouseEvent, ReactElement, ReactNode, useMemo } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import Divider from '../../../Divider/Divider';
import CustomButton from '../../../ButtonComponents/CustomButton/CustomButton';
import CustomRadioButton from '../../../ButtonComponents/CustomRadioButton/CustomRadioButton';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import CheckBox from '../../../../controls/CheckBox/CheckBox';

import './DropdownFooterStyles.scss';

interface RadioGroupItem {
  value: string | number;
  title: string;
}

interface DropdownFooterProps<R> {
  type: 'divided-button' | 'radio-group' | 'checkBox-group' | 'warning';
  customClassName?: string;
  onButtonClick?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  buttonTitle?: string | ReactElement;
  buttonLoading?: boolean;
  radioGroup?: RadioGroupItem[];
  radioClickHandler?: (value: R) => void;
  selectedRadioValue?: R;
  isMobile?: boolean;
  isDisabledBtn?: boolean;
  tooltip?: string;
  footerWarningText?: string;
  codesCheckBoxGroup?: RadioGroupItem[];
  checkBoxClickHandler?: (value: R) => void;
  selectedcheckBoxValues?: R[];
  footerIcon?: ReactElement;
}

function DropdownFooter<R>({
  customClassName,
  type,
  onButtonClick,
  buttonTitle,
  buttonLoading,
  radioGroup,
  radioClickHandler,
  selectedRadioValue,
  isMobile,
  isDisabledBtn,
  tooltip,
  footerWarningText,
  codesCheckBoxGroup,
  checkBoxClickHandler,
  selectedcheckBoxValues,
  footerIcon,
}: DropdownFooterProps<R>) {
  const themeClass = useGetThemeClass('b-dropdownFooter');

  return (
    <>
      {type === 'warning' ? (
        <div
          className={cn(`${themeClass} ${themeClass}_${type}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          <Divider direction={'horizontal'} type={'srf-4'} />
          <div className={cn(`${themeClass}_${type}_warningContainer`, { [`-mobile`]: isMobile })}>
            <TokenIcon iconName={'not-verified-triangle'} size={16} customClass={`${themeClass}_${type}_warningIcon`} />
            {footerWarningText}
          </div>
        </div>
      ) : null}
      {type === 'divided-button' ? (
        <div
          className={cn(`${themeClass} ${themeClass}_${type}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          <Divider direction={'horizontal'} type={'srf-4'} />
          <div className={cn(`${themeClass}_${type}_buttonContainer`, { [`-mobile`]: isMobile })}>
            <CustomButton
              loading={buttonLoading}
              type={'text-plain'}
              size={isMobile ? 'lg' : 'xs'}
              title={buttonTitle}
              clickHandler={onButtonClick}
              isMobile={isMobile}
              disabled={isDisabledBtn}
              tooltip={tooltip}
              icon={footerIcon}
            />
          </div>
        </div>
      ) : null}
      {radioGroup?.length && type === 'radio-group' ? (
        <div
          className={cn(`${themeClass} ${themeClass}_${type}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          {radioGroup.map(radio => {
            const isChecked = radio.value === selectedRadioValue;

            return (
              <div key={radio.value} className={`${themeClass}_${type}_radioItem`}>
                <CustomRadioButton value={radio.value} checked={isChecked} clickHandler={radioClickHandler} isMobile={isMobile} />
                <span className={`${themeClass}_${type}_radioItem_label`}>{radio.title}</span>
              </div>
            );
          })}
        </div>
      ) : null}
      {codesCheckBoxGroup?.length && type === 'checkBox-group' ? (
        <div
          className={cn(`${themeClass} ${themeClass}_${type}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          {codesCheckBoxGroup.map(checkBox => {
            const isChecked = !!selectedcheckBoxValues.find(item => checkBox.value === item);

            return (
              <div key={checkBox.value} className={`${themeClass}_${type}_checkBoxItem`}>
                <CheckBox
                  checked={isChecked}
                  onClick={e => checkBoxClickHandler(checkBox.value)}
                  isMobile={isMobile}
                  disabled={isDisabledBtn}
                />
                <span className={`${themeClass}_${type}_checkBoxItem_label`}>{checkBox.title}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

export default DropdownFooter;
