import React from 'react';
import SVG from 'react-inlinesvg';
import { logout } from '../../../../../store/user/userThunk';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { Link } from 'react-router-dom';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { settingsSlice } from '../../../../../store/globalSettings/settingsSlice';
import useRouter from '../../../../../hooks/useRouter/useRouter';

interface IProps {
  containerClassName: string;
}

const { setGlobalPrompPopUp } = settingsSlice.actions;

const Logout = ({ containerClassName }: IProps) => {
  const dispatch = useAppDispatch();
  const { navigate } = useRouter();

  const promptData = useAppSelector(state => state.settingsReducer.globalPromptPopUp);

  const onLogout = () => {
    if (promptData.isChanged) {
      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: () => {
            dispatch(
              logout({
                callback: () => {
                  navigate('/');
                },
              }),
            );
          },
        }),
      );
      return;
    }
    const oldTheme = localStorage.getItem('theme');

    dispatch(
      logout({
        callback: () => {
          navigate('/');
        },
      }),
    );

    localStorage.setItem('theme', oldTheme);
  };

  const handleClick = e => {
    if (promptData.isChanged) {
      e.preventDefault();
    }
  };

  return (
    <Link to={''} onClick={handleClick}>
      <CustomTooltip title={'Logout'} placement={'bottom'}>
        <div className={containerClassName} onClick={onLogout}>
          <div className={`${containerClassName}_iconCont`}>
            <SVG src={require('../../../../../assets/icons/new-logout.svg')} />
          </div>
        </div>
      </CustomTooltip>
    </Link>
  );
};

export default Logout;
