import { createAsyncThunk } from '@reduxjs/toolkit';
import { FiltersDDLType } from '../../components/common/DesignDocumentsLog/DesignDocumentsLog';
import fileDownload from 'js-file-download';
import { API_URI, DOMAIN_URI } from '../../service/links';

import documentsApi from '../../service/Api/documentsApi';
import {
  IDDLFilters,
  IDocumentListDocuments,
  IDocumentListRequest,
  IMainDDL,
  IMainDDLThunk,
  IStatusNFPayload,
  FiltersDDLTypeThunk,
  IDocumentExport,
  ICreateUpdateDoc,
  ICreateUpdateSet,
  ITypeDDLSet,
} from './documentReducer.model';
import { errorsSlice } from '../errors/errorsSlice';
import FileHelper from '../../helpers/FileHelper';
import { GlobalFiltersQueryModel } from '../../models';
import { settingsSlice } from '../globalSettings/settingsSlice';
const { setShowErrorData } = errorsSlice.actions;
const { setIsFirstHashRender } = settingsSlice.actions;

export const getMainSetDDL = createAsyncThunk<IMainDDLThunk, { page?: number; projectId?: number }>(
  'documentsReducer/getMainSetDDL',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = payload?.projectId || Number(sessionStorage.getItem('active_project_id'));
      const page = payload?.page || 1;
      const data = {
        page: page,
        ipp: 80,
      };
      const response = await documentsApi.getMainSetDDL(id, data);

      if (response.status !== true) {
        throw response;
      }
      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getTypeDDLSet = createAsyncThunk<ITypeDDLSet[], null>(
  'documentsReducer/getTypeDDLSet',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await documentsApi.getTypeDDLSet();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setMainSetDDLFilters = createAsyncThunk<
  IMainDDLThunk,
  { queryModel: GlobalFiltersQueryModel; callbackNavigation: (hash?: string) => void }
>('documentsReducer/setMainSetDDLFilters', async (payload, { dispatch, rejectWithValue, getState }) => {
  try {
    const { callbackNavigation, queryModel } = payload;
    const currentState = getState();
    const isFirstRenderWithURLHash = currentState?.settingsReducer?.isFirstRenderWithURLHash;
    const filterHash = new URLSearchParams(location.search).get(`design_document_hash`);
    const generateUrlWithFiltersHash = hash => {
      if (isFirstRenderWithURLHash) {
        dispatch(setIsFirstHashRender());
      }
      if (typeof hash === 'string') {
        callbackNavigation(hash);
      } else {
        callbackNavigation();
      }
    };

    const projectId = Number(sessionStorage.getItem('active_project_id'));
    const data = { ...queryModel, local_storage: JSON.parse(localStorage.getItem(`design_document`)) };
    if (!data.page) {
      data.page = 1;
      data.ipp = 80;
    }
    if (isFirstRenderWithURLHash && filterHash) {
      data.filters_hash = `design_document_hash=${filterHash}`;
    }
    const response = await documentsApi.setMainSetDDLFilters(data, projectId);

    generateUrlWithFiltersHash(response?.response?.filters_hash);

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getDDLlistRequest = createAsyncThunk<IDocumentListRequest, number>(
  'documentsReducer/getDDLlistRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id_doc = payload;
      const response = await documentsApi.getDDLlistRequest(id_doc);

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDDLlistDocuments = createAsyncThunk<IDocumentListDocuments[], { project_id: number; selectedRequestId: number }>(
  'documentsReducer/getDDLlistDocuments',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = payload.project_id || Number(sessionStorage.getItem('active_project_id'));
      const selectedRequestId = payload.selectedRequestId;
      const response = await documentsApi.getDDLlistDocuments(id, selectedRequestId);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setStatusNF = createAsyncThunk<any, IStatusNFPayload>(
  'documentsReducer/setStatusNF',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = payload.data;
      const selectedRequestId = payload.selectedRequestId;
      const id = Number(sessionStorage.getItem('active_project_id'));
      data.project_id = id;
      const response = await documentsApi.setStatusNF(data, selectedRequestId);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDDLlistDocumentsNoNF = createAsyncThunk<IDocumentListDocuments[]>(
  'documentsReducer/getDDLlistDocumentsNoNF',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));
      const response = await documentsApi.getDDLlistDocumentsNoNF(id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDDLColumnSettings = createAsyncThunk(
  'documentsReducer/getDDLColumnSettings',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await documentsApi.getDDLColumnSettings();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setDDLColumnSettings = createAsyncThunk<any, { columnSettings: string }>(
  'documentsReducer/setDDLColumnSettings',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { columnSettings } = payload;

      const response = await documentsApi.setDDLColumnSettings({ setting: columnSettings });

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDDLExport = createAsyncThunk<any, IDocumentExport>(
  'commonReducer/getDDLTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { filename, link_file } = payload;
      const body_params = payload;
      delete body_params['filename'];
      delete body_params['link_file'];
      const url = new URL(`${DOMAIN_URI}${API_URI}export/${link_file}`);

      const response = await documentsApi.getFilePost(url, body_params);
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteDocument = createAsyncThunk<any, { document_id: number }>(
  'documentsReducer/deleteDocument',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));
      const { document_id } = payload;
      const body_params = {
        project_id: id,
        document_id: document_id,
      };
      const response = await documentsApi.deleteDocument(body_params);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createDocument = createAsyncThunk<any, { document: ICreateUpdateDoc }>(
  'documentsReducer/createDocument',
  async (payload, { dispatch }) => {
    const id = Number(sessionStorage.getItem('active_project_id'));
    const body_params = { ...payload.document };
    body_params.project_id = id;
    try {
      const response = await documentsApi.createDocument(body_params);
      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return error.data;
    }
  },
);

export const updateDocument = createAsyncThunk<any, { document: ICreateUpdateDoc }>(
  'documentsReducer/updateDocument',
  async (payload, { dispatch }) => {
    const id = Number(sessionStorage.getItem('active_project_id'));
    const body_params = { ...payload.document };
    body_params.project_id = id;
    try {
      const response = await documentsApi.updateDocument(body_params);
      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return error.data;
    }
  },
);

export const updateDocumentValidityStatus = createAsyncThunk<any, { document: ICreateUpdateDoc }>(
  'documentsReducer/updateDocumentValidityStatus',
  async (payload, { dispatch }) => {
    const id = Number(sessionStorage.getItem('active_project_id'));
    const body_params = { ...payload.document };
    body_params.project_id = id;
    try {
      const response = await documentsApi.updateDocument(body_params);
      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return error.data;
    }
  },
);

export const deleteSet = createAsyncThunk<any, { document_set_id: number }>(
  'documentsReducer/deleteSet',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));
      const { document_set_id } = payload;
      const body_params = {
        project_id: id,
        document_set_id: document_set_id,
      };
      const response = await documentsApi.deleteSet(body_params);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createSet = createAsyncThunk<any, { set: ICreateUpdateSet }>('documentsReducer/createSet', async (payload, { dispatch }) => {
  const id = Number(sessionStorage.getItem('active_project_id'));
  const body_params = { ...payload.set };
  body_params.project_id = id;
  try {
    const response = await documentsApi.createSet(body_params);
    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return error.data;
  }
});

export const updateSet = createAsyncThunk<any, { set: ICreateUpdateSet }>('documentsReducer/updateSet', async (payload, { dispatch }) => {
  const id = Number(sessionStorage.getItem('active_project_id'));
  const body_params = { ...payload.set };
  body_params.project_id = id;
  try {
    const response = await documentsApi.updateSet(body_params);
    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return error.data;
  }
});

export const publicSet = createAsyncThunk<IMainDDLThunk, GlobalFiltersQueryModel>(
  'documentsReducer/publicSet',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await documentsApi.publicSet(payload);

      if (response.status !== true) {
        throw response;
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
