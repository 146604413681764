import copy from 'clipboard-copy';
import * as React from 'react';
import CustomTooltip from '../Tooltip/Tooltip';

/**
 * Render prop component that wraps element in a Tooltip that shows "Copied to clipboard!" when the
 * copy function is invoked
 */

class CopyToClipboard extends React.Component {
  state = { showTooltip: false };

  onCopy = content => {
    copy(content);
    this.setState({ showTooltip: true });
  };

  handleOnTooltipClose = () => {
    this.setState({ showTooltip: false });
  };
  render() {
    return (
      <CustomTooltip
        open={this.state.showTooltip}
        title={this.props.title}
        leaveDelay={this.props.leaveDelay}
        onClose={this.handleOnTooltipClose}
        style={{ width: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      >
        {this.props.children({ copy: this.onCopy })}
      </CustomTooltip>
    );
  }
}

export default CopyToClipboard;
