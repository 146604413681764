import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './MiniLoader.module.scss';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';

interface IMiniLoaderProps {
  containerClasses?: string;
  rootClasses?: string;
  circleClasses: string;
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const MiniLoader: React.FC<IMiniLoaderProps> = ({ containerClasses, rootClasses, circleClasses, size }) => {
  //TODO Isolate different styles for MiniLoader (example CustomButton) to manipulate only with props and do not get this from parent styles
  const themeClass = useGetThemeClass('b-miniLoader');

  return (
    <div
      className={cn(`${themeClass}`, {
        [`${containerClasses}`]: containerClasses,
      })}
    >
      <CircularProgress
        classes={{
          root: cn(`${themeClass}_root -${size}`, {
            [`${rootClasses}`]: rootClasses,
          }),
          circle: cn(`${themeClass}_circle`, {
            [`${circleClasses}`]: circleClasses,
          }),
        }}
      />
    </div>
  );
};

export default MiniLoader;
