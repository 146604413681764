import React, { ReactElement } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import RelatedItemsSnippet from '../RelatedItemsSnippet/RelatedItemsSnippet';
import {
  DeliverableSnippetModel,
  DocumentSnippetModel,
  RequestSnippetModel,
  SubmittalSnippetModel,
} from '../../../../../store/relatedItems/relatedItemsSlice.model';
import { LinkedType } from '../../RelatedItemsTabConstants';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { PredecessorSuccessorModel } from '../../../../../models';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { ItemsCounter } from '../../../../controls/ItemsСounter';
import ChipsDiscipline from '../../../../controls/Chips/ChipsDiscipline/ChipsDiscipline';
import StatusNF from '../../../../controls/Status/StatusNF/StatusNF';
import ChipsRequestType from '../../../../controls/Chips/ChipsRequestType/ChipsRequestType';
import Placeholder from '../../../../controls/Placeholder/Placeholder';
import StatusSubmittal from '../../../../controls/Status/StatusSubmittal/StatusSubmittal';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { ChipsStandardTag } from '../../../../controls/Chips';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import cn from 'classnames';

import './RelatedItemsGroupingItemStyles.scss';

interface IProps {
  groupingData: any;
  openCard?: (snippetData: any) => void;
  handleUnlinkItem?: (
    item: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel,
    type?: LinkedType,
  ) => void;
  relatedTabType?: RelatedItemsTabsType;
  currentCard?: string;
  isDragRequestCard: boolean;
  renderValidityButton: (item, part) => ReactElement | null;
  part: LinkedType;
  relation: 'single' | 'double';
  isDeactivated?: boolean;
  checkIfOverdue: (item: PredecessorSuccessorModel, type: LinkedType) => void;
  expandedAccordionKeys: string[];
  handleExpandAccordion: (key: string, type: LinkedType) => void;
  selectedGrouping: string[];
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
}

const RelatedItemsGroupingItem: React.FC<IProps> = ({
  groupingData,
  openCard,
  handleUnlinkItem,
  relatedTabType,
  currentCard,
  isDragRequestCard,
  renderValidityButton,
  part,
  relation,
  isDeactivated,
  checkIfOverdue,
  expandedAccordionKeys,
  handleExpandAccordion,
  selectedGrouping,
  selectedRequest,
}) => {
  const themeClass = useGetThemeClass('b-RelatedItemsGroupingItem');

  const getIconType = (value: string): string => {
    if (value === 'CL-RFI') {
      return 'cl-rfi';
    } else if (value === 'QC-D') {
      return 'qc';
    } else if (value === 'SO') {
      return 'sign-off';
    } else if (value === 'SD') {
      return 'shop-drawings';
    } else {
      return 'deliverables';
    }
  };

  const renderTitle = (
    value: string,
    depth: number,
    itemObject: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel,
  ): React.ReactElement => {
    const groupingValue = selectedGrouping[depth]?.value;

    //DD
    if (groupingValue === 'disciplineDD') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>DD discipline:</div>
          <ChipsStandardTag value={value} />
        </div>
      );
    }

    if (groupingValue === 'latestDdSet') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Latest DD set: {value}</div>
        </div>
      );
    }

    if (groupingValue === 'buildingDD') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Building: {value}</div>
        </div>
      );
    }

    //NF
    if (groupingValue === 'requestDiscipline') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Request discipline:</div>
          <ChipsDiscipline type={'filled'} color={itemObject?.request_discipline?.text_color} value={value} />
        </div>
      );
    }

    if (groupingValue === 'nfStatus') {
      const status = value;
      const formattedStatus = status === 'declined' ? 'Re-opened' : status;

      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Status:</div>
          <StatusNF status={formattedStatus} />
        </div>
      );
    }

    if (groupingValue === 'requestType') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Request type:</div>
          <ChipsRequestType type={value} />
        </div>
      );
    }

    if (groupingValue === 'BicCompany') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>BIC company:</div>
          <Placeholder type={'Company'} imagePath={itemObject.court?.renderings?.file} size={24} />
          <div className={`${themeClass}_text`}>{value || 'Not specified'}</div>
        </div>
      );
    }

    //Deliverable
    if (groupingValue === 'drawingDiscipline') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Drawing discipline:</div>
          <ChipsDiscipline type={'filled'} color={itemObject?.discipline?.text_color} value={value} />
        </div>
      );
    }

    if (groupingValue === 'drawingType') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Drawing type:</div>
          <CardLinkButton
            type={'deliverable'}
            size={'md'}
            title={value}
            tooltip={value}
            icon={() => <TokenIcon iconName={getIconType(value)} size={16} />}
          />
        </div>
      );
    }

    if (groupingValue === 'deliverableLocation') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Deliverable location: {value}</div>
        </div>
      );
    }

    if (groupingValue === 'responseCompany') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Response company:</div>
          <Placeholder type={'Company'} imagePath={itemObject.response_party?.renderings?.file} size={24} />
          <div className={`${themeClass}_text`}>{value || 'Not specified'}</div>
        </div>
      );
    }

    //Submittal
    if (groupingValue === 'specificationSection') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Specification section: {value}</div>
        </div>
      );
    }

    if (groupingValue === 'submittalStatus') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Status:</div>
          <StatusSubmittal status={itemObject?.status} reviewState={itemObject?.review_state} />
        </div>
      );
    }

    if (groupingValue === 'submittalType') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Submittal type:</div>
          <ChipsRequestType type={value} />
        </div>
      );
    }

    if (groupingValue === 'responsibleManagerCompany') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Responsible manager company:</div>
          <Placeholder type={'Company'} imagePath={itemObject.responsible_party?.renderings?.file} size={24} />
          <div className={`${themeClass}_text`}>{value || 'Not specified'}</div>
        </div>
      );
    }
    if (groupingValue === 'responsibleContractorCompany') {
      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Responsible contractor company:</div>
          <Placeholder type={'Company'} imagePath={itemObject.contractor_party?.renderings?.file} size={24} />
          <div className={`${themeClass}_text`}>{value || 'Not specified'}</div>
        </div>
      );
    }

    if (groupingValue === 'validityStatus') {
      let title = '';

      if (value === 'valid') title = 'Actual';
      if (value === 'not_valid') title = 'Outdated';
      if (value === 'not_verified') title = 'Requiring verification';

      return (
        <div className={`${themeClass}_accordionTitle`}>
          <div className={`${themeClass}_text`}>Validity status: {title}</div>
        </div>
      );
    }

    return <div className={`${themeClass}_text`}>{value}</div>;
  };

  const renderAccordion = ({
    title,
    itemCount,
    depth,
    itemObject,
    accordionOpeningKey,
  }: {
    title: string;
    itemCount: number;
    depth: number;
    itemObject: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel;
    accordionOpeningKey: string;
  }): React.ReactElement => {
    return (
      <div
        key={`${title}`}
        className={`${themeClass}_accordion`}
        onClick={() => handleExpandAccordion(accordionOpeningKey, part)}
        style={{ paddingLeft: `${14 * depth}px` }}
      >
        <SystemButton
          type={isAccordionExpanded(accordionOpeningKey) ? 'chevron-down' : 'chevron-right'}
          size={'md'}
          variant={'transparent'}
        />
        {renderTitle(title, depth, itemObject)}
        <ItemsCounter value={`(${itemCount})`} size={'md'} />
      </div>
    );
  };
  const isAccordionExpanded = (accordionOpeningKey: string): boolean => {
    return expandedAccordionKeys.includes(accordionOpeningKey);
  };

  const findFirstObject = data => {
    // Check if the data is an array
    if (Array.isArray(data)) {
      // If it is an array and not empty, return the first object
      if (data.length > 0) {
        return data[0];
      } else {
        return null; // Or handle the case where no objects are found
      }
    }

    // If data is an object, recursively search its properties
    if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Recursively call the function for each property
          return findFirstObject(data[key]);
        }
      }
    }

    // If data is neither an array nor an object, return null
    return null;
  };

  const countObjectsInArrays = obj => {
    if (Array.isArray(obj)) {
      // If obj is an array, count the objects in it
      return obj.reduce((count, item) => {
        if (typeof item === 'object' && item !== null) {
          return count + 1; // Count the object
        }
        return count;
      }, 0);
    } else if (typeof obj === 'object' && obj !== null) {
      // If obj is an object, recursively check its values for arrays
      return Object.values(obj).reduce((count, value) => {
        if (Array.isArray(value)) {
          // If the value is an array, count the objects in it
          return count + countObjectsInArrays(value);
        } else if (typeof value === 'object' && value !== null) {
          // If the value is an object, recursively check its values for arrays
          return count + countObjectsInArrays(value);
        } else {
          return count;
        }
      }, 0);
    } else {
      return 0; // If obj is not an array or object, return 0
    }
  };

  const renderData = (data, depth = 0, parentKey?) => {
    return Object.entries(data).map(([key, value]) => {
      let itemCount: number;
      let itemObject: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel;
      if (Array.isArray(value)) {
        itemCount = value.length;
        itemObject = value[0];
      } else {
        itemCount = countObjectsInArrays(value); // Count objects only within arrays
        itemObject = findFirstObject(value);
      }

      const accordionOpeningKey = parentKey ? `${key}-${parentKey}` : `${key}`;

      if (Array.isArray(value)) {
        // If value is an array, render items
        return (
          <div className={`${themeClass}_items`} key={`${key}-${depth}-${itemCount}`}>
            {renderAccordion({ title: key, itemCount, depth, itemObject, accordionOpeningKey })}
            <div className={cn({ [`${themeClass}_snippetGroup`]: relation === 'single' })}>
              {isAccordionExpanded(accordionOpeningKey) &&
                value.map(item => (
                  <RelatedItemsSnippet
                    item={item}
                    handleOpenCard={() => openCard(item)}
                    handleUnlink={() => handleUnlinkItem(item, part)}
                    relatedTabType={relatedTabType}
                    snippetData={item}
                    currentCard={currentCard}
                    isDragRequestCard={isDragRequestCard}
                    validityStatusIcon={renderValidityButton(item, part)}
                    part={part}
                    relation={relation}
                    checkIfOverdue={checkIfOverdue}
                    isDeactivated={isDeactivated}
                    openedRequest={selectedRequest}
                  />
                ))}
            </div>
          </div>
        );
      } else {
        // If value is an object, recursively render it
        return (
          <div key={`${key}-${depth}-${itemCount}`} className={`${themeClass}_items`}>
            {renderAccordion({ title: key, itemCount, depth, itemObject, accordionOpeningKey })}
            {isAccordionExpanded(accordionOpeningKey) && renderData(value, depth + 1, accordionOpeningKey)}
          </div>
        );
      }
    });
  };

  return <div className={`${themeClass}`}>{renderData(groupingData)}</div>;
};

export default RelatedItemsGroupingItem;
