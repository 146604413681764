import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ReactDataGrid, { DataGridProps, FormatterProps, HeaderRendererProps, SortColumn } from 'react-data-grid';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { DraggbleScroll } from '../NeedsList/new/controls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DataGridBasicProps } from './DataGridBasic.props';
import { NeedListRequest } from '../../../store/needlist/needListModels';
import { NeedListColumn } from '../NeedsList/NeedListModels';
import { list_columns } from '../NeedsList/NeedListConstants/NeedListConstants';
import { submittalsGridColumns } from '../DesignDocumentsLog/constants';
import { DraggableHeaderRenderer } from '../NeedsList/new/DraggableHeader/DraggableHeader';
import { debounce, groupBy } from 'lodash';
import BasicHeaderRenderer from '../NeedsList/new/BasicHeader/BasicHeader';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import CheckBox from '../../controls/CheckBox/CheckBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cn from 'classnames';
import { RESOLUTION_2K_MULTIPLIER, RESOLUTION_4K_MULTIPLIER } from '../../../constants/screenResolutionConstants';
import { GetResolutionMin } from '../../../helpers/ScreenResolution/GetResolution';

import './DataGridBasicStyles.module.scss';

type IProps = DataGridBasicProps | DataGridProps;

const DataGridBasic: FC<IProps> = ({
  columns,
  rows,
  headerRowHeight,
  rowHeight,
  getMoreData,
  rowKeyGetter,
  rowGrouper,
  onExpandedGroupIdsChange,
  onColumnResize,
  setIsDraggingActive,
  isBulkEditMode,
  onRowDoubleClick,
  selectedGroupingValues = [],
  editingRowIds,
  sorting,
  isAccess,
  userData,
  deleteRequest,
  setEditingRowsAction,
  getAvailableBulkEditFields,
  changedFields,
  openedRequestId,
  onSort,
  draggableColumns = false,
  resizable = false,
  isDeletable = true,
  getGroupingRowValue,
  customHeaderRenderer = false,
  tabelOutRef,
  isManageStatusesPopUp = false,
  isCustomGrouping = false,
  customGropingRows,
  isLoading,
  isSetAllExpand,
  borderType = 'on-bgd',
  canRowDisable = true,
  handleColumnsReorder,
  isInitAllExpand,
  setGroupingRowIdExpand,
  isReadOnly = false,
  handleSortRows,
  sortingFieldModule,
  getIsAllGroupsExpand,
}) => {
  const { is2560Resolution, is3840Resolution } = GetResolutionMin();
  const themeClass = useGetThemeClass('data-grid-basic');
  const tableRef = tabelOutRef || useRef(null);
  const [dropTargetColumn, setDropTargetColumn] = useState(null);
  const [dragTargetItem, setDragTargetItem] = useState(null);
  const [isBulkEditAll, setIsBulkEditAll] = useState(0);
  const [groupedData, setGroupedData] = useState([]);
  const [groupedRows, setGroupedRows] = useState([]);
  const [basicRows, setBasicRows] = useState([]);
  const [chosenRow, setChosenRow] = useState(null);
  const [rowClicks, setRowClicks] = useState(0);
  const [lastClickTarget, setLastClickTarget] = useState(null);
  const [configuredColumns, setConfiguredColumns] = useState([]);
  const [expandedRowsIds, setExpandedRowsIds] = useState([]);
  const [isInitAllExpandState, setIsInitAllExpandState] = useState<boolean>(false);

  useEffect(() => {
    if (editingRowIds && basicRows) {
      if (editingRowIds?.length === basicRows?.length) {
        setIsBulkEditAll(2);
      } else if (editingRowIds?.length === 0) {
        setIsBulkEditAll(0);
      } else {
        setIsBulkEditAll(1);
      }
    }
  }, [editingRowIds?.length, basicRows?.length]);

  useEffect(() => {
    groupColumns();
  }, [selectedGroupingValues?.length, basicRows?.length, rows, rows.length, basicRows, expandedRowsIds.length]);

  useEffect(() => {
    if (groupedData && Object.keys(groupedData).length && !isInitAllExpandState && isInitAllExpand) {
      const key = Object.keys(groupedData)[0];
      if (Object.keys(groupedData[key])?.length) {
        handleSetAllExpand();
        setIsInitAllExpandState(true);
      }
    }
  }, [groupedData]);

  useEffect(() => {
    _getMergedData();
  }, [
    selectedGroupingValues?.length,
    basicRows?.length,
    groupedRows?.length,
    rows,
    rows.length,
    changedFields,
    groupedData,
    editingRowIds?.length,
    isBulkEditAll,
  ]);

  useEffect(() => {
    setChosenRow(openedRequestId);
  }, [openedRequestId]);

  useEffect(() => {
    setConfiguredColumns(generateColumns());
  }, [columns, dropTargetColumn, dragTargetItem, basicRows, editingRowIds?.length, rows, rows.length, isBulkEditAll]);

  useEffect(() => {
    if (isSetAllExpand !== null) {
      handleSetAllExpand();
    }
  }, [isSetAllExpand]);

  const handleSetAllExpand = () => {
    if (isSetAllExpand || (isInitAllExpand && !isInitAllExpandState)) {
      const rowIds = [];
      Object.keys(groupedData).forEach(globalKey => {
        Object.keys(groupedData[globalKey]).forEach(key => {
          const groupingRow = groupedData[globalKey][key];

          const isAllowExpand =
            groupingRow?.rows?.length > 0 && !(groupingRow?.groupId?.includes('sf_index__') && groupingRow?.rows[0]?.code);

          if (groupingRow.groupId && isAllowExpand) {
            rowIds.push(groupingRow.groupId);
          }

          if (groupingRow?.groups?.length) {
            groupingRow.groups.forEach(group => rowIds.push(group.groupId));
          }
        });
      });
      setExpandedRowsIds(rowIds);
    } else {
      setExpandedRowsIds([]);
    }
  };

  useEffect(() => {
    if (setGroupingRowIdExpand?.length) {
      handleSetGroupingRowIdExpand();
    }
  }, [setGroupingRowIdExpand]);

  const handleSetGroupingRowIdExpand = () => {
    if (setGroupingRowIdExpand?.length) {
      setExpandedRowsIds(setGroupingRowIdExpand);
    }
  };

  const handleOnRowDoubleClick = (row): void => {
    onRowDoubleClick && onRowDoubleClick(row.id, row.nf);
    setChosenRow(row.id);
  };

  const getExpandedRows = expanded_len => {
    const all_groups_len = groupedRows.filter(item => item.groupingValue).filter(item => !item.rows[0]?.code).length;
    if (all_groups_len === expanded_len) {
      getIsAllGroupsExpand && getIsAllGroupsExpand(true);
    } else {
      getIsAllGroupsExpand && getIsAllGroupsExpand(false);
    }
  };

  const handleRowExpand = row => {
    if (expandedRowsIds.includes(row.groupId)) {
      getExpandedRows(expandedRowsIds.filter(item => item !== row.groupId).length);
      setExpandedRowsIds(expandedRowsIds.filter(item => item !== row.groupId));
    } else {
      getExpandedRows([...expandedRowsIds, row.groupId].length);
      setExpandedRowsIds([...expandedRowsIds, row.groupId]);
    }

    _getMergedData();
  };

  const onRowClick = (rowId, row): void => {
    const isAllowExpand = row?.rows?.length > 0 && !(row?.groupId?.includes('sf_index__') && row?.rows[0]?.code);

    if (!row?.id && isAllowExpand) {
      handleRowExpand(row);
    }
    // if (!isBulkEditMode) {
    //   let clickTimer;
    //   const tmpClicks = rowClicks + 1;
    //   const targetCheck = lastClickTarget === row.id;
    //   setRowClicks(tmpClicks);
    //   setLastClickTarget(row.id);
    //
    //   if (tmpClicks === 1) {
    //     clickTimer = setTimeout(() => {
    //       setRowClicks(0);
    //       setLastClickTarget(null);
    //     }, 300);
    //   } else if (tmpClicks > 1 && targetCheck) {
    //     clearTimeout(clickTimer);
    //     handleOnRowDoubleClick(row);
    //     setRowClicks(0);
    //     setLastClickTarget(null);
    //   }
    // }
  };

  const handleSortColumn = (sortColumns: SortColumn[]): void => {
    const col = columns.find(item => item.key === sortColumns[0].columnKey);
    const payload = {
      order_by: col.sortingField,
      order_dir: sortColumns[0].direction.toLowerCase(),
    };

    if (sorting.order_by === col.sortingField) {
      if (sorting.order_dir === 'desc') {
        payload.order_dir = '';
        payload.order_by = '';
      } else if (sorting.order_dir === 'asc') {
        payload.order_dir = 'desc';
      }
    }

    onSort(payload);
  };

  const renderHeaderCell = (props: HeaderRendererProps<NeedListRequest>): ReactElement => {
    const { column } = props;

    const currentPlanitColumn = columns.find(item => item.key === column.key);

    if (column.key === 'EDIT') {
      return (
        <div className={`checkBox`}>
          <CheckBox btnClassName={'checkbox'} isTripleState important={isBulkEditAll} onClick={handleChangeEditAll} disabled={isReadOnly} />
        </div>
      );
    }

    const getSortingList = () => {
      if (!sortingFieldModule || sortingFieldModule === 'needlist') {
        return list_columns.find(f => f.value === column.key)?.sortingField;
      }
      if (sortingFieldModule === 'submittals') {
        return submittalsGridColumns.find(f => f.key === column.key)?.sortingField;
      }
    };
    const sortingField = getSortingList(); //list_columns.find(f => f.value === column.key)?.sortingField; //Todo

    if (draggableColumns) {
      return (
        <div onClick={e => e.stopPropagation()} className={`${themeClass}__draggableHeader`}>
          <DraggableHeaderRenderer
            {...props}
            onColumnsReorder={handleColumnsReorder}
            onDropTargetHover={handleDropTargetColumn}
            sorting={sorting}
            currentPlanitColumn={currentPlanitColumn}
            column={column}
            sortingField={sortingField}
          />
        </div>
      );
    } else {
      return (
        <div onClick={e => e.stopPropagation()} className={`${themeClass}__basicHeader`}>
          <BasicHeaderRenderer
            sorting={sorting}
            currentPlanitColumn={currentPlanitColumn}
            onSort={onSort}
            column={column}
            sortingField={sortingField}
          />
        </div>
      );
    }
  };

  const _isDropTarget = (col: NeedListColumn): string => {
    if (dropTargetColumn?.key === col.key) {
      const planitColumnsCopy = [...configuredColumns];
      const fromIndex = planitColumnsCopy.findIndex(f => f.key === dragTargetItem?.key);
      const toIndex = planitColumnsCopy.findIndex(f => f.key === col.key);

      if (fromIndex > toIndex) {
        return '-dragDropHoverLeft';
      } else {
        return '-dragDropHoverRight';
      }
    } else {
      return '';
    }
  };

  const getRowClass = (row: any): string => {
    const chosenRowCopy = row.id === chosenRow && !isBulkEditMode ? 'chosenRow' : '';
    const isGroupingActive = !!row.groupId && selectedGroupingValues?.length ? '-groupingActive' : '';
    const isGroupingWithoutBorders =
      (!isManageStatusesPopUp && !!row.current_set && !row?.parend_row) ||
      (row.ufTitle && row.expandLevelValue === 0) ||
      (!!row?.specTitle && row?.rows?.length)
        ? '-groupingWithoutBorders'
        : '';
    const isManageStatusesPopUpActive = isManageStatusesPopUp ? '-isManageStatusesPopUp' : '';
    const isEditRow = _isRowEditing(row.id) ? '-editRow' : '';
    const isDisabled = canRowDisable && !row.isActive && row.code ? '-disabled' : '';
    const expandLevelValueClass = row?.expandLevelValue >= 0 ? `-expandLevelValue-${row?.expandLevelValue}` : '';

    return `${chosenRowCopy} ${
      isGroupingActive || isGroupingWithoutBorders
    } ${isManageStatusesPopUpActive} ${isEditRow} ${isDisabled} ${expandLevelValueClass}`;
  };

  const handleDropTargetColumn = (item, column) => {
    if (dropTargetColumn !== column) {
      setDropTargetColumn(column);
    }
    if (dragTargetItem !== item) {
      setDragTargetItem(item);
    }
  };

  const handleChangeEditAll = (): void => {
    const newIsBulkEditAll = !isBulkEditAll;
    setIsBulkEditAll(newIsBulkEditAll);
    let newEditingRowIds: number[];
    if (newIsBulkEditAll) {
      newEditingRowIds = rows.map(request => (rowKeyGetter ? rowKeyGetter(request) : request.id));
    } else {
      newEditingRowIds = [];
    }

    setEditingRowsAction({
      editingRowIds: newEditingRowIds,
    });

    if (newEditingRowIds.length) {
      getAvailableBulkEditFields && getAvailableBulkEditFields({ request_ids: newEditingRowIds });
    }
  };

  const _isRowEditing = (requestId: number): boolean => {
    const res = editingRowIds?.find(id => id === requestId);
    return Boolean(res);
  };

  const handleChangeEditCheckbox = request => {
    let newEditingRowIds: number[];

    if (_isRowEditing(rowKeyGetter ? rowKeyGetter(request) : request.id)) {
      newEditingRowIds = [...editingRowIds].filter(item => item !== (rowKeyGetter ? rowKeyGetter(request) : request.id));
    } else {
      newEditingRowIds = [...editingRowIds, rowKeyGetter ? rowKeyGetter(request) : request.id];
    }

    setEditingRowsAction({
      editingRowIds: newEditingRowIds,
    });

    if (newEditingRowIds.length) {
      getAvailableBulkEditFields && getAvailableBulkEditFields({ request_ids: newEditingRowIds });
    }
  };

  const renderDeleteColumn = (props: FormatterProps<NeedListRequest>) => {
    const isAccessState =
      isAccess || (userData.user_partie === props.row.request_party?.company && props.row.lastUserStatus?.status === 'drafted');

    return (
      <div style={{ display: isAccessState ? '' : 'none' }} className={`${themeClass}_deleteBtn`} onClick={() => deleteRequest(props.row)}>
        <TokenIcon iconName={'trash'} size={16} />
      </div>
    );
  };

  const renderEditCheckbox = (props: FormatterProps<NeedListRequest>) => {
    _isRowEditing(props.row.id);

    const isChecked = editingRowIds?.find(id => id === (rowKeyGetter ? rowKeyGetter(props.row) : props.row.id));

    return (
      <div>
        <CheckBox
          btnClassName={'checkbox'}
          checked={Boolean(isChecked)}
          onClick={() => handleChangeEditCheckbox(props.row)}
          disabled={isReadOnly}
        />
      </div>
    );
  };
  const createGroupsObject = (groups, filteredDataKey, filteredDataValue, resultIndex) => {
    const groupingField = selectedGroupingValues[resultIndex];

    if (groups?.length && !!groupingField) {
      Object.keys(groups).forEach(groupsValue => {
        const filteredRows = groupBy(groups[groupsValue].rows, row => getGroupingRowValue(row, groupingField));
        if (filteredRows) {
          groups[groupsValue].groups = [];
          Object.keys(filteredRows).forEach(filteredRowsValue => {
            const groupId = `${filteredDataKey}__${filteredDataValue}__${groupingField}__${filteredRowsValue}`;

            const newGroup = {
              key: groupingField,
              value: filteredRowsValue,
              depth: true,
              depthLevel: resultIndex,
              groupId,
              rows: filteredRows[filteredRowsValue],
              groupingValue: filteredRowsValue,
            };

            const isAllowExpand = newGroup?.rows?.length > 0 && !(newGroup?.groupId?.includes('sf_index__') && newGroup?.rows[0]?.code);

            newGroup.expanded = expandedRowsIds.includes(groupId) && isAllowExpand;

            groups[groupsValue].groups.push(newGroup);

            createGroupsObject(groups[groupsValue].groups, filteredDataKey, filteredDataValue, resultIndex + 1);
          });
        }
      });
    }
  };

  const groupColumns = (): void => {
    // function that write refactored data to groupData state, for render grouped rows

    const result = {};

    if (!selectedGroupingValues) return;
    selectedGroupingValues.forEach(columnKey => {
      const newGroupedData = groupBy(rows, row => getGroupingRowValue(row, columnKey));

      // go over the groupedData and rewrite expanded value, if in state.groupedData it will find an equal groupId, then generate row and assign it to result
      Object.keys(newGroupedData).forEach(key => {
        const groupId = `${columnKey}__${key}`;

        const newGroup = { rows: newGroupedData[key], groupId, groupingValue: key };

        const isAllowExpand = newGroup?.rows?.length > 0 && !(newGroup?.groupId?.includes('sf_index__') && newGroup?.rows[0]?.code);

        newGroup.expanded = expandedRowsIds.includes(groupId) && isAllowExpand;

        newGroupedData[key] = newGroup;
      });
      result[columnKey] = newGroupedData;
    });

    // repeating all from the previous function, but it generates groups

    const resultLength = Object.keys(result).length;
    let filteredData;
    if (resultLength.length === 1) {
      setGroupedData(result);
    } else {
      Object.keys(result).forEach((resultKey, resultIndex) => {
        // if first groping value generate first grouping object
        if (resultIndex === 0) {
          filteredData = { [resultKey]: result[resultKey] };
        } else {
          // if more than one groping value generate groups
          Object.keys(filteredData).forEach(filteredDataKey => {
            const firstGroupingField = filteredData[filteredDataKey];
            Object.keys(firstGroupingField).forEach(filteredDataValue => {
              const firstGroupingFieldValue = firstGroupingField[filteredDataValue];
              Object.keys(result[resultKey]).forEach(resultValue => {
                // if more than two grouping value jump inside the groups using recursion generate them
                if (resultIndex === 1) {
                  const filteredRows = groupBy(firstGroupingFieldValue.rows, row => getGroupingRowValue(row, resultKey));

                  firstGroupingFieldValue.groups = [];
                  Object.keys(filteredRows).forEach(filteredRowsValue => {
                    const groupId = `${filteredDataKey}__${filteredDataValue}__${resultKey}__${filteredRowsValue}`;

                    const newGroup = {
                      key: resultKey,
                      value: filteredRowsValue,
                      depth: true,
                      depthLevel: resultIndex,
                      groupId,
                      rows: filteredRows[filteredRowsValue],
                      groupingValue: filteredRowsValue,
                    };

                    const isAllowExpand =
                      newGroup?.rows?.length > 0 && !(newGroup?.groupId?.includes('sf_index__') && newGroup?.rows[0]?.code);

                    newGroup.expanded = expandedRowsIds.includes(groupId) && isAllowExpand;

                    firstGroupingFieldValue.groups.push(newGroup);
                  });
                } else if (resultIndex === 2) {
                  const groups = firstGroupingFieldValue?.groups;
                  createGroupsObject(groups, filteredDataKey, filteredDataValue, resultIndex);
                }
              });
            });
          });
        }
      });

      setGroupedData(filteredData);
    }
  };

  const handleExpendRows = (data, result): void => {
    data?.groups?.forEach(group => {
      if (group?.groups?.length) {
        result.push(group);
        if (group.expanded) {
          handleExpendRows(group, result);
        }
      } else {
        result.push(group);
        if (group.expanded) {
          result.push(...group.rows);
        }
      }
    });
  };

  const _getMergedData = (): void => {
    // uses only when bulk edit enabled
    // Note: editingRowIds = requestId, needListDataItems = requests

    if (rows) {
      const result = [];

      // work only with submital table
      const newGroupedData = handleSortRows ? { sf_index: handleSortRows(groupedData?.sf_index || {}) } : { ...groupedData };

      // If grouping enabled, it will add new rows to the groupedRows state
      if (selectedGroupingValues?.length && newGroupedData) {
        Object.keys(newGroupedData).forEach(key => {
          Object.keys(newGroupedData[key]).forEach(value => {
            result.push({
              key,
              value,
              ...newGroupedData[key][value],
            });
            if (newGroupedData[key][value]?.groups?.length) {
              if (newGroupedData[key][value].expanded) {
                handleExpendRows(newGroupedData[key][value], result);
              }
            } else {
              if (newGroupedData[key][value].expanded) {
                result.push(...newGroupedData[key][value].rows);
              }
            }
          });
        });
      }
      setBasicRows(rows);
      if (result.length) {
        setGroupedRows(result);
      }
    }
  };

  const generateColumns = () => {
    const result = [];
    if (isBulkEditMode) {
      result.push({
        key: 'EDIT',
        name: '',
        width: is3840Resolution ? 32 * RESOLUTION_4K_MULTIPLIER : is2560Resolution ? 32 * RESOLUTION_2K_MULTIPLIER : 32,
        minWidth: is3840Resolution ? 32 * RESOLUTION_4K_MULTIPLIER : is2560Resolution ? 32 * RESOLUTION_2K_MULTIPLIER : 32,
        frozen: true,
        formatter: (props: FormatterProps<NeedListRequest>) => renderEditCheckbox(props),
        headerRenderer: renderHeaderCell,
        cellClass: (row: NeedListRequest) => {
          const disabledBoxShadow = isBulkEditMode ? '' : '-noneEditMode';

          return `${_isRowEditing(row.id) ? '-editMode' : ''} ${disabledBoxShadow}`;
        },
      });
    }

    columns.forEach(column => {
      const columnCopy = { ...column };

      column.resizable = column.isResizable ?? resizable;

      column.headerRenderer = !customHeaderRenderer ? renderHeaderCell : column.headerRenderer;

      column.editorOptions = {
        editOnClick: 'ontouchstart' in window,
      };

      column.cellClass = row => {
        // const disabledClass = this._isCellDisabled(col, row) ? '-disabled' : '';

        let customCellClass;

        if (columnCopy.cellClass && typeof columnCopy.cellClass === 'function') {
          customCellClass = columnCopy.cellClass(row);
        } else if (columnCopy.cellClass) {
          customCellClass = columnCopy.cellClass;
        }

        if (_isDropTarget(column)) {
          return `${_isRowEditing(row.id) ? '-editMode' : ''} ${_isDropTarget(column)} ${customCellClass ? customCellClass : ''}`;
        } else {
          return `${_isRowEditing(row.id) ? '-editMode' : ''} ${customCellClass ? customCellClass : ''}`
            .replace('-dragDropHoverLeft', '')
            .replace('-dragDropHoverRight', '');
        }
      };

      result.push(column);
    });

    if (isBulkEditMode && isDeletable) {
      result.push({
        key: 'DELETE',
        name: 'Delete',
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        formatter: (props: FormatterProps<NeedListRequest>) => renderDeleteColumn(props),
        cellClass: (row: NeedListRequest) => {
          const disabledBoxShadow = isBulkEditMode ? '' : '-noneEditMode';
          return `${_isRowEditing(row.id) ? '-editMode' : ''} ${disabledBoxShadow}`;
        },
      });
    }

    return result;
  };

  const getGroupingRows = () => {
    if (isCustomGrouping) {
      return customGropingRows;
    }

    return selectedGroupingValues?.length ? groupedRows : basicRows;
  };

  const onResizeFinish = useCallback(
    debounce((idx: number, width: number, gridColumns: any[]) => {
      onColumnResize(idx, width, gridColumns);
    }, 1000),
    [onColumnResize],
  );

  const updateRefPosition = (newX, newY) => {
    tableRef?.current?.element?.scrollLeft = newX;
    tableRef?.current?.element?.scrollTop = newY;
  };

  return (
    <div className={`${themeClass}__container`}>
      {tableRef.current?.element && (
        <>
          {getMoreData && (
            <InfiniteScroll
              scrollableTarget={tableRef.current.element}
              dataLength={rows.length}
              next={() => {
                getMoreData();
              }}
              hasMore={true}
              loader={null}
            >
              <div />
            </InfiniteScroll>
          )}
          <DraggbleScroll
            element={tableRef.current.element}
            setIsDraggingActive={setIsDraggingActive}
            updateRefPosition={updateRefPosition}
          />
        </>
      )}
      <DndProvider backend={HTML5Backend}>
        <ReactDataGrid
          ref={tableRef}
          columns={configuredColumns}
          rows={rows.length ? getGroupingRows() : []}
          headerRowHeight={headerRowHeight}
          rowHeight={rowHeight}
          className={cn(`${themeClass}__table border-${borderType}`, { ['-loading']: isLoading })}
          onSortColumnsChange={handleSortColumn}
          rowKeyGetter={rowKeyGetter}
          onRowClick={onRowClick}
          groupBy={selectedGroupingValues}
          rowGrouper={rowGrouper}
          onExpandedGroupIdsChange={onExpandedGroupIdsChange}
          onColumnResize={(idx: number, width: number) => onColumnResize && onResizeFinish(idx, width, columns)}
          rowClass={row => getRowClass(row)}
        />
      </DndProvider>
    </div>
  );
};

export default React.memo(DataGridBasic);
