export const DOMAIN_URI = process.env.DOMAIN_URI;
export const WEB_SOCKET_DOMAIN = process.env.WEB_SOCKET_DOMAIN;
export const DOMAIN_KPI_URL = process.env.DOMAIN_KPI_URL;
// export const WEB_SOCKET_DOMAIN = 'wss://copy.planit-inc.com:6001';
export const DOMAIN_SELF_URL = process.env.DOMAIN_SELF_URL;

export const API_URI = '/api/v1/';

export const DOMAIN_MOBILE_URI = process.env.DOMAIN_MOBILE_URI;

export const EXPORT_NEEDLIST_XLS = 'export/needlist';
export const EXPORT_NEEDLIST_PDF = 'export/pdf/needlist';
export const EXPORT_TRACKING_XLS = 'export/tracking';
export const EXPORT_TRACKING_PDF = 'export/pdf/tracking';
