import './OutlinedButtonStyles.scss';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export default class OutlinedButton extends React.Component<any, any> {
  render() {
    const { title, handleClick, height, width, disabled, tooltip, style, id } = this.props;
    return (
      <div className={'OutlinedButton'}>
        <Tooltip disableFocusListener title={tooltip ? tooltip : ''}>
          <div className={`outlinedButton`}>
            <button
              id={id || `outlinedBtn-${Math.ceil(Math.random() * 1000)}`}
              className={`button ${disabled ? 'disabled' : ''}`}
              onClick={!disabled ? handleClick : () => {}}
              style={{
                marginTop: '0px',
                padding: '4px 0px',
                width: width ? width : '100px',
                height: height ? height : '20px',
                borderRadius: '2px',
                cursor: disabled ? 'inherit' : 'pointer',
                ...style,
              }}
            >
              <span>{title}</span>
            </button>
          </div>
        </Tooltip>
      </div>
    );
  }
}
