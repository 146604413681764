export const ufCodeStyle = theme => ({
  formControl: {
    minWidth: '100%',
    maxWidth: '100%',
    height: '47px',
    '& label': {
      top: '4px',
    },
  },
  SandBoxInput: {
    padding: '13px 15px 0 15px',
    // letterSpacing: '-0.06em',
    height: '47px',
    boxSizing: 'border-box',
  },
});

export const disceplineStyle = theme => ({
  wrapContent: {
    width: '100%',
    borderRadius: '4px',
    border: 'var(--background-border)',
    backgroundColor: '#2d3049',
    height: '60px',
    '& svg': {
      right: '10px',
    },
  },
  formControl: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    // height: '30px',
    lineHeight: '30px',
    '& label': {
      top: '4px',
    },
  },
  SandBoxInput: {
    padding: '25px 15px 0 15px',
    // letterSpacing: '-0.06em',
    height: '60px',
    fontSize: '15px',
    backgroundColor: 'var(--background-sheet)',
    border: 'var(--background-border)',
    color: 'var(--color)',
    fontWeight: '400',
    boxSizing: 'border-box',
    '& > svg': {
      marginTop: '20px',
    },
  },
  SandBoxMenu: {
    '& > div + div': {
      fontFamily: 'var(--font-general)',
      background: 'var(--background-main)',
      fontWeight: '400',
      fontSize: 10,
      lineHeight: '12px',
      padding: '0',
      margin: '0',
      color: '#5877a2',
    },
    '& > div ul li': {
      fontFamily: 'var(--font-general)',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: '14px',
      padding: '5px 5px',
      margin: '0',
      color: '#5877a2',
      borderBottom: '1px #262e3d solid',
    },
  },
  disciplineIcon: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '-2px 5px 0 0',
  },
  shrink: {
    transform: 'translate(14px, 1px) scale(0.75) !important',
  },
});

export const RequestType = theme => ({
  wrapContent: {
    borderRadius: '4px',
    width: 'calc(100% - 6px)',
    height: '32px',
    '& svg': {
      right: '10px',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  formControl: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    '& label': {
      top: '0px',
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: '12px',
      color: 'var(--color)',
      fontFamily: 'var(--font-general)',
      fontWeight: 'var(--font-weight)',
    },
  },
  disableShrink: {
    transform: 'translate(14px, 13px) scale(1) !important',
  },
  SandBoxInput: {
    padding: '12px 15px 0 15px',
    height: '32px',
    fontSize: '12px',
    border: '1px solid var(--color-noSelect)',
    color: 'var(--color)',
    fontWeight: '400',
    boxSizing: 'border-box',
    '& > svg': {
      marginTop: '20px',
    },
  },
  SandBoxMenu: {
    '& > div + div': {
      fontFamily: 'var(--font-general)',
      background: 'var(--background-main)',
      fontWeight: '400',
      fontSize: 10,
      lineHeight: '12px',
      padding: '0',
      margin: '0',
      color: '#5877a2',
    },
    '& > div ul li': {
      fontFamily: 'var(--font-general)',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: '14px',
      padding: '5px 5px',
      margin: '0',
      color: '#5877a2',
      borderBottom: '1px #262e3d solid',
    },
  },
  disciplineIcon: {
    width: '11px',
    height: '11px',
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '-2px 5px 0 0',
  },
  shrink: {
    transform: 'translate(15px, 2px) scale(1) !important',
  },
  outlined: {
    transform: 'translate(10px, 9px) scale(1) !important',
    color: 'var(--color)',
  },
  focused: {
    color: 'var(--color) !important',
  },
});

export const locations = theme => ({
  wrapContent: {
    textTransform: 'initial',
    borderRadius: '4px',
    width: 'calc(100% - 6px)',
    backgroundColor: 'var(--background-sheet)',
    height: '40px',
    '& svg': {
      right: '10px',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  formControl: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    '& label': {
      top: '0px',
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: '12px',
      color: 'var(--color-noSelect)',
      fontFamily: 'var(--font-general)',
      fontWeight: 'var(--font-weight)',
    },
  },
  disableShrink: {
    transform: 'translate(14px, 13px) scale(1) !important',
  },
  SandBoxInput: {
    padding: '20px 15px 0 15px',
    height: '40px',
    fontSize: '14px',
    fontFamily: 'var(--font-general)',
    backgroundColor: 'var(--background-main)',
    color: 'var(--color)',
    fontWeight: '400',
    boxSizing: 'border-box',
    '& > svg': {
      marginTop: '20px',
    },
  },
  SandBoxMenu: {
    '& > div + div': {
      fontFamily: 'var(--font-general)',
      background: 'var(--background-main)',
      fontWeight: '400',
      fontSize: 10,
      lineHeight: '12px',
      padding: '0',
      margin: '0',
      color: '#5877a2',
    },
    '& > div ul li': {
      fontFamily: 'var(--font-general)',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: '14px',
      padding: '5px 5px',
      margin: '0',
      color: '#5877a2',
      borderBottom: '1px #262e3d solid',
    },
  },
  disciplineIcon: {
    width: '6px',
    height: '40px',
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  shrink: {
    transform: 'translate(14px, 5px) scale(1) !important',
  },
  focused: {
    color: 'var(--color-noSelect) !important',
  },
});

export const buildings = theme => ({});

export const SelectFieldStyles = theme => ({
  formControl: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    // '& label': {
    //   top: '0px',
    //   transform: 'translate(14px, 5px) scale(1) !important',
    // },
  },
  disableShrink: {
    // transform: 'translate(14px, 5px) scale(1) !important',
  },
  formControlRoot: {
    '& label': {
      transform: 'translate(12px, 13px) scale(1) !important',
      // letterSpacing: '-0.06em',
      // transform: 'translate(14px, 14px) scale(1)',
      fontSize: '12px',
      color: 'var(--color-labelSelect)',
      fontFamily: 'var(--font-general)',
      fontWeight: 'var(--font-weight)',

      '@media (max-width: 535px)': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '33px',
        whiteSpace: 'nowrap',
      },
    },
  },
  selectRoot: {
    background: 'var(--background-sheet)',
    borderRadius: '2px',
    height: '24px',

    '& label': {
      display: 'none',
      // fontSize: '12px',
      // color: 'var(--color-noSelect)',
      // fontFamily: 'var(--font-general)',
      // fontWeight: 'var(--font-weight)',
    },
  },
  disabled: {
    background: 'var(--background-header) !important',
    '& svg': {
      color: 'red',
    },
  },
  multiSelectRoot: {
    background: 'var(--background-grid)',
    borderRadius: '2px',
    height: '100%',
    '& div': {
      paddingTop: '1.375rem',
      paddingLeft: '0.5rem',
      paddingBottom: '0.5rem',

      minWidth: '15.75rem',
      maxWidth: '15.75rem',
    },
  },
  focused: {
    transform: 'translate(7px, 13px) scale(1) !important',
    color: 'var(--color-labelSelect) !important',
  },
});

export const MultiSelectFieldStyles = theme => ({
  formControl: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    // height: '30px',
    // lineHeight: '30px',
    '& label': {
      top: '0px',
      // width: 'calc(100% - 45px)',
      transform: 'translate(14px, 10px) scale(1) ',
      fontWeight: 'var(--font-weight)',
    },
  },
  disableShrink: {
    transform: 'translate(14px, 5px) scale(1) !important',
  },
  formControlRoot: {
    '& label': {
      // letterSpacing: '-0.06em',
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: '12px',
      fontWeight: 'var(--font-weight)',
    },
  },
  selectRoot: {
    background: 'var(--background-sheet)',
    border: 'var(--background-border)',
    borderRadius: '4px',
    height: '32px',
  },
  multiSelectRoot: {
    background: 'var(--background-sheet)',
    border: 'var(--background-border)',
    borderRadius: '4px',
    height: '29px',
    '& div': {
      padding: '13px 14px 0px 14px !important',
    },
  },
});

export const InputFieldRequest = theme => ({
  SandBoxInputField: {
    height: '44px',
    width: '49px',
    borderRadius: '4px',
    border: 'var(--background-border)',
    // paddingLeft: '10px',
    backgroundColor: 'var(--background-main)',
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
    margin: 0,
    '& label': {
      top: '-5px',
      width: '100%',
      textAlign: 'center',
    },
    '& div': {
      marginTop: '0',
      '&:before': {
        display: 'none',
      },
    },
  },
  SandBoxInput: {
    padding: '28px 0 0 5px',
    // letterSpacing: '-0.06em',
  },
  shrink: {
    transform: 'translate(0, 10.5px) scale(0.75)',
  },
});

export const InputFieldCommitment = theme => ({
  SandBoxInputField: {
    height: '50px',
    width: '100%',
    borderRadius: '4px',
    border: 'var(--background-border)',
    paddingLeft: '10px',
    backgroundColor: 'var(--background-main)',
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
    margin: 0,
    '& label': {
      top: '-5px',
      width: '100%',
      textAlign: 'center',
    },
    '& div': {
      marginTop: '0',
      '&:before': {
        display: 'none',
      },
    },
  },
  SandBoxInput: {
    padding: '28px 0 0 5px',
    // letterSpacing: '-0.06em',
  },
  shrink: {
    transform: 'translate(0, 10.5px) scale(0.75)',
  },
});
