import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createMilestone,
  getAssignedMilestone,
  getMilestonesInfo,
  getProjectInfo,
  issuedMilestone,
  removeAssignedMilestone,
} from '../../service/redmineApi/redmineApi';
import { errorsSlice } from '../errors/errorsSlice';
import RedmineApi from '../../service/Api/redmineApi';
import { IAssignedMilestone, IErrorBody, RedmineItem } from './redmineSlice.model';

const { setShowErrorData } = errorsSlice.actions;

export const getRedmineProjects = createAsyncThunk<RedmineItem[], { rmApiKey: string; projectList: string[] }>(
  'redmineReducer/getRedmineProjects',
  async (payload, thunkApi) => {
    try {
      const response = await getProjectInfo(payload.rmApiKey, payload.projectList);
      return response;
    } catch (error: any) {
      thunkApi.dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRedmineMilestones = createAsyncThunk<RedmineItem[], { projectId: string | number; rmApiKey: string }>(
  'redmineReducer/getRedmineMilestones',
  async (payload, thunkApi) => {
    try {
      const response = await getMilestonesInfo(payload.projectId, payload.rmApiKey);
      return response;
    } catch (error: any) {
      thunkApi.dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRedmineAssignedMilestone = createAsyncThunk<IAssignedMilestone, { milestoneId: string | number; rmApiKey: string }>(
  'redmineReducer/getRedmineAssignedMilestone',
  async (payload, thunkApi) => {
    try {
      const response = await getAssignedMilestone(payload.milestoneId, payload.rmApiKey);
      return response;
    } catch (error: any) {
      thunkApi.dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createRedmineMilestone = createAsyncThunk<
  IAssignedMilestone,
  { rmApiKey: string; projectId: string | number; pcdID: number; body: {} },
  { rejectValue: IErrorBody }
>('redmineReducer/createRedmineMilestone', async (payload, thunkApi) => {
  try {
    const response = await createMilestone(payload.rmApiKey, payload.projectId, payload.body);
    const redmineId = {
      owner_id: payload.pcdID,
      owner_type: 'deliverable',
      redmine_ids: [`${response.id}`],
      action: 'add',
    };
    await RedmineApi.setRedmineId(redmineId);
    return response;
  } catch (error: any) {
    thunkApi.dispatch(setShowErrorData({ ...error.response, message: error.response.data.errors[0], statusCode: error.response.status }));
    const redmineError = {
      code: error.response.status,
      message: error.response.data.errors[0],
    };
    return thunkApi.rejectWithValue(redmineError);
  }
});

export const removeRedmineAssignedMilestone = createAsyncThunk<IAssignedMilestone, { milestoneId: string | number; rmApiKey: string }>(
  'redmineReducer/removeRedmineAssignedMilestone',
  async (payload, thunkApi) => {
    try {
      await removeAssignedMilestone(payload.milestoneId, payload.rmApiKey);
    } catch (error: any) {
      thunkApi.dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const issuedRedmineMilestone = createAsyncThunk<IAssignedMilestone, { milestoneId: string | number; rmApiKey: string }>(
  'redmineReducer/issuedRedmineMilestone',
  async (payload, thunkApi) => {
    try {
      await issuedMilestone(payload.milestoneId, payload.rmApiKey);
    } catch (error: any) {
      thunkApi.dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
