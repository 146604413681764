import React, { FC } from 'react';
import ChipsStandardTag from '../../../Chips/ChipsStandardTag/ChipsStandardTag';
import CustomTooltip from '../../../Tooltip/Tooltip';
import { LocationCollectionModel, RequestBuildingModel, SimpleTitleModel } from '../../../../../models';
import { NLDeliverablesModel, NLSubmittalsModel } from '../../../../../store/needlist/needListModels';
import cn from 'classnames';

interface IProps {
  data: RequestBuildingModel[] | LocationCollectionModel[] | SimpleTitleModel[] | NLDeliverablesModel[] | NLSubmittalsModel[];
  elementRef?: any;
  themeClass?: string;
  handleOpenLinkPopover?: () => void;
  isFullWidth: boolean;
}

const LocationList: FC<IProps> = ({ data, elementRef, themeClass, handleOpenLinkPopover, isFullWidth }) => {
  return (
    <div className={cn(`${themeClass}_container_locationsBlock`, { ['-isFullWidth']: isFullWidth })}>
      {data.map(item => {
        return (
          <div className={`Badge`} key={`Badge-moreLocation-1-${item.id}`}>
            <CustomTooltip placement={'top'} key={`moreLocation-1-${item.id}`} title={item.title || item.name} style={{ width: '100%' }}>
              <ChipsStandardTag
                chipsRef={elementRef}
                value={item.title || item.name}
                isRelinkFileIcon={item?.link}
                handleClickRelink={e => handleOpenLinkPopover(e, item)}
                isFullWidth={isFullWidth}
              />
            </CustomTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default LocationList;
