import React from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import TokenIcon from '../TokenIcon/TokenIcon';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import cn from 'classnames';

import './CustomAccordionStyles.scss';

interface IProps {
  accordionOpened: boolean;
  openAccordion: (type?: string) => void;
  label: string;
  isMobile?: boolean;
}

const CustomAccordion: React.FC<IProps> = ({ accordionOpened, openAccordion, label, isMobile = false }) => {
  const themeClass = useGetThemeClass('b-customAccordion');

  const handleOpenAccordion = () => {
    openAccordion();
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-isMobile']: isMobile,
      })}
    >
      <div className={`${themeClass}_container`} onClick={handleOpenAccordion}>
        {isMobile ? (
          <SystemButton type={accordionOpened ? 'chevron-down' : 'chevron-right'} variant={'transparent'} size={'md'} isMobile={isMobile} />
        ) : (
          <div className={`${themeClass}_icon`}>
            <TokenIcon iconName={accordionOpened ? 'chevron-down' : 'chevron-right'} size={16} />
          </div>
        )}
        <span className={`${themeClass}_label`}>{label}</span>
      </div>
    </div>
  );
};

export default CustomAccordion;
