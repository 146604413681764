import { createAsyncThunk } from '@reduxjs/toolkit';
import { PCDApi } from '../../service/Api/pcd/types';
import { errorsSlice } from '../errors/errorsSlice';
import {
  GetLinkingItemsModel,
  GetRelationTabModel,
  LinkModel,
  RelatedTabResponseModel,
  SetValidityStatusPayload,
  UnlinkModel,
} from './relatedItemsSlice.model';
import relatedItemsApi from '../../service/Api/relatedItems/relatedItemsApi';

const { setShowErrorData } = errorsSlice.actions;

export const getRelationTab = createAsyncThunk<RelatedTabResponseModel, GetRelationTabModel>(
  'relatedItems/getRelationTab',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await relatedItemsApi.getRelationTab(payload);

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const linkRelationItem = createAsyncThunk<RelatedTabResponseModel, LinkModel>(
  'relatedItems/linkRelationItem',
  async (payload, { dispatch, rejectWithValue }) => {
    const { linkData, callback } = payload;

    try {
      const response = await relatedItemsApi.linkRelationItem(linkData);

      if (callback) {
        callback();
      }

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const unlinkRelationItem = createAsyncThunk<RelatedTabResponseModel, UnlinkModel>(
  'relatedItems/unlinkRelationItem',
  async (payload, { dispatch, rejectWithValue }) => {
    const { unlinkData, callback } = payload;

    try {
      const response = await relatedItemsApi.unlinkRelationItem(unlinkData);

      if (callback) {
        callback();
      }

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getLinkingItems = createAsyncThunk<PCDApi.LinkSuggestionItem[], GetLinkingItemsModel>(
  'relatedItems/getLinkingSuggestions',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, owner_id, owner_type, sync_owner_type, callback } = payload;
    try {
      const response = await relatedItemsApi.getLinkingItems({ project_id, owner_id, owner_type, sync_owner_type });

      if (callback) {
        callback(Object.values(response.data));
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setValidityStatus = createAsyncThunk<RelatedTabResponseModel, SetValidityStatusPayload>(
  'relatedItems/setValidityStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    const { validityStatusData, callback } = payload;
    try {
      const response = await relatedItemsApi.setValidityStatus(validityStatusData);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
