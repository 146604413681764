import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import { FileModel, RequestUserModel } from '../../../../../models';
import { OverdueStateNames, PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { DeliverableCardTabNamesModel } from '../../constans/constants';
import { collectMentions } from '../../../../../helpers/DescriptionFieldHelper/MentionsHelper';
import FileUploader from '../../../../controls/FileUploader/FileUploader';

import './DeliverableStyles.scss';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

interface IProps {
  selectedRequest: PCDCard;
  isCreateRevision: boolean;
  isDragRequestCard: boolean;
  activeTab: DeliverableCardTabNamesModel;
  currentDescription: string;
  errors: any;
  selectedFiles: FileModel[] | FileList | any;
  loadingFilesName: string[];
  handleFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  handleDownloadAll: (e: any) => void;
  setDescription: (descriptionValue: string, delta?: any, source?: any, editor?: any) => void;
  changeMentions: (newMentions: { id: number; email: string }[]) => void;
  renderHistoryAction: (
    type: OverdueStateNames,
    date: string | Date,
    firstName: string,
    lastName: string,
    userProfile: RequestUserModel,
    isShowTime: boolean,
  ) => React.ReactElement;
  isPCDFieldsDisabled: boolean;
  isRevisionSubmitted: boolean;
  isNextRevisionDrafted: boolean;
  nextRevisionObject: PCDCard;
  handleOpenReasonsPopUp: (isUpdateReasonsPopUp: boolean) => void;
  isHiddenFields: boolean;
  moreLimitFiles: FileModel[] | FileList | any;
  handleMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
}

const DeliverableTab: FC<IProps> = ({
  selectedRequest,
  isCreateRevision,
  isDragRequestCard,
  activeTab,
  errors,
  selectedFiles,
  loadingFilesName,
  handleFile,
  delSavedFile,
  handleDownloadAll,
  currentDescription,
  setDescription,
  changeMentions,
  renderHistoryAction,
  isPCDFieldsDisabled,
  isRevisionSubmitted,
  isNextRevisionDrafted,
  nextRevisionObject,
  handleOpenReasonsPopUp,
  isHiddenFields,
  moreLimitFiles,
  handleMoreLimitFiles,
}) => {
  const themeClass = useGetThemeClass('b-cardDeliverable-deliverableTab');
  const { isMobile } = GetResolution();

  const pcdInfoLoading = useAppSelector<boolean>(state => state.pcdReducer.pcdInfoLoading);

  const [textValue, setTextValue] = useState('');
  const [isKeyPress, setIsKeyPress] = useState<boolean>(false);

  const collectFinalFiles =
    selectedRequest?.files && selectedFiles ? [...(selectedRequest?.files || []), ...(selectedFiles || [])] : [...(selectedFiles || [])];

  const showInfoBlock = useMemo(() => {
    return !isCreateRevision && selectedRequest?.author?.profile && selectedRequest?.issued_date;
  }, [isCreateRevision, selectedRequest]);

  const isReasonTextShown: boolean = useMemo(() => {
    return isRevisionSubmitted && selectedRequest && selectedRequest.revision !== selectedRequest.revisions.length - 1;
  }, [isRevisionSubmitted, selectedRequest]);

  const handleChangeFile = (e: any, isDrag?: boolean, isRestrict?: boolean) => {
    handleFile(e, isDrag, isRestrict);
  };

  const isDisableFileList = isPCDFieldsDisabled;

  useEffect(() => {
    setIsKeyPress(false);
  }, [selectedRequest]);

  useEffect(() => {
    if (typeof currentDescription === 'string') {
      setTextValue(currentDescription);
    }
  }, []);

  useEffect(() => {
    if (currentDescription !== textValue && currentDescription !== '<p><br></p>' && currentDescription !== '<p></p>') {
      setTextValue(currentDescription || '');
    }
  }, [currentDescription]);

  const handleChange = (value, delta, source, editor) => {
    setTextValue(value);
    setDescription && setDescription(value);

    if (isKeyPress) {
      changeMentions(collectMentions(editor.getContents()));
    }
  };

  const onKeyPress = () => {
    setIsKeyPress(true);
  };

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-sidePeekView']: isDragRequestCard,
        })}
        style={activeTab !== 'deliverable' ? { display: 'none' } : {}}
      >
        <div className={`${themeClass}_container`}>
          <div className={cn(`${themeClass}_contentBlock`)}>
            <div className={`${themeClass}_mainBlock`}>
              <TextQuillEditor
                fieldId={'deliverableTab'}
                showToolbar
                value={textValue}
                handleChange={handleChange}
                placeholder={'Deliverable description'}
                disabled={isPCDFieldsDisabled}
                customContainerClassName={cn(`${themeClass}_description`)}
                onKeyPress={onKeyPress}
                error={errors?.desc || errors?.files || null}
              />
              <FileUploader
                isCard={true}
                handleChangeFile={handleChangeFile}
                isCardSidePeek={isDragRequestCard}
                isHiddenFields={isHiddenFields}
                collectFinalFiles={collectFinalFiles}
                isDisableFileList={isDisableFileList}
                files={selectedFiles || []}
                selectedRequest={selectedRequest}
                source={'PCD'}
                cardType={'pcd'}
                isEditable={true}
                isEditMode={true}
                delSavedFile={delSavedFile}
                fileLoading={pcdInfoLoading}
                loadingFilesName={loadingFilesName}
                handleDownloadAll={handleDownloadAll}
                moreLimitFiles={moreLimitFiles}
                handleMoreLimitFiles={handleMoreLimitFiles}
                customFileUploaderClassName={`${themeClass}_customDeliverableFileUploader${isDragRequestCard ? '_sidePeekView' : ''}`}
              />
            </div>
            <div className={`${themeClass}_infoBlock`}>
              {showInfoBlock ? (
                <div className={`${themeClass}_infoBlock_item`}>
                  {renderHistoryAction(
                    selectedRequest?.overdue_state,
                    selectedRequest?.issued_date,
                    selectedRequest?.author?.profile?.first_name,
                    selectedRequest?.author?.profile?.last_name,
                    selectedRequest?.author,
                    true,
                  )}
                </div>
              ) : null}
              {isReasonTextShown ? (
                <div className={`${themeClass}_infoBlock_item`}>
                  <span className={`${themeClass}_infoBlock_item_newRevisionText`}>
                    {`Revision #${selectedRequest.revision} ${
                      isNextRevisionDrafted ? 'will soon be superseded by Revision' : 'has been superseded by Revision'
                    } #${nextRevisionObject.revision}.`}
                  </span>
                  <CustomButton
                    type={'text-plain'}
                    size={'sm'}
                    clickHandler={() => handleOpenReasonsPopUp(true)}
                    title={isNextRevisionDrafted ? 'Edit reason' : 'Preview reason'}
                    isMobile={isMobile}
                    customClassName={`${themeClass}_infoBlock_button`}
                    customTooltipBasisClassName={`${themeClass}_infoBlock_buttonTooltipBasis`}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliverableTab;
