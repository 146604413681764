import ligthImg from '../../assets/images/skeleton/wb__disciplines__bg__light.jpg';
import ligthCellImg from '../../assets/images/skeleton/wb_tableCell__light.jpg';
import lightDateCellImg from '../../assets/images/skeleton/wb__date__bg__light.jpg';

export const lightTheme = {
  colors: {
    background: {
      grid: '#D2D7DF', // btn border | btn background | table border | SandBox
      header: '#E2E7EC',
      sheet: '#F1F4F8', // Popup header|
      main: '#FFFFFF', // Popup body| main background | fields
      alterMain: '#B4BDCF', // Pcd popup body
      color1: '#A5B0C6', // Request header |
      color2: '#FFBC71', // Commitment header | NF | GMP
      color3: '#A5B0C6', // SandBox active
      color4: '#E2E7EC', // SandBox DUE UPD NEW
      color5: '#38455E', // Reporting table
      border: '1px solid #D2D7DF',
      tour: '#FFFFFF',
      isDeactivated: '#F1F4F8', // SandBox Request card add deactivated status
      carousel: '#D2D7DF', // Carousel in ProjectGrid
      arrow: '#BCC1C8', // Carousel arrow in ProjectGrid
      hashtag: '#D2D7DF', // hashtag default background color
      selectFilterDisabled: '#D2D7DF', // select filter disabled state
    },
    sandBox: {
      graphs: {
        alertList: {
          background: '#F7F7F7',
        },
        alertSnippet: {
          background: 'rgba(89, 105, 137, 0.08)',
          headText: '#0B1324',
          midText: '#596989',
        },
        heatmap: {
          text: '#0B1324',
          arrowBackground: 'rgba(89, 105, 137, 0.08)',
          tooltip: {
            text: '#FFFFFF',
            background: '#0B1324',
          },
        },
      },
      relationSwitcher: {
        active: {
          label: '#FC8F14',
          default: '#F7F7F7',
          hover: '#F7CD9D',
          pressed: '#F5C792',
          disabled: '#F5C792',
          border: '1px solid #FC8F14',
        },
        plain: {
          label: '#72819E',
          default: '#DDE0E5',
          hover: '#F7F7F7',
          pressed: '#BEC4D0',
          disabled: '#EBECEF',
        },
      },
      tooltip: {
        background: '#D2D7DF',
      },
      tab: {
        selected: '#0B1324',
        defaultText: '#596989',
        hover: 'rgba(89, 105, 137, 0.06)',
      },
      section: {
        active: 'rgba(89, 105, 137, 0.16)',
        hover: 'rgba(89, 105, 137, 0.08)',
        textHover: '#22304D',
      },
      contentSwitcher: {
        hover: 'rgba(89, 105, 137, 0.08)',
      },
      table: {
        scrollBarThumb: '#CCD1DB',
        scrollBarTrack: '#F0F0F0',
        borderColor: '#CCD1DB',
      },
      accordion: {
        arrow: '#72819E',
        badge: 'rgba(89, 105, 137, 0.16);',
      },
      snippet: {
        activeBackground: '#F0F0F0',
        activeHoverBackground: '#E7E8EA',
        icon: '#596989',
        fadedSectionIcon: '#72819E',
        hover: 'rgba(89, 105, 137, 0.08)',
        focus: 'rgba(89, 105, 137, 0.16)',
        focusBorder: '1.2px solid #72819E',
        filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2))',
      },
      text: {
        label: '#596989', // SandBox label
        text: '#8BA1B6', // SandBox text ( responsible party )
        text2: '#22304D', // SandBox text ( milestone , responsible dis. )
        headlineText: '#22304D', // text of the headlines
        tabText: '#22304D', // SandBox tab text
      },
    },
    popup: {
      header: '#D2D7DF',
      content: '#E2E7EC',
    },
    text: {
      main: '#38455E',
      secondary: '#838EA2',
      noSelect: '#B4BDCF', // Labels | No selected
      colorSelect: '#38455E', // SandBox text
      colorNoSelect: '#838EA2', // SandBox text
      helpMenuColor: '#38455E',
      labelSelect: '#38455E', // Nf card labels
      labelSelected: '#38455E', // Nf card label selected
    },
    buttons: {
      default: '#F1F4F8',
      hoverMenu: '#F1F4F8',
      activeMenu: '#D2D7DF',
      defaultMenuText: '#38455E',
      hoverMenuText: '#B4BDCF',
      activeMenuText: '#38455E',
      deactivated: '#B4BDCF',
    },
    // Btn primal
    buttons2: {
      unactive: '#E2E7EC',
      active: '#A5B0C6',
      hover: '#FFBC71',
      unactiveText: '#B4BDCF',
      activeText: '#FFFFFF',
      hoverText: '#FFFFFF',
    },
    btnAction: {
      disable: '#E2E7EC',
      disableText: '#838EA1',
      default: '#FFBC71',
      defaultText: '#38455E',
      hover: '#F3A359',
      hoverText: '#38455E',
    },
    btnDefault: {
      disable: '#F1F4F8',
      disableText: '#838EA1',
      default: '#D2D7DF',
      defaultText: '#38455E',
      hover: '#B4BDCF',
      hoverText: '#38455E',
    },
    btnOutlined: {
      disable: '#E5E5E5',
      disableBorder: '1px solid #D2D7DF',
      disableText: '#B4BDCF',
      default: '#E5E5E5',
      defaultBorder: '1px solid #38455E',
      defaultText: '#38455E',
      hover: '#F1F4F8',
      hoverBorder: '1px solid #38455E',
      hoverText: '#38455E',
    },
    // CustomButton
    generalButton: {
      default: 'transparent',
      defaultBorder: '1px solid #D2D7DF',
      hover: '#E2E7EC',
      hoverBorder: '1px solid #E2E7EC',
      defaultText: '#38455E',
      hoverText: '#B4BDCF',
    },
    // CustomButton Accept
    buttonAccept: {
      default: '#A5B0C6',
      defaultText: '#FFFFFF',
      hover: '#B4BDCF',
      hoverText: '#FFFFFF',
      disable: '#D2D7DF',
      disableText: '#838EA2',
    },
    // CustomButton Commit
    buttonCommit: {
      hover: '#B4BDCF',
    },
    // CustomButton Decline
    buttonDecline: {
      default: '#ED5147',
      defaultText: '#ECEFF2',
      hover: '#FFBC71',
      hoverText: '#ECEFF2',
      disable: '#D2D7DF',
      disableText: '#838EA2',
    },
    buttonPullRequest: {
      default: 'transparent',
      defaultBorder: '1px solid #38455E',
      defaultText: '#38455E',
      hover: 'transparent',
      hoverBorder: '1px solid #F3A359',
      hoverText: '#F3A359',
      disable: 'transparent',
      disableBorder: 'border: 1px solid #D2D7DF',
      alternateBorder: '1px solid #D2D7DF',
      disableText: '#838EA2',
    },
    // Buttons Cancel
    outlinedButton: {
      default: 'transparent',
      defaultBorder: '1px solid #838EA2',
      disableBorder: '1px solid #D2D7DF',
      hover: '#E2E7EC',
      disabled: '#E2E7EC',
      defaultText: '#38455E',
      hoverText: '#38455E',
      disabledText: '#B4BDCF',
    },
    orange: {
      default: '#FFBC71',
      defaultText: '#FFFFFF',
      hover: '#F3A359',
      hoverText: '#FFFFFF',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    // Change a company \ Change a dueDate
    pourButton: {
      default: '#E2E7EC',
      defaultText: '#38455E',
      hover: '#B4BDCF',
      hoverText: '#38455E ',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    commentButton: {
      default: '#FFFFFF',
      defaultText: '#38455E',
      hover: '#D2D7DF',
      hoverText: '#38455E',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    buildingButton: {
      default: '#E2E7EC',
      defaultText: '#38455E',
      hover: '#A5B0C6',
      hoverText: '#38455E',
      disable: '#E2E7EC',
      disableText: '#A5B0C6',
    },
    showHideRequestButton: {
      defaultText: '#838EA1',
      hoverText: '#38455E',
    },
    cancelButton: {
      default: 'transparent',
      defaultBorder: '1px solid #838EA2',
      disableBorder: '1px solid #D2D7DF',
      hover: '#B4BDCF',
      disabled: 'transparent',
      defaultText: '#38455E',
      hoverText: '#38455E',
      disabledText: '#D2D7DF',
    },
    tags: {
      tag1: '#778EA4',
      tag2: '#55738E',
      tag3: '#365C7E',
      color: '#fff',
    },
    notificationBell: {
      counter: 'yellow',
      today: '#65C17F',
    },
    phasePlan: {
      lod350: '#BDC4D4',
      lod325: '#A5B0C6',
      lod300: '#8E9CB9',
      lod290: '#7688AB',
      lod200: '#5F749E',
      permit: '#4684BA',
      construction: '#98A2BA',
      color: '#FFFFFF',
    },
    permissions: {
      color1: '#263C5C',
      color2: '#3A5676',
      color3: '#517597',
      color4: '#7392AF',
    },
    driverMode: {
      mainDriver: '#FFBC71',
      selectedDriver: '#F3A359',
      possibleDriver: '#5E739E',
    },
    switcherThemeIcon: {
      sun: '#8FA6BB',
      moon: '#E5E9ED',
    },
    rest: {
      predecessorColor: '#38455E',
      predecessorBackground: '#D2D7DF',
    },
    select: {
      color: '#38455E',
      background: '#E2E7EC',
      backgroundHover: '#F1F4F8',
      hoverText: '#38455E',
      backgroundActive: '#F1F4F8',
    },
    calendarPCD: {
      disabledBorder: '#B4BDCF',
    },
    navIcons: {
      active: '#596989',
      hover: '#909BB0',
      default: '#909BB0',
    },
    logo: {
      part1: '#5E739D',
      part2: '#A5B0C6',
      text: '#F1F4F8',
    },
    dropDown: {
      default: '#2D384E',
      hover: '#F3A359',
      active: '#B3C3D3',
    },
    selectedFilterDropDown: {
      default: '#F1F4F8',
      hover: '#E2E7EC',
    },
    multiSelect: {
      defaultText: '#38455E',
      hoveredText: '#F3A359',
    },
    multiSelectSearch: {
      default: '#FFFFFF',
    },
    tooltip: {
      default: '#D2D7DF',
      defaultText: '#38455E',
    },
    checkbox: {
      default: '#838EA2',
    },
    // CustomButton for more files
    fileButtons: {
      showMore: '#838EA1',
      attachFile: '#838EA1',
    },
    svgIcons: {
      color: '#838EA2',
      arrowColor: '#838EA2',
      removeIconBackground: '#D2D7DF',
      removeIconColor: '#838EA2',
    },
    textField: {
      label: '#8BA1B6',
      border: '#2D384E',
      font: '#38455E',
      error: '#FF0000',
    },
    teamAvatar: {
      font: '#38455E',
      border: '#2D384E',
    },
    requestFilters: {
      default: '#38455E',
      hover: '#F3A359',
      active: '#F3A359',
    },
  },
  fonts: {
    general: 'Proxima Nova',
    menuItemSize: '12px',
    weight: '600',
  },
  images: {},
  svgImages: {
    settings: {
      default: '#5F6875',
      hover: '#F3A359',
      active: '#8BA1B6',
    },
    requestNavigation: {
      default: '#FFFFFF',
      hover: '#F3A359',
      disable: '#2D384E',
    },
  },
  skeletons: {
    wbDisciplines: `url("${ligthImg}")`,
    wbTableCell: `url("${ligthCellImg}")`,
    wbDateCell: `url("${lightDateCellImg}")`,
  },
};
