/* eslint-disable no-console */
import React, { Component } from 'react';
import * as ROUTES from '../../../navigation/routes';
import ProjectView from './ProjectView';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { ProjectModel, UserModel } from '../../../models';
import { ClientProjectModel } from '../../../store/user/userReducerModel';
import { getProjectDetails } from '../../../store/project/projectLogic';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { getPhasePlanSelectData, updateProject, getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { phasePlanSlice } from '../../../store/phasePlan/phasePlanSlice';
import { getClientsProjects } from '../../../store/user/userThunk';
import { userSlice } from '../../../store/user/userSlice';
import { asideSlice } from '../../../store/aside/asideSlice';
import { filterSlice } from '../../../store/filters/filtersReducer';
import { commonSlice } from '../../../store/common/commonReducer';
import { SpecAndSubsSlice } from '../../../store/specAndSubs/specAndSubsSlice';
import MixpanelHelper from '../../../service/Mixpanel/Mixpanel';
import { RouteComponentProps, withRouter } from '../../../navigation/withRouter/withRouter';
const { clearNeedListData, clearNeedListFilters, resetSandboxLoadings, changeActiveProject } = requestSlice.actions;
const { setActiveTabProject, clearProjectData } = phasePlanSlice.actions;
const { setActiveProject } = userSlice.actions;
const { setActiveModule } = asideSlice.actions;
const { hardResetFiltersReducer } = filterSlice.actions;
const { clearRequestsFilters } = commonSlice.actions;
const { clearSubmittalsData, clearSpecificationData } = SpecAndSubsSlice.actions;

interface IProps extends RouteComponentProps {
  userInfo: UserModel;
  projectInfo: any;
  selected_client_id: number | string;
  getClientsProjects: (data: { clientId: number | string; code?: string }) => void;
  clientProjectsLoading: boolean;
  clientProjects: ClientProjectModel;
  clients: any;
  phasePlanSelect: any;
  getPhasePlanSelectData: () => void;
  updateProject: (data: any) => void;
  setActiveProject: (data: number | string) => void;
  changeActiveProject: (data: number | string) => void;
  getProjectDetails: (data: number | string) => void;
  getProjectById: (data: number | string) => void;
  activeTabProject: number;
  setActiveTabProject: (data: number) => void;
  clearNeedListData: () => void;
  clearNeedListFilters: () => void;
  clearProjectData: () => void;
  clearSubmittalsData: () => void;
  clearSpecificationData: () => void;
  setActiveModule: ({ module: string }) => void;
  hardResetFiltersReducer: () => void;
  clearRequestsFilters: () => void;
  active_project_id: number;
}

class ProjectComponent extends Component<IProps, {}> {
  state = {
    activeTab: 0,
  };

  handleCreateProject = () => {
    const { clearProjectData, navigate } = this.props;

    navigate(ROUTES.getCreateProject(sessionStorage.getItem('project_name')));
    clearProjectData();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.props.getPhasePlanSelectData();
    MixpanelHelper.track('Project module visited');
  }

  componentDidUpdate(prevProps) {}

  handleChangeView = activeTab => {
    this.setState({
      activeTab: activeTab,
    });
  };

  handleClickOpenEdit = (project: ProjectModel) => {
    const { navigate } = this.props;
    this.props.setActiveProject(project.id);
    this.props.changeActiveProject(project.id);
    this.props.getProjectDetails(project.id);
    navigate(ROUTES.getCreateProjectIdSheet(sessionStorage.getItem('project_name'), project.id, 'general'));
  };

  selectProject = (id: number | string) => {
    const {
      setActiveProject,
      changeActiveProject,
      getProjectDetails,
      getProjectById,
      clearNeedListData,
      resetSandboxLoadings,
      hardResetFiltersReducer,
      setActiveModule,
      active_project_id,
      clearNeedListFilters,
      clearRequestsFilters,
      clearSubmittalsData,
      clearSpecificationData,
      navigate,
      urlProject,
    } = this.props;

    if (id !== active_project_id) {
      setActiveProject(id);
      changeActiveProject(id);
      getProjectDetails(id);
      getProjectById(id);
      clearNeedListData();
      resetSandboxLoadings();
      clearNeedListFilters();
      hardResetFiltersReducer();
      clearRequestsFilters();
      clearSubmittalsData();
      clearSpecificationData();
    }
    setActiveModule({ module: 'commandCenter' });

    navigate({
      pathname: `/${urlProject[0]}/command-center`,
      search: '',
    });
  };

  render() {
    const {
      userInfo,
      clientProjects,
      phasePlanSelect,
      clients,
      selected_client_id,
      updateProject,
      getClientsProjects,
      setActiveTabProject,
      activeTabProject,
    } = this.props;

    const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '1' || key === '4').length;
    const projects = Object.values(clientProjects || {});
    const currentClient = clients?.find(client => client.id === +selected_client_id);

    return (
      <ProjectView
        isAdmin={isAdmin}
        handleCreateProject={this.handleCreateProject}
        projects={projects}
        activeTab={activeTabProject}
        handleChangeView={setActiveTabProject}
        handleClickOpenEdit={this.handleClickOpenEdit}
        projectTypes={phasePlanSelect.project_types}
        currentClient={currentClient}
        updateProject={updateProject}
        getClientsProjects={getClientsProjects}
        selectProject={this.selectProject}
      />
    );
  }
}

const mapStateToProps = ({ userReducer, projectReducer, phasePlan }) => ({
  projectsLoading: projectReducer.loading,
  userInfo: userReducer.userInfo,
  projectInfo: projectReducer.projectInfo,
  active_project_id: userReducer.active_project_id,
  selected_client_id: userReducer.selected_client_id,
  clientProjectsLoading: userReducer.clientProjectsLoading,
  clientProjects: userReducer.clientProjects,
  clients: userReducer.clients,
  phasePlanSelect: phasePlan.phasePlanSelect,
  activeTabProject: phasePlan.activeTabProject,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // getProjectsList,
      getClientsProjects,
      getPhasePlanSelectData,
      updateProject,
      setActiveProject,
      changeActiveProject,
      getProjectDetails,
      setActiveTabProject,
      clearNeedListData,
      resetSandboxLoadings,
      clearNeedListFilters,
      clearProjectData,
      setActiveModule,
      getProjectById,
      hardResetFiltersReducer,
      clearRequestsFilters,
      clearSubmittalsData,
      clearSpecificationData,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectComponent));
