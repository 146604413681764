import { actionCreater } from '../configure/actionCreater';

//Get comments
export const GET_HISTORY = 'GET_HISTORY';
export const getHistory = actionCreater(GET_HISTORY);

export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const getHistorySuccess = actionCreater(GET_HISTORY_SUCCESS);

export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';
export const getHistoryFail = actionCreater(GET_HISTORY_FAIL);

export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const clearHistory = actionCreater(CLEAR_HISTORY);
