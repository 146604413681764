import React, { FC, useState, useEffect, useRef } from 'react';
import ReportingView from './ReportingView';
import { getReportingHeader, getReportingTable } from '../../../store/reporting/reportingThunk';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import moment from 'moment';
import MixpanelHelper from '../../../service/Mixpanel/Mixpanel';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';
import { isMobile } from 'react-device-detect';

const ReportingContainer: FC = () => {
  const [selectData, setSelectData] = useState<string>('');
  const [timePeriodName, setTimePeriodName] = useState<string>('All history');
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [selectGroup, setSelectGroup] = useState<string>('Default grouping');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>(null);
  const [isShowNFs, setIsShowNFs] = useState<boolean>(false);
  const [selectProject, setSelectProject] = useState(null);

  const dispatch = useAppDispatch();
  const { setUrlReporting, setUrlReportingCompany } = useRoutingHelper();
  const { params, searchParams, urlProject } = useRouter();

  const loading = useAppSelector(state => state.reportingReducer.loading);
  const loadingTabel = useAppSelector(state => state.reportingReducer.loadingTabel);
  const userData = useAppSelector(state => state.userReducer);
  const reportingData = useAppSelector(state => state.reportingReducer.reportingData);
  const reportingDataTabel = useAppSelector(state => state.reportingReducer.reportingDataTabel);
  const project = useAppSelector(state => state.phasePlan.projectData);
  const getProjectStatus = useAppSelector(state => state.phasePlan.getProjectStatus);

  const headerPromise = useRef();
  const tablePromise = useRef();

  useEffect(() => {
    MixpanelHelper.track('Reporting module visited');

    if (urlProject[1] === 'reporting') {
      urlProject[2] && setSelectedCompanyId(Number(urlProject[2]));
      urlProject[3] && setSelectedUserId(Number(urlProject[3]));
    }
  }, []);

  useEffect(() => {
    if (selectedUserId && !selectedUser && reportingDataTabel?.table_data?.users) {
      const selectedUser = reportingDataTabel.table_data.users.find(user => user.id === Number(urlProject[3]));
      if (selectedUser) {
        selectUser(selectedUser.id, selectedUser.title);
      }
    }
  }, [selectedUserId, selectedUser, reportingDataTabel]);

  useEffect(() => {
    if (project && !getProjectStatus.loading) {
      if (selectProject) {
        if (selectProject.title !== project.title) setSelectProject(project);
      } else {
        setSelectProject(project);
      }
    }
  }, [project]);

  useEffect(() => {
    if (selectProject?.target_start) {
      if (userData?.active_project_id) {
        setSelectData('');
        setTimePeriodName('All history');
        setSelectedCompanyId(0);
        setSelectedCompanyName('');
        setSelectGroup('Default grouping');
        const somedate = new Date();
        const start_date = selectProject.target_start;
        const end_date = moment(somedate).format('YYYY-MM-DD');
        headerPromise.current?.abort();
        headerPromise.current = dispatch(
          getReportingHeader({
            active_project_id: userData?.active_project_id,
            start_date: start_date,
            end_date: end_date,
            group_by: 'no_group',
          }),
        );
        tablePromise.current?.abort();
        tablePromise.current = dispatch(
          getReportingTable({
            active_project_id: userData?.active_project_id,
          }),
        );
      }
    }
  }, [selectProject?.title]);

  useEffect(() => {
    if (params.companyId && reportingDataTabel?.table_data?.companies) {
      const selectedCompany = reportingDataTabel.table_data.companies.find(company => +params.companyId === company.id);
      if (selectedCompany) {
        selectCompany(selectedCompany.id, selectedCompany.title);
      }
    }

    if (params.userId && reportingDataTabel?.table_data?.users && !selectedUserId) {
      const selectedUser = reportingDataTabel.table_data.users.find(company => +params.userId === company.id);
      if (selectedUser) {
        selectUser(selectedUser.id, selectedUser.title);
      }
    }
  }, [reportingDataTabel, params]);

  const handleTimePeriod = (timePeriodName: string, selectPeriod: string) => {
    setTimePeriodName(timePeriodName);
    setSelectData(selectPeriod);
    const period = getPeriod(timePeriodName, selectPeriod);
    if (selectedUserId) {
      headerPromise.current?.abort();
      headerPromise.current = dispatch(
        getReportingHeader({
          active_project_id: userData.active_project_id,
          start_date: period[0],
          end_date: period[1],
          group_by: getGroupCode(selectGroup),
          user_id: selectedUserId,
        }),
      );
      tablePromise.current?.abort();
      tablePromise.current = dispatch(
        getReportingTable({
          active_project_id: userData?.active_project_id,
          start_date: period[0],
          end_date: period[1],
          user_id: selectedUserId,
        }),
      );
    } else {
      headerPromise.current?.abort();
      headerPromise.current = dispatch(
        getReportingHeader({
          active_project_id: userData.active_project_id,
          start_date: period[0],
          end_date: period[1],
          group_by: getGroupCode(selectGroup),
          party_id: selectedCompanyId,
        }),
      );
      tablePromise.current?.abort();
      tablePromise.current = dispatch(
        getReportingTable({
          active_project_id: userData?.active_project_id,
          start_date: period[0],
          end_date: period[1],
          party_id: selectedCompanyId,
        }),
      );
    }
  };

  const getPeriod = (timePeriod, selectPeriod) => {
    const somedate = new Date();
    if (timePeriod === 'Custom') {
      const start_date = selectPeriod;
      const end_date = moment(somedate).format('YYYY-MM-DD');
      return [start_date, end_date];
    }
    if (timePeriod === 'Last month') {
      const start_date = moment(somedate).subtract(30, 'days').format('YYYY-MM-DD');
      const end_date = moment(somedate).format('YYYY-MM-DD');
      return [start_date, end_date];
    }
    if (timePeriod === 'Last quarter') {
      const start_date = moment(somedate).subtract(92, 'days').format('YYYY-MM-DD');
      const end_date = moment(somedate).format('YYYY-MM-DD');
      return [start_date, end_date];
    }
    if (timePeriod === 'Last year') {
      const start_date = moment(somedate).subtract(1, 'years').format('YYYY-MM-DD');
      const end_date = moment(somedate).format('YYYY-MM-DD');
      return [start_date, end_date];
    }
    if (timePeriod === 'All history') {
      const start_date = project.target_start;
      const end_date = moment(somedate).format('YYYY-MM-DD');
      return [start_date, end_date];
    }
  };
  const getGroupCode = select => {
    if (select === 'Default grouping') {
      return 'no_group';
    } else if (select === 'By discipline') {
      return 'discipline';
    } else if (select === 'By status') {
      return 'status';
    } else if (select === 'By type') {
      return 'type';
    } else {
      return 'level';
    }
  };

  const selectCompany = (id: number, company_name: string) => {
    const period = getPeriod(timePeriodName, selectData);
    headerPromise.current?.abort();
    headerPromise.current = dispatch(
      getReportingHeader({
        active_project_id: userData.active_project_id,
        start_date: period[0],
        end_date: period[1],
        group_by: getGroupCode(selectGroup),
        party_id: id,
      }),
    );
    tablePromise.current?.abort();
    tablePromise.current = dispatch(
      getReportingTable({
        active_project_id: userData.active_project_id,
        party_id: id,
        start_date: period[0],
        end_date: period[1],
      }),
    );
    setSelectedCompanyId(id);
    setSelectedCompanyName(company_name);
  };

  const selectUser = (user_id: number, user_name: string) => {
    const period = getPeriod(timePeriodName, selectData);
    headerPromise.current?.abort();
    headerPromise.current = dispatch(
      getReportingHeader({
        active_project_id: userData.active_project_id,
        start_date: period[0],
        end_date: period[1],
        group_by: getGroupCode(selectGroup),
        user_id: user_id,
      }),
    );
    setSelectedUser(user_name);
    setSelectedUserId(user_id);
  };

  const goBackFromCompany = () => {
    const period = getPeriod(timePeriodName, selectData);
    headerPromise.current?.abort();
    headerPromise.current = dispatch(
      getReportingHeader({
        active_project_id: userData.active_project_id,
        start_date: period[0],
        end_date: period[1],
        group_by: getGroupCode(selectGroup),
      }),
    );
    tablePromise.current?.abort();
    tablePromise.current = dispatch(
      getReportingTable({
        active_project_id: userData.active_project_id,
        start_date: period[0],
        end_date: period[1],
      }),
    );
    setSelectedCompanyId(0);
    setSelectedCompanyName('');
  };

  const handleGroup = (select: string) => {
    setSelectGroup(select);
    const period = getPeriod(timePeriodName, selectData);
    if (selectedUserId) {
      headerPromise.current?.abort();
      headerPromise.current = dispatch(
        getReportingHeader({
          active_project_id: userData.active_project_id,
          start_date: period[0],
          end_date: period[1],
          group_by: getGroupCode(select),
          user_id: selectedUserId,
        }),
      );
    } else {
      headerPromise.current?.abort();
      headerPromise.current = dispatch(
        getReportingHeader({
          active_project_id: userData.active_project_id,
          start_date: period[0],
          end_date: period[1],
          group_by: getGroupCode(select),
          party_id: selectedCompanyId,
        }),
      );
    }
  };

  const needsListFilters = data => {
    const period = getPeriod(timePeriodName, selectData);
    const localStorageData = {
      formData: data.formData,
    };
    if (timePeriodName !== 'All history') {
      localStorageData.conditions = { date_status_sent: ['AFTER'] };
      localStorageData.containsFilter = { date_status_sent: { AFTER: { date: period[0] } } };
    }
    if (data?.formDataOrFilters) {
      localStorageData.formDataOrFilters = data.formDataOrFilters;
    }
    const localStorageDataOld = JSON.parse(localStorage.getItem(`need_form`));
    const filters = JSON.stringify({ ...localStorageDataOld, ...localStorageData });
    localStorage.setItem(`need_form`, filters);

    if (isMobile) {
      globals.history.push({
        pathname: `/${urlProject[0]}/needs-list`,
        search: '',
      });
    } else {
      window.open(`/${urlProject[0]}/needs-list?need_form_filter="true"`, '_blank', 'noopener,noreferrer');
    }
  };

  const goBackHandleFromCompany = () => {
    setUrlReporting();
    setIsShowNFs(false);
    setSelectedUser('');
    setSelectedUserId(null);
    goBackFromCompany();
  };

  const goBackFromUser = () => {
    setUrlReportingCompany(selectedCompanyId);
    setSelectedUser('');
    setSelectedUserId(null);
    selectCompany(selectedCompanyId, selectedCompanyName);
  };

  useEffect(() => {
    if (!selectedCompanyId) {
      const activeTabUrl = searchParams.get(`activeTab`);
      setIsShowNFs(!!activeTabUrl);
    }
  }, [selectedCompanyId]);

  const handleShowNFs = () => {
    setIsShowNFs(!isShowNFs);
  };
  return (
    <ReportingView
      loading={loading}
      loadingTabel={loadingTabel}
      reportingData={reportingData}
      reportingDataTabel={reportingDataTabel}
      timePeriodName={timePeriodName}
      handleTimePeriod={handleTimePeriod}
      selectData={selectData}
      selectGroup={selectGroup}
      selectedCompanyId={selectedCompanyId}
      selectedCompanyName={selectedCompanyName}
      selectedUser={selectedUser}
      goBackFromUser={goBackFromUser}
      goBackHandleFromCompany={goBackHandleFromCompany}
      handleGroup={handleGroup}
      needsListFilters={needsListFilters}
      handleShowNFs={handleShowNFs}
      isShowNFs={isShowNFs}
      selectedUserId={selectedUserId}
      project_title={project.title}
    />
  );
};

export default React.memo(ReportingContainer);
