import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';

const LoginPageView = props => {
  const { login, textFiealdChangeAction, passwFieldValue, emailFieldValue } = props;
  const onSubmit = event => {
    event.preventDefault();
    login();
  };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, reset, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="email"
              component={EmailFieldAdapter}
              validate={required}
              onChange={textFiealdChangeAction('email')}
              value={emailFieldValue}
            />
          </div>
          <div>
            <Field
              name="password"
              component={PasswordFieldAdapter}
              validate={required}
              onChange={textFiealdChangeAction('password')}
              value={passwFieldValue}
            />
          </div>
          <Button variant="outlined">Sig In</Button>
        </form>
      )}
    />
  );
};

const required = value => (value ? undefined : 'Required');

const EmailFieldAdapter = ({ input, meta, ...rest }) =>
  console.log('input= ', input, 'meta= ', meta, 'rest= ', rest) || (
    <TextField {...input} {...rest} required onChange={(event, value) => rest.onChange(value)} error={!!meta.error} />
  );

const PasswordFieldAdapter = ({ input, meta, ...rest }) =>
  console.log('input= ', input, 'meta= ', meta, 'rest= ', rest) || (
    <TextField
      {...input}
      {...rest}
      required
      type="password"
      autoComplete="current-password"
      onChange={(event, value) => rest.onChange(event.target.value)}
      errorText={meta.touched ? meta.error : ''}
      error={!!meta.error}
    />
  );

export default LoginPageView;
