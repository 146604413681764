import React from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import ChipsIntegration from '../../controls/Chips/ChipsIntegration/ChipsIntegration';
import cn from 'classnames';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';

import './ApplicationCardStyles.scss';

interface IProps {
  title: string;
  logo: 'procore-logo' | 'autodesk-bim-logo' | 'autodesk-revit-logo' | 'redmine-logo';
  isConnected: boolean;
  disabled?: boolean;
  handleOnClick?: () => void;
  isModalOpen?: boolean;
  modalTitle?: string;
  renderModalContent?: () => React.ReactElement;
  handleModalClose?: () => void;
  modalPrimaryText?: string;
  modalSecondaryText?: string;
  modalTertiaryText?: string;
  handleOnModalPrimary?: (e: Event) => void;
  handleOnModalSecondary?: (e: Event) => void;
  handleOnModalTertiary?: (e: Event) => void;
  loadingOnModalPrimary?: boolean;
  loadingOnModalSecondary?: boolean;
  loadingOnModalTertiary?: boolean;
  isModalLoading?: boolean;
  paperModalMaxWidth?: string;
  modalDividedHeader?: boolean;
  customModalClassName?: string;
  customModalContainerClassName?: string;
}

const ApplicationCard: React.FC<IProps> = ({
  title,
  logo,
  isConnected,
  disabled,
  handleOnClick,
  isModalOpen,
  modalTitle,
  renderModalContent,
  handleModalClose,
  modalPrimaryText,
  modalSecondaryText,
  modalTertiaryText,
  handleOnModalPrimary,
  handleOnModalSecondary,
  handleOnModalTertiary,
  loadingOnModalPrimary,
  loadingOnModalSecondary,
  loadingOnModalTertiary,
  isModalLoading,
  paperModalMaxWidth,
  modalDividedHeader,
  customModalClassName,
  customModalContainerClassName,
}) => {
  const themeClass = useGetThemeClass('b-application-card');

  return (
    <>
      <div className={cn(`${themeClass}__main-container`, { ['-disabled']: disabled })} onClick={handleOnClick}>
        <div className={`${themeClass}__hover-container`}></div>
        <div className={`${themeClass}__logo-container`}>
          <img className={`${themeClass}__itemLogo`} src={require(`../../../assets/images/integrations/${logo}.png`)} alt={`${title}`} />
          {isConnected && (
            <div className={`${themeClass}__icon-container`}>
              <ChipsIntegration />
            </div>
          )}
        </div>
        <span className={`${themeClass}__card-title`}>{title}</span>
      </div>
      <DialogPopUp
        open={isModalOpen}
        title={modalTitle ? modalTitle : title}
        renderModalContent={renderModalContent}
        onClose={handleModalClose}
        primaryText={modalPrimaryText}
        secondaryText={modalSecondaryText}
        tertiaryText={modalTertiaryText}
        handleOnPrimary={handleOnModalPrimary}
        handleOnSecondary={handleOnModalSecondary}
        handleOnTertiary={handleOnModalTertiary}
        loadingOnPrimary={loadingOnModalPrimary}
        loadingOnSecondary={loadingOnModalSecondary}
        loadingOnTertiary={loadingOnModalTertiary}
        isLoading={isModalLoading}
        paperMaxWidth={paperModalMaxWidth}
        dividedHeader={modalDividedHeader}
        customClassname={customModalClassName}
        customContainerClassName={customModalContainerClassName}
      />
    </>
  );
};

export default ApplicationCard;
