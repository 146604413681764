import React, { Component } from 'react';

class Update_20220420_4 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            style={{ width: '833px', height: '120px' }}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_2bc82e15e7d13e73b4f2293d8d02785f/images/image3_9Ht.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20220420_4;
