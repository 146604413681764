import React, { FC, useState } from 'react';
import Select from '@material-ui/core/Select';
import ShowMoreCodes from '../../../../controls/ShowMore/ShowMoreCodes/ShowMoreCodes';
import { FilterNestedType } from '../../../../../models';
import MultiNestedFilter from '../../../UfCodeSelector/MultiNestedFilter';

import './Styles.scss';

export interface SelectFieldValue {
  id: number;
  code: string;
  description?: string;
  title?: string;
  label: string;
  value: number;
  viewData: {
    code: string;
    id: number;
    title: string;
  };
}

interface IProps {
  label: string;
  type: FilterNestedType;
  values: SelectFieldValue[];
  onClose: (values: SelectFieldValue[], isCommit?: boolean) => void;
}

interface IState {
  isChanged: boolean;
  values: SelectFieldValue[];
}

const CodeSelectField: FC<IProps> = props => {
  const { onClose, type } = props;

  const initialState = () => {
    const newValues = [];
    if (props.values.length) {
      props.values.map(item =>
        newValues.push({
          label: item.label || `${item.code} - ${item.description || item.title}`,
          value: item.id || item.value,
          type: item.type || item?.pivot?.type || 'default',
          viewData: {
            code: item.code || item.viewData.code,
            id: item.id || item.viewData.id,
            title: item.description || item.viewData?.title || item.title,
          },
        }),
      );
    }

    return {
      isChanged: false,
      values: props.values.length ? newValues : [],
    };
  };

  const [state, setState] = useState<IState>(initialState());

  const handleClose = () => {
    onClose(state.values, state.isChanged);
  };

  const handleSelect = (val: SelectFieldValue, selectUfMf: SelectFieldValue, changeSelectedItem) => {
    if (changeSelectedItem) {
      const arr = [...state.values];
      const findIndex = arr.findIndex(item => item.value == selectUfMf.value);

      arr.splice(findIndex, 1, val);
      setState({
        ...state,
        values: arr,
        isChanged: true,
      });
      return;
    }

    const arr = [...state.values];
    const findIndex = arr.findIndex(item => item.value == val.value);
    if (findIndex > -1) {
      arr[findIndex] = val;
      setState({
        ...state,
        values: arr,
        isChanged: true,
      });
    } else {
      setState({
        ...state,
        values: [...state.values, val],
        isChanged: true,
      });
    }
  };

  const clearAll = () => {
    setState({
      ...state,
      values: [],
      isChanged: true,
    });
  };

  const handleDelete = (val: SelectFieldValue) => {
    const newValues = [...state.values].filter(item => val.value !== item.value);
    setState({
      ...state,
      values: newValues,
      isChanged: true,
    });
  };

  return (
    <Select renderValue={() => <ShowMoreCodes items={state.values} />}>
      <MultiNestedFilter
        label={type}
        type={type}
        // selectedBuildings={[]}
        disabled={false}
        isAccess={true}
        handleClose={handleClose}
        isBulkEditMode={true}
        bulkEditState={state}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        clearAll={clearAll}
        isEditUF={type == 'UF' ? true : false}
      />
    </Select>
  );
};

CodeSelectField.defaultProps = {};

export default CodeSelectField;
