import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';
import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { TabItemModel } from '../../../../../models/global';
import { ActivationTabType, RequestModel } from '../../../../../models';
import { requestTabs } from '../../constants/requestTabs';
import { NeedListRequest } from '../../../../../store/needlist/needListModels';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { IFeedData } from '../../../../../store/comments/commentReducer.model';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import Divider from '../../../../controls/Divider/Divider';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import StatusIncorporation from '../../../../controls/Status/StatusIncorporation/StatusIncorporation';
import { IncorporationTaskModel } from '../../../../../store/incorporation/incorporationReducer.model';
import { globalIncorporationStatusText } from '../../../../../helpers/IncorporationHelper';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { RelatedTabDataModel } from '../../../../../store/relatedItems/relatedItemsSlice.model';

import './TabsContainerStyles.scss';

const { setCommitmentAction } = requestSlice.actions;

interface IProps {
  isDragRequestCard: boolean;
  incorporationList: IncorporationTaskModel[] | [];
  feedData: IFeedData;
  relatedTabData: RelatedTabDataModel;
  openedRequest: RequestModel & NeedListRequest;
  requestLoading: boolean;
  relCommitment: (id: number) => void;
  activeTab: ActivationTabType;
  onChangeActiveTab: (activeTab: ActivationTabType) => void;
  isDisable: boolean;
  isPullRequest: boolean;
  isAccess: boolean;
  handleGlobalIncorporation: () => void;
}

const TabsContainer: FC<IProps> = ({
  isDragRequestCard,
  incorporationList,
  feedData,
  relatedTabData,
  openedRequest,
  requestLoading,
  relCommitment,
  activeTab,
  onChangeActiveTab,
  isDisable,
  isPullRequest,
  isAccess,
  handleGlobalIncorporation,
}) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-cardRequest-tabsContainer');
  const iconThemeClass = useGetThemeClass('buttonIconClass');
  const { is1440Resolution, isMobile } = GetResolution();

  const isGlobalIncorporated = useAppSelector(state => state.incorporationReducer.isGlobalIncorporated);
  const globalIncorporationLoading = useAppSelector(state => state.incorporationReducer.globalIncorporationLoading);
  const [tabs, setTabs] = useState<TabItemModel[]>([...requestTabs] || []);

  const { status, text } = globalIncorporationStatusText(incorporationList);

  useEffect(() => {
    if (openedRequest?.id || isDisable) {
      renderTabs();
    }
  }, [incorporationList, openedRequest, feedData, relatedTabData]);

  const renderTabs = () => {
    const newTabs = tabs.map(tab => {
      if (tab.id === 'request') {
        return { ...tab, disabled: checkDisable(tab.id as ActivationTabType) };
      }
      if (tab.id === 'response') {
        return { ...tab, disabled: checkDisable(tab.id as ActivationTabType) };
      }
      if (tab.id === 'incorporation') {
        return { ...tab, count: incorporationList?.length || 0, disabled: checkDisable(tab.id as ActivationTabType) };
      }
      if (tab.id === 'related-items') {
        let counter = 0;
        for (const key in relatedTabData) {
          if (key !== 'document' && key !== 'card') {
            counter += relatedTabData[key].length;
          }
        }
        return {
          ...tab,
          count: counter,
          disabled: checkDisable(tab.id as ActivationTabType),
        };
      }
      if (tab.id === 'discussion') {
        return { ...tab, count: feedData?.length || 0, disabled: checkDisable(tab.id as ActivationTabType) };
      }
      return tab;
    });
    setTabs(newTabs);
  };

  const handleChangeActiveTab = (event: React.SyntheticEvent, tab: string) => {
    const isDisable = checkDisable(tab as ActivationTabType);

    if (isDisable) {
      return;
    }
    !isDisable && onChangeActiveTab(tab as ActivationTabType);
    dispatch(setCommitmentAction(''));
  };

  const checkDisable = (type: ActivationTabType) => {
    return isDisable && type !== 'request';
  };

  const isSidePeekView = isDragRequestCard || is1440Resolution;

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-sidePeekView']: isSidePeekView,
        ['-showPullRequestButton']: activeTab === 'request' && openedRequest?.author?.profile && !isPullRequest && !isMobile,
      })}
    >
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_tabsGroup`}>
          <TabsBar
            sections={tabs}
            selectedTab={activeTab}
            handleChange={handleChangeActiveTab}
            activeSectionType={'request'}
            isCountersLoading={false}
            isNfCard
            customClass={``}
            isMobile={isMobile}
          />
        </div>
        {activeTab === 'request' && openedRequest?.author?.profile && !isPullRequest && !isMobile ? (
          <div className={`${themeClass}_buttonGroup`}>
            <CustomTooltip
              enterDelay={500}
              leaveDelay={0}
              title={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Create new NF as predecessor to this NF'}
              placement={'top'}
            >
              <CustomButton
                icon={<TokenIcon iconName={'pull'} size={12} />}
                type={'text-plain'}
                size={'sm'}
                clickHandler={() => relCommitment(openedRequest.id)}
                title={'Pull request'}
                iconClass={`${iconThemeClass}_text-plain`}
                disabled={!openedRequest?.status || requestLoading || !!openedRequest?.is_deactivated || !!openedRequest?.parent_id}
              />
            </CustomTooltip>
          </div>
        ) : null}
        {activeTab === 'incorporation' && openedRequest && incorporationList.length && !isSidePeekView ? (
          <div className={`${themeClass}_buttonGroup`}>
            {!isGlobalIncorporated && (
              <StatusIncorporation status={status} label={text} isVisibleLabel={true} isDragRequestCard={false} size={'md'} />
            )}
            {isGlobalIncorporated ? (
              <CustomButton
                type={'text-plain'}
                size={'md'}
                clickHandler={handleGlobalIncorporation}
                title={'Clear incorporation'}
                loading={globalIncorporationLoading.loading}
                disabled={openedRequest?.status === 'declined'}
              />
            ) : (
              <CustomButton
                type={status === 'completed' ? 'primary' : 'text-plain'}
                size={'md'}
                clickHandler={handleGlobalIncorporation}
                title={'Complete incorporation'}
                loading={globalIncorporationLoading.loading}
                disabled={openedRequest?.status === 'declined'}
              />
            )}
          </div>
        ) : null}
      </div>
      <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-4'} />
    </div>
  );
};

export default TabsContainer;
