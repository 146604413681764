import useRouter from '../useRouter/useRouter';
import { DeliverableCardTabNamesModel } from '../../components/common/CardPcd/constans/constants';
import { SubmittalCardTabNamesModel } from '../../components/common/CardSubmittal/constants/submittalTabs';

export default function useRoutingHelper() {
  const { location, urlProject, navigate, searchParams } = useRouter();

  const setUrlNF = (nf: string, tab?: string) => {
    if (nf) {
      const resultUrl = Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            key !== 'nf' &&
            key !== 'tab' &&
            (key.includes('_hash') || key.includes('default_filter') || key.includes('activeTab') || key.includes('custom_filters')),
        )
        .map(([key, value]) => `${key}=${value}`);
      resultUrl.splice(0, 0, `nf=${nf}`, `tab=${tab || 'request'}`);

      navigate({
        pathname: location.pathname,
        search: resultUrl.join('&'),
      });
    }
  };

  const setUrlPreviewNF = (nf: string, id: string, tab?: string) => {
    if (nf) {
      const resultUrl = Array.from(searchParams.entries())
        .filter(([key]) => key !== 'previewCard')
        .map(([key, value]) => `${key}=${value}`);

      const previewCard = JSON.stringify({ nf, id, tab: `${tab || 'request'}` });
      resultUrl.push(`previewCard=${previewCard}`);

      navigate({
        pathname: location.pathname,
        search: resultUrl.join('&'),
      });
    }
  };

  const removeUrlPreviewNF = () => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => key !== 'previewCard')
      .map(([key, value]) => `${key}=${value}`);

    navigate({
      pathname: location.pathname,
      search: resultUrl.join('&'),
    });
  };

  const setAddCompanyPopUp = (value?: number) => {
    navigate({
      pathname: location.pathname,
      search: value ? `?addCompany=${value}` : '',
    });
  };

  const setUrlReporting = () => {
    navigate({
      pathname: `/${urlProject[0]}/reporting`,
    });
  };

  const setUrlChangeProject = () => {
    navigate({
      pathname: `/change-project`,
    });
  };

  const setUrlNoProjects = () => {
    navigate({
      pathname: `/no-projects-yet/projects`,
    });
  };

  const setUrlChangeCompany = () => {
    navigate({
      pathname: `/change-company`,
    });
  };

  const setUrlDeliverables = () => {
    navigate({
      pathname: `/${urlProject[0]}/output-data/deliverables`,
    });
  };

  const redirectToCommandCenter = () => {
    const sandboxUrlActiveTab = searchParams.get(`activeTab`);

    navigate({
      pathname: `/${urlProject[0]}/command-center`,
      search: sandboxUrlActiveTab ? `activeTab=${sandboxUrlActiveTab}` : '',
    });
  };

  const redirectToProject = () => {
    navigate({
      pathname: `/${urlProject[0]}/projects`,
    });
  };

  const redirectToTeam = () => {
    navigate({
      pathname: `/${urlProject[0]}/team`,
    });
  };

  const redirectToTeamEdit = (companyId: number) => {
    navigate({
      pathname: `/${urlProject[0]}/team/company/${companyId}`,
    });
  };

  const setUrlCommandCenter = () => {
    navigate({
      pathname: `${urlProject[0]}/change-company`,
    });
  };

  const returnUrlDeliverablesCardTab = (deliverable): string => {
    return `/${urlProject[0]}/card?building=${deliverable.building_id || deliverable.building?.id}&deliverable=${deliverable.id}`;
  };

  const returnUrlNfCardTab = (card): string => {
    return `/${urlProject[0]}/card?nf=${card.nf}`;
  };

  const returnUrlSubmittalCardTab = (submittal): string => {
    return `/${urlProject[0]}/card?submittal=${submittal.id}`;
  };

  const returnUrlTeamEdit = (companyId: number) => {
    return `/${urlProject[0]}/team/company/${companyId}`;
  };

  const setUrlReportingCompany = (companyId: number) => {
    if (companyId) {
      navigate({
        pathname: `/${urlProject[0]}/reporting/${companyId}`,
      });
    }
  };

  const setUrlReportingUser = (userID: number) => {
    if (userID) {
      navigate({
        pathname: `${location.pathname}/${userID}`,
      });
    }
  };

  const setUrlPCD = (pcd: string | number, building: string | number, tab?: string) => {
    if (pcd) {
      const resultUrl = Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            key !== 'building' &&
            key !== 'deliverable' &&
            key !== 'tab' &&
            (key.includes('_hash') || key.includes('default_filter') || key.includes('activeTab') || key.includes('custom_filters')),
        )
        .map(([key, value]) => `${key}=${value}`);
      resultUrl.splice(0, 0, `building=${building}`, `deliverable=${pcd}`, `tab=${tab || 'deliverable'}`);

      navigate({
        pathname: location.pathname,
        search: resultUrl.join('&'),
      });
    }
  };

  const setUrlSubmittal = (submittalId: string | number, tab?: string) => {
    if (submittalId) {
      const resultUrl = Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            key !== 'submittal' &&
            key !== 'tab' &&
            (key.includes('_hash') || key.includes('default_filter') || key.includes('activeTab') || key.includes('custom_filters')),
        )
        .map(([key, value]) => `${key}=${value}`);
      resultUrl.splice(0, 0, `submittal=${submittalId}`, `tab=${tab || 'general'}`);

      navigate({
        pathname: location.pathname,
        search: resultUrl.join('&'),
      });
    }
  };

  const removeParams = () => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(
        ([key]) => key.includes(`_hash`) || key.includes('default_filter') || key.includes('activeTab') || key.includes('custom_filters'),
      )
      .map(([key, value]) => `${key}=${value}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const getNfParamsFromURL = (): { nf: number; project_id: number | string; file_id: number; nfTab: string } => {
    const nfParam = searchParams.get('nf');
    const nfTab = searchParams.get('tab');
    const file_id = searchParams.get('fileid');

    return {
      nf: nfParam ? Number(nfParam) : null,
      project_id: urlProject[0] || '',
      file_id: file_id ? Number(file_id) : null,
      nfTab: nfTab || null,
    };
  };

  const getPreviewNfParamsFromURL = (): { id: number; nf: number; project_id: number | string; nfTab: string } => {
    const nfPreviewData = JSON.parse(searchParams.get('previewCard'));

    return {
      id: Number(nfPreviewData.id),
      nf: Number(nfPreviewData.nf),
      project_id: urlProject[0] || '',
      nfTab: nfPreviewData?.tab || null,
    };
  };

  const getPCDParamsFromURL = (): {
    pcdId: number;
    buildingId: number;
    project_id: number | string;
    isPCDTab: boolean;
    tabParam: DeliverableCardTabNamesModel;
  } => {
    const isPCDTab = urlProject[1] === 'deliverables';
    const pcdParam = searchParams.get('deliverable');
    const buildingParam = searchParams.get('building');
    const tabParam = searchParams.get('tab') as DeliverableCardTabNamesModel;

    return {
      pcdId: pcdParam ? Number(pcdParam) : null,
      buildingId: buildingParam ? Number(buildingParam) : null,
      project_id: urlProject[0] || '',
      isPCDTab,
      tabParam: tabParam || null,
    };
  };

  const getSubmittalParamsFromURL = (): {
    submittalId: number;
    project_id: number | string;
    tabParam: SubmittalCardTabNamesModel;
  } => {
    const submittalParam = searchParams.get('submittal');
    const tabParam = searchParams.get('tab') as SubmittalCardTabNamesModel;

    return {
      submittalId: submittalParam ? Number(submittalParam) : null,
      project_id: urlProject[0] || '',
      tabParam: tabParam || null,
    };
  };

  const getCardParamsFromURL = (): {
    nfParam: number;
    pcdIdParam: number;
    buildingIdParam: number;
    submittalIdParam: number;
    tabParam: SubmittalCardTabNamesModel | DeliverableCardTabNamesModel | string;
    nfPreviewData: boolean;
  } => {
    const submittalParam = searchParams.get('submittal');
    const pcdParam = searchParams.get('deliverable');
    const buildingParam = searchParams.get('building');
    const tabParam = searchParams.get('tab');
    const nfParam = searchParams.get('nf');
    const nfPreviewData = searchParams.get('previewCard');

    return {
      nfParam: nfParam ? Number(nfParam) : null,
      pcdIdParam: pcdParam ? Number(pcdParam) : null,
      buildingIdParam: buildingParam ? Number(buildingParam) : null,
      submittalIdParam: submittalParam ? Number(submittalParam) : null,
      tabParam: tabParam || null,
      nfPreviewData: !!nfPreviewData,
    };
  };

  const getIsChangeProject = (): boolean => {
    return urlProject[0] === 'change-project';
  };

  const getActiveTabInputDataFromURL = (): string => {
    return urlProject[2];
  };

  const getIsNeedListFromURL = (): boolean => {
    return urlProject[1] === 'needs-list';
  };

  const getIsSubmittalsFromURL = (): boolean => {
    return urlProject[2] === 'submittals';
  };

  const setUrlCommandCenterTab = (tabName: string, isReplace?: boolean) => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('activeTab'))
      .map(([key, value]) => `${key}=${value}`);
    resultUrl.splice(0, 0, `activeTab="${tabName}"`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: !!isReplace },
    );
  };

  const setUrlDefaultFilter = (url: string) => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('default_filter') && !key.includes('_hash'))
      .map(([key, value]) => `${key}=${value}`);
    resultUrl.push(`default_filter="${url}"`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const removeUrlDefaultFilter = () => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('default_filter'))
      .map(([key, value]) => `${key}=${value}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const setUrlHeatmapFilter = (url: string) => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('default_filter') && !key.includes('_hash') && !key.includes('custom_filters'))
      .map(([key, value]) => `${key}=${value}`);
    resultUrl.push(`custom_filters=${url}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const removeUrlHeatmapFilter = () => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('custom_filters'))
      .map(([key, value]) => `${key}=${value}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const setUrlHash = (hash: string) => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('_hash'))
      .map(([key, value]) => `${key}=${value}`);
    resultUrl.push(`${hash}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  const setUrlActiveRelatedItem = (nf: string, tab: string, activeRelatedItems: string) => {
    if (nf) {
      const resultUrl = Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            key !== 'nf' &&
            key !== 'tab' &&
            (key.includes('_hash') ||
              key.includes('default_filter') ||
              key.includes('activeTab') ||
              key.includes('custom_filters' || 'active-related-item')),
        )
        .map(([key, value]) => `${key}=${value}`);
      resultUrl.splice(0, 0, `nf=${nf}`, `tab=${tab}`, `active-related-item=${activeRelatedItems}`);

      return navigate({
        pathname: location.pathname,
        search: resultUrl.join('&'),
      });
    }
  };

  const removeUrlHash = (isNf?: boolean) => {
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => (isNf ? !key.includes('_hash') && !key.includes('need_form_filter') : !key.includes('_hash')))
      .map(([key, value]) => `${key}=${value}`);

    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
  };

  return {
    setUrlChangeCompany,
    setUrlChangeProject,
    setUrlNoProjects,
    setUrlCommandCenter,
    removeUrlHash,
    setUrlActiveRelatedItem,
    setUrlHash,
    removeUrlHeatmapFilter,
    removeUrlDefaultFilter,
    setUrlHeatmapFilter,
    setUrlDefaultFilter,
    getIsChangeProject,
    getActiveTabInputDataFromURL,
    getIsNeedListFromURL,
    getIsSubmittalsFromURL,
    setUrlCommandCenterTab,
    setUrlNF,
    setUrlPreviewNF,
    removeUrlPreviewNF,
    setAddCompanyPopUp,
    setUrlReporting,
    setUrlDeliverables,
    redirectToCommandCenter,
    redirectToProject,
    redirectToTeam,
    redirectToTeamEdit,
    returnUrlDeliverablesCardTab,
    returnUrlNfCardTab,
    returnUrlSubmittalCardTab,
    returnUrlTeamEdit,
    setUrlReportingCompany,
    setUrlReportingUser,
    setUrlPCD,
    setUrlSubmittal,
    removeParams,
    getNfParamsFromURL,
    getPreviewNfParamsFromURL,
    getPCDParamsFromURL,
    getSubmittalParamsFromURL,
    getCardParamsFromURL,
  };
}
