import React, { Component } from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { LocationModel, TypicalLayoutModel, UserPermissionsModel } from '../../../../../models';
import { KeyValueModel } from '../../../../../models/key-value.model';
import userPermissions from './UserRolesContents';

enum WidthSize {
  NameCell = '20%',
  DescriptionCell = '80%',
}

enum PermissionWidth {
  UserRoles = '52%',
  CompanyUser = '12%',
  CompanyManager = '12%',
  WorkflowManager = '12%',
  ProjectAdmin = '12%',
}

interface IProps {}

interface IState {
  userPermissionsState: UserPermissionsModel[];
}

class UserRolesHelp extends Component<IProps, IState> {
  state = {
    userPermissionsState: userPermissions,
  };

  hideMainHeader = (id: number) => {
    const hide = state => {
      const newState = state;
      newState[id].isClosed = !state[id].isClosed;
      return newState;
    };

    this.setState(prev => ({
      userPermissionsState: hide(prev.userPermissionsState),
    }));
  };

  hideSubHeader = (mainHeaderId: number, subHeaderId: number) => {
    const hide = state => {
      const newState = state;
      newState[mainHeaderId].headers[subHeaderId].isClosed = !state[mainHeaderId].headers[subHeaderId].isClosed;
      return newState;
    };

    this.setState(prev => ({
      userPermissionsState: hide(prev.userPermissionsState),
    }));
  };

  hideInternalHeader = (mainHeaderId: number, subHeaderId: number, internalHeaderId: number) => {
    const hide = state => {
      const newState = state;
      newState[mainHeaderId].headers[subHeaderId].headers[internalHeaderId].isClosed =
        !state[mainHeaderId].headers[subHeaderId].headers[internalHeaderId].isClosed;
      return newState;
    };

    this.setState(prev => ({
      userPermissionsState: hide(prev.userPermissionsState),
    }));
  };

  render() {
    const { userPermissionsState } = this.state;

    const tickOrCross = state => {
      if (state) {
        return (
          <span className={'helpContentIcon -tickIcon'}>
            <SVG src={require('../../../../../assets/icons/tick2.svg')} />
          </span>
        );
      } else {
        return (
          <span className={'helpContentIcon -crossIcon'}>
            <SVG src={require('../../../../../assets/icons/cross.svg')} />
          </span>
        );
      }
    };

    return (
      <>
        <div className={'popup-content'}>
          <div className={'popup-content__text'} style={{ margin: '0 0 30px 2px' }}>
            <p>
              Depending on the responsibilities of the users on the project different user roles can be assigned. For the time being, each
              user can be assigned only one user role for all Projects.
            </p>
          </div>
          <div className={'popup-content__text coloredWord'} style={{ margin: '0 0 8px 0' }}>
            <p>There are 3 different levels that define user roles permissions:</p>
          </div>
          <div className={'popup-content__tableContainer'}>
            <Table className={'popup-content__table'}>
              <TableBody>
                <TableRow className={'popup-content__tableRow'}>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Admin</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          has full control over the projects he/she has been assigned to in terms of projects creation, teams shaping and
                          NFs/Deliverables modifications
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Manager</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          has full control over NFs/Deliverables modifications in the projects he/she has been assigned to; has roughly full
                          access in terms of teams shaping
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>User</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          has full control over NFs/Deliverables modifications only within his/her own company in the projects he/she has
                          been assigned to; has limited access in terms of teams shaping
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={'popup-content__tableContainer'}>
            <Table style={{ position: 'sticky', top: '-6px', zIndex: '2' }}>
              <TableHead>
                <TableRow style={{ height: '50px' }}>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ width: PermissionWidth.UserRoles }}
                    className={`mui-table__head popup-content__headCell`}
                  >
                    <span className={'popup-content__text'}>Activity/User Roles</span>
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ width: PermissionWidth.CompanyManager }}
                    className={`mui-table__head popup-content__headCell`}
                  >
                    <span className={'popup-content__text'}>User</span>
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ width: PermissionWidth.WorkflowManager }}
                    className={`mui-table__head popup-content__headCell`}
                  >
                    <span className={'popup-content__text'}>Manager</span>
                  </TableCell>
                  <TableCell
                    variant="head"
                    align="center"
                    style={{ width: PermissionWidth.ProjectAdmin }}
                    className={`mui-table__head popup-content__headCell`}
                  >
                    <span className={'popup-content__text'}>Admin</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            {userPermissionsState.map(mainHeader => {
              return (
                <>
                  <div className={'popup-content__accordion'} key={mainHeader.id} onClick={() => this.hideMainHeader(mainHeader.id)}>
                    <div className={'popup-content__accordionContent'}>
                      <SVG
                        src={require('../../../../../assets/icons/arrow_right.svg')}
                        className="popup-content__accordionArrow"
                        style={userPermissionsState[mainHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}
                      />
                      <span className={'popup-content__text'}>{mainHeader.title}</span>
                    </div>
                  </div>
                  <div style={userPermissionsState[mainHeader.id].isClosed ? { display: 'none' } : {}}>
                    {mainHeader.headers.map(subHeader => {
                      const mainHeaderItem = userPermissionsState[mainHeader.id].headers;
                      return (
                        <>
                          <div
                            className={'popup-content__accordion -subHeader'}
                            key={subHeader.id}
                            onClick={() => this.hideSubHeader(mainHeader.id, subHeader.id)}
                          >
                            <div className={'popup-content__accordionContent -subHeader'}>
                              <SVG
                                src={require('../../../../../assets/icons/arrow_right.svg')}
                                className="popup-content__accordionArrow"
                                style={mainHeaderItem[subHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}
                              />
                              <span className={'popup-content__text'}>{subHeader.title}</span>
                            </div>
                          </div>
                          <div style={mainHeaderItem[subHeader.id].isClosed ? { display: 'none' } : {}}>
                            {subHeader.headers &&
                              subHeader.headers.map(internalHeader => {
                                const subHeaderItem = mainHeaderItem[subHeader.id].headers;
                                return (
                                  <>
                                    <div
                                      className={'popup-content__accordion -internalHeader'}
                                      key={internalHeader.id}
                                      onClick={() => this.hideInternalHeader(mainHeader.id, subHeader.id, internalHeader.id)}
                                    >
                                      <div className={'popup-content__accordionContent -internalHeader'}>
                                        <SVG
                                          src={require('../../../../../assets/icons/arrow_right.svg')}
                                          className="popup-content__accordionArrow"
                                          style={subHeaderItem[internalHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}
                                        />
                                        <span className={'popup-content__text'}>{internalHeader.title}</span>
                                      </div>
                                    </div>
                                    <div style={subHeaderItem[internalHeader.id].isClosed ? { display: 'none' } : {}}>
                                      {internalHeader.permissions ? (
                                        <Table>
                                          <TableBody>
                                            {internalHeader.permissions.map((permission, index) => {
                                              return (
                                                <TableRow style={{ height: '36px' }} className={'popup-content__tableRow'} key={index}>
                                                  <TableCell
                                                    variant="body"
                                                    align="left"
                                                    style={{ width: PermissionWidth.UserRoles }}
                                                    className={`mui-table__cell popup-content__permissionsCell`}
                                                  >
                                                    <div style={{ marginLeft: '30px' }}>
                                                      <span className={'popup-content__text'}>{permission.title}</span>
                                                    </div>
                                                  </TableCell>
                                                  <TableCell
                                                    variant="body"
                                                    align="center"
                                                    style={{ width: PermissionWidth.CompanyManager }}
                                                    className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                                  >
                                                    {tickOrCross(permission.companyManager)}
                                                  </TableCell>
                                                  <TableCell
                                                    variant="body"
                                                    align="center"
                                                    style={{ width: PermissionWidth.WorkflowManager }}
                                                    className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                                  >
                                                    {tickOrCross(permission.workflowManager)}
                                                  </TableCell>
                                                  <TableCell
                                                    variant="body"
                                                    align="center"
                                                    style={{ width: PermissionWidth.ProjectAdmin }}
                                                    className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                                  >
                                                    {tickOrCross(permission.projectAdmin)}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      ) : null}
                                    </div>
                                  </>
                                );
                              })}
                            {subHeader.permissions ? (
                              <Table>
                                <TableBody>
                                  {subHeader.permissions.map((permission, index) => {
                                    return (
                                      <TableRow style={{ height: '36px' }} className={'popup-content__tableRow'} key={index}>
                                        <TableCell
                                          variant="body"
                                          align="left"
                                          style={{ width: PermissionWidth.UserRoles }}
                                          className={`mui-table__cell popup-content__permissionsCell`}
                                        >
                                          <div style={{ marginLeft: '19px' }}>
                                            <span className={'popup-content__text'}>{permission.title}</span>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          align="center"
                                          style={{ width: PermissionWidth.CompanyManager }}
                                          className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                        >
                                          {tickOrCross(permission.companyManager)}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          align="center"
                                          style={{ width: PermissionWidth.WorkflowManager }}
                                          className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                        >
                                          {tickOrCross(permission.workflowManager)}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          align="center"
                                          style={{ width: PermissionWidth.ProjectAdmin }}
                                          className={`mui-table__cell popup-content__iconCell popup-content__permissionsCell`}
                                        >
                                          {tickOrCross(permission.projectAdmin)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default UserRolesHelp;
