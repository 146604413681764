export const IncorrectProject = 'Incorrect Project';
export const IncorrectNeedForm = 'Incorrect Need Form';
export const IncorrectDeliverable = 'Incorrect Deliverable';
export const IncorrectSubmittal = 'Incorrect Submittal';
export const IncorrectCompany = 'Incorrect Company';
export const AccessDenied = 'Access denied';
export const NoProjectAssigned = 'No project assigned';
export const HashNotSaved = 'Incorrect link';
export const TitleList: string[] = [
  IncorrectProject,
  IncorrectNeedForm,
  IncorrectDeliverable,
  IncorrectSubmittal,
  IncorrectCompany,
  AccessDenied,
  NoProjectAssigned,
  HashNotSaved,
];

export const ServerErrorObj = {
  title: 'Oops! Something went wrong',
  secondaryBtnText: 'Refresh the page',
  description: `We're sorry, but we're having trouble processing your request right now. \n Our team has been notified and is working to resolve the issue as quickly as possible. We appreciate your patience and understanding.`,
};
export const PageNotFoundErrorObj = {
  title: 'Page not found',
  secondaryBtnText: 'Browse my projects',
  description: `The address you are trying to access is incorrect.\n\nAre you sure you wanted to go to: `,
};
export const ButtonsText = {
  goToProjectList: 'Go to Project list',
  goToSandbox: 'Go to Command center',
  goToTeamSettings: 'Go to Team settings',
  goToAvailableProjects: 'Go to available projects',
};
