import { DOMAIN_URI } from '../../../service/links';
import React from 'react';
import './MediaContentStyles.scss';
import { MediaModel } from '../../../models';

export const renderMedia = (media: MediaModel) => {
  if (!media) {
    return <span />;
  }
  const mimeType = media.ext.split('/')[0];
  if (mimeType === 'image') {
    return <img src={`${DOMAIN_URI}${media.file}`} alt="" />;
  }
  if (mimeType === 'video') {
    return (
      <video src={`${DOMAIN_URI}${media.file}`} autoPlay controls loop={true}>
        {`Sorry, your browser doesn't support embedded videos,
          but don't worry`}
        , you can <a href="videofile.ogg">download it</a>
        and watch it with your favorite video player!
      </video>
    );
  }
  if (mimeType === 'audio') {
    return (
      <audio controls src={`${DOMAIN_URI}${media.file}`}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    );
  }
  if (mimeType === 'youtube_video') {
    return (
      <iframe
        width="570"
        height="315"
        src={media.file}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
};
