import { createLogic } from 'redux-logic';
import * as actions from './historyActions';
import requestApi from '../../service/Api/requestApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetHistoryPayloadModel, GetHistoryResponseModel } from '../../models/history/history.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

const getHistoryLogic = createLogic({
  type: actions.GET_HISTORY,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    projectParam.ipp = 50;
    const type = projectParam.type;

    if (type) {
      delete projectParam.type;
    }

    requestApi
      .getHistory(type, projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(actions.getHistoryFail(response.data));
          throw response;
        }
        dispatch(actions.getHistorySuccess({ ...response, type }));
      })
      .catch(err => {
        dispatch(actions.getHistoryFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getHistory = createAsyncThunk<GetHistoryResponseModel, GetHistoryPayloadModel>(
  'historyReducer/getHistory',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectParam = payload;
      projectParam.ipp = 50;
      const type = projectParam.type;

      if (type) {
        delete projectParam.type;
      }

      const response: GetHistoryResponseModel = await requestApi.getHistory(type, projectParam);

      return { ...response, type };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export default [
  // getHistoryLogic
];
