import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import ReactDOMServer from 'react-dom/server';
import cn from 'classnames';
import { basicModules } from '../../../helpers/quillConfig';
import { addMention, mentionModule } from './quillModules';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { RibbonComponent } from './parts/RibbonComponent';
import { useClickOutside } from '../../../helpers/commonHooks';

import './TextQuillEditor.module.scss';

interface ITextQuillEditor extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value: string;
  handleChange: (value: string, delta: any, source: any, editor: any) => void;
  placeholder: string;
  handleSetQuillEditorRef?: (ref: React.MutableRefObject<any>) => void;
  customContainerClassName?: string;
  customEditorClassName?: string;
  customToolbarClassName?: string;
  showToolbar?: boolean;
  disabled?: boolean;
  stopFocus?: boolean;
  error?: string;
  ref?: React.MutableRefObject<any>;
  modules?: any;
  commentFormType?: string;
  readOnly?: boolean;
  onKeyPress?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  scrollingContainer?: string;
  fieldId: number | string;
  externalFieldId?: number | string;
  shouldFocusDescription?: boolean;
  commentSection?: boolean;
  autoFocus?: boolean;
}

//@ts-ignore
const icons = ReactQuill.Quill.import('ui/icons');
const colorPickerIcon = ReactDOMServer.renderToStaticMarkup(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.942 7.57451C11.9512 7.92651 11.8214 8.28143 11.5528 8.55007L8.11209 11.9907C7.59245 12.5104 6.74995 12.5104 6.23031 11.9907L2.78963 8.55007C2.52099 8.28143 2.39123 7.92651 2.40035 7.57451M11.942 7.57451C11.9343 7.27515 11.8261 6.9779 11.6174 6.73765C11.5933 6.68464 11.5595 6.63499 11.5159 6.59139L7.23174 2.30723M11.942 7.57451H2.40035M2.40035 7.57451C2.40887 7.24563 2.53863 6.91929 2.78963 6.66829C2.87809 6.57983 7.23174 2.30723 7.23174 2.30723M7.23174 2.30723L6.52607 1.60156M3.07358 14.4016H10.1472M13.0329 10.1307C12.991 10.0646 12.9193 10.0225 12.8415 10.0225C12.7638 10.0225 12.692 10.0646 12.6502 10.1307C12.5007 10.3771 12.0163 11.2185 12.0163 11.5852C12.0163 12.0419 12.387 12.4146 12.8415 12.4146C13.296 12.4146 13.6668 12.042 13.6668 11.5852C13.6668 11.2125 13.1824 10.3771 13.0329 10.1307Z"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
icons['bold'] = null;
icons['italic'] = null;
icons['underline'] = null;
icons['color'] = colorPickerIcon;
icons['list']['bullet'] = null;
icons['list']['ordered'] = null;
icons['link'] = null;

export const TextQuillEditor: React.FC<ITextQuillEditor> = ({
  handleSetQuillEditorRef,
  value,
  modules,
  handleChange,
  fieldId,
  externalFieldId,
  placeholder,
  readOnly,
  onKeyPress,
  onFocus,
  onBlur,
  scrollingContainer,
  customContainerClassName,
  customEditorClassName,
  customToolbarClassName,
  showToolbar,
  disabled,
  error,
  stopFocus,
  shouldFocusDescription,
  autoFocus,
  commentSection,
  ...props
}): JSX.Element => {
  const containerQuillRef = useRef(null);
  const quillEditorRef = useRef(null);
  const modulesInit = {
    ...basicModules,
    toolbar: {
      container: `#toolbar-${externalFieldId || fieldId}`,
      handlers: {
        addMention: addMention,
        // link: linkHandler,
      },
    },
    mention: mentionModule,
  };

  const themeClassQuillContainer = useGetThemeClass('b-quillContainer');
  const themeClassQuillAreaWrapper = useGetThemeClass('quillAreaWrapper');
  const themeClassRibbonWrapper = useGetThemeClass('ribbonWrapper');

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (typeof value === 'string') {
      setTextValue(value);
    }
  }, []);

  useEffect(() => {
    if (value !== textValue && value !== '<p><br></p>' && value !== '<p></p>') {
      setTextValue(value || '');
    }
  }, [value]);

  const handleChangeInput = (value, delta, source, editor) => {
    setTextValue(value);
    if (isFocus) {
      handleChange && handleChange(value, delta, source, editor);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
    onFocus && onFocus();
  };

  useClickOutside(containerQuillRef, () => setIsFocus(false));

  useEffect(() => {
    if (shouldFocusDescription) {
      setIsFocus(true);
    }
  }, [shouldFocusDescription]);

  useEffect(() => {
    if (autoFocus) {
      setIsFocus(true);
      quillEditorRef?.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (quillEditorRef.current) {
      const nodesLinkModal = document.querySelectorAll('.ql-tooltip');
      const nodesLinkModalArray = Array.from(nodesLinkModal);

      for (const node of nodesLinkModalArray) {
        const inputNode = node.querySelector('input');
        inputNode.dataset.link = 'Type or paste URL';
      }

      handleSetQuillEditorRef && handleSetQuillEditorRef(quillEditorRef.current);
    }
  }, [quillEditorRef.current]);

  const isEmpty = !value || value.split(' ').join('') === '<p></p>' || value === '<p><br></p>';

  return (
    <div
      ref={containerQuillRef}
      className={cn(`${themeClassQuillContainer}`, {
        [`${customContainerClassName}`]: customContainerClassName,
      })}
    >
      <div
        className={cn(themeClassRibbonWrapper, {
          ['-disabled']: disabled,
          [`${customToolbarClassName}`]: customEditorClassName,
        })}
        style={{ display: showToolbar ? '' : 'none' }}
      >
        <RibbonComponent fieldId={fieldId} disabled={disabled} />
      </div>
      <div
        className={cn(themeClassQuillAreaWrapper, {
          [`${customEditorClassName}`]: customEditorClassName,
          ['-selected']: !isEmpty,
          ['-focus']: isFocus && !disabled && !stopFocus,
          ['-disabled']: disabled,
          ['-error']: !!error,
          ['-commentSection']: commentSection,
        })}
      >
        <ReactQuill
          ref={quillEditorRef}
          value={textValue}
          onChange={handleChangeInput}
          placeholder={placeholder}
          modules={modules || modulesInit}
          readOnly={readOnly || disabled}
          onKeyPress={onKeyPress}
          onKeyDown={() => setIsFocus(true)}
          onFocus={handleFocus}
          onBlur={onBlur}
          scrollingContainer={scrollingContainer}
        />
        {!!error && <span className={`${themeClassQuillAreaWrapper}_error`}>{error}</span>}
      </div>
    </div>
  );
};
