import React from 'react';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { MfCodeListItemsInterface } from '../../../../../store/relatedItems/relatedItemsSlice.model';

import './MfCodeItemStyles.scss';

interface IProps {
  mfCode: MfCodeListItemsInterface;
  handleMfItemClick?: (mfCode: MfCodeListItemsInterface) => void;
}

const MfCodeItem: React.FC<IProps> = ({ mfCode, handleMfItemClick }) => {
  const themeClass = useGetThemeClass('b-MfCodeItem');

  return (
    <div className={`${themeClass}_container`}>
      <div className={`${themeClass}_item`} onClick={() => handleMfItemClick(mfCode)}>
        <div className={`${themeClass}_item_title`}>{mfCode.title}</div>
        <div className={`${themeClass}_item_icon`}>
          <TokenIcon iconName={mfCode.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
        </div>
      </div>
    </div>
  );
};

export default MfCodeItem;
