const REACT_APP_TOKEN_LOCALSTORAGE_NAME = 'token';

export const setToken = token => {
  if (!token) {
    return false;
  }
  window.localStorage.setItem(REACT_APP_TOKEN_LOCALSTORAGE_NAME, token);
};

export const getToken = () => {
  return window.localStorage.getItem(REACT_APP_TOKEN_LOCALSTORAGE_NAME);
};

export const deleteToken = () => {
  window.localStorage.removeItem(REACT_APP_TOKEN_LOCALSTORAGE_NAME);
  return true;
};

export const isLoggedIn = () => {
  return !!getToken();
};

export const getSelected = selectedClientId => {
  if (!selectedClientId) {
    return false;
  }
  return selectedClientId;
};

export const isSelectedClient = selectedClientId => {
  return !!getSelected(selectedClientId);
};

export default {
  setToken,
  getToken,
  deleteToken,
  isLoggedIn,
};
