import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmDialog } from './ConfirmDialog';
import { ConfirmDialogContext } from './context';
import { IWithConfirmDialogProps, IConfirmDialogState, ActualValidationFields } from './types';
import { useAppDispatch, useAppSelector } from '../../../../store/configure/configureStore';
import { changePrivate } from '../../../../store/common/commonThunk';
import useRouter from '../../../../hooks/useRouter/useRouter';

export const withConfirmDialog = <P extends IWithConfirmDialogProps>(Component: React.ComponentType<P>): React.ComponentType<P> => {
  const WithConfirmDialogComponent = (props: P) => {
    const { selectRequestId, requestUpdatePrivate, getNeedListData, needList } = props;

    const dispatch = useAppDispatch();
    const { urlProject } = useRouter();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [state, setState] = useState<IConfirmDialogState | null>(null);

    const requestInfo = useAppSelector(state => state.requestReducer.requestInfo);
    const selectedRequest = useMemo(() => requestInfo?.data?.[selectRequestId], [requestInfo, selectRequestId]);
    const active_project_id = sessionStorage.getItem('active_project_id');
    const parties = useAppSelector(state => state.projectReducer.projectInfo.parties)?.[active_project_id] || [];

    const validateConfirm = useCallback(
      (userIds: number[], companyIds: number[], checkNotRfi: boolean, actualValidationFields: ActualValidationFields) => {
        if (!selectedRequest) {
          return true;
        }

        const selectedCompanyIds = companyIds ? companyIds : [];
        const selectedRequestPartyId =
          actualValidationFields && actualValidationFields.actual_request_party_id
            ? actualValidationFields.actual_request_party_id
            : selectedRequest.request_party_id;
        const selectedResponsePartyId =
          actualValidationFields && actualValidationFields.actual_response_party_id
            ? actualValidationFields.actual_response_party_id
            : selectedRequest.response_party_id;

        const requestResponsePartyUserIds = parties?.reduce((acc, party) => {
          if (party.id === selectedRequestPartyId || party.id === selectedResponsePartyId) {
            const usersIds = party.users.map(user => user.id);
            acc = [...acc, ...usersIds];
          }

          return acc;
        }, []);

        const nonRequestResponsePartyCompanyIds = parties?.reduce((acc, party) => {
          if (party.id !== selectedRequestPartyId && party.id !== selectedResponsePartyId) {
            acc = [...acc, party.id];
          }

          return acc;
        }, []);

        const existIds: number[] = [
          // ...selectedRequest.all_mentions.map(({ user_id }) => user_id),
          // ...selectedRequest.watchers.map(({ user_id }) => user_id),
          // ...selectedRequest.ball_in_court.map(({ owner_id }) => owner_id),
          ...requestResponsePartyUserIds,
        ];

        const hasNonReqResUsersOrParties =
          userIds.filter(id => !existIds.includes(id)).length ||
          selectedCompanyIds.filter(id => nonRequestResponsePartyCompanyIds.includes(id)).length;

        if (checkNotRfi) {
          return !hasNonReqResUsersOrParties;
        } else {
          return !(selectedRequest.is_rfi && hasNonReqResUsersOrParties);
        }
      },
      [selectedRequest, parties],
    );

    const handleRequestDisableRfi = useCallback(
      async (callback: (isValidate: boolean) => void, needUpdateState: boolean, checkNotRfi: boolean) => {
        const sendCallback = () => {
          const isNeedList = urlProject[1] === 'needs-list';
          callback(false);
          if (isNeedList) {
            if (getNeedListData) {
              getNeedListData();
            } else {
              needList({
                filters: {},
                isGroupingActive: false,
                order_by: 'nf',
                order_dir: 'desc',
              });
            }
          }
        };

        if (!checkNotRfi) {
          await dispatch(changePrivate({ owner_type: 'request', owner_id: selectedRequest.id, is_private: false }));
          sendCallback();
        } else {
          sendCallback();
        }
      },
      [selectedRequest, validateConfirm, getNeedListData],
    );

    const handleShowConfirmDialog = useCallback(
      async ({
        onAllow,
        userIds,
        companyIds,
        needUpdateState,
        checkNotRfi,
        actualValidationFields,
        shouldNotValidate,
        ...state
      }: IConfirmDialogState) => {
        if (!shouldNotValidate && validateConfirm(userIds, companyIds, checkNotRfi, actualValidationFields)) {
          onAllow(true);
        } else if (shouldNotValidate) {
          setState({ ...state, needUpdateState, onAllow: () => onAllow(true) });
          setIsOpen(true);
        } else {
          setState({ ...state, userIds, needUpdateState, onAllow: () => handleRequestDisableRfi(onAllow, needUpdateState, checkNotRfi) });
          setIsOpen(true);
        }
      },
      [handleRequestDisableRfi, validateConfirm],
    );

    useEffect(() => {
      if (!isOpen) {
        setState(null);
      }
    }, [isOpen]);

    const handleCLose = () => {
      setIsOpen(false);
    };

    return (
      <ConfirmDialogContext.Provider
        value={{
          handleShowConfirmDialog,
        }}
      >
        <Component {...props} />
        <ConfirmDialog {...state} isOpen={isOpen} onClose={handleCLose} />
      </ConfirmDialogContext.Provider>
    );
  };

  return WithConfirmDialogComponent;
};
