import React, { ReactElement } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import Snippet from '../../../../controls/Snippet/Snippet';
import Divider from '../../../../controls/Divider/Divider';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { LinkedType } from '../../RelatedItemsTabConstants';
import {
  DeliverableSnippetModel,
  DocumentSnippetModel,
  RequestSnippetModel,
  SubmittalSnippetModel,
} from '../../../../../store/relatedItems/relatedItemsSlice.model';
import { PredecessorSuccessorModel } from '../../../../../models';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';

import './RelatedItemsSnippetStyles.scss';

interface IProps {
  item: any;
  handleOpenCard?: (snippetData: any) => void;
  handleUnlink?: (
    item: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel,
    type?: LinkedType,
  ) => void;
  relatedTabType?: RelatedItemsTabsType;
  snippetData: any;
  currentCard?: 'Nf' | 'Deliverable' | 'Submittal';
  isDragRequestCard: boolean;
  validityStatusIcon?: ReactElement | null;
  part: LinkedType;
  relation: 'single' | 'double';
  isDeactivated?: boolean;
  checkIfOverdue: (item: PredecessorSuccessorModel, type: LinkedType) => void;
  openedRequest: FullRequestModel | ISubmittal | PCDCard;
}

const RelatedItemsSnippet: React.FC<IProps> = ({
  item,
  handleOpenCard,
  handleUnlink,
  relatedTabType,
  snippetData,
  currentCard,
  isDragRequestCard,
  validityStatusIcon,
  part,
  relation,
  isDeactivated,
  checkIfOverdue,
  openedRequest,
}) => {
  const themeClass = useGetThemeClass('b-RelatedItemsSnippet');

  const overdueData = relation === 'double' ? checkIfOverdue(item, part) : checkIfOverdue(item, 'predecessor');
  const isSuperseded = openedRequest && openedRequest?.status === 'superseded';

  const renderIcon = () => {
    if (currentCard === 'Deliverable' && relatedTabType === 'request') {
      return !isSuperseded && item?.flags?.ovd_nf;
    }
    return overdueData?.isOverdue;
  };

  const renderToolTip = () => {
    if (currentCard === 'Deliverable' && relatedTabType === 'request') {
      return !isSuperseded && item?.flags?.ovd_nf ? 'Request due date threatens Deliverable Scheduled date' : '';
    }
    return overdueData?.overdueTooltip;
  };

  return (
    <div key={relation === 'single' ? `${item?.snippet}-${item?.id}` : `${item?.id}`} className={`${themeClass}-${relation}_snippet`}>
      <Snippet
        key={item.id}
        handleOpenCard={() => handleOpenCard(item)}
        handleUnlink={() => handleUnlink(item, part)}
        relatedTabType={relatedTabType}
        snippetData={snippetData}
        showWatchIcon={renderIcon()}
        watchToolTip={renderToolTip()}
        currentCard={currentCard}
        isDragRequestCard={isDragRequestCard}
        validityStatusIcon={validityStatusIcon}
        part={part}
        isDeactivated={isDeactivated}
      />
      <Divider customClassname={`${themeClass}-${relation}_snippet_divider`} direction={'horizontal'} type={'srf-5'} />
    </div>
  );
};

export default RelatedItemsSnippet;
