import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IDocumentNfPayload, IDocumentsReducer, IMainDDLDocuments } from './documentReducer.model';
import {
  getDDLlistDocuments,
  getDDLlistDocumentsNoNF,
  getDDLlistRequest,
  getMainSetDDL,
  setMainSetDDLFilters,
  setStatusNF,
  getDDLColumnSettings,
  setDDLColumnSettings,
  getDDLExport,
  deleteDocument,
  createDocument,
  updateDocument,
  updateDocumentValidityStatus,
  publicSet,
  deleteSet,
  createSet,
  updateSet,
  getTypeDDLSet,
} from './documentsThunk';

const initialState: IDocumentsReducer = {
  loading: false,
  ddl_filters: null,
  filters_loading: false,
  loading_all_set: false,
  main_ddl: { documents: [], list_set: [], list_buildings: [] },
  isFirstMainLoading: true,
  list_request: null,
  list_request_loading: false,
  list_documents: null,
  list_documents_no_nf: [],
  list_documents_loading: false,
  add_documents_nf: null,
  original_doc_id: null,
  set_status_nf_loading: false,
  is_set_ddl_column_settings: false,
  is_get_ddl_column_settings: false,
  column_settings: '',
  ddl_current_page: null,
  ddl_last_page: null,
  ddl_pagination_load: false,
  is_ddl_export: false,
  is_delete_doc: false,
  is_create_update_doc: false,
  error_message_doc: '',
  is_delete_set: false,
  is_create_update_set: false,
  error_message_set: '',
  ddlTypeSet: [],
  is_update_doc_validity_status_doc: false,
};

export const documentsSlice = createSlice({
  initialState,
  name: 'documentsReducer',
  reducers: {
    addDocumentNF(state, { payload }: PayloadAction<IDocumentNfPayload>) {
      state.add_documents_nf = payload.document_id;
      state.original_doc_id = payload.original_doc_id;
    },
    clearDocumentsNF(state) {
      state.add_documents_nf = null;
      state.original_doc_id = null;
    },
    cancelDocuments(state) {
      state.error_message_doc = '';
    },
    cancelSets(state) {
      state.error_message_set = '';
    },
    clearListDocuments(state) {
      state.list_documents = null;
    },
    resetDocumentsFirstMainLoading(state) {
      state.isFirstMainLoading = true;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getMainSetDDL
       */
      .addCase(getMainSetDDL.pending, state => {
        // state.main_ddl.documents = [];
        //state.main_ddl.list_set = [];
        state.loading_all_set = true;
        state.ddl_pagination_load = false;
      })
      .addCase(getMainSetDDL.fulfilled, (state, { payload }) => {
        const currentState = current(state);
        const all_documents =
          payload.documents.current_page > 1 ? [...currentState.main_ddl.documents, ...payload.documents.data] : payload.documents.data;
        state.ddl_pagination_load = payload.documents.current_page > 1 ? true : false;
        state.main_ddl.list_set = payload.list_set;
        state.main_ddl.list_buildings = payload.list_buildings;
        state.ddl_current_page = payload.documents.current_page;
        state.ddl_last_page = payload.documents.last_page;
        state.loading_all_set = false;
        state.isFirstMainLoading = false;

        state.main_ddl.documents = all_documents.map((currSer: IMainDDLDocuments) => {
          if (currSer.current_set_id) {
            const needSet = payload.list_set.find(set => set.id === currSer.current_set_id);

            return { ...currSer, current_set_color: needSet.color };
          } else {
            return { ...currSer, current_set_color: '#ffffff' };
          }
        });
      })
      .addCase(getMainSetDDL.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading_all_set = false;
        state.ddl_pagination_load = false;
        state.isFirstMainLoading = false;
      })
      /**
       * getTypeDDLSet
       */
      .addCase(getTypeDDLSet.pending, state => {
        return state;
      })
      .addCase(getTypeDDLSet.fulfilled, (state, { payload }) => {
        state.ddlTypeSet = payload;
      })
      .addCase(getTypeDDLSet.rejected, (state, { meta }) => {
        return state;
      })
      /**
       * setMainSetDDLFilters
       */
      .addCase(setMainSetDDLFilters.pending, state => {
        state.filters_loading = true;
      })
      .addCase(setMainSetDDLFilters.fulfilled, (state, { payload }) => {
        const currentState = current(state);
        state.main_ddl.documents =
          payload.documents.current_page > 1 ? [...currentState.main_ddl.documents, ...payload.documents.data] : payload.documents.data;
        state.ddl_pagination_load = payload.documents.current_page > 1 ? true : false;
        state.main_ddl.list_set = payload.list_set;
        state.main_ddl.list_buildings = payload.list_buildings;
        state.ddl_current_page = payload.documents.current_page;
        state.ddl_last_page = payload.documents.last_page;
        state.filters_loading = false;
        state.isFirstMainLoading = false;
      })
      .addCase(setMainSetDDLFilters.rejected, state => {
        state.filters_loading = false;
        state.isFirstMainLoading = false;
      })
      /**
       * getDDLlistRequest
       */
      .addCase(getDDLlistRequest.pending, state => {
        state.list_request_loading = true;
      })
      .addCase(getDDLlistRequest.fulfilled, (state, { payload }) => {
        state.list_request = payload;
        state.list_request_loading = false;
      })
      .addCase(getDDLlistRequest.rejected, state => {
        state.list_request_loading = false;
      })
      /**
       * getDDLlistDocuments
       */
      .addCase(getDDLlistDocuments.pending, state => {
        state.list_documents = null;
        state.list_documents_loading = true;
      })
      .addCase(getDDLlistDocuments.fulfilled, (state, { payload }) => {
        state.list_documents = payload;
        state.list_documents_loading = false;
      })
      .addCase(getDDLlistDocuments.rejected, state => {
        state.list_documents_loading = false;
      })
      /**
       * setStatusNF
       */
      .addCase(setStatusNF.pending, state => {
        state.set_status_nf_loading = true;
      })
      .addCase(setStatusNF.fulfilled, state => {
        state.set_status_nf_loading = false;
      })
      .addCase(setStatusNF.rejected, state => {
        state.set_status_nf_loading = false;
      })
      /**
       * getDDLlistDocumentsNoNF
       */
      .addCase(getDDLlistDocumentsNoNF.pending, state => {
        state.list_documents_no_nf = [];
        state.list_documents = null;
        state.list_documents_loading = true;
      })
      .addCase(getDDLlistDocumentsNoNF.fulfilled, (state, { payload }) => {
        state.list_documents_no_nf = payload;
        state.list_documents_loading = false;
      })
      .addCase(getDDLlistDocumentsNoNF.rejected, state => {
        state.list_documents_loading = false;
      })
      /**
       * getDDLColumnSettings
       */
      .addCase(getDDLColumnSettings.pending, state => {
        state.is_get_ddl_column_settings = true;
      })
      .addCase(getDDLColumnSettings.fulfilled, (state, { payload }) => {
        const currentState = current(state);
        state.is_get_ddl_column_settings = false;
        state.column_settings = payload?.setting;
      })
      .addCase(getDDLColumnSettings.rejected, state => {
        state.is_get_ddl_column_settings = false;
      })
      /**
       * setDDLColumnSettings
       */
      .addCase(setDDLColumnSettings.pending, state => {
        state.is_set_ddl_column_settings = true;
      })
      .addCase(setDDLColumnSettings.fulfilled, (state, { payload }) => {
        state.is_set_ddl_column_settings = false;
      })
      .addCase(setDDLColumnSettings.rejected, state => {
        state.is_set_ddl_column_settings = false;
      })
      /**
       * getDDLExport
       */
      .addCase(getDDLExport.pending, state => {
        state.is_ddl_export = true;
      })
      .addCase(getDDLExport.fulfilled, state => {
        state.is_ddl_export = false;
      })
      .addCase(getDDLExport.rejected, state => {
        state.is_ddl_export = false;
      })
      /**
       * deleteDocument
       */
      .addCase(deleteDocument.pending, state => {
        state.is_delete_doc = true;
      })
      .addCase(deleteDocument.fulfilled, state => {
        state.is_delete_doc = false;
      })
      .addCase(deleteDocument.rejected, state => {
        state.is_delete_doc = false;
      })
      /**
       * createDocument
       */
      .addCase(createDocument.pending, state => {
        state.is_create_update_doc = true;
        state.error_message_doc = '';
      })
      .addCase(createDocument.fulfilled, (state, { payload }) => {
        state.is_create_update_doc = false;
        state.error_message_doc = !payload.status ? payload.message : '';
      })
      .addCase(createDocument.rejected, state => {
        state.is_create_update_doc = false;
      })
      /**
       * updateDocument
       */
      .addCase(updateDocument.pending, state => {
        state.is_create_update_doc = true;
        state.error_message_doc = '';
      })
      .addCase(updateDocument.fulfilled, (state, { payload }) => {
        state.is_create_update_doc = false;
        state.error_message_doc = !payload.status ? payload.message : '';
      })
      .addCase(updateDocument.rejected, state => {
        state.is_create_update_doc = false;
      })
      /**
       * updateDocumentValidityStatus
       */
      .addCase(updateDocumentValidityStatus.pending, state => {
        state.is_update_doc_validity_status_doc = true;
        state.error_message_doc = '';
      })
      .addCase(updateDocumentValidityStatus.fulfilled, (state, { payload }) => {
        state.is_update_doc_validity_status_doc = false;
        state.error_message_doc = !payload.status ? payload.message : '';
      })
      .addCase(updateDocumentValidityStatus.rejected, state => {
        state.is_update_doc_validity_status_doc = false;
      })
      /**
       * deleteSet
       */
      .addCase(deleteSet.pending, state => {
        state.is_delete_set = true;
      })
      .addCase(deleteSet.fulfilled, state => {
        state.is_delete_set = false;
      })
      .addCase(deleteSet.rejected, state => {
        state.is_delete_set = false;
      })
      /**
       * createSet
       */
      .addCase(createSet.pending, state => {
        state.is_create_update_set = true;
        state.error_message_set = '';
      })
      .addCase(createSet.fulfilled, (state, { payload }) => {
        state.is_create_update_set = false;
        state.error_message_set = !payload.status ? payload.message : '';
      })
      .addCase(createSet.rejected, state => {
        state.is_create_update_set = false;
      })
      /**
       * updateSet
       */
      .addCase(updateSet.pending, state => {
        state.is_create_update_set = true;
        state.error_message_set = '';
      })
      .addCase(updateSet.fulfilled, (state, { payload }) => {
        state.is_create_update_set = false;
        state.error_message_set = !payload.status ? payload.message : '';
      })
      .addCase(updateSet.rejected, state => {
        state.is_create_update_set = false;
      })
      /**
       * publicSet
       */
      .addCase(publicSet.pending, state => {
        state.loading_all_set = true;
        state.ddl_pagination_load = false;
      })
      .addCase(publicSet.fulfilled, (state, { payload }) => {
        const currentState = current(state);
        const all_documents =
          payload.documents.current_page > 1 ? [...currentState.main_ddl.documents, ...payload.documents.data] : payload.documents.data;
        state.ddl_pagination_load = payload.documents.current_page > 1 ? true : false;
        state.main_ddl.list_set = payload.list_set;
        state.main_ddl.list_buildings = payload.list_buildings;
        state.ddl_current_page = payload.documents.current_page;
        state.ddl_last_page = payload.documents.last_page;
        state.loading_all_set = false;
        state.isFirstMainLoading = false;
        state.main_ddl.documents = all_documents.map((currSer: IMainDDLDocuments) => {
          if (currSer.current_set_id) {
            const needSet = payload.list_set.find(set => set.id === currSer.current_set_id);

            return { ...currSer, current_set_color: needSet.color };
          } else {
            return { ...currSer, current_set_color: '#ffffff' };
          }
        });
      })
      .addCase(publicSet.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading_all_set = false;
        state.ddl_pagination_load = false;
        state.isFirstMainLoading = false;
      });
  },
});

export default documentsSlice.reducer;
