import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment/moment';
import { useAppSelector } from '../../../../../../../store/configure/configureStore';
import {
  CustomFiltersModel,
  SandBoxWidgetsCalendarItemModel,
  SandBoxWidgetsPayloadModel,
} from '../../../../../../../store/request/requestReducer.model';
import { SandBoxCustomTabsPayloadModel } from '../../../../../../../models/sandBox/sandBoxArhitecture';
import CustomTooltip from '../../../../../../controls/Tooltip/Tooltip';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import SystemButton from '../../../../../../controls/ButtonComponents/SystemButton/SystemButton';
import CustomCalendar from '../../../../../../controls/CustomCalendar/CustomCalendar';
import TokenIcon from '../../../../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './HeatmapStyles.scss';
import CustomSkeleton from '../../../../../../controls/CustomSkeleton/CustomSkeleton';

interface IProps {
  loadWidgets: (widgets: SandBoxWidgetsPayloadModel) => void;
  relatedTo: 'user' | 'party';
  setCurrentStartWidgetDate: (date: string) => void;
  setCustomFilterTab: (
    tabs: SandBoxCustomTabsPayloadModel,
    type: 'request' | 'submittalInput' | 'deliverable' | 'submittalOutput',
    customFilters: CustomFiltersModel,
  ) => void;
  isShowOnlyOneMonth?: boolean;
}

const Heatmap = ({ loadWidgets, relatedTo, setCurrentStartWidgetDate, setCustomFilterTab, isShowOnlyOneMonth }: IProps) => {
  const alertList = useAppSelector(state => state.requestReducer.sandBoxWidgets?.calendar) || {};
  const isFirstSandBoxWidgetsLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxWidgetsLoading);

  const themeClassCalendar = useGetThemeClass('b-calendar');
  const themeClassHeatMap = useGetThemeClass('b-sandBox-heatmap');

  const getCalendarViewDates = (calendarDate: string | Date) => {
    const firstCalDate = new Date(moment(calendarDate).startOf('month').format('YYYY-MM-DD'));
    const secondCalDate = new Date(moment(calendarDate).add(1, 'M').startOf('month').format('YYYY-MM-DD'));

    return {
      firstCalDate,
      secondCalDate,
    };
  };

  const initialDatesState = useMemo(() => {
    return getCalendarViewDates(new Date());
  }, []);

  const [calendarDates, setCalendarDates] = useState<{ firstCalDate: Date; secondCalDate: Date }>(initialDatesState);

  const handleMonth = (action: 'prev' | 'prev2' | 'next' | 'next2') => {
    let newFirstCalDate;

    if (action === 'prev') {
      newFirstCalDate = new Date(moment(calendarDates.firstCalDate).subtract(1, 'M').startOf('month').format('YYYY-MM-DD'));
    }

    if (action === 'prev2') {
      newFirstCalDate = new Date(moment(calendarDates.firstCalDate).subtract(12, 'M').startOf('month').format('YYYY-MM-DD'));
    }

    if (action === 'next') {
      newFirstCalDate = new Date(moment(calendarDates.firstCalDate).add(1, 'M').startOf('month').format('YYYY-MM-DD'));
    }

    if (action === 'next2') {
      newFirstCalDate = new Date(moment(calendarDates.firstCalDate).add(12, 'M').startOf('month').format('YYYY-MM-DD'));
    }

    const newSecondCalDate = new Date(moment(newFirstCalDate).add(1, 'M').startOf('month').format('YYYY-MM-DD'));

    const startCalendarDate = moment(newFirstCalDate).startOf('month').format('YYYY-MM-DD');
    const endCalendarDate = moment(newSecondCalDate).endOf('month').format('YYYY-MM-DD');

    const widgetOptions = {
      related_to: relatedTo,
      widgets: [
        {
          alias: 'calendar',
          options: {
            types: ['requests', 'deliverables', 'submittalsInput', 'submittalsOutput'],
            start_date: startCalendarDate,
            end_date: endCalendarDate,
          },
        },
      ],
    };

    setCalendarDates({ firstCalDate: newFirstCalDate, secondCalDate: newSecondCalDate });
    setCurrentStartWidgetDate(newFirstCalDate);
    loadWidgets(widgetOptions);
  };

  const getTileClassName = useCallback(
    ({ activeStartDate, date, view }) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const dateLoadPercentage = alertList[formattedDate]?.val;

      if (dateLoadPercentage === 0.2) {
        return '-p20';
      }

      if (dateLoadPercentage === 0.4) {
        return '-p40';
      }

      if (dateLoadPercentage === 0.6) {
        return '-p60';
      }

      if (dateLoadPercentage === 0.8) {
        return '-p80';
      }

      if (dateLoadPercentage === 1) {
        return '-p100';
      }

      return '';
    },
    [alertList],
  );

  const loadCustomFilterTab = (type: 'request' | 'submittalInput' | 'deliverable' | 'submittalOutput', date: string) => {
    const newTabs = {
      [type]: {
        id: `${type}_custom`,
        title: 'My heatmap',
        isCustom: true,
        subSections: [
          {
            id: `${type}_custom_heatmap`,
            title: 'My heatmap',
          },
        ],
      },
    };

    const customFilters = {
      [type]: {
        filter_date: date,
      },
    };

    setCustomFilterTab(newTabs, type, customFilters);
  };

  const getToolTipContent = (dateCont: SandBoxWidgetsCalendarItemModel, date: string) => {
    const filterDate = moment(date).format('YYYY-MM-DD');
    const handleLoadTab = type => loadCustomFilterTab(type, filterDate);

    if (dateCont?.req || dateCont?.del || dateCont?.subIn || dateCont?.subOut) {
      return (
        <>
          <div className={`${themeClassHeatMap}_calendarHeatmap_tooltip_content`}>
            {!!dateCont?.req && (
              <span onClick={() => handleLoadTab('request')}>{`${dateCont?.req} ${dateCont?.req > 1 ? 'Need Forms' : 'Need Form'}`}</span>
            )}
            {!!dateCont?.subIn && (
              <span onClick={() => handleLoadTab('submittalInput')}>
                {`${dateCont?.subIn} ${dateCont?.subIn > 1 ? 'Input submittals' : 'Input submittal'}`}
              </span>
            )}
            {!!dateCont?.del && (
              <span onClick={() => handleLoadTab('deliverable')}>
                {`${dateCont?.del} ${dateCont?.del > 1 ? 'CL drawings' : 'CL drawing'}`}
              </span>
            )}
            {!!dateCont?.subOut && (
              <span onClick={() => handleLoadTab('submittalOutput')}>
                {`${dateCont?.subOut} ${dateCont?.subOut > 1 ? 'Output submittals' : 'Output submittal'}`}
              </span>
            )}
          </div>
        </>
      );
    }

    return '';
  };

  const getTileContent = ({ activeStartDate, date, view }) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const dateContent = alertList[formattedDate];
    const tooltipContent = getToolTipContent(dateContent, date);

    return (
      <div className={`${themeClassHeatMap}_calendarHeatmap_tooltipContainer`}>
        <CustomTooltip
          leaveDelay={500}
          leaveTouchDelay={500}
          enterDelay={500}
          title={tooltipContent}
          placement={'bottom'}
          customTooltipClass={`${themeClassHeatMap}_calendarHeatmap_tooltip`}
          isInteractive={true}
        >
          <div className={`${themeClassHeatMap}_calendarHeatmap_tooltipAnchor`} />
        </CustomTooltip>
      </div>
    );
  };

  const monthNames = useMemo(() => {
    const firstCalMonthName = moment(calendarDates.firstCalDate).format('MMMM YYYY');
    const secondCalMonthName = moment(calendarDates.secondCalDate).format('MMMM YYYY');

    return {
      firstCalMonthName,
      secondCalMonthName,
    };
  }, [calendarDates]);

  // if (isFirstSandBoxWidgetsLoading) {
  //   return <CustomSkeleton width={'100%'} height={344} />;
  // }

  return (
    <div className={`${themeClassHeatMap}`}>
      <div className={`${themeClassHeatMap}_header`}>
        <div className={`${themeClassHeatMap}_header_title`}>
          <div className={`${themeClassHeatMap}_header_icon_assets`}>
            <TokenIcon iconName={'assets'} size={16} />
          </div>
          <span>Heatmap</span>
        </div>
        <div className={`${themeClassHeatMap}_header_settings`}>
          {/*  <SystemButton type={'settings'} variant={'transparent'} size={'md'} />*/}
          {/* <SystemButton tooltip={''} type={'information'} size={'md'} variant={'transparent'} /> */}
        </div>
      </div>
      <div className={`${themeClassHeatMap}_calendarsContainer`}>
        <div className={`${themeClassHeatMap}_calendarsContainer_item`}>
          <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName`}>
            <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons -left`}>
              <div onClick={() => handleMonth('prev2')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow2`}>
                <SystemButton type={'chevron-double-right'} variant={'transparent'} size={'sm'} />
              </div>
              <div onClick={() => handleMonth('prev')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow1`}>
                <SystemButton type={'chevron-right'} variant={'transparent'} size={'sm'} />
              </div>
            </div>
            <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName_title`}>
              <span>{monthNames.firstCalMonthName}</span>
            </div>
            <div
              className={cn(`${themeClassHeatMap}_calendarsContainer_item_monthName_icons -right`, { ['hideArrows']: !isShowOnlyOneMonth })}
            >
              <div onClick={() => handleMonth('next')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow1`}>
                <SystemButton type={'chevron-right'} variant={'transparent'} size={'sm'} />
              </div>
              <div onClick={() => handleMonth('next2')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow2`}>
                <SystemButton type={'chevron-double-right'} variant={'transparent'} size={'sm'} />
              </div>
            </div>
          </div>
          <div className={`${themeClassHeatMap}_calendarHeatmap`}>
            <CustomCalendar
              activeStartDate={calendarDates.firstCalDate}
              name={'formData'}
              value={'hideValue'}
              showNavigation={false}
              tileClassName={getTileClassName}
              tileContent={getTileContent}
              shadowOff={true}
            />
          </div>
        </div>
        {!isShowOnlyOneMonth && (
          <div className={`${themeClassHeatMap}_calendarsContainer_item`}>
            <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName`}>
              <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons -left hideArrows`}>
                <div onClick={() => handleMonth('prev2')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow2`}>
                  <SystemButton type={'chevron-double-right'} variant={'transparent'} size={'sm'} />
                </div>
                <div onClick={() => handleMonth('prev')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow1`}>
                  <SystemButton type={'chevron-right'} variant={'transparent'} size={'sm'} />
                </div>
              </div>
              <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName_title`}>
                <span>{monthNames.secondCalMonthName}</span>
              </div>
              <div className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons -right`}>
                <div onClick={() => handleMonth('next')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow1`}>
                  <SystemButton type={'chevron-right'} variant={'transparent'} size={'sm'} />
                </div>
                <div onClick={() => handleMonth('next2')} className={`${themeClassHeatMap}_calendarsContainer_item_monthName_icons_arrow2`}>
                  <SystemButton type={'chevron-double-right'} variant={'transparent'} size={'sm'} />
                </div>
              </div>
            </div>
            <div className={`${themeClassHeatMap}_calendarHeatmap`}>
              <CustomCalendar
                activeStartDate={calendarDates.secondCalDate}
                name={'formData'}
                value={'hideValue'}
                showNavigation={false}
                tileClassName={getTileClassName}
                tileContent={getTileContent}
                shadowOff={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heatmap;
