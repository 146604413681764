import React, { Component } from 'react';
import { DOMAIN_SELF_URL } from '../../../../../../service/links';

class Updates_20230427_4 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <video width="100%" height="auto" src={`${DOMAIN_SELF_URL}/videos/use-comment-as-response.mp4`} controls>
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </div>
      </>
    );
  }
}

export default Updates_20230427_4;
