import { formatDateISO } from '../service/date';

export default class NeedListRequestDataHelper {
  static getRequestData = state => {
    let query_params = {};

    if (Object.values(state.query.sorting || {}).length) {
      query_params = { ...state.query.sorting };
    }

    if (state.query.search) {
      query_params = {
        ...query_params,
        search: state.query.search,
      };
    }

    if (state.query.page) {
      const page_param = { page: state.query.page };
      if (Object.keys(page_param)) {
        query_params = { ...query_params, ...page_param };
      }
    }

    query_params.filters = state?.query?.filters || undefined;

    let searches = {};
    if (Object.values(state?.searchFields ? state.searchFields : {}).length) {
      searches = { ...state.searchFields };
      query_params.searches = searches;
      delete searches.created_at;
      delete searches.due_date;
    }

    if (state.query?.conditions) {
      query_params.conditions = state.query.conditions;
    }

    if (state.query?.or_conditions) {
      query_params.or_conditions = state.query.or_conditions;
    }

    if (state.query?.default_filter) {
      query_params.default_filter = state.query.default_filter;
    }

    if (state.query?.filter_date) {
      query_params.filter_date = state.query.filter_date;
    }

    query_params.isGroupingActive = state.isGroupingActive;
    query_params.isBulkEditModeActive = state.isBulkEditModeActive;
    query_params.or_filters = state?.query?.or_filters || undefined;
    return query_params;
  };

  static getRequestDataNew = ({ query, searchFields, isGroupingActive, isBulkEditModeActive }) => {
    let query_params = {};

    if (Object.values(query.sorting || {}).length) {
      query_params = { ...query.sorting };
    }

    if (query.search) {
      query_params = {
        ...query_params,
        search: query.search,
      };
    }

    if (query.page) {
      const page_param = { page: query.page };
      if (Object.keys(page_param)) {
        query_params = { ...query_params, ...page_param };
      }
    }

    query_params.filters = query?.filters || undefined;

    let searches = {};
    if (Object.values(searchFields ? searchFields : {}).length) {
      searches = { ...searchFields };
      query_params.searches = searches;
      delete searches.created_at;
      delete searches.due_date;
    }

    if (query?.conditions) {
      query_params.conditions = query.conditions;
    }

    if (query?.or_conditions) {
      query_params.or_conditions = query.or_conditions;
    }

    if (query?.default_filter) {
      query_params.default_filter = query.default_filter;
    }

    if (query?.filter_date) {
      query_params.filter_date = query.filter_date;
    }

    if (query?.switch_and?.length > 0) {
      query_params.switch_and = query.switch_and;
    }

    query_params.isGroupingActive = isGroupingActive;
    query_params.isBulkEditModeActive = isBulkEditModeActive;
    query_params.or_filters = query?.or_filters || undefined;
    return query_params;
  };

  static getFiltersData = state => {
    let query_params = {};
    let filters = {};
    Object.keys(state?.needListFilters ? state.needListFilters : {}).map(key => {
      if (key !== 'due_date' && key !== 'created_at') {
        const filter = state.needListFilters[key]?.filter(f => f.isChecked);
        if (filter?.length) {
          filters[key] = [];
          filter.map(m => filters[key].push(m));
        }
      }
    });

    if (state.needListFilters?.created_at) {
      let createdAt = {};
      if (state?.needListFilters?.created_at?.date) {
        createdAt.date = state.needListFilters.created_at?.date.map(c => formatDateISO(c));
      }
      if (state?.needListFilters?.created_at?.filter || Array.isArray(state?.needListFilters?.created_at)) {
        let createdAtFilterList = [];
        const dateFilter = Array.isArray(state?.needListFilters?.created_at)
          ? state?.needListFilters?.created_at
          : state.needListFilters.created_at?.filter;
        const createdAtFilter = dateFilter?.filter(f => f.isChecked);
        if (createdAtFilter?.length) {
          createdAtFilter.map(m => createdAtFilterList.push(m));
        }
        if (createdAtFilterList.length) {
          createdAt.filter = createdAtFilterList;
        }
      }
      if (Object.values(createdAt).length) {
        filters.created_at = createdAt;
      }
    }

    if (state.needListFilters?.due_date) {
      let dueDate = {};
      if (state?.needListFilters?.due_date?.date) {
        dueDate.date = state.needListFilters.due_date?.date.map(c => formatDateISO(c));
      }
      if (state?.needListFilters?.due_date?.filter || Array.isArray(state?.needListFilters?.due_date)) {
        let dueDateFilterList = [];
        const dateFilter = Array.isArray(state?.needListFilters?.due_date)
          ? state?.needListFilters?.due_date
          : state.needListFilters.due_date?.filter;
        const dueDateFilter = dateFilter?.filter(f => f.isChecked);
        if (dueDateFilter?.length) {
          dueDateFilter.map(m => dueDateFilterList.push(m));
        }
        if (dueDateFilterList.length) {
          dueDate.filter = dueDateFilterList;
        }
      }
      if (Object.values(dueDate).length) {
        filters.due_date = dueDate;
      }
    }
    // if (state?.needListFilters?.due_date) {
    //   filters.due_date = state.needListFilters.due_date.map(c => formatDateISO(c))
    // }
    if (state?.needListFilters?.status) {
      const filter = state?.needListFilters?.status?.filter(f => f.isChecked);
      if (filter?.length) {
        filters.status = [];
        filter.map(f => filters.status.push(f));
      }
    }
    if (state.needListFilters?.commitment_status) {
      const filter = state.needListFilters?.commitment_status?.filter(f => f.isChecked);
      if (filter.length) {
        filters.commitment_status = [];
        filter.map(f => filters.commitment_status.push(f));
      }
    }
    if (state.needListFilters?.nf_status) {
      const filter = state.needListFilters?.nf_status?.filter(f => f.isChecked);
      if (filter.length) {
        filters.nf_status = [];
        filter.map(f => filters.nf_status.push(f));
      }
    }
    if (Object.values(filters).length) {
      query_params.filters = filters;
    }
    let searches = {};
    const searchKeys = Object.keys(state.searchFields ? state.searchFields : {});
    if (searchKeys.length) {
      searchKeys.map(searchField => {
        // if (searchField === 'due_date') {
        //   searches[searchField] = state.searchFields.due_date.map(c => formatDateISO(c));
        //   return;
        // }
        searches[searchField] = state.searchFields[searchField];
      });
      query_params.searches = searches;
    }
    return query_params;
  };

  static requestCardFiltersToNeedsList = (filters, needListFilters) => {
    const initFilters = { ...needListFilters };
    const currFilters = filters?.filters || {};

    Object.keys(currFilters).map(key => {
      const initFiltersForKey = key === 'due_date' ? initFilters[key].filter : initFilters[key];
      const filtersForKey = key === 'due_date' ? currFilters[key].filter || [] : currFilters[key];
      initFiltersForKey?.map(filter => {
        const isChecked = filtersForKey.find(f => f.id === filter.id)?.isChecked;
        filter.isChecked = !!isChecked;
        return filter;
      });
    });
    return initFilters;
  };
}
