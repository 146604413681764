import React, { FC, useEffect, useState } from 'react';
import { ProjectTypeModel } from '../../../../models';
import StatusProject from '../../../controls/Status/StatusProject/StatusProject';
import { DataGridBasic } from '../../DataGridBasic';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { SystemButton } from '../../../controls/ButtonComponents';
import DateField from '../../../controls/DateField/DateField';
import moment from 'moment';
import { useAppSelector } from '../../../../store/configure/configureStore';
import SkeletonGrid from '../../../controls/SkeletonGrid/SkeletonGrid';
import cn from 'classnames';

import '../ProjectTableStyles.scss';

interface IProps {
  isAdmin: boolean;
  items: any[];
  handleClickOpenEdit: (project_name: string) => void;
  selectProject: (id: number) => void;
  projectTypes: ProjectTypeModel[];
  pageHeaderHeight;
}

const ProjectTable: FC<IProps> = ({ items, isAdmin, handleClickOpenEdit, selectProject, projectTypes, pageHeaderHeight = 96 }) => {
  const [columns, setColumns] = useState([]);

  const { isFirstClientProjectLoading } = useAppSelector(state => state.userReducer);

  const themeClass = useGetThemeClass('b-projectsTable');

  useEffect(() => {
    setColumns([
      {
        key: 'title',
        name: 'Project name',
        width: 350,
        frozen: true,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'code',
        name: 'Acronym',
        width: 400,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'address',
        name: 'Address',
        width: 400,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'type',
        name: 'Type',
        width: 250,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'status',
        name: 'Status',
        width: 97,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'target_start',
        name: 'Kick-off',
        width: 140,
        isActive: true,
        formatter: p => renderCell(p),
      },
      {
        key: 'target_to',
        name: 'TCO',
        width: 132,
        isActive: true,
        formatter: p => renderCell(p),
      },
    ]);
  }, [items, projectTypes, isAdmin]);

  const renderCell = p => {
    const columnKey = p.column.key;
    const row = p.row;

    if (columnKey === 'title') {
      return (
        <div className={`${themeClass}_cell`}>
          <span
            className={cn(`${themeClass}_cell_link`, { ['-greyColor']: row.status === 'archived' })}
            onClick={() => selectProject(row.id)}
          >
            {row.title}
          </span>
          {isAdmin && (
            <div className={`${themeClass}_cell_controls`}>
              <SystemButton type={'settings'} size={'md'} variant={'transparent'} clickHandler={() => handleClickOpenEdit(row)} />
            </div>
          )}
        </div>
      );
    }

    if (columnKey === 'status') {
      return (
        <div className={`${themeClass}_cell`}>
          <StatusProject project_status={row.status} />
        </div>
      );
    }

    if (columnKey === 'type') {
      return (
        <div className={cn(`${themeClass}_cell`, { ['-greyColor']: row.status === 'archived' })}>
          <div className={`${themeClass}_cell_text`}>{projectType(row)}</div>
        </div>
      );
    }

    if (columnKey === 'target_start' || columnKey === 'target_to') {
      return (
        <div className={cn(`${themeClass}_cell`, { ['-greyColor']: row.status === 'archived' })}>
          <DateField
            selectedDate={moment(row[columnKey]).format('MM/DD/YYYY')}
            isSentRequestToChange={false}
            isOpenCalendar={false}
            isDiffDueScheduledDate={false}
            info={false}
            disabled={false}
            error={false}
            valueIsDate={true}
            isDisableBorder
          />
        </div>
      );
    }

    return (
      <div className={cn(`${themeClass}_cell`, { ['-greyColor']: row.status === 'archived' })}>
        <div className={`${themeClass}_cell_text`}>{row[columnKey]}</div>
      </div>
    );
  };

  const projectType = item => {
    if (item.project_type_id) {
      return projectTypes?.find(type => type.id === item.project_type_id).title;
    } else {
      return null;
    }
  };

  return (
    <div className={themeClass} style={{ height: `calc(100svh - ${pageHeaderHeight}px - 48px - 12px)` }}>
      {isFirstClientProjectLoading ? (
        <SkeletonGrid />
      ) : (
        <DataGridBasic rows={items} columns={columns} headerRowHeight={36} rowHeight={() => 52} canRowDisable={false} />
      )}
    </div>
  );
};

export default ProjectTable;
