import React, { FC, useMemo } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';

import './PlaceholderStyles.module.scss';

const Placeholder: FC<{
  type: 'Company' | 'Persona';
  imagePath?: string;
  firstName?: string;
  lastName?: string;
  size: 24 | 32 | 48 | 56 | 120 | 144;
}> = ({ type, imagePath, size, firstName, lastName }) => {
  const themeClass = useGetThemeClass('b-placeholder');
  const logoFirst = firstName?.length > 0 ? firstName.slice(0, 1) : '';
  const logoSecond = lastName?.length > 0 ? lastName.slice(0, 1) : '';
  const logo = logoFirst + logoSecond;
  const sizeName = useMemo(() => {
    if (size === 24) {
      return 16;
    } else if (size === 32) {
      return 20;
    } else if (size === 48) {
      return 40;
    } else if (size === 56) {
      return 40;
    } else if (size === 120) {
      return 88;
    } else if (size === 144) {
      return 104;
    }
  }, [size]);

  return (
    <>
      {type === 'Company' && (
        <div
          className={cn(`${themeClass} ${themeClass}_company ${themeClass}_company_${size}`, {
            [`${themeClass}_company_${size}_no_company_img`]: !imagePath,
          })}
        >
          {imagePath ? (
            <div
              className={`${themeClass}_company_${size} ${themeClass}_persona_${size}_img_center`}
              style={{
                background: `url(${imagePath}), white 50%`,
              }}
            ></div>
          ) : (
            <TokenIcon iconName={'logomark'} size={sizeName} />
          )}
        </div>
      )}
      {type === 'Persona' && (
        <div className={`${themeClass} ${themeClass}_persona ${themeClass}_persona_${size}`}>
          {imagePath ? (
            <div
              className={`${themeClass}_persona_${size} ${themeClass}_persona_${size}_img_center`}
              style={{
                background: `url(${imagePath}), white 50%`,
              }}
            ></div>
          ) : (
            <div className={`${themeClass}_persona_${size}_label_${size}`}>{logo}</div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(Placeholder);
