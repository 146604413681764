import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Step1View from './Step1View';
import moment from 'moment';
import { FILE_SIZE_LIMIT } from '../../../../../service/file';
import { isEmptyObject } from '../../../../../helpers/commonHelpers';
import * as ROUTES from '../../../../../navigation/routes';
import { filesUpload } from '../../../../../store/globalSettings/settingsThunk';
import { settingsSlice } from '../../../../../store/globalSettings/settingsSlice';
import { createProject, deleteBuilding, updateProject } from '../../../../../store/phasePlan/phasePlanThunk';
import { phasePlanSlice } from '../../../../../store/phasePlan/phasePlanSlice';
import { getClientsProjects } from '../../../../../store/user/userThunk';
import { withRouter } from '../../../../../navigation/withRouter/withRouter';
const { deleteFile, filesUploadClean } = settingsSlice.actions;
const { resetBuildingErrors } = phasePlanSlice.actions;

const setFields = (project, isNew) => {
  if (isNew) {
    return;
  }

  return {
    title: project.title,
    desc: project.desc,
    address: project.address,
    website: project.website,
    project_type_id: project.project_type_id,
    target_start: project.target_start,
    target_to: project.target_to,
    target_budget: project.target_budget,
    renderings: project.renderings,
    address_details: project.address_details,
    owner_name: project.owner_name,
    status: project.status,
    client_id: project.client_id,
    main_image: project.renderings?.find(image => image.is_final),
    work_week: project.work_week,
  };
};

class Step1Container extends React.Component {
  state = {
    anchorEl: null,
    formData: {
      title: '',
      desc: '',
      address: '',
      website: '',
      project_type_id: 0,
      target_start: '',
      target_to: '',
      target_budget: 0,
      renderings: [],
      address_details: null,
      owner_name: '',
      status: null,
      client_id: 0,
      main_image: null,
      work_week: 5,
    },
    phasePlanSelect: {
      project_types: [],
    },
    errors: {},
    project: null,
    companyTitle: '',
    currentClientLogo: '',
    chosenThumbnail: '',
    isSaveAnimeBtn: false,
    isSuccessCircular: false,
    isCircularProgress: false,
    nameHandleFile: [],
    isCircularProgressAnime: false,
    isNewProject: false,
  };

  getChosenThumbnail = project => {
    const chosenImage = project.renderings?.find(image => image.is_final);

    if (chosenImage) {
      return chosenImage.fullpath || chosenImage.file;
    } else {
      return '';
    }
  };

  componentDidMount() {
    const { project, phasePlanSelect, selected_client_id, clients, urlProject } = this.props;
    const { isNewProject } = this.state;

    if (Object.values(project).length) {
      const isNew = urlProject[0] === 'new-project' && !isNewProject;

      this.setState({
        project: project,
        chosenThumbnail: this.getChosenThumbnail(this.props.project),
        formData: {
          ...this.state.formData,
          ...setFields(project, isNew),
        },
      });
      this.checkAddress();
    }
    if (Object.values(phasePlanSelect).length) {
      this.setState({
        phasePlanSelect: phasePlanSelect,
      });
    }

    if (selected_client_id && !isEmptyObject(clients)) {
      const currentClient = clients.find(client => client.id === +selected_client_id) || clients[0];
      const currentClientLogo = currentClient?.renderings[currentClient.renderings.length - 1]?.file;
      setTimeout(() => {
        this.setState({
          formData: {
            ...this.state.formData,
            client_id: currentClient?.id,
          },
          companyTitle: currentClient?.title,
          currentClientLogo: currentClientLogo,
        });
      }, 10);
    }

    if (urlProject[1] === 'new-project' && !isNewProject) {
      this.handleChangeSelect('status', 'active');
      this.setState({
        isNewProject: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isNewProject } = this.state;
    const { project, phasePlanSelect, selected_client_id, clients, navigate } = this.props;

    if (this.props.filesData !== prevProps.filesData && this.props.filesData.project && this.props.filesData.project.data) {
      let files = Object.values(this.props.filesData.project.data);
      files = files.map(file => {
        return {
          ...file,
          name: file.original_name,
          file_path: file.filepath,
          file_name: file.original_name,
        };
      });
      const unicFiles = this.state.formData.renderings.filter(f => f.id);
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          renderings: [...unicFiles, ...files],
        },
      });
    }
    if (this.props.phasePlanSelect && this.props.phasePlanSelect !== prevProps.phasePlanSelect) {
      this.setState({
        phasePlanSelect: phasePlanSelect,
      });
    }
    if (this.props.project !== prevProps.project) {
      this.checkAddress();
      this.setState({
        project: this.props.project,
        chosenThumbnail: this.getChosenThumbnail(this.props.project),
        formData: {
          ...this.state.formData,
          ...setFields(project, isNewProject),
        },
      });
    }
    if (this.props.projectErrors !== prevProps.projectErrors) {
      this.setState({
        errors: this.props.projectErrors,
      });
    }
    if (
      !this.props.createProjectStatus.loading &&
      prevProps.createProjectStatus.loading !== this.props.createProjectStatus.loading &&
      !this.props.createProjectStatus.fail
    ) {
      this.setState({
        isSaveAnimeBtn: false,
      });
      setTimeout(() => {
        navigate(ROUTES.getCreateProjectId(sessionStorage.getItem('project_name'), this.props.project.id));
        this.props.getClientsProjects({ clientId: selected_client_id });
      }, 3000);
    }
    if (
      this.props.selected_client_id &&
      !isEmptyObject(this.props.clients) &&
      (this.props.selected_client_id !== prevProps.selected_client_id || this.props.clients !== prevProps.clients)
    ) {
      const currentClient = this.props.clients.find(client => client.id === +this.props.selected_client_id);
      const currentClientLogo = currentClient?.renderings[currentClient.renderings.length - 1]?.file;
      this.setState({
        formData: {
          ...this.state.formData,
          client_id: currentClient?.id,
        },
        companyTitle: currentClient?.title,
        currentClientLogo: currentClientLogo,
      });
    }
    if (this.props.updateProjectStatus !== prevProps.updateProjectStatus) {
      if (this.props.updateProjectStatus.loaded) {
        this.props.getClientsProjects({ clientId: selected_client_id });
      }
      this.setState({
        isSaveAnimeBtn: false,
      });
    }
    if (this.props.fileLoading && this.props.fileLoading !== prevProps.fileLoading) {
      setTimeout(() => {
        this.setState({
          isSuccessCircular: false,
        });
      }, 2000);
    }
  }

  componentWillUnmount() {
    const { filesUploadClean } = this.props;

    filesUploadClean({ type: 'project' });
  }

  checkAddress = () => {
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: this.props.project?.addressDetails?.place_id }, (results, status) => {
      if (status === 'OK') {
        const types = [];
        results[0].address_components.map(value => {
          types.push(...value.types);
        });
        const postalCode = types.filter(f => f === 'postal_code');
        const buildingCode = types.filter(f => f === 'street_number');
        if (!postalCode.length) {
          this.setState({
            isEmptyZipCode: true,
          });
        }
        if (!buildingCode.length) {
          this.setState({
            isEmptyStreet: true,
          });
        }
      }
    });
  };

  handleOpenCalendar = type => e => {
    if (!type) {
      return;
    }
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      typeOfOpenedCalendar: type,
    });
  };

  handleCloseCalendar = () => {
    this.setState({
      anchorEl: null,
      typeOfOpenedCalendar: null,
    });
  };

  selectAtDateHandler = selectedAtDate => {
    const { typeOfOpenedCalendar } = this.state;
    if (!typeOfOpenedCalendar) {
      return;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [typeOfOpenedCalendar]: selectedAtDate,
      },
    });
  };

  selectAtDateHandlerSingle = selectedAtDate => {
    const { typeOfOpenedCalendar } = this.state;
    if (!typeOfOpenedCalendar) {
      return;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [typeOfOpenedCalendar]: selectedAtDate,
      },
    });
    this.resetError(typeOfOpenedCalendar);
    this.handleCloseCalendar();
  };

  handleFile = e => {
    if (this.state.formData.renderings?.length === 5) {
      return;
    }
    const files = Array.from(e.target.files).filter(f => f?.size < FILE_SIZE_LIMIT);
    files &&
      files.map(i => {
        if (i.type.split('/')[0] !== 'image') {
          return;
        }
        this.props.filesUpload({ file: i, source: 'project' });
        const newNameFile = [...this.state.nameHandleFile, i.name];
        this.setState({
          isCircularProgress: true,
          isSuccessCircular: true,
          isCircularProgressAnime: true,
          nameHandleFile: newNameFile,
        });
      });
  };

  delFile = filename => {
    const { chosenThumbnail, formData } = this.state;

    const currentFileToDelete = this.state.formData.renderings.find(item => item.name === filename);
    const isMainImageDeleted = currentFileToDelete.fullpath === chosenThumbnail;

    this.setState({
      ...this.state,
      formData: {
        ...formData,
        main_image: isMainImageDeleted ? null : formData.main_image,
        renderings: formData.renderings.filter(item => item.name !== filename),
      },
      chosenThumbnail: isMainImageDeleted ? '' : chosenThumbnail,
    });
    this.props.deleteFile({ data: filename, sourse: 'project' });
  };

  addressInputChange = e => {
    this.setState({
      formData: {
        ...this.state.formData,
        address: e.target.value,
        address_details: null,
      },
    });
    this.resetError('address');
    return;
  };

  handleChangeValue = (name, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    this.resetError(name);
  };

  handleChangeValueOld = name => e => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: e.target.value,
      },
    });
    this.resetError(name);
  };

  locationChangeHandler = place => {
    const address_details = {
      full_address: place.formatted_address,
      country: '',
      state: '',
      city: '',
      street: '',
      building: '',
      zip: '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      place_id: place.place_id,
    };
    place.address_components.map(value => {
      if (value.types.find(f => f === 'route')) {
        address_details.street = value.long_name;
      }
      if (value.types.find(f => f === 'country')) {
        address_details.country = value.short_name;
      }
      if (value.types.find(f => f === 'postal_code')) {
        address_details.zip = value.long_name;
      }
      if (value.types.find(f => f === 'street_number')) {
        address_details.building = value.long_name;
      }
      if (value.types.find(f => f === 'locality')) {
        address_details.city = value.long_name;
      } else if (value.types.find(f => f === 'administrative_area_level_1')) {
        address_details.city = value.long_name;
      }
      if (value.types.find(f => f === 'administrative_area_level_1')) {
        address_details.state = value.long_name;
      }
    });
    this.setState({
      formData: {
        ...this.state.formData,
        address: place.formatted_address,
        address_details: address_details,
      },
      isEmptyZipCode: !address_details.zip,
      isEmptyStreet: !address_details.building,
    });
    this.resetError('project_address');
  };

  resetError = field => {
    this.setState({
      errors: {
        ...this.state.errors,
        [field]: false,
      },
    });
  };

  handleSave = () => {
    const { navigate, urlProject } = this.props;
    const formData = this.collectData({ ...this.state.formData });

    this.setState({
      isSaveAnimeBtn: true,
    });

    if (this.state?.project?.id) {
      this.props.updateProject({ id: this.state.project.id, formData: formData });
    } else {
      this.props.createProject({
        formData: formData,
        urlProject,
        callback: response => {
          navigate({
            pathname: `/${response.data.code}/project-settings/${response.data.id}`,
          });
        },
      });
    }
  };

  setNewProject = () => {
    this.setState({
      isNewProject: false,
    });
  };

  collectData = data => {
    const { project } = this.state;
    const chosenImage = project?.renderings?.find(image => image.is_final);

    if (data.target_start) {
      data.target_start = moment(data.target_start).format('YYYY-MM-DD');
    }
    if (data.target_to) {
      data.target_to = moment(data.target_to).format('YYYY-MM-DD');
    }
    if (this.state?.filesToDelete?.length) {
      data.renderings = [...data.renderings, ...this.state.filesToDelete];
    }
    if (!data.main_image || (data.main_image?.id && chosenImage && data.main_image.id === chosenImage.id)) {
      delete data.main_image;
    }

    return data;
  };

  handleChangeSelect = (name, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    this.resetError(name);
  };

  handleChangeBudget = name => value => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value.floatValue,
      },
    });
  };

  delSavedFile = file_id => {
    const { chosenThumbnail, formData } = this.state;

    const fileData = { file_id: file_id, action: 'delete' };
    const filesToDelete = this.state.filesToDelete ? [...this.state.filesToDelete, fileData] : [fileData];
    const currentFileToDelete = this.state.formData.renderings.find(item => item.id === file_id);
    const isMainImageDeleted = currentFileToDelete.file === chosenThumbnail;

    this.setState({
      formData: {
        ...formData,
        main_image: isMainImageDeleted ? null : formData.main_image,
        renderings: formData.renderings.filter(item => item.id !== file_id),
      },
      chosenThumbnail: isMainImageDeleted ? '' : chosenThumbnail,
      filesToDelete,
    });
  };

  handleEmptyAutoComplete = name => e => {
    if (e.target.value.length > 8) {
      return;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        address_details: {
          ...this.state.formData.address_details,
          [name]: e.target.value,
        },
      },
    });
    this.resetError(`address_details.${name}`);
  };

  handleChooseThumbnail = file => {
    this.setState({
      chosenThumbnail: file.fullpath || file.file,
      formData: {
        ...this.state.formData,
        main_image: {
          id: file.id ? file.id : 0,
          filepath: file.id ? '' : file.filepath,
        },
      },
    });
  };

  setCircularProgressAnime = () => {
    this.setState({
      isCircularProgressAnime: false,
    });
  };

  render() {
    const {
      anchorEl,
      typeOfOpenedCalendar,
      formData,
      phasePlanSelect,
      project,
      errors,
      isEmptyZipCode,
      isEmptyStreet,
      companyTitle,
      chosenThumbnail,
      isSaveAnimeBtn,
      isCircularProgress,
      isSuccessCircular,
      nameHandleFile,
      isCircularProgressAnime,
      isNewProject,
      currentClientLogo,
    } = this.state;

    const { getProjectStatus, exitEditMode } = this.props;

    const isFileAddDisabled = this.state.formData.renderings?.length === 5 || getProjectStatus.loading;

    return (
      <>
        <Step1View
          getProjectStatus={getProjectStatus}
          handleOpenCalendar={this.handleOpenCalendar}
          handleCloseCalendar={this.handleCloseCalendar}
          anchorEl={anchorEl}
          typeOfOpenedCalendar={typeOfOpenedCalendar}
          selectAtDateHandler={this.selectAtDateHandler}
          formData={formData}
          selectAtDateHandlerSingle={this.selectAtDateHandlerSingle}
          handleFile={this.handleFile}
          delFile={this.delFile}
          addressInputChange={this.addressInputChange}
          handleChangeValue={this.handleChangeValue}
          locationChangeHandler={this.locationChangeHandler}
          handleSave={this.handleSave}
          phasePlanSelect={phasePlanSelect}
          handleChangeSelect={this.handleChangeSelect}
          project={project}
          errors={errors}
          delSavedFile={this.delSavedFile}
          handleChangeBudget={this.handleChangeBudget}
          isEmptyZipCode={isEmptyZipCode}
          isEmptyStreet={isEmptyStreet}
          handleEmptyAutoComplete={this.handleEmptyAutoComplete}
          companyTitle={companyTitle}
          isFileAddDisabled={isFileAddDisabled}
          handleChooseThumbnail={this.handleChooseThumbnail}
          chosenThumbnail={chosenThumbnail}
          isSaveAnimeBtn={isSaveAnimeBtn}
          isCircularProgress={isCircularProgress}
          isSuccessCircular={isSuccessCircular}
          nameHandleFile={nameHandleFile}
          isCircularProgressAnime={isCircularProgressAnime}
          setCircularProgressAnime={this.setCircularProgressAnime}
          isCreateProject={this.props.isCreateProject}
          isNewProject={isNewProject}
          setNewProject={this.setNewProject}
          work_week={this.props.projectData?.work_week || 0}
          exitEditMode={exitEditMode}
          iconAvatar={currentClientLogo}
        />
      </>
    );
  }
}

const mapStateToProps = ({ settingsReducer, phasePlan, userReducer }) => {
  return {
    getProjectStatus: phasePlan.getProjectStatus,
    filesData: settingsReducer.files,
    fileLoading: settingsReducer.fileLoading,
    phasePlanSelect: phasePlan.phasePlanSelect,
    isCreateProject: phasePlan.isCreateProject,
    project: phasePlan.projectData,
    projectErrors: phasePlan.projectErrors,
    updateProjectStatus: phasePlan.updateProjectStatus,
    createProjectStatus: phasePlan.createProjectStatus,
    clients: userReducer.clients,
    selected_client_id: userReducer.selected_client_id,
    projectData: phasePlan.projectData,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      filesUpload,
      deleteFile,
      filesUploadClean,
      createProject,
      updateProject,
      deleteBuilding,
      resetBuildingErrors,
      getClientsProjects,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1Container));
