import React from 'react';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import CheckBox from '../../../../../controls/CheckBox/CheckBox';
import { FilterOptionItemInterface } from '../../../../../../store/relatedItems/relatedItemsSlice.model';
import cn from 'classnames';

import './FilterOptionItemStyles.scss';

interface IProps {
  filterOption: FilterOptionItemInterface;
  handleFilterOptionItemClick?: (event: React.SyntheticEvent, filterOption: FilterOptionItemInterface) => void;
}

const FilterOptionItem: React.FC<IProps> = ({ filterOption, handleFilterOptionItemClick }) => {
  const themeClass = useGetThemeClass('b-filterOptionItem');

  return (
    <>
      <div
        className={cn(`${themeClass}_item`, {
          [`-ml24`]: !!filterOption.isRequiringVerification,
        })}
        onClick={e => handleFilterOptionItemClick(e, filterOption)}
      >
        <CheckBox checked={filterOption.selected} />
        <div className={`${themeClass}_item_title`}>{filterOption.title}</div>
      </div>
    </>
  );
};

export default FilterOptionItem;
