import {
  BuildingModel,
  FileModel,
  FileUploadedModel,
  LocationCollectionModel,
  LocationModel,
  PartyModel,
  RequestBuildingModel,
  RequestDisciplineModel,
  SimpleTitleModel,
  TimezoneModel,
  TitleModel,
  TypicalLayoutModel,
} from '..';
import { KeyValueNumberModel } from '../key-value.model';
import { MilestoneItemModel } from '../phasePlan/milestone.model';
import { BaseResponse } from '../Api/response.model';

export interface ProjectModel extends ProjectSimpleModel {
  redmine?: string[];
  addressDetails: AddressDetailsModel;
  buildings: BuildingModel[];
  locations: LocationModel[];
  id: number;
  step: number;
}

export interface ProjectSimpleModel {
  id: number;
  address: string;
  architect_firstname: string;
  architect_lastname: string;
  code: string;
  created_at: TimezoneModel;
  desc: string;
  is_active: number;
  is_deleted: number;
  owner: any;
  owner_id: number;
  project_type_id: number;
  renderings: FileUploadedModel[];
  target_budget: string;
  target_start: string;
  target_to: string;
  title: string;
  updated_at: TimezoneModel;
  website: string;
  procore_sync_enabled: boolean;
  work_week: number;
  status?: string;
}

export type ProjectCreateFormDataModel = {
  title: string;
  desc: string;
  address: string;
  website: string;
  project_type_id: number;
  target_start: string;
  target_to: string;
  target_budget: number;
  renderings: FileUploadedModel[];
  address_details: AddressDetailsModel;
  owner_name: string;
  status: string;
  workweek: number;
  client_id: number;
  main_image: FileUploadedModel | FileModel;
};

export interface ProjectCreatePayloadModel {
  formData: ProjectCreateFormDataModel;
  urlProject: string[];
  callback?: (response: ProjectCreateResponseModel) => void;
}

export interface ProjectUpdatePayloadModel extends ProjectCreatePayloadModel {
  id: number;
}

export type ProjectCreateResponseModel = BaseResponse<ProjectModel>;

export type GetProjectById = BaseResponse<{ data: ProjectModel }>;

export interface AddressDetailsModel {
  building: string;
  city: string;
  country: string;
  full_address: string;
  id: number;
  is_active: number;
  is_deleted: number;
  lat: number;
  lng: number;
  owner_id: number;
  owner_type: string;
  place_id: string;
  state: string;
  street: string;
  zip: string;
}

export interface ProjectInfoModel {
  building_spaces: KeyValueNumberModel<SimpleTitleModel[]>;
  buildings: KeyValueNumberModel<RequestBuildingModel[]>;
  disciplines: KeyValueNumberModel<RequestDisciplineModel[]>;
  locations: KeyValueNumberModel<LocationCollectionModel[]>;
  milestones: KeyValueNumberModel<MilestoneItemModel[]>;
  parties: KeyValueNumberModel<PartyModel[]>;
  document_sets: KeyValueNumberModel<TitleModel[]>;
}

export class LocationSaveFormApi {
  id: number;
  title: string;
  footage: number;
  exterior_types: number[];
  construction_types: number[];
  below_grade: number;
  building_spaces: number[];
  position: number;
  occupancy_type_id: number;

  constructor(props) {
    this.id = props.id || undefined;
    this.title = props.title || null;
    this.footage = props.footage || null;
    this.exterior_types = props.exterior_types?.map(e => e.id || e) || [];
    this.construction_types = props.construction_types?.map(e => e.id || e) || [];
    this.below_grade = props.below_grade || 0;
    this.building_spaces = props.building_spaces?.map(e => e.id || e) || [];
    this.position = props.position || 0;
    this.occupancy_type_id = props.occupancy_type_id || null;
  }
}

export class BuildingSaveFormApi {
  id?: number;
  project_id: number;
  title: string;
  foundation_types: number[];
  units_count: number;
  locations: LocationSaveFormApi[];
  typical_layouts: TypicalLayoutModel[];

  constructor(props) {
    this.id = props.id || null;
    this.project_id = props.project_id || null;
    this.title = props.title || null;
    this.foundation_types = props.foundation_types.map(f => f.id || f) || null;
    this.units_count = props.units_count || null;
    this.locations = props.locations.map(location => new LocationSaveFormApi(location)) || null;
    this.typical_layouts = props.typical_layouts || [];
  }
}

export type CreateUpdateBuildingResponse = BaseResponse<BuildingModel>;

export type UpdateBuildingLocationsResponse = BaseResponse<{ data: BuildingModel }>;

export interface UpdateBuildingLocationsPayloadModel {
  building_id: number;
  locations: LocationModel[];
  typical_layouts: TypicalLayoutModel[];
}
