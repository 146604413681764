import fileDownload from 'js-file-download';

import commonApi from '../../service/Api/commonApi';
import { API_URI, DOMAIN_URI } from '../../service/links';

// import * as requestTypes from './commonActions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ConstructionCodesResponsePayload,
  IChangePrivatePayload,
  ICommonListSetData,
  IGetRequestsPayload,
  IImportBuildingSpacesPayload,
  IImportContentPlanPayload,
  IMfPayload,
  IUfPayload,
  IValidateBuildingSpacesImportPayload,
  IValidateContentPlanImportPayload,
  IValidateDDLImportPayload,
} from './commonReducer.model';
import {
  ConstructionCodesRequestPayload,
  CurrentProjectTemplatePayload,
  FileUploadedModel,
  IBuildingTemplatePayload,
  IContentPlanTemplatePayload,
  ICurrentProjectSpecialReportPayload,
  IDDLTemplatePayload,
  ValidationModel,
} from '../../models';
import { ImportDDL } from '../../components/common/DesignDocumentsLog/parts/UploadDocuments';
import { errorsSlice } from '../errors/errorsSlice';
import { getFeed } from '../comments/commentsThunk';
const { setShowErrorData } = errorsSlice.actions;

export const getUfCodes = createAsyncThunk<IUfPayload, string>(
  'commonReducer/getUfCodes',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const getParam = payload && { search: payload };
      const response = await commonApi.getUfCodes(getParam);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getMfCodes = createAsyncThunk<IMfPayload, string>(
  'commonReducer/getMfCodes',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const getParam = payload && { search: payload };
      const response = await commonApi.getMfCodes(getParam);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getConstructionCodes = createAsyncThunk<ConstructionCodesResponsePayload, ConstructionCodesRequestPayload>(
  'commonReducer/getConstructionCodes',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = payload;

      const response = await commonApi.getConstructionCodes(data);

      if (callback) {
        callback();
      }

      return { ...response.data, type: data.table };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getRequestsFilters = createAsyncThunk<IGetRequestsPayload, { project_id: number; name: string }>(
  'commonReducer/getRequestsFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const getParam = payload;
      const response = await commonApi.getRequestsFilters(getParam);

      return { response, name: payload.name };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getCurrentProjectTemplate = createAsyncThunk<any, CurrentProjectTemplatePayload>(
  'commonReducer/getCurrentProjectTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}import-export/${id}/import-need-list-template`);

      const response = await commonApi.getFilePost(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSpecificationTemplate = createAsyncThunk<any, CurrentProjectTemplatePayload>(
  'commonReducer/getSpecificationTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}import-export/${id}/import-content-plan-mf-template`);

      const response = await commonApi.getFilePost(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const constructionDriverDownloadFile = createAsyncThunk<any, CurrentProjectTemplatePayload>(
  'commonReducer/constructionDriverDownloadFile',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { buildingId, versionId, filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}construction-driver/download-file`);

      const response = await commonApi.getFilePost(url, { building_id: buildingId, version_id: versionId });
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getBuildingTemplate = createAsyncThunk<any, IBuildingTemplatePayload>(
  'commonReducer/getBuildingTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}import-export/${id}/import-building-spaces-template`);

      const response = await commonApi.getFilePost(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getContentPlanTemplate = createAsyncThunk<any, IContentPlanTemplatePayload>(
  'commonReducer/getContentPlanTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { filename, id } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}import-export/${id}/import-content-plan-template`);

      const response = await commonApi.getFilePost(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDDLTemplate = createAsyncThunk<any, IDDLTemplatePayload>(
  'commonReducer/getDDLTemplate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}ddl/get-template`);

      const response = await commonApi.getFilePost(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getCurrentProjectSpecialReport = createAsyncThunk<any, ICurrentProjectSpecialReportPayload>(
  'commonReducer/getCurrentProjectSpecialReport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filename } = payload;
      const url = new URL(`${DOMAIN_URI}${API_URI}project/special-report/${id}`);

      const response = await commonApi.getFile(url);
      fileDownload(response.data, filename);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateNeedListImport = createAsyncThunk<ValidationModel, FileUploadedModel>(
  'commonReducer/validateNeedListImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));
      const data = payload;

      const response = await commonApi.validateNeedListImport({ files: data }, id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateSpecificationImport = createAsyncThunk<ValidationModel, FileUploadedModel>(
  'commonReducer/validateSpecificationImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));
      const data = payload;

      const response = await commonApi.validateSpecificationImport({ files: data }, id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateConstructionDriverImport = createAsyncThunk<ValidationModel, FileUploadedModel>(
  'commonReducer/validateConstructionDriverImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data, buildingId } = payload;

      const response = await commonApi.validateConstructionDriverImport({ files: data, building_id: buildingId });

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateBuildingSpacesImport = createAsyncThunk<ValidationModel, IValidateBuildingSpacesImportPayload>(
  'commonReducer/validateBuildingSpacesImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data, id } = payload;

      const response = await commonApi.validateBuildingSpacesImport({ files: data }, id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateContentPlanImport = createAsyncThunk<ValidationModel, IValidateContentPlanImportPayload>(
  'commonReducer/validateContentPlanImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data, id } = payload;

      const response = await commonApi.validateContentPlanImport({ files: data }, id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const validateDDLImport = createAsyncThunk<ValidationModel, IValidateDDLImportPayload>(
  'commonReducer/validateDDLImport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = payload;
      const id = Number(sessionStorage.getItem('active_project_id'));

      data.project_id = id;
      const response = await commonApi.validateDDLImport(data, id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importNeedList = createAsyncThunk<string, string>(
  'commonReducer/importNeedList',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));

      const response = await commonApi.importNeedList({ filepath: payload }, id);
      return response.message;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importSpecification = createAsyncThunk<string, string>(
  'commonReducer/importSpecification',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = Number(sessionStorage.getItem('active_project_id'));

      const response = await commonApi.importSpecification({ filepath: payload }, id);
      return response.message;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importConstructionDriver = createAsyncThunk<string, string>(
  'commonReducer/importConstructionDriver',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, buildingId } = payload;
    try {
      const response = await commonApi.importConstructionDriver({ filepath: data, building_id: buildingId });
      return response.message;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importBuildingSpaces = createAsyncThunk<string, IImportBuildingSpacesPayload>(
  'commonReducer/importBuildingSpaces',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filepath } = payload;

      const response = await commonApi.importBuildingSpaces({ filepath: filepath }, id);
      return response.message;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importContentPlan = createAsyncThunk<string, IImportContentPlanPayload>(
  'commonReducer/importContentPlan',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, filepath } = payload;

      const response = await commonApi.importContentPlan({ filepath: filepath }, id);
      return response.message;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const importDDL = createAsyncThunk<string, ImportDDL>('commonReducer/importDDL', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { id, filepath, title, date, color, document_set_id, type_id } = payload;
    const project_id = Number(sessionStorage.getItem('active_project_id'));
    const data = document_set_id
      ? { filepath: filepath, document_set_id: document_set_id, project_id: project_id }
      : { filepath: filepath, title: title, date: date, type_id, project_id: project_id };

    const response = await commonApi.importDDL(data, id);
    return response.message;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getListSetDDL = createAsyncThunk<ICommonListSetData[], number>(
  'commonReducer/getListSetDDL',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = payload || Number(sessionStorage.getItem('active_project_id'));

      const response = await commonApi.getListSetDDL(id);
      return response.data.list_set;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const changePrivate = createAsyncThunk<any, IChangePrivatePayload>(
  'commonReducer/changePrivate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const project_id = Number(sessionStorage.getItem('active_project_id'));

      const bodyParams = {
        ...payload,
        project_id,
      };

      const response = await commonApi.changePrivate(bodyParams);

      dispatch(getFeed({ owner_id: payload.owner_id, type: payload.owner_type }));

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
