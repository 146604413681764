import React, { FC, ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { AppBar, AsideHolder } from '../../components/common';
import CardGlobalDialog from '../../components/common/CardGlobalDialog/CardGlobalDialog';
import { GlobalPromptPopUp } from '../../components/common/GlobalPromptPopUp/GlobalPromptPopUp';
import useRouter from '../../hooks/useRouter/useRouter';
import { useAppSelector } from '../../store/configure/configureStore';
import { useGetThemeClass } from '../../helpers/designTokens';

interface IProps {
  children: ReactNode;
  isUserWithProjects: boolean;
  isPostLoginPage: boolean;
}

const Layout: FC<IProps> = ({ children, isUserWithProjects, isPostLoginPage }) => {
  const themeClass = useGetThemeClass('b-routerContainer');
  const { urlProject, searchParams } = useRouter();
  const [openRequestSubId, setOpenRequestSubId] = useState(null);
  const [openRequestSubNf, setOpenRequestSubNf] = useState(null);
  const [prevIsUrlNfCardPreview, setPrevIsUrlNfCardPreview] = useState(false);
  // User slice
  const user = useAppSelector(state => state.userReducer);

  const isUrlNfCardTab = useMemo(() => {
    return !!searchParams.get('nf');
  }, [searchParams]);

  const isUrlNfCardPreview = useMemo(() => {
    return !!searchParams.get('previewCard');
  }, [searchParams]);

  useEffect(() => {
    if (isUrlNfCardPreview) {
      const nfPreviewData = JSON.parse(searchParams.get('previewCard'));
      setOpenRequestSubId(Number(nfPreviewData.id));
      setOpenRequestSubNf(Number(nfPreviewData.nf));
      setPrevIsUrlNfCardPreview(true);
    }
    if (prevIsUrlNfCardPreview && !isUrlNfCardPreview) {
      setOpenRequestSubId(null);
      setOpenRequestSubNf(null);
      setPrevIsUrlNfCardPreview(false);
    }
  }, [isUrlNfCardPreview, searchParams]);

  const isUrlPCDCardTab = useMemo(() => {
    return !!searchParams.get('building') && !!searchParams.get('deliverable');
  }, [searchParams]);

  const isUrlSubmittalCardTab = useMemo(() => {
    return !!searchParams.get('submittal');
  }, [searchParams]);

  const isSeparateTab = useMemo(() => {
    return urlProject && urlProject[1] === 'card';
  }, [urlProject]);

  const isUserInfoEmpty = useMemo(() => {
    return user.userInfo.id === 0;
  }, [user?.userInfo?.id]);

  if (isPostLoginPage) {
    return <Suspense fallback={<div></div>}>{children}</Suspense>;
  }

  return (
    <div className={`b-routerContainer ${themeClass}`}>
      <AsideHolder />
      {isUserWithProjects && (
        <CardGlobalDialog
          isUrlNfCardTab={isUrlNfCardTab}
          isUrlPCDCardTab={isUrlPCDCardTab}
          asideOpen={false}
          isActivePCDCard={true}
          isUserInfoEmpty={isUserInfoEmpty}
          isUrlSubmittalCardTab={isUrlSubmittalCardTab}
          isSeparateTab={isSeparateTab}
        />
      )}
      {/* todo - second card*/}
      {/*{isUrlSecondCard && (*/}
      {/*  <CardGlobalDialog*/}
      {/*  isUrlNfCardTab={false}*/}
      {/*  isUrlPCDCardTab={false}*/}
      {/*  asideOpen={false}*/}
      {/*  isActivePCDCard={true}*/}
      {/*  isUserInfoEmpty={isUserInfoEmpty}*/}
      {/*  isUrlSubmittalCardTab={false}*/}
      {/*  isSeparateTab={false}*/}
      {/*  />*/}
      {/*)}*/}
      {isUrlNfCardPreview && openRequestSubId && (
        <CardGlobalDialog
          isUrlNfCardTab={false}
          isUrlPCDCardTab={false}
          asideOpen={false}
          isActivePCDCard={true}
          isUserInfoEmpty={isUserInfoEmpty}
          isUrlSubmittalCardTab={false}
          isSeparateTab={false}
          isPreviewCard={isUrlNfCardPreview}
          selectRequestIdForPreview={openRequestSubId}
          selectRequestNfForPreview={openRequestSubNf}
        />
      )}
      {isUserWithProjects && <GlobalPromptPopUp />}
      <div className={`b-routerContainer__closedMenu ${themeClass}__main`}>
        <AppBar />
        <main className="main_content">
          <Suspense fallback={<div></div>}>{children}</Suspense>
        </main>
      </div>
    </div>
  );
};

export default Layout;
