import React, { FC, useState, useEffect, useMemo } from 'react';
import ReportingGaugeChart from './parts/ReportingCharts/ReportingGaugeChart/ReportingGaugeChart';
import ReportingPieChart from './parts/ReportingCharts/ReportingPieChart/ReportingPieChart';
import ReportingComposedChart from './parts/ReportingCharts/ReportingComposedChart/ReportingComposedChart';
import ReportingComposedLabels from './parts/ReportingCharts/ReportingComposedChart/ReportingComposedLabels';
import SelectGroupingChart from './parts/ReportingCharts/SelectGroupingChart/SelectGroupingChart';
import SelectTimePeriod from './parts/ReportingCharts/SelectTimePeriod/SelectTimePeriod';
import ReportingProfile from './parts/ReportingProfile/ReportingProfile';
import ReportingGrid from './parts/ReportingGrids/ReportingGrid';
import LinearDeterminate from '../../controls/LinearDeterminate/LinearDeterminate';
import SandBox from '../SandBox/SandBox';
import PageHeader from '../../controls/PageHeader/PageHeader';
import { useGetThemeClass } from '../../../helpers/designTokens';
import SegmentedButton from '../../controls/ButtonComponents/SegmentedButton/SegmentedButton';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import Divider from '../../controls/Divider/Divider';
import CustomSkeleton from '../../controls/CustomSkeleton/CustomSkeleton';
import { useAppSelector } from '../../../store/configure/configureStore';
import SkeletonGrid from '../../controls/SkeletonGrid/SkeletonGrid';

import './ReportingStyle.scss';

export type BreadcrumbType = {
  id: number;
  title: string;
  type: string;
};

const workingOptions = [
  {
    id: 'users',
    title: 'Users',
  },
  {
    id: 'sandbox',
    title: 'Command center',
  },
];

const ReportingView: FC<{
  loading: boolean;
  loadingTabel: boolean;
  reportingData: any;
  reportingDataTabel: any;
  timePeriodName: string;
  handleTimePeriod: (timePeriodName: string, selectPeriod: string) => void;
  selectData: string;
  selectedCompanyId: number;
  selectedCompanyName: string;
  selectGroup: string;
  selectedUser: string;
  selectedUserId: number;
  isShowNFs: boolean;
  goBackHandleFromCompany: () => void;
  goBackFromUser: () => void;
  handleGroup: (select: string) => void;
  needsListFilters: (data) => void;
  handleShowNFs: () => void;
  project_title: string;
}> = ({
  loading,
  loadingTabel,
  reportingData,
  reportingDataTabel,
  timePeriodName,
  handleTimePeriod,
  selectData,
  selectedCompanyId,
  selectedCompanyName,
  selectGroup,
  selectedUser,
  selectedUserId,
  isShowNFs,
  goBackHandleFromCompany,
  goBackFromUser,
  handleGroup,
  needsListFilters,
  handleShowNFs,
  project_title,
}) => {
  const [first_percent, set_first_percent] = useState<number>(0);
  const [second_percent, set_second_percent] = useState<number>(0);
  const [barChartList, setBarChartList] = useState(null);
  const [isHoverPie, setIsHoverPie] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>(null);
  const [breadcrumbsSelect, setBreadcrumbsSelect] = useState<BreadcrumbType>(null);
  const [visibleChart, setVisibleChart] = useState<string>('first');

  const { isFirstLoading, isFirstTableLoading } = useAppSelector(state => state.reportingReducer);

  const themeClass = useGetThemeClass('b-reportingWrapper');

  const { sizeResolution } = GetResolution();

  useEffect(() => {
    if (selectedCompanyName && !selectedUser) {
      const bred_list = [
        {
          id: 1,
          title: project_title,
          type: 'project',
        },
        {
          id: selectedCompanyId,
          title: selectedCompanyName,
          type: 'company',
        },
      ];
      setBreadcrumbs(bred_list);
      setBreadcrumbsSelect(bred_list[1]);
    } else if (selectedCompanyName && selectedUser) {
      const bred_list = [
        {
          id: 1,
          title: project_title,
          type: 'project',
        },
        {
          id: selectedCompanyId,
          title: selectedCompanyName,
          type: 'company',
        },
        {
          id: selectedUserId,
          title: selectedUser,
          type: 'user',
        },
      ];
      setBreadcrumbs(bred_list);
      setBreadcrumbsSelect(bred_list[2]);
    } else {
      const bred_list = [
        {
          id: 1,
          title: project_title,
          type: 'project',
        },
      ];
      setBreadcrumbs(bred_list);
      setBreadcrumbsSelect(bred_list[0]);
    }
  }, [selectedCompanyName, selectedUser]);

  useEffect(() => {
    if (reportingData?.header_data?.ppi_log.length) {
      const ppi_log = reportingData?.header_data?.ppi_log.length > 0 ? [...reportingData?.header_data?.ppi_log] : [];
      ppi_log.sort((a, b) => new Date(a.date) - new Date(b.date));
      const lastIndex = reportingData?.header_data?.ppi_log.length - 1;
      const firstPercent = reportingData?.header_data?.ppi_log[0].value;
      set_first_percent(firstPercent);
      const secondPercent = reportingData?.header_data?.ppi_log[lastIndex].value;
      set_second_percent(secondPercent);
    } else {
      set_first_percent(0);
      set_second_percent(0);
    }
  }, [reportingData?.header_data?.ppi_log]);

  const getBarChartList = list => {
    setBarChartList(list);
  };

  const handleIsHoverPie = isHover => {
    setIsHoverPie(isHover);
  };

  const handleSelectBreadcrumbs = (item: BreadcrumbType) => {
    if (item.type === 'project') {
      goBackHandleFromCompany();
    }
    if (item.type === 'company') {
      goBackFromUser();
    }
  };

  const handleVisibleChart = (isOnBtnLeft: boolean, resolution: string) => {
    if (isOnBtnLeft) {
      const VisibleChart =
        resolution === '1440' || resolution === '640'
          ? 'first'
          : sizeResolution === '1366' || resolution === '1024' || resolution === '960'
          ? visibleChart === 'third'
            ? 'second'
            : 'first'
          : 'first';
      setVisibleChart(VisibleChart);
    } else {
      const VisibleChart =
        resolution === '1440' || resolution === '640'
          ? 'second'
          : sizeResolution === '1366' || resolution === '1024' || resolution === '960'
          ? visibleChart === 'first'
            ? 'second'
            : 'third'
          : 'third';
      setVisibleChart(VisibleChart);
    }
  };

  const isVisiblePie = useMemo(() => {
    if (sizeResolution === '1920' || ((sizeResolution === '1440' || sizeResolution === '640') && visibleChart === 'second')) {
      return true;
    }
    if ((sizeResolution === '1366' || sizeResolution === '1024' || sizeResolution === '960') && visibleChart === 'third') {
      return true;
    }
    return false;
  }, [visibleChart, sizeResolution]);

  const isVisibleComposedChart = useMemo(() => {
    if (
      sizeResolution === '1920' ||
      (sizeResolution === '640' && visibleChart === 'second') ||
      (sizeResolution === '1440' && visibleChart === 'first')
    ) {
      return true;
    }
    if ((sizeResolution === '1366' || sizeResolution === '1024' || sizeResolution === '960') && visibleChart === 'second') {
      return true;
    }
    return false;
  }, [visibleChart, sizeResolution]);

  const isVisibleGaugeChart = useMemo(() => {
    if (sizeResolution === '1920' || (sizeResolution === '640' && visibleChart === 'second') || sizeResolution === '1440') {
      return true;
    }
    if ((sizeResolution === '1366' || sizeResolution === '1024' || sizeResolution === '960') && visibleChart === 'first') {
      return true;
    }
    return false;
  }, [visibleChart, sizeResolution]);

  const isVisibleTabel = useMemo(() => {
    if (
      sizeResolution === '1920' ||
      sizeResolution === '1366' ||
      sizeResolution === '1440' ||
      sizeResolution === '1024' ||
      sizeResolution === '960'
    ) {
      return true;
    }
    if (sizeResolution === '640' && visibleChart === 'first') {
      return true;
    }
    return false;
  }, [visibleChart, sizeResolution]);

  return (
    <>
      <div className={'linearProgress'}>
        <LinearDeterminate isActive={loadingTabel || loading} />
      </div>
      <div className={`${themeClass}`}>
        <div className={`${themeClass}_header`}>
          <PageHeader title={'Reporting'} isLoading={loadingTabel || loading} />
          <div className={`${themeClass}_header_container_filter`}>
            <>
              {isVisibleGaugeChart && (sizeResolution === '1366' || sizeResolution === '1024' || sizeResolution === '960')
                ? false
                : sizeResolution !== '640' && (
                    <div className={'selectGroupingChart'}>
                      <SelectGroupingChart selectGroup={selectGroup} handleGroup={handleGroup} />
                    </div>
                  )}
              <div className={`${themeClass}_header_container_filter_selectBtn`}>
                {isVisibleGaugeChart && (sizeResolution === '1366' || sizeResolution === '1024' || sizeResolution === '960')
                  ? false
                  : sizeResolution !== '640' && (
                      <div className={'selectTimePeriod'}>
                        <SelectTimePeriod
                          timePeriodName={timePeriodName}
                          selectData={selectData}
                          handleTimePeriod={handleTimePeriod}
                          selectGroup={selectGroup}
                          handleGroup={handleGroup}
                        />
                      </div>
                    )}
                {(sizeResolution === '1440' ||
                  sizeResolution === '1366' ||
                  sizeResolution === '1024' ||
                  sizeResolution === '960' ||
                  sizeResolution === '640') && (
                  <div className={`${themeClass}_container_btn`}>
                    <SystemButton
                      type={'chevron-double-left'}
                      size={'md'}
                      variant={'transparent'}
                      clickHandler={e => handleVisibleChart(true, sizeResolution)}
                    />
                    <SystemButton
                      type={'chevron-double-right'}
                      size={'md'}
                      variant={'transparent'}
                      clickHandler={e => handleVisibleChart(false, sizeResolution)}
                    />
                  </div>
                )}
              </div>
              {/*TO DO*/}
              {/*{false && (*/}
              {/*  <div className={`${themeClass}_overflowMenuButton`}>*/}
              {/*    <OverflowMenuButton size={'md'} tooltip={'Test'} />*/}
              {/*  </div>*/}
              {/*)}*/}
            </>
          </div>
        </div>
        {reportingData?.header_data && !isFirstLoading ? (
          <div className="reportCharts noSelectedCompany">
            {selectedCompanyId == 0 ? (
              <>
                <ReportingGaugeChart
                  isAllCompanies={!selectedCompanyId}
                  first_percent={first_percent}
                  second_percent={second_percent}
                  themeClass={themeClass}
                  project_title={project_title}
                  open_NFs={reportingData?.header_data?.open_nfs}
                  court_nfs={reportingData?.header_data?.court_nfs}
                  overdue_NFs={reportingData?.header_data?.overdue_nfs}
                  overdue_deliverables={reportingData?.header_data?.overdue_deliverables}
                  avg_reaction_time={reportingData?.header_data?.avg_court_time}
                  sizeResolution={sizeResolution}
                  isVisibleChart={isVisibleGaugeChart}
                />
              </>
            ) : (
              <ReportingProfile
                is_user={!!selectedUser}
                data={reportingData?.header_data?.details}
                loading={loading}
                breadcrumbs={breadcrumbs}
                breadcrumbsSelect={breadcrumbsSelect}
                selectedUser={selectedUser}
                selectedCompanyName={selectedCompanyName}
                handleSelectBreadcrumbs={handleSelectBreadcrumbs}
                sizeResolution={sizeResolution}
                isVisibleProfile={isVisibleGaugeChart}
              />
            )}
            {isVisibleComposedChart && (
              <div className={'containerSelectChart'}>
                {sizeResolution === '640' && (
                  <div className={'divider_chart'}>
                    <Divider direction={'horizontal'} type={'srf-5'} />
                  </div>
                )}
                {sizeResolution === '640' && (
                  <div className={'groupingChartContainer'}>
                    <div className={'selectGroupingChart'}>
                      <SelectGroupingChart selectGroup={selectGroup} handleGroup={handleGroup} sizeResolution={sizeResolution} />
                    </div>
                    <div className={'selectTimePeriod'}>
                      <SelectTimePeriod
                        timePeriodName={timePeriodName}
                        selectData={selectData}
                        handleTimePeriod={handleTimePeriod}
                        selectGroup={selectGroup}
                        handleGroup={handleGroup}
                      />
                    </div>
                  </div>
                )}
                <div className={'reportingComposedBlock'}>
                  <div className={'graph-wrapper'}>
                    <ReportingComposedChart
                      isAllCompanies={!selectedCompanyId}
                      timePeriodName={timePeriodName}
                      loading={loading}
                      header_data={reportingData?.header_data}
                      getBarChartList={getBarChartList}
                    />
                  </div>
                  <ReportingComposedLabels barChartList={barChartList} isHoverPie={isHoverPie} />
                </div>
              </div>
            )}
            {isVisiblePie && (
              <div className={'reportingPieChartBlock'}>
                <ReportingPieChart
                  deliverable_chart={reportingData?.header_data?.deliverable_chart?.items}
                  deliverable_chart_total={reportingData?.header_data?.deliverable_chart_total}
                  nf_chart={reportingData?.header_data?.nf_chart?.items}
                  nf_chart_total={reportingData?.header_data?.nf_chart_total}
                  selectGroup={selectGroup}
                  barChartList={barChartList}
                  handleIsHoverPie={handleIsHoverPie}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={`${themeClass}_skeletonRow`}>
            <CustomSkeleton width={570} height={300} />
            <div className={`${themeClass}_skeletonGroup`}>
              <CustomSkeleton width={620} height={300} />
            </div>
            <div className={`${themeClass}_skeletonRight`}>
              <CustomSkeleton width={165} height={165} sx={{ borderRadius: 100 }} />
              <CustomSkeleton width={165} height={165} sx={{ borderRadius: 100 }} />
            </div>
          </div>
        )}
        {isVisibleTabel && (
          <>
            {reportingData?.header_data && !loadingTabel ? (
              <div className={`reportingSandBoxHeader ${isShowNFs || selectedUser ? '-showNfs' : ''}`}>
                {selectedCompanyId > 0 && !selectedUser ? (
                  <div className={isShowNFs ? 'isSandBox' : ''}>
                    <SegmentedButton
                      selectedButtonId={!isShowNFs ? 'users' : 'sandbox'}
                      buttons={workingOptions}
                      changeHandler={handleShowNFs}
                    />
                  </div>
                ) : null}
                {isShowNFs || selectedUser ? (
                  <div className={'reportingSandBoxHeader_label'}>{selectedUser ? selectedUser : selectedCompanyName} command center</div>
                ) : null}
              </div>
            ) : null}
            {!isShowNFs && !selectedUser && (
              <div className={selectedCompanyId > 0 ? '-rep_TabelMultiCompany' : '-rep_TabelUsers'}>
                <ReportingGrid
                  data={selectedCompanyId === 0 ? reportingDataTabel?.table_data?.companies : reportingDataTabel?.table_data?.users}
                  data_min_max={
                    selectedCompanyId === 0
                      ? reportingDataTabel?.table_data?.companies_min_max
                      : reportingDataTabel?.table_data?.users_min_max
                  }
                  isAllCompanies={!selectedCompanyId}
                  needsListFilters={needsListFilters}
                  isLoading={isFirstTableLoading}
                />
              </div>
            )}
            {!reportingData?.header_data && isShowNFs && isFirstTableLoading && <SkeletonGrid />}
            {reportingData?.header_data && !loadingTabel && (isShowNFs || selectedUser) ? (
              <div className={'reportingShowNFs'}>
                <SandBox
                  openedFrom={'report'}
                  report_party_id={selectedCompanyId}
                  report_user_id={selectedUserId}
                  headerName={selectedUser ? '' : 'Reporting'}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(ReportingView);
