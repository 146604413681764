import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';

class SupportHome extends Component {
  render() {
    const { items, openItem, userData } = this.props;

    const { getting_started, quick_product_tour, glossary, user_manual, faq, what_new, get_help } = items;

    return (
      <>
        <div className={'b-helperDrawer__item'} onClick={() => openItem('getting_started')}>
          <div className={'b-helperDrawer__img'}>{getting_started.img}</div>
          <div className={'b-helperDrawer__itemTitle'}>
            <span className={'title'}>{getting_started.title}</span>
            <span className={'text'}>{getting_started.subTitle}</span>
          </div>
        </div>
        {userData.userInfo?.projects?.length ? (
          <div className={'b-helperDrawer__item'} onClick={() => this.props.handleOpenProductTour()}>
            <div className={'b-helperDrawer__img'}>{quick_product_tour.img}</div>
            <div className={'b-helperDrawer__itemTitle'}>
              <span className={'title'}>{quick_product_tour.title}</span>
              <span className={'text'}>{quick_product_tour.subTitle}</span>
            </div>
          </div>
        ) : null}
        {/*<div*/}
        {/*  className={'b-helperDrawer__item'}*/}
        {/*  onClick={() => openItem('glossary')}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className={'b-helperDrawer__img'}>*/}
        {/*    {glossary.img}*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={'b-helperDrawer__itemTitle'}>*/}
        {/*    <span*/}
        {/*      className={'title'}>{glossary.title}</span>*/}
        {/*    <span*/}
        {/*      className={'text'}>{glossary.subTitle}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  className={'b-helperDrawer__item'}*/}
        {/*  // onClick={() => openItem('getting_started')}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className={'b-helperDrawer__img'}>*/}
        {/*    {user_manual.img}*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={'b-helperDrawer__itemTitle'}>*/}
        {/*    <span*/}
        {/*      className={'title'}>{user_manual.title}</span>*/}
        {/*    <span*/}
        {/*      className={'text'}>{user_manual.subTitle}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={'b-helperDrawer__item'} onClick={() => openItem('faq')}>
          <div className={'b-helperDrawer__img'}>{faq.img}</div>
          <div className={'b-helperDrawer__itemTitle'}>
            <span className={'title'}>{faq.title}</span>
            <span className={'text'}>{faq.subTitle}</span>
          </div>
        </div>
        <div className={'b-helperDrawer__item'} onClick={() => openItem('what_new')}>
          <div className={'b-helperDrawer__img'}>
            {what_new.img}
            {userData?.userInfo?.whats_new_unread && userData?.userInfo?.projects?.length ? <span className={'counterNews'} /> : ''}
          </div>
          <div className={'b-helperDrawer__itemTitle'}>
            <span className={'title'}>{what_new.title}</span>
            <span className={'text'}>{what_new.subTitle}</span>
          </div>
        </div>
        <div className={'b-helperDrawer__item'} onClick={() => openItem('get_help')}>
          <div className={'b-helperDrawer__img'}>{get_help.img}</div>
          <div className={'b-helperDrawer__itemTitle'}>
            <span className={'title'}>{get_help.title}</span>
            <span className={'text'}>{get_help.subTitle}</span>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ supportReducer, userReducer }) => ({
  userData: userReducer,
});
export default connect(mapStateToProps, null)(SupportHome);
