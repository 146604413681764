import React, { FC, useEffect, useState } from 'react';
import { ActionButton } from '../../controls/ButtonComponents';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { Popover } from '@material-ui/core';
import { DropdownMenuItem } from '../../controls/Dropdowns/DropdownParts';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';

import './GeometryObjectsStyles.scss';

interface DataProps {
  markups: [
    {
      geometry_objects: string[];
      markup_annotation: string[];
      markup_objects: string[];
    },
  ];
}

interface GeometryProps {
  created_at: string;
  data: DataProps;
  id: number;
  model: {
    cloud_model_urn: string;
    cloud_project_id: string;
    created_at: string;
    hub_id: string;
    id: number | string;
    model_guid: string;
    project_guid: string;
    project_id: number | string;
    project_type: string;
    title: string;
    updated_at: string;
    urn: string;
    worksharing_central_guid: string;
    cloud_folder_id: string;
  };
  owner_id: number;
  owner_type: string;
  updated_at: string;
}

interface IProps {
  geometries: GeometryProps[];
}

const GeometryObjects: FC<IProps> = ({ geometries }) => {
  const themeClass = useGetThemeClass('geometry-objects');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const [geometriesQuantity, setGeometryQuantity] = useState<number>(0);
  const [isGeomentyAssigned, setIsGeometryAssigned] = useState<boolean>(false);
  const [isGeomentyCounted, setIsGeometryCounted] = useState<boolean>(false);
  const [isOpenPopover, setIsOpenPopover] = useState<EventTarget | null>(null);

  useEffect(() => {
    setGeometryQuantity(null);
    setIsGeometryCounted(false);
    setIsGeometryAssigned(false);
    let quantity = 0;
    if (!geometries?.length) return;
    if (geometries?.map(item => !!item.model).every(item => item === true)) setIsGeometryAssigned(true);
    geometries?.map(item => {
      if (!!Object.keys(item.data).find(elem => elem === 'markups')) {
        item.data.markups.map(element => (quantity += element.geometry_objects.length));
        setIsGeometryAssigned(true);
        setIsGeometryCounted(true);
        setGeometryQuantity(quantity);
        return;
      }
    });
  }, [geometries]);

  const linkHadler = idProp => {
    const selectedModel = geometries?.find(({ id }) => id === idProp);
    const { model } = selectedModel;
    if (model?.project_type === 'BIM360')
      return `https://docs.b360.autodesk.com/projects/${model.project_guid.slice(2)}/folders/${model.cloud_folder_id}/detail/viewer/items/${
        model.cloud_model_urn
      }`;
    if (model?.project_type === 'ACC')
      return `https://acc.autodesk.com/docs/files/projects/${model.project_guid.slice(2)}?folderUrn=${model.cloud_folder_id}&entityId=${
        model.cloud_model_urn
      }&viewModel=detail&moduleId=folders`;
    return null;
  };

  const popoverContent = () => {
    return (
      <div className={`${themeClass}__popover-quantity`}>
        {geometries
          ?.filter((obj, index) => geometries?.findIndex(item => item.model.id === obj.model.id) === index)
          .map(({ id, model }) => {
            return (
              <div key={model.updated_at}>
                <a
                  href={linkHadler(id)}
                  target="_blank"
                  rel="noreferrer"
                  className={linkHadler(id) ? `${themeClass}__model-title` : `${themeClass}__model-title-disabled`}
                >
                  <DropdownMenuItem
                    size={'md'}
                    title={model.title ? model.title : 'Untitled model'}
                    value={null}
                    itemId={id}
                    disabled={!linkHadler(id) ? true : false}
                    customClassName={linkHadler(id) ? '' : `${themeClass}__model-title-disabled`}
                    rightElem={
                      <TokenIcon iconName={'arrow-diagonal'} size={20} isClickable={false} customClass={`${themeClass}__link-icon`} />
                    }
                  />
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <div className={`${themeClass}_container`} onClick={isGeomentyAssigned ? e => setIsOpenPopover(e.currentTarget) : null}>
        <ActionButton
          type={isGeomentyAssigned ? 'geometry-objects' : 'no-geometry-objects'}
          disabled={!isGeomentyAssigned}
          showCounter={isGeomentyCounted}
          itemCounterColor={'nf'}
          itemCounterSize={'sm'}
          itemCounterValue={geometriesQuantity}
        />
      </div>
      <Popover
        open={!!isOpenPopover}
        onClose={() => setIsOpenPopover(null)}
        anchorEl={isOpenPopover as HTMLElement}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {popoverContent()}
      </Popover>
    </div>
  );
};

export default GeometryObjects;
