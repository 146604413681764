import { createSlice } from '@reduxjs/toolkit';
import {
  getRedmineAssignedMilestone,
  getRedmineMilestones,
  getRedmineProjects,
  createRedmineMilestone,
  removeRedmineAssignedMilestone,
  issuedRedmineMilestone,
} from './redmineThunk';
import { InitialRedmineState } from './redmineSlice.model';

const initialState: InitialRedmineState = {
  projects: [],
  selectedProject: null,
  milestones: [],
  selectedMilestone: null,
  loadingProjects: false,
  loadingMilestones: false,
  hasAssignedMilestone: false,
  loadingAssignedMilestone: false,
  loadingCreateMilestone: false,
  assignedMilestone: null,
  milestoneError: null,
  projectsLoaded: false,
};

export const redmineSlice = createSlice({
  name: 'redmineReducer',
  initialState,
  reducers: {
    projectsLoading(state) {
      state.loadingProjects = true;
    },
    milestonesLoading(state) {
      state.loadingMilestones = true;
    },
    selectRedmineProject(state, action) {
      state.selectedProject = action.payload;
    },
    selectRedmineMilestone(state, action) {
      state.selectedMilestone = action.payload;
    },
    removeAssignedMilestone(state) {
      state.assignedMilestone = null;
      state.hasAssignedMilestone = false;
    },
    clearMilestoneError(state) {
      state.milestoneError = null;
    },
  },
  extraReducers: builder => {
    builder
      //get redmine projects
      .addCase(getRedmineProjects.pending, state => {
        state.loadingProjects = true;
        state.projectsLoaded = false;
      })
      .addCase(getRedmineProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.loadingProjects = action.payload.length === 0 ? true : false;
        state.projectsLoaded = true;
      })
      .addCase(getRedmineProjects.rejected, state => {
        (state.projects = []), (state.loadingProjects = false);
        state.projectsLoaded = false;
      })
      //get milestones info
      .addCase(getRedmineMilestones.pending, state => {
        state.loadingMilestones = true;
      })
      .addCase(getRedmineMilestones.fulfilled, (state, action) => {
        state.milestones = action.payload;
        state.loadingMilestones = false;
      })
      .addCase(getRedmineMilestones.rejected, state => {
        state.milestones = [];
        state.loadingMilestones = false;
      })
      //get assigned milestone info
      .addCase(getRedmineAssignedMilestone.pending, state => {
        state.loadingAssignedMilestone = true;
      })
      .addCase(getRedmineAssignedMilestone.fulfilled, (state, action) => {
        state.assignedMilestone = action.payload;
        state.loadingAssignedMilestone = false;
        state.hasAssignedMilestone = true;
      })
      .addCase(getRedmineAssignedMilestone.rejected, state => {
        state.loadingAssignedMilestone = false;
      })
      //create and assign milestone
      .addCase(createRedmineMilestone.pending, state => {
        state.loadingCreateMilestone = true;
      })
      .addCase(createRedmineMilestone.fulfilled, (state, action) => {
        state.assignedMilestone = action.payload;
        state.loadingCreateMilestone = false;
        state.hasAssignedMilestone = true;
      })
      .addCase(createRedmineMilestone.rejected, (state, action) => {
        state.loadingCreateMilestone = false;
        state.milestoneError = action.payload;
      })
      //remove (close) redmine milestone
      .addCase(removeRedmineAssignedMilestone.pending, state => {
        state.loadingAssignedMilestone = true;
      })
      .addCase(removeRedmineAssignedMilestone.fulfilled, state => {
        state.loadingAssignedMilestone = false;
        state.assignedMilestone = null;
        state.hasAssignedMilestone = false;
      })
      .addCase(removeRedmineAssignedMilestone.rejected, state => {
        state.loadingAssignedMilestone = false;
      })
      //issued deliverable => change milestone status
      .addCase(issuedRedmineMilestone.pending, state => {
        state.loadingAssignedMilestone = true;
      })
      .addCase(issuedRedmineMilestone.fulfilled, state => {
        state.loadingAssignedMilestone = false;
      })
      .addCase(issuedRedmineMilestone.rejected, state => {
        state.loadingAssignedMilestone = false;
      });
  },
});

export default redmineSlice.reducer;
