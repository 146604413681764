import React from 'react';
import highlightedWords from './GlossaryHighlightedWords';

const requirements = (
  <>
    <p style={{ marginBottom: '7px' }}>Recommended system requirements:</p>
    <ul style={{ marginLeft: '30px' }}>
      <li>
        <p>Internet connection: 675 kbps or faster</p>
      </li>
      <li>
        <p>Screen resolution: 1920 x 1080 or larger</p>
      </li>
      <li>
        <p>Application window size: 1700 x 800 or larger</p>
      </li>
      <li>
        <p>Supported web browsers:</p>
        <div style={{ margin: '0 0 20px 15px' }}>
          <p style={{ marginLeft: '8px' }}>Google Chrome (latest publicly-released version)</p>
          <p style={{ marginLeft: '8px' }}>Safari (latest publicly-released version)</p>
          <p style={{ marginLeft: '8px' }}>Microsoft Edge (latest publicly-released version)</p>
          <p style={{ marginLeft: '8px' }}>Mozilla Firefox (latest publicly-released version)</p>
        </div>
      </li>
    </ul>
    <p style={{ marginBottom: '20px' }}>
      Using plug-ins or other third-party extensions in your browser can cause some unexpected problems. In such a case, please turn off the
      mentioned above and check if problems still occur. If so, send us a Support request describing the problems you encountered.
    </p>
    <p>Support for mobile browsers is coming soon.</p>
  </>
);

const faqContents = [
  {
    title: 'Permissions',
    id: 0,
    isClosed: false,
    headers: [
      {
        title: 'How to change your User Role?',
        text: 'If you have been assigned the Company User role, you cannot change it by yourself. Only team members who are Company Managers, Workflow Managers and Project Admins can do so. Contact directly any of them to request such a change.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'Technical',
    id: 1,
    isClosed: false,
    headers: [
      {
        title: 'What are Scheduler’s System Requirements?',
        text: requirements,
        id: 0,
        isClosed: true,
      },
      {
        title: 'How to scroll the views horizontally?',
        text: 'There are several options: tilting the mouse wheel, pressing the left mouse button/mouse wheel while moving the cursor and scrolling through bars on the bottom and right.',
        id: 1,
        isClosed: true,
      },
    ],
  },
  {
    title: 'Requests',
    id: 2,
    isClosed: false,
    headers: [
      {
        title: 'Who can create a Request?',
        text: 'Every user who has been invited to the project can create requests.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'Who can commit to a Request',
        text: (
          <span>
            To commit to a request, an NF must be assigned either to you or your company. Commitment can be provided by{' '}
            {highlightedWords[27].component} or another manager from the {highlightedWords[23].component}. Both Response Company and
            Response Manager are set by the author of the Request.
          </span>
        ),
        id: 1,
        isClosed: true,
      },
      {
        title: 'When Pull Requests are used?',
        text: 'Pull Requests are used when providing a Commitment to a certain NF needs some other requests to be answered beforehand. Creating a Pull Request means creating and linking a Predecessor to a current NF. Predecessors can be assigned to different Response parties than those in the original NF.',
        id: 2,
        isClosed: true,
      },
      {
        title: 'Can I create a Pull Request without providing a Commitment?',
        text: (
          <span>Yes, you can create a Pull Request from any NF, regardless of whether someone has already committed or replied to it.</span>
        ),
        id: 3,
        isClosed: true,
      },
      {
        title: 'Who can submit a Response?',
        text: (
          <span>
            To submit a Response, an NF must be assigned either to you or your company. Response can be completed by{' '}
            {highlightedWords[27].component} or another person from the {highlightedWords[23].component}. Both Response Company and Response
            Manager are set by the author of the Request.
          </span>
        ),
        id: 4,
        isClosed: true,
      },
      {
        title: 'Can I submit a Response without committing to a Request?',
        text: 'Yes, you can skip the step of providing a Commitment to the Request and submit a Response right away.',
        id: 5,
        isClosed: true,
      },
      {
        title: 'Who can accept or decline a Response?',
        text: (
          <span>
            Response can be either accepted or declined by {highlightedWords[26].component} or another manager from the{' '}
            {highlightedWords[22].component}.
          </span>
        ),
        id: 6,
        isClosed: true,
      },
      {
        title: 'What’s the difference between an NF and a Draft NF?',
        text: (
          <span>
            ‘Draft’ is one of the possible statuses of NF. It means that the NF that is being created isn’t finalized yet. Therefore it will
            not be visible to anyone outside of the {highlightedWords[22].component} until it is sent.
          </span>
        ),
        id: 7,
        isClosed: true,
      },
      {
        title: 'Who can change the NF Due Date?',
        text: (
          <span>
            NF Due Date can be edited by {highlightedWords[26].component} or another manager from the {highlightedWords[22].component}.
            Furthermore, {highlightedWords[23].component} managers and {highlightedWords[27].component} can request a modification of the NF
            Due Date. However, such a demand needs to be accepted by the requesting entity.
          </span>
        ),
        id: 8,
        isClosed: true,
      },
      {
        title: 'Who can change the Response Company?',
        text: (
          <span>
            {highlightedWords[23].component} can be edited by {highlightedWords[26].component} or another manager from the{' '}
            {highlightedWords[22].component}. Furthermore, {highlightedWords[23].component} managers and {highlightedWords[27].component}{' '}
            can request a modification of the Response Company. However, such a demand needs to be accepted by the requesting entity.
          </span>
        ),
        id: 9,
        isClosed: true,
      },
      {
        title: 'Who can link NFs?',
        text: <span>NFs can be linked by any user of any company.</span>,
        id: 10,
        isClosed: true,
      },
    ],
  },
  {
    title: 'Teams',
    id: 3,
    isClosed: false,
    headers: [
      {
        title: 'How to create a company in a project?',
        text: 'Open the Team module from the application top bar. Click on the Add company button in the top-right corner of the window. First, check if the company has already been created, using the search bar. If not, click on the Create new button in the top-right corner of the popup window. You see another popup window displayed. Provide the company name and click Create in the bottom-right corner of the popup. The company has been created and automatically linked to the project you are currently logged to.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'How to add a company to a project?',
        text: 'Open the Team module from the application top bar. Click on the Add company button in the top-right corner of the window. Type the company name in the search bar and press enter to find the company. Now, click the Add button next to the company name. The company has been successfully added to the project. The displayed popup window allows you to provide project-specific company information and choose the team that will work with you.',
        id: 1,
        isClosed: true,
      },
      {
        title: 'Who can create a company?',
        text: 'Company creation is permitted only for team members with the following roles assigned: Workflow Manager and Project Admin.',
        id: 1,
        isClosed: true,
      },
    ],
  },
  {
    title: 'Project settings',
    id: 4,
    isClosed: false,
    headers: [
      {
        title: 'How to edit the building details (levels, building areas, etc.)?',
        text: (
          <>
            <p>Building details can be modified in the Projects module.</p>
            <p>In order to do that Project Admin Role needs to be assigned to the user.</p>
          </>
        ),
        id: 0,
        isClosed: true,
      },
    ],
  },
];

export default faqContents;
