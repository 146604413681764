import React, { FC } from 'react';
import Popover from '@material-ui/core/Popover';
import { useGetThemeClass } from '../../../helpers/designTokens';
import PlaceholderInfo from '../PlaceholderInfo/PlaceholderInfo';
import Divider from '../Divider/Divider';
import { CustomButton } from '../ButtonComponents';
import TokenIcon from '../TokenIcon/TokenIcon';
import { useAppDispatch } from '../../../store/configure/configureStore';
import { logout } from '../../../store/user/userThunk';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';

import './UserPopupStyles.scss';
import useRouter from '../../../hooks/useRouter/useRouter';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  userInfo: any;
  iconAvatar: any;
  userRoles: any[];
  handleChangeTheme: (any) => void;
  theme: boolean;
}

const UserPopup: FC<IProps> = ({ anchorEl, onClose, isOpen, userInfo, userRoles, iconAvatar, handleChangeTheme, theme }) => {
  const dispatch = useAppDispatch();
  const { navigate } = useRouter();
  const themeClass = useGetThemeClass('b-userPopup');
  const { isMobile } = GetResolution();

  const onLogout = () => {
    dispatch(
      logout({
        callback: () => {
          navigate('/');
        },
      }),
    );
  };

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: `${themeClass}_paper` }}
    >
      <div className={themeClass}>
        <div className={`${themeClass}_item`}>
          <PlaceholderInfo
            type={'Persona'}
            imagePath={iconAvatar}
            firstName={userInfo.first_name}
            lastName={userInfo.last_name}
            detailInfo={`${userInfo.first_name} ${userInfo.last_name}`}
            subInfo={userInfo?.projects?.length ? userRoles : 'Not assigned'}
            tooltip={'User profile'}
            size={32}
            isMobile={isMobile}
          />
        </div>
        <Divider direction={'horizontal'} type={'srf-4'} />
        <div className={`${themeClass}_item`}>
          <span className={`${themeClass}_item_text`}>Appearance</span>
          <CustomButton
            type={'text-plain'}
            size={'md'}
            title={theme ? 'Dark' : 'Light'}
            customClassName={`${themeClass}_item_theme`}
            iconClass={`${themeClass}_item_theme_icon`}
            clickHandler={() => handleChangeTheme({ target: { checked: !theme } })}
            isMobile={isMobile}
          />
        </div>
        <Divider direction={'horizontal'} type={'srf-4'} />
        <div className={`${themeClass}_item`}>
          <div className={`${themeClass}_item_logout`} onClick={onLogout}>
            <TokenIcon iconName={'logout'} size={20} />
            <span className={`${themeClass}_item_text`}>Logout</span>
          </div>
        </div>
      </div>
    </Popover>
  );
};
export default UserPopup;
