import { FullRequestModel } from '../../store/request/requestReducer.model';
import { breadcrumbsSlice } from '../../store/breadcrumbs/breadcrumbsReducer';

const { setBreadcrumbs } = breadcrumbsSlice.actions;

export const handleSetBreadcrumbs = (
  isRelatedItemsTab: boolean,
  currentCard: 'Nf' | 'Deliverable' | 'Submittal',
  prevCard,
  nextCard,
  dispatch,
  typeBreadcrumbs: 'firstCardBreadcrumbs' | 'secondCardBreadcrumbs' | 'previewCardBreadcrumbs',
  activeTab?: string,
) => {
  if (!prevCard) {
    return;
  }

  let fromCardObject = null;
  let toCardObject = null;

  if (currentCard === 'Nf') {
    fromCardObject = {
      id: prevCard?.id,
      title: `NF ${prevCard?.nf}`,
      breadcrumbType: 'nf',
      nf: prevCard?.nf,
      activeTab,
    };
  }

  if (currentCard === 'Deliverable') {
    fromCardObject = {
      id: prevCard?.id,
      title: `${prevCard?.title}`,
      breadcrumbType: 'pcd',
      buildingId: prevCard?.building_id,
      activeTab,
    };
  }

  if (currentCard === 'Submittal') {
    fromCardObject = {
      id: prevCard?.id,
      title: `${prevCard?.sf_index}`,
      breadcrumbType: 'submittal',
      activeTab,
    };
  }

  if (!isRelatedItemsTab) {
    if (nextCard.toCard === 'Nf') {
      toCardObject = { id: nextCard?.id, title: `NF ${nextCard?.nf}`, nf: nextCard?.nf, breadcrumbType: 'nf' };
    }
    if (nextCard.toCard === 'Deliverable') {
      toCardObject = {
        id: nextCard?.id,
        title: `${nextCard?.title}`,
        buildingId: nextCard?.buildingId,
        breadcrumbType: 'pcd',
      };
    }
    if (nextCard.toCard === 'Submittal') {
      toCardObject = { id: nextCard?.id, title: `${nextCard?.sf_index}`, toCardType: 'submittal', breadcrumbType: 'submittal' };
    }
  } else {
    if (nextCard?.snippet === 'request') {
      toCardObject = { id: nextCard?.id, title: `NF ${nextCard?.nf}`, nf: nextCard?.nf, breadcrumbType: 'nf' };
    }

    if (nextCard?.snippet === 'deliverable') {
      if (currentCard === 'Deliverable') {
        toCardObject = {
          id: nextCard?.flags.last_id,
          title: `${nextCard?.flags?.last_title || nextCard?.title}`,
          buildingId: nextCard?.building.id,
          breadcrumbType: 'pcd',
        };
      } else {
        toCardObject = {
          id: nextCard?.id,
          title: `${nextCard?.title}`,
          buildingId: nextCard?.building.id,
          breadcrumbType: 'pcd',
        };
      }
    }

    if (nextCard?.snippet === 'submittal') {
      toCardObject = { id: nextCard?.id, title: `${nextCard?.sf_index}`, toCardType: 'submittal', breadcrumbType: 'submittal' };
    }
  }
  dispatch(setBreadcrumbs({ typeBreadcrumbs, array: [{ ...fromCardObject }, { ...toCardObject }] }));
};

export const handleOpenCardHelper = (
  isRelatedItemsTab: boolean,
  currentCard: 'Nf' | 'Deliverable' | 'Submittal',
  prevCard,
  nextCard: FullRequestModel,
  dispatch,
  routerHelper,
  typeBreadcrumbs: 'firstCardBreadcrumbs' | 'secondCardBreadcrumbs' | 'previewCardBreadcrumbs',
) => {
  if (isRelatedItemsTab) {
    if (nextCard?.snippet === 'request') {
      if (typeBreadcrumbs === 'previewCardBreadcrumbs') {
        routerHelper.setUrlPreviewNF(String(nextCard?.nf), String(nextCard?.id));
      } else {
        routerHelper.setUrlNF(String(nextCard?.nf));
      }
    }
    if (nextCard?.snippet === 'deliverable') {
      if (currentCard === 'Deliverable') {
        routerHelper.setUrlPCD(String(nextCard?.flags.last_id), String(nextCard?.building?.id));
      } else {
        routerHelper.setUrlPCD(String(nextCard?.id), String(nextCard?.building?.id));
      }
    }
    if (nextCard?.snippet === 'submittal') {
      routerHelper.setUrlSubmittal(String(nextCard?.id));
    }
    handleSetBreadcrumbs(isRelatedItemsTab, currentCard, prevCard, nextCard, dispatch, typeBreadcrumbs, 'related-items');
  }
};
