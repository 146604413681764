import React, { PureComponent } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class SinglePagePDFViewer extends PureComponent {
  render() {
    return (
      <>
        <Document file={this.props.pdf}>
          <Page pageIndex={0} renderAnnotationLayer={false} renderTextLayer={false} />
        </Document>
      </>
    );
  }
}

export default SinglePagePDFViewer;
