import { NeedListStoreConfig } from './Constants';
import {
  AddItemModel,
  ClearDataModel,
  CreateStoreModel,
  DeleteByIdModel,
  GetAllModel,
  GetByIdModel,
  GetObjectStoreModel,
  HandleProjectChangeModel,
  InitDbModel,
  UpdateByIdModel,
} from './DataBaseTypes';
import { indexedDbSlice } from '../../store/indexedDb/indexedDb';

const { setField } = indexedDbSlice.actions;

class DatabaseProvider {
  db: IDBDatabase | null;
  dbName: string;
  dbVersion: number;
  stores: { [keys: string]: string };
  request: IDBOpenDBRequest | null;
  debugMode: boolean;

  constructor() {
    this.db = null;
    this.dbName = 'PlanitDB';
    this.dbVersion = 1;
    this.stores = { NEEDLIST: 'need-list' };
    this.request = null;
    this.debugMode = false;
  }

  initDb = (data: InitDbModel) => {
    const { projects, dispatch } = data;
    return new Promise<void>(resolve => {
      this.request = window.indexedDB.open(this.dbName, this.dbVersion);

      this.request.onupgradeneeded = () => {
        this.db = this.request.result;
        dispatch && dispatch(setField({ field: 'thisDb', value: this.request.result }));
        projects.forEach(project => {
          this.createStore({ storeName: 'NEEDLIST', config: NeedListStoreConfig, projectId: project.id });
        });
      };

      this.request.onsuccess = event => {
        this.db = event.target.result;
        dispatch && dispatch(setField({ field: 'thisDb', value: event.target.result }));
        dispatch && dispatch(setField({ field: 'isDbCreated', value: true }));
        resolve(event.target.result);
      };

      this.request.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        console.error({ error });
      };
    });
  };

  handleProjectChange = async (data: HandleProjectChangeModel) => {
    const { newProjects, dispatch } = data;

    if (!this.db) {
      return;
    }

    // Close the existing DB
    await this.db.close();
    this.db = null;

    // Delete the existing DB
    const deleteRequest = await window.indexedDB.deleteDatabase(this.dbName);

    deleteRequest.onsuccess = async () => {
      // Open a new DB with the same name and version
      const openRequest = window.indexedDB.open(this.dbName, this.dbVersion);

      openRequest.onupgradeneeded = async event => {
        const db = event.target.result;

        // Create object stores for the current projects
        newProjects.forEach(project => {
          const objectStoreName = `${this.stores['NEEDLIST']}-${project.id}`;
          db.createObjectStore(objectStoreName, NeedListStoreConfig);
        });
      };

      openRequest.onsuccess = event => {
        this.db = event.target.result;
        dispatch && dispatch(setField({ field: 'thisDb', value: event.target.result }));
      };

      openRequest.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        this.debugMode && console.error('There was a handleProjectChange error', { error });
      };
    };

    deleteRequest.onerror = error => {
      dispatch && dispatch(setField({ field: 'error', value: error }));
      this.debugMode && console.error('There was a deleteRequest error', { error });
    };
  };

  handleLogout = async () => {
    if (this.db) {
      await this.db.close();
      const deleteRequest = await window.indexedDB.deleteDatabase(this.dbName);
      deleteRequest.onsuccess = () => {
        this.debugMode && console.log('Database deleted successfully.');
      };
      deleteRequest.onerror = error => {
        this.debugMode && console.error('Error deleting database:', error);
      };
    }
  };

  createStore = (data: CreateStoreModel) => {
    const { storeName, config, projectId } = data;

    this.db.createObjectStore(`${this.stores[storeName]}-${projectId}`, config);
  };

  getObjectStore = (data: GetObjectStoreModel) => {
    const { storeName, projectId, dispatch } = data;

    const objectStoreName = `${this.stores[storeName]}-${projectId}`;

    const transaction = this.db.transaction([objectStoreName], 'readwrite');

    transaction.oncomplete = () => {
      this.debugMode && console.log('Transaction oncomplete');
    };
    transaction.onerror = error => {
      dispatch && dispatch(setField({ field: 'error', value: error }));
      this.debugMode && console.error('There was a transaction error', { error });
    };

    return transaction.objectStore(`${this.stores[storeName]}-${projectId}`);
  };

  addItem = (data: AddItemModel) => {
    const { item, storeName, projectId, dispatch } = data;

    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const addItem = objectStore.put(item);
      addItem.onsuccess = async () => {
        this.debugMode && console.log('Transaction oncomplete');
      };
      addItem.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        this.debugMode && reject(error);
      };
    });
  };

  getAll = (data: GetAllModel) => {
    const { storeName, projectId, dispatch } = data;
    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const items = objectStore.getAll();

      items.onsuccess = () => (items.result ? resolve(items.result) : reject('getAll ERROR!!!'));
      items.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        reject(error);
      };
    });
  };

  clearData = (data: ClearDataModel) => {
    const { storeName, projectId, dispatch } = data;

    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const clearing = objectStore.clear();

      clearing.onsuccess = () => {
        this.debugMode && console.log('Clearing oncomplete');
      };
      clearing.onerror = error => this.debugMode && reject(error);
    });
  };

  updateById = (data: UpdateByIdModel) => {
    const { newValue, key, storeName, projectId, dispatch } = data;

    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const item = objectStore.get(key);

      item.onsuccess = () => {
        const updateRequest = objectStore.put(newValue);

        updateRequest.onsuccess = () => {
          this.debugMode && console.log(`Item updated ${updateRequest.result}`);
        };
      };

      item.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        reject(error);
      };
    });
  };

  deleteById = (data: DeleteByIdModel) => {
    const { itemId, storeName, projectId, dispatch } = data;

    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const deletingItem = objectStore.delete(itemId);
      deletingItem.onsuccess = () => {
        this.debugMode && console.log(`Item deleted ${deletingItem.result}`);
      };
      deletingItem.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        reject(error);
      };
    });
  };

  getById = (data: GetByIdModel) => {
    const { itemId, storeName, projectId, dispatch } = data;
    return new Promise((resolve, reject) => {
      const objectStore = this.getObjectStore({ storeName, projectId, dispatch });
      const item = objectStore.get(itemId);
      item.onsuccess = () => (item.result ? resolve(item.result) : reject('Not found'));
      item.onerror = error => {
        dispatch && dispatch(setField({ field: 'error', value: error }));
        reject(error);
      };
    });
  };
}

export const DatabaseService = new DatabaseProvider();
