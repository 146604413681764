import React, { useMemo, RefObject, MutableRefObject, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/configure/configureStore';
import SVG from 'react-inlinesvg';
import { KeyValueModel } from '../../../../../../../models/key-value.model';
import { SandBoxNfSnippetModel, SandBoxAccordionData } from '../../../../../../../store/request/requestReducer.model';
import { SubmittalModel } from '../../../../../../../models/submittals/submittals.model';
import { UserModel } from '../../../../../../../models';
import Snippet from '../../../../../../controls/Snippet/Snippet';
import ContainedList from '../../../../../../controls/ContainedList/ContainedList';
import cn from 'classnames';
import { getSandBoxIDsHotList } from '../../../../../../../store/request/requestLogic(HOLD)';

interface IProps {
  isShortView: boolean;
  filterHeight: number;
  openRequest: (id: number, nf: number, accordionAlias: string, isMarked: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  openSubmittal: (id: string, accordionAlias?: string, isMarked?: boolean) => void;
  accordionStates: KeyValueModel<boolean> | null;
  accordionRefs: KeyValueModel<RefObject<HTMLElement>>;
  handleAccordion: (alias: string) => void;
  handleDeactivationPopUp: (
    snippetData: SandBoxNfSnippetModel | SubmittalModel,
    action: 'activate' | 'deactivate',
    accordion: string,
  ) => void;
  handleDeleteDraftPopUp: (nf: SandBoxNfSnippetModel, accordion) => void;
  openedFrom: string;
  report_user_id: number;
  userInfo: UserModel;
  sizeResolution: string;
  is1100Resolution: boolean;
  isTablet: boolean;
  isRightBtn: boolean;
  sizeResolutionSnippet: string;
  isMobile?: boolean;
  pageHeaderRef: MutableRefObject<HTMLDivElement>;
  relatedTo: string;
}

const RequestsListTable = ({
  isShortView,
  filterHeight,
  openRequest,
  accordionStates,
  accordionRefs,
  handleAccordion,
  openDeliverable,
  openSubmittal,
  handleDeactivationPopUp,
  handleDeleteDraftPopUp,
  openedFrom,
  report_user_id,
  userInfo,
  sizeResolution,
  is1100Resolution,
  isTablet,
  isRightBtn,
  sizeResolutionSnippet,
  isMobile,
  pageHeaderRef,
  relatedTo,
}: IProps) => {
  const dispatch = useAppDispatch();
  const sandBoxData = useAppSelector(state => state.requestReducer.sandBox.accordion_list);
  const sandBoxIDsHotList = useAppSelector(state => state.requestReducer.sandBoxIDsHotList);
  const theme = useAppSelector(state => state.userReducer.userInfo.theme);
  const sendBoxDataLoading = useAppSelector(state => state.requestReducer.sendBoxDataLoading);

  const [sandBoxAccordion, setSandBoxAccordion] = useState([]);

  const tableRef = useRef();

  useEffect(() => {
    if (sandBoxData?.length) {
      const arrayIds = [];
      sandBoxData.forEach(accordionItem => {
        accordionItem.data.forEach(item => {
          arrayIds.push(item.id);
        });
      });
      const getSectionParendType = (sectionId: string) => {
        if (sectionId && sectionId.includes('request')) {
          return 'request';
        }

        if (sectionId && sectionId.includes('deliverable')) {
          return 'deliverable';
        }

        if (sectionId && sectionId.includes('submittalInput')) {
          return 'submittalInput';
        }

        if (sectionId && sectionId.includes('submittalOutput')) {
          return 'submittalOutput';
        }
      };
      const activeTabUrl = new URLSearchParams(location.search).get(`activeTab`);
      const activeTabUrlType = getSectionParendType(activeTabUrl);
      const uniqArrayIds = Array.from(new Set(arrayIds));
      dispatch(getSandBoxIDsHotList({ related_to: relatedTo, entity: activeTabUrlType, check_ids: uniqArrayIds }));
    } else {
      setSandBoxAccordion([]);
    }
  }, [sandBoxData]);

  useEffect(() => {
    if (sandBoxIDsHotList?.length) {
      const newSandBoxData = sandBoxData.map(accordionItem => {
        const newData = accordionItem.data.map(item => {
          const obj = { ...item };
          const isIDsHotList = sandBoxIDsHotList.find(value => value === item.id);
          if (isIDsHotList) {
            obj.no_change_hot_list = true;
          }

          return obj;
        });
        const newAccordion = { ...accordionItem, data: newData };
        return newAccordion;
      });
      setSandBoxAccordion(newSandBoxData);
    } else {
      setSandBoxAccordion(sandBoxData);
    }
  }, [sandBoxIDsHotList]);

  const filterReportHeight = useMemo(() => {
    return openedFrom !== 'report'
      ? isMobile
        ? `calc(100svh - ${pageHeaderRef?.current?.getBoundingClientRect()?.height}px - 40px - 48px)`
        : `calc(100svh - ${sizeResolution === '640' ? '322px' : '276px'})`
      : report_user_id
      ? `${sizeResolution === '640' ? 'calc(100svh - 477px)' : 'calc(100svh - 685px)'}`
      : `${sizeResolution === '640' ? 'calc(100svh - 532px)' : 'calc(100svh - 692px)'}`;
  }, [filterHeight, openedFrom, report_user_id, sizeResolution, pageHeaderRef.current]);

  return (
    <div
      className={cn('b-sandBox-requestsList__table', {
        ['-isSkeletons']: sandBoxAccordion?.length && sendBoxDataLoading?.loading,
      })}
      ref={tableRef}
      style={{
        height: filterReportHeight,
        overflowY: sendBoxDataLoading.loading ? 'hidden' : 'auto',
      }}
    >
      <div className={'b-sandBox-requestsList__table_table_item'}>
        <ContainedList<SandBoxAccordionData>
          data={sandBoxAccordion}
          accordionStates={accordionStates}
          accordionRefs={accordionRefs}
          handleAccordion={handleAccordion}
          isLoading={sendBoxDataLoading?.loading}
          isMobile={isMobile}
          isShortView={isShortView}
          maxCountSkeleton={25}
          tableRef={tableRef}
          renderItem={(item, alias) => {
            return (
              <Snippet
                accordionAlias={alias}
                key={item.id}
                openRequest={snippetData => openRequest(snippetData.id, snippetData.nf, alias, !!snippetData.marker)}
                openDeliverable={snippetData => openDeliverable(snippetData.id, snippetData.building_id, alias, !!snippetData.marker)}
                openSubmittal={snippetData => openSubmittal(snippetData.id, alias, !!snippetData.marker)}
                isShortView={isShortView}
                snippetData={item}
                handleDeactivationPopUp={handleDeactivationPopUp}
                handleDeleteDraftPopUp={handleDeleteDraftPopUp}
                openedFrom={openedFrom}
                userInfo={userInfo}
                is1100Resolution={is1100Resolution}
                is1920full={sizeResolution === '1440' && !isRightBtn}
                isTablet={
                  isShortView
                    ? false
                    : sizeResolutionSnippet === '1920'
                    ? false
                    : sizeResolution === '1440' && !isRightBtn
                    ? false
                    : isTablet || isMobile
                }
                sizeContainer={
                  sizeResolutionSnippet
                    ? sizeResolutionSnippet === '1920'
                      ? ''
                      : sizeResolutionSnippet
                    : sizeResolution === '1440' && isRightBtn
                    ? '960'
                    : sizeResolution === '960'
                    ? '960'
                    : sizeResolution === '640'
                    ? '960'
                    : sizeResolution === 'mobile'
                    ? 'mobile'
                    : sizeResolution === '1024'
                    ? '1024'
                    : ''
                }
              />
            );
          }}
        />
      </div>

      {sandBoxAccordion && sendBoxDataLoading.loaded && !sandBoxAccordion?.length ? (
        <div
          className={`${openedFrom !== 'report' ? 'b-sandBox-requestsList__table_empty' : 'b-sandBox-requestsList__table_empty -report'}`}
        >
          <div className={'b-sandBox-requestsList__table_empty_placeholderImage'}>
            {theme === 'dark' ? (
              <SVG src={require('../../../../../../../assets/images/sandBox/snippet-placeholder-dark.svg')} />
            ) : (
              <SVG src={require('../../../../../../../assets/images/sandBox/snippet-placeholder-light.svg')} />
            )}
          </div>
          <div className={'b-sandBox-requestsList__table_empty_textContainer'}>
            <div className={'b-sandBox-requestsList__table_empty_textContainer_header'}>There are no items in this tab</div>
            <div className={'b-sandBox-requestsList__table_empty_textContainer_text'}>
              Items will appear once they meet the criteria defined for this view.
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RequestsListTable;
