import React, { Component } from 'react';

class Updates_20211125_1 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4f21cde1fe55004e062911616fdba2f9/images/image5.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'}>
          It will contain descriptions of both basic and advanced functionalities. Glossary, Frequently Asked Questions and a Quick Product
          Tour will simplify the onboarding of new users. If you have broad experience of using our software, we believe it still will be
          worth visiting, for example, to explore our recent updates.
        </div>
        <div className={'paragraph'}>
          Last but not least, there will be an updated Share Feedback form, where you will be able to conveniently reach the User Success
          Team in case you have encountered a bug, want to ask us for assistance, or suggest anything. Of course, files could also be
          attached.
        </div>
        <div className={'paragraph'}>We are looking forward to hearing from you!</div>
      </>
    );
  }
}

export default Updates_20211125_1;
