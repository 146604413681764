import mixpanel, { Dict } from 'mixpanel-browser';

const ENVIRONMENT = process.env.NODE_ENV === 'production';

export default class MixpanelHelper {
  public static init = () => {
    if (ENVIRONMENT) mixpanel.init('85f16979307a70c8939bbf093af17f10', { track_pageview: true, ignore_dnt: true });
  };

  public static identify = (id: string) => {
    if (ENVIRONMENT) mixpanel.identify(id);
  };

  public static track = (name: string, props?: Dict) => {
    if (ENVIRONMENT) mixpanel.track(name, props);
  };

  public static people = {
    set: (props: Dict) => {
      if (ENVIRONMENT) mixpanel.people.set(props);
    },
  };
}
