import React, { FC, useEffect, useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import CheckBox from '../CheckBox/CheckBox';
import StatusIncorporation from '../Status/StatusIncorporation/StatusIncorporation';
import DateField from '../DateField/DateField';
import HistoryAction from '../HistoryAction/HistoryAction';
import { CustomButton, SystemButton } from '../ButtonComponents';
import cn from 'classnames';
import CustomCalendar from '../CustomCalendar/CustomCalendar';
import { Select } from '../Dropdowns/DropdownСomponents';
import Popover from '@mui/material/Popover';
import { IncorporationSubTaskModel, IncorporationTaskModel } from '../../../store/incorporation/incorporationReducer.model';
import moment from 'moment/moment';
import { DropdownItemModel, DropdownGroupItem } from '../../../models/global';
import { UserModel } from '../../../models';
import AccessHelper from '../../../helpers/AccessHelper';
import CustomTooltip from '../Tooltip/Tooltip';
import { isMobile } from 'react-device-detect';

import './IncorporateFloorSnipperStyles.scss';

const detectedMobileDevice = isMobile;

interface IProps {
  key: number;
  parentTask: IncorporationTaskModel;
  data: IncorporationSubTaskModel;
  isEdit: boolean;
  isDragRequestCard: boolean;
  isSizeResolution640?: boolean;
  isSidePeek?: boolean;
  isTablet?: boolean;
  sizeResolution?: string;
  checkedSubTask: boolean;
  incorporationListHaveScroll: boolean;
  handleCheckSubTask: (parentTaskId: number, subTaskId: number) => void;
  handleChangeSubTaskStatus: (parentTaskId: number, subTaskId: number, type: 'incorporated' | 'discarded') => void;
  menuItems: DropdownItemModel[];
  groups: DropdownGroupItem[];
  work_week?: number;
  handleChangeLevel: (parentTaskId: number, subTaskId: number, item: number | number[]) => void;
  handleChangeDueDate: (parentTaskId: number, subTaskId: number, date: string) => void;
  handleDeleteItem: (type: 'task' | 'subTask', id: number, parentTaskId: number) => void;
  errors: any;
  userInfo: UserModel;
  isAccess: boolean;
  isDeclinedNfCard: boolean;
  isMobile: boolean;
}

const statusTitle = {
  pending: 'Pending',
  discarded: 'Discarded',
  incorporated: 'Incorporated',
};

const IncorporationFloorSnippet: FC<IProps> = ({
  key,
  parentTask,
  data,
  isEdit,
  isDragRequestCard,
  isSizeResolution640,
  isSidePeek,
  checkedSubTask,
  isTablet,
  sizeResolution,
  incorporationListHaveScroll,
  handleCheckSubTask,
  handleChangeSubTaskStatus,
  menuItems,
  groups,
  work_week = 7,
  handleChangeLevel,
  handleChangeDueDate,
  handleDeleteItem,
  errors,
  userInfo,
  isAccess,
  isDeclinedNfCard,
  isMobile = false,
}) => {
  const themeClass = useGetThemeClass('b-incorporationFloorSnippet');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');
  const offset = new Date().getTimezoneOffset();

  const [isOpenCalendar, setIsOpenCalendar] = useState<EventTarget | null>(null);
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    if (isEdit && !!parentTask?.selectedParty?.company?.id && !currentUserId) {
      setCurrentUserId(parentTask?.selectedParty?.company?.id);
      return;
    }
    if (!isEdit) {
      setCurrentUserId(null);
    }
  }, [isEdit]);

  const selectAtDateHandler = (selectedAtDate: string | Date) => {
    handleChangeDueDate(parentTask.id, data.id, moment(selectedAtDate).format('YYYY-MM-DD'));
    setIsOpenCalendar(null);
  };

  const isAccessToEditIncorporation = AccessHelper.isAccessToEditIncorporation(userInfo, isEdit ? currentUserId : parentTask?.party_id);

  return (
    <div key={key}>
      <div
        className={cn(themeClass, {
          ['-sidePeek']: isDragRequestCard || isTablet || isSidePeek,
          ['-edit']: isEdit,
          ['-sidePeek640']: isSizeResolution640 || sizeResolution === '640',
          ['-scroll']: incorporationListHaveScroll,
        })}
      >
        <div className={`${themeClass}__left`}>
          <div className={`${themeClass}__block`}>
            {!isEdit && (
              <div className={`${themeClass}__checkBoxBlock`}>
                {data?.status === 'pending' && (
                  <div className={cn(`${themeClass}__checkbox`, { ['-checked']: checkedSubTask, ['-mobile']: detectedMobileDevice })}>
                    <CheckBox
                      checked={checkedSubTask}
                      onClick={() => handleCheckSubTask(parentTask.id, data.id)}
                      disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
                      isMobile={isMobile}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={`${themeClass}__status`}>
              <StatusIncorporation status={data?.status} isDragRequestCard={isDragRequestCard} tooltip={statusTitle[data?.status]} />
            </div>
            <div className={`${themeClass}__title`}>
              {isEdit ? (
                <div className={`${themeClass}__title__container`}>
                  <Select<DropdownItemModel, DropdownGroupItem, false>
                    isMulti={false}
                    menuItems={menuItems || []}
                    groups={groups || []}
                    menuGroupingType={'labeled'}
                    selectHandler={item => handleChangeLevel(parentTask.id, data.id, item)}
                    selected={data?.selectedLevel}
                    basisPlaceholder={'Select levels'}
                    menuItemSize={'md'}
                    basisMaxItems={8}
                    noSort
                    error={!data?.selectedLevel?.id && !!errors?.includes('location_id')}
                    disabled={!(isAccessToEditIncorporation || isAccess) || data?.status !== 'pending'}
                  />
                  {data?.building_title ? <div className={`${themeClass}__building`}>({data?.building_title})</div> : ''}
                </div>
              ) : (
                <CustomTooltip
                  title={`${data?.level_title} (${data?.building_title})`}
                  placement={'top'}
                  customBasisClass={`${themeClass}__levelTooltip`}
                >
                  <div className={`${themeClass}__floor`}>
                    <div className={`${themeClass}__levelTitle`}>{data?.level_title}</div>
                    {data?.building_title ? <div className={`${themeClass}__building`}>({data?.building_title})</div> : ''}
                  </div>
                </CustomTooltip>
              )}
            </div>
          </div>
          <div className={`${themeClass}__center`}>
            <div className={`${themeClass}__date`}>
              <div className={`${themeClass}__label`}>Due date</div>
              <DateField
                selectedDate={data?.due_date ? moment(data.due_date).format('MM/DD/YYYY') : ''}
                isSentRequestToChange={false}
                isOpenCalendar={isOpenCalendar && isEdit}
                clickHandler={e => isEdit && setIsOpenCalendar(e.currentTarget)}
                isDiffDueScheduledDate={false}
                info={false}
                disabled={!isEdit || !(isAccessToEditIncorporation || isAccess) || data?.status !== 'pending'}
                isBefore={false}
                error={false}
                valueIsDate={!!data?.due_date}
                isDisableBorder={!isEdit}
              />
              {!data?.due_date && data?.isNew && (
                <SystemButton
                  tooltip={'The due date will be calculated automatically after saving'}
                  type={'information'}
                  size={'md'}
                  variant={'transparent'}
                />
              )}
            </div>
            <div className={`${themeClass}__info`}>
              {data?.completed_at && data?.author && (
                <>
                  <span className={`${themeClass}__label`}>{data?.status === 'incorporated' ? 'Incorporated by' : 'Discarded by'}</span>
                  <HistoryAction
                    type={''}
                    date={new Date(moment(data.completed_at).add('minutes', -offset))}
                    firstName={data?.author}
                    lastName={''}
                    userImagePath={data?.author_image}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {/*{isMobile && (*/}
        {/*  <div className={`${themeClass}__block`}>*/}
        {/*    {!isEdit && (*/}
        {/*      <div className={`${themeClass}__checkBoxBlock`}>*/}
        {/*        {data?.status === 'pending' && (*/}
        {/*          <div className={cn(`${themeClass}__checkbox`, { ['-checked']: checkedSubTask })}>*/}
        {/*            <CheckBox*/}
        {/*              checked={checkedSubTask}*/}
        {/*              onClick={() => handleCheckSubTask(parentTask.id, data.id)}*/}
        {/*              disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}*/}
        {/*              isMobile={isMobile}*/}
        {/*            />*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*    <div className={`${themeClass}__status`}>*/}
        {/*      <StatusIncorporation status={data?.status} isDragRequestCard={isDragRequestCard} tooltip={statusTitle[data?.status]} />*/}
        {/*    </div>*/}
        {/*    <div className={`${themeClass}__title`}>*/}
        {/*      {isEdit ? (*/}
        {/*        <div className={`${themeClass}__title__container`}>*/}
        {/*          <Select<DropdownItemModel, DropdownGroupItem, false>*/}
        {/*            isMulti={false}*/}
        {/*            menuItems={menuItems || []}*/}
        {/*            groups={groups || []}*/}
        {/*            menuGroupingType={'labeled'}*/}
        {/*            selectHandler={item => handleChangeLevel(parentTask.id, data.id, item)}*/}
        {/*            selected={data?.selectedLevel}*/}
        {/*            basisPlaceholder={'Select levels'}*/}
        {/*            menuItemSize={'md'}*/}
        {/*            basisMaxItems={8}*/}
        {/*            error={!data?.selectedLevel?.id && !!errors?.includes('location_id')}*/}
        {/*            disabled={!(isAccessToEditIncorporation || isAccess) || data?.status !== 'pending'}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      ) : (*/}
        {/*        <div className={`${themeClass}__floor`}>*/}
        {/*          <div className={`${themeClass}__levelTitle`}>{data?.level_title}</div>*/}
        {/*          {data?.building_title ? <div className={`${themeClass}__building`}>({data?.building_title})</div> : ''}*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{isMobile && (*/}
        {/*  <div className={`${themeClass}__center`}>*/}
        {/*    <div className={`${themeClass}__date`}>*/}
        {/*      <div className={`${themeClass}__label`}>Due date</div>*/}
        {/*      <DateField*/}
        {/*        selectedDate={data?.due_date ? moment(data.due_date).format('MM/DD/YYYY') : ''}*/}
        {/*        isSentRequestToChange={false}*/}
        {/*        isOpenCalendar={isOpenCalendar && isEdit}*/}
        {/*        clickHandler={e => isEdit && setIsOpenCalendar(e.currentTarget)}*/}
        {/*        isDiffDueScheduledDate={false}*/}
        {/*        info={false}*/}
        {/*        disabled={!isEdit || !(isAccessToEditIncorporation || isAccess) || data?.status !== 'pending'}*/}
        {/*        isBefore={false}*/}
        {/*        error={false}*/}
        {/*        valueIsDate={!!data?.due_date}*/}
        {/*        isDisableBorder={!isEdit}*/}
        {/*      />*/}
        {/*      {!data?.due_date && data?.isNew && (*/}
        {/*        <SystemButton*/}
        {/*          tooltip={'The due date will be calculated automatically after saving'}*/}
        {/*          type={'information'}*/}
        {/*          size={'md'}*/}
        {/*          variant={'transparent'}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*    <div className={`${themeClass}__info`}>*/}
        {/*      {data?.completed_at && data?.author && (*/}
        {/*        <>*/}
        {/*          <span className={`${themeClass}__label`}>{data?.status === 'incorporated' ? 'Incorporated by' : 'Discarded by'}</span>*/}
        {/*          <HistoryAction*/}
        {/*            type={''}*/}
        {/*            date={new Date(moment(data.completed_at).add('minutes', -offset))}*/}
        {/*            firstName={data?.author}*/}
        {/*            lastName={''}*/}
        {/*          />*/}
        {/*        </>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {isEdit ? (
          <div className={`${themeClass}__buttons`}>
            <SystemButton
              type={'delete'}
              size={'md'}
              variant={'transparent'}
              clickHandler={() => handleDeleteItem('subTask', data.id, parentTask.id)}
              disabled={!(isAccessToEditIncorporation || isAccess)}
            />
          </div>
        ) : (
          <div className={`${themeClass}__buttons`}>
            {data?.status !== 'pending' ? (
              <div className={`${themeClass}__buttonInfo`}>
                <SystemButton
                  tooltip={`${data?.status === 'incorporated' ? 'Incorporated by' : 'Discarded by'}: ${data?.author}`}
                  type={'information'}
                  size={'md'}
                  variant={'transparent'}
                />
              </div>
            ) : (
              <>
                <div className={`${themeClass}__buttonsSide`}>
                  <SystemButton
                    type={'dash-square'}
                    size={'md'}
                    variant={'transparent'}
                    clickHandler={() => handleChangeSubTaskStatus(parentTask.id, data.id, 'discarded')}
                    disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
                  />
                  <SystemButton
                    type={'plus-square'}
                    size={'md'}
                    variant={'transparent'}
                    clickHandler={() => handleChangeSubTaskStatus(parentTask.id, data.id, 'incorporated')}
                    disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
                  />
                </div>
                <div className={`${themeClass}__buttonsDesktop`}>
                  <CustomButton
                    type={'text-plain'}
                    size={'md'}
                    title={'Discard'}
                    clickHandler={() => handleChangeSubTaskStatus(parentTask.id, data.id, 'discarded')}
                    disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
                  />
                  <CustomButton
                    type={'text-plain'}
                    size={'md'}
                    title={'Incorporate'}
                    clickHandler={() => handleChangeSubTaskStatus(parentTask.id, data.id, 'incorporated')}
                    disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Popover
        open={!!isOpenCalendar}
        onClose={() => setIsOpenCalendar(null)}
        anchorEl={isOpenCalendar as HTMLElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <CustomCalendar handleChange={date => selectAtDateHandler(date)} value={data?.due_date} work_week={work_week} />
      </Popover>
    </div>
  );
};
export default IncorporationFloorSnippet;
