import { BaseApi } from './baseApi';
import { setNotificationSettings } from '../../store/user/userActions';

class UserApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getLogin(email, password) {
    const bodyParams = { email, password };
    return await this._post({ route: 'login', bodyParams, devMode: this.devMode });
  }

  async getLogout() {
    return await this._get({ route: 'logout', needAuth: true, devMode: this.devMode });
  }

  async inviteUser(token, password) {
    const bodyParams = { token, password };
    return await this._post({ route: 'user-invite', bodyParams, devMode: this.devMode });
  }

  async checkInviteLinkUser(code) {
    return await this._get({ route: `check-invite/${code}`, devMode: this.devMode });
  }

  async getUserAllInfo(userId) {
    return await this._get({ route: `user-short/${userId}`, needAuth: true, devMode: this.devMode });
  }
  async getUserAllInfoWithoutId() {
    return await this._get({ route: `user-short`, needAuth: true, devMode: this.devMode });
  }

  async forgotPassword(email) {
    const bodyParams = { email };
    return await this._post({ route: `password/recovery`, bodyParams, devMode: this.devMode });
  }

  async resetPassword(password, token) {
    const bodyParams = { password, token };
    return await this._post({ route: `password/restore`, bodyParams, devMode: this.devMode });
  }

  async watcherAdd(type, bodyParams) {
    return this._post({ route: `${type || 'request'}/watcher-add`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async userSetBanner(bodyParams) {
    return this._post({ route: `user-set-banner`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async setUserSeenReleaseNotes(bodyParams) {
    return this._post({ route: `user-set-seen-release-notes`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }
  async setUserSeenReleaseSandboxTour(bodyParams) {
    return this._post({ route: `user-set-seen-sandbox-tour`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async getClientFromProject(bodyParams) {
    return await this._post({
      route: `client/get-client-from-project/${bodyParams.code}`,
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getClientsProjects(clientId) {
    return await this._get({
      route: `client/get-clients-project/${clientId}`,
      bodyParams: {},
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async createClient(bodyParams) {
    return await this._post({
      route: `client/create`,
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async updateClient(bodyParams, clientId) {
    return await this._update({
      route: `client/update/${clientId}`,
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getPromptsAlerts(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };
    return await this._post({ route: 'user/prompts-alerts-get', bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async setPromptsAlerts(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };
    return await this._post({ route: 'user/prompts-alerts-set', bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async unsubscribeReleaseUser(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };
    return await this._post({ route: 'unsubscribe-release', bodyParams: bodyParams, needAuth: false, devMode: this.devMode });
  }

  async setNotificationSettings(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };
    return await this._post({
      route: 'user/project-options-update',
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getUserProfile(projectId, user_id) {
    return await this._get({
      route: `/project/${projectId}/user/${user_id}`,
      bodyParams: {},
      needAuth: true,
      devMode: this.devMode,
    });
  }
}
export default new UserApi({ devMode: false });
