import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import { DropdownGroupItem, DropdownItemModel } from '../../../models/global';
import { PCDApi } from '../../../service/Api/pcd/types';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { ChipsDiscipline } from '../Chips';
import PlaceholderInfo from '../PlaceholderInfo/PlaceholderInfo';
import { Select } from '../Dropdowns/DropdownСomponents';
import TokenIcon from '../TokenIcon/TokenIcon';

import './PartyRenderSelectStyles.scss';

interface RadioGroupItem {
  value: string | number;
  title: string;
}

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface PartyRenderSelectProps<T, G, IsMultiValue extends boolean> {
  type: 'discipline' | 'company' | 'user';
  isMulti: IsMultiValue;
  isValuesRemovable?: boolean;
  isHeader?: boolean;
  isFooter?: boolean;
  customSelectClass?: string;
  menuItems: T[];
  groups?: G[];
  selectHandler: (selected: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  customBasisContainerClass?: string;
  customBasisFieldClass?: string;
  basisPlaceholder: string;
  renderBasisSelectedItem?: (selected: T) => ReactElement | ReactNode;
  renderCustomBasis?: (isActive: boolean, disabled: boolean) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  basisTooltipClassName?: string;
  onChangeSearch?: (search: string) => void;
  customHeaderClass?: string;
  headerAdditionalText?: string;
  footerType?: 'divided-button' | 'radio-group';
  customFooterClass?: string;
  footerButtonClick?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  footerButtonTitle?: string | ReactElement;
  footerRadioGroup?: RadioGroupItem[];
  footerRadioClickHandler?: (value: string | number) => void;
  footerSelectedRadioValue?: string | number;
  menuItemSize: 'sm' | 'md';
  checkMenuItemDisabled?: (item: T) => boolean;
  disabled?: boolean;
  menuItemTitleRenderer?: (item: T) => string | ReactNode;
  menuItemTooltipRenderer?: (item: T) => string | ReactNode;
  menuItemLeftElemRenderer?: (item: T) => ReactNode;
  menuItemLeftElemClick?: (item: T) => void;
  menuItemRightElemRenderer?: (item: T) => ReactNode;
  menuItemRightElemClick?: (item: T) => void;
  menuItemRightTextRenderer?: (item: T) => ReactNode;
  customMenuItemClass?: string;
  customMenuClass?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onMenuClose?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  isOpenMenu?: boolean;
  customOpening?: boolean;
  handleBuildingItemClick?: (building: PCDApi.PCDListBuildingInterface) => void;
  handleDrawingItemClick?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  handleDeliverablesItemClick?: (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => void;
  openedBuildingIds?: number[];
  openedDrawingIds?: number[];
  handleOpenLinkPopover: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface[]) => void;
  isFullWidth?: boolean;
  handleSelectedDrawingDelete?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  getBuildingsTitle?: (building_ids: number[]) => string;
  handleOpenDDManageStatuses?: () => void;
  handleOpenDDItem?: (item: DropdownItemModel) => void;
  handleRemoveDDItem?: (item: DropdownItemModel) => void;
  isAccess?: boolean;
  isAccessToRemove?: boolean;
  disableSelectAll?: boolean;
  handleDrawingCheckBoxClick?: (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  isMobile: boolean;
  isHighResolution?: boolean;
  onClickOutside?: () => void;
}

function PartyRenderSelect<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  type,
  isMulti,
  isHeader,
  isFooter,
  disableSelectAll,
  menuItems,
  groups,
  selectHandler,
  selected,
  isLoading,
  customBasisFieldClass,
  customBasisContainerClass,
  basisPlaceholder,
  basisMaxItems,
  error,
  basisTooltip,
  basisTooltipClassName,
  onChangeSearch,
  headerAdditionalText,
  footerType,
  footerButtonClick,
  footerButtonTitle,
  menuItemSize,
  disabled,
  menuItemLeftElemRenderer,
  menuItemRightTextRenderer,
  customMenuItemClass,
  customMenuClass,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isOpenMenu,
  customOpening,
  isFullWidth,
  handleOpenDDManageStatuses,
  isMobile,
  onClickOutside,
  isHighResolution,
}: PartyRenderSelectProps<T, G, IsMultiValue>) {
  const textClass = useGetThemeClass('b-renderPartySelectText');
  const dot_icon = <TokenIcon iconName={'dot'} size={12} />;

  const renderSelectItem = (item: DropdownItemModel) => {
    if (type === 'discipline') {
      return (
        <>
          <ChipsDiscipline type={'filled'} color={item?.text_color} value={item?.title} />
          <div className={textClass}>{`${item?.parties.length} company`}</div>
        </>
      );
    }

    if (type === 'company') {
      return (
        <>
          <PlaceholderInfo
            type={'Company'}
            detailInfo={item?.title || item?.company}
            imagePath={item?.renderings ? item?.renderings?.file || item?.renderings[0]?.file : item.image || null}
            subInfo={
              <>
                {item?.disciplines[0]?.title && <div>{item?.disciplines[0]?.title}</div>}
                {item?.disciplines[0]?.title && !!item?.roles?.length && dot_icon}
                <div>{item?.roles[0]?.title}</div>
              </>
            }
            size={24}
          />
        </>
      );
    }

    if (type === 'user') {
      return (
        <div className={`${textClass}_userRow`}>
          <PlaceholderInfo
            type={'Persona'}
            detailInfo={item?.title || `${item?.profile?.first_name} ${item?.profile?.last_name}`}
            firstName={item?.profile?.first_name || item?.first_name}
            lastName={item?.profile?.last_name || item?.last_name}
            imagePath={item?.profile?.image || item.image || null}
            size={24}
          />
          {!item?.is_active && <span className={textClass}>Inactive</span>}
        </div>
      );
    }

    return item.title;
  };

  const renderSelectBasisItem = (item: DropdownItemModel) => {
    if (type === 'discipline') {
      return (
        <>
          <ChipsDiscipline type={'filled'} color={item?.text_color} value={item?.title} />
        </>
      );
    }

    if (type === 'company') {
      return (
        <>
          <PlaceholderInfo
            type={'Company'}
            detailInfo={item?.title || item?.company}
            imagePath={item?.renderings ? item?.renderings?.file || item?.renderings[0]?.file : item.image || null}
            size={24}
          />
        </>
      );
    }

    if (type === 'user') {
      return (
        <>
          <PlaceholderInfo
            type={'Persona'}
            detailInfo={item?.title || `${item?.profile?.first_name} ${item?.profile?.last_name}`}
            firstName={item?.profile?.first_name || item?.first_name}
            lastName={item?.profile?.last_name || item?.last_name}
            imagePath={item?.profile?.image || item.image || null}
            size={24}
          />
        </>
      );
    }

    return item.title;
  };

  return (
    <>
      <Select<T, G, IsMultiValue>
        isMulti={isMulti}
        isHeader={isHeader}
        isFooter={isFooter}
        disableSelectAll={disableSelectAll}
        menuItems={menuItems}
        groups={groups}
        menuGroupingType={menuGroupingType}
        selectHandler={selectHandler}
        selected={selected}
        headerAdditionalText={headerAdditionalText}
        isLoading={isLoading}
        basisPlaceholder={basisPlaceholder}
        renderBasisSelectedItem={renderSelectBasisItem}
        footerButtonClick={footerButtonClick}
        footerButtonTitle={footerButtonTitle}
        footerType={footerType}
        menuItemSize={menuItemSize}
        menuItemTitleRenderer={renderSelectItem}
        customBasisContainerClass={customBasisContainerClass}
        customBasisFieldClass={customBasisFieldClass}
        error={error}
        onChangeSearch={onChangeSearch}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isOpenMenu={isOpenMenu}
        disabled={disabled}
        customOpening={customOpening}
        isFullWidth={isFullWidth}
        menuItemLeftElemRenderer={menuItemLeftElemRenderer}
        menuItemRightTextRenderer={menuItemRightTextRenderer}
        customMenuItemClass={customMenuItemClass}
        customMenuClass={customMenuClass}
        handleOpenDDManageStatuses={handleOpenDDManageStatuses}
        basisMaxItems={basisMaxItems}
        basisTooltip={basisTooltip}
        basisTooltipClassName={basisTooltipClassName}
        isMobile={isMobile}
        onClickOutside={onClickOutside}
        isHighResolution={isHighResolution}
      />
    </>
  );
}

export default PartyRenderSelect;
