import React, { useMemo } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { RequestUserModel } from '../../../models';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import DateRow from '../../controls/DateRow/DateRow';

import './PopupCreatedByStyles.module.scss';

interface IProps {
  user: RequestUserModel;
  create_date: string;
  refCreatedBy: React.MutableRefObject<HTMLDivElement>;
  forShowSmall?: boolean;
}

export const PopupCreatedBy = ({ user, create_date, refCreatedBy, forShowSmall }: IProps) => {
  const themeClass = useGetThemeClass('b-popupCreatedBy');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-popupCreatedByPaper');

  const customPosition = useMemo(() => {
    const coordinates = refCreatedBy?.current?.getBoundingClientRect();
    return {
      top: coordinates?.y + 27,
      left: coordinates?.x,
    };
  }, [refCreatedBy]);

  return (
    <div className={`${themeClass} ${themeClassCallout} ${themeClassPaper}`} style={customPosition}>
      <div className={`${themeClass}_createBy`}>
        <div className={`${themeClass}_createBy_label_text`}>Created by</div>
        <PlaceholderInfo
          type={'Persona'}
          firstName={user?.profile?.first_name}
          lastName={user?.profile?.last_name}
          imagePath={user?.profile?.image}
          detailInfo={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
          size={24}
        />
      </div>
      <div className={`${themeClass}_dateInfo`}>
        <div className={`${themeClass}_dateInfo_date_by`}></div>
        <DateRow date={create_date} showTime />
      </div>
    </div>
  );
};
