import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';

import './StatusNFsnippetStyles.module.scss';

const StatusNFsnippet: FC<{
  type: 'new' | 'upd';
  size: 'sm' | 'lg';
  isOnlyText?: boolean;
}> = ({ type, size, isOnlyText }) => {
  const themeClass = useGetThemeClass('b-status_NF_snippet');

  return (
    <>
      {(type === 'new' || type === 'upd') && (
        <div
          className={cn(`${themeClass}`, {
            [`${size}`]: !isOnlyText,
            [`${type}`]: !isOnlyText,
            [`-isOnlyText_${type}`]: isOnlyText,
          })}
        >
          {size === 'lg' && <div className={'label_text'}>{type.toLocaleUpperCase()}</div>}
        </div>
      )}
    </>
  );
};

export default React.memo(StatusNFsnippet);
