import React, { useMemo, useState, useEffect, ReactNode, ChangeEvent } from 'react';
import DropdownPopoverWatchlist from './parts/DropdownPopoverWatchlist/DropdownPopoverWatchlist';
import DropdownPopoverWatchlistGroups from './parts/DropdownPopoverWatchlistGroups/DropdownPopoverWatchlistGroups';
import { DropdownMenuItem } from '../../controls/Dropdowns/DropdownParts';
import ActionButton from '../../controls/ButtonComponents/ActionButton/ActionButton';
import { IWithConfirmDialogProps } from '../HighOrderComponents';
import { WatcherModel, RequestUserModel, WatcherGroupModel } from '../../../models';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import {
  createWatchersGroups,
  deleteWatchersGroups,
  getWatchersGroups,
  updateWatchersGroups,
  addToWatchersGroups,
  searchWatchersRequest,
} from '../../../store/request/requestLogic(HOLD)';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { useGetThemeClass } from '../../../helpers/designTokens';
import CheckBox from '../../controls/CheckBox/CheckBox';
import { watcherAdd } from '../../../store/user/userThunk';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import Divider from '../../controls/Divider/Divider';
import { IConfirmDialogState, withConfirmDialogContext } from '../HighOrderComponents/ConfirmDialog';
import cn from 'classnames';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { changePrivate } from '../../../store/common/commonThunk';
import { IRequestWatchersGroup } from '../../../store/request/requestReducer.model';
import WatchlistGroupPreview from '../WatchlistGroupPreview/WatchlistGroupPreview';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';
import Timeout = NodeJS.Timeout;

import './WatchlistStyles.module.scss';

interface IProps extends IWithConfirmDialogProps {
  type: 'request' | 'deliverable' | 'submittal';
  watchers: WatcherModel[];
  requestId: number;
  project_id: number;
  disabled?: boolean;
  pcdParentId?: number;
  handleShowConfirmDialog?: (options: IConfirmDialogState) => void;
  isPrivate?: boolean;
  isMenuCustomOpenAnchor?: Event;
  onCloseWatchlist?: () => void;
  linkedGroups?: WatcherGroupModel[];
}

let timer: Timeout;

const Watchlist = ({
  type,
  watchers,
  requestId,
  project_id,
  disabled,
  pcdParentId,
  handleShowConfirmDialog,
  isPrivate,
  isMenuCustomOpenAnchor,
  onCloseWatchlist,
  linkedGroups,
}: IProps) => {
  const dispatch = useAppDispatch();
  const findWatchers = useAppSelector(state => state.requestReducer.findWatchers);
  const watchersGroups = useAppSelector(state => state.requestReducer.watchersGroups);
  const userInfo = useAppSelector(state => state.userReducer.userInfo);

  const { isMobile } = GetResolution();
  const { isManagerAccess } = usePermissions();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [searchValueAddToGroup, setSearchValueAddToGroup] = useState<string>('');
  const [watcherSelected, setWatcherSelected] = useState<WatcherModel[]>([]);
  const [watcherGroupsSelected, setWatcherGroupsSelected] = useState<IRequestWatchersGroup[]>([]);
  const [isEditGroup, setIsEditGroup] = useState<boolean>(false);
  const [addGroupOpen, setAddGroupOpen] = useState<boolean>(false);
  const [isMenuOpenGroup, setIsMenuOpenGroup] = useState<boolean>(false);
  const [selectedUsersToGroup, setSelectedUsersToGroup] = useState<number[]>(null);
  const [editGroupItemId, setEditGroupItemId] = useState<number>(null);
  const [changeWatching, setChangeWatching] = useState<boolean>(false);
  const [anchorElsearch, setAnchorElsearch] = useState<HTMLDivElement>(null);

  // Group preview
  const [anchorElPreview, setAnchorElPreview] = useState<HTMLDivElement>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewGroupId, setPreviewGroupId] = useState<number | null>(null);
  const [isEditFromPreview, setIsEditFromPreview] = useState<boolean>(false);

  // User popup
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [isOpenAuthorUser, setIsOpenAuthorUser] = useState<boolean>(false);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [coordinatesAuthor, setCoordinatesAuthor] = useState(null);

  const themeClass = useGetThemeClass('b-watchlist');

  const inWatchlist: boolean = useMemo(() => {
    return watchers?.length > 0 || !!linkedGroups?.length;
  }, [watchers, linkedGroups]);

  const watchlistCount: number = useMemo(() => {
    const allUsers = [
      ...watchers,
      ...watchersGroups.filter(group => linkedGroups?.some(g => g.id === group.id))?.flatMap(group => group.users),
    ];

    const uniqueUsersSet = new Set(allUsers.map(user => user.user_id || user.id));

    const uniqueUsers = Array.from(uniqueUsersSet).map(userId => allUsers.find(user => user.id === userId));

    return uniqueUsers.length;
  }, [watchers, watchersGroups, linkedGroups]);

  const isWatchersChanged: boolean = useMemo(() => {
    return (
      watchers?.some(watcher => !watcherSelected.some(w => w.user_id === watcher.user_id)) ||
      watcherSelected?.some(watcher => !watchers.some(w => w.user_id === watcher.user_id))
    );
  }, [watchers, watcherSelected]);

  const isGroupsChanged: boolean = useMemo(() => {
    return (
      linkedGroups?.some(group => !watcherGroupsSelected.some(g => g.id === group.id)) ||
      watcherGroupsSelected?.some(group => !linkedGroups.some(g => g.id === group.id))
    );
  }, [linkedGroups, watcherGroupsSelected]);

  useEffect(() => {
    if (linkedGroups) {
      setWatcherGroupsSelected(watchersGroups.filter(group => linkedGroups.some(g => g.id === group.id)));
    }
  }, [linkedGroups, watchersGroups]);

  useEffect(() => {
    if (watchers) {
      setWatcherSelected(watchers);
    }
  }, [watchers]);

  useEffect(() => {
    if (isMenuCustomOpenAnchor) {
      openMenu(isMenuCustomOpenAnchor);
    }
  }, [isMenuCustomOpenAnchor]);

  const handleAddSelf = () => {
    setChangeWatching(true);

    const onAllow = isValid => {
      const sendData = {
        owner_id: type === 'deliverable' ? pcdParentId || requestId : requestId,
        user_ids: [...watchers.map(w => w.user_id), userInfo.id],
        type,
      };

      dispatch(watcherAdd(sendData));
      if ((type === 'request' || type === 'submittal') && isPrivate && !isValid) {
        dispatch(changePrivate({ owner_type: type, owner_id: requestId, is_private: false }));
      }
    };

    const onCancel = () => {
      setChangeWatching(true);
    };

    if (type === 'request' || type === 'submittal') {
      handleConfirmAddWatcher([userInfo.id], onAllow, onCancel);
    } else {
      onAllow(true);
    }
  };

  const handleRemoveSelf = () => {
    const sendData = {
      owner_id: type === 'deliverable' ? pcdParentId || requestId : requestId,
      user_ids: [...watchers.map(w => w.user_id).filter(watcherId => watcherId !== userInfo.id)],
      type,
    };

    dispatch(watcherAdd(sendData));
    setChangeWatching(true);
  };

  const handleGroupAddToWatchers = (selected: IRequestWatchersGroup[]) => {
    const onAllow = isValid => {
      const sendData = {
        group_ids: selected.map(g => g.id),
        owner_id: type === 'deliverable' ? pcdParentId || requestId : requestId,
        project_id,
        type,
      };

      dispatch(addToWatchersGroups(sendData));

      if ((type === 'request' || type === 'submittal') && isPrivate && !isValid) {
        dispatch(changePrivate({ owner_type: type, owner_id: requestId, is_private: false }));
      }
    };

    const onCancel = () => {
      return;
    };

    if (type === 'request' || type === 'submittal') {
      handleConfirmAddWatcher(
        selected.flatMap(group => [...group.users]).map(user => user.id),
        onAllow,
        onCancel,
      );
    } else {
      onAllow(true);
    }
  };

  const handleSelectItem = (selected: RequestUserModel[]) => {
    setWatcherSelected(selected.map(item => ({ id: 0, user: item, user_id: item.id })));
  };

  const handleSelectGroup = (selected: IRequestWatchersGroup[]) => {
    setWatcherGroupsSelected(selected);
  };

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
    setAnchorElPreview(null);
    onCloseWatchlist && onCloseWatchlist();
  };

  const onSelectClose = (type: 'watcher' | 'group') => {
    if (type === 'group') {
      if (isGroupsChanged) {
        handleGroupAddToWatchers(watcherGroupsSelected);
      }
    }

    if (type === 'watcher') {
      if (isWatchersChanged) {
        addRequestWatcher(watcherSelected);
      }
    }
  };

  const closeMenuGroup = () => {
    setIsMenuOpenGroup(false);
    setSearchValueAddToGroup('');
  };

  useEffect(() => {
    if (project_id) {
      dispatch(getWatchersGroups(String(project_id)));
      dispatch(searchWatchersRequest({ search: '', limit: 100, project_id: project_id }));
    }
  }, [project_id]);

  useEffect(() => {
    if (searchValueAddToGroup.length > 0) {
      dispatch(searchWatchersRequest({ search: searchValueAddToGroup }));
    } else {
      if (project_id) {
        dispatch(searchWatchersRequest({ search: '', limit: 100, project_id: project_id }));
      }
    }
  }, [searchValueAddToGroup]);

  const handleChangeGroupName = value => {
    setGroupName(value);
  };

  const handleChangeSearchAddToGroup = (value: string) => {
    setSearchValueAddToGroup(value);
  };

  const handleConfirmAddWatcher = (userIds, onAllow, onCancel) => {
    if (isPrivate) {
      handleShowConfirmDialog({
        title: `Request private request change?`,
        message: `${type === 'request' ? 'NF' : 'Submittal'} will cease to be private and will be available to others.`,
        userIds,
        onAllow,
        onCancel,
        needUpdateState: true,
      });
    } else {
      onAllow(true);
    }
  };

  const handleCreateGroup = (isEdit: boolean) => {
    if (isEdit) {
      setIsEditGroup(true);
      setAddGroupOpen(false);
    } else {
      setSelectedUsersToGroup([]);
      setAddGroupOpen(true);
      setIsEditGroup(false);
      setGroupName('');
    }
    setIsMenuOpenGroup(true);
  };

  const renderItemFooter = () => {
    return (
      <>
        <Divider direction={'horizontal'} type={'srf-4'} />
        <div
          className={cn(`${themeClass}_conatainerBtn`, {
            ['-mobile']: isMobile,
          })}
        >
          <CustomButton
            icon={<TokenIcon iconName={isWatch ? 'trash' : 'plus'} size={isMobile ? 20 : 12} />}
            type={'text-plain'}
            size={'xs'}
            clickHandler={isWatch ? handleRemoveSelf : handleAddSelf}
            title={isWatch ? 'Remove from my watchlist' : 'Add to my watchlist'}
            iconClass={`${themeClass}_text-plain`}
            isMobile={isMobile}
          />
        </div>
      </>
    );
  };

  const isWatch = useMemo(() => {
    return watchers.find(w => w.user_id === userInfo.id);
  }, [watchers]);

  const renderItemFooterGroups = () => {
    return (
      <>
        <Divider direction={'horizontal'} type={'srf-4'} />
        <div className={`${themeClass}_conatainerBtnGroup`}>
          <CustomButton type={'secondary'} size={'sm'} clickHandler={closeMenuGroup} title={'Cancel'} />
          <CustomButton type={'primary'} size={'sm'} clickHandler={handleSaveGroup} title={'Save'} />
        </div>
      </>
    );
  };

  const addRequestWatcher = (selected: WatcherModel[]) => {
    const userIds = selected.map(item => item.user_id);

    setSearchValue('');

    const onAllow = isValid => {
      const sendData = {
        owner_id: type === 'deliverable' ? pcdParentId || requestId : requestId,
        user_ids: userIds,
        type,
      };

      dispatch(watcherAdd(sendData));

      if ((type === 'request' || type === 'submittal') && isPrivate && !isValid) {
        dispatch(changePrivate({ owner_type: type, owner_id: requestId, is_private: false }));
      }
    };

    if (type === 'request' || type === 'submittal') {
      handleConfirmAddWatcher(userIds, onAllow, null);
    } else {
      onAllow(true);
    }
  };

  const handleEditGroup = (e, item, isPreview: boolean) => {
    const newSelectedUsersToGroup = item.users.map(el => el.id);
    e.stopPropagation();
    setIsEditGroup(true);
    setAddGroupOpen(true);
    setEditGroupItemId(item.id);
    setGroupName(item.title);
    setSelectedUsersToGroup(newSelectedUsersToGroup);
    handleCreateGroup(true);
    setIsEditFromPreview(isPreview);
  };

  const handleDeleteGroup = (e, id) => {
    e.stopPropagation();
    dispatch(deleteWatchersGroups(id));
  };

  const handleUnlinkGroup = (id: number) => {
    handleGroupAddToWatchers(watcherGroupsSelected.filter(group => group.id !== id));
  };

  const handleUnlinkWatcher = (id: number) => {
    addRequestWatcher(watcherSelected.filter(watcher => watcher.user_id !== id));
    setSearchValue('');
  };

  const handleChangeMultiSelect = item => {
    const selectedItems = selectedUsersToGroup ? [...selectedUsersToGroup] : [];
    const findIndex = selectedItems?.findIndex(value => value === item);
    if (findIndex > -1) {
      selectedItems.splice(findIndex, 1);
    } else {
      selectedItems.push(item);
    }
    setSelectedUsersToGroup(selectedItems);
  };

  const handlePreviewGroup = (e, groupId: number) => {
    setIsPreviewOpen(true);
    setAnchorElPreview(e.currentTarget);
    setPreviewGroupId(groupId);
  };

  const handleClosePreviewGroup = (isEdit: boolean) => {
    !isEdit && setAnchorElPreview(null);
    setPreviewGroupId(null);
    setIsPreviewOpen(false);
  };

  const handleOpenAuthorUser = (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => {
    const target = e.currentTarget;
    timer = setTimeout(() => {
      setIsOpenAuthorUser(true);
      setCoordinatesAuthor(target);
      setSelectedAuthor(user);
    }, 500);
  };

  const handleCloseAuthorUser = () => {
    if (timer && !isOpenAuthorUser) {
      clearTimeout(timer);
      return;
    }
    if (timer) {
      return;
    }
    handleCloseAuthorUserFromProfile();
  };

  const viewProfile = () => {
    setIsViewProfile(true);
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
  };

  const handleCloseAuthorUserFromProfile = () => {
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
    setSelectedAuthor(null);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const handleSaveGroup = () => {
    if (groupName && selectedUsersToGroup.length) {
      const saveData = {
        title: groupName,
        user_ids: selectedUsersToGroup,
      };
      if (isEditGroup) {
        dispatch(updateWatchersGroups({ saveData, id: editGroupItemId }));
      } else {
        dispatch(createWatchersGroups(saveData));
      }
      setGroupName('');
      setEditGroupItemId(null);
      setSelectedUsersToGroup([]);
      setSearchValueAddToGroup('');
      setIsMenuOpenGroup(false);
    }
  };

  const renderMenuItem = (item: RequestUserModel) => {
    const title = (
      <>
        <div className={`${themeClass}_watchersMenuItem`}>
          <div onMouseEnter={e => handleOpenAuthorUser(e, item)} onMouseLeave={e => handleCloseAuthorUser()}>
            <PlaceholderInfo
              type={'Persona'}
              firstName={item?.profile?.first_name}
              lastName={item?.profile?.last_name}
              imagePath={item?.profile?.image}
              detailInfo={`${item?.profile?.first_name} ${item?.profile?.last_name}`}
              size={24}
              isMobile={isMobile}
            />
          </div>
          <div className={'visibleCompany'}>
            <div
              className={cn(`${themeClass}_company_name`, {
                ['-mobile']: isMobile,
              })}
            >
              {item?.parties?.map(item => item?.company).join(', ')}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'sm'}
        disabled={false}
        title={title}
        value={item?.id}
        onItemClick={() => null}
        tooltip={null}
        isSelected={null}
        leftElem={null}
        leftElemClick={null}
        rightElem={
          !searchValue &&
          !isMobile && <CustomButton type={'text-plain'} size={'xs'} clickHandler={() => handleUnlinkWatcher(item?.id)} title={'Remove'} />
        }
        customClassName={cn(`${themeClass}_itemPadding`, {
          ['-mobile']: isMobile,
        })}
      />
    );
  };

  const renderMenuGroupItem = (item: WatcherGroupModel) => {
    const title = (
      <>
        <div
          className={cn(`${themeClass}_group_name`, {
            ['-mobile']: isMobile,
          })}
        >
          {' '}
          {item?.title}{' '}
        </div>
      </>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'sm'}
        disabled={false}
        title={title}
        value={item?.id}
        onItemClick={(_, __, e) => handlePreviewGroup(e, item.id)}
        tooltip={null}
        isSelected={null}
        leftElem={null}
        leftElemClick={null}
        rightElem={
          <>
            <div style={{ display: isMobile ? 'none' : 'flex' }}>
              <CustomButton title={'Remove'} type={'text-plain'} size={'xs'} clickHandler={e => handleUnlinkGroup(item?.id)} />
            </div>
          </>
        }
        customClassName={cn(`${themeClass}_itemPadding`, {
          ['-mobile']: isMobile,
        })}
      />
    );
  };

  const renderMenuItemGroups = (item: RequestUserModel) => {
    const isSelected = selectedUsersToGroup?.includes(item.id);
    const title = (
      <div className={`${themeClass}_watchersMenuItem`}>
        <CheckBox checked={isSelected} />
        <div onMouseEnter={e => handleOpenAuthorUser(e, item)} onMouseLeave={e => handleCloseAuthorUser()}>
          <PlaceholderInfo
            type={'Persona'}
            firstName={item?.profile?.first_name}
            lastName={item?.profile?.last_name}
            imagePath={item?.profile?.image}
            detailInfo={`${item?.profile?.first_name} ${item?.profile?.last_name}`}
            size={24}
          />
        </div>
        <div>
          <div
            className={cn(`${themeClass}_company_name`, {
              ['-mobile']: isMobile,
            })}
          >
            {item?.parties?.map(item => item?.company).join(', ')}
          </div>
        </div>
      </div>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'sm'}
        disabled={false}
        title={title}
        value={item?.id}
        onItemClick={handleChangeMultiSelect}
        tooltip={null}
        isSelected={null}
        leftElem={null}
        leftElemClick={null}
        rightElem={null}
        rightElemClick={null}
      />
    );
  };

  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <div
          className={cn(`${themeClass} ${themeClass}_watchlistContainer`, {
            ['-disabled']: disabled,
          })}
          onClick={e => (disabled ? () => void 0 : openMenu(e))}
        >
          <ActionButton
            type={inWatchlist ? 'in-watchlist' : 'not-in-watchlist'}
            isMobile={isMobile}
            showCounter={inWatchlist}
            itemCounterColor={'primary'}
            itemCounterSize={'sm'}
            itemCounterValue={watchlistCount}
          />
        </div>
      )}
      <DropdownPopoverWatchlist
        isMenuOpen={isMenuOpen}
        customClassName={null}
        renderItemHeader={null}
        renderItemFooter={renderItemFooter}
        renderMenuItem={renderMenuItem}
        renderMenuGroupItem={renderMenuGroupItem}
        menuItems={watchers?.map(item => item.user)}
        findWatchersItems={findWatchers}
        watcherSelected={watcherSelected}
        anchorElsearch={anchorElsearch}
        groupItems={watchersGroups}
        size={'sm'}
        anchorEl={anchorEl}
        onOpen={null}
        onClose={closeMenu}
        onSelectClose={onSelectClose}
        searchValue={searchValue}
        handleCreateGroup={handleCreateGroup}
        handleSelectItem={handleSelectItem}
        handleSelectGroup={handleSelectGroup}
        linkedGroups={watcherGroupsSelected}
        handleEditGroup={handleEditGroup}
        handleDeleteGroup={handleDeleteGroup}
        handleOpenAuthorUser={handleOpenAuthorUser}
        handleCloseAuthorUser={handleCloseAuthorUser}
      />
      <DropdownPopoverWatchlistGroups
        isMenuOpen={isMenuOpenGroup}
        customClassName={null}
        renderItemHeader={null}
        renderItemFooter={renderItemFooterGroups}
        renderMenuItem={renderMenuItemGroups}
        menuItems={findWatchers}
        groupItems={findWatchers}
        size={'sm'}
        groups={null}
        groupingType={null}
        anchorEl={isEditFromPreview ? anchorElPreview : anchorEl}
        onOpen={null}
        onClose={closeMenuGroup}
        groupName={groupName}
        handleChangeGroupName={handleChangeGroupName}
        searchValueAddToGroup={searchValueAddToGroup}
        handleChangeSearchAddToGroup={handleChangeSearchAddToGroup}
        isEditFromPreview={isEditFromPreview}
        handleOpenAuthorUser={handleOpenAuthorUser}
        handleCloseAuthorUser={handleCloseAuthorUser}
      />
      <WatchlistGroupPreview
        isOpen={isPreviewOpen}
        onClose={handleClosePreviewGroup}
        anchorEl={anchorElPreview}
        group={watchersGroups.find(group => group.id === previewGroupId)}
        handleEditGroup={handleEditGroup}
        handleOpenAuthorUser={handleOpenAuthorUser}
        handleCloseAuthorUser={handleCloseAuthorUser}
      />
      {isOpenAuthorUser && (
        <PopupProfile
          anchorEl={coordinatesAuthor}
          user={selectedAuthor}
          viewProfile={viewProfile}
          closeTeamUser={handleCloseAuthorUserFromProfile}
          closeFixedTooltip={handleCloseAuthorUserFromProfile}
        />
      )}
      {isViewProfile && (
        <ProfilePopover
          open={isViewProfile}
          onClose={viewProfileClose}
          type={isManagerAccess ? 'edit' : 'view'}
          projectId={project_id}
          user_id={selectedAuthor.id}
        />
      )}
    </>
  );
};

export default withConfirmDialogContext(React.memo(Watchlist));
