import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { CodeSelector } from '../../../../controls/Dropdowns/DropdownСomponents';
import TextInputNew from '../../../../controls/TextInputNew/TextInputNew';
import PartySelector from '../../../../controls/PartySelector/PartySelector';
import DateField from '../../../../controls/DateField/DateField';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import CustomCalendar from '../../../../controls/CustomCalendar/CustomCalendar';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import HashtagsEllipsis from '../../../Hashtags/HashtagsEllipsis';
import {
  LocationCollectionModel,
  MfViewDataModel,
  RequestBuildingModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
} from '../../../../../models';
import CardRenderSelect from '../../../CardRequest/controls/CardRenderSelect/CardRenderSelect';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import LevelsSelect from '../../../CardRequest/controls/LevelsSelect/LevelsSelect';
import BuildingSelect from '../../../CardRequest/controls/BuildingSelect/BuildingSelect';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import ImpactSelect, { ImpactModel } from '../../../../controls/ImpactSelect/ImpactSelect';

import './TopCardPartStyles.scss';

interface TopCardPartProps {
  selectedRequest: ISubmittal;
  isCardLoading: boolean;
  isDragRequestCard: boolean;
  isHiddenFields: boolean;
  handleChangeShowMoreFields: () => void;
  submitDueDateSelected: string;
  setSubmitDueDateSelected: (value: string) => void;
  requiredDueDateSelected: string;
  setRequiredDueDateSelected: (value: string) => void;
  hashtagsSelected: TagFormModel[];
  handleChangeHashtags: (value: TagFormModel[]) => void;
  buildingsSelected: RequestBuildingModel[];
  setBuildingsSelected: (value: RequestBuildingModel[]) => void;
  locationsSelected: LocationCollectionModel[];
  setLocationsSelected: (value: LocationCollectionModel[]) => void;
  typicalLayoutsSelected: any[];
  setTypicalLayoutsSelected: (value: any[]) => void;
  buildingSpacesSelected: SimpleTitleModel[];
  setBuildingSpacesSelected: (value: SimpleTitleModel[]) => void;
  buildings: RequestBuildingModel[];
  locations: LocationCollectionModel[];
  typicalLayouts: any[];
  buildingSpaces: SimpleTitleModel[];
  titleSelected: string;
  setTitleSelected: (value: string | number) => void;
  responsibleContractorSelected: SelectedRequestCompanyModel;
  setResponsibleContractorSelected: (value: SelectedRequestCompanyModel) => void;
  responsibleManagerSelected: SelectedRequestCompanyModel;
  setResponsibleManagerSelected: (value: SelectedRequestCompanyModel) => void;
  mfSelected: MfViewDataModel;
  setMfSelected: (value: MfViewDataModel) => void;
  errors: any;
  isDeactivated: boolean;
  isCreateSubmittal: boolean;
  isPreviousRevisionDeactivated: boolean;
  clImpactsSelected: ImpactModel[];
  setClImpactsSelected: (items: ImpactModel[]) => void;
  saveSubmittalRequest: (isSubmit: boolean, isTopCardUpdate?: boolean) => void;
  isUpdateDraftButtonDisabledTopCard: boolean;
  canUpdateDescription: boolean;
}

const TopCardPart: React.FC<TopCardPartProps> = ({
  selectedRequest,
  isCardLoading,
  isDragRequestCard,
  isHiddenFields,
  handleChangeShowMoreFields,
  submitDueDateSelected,
  setSubmitDueDateSelected,
  requiredDueDateSelected,
  setRequiredDueDateSelected,
  hashtagsSelected,
  handleChangeHashtags,
  buildingsSelected,
  setBuildingsSelected,
  locationsSelected,
  setLocationsSelected,
  typicalLayoutsSelected,
  setTypicalLayoutsSelected,
  buildingSpacesSelected,
  setBuildingSpacesSelected,
  buildings,
  locations,
  typicalLayouts,
  buildingSpaces,
  titleSelected,
  setTitleSelected,
  responsibleContractorSelected,
  setResponsibleContractorSelected,
  responsibleManagerSelected,
  setResponsibleManagerSelected,
  mfSelected,
  setMfSelected,
  errors,
  isDeactivated,
  isCreateSubmittal,
  isPreviousRevisionDeactivated,
  setClImpactsSelected,
  clImpactsSelected,
  saveSubmittalRequest,
  isUpdateDraftButtonDisabledTopCard,
  canUpdateDescription,
}) => {
  const themeClass = useGetThemeClass('b-cardSubmittal-topCardPart');
  const iconThemeClass = useGetThemeClass('buttonIconClass');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const submitDateRef = useRef(null);
  const requiredDateRef = useRef(null);

  const projectData = useAppSelector<any>(state => state.phasePlan.projectData);
  const { isCardFirstLoading } = useAppSelector(state => state.submittalSlice);
  const projectInfoLoading = useAppSelector(state => state.projectReducer.projectInfoLoading);
  const userInfo = useAppSelector(state => state.userReducer.userInfo);

  // States
  const [focusElement, setFocusElement] = useState('');
  const [responsibleContractorErrors, setResponsibleContractorErrors] = useState([]);
  const [responsibleManagerErrors, setResponsibleManager] = useState([]);
  const [openedCalendarType, setOpenedCalendarType] = useState<'submit' | 'required'>(null);

  const isOverdueDueDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const dueDate = new Date(submitDueDateSelected);
    dueDate.setHours(0, 0, 0, 0);

    return dueDate <= currentDate;
  }, [submitDueDateSelected]);

  const { is1440Resolution } = GetResolution();
  const isSidePeekView = isDragRequestCard || is1440Resolution;
  const isAfterSubmitDate = selectedRequest?.status?.alias === 'draft' && isOverdueDueDate;

  const selectedBuildingsIds = useMemo(() => {
    return buildingsSelected?.map(building => building.id) || [];
  }, [buildingsSelected]);

  useEffect(() => {
    const responsibleContractorErrors = [];
    const responsibleManagerErrors = [];

    if (errors?.contractor_party_id) responsibleContractorErrors.push('company');
    if (errors?.contractor_user_id) responsibleContractorErrors.push('user');
    if (errors?.responsible_party_id) responsibleManagerErrors.push('company');
    if (errors?.responsible_user_id) responsibleManagerErrors.push('user');

    setResponsibleContractorErrors(responsibleContractorErrors);
    setResponsibleManager(responsibleManagerErrors);
  }, [errors]);

  const renderShowMoreButton = () => {
    return (
      <CustomButton
        icon={<TokenIcon iconName={isHiddenFields ? 'plus' : 'dash'} size={12} />}
        type={'text-plain'}
        size={'md'}
        clickHandler={handleChangeShowMoreFields}
        title={isHiddenFields ? 'Show more fields' : 'Show less fields'}
        iconClass={`${iconThemeClass}_text-plain`}
      />
    );
  };

  const handleOpenCalendar = (dateType: 'submit' | 'required') => {
    setOpenedCalendarType(dateType);
  };

  const handleCloseCalendar = () => {
    setOpenedCalendarType(null);
  };

  const handleChangeImpacted = (item: ImpactModel, status: 0 | 1 | null) => {
    if (status === null) {
      setClImpactsSelected(clImpactsSelected.filter(impact => impact.alias !== item.alias));
    } else {
      setClImpactsSelected(
        clImpactsSelected.some(impact => impact.alias === item.alias)
          ? clImpactsSelected.map(impact => (impact.alias === item.alias ? { ...impact, status } : impact))
          : [...clImpactsSelected, { ...item, status }],
      );
    }
  };

  const selectAtDateHandler = (date: string | Date, dateType: 'submit' | 'required') => {
    if (dateType === 'submit') setSubmitDueDateSelected(moment(date).format('YYYY-MM-DD'));
    if (dateType === 'required') setRequiredDueDateSelected(moment(date).format('YYYY-MM-DD'));

    handleCloseCalendar();
  };

  const handleUpdateTopCard = () => {
    saveSubmittalRequest(false, true);
  };

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-sidePeekView']: isSidePeekView,
        })}
      >
        <div className={`${themeClass}_mainContainer`}>
          <div className={`${themeClass}_topBlock`}>
            <div className={`${themeClass}_topBlock_submittalSelect`}>
              <div className={cn(`${themeClass}_topBlock_submittalSelect_labelText`, { ['-value']: mfSelected?.code })}>
                {mfSelected?.code ? `#${mfSelected?.code}` : '-'}
              </div>
              <CustomTooltip
                enterDelay={1000}
                enterNextDelay={1000}
                leaveDelay={0}
                title={
                  localStorage.getItem('show_tooltips') === 'no' ? '' : mfSelected ? '' : 'MasterFormat code associated with the submittal'
                }
                placement={'top'}
                customBasisClass={`${themeClass}_tooltip`}
              >
                <CodeSelector
                  isMulti={false}
                  codeType={'mf'}
                  isValuesRemovable
                  isValuesEditable
                  selected={mfSelected}
                  selectHandler={setMfSelected}
                  selectedBuildingIds={selectedBuildingsIds?.length ? selectedBuildingsIds : null}
                  basisPlaceholder={`Select /--/`}
                  menuItemSize={'md'}
                  basisMaxItems={2}
                  handleFor={'specification'}
                  renderBasisSelectedItem={item => item.title}
                  error={errors?.mf_id}
                  disabled={(isDeactivated || isCardFirstLoading || isPreviousRevisionDeactivated) && !isCreateSubmittal}
                />
              </CustomTooltip>
            </div>
            <div className={`${themeClass}_topBlock_titleInput`}>
              <TextInputNew
                type={'on-bgd'}
                placeholder={'Input title'}
                onChange={value => setTitleSelected(value)}
                value={titleSelected || ''}
                isFocus={focusElement === 'Input title'}
                onClickIcon={() => null}
                onFocus={() => setFocusElement('Input title')}
                onBlur={() => setFocusElement('')}
                error={errors?.title}
                disabled={(isDeactivated || isCardFirstLoading || isPreviousRevisionDeactivated) && !isCreateSubmittal}
              />
            </div>
            <div
              className={cn(`${themeClass}_topBlock_responsibleContractorBlock`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_topBlock_responsibleContractorBlock_title`}>Responsible contractor</div>
              <div className={`${themeClass}_topBlock_responsibleContractorBlock_select`}>
                <PartySelector
                  type={'request'}
                  selected={responsibleContractorSelected}
                  handleSelect={item => setResponsibleContractorSelected(item)}
                  customPartyContainerClassName={cn(`${themeClass}_topBlock_responsibleContractorContainer`, {
                    ['-sidePeekView']: isSidePeekView,
                  })}
                  isLoading={isCardFirstLoading && !isCreateSubmittal}
                  errors={responsibleContractorErrors}
                  isDisableSelect={isDeactivated || isPreviousRevisionDeactivated}
                  isDisableDisciplineAndUserSelect={isDeactivated || isPreviousRevisionDeactivated}
                  // onSelectCompany={() => {
                  //   setMainErrors({ ...errors, request_party_id: '' });
                  // }}
                />
              </div>
            </div>
            <div
              className={cn(`${themeClass}_topBlock_submitByDate`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_topBlock_submitByDate_title`}>Submit by</div>
              <div className={`${themeClass}_topBlock_submitByDate_date`}>
                <DateField
                  selectedDate={submitDueDateSelected ? moment(submitDueDateSelected).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                  isSentRequestToChange={false}
                  isOpenCalendar={openedCalendarType === 'submit'}
                  isDiffDueScheduledDate={false}
                  info={false}
                  isBefore={submitDueDateSelected && isAfterSubmitDate}
                  valueIsDate={true}
                  dateFieldRef={submitDateRef}
                  clickHandler={() => handleOpenCalendar('submit')}
                  disabled={isDeactivated || isPreviousRevisionDeactivated}
                  // disabled={isScheduleDateDisabled}
                />
              </div>
            </div>
            <div
              className={cn(`${themeClass}_topBlock_responsibleManagerBlock`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_topBlock_responsibleManagerBlock_title`}>Responsible manager</div>
              <div className={`${themeClass}_topBlock_responsibleManagerBlock_select`}>
                <PartySelector
                  type={'response'}
                  selected={responsibleManagerSelected}
                  handleSelect={item => setResponsibleManagerSelected(item)}
                  customPartyContainerClassName={cn(`${themeClass}_topBlock_responsibleManagerContainer`, {
                    ['-sidePeekView']: isSidePeekView,
                  })}
                  isLoading={isCardFirstLoading && !isCreateSubmittal}
                  errors={responsibleManagerErrors}
                  isDisableSelect={isDeactivated || isPreviousRevisionDeactivated}
                  // onSelectCompany={() => {
                  //   setMainErrors({ ...errors, request_party_id: '' });
                  // }}
                />
              </div>
            </div>
            <div
              className={cn(`${themeClass}_topBlock_requiredDate`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_topBlock_requiredDate_title`}>Required on site date</div>
              <div className={`${themeClass}_topBlock_requiredDate_date`}>
                <DateField
                  selectedDate={requiredDueDateSelected ? moment(requiredDueDateSelected).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                  isSentRequestToChange={false}
                  isOpenCalendar={openedCalendarType === 'required'}
                  isDiffDueScheduledDate={false}
                  info={false}
                  isBefore={false}
                  valueIsDate={true}
                  dateFieldRef={requiredDateRef}
                  clickHandler={() => handleOpenCalendar('required')}
                  disabled={isDeactivated || isPreviousRevisionDeactivated}
                />
              </div>
            </div>
          </div>
          {!isHiddenFields && (
            <div className={`${themeClass}_bottomBlock`}>
              <div className={`${themeClass}_bottomBlock_tagsBlock`}>
                <div className={`${themeClass}_bottomBlock_tagsBlock_tagsTitle`}>Tags</div>
                <div className={cn(`${themeClass}_bottomBlock_tagsBlock_tagsSelect`, { ['-sidePeek']: isSidePeekView })}>
                  <HashtagsEllipsis
                    isAccess
                    disabled={isDeactivated || isPreviousRevisionDeactivated}
                    onChangeHashtags={handleChangeHashtags}
                    tags={hashtagsSelected}
                    isHashTagChanging={false}
                    isSandBox={false}
                  />
                </div>
              </div>
              <div className={cn(`${themeClass}_bottomBlock_location`)}>
                <div className={`${themeClass}_bottomBlock_location_title`}>Location</div>
                <div className={`${themeClass}_bottomBlock_location_selectorsBlock`}>
                  <CustomTooltip
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={0}
                    title={
                      localStorage.getItem('show_tooltips') === 'no'
                        ? ''
                        : buildings.length > 0
                        ? ''
                        : 'Buildings affected by this submittal'
                    }
                    placement={'top'}
                    customBasisClass={`${themeClass}_tooltip`}
                  >
                    <CardRenderSelect<DropdownItemModel, null, true>
                      type={'building'}
                      isMulti={true}
                      isHeader
                      isFooter
                      menuItems={buildings}
                      selected={buildingsSelected}
                      basisPlaceholder={'Select building'}
                      menuItemSize={'md'}
                      basisMaxItems={1}
                      customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                      isLoading={isCardFirstLoading && !isCreateSubmittal}
                      disabled={isDeactivated || isPreviousRevisionDeactivated}
                      selectHandler={item => setBuildingsSelected(item)}
                    />
                  </CustomTooltip>
                  <CustomTooltip
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={0}
                    title={
                      localStorage.getItem('show_tooltips') === 'no'
                        ? ''
                        : locationsSelected.length > 0
                        ? ''
                        : 'Levels affected by this submittal'
                    }
                    placement={'top'}
                    customBasisClass={`${themeClass}_tooltip`}
                  >
                    <LevelsSelect<DropdownItemModel, DropdownGroupItem, true>
                      isHeader
                      isFooter
                      selectHandler={null}
                      selected={[...locationsSelected, ...typicalLayoutsSelected]}
                      basisPlaceholder={'Select levels'}
                      menuItemSize={'md'}
                      customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                      isFullWidth
                      buildings={buildings}
                      levels={locations}
                      typicalLayouts={typicalLayouts}
                      selectedBuildings={buildingsSelected}
                      setBuildingsSelected={setBuildingsSelected}
                      setLevelsSelected={setLocationsSelected}
                      levelsSelected={locationsSelected}
                      setTypicalLayoutsSelected={setTypicalLayoutsSelected}
                      typicalLayoutsSelected={typicalLayoutsSelected}
                      isLoading={isCardFirstLoading && !isCreateSubmittal}
                      disabled={isDeactivated || isPreviousRevisionDeactivated}
                    />
                  </CustomTooltip>
                  <CustomTooltip
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={0}
                    title={
                      localStorage.getItem('show_tooltips') === 'no'
                        ? ''
                        : buildingSpaces.length > 0
                        ? ''
                        : 'No building spaces available. Populate them in Project Settings > Buildings & Locations'
                    }
                    placement={'top'}
                    customBasisClass={`${themeClass}_tooltip`}
                  >
                    <BuildingSelect<DropdownItemModel, DropdownGroupItem, true>
                      isHeader
                      isFooter
                      selectHandler={null}
                      selected={buildingSpacesSelected}
                      basisPlaceholder={'Select building spaces'}
                      menuItemSize={'md'}
                      customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                      isFullWidth
                      buildings={buildings}
                      levels={locations}
                      levelsSelected={locationsSelected}
                      typicalLayoutsSelected={typicalLayoutsSelected}
                      buildingSpaces={buildingSpaces}
                      buildingSpacesSelected={buildingSpacesSelected}
                      setBuildingSpacesSelected={setBuildingSpacesSelected}
                      isLoading={isCardFirstLoading && !isCreateSubmittal}
                      disabled={isDeactivated || isPreviousRevisionDeactivated}
                    />
                  </CustomTooltip>
                </div>
              </div>
              {userInfo.qc_user && (
                <div className={cn(`${themeClass}_bottomBlock_impactsBlock`)}>
                  <div className={`${themeClass}_bottomBlock_impactsBlock_impactsTitle`}>CL impact</div>
                  <div className={`${themeClass}_bottomBlock_impactsBlock_impactsSelect`}>
                    <ImpactSelect
                      items={clImpactsSelected || []}
                      onChange={handleChangeImpacted}
                      loading={projectInfoLoading.loading || isCardLoading}
                      type={'Submittal'}
                      onResetAll={() => {
                        setClImpactsSelected([]);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`${themeClass}_subContainer`}>
          {renderShowMoreButton()}
          {!isCreateSubmittal && (
            <CustomTooltip
              enterDelay={500}
              leaveDelay={0}
              title={canUpdateDescription ? 'Click Update at the bottom of the card to save changes' : ''}
              placement={'top'}
            >
              <CustomButton
                type={'tertiary'}
                size={'sm'}
                clickHandler={() => handleUpdateTopCard()}
                title={'Update'}
                disabled={isUpdateDraftButtonDisabledTopCard}
              />
            </CustomTooltip>
          )}
        </div>
      </div>
      <Popover
        open={!!openedCalendarType}
        onClose={handleCloseCalendar}
        anchorEl={openedCalendarType === 'submit' ? submitDateRef?.current : requiredDateRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div>
          {!!openedCalendarType && (
            <>
              <CustomCalendar
                handleChange={date => selectAtDateHandler(date, openedCalendarType)}
                value={submitDueDateSelected}
                name={'formData'}
                work_week={projectData.work_week || 7}
              />
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default TopCardPart;
