import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { pdfTronSupportedTypes } from '../../../constants/pdfTronSupportedTypes';
import SinglePagePDFViewer from '../../controls/Preview/SinglePagePDFViewer';
import FileHelper from '../../../helpers/FileHelper';
import { commonSlice } from '../../../store/common/commonReducer';
import { useDispatch } from 'react-redux';
import { FileModel, RequestModel } from '../../../models';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { getFileSizeName } from '../../../service/file';
import { PCDApi } from '../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import AccordionFeedTab from '../AccordionFeedTab/AccordionFeedTab';
import cn from 'classnames';
import CheckBox from '../CheckBox/CheckBox';
import { MiniLoader } from '../MiniLoader';
import TokenIcon from '../TokenIcon/TokenIcon';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import { NeedListRequestShort } from '../../../store/needlist/needListModels';
import DialogPopUp from '../DialogPopUp/DialogPopUp';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import OverflowMenuButton from '../ButtonComponents/OverflowMenuButton/OverflowMenuButton';
import Popover from '@material-ui/core/Popover';
import Divider from '../Divider/Divider';
import moment from 'moment';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import { useCheckIfElementHaveScroll } from '../../../helpers/commonHooks';
import commonApi from '../../../service/Api/commonApi';
import fileDownload from 'js-file-download';
import { settingsSlice } from '../../../store/globalSettings/settingsSlice';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './FilesListStyles.scss';

const { setGlobalPrompPopUp } = settingsSlice.actions;

interface IProps {
  selectedRequest?: RequestModel | PCDCard | NeedListRequestShort;
  source: 'R' | 'C' | 'FC' | 'PCD' | 'Comment' | 'Sub';
  isEditable?: boolean;
  isAccess?: boolean;
  isThumbnailView?: boolean;
  subSource?: string;
  files: FileModel[] | FileList | any;
  type: 'horizontalList' | 'verticalList';
  cardType?: 'nf' | 'pcd' | 'submittal';
  disabled: boolean;
  fullSizeList?: boolean;
  delFile?: (filename: string) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  isEditMode?: boolean;
  fileLoading?: boolean;
  loadingFilesName?: any;
  showAccordion?: boolean;
  totalViewFiles?: number;
  typeSize?: 'sm';
  isAllExpanded?: boolean;
  isCardSidePeek?: boolean;
  isDeleteCheckedItems?: boolean;
  handleDeleteCheckedFiles?: (status: boolean) => void;
  setCheckedFilesId?: (ids: number[]) => void;
  isReview?: boolean;
  isSubmittalCard?: boolean;
}

const FilesListViewTokens = ({
  selectedRequest,
  source,
  isEditable,
  isAccess,
  isThumbnailView,
  subSource,
  files,
  type,
  cardType,
  disabled,
  fullSizeList,
  delFile,
  delSavedFile,
  isEditMode,
  fileLoading,
  loadingFilesName,
  showAccordion,
  totalViewFiles,
  typeSize,
  isAllExpanded,
  isCardSidePeek,
  isDeleteCheckedItems,
  handleDeleteCheckedFiles,
  setCheckedFilesId,
  isReview,
  isSubmittalCard,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { getNfParamsFromURL } = useRoutingHelper();
  const { location } = useRouter();

  const { isMobile, is1440Resolution } = GetResolution();

  const themeClass = useGetThemeClass('b-fileList');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const listRef = useRef();
  const isListHaveScroll = useCheckIfElementHaveScroll(listRef);

  const [selectedPrevFile, setSelectedPrevFile] = useState(null);
  const [isPDF, setIsPDF] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileDelete, setFileDelete] = useState(false);
  const [fileName, setFileName] = useState('');
  const [showList, setShowList] = useState(isEditMode || false);
  const [deletableFile, setDeletableFile] = useState(null);
  const [deletableFilesId, setDeletableFilesId] = useState([]);
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState(false);

  // File Overflow Menu States
  const [overflowFileMenuAnchor, setOverflowFileMenuAnchor] = useState(null);
  const [overflowMenuFileData, setOverflowMenuFileData] = useState<FileModel>(null);

  useEffect(() => {
    if (isDeleteCheckedItems) {
      setIsOpenRemovePopup(true);
    }
  }, [isDeleteCheckedItems]);

  const isCardSidePeekMode = useMemo(() => {
    return isCardSidePeek || is1440Resolution;
  }, [is1440Resolution, isCardSidePeek]);

  useEffect(() => {
    if (isEditMode) {
      setShowList(true);
    }
  }, [files]);

  useEffect(() => {
    setShowList(isAllExpanded);
  }, [isAllExpanded]);

  const handleOpenAccordion = () => {
    setShowList(!showList);
  };

  const handleRemovePopupClose = () => {
    setIsOpenRemovePopup(false);
    setDeletableFile(null);
    handleDeleteCheckedFiles && handleDeleteCheckedFiles(false);
  };

  const handleRemovePopupOpen = file => {
    setIsOpenRemovePopup(true);
    setDeletableFile(file);
  };

  const handleCheckFileToDelete = file => {
    if (deletableFilesId.includes(file.id)) {
      setDeletableFile(prevState => prevState?.filter(item => item.id !== file.id));
      setDeletableFilesId(prevState => prevState.filter(item => item !== file.id) || []);
      setCheckedFilesId(deletableFilesId.filter(item => item !== file.id) || []);
    } else {
      setDeletableFile([...(deletableFile || []), file]);
      setDeletableFilesId([...(deletableFilesId || []), file.id]);
      setCheckedFilesId([...(deletableFilesId || []), file.id]);
    }
  };

  const fileHandler = async (route, name) => {
    if (overflowFileMenuAnchor) {
      handleCloseOverflowFileMenu();
    }

    let fileName = '';

    const { project_id } = getNfParamsFromURL();
    await setFileLoaded(true);
    setFileName(name);

    const response = await commonApi.getFile(route);

    if (response.headers['content-type'] === 'application/pdf' && !name.includes('.pdf')) {
      fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_${FileHelper.getSourceName(source)}_${name}.pdf`;
    } else {
      fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_${FileHelper.getSourceName(source)}_${name}`;
    }

    fileDownload(response.data, fileName);

    await setFileLoaded(false);
    setFileName('');
  };

  const delFileProgress = (file, name) => {
    if (file && name) {
      setFileDelete(true);
      setFileName(name);

      setTimeout(() => {
        delFile(name);
        setFileDelete(false);
        setFileName('');
        handleRemovePopupClose();
        if (overflowFileMenuAnchor) {
          handleCloseOverflowFileMenu();
        }
      }, 1500);
    }
  };

  const delSavedFileProgress = (file, title, isBulk?) => {
    if (isBulk) {
      setFileDelete(true);
      setFileName(deletableFile?.map(item => item.title) || '');
      delSavedFile(deletableFilesId, true);
      setDeletableFilesId([]);
      setCheckedFilesId([]);

      setTimeout(() => {
        setFileDelete(false);
        setFileName('');
        handleRemovePopupClose();
        if (overflowFileMenuAnchor) {
          handleCloseOverflowFileMenu();
        }
      }, 1500);
      return;
    }

    if (file && title) {
      setFileDelete(true);
      setFileName(title);
      delSavedFile(file.id);
      setDeletableFilesId([]);
      setCheckedFilesId([]);

      setTimeout(() => {
        setFileDelete(false);
        setFileName('');
        handleRemovePopupClose();
        if (overflowFileMenuAnchor) {
          handleCloseOverflowFileMenu();
        }
      }, 1500);
    }
  };

  const openPrevFile = (file, e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!file.id) {
      return null;
    }

    let isAllowFileOpening = false;

    if (
      file?.title.includes('.jpeg') ||
      file?.title.includes('.pdf') ||
      file?.title.includes('.png') ||
      file?.title.includes('.jpg') ||
      file?.title.includes('.ico') ||
      file?.title.includes('.gif') ||
      file?.title.includes('.tiff') ||
      file?.title.includes('.webp') ||
      file?.title.includes('.eps') ||
      file?.title.includes('.svg') ||
      file?.title.includes('.psd') ||
      file?.title.includes('.indd') ||
      file?.title.includes('.cdr') ||
      file?.title.includes('.ai') ||
      file?.title.includes('.raw')
    ) {
      isAllowFileOpening = true;
    }

    if (file?.title && !isAllowFileOpening) {
      dispatch(
        setGlobalPrompPopUp({
          isChanged: false,
          isOpenPopUp: true,
          changeUrl: '',
          header: 'Error',
          body: `We can't open this file for you, please download with the button below.`,
          tertiaryTitle: 'Cancel',
          primaryTitle: 'Download',
          callback: () => fileHandler(file.url, file.title),
        }),
      );
      return null;
    }

    if (isEditable) {
      dispatch(
        openPdftron({
          file,
          selectedRequest,
          isRequest: source === 'R',
          isDeliverable: source === 'FC',
          isComment: subSource === 'comment',
          isPCD: source === 'PCD',
          isSubmittal: source === 'Sub',
          isReview: !!isReview,
          isAccess: isAccess && !(window.innerWidth < 767 || window.innerWidth < 1024),
          isThumbnailView: isThumbnailView,
        }),
      );
    } else {
      if (file.file) {
        setSelectedPrevFile(file);
        const ispdf = pdfTronSupportedTypes.find(f => f === file.ext.toUpperCase());
        setIsPDF(!!ispdf);
      } else {
        setSelectedPrevFile(file.fullpath || URL.createObjectURL(file));
        setIsPDF(file.fullpath ? false : file.type.indexOf('pdf') + 1);
      }
    }
  };

  const handleCloseSelectedPrevFile = () => {
    setSelectedPrevFile(null);
    setIsPDF(false);
  };

  const handleOpenOverflowFileMenu = (e: MouseEvent, fileData: FileModel) => {
    setOverflowFileMenuAnchor(e.currentTarget);
    setOverflowMenuFileData(fileData);
  };

  const handleCloseOverflowFileMenu = () => {
    setOverflowFileMenuAnchor(null);
    setOverflowMenuFileData(null);
  };

  const renderIcon = type => {
    const sizeInterpreter = {
      default: 16,
      mobile: 20,
    };

    let sizeName = 'default';

    if (isMobile) {
      sizeName = 'mobile';
    }

    if (type === 'csv') {
      return <TokenIcon iconName={'file-csv'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'docx' || type === 'doc') {
      return <TokenIcon iconName={'file-doc'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'img' || type === 'svg') {
      return <TokenIcon iconName={'file-img'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'jpg' || type === 'jpeg') {
      return <TokenIcon iconName={'file-jpeg'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'pdf') {
      return <TokenIcon iconName={'file-pdf'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'png') {
      return <TokenIcon iconName={'file-png'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'txt') {
      return <TokenIcon iconName={'file-txt'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'xlsx' || type === 'xls') {
      return <TokenIcon iconName={'file-xls'} size={sizeInterpreter[sizeName]} />;
    }
    if (type === 'zip') {
      return <TokenIcon iconName={'file-zip'} size={sizeInterpreter[sizeName]} />;
    }
    return <TokenIcon iconName={'file'} size={sizeInterpreter[sizeName]} />;
  };

  const renderList = () => {
    if (type === 'horizontalList') {
      return (
        <div
          className={cn(`${themeClass}_filesBlock`, {
            ['isEditModeForAttach']: isEditMode && !isThumbnailView,
            ['isThumbnailViewBlock']: isThumbnailView,
          })}
        >
          <div className={`${themeClass}_fileListEdit`}>
            <ul
              className={`${isThumbnailView ? `${themeClass}_containerImgPdf` : `${themeClass}_fileList`}`}
              style={{ width: fullSizeList ? '100%' : null }}
            >
              {isThumbnailView &&
                files.map((file, i) => {
                  return (
                    <li key={`file-${file.id}-${i}`} style={{ listStyleType: 'none' }} className={`${themeClass}_thumbnailViewListItem`}>
                      <div onClick={e => openPrevFile(file, e)}>
                        {file?.ext.includes('png') || file?.ext.includes('jpg') || file?.ext.includes('jpeg') ? (
                          <div className={`${themeClass}_viewer`}>
                            <img className={`${themeClass}_boxImg`} src={file.file} />
                            <div className={'iconBox'}>
                              <TokenIcon iconName={'search'} size={isMobile ? 24 : 16} />
                            </div>
                          </div>
                        ) : file?.ext.includes('pdf') ? (
                          <div className={`${themeClass}_viewer`}>
                            <SinglePagePDFViewer pdf={file.file} />
                            <div className={'iconBox'}>
                              <TokenIcon iconName={'search'} size={isMobile ? 24 : 16} />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {file?.ext.includes('png') || file?.ext.includes('jpg') || file?.ext.includes('jpeg') || file?.ext.includes('pdf') ? (
                        <div className={`${themeClass}_thumbnailViewItemInfo`}>
                          <div className={`${themeClass}_thumbnailLeftContent`} onClick={e => openPrevFile(file, e)}>
                            <span className={`${themeClass}_thumbnailFileName`}>{file.name || file.title}</span>
                          </div>
                          <div className={`${themeClass}_rightContent -thumbnail`}>
                            {fileLoaded && fileName === file.title ? (
                              <div className={'downloadBoxLoading'}>
                                <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                              </div>
                            ) : (
                              <a className={'downloadBox'} target="_blank" rel="noopener noreferrer">
                                <SystemButton
                                  type={'download'}
                                  variant={'transparent'}
                                  size={'md'}
                                  clickHandler={() => fileHandler(file.url, file.title)}
                                  isMobile={isMobile}
                                />
                              </a>
                            )}
                            {(file.name || file.title) && !disabled && !(fileLoaded && fileName === file.title) && (
                              <a className={'removeBox'} target="_blank" rel="noopener noreferrer">
                                <SystemButton
                                  isMobile={isMobile}
                                  type={'delete'}
                                  variant={'transparent'}
                                  size={'md'}
                                  clickHandler={() => null}
                                />
                                {fileDelete && (fileName === file.title || fileName === file.name) && (
                                  <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </li>
                  );
                })}
              {!isThumbnailView &&
                files.map((file, i) => {
                  if (typeSize === 'sm') {
                    return (
                      <li
                        key={`file-${file.id}-${i}`}
                        className={cn(`${themeClass}_horizontalFileListItem -sm`, {
                          ['-mobile']: isMobile,
                        })}
                      >
                        <span className={`${themeClass}_icon`}>
                          {renderIcon(file?.ext || file?.name?.split('.')?.[file?.name?.split('.')?.length - 1])}
                        </span>
                        <a
                          href={`${location.pathname}?${selectedRequest?.nf ? `nf=${selectedRequest?.nf}&` : ''}fileid=${file?.id || ''}`}
                          onClick={e => openPrevFile(file, e)}
                          className={`${themeClass}_fileInfo -sm`}
                        >
                          <CustomTooltip
                            enterDelay={300}
                            leaveDelay={0}
                            title={file.name || file.title || ''}
                            placement={'top'}
                            customBasisClass={`${themeClass}_fileName -sm`}
                          >
                            <span className={`${themeClass}_fileName -sm`}>{file.name || file.title}</span>
                          </CustomTooltip>
                          <span className={`${themeClass}_fileType -sm`}>{getFileSizeName(file.size)}</span>
                        </a>

                        {!isEditMode ? (
                          <a className={`${themeClass}_downloadBox`} target="_blank" rel="noopener noreferrer">
                            {fileLoaded && fileName === file.title ? null : (
                              <SystemButton
                                type={'download'}
                                variant={'transparent'}
                                size={'md'}
                                clickHandler={() => fileHandler(file.url, file.title)}
                                isMobile={isMobile}
                              />
                            )}
                            {fileLoaded && fileName === file.title && (
                              <div className={'downloadBoxLoading'}>
                                <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                              </div>
                            )}
                          </a>
                        ) : null}
                        {fileLoading && loadingFilesName?.includes(file.name || file.title) ? (
                          <div className={'downloadBoxLoading'}>
                            <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                          </div>
                        ) : null}
                        {(file.name || file.title) && !disabled && !loadingFilesName?.includes(file.name) && (
                          <a className={`${themeClass}_removeBox`} target="_blank" rel="noopener noreferrer">
                            <SystemButton
                              type={'delete'}
                              variant={'transparent'}
                              isMobile={isMobile}
                              size={'md'}
                              clickHandler={() =>
                                file.name && !file.title ? delFileProgress(file, file.name) : delSavedFileProgress(file, file.title)
                              }
                            />
                            {fileDelete && (fileName === file.title || fileName === file.name) && (
                              <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                            )}
                          </a>
                        )}
                      </li>
                    );
                  }
                  return (
                    <li key={`file-${file.id}-${i}`} className={`${themeClass}_horizontalFileListItem`}>
                      <span className={`${themeClass}_icon`}>
                        {renderIcon(file?.ext || file?.name?.split('.')?.[file?.name?.split('.')?.length - 1])}
                      </span>
                      <a
                        href={`${location.pathname}?${selectedRequest?.nf ? `nf=${selectedRequest?.nf}&` : ''}fileid=${file?.id || ''}`}
                        onClick={e => openPrevFile(file, e)}
                        className={`${themeClass}_fileInfo`}
                      >
                        <CustomTooltip
                          enterDelay={300}
                          leaveDelay={0}
                          title={file.name || file.title || ''}
                          placement={'top'}
                          customBasisClass={`${themeClass}_fileName`}
                        >
                          <span className={`${themeClass}_fileName`}>{file.name || file.title}</span>
                        </CustomTooltip>
                        <span className={`${themeClass}_fileType`}>
                          <span className={`${themeClass}_fileType_format`}>
                            {(file?.ext || file?.name?.split('.')?.[file?.name?.split('.')?.length - 1]).toUpperCase()} File
                          </span>
                          <span className={`${themeClass}_fileType_weight`}>{getFileSizeName(file.size)}</span>
                        </span>
                      </a>
                      {!isEditMode ? (
                        <a className={`${themeClass}_downloadBox`} target="_blank" rel="noopener noreferrer">
                          {fileLoaded && fileName === file.title ? null : (
                            <SystemButton
                              type={'download'}
                              variant={'transparent'}
                              size={'md'}
                              clickHandler={() => fileHandler(file.url, file.title)}
                              isMobile={isMobile}
                            />
                          )}
                          {fileLoaded && fileName === file.title && (
                            <div className={'downloadBoxLoading'}>
                              <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                            </div>
                          )}
                        </a>
                      ) : null}
                      {(file.name || file.title) && !disabled && (
                        <a className={`${themeClass}_removeBox`} target="_blank" rel="noopener noreferrer">
                          <SystemButton
                            type={'delete'}
                            variant={'transparent'}
                            size={'md'}
                            isMobile={isMobile}
                            clickHandler={() =>
                              file.name && !file.title ? delFileProgress(file, file.name) : delSavedFileProgress(file, file.title)
                            }
                          />
                          {fileDelete && (fileName === file.title || fileName === file.name) && (
                            <MiniLoader
                              size={isMobile ? 'lg' : 'sm'}
                              rootClasses={`-delete`}
                              circleClasses={`${themeClass}_loader_circle`}
                            />
                          )}
                        </a>
                      )}
                    </li>
                  );
                })}
              {!isThumbnailView && !isEditable && selectedPrevFile?.id ? (
                <Dialog open={true} onClose={handleCloseSelectedPrevFile} maxWidth={'xl'}>
                  <div style={{ position: 'relative' }}>
                    <img src={selectedPrevFile.file} style={{ objectFit: 'contain', width: '100%' }} alt="file" />
                  </div>
                </Dialog>
              ) : null}
            </ul>
          </div>
        </div>
      );
    } else {
      const filesArray = [...files];
      return (
        <div className={`${themeClass}_verticalListBlock`} style={{ width: !isCardSidePeekMode ? '' : 'auto' }} ref={listRef}>
          <ul
            className={cn(`${themeClass}_verticalList`, { ['-scroll']: isListHaveScroll })}
            style={totalViewFiles > 7 ? { height: 'auto', overflowY: 'hidden' } : {}}
          >
            {filesArray?.length
              ? filesArray
                  .reverse()
                  .slice(0, totalViewFiles)
                  .map((file, i, arr) => {
                    const isLast = i + 1 === arr.length;

                    return (
                      <li
                        key={`file-${file.id}-${i}`}
                        className={cn(`${themeClass}_verticalFileListItem`, {
                          ['-isChecked']: !!deletableFilesId.includes(file.id),
                          ['-isLast']: isLast,
                        })}
                      >
                        <div className={`${themeClass}_leftContent`}>
                          <div className={cn(`checkBoxContainer`, { ['-mobile']: isMobile })}>
                            <CheckBox
                              isMobile={isMobile}
                              btnClassName={'checkbox'}
                              checked={!!deletableFilesId.includes(file.id)}
                              onClick={() => handleCheckFileToDelete(file)}
                            />
                          </div>
                          <span className={'icon'}>
                            {renderIcon(file?.ext || file?.name?.split('.')?.[file?.name?.split('.')?.length - 1])}
                          </span>
                          <a
                            href={`${location.pathname}?${selectedRequest?.nf ? `nf=${selectedRequest?.nf}&` : ''}fileid=${file?.id || ''}`}
                            onClick={e => openPrevFile(file, e)}
                            className={`${themeClass}_verticalFileInfo`}
                          >
                            <CustomTooltip
                              enterDelay={300}
                              leaveDelay={0}
                              title={file.name || file.title || ''}
                              placement={'top'}
                              customBasisClass={`${themeClass}_verticalFileName`}
                            >
                              <span className={`${themeClass}_verticalFileName`}>{file.name || file.title}</span>
                            </CustomTooltip>
                          </a>
                        </div>

                        {!isMobile && isSubmittalCard ? (
                          <div className={`${themeClass}_verticalFileType`}>
                            <span className={'weight'}>{getFileSizeName(file.size)}</span>
                          </div>
                        ) : null}

                        <div className={`${themeClass}_rightContent`}>
                          {!isMobile && !isSubmittalCard ? (
                            <div className={`${themeClass}_verticalFileType`}>
                              <span className={'weight'}>{getFileSizeName(file.size)}</span>
                            </div>
                          ) : null}
                          {fileLoading && loadingFilesName?.includes(file.name || file.title) ? (
                            <div className={'downloadBoxLoading'}>
                              <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                            </div>
                          ) : fileLoaded && fileName === file.title ? (
                            <div className={'downloadBoxLoading'}>
                              <MiniLoader size={isMobile ? 'lg' : 'sm'} circleClasses={`${themeClass}_loader_circle`} />
                            </div>
                          ) : null}
                          {isMobile && !fileLoading && !fileLoaded ? (
                            <OverflowMenuButton clickHandler={e => handleOpenOverflowFileMenu(e, file)} size={'md'} />
                          ) : null}
                          {!isMobile && !fileLoading && !fileLoaded ? (
                            <a className={'downloadBox'} target="_blank" rel="noopener noreferrer">
                              <SystemButton
                                type={'download'}
                                variant={'transparent'}
                                size={'md'}
                                isMobile={isMobile}
                                clickHandler={() => fileHandler(file.url, file.title)}
                              />
                            </a>
                          ) : null}
                          {!isMobile &&
                            (file.name || file.title) &&
                            !disabled &&
                            !loadingFilesName?.includes(file.name) &&
                            !(fileLoaded && fileName === file.title) && (
                              <a className={'removeBox'} target="_blank" rel="noopener noreferrer">
                                <SystemButton
                                  type={'delete'}
                                  variant={'transparent'}
                                  size={'md'}
                                  isMobile={isMobile}
                                  clickHandler={() => handleRemovePopupOpen(file)}
                                />
                                {fileDelete && (fileName === file.title || fileName === file.name) && (
                                  <MiniLoader size={'md'} circleClasses={`${themeClass}_loader_circle`} />
                                )}
                              </a>
                            )}
                        </div>
                      </li>
                    );
                  })
              : null}
          </ul>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          ['isFiles']: files.length > 0,
          ['-mobile']: isMobile,
          [`${themeClass}_horizontalListTab`]: type === 'horizontalList',
        })}
      >
        {files.length && type === 'horizontalList' && !isThumbnailView && showAccordion ? (
          <AccordionFeedTab accordionOpened={showList} openAccordion={handleOpenAccordion} count={files.length} type={'attachment'} />
        ) : null}
        {showList || !showAccordion ? renderList() : null}
      </div>
      <DialogPopUp
        open={isOpenRemovePopup}
        onClose={handleRemovePopupClose}
        title={'Delete this attachment?'}
        hideBackdrop={true}
        maxWidth={'xs'}
        handleOnSecondary={handleRemovePopupClose}
        secondaryText={'Cancel'}
        handleOnPrimary={() => {
          deletableFile?.name && !deletableFile.title
            ? delFileProgress(deletableFile, deletableFile.name)
            : delSavedFileProgress(deletableFile, deletableFile?.title, isDeleteCheckedItems);
        }}
        primaryText={'Yes, delete'}
        modalText={'This file will be deleted and you won`t be able to recover it.'}
        loadingOnPrimary={fileDelete}
      />
      <Popover
        id={'discipline-role'}
        open={!!overflowFileMenuAnchor}
        anchorEl={overflowFileMenuAnchor}
        onClose={handleCloseOverflowFileMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div className={`${themeClass}_overflowFileMenuContent`}>
          <div className={`${themeClass}_overflowFileMenuContent_details`}>
            <span className={`${themeClass}_overflowFileMenuContent_text`}>
              {`${getFileSizeName(overflowMenuFileData?.size) || ''} | ${moment(overflowMenuFileData?.lastModifiedDate).format(
                'MM/DD/YYYY',
              )}`}
            </span>
          </div>
          <Divider direction={'horizontal'} type={'srf-5'} />
          <div className={`${themeClass}_overflowFileMenuContent_controls`}>
            <div
              className={`${themeClass}_overflowFileMenuContent_controls_item`}
              onClick={() => fileHandler(overflowMenuFileData.url, overflowMenuFileData.title)}
            >
              <TokenIcon iconName={'download'} size={20} />
              <span className={`${themeClass}_overflowFileMenuContent_text`}>Download</span>
            </div>
            <div
              className={`${themeClass}_overflowFileMenuContent_controls_item`}
              onClick={() => handleRemovePopupOpen(overflowMenuFileData)}
            >
              <TokenIcon iconName={'trash'} size={20} />
              <span className={`${themeClass}_overflowFileMenuContent_text`}>Delete</span>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

const { openPdftron } = commonSlice.actions;

export default FilesListViewTokens;
