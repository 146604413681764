import Update_20211125_1 from './Updates_2021-11-25/Update_20211125_1';
import Update_20211125_2 from './Updates_2021-11-25/Update_20211125_2';
import Update_20211125_7 from './Updates_2021-11-25/Update_20211125_7';
import Update_20211125_6 from './Updates_2021-11-25/Update_20211125_6';
import Update_20211125_8 from './Updates_2021-11-25/Update_20211125_8';
import Update_20211125_9 from './Updates_2021-11-25/Update_20211125_9';
import Update_20211125_11 from './Updates_2021-11-25/Update_20211125_11';
import React from 'react';
import SVG from 'react-inlinesvg';
import Update_20220119_1 from './Updates_2022-01-19/Update_20220119_1';
import Update_20220119_2 from './Updates_2022-01-19/Update_20220119_2';
import Update_20220119_3 from './Updates_2022-01-19/Update_20220119_3';
import Update_20220119_4 from './Updates_2022-01-19/Update_20220119_4';
import Update_20220119_5 from './Updates_2022-01-19/Update_20220119_5';
import Update_20220217_1 from './Updates_2022-02-17/Update_20220217_1';
import Update_20220217_2 from './Updates_2022-02-17/Update_20220217_2';
import Update_20220217_3 from './Updates_2022-02-17/Update_20220217_3';
import Update_20220217_4 from './Updates_2022-02-17/Update_20220217_4';
import Update_20220217_5 from './Updates_2022-02-17/Update_20220217_5';
import Update_20220420_1 from './Updates_2022-04-20/Update_20220420_1';
import Update_20220420_2 from './Updates_2022-04-20/Update_20220420_2';
import Update_20220420_3 from './Updates_2022-04-20/Update_20220420_3';
import Update_20220420_4 from './Updates_2022-04-20/Update_20220420_4';
import Update_20220531_1 from './Updates_2022-05-31/Update_20220531_1';
import Update_20220531_2 from './Updates_2022-05-31/Update_20220531_2';
import Update_20220531_3 from './Updates_2022-05-31/Update_20220531_3';
import Update_20220531_4 from './Updates_2022-05-31/Update_20220531_4';
import Update_20220922_1 from './Updates_2022-09-22/Update_20220922_1';
import Update_20220922_2 from './Updates_2022-09-22/Update_20220922_2';
import Update_20220922_3 from './Updates_2022-09-22/Update_20220922_3';
import Update_20220922_4 from './Updates_2022-09-22/Update_20220922_4';
import Update_20230306_1 from './Updates_2023-03-06/Update_20230306_1';
import Update_20230306_2 from './Updates_2023-03-06/Update_20230306_2';
import Update_20230306_3 from './Updates_2023-03-06/Update_20230306_3';
import Updates_20230427_1 from './Updates_2023-04-27/Updates_20230427_1';
import Updates_20230427_2 from './Updates_2023-04-27/Updates_20230427_2';
import Updates_20230427_3 from './Updates_2023-04-27/Updates_20230427_3';
import Updates_20230427_4 from './Updates_2023-04-27/Updates_20230427_4';
import Updates_20230427_5 from './Updates_2023-04-27/Updates_20230427_5';
import Updates_20230427_6 from './Updates_2023-04-27/Updates_20230427_6';
import Updates_20230713_1 from './Updates_2023-07-13/Updates_20230713_1';
import Updates_20230713_2 from './Updates_2023-07-13/Updates_20230713_2';
import Updates_20230713_3 from './Updates_2023-07-13/Updates_20230713_3';
import Updates_20230713_4 from './Updates_2023-07-13/Updates_20230713_4';

const whatsNew = [
  {
    id: 0,
    notification_id: 64,
    date: '2024-04-02',
    title: '🎛️ Command center',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
          💨Flow
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          Revamped interface of former ‘Sandbox’ that automatically transforms incoming information in actionable items for all: NFs, CL
          drawings and Input/Output submittals, and helps to keep ball in the air at all times.
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
          🔥Hotlist
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          Hotlist becomes a separate widget to show items that are becoming urgent based on critical dates. Along with automated logic,
          users can mark items as ‘hot’ manually.
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
          🌡️Heatmap
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          Calendars will highlight ‘busy’ days, when you or your company has a lot of items in court. Now includes submittals.
        </div>
      </div>
    ),
  },
  {
    id: 1,
    notification_id: 63,
    date: '2024-02-28',
    title: '⭐ User roles simplification',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          We have simplified the user roles to streamline the user experience and improve the clarity and understanding of the user roles
          available in the application. From now on, you will be associated with one of the following user roles:
        </div>
        <div className={'paragraph'}>
          <ul>
            <li style={{ listStyle: 'disc' }}>
              Admin (applies to former{' '}
              <i>
                <u>Project Admins</u>
              </i>
              )
            </li>
            <li style={{ listStyle: 'disc' }}>
              Manager (applies to former{' '}
              <i>
                <u>Workflow Managers</u>
              </i>
              )
            </li>
            <li style={{ listStyle: 'disc' }}>
              User (applies to former{' '}
              <i>
                <u>Company Users</u>
              </i>{' '}
              and{' '}
              <i>
                <u>Company Managers</u>
              </i>
              ; the <i>Company Manager</i> permission level has been retained)
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    id: 2,
    notification_id: 62,
    date: '2024-01-25',
    title: '🔃 Input & Output data modules',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          Experience a new approach to the Plan & Control process that splits the flow of information within the application into two parts.
        </div>
        <div className={'paragraph'}>
          <ul>
            <li style={{ listStyle: 'disc' }}>
              <div>
                🟦 <b>Input data</b>
              </div>
              <div>Easily track Drawings, Specifications, and Input Submittals for accurate input data management.</div>
            </li>
            <li style={{ listStyle: 'disc' }}>
              <div>
                🟧 <b>Output data</b>
              </div>
              <div>
                Conventional Deliverables are now accompanied by a new submodule for shop drawings management, called Output Submittals.
              </div>
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    notification_id: 61,
    date: '2024-01-25',
    title: '⚙️ Validation engine',
    text: (
      <div className={'paragraph'}>
        We’re streamlining your workflow by introducing the <b>Related items</b> tab - a unified interface within the Need Form, Deliverable
        form, and Submittal form. This feature replaces the&nbsp;
        <span style={{ textDecoration: 'line-through' }}>
          <i>Linked NFs</i>
        </span>{' '}
        and&nbsp;
        <span style={{ textDecoration: 'line-through' }}>
          <i>Related deliverables</i>
        </span>{' '}
        tabs, and provides a central hub for efficiently managing the links between these entities.
      </div>
    ),
  },
  {
    id: 4,
    notification_id: 60,
    date: '2024-01-25',
    title: '🔀 Deliverables sequence',
    text: (
      <div className={'paragraph'}>
        Rediscover the way you manage relationships between Deliverables. Experience the power of setting up the predecessor/successor
        relations for your output documents. Whenever information in a preceding deliverable changes, you will be triggered to validate the
        subsequent deliverables. This feature leaves no room for oversights and ensures a seamless, robust process.
      </div>
    ),
  },
  {
    id: 5,
    notification_id: 59,
    date: '2024-01-25',
    title: '🖼️ Drawings (formerly Design Documents) statuses',
    text: (
      <div className={'paragraph'}>
        Embark on a more user-friendly journey with the refined approach to statuses that reflect the validity of Need Form - Drawing
        relations. Plus, the process of uploading and publishing new drawing sets has never been smoother!
      </div>
    ),
  },
  {
    id: 6,
    notification_id: 58,
    date: '2024-01-25',
    title: '✒️ ‘Sandbox’ becomes ‘Command center’',
    text: (
      <div className={'paragraph'}>
        Experience a cosmetic transformation as we rebrand{' '}
        <span style={{ textDecoration: 'line-through' }}>
          <i>Sandbox</i>
        </span>{' '}
        to <b>Command center</b>, setting the stage for upcoming exciting updates to come. Soon, you can expect more widgets, a cleaner
        interface, and a reflection of the Submittals tracking process. Stay tuned for an even more personalized and purpose-driven
        workspace that will enhance your overall experience and daily work.
      </div>
    ),
  },
  {
    id: 7,
    notification_id: 57,
    date: '2024-01-25',
    title: '🚧 Old User Interface shutdown',
    text: (
      <div className={'paragraph'}>
        As announced, we are bidding farewell to the old Planit Scheduler user interface. We appreciate your understanding and we’re
        confident that the new UI will exceed your expectations. Embrace this change for a more streamlined and intuitive experience that
        improves your overall interaction with our platform!
      </div>
    ),
  },
  {
    id: 8,
    notification_id: 56,
    date: '2024-01-25',
    title: '🏗️ Add-in for Autodesk Revit',
    text: (
      <div className={'paragraph'}>
        Unleash a world of possibilities with the groundbreaking Planit Scheduler add-in for Autodesk Revit software! Seamless integration
        that links model geometry directly to specific Need Forms within our application takes the project workflow to new heights. Ready to
        be amazed? Contact us directly to schedule a demo session and learn more about this truly sensational feature! ✨
      </div>
    ),
  },
  {
    id: 9,
    notification_id: 55,
    date: '2023-11-04',
    title: '🎨 Redesigned User Interface',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          Discover a completely new, elegant and user-friendly Planit Scheduler interface that simplifies navigation through the app, making
          your work more efficient and enjoyable. We also switched to a Light color scheme as default one.
        </div>
      </div>
    ),
  },
  {
    id: 10,
    notification_id: 54,
    date: '2023-11-04',
    title: '🔀 Seamless transition',
    text: (
      <div className={'paragraph'}>
        We encourage you to explore the enhanced application UI, but we understand that change can sometimes be met with hesitation. That’s
        why we’re giving you the option to switch back to the old interface for a limited time to ensure a smooth transition.
      </div>
    ),
  },
  {
    id: 11,
    notification_id: 53,
    date: '2023-11-04',
    title: '🔜 Features evolving',
    text: (
      <div className={'paragraph'}>
        As a part of this update, some features have evolved as well. As a result, you won’t be able to use them in the old interface any
        more. To access them, you will need to switch to the new design.
        <br />
        Please note that certain modules, such as Phase Plan and Whiteboard, have not yet been migrated to the new layout. They will remain
        available in the old interface for some time.
      </div>
    ),
  },
  {
    id: 12,
    notification_id: 52,
    date: '2023-07-13',
    title: '1. Merged ‘Discussion and history’ tab',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          With this update, the <b>‘Discussion and history’</b> tab becomes your go-to hub for all peer-to-peer communication and history
          tracking. All conversations and chronological updates have been gathered in one convenient location to allow you to easily access
          all the information you need.
        </div>
      </div>
    ),
    component: <Updates_20230713_1 />,
    isComponentOpen: true,
  },
  {
    id: 13,
    notification_id: 51,
    date: '2023-07-13',
    title: '2. Discussion tab in Sandbox',
    text: (
      <div className={'paragraph'}>
        Our application is built on the foundation of collaboration. Bearing this in mind, we are pleased to announce a dedicated place
        within your Sandbox that allows you to conveniently locate all the items in which you are involved in discussions.
      </div>
    ),
    component: <Updates_20230713_2 />,
    isComponentOpen: true,
  },
  {
    id: 14,
    notification_id: 50,
    date: '2023-07-13',
    title: '3. Daily company summary email',
    text: (
      <div className={'paragraph'}>
        We have updated the user notification settings to allow you to tailor them to meet your needs. If you feel overwhelmed by being
        informed about every single action and update, you can turn off the <b>Instant emails</b> option and tick the{' '}
        <b>Daily company report</b> checkbox. On a daily basis, you will receive a comprehensive report summarizing all the important
        actions and activities within your company’s area of interest.
      </div>
    ),
    component: <Updates_20230713_3 />,
    isComponentOpen: true,
  },
  {
    id: 15,
    notification_id: 49,
    date: '2023-07-13',
    title: '4. Deliverables reports available',
    text: (
      <div className={'paragraph'}>
        It is important to be able to access and process the data outside of Scheduler. Were glad to announce that we prepared new reports
        you can export as PDF/XLS files directly from the Deliverables module:
        <br />
        <ul>
          <li style={{ listStyle: 'disc' }}>
            <b>Deliverables Dates Tracker</b> 📅
            <br />
            See the Deliverables’ release schedule. Compare the dates, see the big picture and plan ahead
          </li>
          <li style={{ listStyle: 'disc' }}>
            <b>Deliverables Review Tracker</b> ✍
            <br />
            Check the review status of the Deliverables. See who has already reviewed them, and who’s lagging behind.
          </li>
          <li style={{ listStyle: 'disc' }}>
            <b>Outstanding NFs per Deliverables</b> 🚨
            <br />
            Get a list of the companies responsible for unresolved Need Forms that interfere with the Deliverables.
          </li>
        </ul>
      </div>
    ),
    component: <Updates_20230713_4 />,
    isComponentOpen: true,
  },
  {
    id: 16,
    notification_id: 48,
    date: '2023-04-27',
    title: (
      <>
        We are excited to announce the release of our latest update, which brings several exciting changes to Scheduler App. We have
        listened to your feedback and worked hard to improve our product to meet your needs. 🚀 You can read through the changelog to get a
        more detailed understanding of the updates we have made.
        <br />
        <br />
        1. 🛠️ Revamped Sandbox module
      </>
    ),
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          We have completely rebuilt the Sandbox module to make it easier to plan & control construction. Our zero-inbox approach allows you
          to focus on what’s most important at any given time. Pre-defined Sandbox groups make it easy to prioritize tasks and always stay
          on top of them. The key groups to focus on are:
        </div>
        <div className={'paragraph'}>
          - 🔥<b>Hotlist</b> - lists NFs/Deliverables that require your immediate attention because the due dates are approaching,
        </div>
        <div className={'paragraph'}>
          - 🏀<b>In my court</b> - lists NFs/Deliverables that require your action based on the workflow. Remember that items in this group
          will become hot one day if you sit on them.
        </div>
        <div className={'paragraph'}>
          🕵️‍♂️ If you are interested in getting into the details, please read through the&nbsp;
          <a
            href={'https://docs.google.com/document/d/1bN6AwjQx0T3Nopvy-GgNJArqcMAizIRwJpkSSp3N_xg/edit?usp=sharing'}
            target={'_blank'}
            rel="noreferrer"
          >
            <span style={{ color: '#1376c8', textDecoration: 'underline' }}>Sandbox documentation</span>
          </a>
          .
        </div>
      </div>
    ),
    component: <Updates_20230427_1 />,
    isComponentOpen: true,
  },
  {
    id: 17,
    notification_id: 47,
    date: '2023-04-27',
    title: '2. 📊 Sandbox widgets',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          We have also added two Sandbox widgets to make your life even easier. From now on, you can access:
        </div>
        <div className={'paragraph'}>
          - a <b>feed</b> 📋 to remind you of important events,
        </div>
        <div className={'paragraph'}>
          - a <b>calendar heatmap</b> 📅 to help you spot trends and patterns in your incoming Need Forms and outgoing Deliverables.
        </div>
      </div>
    ),
    component: <Updates_20230427_2 />,
    isComponentOpen: true,
  },
  {
    id: 18,
    notification_id: 46,
    date: '2023-04-27',
    title: '3. 📈 Updated reporting',
    text: (
      <div className={'paragraph'}>
        The Reporting module has also been updated to help you keep your project statistics relevant and up-to-date. We now include both
        Needs Forms and Deliverables data when calculating the Project Performance Index (PPI), allowing you to make informed decisions and
        optimize the management process.
      </div>
    ),
    component: <Updates_20230427_3 />,
    isComponentOpen: true,
  },
  {
    id: 19,
    notification_id: 45,
    date: '2023-04-27',
    title: '4. 💬 Use comment as NF response',
    text: (
      <div className={'paragraph'}>
        You can now use discussion’s comments as responses to the Need Form. If you’re discussing the resolution of an NF and someone’s
        comment contains an answer, you can now use it as a response. Just tick the checkbox and submit the response as usual.
      </div>
    ),
    component: <Updates_20230427_4 />,
    isComponentOpen: true,
  },
  {
    id: 20,
    notification_id: 44,
    date: '2023-04-27',
    title: '5. ➡️ Right-side NF card layout',
    text: (
      <div className={'paragraph'}>
        We have also improved the user experience by introducing the right side layout of the Need Form card. You can now lock it to the
        right side of the window and leave it open while you work with other modules in the background. Even if you navigate to another
        module, you will still see the Need Form card.
      </div>
    ),
    component: <Updates_20230427_5 />,
    isComponentOpen: true,
  },
  {
    id: 21,
    notification_id: 43,
    date: '2023-04-27',
    title: '6. 📦 Open Deliverable from NF card',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          You ask, we deliver! 🤝 You can now open the Deliverable card directly from the Need Form card interface.
        </div>
      </div>
    ),
    component: <Updates_20230427_6 />,
    isComponentOpen: true,
  },
  {
    id: 22,
    notification_id: 40,
    date: '2023-03-06',
    title: (
      <>
        The release several major UX improvements:
        <br />
        <br />
        1. Updated Commitment Process
      </>
    ),
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          Commitment process is a crucial part of pull planning. But reports show that it was underutilized or misused in many cases, so the
          decision made is to simplify it as much as possible.
        </div>
        <div className={'paragraph'}>
          Just click the <b>Commit</b> button to see the list of commitment options:
        </div>
      </div>
    ),
    component: <Update_20230306_1 />,
    isComponentOpen: true,
  },
  {
    id: 23,
    notification_id: 41,
    date: '2023-03-06',
    title: '2. NF versioning when declined',
    text: (
      <div className={'paragraph'}>
        <div className={'paragraph'}>
          We’ve improved the process of work with the declined Need Forms. Each time the NF is reopened, a new ‘version’ is created. At any
          time, the previous versions of the request and response can still be previewed if you need to track the history of the NF changes.
        </div>
      </div>
    ),
    component: <Update_20230306_2 />,
    isComponentOpen: true,
  },
  {
    id: 24,
    notification_id: 42,
    date: '2023-03-06',
    title: '3. Deliverable Reviewers’ hierarchy and due dates',
    text: (
      <div className={'paragraph'}>
        You ask, we deliver
        <a target="_blank">
          <img
            src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image8.png"
            alt=""
            style={{ display: 'inline-block' }}
            width="17"
          />
        </a>
        . We’re excited to announce an improved process of deliverables review. Now, there is a possibility to create a review party
        sequence and specify a due date for each review.
      </div>
    ),
    component: <Update_20230306_3 />,
    isComponentOpen: true,
  },
  {
    id: 25,
    notification_id: 36,
    date: '2022-09-22',
    title: 'Rethought ‘Need form’ interface',
    text: (
      <div className={'paragraph'}>
        Considering the users’ behavior and devices that you are working on, we decided to slightly rework the NF interface.
        <br />
        Here is a list of changes:
        <ul>
          <li style={{ listStyle: 'disc' }}>
            The major change is that instead of an accordion expansion list we changed the layout to be tabs ribbon;
          </li>
          <li style={{ listStyle: 'disc' }}>
            The <i>‘Deliverable’</i> tab has been renamed to a more familiar <i>‘Response’;</i>
          </li>
          <li style={{ listStyle: 'disc' }}>
            Start looking for a particular user or company right away in the Request/Reponse party dropdowns, and matching options will be
            automatically suggested;
          </li>
          <li style={{ listStyle: 'disc' }}>
            Options to <i>‘Change a due date’ </i>and <i>‘Redirect request’</i> are now merged with <i>‘Due date calendar’</i> and{' '}
            <i>‘Response company’</i> dropdowns respectively;
          </li>
          <li style={{ listStyle: 'disc' }}>
            Two new multichoice dropdown options: Design Documents and Deliverables appeared on the card;
          </li>
          <li style={{ listStyle: 'disc' }}>Styles and elements layout has been revised.</li>
        </ul>
      </div>
    ),
    component: <Update_20220922_1 />,
    isComponentOpen: true,
  },
  {
    id: 26,
    notification_id: 37,
    date: '2022-09-22',
    title: 'Team module UX improvements',
    text: (
      <div className={'paragraph'}>
        Project team tab migrated to an adjustable grids interface and became more responsive and organized.
        <ul>
          <li style={{ listStyle: 'disc' }}>
            The company setup now opens in a separate pop-over window and provides two lists of users: <i>‘All members’</i> and{' '}
            <i>‘Members of this project’</i>.
          </li>
          <li style={{ listStyle: 'disc' }}>The team tab now includes a search and filtering for a quicker navigation.</li>
          <li style={{ listStyle: 'disc' }}>
            Besides that, we’ve cleaned and unified the database of companies/users across all the system (deleted duplicates, merged
            companies with similar names, etc.).
          </li>
        </ul>
        Users will get separate emails if they’ve been affected by this unification process.
      </div>
    ),
    component: <Update_20220922_2 />,
    isComponentOpen: true,
  },
  {
    id: 27,
    notification_id: 38,
    date: '2022-09-22',
    title: (
      <>
        <a target="_blank">
          <img
            src="https://pmexpd.stripocdn.email/content/guids/CABINET_4131b35ac0c45602499a739bba83b2b0/images/image3.png"
            alt=""
            style={{ display: 'inline-block' }}
            width="17"
          />
        </a>
        <b> Plan &amp; Control deliverables </b>
      </>
    ),
    text: (
      <div className={'paragraph'}>
        Instead of having the need form type ‘CL Drawing’ we introduce a completely new functionality for scheduling and tracking of Plan &
        Control deliverables submission and review status.
        <br />
        The module will be presented for beta testing to certain users and will become available for a broader audience in a few weeks.
      </div>
    ),
    component: <Update_20220922_3 />,
    isComponentOpen: true,
  },
  {
    id: 28,
    notification_id: 39,
    date: '2022-09-22',
    title: (
      <>
        <a target="_blank">
          <img
            src="https://pmexpd.stripocdn.email/content/guids/CABINET_4131b35ac0c45602499a739bba83b2b0/images/image3.png"
            alt=""
            style={{ display: 'inline-block' }}
            width="17"
          />
        </a>
        <b> Design documents log</b>
      </>
    ),
    text: (
      <div className={'paragraph'}>
        Introducing an organized way to track the project input information by listing the contents of each incoming DD set and linking it
        to the Need forms and P&amp;C deliverables. This will allow to check the validity of Requests/Responses and adjust the project flow
        by analyzing the impact of all the incoming information on output contents and schedules.
        <br />
        The module will be presented for beta testing to certain users and will become available for a broader audience in a few weeks.
      </div>
    ),
    component: <Update_20220922_4 />,
    isComponentOpen: true,
  },
  {
    id: 29,
    notification_id: 31,
    date: '2022-05-31',
    title: 'Need form breadcrumbs',
    text: (
      <div className={'paragraph'}>
        The Lean process requires creation of predecessor-successor ties between the NFs. For better readability of switching between NFs
        through the ‘Linked NFs’ block we introduce the updated ‘breadcrumbs’.
      </div>
    ),
    component: <Update_20220531_1 />,
    isComponentOpen: true,
  },
  {
    id: 30,
    notification_id: 32,
    date: '2022-05-31',
    title: 'User profile improvements',
    text: (
      <div className={'paragraph'}>
        User profile is now a full page, and also allows you to adjust the profile photo by cropping, scaling, rotating and padding it,
        however we still recommend uploading a proportional image (e.g. 500x500px) for proper placing and appearance. There are more
        settings for user profiles coming soon.
      </div>
    ),
    component: <Update_20220531_2 />,
    isComponentOpen: true,
  },
  {
    id: 31,
    notification_id: 33,
    date: '2022-05-31',
    title: 'Project-specific notification settings',
    text: (
      <div className={'paragraph'}>
        Depending on the stage of the project and user role in it, there is a need to increase or decrease the number of incoming
        email-notifications. Thus, we introduced the new feature to set up the notification settings for particular projects only.
      </div>
    ),
    component: <Update_20220531_3 />,
    isComponentOpen: true,
  },
  {
    id: 32,
    notification_id: 34,
    date: '2022-05-31',
    title: 'Sorting, Grouping, Filtering of the Needs List/Sandbox',
    text: (
      <div className={'paragraph'}>
        For your convenience we’ve created a set of sorting options that is available for Needs list and Sandbox. The grouping is only
        available for the Needs list.
      </div>
    ),
    component: <Update_20220531_4 />,
    isComponentOpen: true,
  },
  {
    id: 33,
    notification_id: 35,
    date: '2022-05-31',
    title: 'Minor fixes and improvements:',
    text: (
      <div className={'paragraph'}>
        <ul>
          <li style={{ listStyle: 'disc' }}>
            Reporting module calculations enhancement; (Please see <u>detailed description</u> in the Help menu);
          </li>
          <li style={{ listStyle: 'disc' }}>Automatic cleaning of app data cached in browser;</li>
          <li style={{ listStyle: 'disc' }}>Ability to change a due dates of linked NFs;</li>
          <li style={{ listStyle: 'disc' }}>Some action loaders changes;</li>
          <li style={{ listStyle: 'disc' }}>Sandbox ‘placeholder’ email;</li>
          <li style={{ listStyle: 'disc' }}>Updated version of PDF annotation tool.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 34,
    notification_id: 26,
    date: '2022-04-20',
    title: 'Reporting module update',
    text: (
      <div className={'paragraph'}>
        Any data powered platform should have a comprehensive reporting and analytics module, we realized that ours was a bit clumpy and
        decided to fix that :-). There is some math behind those parameters that we think should be useful, however any feedback is welcomed
        and we will tweak the reporting stats as needed so that you can keep your project decision making data-wise!
      </div>
    ),
    component: <Update_20220420_1 />,
    isComponentOpen: true,
  },
  {
    id: 35,
    notification_id: 27,
    date: '2022-04-20',
    title: (
      <>
        <b>‘Comments’</b>
        <img
          style={{ width: '13px', margin: '0 5px' }}
          src={'https://pmexpd.stripocdn.email/content/guids/CABINET_2bc82e15e7d13e73b4f2293d8d02785f/images/image9.png'}
          alt=""
        />
        <b>‘Discussion’ (w/ ‘Ball-in-Court)</b>
      </>
    ),
    text: (
      <div className={'paragraph'}>
        ‘Comments’ block of the NF card becomes ‘Discussion’. Beside the commenting function it will also have ‘Ball-in-court’ feature, that
        will allow to push an NF item with a call to action into someone's court at any given point of time.
      </div>
    ),
    component: <Update_20220420_2 />,
    isComponentOpen: true,
  },
  {
    id: 36,
    notification_id: 28,
    date: '2022-04-20',
    title: 'Needs list grouping',
    text: (
      <div className={'paragraph'}>
        There is a new tool for data organization on the Needs list. Users are now able to group the NFs by major parameters and along with
        filtering it brings more visibility for your information queries.
      </div>
    ),
    component: <Update_20220420_3 />,
    isComponentOpen: true,
  },
  {
    id: 37,
    notification_id: 29,
    date: '2022-04-20',
    title: '#hashtags interaction improvements',
    text: (
      <div className={'paragraph'}>
        Labeling NFs with a #hashtag is a frequently used functionality. We’ve improved the process a bit by allowing users to change the
        color of hashtags and adding a hyperlink to them. To enter a #hashtag edit menu, simply double click on created ones.
      </div>
    ),
    component: <Update_20220420_4 />,
    isComponentOpen: true,
  },
  {
    id: 38,
    notification_id: 30,
    date: '2022-04-20',
    title: 'Minor fixes and improvements:',
    text: (
      <div className={'paragraph'}>
        <ul>
          <li style={{ listStyle: 'disc' }}>Projects tab UX improvements and Grid view;</li>
          <li style={{ listStyle: 'disc' }}>File attachments are now hyperlinks;</li>
          <li style={{ listStyle: 'disc' }}>NFs in the needs list are now hyperlinks;</li>
          <li style={{ listStyle: 'disc' }}>New filter for ‘Watchers’;</li>
          <li style={{ listStyle: 'disc' }}>‘OR’ condition for filters;</li>
          <li style={{ listStyle: 'disc' }}>New starting screen and project chooser;</li>
          <li style={{ listStyle: 'disc' }}>Update for Masterformat field;</li>
          <li style={{ listStyle: 'disc' }}>Hierarchical filters for Uniformat and Masterformat;</li>
          <li style={{ listStyle: 'disc' }}>Response decline counters.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 39,
    notification_id: 19,
    date: '2022-02-17',
    title: 'Content plan and Uniformat input updates',
    text: (
      <div className={'paragraph'}>
        We do know that many of you have been questioning on how to utilize the UniFormat input field when it is needed to be filled and by
        whom. We simplified the process shortening the list of UF items suited only to current projects that will allow additional control
        over the communication flow and design progression.
      </div>
    ),
    component: <Update_20220217_1 />,
    isComponentOpen: true,
  },
  {
    id: 40,
    notification_id: 20,
    date: '2022-02-17',
    title: 'Action loaders UI improvements',
    text: (
      <div className={'paragraph'}>
        We’ve changed circular loaders to linear ones in order to bring better readability and more pleasant user experience. Also, action
        buttons are now supported with additional animations to report that the user made an action he intended.
      </div>
    ),
    component: <Update_20220217_2 />,
    isComponentOpen: true,
  },
  {
    id: 41,
    notification_id: 21,
    date: '2022-02-17',
    title: 'Action prompts & tooltips',
    text: (
      <div className={'paragraph'}>
        The Planit App is a process oriented software that may look a bit busy from the first glance, so we’ve decided to implement prompt
        notifications and tooltips that will help to understand the workflow and basic functionality quicker, especially for new users.
      </div>
    ),
    component: <Update_20220217_3 />,
    isComponentOpen: true,
  },
  {
    id: 42,
    notification_id: 22,
    date: '2022-02-17',
    title: 'Data imports for building spaces',
    text: (
      <div className={'paragraph'}>
        The process of adjusting of the specific locations is now fully customizable so project administrators can fully match the naming of
        building spaces and programm areas to exact project needs.
      </div>
    ),
    component: <Update_20220217_4 />,
    isComponentOpen: true,
  },
  {
    id: 43,
    notification_id: 23,
    date: '2022-02-17',
    title: 'Integration with Procore RFI module',
    text: (
      <div className={'paragraph'}>
        There are some projects that require maintenance of multiple communication platforms due to project teams setup and established
        procedures. We are introducing a direct synchronization of NF creation with an RFI tool in Procore that will help to simplify an
        information exchange between two systems. Some additional adjustments should be made in order to establish connection, so if you’d
        like to make it happen please contact us so we can share instructions and support you in this way.
      </div>
    ),
    component: <Update_20220217_5 />,
    isComponentOpen: true,
  },
  {
    id: 44,
    notification_id: 24,
    date: '2022-02-17',
    title: 'Some additional improvements:',
    text: (
      <div className={'paragraph'}>
        <ul>
          <li style={{ listStyle: 'disc' }}>Introducing ‘Activity’ menu on mobile devices and tablets;</li>
          <li style={{ listStyle: 'disc' }}>PDF viewer enhancement for mobile devices and tablets;</li>
          <li style={{ listStyle: 'disc' }}>Additional filters for ‘Incorporation’ tab on the Needs list and Sandbox;</li>
          <li style={{ listStyle: 'disc' }}>‘Archive email’ for deleted Need forms;</li>
          <li style={{ listStyle: 'disc' }}>Autofiltered ‘Buildings’ - ‘Levels’ - ‘Building spaces’ on the Need form;</li>
        </ul>
      </div>
    ),
  },
  {
    id: 45,
    notification_id: 25,
    date: '2022-02-17',
    title: 'Miscellaneous bug fixes:',
    text: (
      <div className={'paragraph'}>
        <ul>
          <li style={{ listStyle: 'disc' }}>Sandbox counters are now showing correct numbers;</li>
          <li style={{ listStyle: 'disc' }}>Attachment links in the email notifications are no longer expiring;</li>
          <li style={{ listStyle: 'disc' }}>Multiple WebSockets issues are now fixed for NF statuses, counters, etc.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 46,
    notification_id: 12,
    date: '2022-01-19',
    title: 'Responsive design',
    text: (
      <div className={'paragraph'}>
        One of the most requested features last year was to provide an ability to interact with a platform on mobile devices. While we are
        working on a separate mobile app, please enjoy the possibility of working with your Need forms on mobile and tablet browsers.
      </div>
    ),
    component: <Update_20220119_1 />,
    isComponentOpen: true,
  },
  {
    id: 47,
    notification_id: 13,
    date: '2022-01-19',
    title: 'Data imports for Needs list',
    text: (
      <div className={'paragraph'}>
        This is something we are proud of the most! Project admins are now able to import an unlimited number of NFs from any external
        sources, just by shaping the existing data within a special .xlsx template. Guess what, this will allow to transfer existing
        communication to Scheduler in minutes, and means no more multiple sources of truth and tracking multiple platforms.
      </div>
    ),
    component: <Update_20220119_2 />,
    isComponentOpen: true,
  },
  {
    id: 48,
    notification_id: 14,
    date: '2022-01-19',
    title: 'Activity thread',
    text: (
      <div className={'paragraph'}>
        We’ve rethought our ‘Notifications’ menu and it now became an ‘Activity’. This is a great tool for managers to check project team
        members’ performance or recall an information flow on a certain project at any point of time.
      </div>
    ),
    component: <Update_20220119_3 />,
    isComponentOpen: true,
  },
  {
    id: 49,
    notification_id: 15,
    date: '2022-01-19',
    title: 'NF deactivation',
    text: (
      <div className={'paragraph'}>
        Sometimes NFs become outdated or not-valid due to constant changes on the project. We are introducing the new feature that will
        allow NF authors to deactivate their NFs but not delete them so they will remain listed for record needs.
      </div>
    ),
    component: <Update_20220119_4 />,
    isComponentOpen: true,
  },
  {
    id: 50,
    notification_id: 16,
    date: '2022-01-19',
    title: 'Multiple browser tabs with multiple projects',
    text: (
      <div className={'paragraph'}>
        We hear you! 😊 From now and on, you can open as many tabs with different projects as needed. Thanks to an updated project tree
        structure there is no risk to accidentally mix the data between the projects.
      </div>
    ),
  },
  {
    id: 51,
    notification_id: 17,
    date: '2022-01-19',
    title: 'Commenting on Draft NFs',
    text: (
      <div className={'paragraph'}>
        Sometimes you want to start your communication loop over a certain need before the request was actually sent. We have opened a
        ‘Comment’ function for an NF starting from the Draft step.
      </div>
    ),
  },
  {
    id: 52,
    notification_id: 18,
    date: '2022-01-19',
    title: 'Attachments preview resize and drag & drop',
    text: (
      <div className={'paragraph'}>
        Now, you can move and resize a file previewer for your convenience. Simply click on an ‘Resize’ icon in the top right corner to
        adjust a window size, or grab it with a cursor to place anywhere on a screen.
      </div>
    ),
    component: <Update_20220119_5 />,
    isComponentOpen: true,
  },
  {
    id: 53,
    notification_id: 1,
    date: '2021-10-21',
    title: 'Brand New Help Menu',
    text: (
      <div className={'paragraph'}>
        After weeks of thorough preparations, we’re ready to release the long-awaited Help module explaining the features of Planit
        Scheduler. Access it anytime by clicking on the question mark{' '}
        <SVG className={'icon'} src={require('../../../../../assets/icons/support.svg')} /> in the top right corner.
      </div>
    ),
    component: <Update_20211125_1 />,
    isComponentOpen: true,
  },
  {
    id: 54,
    notification_id: 2,
    date: '2021-11-25',
    title: 'Enhanced system announcements',
    text: (
      <div className={'paragraph'}>
        Every time an updated version of Scheduler is live, an announcement from our team is displayed. We have decided to display them in a
        less absorbing way - at the bottom of your screen. Moreover, the pop-ups won’t appear again once you close them on any of your
        devices.
      </div>
    ),
    component: <Update_20211125_2 />,
    isComponentOpen: true,
  },
  {
    id: 55,
    notification_id: 3,
    date: '2021-10-21',
    title: 'Request/commitment/response authorship changed',
    text: (
      <div className={'paragraph'}>
        To avoid confusion, we decided that the person who created a request, submitted a commitment, or submitted a deliverable will stay
        as an author of the section regardless of any further changes.
      </div>
    ),
  },
  {
    id: 56,
    notification_id: 4,
    date: '2021-10-21',
    title: 'Request descriptions/Commitments of NFs in ‘Deliverable submitted’ status locked',
    text: (
      <div className={'paragraph'}>
        For archiving purposes, from the next update it won’t be possible to modify the request description or commitments for successfully
        submitted NFs.
      </div>
    ),
  },
  {
    id: 57,
    notification_id: 5,
    date: '2021-10-21',
    title: 'Comments displayed in Needs List',
    text: (
      <div className={'paragraph'}>
        To let you find the required NFs even quicker, the 3 latest comments will be visible in the respective column in Needs List. We’ll
        also add 3 more searching filters applicable for it: <i>Comment date, Comment author, Comment mentions.</i>
      </div>
    ),
  },
  {
    id: 58,
    notification_id: 6,
    date: '2021-10-21',
    title: 'Email notifications redesigned',
    text: (
      <>
        <div className={'paragraph'}>
          <u>Customize notification frequency</u>
        </div>
        <div className={'paragraph'}>
          Tired of being informed too often about every NF update? We’re rolling out the feature to let you choose if or how often email
          notifications are sent to you. To change your settings, simply open “Edit Profile” by clicking your avatar in the top right
          corner.
        </div>
      </>
    ),
    component: <Update_20211125_6 />,
    isComponentOpen: true,
  },
  {
    id: 59,
    notification_id: 7,
    date: '2021-10-21',
    title: 'Additional Request Types',
    text: (
      <div className={'paragraph'}>
        <span>
          To make NF type assessments more precise, "For Record" and "Submittals/Cut Sheets" will join the list of available options for
          your NFs.
        </span>
      </div>
    ),
    component: <Update_20211125_7 />,
  },
  {
    id: 60,
    notification_id: 8,
    date: '2021-10-21',
    title: 'Markup functionality expansion',
    text: (
      <div className={'paragraph'}>
        We’ve added a possibility to insert custom signatures, stamps, images, links and even files to the markups! Choose from a variety of
        predefined annotations or create your own.
      </div>
    ),
    component: <Update_20211125_8 />,
    isComponentOpen: true,
  },
  {
    id: 61,
    notification_id: 9,
    date: '2021-10-21',
    title: 'Saved Filters in Sandbox',
    text: (
      <div className={'paragraph'}>
        From this update the filters (both shared and created by you) could be used not only in the Needs List but also in Sandbox.
      </div>
    ),
    component: <Update_20211125_9 />,
    isComponentOpen: true,
  },
  {
    id: 62,
    notification_id: 10,
    date: '2021-10-21',
    title: 'NF Card download options',
    text: (
      <div className={'paragraph'}>
        Now you can choose whether you want to download only the NF Card or a .zip archive with all the attachments. Additionally, the
        contents of the downloaded archive will have automatically assigned names containing project name and NF number for better
        identification. The same applies for marked-up files created in the document viewer - they will be differentiated from the original
        files.
      </div>
    ),
  },
  {
    id: 63,
    notification_id: 11,
    date: '2021-10-21',
    title: 'New design of the Projects tab',
    text: (
      <div className={'paragraph'}>
        To prepare for the oncoming expansion of project import capabilities, and further customization of settings for each Client, we
        modified the Projects tab a bit. Now it’s possible to switch between companies within the drop-down list as well as display company
        logos.
      </div>
    ),
    component: <Update_20211125_11 />,
    isComponentOpen: true,
  },
];

export default whatsNew;
