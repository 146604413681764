import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import NotificationsTableRow from './NotificationsTableRow/NotificationsTableRow';
import { styles } from './NTableStyle';
import classesStyles from '../NotificationsList.scss';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

class NotificationsTable extends Component {
  state = {
    ref: React.createRef(),
  };

  render() {
    const { classes, items, handleSetSeen, handleShowMore, openedNotification } = this.props;

    let rowCount = 0;
    return (
      <Paper className={classes.root}>
        <Grid container>
          {items &&
            items.map(item => {
              ++rowCount;
              return (
                <div
                  key={item.id}
                  className={`${classesStyles.notificationCard} ${item.is_seen ? '' : classesStyles.unRead}`}
                  onClick={handleSetSeen(item)}
                >
                  <List>
                    <NotificationsTableRow
                      key={item.id}
                      item={item}
                      tableClases={classes}
                      rowCount={rowCount}
                      setSeen={this.props.setSeen}
                      handleMarkRead={this.handleMarkRead}
                      handleShowMore={handleShowMore}
                      openedNotification={openedNotification}
                    />
                  </List>
                </div>
              );
            })}
        </Grid>
      </Paper>
    );
  }
}

NotificationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NotificationsTable.displayName = 'NotificationsTable';

export default withStyles(styles)(NotificationsTable);
