import React from 'react';
import styles from '../WhileBoardStyle.scss';
import moment from 'moment';
import classNames from 'classnames';

class WhiteBoardtableHead extends React.Component {
  state = {
    isPopoverOpen: false,
    headRef: React.createRef(),
    todayRef: React.createRef(),
  };

  componentDidMount() {
    if (this.state.todayRef) {
      setTimeout(() => {
        this.state.todayRef.current?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'center',
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  getIsToday = day => {
    const calendarDay =
      this.props.columns && Object.values(this.props.columns).length && Object.values(this.props.columns).find(item => item.id === day);
    const curDay = moment().format('YYYY-MM-DD');
    return calendarDay?.date === curDay;
  };

  getSelectedMonth = day => {
    const { navigateToMonth } = this.state;
    if (!navigateToMonth) {
      return false;
    }
    const calendarDay =
      this.props.columns && Object.values(this.props.columns).length && Object.values(this.props.columns).find(item => item.id === day);
    const curDay = moment(navigateToMonth).startOf('month').format('YYYY-MM-DD');
    return calendarDay?.date === curDay;
  };

  replaceNumberWeeks = num => {
    const numAlias = {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
      6: '6th',
    };
    return numAlias[num];
  };

  render() {
    const { columnOrder, mode, zoomStyle, onMonth, columns, fullWidth, isNonFullWidth, zoom, rangeElementsLogic, fullTableWidth } =
      this.props;
    const { todayRef, navigateToMonthRef } = this.state;
    const fullWidthClassName = fullWidth ? styles['WhiteBoardCellScroll--fullWidth'] : '';
    const bgDisciplineHead = { backgroundColor: '#242d3f', width: zoomStyle.request.row.width };
    return (
      <>
        <div
          ref={this.state.headRef}
          className={`${styles.WhiteBoardtableRow} WhiteBoardtableRow_sticky`}
          style={{
            bgDisciplineHead,
            // paddingRight: 0,
            paddingLeft: mode !== 'yearMode' ? rangeElementsLogic?.paddingLeft : 0,
            // width: `${fullWidth}px`,
            width: isNonFullWidth ? '100%' : 'auto',
            // display: 'block',
          }}
        >
          <div className={styles.DisciplineHead}></div>

          <div className={classNames(styles.WhiteBoardCellScroll, fullWidthClassName)}>
            {mode === 'yearMode' &&
              columnOrder &&
              columnOrder.map((it, index) => {
                return (
                  <div
                    className={styles.DisciplineCalendarDays}
                    // style={{flex: `1 0 ${this.props.zoomStyle.width}`}}
                    key={`yearModeDate-${it}`}
                    onClick={() => onMonth(index, it)}
                    style={{
                      minWidth:
                        // isNonFullWidth ? 'calc(100% / 12)' :
                        zoomStyle.request.column.minWidth,
                      width:
                        // isNonFullWidth ? 'calc(100% / 12)' :
                        zoomStyle.request.column.width,
                      // minWidth: isNonFullWidth ? '' : zoom === 1 ? zoomStyle.request.column.minWidth * 4 : zoomStyle.request.column.minWidth * 4,
                      // width: isNonFullWidth ? '' : zoom === 1 ? zoomStyle.request.column.minWidth * 4 : zoomStyle.request.column.minWidth * 4,
                    }}
                  >
                    <span className={styles.type}>{it.split('-')[1]}</span>
                  </div>
                );
              })}
            {mode === 'monthMode' &&
              columnOrder &&
              columnOrder.map((item, index) => {
                const i = index + 1;
                if (!(rangeElementsLogic?.rangeElements[0] < i && rangeElementsLogic?.rangeElements[1] > i)) {
                  // return <div style={{minWidth: '49px'}}></div>;

                  return <></>;
                }
                return (
                  <>
                    {/*<div></div>*/}
                    <div
                      ref={this.getIsToday(item) ? todayRef : ''}
                      className={`${styles.DisciplineCalendarDays} ${this.getIsToday(item) ? styles.WhiteBoardDaysToday : ''}`}
                      style={{
                        minWidth: zoomStyle.request.column.minWidth,
                        width: zoomStyle.request.column.width,
                        maxWidth: zoomStyle.request.column.minWidth,
                      }}
                      onClick={() => onMonth(index, item)}
                      key={`monthModeDate-${item}`}
                    >
                      <span className={styles.type}>{`${this.replaceNumberWeeks(item.replace(/.+-/, ''))} week`}</span>
                    </div>
                  </>
                );
              })}
            {mode === 'weekMode' &&
              columnOrder &&
              columnOrder.map((item, index) => {
                const i = index + 1;
                if (!(rangeElementsLogic?.rangeElements[0] < i && rangeElementsLogic?.rangeElements[1] > i)) {
                  // return <div style={{minWidth: '49px'}}></div>;
                  return <></>;
                }
                return (
                  <>
                    {/*<div></div>*/}
                    <div
                      ref={this.getIsToday(item) ? todayRef : ''}
                      className={`${styles.DisciplineCalendarDays} ${this.getIsToday(item) ? styles.WhiteBoardDaysToday : ''}`}
                      style={{
                        minWidth: zoomStyle.request.column.minWidth,
                        width: zoomStyle.request.column.width,
                        maxWidth: zoomStyle.request.column.minWidth,
                      }}
                      key={`monthModeDate-${item}`}
                    >
                      <span className={styles.type}>{`${moment(item).format('ddd')}, ${moment(item).format('D')}`}</span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

WhiteBoardtableHead.displayName = 'WhiteBoardtableHead';

WhiteBoardtableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default WhiteBoardtableHead;
