import React, { Component } from 'react';

class Update_20211125_6 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4f21cde1fe55004e062911616fdba2f9/images/image7.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'}>
          <u>Layout improvements</u>
        </div>
        <div className={'paragraph'}>
          Once again we’ve made a design change in order to make the Scheduler emails look more pleasant. Some system notifications will
          have a brand new appearance.
        </div>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4f21cde1fe55004e062911616fdba2f9/images/image11.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'}>
          <u>Delayed NF card & markup notifications</u>
        </div>
        <div className={'paragraph'}>
          In order to address the large number of notifications about any changes to the NF card and marked up files, we decided to
          aggregate them in a single email notification sent after 5 minutes of the change.
        </div>
      </>
    );
  }
}

export default Update_20211125_6;
