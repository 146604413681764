import React, { FC } from 'react';
import { switchColumns } from '../../NeedListConstants/NeedListConstants';
import cn from 'classnames';
import { CalculatedColumn } from 'react-data-grid';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';

interface IProps {
  column: CalculatedColumn<any>;
  onSort;
  sorting;
  sortingField: string;
  currentPlanitColumn;
}

const BasicHeaderRenderer: FC<IProps> = ({ column, onSort, sorting, sortingField, currentPlanitColumn }) => {
  const themeClass = useGetThemeClass('data-grid-basic');

  if (column.key === switchColumns.DELETE) {
    return (
      <div className={`${themeClass}__basicHeaderInner`}>
        <span className={`${themeClass}__basicHeaderInner_text`}>{column.name}</span>
      </div>
    );
  }

  if (column.key === switchColumns.NF) {
    return (
      <div
        onClick={() => onSort(true)}
        className={cn(`${themeClass}__basicHeaderInner`, { ['sorted']: sorting.order_by === sortingField })}
      >
        <span className={`${themeClass}__basicHeaderInner_text`}>{column.name}</span>
        {sorting.order_by === sortingField && (
          <div className={'arrowContainer'}>
            <TokenIcon iconName={sorting.order_dir === 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
          </div>
        )}
      </div>
    );
  }

  if (!column.sortable) {
    return (
      <div className={`${themeClass}__basicHeaderInner`}>
        <span className={`${themeClass}__basicHeaderInner_text`}>{column.name}</span>
      </div>
    );
  }

  return (
    <div onClick={e => onSort(true)} className={cn(`${themeClass}__basicHeaderInner`, { ['sorted']: sorting.order_by === sortingField })}>
      <span className={`${themeClass}__basicHeaderInner_text`}>{column.name}</span>
      {sorting.order_by === sortingField && (
        <div className={'arrowContainer'}>
          <TokenIcon iconName={sorting.order_dir === 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
        </div>
      )}
    </div>
  );
};

export default BasicHeaderRenderer;
