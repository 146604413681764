import React, { ChangeEvent, MouseEvent } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import { DropdownMenuItem } from '../../DropdownParts';
import TextInputNew from '../../../TextInputNew/TextInputNew';
import CustomButton from '../../../ButtonComponents/CustomButton/CustomButton';
import Popover from '@material-ui/core/Popover';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import Divider from '../../../../controls/Divider/Divider';
import { HIGH_RESOLUTION_CLASS } from '../../../../../constants/screenResolutionConstants';

import './DropdownPopoverFilterStyles.scss';

interface DropDownItemModel {
  value: string | number;
  title: string;
  group: string | number;
  filterType: 'pcd' | 'needsList';
}

interface DropDownGroupModel {
  value: string | number;
  title: string;
}

interface DropdownPopoverFilterProps {
  customClassName?: string;
  groupList?: DropDownGroupModel[];
  filterItemList?: DropDownItemModel[];
  handleChange: (value: string | number) => void;
  handleClearItems: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleChangeOrBtn: () => void;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedIds: (string | number)[];
  showClearBtn?: boolean;
  isOrFilter?: boolean;
  searchValue: string;
  isOpenPopover: boolean;
  anchorEl: HTMLDivElement | null;
  closePopover: () => void;
  showOrButton?: boolean;
  orButtonActive?: boolean;
  isMobile?: boolean;
  isHighResolution?: boolean;
}

const DropdownPopoverFilter: React.FC<DropdownPopoverFilterProps> = ({
  customClassName,
  groupList,
  filterItemList,
  handleChange,
  selectedIds,
  showClearBtn,
  handleClearItems,
  isOrFilter,
  handleChangeOrBtn,
  handleChangeSearch,
  searchValue,
  isOpenPopover,
  anchorEl,
  closePopover,
  showOrButton,
  orButtonActive,
  isMobile,
  isHighResolution,
}) => {
  const themeClass = useGetThemeClass('b-dropdownPopoverFilter');

  return (
    <Popover
      id={`Filter`}
      open={isOpenPopover}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={cn({ [HIGH_RESOLUTION_CLASS]: isHighResolution })}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        classes: {
          root: `${themeClass}_popover ${isMobile && `${themeClass}_mobile_popover`}`,
        },
      }}
    >
      <div
        className={cn(`${themeClass}`, {
          [`${customClassName}`]: customClassName,
          ['-mobile']: isMobile,
        })}
      >
        <div className={cn(`${themeClass}_header`, { ['-mobile']: isMobile })}>
          <div className={cn(`${themeClass}_headerLeftContent`, { ['-mobile']: isMobile })}>
            <TextInputNew
              isSearch
              type={'on-bgd'}
              size={'md'}
              placeholder={'Search'}
              onChange={handleChangeSearch}
              value={searchValue}
              onKeyPress={() => null}
              disabled={!filterItemList?.length && !searchValue}
              isMobile={isMobile}
              width={isMobile ? '91%' : 'auto'}
            />
            {showOrButton && !isMobile ? (
              <CustomButton
                type={isOrFilter ? 'selection-activated' : 'selection-plain'}
                size={'md'}
                clickHandler={handleChangeOrBtn}
                title={'Or'}
                disabled={!orButtonActive}
              />
            ) : null}
            {isMobile && (
              <div className={`${themeClass}_closeIcon`} onClick={closePopover}>
                <SystemButton type={'close'} size={'lg'} variant={'transparent'} isMobile={isMobile} />
              </div>
            )}
          </div>
          {!isMobile && (
            <div className={`${themeClass}_headerRightContent`}>
              {showClearBtn && !!filterItemList?.length && selectedIds?.length ? (
                <>
                  <CustomButton type={'text-plain'} size={'sm'} clickHandler={handleClearItems} title={'Clear selected items'} />
                </>
              ) : null}
            </div>
          )}
          {isMobile && <Divider direction={'horizontal'} type={'srf-5'} customClassname={`${themeClass}_customDividerClassname`} />}
        </div>
        <div className={cn(`${themeClass}_filterGroups`, { ['-mobile']: isMobile })}>
          <div className={cn(`${themeClass}_filterGroupsContainer`, { ['-mobile']: isMobile })}>
            {groupList.map(group => {
              const itemsFromGroup = filterItemList.filter(filterItem => filterItem.group === group.value);

              if (!itemsFromGroup.length) {
                return null;
              }

              return (
                <div className={`${themeClass}_filterGroupBlock`} key={group.title}>
                  <div className={cn(`${themeClass}_filterGroupHeader`, { ['-mobile']: isMobile })}>{group.title}</div>
                  <div className={`${themeClass}_filterGroupList`}>
                    {itemsFromGroup.length
                      ? itemsFromGroup.map((item, index) => {
                          return (
                            <DropdownMenuItem
                              value={item.value}
                              title={item.title}
                              isMulti
                              isSelected={Object.values(selectedIds)?.includes(item.value)}
                              size={'md'}
                              onItemClick={handleChange}
                              key={item.value}
                              isMobile={isMobile}
                            />
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default DropdownPopoverFilter;
