import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NotificationFiltersContainer from '../NotificationFilters/NotificationFiltersContainer';
import { ResetButton } from '../../../controls';

const styles = {
  root: {
    flexGrow: 1,
    transform: 'translateY(-7px)',
  },
  panel: {
    backgroundColor: 'var(--background-main)',
    color: 'var(--color)',
    boxShadow: 'none',
  },
  rootToolbars: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 0px 9px',
  },
  fixed: {
    right: 0,
    zIndex: 2,
  },
  wrapTitle: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 120px)',

    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  title: {
    marginRight: '10px',
  },
};

class NotificationsListPanel extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active_project_id && this.props.active_project_id !== prevProps.active_project_id) {
      this.props.handleResetFilter();
    }
  }

  render() {
    const { classes, logoText, panel, handleChangeFilters, handleReadAll, handleGetSpecialReport, isAdmin } = this.props;

    return (
      <div className={`b-table__wrapMainTitle activityTitle`} ref={panel}>
        <div className={'filters_header'}>
          <div className={'filters_headerContainer'}>
            <h1 className={'b-table__mainTitle'}>{logoText}</h1>
            <div className={'FiltersView'}>
              <div className={'constFilters'}>
                <div className={`${classes.wrapTitle}`}>
                  <NotificationFiltersContainer
                    handleChangeFilters={handleChangeFilters}
                    getNotificationsList={this.props.getNotificationsList}
                    selectedFilters={this.props.selectedFilters}
                    conditions={this.props.conditions}
                    containsFilter={this.props.containsFilter}
                    formData={this.props.formData}
                    searchValue={this.props.searchValue}
                    handleChangeSearch={this.props.handleChangeSearch}
                    handleChangeMore={this.props.handleChangeMore}
                    handleChangeSelect={this.props.handleChangeSelect}
                    handleCloseSelect={this.props.handleCloseSelect}
                    handleRemoveFilter={this.props.handleRemoveFilter}
                    handleChangeContains={this.props.handleChangeContains}
                    handleSelectCondition={this.props.handleSelectCondition}
                    handleReadAll={this.props.handleReadAll}
                    onChangeFilters={this.props.onChangeFilters}
                    handleResetFilter={this.props.handleResetFilter}
                    onChangeFiltersHeight={this.props.onChangeFiltersHeight}
                  />
                </div>
              </div>
              {this.props.selectedFilters && this.props.selectedFilters.length > 0 ? (
                <div style={{ marginTop: '17px', marginRight: '1px' }}>
                  <ResetButton title={'Reset filters'} handleClick={this.props.handleResetFilter} height={'30px'} />
                </div>
              ) : (
                ''
              )}
              {isAdmin && (
                <div style={{ marginTop: '17px', marginRight: '1px' }}>
                  <ResetButton title={'Export CSV'} handleClick={handleGetSpecialReport} height={'30px'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotificationsListPanel.displayName = 'Notifications List Panel';

export default withStyles(styles)(NotificationsListPanel);
