import React, { ReactElement } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { ColumnToolTip } from '../../../AsideHolder/AsideHolder';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuBuildingPropsSingle, MenuPropsSingle } from '../../../../controls/Select/MenuPropsSingle';
import BaseHelper from '../../../../../helpers/BaseHelper';
import { ListSubheader } from '@material-ui/core';
import { KeyValueModel } from '../../../../../models/key-value.model';
import { BuildingModel } from '../../../../../models';
import SVG from 'react-inlinesvg';
import ButtonView from '../../../../controls/ButtonComponents/ButtonView/ButtonView';
import moment from 'moment';
import { TextInputNew } from '../../../../controls';
import _ from 'lodash';

import './MultiSelectFormStyle.scss';

interface IProps {
  selectHandler: (value: string[]) => void;
  rowIndex?: number;
  updateUfcStep3?: (rowIndex: number) => void;
  onClose?: (name: string) => void;
  forbidToOpen?: boolean;
  values: KeyValueModel<any>[];
  buildings?: BuildingModel<any>[];
  selectedIds: number[];
  label?: string | ReactElement<any, any>;
  error?: any;
  name?: string;
  classes?: any;
  customStylesForm?: KeyValueModel<string>;
  customStylesSelect?: KeyValueModel<string>;
  clear?: () => void;
  disabled?: boolean;
  isLocation?: boolean;
  customClasses?: string;
  controlOpen?: KeyValueModel<any>;
  searchable?: boolean;
  searchPlaceholder?: string;
  showClearBtn?: boolean;
  // if got at least 1 Ids, hide label
  hideLabelForSelected?: boolean;
  isBuildingSelect?: boolean;
  clearItemFromLabel?: boolean;
  // method for clear action from label, required clearItemFromLabel
  clearFromLabel?: () => void;
  condition?: any;
  selectedCondition?: any;
  hideTooltip?: boolean;
  showSelectAll?: boolean;
  htmltooltip?: (isShow: boolean) => void;
  changeOrBtn?: () => void;
  isOrFilter?: boolean;
  isVisibleOrBtn?: boolean;
  searchWidth?: string;
  hideSelectedValues: boolean;
  isNfCard?: boolean;
  isBuildings?: boolean;
}

interface IState {
  open: boolean;
  searchValue: string;
  values: KeyValueModel<any>[];
  isValues: boolean;
}

const clearItemFromLabelStyle: KeyValueModel<any> = {
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
};

const groupingIcon: KeyValueModel<any> = {
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  background: 'none',
  marginLeft: '10px',
};

class MultiSelectField extends React.Component<IProps, IState> {
  state = {
    open: false,
    searchValue: '',
    values: [],
    isValues: false,
  };

  componentDidMount(): void {
    const { values } = this.props;
    this.setState({
      values: values,
      isValues: !!(values.length - 1),
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    let { values } = this.props;
    const isDate = moment(values && values[0]?.title).isValid();
    const inputValue = _.escapeRegExp(this.state.searchValue)?.trim().toLowerCase();
    const isNumber = Number(values && values[0]?.title?.split('-').join(''));

    if (values !== prevProps.values && (values?.length > 0 || values?.length === 0)) {
      if (this.state.searchValue && values.length) {
        values = values.filter(v => {
          if (v['code']) {
            if (('(' + v['code'] + ') ' + v['title'])?.trim().toLowerCase().search(inputValue) !== -1) {
              return true;
            } else {
              return false;
            }
          } else if (isDate && isNumber) {
            if (moment(v['title']).format('MM/DD/YYYY').search(inputValue.replace(/-/gi, '/')) !== -1) {
              return true;
            } else {
              return false;
            }
          } else {
            if (v['title']?.trim().toLowerCase().search(inputValue) !== -1) {
              return true;
            } else {
              return false;
            }
          }
        });
      }
      this.setState({
        values: values,
      });
    }
  }

  handleChange = e => {
    const { target, currentTarget } = e;
    e.stopPropagation();
    const { selectHandler } = this.props;
    selectHandler(target.value.filter(f => f !== undefined));
  };

  handleClose = () => {
    const { rowIndex, updateUfcStep3, onClose, values, htmltooltip, name } = this.props;
    htmltooltip && htmltooltip(false);
    this.setState({ open: false, searchValue: '', values: values && values });
    updateUfcStep3 && updateUfcStep3(rowIndex);
    onClose && onClose(name);
  };

  handleOpen = () => {
    const { htmltooltip } = this.props;
    if (!this.props.forbidToOpen) {
      this.setState({ open: true });
      htmltooltip && htmltooltip(true);
    }
  };

  getBuildingsLevels = (): { title: string; levels: any[] }[] => {
    const buildingsObj = this.state.values.reduce((buildings, level) => {
      buildings[level.building_id] = buildings[level.building_id] || [];
      buildings[level.building_id].push(level);

      return buildings;
    }, {});

    return Object.keys(buildingsObj).map((buildId: any) => {
      return {
        title: this.props.buildings && this.props.buildings.find(building => building.id == buildId)?.title,
        levels: BaseHelper.sort(buildingsObj[buildId], 'title'),
      };
    });
  };

  handleChangeSearch = (targetValue: string) => {
    const { values } = this.props;
    const isDate = moment(values && values[0]?.title).isValid();
    const isNumber = Number(values && values[0]?.title?.split('-').join(''));
    const inputValue = targetValue?.trim().toLowerCase();

    const newValues = values.filter(v => {
      if (v['code']) {
        if (('(' + v['code'] + ') ' + v['title'])?.trim().toLowerCase().search(inputValue) !== -1) {
          return true;
        } else {
          return false;
        }
      } else if (isDate && isNumber) {
        if (moment(v['title']).format('MM/DD/YYYY').search(inputValue.replace(/-/gi, '/')) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (v['title']?.trim().toLowerCase().search(inputValue) !== -1) {
          return true;
        } else {
          return false;
        }
      }
    });

    this.setState({
      searchValue: targetValue,
      values: newValues,
    });
  };

  handleClearItems = e => {
    const { clear, selectHandler } = this.props;
    e.stopPropagation();
    clear && clear();
    selectHandler([]);
  };

  handleClearFromLabel = e => {
    const { clearFromLabel } = this.props;
    e.stopPropagation();
    clearFromLabel && clearFromLabel();
  };

  handleSelectAll = e => {
    e.stopPropagation();
    const { selectHandler } = this.props;
    const { values } = this.state;

    const allIds = values.map(v => v.id) || [];

    selectHandler(allIds);
  };

  handleRightDateFormat = items => {
    if (items) {
      let itemsArray;

      const checkItem = items && items[0];
      const formats = ['DD/MM/YYYY', 'YYYY-MM-DD'];
      const isDate = moment(checkItem, formats, true).isValid();

      if (isDate) {
        itemsArray = items.map(item => moment(Date.parse(item)).format('DD/MM/YYYY'));
      } else {
        itemsArray = items;
      }

      if (itemsArray && Array.isArray(itemsArray) && itemsArray.join) {
        return itemsArray.join(', ');
      } else {
        return '';
      }
    }
  };

  changeOrBtn = () => {
    this.props.changeOrBtn();
  };

  handlePaperProps = () => {
    const { isBuildingSelect } = this.props;

    if (isBuildingSelect) {
      return MenuBuildingPropsSingle;
    }

    return MenuPropsSingle;
  };

  render() {
    const {
      label,
      error,
      name,
      classes,
      customStylesForm,
      customStylesSelect,
      disabled,
      isLocation,
      customClasses,
      controlOpen,
      showClearBtn,
      searchable,
      searchPlaceholder,
      isBuildingSelect,
      hideLabelForSelected,
      clearItemFromLabel,
      condition,
      selectedCondition,
      hideTooltip,
      showSelectAll,
      isVisibleOrBtn,
      hideSelectedValues,
      isNfCard,
      isBuildings,
    } = this.props;

    let { selectedIds } = this.props;

    const { searchValue, values, isValues } = this.state;

    const selectedItems = [];

    selectedIds?.length &&
      selectedIds?.map(id => {
        const selected = values && values.length && values.find(value => value?.id === id);
        if (selected) {
          selectedItems.push(selected.title);
        }
      });

    selectedIds = selectedIds || [];
    let formStyle = {
      width: '100%',
    } as KeyValueModel<string>;

    if (customStylesForm) {
      formStyle = customStylesForm;
    }
    let showLabel = true;
    if (hideLabelForSelected && selectedIds.length) {
      showLabel = false;
    }
    return (
      <FormControl
        variant="outlined"
        style={formStyle}
        classes={{
          root: classes ? classes.formControlRoot : '',
        }}
      >
        {label && label.toString().includes('Grouping') ? (
          <span style={groupingIcon}>
            <SVG src={require('../../../../../assets/icons/grouping_icon.svg')} />
          </span>
        ) : (
          ''
        )}
        {label && label.toString().includes('Filters') ? (
          <span style={groupingIcon}>
            <SVG src={require('../../../../../assets/icons/new_filter_icon.svg')} />
          </span>
        ) : (
          ''
        )}
        {label && label.toString().includes('Buildings') && isBuildings ? (
          <span style={groupingIcon}>
            <SVG src={require('../../../../../assets/icons/building_icon_filters.svg')} />
          </span>
        ) : (
          ''
        )}
        {clearItemFromLabel ? (
          <span className={'filteredRemove'} style={clearItemFromLabelStyle} onClick={this.handleClearFromLabel}>
            <SVG src={require('../../../../../assets/icons/cross.svg')} />
          </span>
        ) : (
          ''
        )}
        {label &&
          classes &&
          showLabel &&
          (isBuildingSelect ? (
            <InputLabel
              classes={{
                shrink: classes.disableShrink,
                focused: classes.focused,
              }}
              htmlFor={`outlined-${selectedIds}`}
            >
              {label}
            </InputLabel>
          ) : (
            <InputLabel
              classes={{
                root: classes.labelRoot,
                shrink: classes.labelShrink,
                focused: classes.focused,
              }}
              style={
                label.toString().includes('Grouping') || label.toString().includes('Filters') || label.toString().includes('Buildings')
                  ? { marginLeft: '10px' }
                  : {}
              }
              htmlFor={`outlined-${selectedIds}`}
            >
              {label}
            </InputLabel>
          ))}
        <Select
          open={this.state.open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          value={selectedIds || []}
          onChange={this.handleChange}
          disabled={disabled}
          className={`b-createProject__select ${customClasses}`}
          multiple={true}
          MenuProps={{
            ...(this.handlePaperProps() || {}),
          }}
          classes={{
            root: classes ? classes.multiSelectRoot : '',
            disabled: classes ? classes.disabled : '',
          }}
          style={customStylesSelect}
          id={`grouped-select`}
          {...(controlOpen ? controlOpen : {})}
          input={
            // <CustomTooltip
            //   title={
            //     !(this.state.open || (controlOpen && controlOpen.open)) && !hideTooltip && selectedItems.length
            //       ? this.handleRightDateFormat(selectedItems)
            //       : ''
            //   }
            // >
            <OutlinedInput labelWidth={0} name={name} id={`outlined-${label}`} />
            // </CustomTooltip>
          }
          renderValue={(selected: any) => {
            const selectedItem = [];
            values?.length &&
              values.map(v => {
                if (selected?.find(f => f === v.id)) {
                  selectedItem.push(v.title);
                }
              });
            return (
              <span style={{ display: hideSelectedValues ? 'none' : '' }} className="selectedValues">
                {selectedItem.join(', ')}
              </span>
            );
          }}
          error={error}
        >
          {searchable && isValues && (
            <div className={'-menu inputTextContentBuildingSpaces'}>
              <TextInputNew
                isSearch
                type={'on-bgd'}
                size={'sm'}
                placeholder={searchPlaceholder}
                onChange={this.handleChangeSearch}
                value={searchValue}
              />
            </div>
          )}
          {condition && condition}
          {selectedCondition && selectedCondition}
          {(showSelectAll || showClearBtn || isVisibleOrBtn) && !!values?.length && (
            <div className={'selectContent'}>
              {(showSelectAll && !!values?.length && (
                <div onClick={this.handleSelectAll} className={'btnClear'}>
                  Select all
                </div>
              )) ||
                ''}
              {(showClearBtn && !!values?.length && (
                <div onClick={this.handleClearItems} className={'btnClear'}>
                  Clear all
                </div>
              )) ||
                ''}
              {(isVisibleOrBtn && !showSelectAll && !!values?.length && showClearBtn && (
                <ButtonView
                  handleClick={this.changeOrBtn}
                  type={this.props.isOrFilter ? 'orange' : 'primal'}
                  title={'Or'}
                  width={'40px'}
                  height={'26px'}
                  style={{ margin: '4px 22px', padding: 0 }}
                  disabled={!this.props.selectedIds?.length && !this.props.isOrFilter}
                />
              )) ||
                ''}
            </div>
          )}
          {!values?.length && isValues && <span className={'select__noItems'}>No options</span>}
          {isLocation && !!values?.length
            ? this.getBuildingsLevels().map(item => {
                const items = item.levels.map(level => (
                  <MenuItem
                    key={level.id}
                    value={level.id}
                    className={`menuItem ${searchable ? 'menuItem__searchable' : ''} ${isNfCard ? 'nfCardHover' : ''}`}
                  >
                    <Checkbox checked={selectedIds?.indexOf(level.id) > -1} className={'filter__checkBox'} />
                    <ColumnToolTip title={level.desc ? level.desc : ''} placement="top">
                      <span>{level.title}</span>
                    </ColumnToolTip>
                  </MenuItem>
                ));

                return [
                  <ListSubheader className={'filter__subtitle'} key={`multiSelect-${item.title}`}>
                    {item.title}
                  </ListSubheader>,
                  items,
                ];
              })
            : (!!values?.length &&
                values
                  .filter(value => !!value)
                  .map(({ id, title, parties, company, desc, filterField, groupField, code, is_auto, first_name, last_name }, index) => {
                    const isActive =
                      selectedIds?.findIndex(selected => selected === id || selected === filterField || selected === groupField) > -1;
                    const dateParsed = title && title.match(/(\d{4})-(\d{2})-(\d{2})/);
                    //TODO need search this new fields first_name && last_name
                    const isDate = first_name && last_name ? false : moment(dateParsed && dateParsed[0]).isValid();
                    return (
                      <MenuItem
                        key={`menuItemKey-${index}`}
                        value={filterField || groupField || id}
                        className={`menuItemFilters ${searchable ? 'menuItem__searchable' : ''} ${isNfCard ? 'nfCardHover' : ''}`}
                        style={{
                          backgroundColor: isActive ? 'var(--background-select)' : '',
                        }}
                      >
                        <Checkbox checked={!!isActive} className={'filter__checkBox'} />
                        <ColumnToolTip title={desc ? desc : ''} placement="top">
                          <div className={'selectUserCompany'}>
                            <span
                              className={`title ${title?.trim().search('#imported') !== -1 && is_auto ? '-isTagImported' : ''} ${
                                is_auto ? '-isTagAuto' : ''
                              } ${parties || company ? 'selectUserText' : ''}`}
                            >
                              {isDate
                                ? moment(title).format('MM/DD/YYYY')
                                : code
                                ? '(' + code + ') ' + title
                                : title || `${first_name + ' ' + last_name}`}
                            </span>
                            {parties && title && (
                              <span key={`user-${id}`} className={'selectUserCompanyText'}>
                                {parties
                                  ?.map((item, i) => {
                                    return item.company;
                                  })
                                  .join(', ')}
                              </span>
                            )}
                            {company && title && <span className={'selectUserCompanyText'}>{company}</span>}
                          </div>
                        </ColumnToolTip>
                      </MenuItem>
                    );
                  })) ||
              ''}
        </Select>
      </FormControl>
    );
  }
}

export default MultiSelectField;
