export const styles = {
  mainPaper: {
    height: 'calc(100svh - 107px - 43px)',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 'inherit',
    display: 'flex',
    backgroundColor: 'var(--background-main)',
    boxShadow: 'none',
  },
};
