import { ApiFilterModel } from '../models';
import FiltersHelper from './FiltersHelper';

export default class WhiteBoardRequestDataHelper {
  static getFiltersData = (filtersData, allFilters): ApiFilterModel => {
    const query_params = {} as ApiFilterModel;
    const filters = {} as any;
    delete allFilters.first_date;
    delete allFilters.last_date;
    const convertedFilters = FiltersHelper.convertFilterFromApi(allFilters);
    // filtersData.display !== 'All' ? filters.process = [{id: filtersData.display, value: filtersData.display}]: null;
    // filtersData?.display !== 'All' ? filters.process = [convertedFilters?.process.find(party => party?.id === filtersData?.display?.toLowerCase())]: null;
    filtersData.requestCompany.value !== 'All' && filtersData.requestCompany.value !== 'Request company'
      ? (filters.request_party = [convertedFilters.request_parties?.find(party => party.value === filtersData.requestCompany.value)])
      : null;
    filtersData.responseCompany.value !== 'All' && filtersData.responseCompany.value !== 'Response company'
      ? (filters.response_party = [convertedFilters.response_parties?.find(party => party.value === filtersData.responseCompany.value)])
      : null;
    filtersData.location.value !== 'All' && filtersData.location.value !== 'Location'
      ? (filters.locations = [convertedFilters.locations?.find(party => party.value === filtersData.location.value)])
      : null;
    if (Object.values(filters).length) {
      query_params.filters = filters;
    }
    return query_params;
  };
  static getFiltersDataFromCard = (filtersData, allFilters) => {
    const filters = {} as any;
    // filtersData.filters.process ? filters.display = filtersData.filters.process[0].value: filters.display = 'All';
    filtersData.filters.request_party
      ? (filters.requestCompany = filtersData.filters.request_party[0].value)
      : (filters.requestCompany = 'All');
    filtersData.filters.response_party
      ? (filters.responseCompany = filtersData.filters.response_party[0].value)
      : (filters.responseCompany = 'All');
    filtersData.filters.locations ? (filters.location = filtersData.filters.locations[0].value) : (filters.location = 'All');
    return filters;
  };
}
