import { CreatePartyResponsePayload, ListSubdivision, TeamApi } from '../../service/Api/team/types';
import { actionCreator } from '../configure/actionCreater';

import { payloadWithCallback, Team } from './types';

// team get parties for project

export const fetchMembers = actionCreator<Team.GetMembersPayload>(Team.Actions.FETCH_MEMBERS);

export const fetchMembersCancel = actionCreator(Team.Actions.FETCH_MEMBERS_CANCEL);

export const fetchMembersSuccess = actionCreator<TeamApi.IMember[]>(Team.Actions.FETCH_MEMBERS_SUCCESS);

export const createParty = actionCreator<payloadWithCallback<TeamApi.CreatePartyRequest & Team.DivisionPayload, TeamApi.IMember>>(
  Team.Actions.CREATE_PARTY,
);

export const updateParty = actionCreator<payloadWithCallback<TeamApi.UpdatePartyRequest & Team.DivisionPayload, TeamApi.IMember>>(
  Team.Actions.UPDATE_PARTY,
);

export const createUpdatePartySuccess = actionCreator<TeamApi.CreatePartyResponsePayload>(Team.Actions.UPDATE_CREATE_PARTY_SUCCESS);

export const createUpdatePartyFail = actionCreator<TeamApi.IMember>(Team.Actions.UPDATE_CREATE_PARTY_FAIL);

// team party disciplines

export const fetchDisciplinesRoles = actionCreator(Team.Actions.FETCH_DISCIPLINES_ROLES);

export const fetchDisciplinesRolesSuccess = actionCreator<Team.FetchDisciplinesRolesPayload>(Team.Actions.FETCH_DISCIPLINES_ROLES_SUCCESS);

export const getCountNfForParty = actionCreator(Team.Actions.GET_COUNT_NF_FOR_PARTY);

export const getCountNfForPartySuccess = actionCreator<TeamApi.NfCountForPartyData>(Team.Actions.GET_COUNT_NF_FOR_PARTY_SUCCESS);

// team get users

export const getPartyUsers = actionCreator(Team.Actions.GET_PARTY_USERS);

export const getPartyUsersSuccess = actionCreator<any>(Team.Actions.GET_PARTY_USERS_SUCCESS);

// team users search

export const searchTeamUsers = actionCreator<TeamApi.SearchUsersTeam>(Team.Actions.SEARCH_USERS_TEAM);

export const searchTeamUsersSuccess = actionCreator<TeamApi.UserResponse>(Team.Actions.SEARCH_USERS_TEAM_SUCCESS);

// team link / unlink user to project

export const linkUserToProject = actionCreator(Team.Actions.LINK_USER_TO_PROJECT);

export const linkUserToProjectSuccess = actionCreator<any>(Team.Actions.LINK_USER_TO_PROJECT_SUCCESS);

export const unlinkUserToProject = actionCreator(Team.Actions.UNLINK_USER_TO_PROJECT);

export const unlinkUserToProjectSuccess = actionCreator<any>(Team.Actions.UNLINK_USER_TO_PROJECT_SUCCESS);

// team create / update / delete user

export const createUserToParty = actionCreator<any>(Team.Actions.CREATE_USER_TO_PARTY);

export const updateUserToParty = actionCreator<any>(Team.Actions.UPDATE_USER_TO_PARTY);

export const deleteUserToParty = actionCreator<any>(Team.Actions.DELETE_USER_TO_PARTY);

export const createUpdateDeleteUserSuccess = actionCreator<any>(Team.Actions.DELETE_UPDATE_CREATE_USER_SUCCESS);

export const updateCreateUserFail = actionCreator<any>(Team.Actions.UPDATE_CREATE_USER_FAIL);

// getting list subdivisions for party

export const getListSubdivision = actionCreator(Team.Actions.GET_LIST_SUBDIVISION);

export const getListSubdivisionSuccess = actionCreator<ListSubdivision[]>(Team.Actions.GET_LIST_SUBDIVISION_SUCCESS);

export const clearListSubdivision = actionCreator<any>(Team.Actions.CLEAR_LIST_SUBDIVISION);

// team all parties

export const getParties = actionCreator<Team.GetPartiesPayload>(Team.Actions.GET_PARTIES);

export const getPartiesCancel = actionCreator(Team.Actions.GET_PARTIES_CANCEL);

export const getPartiesSuccess = actionCreator<TeamApi.Party[]>(Team.Actions.GET_PARTIES_SUCCESS);

export const addParty = actionCreator<payloadWithCallback<TeamApi.AddPartyRequest, TeamApi.IMember>>(Team.Actions.ADD_PARTY);

export const setAddedPartyId = actionCreator<number>(Team.Actions.SET_ADDED_PARTY_ID);

export const addPartySuccess = actionCreator<TeamApi.AddCompanySuccessPayload>(Team.Actions.ADD_PARTY_SUCCESS);

export const unlinkParty = actionCreator<TeamApi.LinkOrUnlinkPartyProject>(Team.Actions.UNLINK_PARTY_FROM_PROJECT);

export const unlinkPartySuccess = actionCreator<TeamApi.LinkOrUnlinkPartyPayload>(Team.Actions.UNLINK_PARTY_FROM_PROJECT_SUCCESS);

// set contact-point

export const setContactPointProject = actionCreator(Team.Actions.SET_CONTACT_POINT_PROJECT);

export const setContactPointProjectSuccess = actionCreator<any>(Team.Actions.SET_CONTACT_POINT_PROJECT_SUCCESS);

export const setContactPointParty = actionCreator(Team.Actions.SET_CONTACT_POINT_PARTY);

export const setContactPointPartySuccess = actionCreator<any>(Team.Actions.SET_CONTACT_POINT_PARTY_SUCCESS);

// filters

export const setFilters = actionCreator<Record<string, unknown>>(Team.Actions.SET_TEAM_FILTERS);

export const getFilters = actionCreator<any>(Team.Actions.GET_TEAM_FILTERS);

export const getFiltersSuccess = actionCreator<any>(Team.Actions.GET_TEAM_FILTERS_SUCCESS);

// set planned/actual buyout date

export const setBuyoutDate = actionCreator(Team.Actions.SET_BUYOUT_DATE);

export const setBuyoutDateSuccess = actionCreator<any>(Team.Actions.SET_BUYOUT_DATE_SUCCESS);

// create standard template

export const createTemplateParties = actionCreator<TeamApi.CreateTemplatePayload>(Team.Actions.CREATE_TEMPLATE_PARTIES);

export const createTemplatePartiesSuccess = actionCreator<TeamApi.IMember[]>(Team.Actions.CREATE_TEMPLATE_PARTIES_SUCCESS);

export const clearTeamState = actionCreator(Team.Actions.CLEAR_STATE);
