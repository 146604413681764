import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './TextFieldStyles.scss';

type TextFiledProps = {
  label?: string;
  error?: string;
  className?: string;
  wrapperClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const TextField = forwardRef<HTMLInputElement, TextFiledProps>(function TextField(
  { label, error, className, wrapperClassName, ...props },
  ref,
) {
  return (
    <div className={cn(styles.TextField, wrapperClassName)}>
      {!!label && <label className={styles.TextField__label}>{label}</label>}
      <input
        ref={ref}
        className={cn(
          styles.TextField__input,
          {
            [styles.TextField__input_error]: !!error,
          },
          className,
        )}
        {...props}
      />
      {!!error && <span className={styles.TextField__error}>{error}</span>}
    </div>
  );
});
