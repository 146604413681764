export enum FilterNestedType {
  MF = 'MF',
  UF = 'UF',
}

export interface FilterNestedInterface<T = any> {
  value: number;
  label: string;
  type?: string;
  viewData: T;
}
