import React from 'react';
import styles from './FiltersListPopoverStyles';
import Popover from '@material-ui/core/Popover';

class FiltersListPopover extends React.Component {
  state = {
    anchorEl: null,
    filtersOpen: false,
  };

  handleDrawer = event => {
    this.setState({
      filtersOpen: !this.state.filtersOpen,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      filtersOpen: false,
    });
  };

  render() {
    const { children, height, isSandbox, theme } = this.props;
    const { anchorEl, filtersOpen } = this.state;
    if (!(window.innerWidth < 767 || window.innerWidth < 980 || isSandbox)) {
      return children;
    }
    return (
      <div className={styles.FiltersListPopover}>
        <div className={styles.wrapFiltersBtn} style={{ height: height ? height : '12px' }} onClick={this.handleDrawer}>
          {/*<SVG src={theme.images.doubleArrow} className={filtersOpen ? styles.openedMenu : styles.closedMenu} alt="#"/>*/}
          <span>Filters</span>
        </div>
        <Popover
          id="planit-popper-filters"
          open={filtersOpen}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              backgroundColor: 'var(--background-sheet);',
              transform: isSandbox ? 'translateX(90px)' : 'translateX(-18px)',
              overflow: 'hidden',
              borderRadius: '0',
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={styles.FiltersListPopoverChild}>
            <span>Filters:</span>
            <div className={styles.FiltersListPopoverChild__holder}>{children}</div>
          </div>
        </Popover>
      </div>
    );
  }
}

export default FiltersListPopover;
