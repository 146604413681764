import React, { useEffect } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { ActivationTabType, FileModel, RequestModel, RequestUserModel } from '../../../../../models';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import { useDispatch } from 'react-redux';
import { IConfirmDialogState, withConfirmDialogContext } from '../../../HighOrderComponents';
import { getResponseActionMessage } from '../../../../../helpers/RequestHelper';
import { Mention } from '../../mainParts/BottomCardPart/BottomCardPart';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import FileUploader from '../../../../controls/FileUploader/FileUploader';
import EmptyStatePlaceholder from '../../../../controls/EmptyStatePlaceholder/EmptyStatePlaceholder';
import Divider from '../../../../controls/Divider/Divider';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import DateRow from '../../../../controls/DateRow/DateRow';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { handleSetBreadcrumbs } from '../../../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';
import { breadcrumbsSlice } from '../../../../../store/breadcrumbs/breadcrumbsReducer';

import './ResponseTabStyles.module.scss';

const { setFinal_desc, setIsChangedFinalValue } = requestSlice.actions;
const { setBreadcrumbs } = breadcrumbsSlice.actions;

interface ResponseTabProps {
  isDragRequestCard: boolean;
  activeTab: ActivationTabType;
  isHiddenFields: boolean;
  selectedRequest: RequestModel;
  requestLoading: boolean;
  isAccessToCommitment: boolean;
  changeAnimationBtn: (nameBtn: string) => void;
  isUser: boolean;
  errors: any;
  handleShowConfirmDialog: (options: IConfirmDialogState) => void;
  handleFile: (e: any, isDrag: boolean, isRestrict: boolean, name: string) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => Promise<void>;
  handleDownloadAll: (e: any) => void;
  setDescription: (descriptionValue: string, delta: any, source: any, editor: any) => void;
  responseCurrentDescription: string;
  setResponseCurrentDescription: (value: string) => void;
  setIsResponseCurrentDescriptionChanged: (value: boolean) => void;
  responseFiles: FileModel[] | FileList | any;
  animationLoading: boolean;
  loadingFilesName: string[];
  responseMentions: Mention[];
  promptName: string;
  is_promptBtnOk: boolean;
  openRequest: (id: number, isNavToSubtask?: boolean) => void;
  commitmentSave: (isSubmit: boolean, files?: FileModel[] | FileList | any, isDraft?: boolean, delete_files?) => void;
  renderHistoryAction: (
    type: 'send' | 'updated',
    date: string | Date,
    firstName: string,
    lastName: string,
    userProfile: RequestUserModel,
    isBeforeText?: boolean,
    company?: string,
  ) => React.ReactElement;
  activeCommitmentAction: string | null;
  onKeyPress: () => void;
  responseMoreLimitFiles: FileModel[] | FileList | any;
  handleResponseMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
  isSkeletonLoading: boolean;
}

const ResponseTab: React.FC<ResponseTabProps> = ({
  isDragRequestCard,
  activeTab,
  isHiddenFields,
  selectedRequest,
  requestLoading,
  isAccessToCommitment,
  changeAnimationBtn,
  isUser,
  errors,
  handleFile,
  delSavedFile,
  handleDownloadAll,
  setDescription,
  responseCurrentDescription,
  setResponseCurrentDescription,
  setIsResponseCurrentDescriptionChanged,
  responseFiles,
  animationLoading,
  loadingFilesName,
  promptName,
  is_promptBtnOk,
  openRequest,
  commitmentSave,
  renderHistoryAction,
  activeCommitmentAction,
  onKeyPress,
  responseMoreLimitFiles,
  handleResponseMoreLimitFiles,
  isSkeletonLoading,
}) => {
  const { sizeResolution, isMobile } = GetResolution();
  const { setUrlNF, setUrlSubmittal } = useRoutingHelper();
  const showSmall = isDragRequestCard || sizeResolution === '640';

  const themeClass = showSmall
    ? `${useGetThemeClass('b-cardRequest-responseTab')}-small`
    : `${useGetThemeClass('b-cardRequest-responseTab')}-full`;

  const dispatch = useDispatch();

  const isDisableFile =
    !selectedRequest?.id ||
    !isAccessToCommitment ||
    selectedRequest?.status === 'accepted' ||
    (isUser && !isAccessToCommitment) ||
    requestLoading ||
    selectedRequest?.status === 'provided';

  useEffect(() => {
    if (!requestLoading) {
      setResponseCurrentDescription(selectedRequest?.response_desc);
      setIsResponseCurrentDescriptionChanged(false);

      if (responseCurrentDescription === selectedRequest?.response_desc) {
        setIsChangedFinalValue(false);
      }
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (is_promptBtnOk && promptName === 'deliverableComplete') {
      commitmentSave(true);
    }
  }, [is_promptBtnOk]);

  useEffect(() => {
    dispatch(setFinal_desc(responseCurrentDescription));
  }, [responseCurrentDescription]);

  useEffect(() => {
    if (promptName === 'deliverableComplete') {
      deliverableComplete();
    }
  }, [is_promptBtnOk, promptName]);

  const collectFinalFiles =
    selectedRequest?.response_files && responseFiles ? [...selectedRequest?.response_files, ...responseFiles] : [...responseFiles];

  const handleChange = (value, delta, source, editor) => {
    setDescription && setDescription(value, delta, source, editor);
  };

  const handleOpenRequest = (id, nf) => {
    openRequest(id, true);

    setUrlNF(String(nf));
    const nextCard = { id, nf, toCard: 'Nf' };
    handleSetBreadcrumbs(false, 'Nf', selectedRequest, nextCard, dispatch, 'firstCardBreadcrumbs');
  };

  const getResponseMessagePostfix = () => {
    if (!selectedRequest?.commit_action || !Object.values({ ...(selectedRequest?.commit_action || {}) }).length) {
      return null;
    }

    if (selectedRequest?.commit_action?.action === 'due_date') {
      return <div className={`${themeClass}_responseSection_postfix`}>if due date change will be approved by the Request party</div>;
    }

    if (selectedRequest?.commit_action?.action === 'pull_request') {
      return (
        <div className={`${themeClass}_responseSection_postfix`}>
          <span>after resolution of the </span>
          <span
            className={`${themeClass}_responseSection_nf-link`}
            onClick={() => handleOpenRequest(selectedRequest?.commit_action?.request_id, selectedRequest?.commit_action?.request_nf)}
          >
            {`NF${selectedRequest?.commit_action?.request_nf}`}
          </span>
        </div>
      );
    }

    return null;
  };

  const deliverableComplete = () => {
    changeAnimationBtn('deliverableBtn');
  };

  const renderEmptyContainer = () => {
    return (
      <div className={`${themeClass}_emptyStateContainer`}>
        <div className={`${themeClass}_emptyStateContainer_placeholderBlock`}>
          <EmptyStatePlaceholder
            type={'nf-card-response'}
            customClassName={cn(`${themeClass}_emptyStateContainer_image`, {
              ['-isMobile']: isMobile,
            })}
          />
          <div className={`${themeClass}_emptyStateContainer_placeholderBlock_infoBlock`}>
            <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock`}>
              <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_title`}>Response</div>
              <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_text`}>
                Response party will be obliged to provide the answer to your request when you send the Need Form.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleChangeFile = (e: any, isDrag?: boolean, isRestrict?: boolean, name?: string) => {
    handleFile(e, isDrag, isRestrict, name);
  };

  const responseActionMessageData = getResponseActionMessage(selectedRequest);
  const responsePostfix = getResponseMessagePostfix();

  const shouldFocusDescription = activeCommitmentAction === 'provide_response';

  const openSubmittal = () => {
    const commitArray = selectedRequest?.action_dates?.commit?.action?.split(',');
    setUrlSubmittal(commitArray[1]);

    const fromCardObject = { id: selectedRequest?.id, title: `NF ${selectedRequest?.nf}`, nf: selectedRequest?.nf, breadcrumbType: 'nf' };
    const toCardObject = {
      id: Number(commitArray[1]),
      title: `#${commitArray[2]} - ${commitArray[3]} (R${0})`,
      breadcrumbType: 'submittal',
      activeTab: 'related-items',
    };
    dispatch(setBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs', array: [{ ...fromCardObject }, { ...toCardObject }] }));
  };

  const blockCommitAction = () => {
    const commitArray = selectedRequest?.action_dates?.commit?.action?.split(',');
    if (!commitArray) {
      return <></>;
    }
    if (commitArray[0] === 'input_check') {
      return (
        <div className={`${themeClass}_commitAction`}>
          <div className={cn(`${themeClass}_commitAction_commit_with_submit`, { ['-isDragRequestCard']: isDragRequestCard })}>
            <div className={`${themeClass}_commitAction_iconCommitAction`}>
              <TokenIcon iconName={'alert'} size={16} />
            </div>
            <div className={`${themeClass}_commitAction_title`}>Response will be provided on</div>
            <div>
              <DateRow date={commitArray[4]} isMobile={isMobile} showTime={false} />
            </div>
            <div className={`${themeClass}_commitAction_title`}>with resolution of submittal</div>
            <CardLinkButton
              type={'submittal'}
              size={'sm'}
              title={`#${commitArray[2]} - ${commitArray[3]} (R${0})`}
              customClassName={`${themeClass}_cardLinkButtonTitle`}
              clickHandler={openSubmittal}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className={`${themeClass}`} style={activeTab !== 'response' ? { display: 'none' } : {}}>
      {selectedRequest?.status === 'drafted' ? (
        renderEmptyContainer()
      ) : (
        <div className={`${themeClass}_container`}>
          <div className={`${themeClass}_editorFileTextContainer`}>
            {isSkeletonLoading ? (
              <div className={'editorFileContainer'}>
                <CustomSkeleton width={909} height={256} />
                <CustomSkeleton width={443} height={256} />
              </div>
            ) : (
              <div className={cn('editorFileContainer', {})}>
                <div className={'textQuillEditor'}>
                  <TextQuillEditor
                    fieldId={'responseTab'}
                    showToolbar={!isMobile}
                    value={responseCurrentDescription}
                    handleChange={handleChange}
                    placeholder={'Response description'}
                    disabled={
                      !selectedRequest?.id ||
                      !isAccessToCommitment ||
                      selectedRequest?.status === 'accepted' ||
                      (isUser && !isAccessToCommitment) ||
                      requestLoading ||
                      selectedRequest?.status === 'provided' ||
                      selectedRequest?.parent_id
                    }
                    customContainerClassName={cn(`${themeClass}_description`, {
                      ['-isHiddenFields']: !isMobile && isHiddenFields,
                    })}
                    error={errors?.response_desc}
                    shouldFocusDescription={shouldFocusDescription}
                    onKeyPress={onKeyPress}
                  />
                </div>
                {isMobile && <Divider direction={'horizontal'} type={'srf-4'} />}
                <FileUploader
                  isCard={true}
                  handleChangeFile={handleChangeFile}
                  isCardSidePeek={isDragRequestCard}
                  isHiddenFields={isHiddenFields}
                  collectFinalFiles={collectFinalFiles}
                  isDisableFileList={isDisableFile || !!selectedRequest?.parent_id}
                  files={responseFiles || []}
                  moreLimitFiles={responseMoreLimitFiles || []}
                  handleMoreLimitFiles={handleResponseMoreLimitFiles}
                  selectedRequest={selectedRequest}
                  source={'FC'}
                  cardType={'nf'}
                  isEditable={true}
                  isEditMode={true}
                  delSavedFile={delSavedFile}
                  fileLoading={animationLoading}
                  loadingFilesName={loadingFilesName}
                  handleDownloadAll={handleDownloadAll}
                  customFileUploaderClassName={`${themeClass}_customResponseTabFileUploader${isDragRequestCard ? '_sidePeekView' : ''}`}
                />
              </div>
            )}
            {isMobile && <Divider direction={'horizontal'} type={'srf-4'} />}
            {(selectedRequest?.action_dates?.response?.date ||
              (selectedRequest?.action_dates?.complete?.date && selectedRequest?.status === 'accepted') ||
              (responseActionMessageData && selectedRequest?.status !== 'provided')) && (
              <div className={'infoBlockContainer'}>
                {responseActionMessageData && selectedRequest?.status !== 'provided' && (
                  <div
                    className={cn(`${themeClass}_responseSection`, {
                      '-warning': responseActionMessageData.color === 'warning',
                      '-danger': responseActionMessageData.color === 'danger',
                    })}
                  >
                    <span className={`${themeClass}_responseSection_prefix`}>{responseActionMessageData.prefix}</span>
                    {selectedRequest?.response_user ? (
                      renderHistoryAction(
                        '',
                        responseActionMessageData.date,
                        selectedRequest?.response_user?.profile?.first_name,
                        selectedRequest?.response_user?.profile?.last_name,
                        selectedRequest?.response_user,
                        true,
                        selectedRequest?.response_party?.company || '',
                        selectedRequest?.response_party?.renderings?.file || '',
                      )
                    ) : (
                      <span className={`${themeClass}_responseSection_user`}>Response manager</span>
                    )}
                    {responsePostfix}
                  </div>
                )}
                {selectedRequest?.action_dates?.response?.date &&
                  (selectedRequest?.status === 'provided' ||
                    selectedRequest?.status === 'accepted' ||
                    selectedRequest?.status === 'declined') && (
                    <div className={'sentBy'}>
                      {renderHistoryAction(
                        'send',
                        selectedRequest?.action_dates?.response?.date,
                        selectedRequest?.action_dates?.response?.user?.profile?.first_name,
                        selectedRequest?.action_dates?.response?.user?.profile?.last_name,
                        selectedRequest?.action_dates?.response?.user,
                      )}
                    </div>
                  )}
                {selectedRequest?.action_dates?.complete?.date && selectedRequest?.status === 'accepted' && (
                  <div className={'updatedBy'}>
                    {renderHistoryAction(
                      'accepted',
                      selectedRequest?.action_dates?.complete?.date,
                      selectedRequest?.action_dates?.complete?.user?.profile?.first_name,
                      selectedRequest?.action_dates?.complete?.user?.profile?.last_name,
                      selectedRequest?.action_dates?.complete?.user,
                    )}
                  </div>
                )}
              </div>
            )}
            {blockCommitAction()}
          </div>
        </div>
      )}
    </div>
  );
};

export default withConfirmDialogContext(ResponseTab);
