import React, { FC, useState, useEffect, useRef } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import Step1Container from './parts/Step1/Step1Container';
import Step2Container from './parts/Step2/Step2Container';
import Step4Container from './parts/Step4/Step4ContainerNew';
import { TabsBar } from '../../controls/TabsBarParts/TabsBar';
import Step5Container from './parts/Step5/Step5Container';

import './CreateProjectStyles.scss';

const tabsGroups = {
  sections: [
    {
      id: 1,
      title: 'General information',
    },
    {
      id: 2,
      title: 'Buildings & Locations',
    },
    {
      id: 4,
      title: 'Content Plan',
    },
    {
      id: 5,
      title: 'Integrations',
    },
  ],
};

const CreateProjectView: FC<{
  step: number;
  project: number;
  redirectToStep: (step: number) => void;
  exitEditMode: () => void;
}> = ({ step, project, redirectToStep, exitEditMode }) => {
  const themeClass = useGetThemeClass('b-create-project');
  const [selectedTabFist, setSelectedTabFist] = useState(step);
  const containerRef = useRef<HTMLDivElement>();

  const handleChangeTabBar = (event: React.SyntheticEvent, tabName: string) => {
    redirectToStep(Number(tabName));
  };

  useEffect(() => {
    setSelectedTabFist(step);
  }, [step]);

  return (
    <div className={`${themeClass}`}>
      <div className={`${themeClass}_title_container`}>
        <div className={`${themeClass}_title_container_title`}>{project.id ? 'Updating' : 'Creating'} a project</div>
      </div>
      <div className={'tab_bar_container'}>
        <TabsBar
          relatedTo={tabsGroups.relatedTo}
          sections={tabsGroups.sections}
          selectedTab={selectedTabFist}
          handleChange={handleChangeTabBar}
          activeSectionType={tabsGroups.activeSectionType}
          isCountersLoading={false}
          type={'project'}
        />
      </div>
      <div className={'tab_container'} ref={containerRef}>
        {step === 1 ? <Step1Container step={step} redirectToStep={redirectToStep} exitEditMode={exitEditMode} /> : null}
        {step === 2 ? (
          <Step2Container step={step} redirectToStep={redirectToStep} exitEditMode={exitEditMode} containerRef={containerRef} />
        ) : null}
        {step === 4 ? <Step4Container exitEditMode={exitEditMode} /> : null}
        {step === 5 ? <Step5Container step={step} redirectToStep={redirectToStep} /> : null}
      </div>
    </div>
  );
};

export default React.memo(CreateProjectView);
