import React, { useMemo } from 'react';
import { Select } from '../../../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../../../../models/global';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';

import './ProjectSelectStyle.module.scss';

interface projectType {
  id: number | string;
  title: string;
  alias?: string;
}

interface IProps {
  values: projectType[];
  selectedId: string;
  error?: string;
  selectHandler: (id: number | string) => void;
}

const ProjectSelect = ({ values, selectedId, error, selectHandler }: IProps) => {
  const themeClass = useGetThemeClass('b-project-select');

  const projectTypesItems = useMemo(() => {
    return values
      ? values.map(item => {
          const obj = {
            value: item.id,
            title: item.title,
          };
          return obj;
        })
      : [];
  }, [values]);
  const selected = useMemo(() => {
    const findIndex = values?.findIndex(item => item.id == selectedId);
    if (findIndex > -1) {
      const obj = {
        value: values[findIndex].id,
        title: values[findIndex].title,
      };
      return obj;
    } else {
      return null;
    }
  }, [values, selectedId]);

  const renderSingleSelectItem = (item: DropdownItemModel) => {
    return <div>{item.title}</div>;
  };

  const handleSelectSection = (item: DropdownItemModel) => {
    selectHandler(item.value);
  };

  return (
    <div className={`${themeClass}`}>
      <Select<DropdownItemModel, null, false>
        isMulti={false}
        menuItems={projectTypesItems}
        selectHandler={handleSelectSection}
        selected={selected}
        headerAdditionalText={'Additional text'}
        isLoading={false}
        basisPlaceholder={'Select'}
        renderBasisSelectedItem={item => item.title}
        customBasisFieldClass={'customClassProjectTypeWidth'}
        menuItemSize={'md'}
        menuItemTitleRenderer={renderSingleSelectItem}
        error={error}
      />
    </div>
  );
};

export default ProjectSelect;
