import React, { FC, useEffect, useState } from 'react';
import { ButtonView } from '../../../../controls';
import { DropdownItemModel, SortingDataModel } from '../../../../../models/global';
import DropdownSelectMenu from '../../DropdownPopovers/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownMenuItem } from '../../DropdownParts';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import './SortingFieldStyles.scss';
import { FilterButton } from '../../../ButtonComponents';

interface IProps {
  values: DropdownItemModel[];
  onSort: (payload: SortingDataModel) => void;
  sortInfo: SortingDataModel;
  disabled?: boolean;
  isMobile?: boolean;
  isHighResolution?: boolean;
}

interface IState {
  values: DropdownItemModel[];
  isMenuOpen: boolean;
  anchorElFilter: any;
  sortingValue: string | number;
  sortingType: string;
  isSortingActive: boolean;
}

const SortingField: FC<IProps> = props => {
  const { values, onSort, sortInfo, disabled, isMobile, isHighResolution } = props;

  const themeClass = useGetThemeClass('b-sortingField');

  const initialState: IState = {
    values: props.values,
    isMenuOpen: false,
    anchorElFilter: null,
    sortingValue: props.sortInfo?.order_by || '',
    sortingType: props.sortInfo?.order_dir || '',
    isSortingActive: true,
  };

  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    if (sortInfo) {
      setState({
        ...state,
        sortingValue: sortInfo.order_by,
        sortingType: sortInfo.order_dir,
        isSortingActive: !sortInfo.order_by && !sortInfo.order_dir ? true : state.isSortingActive,
      });
    }
  }, [sortInfo]);

  const handleOpen = event => {
    setState({
      ...state,
      isMenuOpen: true,
      anchorElFilter: event.currentTarget,
    });
  };

  const handleClose = () => {
    setState({ ...state, isMenuOpen: false, anchorElFilter: null });
  };

  const renderRightItem = (item: DropdownItemModel) => {
    if (state.sortingValue === item.value) {
      return (
        <>
          <div className={`${themeClass}_sortingIcon`}>
            {state.sortingType === 'asc' ? (
              <TokenIcon iconName={'arrow-up'} size={isMobile ? 24 : 16} />
            ) : (
              <TokenIcon iconName={'arrow-down'} size={isMobile ? 24 : 16} />
            )}
          </div>
        </>
      );
    }

    return null;
  };

  const handleChangeSortValue = (value: string | number) => {
    let sort = 'asc';

    if (value === 'created_at' || value === 'nf_action_date' || value === 'milestone') {
      sort = 'desc';
      if (state.sortingValue === value) {
        if (state.sortingType === 'desc') {
          sort = 'asc';
          setState({ ...state, sortingValue: value, sortingType: 'asc', isSortingActive: false });
        } else {
          setState({ ...state, sortingValue: value, sortingType: 'desc', isSortingActive: true });
        }
      } else {
        setState({ ...state, sortingValue: value, sortingType: 'desc', isSortingActive: true });
      }
    } else {
      if (state.sortingValue === value) {
        if (state.sortingType === 'asc') {
          sort = 'desc';
          setState({ ...state, sortingValue: value, sortingType: 'desc', isSortingActive: false });
        } else {
          setState({ ...state, sortingValue: value, sortingType: 'asc', isSortingActive: true });
        }
      } else {
        setState({ ...state, sortingValue: value, sortingType: 'asc', isSortingActive: true });
      }
    }

    const payload = {
      order_by: !state.isSortingActive && state.sortingValue === value ? '' : value,
      order_dir: !state.isSortingActive && state.sortingValue === value ? '' : sort,
    };

    onSort(payload);
  };

  const renderMenuItem = (item: DropdownItemModel) => {
    const rightElem = renderRightItem(item);
    const isSelected = state.sortingValue === item.value;

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'md'}
        title={item.title}
        value={item.value}
        onItemClick={value => handleChangeSortValue(value)}
        isSelected={isSelected}
        rightElem={rightElem}
        isMobile={isMobile}
      />
    );
  };

  const { isMenuOpen, anchorElFilter, sortingValue } = state;

  return (
    <>
      <FilterButton
        disabled={disabled}
        type={'sort'}
        clickHandler={handleOpen}
        isPressed={isMenuOpen}
        isActive={!!sortingValue}
        isMobile={isMobile}
      />
      <DropdownSelectMenu
        isMenuOpen={isMenuOpen}
        renderItemHeader={null}
        renderItemFooter={null}
        renderMenuItem={renderMenuItem}
        menuItems={values}
        size={'md'}
        anchorEl={anchorElFilter}
        onClose={handleClose}
        isMobile={isMobile}
        isHighResolution={isHighResolution}
      />
    </>
  );
};

export default SortingField;
