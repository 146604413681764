import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/configure/configureStore';
import { SandBoxAlertWidgetResponseModel, SandBoxWidgetsPayloadModel } from '../../../../../../../store/request/requestReducer.model';
import { requestSlice } from '../../../../../../../store/request/requestReducer(HOLD)';
import { sandBoxNfSections, sandBoxDeliverableSections } from '../../../../SandBoxConstants/SandBoxConstants';
import { deleteFromPostponedSandBoxAlerts, postponeSandBoxAlert } from '../../../../../../../store/request/requestLogic(HOLD)';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import SegmentedButton from '../../../../../../controls/ButtonComponents/SegmentedButton/SegmentedButton';
import CustomButton from '../../../../../../controls/ButtonComponents/CustomButton/CustomButton';
import TokenIcon from '../../../../../../controls/TokenIcon/TokenIcon';
import CustomSkeleton from '../../../../../../controls/CustomSkeleton/CustomSkeleton';

import './AlertListStyles.scss';

const { setActiveSandBoxAlertSnippetActions } = requestSlice.actions;

interface IProps {
  loadWidgets: (widgets: SandBoxWidgetsPayloadModel) => void;
  relatedTo: 'user' | 'party';
  openRequest: (id: number, nf: number, accordionAlias?: string, isMarked?: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  selectSubSection: (subSectionId: string, shouldSetSectionId?: boolean) => void;
  activeSectionId: string;
}

const alertTabsInitial = [
  { id: 'total', title: 'Total' },
  { id: 'postponed', title: 'Postponed' },
];

const AlertList = ({ relatedTo, loadWidgets, openRequest, openDeliverable, selectSubSection, activeSectionId }: IProps) => {
  const dispatch = useAppDispatch();

  const alertList = useAppSelector(state => state.requestReducer.sandBoxWidgets?.alerts);
  const sandBoxWidgetsLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxWidgetsLoading);
  const sandBoxData = useAppSelector(state => state.requestReducer.sandBox.accordion_list);
  const sandBoxCounters = useAppSelector(state => state.requestReducer.sandBox.counters);

  const themeClass = useGetThemeClass('b-sandBox-alertList');

  const [selectedTab, setSelectedTab] = useState<'total' | 'postponed'>('total');
  const [hoveredSnippet, setHoveredSnippet] = useState<SandBoxAlertWidgetResponseModel | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const alertListToShow = useMemo(() => {
    let alertItems = [];

    if (selectedTab === 'total' && alertList?.feed && alertList?.feed?.length) {
      alertItems = alertList?.feed;
    }

    if (selectedTab === 'postponed' && alertList?.remind_later && alertList?.remind_later?.length) {
      alertItems = alertList?.remind_later;
    }

    const allowedItemsLength = currentPage * 10;
    const allowedItems = alertItems.slice(0, allowedItemsLength);

    const allItemsLengthDifference = alertItems.length - allowedItems.length;

    return { list: allowedItems, moreItemsLength: allItemsLengthDifference };
  }, [alertList, selectedTab, currentPage]);

  const getAlertSvg = alert => {
    if (alert.type === 'overdueRevision' || alert.type === 'requiredReview') {
      return (
        <div className={`${themeClass}_content_alertSnippet_content_headline_icon_deliverables`}>
          <TokenIcon iconName={'deliverables'} size={16} />
        </div>
      );
    }

    if (alert.type === 'userMentioned' || alert.type === 'bicOverloaded') {
      return (
        <div className={`${themeClass}_content_alertSnippet_content_headline_icon_bic`}>
          <TokenIcon iconName={'bic'} size={16} />
        </div>
      );
    }
  };

  const getEndLineStyle = alert => {
    if (alert.type === 'overdueRevision' || alert.type === 'requiredReview' || alert.type === 'bicOverloaded') {
      return {
        color: '#ED5147',
      };
    }

    if (alert.type === 'userMentioned') {
      if (alert.entity_type === 'request') {
        return {
          color: '#0075EB',
          fontWeight: 700,
        };
      }

      if (alert.entity_type === 'deliverable') {
        return {
          color: 'var(--color)',
          fontWeight: 400,
        };
      }
    }
  };

  const findSandBoxItem = (id: number) => {
    return sandBoxData.reduce((accumulator, accordion) => {
      const changingSnippet = accordion.data.find(sandSnippet => sandSnippet.id === id);

      if (changingSnippet) {
        accumulator = {
          alias: accordion.alias,
          isMarked: changingSnippet.marker,
        };
      }

      return accumulator;
    }, {});
  };

  const handleActivateSnippet = (snippet: SandBoxAlertWidgetResponseModel) => {
    const isDeliverable = activeSectionId.includes('deliverable');

    if (snippet.type === 'overdueRevision' || snippet.type === 'requiredReview') {
      let deliverableSandBoxSnippetInfo;

      if (isDeliverable) {
        deliverableSandBoxSnippetInfo = findSandBoxItem(snippet.action.deliverable_id);
      }

      openDeliverable(
        snippet.action.deliverable_id,
        snippet.action.url_number,
        deliverableSandBoxSnippetInfo?.alias,
        deliverableSandBoxSnippetInfo?.isMarked,
      );
    }

    if (snippet.type === 'userMentioned') {
      const snippetId = snippet.entity_type === 'request' ? snippet.action.request_id : snippet.action.deliverable_id;
      const snippetOpenFunc = snippet.entity_type === 'request' ? openRequest : openDeliverable;

      const snippetInfo = findSandBoxItem(snippetId);

      snippetOpenFunc(snippetId, snippet.action.url_number, snippetInfo?.alias, snippetInfo?.isMarked);
    }

    if (snippet.type === 'bicOverloaded') {
      const allSections = sandBoxNfSections.concat(sandBoxDeliverableSections);
      const inMyCourtSubSections = allSections.find(section => section.id === `${snippet.entity_type}_inMyCourt`).subSections;
      const inMyCourtSubSectionsCountersSortedByCount = inMyCourtSubSections
        .map(subSection => {
          return {
            id: subSection.id,
            count: sandBoxCounters[subSection.id],
          };
        })
        .sort((a, b) => a.count - b.count);

      const highestCountSubSection = inMyCourtSubSectionsCountersSortedByCount.at(-1);

      selectSubSection(highestCountSubSection.id, true);
    }

    dispatch(setActiveSandBoxAlertSnippetActions(snippet.action));
  };

  const showAlertList = useMemo(() => {
    return !!Object.values(sandBoxCounters || {}).length && alertList;
  }, [sandBoxData, sandBoxCounters]);

  const handleSetSelectedTab = (tabId: 'total' | 'postponed') => {
    setSelectedTab(tabId);
  };

  const handleAlertEndField = (e: Event, alert: SandBoxAlertWidgetResponseModel) => {
    e.stopPropagation();

    const widgetOptions = {
      related_to: relatedTo,
      widgets: [
        {
          alias: 'alerts',
          options: {
            types: ['overdueRevision', 'requiredReview', 'userMentioned', 'bicOverloaded'],
          },
        },
      ],
    };

    if (selectedTab === 'total') {
      const entity_id = alert.action[`${alert.entity_type}_id`];

      const postponeData = {
        entity_id,
        party_id: alert.party_id,
        entity_type: alert.entity_type,
        alert: alert.type,
        data: JSON.stringify(alert),
        callback: () => {
          loadWidgets(widgetOptions);
        },
      };

      dispatch(postponeSandBoxAlert(postponeData));
    }

    if (selectedTab === 'postponed') {
      dispatch(
        deleteFromPostponedSandBoxAlerts({
          id: alert.id,
          callback: () => {
            loadWidgets(widgetOptions);
          },
        }),
      );
    }
  };

  const handleSetPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const postponeMessageTitle = useMemo(() => {
    if (selectedTab === 'total') {
      return 'Remind me later';
    }

    if (selectedTab === 'postponed') {
      return 'Bring back';
    }
  }, [selectedTab]);

  const alertTabs = useMemo(() => {
    return alertTabsInitial.map(tab => {
      const tabTotal = tab.id === 'total' ? alertList?.feed?.length : alertList?.remind_later?.length;

      return {
        ...tab,
        count: tabTotal,
      };
    });
  }, [alertTabsInitial, alertList]);

  return (
    <>
      <div className={themeClass}>
        <div className={`${themeClass}_header`}>
          <div className={`${themeClass}_header_title`}>
            <span>Feed</span>
          </div>
          {/*<div className={`${themeClass}_header_settings`}>*/}
          {/*  <SVG src={require('../../../../../../../assets/icons/settings-smooth.svg')} />*/}
          {/*</div>*/}
        </div>
        {showAlertList ? <SegmentedButton buttons={alertTabs} selectedButtonId={selectedTab} changeHandler={handleSetSelectedTab} /> : null}
        <>
          {sandBoxWidgetsLoading.loading || !showAlertList ? (
            <div className={`${themeClass}_skeletonColumn`}>
              <CustomSkeleton width={175} height={34} />
              <CustomSkeleton width={'100%'} height={40} />
              <CustomSkeleton width={'100%'} height={40} />
              <CustomSkeleton width={'100%'} height={40} />
            </div>
          ) : (
            <div className={`${themeClass}_content`}>
              {showAlertList &&
                !!alertListToShow.list.length &&
                alertListToShow.list.map((alert, index) => {
                  const alertSVG = getAlertSvg(alert);
                  const endLineStyle = getEndLineStyle(alert);
                  const isHovered = JSON.stringify(alert) === JSON.stringify(hoveredSnippet);
                  const isCouldPostpone = alert.type === 'overdueRevision' || alert.type === 'requiredReview';
                  const isShowRemindLater = isHovered && isCouldPostpone;

                  return (
                    <div
                      onClick={() => handleActivateSnippet(alert)}
                      onMouseEnter={() => setHoveredSnippet(alert)}
                      onMouseLeave={() => setHoveredSnippet(null)}
                      key={index}
                      className={`${themeClass}_content_alertSnippet -${alert.type}`}
                    >
                      <div className={`${themeClass}_content_alertSnippet_content`}>
                        <div className={`${themeClass}_content_alertSnippet_content_headline`}>
                          <div className={`${themeClass}_content_alertSnippet_content_headline_icon`}>{alertSVG}</div>
                          <div className={`${themeClass}_content_alertSnippet_content_headline_text`}>
                            <span>{alert.title}</span>
                          </div>
                        </div>
                        <div className={`${themeClass}_content_alertSnippet_content_midLine`}>
                          <span>{alert.subtitle}</span>
                        </div>
                        <div className={`${themeClass}_content_alertSnippet_content_endLine`}>
                          <div
                            style={{ display: isShowRemindLater ? 'none' : '' }}
                            className={`${themeClass}_content_alertSnippet_content_endLine_label`}
                          >
                            <span
                              style={{
                                ...(endLineStyle || {}),
                              }}
                            >
                              {alert.context}
                            </span>
                          </div>
                          <div style={{ display: isShowRemindLater ? 'flex' : 'none' }}>
                            <CustomButton
                              type={'text-plain'}
                              size={'xs'}
                              clickHandler={e => handleAlertEndField(e, alert)}
                              icon={<TokenIcon iconName={'remind-later'} size={12} />}
                              iconClass={`${themeClass}_content_alertSnippet_content_endLine_remindLater_clock`}
                              title={postponeMessageTitle}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {showAlertList && alertListToShow.moreItemsLength > 0 ? (
                <div onClick={handleSetPage} className={`${themeClass}_content_showMore`}>
                  <span>{alertListToShow.moreItemsLength} more alerts</span>
                </div>
              ) : null}
              {!showAlertList || !alertListToShow.list.length ? (
                <div className={`${themeClass}_content_empty`}>
                  <span>You have no alerts in your feed!</span>
                </div>
              ) : null}
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default AlertList;
