import React, { Component } from 'react';
import PopUpView from '../../../../controls/PopUp/PopUpView';
import { UserPermissionsModel } from '../../../../../models';
import glossaryContents from '../helpContents/GlossaryContents';
import SVG from 'react-inlinesvg';
import GlossarySearch from '../help_controls/GlossarySearch';
import moment from 'moment';

interface IProps {
  open: boolean;
  close: () => void;
}

interface IState {
  glossaryHelpContents: UserPermissionsModel[];
  refs: any;
}

class Glossary extends Component<IProps, IState> {
  state = {
    glossaryHelpContents: glossaryContents,
    refs: {},
  };

  componentDidMount() {
    const refs = {};

    glossaryContents.map(value => {
      value.headers.map(item => {
        refs[`${value.id}-${item.id}`] = React.createRef();
      });
    });

    this.setState({
      refs,
    });
  }

  hideMainHeader = (id: number) => {
    const hide = state => {
      const newState = state;
      newState[id].isClosed = !state[id].isClosed;
      return newState;
    };

    this.setState(prev => ({
      glossaryHelpContents: hide(prev.glossaryHelpContents),
    }));
  };

  hideSubHeader = (mainHeaderId: number, subHeaderId: number) => {
    const hide = state => {
      const newState = state;
      newState[mainHeaderId].headers[subHeaderId].isClosed = !state[mainHeaderId].headers[subHeaderId].isClosed;
      return newState;
    };

    this.setState(prev => ({
      glossaryHelpContents: hide(prev.glossaryHelpContents),
    }));
  };

  scrollToHeader = (mainHeadId, subHeadId) => {
    this.state.refs[`${mainHeadId}-${subHeadId}`]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });

    const open = state => {
      const newState = state;
      newState[mainHeadId].headers[subHeadId].isClosed = false;
      return newState;
    };

    this.setState(prev => ({
      glossaryHelpContents: open(prev.glossaryHelpContents),
    }));
  };

  render() {
    const { glossaryHelpContents } = this.state;

    const { open, close } = this.props;

    return (
      <PopUpView
        open={open}
        onClose={close}
        title={`Glossary`}
        maxWidth={'lg'}
        className={'library-popup'}
        disableBtn={true}
        customButton={<GlossarySearch scrollToHeader={this.scrollToHeader} />}
      >
        <div className={'tutorialPopupContent'} style={{ padding: '0px', minHeight: '550px' }}>
          <div className={'popup-content'} style={{ margin: '0px' }}>
            {glossaryHelpContents.map(mainHeader => {
              return (
                <>
                  <div className={'popup-content__accordion -glossary'} style={{ cursor: 'default' }} key={mainHeader.id}>
                    <div className={'popup-content__accordionContent'}>
                      {/*<SVG*/}
                      {/*  src={require('../../../../../assets/icons/arrow_right.svg')}*/}
                      {/*  className='popup-content__accordionArrow'*/}
                      {/*  // style={faqHelpContents[mainHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}*/}
                      {/*  // onClick={() => this.hideMainHeader(mainHeader.id)}*/}
                      {/*/>*/}
                      <span
                        // onClick={() => this.hideMainHeader(mainHeader.id)}
                        className={'popup-content__text library-headerText'}
                        style={{ cursor: 'default' }}
                      >
                        {mainHeader.title}
                      </span>
                    </div>
                  </div>
                  <div
                  // style={faqHelpContents[mainHeader.id].isClosed ? {display:'none'} : {}}
                  >
                    {mainHeader.headers.map(subHeader => {
                      const mainHeaderItem = glossaryHelpContents[mainHeader.id].headers;
                      return (
                        <>
                          <div
                            className={'popup-content__accordion -subHeader'}
                            key={subHeader.id}
                            onClick={() => this.hideSubHeader(mainHeader.id, subHeader.id)}
                            ref={this.state.refs[`${mainHeader.id}-${subHeader.id}`]}
                          >
                            <div className={'popup-content__accordionContent -subHeader'}>
                              <SVG
                                src={require('../../../../../assets/icons/arrow_right.svg')}
                                className="popup-content__accordionArrow"
                                style={mainHeaderItem[subHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}
                              />
                              <span className={'popup-content__text'}>{subHeader.title}</span>
                            </div>
                          </div>
                          <div
                            className={'popup-content__text library-text'}
                            style={mainHeaderItem[subHeader.id].isClosed ? { display: 'none' } : {}}
                          >
                            <p>{subHeader.text}</p>
                          </div>
                          <div className={'library-borderline'} />
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </PopUpView>
    );
  }
}

export default Glossary;
