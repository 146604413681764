import React, { ReactNode } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { ItemsCounter } from '../../../components/controls/ItemsСounter';
import MiniLoader from '../../../components/controls/MiniLoader/MiniLoader';
import SystemButton from '../../../components/controls/ButtonComponents/SystemButton/SystemButton';
import TokenIcon from '../../../components/controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './SubmenuNewStyle.module.scss';

interface SubmenuProps {
  title: string;
  isParent: boolean;
  type: 'expanded' | 'collapsed';
  openedFrom: string;
  count?: number;
  isSelected?: boolean;
  isSwitchIcons?: boolean;
  onClick?: () => void;
  leftElem?: ReactNode;
  rightElem?: ReactNode;
  rightElemClick?: () => void;
  disabled?: boolean;
  isCountersLoading?: boolean;
  customClassName?: string;
  typeSystemButton?: 'chevron-down' | 'chevron-right' | null;
  isHovered?: boolean;
  isSelectGroup?: boolean;
}

const SubmenuNew: React.FC<SubmenuProps> = ({
  title,
  isParent,
  type,
  openedFrom,
  count,
  isSelected,
  isSwitchIcons,
  onClick,
  leftElem,
  rightElem,
  rightElemClick,
  disabled,
  isCountersLoading,
  customClassName,
  typeSystemButton,
  isHovered,
  isSelectGroup,
}) => {
  const themeClass = useGetThemeClass('b-submenuNew');

  const onItemClick = e => {
    e.stopPropagation();

    onClick && onClick();
  };

  const onRightElemClick = e => {
    e.stopPropagation();

    rightElemClick && rightElemClick();
  };

  const getCount = (count: number | null) => {
    if (!count) {
      return 0;
    }
    if (count > 99) {
      return '99+';
    } else {
      return count;
    }
  };

  const getTitle = (title: string) => {
    if (type === 'expanded') {
      return title;
    }
    if (isParent) {
      return <div className={`${themeClass}_label`}>{title}</div>;
    }
    if (isHovered) {
      return (
        <div className={`${themeClass}_sub_item_title`}>
          <div
            className={cn(`${themeClass}_indicator`, {
              [`-selected`]: isSelected,
            })}
          >
            <TokenIcon iconName={'ellipse-220'} size={4} />
          </div>
          <div className={`${themeClass}_label_sub`}>{title}</div>
        </div>
      );
    } else {
      return (
        <div
          className={cn(`${themeClass}_indicator`, {
            [`-selected`]: isSelected,
          })}
        >
          <TokenIcon iconName={'ellipse-220'} size={4} />
        </div>
      );
    }
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-selectGroup']: isSelectGroup && (type === 'collapsed' ? !isHovered : true),
      })}
    >
      <div
        className={cn(`${themeClass}_item`, {
          [`-disabled`]: disabled,
          [`-selected`]: isSelected,
          [`-subitem`]: !isParent,
          [`-${openedFrom === 'report' ? 'report_' : ''}${type}`]: type,
          [`${customClassName}`]: customClassName,
        })}
        onClick={isParent ? (typeSystemButton === 'chevron-right' ? onItemClick : null) : onItemClick}
      >
        <div className={`${themeClass}_text_icon`}>
          {typeSystemButton && type === 'expanded' && (
            <div className={cn('', { [`${themeClass}_width20`]: typeSystemButton === 'chevron-down' })}>
              {typeSystemButton === 'chevron-right' && <SystemButton type={typeSystemButton} size={'md'} variant={'transparent'} />}
            </div>
          )}
          <div
            className={cn(`${themeClass}_title_icon`, {
              [`-collapsed`]: type === 'collapsed' && !isHovered,
            })}
          >
            {leftElem && leftElem}
            <div
              className={cn({
                [`${themeClass}_label_item`]: isParent,
                [`${themeClass}_label_sub_item`]: !isParent,
                [`${themeClass}_selectedTitle`]: isSelected,
                [`${themeClass}_hidden`]: type === 'collapsed' && !isHovered && isParent,
              })}
            >
              {getTitle(title)}
            </div>
          </div>
        </div>

        <div
          className={cn(`${themeClass}_items_counter`, {
            [`-isParent`]: isParent,
          })}
        >
          {!isSwitchIcons && !isCountersLoading && <ItemsCounter value={getCount(count)} size={'sm'} />}
          {isCountersLoading && (
            <div className={'downloadBoxLoading'}>
              <MiniLoader size={'sm'} circleClasses={cn(`${themeClass}_loader`, { ['-selected']: isSelected })} />
            </div>
          )}
          {rightElem && isSwitchIcons && !isCountersLoading && (
            <div className={`${themeClass}_icon -right`} onClick={onRightElemClick}>
              {rightElem}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmenuNew;
