import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import ForgotPasswordView from './ForgotPasswordView';
import * as ROUTES from '../../../navigation/routes';
import { Navigate } from 'react-router-dom';
import { forgotPassword } from '../../../store/user/userThunk';
import { userSlice } from '../../../store/user/userSlice';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
const { clearMessage } = userSlice.actions;

class ForgotPasswordComponent extends React.Component {
  static propTypes = {
    //actions
    login: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    user_data: PropTypes.object.isRequired,
  };

  state = {
    email: '',
    redirect: false,
    theme: '',
  };

  componentDidMount() {
    if (
      (!!localStorage.getItem('theme') && this.state.theme !== localStorage.getItem('theme')) ||
      (!localStorage.getItem('theme') && this.props.user_data.userInfo.theme && this.state.theme !== this.props.user_data.userInfo.theme)
    ) {
      this.setState({ theme: localStorage.getItem('theme') || this.props.user_data.userInfo.theme });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!!localStorage.getItem('theme') && this.state.theme !== localStorage.getItem('theme')) ||
      (!localStorage.getItem('theme') && this.props.user_data.userInfo.theme && this.state.theme !== this.props.user_data.userInfo.theme)
    ) {
      this.setState({ theme: localStorage.getItem('theme') || this.props.user_data.userInfo.theme });
    }
  }

  handleChangeField = value => {
    this.setState({ email: value });
  };

  handleLogin = () => {
    const { email } = this.state;
    const { loading } = this.props;

    let err = false;
    if (!err || !loading) {
      this.props.forgotPassword({ email });
    }
  };

  decline = () => {
    this.setState({ redirect: true });
    this.props.clearMessage();
  };

  render() {
    const { email, theme } = this.state;
    const { loading, message, status } = this.props;
    return (
      <>
        {this.state.redirect && <Navigate to={ROUTES.loginP} />}
        {status && (
          <DialogPopUp open={true} onClose={this.props.decline} modalText={message} primaryText={'Ok'} handleOnPrimary={this.decline} />
        )}
        <ForgotPasswordView
          email={email}
          handleChangeField={this.handleChangeField}
          handleLogin={this.handleLogin}
          loading={loading}
          message={message}
          status={status}
          theme={theme}
          clearMessage={this.props.clearMessage}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userReducer }) => ({
  loading: userReducer.loading,
  message: userReducer.message,
  status: userReducer.status,
  errors: userReducer.forgot_data,
  user_data: userReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      forgotPassword,
      clearMessage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent);
