import React, { FC, useCallback, useState, useRef } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { useClickOutside } from '../../../../../../helpers/commonHooks';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';

import './ReportingPieChartStyle.scss';

type ChartType = {
  title: string;
  color: string | null;
  total: number;
};

const ColorsLevelsDeliverable = [
  '#E56262',
  '#A73131',
  '#82A4EB',
  '#013985',
  '#F09E70',
  '#BC6B3E',
  '#85A1DB',
  '#002556',
  '#F9CA84',
  '#CBA56D',
  '#5E739E',
  '#F3A359',
  '#FF0000',
  '#2FB400',
  '#0075FF',
  '#FF7A00',
  '#00BDA6',
  '#BD00FF',
  '#6AA071',
  '#B6B77F',
  '#EF8C54',
  '#8F16C0',
  '#EBCE2B',
  '#96CDE6',
  '#FF6B00',
  '#BA1C30',
  '#C0BD7F',
  '#5FA641',
  '#D485B2',
  '#4076B4',
  '#DF8461',
  '#E0A11B',
  '#92AE31',
  '#A52481',
  '#F22515',
  '#E56262',
  '#A73131',
  '#82A4EB',
  '#013985',
  '#F09E70',
  '#BC6B3E',
  '#85A1DB',
  '#002556',
  '#F9CA84',
  '#CBA56D',
  '#5E739E',
  '#F3A359',
  '#FF0000',
  '#2FB400',
  '#0075FF',
  '#FF7A00',
  '#00BDA6',
  '#BD00FF',
  '#6AA071',
  '#B6B77F',
  '#EF8C54',
  '#8F16C0',
  '#EBCE2B',
  '#96CDE6',
  '#FF6B00',
  '#BA1C30',
  '#C0BD7F',
  '#5FA641',
  '#D485B2',
  '#4076B4',
  '#DF8461',
  '#E0A11B',
  '#92AE31',
  '#A52481',
  '#F22515',
];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    title,
    title2,
    name_group,
    total,
    themeClass,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const text_length = value > 0 && name_group?.length;
  const isVisibleWrap = textAnchor === 'start' && ey < 100 ? true : textAnchor === 'end' && ey < 100 ? true : false;
  return (
    <g>
      <text x={cx} y={cy - 5} dy={8} className={`${themeClass}_title_number`} textAnchor="middle" fill={'var(--color-secondary)'}>
        {total}
      </text>
      <text x={cx} y={cy + 15} dy={8} className={`${themeClass}_label_number`} textAnchor="middle" fill={'var(--color-noSelect)'}>
        {title}
      </text>
      {title2 && (
        <text x={cx} y={cy + 30} dy={8} className={`${themeClass}_label_number`} textAnchor="middle" fill={'var(--color-noSelect)'}>
          {title2}
        </text>
      )}
      {value > 0 ? (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      ) : null}
      {value > 0 ? (
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      ) : null}
      {value > 0 ? (
        text_length < 12 || isVisibleWrap ? (
          <text
            x={ex + (cos >= 0 ? 1 : -1)}
            y={ey}
            style={{ fontSize: '12px', zIndex: 1100 }}
            textAnchor={textAnchor}
            fill={fill}
          >{`${name_group} (${value})`}</text>
        ) : (
          <foreignObject x={ex + (textAnchor === 'end' ? -90 : 8)} y={ey} width="82" height="100">
            <div style={{ fontSize: '12px', color: fill, textAlign: 'center' }}>{`${name_group} (${value})`}</div>
          </foreignObject>
        )
      ) : null}
    </g>
  );
};

const ReportingPieChart: FC<{
  deliverable_chart: ChartType[];
  deliverable_chart_total: number;
  nf_chart: ChartType[];
  nf_chart_total: number;
  barChartList: any;
  selectGroup: string;
  handleIsHoverPie: (isHover: boolean) => void;
}> = ({ deliverable_chart, deliverable_chart_total, nf_chart, nf_chart_total, barChartList, selectGroup, handleIsHoverPie }) => {
  const [activeIndexFirst, setActiveIndexFirst] = useState(0);
  const [activeIndexSecond, setActiveIndexSecond] = useState(0);
  const themeClass = useGetThemeClass('b-recharts-pie');
  const onPieEnterFirst = (_, index) => {
    setActiveIndexFirst(index);
    setActiveIndexSecond(0);
    handleIsHoverPie(true);
  };
  const onPieLeaveFirst = _ => {
    setActiveIndexFirst(0);
    setActiveIndexSecond(0);
    handleIsHoverPie(false);
  };
  const onPieEnterSecond = (_, index) => {
    setActiveIndexSecond(index);
    setActiveIndexFirst(0);
  };
  const onPieLeaveSecond = _ => {
    setActiveIndexFirst(0);
    setActiveIndexSecond(0);
  };

  const data_first = nf_chart.map(item => {
    const obj = {
      title: 'NFs sent',
      total: nf_chart_total,
      name_group: item.title,
      value: item.total,
      color: item.color,
      themeClass: themeClass,
    };
    return obj;
  });

  if (data_first?.length === 0) {
    const objNull = {
      title: 'NFs sent',
      total: 0,
      name_group: '',
      value: 1,
      color: '',
      themeClass: themeClass,
    };
    data_first.push(objNull);
  }

  const objF = {
    title: 'NFs sent',
    total: nf_chart_total,
    name_group: '',
    value: 0,
    color: '',
    themeClass: themeClass,
  };

  data_first.unshift(objF);

  const data_second = deliverable_chart.map(item => {
    const obj = {
      title: 'Scheduled',
      title2: 'deliverables',
      total: deliverable_chart_total,
      name_group: item.title,
      value: item.total,
      color: item.color,
      themeClass: themeClass,
    };
    return obj;
  });

  if (data_second?.length === 0) {
    const objLNull = {
      title: 'Scheduled',
      title2: 'deliverables',
      total: 0,
      name_group: '',
      value: 1,
      color: '',
      themeClass: themeClass,
    };
    data_second.push(objLNull);
  }

  const objL = {
    title: 'Scheduled',
    title2: 'deliverables',
    total: deliverable_chart_total,
    name_group: '',
    value: 0,
    color: '',
    themeClass: themeClass,
  };

  data_second.unshift(objL);

  const getColor = (title, index, isDeliverable) => {
    if (selectGroup === 'By level') {
      const findIndex = barChartList?.findIndex(item => item.title == title);
      if (title) {
        if (findIndex > -1) {
          return barChartList[findIndex].color;
        } else {
          return ColorsLevelsDeliverable[index];
        }
      } else {
        return '#74BF89';
      }
    } else {
      if (isDeliverable) {
        return ColorsLevelsDeliverable[index];
      }
      const findIndex = barChartList?.findIndex(item => item.title == title);
      return findIndex > -1 ? barChartList[findIndex].color : '#74BF89';
    }
  };

  const onPieClose = () => {
    setActiveIndexFirst(0);
    setActiveIndexSecond(0);
  };

  const ref = useRef<HTMLDivElement>();
  useClickOutside(ref, onPieClose);

  return (
    <div ref={ref}>
      <div
        style={{
          display: (activeIndexFirst > 0 && activeIndexSecond === 0) || (!activeIndexFirst && !activeIndexSecond) ? 'block' : 'none',
          maxHeight: '330px',
        }}
      >
        <PieChart width={740} height={300} style={{ left: '-70px', top: '-30px' }}>
          <Pie
            activeIndex={activeIndexSecond}
            activeShape={renderActiveShape}
            data={data_second}
            cx={490}
            cy={165}
            innerRadius={63}
            outerRadius={83}
            paddingAngle={1}
            minAngle={1}
            dataKey="value"
            onMouseEnter={deliverable_chart.length > 0 ? onPieEnterSecond : null}
            onMouseLeave={deliverable_chart.length > 0 ? onPieLeaveSecond : null}
          >
            {data_second.map((item, index) => (
              <Cell key={`cell-${item.title}`} fill={item.color || getColor(item.name_group, index, true)} />
            ))}
          </Pie>
          <Pie
            activeIndex={activeIndexFirst}
            activeShape={renderActiveShape}
            data={data_first}
            cx={210}
            cy={165}
            innerRadius={63}
            outerRadius={83}
            paddingAngle={1}
            minAngle={1}
            dataKey="value"
            onMouseEnter={nf_chart.length > 0 ? onPieEnterFirst : null}
            onMouseLeave={nf_chart.length > 0 ? onPieLeaveFirst : null}
          >
            {data_first.map((item, index) => {
              return <Cell key={`cell-${item.title}`} fill={item.color || getColor(item.name_group, index, false)} />;
            })}
          </Pie>
        </PieChart>
      </div>
      <div style={{ display: activeIndexSecond > 0 ? 'block' : 'none', maxHeight: '330px' }}>
        <PieChart width={740} height={300} style={{ left: '-70px', top: '-30px' }}>
          <Pie
            activeIndex={activeIndexFirst}
            activeShape={renderActiveShape}
            data={data_first}
            cx={210}
            cy={165}
            innerRadius={63}
            outerRadius={83}
            paddingAngle={1}
            minAngle={1}
            dataKey="value"
            onMouseEnter={nf_chart.length > 0 ? onPieEnterFirst : null}
            onMouseLeave={nf_chart.length > 0 ? onPieLeaveFirst : null}
          >
            {data_first.map((item, index) => {
              return <Cell key={`cell-${item.title}`} fill={item.color || getColor(item.name_group, index, false)} />;
            })}
          </Pie>
          <Pie
            activeIndex={activeIndexSecond}
            activeShape={renderActiveShape}
            data={data_second}
            cx={490}
            cy={165}
            innerRadius={63}
            outerRadius={83}
            paddingAngle={1}
            minAngle={1}
            dataKey="value"
            onMouseEnter={deliverable_chart.length > 0 ? onPieEnterSecond : null}
            onMouseLeave={deliverable_chart.length > 0 ? onPieLeaveSecond : null}
          >
            {data_second.map((item, index) => (
              <Cell key={`cell-${item.title}`} fill={item.color || getColor(item.name_group, index, true)} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default React.memo(ReportingPieChart);
