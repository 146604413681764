import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { DropdownItemModel } from '../../../../../models/global';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { getFiltersConstants } from './FiltersConstants';
import { FilterButton } from '../../../../controls/ButtonComponents';
import { LinkedItemsShowType, LinkedType } from '../../RelatedItemsTabConstants';
import {
  FilterNameItemInterface,
  FilterOptionItemInterface,
  FilterParamsModel,
} from '../../../../../store/relatedItems/relatedItemsSlice.model';
import CardRenderSelect from '../../../CardRequest/controls/CardRenderSelect/CardRenderSelect';
import moment from 'moment';
import { PredecessorSuccessorModel } from '../../../../../models';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;

import './RelatedItemsFiltersSelectStyles.scss';

interface IProps {
  currentCard: 'Nf' | 'Deliverable' | 'Submittal';
  relatedTabType: RelatedItemsTabsType;
  part: LinkedType;
  selectedShowType: LinkedItemsShowType;
  linkedDataByShowType: any;
  setSelectedParams: (value: FilterParamsModel[], part: LinkedType) => void;
  isGrouping: boolean;
  checkIfOverdue: (item: PredecessorSuccessorModel, type: LinkedType) => void;
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
}

const RelatedItemsFiltersSelect: React.FC<IProps> = ({
  currentCard,
  relatedTabType,
  part,
  selectedShowType,
  linkedDataByShowType,
  setSelectedParams,
  isGrouping,
  checkIfOverdue,
  selectedRequest,
}) => {
  const themeClass = useGetThemeClass('b-RelatedItemsFiltersSelect');

  const selectRef = useRef(null);

  const { isMobile } = GetResolution();

  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const [openedFilters, setOpenedFilters] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedOptionsParams, setSelectedOptionsParams] = useState<FilterParamsModel[]>([]);
  const [filterData, setFilterData] = useState<FilterNameItemInterface[]>([]);
  const [reGenerateFilterSelect, setReGenerateFilterSelect] = useState<boolean>(false);

  const handleCustomClearAll = () => {
    setSelectedOptionsParams([]);
    setSelectedOptions([]);
    setSelectedParams([], part);
    setReGenerateFilterSelect(true);
  };

  const menuItems = useMemo(() => {
    const filtersArray = getFiltersConstants({ currentCard, relatedTabType, part, selectedShowType, selectedRequest });
    const newArray = [];

    const flattenNestedArrays = (obj, resultArray) => {
      Object.values(obj).forEach(value => {
        if (Array.isArray(value)) {
          resultArray.push(...value);
        } else if (typeof value === 'object' && value !== null) {
          flattenNestedArrays(value, resultArray);
        }
      });
    };

    const newLinkedDataByShowType = isGrouping ? [] : linkedDataByShowType;
    const canGenerateFilter = Array.isArray(newLinkedDataByShowType);

    if (isGrouping) {
      flattenNestedArrays(linkedDataByShowType, newLinkedDataByShowType);
    }

    const generateValidityStatusNotVerified = (handler, snippet, parentValue) => {
      const filterOptions =
        (canGenerateFilter &&
          newLinkedDataByShowType
            ?.map(snippet => {
              const id = handler.getRequiringId(snippet);
              if (id === null) return null;

              return {
                value: id,
                title: handler.getRequiringTitle(snippet),
                selected: false,
                itemType: 'filterOption',
                parent_value: parentValue,
                isRequiringVerification: true,
              };
            })
            .filter(Boolean)) ||
        [];

      const uniqueFilterOptions = filterOptions?.reduce((acc, curr) => {
        const isDuplicate = acc.some(item => item.value === curr.value);
        if (!isDuplicate) {
          acc.push(curr);
        }
        return acc;
      }, []);

      return {
        value: handler.getId(snippet),
        title: handler.getTitle(snippet),
        isItemOpened: false,
        itemType: 'filterName',
        data: uniqueFilterOptions,
        parent_value: parentValue,
      };
    };

    const filterHandlersMap = {
      // nf snippets
      request: {
        requestType: {
          title: 'Request type',
          value: 'requestType',
          parent_value: 'requestType',
          getId: snippet => (snippet.tags?.[0]?.id ? `requestType${snippet.tags?.[0]?.id}` : 'empty_request_type'),
          getTitle: snippet => snippet.tags?.[0]?.title || '(Blanks)',
        },
        requestDiscipline: {
          title: 'Request discipline',
          value: 'requestDiscipline',
          parent_value: 'requestDiscipline',
          getId: snippet => snippet.request_discipline?.id || 'empty_discipline',
          getTitle: snippet => snippet.request_discipline?.title || '(Blanks)',
        },
        NfStatus: {
          title: 'NF status',
          value: 'NfStatus',
          parent_value: 'NfStatus',
          getId: snippet => snippet.status,
          getTitle: snippet =>
            ({
              drafted: 'Request drafted',
              sent: 'Request sent',
              submitted: 'Commitment submitted',
              provided: 'Response Submitted',
              accepted: 'Response Accepted',
              declined: 'Request re-opened',
            }[snippet.status]),
        },
        requestOverdue: {
          title: 'Request overdue',
          value: 'requestOverdue',
          parent_value: 'requestOverdue',
          getId: snippet => (moment(new Date()).isAfter(snippet.due_date) && snippet?.status !== 'accepted' ? 'true' : 'false'),
          // moment(new Date()).diff(snippet.due_date, 'days') > 0 && !snippet.due_date_change_request ? 'true' : 'false'
          getTitle: snippet =>
            // moment(new Date()).diff(snippet.due_date, 'days') > 0 && !snippet.due_date_change_request ? 'True' : 'False',
            moment(new Date()).isAfter(snippet.due_date) && snippet?.status !== 'accepted' ? 'True' : 'False',
        },
        dueDateThanThisNF: {
          title: `Due date ${part === 'predecessor' ? 'later' : 'earlier'} than this NF`,
          value: 'dueDateThanThisNF',
          parent_value: 'dueDateThanThisNF',
          getId: snippet => {
            const overdueData = checkIfOverdue(snippet, part);
            return overdueData?.isOverdue ? 'true' : 'false';
          },
          getTitle: snippet => {
            const overdueData = checkIfOverdue(snippet, part);
            return overdueData?.isOverdue ? 'True' : 'False';
          },
        },
        responseIncorporated: {
          title: 'Response incorporated',
          value: 'responseIncorporated',
          parent_value: 'responseIncorporated',
          getId: snippet => (snippet.is_incorporated ? 'true' : 'false'),
          getTitle: snippet => (snippet.is_incorporated ? 'True' : 'False'),
        },
        localIncorporation: {
          title: 'Local incorporation',
          value: 'localIncorporation',
          parent_value: 'localIncorporation',
          getId: snippet => (snippet?.flags?.is_incorporated ? 'true' : 'false'),
          getTitle: snippet => (snippet?.flags?.is_incorporated ? 'True' : 'False'),
        },
        validityStatusRequest: {
          title: 'Validity status',
          value: 'validityStatusRequest',
          parent_value: 'validityStatusRequest',
          getId: snippet => snippet.flags?.validity_status || 'empty_validity_status',
          getTitle: snippet => {
            if (snippet.flags?.validity_status) {
              if (snippet.flags?.validity_status === 'not_verified') {
                return 'Requiring verification';
              }
              if (snippet.flags?.validity_status === 'not_valid') {
                return 'Outdated';
              }
              if (snippet.flags?.validity_status === 'valid') {
                return 'Actual';
              }
            } else {
              return '(Blanks)';
            }
          },
          getRequiringId: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || 'empty_validity_status';
            }
            return null;
          },
          getRequiringTitle: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || '(Blanks)';
            }
            return '';
          },
        },
        dueDateLaterThan2DaysBeforeScheduledDate: {
          title: 'Due date later than 2 days before Scheduled date',
          value: 'dueDateLaterThan2DaysBeforeScheduledDate',
          parent_value: 'dueDateLaterThan2DaysBeforeScheduledDate',
          getId: snippet => {
            return snippet?.flags?.ovd_nf ? 'true' : 'false';
          },
          getTitle: snippet => {
            return snippet?.flags?.ovd_nf ? 'True' : 'False';
          },
        },
      },

      // deliverable snippets
      deliverable: {
        drawingDiscipline: {
          title: 'Drawing discipline',
          value: 'drawingDiscipline',
          parent_value: 'drawingDiscipline',
          getId: snippet => (snippet.discipline?.id ? `drawingDiscipline${snippet.discipline?.id}` : 'empty_drawing_discipline'),
          getTitle: snippet => snippet.discipline?.title || '(Blanks)',
        },
        drawingType: {
          title: 'Drawing type',
          value: 'drawingType',
          parent_value: 'drawingType',
          getId: snippet => (snippet.type?.id ? `drawingType${snippet.type?.id}` : 'empty_drawing_type'),
          getTitle: snippet => snippet.type?.title || '(Blanks)',
        },
        deliverableLocation: {
          title: 'Deliverable location',
          value: 'deliverableLocation',
          parent_value: 'deliverableLocation',
          getId: snippet => (snippet?.owner?.id ? `deliverableLocation${snippet?.owner?.id}` : 0),
          getTitle: snippet =>
            snippet?.owner?.id ? `${snippet?.owner?.title || ''} (${snippet?.building?.title || 'Empty building'})` : 'Empty drawing type',
        },
        validityStatusDeliverable: {
          title: 'Validity status',
          value: 'validityStatusDeliverable',
          parent_value: 'validityStatusDeliverable',
          getId: snippet => snippet.flags?.validity_status || 'empty_validity_status',
          getTitle: snippet => {
            if (snippet.flags?.validity_status) {
              if (snippet.flags?.validity_status === 'not_verified') {
                return 'Requiring verification';
              }
              if (snippet.flags?.validity_status === 'not_valid') {
                return 'Outdated';
              }
              if (snippet.flags?.validity_status === 'valid') {
                return 'Actual';
              }
            } else {
              return '(Blanks)';
            }
          },
          getRequiringId: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || 'empty_validity_status';
            }
            return null;
          },
          getRequiringTitle: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || '(Blanks)';
            }
            return '';
          },
        },
        latestDeliverableRevisionStatus: {
          title: 'Latest deliverable revision status',
          value: 'latestDeliverableRevisionStatus',
          parent_value: 'latestDeliverableRevisionStatus',
          getId: snippet => {
            const ucFirst = str => {
              if (!str) return str;
              return str[0].toUpperCase() + str.slice(1);
            };
            return snippet.status === 'not_issued_yet' ? 'Not issued yet' : `${ucFirst(snippet.status)} | ${ucFirst(snippet.review_state)}`;
          },
          getTitle: snippet => {
            const ucFirst = str => {
              if (!str) return str;
              return str[0].toUpperCase() + str.slice(1);
            };
            return snippet.status === 'not_issued_yet' ? 'Not issued yet' : `${ucFirst(snippet.status)} | ${ucFirst(snippet.review_state)}`;
          },
        },
        scheduledDateEarlierThan: {
          title: 'Scheduled date earlier than 2 days before this NF due date',
          value: 'scheduledDateEarlierThan',
          parent_value: 'scheduledDateEarlierThan',
          getId: snippet => (snippet.flags?.ovd_nf ? 'true' : 'false'),
          getTitle: snippet => (snippet.flags?.ovd_nf ? 'True' : 'False'),
        },
      },

      // submittal snippets
      submittal: {
        specificationSection: {
          title: 'Specification section',
          value: 'specificationSection',
          parent_value: 'specificationSection',
          getId: snippet => snippet.mf_code || 'empty_specification_section',
          getTitle: snippet => `#${snippet.mf_code}` || '(Blanks)',
        },
        submittalType: {
          title: 'Submittal type',
          value: 'submittalType',
          parent_value: 'submittalType',
          getId: snippet => snippet.type || 'empty_submittal_type',
          getTitle: snippet => snippet.type || '(Blanks)',
        },
        responsibleManagerCompany: {
          title: 'Responsible manager company',
          value: 'responsibleManagerCompany',
          parent_value: 'responsibleManagerCompany',
          getId: snippet =>
            snippet.responsible_party?.id ? `responsibleManagerCompany${snippet.responsible_party?.id}` : 'empty_responsible_company',
          getTitle: snippet => (snippet.responsible_party?.id ? snippet.responsible_party?.company || '' : '(Blanks)'),
        },
        responsibleManager: {
          title: 'Responsible manager',
          value: 'responsibleManager',
          parent_value: 'responsibleManager',
          getId: snippet =>
            snippet.responsible_user?.id ? `responsibleManager${snippet.responsible_user?.id}` : 'empty_responsible_manager',
          getTitle: snippet =>
            snippet.responsible_user?.id
              ? `${snippet.responsible_user?.first_name || ''} ${snippet.responsible_user?.last_name || ''}`
              : '(Blanks)',
        },
        responsibleContractorCompany: {
          title: 'Responsible contractor company',
          value: 'responsibleContractorCompany',
          parent_value: 'responsibleContractorCompany',
          getId: snippet =>
            snippet.contractor_party?.id ? `responsibleContractorCompany${snippet.contractor_party?.id}` : 'empty_contractor_company',
          getTitle: snippet => (snippet.contractor_party?.id ? snippet.contractor_party?.company || '' : '(Blanks)'),
        },
        responsibleContractor: {
          title: 'Responsible contractor',
          value: 'responsibleContractor',
          parent_value: 'responsibleContractor',
          getId: snippet =>
            snippet.contractor_user?.id ? `responsibleContractor${snippet.contractor_user?.id}` : 'empty_contractor_manager',
          getTitle: snippet =>
            snippet.contractor_user?.id
              ? `${snippet.contractor_user?.first_name || ''} ${snippet.contractor_user?.last_name || ''}`
              : '(Blanks)',
        },
        submittalStatus: {
          title: 'Submittal status',
          value: 'submittalStatus',
          parent_value: 'submittalStatus',
          getId: snippet => snippet.status?.title || 'empty_submittal_status',
          getTitle: snippet => snippet.status?.title || '(Blanks)',
        },
        validityStatusSubmittal: {
          title: 'Validity status',
          value: 'validityStatusSubmittal',
          parent_value: 'validityStatusSubmittal',
          getId: snippet => snippet.flags?.validity_status || 'empty_validity_status',
          getTitle: snippet => {
            if (snippet.flags?.validity_status) {
              if (snippet.flags?.validity_status === 'not_verified') {
                return 'Requiring verification';
              }
              if (snippet.flags?.validity_status === 'not_valid') {
                return 'Outdated';
              }
              if (snippet.flags?.validity_status === 'valid') {
                return 'Actual';
              }
            } else {
              return '(Blanks)';
            }
          },
          getRequiringId: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || 'empty_validity_status';
            }
            return null;
          },
          getRequiringTitle: snippet => {
            if (snippet.flags?.validity_status === 'not_verified') {
              return snippet.flags?.validity_alert || '(Blanks)';
            }
            return '';
          },
        },
      },

      // document snippets
      document: {
        disciplineDD: {
          title: 'Discipline',
          value: 'disciplineDD',
          parent_value: 'disciplineDD',
          getId: snippet => (snippet.discipline?.id ? `disciplineDD${snippet.discipline?.id}` : 'empty_discipline'),
          getTitle: snippet => snippet.discipline?.title || '(Blanks)',
        },
        latestDdSet: {
          title: 'Latest DD set',
          value: 'latestDdSet',
          parent_value: 'latestDdSet',
          getId: snippet => snippet.current_set || 'empty_latestDdSet',
          getTitle: snippet => snippet.current_set || '(Blanks)',
        },
        buildingDD: {
          title: 'Building',
          value: 'buildingDD',
          parent_value: 'buildingDD',
          getId: snippet => (snippet.building[0]?.id ? `buildingDD${snippet.building[0]?.id}` : 'empty_building'),
          getTitle: snippet => snippet.building[0]?.title || '(Blanks)',
        },
        //       todo validityStatusDocument
        //       validityStatusDocument: {
        //         title: 'Validity status',
        //         value: 'validityStatusDocument',
        //         parent_value: 'validityStatusDocument',
        //         getId: snippet => snippet.flags?.validity_status || 'empty_validity_status',
        //         getTitle: snippet => {
        //           if (snippet.flags?.validity_status) {
        //             if (snippet.flags?.validity_status === 'not_verified') {
        //               return 'Requiring verification';
        //             }
        //             if (snippet.flags?.validity_status === 'not_valid') {
        //               return 'Outdated';
        //             }
        //             if (snippet.flags?.validity_status === 'valid') {
        //               return 'Actual';
        //             }
        //           } else {
        //             return '(Blanks)';
        //           }
        //         },
        //         getRequiringId: snippet => {
        //           if (snippet.flags?.validity_status === 'not_verified') {
        //             return snippet.flags?.validity_alert || 'empty_validity_status';
        //           }
        //           return null;
        //         },
        //         getRequiringTitle: snippet => {
        //           if (snippet.flags?.validity_status === 'not_verified') {
        //             return snippet.flags?.validity_alert || '(Blanks)';
        //           }
        //           return '';
        //         },
        //       },
      },
    };

    const tabType = relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput' ? 'submittal' : relatedTabType;
    const filterHandlers = filterHandlersMap[tabType];

    filtersArray?.forEach(filter => {
      const handler = filterHandlers[filter.value];
      if (handler) {
        const filterOptions =
          (canGenerateFilter &&
            newLinkedDataByShowType
              ?.map(snippet => {
                const id = handler.getId(snippet);
                if (id === null) return null;

                if (
                  (handler.value === 'validityStatusRequest' ||
                    handler.value === 'validityStatusDeliverable' ||
                    handler.value === 'validityStatusSubmittal') &&
                  snippet.flags?.validity_status === 'not_verified'
                ) {
                  return generateValidityStatusNotVerified(handler, snippet, handler.value);
                } else {
                  return {
                    value: id,
                    title: handler.getTitle(snippet),
                    selected: false,
                    itemType: 'filterOption',
                    parent_value: handler.parent_value,
                  };
                }
              })
              .filter(Boolean)) ||
          [];

        const uniqueFilterOptions = filterOptions?.reduce((acc, curr) => {
          const isDuplicate = acc.some(item => item.value === curr.value);
          if (!isDuplicate) {
            acc.push(curr);
          }
          return acc;
        }, []);

        if (uniqueFilterOptions?.length) {
          if (
            // request fields
            handler.value === 'requestType' ||
            handler.value === 'requestDiscipline' ||
            handler.value === 'NfStatus' ||
            // deliverable fields
            handler.value === 'drawingDiscipline' ||
            handler.value === 'drawingType' ||
            handler.value === 'latestDeliverableRevisionStatus' ||
            // submittal fields
            handler.value === 'specificationSection' ||
            handler.value === 'submittalType' ||
            handler.value === 'responsibleManagerCompany' ||
            handler.value === 'responsibleManager' ||
            handler.value === 'responsibleContractorCompany' ||
            handler.value === 'responsibleContractor' ||
            handler.value === 'submittalStatus' ||
            // document fields
            handler.value === 'disciplineDD' ||
            handler.value === 'latestDdSet' ||
            handler.value === 'buildingDD'
          ) {
            uniqueFilterOptions.sort((a, b) => a.title.localeCompare(b.title));
          }

          if (handler.value === 'deliverableLocation') {
            uniqueFilterOptions.sort((a, b) => a.value - b.value);
          }

          if (
            handler.value === 'validityStatusDocument' ||
            handler.value === 'validityStatusRequest' ||
            handler.value === 'validityStatusDeliverable' ||
            handler.value === 'validityStatusSubmittal'
          ) {
            uniqueFilterOptions.sort((a, b) => b.value.length - a.value.length);
          }

          if (
            handler.value === 'requestOverdue' ||
            handler.value === 'dueDateThanThisNF' ||
            handler.value === 'dueDateLaterThan2DaysBeforeScheduledDate' ||
            handler.value === 'responseIncorporated' ||
            handler.value === 'scheduledDateEarlierThan' ||
            handler.value === 'localIncorporation'
          ) {
            uniqueFilterOptions.sort((a, b) => {
              if (a.title === 'True') return -1;
              if (b.title === 'True') return 1;
              return 0;
            });
          }

          const filterItem = {
            title: handler.title,
            value: handler.value,
            isItemOpened: false,
            itemType: 'filterName',
            data: uniqueFilterOptions,
          };

          newArray.push(filterItem);
        }
      }
    });

    if (!newLinkedDataByShowType?.length) {
      setSelectedOptionsParams([]);
      setSelectedOptions([]);
      setSelectedParams([], part);
    }

    setReGenerateFilterSelect(false);
    return newArray;
  }, [currentCard, relatedTabType, part, selectedShowType, relatedTabData, reGenerateFilterSelect]);

  useEffect(() => {
    setSelectedParams(selectedOptionsParams, part);
  }, [selectedOptionsParams]);

  useEffect(() => {
    setSelectedParams([], 'predecessor');
    setSelectedParams([], 'successor');
    setSelectedOptionsParams([]);
    setSelectedOptions([]);
    setOpenedFilters([]);
    setReGenerateFilterSelect(true);
  }, [relatedTabType, selectedShowType]);

  useEffect(() => {
    const result = [];

    if (menuItems?.length) {
      menuItems.forEach(filterName => {
        const isFilterOpened = openedFilters.includes(filterName.value);
        result.push({ ...filterName, itemType: 'filterName', isItemOpened: isFilterOpened });

        if (isFilterOpened) {
          filterName.data.forEach(filterOption => {
            const optionName =
              filterOption.value === 'false' || filterOption.value === 'true'
                ? `${filterOption.parent_value}-${filterOption.value}`
                : filterOption.value;

            const selectedOption = selectedOptions.includes(optionName);

            if (filterOption.value === 'not_verified') {
              const isNotVerifiedFilterOpened = openedFilters.includes(filterOption.value);
              result.push({ ...filterOption, itemType: 'filterName', isItemOpened: isNotVerifiedFilterOpened });

              if (isNotVerifiedFilterOpened) {
                filterOption.data.forEach(filterOption => {
                  const selectedOption = selectedOptions.includes(filterOption.value);

                  result.push({
                    ...filterOption,
                    itemType: 'filterOption',
                    selected: selectedOption,
                  });
                });
              }
            } else {
              result.push({
                ...filterOption,
                itemType: 'filterOption',
                selected: selectedOption,
              });
            }
          });
        }
      });
    }

    setFilterData(result);
  }, [menuItems, openedFilters, selectedOptions]);

  const handleFilterNameItemClick = (filterName: FilterNameItemInterface) => {
    const result = [...openedFilters];

    if (result?.includes(filterName.value)) {
      result.splice(
        result.findIndex(el => el === filterName.value),
        1,
      );
    } else {
      result.push(filterName.value);
    }
    setOpenedFilters(result);
  };

  const handleFilterOptionItemClick = (event: React.SyntheticEvent, filterOption: FilterOptionItemInterface) => {
    event.stopPropagation();

    const optionName =
      filterOption.value === 'false' || filterOption.value === 'true'
        ? `${filterOption.parent_value}-${filterOption.value}`
        : filterOption.value;

    const result = [...selectedOptions];
    const resultParams = [...selectedOptionsParams];

    if (result?.includes(optionName)) {
      result.splice(
        result.findIndex(el => el === optionName),
        1,
      );

      const filterNameIndex = resultParams.findIndex(el => el.filterName === filterOption.parent_value);

      if (resultParams[filterNameIndex]?.options?.length === 1) {
        resultParams.splice(filterNameIndex, 1);
      } else {
        resultParams.splice(filterNameIndex, 1, {
          ...resultParams[filterNameIndex],
          options: resultParams[filterNameIndex]?.options?.filter(f => f !== optionName),
        });
      }
    } else {
      result.push(optionName);

      const filterNameIndex = resultParams.findIndex(el => el.filterName === filterOption.parent_value);
      if (filterNameIndex !== -1) {
        resultParams.splice(filterNameIndex, 1, {
          ...resultParams[filterNameIndex],
          options: [...resultParams[filterNameIndex]?.options, optionName],
        });
      } else {
        resultParams.push({ filterName: filterOption.parent_value, options: [optionName] });
      }
    }
    setSelectedOptionsParams(resultParams);
    setSelectedOptions(result);
  };

  const handleCustomSelectAll = () => {
    const result = [];
    const resultParams = [];

    menuItems.forEach(item => {
      item?.data?.forEach(filterOption => {
        const option =
          filterOption.value === 'false' || filterOption.value === 'true'
            ? `${filterOption.parent_value}-${filterOption.value}`
            : filterOption.value;
        result.push(option);
        if (filterOption.value === 'not_verified') {
          const subArray = filterOption.data.map(item => item.value) || [];
          result.push(...subArray);
        }

        const filterNameIndex = resultParams.findIndex(el => el.filterName === filterOption.parent_value);
        if (filterNameIndex !== -1) {
          resultParams.splice(filterNameIndex, 1, {
            ...resultParams[filterNameIndex],
            options: [...resultParams[filterNameIndex]?.options, option],
          });
        } else {
          if (filterOption.value === 'not_verified') {
            const subArray = filterOption.data.map(item => item.value) || [];
            resultParams.push({ filterName: filterOption.parent_value, options: [...subArray] });
          } else {
            resultParams.push({ filterName: filterOption.parent_value, options: [option] });
          }
        }
      });
    });
    setSelectedOptionsParams(resultParams);
    setSelectedOptions(result);
  };

  const onCloseFilter = () => {};

  return (
    <>
      <div className={`${themeClass}_container`} ref={selectRef}>
        <CardRenderSelect<DropdownItemModel, null, true>
          type={'filter'}
          isMulti
          isCustomMulti={true}
          isHeader
          isHideSearch
          menuItems={filterData}
          selected={selectedOptions}
          selectHandler={null}
          isLoading={false}
          menuItemSize={'md'}
          basisMaxItems={3}
          handleFilterNameItemClick={handleFilterNameItemClick}
          handleFilterOptionItemClick={handleFilterOptionItemClick}
          isFullWidth={true}
          isHeaderNoMulti={true}
          isExpandOnHover={true}
          customClearAll={handleCustomClearAll}
          customSelectAll={handleCustomSelectAll}
          disabled={false}
          isMobile={isMobile}
          renderCustomBasis={() => (
            <FilterButton
              type={'filter'}
              isPressed={false}
              isActive={!!selectedOptions.length}
              isHiddenTitle
              isMobile={isMobile}
              customSize
            />
          )}
          onMenuClose={onCloseFilter}
        />
      </div>
    </>
  );
};

export default RelatedItemsFiltersSelect;
