import React, { FC } from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './CustomSkeletonStyles.scss';

const CustomSkeleton: FC<SkeletonProps> = props => {
  const themeClass = useGetThemeClass('b-skeleton');

  return <Skeleton {...props} animation={'pulse'} variant={'rectangular'} className={themeClass} />;
};
export default CustomSkeleton;
