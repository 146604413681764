import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/configure/configureStore';
import { getUserProfile, setProjectNotificationSettings, setPromptsAlerts, updateUser } from '../../../store/user/userThunk';
import { FILE_SIZE_LIMIT } from '../../../service/file';
import { changeUserAvatar } from '../../../store/user/userActions';
import Slider, { SliderTooltip } from 'rc-slider';
import ToggleSwitch from '../../controls/ToggleSwitch/ToggleSwitch';
import { CheckBox, TextInputNew } from '../../controls';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import { isEmptyObject, ucFirst } from '../../../helpers/commonHelpers';
import { AvatarImageEditor } from '../../controls/AvatarImageEditor/AvatarImageEditor';
import { handleGetThemeClass, useGetThemeClass } from '../../../helpers/designTokens';
import {
  AvatarPositionInterface,
  ProfileDefaultData,
  ProfileErrorsInterface,
  ProfileFormData,
  ProfileProjectOptionsInterface,
} from '../Profile/Profile.types';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { UserReducerModel } from '../../../store/user/userReducerModel';
import ProfileGrid from '../Profile/parts/ProfileGrid/ProfileGrid';
import { ProfileHelper } from '../../../helpers/ProfileHelper';
import { CustomButton } from '../../controls/ButtonComponents';
import AvatarComponent from '../../controls/Avatar/AvatarComponent';
import cn from 'classnames';

import './ProfileEditStyles.scss';

const { Handle } = Slider;

interface IProps {
  isPopover: boolean;
  handleClose: () => void;
  userData: UserReducerModel;
  enqueueSnackbar?: any;
  onSaveSettings: () => void;
  handleOnClose?: () => void;
}

const ProfileEdit: FC<IProps> = ({ isPopover, userData, handleClose, enqueueSnackbar, onSaveSettings, handleOnClose }) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<ProfileFormData>({});
  const [project_options, setProjectOptions] = useState<ProfileProjectOptionsInterface>(null);
  const [errors, setErrors] = useState<ProfileErrorsInterface>({});
  const [isWatchCurrentPassword, setIsWatchCurrentPassword] = useState<boolean>(false);
  const [isWatchPassword, setIsWatchPassword] = useState<boolean>(false);
  const [isWatchConfirmPassword, setIsWatchConfirmPassword] = useState<boolean>(false);
  const [btnSaveUserData, setBtnSaveUserData] = useState<boolean>(false);
  const [show_prompts, setShowPrompts] = useState<0 | 1>(0);
  const [show_tooltips, setShowTooltips] = useState<0 | 1>(0);
  const [isDataAnimeBtnLoading, setIsDataAnimeBtnLoading] = useState<boolean>(false);
  const [changePasswordActive, setChangePasswordActive] = useState<boolean>(false);
  const [notificationSettingsType, setNotificationSettingsType] = useState<string>('general');
  const [wasProjectSettingsChanged, setWasProjectSettingsChanged] = useState<boolean>(false);
  const [isOpenEditorPopup, setIsOpenEditorPopup] = useState<boolean>(false);
  const [editorPosition, setEditorPosition] = useState<AvatarPositionInterface | null>(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarScale, setAvatarScale] = useState<number>(1);
  const [avatarRotation, setAvatarRotation] = useState<number>(0);
  const [avatarForEdit, setAvatarForEdit] = useState(null);
  const [avatarForEditLoading, setAvatarForEditLoading] = useState<boolean>(false);
  const [default_important, setDefaultImportant] = useState<ProfileDefaultData[]>(null);
  const [data_important, setDataImportant] = useState<ProfileDefaultData[]>(null);
  const [focusElement, setFocusElement] = useState<string | null>(null);
  const userInfo = userData && userData.user_profile;

  useEffect(() => {
    if (userInfo.project_options) {
      initProjectOptions();
    }
  }, [userInfo.project_options]);

  useEffect(() => {
    if (!avatarForEdit && !avatarForEditLoading) {
      setAvatarForEditLoading(true);
    }
    getEditorAvatarImage();
  }, [userInfo]);

  useEffect(() => {
    if (userData.update_data?.status === false) {
      if (userData.update_data.message === 'Current password is incorrect.') {
        _validatorReport({ errors: { currentPassword: userData.update_data.message } });
        setErrors({ currentPassword: userData.update_data.message });
      }
      if (userData.update_data.errors.email) {
        setErrors({ email: userData.update_data.errors.email[0] });
      }
      if (userData.update_data.errors.first_name) {
        setErrors({ first_name: userData.update_data.errors.first_name[0] });
      }
      if (userData.update_data.errors.last_name) {
        setErrors({ last_name: userData.update_data.errors.last_name[0] });
      }
      if (userData.update_data.errors.title) {
        setErrors({ title: userData.update_data.errors.title[0] });
      }
    }
  }, [userData.update_data]);

  useEffect(() => {
    if (!data_important?.length) {
      setDataImportant(formData?.set_important);
    }
  }, [formData?.set_important]);

  useEffect(() => {
    const notificationContainerName = notificationSettingsType === 'project' ? 'project_options' : 'formData';
    const select_important = notificationContainerName === 'project_options' ? project_options?.set_important : formData?.set_important;

    setDataImportant(select_important);
  }, [notificationSettingsType]);

  useEffect(() => {
    if (userData.userInfo && formData && isEmptyObject(formData)) {
      initUserData();
    }
  }, [userData.userInfo]);

  useEffect(() => {
    if (!userData.projectSettingsStatus.loading) {
      setIsDataAnimeBtnLoading(false);
      setAvatar(null);
    }
  }, [userData.userInfo]);

  useEffect(() => {
    if (!isEmptyObject(userData.update_data) && !userData.update_data.status) {
      setIsDataAnimeBtnLoading(false);
    }
  }, [userData.update_data]);

  useEffect(() => {
    if (isDataAnimeBtnLoading) {
      _validatorReport();
    }
  }, [userData.userInfo]);

  useEffect(() => {
    if (userInfo.options.show_prompts === 0) {
      dispatch(setPromptsAlerts(ProfileHelper.getEmptyPromptNameList()));
    } else {
      dispatch(setPromptsAlerts(ProfileHelper.getPromptNameList()));
    }
  }, [userInfo.options.show_prompts]);

  useEffect(() => {
    if (userInfo.options.show_tooltips === 0) {
      localStorage.setItem('show_tooltips', 'no');
    } else {
      localStorage.removeItem('show_tooltips');
    }
  }, [userInfo.options.show_tooltips]);

  const handleChange = (name: string) => (value: string) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setErrors({});
  };

  const initProjectOptions = () => {
    const active_project_options = userInfo.project_options.find(opt => opt.project_id == userData.active_project_id);
    if (active_project_options?.frequency) {
      setProjectOptions({
        frequency: active_project_options?.frequency,
        is_alerts: active_project_options?.is_alerts,
        is_important: active_project_options?.is_important,
        is_notification: active_project_options?.is_notification,
        project_id: active_project_options?.project_id,
        options_id: active_project_options?.options_id || 0,
        report_daily: active_project_options?.report_daily,
        set_important: active_project_options?.set_important,
        is_active: active_project_options?.is_active || 0,
        is_only_my: active_project_options?.is_only_my || 0,
        is_only_discipline: active_project_options?.is_only_discipline || 0,
      });
    } else {
      setProjectOptions({
        frequency: userInfo.options.frequency,
        is_alerts: userInfo.options.is_alerts,
        is_important: userInfo.options.is_important,
        is_notification: userInfo.options.is_notification,
        project_id: userData.active_project_id,
        options_id: active_project_options?.options_id || 0,
        report_daily: userInfo.options.report_daily,
        set_important: userInfo.options.set_important,
        is_active: active_project_options?.is_active || 0,
        is_only_my: active_project_options?.is_only_my || 0,
        is_only_discipline: active_project_options?.is_only_discipline || 0,
      });
    }
    setIsDataAnimeBtnLoading(false);
  };

  const handleSubmitPassword = () => {
    const memberId = userInfo && userInfo.id;

    let data = {
      first_name: userInfo.profile.first_name,
      last_name: userInfo.profile.last_name,
      email: userInfo.email,
      title: userInfo.profile.title,
      phone: userInfo.profile.phone,
      phoneO: userInfo.profile.phoneO,
      is_alerts: project_options.is_alerts,
      is_important: project_options.is_important,
      is_notification: project_options.is_notification,
      password: formData.password,
      currentPassword: formData.currentPassword,
    };

    if (Object.keys(formData).length && memberId) {
      if (formData.password === '') {
        setErrors({ password: 'Please enter a passwords' });
      } else {
        if (formData.password !== formData.confirmPassword) {
          setErrors({ confirmPassword: 'Passwords are not equal' });
        } else {
          data = {
            ...data,
            user_id: memberId,
          };
          delete data.image;
          delete data.confirmPassword;
          if (!data.password.trim()) {
            delete data.password;
          }
          setBtnSaveUserData(false);
          setIsDataAnimeBtnLoading(true);

          dispatch(updateUser(data));
        }
      }
    }
  };

  const handleSubmitSettings = async () => {
    const memberId = userInfo && userInfo.id;
    const active_project_options = userInfo.project_options.find(opt => opt.project_id === +userData.active_project_id);

    if (isDataAnimeBtnLoading) {
      return;
    }

    let userSettings = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      title: formData.title,
      phone: formData.phone,
      phoneO: formData.phoneO,
      is_alerts: formData.is_alerts,
      is_important: formData.is_important,
      report_daily: formData.report_daily,
      is_notification: formData.is_notification,
      set_important: formData.set_important.filter(item => item.important).map(item => item.alias),
      show_prompts: show_prompts === 0 ? 1 : 0,
      show_tooltips: show_tooltips === 0 ? 1 : 0,
      is_only_my: formData.is_only_my,
      is_only_discipline: formData.is_only_discipline,
    };
    const needData = userInfo.project_options.find(opt => opt.project_id === +userData.active_project_id);

    const project_settings = {
      options_id: userData.active_project_id || 0,
      user_id: userInfo.id,
      project_id: +userData.active_project_id,
      is_personal_settings: project_options?.is_active ? 1 : 0,
      report_daily: needData?.report_daily || null,
      ...project_options,
      set_important: project_options.set_important.filter(item => item.important).map(item => item.alias),
    };

    if (avatar) {
      userSettings.image_to_upload = avatar;
      dispatch(changeUserAvatar(URL.createObjectURL(avatar)));
    }
    delete userSettings.image;

    userSettings = {
      ...userSettings,
      user_id: memberId,
    };

    if (formData.is_important && formData.set_important) {
      userSettings.set_important = formData.set_important.filter(item => item.important).map(item => item.alias);
    }

    handleOnClose && handleOnClose();
    setBtnSaveUserData(false);
    setIsDataAnimeBtnLoading(true);

    await dispatch(updateUser(userSettings));

    if (wasProjectSettingsChanged) {
      if (!project_settings?.options_id) {
        project_settings.options_id = active_project_options?.options_id || 0;
      }
      await dispatch(setProjectNotificationSettings(project_settings));
    }
    dispatch(getUserProfile({ projectId: userData.active_project_id, user_id: userData.user_profile.id }));
    onSaveSettings && onSaveSettings();
  };

  const _validatorReport = (errors?: any | undefined) => {
    if (enqueueSnackbar) {
      if (!errors) {
        return enqueueSnackbar('User successfully updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 4000,
        });
      }
      enqueueSnackbar(errors.errors.currentPassword, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };

  const handleFile = e => {
    const files = Array.from(e.target.files).filter((f: any) => f?.size < FILE_SIZE_LIMIT);

    if (files[0]) {
      setAvatar(files[0]);
      setFormData(prevState => ({ ...prevState, image: null }), handleOpenEditorPopup);
    }
  };

  const initUserData = () => {
    if (btnSaveUserData == false || (btnSaveUserData && !!avatar?.name == false)) {
      const defaultImportant =
        userInfo.options.set_important.map(item => {
          const obj = { ...item };
          obj.important = userData.userInfo.default_important.includes(item.alias);
          return obj;
        }) || null;
      setFormData(() => ({
        first_name: userInfo.profile.first_name,
        last_name: userInfo.profile.last_name,
        email: userInfo.email,
        phone: userInfo.profile.phone,
        phoneO: userInfo.profile.phoneO,
        title: userInfo.profile.title,
        is_notification: userInfo.options.is_notification,
        is_important: userData.user_profile.options.is_important,
        report_daily: userData.user_profile.options.report_daily,
        is_alerts: userData.user_profile.options.is_alerts,
        currentPassword: '',
        password: '',
        confirmPassword: '',
        set_important: userInfo.options.set_important,
        is_only_my: userInfo.is_only_my,
        is_only_discipline: userInfo.is_only_discipline,
      }));
      setShowPrompts(userData.user_profile.options.show_prompts === 0 ? 1 : 0);
      setShowTooltips(userData.user_profile.options.show_tooltips === 0 ? 1 : 0);
      setIsDataAnimeBtnLoading(false);
      setDefaultImportant(defaultImportant);
    }
  };

  const handleWatch = btn => {
    if (btn === 'CurrentPassword') {
      setIsWatchCurrentPassword(!isWatchCurrentPassword);
    }

    if (btn === 'Password') {
      setIsWatchPassword(!isWatchPassword);
    }

    if (btn === 'ConfirmPassword') {
      setIsWatchConfirmPassword(!isWatchConfirmPassword);
    }
  };

  const handleNotification = txt => {
    const notificationOptionsContainer = notificationSettingsType === 'project' ? 'project_options' : 'formData';
    if (txt === 'report_daily') {
      if (notificationOptionsContainer === 'formData') {
        setFormData(prevState => ({
          ...prevState,
          report_daily: formData.report_daily === 1 ? 0 : 1,
        }));
      }
      if (notificationOptionsContainer === 'project_options') {
        setProjectOptions(prevState => ({
          ...prevState,
          report_daily: project_options.report_daily === 1 ? 0 : 1,
        }));
      }
    }
    if (txt === 'important') {
      if (notificationOptionsContainer === 'formData') {
        setFormData(prevState => ({
          ...prevState,
          is_important: formData.is_important === 1 ? 0 : 1,
        }));
      }
      if (notificationOptionsContainer === 'project_options') {
        setProjectOptions(prevState => ({
          ...prevState,
          is_important: project_options.is_important === 1 ? 0 : 1,
        }));
      }
    } else if (txt === 'alerts') {
      if (notificationOptionsContainer === 'formData') {
        setFormData(prevState => ({
          ...prevState,
          is_alerts: formData.is_alerts === 1 ? 0 : 1,
        }));
      }
      if (notificationOptionsContainer === 'project_options') {
        setProjectOptions(prevState => ({
          ...prevState,
          is_alerts: project_options.is_alerts === 1 ? 0 : 1,
        }));
      }
    }
  };

  const handleGeneralSettings = (event, checked) => {
    const notificationOptionsContainer = notificationSettingsType === 'project' ? 'project_options' : 'formData';

    if (notificationOptionsContainer === 'formData') {
      setFormData(prevState => ({
        ...prevState,
        is_notification: checked ? 1 : 0,
      }));
    }
    if (notificationOptionsContainer === 'project_options') {
      setProjectOptions(prevState => ({
        ...prevState,
        is_notification: checked ? 1 : 0,
      }));
    }
  };

  const handleDefaultNotifications = () => {
    const notificationOptionsContainer = notificationSettingsType === 'project' ? 'project_options' : 'formData';
    const defaultImportant =
      userInfo.options.set_important.map(item => {
        const obj = { ...item };
        obj.important = userData.userInfo.default_important.includes(item.alias);
        return obj;
      }) || null;
    setDataImportant([...defaultImportant]);
    if (notificationOptionsContainer === 'formData') {
      setFormData(prevState => ({
        ...prevState,
        is_important: formData.is_important,
        set_important: default_important,
      }));
    }
    if (notificationOptionsContainer === 'project_options') {
      setProjectOptions(prevState => ({
        ...prevState,
        is_important: project_options.is_important,
        set_important: default_important,
      }));
    }
  };

  const handleSelectNotifications = notifications => {
    const notificationOptionsContainer = notificationSettingsType === 'project' ? 'project_options' : 'formData';
    if (notificationOptionsContainer === 'formData') {
      const setImportant = [...formData.set_important];
      const newSetImportant = setImportant.map(item => {
        const obj = { ...item };
        obj.important = notifications.findIndex(value => value === item.alias) > -1;
        return obj;
      });

      setFormData(prevState => ({
        ...prevState,
        is_important: formData.is_important,
        set_important: [...newSetImportant],
      }));
    }
    if (notificationOptionsContainer === 'project_options') {
      const setImportant = [...project_options.set_important];
      const newSetImportant = setImportant.map(item => {
        const obj = { ...item };
        obj.important = notifications.findIndex(value => value === item.alias) > -1;
        return obj;
      });

      setProjectOptions(prevState => ({
        ...prevState,
        is_important: project_options.is_important,
        set_important: [...newSetImportant],
      }));
    }
  };

  const handleSwitchShowPrompts = switchState => {
    setShowPrompts(switchState.target.checked ? 0 : 1);
  };

  const handleSwitchEmailNotification = (switchState, type: 'onlyMy' | 'onlyDiscipline') => {
    const result = switchState.target.checked ? 1 : 0;
    if (notificationContainerName === 'formData') {
      if (type === 'onlyMy') {
        setFormData(prevState => ({ ...prevState, is_only_my: result, is_only_discipline: result ? 0 : prevState.is_only_discipline }));
      }
      if (type === 'onlyDiscipline') {
        setFormData(prevState => ({ ...prevState, is_only_discipline: result, is_only_my: result ? 0 : prevState.is_only_my }));
      }
    } else {
      if (type === 'onlyMy') {
        setProjectOptions(prevState => ({
          ...prevState,
          is_only_my: result,
          is_only_discipline: result ? 0 : prevState.is_only_discipline,
        }));
      }
      if (type === 'onlyDiscipline') {
        setProjectOptions(prevState => ({ ...prevState, is_only_discipline: result, is_only_my: result ? 0 : prevState.is_only_my }));
      }
    }
  };

  const handleSwitchShowTooltips = switchState => {
    setShowTooltips(switchState.target.checked ? 0 : 1);
  };

  const handleChangePassword = () => {
    setChangePasswordActive(!changePasswordActive);
  };

  const handleNotificationTab = type => {
    setNotificationSettingsType(type);

    if (type === 'project') {
      setWasProjectSettingsChanged(true);
    }
  };

  const handleSwitchProjectOptions = (switchState, checked) => {
    setProjectOptions(prevState => ({
      ...prevState,
      is_active: checked ? 1 : 0,
    }));
  };

  const handleOpenEditorPopup = () => {
    setIsOpenEditorPopup(true);
    setAvatarScale(1);
  };

  const handleCloseEditorPopup = () => {
    setIsOpenEditorPopup(false);
    setAvatar(null);
  };

  const handleSlider = props => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <SliderTooltip prefixCls="rc-slider-tooltip" overlay={`${value} %`} visible={dragging} placement="top" key={index}>
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const onSliderChange = value => {
    setAvatarScale(value);
  };

  const handlePositionChange = editorPosition => {
    setEditorPosition(editorPosition);
  };

  const onAvatarSave = async editorRef => {
    if (editorRef) {
      const dataUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      setAvatar(blob);
      setIsOpenEditorPopup(false);
      setAvatarRotation(0);
    }
  };

  const getEditorAvatarImage = async () => {
    const getUrlExtension = url => {
      return url.split(/[#?]/)[0].split('.').pop().trim();
    };

    if (userInfo && userInfo.profile.image && !userInfo.profile.image.includes('images/avatar.png')) {
      setAvatarForEditLoading(true);

      const imageExtension = getUrlExtension(userInfo.profile.image);
      const result = await fetch(userInfo.profile.image, {
        cache: 'no-cache',
      });
      const blob = await result.blob();

      const image = new File([blob], 'profileImage.' + imageExtension, {
        type: blob.type,
      });

      setAvatarForEdit(image);
      setAvatarForEditLoading(false);
    }
  };

  const rotateAvatar = (e, direction) => {
    e.preventDefault();

    if (direction === 'left') {
      setAvatarRotation((avatarRotation - 90) % 360);
    } else {
      setAvatarRotation((avatarRotation + 90) % 360);
    }
  };

  const handleSetFocus = focusElement => {
    setFocusElement(focusElement);
  };

  const themeClass = handleGetThemeClass('b-profile_table_grid', localStorage.getItem('theme'));

  const errorsData = errors;
  const notificationContainerName = notificationSettingsType === 'project' ? 'project_options' : 'formData';
  const isGeneralSettings = notificationContainerName === 'formData' ? formData?.is_notification : project_options?.is_notification;
  const isOnlyMyNotifications = notificationContainerName === 'formData' ? formData?.is_only_my : project_options?.is_only_my;
  const isOnlyDisciplineNotifications =
    notificationContainerName === 'formData' ? formData?.is_only_discipline : project_options?.is_only_discipline;
  const currentCompany = userInfo.parties?.[0];
  const activeProject =
    userData.userInfo &&
    userData.userInfo.projects &&
    userData.userInfo.projects.find(project => project.id === +userData.active_project_id);
  const isImportant = notificationContainerName === 'formData' ? formData?.is_important : project_options?.is_important;
  const isReportDaily = notificationContainerName === 'formData' ? formData?.report_daily : project_options?.report_daily;
  const projectNotificationDisabled = notificationSettingsType === 'project' && !project_options?.is_active;
  const userAvatar = (avatar && URL.createObjectURL(avatar)) || userInfo.profile.image;
  const imageForEdit = (avatar && URL.createObjectURL(avatar)) || avatarForEdit;
  const isAvatarEdit = userInfo.profile.image && !userInfo.profile.image.includes('images/avatar.png');

  const profileThemeClass = useGetThemeClass('b-profile');
  const profilePopoverThemeClass = useGetThemeClass('b-profilePopover');

  return (
    <>
      {isPopover ? (
        <div className={`${profilePopoverThemeClass}__header`}>
          <div className={`${profilePopoverThemeClass}__left`}>
            <span className={`${profilePopoverThemeClass}__title`}>Profile</span>
            <span className={`${profilePopoverThemeClass}__role`}>
              {userInfo && userInfo.roles && Object.keys(userInfo.roles)[0] === '4'
                ? 'Superadmin'
                : `${userInfo.roles[Object.keys(userInfo.roles)[0]].description} ${currentCompany ? 'at' : ''} ${
                    currentCompany?.title || ''
                  }`}
            </span>
          </div>
          <div className={`${profilePopoverThemeClass}__closeBtn`} onClick={handleClose}>
            <TokenIcon iconName={'close'} size={16} />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={profileThemeClass}>
        <div className={`${profileThemeClass}__container ${isPopover ? '-popover' : ''}`}>
          <form className={`${profileThemeClass}__profileForm`}>
            <div className={`${profileThemeClass}__formRow`}>
              <div className={`${profileThemeClass}__leftColumn -avatar`}>
                <div>
                  <span className={`${profileThemeClass}__title ${profileThemeClass}__leftColumnTitle`}>Photo</span>
                  <AvatarComponent
                    handleFile={handleFile}
                    avatar={userAvatar}
                    handleOpenEditorPopup={handleOpenEditorPopup}
                    isAvatarEdit={isAvatarEdit}
                    tooltip={'Recommended image size: 150px * 150px'}
                    label="Upload photo"
                  />
                </div>
              </div>
              <div className={`${profileThemeClass}__rightColumnWrapper`}>
                <div className={`${profileThemeClass}__rightColumn`}>
                  <span className={`${profileThemeClass}__title`}>Personal info1</span>
                  <div className={`${profileThemeClass}__inputsColumn`}>
                    <TextInputNew
                      label={'First Name'}
                      value={formData.first_name}
                      type={'on-bgd'}
                      size={'md'}
                      name="first_name"
                      onChange={handleChange('first_name')}
                      isFocus={focusElement === 'First Name'}
                      onFocus={() => handleSetFocus('First Name')}
                      onBlur={() => handleSetFocus('')}
                      error={errorsData.first_name}
                    />
                    <TextInputNew
                      label={'Last Name'}
                      value={formData.last_name}
                      type={'on-bgd'}
                      size={'md'}
                      name="last_name"
                      onChange={handleChange('last_name')}
                      isFocus={focusElement === 'Last Name'}
                      onFocus={() => handleSetFocus('Last Name')}
                      onBlur={() => handleSetFocus('')}
                      error={errorsData.last_name}
                    />
                    <TextInputNew
                      label={'Email'}
                      value={formData.email}
                      type={'on-bgd'}
                      size={'md'}
                      name="email"
                      onChange={handleChange('email')}
                      isFocus={focusElement === 'Email'}
                      onFocus={() => handleSetFocus('Email')}
                      onBlur={() => handleSetFocus('')}
                      error={errorsData.email}
                    />
                    <TextInputNew
                      label={'Title'}
                      value={formData.title}
                      type={'on-bgd'}
                      size={'md'}
                      name="title"
                      onChange={handleChange('title')}
                      isFocus={focusElement === 'Title'}
                      onFocus={() => handleSetFocus('Title')}
                      onBlur={() => handleSetFocus('')}
                      error={errorsData.title}
                    />
                    <TextInputNew
                      label={'Phone number (M):'}
                      value={formData.phone}
                      type={'on-bgd'}
                      size={'md'}
                      name="phone"
                      id="phone"
                      onChange={handleChange('phone')}
                      isFocus={focusElement === 'phone'}
                      onFocus={() => handleSetFocus('phone')}
                      onBlur={() => handleSetFocus('')}
                    />
                    <TextInputNew
                      label={'Phone number (O):'}
                      value={formData.phoneO}
                      type={'on-bgd'}
                      size={'md'}
                      name="phoneO"
                      id="phoneO"
                      onChange={handleChange('phoneO')}
                      isFocus={focusElement === 'phoneO'}
                      onFocus={() => handleSetFocus('phoneO')}
                      onBlur={() => handleSetFocus('')}
                    />
                  </div>
                  <div className={`${profileThemeClass}__role`}>
                    <span className={`${profileThemeClass}__roleLabel`}>Role</span>
                    {userInfo && userInfo.roles && userInfo.roles[Object.keys(userInfo.roles)[0]].id === 4 ? (
                      <span className={`${profileThemeClass}__roleText`}>Superadmin</span>
                    ) : (
                      <span className={`${profileThemeClass}__roleText`}>
                        {userInfo.roles[Object.keys(userInfo.roles)[0]].description}
                        {currentCompany && <span> at </span>}
                        {currentCompany?.title || ''}
                      </span>
                    )}
                  </div>
                </div>
                <div className={`${profileThemeClass}__divider`}></div>
              </div>
            </div>
          </form>
          <div className={`${profileThemeClass}__profileSettings`}>
            <div className={`${profileThemeClass}__profileScroll`}>
              <header className={`${profileThemeClass}__profileHeader`}>
                <div className={`${profileThemeClass}__hideAllWrap`}>
                  <span className={`${profileThemeClass}__title`}>Security</span>
                </div>
              </header>
              <div>
                <div className={`${profileThemeClass}__changePasswordForm`}>
                  <form>
                    <div className={`${profileThemeClass}__headBlockItems`}>
                      <CustomButton
                        type={'text-plain'}
                        size={'md'}
                        title={'Change password'}
                        clickHandler={handleChangePassword}
                        isActive={changePasswordActive}
                      />
                    </div>
                    <div
                      className={cn(`${profileThemeClass}__newPasswordForm`, {
                        [`${profileThemeClass}__none`]: !changePasswordActive,
                      })}
                    >
                      <TextInputNew
                        label={'New password'}
                        size={'md'}
                        type={'on-bgd'}
                        value={formData.password}
                        onChange={handleChange('password')}
                        inputType={!isWatchPassword ? 'password' : 'text'}
                        customIconClass={`${profileThemeClass}__isWatch`}
                        customIcon={
                          isWatchPassword ? <TokenIcon iconName={'deactivate'} size={20} /> : <TokenIcon iconName={'activate'} size={20} />
                        }
                        onClickIcon={() => handleWatch('Password')}
                        error={errorsData?.password}
                      />
                      <TextInputNew
                        label={'Confirm password'}
                        size={'md'}
                        type={'on-bgd'}
                        value={formData.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        inputType={!isWatchConfirmPassword ? 'password' : 'text'}
                        customIconClass={`${profileThemeClass}__isWatch`}
                        customIcon={
                          isWatchConfirmPassword ? (
                            <TokenIcon iconName={'deactivate'} size={20} />
                          ) : (
                            <TokenIcon iconName={'activate'} size={20} />
                          )
                        }
                        onClickIcon={() => handleWatch('ConfirmPassword')}
                        error={errorsData?.confirmPassword}
                      />
                      <div
                        className={`${profileThemeClass}__passwordBtn ${
                          errorsData?.password || errorsData?.confirmPassword ? '-error' : ' '
                        }`}
                      >
                        <CustomButton
                          clickHandler={handleSubmitPassword}
                          type={'primary'}
                          title={'Save'}
                          disabled={!formData.confirmPassword || !formData.password}
                          loading={isDataAnimeBtnLoading}
                          size={'md'}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className={`${profileThemeClass}__divider`}></div>

              <header className={`${profileThemeClass}__profileHeader`}>
                <div className={`${profileThemeClass}__notifications`}>
                  <span className={`${profileThemeClass}__title`}>Notifications</span>
                </div>
              </header>

              <div className={`${profileThemeClass}__settingsForm`}>
                <div className={`${profileThemeClass}__notificationTabs`}>
                  <CustomTooltip
                    title={
                      'If active, the notifications settings will override the General ones. ' +
                      'Please note, that if you want to use a custom preferences for other projects, ' +
                      "you'll need to set them within a necessary project chosen'."
                    }
                    placement="top"
                    key={'project_settings'}
                  >
                    <div
                      className={`${profileThemeClass}__notificationTab ${notificationSettingsType === 'project' ? '-active' : ''}`}
                      onClick={() => handleNotificationTab('project')}
                    >
                      <span
                        className={`${profileThemeClass}__notificationTabText ${notificationSettingsType === 'project' ? '-active' : ''}`}
                      >
                        Project settings
                      </span>
                      <div
                        className={`${profileThemeClass}__highlightLine ${notificationSettingsType === 'project' ? '-active' : ''}`}
                      ></div>
                    </div>
                  </CustomTooltip>
                  <CustomTooltip
                    title={
                      'Here you manage the notifications preferences across all the projects. ' +
                      'If you want to set specific notifications for current project,' +
                      " please switch to 'Project notifications settings'."
                    }
                    placement="top"
                    key={'general_settings'}
                  >
                    <div
                      className={`${profileThemeClass}__notificationTab ${notificationSettingsType === 'general' ? '-active' : ''}`}
                      onClick={() => handleNotificationTab('general')}
                    >
                      <span
                        className={`${profileThemeClass}__notificationTabText ${notificationSettingsType === 'general' ? '-active' : ''}`}
                      >
                        General settings
                      </span>
                      <div
                        className={`${profileThemeClass}__highlightLine ${notificationSettingsType === 'general' ? '-active' : ''}`}
                      ></div>
                    </div>
                  </CustomTooltip>
                </div>
                {notificationSettingsType === 'project' && activeProject && (
                  <div className={`${profileThemeClass}__projectActivitySettings`}>
                    <div className={`${profileThemeClass}__labelGold`}>
                      <p>Overwrite general settings for</p> {activeProject && ucFirst(activeProject.title)}
                    </div>
                    <CustomTooltip
                      title={
                        "By switching this to 'on', you'll activate the Project specific notifications. " +
                        "When 'off' the General settings will be applied"
                      }
                      placement="top"
                      key={'project_settings'}
                    >
                      <div className={`${profileThemeClass}__toggleContainer`}>
                        <span className={`${profileThemeClass}__toggleText`}>Off</span>

                        <ToggleSwitch
                          isSelectedToggle={!!project_options.is_active}
                          handleSelect={handleSwitchProjectOptions}
                          disabled={false}
                          type={'toggle'}
                        />

                        <span className={`${profileThemeClass}__toggleText`}>On</span>
                      </div>
                    </CustomTooltip>
                  </div>
                )}
                {activeProject && (
                  <div className={`${profileThemeClass}__headItems`}>
                    <div
                      className={cn(`${profileThemeClass}__containerEmailNotificationSettings -popover`, {
                        ['-disabled']: !isGeneralSettings || projectNotificationDisabled,
                      })}
                    >
                      <div className={`${profileThemeClass}__title emailNotificationTitle`}>Personal notifications only</div>

                      <div className={`${profileThemeClass}__toggleContainer`}>
                        <span className={`${profileThemeClass}__toggleText`}>Off</span>
                        <CustomTooltip
                          title={"Don't get notified about items that are not directly related to you."}
                          placement="top"
                          key={'project_settings'}
                        >
                          <ToggleSwitch
                            isSelectedToggle={!!isOnlyMyNotifications}
                            handleSelect={event => handleSwitchEmailNotification(event, 'onlyMy')}
                            type={'toggle'}
                            disabled={!isGeneralSettings || projectNotificationDisabled}
                          />
                        </CustomTooltip>
                        <span className={`${profileThemeClass}__toggleText`}>On</span>
                      </div>
                    </div>
                  </div>
                )}
                {activeProject && (
                  <div className={`${profileThemeClass}__headItems`}>
                    <div
                      className={cn(`${profileThemeClass}__containerEmailNotificationSettings -popover`, {
                        ['-disabled']: !isGeneralSettings || projectNotificationDisabled,
                      })}
                    >
                      <div className={`${profileThemeClass}__title emailNotificationTitle`}>User discipline notifications only</div>

                      <div className={`${profileThemeClass}__toggleContainer`}>
                        <span className={`${profileThemeClass}__toggleText`}>Off</span>
                        <CustomTooltip
                          title={"Don't get notified about items that are not related to your user discipline."}
                          placement="top"
                          key={'project_settings'}
                        >
                          <ToggleSwitch
                            isSelectedToggle={!!isOnlyDisciplineNotifications}
                            handleSelect={event => handleSwitchEmailNotification(event, 'onlyDiscipline')}
                            type={'toggle'}
                            disabled={!isGeneralSettings || projectNotificationDisabled}
                          />
                        </CustomTooltip>
                        <span className={`${profileThemeClass}__toggleText`}>On</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${profileThemeClass}__headItems -popover`}>
                  <div className={`${profileThemeClass}__containerNotificationSettings ${projectNotificationDisabled ? '-disabled' : ''}`}>
                    <div className={`${profileThemeClass}__title`}>Instant emails</div>

                    <div className={`${profileThemeClass}__toggleContainer`}>
                      <span className={`${profileThemeClass}__toggleText`}>Off</span>
                      <ToggleSwitch
                        isSelectedToggle={!!isGeneralSettings}
                        handleSelect={handleGeneralSettings}
                        disabled={!!projectNotificationDisabled}
                        type={'toggle'}
                      />
                      <span className={`${profileThemeClass}__toggleText`}>On</span>
                    </div>
                  </div>

                  <div
                    className={`${profileThemeClass}__labelBlock -popover ${
                      !isGeneralSettings || projectNotificationDisabled ? `${profileThemeClass}__labelDisabled` : ''
                    }`}
                  >
                    <div className={`${profileThemeClass}__important_container`}>
                      <div className={`${profileThemeClass}__labelCheckBox`}>
                        <CheckBox
                          checked={!!isImportant}
                          onClick={() => handleNotification('important')}
                          disabled={!isGeneralSettings || !!projectNotificationDisabled}
                        />
                        <CustomTooltip
                          title={
                            'Customize your email notifications by selecting specific actions. Stay informed about the updates that matter to you most!'
                          }
                          placement="top"
                          key={'is_important'}
                        >
                          <span className={`${profileThemeClass}__toggleText`}>Important only</span>
                        </CustomTooltip>
                      </div>
                      {isImportant && isGeneralSettings ? <div className={'divider'}>|</div> : null}
                      {isImportant && isGeneralSettings ? (
                        <div className={'important_default_btn'} onClick={handleDefaultNotifications}>
                          <CustomButton type={'text-plain'} size={'xs'} title={'Reset To Default'} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {!!isImportant && !!data_important && !!isGeneralSettings && (
                    <div className={`${profileThemeClass}__profile_grid`}>
                      <ProfileGrid
                        data={data_important}
                        themeClass={themeClass}
                        isPopover
                        handleSelectNotifications={handleSelectNotifications}
                      />
                    </div>
                  )}
                </div>
                <div className={`${profileThemeClass}__containerNotificationSettings -emailReports`}>
                  <div className={`${profileThemeClass}__title`}>Email Reports</div>

                  <div className={`${profileThemeClass}__emailReportsColumn`}>
                    <div className={`${profileThemeClass}__emailReportsRow`}>
                      <div className={`${profileThemeClass}__labelCheckBox -emailReports`}>
                        <CheckBox checked={!!isReportDaily} onClick={() => handleNotification('report_daily')} />
                        <CustomTooltip
                          title={
                            'Customize your email notifications by selecting specific actions. Stay informed about the updates that matter to you most!'
                          }
                          placement="top"
                          key={'isReportDaily'}
                        >
                          <span className={`${profileThemeClass}__toggleText`}>Daily company report</span>
                        </CustomTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${profileThemeClass}__divider`}></div>

              <header className={`${profileThemeClass}__profileHeader`}>
                <div className={`${profileThemeClass}__hideAllWrap`}>
                  <span className={`${profileThemeClass}__title`}>Help menu settings</span>
                </div>
              </header>
              <div className={`${profileThemeClass}__settingsForm -helpers`}>
                <div className={`${profileThemeClass}__headItems -popover ${profileThemeClass}__headItems__helpMenu`}>
                  <div className={`${profileThemeClass}__containerNotificationSettings`}>
                    <div className={`${profileThemeClass}__settingsLabel`}>Show prompts</div>

                    <div className={`${profileThemeClass}__toggleContainer`}>
                      <span className={`${profileThemeClass}__toggleText`}>Off</span>
                      <ToggleSwitch
                        isSelectedToggle={!show_prompts}
                        handleSelect={handleSwitchShowPrompts}
                        disabled={false}
                        type={'toggle'}
                      />
                      <span className={`${profileThemeClass}__toggleText`}>On</span>
                    </div>
                  </div>
                  <div className={`${profileThemeClass}__containerNotificationSettings`}>
                    <div className={`${profileThemeClass}__settingsLabel`}>Show tooltips</div>

                    <div className={`${profileThemeClass}__toggleContainer`}>
                      <span className={`${profileThemeClass}__toggleText`}>Off</span>
                      <ToggleSwitch
                        isSelectedToggle={!show_tooltips}
                        handleSelect={handleSwitchShowTooltips}
                        disabled={false}
                        type={'toggle'}
                      />
                      <span className={`${profileThemeClass}__toggleText`}>On</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${profileThemeClass}__buttonSave ${isPopover ? '-popover' : ''}`}>
                <CustomButton
                  type={'primary'}
                  size={'md'}
                  title={'Save settings'}
                  clickHandler={handleSubmitSettings}
                  loading={isDataAnimeBtnLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <AvatarImageEditor
          width={150}
          height={150}
          borderRadius={100}
          isOpenEditorPopup={isOpenEditorPopup}
          handleCloseEditorPopup={handleCloseEditorPopup}
          imageForEdit={imageForEdit}
          avatarScale={avatarScale}
          avatarRotation={avatarRotation}
          editorPosition={editorPosition}
          handlePositionChange={handlePositionChange}
          handleSlider={handleSlider}
          onSliderChange={onSliderChange}
          rotateAvatar={rotateAvatar}
          onAvatarSave={onAvatarSave}
        />
      </div>
    </>
  );
};
export default ProfileEdit;
