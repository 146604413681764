import React, { useMemo, useState } from 'react';
import { SandBoxTabModel } from '../../../models/sandBox/sandBoxArhitecture';
import { Select } from '../../controls/Dropdowns/DropdownСomponents';
import { DropdownGroupItem, DropdownItemModel } from '../../../models/global';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppSelector } from '../../../store/configure/configureStore';
import { ItemsCounter } from '../../../components/controls/ItemsСounter';
import MiniLoader from '../../../components/controls/MiniLoader/MiniLoader';
import TokenIcon from '../../../components/controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './SubmenuSelectStyle.module.scss';

interface DropdownItemModelExt extends DropdownItemModel {
  count?: number;
  isCountersLoading?: string;
}

export type groupNameType = {
  value: string;
  title: string;
};

interface IProps {
  allSections: SandBoxTabModel[];
  activeSectionId: string;
  selectSection: (sectionId: string, isClick: boolean) => void;
  relatedTo: 'user' | 'party';
  isMobile: boolean;
  openedFrom?: string;
  report_user_id?: number;
}

const SubmenuSelect = ({ allSections, activeSectionId, selectSection, relatedTo, isMobile, openedFrom, report_user_id }: IProps) => {
  const themeClass = useGetThemeClass('b-submenu-select');
  const themeIconClass = useGetThemeClass('b-submenuNew');
  const sandBoxCountersLoading = useAppSelector(state => state.requestReducer.sandBoxCountersLoading);
  const [groupsForSelect, setGroupsForSelect] = useState<groupNameType[]>([]);

  const getIcon = (nameIcon: string, nameClass: string) => {
    return (
      <div className={`${themeIconClass}_container_icon ${themeIconClass}_${nameClass}`}>
        <TokenIcon iconName={nameIcon} size={16} />
      </div>
    );
  };

  const getTitle = (Sections: SandBoxTabModel) => {
    if (openedFrom === 'report') {
      if (Sections?.title_report) {
        if (report_user_id) {
          return Sections?.title_report.user;
        } else {
          return Sections?.title_report.company;
        }
      }
    }
    if (relatedTo === 'user' || report_user_id) {
      return Sections?.title_to_me;
    } else {
      return Sections?.title_to_company;
    }
  };

  const selectListAll = useMemo(() => {
    const GroupsForSelect = [];
    const AllSections = [];
    allSections
      .filter(item => (item.isHideSection ? false : true))
      .forEach(item => {
        const groupName = {
          value: item.id,
          title: getTitle(item),
        };

        GroupsForSelect.push(groupName);
        item.sections.forEach(section => {
          const obj = {
            value: section.id,
            title: section.title,
            group: groupName.value,
            leftElem: item.icon && getIcon(item.icon, item.iconNameClass),
            count: section.count,
            isCountersLoading: sandBoxCountersLoading.loading,
          };
          AllSections.push(obj);
        });
      });
    setGroupsForSelect(GroupsForSelect);
    return AllSections;
  }, [allSections, sandBoxCountersLoading]);

  const selected = useMemo(() => {
    if (selectListAll && activeSectionId) {
      const findIndex = selectListAll.findIndex(item => item.value === activeSectionId);
      return findIndex > -1 ? selectListAll[findIndex] : null;
    } else {
      return null;
    }
  }, [activeSectionId]);

  const itemLeftElemRenderer = (item: DropdownItemModelExt) => {
    return item.leftElem ? <div className={`${themeClass}`}>{item.leftElem}</div> : null;
  };

  const itemRightElemRenderer = (item: DropdownItemModelExt) => {
    const isSelected = activeSectionId === item.id;
    return (
      <div className={`${themeClass}_items_counter`}>
        {!item.isCountersLoading && <ItemsCounter value={item.count ? item.count : 0} size={isMobile ? 'mobile' : 'sm'} />}
        {item.isCountersLoading && (
          <div className={`${themeClass}_downloadBoxLoading`}>
            <MiniLoader size={'sm'} circleClasses={cn(`${themeClass}_loader`, { ['-selected']: isSelected })} />
          </div>
        )}
      </div>
    );
  };

  const renderBasisSelectedItem = (item: DropdownItemModelExt) => {
    const count = selectListAll.find(value => value.title === item.title && value.group === item.group)?.count;
    return (
      <div className={`${themeClass}_basisSelectedContainer`}>
        <div className={`${themeClass}`}>{item.leftElem}</div>
        <div className={cn(`${themeClass}_title`, { ['-mobile']: isMobile })}>{item.title}</div>
        <div className={`${themeClass}_countContainer`}>
          {!sandBoxCountersLoading.loading && <ItemsCounter value={count ? count : 0} size={isMobile ? 'mobile' : 'sm'} />}
          {sandBoxCountersLoading.loading && (
            <div className={`${themeClass}_downloadBoxLoading`}>
              <MiniLoader size={'sm'} circleClasses={`${themeClass}_loader`} />
            </div>
          )}{' '}
        </div>
      </div>
    );
  };

  const handleSelectSection = (item: DropdownItemModel) => {
    selectSection(String(item.value), true);
  };

  return (
    <>
      <Select<DropdownItemModelExt, DropdownGroupItem, false>
        isMulti={false}
        noSort
        menuItems={selectListAll}
        groups={groupsForSelect}
        menuGroupingType={'labeled'}
        selectHandler={handleSelectSection}
        selected={selected}
        headerAdditionalText={'Additional text'}
        isLoading={false}
        basisPlaceholder={'Select'}
        renderBasisSelectedItem={renderBasisSelectedItem}
        menuItemSize={'md'}
        menuItemLeftElemRenderer={itemLeftElemRenderer}
        menuItemRightElemRenderer={itemRightElemRenderer}
        customMenuItemClass={`${themeClass}_customClassSubmenuWidth`}
        customBasisFieldClass={isMobile ? `${themeClass}_mobileSubmenuSelect` : ''}
        isMobile={isMobile}
        disabled={sandBoxCountersLoading.loading}
        customClassPaperName={isMobile && `${themeClass}_customClassPaperNameMobile`}
        customMenuClass={isMobile ? `${themeClass}_customMenuClassMobile` : `${themeClass}_customMenuClass`}
      />
    </>
  );
};

export default SubmenuSelect;
