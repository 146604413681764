import { log } from 'util';
import { BallInCourtModel } from './../../models/request/request.model';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  IBallInCourtPayloadData,
  ICommentData,
  ICommentInfo,
  ICommentReducer,
  ICommentSubData,
  IFeedData,
  IResponseFeedPayload,
} from './commentReducer.model';

import Api from '../../service/api';

import {
  addBallInCourt,
  createComments,
  deleteComments,
  editBallInCourt,
  getBallInCourtList,
  getComments,
  getFeed,
  replyComments,
  unAssigmentUser,
  updateComments,
} from './commentsThunk';
import { IHistoryData } from '../history/historyReducer.model';

const initialState: ICommentReducer = {
  getFeedStatus: Api.initialStatus,
  getCommentStatus: Api.initialStatus,
  createCommentStatus: Api.initialStatus,
  createReplyStatus: Api.initialStatus,
  updateCommentStatus: Api.initialStatus,
  deleteCommentStatus: Api.initialStatus,
  getBallInCourtListStatus: Api.initialStatus,
  addBallInCourtStatus: Api.initialStatus,
  editBallInCourtStatus: Api.initialStatus,
  unAssignUserStatus: Api.initialStatus,
  isFirstFeedLoading: true,
  comments: {
    comments: [],
    total: 0,
    ballInCort: [],
  },
  feed: {
    feedData: [],
    important_history: [],
    incorporation_history: [],
    total: 0,
    newReplyComment: null,
  },
  ballInCourtList: {
    companies: [],
    users: [],
  },
  canComment: false,
  isNewComment: false,
  isNavigateToDiscussion: false,
  responseCommentsIds: [],
  bicIndicatorScrollToCommentId: null,
  isNavigateFromSandbox: { state: false, related: 'user' },
};

export const isCommentHelper = (comment: ICommentData | IHistoryData): comment is ICommentData => {
  return 'is_response' in comment;
};

export const commentSlice = createSlice({
  initialState,
  name: 'commentReducer',
  reducers: {
    setBicIndicatorScrollToCommentId(state, { payload }: PayloadAction<number | null>) {
      state.bicIndicatorScrollToCommentId = payload;
    },
    setIsNavigateFromSandbox(state, { payload }: PayloadAction<{ state: boolean; related: 'user' | 'party' }>) {
      state.isNavigateFromSandbox = payload;
    },
    clearComments(state) {
      state.comments = initialState.comments;
    },
    clearFeed(state) {
      return initialState;
    },
    socketCreateComments(state, { payload }: PayloadAction<ICommentData>) {
      payload.isNew = true;

      state.feed.feedData.push(payload);

      state.createCommentStatus = Api.successStatus;
    },

    socketUpdateComments(state, { payload }: PayloadAction<ICommentData>) {
      const currentState = current(state);
      const feedData = [...currentState.feed.feedData];

      const updateCommentFeedHelper = (feedData: (ICommentData | IHistoryData)[]): (ICommentData | IHistoryData)[] => {
        return feedData.map((feed, i) => {
          // console.log('feed', feed);
          if (isCommentHelper(feed)) {
            if (feed.id === payload.id && !payload.parent_id) {
              return payload;
            } else {
              if (feed.id === payload.parent_id) {
                const needSubId = feed.sub_comments.findIndex(sub => sub.id === payload.id);
                const copySubs = [...feed.sub_comments];

                copySubs.splice(needSubId, 1, payload);

                feed = { ...feed, sub_comments: copySubs };
              }

              return feed;
            }
          } else {
            return feed;
          }
        });
      };

      const updatedFeed = updateCommentFeedHelper(feedData);

      state.feed.feedData = updatedFeed;
      state.updateCommentStatus = Api.successStatus;
    },

    socketReplyComments(state, { payload }: PayloadAction<ICommentData>) {
      const currentState = current(state);
      const comments = [...currentState.comments.comments];
      const ballInCourts = [...currentState.comments.ballInCort];

      const filteredItems = (data: ICommentData[] | BallInCourtModel[], isComment: boolean) => {
        return data.map(item => {
          let subComments;

          if (isComment) {
            subComments = item.sub_comments.map(sub_item => {
              return {
                ...sub_item,
                isNew: false,
              };
            });
          }

          const filtered = {
            ...item,
            isNew: false,
          };

          if (isComment) {
            filtered.sub_comments = subComments;
          }

          return filtered;
        });
      };

      const filteredComments = filteredItems(comments, true);
      const filteredBallInCourts = filteredItems(ballInCourts, false);

      if (payload.parent_id) {
        const parentIndex = filteredComments.findIndex(f => f.id === payload.parent_id);
        filteredComments[parentIndex].sub_comments.push({
          ...payload,
          isNew: true,
        });
      } else {
        filteredComments.push({
          ...payload,
          isNew: true,
        });
      }

      state.comments.comments = filteredComments;
      state.comments.ballInCort = filteredBallInCourts;
      state.createCommentStatus = Api.successStatus;
    },

    socketDeleteComments(state, { payload }: PayloadAction<ICommentData>) {
      const filteredFeed = state.feed.feedData.map(el => {
        if (el.id === payload.id) {
          return payload;
        } else {
          return el;
        }
      });
      state.feed.feedData = filteredFeed;
      state.deleteCommentStatus = Api.successStatus;
    },

    socketCreateBallInCourt(state, { payload }: PayloadAction<BallInCourtModel>) {
      const currentState = current(state);
      const comments = [...currentState.comments.comments];
      const ballInCourts = [...currentState.comments.ballInCort];

      const filteredItems = (data: ICommentData[] | BallInCourtModel[], isComment) => {
        return data.map(item => {
          let subComments;

          if (isComment) {
            subComments = item.sub_comments.map(sub_item => {
              return {
                ...sub_item,
                isNew: false,
              };
            });
          }

          const filtered = {
            ...item,
            isNew: false,
          };

          if (isComment) {
            filtered.sub_comments = subComments;
          }

          return filtered;
        });
      };

      const filteredComments = filteredItems(comments, true);
      const filteredBallInCourts = filteredItems(ballInCourts, false);

      payload.isNew = true;

      filteredBallInCourts.unshift(payload);

      const newTotal = currentState.comments.total ? currentState.comments.total + 1 : 1;

      state.comments.ballInCort = filteredBallInCourts;
      state.comments.comments = filteredComments;
      state.comments.total = newTotal;
      state.addBallInCourtStatus = Api.successStatus;
    },

    socketEditBallInCourt(state, { payload }: PayloadAction<BallInCourtModel>) {
      const currentState = current(state);
      const data = [...currentState.comments.ballInCort];

      const editedIndex = data.findIndex(f => f.bic_id === payload.bic_id);
      if (editedIndex > -1) {
        data[editedIndex] = payload;
      }

      state.comments.ballInCort = data;
      state.editBallInCourtStatus = Api.successStatus;
    },

    handleSaveResponseCommentId(state, { payload }: PayloadAction<number | undefined>) {
      if (payload) {
        if (state.responseCommentsIds.includes(payload)) {
          state.responseCommentsIds = state.responseCommentsIds.filter(id => id !== payload);
        } else {
          state.responseCommentsIds.push(payload);
        }
      } else {
        state.responseCommentsIds = [];
      }
    },
    handleDeleteResponseCommentId(state, { payload }: PayloadAction<number>) {
      state.responseCommentsIds = state.responseCommentsIds.filter(id => id !== payload);
    },
    handleGoToNavigate(state, { payload }: PayloadAction<boolean>) {
      state.isNavigateToDiscussion = payload;
    },

    handleResetCreateStatus(state) {
      state.createCommentStatus = Api.initialStatus;
    },

    removeIsNewStatus(state, { payload }: PayloadAction<number>) {
      state.feed.feedData.map((feed, i) => {
        if (isCommentHelper(feed)) {
          const copyFeed = { ...feed };

          const clearStatusComments = copyFeed.sub_comments.map(sub => {
            if (sub.id === payload) {
              sub.isNew = false;
              return sub;
            }
          });

          copyFeed.sub_comments = clearStatusComments;
          return copyFeed;
        } else {
          return feed;
        }
      });
    },
  },

  extraReducers: builder => {
    builder
      /**
       * getFeed
       */

      .addCase(getFeed.pending, (state, { payload }: PayloadAction<any>) => {
        // const currentState = current(state);
        // let comments = currentState.comments.comments.length ? [...currentState.comments.comments] : [];
        // let ballInCort = currentState.comments.ballInCort.length ? [...currentState.comments.ballInCort] : [];
        // let total = currentState.comments.total ? currentState.comments.total : 0;
        // if (meta.arg && meta.arg.isNew) {
        //   comments = [];
        //   ballInCort = [];
        //   total = 0;
        // }
        state.getFeedStatus = Api.requestStatus;
        // state.comments.comments = comments;
        // state.comments.ballInCort = ballInCort;
        // state.comments.total = total;
      })
      .addCase(getFeed.fulfilled, (state, { payload }: PayloadAction<IResponseFeedPayload>) => {
        // console.log('getFeed.fulfilled payload', payload);
        const currentState = current(state);
        let updatedFeed = null;

        if (currentState.feed.newReplyComment) {
          const updateCommentFeedHelper = (feedData: (ICommentData | IHistoryData)[]): (ICommentData | IHistoryData)[] => {
            return feedData.map((feed, i) => {
              if (isCommentHelper(feed)) {
                if (currentState.feed.newReplyComment.parent_id === feed.id) {
                  feed.sub_comments.push(currentState.feed.newReplyComment);
                }
                return feed;
              } else {
                return feed;
              }
            });
          };
          updatedFeed = updateCommentFeedHelper(state.feed.feedData);
        }
        state.feed.feedData = updatedFeed;

        // console.log('currentState newReplyComment', currentState.feed.newReplyComment);
        //
        state.getFeedStatus = Api.successStatus;
        state.isFirstFeedLoading = false;
        if (!updatedFeed) {
          state.feed.feedData = payload.resource.items;
        }
        state.feed.important_history = payload.resource.important_history;
        state.feed.incorporation_history = payload.resource.incorporation_history;
        state.feed.total = payload.resource.items.length;
        state.canComment = payload.can_comment;
        state.feed.newReplyComment = null;
        state.unAssignUserStatus = Api.successStatus;
        // // state.responseCommentsIds.map()
        payload.resource.items?.reverse().reduce((_, comment) => {
          if (isCommentHelper(comment)) {
            // comment
            if (comment.is_response) {
              state.responseCommentsIds.push(comment.id);
            }
            comment.sub_comments.reduce((_, sub) => {
              if (sub.is_response) {
                state.responseCommentsIds.push(sub.id);
              }
              return _;
            }, null);
            return _;
          } else {
            return _;
          }
        }, null);
      })
      .addCase(getFeed.rejected, (state, { meta }) => {
        state.getFeedStatus = Api.failStatus;
        state.isNewComment = false;
        state.isFirstFeedLoading = meta.aborted;
      })
      /**
       * getComments
       */

      .addCase(getComments.pending, (state, { meta }: PayloadAction<BallInCourtModel, string, any>) => {
        const currentState = current(state);
        let comments = currentState.comments.comments.length ? [...currentState.comments.comments] : [];
        let ballInCort = currentState.comments.ballInCort.length ? [...currentState.comments.ballInCort] : [];
        let total = currentState.comments.total ? currentState.comments.total : 0;
        if (meta.arg && meta.arg.isNew) {
          comments = [];
          ballInCort = [];
          total = 0;
        }

        state.getCommentStatus = Api.requestStatus;
        state.comments.comments = comments;
        state.comments.ballInCort = ballInCort;
        state.comments.total = total;
      })
      .addCase(getComments.fulfilled, (state, { payload }: PayloadAction<{ data: ICommentInfo; response: { can_comment: boolean } }>) => {
        state.getCommentStatus = Api.successStatus;
        state.comments = payload.data;
        // state.responseCommentsIds.map()
        payload.data.comments?.reverse().reduce((_, comment) => {
          if (comment.is_response) {
            state.responseCommentsIds.push(comment.id);
          }

          comment.sub_comments.reduce((_, sub) => {
            if (sub.is_response) {
              state.responseCommentsIds.push(sub.id);
            }
            return _;
          }, null);
          return _;
        }, null);
        state.canComment = payload.response.can_comment;
      })
      .addCase(getComments.rejected, state => {
        state.getCommentStatus = Api.failStatus;
        state.isNewComment = false;
      })
      /**
       * createComments
       */
      .addCase(createComments.pending, state => {
        state.createCommentStatus = Api.requestStatus;
      })
      .addCase(createComments.fulfilled, (state, { payload }: PayloadAction<ICommentData>) => {
        const currentState = current(state);
        const comments = [...currentState.comments.comments];
        const ballInCourts = [...currentState.comments.ballInCort];

        const filteredItems = (data: ICommentData[] | BallInCourtModel[], isComment: boolean) => {
          return data.map(item => {
            let subComments;

            if (isComment) {
              subComments = item.sub_comments.map(sub_item => {
                return {
                  ...sub_item,
                  isNew: false,
                };
              });
            }

            const filtered = {
              ...item,
              isNew: false,
            };

            if (isComment) {
              filtered.sub_comments = subComments;
            }

            return filtered;
          });
        };

        const filteredComments = filteredItems(comments, true);
        const filteredBallInCourts = filteredItems(ballInCourts, false);

        payload.isNew = true;

        filteredComments.unshift(payload);

        const newTotal = currentState.comments.total ? currentState.comments.total + 1 : 1;

        state.comments.comments = filteredComments;
        state.comments.ballInCort = filteredBallInCourts;
        state.comments.total = newTotal;
        state.createCommentStatus = Api.successStatus;
      })
      .addCase(createComments.rejected, state => {
        state.createCommentStatus = Api.failStatus;
      })
      /**
       * replyComments
       */
      .addCase(replyComments.pending, state => {
        state.createReplyStatus = Api.requestStatus;
      })
      .addCase(replyComments.fulfilled, (state, { payload }: PayloadAction<ICommentData>) => {
        payload.isNew = true;
        state.feed.newReplyComment = payload;
        state.createReplyStatus = Api.successStatus;
      })
      .addCase(replyComments.rejected, state => {
        state.createReplyStatus = Api.failStatus;
      })
      /**
       * updateComments
       */
      .addCase(updateComments.pending, state => {
        state.updateCommentStatus = Api.requestStatus;
      })
      .addCase(updateComments.fulfilled, (state, { payload }: PayloadAction<ICommentData>) => {
        const currentState = current(state);
        const data = [...currentState.comments.comments];
        if (payload.parent_id) {
          const parentIndex = data.findIndex(f => f.id === payload.parent_id);
          if (parentIndex > -1) {
            const subCommentIndex = data[parentIndex].sub_comments.findIndex(f => f.id === payload.id);
            const parentComment = { ...data[parentIndex] };
            const parentSubComments = [...parentComment.sub_comments];
            parentSubComments[subCommentIndex] = payload;
            parentComment.sub_comments = parentSubComments;
            data[parentIndex] = parentComment;
          }
        } else {
          const updatedIndex = data.findIndex(f => f.id === payload.id);
          if (updatedIndex > -1) {
            data[updatedIndex] = payload;
          }
        }

        state.comments.comments = data;
        state.updateCommentStatus = Api.successStatus;
      })
      .addCase(updateComments.rejected, state => {
        state.updateCommentStatus = Api.failStatus;
      })
      /**
       * unAssigmentUser
       */
      .addCase(unAssigmentUser.pending, state => {
        state.unAssignUserStatus = Api.requestStatus;
      })
      .addCase(unAssigmentUser.fulfilled, (state, { payload }: PayloadAction<ICommentData>) => {
        return state;
      })
      .addCase(unAssigmentUser.rejected, state => {
        state.unAssignUserStatus = Api.failStatus;
      })
      /**
       * deleteComments
       */
      .addCase(deleteComments.pending, state => {
        state.deleteCommentStatus = Api.requestStatus;
      })
      .addCase(deleteComments.fulfilled, (state, { payload }: PayloadAction<ICommentSubData>) => {
        const currentState = current(state);
        const data = JSON.parse(JSON.stringify(currentState.comments.comments)) as ICommentData[];
        if (payload.parent_id) {
          const parentIndex = data.findIndex(f => f.id === payload.parent_id);
          if (parentIndex > -1) {
            const subCommentIndex = data[parentIndex].sub_comments.findIndex(f => f.id === payload.id);
            data[parentIndex].sub_comments.splice(subCommentIndex, 1, payload);
          }
        } else {
          const removedIndex = data.findIndex(f => f.id === payload.id);
          if (removedIndex > -1 && !data[removedIndex]?.sub_comments?.length) {
            data.splice(removedIndex, 1, payload);
          } else if (removedIndex > -1 && data[removedIndex]?.sub_comments?.length) {
            data.splice(removedIndex, 1, payload);
            data[removedIndex].is_deactivated = true;
          }
        }

        const newTotal = currentState.comments.total ? currentState.comments.total - 1 : 0;

        state.comments.comments = data;
        state.comments.total = newTotal;
        state.deleteCommentStatus = Api.successStatus;
      })
      .addCase(deleteComments.rejected, state => {
        state.deleteCommentStatus = Api.failStatus;
      })
      /**
       * getBallInCourtList
       */
      .addCase(getBallInCourtList.pending, state => {
        const currentState = current(state);
        const ballInCourtList = currentState.ballInCourtList ? { ...currentState.ballInCourtList } : { companies: [], users: [] };

        state.getBallInCourtListStatus = Api.requestStatus;
        state.ballInCourtList = ballInCourtList;
      })
      .addCase(getBallInCourtList.fulfilled, (state, { payload }: PayloadAction<IBallInCourtPayloadData>) => {
        state.getBallInCourtListStatus = Api.successStatus;
        state.ballInCourtList = payload;
      })
      .addCase(getBallInCourtList.rejected, state => {
        state.getBallInCourtListStatus = Api.failStatus;
      })
      /**
       * addBallInCourt
       */
      .addCase(addBallInCourt.pending, state => {
        state.addBallInCourtStatus = Api.requestStatus;
      })
      .addCase(addBallInCourt.fulfilled, (state, { payload }: PayloadAction<BallInCourtModel>) => {
        const currentState = current(state);
        const comments = [...currentState.comments.comments];
        const ballInCourts = [...currentState.comments.ballInCort];

        const filteredItems = (data: ICommentData[] | BallInCourtModel[], isComment) => {
          return data.map(item => {
            let subComments;

            if (isComment) {
              subComments = item.sub_comments.map(sub_item => {
                return {
                  ...sub_item,
                  isNew: false,
                };
              });
            }

            const filtered = {
              ...item,
              isNew: false,
            };

            if (isComment) {
              filtered.sub_comments = subComments;
            }

            return filtered;
          });
        };

        const filteredComments = filteredItems(comments, true);
        const filteredBallInCourts = filteredItems(ballInCourts, false);

        payload.isNew = true;

        filteredBallInCourts.unshift(payload);

        const newTotal = currentState.comments.total ? currentState.comments.total + 1 : 1;

        state.comments.ballInCort = filteredBallInCourts;
        state.comments.comments = filteredComments;
        state.comments.total = newTotal;
        state.addBallInCourtStatus = Api.successStatus;
      })
      .addCase(addBallInCourt.rejected, state => {
        state.addBallInCourtStatus = Api.failStatus;
      })
      /**
       * editBallInCourt
       */
      .addCase(editBallInCourt.pending, state => {
        state.editBallInCourtStatus = Api.requestStatus;
      })
      .addCase(editBallInCourt.fulfilled, (state, { payload }: PayloadAction<BallInCourtModel>) => {
        const currentState = current(state);
        const data = [...currentState.comments.ballInCort];

        const editedIndex = data.findIndex(f => f.bic_id === payload.bic_id);
        if (editedIndex > -1) {
          data[editedIndex] = payload;
        }

        state.comments.ballInCort = data;
        state.editBallInCourtStatus = Api.successStatus;
      })
      .addCase(editBallInCourt.rejected, state => {
        state.editBallInCourtStatus = Api.failStatus;
      });
  },
});

export default commentSlice.reducer;
