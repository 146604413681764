export type LinkedItemsShowType =
  | 'outstanding'
  | 'open'
  | 'all'
  | 'accepted'
  | 'incorporated in this level/typicality'
  | 'accepted, not incorporated in this level/typicality'
  | 'deactivated';
export type LinkedType = 'predecessor' | 'successor';

export const linkedItemsShowTypesNfToNF = ['outstanding', 'accepted'] as LinkedItemsShowType[];
export const linkedItemsShowTypesDelToNf = [
  'outstanding',
  'open',
  'all',
  'accepted',
  'incorporated in this level/typicality',
  'accepted, not incorporated in this level/typicality',
] as LinkedItemsShowType[];
export const linkedParts = ['predecessor', 'successor'] as LinkedType[];
