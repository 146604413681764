import React from 'react';
import moment from 'moment';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import CustomSlider from '../../../../controls/Slider/Slider';
import { Dialog } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Popover from '@material-ui/core/Popover';
import SVG from 'react-inlinesvg';
import { milestoneLevels, MilestoneKeys } from '../../../../../constants/phasePlanColumns';
import ColorHelper from '../../../../../helpers/ColorHelper';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { savePhasePlan } from '../../../../../store/project/projectLogic';
import { withRouterHelper } from '../../../../../navigation/withRouterHelper/withRouterHelper';

class SchedulePlan extends React.Component {
  state = {
    scheduleColumns: [],
    permissionColumns: [],
    schedules: [],
    permissions: [],
    steps: {
      scheduleStep: 1,
      permissionStep: 1,
    },
    rowStep: 14,
    tableRef: React.createRef(),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (Object.values(this.props.phasePlanData || {}).length && this.props.phasePlanData !== prevProps.phasePlanData) {
      this.initPhasePlan();
    }
    if (!Object.values(this.props.phasePlanData || {}).length && this.props.phasePlanData !== prevProps.phasePlanData) {
      this.setState({
        schedules: [],
        permissions: [],
      });
    }
    if (this.state.isDrag !== prevProps.isDrag) {
      this.props.onChangeIsDrag(this.state.isDrag);
    }
    if (this.props.gmp !== prevProps.gmp) {
      this.initPhasePlan();
    }
  }

  initPhasePlan = async () => {
    const startProjectDate = this.props.phasePlanData.project.target_start;
    const endProjectDate = this.props.phasePlanData.project.target_to;
    const scheduleColumns = [];
    const permissionColumns = [];
    if (this.state.steps.scheduleStep === 1 || this.state.steps.scheduleStep === 2) {
      const weeksCount = (moment(endProjectDate).diff(new Date(startProjectDate), 'week') + 1) / this.state.steps.scheduleStep;
      for (let i = 0; i <= weeksCount; i++) {
        scheduleColumns.push({
          id: `title__${i}`,
          label: moment(startProjectDate)
            .add(i * (this.state.steps.scheduleStep * 7), 'days')
            .format('YYYY-MM-DD'),
          collection: null,
          isToday: !moment().diff(
            moment(startProjectDate)
              .add(i * (this.state.steps.scheduleStep * 7), 'days')
              .format('YYYY-MM-DD'),
            'weeks',
            true,
          ),
        });
      }
    }
    if (this.state.steps.scheduleStep === 3) {
      const weeksCount = moment(endProjectDate).diff(new Date(startProjectDate), 'month');
      // const weeksCount = (moment(endProjectDate).diff(new Date(startProjectDate), 'week') + 1)/this.state.steps.scheduleStep;
      for (let i = 0; i <= weeksCount; i++) {
        scheduleColumns.push({
          id: `title__${i}`,
          label: moment(startProjectDate).add(i, 'month').format('YYYY-MM-DD'),
          collection: null,
          isToday: !moment().diff(moment(startProjectDate).add(i, 'month').format('YYYY-MM-DD'), 'month', true),
        });
      }
    }
    if (this.state.steps.permissionStep === 1 || this.state.steps.permissionStep === 2) {
      const weeksCount = (moment(endProjectDate).diff(new Date(startProjectDate), 'week') + 1) / this.state.steps.permissionStep;
      for (let i = 0; i <= weeksCount; i++) {
        permissionColumns.push({
          id: `title__${i}`,
          label: moment(startProjectDate)
            .add(i * (this.state.steps.permissionStep * 7), 'days')
            .format('YYYY-MM-DD'),
          collection: null,
          isToday: !moment().diff(
            moment(startProjectDate)
              .add(i * (this.state.steps.permissionStep * 7), 'days')
              .format('YYYY-MM-DD'),
            'weeks',
            true,
          ),
        });
      }
    }
    if (this.state.steps.permissionStep === 3) {
      // const weeksCount = (moment(endProjectDate).diff(new Date(startProjectDate), 'week') + 1)/this.state.steps.permissionStep;
      const weeksCount = moment(endProjectDate).diff(new Date(startProjectDate), 'month');
      for (let i = 0; i <= weeksCount; i++) {
        permissionColumns.push({
          id: `title__${i}`,
          label: moment(startProjectDate).add(i, 'month').format('YYYY-MM-DD'),
          collection: null,
          isToday: !moment().diff(moment(startProjectDate).add(i, 'month').format('YYYY-MM-DD'), 'month', true),
        });
      }
    }
    let isExistGMPLine = scheduleColumns.length && !!scheduleColumns.find(f => f.isGMP);
    if ((this.props.phasePlanData.gmp || this.props.gmp) && scheduleColumns.length && !isExistGMPLine) {
      const filteredColumns = scheduleColumns.filter(
        column => moment(column.label).diff(this.props.gmp || this.props.phasePlanData.gmp, 'days') <= 0,
      );
      if (scheduleColumns?.[filteredColumns.length - 1]) {
        scheduleColumns.length ? (scheduleColumns[filteredColumns.length - 1].isGMP = true) : null;
        isExistGMPLine = true;
      }
    }

    if (!isExistGMPLine) {
      scheduleColumns[0].isGMP = true;
    }

    await this.setState({
      scheduleColumns: scheduleColumns,
      permissionColumns: permissionColumns,
      schedules: [...this.props.phasePlanData.schedules],
      permissions: [...this.props.phasePlanData.permissions],
    });
  };

  handleChangeRange = type => async value => {
    await this.setState({
      steps: {
        ...this.state.steps,
        scheduleStep: value,
        permissionStep: value,
      },
    });
    await this.initPhasePlan();
  };

  countLodWidth = (startDate, duration) => {
    const { scheduleColumns, steps } = this.state;
    const { scheduleStep } = steps;
    if (scheduleStep === 1 || scheduleStep === 2) {
      return Math.ceil(moment(startDate).diff(scheduleColumns[0].label, 'weeks') / scheduleStep);
    }
    if (scheduleStep === 3) {
      return moment(moment(startDate).diff(moment(scheduleColumns[0].label).format('YYYY-MM'), 'month'));
    }
  };

  onDragStart = () => {
    this.setState({
      isDrag: true,
    });
  };

  onDragEnd = result => {
    const { scheduleColumns } = this.state;
    this.setState({
      isDrag: false,
    });

    if (!result?.destination) {
      return;
    }

    scheduleColumns.map(s => (s.isGMP = false));
    scheduleColumns[Number(result.destination.droppableId)].isGMP = true;
    this.setState({
      scheduleColumns: scheduleColumns,
      isDrag: false,
    });
    this.props.setDateGMP(scheduleColumns[Number(result.destination.droppableId)].label);
  };

  handleLegend = e => {
    this.setState({
      isShowLegend: !this.state.isShowLegend,
      anchorEl: e.currentTarget,
    });
  };

  legendItemHover = level => {
    this.props.legendItemHover(level);
  };

  legendItemLeave = level => {
    this.props.legendItemHover();
  };

  selectRequestHandler = (requestId, isBackRequest, query, nf) => {
    this.props.routerHelper.setUrlNF(nf);
    this.setState({
      selectedRequestId: requestId,
    });
  };

  handleCloseRequest = () => {
    this.props.routerHelper.removeParams();
    this.setState({
      selectedRequestId: null,
    });
  };

  componentWillUnmount() {
    this.setState({
      milestoneAnchorEl: null,
      milestone: null,
    });
  }

  render() {
    const { isOpenCollapse, wrapTableRef, editMode, hoverLegendMilestone, isActivePhasePlan } = this.props;

    const {
      permissionColumns,
      scheduleColumns,
      schedules,
      permissions,
      steps,
      isDrag,
      tableRef,
      isShowLegend,
      anchorEl,
      milestoneAnchorEl,
      milestone,
      selectedRequestId,
    } = this.state;

    const { scheduleStep, permissionStep } = steps;
    const bodyWidth = Math.max(scheduleColumns.length, permissionColumns.length) * 29;

    if (!scheduleColumns.length) {
      return <></>;
    }

    const getItemStyle = (isDragging, draggableStyle, isDrag) => ({
      ...draggableStyle,
      height: '61px',
      width: '29px',
      ...getColorLine(MilestoneKeys.gmp),
      // styles we need to apply on draggables
      zIndex: 3,
      position: 'absolute',

      ...(isDragging && {
        background: milestoneLevels.gmp.color,
        backgroundColor: milestoneLevels.gmp.color,
        position: 'fixed',
      }),
    });

    const getColorLine = milestoneKey => {
      if (!milestoneKey) {
        return {};
      }
      return {
        backgroundColor: ColorHelper.bgColorSetDarken(
          ColorHelper.getCssVariable(milestoneLevels[milestoneKey].color),
          hoverLegendMilestone && hoverLegendMilestone !== milestoneKey ? 0.6 : 0,
        ),
        color: ColorHelper.bgColorSetDarken(
          ColorHelper.getCssVariable('var(--phasePlan-color)'),
          hoverLegendMilestone && hoverLegendMilestone !== milestoneKey ? 0.6 : 0,
        ),
      };
    };

    const getPermitColorLine = (milestoneKey, row) => {
      if (!milestoneKey) {
        return {};
      }
      return {
        backgroundColor: ColorHelper.bgColorSetDarken(
          ColorHelper.getCssVariable(
            typeof row.color === 'number' && row.color > -1
              ? `var(--phasePlan-permit${row.color + 1})`
              : milestoneLevels[milestoneKey].color,
          ),
          hoverLegendMilestone && hoverLegendMilestone !== milestoneKey ? 0.6 : 0,
        ),
        color: ColorHelper.bgColorSetDarken(
          ColorHelper.getCssVariable('var(--phasePlan-color)'),
          hoverLegendMilestone && hoverLegendMilestone !== milestoneKey ? 0.6 : 0,
        ),
      };
    };

    return (
      <>
        {isActivePhasePlan ? (
          <div className={`b-phasePlan__wrapTable ${isOpenCollapse ? 'b-phasePlan__open' : 'b-phasePlan__close'}`} ref={wrapTableRef}>
            <div aria-label="sticky table" className={'b-phasePlan__table'} ref={tableRef}>
              <div
                className={`b-phasePlan__body`}
                style={{
                  width: bodyWidth,
                  position: 'relative',
                  zIndex: 2,
                }}
              >
                <div
                  className={'b-phasePlan__rowHead b-phasePlan__rowHeadHidingField'}
                  style={{
                    zIndex: editMode ? 100 : 99,
                    position: 'sticky',
                  }}
                >
                  <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onDragStart}>
                    {scheduleColumns.map((column, columnIndex) => {
                      return (
                        <Droppable droppableId={`${columnIndex}`} direction="horizontal" key={`droppableShedule-${columnIndex}`}>
                          {(provided, snapshot, e) => {
                            let lastPositionX = null;
                            return (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                <>
                                  <div align={column.align} className={`b-phasePlan__head`}>
                                    {column.isGMP && (
                                      <Draggable draggableId={`id-${columnIndex}`} index={columnIndex} isDragDisabled={!editMode}>
                                        {(provided, snapshot, e) => {
                                          if (provided.draggableProps.style.transform) {
                                            let transformArray = provided.draggableProps.style.transform.split(',');
                                            if (snapshot.draggingOver) {
                                              lastPositionX = transformArray[0];
                                            }
                                            transformArray[0] = lastPositionX;
                                            transformArray[1] = '0px)';
                                            if (transformArray[0] && transformArray[1]) {
                                              provided.draggableProps.style.transform = transformArray.join(',');
                                            } else {
                                              provided.draggableProps.style.transform = 'translate(0px,0px)';
                                            }
                                          }

                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              onMouseDown={e => {
                                                if (!editMode) {
                                                  return;
                                                }
                                                this.onDragStart();
                                                provided?.dragHandleProps?.onMouseDown(e);
                                              }}
                                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, isDrag)}
                                              className={'b-phasePlan__scheduleDnDBlock'}
                                            >
                                              <CustomTooltip title={`GMP line`} placement={'top'}>
                                                <div>
                                                  <div
                                                    className={'b-phasePlan__verticalLineGMP'}
                                                    style={{
                                                      height: tableRef?.current?.clientHeight - 50,
                                                      ...getColorLine(MilestoneKeys.gmp),
                                                    }}
                                                  ></div>
                                                  <span
                                                    style={{
                                                      position: 'relative',
                                                      zIndex: 2,
                                                      color: column.isGMP ? 'var(--background-main)' : '',
                                                      fontWeight: 'var(--font-weight)',
                                                    }}
                                                  >
                                                    {scheduleStep === 1 || scheduleStep === 2
                                                      ? moment(scheduleColumns[snapshot.draggingOver]?.label || column.label).format(
                                                          'DD-MMM-YY',
                                                        )
                                                      : moment(scheduleColumns[snapshot.draggingOver]?.label || column.label).format(
                                                          'MMM - YYYY',
                                                        )}
                                                  </span>
                                                </div>
                                              </CustomTooltip>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    )}
                                    <span className={'b-phasePlan__verticalLine'}></span>
                                    {moment().diff(column.label, 'days') > 0 && moment().diff(column.label, 'days') <= scheduleStep * 7 ? (
                                      <div className={'b-phasePlan__toDay'}>Today</div>
                                    ) : null}
                                    <span style={{ position: 'relative', zIndex: 2 }}>
                                      {scheduleStep === 1 || scheduleStep === 2
                                        ? moment(column.label).format('DD-MMM-YY')
                                        : moment(column.label).format('MMM - YYYY')}
                                    </span>
                                  </div>
                                </>
                              </div>
                            );
                          }}
                        </Droppable>
                      );
                    })}
                  </DragDropContext>
                </div>

                {schedules.map((row, rowIndex) => {
                  if (row.isMainBlock) {
                    return <></>;
                  }
                  let widthLod200, widthLod290, widthLod300, widthLod325, widthLod350;
                  widthLod200 = this.countLodWidth(row.lod290_start, null) + 1;
                  widthLod290 = this.countLodWidth(row.lod300_start, row.lod290_duration) + 1;
                  widthLod300 = this.countLodWidth(row.lod325_start, row.lod300_duration) + 1;
                  widthLod325 = this.countLodWidth(row.lod350_start, row.lod325_duration) + 1;
                  widthLod350 = this.countLodWidth(row.construction_start, row.lod350_duration) + 1;
                  widthLod200 < 0 ? (widthLod200 = 0) : null;
                  widthLod290 < 0 ? (widthLod290 = 0) : null;
                  widthLod300 < 0 ? (widthLod300 = 0) : null;
                  widthLod325 < 0 ? (widthLod325 = 0) : null;
                  widthLod350 < 0 ? (widthLod350 = 0) : null;
                  let widthConstruction = 1;
                  if (scheduleStep === 1 || scheduleStep === 2) {
                    widthConstruction = Math.ceil(
                      (moment(row.construction_end).diff(scheduleColumns[0].label, 'weeks') + 1) / scheduleStep,
                    );
                  }
                  if (scheduleStep === 3) {
                    widthConstruction = Math.ceil(moment(row.construction_end).diff(scheduleColumns[0].label, 'month') + 1);
                  }
                  return (
                    <div key={row.code} className={'b-phasePlan__row'}>
                      {widthConstruction ? (
                        <CustomTooltip title={row.title} placement="top" followCursor>
                          <div
                            className={`b-phasePlan__cell b-phasePlan__hover`}
                            style={{
                              width: `${widthConstruction * 28 + widthConstruction}px`,
                              minWidth: `${widthConstruction * 28 + widthConstruction}px`,
                              ...getColorLine(MilestoneKeys.construction),
                            }}
                          ></div>
                        </CustomTooltip>
                      ) : null}

                      {widthLod350 ? (
                        <div
                          className={'b-phasePlan__cell'}
                          style={{
                            width: `${widthLod350 * 28 + widthLod350}px`,
                            minWidth: `${widthLod350 * 28 + widthLod350}px`,
                            ...getColorLine(MilestoneKeys.lod325),
                          }}
                        >
                          <span style={{ ...getColorLine(MilestoneKeys.lod350), zIndex: 10, cursor: 'pointer' }}>350</span>
                        </div>
                      ) : null}

                      {widthLod325 ? (
                        <div
                          className={'b-phasePlan__cell'}
                          style={{
                            width: `${widthLod325 * 28 + widthLod325}px`,
                            minWidth: `${widthLod325 * 28 + widthLod325}px`,
                            ...getColorLine(MilestoneKeys.lod300),
                          }}
                        >
                          <span style={{ ...getColorLine(MilestoneKeys.lod325), zIndex: 9, cursor: 'pointer' }}>325</span>
                        </div>
                      ) : null}

                      {widthLod300 ? (
                        <div
                          className={'b-phasePlan__cell'}
                          style={{
                            width: `${widthLod300 * 28 + widthLod300}px`,
                            minWidth: `${widthLod300 * 28 + widthLod300}px`,
                            ...getColorLine(MilestoneKeys.lod290),
                          }}
                        >
                          <span style={{ ...getColorLine(MilestoneKeys.lod300), zIndex: 8, cursor: 'pointer' }}>300</span>
                        </div>
                      ) : null}

                      {widthLod290 ? (
                        <div
                          className={'b-phasePlan__cell'}
                          style={{
                            width: `${widthLod290 * 28 + widthLod290}px`,
                            minWidth: `${widthLod290 * 28 + widthLod290}px`,
                            ...getColorLine(MilestoneKeys.lod200),
                          }}
                        >
                          <span style={{ ...getColorLine(MilestoneKeys.lod290), zIndex: 7, cursor: 'pointer' }}>290</span>
                        </div>
                      ) : null}

                      {widthLod200 ? (
                        <div
                          className={'b-phasePlan__cell'}
                          style={{
                            width: `${widthLod200 * 28 + widthLod200}px`,
                            minWidth: `${widthLod200 * 28 + widthLod200}px`,
                          }}
                        >
                          <span style={{ ...getColorLine(MilestoneKeys.lod200), zIndex: 6, cursor: 'pointer' }}>200</span>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div
                className={`b-phasePlan__body`}
                style={{ width: bodyWidth, backgroundPosition: '0px 17px', position: 'relative', zIndex: 1 }}
              >
                <div className={'b-phasePlan__rowHead b-phasePlan__rowHeadHidingField'}>
                  {scheduleColumns.map((column, columnIndex) => (
                    <div key={`permissionsColumns-${column.id}`} align={column.align} className={`b-phasePlan__head`}>
                      {column.isGMP && !isDrag ? (
                        <div className={'b-phasePlan__permissionGMP'} style={{ ...getColorLine(MilestoneKeys.gmp) }}></div>
                      ) : null}
                      {!column.isGMP && <span className={'b-phasePlan__verticalLine'}></span>}
                      {moment().diff(column.label, 'days') > 0 && moment().diff(column.label, 'days') <= permissionStep * 7 ? (
                        <div className={'b-phasePlan__toDay'}>Today</div>
                      ) : null}
                      <span
                        style={{
                          ...(column.isGMP && !isDrag && getColorLine(MilestoneKeys.gmp)),
                          color: column.isGMP && !isDrag ? 'var(--background-main)' : '',
                          fontWeight: 'var(--font-weight)',
                        }}
                      >
                        {permissionStep === 1 || permissionStep === 2
                          ? moment(column.label).format('DD-MMM-YY')
                          : moment(column.label).format('MMM - YYYY')}
                      </span>
                    </div>
                  ))}
                </div>
                {permissions.map((row, rowIndex) => {
                  if (row.isMainBlock) {
                    return <></>;
                  }
                  let approvalLength = 1;
                  let submissionLength = 1;
                  if (permissionStep === 1 || permissionStep === 2) {
                    approvalLength = Math.ceil((moment(row.approval_date).diff(permissionColumns[0].label, 'weeks') + 1) / permissionStep);
                    submissionLength = Math.ceil(
                      (moment(row.submission_date).diff(permissionColumns[0].label, 'weeks') + 1) / permissionStep,
                    );
                  }
                  if (permissionStep === 3) {
                    approvalLength = Math.ceil(moment(row.approval_date).diff(permissionColumns[0].label, 'month') + 1);
                    submissionLength = Math.ceil(moment(row.submission_date).diff(permissionColumns[0].label, 'month') + 1);
                  }
                  let permit = approvalLength - submissionLength;
                  return (
                    <div
                      key={`permissionRow-${rowIndex}`}
                      className={'b-phasePlan__row'}
                      style={{ border: permissions.length === rowIndex + 1 ? 'none' : null }}
                    >
                      {approvalLength ? (
                        <CustomTooltip title={row.title} placement="top" followCursor>
                          <div
                            className={'b-phasePlan__cell'}
                            style={{
                              width: `${permit * 28 + permit}px`,
                              minWidth: `${permit * 28 + permit}px`,
                              ...getPermitColorLine(MilestoneKeys.permit, row),
                              marginLeft: `${submissionLength * 28 + submissionLength}px`,
                            }}
                          ></div>
                        </CustomTooltip>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div className={`b-phasePlan__separation`} style={{ width: bodyWidth, height: isDrag ? '41px' : '33px' }}>
                <CustomSlider
                  steps={{ step1: 'Week', step2: '2 weeks', step3: 'Month' }}
                  onChange={this.handleChangeRange('scheduleStep')}
                  step={steps.scheduleStep}
                />
                <div className={'b-phasePlan__actionsBtn'}>
                  <span
                    className={`${isShowLegend ? 'b-phasePlan__legendBtnHide' : 'b-phasePlan__legendBtn'}  save`}
                    onClick={this.handleLegend}
                  >
                    {isShowLegend ? 'Hide legend' : 'Show legend'}
                  </span>
                </div>
              </div>
            </div>
            <Popover
              id={`legendPopover`}
              open={!!isShowLegend}
              anchorEl={anchorEl}
              onClose={this.handleLegend}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  borderRadius: '0px',
                  border: '1px solid #7f89a4',
                  transform: 'translateY(-15px)',
                },
              }}
            >
              <div className={'b-phasePlan__legendPopover'}>
                <div className={'header'}>
                  <span>Level of Development Milestones</span>
                </div>
                <div className={'close'} onClick={this.handleLegend}>
                  <SVG src={require('../../../../../assets/icons/remove_icon.svg')} />
                </div>
                <div className={'legendContent'}>
                  {Object.keys(milestoneLevels).map(key => {
                    if (!milestoneLevels[key].isShowLegend) {
                      return <></>;
                    }
                    return (
                      <div className={'item'} key={milestoneLevels[key].label}>
                        <div className={'left'} style={{ ...getColorLine(key) }}>
                          <span>{milestoneLevels[key].label}</span>
                        </div>
                        <div
                          className={'right'}
                          style={{ ...getColorLine(key) }}
                          onMouseEnter={() => this.legendItemHover(key)}
                          onMouseLeave={() => this.legendItemLeave(key)}
                        >
                          <span>{milestoneLevels[key].value}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Popover>
            {selectedRequestId ? (
              <Dialog
                open={selectedRequestId}
                onClose={this.handleCloseRequest}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                PaperProps={{
                  style: {
                    overflowY: 'inherit',
                    backgroundColor: 'transparent',
                    height: 'calc(100% - 96px)',
                    top: '82px',
                    boxShadow: 'none',
                  },
                }}
              >
                {/*<RequestCard selectRequestId={selectedRequestId} closeRequest={() => this.handleCloseRequest()} /> Todo*/}
              </Dialog>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ phasePlan, projectReducer, userReducer }) => {
  return {
    phasePlanData: phasePlan.phasePlan,
    milestones: projectReducer.projectInfo.milestones?.[userReducer.active_project_id],
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      savePhasePlan,
    },
    dispatch,
  );

export default withRouterHelper(connect(mapStateToProps, mapDispatchToProps)(SchedulePlan));
