import React, { useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TextInputNew from '../../../../controls/TextInputNew/TextInputNew';
import DateField from '../../../../controls/DateField/DateField';
import { CodeSelector } from '../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../../models/global';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import PcdRenderSelect from '../../controls/PcdRenderSelect/PcdRenderSelect';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { FileDataModel, SelectedRequestCompanyModel, TagFormModel, TitleModel, UfMfValueModel } from '../../../../../models';
import Popover from '@material-ui/core/Popover';
import CustomCalendar from '../../../../controls/CustomCalendar/CustomCalendar';
import moment from 'moment/moment';
import Divider from '../../../../controls/Divider/Divider';
import cn from 'classnames';
import PartySelector from '../../../../controls/PartySelector/PartySelector';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import HashtagsEllipsis from '../../../Hashtags/HashtagsEllipsis';
import { convertToLocalTimezone } from '../../../../../helpers/commonHelpers';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import AllDates from '../../controls/AllDates/AllDates';
import { updateMilestoneDueDate } from '../../../../../helpers/redmineMilestone/redmineMilestoneHelpers';
import { useAppSelector } from '../../../../../store/configure/configureStore';

import './TopCardPartPcdStyles.scss';

interface TopCardPartPcdProps {
  isDragRequestCard: boolean;
  isPCDFieldsDisabled: boolean;
  selectedRequest: PCDCard;
  isAccess: boolean;
  isCreateRevision: boolean;
  documentSets: TitleModel[];
  work_week: number;
  criticalPathDate: string | Date;
  scheduledDate: string | Date;
  setScheduledDate: (value: string | Date) => void;
  selectedDocumentSet: TitleModel | number;
  setSelectedDocumentSet: (value: TitleModel | number) => void;
  currentUfcCode: UfMfValueModel[];
  setCurrentUfcCode: (value: UfMfValueModel[]) => void;
  currentMfCode: UfMfValueModel[];
  setCurrentMfCode: (value: UfMfValueModel[]) => void;
  pcdTitle: string;
  setPcdTitle: (value: string) => void;
  selectedResponsibleParty: SelectedRequestCompanyModel | null;
  setSelectedResponsibleParty: (value: SelectedRequestCompanyModel | null) => void;
  errors: any;
  isHiddenFields: boolean;
  handleContentSwitcher: () => void;
  hashtags: TagFormModel[];
  handleChangeHashtags: (hashtags: TagFormModel[]) => void;
  handleScheduledDate: (reason: string, scheduledDate: string | Date) => void;
  requestSave: (whiteboard: number, files?: FileDataModel[]) => void;
  isRevisionUpdateDraftButtonDisabledTopCard: boolean;
  canUpdateDescription: boolean;
}

const TopCardPartPcd: React.FC<TopCardPartPcdProps> = ({
  isDragRequestCard,
  isPCDFieldsDisabled,
  selectedRequest,
  isAccess,
  isCreateRevision,
  documentSets,
  work_week,
  criticalPathDate,
  scheduledDate,
  setScheduledDate,
  selectedDocumentSet,
  setSelectedDocumentSet,
  currentUfcCode,
  setCurrentUfcCode,
  currentMfCode,
  setCurrentMfCode,
  pcdTitle,
  setPcdTitle,
  selectedResponsibleParty,
  setSelectedResponsibleParty,
  errors,
  isHiddenFields,
  handleContentSwitcher,
  hashtags,
  handleChangeHashtags,
  handleScheduledDate,
  requestSave,
  isRevisionUpdateDraftButtonDisabledTopCard,
  canUpdateDescription,
}) => {
  const themeClass = useGetThemeClass('b-cardPCD-topCardPart');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');
  const dialogClass = useGetThemeClass('dialogPopUp');

  const redmineData = useAppSelector(state => state.redmineReducer);
  const user_data = useAppSelector(state => state.userReducer);

  const dueDateRef = useRef(null);

  const { is1440Resolution, isMobile } = GetResolution();

  const [focusElement, setFocusElement] = useState<string>('');
  const [openedCalendar, setOpenedCalendar] = useState<boolean>(false);
  const [isChangeScheduledDate, setIsChangeScheduledDate] = useState<boolean>(false);
  const [changeScheduledDate, setChangeScheduledDate] = useState<string>('');

  const [changeMsg, setChangeMsg] = useState<string>('');
  const [changeMsgError, setChangeMsgError] = useState<boolean>(false);

  // Due date tooltip
  const [isDueDateTooltipOpen, setIsDueDateTooltipOpen] = useState<boolean>(false);

  const criticalDateValue = criticalPathDate || (selectedRequest && selectedRequest.critical_date);
  const scheduleDateValue = scheduledDate || (selectedRequest && selectedRequest.scheduled_date);
  const isScheduleDateDisabled = (selectedRequest && !selectedRequest?.critical_date) || !isAccess;
  const documentSetsBuilding = documentSets?.filter(
    item => item?.building_ids?.findIndex(item => item == selectedRequest?.building?.id) > -1,
  );
  const isSidePeekView = isDragRequestCard || is1440Resolution;

  const handleChangeValue = (value: string) => {
    setPcdTitle(value);
  };

  const handleSelectAutocomplete = (item: DropdownItemModel) => {
    setSelectedDocumentSet(item);
  };

  const selectAtDateHandler = (selectedAtDate: string) => {
    if (scheduleDateValue) {
      setIsChangeScheduledDate(true);
      setChangeScheduledDate(selectedAtDate);
    } else {
      setScheduledDate(moment(selectedAtDate).format('YYYY-MM-DD'));
      handleCloseCalendar();
    }
  };

  const handleOpenCalendar = () => {
    setOpenedCalendar(true);
  };

  const handleCloseCalendar = () => {
    setOpenedCalendar(false);
  };

  const selectUfCodeHandler = selectedUfCode => {
    setCurrentUfcCode(selectedUfCode);
  };

  const selectMfCodeHandler = (selectedMfCode: UfMfValueModel[]) => {
    setCurrentMfCode(selectedMfCode);
  };

  const resetPcdTitle = () => {
    setPcdTitle(selectedRequest.default_title || '');
  };

  const handleCloseChangeScheduledDate = () => {
    setIsChangeScheduledDate(false);
    setChangeMsg('');
  };

  const changeHandlerChangeMsg = (value: string) => {
    setChangeMsg(value);
    setChangeMsgError(false);
  };

  const handleChangeScheduledDate = () => {
    setScheduledDate(moment(changeScheduledDate).format('YYYY-MM-DD'));
    handleCloseCalendar();
    handleCloseChangeScheduledDate();
    setChangeMsg('');
    handleScheduledDate(changeMsg, moment(changeScheduledDate).format('YYYY-MM-DD'));
    if (redmineData.hasAssignedMilestone && !!user_data.userInfo?.redmine_key) {
      updateMilestoneDueDate(
        redmineData.assignedMilestone.id,
        user_data.userInfo.redmine_key,
        moment(changeScheduledDate).format('YYYY-MM-DD'),
      );
    }
  };

  const handleUpdateTopCard = () => {
    requestSave(selectedRequest?.is_whiteboard || 0, null, null, true);
  };

  return (
    <>
      <div
        className={cn(`${themeClass}_mainContainer`, {
          ['-sidePeekView']: isSidePeekView,
          ['-isHiddenFields']: isHiddenFields,
        })}
      >
        <div className={`${themeClass}_subject`}>
          <div className={`${themeClass}_subject_title`}>Deliverable title</div>
          <div className={`${themeClass}_subject_body`}>
            <CustomTooltip
              enterDelay={500}
              leaveDelay={0}
              title={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Title of the deliverable card'}
              placement={'top-start'}
            >
              <TextInputNew
                type={'on-bgd'}
                placeholder={'Input subject'}
                onChange={handleChangeValue}
                value={pcdTitle || ''}
                disabled={isPCDFieldsDisabled}
                isFocus={focusElement === 'Input subject'}
                customIcon={<TokenIcon iconName={'close'} size={isMobile ? 20 : 12} />}
                customIconClass={`${themeClass}_closeIcon`}
                customFieldInputClassName={`${themeClass}_subject_body_inputField`}
                onClickIcon={() => resetPcdTitle()}
                onFocus={() => setFocusElement('Input subject')}
                onBlur={() => setFocusElement('')}
                isMobile={isMobile}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className={`${themeClass}_releaseDate`}>
          <div
            className={cn(`${themeClass}_releaseDate_title`, {
              ['-sidePeekView']: isSidePeekView,
            })}
          >
            Scheduled date
          </div>
          <div className={`${themeClass}_releaseDate_body`}>
            {isMobile ? (
              <>
                <Divider customClassname={`${themeClass}_releaseDate_body_horizontalLine`} direction={'vertical'} type={'srf-5'} />
                <DateField
                  selectedDate={scheduleDateValue ? moment(scheduleDateValue).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                  isSentRequestToChange={false}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={true}
                  isBefore={false}
                  error={errors.scheduled_date}
                  valueIsDate={true}
                  isDisableBorder
                  isHideCalendar
                />
              </>
            ) : null}
            {!isMobile ? (
              <>
                <div className={`${themeClass}_releaseDate_body_scheduleDate`}>
                  <CustomTooltip
                    enterDelay={500}
                    leaveDelay={0}
                    title={
                      localStorage.getItem('show_tooltips') === 'no'
                        ? ''
                        : errors.scheduled_date
                        ? 'Please specify date'
                        : 'Specify the deadline for completing the deliverable.'
                    }
                    placement={'top-start'}
                  >
                    <DateField
                      selectedDate={scheduleDateValue ? moment(scheduleDateValue).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                      isSentRequestToChange={false}
                      isOpenCalendar={openedCalendar}
                      isDiffDueScheduledDate={false}
                      info={false}
                      disabled={isScheduleDateDisabled || isPCDFieldsDisabled}
                      isBefore={false}
                      error={errors.scheduled_date}
                      valueIsDate={true}
                      dateFieldRef={dueDateRef}
                      clickHandler={() => handleOpenCalendar()}
                    />
                  </CustomTooltip>
                  <DialogPopUp
                    isCard
                    open={isChangeScheduledDate}
                    onClose={handleCloseChangeScheduledDate}
                    title={'Change deliverable Scheduled date?'}
                    hideBackdrop={true}
                    maxWidth={'xs'}
                    renderModalContent={() => (
                      <div className={`${themeClass}_${dialogClass}_content`}>
                        <div className={`${dialogClass}_content_modalText`}>
                          You are trying to reschedule the deliverable from&nbsp;
                          {convertToLocalTimezone(moment(scheduleDateValue)).format('MM/DD/YYYY')} to&nbsp;
                          {moment(changeScheduledDate).format('MM/DD/YYYY')}.
                        </div>
                        <div className={`${dialogClass}_content_modalText`}>Please specify the reason for your decision:</div>
                        <div className={'textField'} style={{ lineHeight: 0 }}>
                          <TextInputNew
                            inputType="text"
                            type={'on-srf'}
                            value={changeMsg || ''}
                            onChange={changeHandlerChangeMsg}
                            error={changeMsgError && 'The reason field is required.'}
                            autoComplete={'off'}
                          />
                        </div>
                      </div>
                    )}
                    secondaryText={'Cancel'}
                    handleOnSecondary={handleCloseChangeScheduledDate}
                    primaryText={'Yes, change'}
                    handleOnPrimary={handleChangeScheduledDate}
                    primaryTextDisabled={!changeMsg}
                  />
                </div>
                <AllDates
                  criticalDateValue={criticalDateValue}
                  errors={errors}
                  scheduleDateValue={scheduleDateValue}
                  selectedRequest={selectedRequest}
                />
              </>
            ) : null}
            {isMobile ? (
              <CustomTooltip
                open={isDueDateTooltipOpen}
                onClose={() => setIsDueDateTooltipOpen(false)}
                customBasisClass={`${themeClass}_releaseDate_body_scheduleDateInfoTooltip`}
                title={`Scheduled ${scheduleDateValue ? moment(scheduleDateValue).format('MM/DD/YYYY') : 'mm/dd/yyyy'}`}
              >
                <TokenIcon
                  clickHandler={() => setIsDueDateTooltipOpen(true)}
                  customClass={`${themeClass}_releaseDate_body_scheduleDateInfoIcon`}
                  iconName={'information'}
                  size={20}
                />
              </CustomTooltip>
            ) : null}
          </div>
        </div>
        {isMobile ? (
          <div className={`${themeClass}_contentSwitcher`}>
            <CustomButton
              icon={<TokenIcon iconName={isHiddenFields ? 'plus' : 'dash'} size={20} />}
              type={'text-plain'}
              size={'md'}
              clickHandler={handleContentSwitcher}
              title={isHiddenFields ? 'Show More Fields' : 'Show Less Fields'}
              iconClass={`${themeClass}_contentSwitcher_icon`}
              isMobile={isMobile}
            />
          </div>
        ) : null}
        <div
          className={cn(`${themeClass}_ufMf`, {
            ['-sidePeekView']: isSidePeekView,
          })}
        >
          <div className={`${themeClass}_ufMf_ufMfBlock`}>
            <div className={`${themeClass}_ufMf_ufMfBlock_ufMfTitle`}>UF</div>
            <div className={`${themeClass}_ufMf_ufMfBlock_ufMfBody`}>
              <CodeSelector
                isMulti
                codeType={'uf'}
                isValuesRemovable
                isValuesEditable
                selected={currentUfcCode || []}
                selectHandler={selectUfCodeHandler}
                basisPlaceholder={`Select UniFormat`}
                menuItemSize={'md'}
                basisMaxItems={2}
                handleFor={'content-plan'}
                selectedBuildingIds={selectedRequest?.building_id ? [selectedRequest?.building_id] : null}
                isMobile={isMobile}
                customBasisFieldClass={`${themeClass}_ufMf_ufMfBlock_ufMfBody_field`}
                disabled={isPCDFieldsDisabled}
              />
            </div>
          </div>
          <div className={`${themeClass}_ufMf_ufMfBlock`}>
            <div className={`${themeClass}_ufMf_ufMfBlock_ufMfTitle`}>MF</div>
            <div className={`${themeClass}_ufMf_ufMfBlock_ufMfBody`}>
              <CodeSelector
                isMulti
                codeType={'mf'}
                isValuesRemovable
                isValuesEditable
                selected={currentMfCode || []}
                selectHandler={selectMfCodeHandler}
                basisPlaceholder={`Select MasterFormat`}
                menuItemSize={'md'}
                basisMaxItems={2}
                handleFor={'content-plan'}
                selectedBuildingIds={selectedRequest?.building_id ? [selectedRequest?.building_id] : null}
                isMobile={isMobile}
                customBasisFieldClass={`${themeClass}_ufMf_ufMfBlock_ufMfBody_field`}
                disabled={isPCDFieldsDisabled}
              />
            </div>
          </div>
        </div>
        {!isHiddenFields ? (
          <>
            <div
              className={cn(`${themeClass}_ddSelect`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_ddSelect_title`}>Design documents set</div>
              <div className={`${themeClass}_ddSelect_body`}>
                <PcdRenderSelect<DropdownItemModel, null, false>
                  type={'documents'}
                  isMulti={false}
                  isHeader
                  isFooter
                  menuItems={documentSetsBuilding}
                  selectHandler={item => handleSelectAutocomplete(item)}
                  selected={
                    (isCreateRevision
                      ? selectedDocumentSet
                      : selectedDocumentSet || (selectedRequest && selectedRequest.document_set_id)) || 0
                  }
                  isLoading={false}
                  basisPlaceholder={'Select design documents set'}
                  menuItemSize={'md'}
                  isMobile={isMobile}
                  customBasisFieldClass={`${themeClass}_ddSelect_body_field`}
                  disabled={isPCDFieldsDisabled}
                />
              </div>
            </div>
            <div
              className={cn(`${themeClass}_tags`, {
                ['-isMobile']: isMobile,
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_tags_title`}>Tags</div>
              <div className={`${themeClass}_tags_body`}>
                <CustomTooltip
                  enterDelay={500}
                  leaveDelay={0}
                  title={localStorage.getItem('show_tooltips') === 'no' ? '' : hashtags?.length > 0 ? '' : 'Tags for labeling NFs'}
                  placement={'top'}
                >
                  <div style={{ width: '100%' }}>
                    <HashtagsEllipsis
                      isAccess={isAccess}
                      onChangeHashtags={handleChangeHashtags}
                      tags={hashtags}
                      isHashTagChanging={false}
                      isSandBox={false}
                      disabled={isPCDFieldsDisabled}
                    />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div
              className={cn(`${themeClass}_requestParty`, {
                ['-sidePeekView']: isSidePeekView,
              })}
            >
              <div className={`${themeClass}_requestParty_title`}>Responsible party</div>
              <PartySelector
                type={''}
                selected={selectedResponsibleParty}
                handleSelect={setSelectedResponsibleParty}
                customPartyContainerClassName={cn(`${themeClass}_requestParty_body`, {
                  ['-sidePeekView']: isSidePeekView,
                })}
                customSelectBasisFieldClass={cn(`${themeClass}_requestParty_body_field`, {
                  ['-sidePeekView']: isSidePeekView,
                })}
                toolTipClassName={`${themeClass}_requestParty_body_toolTip`}
                isDisableSelect={!isAccess || isPCDFieldsDisabled}
                errors={errors.responsible_party_id ? ['company'] : []}
                isMobile={isMobile}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className={`${themeClass}_subContainer`}>
        <CustomTooltip
          enterDelay={500}
          leaveDelay={0}
          title={canUpdateDescription ? 'Click Update at the bottom of the card to save changes' : ''}
          placement={'top'}
        >
          <CustomButton
            type={'tertiary'}
            size={'sm'}
            clickHandler={() => handleUpdateTopCard()}
            title={'Update'}
            disabled={isRevisionUpdateDraftButtonDisabledTopCard}
            isMobile={isMobile}
          />
        </CustomTooltip>
      </div>
      <Popover
        disableEnforceFocus
        open={openedCalendar}
        onClose={handleCloseCalendar}
        anchorEl={dueDateRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div>
          {openedCalendar && (
            <>
              <CustomCalendar
                handleChange={date => selectAtDateHandler(date, false)}
                value={scheduleDateValue}
                name={'formData'}
                work_week={work_week}
              />
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default TopCardPartPcd;
