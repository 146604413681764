import { actionCreater } from '../configure/actionCreater';
//import { createRequestTypes } from '../../service/utils';

export const REQUEST_LOADING = 'REQUEST_LOADING';
export const CLEAR_REQUEST_INFO_DATA = 'CLEAR_REQUEST_INFO_DATA';
export const CLEAR_REQUEST_ID = 'CLEAR_REQUEST_ID';
export const SINGLE_REQUEST_LOADING = 'SINGLE_REQUEST_LOADING';
export const CLEAR_LAST_REQUESTS_LIST = 'CLEAR_LAST_REQUESTS_LIST';
export const DELETE_ID_FROM_LAST_REQUESTS_LIST = 'DELETE_ID_FROM_LAST_REQUESTS_LIST';
export const REMOVE_LAST_REQUESTS_ITEM = 'REMOVE_LAST_REQUESTS_ITEM';
export const ALL_REQUEST_LOADING_FAIL = 'ALL_REQUEST_LOADING_FAIL';
export const ALL_REQUEST_FOR_PROJECT_LOADING_FAIL = 'ALL_REQUEST_FOR_PROJECT_LOADING_FAIL';
export const ALL_REQUEST_LOADING_SUCCES = 'ALL_REQUEST_LOADING_SUCCES';
export const ALL_REQUEST_FOR_PROJECT_LOADING_SUCCES = 'ALL_REQUEST_FOR_PROJECT_LOADING_SUCCES';
export const SINGLE_REQUEST_FOR_PROJECT_LOADING_SUCCES = 'SINGLE_REQUEST_FOR_PROJECT_LOADING_SUCCES';
export const SINGLE_REQUEST_FOR_PROJECT_LOADING_FAIL = 'SINGLE_REQUEST_FOR_PROJECT_LOADING_FAIL';
export const REQUEST_SAVE = 'REQUEST_SAVE';
export const REQUEST_SAVE_SUCCES = 'REQUEST_SAVE_SUCCES';
export const REQUEST_SAVE_FAIL = 'REQUEST_SAVE_FAIL';
export const COMMITMENT_SAVE = 'COMMITMENT_SAVE';
export const COMMITMENT_SAVE_SUCCES = 'COMMITMENT_SAVE_SUCCES';
export const COMMITMENT_SAVE_FAIL = 'COMMITMENT_SAVE_FAIL';
export const REQUEST_SAVE_SUCCES_NEEDLIST = 'REQUEST_SAVE_SUCCES_NEEDLIST';
export const REQUEST_SAVE_SUCCES_TRACKING = 'REQUEST_SAVE_SUCCES_TRACKING';
export const OPEN_EXISTED_REQUEST = 'OPEN_EXISTED_REQUEST';
export const REQUEST_UPDATE_PRIVATE = 'REQUEST_UPDATE_PRIVATE';
export const REQUEST_UPDATE_PRIVATE_SUCCESS = 'REQUEST_UPDATE_PRIVATE_SUCCESS';
export const SET_REQUEST_TO_OPEN = 'SET_REQUEST_TO_OPEN';

export const requestLoading = actionCreater(REQUEST_LOADING);
export const clearRequestInfoData = actionCreater(CLEAR_REQUEST_INFO_DATA);
export const clearRequestID = actionCreater(CLEAR_REQUEST_ID);
export const singleRequestLoading = actionCreater(SINGLE_REQUEST_LOADING);
export const openExistedRequest = actionCreater(OPEN_EXISTED_REQUEST);

export const setRequestToOpen = actionCreater(SET_REQUEST_TO_OPEN);

export const clearLastRequestsList = actionCreater(CLEAR_LAST_REQUESTS_LIST);
export const deleteIdFromLastRequestsList = actionCreater(DELETE_ID_FROM_LAST_REQUESTS_LIST);
export const removeLastRequestsItem = actionCreater(REMOVE_LAST_REQUESTS_ITEM);
export const allRequestLoadingFailed = actionCreater(ALL_REQUEST_LOADING_FAIL);
export const allRequestForProjectLoadingFailed = actionCreater(ALL_REQUEST_FOR_PROJECT_LOADING_FAIL);
export const allRequestForProjectLoadingSucces = actionCreater(ALL_REQUEST_FOR_PROJECT_LOADING_SUCCES);
export const singleRequestForProjectLoadingSucces = actionCreater(SINGLE_REQUEST_FOR_PROJECT_LOADING_SUCCES);
export const singleRequestForProjectLoadingFailed = actionCreater(SINGLE_REQUEST_FOR_PROJECT_LOADING_FAIL);
export const allRequestLoadingSucces = actionCreater(ALL_REQUEST_LOADING_SUCCES);

//Save request
export const requestSave = actionCreater(REQUEST_SAVE);
export const requestSaveSucces = actionCreater(REQUEST_SAVE_SUCCES);
export const requestSaveFail = actionCreater(REQUEST_SAVE_FAIL);
export const requestUpdatePrivate = actionCreater(REQUEST_UPDATE_PRIVATE);
export const requestUpdatePrivateSuccess = actionCreater(REQUEST_UPDATE_PRIVATE_SUCCESS);
export const requestUpdateNeedList = actionCreater(REQUEST_SAVE_SUCCES_NEEDLIST);

//Delete Request
export const REQUEST_DELETE = 'REQUEST_DELETE';
export const requestDelete = actionCreater(REQUEST_DELETE);

export const REQUEST_DELETE_SUCCES = 'REQUEST_DELETE_SUCCES';
//TODO ? this requestDeleteSucces is part chain logic
export const requestDeleteSucces = actionCreater(REQUEST_DELETE_SUCCES);

export const REQUEST_DELETE_FAILE = 'REQUEST_DELETE_FAILE';
export const requestDeleteFaile = actionCreater(REQUEST_DELETE_FAILE);

//Save commitment
export const commitmentSave = actionCreater(COMMITMENT_SAVE);
export const commitmentSaveSucces = actionCreater(COMMITMENT_SAVE_SUCCES);
export const commitmentSaveFail = actionCreater(COMMITMENT_SAVE_FAIL);

//NeedList
export const NEED_LIST = 'NEED_LIST';
export const needList = actionCreater(NEED_LIST);

export const NEED_LIST_CANCEL = 'NEED_LIST_CANCEL';
export const needListCancel = actionCreater(NEED_LIST_CANCEL);

export const SAVE_QUERY_PARAMS = 'SAVE_QUERY_PARAMS';
export const saveQueryParams = actionCreater(SAVE_QUERY_PARAMS);

export const CLEAR_NEEDLIST_DATA = 'CLEAR_NEEDLIST_DATA';
export const clearNeedListData = actionCreater(CLEAR_NEEDLIST_DATA);

export const NEED_LIST_SUCCESS = 'NEED_LIST_SUCCESS';
export const needListSuccess = actionCreater(NEED_LIST_SUCCESS);

export const GET_TAGS = 'GET_TAGS';
export const getTags = actionCreater(GET_TAGS);

export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const getTagsSuccess = actionCreater(GET_TAGS_SUCCESS);

export const GET_NEED_LIST_COLUMN_ORDER = 'NEED_LIST_COLUMN_ORDER';
export const getNeedListColumnOrder = actionCreater(GET_NEED_LIST_COLUMN_ORDER);

export const SET_NEED_LIST_COLUMN_ORDER = 'SET_NEED_LIST_COLUMN_ORDER';
export const setNeedListColumnOrder = actionCreater(SET_NEED_LIST_COLUMN_ORDER);

export const SET_NEED_LIST_COLUMN_ORDER_LOADING = 'SET_NEED_LIST_COLUMN_ORDER_LOADING';
export const setNeedListColumnOrderLoading = actionCreater(SET_NEED_LIST_COLUMN_ORDER_LOADING);

export const SET_NEED_LIST_COLUMN_ORDER_LOADING_SUCCESS = 'SET_NEED_LIST_COLUMN_ORDER_LOADING_SUCCESS';
export const setNeedListColumnOrderLoadingSuccess = actionCreater(SET_NEED_LIST_COLUMN_ORDER_LOADING_SUCCESS);

export const NEED_LIST_COLUMN_ORDER_SUCCESS = 'NEED_LIST_COLUMN_ORDER_SUCCESS';
export const needListColumnOrderSuccess = actionCreater(NEED_LIST_COLUMN_ORDER_SUCCESS);

export const NEED_LIST_COLUMN_ORDER_FAIL = 'NEED_LIST_COLUMN_ORDER_FAIL';
export const needListColumnOrderFail = actionCreater(NEED_LIST_COLUMN_ORDER_FAIL);

//bulk edit
export const GET_BULK_AVAILABLE_FIELDS = 'GET_BULK_AVAILABLE_FIELDS';
export const getAvailableBulkEditFields = actionCreater(GET_BULK_AVAILABLE_FIELDS);

export const GET_BULK_AVAILABLE_FIELDS_SUCCESS = 'GET_BULK_AVAILABLE_FIELDS_SUCCESS';
export const getAvailableBulkEditFieldsSuccess = actionCreater(GET_BULK_AVAILABLE_FIELDS_SUCCESS);

export const GET_BULK_AVAILABLE_FIELDS_FAIL = 'GET_BULK_AVAILABLE_FIELDS_FAIL';
export const getAvailableBulkEditFieldsFail = actionCreater(GET_BULK_AVAILABLE_FIELDS_FAIL);

export const BULK_EDIT = 'BULK_EDIT';
export const bulkEdit = actionCreater(BULK_EDIT);

export const BULK_EDIT_SUCCESS = 'BULK_EDIT_SUCCESS';
export const bulkEditSuccess = actionCreater(BULK_EDIT_SUCCESS);

export const BULK_EDIT_FAIL = 'BULK_EDIT_FAIL';
export const bulkEditFail = actionCreater(BULK_EDIT_FAIL);

// New Sand Box
export const GET_SAND_BOX_DATA = 'GET_SAND_BOX_DATA';
export const getSandBoxData = actionCreater(GET_SAND_BOX_DATA);

export const GET_SAND_BOX_DATA_SUCCESS = 'GET_SAND_BOX_DATA_SUCCESS';
export const getSandBoxDataSuccess = actionCreater(GET_SAND_BOX_DATA_SUCCESS);

export const GET_SAND_BOX_DATA_CANCEL = 'GET_SAND_BOX_DATA_CANCEL';
export const getSandBoxDataCancel = actionCreater(GET_SAND_BOX_DATA_CANCEL);

export const NEED_LIST_FAILE = 'NEED_LIST_FAILE';
export const needListFaile = actionCreater(NEED_LIST_FAILE);

// Set seen
export const REQUEST_SET_SEEN = 'REQUEST_SET_SEEN';
export const requestSetSeen = actionCreater(REQUEST_SET_SEEN);

export const REQUEST_SET_SEEN_SUCCESS = 'REQUEST_SET_SEEN_SUCCESS';
export const requestSetSeenSuccess = actionCreater(REQUEST_SET_SEEN_SUCCESS);

export const REQUEST_SET_SEEN_FAILURE = 'REQUEST_SET_SEEN_FAILURE';
export const requestSetSeenFailure = actionCreater(REQUEST_SET_SEEN_FAILURE);

//WhiteBoard
export const WHITE_BOARD = 'WHITE_BOARD';
export const whiteBoard = actionCreater(WHITE_BOARD);

export const WHITE_BOARD_CANCEL = 'WHITE_BOARD_CANCEL';
export const whiteBoardCancel = actionCreater(WHITE_BOARD_CANCEL);

export const WHITE_BOARD_SUCCESS = 'WHITE_BOARD_SUCCESS';
export const whiteBoardSuccess = actionCreater(WHITE_BOARD_SUCCESS);

export const WHITE_BOARD_FAILE = 'WHITE_BOARD_FAILE';
export const whiteBoardFaile = actionCreater(WHITE_BOARD_FAILE);

export const WHITE_BOARD_FILTERS = 'WHITE_BOARD_FILTERS';
export const whiteBoardFilters = actionCreater(WHITE_BOARD_FILTERS);

export const RESET_WHITEBOARD_FILTERTS = 'RESET_WHITEBOARD_FILTERTS';
export const resetWhiteBoardFilters = actionCreater(RESET_WHITEBOARD_FILTERTS);

export const WHITE_BOARD_FILTERS_SUCCESS = 'WHITE_BOARD_FILTERS_SUCCESS';
export const whiteBoardFiltersSuccess = actionCreater(WHITE_BOARD_FILTERS_SUCCESS);

export const WHITE_BOARD_FILTERS_FAIL = 'WHITE_BOARD_FILTERS_FAIL';
export const whiteBoardFiltersFail = actionCreater(WHITE_BOARD_FILTERS_FAIL);

//DownloadFile

export const EXPORT_DATA = 'EXPORT_DATA';
export const ExportData = actionCreater(EXPORT_DATA);

export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const ExportDataSuccess = actionCreater(EXPORT_DATA_SUCCESS);

export const EXPORT_DATA_FAILE = 'EXPORT_DATA_FAILE';
export const ExportDataFaile = actionCreater(EXPORT_DATA_FAILE);

//getWatchers

export const GET_WATCHERS = 'GET_WATCHERS';
export const getWatchers = actionCreater(GET_WATCHERS);

export const GET_WATCHERS_SUCCESS = 'GET_WATCHERS_SUCCESS';
export const getWatchersSuccess = actionCreater(GET_WATCHERS_SUCCESS);

export const GET_WATCHERS_FAIL = 'GET_WATCHERS_FAIL';
export const getWatchersFail = actionCreater(GET_WATCHERS_FAIL);

//get Watchers groups
export const GET_WATCHERS_GROUPS = 'GET_WATCHERS_GROUPS';
export const getWatchersGroups = actionCreater(GET_WATCHERS_GROUPS);

export const GET_WATCHERS_GROUPS_SUCCESS = 'GET_WATCHERS_GROUPS_SUCCESS';
export const getWatchersGroupsSuccess = actionCreater(GET_WATCHERS_GROUPS_SUCCESS);

export const GET_WATCHERS_GROUPS_FAIL = 'GET_WATCHERS_GROUPS_FAIL';
export const getWatchersGroupsFail = actionCreater(GET_WATCHERS_GROUPS_FAIL);

//create Watchers groups
export const CREATE_WATCHERS_GROUPS = 'CREATE_WATCHERS_GROUPS';
export const createWatchersGroups = actionCreater(CREATE_WATCHERS_GROUPS);

export const CREATE_WATCHERS_GROUPS_SUCCESS = 'CREATE_WATCHERS_GROUPS_SUCCESS';
export const createWatchersGroupsSuccess = actionCreater(CREATE_WATCHERS_GROUPS_SUCCESS);

export const CREATE_WATCHERS_GROUPS_FAIL = 'CREATE_WATCHERS_GROUPS_FAIL';
export const createWatchersGroupsFail = actionCreater(CREATE_WATCHERS_GROUPS_FAIL);

//delete Watchers groups
export const DELETE_WATCHERS_GROUPS = 'DELETE_WATCHERS_GROUPS';
export const deleteWatchersGroups = actionCreater(DELETE_WATCHERS_GROUPS);

export const DELETE_WATCHERS_GROUPS_SUCCESS = 'DELETE_WATCHERS_GROUPS_SUCCESS';
export const deleteWatchersGroupsSuccess = actionCreater(DELETE_WATCHERS_GROUPS_SUCCESS);

export const DELETE_WATCHERS_GROUPS_FAIL = 'DELETE_WATCHERS_GROUPS_FAIL';
export const deleteWatchersGroupsFail = actionCreater(DELETE_WATCHERS_GROUPS_FAIL);

//update Watchers groups
export const UPDATE_WATCHERS_GROUPS = 'UPDATE_WATCHERS_GROUPS';
export const updateWatchersGroups = actionCreater(UPDATE_WATCHERS_GROUPS);

export const UPDATE_WATCHERS_GROUPS_SUCCESS = 'UPDATE_WATCHERS_GROUPS_SUCCESS';
export const updateWatchersGroupsSuccess = actionCreater(UPDATE_WATCHERS_GROUPS_SUCCESS);

export const UPDATE_WATCHERS_GROUPS_FAIL = 'UPDATE_WATCHERS_GROUPS_FAIL';
export const updateWatchersGroupsFail = actionCreater(UPDATE_WATCHERS_GROUPS_FAIL);

//add to Watchers groups
export const ADD_TO_WATCHERS_GROUPS = 'ADD_TO_WATCHERS_GROUPS';
export const addToWatchersGroups = actionCreater(ADD_TO_WATCHERS_GROUPS);

export const ADD_TO_WATCHERS_GROUPS_SUCCESS = 'ADD_TO_WATCHERS_GROUPS_SUCCESS';
export const addToWatchersGroupsSuccess = actionCreater(ADD_TO_WATCHERS_GROUPS_SUCCESS);

export const ADD_TO_WATCHERS_GROUPS_FAIL = 'ADD_TO_WATCHERS_GROUPS_FAIL';
export const addToWatchersGroupsFail = actionCreater(ADD_TO_WATCHERS_GROUPS_FAIL);

//getWatchers

// export const GET_USER_WATCHERS = 'GET_USER_WATCHERS';
// export const getUserWatchers = actionCreater(GET_USER_WATCHERS);
//
// export const GET_USER_WATCHERS_SUCCESS = 'GET_USER_WATCHERS_SUCCESS';
// export const getUserWatchersSuccess = actionCreater(GET_USER_WATCHERS_SUCCESS);
//
// export const GET_USER_WATCHERS_FAIL = 'GET_USER_WATCHERS_FAIL';
// export const getUserWatchersFail = actionCreater(GET_USER_WATCHERS_FAIL);

//Support

export const SEND_BUG_REPORT = 'SEND_BUG_REPORT';
export const sendBugReport = actionCreater(SEND_BUG_REPORT);

export const SEND_BUG_REPORT_SUCCESS = 'SEND_BUG_REPORT_SUCCESS';
export const sendBugReportSuccess = actionCreater(SEND_BUG_REPORT_SUCCESS);

export const SEND_BUG_REPORT_FAIL = 'SEND_BUG_REPORT_FAIL';
export const sendBugReportFail = actionCreater(SEND_BUG_REPORT_FAIL);

export const GET_REQUEST_BY_NF = 'GET_REQUEST_BY_NF';
export const getRequestByNf = actionCreater(GET_REQUEST_BY_NF);
export const GET_REQUEST_BY_NF_SUCCESS = 'GET_REQUEST_BY_NF_SUCCESS';
export const getRequestByNfSuccess = actionCreater(GET_REQUEST_BY_NF_SUCCESS);
export const GET_REQUEST_BY_NF_FAIL = 'GET_REQUEST_BY_NF_FAIL';
export const getRequestByNfFail = actionCreater(GET_REQUEST_BY_NF_FAIL);

export const CHANGE_ACTIVE_PROJECT = 'CHANGE_ACTIVE_PROJECT';
export const changeActiveProject = actionCreater(CHANGE_ACTIVE_PROJECT);

//Change dueDate
export const CREATE_NEW_DUE_DATE = 'CREATE_NEW_DUE_DATE';
export const createNewDueDate = actionCreater(CREATE_NEW_DUE_DATE);

export const CREATE_NEW_DUE_DATE_SUCCESS = 'CREATE_NEW_DUE_DATE_SUCCESS';
export const createNewDueDateSuccess = actionCreater(CREATE_NEW_DUE_DATE_SUCCESS);

export const CREATE_NEW_DUE_DATE_FAIL = 'CREATE_NEW_DUE_DATE_FAIL';
export const createNewDueDateFail = actionCreater(CREATE_NEW_DUE_DATE_FAIL);

//Update status New Due Date
export const UPDATE_NEW_DUE_DATE = 'UPDATE_NEW_DUE_DATE';
export const updateNewDueDate = actionCreater(UPDATE_NEW_DUE_DATE);

export const UPDATE_NEW_DUE_DATE_SUCCESS = 'UPDATE_NEW_DUE_DATE_SUCCESS';
export const updateNewDueDateSuccess = actionCreater(UPDATE_NEW_DUE_DATE_SUCCESS);

export const UPDATE_NEW_DUE_DATE_FAIL = 'UPDATE_NEW_DUE_DATE_FAIL';
export const updateNewDueDateFail = actionCreater(UPDATE_NEW_DUE_DATE_FAIL);

//Change Response Company
export const CREATE_NEW_RESPONSE_COMPANY = 'CREATE_NEW_RESPONSE_COMPANY';
export const createNewResponseCompany = actionCreater(CREATE_NEW_RESPONSE_COMPANY);

export const CREATE_NEW_RESPONSE_COMPANY_SUCCESS = 'CREATE_NEW_RESPONSE_COMPANY_SUCCESS';
export const createNewResponseCompanySuccess = actionCreater(CREATE_NEW_RESPONSE_COMPANY_SUCCESS);

export const CREATE_NEW_RESPONSE_COMPANY_FAIL = 'CREATE_NEW_RESPONSE_COMPANY_FAIL';
export const createNewResponseCompanyFail = actionCreater(CREATE_NEW_RESPONSE_COMPANY_FAIL);

//Update status Response Company
export const UPDATE_NEW_RESPONSE_COMPANY = 'UPDATE_NEW_RESPONSE_COMPANY';
export const updateNewResponseCompany = actionCreater(UPDATE_NEW_RESPONSE_COMPANY);

export const UPDATE_NEW_RESPONSE_COMPANY_SUCCESS = 'UPDATE_NEW_RESPONSE_COMPANY_SUCCESS';
export const updateNewResponseCompanySuccess = actionCreater(UPDATE_NEW_RESPONSE_COMPANY_SUCCESS);

export const UPDATE_NEW_RESPONSE_COMPANY_FAIL = 'UPDATE_NEW_RESPONSE_COMPANY_FAIL';
export const updateNewResponseCompanyFail = actionCreater(UPDATE_NEW_RESPONSE_COMPANY_FAIL);

//Search users in projects
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const searchUsersRequest = actionCreater(SEARCH_USERS_REQUEST);

export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const searchUsersSuccess = actionCreater(SEARCH_USERS_SUCCESS);

export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL';
export const searchUsersFail = actionCreater(SEARCH_USERS_FAIL);

// Get list predecessors for link NF
export const GET_NF_LINK = 'GET_NF_LINK';
export const getNfLink = actionCreater(GET_NF_LINK);

// export const GET_NF_LINK_SUCCESS = 'GET_NF_LINK_SUCCESS';
// export const getNfLinkSuccess = actionCreater(GET_NF_LINK_SUCCESS);
//
// export const GET_NF_LINK_FAIL = 'GET_NF_LINK_FAIL';
// export const getNfLinkFail = actionCreater(GET_NF_LINK_FAIL);

// Link NF Predecessor
export const LINK_NF_PREDECESSOR = 'LINK_NF_PREDECESSOR';
export const linkNfPredecessor = actionCreater(LINK_NF_PREDECESSOR);

export const LINK_NF_PREDECESSOR_SUCCESS = 'LINK_NF_PREDECESSOR_SUCCESS';
export const linkNfPredecessorSuccess = actionCreater(LINK_NF_PREDECESSOR_SUCCESS);

export const LINK_NF_PREDECESSOR_FAIL = 'LINK_NF_PREDECESSOR_FAIL';
export const linkNfPredecessorFail = actionCreater(LINK_NF_PREDECESSOR_FAIL);

// Unlunk NF Predecessor
export const UNLINK_NF_PREDECESSOR = 'UNLINK_NF_PREDECESSOR';
export const unlinkNfPredecessor = actionCreater(UNLINK_NF_PREDECESSOR);

export const UNLINK_NF_PREDECESSOR_SUCCESS = 'UNLINK_NF_PREDECESSOR_SUCCESS';
export const unlinkNfPredecessorSuccess = actionCreater(UNLINK_NF_PREDECESSOR_SUCCESS);

// link NF Successor
export const LINK_NF_SUCCESSOR = 'LINK_NF_SUCCESSOR';
export const linkNfSuccessor = actionCreater(LINK_NF_SUCCESSOR);

export const LINK_NF_SUCCESSOR_SUCCESS = 'LINK_NF_SUCCESSOR_SUCCESS';
export const linkNfSuccessorSuccess = actionCreater(LINK_NF_SUCCESSOR_SUCCESS);

export const LINK_NF_SUCCESSOR_FAIL = 'LINK_NF_SUCCESSOR_FAIL';
export const linkNfSuccessorFail = actionCreater(LINK_NF_SUCCESSOR_FAIL);

// Unlunk NF Successor
export const UNLINK_NF_SUCCESSOR = 'UNLINK_NF_SUCCESSOR';
export const unlinkNfSuccessor = actionCreater(UNLINK_NF_SUCCESSOR);

export const UNLINK_NF_SUCCESSOR_SUCCESS = 'UNLINK_NF_SUCCESSOR_SUCCESS';
export const unlinkNfSuccessorSuccess = actionCreater(UNLINK_NF_SUCCESSOR_SUCCESS);

// Request card | add deactivated status
export const DEACTIVATED_STATUS = 'DEACTIVATED_STATUS';
export const deactivatedStatus = actionCreater(DEACTIVATED_STATUS);

export const DEACTIVATED_STATUS_SUCCESS = 'DEACTIVATED_STATUS_SUCCESS';
export const deactivatedStatusSuccess = actionCreater(DEACTIVATED_STATUS_SUCCESS);

export const DEACTIVATED_STATUS_FAIL = 'DEACTIVATED_STATUS_FAIL';
export const deactivatedStatusFail = actionCreater(DEACTIVATED_STATUS_FAIL);

//Sockets
export const SOCKET_HANDLE_REQUEST = 'SOCKET_HANDLE_REQUEST';
export const socketHandleRequest = actionCreater(SOCKET_HANDLE_REQUEST);

export const SOCKET_HANDLE_NEW_REQUEST = 'SOCKET_HANDLE_NEW_REQUEST';
export const socketHandleNewRequest = actionCreater(SOCKET_HANDLE_NEW_REQUEST);

export const SOCKET_HANDLE_NEW_REQUEST_SANDBOX = 'SOCKET_HANDLE_NEW_REQUEST_SANDBOX';
export const socketHandleNewRequestSandBox = actionCreater(SOCKET_HANDLE_NEW_REQUEST_SANDBOX);

export const SOCKET_HANDLE_NEW_COMMITMENT_SANDBOX = 'SOCKET_HANDLE_NEW_COMMITMENT_SANDBOX';
export const socketHandleNewCommitment = actionCreater(SOCKET_HANDLE_NEW_COMMITMENT_SANDBOX);

export const SOCKET_HANDLE_COMMITMENT = 'SOCKET_HANDLE_COMMITMENT';
export const socketHandleCommitment = actionCreater(SOCKET_HANDLE_COMMITMENT);

export const SOCKET_HANDLE_REQUEST_SAND_BOX = 'SOCKET_HANDLE_REQUEST_SAND_BOX';
export const socketHandleRequestSandBox = actionCreater(SOCKET_HANDLE_REQUEST_SAND_BOX);

export const SOCKET_HANDLE_COMMITMEN_SAND_BOXT = 'SOCKET_HANDLE_COMMITMEN_SAND_BOXT';
export const socketHandleCommitmentSandBox = actionCreater(SOCKET_HANDLE_COMMITMEN_SAND_BOXT);

export const SOCKET_HANDLE_DUE_DATE_CHANGED_SAND_BOX = 'SOCKET_HANDLE_DUE_DATE_CHANGED_SAND_BOX';
export const socketHandleDueDateChangedSandBox = actionCreater(SOCKET_HANDLE_DUE_DATE_CHANGED_SAND_BOX);

export const SOCKET_HANDLE_DUE_DATE_CHANGED = 'SOCKET_HANDLE_DUE_DATE_CHANGED';
export const socketHandleDueDateChanged = actionCreater(SOCKET_HANDLE_DUE_DATE_CHANGED);

export const SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED_SAND_BOX = 'SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED_SAND_BOX';
export const socketHandleRequestCompanyChangedSandBox = actionCreater(SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED_SAND_BOX);

export const SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED = 'SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED';
export const socketHandleResponseCompanyChanged = actionCreater(SOCKET_HANDLE_RESPONSE_COMPANY_CHANGED);

export const SOCKET_HANDLE_DELETE_REQUEST_SAND_BOX = 'SOCKET_HANDLE_DELETE_REQUEST_SAND_BOX';
export const socketHandleDeleteRequestSandBox = actionCreater(SOCKET_HANDLE_DELETE_REQUEST_SAND_BOX);

export const SOCKET_HANDLE_DELETE_REQUEST_NEED_LIST = 'SOCKET_HANDLE_DELETE_REQUEST_NEED_LIST';
export const socketHandleDeleteRequestNeedList = actionCreater(SOCKET_HANDLE_DELETE_REQUEST_NEED_LIST);

export const REQUEST_SYNC_LIST = 'REQUEST_SYNC_LIST';
export const requestSyncList = actionCreater(REQUEST_SYNC_LIST);

//Watcher
export const WATCHER_ADD_REQUEST = 'WATCHER_ADD_REQUEST';
export const watcherAddRequest = actionCreater(WATCHER_ADD_REQUEST);

//Search Watchers in projects
export const SEARCH_WATCHERS_REQUEST = 'SEARCH_WATCHERS_REQUEST';
export const searchWatchersRequest = actionCreater(SEARCH_WATCHERS_REQUEST);

export const SEARCH_WATCHERS_SUCCESS = 'SEARCH_WATCHERS_SUCCESS';
export const CLEAR_SEARCH_WATCHERS = 'CLEAR_SEARCH_WATCHERS';
export const searchWatchersSuccess = actionCreater(SEARCH_WATCHERS_SUCCESS);
export const clearSearchWatchersSuccess = actionCreater(CLEAR_SEARCH_WATCHERS);

export const SEARCH_WATCHERS_ADD_TO_GROUP_SUCCESS = 'SEARCH_WATCHERS_ADD_TO_GROUP_SUCCESS';
export const searchWatchersAddToGroupSuccess = actionCreater(SEARCH_WATCHERS_ADD_TO_GROUP_SUCCESS);

export const SEARCH_WATCHERS_FAIL = 'SEARCH_WATCHERS_FAIL';
export const searchWatchersFail = actionCreater(SEARCH_WATCHERS_FAIL);

// SandBox return To main Page
export const SANDBOX_RETURN_TO_MAIN_PAGE = 'SANDBOX_RETURN_TO_MAIN_PAGE';
export const sandBoxReturnToMainPage = actionCreater(SANDBOX_RETURN_TO_MAIN_PAGE);

export const REQUEST_VIEW = 'REQUEST_VIEW';
export const requestView = actionCreater(REQUEST_VIEW);

export const REQUEST_VIEW_SUCCESS = 'REQUEST_VIEW_SUCCESS';
export const requestViewSuccess = actionCreater(REQUEST_VIEW_SUCCESS);

export const SET_INCORPORATED = 'SET_INCORPORATED';
export const setIncorporated = actionCreater(SET_INCORPORATED);

export const SET_INCORPORATED_SUCCESS = 'SET_INCORPORATED_SUCCESS';
export const setIncorporatedSuccess = actionCreater(SET_INCORPORATED_SUCCESS);

export const CLEAR_NEED_LIST_FILTERS = 'CLEAR_NEED_LIST_FILTERS';
export const clearNeedListFilters = actionCreater(CLEAR_NEED_LIST_FILTERS);

export const GET_NEED_LIST_ALL_IDS = 'GET_NEED_LIST_ALL_IDS';
export const getNeedListAllRequest = actionCreater(GET_NEED_LIST_ALL_IDS);

export const GET_NEED_LIST_ALL_IDS_SUCCESS = 'GET_NEED_LIST_ALL_IDS_SUCCESS';
export const getNeedListAllRequestSuccess = actionCreater(GET_NEED_LIST_ALL_IDS_SUCCESS);

export const GET_FILTERED_REQUEST = 'GET_FILTERED_REQUEST';
export const getFilteredRequest = actionCreater(GET_FILTERED_REQUEST);

export const GET_FILTERED_REQUEST_SUCCESS = 'GET_FILTERED_REQUEST_SUCCESS';
export const getFilteredRequestSuccess = actionCreater(GET_FILTERED_REQUEST_SUCCESS);

export const GET_FILTERED_REQUEST_FAIL = 'GET_FILTERED_REQUEST_FAIL';
export const getFilteredRequestFail = actionCreater(GET_FILTERED_REQUEST_FAIL);

export const GET_SUCC_PRED__FROM_REQUEST = 'GET_SUCC_PRED__FROM_REQUEST';
export const getSuccPredFromRequest = actionCreater(GET_SUCC_PRED__FROM_REQUEST);

export const GET_SUCC_PRED__FROM_REQUEST_SUCCESS = 'GET_SUCC_PRED__FROM_REQUEST_SUCCESS';
export const getSuccPredFromRequestSuccess = actionCreater(GET_SUCC_PRED__FROM_REQUEST_SUCCESS);

export const SOCKET_SAND_BOX_COUNTER = 'SOCKET_SAND_BOX_COUNTER';
export const getSocketSandBoxCounter = actionCreater(SOCKET_SAND_BOX_COUNTER);

// Hashtags edit

export const EDIT_HASHTAG = 'EDIT_HASHTAG';
export const editHashtag = actionCreater(EDIT_HASHTAG);

export const EDIT_HASHTAG_SUCCESS = 'EDIT_HASHTAG_SUCCESS';
export const editHashtagSuccess = actionCreater(EDIT_HASHTAG_SUCCESS);

export const EDIT_HASHTAG_FAIL = 'EDIT_HASHTAG_FAIL';
export const editHashtagFail = actionCreater(EDIT_HASHTAG_FAIL);

// Hashtags delete

export const DELETE_HASHTAG = 'DELETE_HASHTAG';
export const deleteHashtag = actionCreater(DELETE_HASHTAG);

export const DELETE_HASHTAG_SUCCESS = 'DELETE_HASHTAG_SUCCESS';
export const deleteHashtagSuccess = actionCreater(DELETE_HASHTAG_SUCCESS);

export const DELETE_HASHTAG_FAIL = 'DELETE_HASHTAG_FAIL';
export const deleteHashtagFail = actionCreater(DELETE_HASHTAG_FAIL);

// Request card | Deliverable (Decline counter)
export const DECLINE_COUNTER = 'DECLINE_COUNTER';
export const declineCounter = actionCreater(DECLINE_COUNTER);

export const DECLINE_COUNTER_SUCCESS = 'DECLINE_COUNTER_SUCCESS';
export const declineCounterSuccess = actionCreater(DECLINE_COUNTER_SUCCESS);

export const DECLINE_COUNTER_FAIL = 'DECLINE_COUNTER_FAIL';
export const declineCounterFail = actionCreater(DECLINE_COUNTER_FAIL);

export const CLEAR_DECLINE_COUNTER = 'CLEAR_DECLINE_COUNTER';
export const clearDeclineCounter = actionCreater(CLEAR_DECLINE_COUNTER);

// MF Filters

export const GET_MAIN_MF = 'GET_MAIN_MF';
export const getMainMf = actionCreater(GET_MAIN_MF);

export const GET_MAIN_MF_CANCEL = 'GET_MAIN_MF_CANCEL';
export const getMainMfCancel = actionCreater(GET_MAIN_MF_CANCEL);

export const GET_MAIN_MF_SUCCESS = 'GET_MAIN_MF_SUCCESS';
export const getMainMfSuccess = actionCreater(GET_MAIN_MF_SUCCESS);

export const GET_MAIN_MF_FAIL = 'GET_MAIN_MF_FAIL';
export const getMainMfFail = actionCreater(GET_MAIN_MF_FAIL);

export const GET_MF_FROM_PARENT = 'GET_MF_FROM_PARENT';
export const getMfFromParent = actionCreater(GET_MF_FROM_PARENT);

export const GET_MF_FROM_PARENT_SUCCESS = 'GET_MF_FROM_PARENT_SUCCESS';
export const getMfFromParentSuccess = actionCreater(GET_MF_FROM_PARENT_SUCCESS);

export const GET_MF_FROM_PARENT_FAIL = 'GET_MF_FROM_PARENT_FAIL';
export const getMfFromParentFail = actionCreater(GET_MF_FROM_PARENT_FAIL);

// UF Filters

export const GET_MAIN_UF = 'GET_MAIN_UF';
export const getMainUf = actionCreater(GET_MAIN_UF);

export const GET_MAIN_UF_SUCCESS = 'GET_MAIN_UF_SUCCESS';
export const getMainUfSuccess = actionCreater(GET_MAIN_UF_SUCCESS);

export const GET_MAIN_UF_FAIL = 'GET_MAIN_UF_FAIL';
export const getMainUfFail = actionCreater(GET_MAIN_UF_FAIL);

export const GET_UF_FROM_PARENT = 'GET_UF_FROM_PARENT';
export const getUfFromParent = actionCreater(GET_UF_FROM_PARENT);

export const GET_UF_FROM_PARENT_SUCCESS = 'GET_UF_FROM_PARENT_SUCCESS';
export const getUfFromParentSuccess = actionCreater(GET_UF_FROM_PARENT_SUCCESS);

export const GET_UF_FROM_PARENT_FAIL = 'GET_UF_FROM_PARENT_FAIL';
export const getUfFromParentFail = actionCreater(GET_UF_FROM_PARENT_FAIL);

// MF UF Filters Search

export const SEARCH_MF_OR_UF_CODE = 'SEARCH_MF_OR_UF_CODE';
export const searchMForUFCode = actionCreater(SEARCH_MF_OR_UF_CODE);

export const SEARCH_MF_OR_UF_CODE_SUCCESS = 'SEARCH_MF_OR_UF_CODE_SUCCESS';
export const searchMForUFCodeSuccess = actionCreater(SEARCH_MF_OR_UF_CODE_SUCCESS);

export const SEARCH_MF_OR_UF_CODE_FAIL = 'SEARCH_MF_OR_UF_CODE_FAIL';
export const searchMForUFCodeFail = actionCreater(SEARCH_MF_OR_UF_CODE_FAIL);

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const searchRequest = actionCreater(SEARCH_REQUEST);

export const GET_SEARCH_REQUEST_FILTERS = 'GET_SEARCH_REQUEST_FILTERS';
export const getSearchRequestFilters = actionCreater(GET_SEARCH_REQUEST_FILTERS);

export const GET_SEARCH_REQUEST_FILTERS_SUCCESS = 'GET_SEARCH_REQUEST_FILTERS_SUCCESS';
export const getSearchRequestFiltersSuccess = actionCreater(GET_SEARCH_REQUEST_FILTERS_SUCCESS);
