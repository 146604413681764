import { filterFields, searchFields, switchColumns } from '../NeedsList/NeedListConstants/NeedListConstants';

export const deliverableGroupedByFilters = {
  buildings: ['locations', 'level'],
};

export const deliverableSelectorGroups = [
  { title: 'General', value: 'general' },
  { title: 'Parties', value: 'parties' },
  { title: 'Dates', value: 'dates' },
  { title: 'Locations', value: 'locations' },
  { title: 'Plan & Control', value: 'plan' },
];

export const deliverableSortingOptions = [
  { value: 'status', title: 'Status' },
  { value: 'discipline', title: 'Discipline' },
  { value: 'scheduled_date', title: 'Scheduled date' },
  { value: 'critical_date', title: 'Critical date' },
  { value: 'issued_date', title: 'Issued date' },
  { value: 'level', title: 'Level' },
];

/*
 * title - Column name
 * isChecked - Hide\show column
 * value - Property for switch hide\show column
 * width - Column width
 * filterField - Property for get filter from filters collection
 * searchField - Column search field
 * exportField - For export column like: PDF
 * sortingField - Column sorting
 * editFieldName - For edit field
 * filteredField - Send filter property to API for filtration
 * */
export const list_columns = [
  /**
   * General
   */
  {
    title: 'Issuance status',
    isChecked: true,
    value: 'card_status',
    width: 72,
    filterField: 'card_status',
    searchField: 'card_status',
    exportField: 'card_status',
    sortingField: 'card_status',
    position: 1,
    group: 'general',
  },
  {
    title: 'Review status',
    isChecked: true,
    value: 'review_state',
    width: 128,
    filterField: 'review_state',
    searchField: 'review_state',
    exportField: 'review_state',
    sortingField: 'review_state',
    editFieldName: 'review_state',
    filteredField: 'review_state',
    position: 2,
    group: 'general',
  },
  {
    title: 'Deliverable type',
    isChecked: true,
    value: 'deliverable_type',
    filterField: 'deliverable_type',
    width: 128,
    exportField: 'deliverable_type',
    filteredField: 'deliverable_type',
    position: 3,
    group: 'general',
  },
  {
    title: 'Deliverable discipline',
    isChecked: true,
    value: 'deliverable_discipline',
    filterField: 'deliverable_discipline',
    width: 148,
    exportField: 'deliverable_discipline',
    sortingField: 'deliverable_discipline',
    filteredField: 'deliverable_discipline',
    position: 4,
    group: 'general',
  },
  {
    title: 'UniFormat',
    isChecked: true,
    value: 'uf',
    width: 127,
    filterField: 'uf',
    searchField: 'uf',
    exportField: 'uf',
    sortingField: 'uf',
    filteredField: 'uf',
    position: 5,
    group: 'general',
  },
  {
    title: 'MasterFormat',
    isChecked: true,
    value: 'mf',
    width: 127,
    filterField: 'mf',
    searchField: 'mf',
    exportField: 'mf',
    sortingField: 'mf',
    filteredField: 'mf',
    position: 6,
    group: 'general',
  },
  {
    title: 'Tags',
    isChecked: true,
    value: 'hashtags',
    width: 160,
    filterField: 'hashtags',
    searchField: 'hashtags',
    exportField: 'hashtags',
    sortingField: 'hashtags',
    editFieldName: 'hashtags',
    filteredField: 'hashtags',
    isSwitchAnd: true,
    position: 9,
    group: 'general',
    conditionType: 'TEXT',
  },
  {
    title: 'DD set',
    isChecked: true,
    value: 'DDL_set',
    width: 98,
    filterField: 'DDL_set',
    searchField: 'DDL_set',
    exportField: 'DDL_set',
    sortingField: 'DDL_set',
    editFieldName: 'DDL_set',
    filteredField: 'DDL_set',
    position: 7,
    group: 'general',
  },
  /**
   * Parties
   */
  {
    title: 'Responsible party company',
    isChecked: true,
    value: 'responsible_party',
    width: 148,
    filterField: 'responsible_party',
    searchField: 'responsible_party',
    exportField: 'responsible_party',
    sortingField: 'responsible_party',
    editFieldName: 'responsible_party',
    filteredField: 'responsible_party',
    position: 1,
    group: 'parties',
  },
  {
    title: 'Responsible party manager',
    isChecked: true,
    value: 'responsible_party_manager',
    width: 148,
    filterField: 'responsible_party_manager',
    searchField: 'responsible_party_manager',
    exportField: 'responsible_party_manager',
    sortingField: 'responsible_party_manager',
    editFieldName: 'responsible_party_manager',
    filteredField: 'responsible_party_manager',
    position: 2,
    group: 'parties',
  },
  {
    title: 'Review party company',
    isChecked: true,
    value: 'review_company',
    width: 148,
    filterField: 'review_company',
    searchField: 'review_company',
    exportField: 'review_company',
    sortingField: 'review_company',
    editFieldName: 'review_company',
    filteredField: 'review_company',
    position: 3,
    group: 'parties',
  },
  {
    title: 'Review party manager',
    isChecked: true,
    value: 'review_manager',
    width: 148,
    filterField: 'review_manager',
    searchField: 'review_manager',
    exportField: 'review_manager',
    sortingField: 'review_manager',
    editFieldName: 'review_manager',
    filteredField: 'review_manager',
    position: 4,
    group: 'parties',
  },
  {
    title: 'Deliverable issued by',
    isChecked: true,
    value: 'card_author',
    width: 180,
    filterField: 'card_author',
    searchField: 'card_author',
    exportField: 'card_author',
    sortingField: 'card_author',
    editFieldName: 'card_author',
    filteredField: 'card_author',
    position: 5,
    group: 'parties',
  },
  {
    title: 'Deliverable revision author',
    isChecked: true,
    value: 'card_creator',
    width: 180,
    filterField: 'card_creator',
    searchField: 'card_creator',
    exportField: 'card_creator',
    sortingField: 'card_creator',
    editFieldName: 'card_creator',
    filteredField: 'card_creator',
    position: 6,
    group: 'parties',
  },
  /**
   * Dates
   */
  {
    title: 'Critical Path date',
    isChecked: true,
    value: 'critical_date',
    width: 180,
    filterField: 'critical_date',
    searchField: 'critical_date',
    exportField: 'critical_date',
    sortingField: 'critical_date',
    editFieldName: 'critical_date',
    filteredField: 'critical_date',
    position: 1,
    group: 'dates',
    conditionType: 'DATE',
  },
  {
    title: 'Scheduled date',
    isChecked: true,
    value: 'scheduled_date',
    width: 180,
    filterField: 'scheduled_date',
    searchField: 'scheduled_date',
    exportField: 'scheduled_date',
    sortingField: 'scheduled_date',
    editFieldName: 'scheduled_date',
    filteredField: 'scheduled_date',
    position: 2,
    group: 'dates',
    conditionType: 'DATE',
  },
  {
    title: 'Issued date',
    isChecked: true,
    value: 'issued_date',
    width: 148,
    filterField: 'issued_date',
    searchField: 'issued_date',
    exportField: 'issued_date',
    sortingField: 'issued_date',
    editFieldName: 'issued_date',
    filteredField: 'issued_date',
    position: 3,
    group: 'dates',
    conditionType: 'DATE',
  },
  {
    title: 'Issued date state',
    isChecked: true,
    value: 'issued_date_state',
    width: 148,
    filterField: 'issued_date_state',
    searchField: 'issued_date_state',
    exportField: 'issued_date_state',
    sortingField: 'issued_date_state',
    editFieldName: 'issued_date_state',
    filteredField: 'issued_date_state',
    position: 4,
    group: 'dates',
  },
  /**
   * Locations
   */
  {
    title: 'Level',
    isChecked: true,
    value: 'level',
    width: 100,
    filterField: 'level',
    searchField: 'level',
    exportField: 'level',
    sortingField: 'level',
    editFieldName: 'level',
    filteredField: 'level',
    position: 1,
    group: 'locations',
  },
  /**
   * Plan and control
   */
  {
    filterField: 'my_hot_list',
    title: 'Marked by me',
    group: 'plan',
    position: 1,
  },
  {
    filterField: 'company_hot_list',
    title: 'Marked by my company',
    group: 'plan',
    position: 2,
  },
];
