import React from 'react';
import SVG from 'react-inlinesvg';
import './FileGalleryStyles.scss';
import { FileModel } from '../../../models';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

export const CircularProgressBtn = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-20px',
    marginLeft: '-20px',
  },
  colorPrimary: {
    color: '#FC8F14',
  },
})(CircularProgress);
interface IProps {
  handleFile: (e: any) => void;
  files: FileModel[] | FileList | any;
  delSavedFile: (file_id: number) => void;
  delFile: (filename: string) => void;
  isFileAddDisabled: boolean;
  chooseThumbnail: (file: FileModel | FileList | any) => void;
  chosenThumbnail: string;
  isCircularProgress: boolean;
  isSuccessCircular: boolean;
  isCircularProgressAnime: boolean;
  nameHandleFile: string;
  setCircularProgressAnime: () => void;
}

interface IState {}

class FileGalleryView extends React.Component<IProps, IState> {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.nameHandleFile.length > 0 &&
      this.props.nameHandleFile.length > prevProps.nameHandleFile.length &&
      this.props.isCircularProgressAnime
    ) {
      setTimeout(() => {
        this.props.setCircularProgressAnime();
      }, 4000);
    }
  }

  delFile = file => {
    const { delFile, delSavedFile } = this.props;

    if (!file.name && file.title) {
      delSavedFile(file.id);
    }

    if (file.name && !file.title) {
      delFile(file.name);
    }
  };

  render() {
    const {
      handleFile,
      files,
      isFileAddDisabled,
      chooseThumbnail,
      chosenThumbnail,
      isCircularProgress,
      isSuccessCircular,
      nameHandleFile,
      isCircularProgressAnime,
    } = this.props;

    return (
      <>
        <div className={'fileGallery'}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`raised-button-file-project`}
            multiple
            type="file"
            onChange={handleFile}
            disabled={isFileAddDisabled}
          />
          {files &&
            files.map((file, i) => {
              const isChosen = chosenThumbnail && chosenThumbnail === (file.fullpath || file.file);
              return (
                <div
                  className={`fileGallery__item ${isChosen ? '-chosenItem' : ''}`}
                  onClick={() => chooseThumbnail(file)}
                  key={file.id}
                  style={{
                    backgroundImage: `url(${file.fullpath || file.file})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  <div
                    className={'iconDelete'}
                    onClick={e => {
                      e.stopPropagation();
                      this.delFile(file);
                    }}
                  >
                    <ClearIcon />
                  </div>
                  {isChosen ? (
                    <span className={'chosenThumbnail'}>Current thumbnail</span>
                  ) : (
                    <span className={'thumbnailToChoose'}>Set as thumbnail</span>
                  )}
                  {isCircularProgress && !file.id && file.name === nameHandleFile[nameHandleFile.length - 1] && isCircularProgressAnime ? (
                    isSuccessCircular ? (
                      <CircularProgressBtn size={40} />
                    ) : (
                      <div className={'fileGallery__successCircularProgress'}>
                        <SVG src={require('../../../assets/images/vectorSuccessProgress.svg')} />
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          <label htmlFor={`raised-button-file-project`}>
            <div className={`fileGallery__fileHandler ${isFileAddDisabled ? '-disabled' : ''}`}>
              <span className={`plusSVG`}>
                <SVG src={require('../../../assets/icons/plus_icon.svg')} />
              </span>
            </div>
          </label>
        </div>
      </>
    );
  }
}

export default FileGalleryView;
