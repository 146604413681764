export const validatorPatternForSend = {
  required: [
    'title',
    'type_id',
    'mf_id',
    'contractor_party_id',
    'contractor_user_id',
    'responsible_party_id',
    'responsible_user_id',
    'description',
  ],
  requiredOne: [],
};

export const validatorPatternForSendWithFiles = {
  required: ['title', 'type_id', 'mf_id', 'contractor_party_id', 'contractor_user_id', 'responsible_party_id', 'responsible_user_id'],
  requiredOne: [],
};

export const validatorPatternForDraft = {
  required: ['mf_id', 'type_id'],
  requiredOne: [],
};
