import React from 'react';
import { LocationCollectionModel } from '../../../../../../models';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import TokenIcon from '../../../../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../../../../controls/ButtonComponents/CustomButton/CustomButton';

import './LevelExpandItemStyles.scss';

interface IProps {
  level: LocationCollectionModel;
  handleLevelExpandItemItemClick?: (level: LocationCollectionModel) => void;
  handleLevelExpandSelectAll?: (e: React.SyntheticEvent, levelsArr: LocationCollectionModel[]) => void;
  handleLevelExpandClearAll?: (e: React.SyntheticEvent, levelsArr: LocationCollectionModel[]) => void;
}

const LevelExpandItem: React.FC<IProps> = ({
  level,
  handleLevelExpandItemItemClick,
  handleLevelExpandSelectAll,
  handleLevelExpandClearAll,
}) => {
  const themeClass = useGetThemeClass('b-levelExpandItem');

  return (
    <div className={`${themeClass}_container`} onClick={() => handleLevelExpandItemItemClick(level)}>
      <div className={`${themeClass}_item`}>
        <div className={`${themeClass}_item_title`}>{level.title}</div>
        <div className={`${themeClass}_item_rightElems`}>
          {handleLevelExpandSelectAll && handleLevelExpandClearAll && level.selectedLevelsCounter === level?.levelsInItem?.length && (
            <CustomButton
              title={'Clear All'}
              clickHandler={e => handleLevelExpandClearAll(e, level.levelsInItem)}
              type={'text-plain'}
              size={'xs'}
            />
          )}
          {handleLevelExpandSelectAll && handleLevelExpandClearAll && level.selectedLevelsCounter !== level?.levelsInItem?.length && (
            <CustomButton
              title={'Select All'}
              clickHandler={e => handleLevelExpandSelectAll(e, level.levelsInItem)}
              type={'text-plain'}
              size={'xs'}
            />
          )}
          <div className={`${themeClass}_item_rightElems_icon`}>
            <TokenIcon iconName={level.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelExpandItem;
