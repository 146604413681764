import React, { Component } from 'react';

class Update_20220420_3 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_1b895340aaef28e8caa0a9be6f3cc8a8/images/image2_qhD.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20220420_3;
