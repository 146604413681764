export const MenuPropsSingle: any = {
  PaperProps: {
    style: {
      maxHeight: '419px',
      overflowY: 'auto',
      maxWidth: '400px',
      minWidth: '260px',
      backgroundColor: 'var(--background-main)',
      borderRadius: '3px',
      zIndex: 3,
      marginTop: '5px',
    },
  },
  MenuListProps: {
    style: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '1px',
      backgroundColor: 'var(--background-header)',
      '&:hover': {
        backgroundColor: 'var(--selectedFilterDropDown-hover)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const MenuBuildingPropsSingle: any = {
  PaperProps: {
    style: {
      maxHeight: '419px',
      overflowY: 'auto',
      maxWidth: '400px',
      minWidth: '160px',
      backgroundColor: 'var(--selectedFilterDropDown)',
      borderRadius: '3px',
      zIndex: 3,
      marginTop: '4px',
    },
  },
  MenuListProps: {
    style: {
      paddingBottom: '1px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'var(--selectedFilterDropDown)',
      '&:hover': {
        backgroundColor: 'var(--selectedFilterDropDown-hover)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const MenuPropsSingleSelected = {
  backgroundColor: 'var(--background-select-hover)',
  fontWeight: 'var(--font-weight)',
};
