import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import tokenService from '../auth';
import { DOMAIN_URI, API_URI } from '../links';
import { logoutSuccess } from '../../store/user/userActions';

// Local
//export const DOMAIN_URI = 'http://api.dev.planit-inc.com';
//export const API_URI = '/api/v1/';

type RequestInterface = Partial<Pick<AxiosRequestConfig, 'url' | 'headers'>> & {
  route: string;
  getParams?: unknown;
  method: string;
  bodyParams?: Record<string, any>;
  needAuth?: boolean;
  formData?: boolean;
  devMode?: boolean;
  files?: unknown[];
};

export class BaseApi {
  _axios: AxiosInstance;

  _methods = {
    GET: 'get',
    POST: 'post',
    PATCH: 'patch',
    PUT: 'put',
    DELETE: 'delete',
  };
  //_requestDevMode = false;
  tokenService = tokenService;

  static instanse = null;

  static getBaseUrl() {
    //return 'http://api.dev.planit-inc.com' + '/api/v1/';
    return DOMAIN_URI + API_URI;
  }

  static getInstance() {
    if (!BaseApi.instanse) {
      BaseApi.instanse = new BaseApi(BaseApi.getBaseUrl());
    }
    return BaseApi.instanse;
  }

  constructor(baseURL) {
    if (!BaseApi.instanse) {
      this._axios = axios.create({
        baseURL: baseURL || BaseApi.getBaseUrl(),
        headers: {
          Accept: 'application/json; charset=utf-8',
          'X-Requested-With': 'XMLHttpRequest',
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
      BaseApi.instanse = this;
    }
    return this;
  }

  _isMethodExist(method) {
    switch (method) {
      case this._methods.GET:
        return true;
      case this._methods.POST:
        return true;
      case this._methods.PUT:
        return true;
      case this._methods.DELETE:
        return true;
      default:
        return false;
    }
  }

  async _getRequest({
    url = null,
    method,
    route,
    getParams,
    bodyParams,
    needAuth = false,
    headers = {},
    formData = false,
    devMode = false,
    files = [],
  }: RequestInterface) {
    const correctUrlRegExp = /^(http|https):\/\//i;
    if (!this._isMethodExist(method)) {
      throw [`This is not correct method ${method}`];
    }
    const config: AxiosRequestConfig = {
      url: route,
      method,
    };

    if (url && correctUrlRegExp.test(url)) {
      config.baseURL = url;
    }

    if (needAuth) {
      const token = this.tokenService.getToken();
      if (!this.tokenService.isLoggedIn()) {
        throw [`User is not Authorized or token is not valid`];
      }
      config.headers = { Authorization: `Bearer ${token}` };
    }

    if (formData && bodyParams) {
      config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data' };
      const data = new FormData();
      for (const fieldName in bodyParams) {
        if (Array.isArray(bodyParams[fieldName])) {
          bodyParams[fieldName].map(param => {
            data.append(`${fieldName}[]`, param);
          });
          if (!bodyParams[fieldName].length) {
            data.append(`${fieldName}[]`, '');
          }
        } else {
          data.append(fieldName, bodyParams[fieldName]);
        }
      }

      config.data = data;
    } else {
      config.headers = { ...config.headers, 'Content-Type': 'application/json' };
    }

    config.headers = { ...config.headers, ...headers };

    if (getParams && method === this._methods.GET) {
      config.params = getParams;
    }

    if (bodyParams && !formData && (method === this._methods.POST || method === this._methods.PUT || method === this._methods.DELETE)) {
      config.data = bodyParams;
    }

    return await BaseApi.instanse._axios
      .request(config)
      .then(response => {
        if (devMode) {
          // console.log('request dev mode -- ', response);
        }

        const { data } = response;

        // if (!data.status) {
        //   const errorResponse = {
        //     data: data.data,
        //     status: data.status,
        //     message: data.message,
        //   };
        //   //throw errorResponse;
        // }

        const result = {
          message: data.message,
          status: data.status,
          data: data.data,
          response: data.data,
        };
        if (data.data.resource && !data.data.by_me) {
          result.data = { ...data.data.resource };

          if (result.data.accessToken) {
            this.tokenService.setToken(result.data.accessToken);
            delete result.data.accessToken;
          }
        }
        return result;
      })
      .catch(this._requestErrorHandler);
  }

  _requestErrorHandler(errorResponse) {
    //if (process.env.NODE_ENV === 'development') {
    //  console.error('Error response', errorResponse.response);
    //}
    console.log('errorResponse', errorResponse);
    if (errorResponse.response?.status === 401 || errorResponse.response?.status === 429) {
      localStorage.clear();
      sessionStorage.clear();
    }

    // if (errorResponse.response?.status === 404) {
    //   return errorResponse.response;
    // }

    if (errorResponse.response) {
      const message = errorResponse.response.statusText;
      console.error(new Error(message || ''));
      const resultError = { ...new Error(''), ...errorResponse.response };
      throw resultError;
    }
    throw errorResponse;
  }

  async _get({
    url,
    route,
    getParams,
    needAuth,
    headers,
    devMode,
  }: Pick<RequestInterface, 'url' | 'route' | 'getParams' | 'headers' | 'needAuth' | 'devMode'>) {
    return await this._getRequest({
      url,
      method: this._methods.GET,
      route,
      getParams,
      needAuth,
      headers,
      devMode,
    })
      .then(result => result)
      .catch(this._requestErrorHandler);
  }

  async _post({
    url,
    route,
    bodyParams,
    needAuth,
    headers,
    formData,
    devMode,
    files,
  }: Pick<RequestInterface, 'url' | 'route' | 'formData' | 'headers' | 'needAuth' | 'devMode' | 'files' | 'bodyParams'>) {
    return await this._getRequest({
      url,
      method: this._methods.POST,
      route,
      bodyParams,
      needAuth,
      headers,
      formData,
      devMode,
      files,
    }).then(result => result);
    // .catch(this._requestErrorHandler);
  }

  async _update({
    url,
    route,
    bodyParams,
    needAuth,
    headers,
    formData,
    devMode,
  }: Pick<RequestInterface, 'url' | 'route' | 'formData' | 'headers' | 'needAuth' | 'devMode' | 'bodyParams'>) {
    return await this._getRequest({
      url,
      method: this._methods.PUT,
      route,
      bodyParams,
      needAuth,
      headers,
      formData,
      devMode,
    }).then(result => result);
    // .catch(this._requestErrorHandler);
  }

  async _delete({
    route,
    needAuth,
    headers,
    devMode,
    bodyParams,
  }: Pick<RequestInterface, 'route' | 'headers' | 'needAuth' | 'devMode' | 'bodyParams'>) {
    return await this._getRequest({
      method: this._methods.DELETE,
      route,
      bodyParams,
      needAuth,
      headers,
      devMode,
    })
      .then(result => result)
      .catch(this._requestErrorHandler);
  }

  async _put({
    route,
    needAuth,
    headers,
    devMode,
    bodyParams,
  }: Pick<RequestInterface, 'route' | 'headers' | 'needAuth' | 'devMode' | 'bodyParams'>) {
    return await this._getRequest({
      method: this._methods.PUT,
      route,
      bodyParams,
      needAuth,
      headers,
      devMode,
    })
      .then(result => result)
      .catch(this._requestErrorHandler);
  }

  _routeCompose(route, params) {
    const routeWithLastSlash = route.replace(/\/$/i, '') + '/';
    if (Array.isArray(params)) {
      return routeWithLastSlash + params.join('/');
    }
    return routeWithLastSlash + params;
  }

  _objectIndexRebace = obj => {
    const newObj = {};
    for (const key in obj) {
      newObj[obj[key].id] = obj[key];
    }
    return newObj;
  };

  async _getFile(url) {
    const token = this.tokenService.getToken();
    if (!this.tokenService.isLoggedIn()) {
      throw [`User is not Authorized or token is not valid`];
    }

    const config = {
      url,
      method: this._methods.GET,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    };
    return await await axios(config).then(response => {
      return response;
    });
  }

  async _getFilePost(url, bodyParams) {
    const token = this.tokenService.getToken();
    if (!this.tokenService.isLoggedIn()) {
      throw [`User is not Authorized or token is not valid`];
    }

    const config = {
      url,
      method: this._methods.POST,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
      data: bodyParams,
    };
    return await await axios(config).then(response => {
      return response;
    });
  }

  async _getFileGet(url, bodyParams) {
    const token = this.tokenService.getToken();
    if (!this.tokenService.isLoggedIn()) {
      throw [`User is not Authorized or token is not valid`];
    }

    const config = {
      url,
      method: this._methods.GET,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
      data: bodyParams,
    };
    return await await axios(config).then(response => {
      return response;
    });
  }
}

export default BaseApi.getInstance();
