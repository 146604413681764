import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import TablePlanView from './TablePlanView';
import moment from 'moment';
import { ScheduleModel } from '../../../../../models';
import { scheduleColumns } from '../../../../../constants/phasePlanColumns';
import PhasePlanHelper from '../../../../../helpers/PhasePlanHelper';
import BaseHelper from '../../../../../helpers/BaseHelper';
import {
  createPhasePlanField,
  deletePhasePlanField,
  setDriverTasks,
  updatePhasePlanField,
} from '../../../../../store/phasePlan/phasePlanThunk';
import { phasePlanSlice } from '../../../../../store/phasePlan/phasePlanSlice';
import { savePhasePlan } from '../../../../../store/project/projectLogic';
const { phasePlanChangeTable } = phasePlanSlice.actions;

export class SelectedFieldModel {
  row: number;
  column: number;
  totalRows: number;
  totalColumns: number;
  editField = false;
  table: 'schedules' | 'permissions' = 'schedules';
  columnName: string;
  action: 'create' | 'update' | 'delete' | 'd&d' | 'driver' = null;

  constructor(props) {
    this.row = props.row;
    this.column = props.column;
    this.totalRows = props.totalRows;
    this.totalColumns = props.totalColumns;
    this.editField = !!props.editField;
    this.table = props.table;
    this.columnName = props.columnName;
    this.action = props.action;
  }
}

class TablePlanContainer extends React.Component<any, {}> {
  state = {
    errors: {},
    schedules: [],
    permissions: [],
    anchorEl: null,
    removeRowIndex: null,
    indexOpenedRowCalendar: null,
    idOpenedColumnCalendar: null,
    calendarOpenedTable: null,
    removeRow: null,
    isSavePhasePlan: null,
    selectedField: {} as SelectedFieldModel,
    driverTasks: [],
    copyValue: null,
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editMode !== prevProps.editMode) {
      this.setState({
        driverTasks: [],
        selectedField: {},
        copyValue: null,
      });
    }
    if (Object.values(this.props.phasePlanData || {}).length && this.props.phasePlanData !== prevProps.phasePlanData) {
      const schedules = [...this.props.phasePlanData.schedules];
      const permissions = [...this.props.phasePlanData.permissions];
      this.setState({
        permissions: permissions,
        schedules: schedules,
      });
    }
    if (!Object.values(this.props.phasePlanData || {}).length && this.props.phasePlanData !== prevProps.phasePlanData) {
      this.setState({
        permissions: [],
        schedules: [],
      });
    }
    if (this.props.updatePhasePlanStatus.fail && this.props.updatePhasePlanStatus !== prevProps.updatePhasePlanStatus) {
      this.setState({
        errors: this.props.phasePlanErrors,
      });
    }
    if (this.props.applyCoordinationDriver && this.props.applyCoordinationDriver !== prevProps.applyCoordinationDriver) {
      const { driverTasks, schedules } = this.state;
      let data = [...schedules];

      if (driverTasks?.length) {
        data = this.calculateDriverDates(data, driverTasks[0].lod325_start);
      }
      this.setState({
        driverTasks: [],
        schedules: data,
      });
      this.props.successApplyDriverChangesDate();
      if (driverTasks.length) {
        const { initData, setDriverTasks } = this.props;

        const newDrivers = driverTasks.map(task => data.find(s => task.id === s.id));

        const selectedField: SelectedFieldModel = {
          row: driverTasks[0].position,
          column: 7,
          totalColumns: null,
          totalRows: null,
          editField: true,
          table: 'schedules',
          columnName: 'lod325_start',
          action: 'driver',
        };

        const filteredArray = initData.schedules
          .map(s => {
            if (driverTasks.find(d => d.id === s.id)) {
              return s;
            } else {
              return void 0;
            }
          })
          .filter(f => !!f);

        setDriverTasks(newDrivers);
        this.saveToStack(selectedField, filteredArray, newDrivers);
      }
      this.props.phasePlanChangeTable({ table: 'schedules', data: data });
    }
    if (this.props.coordinationDriverMode !== prevProps.coordinationDriverMode) {
      this.setState({
        driverTasks: [],
        selectedField: {},
        copyValue: null,
      });
    }
    if (this.props.currentStateIndex !== prevProps.currentStateIndex) {
      const { past, currentStateIndex, initData } = this.props;
      // REDO OR NEW CHANGES
      if (this.props.currentStateIndex > prevProps.currentStateIndex) {
        const status = past?.[currentStateIndex - 1]?.selectedField?.action;
        // GMP
        if (status === 'gmp') {
          return;
        }
        // UPDATE
        if (past[currentStateIndex - 1]?.selectedField?.action === 'update') {
          const data = [...this.state[past[currentStateIndex - 1].selectedField.table]];
          if (past[currentStateIndex - 1]?.fromField) {
            data[past[currentStateIndex - 1].selectedField.row] = past[currentStateIndex - 1].to;
            this.setState({
              [past[currentStateIndex - 1].selectedField.table]: data,
            });
          }
        }
        // DRAG AND DROP
        if (status === 'd&d') {
          let items = PhasePlanHelper.reorderArray(
            initData[past[currentStateIndex - 1].selectedField.table],
            past[currentStateIndex - 1].fromField.position,
            past[currentStateIndex - 1].to.position,
          ) as ScheduleModel[];
          items = items.map((item, index) => {
            return {
              ...item,
              position: index,
            };
          });
          this.setState({
            [past[currentStateIndex - 1].selectedField.table]: items,
          });
        }
      }
      // UNDO
      if (this.props.currentStateIndex < prevProps.currentStateIndex) {
        const status = past[currentStateIndex].selectedField.action;
        // GMP
        if (status === 'gmp') {
          return;
        }
        const data = [...this.state[past[currentStateIndex].selectedField.table]];
        // UPDATE
        if (status === 'update') {
          if (past[currentStateIndex]?.fromField) {
            data.splice(past[currentStateIndex].selectedField.row, 1, past[currentStateIndex].fromField);
          } else {
            data.splice(past[currentStateIndex].selectedField.row, 1);
          }
          this.setState({
            [past[currentStateIndex].selectedField.table]: data,
          });
        }
        // DRAG AND DROP
        if (status === 'd&d') {
          let items = PhasePlanHelper.reorderArray(
            data,
            past[currentStateIndex].to.position,
            past[currentStateIndex].fromField.position,
          ) as ScheduleModel[];
          items = items.map((item, index) => {
            return {
              ...item,
              position: index,
            };
          });
          this.setState({
            [past[currentStateIndex].selectedField.table]: items,
          });
        }
        // CREATE
        if (status === 'create') {
          this.props.deletePhasePlanField({
            id: initData[past[currentStateIndex].selectedField.table][past[currentStateIndex].selectedField.row].id,
            table: past[currentStateIndex].selectedField.table,
          });
        }
      }
    }
  }

  handleChangeNumber = (name, index, table) => numberValue => {
    const data = [...this.state[table]];
    let value = numberValue.floatValue && +numberValue.floatValue.toFixed(3);
    if (value > 999) {
      value = 999;
    } else if (value < 0) {
      value = 0;
    }
    let changeField = { ...data[index] };
    const resetIndex = data[index].position;

    changeField = {
      ...changeField,
      [name]: value,
    };

    if (table === 'permissions') {
      changeField = this.calculatePermissionsDates({ ...changeField });

      if (data[index].submission_date) {
        changeField = {
          ...changeField,
          approval_date: moment(data[index].submission_date).add(value, 'weeks').format('YYYY-MM-DD'),
        };
      }
    } else {
      changeField = this.calculateScheduleDates({ ...changeField });
    }

    data.splice(index, 1, changeField);

    this.setState({
      [table]: data,
    });
    this.props.phasePlanChangeTable({ table: table, data: data });
    this.resetError(resetIndex, name, table);
  };

  calculatePermissionsDates = data => {
    const { idOpenedColumnCalendar } = this.state;
    const newData = { ...data };

    if (idOpenedColumnCalendar && idOpenedColumnCalendar.type === 'datePicker') {
      if (idOpenedColumnCalendar.id === 'submission_date') {
        if (!newData.approval_date) {
          newData.approval_date = moment(newData.submission_date)
            .add(newData.duration || 0, 'weeks')
            .format('YYYY-MM-DD');
        } else {
          newData.durations = moment(newData.approval_date).diff(newData.submission_date, 'weeks', true);
        }
      }
      if (idOpenedColumnCalendar.id === 'approval_date') {
        if (!newData.submission_date) {
          newData.submission_date = moment(newData.approval_date)
            .add(-data.duration || 0, 'weeks')
            .format('YYYY-MM-DD');
        } else {
          newData.durations = moment(newData.approval_date).diff(newData.submission_date, 'weeks', true);
        }
      }
    }
    if (!newData['durations']) {
      newData['durations'] = 0;
    }

    if (newData['durations'] === 0 && newData.submission_date) {
      newData.approval_date = moment(newData.submission_date).format('YYYY-MM-DD');
    }

    return newData;
  };

  calculateScheduleDates = (data, idOpenedDate?) => {
    const newData = { ...data };
    if (idOpenedDate && idOpenedDate.type === 'datePicker') {
      if (idOpenedDate.id === 'lod290_start') {
        newData.lod290_duration = moment(newData.lod300_start).diff(newData.lod290_start, 'weeks', true);
      }
      if (idOpenedDate.id === 'lod300_start') {
        newData.lod300_duration = moment(newData.lod325_start).diff(newData.lod300_start, 'weeks', true);
      }
      if (idOpenedDate.id === 'lod325_start') {
        newData.lod325_duration = moment(newData.lod350_start).diff(newData.lod325_start, 'weeks', true);
      }
      if (idOpenedDate.id === 'lod350_start') {
        newData.lod350_duration = moment(newData.construction_start).diff(newData.lod350_start, 'weeks', true);
      }
    }
    if (newData.construction_start) {
      !Number.isFinite(newData?.lod350_duration) && newData?.lod350_duration !== undefined ? (newData.lod350_duration = 1) : null;
      !Number.isFinite(newData?.lod325_duration) && newData?.lod325_duration !== undefined ? (newData.lod325_duration = 1) : null;
      !Number.isFinite(newData?.lod300_duration) && newData?.lod300_duration !== undefined ? (newData.lod300_duration = 1) : null;
      !Number.isFinite(newData?.lod290_duration) && newData?.lod290_duration !== undefined ? (newData.lod290_duration = 1) : null;
      !Number.isFinite(newData?.lod200_duration) && newData?.lod200_duration !== undefined ? (newData.lod200_duration = 1) : null;
      !Number.isFinite(newData?.construction_duration) && data?.construction_duration !== undefined
        ? (newData.construction_duration = 1)
        : null;
      newData.lod350_start = moment(newData.construction_start).add(-data.lod350_duration, 'weeks').format('YYYY-MM-DD');
      newData.lod325_start = moment(newData.construction_start)
        .add(-(newData.lod350_duration + newData.lod325_duration), 'weeks')
        .format('YYYY-MM-DD');
      newData.lod300_start = moment(newData.construction_start)
        .add(-(newData.lod350_duration + newData.lod325_duration + newData.lod300_duration), 'weeks')
        .format('YYYY-MM-DD');
      newData.lod290_start = moment(newData.construction_start)
        .add(-(newData.lod350_duration + newData.lod325_duration + newData.lod300_duration + newData.lod290_duration), 'weeks')
        .format('YYYY-MM-DD');
      newData.lod200_start = moment(newData.construction_start)
        .add(
          -(
            newData.lod350_duration +
            newData.lod325_duration +
            newData.lod300_duration +
            newData.lod290_duration +
            newData.lod200_duration
          ),
          'weeks',
        )
        .format('YYYY-MM-DD');
    }

    return newData;
  };

  handleOpenCalendar = (index: number, column, table: string, isDisabled: boolean) => e => {
    if (isDisabled && !this.state.driverTasks?.length) {
      return;
    }
    if (
      this.props.editMode ||
      (this.state.driverTasks?.length && column.id === 'lod325_start' && this.state[table][index]?.id === this.state.driverTasks?.[0].id)
    ) {
      e.stopPropagation();
      column.index = index;
      this.setState({
        anchorEl: e.currentTarget,
        indexOpenedRowCalendar: index.toString(),
        idOpenedColumnCalendar: column,
        calendarOpenedTable: table,
      });
    }
  };

  handleCloseCalendar = () => {
    this.setState({
      anchorEl: null,
      indexOpenedRowCalendar: null,
      idOpenedColumnCalendar: null,
      calendarOpenedTable: null,
    });
  };

  selectAtDateHandler = selectedAtDate => {};

  calculateDriverDates = (data, selectedAtDate) => {
    let { driverTasks, idOpenedColumnCalendar } = this.state;
    idOpenedColumnCalendar = idOpenedColumnCalendar ? idOpenedColumnCalendar : scheduleColumns.find(s => s.id === 'lod325_start');

    return data.map(task => {
      const isInDrivers = !!driverTasks.find(f => f.id === task.id);

      if (isInDrivers) {
        const newData = {
          ...task,
          [idOpenedColumnCalendar?.id || 'lod325_start']: moment(selectedAtDate).format('YYYY-MM-DD'),
        };

        return this.calculateScheduleDates(newData, idOpenedColumnCalendar);
      }

      return task;
    });
  };

  selectAtDateHandlerSingle = (selectedAtDate, dataInfo) => {
    const indexOpenedRowCalendar = this.state.indexOpenedRowCalendar || dataInfo?.indexOpenedRowCalendar;
    const idOpenedColumnCalendar = this.state.idOpenedColumnCalendar || dataInfo?.idOpenedColumnCalendar;
    const calendarOpenedTable = this.state.calendarOpenedTable || dataInfo?.calendarOpenedTable;
    let { driverTasks } = this.state;

    if (!indexOpenedRowCalendar) {
      return;
    }
    let data = [...this.state[calendarOpenedTable]];
    // if driver mode enabled and driver tasks exist
    if (this.props.coordinationDriverMode && driverTasks?.length) {
      data = this.calculateDriverDates(data, selectedAtDate);
    }
    const changingCalendarRow = { ...data[indexOpenedRowCalendar] };
    changingCalendarRow[idOpenedColumnCalendar.id] = moment(selectedAtDate).format('YYYY-MM-DD');
    data.splice(indexOpenedRowCalendar, 1, changingCalendarRow);

    this.setState({
      [calendarOpenedTable]: data,
    });
    if (this.state.calendarOpenedTable === 'permissions') {
      this.calculatePermissionsDates(data[indexOpenedRowCalendar]);
    } else {
      data[indexOpenedRowCalendar] = this.calculateScheduleDates(data[indexOpenedRowCalendar], idOpenedColumnCalendar);
      this.setState({
        [calendarOpenedTable]: data,
      });
    }
    this.handleCloseCalendar();
    if (idOpenedColumnCalendar.id === 'construction_start') {
      this.resetAllCalendarErrors(indexOpenedRowCalendar, idOpenedColumnCalendar.id, calendarOpenedTable);
    } else {
      this.resetError(data[indexOpenedRowCalendar].position, idOpenedColumnCalendar.id, calendarOpenedTable);
    }
    this.props.phasePlanChangeTable({ table: calendarOpenedTable, data: data });
  };

  resetAllCalendarErrors = (index, name, table) => {
    const data = [...this.state[table]];
    let selectedRowErrors = { ...data[index] };

    selectedRowErrors = {
      ...selectedRowErrors,
      errors: {
        ...selectedRowErrors.errors,
        lod350_start: null,
        lod325_start: null,
        // lod300_c_start: null,
        lod300_start: null,
        lod290_start: null,
        lod200_start: null,
        construction_start: null,
      },
    };

    data.splice(index, 1, selectedRowErrors);

    this.setState({
      [table]: data,
    });
  };

  handleChangeValue = (name, index, table) => e => {
    const data = [...this.state[table]];
    let changeField = [...data[index]];
    const resetIndex = data[index].position;

    changeField = {
      ...changeField,
      [name]: e.target.value,
    };

    data.splice(index, 1, changeField);

    this.setState({
      [table]: data,
    });
    this.resetError(resetIndex, name, table);
  };

  handleRemoveRow = (row, index, table) => () => {
    const { phasePlanFieldStatus } = this.props;
    if (phasePlanFieldStatus.loading) {
      return;
    }
    const removeRow = { row: row, index: index, table: table };
    this.handleRemoveMilestone(removeRow);
  };

  handleCloseRemoveRow = () => {
    this.setState({
      removeRow: null,
    });
  };

  handleRemoveMilestone = removeRow => {
    const { index, table } = removeRow ? removeRow : this.state.removeRow;
    const data = [...this.state[table]];
    this.handleCloseRemoveRow();
    this.props.phasePlanChangeTable({ table: table, data: data });
    this.props.deletePhasePlanField({
      id: removeRow.row.id,
      table: table,
    });
    const selectedField = new SelectedFieldModel({
      row: index,
      column: 0,
      totalColumns: null,
      totalRows: null,
      editField: false,
      table: table,
      columnName: null,
      action: 'delete',
    });
    this.saveToStack(selectedField, data[index], null);
  };

  handleAddMilestone = (type: string, index: number, skipStack?) => () => {
    const { phasePlanFieldStatus } = this.props;
    if (phasePlanFieldStatus?.loading) {
      return;
    }
    const newScheduleItem = {
      construction_duration: null,
      construction_end: null,
      construction_start: null,
      lod200_duration: null,
      lod200_start: null,
      lod290_duration: null,
      lod290_start: null,
      lod300_duration: null,
      lod300_start: null,
      lod325_duration: null,
      lod325_start: null,
      lod350_duration: null,
      lod350_start: null,
      title: '',
      position: index + 1,
    };
    const newPermissionItem = {
      ufc_id: null,
      submission_date: null,
      durations: null,
      approval_date: null,
      tied_to: null,
      desc: '',
      title: '',
      position: index + 1,
    };
    const data = [...this.state[type]];
    if (type === 'schedules') {
      data.splice(index + 1, 0, newScheduleItem);
    }
    if (type === 'permissions') {
      data.splice(index + 1, 0, newPermissionItem);
    }
    this.setState({
      [type]: data,
    });
    const selectedField = new SelectedFieldModel({
      row: index + 1,
      column: 0,
      totalColumns: null,
      totalRows: null,
      editField: false,
      table: type,
      columnName: null,
      action: 'create',
    });
    if (!skipStack) {
      this.saveToStack(selectedField, null, data[index + 1]);
    }
    this.props.phasePlanChangeTable({ table: type, data: data });
    this.props.createPhasePlanField({
      project_id: this.props.phasePlanData.project_id,
      plan_id: this.props.phasePlanData.id,
      position: index + 1,
      table: type,
    });
  };

  handleChangeSelect = (name, index, table, columnIndex) => value => {
    const { selectedField } = this.state;
    const data = [...this.state[table]];
    let changeField = { ...data[index] };
    const resetIndex = data[index].position;

    changeField = {
      ...changeField,
      [name]: value,
    };

    data.splice(index, 1, changeField);

    this.setState({
      [table]: data,
    });
    this.resetError(resetIndex, name, table);
    this.setState({
      selectedField: new SelectedFieldModel({ ...selectedField, index, columnIndex, editField: false, name, action: 'update' }),
    });
  };

  resetError = (index: number, field: string, table: string) => {
    const { errors } = this.state;
    const data = [...this.state[table]].find(f => f.position === index);

    Object.keys(data).map(key => {
      if (data[key] || data[key] === 0) {
        delete errors[`${table}.${index}.${key}`];
      }
    });
    this.setState({
      errors: errors,
    });
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selectedField = new SelectedFieldModel({
      row: result.source.index,
      column: 0,
      totalColumns: null,
      totalRows: null,
      editField: false,
      table: result.source.droppableId,
      columnName: null,
      action: 'd&d',
    });
    const from = {
      id: this.state[result.source.droppableId][result.source.index].id,
      position: result.source.index,
    };
    const to = {
      id: this.state[result.source.droppableId][result.source.index].id,
      position: result.destination.index,
    };
    this.saveToStack(selectedField, from, to);
    const items = PhasePlanHelper.reorderArray([...this.state[result.source.droppableId]], result.source.index, result.destination.index);

    this.props.phasePlanChangeTable({ table: result.source.droppableId, data: items });

    this.setState({
      [result.source.droppableId]: items,
    });
  };

  getMaxCalendarDate = (
    calendarOpenedTable = this.state.calendarOpenedTable,
    idOpenedColumnCalendar = this.state.idOpenedColumnCalendar,
    indexOpenedRowCalendar = this.state.indexOpenedRowCalendar,
  ): Date => {
    const { permissions } = this.state;
    const { phasePlanData } = this.props;
    let date;
    /* Сounting dates based on adjacent dates  */
    if (calendarOpenedTable === 'permissions') {
      if (phasePlanData?.project?.target_to) {
        if (idOpenedColumnCalendar.id === 'submission_date') {
          date = new Date(
            moment(phasePlanData.project.target_to).add(-permissions[indexOpenedRowCalendar].durations, 'weeks').format('YYYY-MM-DD'),
          );
        } else {
          date = new Date(moment(phasePlanData.project.target_to).format('YYYY-MM-DD'));
        }
      } else {
        date = new Date();
      }
    } else if (calendarOpenedTable === 'schedules') {
      if (idOpenedColumnCalendar.id === 'lod290_start') {
        date = new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].lod300_start).format('YYYY-MM-DD'));
      }
      if (idOpenedColumnCalendar.id === 'lod300_start') {
        date = new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].lod325_start).format('YYYY-MM-DD'));
      }
      if (idOpenedColumnCalendar.id === 'lod325_start') {
        date = new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].lod350_start).format('YYYY-MM-DD'));
      }
      if (idOpenedColumnCalendar.id === 'lod350_start') {
        date = new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].construction_start).format('YYYY-MM-DD'));
      }
      if (idOpenedColumnCalendar.id === 'construction_end') {
        date = new Date(moment(phasePlanData.project.target_to).format('YYYY-MM-DD'));
      }
      if (
        idOpenedColumnCalendar.id === 'construction_start' &&
        phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].construction_end
      ) {
        date = new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].construction_end).format('YYYY-MM-DD'));
      }
      if (date === 'Invalid Date' || date === undefined) {
        date = new Date(moment(phasePlanData.project.target_to).format('YYYY-MM-DD'));
      }
    } else {
      if (phasePlanData?.project?.target_to) {
        date = new Date(moment(phasePlanData.project.target_to).format('YYYY-MM-DD'));
      } else {
        date = new Date();
      }
    }
    /* Сheck with project completion date  */
    if (phasePlanData.project && moment(phasePlanData.project.target_to).diff(date, 'days') < 1) {
      date = new Date(moment(phasePlanData.project.target_to).format('YYYY-MM-DD'));
    }
    return date || new Date();
  };

  getMinCalendarDate = (
    calendarOpenedTable = this.state.calendarOpenedTable,
    idOpenedColumnCalendar = this.state.idOpenedColumnCalendar,
  ): Date => {
    // const {calendarOpenedTable, idOpenedColumnCalendar} = this.state;
    const { phasePlanData } = this.props;
    if (calendarOpenedTable === 'permissions') {
      if (idOpenedColumnCalendar.id === 'submission_date') {
        return new Date(moment(phasePlanData.project.target_start).format('YYYY-MM-DD'));
      }
      if (idOpenedColumnCalendar.id === 'approval_date') {
        return new Date(moment(phasePlanData[calendarOpenedTable][idOpenedColumnCalendar.index].submission_date).format('YYYY-MM-DD'));
      }
    } else if (calendarOpenedTable === 'schedules') {
      if (phasePlanData?.project?.target_start) {
        return new Date(moment(phasePlanData.project.target_start).format('YYYY-MM-DD'));
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  };

  selectRowForDriver = (row: ScheduleModel, rowIndex: number, event) => {
    const driverTasks = [...this.state.driverTasks];
    event.stopPropagation();
    const { coordinationDriverMode } = this.props;
    if (!coordinationDriverMode) {
      return;
    }
    const isAlreadyAdded = driverTasks.findIndex(f => f.id === row.id);
    if (isAlreadyAdded + 1) {
      driverTasks.splice(isAlreadyAdded, 1);
    } else if (
      (row.lod350_start && driverTasks?.[0]?.lod350_start && moment(row.lod350_start).diff(driverTasks?.[0].lod350_start, 'days') >= 0) ||
      (!driverTasks.length && this.props.coordinationDriverMode)
    ) {
      driverTasks.push(row);
    }
    this.setState({
      driverTasks: driverTasks,
      selectedField: driverTasks.length ? {} : this.state.selectedField,
    });
    this.props.selectedDrivers(driverTasks);
  };

  handleExcelNav = (e, rowIndex, columnIndex, totalColumns, totalRows, table, columnName) => {
    const { selectedField, driverTasks } = this.state;
    const { editMode } = this.props;
    if (driverTasks?.length || !editMode) {
      return;
    }
    if (selectedField.editField && selectedField.row === rowIndex && selectedField.column === columnIndex) {
      return;
    }
    if (selectedField.editField) {
      this.saveToStack(selectedField);
    }
    this.setState({
      selectedField: {
        row: rowIndex,
        column: columnIndex,
        totalColumns: totalColumns,
        totalRows: totalRows,
        editField: false,
        table: table,
        columnName: columnName,
        action: 'update',
      },
    });
  };

  saveToStack = (selectedField: SelectedFieldModel, fromField?, to?) => {
    const { initData, editMode } = this.props;
    if (!editMode) {
      return;
    }
    const data = to || { ...this.state[selectedField.table][selectedField.row] };

    if (!data.id) {
      data.id = initData?.[selectedField.table]?.[selectedField.row]?.id || null;
    }
    this.props.saveToStack({
      ...{
        fromField: fromField !== undefined ? fromField : initData[selectedField.table][selectedField.row],
        to: to !== undefined ? to : { ...data },
        selectedField,
      },
    });
    let formData = { ...data };
    formData.table = selectedField.table;
    if (selectedField.action === 'update') {
      formData = PhasePlanHelper.convertDateToRequest(formData);
      this.props.updatePhasePlanField(formData);
    }
    if (selectedField.action === 'd&d') {
      this.props.updatePhasePlanField(formData);
    }
  };

  handleKeyDown(e) {
    const { selectedField } = this.state;
    const { handleRedo, handleUndo } = this.props;
    const { row, column, totalColumns, totalRows, editField, columnName } = selectedField;
    if (editField) {
      if (e.keyCode === 13 && row + 1 && column + 1) {
        this.saveToStack(selectedField);
        this.setState({
          selectedField: new SelectedFieldModel({
            ...selectedField,
            row,
            column,
            totalColumns,
            totalRows,
            editField: false,
            columnName,
            action: 'update',
          }),
        });
      }
      return;
    }
    if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.keyCode === 90 && e.ctrlKey) {
      handleUndo();
    }
    if (e.keyCode === 90 && e.ctrlKey && e.shiftKey) {
      handleRedo();
    }
    // Left arrow
    if (e.keyCode === 37 && column > 0) {
      this.setState({
        selectedField: new SelectedFieldModel({
          ...selectedField,
          row,
          column: column - 1,
          totalColumns,
          totalRows,
          columnName,
          action: 'update',
        }),
      });
    }
    // Top arrow
    if (e.keyCode === 38 && row > 0) {
      this.setState({
        selectedField: new SelectedFieldModel({
          ...selectedField,
          row: row - 1,
          column: column,
          totalColumns,
          totalRows,
          columnName,
          action: 'update',
        }),
      });
    }
    // Right arrow
    if (e.keyCode === 39 && column < totalColumns - 2) {
      this.setState({
        selectedField: new SelectedFieldModel({
          ...selectedField,
          row,
          column: column + 1,
          totalColumns,
          totalRows,
          columnName,
          action: 'update',
        }),
      });
    }
    // Bottom arrow
    if (e.keyCode === 40 && row < totalRows - 1) {
      this.setState({
        selectedField: new SelectedFieldModel({
          ...selectedField,
          row: row + 1,
          column,
          totalColumns,
          totalRows,
          columnName,
          action: 'update',
        }),
      });
    }
    // Enter
    if (e.keyCode === 13 && row + 1 && column + 1) {
      this.setState({
        selectedField: new SelectedFieldModel({
          ...selectedField,
          row,
          column,
          totalColumns,
          totalRows,
          editField: true,
          columnName,
          action: 'update',
        }),
      });
    }
    // Ctrl + C
    if (e.keyCode === 67 && e.ctrlKey) {
      this.copyValue();
    }
    // Ctrl + V
    if (e.keyCode === 86 && e.ctrlKey) {
      this.passValue();
    }
  }

  handleDblClick = () => {
    const { selectedField } = this.state;
    if (selectedField.editField) {
      return;
    }
    this.setState({ selectedField: { ...selectedField, editField: true, action: 'update' } });
  };

  handleChangeDate = (column, index, table, isDriver) => numberValue => {
    const { selectedField } = this.state;
    // count MM/DD/YYYY = 8
    if (numberValue.value.length !== 8) {
      return;
    }

    this.selectAtDateHandlerSingle(new Date(numberValue.formattedValue), {
      indexOpenedRowCalendar: selectedField.row,
      idOpenedColumnCalendar: column,
      calendarOpenedTable: selectedField.table,
    });
  };

  copyValue = () => {
    const { selectedField } = this.state;
    const { editMode } = this.props;
    if (!selectedField?.table || !editMode) {
      return;
    }
    const data = [...this.state[selectedField.table]];
    this.setState({
      copyValue: data[selectedField.row][selectedField.columnName],
    });
  };

  passValue = async () => {
    const { selectedField, copyValue } = this.state;
    const { editMode } = this.props;
    const { columnName } = selectedField;
    if (copyValue == undefined || copyValue == null || !editMode) {
      return;
    }
    if (
      columnName === 'lod350_start' ||
      columnName === 'lod325_start' ||
      columnName === 'lod300_start' ||
      columnName === 'lod290_start' ||
      columnName === 'lod200_start'
    ) {
      const minDate = this.getMinCalendarDate(selectedField.table, { id: selectedField.columnName, index: selectedField.row });
      const maxDate = this.getMaxCalendarDate(
        selectedField.table,
        { id: selectedField.columnName, index: selectedField.row },
        selectedField.row,
      );
      if (!moment(copyValue).isBetween(minDate, maxDate)) {
        return;
      }
    }
    const data = [...this.state[selectedField.table]];

    const newFieldValue = {
      ...data[selectedField.row],
      [selectedField.columnName]: copyValue,
    };

    data.splice(selectedField.row, 1, newFieldValue);

    this.saveToStack(selectedField, undefined, data[selectedField.row]);

    await this.setState({
      [selectedField.table]: data,
    });
  };

  handleOpenListColor = e => {
    const { selectedField } = this.state;
    const { editMode } = this.props;
    if (selectedField.editField || !editMode) {
      return;
    }
    this.setState({
      selectedField: { ...selectedField, editField: true, action: 'update' },
      anchorListColor: e.currentTarget,
    });
  };

  handleCloseListColor = () => {
    const { selectedField } = this.state;
    this.setState({
      anchorListColor: null,
      selectedField: {
        ...selectedField,
        editField: false,
      },
    });
  };

  handleSelectColor = colorIndex => {
    const { selectedField } = this.state;
    const data = [...this.state[selectedField.table]];

    const newFieldData = {
      ...data[selectedField.row],
      color: colorIndex,
    };

    data.splice(selectedField.row, 1, newFieldData);

    this.setState(
      {
        [selectedField.table]: data,
        anchorListColor: null,
        selectedField: {
          ...selectedField,
          editField: false,
        },
      },
      () => {
        this.saveToStack(selectedField);
      },
    );
  };

  handleCloseSelect = async e => {
    const { selectedField } = this.state;
    await this.setState({
      selectedField: {
        ...selectedField,
        editField: false,
      },
    });
    this.saveToStack(selectedField);
  };

  render() {
    const {
      errors,
      schedules,
      anchorEl,
      indexOpenedRowCalendar,
      idOpenedColumnCalendar,
      permissions,
      removeRow,
      calendarOpenedTable,
      isSavePhasePlan,
      driverTasks,
      selectedField,
      anchorListColor,
    } = this.state;

    const {
      editableTableRef,
      wrapTableRef,
      phasePlanSelect,
      tableRef,
      editMode,
      isOpenCollapse,
      coordinationDriverMode,
      handleCollapse,
      isExistScrollX,
      phasePlanFieldStatus,
      isActivePhasePlan,
    } = this.props;

    return (
      <>
        {isActivePhasePlan ? (
          <TablePlanView
            isOpenCollapse={isOpenCollapse}
            errors={errors}
            schedules={schedules}
            permissions={permissions}
            handleChangeNumber={this.handleChangeNumber}
            handleOpenCalendar={this.handleOpenCalendar}
            anchorEl={anchorEl}
            indexOpenedRowCalendar={indexOpenedRowCalendar}
            handleCloseCalendar={this.handleCloseCalendar}
            selectAtDateHandler={this.selectAtDateHandler}
            selectAtDateHandlerSingle={this.selectAtDateHandlerSingle}
            handleChangeValue={this.handleChangeValue}
            editableTableRef={editableTableRef}
            wrapTableRef={wrapTableRef}
            handleRemoveRow={this.handleRemoveRow}
            idOpenedColumnCalendar={idOpenedColumnCalendar}
            handleCloseRemoveRow={this.handleCloseRemoveRow}
            removeRow={removeRow}
            handleCloseRemoving={this.handleCloseRemoveRow}
            handleOpenRemoving={this.handleRemoveRow}
            handleRemoveMilestone={this.handleRemoveMilestone}
            handleAddMilestone={this.handleAddMilestone}
            calendarOpenedTable={calendarOpenedTable}
            isSavePhasePlan={isSavePhasePlan}
            phasePlanSelect={phasePlanSelect}
            handleChangeSelect={this.handleChangeSelect}
            tableRef={tableRef}
            editMode={editMode}
            onDragEnd={this.onDragEnd}
            getMaxCalendarDate={this.getMaxCalendarDate}
            getMinCalendarDate={this.getMinCalendarDate}
            coordinationDriverMode={coordinationDriverMode}
            selectRowForDriver={this.selectRowForDriver}
            driverTasks={driverTasks}
            handleCollapse={handleCollapse}
            isExistScrollX={isExistScrollX}
            handleExcelNav={this.handleExcelNav}
            selectedField={selectedField}
            handleDblClick={this.handleDblClick}
            handleChangeDate={this.handleChangeDate}
            handleOpenListColor={this.handleOpenListColor}
            handleCloseListColor={this.handleCloseListColor}
            anchorListColor={anchorListColor}
            handleSelectColor={this.handleSelectColor}
            phasePlanFieldStatus={phasePlanFieldStatus}
            handleCloseSelect={this.handleCloseSelect}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ phasePlan, userReducer }) => {
  return {
    phasePlanData: phasePlan.phasePlan,
    phasePlanSelect: phasePlan.phasePlanSelect,
    phasePlanLoading: phasePlan.loading,
    removeScheduleStatus: phasePlan.removeScheduleStatus,
    removePermitStatus: phasePlan.removePermitStatus,
    userData: userReducer,
    updatePhasePlanStatus: phasePlan.updatePhasePlanStatus,
    phasePlanErrors: phasePlan.phasePlanErrors,
    initData: phasePlan.initData,
    phasePlanFieldStatus: phasePlan.phasePlanFieldStatus,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      savePhasePlan,
      phasePlanChangeTable,
      createPhasePlanField,
      updatePhasePlanField,
      deletePhasePlanField,
      setDriverTasks,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TablePlanContainer);
