import { useEffect, useState } from 'react';
import Api from '../../../../service/api';
import { useAppSelector } from '../../../../store/configure/configureStore';

export const useCommentStatus = (status: typeof Api.initialStatus, closeCallback) => {
  useEffect(() => {
    if (status.loaded) {
      closeCallback();
    }
  }, [status]);
  return null;
};
