import React from 'react';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { PCDApi } from '../../../../../service/Api/pcd/types';

import './BuildingItemStyles.scss';

interface IProps {
  building: PCDApi.PCDListBuildingInterface;
  handleBuildingItemClick?: (building: PCDApi.PCDListBuildingInterface) => void;
}

const BuildingItem: React.FC<IProps> = ({ building, handleBuildingItemClick }) => {
  const themeClass = useGetThemeClass('b-buildingItem');

  return (
    <div className={`${themeClass}_container`}>
      <div className={`${themeClass}_item`} onClick={() => handleBuildingItemClick(building)}>
        <div className={`${themeClass}_item_title`}>{building.title}</div>
        <div className={`${themeClass}_item_icon`}>
          <TokenIcon iconName={building.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
        </div>
      </div>
    </div>
  );
};

export default BuildingItem;
