import React from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { MiniLoader } from '../../MiniLoader';
import cn from 'classnames';
import ChipsDataType from '../../Chips/ChipsDataType/ChipsDataType';
import StatusNFsnippet from '../../Status/StatusNFsnippet/StatusNFsnippet';

import './SegmentedButtonStyles.scss';

interface SegmentedButtonValueProps {
  id: string;
  title: string;
  count?: number;
  disabled?: boolean;
  loading?: boolean;
  labelColor?: 'yellow' | 'green' | 'blue';
  labelType?: 'input' | 'output' | 'communication';
  statusType?: 'new' | 'upd';
}

interface SegmentedButtonProps {
  buttons: SegmentedButtonValueProps[];
  selectedButtonId: string;
  changeHandler: (buttonId: string) => void;
  disabled?: boolean;
  loading?: boolean;
  isMobile?: boolean;
  customClassName?: string;
}

const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  buttons,
  selectedButtonId,
  changeHandler,
  disabled,
  loading,
  isMobile,
  customClassName,
}) => {
  const themeClass = useGetThemeClass('b-segmentedButton');

  const handleChangeTab = (e: Event, tabId: string, isDisabled: boolean) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    changeHandler && changeHandler(tabId);
  };

  return (
    <>
      <div className={`${themeClass}`}>
        {buttons?.length &&
          buttons.map((tab, index, { length }) => {
            const isLast = index + 1 === length;
            const isSelected = tab.id === selectedButtonId;
            const tabCount = tab.count ? tab.count : '';
            const isLoading = tab.loading || loading;
            const isDisabled = tab.disabled || disabled || isLoading;

            return (
              <>
                <div
                  key={`segmentButton-${tab.id}-${index}`}
                  className={cn(`${themeClass}_tab`, {
                    ['-selected']: isSelected,
                    ['-loading']: isLoading,
                    [`-mobile`]: isMobile,
                    [`${customClassName}`]: customClassName,
                  })}
                  onClick={e => handleChangeTab(e, tab.id, isDisabled)}
                >
                  <span className={cn(`${themeClass}_tab_title`, { [`-mobile`]: isMobile })}>{tab.title}</span>
                  {tabCount ? <span className={cn(`${themeClass}_tab_count`, { [`-mobile`]: isMobile })}>{tabCount}</span> : null}
                  {isLoading ? (
                    <MiniLoader
                      size={'sm'}
                      containerClasses={`${themeClass}_tab_loader`}
                      circleClasses={`${themeClass}_tab_loader_circle`}
                    />
                  ) : null}
                  {(tab.statusType || tab.labelType) && (
                    <div className={`${themeClass}_tab_label`}>
                      {tab.labelType && <ChipsDataType type={tab.labelType} size={'sm'} color={tab.labelColor} />}
                    </div>
                  )}
                </div>
                {!isLast && <div key={tab.id} className={`${themeClass}_divider`} />}
              </>
            );
          })}
      </div>
    </>
  );
};

export default SegmentedButton;
