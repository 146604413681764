import React, { Component } from 'react';

class Update_20211125_11 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4f21cde1fe55004e062911616fdba2f9/images/image10.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20211125_11;
