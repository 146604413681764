import React, { FC, MouseEvent } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';

import './ChipsRevisionTagStyles.module.scss';

const ChipsRevisionTag: FC<{
  processStatus: 'issued' | 'superseded' | 'not_issued_yet' | 'default';
  value: string;
  clickHandler?: (e: MouseEvent) => void;
  isInvalid?: boolean;
  isSubmittal?: boolean;
  customClassName?: string;
  isRedLabel?: boolean;
  isMobile?: boolean;
}> = ({ processStatus, value, clickHandler, isInvalid, isSubmittal, customClassName, isRedLabel, isMobile }) => {
  const themeClass = useGetThemeClass('b-chipsRevisionTag');

  const handleClick = (e: MouseEvent) => {
    if (clickHandler) {
      e.stopPropagation();
      clickHandler(e);
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={cn(`${themeClass} ${processStatus}`, {
          ['-invalid']: isInvalid,
          ['-submittal']: isSubmittal,
          ['-red']: isRedLabel,
          ['-mobile']: isMobile,
          [`${customClassName}`]: customClassName,
        })}
      >
        <div className={'label_text'}>{value}</div>
      </div>
    </>
  );
};

export default React.memo(ChipsRevisionTag);
