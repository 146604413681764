import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { DataGridHandle } from 'react-data-grid';
import DraggleScroll from '../../../NeedsList/new/controls/DraggbleScroll';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { BackgroundValues, columnTooltipsTextArr } from '../../../../../constants/reportingConstatns';
import ReactHtmlParser from 'react-html-parser';
import { DataGridBasic } from '../../../DataGridBasic';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import SkeletonGrid from '../../../../controls/SkeletonGrid/SkeletonGrid';
import cn from 'classnames';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';

import './ReportingGridStyles.scss';

type GroupVisibleType = {
  general: { is_visible: boolean };
  requester: { is_visible: boolean };
  responder: { is_visible: boolean };
  deliverable: { is_visible: boolean };
};

type SelectItemType = {
  group_column: string;
  id: number;
  nf_status: string[];
  due_date_change: number[];
  name: string;
  isRowNum: boolean;
};

type SortColumnType = {
  name: string;
  order_dir: string;
  idx: number;
};

const ReportingGrid: FC<{
  data: any;
  data_min_max: any;
  isAllCompanies: boolean;
  needsListFilters: (data) => void;
  isLoading: boolean;
}> = ({ data, data_min_max, isAllCompanies, needsListFilters, isLoading }) => {
  const { setUrlReportingCompany, setUrlReportingUser } = useRoutingHelper();

  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);
  const [rowsOrig, setRowsOrig] = useState(null);
  const groupVisible = {
    general: { is_visible: false },
    requester: { is_visible: false },
    responder: { is_visible: false },
    deliverable: { is_visible: false },
  };
  const [gridColumnsVisible, setGridColumnsVisible] = useState<GroupVisibleType>(groupVisible);
  const [onSortColumn, setOnSortColumn] = useState<SortColumnType>(null);
  const [selectItem, setSelectItem] = useState<SelectItemType>(null);
  const firstColumnRef = useRef<DataGridHandle>();
  const themeClass = useGetThemeClass('b-table_grid_report');

  const [collapseButtonTitle, setCollapseButtonTitle] = useState('Expand all');

  useEffect(() => {
    if (
      gridColumnsVisible.general.is_visible &&
      gridColumnsVisible.requester.is_visible &&
      gridColumnsVisible.responder.is_visible &&
      gridColumnsVisible.deliverable.is_visible
    ) {
      setCollapseButtonTitle('Collapse all');
      return;
    } else if (
      !gridColumnsVisible.general.is_visible &&
      !gridColumnsVisible.requester.is_visible &&
      !gridColumnsVisible.responder.is_visible &&
      !gridColumnsVisible.deliverable.is_visible
    ) {
      setCollapseButtonTitle('Expand all');
      return;
    }
  }, [gridColumnsVisible]);

  const changeAccordionCollapse = () => {
    if (collapseButtonTitle === 'Expand all') {
      setGridColumnsVisible({
        general: { is_visible: true },
        requester: { is_visible: true },
        responder: { is_visible: true },
        deliverable: { is_visible: true },
      });

      setCollapseButtonTitle('Collapse all');
    } else {
      setGridColumnsVisible({
        general: { is_visible: false },
        requester: { is_visible: false },
        responder: { is_visible: false },
        deliverable: { is_visible: false },
      });

      setCollapseButtonTitle('Expand all');
    }
  };

  const handleSelect = (
    group_column: string,
    id: number,
    nf_status: string[],
    due_date_change: number[],
    name: string,
    isRowNum: boolean,
  ) => {
    setSelectItem({
      group_column: group_column,
      id: id,
      nf_status: nf_status,
      due_date_change: due_date_change,
      name: name,
      isRowNum: isRowNum ? isRowNum : false,
    });
  };

  const createFilters = select => {
    if (!select.group_column) {
      const request_name = isAllCompanies ? 'request_party' : 'users';
      const response_name = isAllCompanies ? 'response_party' : 'response_managers';
      const data = {
        formData: {
          [request_name]: [{ id: select.id, value: select.id }],
        },
        formDataOrFilters: {
          [response_name]: [{ id: select.id, value: select.id }],
        },
      };
      return data;
    } else {
      const name =
        select.group_column === 'general'
          ? isAllCompanies
            ? 'bic_party'
            : 'bic_manager'
          : select.group_column === 'requester'
          ? isAllCompanies
            ? 'request_party'
            : 'users'
          : select.group_column === 'responder'
          ? isAllCompanies
            ? 'response_party'
            : 'response_managers'
          : 'no';
      const filter_name = select.nf_status ? 'nf_status' : 'due_date_change';
      const filter = select.nf_status ? select.nf_status : select.due_date_change;
      const formattedFilter = filter.map(item => {
        return { id: item, value: item };
      });
      const data = {
        formData: {
          [name]: [{ id: select.id, value: select.id }],
          [filter_name]: formattedFilter,
        },
      };
      return data;
    }
  };

  useEffect(() => {
    if (selectItem) {
      if (selectItem.isRowNum) {
        const filters = createFilters(selectItem);
        needsListFilters(filters);
      } else {
        if (isAllCompanies) {
          setUrlReportingCompany(selectItem.id);
        } else {
          setUrlReportingUser(selectItem.id);
        }
      }
    }
  }, [selectItem]);

  const addHoverClass = (e, class_group) => {
    const groupCells = e.currentTarget.parentElement.parentElement.parentElement.getElementsByClassName(class_group);
    if (groupCells.length) {
      groupCells[0].classList.add('group_hover');
    }
  };

  const removeHoverClass = e => {
    const groupCells: HTMLCollection = e.currentTarget.parentElement.parentElement.parentElement.querySelectorAll('.group_hover');
    if (groupCells.length) {
      groupCells[0].classList.remove('group_hover');
    }
  };

  const renderMainHeaderCell = p => {
    return (
      <div style={{ width: '100%' }}>
        <div className={'headerCellFirstName'}></div>
        <div className={'headerCellSecond -noBorderRight'} onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}>
          {p.column.name}
          {p.column.order_dir && (
            <div className={'arrowContainer'}>
              <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderHeaderCell = (p, tooltipTitle) => {
    let backgroundColor: string;
    if (p.column.resizableName === 'general') {
      backgroundColor = `${BackgroundValues[0]}`;
    }

    if (p.column.resizableName === 'requester') {
      backgroundColor = `${BackgroundValues[1]}`;
    }

    if (p.column.resizableName === 'responder') {
      backgroundColor = `${BackgroundValues[2]}`;
    }

    if (p.column.resizableName === 'deliverable') {
      backgroundColor = `${BackgroundValues[3]}`;
    }

    return (
      <div className={`${themeClass}__groupHeaderBorderTop`} style={{ width: '100%' }}>
        <div
          className={'headerCellFirst'}
          onClick={() => clickVisible(p.column.resizableName)}
          onMouseEnter={e => addHoverClass(e, p.column.resizableName)}
          onMouseLeave={e => removeHoverClass(e)}
          style={{ background: `${backgroundColor}` }}
        ></div>
        <CustomTooltip enterDelay={300} leaveDelay={0} title={<div>{ReactHtmlParser(tooltipTitle)}</div>} placement={'top'}>
          <div>
            <div
              className={cn('headerCellSecond', {
                ['-noBorderRight']:
                  p.column.name === 'Average time in court' ||
                  p.column.name === 'Avg. time in court' ||
                  p.column.name === 'Unaccepted responses' ||
                  p.column.name === 'Avg. overdue time' ||
                  p.column.name === 'Declined percentage',
              })}
              onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}
            >
              {p.column.name}
              {p.column.order_dir && (
                <div className={'arrowContainer'}>
                  <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
                </div>
              )}
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const renderHeaderCellGeneralStatistics = (p, tooltipTitle) => {
    return (
      <div className={`${themeClass}__headerColumn`}>
        <div className={`${themeClass}__headerExpander`}>
          <CustomButton
            type={'text-plain'}
            size={'sm'}
            clickHandler={() => clickVisible('general')}
            title={'General statistics'}
            icon={
              <TokenIcon
                customClass={`${themeClass}__headerIcon`}
                iconName={gridColumnsVisible.general.is_visible ? 'dash' : 'plus'}
                size={12}
              />
            }
          />
        </div>
        <CustomTooltip enterDelay={300} leaveDelay={0} title={tooltipTitle || ''} placement={'top'}>
          <div className={`${themeClass}__groupHeaderBorderLeft ${themeClass}__groupHeaderBorderTop`}>
            <div className={'headerCellGroupSecond'} onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}>
              {p.column.name}
              {p.column.order_dir && (
                <div className={'arrowContainer'}>
                  <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
                </div>
              )}
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const renderHeaderCellRequestParty = (p, tooltipTitle) => {
    return (
      <div className={`${themeClass}__headerColumn`}>
        <div className={`${themeClass}__headerExpander`}>
          <CustomButton
            type={'text-plain'}
            size={'sm'}
            clickHandler={() => clickVisible('requester')}
            title={'Requester statistics'}
            icon={
              <TokenIcon
                customClass={`${themeClass}__headerIcon`}
                iconName={gridColumnsVisible.requester.is_visible ? 'dash' : 'plus'}
                size={12}
              />
            }
          />
        </div>
        <CustomTooltip enterDelay={300} leaveDelay={0} title={tooltipTitle || ''} placement={'top'}>
          <div className={`${themeClass}__groupHeaderBorderLeft ${themeClass}__groupHeaderBorderTop`}>
            <div className={'headerCellGroupSecond'} onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}>
              {p.column.name}
              {p.column.order_dir && (
                <div className={'arrowContainer'}>
                  <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
                </div>
              )}
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const renderHeaderCellResponseParty = (p, tooltipTitle) => {
    return (
      <div className={`${themeClass}__headerColumn`}>
        <div className={`${themeClass}__headerExpander`}>
          <CustomButton
            type={'text-plain'}
            size={'sm'}
            clickHandler={() => clickVisible('responder')}
            title={'Responder statistics'}
            icon={
              <TokenIcon
                customClass={`${themeClass}__headerIcon`}
                iconName={gridColumnsVisible.responder.is_visible ? 'dash' : 'plus'}
                size={12}
              />
            }
          />
        </div>
        <CustomTooltip enterDelay={300} leaveDelay={0} title={tooltipTitle || ''} placement={'top'}>
          <div className={`${themeClass}__groupHeaderBorderLeft ${themeClass}__groupHeaderBorderTop`}>
            <div className={'headerCellGroupSecond'} onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}>
              {p.column.name}
              {p.column.order_dir && (
                <div className={'arrowContainer'}>
                  <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
                </div>
              )}
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const renderHeaderCellDeliverable = (p, tooltipTitle) => {
    return (
      <div className={`${themeClass}__headerColumn`}>
        <div className={`${themeClass}__headerExpander`}>
          <CustomButton
            type={'text-plain'}
            size={'sm'}
            clickHandler={() => clickVisible('deliverable')}
            title={'Deliverable statistics'}
            icon={
              <TokenIcon
                customClass={`${themeClass}__headerIcon`}
                iconName={gridColumnsVisible.deliverable.is_visible ? 'dash' : 'plus'}
                size={12}
              />
            }
          />
        </div>
        <CustomTooltip enterDelay={300} leaveDelay={0} title={tooltipTitle || ''} placement={'top'}>
          <div className={`${themeClass}__groupHeaderBorderLeft ${themeClass}__groupHeaderBorderTop`}>
            <div className={'headerCellGroupSecond'} onClick={() => handleOnSort(p.column.key, p.column.order_dir, p.column.idx)}>
              {p.column.name}
              {p.column.order_dir && (
                <div className={'arrowContainer'}>
                  <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
                </div>
              )}
            </div>
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const setCellClass = p => {
    return 'rgb_text_align';
  };

  const setCellMinMaxRedGreenClass = (name, value, data) => {
    /*if (data?.max) {
      if (value === data.max[name]) {
        return 'rgb_text_align rgb_text_red';
      }
      if (value === data.min[name]) {
        return 'rgb_text_align rgb_text_green';
      }
    }*/
    return 'rgb_text_align';
  };

  const setCellMinMaxGreenRedClass = (name, value, data) => {
    /*if (data?.max) {
      if (value === data.max[name]) {
        return 'rgb_text_align rgb_text_green';
      }
      if (value === data.min[name]) {
        return 'rgb_text_align rgb_text_red';
      }
    }*/
    return 'rgb_text_align';
  };

  const onColumnResize = (idx: number, width: number, gridColumns: any[]) => {
    const g = { ...gridColumnsVisible };
    if (gridColumns[idx].resizableName === 'general') {
      g['general'].is_visible = !g['general'].is_visible;
    }
    if (gridColumns[idx].resizableName === 'requester') {
      g['requester'].is_visible = !g['requester'].is_visible;
    }
    if (gridColumns[idx].resizableName === 'responder') {
      g['responder'].is_visible = !g['responder'].is_visible;
    }
    if (gridColumns[idx].resizableName === 'deliverable') {
      g['deliverable'].is_visible = !g['deliverable'].is_visible;
    }
    setGridColumnsVisible(g);
  };

  const onSubmit = useCallback(
    debounce((idx: number, width: number, gridColumns: any[]) => {
      onColumnResize(idx, width, gridColumns);
    }, 500),
    [onColumnResize],
  );

  const renderRowNumFormatter = p => {
    return (
      <>
        <div className={'rgb_hyperlinks rgb_text_color'} onClick={() => handleSelect('', p.row.id, null, null, p.row.title, true)}>
          {p.rowIdx + 1}
        </div>
      </>
    );
  };

  const renderCompanyFormatter = p => {
    return (
      <>
        <div className={'rgb_company_name rgb_text_color'} onClick={() => handleSelect('', p.row.id, null, null, p.row.title, false)}>
          {p.row.title}
        </div>
      </>
    );
  };

  const renderDataFormatter = (p, isFirst?: boolean) => {
    const columnName = p.column.key;
    return (
      <div className={isFirst ? `${themeClass}__groupCellBorderLeft` : ''}>
        <span
          className={'rgb_hyperlinks rgb_text_color'}
          onClick={() =>
            handleSelect(
              p.column.resizableName,
              p.row.id,
              p.column?.nf_status || null,
              p.column?.due_date_change || null,
              p.row.title,
              true,
            )
          }
        >
          {p.row[columnName]}
        </span>
      </div>
    );
  };

  const renderPercentageFormatter = p => {
    const columnName = p.column.key;
    return <div className={'rgb_text_color '}>{p.row[columnName]}%</div>;
  };

  const renderFormatter = (p, isFirst?: boolean) => {
    const columnName = p.column.key;
    return <div className={`rgb_text_color ${isFirst ? `${themeClass}__groupCellBorderLeft` : ''}`}>{p.row[columnName]}</div>;
  };

  const renderAverageFormatter = p => {
    const columnName = p.column.key;
    const hoursToString = val => {
      const days = Math.floor(val / 24);
      const hours = Math.floor(val % 24);
      let text = '';
      if (days != 0) {
        text = `${days}d`;
      }
      if (hours != 0) {
        text = text + ' ' + hours + 'h';
      }
      text = text !== '' ? text : '0';
      return text;
    };
    return <div className={'rgb_text_color '}>{hoursToString(p.row[columnName])}</div>;
  };

  const handleOnSort = (name, order_dir, idx) => {
    const obj = {
      name: name,
      order_dir: order_dir,
      idx: idx,
    };
    setOnSortColumn(obj);
  };

  const clickVisible = resizableName => {
    const g = { ...gridColumnsVisible };
    if (resizableName === 'general') {
      g['general'].is_visible = !g['general'].is_visible;
    }
    if (resizableName === 'requester') {
      g['requester'].is_visible = !g['requester'].is_visible;
    }
    if (resizableName === 'responder') {
      g['responder'].is_visible = !g['responder'].is_visible;
    }
    if (resizableName === 'deliverable') {
      g['deliverable'].is_visible = !g['deliverable'].is_visible;
    }
    setGridColumnsVisible(g);
  };

  const getTooltipText = (key: string): string => {
    return columnTooltipsTextArr.find(item => item.key === key).tooltipText;
  };

  const findColumnOrder = key => {
    if (!key) {
      return '';
    }
    const indx = columns?.findIndex(item => item.key === key);
    if (indx === -1) {
      if (onSortColumn.order_dir === '' && onSortColumn.name === key) {
        return 'desc';
      } else if (onSortColumn.order_dir === 'desc' && onSortColumn.name === key) {
        return 'asc';
      } else {
        return '';
      }
    } else {
      return columns[indx].order_dir;
    }
  };

  const get_columns_general = (isAllColumns: boolean) => {
    if (isAllColumns) {
      const columns_general = [
        {
          key: 'bic',
          name: 'In court',
          width: 120,
          maxWidth: 120,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellGeneralStatistics(p, getTooltipText('bic')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft general`,
          cellClass: p => setCellMinMaxRedGreenClass('bic', p.bic, p.data_min_max),
        },
        {
          key: 'bic_to_commit',
          name: 'To respond',
          width: 95,
          maxWidth: 95,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic_to_commit') : '',
          nf_status: ['sent'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('bic_to_commit')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('bic_to_commit', p.bic_to_commit, p.data_min_max),
        },
        {
          key: 'bic_to_provide',
          name: 'Commitments',
          width: 110,
          maxWidth: 110,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic_to_provide') : '',
          nf_status: ['submitted'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('bic_to_provide')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('bic_to_provide', p.bic_to_provide, p.data_min_max),
        },
        {
          key: 'bic_to_accept',
          name: 'To accept',
          width: 90,
          maxWidth: 90,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic_to_accept') : '',
          nf_status: ['provided'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('bic_to_accept')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('bic_to_accept', p.bic_to_accept, p.data_min_max),
        },
        {
          key: 'bic_to_resubmit',
          name: 'Declined',
          width: 80,
          maxWidth: 80,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic_to_resubmit') : '',
          nf_status: ['declined'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('bic_to_resubmit')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('bic_to_resubmit', p.bic_to_resubmit, p.data_min_max),
        },
        {
          key: 'bic_due_date',
          name: 'Due date change',
          width: 130,
          maxWidth: 130,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic_due_date') : '',
          due_date_change: [1],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('bic_due_date')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('bic_due_date', p.bic_due_date, p.data_min_max),
        },
        {
          key: 'resp_company_change',
          name: 'Responder change',
          width: 145,
          maxWidth: 145,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('resp_company_change') : '',
          due_date_change: [1],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('resp_company_change')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('resp_company_change', p.resp_company_change, p.data_min_max),
        },
        {
          key: 'avg_court_time',
          name: 'Avg. time in court',
          width: 137,
          minWidth: 137,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('avg_court_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('avg_court_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('avg_court_time', p.avg_court_time, p.data_min_max),
        },
      ];
      return columns_general;
    } else {
      const columns_general = [
        {
          key: 'bic',
          name: 'In court',
          width: 120,
          maxWidth: 120,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('bic') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellGeneralStatistics(p, getTooltipText('bic')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft general`,
          cellClass: p => setCellMinMaxRedGreenClass('bic', p.bic, p.data_min_max),
        },
        {
          key: 'avg_court_time',
          name: 'Average time in court',
          width: 160,
          minWidth: 160,
          resizableName: 'general',
          order_dir: columns ? findColumnOrder('avg_court_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('avg_court_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('avg_court_time', p.avg_court_time, p.data_min_max),
        },
      ];
      return columns_general;
    }
  };

  const get_columns_requester = (isAllColumns: boolean) => {
    if (isAllColumns) {
      const columns_requester = [
        {
          key: 'requester_total',
          name: 'Total',
          width: 138,
          maxWidth: 138,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_total') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellRequestParty(p, getTooltipText('requester_total')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft requester`,
          cellClass: p => setCellMinMaxGreenRedClass('requester_total', p.requester_total, p.data_min_max),
        },
        {
          key: 'requester_sent',
          name: 'Sent',
          width: 80,
          maxWidth: 80,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_sent') : '',
          nf_status: ['sent'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_sent')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('requester_sent', p.requester_sent, p.data_min_max),
        },
        {
          key: 'requester_provided',
          name: 'To accept',
          width: 85,
          maxWidth: 85,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_provided') : '',
          nf_status: ['provided'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_provided')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_provided', p.requester_provided, p.data_min_max),
        },
        {
          key: 'requester_declined',
          name: 'Declined',
          width: 80,
          maxWidth: 80,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_declined') : '',
          nf_status: ['declined'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_declined')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_declined', p.requester_declined, p.data_min_max),
        },
        {
          key: 'requester_accepted',
          name: 'Accepted',
          width: 83,
          maxWidth: 83,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_accepted') : '',
          nf_status: ['accepted'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_accepted')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('requester_accepted', p.requester_accepted, p.data_min_max),
        },
        {
          key: 'requester_declined_rate',
          name: '% Declined',
          width: 93,
          maxWidth: 93,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_declined_rate') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_declined_rate')),
          formatter: p => renderPercentageFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_declined_rate', p.requester_declined_rate, p.data_min_max),
        },
        {
          key: 'requester_deactivated',
          name: 'Deactivated',
          width: 98,
          maxWidth: 98,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_deactivated') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_deactivated')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_deactivated', p.requester_deactivated, p.data_min_max),
        },
        {
          key: 'requester_avg_open_time',
          name: 'Avg. open time',
          width: 120,
          maxWidth: 120,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_avg_open_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_avg_open_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_avg_open_time', p.requester_avg_open_time, p.data_min_max),
        },
        {
          key: 'requester_deactivated_rate',
          name: '% Deactivated',
          width: 115,
          maxWidth: 115,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_deactivated_rate') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_deactivated_rate')),
          formatter: p => renderPercentageFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_deactivated_rate', p.requester_deactivated_rate, p.data_min_max),
        },
        {
          key: 'requester_avg_ovd_time',
          name: 'Avg. overdue time',
          width: 140,
          minWidth: 140,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_avg_ovd_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_avg_ovd_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_avg_ovd_time', p.requester_avg_ovd_time, p.data_min_max),
        },
      ];
      return columns_requester;
    } else {
      const columns_requester = [
        {
          key: 'requester_total',
          name: 'Total',
          width: 138,
          maxWidth: 138,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_total') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellRequestParty(p, getTooltipText('requester_total')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft requester collapsed`,
          cellClass: p => setCellMinMaxGreenRedClass('requester_total', p.requester_total, p.data_min_max),
        },
        {
          key: 'requester_provided',
          name: 'Unaccepted responses',
          width: 170,
          maxWidth: 170,
          resizableName: 'requester',
          order_dir: columns ? findColumnOrder('requester_provided') : '',
          nf_status: ['provided'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('requester_provided')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('requester_provided', p.requester_provided, p.data_min_max),
        },
      ];
      return columns_requester;
    }
  };

  const get_columns_responder = (isAllColumns: boolean) => {
    if (isAllColumns) {
      const columns_responder = [
        {
          key: 'responder_total',
          name: 'Total',
          width: 142,
          maxWidth: 142,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_total') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellResponseParty(p, getTooltipText('responder_total')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft responder`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_total', p.responder_total, p.data_min_max),
        },
        {
          key: 'responder_sent',
          name: 'Sent',
          width: 80,
          maxWidth: 80,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_sent') : '',
          nf_status: ['sent'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_sent')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_sent', p.responder_sent, p.data_min_max),
        },
        {
          key: 'responder_submitted',
          name: 'Commitments',
          width: 110,
          maxWidth: 110,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_submitted') : '',
          nf_status: ['submitted'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_submitted')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_submitted', p.responder_submitted, p.data_min_max),
        },
        {
          key: 'responder_provided',
          name: 'Responded',
          width: 95,
          maxWidth: 95,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_provided') : '',
          nf_status: ['provided'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_provided')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_provided', p.responder_provided, p.data_min_max),
        },
        {
          key: 'responder_declined',
          name: 'Declined',
          width: 80,
          maxWidth: 80,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_declined') : '',
          nf_status: ['declined'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_declined')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_declined', p.responder_declined, p.data_min_max),
        },
        {
          key: 'responder_accepted',
          name: 'Accepted',
          width: 83,
          maxWidth: 83,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_accepted') : '',
          nf_status: ['accepted'],
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_accepted')),
          formatter: p => renderDataFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_accepted', p.responder_accepted, p.data_min_max),
        },
        {
          key: 'responder_declined_rate',
          name: '% Declined',
          width: 93,
          maxWidth: 93,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_declined_rate') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_declined_rate')),
          formatter: p => renderPercentageFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_declined_rate', p.responder_declined_rate, p.data_min_max),
        },
        {
          key: 'responder_revision_count',
          name: 'Avg. revisions',
          width: 110,
          maxWidth: 110,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_revision_count') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_revision_count')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_revision_count', p.responder_revision_count, p.data_min_max),
        },
        {
          key: 'responder_deactivated',
          name: 'Deactivated',
          width: 98,
          maxWidth: 98,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_deactivated') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_deactivated')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_deactivated', p.responder_deactivated, p.data_min_max),
        },
        {
          key: 'responder_avg_response_time',
          name: 'Avg. response time',
          width: 143,
          maxWidth: 143,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_avg_response_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_avg_response_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_avg_response_time', p.responder_avg_response_time, p.data_min_max),
        },
        {
          key: 'responder_avg_ovd_time',
          name: 'Avg. overdue time',
          width: 138,
          minWidth: 138,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_avg_ovd_time') : '',
          formatter: p => renderAverageFormatter(p),
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_avg_ovd_time')),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_avg_ovd_time', p.responder_avg_ovd_time, p.data_min_max),
        },
      ];
      return columns_responder;
    } else {
      const columns_responder = [
        {
          key: 'responder_total',
          name: 'Total',
          width: 142,
          maxWidth: 142,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_total') : '',
          nf_status: ['sent', 'submitted', 'provided', 'accepted', 'declined'],
          headerRenderer: p => renderHeaderCellResponseParty(p, getTooltipText('responder_total')),
          formatter: p => renderDataFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft responder`,
          cellClass: p => setCellMinMaxGreenRedClass('responder_total', p.responder_total, p.data_min_max),
        },
        {
          key: 'responder_declined_rate',
          name: 'Declined percentage',
          width: 155,
          maxWidth: 155,
          resizableName: 'responder',
          order_dir: columns ? findColumnOrder('responder_declined_rate') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('responder_declined_rate')),
          formatter: p => renderPercentageFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell responder`,
          cellClass: p => setCellMinMaxRedGreenClass('responder_declined_rate', p.responder_declined_rate, p.data_min_max),
        },
      ];
      return columns_responder;
    }
  };

  const get_columns_deliverable = (isAllColumns: boolean) => {
    if (isAllColumns) {
      const columns_deliverable = [
        {
          key: 'deliverable_court',
          name: 'Responsible for',
          width: 145,
          maxWidth: 145,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('deliverable_court') : '',
          headerRenderer: p => renderHeaderCellDeliverable(p, getTooltipText('deliverable_court')),
          formatter: p => renderFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft deliverable`,
          cellClass: p => setCellMinMaxGreenRedClass('deliverable_court', p.deliverable_court, p.data_min_max),
        },
        {
          key: 'deliverable_review',
          name: 'Reviewer of',
          width: 100,
          maxWidth: 100,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('deliverable_review') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('deliverable_review')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('deliverable_review', p.deliverable_review, p.data_min_max),
        },
        {
          key: 'review_approved',
          name: 'Accepted reviews',
          width: 135,
          maxWidth: 135,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('review_approved') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('review_approved')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxGreenRedClass('review_approved', p.review_approved, p.data_min_max),
        },
        {
          key: 'review_declined',
          name: 'Declined reviews',
          width: 130,
          minWidth: 130,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('review_declined') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('review_declined')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('review_declined', p.review_declined, p.data_min_max),
        },
        {
          key: 'review_pending',
          name: 'Pending reviews',
          width: 125,
          minWidth: 125,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('review_pending') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('review_pending')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('review_pending', p.review_pending, p.data_min_max),
        },
      ];
      return columns_deliverable;
    } else {
      const columns_deliverable = [
        {
          key: 'deliverable_court',
          name: 'Responsible for',
          width: 145,
          maxWidth: 145,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('deliverable_court') : '',
          headerRenderer: p => renderHeaderCellDeliverable(p, getTooltipText('deliverable_court')),
          formatter: p => renderFormatter(p, true),
          headerCellClass: `${themeClass}__tableHeaderCellLeft deliverable`,
          cellClass: p => setCellMinMaxGreenRedClass('deliverable_court', p.deliverable_court, p.data_min_max),
        },
        {
          key: 'deliverable_review',
          name: 'Reviewer of',
          width: 100,
          maxWidth: 100,
          resizableName: 'deliverable',
          order_dir: columns ? findColumnOrder('deliverable_review') : '',
          headerRenderer: p => renderHeaderCell(p, getTooltipText('deliverable_review')),
          formatter: p => renderFormatter(p),
          headerCellClass: `${themeClass}__tableHeaderCell`,
          cellClass: p => setCellMinMaxRedGreenClass('deliverable_review', p.deliverable_review, p.data_min_max),
        },
      ];
      return columns_deliverable;
    }
  };
  useEffect(() => {
    const columns_first = [
      {
        key: 'row_num',
        name: '#',
        width: 36,
        maxWidth: 36,
        frozen: true,
        headerRenderer: p => renderMainHeaderCell(p),
        headerCellClass: `${themeClass}__tableHeaderCell`,
        formatter: p => renderRowNumFormatter(p),
        cellClass: p => setCellClass(p),
      },
      {
        key: 'title',
        name: `${isAllCompanies ? 'Company name' : 'User name'}`,
        width: 300,
        maxWidth: 300,
        frozen: true,
        order_dir: columns ? findColumnOrder('title') : '',
        formatter: p => renderCompanyFormatter(p),
        headerRenderer: p => renderMainHeaderCell(p),
        headerCellClass: `${themeClass}__tableHeaderCellLeft no_bl`,
      },
    ];
    const columns_general = get_columns_general(gridColumnsVisible['general'].is_visible);
    const columns_requester = get_columns_requester(gridColumnsVisible['requester'].is_visible);
    const columns_responder = get_columns_responder(gridColumnsVisible['responder'].is_visible);
    const columns_deliverable = get_columns_deliverable(gridColumnsVisible['deliverable'].is_visible);
    const colum = [...columns_first, ...columns_general, ...columns_requester, ...columns_responder, ...columns_deliverable];
    setColumns(colum);
    //setTimeout(() => {
    // firstColumnRef.current.element.scrollLeft = 0;
    //}, 50);
  }, [gridColumnsVisible, themeClass, isAllCompanies]);
  //this state need fix drop sorting after change time popup select
  const [render, setRender] = useState(false);

  useEffect(() => {
    const allRows = data
      ? data.map(item => {
          const obj = { ...item };
          obj.data_min_max = data_min_max;
          return obj;
        })
      : [];
    setRows(allRows);
    setRowsOrig(allRows);
    const obj = { idx: 1, name: 'title', order_dir: '' };
    setOnSortColumn(onSortColumn || obj);
    setRender(true);
  }, [data]);

  useEffect(() => {
    if (onSortColumn?.name) {
      const sortRows = [...rows];
      const sortColumns = [...columns].map(item => {
        const obj = { ...item };
        if (onSortColumn.name !== item.key) {
          obj.order_dir = '';
        }
        return obj;
      });

      const sortData = (a, b, isNumber, isAsc) => {
        if (isNumber) {
          return isAsc ? b - a : a - b;
        } else {
          const nameA = a.toLowerCase() === '(no company assigned)' ? 'zzzzzzzzzzz' : a.toLowerCase();
          const nameB = b.toLowerCase() === '(no company assigned)' ? 'zzzzzzzzzzz' : b.toLowerCase();
          if ((isAsc && nameA < nameB) || (!isAsc && nameB < nameA)) return -1;
          if ((isAsc && nameA > nameB) || (!isAsc && nameB < nameA)) return 1;
          return 0;
        }
      };

      if (onSortColumn.order_dir === '') {
        sortRows.sort((a, b) => sortData(a[onSortColumn.name], b[onSortColumn.name], onSortColumn.name !== 'title', true));

        if (sortColumns[onSortColumn.idx]) sortColumns[onSortColumn.idx].order_dir = 'desc';

        setColumns(sortColumns);
        setRows(sortRows);
      } else if (onSortColumn.order_dir === 'desc') {
        sortRows.sort((a, b) => sortData(a[onSortColumn.name], b[onSortColumn.name], onSortColumn.name !== 'title', false));
        if (sortColumns[onSortColumn.idx]) sortColumns[onSortColumn.idx].order_dir = 'asc';
        setColumns(sortColumns);
        setRows(sortRows);
      } else {
        if (sortColumns[onSortColumn.idx]) sortColumns[onSortColumn.idx].order_dir = '';
        setColumns(sortColumns);
        setRows(rowsOrig);
      }

      setRender(false);
    }
  }, [onSortColumn, render]);

  return (
    <div className={`${themeClass}`}>
      {firstColumnRef?.current?.element && rows?.length > 0 && (
        <>
          <DraggleScroll element={firstColumnRef.current.element} />
        </>
      )}
      <div className={'collapseButton'}>
        <CustomButton type={'text-plain'} size={'md'} clickHandler={changeAccordionCollapse} title={collapseButtonTitle} />
      </div>

      <div className={`${themeClass}__container`}>
        {isLoading ? (
          <SkeletonGrid />
        ) : (
          <DataGridBasic
            ref={firstColumnRef}
            columns={columns || []}
            rows={rows || []}
            headerRowHeight={68}
            rowHeight={35}
            className={`${themeClass}__table`}
            doubleHeader={true}
            onColumnResize={(idx: number, width: number) => onSubmit(idx, width, columns)}
            customHeaderRenderer={true}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ReportingGrid);
