import React, { useRef } from 'react';
import TokenIcon from '../TokenIcon/TokenIcon';
import CustomTooltip from '../Tooltip/Tooltip';
import { CustomButton } from '../ButtonComponents';
import './Avatar.scss';

interface IProps {
  avatar: string;
  isAvatarEdit: boolean;
  handleOpenEditorPopup: () => void;
  handleFile: (e: any) => void;
  tooltip: string;
  label: string;
  isMobile?: boolean;
}

const AvatarComponent = ({ avatar, isAvatarEdit, handleOpenEditorPopup, handleFile, tooltip, label, isMobile }: IProps) => {
  const fileInputRef = useRef(null);

  const handleClickBlock = () => {
    if (isAvatarEdit) {
      return;
    }
    fileInputRef.current.click();
  };

  return (
    <div className="mainAvatar" onClick={handleClickBlock}>
      <div className={`imgAvatar ${isAvatarEdit ? '-edit' : ''}`} onClick={() => isAvatarEdit && handleOpenEditorPopup()}>
        {avatar ? (
          <img src={avatar} className={'avatarImage'} />
        ) : (
          <TokenIcon customClass={'avatarImage'} iconName={'logomark'} size={104} />
        )}
        {isAvatarEdit && <span className={'editAvatar'}>Edit</span>}
      </div>
      <div className="inputAvatar">
        <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" type="file" onChange={handleFile} ref={fileInputRef} />
        <CustomTooltip title={tooltip} placement="bottom" key={'request-avatar'}>
          <label htmlFor={isAvatarEdit ? 'raised-button-file' : undefined}>
            <CustomButton type="text-plain" title={label} size="xs" isMobile={isMobile} />
          </label>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default AvatarComponent;
