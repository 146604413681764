import React, { FC } from 'react';
import ChipsStandardTag from '../../../Chips/ChipsStandardTag/ChipsStandardTag';
import { NLUfcModel } from '../../../../../store/needlist/needListModels';
import { Badge } from '../../../../common/TeamTab/Badge';

interface IProps {
  data: NLUfcModel[];
  elementRef?: React.ElementRef<any>;
  themeClass?: string;
  isShowDescription?: boolean;
  isShowTooltip?: boolean;
}

const typeToIndicator = {
  default: null,
  reason: 'blue',
  impacted: 'red',
};

const UniFormatChips: FC<IProps> = ({ data, elementRef, themeClass, isShowDescription = true, isShowTooltip = false }) => {
  return (
    <div className={`${themeClass}_container_ufcBlock`}>
      {data.map(item => {
        return (
          <div className={`Badge`} key={`ufc-${item.id}`}>
            <ChipsStandardTag
              chipsRef={elementRef}
              indicator={typeToIndicator[item.type] || ''}
              value={isShowDescription ? item.code + ' - ' + `${item.description || item.title}` : item.code}
              tooltip={isShowTooltip && `${item.code} - ${item?.title || item?.description || ''}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default UniFormatChips;
