import React, { useState, useEffect, useMemo } from 'react';
import InvitePageView from './InvitePageView';
import { UserApi } from '../../../service/Api/Api';
import { inviteUser } from '../../../store/user/userThunk';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import useRouter from '../../../hooks/useRouter/useRouter';

const InvitePageContainer = () => {
  const { params, navigate } = useRouter();

  const dispatch = useAppDispatch();

  const loading = useAppSelector(state => state.userReducer.loading);
  const message = useAppSelector(state => state.userReducer.message);
  const status = useAppSelector(state => state.userReducer.status);

  const inviteKey = useMemo(() => {
    return params['inviteKey'];
  }, [params]);

  const [user, setUser] = useState({
    password: '',
    confirmPassword: '',
    token: inviteKey || '',
  });

  useEffect(() => {
    const checkInviteLink = async () => {
      const isUsedLinkRequest = await UserApi.checkInviteLinkUser(inviteKey);
      if (!isUsedLinkRequest.data.exist) {
        navigate('/');
      }
    };

    checkInviteLink();
  }, [inviteKey]);

  const handleChangeField = field => value => {
    setUser(prevUser => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleLogin = () => {
    if (!user.password && !user.confirmPassword) {
      setUser(prevUser => ({
        ...prevUser,
        error: '',
        passwordError: 'Please fill required fields',
        confirmPasswordError: 'Please fill required fields',
      }));
      return;
    }
    if (!user.password) {
      setUser(prevUser => ({
        ...prevUser,
        error: '',
        passwordError: 'Please fill required fields',
      }));
      return;
    }
    if (!user.confirmPassword) {
      setUser(prevUser => ({
        ...prevUser,
        error: '',
        confirmPasswordError: 'Please fill required fields',
      }));
      return;
    }

    if (user.password === user.confirmPassword) {
      setUser(prevUser => ({
        ...prevUser,
        error: '',
      }));
      delete user.confirmPassword;
      dispatch(
        inviteUser({
          ...user,
          callback: () => {
            navigate('/');
          },
        }),
      );
    } else {
      setUser(prevUser => ({
        ...prevUser,
        error: 'Passwords are not equal',
      }));
    }
  };

  return (
    <InvitePageView
      user={user}
      handleChangeField={handleChangeField}
      handleLogin={handleLogin}
      loading={loading}
      message={message}
      status={status}
      error={user.error}
      passwordError={user.passwordError}
      confirmPasswordError={user.confirmPasswordError}
    />
  );
};

export default InvitePageContainer;
