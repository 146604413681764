import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { payloadWithCallback, Team } from './types';
import {
  addParty,
  createParty,
  createTemplateParties,
  createUserToParty,
  deleteUserToParty,
  fetchDisciplinesRoles,
  fetchMembers,
  getCountNfForParty,
  getFilters,
  getListSubdivision,
  getParties,
  getPartyUsers,
  linkUserToProject,
  searchTeamUsers,
  setBuyoutDate,
  setContactPointParty,
  setContactPointProject,
  unlinkParty,
  unlinkUserToProject,
  updateParty,
  updateUserToParty,
} from './teamThunk';
import { TeamApi } from '../../service/Api/team/types';
import { KeyValueModel } from '../../models/key-value.model';
import { TitleModel } from '../../models';

const initialState: Team.State = {
  members: {
    isLoading: false,
    data: [],
  },
  disciplines: [],
  roles: [],
  isLoadingDisciplinesRoles: false,
  isLoadingParty: false,
  isFirstLoadingMembers: true,
  usersTeam: { partyUsers: [], projectUsers: [] },
  isLoadingPartyUsers: false,
  isLoadingGetPartyUsers: false,
  isUpdatePartyUsers: false,
  isGetListSubdivision: false,
  isLink_UnlinkPartyUsers: false,
  subdivisions: [],
  errorsTeamTab: null,
  parties: {
    isLoading: false,
    data: [],
  },
  isLoadingAddParty: false,
  isSetContactPoint: false,
  filters: {},
  nfCountForParties: null,
  isStandardTemplateCreating: false,
  addedPartyId: null,
};

export const teamSlice = createSlice({
  initialState,
  name: 'teamReducer',
  reducers: {
    clearListSubdivision(state) {
      state.subdivisions = [];
    },
    setAddedPartyId(state, { payload }: PayloadAction<number>) {
      state.addedPartyId = payload;
    },
    setFilters(state, { payload }: PayloadAction<KeyValueModel<TitleModel>>) {
      state.filters = payload;
    },
    clearTeamState(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * fetchMembers
       */
      .addCase(fetchMembers.pending, state => {
        const teamMembersData = state.members.data.length ? [...state.members.data] : [];

        state.isStandardTemplateCreating = false;
        state.members = {
          data: teamMembersData,
          isLoading: true,
        };
      })
      .addCase(fetchMembers.fulfilled, (state, { payload }: PayloadAction<TeamApi.IMember[]>) => {
        state.members = {
          data: payload,
          isLoading: false,
        };
        state.isFirstLoadingMembers = false;
      })
      .addCase(fetchMembers.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }

        state.isStandardTemplateCreating = false;
        state.members = {
          ...state.members,
          isLoading: false,
        };
        state.isFirstLoadingMembers = false;
      })
      /**
       * createParty
       */
      .addCase(createParty.pending, state => {
        state.isLoadingParty = true;
      })
      .addCase(createParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.CreatePartyResponsePayload>) => {
        let data;
        const partyToReplace = state.members.data.findIndex(party => party.id === payload.temp_party_id);

        if (payload.temp_party_id) {
          data = [...state.members.data];
          data.splice(partyToReplace, 1, payload.data);
        } else {
          data = [...state.members.data, payload.data];
        }

        state.isLoadingParty = false;
        state.members = {
          ...state.members,
          data: data,
        };
        state.errorsTeamTab = null;
      })
      .addCase(createParty.rejected, (state, { payload }) => {
        state.isLoadingParty = false;
        state.errorsTeamTab = {
          errors: payload.data.errors,
        };
      })
      /**
       * updateParty
       */
      .addCase(updateParty.pending, state => {
        state.isLoadingParty = true;
        state.errorsTeamTab = null;
      })
      .addCase(updateParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.UpdatePartyResponsePayload>) => {
        const data = state.members.data.map(member => (member.id === payload.data.id ? payload.data : member));

        state.isLoadingParty = false;
        state.members = {
          ...state.members,
          data: data,
        };
        state.errorsTeamTab = null;
      })
      .addCase(updateParty.rejected, (state, { payload }) => {
        state.isLoadingParty = false;
        state.errorsTeamTab = {
          errors: payload.data.errors,
        };
      })
      /**
       * getListSubdivision
       */
      .addCase(getListSubdivision.pending, state => {
        state.isGetListSubdivision = true;
      })
      .addCase(getListSubdivision.fulfilled, (state, { payload }: PayloadAction<TeamApi.ListSubdivision[]>) => {
        state.subdivisions = payload;
        state.isGetListSubdivision = false;
      })
      .addCase(getListSubdivision.rejected, (state, { error }) => {
        state.isGetListSubdivision = false;
      })
      /**
       * unlinkParty
       */
      .addCase(unlinkParty.pending, state => {
        state.isLoadingParty = true;
        state.errorsTeamTab = null;
      })
      .addCase(unlinkParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.LinkOrUnlinkPartyPayload>) => {
        const { party_id, data } = payload;
        const { members } = state;
        let newMembersData = [...members.data];

        if (data) {
          const partyIndex = newMembersData.findIndex(member => member.id === party_id);
          partyIndex !== -1 && newMembersData.splice(partyIndex, 1, data);
        } else {
          newMembersData = newMembersData.filter(member => member.id !== payload.party_id);
        }

        state.isLoadingParty = false;
        state.members = {
          ...state.members,
          data: newMembersData,
        };
        state.errorsTeamTab = null;
      })
      .addCase(unlinkParty.rejected, (state, { error }) => {
        state.isLoadingParty = false;
      })
      /**
       * fetchDisciplinesRoles
       */
      .addCase(fetchDisciplinesRoles.pending, state => {
        state.isLoadingDisciplinesRoles = true;
      })
      .addCase(fetchDisciplinesRoles.fulfilled, (state, { payload }: PayloadAction<Team.FetchDisciplinesRolesPayload>) => {
        state.disciplines = payload.disciplines;
        state.roles = payload.roles;
        state.isLoadingDisciplinesRoles = false;
      })
      .addCase(fetchDisciplinesRoles.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.isLoadingDisciplinesRoles = false;
      })
      /**
       * getCountNfForParty
       */
      .addCase(getCountNfForParty.pending, state => {
        return state;
      })
      .addCase(getCountNfForParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.NfCountForPartyData>) => {
        state.nfCountForParties = payload;
      })
      .addCase(getCountNfForParty.rejected, (state, { error }) => {
        return state;
      })
      /**
       * getPartyUsers
       */
      .addCase(getPartyUsers.pending, state => {
        state.isLoadingGetPartyUsers = true;
      })
      .addCase(getPartyUsers.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isLoadingGetPartyUsers = false;
        state.usersTeam = payload;
        state.errorsTeamTab = null;
      })
      .addCase(getPartyUsers.rejected, (state, { error }) => {
        state.isLoadingGetPartyUsers = false;
      })
      /**
       * searchTeamUsers
       */
      .addCase(searchTeamUsers.pending, state => {
        state.isLoadingPartyUsers = true;
      })
      .addCase(searchTeamUsers.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isLoadingPartyUsers = false;
        state.usersTeam = payload;
        state.errorsTeamTab = null;
      })
      .addCase(searchTeamUsers.rejected, (state, { error }) => {
        state.isLoadingPartyUsers = false;
      })
      /**
       * linkUserToProject
       */
      .addCase(linkUserToProject.pending, state => {
        state.isLink_UnlinkPartyUsers = true;
      })
      .addCase(linkUserToProject.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isLink_UnlinkPartyUsers = false;
        state.usersTeam = payload;
        state.errorsTeamTab = null;
      })
      .addCase(linkUserToProject.rejected, (state, { error }) => {
        state.isLink_UnlinkPartyUsers = false;
      })
      /**
       * unlinkUserToProject
       */
      .addCase(unlinkUserToProject.pending, state => {
        state.isLink_UnlinkPartyUsers = true;
      })
      .addCase(unlinkUserToProject.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isLink_UnlinkPartyUsers = false;
        state.usersTeam = payload;
        state.errorsTeamTab = null;
      })
      .addCase(unlinkUserToProject.rejected, (state, { error }) => {
        state.isLink_UnlinkPartyUsers = false;
      })
      /**
       * createUserToParty
       */
      .addCase(createUserToParty.pending, state => {
        state.isUpdatePartyUsers = true;
      })
      .addCase(createUserToParty.fulfilled, state => {
        state.isUpdatePartyUsers = false;
      })
      .addCase(createUserToParty.rejected, (state, { payload }) => {
        state.errorsTeamTab = {
          errors: payload.data.errors,
        };
      })
      /**
       * updateUserToParty
       */
      .addCase(updateUserToParty.pending, state => {
        createUserToParty;
        state.isUpdatePartyUsers = true;
      })
      .addCase(updateUserToParty.fulfilled, state => {
        state.isUpdatePartyUsers = false;
      })
      .addCase(updateUserToParty.rejected, (state, { payload }) => {
        state.errorsTeamTab = {
          errors: payload.data.errors,
        };
      })
      /**
       * deleteUserToParty
       */
      .addCase(deleteUserToParty.pending, state => {
        state.isUpdatePartyUsers = true;
      })
      .addCase(deleteUserToParty.fulfilled, state => {
        state.isUpdatePartyUsers = false;
      })
      .addCase(deleteUserToParty.rejected, (state, { error }) => {
        state.isUpdatePartyUsers = false;
      })
      /**
       * getParties
       */
      .addCase(getParties.pending, state => {
        state.parties = {
          data: [],
          isLoading: true,
        };
      })
      .addCase(getParties.fulfilled, (state, { payload }: PayloadAction<TeamApi.Party[]>) => {
        state.parties = {
          data: payload,
          isLoading: false,
        };
      })
      .addCase(getParties.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }

        state.parties = {
          ...state.parties,
          isLoading: false,
        };
      })
      /**
       * addParty
       */
      .addCase(
        addParty.pending,
        (state, { meta }: PayloadAction<null, string, { arg: payloadWithCallback<TeamApi.AddPartyRequest, TeamApi.IMember> }>) => {
          state.isLoadingAddParty = meta.arg.data.party_id;
        },
      )
      .addCase(addParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.AddCompanySuccessPayload>) => {
        let data;

        if (payload.temp_party_id) {
          const partyToReplace = state.members.data.findIndex(party => party.id === payload.temp_party_id);
          data = [...state.members.data];
          data.splice(partyToReplace, 1, payload.party);
        } else {
          data = [...state.members.data, payload.party];
        }

        state.isLoadingAddParty = false;
        state.addedPartyId = payload.party.id;
        state.members = {
          ...state.members,
          data: data,
        };
      })
      .addCase(addParty.rejected, (state, { error }) => {
        state.isLoadingAddParty = false;
      })
      /**
       * setContactPointProject
       */
      .addCase(setContactPointProject.pending, state => {
        state.isSetContactPoint = true;
      })
      .addCase(setContactPointProject.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isSetContactPoint = false;
        state.usersTeam = payload;
      })
      .addCase(setContactPointProject.rejected, (state, { error }) => {
        state.isSetContactPoint = false;
      })
      /**
       * setContactPointParty
       */
      .addCase(setContactPointParty.pending, state => {
        state.isSetContactPoint = true;
      })
      .addCase(setContactPointParty.fulfilled, (state, { payload }: PayloadAction<TeamApi.UsersTeam>) => {
        state.isSetContactPoint = false;
        state.usersTeam = payload;
      })
      .addCase(setContactPointParty.rejected, (state, { error }) => {
        state.isSetContactPoint = false;
      })
      /**
       * getFilters
       */
      .addCase(getFilters.pending, state => {
        return state;
      })
      .addCase(getFilters.fulfilled, (state, { payload }: PayloadAction<KeyValueModel<TitleModel>>) => {
        state.filters = payload;
      })
      .addCase(getFilters.rejected, (state, { error }) => {
        return state;
      })
      /**
       * setBuyoutDate
       */
      .addCase(setBuyoutDate.pending, state => {
        return state;
      })
      .addCase(setBuyoutDate.fulfilled, (state, { payload }: PayloadAction<TeamApi.SetBuyoutDateRequest>) => {
        const data = [...state.members.data];
        const foundIndex = data.findIndex(item => item.id === payload.party_id);
        data[foundIndex].actual_buyout_date = payload.actual_buyout_date;
        data[foundIndex].planned_buyout_date = payload.planned_buyout_date;

        state.members = {
          ...state.members,
          data: [...data],
        };
      })
      .addCase(setBuyoutDate.rejected, (state, { error }) => {
        return state;
      })
      /**
       * createTemplateParties
       */
      .addCase(createTemplateParties.pending, state => {
        state.isStandardTemplateCreating = false;
      })
      .addCase(createTemplateParties.fulfilled, (state, { payload }: PayloadAction<TeamApi.IMember[]>) => {
        state.members = {
          data: payload,
          isLoading: false,
        };
        state.isStandardTemplateCreating = false;
      })
      .addCase(createTemplateParties.rejected, (state, { meta }) => {
        state.isStandardTemplateCreating = false;
      });
  },
});

export default teamSlice.reducer;
