import React from 'react';
import './PopUp.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SVG from 'react-inlinesvg';
import Loader from 'react-loader-spinner';
import ReactHtmlParser from 'react-html-parser';

class PopUpView extends React.Component {
  render() {
    const {
      open,
      onClose,
      maxWidth,
      title,
      children,
      onSubmit,
      btnText,
      fullWidth,
      disableBtn,
      contentStyles,
      actionBtnLeftSide,
      customButton,
      hideBackdrop,
      customStyles,
      isLoading,
      hideRemoveIcon,
      className,
      customButtonStyle,
      customPaperClass,
      isTitleTag,
      popUpContentStyles,
    } = this.props;

    let dialogStyle = { backgroundColor: hideBackdrop ? 'transparent' : null };

    if (customStyles) {
      dialogStyle = { ...dialogStyle, ...customStyles };
    }

    return (
      <Dialog
        data-closeim
        open={!!open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth={fullWidth}
        maxWidth={maxWidth || 'sm'}
        className={`mainPopup ${className}`}
        PaperProps={{
          className: `modalPaper -exitModal ${customPaperClass || ''}`,
        }}
        BackdropProps={{
          invisible: hideBackdrop,
          hideBackdrop: hideBackdrop,
        }}
        style={dialogStyle}
      >
        {title && !isTitleTag && (
          <DialogTitle id="form-dialog-title" className="popupTitle">
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', ...customButtonStyle }}>
              {title} {customButton ? customButton : ''}
            </div>
            <div className={'popupActionsBtnClose'} style={hideRemoveIcon ? { display: 'none' } : {}} onClick={onClose}>
              <SVG src={require('../../../assets/icons/remove_icon.svg')} />
            </div>
          </DialogTitle>
        )}
        {title && isTitleTag && (
          <DialogTitle id="form-dialog-title" className="popupTitle">
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', ...customButtonStyle }}>
              {title ? ReactHtmlParser(title) : title}
            </div>
            <div className={'popupActionsBtnClose'} style={hideRemoveIcon ? { display: 'none' } : {}} onClick={onClose}>
              <SVG src={require('../../../assets/icons/remove_icon.svg')} />
            </div>
          </DialogTitle>
        )}
        <DialogContent className={`${popUpContentStyles ? `${popUpContentStyles}` : 'popupContent'}`} style={contentStyles}>
          {isLoading ? (
            <div className="light-loader">
              <Loader type="Oval" color="#00BFFF" height={'60'} width={'60'} />
            </div>
          ) : (
            children
          )}
        </DialogContent>
        {!disableBtn && !isLoading && (
          <DialogActions className={`popupActionsWide ${actionBtnLeftSide ? 'actionBtnLeftSide' : ''}`}>
            <div className={'popupBtns'}>
              {onSubmit && (
                <button onClick={e => onSubmit(e)} className={'popupBtns__save'}>
                  {btnText?.[0] || 'Save'}
                </button>
              )}
              {onClose && (
                <button onClick={onClose} className="popupBtns__cancel">
                  {btnText?.[1] || 'Close'}
                </button>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default PopUpView;
