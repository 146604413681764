import { DatabaseService } from '../../../../service/IndexedDB/DBConfig';
import { saveNeedListCashingTime } from '../../../../service/IndexedDB/Helper';
import { projectSlice } from '../../../../store/project/projectReducer';
import { requestSlice } from '../../../../store/request/requestReducer(HOLD)';
import { RequestSocketActionI } from '../SocketConstants';
const { updateRefreshesValue } = projectSlice.actions;

const {
  socketHandleRequest,
  socketHandleNewRequest,
  socketHandleRequestChangePrivate,
  socketHandleRequestChangeWatchlist,
  socketHandleRequestChangeHotlist,
  socketHandleRequestChangeProcore,
  socketSandBoxHotlistState,
  socketDDLRefreshes,
  socketHandleDeleteRequestNeedList,
  socketHandleRequestInfo,
} = requestSlice.actions;

interface RequestDataI {
  isNeedListLoadingStatus: boolean;
  isPendingAction: boolean;
  setIsPendingAction: (value: boolean) => void;
  isNLCached: boolean;
  addPendingAction: (data: RequestSocketActionI) => void;
  activeProjectId: number;
  socketData: RequestSocketActionI;
  isAllowToAddNf: boolean;
  dispatch: any;
  nf: number;
}

export const handleRequestSocket = ({
  socketData,
  setIsPendingAction,
  isNeedListLoadingStatus,
  isPendingAction,
  addPendingAction,
  isNLCached,
  dispatch,
  activeProjectId,
  isAllowToAddNf,
  nf,
}: RequestDataI) => {
  if (socketData.action === 'needlist_bulk_edit' && socketData.event === 'start') {
    setIsPendingAction(true);
  }

  if (socketData.action === 'needlist_bulk_edit' && socketData.event === 'finish') {
    setIsPendingAction(false);
  }

  if (isNeedListLoadingStatus || isPendingAction) {
    addPendingAction(socketData);
    return;
  }

  if (socketData.action === 'delete') {
    if (isNLCached) {
      DatabaseService.deleteById({ itemId: socketData.data.id, storeName: `NEEDLIST`, projectId: activeProjectId, dispatch });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }

    dispatch(socketHandleDeleteRequestNeedList(socketData.data));
  }
  if (socketData.action === 'created') {
    if (!isAllowToAddNf) return;

    if (isNLCached) {
      DatabaseService.addItem({ item: socketData.data, storeName: `NEEDLIST`, projectId: activeProjectId, dispatch });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleNewRequest(socketData.data));
  }
  if (socketData.action === 'updated' && socketData.event === 'private') {
    if (isNLCached) {
      DatabaseService.updateById({
        newValue: socketData.data,
        key: socketData.data.id,
        storeName: `NEEDLIST`,
        projectId: activeProjectId,
        dispatch,
      });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleRequestChangePrivate({ data: socketData.data, nf }));
  }
  if (socketData.action === 'updated' && socketData.event === 'watchlist') {
    if (isNLCached) {
      DatabaseService.updateById({
        newValue: socketData.data,
        key: socketData.data.id,
        storeName: `NEEDLIST`,
        projectId: activeProjectId,
        dispatch,
      });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleRequestChangeWatchlist({ data: socketData.data, nf }));
  }
  if (socketData.action === 'updated' && socketData.event === 'hotlist') {
    dispatch(socketSandBoxHotlistState({ changedNow: true, sectionType: 'request' }));

    if (isNLCached) {
      DatabaseService.updateById({
        newValue: socketData.data,
        key: socketData.data.id,
        storeName: `NEEDLIST`,
        projectId: activeProjectId,
        dispatch,
      });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleRequestChangeHotlist({ data: socketData.data, nf }));
  }
  if (socketData.action === 'updated' && socketData.event === 'procore') {
    if (isNLCached) {
      DatabaseService.updateById({
        newValue: socketData.data,
        key: socketData.data.id,
        storeName: `NEEDLIST`,
        projectId: activeProjectId,
        dispatch,
      });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleRequestChangeProcore({ data: socketData.data, nf }));
  }
  if (
    socketData.action === 'updated' &&
    socketData.responsible_user &&
    (!socketData.event ||
      socketData.event === 'incorporation' ||
      socketData.event === 'submitted' ||
      socketData.event === 'provided' ||
      socketData.event === 'declined' ||
      socketData.event === 'accepted' ||
      socketData.event === 'deactivated')
  ) {
    dispatch(socketDDLRefreshes({ changedNow: true }));
    if (isNLCached) {
      if (socketData.event === 'deactivated') {
        DatabaseService.deleteById({ itemId: socketData.data.id, storeName: `NEEDLIST`, projectId: activeProjectId, dispatch });
      } else {
        DatabaseService.updateById({
          newValue: socketData.data,
          key: socketData.data.id,
          storeName: `NEEDLIST`,
          projectId: activeProjectId,
          dispatch,
        });
      }
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }

    if (socketData.event === 'deactivated') {
      dispatch(socketHandleDeleteRequestNeedList(socketData.data));
    }

    dispatch(socketHandleRequest({ data: socketData.data, action: socketData.action }));
    dispatch(socketHandleRequestInfo(socketData.data));
  }
  if (socketData.action === 'updated_bic' && socketData.responsible_user) {
    if (isNLCached) {
      DatabaseService.updateById({
        newValue: socketData.data,
        key: socketData.data.id,
        storeName: `NEEDLIST`,
        projectId: activeProjectId,
        dispatch,
      });
      dispatch(updateRefreshesValue({ key: 'request', value: socketData.timestamp }));
      saveNeedListCashingTime(activeProjectId);
    }
    dispatch(socketHandleRequest({ data: socketData.data, action: socketData.action }));
    dispatch(socketHandleRequestInfo(socketData.data));
  }
};
