import React from 'react';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { settingsSlice } from '../../../store/globalSettings/settingsSlice';

const { setGlobalPrompPopUp } = settingsSlice.actions;

export const GlobalPromptPopUp = () => {
  const dispatch = useAppDispatch();
  const dialogClass = useGetThemeClass('dialogPopUp');

  const promptData = useAppSelector(state => state.settingsReducer.globalPromptPopUp);

  const handleClosePopUp = () => {
    dispatch(
      setGlobalPrompPopUp({
        ...promptData,
        isOpenPopUp: false,
      }),
    );
  };

  const handleCloseAndQuit = async () => {
    promptData.callback && (await promptData.callback());
    dispatch(
      setGlobalPrompPopUp({
        isChanged: false,
        isOpenPopUp: false,
        changeUrl: '',
        header: '',
        body: '',
        tertiaryTitle: '',
        primaryTitle: '',
        callback: null,
      }),
    );
  };

  return (
    <DialogPopUp
      isCard
      open={promptData.isOpenPopUp}
      onClose={handleClosePopUp}
      title={promptData.header}
      hideBackdrop={true}
      maxWidth={'xs'}
    >
      <div className={`${dialogClass}_dialogTitle`}>
        <div>{promptData.header}</div>
        <div className={`${dialogClass}_closeIcon`} onClick={handleClosePopUp}>
          <SystemButton type={'close'} size={'lg'} variant={'transparent'} />
        </div>
      </div>
      <div className={`${dialogClass}_content`}>
        <span className={`${dialogClass}_content_modalText`}>{promptData.body}</span>
      </div>
      <div className={`${dialogClass}_buttonsSection`}>
        <CustomButton clickHandler={handleClosePopUp} type={'tertiary'} title={promptData.tertiaryTitle || 'Keep editing'} size={'md'} />
        <CustomButton clickHandler={handleCloseAndQuit} type={'primary'} title={promptData.primaryTitle || 'Yes, quit'} size={'md'} />
      </div>
    </DialogPopUp>
  );
};
