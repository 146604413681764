export default class AccessHelper {
  static isProfileAccessCheck = (userData, user, isTrashCan) => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '2').length) {
      //Company User
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '1').length) {
      // Project Admin

      if (user.roles[0]?.id !== 4) {
        if (isTrashCan && user.roles[0]?.id === 1) {
          return false;
        }

        return true;
      } else {
        return false;
      }
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '4').length) {
      // Super Admin

      if (isTrashCan && (user.roles[0]?.id === 1 || user.roles[0]?.id === 4)) {
        return false;
      }

      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      //Company Manager
      if (user.roles[0]?.id === 2 || user.roles[0]?.id === 3) {
        return true;
      } else {
        return false;
      }
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '5').length) {
      //Workflow Manager
      if (user.roles[0]?.id === 2 || user.roles[0]?.id === 3 || user.roles[0]?.id === 5 || user.roles[0]?.id === 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  static isAccessCheck = (userData, company) => {
    if (!userData?.roles) {
      return false;
    }
    if (Object.keys(userData.roles).filter(key => key === '2').length) {
      return false;
    }
    if (Object.keys(userData.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (company.users.filter(user => user.id === userData.id).length && Object.keys(userData.roles).filter(key => key === '3').length) {
      return true;
    }
    return false;
  };

  // is exist user in this company
  static isSelfCompany = (userData, company) => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      const findUser = company?.users?.find(user => user.id === userData.id);
      if (findUser) {
        return true;
      } else {
        return false;
      }
    }
  };

  static isAccessToRequestEdit = (userInfo, request) => {
    if (request?.parent_id) {
      return false;
    }
    if (!userInfo?.roles) {
      return false;
    }
    if (Object.keys(userInfo?.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (!request) {
      return true;
    }

    const userExistInParty = !!userInfo.user_parties.find(f => f.id === request.request_party_id);

    if (request?.author?.id === userInfo?.id && userExistInParty) {
      return true;
    }
    if (Object.keys(userInfo?.roles || {}).filter(key => key === '3' || key === '2').length) {
      return userExistInParty;
    }
  };

  static isAccessToEditPCDCard = (userInfo, request) => {
    if (!userInfo?.roles) {
      return false;
    }
    if (Object.keys(userInfo?.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (!request) {
      return true;
    }
    if (Object.keys(userInfo?.roles || {}).filter(key => key === '2' || key === '3' || key === '5').length) {
      const userExistInParty = !!userInfo.user_parties.find(f => f.id === request.responsible_party?.id);
      return userExistInParty;
    }
  };

  static isAccessToEditReview = (userInfo, review) => {
    if (!userInfo?.roles) {
      return false;
    }
    if (Object.keys(userInfo?.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }

    if (Object.keys(userInfo?.roles || {}).filter(key => key === '2' || key === '3' || key === '5').length) {
      const userExistInParty = !!userInfo.user_parties.find(f => f.id === review.party?.id);
      return userExistInParty;
    }
  };

  static isAccessToEditIncorporation = (userInfo, assigneeCompany) => {
    if (!userInfo?.roles) {
      return false;
    }
    if (Object.keys(userInfo?.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (Object.keys(userInfo?.roles || {}).filter(key => key === '2' || key === '3').length) {
      const userExistInParty = !!userInfo.user_parties.find(f => f.id === assigneeCompany);
      return userExistInParty;
    }
  };

  static isAccessToCommitmentEdit = (userInfo, request) => {
    if (!userInfo?.roles || (request && request.is_whiteboard === 0)) {
      return false;
    }
    if (Object.keys(userInfo.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }

    const userExistInParty = !!userInfo.user_parties.find(f => f.id === request?.response_party_id);

    if (Object.keys(userInfo.roles || {}).filter(key => key === '2' || key === '3').length) {
      return userExistInParty;
    }
    return false;
  };

  static isAccessToDueDateEdit = (userInfo, request) => {
    if (!userInfo?.roles) {
      return false;
    }

    if (Object.keys(userInfo.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }

    if (!request) {
      return true;
    }

    const userExistInParty = !!userInfo.user_parties.find(f => f.id === request?.request_party_id || f.id === request?.response_party_id);

    if (Object.keys(userInfo.roles || {}).filter(key => key === '3' || key === '2').length) {
      return userExistInParty;
    }
    return false;
  };

  static isUserExistsInRequestParty = (userInfo, request) => {
    if (!userInfo?.roles) {
      return false;
    }

    if (Object.keys(userInfo.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }

    const userExistInParty = !!userInfo.user_parties.find(f => f.id === request?.request_party_id);

    return userExistInParty;
  };

  static isUserExistsInResponseParty = (userInfo, request) => {
    if (!userInfo?.roles) {
      return false;
    }

    if (Object.keys(userInfo.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }

    const userExistInParty = !!userInfo.user_parties.find(f => f.id === request?.response_party_id);

    return userExistInParty;
  };

  // Collect parties where users exist
  static userExistInParty = (userData, projectDisciplines) => {
    const userExistInParties = [];
    projectDisciplines.map(discipline => {
      discipline.parties.map(party => {
        const isUser = party.users?.find(user => user.id === userData.id);
        if (isUser) {
          if (party.party) {
            userExistInParties.push(party.party);
          } else {
            party.discipline_id = discipline.id;
            userExistInParties.push(party);
          }
        }
      });
    });

    return userExistInParties;
  };

  static isAccessToRemoveRequest = (userData, request, projectDisciplines) => {
    if (!(userData?.role || userData?.roles) || !projectDisciplines) {
      return false;
    }
    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '1' || key === '4').length) {
      return true;
    }
    if (request.action_dates.sent) {
      return false;
    }
    /*if (Object.keys(userData?.role || userData?.roles).filter(key => key === '2').length) {
      if (request && request.created_by_user_id === userData.id && !request.is_whiteboard) {
        return true;
      } else {
        return false;
      }
    }*/

    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '2' || key === '3' || key === '5').length) {
      const userExistInParty = AccessHelper.userExistInParty(userData, projectDisciplines);
      if (
        !request?.is_whiteboard &&
        userExistInParty?.find(party => party?.id === request?.request_party_id)?.discipline_id === request?.req_disc_id
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  static isAccessToUnassignComment = userData => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '2').length) {
      //Company User
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '1').length) {
      // Project Admin
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '4').length) {
      // Super Admin
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      //Company Manager
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '5').length) {
      //Workflow Manager
      return true;
    }
    return false;
  };

  static isAccessToShareFilters = userData => {
    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
  };

  static isAccessToUnassignComment = userData => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '2').length) {
      //Company User
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '1').length) {
      // Project Admin
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '4').length) {
      // Super Admin
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      //Company Manager
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '5').length) {
      //Workflow Manager
      return true;
    }
    return false;
  };

  static isAccessToEditCompany = userData => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '2').length) {
      //Company User
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '1').length) {
      // Project Admin
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '4').length) {
      // Super Admin
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      //Company Manager
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '5').length) {
      //Workflow Manager
      return false;
    }
    return false;
  };
}
