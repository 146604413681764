export default class BaseHelper {
  static getKeyByValue = (object, value): string => {
    return Object.keys(object).find(key => object[key] === value);
  };

  public static downloadFile = async (url: string, fileName: string) => {
    try {
      await fetch(url).then(function (t) {
        return t.blob().then(b => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', fileName);
          a.click();
        });
      });
    } catch (e) {
      return void 0;
    }
  };
  public static accessWhenInternalRFI = (request, userInfo, activeProjectId): boolean => {
    if (request.tags.find(f => f.alias === 'internal_rfi')) {
      const isUserInParty = userInfo.user_parties.find(
        f =>
          (f.party_id === request.request_party_id || f.party_id === request.response_party_id) && request.project_id === activeProjectId,
      );
      const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '1' || key === '4').length;
      if (!(isUserInParty || isAdmin)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  public static sort = (data: any, field: string) => {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return data.sort(function (a, b) {
      return collator.compare(a[field], b[field]);
    });
  };
  public static onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
}
