import React from 'react';
import SVG from 'react-inlinesvg';
import moment from 'moment';
import { ICommentTargetProps } from './CommentTarget.props';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { SystemButton } from '../../../../controls/ButtonComponents';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

import './CommentTarget.module.scss';
import DateRow from '../../../../controls/DateRow/DateRow';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';

export const CommentTarget: React.FC<ICommentTargetProps> = ({ comment, isEditComment, onClearTarget, getViewText }) => {
  const { isMobile } = GetResolution();

  const themeClassCommentTargetWrapper = useGetThemeClass('commentTargetWrapper');

  const offset = new Date().getTimezoneOffset();

  const viewText = getViewText(comment);

  return (
    <div className={themeClassCommentTargetWrapper}>
      <div className={'targetLine'}>
        <span>.</span>
      </div>
      <div className={'targetBodyContainer'}>
        <div className={'targetHeader'}>
          {isEditComment ? (
            <>
              <TokenIcon customClass={'editIcon'} iconName={'edit-text'} size={16} />
              <span className={'editPlaceHolder'}>Edit Comment</span>
            </>
          ) : (
            <div className={'commentDataContainer'}>
              <DateRow isMobile={isMobile} date={comment.created_at} showTime />
              <PlaceholderInfo
                type={'Persona'}
                firstName={comment?.author?.profile?.first_name}
                lastName={comment?.author?.profile?.last_name}
                imagePath={comment?.author?.profile?.image}
                detailInfo={`${comment?.author?.profile?.first_name} ${comment?.author?.profile?.last_name}`}
                isMobile={isMobile}
                size={24}
              />
            </div>
          )}
        </div>

        <div className={'targetText'} dangerouslySetInnerHTML={{ __html: viewText }}></div>
      </div>
      <div className={'targetClearContainer'}>
        <SystemButton type={'close'} variant={'transparent'} size={'md'} isMobile={isMobile} clickHandler={onClearTarget} />
      </div>
    </div>
  );
};
