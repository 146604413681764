import React, { ReactElement, ReactNode, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import Popover from '@mui/material/Popover';

import './DropdownDownloadList.module.scss';

interface DropdownDownloadListProps<T> {
  size: 'sm' | 'md';
  menuItems: T[];
  customClassName?: string;
  renderItemHeader: () => ReactElement | ReactNode;
  renderItemFooter?: () => ReactElement | ReactNode;
  renderMenuItem: (item: T) => ReactElement | ReactNode;
  anchorEl: ReactNode;
  onOpen?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void;
  onClose?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void;
  isMenuOpen: boolean;
}

function DropdownDownloadList<T>({
  customClassName,
  renderItemHeader,
  renderItemFooter,
  menuItems,
  renderMenuItem,
  anchorEl,
  onOpen,
  onClose,
  isMenuOpen,
}: DropdownDownloadListProps<T>) {
  const themeClassMenu = useGetThemeClass('b-dropdownDownloadList');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  useEffect(() => {
    if (isMenuOpen) {
      onOpen && onOpen();
    }
  }, [isMenuOpen]);

  return (
    <>
      <Popover
        open={!!isMenuOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        onOpen={onOpen}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          className={cn(`${themeClassMenu}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          {renderItemHeader ? <>{renderItemHeader()}</> : null}
          <div className={`${themeClassMenu}_itemList`}>
            <div className={`${themeClassMenu}_itemList_group`}>
              {menuItems?.length > 0 ? (
                menuItems?.map(menuItem => {
                  return <>{renderMenuItem(menuItem)}</>;
                })
              ) : (
                <div className={`${themeClassMenu}_empty`}>
                  <span>No items</span>
                </div>
              )}
            </div>
          </div>
          {renderItemFooter ? <>{renderItemFooter()}</> : null}
        </div>
      </Popover>
    </>
  );
}

export default DropdownDownloadList;
