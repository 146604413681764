import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import { formatDateISO } from '../../../../../service/date';
import moment from 'moment';
import CustomPopover from '../../../../controls/CustomPopover/CustomPopover';
import { Popover } from '@material-ui/core';
import DateField from '../../../../controls/DateField/DateField';
import CustomCalendar from '../../../../controls/CustomCalendar/CustomCalendar';
import { RequestModel } from '../../../../../models';
import { NeedListRequest } from '../../../../../store/needlist/needListModels';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useAppDispatch } from '../../../../../store/configure/configureStore';

import './SandBoxDate.module.scss';

interface IProps {
  saveChangeHandler: (selectedAtDate: string) => void;
  valueDate: string;
  currentDate: string;
  disabled: boolean;
  error: boolean;
  isUserInRequestParty: boolean;
  isAuthorOfTheRequest: boolean;
  isCreateRequest: boolean;
  isPullRequest: boolean;
  selectedRequest: RequestModel & NeedListRequest;
  promptName: string;
  is_promptBtnOk: boolean;
  requestToNewDueDate: (newDueDate: string) => void;
  min_due_date: string;
  max_due_date: string;
  isNfCard: boolean;
  work_week: number;
  activeCommitmentAction: string;
  setCommitmentAction: (data: string) => void;
  currentPCD: number[];
  listPCD;
  statusRequest: string;
  isShowDueDatePopup: boolean;
  handleOpenConfirmPopup: (e: HTMLDivElement) => void;
  isHideTitle?: boolean;
  isHideDate?: boolean;
  isMobile?: boolean;
  isDisableBorder?: boolean;
  handleCloseAllNotificationPopups?: () => void;
  isAllNotificationPopupsClosed: boolean;
  isDeliverablesPopUpOpened: boolean;
}

//Todo fix problem this 00 day. If user chose data on data up/down button
const SandBoxDate: FC<IProps> = ({
  saveChangeHandler,
  valueDate,
  currentDate,
  disabled,
  error,
  isUserInRequestParty,
  isAuthorOfTheRequest,
  isCreateRequest,
  isPullRequest,
  selectedRequest,
  promptName,
  is_promptBtnOk,
  requestToNewDueDate,
  min_due_date,
  max_due_date,
  isNfCard,
  work_week,
  activeCommitmentAction,
  setCommitmentAction,
  currentPCD,
  listPCD,
  statusRequest,
  isShowDueDatePopup,
  handleOpenConfirmPopup,
  isHideTitle = false,
  isHideDate = false,
  isMobile = false,
  isDisableBorder = false,
  handleCloseAllNotificationPopups,
  isAllNotificationPopupsClosed,
  isDeliverablesPopUpOpened,
}) => {
  const dispatch = useAppDispatch();

  const today = new Date(moment().add(2, 'weeks'));
  const [value, setValue] = useState<string>(formatDateISO(valueDate || today));
  const [confirmChangeDueDate, setConfirmChangeDueDate] = useState<string>(null);
  const [prevValue, setPrevValue] = useState<string>(null);
  const [isDiffDueScheduledDate, setIsDiffDueScheduledDate] = useState<boolean>(null);
  const [typeDueDate, setTypeDueDate] = useState<string>('');
  const [infoChangeDueDate, setInfoChangeDueDate] = useState<string>('');
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const themeClass = useGetThemeClass('b-sand-box-date');

  const dueDateRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isAllNotificationPopupsClosed) {
      handleCloseDue();
    }
  }, [isAllNotificationPopupsClosed]);

  const checkIsShowConfirmPopup = () => {
    return !(isCreateRequest || isPullRequest || selectedRequest.lastUserStatus.status === 'drafted');
  };

  const handleChange = date => {
    const newDate = moment(date).format('MM/DD/YYYY');
    if (isOpenCalendar && saveChangeHandler) {
      if (checkIsShowConfirmPopup() && newDate !== moment(value).format('MM/DD/YYYY')) {
        setConfirmChangeDueDate(newDate);
        setIsDiffDueScheduledDate(false);
        setTypeDueDate('');
        setInfoChangeDueDate('');
        return;
      }
      handleChangeNow(date);
    }
  };

  const handleOpenCalendar = e => {
    const isSentRequestToChange = !!selectedRequest?.due_date_change_request;
    if (!isShowDueDatePopup && !isPullRequest) {
      return;
    }
    if (isSentRequestToChange) {
      if (isUserInRequestParty) {
        handleOpenConfirmPopup(e);
      }
      return;
    }
    setIsOpenCalendar(true);
    setAnchorEl(isOpenCalendar ? null : dueDateRef.current);

    if (isOpenCalendar && saveChangeHandler) {
      saveChangeHandler(value);
    }
  };

  const handleOpenCalendarFromAction = () => {
    if (disabled && !isPullRequest) {
      return;
    }

    setIsOpenCalendar(true);
    setAnchorEl(isOpenCalendar ? null : dueDateRef.current);
  };

  const handleCloseCalendar = () => {
    const PrevValue = moment(prevValue).format('YYYY-MM-DD');

    if (value !== PrevValue && value !== PrevValue) {
      handleChange(value);
    }

    if (!value || value === 'Invalid Date') {
      const today = new Date(moment().add(2, 'weeks'));
      handleChange(today);
    }

    if (activeCommitmentAction === 'commit_with_due_date') {
      dispatch(setCommitmentAction(''));
    }

    setIsOpenCalendar(false);
  };

  const changeDueDate = date => {
    const newValue = moment(date).format('YYYY-MM-DD');
    saveChangeHandler(newValue);
    setConfirmChangeDueDate(null);
    setValue(newValue);
    setIsOpenCalendar(false);
    setPrevValue(null);
  };

  const handleRequestToNewDueDate = date => {
    requestToNewDueDate(date);
    setConfirmChangeDueDate(null);
    setIsOpenCalendar(false);
    setPrevValue(null);
  };

  const handleChangeNow = newValue => {
    let value = newValue;

    if (typeof newValue !== 'string') {
      value = moment(newValue).format('MM/DD/YYYY');
    }

    changeDueDate(value);
    setIsOpenCalendar(false);

    setIsDiffDueScheduledDate(false);
  };

  const handleSendRequest = newValue => {
    handleRequestToNewDueDate(newValue);
  };

  const changeDueDatePopupAll = () => {
    if ((currentPCD || listPCD) && !isDeliverablesPopUpOpened) {
      const arrAllRows = [];

      listPCD?.forEach(item => {
        arrAllRows.push(item.drawings ? item.drawings : []);
      });
      const drawingsRows = arrAllRows.flatMap(item => item);
      const drawingsRowsAll = drawingsRows.flatMap(item => item.deliverables).filter(item => item);
      const drawings = drawingsRowsAll
        .filter(item => currentPCD.findIndex(value => value === item.id) > -1)
        .map(item => item.scheduled_date)
        .filter(f => !!f);
      drawings.sort((a, b) => new Date(a) - new Date(b));

      const scheduled_date = drawings && drawings[0] ? moment(drawings[0]).subtract(2, 'days').format('YYYY-MM-DD') : null;
      const selected_date = moment(value).format('YYYY-MM-DD');
      const isDueScheduledDate = new Date(selected_date) - new Date(scheduled_date);
      const change_scheduled_date = moment(scheduled_date).format('MM/DD/YYYY');

      if (scheduled_date) {
        if (statusRequest === 'Response Submitted') {
          setIsDiffDueScheduledDate(true);
          setTypeDueDate('');
          setInfoChangeDueDate('');
        } else if (
          (statusRequest === 'Request Sent' || statusRequest === 'Commitment Submitted' || statusRequest === 'Request Re-opened') &&
          isDueScheduledDate > 0
        ) {
          setTypeDueDate('info');
          setIsDiffDueScheduledDate(true);
          setInfoChangeDueDate(change_scheduled_date);
          setIsOpenCalendar(false);
          setAnchorEl(dueDateRef.current);
        }
      }
    }
  };

  const closeConfirmChangeDueDate = () => {
    dispatch(setCommitmentAction(''));
    setConfirmChangeDueDate(null);
    setIsOpenCalendar(false);
    setPrevValue(null);
  };

  const handleCloseDue = () => {
    setIsDiffDueScheduledDate(false);
    handleCloseAllNotificationPopups && handleCloseAllNotificationPopups();
  };

  useEffect(() => {
    setIsDiffDueScheduledDate(null);
    setTypeDueDate('');
    setInfoChangeDueDate('');
  }, [selectedRequest?.nf]);

  useEffect(() => {
    if (currentPCD.length === 0) {
      setIsDiffDueScheduledDate(null);
      setTypeDueDate('');
      setInfoChangeDueDate('');
    }
  }, [currentPCD]);

  useEffect(() => {
    setIsDiffDueScheduledDate(null);
    setTypeDueDate('');
    setInfoChangeDueDate('');

    if (listPCD?.length > 0 && isShowDueDatePopup) {
      changeDueDatePopupAll(true);
    }
  }, [listPCD, isShowDueDatePopup, isDeliverablesPopUpOpened]);

  useEffect(() => {
    if (listPCD?.length > 0 && currentPCD.length > 0) {
      if (isShowDueDatePopup) {
        changeDueDatePopupAll(false);
      }
    }
  }, [listPCD, currentPCD?.length]);

  useEffect(() => {
    changeDueDatePopupAll(true);
  }, [statusRequest, valueDate]);

  useEffect(() => {
    if (promptName === 'dueDateChangeBtn') {
      requestToNewDueDate(value);
    }
  }, [is_promptBtnOk]);

  useEffect(() => {
    if (activeCommitmentAction === 'commit_with_due_date') {
      handleOpenCalendarFromAction();
    }
  }, [activeCommitmentAction]);

  useEffect(() => {
    if (!isCreateRequest) {
      const today = new Date(moment().add(2, 'weeks'));
      setValue(formatDateISO(valueDate || today));
    }
  }, [valueDate]);

  useEffect(() => {
    if (isDiffDueScheduledDate) {
      setTimeout(() => handleCloseDue(), 10000);
    }
  }, [isDiffDueScheduledDate]);

  const isSentRequestToChange = useMemo(() => {
    return !!selectedRequest?.due_date_change_request;
  }, [selectedRequest]);

  const valueIsDate = useMemo(() => {
    return value !== 'Invalid date' && !!value;
  }, [value]);

  const date = useMemo(() => {
    return valueIsDate ? moment(value)._d : new Date(moment().add(2, 'weeks').format('YYYY-MM-DD'));
  }, [valueIsDate, value]);

  const isBefore = useMemo(() => {
    return (
      moment(date).isBefore(moment().add(1, 'day').format('YYYY-MM-DD')) &&
      !(selectedRequest?.lastUserStatus ? selectedRequest.lastUserStatus?.status === 'accepted' : selectedRequest?.status === 'accepted')
    ); // true
  }, [date, selectedRequest]);

  const selectedDate = useMemo(() => {
    return (valueIsDate && date && valueDate) || (isPullRequest && valueDate) ? moment(valueDate).format('MM/DD/YYYY') : null;
  }, [valueIsDate, date, valueDate, isPullRequest]);

  const canChangeDueDateNow = useMemo(() => {
    return (isAuthorOfTheRequest || isUserInRequestParty) && !(activeCommitmentAction === 'commit_with_due_date');
  }, [isAuthorOfTheRequest, isUserInRequestParty, activeCommitmentAction]);

  return (
    <>
      <CustomTooltip
        enterDelay={500}
        leaveDelay={0}
        title={
          typeDueDate === 'info' && isShowDueDatePopup ? (
            <div>
              <div>Due date is less than 2 days before earliest linked</div>
              <div>
                <span>Deliverable. Think of changing it to </span>
                <span className={'text_gold'}>{infoChangeDueDate}</span>
              </div>
            </div>
          ) : (
            ''
          )
        }
        placement={'bottom'}
      >
        <div ref={dueDateRef} style={{ position: 'relative' }}>
          <CustomTooltip
            enterDelay={500}
            leaveDelay={0}
            title={
              localStorage.getItem('show_tooltips') === 'no' || typeDueDate === 'info'
                ? ''
                : isSentRequestToChange
                ? 'Accept/decline proposed due date'
                : isBefore || error
                ? 'NF is overdue'
                : selectedRequest?.lastUserStatus?.status === 'drafted' || !selectedRequest?.lastUserStatus?.status
                ? 'Due date'
                : 'Request due date change'
            }
            placement={'bottom'}
          >
            <DateField
              title={isHideTitle ? '' : 'Due date'}
              selectedDate={selectedDate}
              isSentRequestToChange={isSentRequestToChange}
              isOpenCalendar={isOpenCalendar}
              isDiffDueScheduledDate={isDiffDueScheduledDate}
              info={typeDueDate === 'info'}
              disabled={disabled}
              isBefore={isBefore}
              error={error}
              valueIsDate={!selectedDate ? false : valueIsDate}
              clickHandler={handleOpenCalendar}
              isHideDate={isHideDate}
              isMobile={isMobile}
              isDisableBorder={isDisableBorder}
            />
          </CustomTooltip>
        </div>
      </CustomTooltip>
      <CustomPopover
        open={isOpenCalendar}
        onClose={handleCloseCalendar}
        anchorEl={anchorEl}
        hideTitle
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        contentStyles={{
          padding: '0',
        }}
        styles={{
          overflowX: 'visible',
          overflowY: 'visible',
          marginTop: '4px',
        }}
      >
        <div className={`calendarContainer ${isNfCard ? '-isNfCard' : ''}`}>
          <CustomCalendar
            handleChange={handleChange}
            value={value}
            min_due_date={min_due_date}
            max_due_date={max_due_date}
            work_week={work_week}
            passed={isBefore}
          />
        </div>
      </CustomPopover>
      {confirmChangeDueDate && !typeDueDate && (
        <DialogPopUp open={!!confirmChangeDueDate} onClose={closeConfirmChangeDueDate} isOverlay={true} paperMaxWidth={'661px'}>
          <div className={`${themeClass}`}>
            <div className={`${themeClass}_header`}>
              <div className={`${themeClass}_header_title`}>Send a change request?</div>
              <div className={'svg_icon'}>
                <TokenIcon iconName={'close'} size={16} clickHandler={closeConfirmChangeDueDate} />
              </div>
            </div>
            <div className={`${themeClass}_container`}>
              <div className={'dialog-text'}>
                You are trying to change a due date from<div className={'text_warning'}>{selectedDate}</div>to
                <div className={'text_warning'}>{confirmChangeDueDate}</div>
              </div>
              <div className={'dialog-text'}>If you send request, it has to be approved by the responsible person.</div>
            </div>
            <div className={`${themeClass}_button_group`}>
              <CustomButton type={'text-plain'} size={'md'} clickHandler={closeConfirmChangeDueDate} title={'Cancel'} isMobile={isMobile} />
              {canChangeDueDateNow && (
                <CustomButton
                  type={'tertiary'}
                  size={'md'}
                  clickHandler={() => handleChangeNow(confirmChangeDueDate)}
                  title={'Change now'}
                  isMobile={isMobile}
                />
              )}
              <CustomButton
                type={'primary'}
                size={'md'}
                clickHandler={() => handleSendRequest(confirmChangeDueDate)}
                title={'Send request'}
                isMobile={isMobile}
              />
            </div>
          </div>
        </DialogPopUp>
      )}
      {isDiffDueScheduledDate && isShowDueDatePopup && (
        <Popover
          id={'infoDueDate'}
          open={isDiffDueScheduledDate}
          onClose={handleCloseDue}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={`${themeClass}_isDueDateMoreScheduledDate`} onClick={handleCloseDue}>
            {typeDueDate === 'change' ? (
              <div>
                <div className={'dialog-text-msg'}>Due date was set to allow resolving this request</div>
                <div className={'dialog-text-msg'}>2 days before the earliest Scheduled date of linked</div>
                <div className={'dialog-text-msg'}>Deliverable cards.</div>
              </div>
            ) : typeDueDate === 'info' ? (
              <div>
                <div className={'dialog-text-msg'}>Due date is less than 2 days before earliest linked</div>
                <div>
                  <span className={'dialog-text-msg'}>Deliverable. Think of changing it to </span>
                  <span className={'dialog-text-msg -text_gold'}>{infoChangeDueDate}</span>
                </div>
              </div>
            ) : null}
          </div>
        </Popover>
      )}
    </>
  );
};

export default React.memo(SandBoxDate);
