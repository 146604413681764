import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './StatusProjectStyles.module.scss';

const StatusProject: FC<{
  project_status: 'planning' | 'active' | 'paused' | 'archived' | 'Select project status';
  isEditable?: boolean;
}> = ({ project_status, isEditable }) => {
  const themeClass = useGetThemeClass('b-statusProject');
  const ucFirst = str => {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  };
  return (
    <>
      {project_status && (
        <div className={`${themeClass} ${project_status === 'Select project status' ? 'unassigned' : project_status}`}>
          {project_status !== 'Select project status' && (
            <div
              className={cn(`${themeClass}_svg_icon`, {
                [`${themeClass}_secondary`]: project_status === 'planning',
                [`${themeClass}_sys-success`]: project_status === 'active',
                [`${themeClass}_tertiary-gray`]: project_status === 'paused',
                [`${themeClass}_tertiary-gray`]: project_status === 'archived',
              })}
            >
              <TokenIcon iconName={'status'} size={12} />
            </div>
          )}
          <div
            className={cn('label_text', {
              [`${themeClass}_on-additional`]: project_status === 'planning' || project_status === 'active',
              [`${themeClass}_on-tertiary-container-gray-variant`]: project_status === 'paused' || project_status === 'archived',
              [`${themeClass}_on-unassigned`]: project_status === 'Select project status',
            })}
          >
            {ucFirst(project_status)}
          </div>
          {isEditable && (
            <div className={`${themeClass}_svg_icon ${themeClass}_secondary_arrow`}>
              <TokenIcon iconName={'chevron-down'} size={16} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(StatusProject);
