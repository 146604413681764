import { RequestModel, ResponseActionMessageHelperPayload, SelectedRequestCompanyModel } from '../models';
import moment from 'moment';

export function getResponsePartySelect(selectedRequest: RequestModel): SelectedRequestCompanyModel {
  const selectedResponseCompany = {
    company: selectedRequest.response_party_id
      ? {
          ...selectedRequest.response_party,
          id: selectedRequest.response_party_id,
          title: selectedRequest.response_party?.company,
        }
      : null,
    discipline: selectedRequest.resp_disc_id
      ? {
          id: selectedRequest.resp_disc_id,
          title: selectedRequest.response_discipline?.title || '',
          text_color: selectedRequest.response_discipline?.text_color || '',
        }
      : null,
    user: selectedRequest.response_user?.id
      ? {
          ...selectedRequest.response_user,
          id: selectedRequest.response_user.id,
          title: `${selectedRequest.response_user.profile.first_name} ${selectedRequest.response_user.profile.last_name}`,
        }
      : null,
  };

  return selectedResponseCompany;
}

export function getResponseActionMessage(selectedRequest: RequestModel): ResponseActionMessageHelperPayload {
  let prefix, color, date;

  if (!selectedRequest?.commit_action || !Object.values({ ...(selectedRequest.commit_action || {}) }).length) {
    return null;
  }

  let dueDateCheck;

  if (selectedRequest.commit_action.action === 'due_date') {
    dueDateCheck = moment(new Date()).isBefore(selectedRequest.commit_action.date);
  } else {
    dueDateCheck = moment(new Date()).isBefore(selectedRequest.due_date);
  }

  if (selectedRequest.commit_action.action === 'commit') {
    if (dueDateCheck) {
      prefix = 'Response will be provided by';
      color = 'default';
    } else {
      prefix = 'Response should be provided by';
      color = 'danger';
    }

    date = `before ${moment(selectedRequest.due_date).format('MM/DD/YYYY')}`;
  }

  if (selectedRequest.commit_action.action === 'due_date') {
    if (dueDateCheck) {
      prefix = 'Response will be provided by';
      color = 'warning';
    } else {
      prefix = 'Response should be provided by';
      color = 'danger';
    }

    date = `before ${moment(selectedRequest.commit_action.date).format('MM/DD/YYYY')}`;
  }

  if (selectedRequest.commit_action.action === 'pull_request') {
    if (dueDateCheck) {
      prefix = 'Response will be provided by';
      color = 'warning';
    } else {
      prefix = 'Response should be provided by';
      color = 'danger';
    }

    date = `before ${moment(selectedRequest.due_date).format('MM/DD/YYYY')}`;
  }

  return {
    prefix,
    color,
    date,
  };
}
