export const pdfTronSupportedTypes = [
  'PDF',
  'DOC',
  'DOCX',
  'PNG',
  'JPEG',
  'JPG',
  'XLS',
  'XLSX',
  'PPTX',
  // 'XLS', // server
  // 'DWG',
  // 'DXF', 'BIN', 'TXT',
  // 'DGN',
  // 'RVT',
  // 'DWF',
  // 'RTF',
  // 'ODT', 'ODS', 'ODP',
  // 'BMP',
  // 'GIF',
  // 'TIF',
  // 'SVG',
  // 'XML',
];

export const iframeSupportedTypes = [
  // 'TXT',
];
