import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { CustomButton } from '../../../../controls/ButtonComponents';
import { useGetThemeClass } from '../../../../../helpers/designTokens';

import './SandBoxRelationSwitcherStyle.mobile.scss';

interface IProps {
  relatedTo: 'user' | 'party';
  setRelation: (relation: 'user' | 'party') => void;
  isMobile?: boolean;
}

const RelationSwitcher: FC<IProps> = ({ setRelation, relatedTo, isMobile }: IProps) => {
  const buttonRef = useRef();
  const themeClass = useGetThemeClass('b-sandBox-relation-switcher');

  const handleSetRelation = () => {
    if (relatedTo === 'user') {
      setRelation('party');
    }

    if (relatedTo === 'party') {
      setRelation('user');
    }
  };

  const buttonType = useMemo(() => {
    if (relatedTo === 'user') {
      return 'selection-activated';
    }

    if (relatedTo === 'party') {
      return 'selection-plain';
    }
  }, [relatedTo]);

  return (
    <div id={'sandBoxRelation-tourBlock'} className={isMobile ? `${themeClass}_mobileRelation` : ''} ref={buttonRef}>
      <CustomButton type={buttonType} size={'md'} title={'My items only'} clickHandler={handleSetRelation} isMobile={isMobile} />
    </div>
  );
};

export default RelationSwitcher;
