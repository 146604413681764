import { ActivityCommentModel, ActivityModel } from '../models/request/activity.model';
import { FileModel } from '../models';
import { AxiosResponse } from 'axios';
import { FILE_SIZE_LIMIT } from '../service/file';

export default class FileHelper {
  public static isFileActivity = (file_id: number, fileType: 'R' | 'C' | 'FC' | 'Comment', activityModel: ActivityModel): boolean => {
    if (fileType === 'R' && activityModel?.details?.files) {
      return !!activityModel.details.files.find(f => f === file_id);
    }
    if (fileType === 'Comment' && activityModel?.details?.comments?.length) {
      const files = [];
      activityModel.details.comments.map((comment: ActivityCommentModel) => {
        files.push(...comment.files);
      });
      if (files.length) {
        return !!files.find(f => f === file_id);
      }
    }
    return false;
  };

  public static getFileById = (file_id: number, files: FileModel[]): FileModel | null => {
    const file = files.find(f => f.id === file_id);
    return file || null;
  };
  public static getSourceName = source => {
    if (source === 'R') {
      return 'req';
    } else if (source === 'C') {
      return 'cmt';
    } else if (source === 'Comment') {
      return 'com';
    } else if (source === 'FC') {
      return 'dlv';
    } else if (source === 'Sub') {
      return 'sub';
    }
    return '';
  };

  public static getFileName(response: AxiosResponse): string | null {
    const filenamePatternPDF = /filename="([^"]+)"/;
    const filenamePatternXLSX = /filename=([^;]+)/;
    const matchPdf = response.headers['content-disposition'].match(filenamePatternPDF);
    const matchXlsx = response.headers['content-disposition'].match(filenamePatternXLSX);
    if (matchPdf) {
      return matchPdf[1];
    }
    if (matchXlsx) {
      return matchXlsx[1];
    }
    return null;
  }

  static separateByMaxSize(files: FileModel[] | null): FileModel[][] | null {
    if (!files?.length) {
      return null;
    }
    files.sort((a, b) => a.size - b.size);
    const maxSum = FILE_SIZE_LIMIT;
    let tempSum = 0;
    let tempArr: FileModel[] = [];
    const result: FileModel[][] = [];

    files.forEach(file => {
      if (tempSum + file.size <= maxSum) {
        tempSum += file.size;
        tempArr.push(file);
      } else {
        result.push(tempArr);
        tempArr = [file];
        tempSum = file.size;
      }
    });

    if (tempArr.length > 0) {
      result.push(tempArr);
    }
    return result;
  }
}
