import React from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import TokenIcon from '../TokenIcon/TokenIcon';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';

import './AccordionFeedTabStyles.scss';

interface IProps {
  accordionOpened: boolean | number[];
  openAccordion: (index?: number) => void;
  count: number;
  type: 'attachment' | 'reply';
  index?: number;
}

const AccordionFeedTab: React.FC<IProps> = ({ accordionOpened, openAccordion, count, type, index, isAllExpanded }) => {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-feed-accordion');

  const handleOpenAccordion = () => {
    index !== undefined ? openAccordion(index) : openAccordion();
  };

  const renderTitle = () => {
    if (type === 'attachment') {
      if (count === 1) {
        return '1 Attachment';
      }
      return `${count} Attachments`;
    }

    if (type === 'reply') {
      if (count === 1) {
        return '1 Reply';
      }
      return `${count} Replies`;
    }
  };

  const renderExpanded = () => {
    if (index !== null && index !== undefined) {
      return accordionOpened.includes(index) ? '-expanded' : '';
    }

    if (accordionOpened) {
      return '-expanded';
    } else {
      return '';
    }
  };

  return (
    <div
      className={cn(themeClass, {
        ['-mobile']: isMobile,
      })}
    >
      <div className={`${themeClass}_container `} onClick={handleOpenAccordion}>
        <div className={`${themeClass}_arrow ${renderExpanded()}`}>
          <TokenIcon iconName={'chevron-right'} size={isMobile ? 24 : 16} />
        </div>
        <span className={`${themeClass}_title ${renderExpanded()}`}>{renderTitle()}</span>
      </div>
    </div>
  );
};

export default AccordionFeedTab;
