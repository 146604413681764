import React, { useState, MouseEvent, useMemo } from 'react';
import DropdownPopoverFilter from '../../DropdownPopovers/DropdownPopoverFilter/DropdownPopoverFilter';
import { FilterButton, SystemButton } from '../../../ButtonComponents';
import { DropdownItemModel } from '../../../../../models/global';
import _ from 'lodash';

interface IProps {
  disabled?: boolean;
  isActive: boolean;
  groupList?: DropdownItemModel[];
  values?: DropdownItemModel[];
  onClickOrBtn?: () => void;
  isOrFilter?: boolean;
  orButtonActive?: boolean;
  selectedIds?: string[];
  resetFilters: () => void;
  selectFilters: (value: string[]) => void;
  showOrButton?: boolean;
  isHighResolution?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  isMobile?: boolean;
  handleSelectedFilter?: (newSelectedFilter: string[]) => void;
  selectedFilter?: string[];
}

const FilterSelector = ({
  disabled,
  isActive,
  groupList,
  values,
  onClickOrBtn,
  isOrFilter,
  orButtonActive,
  selectedIds,
  resetFilters,
  selectFilters,
  showOrButton,
  onMenuOpen,
  onMenuClose,
  isMobile,
  handleSelectedFilter,
  isHighResolution,
  selectedFilter,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const itemsToShow = useMemo(() => {
    if (values && values?.length) {
      const inputValue = _.escapeRegExp(searchValue)?.trim().toLowerCase();
      return values.filter(item => {
        return item.title?.trim().toLowerCase().search(inputValue) !== -1;
      });
    }

    return [];
  }, [values, searchValue]);

  const handleOpen = (e: MouseEvent<HTMLDivElement>) => {
    setIsOpen(true);
    setAnchorElFilter(e.currentTarget);
    handleSelectedFilter(selectedIds);
    onMenuOpen && onMenuOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorElFilter(null);
    setSearchValue('');
    const newSelectedFilter = selectedIds.filter(item => !selectedFilter.find(value => value === item));
    if (newSelectedFilter.length > 0) {
      handleSelectedFilter(newSelectedFilter);
    }
    onMenuClose && onMenuClose();
  };

  const changeSearch = (value: string) => {
    setSearchValue(value);
  };

  const changeOrBtn = () => {
    onClickOrBtn();
  };

  const handleClearItems = () => {
    resetFilters && resetFilters();
  };

  const handleAddId = (value: string | number) => {
    let newArray = [...selectedIds];
    if (selectedIds.includes(value)) {
      newArray = selectedIds.filter(item => item !== value);
    } else {
      newArray.push(value);
    }
    selectFilters(newArray);
  };

  return (
    <>
      {isMobile ? (
        <SystemButton type={'filter'} size={'lg'} variant={'transparent'} isMobile clickHandler={handleOpen} />
      ) : (
        <FilterButton type={'filter'} clickHandler={handleOpen} isPressed={isOpen} isActive={isActive} disabled={disabled} />
      )}
      <DropdownPopoverFilter
        groupList={groupList}
        filterItemList={itemsToShow}
        handleChange={handleAddId}
        selectedIds={selectedIds}
        showClearBtn={!!selectedIds.length}
        handleClearItems={handleClearItems}
        isOrFilter={isOrFilter}
        handleChangeOrBtn={changeOrBtn}
        handleChangeSearch={changeSearch}
        searchValue={searchValue}
        isOpenPopover={isOpen}
        anchorEl={anchorElFilter}
        closePopover={handleClose}
        showOrButton={showOrButton}
        orButtonActive={orButtonActive}
        isMobile={isMobile}
        isHighResolution={isHighResolution}
      />
    </>
  );
};

export default FilterSelector;
