import React, { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { useClickOutside } from '../../../../../helpers/commonHooks';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const BallInCourtSearch = ({
  searchValue,
  searchUserOrCompany,
  ballInCourtItemsList,
  chooseUserOrCompany,
  chosenItem,
  deleteChosenItem,
  disabled,
}) => {
  const initialState = {
    anchorEl: null,
    popOverVisible: false,
  };

  const [state, setState] = useState(initialState);

  const onChangeInput = ({ currentTarget }) => {
    let popOverVisible = false;
    let anchorEl = null;
    if (currentTarget.value) {
      popOverVisible = true;
      anchorEl = currentTarget;
    }
    setState({ ...state, anchorEl, popOverVisible });
    searchUserOrCompany && searchUserOrCompany(currentTarget.value);
  };

  const closePopOver = () => {
    setState({
      ...state,
      popOverVisible: false,
      anchorEl: null,
    });
  };

  // const handleSelect = (mainHeadId, subHeadId) => {
  //     scrollToHeader(mainHeadId, subHeadId)
  // };

  const compRef = useRef();
  useClickOutside(compRef, closePopOver);

  const renderPopper = () => {
    if (searchValue.length) {
      if (ballInCourtItemsList && ballInCourtItemsList.length) {
        return (
          <Popper
            id={`transitions-popper=ballInCourt`}
            style={{ zIndex: 1000, borderRadius: '2px', position: 'absolute' }}
            anchorEl={state.anchorEl}
            open={state.popOverVisible}
            placement="bottom-start"
            disablePortal={true}
            modifiers={{
              flip: { enabled: true },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
          >
            <Paper>
              <div className={'watchingGroupPopup -ballInCourt'}>
                {!!ballInCourtItemsList?.length && (
                  <div className={'groupsList'}>
                    {ballInCourtItemsList?.map((item, index) => {
                      return (
                        <div key={item.id} className={'NeedListSelectItemFilter'}>
                          <MenuItem value={item.id} className={`menuItem`} onClick={() => chooseUserOrCompany(item)}>
                            <ListItemText>
                              <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                {item.user_id ? `${item.first_name} ${item.last_name}` : `${item.company}`}
                              </Typography>
                            </ListItemText>
                          </MenuItem>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Paper>
          </Popper>
        );
      }
    }
  };

  const renderC = () => {
    return (
      <div ref={compRef} className={'ballInCourtSearchContainer'}>
        <div className={'ballInCourtSearch'}>
          <input
            disabled={disabled}
            autoFocus
            value={searchValue}
            onChange={onChangeInput}
            className={`ballInCourtSearch__searchInput ${disabled ? '-disabled' : ''}`}
            type="text"
            placeholder={'Search for user or company name'}
            aria-describedby={`transitions-popper=ballInCourt`}
          />
          <SVG src={require('../../../../../assets/icons/search.svg')} />
        </div>
        <div className={'chosenItem'} style={chosenItem ? {} : { display: 'none' }}>
          <span className={`chosenItem__name ${disabled ? '-disabled' : ''}`}>
            {chosenItem?.user_id ? `${chosenItem?.first_name} ${chosenItem?.last_name}` : `${chosenItem?.company}`}
          </span>
          <span style={disabled ? { display: 'none' } : {}} className={'chosenItem__wrapItemRemove'} onClick={deleteChosenItem}>
            <SVG src={require('../../../../../assets/icons/cross_thin.svg')} />
          </span>
        </div>
        {renderPopper()}
      </div>
    );
  };

  return renderC();
};

export default BallInCourtSearch;
