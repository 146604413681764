import React, { useRef } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SVG from 'react-inlinesvg';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { useAppSelector } from '../../../store/configure/configureStore';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import cn from 'classnames';

const popoverButton = () => (
  <div className={'clients__popover__button-content'}>
    <span className={'clients__popover__button-icon'}>
      <SVG src={require('../../../assets/icons/plus_icon.svg')} />
    </span>
    <span className={'clients__popover__button-text'}>Add company</span>
  </div>
);

const SelectProjectView = ({
  containerClassName,
  user_data,
  active_project_id,
  selectProject,
  isOpenProjectsSelect,
  clientProjects,
  handleOpenProjectsSelect,
  handleCloseProjectsSelect,
  currentClientLogo,
  currentClient,
  clearData,
}: any) => {
  const clientsMenuRef = useRef(null);
  const { isMobile } = GetResolution();

  const promptData = useAppSelector(state => state.settingsReducer.globalPromptPopUp);

  const IconComponent = () => (
    <div className={`${containerClassName}_select_icon ${isOpenProjectsSelect ? '-opened' : ''}`}>
      <SVG src={require('../../../assets/icons/arrow_right_icon.svg')} />
    </div>
  );

  if (!user_data.userInfo?.projects?.length) {
    return <></>;
  }

  const handleClick = e => {
    if (promptData.isChanged) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div onClick={handleOpenProjectsSelect} className={`${containerClassName} ${isOpenProjectsSelect ? '-opened' : ''}`}>
        <div id={'selectProject-tourBlock'}>
          <Select
            value={active_project_id}
            onChange={selectProject}
            onOpen={() => {}}
            displayEmpty={false}
            IconComponent={IconComponent}
            name="project"
            MenuProps={{
              ...{
                ...(isMobile
                  ? {
                      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                      transformOrigin: { horizontal: 'center', vertical: 'top' },
                    }
                  : {}),
              },
              className: `${containerClassName}_select_selectPopover`,
              classes: {
                paper: `${containerClassName}_select_selectPaper`,
              },
            }}
            className={`${containerClassName}_select select aside__select ${!user_data.userInfo?.projects?.length ? '-disabled' : ''}`}
            open={isOpenProjectsSelect}
            onClose={handleCloseProjectsSelect}
            disabled={!user_data.userInfo?.projects?.length}
            classes={{
              root: `${containerClassName}_select_root`,
              select: `${containerClassName}_select_input`,
            }}
          >
            {clientProjects &&
              clientProjects.map(({ id, title, status }) => (
                // @ts-ignore
                <MenuItem
                  className={cn(`${containerClassName}_select_menuItem`, {
                    ['-active']: id === active_project_id,
                    ['-archived']: status === 'archived',
                  })}
                  key={id}
                  value={id}
                  disableRipple
                >
                  <em className={status === 'archived' ? `${containerClassName}_select_menuItem_archivedTitle -whiteSpace` : ''}>
                    {title}
                  </em>
                  {status === 'archived' && (
                    <em
                      className={status === 'archived' ? `${containerClassName}_select_menuItem_archivedTitle -selectedArchivedHidden` : ''}
                    >
                      Archived
                    </em>
                  )}
                </MenuItem>
              ))}
            <div className={`${containerClassName}_select_clientMenuContainer`}>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={`${containerClassName}_select_clientMenu`}
                ref={clientsMenuRef}
              >
                <div className={`${containerClassName}_select_clientMenu_container`}>
                  <PlaceholderInfo size={24} type={'Company'} imagePath={currentClientLogo} detailInfo={currentClient?.title} />
                  <Link to={'/change-company'} onClick={handleClick}>
                    <CustomButton
                      type={'text-plain'}
                      size={'xs'}
                      title={isMobile ? 'Change' : 'Change company'}
                      clickHandler={() => clearData()}
                      isMobile={isMobile}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Select>
        </div>
      </div>
    </>
  );
};

export default SelectProjectView;
