import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISandBoxReducer, SandBoxFiltersModel } from './sandBoxReducer.model';

const initialState: ISandBoxReducer = {
  sandBoxFilters: {
    requestStatusFilters: [],
    commitementStatusFilter: [],
    presetFilters: [],
  },
};

export const sandBoxSlice = createSlice({
  initialState,
  name: 'sandBoxReducer',
  reducers: {
    setSandBoxFilters(state, { payload }: PayloadAction<SandBoxFiltersModel>) {
      state.sandBoxFilters = payload;
    },
  },
});

export default sandBoxSlice.reducer;
