import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';
import { statusRevisionType, statusRevision, reviewStateType, reviewState } from '../../../../constants/cardTypeConstants';

import './StatusRevisionStyles.module.scss';
import CustomTooltip from '../../Tooltip/Tooltip';

const StatusRevision: FC<{
  status: statusRevisionType;
  review_state?: reviewStateType;
  isVisibleLabel?: boolean;
  labelText?: string;
  tooltip?: string;
  showTextLabelAsTooltip?: boolean;
  isMobile?: boolean;
  customClassName?: string;
}> = ({ status, review_state, isVisibleLabel = false, labelText, tooltip, isMobile, showTextLabelAsTooltip = false, customClassName }) => {
  const themeClass = useGetThemeClass('b-statusRevision');
  const ucFirst = str => {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  };
  const text_label = status === statusRevision.not_issued_yet ? 'Not issued yet' : `${ucFirst(status)} | ${ucFirst(review_state)}`;
  const isStatusExist = status && review_state;

  return (
    <>
      <CustomTooltip title={tooltip || (showTextLabelAsTooltip && text_label) || ''} placement={'bottom'}>
        <div
          className={cn(`${themeClass}`, {
            [`mobile`]: isMobile,
            [`${customClassName}`]: customClassName,
          })}
        >
          <div
            className={cn(`${themeClass}_svg_icon`, {
              [`${themeClass}_not_issued_yet`]: status === statusRevision.not_issued_yet,
              [`${themeClass}_sys-warning`]: status === statusRevision.issued && review_state === reviewState.pending,
              [`${themeClass}_sys-success`]: status === statusRevision.issued && review_state === reviewState.approved,
              [`${themeClass}_sys-danger`]: status === statusRevision.issued && review_state === reviewState.returned,
              [`${themeClass}_tertiary-gray`]: status === statusRevision.superseded,
            })}
          >
            {status === statusRevision.not_issued_yet ? (
              <TokenIcon iconName={'cancel'} size={isMobile ? 16 : 12} />
            ) : review_state === reviewState.pending ? (
              <TokenIcon iconName={'dash'} size={isMobile ? 16 : 12} />
            ) : review_state === reviewState.approved ? (
              <TokenIcon iconName={'check'} size={isMobile ? 16 : 12} />
            ) : review_state === reviewState.returned ? (
              <TokenIcon iconName={'close'} size={isMobile ? 16 : 12} />
            ) : null}
          </div>
          {(isVisibleLabel || labelText) && isStatusExist && <div className={'label_text'}>{labelText || text_label || ''}</div>}
        </div>
      </CustomTooltip>
    </>
  );
};

export default React.memo(StatusRevision);
