import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IWithConfirmDialogProps, withConfirmDialog, withConfirmDialogContext } from '../HighOrderComponents';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import Header from './mainParts/Header/Header';
import {
  createSubmittalRevision,
  getSubmittalCard,
  getSubmittalTags,
  removeSubmittalCard,
  setSyncProcoreSubmittal,
  submittalSave,
} from '../../../store/submittal/submittalThunk';
import { getSandBoxCardIDHotList } from '../../../store/request/requestLogic(HOLD)';
import { submittalSlice } from '../../../store/submittal/submittalSlice';
import {
  BreadcrumbModel,
  LocationCollectionModel,
  LoginUserModel,
  MfViewDataModel,
  RequestBuildingModel,
  RequestTypeModel,
  RequestUserModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
  UserModel,
} from '../../../models';
import { ISubmittal, ISubmittalUpdateModel, SubmittalUserModel } from '../../../models/submittals/submittals.model';
import TabsContainer from './mainParts/TabsContainer/TabsContainer';
import { SubmittalCardTabNamesModel, SubmittalCardTabs } from './constants/submittalTabs';
import BottomCardPart from './mainParts/BottomCardPart/BottomCardPart';
import TopCardPart from './mainParts/TopCardPart/TopCardPart';
import * as validator from '../../../service/validator';
import { validatorPatternForDraft, validatorPatternForSend, validatorPatternForSendWithFiles } from './constants/validationObjects';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import { changePrivate } from '../../../store/common/commonThunk';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import Modal from '../../controls/DialogPopUp/DialogPopUp';
import { ImpactModel } from '../../controls/ImpactSelect/ImpactSelect';
import isEqual from 'lodash/isEqual';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './CardSubmittalStyles.scss';

const {
  handleResizableSubmittalCardPart,
  resetSocketSubmittalPrivateState,
  resetSocketSubmittalProcoreState,
  resetSocketSubmittalWatchlistState,
  closeSubmittalCard,
} = submittalSlice.actions;
const { setNewBreadcrumbs } = breadcrumbsSlice.actions;

interface IProps extends IWithConfirmDialogProps {
  selectRequestId: number | null;
  closeRequest: (isRequestClickOutSide?: boolean) => void;
  requestClickOutSide: boolean;
  closeRequestClickOutSide: () => void;
  escapeKeyDown: boolean;
  closeRequestEscapeKeyDown: () => void;
  isDragRequestCard?: boolean;
  isSeparateTab?: boolean;
  isUrlSubmittalCardTab?: boolean;
  handleShowConfirmDialog?: (options) => void;
  isPreview?: boolean;
  isCreateSubmittal?: boolean;
}
let timer;

function CardSubmittal({
  isDragRequestCard,
  selectRequestId,
  closeRequest,
  requestClickOutSide,
  closeRequestClickOutSide,
  escapeKeyDown,
  closeRequestEscapeKeyDown,
  isSeparateTab,
  handleShowConfirmDialog,
  isUrlSubmittalCardTab,
  isPreview,
  isCreateSubmittal,
}: IProps) {
  const dispatch = useAppDispatch();
  const { setUrlCommandCenter, setUrlSubmittal, getSubmittalParamsFromURL } = useRoutingHelper();
  const { urlProject, location, searchParams } = useRouter();
  const themeClass = useGetThemeClass('b-cardSubmittal');

  const { isMobile, is1440Resolution } = GetResolution();
  const { submittalId } = getSubmittalParamsFromURL();

  const bodyContainerRef = useRef(null);

  const {
    submittalInfo,
    availableTags,
    newSubmittalTag,
    isCardLoading,
    socketPrivateState,
    socketWatchlistState,
    socketProcoreState,
    socketHotlistState,
  } = useAppSelector(state => state.submittalSlice);
  const userInfo = useAppSelector<UserModel>(state => state.userReducer.userInfo);
  const breadcrumbs = useAppSelector<BreadcrumbModel[] | null>(state => state.breadcrumbsReducer.firstCardBreadcrumbs);
  const active_project_id = useAppSelector(state => state.userReducer.active_project_id);
  const typicalLayoutsCollection = useAppSelector(state => state.projectReducer.projectInfo.typical_layouts);
  const locationsCollection = useAppSelector(state => state.projectReducer.projectInfo.locations);
  const buildingsCollection = useAppSelector(state => state.projectReducer.projectInfo.buildings);
  const buildingSpacesCollection = useAppSelector(state => state.projectReducer.projectInfo.building_spaces);
  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo);
  const feedData = useAppSelector(state => state.commentReducer.feed.feedData);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const [selectedRequest, setSelectedRequest] = useState<ISubmittal | null>(null);
  const [login_user, setLogin_user] = useState<LoginUserModel>({} as LoginUserModel);
  const [activeTab, setActiveTab] = useState<SubmittalCardTabNamesModel>('general');

  const [isOpenAuthorUser, setIsOpenAuthorUser] = useState<boolean>(false);
  const [coordinatesAuthor, setCoordinatesAuthor] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState<SubmittalUserModel>(null);
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);

  const [promptName, setPromptName] = useState<string>(null);
  const [promptTitle, setPromptTitle] = useState<string>(null);
  const [promptMessage, setPromptMessage] = useState<string>(null);
  const [promptNameBtnSave, setPromptNameBtnSave] = useState<string>(null);
  const [promptNameBtnCancel, setPromptNameBtnCancel] = useState<string>(null);
  const [promptNameBtnOk, setPromptNameBtnOk] = useState<string>(null);
  const [isPromptPopUpView, setIsPromptPopUpView] = useState<boolean>(false);
  const [isPromptBtnOk, setIsPromptBtnOk] = useState<boolean>(false);
  const [isMsgNotShowAgain, setIsMsgNotShowAgain] = useState<boolean>(false);
  const [isShowAgain, setIsShowAgain] = useState<boolean>(false);
  const [isPromptPopUpClose, setIsPromptPopUpClose] = useState<boolean>(false);
  const [canUpdateSaveBtn, setCanUpdateSaveBtn] = useState<boolean>(false);
  const [isHiddenFields, setIsHiddenFields] = useState<boolean>(false);
  const [isUndoPopupOpen, setIsUndoPopupOpen] = useState(false);
  const [canUpdateTopCard, setCanUpdateTopCard] = useState<boolean>(false);
  const [canUpdateDescription, setCanUpdateDescription] = useState<boolean>(false);
  const [isProcoreConfirmPopUpOpen, setIsProcoreConfirmPopUpOpen] = useState<boolean>(false);

  const [buildings, setBuildings] = useState<RequestBuildingModel[]>([]);
  const [locations, setLocations] = useState<LocationCollectionModel[]>([]);
  const [typicalLayouts, setTypicalLayouts] = useState<any[]>([]);
  const [buildingSpaces, setBuildingSpacesProps] = useState<SimpleTitleModel[]>([]);

  const [title_selected, setTitle_selected] = useState<string>(null);
  const [responsible_contractor_selected, setResponsible_contractor_selected] = useState<SelectedRequestCompanyModel>({
    discipline: null,
    company: null,
    user: null,
  });
  const [responsible_manager_selected, setResponsible_manager_selected] = useState<SelectedRequestCompanyModel>({
    discipline: null,
    company: null,
    user: null,
  });
  const [submit_due_date_selected, setSubmit_due_date_selected] = useState<string>(null);
  const [required_due_date_selected, setRequired_due_date_selected] = useState<string>(null);
  const [hashtags_selected, setHashtags_selected] = useState<TagFormModel[]>([] as TagFormModel[]);
  const [buildings_selected, setBuildings_selected] = useState<RequestBuildingModel[]>([]);
  const [locations_selected, setLocations_selected] = useState<LocationCollectionModel[]>([]);
  const [typicalLayouts_selected, setTypicalLayouts_selected] = useState<any[]>([]);
  const [building_spaces_selected, setBuilding_spaces_selected] = useState<SimpleTitleModel[]>([]);
  const [mf_selected, setMf_selected] = useState<MfViewDataModel>(null);
  const [tags_selected, setTags_selected] = useState<RequestTypeModel>(null);
  const [currentDescription, setCurrentDescription] = useState<string>('');
  const [private_selected, setPrivate_selected] = useState<0 | 1>(0);
  const [cl_impacts_selected, setCl_impacts_selected] = useState<ImpactModel[]>([]);
  const [submittalWatchers, setSubmittalWatchers] = useState(null);
  const [submittalWatchersGroups, setSubmittalWatchersGroups] = useState(null);
  const [is_procore_sync_needed_selected, setIs_procore_sync_needed_selected] = useState<boolean | number>(0);
  const [initialFields, setInitialFields] = useState(null);

  const [errors, setErrors] = useState<any>(null);
  const [isSubmitPopupOpen, setIsSubmitPopupOpen] = useState(false);
  const [isDistributePopupOpen, setIsDistributePopupOpen] = useState(false);

  // Delete submittal logic states
  const [isDeleteSubmittalPopUpOpen, setIsDeleteSubmittalPopUpOpen] = useState<boolean>(false);
  const [isDeleteSubmittalLoading, setIsDeleteSubmittalLoading] = useState<boolean>(false);
  const [forceDelete, setForceDelete] = useState<1 | 0 | null>(null);

  const isSidePeekView = isDragRequestCard || is1440Resolution;
  const isDeactivated = selectedRequest?.status?.title === 'Deactivated';
  const isPreviousRevisionDeactivated = !!selectedRequest?.parent;
  const isAnyReturnedReviews = selectedRequest?.reviews?.some(
    review => review.status === 'rejected' || review.status === 'revise_resubmit',
  );

  const isUpdateDraftButtonDisabled = useMemo(() => {
    return (
      ((!canUpdateTopCard && !canUpdateDescription) || isCardLoading || isDeactivated || isPreviousRevisionDeactivated) &&
      !isCreateSubmittal
    );
  }, [isCardLoading, isDeactivated, isPreviousRevisionDeactivated, canUpdateTopCard, canUpdateDescription]);

  const isUpdateDraftButtonDisabledTopCard = useMemo(() => {
    return (
      !canUpdateTopCard ||
      canUpdateDescription ||
      isCardLoading ||
      isDeactivated ||
      isPreviousRevisionDeactivated ||
      selectedRequest?.status?.alias === 'closed'
    );
  }, [isCardLoading, isDeactivated, isPreviousRevisionDeactivated, selectedRequest, canUpdateTopCard, canUpdateDescription]);

  useEffect(() => {
    initRequestField();
  }, [selectedRequest]);

  useEffect(() => {
    if (projectInfo) {
      initDataFromProject();
    }
  }, [projectInfo]);

  useEffect(() => {
    if (
      submittalInfo?.id === selectRequestId &&
      !socketWatchlistState.changedNow &&
      !socketHotlistState.changedNow &&
      !socketPrivateState.changedNow
    ) {
      setSelectedRequest(submittalInfo);
    }
  }, [submittalInfo]);

  useEffect(() => {
    if (isCreateSubmittal) {
      setTags_selected(newSubmittalTag);
    }
  }, [isCreateSubmittal]);

  useEffect(() => {
    if (socketPrivateState.changedNow) {
      setPrivate_selected(socketPrivateState.is_private);
      dispatch(resetSocketSubmittalPrivateState());
    }
  }, [socketPrivateState.changedNow]);

  useEffect(() => {
    if (socketWatchlistState.changedNow) {
      socketWatchlistState.watchers && setSubmittalWatchers(socketWatchlistState.watchers);
      socketWatchlistState.watcher_groups && setSubmittalWatchersGroups(socketWatchlistState.watcher_groups);
      dispatch(resetSocketSubmittalWatchlistState());
    }
  }, [socketWatchlistState.changedNow]);

  useEffect(() => {
    if (socketProcoreState.changedNow) {
      setIs_procore_sync_needed_selected(socketProcoreState.is_procore_sync_needed);
      dispatch(resetSocketSubmittalProcoreState());
    }
  }, [socketProcoreState.changedNow]);

  useEffect(() => {
    if (selectedRequest) {
      setSubmittalWatchers(selectedRequest.watchers);
      setSubmittalWatchersGroups(selectedRequest.watcher_groups);
      dispatch(
        getSandBoxCardIDHotList({
          related_to: 'user',
          entity: selectedRequest?.is_output ? 'submittalOutput' : 'submittalInput',
          check_ids: [submittalId],
        }),
      );
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (submittalId) {
      dispatch(getSubmittalCard({ id: submittalId }));
      setTags_selected(null);
    }
  }, [submittalId]);

  useEffect(() => {
    setLogin_user({
      id: +userInfo.id,
      role: userInfo.roles,
    });
  }, [userInfo]);

  useEffect(() => {
    dispatch(getSubmittalTags());
  }, []);

  useEffect(() => {
    if (errors) {
      const newErrorsObj = { ...errors };

      if (title_selected) delete newErrorsObj?.title;
      if (tags_selected?.title) delete newErrorsObj?.type_id;
      if (responsible_contractor_selected?.company) delete newErrorsObj?.contractor_party_id;
      if (responsible_contractor_selected?.user) delete newErrorsObj?.contractor_user_id;
      if (responsible_manager_selected?.company) delete newErrorsObj?.responsible_party_id;
      if (responsible_manager_selected?.user) delete newErrorsObj?.responsible_user_id;
      if (mf_selected) delete newErrorsObj?.mf_id;

      setErrors(newErrorsObj);
    }
  }, [title_selected, tags_selected, responsible_contractor_selected, responsible_manager_selected, mf_selected]);

  useEffect(() => {
    const submittalTab = searchParams.get('tab');

    if (SubmittalCardTabs.includes(submittalTab)) {
      setActiveTab(submittalTab as SubmittalCardTabNamesModel);
    } else {
      setActiveTab('general');
    }
  }, [location]);

  useEffect(() => {
    if (initialFields) {
      if (buildings?.length === 1) {
        delete initialFields.buildings;
      }

      const isTopCardChanged = checkIsTopCardChanged();
      const isDescriptionChanged = checkIsDescriptionChanged();

      if (isTopCardChanged && !canUpdateTopCard) {
        setCanUpdateTopCard(true);
      } else if (!isTopCardChanged && canUpdateTopCard) {
        setCanUpdateTopCard(false);
      }

      if (isDescriptionChanged && !canUpdateDescription) {
        setCanUpdateDescription(true);
      } else if (!isDescriptionChanged && canUpdateDescription) {
        setCanUpdateDescription(false);
      }
    }
  });

  useEffect(() => {
    if (requestClickOutSide) {
      if (selectRequestId && canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('submittalClickOutSide') !== 'yes');
        setPromptTitle('Discard changes?');
        setPromptMessage('Leaving this submittal card will discard any unsaved changes.');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, discard');
        setPromptName('submittalClickOutSide');
        setIsShowAgain(true);
        setIsMsgNotShowAgain(false);
        setPromptNameBtnSave(null);

        if (localStorage.getItem('submittalClickOutSide') === 'yes') {
          closeRequest(requestClickOutSide);
        }
      } else if (canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('submittalClickOutSide') !== 'yes');
        setPromptTitle('Quit submittal card creation?');
        setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
        setPromptNameBtnSave('Save draft');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, quit');
        setPromptName('submittalClickOutSide');
        setIsShowAgain(true);
        setIsMsgNotShowAgain(false);

        if (localStorage.getItem('submittalClickOutSide') === 'yes') {
          closeRequest(requestClickOutSide);
        }
      }
      if (!canUpdateSaveBtn) {
        closeRequest(requestClickOutSide);
      }
      closeRequestClickOutSide();
    }
  }, [requestClickOutSide, selectRequestId, canUpdateSaveBtn]);

  useEffect(() => {
    if (escapeKeyDown) {
      closeRequestEscapeKeyDown();
      requestHeadClose();
    }
  }, [escapeKeyDown]);

  const checkIsTopCardChanged = () => {
    const initFields = { ...initialFields };

    delete initFields.description;

    return Object.keys(initFields).some(fieldKey => {
      const variableMapping: { [key: string]: any } = {};
      if (fieldKey === 'type') {
        variableMapping[fieldKey] = fieldKey ? eval(`tags_selected`) : null; // todo
      } else if (fieldKey === 'is_procore_sync_needed_selected') {
        variableMapping[fieldKey] = fieldKey ? eval(`is_procore_sync_needed_selected`) : null; // todo
      } else {
        variableMapping[fieldKey] = fieldKey ? eval(`${fieldKey}_selected`) : null; // todo
      }

      return !isEqual(variableMapping[fieldKey], initFields[fieldKey]);
    });
  };

  const checkIsDescriptionChanged = () => {
    return !isEqual(currentDescription === '<p><br></p>' ? null : currentDescription, initialFields.description);
  };

  const buildingSelector = (): RequestBuildingModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const buildings = buildingsCollection?.[activeProject];
    return buildings || [];
  };

  const locationsSelector = (): LocationCollectionModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const levels = locationsCollection?.[activeProject];
    return levels || [];
  };

  const typicalLayoutsSelector = (): any[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const typicalLayouts = typicalLayoutsCollection?.[activeProject];
    return typicalLayouts || [];
  };

  const buildingSpacesSelector = (): SimpleTitleModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const buildings = buildingSpacesCollection?.[activeProject];
    return buildings || [];
  };

  const initDataFromProject = () => {
    const buildings = buildingSelector();
    const locations = locationsSelector();
    const typicalLayouts = typicalLayoutsSelector();
    const building_spaces = buildingSpacesSelector();

    setBuildings_selected(prevState => {
      const copyState = prevState?.map(item => item.id) || [];
      const newBuildingsList =
        buildings?.reduce((acc, item) => {
          if (copyState.includes(item.id)) {
            acc.push(item);
          }
          return acc;
        }, []) || [];

      return newBuildingsList;
    });

    setTypicalLayouts_selected(prevState => {
      const copyState = prevState?.map(item => item.id) || [];
      const newTypicalLayouts =
        typicalLayouts?.reduce((acc, item) => {
          if (copyState.includes(item.id)) {
            acc.push(item);
          }
          return acc;
        }, []) || [];

      return newTypicalLayouts;
    });

    setBuildings(buildings);
    setLocations(locations);
    setTypicalLayouts(typicalLayouts);
    setBuildingSpacesProps(building_spaces);
  };

  const initRequestField = () => {
    const selectedCard = selectedRequest;
    if (!selectedCard) {
      let selectedResponsibleContractor: SelectedRequestCompanyModel;
      if (userInfo?.user_parties && !isCardLoading) {
        const currentProject = userInfo?.projects?.find(project => project.code == urlProject[0]);
        if (userInfo?.user_parties?.length && projectInfo?.parties && currentProject && Object.values(projectInfo?.parties).length) {
          const projectPartyIds = projectInfo?.parties[currentProject.id]?.map(c => c.id) || [];
          const userCompany = userInfo.user_parties.find(c => projectPartyIds.includes(c.id));
          const parties = projectInfo?.parties[currentProject.id]?.find(f => f.id === userCompany?.id);
          selectedResponsibleContractor = {
            discipline: parties?.project_disciplines[0]
              ? {
                  id: parties?.project_disciplines[0].id,
                  title: parties?.project_disciplines[0].title,
                  text_color: parties?.project_disciplines[0].text_color,
                }
              : null,
            company: userCompany
              ? {
                  ...userCompany,
                  id: userCompany.id,
                  title: userCompany.company,
                }
              : null,
            user: {
              ...userInfo,
              id: userInfo.id,
              title: `${userInfo?.first_name} ${userInfo?.last_name}`,
            },
          };
        }
      }

      setTitle_selected('');
      setResponsible_contractor_selected(selectedResponsibleContractor || null);
      setResponsible_manager_selected(null);
      setSubmit_due_date_selected(null);
      setRequired_due_date_selected(null);
      setHashtags_selected([]);
      setBuildings_selected([]);
      setLocations_selected([]);
      setTypicalLayouts_selected([]);
      setBuilding_spaces_selected([]);
      setTags_selected(null);
      setCurrentDescription('');
      setMf_selected(null);
      setPrivate_selected(0);
      setCl_impacts_selected([]);
      setIs_procore_sync_needed_selected(0);

      setErrors({});
      return;
    }

    const getHashtags: TagFormModel[] = (selectedCard.hashtags || []).map(tag => {
      return {
        label: tag.name,
        value: tag.id,
        color: tag.color,
        link: tag.link,
        is_auto: tag.is_auto,
        text_color: tag.text_color,
      };
    });

    const result = {
      buildings: selectedCard.buildings?.length ? selectedCard.buildings : buildings_selected?.length === 1 ? [buildings_selected[0]] : [],
      building_spaces: selectedCard.building_spaces?.length ? selectedCard.building_spaces : [],
      locations: selectedCard.locations?.length ? selectedCard.locations : [],
      typicalLayouts: selectedCard.typicalLayouts?.length ? selectedCard.typicalLayouts : [],
      title: selectedCard.title || '',
      submit_due_date: selectedCard.submit_due_date || null,
      required_due_date: selectedCard.required_due_date || null,
      responsible_contractor: {
        company: selectedCard?.responsible_contractor?.contractor_party
          ? {
              ...selectedCard.responsible_contractor?.contractor_party,
              id: selectedCard.responsible_contractor?.contractor_party?.id,
              title: selectedCard.responsible_contractor?.contractor_party?.company,
            }
          : null,
        discipline: selectedCard?.responsible_contractor?.contractor_discipline
          ? {
              id: selectedCard.responsible_contractor?.contractor_discipline?.id,
              title: selectedCard.responsible_contractor?.contractor_discipline?.title || '',
              text_color: selectedCard.responsible_contractor?.contractor_discipline?.text_color || '',
            }
          : null,
        user: selectedCard?.responsible_contractor?.contractor_user
          ? {
              ...selectedCard.responsible_contractor?.contractor_user,
              id: selectedCard.responsible_contractor?.contractor_user?.id,
              title: `${selectedCard.responsible_contractor?.contractor_user?.profile?.first_name} ${selectedCard.responsible_contractor?.contractor_user?.profile?.last_name}`,
            }
          : null,
      },
      responsible_manager: {
        company: selectedCard?.responsible_manager?.responsible_party
          ? {
              ...selectedCard.responsible_manager?.responsible_party,
              id: selectedCard.responsible_manager?.responsible_party?.id,
              title: selectedCard.responsible_manager?.responsible_party?.company,
            }
          : null,
        discipline: selectedCard?.responsible_manager?.responsible_discipline
          ? {
              id: selectedCard.responsible_manager?.responsible_discipline?.id,
              title: selectedCard.responsible_manager?.responsible_discipline?.title || '',
              text_color: selectedCard.responsible_manager?.responsible_discipline?.text_color || '',
            }
          : null,
        user: selectedCard?.responsible_manager?.responsible_user
          ? {
              ...selectedCard.responsible_manager?.responsible_user,
              id: selectedCard.responsible_manager?.responsible_user?.id,
              title: `${selectedCard.responsible_manager?.responsible_user?.profile?.first_name} ${selectedCard.responsible_manager?.responsible_user?.profile?.last_name}`,
            }
          : null,
      },
      hashtags: getHashtags,
      mf: {
        id: selectedCard.mf_id,
        title: selectedCard.mf_title,
        code: selectedCard.mf_code,
      },
      type: selectedCard.type,
      description: selectedCard.description,
      private: selectedCard.private,
      cl_impacts: selectedRequest.cl_impacts,
      is_procore_sync_needed: selectedRequest.is_procore_sync_needed,
    };

    setTitle_selected(result.title);
    setResponsible_contractor_selected(result.responsible_contractor);
    setResponsible_manager_selected(result.responsible_manager);
    setSubmit_due_date_selected(result.submit_due_date);
    setRequired_due_date_selected(result.required_due_date);
    setHashtags_selected(result.hashtags);
    setBuildings_selected(result.buildings);
    setLocations_selected(result.locations);
    setTypicalLayouts_selected(result.typicalLayouts);
    setBuilding_spaces_selected(result.building_spaces);
    setTags_selected(result.type);
    setCurrentDescription(result.description);
    setMf_selected(result.mf);
    setPrivate_selected(result.private);
    setCl_impacts_selected(result.cl_impacts);
    setIs_procore_sync_needed_selected(result.is_procore_sync_needed);
    setInitialFields(result);

    setErrors({});
  };

  const handleChangeProcoreSyncStatus = () => {
    dispatch(
      setSyncProcoreSubmittal({ submittal_id: selectedRequest?.id, is_procore_sync_needed: is_procore_sync_needed_selected === 1 ? 0 : 1 }),
    );
    setIsProcoreConfirmPopUpOpen(false);
  };

  const handleOpenProcoreConfirmPopUp = () => {
    if (userInfo?.can_integrate && selectedRequest?.procore_project && !selectedRequest?.parent_id) {
      setIsProcoreConfirmPopUpOpen(true);
    }
  };

  const handleCloseProcoreConfirmPopUp = () => {
    setIsProcoreConfirmPopUpOpen(false);
  };

  const handleChangeHashtags = (hashtags: TagFormModel[]) => {
    setHashtags_selected(hashtags);
  };

  const handleCloseRequest = () => {
    if (isSeparateTab) {
      return;
    }

    closeRequest();
  };

  const requestHeadClose = () => {
    if (!canUpdateSaveBtn) {
      setIsPromptBtnOk(true);
      setIsPromptPopUpClose(false);
      setPromptTitle(null);
      setPromptMessage(null);
      setPromptNameBtnCancel(null);
      setPromptNameBtnOk(null);
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      handleCloseRequest();

      if (isUrlSubmittalCardTab && isSeparateTab) {
        setUrlCommandCenter();
      }
    }

    if (selectRequestId && canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('submittalClickOutSide') !== 'yes');
      setPromptTitle('Discard changes?');
      setPromptMessage('Leaving this submittal card will discard any unsaved changes.');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, discard');
      setPromptName('submittalHeadClose');
      setIsShowAgain(true);
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      if (localStorage.getItem('submittalHeadClose') === 'yes') {
        handleCloseRequest();

        if (isUrlSubmittalCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    } else if (canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('submittalClickOutSide') !== 'yes');
      setPromptTitle('Quit submittal card creation?');
      setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
      setPromptNameBtnSave('Save draft');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, quit');
      setPromptName('submittalHeadClose');
      setIsShowAgain(true);
      setIsMsgNotShowAgain(false);

      if (localStorage.getItem('submittalHeadClose') === 'yes') {
        handleCloseRequest();

        if (isUrlSubmittalCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    }
  };

  const onChangeActiveTab = (activeTab: SubmittalCardTabNamesModel) => {
    const { submittalId } = getSubmittalParamsFromURL();
    setUrlSubmittal(submittalId, activeTab);
  };

  const handleResizableCard = () => {
    const rw = localStorage.getItem('resizableSubmittalCard') === 'small' ? 'full' : 'small';
    localStorage.setItem('resizableSubmittalCard', rw);
    dispatch(handleResizableSubmittalCardPart(rw));
  };

  const openDeletePopUp = force => {
    setForceDelete(force);
    setIsDeleteSubmittalPopUpOpen(true);
  };

  const closeDeletePopUp = () => {
    setIsDeleteSubmittalPopUpOpen(false);
    setForceDelete(null);
  };

  const handleDeleteSubmittal = () => {
    setIsDeleteSubmittalLoading(true);
    dispatch(
      removeSubmittalCard({
        data: {
          project_id: selectedRequest?.project_id || +active_project_id,
          spec_id: selectedRequest.spec_id,
          submittal_id: selectedRequest.id,
          force: forceDelete,
        },

        callback: sfCard => {
          if (forceDelete) {
            closeRequest();
          } else {
            dispatch(
              setNewBreadcrumbs({
                typeBreadcrumbs: 'firstCardBreadcrumbs',
                array: breadcrumbs.map(item => {
                  if (item.id === sfCard.id) {
                    return {
                      id: sfCard.id,
                      title: sfCard.sf_index,
                      breadcrumbType: 'submittal',
                      activeTab: item?.activeTab || 'general',
                    };
                  } else {
                    return { ...item };
                  }
                }),
              }),
            );
          }
          setIsDeleteSubmittalLoading(false);
          closeDeletePopUp();
        },
      }),
    );
  };

  const handleChangeRequestTypeSelect = (tag: RequestTypeModel) => {
    setTags_selected(tag);
  };

  const handleChangeShowMoreFields = () => {
    setIsHiddenFields(!isHiddenFields);
  };

  const handleOpenSubmitPopup = () => {
    setIsSubmitPopupOpen(true);
  };

  const handleCloseSubmitPopup = () => {
    setIsSubmitPopupOpen(false);
  };

  const handleOpenDistributePopup = () => {
    setIsDistributePopupOpen(true);
  };

  const handleCloseDistributePopup = () => {
    setIsDistributePopupOpen(false);
  };

  const handleOpenAuthorUser = (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel | SubmittalUserModel) => {
    const target = e.currentTarget;
    timer = setTimeout(() => {
      setIsOpenAuthorUser(true);
      setCoordinatesAuthor(target);
      setSelectedAuthor(user);
    }, 500);
  };

  const handleCloseAuthorUser = () => {
    if (timer && !isOpenAuthorUser) {
      clearTimeout(timer);
      return;
    }
    if (timer) {
      return;
    }
    handleCloseAuthorUserFromProfile();
  };

  const handleCloseAuthorUserFromProfile = () => {
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
    setSelectedAuthor(null);
  };

  const viewProfile = () => {
    setIsViewProfile(true);
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const handleOpenUndoPopup = () => {
    setIsUndoPopupOpen(true);
  };

  const handleCloseUndoPopup = () => {
    setIsUndoPopupOpen(false);
  };

  const selectRequestHandler = (id: number) => {
    //Todo
  };

  const collectSaveObject = (isSubmit: boolean, isTopCardUpdate: boolean) => {
    const getNextStatus = (): 'draft' | 'open' | 'closed' | 'deactivated' => {
      if (isSubmit) {
        return 'open';
      } else {
        return selectedRequest?.status?.alias || 'draft';
      }
    };

    const saveObject: ISubmittalUpdateModel = {
      description: currentDescription || '',
      title: title_selected || '',
      type_id: tags_selected?.id,
      mf_id: mf_selected?.id,
      submit_due_date: submit_due_date_selected,
      required_due_date: required_due_date_selected,
      contractor_discipline_id: responsible_contractor_selected?.discipline?.id,
      contractor_party_id: responsible_contractor_selected?.company?.id,
      contractor_user_id: responsible_contractor_selected?.user?.id,
      responsible_discipline_id: responsible_manager_selected?.discipline?.id,
      responsible_party_id: responsible_manager_selected?.company?.id,
      responsible_user_id: responsible_manager_selected?.user?.id,
      buildings: buildings_selected.map(building => building.id) || [],
      locations: locations_selected.map(location => location.id) || [],
      typicalLayouts: typicalLayouts_selected.map(typical => typical.id) || [],
      buildingSpaces: building_spaces_selected.map(space => space.id) || [],
      hashtags: hashtags_selected
        .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
        .map(hashTag => {
          const tag = {
            name: hashTag.label,
            color: hashTag.color,
            link: hashTag.link,
          };
          return JSON.stringify(tag);
        }),
      status: getNextStatus(),
    };

    if (cl_impacts_selected.length) {
      const impactsObj = {};

      cl_impacts_selected.forEach(item => {
        impactsObj[item.alias] = item.status;
      });

      saveObject.cl_impacts = JSON.stringify(impactsObj);
      delete saveObject.reset_cl_impacts;
    } else {
      saveObject.reset_cl_impacts = 1;
      delete saveObject.cl_impacts;
    }

    if (!saveObject.responsible_party_id) {
      delete saveObject.responsible_party_id;
    }

    if (!saveObject.responsible_user_id) {
      delete saveObject.responsible_user_id;
    }

    if (!saveObject.responsible_discipline_id) {
      delete saveObject.responsible_discipline_id;
    }

    if (!saveObject.contractor_party_id) {
      delete saveObject.contractor_party_id;
    }

    if (!saveObject.contractor_user_id) {
      delete saveObject.contractor_user_id;
    }

    if (!saveObject.contractor_discipline_id) {
      delete saveObject.contractor_discipline_id;
    }

    if (saveObject.description == '<p><br></p>' || isTopCardUpdate) {
      saveObject.description = '';
    }

    if (!saveObject.description || isTopCardUpdate) {
      delete saveObject.description;
    }

    if (!saveObject.type_id) {
      delete saveObject.type_id;
    }

    if (!saveObject.submit_due_date) {
      delete saveObject.submit_due_date;
    }

    if (!saveObject.required_due_date) {
      delete saveObject.required_due_date;
    }

    if (!saveObject.title) {
      delete saveObject.title;
    }

    if (isCreateSubmittal) {
      saveObject.private = private_selected;
    }

    return saveObject;
  };

  const saveSubmittal = (isSubmit: boolean) => {
    const newErrors = validateCard(isSubmit);

    if (!Object.keys(newErrors).length) {
      if (isSubmit) {
        handleOpenSubmitPopup();
      } else {
        saveSubmittalRequest(isSubmit);
      }
    }
  };

  const saveSubmittalRequest = (isSubmit: boolean, isTopCardUpdate?: boolean) => {
    const saveObject = collectSaveObject(isSubmit, isTopCardUpdate);

    onSubmittalSave(selectedRequest?.id, saveObject, null, true);
    handleCloseSubmitPopup();
  };

  const handleCloseAndDistribute = () => {
    handleOpenDistributePopup();
  };

  const handleCloseAndDistributeRequest = () => {
    const bodyParams: ISubmittalUpdateModel = {
      mf_id: selectedRequest?.mf_id,
      type_id: selectedRequest?.type?.id,
      status: 'closed',
    };

    dispatch(
      submittalSave({
        submittalId: selectedRequest?.id,
        bodyParams,
        isNotFormData: false,
        callbackNavigation: id => {
          setUrlSubmittal(id);
        },
      }),
    );
    handleCloseDistributePopup();
  };

  const validateCard = (isSubmit: boolean) => {
    const saveObject = collectSaveObject(isSubmit);

    const { errors } = validator.validate(
      (selectedRequest?.status?.alias === 'draft' || isCreateSubmittal) && !isSubmit
        ? validatorPatternForDraft
        : selectedRequest?.files?.length
        ? validatorPatternForSendWithFiles
        : validatorPatternForSend,
      saveObject,
    );

    setErrors(errors);

    return errors;
  };

  const onSubmittalSave = (submittalId: number, bodyParams: ISubmittalUpdateModel, callback?: () => void, isNotFormData?: boolean) => {
    dispatch(
      submittalSave({
        submittalId,
        bodyParams,
        callback,
        isNotFormData,
        callbackNavigation: id => {
          setUrlSubmittal(id);
        },
      }),
    );
  };

  const handleChangePrivate = (state: boolean) => {
    if (!isCreateSubmittal) {
      dispatch(changePrivate({ owner_type: 'submittal', owner_id: selectedRequest?.id, is_private: state }));
    } else {
      setPrivate_selected(state ? 1 : 0);
    }
  };

  const onCreateNewRevision = () => {
    dispatch(
      createSubmittalRevision({
        submittalId: selectedRequest?.id,
        callback: response => {
          dispatch(
            setNewBreadcrumbs({
              typeBreadcrumbs: 'firstCardBreadcrumbs',
              array: breadcrumbs.map(item => {
                if (item.id === selectedRequest.id) {
                  return {
                    ...item,
                    id: response.data.id,
                    title: response.data.sf_index,
                  };
                } else {
                  return { ...item };
                }
              }),
            }),
          );
        },
      }),
    );
  };

  const onChangeRevision = (revisionId: number) => {
    if (revisionId) {
      setUrlSubmittal(revisionId);
      dispatch(
        getSubmittalCard({
          id: revisionId,
          callback: sfCard => {
            dispatch(
              setNewBreadcrumbs({
                typeBreadcrumbs: 'firstCardBreadcrumbs',
                array: breadcrumbs.map(item => {
                  if (item.id === selectedRequest.id) {
                    return {
                      id: sfCard.id,
                      title: sfCard.sf_index,
                      breadcrumbType: 'submittal',
                      activeTab: item?.activeTab || 'general',
                    };
                  } else {
                    return { ...item };
                  }
                }),
              }),
            );
          },
        }),
      );
    }
  };

  const handleUndoCloseAndDistribute = () => {
    const bodyParams: ISubmittalUpdateModel = {
      mf_id: selectedRequest?.mf_id,
      type_id: selectedRequest?.type?.id,
      status: 'open',
    };
    dispatch(
      submittalSave({
        submittalId: selectedRequest?.id,
        bodyParams,
        isNotFormData: false,
        callbackNavigation: id => {
          setUrlSubmittal(id);
        },
      }),
    );
    handleCloseUndoPopup();
  };

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-centralPeek']: !isSidePeekView && !isSeparateTab && !isMobile,
          ['-sidePeek']: isSidePeekView,
          ['-isSeparateTab']: isSeparateTab,
        })}
      >
        <Header
          isDragRequestCard={isDragRequestCard}
          handleChangeCardView={handleResizableCard}
          selectedRequest={selectedRequest}
          requestHeadClose={requestHeadClose}
          breadcrumbs={breadcrumbs}
          selectRequestHandler={selectRequestHandler}
          isPreviewCard={isPreview}
          isSeparateTab={isSeparateTab}
          login_user={login_user}
          onChangeActiveTab={onChangeActiveTab}
          tags={availableTags}
          selectedTags={tags_selected}
          handleChangeRequestTypeSelect={handleChangeRequestTypeSelect}
          isCreateSubmittal={isCreateSubmittal}
          handleChangePrivate={handleChangePrivate}
          selectedPrivate={private_selected}
          errors={errors}
          isDeactivated={isDeactivated}
          handleChangeRevision={onChangeRevision}
          isPreview={isPreview}
          isCardLoading={isCardLoading}
          submittalWatchers={submittalWatchers}
          submittalWatchersGroup={submittalWatchersGroups}
          isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
          handleOpenUndoPopup={handleOpenUndoPopup}
          openDeletePopup={openDeletePopUp}
          activeTab={activeTab}
          is_procore_sync_needed={is_procore_sync_needed_selected}
          handleOpenProcoreConfirmPopUp={handleOpenProcoreConfirmPopUp}
          handleCloseProcoreConfirmPopUp={handleCloseProcoreConfirmPopUp}
          handleChangeProcoreSyncStatus={handleChangeProcoreSyncStatus}
          isProcoreConfirmPopUpOpen={isProcoreConfirmPopUpOpen}
        />
        <div className={`${themeClass}_bodyContainer`} ref={bodyContainerRef}>
          <TopCardPart
            selectedRequest={selectedRequest}
            isCardLoading={isCardLoading}
            isHiddenFields={isHiddenFields}
            handleChangeShowMoreFields={handleChangeShowMoreFields}
            isDragRequestCard={isDragRequestCard}
            submitDueDateSelected={submit_due_date_selected}
            setSubmitDueDateSelected={setSubmit_due_date_selected}
            requiredDueDateSelected={required_due_date_selected}
            setRequiredDueDateSelected={setRequired_due_date_selected}
            hashtagsSelected={hashtags_selected}
            handleChangeHashtags={handleChangeHashtags}
            buildingsSelected={buildings_selected}
            setBuildingsSelected={setBuildings_selected}
            locationsSelected={locations_selected}
            setLocationsSelected={setLocations_selected}
            typicalLayoutsSelected={typicalLayouts_selected}
            setTypicalLayoutsSelected={setTypicalLayouts_selected}
            buildingSpacesSelected={building_spaces_selected}
            setBuildingSpacesSelected={setBuilding_spaces_selected}
            buildings={buildings}
            locations={locations}
            typicalLayouts={typicalLayouts}
            buildingSpaces={buildingSpaces}
            titleSelected={title_selected}
            setTitleSelected={setTitle_selected}
            responsibleContractorSelected={responsible_contractor_selected}
            setResponsibleContractorSelected={setResponsible_contractor_selected}
            responsibleManagerSelected={responsible_manager_selected}
            setResponsibleManagerSelected={setResponsible_manager_selected}
            mfSelected={mf_selected}
            setMfSelected={setMf_selected}
            errors={errors}
            isDeactivated={isDeactivated}
            isCreateSubmittal={isCreateSubmittal}
            isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
            clImpactsSelected={cl_impacts_selected}
            setClImpactsSelected={setCl_impacts_selected}
            saveSubmittalRequest={saveSubmittalRequest}
            tags_selected={tags_selected}
            isUpdateDraftButtonDisabledTopCard={isUpdateDraftButtonDisabledTopCard}
            canUpdateDescription={canUpdateDescription}
          />
          <TabsContainer
            isDragRequestCard={isSidePeekView}
            feedData={feedData}
            relatedTabData={relatedTabData}
            openedRequest={selectedRequest && Object.values(selectedRequest)?.length ? selectedRequest : null}
            requestLoading={isCardLoading}
            activeTab={activeTab}
            onChangeActiveTab={onChangeActiveTab}
            isDisable={isCreateSubmittal}
            isAccess={true}
            isDeactivated={isDeactivated}
            handleCloseAndDistribute={handleCloseAndDistribute}
            isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
            isAnyReturnedReviews={isAnyReturnedReviews}
          />
          <BottomCardPart
            activeTab={activeTab}
            isDragRequestCard={isDragRequestCard}
            selectedRequest={selectedRequest}
            submittalLoading={isCardLoading}
            isAccess={true} //Todo
            currentDescription={currentDescription}
            setDescription={setCurrentDescription}
            files={selectedRequest?.files}
            isHiddenFields={isHiddenFields}
            onSubmittalSave={onSubmittalSave}
            saveSubmittal={saveSubmittal}
            onChangeActiveTab={onChangeActiveTab}
            handleShowConfirmDialog={handleShowConfirmDialog}
            bodyContainerRef={bodyContainerRef}
            isCreateSubmittal={isCreateSubmittal}
            isAccessToEditCard
            validateCard={validateCard}
            isDeactivated={isDeactivated}
            onCreateNewRevision={onCreateNewRevision}
            mf_selected={mf_selected}
            tags_selected={tags_selected}
            handleCloseAuthorUser={handleCloseAuthorUser}
            handleOpenAuthorUser={handleOpenAuthorUser}
            building_spaces_selected={building_spaces_selected}
            hashtags_selected={hashtags_selected}
            buildings_selected={buildings_selected}
            locations_selected={locations_selected}
            responsible_contractor_selected={responsible_contractor_selected}
            required_due_date_selected={required_due_date_selected}
            submit_due_date_selected={submit_due_date_selected}
            responsible_manager_selected={responsible_manager_selected}
            typicalLayouts_selected={typicalLayouts_selected}
            title_selected={title_selected}
            private_selected={private_selected}
            handleCloseAndDistribute={handleCloseAndDistribute}
            cardErrors={errors}
            setCardErrors={setErrors}
            isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
            isAnyReturnedReviews={isAnyReturnedReviews}
            clImpactsSelected={cl_impacts_selected}
            setClImpactsSelected={setCl_impacts_selected}
            isUpdateDraftButtonDisabled={isUpdateDraftButtonDisabled}
          />
        </div>
      </div>
      {isOpenAuthorUser && (
        <PopupProfile
          anchorEl={coordinatesAuthor}
          user={selectedAuthor}
          viewProfile={viewProfile}
          closeTeamUser={handleCloseAuthorUserFromProfile}
          closeFixedTooltip={handleCloseAuthorUserFromProfile}
        />
      )}
      {isViewProfile && (
        <ProfilePopover
          open={isViewProfile}
          onClose={viewProfileClose}
          type={'view'} // Todo: Permissions
          projectId={active_project_id}
          user_id={selectedAuthor.id}
        />
      )}
      <DialogPopUp
        open={isSubmitPopupOpen}
        onClose={handleCloseSubmitPopup}
        title={'Submit this submittal?'}
        modalText={'All involved parties will be notified. This action cannot be undone.'}
        secondaryText={'Keep editing'}
        handleOnSecondary={handleCloseSubmitPopup}
        primaryText={'Yes, submit'}
        handleOnPrimary={() => saveSubmittalRequest(true)}
      />
      <DialogPopUp
        open={isDistributePopupOpen}
        onClose={handleCloseDistributePopup}
        title={'Close and distribute the submittal?'}
        modalText={'All involved parties will be notified. You will be able to undo this action.'}
        tertiaryText={'Cancel'}
        handleOnTertiary={handleCloseDistributePopup}
        primaryText={'Yes, close'}
        handleOnPrimary={() => handleCloseAndDistributeRequest()}
      />
      <DialogPopUp
        open={isUndoPopupOpen}
        onClose={handleCloseUndoPopup}
        title={'Undo Close & Distribute action?'}
        modalText={'The submittal status will be changed back to Open. All involved parties will be notified.'}
        tertiaryText={'Cancel'}
        handleOnTertiary={handleCloseUndoPopup}
        primaryText={'Yes, undo'}
        handleOnPrimary={() => handleUndoCloseAndDistribute()}
      />
      <Modal
        open={isDeleteSubmittalPopUpOpen}
        onClose={closeDeletePopUp}
        title={forceDelete ? 'Delete this submittal?' : 'Remove this revision?'}
        modalText={
          forceDelete
            ? "The whole submittal will be deleted. The data included in all the revisions will be lost. You won't be able to recover it."
            : selectedRequest?.status?.alias === 'draft'
            ? "The data included in this revision will be lost. You won't be able to recover it."
            : "This revision has been already submitted. The data included in this revision will be lost. You won't be able to recover it."
        }
        primaryText={'Yes, remove'}
        tertiaryText={'Close'}
        customSecondaryType={'tertiary'}
        handleOnPrimary={handleDeleteSubmittal}
        handleOnTertiary={closeDeletePopUp}
        loadingOnPrimary={isDeleteSubmittalLoading}
        isCard
      />
    </>
  );
}

export default withConfirmDialog<IProps>(withConfirmDialogContext(CardSubmittal));
