import React, { ChangeEvent } from 'react';
import styles from './ToggleSwitchStyles.scss';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { Switch } from '@material-ui/core';

interface IProps {
  isSelectedToggle: boolean;
  handleSelect: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled: boolean;
  type: 'toggle' | 'switcher';
}

const ToggleSwitch: React.FC<IProps> = ({ isSelectedToggle, handleSelect, disabled, type }) => {
  const themeClass = useGetThemeClass('b-toggleSwitch');

  return (
    <div className={`${themeClass}__${type}`}>
      <Switch
        checked={isSelectedToggle}
        onChange={handleSelect}
        classes={{
          root: styles.root,
          switchBase: styles.switchBase,
          checked: styles.colorChecked,
          bar: styles.colorBar,
          icon: styles.icon,
          disabled: styles.disabled,
        }}
        disabled={disabled}
        disableRipple={true}
      />
    </div>
  );
};

export default ToggleSwitch;
