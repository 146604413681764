import React, { FC, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import ChipsProjectSelect from './parts/ChipsProjectSelect/ChipsProjectSelect';
import ProjectSelect from './parts/ProjectSelect/ProjectSelect';
import TextInputNew from '../../../../controls/TextInputNew/TextInputNew';
import DateField from '../../../../controls/DateField/DateField';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import ProjectCalendar from './parts/ProjectCalendar/ProjectCalendar';
import FileGalleryView from '../../../../controls/FileGallery/FileGalleryView';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import Divider from '../../../../controls/Divider/Divider';
import { FileModel, ProjectModel } from '../../../../../models';
import { ProjectSimpleModel } from '../../../../../models/project/project.model';
import { SelectDataModel } from '../../../../../models/project/select-data.model';
import { KeyValueModel } from '../../../../../models/key-value.model';
import moment from 'moment/moment';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';

import './Step1ViewStyle.module.scss';

const Step1View: FC<{
  handleOpenCalendar: (type: string) => (e) => void;
  typeOfOpenedCalendar: string;
  anchorEl: HTMLDivElement | null;
  handleCloseCalendar: () => void;
  selectAtDateHandler: (date: string) => void;
  formData: ProjectSimpleModel;
  selectAtDateHandlerSingle: (date: string) => void;
  handleFile: (e: any) => void;
  delFile: (filename: string) => void;
  handleChangeValue: (name: string, value: string) => void;
  handleSave: () => void;
  phasePlanSelect: SelectDataModel;
  handleChangeSelect: (name: string, value: string) => void;
  errors: KeyValueModel<string>;
  delSavedFile: (file_id: number) => void;
  companyTitle: string;
  isFileAddDisabled: boolean;
  handleChooseThumbnail: (file: FileModel | FileList | any) => void;
  chosenThumbnail: string;
  isCircularProgress: boolean;
  isSuccessCircular: boolean;
  nameHandleFile: string;
  isCircularProgressAnime: boolean;
  setCircularProgressAnime: () => void;
  work_week: number;
  exitEditMode: () => void;
  iconAvatar: string;
  project: ProjectModel | null;
}> = ({
  anchorEl,
  formData,
  phasePlanSelect,
  companyTitle,
  errors,
  work_week,
  typeOfOpenedCalendar,
  handleSave,
  handleFile,
  delFile,
  delSavedFile,
  isFileAddDisabled,
  handleChooseThumbnail,
  chosenThumbnail,
  isCircularProgress,
  isSuccessCircular,
  nameHandleFile,
  isCircularProgressAnime,
  setCircularProgressAnime,
  handleChangeSelect,
  handleChangeValue,
  handleOpenCalendar,
  handleCloseCalendar,
  selectAtDateHandler,
  selectAtDateHandlerSingle,
  exitEditMode,
  iconAvatar,
  project,
}) => {
  const themeClass = useGetThemeClass('b-general_information');
  const { sizeResolution } = GetResolution();
  const [focusElement, setFocusElement] = useState<string>('');
  const [isOpenSaveArchivedProject, setIsOpenSaveArchivedProject] = useState<boolean>(false);
  const [isChangeStatusInArchived, setIsChangeStatusInArchived] = useState<boolean>(false);

  const handleChangeInput = (title: string) => (value: string) => {
    handleChangeValue(title, value);
  };

  const handleChange = (title: string) => (value: string) => {
    handleChangeSelect(title, value);
  };

  const handleChangeStatus = (value: string) => {
    if (value === 'archived') {
      setIsChangeStatusInArchived(true);
    }
    handleChangeSelect('status', value);
  };

  const handleChangeDescription = value => {
    handleChangeValue('desc', value);
  };

  const handleOpenPopupSaveArchivedProject = () => {
    if (isChangeStatusInArchived) {
      setIsOpenSaveArchivedProject(true);
    } else {
      handlehandleSave();
    }
  };

  const handleClosePopupSaveArchivedProject = () => {
    setIsOpenSaveArchivedProject(false);
  };

  const handlehandleSave = () => {
    setIsOpenSaveArchivedProject(false);
    setIsChangeStatusInArchived(false);
    handleSave();
  };

  return (
    <>
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_container_project_info`}>
          <div className={'project_info_row'}>
            <div className={'project_info_title'}>Project info</div>
            <div>
              <ChipsProjectSelect project_status={formData.status} handleChangeStatus={handleChangeStatus} isCreateProject={!project} />
            </div>
          </div>
          <div className={'first_row'}>
            <TextInputNew
              type={'on-bgd'}
              placeholder={'Type name'}
              onChange={handleChangeInput('title')}
              value={formData?.title}
              label={'Project name'}
              error={errors?.title}
              isFocus={focusElement === 'title'}
              onFocus={() => setFocusElement('title')}
              onBlur={() => setFocusElement('')}
              onClickIcon={() => null}
              width={sizeResolution === '640' ? 170 : 200}
            />
            <div className={'label_container'}>
              <div className={'label_container_label'}>Project type</div>
              <ProjectSelect
                values={phasePlanSelect.project_types}
                selectHandler={handleChange('project_type_id')}
                selectedId={formData.project_type_id}
                error={errors?.project_type_id}
              />
            </div>
            <TextInputNew
              type={'on-bgd'}
              placeholder={'Street 23, City'}
              onChange={handleChangeInput('address')}
              value={formData?.address}
              label={'Project address'}
              error={null}
              isFocus={focusElement === 'address'}
              onFocus={() => setFocusElement('address')}
              onBlur={() => setFocusElement('')}
              onClickIcon={() => null}
              width={sizeResolution === '640' ? 170 : 200}
            />
          </div>
          <div className={'second_row'}>
            <div className={'dateField_container'} onClick={handleOpenCalendar('target_start')}>
              <DateField
                title={'Kick-off date'}
                selectedDate={formData?.target_start ? moment(formData['target_start']).format('MM/DD/YYYY') : ''}
                isSentRequestToChange={false}
                isOpenCalendar={false}
                isDiffDueScheduledDate={false}
                info={false}
                disabled={false}
                isBefore={false}
                error={!!errors?.target_start}
                valueIsDate={!!formData?.target_start}
              />
            </div>
            <div className={'dateField_container -margleft'} onClick={handleOpenCalendar('target_to')}>
              <DateField
                title={'TCO'}
                selectedDate={formData?.target_to ? moment(formData['target_to']).format('MM/DD/YYYY') : ''}
                isSentRequestToChange={false}
                isOpenCalendar={false}
                isDiffDueScheduledDate={false}
                info={false}
                disabled={false}
                isBefore={false}
                error={!!errors?.target_to}
                valueIsDate={!!formData?.target_to}
              />
            </div>
            <div className={'label_container -margleftSecond'}>
              <div className={'label_container_label'}>Workweek</div>
              <ProjectSelect values={phasePlanSelect.workweeks} selectHandler={handleChange('work_week')} selectedId={formData.work_week} />
            </div>
          </div>
        </div>
        <div className={`${themeClass}_container_company`}>
          <div className={'company_title'}>Company</div>
          <div className={'placeholderInfo'}>
            <PlaceholderInfo
              type={'Company'}
              detailInfo={companyTitle}
              //subInfo={'planit'}
              size={24}
              imagePath={iconAvatar}
            />
          </div>
        </div>
        <div className={`${themeClass}_container_additional`}>
          <div className={'additional_title'}>Additional</div>
          <div>
            <TextInputNew
              type={'on-bgd'}
              placeholder={'Type name'}
              onChange={handleChangeInput('website')}
              value={formData?.website}
              label={'Website'}
              error={errors?.website}
              isFocus={focusElement === 'website'}
              onFocus={() => setFocusElement('website')}
              onBlur={() => setFocusElement('')}
              onClickIcon={() => null}
              width={200}
            />
          </div>
          <div className={`${themeClass}_container_project_description`}>
            <div className={'project_container'}>
              <div className={'label_container'}>
                <div className={'label_container_label'}>Project description</div>
                <TextQuillEditor
                  fieldId={'test1'}
                  showToolbar
                  value={formData?.desc || ''}
                  handleChange={handleChangeDescription}
                  placeholder={'Type description'}
                  disabled={false}
                />
              </div>
            </div>
            <div className={`${themeClass}_gallery`}>
              <FileGalleryView
                handleFile={handleFile}
                files={formData.renderings || []}
                delFile={delFile}
                delSavedFile={delSavedFile}
                isFileAddDisabled={isFileAddDisabled}
                chooseThumbnail={handleChooseThumbnail}
                chosenThumbnail={chosenThumbnail}
                isCircularProgress={isCircularProgress}
                isSuccessCircular={isSuccessCircular}
                nameHandleFile={nameHandleFile}
                isCircularProgressAnime={isCircularProgressAnime}
                setCircularProgressAnime={setCircularProgressAnime}
              />
            </div>
          </div>
        </div>
        <div className={`${themeClass}_container_footer`}>
          <Divider direction={'horizontal'} type={'srf-5'} />
          <div className={'btn_container'}>
            <CustomButton type={'tertiary'} size={'md'} clickHandler={exitEditMode} title={'Exit edit mode'} />
            <CustomButton
              type={'primary'}
              size={'md'}
              clickHandler={handleOpenPopupSaveArchivedProject}
              title={project ? 'Save' : 'Create'}
            />
          </div>
        </div>
      </div>
      <ProjectCalendar
        typeOfOpenedCalendar={typeOfOpenedCalendar}
        anchorEl={anchorEl}
        handleCloseCalendar={handleCloseCalendar}
        selectAtDateHandler={selectAtDateHandler}
        formData={formData}
        selectAtDateHandlerSingle={selectAtDateHandlerSingle}
        work_week={work_week}
      />
      {isOpenSaveArchivedProject && (
        <DialogPopUp
          isCard
          open={isOpenSaveArchivedProject}
          onClose={handleClosePopupSaveArchivedProject}
          aria-labelledby="form-dialog-title SaveArchivedProject"
          isOverlay={true}
          paperMaxWidth={'600px'}
          title={'Are you sure you want to archive this project?'}
          modalText={`Archiving will stop notifications and restrict access for Users and Managers.`}
          secondaryText={'Cancel'}
          handleOnSecondary={handleClosePopupSaveArchivedProject}
          primaryText={'Yes, Archive'}
          handleOnPrimary={handlehandleSave}
          customPrimaryType={'primary'}
        />
      )}
    </>
  );
};

export default React.memo(Step1View);
