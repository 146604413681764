import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';

import './ChipsDataTypeStyles.scss';

const ChipsDataType: FC<{
  type: 'input' | 'output' | 'communication';
  color: 'yellow' | 'green' | 'blue';
  size: 'xsm' | 'sm' | 'md';
}> = ({ type, color, size }) => {
  const themeClass = useGetThemeClass('b-chipsDataType');

  return (
    <>
      {(type === 'input' || type === 'output' || type === 'communication') && (
        <div className={`${themeClass} ${size} ${color}`}>
          <div className={'label_text'}>{type[0].toUpperCase() + type.slice(1)}</div>
        </div>
      )}
    </>
  );
};

export default React.memo(ChipsDataType);
