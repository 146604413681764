import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import LocationEditorView from './LocationEditorView';
import {
  BuildingModel,
  LocationModel,
  ProjectModel,
  SimpleTitleModel,
  StatusModel,
  TypicalLayoutModel,
  TypicalLayoutsModel,
  UserModel,
} from '../../../../../../../models';
import { KeyValueModel } from '../../../../../../../models/key-value.model';
import { useAppSelector } from '../../../../../../../store/configure/configureStore';
import BasicEditor from './BasicEditor/BasicEditor';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import TokenIcon from '../../../../../../controls/TokenIcon/TokenIcon';
import { SystemButton } from '../../../../../../controls/ButtonComponents';

interface IProps {
  locations: LocationModel[];
  typical_layouts: TypicalLayoutModel[];
  updateLocationBuildingStatus: StatusModel;
  building: BuildingModel<SimpleTitleModel[]>;
  updateBuildingLocation: (data: any) => void;
  changeLocations: (locations: LocationModel[], index?: number) => void;
  changeTypicalLayout: (typicalLayouts: TypicalLayoutsModel[]) => void;
  buildingsErrors: any;
  gridRef: any;
  userData: UserModel;
  project: ProjectModel;
  countNewFloors: {
    title: string;
    count: string;
  };
  onChangeNewFloors: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAddFloors: () => void;
}

const LocationEditorContainer: FC<IProps> = ({
  changeLocations,
  locations,
  changeTypicalLayout,
  gridRef,
  building,
  typical_layouts,
  project,
  countNewFloors,
  onChangeNewFloors,
  handleAddFloors,
}) => {
  const [rows, setRows] = useState<LocationModel[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [typicalLayouts, setTypicalLayouts] = useState<TypicalLayoutModel[]>([]);
  const [isTypicalLayout, setIsTypicalLayout] = useState(null);
  const [typicalLayoutAnchorEl, setTypicalLayoutAnchorEl] = useState(null);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const [typicalLayoutCross, setTypicalLayoutCross] = useState<TypicalLayoutModel[]>([]);
  const [actionChangeTypicalCross, setActionChangeTypicalCross] = useState<'add' | 'remove'>(null);
  const [actionRowIndexCross, setActionRowIndexCross] = useState(null);
  const [isImportListOpen, setIsImportListOpen] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [isDataImportOpen, setIsDataImportOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [changedFields, setChangedFields] = useState<{ [key: string]: any }>(null);

  const { phasePlanSelect } = useAppSelector(state => state.phasePlan);
  const userData = useAppSelector(state => state.userReducer.userInfo);

  const themeClass = useGetThemeClass('b-locationEditorContainer');

  const onRowChange = async (changed: { [key: string]: any }) => {
    const editingRows: LocationModel[] = [];

    rows.forEach(row => {
      if (changed?.id === (row.id || row.custom_id)) {
        editingRows.push({ ...row });
      }
    });

    selectedRows.forEach(id => {
      rows.forEach(row => {
        if ((row.id || row.custom_id) === id && changed?.id !== (row.id || row.custom_id)) {
          editingRows.push({ ...row });
        }
      });
    });

    editingRows.forEach(row => {
      if (changed.title !== undefined) {
        row.title = changed.title;
      }
      if (changed.footage !== undefined) {
        row.footage = changed.footage;
      }
      if (changed.below_grade !== undefined) {
        row.below_grade = changed.below_grade;
      }
      if (changed.construction_types !== undefined) {
        row.construction_types = changed.construction_types;
      }
      if (changed.occupancy_type_id !== undefined) {
        row.occupancy_type_id = changed.occupancy_type_id;
      }
      if (changed.building_spaces !== undefined) {
        row.building_spaces = changed.building_spaces;
      }
    });

    const result: LocationModel[] = [];

    rows.forEach(request => {
      if (selectedRows.includes(request.id || request.custom_id) || changed?.id === (request.id || request.custom_id)) {
        const foundEditingRow = editingRows.find(row => (row.id || row.custom_id) === (row.id ? request.id : request.custom_id));
        if (foundEditingRow) {
          result.push(foundEditingRow);
        }
      } else {
        result.push(request);
      }
    });

    await changeLocations(result);
    setChangedFields(changed);
  };

  const renderFormatterLevelText = p => {
    return (
      <div className={`${themeClass}__cellSelect`}>
        <span className={`${themeClass}__cellLabel`}>{p.row.title}</span>
        <div className={`${themeClass}__cellRight`}>
          <SystemButton
            customClass={`${themeClass}__cellDelete`}
            type={'plus'}
            size={'md'}
            variant={'transparent'}
            clickHandler={() => handleAddFloor(p)}
          />
          <SystemButton
            customClass={`${themeClass}__cellDelete`}
            type={'delete'}
            size={'md'}
            variant={'transparent'}
            clickHandler={() => handleRemoveFloor(p)}
          />
        </div>
      </div>
    );
  };

  const renderFormatterAreaText = p => {
    return (
      <div className={`${themeClass}__cellSelect`}>
        <span className={`${themeClass}__cellText`}>{p.row.footage}</span>
      </div>
    );
  };

  const renderFormatterSelect = (p, multiSelect = true) => {
    if (!phasePlanSelect[p.column.key] && !phasePlanSelect['occupancy_types']) {
      return '';
    }

    if (!multiSelect) {
      return (
        <div className={`${themeClass}__cellSelect`}>
          <span className={`${themeClass}__cellText`}>
            {phasePlanSelect[p.column.key === 'occupancy_type_id' ? 'occupancy_types' : p.column.key].find(
              f => f.id === p.row[p.column.key],
            )?.title || 'Select'}
          </span>
          <div className={`${themeClass}__cellIcon`}>
            <TokenIcon iconName={'chevron-down'} size={16} />
          </div>
        </div>
      );
    }
    const getSelectedItems = () => {
      if (p.column.key === 'building_spaces') {
        return (
          (building.building_spaces &&
            building.building_spaces.filter(f => p.row[p.column.key] && p.row[p.column.key].find(r => r.id === f.id))) ||
          []
        );
      } else {
        return phasePlanSelect[p.column.key].filter(f => p.row[p.column.key] && p.row[p.column.key].find(r => r.id === f.id));
      }
    };

    const selectedItems = getSelectedItems();

    return (
      <div className={`${themeClass}__cellSelect`}>
        <span className={`${themeClass}__cellText`}>{selectedItems[0]?.title || 'Select'}</span>
        <div className={`${themeClass}__cellIcon`}>
          <TokenIcon iconName={'chevron-down'} size={16} />
        </div>
      </div>
    );
  };

  const collectCrossedTypical = (typicalLayouts, actionRowIndexCross, actionChangeTypicalCross) => {
    const typicalLayoutCross = [];
    typicalLayouts.map(t => {
      if (actionChangeTypicalCross === 'add' && t.start_position <= actionRowIndexCross + 1 && actionRowIndexCross + 1 <= t.end_position) {
        typicalLayoutCross.push(t);
      } else if (
        actionChangeTypicalCross === 'remove' &&
        t.start_position <= actionRowIndexCross &&
        actionRowIndexCross <= t.end_position
      ) {
        typicalLayoutCross.push(t);
      }
    });
    return typicalLayoutCross;
  };

  const closeTypicalLayoutCross = () => {
    setTypicalLayoutCross(null);
    setActionChangeTypicalCross(null);
    setActionRowIndexCross(null);
  };

  const removeCrossedTypical = async () => {
    const typical_layouts_new = [];
    if (actionChangeTypicalCross === 'add') {
      typicalLayouts.map(t => {
        if (!(t.start_position <= actionRowIndexCross + 1 && actionRowIndexCross + 1 <= t.end_position)) {
          typical_layouts_new.push(t);
        }
      });

      await addFloor(actionRowIndexCross);
    } else if (actionChangeTypicalCross === 'remove') {
      typicalLayouts.map(t => {
        if (!(t.start_position <= actionRowIndexCross && actionRowIndexCross <= t.end_position)) {
          typical_layouts_new.push(t);
        }
      });
      await removeFloor(actionRowIndexCross);
    }
    changeTypicalLayout(typical_layouts_new);
    setTypicalLayouts(typical_layouts_new);
    closeTypicalLayoutCross();
    return typical_layouts_new;
  };

  const addFloor = async index => {
    const newLocations = [...rows];
    const locationFields = {
      title: countNewFloors.title,
      custom_id: Math.ceil(Math.random() * 1000),
    };
    newLocations.splice(index + 1, 0, locationFields);
    await changeLocations(newLocations, index + 1);
  };

  const handleAddFloor = p => {
    const typicalLayoutCross = collectCrossedTypical(typicalLayouts, p.rowIdx, 'add');
    if (typicalLayoutCross.length) {
      setTypicalLayoutCross(typicalLayoutCross);
      setActionChangeTypicalCross('add');
      setActionRowIndexCross(p.rowIdx);
    } else {
      addFloor(p.rowIdx);
    }
  };

  const handleRemoveFloor = p => {
    const typicalLayoutCross = collectCrossedTypical(typicalLayouts, p.rowIdx, 'remove');
    if (typicalLayoutCross.length) {
      setTypicalLayoutCross(typicalLayoutCross);
      setActionChangeTypicalCross('remove');
      setActionRowIndexCross(p.rowIdx);
    } else {
      removeFloor(p.rowIdx);
    }
  };

  const handleTypicalLayout = e => {
    setIsTypicalLayout(true);
    setTypicalLayoutAnchorEl(e.currentTarget);
  };

  const handleCloseTypicalLayout = () => {
    setIsTypicalLayout(false);
    setTypicalLayoutAnchorEl(null);
  };

  const updateTypical = ranges => {
    changeTypicalLayout(ranges);
    setTypicalLayouts(ranges);
  };

  const handleOpenImportList = event => {
    setIsImportListOpen(true);
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseImportList = () => {
    setIsImportListOpen(false);
    setAnchorElFilter(null);
  };

  const handleOpenDataImport = () => {
    setIsDataImportOpen(true);
  };

  const handleCloseDataImport = () => {
    setIsDataImportOpen(false);
  };

  const removeFloor = removeIndex => {
    const newLocations = [...rows];
    newLocations.splice(removeIndex, 1);
    changeLocations(newLocations);
  };

  const setCellError = (row, column) => {
    return errors[`locations.${row.position}.${column}`] ? 'rdg-cell-error' : '';
  };

  const getColumns = () => {
    setColumns([
      {
        key: 'title',
        name: 'Levels',
        width: 250,
        editor: p => <BasicEditor col={p} onChange={onRowChange} building={building} />,
        cellClass: row => setCellError(row, 'title'),
        formatter: p => renderFormatterLevelText(p),
      },
      {
        key: 'below_grade',
        name: 'Above / Below Grade',
        width: 300,
        cellClass: row => setCellError(row, 'below_grade'),
        editor: p => <BasicEditor col={p} onChange={onRowChange} isSelect building={building} />,
        formatter: p => renderFormatterSelect(p, false),
      },
      {
        key: 'construction_types',
        name: 'Type Of Construction',
        width: 296,
        cellClass: row => setCellError(row, 'construction_types'),
        editor: p => <BasicEditor col={p} onChange={onRowChange} isSelect isMulti building={building} />,
        formatter: p => renderFormatterSelect(p),
      },
      {
        key: 'footage',
        name: 'Gross Area',
        width: 295,
        cellClass: row => setCellError(row, 'footage'),
        editor: p => <BasicEditor col={p} onChange={onRowChange} building={building} />,
        formatter: p => renderFormatterAreaText(p),
      },
      {
        key: 'occupancy_type_id',
        name: 'Occupancy Classification',
        width: 295,
        cellClass: row => setCellError(row, 'occupancy_type_id'),
        editor: p => <BasicEditor col={p} onChange={onRowChange} isSelect building={building} />,
        formatter: p => renderFormatterSelect(p, false),
      },
      {
        key: 'building_spaces',
        name: 'Building Spaces',
        minWidth: 280,
        cellClass: row => setCellError(row, 'building_spaces'),
        editor: p => <BasicEditor col={p} onChange={onRowChange} isSelect isMulti building={building} />,
        formatter: p => renderFormatterSelect(p),
      },
    ]);
  };

  useEffect(() => {
    if (locations) {
      setRows(locations);
      setChangedFields({ update: [] });
    }
    if (typical_layouts) {
      setTypicalLayouts(typical_layouts);
    }
  }, [locations, typical_layouts]);

  useEffect(() => {
    if (rows) {
      getColumns();
    }
  }, [rows, phasePlanSelect, themeClass, selectedRows]);

  const isAccess = userData.roles && !!Object.keys(userData.roles).filter(key => key === '1' || key === '4').length;

  return (
    <div className={themeClass}>
      <LocationEditorView
        locations={rows}
        handleTypicalLayout={handleTypicalLayout}
        isTypicalLayout={isTypicalLayout}
        handleCloseTypicalLayout={handleCloseTypicalLayout}
        typical_layouts={typicalLayouts}
        updateTypical={updateTypical}
        building={building}
        columns={columns}
        setRows={setRows}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        typicalLayoutAnchorEl={typicalLayoutAnchorEl}
        typicalLayoutCross={typicalLayoutCross}
        closeTypicalLayoutCross={closeTypicalLayoutCross}
        removeCrossedTypical={removeCrossedTypical}
        actionChangeTypicalCross={actionChangeTypicalCross}
        gridRef={gridRef}
        openImportList={handleOpenImportList}
        closeImportList={handleCloseImportList}
        isImportListOpen={isImportListOpen}
        anchorElFilter={anchorElFilter}
        isAccess={isAccess}
        isDataImportOpen={isDataImportOpen}
        openDataImport={handleOpenDataImport}
        closeDataImport={handleCloseDataImport}
        project={project}
        changedFields={changedFields}
        countNewFloors={countNewFloors}
        onChangeNewFloors={onChangeNewFloors}
        handleAddFloors={handleAddFloors}
      />
    </div>
  );
};
export default LocationEditorContainer;
