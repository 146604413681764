import React from 'react';
import './DividerStyles.scss';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';

interface DividerProps {
  customClassname?: string;
  direction: 'horizontal' | 'vertical';
  type: 'srf-4' | 'srf-5';
}

const Divider: React.FC<DividerProps> = ({ customClassname, direction, type }) => {
  const themeClass = useGetThemeClass('b-lineDivider');

  return (
    <>
      <div
        className={cn(`${themeClass} ${themeClass}_${type} -${direction}`, {
          [`${customClassname}`]: customClassname,
        })}
      />
    </>
  );
};

export default Divider;
