import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../user/userSlice';
import errorsReducer from '../errors/errorsSlice';
import projectReducer from '../project/projectReducer';
import notificationsReducer from '../notifications/notificationsReducer';
import documentsReducer from '../documents/documentsReducer';
import requestReducer from '../request/requestReducer(HOLD)';
import needList from '../needlist/needsListSlice';
import settingsReducer from '../globalSettings/settingsSlice';
import commonReducer from '../common/commonReducer';
import sandBoxReducer from '../sandBox/sandBoxSlice';
import filterReducer from '../filters/filtersReducer';
import asideReducer from '../aside/asideSlice';
import phasePlan from '../phasePlan/phasePlanSlice';
import reportingReducer from '../reporting/reportingSlice';
import commentReducer from '../comments/commentsReducer';
import historyReducer from '../history/historySlice';
import incorporationReducer from '../incorporation/incorporationSlice';
import supportReducer from '../support/supportSlice';
import { teamReducer } from '../team';
import pcdReducer from '../pcd/pcdSlice';
import specAndSubsSlice from '../specAndSubs/specAndSubsSlice';
import submittalSlice from '../submittal/submittalSlice';
import relatedItemsSlice from '../relatedItems/relatedItemsSlice';
import indexedDbSlice from '../indexedDb/indexedDb';
import redmineReducer from '../redmine/redmineSlice';
import breadcrumbsReducer from '../breadcrumbs/breadcrumbsReducer';

export const rootReducer = combineReducers({
  userReducer,
  projectReducer,
  notificationsReducer,
  documentsReducer,
  requestReducer,
  commentReducer,
  settingsReducer,
  commonReducer,
  sandBoxReducer,
  filterReducer,
  asideReducer,
  phasePlan,
  historyReducer,
  incorporationReducer,
  supportReducer,
  reportingReducer,
  needList,
  team: teamReducer,
  pcdReducer,
  errorsReducer,
  specAndSubsSlice,
  submittalSlice,
  relatedItemsSlice,
  redmineReducer,
  indexedDbSlice,
  breadcrumbsReducer,
});
