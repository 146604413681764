import React from 'react';
// import {Draggable} from 'react-beautiful-dnd';
// import { ArcherElement } from 'react-archer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LOGIN } from '../../../../../store/user/userActions';
import ReactHtmlParser from 'react-html-parser';

export default class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestIsOpen: true,
      isShowAll: false,
      linkActive: false,
      linkFocus: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { task } = this.props;

    if (
      this.props.relations !== nextProps.relations &&
      !this.props.relations[task.nf] &&
      !nextProps.relations[task.nf] &&
      !nextState.linkFocus &&
      !this.state.linkFocus &&
      !this.state.linkActive
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reset !== prevProps.reset && this.props.reset) {
      this.setState({ linkActive: false });
    }
  }

  componentWillUnmount() {
    const relations = this.getRelations();
    if (this.state.linkActive) {
      this.props.onRemoveRelations(relations);
    }
  }

  shouldShowTooltip() {
    const { task } = this.props;
    if (!this.state.linkFocus && !this.state.linkActive) {
      return false;
    }
    const successors = task.successor_nf ? task.successor_nf.split(', ') : [];
    const predecessors = task.predecessor_nf ? [task.predecessor_nf] : [];
    const links = [...successors, ...predecessors];
    const showTooltip = links.some(el => !this.props.checkRequestInList(el));
    // const showTooltip = links.map(el => {
    //   !this.props.checkRequestInList(el);
    // });

    return showTooltip;
  }

  getRelations() {
    const { task } = this.props;
    let successors = task.successors?.length ? task.successors.map(s => s.nf) : [];
    const predecessors = task.predecessors?.length ? task.predecessors.map(s => s.nf) : [];
    successors = [...successors, ...predecessors];
    let result = successors.filter(this.props.checkRequestInList);
    if (task.predecessor_nf && (this.props.display !== 'R' || task.isExistPredecessorIdFromList)) {
      if (this.props.checkRequestInList(task.predecessor_nf)) {
        result.push(task.predecessor_nf);
      }
    }
    return result;
  }

  getAvailableRelations() {
    const availableItems = Object.values(this.props.availableItems).flat();
    return this.getRelations().filter(item => availableItems.includes(+item));
  }

  archerRelationsTarget() {
    const { task } = this.props;
    if (!this.state.linkFocus && !this.state.linkActive) {
      return [];
    }
    return this.getAvailableRelations().map(id => ({
      targetId: `Predecessor-${id}`,
      targetAnchor: 'middle',
      sourceAnchor: 'top',
      style: { strokeColor: task.req_dis_color, strokeWidth: 1, noCurves: false, arrowThickness: 8, arrowLength: 8 },
    }));
  }

  getTaskClassName = () => {
    const { styles, task } = this.props;
    const { linkActive, linkFocus } = this.state;
    if ((linkActive || linkFocus || this.props.relations[task.nf]) && this.getAvailableRelations()?.length > 0) {
      return `${styles.WhiteBoardTask} ${styles.WhiteBoardTaskSelected} Predecessor-${task.nf}`;
    } else {
      return `${styles.WhiteBoardTask} Predecessor-${task.nf}`;
    }
  };

  handleContextMenu = e => {
    e.preventDefault();

    if (!this.state.linkActive) {
      const availableRelations = this.getAvailableRelations();
      if (!availableRelations?.length) {
        return;
      }
    }

    const relations = this.getRelations();
    if (this.state.linkActive) {
      this.props.onRemoveRelations(relations);
    }

    this.setState({
      linkActive: !this.state.linkActive,
      linkFocus: this.state.linkActive ? false : this.state.linkFocus,
    });
  };

  // timer;
  handleMouseOver = () => {
    // if (this.timer) {
    //   clearTimeout(this.timer);
    // }
    // this.timer = setTimeout(() => {
    if (!this.state.linkActive) {
      const relations = this.getRelations();
      relations?.length && this.props.onAddRelations(relations);
    }

    this.setState(
      {
        linkFocus: true,
      },
      () => {
        if (this.props.mode === 'monthMode') {
          this.setState({ showTooltip: this.shouldShowTooltip() });
        } else {
          // this.props.archerEl.current.refreshScreen();
        }
      },
    );
    // }, 100);
  };
  mouseOutFromRequest = () => {
    // this.props.archerEl.current.refreshScreen();
    if (!this.state.linkActive) {
      const relations = this.getRelations();
      relations?.length && this.props.onRemoveRelations(relations);
    }

    this.setState(
      {
        linkFocus: false,
      },
      () => {
        if (this.props.mode === 'monthMode') {
          this.timeout = setTimeout(() => this.setState({ showTooltip: false }), 1000);
        }
      },
    );
  };

  disableByNfStatus = () => {
    const { task, display } = this.props;
    if (display.value === 'All' || display.value === 'NF status') return false;
    if (task.status === 'drafted') {
      return true;
    }
    if (task.status === 'sent') {
      return true;
    }
    if (task?.status === 'submitted') {
      return true;
    }
    if (task.status === 'provided') {
      return true;
    }
    return task?.status === 'declined' && display.value !== 'Request re-opened';
  };

  getNfStatus = () => {
    const { task } = this.props;
    if (task?.status === 'drafted') {
      return 'Request Drafted';
    }
    if (task?.status === 'sent') {
      return 'Request Sent';
    }
    if (task?.status === 'submitted') {
      return 'Commitment Submitted';
    }
    if (task?.status === 'provided') {
      return 'Response Provided';
    }
    if (task?.status === 'accepted') {
      return 'Response Accepted';
    }
    if (task?.status === 'declined') {
      return 'Request Re-opened';
    }
  };

  handleTaskHover = () => {
    this.setState({ fullHeight: true });
  };

  handleTaskLeave = () => {
    this.setState({ fullHeight: false });
  };

  render() {
    const { styles, mode, task, makeBgColor, zoomStyle, zoom, location, requestCompany, responseCompany, setMode, reset } = this.props;
    const { fullHeight } = this.state;

    const TaskClass = this.getTaskClassName();
    let bgColor = task.request_discipline?.color ? makeBgColor(task.request_discipline.color) : '';

    let TaskStyle, width;

    TaskStyle = { ...zoomStyle.task, background: bgColor };
    width = '100%';

    let disabled;
    if (
      this.disableByNfStatus() ||
      (location.value !== task.location?.title && location.value !== 'All' && location.value !== 'Level') ||
      (requestCompany.value !== task.request_party.company &&
        requestCompany.value !== 'All' &&
        requestCompany.value !== 'Request company') ||
      (responseCompany.value !== task?.response_party?.company &&
        responseCompany.value !== 'All' &&
        responseCompany.value !== 'Response company')
    ) {
      TaskStyle.backgroundColor = '#999';
      TaskStyle.pointerEvents = 'none';
      TaskStyle.opacity = 0.7;
      disabled = true;
    }

    return (
      <>
        <div
          className={TaskClass}
          style={TaskStyle}
          onClick={() => this.props.openRequest(task.id, task.nf, task.due_date)}
          ref={this.props.taskRef}
        >
          <div className={styles.description}>{(task && task.specific) || ReactHtmlParser(task.desc)}</div>
          {!!task.successor_nf || (!!task.predecessor_nf && <div className={styles.link}></div>)}
        </div>
        <div
          className={`${TaskClass} ${this.props.active && !disabled ? 'fadeIn' : 'fadeOut'}`}
          style={TaskStyle}
          onClick={() => this.props.openRequest(task.id, task.nf, task.due_date)}
        >
          <div className={styles.description}>{task && this.getNfStatus()}</div>
          {task.ufc &&
            task.ufc.map((item, i) => (
              <div key={i} className={styles.description}>
                {item.code} - {item.description}
              </div>
            ))}

          <div className={styles.description}>{task.request_party.company}</div>
          <div className={styles.description}>{task.response_party?.company || ''}</div>
          <div className={styles.description}>{task && task.specific && ReactHtmlParser(task.specific)}</div>
          {!!task.successor_nf || (!!task.predecessor_nf && <div className={styles.link}></div>)}
        </div>
      </>
    );
  }
}
