import { errorsReducerModel } from './errosReducerModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TitleList } from '../../components/pages/ErrorsPopUp/ErrorsPopUpConstants';

const initialState: errorsReducerModel = {
  isShowPopUp: false,
  description: '',
  title: '',
  statusCode: 0,
  message: '',
  isPageNotFound: false,
  pageNotFoundURL: '',
};

export const errorsSlice = createSlice({
  initialState,
  name: 'errorsReducer',
  reducers: {
    setShowErrorData(state, { payload }: PayloadAction<errorsReducerModel>) {
      const { description, title, statusCode, message, isPageNotFound, pageNotFoundURL } = payload;
      if (TitleList.includes(title) || statusCode === 500 || isPageNotFound) {
        state.isShowPopUp = true;
        state.description = description;
        state.title = title;
        state.statusCode = statusCode;
        state.message = message;
        state.isPageNotFound = isPageNotFound;
        state.pageNotFoundURL = pageNotFoundURL;
      }
    },
    deleteErrorData(state) {
      state.isShowPopUp = false;
      state.description = '';
      state.title = '';
      state.message = '';
      state.statusCode = 0;
      state.isPageNotFound = false;
      state.pageNotFoundURL = '';
    },
  },
});

export default errorsSlice.reducer;
