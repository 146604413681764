import React, { ForwardedRef, forwardRef, MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import './DropdownBasisStyles.scss';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import { isEmptyObject } from '../../../../../helpers/commonHelpers';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import { MiniLoader } from '../../../MiniLoader';
import ChipsStandardTag from '../../../Chips/ChipsStandardTag/ChipsStandardTag';
import CustomTooltip from '../../../Tooltip/Tooltip';
import { DropdownItemModel } from '../../../../../models/global';
import ReactResizeDetector from 'react-resize-detector';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue extends boolean> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface DropdownBasisProps<T, isMultiValue extends boolean> {
  isMulti: boolean;
  customClassNameContainer?: string;
  customClassNameField?: string;
  renderCustomBasis?: (
    isActive: boolean,
    disabled: boolean,
    isLoading: boolean,
    selected: SelectedValue<T, isMultiValue>,
  ) => ReactElement | ReactNode;
  selected: SelectedValue<T, isMultiValue>;
  placeholder: string;
  renderSelected: (selectedItem: T) => ReactElement | ReactNode;
  basisClickHandler: (e: MouseEvent) => void;
  isActive: boolean;
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  maxElements?: number;
  tooltip?: string | ReactNode;
  toolTipClassName?: string;
  isAlwaysPlaceholder?: boolean;
  isExpandOnHover?: boolean;
  isDisableMinWidth?: boolean;
  isMobile?: boolean;
  selectType?: string;
  disableRightElem?: boolean;
}

type DropdownBasisWithRefProps<T, isMultiValue extends boolean> = DropdownBasisProps<T, isMultiValue> & {
  mRef?: ForwardedRef<unknown>;
};

function DropdownBasisInner<T extends DropdownItemModel, isMultiValue extends boolean>(
  {
    isMulti,
    customClassNameContainer,
    customClassNameField,
    selected,
    placeholder,
    renderSelected,
    renderCustomBasis,
    basisClickHandler,
    isActive,
    isLoading,
    error,
    disabled,
    maxElements,
    tooltip,
    toolTipClassName,
    isAlwaysPlaceholder = false,
    isExpandOnHover = true,
    isDisableMinWidth = false,
    isMobile = false,
    selectType = '',
    disableRightElem = false,
  }: DropdownBasisProps<T, isMultiValue>,
  forwardedRef: ForwardedRef<unknown>,
) {
  const themeClass = useGetThemeClass('b-dropdownBasis');
  const customThemeClass = useGetThemeClass('b-dropdownCustomBasis');

  const [isNotContain, setIsNotContain] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const isShowAllMultiItems = useMemo(() => {
    return (isHovered || isActive) && !isLoading && isMulti && selected;
  }, [isActive, isHovered, isMulti, selected, isLoading]);

  const itemsToShow = useMemo(() => {
    if (isMulti) {
      return selected || [];
    }
  }, [isActive, isHovered, selected, isMulti]);

  const isSelected = useMemo(() => {
    if (isMulti) {
      return !!selected && !isEmptyObject(selected);
    } else {
      if (Array.isArray(selected)) {
        return !!selected.length;
      } else {
        return !!selected;
      }
    }
  }, [selected, isMulti]);

  const generateShowMoreBlock = (height, scrollHeight) => {
    if (height !== scrollHeight) {
      setIsNotContain(true);
    } else {
      setIsNotContain(false);
    }
  };

  useEffect(() => {
    if (containerRef?.current && !isHovered) {
      const blockHeight = containerRef.current.offsetHeight;
      const scrollHeight = containerRef.current.scrollHeight;

      generateShowMoreBlock(blockHeight, scrollHeight);
    }
  }, [itemsToShow, containerRef?.current]);

  const handleResize = (width: number, height: number) => {
    if (containerRef?.current) {
      const scrollHeight = containerRef.current.scrollHeight;

      generateShowMoreBlock(height, scrollHeight);
    }
  };

  const handleBasisClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (isLoading) {
      return;
    }

    basisClickHandler(e);
  };

  return (
    <>
      {renderCustomBasis ? (
        <div
          className={cn(customThemeClass, {
            [`${customClassNameContainer}`]: customClassNameContainer,
          })}
          ref={forwardedRef}
          onClick={handleBasisClick}
        >
          <CustomTooltip className={`${toolTipClassName}`} title={tooltip} placement={'top'} enterDelay={1000} enterNextDelay={1000}>
            {renderCustomBasis(isActive, disabled, isLoading, selected)}
          </CustomTooltip>
        </div>
      ) : (
        <div
          ref={forwardedRef}
          className={cn(themeClass, {
            [`${customClassNameContainer}`]: customClassNameContainer,
            [`-error`]: error,
            [`-disabled`]: selectType === 'deliverables' ? false : disabled,
            [`-mobile`]: isMobile,
          })}
        >
          <CustomTooltip title={tooltip} placement={'top'} enterDelay={1000} enterNextDelay={1000}>
            <div
              className={cn(`${themeClass}_field`, {
                [`${customClassNameField}`]: customClassNameField,
                [`-active`]: isActive,
                [`-error`]: error,
                [`-loading`]: isLoading,
                [`-expand`]: isExpandOnHover && isShowAllMultiItems && selected?.length,
                [`-isDisableMinWidth`]: isDisableMinWidth,
                [`-mobile`]: isMobile,
              })}
              onClick={handleBasisClick}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                className={cn(`${themeClass}_field_content`, {
                  [`-expand`]: isExpandOnHover && isShowAllMultiItems,
                })}
              >
                {isAlwaysPlaceholder ? (
                  <div className={cn(`${themeClass}_field_content_placeholder`, { [`-mobile`]: isMobile })}>{placeholder || 'Select'}</div>
                ) : (
                  <>
                    {!isSelected && (
                      <div className={cn(`${themeClass}_field_content_placeholder`, { [`-mobile`]: isMobile })}>
                        {placeholder || 'Select'}
                      </div>
                    )}
                    {isSelected && selected && !isMulti ? (
                      <div className={`${themeClass}_field_content_value`}>{renderSelected(selected)}</div>
                    ) : null}
                    {isSelected && isMulti && selected?.length ? (
                      <>
                        <ReactResizeDetector
                          targetRef={containerRef}
                          handleHeight={true}
                          refreshMode={'throttle'}
                          onResize={handleResize}
                        />
                        <div className={`${themeClass}_field_content_values`} ref={containerRef}>
                          {itemsToShow.map(item => {
                            return (
                              <div
                                className={cn(`${themeClass}_field_content_value`, { [`-mobile`]: isMobile })}
                                key={item.value || item.id}
                              >
                                {renderSelected(item)}
                              </div>
                            );
                          })}
                        </div>
                        {!isShowAllMultiItems && isNotContain ? (
                          <ChipsStandardTag customClassName={`${themeClass}_field_content_more`} value={'...'} />
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </div>
              {!disableRightElem && (
                <div className={`${themeClass}_field_rightElem`}>
                  {!isLoading ? (
                    <div
                      className={cn(`${themeClass}_field_rightElem_selectIcon`, {
                        [`-active`]: isActive,
                      })}
                    >
                      <TokenIcon iconName={'chevron-down'} size={isMobile ? 24 : 12} />
                    </div>
                  ) : null}
                  {isLoading ? (
                    <div className={`${themeClass}_field_rightElem_loader`}>
                      <MiniLoader
                        size={isMobile ? 'lg' : 'xs'}
                        rootClasses={`${themeClass}_field_rightElem_loader_root`}
                        circleClasses={`${themeClass}_field_rightElem_loader_circle`}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </CustomTooltip>
          {!!error && <span className={`${themeClass}_error`}>{error}</span>}
        </div>
      )}
    </>
  );
}

const DropdownBasisWithRef = forwardRef(DropdownBasisInner);

function DropdownBasis<T extends DropdownItemModel, isMultiValue extends boolean>({
  mRef,
  ...props
}: DropdownBasisWithRefProps<T, isMultiValue>) {
  // @ts-ignore
  return <DropdownBasisWithRef ref={mRef} {...props} />;
}

export default DropdownBasis;
