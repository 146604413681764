import { ProjectApi } from '../../service/Api/Api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UFCSearchModel } from '../../models';
import {
  CreateTypicalModelResponse,
  CreateTypicalPayloadModel,
  DriversDataModel,
  DriversListVersionModel,
  GetDriversListVersionsPayloadModel,
  GetDriversPayloadModel,
  getDriversPayloadModel,
  GetDriversVersionPayloadModel,
  IBulkCreateMultiplePCDPayload,
  ICreateMultiplePCDPayload,
  ICreatePCDPayload,
  IDeliverablePCDPayload,
  IDrawingDaysPayload,
  ISelectZonePCDPayload,
  IUpdateDrawingReviewsPCDPayload,
  IUpdateDrawingsListPayload,
  IUpdateReviewsPCDPayload,
  SaveDriversPayloadModel,
} from './projectReducer.model';
import { getMembers } from '../globalSettings/settingsThunk';
import { errorsSlice } from '../errors/errorsSlice';
import { ErrorResponseModel } from '../../models/Api/response.model';
import { settingsSlice } from '../globalSettings/settingsSlice';
const { setShowErrorData } = errorsSlice.actions;
const { setIsFirstHashRender } = settingsSlice.actions;

export const getDisciplines = createAsyncThunk<any, { project_id: string }>(
  'projectReducer/getDisciplines',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getDisciplines(payload.project_id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateLinkParty = createAsyncThunk<any, any>(
  'projectReducer/updateLinkParty',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectIds = payload;
      const projectId = sessionStorage.getItem('active_project_id');

      const response = await ProjectApi.updateLinkParty(projectIds, projectId);

      dispatch(getMembers());

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createTypical = createAsyncThunk<
  CreateTypicalModelResponse,
  CreateTypicalPayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('projectReducer/createTypical', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { callback, projectId } = payload;
    const response = await ProjectApi.createTypical(payload);

    if (callback) {
      callback({ ...response.data });
    }

    return { ...response, projectId };
  } catch (error) {
    error.buildingId = payload.id;
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getUsersCompany = createAsyncThunk<any, any>(
  'projectReducer/getUsersCompany',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { search, party_id } = payload;
      const response = await ProjectApi.getAllusersCompany(search, party_id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const savePhasePlan = createAsyncThunk<any, any>('projectReducer/savePhasePlan', async (payload, { dispatch, rejectWithValue }) => {
  const projectId = sessionStorage.getItem('active_project_id');
  const data = payload;
  try {
    const response = await ProjectApi.savePhasePlan(data, projectId);

    return { data: response.data };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getUfcData = createAsyncThunk<any, number>('projectReducer/getUfcData', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await ProjectApi.getUfcData(payload);

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getUfcListActive = createAsyncThunk<any, number>(
  'projectReducer/getUfcListActive',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getUfcListActive(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getUFCVersions = createAsyncThunk<any, number>(
  'projectReducer/getUFCVersions',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getUFCVersions(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getUFCVersionData = createAsyncThunk<any, number>(
  'projectReducer/getUFCVersionData',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getUFCVersionData(payload);

      return { buildings: response.data.buildings };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const postUFCData = createAsyncThunk<any, any>('projectReducer/postUFCData', async (payload, { dispatch, rejectWithValue }) => {
  const { saveObject, callback } = payload;
  try {
    const response = await ProjectApi.postUFCData(saveObject);

    if (callback) {
      callback();
    }

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const searchUFC = createAsyncThunk<any, { textSearch: string; buildingId: number[]; projectId: number | unknown }>(
  'projectReducer/searchUFC',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.searchUFC(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const searchUFCResult = createAsyncThunk<{ data: any; ufcId: number }, UFCSearchModel>(
  'projectReducer/searchUFCResult',
  async (payload, { dispatch, rejectWithValue }) => {
    const { ufcId } = payload;
    try {
      const response = await ProjectApi.searchUFCResult(payload);

      return { data: response.data, ufcId };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const searchUFCPopoverResult = createAsyncThunk<
  any,
  { ufcId: string | number; buildingId: number[]; projectId: number; callback?: any }
>('projectReducer/searchUFCPopoverResult', async (payload, { dispatch, rejectWithValue }) => {
  const { ufcId, projectId, buildingId, callback } = payload;
  try {
    const response = await ProjectApi.searchUFCResult({ ufcId: ufcId, projectId: projectId, buildingId: buildingId });

    if (callback) {
      callback();
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getProjectRefreshes = createAsyncThunk<any, number | string>(
  'projectReducer/getProjectRefreshes',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getProjectRefreshes(payload);

      return { ...response.data, id: payload };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getProjectDetails = createAsyncThunk<any, number | string>(
  'projectReducer/getProjectDetails',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getProjectDetails(payload);

      return { ...response.data, id: payload };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPCDeliverables = createAsyncThunk<any, number | string>(
  'projectReducer/getPCDeliverables',
  async (payload, { dispatch, rejectWithValue }) => {
    const buildingId = payload;
    const projectId = Number(sessionStorage.getItem('active_project_id'));
    try {
      const response = await ProjectApi.getPCDeliverables(projectId, buildingId);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPCDMain = createAsyncThunk<any, any>(
  'projectReducer/getPCDMain',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    const buildingId = payload.buildingId;
    const projectId = Number(sessionStorage.getItem('active_project_id'));
    try {
      const currentState = getState();
      const isFirstRenderWithURLHash = currentState?.settingsReducer?.isFirstRenderWithURLHash;
      const filterHash = new URLSearchParams(location.search).get(`deliverable_hash`);
      const generateUrlWithFiltersHash = hash => {
        if (isFirstRenderWithURLHash) {
          dispatch(setIsFirstHashRender());
        }
        if (typeof hash === 'string') {
          payload.callbackNavigation(hash);
        } else {
          payload.callbackNavigation(hash);
        }
      };

      let projectParam = {
        ...payload.data,
        local_storage: JSON.parse(localStorage.getItem(`deliverable`)),
      };
      if (isFirstRenderWithURLHash && filterHash) {
        projectParam.filters_hash = `deliverable_hash=${filterHash}`;
      }

      const response = await ProjectApi.getPCDMain(projectParam, projectId, buildingId);
      generateUrlWithFiltersHash(response?.response?.filters_hash);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDrivers = createAsyncThunk<DriversDataModel, GetDriversPayloadModel>(
  'projectReducer/getDrivers',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getDrivers(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const saveDrivers = createAsyncThunk<DriversDataModel, SaveDriversPayloadModel>(
  'projectReducer/saveDrivers',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { saveData, callback } = payload;
      const response = await ProjectApi.saveDrivers(saveData);

      callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDriversListVersions = createAsyncThunk<DriversListVersionModel[], GetDriversListVersionsPayloadModel>(
  'projectReducer/getDriversListVersions',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getDriversListVersions(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDriversVersion = createAsyncThunk<DriversDataModel, GetDriversVersionPayloadModel>(
  'projectReducer/getDriversVersion',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getDriversVersion(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setPCDCalendarUpdate = createAsyncThunk<
  any,
  { data: { scheduled_date: any; driver?: any; project_id: number; id: number }; callback?: any }
>('projectReducer/setPCDCalendarUpdate', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const id = payload.data.id;
    const { data, callback } = payload;
    const response = await ProjectApi.setPCDCalendarUpdate(data, id);

    const verificationDates = [];

    if (Object.keys(response.data).length && !response.data.hasOwnProperty('id')) {
      for (let title in response.data) {
        const levels = [];
        if (Object.keys(response.data[title]).length) {
          Object.values(response.data[title]).map((el, index) => {
            if (el !== false) {
              levels.push({ title: Object.keys(response.data[title])[index], dates: el });
            }
          });
        }
        if (levels.length) {
          verificationDates.push({ [title]: levels });
        }
      }
    }

    if (callback) {
      callback(verificationDates);
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const setPCDAreaCreate = createAsyncThunk<any, any>(
  'projectReducer/setPCDAreaCreate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = payload;
      const response = await ProjectApi.setPCDAreaCreate(data);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setPCDAreaUpdate = createAsyncThunk<any, { id: number | string; title: string; project_id: number | string; callback: any }>(
  'projectReducer/setPCDAreaUpdate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = payload;
      const id = data.id;
      delete data.id;

      const response = await ProjectApi.setPCDAreaUpdate(data, id);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setPCDAreaDelete = createAsyncThunk<any, { id: number; callback: any }>(
  'projectReducer/setPCDAreaDelete',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, callback } = payload;
    try {
      const response = await ProjectApi.setPCDAreaDelete(id);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateDrawingDays = createAsyncThunk<any, { data: IDrawingDaysPayload; callback?: any }>(
  'projectReducer/updateDrawingDays',
  async (payload, { dispatch, rejectWithValue }) => {
    const id = payload.data.drawing_id;
    const { data, callback } = payload;
    try {
      const response = await ProjectApi.updateDrawingDays(data, id);

      const verificationDates = [];

      if (Object.keys(response.data).length && !response.data.hasOwnProperty('id')) {
        for (let title in response.data) {
          const levels = [];
          if (Object.keys(response.data[title]).length) {
            Object.values(response.data[title]).map((el, index) => {
              if (el !== false) {
                levels.push({ title: Object.keys(response.data[title])[index], dates: el });
              }
            });
          }
          if (levels.length) {
            verificationDates.push({ [title]: levels });
          }
        }
      }

      if (callback) {
        callback(verificationDates);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const bulkUpdateDrawingDays = createAsyncThunk<any, { data: IDrawingDaysPayload; callback?: any }>(
  'projectReducer/bulkUpdateDrawingDays',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const response = await ProjectApi.bulkUpdateDrawingDays(data);
      const verificationDates = [];

      if (Object.keys(response.data).length && !response.data.hasOwnProperty('id')) {
        for (let i in response.data) {
          if (!response.data[i].hasOwnProperty('id')) {
            for (let title in response.data[i]) {
              const levels = [];
              if (Object.keys(response.data[i][title]).length) {
                Object.values(response.data[i][title]).map((el, index) => {
                  if (el !== false) {
                    levels.push({ title: Object.keys(response.data[i][title])[index], dates: el });
                  }
                });
              }
              if (levels.length) {
                verificationDates.push({ [title]: levels });
              }
            }
          }
        }
      }

      if (callback) {
        callback(verificationDates);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createPCD = createAsyncThunk<any, ICreatePCDPayload>(
  'projectReducer/createPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.createPCD(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createMultiplePCD = createAsyncThunk<any, { data: ICreateMultiplePCDPayload; callback?: any }>(
  'projectReducer/createMultiplePCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const response = await ProjectApi.createMultiplePCD(data);

      const verificationDates = [];

      if (
        callback &&
        Object.keys(response.data).length &&
        !response.data.hasOwnProperty('id') &&
        !Object.values(response.data)[0].hasOwnProperty('id')
      ) {
        for (let title in response.data) {
          const levels = [];
          if (Object.keys(response.data[title]).length) {
            Object.values(response.data[title]).map((el, index) => {
              if (el !== false) {
                levels.push({ title: Object.keys(response.data[title])[index], dates: el });
              }
            });
          }
          if (levels.length) {
            verificationDates.push({ [title]: levels });
          }
        }
      }

      if (callback) {
        callback(verificationDates);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const bulkCreateMultiplePCD = createAsyncThunk<any, { data: IBulkCreateMultiplePCDPayload; callback?: any }>(
  'projectReducer/bulkCreateMultiplePCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const response = await ProjectApi.bulkCreateMultiplePCD(data);
      const verificationDates = [];

      if (Object.keys(response.data).length && !response.data.hasOwnProperty('id')) {
        for (let i in response.data) {
          const object = response.data[i];
          if (!response.data[i].hasOwnProperty('id') && !(object && object.length && object[0]?.hasOwnProperty('id'))) {
            for (let title in response.data[i]) {
              const levels = [];
              if (Object.keys(response.data[i][title]).length) {
                Object.values(response.data[i][title]).map((el, index) => {
                  if (el !== false) {
                    levels.push({ title: Object.keys(response.data[i][title])[index], dates: el });
                  }
                });
              }
              if (levels.length) {
                verificationDates.push({ [title]: levels });
              }
            }
          }
        }
      }

      if (callback) {
        callback(verificationDates);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deliverablePCD = createAsyncThunk<any, IDeliverablePCDPayload>(
  'projectReducer/deliverablePCD',
  async (payload, { dispatch, rejectWithValue }) => {
    const deliverable = payload.deliverable_id;
    const data = { ...payload };
    try {
      delete data.deliverable_id;

      const response = await ProjectApi.deliverablePCD(data, deliverable);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setSelectZonePCD = createAsyncThunk<any, { data: ISelectZonePCDPayload; callback: () => void }>(
  'projectReducer/setSelectZonePCD',
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    const drawing = data.drawing_id;
    const body = { ...data };
    try {
      delete body.drawing_id;

      const response = await ProjectApi.setSelectZonePCD(body, drawing);

      callback && callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateDrawingReviewsPCD = createAsyncThunk<any, IUpdateReviewsPCDPayload>(
  'projectReducer/updateDrawingReviewsPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const drawing = payload.drawing_id;
      const data = { ...payload };
      delete data.drawing_id;

      const response = await ProjectApi.updateDrawingReviewsPCD(data, drawing);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getDrawingReviewsPCD = createAsyncThunk<any, { drawing_ids: number[] }>(
  'projectReducer/getDrawingReviewsPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getDrawingReviewsPCD(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const bulkUpdateDrawingReviewsPCD = createAsyncThunk<any, IUpdateDrawingReviewsPCDPayload>(
  'projectReducer/bulkUpdateDrawingReviewsPCD',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.bulkUpdateDrawingReviewsPCD(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createDrawingForBuilding = createAsyncThunk<any, { building_id: number; callback: () => void }>(
  'projectReducer/createDrawingForBuilding',
  async (payload, { dispatch, rejectWithValue }) => {
    const { building_id, callback } = payload;
    try {
      const response = await ProjectApi.createDrawingForBuilding(building_id);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getListDrawings = createAsyncThunk<any, number>(
  'projectReducer/getListDrawings',
  async (payload, { dispatch, rejectWithValue }) => {
    const buildingId = payload;
    try {
      const response = await ProjectApi.getListDrawings(buildingId);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateDrawingsList = createAsyncThunk<any, IUpdateDrawingsListPayload>(
  'projectReducer/updateDrawingsList',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.updateDrawingsList(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteDrawing = createAsyncThunk<any, number>(
  'projectReducer/deleteDrawing',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.deleteDrawing(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getListDisciplines = createAsyncThunk<any>(
  'projectReducer/getListDisciplines',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectApi.getListDDisciplines();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
