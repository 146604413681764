import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Logo } from '../../controls/index';
import ButtonView from '../../controls/ButtonComponents/ButtonView/ButtonView';
import { unsubscribeReleaseUser } from '../../../store/user/userThunk';
import { handleGetThemeClass } from '../../../helpers/designTokens';
import { withRouter } from '../../../navigation/withRouter/withRouter';

import '../NonLoginedPage/NonLoginedPageStyles.scss';
import './UnsubscribeStyles.scss';

class Unsubscribe extends React.Component {
  clickBtnHash = () => {
    const { searchParams } = this.props;
    const hash_from_url = searchParams.get('user');
    this.props.unsubscribeReleaseUser(hash_from_url);
  };

  render() {
    const themeClass = handleGetThemeClass('b-authorization-loginPage', localStorage.getItem('theme') || 'light');

    return (
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_topInfo`}>
          <div className={'containerUnsubscribe'}>
            <div className={`${themeClass}_logoContainer marginUnsubscribe`}>
              <Logo isFull themeProp={localStorage.getItem('theme') || 'light'} />
              <p className={`${themeClass}_logoContainer__header marginUnsubscribe ${localStorage.getItem('theme') || 'light'}`}>
                You will no longer receive emails about latest updates and improvements of Planit App. Are you sure you want to get your
                email off the list?
              </p>
            </div>
            <ButtonView
              handleClick={this.clickBtnHash}
              type={'dark_primal'}
              title={'Yes, unsubscribe'}
              style={{ margin: '20px 145px' }}
              width={'140px'}
              disabled={this.props.unsubscribeReleaseUserStatus}
            />
            <div className={`textUnsubscribe ${this.props.unsubscribeReleaseUserStatus == 'SUCCESS' ? 'textSuccess' : 'textError'}`}>
              {this.props.unsubscribeReleaseUserMSG}
            </div>
          </div>
        </div>
        <div className={`${themeClass}_bottomInfo`}>
          <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
          {/*<span className={`${themeClass}_bottomInfo_textInfo`}>Privacy Policy</span>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer }) => ({
  unsubscribeReleaseUserMSG: userReducer.unsubscribeReleaseUserMSG,
  unsubscribeReleaseUserStatus: userReducer.unsubscribeReleaseUserStatus,
});

const mapDispatchToProps = dispatch => bindActionCreators({ unsubscribeReleaseUser }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unsubscribe));
