import React, { FC, useEffect, useState } from 'react';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { TextInputNew } from '../../controls';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { FILE_SIZE_LIMIT } from '../../../service/file';
import { createClient, updateClient } from '../../../store/user/userThunk';
import { Handle, SliderTooltip } from 'rc-slider';
import { AvatarPositionInterface } from '../Profile/Profile.types';
import { AvatarImageEditor } from '../../controls/AvatarImageEditor/AvatarImageEditor';
import { CreateClientDataModel, UpdateClientPayloadModel } from '../../../models';
import { TeamApi } from '../../../service/Api/team/types';
import settingsApi from '../../../service/Api/settingsApi';
import { FileModel } from '../TeamTab/CompanyForm/type';
import Rendering = TeamApi.Rendering;
import { userSlice } from '../../../store/user/userSlice';
import AvatarComponent from '../../controls/Avatar/AvatarComponent';

const { clearClientError } = userSlice.actions;

import './EditCompanyPopupStyles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  editingCompany: any | null;
}

const EditCompanyPopup: FC<IProps> = ({ onClose, isOpen, editingCompany }) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-editCompanyPopup');

  const [isOpenEditorPopup, setIsOpenEditorPopup] = useState<boolean>(false);
  const [editorPosition, setEditorPosition] = useState<AvatarPositionInterface | null>(null);
  const [avatarScale, setAvatarScale] = useState<number>(1);
  const [avatarRotation, setAvatarRotation] = useState<number>(0);

  const [isNewFile, setIsNewFile] = useState<boolean>(false);
  const [companyAvatar, setCompanyAvatar] = useState(null);
  const [editCompany, setEditCompany] = useState({
    title: '',
    desc: '',
  });

  const clientProjectsLoading = useAppSelector(state => state.userReducer.clientProjectsLoading);
  const clientError = useAppSelector(state => state.userReducer.clientError);

  const isSubmitDisabled = !editCompany.title.trim();
  const avatar = (companyAvatar && URL.createObjectURL(companyAvatar)) || editingCompany?.renderings?.[0]?.file;

  useEffect(() => {
    if (editingCompany) {
      setEditCompany({
        title: editingCompany.title,
        desc: editingCompany.desc,
      });
    }
  }, [editingCompany]);

  const handleClose = () => {
    setEditCompany({
      title: '',
      desc: '',
    });
    setCompanyAvatar(null);
    setIsNewFile(false);
    dispatch(clearClientError());
    onClose();
  };

  const handleFile = e => {
    const files = Array.from(e.target.files).filter((f: any) => f?.size < FILE_SIZE_LIMIT);

    if (files[0]) {
      setCompanyAvatar(files[0]);
      handleOpenEditorPopup();
    }
  };

  const handleOpenEditorPopup = () => {
    setIsOpenEditorPopup(true);
    setAvatarScale(1);
  };

  const handleCloseEditorPopup = () => {
    setIsOpenEditorPopup(false);
    setCompanyAvatar(null);
  };

  const handleSlider = props => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <SliderTooltip prefixCls="rc-slider-tooltip" overlay={`${value} %`} visible={dragging} placement="top" key={index}>
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const onSliderChange = value => {
    setAvatarScale(value);
  };

  const handlePositionChange = editorPosition => {
    setEditorPosition(editorPosition);
  };

  const rotateAvatar = (e, direction) => {
    e.preventDefault();

    if (direction === 'left') {
      setAvatarRotation((avatarRotation - 90) % 360);
    } else {
      setAvatarRotation((avatarRotation + 90) % 360);
    }
  };

  const onAvatarSave = async editorRef => {
    if (editorRef) {
      const dataUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      setCompanyAvatar(blob);
      setIsNewFile(true);
      setIsOpenEditorPopup(false);
      setAvatarRotation(0);
    }
  };

  const handleChange = (type: 'title' | 'desc') => (value: string | number) => {
    if (type === 'title') {
      dispatch(clearClientError());
    }
    setEditCompany(prevState => ({ ...prevState, [type]: value }));
  };

  const handleSave = async () => {
    let renderings: Rendering | undefined;

    if (isNewFile) {
      try {
        const uploadResponse = await settingsApi.filesUpload(companyAvatar);

        if (uploadResponse && uploadResponse.data) {
          const file: FileModel = uploadResponse.data;
          renderings = {
            filepath: file.filepath,
            original_name: file.original_name,
            action: 'add',
          };
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (editingCompany) {
      const data: UpdateClientPayloadModel = {
        clientId: editingCompany.id,
        bodyParams: {
          title: editCompany.title,
          desc: editCompany.desc,
        },
        callback: handleClose,
      };

      if (isNewFile) {
        data.bodyParams.renderings = renderings;
      }

      dispatch(updateClient(data));
    } else {
      const bodyParams: CreateClientDataModel = {
        title: editCompany.title,
        desc: editCompany.desc,
        renderings: renderings,
      };

      if (isNewFile) {
        bodyParams.renderings = renderings;
      }

      dispatch(createClient({ bodyParams, callback: handleClose }));
    }
  };

  return (
    <>
      <DialogPopUp
        open={isOpen}
        onClose={handleClose}
        customClassname={themeClass}
        title={editingCompany ? 'Edit company' : 'Create company'}
        renderModalContent={() => (
          <div className={`${themeClass}_content`}>
            <div className={`${themeClass}_content_left`}>
              <AvatarComponent
                handleFile={handleFile}
                avatar={avatar}
                isAvatarEdit={!!avatar}
                handleOpenEditorPopup={handleOpenEditorPopup}
                tooltip={'Recommended image size: 150px * 150px'}
                label="Upload logo"
              />
            </div>
            <div className={`${themeClass}_content_right`}>
              <TextInputNew
                label={'Company name'}
                placeholder={'Company name'}
                value={editCompany?.title}
                onChange={handleChange('title')}
                type={'on-bgd'}
                error={clientError}
              />
              <TextInputNew
                label={'Website'}
                placeholder={'https:/...'}
                value={editCompany?.desc}
                onChange={handleChange('desc')}
                type={'on-bgd'}
              />
            </div>
          </div>
        )}
        paperMaxWidth={'492px'}
        loadingOnPrimary={clientProjectsLoading}
        primaryText={'Save'}
        handleOnPrimary={handleSave}
        secondaryText={'Cancel'}
        customTertiaryType={'text-plain'}
        handleOnSecondary={handleClose}
        primaryTextDisabled={isSubmitDisabled}
      />
      <AvatarImageEditor
        title={'Logo editor'}
        width={150}
        height={150}
        borderRadius={100}
        isOpenEditorPopup={isOpenEditorPopup}
        handleCloseEditorPopup={handleCloseEditorPopup}
        imageForEdit={avatar}
        avatarScale={avatarScale}
        avatarRotation={avatarRotation}
        editorPosition={editorPosition}
        handlePositionChange={handlePositionChange}
        handleSlider={handleSlider}
        onSliderChange={onSliderChange}
        rotateAvatar={rotateAvatar}
        onAvatarSave={onAvatarSave}
      />
    </>
  );
};

export default EditCompanyPopup;
