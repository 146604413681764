import { actionCreater } from '../configure/actionCreater';

//Create Party

export const PARTY_SAVE = 'PARTY_SAVE';
export const PartySave = actionCreater(PARTY_SAVE);

export const PARTY_SAVE_SUCCES = 'PARTY_SAVE_SUCCES';
export const PartySaveSucces = actionCreater(PARTY_SAVE_SUCCES);

export const PARTY_SAVE_FAIL = 'PARTY_SAVE_FAIL';
export const PartySaveFail = actionCreater(PARTY_SAVE_FAIL);

//Update Party

export const PARTY_UPDATE = 'PARTY_UPDATE';
export const PartyUpdate = actionCreater(PARTY_UPDATE);

export const PARTY_UPDATE_SUCCESS = 'PARTY_UPDATE_SUCCESS';
export const PartyUpdateSuccess = actionCreater(PARTY_UPDATE_SUCCESS);

export const PARTY_UPDATE_FAIL = 'PARTY_UPDATE_FAIL';
export const PartyUpdateFail = actionCreater(PARTY_UPDATE_FAIL);

// Get Members

export const GET_MEMBERS = 'GET_MEMBERS';
export const getMembers = actionCreater(GET_MEMBERS);

export const GET_MEMBERS_SUCCES = 'GET_MEMBERS_SUCCES';
export const getMembersSucces = actionCreater(GET_MEMBERS_SUCCES);

export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';
export const getMembersFail = actionCreater(GET_MEMBERS_FAIL);

// Add Members

export const ADD_MEMBER = 'ADD_MEMBER';
export const addMember = actionCreater(ADD_MEMBER);

export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const addMemberSuccess = actionCreater(ADD_MEMBER_SUCCESS);

export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const updateMemberSuccess = actionCreater(UPDATE_MEMBER_SUCCESS);

export const ADD_MEMBER_FAIL = 'ADD_MEMBER_FAIL';
export const addMemberFail = actionCreater(ADD_MEMBER_FAIL);

// Delete Members

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const delMember = actionCreater(DELETE_MEMBER);

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const delMemberSuccess = actionCreater(DELETE_MEMBER_SUCCESS);

export const DELETE_MEMBER_FAIL = 'DELETE_MEMBER_FAIL';
export const delMemberFail = actionCreater(DELETE_MEMBER_FAIL);

//DisciplineUpdate

export const DISCIPLINE_UPDATE = 'DISCIPLINE_UPDATE';
export const DisciplineUpdate = actionCreater(DISCIPLINE_UPDATE);

export const DISCIPLINE_UPDATE_SUCCES = 'DISCIPLINE_UPDATE_SUCCES';
export const DisciplineUpdateSucces = actionCreater(DISCIPLINE_UPDATE_SUCCES);

export const DISCIPLINE_UPDATE_FAIL = 'DISCIPLINE_UPDATE_FAIL';
export const DisciplineUpdateFail = actionCreater(DISCIPLINE_UPDATE_FAIL);

//FilesUpload

export const FILES_UPLOAD = 'FILES_UPLOAD';
export const filesUpload = actionCreater(FILES_UPLOAD);

export const FILES_UPLOAD_SUCCESS = 'FILES_UPLOAD_SUCCESS';
export const filesUploadSuccess = actionCreater(FILES_UPLOAD_SUCCESS);

export const FILE_DELETE = 'FILE_DELETE';
export const deleteFile = actionCreater(FILE_DELETE);

export const FILES_UPLOAD_FAIL = 'FILES_UPLOAD_FAIL';
export const filesUploadFail = actionCreater(FILES_UPLOAD_FAIL);

export const FILES_UPLOAD_CLEAN = 'FILES_UPLOAD_CLEAN';
export const filesUploadClean = actionCreater(FILES_UPLOAD_CLEAN);

//Link User
export const UPDATE_LINK_USER = 'UPDATE_LINK_USER';
export const updateLinkUser = actionCreater(UPDATE_LINK_USER);

export const UPDATE_LINK_USER_SUCCESS = 'UPDATE_LINK_USER_SUCCESS';
export const updateLinkUserSuccess = actionCreater(UPDATE_LINK_USER_SUCCESS);

export const UPDATE_UNLINK_USER = 'UPDATE_UNLINK_USER';
export const updateUnlinkUser = actionCreater(UPDATE_UNLINK_USER);

export const UPDATE_UNLINK_USER_SUCCESS = 'UPDATE_UNLINK_USER_SUCCESS';
export const updateUnlinkUserSuccess = actionCreater(UPDATE_UNLINK_USER_SUCCESS);

export const FILE_UPLOAD_OVER_SIZE = 'FILE_UPLOAD_OVER_SIZE';
export const fileUploadOverSize = actionCreater(FILE_UPLOAD_OVER_SIZE);

export const SET_THEME = 'SET_THEME';
export const setTheme = actionCreater(SET_THEME);

export const SET_ONLY_AUTH = 'SET_ONLY_AUTH';
export const setOnlyAuth = actionCreater(SET_ONLY_AUTH);

export const SET_KPI_FRAME = 'SET_KPI_FRAME';
export const setKpiFrame = actionCreater(SET_KPI_FRAME);

export const SET_IS_INIT = 'SET_IS_INIT';
export const setIsInit = actionCreater(SET_IS_INIT);
