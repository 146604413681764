import React, { useEffect } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../../models/global';
import { getGroupingConstants } from './GroupingConstants';
import { SystemButton } from '../../../../controls/ButtonComponents';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { LinkedItemsShowType, LinkedType } from '../../RelatedItemsTabConstants';
import cn from 'classnames';
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import PCDCard = PCDApi.PCDCard;
import { PCDApi } from '../../../../../service/Api/pcd/types';

import './RelatedItemsGroupingSelectStyles.scss';

interface IProps {
  currentCard: 'Nf' | 'Deliverable' | 'Submittal';
  relatedTabType: RelatedItemsTabsType;
  part?: LinkedType;
  selectedShowType?: LinkedItemsShowType;
  selectedGrouping: DropdownItemModel[];
  handleAddGroupingValue: (gropingValue: DropdownItemModel[], type: LinkedType) => void;
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
}

const RelatedItemsGroupingSelect: React.FC<IProps> = ({
  currentCard,
  relatedTabType,
  part,
  selectedShowType,
  selectedGrouping,
  handleAddGroupingValue,
  selectedRequest,
}) => {
  const themeClass = useGetThemeClass('b-RelatedItemsGroupingSelect');

  const { isMobile } = GetResolution();

  useEffect(() => {
    handleAddGroupingValue([], 'predecessor');
    handleAddGroupingValue([], 'successor');
  }, [relatedTabType, selectedShowType]);

  const menuItems = getGroupingConstants({ currentCard, relatedTabType, part, selectedShowType, selectedRequest });

  return (
    <div className={cn(`${themeClass}`, { '-active': !!selectedGrouping?.length })}>
      <Select<DropdownItemModel, null, true>
        isMulti
        menuItems={menuItems}
        selectHandler={selected => handleAddGroupingValue(selected, part)}
        selected={selectedGrouping}
        menuItemSize={'md'}
        renderCustomBasis={() => <SystemButton type={'group'} size={'md'} variant={'transparent'} isMobile={isMobile} />}
      />
    </div>
  );
};

export default RelatedItemsGroupingSelect;
