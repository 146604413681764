import React, { FC } from 'react';
import CustomTooltip from '../../../Tooltip/Tooltip';
import cn from 'classnames';
import { ImpactModel } from '../../../ImpactSelect/ImpactSelect';
import { ChipsDiscipline } from '../../../Chips';
import { LocationCollectionModel, RequestBuildingModel, SimpleTitleModel, TagModel } from '../../../../../models';
import { NLDeliverablesModel, NLSubmittalsModel, NLUfcModel } from '../../../../../store/needlist/needListModels';

interface IProps {
  data:
    | RequestBuildingModel[]
    | LocationCollectionModel[]
    | SimpleTitleModel[]
    | TagModel[]
    | NLDeliverablesModel[]
    | NLUfcModel[]
    | NLSubmittalsModel[]
    | ImpactModel[];
  elementRef?: any;
  themeClass?: string;
  isFullWidth: boolean;
}

const ImpactsList: FC<IProps> = ({ data, elementRef, themeClass, isFullWidth }) => {
  return (
    <div className={cn(`${themeClass}_container_locationsBlock`, { ['-isFullWidth']: isFullWidth })}>
      {data.map(item => {
        return (
          <div className={`Badge`} key={`Badge-moreLocation-1-${item.alias}`}>
            <CustomTooltip placement={'top'} key={`moreLocation-1-${item.alias}`} title={item.title} style={{ width: '100%' }}>
              <ChipsDiscipline chipsRef={elementRef} type={'filled'} color={'gray'} value={item.title} />
            </CustomTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default ImpactsList;
