import { PhasePlanApi } from '../../service/Api/Api';
import { settingsSlice } from '../globalSettings/settingsSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetSelectDataResponse } from './phasePlanReducer.model';
import {
  BuildingSaveFormApi,
  CreatePhasePlanFieldPayload,
  CreateUpdateBuildingResponse,
  DeletePhasePlanFieldPayloadModel,
  DeletePhasePlanFieldResponseModel,
  GetPhasePlanPayloadModel,
  GetPhasePlanResponseModel,
  GetProjectById,
  PermissionModel,
  PhasePlanFieldResponseModel,
  PhasePlanUpdateModel,
  ProjectCreatePayloadModel,
  ProjectCreateResponseModel,
  ProjectUpdatePayloadModel,
  SavePhasePlanVersionPayloadModel,
  ScheduleModel,
  SetDriverTasksPayloadModel,
  SetDriverTasksResponseModel,
  UpdateBuildingLocationsPayloadModel,
  UpdateBuildingLocationsResponse,
  UpdateGMPPayloadModel,
  UpdateGMPResponseModel,
} from '../../models';
import { ErrorResponseModel } from '../../models/Api/response.model';
import { userSlice } from '../user/userSlice';
import { errorsSlice } from '../errors/errorsSlice';
import { getProjectDetails } from '../project/projectLogic';

const { filesUploadClean } = settingsSlice.actions;
const { addedNewProject, setActiveProject } = userSlice.actions;
const { setShowErrorData } = errorsSlice.actions;

export const getPhasePlanSelectData = createAsyncThunk<GetSelectDataResponse>(
  'phasePlanReducer/getPhasePlanSelectData',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.getSelectData();

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createProject = createAsyncThunk<
  ProjectCreateResponseModel,
  ProjectCreatePayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('phasePlanReducer/createProject', async (payload, { dispatch, rejectWithValue }) => {
  const { formData, urlProject, callback } = payload;

  try {
    const response = await PhasePlanApi.createProject(formData);

    // TODO change to thunk
    dispatch(addedNewProject(response));
    dispatch(setActiveProject(response.data.id));

    if (urlProject[0] === 'no-projects-yet') {
      callback && callback(response);
    }

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const updateProject = createAsyncThunk<
  ProjectCreateResponseModel,
  ProjectUpdatePayloadModel,
  {
    rejectValue: ErrorResponseModel;
  }
>('phasePlanReducer/updateProject', async (payload, { dispatch, rejectWithValue }) => {
  const { id, formData } = payload;

  try {
    const response = await PhasePlanApi.updateProject(id, formData);

    dispatch(filesUploadClean({ type: 'comment' }));

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getProjectById = createAsyncThunk<GetProjectById, number>(
  'phasePlanReducer/getProjectById',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.getProjectData(payload);

      if (!response || response.status === false || response.status === 400) {
        throw response;
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createBuilding = createAsyncThunk<
  CreateUpdateBuildingResponse,
  BuildingSaveFormApi,
  {
    rejectValue: ErrorResponseModel;
  }
>('phasePlanReducer/createBuilding', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await PhasePlanApi.createBuilding(payload);

    dispatch(getProjectDetails(payload.project_id));

    return response;
  } catch (error) {
    error.buildingId = payload.id;
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const updateBuilding = createAsyncThunk<
  CreateUpdateBuildingResponse,
  BuildingSaveFormApi,
  {
    rejectValue: ErrorResponseModel;
  }
>('phasePlanReducer/updateBuilding', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await PhasePlanApi.updateBuilding(payload);

    dispatch(getProjectDetails(payload.project_id));

    return response;
  } catch (error) {
    error.buildingId = payload.id;
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const deleteBuilding = createAsyncThunk<
  null,
  { buildingId: number; projectId: number },
  {
    rejectValue: ErrorResponseModel;
  }
>('phasePlanReducer/deleteBuilding', async ({ buildingId, projectId }, { dispatch, rejectWithValue }) => {
  try {
    const response = await PhasePlanApi.deleteBuilding(buildingId);

    dispatch(getProjectDetails(projectId));

    return response;
  } catch (error) {
    error.buildingId = buildingId;
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const updateBuildingLocation = createAsyncThunk<UpdateBuildingLocationsResponse, UpdateBuildingLocationsPayloadModel>(
  'phasePlanReducer/updateBuildingLocation',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.updateBuildingLocation(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPhasePlan = createAsyncThunk<GetPhasePlanResponseModel, GetPhasePlanPayloadModel>(
  'phasePlanReducer/getPhasePlan',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId } = payload;
    try {
      const response = await PhasePlanApi.getPhasePlan(projectId);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updatePhasePlan = createAsyncThunk<GetPhasePlanResponseModel, PhasePlanUpdateModel>(
  'phasePlanReducer/updatePhasePlan',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.updatePhasePlan(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const savePhasePlanVersion = createAsyncThunk<GetPhasePlanResponseModel, SavePhasePlanVersionPayloadModel>(
  'phasePlanReducer/savePhasePlanVersion',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.savePhasePlanVersion(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createPhasePlanField = createAsyncThunk<
  PhasePlanFieldResponseModel<ScheduleModel | PermissionModel>,
  CreatePhasePlanFieldPayload
>('phasePlanReducer/createPhasePlanField', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response: PhasePlanFieldResponseModel<ScheduleModel | PermissionModel> = await PhasePlanApi.createPhasePlanField(payload);

    response.table = payload.table;

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const updatePhasePlanField = createAsyncThunk<
  PhasePlanFieldResponseModel<ScheduleModel | PermissionModel>,
  ScheduleModel | PermissionModel
>('phasePlanReducer/updatePhasePlanField', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response: PhasePlanFieldResponseModel<ScheduleModel | PermissionModel> = await PhasePlanApi.updatePhasePlanField(payload);

    return { ...response, table: payload.table };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const deletePhasePlanField = createAsyncThunk<DeletePhasePlanFieldResponseModel, DeletePhasePlanFieldPayloadModel>(
  'phasePlanReducer/deletePhasePlanField',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response: DeletePhasePlanFieldResponseModel = await PhasePlanApi.deletePhasePlanField(payload);

      response.table = payload.table;

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateGMP = createAsyncThunk<UpdateGMPResponseModel, UpdateGMPPayloadModel>(
  'phasePlanReducer/updateGMP',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.updateGMP(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPrevPhasePlan = createAsyncThunk<GetPhasePlanResponseModel, number>(
  'phasePlanReducer/getPrevPhasePlan',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.getPrevPhasePlan(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
export const setDriverTasks = createAsyncThunk<SetDriverTasksResponseModel, SetDriverTasksPayloadModel>(
  'phasePlanReducer/setDriverTasks',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await PhasePlanApi.setDriverTasks({ items: payload });

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
