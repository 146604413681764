import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from './components/pages/ErrorPage/ErrorBoundary';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProviderStyles } from './components/common/SnackBarSockets/SnackBarSockets';
import Router from './navigation/Router';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/configure/configureStore';
import App from './App';

import './assets/scss/main.scss';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProviderStyles>
        <BrowserRouter>
          <App>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </App>
        </BrowserRouter>
      </SnackbarProviderStyles>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
