import filterApi from '../../service/Api/filterApi';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetPageFiltersFiltersPayload,
  GetPcdFiltersPayload,
  IFilterSetRequest,
  IFilterUpdateRequest,
  IUserFilter,
  PageFiltersResponse,
  IDeleteUserFilters,
} from './filterReducer.model';
import { KeyValueModel } from '../../models/key-value.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

export const getUserFilters = createAsyncThunk<IUserFilter, string | null>(
  'filterReducer/getUserFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const pageName = payload ? payload : 'needlist';
      if (!projectId) {
        return;
      }
      const response = await filterApi.getFilter(projectId, pageName);
      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setUserFilters = createAsyncThunk<IUserFilter, IFilterSetRequest>(
  'filterReducer/setUserFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const pageName = payload?.pageName ? payload?.pageName : 'needlist';
      if (!projectId) {
        return;
      }
      const formData = payload;
      delete formData['pageName'];
      formData.project_id = projectId;

      const response = await filterApi.setFilter(formData, pageName);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteUserFilters = createAsyncThunk<IUserFilter, IDeleteUserFilters>(
  'filterReducer/deleteUserFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const id = payload.id;
      const pageName = payload?.pageName ? payload?.pageName : 'needlist';

      const response = await filterApi.deleteFilter(id, pageName);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateUserFilters = createAsyncThunk<IUserFilter, IFilterUpdateRequest>(
  'filterReducer/updateUserFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id } = payload;
      const bodyParams = payload;
      const pageName = payload?.pageName ? payload?.pageName : 'needlist';

      delete bodyParams['pageName'];

      const response = await filterApi.updateFilter(id, bodyParams, pageName);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPCDFilters = createAsyncThunk<KeyValueModel<any[]>, GetPcdFiltersPayload>(
  'filterReducer/getPCDFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { project_id, building_id, fromModule } = payload;

      let response;

      if (fromModule === 'sandbox') {
        response = await filterApi.getDeliverableSandboxFilters(project_id);
      } else {
        response = await filterApi.getPCDFilters(project_id, { building_id });
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getPageFilters = createAsyncThunk<PageFiltersResponse, GetPageFiltersFiltersPayload>(
  'filterReducer/getPageFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { filter_name } = payload;

      const response = await filterApi.getPageFilters(payload);

      return { filters: response.data, filterName: filter_name };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
