import React, { Component } from 'react';
import './FilterMiniListStyles.scss';
import { list_columns, switchColumns } from '../NeedsList/NeedListConstants/NeedListConstants';
import ShowMoreMarkup from '../../controls/ShowMore/ShowMoreMarkup';
import { log } from 'util';
import moment from 'moment';

interface IProps {
  selectedFilters: any;
  onChange: (selectedFilters: any) => void;
  isShowFilteredStatus: boolean;
  isWhiteBoard: boolean;
  displayFilters: boolean;
  hasNotSavedFilters: boolean;
}

export class FilterMiniList extends Component<IProps, any> {
  public filterTarget = React.createRef();

  state = {
    filterItems: [],
    searchItems: [],
    filters: {} as any,
    filterHoveredItem: null,
    searchHoveredItem: null,
    isShowFilteredStatus: false,
    filteredHoveredItemKey: null,
  };

  componentDidMount() {
    const { displayFilters, selectedFilters, hasNotSavedFilters } = this.props;
    if (!hasNotSavedFilters && displayFilters) {
      this.initFilters(selectedFilters);
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    const { displayFilters, hasNotSavedFilters, selectedFilters } = this.props;
    if (
      (hasNotSavedFilters !== prevProps.hasNotSavedFilters && !hasNotSavedFilters && displayFilters) ||
      (displayFilters !== prevProps.displayFilters && !displayFilters)
    ) {
      this.initFilters(selectedFilters);
    }
    // if (prevProps.selectedFilters.filterItems !== selectedFilters.filterItems && prevProps.selectedFilters.searchItems !== selectedFilters.searchItems && !selectedFilters.searchItems?.length && selectedFilters.filterItems?.length) {
    //   debugger
    //   this.props.resetFilters();
    // }
  }

  removeItemFilter = (filterKey: string, index: number, dueDateField: any) => {
    const { filters } = this.state;
    if (this.props.isWhiteBoard) {
      this.props.onChange(filterKey);
      return;
    }
    if (filterKey === 'created_at') {
      filters.filters[filterKey] = [];
    } else if (filterKey === 'due_date') {
      if (dueDateField) {
        filters.filters.due_date[dueDateField] = [];
      }
    } else {
      filters.filters[filterKey].splice(index, 1);
    }
    if (!filters.filters[filterKey].length && filterKey !== 'due_date') {
      delete filters.filters[filterKey];
    } else if (filterKey === 'due_date' && !filters.filters?.due_date?.date.length && !filters.filters?.due_date?.filter?.length) {
      delete filters.filters.due_date;
    }
    if (!Object.values(filters.filters).length) {
      delete filters.filters;
    }
    this.initFilters(filters);
    this.props.onChange(filters);
  };

  removeItemSearch = (searchKey: string) => {
    const { filters } = this.state;
    delete filters.searches[searchKey];
    if (!Object.values(filters.searches).length) {
      delete filters.searches;
    }
    this.initFilters(filters);
    this.props.onChange(filters);
  };

  initFilters = selectedFilters => {
    this.setState({
      filterItems: Object.keys(selectedFilters?.filters || {}),
      searchItems: Object.keys(selectedFilters?.searches || {}),
      filters: selectedFilters,
    });
  };

  filterRemoveHover = (key, item) => {
    this.setState({
      filterHoveredItem: item.id,
      filteredHoveredItemKey: key,
    });
  };
  filterRemoveLeave = () => {
    this.setState({
      filterHoveredItem: null,
      filteredHoveredItemKey: null,
    });
  };
  searchRemoveHover = item => {
    this.setState({
      searchHoveredItem: item,
    });
  };
  searchRemoveLeave = () => {
    this.setState({
      searchHoveredItem: null,
    });
  };

  render() {
    const { filterItems, searchItems, filters, filterHoveredItem, searchHoveredItem, filteredHoveredItemKey } = this.state;
    const { selectedFilters, isShowFilteredStatus, hasNotSavedFilters, displayFilters } = this.props;

    const isExistFilters = Object.values(selectedFilters).length;
    return (
      <div className={'FilterMiniList'} ref={this.filterTarget}>
        {/*{Object.values(filters?.filters || {}).length || Object.values(filters?.searches || {}).length ?*/}
        <ShowMoreMarkup
          lines={4}
          refComponent={this.filterTarget}
          title={'All selected filters'}
          styles={{ transform: 'translate(32px, 17px)' }}
        >
          <div className={`requestFilters`}>
            {isShowFilteredStatus ? (
              <span className={'filterTitle'}>{isExistFilters ? 'Filters applied:' : 'No filters applied'}</span>
            ) : null}
            {filterItems?.map((filterKey, filterIndex) => {
              // if (!filters.filters[filterKey].length) {
              //   return <></>;
              // }
              const findFieldValue = list_columns.find(col => col.filterField == filterKey);

              return (
                <>
                  <div className={'filteredItem filteredField'}>{`${
                    findFieldValue?.title || filters.filters[filterKey][0]?.title || filterKey
                  }: `}</div>
                  {filterKey === 'due_date' ? (
                    <>
                      {filters?.filters?.due_date?.date &&
                        Object.values(filters?.filters.due_date.date).map((item: any, index) => {
                          const title = moment(item).format('MM/DD/YYYY');
                          return (
                            <div
                              key={`miniFilters-${index}`}
                              className={`filteredItem ${
                                filterHoveredItem === item.id && filteredHoveredItemKey === filterKey ? 'filteredItemHovered' : ''
                              }`}
                            >
                              <span>{`${title}`}</span>
                              <div
                                className={'filteredRemove'}
                                onClick={() => this.removeItemFilter(filterKey, index, 'date')}
                                onMouseEnter={() => this.filterRemoveHover(filterKey, item)}
                                onMouseLeave={() => this.filterRemoveLeave()}
                              >
                                <img src={require('../../../assets/icons/cross.svg')} />
                              </div>
                              {/*<span>{index !== filters.filters[filterKey].length - 1 ? ',' : ''}</span>*/}
                            </div>
                          );
                        })}
                      {filters?.filters?.due_date?.filter &&
                        Object.values(filters?.filters?.due_date?.filter).map((item: any, index) => {
                          const title = item?.title || item?.value || item;
                          return (
                            <div
                              key={`miniFilters-${index}`}
                              className={`filteredItem ${
                                filterHoveredItem === item.id && filteredHoveredItemKey === filterKey ? 'filteredItemHovered' : ''
                              }`}
                            >
                              <span>{`${title}`}</span>
                              <div
                                className={'filteredRemove'}
                                onClick={() => this.removeItemFilter(filterKey, index, 'filter')}
                                onMouseEnter={() => this.filterRemoveHover(filterKey, item)}
                                onMouseLeave={() => this.filterRemoveLeave()}
                              >
                                <img src={require('../../../assets/icons/cross.svg')} />
                              </div>
                              {/*<span>{index !== filters.filters[filterKey].length - 1 ? ',' : ''}</span>*/}
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    filters?.filters[filterKey].map((item: any, index) => {
                      let title = item?.title || item?.value || item;
                      if (findFieldValue?.value === switchColumns.CREATED || findFieldValue?.value === switchColumns.DUE_DATE) {
                        title = moment(item).format('MM/DD/YYYY');
                      }
                      return (
                        <div
                          key={`miniFilters-${index}`}
                          className={`filteredItem ${
                            filterHoveredItem === item.id && filteredHoveredItemKey === filterKey ? 'filteredItemHovered' : ''
                          }`}
                        >
                          <span>{`${title}`}</span>
                          <div
                            className={'filteredRemove'}
                            onClick={() => this.removeItemFilter(filterKey, index, null)}
                            onMouseEnter={() => this.filterRemoveHover(filterKey, item)}
                            onMouseLeave={() => this.filterRemoveLeave()}
                          >
                            <img src={require('../../../assets/icons/cross.svg')} />
                          </div>
                          {/*<span>{index !== filters.filters[filterKey].length - 1 ? ',' : ''}</span>*/}
                        </div>
                      );
                    })
                  )}
                </>
              );
            })}
            {searchItems?.map((searchKey: string, index) => {
              const findFieldValue = list_columns.find(col => col.searchField == searchKey);
              if (!filters?.searches?.[searchKey] || searchKey === 'created_at') {
                return <></>;
              }
              return (
                <>
                  <div className={'filteredItem filteredField'}>{`${findFieldValue?.title}: `}</div>
                  <div className={`filteredItem ${searchHoveredItem == filters?.searches[searchKey] ? 'filteredItemHovered' : ''}`}>
                    <span>{`${filters?.searches[searchKey]}`}</span>
                    <div
                      className={'filteredRemove'}
                      onClick={() => this.removeItemSearch(searchKey)}
                      onMouseEnter={() => this.searchRemoveHover(filters?.searches[searchKey])}
                      onMouseLeave={() => this.searchRemoveLeave()}
                    >
                      <img src={require('../../../assets/icons/cross.svg')} />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </ShowMoreMarkup>
        {/*: null}*/}
      </div>
    );
  }
}
