import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { PostTypes, usePostMessage } from '../../../hooks/usePostMessage';
import { getToken, isLoggedIn } from '../../../service/auth';
import { logout } from '../../../store/user/userThunk';
import FlatLoader from '../../controls/FlatLoader/FlatLoader';
import useRouter from '../../../hooks/useRouter/useRouter';

const AuthService = ({ onlyAuth, logout, userData, kpiFrame }) => {
  const { sendMessage, subscribe } = usePostMessage();
  const { navigate } = useRouter();

  const onLogout = useCallback(() => {
    localStorage.removeItem('token');
    logout({
      callback: () => {
        navigate('/');
      },
    });
  }, []);

  useEffect(() => {
    console.log('onlyAuth', onlyAuth, kpiFrame);
    if (onlyAuth === 'login') {
      if (isLoggedIn()) {
        sendMessage({
          type: PostTypes.ON_LOGIN,
          data: {
            token: getToken(),
            user_id: +localStorage.getItem('user_id'),
          },
        });

        const selectedClientId = userData.selected_client_id;

        if (selectedClientId) {
          sendMessage({
            type: PostTypes.ON_COMPANY_SELECT,
            data: {
              selected_client_id: selectedClientId as any,
            },
          });
        }
      } else {
        const error = new Error('Cannot correct authentication');
        throw error;
      }
    }
    // if (kpiFrame === 'company') {
    //   if (isLoggedIn()) {
    //     sendMessage({
    //       type: PostTypes.ON_COMPANY_SELECT,
    //       data: {
    //         selected_client_id: +sessionStorage.getItem('client_id'),
    //       },
    //     });
    //   } else {
    //     const error = new Error('Cannot correct authentication');
    //     throw error;
    //   }
    // }
  }, [onlyAuth, sendMessage, kpiFrame]);

  useEffect(() => {
    if (onlyAuth === 'logout') {
      subscribe({ type: PostTypes.ON_LOGOUT, callback: onLogout });
    }
  }, [onLogout, subscribe]);

  if (onlyAuth === 'login') {
    return <section className="authService">{/*<FlatLoader showLineLoader={true} loading={onlyAuth === 'login'} />*/}</section>;
  }

  return null;
};

const mapStateToProps = ({ settingsReducer, userReducer }) => ({
  onlyAuth: settingsReducer.onlyAuth,
  userInfo: userReducer.userInfo,
  userData: userReducer,
  kpiFrame: settingsReducer.kpiFrame,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AuthService);
