import React, { FC } from 'react';
import { ISubmittal, SubmittalUserModel } from '../../../../../models/submittals/submittals.model';
import { SubmittalCardTabNamesModel } from '../../constants/submittalTabs';
import cn from 'classnames';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import FileUploader from '../../../../controls/FileUploader/FileUploader';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { FileModel, RequestUserModel } from '../../../../../models';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import HistoryAction from '../../../../controls/HistoryAction/HistoryAction';

import './GeneralTabStyles.scss';

interface IProps {
  selectedRequest: ISubmittal;
  isAccess: boolean;
  isDragRequestCard: boolean;
  activeTab: SubmittalCardTabNamesModel;
  currentDescription: string;
  setDescription: (descriptionValue: string, delta: any, source: any, editor: any) => void;
  handleFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  isHiddenFields: boolean;
  files: FileModel[] | FileList | any;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  animationLoading: boolean;
  loadingFilesName: string[];
  handleDownloadAll: (e: any) => void;
  isCreateSubmittal: boolean;
  validateCard: (isSubmit: boolean) => any;
  isDeactivated: boolean;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, user: RequestUserModel | SubmittalUserModel) => void;
  handleCloseAuthorUser: () => void;
  cardErrors: any;
  isPreviousRevisionDeactivated: boolean;
  moreLimitFiles: FileModel[] | FileList | any;
  handleMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
}

const GeneralTab: FC<IProps> = ({
  activeTab,
  selectedRequest,
  isDragRequestCard,
  currentDescription,
  setDescription,
  isAccess,
  handleFile,
  isHiddenFields,
  files,
  delSavedFile,
  animationLoading,
  loadingFilesName,
  handleDownloadAll,
  isCreateSubmittal,
  validateCard,
  isDeactivated,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
  cardErrors,
  isPreviousRevisionDeactivated,
  moreLimitFiles,
  handleMoreLimitFiles,
}) => {
  const themeClass = useGetThemeClass('b-cardSubmittal-generalTab');
  const { isMobile } = GetResolution();

  const { isCardFirstLoading } = useAppSelector(state => state.submittalSlice);

  const handleChange = (value, delta, source, editor) => {
    setDescription && setDescription(value, delta, source, editor);
  };

  const handleChangeFile = (e: any, isDrag?: boolean, isRestrict?: boolean) => {
    handleFile(e, isDrag, isRestrict);
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-sidePeekView']: isDragRequestCard,
      })}
      style={activeTab !== 'general' ? { display: 'none' } : {}}
    >
      <div className={`${themeClass}_container`}>
        <div className={cn(`${themeClass}_contentBlock`, {})}>
          <div className={`${themeClass}_mainBlock`}>
            {isCardFirstLoading && !isCreateSubmittal ? (
              <>
                <CustomSkeleton width={'50%'} height={323} />
                <CustomSkeleton width={'50%'} height={323} />
              </>
            ) : (
              <>
                <div className={`${themeClass}_fileUploader`}>
                  <FileUploader
                    isCard={true}
                    handleChangeFile={handleChangeFile}
                    isCardSidePeek={isDragRequestCard}
                    isHiddenFields={isHiddenFields}
                    collectFinalFiles={files || []}
                    files={files || []}
                    selectedRequest={selectedRequest}
                    source={'Sub'}
                    cardType={'submittal'}
                    isEditable={true}
                    isEditMode={true}
                    delSavedFile={delSavedFile}
                    fileLoading={animationLoading}
                    loadingFilesName={loadingFilesName}
                    handleDownloadAll={handleDownloadAll}
                    isSubmittalCard
                    validateCard={validateCard}
                    isCreateCard={isCreateSubmittal}
                    isDisableFileList={isDeactivated || isPreviousRevisionDeactivated}
                    moreLimitFiles={moreLimitFiles || []}
                    handleMoreLimitFiles={handleMoreLimitFiles}
                    customFileUploaderClassName={`${themeClass}_customGeneralTabFileUploader${isDragRequestCard ? '_sidePeekView' : ''}`}
                  />
                </div>
                <TextQuillEditor
                  fieldId={'generalTab'}
                  showToolbar={!isMobile}
                  value={currentDescription}
                  handleChange={handleChange}
                  placeholder={'Request description'}
                  disabled={!isAccess || isDeactivated || isPreviousRevisionDeactivated}
                  customContainerClassName={cn(`${themeClass}_description`, {
                    ['-isHiddenFields']: !isMobile && isHiddenFields,
                  })}
                  error={cardErrors?.description || null}
                />
              </>
            )}
          </div>
          {(!!selectedRequest?.submitted_by || !!selectedRequest?.updated_by) && (
            <div className={`${themeClass}_infoBlock`}>
              {!!selectedRequest?.submitted_by && (
                <div className={`${themeClass}_sentBy`}>
                  <HistoryAction
                    type={''}
                    date={selectedRequest.submitted_date}
                    firstName={selectedRequest.submitted_by.profile.first_name}
                    lastName={selectedRequest.submitted_by.profile.last_name}
                    handleOpenAuthorUser={handleOpenAuthorUser}
                    handleCloseAuthorUser={handleCloseAuthorUser}
                    userProfile={selectedRequest.submitted_by}
                    isShowTime
                    infoMessage={'Submitted by'}
                  />
                </div>
              )}
              {!!selectedRequest?.updated_by && (
                <div className={`${themeClass}_sentBy`}>
                  <HistoryAction
                    type={'updated'}
                    date={selectedRequest.updated_date}
                    firstName={selectedRequest.updated_by.profile.first_name}
                    lastName={selectedRequest.updated_by.profile.last_name}
                    handleOpenAuthorUser={handleOpenAuthorUser}
                    handleCloseAuthorUser={handleCloseAuthorUser}
                    userProfile={selectedRequest.updated_by}
                    isShowTime
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default GeneralTab;
