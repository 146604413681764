import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NeedListData,
  NeedListState,
  SetBulkEditDataAction,
  SetEditingRowsActionPayload,
  SetIsBulkEditModeActionPayload,
} from './needListModels';
import { getAvailableBulkEditFields } from './needListThunk';
import { KeyValueNumberModel } from '../../models/key-value.model';

const initialState: NeedListState = {
  isBulkEditMode: false,
  editingRowIds: [],
  availableEditableFields: null,
  bulkEditData: null,
  changedFields: null,
  isThumbnailView: false,
  bulkEditModeLoading: false,
};
export const needsListSlice = createSlice({
  initialState,
  name: 'needsListReducer',
  reducers: {
    setIsBulkEditModeAction(state, { payload }: PayloadAction<SetIsBulkEditModeActionPayload>) {
      if (!payload.isBulkEditMode) {
        state.editingRowIds = [];
        state.availableEditableFields = null;
        state.isBulkEditMode = payload.isBulkEditMode;
      } else {
        state.isBulkEditMode = payload.isBulkEditMode;
      }
    },
    setEditingRowsAction(state, { payload }: PayloadAction<SetEditingRowsActionPayload>) {
      if (!payload.editingRowIds.length) {
        state.availableEditableFields = null;
        state.bulkEditData = null;
        state.changedFields = null;
        state.editingRowIds = [];
      } else {
        state.editingRowIds = payload.editingRowIds;
      }
    },
    setBulkEditDataAction(state, { payload }: PayloadAction<SetBulkEditDataAction>) {
      const newBulkEditData = state.bulkEditData ? { ...state.bulkEditData, ...payload.bulkEditData } : payload.bulkEditData;
      const newChangedFields = state.changedFields ? { ...state.changedFields, ...payload.changedFields } : payload.changedFields;

      state.bulkEditData = newBulkEditData;
      state.changedFields = newChangedFields;
    },
    setThumbnailViewAction(state, { payload }: PayloadAction<boolean>) {
      state.isThumbnailView = payload;
    },
    bulkEditLoading(state) {
      state.bulkEditModeLoading = true;
    },
    bulkEditSuccess(state) {
      state.bulkEditModeLoading = false;
      state.editingRowIds = [];
      state.availableEditableFields = null;
      state.bulkEditData = null;
      state.changedFields = null;
    },
  },
  extraReducers: builder =>
    builder
      /**
       * getAvailableBulkEditFields
       */
      .addCase(getAvailableBulkEditFields.pending, state => {
        return state;
      })
      .addCase(getAvailableBulkEditFields.fulfilled, (state, { payload }: PayloadAction<KeyValueNumberModel<string>>) => {
        state.availableEditableFields = payload;
      })
      .addCase(getAvailableBulkEditFields.rejected, state => {
        return state;
      }),
});

export default needsListSlice.reducer;
