import React from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../TokenIcon/TokenIcon';

import './ChipsIntegrationStyles.scss';

const ChipsIntegration: React.FC = () => {
  const themeClass = useGetThemeClass('b-chipsIntegration');
  return (
    <div className={`${themeClass}`}>
      <TokenIcon iconName={'integration'} size={12} />
    </div>
  );
};

export default React.memo(ChipsIntegration);
