import React from 'react';
import MarkerBlock from './MarkerBlock';
import CustomTooltip from '../../Tooltip/Tooltip';
import SystemButton from '../../ButtonComponents/SystemButton/SystemButton';
import cn from 'classnames';

interface IProps {
  themeClass: string;
  openedFrom: string;
  status: string;
  isHover: boolean;
  isDeactivated: boolean | number;
  is1100Resolution: boolean;
  isAccessToEditDeactivation: boolean;
  marker: 'new' | 'upd';
  handleEndField: (e: Event) => void;
  isMobile: boolean;
  customClassName?: string;
}

const EndField = ({
  themeClass,
  openedFrom,
  status,
  isHover,
  isDeactivated,
  is1100Resolution,
  isAccessToEditDeactivation,
  marker,
  handleEndField,
  isMobile,
  customClassName,
}: IProps) => {
  return (
    <>
      {openedFrom !== 'report' ? (
        <>
          {marker && !is1100Resolution ? (
            <MarkerBlock marker={marker} is1100Resolution={is1100Resolution} themeClass={themeClass} />
          ) : (
            <div className={cn(`${themeClass}_endField`, { [`${customClassName}`]: customClassName })}>
              <div
                onClick={openedFrom !== 'report' ? handleEndField : null}
                className={`${themeClass}_endField_deactivation`}
                style={{ visibility: isHover ? '' : 'hidden' }}
              >
                {status !== 'drafted' && isDeactivated && isAccessToEditDeactivation && (
                  <CustomTooltip enterDelay={500} leaveDelay={0} title={'Activate'} placement={'bottom'}>
                    <div className={`${themeClass}_endField_deactivation_icon`}>
                      <SystemButton type={'activate'} variant={'transparent'} size={'sm'} isMobile={isMobile} />
                    </div>
                  </CustomTooltip>
                )}
                {status !== 'drafted' && !isDeactivated && isAccessToEditDeactivation && (
                  <CustomTooltip enterDelay={500} leaveDelay={0} title={'Deactivate'} placement={'bottom'}>
                    <div className={`${themeClass}_endField_deactivation_icon`}>
                      <SystemButton type={'deactivate'} variant={'transparent'} size={'sm'} isMobile={isMobile} />
                    </div>
                  </CustomTooltip>
                )}
                {status === 'drafted' && (
                  <CustomTooltip enterDelay={500} leaveDelay={0} title={'Delete'} placement={'bottom'}>
                    <div className={`${themeClass}_endField_deactivation_icon`}>
                      <SystemButton type={'delete'} variant={'transparent'} size={'sm'} isMobile={isMobile} />
                    </div>
                  </CustomTooltip>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={`${themeClass}_endField`}></div>
      )}
    </>
  );
};

export default EndField;
