import { IncorporationResponseModel, IIncorporationReducer, GlobalIncorporationResponse } from './incorporationReducer.model';
import Api from '../../service/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createIncorporation,
  getIncorporation,
  setGlobalIncorporation,
  setIncorporation,
  updateSubTaskStatus,
  updateTasks,
} from './incorporationThunk';

const initialState: IIncorporationReducer = {
  incorporationStatus: Api.initialStatus,
  incorporationSaveStatus: Api.initialStatus,
  incorporationList: [],
  globalIncorporationLoading: Api.initialStatus,
  isGlobalIncorporated: false,
};

export const incorporationSlice = createSlice({
  initialState,
  name: 'incorporationReducer',
  reducers: {
    clearIncorporation(state) {
      return initialState;
    },
    changeGlobalIncorporation(state, { payload }: PayloadAction<number>) {
      state.isGlobalIncorporated = !!payload;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getIncorporation
       */
      .addCase(getIncorporation.pending, state => {
        state.incorporationStatus = Api.requestStatus;
      })
      .addCase(getIncorporation.fulfilled, (state, { payload }: PayloadAction<IncorporationResponseModel>) => {
        state.incorporationStatus = Api.successStatus;
        state.incorporationList = payload.resource;
      })
      .addCase(getIncorporation.rejected, (state, { error }) => {
        state.incorporationStatus = Api.failStatus;
      })
      /**
       * setGlobalIncorporation
       */
      .addCase(setGlobalIncorporation.pending, state => {
        state.globalIncorporationLoading = Api.requestStatus;
        state.incorporationSaveStatus = Api.requestStatus;
      })
      .addCase(setGlobalIncorporation.fulfilled, (state, { payload }: PayloadAction<GlobalIncorporationResponse>) => {
        state.globalIncorporationLoading = Api.successStatus;
        state.incorporationSaveStatus = Api.successStatus;
        state.isGlobalIncorporated = !!payload.is_incorporated;
      })
      .addCase(setGlobalIncorporation.rejected, (state, { error }) => {
        state.globalIncorporationLoading = Api.failStatus;
        state.incorporationSaveStatus = Api.failStatus;
      })
      /**
       * createIncorporation
       */
      .addCase(createIncorporation.pending, state => {
        state.incorporationStatus = Api.requestStatus;
        state.incorporationSaveStatus = Api.requestStatus;
      })
      .addCase(createIncorporation.fulfilled, (state, { payload }: PayloadAction<IncorporationResponseModel>) => {
        state.incorporationStatus = Api.successStatus;
        state.incorporationSaveStatus = Api.successStatus;
        state.incorporationList = payload.resource;
      })
      .addCase(createIncorporation.rejected, (state, { error }) => {
        state.incorporationStatus = Api.failStatus;
        state.incorporationSaveStatus = Api.failStatus;
      })
      /**
       * updateTask
       */
      .addCase(updateTasks.pending, state => {
        state.incorporationStatus = Api.requestStatus;
        state.incorporationSaveStatus = Api.requestStatus;
      })
      .addCase(updateTasks.fulfilled, (state, { payload }: PayloadAction<IncorporationResponseModel>) => {
        state.incorporationStatus = Api.successStatus;
        state.incorporationSaveStatus = Api.successStatus;
        state.incorporationList = payload.resource;
      })
      .addCase(updateTasks.rejected, (state, { error }) => {
        state.incorporationStatus = Api.failStatus;
        state.incorporationSaveStatus = Api.failStatus;
      })
      /**
       * updateSubTaskStatus
       */
      .addCase(updateSubTaskStatus.pending, state => {
        state.incorporationStatus = Api.requestStatus;
        state.incorporationSaveStatus = Api.requestStatus;
      })
      .addCase(updateSubTaskStatus.fulfilled, (state, { payload }: PayloadAction<IncorporationResponseModel>) => {
        state.incorporationStatus = Api.successStatus;
        state.incorporationSaveStatus = Api.successStatus;
        state.incorporationList = payload.resource;
      })
      .addCase(updateSubTaskStatus.rejected, (state, { error }) => {
        state.incorporationStatus = Api.failStatus;
        state.incorporationSaveStatus = Api.failStatus;
      })
      /**
       * setIncorporation
       */
      .addCase(setIncorporation.pending, state => {
        state.incorporationStatus = Api.requestStatus;
        state.incorporationSaveStatus = Api.requestStatus;
      })
      .addCase(setIncorporation.fulfilled, (state, { payload }: PayloadAction<IncorporationResponseModel>) => {
        state.incorporationSaveStatus = Api.successStatus;
      })
      .addCase(setIncorporation.rejected, (state, { error }) => {
        state.incorporationStatus = Api.failStatus;
        state.incorporationSaveStatus = Api.failStatus;
      });
  },
});

export default incorporationSlice.reducer;
