import React, { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import DropdownBasis from '../../DropdownParts/DropdownBasis/DropdownBasis';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import DropdownSelectMenu from '../../DropdownPopovers/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownFooter, DropdownHeader, DropdownMenuItem } from '../../DropdownParts';
import moment from 'moment';
import _, { uniqBy } from 'lodash';
import ChipsStandardTag from '../../../Chips/ChipsStandardTag/ChipsStandardTag';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import { handleRightDateFormat } from '../../../../../helpers/commonHelpers';
import { useAppDispatch } from '../../../../../store/configure/configureStore';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';

const { setCommitmentAction } = requestSlice.actions;

import './SingleStyles.scss';

interface RadioGroupItem {
  value: string | number;
  title: string;
}

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface SelectProps<T, G, IsMultiValue extends boolean> {
  isMulti: IsMultiValue;
  isValuesRemovable?: boolean;
  isHeader?: boolean;
  isFooter?: boolean;
  customSelectClass?: string;
  menuItems: T[];
  groups?: G[];
  selectHandler: (selected: SelectedValue<T, IsMultiValue>, item?: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  customBasisContainerClass?: string;
  customBasisFieldClass?: string;
  basisPlaceholder?: string;
  renderBasisSelectedItem?: (selected: T) => ReactElement | ReactNode;
  renderCustomBasis?: (
    isActive: boolean,
    disabled: boolean,
    isLoading: boolean,
    selected: SelectedValue<T, IsMultiValue>,
  ) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  onChangeSearch?: (search: string) => void;
  customHeaderClass?: string;
  headerAdditionalText?: string;
  footerType?: 'divided-button' | 'radio-group' | 'warning';
  customFooterClass?: string;
  footerButtonClick?: (closeMenu: () => void) => void;
  footerButtonLoading?: boolean;
  footerButtonTitle?: string | ReactElement;
  footerIcon?: ReactElement;
  footerRadioGroup?: RadioGroupItem[];
  footerRadioClickHandler?: (value: string | number) => void;
  footerSelectedRadioValue?: string | number;
  footerWarningText?: string;
  menuItemSize: 'sm' | 'md';
  checkMenuItemDisabled?: (item: T) => boolean;
  disabled?: boolean;
  customDisabled?: boolean;
  menuItemTitleRenderer?: (item: T) => string | ReactNode;
  menuItemTooltipRenderer?: (item: T) => string | ReactNode;
  menuItemLeftElemRenderer?: (item: T) => ReactNode;
  menuItemLeftElemClick?: (item: T) => void;
  menuItemRightElemRenderer?: (item: T) => ReactNode;
  menuItemRightElemClick?: (item: T) => void;
  menuItemRightTextRenderer?: (item: T) => ReactNode;
  customMenuItemClass?: string;
  customMenuClass?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: MouseEvent) => void;
  onMenuClose?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  revertDropdownPositionToTop?: boolean;
  customOpening?: boolean;
  isOpenMenu?: boolean;
  isFullWidth?: boolean;
  isAlwaysPlaceholder?: boolean;
  handleOpenDDManageStatuses?: () => void;
  disableSelectAll?: boolean;
  alignDropdownPositionToLeft?: boolean;
  isHeaderNoMulti?: boolean;
  isExpandOnHover?: boolean;
  customClearAll?: () => void;
  customSelectAll?: () => void;
  isDisableMinWidth?: boolean;
  popOverId?: string;
  isMobile?: boolean;
  selectType?: string;
  disableRightElem?: boolean;
  customClassPaperName?: string;
  noSort?: boolean;
  toolTipClassName?: string;
  isCustomMulti?: boolean;
  onClickOutside?: () => void;
  isSelectMenuClose?: boolean;
  isDisabledFooterBtn?: boolean;
  footerButtonTooltip?: string;
  isActiveCheckBoxForDisabledItems?: boolean;
  isHideSearch?: boolean;
  onResetAll?: () => void;
  isSwitchAndButton?: boolean;
  isOnSwitchAndFilter?: boolean;
  handleSwitchAndFilter?: () => void;
  isHighResolution?: boolean;
  sortingValue?: string;
  isHighResolution?: boolean;
}

function Select<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  isMulti,
  isValuesRemovable,
  isHeader,
  isFooter,
  disableSelectAll,
  customSelectClass,
  menuItems,
  groups,
  selectHandler,
  selected,
  isLoading,
  customBasisContainerClass,
  customBasisFieldClass,
  basisPlaceholder,
  renderBasisSelectedItem,
  renderCustomBasis,
  basisMaxItems,
  error,
  basisTooltip,
  onChangeSearch,
  customHeaderClass,
  headerAdditionalText,
  footerType,
  customFooterClass,
  footerButtonClick,
  footerButtonLoading,
  footerButtonTitle,
  footerIcon,
  footerRadioGroup,
  footerRadioClickHandler,
  footerSelectedRadioValue,
  footerWarningText,
  menuItemSize,
  checkMenuItemDisabled,
  disabled,
  customDisabled,
  menuItemTitleRenderer,
  menuItemTooltipRenderer,
  menuItemLeftElemRenderer,
  menuItemLeftElemClick,
  menuItemRightElemRenderer,
  menuItemRightElemClick,
  customMenuItemClass,
  customMenuClass,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isOpenMenu,
  revertDropdownPositionToTop,
  customOpening,
  isFullWidth,
  isAlwaysPlaceholder,
  menuItemRightTextRenderer,
  handleOpenDDManageStatuses,
  alignDropdownPositionToLeft,
  isHeaderNoMulti,
  isExpandOnHover,
  customClearAll,
  customSelectAll,
  isDisableMinWidth,
  popOverId,
  isMobile,
  selectType,
  disableRightElem,
  customClassPaperName,
  noSort,
  toolTipClassName,
  isCustomMulti = false,
  onClickOutside,
  isSelectMenuClose,
  isDisabledFooterBtn,
  footerButtonTooltip,
  isActiveCheckBoxForDisabledItems,
  isHighResolution,
  isHideSearch,
  onResetAll,
  isSwitchAndButton,
  isOnSwitchAndFilter,
  handleSwitchAndFilter,
  sortingValue,
}: SelectProps<T, G, IsMultiValue>) {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-select');

  const selectBasisRef = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (customOpening) {
      openMenu && openMenu();
      dispatch(setCommitmentAction(''));
    }
  }, [customOpening]);

  useEffect(() => {
    if (isOpenMenu) {
      openMenu && openMenu();
      return;
    }
    if (isOpenMenu === false) {
      menuCloseHandler();
      return;
    }
  }, [isOpenMenu]);

  const formattedMenuItems = useMemo(() => {
    return menuItems?.map(item => {
      const title = handleRightDateFormat(item.title);

      return {
        value: item.id,
        ...item,
        title,
      };
    });
  }, [menuItems]);

  const formattedSelected = useMemo(() => {
    if (isMulti) {
      return (
        selected?.map(item => {
          const title = handleRightDateFormat(item.title);

          return {
            value: item.id,
            ...item,
            title,
          };
        }) || []
      );
    } else {
      return selected;
    }
  }, [selected]);

  const itemsToShow = useMemo(() => {
    if (formattedMenuItems && formattedMenuItems?.length) {
      let showingItems = [...formattedMenuItems];

      if (!onChangeSearch) {
        const formats = ['DD/MM/YYYY', 'YYYY-MM-DD'];
        const isDate = moment(formattedMenuItems[0].title, formats, true).isValid();
        const inputValue = _.escapeRegExp(searchValue)?.trim().toLowerCase();

        showingItems = formattedMenuItems.filter(item => {
          if (isDate) {
            return moment(item.title).format('MM/DD/YYYY').search(inputValue.replace(/-/gi, '/')) !== -1;
          } else {
            return item.title?.trim().toLowerCase().search(inputValue) !== -1;
          }
        });
      }

      return showingItems;
    }

    return [];
  }, [formattedMenuItems, searchValue, isMulti, onChangeSearch]);

  const getFieldName = (item: T) => {
    return item.value ? 'value' : 'id';
  };

  const openMenu = (e?: MouseEvent) => {
    if (disabled) {
      return;
    }
    if (customDisabled) {
      onMenuOpen && onMenuOpen(e);
      return;
    }
    setIsMenuOpen(true);
    setSearchValue('');
    onMenuOpen && onMenuOpen(e);
  };

  const closeMenu = (event?: Record<string, unknown>) => {
    setIsMenuOpen(false);
    onMenuClose && onMenuClose(event);
  };

  const changeSearch = (search: string) => {
    setSearchValue(search);
    onChangeSearch && onChangeSearch(search);
  };

  const handleSelectItem = (value: string | number, itemId: string | number) => {
    const newSelectedItem = formattedMenuItems.find(item => (item.value ? item.value === value : item.id === itemId));
    const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(newSelectedItem) : newSelectedItem.disabled;

    if (isDisabled) {
      return;
    }

    if (isMulti) {
      const newSelectedItems = [...(formattedSelected || [])];
      const selectedItemIndex = newSelectedItems.findIndex(sItem => (sItem.value ? sItem.value === value : sItem.id === itemId));
      const isItemSelected = selectedItemIndex !== -1;

      if (isItemSelected) {
        newSelectedItems.splice(selectedItemIndex, 1);
      } else {
        newSelectedItems.push(newSelectedItem);
      }

      selectHandler && selectHandler(newSelectedItems, newSelectedItem);
    } else {
      selectHandler && selectHandler(newSelectedItem);
    }

    if (!isMulti && selectHandler) {
      closeMenu();
    }
  };

  const handleMenuItemLeftElemClick = (value: string | number, itemId: string | number) => {
    const leftElemClickedItem = formattedMenuItems.find(item => (item.value ? item.value === value : item.id === itemId));

    menuItemLeftElemClick && menuItemLeftElemClick(leftElemClickedItem);
  };

  const handleMenuItemRightElemClick = (value: string | number, itemId: string | number) => {
    const rightElemClickedItem = formattedMenuItems.find(item => (item.value ? item.value === value : item.id === itemId));

    menuItemRightElemClick && menuItemRightElemClick(rightElemClickedItem);
  };

  const menuOpenHandler = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    onMenuOpen && onMenuOpen(event);
  };

  const menuCloseHandler = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    closeMenu(event);
  };

  const selectAll = () => {
    if (customSelectAll) {
      customSelectAll();
    } else {
      const allowedItems = itemsToShow.filter(item => {
        const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(item) : item.disabled;

        return !isDisabled;
      });
      const newSelectedItems = uniqBy([...(formattedSelected || []), ...allowedItems], 'value');

      selectHandler && selectHandler(newSelectedItems);
    }
  };

  const clearAll = () => {
    if (customClearAll) {
      customClearAll();
    } else {
      selectHandler && selectHandler([]);
    }
  };

  const handleRemove = (value: string | number, itemId: string | number) => {
    const selectedItemIndex = formattedSelected.findIndex(item => (item.value ? item.value === value : item.id === itemId));

    const selectedCurrentValue = [...formattedSelected];
    selectedCurrentValue.splice(selectedItemIndex, 1);
    selectHandler && selectHandler(selectedCurrentValue);
  };

  const renderHeader = () => {
    return (
      <DropdownHeader<T>
        searchValue={searchValue}
        onChangeSearch={isHideSearch ? null : changeSearch}
        customClassName={customHeaderClass}
        additionalText={headerAdditionalText}
        onClearAll={(isMulti || isHeaderNoMulti) && !onResetAll ? clearAll : null}
        onSelectAll={((isMulti && !disableSelectAll) || isHeaderNoMulti) && !onResetAll ? selectAll : null}
        isMobile={isMobile}
        isResetBtn={isMobile && !!searchValue}
        onResetAll={onResetAll}
        isSwitchAndButton={isSwitchAndButton}
        isOnSwitchAndFilter={isOnSwitchAndFilter}
        handleSwitchAndFilter={handleSwitchAndFilter}
        disabledSwitchAndButton={!selected?.length}
      />
    );
  };

  const renderFooter = () => {
    return (
      <DropdownFooter
        type={footerType}
        onButtonClick={() => footerButtonClick(closeMenu)}
        buttonTitle={footerButtonTitle}
        buttonLoading={footerButtonLoading}
        radioGroup={footerRadioGroup}
        radioClickHandler={footerRadioClickHandler}
        selectedRadioValue={footerSelectedRadioValue}
        customClassName={customFooterClass}
        isMobile={isMobile}
        isDisabledBtn={isDisabledFooterBtn}
        tooltip={footerButtonTooltip}
        footerWarningText={footerWarningText}
        footerIcon={footerIcon}
      />
    );
  };

  const renderMenuItem = (item: T) => {
    const key = getFieldName(item);

    const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(item) : item.disabled;
    const title = menuItemTitleRenderer ? menuItemTitleRenderer(item) : item.title;
    const tooltip = menuItemTooltipRenderer ? menuItemTooltipRenderer(item) : item.tooltip;
    const leftElem = menuItemLeftElemRenderer ? menuItemLeftElemRenderer(item) : null;
    const rightElem = menuItemRightElemRenderer ? menuItemRightElemRenderer(item) : null;
    const rightText = menuItemRightTextRenderer ? menuItemRightTextRenderer(item) : null;
    const isSelected = isMulti
      ? !!formattedSelected?.find(sItem => item[key] === sItem[key])
      : formattedSelected
      ? item[key] === formattedSelected[key]
      : null;

    return (
      <DropdownMenuItem
        isMulti={isMulti}
        size={menuItemSize}
        disabled={isDisabled}
        title={title}
        value={item.value}
        itemId={item.id}
        onItemClick={handleSelectItem}
        tooltip={tooltip}
        isSelected={isSelected}
        leftElem={leftElem}
        leftElemClick={handleMenuItemLeftElemClick}
        rightElem={rightElem}
        rightElemClick={handleMenuItemRightElemClick}
        rightText={rightText}
        customClassName={customMenuItemClass}
        isFullWidth={isFullWidth}
        isMobile={isMobile}
        isCustomMulti={isCustomMulti}
        isActiveCheckBoxForDisabledItems={isActiveCheckBoxForDisabledItems && !!item.is_locked}
      />
    );
  };

  const renderDefaultBasisItem = (item: T) => {
    const key = getFieldName(item);

    if (isMulti) {
      return (
        <ChipsStandardTag
          value={item.title}
          customIcon={isValuesRemovable ? <TokenIcon iconName={'close'} size={12} /> : null}
          onClickIcon={() => handleRemove(item[key])}
        />
      );
    }

    return item.title;
  };

  useEffect(() => {
    if (isSelectMenuClose) {
      closeMenu();
    }
  }, [isSelectMenuClose]);

  return (
    <>
      <div
        className={cn(themeClass, {
          [`${customSelectClass}`]: customSelectClass,
        })}
      >
        <DropdownBasis<T, IsMultiValue>
          isMulti={isMulti}
          mRef={selectBasisRef}
          customClassNameContainer={customBasisContainerClass}
          customClassNameField={customBasisFieldClass}
          selected={formattedSelected}
          placeholder={basisPlaceholder}
          renderSelected={renderBasisSelectedItem ? renderBasisSelectedItem : renderDefaultBasisItem}
          renderCustomBasis={renderCustomBasis}
          basisClickHandler={openMenu}
          isActive={isMenuOpen}
          isLoading={!isMenuOpen && isLoading}
          error={error}
          disabled={disabled}
          tooltip={basisTooltip}
          maxElements={basisMaxItems}
          isAlwaysPlaceholder={isAlwaysPlaceholder}
          isExpandOnHover={isExpandOnHover}
          isDisableMinWidth={isDisableMinWidth}
          isMobile={isMobile}
          selectType={selectType}
          disableRightElem={disableRightElem}
          toolTipClassName={toolTipClassName}
        />
        <DropdownSelectMenu<T, G>
          isMenuOpen={isMenuOpen}
          isLoading={isLoading}
          customClassName={customMenuClass}
          customClassPaperName={customClassPaperName}
          renderItemHeader={isHeader ? renderHeader : null}
          renderItemFooter={isFooter ? renderFooter : null}
          renderMenuItem={renderMenuItem}
          menuItems={itemsToShow}
          size={menuItemSize}
          groups={groups}
          groupingType={menuGroupingType}
          anchorEl={selectBasisRef.current}
          onClose={menuCloseHandler}
          revertDropdownPositionToTop={revertDropdownPositionToTop}
          alignDropdownPositionToLeft={alignDropdownPositionToLeft}
          handleOpenDDManageStatuses={handleOpenDDManageStatuses}
          popOverId={popOverId}
          isMobile={isMobile}
          noSort={noSort}
          onClickOutside={onClickOutside}
          isHighResolution={isHighResolution}
          sortingValue={sortingValue}
          isHighResolution={isHighResolution}
        />
      </div>
    </>
  );
}

export default Select;
