import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { setUserSeenReleaseNotes } from '../../../store/user/userThunk';
import { asideSlice } from '../../../store/aside/asideSlice';
import { supportSlice } from '../../../store/support/supportSlice';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../navigation/routes';
import AsideListItem from './ListItem/AsideListItem';
import SVG from 'react-inlinesvg';
import GlobalNewRequestCard from './GlobalRequestCard/GlobalNewRequestCard';
import Dialog from '@material-ui/core/Dialog';
import Transition from 'react-transition-group/Transition';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { Logo } from '../../controls';
import { SystemButton } from '../../controls/ButtonComponents';
import { filterSlice } from '../../../store/filters/filtersReducer';
import { userSlice } from '../../../store/user/userSlice';
import { teamSlice } from '../../../store/team/teamSlice';
import { getProjectDetails } from '../../../store/project/projectLogic';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { commonSlice } from '../../../store/common/commonReducer';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { reportingSlice } from '../../../store/reporting/reportingSlice';
import { documentsSlice } from '../../../store/documents/documentsReducer';
import { projectSlice } from '../../../store/project/projectReducer';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { SpecAndSubsSlice } from '../../../store/specAndSubs/specAndSubsSlice';
import { settingsSlice } from '../../../store/globalSettings/settingsSlice';
import Divider from '../../controls/Divider/Divider';
import useRouter from '../../../hooks/useRouter/useRouter';

import './AsideHolderStyles.scss';

const { setAsideMenuState, setActiveModule, setAsideMenuMobileState } = asideSlice.actions;
const { changeActiveProject, sandBoxReturnToMainPage } = requestSlice.actions;
const { handleHelpMenu, handleHelpSubMenu, handleRunQuickTourNewUser } = supportSlice.actions;
const { hardResetFiltersReducer } = filterSlice.actions;
const { clearNeedListData, resetSandboxLoadings, clearNeedListFilters } = requestSlice.actions;
const { setActiveProject } = userSlice.actions;
const { clearTeamState } = teamSlice.actions;
const { clearRequestsFilters } = commonSlice.actions;
const { resetReportingFirstLoading } = reportingSlice.actions;
const { resetDocumentsFirstMainLoading } = documentsSlice.actions;
const { clearPCDTableData } = projectSlice.actions;
const { clearSubmittalsData, clearSpecificationData } = SpecAndSubsSlice.actions;
const { setGlobalPrompPopUp } = settingsSlice.actions;

export const ColumnToolTip = withStyles({
  tooltip: {
    color: 'var(--color-secondary)',
    fontSize: '14px',
    padding: '5px 9px',
    backgroundColor: 'var(--button-active-menu)',
  },
  popper: {
    opacity: 1,
  },
})(Tooltip);

const initState = {
  activePages: {
    needs: false,
    whiteboard: false,
    tracking: false,
    reporting: false,
    inputData: false,
    outputData: false,
    settings: false,
    phase: false,
    commandCenter: false,
    commandCenterNew: false,
    notificationsList: false,
  },
  open: false,
  numPages: null,
  pageNumber: 1,
  isOpenPDF: false,
  isNewUser: false,
  isNewUserVisible: false,
};

const AsideHolder = () => {
  const user_data = useAppSelector(state => state.userReducer);
  const { isAsideMobileOpen, productTourIndex, isAsideMenuOpen, activeModule } = useAppSelector(state => state.asideReducer);
  const { sandbox_counters } = useAppSelector(state => state.projectReducer.projectInfo);
  const {
    sandBox: { total: sandBox_total },
    loading: needListLoading,
  } = useAppSelector(state => state.requestReducer);
  const { isRunSandboxQuickTour } = useAppSelector(state => state.supportReducer);
  const promptData = useAppSelector(state => state.settingsReducer.globalPromptPopUp);

  const clientProjects = user_data.clientProjects;
  const clients = user_data.clients;

  const currentClient = clients?.find(client => client.id === +user_data.selected_client_id);
  const currentClientLogo = currentClient?.renderings[currentClient.renderings.length - 1]?.file;

  const dispatch = useAppDispatch();
  const { urlProject, navigate, searchParams } = useRouter();

  const [activePages, setActivePages] = useState({
    needs: false,
    whiteboard: false,
    tracking: false,
    reporting: false,
    inputData: false,
    outputData: false,
    settings: false,
    phase: false,
    commandCenter: false,
    commandCenterNew: false,
    notificationsList: false,
  });
  const [isNewUser, setIsNewUser] = useState(false);
  const [isNewUserVisible, setIsNewUserVisible] = useState(false);
  const [active_project_id, setActiveProjectId] = useState(user_data.active_project_id && parseInt(user_data.active_project_id));
  const [asideOpen, setAsideOpen] = useState(false);
  const [isShowNews1, setIsShowNews1] = useState(JSON.parse(localStorage.getItem('isShowNews1') || 'true'));
  const [isOpenProjectsSelect, setIsOpenProjectsSelect] = useState(false);

  const project_name = sessionStorage.getItem('project_name');

  const listMobileRef = useRef<HTMLDivElement>();
  const openDeliverablesRenameRef = useRef<HTMLDivElement>();

  const { isMobile } = GetResolution();

  const { needs, reporting, commandCenter, inputData, outputData, phase } = activePages;

  const isSeparateTab = urlProject && urlProject[1] === 'card';
  const isTeamTab = urlProject && urlProject[1] === 'team';

  useEffect(() => {
    setIsShowNews1(JSON.parse(localStorage.getItem('isShowNews1') || 'true'));
  }, []);

  useEffect(() => {
    initActivePage();
    loadCSS('https://use.fontawesome.com/releases/v5.1.0/css/all.css', document.querySelector('#insertion-point-jss'));
  }, []);

  useEffect(() => {
    resetActiveButton();
    const pageName = detectPageName(location.pathname);
    handleClick(pageName);
  }, [location.pathname]);

  useEffect(() => {
    if (activePages.reporting && urlProject[1] == null) {
      initActivePage();
    }
  }, [activePages]);

  useEffect(() => {
    if (!isAsideMenuOpen && productTourIndex === 3 && !isRunSandboxQuickTour) {
      dispatch(setAsideMenuState(true));
    }
  }, [isAsideMenuOpen]);

  useEffect(() => {
    if (user_data.active_project_id) {
      setActiveProjectId(user_data.active_project_id);
    }
  }, [user_data.active_project_id]);

  const handleOpenProjectsSelect = e => {
    e.stopPropagation();

    setIsOpenProjectsSelect(true);
  };

  const handleCloseProjectsSelect = e => {
    e.stopPropagation();

    setIsOpenProjectsSelect(false);
  };

  const detectPageName = url => {
    const page = url.split('/')[2];
    let currentPage;
    switch (page) {
      case 'needs-list': {
        return (currentPage = 'needs');
      }
      case 'command-center': {
        return (currentPage = 'commandCenter');
      }
      case 'white-board': {
        return (currentPage = 'whiteboard');
      }
      case 'reporting': {
        return (currentPage = 'reporting');
      }
      case 'inputData': {
        return (currentPage = 'inputData');
      }
      case 'outputData': {
        return (currentPage = 'outputData');
      }
      case 'phase-plan': {
        return (currentPage = 'phase');
      }
      default: {
        return (currentPage = '');
      }
    }
  };

  const initActivePage = () => {
    if (urlProject[1] === 'command-center' || urlProject[1] === 'sand-box' || !urlProject[1]) {
      setActivePages(prevState => ({ ...prevState, commandCenter: true }));
      dispatch(setActiveModule({ module: 'commandCenter' }));
    }
    if (urlProject[1] === 'needs-list') {
      setActivePages(prevState => ({ ...prevState, needs: true }));
      dispatch(setActiveModule({ module: 'needs' }));
    }
    if (urlProject[1] === 'white-board') {
      setActivePages(prevState => ({ ...prevState, whiteboard: true }));
      dispatch(setActiveModule({ module: 'whiteboard' }));
    }
    if (urlProject[1] === 'reporting') {
      setActivePages(prevState => ({ ...prevState, reporting: true }));
      dispatch(setActiveModule({ module: 'reporting' }));
    }
    if (urlProject[1] === 'input-data') {
      setActivePages(prevState => ({ ...prevState, inputData: true }));
      dispatch(setActiveModule({ module: 'inputData' }));
    }
    if (urlProject[1] === 'output-data') {
      setActivePages(prevState => ({ ...prevState, outputData: true }));
      dispatch(setActiveModule({ module: 'outputData' }));
    }
    if (urlProject[1] === 'phase-plan') {
      setActivePages(prevState => ({ ...prevState, phase: true }));
      dispatch(setActiveModule({ module: 'phase' }));
    }
  };

  const resetActiveButton = () => {
    setActivePages(initState.activePages);
  };

  const handleClick = (name, url?) => {
    if (promptData.isChanged) {
      const newFunc = () => {
        dispatch(setActiveModule({ module: name }));
        setActivePages({ ...initState.activePages, [name]: true });
        handleCloseMenu();
        dispatch(setAsideMenuState(false));
        navigate(url);
      };

      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: newFunc,
        }),
      );
      return;
    }

    dispatch(setActiveModule({ module: name }));
    setActivePages({ ...initState.activePages, [name]: true });
    handleCloseMenu();
    dispatch(setAsideMenuState(false));
  };

  const handleAsideMenu = e => {
    if (promptData.isChanged) {
      e.preventDefault();
    }
  };

  const handleCloseNews = () => {
    setIsShowNews1(false);
    localStorage.setItem('isShowNews1', JSON.stringify(false));
    dispatch(setUserSeenReleaseNotes());
  };

  const handleOpenQuickTour = () => {
    handleCloseNews();
    setIsNewUser(false);
    setTimeout(() => {
      dispatch(handleHelpMenu({ helpState: true }));
      dispatch(handleRunQuickTourNewUser(true));
    }, 1000);
    localStorage.removeItem('inviteUser');
  };

  const handleOpenSupport = () => {
    handleCloseNews();
    setIsNewUser(false);
    setTimeout(() => {
      dispatch(handleHelpMenu({ helpState: true }));
      dispatch(handleHelpSubMenu('get_help'));
    }, 1000);
    localStorage.removeItem('inviteUser');
  };

  const handleCloseNewUser = () => {
    handleCloseNews();
    setIsNewUser(false);
    dispatch(handleRunQuickTourNewUser(false));
    localStorage.removeItem('inviteUser');
  };

  const handleCloseMenu = () => {
    dispatch(setAsideMenuMobileState(false));
  };

  const selectProject = async e => {
    const filterParam = searchParams.get('filter');
    if (urlProject[0] && urlProject[1] === 'needs-list' && filterParam) {
      navigate(`/${urlProject[0]}/needs-list`);
    }

    if (+e.target.value !== +active_project_id) {
      dispatch(clearNeedListData());
      dispatch(resetSandboxLoadings());
      dispatch(clearTeamState());
      setActiveProjectId(e.target.value);
      dispatch(setActiveProject(e.target.value));
      dispatch(hardResetFiltersReducer());
      dispatch(changeActiveProject(+e.target.value));
      dispatch(getProjectDetails(e.target.value));
      dispatch(getProjectById(e.target.value));
      dispatch(clearNeedListFilters());
      dispatch(resetReportingFirstLoading());
      dispatch(resetDocumentsFirstMainLoading());
      dispatch(clearRequestsFilters());
      dispatch(clearSubmittalsData());
      dispatch(clearSpecificationData());
    }
  };

  const clearData = () => {
    dispatch(clearNeedListData());
    dispatch(resetSandboxLoadings());
    dispatch(clearTeamState());
    dispatch(hardResetFiltersReducer());
    dispatch(clearNeedListFilters());
    dispatch(clearRequestsFilters());
    dispatch(resetReportingFirstLoading());
    dispatch(resetDocumentsFirstMainLoading());
    dispatch(clearPCDTableData());
    dispatch(clearSubmittalsData());
    dispatch(clearSpecificationData());
  };

  const themeClass = useGetThemeClass('b-asideHolder');
  const themeItemClass = useGetThemeClass('b-aside-list-item');

  const partyIds = user_data?.userInfo?.parties?.length ? user_data?.userInfo?.parties.map(item => item.id) : [];
  const generalContractorArray = [4, 481, 44, 68, 97, 266, 321, 338, 352, 366, 458, 1464, 1470, 2204];

  const isGeneralContractor = partyIds.length ? partyIds.filter(el => generalContractorArray.includes(el)) : [];

  const isPhasePlanActive: boolean =
    user_data?.userInfo?.roles &&
    !!Object.keys(user_data.userInfo.roles).filter(key => key === '1' || key === '4').length &&
    !!isGeneralContractor.length;

  return isSeparateTab ? null : (
    <>
      <aside
        className={cn(`${themeClass}`, { ['-opened']: isAsideMobileOpen })}
        onClick={e => {
          if (isMobile) {
            e.stopPropagation();
            handleCloseMenu();
          }
        }}
      >
        <div
          className={cn(`${themeClass}_list`, { ['-noDisplay']: urlProject[0] === 'no-projects-yet' })}
          ref={isMobile ? listMobileRef : undefined}
          onClick={e => {
            if (isMobile) {
              e.stopPropagation();
            }
          }}
        >
          {isMobile && (
            <div className={`${themeClass}_header`}>
              <div className={`${themeClass}_header_logo`}>
                <Logo isFull={false} />
                <span className={`${themeClass}_header_title`}>Scheduler</span>
              </div>
              <SystemButton
                type={'close'}
                size={'lg'}
                variant={'transparent'}
                isMobile
                clickHandler={handleCloseMenu}
                customClass={`${themeClass}_header_icon`}
              />
            </div>
          )}
          <div id="group-tourBlock" className={`${themeClass}_block`}>
            <div className={`${themeItemClass}_link`}>
              <Link
                to={ROUTES.getSandBoxNew(project_name)}
                className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                onClick={handleAsideMenu}
              >
                <AsideListItem
                  opened={commandCenter}
                  name={'commandCenter'}
                  onClick={() => {
                    dispatch(sandBoxReturnToMainPage({ status: true }));
                    handleClick('commandCenter', ROUTES.getSandBoxNew(project_name));
                  }}
                  icon={<TokenIcon iconName={'sandbox'} size={24} />}
                  title={'Command center'}
                  counter={sandBox_total || sandbox_counters}
                  tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                  disabled={!user_data.userInfo?.projects?.length}
                />
              </Link>
            </div>
            {!isMobile && (
              <>
                {isPhasePlanActive && (
                  <div className={`${themeItemClass}_link`}>
                    <Link
                      to={ROUTES.getPhasePlan(project_name)}
                      className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                      onClick={handleAsideMenu}
                    >
                      <AsideListItem
                        opened={phase}
                        name={'phase'}
                        onClick={name => handleClick(name, ROUTES.getPhasePlan(project_name))}
                        icon={<SVG src={require('../../../assets/images/sideBar/phase_plan.svg')} alt="" />}
                        title={'Phase plan'}
                        tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                        disabled={!user_data.userInfo?.projects?.length}
                        wipBadge
                      />
                    </Link>
                  </div>
                )}
                <div className={`${themeItemClass}_link`}>
                  <Link
                    to={ROUTES.getNeedsList(project_name)}
                    className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                    onClick={handleAsideMenu}
                  >
                    <AsideListItem
                      opened={needs}
                      name={'needs'}
                      onClick={name => handleClick(name, ROUTES.getNeedsList(project_name))}
                      icon={<TokenIcon iconName={'needs-list'} size={24} />}
                      title={'Needs list'}
                      tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                      disabled={!user_data.userInfo?.projects?.length}
                    />
                  </Link>
                </div>
                {/*<div className={`${themeItemClass}_link`}>*/}
                {/*  <Link*/}
                {/*    to={ROUTES.getWhiteBoard(project_name)}*/}
                {/*    className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}*/}
                {/*    onClick={handleAsideMenu}*/}
                {/*  >*/}
                {/*    <AsideListItem*/}
                {/*      opened={whiteboard}*/}
                {/*      name={'whiteboard'}*/}
                {/*      onClick={(name)=>handleClick(name, ROUTES.getWhiteBoard(project_name))}*/}
                {/*      icon={<SVG src={require('../../../assets/images/sideBar/white_board.svg')} alt="" />}*/}
                {/*      title={'Whiteboard'}*/}
                {/*      tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}*/}
                {/*      disabled={!user_data.userInfo?.projects?.length}*/}
                {/*      wipBadge*/}
                {/*    />*/}
                {/*  </Link>*/}
                {/*</div>*/}
                <div className={`${themeItemClass}_link`}>
                  <Link
                    to={ROUTES.getReporting(project_name)}
                    className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                    onClick={handleAsideMenu}
                  >
                    <AsideListItem
                      opened={reporting}
                      name={'reporting'}
                      onClick={name => handleClick(name, ROUTES.getReporting(project_name))}
                      icon={<TokenIcon iconName={'reporting'} size={24} />}
                      title={'Reporting'}
                      tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                      disabled={!user_data.userInfo?.projects?.length}
                    />
                  </Link>
                </div>
                <div className={`${themeItemClass}_link`}>
                  <Link
                    to={ROUTES.getInputDataPage(project_name, 'drawings')}
                    className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                    onClick={handleAsideMenu}
                  >
                    <AsideListItem
                      opened={inputData}
                      name={'inputData'}
                      onClick={name => handleClick(name, ROUTES.getInputDataPage(project_name, 'drawings'))}
                      icon={<TokenIcon iconName={'input-data'} size={24} />}
                      title={'Input data'}
                      tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                      disabled={!user_data.userInfo?.projects?.length}
                    />
                  </Link>
                </div>
              </>
            )}
            <div className={`${themeItemClass}_link`} ref={openDeliverablesRenameRef}>
              <Link
                to={ROUTES.getOutputDataPage(project_name, 'deliverables')}
                className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                onClick={handleAsideMenu}
              >
                <AsideListItem
                  opened={outputData}
                  name={'outputData'}
                  onClick={name => handleClick(name, ROUTES.getOutputDataPage(project_name, 'deliverables'))}
                  icon={<TokenIcon iconName={'output-data'} size={24} />}
                  title={'Output data'}
                  tourActive={productTourIndex === 3 && !isRunSandboxQuickTour}
                  disabled={!user_data.userInfo?.projects?.length}
                />
              </Link>
            </div>
            {isMobile && <Divider direction={'horizontal'} type={'srf-5'} />}
            {isMobile && (
              <div className={`${themeItemClass}_link`}>
                <Link
                  to={ROUTES.getTeam(project_name)}
                  className={!user_data.userInfo?.projects?.length ? `${themeItemClass}_disable` : ''}
                  onClick={handleAsideMenu}
                >
                  <AsideListItem
                    opened={isTeamTab}
                    name={'team'}
                    onClick={name => handleClick(name, ROUTES.getTeam(project_name))}
                    icon={<TokenIcon iconName={'team'} size={24} />}
                    title={'Team'}
                    tourActive={false}
                    disabled={!user_data.userInfo?.projects?.length}
                  />
                </Link>
              </div>
            )}
          </div>
          <GlobalNewRequestCard onClick={handleCloseMenu} />
        </div>
        {isNewUser ? (
          <Dialog
            open={isNewUser}
            onClose={handleCloseNewUser}
            TransitionComponent={Transition}
            classes={{ paperFullScreen: '50px' }}
            PaperProps={{
              style: {
                maxWidth: '740px',
              },
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '47vh',
                overflow: 'hidden',
                textAlign: 'justify',
              }}
            >
              <div className={'newsContent'}>
                <a href="#" className="Logo__whatNew">
                  <img src={require('../../../assets/icons/logo beta 1080.png')} alt="" />
                </a>
                <div className={'paragraph'}>
                  <span style={{ fontWeight: 'bold' }}>
                    Welcome to Planit Scheduler!
                    <a target="_blank">
                      <img
                        src="https://pmexpd.stripocdn.email/content/guids/CABINET_4131b35ac0c45602499a739bba83b2b0/images/image12.png"
                        alt=""
                        style={{ display: 'inline-block' }}
                        width="17"
                      />
                    </a>
                  </span>
                </div>
                <div className={'paragraph'}>
                  We're thrilled to have you on board. As a new user, we want to make sure you feel right at home. With Planit Scheduler,
                  you'll be able to bring your efficiency to another level.
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  <span>To get started, we recommend taking </span>
                  <span className={'newUserText'} onClick={handleOpenQuickTour}>
                    a quick tour of the app
                  </span>
                  .<span> This will give you a good sense of how everything works and </span>
                  <span>where to find the features you need. If you ever have any questions or need help, don't hesitate to </span>
                  <span className={'newUserText'} onClick={handleOpenSupport}>
                    reach out to our support team
                  </span>
                  .<span> We're here to make your experience as smooth and enjoyable as possible.</span>
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  Thanks again for joining Planit Scheduler. We can't wait to see what you'll achieve with us!
                </div>
                <div className={'paragraph'}>
                  <b>Best,</b>
                </div>
                <div className={'paragraph'} style={{ padding: 0 }}>
                  Planit Scheduler team
                </div>
              </div>
            </div>
          </Dialog>
        ) : null}
        {!isNewUserVisible ? (
          <Dialog
            open={!!(isShowNews1 && user_data?.userInfo?.title && !user_data?.userInfo?.is_seen_release_notes)}
            onClose={handleCloseNews}
            TransitionComponent={Transition}
            classes={{ paperFullScreen: '50px' }}
            PaperProps={{
              style: {
                maxWidth: '740px',
              },
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '90vh',
                overflow: 'hidden',
                textAlign: 'justify',
              }}
            >
              <div className={'newsContent'}>
                <a href="#" className="Logo__whatNew">
                  <img src={require('../../../assets/icons/logo.png')} alt="" />
                </a>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  Hello, dear {`${user_data?.userInfo?.first_name || ''} ${user_data?.userInfo?.last_name || ''}`}
                  &nbsp;👋,
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  We are pleased to inform you about the latest changes in Planit app. We’ve rethinked the Command center to accommodate the
                  recent addition of Input/Output submittals workflow. Three default widgets Flow, Hotlist, Heatmap will help you navigate
                  through the intensive communication that every project includes. Moving forward we encourage you to request widgets from
                  our dev team by contacting us at{' '}
                  <i>
                    <span style={{ color: '#1376c8', textDecoration: 'underline' }}>admin@planit-inc.com</span>
                  </i>
                  .
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
                  💨Flow
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  Revamped interface of former ‘Sandbox’ that automatically transforms incoming information in actionable items for all:
                  NFs, CL drawings and Input/Output submittals, and helps to keep ball in the air at all times.
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
                  🔥Hotlist
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  Hotlist becomes a separate widget to show items that are becoming urgent based on critical dates. Along with automated
                  logic, users can mark items as ‘hot’ manually.
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 15px' }}>
                  🌡️Heatmap
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  Calendars will highlight ‘busy’ days, when you or your company has a lot of items in court. Now includes submittals.
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
                  ***
                </div>
                <div className={'paragraph'} style={{ padding: '0 0 0 0' }}>
                  The release also includes bug fixes, multiple performance improvements, and misc UI/UX enhancements.
                </div>
                <div className={'paragraph'} style={{ padding: '70px 0 0 0' }}>
                  <b>Sincerely,</b>
                </div>
                <div className={'paragraph'} style={{ padding: 0 }}>
                  Planit Scheduler App Team
                </div>
                <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}></div>
              </div>
            </div>
          </Dialog>
        ) : null}
      </aside>
    </>
  );
};
export default AsideHolder;
