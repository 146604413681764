import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ColumnToolTip } from '../../../AsideHolder/AsideHolder';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuPropsSingle, MenuPropsSingleSelected } from '../../../../controls/Select/MenuPropsSingle';
import SVG from 'react-inlinesvg';

class Arrow extends React.Component {
  render() {
    return (
      <span>
        <SVG {...this.props} style={{ margin: '8px 5px 0 0' }} src={require('../../../../../assets/icons/arrow_select.svg')} alt="#" />
      </span>
    );
  }
}

class SelectField extends React.Component {
  state = {
    open: false,
  };

  handleChange = e => {
    const { target } = e;
    const { selectHandler } = this.props;
    e.stopPropagation();
    selectHandler(target.value);
  };

  handleOpen = () => {
    if (!this.props.forbidToOpen) {
      this.setState({ open: true });
      this.props.changeRequestType && this.props.changeRequestType(true);
    }
  };
  handleClose = () => {
    this.props.changeRequestType && this.props.changeRequestType(false);
    const { onClose } = this.props;
    this.setState({ open: false });
    onClose && onClose();
  };

  render() {
    const { label, error, selectedId, style, placeholder, classes, controlOpen, fieldView, customClasses, customValuesId } = this.props;
    let { values } = this.props;
    !values?.length ? (values = []) : null;
    const selectedItem = values.find(value => value[customValuesId || 'id'] === selectedId);

    return (
      <FormControl
        variant="outlined"
        style={{ width: '100%' }}
        classes={{
          root: classes ? classes.formControlRoot : '',
        }}
      >
        {label && classes && !selectedId && (
          <InputLabel
            classes={{
              shrink: classes.disableShrink,
              outlined: classes.outlined,
              focused: classes.focused,
            }}
            htmlFor={`outlined-${selectedId}`}
          >
            {label}
          </InputLabel>
        )}
        <Select
          open={this.state.open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          value={selectedItem?.[customValuesId || 'id'] + 1 ? selectedItem?.[customValuesId || 'id'] : ''}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          placeholder={placeholder}
          className={`b-createProject__select ${customClasses} ${error ? 'b-createProject__selectError' : ''}`}
          style={style}
          MenuProps={MenuPropsSingle}
          classes={{
            root: classes ? classes.selectRoot : '',
          }}
          {...(controlOpen ? controlOpen : {})}
          // displayEmpty
          // inputProps={{ 'aria-label': 'Without label' }}
          input={
            <OutlinedInput
              labelWidth={0}
              name={`${selectedItem?.[customValuesId || 'id']}`}
              id={`outlined-${selectedId}`}
              placeholder={placeholder}
              className={`b-createProject__input`}
              // inputProps={{ autocomplete: 'off'}}
            />
          }
          IconComponent={props => <Arrow {...props} />}
          error={error}
        >
          {values.map(item => {
            const { id, title, company, desc } = item;
            return (
              <MenuItem
                key={item[customValuesId || 'id']}
                value={item[customValuesId || 'id']}
                className={'menuItem'}
                style={
                  item[customValuesId || 'id'] ===
                  (selectedItem?.[customValuesId || 'id'] + 1 ? selectedItem?.[customValuesId || 'id'] : '')
                    ? MenuPropsSingleSelected
                    : {}
                }
              >
                <ColumnToolTip title={desc ? desc : ''} placement="top">
                  <span>{item?.[fieldView] || title || company}</span>
                </ColumnToolTip>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      overflowY: 'auto',
      width: 'auto',
      backgroundColor: 'var(--background-header)',
    },
  },
  MenuListProps: {
    style: {
      backgroundColor: 'var(--background-select)',
    },
  },
};

// SelectField.propTypes = {
//   label: PropTypes.string,
//   // children: PropTypes.func.isRequired,
//   currentValue: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.number,
//   ]),
//   // selectHandler: PropTypes.func.isRequired,
//
// };

SelectField.displayName = 'SelectField';

export default SelectField;
