import React, { useMemo, useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { DropdownItemModel } from '../../../models/global';
import { Select } from '../Dropdowns/DropdownСomponents';
import PartySelector from '../PartySelector/PartySelector';
import { NfFilterDisciplineModel, SelectedRequestCompanyModel, SimpleTitleModel } from '../../../models';
import TextInputNew from '../TextInputNew/TextInputNew';
import StatusReview from '../Status/StatusReview/StatusReview';
import { PCDApi } from '../../../service/Api/pcd/types';
import ReviewStatusesType = PCDApi.ReviewStatuses;
import { SystemButton } from '../ButtonComponents';
import cn from 'classnames';

import './ReviewerEditTemplateStyles.scss';

type ReviewerEditTemplateErrors = 'party' | 'days';

interface IProps {
  type: 'card' | 'portable' | 'popup' | 'mobile';
  selectPriority: (number) => void;
  selectedPriority: number;
  selectParty: (item: NfFilterDisciplineModel) => void;
  onSelectCompany?: (company: SimpleTitleModel) => void;
  selectedParty: SelectedRequestCompanyModel;
  errors: ReviewerEditTemplateErrors[];
  setDaysToRespond: (days: number) => void;
  daysToRespond: number;
  status?: ReviewStatusesType;
  setNoteToReviewer: (value: string) => void;
  noteToReviewer: string;
  deleteReview?: () => void;
  isSubmittal: boolean;
}

const priorityOptions = [
  {
    value: 1,
    title: '1st',
  },
  {
    value: 2,
    title: '2nd',
  },
  {
    value: 3,
    title: '3rd',
  },
];

const ReviewerEditTemplate = ({
  type,
  selectPriority,
  selectedPriority,
  selectParty,
  selectedParty,
  setDaysToRespond,
  daysToRespond,
  status,
  setNoteToReviewer,
  noteToReviewer,
  errors,
  deleteReview,
  isSubmittal,
}: IProps) => {
  const themeClass = useGetThemeClass(`b-reviewerEditTemplate-${type}`);

  const [focusInputName, setFocusInputName] = useState<'days' | 'note' | ''>('');

  const showStatus = useMemo(() => {
    return type !== 'popup';
  }, [type]);

  const isMobile = useMemo(() => {
    return type === 'mobile';
  }, [type]);

  const selectedPriorityObject = useMemo(() => {
    return priorityOptions.find(priorityOption => priorityOption.value === selectedPriority);
  }, [selectedPriority]);

  const renderPriority = () => {
    return (
      <div className={`${themeClass}_priority`}>
        <Select<DropdownItemModel, null, false>
          isMulti={false}
          menuItems={priorityOptions}
          selectHandler={item => selectPriority(item.value)}
          selected={selectedPriorityObject}
          menuItemSize={'md'}
          disabled={false}
          customBasisFieldClass={`${themeClass}_priority_selectField`}
          customMenuClass={`${themeClass}_priority_selectMenu`}
          isMobile={isMobile}
        />
      </div>
    );
  };

  const renderDaysToRespond = () => {
    return (
      <div className={`${themeClass}_days`}>
        <span className={`${themeClass}_days_label`}>Days to respond</span>
        <div className={`${themeClass}_days_input`}>
          <TextInputNew
            type={'on-bgd'}
            placeholder={''}
            onChange={value => setDaysToRespond(value)}
            value={String(daysToRespond)}
            inputType={'number'}
            error={''}
            isFocus={focusInputName === 'days'}
            onFocus={() => setFocusInputName('days')}
            onBlur={() => setFocusInputName('')}
            isMobile={isMobile}
            autoComplete={'off'}
          />
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    const showLabel = type === 'portable' || type === 'mobile';

    return (
      <div className={`${themeClass}_status`}>
        <StatusReview status={status} showLabel={showLabel} showTooltip={!showLabel} />
        {isMobile ? <SystemButton type={'delete'} size={'lg'} variant={'transparent'} clickHandler={deleteReview} /> : null}
      </div>
    );
  };

  const renderPriorityDays = () => {
    return (
      <div className={`${themeClass}_priorityDays`}>
        {renderPriority()}
        {renderDaysToRespond()}
      </div>
    );
  };

  return (
    <>
      <div className={cn(themeClass, { ['-isSubmittal']: isSubmittal })}>
        {isMobile ? renderStatus() : null}
        {isMobile ? renderPriorityDays() : null}
        {!isMobile ? renderPriority() : null}
        <div className={`${themeClass}_party`}>
          <div className={`${themeClass}_inputLabel`}>Review party</div>
          <PartySelector
            selected={selectedParty}
            handleSelect={item => selectParty(item)}
            isDisableSelect={false}
            errors={errors}
            customPartyContainerClassName={`${themeClass}_party_selector`}
            customSelectBasisFieldClass={`${themeClass}_party_selector_selectField`}
            isMobile={isMobile}
          />
        </div>
        {!isMobile ? renderDaysToRespond() : null}
        {showStatus && !isMobile ? renderStatus() : null}
        {!isSubmittal && (
          <div className={`${themeClass}_note`}>
            <div className={`${themeClass}_inputLabel`}>Note to reviewer</div>
            <div className={`${themeClass}_note_input`}>
              <TextInputNew
                type={'on-bgd'}
                placeholder={'Input subject'}
                onChange={value => setNoteToReviewer(value)}
                value={noteToReviewer}
                error={''}
                isFocus={focusInputName === 'note'}
                onFocus={() => setFocusInputName('note')}
                onBlur={() => setFocusInputName('')}
                isMobile={isMobile}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewerEditTemplate;
