import React, { FC } from 'react';
import DialogPopUp from '../DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './PopupSelectDayOffStyles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleSelect: (prevValue?: string, state?: boolean) => void;
  work_week: number;
  value?: string;
}

const PopupSelectDayOff: FC<IProps> = ({ isOpen, handleSelect, onClose, work_week, value }) => {
  const themeClass = useGetThemeClass('b-selectDayOffPopup');
  const dialogClass = useGetThemeClass('dialogPopUp');

  return (
    <DialogPopUp
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title Pull request modal"
      maxWidth={'md'}
      fullWidth={true}
      title={'Select the day off from work?'}
      customClassname={themeClass}
      renderModalContent={() => (
        <div className={`${dialogClass}_content_modalText`}>
          The date you picked is not a working day in this project. The construction process is carried out from{' '}
          <span className="marked">{work_week === 5 ? ' Monday to Friday' : ' Monday to Saturday'}</span>
          <br />
          <br /> Do you want to choose it anyway?
        </div>
      )}
      primaryText={'Select anyway'}
      handleOnPrimary={() => handleSelect(value, true)}
      secondaryText={'Cancel'}
      handleOnSecondary={onClose}
    />
  );
};
export default PopupSelectDayOff;
