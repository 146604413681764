import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageHeading2Styles.scss';

class PageHeading2View extends React.Component {
  static propTypes = {
    //data
    title: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
  };

  render() {
    const { title, theme } = this.props;
    return <p className={`${styles.PageHeading2} ${theme}`}>{title}</p>;
  }
}

export default PageHeading2View;
