import React from 'react';
import TokenIcon from '../../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import { FilterNameItemInterface } from '../../../../../../store/relatedItems/relatedItemsSlice.model';
import cn from 'classnames';

import './FilterNameItemStyles.scss';

interface IProps {
  filterNameItem: FilterNameItemInterface;
  handleFilterNameItemClick?: (filterName: FilterNameItemInterface) => void;
}

const FilterNameItem: React.FC<IProps> = ({ filterNameItem, handleFilterNameItemClick }) => {
  const themeClass = useGetThemeClass('b-filterNameItem');

  return (
    <div className={`${themeClass}_container`}>
      <div
        className={cn(`${themeClass}_item`, {
          [`-ml12`]: filterNameItem.value === 'not_verified',
        })}
        onClick={() => handleFilterNameItemClick(filterNameItem)}
      >
        <div className={`${themeClass}_item_title`}>{filterNameItem.title}</div>
        <div className={`${themeClass}_item_icon`}>
          <TokenIcon iconName={filterNameItem.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
        </div>
      </div>
    </div>
  );
};

export default FilterNameItem;
