import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';

import styles from './RibbonComponent.module.scss';
import CustomTooltip from '../../../Tooltip/Tooltip';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

interface IRibbonComponent {
  fieldId: number | string;
  disabled: boolean;
}

export const RibbonComponent: React.FC<IRibbonComponent> = ({ disabled, fieldId }) => {
  const { isMobile } = GetResolution();

  const themeClassRibbonContainer = useGetThemeClass(styles.ribbonContainer);

  return (
    <div id={`toolbar-${fieldId}`} className={cn(themeClassRibbonContainer, 'ql-snow')}>
      <CustomTooltip title={'Bold'} placement={'bottom'}>
        <button className="ribbonBtn ql-bold">
          <TokenIcon iconName={'bold'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>

      <CustomTooltip title={'Italics'} placement={'bottom'}>
        <button className="ribbonBtn ql-italic">
          <TokenIcon iconName={'italics'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>

      <CustomTooltip title={'Underline'} placement={'bottom'}>
        <button className="ribbonBtn ql-underline">
          <TokenIcon iconName={'underline'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>
      <div className="ql-lineSeparation"></div>

      <select className="ql-color" defaultValue={'var(--color)'}>
        <option value="red"></option>
        <option value="orange"></option>
        <option value="yellow"></option>
        <option value="lime"></option>
        <option value="blue"></option>
        <option value="violet"></option>
        <option value="purple"></option>
        <option value="gray"></option>
        <option selected></option>
      </select>

      <div className="ql-lineSeparation"></div>
      <CustomTooltip title={'List bullet'} placement={'bottom'}>
        <button className="ribbonBtn ql-list" value={'bullet'}>
          <TokenIcon iconName={'list-bullet'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>

      <CustomTooltip title={'List number'} placement={'bottom'}>
        <button className="ribbonBtn ql-list" value={'ordered'} style={{ paddingLeft: '3px' }}>
          <TokenIcon iconName={'list-number'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>

      <div className="ql-lineSeparation"></div>

      <CustomTooltip title={'Mention a person'} placement={'bottom'}>
        <button className="ribbonBtn ql-addMention" disabled={disabled}>
          <TokenIcon iconName={'at'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>

      <CustomTooltip title={'Link'} placement={'bottom'}>
        <button className="ribbonBtn ql-link" value={'link'}>
          <TokenIcon iconName={'link'} size={isMobile ? 20 : 16} />
        </button>
      </CustomTooltip>
    </div>
  );
};
