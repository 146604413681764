import React from 'react';
import useRoutingHelper from '../../hooks/useRoutingHelper/useRoutingHelper';

export function withRouterHelper(Component) {
  function ComponentWithRouterProp(props) {
    const routerHelper = useRoutingHelper();

    return <Component {...props} routerHelper={routerHelper} />;
  }

  return ComponentWithRouterProp;
}

export interface RouterHelperComponentProps {
  routerHelper: { [key: string]: () => any };
}
