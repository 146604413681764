import { BaseApi } from '../baseApi';

import { ConstructorParams, PCDApi } from './types';
import { PlaneControlDeliverable } from '../../../store/pcd/types';
import { pcdHistoryRecordSend } from '../../../store/pcd/pcdThunk';

class PCDApi extends BaseApi {
  devMode: boolean;

  constructor({ baseURL, devMode }: ConstructorParams) {
    super(baseURL);
    this.devMode = devMode;
  }

  getPCDCard({ id }: PlaneControlDeliverable.GetCardPayload): Promise<PCDApi.GetPCDCardResponse> {
    const result = this._get({
      route: `pcd/${id}/card-pcd`,
      needAuth: true,
      devMode: this.devMode,
    });

    return result;
  }

  updatePCDCard({ deliverable_id, data, upload_files }: PlaneControlDeliverable.UpdatePCDCardPayload): Promise<PCDApi.PCDCardResponse> {
    return this._post({
      route: `pcd/${deliverable_id}/update`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { ...data },
      formData: true,
      files: upload_files,
    });
  }

  createPCDCard({ data, upload_files }: PlaneControlDeliverable.CreatePCDCardPayload): Promise<PCDApi.PCDCardResponse> {
    return this._post({
      route: `pcd/create-revision`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { ...data },
      formData: true,
      files: upload_files,
    });
  }

  getListForLinking({
    project_id,
    building_id,
    entity,
  }: PlaneControlDeliverable.ListForLinkingPayload): Promise<PCDApi.GetListForLinkingResponse> {
    return this._get({
      route: `pcd/${project_id}/${building_id}/get-list-${entity}`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  linkDeliverableToNf({ pcd_id, request_id, project_id }: PlaneControlDeliverable.LinkUnlinkRequestItem): Promise<PCDApi.PCDCardResponse> {
    return this._post({
      route: `pcd/${pcd_id}/link-nf`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { project_id, request_id },
    });
  }

  changeNfValidityStatus({ owner_id, data }: PlaneControlDeliverable.ChangeNfValidityStatus): Promise<PCDApi.PCDCardResponse> {
    return this._post({
      route: `pcd/set-status-request`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { owner_id, data },
    });
  }

  unLinkDeliverableToNf({
    pcd_id,
    request_id,
    project_id,
    unlink_previous,
  }: PlaneControlDeliverable.LinkUnlinkRequestItem): Promise<PCDApi.PCDCardResponse> {
    return this._post({
      route: `pcd/${pcd_id}/unlink-nf`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { project_id, request_id, unlink_previous },
    });
  }

  linkDeliverableToDeliverable({
    pcd_id,
    deliverable_id,
    project_id,
  }: PlaneControlDeliverable.LinkUnlinkDeliverableItem): Promise<PCDApi.GetPCDCardResponse> {
    return this._post({
      route: `pcd/${pcd_id}/link-deliverable`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { deliverable_id, project_id },
    });
  }

  unLinkDeliverableToDeliverable({
    pcd_id,
    deliverable_id,
    project_id,
  }: PlaneControlDeliverable.LinkUnlinkDeliverableItem): Promise<PCDApi.GetPCDCardResponse> {
    return this._post({
      route: `pcd/${pcd_id}/unlink-deliverable`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { deliverable_id, project_id },
    });
  }

  getPCDList({ project_id }: PlaneControlDeliverable.GetPCDList): Promise<PCDApi.GetPCDListResponse> {
    return this._get({
      route: `pcd/${project_id}/list-pcd`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  editDeliverableReviewsPCD(bodyParams) {
    return this._post({
      route: `/edit-reviews`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  updateReviewPCD(bodyParams, review) {
    return this._post({
      route: `/${review}/update-review`,
      bodyParams: { ...bodyParams },
      needAuth: true,
      devMode: this.devMode,
      formData: true,
      // files: upload_files,
    });
  }

  deleteDeliverablePCD({ project_id, deliverable_id }: PlaneControlDeliverable.DeleteDeliverablePayload) {
    return this._delete({
      route: `/pcd/${deliverable_id}/delete`,
      bodyParams: { project_id },
      needAuth: true,
      devMode: this.devMode,
    });
  }

  changePCDCardStatus({ id, status }: PlaneControlDeliverable.ChangePCDCardStatusPayload): Promise<PCDApi.GetPCDCardResponse> {
    return this._post({
      route: `pcd/${id}/set-status`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { status },
    });
  }

  updatePcdReasons({
    id,
    reason_comment,
    reason,
    caused_nfs,
    caused_deliverables,
  }: PlaneControlDeliverable.RevisionReasonPayload): Promise<PCDApi.GetPCDCardResponse> {
    return this._put({
      route: `pcd/${id}/update-reason`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { reason_comment, reason, caused_nfs, caused_deliverables },
    });
  }

  pcdHistoryRecordSend({ type, deliverableId, fileId }: PCDApi.PCDHistoryRecordInterface) {
    return this._get({
      route: `pcd/${deliverableId}/${fileId}/history-record-${type}`,
      needAuth: true,
      devMode: this.devMode,
    });
  }
}

export default new PCDApi({ devMode: false });
