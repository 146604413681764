import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { descriptionStyles } from './SandBoxDescriptionFieldStyles';

class SandBoxDescription extends Component {
  changeHandler = ({ target }) => {
    const { changeHandler } = this.props;
    changeHandler(target.value);
  };
  render() {
    const { classes, currentValue, disabled, rows } = this.props;
    return (
      <div>
        <TextField
          id="outlined-textarea"
          label=""
          placeholder=""
          multiline
          // rows={rows || 2}
          // rowsMax={4}
          InputProps={{
            className: classes.SandBoxDescriptionInput,
            underline: classes.underline,
          }}
          className={classes.SandBoxDescription}
          margin="normal"
          onChange={this.changeHandler}
          value={currentValue || ''}
          disabled={disabled}
        />
      </div>
    );
  }
}

SandBoxDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
};

SandBoxDescription.displayName = 'SandBoxDescription';

export default withStyles(descriptionStyles)(SandBoxDescription);
