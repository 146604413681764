import React, { SetStateAction, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { DropdownItemModel } from '../../../models/global';
import { Select } from '../Dropdowns/DropdownСomponents';
import { FileModel, IVisibilityFlag, UserProfileModel } from '../../../models';
import PlaceholderInfo from '../PlaceholderInfo/PlaceholderInfo';
import { TeamApi } from '../../../service/Api/team/types';
import ChipsDiscipline from '../Chips/ChipsDiscipline/ChipsDiscipline';
import moment from 'moment';
import DateField from '../DateField/DateField';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import { PCDApi } from '../../../service/Api/pcd/types';
import ReviewStatusesType = PCDApi.ReviewStatuses;
import Divider from '../Divider/Divider';
import { Popover } from '@mui/material';
import { FileDrop } from 'react-file-drop';
import TokenIcon from '../TokenIcon/TokenIcon';
import FilesListViewTokens from '../FilesListViewTokens/FilesListViewTokens';
import CustomButton from '../ButtonComponents/CustomButton/CustomButton';
import { TextQuillEditor } from '../TextQuillEditor';

import cn from 'classnames';
import './ReviewSnippetStyles.scss';
import CustomTooltip from '../Tooltip/Tooltip';
import FileUploader from '../FileUploader/FileUploader';

interface IProps {
  type: 'card' | 'portable' | 'mobile';
  selectedPriority: number;
  selectedUserProfile: UserProfileModel;
  reviewerUserProfile: UserProfileModel;
  updateDate: string;
  companyInfo: PCDApi.ReviewCompanyModel;
  disciplineInfo: TeamApi.IDiscipline;
  dueDate: string;
  reviewNote: string;
  selectedReviewStatus: ReviewStatusesType;
  setSelectedReviewStatus: (status: ReviewStatusesType) => void;
  isReviewEditable: boolean;
  selectedFiles: FileModel[];
  handleFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  handleDownloadAll: (e: any) => void;
  loadingFilesName: string[];
  loadingReview: boolean;
  reviewDescription: string;
  setReviewDescription: (value: string) => void;
  tooltip: string;
  showSaveButton: boolean;
  saveReview: () => void;
  selectedRequest?: any;
  isSubmittal?: boolean;
  moreLimitFiles: FileModel[] | FileList | any;
  handleMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
  isSidePeek?: boolean;
  isAlertIcon?: boolean;
  onAlertClick?: () => void;
}

const reviewStatusesDeliverable = [
  { value: 'no_exceptions', title: 'Reviewed - no exception taken' },
  { value: 'exceptions', title: 'Reviewed - exceptions as noted' },
  { value: 'revise_resubmit', title: 'Revise & resubmit' },
  { value: 'no_action', title: 'No review required' },
  { value: 'response_required', title: 'Response required' },
];

const reviewStatusesSubmittal = [
  { value: 'no_exceptions', title: 'Reviewed - no exception taken' },
  { value: 'exceptions', title: 'Reviewed - exception as noted' },
  { value: 'record_only', title: 'For Record Only' },
  { value: 'rejected', title: 'Rejected' },
  { value: 'revise_resubmit', title: 'Revise & Resubmit' },
  { value: 'no_action', title: 'No Action Taken' },
  { value: 'response_required', title: 'Response required' },
];

const noteWidthByType = {
  card: 287,
  portable: 244,
};

const userInfoWidthByType = {
  card: 364,
  portable: 244,
};

const ReviewerSnippet = ({
  type,
  selectedPriority,
  selectedUserProfile,
  reviewerUserProfile,
  updateDate,
  companyInfo,
  disciplineInfo,
  dueDate,
  reviewNote,
  selectedReviewStatus,
  setSelectedReviewStatus,
  isReviewEditable,
  selectedFiles,
  handleFile,
  delSavedFile,
  handleDownloadAll,
  loadingFilesName,
  loadingReview,
  reviewDescription,
  setReviewDescription,
  tooltip,
  showSaveButton,
  saveReview,
  selectedRequest,
  isSubmittal,
  moreLimitFiles,
  handleMoreLimitFiles,
  isSidePeek,
  isAlertIcon,
  onAlertClick,
}: IProps) => {
  const themeClass = useGetThemeClass(`b-reviewerSnippet-${type}`);
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const offset = new Date().getTimezoneOffset();

  const [menuOpenType, setMenuOpenType] = useState<'file' | 'note' | ''>('');
  const [anchorEl, setAnchorEl] = useState(null);

  const reviewStatuses = useMemo(() => {
    return isSubmittal ? reviewStatusesSubmittal : reviewStatusesDeliverable;
  }, [isSubmittal]);

  const isPortable = useMemo(() => {
    return type === 'portable';
  }, [type]);

  const isMobile = useMemo(() => {
    return type === 'mobile';
  }, [type]);

  const selectedReviewStatusObject = useMemo(() => {
    return reviewStatuses.find(status => status.value === selectedReviewStatus);
  }, [selectedReviewStatus]);

  const widthOfNoteByPriorityAndType = useMemo(() => {
    if (isMobile) {
      return '100%';
    }

    if (type === 'card') {
      return noteWidthByType[type];
    }

    return noteWidthByType[type] - (selectedPriority - 1) * 40;
  }, [selectedPriority, type, isMobile]);

  const widthUserInfoByPriorityAndType = useMemo(() => {
    if (type === 'portable' || isMobile) {
      return '';
    }

    return userInfoWidthByType[type] - (selectedPriority - 1) * 40;
  }, [selectedPriority, type, isMobile]);

  const snippetBorderClass = useMemo(() => {
    if (isReviewEditable && selectedReviewStatus === 'response_required') {
      const currentDate = new Date().getTime();
      const dueDateParsed = Date.parse(dueDate);

      if (dueDateParsed > currentDate) {
        return '-valid';
      } else {
        return '-danger';
      }
    }

    return '';
  }, [dueDate, selectedReviewStatus, isReviewEditable]);

  const isOverdueDueDate = useMemo(() => {
    const currentDate = new Date().getTime();
    const dueDateParsed = Date.parse(dueDate);

    return dueDateParsed <= currentDate;
  }, [dueDate]);

  const filteredReviewStatuses = useMemo(() => {
    return reviewStatuses.filter(status => status.value !== 'response_required');
  }, [reviewStatuses]);

  const openMenu = (e: MouseEvent, type: 'file' | 'note') => {
    setMenuOpenType(type);
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuOpenType('');
    setAnchorEl(null);
  };

  const handleChangeFile = (e: any, isDrag?: boolean, isRestrict?: boolean) => {
    handleFile(e, isDrag, isRestrict);
  };

  const handleChange = value => {
    setReviewDescription && setReviewDescription(value);
  };

  const renderMainInfo = () => {
    return (
      <div style={{ width: widthUserInfoByPriorityAndType }} className={`${themeClass}_mainInfo`}>
        <div className={`${themeClass}_mainInfo_companyUser`}>
          <PlaceholderInfo type={'Company'} imagePath={companyInfo?.renderings?.file} detailInfo={companyInfo.company || ''} size={24} />
          {selectedUserProfile ? (
            <PlaceholderInfo
              type={'Persona'}
              firstName={selectedUserProfile?.first_name}
              lastName={selectedUserProfile?.last_name}
              imagePath={selectedUserProfile?.image}
              detailInfo={`${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
              size={24}
            />
          ) : null}
        </div>
        <div className={`${themeClass}_mainInfo_discipline`}>
          {disciplineInfo ? (
            <ChipsDiscipline isMobile={isMobile} type={'filled'} color={disciplineInfo.text_color} value={disciplineInfo.title} />
          ) : null}
        </div>
      </div>
    );
  };

  const renderReviewNote = () => {
    return (
      <div style={{ width: widthOfNoteByPriorityAndType }} className={`${themeClass}_reviewInfo_note`}>
        {!isSubmittal && (
          <>
            <span className={`${themeClass}_reviewInfo_label`}>Note to reviewer</span>
            <span className={`${themeClass}_reviewInfo_text`}>{reviewNote || ''}</span>
          </>
        )}
      </div>
    );
  };

  const renderReviewInfo = () => {
    const reviewedByText = reviewerUserProfile
      ? `${reviewerUserProfile?.first_name} 
                ${reviewerUserProfile?.last_name} 
                ${moment(updateDate).add('minutes', -offset).format('hh:mm A, MM/DD/YYYY')}`
      : '';

    return (
      <div className={`${themeClass}_reviewInfo`}>
        <div className={`${themeClass}_reviewInfo_dueDate`}>
          <span className={`${themeClass}_reviewInfo_label`}>Review due date:</span>
          {dueDate ? (
            <span className={`${themeClass}_reviewInfo_text ${isOverdueDueDate ? '-danger' : ''}`}>{`${moment(dueDate)
              .add('minutes')
              .format('ll')}`}</span>
          ) : null}
        </div>
        {!isPortable ? renderReviewNote() : null}
        <div className={`${themeClass}_reviewInfo_reviewer`}>
          <span className={`${themeClass}_reviewInfo_label`}>Reviewed by</span>
          <span className={`${themeClass}_reviewInfo_text`}>
            <CustomTooltip title={reviewedByText || ''} placement={'bottom'}>
              {reviewedByText}
            </CustomTooltip>
          </span>
        </div>
        {isPortable ? renderReviewNote() : null}
      </div>
    );
  };

  const renderStatusAdditions = () => {
    return (
      <CustomTooltip title={tooltip || ''} placement={'bottom'}>
        <div className={`${themeClass}_statusAdditions`}>
          {isAlertIcon && (
            <SystemButton
              clickHandler={() => onAlertClick && onAlertClick()}
              type={'alert'}
              size={'md'}
              variant={'transparent'}
              isMobile={isMobile}
            />
          )}
          <SystemButton
            clickHandler={e => openMenu(e, 'file')}
            count={selectedFiles?.length}
            type={'attachment'}
            size={'md'}
            variant={'transparent'}
            isMobile={isMobile}
          />
          <SystemButton
            isMobile={isMobile}
            clickHandler={e => openMenu(e, 'note')}
            tooltip={'Note'}
            type={'information'}
            size={'md'}
            variant={'transparent'}
            emptyBadge={!(!reviewDescription || reviewDescription.split(' ').join('') === '<p></p>' || reviewDescription === '<p><br></p>')}
          />
          <div className={`${themeClass}_statusAdditions_reviewStatuses`}>
            <Select<DropdownItemModel, null, false>
              isMulti={false}
              menuItems={filteredReviewStatuses}
              selectHandler={item => setSelectedReviewStatus(item.value)}
              selected={selectedReviewStatusObject}
              menuItemSize={'md'}
              disabled={!isReviewEditable}
              isMobile={isMobile}
            />
          </div>
          {showSaveButton && !isMobile ? (
            <SystemButton clickHandler={saveReview} tooltip={'Save review'} type={'save'} size={'md'} variant={'transparent'} />
          ) : null}
        </div>
      </CustomTooltip>
    );
  };

  const renderCompanyStatusAdditions = () => {
    return (
      <>
        <div className={`${themeClass}_companyStatusAdditions`}>
          <PlaceholderInfo type={'Company'} imagePath={companyInfo?.renderings?.file} detailInfo={companyInfo.company || ''} size={24} />
          {renderStatusAdditions()}
        </div>
      </>
    );
  };

  const renderUserDiscipline = () => {
    return (
      <div className={`${themeClass}_userDiscipline`}>
        {selectedUserProfile ? (
          <PlaceholderInfo
            type={'Persona'}
            firstName={selectedUserProfile?.first_name}
            lastName={selectedUserProfile?.last_name}
            imagePath={selectedUserProfile?.image}
            detailInfo={`${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
            size={24}
          />
        ) : null}
        {disciplineInfo ? <ChipsDiscipline type={'filled'} color={disciplineInfo.text_color} value={disciplineInfo.title} /> : null}
      </div>
    );
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          ['-disabled']: !isReviewEditable,
          ['-saveActive']: isReviewEditable && showSaveButton,
          [snippetBorderClass]: snippetBorderClass,
        })}
      >
        {isPortable ? (
          <>
            <div className={`${themeClass}_firstRow`}>
              {renderCompanyStatusAdditions()}
              {renderUserDiscipline()}
            </div>
            <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-5'} />
            {renderReviewInfo()}
          </>
        ) : null}
        {isMobile ? (
          <>
            {renderMainInfo()}
            {renderStatusAdditions()}
            <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-5'} />
            {renderReviewInfo()}
            {isReviewEditable && showSaveButton ? (
              <div className={`${themeClass}_saveBlock`}>
                <CustomButton
                  type={'primary'}
                  size={'md'}
                  isMobile={isMobile}
                  title={'Save'}
                  clickHandler={saveReview}
                  customClassName={`${themeClass}_saveBlock_button`}
                  customTooltipBasisClassName={`${themeClass}_saveBlock_buttonTooltip`}
                  loading={loadingReview}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {type === 'card' ? (
          <>
            {renderMainInfo()}
            {renderReviewInfo()}
            {renderStatusAdditions()}
          </>
        ) : null}
      </div>
      <Popover
        open={!!menuOpenType}
        onClose={closeMenu}
        anchorEl={anchorEl}
        classes={{
          paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClass}_editPaper`),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuOpenType === 'file' ? (
          <FileUploader
            isCard={true}
            handleChangeFile={handleChangeFile}
            collectFinalFiles={selectedFiles}
            isDisableFileList={!isReviewEditable}
            files={selectedFiles || []}
            selectedRequest={selectedRequest}
            source={isSubmittal ? 'Sub' : 'PCD'}
            cardType={isSubmittal ? 'submittal' : 'pcd'}
            isReview={true}
            isEditable={true}
            isEditMode={true}
            delSavedFile={delSavedFile}
            fileLoading={loadingReview}
            loadingFilesName={loadingFilesName}
            handleDownloadAll={handleDownloadAll}
            moreLimitFiles={moreLimitFiles}
            handleMoreLimitFiles={handleMoreLimitFiles}
            customFileUploaderClassName={`${themeClass}_customReviewSnippetFileUploader${isSidePeek ? '_sidePeekView' : ''}`}
          />
        ) : null}
        {menuOpenType === 'note' ? (
          <TextQuillEditor
            fieldId={'reviewSnippet'}
            value={reviewDescription}
            handleChange={handleChange}
            placeholder={'Review note'}
            disabled={!isReviewEditable}
            customContainerClassName={cn(`${themeClass}_noteEditor`)}
            error={null}
            stopFocus
          />
        ) : null}
      </Popover>
    </>
  );
};

export default ReviewerSnippet;
