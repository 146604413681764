import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbsPayload, UpdateActiveBreadcrumbsPayload } from '../../models';
import { IBreadcrumbsReducer } from './breadcrumbsReducer.model';

const initialState: IBreadcrumbsReducer = {
  firstCardBreadcrumbs: [],
  secondCardBreadcrumbs: [],
  previewCardBreadcrumbs: [],
};

export const breadcrumbsSlice = createSlice({
  initialState,
  name: 'breadcrumbReducer',
  reducers: {
    clearBreadcrumbs(state, { payload }: PayloadAction<BreadcrumbsPayload>) {
      const { typeBreadcrumbs } = payload;
      state[typeBreadcrumbs] = [];
    },
    setBreadcrumbs(state, { payload }: PayloadAction<BreadcrumbsPayload>) {
      const { typeBreadcrumbs, array } = payload;
      const currentState = current(state);

      let breadcrumbs = [...currentState[typeBreadcrumbs]];

      const existedBreadcrumbIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.id === array[0].id);
      if (existedBreadcrumbIndex !== -1) {
        breadcrumbs.splice(existedBreadcrumbIndex, 1);
      }

      const existedNewNfIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.id === array[1].id);
      if (existedNewNfIndex !== -1) {
        breadcrumbs.splice(existedNewNfIndex, 1);
      }

      breadcrumbs = breadcrumbs.concat(array);

      state[typeBreadcrumbs] = breadcrumbs;
    },
    setNewBreadcrumbs(state, { payload }: PayloadAction<BreadcrumbsPayload>) {
      const { typeBreadcrumbs, array } = payload;
      state[typeBreadcrumbs] = array;
    },
    updateActiveTabBreadcrumbs(state, { payload }: PayloadAction<UpdateActiveBreadcrumbsPayload>) {
      const { typeBreadcrumbs, data } = payload;

      const currentState = current(state);
      state[typeBreadcrumbs] = currentState[typeBreadcrumbs].map(item => (item.id === data.id ? { ...item, activeTab: data.tab } : item));
    },
  },
});

export default breadcrumbsSlice.reducer;
