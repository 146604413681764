import React from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import cn from 'classnames';
import { ItemsCounter } from '../../ItemsСounter';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import MiniLoader from '../../MiniLoader/MiniLoader';
import CustomTooltip from '../../Tooltip/Tooltip';
import StatusNFsnippet from '../../Status/StatusNFsnippet/StatusNFsnippet';
import { AllSectionType } from '../../../common/SandBox/SandBoxConstants/SandBoxConstants';

import './TabComponent.module.scss';

const AllSectionTypeMappings = {
  request: 'Need Form',
  deliverable: 'Deliverable',
  submittalInput: 'Input submittal',
  submittalOutput: 'Output submittal',
};

interface IProps extends TabProps {
  label: string;
  value: number | string;
  counter: number;
  activeSectionType: AllSectionType;
  isCountersLoading: boolean;
  disabled: boolean;
  type?: string;
  customClass?: string;
  toolTipText?: string;
  isNfCard?: boolean;
  isMobile?: boolean;
}

const TabComponent = (props: IProps) => {
  const {
    counter,
    activeSectionType,
    selected,
    label,
    value,
    onChange,
    isCountersLoading,
    disabled,
    type,
    customClass,
    toolTipText,
    isNfCard,
    isMobile,
  } = props;
  const themeClass = useGetThemeClass('tab');
  const tooltipTypeName = AllSectionTypeMappings[activeSectionType];

  const renderCounter = () => {
    if (isNfCard) {
      return (
        <CustomTooltip title={toolTipText} placement={'top'}>
          <ItemsCounter value={counter} size={'sm'} />
        </CustomTooltip>
      );
    }

    return (
      <CustomTooltip
        enterDelay={500}
        leaveDelay={0}
        title={`The counters are showing the number of unique items in the group. One ${tooltipTypeName} can require multiple actions and might appear in several groups.`}
        placement={'top'}
      >
        <ItemsCounter value={counter} size={isMobile ? 'mobile' : 'sm'} />
      </CustomTooltip>
    );
  };

  return (
    // @ts-ignore
    <div
      onClick={event => (disabled ? null : onChange(event, value))}
      className={cn(`${themeClass}_container`, {
        [`${customClass}`]: customClass,
        ['-selected']: selected && !isCountersLoading,
        ['-disabled']: !!isCountersLoading || disabled,
      })}
    >
      <div
        className={cn(`${themeClass}_btnContainer`, {
          ['-project']: type === 'project',
          ['-mobile']: isMobile,
        })}
      >
        <Tab classes={{ root: `${themeClass}_root` }} disableRipple label={label} value={value} />
      </div>
      {isCountersLoading && (
        <div className={'downloadBoxLoading'}>
          <MiniLoader size={'sm'} circleClasses={cn(`${themeClass}_loader`, { ['-selected']: selected })} />
        </div>
      )}
      {counter > 0 && renderCounter()}
    </div>
  );
};

export default TabComponent;
