interface IProps {
  rm_id: number;
  title: string;
  value: string;
}

export const redmineTaskTrackers: IProps[] = [
  {
    rm_id: 4,
    title: 'Irregular Task',
    value: 'Irregular Task',
  },
  {
    rm_id: 1,
    title: 'Modeling',
    value: 'Modeling',
  },
  {
    rm_id: 24,
    title: 'iRFI Incorporation',
    value: 'iRFI Incorporation',
  },
];
