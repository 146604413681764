import React, { FC, useEffect, useRef, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { LocationCollectionModel, RequestBuildingModel, SimpleTitleModel, TagModel } from '../../../../models';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';
import UniFormatChips from './parts/UniFormatChips';
import LocationList from './parts/LocationList';
import { NLDeliverablesModel, NLUfcModel, NLSubmittalsModel } from '../../../../store/needlist/needListModels';
import HashTags from './parts/HashTags';
import ReactResizeDetector from 'react-resize-detector';
import { ImpactModel } from '../../ImpactSelect/ImpactSelect';
import ImpactsList from './parts/ImpactsList';
import { HIGH_RESOLUTION_CLASS } from '../../../../constants/screenResolutionConstants';

import './ShowMoreLocationsStyles.module.scss';

interface IProps {
  items:
    | RequestBuildingModel[]
    | LocationCollectionModel[]
    | SimpleTitleModel[]
    | TagModel[]
    | NLDeliverablesModel[]
    | NLUfcModel[]
    | NLSubmittalsModel[]
    | ImpactModel[];
  typeMoreLocations?: string;
  isThumbnailView?: boolean;
  isFullWidth?: boolean;
  isUfcDescription?: boolean;
  isUfcTooltip?: boolean;
}

const ShowMoreLocations: FC<IProps> = ({
  items,
  typeMoreLocations,
  isThumbnailView,
  isFullWidth,
  isUfcDescription = true,
  isUfcTooltip = false,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isNotContain, setIsNotContain] = useState(false);
  const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [linkedTag, setLinkedTag] = useState<TagModel>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef(null);

  useEffect(() => {
    if (!isThumbnailView && items?.length > 1) {
      setIsNotContain(true);
    } else {
      if (containerRef?.current && elementRef?.current) {
        const blockHeight = containerRef?.current?.offsetHeight;
        const elementHeight = elementRef?.current?.offsetHeight;

        if (blockHeight < (elementHeight + 6) * items?.length - 16) {
          setIsNotContain(true);
        }
      }
    }
  }, []);

  const themeClass = useGetThemeClass('showMoreLocation');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const changeView = e => {
    e.preventDefault();
    setIsShowMore(!isShowMore);
  };

  const handleOpenLinkPopover = (e, tag) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLinkPopoverOpen(true);
    setAnchorElFilter(e.currentTarget);
    setLinkedTag(tag);
  };

  const handleCloseLinkPopover = () => {
    setIsLinkPopoverOpen(false);
    setAnchorElFilter(null);
  };

  const handleResize = (width: number, height: number) => {
    let elementsWidth = 0;
    containerRef.current.querySelectorAll('.Badge').forEach(el => {
      elementsWidth += el.getBoundingClientRect().width;
    });
    if (width - 16 > elementsWidth + 13) {
      // 16px - padding; 13px - show more button
      setIsNotContain(false);
    } else {
      setIsNotContain(true);
    }
  };

  const renderItems = [...items];

  if (isNotContain && isThumbnailView) {
    renderItems.splice(3);
  }

  return (
    <>
      <ReactResizeDetector targetRef={containerRef} handleWidth={true} refreshMode={'throttle'} onResize={handleResize} />
      <div className={cn(`${themeClass}_container`, { '-isThumbnailView': isThumbnailView })} ref={containerRef}>
        {typeMoreLocations === 'hashtags' && (
          <HashTags data={renderItems} elementRef={elementRef} themeClass={themeClass} handleOpenLinkPopover={handleOpenLinkPopover} />
        )}
        {typeMoreLocations === 'ufc' && (
          <UniFormatChips
            data={renderItems}
            elementRef={elementRef}
            themeClass={themeClass}
            isShowDescription={isUfcDescription}
            isShowTooltip={isUfcTooltip}
          />
        )}
        {!typeMoreLocations && (
          <LocationList
            isFullWidth={isFullWidth}
            data={renderItems}
            elementRef={elementRef}
            themeClass={themeClass}
            handleOpenLinkPopover={handleOpenLinkPopover}
          />
        )}
        {typeMoreLocations === 'impacts' && (
          <ImpactsList data={renderItems} isFullWidth={isFullWidth} themeClass={themeClass} elementRef={elementRef} />
        )}
        {isNotContain && (
          <div onClick={changeView} className={`${themeClass}_container_showMoreLink`}>
            ...
          </div>
        )}
        <Popover
          id={'hashtag-link'}
          open={isLinkPopoverOpen}
          anchorEl={anchorElFilter}
          onClose={handleCloseLinkPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={HIGH_RESOLUTION_CLASS}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              marginTop: '15px',
              height: 'auto',
              background: 'transparent',
              boxShadow: 'none',
              borderRadius: 0,
              marginLeft: typeMoreLocations === 'hashtags' ? -53 : 0,
            },
          }}
        >
          <div className={'linkEditor'}>
            <div className={`linkEditor__content -link`}>
              <div className={'linkEditor__href'}>
                <span>Visit URL:</span>
                <a
                  rel="noreferrer"
                  href={linkedTag?.link.includes('https://') ? `${linkedTag?.link}` : `https://${linkedTag?.link}`}
                  target="_blank"
                  className={'linkEditor__link'}
                >
                  {linkedTag?.link}
                </a>
              </div>
            </div>
          </div>
        </Popover>
      </div>
      <Popover
        open={!!isShowMore}
        anchorEl={containerRef.current}
        onClose={changeView}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={`${themeClass}_popUp`}>
          {typeMoreLocations === 'hashtags' && (
            <HashTags data={items} elementRef={elementRef} themeClass={themeClass} handleOpenLinkPopover={handleOpenLinkPopover} />
          )}
          {typeMoreLocations === 'ufc' && (
            <UniFormatChips
              data={items}
              elementRef={elementRef}
              themeClass={themeClass}
              isShowDescription={isUfcDescription}
              isShowTooltip={isUfcTooltip}
            />
          )}
          {typeMoreLocations === 'impacts' && (
            <ImpactsList data={renderItems} isFullWidth={isFullWidth} themeClass={themeClass} elementRef={elementRef} />
          )}
          {!typeMoreLocations && <LocationList data={items} elementRef={elementRef} themeClass={themeClass} />}
        </div>{' '}
      </Popover>
    </>
  );
};

export default ShowMoreLocations;
