import React, { Component } from 'react';
import NotificationsTable from './NotificationsTable/NotificationsTable';
import NotificationsListPanel from './NotificationsListPanel/NotificationsListPanel';
import compose from 'recompose/compose';
import { connector } from '../NotificationsList/connector';
import { withStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EmptyData from '../EmptyData/EmptyData';
import LinearDeterminate from '../../controls/LinearDeterminate/LinearDeterminate';
import { withRouterHelper } from '../../../navigation/withRouterHelper/withRouterHelper';

const mqPad = window.matchMedia('(max-width: 768px)');

const styles = theme => ({
  scrollToTop: {
    position: 'fixed',
    right: '2%',
    bottom: '60px',
    borderRadius: '50%',
    backgroundColor: 'var(--background-grid)',
    height: '40px',
    cursor: 'pointer',
    zIndex: '2',
    // border: '1px #fff solid',
  },
  topButton: {
    width: '40px',
    height: '40px',
    color: 'rgb(97, 118, 159)',
  },
});

class NotificationsList extends Component {
  constructor(props) {
    super(props);
    this.logoText = 'Activity';
    this.state = {
      fixedHeader: false,
      hasMore: false,
      page: 1,
      ref: React.createRef(),
      searchValue: '',
      formData: {},
      conditions: {},
      containsFilter: {},
      isChangeFilter: false,
      filterHeight: 36,
      requestClickOutSide: false,
      escapeKeyDown: false,
    };
    this.notificationPanel = React.createRef();
  }

  componentDidMount() {
    this.props.getNotificationsFilters();
    this.props.loadProjectData();
    this.props.getNotificationsList({ page: 1, ...this.state.query, isNew: true });
  }

  handleSetSeen = item => () => {
    this.props.getProjectDetails(item.project_id);
    //TODO this deleted query
    // if (!item.is_seen) {
    //   this.props.setNotificationsSeen([item.id]);
    // }
    if (item.request_id && item.alias !== 'requestDeleted') {
      this.props.routerHelper.setUrlNF(item.nf);
      this.props.setNfOpenId(item.request_id);
      this.props.singleRequestLoading({ requestId: item.request_id });
      this.setState({
        request_id: item.request_id,
      });
    }
  };

  getMoreData = () => {
    const formData = { ...this.state.formData };
    const { containsFilter } = this.state;
    const { searchValue } = this.state;
    const filters =
      Object.values(formData).length > 0
        ? {
            ...formData,
          }
        : {};
    this.props.getNotificationsList({
      filters: filters,
      conditions: containsFilter,
      search: searchValue,
      sorting: '',
      page: this.props?.allNotifications?.notificationsInfo?.current_page + 1,
    });
  };

  onScroll = e => {
    this.setState({
      isShowScroll: e.target.scrollTop > window.screen.height - 600,
    });
  };

  handleShowMore = item => e => {
    e.stopPropagation();
    if (this.state.openedNotification !== item) {
      this.setState({
        openedNotification: item,
      });
    } else {
      this.setState({
        openedNotification: null,
      });
    }
  };

  closeRequest = () => {
    this.props.routerHelper.removeParams();
    this.setState({ request_id: null });
  };

  openRequest = request_id => {
    this.setState({ request_id });
  };
  handleReadAll = () => {
    this.props.readAll();
  };

  handleChangeSearch = ({ target }) => {
    this.setState({
      searchValue: target.value,
    });
  };
  handleChangeMore = values => {
    this.setState({
      selectedFilters: values,
    });
  };
  handleChangeSelect = (value, name) => {
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    if (!value.length) {
      delete formData[name];
    }
    this.setState({
      formData: formData || {},
      isChangeFilter: true,
    });
  };
  handleCloseSelect = () => {
    const formData = { ...this.state.formData };
    const { containsFilter, isChangeFilter, searchValue } = this.state;
    const filters =
      Object.values(formData).length > 0
        ? {
            ...formData,
          }
        : {};

    if (isChangeFilter) {
      this.props.getNotificationsList({
        filters: filters,
        conditions: containsFilter,
        search: searchValue,
        sorting: '',
        page: 1,
        isNew: true,
      });
    }
    this.setState({
      isChangeFilter: false,
    });
  };
  handleResetFilter = () => {
    this.setState({
      selectedFilters: [],
      formData: {},
      conditions: {},
      containsFilter: {},
      searchValue: '',
    });
    this.props.getNotificationsFilters();
    this.props.getNotificationsList({ filters: {}, conditions: {}, sorting: '', page: 1, isNew: true });
  };
  handleRemoveFilter = filterName => {
    const { containsFilter } = this.state;
    const formData = {
      ...this.state.formData,
    };
    const selectedFilters = [...this.state.selectedFilters];
    const removedIndex = selectedFilters.findIndex(f => f === filterName);
    if (removedIndex > -1) {
      selectedFilters.splice(removedIndex, 1);
    }
    delete formData[filterName];
    this.setState({
      formData: formData || {},
      selectedFilters: selectedFilters || [],
    });

    delete containsFilter[filterName];
    const filters =
      Object.values(formData).length > 0
        ? {
            ...formData,
          }
        : {};
    this.props.getNotificationsList({ filters: filters, conditions: containsFilter, sorting: '', page: 1, isNew: true });
  };

  handleChangeContains = (value, filterId, filterName) => {
    let resValue = value;
    const containsFilter = {
      ...this.state.containsFilter,
      [filterName]: {
        ...this.state.containsFilter[filterName],
        [filterId]: resValue,
      },
    };

    this.setState({
      containsFilter: containsFilter,
    });
  };

  handleSelectCondition = (filterName, filtersAlias) => {
    const { containsFilter } = this.state;
    const conditions = {
      ...this.state.conditions,
      [filterName]: filtersAlias,
    };

    this.setState({
      conditions: conditions,
    });

    const newContainsFilter = {};
    filtersAlias.forEach(f => {
      if (!containsFilter[filterName]) {
        return;
      }
      newContainsFilter[f] = containsFilter[filterName][f];
    });

    const containsFilterState = {
      ...this.state.containsFilter,
      [filterName]: newContainsFilter,
    };

    this.setState({
      containsFilter: containsFilterState,
    });
  };

  handleChangeSearch = ({ target }) => {
    this.setState({
      searchValue: target.value,
    });
  };

  onChangeFilters = () => {
    const formData = { ...this.state.formData };
    const { containsFilter } = this.state;
    const { searchValue } = this.state;
    const filters =
      Object.values(formData).length > 0
        ? {
            ...formData,
          }
        : {};
    this.props.getNotificationsList({
      filters: filters,
      conditions: containsFilter,
      search: searchValue,
      sorting: '',
      page: 1,
      isNew: true,
    });
  };

  onChangeFiltersHeight = height => {
    this.setState({
      filterHeight: height,
    });
  };

  closeRequestClickOutSide = () => {
    if (localStorage.getItem('requestClickOutSide') === 'yes') {
      this.closeRequest();
    }
    this.setState({
      requestClickOutSide: true,
    });
  };
  closeRequestClickOutSideR = () => {
    this.setState({
      requestClickOutSide: false,
    });
  };
  closeRequestEscapeKeyDown = () => {
    if (localStorage.getItem('requestHeadClose') === 'yes') {
      this.closeRequest();
    }
    this.setState({
      escapeKeyDown: true,
    });
  };
  closeRequestEscapeKeyDownR = () => {
    this.setState({
      escapeKeyDown: false,
    });
  };

  handleGetSpecialReport = () => {
    const { getCurrentProjectSpecialReport } = this.props;

    const nowDate = new Date().toISOString().slice(0, 19);
    const project_name = sessionStorage.getItem('project_name');
    const id = Number(sessionStorage.getItem('active_project_id'));

    getCurrentProjectSpecialReport({ id: id, filename: `activity_project_${project_name}_special_report_${nowDate}.csv` });
  };

  render() {
    const { allNotifications, notificationsLoading, classes, userData } = this.props;

    const { isShowScroll, filterHeight } = this.state;

    const itemForRender = allNotifications.notificationsInfo.notifications ? [...allNotifications.notificationsInfo.notifications] : [];
    const handleClick = () => {
      this.state.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    };
    const hasMore =
      this.props?.allNotifications?.notificationsInfo?.current_page !== this.props?.allNotifications?.notificationsInfo?.last_page;
    const isAdmin = userData.userInfo.roles && !!Object.keys(userData.userInfo.roles).filter(key => key === '1' || key === '4').length;

    return (
      <div className={'NotificationsList'}>
        <div className={'linearProgress'}>
          <LinearDeterminate isActive={notificationsLoading} />
        </div>
        {isShowScroll ? (
          <div onClick={() => handleClick()} className={classes.scrollToTop}>
            <ExpandLessIcon className={classes.topButton} />
          </div>
        ) : null}
        <NotificationsListPanel
          logoText={this.logoText}
          panel={this.notificationPanel}
          fixedHeader={this.state.fixedHeader}
          handleChangeFilters={this.handleChangeFilters}
          handleReadAll={this.handleReadAll}
          notificationFilters={this.props.allNotifications.notificationFilters}
          getNotificationsList={this.props.getNotificationsList}
          active_project_id={this.props.userData.active_project_id}
          selectedFilters={this.state.selectedFilters}
          conditions={this.state.conditions}
          containsFilter={this.state.containsFilter}
          formData={this.state.formData}
          searchValue={this.state.searchValue}
          handleChangeSearch={this.handleChangeSearch}
          handleChangeMore={this.handleChangeMore}
          handleChangeSelect={this.handleChangeSelect}
          handleCloseSelect={this.handleCloseSelect}
          handleRemoveFilter={this.handleRemoveFilter}
          handleChangeContains={this.handleChangeContains}
          handleSelectCondition={this.handleSelectCondition}
          onChangeFilters={this.onChangeFilters}
          onChangeFiltersHeight={this.onChangeFiltersHeight}
          handleResetFilter={this.handleResetFilter}
          handleGetSpecialReport={this.handleGetSpecialReport}
          isAdmin={isAdmin}
        />
        <div className={'b-table__container'}>
          <InfiniteScroll
            dataLength={itemForRender.length}
            next={this.getMoreData}
            hasMore={hasMore}
            onScroll={this.onScroll}
            height={
              !(window.innerWidth < 767 || window.innerWidth < 1024)
                ? `calc(100svh - 103px - ${filterHeight || 0}px)`
                : `calc(100svh - 173px - ${filterHeight || 0}px)`
            }
          >
            <div ref={this.state.ref}></div>
            <div className={'activity'}>
              <NotificationsTable
                items={itemForRender}
                // setSeen={this.handleSetSeen}
                fixedHeader={this.state.fixedHeader}
                isShowScroll={this.state.isShowScroll}
                hasMore={hasMore}
                getMoreData={this.getMoreData}
                handleSetSeen={this.handleSetSeen}
                handleShowMore={this.handleShowMore}
                openedNotification={this.state.openedNotification}
              />
            </div>

            {itemForRender.length === 0 && !this.props.notificationsLoading ? <EmptyData /> : null}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

NotificationsList.displayName = 'NotificationsList';

export default withRouterHelper(compose(connector, withStyles(styles))(NotificationsList));
