import React, { useRef } from 'react';
import ProjectTable from './parts/ProjectTable';
import ProjectGrid from './parts/ProjectGrid';
import PageHeader from '../../controls/PageHeader/PageHeader';
import { ContentSwitcher, CustomButton } from '../../controls/ButtonComponents';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './ProjectStyles.scss';
import './ProjectPageStyles.scss';

function ProjectView({
  handleCreateProject,
  isAdmin,
  projects,
  activeTab,
  handleChangeView,
  handleClickOpenEdit,
  projectTypes,
  currentClient,
  updateProject,
  getClientsProjects,
  selectProject,
}) {
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const themeClass = useGetThemeClass('b-projectsPage');

  return (
    <div className={'b-project'}>
      <PageHeader
        ref={pageHeaderRef}
        title={'Projects'}
        isDivided
        customRightSideClass={`${themeClass}_header_right`}
        renderLeftSide={() => <></>}
        renderRightSide={() => (
          <>
            <CustomButton
              title={'Create project'}
              size={'sm'}
              type={'secondary'}
              icon={<TokenIcon iconName={'plus'} size={12} customClass={`${themeClass}_header_right_icon`} />}
              clickHandler={handleCreateProject}
              disabled={!isAdmin}
            />
          </>
        )}
      />
      <div className={`${themeClass}_table`}>
        {projects && projects?.length === 1 && projects[0].id === 0 ? (
          <div>No projects yet</div>
        ) : (
          <>
            {activeTab === 0 && (
              <ProjectTable
                isAdmin={isAdmin}
                items={projects}
                handleClickOpenEdit={handleClickOpenEdit}
                projectTypes={projectTypes}
                selectProject={selectProject}
                pageHeaderHeight={pageHeaderRef?.current?.getBoundingClientRect()?.height}
              />
            )}
            {activeTab === 1 && (
              <ProjectGrid
                isAdmin={isAdmin}
                items={projects}
                handleClickOpenEdit={handleClickOpenEdit}
                projectTypes={projectTypes}
                currentClient={currentClient}
                updateProject={updateProject}
                getClientsProjects={getClientsProjects}
                selectProject={selectProject}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProjectView;
