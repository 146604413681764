import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { documentsSlice } from '../../../../../store/documents/documentsReducer';
import { useClickOutside } from '../../../../../helpers/commonHooks';
import { setStatusNF } from '../../../../../store/documents/documentsThunk';
import { deactivatedStatus } from '../../../../../store/request/requestLogic(HOLD)';
import { getHistory } from '../../../../../store/history/historyThunk';
import ButtonView from '../../../../controls/ButtonComponents/ButtonView/ButtonView';
import ManageStatuses from '../ManageStatuses/ManageStatuses';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import CardRenderSelect from '../CardRenderSelect/CardRenderSelect';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { RequestModel } from '../../../../../models';
import { IDocumentListDocuments } from '../../../../../store/documents/documentReducer.model';
import { getFeed } from '../../../../../store/comments/commentsThunk';
import Popover from '@material-ui/core/Popover';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';

import './CardRenderDesignInputStyles.scss';
import '../../mainParts/Header/HeaderStyles.module.scss';

const designInputGroups = [
  { value: 'Assigned Design Documents', title: 'Assigned Design Documents' },
  { value: 'Design Documents', title: 'Design Documents' },
];

function notContain(list, selected) {
  return list?.filter(value => value.id !== selected.id);
}

function notContainMultiple(list, selected) {
  return list?.filter(value => !selected?.find(selectedItem => selectedItem.id === value.id)) || [];
}

export type TransferListType = {
  group: 'Assigned Design Documents' | 'Design Documents';
  current_set: string;
  exist: boolean;
  id: number;
  number: string;
  title: string;
  condition: string;
  status: string | null;
};

type animationType = { doc_id: number; btn: string };

interface Props {
  selectedRequestId: number;
  isHandleSelectDraw: boolean;
  listDocumentsProps: IDocumentListDocuments[];
  listDocumentsNoNfProps: IDocumentListDocuments[];
  set_status_nf_loading: boolean;
  isAccess: boolean;
  isAccessToRemove: boolean;
  statusRequest: string;
  selectedRequest: RequestModel;
  list_documents_loading: boolean;
  buildings: number[];
  selectedBuildings: number[];
  add_documents_nf: number[];
  getSetStatus: (status: string, type?: string) => ReactNode;
  onMenuClose: () => void;
  isMenuOpen?: boolean;
  isDisableMinWidth?: boolean;
  disabled: boolean;
  isMobile?: boolean;
  isHighResolution?: boolean;
  isNeedListEditMode?: boolean;
  isSidePeekView?: boolean;
  handleCloseAllNotificationPopups?: () => void;
  isAllNotificationPopupsClosed?: boolean;
}

const CardRenderDesignInput: React.FC<Props> = ({
  selectedRequestId,
  isHandleSelectDraw,
  listDocumentsProps,
  listDocumentsNoNfProps,
  set_status_nf_loading,
  isAccess,
  statusRequest,
  selectedRequest,
  list_documents_loading,
  isAccessToRemove,
  buildings,
  selectedBuildings,
  add_documents_nf,
  getSetStatus,
  onMenuClose,
  isMenuOpen,
  isDisableMinWidth,
  disabled,
  isMobile,
  isNeedListEditMode = false,
  isSidePeekView,
  handleCloseAllNotificationPopups,
  isAllNotificationPopupsClosed,
  isHighResolution,
}) => {
  const topCardPartThemeClass = useGetThemeClass('b-cardRequest-topCardPart');
  const themeClass = useGetThemeClass('b-cardRequest-designInputSelect');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPopUpDeactivation = useGetThemeClass('b-popupDeactivation');

  const dispatch = useAppDispatch();
  const { addDocumentNF } = documentsSlice.actions;

  const sandBox = useAppSelector((state: any) => state.requestReducer.sandBox?.data);

  const is_deactivated = sandBox ? sandBox[selectedRequestId]?.is_deactivated : selectedRequest?.is_deactivated;

  const [bottom, setBottom] = useState<TransferListType[]>(null);
  const [top, setTop] = useState<TransferListType[]>(null);
  const [originalTop, setOriginalTop] = useState<TransferListType[]>(null);

  const [menuItems, setMenuItems] = useState<TransferListType[]>([]);

  const [topNotVerified, setTopNotVerified] = useState<TransferListType[]>(null);
  const [selectedNotVerified, setSelectedNotVerified] = useState<TransferListType[]>(null);
  const [prevSelectedNotVerified, setPrevSelectedNotVerified] = useState<TransferListType[]>(null);
  const [topNotValid, setTopNotValid] = useState<TransferListType[]>(null);
  const [isBtnNotValid, setBtnIsNotValid] = useState<boolean>(false);
  const [listDocuments, setListDocuments] = useState(null);
  const [listDocumentsNoNf, setListDocumentsNoNf] = useState(null);
  const [listDocumentsNF, setListDocumentsNF] = useState(null);

  const [isCloseReactivate, setIsCloseReactivate] = useState<boolean>(false);
  const [isDeactivationPopUpOpen, setIsDeactivationPopUpOpen] = useState<boolean>(false);

  const [animation, setAnimation] = useState<animationType>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearchValue, setIsSearchValue] = useState<string>('');
  const [isNotVerifiedItemsPopup, setIsNotVerifiedItemsPopup] = useState<boolean>(false);
  const [isNotValidItemsPopup, setIsNotValidItemsPopup] = useState<boolean>(false);
  const [isClosedNotValidPopUp, setIsClosedNotValidPopUp] = useState<boolean>(false);
  const [isNotDDList, setIsNotDDList] = useState<boolean>(true);
  const [designDocumentListIsOpen, setDesignDocumentListIsOpen] = useState(false);

  const [isSelectedBuildings, setIsSelectedBuildings] = useState<boolean>(false);
  const [isHandleSaveDDList, setIsHandleSaveDDList] = useState<boolean>(false);
  const [differentStatus, setDifferentStatus] = useState<boolean>(false);

  const notValidItemsPopupRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isAllNotificationPopupsClosed) {
      handleCloseNotValidItemsPopup();
    }
  }, [isAllNotificationPopupsClosed]);

  const handleCloseNotVerifiedItemsPopup = () => {
    setIsNotVerifiedItemsPopup(false);
    setSelectedNotVerified(null);
  };

  const handleCloseNotValidItemsPopup = () => {
    setIsNotValidItemsPopup(false);
    setIsClosedNotValidPopUp(true);
    handleCloseAllNotificationPopups && handleCloseAllNotificationPopups();
  };

  useEffect(() => {
    if (isHandleSaveDDList) {
      setIsHandleSaveDDList(false);
      if (differentStatus) {
        setIsDeactivationPopUpOpen(true);
      }
    }

    if (Object.values(listDocumentsProps || {})?.length) {
      const listDocumentsPropsArray = Object.values(listDocumentsProps);

      const listDocuments = listDocumentsPropsArray.map(item => {
        return { ...item, group: item?.exist ? 'Assigned Design Documents' : 'Design Documents' };
      });
      setListDocuments({ ...listDocuments });
    } else {
      setListDocuments(null);
    }
  }, [listDocumentsProps]);

  useEffect(() => {
    if (Object.values(listDocumentsNoNfProps || {})?.length) {
      const listDocumentsNoNfPropsArray = Object.values(listDocumentsNoNfProps);

      const listDocumentsNoNf = listDocumentsNoNfPropsArray.map(item => {
        return { ...item, group: item?.exist ? 'Assigned Design Documents' : 'Design Documents' };
      });
      setListDocumentsNoNf({ ...listDocumentsNoNf });
    } else {
      setListDocumentsNoNf(null);
    }
  }, [listDocumentsNoNfProps]);

  useEffect(() => {
    setBtnIsNotValid(false);
    setTopNotVerified(null);
    setTopNotValid(null);
    setIsCloseReactivate(false);
  }, [selectedRequestId]);

  useEffect(() => {
    if (topNotValid?.length) {
      setIsNotValidItemsPopup(true);
    } else {
      setIsNotValidItemsPopup(false);
    }
  }, [topNotValid]);

  useEffect(() => {
    if (!set_status_nf_loading) {
      if (animation?.btn == 'No') {
        setTimeout(() => {
          setIsNotVerifiedItemsPopup(true);
        }, 1000);
      }
    }
  }, [set_status_nf_loading]);

  useEffect(() => {
    if (list_documents_loading) {
      setIsNotDDList(true);
    } else {
      setIsNotDDList(false);
    }

    if (selectedBuildings.length > 0) {
      setIsNotDDList(false);
    }
  }, [list_documents_loading, selectedBuildings]);

  useEffect(() => {
    if (is_deactivated == 1) {
      setBtnIsNotValid(true);
      setIsCloseReactivate(false);
    } else {
      setBtnIsNotValid(false);
    }
  }, [is_deactivated, selectedRequestId]);

  useEffect(() => {
    if (!isNotDDList) {
      let transferListData = [];

      if (selectedRequestId) {
        transferListData = listDocuments ? getlistDocuments(listDocuments, selectedBuildings) : [];
      } else {
        transferListData = listDocumentsNoNf ? getlistDocuments(listDocumentsNoNf, selectedBuildings) : [];
      }
      const data = listDocumentsNF ? listDocumentsNF : {};
      const sR_id = selectedRequestId ? selectedRequestId : 0;
      if (!isNotDDList && transferListData.length > 0) {
        data[sR_id] = transferListData;
        setListDocumentsNF({ ...data });
      }
    } else {
      const data = listDocumentsNF ? listDocumentsNF : {};
      const sR_id = selectedRequestId ? selectedRequestId : 0;
      data[sR_id] = [];

      setListDocumentsNF(data);
    }
  }, [listDocuments, listDocumentsNoNf, isNotDDList]);

  useEffect(() => {
    const sR_id = selectedRequestId ? selectedRequestId : 0;
    const transferListData = listDocumentsNF && listDocumentsNF[sR_id];
    if (transferListData && transferListData?.length !== 0) {
      if (transferListData && (!isHandleSelectDraw || isSelectedBuildings)) {
        const list_bottom = transferListData?.filter(item => !item?.exist);
        const list_top = transferListData?.filter(item => item?.exist);

        const list_top_no = list_top?.filter(item => item?.status == 'not_verified');
        const list_top_not_valid = list_top?.filter(item => item?.status == 'not_valid');
        setTopNotVerified(list_top_no);
        setTopNotValid(list_top_not_valid);
        setBottom([...notContainMultiple(list_bottom, list_top)]);
        setTop(list_top);
        setMenuItems([...([...notContainMultiple(list_bottom, list_top)] || []), ...(list_top || [])]);

        setOriginalTop(list_top);
        setIsSelectedBuildings(false);
      } else {
        setTopNotVerified(null);
        setTopNotValid(null);
        setBottom(null);
        setTop(null);
        setMenuItems([]);

        setOriginalTop(null);
      }
    }
  }, [listDocumentsNF, isNotDDList, selectedRequestId]);

  useEffect(() => {
    if (topNotVerified?.length > 1) {
      setSelectedNotVerified(null);
      setIsNotVerifiedItemsPopup(true);
    } else {
      setSelectedNotVerified(topNotVerified || null);
      setIsNotVerifiedItemsPopup(true);
    }
  }, [topNotVerified]);

  useEffect(() => {
    let transferListData = [];
    if (selectedRequestId) {
      transferListData = listDocuments ? getlistDocuments(listDocuments, selectedBuildings) : [];
    } else {
      transferListData = listDocumentsNoNf ? getlistDocuments(listDocumentsNoNf, selectedBuildings) : [];
    }
    const data = listDocumentsNF ? { ...listDocumentsNF } : {};
    const sR_id = selectedRequestId ? selectedRequestId : 0;
    if (transferListData.length > 0) {
      data[sR_id] = transferListData;
      setListDocumentsNF(data);
    }
    setIsSelectedBuildings(true);
  }, [selectedBuildings?.length]);

  const handleOpenDDManageStatuses = () => {
    setDesignDocumentListIsOpen(true);
    setIsNotValidItemsPopup(false);
    setIsNotVerifiedItemsPopup(false);
    setSelectedNotVerified(null);
    setIsClosedNotValidPopUp(true);
  };

  const handleCloseDDList = () => {
    setDesignDocumentListIsOpen(false);
  };

  const handleSaveDDList = list => {
    const validItems = [];
    const notValidItems = [];
    const ignoreItems = [];
    list.map(item => (item.validity_status === 'valid' ? validItems.push(item.id) : item));
    list.map(item => (item.validity_status === 'not_valid' ? notValidItems.push(item.id) : item));
    list.map(item => (item.validity_status === 'ignore' ? ignoreItems.push(item.id) : item));

    validItems.length && handleOnYes(validItems);
    notValidItems.length && handleOnNo(notValidItems, true);
    ignoreItems.length && handleOnIgnore(ignoreItems);
    if (selectedRequest.status !== 'drafted') {
      setIsHandleSaveDDList(true);
      setDifferentStatus(!!notValidItems.length);
    }
    handleCloseDDList();
  };

  const handleOpenDDItem = (value?) => {
    if (!selectedRequest?.parent_id) {
      if (value) {
        setSelectedNotVerified([value]);
        if (prevSelectedNotVerified?.length && prevSelectedNotVerified[0].id === value.id) {
          setIsNotVerifiedItemsPopup(false);
          setPrevSelectedNotVerified(null);
          setSelectedNotVerified(null);
          return;
        }
        if (!isNotVerifiedItemsPopup) {
          setIsNotVerifiedItemsPopup(true);
        }
      } else {
        setSelectedNotVerified(null);
        setPrevSelectedNotVerified(null);
        setIsNotVerifiedItemsPopup(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setPrevSelectedNotVerified(selectedNotVerified);
    }, 500);
  }, [selectedNotVerified, isNotVerifiedItemsPopup]);

  const handleChangeSearch = (searchValue, customSearch?, currentTop?) => {
    if (!searchValue && !customSearch) {
      setSearchValue('');
      setBottom([
        ...notContainMultiple(
          listDocuments ? getlistDocuments(listDocuments, selectedBuildings) : getlistDocuments(listDocumentsNoNf || [], selectedBuildings),
          [...((currentTop ? currentTop : top) || [])],
        ).map(item => {
          return { ...item, group: 'Design Documents' };
        }),
      ]);
      setTop([...((currentTop ? currentTop : top) || [])]);

      setMenuItems([
        ...notContainMultiple(
          listDocuments ? getlistDocuments(listDocuments, selectedBuildings) : getlistDocuments(listDocumentsNoNf || [], selectedBuildings),
          [...((currentTop ? currentTop : top) || [])],
        ).map(item => {
          return { ...item, group: 'Design Documents' };
        }),
        ...(currentTop ? currentTop : top || []),
      ]);
    } else {
      const values = [
        ...notContainMultiple(
          listDocuments ? getlistDocuments(listDocuments, selectedBuildings) : getlistDocuments(listDocumentsNoNf || [], selectedBuildings),
          [...((currentTop ? currentTop : top) || [])],
        ),
      ];
      const targetValue = customSearch ? customSearch : searchValue;
      const inputValue = targetValue?.trim().toLowerCase();
      if (inputValue) {
        const newValuesNumber = values?.filter(v => {
          if (v['number']?.trim().toLowerCase().includes(inputValue)) {
            return true;
          } else {
            return false;
          }
        });

        const newValuesTitle = values?.filter(v => {
          if (v['title']?.trim().toLowerCase().includes(inputValue)) {
            return true;
          } else {
            return false;
          }
        });

        const newValuesNumberTitle = values?.filter(v => {
          if ((v['number'] + ' - ' + v['title'])?.trim().toLowerCase().includes(inputValue)) {
            return true;
          } else {
            return false;
          }
        });

        const newValuesCurrentSet = values?.filter(v => {
          if (v['current_set']?.trim().toLowerCase().includes(inputValue)) {
            return true;
          } else {
            return false;
          }
        });
        if (newValuesNumber?.length > 0) {
          setBottom(
            newValuesNumber.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          );
          setMenuItems([
            ...((currentTop ? currentTop : top) || []),
            ...newValuesNumber.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          ]);
          setIsSearchValue('number');
        } else if (newValuesTitle?.length > 0) {
          setBottom(
            newValuesTitle.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          );

          setMenuItems([
            ...((currentTop ? currentTop : top) || []),
            ...newValuesTitle.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          ]);

          setIsSearchValue('title');
        } else if (newValuesNumberTitle?.length > 0) {
          setBottom(
            newValuesNumberTitle.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          );
          setMenuItems([
            ...((currentTop ? currentTop : top) || []),
            ...newValuesNumberTitle.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          ]);

          setIsSearchValue('number_title');
        } else if (newValuesCurrentSet?.length > 0) {
          setBottom(
            newValuesCurrentSet.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          );
          setMenuItems([
            ...((currentTop ? currentTop : top) || []),
            ...newValuesCurrentSet.map(item => {
              return { ...item, group: 'Design Documents' };
            }),
          ]);

          setIsSearchValue('set');
        } else {
          setBottom(null);
          setMenuItems([...((currentTop ? currentTop : top) || [])]);
        }

        setSearchValue(targetValue);
      }
    }
  };

  const getlistDocuments = (listDocs, selectedBuildings) => {
    const listDocsArray: TransferListType[] = Object.values(listDocs);
    let selectedListBuildings =
      selectedBuildings?.length > 0
        ? listDocsArray.filter(doc => {
            const custom_array = doc.building_ids.filter(item => {
              const isFind = selectedBuildings.findIndex(value => value.id === item.id) > -1;
              if (isFind) {
                return true;
              } else {
                return false;
              }
            });

            return custom_array.length > 0;
          })
        : listDocsArray;

    selectedListBuildings = selectedListBuildings.map(item => {
      if (top && top.length) {
        if (item.exist && top.map(el => el.id).includes(item.id)) {
          return { ...item, group: 'Assigned Design Documents' };
        } else {
          return { ...item, group: 'Design Documents' };
        }
      } else {
        if (item.exist) {
          return { ...item, group: 'Assigned Design Documents' };
        } else {
          return { ...item, group: 'Design Documents' };
        }
      }
    });
    return selectedListBuildings;
  };

  const handleOnYes = (id: number | number[]) => {
    const doc_id = typeof id === 'number' ? [id] : id;
    const data = {
      document_id: doc_id,
      status: 'valid',
    };
    // @ts-ignore
    setAnimation({ doc_id: doc_id || doc_id[0], btn: 'Yes' });
    setSelectedNotVerified(null);
    setPrevSelectedNotVerified(null);
    setIsNotVerifiedItemsPopup(false);
    setIsNotValidItemsPopup(false);
    dispatch(setStatusNF({ data: data, selectedRequestId: selectedRequestId }));
  };

  const handleOnIgnore = (id: number | number[]) => {
    const doc_id = typeof id === 'number' ? [id] : id;
    const data = {
      document_id: doc_id,
      status: 'ignore',
    };
    // @ts-ignore
    setAnimation({ doc_id: doc_id || doc_id[0], btn: 'Yes' });
    setSelectedNotVerified(null);
    setPrevSelectedNotVerified(null);
    setIsNotVerifiedItemsPopup(false);
    setIsNotValidItemsPopup(false);
    dispatch(setStatusNF({ data: data, selectedRequestId: selectedRequestId }));
  };

  const handleOnNo = (id: number | number[], isManageStatuses: boolean) => {
    const doc_id = typeof id === 'number' ? [id] : id;
    const data = {
      document_id: doc_id,
      status: 'not_valid',
    };
    // @ts-ignore
    setAnimation({ doc_id: doc_id || doc_id[0], btn: 'No' });
    if (!isManageStatuses && selectedRequest.status !== 'drafted') {
      setIsHandleSaveDDList(true);
      setDifferentStatus(true);
    }
    dispatch(setStatusNF({ data: data, selectedRequestId: selectedRequestId }));

    setSelectedNotVerified(null);
    setPrevSelectedNotVerified(null);
    setIsNotVerifiedItemsPopup(false);
  };

  const handleReactivateYes = () => {
    dispatch(deactivatedStatus({ request_id: selectedRequestId, action: 'activate' }));

    dispatch(getFeed({ owner_id: selectedRequestId }));
  };

  const handleReactivateNo = () => {
    const data = {
      document_id: [topNotVerified[0].id],
      status: 'not_valid',
    };

    dispatch(setStatusNF({ data: data, selectedRequestId: selectedRequestId }));
    setIsCloseReactivate(true);
  };

  const handleReactivateIgnore = () => {
    const data = {
      document_id: [topNotVerified[0].id],
      status: 'ignore',
    };

    dispatch(setStatusNF({ data: data, selectedRequestId: selectedRequestId }));
    setIsCloseReactivate(true);
  };

  const handleSetBottom = (items: TransferListType[]) => {
    setBottom(items);
  };

  const handleSetTop = (items: TransferListType[]) => {
    setTop(items);
  };

  const handleRemove = val => {
    const topChecked = { ...val, group: val.group === 'Assigned Design Documents' ? 'Design Documents' : 'Assigned Design Documents' };
    const bottom_arr = [...bottom.concat(topChecked)];
    const bottom_arr_sort = bottom_arr.sort((a, b) => {
      return a.id - b.id;
    });
    handleSetBottom(bottom_arr_sort);

    const docsNf = [...notContain(top, topChecked)];
    setMenuItems([...(bottom_arr_sort || []), ...(docsNf || [])]);

    const setTopDocs = docsNf.map(item => item.id);
    const setTopOriginalDocs = originalTop.map(item => item.id);

    dispatch(addDocumentNF({ document_id: setTopDocs, original_doc_id: setTopOriginalDocs }));

    handleSetTop(docsNf);
  };

  const getBuildingsTitle = building_ids => {
    const buildings_title = building_ids
      .map(build => {
        const isFindIndex = buildings.findIndex(value => value.id === build);
        if (isFindIndex > -1) {
          return buildings[isFindIndex].title;
        } else {
          return null;
        }
      })
      .filter(f => !!f)
      .join(', ');
    return buildings_title ? `${buildings_title}` : '';
  };

  const renderRightText = (item: DropdownItemModel) => {
    return <div className={`${themeClass}_rightText`}>{`${item.current_set}`}</div>;
  };

  const renderLeftIcon = (item: DropdownItemModel) => {
    return (
      <>
        {item.group === 'Assigned Design Documents' ? (
          <div className={`${themeClass}_statusIcon`}>{getSetStatus(item.status || '', 'designInput')}</div>
        ) : null}
      </>
    );
  };

  const handleCheckedTop = value => {
    const bottomChecked = { ...value, group: 'Assigned Design Documents' };
    const docsNf = top.concat(bottomChecked);
    setMenuItems([...(notContain(bottom, bottomChecked) || []), ...(docsNf || [])]);

    handleSetTop(docsNf);
    const setTopDocs = docsNf.map(item => item.id);
    const setOriginalTop = originalTop.map(item => item.id);

    dispatch(addDocumentNF({ document_id: setTopDocs, original_doc_id: setOriginalTop }));

    handleSetBottom(notContain(bottom, bottomChecked));
  };

  const handleCheckedBottom = value => {
    const topChecked = { ...value, group: 'Design Documents' };
    const bottom_arr = bottom.concat(topChecked);
    const bottom_arr_sort = bottom_arr.sort((a, b) => {
      return a.id - b.id;
    });

    handleSetBottom(bottom_arr_sort);

    const docsNf = [...notContain(top, topChecked)];
    setMenuItems([...(bottom_arr_sort || []), ...(docsNf || [])]);

    const setTopDocs = docsNf.map(item => item.id);
    const setOriginalTop = originalTop.map(item => item.id);

    dispatch(addDocumentNF({ document_id: setTopDocs, original_doc_id: setOriginalTop }));

    handleSetTop(docsNf);

    handleChangeSearch(false, searchValue, docsNf);
  };

  const clearTop = () => {
    const bottom_arr = [...(menuItems || [])];
    const bottom_arr_sort = bottom_arr
      ?.map(el => {
        return { ...el, group: 'Design Documents' };
      })
      ?.sort((a, b) => {
        return a.id - b.id;
      });
    handleSetBottom(bottom_arr_sort);
    setMenuItems(bottom_arr_sort);

    const docsNf = [];
    const setTopDocs = docsNf.map(item => item.id);
    const setOriginalTop = originalTop.map(item => item.id);
    dispatch(addDocumentNF({ document_id: setTopDocs, original_doc_id: setOriginalTop }));
    handleSetTop([]);
  };

  const handleSelectDDItem = (selectedItems, elem) => {
    if (!selectedItems?.length) {
      return clearTop();
    }
    if (selectedItems?.length < top.length) {
      return handleCheckedBottom(elem);
    }
    if (selectedItems?.length > top.length) {
      return handleCheckedTop(elem);
    }
  };

  const renderDesignInputTooltipText = () => {
    if (localStorage.getItem('show_tooltips') === 'no' || (add_documents_nf && add_documents_nf.length > 0)) {
      return '';
    } else {
      return <div style={{ textAlign: 'center' }}>Design Documents impacting this request</div>;
    }
  };

  const cancelDeactivatedStatus = () => {
    setIsDeactivationPopUpOpen(false);
  };

  const handleDeactivateNf = () => {
    dispatch(
      deactivatedStatus({
        request_id: selectedRequest.id,
        action: 'deactivate',
        callback: () => setIsDeactivationPopUpOpen(false),
      }),
    );
  };

  return (
    <>
      <div className={`${themeClass}_selectContainer`} ref={notValidItemsPopupRef}>
        <CardRenderSelect<DropdownItemModel, DropdownGroupItem, true>
          type={'design input'}
          isMulti={true}
          disableSelectAll={true}
          isHeader
          menuItems={menuItems.map(item => ({ ...item, sortingValue: `${item?.number || ''} - ${item?.title || ''}` }))}
          groups={designInputGroups}
          selectHandler={(items, elem) => handleSelectDDItem(items, elem)}
          selected={top}
          isLoading={list_documents_loading || set_status_nf_loading}
          basisPlaceholder={'Select Design drawings'}
          menuItemSize={'md'}
          basisMaxItems={isSidePeekView ? 4 : 6}
          customMenuClass={`${topCardPartThemeClass}_parentSelectWidth ${themeClass}_groupTitle`}
          menuGroupingType={'labeled'}
          menuItemLeftElemRenderer={renderLeftIcon}
          menuItemRightTextRenderer={renderRightText}
          customMenuItemClass={`${themeClass}_designInputDropdown`}
          getBuildingsTitle={getBuildingsTitle}
          handleOpenDDManageStatuses={isNeedListEditMode ? null : handleOpenDDManageStatuses}
          handleOpenDDItem={handleOpenDDItem}
          onChangeSearch={handleChangeSearch}
          handleRemoveDDItem={handleRemove}
          isAccess={isAccess}
          isAccessToRemove={isAccessToRemove}
          onMenuOpen={() => handleChangeSearch(false, '')}
          basisTooltip={renderDesignInputTooltipText()}
          onMenuClose={onMenuClose}
          isOpenMenu={isMenuOpen}
          isDisableMinWidth={isDisableMinWidth}
          disabled={disabled}
          isMobile={isMobile}
          isSelectMenuClose={isDeactivationPopUpOpen}
          isHighResolution={isHighResolution}
        />
      </div>
      {topNotVerified?.length > 0 && isNotVerifiedItemsPopup && !isNotValidItemsPopup && !isBtnNotValid && !selectedRequest?.parent_id && (
        <Popover
          id={'not-verified'}
          open={!!isNotVerifiedItemsPopup}
          anchorEl={notValidItemsPopupRef?.current}
          onClose={handleCloseNotVerifiedItemsPopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
        >
          {!isBtnNotValid &&
            !selectedRequest?.parent_id &&
            selectedNotVerified?.map((item, i) => {
              return (
                <div key={`${item.id}`} className={`${themeClass}_notVerifiedPopup`}>
                  <div className={`${themeClass}_notVerifiedPopup_messageBlock`}>
                    <span className={`${themeClass}_notVerifiedPopup_messageBlock_title`}></span>
                    {item?.condition === 'excluded' ? (
                      <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>
                        Document
                        <span className={`marked`}>{` ${item.number} - ${item.title} `}</span>
                        was excluded. Is this NF still valid?
                      </span>
                    ) : (
                      <>
                        <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>
                          Drawing <span className={`marked`}>{` ${item.number} `}</span> was updated in set
                          <span className={`marked`}> {` ${item.current_set}`}</span>
                        </span>
                        {statusRequest === 'Response Accepted' ? (
                          <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Reopen this NF?</span>
                        ) : (
                          <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Is this NF still Valid?</span>
                        )}
                      </>
                    )}
                  </div>
                  <div className={`${themeClass}_notVerifiedPopup_btnContainer`}>
                    <CustomButton
                      icon={<TokenIcon iconName={'cancel-right'} size={16} />}
                      type={'text-plain'}
                      size={'md'}
                      clickHandler={() => handleOnIgnore(item.id)}
                      title={'Ignore'}
                      disabled={!isAccess}
                      loading={set_status_nf_loading && animation?.doc_id == item?.id && animation?.btn == 'Yes'}
                    />
                    <CustomButton
                      icon={<TokenIcon iconName={'validDDL'} size={16} />}
                      type={'text-plain'}
                      size={'md'}
                      clickHandler={() => handleOnYes(item.id)}
                      title={'Valid'}
                      disabled={!isAccess}
                      loading={set_status_nf_loading && animation?.doc_id == item?.id && animation?.btn == 'Yes'}
                    />
                    <CustomButton
                      icon={<TokenIcon iconName={'void'} size={16} />}
                      type={'text-plain'}
                      size={'md'}
                      clickHandler={() => handleOnNo(item.id, false)}
                      title={'Void'}
                      disabled={!isAccess}
                      loading={set_status_nf_loading && animation?.doc_id == item?.id && animation?.btn == 'No'}
                    />
                  </div>
                </div>
              );
            })}
          {!isBtnNotValid &&
            !topNotValid?.length &&
            !selectedNotVerified?.length &&
            topNotVerified?.length > 1 &&
            !selectedRequest?.parent_id && (
              <div className={`${themeClass}_notVerifiedPopup`}>
                <div className={`${themeClass}_notVerifiedPopup_messageBlock`}>
                  <span className={`${themeClass}_notVerifiedPopup_messageBlock_title`}>Documents assigned to this NF were updated</span>
                  <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Please review the changes.</span>
                </div>
                <div className={`${themeClass}_notVerifiedPopup_btnContainer`}>
                  <CustomButton
                    type={'primary'}
                    size={'md'}
                    clickHandler={handleOpenDDManageStatuses}
                    title={'Review'}
                    disabled={!isAccess}
                  />
                </div>
              </div>
            )}
        </Popover>
      )}
      {!isBtnNotValid && !!topNotValid?.length && isNotValidItemsPopup && !selectedRequest?.parent_id && !isClosedNotValidPopUp && (
        <Popover
          id={'not-valid'}
          open={!!isNotValidItemsPopup && !isDeactivationPopUpOpen}
          anchorEl={notValidItemsPopupRef?.current}
          onClose={handleCloseNotValidItemsPopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
        >
          <div className={`${themeClass}_notVerifiedPopup`}>
            <div className={`${themeClass}_notVerifiedPopup_messageBlock`}>
              <span className={`${themeClass}_notVerifiedPopup_messageBlock_title`}>This NF is outdated</span>
              <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Please update NF and review the changes.</span>
            </div>
            <div className={`${themeClass}_notVerifiedPopup_btnContainer`}>
              <CustomButton type={'primary'} size={'md'} clickHandler={handleOpenDDManageStatuses} title={'Review'} disabled={!isAccess} />
            </div>
          </div>
        </Popover>
      )}
      {isBtnNotValid && topNotVerified?.length > 0 && !isCloseReactivate && isNotVerifiedItemsPopup && !selectedRequest?.parent_id && (
        <Popover
          id={'not-verified-reactivate'}
          open={!!isNotVerifiedItemsPopup}
          anchorEl={notValidItemsPopupRef?.current}
          onClose={() => setIsNotVerifiedItemsPopup(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
        >
          {topNotVerified?.length > 0 && (
            <div className={`${themeClass}_notVerifiedPopup`}>
              <div className={`${themeClass}_notVerifiedPopup_messageBlock`}>
                <span className={`${themeClass}_notVerifiedPopup_messageBlock_title`}>The following documents changed:</span>
                <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>
                  <span className={`marked`}>
                    {topNotVerified
                      .map(item => {
                        return `${item.number} - ${item.title}`;
                      })
                      .join(', ')}
                  </span>
                </span>
                <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Do you want to reactivate this NF?</span>
              </div>
              <div className={`${themeClass}_notVerifiedPopup_btnContainer`}>
                <CustomButton
                  type={'accept'}
                  size={'sm'}
                  clickHandler={handleReactivateYes}
                  title={'Yes'}
                  disabled={!isAccess}
                  customClassName={`${themeClass}_customBtnContainer`}
                />
                <CustomButton
                  type={'decline'}
                  size={'sm'}
                  clickHandler={handleReactivateNo}
                  title={'No'}
                  disabled={!isAccess}
                  customClassName={`${themeClass}_customBtnContainer`}
                />
                <CustomButton
                  type={'secondary'}
                  size={'sm'}
                  clickHandler={handleReactivateIgnore}
                  title={'Ignore'}
                  disabled={!isAccess}
                  customClassName={`${themeClass}_customBtnContainer`}
                />
              </div>
            </div>
          )}
        </Popover>
      )}
      {designDocumentListIsOpen && (
        <ManageStatuses
          type={'designInput'}
          popUpIsOpen={designDocumentListIsOpen}
          designDocumentsList={top}
          handleClose={handleCloseDDList}
          handleSaveList={handleSaveDDList}
        />
      )}
      {isDeactivationPopUpOpen && (
        <DialogPopUp isCard open={isDeactivationPopUpOpen} onClose={cancelDeactivatedStatus} isOverlay={true}>
          <div className={`${themeClassPopUpDeactivation}`}>
            <div className={`${themeClassPopUpDeactivation}_header`}>
              <div className={`${themeClassPopUpDeactivation}_header_title`}>{`Deactivate NF# ${selectedRequest.nf}?`}</div>
            </div>
            <div className={`${themeClassPopUpDeactivation}_container`}>
              <div className={'dialog-text'}>This NF will become deactivated, you'll be able to activate it back.</div>
            </div>
            <div className={`${themeClassPopUpDeactivation}_button_group`}>
              <CustomButton type={'tertiary'} size={'md'} clickHandler={cancelDeactivatedStatus} title={'Cancel'} />
              <CustomButton type={'primary'} size={'md'} clickHandler={handleDeactivateNf} title={'Yes, deactivate'} />
            </div>
          </div>
        </DialogPopUp>
      )}
    </>
  );
};

export default CardRenderDesignInput;
