import React, { useEffect, useState } from 'react';
import { RequestModel, VersionModel } from '../../../../../../../models';
import moment from 'moment';
import CustomTooltip from '../../../../../../controls/Tooltip/Tooltip';
import { RequestVersions } from '../../../../../RequestCard/parts/RequestVersions/RequestVersions';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import PlaceholderInfo from '../../../../../../controls/PlaceholderInfo/PlaceholderInfo';
import DateRow from '../../../../../../controls/DateRow/DateRow';
import Divider from '../../../../../../controls/Divider/Divider';
import cn from 'classnames';
import CardLinkButton from '../../../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import { GetResolution } from '../../../../../../../helpers/ScreenResolution/GetResolution';

import './CommitmentVersions.module.scss';

interface IProps {
  selectedRequest: RequestModel;
  changeVersion: (id: number) => void;
  forOpen?: string;
}

export const CommitmentVersions = ({ selectedRequest, changeVersion, forOpen }: IProps) => {
  const { isMobile, is390Resolution } = GetResolution();
  const themeClass = useGetThemeClass('b-commitmentVersions');

  const [versions, setVersions] = useState<VersionModel[] | []>([]);
  const [downloadNF, setDownloadNF] = useState<VersionModel | RequestModel>(null);

  useEffect(() => {
    if (selectedRequest?.versions.length) {
      setVersions(selectedRequest.versions);
      return;
    }
    if (selectedRequest?.parent?.versions?.length) {
      setVersions(selectedRequest.parent.versions);
      return;
    }
  }, [selectedRequest]);

  const handleChangeVersion = (version: VersionModel | RequestModel) => {
    if (selectedRequest.version === version.id) {
      return;
    }
    changeVersion(version.id);
  };

  const handleDownloadNF = version => {
    setDownloadNF(version);
  };

  const closeDownloadNF = () => {
    setDownloadNF(null);
  };

  const parentVersion = () => {
    const revisionProvidedTitle = () => {
      if (!selectedRequest?.parent_id) {
        return selectedRequest?.lastUserStatus?.status && selectedRequest.lastUserStatus.status !== 'declined'
          ? `${selectedRequest?.lastUserStatus?.author ? `${selectedRequest?.lastUserStatus?.author}` : ''} on ${
              selectedRequest?.lastUserStatus?.date ? moment(selectedRequest.lastUserStatus.date).format('MM/DD/YYYY') : '__ /__ /____'
            }`
          : '';
      } else {
        return selectedRequest?.parent?.lastUserStatus?.status && selectedRequest.parent.lastUserStatus.status !== 'declined'
          ? `${selectedRequest?.parent?.lastUserStatus?.author ? `${selectedRequest?.parent?.lastUserStatus?.author}` : ''} on ${
              selectedRequest?.parent?.lastUserStatus?.date
                ? moment(selectedRequest.parent?.lastUserStatus.date).format('MM/DD/YYYY')
                : '__ /__ /____'
            }`
          : '';
      }
    };

    const isRevisionProvided = !selectedRequest?.parent_id
      ? selectedRequest?.lastUserStatus?.status &&
        selectedRequest.lastUserStatus.status !== 'declined' &&
        selectedRequest?.lastUserStatus?.author
        ? true
        : false
      : selectedRequest?.parent?.lastUserStatus?.status &&
        selectedRequest.parent.lastUserStatus.status !== 'declined' &&
        selectedRequest?.parent?.lastUserStatus?.author
      ? true
      : false;

    const revisionProvided = () => {
      if (!selectedRequest?.parent_id) {
        if (selectedRequest?.lastUserStatus?.status && selectedRequest.lastUserStatus.status !== 'declined') {
          if (selectedRequest?.lastUserStatus?.author) {
            const status =
              selectedRequest.lastUserStatus.status === 'submitted'
                ? 'commit'
                : selectedRequest.lastUserStatus.status === 'provided'
                ? 'response'
                : selectedRequest.lastUserStatus.status === 'accepted'
                ? 'complete'
                : selectedRequest.lastUserStatus.status;
            const firstName = selectedRequest.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.first_name;
            const lastName = selectedRequest.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.last_name;
            const imagePath = selectedRequest.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.image;
            return {
              firstName: firstName,
              lastName: lastName,
              imagePath: imagePath,
            };
          }
        }
      } else {
        if (selectedRequest?.parent?.lastUserStatus?.status && selectedRequest.parent.lastUserStatus.status !== 'declined') {
          if (selectedRequest?.parent?.lastUserStatus?.author) {
            const status =
              selectedRequest.parent.lastUserStatus.status === 'submitted'
                ? 'commit'
                : selectedRequest.parent.lastUserStatus.status === 'provided'
                ? 'response'
                : selectedRequest.parent.lastUserStatus.status === 'accepted'
                ? 'complete'
                : selectedRequest.parent.lastUserStatus.status;
            const firstName = selectedRequest?.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.first_name;
            const lastName = selectedRequest?.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.last_name;
            const imagePath = selectedRequest?.action_dates[status] && selectedRequest.action_dates[status]?.user?.profile?.image;
            return {
              firstName: firstName,
              lastName: lastName,
              imagePath: imagePath,
            };
          }
        }
      }
    };

    const revisionAcceptedTitle = () => {
      if (!selectedRequest?.parent_id) {
        return selectedRequest?.lastUserStatus?.status === 'accepted' ? 'Response accepted' : '';
      } else {
        return selectedRequest?.parent?.lastUserStatus?.status === 'accepted' ? 'Response accepted' : '';
      }
    };
    const revisionProvidedObj = revisionProvided();
    if (!selectedRequest?.parent_id) {
      return (
        <div
          key={selectedRequest?.id}
          className={cn({
            [`${themeClass}_listItem`]: !forOpen,
            [`${themeClass}_listItemDropdown`]: forOpen === 'Overflow' || forOpen === 'CardLinkButton',
            ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
          })}
          onClick={() => (forOpen === 'Overflow' ? handleDownloadNF(selectedRequest) : handleChangeVersion(selectedRequest))}
        >
          <div className={`${themeClass}_rowItem`}>
            <div
              className={cn(`${themeClass}_column`, {
                ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
              })}
            >
              <div className={cn(`${themeClass}_linkBtnBlock`)}>
                <CardLinkButton
                  type={'nf'}
                  size={'sm'}
                  title={`NF ${selectedRequest?.nf || ''} ${selectedRequest?.version ? `(${selectedRequest?.version})` : ''}`}
                  tooltip={`NF ${selectedRequest?.nf} (${selectedRequest?.version || ''})`}
                />
              </div>
              {forOpen === 'CardLinkButton' ? (
                <DateRow date={selectedRequest?.lastUserStatus?.date} showTime isMobile={isMobile} />
              ) : (
                <>
                  {forOpen !== 'Overflow' && isRevisionProvided ? (
                    <CustomTooltip placement="bottom" title={revisionProvidedTitle()}>
                      <div className={`${themeClass}_placeholderInfoDateRow -provided`}>
                        <PlaceholderInfo
                          type={'Persona'}
                          firstName={revisionProvidedObj?.firstName}
                          lastName={revisionProvidedObj?.lastName}
                          imagePath={revisionProvidedObj?.imagePath}
                          detailInfo={`${revisionProvidedObj?.firstName} ${revisionProvidedObj?.lastName}`}
                          size={24}
                          customClassName={`${themeClass}_cursor`}
                        />
                        <DateRow date={selectedRequest?.lastUserStatus?.date} showTime isMobile={isMobile} />
                      </div>
                    </CustomTooltip>
                  ) : null}
                  <CustomTooltip placement="bottom" title={revisionAcceptedTitle()}>
                    <div className={'decline_reason_text'}>{revisionAcceptedTitle()}</div>
                  </CustomTooltip>
                </>
              )}
            </div>
          </div>
          <RequestVersions version={selectedRequest} downloadNF={downloadNF} closeDownloadNF={closeDownloadNF} />
        </div>
      );
    } else {
      return (
        <div
          key={selectedRequest?.parent.id}
          className={cn({
            [`${themeClass}_listItem`]: !forOpen,
            [`${themeClass}_listItemDropdown`]: forOpen === 'Overflow' || forOpen === 'CardLinkButton',
            ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
          })}
        >
          <div
            className={`${themeClass}_rowItem`}
            onClick={() =>
              forOpen === 'Overflow' ? handleDownloadNF(selectedRequest?.parent) : handleChangeVersion(selectedRequest?.parent)
            }
          >
            <div
              className={cn(`${themeClass}_column`, {
                ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
              })}
            >
              <div className={cn(`${themeClass}_linkBtnBlock`)}>
                <CardLinkButton
                  type={'nf'}
                  size={'sm'}
                  title={`NF ${selectedRequest?.nf} (${
                    selectedRequest?.parent?.versions?.length ? selectedRequest.parent.versions.length : ''
                  })`}
                  tooltip={`NF ${selectedRequest?.nf} (${
                    selectedRequest?.parent?.versions?.length ? selectedRequest.parent.versions.length : ''
                  })`}
                />
              </div>
              {forOpen === 'CardLinkButton' ? (
                <DateRow date={selectedRequest?.lastUserStatus?.date} showTime isMobile={isMobile} />
              ) : (
                <>
                  {forOpen !== 'Overflow' && isRevisionProvided ? (
                    <CustomTooltip placement="bottom" title={revisionProvidedTitle()}>
                      <div className={`${themeClass}_placeholderInfoDateRow -provided`}>
                        <PlaceholderInfo
                          type={'Persona'}
                          firstName={revisionProvidedObj?.firstName}
                          lastName={revisionProvidedObj?.lastName}
                          imagePath={revisionProvidedObj?.imagePath}
                          detailInfo={`${revisionProvidedObj?.firstName} ${revisionProvidedObj?.lastName}`}
                          size={24}
                          customClassName={`${themeClass}_cursor`}
                        />
                        <DateRow date={selectedRequest?.lastUserStatus?.date} showTime isMobile={isMobile} />
                      </div>
                    </CustomTooltip>
                  ) : null}
                  <CustomTooltip placement="bottom" title={revisionAcceptedTitle()}>
                    <div className={'decline_reason_text'}>{revisionAcceptedTitle()}</div>
                  </CustomTooltip>
                </>
              )}
            </div>
          </div>
          <RequestVersions version={selectedRequest?.parent} downloadNF={downloadNF} closeDownloadNF={closeDownloadNF} />
        </div>
      );
    }
  };

  return (
    <div className={`${themeClass}`}>
      {forOpen === 'Overflow' || forOpen === 'CardLinkButton' ? null : (
        <div className={`${themeClass}_header`}>
          <div className={`${themeClass}_header_group`}>
            <div className={'headline'}>Version</div>
            <div className={'headline -provided'}>Response provided</div>
          </div>
          <div className={'headline -declined'}>Response declined</div>
        </div>
      )}
      <div className={`${themeClass}_container_items`}>
        {forOpen !== 'Overflow' && versions.length
          ? versions.map(version => {
              const revisionProvidedTitle = `${
                version.action_dates.response
                  ? `${version.action_dates.response?.user?.profile?.first_name || ''} ${
                      version.action_dates.response?.user?.profile?.last_name || ''
                    }`
                  : ''
              } on ${
                version.action_dates.response.date ? moment(version.action_dates.response.date).format('MM/DD/YYYY') : '__ /__ /____'
              }`;

              const revisionDeclinedTitle = `${
                version.action_dates.decline.date ? moment(version.action_dates.decline.date).format('MM/DD/YYYY') : '__ /__ /____'
              }${version.action_dates.decline?.decline_reason ? `, “${version.action_dates.decline.decline_reason || ''}”` : ``}`;

              return (
                <div
                  key={version.id}
                  className={cn({
                    [`${themeClass}_listItem`]: !forOpen,
                    [`${themeClass}_listItemDropdown`]: forOpen === 'Overflow' || forOpen === 'CardLinkButton',
                    ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
                  })}
                >
                  <div
                    className={`${themeClass}_rowItem`}
                    onClick={() => (forOpen === 'Overflow' ? handleDownloadNF(version) : handleChangeVersion(version))}
                  >
                    <div
                      className={cn(`${themeClass}_column`, {
                        ['-gap16']: !is390Resolution && (forOpen === 'Overflow' || forOpen === 'CardLinkButton'),
                      })}
                    >
                      <div className={cn(`${themeClass}_linkBtnBlock`)}>
                        <CardLinkButton
                          type={'nf'}
                          size={'sm'}
                          title={`NF ${version.nf} (${version.version})`}
                          tooltip={`NF ${version.nf} (${version.version})`}
                        />
                      </div>
                      {forOpen === 'Overflow' || forOpen === 'CardLinkButton' ? (
                        <div>
                          <DateRow date={version.action_dates.response.date} showTime isMobile={isMobile} />
                        </div>
                      ) : (
                        <CustomTooltip placement="bottom" title={revisionProvidedTitle}>
                          <div className={`${themeClass}_placeholderInfoDateRow -provided`}>
                            <PlaceholderInfo
                              type={'Persona'}
                              firstName={version.action_dates.response?.user?.profile?.first_name}
                              lastName={version.action_dates.response?.user?.profile?.last_name}
                              imagePath={version.action_dates.response?.user?.profile?.image}
                              detailInfo={`${version.action_dates.response?.user?.profile?.first_name} ${version.action_dates.response?.user?.profile?.last_name}`}
                              size={24}
                              customClassName={`${themeClass}_cursor`}
                            />
                            <DateRow date={version.action_dates.response.date} showTime isMobile={isMobile} />
                          </div>
                        </CustomTooltip>
                      )}
                      {forOpen === 'Overflow' || forOpen === 'CardLinkButton' ? null : (
                        <CustomTooltip placement="bottom" title={revisionDeclinedTitle}>
                          <div className={`${themeClass}_response_declined`}>
                            <div className={`${themeClass}_dateRow_declined`}>
                              <DateRow date={version.action_dates.decline.date} showTime isMobile={isMobile} />
                            </div>
                            <div className={`${themeClass}_container_declined -declined`}>
                              <div className={'divider_declined'}>
                                <Divider direction={'vertical'} type={'srf-4'} />
                              </div>
                              <div className={'decline_reason_text'}>{version.action_dates.decline.decline_reason}</div>
                            </div>
                          </div>
                        </CustomTooltip>
                      )}
                    </div>
                  </div>
                  <RequestVersions version={version} downloadNF={downloadNF} closeDownloadNF={closeDownloadNF} />
                </div>
              );
            })
          : null}
        {versions.length ? parentVersion() : null}
      </div>
    </div>
  );
};
