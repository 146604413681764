import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import DateField from '../../../controls/DateField/DateField';
import { TextInputNew } from '../../../controls';
import CustomCalendar from '../../../controls/CustomCalendar/CustomCalendar';
import { FilterSelectBasis } from '../../../controls/Dropdowns/CustomParts';
import { Select } from '../../../controls/Dropdowns/DropdownСomponents';
import CheckBox from '../../../controls/CheckBox/CheckBox';
import { DropdownItemModel } from '../../../../models/global';

import './DateSelectStyle.scss';

interface DateValueModelProps {
  date?: string | string[];
  filter?: string;
}

const DateValueModel = ({ date: initialDate, filter: initialFilter }: DateValueModelProps) => {
  const date = initialDate || undefined;
  const filter = initialFilter || undefined;

  return {
    date,
    filter,
  };
};

const groupingOptions = [
  {
    title: 'Is On',
    value: 'date_is',
  },
  {
    title: 'Is Between',
    value: 'range',
  },
  {
    title: 'Is On or Before',
    value: 'before',
  },
  {
    title: 'Is On or After',
    value: 'after',
  },
];

interface DateSelectProps {
  dataValue?: DateValueModelProps;
  handleApplyFilter: (formData: any) => void;
  changeGrouping?: (value: string) => void;
  selectedGrouping?: string;
  changeActiveOverdue?: (isActiveOverdue: boolean) => void;
  isActiveOverdue?: boolean;
  isShowOverdue?: boolean;
  menuCloseHandler?: () => void;
}

const DateSelect: React.FC<DateSelectProps> = ({
  dataValue: initialDataValue,
  handleApplyFilter,
  changeGrouping,
  selectedGrouping,
  changeActiveOverdue,
  isActiveOverdue,
  isShowOverdue,
  menuCloseHandler,
}) => {
  const [date, setDate] = useState(null);
  const [selectedGroupingValues, setSelectedGroupingValues] = useState([]);
  const [overdueIsActive, setOverdueIsActive] = useState(false);
  const [isCalendarValue, setIsCalendarValue] = useState(false);

  const themeClassDateField = useGetThemeClass('b-date-field');

  useEffect(() => {
    const { date } = DateValueModel(initialDataValue || {});
    setDate(getDate(date));
  }, [initialDataValue]);

  useEffect(() => {
    if (selectedGrouping) {
      const findIndex = groupingOptions.findIndex(item => item.value === selectedGrouping);
      if (findIndex > -1) {
        setSelectedGroupingValues(groupingOptions[findIndex]);
        selectedGrouping === 'range' && setIsCalendarValue(true);
      }
    }
  }, [selectedGrouping]);

  useEffect(() => {
    if (isActiveOverdue) {
      setOverdueIsActive(true);
    }
  }, [isActiveOverdue]);

  const getDate = date => {
    if (!date) {
      return null;
    }

    let dateValue;

    if (date && Array.isArray(date)) {
      dateValue = Array.isArray(date) ? date.map(d => new Date(d)) : null;
    } else {
      dateValue = new Date(date);
    }

    return dateValue;
  };

  const selectAtDateHandler = (date, fromCalendar?: boolean) => {
    if (fromCalendar) {
      setIsCalendarValue(true);
    }
    const formData = {};
    if (date && Array.isArray(date)) {
      formData['date'] = date.map(d => moment(d).format('YYYY-MM-DD'));
    } else {
      if (date) {
        formData['date'] = moment(date).format('YYYY-MM-DD');
      } else {
        return;
      }
    }
    handleApplyFilter(formData);
    setDate(date);
  };

  const handleChangeGrouping = values => {
    setDate(null);
    setSelectedGroupingValues(values);
    setIsCalendarValue(false);
    changeGrouping && changeGrouping(values);
  };

  const renderCustomGroupingBasis = (isActive: boolean, disabled: boolean): JSX.Element => {
    return <FilterSelectBasis title={selectedGroupingValues?.title || groupingOptions[0].title} disabled={disabled} isActive={isActive} />;
  };

  const handleActiveOverdue = () => {
    changeActiveOverdue && changeActiveOverdue(!overdueIsActive);
    setOverdueIsActive(!overdueIsActive);
  };

  let dateRender: string | null, firstDate: string | null, secondDate: string | null;

  if (date && isCalendarValue) {
    if (selectedGroupingValues?.value === 'range') {
      if (date.length) {
        firstDate = moment(date[0]).format('MM/DD/YYYY');
        secondDate = moment(date[1]).format('MM/DD/YYYY');
      } else {
        firstDate = null;
        secondDate = null;
      }
    } else {
      dateRender = moment(date).format('MM/DD/YYYY');
    }
  } else {
    if (date?.length === 10) {
      selectAtDateHandler(new Date(date));
    }
  }

  const isLeapYear = useCallback(year => {
    return !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
  }, []);

  const format = useCallback(
    val => {
      const len = val.length;
      if (!val) return '';

      if (len === 1 && !['0', '1'].includes(val[0])) {
        return '';
      } else if ((len === 2 && val[0] !== '0' && !['0', '1', '2'].includes(val[1])) || val.substr(0, 2) === '00') {
        return val.substr(0, 1);
      } else if (len === 3) {
        if (val[2] === '/') {
          return val.substr(0, 2);
        } else if (
          (val.substr(0, 2) === '02' && ['0', '1', '2'].includes(val[2])) ||
          (val.substr(0, 2) !== '02' && ['0', '1', '2', '3'].includes(val[2]))
        ) {
          return val.substr(0, 2) + '/' + val.substr(2);
        } else {
          return val.substr(0, 2);
        }
      } else if (
        len === 5 &&
        (val.substr(3, 5) === '00' ||
          (val[3] === '3' && !['0', '1'].includes(val[4])) ||
          (val[3] === '3' && val[4] === '1' && !['01', '03', '05', '07', '08', '10', '12'].includes(val.substr(0, 2))))
      ) {
        return val.substr(0, 4);
      } else if (len === 6) {
        if (val[5] === '/') {
          return val.substr(0, 5);
        } else {
          return val.substr(0, 5) + '/' + val.substr(5);
        }
      } else if (len === 8 && val.substr(0, 6) === '02/29/') {
        let year = +val.substr(6);
        year = year > 1970 ? 1900 + year : 2000 + year;
        if (isLeapYear(year)) {
          return val.substr(0, 7);
        }
      } else if (len === 10 && val.substr(0, 6) === '02/29/') {
        if (isLeapYear(+val.substr(6))) {
          return val.substr(0, 9);
        }
      } else if (len > 10) {
        return val.substr(0, 10);
      }
      return val;
    },
    [isLeapYear],
  );

  const onChangeRaw = useCallback(
    value => {
      value = format(value);
      setDate(value);
    },
    [format],
  );

  return (
    <div className={`${themeClassDateField}_filtersByDateBlock`}>
      <div className={`${themeClassDateField}_header`}>
        <div className={`${themeClassDateField}_header_title`}>Dates</div>
        <Select<DropdownItemModel, null, false>
          menuItems={groupingOptions || []}
          selectHandler={handleChangeGrouping}
          selected={selectedGroupingValues}
          renderCustomBasis={renderCustomGroupingBasis}
          menuItemSize={'sm'}
          disabled={false}
          customSelectClass={`${themeClassDateField}_switcherContainer`}
          isHighResolution
        />
      </div>
      {selectedGroupingValues?.value === 'range' ? (
        <div className={`${themeClassDateField}_isRange`}>
          <DateField isFilter={true} selectedDate={firstDate} valueIsDate={!!firstDate} />
          <DateField isFilter={true} selectedDate={secondDate} valueIsDate={!!secondDate} />
        </div>
      ) : (
        <div className={`${themeClassDateField}_isSingle`}>
          {dateRender && isCalendarValue ? (
            <DateField isFilter={true} selectedDate={dateRender} valueIsDate={!!dateRender} />
          ) : (
            <TextInputNew
              value={date && typeof date === 'object' ? moment(date).format('MM/DD/YYYY') : date || ''}
              type={'on-srf'}
              name="date_input"
              onChange={onChangeRaw}
              placeholder={'Select or type a date ...'}
              onKeyPress={e => {
                if (e.key === 'Enter' && date && typeof date === 'object') {
                  menuCloseHandler && menuCloseHandler();
                }
              }}
            />
          )}
        </div>
      )}
      <div className={`${themeClassDateField}_calendarContainer`}>
        <CustomCalendar
          handleChange={date => selectAtDateHandler(date, true)}
          value={date}
          range={selectedGroupingValues?.value === 'range'}
          returnValue={selectedGroupingValues?.value === 'range' ? 'range' : 'start'}
          shadowOff={true}
        />
      </div>
      {isShowOverdue && (
        <div className={`${themeClassDateField}_overdueContainer`} onClick={handleActiveOverdue}>
          <CheckBox checked={overdueIsActive} />
          <div className={`${themeClassDateField}_overdueContainer_title`}>Overdue</div>
        </div>
      )}
    </div>
  );
};

export default DateSelect;
