import React, { ReactElement, ReactNode, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import { TextInputNew } from '../../../../controls';
import { RequestUserModel } from '../../../../../models';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import Divider from '../../../../controls/Divider/Divider';

import './DropdownPopoverWatchlistGroupsStyles.module.scss';

interface DropdownPopoverWatchlistGroupsProps<T> {
  size: 'sm' | 'md';
  menuItems: T[];
  groupItems: T[];
  customClassName?: string;
  renderItemHeader: () => ReactElement | ReactNode;
  renderItemFooter?: () => ReactElement | ReactNode;
  renderMenuItem: (item: T) => ReactElement | ReactNode;
  renderMenuGroupItem: (item: T) => ReactElement | ReactNode;
  anchorEl: ReactNode;
  onOpen?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void;
  onClose?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void;
  isMenuOpen: boolean;
  searchValueAddToGroup: string;
  handleChangeSearchAddToGroup: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeGroupName: (e: ChangeEvent<HTMLInputElement>) => void;
  groupName: string;
  isEditFromPreview: boolean;
}

function DropdownPopoverWatchlistGroups<T extends RequestUserModel>({
  customClassName,
  renderItemHeader,
  renderItemFooter,
  menuItems,
  groupItems,
  renderMenuItem,
  renderMenuGroupItem,
  anchorEl,
  onOpen,
  onClose,
  isMenuOpen,
  searchValueAddToGroup,
  handleChangeSearchAddToGroup,
  handleChangeGroupName,
  groupName,
  isEditFromPreview,
}: DropdownPopoverWatchlistGroupsProps<T>) {
  const themeClassMenu = useGetThemeClass('b-dropdownPopoverWatchlistGroups');
  const themeClassPaper = useGetThemeClass('b-dropdownPopoverWatchlistGroupsPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  useEffect(() => {
    if (isMenuOpen) {
      onOpen && onOpen();
    }
  }, [isMenuOpen]);

  return (
    <>
      <Popover
        open={!!isMenuOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        onOpen={onOpen}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: isEditFromPreview ? 0 : '150px',
          },
        }}
      >
        <div
          className={cn(`${themeClassMenu}`, {
            [`${customClassName}`]: customClassName,
          })}
        >
          <div className={`${themeClassMenu}_header`}>
            <TextInputNew
              type={'on-bgd'}
              size={'sm'}
              placeholder={'Enter group name'}
              onChange={value => handleChangeGroupName(value)}
              value={groupName}
              label={'Group name'}
            />
          </div>
          <Divider direction={'horizontal'} type={'srf-4'} />
          <div className={`${themeClassMenu}_itemList`}>
            <div className={`${themeClassMenu}_containerTextInput`}>
              <label className={`${themeClassMenu}_containerTextInput_label`}>Participants</label>
              <TextInputNew
                isSearch
                type={'on-bgd'}
                size={'sm'}
                placeholder={'Search'}
                onChange={handleChangeSearchAddToGroup}
                value={searchValueAddToGroup}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className={`${themeClassMenu}_itemList_group`}>
              {menuItems?.length > 0 ? (
                menuItems?.map(menuItem => {
                  return <>{renderMenuItem(menuItem)}</>;
                })
              ) : (
                <div className={`${themeClassMenu}_empty`}>
                  <span>No items</span>
                </div>
              )}
            </div>
          </div>
          {renderItemFooter ? <>{renderItemFooter()}</> : null}
        </div>
      </Popover>
    </>
  );
}

export default DropdownPopoverWatchlistGroups;
