import React, { useState, useEffect, useRef, useCallback, MutableRefObject } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { AlertList, Heatmap } from './parts/index';
import { CustomFiltersModel, SandBoxWidgetsPayloadModel } from '../../../../../store/request/requestReducer.model';
import { getSandBoxWidgets } from '../../../../../store/request/requestLogic(HOLD)';
import moment from 'moment/moment';
import { SandBoxCustomTabsPayloadModel } from '../../../../../models/sandBox/sandBoxArhitecture';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import SandBoxHotlist from '../SandBoxHotlist/SandBoxHotlist';
import { GlobalFiltersQueryModel } from '../../../../../models';
import { SandBoxQueryModel } from '../../../../../store/request/requestReducer.model';
import cn from 'classnames';

import './SandBoxGraphsStyles.scss';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';

type SandBoxQueryWithFilters = SandBoxQueryModel & GlobalFiltersQueryModel;

interface IProps {
  filterHeight: number;
  relatedTo: 'user' | 'party';
  openRequest: (id: number, nf: number, accordionAlias?: string, isMarked?: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  openSubmittal: (id: string, accordionAlias?: string, isMarked?: boolean) => void;
  selectSubSection: (subSectionId: string, shouldSetSectionId?: boolean) => void;
  activeSectionId: string;
  setCustomFilterTab: (
    tabs: SandBoxCustomTabsPayloadModel,
    type: 'request' | 'submittalInput' | 'deliverable' | 'submittalOutput',
    customFilters: CustomFiltersModel,
  ) => void;
  sizeResolution: string;
  isMobile: boolean;
  pageHeaderRef: MutableRefObject<HTMLDivElement>;
  user_id?: number;
}

const SandBoxGraphs = ({
  filterHeight,
  relatedTo,
  openRequest,
  openDeliverable,
  openSubmittal,
  selectSubSection,
  activeSectionId,
  setCustomFilterTab,
  sizeResolution,
  isMobile,
  pageHeaderRef,
  user_id,
}: IProps) => {
  const dispatch = useAppDispatch();

  const getSandBoxWidgetsPromise = useRef();

  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const active_project_id = useAppSelector(state => state.userReducer.active_project_id);

  const themeClass = useGetThemeClass('b-sandBox-graphs');

  const [currentStartWidgetDate, setCurrentStartWidgetDate] = useState('');

  const handleLoadWidgets = useCallback(
    (widgets: SandBoxWidgetsPayloadModel) => {
      getSandBoxWidgetsPromise.current?.abort();
      getSandBoxWidgetsPromise.current = dispatch(getSandBoxWidgets(widgets));
    },
    [getSandBoxWidgets, getSandBoxWidgetsPromise],
  );

  useEffect(() => {
    if (active_project_id && relatedTo) {
      const startCalendarDate = moment(currentStartWidgetDate || new Date())
        .startOf('month')
        .format('YYYY-MM-DD');
      const endCalendarDate = moment(currentStartWidgetDate || new Date())
        .add(1, 'M')
        .endOf('month')
        .format('YYYY-MM-DD');

      const widgetOptions = {
        related_to: relatedTo,
        widgets: [
          {
            alias: 'calendar',
            options: {
              types: ['requests', 'deliverables', 'submittalsInput', 'submittalsOutput'],
              start_date: startCalendarDate,
              end_date: endCalendarDate,
            },
          },
        ],
      };

      handleLoadWidgets(widgetOptions);
    }
  }, [active_project_id, relatedTo]);

  return (
    <div id={'sandBoxGraph-tourBlock'} className={`${themeClass}`}>
      <div
        className={`${themeClass}_main`}
        style={{
          height: `calc(100svh - 98px - ${filterHeight || 52}px)`,
        }}
      >
        <div className={`${themeClass}_main_visualModule`}>
          <SandBoxHotlist
            project_id={active_project_id}
            openRequest={openRequest}
            openDeliverable={openDeliverable}
            openSubmittal={openSubmittal}
            relatedTo={relatedTo}
            isMobile={isMobile}
            isAllHeight={sizeResolution === '1024' || sizeResolution === '960'}
            pageHeaderRef={pageHeaderRef}
            user_id={user_id}
          />
        </div>
        {false && (
          <div className={`${themeClass}_main_visualModule`}>
            <AlertList
              relatedTo={relatedTo}
              loadWidgets={handleLoadWidgets}
              openRequest={openRequest}
              openDeliverable={openDeliverable}
              selectSubSection={selectSubSection}
              activeSectionId={activeSectionId}
            />
          </div>
        )}
        <div
          className={cn(`${themeClass}_main_visualModule ${themeClass}_main_marginBottom`, {
            [`${themeClass}_main_heatmap`]: sizeResolution === '1024' || sizeResolution === '960',
          })}
        >
          <Heatmap
            setCustomFilterTab={setCustomFilterTab}
            relatedTo={relatedTo}
            loadWidgets={handleLoadWidgets}
            setCurrentStartWidgetDate={setCurrentStartWidgetDate}
            isShowOnlyOneMonth={sizeResolution === '1024' || sizeResolution === '960'}
          />
        </div>
      </div>
    </div>
  );
};

export default SandBoxGraphs;
