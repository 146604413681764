import { createAsyncThunk } from '@reduxjs/toolkit';
import { submittalSlice } from './submittalSlice';
import { errorsSlice } from '../errors/errorsSlice';
import submittalsApi from '../../service/Api/submittals/submittalsApi';
import { ISubmittal, ISubmittalUpdateModel, RemoveSubmittalCardPayload } from '../../models/submittals/submittals.model';
import { FileModel, RequestTypeModel } from '../../models';
import { PlaneControlDeliverable } from '../pcd/types';
import pcdApi from '../../service/Api/pcd/pcdApi';
import { getFeed } from '../comments/commentsThunk';
import fileDownload from 'js-file-download';
import { API_URI, DOMAIN_URI } from '../../service/links';
import documentsApi from '../../service/Api/documentsApi';
import FileHelper from '../../helpers/FileHelper';

const { setShowErrorData } = errorsSlice.actions;

export const getSubmittalCard = createAsyncThunk<ISubmittal, { id: number; callback?: (card: ISubmittal) => void }>(
  'submittal/getSubmittalCard',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    const { closeSubmittalCard } = submittalSlice.actions;
    try {
      const { id, callback } = payload;

      if (!id) {
        throw null;
      }

      const response = await submittalsApi.getSubmittalsCard(id);

      if (response.data?.status === false || response.data?.status === 400 || response.data?.status === 404) {
        throw response;
      }

      if (callback) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      dispatch(closeSubmittalCard());
      return rejectWithValue(error);
    }
  },
);

export const getSubmittalTags = createAsyncThunk<RequestTypeModel[], void>(
  'submittal/getSubmittalTags',
  async (_, { dispatch, rejectWithValue }) => {
    const { closeSubmittalCard } = submittalSlice.actions;
    try {
      const response = await submittalsApi.getSubmittalTags();

      if (response.data?.status === false || response.data?.status === 400 || response.data?.status === 404) {
        throw response;
      }

      return response.data.types;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      dispatch(closeSubmittalCard());
      return rejectWithValue(error);
    }
  },
);

export const submittalSave = createAsyncThunk<
  { data: ISubmittal },
  {
    submittalId: number;
    isNotFormData: boolean;
    bodyParams: ISubmittalUpdateModel;
    callback?: () => void;
    callbackNavigation?: (id: number) => void;
  }
>('submittal/submittalSave', async (payload, { dispatch, rejectWithValue, getState }) => {
  const projectId = +sessionStorage.getItem('active_project_id');
  let { bodyParams } = payload;
  const { callback, submittalId, isNotFormData, callbackNavigation } = payload;

  const upload_files = bodyParams['upload_files'] || null;

  bodyParams = { ...bodyParams, project_id: projectId, submittal_id: submittalId };

  let response;
  try {
    const links = [];
    const files = FileHelper.separateByMaxSize(upload_files);
    if (!submittalId) {
      delete bodyParams.submittal_id;
      // Separate to many request, if request size > FILE_SIZE_LIMIT
      if (files && files.length) {
        const createdSubmittal = await submittalsApi.createSubmittal({ ...bodyParams, upload_files: files[0] }, files[0], isNotFormData);
        response = { ...createdSubmittal };
        files.shift();
        files.map((f, fIndex) =>
          links.push(
            submittalsApi.updateSubmittal(
              { ...bodyParams, upload_files: f, submittal_id: createdSubmittal.data.id, quietly: fIndex !== files.length - 1 ? 1 : 0 },
              createdSubmittal.data.id,
              f,
              isNotFormData,
            ),
          ),
        );
      } else {
        links.push(submittalsApi.createSubmittal(bodyParams, upload_files, isNotFormData));
      }
      const responses = await Promise.all(links);
      if (responses?.length) {
        response = responses[responses.length - 1];
      }
      bodyParams.nf_id ? null : callbackNavigation && callbackNavigation(response.data.id);
    } else {
      if (files && files.length) {
        files.map(f => links.push(submittalsApi.updateSubmittal({ ...bodyParams, upload_files: f }, submittalId, f, isNotFormData)));
      } else {
        links.push(submittalsApi.updateSubmittal(bodyParams, submittalId, upload_files, isNotFormData));
      }
      const responses = await Promise.all(links);
      response = responses[responses.length - 1];
    }
    if (submittalId) {
      dispatch(
        getFeed({
          owner_id: submittalId,
          type: 'submittal',
        }),
      );
    }

    if (callback) {
      callback();
    }
    return { ...response };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const editSubmittalReviews = createAsyncThunk<ISubmittal, PlaneControlDeliverable.EditDeliverableReviewsPCD>(
  'submittal/editSubmittalReviews',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const newData = { ...data };

      const response = await pcdApi.editDeliverableReviewsPCD(newData);

      dispatch(
        getFeed({
          owner_id: data.owner_id,
          type: 'submittal',
        }),
      );

      if (callback) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateReviewSubmittal = createAsyncThunk<PlaneControlDeliverable.PCDCardWithFilters, PlaneControlDeliverable.UpdateReviewPCD>(
  'submittal/updateReviewSubmittal',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const review = data.id;
      const newData = { ...data };
      const upload_files = data['upload_files'];

      let response;
      const files = FileHelper.separateByMaxSize(upload_files as unknown as FileModel[]);
      // Separate to many request, if request size > FILE_SIZE_LIMIT
      if (files && files.length > 1) {
        const responses = await Promise.all(
          files.map(async (f, fIndex) =>
            pcdApi.updateReviewPCD({ ...newData, upload_files: f, quietly: fIndex !== files.length - 1 ? 1 : 0 }, review),
          ),
        );
        response = responses[responses.length - 1];
      } else {
        response = await pcdApi.updateReviewPCD(newData, review);
      }

      dispatch(
        getFeed({
          owner_id: data.deliverable_id,
          type: 'submittal',
        }),
      );

      if (callback) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createSubmittalRevision = createAsyncThunk<ISubmittal, { submittalId: number; callback?: (response) => void }>(
  'submittal/createSubmittalRevision',
  async ({ submittalId, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await submittalsApi.createRevision(submittalId);

      if (callback) {
        callback(response);
      }

      dispatch(
        getFeed({
          owner_id: submittalId,
          type: 'submittal',
        }),
      );

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSubmittalsExport = createAsyncThunk<any, any>(
  'submittal/getSubmittalsExport',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { filename, link_file, link_ext } = payload;
      const body_params = payload;
      delete body_params['filename'];
      delete body_params['link_file'];
      delete body_params['link_ext'];
      const url = link_ext
        ? new URL(`${DOMAIN_URI}${API_URI}export/${link_file}/${link_ext}`)
        : new URL(`${DOMAIN_URI}${API_URI}export/${link_file}`);

      const response = await documentsApi.getFilePost(url, body_params);
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const removeSubmittalCard = createAsyncThunk<ISubmittal, { data: RemoveSubmittalCardPayload; callback?: (sfCard) => void }>(
  'submittal/removeSubmittalCard',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;

    try {
      const response = await submittalsApi.removeSubmittalCard(data);

      if (!data.force) {
        dispatch(
          getFeed({
            owner_id: data.submittal_id,
            type: 'submittal',
          }),
        );
      }

      if (callback) {
        callback(response.data);
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setSyncProcoreSubmittal = createAsyncThunk<
  any,
  {
    submittal_id: number;
    is_procore_sync_needed: 0 | 1;
  }
>('submittal/syncProcore', async (payload, { dispatch, rejectWithValue }) => {
  const { submittal_id, is_procore_sync_needed } = payload;
  try {
    const sendData = { is_procore_sync_needed };

    const response = await submittalsApi.setSyncProcore(submittal_id, sendData);

    return sendData;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});
