import React, { useMemo } from 'react';
import './EmptyStatePlaceholderStyles.scss';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppSelector } from '../../../store/configure/configureStore';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

interface EmptyStatePlaceholderProps {
  type:
    | 'sandbox-nf-snippets'
    | 'nf-card-linked-nfs'
    | 'nf-card-response'
    | 'nf-card-incorporation-secondary'
    | 'nf-card-incorporation-default'
    | 'related-need-form-pred'
    | 'related-need-form-succ'
    | 'related-deliverables-pred'
    | 'related-deliverables-succ'
    | 'related-submittals-pred'
    | 'related-submittals-succ'
    | 'related-design-document'
    | 'manage-drawing';
  isMobile?: boolean;
  isSmall?: boolean;
  customClassName?: string;
  isManageDrawing?: boolean;
}

const EmptyStatePlaceholder: React.FC<EmptyStatePlaceholderProps> = ({ type, isMobile, isSmall, customClassName, isManageDrawing }) => {
  const themeClass = useGetThemeClass('b-emptyStatePlaceholder');

  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  const emptyPlaceholder = useMemo(() => {
    const isRelatedTab = type.includes('related');
    if (type && theme && !isRelatedTab && !isManageDrawing) {
      return (
        <img src={require(`../../../assets/images/empty-state-placeholders/${type}/${type}${isMobile ? '-mobile' : ''}-${theme}.png`)} />
      );
    }

    if (type && theme && (isRelatedTab || isManageDrawing)) {
      return (
        <SVG src={require(`../../../assets/images/empty-state-placeholders/${type}/${type}${isSmall ? '-small' : ''}-${theme}.svg`)} />
      );
    }
    return null;
  }, [type, theme, isMobile]);

  return (
    <>
      <div
        className={cn(themeClass, {
          [`${customClassName}`]: customClassName,
        })}
      >
        {emptyPlaceholder}
      </div>
    </>
  );
};

export default EmptyStatePlaceholder;
