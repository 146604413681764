import React, { useEffect, useState } from 'react';
import { RequestModel, VersionModel } from '../../../../../models';
import fileDownload from 'js-file-download';
import { API_URI, DOMAIN_URI } from '../../../../../service/links';
import commonApi from '../../../../../service/Api/commonApi';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileHelper from '../../../../../helpers/FileHelper';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

import './RequestVersions.module.scss';

interface IProps {
  version: VersionModel | RequestModel;
  oneVersion?: boolean;
  downloadNF?: VersionModel | RequestModel;
  closeDownloadNF?: () => void;
}

export const CircularProgressDownload = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  colorPrimary: {
    color: localStorage.getItem('theme') === 'dark' ? '#FFF !important;' : '#0b1324 !important;',
  },
})(CircularProgress);

export const RequestVersions = ({ version, oneVersion, downloadNF, closeDownloadNF }: IProps) => {
  const { isMobile } = GetResolution();
  const themeClass = useGetThemeClass('b-requestVersions');

  const [anchorEl, setAnchorEl] = useState<null | any>();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileId, setFileId] = useState<number | null>(null);

  const handleOpenDownload = (e: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (downloadNF && downloadNF?.id === version?.id) {
      downloadRequestCard('pdf', downloadNF.id);
    }
    closeDownloadNF && closeDownloadNF();
  }, [downloadNF]);

  const downloadRequestCard = async (type: 'pdf' | 'zip', id) => {
    setFileId(id);
    const url = new URL(`${DOMAIN_URI}${API_URI}export/${type}/request/${version.id}`);
    setFileLoading(true);
    try {
      const response = await commonApi.getFile(url.href);
      setFileLoading(false);
      setFileId(null);
      handleCloseDownload();
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);
    } catch (e) {
      setFileLoading(false);
      setFileId(null);
      handleCloseDownload();
    }
  };

  const downloadPDF = () => {
    return (
      <>
        {oneVersion ? (
          <div className={`${themeClass}_requestDownloadPDF`} onClick={() => downloadRequestCard('pdf', version.id)}>
            <span>Download PDF</span>
            <div className={'downloadBtn'}>
              {fileLoading ? (
                <div className={`${themeClass}_iconBlockProgress`}>
                  <SystemButton type={'download'} size={'md'} variant={'transparent'} isMobile={isMobile} />
                  <CircularProgressDownload
                    style={{ marginTop: isMobile ? '-15px' : '-11px', marginLeft: isMobile ? '-15px' : '-11px' }}
                    size={isMobile ? 30 : 22}
                  />
                </div>
              ) : (
                <SystemButton type={'download'} size={'md'} variant={'transparent'} isMobile={isMobile} />
              )}
            </div>
          </div>
        ) : (
          <div className={'iconBlock'} onClick={() => downloadRequestCard('pdf', version.id)}>
            {fileLoading && fileId === version.id ? (
              <div className={`${themeClass}_iconBlockProgress`}>
                <CircularProgressDownload
                  style={{ marginTop: isMobile ? '-15px' : '-11px', marginLeft: isMobile ? '-15px' : '-11px' }}
                  size={isMobile ? 30 : 22}
                  className={'progressIcon'}
                />
                <SystemButton type={'download'} size={'md'} variant={'transparent'} isMobile={isMobile} />
              </div>
            ) : (
              <SystemButton type={'download'} size={'md'} variant={'transparent'} isMobile={isMobile} />
            )}
          </div>
        )}
      </>
    );
  };

  return <div className={`${themeClass}`}>{downloadPDF()}</div>;
};
