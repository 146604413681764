import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { useGetThemeClass } from '../../../helpers/designTokens';
import EmptyStatePlaceholder from '../../controls/EmptyStatePlaceholder/EmptyStatePlaceholder';
import { ucFirst } from '../../../helpers/commonHelpers';
import { ActionButton, CustomButton } from '../../controls/ButtonComponents';
import Divider from '../../controls/Divider/Divider';
import { DropdownItemModel } from '../../../models/global';
import { Select } from '../../controls/Dropdowns/DropdownСomponents';
import FilterSelectBasis from '../../controls/Dropdowns/CustomParts/FilterSelectBasis/FilterSelectBasis';
import { PredecessorSuccessorModel } from '../../../models';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { FullRequestModel } from '../../../store/request/requestReducer.model';
import CardLinkButton from '../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import CustomSkeleton from '../../controls/CustomSkeleton/CustomSkeleton';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { RelatedItemsTabsType } from '../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import {
  DeliverableSnippetModel,
  DocumentSnippetModel,
  FilterParamsModel,
  MfCodeListItemsInterface,
  RequestSnippetModel,
  SubmittalListItemsInterface,
  SubmittalSnippetModel,
} from '../../../store/relatedItems/relatedItemsSlice.model';
import {
  getLinkingItems,
  getRelationTab,
  linkRelationItem,
  unlinkRelationItem,
  setValidityStatus,
} from '../../../store/relatedItems/relatedItemsThunk';
import { ISubmittal } from '../../../models/submittals/submittals.model';
import CardRenderSelect from '../CardRequest/controls/CardRenderSelect/CardRenderSelect';
import { PCDApi } from '../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import ManageStatuses from '../CardRequest/controls/ManageStatuses/ManageStatuses';
import ChipsDiscipline from '../../controls/Chips/ChipsDiscipline/ChipsDiscipline';
import { Popover } from '@material-ui/core';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { getFeed } from '../../../store/comments/commentsThunk';
import { relatedItemsSlice } from '../../../store/relatedItems/relatedItemsSlice';
import RelatedItemsGroupingSelect from './controls/RelatedItemsGroupingSelect/RelatedItemsGroupingSelect';
import RelatedItemsFiltersSelect from './controls/RelatedItemsFiltersSelect/RelatedItemsFiltersSelect';
import {
  LinkedItemsShowType,
  linkedItemsShowTypesDelToNf,
  linkedItemsShowTypesNfToNF,
  linkedParts,
  LinkedType,
} from './RelatedItemsTabConstants';
import { groupBy } from 'lodash';
import { getGroupingSnippetValue, sortGroupingObject } from './controls/RelatedItemsGroupingSelect/GroupingConstants';
import RelatedItemsSnippet from './controls/RelatedItemsSnippet/RelatedItemsSnippet';
import RelatedItemsGroupingItem from './controls/RelatedItemsGroupingItem/RelatedItemsGroupingItem';
import { getFilteredData } from './controls/RelatedItemsFiltersSelect/FiltersConstants';
import { RouterHelperComponentProps, withRouterHelper } from '../../../navigation/withRouterHelper/withRouterHelper';
import { handleOpenCardHelper } from '../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './RelatedItemsTabStyles.scss';

const { clearRelatedItemsTab } = relatedItemsSlice.actions;

interface RelatedItemsTabProps extends RouterHelperComponentProps {
  isDragRequestCard?: boolean;
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
  isSeparateTab: boolean;
  relatedTabType: RelatedItemsTabsType;
  currentCard: 'Nf' | 'Deliverable' | 'Submittal';
  isCreateRequest: boolean;
  isAccess?: boolean;
  isCreateSubmitButtonDisabled?: boolean;
  handleOpenReasonsPopUp?: (isUpdateReasonsPopUp: boolean) => void;
  isCreateRevision?: boolean;
  isDeactivated?: boolean;
  isChanged?: boolean;
  setIsChanged?: (isChanged: boolean) => void;
  handleRelatedItems?: (tab: any) => void;
  previewCard: boolean;
}

type LinkingSuggestions = DropdownItemModel &
  (RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel);

type CardType = 'request' | 'deliverable' | 'submittal';
type SnippetType = 'request' | 'deliverable' | 'document' | 'submittalInput' | 'submittalOutput' | 'predecessor' | 'successor';

const cardTypeMappings = {
  Nf: 'request',
  Deliverable: 'deliverable',
  Submittal: 'submittal',
};

const RelatedItemsTab: React.FC<RelatedItemsTabProps> = ({
  isDragRequestCard,
  selectedRequest,
  isSeparateTab,
  relatedTabType,
  currentCard,
  isCreateRequest,
  isAccess,
  isCreateSubmitButtonDisabled,
  handleOpenReasonsPopUp,
  isCreateRevision,
  isDeactivated,
  isChanged,
  setIsChanged,
  routerHelper,
  handleRelatedItems,
  previewCard,
}) => {
  const dispatch = useAppDispatch();

  const { isMobile } = GetResolution();
  const themeClassDoubleColumn = useGetThemeClass('b-related-tab-double-column');
  const themeClassSingleColumn = useGetThemeClass('b-related-tab-single-column');
  const themeClassValidityStatusPopUp = useGetThemeClass('b-related-tab-validity-status-popup');
  const themeClassEmptyContent = useGetThemeClass('b-emptyContent');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const { setUrlPreviewNF } = useRoutingHelper();

  const { relatedTabData, isLinkingRelationItemLoading, isRelationTabLoading } = useAppSelector(state => state.relatedItemsSlice);
  const activeProjectId = useAppSelector(state => state.userReducer.active_project_id);

  const [selectedGroupingPredecessor, setSelectedGroupingPredecessor] = useState<string[]>([]);
  const [selectedGroupingSuccessor, setSelectedGroupingSuccessor] = useState<string[]>([]);
  const [expandedAccordionKeysPredecessor, setExpandedAccordionKeysPredecessor] = useState<string[]>([]);
  const [expandedAccordionKeysSuccessor, setExpandedAccordionKeysSuccessor] = useState<string[]>([]);
  const [predecessorAccordionCounter, setPredecessorAccordionCounter] = useState<number>(null);
  const [successorAccordionCounter, setSuccessorAccordionCounter] = useState<number>(null);
  const [selectedFilteringPredecessor, setSelectedFilteringPredecessor] = useState<FilterParamsModel[]>([]);
  const [selectedFilteringSuccessor, setSelectedFilteringSuccessor] = useState<FilterParamsModel[]>([]);
  const [updateSelectedShowType, setUpdateSelectedShowType] = useState<boolean>(false);
  const [predecessorsShowType, setPredecessorsShowType] = useState<DropdownItemModel>(null);
  const [successorsShowType, setSuccessorsShowType] = useState<DropdownItemModel>(null);

  const [selectedItems, setSelectedItems] = useState<LinkingSuggestions[]>(null);
  const [linkingItems, setLinkingItems] = useState<any[]>(null);
  const [linkingListLoading, setLinkingListLoading] = useState<boolean>(false);
  const [selectedPart, setSelectedPart] = useState<string>('');

  const [isManageStatusesOpen, setIsManageStatusesOpen] = useState<boolean>(false);
  const [isOpenValidityStatusPopUp, setIsOpenValidityStatusPopUp] = useState(false);
  const [validityAnchorEl, setValidityAnchorEl] = useState(null);
  const [openedSnippetPopUp, setOpenedSnippetPopUp] = useState(null);
  const [isShowMore, setIsShowMore] = useState<Record<LinkedType, boolean>>({
    predecessor: false,
    successor: false,
  });

  // submittal Dropdown
  const [fullMfs, setFullMfs] = useState<MfCodeListItemsInterface[]>([]);
  const [submittalsData, setSubmittalsData] = useState<MfCodeListItemsInterface[]>([]);
  const [openedMfIds, setOpenedMfIds] = useState<number[]>([]);
  const [selectedSfIds, setSelectedSfIds] = useState<number[]>([]);
  const [mfWithSelectedSf, setMfWithSelectedSf] = useState<number[]>([]);
  const [localSearchValue, setLocalSearchValue] = useState<string>('');

  const loadRelationTabPromise = useRef(null);

  useEffect(() => {
    const result = [];

    if (fullMfs?.length) {
      fullMfs.forEach(mf => {
        const isMfOpened = openedMfIds.includes(mf.id);

        if (localSearchValue) {
          if (
            mf.submittals.some(
              sf => sf.sf_index?.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue),
            )
          ) {
            result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
          }
        } else {
          result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
        }

        if (isMfOpened) {
          mf.submittals.forEach(sf => {
            const selectedSf = selectedSfIds.includes(sf.id);

            if (localSearchValue) {
              if (sf.sf_index.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue)) {
                result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
              }
            } else {
              result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
            }
          });
        }
      });
    }
    setSubmittalsData(result);
  }, [fullMfs, openedMfIds, selectedSfIds, localSearchValue]);

  useEffect(() => {
    const result = [];
    if (fullMfs?.length) {
      fullMfs.forEach(mf => {
        mf.submittals.forEach(sf => {
          const isMfHaveSelectedSf = selectedSfIds.includes(sf.id);

          if (isMfHaveSelectedSf) {
            result.push(sf);
          }
        });
      });
    }

    setMfWithSelectedSf(result);
  }, [fullMfs, selectedSfIds]);

  // deliverable Dropdown
  const [fullBuildings, setFullBuildings] = useState<PCDApi.PCDListBuildingInterface[]>([]);
  const [deliverablesData, setDeliverablesData] = useState<PCDApi.PCDListBuildingInterface[]>([]);
  const [openedBuildingIds, setOpenedBuildingIds] = useState<number[]>([]);
  const [openedDrawingIds, setOpenedDrawingIds] = useState<number[]>([]);
  const [selectedDeliverablesIds, setSelectedDeliverablesIds] = useState<number[]>([]);
  const [drawingsWithSelectedDeliverables, setDrawingsWithSelectedDeliverables] = useState<number[]>([]);

  useEffect(() => {
    const result = [];

    if (fullBuildings?.length) {
      fullBuildings.forEach(building => {
        const isBuildingOpened = openedBuildingIds.includes(building.id);

        if (localSearchValue) {
          if (building.drawings.some(drawing => drawing.title.toLowerCase().includes(localSearchValue))) {
            result.push({ ...building, itemType: 'building', isItemOpened: isBuildingOpened });
          }
        } else {
          result.push({ ...building, itemType: 'building', isItemOpened: isBuildingOpened });
        }

        if (isBuildingOpened) {
          building.drawings.forEach(drawing => {
            const isDrawingOpened = openedDrawingIds.includes(drawing.id);
            const selectedDeliverables = drawing.deliverables.filter(deliverable => selectedDeliverablesIds.includes(deliverable.id));
            let selectedDrawingValue;

            if (selectedDeliverables.length === drawing.deliverables.length) {
              selectedDrawingValue = 2;
            } else if (selectedDeliverables.length >= 1) {
              selectedDrawingValue = 1;
            } else {
              selectedDrawingValue = 0;
            }

            if (localSearchValue) {
              if (drawing.title.toLowerCase().includes(localSearchValue)) {
                result.push({ ...drawing, itemType: 'drawing', isItemOpened: isDrawingOpened, selected: selectedDrawingValue });
              }
            } else {
              result.push({ ...drawing, itemType: 'drawing', isItemOpened: isDrawingOpened, selected: selectedDrawingValue });
            }

            if (isDrawingOpened) {
              drawing.deliverables.forEach(deliverable => {
                const isDeliverableSelected = selectedDeliverablesIds.includes(deliverable.id);
                result.push({ ...deliverable, itemType: 'deliverable', isItemOpened: isDrawingOpened, selected: isDeliverableSelected });
              });
            }
          });
        }
      });
    }

    setDeliverablesData(result);
  }, [fullBuildings, openedBuildingIds, openedDrawingIds, selectedDeliverablesIds, localSearchValue]);

  useEffect(() => {
    const result = [];

    if (fullBuildings?.length) {
      fullBuildings.forEach(building => {
        building.drawings.forEach(drawing => {
          const isDrawingHaveSelectedDeliverable = drawing.deliverables.find(deliverable =>
            selectedDeliverablesIds.includes(deliverable.id),
          );

          if (isDrawingHaveSelectedDeliverable) {
            result.push(drawing);
          }
        });
      });
    }

    setDrawingsWithSelectedDeliverables(result);
  }, [fullBuildings, selectedDeliverablesIds]);

  const handleAddGroupingValue = (gropingValues: DropdownItemModel[], type: LinkedType) => {
    function moveValidityStatusToEnd(array) {
      const validityStatusIndex = array.findIndex(obj => obj.value === 'validityStatus');
      if (validityStatusIndex !== -1) {
        const validityStatusObj = array.splice(validityStatusIndex, 1)[0];
        array.push(validityStatusObj);
      }
      return array;
    }

    const newArray = moveValidityStatusToEnd(gropingValues);
    if (type === 'predecessor') setSelectedGroupingPredecessor(newArray);
    if (type === 'successor') setSelectedGroupingSuccessor(newArray);
  };

  const handleAddFilteringValue = (value: FilterParamsModel[], type: LinkedType) => {
    if (type === 'predecessor') setSelectedFilteringPredecessor(value);
    if (type === 'successor') setSelectedFilteringSuccessor(value);
  };

  const handleBuildingItemClick = (building: PCDApi.PCDListBuildingInterface) => {
    const result = [...openedBuildingIds];

    if (result?.includes(building.id)) {
      result.splice(
        result.findIndex(id => id === building.id),
        1,
      );
    } else {
      result.push(building.id);
    }

    setOpenedBuildingIds(result);
  };

  const handleMfItemClick = (mf: MfCodeListItemsInterface) => {
    const result = [...openedMfIds];

    if (result?.includes(mf.id)) {
      result.splice(
        result.findIndex(id => id === mf.id),
        1,
      );
    } else {
      result.push(mf.id);
    }
    setOpenedMfIds(result);
  };

  const handleManageStatusesOpen = partType => {
    setSelectedPart(partType);
    setIsManageStatusesOpen(true);
  };

  const handleManageStatusesClose = () => {
    setSelectedPart('');
    setIsManageStatusesOpen(false);
  };

  const handleToggleShowMore = (part: LinkedType) => {
    setIsShowMore(prevState => ({ ...prevState, [part]: !prevState[part] }));
  };

  const handleDrawingItemClick = (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    const result = [...openedDrawingIds];

    if (result?.includes(drawing.id)) {
      result.splice(
        result.findIndex(id => id === drawing.id),
        1,
      );
    } else {
      result.push(drawing.id);
    }

    setOpenedDrawingIds(result);
  };

  const handleDeliverablesItemClick = (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    if (deliverable.disabled) {
      return;
    }
    const result = [...selectedDeliverablesIds];

    if (result?.includes(deliverable.id)) {
      result.splice(
        result.findIndex(id => id === deliverable.id),
        1,
      );
    } else {
      result.push(deliverable.id);
    }

    setSelectedDeliverablesIds(result);
  };

  const handleSfItemClick = (event: React.SyntheticEvent, sf: SubmittalListItemsInterface) => {
    event.stopPropagation();

    if (sf.disabled) {
      return;
    }
    const result = [...selectedSfIds];

    if (result?.includes(sf.id)) {
      result.splice(
        result.findIndex(id => id === sf.id),
        1,
      );
    } else {
      result.push(sf.id);
    }
    setSelectedSfIds(result);
  };

  const handleSelectedDrawingDelete = (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    const result = [...selectedDeliverablesIds];

    drawing.deliverables.forEach(deliverable => {
      if (result.includes(deliverable.id)) {
        result.splice(
          result.findIndex(item => item.id === deliverable.id),
          1,
        );
      }
    });

    setSelectedDeliverablesIds(result);
  };

  const handleDrawingCheckBoxClick = (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    e.stopPropagation();

    if (drawing.disabled) {
      return;
    }
    const result = [...selectedDeliverablesIds];

    const { selected, deliverables } = drawing;
    if (selected === 2) {
      deliverables.forEach(deliverable => {
        const deliverableIndex = result.findIndex(item => item === deliverable.id);
        if ((deliverableIndex || deliverableIndex === 0) && deliverableIndex !== -1) {
          result.splice(deliverableIndex, 1);
        }
      });
    }

    if (selected === 1) {
      deliverables.forEach(deliverable => {
        if (!result.includes(deliverable.id) && !deliverable.is_locked) {
          result.push(deliverable.id);
        }
      });
    }

    if (selected === 0) {
      deliverables.forEach(deliverable => {
        if (!deliverable.is_locked) {
          result.push(deliverable.id);
        }
      });
    }

    setSelectedDeliverablesIds(result);
  };

  const deliverablesCustomSelectAll = () => {
    const result = [];

    deliverablesData.forEach(item => {
      item?.drawings?.forEach(drawing => {
        if (localSearchValue && !drawing.title.toLowerCase().includes(localSearchValue)) return;

        drawing?.deliverables?.forEach(deliverable => {
          if (!deliverable.is_locked) {
            result.push(deliverable.id);
          }
        });
      });
    });

    setSelectedDeliverablesIds(result);
  };

  const sfCustomSelectAll = () => {
    const result = [];

    submittalsData.forEach(item => {
      item?.submittals?.forEach(sf => {
        if (
          localSearchValue &&
          !sf.sf_index.toLowerCase().includes(localSearchValue) &&
          !sf.title.toLowerCase().includes(localSearchValue)
        ) {
          return;
        }

        if (!sf.is_locked && !sf.is_deactivated) {
          result.push(sf.id);
        }
      });
    });
    setSelectedSfIds(result);
  };

  const handleChangeSearch = searchValue => {
    setLocalSearchValue(searchValue.toLowerCase());
  };

  useEffect(() => {
    getRelatedItems();
  }, [selectedRequest, activeProjectId]);

  useEffect(() => {
    return () => {
      loadRelationTabPromise.current?.abort();

      if (selectedRequest?.id) {
        dispatch(clearRelatedItemsTab());
      }
    };
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (selectedRequest?.id) {
      setUpdateSelectedShowType(true);
    }
  }, [
    selectedRequest?.id,
    relatedTabData?.successor?.length,
    relatedTabData?.predecessor?.length,
    relatedTabData?.request?.length,
    relatedTabType,
  ]);

  useEffect(() => {
    if (selectedRequest?.id) {
      setPredecessorsShowType(null);
      setSuccessorsShowType(null);
    }
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (selectedRequest?.id && isChanged) {
      setSelectedGroupingPredecessor([]);
      setSelectedGroupingSuccessor([]);
      setSelectedFilteringPredecessor([]);
      setSelectedFilteringSuccessor([]);
      setExpandedAccordionKeysPredecessor([]);
      setExpandedAccordionKeysSuccessor([]);
      setPredecessorAccordionCounter(null);
      setSuccessorAccordionCounter(null);
      setIsChanged(false);
    }
  }, [selectedRequest?.id, isChanged]);

  useEffect(() => {
    if (selectedGroupingPredecessor?.length === 0) {
      setExpandedAccordionKeysPredecessor([]);
    }

    if (selectedGroupingSuccessor?.length === 0) {
      setExpandedAccordionKeysSuccessor([]);
    }
  }, [selectedGroupingPredecessor, selectedGroupingSuccessor]);

  useEffect(() => {
    if (updateSelectedShowType) {
      linkedParts.forEach(linkedPart => {
        const setSelectedShowTypeFunction = linkedPart === 'predecessor' ? setPredecessorsShowType : setSuccessorsShowType;
        const selectedType = linkedPart === 'predecessor' ? predecessorsShowType : successorsShowType;

        const defaultSelectedShowType = selectedType
          ? getShowTypeOptions(linkedPart).filter(f => f.value === selectedType?.value)[0]
          : getShowTypeOptions(linkedPart)[0];

        setSelectedShowTypeFunction(defaultSelectedShowType);
        setUpdateSelectedShowType(false);
      });
    }
  }, [updateSelectedShowType]);

  const showDoubleRelation =
    (currentCard && relatedTabType === cardTypeMappings[currentCard]) ||
    (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) ||
    (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') ||
    (currentCard === 'Submittal' &&
      selectedRequest?.is_output &&
      (relatedTabType === 'request' || relatedTabType === 'submittalOutput' || relatedTabType === 'deliverable')) ||
    (currentCard === 'Submittal' && !selectedRequest?.is_output && (relatedTabType === 'request' || relatedTabType === 'submittalInput'));

  const showSingleRelation =
    (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) ||
    (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') ||
    (currentCard === 'Submittal' && !selectedRequest?.is_output && (relatedTabType === 'request' || relatedTabType === 'submittalInput')) ||
    (currentCard === 'Submittal' &&
      selectedRequest?.is_output &&
      (relatedTabType === 'request' || relatedTabType === 'submittalOutput' || relatedTabType === 'deliverable'))
      ? false
      : currentCard && relatedTabType !== cardTypeMappings[currentCard];

  const getRelatedItems = () => {
    if (selectedRequest?.id && !isRelationTabLoading.loading && !isCreateRequest && activeProjectId) {
      loadRelationTabPromise.current?.abort();
      loadRelationTabPromise.current = dispatch(
        getRelationTab({
          project_id: activeProjectId,
          owner_id:
            currentCard === 'Submittal' || currentCard === 'Nf' ? selectedRequest?.parent_id || selectedRequest.id : selectedRequest.id,
          owner_type: cardTypeMappings[currentCard] as CardType,
        }),
      );
    }
  };

  const openCard = card => {
    handleOpenCardHelper(
      true,
      currentCard,
      selectedRequest,
      card,
      dispatch,
      routerHelper,
      previewCard ? 'previewCardBreadcrumbs' : 'firstCardBreadcrumbs',
    );
  };

  const handleOpenRequestPreview = (requestId: number, requestNf: number) => {
    setUrlPreviewNF(String(requestNf), String(requestId));
  };

  const isSubmittalOutputSuccessor = currentCard === 'Submittal' && relatedTabType === 'submittalOutput' && !selectedRequest?.is_output;

  const handleLinkItems = (closeMenu: () => void, linkedType: SnippetType, customType?: 'deliverables' | 'submittals') => {
    if (
      !selectedRequest ||
      (!customType && !selectedItems?.length) ||
      (customType === 'deliverables' && !selectedDeliverablesIds?.length) ||
      (customType === 'submittals' && !selectedSfIds?.length)
    ) {
      return;
    }

    const items = customType
      ? customType === 'deliverables'
        ? selectedDeliverablesIds
        : selectedSfIds
      : selectedItems.map(item => item.id);

    const owner_id =
      currentCard === 'Submittal' || currentCard === 'Nf' ? selectedRequest?.parent_id || selectedRequest.id : selectedRequest.id;

    const cardType = cardTypeMappings[currentCard];

    const linkData = {
      project_id: activeProjectId,
      owner_id,
      owner_type: cardTypeMappings[currentCard] as CardType,
      sync_owner_type: linkedType,
      sync_owner_ids: items,
      submittal_role: undefined,
    };

    if (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) {
      linkData.submittal_role = linkedType;
      linkData.sync_owner_type = 'submittal';
    }

    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') {
      linkData.submittal_role = linkedType;
      linkData.sync_owner_type = 'submittal';
    }

    if (currentCard === 'Submittal' && relatedTabType === 'request') {
      linkData.submittal_role = linkedType === 'predecessor' ? 'successor' : 'predecessor';
      linkData.sync_owner_type = 'request';
    }

    if (currentCard === 'Submittal' && relatedTabType === 'deliverable') {
      linkData.submittal_role = linkedType === 'predecessor' ? 'successor' : 'predecessor';
      linkData.sync_owner_type = 'deliverable';
    }

    if (isSubmittalOutputSuccessor) {
      linkData.sync_owner_type = 'successor';
    }

    dispatch(
      linkRelationItem({
        linkData,
        callback: () => {
          closeMenu();
          setLocalSearchValue('');
          setLinkingItems(null);
          setSelectedDeliverablesIds([]);
          setSelectedSfIds([]);
          dispatch(
            getFeed({
              owner_id:
                currentCard === 'Deliverable' && relatedTabType === 'submittalOutput'
                  ? selectedRequest?.parent_id || selectedRequest.id
                  : owner_id,
              type: cardType,
            }),
          );
        },
      }),
    );
  };

  const handleUnlinkItem = (
    item: RequestSnippetModel | DeliverableSnippetModel | DocumentSnippetModel | SubmittalSnippetModel,
    type?: LinkedType,
  ) => {
    if (!selectedRequest?.id) {
      return;
    }

    const owner_id =
      currentCard === 'Submittal' || currentCard === 'Nf' ? selectedRequest?.parent_id || selectedRequest.id : selectedRequest.id;

    const sync_owner_type =
      currentCard === 'Nf' && relatedTabType === 'deliverable'
        ? 'deliverable'
        : currentCard === 'Deliverable' && relatedTabType === 'request'
        ? 'request'
        : type || item.snippet;

    const unlinkData = {
      project_id: activeProjectId,
      owner_id,
      owner_type: item.for_card,
      sync_owner_type: sync_owner_type,
      sync_owner_ids: [item.id],
      submittal_role: undefined,
    };

    if (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) {
      unlinkData.submittal_role = type;
      unlinkData.sync_owner_type = 'submittal';
    }

    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') {
      unlinkData.submittal_role = type;
      unlinkData.sync_owner_type = 'submittal';
    }

    if (currentCard === 'Submittal' && relatedTabType === 'request') {
      unlinkData.submittal_role = type === 'predecessor' ? 'successor' : 'predecessor';
      unlinkData.sync_owner_type = 'request';
    }

    if (currentCard === 'Submittal' && relatedTabType === 'deliverable') {
      unlinkData.submittal_role = type === 'predecessor' ? 'successor' : 'predecessor';
      unlinkData.sync_owner_type = 'deliverable';
    }

    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') {
      unlinkData.submittal_role = type;
      unlinkData.sync_owner_type = 'submittal';
    }

    const cardType = cardTypeMappings[currentCard];

    dispatch(
      unlinkRelationItem({
        unlinkData,
        callback: () => {
          dispatch(
            getFeed({
              owner_id:
                currentCard === 'Deliverable' && relatedTabType === 'submittalOutput'
                  ? selectedRequest?.parent_id || selectedRequest.id
                  : owner_id,
              type: cardType,
            }),
          );
        },
      }),
    );
  };

  const getShowTypeOptions = (linkedType: LinkedType) => {
    let linkedItems = [];

    if (currentCard === 'Nf' && relatedTabType === 'request') {
      linkedItems = [...linkedItemsShowTypesNfToNF];
    }

    if (currentCard === 'Deliverable' && relatedTabType === 'request') {
      linkedItems = [...linkedItemsShowTypesDelToNf];
    }

    return linkedItems.map(item => {
      const linkedItemsCountForType = getLinkedItemsCountForShowType(linkedType, item);

      return {
        value: item,
        title: `${ucFirst(item)} ${linkedItemsCountForType ? `(${linkedItemsCountForType})` : ''}`,
      };
    });
  };

  const geShowTypeSelected = (linkedType: LinkedType) => {
    if (linkedType === 'successor') {
      return successorsShowType;
    } else {
      return predecessorsShowType;
    }
  };

  const handleSelectShowType = (linkedType: LinkedType, selected: DropdownItemModel) => {
    if (linkedType === 'predecessor') {
      setPredecessorsShowType(selected);
    } else {
      setSuccessorsShowType(selected);
    }
  };

  const handleSelectItemForLink = (selected: LinkingSuggestions[]) => {
    setSelectedItems(selected);
  };

  const onCloseLinkingItems = () => {
    setLocalSearchValue('');
    setSelectedItems(null);
    setSelectedDeliverablesIds([]);
    setSelectedSfIds([]);
    setTimeout(() => {
      setLinkingItems(null);
    }, 1000);
  };

  const transformDeliverablesList = data => {
    const newData = [];

    data.length &&
      data.forEach(item => {
        const building = newData.find(building => building.id === item.building_id);

        if (building) {
          const drawing = building.drawings.find(draw => draw.id === item.drawing_id);

          if (drawing) {
            drawing.deliverables.push(item);
          } else {
            building.drawings.push({
              id: item.drawing_id,
              parent_id: item.building_id,
              shot_title: item.shot_title,
              title: item.drawing_title,
              deliverables: [item],
            });
          }
        } else {
          newData.push({
            id: item.building_id,
            title: item.building_title,
            drawings: [
              {
                id: item.drawing_id,
                parent_id: item.building_id,
                shot_title: item.shot_title,
                title: item.drawing_title,
                deliverables: [item],
              },
            ],
          });
        }
      });

    setLinkingListLoading(false);

    setFullBuildings(newData);
  };

  const transformSubmittalsList = data => {
    let newData = [];

    data.length &&
      data.forEach(item => {
        const mfCode = newData.find(mf => mf.id === item.mf_id);

        if (mfCode) {
          const sf = mfCode.submittals.find(sf => sf.id === item.id);
          if (!sf) {
            mfCode.submittals.push({ ...item, name: `${item.mf_code} - ${item.mf_title}` });
          }
        } else {
          newData.push({
            is_locked: item.is_locked,
            id: item.mf_id,
            title: `${item.mf_code} - ${item.mf_title}`,
            submittals: [{ ...item, name: `${item.mf_code} - ${item.mf_title}` }],
          });
        }
      });

    if (newData.length) {
      newData = newData.map(mf => {
        if (mf.submittals.length) {
          const sfLength = mf.submittals.filter(sf => sf.is_locked).length;
          return { ...mf, is_locked: mf.submittals.length === sfLength ? 1 : 0 };
        }

        return mf;
      });
    }

    setLinkingListLoading(false);
    setFullMfs(newData);
  };

  const getLinkingSuggestionsData = (linkedType: SnippetType) => {
    if (currentCard === 'Deliverable' && relatedTabType === 'submittalInput') {
      handleRelatedItems('request');
      return;
    }
    if (selectedRequest) {
      setLinkingListLoading(true);
      const newLinkedType =
        (currentCard === 'Nf' || currentCard === 'Deliverable') &&
        (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')
          ? relatedTabType
          : currentCard === 'Submittal' && relatedTabType === 'request'
          ? 'request'
          : currentCard === 'Submittal' && relatedTabType === 'deliverable'
          ? 'deliverable'
          : linkedType;

      dispatch(
        getLinkingItems({
          project_id: selectedRequest.project_id || activeProjectId,
          owner_id:
            currentCard === 'Submittal' || currentCard === 'Nf' ? selectedRequest?.parent_id || selectedRequest.id : selectedRequest.id,
          owner_type: cardTypeMappings[currentCard] as CardType,
          sync_owner_type: newLinkedType,
          callback: data => {
            if (
              newLinkedType === 'deliverable' ||
              (currentCard === 'Deliverable' && (newLinkedType === 'predecessor' || newLinkedType === 'successor'))
            ) {
              transformDeliverablesList(data);
            } else if (
              newLinkedType === 'submittalInput' ||
              newLinkedType === 'submittalOutput' ||
              (currentCard === 'Submittal' && (newLinkedType === 'predecessor' || newLinkedType === 'successor'))
            ) {
              transformSubmittalsList(data);
            } else {
              setLinkingItems(data);
              setLinkingListLoading(false);
            }
          },
        }),
      );
    }
  };

  const getFormattedLinkingItems = (relatedTabType: RelatedItemsTabsType) => {
    if (relatedTabType === 'request') {
      return (
        linkingItems?.map(item => {
          const isLocked = !!item.is_locked;
          const isDisabled = !!item.is_deactivated;

          return {
            ...item,
            value: item.id,
            specific: item.title,
            title: `NF ${item.nf} - ${item.title}`,
            disabled: isDisabled || isLocked,
            tooltip: isDisabled ? 'NF has been deactivated' : item.message,
          };
        }) || []
      );
    }

    if (relatedTabType === 'deliverable') {
      return (
        deliverablesData?.map(item => {
          let disabled = !!item.is_locked;
          let toolTipText = item.message;

          if (item?.deliverables?.length && item?.deliverables.length === item?.deliverables.filter(f => f.is_locked).length) {
            disabled = true;
            toolTipText = 'This drawing is linked already.';
          }

          return {
            ...item,
            disabled: disabled,
            tooltip: toolTipText,
          };
        }) || []
      );
    }

    if (relatedTabType === 'document') {
      return (
        linkingItems?.map(item => {
          const disabled = !!item.is_locked;

          return {
            ...item,
            value: item.id,
            title: `${item.number} ${item.title}`,
            desc: item.title,
            disabled: disabled,
            tooltip: item.message,
          };
        }) || []
      );
    }

    if (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') {
      return (
        submittalsData?.map(item => {
          const isLocked = !!item.is_locked;
          const toolTipText = item.message;
          const isDisabled = !!item.is_deactivated;

          return {
            ...item,
            disabled: isDisabled || isLocked,
            tooltip: isDisabled ? 'Submittal has been deactivated' : toolTipText,
          };
        }) || []
      );
    }

    return (
      linkingItems?.map(item => {
        const disabled = !!item.is_locked;

        return {
          ...item,
          value: item.id,
          title: item.title,
          disabled: disabled,
          tooltip: item.message,
        };
      }) || []
    );
  };

  const getLinkedItems = (linkedType: LinkedType) => {
    if (currentCard === 'Submittal' && relatedTabType === 'request') {
      return relatedTabData[`request_${linkedType}`] || [];
    }
    if (currentCard === 'Submittal' && relatedTabType === 'deliverable') {
      return relatedTabData[`deliverable_${linkedType}`] || [];
    }
    if (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) {
      return relatedTabData[`${relatedTabType}_${linkedType}`] || [];
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') {
      return relatedTabData[`${relatedTabType}_${linkedType}`] || [];
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'request') {
      return relatedTabData[`request`] || [];
    }
    return relatedTabData?.[linkedType] || [];
  };

  const getLinkedItemsForShowType = (linkedType: LinkedType) => {
    const linkedItems = getLinkedItems(linkedType);
    const showType = linkedType === 'successor' ? successorsShowType : predecessorsShowType;

    if (showType?.value === 'outstanding') {
      return linkedItems?.filter(item => item.status !== 'accepted' && !item.is_deactivated) || [];
    }

    if (showType?.value === 'accepted') {
      return linkedItems?.filter(item => item.status === 'accepted') || [];
    }

    if (showType?.value === 'open') {
      return (
        linkedItems?.filter(
          item =>
            item.status === 'drafted' ||
            item.status === 'sent' ||
            item.status === 'submitted' ||
            item.status === 'provided' ||
            item.status === 'declined',
        ) || []
      );
    }

    if (showType?.value === 'all') {
      return linkedItems || [];
    }

    if (showType?.value === 'incorporated in this level/typicality') {
      return linkedItems?.filter(item => item.flags?.is_incorporated) || [];
    }

    if (showType?.value === 'accepted, not incorporated in this level/typicality') {
      return linkedItems?.filter(item => item.status === 'accepted' && !item.flags?.is_incorporated) || [];
    }
  };

  const getLinkedItemsCountForShowType = (linkedType: LinkedType, showType: LinkedItemsShowType) => {
    const linkedItems = getLinkedItems(linkedType);

    if (showType === 'outstanding') {
      return linkedItems?.filter(item => item.status !== 'accepted' && !item.is_deactivated)?.length || 0;
    }

    if (showType === 'accepted') {
      return linkedItems?.filter(item => item.status === 'accepted')?.length || 0;
    }

    if (showType === 'open') {
      return (
        linkedItems?.filter(
          item =>
            item.status === 'drafted' ||
            item.status === 'sent' ||
            item.status === 'submitted' ||
            item.status === 'provided' ||
            item.status === 'declined',
        )?.length || 0
      );
    }

    if (showType === 'all') {
      return linkedItems?.length || 0;
    }

    if (showType === 'incorporated in this level/typicality') {
      return linkedItems?.filter(item => item.flags?.is_incorporated)?.length || 0;
    }

    if (showType === 'accepted, not incorporated in this level/typicality') {
      return linkedItems?.filter(item => item.status === 'accepted' && !item.flags?.is_incorporated)?.length || 0;
    }
  };

  const getButtonName = partType => {
    if (
      (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput' && partType === 'successor') ||
      (currentCard === 'Nf' && relatedTabType === 'deliverable')
    ) {
      return isMobile ? 'Link items' : 'Link successor';
    }
    if (
      (currentCard === 'Nf' && relatedTabType === 'request' && partType) ||
      (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && partType) ||
      (currentCard === 'Deliverable' && relatedTabType === 'deliverable' && partType) ||
      (currentCard === 'Submittal' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && partType) ||
      (currentCard === 'Submittal' && relatedTabType === 'request' && partType) ||
      (currentCard === 'Submittal' && relatedTabType === 'deliverable' && partType)
    ) {
      return isMobile ? 'Link Items' : `Link ${partType}`;
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput' && partType === 'predecessor') {
      return isMobile ? 'Link items' : 'Link predecessor';
    }
    if (isSubmittalOutputSuccessor) {
      return 'Link successor';
    }
    return currentCard === 'Deliverable' && relatedTabType === 'submittalInput' ? 'Go to Related Need Forms' : 'Link Items';
  };

  const renderLinkNfsBasis = (linkedType: LinkedType, type: 'placeholder' | 'contain', isActive: boolean, disabled: boolean) => {
    if (type === 'placeholder') {
      return (
        <CustomButton
          isMobile={isMobile}
          type={'secondary'}
          size={'sm'}
          title={getButtonName(linkedType)}
          isActive={isActive}
          disabled={disabled}
        />
      );
    }

    if (type === 'contain') {
      return (
        <SystemButton
          type={'link'}
          size={'md'}
          variant={'transparent'}
          tooltip={linkedType ? `Link item as ${linkedType}` : 'Link Items'}
          isMobile={isMobile}
          disabled={disabled}
        />
      );
    }
  };

  const renderLinkNfsTitle = (linkSuggestionItem: LinkingSuggestions, linkedType?: LinkedType) => {
    if (relatedTabType === 'request') {
      return (
        <>
          <CardLinkButton
            clickHandler={() => handleOpenRequestPreview(linkSuggestionItem.id, linkSuggestionItem.nf)}
            type={'nf'}
            size={'sm'}
            title={`NF ${linkSuggestionItem.nf}`}
          />
          <span className={`${themeClassDoubleColumn}_${linkedType || relatedTabType}_linkingItemDescription`}>
            {linkSuggestionItem.specific || ''}
          </span>
        </>
      );
    }

    if (relatedTabType === 'deliverable') {
      return <span className={`${themeClassDoubleColumn}_linkingItemSpecific`}>{linkSuggestionItem.title || ''}</span>;
    }

    if (relatedTabType === 'document') {
      return (
        <>
          <ChipsDiscipline type={'filled'} color={'additional'} value={`${linkSuggestionItem?.number || ''}`} />
          <span className={`${themeClassDoubleColumn}_linkingItemSpecific`}>{linkSuggestionItem?.desc || ''}</span>
        </>
      );
    }

    if (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') {
      return <span className={`${themeClassDoubleColumn}_linkingItemSpecific`}>{linkSuggestionItem.title || ''}</span>;
    }
  };

  const renderShowTypeBasis = (type: LinkedType, isActive: boolean, disabled: boolean) => {
    const showType = type === 'predecessor' ? predecessorsShowType : successorsShowType;

    return (
      <FilterSelectBasis
        title={showType?.title}
        isActive={isActive}
        disabled={disabled}
        customClassName={cn(`${themeClassDoubleColumn}_filterSelect_basis`, {
          [`${themeClassDoubleColumn}_filterSelect_sidePeek`]: isDragRequestCard,
        })}
        isMobile={isMobile}
        size={'sm'}
        tooltip={
          isDragRequestCard &&
          (showType?.value === 'incorporated in this level/typicality' ||
            showType?.value === 'accepted, not incorporated in this level/typicality')
            ? `${showType?.title}`
            : ''
        }
      />
    );
  };

  const checkIfOverdue = (item: PredecessorSuccessorModel, type: LinkedType) => {
    let isOverdue;
    let overdueTooltip;

    if (!selectedRequest) {
      isOverdue = false;
      overdueTooltip = '';
      return { isOverdue, overdueTooltip };
    }

    if (type === 'predecessor') {
      isOverdue = item.due_date > selectedRequest.due_date;
      overdueTooltip = 'Due date is later than that of this request’s';
    } else {
      isOverdue = item.due_date < selectedRequest.due_date;
      overdueTooltip = 'Due date is earlier than that of this request’s';
    }

    return { isOverdue, overdueTooltip };
  };

  const snippetArray = (type?: LinkedType) => {
    switch (relatedTabType as RelatedItemsTabsType) {
      case 'request': {
        if (currentCard === 'Submittal') {
          return relatedTabData[`request_${type}`] as SubmittalSnippetModel[] | [];
        }
        if (type) {
          return relatedTabData[type] as RequestSnippetModel[] | [];
        }
        return relatedTabData.request as RequestSnippetModel[] | [];
      }
      case 'deliverable': {
        if (currentCard === 'Submittal') {
          return relatedTabData[`deliverable_${type}`] as DeliverableSnippetModel[] | [];
        }
        if (type) {
          return relatedTabData[type] as DeliverableSnippetModel[] | [];
        }
        return relatedTabData.deliverable as DeliverableSnippetModel[] | [];
      }
      case 'document': {
        return relatedTabData.document as DocumentSnippetModel[] | [];
      }
      case 'submittalInput': {
        if (currentCard === 'Nf') {
          return relatedTabData[`submittalInput_${type}`] as SubmittalSnippetModel[] | [];
        }
        if (type) {
          return relatedTabData[type] as SubmittalSnippetModel[] | [];
        }
        return relatedTabData.submittal as SubmittalSnippetModel[] | [];
      }
      case 'submittalOutput': {
        if (currentCard === 'Nf') {
          return relatedTabData[`submittalOutput_${type}`] as SubmittalSnippetModel[] | [];
        }
        if (currentCard === 'Deliverable') {
          return relatedTabData[`submittalOutput_${type}`] as SubmittalSnippetModel[] | [];
        }
        if (type) {
          return relatedTabData[type] as SubmittalSnippetModel[] | [];
        }
        if (currentCard === 'Submittal') {
          return relatedTabData[`submittalOutput`] as SubmittalSnippetModel[] | [];
        }
        return relatedTabData.submittal as SubmittalSnippetModel[] | [];
      }
      default:
        return [];
    }
  };

  const handleSaveList = (itemList, isSingleChange?: boolean) => {
    const currentOwnerType =
      currentCard === 'Submittal' && relatedTabType === 'submittalOutput'
        ? 'predecessor'
        : currentCard === 'Deliverable' && relatedTabType === 'deliverable'
        ? 'predecessor'
        : relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput'
        ? 'submittal'
        : relatedTabType;

    const owner_id =
      currentCard === 'Submittal' || currentCard === 'Nf' || (currentCard === 'Deliverable' && relatedTabType === 'deliverable')
        ? selectedRequest?.parent_id || selectedRequest.id
        : selectedRequest.id;

    const saveObj = {
      project_id: activeProjectId,
      owner_id,
      owner_type: cardTypeMappings[currentCard] as CardType,
      sync_owner_type: currentOwnerType,
    };

    if (isSingleChange) {
      saveObj[itemList.validity_status] = [itemList.id];
    } else {
      const not_verified = itemList.filter(item => item.validity_status === 'not_verified');
      const not_valid = itemList.filter(item => item.validity_status === 'not_valid');
      const valid = itemList.filter(item => item.validity_status === 'valid');

      if (not_verified.length) {
        saveObj.not_verified = not_verified.map(item => item.id);
      }
      if (not_valid.length) {
        saveObj.not_valid = not_valid.map(item => item.id);
      }
      if (valid.length) {
        saveObj.valid = valid.map(item => item.id);
      }
    }

    if (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) {
      saveObj.submittal_role = selectedPart;
    }

    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput') {
      saveObj.submittal_role = selectedPart;
    }

    if (currentCard === 'Submittal' && (relatedTabType === 'request' || relatedTabType === 'deliverable')) {
      saveObj.submittal_role = selectedPart === 'predecessor' ? 'successor' : 'predecessor';
    }

    const cardType = cardTypeMappings[currentCard];

    handleManageStatusesClose();
    handleCloseValidityStatusPopUp();
    dispatch(
      setValidityStatus({
        validityStatusData: saveObj,
        callback: () => {
          dispatch(
            getFeed({
              owner_id: currentCard === 'Deliverable' ? selectedRequest?.parent_id || selectedRequest.id : owner_id,
              type: cardType,
            }),
          );
        },
      }),
    );
  };

  const getActionText = (type: string) => {
    if (type === 'designInput') {
      return 'Mark <b>this Need Form</b> per <b>selected Drawings</b> as:';
    }
    if (type === 'deliverables') {
      return 'Mark selected Deliverables per this NF as:';
    }
    if (type === 'pcdCardLinkedNfsTab') {
      return 'Mark <b>this Deliverable</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Deliverable request') {
      return 'Mark <b>this Deliverable</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Deliverable deliverable') {
      return 'Mark <b>this Deliverable</b> per <b>selected Deliverables</b> as:';
    }
    if (type === 'Deliverable submittalOutput') {
      return 'Mark <b>this Deliverable</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Submittal submittalOutput') {
      return 'Mark <b>this Submittal</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Nf deliverable') {
      return 'Mark <b>selected Deliverables</b> per <b>this Need Form</b> as:';
    }
    if (type === 'Nf document') {
      return 'Mark this NF per selected Design Documents as:';
    }
    if (type === 'Nf submittalInput') {
      return 'Mark <b>this Need Form</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Nf submittalOutput') {
      return 'Mark <b>selected Submittals</b> per <b>this Need Form</b> as:';
    }
    if (type === 'Submittal request') {
      return 'Mark <b>this Submittal</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Submittal deliverable') {
      return 'Mark <b>this Submittal</b> per <b>selected Deliverables</b> as:';
    }
    if (type === 'Submittal document') {
      return 'Mark this Submittal per selected Design Documents as:';
    }
  };

  const handleOpenValidityStatusPopUp = (e, suc, part) => {
    setIsOpenValidityStatusPopUp(true);
    setSelectedPart(part);
    setValidityAnchorEl(e.currentTarget);
    setOpenedSnippetPopUp({ ...suc });
  };

  const handleCloseValidityStatusPopUp = () => {
    setIsOpenValidityStatusPopUp(false);
    setSelectedPart(null);
    setValidityAnchorEl(null);
    setOpenedSnippetPopUp(null);
  };

  const renderValidityButton = (snippet, type?: LinkedType) => {
    if (currentCard === 'Deliverable' && type !== 'successor') {
      const isIssued = selectedRequest && selectedRequest?.status === 'issued';

      return (
        <ActionButton
          clickHandler={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenValidityStatusPopUp(e, snippet, type);
          }}
          type={snippet?.flags?.validity_status}
          disabled={!isIssued || isDeactivated}
          tooltip={isIssued ? 'Click to change the status' : ''}
          isMobile={isMobile}
        />
      );
    }

    if (
      (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) ||
      (currentCard === 'Nf' && !type) ||
      (currentCard === 'Nf' && type === 'predecessor')
    ) {
      const isDraft = selectedRequest && selectedRequest?.status === 'drafted';

      return (
        <ActionButton
          clickHandler={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenValidityStatusPopUp(e, snippet, type);
          }}
          type={snippet?.flags?.validity_status}
          disabled={isDraft || isDeactivated}
          tooltip={!isDraft ? 'Click to change the status' : ''}
          isMobile={isMobile}
        />
      );
    }

    if (
      (currentCard === 'Submittal' && relatedTabType === 'request') ||
      (currentCard === 'Submittal' && relatedTabType === 'deliverable' && type === 'predecessor') ||
      (currentCard === 'Submittal' && relatedTabType !== 'deliverable' && type === 'successor') ||
      (currentCard === 'Submittal' && selectedRequest?.is_output && relatedTabType === 'submittalOutput')
    ) {
      return (
        <ActionButton
          clickHandler={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenValidityStatusPopUp(e, snippet, type);
          }}
          type={snippet.flags.validity_status}
          disabled={isDeactivated}
          tooltip={'Click to change the status'}
          isMobile={isMobile}
        />
      );
    }

    if (
      (currentCard === 'Nf' && type === 'successor') ||
      (currentCard === 'Submittal' && relatedTabType === 'deliverable' && type === 'successor') ||
      (currentCard === 'Submittal' &&
        !selectedRequest?.is_output &&
        (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) ||
      (currentCard === 'Deliverable' && type === 'successor')
    ) {
      return <></>;
    }

    return null;
  };

  const renderEmptyContainer = (part: LinkedType | undefined, isSingleRelation: boolean) => {
    if (isSingleRelation) {
      if (isMobile) {
        return (
          <div className={cn(`${themeClassEmptyContent}`, { ['-mobile']: isMobile })}>
            <div className={`${themeClassEmptyContent}_placeholderContent`}>
              <EmptyStatePlaceholder type={getEmptyStatePlaceholderType()} isMobile={isMobile} />
              <div className={`${themeClassEmptyContent}_placeholderContent_header`}>
                <div className={`${themeClassEmptyContent}_placeholderContent_header_title`}>
                  {relatedTabType === 'deliverable'
                    ? 'Successor Deliverables'
                    : relatedTabType === 'submittalInput' && currentCard === 'Deliverable'
                    ? 'Input submittals'
                    : relatedTabType === 'submittalInput' && currentCard !== 'Deliverable'
                    ? 'Predecessor Input submittals'
                    : isSubmittalOutputSuccessor
                    ? 'Successor Output submittals'
                    : relatedTabType === 'submittalOutput' && currentCard !== 'Submittal'
                    ? 'Predecessor Output submittals'
                    : currentCard === 'Submittal'
                    ? 'Successor Need Forms'
                    : 'Predecessor Drawings'}
                </div>
                <div className={`${themeClassEmptyContent}_placeholderContent_header_subtitle`}>
                  <>
                    {relatedTabType === 'deliverable' ? (
                      `There are no successor Deliverables here yet.`
                    ) : relatedTabType === 'submittalInput' && currentCard === 'Deliverable' ? (
                      <>
                        Input submittals are connected to CL drawings through Need Forms of <b>CL Input Checklist</b> type
                      </>
                    ) : relatedTabType === 'submittalInput' && currentCard !== 'Deliverable' ? (
                      'There are no predecessor Input submittals here yet.'
                    ) : isSubmittalOutputSuccessor ? (
                      'There are no successor Output submittals here yet.'
                    ) : relatedTabType === 'submittalOutput' && currentCard !== 'Submittal' ? (
                      'There are no predecessor Output submittals here yet.'
                    ) : currentCard === 'Submittal' ? (
                      'There are no successor Need Forms here yet.'
                    ) : (
                      'There are no predecessor Drawings here yet.'
                    )}
                    {relatedTabType === 'submittalInput' && currentCard === 'Deliverable' ? '' : 'Start by linking them.'}
                  </>
                </div>
              </div>
            </div>
            <div
              className={cn(`${themeClassEmptyContent}_placeholderContent_footer`, {
                ['-mobile']: isMobile,
              })}
            >
              {relatedTabType !== 'deliverable' && relatedTabType !== 'submittalInput' && relatedTabType !== 'submittalOutput' && (
                <Select<LinkingSuggestions, null, true>
                  isMulti
                  isHeader
                  isFooter
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType)}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selectHandler={selected => handleSelectItemForLink(selected)}
                  menuItemTitleRenderer={item => renderLinkNfsTitle(item)}
                  selected={selectedItems}
                  isLoading={linkingListLoading}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  menuItemSize={'md'}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  onMenuClose={onCloseLinkingItems}
                  isMobile={isMobile}
                  disabled={isDisabledLinking}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
              {relatedTabType === 'deliverable' && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'deliverables'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={drawingsWithSelectedDeliverables}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleBuildingItemClick={handleBuildingItemClick}
                  handleDrawingItemClick={handleDrawingItemClick}
                  handleDeliverablesItemClick={handleDeliverablesItemClick}
                  openedBuildingIds={openedBuildingIds}
                  openedDrawingIds={openedDrawingIds}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'deliverables')}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                  handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedDeliverablesIds([])}
                  customSelectAll={() => deliverablesCustomSelectAll()}
                  disabled={isDisabledLinking}
                  selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  selectedDeliverablesIds={selectedDeliverablesIds}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
              {(relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'submittals'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={mfWithSelectedSf}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleMfItemClick={handleMfItemClick}
                  handleSubmittalsItemClick={handleSfItemClick}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'submittals')}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedSfIds([])}
                  customSelectAll={() => sfCustomSelectAll()}
                  disabled={isDisabledLinking}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  customDisabled={!!(currentCard === 'Deliverable' && relatedTabType === 'submittalInput')}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
            </div>
          </div>
        );
      }

      return (
        <div className={cn(`${themeClassEmptyContent}`, { ['-mobile']: isMobile })}>
          <EmptyStatePlaceholder type={getEmptyStatePlaceholderType()} isMobile={isMobile} />
          <div className={`${themeClassEmptyContent}_placeholderContent`}>
            <div className={`${themeClassEmptyContent}_placeholderContent_header`}>
              <div className={`${themeClassEmptyContent}_placeholderContent_header_title`}>
                {relatedTabType === 'deliverable'
                  ? 'Successor Deliverables'
                  : relatedTabType === 'submittalInput' && currentCard === 'Deliverable'
                  ? 'Input submittals'
                  : relatedTabType === 'submittalInput' && currentCard !== 'Deliverable'
                  ? 'Predecessor Input submittals'
                  : isSubmittalOutputSuccessor
                  ? 'Successor Output submittals'
                  : relatedTabType === 'submittalOutput' && currentCard !== 'Submittal'
                  ? 'Predecessor Output submittals'
                  : currentCard === 'Submittal'
                  ? 'Successor Need Forms'
                  : 'Predecessor Drawings'}
              </div>
              <div className={`${themeClassEmptyContent}_placeholderContent_header_subtitle`}>
                <>
                  {relatedTabType === 'deliverable' ? (
                    `There are no successor Deliverables here yet.`
                  ) : relatedTabType === 'submittalInput' && currentCard === 'Deliverable' ? (
                    <>
                      Input submittals are connected to CL drawings through Need Forms of <b>CL Input Checklist</b> type
                    </>
                  ) : relatedTabType === 'submittalInput' && currentCard !== 'Deliverable' ? (
                    'There are no predecessor Input submittals here yet.'
                  ) : isSubmittalOutputSuccessor ? (
                    'There are no successor Output submittals here yet.'
                  ) : relatedTabType === 'submittalOutput' && currentCard !== 'Submittal' ? (
                    'There are no predecessor Output submittals here yet.'
                  ) : currentCard === 'Submittal' ? (
                    'There are no successor Need Forms here yet.'
                  ) : (
                    'There are no predecessor Drawings here yet.'
                  )}
                  <br />
                  {relatedTabType === 'submittalInput' && currentCard === 'Deliverable' ? '' : 'Start by linking them.'}
                </>
              </div>
            </div>
            <div
              className={cn(`${themeClassEmptyContent}_placeholderContent_footer`, {
                ['-mobile']: isMobile,
              })}
            >
              {relatedTabType !== 'deliverable' && relatedTabType !== 'submittalInput' && relatedTabType !== 'submittalOutput' && (
                <Select<LinkingSuggestions, null, true>
                  isMulti
                  isHeader
                  isFooter
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType)}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selectHandler={selected => handleSelectItemForLink(selected)}
                  menuItemTitleRenderer={item => renderLinkNfsTitle(item)}
                  selected={selectedItems}
                  isLoading={linkingListLoading}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  menuItemSize={'md'}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  onMenuClose={onCloseLinkingItems}
                  isMobile={isMobile}
                  disabled={isDisabledLinking}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
              {relatedTabType === 'deliverable' && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'deliverables'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={drawingsWithSelectedDeliverables}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleBuildingItemClick={handleBuildingItemClick}
                  handleDrawingItemClick={handleDrawingItemClick}
                  handleDeliverablesItemClick={handleDeliverablesItemClick}
                  openedBuildingIds={openedBuildingIds}
                  openedDrawingIds={openedDrawingIds}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'deliverables')}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                  handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedDeliverablesIds([])}
                  customSelectAll={() => deliverablesCustomSelectAll()}
                  disabled={isDisabledLinking}
                  selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  selectedDeliverablesIds={selectedDeliverablesIds}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
              {(relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'submittals'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={mfWithSelectedSf}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleMfItemClick={handleMfItemClick}
                  handleSubmittalsItemClick={handleSfItemClick}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'submittals')}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedSfIds([])}
                  customSelectAll={() => sfCustomSelectAll()}
                  disabled={isDisabledLinking}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                  customDisabled={!!(currentCard === 'Deliverable' && relatedTabType === 'submittalInput')}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    if (isMobile) {
      return (
        <div className={cn(`${themeClassEmptyContent}`, { ['-mobile']: isMobile })}>
          <div className={`${themeClassEmptyContent}_placeholderContent`}>
            <EmptyStatePlaceholder type={getEmptyStatePlaceholderType(part)} isMobile={isMobile} />
            <div className={`${themeClassEmptyContent}_placeholderContent_header`}>
              <div className={`${themeClassEmptyContent}_placeholderContent_header_title`}>{ucFirst(`${part}s`)}</div>
              <div className={`${themeClassEmptyContent}_placeholderContent_header_subtitle`}>
                <>
                  {`There are no ${part} ${
                    currentCard === 'Deliverable' && relatedTabType === 'deliverable'
                      ? 'Deliverables'
                      : (currentCard === 'Submittal' || currentCard === 'Nf') && relatedTabType === 'submittalInput'
                      ? `Input submittals`
                      : (currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
                        relatedTabType === 'submittalOutput'
                      ? `Output submittals`
                      : currentCard === 'Submittal' && relatedTabType === 'deliverable'
                      ? 'Deliverables'
                      : currentCard === 'Submittal' && relatedTabType === 'request'
                      ? 'Need Forms'
                      : 'Need Forms'
                  } here yet.`}{' '}
                  Start by linking them.
                </>
              </div>
            </div>
          </div>
          <div
            className={cn(`${themeClassEmptyContent}_placeholderContent_footer`, {
              ['-mobile']: isMobile,
            })}
          >
            {(currentCard === 'Nf' || currentCard === 'Submittal') && relatedTabType === 'request' && (
              <Select<LinkingSuggestions, null, true>
                isMulti
                isHeader
                isFooter
                footerType={'divided-button'}
                footerButtonTitle={'Save'}
                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part)}
                footerButtonLoading={isLinkingRelationItemLoading.loading}
                menuItems={getFormattedLinkingItems(relatedTabType)}
                menuItemTitleRenderer={item => renderLinkNfsTitle(item, part)}
                selectHandler={selected => handleSelectItemForLink(selected)}
                selected={selectedItems}
                isLoading={linkingListLoading}
                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                menuItemSize={'md'}
                onMenuOpen={() => getLinkingSuggestionsData(part)}
                onMenuClose={onCloseLinkingItems}
                isMobile={isMobile}
                disabled={isDisabledLinking}
                isActiveCheckBoxForDisabledItems={true}
              />
            )}

            {relatedTabType === 'deliverable' && (
              <CardRenderSelect<DropdownItemModel, null, true>
                type={'deliverables'}
                isMulti
                isCustomMulti={true}
                isHeader
                isFooter
                menuItems={getFormattedLinkingItems(relatedTabType)}
                selected={drawingsWithSelectedDeliverables}
                selectHandler={null}
                isLoading={linkingListLoading}
                menuItemSize={'md'}
                basisMaxItems={3}
                handleBuildingItemClick={handleBuildingItemClick}
                handleDrawingItemClick={handleDrawingItemClick}
                handleDeliverablesItemClick={handleDeliverablesItemClick}
                openedBuildingIds={openedBuildingIds}
                openedDrawingIds={openedDrawingIds}
                footerType={'divided-button'}
                footerButtonTitle={'Save'}
                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, 'deliverables')}
                footerButtonLoading={isLinkingRelationItemLoading.loading}
                isFullWidth={true}
                handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                isHeaderNoMulti={true}
                isExpandOnHover={true}
                customClearAll={() => setSelectedDeliverablesIds([])}
                customSelectAll={() => deliverablesCustomSelectAll()}
                disabled={isDisabledLinking}
                selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                isMobile={isMobile}
                onChangeSearch={handleChangeSearch}
                selectedDeliverablesIds={selectedDeliverablesIds}
                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                onMenuOpen={() => getLinkingSuggestionsData(part)}
                onMenuClose={onCloseLinkingItems}
                isActiveCheckBoxForDisabledItems={true}
              />
            )}
            {(currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
              (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'submittals'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={mfWithSelectedSf}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleMfItemClick={handleMfItemClick}
                  handleSubmittalsItemClick={handleSfItemClick}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, relatedTabType)}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedSfIds([])}
                  customSelectAll={() => sfCustomSelectAll()}
                  disabled={isDisabledLinking}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(part)}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
          </div>
        </div>
      );
    }

    return (
      <div className={cn(`${themeClassEmptyContent}`, { ['-mobile']: isMobile })}>
        <EmptyStatePlaceholder type={getEmptyStatePlaceholderType(part)} isMobile={isMobile} />
        <div className={`${themeClassEmptyContent}_placeholderContent`}>
          <div className={`${themeClassEmptyContent}_placeholderContent_header`}>
            <div className={`${themeClassEmptyContent}_placeholderContent_header_title`}>
              {ucFirst(`${part}`)}{' '}
              {`${
                currentCard === 'Deliverable' && relatedTabType === 'deliverable'
                  ? `Deliverables`
                  : (currentCard === 'Submittal' || currentCard === 'Nf') && relatedTabType === 'submittalInput'
                  ? `Input submittals`
                  : (currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
                    relatedTabType === 'submittalOutput'
                  ? `Output submittals`
                  : currentCard === 'Submittal' && relatedTabType === 'deliverable'
                  ? `Deliverables`
                  : currentCard === 'Submittal' && relatedTabType === 'request'
                  ? `Need Forms`
                  : 'Need Forms'
              }`}
            </div>
            <div className={`${themeClassEmptyContent}_placeholderContent_header_subtitle`}>
              <>
                {`There are no ${part} ${
                  currentCard === 'Deliverable' && relatedTabType === 'deliverable'
                    ? 'Deliverables'
                    : (currentCard === 'Submittal' || currentCard === 'Nf') && relatedTabType === 'submittalInput'
                    ? `Input submittals`
                    : (currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
                      relatedTabType === 'submittalOutput'
                    ? `Output submittals`
                    : currentCard === 'Submittal' && relatedTabType === 'deliverable'
                    ? 'Deliverables'
                    : currentCard === 'Submittal' && relatedTabType === 'request'
                    ? 'Need Forms'
                    : 'Need Forms'
                } here yet.`}
                <br />
                Start by linking them.
              </>
            </div>
          </div>
          <div
            className={cn(`${themeClassEmptyContent}_placeholderContent_footer`, {
              ['-mobile']: isMobile,
            })}
          >
            {(currentCard === 'Nf' || currentCard === 'Submittal') && relatedTabType === 'request' && (
              <Select<LinkingSuggestions, null, true>
                isMulti
                isHeader
                isFooter
                footerType={'divided-button'}
                footerButtonTitle={'Save'}
                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part)}
                footerButtonLoading={isLinkingRelationItemLoading.loading}
                menuItems={getFormattedLinkingItems(relatedTabType)}
                menuItemTitleRenderer={item => renderLinkNfsTitle(item, part)}
                selectHandler={selected => handleSelectItemForLink(selected)}
                selected={selectedItems}
                isLoading={linkingListLoading}
                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                menuItemSize={'md'}
                onMenuOpen={() => getLinkingSuggestionsData(part)}
                onMenuClose={onCloseLinkingItems}
                isMobile={isMobile}
                disabled={isDisabledLinking}
                isActiveCheckBoxForDisabledItems={true}
              />
            )}

            {relatedTabType === 'deliverable' && (
              <CardRenderSelect<DropdownItemModel, null, true>
                type={'deliverables'}
                isMulti
                isCustomMulti={true}
                isHeader
                isFooter
                menuItems={getFormattedLinkingItems(relatedTabType)}
                selected={drawingsWithSelectedDeliverables}
                selectHandler={null}
                isLoading={linkingListLoading}
                menuItemSize={'md'}
                basisMaxItems={3}
                handleBuildingItemClick={handleBuildingItemClick}
                handleDrawingItemClick={handleDrawingItemClick}
                handleDeliverablesItemClick={handleDeliverablesItemClick}
                openedBuildingIds={openedBuildingIds}
                openedDrawingIds={openedDrawingIds}
                footerType={'divided-button'}
                footerButtonTitle={'Save'}
                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, 'deliverables')}
                footerButtonLoading={isLinkingRelationItemLoading.loading}
                isFullWidth={true}
                handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                isHeaderNoMulti={true}
                isExpandOnHover={true}
                customClearAll={() => setSelectedDeliverablesIds([])}
                customSelectAll={() => deliverablesCustomSelectAll()}
                disabled={isDisabledLinking}
                selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                isMobile={isMobile}
                onChangeSearch={handleChangeSearch}
                selectedDeliverablesIds={selectedDeliverablesIds}
                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                onMenuOpen={() => getLinkingSuggestionsData(part)}
                onMenuClose={onCloseLinkingItems}
                isActiveCheckBoxForDisabledItems={true}
              />
            )}
            {(currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
              (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                <CardRenderSelect<DropdownItemModel, null, true>
                  type={'submittals'}
                  isMulti
                  isCustomMulti={true}
                  isHeader
                  isFooter
                  menuItems={getFormattedLinkingItems(relatedTabType)}
                  selected={mfWithSelectedSf}
                  selectHandler={null}
                  isLoading={linkingListLoading}
                  menuItemSize={'md'}
                  basisMaxItems={3}
                  handleMfItemClick={handleMfItemClick}
                  handleSubmittalsItemClick={handleSfItemClick}
                  footerType={'divided-button'}
                  footerButtonTitle={'Save'}
                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, relatedTabType)}
                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                  isFullWidth={true}
                  isHeaderNoMulti={true}
                  isExpandOnHover={true}
                  customClearAll={() => setSelectedSfIds([])}
                  customSelectAll={() => sfCustomSelectAll()}
                  disabled={isDisabledLinking}
                  isMobile={isMobile}
                  onChangeSearch={handleChangeSearch}
                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'placeholder', isActive, disabled)}
                  onMenuOpen={() => getLinkingSuggestionsData(part)}
                  onMenuClose={onCloseLinkingItems}
                  isActiveCheckBoxForDisabledItems={true}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  const hasDataForSingleRelation = !!snippetArray()?.length;
  const isDisabledManageStatuses =
    (currentCard === 'Submittal' && selectedRequest && selectedRequest?.status?.title === 'Deactivated') ||
    (currentCard === 'Deliverable' && selectedRequest && selectedRequest?.status !== 'issued') ||
    (currentCard === 'Nf' && selectedRequest && selectedRequest?.status === 'drafted');
  const isDisabledLinking = isDeactivated;

  const getEmptyStatePlaceholderType = (Part?: string) => {
    switch (relatedTabType as RelatedItemsTabsType) {
      case 'request': {
        if (Part === 'predecessor') {
          return 'related-need-form-pred';
        }
        return 'related-need-form-succ';
      }
      case 'deliverable': {
        if (Part === 'predecessor') {
          return 'related-deliverables-pred';
        }
        return 'related-deliverables-succ';
      }
      case 'document': {
        return 'related-design-document';
      }
      case 'submittalOutput': {
        if (Part === 'successor') {
          return 'related-submittals-succ';
        }
        return 'related-submittals-pred';
      }
      case 'submittalInput': {
        if (Part === 'successor') {
          return 'related-submittals-succ';
        }
        return 'related-submittals-pred';
      }
      default:
        return 'nf-card-linked-nfs';
    }
  };

  const getTooltipInfoBtn = (part?: string) => {
    if (currentCard === 'Nf' && relatedTabType === 'document') {
      return 'Design drawings that are the source information for this Need Form. Changes to Design drawings trigger validating the relation.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'request' && part === 'predecessor') {
      return 'Need Forms that are the source information or blockers for this Need Form.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'request' && part === 'successor') {
      return 'Need Forms that are the follow-up information for this Need Form.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'submittalInput' && part === 'predecessor') {
      return 'Input submittals that are the source information for this Need Form. Changes to Input submittals trigger validating the relation.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'submittalInput' && part === 'successor') {
      return 'Input submittals that are the follow-up information for this Need Form. Changes to Input submittals trigger validating the relation.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'deliverable') {
      return 'CL drawings that incorporate information from this Need Form. Changes to this Need Form trigger validating the relation.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'submittalOutput' && part === 'predecessor') {
      return 'Shop drawings that are the source information for this Need Form. Changes to Output submittals trigger validating the relation.';
    }
    if (currentCard === 'Nf' && relatedTabType === 'submittalOutput' && part === 'successor') {
      return 'Shop drawings that incorporate information from this Need Form. Changes to this Need Form trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 0 && relatedTabType === 'request' && part === 'predecessor') {
      return 'Need Forms that are the source information for this Input submittal. Changes to this Input submittal trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 0 && relatedTabType === 'request' && part === 'successor') {
      return 'Need Forms that are the follow-up information for this Input submittal. Changes to this Input submittal trigger validating the relation.';
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'request') {
      return 'Need Forms that are the source information for this Deliverable. Changes to Need Forms trigger validating the relation.';
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'deliverable' && part === 'predecessor') {
      return 'CL drawings that are the preceding information for this Deliverable. Changes to predecessor CL drawings trigger validating the relation.';
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'deliverable' && part === 'successor') {
      return 'CL drawings that are the succeeding information for this Deliverable. Open the successor card to check the relation status.';
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput' && part === 'predecessor') {
      return 'Shop drawings that are the source information for this Deliverable. Changes to Output submittals trigger validating the relation.';
    }
    if (currentCard === 'Deliverable' && relatedTabType === 'submittalOutput' && part === 'successor') {
      return 'Shop drawings that are the follow-up information for this Deliverable. Open the successor card to check the relation status.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'request' && part === 'predecessor') {
      return 'Need Forms that are the source information for this Shop drawing. Changes to Need Forms trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'request' && part === 'successor') {
      return 'Need Forms that are the follow-up information for this Shop drawing. Changes to this Output submittal trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'deliverable' && part === 'predecessor') {
      return 'CL drawings that are the source information for this Shop drawing. Changes to CL drawings trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'deliverable' && part === 'successor') {
      return 'CL drawings that are the follow-up information for this Shop drawing. Open the successor card to check the relation status.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'submittalOutput' && part === 'predecessor') {
      return 'Shop drawings that are the preceding information for this Output submittal. Changes to predecessor Shop drawings trigger validating the relation.';
    }
    if (currentCard === 'Submittal' && selectedRequest?.is_output === 1 && relatedTabType === 'submittalOutput' && part === 'successor') {
      return 'Shop drawings that are the succeeding information for this Output submittal. Open the successor card to check the relation status.';
    }
  };

  const getMenuItems = (relation: 'single' | 'double', linkedType: LinkedType) => {
    let result;
    if (relation === 'double') {
      result = currentCard === 'Nf' && relatedTabType === 'request' ? getLinkedItemsForShowType(linkedType) : getLinkedItems(linkedType);
    }
    if (relation === 'single') {
      result = currentCard === 'Deliverable' && relatedTabType === 'request' ? getLinkedItemsForShowType(linkedType) : snippetArray();
    }

    let isFiltering = false;

    if (relation === 'single' && isSubmittalOutputSuccessor ? selectedFilteringSuccessor?.length : selectedFilteringPredecessor?.length)
      isFiltering = true;
    if (relation === 'double' && linkedType === 'predecessor' && selectedFilteringPredecessor?.length) isFiltering = true;
    if (relation === 'double' && linkedType === 'successor' && selectedFilteringSuccessor?.length) isFiltering = true;

    if (isFiltering) {
      const filteringParams = linkedType === 'predecessor' ? selectedFilteringPredecessor : selectedFilteringSuccessor;

      if (filteringParams?.length && result?.length) {
        result = getFilteredData(result, filteringParams, checkIfOverdue, linkedType);
      }
    }

    let isGrouping = false;

    if (relation === 'single' && isSubmittalOutputSuccessor ? selectedGroupingSuccessor?.length : selectedGroupingPredecessor?.length)
      isGrouping = true;
    if (relation === 'double' && linkedType === 'predecessor' && selectedGroupingPredecessor?.length) isGrouping = true;
    if (relation === 'double' && linkedType === 'successor' && selectedGroupingSuccessor?.length) isGrouping = true;

    if (isGrouping) {
      const groupedData = sortGroupingObject(
        groupByFields(result, linkedType === 'predecessor' ? selectedGroupingPredecessor : selectedGroupingSuccessor),
      );

      const accordionCounter = getAccordionTitles(groupedData, false).length;

      if (linkedType === 'predecessor' && predecessorAccordionCounter !== accordionCounter) {
        setPredecessorAccordionCounter(accordionCounter);
      }
      if (linkedType === 'successor' && successorAccordionCounter !== accordionCounter) {
        setSuccessorAccordionCounter(accordionCounter);
      }

      return groupedData;
    }

    return result;
  };

  const groupByFields = (data, fields) => {
    if (fields?.length === 0) {
      return data;
    }

    const field = fields[0].value;
    const groupedData = groupBy(data, row => getGroupingSnippetValue(row, field));

    if (field === 'validityStatus' && groupedData.hasOwnProperty('not_verified')) {
      const notVerifiedArray = [...groupedData['not_verified']];
      groupedData['not_verified'] = groupBy(notVerifiedArray, row => getGroupingSnippetValue(row, 'validity_alert'));
    }

    // Recursively group by remaining fields
    Object.keys(groupedData).forEach(key => {
      groupedData[key] = groupByFields(groupedData[key], fields.slice(1));
    });

    return groupedData;
  };

  const handleExpandAccordion = (key: string, linkedType: LinkedType) => {
    if (linkedType === 'predecessor') {
      if (expandedAccordionKeysPredecessor.includes(key)) {
        setExpandedAccordionKeysPredecessor(expandedAccordionKeysPredecessor.filter(expandedKey => expandedKey !== key));
      } else {
        setExpandedAccordionKeysPredecessor([...expandedAccordionKeysPredecessor, key]);
      }
    }

    if (linkedType === 'successor') {
      if (expandedAccordionKeysSuccessor.includes(key)) {
        setExpandedAccordionKeysSuccessor(expandedAccordionKeysSuccessor.filter(expandedKey => expandedKey !== key));
      } else {
        setExpandedAccordionKeysSuccessor([...expandedAccordionKeysSuccessor, key]);
      }
    }
  };

  const handleExpandAll = (relation: 'single' | 'double', linkedType: LinkedType) => {
    const data = getMenuItems(relation, linkedType);

    const result = getAccordionTitles(data, true);

    if (linkedType === 'predecessor') {
      setExpandedAccordionKeysPredecessor(result);
    }

    if (linkedType === 'successor') {
      setExpandedAccordionKeysSuccessor(result);
    }
  };

  const getAccordionTitles = (data, collectParentKeys = false) => {
    const titles = [];

    const traverse = (obj, parentKey = '') => {
      for (const key in obj) {
        const currentKey = collectParentKeys && parentKey ? `${key}-${parentKey}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          titles.push(currentKey);
          traverse(obj[key], collectParentKeys ? currentKey : '');
        } else if (Array.isArray(obj[key])) {
          titles.push(currentKey);
        }
      }
    };

    traverse(data);
    return titles;
  };

  const handleCollapseAll = (linkedType: LinkedType) => {
    if (linkedType === 'predecessor') {
      setExpandedAccordionKeysPredecessor([]);
    }

    if (linkedType === 'successor') {
      setExpandedAccordionKeysSuccessor([]);
    }
  };

  return (
    <>
      {/*'DoubleRelation'*/}
      {showDoubleRelation && (
        <>
          <div
            className={cn(themeClassDoubleColumn, {
              [`-sidePeek`]: isDragRequestCard,
              [`-deliverableCard`]: currentCard === 'Deliverable',
            })}
          >
            {linkedParts.map(part => {
              const linkedDataByShowType = getMenuItems('double', part);

              const selectedShowType = geShowTypeSelected(part);
              const showTypeItems = getShowTypeOptions(part);
              const hasDataForDoubleRelation = !!snippetArray(part)?.length;
              const isShowMoreItems = isShowMore[part];

              const isGrouping = part === 'predecessor' ? !!selectedGroupingPredecessor?.length : !!selectedGroupingSuccessor?.length;
              const isAllExpanded =
                part === 'predecessor'
                  ? selectedGroupingPredecessor?.length && predecessorAccordionCounter === expandedAccordionKeysPredecessor?.length
                  : selectedGroupingSuccessor?.length && successorAccordionCounter === expandedAccordionKeysSuccessor?.length;

              return (
                <>
                  <div
                    key={part}
                    className={cn(`${themeClassDoubleColumn}_${part}`, {
                      [`-empty`]: !hasDataForDoubleRelation && !isRelationTabLoading.loading,
                      [`-sidePeek`]: isDragRequestCard,
                      [`-mobile`]: isMobile,
                    })}
                  >
                    {hasDataForDoubleRelation ? (
                      <div className={`${themeClassDoubleColumn}_${part}_content`}>
                        <div className={cn(`${themeClassDoubleColumn}_${part}_content_header`, { ['-mobile']: isMobile })}>
                          <div className={`${themeClassDoubleColumn}_${part}_content_header_headerContainer`}>
                            <div className={cn(`${themeClassDoubleColumn}_${part}_content_header_title`, { ['-mobile']: isMobile })}>
                              <div className={`${themeClassDoubleColumn}_${part}_content_header_title_infoBtn`}>
                                <SystemButton
                                  tooltip={getTooltipInfoBtn(part)}
                                  type={'information'}
                                  size={'md'}
                                  variant={'transparent'}
                                  placement="top"
                                />
                              </div>
                              {isMobile && <TokenIcon iconName={part} size={24} />}
                              {ucFirst(part)}s
                            </div>
                            {currentCard === 'Nf' && relatedTabType === 'request' && (
                              <Select<DropdownItemModel, null, false>
                                isMulti={false}
                                menuItems={showTypeItems}
                                selectHandler={selected => handleSelectShowType(part, selected)}
                                selected={selectedShowType}
                                isLoading={false}
                                renderCustomBasis={(isActive, disabled) => renderShowTypeBasis(part, isActive, disabled)}
                                menuItemSize={'sm'}
                                isMobile={isMobile}
                                disabled={isDisabledLinking}
                              />
                            )}
                            {(currentCard === 'Nf' || currentCard === 'Submittal') && relatedTabType === 'request' && (
                              <Select<LinkingSuggestions, null, true>
                                isMulti
                                isHeader
                                isFooter
                                footerType={'divided-button'}
                                footerButtonTitle={'Save'}
                                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part)}
                                footerButtonLoading={isLinkingRelationItemLoading.loading}
                                menuItems={getFormattedLinkingItems(relatedTabType)}
                                selectHandler={selected => handleSelectItemForLink(selected)}
                                menuItemTitleRenderer={item => renderLinkNfsTitle(item, part)}
                                selected={selectedItems}
                                isLoading={linkingListLoading}
                                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'contain', isActive, disabled)}
                                menuItemSize={'md'}
                                onMenuOpen={() => getLinkingSuggestionsData(part)}
                                onMenuClose={onCloseLinkingItems}
                                customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                                customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                                disabled={isDisabledLinking}
                                isMobile={isMobile}
                                isActiveCheckBoxForDisabledItems={true}
                              />
                            )}
                            {relatedTabType === 'deliverable' && (
                              <CardRenderSelect<DropdownItemModel, null, true>
                                type={'deliverables'}
                                isMulti
                                isCustomMulti={true}
                                isHeader
                                isFooter
                                menuItems={getFormattedLinkingItems(relatedTabType)}
                                selected={drawingsWithSelectedDeliverables}
                                selectHandler={null}
                                isLoading={linkingListLoading}
                                menuItemSize={'md'}
                                basisMaxItems={3}
                                handleBuildingItemClick={handleBuildingItemClick}
                                handleDrawingItemClick={handleDrawingItemClick}
                                handleDeliverablesItemClick={handleDeliverablesItemClick}
                                footerType={'divided-button'}
                                footerButtonTitle={'Save'}
                                footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, 'deliverables')}
                                footerButtonLoading={isLinkingRelationItemLoading.loading}
                                isFullWidth={true}
                                handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                                handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                                isHeaderNoMulti={true}
                                isExpandOnHover={true}
                                customClearAll={() => setSelectedDeliverablesIds([])}
                                customSelectAll={() => deliverablesCustomSelectAll()}
                                disabled={isDisabledLinking}
                                selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                                isMobile={isMobile}
                                onChangeSearch={handleChangeSearch}
                                selectedDeliverablesIds={selectedDeliverablesIds}
                                renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'contain', isActive, disabled)}
                                onMenuOpen={() => getLinkingSuggestionsData(part)}
                                onMenuClose={onCloseLinkingItems}
                                customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                                customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                                isActiveCheckBoxForDisabledItems={true}
                              />
                            )}
                            {(currentCard === 'Submittal' || currentCard === 'Nf' || currentCard === 'Deliverable') &&
                              (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                                <CardRenderSelect<DropdownItemModel, null, true>
                                  type={'submittals'}
                                  isMulti
                                  isCustomMulti={true}
                                  isHeader
                                  isFooter
                                  menuItems={getFormattedLinkingItems(relatedTabType)}
                                  selected={mfWithSelectedSf}
                                  selectHandler={null}
                                  isLoading={linkingListLoading}
                                  menuItemSize={'md'}
                                  basisMaxItems={3}
                                  handleMfItemClick={handleMfItemClick}
                                  handleSubmittalsItemClick={handleSfItemClick}
                                  footerType={'divided-button'}
                                  footerButtonTitle={'Save'}
                                  footerButtonClick={closeMenu => handleLinkItems(closeMenu, part, relatedTabType)}
                                  footerButtonLoading={isLinkingRelationItemLoading.loading}
                                  isFullWidth={true}
                                  isHeaderNoMulti={true}
                                  isExpandOnHover={true}
                                  customClearAll={() => setSelectedSfIds([])}
                                  customSelectAll={() => sfCustomSelectAll()}
                                  disabled={isDisabledLinking}
                                  isMobile={isMobile}
                                  onChangeSearch={handleChangeSearch}
                                  renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(part, 'contain', isActive, disabled)}
                                  onMenuOpen={() => getLinkingSuggestionsData(part)}
                                  onMenuClose={onCloseLinkingItems}
                                  customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                                  customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                                  isActiveCheckBoxForDisabledItems={true}
                                />
                              )}
                          </div>
                          <div className={`${themeClassDoubleColumn}_${part}_relatedFilterBtns`}>
                            {!isChanged && (
                              <RelatedItemsFiltersSelect
                                currentCard={currentCard}
                                relatedTabType={relatedTabType}
                                part={part}
                                selectedShowType={selectedShowType?.value}
                                linkedDataByShowType={linkedDataByShowType}
                                setSelectedParams={handleAddFilteringValue}
                                isGrouping={isGrouping}
                                checkIfOverdue={checkIfOverdue}
                                selectedRequest={selectedRequest}
                              />
                            )}
                            {currentCard === 'Nf' &&
                            (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') &&
                            part === 'successor' ? null : (
                              <RelatedItemsGroupingSelect
                                currentCard={currentCard}
                                relatedTabType={relatedTabType}
                                part={part}
                                selectedShowType={selectedShowType?.value}
                                selectedGrouping={part === 'predecessor' ? selectedGroupingPredecessor : selectedGroupingSuccessor}
                                handleAddGroupingValue={handleAddGroupingValue}
                                selectedRequest={selectedRequest}
                              />
                            )}

                            {!isMobile &&
                              ((currentCard === 'Deliverable' && part === 'predecessor') ||
                                (currentCard === 'Nf' && (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput')) ||
                                (currentCard === 'Submittal' && relatedTabType === 'request') ||
                                (currentCard === 'Submittal' &&
                                  (relatedTabType === 'deliverable' || relatedTabType === 'submittalOutput') &&
                                  part === 'predecessor')) && (
                                <CustomButton
                                  type={'text-plain'}
                                  size={'xs'}
                                  title={'Manage statuses'}
                                  clickHandler={() => handleManageStatusesOpen(part)}
                                  disabled={isDisabledManageStatuses}
                                />
                              )}
                            {currentCard === 'Nf' &&
                            (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') &&
                            part === 'successor' ? null : (
                              <CustomButton
                                type={'text-plain'}
                                size={'xs'}
                                title={isAllExpanded ? 'Collapse all' : 'Expand all'}
                                clickHandler={isAllExpanded ? () => handleCollapseAll(part) : () => handleExpandAll('double', part)}
                                isMobile={isMobile}
                                disabled={!isGrouping}
                              />
                            )}
                          </div>
                        </div>
                        <div className={cn(`${themeClassDoubleColumn}_${part}_content_snippetGroup`, { ['-mobile']: isMobile })}>
                          {hasDataForDoubleRelation && isGrouping ? (
                            <RelatedItemsGroupingItem
                              groupingData={linkedDataByShowType}
                              openCard={openCard}
                              handleUnlinkItem={handleUnlinkItem}
                              relatedTabType={relatedTabType}
                              currentCard={currentCard}
                              isDragRequestCard={isDragRequestCard}
                              renderValidityButton={renderValidityButton}
                              part={part}
                              relation={'double'}
                              checkIfOverdue={checkIfOverdue}
                              expandedAccordionKeys={
                                part === 'predecessor' ? expandedAccordionKeysPredecessor : expandedAccordionKeysSuccessor
                              }
                              handleExpandAccordion={handleExpandAccordion}
                              selectedGrouping={part === 'predecessor' ? selectedGroupingPredecessor : selectedGroupingSuccessor}
                              selectedRequest={selectedRequest}
                            />
                          ) : (
                            [...(isMobile && !isShowMoreItems ? linkedDataByShowType?.slice(0, 6) || [] : linkedDataByShowType || [])]?.map(
                              item => {
                                return (
                                  <RelatedItemsSnippet
                                    item={item}
                                    handleOpenCard={() => openCard(item)}
                                    handleUnlink={() => handleUnlinkItem(item, part)}
                                    relatedTabType={relatedTabType}
                                    snippetData={item}
                                    currentCard={currentCard}
                                    isDragRequestCard={isDragRequestCard}
                                    validityStatusIcon={renderValidityButton(item, part)}
                                    part={part}
                                    relation={'double'}
                                    checkIfOverdue={checkIfOverdue}
                                    openedRequest={selectedRequest}
                                  />
                                );
                              },
                            )
                          )}
                          {isMobile && !isGrouping && linkedDataByShowType?.length > 6 && (
                            <CustomButton
                              iconClass={`${themeClassDoubleColumn}_showMore_icon`}
                              icon={<TokenIcon iconName={isShowMoreItems ? 'dash' : 'plus'} size={20} />}
                              type={'text-plain'}
                              size={'xs'}
                              customClassName={`${themeClassDoubleColumn}_showMore`}
                              title={isShowMoreItems ? 'Show less' : 'Show more'}
                              isMobile={isMobile}
                              clickHandler={() => handleToggleShowMore(part)}
                            />
                          )}
                        </div>
                        {isMobile && (
                          <>
                            {part === 'predecessor' && (
                              <Divider
                                customClassname={`${themeClassDoubleColumn}_${part}_mobile_divider`}
                                direction={'horizontal'}
                                type={'srf-5'}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        {isRelationTabLoading.loading ? (
                          <div className={`${themeClassDoubleColumn}_${part}_content`}>
                            <div className={`${themeClassDoubleColumn}_skeleton_header`}></div>
                            <div className={`${themeClassDoubleColumn}_${part}_content_snippetGroup_loading`}>
                              <CustomSkeleton width={'100%'} height={30} />
                              <CustomSkeleton width={'100%'} height={30} />
                              <CustomSkeleton width={'100%'} height={30} />
                              <CustomSkeleton width={'100%'} height={30} />
                            </div>
                          </div>
                        ) : (
                          renderEmptyContainer(part, false)
                        )}
                      </>
                    )}
                  </div>
                  {hasDataForDoubleRelation && part === 'predecessor' && <Divider direction={'vertical'} type={'srf-5'} />}
                </>
              );
            })}
          </div>
        </>
      )}
      {/*'SingleRelation'*/}
      {showSingleRelation && (
        <>
          <div
            className={cn(themeClassSingleColumn, {
              [`-sidePeek`]: isDragRequestCard,
              [`-deliverableCard`]: currentCard === 'Deliverable' && relatedTabType === 'submittalOutput',
              [`-deliverableCardNfs`]: currentCard === 'Deliverable' && relatedTabType === 'request',
            })}
          >
            <div
              className={cn(`${themeClassSingleColumn}_container`, {
                [`-empty`]: !hasDataForSingleRelation && !isRelationTabLoading.loading,
                [`-sidePeek`]: isDragRequestCard,
                [`-mobile`]: isMobile,
              })}
            >
              {hasDataForSingleRelation ? (
                <div className={`${themeClassSingleColumn}_content`}>
                  <div className={cn(`${themeClassSingleColumn}_content_header`, { ['-mobile']: isMobile })}>
                    <div className={`${themeClassSingleColumn}_content_header_headerContainer`}>
                      <div className={cn(`${themeClassSingleColumn}_content_header_title`, { ['-mobile']: isMobile })}>
                        <div className={`${themeClassSingleColumn}_content_header_title_infoBtn`}>
                          <SystemButton
                            tooltip={getTooltipInfoBtn()}
                            type={'information'}
                            size={'md'}
                            variant={'transparent'}
                            placement="top"
                          />
                        </div>
                        {isMobile && (
                          <TokenIcon
                            iconName={
                              currentCard === 'Deliverable' && relatedTabType === 'request'
                                ? 'predecessor'
                                : isSubmittalOutputSuccessor
                                ? 'successor'
                                : relatedTabType === 'submittalInput' ||
                                  relatedTabType === 'submittalOutput' ||
                                  relatedTabType === 'document'
                                ? 'predecessor'
                                : 'successor'
                            }
                            size={24}
                          />
                        )}
                        {currentCard === 'Deliverable' && relatedTabType === 'request'
                          ? ucFirst('predecessors')
                          : isSubmittalOutputSuccessor
                          ? ucFirst('successors')
                          : relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput' || relatedTabType === 'document'
                          ? ucFirst('predecessors')
                          : ucFirst('successors')}
                      </div>
                      {currentCard === 'Deliverable' && relatedTabType === 'request' && (
                        <Select<DropdownItemModel, null, false>
                          isMulti={false}
                          menuItems={getShowTypeOptions('predecessor')}
                          selectHandler={selected => handleSelectShowType('predecessor', selected)}
                          selected={geShowTypeSelected('predecessor')}
                          isLoading={false}
                          renderCustomBasis={(isActive, disabled) => renderShowTypeBasis('predecessor', isActive, disabled)}
                          menuItemSize={'sm'}
                          isMobile={isMobile}
                          disabled={isDisabledLinking}
                        />
                      )}
                      {relatedTabType === 'request' && (
                        <Select<LinkingSuggestions, null, true>
                          isMulti
                          isHeader
                          isFooter
                          footerType={'divided-button'}
                          footerButtonTitle={'Save'}
                          footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType)}
                          footerButtonLoading={isLinkingRelationItemLoading.loading}
                          menuItems={getFormattedLinkingItems(relatedTabType)}
                          selectHandler={selected => handleSelectItemForLink(selected)}
                          menuItemTitleRenderer={item => renderLinkNfsTitle(item)}
                          selected={selectedItems}
                          isLoading={linkingListLoading}
                          renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'contain', isActive, disabled)}
                          menuItemSize={'md'}
                          onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                          onMenuClose={onCloseLinkingItems}
                          customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                          customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                          disabled={isDisabledLinking}
                          isMobile={isMobile}
                          isActiveCheckBoxForDisabledItems={true}
                        />
                      )}
                      {relatedTabType === 'deliverable' && (
                        <CardRenderSelect<DropdownItemModel, null, true>
                          type={'deliverables'}
                          isMulti
                          isCustomMulti={true}
                          isHeader
                          isFooter
                          menuItems={getFormattedLinkingItems(relatedTabType)}
                          selected={drawingsWithSelectedDeliverables}
                          selectHandler={null}
                          isLoading={linkingListLoading}
                          menuItemSize={'md'}
                          basisMaxItems={3}
                          handleBuildingItemClick={handleBuildingItemClick}
                          handleDrawingItemClick={handleDrawingItemClick}
                          handleDeliverablesItemClick={handleDeliverablesItemClick}
                          footerType={'divided-button'}
                          footerButtonTitle={'Save'}
                          footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'deliverables')}
                          footerButtonLoading={isLinkingRelationItemLoading.loading}
                          isFullWidth={true}
                          handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                          handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                          isHeaderNoMulti={true}
                          isExpandOnHover={true}
                          customClearAll={() => setSelectedDeliverablesIds([])}
                          customSelectAll={() => deliverablesCustomSelectAll()}
                          disabled={isDisabledLinking}
                          selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                          isMobile={isMobile}
                          onChangeSearch={handleChangeSearch}
                          selectedDeliverablesIds={selectedDeliverablesIds}
                          renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'contain', isActive, disabled)}
                          onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                          onMenuClose={onCloseLinkingItems}
                          customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                          customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                          isActiveCheckBoxForDisabledItems={true}
                        />
                      )}
                      {(relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') && (
                        <CardRenderSelect<DropdownItemModel, null, true>
                          type={'submittals'}
                          isMulti
                          isCustomMulti={true}
                          isHeader
                          isFooter
                          menuItems={getFormattedLinkingItems(relatedTabType)}
                          selected={mfWithSelectedSf}
                          selectHandler={null}
                          isLoading={linkingListLoading}
                          menuItemSize={'md'}
                          basisMaxItems={3}
                          handleMfItemClick={handleMfItemClick}
                          handleSubmittalsItemClick={handleSfItemClick}
                          footerType={'divided-button'}
                          footerButtonTitle={'Save'}
                          footerButtonClick={closeMenu => handleLinkItems(closeMenu, relatedTabType, 'submittals')}
                          footerButtonLoading={isLinkingRelationItemLoading.loading}
                          isFullWidth={true}
                          isHeaderNoMulti={true}
                          isExpandOnHover={true}
                          customClearAll={() => setSelectedSfIds([])}
                          customSelectAll={() => sfCustomSelectAll()}
                          disabled={isDisabledLinking}
                          isMobile={isMobile}
                          onChangeSearch={handleChangeSearch}
                          renderCustomBasis={(isActive, disabled) => renderLinkNfsBasis(null, 'contain', isActive, disabled)}
                          onMenuOpen={() => getLinkingSuggestionsData(relatedTabType)}
                          onMenuClose={onCloseLinkingItems}
                          customBasisContainerClass={`${themeClassDoubleColumn}_linkButton`}
                          customSelectClass={`${themeClassDoubleColumn}_linkButton`}
                          isActiveCheckBoxForDisabledItems={true}
                        />
                      )}
                    </div>
                    <div className={`${themeClassSingleColumn}_relatedFilterBtns`}>
                      {!isChanged && (
                        <RelatedItemsFiltersSelect
                          currentCard={currentCard}
                          relatedTabType={relatedTabType}
                          part={isSubmittalOutputSuccessor ? 'successor' : 'predecessor'}
                          selectedShowType={geShowTypeSelected('predecessor')?.value}
                          linkedDataByShowType={getMenuItems('single', isSubmittalOutputSuccessor ? 'successor' : 'predecessor')}
                          setSelectedParams={handleAddFilteringValue}
                          isGrouping={
                            isSubmittalOutputSuccessor ? !!selectedGroupingSuccessor?.length : !!selectedGroupingPredecessor?.length
                          }
                          checkIfOverdue={checkIfOverdue}
                          selectedRequest={selectedRequest}
                        />
                      )}
                      <RelatedItemsGroupingSelect
                        currentCard={currentCard}
                        relatedTabType={relatedTabType}
                        selectedShowType={geShowTypeSelected('predecessor')?.value}
                        selectedGrouping={isSubmittalOutputSuccessor ? selectedGroupingSuccessor : selectedGroupingPredecessor}
                        handleAddGroupingValue={handleAddGroupingValue}
                        part={isSubmittalOutputSuccessor ? 'successor' : 'predecessor'}
                        selectedRequest={selectedRequest}
                      />
                      {!isMobile &&
                        (currentCard !== 'Submittal' ||
                          (currentCard === 'Submittal' && !!selectedRequest?.is_output && relatedTabType === 'submittalOutput')) && (
                          <CustomButton
                            type={'text-plain'}
                            size={'xs'}
                            title={'Manage statuses'}
                            clickHandler={() => handleManageStatusesOpen('')}
                            disabled={isDisabledManageStatuses}
                          />
                        )}
                      <CustomButton
                        type={'text-plain'}
                        size={'xs'}
                        title={
                          selectedGroupingPredecessor?.length && predecessorAccordionCounter === expandedAccordionKeysPredecessor?.length
                            ? 'Collapse all'
                            : 'Expand all'
                        }
                        clickHandler={
                          selectedGroupingPredecessor?.length && predecessorAccordionCounter === expandedAccordionKeysPredecessor?.length
                            ? () => handleCollapseAll('predecessor')
                            : () => handleExpandAll('single', 'predecessor')
                        }
                        isMobile={isMobile}
                        disabled={!selectedGroupingPredecessor?.length}
                      />
                    </div>
                  </div>
                  <div className={cn(`${themeClassSingleColumn}_content_snippetGroup`, { ['-mobile']: isMobile })}>
                    {hasDataForSingleRelation &&
                    (isSubmittalOutputSuccessor ? !!selectedGroupingSuccessor?.length : !!selectedGroupingPredecessor?.length) ? (
                      <RelatedItemsGroupingItem
                        groupingData={getMenuItems('single', isSubmittalOutputSuccessor ? 'successor' : 'predecessor')}
                        openCard={openCard}
                        handleUnlinkItem={handleUnlinkItem}
                        relatedTabType={relatedTabType}
                        currentCard={currentCard}
                        isDragRequestCard={isDragRequestCard}
                        renderValidityButton={renderValidityButton}
                        part={isSubmittalOutputSuccessor ? 'successor' : 'predecessor'}
                        relation={'single'}
                        checkIfOverdue={checkIfOverdue}
                        expandedAccordionKeys={
                          isSubmittalOutputSuccessor ? expandedAccordionKeysSuccessor : expandedAccordionKeysPredecessor
                        }
                        handleExpandAccordion={handleExpandAccordion}
                        selectedGrouping={isSubmittalOutputSuccessor ? selectedGroupingSuccessor : selectedGroupingPredecessor}
                        selectedRequest={selectedRequest}
                      />
                    ) : (
                      getMenuItems('single', isSubmittalOutputSuccessor ? 'successor' : 'predecessor')?.map(item => {
                        return (
                          <RelatedItemsSnippet
                            item={item}
                            handleOpenCard={() => openCard(item)}
                            handleUnlink={() => handleUnlinkItem(item, isSubmittalOutputSuccessor ? 'successor' : 'predecessor')}
                            relatedTabType={relatedTabType}
                            snippetData={item}
                            currentCard={currentCard}
                            isDragRequestCard={isDragRequestCard}
                            validityStatusIcon={renderValidityButton(item, 'predecessor')}
                            part={isSubmittalOutputSuccessor ? 'successor' : 'predecessor'}
                            relation={'single'}
                            isDeactivated={isDeactivated}
                            checkIfOverdue={checkIfOverdue}
                            openedRequest={selectedRequest}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {isRelationTabLoading.loading ? (
                    <div className={`${themeClassSingleColumn}_content`}>
                      <div className={`${themeClassSingleColumn}_skeleton_header`}></div>
                      <div className={`${themeClassSingleColumn}_content_snippetGroup_loading`}>
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                        <CustomSkeleton width={676} height={30} />
                      </div>
                    </div>
                  ) : (
                    renderEmptyContainer(null, true)
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {isOpenValidityStatusPopUp && (
        <Popover
          id={'select-validity'}
          open={isOpenValidityStatusPopUp}
          anchorEl={validityAnchorEl}
          onClose={handleCloseValidityStatusPopUp}
          anchorOrigin={{
            vertical: isDragRequestCard ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: isDragRequestCard ? 'bottom' : 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClassValidityStatusPopUp}_popOver`, {
              ['isSidePeek']: isDragRequestCard,
              ['-mobile']: isMobile,
            }),
          }}
        >
          <div className={`${themeClassValidityStatusPopUp}_notVerifiedPopup`}>
            <div className={`${themeClassValidityStatusPopUp}_notVerifiedPopup_messageBlock`}>
              <span className={`${themeClassValidityStatusPopUp}_notVerifiedPopup_messageBlock_title`}></span>
              <span className={`${themeClassValidityStatusPopUp}_notVerifiedPopup_messageBlock_text`}>
                {openedSnippetPopUp.flags?.validity_alert}
              </span>
              <span className={`${themeClassValidityStatusPopUp}_notVerifiedPopup_messageBlock_text`}>
                {ReactHtmlParser(getActionText(`${currentCard} ${relatedTabType}`))}
              </span>
            </div>
            <div className={`${themeClassValidityStatusPopUp}_notVerifiedPopup_btnContainer`}>
              <CustomButton
                icon={<TokenIcon iconName={'valid'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={() => handleSaveList({ validity_status: 'valid', id: openedSnippetPopUp.id }, true)}
                title={'Actual'}
                isMobile={isMobile}
                customClassName={`${themeClassValidityStatusPopUp}_notVerifiedPopup_btnContainer_button`}
                customTooltipBasisClassName={`${themeClassValidityStatusPopUp}_notVerifiedPopup_btnContainer_btnTooltipBasis`}
              />
              <CustomButton
                icon={<TokenIcon iconName={'not-valid'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={() => handleSaveList({ validity_status: 'not_valid', id: openedSnippetPopUp.id }, true)}
                title={'Outdated'}
                isMobile={isMobile}
                customClassName={`${themeClassValidityStatusPopUp}_notVerifiedPopup_btnContainer_button`}
                customTooltipBasisClassName={`${themeClassValidityStatusPopUp}_notVerifiedPopup_btnContainer_btnTooltipBasis`}
              />
            </div>
          </div>
        </Popover>
      )}
      {isManageStatusesOpen && (
        <ManageStatuses
          type={`${currentCard} ${relatedTabType}`}
          part={selectedPart}
          popUpIsOpen={isManageStatusesOpen}
          relatedTabData={relatedTabData}
          handleClose={handleManageStatusesClose}
          handleSaveList={handleSaveList}
          handleOpenCard={openCard}
        />
      )}
    </>
  );
};

export default withRouterHelper(RelatedItemsTab);
