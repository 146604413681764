import React, { FC, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import { iconsChart } from './contants';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';

import './ReportingComposedLabelsStyle.scss';

const ReportingComposedLabels: FC<{
  barChartList: any;
  isHoverPie: boolean;
}> = ({ barChartList, isHoverPie }) => {
  const themeClass = useGetThemeClass('b-reportingComposedLabels');

  return (
    <div className={`${isHoverPie ? `${themeClass} -zIdx` : `${themeClass}`}`}>
      <div className={'rep-label-list'}>
        {barChartList
          ? barChartList.map(item => {
              return (
                <div className={'rep-label-list-item'} key={item.title}>
                  <div style={{ backgroundColor: item.color }} className={'rep-labels-list'}></div>
                  <div className={'rep-labels_text ml16'}>{item.title}</div>
                </div>
              );
            })
          : null}
        {iconsChart.map(item => {
          return (
            <div className={'rep-label-list-item'} key={item.icon_path}>
              <div className={'rep-labels-svg'}>
                <SVG src={item.icon_path} />
              </div>
              <div className={'rep-labels_text'}>{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ReportingComposedLabels);
