import React from 'react';
import { NfUseResponse } from '../NfUseResponse/NfUseResponse';
import { ICommentToolsProps } from './CommentTools.props';
import cn from 'classnames';
import { SystemButton } from '../../../../controls/ButtonComponents';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

export const CommentTools: React.FC<ICommentToolsProps> = ({
  comment,
  selectedRequest,
  userId,
  isAdmin,
  isSubComment,
  handleEditComment,
  handleReplyComment,
  handleDeleteComment,
  isOpenCommentForm,
  isEditComment,
  isReplyComment,
  //UseResponseProps

  handleUseResponseComment,
  isAccessNfStatus,
  isAccessUserRole,
  isUnassignSelectOpen,
}): JSX.Element => {
  const { isMobile } = GetResolution();

  return (
    <>
      <div
        className={cn('b-comment__edit', {
          ['is_hideTools']: comment.deleted_at,
          ['is_showTools']: isUnassignSelectOpen,
        })}
      >
        <div
          className={cn('b-comment__useResponse', {
            ['is_response']: comment.is_response,
            ['is_dontPermission']: !comment.is_response && (!isAccessNfStatus || !isAccessUserRole),
            ['isHaveCourts']: comment.courts.length === 0,
          })}
        >
          <NfUseResponse handleUseResponseComment={handleUseResponseComment} comment={comment} isAccessNfStatus={isAccessNfStatus} />
        </div>
        <SystemButton
          type={'reply'}
          size={'md'}
          variant={'transparent'}
          clickHandler={
            selectedRequest?.is_deactivated == 1 || selectedRequest?.parent?.id
              ? () => void 0
              : () => handleReplyComment(comment, isSubComment)
          }
          isMobile={isMobile}
        />
        {comment.type === 'comment' &&
          (comment.author.id === userId || isAdmin) &&
          !selectedRequest?.is_deactivated &&
          !selectedRequest?.parent?.id && (
            <SystemButton
              type={'edit-text'}
              size={'md'}
              variant={'transparent'}
              clickHandler={
                selectedRequest?.is_deactivated == 1 || selectedRequest?.parent?.id
                  ? () => void 0
                  : () => handleEditComment(comment, isSubComment)
              }
              isMobile={isMobile}
            />
          )}
        {comment.type === 'comment' &&
          (comment.author.id === userId || isAdmin) &&
          !selectedRequest?.is_deactivated &&
          !selectedRequest?.parent?.id && (
            <SystemButton
              type={'delete'}
              size={'md'}
              variant={'transparent'}
              clickHandler={
                selectedRequest?.is_deactivated == 1 || selectedRequest?.parent?.id
                  ? () => void 0
                  : () => handleDeleteComment(comment, isSubComment)
              }
              isMobile={isMobile}
            />
          )}
      </div>
    </>
  );
};
