import { useRef, useCallback, useEffect } from 'react';
import { PostEvent, PostMessage, PostTypes } from './types';

export const usePostMessage = () => {
  const subscribes = useRef<{ handler: (params: CustomEvent) => void; type: PostTypes }[]>([]);

  const sendMessage = useCallback((message: PostMessage) => {
    const messageToString = JSON.stringify(message);

    window.parent.postMessage(messageToString, '*');
  }, []);

  const onMessage = useCallback((message: MessageEvent) => {
    if (typeof message.data !== 'string' || !message.data) return;

    try {
      const { type, data }: PostMessage = JSON.parse(message.data);

      if (!type || !PostTypes[type]) throw Error('Type post message is undefined!');

      const customEvent = new CustomEvent(type, {
        detail: data,
      });

      document.dispatchEvent(customEvent);
    } catch (err) {
      console.warn(err);
      return false;
    }
  }, []);

  const subscribe = useCallback(({ type, callback }: PostEvent) => {
    const handler = ({ detail }: CustomEvent) => callback(detail);
    const id = subscribes.current.push({ handler, type }) - 1;
    document.addEventListener(type, subscribes.current[id].handler as EventListener);

    return id;
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    return () => {
      subscribes.current.forEach(({ type, handler }) => {
        document.removeEventListener(type, handler as EventListener);
      });
    };
  }, []);

  return {
    subscribe,
    sendMessage,
  };
};
