import { TabItemModel } from '../../../../models/global';
import { ActivationTabType } from '../../../../models';

export const requestTabs: TabItemModel<ActivationTabType>[] = [
  {
    title: 'Request',
    id: 'request',
    count: 0,
  },
  {
    title: 'Response',
    id: 'response',
    count: 0,
  },
  {
    title: 'Incorporation',
    id: 'incorporation',
    count: 0,
  },
  {
    title: 'Related items',
    id: 'related-items',
    count: 0,
  },
  {
    title: 'Discussion / History',
    id: 'discussion',
    count: 0,
  },
];
