const userPermissions = [
  {
    title: 'I. NF Card',
    id: 0,
    isClosed: false,
    headers: [
      {
        title: '1. Request - Create/Edit',
        id: 0,
        isClosed: false,
        headers: [
          {
            title: '1.1 Request party',
            id: 0,
            isClosed: false,
            permissions: [
              {
                title: '1.1.1 Assign your own company as a Request Company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '1.1.2 Assign any company as a Request Company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '1.1.3 Assign any of your own company members as a Request Manager',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '1.1.4 Assign anyone as a Request Manager',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
          {
            title: '1.2 Response party',
            id: 1,
            isClosed: true,
            permissions: [
              {
                title: '1.2.1 Assign any company as a Response Company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '1.2.2 Assign anyone as a Response Manager',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
        ],
      },
      {
        title: '2. Commitment - Submit',
        id: 1,
        isClosed: true,
        permissions: [
          {
            title: '2.1 Commit to a Request addressed to your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '2.2 Commit to any Request',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '3. Due date/Response company change request',
        id: 2,
        isClosed: true,
        headers: [
          {
            title: '3.1 Due date change request',
            id: 0,
            isClosed: true,
            permissions: [
              {
                title: '3.1.1 Request a Due date change within a Request addressed to your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.1.2 Request a Due date change within any Request',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.1.3 Accept/decline a Due date change request within a Request managed by your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.1.4 Accept/decline a Due date change request within any Request',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
          {
            title: '3.2 Response company change request',
            id: 1,
            isClosed: true,
            permissions: [
              {
                title: '3.2.1 Request a Response company change within a Request addressed to your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.2.2 Request a Response company change within any Request',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.2.3 Accept/decline a Response company change request within a Request managed by your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '3.2.4 Accept/decline a Response company change request within any Request',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
        ],
      },
      {
        title: '4. Pull request',
        id: 3,
        isClosed: true,
        permissions: [
          {
            title: '4.1 Create a Pull request from any Request',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '5. Response - Submit',
        id: 4,
        isClosed: true,
        permissions: [
          {
            title: '5.1 Submit a Response to a Request addressed to your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '5.2 Submit a Response to any Request',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '6. Response - Accept/Decline',
        id: 5,
        isClosed: true,
        permissions: [
          {
            title: '6.1 Accept/decline a Response submitted to a Request managed by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '6.2 Accept/decline a Response submitted to any Request',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '7. Commenting',
        id: 6,
        isClosed: true,
        permissions: [
          {
            title: '7.1 See any comment made within any Request',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '7.2 Make a comment/reply to any comment within any Request',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '8. NF linking',
        id: 7,
        isClosed: true,
        permissions: [
          {
            title: '8.1 Link any NF to any NF as a Predecessor',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '8.2 Link any NF to any NF as a Successor',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '9. Incorporation',
        id: 8,
        isClosed: true,
        permissions: [
          {
            title: `9.1 Add an Incorporation item to any request ('Response accepted' status required)`,
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '10. Watchlist',
        id: 9,
        isClosed: true,
        permissions: [
          {
            title: '10.1 Add any NF to your own Watchlist',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },

          {
            title: '10.2 Create a Watchers group and add anyone to it',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },

          {
            title: '10.3 Edit any Watchers group',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },

          {
            title: '10.4 See any Watchers group',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '11. Request - Deactivate',
        id: 10,
        isClosed: true,
        permissions: [
          {
            title: '11.1 Deactivate a Request managed by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '11.2 Deactivate any Request',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '12. Request - Delete',
        id: 11,
        isClosed: true,
        permissions: [
          {
            title: '12.1 Delete any Request',
            companyManager: false,
            workflowManager: false,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '13. Draft NF',
        id: 12,
        isClosed: true,
        permissions: [
          {
            title: '13.1 See a Draft NF managed by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '13.2 See any Draft NF',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '13.3 Delete a Draft NF managed by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '13.4 Delete a Draft NF managed by any company',
            companyManager: false,
            workflowManager: false,
            projectAdmin: true,
          },
        ],
      },
      {
        title: `14. Request editing when NF status is 'Response accepted'`,
        id: 13,
        isClosed: true,
        permissions: [
          {
            title: '14.1 Modify a Request (particular fields) managed by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '14.2 Modify the subject of a Request managed by your own company',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '14.3 Modify any Request (particular fields)',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '14.4 Modify the subject of any Request',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '15. Needs List - Bulk edit',
        id: 14,
        isClosed: true,
        permissions: [
          {
            title: '15.1 Modify any Requests (particular fields) in bulk',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
    ],
  },
  {
    title: 'II. Team Shaping',
    id: 1,
    isClosed: true,
    headers: [
      {
        title: '1. Company',
        id: 0,
        isClosed: true,
        permissions: [
          {
            title: '1.1 Create a new Company',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '1.2 Edit your own Company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '1.3 Edit any Company',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '2. User Roles',
        id: 1,
        isClosed: true,
        headers: [
          {
            title: '2.1 Admin',
            id: 0,
            isClosed: true,
            permissions: [
              {
                title: '2.1.1 Create a user with the Admin role within any company',
                companyManager: false,
                workflowManager: false,
                projectAdmin: true,
              },
              {
                title: '2.1.2 Edit any user with the Admin role within any company',
                companyManager: false,
                workflowManager: false,
                projectAdmin: true,
              },
              {
                title: '2.1.3 Add any user with the Admin role to a project within any company',
                companyManager: false,
                workflowManager: false,
                projectAdmin: true,
              },
              {
                title: '2.1.4 Remove any user with the Admin role from a project within any company',
                companyManager: false,
                workflowManager: false,
                projectAdmin: true,
              },
            ],
          },
          {
            title: '2.2 Manager',
            id: 1,
            isClosed: true,
            permissions: [
              {
                title: '2.2.1 Create a user with the Manager role within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.2.2 Edit any user with the Manager role within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.2.3 Add any user with the Manager role to a project within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.2.4 Remove any user with the Manager role from a project within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
          {
            title: '2.3 User',
            id: 2,
            isClosed: true,
            permissions: [
              {
                title: '2.3.1 Create a user with the User role within your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.2 Create a user with the User role within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.3 Edit any user with the User role within your own company',
                companyManager: true,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.4 Edit any user with the User role within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.5 Add any user with the User role to a project within your own company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.6 Add any user with the User role to a project within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.7 Remove any user with the User role from a project within your own company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
              {
                title: '2.3.8 Remove any user with the User role from a project within any company',
                companyManager: false,
                workflowManager: true,
                projectAdmin: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'III. Project',
    id: 2,
    isClosed: true,
    headers: [
      {
        title: '1. Project',
        id: 0,
        isClosed: true,
        permissions: [
          {
            title: '1.1 Create a new Project',
            companyManager: false,
            workflowManager: false,
            projectAdmin: true,
          },
          {
            title: '1.2 Modify Project settings',
            companyManager: false,
            workflowManager: false,
            projectAdmin: true,
          },
        ],
      },
    ],
  },
  {
    title: 'IV. Scheduler Modules',
    id: 3,
    isClosed: true,
    headers: [
      {
        title: '1. Filtering within Needs List & Sandbox',
        id: 0,
        isClosed: true,
        permissions: [
          {
            title: '1.1 Create a Shared filter and share it with any company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '1.2 Edit a Shared filter created by your own company',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '1.3 Edit any Shared filter',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '2. Phase Plan',
        id: 1,
        isClosed: true,
        permissions: [
          {
            title: '2.1 Preview the Phase Plan',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '2.2 Modify the Phase Plan',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '3. Needs List',
        id: 2,
        isClosed: true,
        permissions: [
          {
            title: '3.1 Preview all NFs created within a project',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '4. Sandbox',
        id: 3,
        isClosed: true,
        permissions: [
          {
            title: '4.1 Preview all NFs created within a project',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '5. Whiteboard',
        id: 4,
        isClosed: true,
        permissions: [
          {
            title: '5.1 Preview all NFs created within a project',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '6. Reporting',
        id: 5,
        isClosed: true,
        permissions: [
          {
            title: '6.1 Preview the dashboard',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '7. Projects',
        id: 6,
        isClosed: true,
        permissions: [
          {
            title: "7.1 Preview Projects' general information",
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '8. Team',
        id: 7,
        isClosed: true,
        permissions: [
          {
            title: '8.1 Preview Team members linked to the Project',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title:
              '8.2 Modify a user profile within your own company (in accordance to rules presented in the "II - Team Shaping" chapter)',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
          {
            title: '8.3 Modify any user profile (in accordance to rules presented in the "II - Team Shaping" chapter)',
            companyManager: false,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
      {
        title: '9. User ProfileEdit',
        id: 8,
        isClosed: true,
        permissions: [
          {
            title: '9.1 Modify your own user profile',
            companyManager: true,
            workflowManager: true,
            projectAdmin: true,
          },
        ],
      },
    ],
  },
];

export default userPermissions;
