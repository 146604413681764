import { BaseApi } from './baseApi';
class SettingsApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getUFCCodes() {
    const result = await this._get({ route: 'get-ufcodes', needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: this._objectIndexRebace(result.data),
      message: result.message,
    };
  }

  async postParty(data) {
    const result = await this._post({ route: 'team/create-party', bodyParams: data, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async postPartyUpdate(data) {
    const result = await this._post({
      route: `team/update-party/${data.id}`,
      bodyParams: data.data,
      needAuth: true,
      devMode: this.devMode,
    });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async getMembers({ projectId }) {
    const result = await this._post({ route: `team/${projectId}/get-members`, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async postMember(data) {
    const result = await this._post({ route: 'user/create', bodyParams: data, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async updateMember(data) {
    return await this._post({ route: 'user/update', bodyParams: data, needAuth: true, devMode: this.devMode });
  }

  async deleteMember(data) {
    return await this._post({ route: 'user/delete', bodyParams: data, needAuth: true, devMode: this.devMode });
  }

  //postDisciplineUpdate
  async postDisciplineUpdate(data) {
    return await this._post({
      route: `team/update-discipline/${data.id}`,
      bodyParams: data,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async useFileAsResponse(fileId, is_annotation) {
    if (!fileId) {
      return false;
    }
    const result = await this._post({
      route: `file/${fileId}/file-request-as-response`,
      bodyParams: { is_annotation },
      needAuth: true,
      devMode: this.devMode,
    });

    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async filesUpload(file) {
    if (!file) {
      return false;
    }
    const result = await this._post({ route: 'file/upload', bodyParams: { file }, needAuth: true, formData: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data.file,
    };
  }

  async updateLinkUser(bodyParams) {
    const rezult = await this._post({ route: `team/link-user`, bodyParams, needAuth: true, devMode: this.devMode });
    return rezult;
  }
  async updateUnlinkUser(bodyParams) {
    const rezult = await this._post({ route: `team/unlink-user`, bodyParams, needAuth: true, devMode: this.devMode });
    return rezult;
  }

  async getShortLink({ filter_hash }) {
    return await this._get({
      route: `short-links/${filter_hash}`,
      needAuth: true,
      devMode: this.devMode,
    });
  }
}

export default new SettingsApi({ devMode: false });
