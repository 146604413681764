import { filterFields } from '../../NeedsList/NeedListConstants/NeedListConstants';

export const CONDITION_TYPES = {
  DATE: 'DATE',
  RANGE: 'RANGE',
  TEXT: 'TEXT',
};

export const DATE_FILTERS_ALIAS = {
  DATE_IS: 'DATE_IS',
  RANGE: 'RANGE',
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
};

export const RANGE_FILTERS_ALIAS = {
  RANGE: 'RANGE',
  LIST: 'LIST',
};

export const TEXT_FILTERS_ALIAS = {
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  EXACTLY: 'EXACTLY',
};

export const DATE_FILTERS = [
  {
    title: 'Is On',
    id: DATE_FILTERS_ALIAS.DATE_IS,
  },
  {
    title: 'Is Between',
    id: DATE_FILTERS_ALIAS.RANGE,
  },
  {
    title: 'Is On or Before',
    id: DATE_FILTERS_ALIAS.BEFORE,
  },
  {
    title: 'Is On or After',
    id: DATE_FILTERS_ALIAS.AFTER,
  },
];

export const RANGE_FILTERS = [
  {
    title: 'List',
    id: RANGE_FILTERS_ALIAS.LIST,
  },
  {
    title: 'Range',
    id: RANGE_FILTERS_ALIAS.RANGE,
  },
];

export const TEXT_FILTERS = [
  {
    title: 'Text contains',
    id: TEXT_FILTERS_ALIAS.CONTAINS,
  },
  {
    title: "Text doesn't contain",
    id: TEXT_FILTERS_ALIAS.NOT_CONTAINS,
  },
  {
    title: 'Text exactly',
    id: TEXT_FILTERS_ALIAS.EXACTLY,
  },
];

export const conditionFilters = {
  [CONDITION_TYPES.DATE]: DATE_FILTERS,
  [CONDITION_TYPES.RANGE]: RANGE_FILTERS,
  [CONDITION_TYPES.TEXT]: TEXT_FILTERS,
};

export const FILTERS_TYPE = {
  [filterFields.LOCATION]: null,
  [filterFields.BUILDING_SPACES]: null,
  [filterFields.BUILDING]: null,
  [filterFields.REQUEST_DISCIPLINE]: null,
  [filterFields.RESPONSE_COMPANY]: null,
  [filterFields.REQUEST_COMPANY]: null,
  [filterFields.PREDECESSORS]: CONDITION_TYPES.TEXT,
  [filterFields.PROJECT_SPECIFIC_DESCRIPTION]: CONDITION_TYPES.TEXT,
  [filterFields.NAME]: null,
  [filterFields.REQUEST_MANAGER]: null,
  [filterFields.RESPONSE_MANAGER]: null,
  [filterFields.EMAIL]: null,
  [filterFields.NF]: CONDITION_TYPES.TEXT,
  [filterFields.DESCRIPTION]: CONDITION_TYPES.TEXT,
  [filterFields.SUCCESSOR]: CONDITION_TYPES.TEXT,
  [filterFields.COMMITMENT_FINAL]: CONDITION_TYPES.TEXT,
  [filterFields.DUE_DATE]: CONDITION_TYPES.DATE,
  [filterFields.REQUEST_FILE]: CONDITION_TYPES.TEXT,
  [filterFields.CREATED]: CONDITION_TYPES.DATE,
  // [filterFields.UFC]: CONDITION_TYPES.TEXT,
  // [filterFields.MFC]: CONDITION_TYPES.TEXT,
  [filterFields.RESPONSE_DISCIPLINE]: null,
  [filterFields.SOURCE]: null,
  [filterFields.INCORPORATED]: null,
  [filterFields.REQUEST_STATUS]: null,
  [filterFields.COMMITMENT_STATUS]: null,
  [filterFields.NF_STATUS]: null,
  [filterFields.HASHTAGS]: CONDITION_TYPES.TEXT,
  [filterFields.MILESTONE]: null,
  [filterFields.COMMITMENT_FILE]: CONDITION_TYPES.TEXT,
  [filterFields.COMMITMENT_FINAL_FILE]: CONDITION_TYPES.TEXT,
  [filterFields.NF_ACTION_BY]: null,
  [filterFields.NF_ACTION_DATE]: CONDITION_TYPES.DATE,
  [filterFields.DELIVERABLE_AUTHOR]: null,
  [filterFields.COMMENTS]: null,
  [filterFields.COMMENT_AUTHOR]: null,
  [filterFields.COMMENT_DATE]: CONDITION_TYPES.DATE,
  [filterFields.COMMENT_MENTION]: null,
  [filterFields.MY_REQUEST]: null,
  [filterFields.DELIVERABLES]: null,
  [filterFields.DESIGN_DOCUMENT_LOG]: null,
  [filterFields.REQUEST_SENT]: CONDITION_TYPES.DATE,
  [filterFields.COMMITMENT_SUBMITTED]: CONDITION_TYPES.DATE,
  [filterFields.RESPONSE_SUBMITTED]: CONDITION_TYPES.DATE,
  [filterFields.RESPONSE_ACCEPTED]: CONDITION_TYPES.DATE,
  [filterFields.RESPONSE_DECLINED]: CONDITION_TYPES.DATE,
  //P&CD Filters
  // [filterFieldsPCD.CONDITIONS_CRITICAL_PATH_DATE]: CONDITION_TYPES.DATE,
  // [filterFieldsPCD.SCHEDULED_DATE]: CONDITION_TYPES.DATE,
  // [filterFieldsPCD.ISSUED_DATE]: CONDITION_TYPES.DATE,
  // [filterFieldsPCD.ISSUED_DATE_STATE]: CONDITION_TYPES.DATE,
};
