import React, { useEffect, useState } from 'react';
import { CustomButton } from '../ButtonComponents';
import RedmineMilestoneSelect from './RedmineMilestoneSelect/RedmineMilestoneSelect';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppSelector } from '../../../store/configure/configureStore';
import DialogPopUp from '../DialogPopUp/DialogPopUp';
import DateField from '../DateField/DateField';
import { updateMilestone } from '../../../helpers/redmineMilestone/redmineMilestoneHelpers';
import RedmineApi from '../../../service/Api/redmineApi';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getPCDCard } from '../../../store/pcd/pcdThunk';
import { getRedmineMilestones } from '../../../store/redmine/redmineThunk';
import { redmineSlice } from '../../../store/redmine/redmineSlice';
import TokenIcon from '../TokenIcon/TokenIcon';
import CustomTooltip from '../Tooltip/Tooltip';

import './RedmineMilestoneStyles.scss';

const { selectRedmineProject, selectRedmineMilestone, removeAssignedMilestone } = redmineSlice.actions;

const RedmineMilestone: React.FC = () => {
  const user_data = useAppSelector(state => state.userReducer);
  const project = useAppSelector(state => state.phasePlan.projectData);
  const pcdInfo = useAppSelector(state => state.pcdReducer.pcdInfo);
  const pcdID = useAppSelector(state => state.pcdReducer.lastPcdCardId);
  const pcdInfoLoading = useAppSelector(state => state.pcdReducer.pcdInfoLoading);
  const redmineData = useAppSelector(state => state.redmineReducer);

  const dispatch = useDispatch();

  const themeClass = useGetThemeClass('redmine-milestone');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  //compare dates
  const [datesAreSame, setDatesAreSame] = useState<boolean>(false);

  useEffect(() => {
    dispatch(selectRedmineProject(null));
  }, []);

  useEffect(() => {
    if (redmineData.selectedProject) {
      dispatch(getRedmineMilestones({ projectId: redmineData.selectedProject.id, rmApiKey: user_data.userInfo.redmine_key }));
    }
  }, [redmineData.selectedProject, user_data.userInfo.redmine_key]);

  useEffect(() => {
    if (redmineData.projects.length === 1) {
      dispatch(selectRedmineProject(redmineData.projects[0]));
    }
  }, [redmineData.projects]);

  useEffect(() => {
    const result = areSameDates(
      moment(redmineData.assignedMilestone?.due_date).format('MM/DD/YYYY'),
      moment(pcdInfo?.[pcdID]?.scheduled_date).format('MM/DD/YYYY'),
    );
    setDatesAreSame(result);
  }, [pcdInfo?.[pcdID]?.scheduled_date, redmineData.assignedMilestone?.due_date]);

  const handleSelectMilestone = e => {
    dispatch(selectRedmineMilestone(e));
    setIsModalOpen(true);
  };

  const handleSelectProject = e => {
    dispatch(selectRedmineProject(e));
  };

  const handleAssignMilestone = async () => {
    if (redmineData.hasAssignedMilestone) {
      const redmineIdRemove = {
        owner_id: pcdID,
        owner_type: 'deliverable',
        redmine_ids: [`${redmineData.assignedMilestone.id}`],
        action: 'remove',
      };
      await RedmineApi.setRedmineId(redmineIdRemove);
    }
    const redmineId = {
      owner_id: pcdID,
      owner_type: 'deliverable',
      redmine_ids: [`${redmineData.selectedMilestone.id}`],
      action: 'add',
    };
    await RedmineApi.setRedmineId(redmineId);
    dispatch(getPCDCard({ id: pcdID }));
    const redmineBody = {
      version: {
        status: 'open',
        due_date: pcdInfo?.[pcdID]?.scheduled_date ? moment(pcdInfo?.[pcdID]?.scheduled_date).format('YYYY-MM-DD') : null,
        custom_fields: [
          {
            id: 225,
            name: 'Scheduler Deliverable',
            value: `"${pcdInfo?.[pcdID]?.title} (R${pcdInfo?.[pcdID]?.revision})":${process.env.DOMAIN_SELF_URL}/${project.code}/card?building=${pcdInfo?.[pcdID]?.building_id}&deliverable=${pcdInfo?.[pcdID]?.id}&tab=deliverable`,
          },
        ],
      },
    };
    updateMilestone(redmineData.selectedMilestone.id, user_data.userInfo.redmine_key, redmineBody);
    dispatch(selectRedmineMilestone(null));
    setIsModalOpen(false);
  };

  const handleUnassignMilestone = async () => {
    const redmineIdRemove = {
      owner_id: pcdID,
      owner_type: 'deliverable',
      redmine_ids: [`${redmineData.assignedMilestone.id}`],
      action: 'remove',
    };
    await RedmineApi.setRedmineId(redmineIdRemove);
    dispatch(removeAssignedMilestone());
  };

  return (
    <div>
      {redmineData.hasAssignedMilestone && (
        <div className={`${themeClass}__redmineMilestone`}>
          <div className={`${themeClass}__milestoneContainer`}>
            <div className={`${themeClass}__date-title`}>Milestone date</div>
            <DateField
              selectedDate={
                redmineData.assignedMilestone?.due_date && redmineData.projects.length !== 0
                  ? moment(redmineData.assignedMilestone?.due_date).format('MM/DD/YYYY')
                  : 'mm/dd/yyyy'
              }
              isSentRequestToChange={false}
              isOpenCalendar={false}
              isDiffDueScheduledDate={false}
              info={!datesAreSame}
              disabled={true}
              isBefore={false}
              valueIsDate={true}
              isDisableBorder
              isHideCalendar
            />
          </div>
          <div>
            <CustomTooltip
              title={redmineData.assignedMilestone?.name}
              placement={'top'}
              customBasisClass={`${themeClass}__milestoneTooltip`}
            >
              <a
                href={`${process.env.RM_BASE_URL}/versions/${redmineData.assignedMilestone?.id}`}
                target="_blank"
                rel="noreferrer"
                referrerPolicy="no-referrer"
                className={`${themeClass}__redmine-link`}
              >
                <div className={`${themeClass}__milestone-name`}>{redmineData.assignedMilestone?.name}</div>
                <TokenIcon iconName={'arrow-diagonal'} size={20} isClickable={false} customClass={`${themeClass}__link-icon`} />
              </a>
            </CustomTooltip>
          </div>
          <CustomButton type={'secondary'} size={'md'} title={'Unassign milestone'} clickHandler={handleUnassignMilestone} />
        </div>
      )}
      {!redmineData.hasAssignedMilestone && (
        <div className={`${themeClass}__redmineMilestone`}>
          <RedmineMilestoneSelect
            title={'Redmine project'}
            selectHandler={handleSelectProject}
            selectedItem={redmineData.selectedProject}
            itemsList={redmineData.projects}
            disabled={redmineData.projects.length === 0 || redmineData.projects.length === 1}
            menuItemSize={'md'}
            basisPlaceholder={'Select Redmine project'}
            isLoading={redmineData.loadingProjects}
            customMenuItemClass={`${themeClass}__menuItem`}
            customBasisFieldClass={`${themeClass}__menuBasis`}
            isDisableMinWidth={false}
          />
          <RedmineMilestoneSelect
            title={'Milestone'}
            selectHandler={handleSelectMilestone}
            selectedItem={redmineData.selectedMilestone}
            itemsList={redmineData.milestones}
            menuItemSize={'md'}
            disabled={!redmineData.selectedProject}
            basisPlaceholder={'Select milestone'}
            isLoading={redmineData.loadingMilestones}
            customMenuItemClass={`${themeClass}__menuItem`}
            customBasisFieldClass={`${themeClass}__menuBasis`}
            isDisableMinWidth={false}
          />
        </div>
      )}
      <DialogPopUp
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          dispatch(selectRedmineMilestone(null));
        }}
        title={'Assign Redmine milestone?'}
        primaryText={'Yes, assign'}
        secondaryText={'Cancel'}
        handleOnPrimary={handleAssignMilestone}
        loadingOnPrimary={pcdInfoLoading || redmineData.loadingAssignedMilestone}
        handleOnSecondary={() => {
          setIsModalOpen(false);
          dispatch(selectRedmineMilestone(null));
        }}
        modalText={`Assign milestone "${redmineData.selectedMilestone?.title}" #${redmineData.selectedMilestone?.id} to this Deliverable?`}
      />
    </div>
  );
};

const areSameDates = (d1, d2) => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  if (date1 === date2) return true;
  return false;
};

export default RedmineMilestone;
