import React, { Component } from 'react';

class Update_20220922_1 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img className={'fullImage'} src={require('../../../../../../assets/images/whatsNews/Update_20220922_1.gif')} alt="" />
        </div>
      </>
    );
  }
}

export default Update_20220922_1;
