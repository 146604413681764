import React, { Component } from 'react';
import { DOMAIN_SELF_URL } from '../../../../../../service/links';

class Updates_20230713_1 extends Component {
  render() {
    return (
      <div className={'unreadWhatsNewText'}>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <video width="100%" height="auto" src={`${DOMAIN_SELF_URL}/videos/discussion-history-video.mp4`} controls>
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          By default, the tab displays the comments layer, ensuring that discussions take center stage. But we understand that sometimes you
          need a specific view, so you can easily switch to:
          <br />
          <ul>
            <li style={{ listStyle: 'disc' }}>all items,</li>
            <li style={{ listStyle: 'disc' }}>history records,</li>
            <li style={{ listStyle: 'disc' }}>only the most important actions.</li>
          </ul>
          Rest assured that all the existing data from the ‘Discussion’ and ‘History’ tabs will automatically migrate to the new place.
        </div>
      </div>
    );
  }
}

export default Updates_20230713_1;
