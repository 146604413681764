import React, { ReactElement, ReactNode } from 'react';
import { ActivationTabType, RequestModel } from '../../../models';
import { PCDApi } from '../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { commentSlice } from '../../../store/comments/commentsReducer';
import { Select } from '../../controls/Dropdowns/DropdownСomponents';
import OvalButton from '../../controls/ButtonComponents/OvalButton/OvalButton';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import cn from 'classnames';
import { ISubmittal } from '../../../models/submittals/submittals.model';

import './BICorMentionSelectStyles.module.scss';

export interface DropdownItemModel {
  value: string | number;
  id: string | number;
  title: string;
  tooltip?: string | ReactNode | ReactElement;
  leftElem?: ReactNode | ReactElement;
  rightElem?: ReactNode | ReactElement;
  group?: string | number;
  disabled?: boolean;
  user_id?: number;
  user_name?: string;
  party_title?: string;
  comment_id?: number;
  user?: string;
  party?: string;
}

interface IProps {
  selectedRequest: RequestModel | PCDCard | ISubmittal;
  onChangeActiveTab?: (activeTab: ActivationTabType) => void;
  type: 'request' | 'deliverable' | 'submittal';
  forOpen: 'bic' | 'mention';
  showBasisTitle?: boolean;
}

export const BICorMentionSelect: React.FC<IProps> = ({ selectedRequest, onChangeActiveTab, type, forOpen, showBasisTitle = true }) => {
  const dispatch = useAppDispatch();

  const { isMobile } = GetResolution();

  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const { setBicIndicatorScrollToCommentId } = commentSlice.actions;

  const themeClass = useGetThemeClass('b-BICorMentionSelect');
  const isBicAssignedToMe = userInfo.id === selectedRequest.bic?.[0]?.user_id;
  const isMentionMe = selectedRequest?.secondary_courts?.find(court => court.user_id === userInfo.id);

  const currentUserFromSecondary = selectedRequest?.secondary_courts?.find(court => court.user_id === userInfo.id);

  const handleChangeTab = (tabName: ActivationTabType, commentId?: number) => {
    onChangeActiveTab && onChangeActiveTab(tabName);
    commentId && dispatch(setBicIndicatorScrollToCommentId(commentId));
  };

  const renderBicTitle = () => {
    if (forOpen === 'bic') {
      if (userInfo.id === selectedRequest.bic?.[0]?.user_id) {
        return `${selectedRequest.bic?.[0].user_name} (you)`;
      }
      if (!isBicAssignedToMe) {
        return selectedRequest?.bic?.length ? selectedRequest.bic[0].user_name : null;
      }
    }
    if (forOpen === 'mention') {
      return selectedRequest?.secondary_courts?.length || 0;
    }
  };

  const renderSingleSelect = (item: DropdownItemModel) => {
    const isPrimaryBicAssignedToMe = userInfo.id === item.user_id;
    return (
      <>
        {forOpen === 'bic' ? (
          <div key={item.user_id} className={`${themeClass}_primaryBicType`}>
            <div className={`bicIconInSelect ${isPrimaryBicAssignedToMe ? 'orangeIcon' : ''}`}>
              <TokenIcon iconName={'bic'} size={16} />
            </div>
            <div
              className={cn(`${themeClass}_infoBlock`, {
                ['-mobile']: isMobile,
              })}
            >
              <span className={`managerLabel ${isPrimaryBicAssignedToMe ? 'isBicAssignedToMe' : ''}`}>
                {isPrimaryBicAssignedToMe ? `${item.user_name} (you)` : `${item.user_name}`}
              </span>
              <span className={`managerParty`}>{item.party_title ? `${item.party_title}` : ''}</span>
            </div>
          </div>
        ) : (
          <div key={`court-${item.id}`} className={`${themeClass}_secondaryBicUser`}>
            <div className={`mentionIconInSelect ${item.user_id === userInfo.id ? 'orangeIcon' : ''}`}>
              <TokenIcon iconName={'at'} size={16} />
            </div>
            <div
              className={cn(`${themeClass}_infoBlock`, {
                ['-mobile']: isMobile,
              })}
            >
              <span className={`managerLabel ${item.user_id === userInfo.id ? 'isBicAssignedToMe' : ''}`}>
                {item.user_id === userInfo.id ? `${item.user} (you)` : `${item.user}`}
              </span>
              <span className={`managerParty`}>{item.party ? `${item.party}` : ''}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleChangeSelect = court => {
    if (forOpen === 'mention') {
      handleChangeTab('discussion', court.comment_id);
    }
  };

  return (
    <>
      <Select<DropdownItemModel, null, false>
        isMulti={false}
        menuItems={selectedRequest && forOpen === 'bic' ? selectedRequest?.bic : selectedRequest?.secondary_courts}
        selectHandler={values => handleChangeSelect(values)}
        selected={null}
        menuItemSize={'md'}
        customMenuItemClass={cn(`${themeClass}_selectMenuItemClass`, { ['-mobile']: isMobile })}
        menuItemTitleRenderer={renderSingleSelect}
        isMobile={isMobile}
        renderCustomBasis={isActive => {
          return (
            <OvalButton
              title={renderBicTitle()}
              type={forOpen === 'bic' ? (isBicAssignedToMe ? 'bic-me' : 'bic-user') : isMentionMe ? 'mention-me' : 'mention-user'}
              showTitle={showBasisTitle}
            />
          );
        }}
      />
    </>
  );
};
