import React, { Component } from 'react';

class Update_20220217_5 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_05cb2a5dc008aa405672c3c157649462/images/image6_TNq.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20220217_5;
