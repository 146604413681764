import React from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { SandBoxTabModel } from '../../../../models/sandBox/sandBoxArhitecture';
import Box from '@mui/material/Box';
import { TabComponent } from '../TabComponent';
import cn from 'classnames';
import { TabScrollButtonProps } from '@mui/material';
import SystemButton from '../../ButtonComponents/SystemButton/SystemButton';
import { TabItemModel } from '../../../../models/global';
import { AllSectionType } from '../../../common/SandBox/SandBoxConstants/SandBoxConstants';

import './TabsBar.module.scss';

interface IProps {
  sections: SandBoxTabModel[] | TabItemModel[];
  selectedTab: string | number;
  handleChange: (event: any, tabName: string) => void;
  relatedTo?: string;
  activeSectionType?: AllSectionType;
  isCountersLoading?: boolean;
  type?: string;
  customClass?: string;
  isNfCard?: boolean;
  isMobile?: boolean;
  openedFrom?: string;
}

const TabsBar = ({
  selectedTab,
  sections,
  handleChange,
  relatedTo,
  activeSectionType,
  isCountersLoading,
  type,
  customClass,
  isNfCard,
  isMobile,
  openedFrom,
}: IProps) => {
  const getSectionTitle = (section: SandBoxTabModel) => {
    if (openedFrom === 'report' && section?.title_report) {
      if (relatedTo === 'user') {
        return section?.title_report.user;
      } else {
        return section?.title_report.company;
      }
    }

    if (section.title) {
      return section.title;
    }

    if (relatedTo === 'user') {
      return section.title_to_me;
    } else {
      return section.title_to_company;
    }
  };

  const themeClass = useGetThemeClass('tabBar');

  return (
    <div className={`${themeClass}_container`}>
      <Box position={'relative'} className={`${themeClass}_tabsBar`}>
        <Tabs
          classes={{
            root: `${themeClass}_root`,
            indicator: `${themeClass}_indicator`,
            scroller: `${themeClass}_scroller`,
          }}
          value={selectedTab}
          onChange={!isCountersLoading && handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={(props: TabScrollButtonProps) => {
            const { onClick, direction, disabled } = props;

            return (
              <div
                onClick={onClick}
                className={cn(`${themeClass}_arrowContainer`, { [`-${direction}`]: direction, [`-disabled`]: !!disabled })}
              >
                <SystemButton type={direction === 'left' ? 'chevron-left' : 'chevron-right'} variant={'transparent'} size={'md'} />
              </div>
            );
          }}
        >
          {sections.map(section => {
            const sectionTitle = getSectionTitle(section);

            const renderToolTipText = () => {
              if (localStorage.getItem('show_tooltips') === 'no') {
                return '';
              } else if (section.id === 'request') {
                return 'Request description and files';
              } else if (section.id === 'response') {
                if (section.disabled) {
                  return 'Unavailable until NF is sent';
                } else {
                  return 'Submitted response and files';
                }
              } else if (section.id === 'incorporation') {
                return 'Information about the state of incorporation';
              } else if (section.id === 'discussion') {
                return 'Comments and history of changes';
              } else if (section.id === 'history') {
                if (section.disabled) {
                  return 'Unavailable until NF is saved or sent';
                } else {
                  return 'History of changes';
                }
              } else {
                return '';
              }
            };

            return (
              <TabComponent
                key={section.id}
                label={sectionTitle}
                value={section.id}
                counter={isCountersLoading ? null : section?.count || 0}
                activeSectionType={activeSectionType}
                isCountersLoading={isCountersLoading}
                disabled={section.disabled}
                type={type}
                customClass={customClass}
                toolTipText={isNfCard ? renderToolTipText() : null}
                isNfCard={isNfCard}
                isMobile={isMobile}
              />
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
};

// @ts-ignore
export default TabsBar;
