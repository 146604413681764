import React, { FC, useState, useEffect, useMemo } from 'react';
import Logout from './parts/Logout/Logout';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../navigation/routes';
import { Logo } from '../../controls';
import SVG from 'react-inlinesvg';
import HelpDrawerContainer from '../HelpDrawer/HelpDrawerContainer';
import Popover from '@material-ui/core/Popover';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import SelectProjectView from '../SelectProject/SelectProjectView';
import { getProjectDetails } from '../../../store/project/projectLogic';
import { filterSlice } from '../../../store/filters/filtersReducer';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { supportSlice } from '../../../store/support/supportSlice';
import { teamSlice } from '../../../store/team/teamSlice';
import { settingsSlice } from '../../../store/globalSettings/settingsSlice';
import { asideSlice } from '../../../store/aside/asideSlice';
import { getPromptsAlerts, updateUser } from '../../../store/user/userThunk';
import { userSlice } from '../../../store/user/userSlice';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { commonSlice } from '../../../store/common/commonReducer';
import OverflowMenu from './parts/OverflowMenu/OverflowMenu';
import ErrorsPopUp from '../../pages/ErrorsPopUp/ErrorPopUp';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import CustomSkeleton from '../../controls/CustomSkeleton/CustomSkeleton';
import { reportingSlice } from '../../../store/reporting/reportingSlice';
import { documentsSlice } from '../../../store/documents/documentsReducer';
import cn from 'classnames';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import UserPopup from '../../controls/UserPopup/UserPopup';
import { DropdownMenuItem } from '../../controls/Dropdowns/DropdownParts';
import { getCurrentProjectSpecialReport } from '../../../store/common/commonThunk';
import { projectSlice } from '../../../store/project/projectReducer';
import { SpecAndSubsSlice } from '../../../store/specAndSubs/specAndSubsSlice';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { LoginUserModel } from '../../../models';

import './AppBarStyles.scss';
import useRouter from '../../../hooks/useRouter/useRouter';

const { filesUploadClean } = settingsSlice.actions;
const { setAsideMenuMobileState } = asideSlice.actions;
const { handleHelpMenu } = supportSlice.actions;
const { clearTeamState } = teamSlice.actions;
const { setActiveProject } = userSlice.actions;
const { clearNeedListData, resetSandboxLoadings, clearNeedListFilters } = requestSlice.actions;
const { hardResetFiltersReducer } = filterSlice.actions;
const { handleResizableCardPart, clearRequestsFilters } = commonSlice.actions;
const { resetReportingFirstLoading } = reportingSlice.actions;
const { resetDocumentsFirstMainLoading } = documentsSlice.actions;
const { clearSubmittalsData, clearSpecificationData } = SpecAndSubsSlice.actions;
const { setGlobalPrompPopUp } = settingsSlice.actions;
const { changeActiveProject } = requestSlice.actions;
const { setTheme } = settingsSlice.actions;
const { clearPCDTableData } = projectSlice.actions;

const AppBar: FC = () => {
  const dispatch = useAppDispatch();
  const { urlProject, navigate, location, searchParams } = useRouter();

  const user_data = useAppSelector(state => state.userReducer);
  const avatar = useAppSelector(state => state.userReducer.avatar);
  const unReadNotificationCount = useAppSelector(state => state.notificationsReducer.unReadNotificationCount);
  const changedActiveProjectId = useAppSelector(state => state.requestReducer.changedActiveProjectId);
  const clientProjects = useAppSelector(state => state.userReducer.clientProjects);
  const clients = useAppSelector(state => state.userReducer.clients);
  const promptData = useAppSelector(state => state.settingsReducer.globalPromptPopUp);
  const isAsideMenuOpen = useAppSelector(state => state.asideReducer.isAsideMenuOpen);
  const promptsAlerts = useAppSelector(state => state.userReducer.promptsAlerts);
  const helpMenuState = useAppSelector(state => state.supportReducer.helpMenuState);

  const [login_user, set_login_user] = useState<LoginUserModel>(null);
  const [isOpenProjectsSelect, setIsOpenProjectsSelect] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activityMenuAnchorEl, setActivityMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [active_project_id, set_active_project_id] = useState<number>();
  const [prev_active_project_id, set_prev_active_project_id] = useState<number>();
  const [selected_client_id, set_selected_client_id] = useState<number | string>();
  const [selectedTheme, setSelectedTheme] = useState<string | boolean>('');
  const [activePage, setActivePage] = useState<string>('');

  const themeClass = useGetThemeClass('b-AppBar');

  const projectName = sessionStorage.getItem('project_name');
  const isSeparateTab = urlProject && urlProject[1] === 'card';
  const userInfo = user_data && user_data.userInfo;
  const iconAvatar = avatar || (user_data && user_data.userInfo.image);
  const userRoles = userInfo.roles && Object.keys(userInfo.roles).map(key => userInfo.roles[key]);

  useEffect(() => {
    if (urlProject[1] !== 'project-settings') {
      setActivePage(urlProject[1]);
    }
  }, [urlProject]);

  useEffect(() => {
    dispatch(getPromptsAlerts());

    initActivePage();

    updateResolution();
    window.addEventListener('resize', updateResolution);

    if (localStorage.getItem('pcdDriverGroup') !== 'no') {
      localStorage.setItem('pcdScheduleDriverChanging', 'no');
      localStorage.setItem('pcdScheduleDriverResetting', 'no');
      localStorage.setItem('pcdTimeAdvanceChanging', 'no');
      localStorage.setItem('pcdTimeAdvanceResetting', 'no');
      localStorage.setItem('pcdAllLevels', 'no');
      localStorage.setItem('pcdResponsibleReview', 'no');
      localStorage.setItem('pcdDeactivating', 'no');
      localStorage.setItem('pcdBuildingZone', 'no');
      localStorage.setItem('pcdSelectedZone', 'no');
      localStorage.setItem('pcdDeliverableList', 'no');
      localStorage.setItem('pcdCardCreate', 'no');
      localStorage.setItem('pcdCardEdit', 'no');
      localStorage.setItem('pcdDriverGroup', 'no');
    }

    if (!localStorage.getItem('resizableCard')) {
      localStorage.setItem('resizableCard', 'full');
      localStorage.setItem('resizablePCCard', 'full');
      localStorage.setItem('resizableSubmittalCard', 'full');
      dispatch(handleResizableCardPart('full'));
    }

    if (!localStorage.getItem('resizablePCCard')) {
      localStorage.setItem('resizablePCCard', 'full');
    }

    if (!localStorage.getItem('resizableSubmittalCard')) {
      localStorage.setItem('resizableSubmittalCard', 'full');
    }
  }, []);

  useEffect(() => {
    if (active_project_id && active_project_id !== prev_active_project_id) {
      initHeader();
    }
  }, [active_project_id]);

  useEffect(() => {
    set_active_project_id(user_data.active_project_id);
    set_selected_client_id(user_data.selected_client_id);
    setSelectedTheme(user_data?.userInfo?.theme ? user_data.userInfo.theme !== 'light' : true);
  }, [user_data]);

  useEffect(() => {
    if (user_data.selected_client_id && user_data.userInfo?.id && !selected_client_id) {
      const clientId = user_data.selected_client_id && parseInt(user_data.selected_client_id);

      set_selected_client_id(clientId);
    }
  }, [user_data.selected_client_id]);

  useEffect(() => {
    if (changedActiveProjectId) {
      if (urlProject[1] !== 'project-settings') {
        const project = user_data?.userInfo?.projects?.find(project => project.id == changedActiveProjectId);
        if (project) {
          sessionStorage.setItem('project_name', project.code);
          const stateData = {
            ...login_user,
            id: changedActiveProjectId,
          };
          set_login_user(stateData);
          return;
        }
        if (changedActiveProjectId === 0) {
          sessionStorage.setItem('project_name', 'no-projects-yet');
          const stateData = {
            ...login_user,
            id: changedActiveProjectId,
          };
          set_login_user(stateData);
        }
      }
    }
  }, [changedActiveProjectId]);

  useEffect(() => {
    if (
      user_data.userInfo &&
      clientProjects &&
      Object.keys(user_data.userInfo).length &&
      Object.keys(clientProjects).length &&
      !Object.keys(login_user || {}).length
    ) {
      const project = clientProjects.find(project => project.code == urlProject[0]);
      let active_project_id = 0;
      const stateData = {
        id: parseInt(user_data.user_id),
        role: user_data.userInfo.roles,
      };
      if (project) {
        active_project_id = project.id;
      } else {
        if (clientProjects?.length === 1 && clientProjects[0]?.id) {
          active_project_id = clientProjects[0].id;
        }
      }
      if (urlProject[1] !== 'project-settings') {
        dispatch(setActiveProject(active_project_id));
      }
      set_active_project_id(active_project_id);
      set_login_user(stateData);
    }
  }, [clientProjects]);

  useEffect(() => {
    if (isAsideMenuOpen) {
      document.body.classList.add('isMenuOpen');
    } else {
      document.body.classList.remove('isMenuOpen');
    }
  }, [isAsideMenuOpen]);

  useEffect(() => {
    if (promptsAlerts) {
      const promptNameList = JSON.parse(promptsAlerts) || {};
      localStorage.setItem('promptNameList', promptsAlerts || '');
      localStorage.removeItem('requestHeadClose');
      localStorage.removeItem('requestClickOutSide');
      localStorage.removeItem('pcdHeadClose');
      localStorage.removeItem('submittalHeadClose');
      localStorage.removeItem('pcdClickOutSide');
      localStorage.removeItem('submittalClickOutSide');
      localStorage.removeItem('RequestUpdate');
      localStorage.removeItem('commitmentSubmit');
      localStorage.removeItem('commitmentUpdate');
      localStorage.removeItem('deliverableComplete');
      localStorage.removeItem('RequestSend');
      localStorage.removeItem('finalDelivable');
      localStorage.removeItem('notFinalDelivable');
      localStorage.removeItem('dueDateChangeBtn');
      localStorage.removeItem('changeCompanyBtn');
      localStorage.removeItem('pcdScheduleDriverChanging');
      localStorage.removeItem('pcdScheduleDriverResetting');
      localStorage.removeItem('pcdTimeAdvanceChanging');
      localStorage.removeItem('pcdTimeAdvanceResetting');
      localStorage.removeItem('pcdAllLevels');
      localStorage.removeItem('pcdResponsibleReview');
      localStorage.removeItem('pcdDeactivating');
      localStorage.removeItem('pcdCardCreate');
      localStorage.removeItem('pcdCardEdit');
      localStorage.removeItem('pcdBuildingZone');
      localStorage.removeItem('pcdSelectedZone');
      localStorage.removeItem('pcdDeliverableList');
      localStorage.removeItem('pcdDriverGroup');
      localStorage.removeItem('inactiveUserPopup');

      for (const promptName in promptNameList) {
        if (!promptNameList['pcdDriverGroup']) {
          promptNameList['pcdScheduleDriverChanging'] = 'no';
          promptNameList['pcdScheduleDriverResetting'] = 'no';
          promptNameList['pcdTimeAdvanceChanging'] = 'no';
          promptNameList['pcdTimeAdvanceResetting'] = 'no';
          promptNameList['pcdAllLevels'] = 'no';
          promptNameList['pcdResponsibleReview'] = 'no';
          promptNameList['pcdDeactivating'] = 'no';
          promptNameList['pcdBuildingZone'] = 'no';
          promptNameList['pcdSelectedZone'] = 'no';
          promptNameList['pcdDeliverableList'] = 'no';
          promptNameList['pcdCardCreate'] = 'no';
          promptNameList['pcdCardEdit'] = 'no';
          promptNameList['pcdDriverGroup'] = 'no';
          promptNameList['pcdDriverGroup'] = 'no';
        }
        localStorage.setItem(promptName, promptNameList[promptName]);
      }
    }
    if (user_data.userInfo.show_tooltips === 0) {
      localStorage.setItem('show_tooltips', 'no');
    } else {
      localStorage.removeItem('show_tooltips');
    }
  }, [promptsAlerts]);

  useEffect(() => {
    if (user_data.userInfo.show_tooltips === 0) {
      localStorage.setItem('show_tooltips', 'no');
    } else {
      localStorage.removeItem('show_tooltips');
    }
  }, [user_data.userInfo.show_tooltips]);

  const currentClient = useMemo(() => {
    return clients?.find(client => client.id === +user_data.selected_client_id);
  }, [user_data]);

  const currentClientLogo = useMemo(() => {
    return currentClient?.renderings[currentClient.renderings.length - 1]?.file;
  }, [currentClient]);

  const isAdmin = useMemo(() => {
    return login_user?.role && !!Object.keys(login_user.role).filter(key => key === '1' || key === '4').length;
  }, [login_user]);

  const openAsideMenu = (e, url) => {
    if (promptData.isChanged) {
      e.preventDefault();

      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: () => (isMobile ? dispatch(setAsideMenuMobileState(true)) : navigate(url)),
        }),
      );
      return;
    }

    isMobile && dispatch(setAsideMenuMobileState(true));
  };

  const initActivePage = () => {
    urlProject[1] === 'team' || !urlProject[1] ? setActivePage('team') : null;
    urlProject[1] === 'projects' || !urlProject[1] ? setActivePage('projects') : null;
    urlProject[1] === 'notifications-list' || !urlProject[1] ? setActivePage('notifications-list') : null;
    urlProject[1] === 'profile' || !urlProject[1] ? setActivePage('profile') : null;
  };

  const initHeader = () => {
    const project = user_data.userInfo?.projects?.find(project => project.id == user_data.active_project_id);
    if (project) {
      if (urlProject[0] !== project.code && !(urlProject[1] === 'project-settings') && urlProject[0] !== 'no-projects-yet') {
        const sandboxUrlActiveTab = new URLSearchParams(location.search).get(`activeTab`);
        urlProject[0] = project.code;
        navigate({
          pathname: `/${urlProject.join('/')}`,
          search: sandboxUrlActiveTab ? `activeTab=${sandboxUrlActiveTab}` : '',
        });
      }
    }

    if (user_data.active_project_id === 0) {
      if (urlProject[0] !== 'no-projects-yet' && !(urlProject[0] === 'project_settings')) {
        urlProject[0] = 'no-projects-yet';
        navigate(`/${urlProject.join('/')}`);
      }
    }

    set_active_project_id(user_data.active_project_id);

    if (user_data.active_project_id && user_data.clientProjects?.length > 0) {
      dispatch(setActiveProject(+user_data.active_project_id));
      dispatch(changeActiveProject(user_data.active_project_id));
    }
  };

  const updateResolution = () => {
    setIsMobile(window.innerWidth <= 639 || window.innerHeight <= 639);
    setScreenWidth(window.innerWidth);
  };

  const selectProject = async e => {
    const callback = () => {
      const filterParam = searchParams.get('filter');
      if (urlProject[0] && urlProject[1] === 'needs-list' && filterParam) {
        navigate(`/${urlProject[0]}/needs-list`);
      }

      if (+e.target.value !== +active_project_id) {
        dispatch(clearNeedListData());
        dispatch(resetSandboxLoadings());
        dispatch(clearSubmittalsData());
        dispatch(clearSpecificationData());
        dispatch(clearTeamState());
        set_prev_active_project_id(+active_project_id);
        set_active_project_id(e.target.value);
        dispatch(setActiveProject(e.target.value));
        dispatch(hardResetFiltersReducer());
        dispatch(changeActiveProject(+e.target.value));
        dispatch(getProjectDetails(e.target.value));
        dispatch(getProjectById(e.target.value));
        dispatch(clearNeedListFilters());
        dispatch(clearRequestsFilters());
        dispatch(resetReportingFirstLoading());
        dispatch(resetDocumentsFirstMainLoading());
      }
    };

    if (promptData.isChanged) {
      e.preventDefault();

      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: () => callback(),
        }),
      );
      return;
    }

    callback();
  };

  const handleOpenProjectsSelect = e => {
    e.stopPropagation();
    setIsOpenProjectsSelect(true);
  };

  const handleCloseProjectsSelect = e => {
    e.stopPropagation();
    setIsOpenProjectsSelect(false);
  };

  const clearData = () => {
    const callback = () => {
      set_prev_active_project_id(0);
      dispatch(clearNeedListData());
      dispatch(resetSandboxLoadings());
      dispatch(clearSubmittalsData());
      dispatch(clearSpecificationData());
      dispatch(clearTeamState());
      dispatch(hardResetFiltersReducer());
      dispatch(clearNeedListFilters());
      dispatch(clearRequestsFilters());
      dispatch(resetReportingFirstLoading());
      dispatch(resetDocumentsFirstMainLoading());
      dispatch(clearPCDTableData());
    };

    if (promptData.isChanged) {
      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: () => {
            callback();
            navigate('/change-company');
          },
        }),
      );
      return;
    }

    callback();
  };

  const handleClick = (e, url) => {
    if (promptData.isChanged) {
      e.preventDefault();

      dispatch(
        setGlobalPrompPopUp({
          ...promptData,
          isOpenPopUp: true,
          callback: () => navigate(url),
        }),
      );
    }
  };

  const handleOpenActivityMenu = e => {
    setActivityMenuAnchorEl(e.currentTarget);
  };

  const handleCloseActivityMenu = () => {
    setActivityMenuAnchorEl(null);
  };

  const handleOpenProfileMenu = e => {
    setProfileMenuAnchorEl(e.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenuAnchorEl(null);
  };

  const exportActivity = () => {
    const nowDate = new Date().toISOString().slice(0, 19);
    const project_name = sessionStorage.getItem('project_name');
    const id = Number(sessionStorage.getItem('active_project_id'));

    dispatch(getCurrentProjectSpecialReport({ id: id, filename: `activity_project_${project_name}_special_report_${nowDate}.csv` }));
  };

  const initTheme = theme => {
    localStorage.setItem('theme', theme);
  };

  const handleChangeTheme = status => {
    if (status.target.checked) {
      dispatch(
        updateUser({
          theme: 'dark',
          first_name: user_data.userInfo.first_name,
          last_name: user_data.userInfo.last_name,
        }),
      );
      initTheme('dark');
      setTheme('dark');
    } else {
      dispatch(
        updateUser({
          theme: 'light',
          first_name: user_data.userInfo.first_name,
          last_name: user_data.userInfo.last_name,
        }),
      );
      initTheme('light');
      setTheme('light');
    }
    setSelectedTheme(!selectedTheme);
  };

  const handleCloseSupport = () => {
    dispatch(handleHelpMenu({ helpState: false }));
    dispatch(filesUploadClean({ type: 'commitment' }));
  };

  const handleOpenSupport = () => {
    dispatch(handleHelpMenu({ helpState: true }));
  };

  return (
    <header>
      <div className={`${themeClass}`}>
        {!isMobile && (
          <>
            <div className={`${themeClass}_navigation`}>
              <div className={`${themeClass}_navigation_logo`}>
                <Link
                  to={!isMobile && ROUTES.getSandBoxNew(projectName)}
                  onClick={e => openAsideMenu(e, ROUTES.getSandBoxNew(projectName))}
                >
                  <Logo isFull={false} />
                </Link>
              </div>
              {(active_project_id || active_project_id === 0) && user_data && clientProjects && currentClient ? (
                <SelectProjectView
                  containerClassName={`${themeClass}_navigation_projectSelect`}
                  user_data={user_data}
                  active_project_id={active_project_id}
                  selectProject={selectProject}
                  isOpenProjectsSelect={isOpenProjectsSelect}
                  clientProjects={clientProjects}
                  handleOpenProjectsSelect={handleOpenProjectsSelect}
                  handleCloseProjectsSelect={handleCloseProjectsSelect}
                  currentClientLogo={currentClientLogo}
                  currentClient={currentClient}
                  clearData={clearData}
                />
              ) : (
                <CustomSkeleton width={125} height={20} />
              )}
              {user_data.userInfo?.projects?.length && !isSeparateTab ? (
                <div className={cn(`${themeClass}_navigation_navIconsGroup`, { ['-mobile']: isMobile })}>
                  <Link
                    to={ROUTES.getProject(projectName)}
                    key={'project'}
                    className={!user_data.userInfo?.projects?.length ? 'disabled-link' : ''}
                    onClick={e => handleClick(e, ROUTES.getProject(projectName))}
                  >
                    <CustomTooltip title={'Projects'} placement={'bottom'}>
                      <div
                        className={`
                     ${themeClass}_navigation_navIconsGroup_navIcon
                     ${activePage === 'projects' && user_data.userInfo?.projects?.length ? '-active' : ''} 
                     ${!user_data.userInfo?.projects?.length ? '-disabled' : ''}
                    `}
                        id={'project-tourBlock'}
                      >
                        <div className={`${themeClass}_navigation_navIconsGroup_navIcon_iconCont`}>
                          <TokenIcon iconName={'projects'} size={24} />
                        </div>
                      </div>
                    </CustomTooltip>
                  </Link>
                  <Link
                    to={ROUTES.getTeam(projectName)}
                    key={'team'}
                    className={!user_data.userInfo?.projects?.length ? 'disabled-link' : ''}
                    onClick={e => handleClick(e, ROUTES.getTeam(projectName))}
                  >
                    <CustomTooltip title={'Team'} placement={'bottom'}>
                      <div
                        className={`
                     ${themeClass}_navigation_navIconsGroup_navIcon
                     ${activePage === 'team' && user_data.userInfo?.projects?.length ? '-active' : ''} 
                     ${!user_data.userInfo?.projects?.length ? '-disabled' : ''}
                    `}
                        id={'team-tourBlock'}
                      >
                        <div className={`${themeClass}_navigation_navIconsGroup_navIcon_iconCont`}>
                          <TokenIcon iconName={'team'} size={24} />
                        </div>
                      </div>
                    </CustomTooltip>
                  </Link>
                </div>
              ) : (
                !isSeparateTab && (
                  <div className={`${themeClass}_skeletonRow`}>
                    <div className={`${themeClass}_skeletonNavigation`}>
                      <div className={`${themeClass}_skeletonIcon`}>
                        <CustomSkeleton width={24} height={24} />
                      </div>
                      <div className={`${themeClass}_skeletonIcon`}>
                        <CustomSkeleton width={24} height={24} />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {userInfo && (
              <div className={cn(`${themeClass}_utilities`, { ['-mobile']: isMobile })}>
                {!isSeparateTab && (
                  <div className={`${themeClass}_utilities_navIconsGroup`}>
                    <>
                      {screenWidth > 959 && (
                        <>
                          <CustomTooltip title={'Support'} placement={'bottom'}>
                            <div
                              className={`${themeClass}_utilities_navIconsGroup_navIcon ${
                                !user_data.userInfo?.projects?.length ? '-disabled' : ''
                              }`}
                              onClick={handleOpenSupport}
                              id={'support-tourBlock'}
                            >
                              <div className={`${themeClass}_utilities_navIconsGroup_navIcon_iconCont`}>
                                <SVG src={require('../../../assets/icons/new_support_icon.svg')} />
                                {user_data?.userInfo?.whats_new_unread && user_data.userInfo?.projects?.length ? (
                                  <div className={`${themeClass}_utilities_navIconsGroup_navIcon_iconCont_count`}>
                                    <span>{user_data.userInfo.whats_new_unread > 99 ? '99+' : user_data?.userInfo.whats_new_unread}</span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </CustomTooltip>
                          {isAdmin && user_data.userInfo?.projects?.length ? (
                            <CustomTooltip title={'Activity'} placement={'bottom'}>
                              <div
                                className={`${themeClass}_utilities_navIconsGroup_navIcon
                          ${activePage === 'notifications-list' && user_data.userInfo?.projects?.length ? '-active' : ''} 
                           ${!user_data.userInfo?.projects?.length ? '-disabled' : ''}`}
                                onClick={handleOpenActivityMenu}
                              >
                                <div className={`${themeClass}_utilities_navIconsGroup_navIcon_iconCont`}>
                                  <SVG src={require('../../../assets/icons/new_activity_icon.svg')} />
                                  {unReadNotificationCount && user_data.userInfo?.projects?.length ? (
                                    <div className={`${themeClass}_utilities_navIconsGroup_navIcon_iconCont_notification`}></div>
                                  ) : null}
                                </div>
                              </div>
                            </CustomTooltip>
                          ) : null}
                        </>
                      )}
                    </>
                  </div>
                )}
                <div className={`${themeClass}_utilities_controls`}>
                  <Link to={ROUTES.getProfile(projectName)} onClick={e => handleClick(e, ROUTES.getProfile(projectName))}>
                    <div
                      className={`${themeClass}_utilities_controls_persona ${
                        activePage === 'profile' && user_data.userInfo?.projects?.length ? '-active' : ''
                      }`}
                      id={'profile-tourBlock'}
                    >
                      {userInfo.id !== 0 ? (
                        <PlaceholderInfo
                          type={'Persona'}
                          imagePath={iconAvatar}
                          firstName={userInfo.first_name}
                          lastName={userInfo.last_name}
                          detailInfo={`${userInfo.first_name} ${userInfo.last_name}`}
                          subInfo={userInfo?.projects?.length ? userRoles : 'Not assigned'}
                          tooltip={'User profile'}
                          size={24}
                          isHeader
                          isMobile={isMobile}
                          isTruncateHeader
                        />
                      ) : (
                        <CustomSkeleton width={125} height={32} />
                      )}
                    </div>
                  </Link>
                  <Logout containerClassName={`${themeClass}_utilities_controls_logout`} />
                  {!isSeparateTab && !isMobile && (
                    <OverflowMenu
                      activePage={activePage}
                      isAdmin={isAdmin}
                      openSupport={handleOpenSupport}
                      currentClient={currentClient}
                      containerClassName={`${themeClass}_utilities_controls_overflowMenu`}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {isMobile && (
          <div className={`${themeClass}_navigation`}>
            <div className={`${themeClass}_navigation_logo`}>
              <TokenIcon
                iconName={'burger-menu'}
                size={24}
                customClass={`${themeClass}_navigation_burger`}
                clickHandler={e => openAsideMenu(e, ROUTES.getSandBoxNew(projectName))}
              />
            </div>
            {(active_project_id || active_project_id === 0) && user_data && clientProjects && currentClient ? (
              <SelectProjectView
                containerClassName={`${themeClass}_navigation_projectSelect`}
                user_data={user_data}
                active_project_id={active_project_id}
                selectProject={selectProject}
                isOpenProjectsSelect={isOpenProjectsSelect}
                clientProjects={clientProjects}
                handleOpenProjectsSelect={handleOpenProjectsSelect}
                handleCloseProjectsSelect={handleCloseProjectsSelect}
                currentClientLogo={currentClientLogo}
                currentClient={currentClient}
                clearData={clearData}
              />
            ) : (
              <CustomSkeleton width={125} height={20} />
            )}
            <div onClick={handleOpenProfileMenu}>
              <div
                className={`${themeClass}_utilities_controls_persona ${
                  activePage === 'profile' && user_data.userInfo?.projects?.length ? '-active' : ''
                }`}
                id={'profile-tourBlock'}
              >
                {userInfo.id !== 0 ? (
                  <PlaceholderInfo
                    type={'Persona'}
                    imagePath={iconAvatar}
                    firstName={userInfo.first_name}
                    lastName={userInfo.last_name}
                    detailInfo={`${userInfo.first_name} ${userInfo.last_name}`}
                    subInfo={userInfo?.projects?.length ? userRoles : 'Not assigned'}
                    tooltip={'User profile'}
                    size={24}
                    isHeader
                    isMobile={isMobile}
                  />
                ) : (
                  <CustomSkeleton width={24} height={24} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Popover
        open={!!activityMenuAnchorEl}
        onClose={handleCloseActivityMenu}
        anchorEl={activityMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClass}_csvPaper`,
        }}
      >
        <DropdownMenuItem
          size={'md'}
          title={'Export CSV'}
          value={1}
          itemId={1}
          leftElem={<TokenIcon iconName={'file-csv'} size={16} customClass={`${themeClass}_csvIcon`} />}
          onItemClick={exportActivity}
        />
      </Popover>
      <UserPopup
        anchorEl={profileMenuAnchorEl}
        onClose={handleCloseProfileMenu}
        isOpen={!!profileMenuAnchorEl}
        userInfo={userInfo}
        iconAvatar={iconAvatar}
        userRoles={userRoles}
        handleChangeTheme={handleChangeTheme}
        theme={selectedTheme}
      />
      <HelpDrawerContainer open={!!helpMenuState} handleCloseDrawer={handleCloseSupport} />
      <ErrorsPopUp isSeparateTab={isSeparateTab} />
    </header>
  );
};

export default React.memo(AppBar);
