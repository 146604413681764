import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
// import * as actionTypes from './notificationsActions';
import { getNotificationsFilters, getNotificationsList, readAll } from './notificationsThunk';
import { INotificationsFilter, INotificationsReducer } from './notificationsReducer.model';

const initialState: INotificationsReducer = {
  notificationsInfo: {
    data: [],
    notifications: null,
    current_page: 0,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
  },
  loading: false,
  unReadNotificationCount: 0,
  notificationFilters: null,
};

export const notificationsSlice = createSlice({
  initialState,
  name: 'notificationsReducer',
  reducers: {
    setUnreadNotificationCount(state, { payload }: PayloadAction<number>) {
      state.unReadNotificationCount = payload;
    },

    socketHandleNotification(state, { payload }) {
      if (!payload?.notification_data) {
        return state;
      }
    },
    setNotificationFilters(state, { payload }: PayloadAction<INotificationsFilter>) {
      state.loading = false;
      state.notificationFilters = payload;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getNotificationsList
       */
      .addCase(getNotificationsList.pending, state => {
        state.loading = true;
      })
      .addCase(getNotificationsList.fulfilled, (state, { payload }) => {
        const notificationsData = payload.notifications.data;
        const notificationsAnotherData = payload.notifications;

        //TODO old?
        // const notificationsData =
        // payload.navigationData.notifications && payload.navigationData.notifications.current_page > 1
        //   ? [...(state.notificationsInfo.notifications || []), ...(payload.navigationData.notifications.data || [])]
        //   : payload.navigationData.notifications.data;

        state.loading = false;
        state.notificationsInfo = notificationsAnotherData;
        state.notificationsInfo.notifications = [...notificationsData];
      })
      .addCase(getNotificationsList.rejected, state => {
        state.loading = false;
      })
      /**
       * readAll
       */
      .addCase(readAll.pending, state => {
        state.loading = true;
      })
      .addCase(readAll.fulfilled, state => {
        state.unReadNotificationCount = 0;
        state.loading = false;
      })
      .addCase(readAll.rejected, state => {
        state.loading = false;
      })
      /**
       * getNotificationsFilters
       */
      .addCase(getNotificationsFilters.pending, state => {
        state.loading = true;
      })
      .addCase(getNotificationsFilters.fulfilled, (state, { payload }) => {
        state.notificationFilters = payload;
        state.loading = false;
      })
      .addCase(getNotificationsFilters.rejected, state => {
        state.loading = false;
      });
  },
});

export default notificationsSlice.reducer;
