export const descriptionFieldStyles = theme => ({
  mergeDescription: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& .quill': {
      flex: 'auto',
      minWidth: 'calc(100% - 225px)',
    },
  },
  mergeDescriptionEditRep: {
    '& .quill': {
      minWidth: 'calc(100% - 325px)',
    },
  },
  description: {
    // border: '1px #454a6a solid',
    borderRadius: '2px',
    // border: 'var(--button-pullRequest-border)',
    position: 'relative',
    border: 'var(--background-border)',
    '& .quill': {
      minHeight: '96px',
    },
    '&.-focus': {
      border: '1px solid var(--color2)',
      '& .quill, & .ql-editor': {
        background: 'var(--selectedFilterDropDown)',
      },
    },
    '&.-disabled': {
      border: 'var(--button-request-outline-border-disabled) !important',
      '& .quill, & .ql-editor': {
        background: 'var(--background-header)',
      },
    },
  },
  triggerBtn: {
    position: 'absolute',
    bottom: '0',
    left: 'calc(50% - 8px)',
    width: '15px',
    height: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    backgroundColor: 'var(--background-sheet)',
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      width: '5px',
      height: '9px',
      transform: 'rotate(0deg)',
      // fill: '#fff !important',
    },
  },
  buttonForm: {
    background: 'var(--background-main)',
  },
  fullHeight: {
    '& .quill': {
      height: 'auto !important',
      minHeight: '205px',
    },
  },
  fullHeightCommitment: {
    '& .quill': {
      height: 'auto !important',
    },
  },
  fullHeightBtn: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    margin: '0 0 5px',
    color: 'var(--button-hover-menu-text)',
    fontFamily: 'var(--font-general)',
  },
  AttachBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--color-noSelect)',
    fontSize: '14px',
    textTransform: 'none',
    padding: '0 8px 3px 8px',
    '& span': {
      color: 'var(--color-noSelect)',
      fontSize: '12px',
      fontFamily: 'var(--font-general)',
    },
  },
  SearchIcon: {
    marginRight: '5px',
    padding: '3px',
    fontSize: '12px',
    border: 'solid 1px',
    borderRadius: '50%',
  },
  attachFile: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paperFullScreenMax: {
    margin: '50px',
    height: '90vh',
    // backgroundColor: '#413344',
  },
});

export const descriptionStyles = theme => ({
  SandBoxDescription: {
    width: '100%',
    padding: 0,
    border: 'none',
    margin: 0,
    // '& fieldset': {
    //   border:'none',
    //   padding: '0',
    // },
    '& > div': {
      padding: '7px 15px',
      '&:after': {
        border: 'none',
      },
    },
  },
  SandBoxDescriptionInput: {
    padding: '0',
    boxSizing: 'border-box',
    fontWeight: 400,
    fontSize: '15px',
  },
  underline: {
    background: 'red',
  },
});
