import React, { FC, useMemo } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './StatusReviewStyles.module.scss';
import CustomTooltip from '../../Tooltip/Tooltip';

const StatusReview: FC<{
  status: 'no_exceptions' | 'exceptions' | 'revise_resubmit' | 'no_action' | 'response_required';
  // status: 'Confirmed - no exception taken' | 'Confirmed - exceptions as noted' | 'Revise & resubmit' | 'No review required';
  showLabel?: boolean;
  showTooltip?: boolean;
}> = ({ status, showLabel = true, showTooltip }) => {
  const themeClass = useGetThemeClass('b-statusReview');

  const statusName = useMemo(() => {
    if (status === 'no_exceptions') {
      return 'Confirmed - no exception taken';
    }

    if (status === 'exceptions') {
      return 'Confirmed - exceptions as noted';
    }

    if (status === 'revise_resubmit') {
      return 'Revise & resubmit';
    }

    if (status === 'no_action') {
      return 'No review required';
    }

    if (status === 'response_required') {
      return 'Response required';
    }
  }, [status]);

  return (
    <>
      <CustomTooltip title={showTooltip ? statusName : ''} placement={'top'}>
        <div className={`${themeClass}`}>
          <div
            className={cn(`${themeClass}_svg_icon`, {
              [`${themeClass}_sys-success`]: status === 'no_exceptions',
              [`${themeClass}_sys-warning`]: status === 'exceptions',
              [`${themeClass}_sys-danger`]: status === 'revise_resubmit',
              [`${themeClass}_tertiary-gray`]: status === 'no_action',
              [`${themeClass}_sys-informative`]: status === 'response_required',
            })}
          >
            <TokenIcon iconName={'status'} size={12} />
          </div>
          {showLabel ? <div className={'label_text'}>{statusName}</div> : null}
        </div>
      </CustomTooltip>
    </>
  );
};

export default React.memo(StatusReview);
