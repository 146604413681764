import React from 'react';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import ChipsStandardTag from '../../../../controls/Chips/ChipsStandardTag/ChipsStandardTag';

import './DrawingItemStyles.scss';

interface IProps {
  drawing: PCDApi.PCDListDrawingInterface;
  handleDrawingItemClick?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  handleDrawingCheckBoxClick?: (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  isActiveCheckBoxForDisabledItems?: boolean;
}

const DrawingItem: React.FC<IProps> = ({
  drawing,
  handleDrawingItemClick,
  handleDrawingCheckBoxClick,
  isActiveCheckBoxForDisabledItems,
}) => {
  const themeClass = useGetThemeClass('b-drawingItem');

  return (
    <>
      <div className={`${themeClass}_item`} onClick={e => handleDrawingItemClick(e, drawing)}>
        <div className={`${themeClass}_item_leftPart`}>
          <CheckBox
            isTripleState
            important={drawing.selected || (isActiveCheckBoxForDisabledItems ? 2 : 0)}
            onClick={e => handleDrawingCheckBoxClick(e, drawing)}
          />
          <ChipsStandardTag value={drawing.title} />
        </div>
        <div className={`${themeClass}_item_icon`}>
          <TokenIcon iconName={drawing.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
        </div>
      </div>
    </>
  );
};

export default DrawingItem;
