import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { GetResolution } from '../../../../helpers/ScreenResolution/GetResolution';
import cn from 'classnames';
import Badges from '../../../controls/Badges/Badges';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CustomTooltip from '../../../controls/Tooltip/Tooltip';
import StatusNFsnippet from '../../../controls/Status/StatusNFsnippet/StatusNFsnippet';

import './AsideListItemStyles.scss';

interface IProps {
  onClick: (name: string) => void;
  opened: boolean;
  icon?: any;
  title: string;
  name: string;
  disabled: boolean;
  counter?: number;
  tourActive: boolean;
  wipBadge?: boolean;
  isLabel?: boolean;
}

const AsideListItem: FC<IProps> = ({ opened, icon, tourActive, counter, name, title, disabled, onClick, wipBadge, isLabel }) => {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-aside-list-item');

  const clickHandler = () => {
    onClick(name);
  };

  return (
    <CustomTooltip title={title} placement={'right'} customTooltipClass={'ml11'}>
      <div
        id={`${name}-tourBlock`}
        className={cn(`${themeClass}_listItem`, {
          [`${themeClass}_asideItemActive`]: opened && !tourActive,
          [`${themeClass}_tourActive`]: tourActive,
        })}
        onClick={!disabled && clickHandler}
      >
        <div className={`${themeClass}_icon_wrapper`}>
          {counter && typeof counter === 'number' && title === 'Sandbox' ? (
            <div className={`${themeClass}_badges`}>
              <Badges value={counter} size="lg" type={'notification'} />
            </div>
          ) : null}
          {wipBadge && (
            <div className={`${themeClass}_badges`}>
              <Badges size="lg" type={'wip'} />
            </div>
          )}
          {icon && <ListItemIcon className={`${themeClass}_listItemIcon`} children={icon} />}
        </div>
        {isMobile && <span className={`${themeClass}_title`}>{title}</span>}
        {isLabel && (
          <div className={`${themeClass}_listItem_label`}>
            <StatusNFsnippet type={'upd'} size={'lg'} />
          </div>
        )}
      </div>
    </CustomTooltip>
  );
};
export default AsideListItem;
