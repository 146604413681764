export const FilesListStyles = theme => ({
  FileList: {
    padding: '3px',
    // display: 'flex',
    // flexWrap: 'wrap',
    listStyle: 'none',
    width: 'calc(100% - 25px)',
    '@media (max-width: 767px)': {
      padding: '0',
      width: '100%',
    },
  },
  FileListItem: {
    margin: '5px',
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    border: 'var(--background-border)',

    '@media (max-width: 767px)': {
      margin: '5px 0',
    },
  },
  FileListItemPrimary: {
    background: 'green',
  },
  FileListItemSecondary: {
    background: 'var(--background-popupContent)',
  },
  FileListIcons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fileName: {
    fontSize: '12px',
    color: 'var(--color)',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconClose: {
    marginLeft: '5px',
    color: 'var(--color-rdg)',
    fontSize: '12px',
    cursor: 'pointer',
  },
  icon: {
    width: '25px',
    minWidth: '25px',
    marginRight: '5px',
  },
  paperFullScreenMax: {
    margin: '50px',
    height: '90vh',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});
