import React, { FC, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ShowMoreLocations from '../../../../controls/ShowMore/ShowMoreLocations/ShowMoreLocations';
import { ColumnToolTip } from '../../../AsideHolder/AsideHolder';
import { ButtonView } from '../../../../controls';
import SVG from 'react-inlinesvg';
import { InputBase } from '@material-ui/core';
import _ from 'lodash';

import './Styles.scss';

interface SelectFieldValue {
  id: number;
  title: string;
}

interface IProps {
  options: SelectFieldValue[];
  values: SelectFieldValue[];
  isMultiple?: boolean;
  atLeastOne?: boolean;
  isSearchable?: boolean;
  onClose: (values: SelectFieldValue[], isCommit?: boolean) => void;
}

interface IState {
  values: SelectFieldValue[];
  isChanged: boolean;
  searchValue: string;
}

const MenuProps: any = {
  PaperProps: {
    style: {
      width: 200,
      maxHeight: 400,
      overflowY: 'auto',
      backgroundColor: 'var(--background-main)',
    },
  },
  MenuListProps: {
    style: {
      backgroundColor: 'var(--background-select)',
      '&:hover': {
        backgroundColor: 'var(--background-main)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
};

const SelectField: FC<IProps> = props => {
  const { values, onClose, options, isMultiple, atLeastOne, isSearchable } = props;

  const initialState: IState = {
    values: props.values,
    isChanged: false,
    searchValue: '',
  };

  let [state, setState] = useState<IState>(initialState);

  const handleChange = (item, isChecked: boolean) => {
    let newVals = [...state.values];
    if (isMultiple) {
      if (isChecked) {
        if (!atLeastOne || (atLeastOne && state.values.length > 1)) {
          newVals = state.values.filter(val => val.id !== item.id);
        }
      } else {
        newVals.push(item);
      }
    } else {
      if (!atLeastOne && isChecked) {
        newVals = [];
      } else {
        newVals = [item];
      }
    }

    setState({ ...state, values: newVals, isChanged: true });
  };

  const handleClear = () => {
    setState({ ...state, values: [], isChanged: true });
  };

  const handleClose = () => {
    onClose(state.values, state.isChanged);
  };

  const calcValuesBySearch = query => {
    return options.filter(v => {
      if (v.title.trim().toLowerCase().search(_.escapeRegExp(query)?.trim().toLowerCase()) !== -1) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleChangeSearch = ({ target }) => {
    setState({ ...state, searchValue: target.value });
  };

  const render = () => {
    let renderOptions = options;
    if (isSearchable && state.searchValue.length > 2) {
      renderOptions = calcValuesBySearch(state.searchValue);
    }

    return (
      <>
        <Select
          renderValue={() => <ShowMoreLocations title={'Buildings'} items={values} background={'var(--background-tag1)'} />}
          multiple
          MenuProps={{ ...MenuProps }}
          onClose={handleClose}
          open={true}
          value={state.values}
        >
          {isSearchable ? (
            <div className={'search searchDropdown'}>
              <SVG src={require('../../../../../assets/icons/search.svg')} />
              <InputBase
                // placeholder={'Search'}
                style={{ marginLeft: '5px' }}
                // onFocus={this.onSearchFocus}
                // onBlur={this.onSearchBlur}
                onChange={handleChangeSearch}
                value={state.searchValue}
                className={'search__input'}
              />
            </div>
          ) : null}
          {renderOptions.map(opt => {
            const isChecked = Boolean(state.values.find(item => item.id === opt.id));
            const title = opt.title;
            return (
              <MenuItem key={`be-sel-i-${opt.id}`} value={opt.id} className={'menuItem'}>
                <Checkbox onChange={() => handleChange(opt, isChecked)} checked={isChecked} className={'filter__checkBox'} />
                <ColumnToolTip title={title} placement="top">
                  <span className={'title NeedListSelectField__itemTitle'}>{title}</span>
                </ColumnToolTip>
              </MenuItem>
            );
          })}
          {!atLeastOne && <ButtonView handleClick={handleClear} type={'outline'} title={'Clear'} style={{ margin: '10px auto 0' }} />}
        </Select>
      </>
    );
  };

  return render();
};

SelectField.defaultProps = {
  isMultiple: false,
  atLeastOne: false,
};

export default SelectField;
