import React, { FC, useRef, useMemo, useState, useEffect } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import ContentSwitcher from '../../../../controls/ButtonComponents/ContentSwitcher/ContentSwitcher';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { BreadcrumbModel, LoginUserModel, RequestTypeModel, WatcherGroupModel, WatcherModel } from '../../../../../models';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import cn from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { SubmittalCardTabNamesModel } from '../../constants/submittalTabs';
import { ISubmittal, ISubmittalUpdateModel, SubmittalModel } from '../../../../../models/submittals/submittals.model';
import ActionButton from '../../../../controls/ButtonComponents/ActionButton/ActionButton';
import OverflowMenuButton from '../../../../controls/ButtonComponents/OverflowMenuButton/OverflowMenuButton';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import StatusSubmittal from '../../../../controls/Status/StatusSubmittal/StatusSubmittal';
import { BICorMentionSelect } from '../../../../controls/BICorMentionSelect/BICorMentionSelect';
import OvalButton from '../../../../controls/ButtonComponents/OvalButton/OvalButton';
import DateRow from '../../../../controls/DateRow/DateRow';
import { PopupCreatedBy } from '../../../../controls/PopupCreatedBy/PopupCreatedBy';
import Watchlist from '../../../WatchingBtn/Watchlist';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import ChipsRequestType from '../../../../controls/Chips/ChipsRequestType/ChipsRequestType';
import { DropdownItemModel } from '../../../../../models/global';
import { setHotList } from '../../../../../store/request/requestLogic(HOLD)';
import DropdownDownloadList from '../../../../controls/DropdownDownloadList/DropdownDownloadList';
import { DropdownMenuItem } from '../../../../controls/Dropdowns/DropdownParts';
import { submittalSave } from '../../../../../store/submittal/submittalThunk';
import Popover from '@material-ui/core/Popover';
import { RevisionsList } from '../../../CardPcd/mainParts/Header/parts/RevisionsList/RevisionsList';
import Divider from '../../../../controls/Divider/Divider';
import { CardBreadcrumbs } from '../../../CardBreadcrumbs';
import { submittalSlice } from '../../../../../store/submittal/submittalSlice';
import { API_URI, DOMAIN_URI } from '../../../../../service/links';
import commonApi from '../../../../../service/Api/commonApi';
import FileHelper from '../../../../../helpers/FileHelper';
import fileDownload from 'js-file-download';
import ChipsDataType from '../../../../controls/Chips/ChipsDataType/ChipsDataType';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import { breadcrumbsSlice } from '../../../../../store/breadcrumbs/breadcrumbsReducer';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import useRouter from '../../../../../hooks/useRouter/useRouter';

import './HeaderStyles.scss';

const { setNewNfCard, setNewNfCardSubmittalId } = requestSlice.actions;
const { resetSocketSubmittalHotlistState } = submittalSlice.actions;
const { setNewBreadcrumbs } = breadcrumbsSlice.actions;

const overflowMenuSelect = [
  {
    id: 'download',
    title: 'Download PDF',
  },

  {
    id: 'reflect',
    title: 'Reflect as Input data',
  },
  {
    id: 'generate-rfi',
    title: 'Generate RFI',
  },
  {
    id: 'generate-cl',
    title: 'Generate late CL Input Check',
  },
  {
    id: 'visibleSubmittal',
    title: 'visibleSubmittal',
  },
  {
    id: 'undo',
    title: 'Undo Close & Distribute',
  },
  {
    id: 'soft-delete',
    title: 'Delete current revision',
  },
  {
    id: 'hard-delete',
    title: 'Delete submittal',
  },
];

interface DropdownItemModelExt extends DropdownItemModel {
  code?: string;
  alias?: string;
}

interface IProps {
  isDragRequestCard: boolean;
  selectedRequest: ISubmittal;
  isCreateRevision: boolean;
  handleChangeCardView: () => void;
  requestHeadClose: () => void;
  breadcrumbs: BreadcrumbModel[] | null;
  noBreadcrumbs: boolean;
  selectRequestHandler: (id: number, isNavToSubtask?: boolean) => void;
  isPreviewCard: boolean;
  isSeparateTab: boolean;
  login_user: LoginUserModel;
  onChangeActiveTab: (tab: SubmittalCardTabNamesModel) => void;
  tags: RequestTypeModel[];
  selectedTags: RequestTypeModel;
  handleChangeRequestTypeSelect: (tag: RequestTypeModel) => void;
  isCreateSubmittal: boolean;
  handleChangePrivate: (state: boolean) => void;
  selectedPrivate: 0 | 1;
  errors: any;
  handleChangeRevision: (id: number) => void;
  isDeactivated: boolean;
  isPreview: boolean;
  isCardLoading: boolean;
  submittalWatchers: WatcherModel[] | null;
  submittalWatchersGroup: WatcherGroupModel[] | null;
  isPreviousRevisionDeactivated: boolean;
  activeTab: string;
  handleOpenUndoPopup: () => void;
  openDeletePopup: (force: 1 | 0) => void;
  is_procore_sync_needed: number | boolean;
  handleOpenProcoreConfirmPopUp: () => void;
  handleCloseProcoreConfirmPopUp: () => void;
  handleChangeProcoreSyncStatus: () => void;
  isProcoreConfirmPopUpOpen: boolean;
}

const Header: FC<IProps> = ({
  isDragRequestCard,
  selectedRequest,
  isCreateRevision,
  handleChangeCardView,
  requestHeadClose,
  breadcrumbs,
  noBreadcrumbs,
  selectRequestHandler,
  isPreviewCard,
  isSeparateTab,
  login_user,
  onChangeActiveTab,
  tags,
  selectedTags,
  handleChangeRequestTypeSelect,
  isCreateSubmittal,
  handleChangePrivate,
  selectedPrivate,
  errors,
  handleChangeRevision,
  isDeactivated,
  isPreview,
  isCardLoading,
  submittalWatchers,
  isPreviousRevisionDeactivated,
  handleOpenUndoPopup,
  openDeletePopup,
  activeTab,
  is_procore_sync_needed,
  handleOpenProcoreConfirmPopUp,
  handleCloseProcoreConfirmPopUp,
  isProcoreConfirmPopUpOpen,
  handleChangeProcoreSyncStatus,
  submittalWatchersGroup,
}) => {
  const dispatch = useAppDispatch();
  const { setUrlSubmittal, getIsSubmittalsFromURL } = useRoutingHelper();
  const dialogClass = useGetThemeClass('dialogPopUp');
  const { urlProject } = useRouter();

  const { active_project_id } = useAppSelector(state => state.userReducer);
  const { saveHotlistLoading, socketHotlistState } = useAppSelector(state => state.submittalSlice);
  const sandBoxCardIDHotList = useAppSelector(state => state.requestReducer.sandBoxCardIDHotList);

  const submittals_list = useAppSelector(state => state.specAndSubsSlice.submittals.submittals_list);
  const isSubmittalsFirstLoading = useAppSelector(state => state.specAndSubsSlice.submittals.isSubmittalsFirstLoading);
  const submittals_list_output = useAppSelector(state => state.specAndSubsSlice.submittals.submittals_list_output);
  const isSubmittalsFirstOutputLoading = useAppSelector(state => state.specAndSubsSlice.submittals.isSubmittalsFirstOutputLoading);
  const isSubmittalsSorted = useAppSelector(state => state.specAndSubsSlice.submittals.isSorted);

  const listForNavigate = (urlProject[1] === 'output-data' ? [...(submittals_list_output || [])] : [...(submittals_list || [])]).sort(
    (a, b) => {
      if (a.mf_code === b.mf_code) {
        if (a.sf < b.sf) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.mf_code > b.mf_code) {
        return 1;
      } else {
        return -1;
      }
    },
  );
  const isLoadingForNavigate = urlProject[1] === 'output-data' ? isSubmittalsFirstOutputLoading : isSubmittalsFirstLoading;

  const [isHotList, setIsHotList] = useState<boolean>(false);
  const [isHotListManually, setIsHotListManually] = useState<boolean>(false);
  const [isOpenCreateBy, setIsOpenCreateBy] = useState<boolean>(false);
  const [isMenuDownloadListOpen, setIsMenuDownloadListOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenedCommitmentVersions, setIsOpenedCommitmentVersions] = useState(null);
  const [isOpenedRevisions, setIsOpenedRevisions] = useState(null);

  const headerRef = useRef<HTMLDivElement>();
  const refCreatedBy = useRef<HTMLDivElement>();

  const isAdmin = useMemo(() => {
    return login_user && login_user.role && !!Object.keys(login_user.role).filter(key => key === '1' || key === '4' || key === '5').length;
  }, [login_user]);

  const isAccessToDeleteIssuedRevision = useMemo(() => {
    return login_user && login_user.role && !!Object.keys(login_user.role).filter(key => key === '1' || key === '4').length;
  }, [login_user]);

  const nextRevision = useMemo(() => {
    return selectedRequest && selectedRequest.revisions && selectedRequest.revisions[selectedRequest.version + 1];
  }, [selectedRequest]);

  const filteredOverflowMenuItems = useMemo(() => {
    const isDraft = selectedRequest?.status?.alias === 'draft';
    const isClosed = selectedRequest?.status?.alias === 'closed';

    const allowDeactivate = !isDraft && !nextRevision && isAdmin;
    const allowDelete =
      (!isCreateRevision && selectedRequest?.version && isDraft && isAdmin) ||
      (!isDraft && !nextRevision && isAccessToDeleteIssuedRevision && selectedRequest?.version !== 0);
    const allowUndo = isClosed && !selectedRequest?.parent;
    const allowReflect = selectedRequest && !!selectedRequest?.is_output;
    const allowGenerateRfi = selectedRequest && !!selectedRequest?.is_output;
    const allowGenerateCl = (selectedRequest && !selectedRequest?.is_output) || selectedRequest?.reflect_input;

    return overflowMenuSelect.filter(item => {
      if (item.id === 'hard-delete') {
        return allowDelete || !nextRevision;
      } else if (item.id === 'soft-delete') {
        return allowDelete;
      } else if (item.id === 'undo') {
        return allowUndo;
      } else if (item.id === 'visibleSubmittal') {
        return allowDeactivate;
      } else if (item.id === 'download') {
        return true;
      } else if (item.id === 'reflect') {
        return allowReflect;
      } else if (item.id === 'generate-rfi') {
        return allowGenerateRfi;
      } else if (item.id === 'generate-cl') {
        return allowGenerateCl;
      } else {
        return false;
      }
    });
  }, [isAccessToDeleteIssuedRevision, isAdmin, nextRevision, selectedRequest, isCreateRevision]);

  const { is1440Resolution, isMobile } = GetResolution();
  const showSmall = isDragRequestCard || is1440Resolution;
  const isDraftedCard = selectedRequest?.status?.alias === 'draft';

  const isSubmittalsModule = getIsSubmittalsFromURL();

  const resolutionName = useMemo(() => {
    if (isMobile) {
      return 'mobile';
    }

    if (showSmall) {
      return 'small';
    }

    return 'full';
  }, [showSmall, isMobile]);

  const themeClass = `${useGetThemeClass('b-header-card-submittal')}-${resolutionName}`;
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  useEffect(() => {
    if (selectedRequest) {
      const isIDsHotList = sandBoxCardIDHotList.find(value => value === selectedRequest.id);
      if (isIDsHotList) {
        setIsHotListManually(true);
      }
      setIsHotList(selectedRequest.is_hot_list);
    }
  }, [selectedRequest, sandBoxCardIDHotList]);

  useEffect(() => {
    if (socketHotlistState.changedNow) {
      setIsHotList(socketHotlistState.is_hot_list);
      dispatch(resetSocketSubmittalHotlistState());
    }
  }, [socketHotlistState.changedNow]);

  const truncateBreadcrumbsNumber = useMemo(() => {
    if (isMobile) {
      return 3;
    }

    if (isDragRequestCard || is1440Resolution) {
      return 1;
    }

    return 3;
  }, [isMobile, isDragRequestCard, is1440Resolution]);

  const handleSetHotList = e => {
    e.stopPropagation();
    e.preventDefault();

    const project_id = +sessionStorage.getItem('active_project_id');

    dispatch(
      setHotList({
        project_id,
        entity_ids: [selectedRequest.id],
        entity: 'submittal',
        action: isHotList ? 'remove' : 'add',
        accordion_alias: null,
      }),
    );
    setIsHotList(prevState => !prevState);
  };

  const handleSetPrivat = e => {
    e.stopPropagation();
    e.preventDefault();

    handleChangePrivate(!selectedPrivate);
  };

  const handleOpenAuthor = () => {
    setIsOpenCreateBy(true);
  };

  const handleCloseAuthor = () => {
    setIsOpenCreateBy(false);
  };

  const handleMenuDownloadListOpen = e => {
    setAnchorEl(e.currentTarget);
    setIsMenuDownloadListOpen(true);
  };

  const closeMenuDownloadList = () => {
    setIsMenuDownloadListOpen(false);
    setAnchorEl(null);
  };

  const handleRevisionsPopover = e => {
    setIsOpenedRevisions(isOpenedRevisions ? null : e?.currentTarget || null);
  };

  const handleNavigate = (to: 'down' | 'up' | 'full-up' | 'full-down') => () => {
    const currentIndex = listForNavigate.findIndex(card => card.id === selectedRequest.id);

    let cardToNavigate: SubmittalModel;

    if (to === 'down' || to === 'up') {
      cardToNavigate = listForNavigate[to === 'down' ? currentIndex + 1 : currentIndex - 1];
    } else {
      cardToNavigate = listForNavigate[to === 'full-down' ? listForNavigate.length - 1 : 0];
    }

    if (breadcrumbs?.length) {
      const newBreadcrumbs: BreadcrumbModel[] =
        (breadcrumbs.length === 2 && breadcrumbs.some(card => card.id === cardToNavigate.id)) || breadcrumbs.length === 1
          ? []
          : breadcrumbs
              .filter(card => card.id !== cardToNavigate.id)
              .map(card => {
                if (card.id === selectedRequest.id) {
                  return { id: cardToNavigate.id, title: cardToNavigate.sf_index, breadcrumbType: 'submittal', activeTab: activeTab };
                } else {
                  return { ...card };
                }
              });

      dispatch(setNewBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs', array: newBreadcrumbs }));
    }
    setUrlSubmittal(String(cardToNavigate.id), activeTab);
  };

  const handleChangeOverflowMenuSelect = (value: string) => {
    if (value === 'download') {
      downloadRevisionPdf(selectedRequest);
    } else if (value === 'visibleSubmittal') {
      const action = selectedRequest?.status?.title === 'Deactivated' ? 'activate' : 'deactivate';
      const bodyParams: ISubmittalUpdateModel = {
        mf_id: selectedRequest?.mf_id,
        status: action === 'deactivate' ? 'deactivated' : selectedRequest?.status?.alias,
        type_id: selectedRequest?.type?.id,
      };
      dispatch(
        submittalSave({
          submittalId: selectedRequest?.id,
          bodyParams,
          isNotFormData: false,
          callbackNavigation: id => {
            setUrlSubmittal(id);
          },
        }),
      );
    } else if (value === 'undo') {
      handleOpenUndoPopup();
    } else if (value === 'soft-delete') {
      openDeletePopup && openDeletePopup(0);
    } else if (value === 'hard-delete') {
      openDeletePopup && openDeletePopup(1);
    } else if (value === 'reflect') {
      const bodyParams: ISubmittalUpdateModel = {
        mf_id: selectedRequest?.mf_id,
        type_id: selectedRequest?.type?.id,
        status: selectedRequest?.status?.alias,
        reflect_input: selectedRequest.reflect_input ? 0 : 1,
      };
      dispatch(
        submittalSave({
          submittalId: selectedRequest?.id,
          bodyParams,
          isNotFormData: false,
          callbackNavigation: id => {
            setUrlSubmittal(id);
          },
        }),
      );
    } else if (value === 'generate-rfi') {
      dispatch(setNewNfCard());
      dispatch(setNewNfCardSubmittalId({ submittalId: selectedRequest?.id, is_generate_nf_rfi: 1 }));
    } else if (value === 'generate-cl') {
      dispatch(setNewNfCard());
      dispatch(setNewNfCardSubmittalId({ submittalId: selectedRequest?.id, is_generate_nf_rfi: 0 }));
    }
    closeMenuDownloadList();
  };

  const downloadRevisionPdf = async (revision: ISubmittal) => {
    const url = new URL(`${DOMAIN_URI}${API_URI}export/${revision.id}/submittal-pdf`);
    const response = await commonApi.getFile(url.href);
    const fileName = FileHelper.getFileName(response);
    fileDownload(response.data, fileName);
  };

  const renderMenuItem = (item: DropdownItemModelExt) => {
    const getIcon = () => {
      switch (item.id) {
        case 'visibleSubmittal':
          return selectedRequest?.status?.title !== 'Deactivated' ? 'deactivate' : 'activate';
        case 'undo':
          return 'reply';
        case 'hard-delete':
          return 'trash';
        case 'soft-delete':
          return 'trash';
        case 'reflect':
          return selectedRequest.reflect_input ? 'un-mirror' : 'mirror';
        case 'generate-rfi':
          return 'rfi';
        case 'generate-cl':
          return 'input-check';
        default:
          return item.id;
      }
    };

    const getTitle = () => {
      switch (item.id) {
        case 'visibleSubmittal':
          return selectedRequest?.status?.title === 'Deactivated' ? 'Activate' : 'Deactivate';
        case 'reflect':
          return selectedRequest?.reflect_input ? 'Use as Output data only' : 'Reflect as Input data';
        default:
          return item.title;
      }
    };

    const itemDropdown = (
      <>
        <div
          className={cn(`${themeClass}_overflowMenu`, {
            ['-delete']: item.id === 'undo' || item.id === 'hard-delete' || item.id === 'soft-delete',
            ['-reflect']: item.id === 'reflect' && selectedRequest?.reflect_input,
          })}
        >
          <TokenIcon iconName={getIcon()} size={16} customClass={`${themeClass}_overflowMenu_svg_icon`} />
          <div className={`${themeClass}_overflowMenu_label`}> {getTitle()} </div>
        </div>
      </>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'md'}
        disabled={false}
        title={itemDropdown}
        value={item.id}
        onItemClick={handleChangeOverflowMenuSelect}
        tooltip={null}
        isSelected={!!anchorEl && isOpenedCommitmentVersions && item.id === 'download'}
        leftElem={null}
        leftElemClick={null}
        rightElem={null}
        rightElemClick={null}
      />
    );
  };

  const renderWatchlist = () => {
    return (
      <Watchlist
        type={'submittal'}
        watchers={submittalWatchers || []}
        requestId={selectedRequest?.id}
        disabled={isCreateSubmittal || isDeactivated || isPreviousRevisionDeactivated}
        project_id={active_project_id}
        isPrivate={selectedPrivate}
        linkedGroups={submittalWatchersGroup}
      />
    );
  };

  const renderSingleSelectRequestType = (item: DropdownItemModelExt) => {
    return (
      <>
        <ChipsRequestType type={item.title} isMobile={isMobile} />
      </>
    );
  };

  const renderNavigationArrows = () => {
    const isUpDisabled = listForNavigate?.[0]?.id === selectedRequest?.id;
    const isDownDisabled = listForNavigate?.[listForNavigate.length - 1]?.id === selectedRequest?.id;

    return (
      <>
        <Divider direction={'vertical'} type={'srf-4'} />
        <div className={`${themeClass}_arrows`}>
          <div className={`${themeClass}_arrows_side`}>
            <SystemButton
              type={'chevron-double-up'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isLoadingForNavigate || !selectedRequest || isUpDisabled || isSubmittalsSorted}
              clickHandler={handleNavigate('full-up')}
            />
            <SystemButton
              type={'chevron-up'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isLoadingForNavigate || !selectedRequest || isUpDisabled || isSubmittalsSorted}
              clickHandler={handleNavigate('up')}
            />
          </div>
          <div className={`${themeClass}_arrows_side`}>
            <SystemButton
              type={'chevron-down'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isLoadingForNavigate || !selectedRequest || isDownDisabled || isSubmittalsSorted}
              clickHandler={handleNavigate('down')}
            />
            <SystemButton
              type={'chevron-double-down'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isLoadingForNavigate || !selectedRequest || isDownDisabled || isSubmittalsSorted}
              clickHandler={handleNavigate('full-down')}
            />
          </div>
        </div>
      </>
    );
  };

  const renderLinkButtonContent = () => {
    return (
      <>
        <CardLinkButton
          type={'submittal'}
          size={'md'}
          title={`Submittal ${selectedRequest?.sf_index || ''}`}
          tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Submittal name'}
          clickHandler={handleRevisionsPopover}
        />
      </>
    );
  };

  const renderBreadcrumbs = () => {
    return (
      <>
        <Divider direction={'vertical'} type={'srf-4'} />
        {!isPreview || !isSeparateTab ? (
          <CardBreadcrumbs
            breadcrumbs={breadcrumbs}
            selectedRequest={selectedRequest}
            openRequest={selectRequestHandler}
            isRequestLoading={isCardLoading}
            changeRevision={handleChangeRevision}
            setNfLinkOpenId={null}
            isPcdCardPopUp={false}
            isMobile={isMobile}
            isSidePeek={isDragRequestCard || is1440Resolution}
            isTruncated
            truncateAfterNumber={truncateBreadcrumbsNumber}
          />
        ) : null}
      </>
    );
  };

  const renderCardTypeChip = () => {
    if (!selectedRequest) return null;
    return (
      <div className={`${themeClass}_cardTypes`}>
        <ChipsDataType
          type={selectedRequest.is_output ? 'output' : 'input'}
          size={'md'}
          color={selectedRequest.is_output ? 'green' : 'yellow'}
        />
        {!!selectedRequest.reflect_input && <ChipsDataType type={'input'} size={'md'} color={'yellow'} />}
        {!!selectedRequest.reflect_input && (
          <CustomTooltip className={`${themeClass}_cardTypes_tooltip`} title={'Output submittal reflected as input data'}>
            <TokenIcon customClass={`${themeClass}_cardTypes_icon`} iconName={'information'} size={16} />
          </CustomTooltip>
        )}
      </div>
    );
  };

  return (
    <>
      <ReactResizeDetector targetRef={headerRef} handleHeight skipOnMount refreshMode="throttle" />
      <div className={`${themeClass}`} ref={headerRef}>
        <div className={cn(`${themeClass}_container`)}>
          <div className={`${themeClass}_switcher`}>
            {!is1440Resolution && !isSeparateTab && (
              <>
                {showSmall ? (
                  <ContentSwitcher type={'central-peek'} size={'sm'} clickHandler={handleChangeCardView} />
                ) : (
                  <ContentSwitcher type={'right-peek'} size={'sm'} clickHandler={handleChangeCardView} />
                )}
              </>
            )}
            {!isSeparateTab && !isCreateSubmittal && isSubmittalsModule && renderNavigationArrows()}
            {!!breadcrumbs?.length && renderBreadcrumbs()}
            {isMobile && renderLinkButtonContent()}
          </div>
          <div className={`${themeClass}_action`}>
            <div className={`${themeClass}_selectedFilter`}>
              {selectedRequest?.bic?.length ? (
                <BICorMentionSelect
                  showBasisTitle={!isDragRequestCard && !isMobile}
                  selectedRequest={selectedRequest}
                  type={'submittal'}
                  forOpen={'bic'}
                />
              ) : (
                <OvalButton showTitle={!isDragRequestCard && !isMobile} disabled title={'Unassigned'} type={'bic-unassigned'} />
              )}
            </div>
            <div className={`${themeClass}_selectedFilter`}>
              {selectedRequest?.secondary_courts?.length ? (
                <BICorMentionSelect
                  showBasisTitle={!isDragRequestCard && !isMobile}
                  selectedRequest={selectedRequest}
                  type={'submittal'}
                  forOpen={'mention'}
                  onChangeActiveTab={onChangeActiveTab}
                />
              ) : (
                <OvalButton showTitle={!isDragRequestCard && !isMobile} disabled title={'No mentions'} type={'mention-unassigned'} />
              )}
            </div>
            {isHotListManually && (
              <ActionButton
                type={'hotlisted'}
                clickHandler={null}
                isMobile={isMobile}
                tooltip={'Item became hot due to system rules. You cannot unmark it manually'}
                disabled
              />
            )}
            {isHotList ? (
              <ActionButton
                isMobile={isMobile}
                type={'starred'}
                clickHandler={saveHotlistLoading.loading ? void 0 : handleSetHotList}
                disabled={isDeactivated || isPreviousRevisionDeactivated}
              />
            ) : (
              <ActionButton
                isMobile={isMobile}
                type={'not-starred'}
                clickHandler={saveHotlistLoading.loading || isCreateSubmittal ? void 0 : handleSetHotList}
                disabled={isCreateSubmittal || isDeactivated || isPreviousRevisionDeactivated}
              />
            )}
            <div onClick={e => (isCreateSubmittal ? null : handleMenuDownloadListOpen(e))}>
              <OverflowMenuButton size={'sm'} tooltip={''} disabled={isCreateSubmittal || isDraftedCard} />
            </div>
            <DropdownDownloadList<DropdownItemModelExt>
              isMenuOpen={isMenuDownloadListOpen}
              customClassName={'-submittal'}
              renderItemHeader={null}
              renderItemFooter={null}
              renderMenuItem={renderMenuItem}
              menuItems={filteredOverflowMenuItems}
              size={'md'}
              anchorEl={anchorEl}
              onOpen={null}
              onClose={closeMenuDownloadList}
            />
            {!isSeparateTab && (
              <SystemButton isMobile={isMobile} type={'close'} size={'md'} variant={'transparent'} clickHandler={e => requestHeadClose()} />
            )}
          </div>
          {!isMobile && (
            <div className={`${themeClass}_linkBtn`}>
              {renderLinkButtonContent()}
              <div className={`${themeClass}_statusContainer`}>
                {!showSmall && selectedRequest && (
                  <StatusSubmittal status={selectedRequest?.status} reviewState={selectedRequest?.review_state} />
                )}
                {!isMobile && (
                  <div
                    className={'history_row_created'}
                    ref={refCreatedBy}
                    onMouseEnter={handleOpenAuthor}
                    onMouseLeave={handleCloseAuthor}
                  >
                    {selectedRequest?.date_created && (
                      <div className={'container_dateRow'}>
                        <TokenIcon iconName={'created'} size={16} />
                        <DateRow date={selectedRequest?.date_created} />
                      </div>
                    )}
                    {isOpenCreateBy && (
                      <PopupCreatedBy
                        user={selectedRequest?.created_by}
                        create_date={selectedRequest?.date_created}
                        refCreatedBy={refCreatedBy}
                        forShowSmall={showSmall}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {showSmall && selectedRequest && (
            <div className={`${themeClass}_cardStatus`}>
              <StatusSubmittal status={selectedRequest?.status} reviewState={selectedRequest?.review_state} />
            </div>
          )}
          {(!isMobile || !showSmall) && (
            <div className={`${themeClass}_actionButtons`}>
              {!showSmall && renderCardTypeChip()}
              {!showSmall && (
                <div className={`${themeClass}_selectedFilter`}>
                  <Select<DropdownItemModelExt, null, false>
                    isMulti={false}
                    menuItems={tags}
                    selectHandler={values => handleChangeRequestTypeSelect(values)}
                    selected={selectedTags}
                    menuItemSize={'md'}
                    menuItemTitleRenderer={renderSingleSelectRequestType}
                    renderCustomBasis={isActive => {
                      return (
                        <ChipsRequestType
                          type={selectedTags?.title || 'Select request type'}
                          isPressed={isActive}
                          customIcon={<TokenIcon iconName={'chevron-down'} size={16} />}
                          customIconClass={'selectIcon'}
                          onClickIcon={null}
                          isMobile={isMobile}
                          error={errors?.type_id}
                        />
                      );
                    }}
                    disabled={!isAdmin || isDeactivated || isPreviousRevisionDeactivated}
                  />
                </div>
              )}
              {!showSmall && (
                <div className={`${themeClass}_actionButtons_container`}>
                  <ActionButton
                    type={selectedPrivate ? 'privat' : 'not-privat'}
                    clickHandler={handleSetPrivat}
                    disabled={!isAdmin || isDeactivated || isPreviousRevisionDeactivated}
                  />
                  {!isMobile && renderWatchlist()}
                  <ActionButton type={is_procore_sync_needed ? 'sync' : 'not-sync'} clickHandler={handleOpenProcoreConfirmPopUp} />
                </div>
              )}
              {showSmall && !isMobile && (
                <div className={`${themeClass}_actionBtn`}>
                  <ActionButton
                    type={selectedPrivate ? 'privat' : 'not-privat'}
                    clickHandler={handleSetPrivat}
                    disabled={!isAdmin || isDeactivated || isPreviousRevisionDeactivated}
                  />
                  {!isMobile && renderWatchlist()}
                  <ActionButton type={is_procore_sync_needed ? 'sync' : 'not-sync'} clickHandler={handleOpenProcoreConfirmPopUp} />
                </div>
              )}
            </div>
          )}
          {showSmall && (
            <div className={`${themeClass}_request`}>
              {showSmall && renderCardTypeChip()}
              <Select<DropdownItemModelExt, null, false>
                isMulti={false}
                menuItems={tags}
                selectHandler={values => handleChangeRequestTypeSelect(values)}
                selected={selectedTags}
                menuItemSize={'md'}
                menuItemTitleRenderer={renderSingleSelectRequestType}
                renderCustomBasis={isActive => {
                  return (
                    <ChipsRequestType
                      type={selectedTags?.title || 'Select request type'}
                      isPressed={isActive}
                      customIcon={<TokenIcon iconName={'chevron-down'} size={16} />}
                      customIconClass={'selectIcon'}
                      onClickIcon={null}
                      isMobile={isMobile}
                      error={errors?.type_id}
                    />
                  );
                }}
                disabled={!isAdmin || isDeactivated || isPreviousRevisionDeactivated}
              />
            </div>
          )}
        </div>
        <DialogPopUp
          isCard
          open={isProcoreConfirmPopUpOpen}
          onClose={handleCloseProcoreConfirmPopUp}
          isOverlay={false}
          paperMaxWidth={'661px'}
          title={'This Submittal will become synchronized with Procore'}
          dividedHeader
          customSecondaryType={'tertiary'}
          tertiaryText={'Cancel'}
          handleOnTertiary={handleCloseProcoreConfirmPopUp}
          primaryText={'Okay'}
          handleOnPrimary={handleChangeProcoreSyncStatus}
          renderModalContent={() => <div className={`${dialogClass}_content_modalText`}>You’ll be able to turn synchronization off.</div>}
        />
        {isOpenedRevisions && (
          <Popover
            id={'revisions'}
            open={!!isOpenedRevisions}
            anchorEl={isOpenedRevisions}
            onClose={handleRevisionsPopover}
            classes={{
              paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClass}_revisionsPaper`, {
                ['-mobile']: isMobile,
                ['-download']: anchorEl,
              }),
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: anchorEl ? 'right' : 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: anchorEl ? 'right' : 'left',
            }}
          >
            <RevisionsList
              type={'submittal'}
              selectedRequest={selectedRequest}
              changeRevision={handleChangeRevision}
              forOpen={isMenuDownloadListOpen ? 'download' : 'normal'}
            />
          </Popover>
        )}
        <div className={`${themeClass}_line_submittal`}></div>
      </div>
    </>
  );
};

export default React.memo(Header);
