import React, { useEffect, useRef, useState } from 'react';
import CommentsView from './CommentsView';
import {
  addBallInCourt,
  createComments,
  deleteComments,
  editBallInCourt,
  getComments,
  getFeed,
  replyComments,
  unAssigmentUser,
  updateComments,
} from '../../../store/comments/commentsThunk';
import { commonSlice } from '../../../store/common/commonReducer';
import { commentSlice, isCommentHelper } from '../../../store/comments/commentsReducer';
import { isEmptyObject } from '../../../helpers/commonHelpers';
import { pdfTronSupportedTypes } from '../../../constants/pdfTronSupportedTypes';
import { filesMultipleUpload } from '../../../store/globalSettings/settingsThunk';
import { responseSave } from '../../../store/request/requestLogic(HOLD)';
import { ICommentsContainerProps } from './CommentsContainer.props';
import { useAppSelector } from '../../../store/configure/configureStore';
import { useDispatch } from 'react-redux';
import { filesUploadClean } from '../../../store/globalSettings/settingsActions';
import { ICommentData, IFeedData } from '../../../store/comments/commentReducer.model';
import { IHistoryData } from '../../../store/history/historyReducer.model';
import { useCommentStatus } from './hooks';

const CommentsContainer: React.FC<ICommentsContainerProps> = ({
  type,
  selectedRequest,
  getPCDMain,
  pcdFiltersData,
  onChangeActiveTab,
  isDragCard,
  handleShowConfirmDialog,
  activeTab,
  bodyContainerRef,
  //   comments,
}): JSX.Element => {
  const dispatch = useDispatch();

  const {
    clearComments,
    handleDeleteResponseCommentId,
    handleSaveResponseCommentId,
    handleGoToNavigate,
    handleResetCreateStatus,
    setBicIndicatorScrollToCommentId,
    removeIsNewStatus,
    setIsNavigateFromSandbox,
  } = commentSlice.actions;
  const { openPdftron } = commonSlice.actions;

  const feedData = useAppSelector(state => state.commentReducer.feed.feedData);
  const importantHistoryData = useAppSelector(state => state.commentReducer.feed.important_history);
  const responseCommentsIds = useAppSelector(state => state.commentReducer.responseCommentsIds);
  const canComment = useAppSelector(state => state.commentReducer.canComment);
  const isNavigateToDiscussion = useAppSelector(state => state.commentReducer.isNavigateToDiscussion);

  //TODO need focus this commentstatus (delete bic ?)
  const createCommentStatus = useAppSelector(state => state.commentReducer.createCommentStatus);
  const deleteCommentStatus = useAppSelector(state => state.commentReducer.deleteCommentStatus);
  const updateCommentStatus = useAppSelector(state => state.commentReducer.updateCommentStatus);
  const getFeedStatus = useAppSelector(state => state.commentReducer.getFeedStatus);
  const addBallInCourtStatus = useAppSelector(state => state.commentReducer.addBallInCourtStatus);
  const editBallInCourtStatus = useAppSelector(state => state.commentReducer.editBallInCourtStatus);
  const bicIndicatorScrollToCommentId = useAppSelector(state => state.commentReducer.bicIndicatorScrollToCommentId);
  const filesData = useAppSelector(state => state.settingsReducer.files);
  const fileLoading = useAppSelector(state => state.settingsReducer.fileLoading);

  const userInfo = useAppSelector(state => state.userReducer.userInfo);

  const projectParties = useAppSelector(state => state.projectReducer.projectInfo);

  const [files, setFiles] = useState<any[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<any[]>([]);
  const [text, setText] = useState<string>('');
  const [mentions, setMentions] = useState<any[]>([]);
  const [isReplyComment, setIsReplyComment] = useState<any>(null);
  const [isReplyBic, setIsReplyBic] = useState<any>(null);
  const [isEditBallInCourt, setIsEditBallInCourt] = useState<any>(null);
  const [isOpenCommentForm, setIsOpenCommentForm] = useState<boolean>(false);
  const [isEditComment, setIsEditComment] = useState<{ id: number; comment: ICommentData; isEditParent: number }>(null);
  //TODO need del
  // const [comments, setComments] = useState<{ comments: ICommentData[]; ballInCort?: any }>({ comments: [] });
  const [feed, setFeed] = useState<IFeedData>([]);
  const [animationBtn, setAnimationBtn] = useState(null);
  const [commentFormType, setCommentFormType] = useState('');
  const [sorting, setSorting] = useState('asc');
  const [whatShow, setWhatShow] = useState('');
  const [responseComment, setResponseComment] = useState({});
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState<any>(null);

  const [filterItemId, setFilterItemId] = useState<string>('');
  const [isImportantFilter, setIsImportantFilter] = useState<boolean>(true);
  const [isManualFilter, setIsManualFilter] = useState(false);

  const [isOpenFilterSelect, setIsOpenFilterSelect] = useState<boolean>(false);

  const viewComponent = useRef(null);
  const loadFeedPromise = useRef(null);

  useEffect(() => {
    const whatShowSession = sessionStorage.getItem('commentsContainer-whatShow');
    const isImportantFilterSession = sessionStorage.getItem('commentsContainer-isImportant');
    if (whatShowSession) {
      setWhatShow(whatShowSession);
    }
    if (isImportantFilterSession) {
      setIsImportantFilter(isImportantFilterSession === 'true');
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('commentsContainer-whatShow', whatShow);
    sessionStorage.setItem('commentsContainer-isImportant', String(isImportantFilter));
  }, [whatShow, isImportantFilter]);

  useEffect(() => {
    if (selectedRequest?.id) {
      const owner_id = selectedRequest.parent_id || selectedRequest.id;
      loadFeedPromise.current?.abort();
      loadFeedPromise.current = dispatch(getFeed({ owner_id, type }));
    } else {
      setFeed([]);
    }

    return () => {
      loadFeedPromise.current?.abort();
      if (selectedRequest?.id) {
        //TODO need ?
        dispatch(clearComments());
      }
    };
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (filesData) {
      //
      let filesCopy = [...(files || [])];
      if (filesData?.comment && !filesData?.comment?.status) {
        filesCopy = filesCopy.filter(f => f.id);
      }
      filesCopy.map(file => {
        const isLoadedFile = Object.values(filesData?.comment?.data ? filesData.comment.data : {}).find(f => {
          //@ts-ignore
          return f.original_name === file.name;
        });
        if (isLoadedFile) {
          file.loaded = true;
        }
      });
      setTimeout(() => {
        setFiles(filesCopy);
      });
    }

    if (feedData) {
      //TODO need del
      // setComments(commentsStore);
      // console.log('filterItemId ниже сет всей feedData', filterItemId);
      handleFiltersFeed();
    }
  }, [filesData, feedData, isImportantFilter, whatShow]);

  const handleOpenCommentForm = (type: string): void => {
    dispatch(filesUploadClean({ type: 'comment' }));

    if (isOpenCommentForm && text) {
      setText('');
    }

    setIsOpenCommentForm(!isOpenCommentForm);
    setIsEditComment(null);
    setIsReplyComment(null);
    setIsReplyBic(null);
    setIsEditBallInCourt(null);
    setCommentFormType(commentFormType ? '' : type);
    setFiles([]);
  };
  const handleClearCommentForm = (): void => {
    dispatch(filesUploadClean({ type: 'comment' }));
    setIsEditComment(null);
    setIsReplyComment(null);
    setIsReplyBic(null);
    setIsEditBallInCourt(null);
    setCommentFormType(commentFormType ? '' : 'comment');
    setFiles([]);
  };

  const closeCommentForm = (): void => {
    console.log('closeCommentForm');
    dispatch(filesUploadClean({ type: 'comment' }));

    setText('');
    setIsOpenCommentForm(false);
    setIsEditComment(null);
    setIsReplyComment(null);
    setIsReplyBic(null);
    setIsEditBallInCourt(null);
    setFiles([]);
    setFilesToDelete([]);
  };

  const handleFile = async filesCome => {
    const newFiles = Array.from(filesCome).map(file => {
      return file;
    });

    dispatch(filesMultipleUpload({ files: newFiles, source: 'comment' }));
    setFiles([...files, ...filesCome]);
  };

  const delFile = (filename: string): void => {
    setFiles(files.filter(item => item.name !== filename));
  };

  const delSavedFile = (file_id: number): void => {
    const fileData = { file_id, action: 'delete' };
    const _filesToDelete = filesToDelete ? [...filesToDelete, fileData] : [fileData];

    setFiles(files.filter(item => item.id !== file_id));
    setFilesToDelete(_filesToDelete);
  };

  const handleChange = (text: string): void => {
    setText(text);
  };

  const handleConfirmAddMentions = (userIds: number[], companyIds: number[], onAllow: () => void, onCancel?): void => {
    handleShowConfirmDialog({
      title: 'Request private request change?',
      message: 'NF will cease to be private and will be available to others.',
      userIds,
      companyIds,
      onAllow,
      onCancel,
      needUpdateState: true,
    });
  };

  const mentionsAdapter = (mentions): any[] => (mentions ? mentions.map(mention => mention.email) : []);

  const sendComment = () => {
    const textCheck = !text || text.split(' ').join('') === '<p></p>' || text === '<p><br></p>';
    const filesCheck = !files || isEmptyObject(files);

    if (filesCheck && textCheck) {
      return;
    }

    let sendFiles;

    sendFiles =
      filesData.comment.data &&
      Object.keys(filesData.comment.data).map(key => {
        if (filesData.comment.data[key].isNew) {
          const isNewFileExists = files.find(file => file.name === filesData.comment.data[key].original_name);

          if (isNewFileExists) {
            return filesData.comment.data[key];
          }
        } else {
          return filesData.comment.data[key];
        }
      });

    if (filesToDelete.length) {
      sendFiles = sendFiles ? [...sendFiles, ...filesToDelete] : filesToDelete;
    }
    const owner_id = type === 'deliverable' ? selectedRequest.parent_id || selectedRequest.id : selectedRequest.id;

    const formData: {
      owner_id: number;
      user_id: number;
      text: string;
      files: any;
      mentions: any;
      type: 'request' | 'deliverable' | 'submittal';
      comment_id?: number;
      parent_id?: number;
    } = {
      owner_id,
      user_id: userInfo.id,
      text,
      files: Object.assign({}, sendFiles),
      mentions: mentionsAdapter(mentions),
      type,
    };

    const newMentionIds = mentions.map(mention => parseInt(mention.id, 10));

    const onAllow = () => {
      if (isEditComment) {
        formData.comment_id = isEditComment.comment.id;
        dispatch(updateComments(formData));
      } else if (isReplyComment?.comment) {
        formData.parent_id = isReplyComment?.isReplyParent || isReplyComment?.comment?.id;
        dispatch(replyComments(formData));
      } else {
        dispatch(
          createComments({
            projectParam: formData,
            callback: () => {
              if (filterItemId === 'history') {
                handleFiltersFeed();
                setFilterItemId('');
              }
              scrollToNewItem();
              getPCDMain && getPCDMain(pcdFiltersData, selectedRequest?.building?.id);
            },
          }),
        );
      }
    };

    if (type === 'request') {
      handleConfirmAddMentions(newMentionIds, [], onAllow);
    } else {
      onAllow();
    }
    closeCommentForm();
  };

  const handleSendBallInCourt = (user_id, party_id, bic_id) => {
    if (!text) {
      return;
    }

    const formData: {
      message: string;
      entity_id?: number;
      user_id?: number;
      party_id?: number;
      ball_in_court_id?: number;
    } = {
      message: text,
    };

    if (!bic_id) {
      formData.entity_id = selectedRequest.id;

      if (user_id) {
        formData.user_id = user_id;
      }

      if (party_id && !user_id) {
        formData.party_id = party_id;
      }

      const newBallInCourtUserIds = [user_id].filter(item => !!item);
      const newBallInCourtPartyIds = [party_id].filter(item => !!item);
      const onAllow = () => {
        dispatch(
          addBallInCourt({
            projectParam: formData,
            callback: () => {
              scrollToNewItem();
            },
          }),
        );
      };

      handleConfirmAddMentions(newBallInCourtUserIds, newBallInCourtPartyIds, onAllow);
    } else {
      formData.ball_in_court_id = bic_id;
      dispatch(editBallInCourt({ data: formData }));
    }
  };

  const handleEditComment = (comment: ICommentData, isSubComment) => {
    let editedComment;

    if (!isSubComment) {
      editedComment = feed.find(item => item.id === comment.id);
    } else {
      const parent = feed.find(item => item.id === comment.parent_id) as ICommentData;
      editedComment = parent.sub_comments.find(item => item.id === comment.id);
    }

    setIsEditComment({ id: comment.id, comment, isEditParent: comment.parent_id });
    setText(comment.text);
    setIsOpenCommentForm(true);
    setIsReplyComment(null);
    setIsReplyBic(null);
    setIsEditBallInCourt(null);
    setFiles(editedComment?.files || []);
  };

  const handleReplyComment = (comment: ICommentData, isSubComment) => {
    dispatch(filesUploadClean({ type: 'comment' }));

    setIsReplyComment({ id: comment.id, comment, isReplyParent: comment.parent_id, isSubComment });
    setIsOpenCommentForm(true);
    setIsEditComment(null);
    setIsReplyBic(null);
    setIsEditBallInCourt(null);
    setFiles([]);
  };

  const handleDeleteComment = (comment: ICommentData) => {
    setIsOpenRemovePopup(comment);
  };

  const closeRemoveComment = () => {
    setIsOpenRemovePopup(null);
  };

  const acceptRemoveComment = () => {
    const owner_id = type === 'deliverable' ? selectedRequest.parent_id || selectedRequest.id : selectedRequest.id;

    dispatch(deleteComments({ owner_id, comment_id: isOpenRemovePopup.id, type }));
    setAnimationBtn('commentDelBtn');
    dispatch(handleDeleteResponseCommentId(isOpenRemovePopup.id));
  };

  const openPrevFile = file => () => {
    const isPossibleOpen = pdfTronSupportedTypes.find(f => f === file.ext.toUpperCase());
    if (!isPossibleOpen) {
      return;
    }

    dispatch(
      openPdftron({
        file,
        selectedRequest,
        isAccess: false,
        isPCD: type === 'deliverable',
        isComment: true,
        isRequest: type === 'request',
        isSubmittal: type === 'submittal',
        isDeliverable: false,
      }),
    );
  };

  const changeMentions = mentions => {
    setMentions(mentions);
  };

  const handleProcoreFileLink = e => {
    const { target } = e;

    if (target.classList.value === 'link_to_pdftron') {
      const isPossibleOpen = pdfTronSupportedTypes.find(f => target.innerText.split('.').find(t => f === t.toUpperCase()));

      if (!isPossibleOpen) {
        window.open(target.href, '_blank');
        return;
      }

      const file = {
        file: target.href,
      };

      dispatch(
        openPdftron({
          file,
          selectedRequest,
          isAccess: false,
          isPCD: type === 'deliverable',
          isComment: true,
          isRequest: type === 'request',
          isSubmittal: type === 'submittal',
          isDeliverable: false,
        }),
      );
    }
  };

  const setDisplayFilter = filter => {
    if (filter !== whatShow) {
      setWhatShow(filter);
    } else if (filter === whatShow) {
      setWhatShow('');
    }
  };

  const changeSort = () => {
    if (sorting === 'asc') {
      setSorting('desc');
    } else {
      setSorting('asc');
    }
  };

  const handleReplyBIC = bic => {
    dispatch(filesUploadClean({ type: 'comment' }));

    setIsReplyBic({ bic_id: bic.bic_id, bic, owner: bic.author });
    setIsOpenCommentForm(false);
    setIsEditComment(null);
    setIsReplyComment(null);
    setIsEditBallInCourt(null);
    setFiles([]);
  };

  const handleEditBallInCourt = bic => {
    dispatch(filesUploadClean({ type: 'comment' }));

    setIsEditBallInCourt({ bic_id: bic.bic_id, bic, owner_id: bic.owner_id });
    setIsOpenCommentForm(false);
    setIsEditComment(null);
    setIsReplyComment(null);
    setIsReplyBic(null);
    setFiles([]);
  };

  const scrollToNewItem = () => {
    viewComponent?.current?.targetComment?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const handleUseResponseComment = (comment_id?: number) => {
    if (typeof comment_id === 'number') {
      const commentRespData = { comment_id, project_id: selectedRequest.project_id, submit: 0 };
      const owner_id =
        type === 'deliverable' ? selectedRequest.parent_id || selectedRequest.id : selectedRequest.parent_id || selectedRequest.id;

      if (responseCommentsIds.includes(comment_id)) {
        dispatch(handleSaveResponseCommentId(comment_id));

        //dispatch на снятие галки
        dispatch(updateComments({ type: 'request', comment_id, is_response: 0 }));
      } else {
        setResponseComment(commentRespData);

        dispatch(handleSaveResponseCommentId(comment_id));

        dispatch(
          responseSave({
            bodyParams: commentRespData,
            requestId: selectedRequest.id,
          }),
        );
      }
    } else {
      setResponseComment({});
    }
  };

  const filterFeedEmptyHelper = (item: ICommentData | IHistoryData) => {
    return !isCommentHelper(item) ? importantHistoryData.includes(item.alias) : true;
  };

  const filterFeedHelper = (item: ICommentData | IHistoryData) => {
    return item.type === whatShow;
  };

  const filterFeedImportantHelper = (item: ICommentData | IHistoryData) => {
    return item.type === whatShow ? (!isCommentHelper(item) ? importantHistoryData.includes(item.alias) : true) : false;
  };

  const handleFiltersFeed = () => {
    const copyFeed = [...feedData];
    setIsManualFilter(false);

    if (whatShow) {
      setFeed(copyFeed.filter(isImportantFilter ? filterFeedImportantHelper : filterFeedHelper));
    } else {
      setFeed(isImportantFilter ? copyFeed.filter(filterFeedEmptyHelper) : copyFeed);
    }
  };

  const handleUnAssigmentUser = (type: 'deliverable' | 'request', comment_id: number, userIds: boolean | number[]): void => {
    const owner_id =
      type === 'deliverable' ? selectedRequest.parent_id || selectedRequest.id : selectedRequest.parent_id || selectedRequest.id;
    const callback = () => {
      dispatch(getFeed({ owner_id, type }));
    };
    dispatch(unAssigmentUser({ type, comment_id, owner_id, user_ids: userIds, callback }));
  };

  useCommentStatus(createCommentStatus, closeCommentForm);
  useCommentStatus(deleteCommentStatus, closeCommentForm);
  useCommentStatus(updateCommentStatus, closeCommentForm);
  useCommentStatus(addBallInCourtStatus, closeCommentForm);
  useCommentStatus(editBallInCourtStatus, closeCommentForm);

  const isAdmin =
    selectedRequest && type === 'submittal'
      ? selectedRequest.status.title === 'Deactivated'
      : selectedRequest?.is_deactivated
      ? false
      : userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '4').length;

  return (
    <>
      <CommentsView
        handleOpenCommentForm={handleOpenCommentForm}
        handleClearCommentForm={handleClearCommentForm}
        isOpenCommentForm={isOpenCommentForm}
        closeCommentForm={closeCommentForm}
        getFeedStatus={getFeedStatus}
        createCommentStatus={createCommentStatus}
        updateCommentStatus={updateCommentStatus}
        deleteCommentStatus={deleteCommentStatus}
        addBallInCourtStatus={addBallInCourtStatus}
        editBallInCourtStatus={editBallInCourtStatus}
        handleResetCreateStatus={handleResetCreateStatus}
        text={text}
        files={files}
        handleFile={handleFile}
        delFile={delFile}
        delSavedFile={delSavedFile}
        handleChange={handleChange}
        sendComment={sendComment}
        feed={feed}
        handleEditComment={handleEditComment}
        handleDeleteComment={handleDeleteComment}
        isEditComment={isEditComment}
        isOpenRemovePopup={isOpenRemovePopup}
        closeRemoveComment={closeRemoveComment}
        acceptRemoveComment={acceptRemoveComment}
        userInfo={userInfo}
        isAdmin={isAdmin}
        openPrevFile={openPrevFile}
        canComment={canComment}
        selectedRequest={selectedRequest}
        handleReplyComment={handleReplyComment}
        isReplyComment={isReplyComment}
        changeMentions={changeMentions}
        animationBtn={animationBtn}
        handleProcoreFileLink={handleProcoreFileLink}
        fileLoading={fileLoading}
        commentFormType={commentFormType}
        sendBallInCourt={handleSendBallInCourt}
        sorting={sorting}
        whatShow={whatShow}
        setDisplayFilter={setDisplayFilter}
        changeSort={changeSort}
        isReplyBic={isReplyBic}
        handleReplyBIC={handleReplyBIC}
        isEditBallInCourt={isEditBallInCourt}
        handleEditBallInCourt={handleEditBallInCourt}
        ref={viewComponent}
        type={type}
        responseComment={responseComment}
        handleUseResponseComment={handleUseResponseComment}
        onChangeActiveTab={onChangeActiveTab}
        projectParties={projectParties}
        isNavigateToDiscussion={isNavigateToDiscussion}
        responseCommentsIds={responseCommentsIds}
        handleGoToNavigate={handleGoToNavigate}
        isDragCard={isDragCard}
        handleFiltersFeed={handleFiltersFeed}
        activeTab={activeTab}
        bicIndicatorScrollToCommentId={bicIndicatorScrollToCommentId}
        setBicIndicatorScrollToCommentId={setBicIndicatorScrollToCommentId}
        handleUnAssigmentUser={handleUnAssigmentUser}
        filterItemId={filterItemId}
        setFilterItemId={setFilterItemId}
        removeIsNewStatus={removeIsNewStatus}
        setIsManualFilter={setIsManualFilter}
        isManualFilter={isManualFilter}
        isOpenFilterSelect={isOpenFilterSelect}
        setIsOpenFilterSelect={setIsOpenFilterSelect}
        isImportantFilter={isImportantFilter}
        setIsImportantFilter={setIsImportantFilter}
        bodyContainerRef={bodyContainerRef}
        setIsNavigateFromSandbox={setIsNavigateFromSandbox}
      />
    </>
  );
};

export default React.memo(CommentsContainer);
