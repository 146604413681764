import React from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import RedmineIntegrationItem from '../../../RedmineIntegrationItem/RedmineIntegrationItem';
import ApplicationCard from '../../../ApplicationCard/ApplicationCard';

import './Step5ViewStyles.scss';

const Step5View: React.FC = () => {
  const themeClass = useGetThemeClass('b-step5View');

  return (
    <div className={themeClass}>
      <div className={`${themeClass}__row`}>
        <ApplicationCard title={'Procore'} logo={'procore-logo'} isConnected={false} disabled={true} />
        <ApplicationCard title={'Autodesk BIM 360'} logo={'autodesk-bim-logo'} isConnected={false} disabled={true} />
        <ApplicationCard title={'Autodesk Revit'} logo={'autodesk-revit-logo'} isConnected={false} disabled={true} />
        <RedmineIntegrationItem />
      </div>
    </div>
  );
};
export default Step5View;
