import React, { ChangeEvent, MouseEvent, useMemo } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TextInputNew from '../../../TextInputNew/TextInputNew';
import cn from 'classnames';
import CustomButton from '../../../ButtonComponents/CustomButton/CustomButton';
import { Breadcrumbs } from '../../../Breadcrumbs';
import Divider from '../../../Divider/Divider';
import ToggleSwitch from '../../../ToggleSwitch/ToggleSwitch';

import './DropdownHeaderStyles.scss';

interface DropdownHeaderProps<T> {
  customClassName?: string;
  additionalText?: string;
  onClearAll?: () => void;
  onSelectAll?: () => void;
  searchValue: string;
  onChangeSearch: (searchValue: string) => void;
  breadcrumbs?: T[] | null;
  breadcrumbOnClick?: (breadcrumb: T) => void;
  selectedBreadcrumb?: T;
  isMobile?: boolean;
  isTruncatedBreadcrumbs?: boolean;
  isConfiguration?: boolean;
  isResetBtn?: boolean;
  onResetAll?: () => void;
  isSwitchAndButton?: boolean;
  isOnSwitchAndFilter?: boolean;
  handleSwitchAndFilter?: () => void;
  disabledSwitchAndButton?: boolean;
}

function DropdownHeader<T extends { id: string | number }>({
  customClassName,
  searchValue,
  onChangeSearch,
  onClearAll,
  onSelectAll,
  additionalText,
  breadcrumbs,
  breadcrumbOnClick,
  selectedBreadcrumb,
  isMobile,
  isTruncatedBreadcrumbs,
  isConfiguration,
  isResetBtn,
  onResetAll,
  isSwitchAndButton,
  isOnSwitchAndFilter,
  handleSwitchAndFilter,
  disabledSwitchAndButton,
}: DropdownHeaderProps<T>) {
  const themeClass = useGetThemeClass('b-dropdownHeader');

  const onChangeInput = (value: string) => {
    onChangeSearch && onChangeSearch(value);
  };

  const selectionButtonsChange = (e: MouseEvent<HTMLDivElement, MouseEvent>, type: 'clear' | 'select' | 'reset') => {
    e.stopPropagation();

    const handler = type === 'select' ? onSelectAll : type === 'clear' ? onClearAll : onResetAll;

    handler && handler();
  };

  const showDivider = useMemo(() => {
    return onChangeSearch || additionalText || breadcrumbs?.length || onSelectAll || onClearAll;
  }, [onChangeSearch, additionalText, breadcrumbs, onSelectAll, onClearAll]);

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          [`${customClassName}`]: customClassName,
        })}
      >
        {onChangeSearch ? (
          <TextInputNew
            placeholder={'Search'}
            onChange={onChangeInput}
            value={searchValue}
            isSearch
            type={'on-bgd'}
            size={'sm'}
            isMobile={isMobile}
            isConfiguration={isConfiguration}
            isResetBtn={isResetBtn}
          />
        ) : null}
        {additionalText && <div className={cn(`${themeClass}_additionalText`, { ['-mobile']: isMobile })}>{additionalText}</div>}
        {isSwitchAndButton ? (
          <div className={`${themeClass}__themeAndSwitcher`}>
            <div className={`${themeClass}__andSwitcher`}>
              <span className={cn(`${themeClass}__andSwitcherText`, { ['-active']: !isOnSwitchAndFilter })}>OR</span>
              <ToggleSwitch
                isSelectedToggle={isOnSwitchAndFilter}
                handleSelect={handleSwitchAndFilter}
                disabled={disabledSwitchAndButton}
                type={'switcher'}
              />
              <span className={cn(`${themeClass}__andSwitcherText`, { ['-active']: isOnSwitchAndFilter })}>AND</span>
            </div>
          </div>
        ) : null}
        {!!breadcrumbs?.length && (
          <Breadcrumbs<T>
            breadcrumbs={breadcrumbs}
            breadcrumbOnClick={breadcrumbOnClick}
            selectedBreadcrumb={selectedBreadcrumb}
            isMobile={isMobile}
            isTruncated={isTruncatedBreadcrumbs}
          />
        )}
        {onSelectAll || onClearAll || onResetAll ? (
          <div
            className={cn(`${themeClass}_selectionButtons`, {
              ['singleButtonClearAll']: !onSelectAll,
            })}
          >
            {onSelectAll ? (
              <div className={`${themeClass}_selectionButtons_select`}>
                <CustomButton
                  title={'Select All'}
                  clickHandler={e => selectionButtonsChange(e, 'select')}
                  type={'text-plain'}
                  size={isMobile ? 'lg' : 'xs'}
                  isMobile={isMobile}
                />
              </div>
            ) : null}
            {onClearAll ? (
              <div className={`${themeClass}_selectionButtons_clear`}>
                <CustomButton
                  title={'Clear All'}
                  clickHandler={e => selectionButtonsChange(e, 'clear')}
                  type={'text-plain'}
                  size={isMobile ? 'lg' : 'xs'}
                  isMobile={isMobile}
                />
              </div>
            ) : null}
            {onResetAll ? (
              <div className={`${themeClass}_selectionButtons_reset`}>
                <CustomButton
                  title={'Reset All'}
                  clickHandler={e => selectionButtonsChange(e, 'reset')}
                  type={'text-plain'}
                  size={isMobile ? 'lg' : 'xs'}
                  isMobile={isMobile}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {showDivider ? <Divider direction={'horizontal'} type={'srf-4'} /> : null}
      </div>
    </>
  );
}

export default DropdownHeader;
