import React, { Suspense, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configure/configureStore';
import { supportSlice } from '../store/support/supportSlice';
import { isLoggedIn } from '../service/auth';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ForgotPassword, InvitePage, NonAssignedUser, NonLoginPage, PlugView, RestorePassword, Unsubscribe } from '../components/pages';
import { UnauthorizedRoutes, ScreensRoutes, AuthorizedRoutes } from './routes';
import useRouter from '../hooks/useRouter/useRouter';
import { AuthService } from '../components/pages/AuthService';
import { DOMAIN_SELF_URL } from '../service/links';
import { errorsSlice } from '../store/errors/errorsSlice';
import Layout from './Layout/Layout';
import AuthorizedRouter from './AuthorizedRouter/AuthorizedRouter';

import './RouterStyles.scss';

const { handleHelpMenu, handleHelpSubMenu } = supportSlice.actions;
const { setShowErrorData } = errorsSlice.actions;

export const roles = {
  ADMIN: '1',
  USER: '2',
  COMPANY_MANEGER: '3',
  SUPER_ADMIN: '4',
  WORKFLOW_MANEGER: '5',
};

const Router = () => {
  const dispatch = useAppDispatch();
  const { urlProject, navigate, location } = useRouter();

  // User slice
  const user = useAppSelector(state => state.userReducer);
  const userIPv4 = useAppSelector(state => state.userReducer.userIPv4);

  // Settings slice
  const onlyAuth = useAppSelector(state => state.settingsReducer.onlyAuth);
  const kpiFrame = useAppSelector(state => state.settingsReducer.kpiFrame);

  // Change this value to true for enable plug view
  const isPlugView = false;

  const isIdeusVpn = useMemo(() => {
    return userIPv4 !== '174.138.41.170';
  }, [userIPv4]);

  const isPostLoginPage = useMemo(() => {
    return (
      urlProject[0] === AuthorizedRoutes.changeProject ||
      urlProject[0] === AuthorizedRoutes.changeCompany ||
      urlProject[0] === AuthorizedRoutes.unsubscribe
    );
  }, [urlProject]);

  const onHelpMenu = () => {
    dispatch(handleHelpMenu());
  };

  const onHelpSubMenu = () => {
    dispatch(handleHelpSubMenu());
  };

  // ________________PLUG VIEW FOR RELEASES________________
  if (isPlugView && isIdeusVpn) {
    return (
      <div className="b-routerContainer">
        <Suspense fallback={<div>Load...</div>}>
          <Routes>
            <Route path="/" element={<PlugView />} />
            <Route path="*" element={<Navigate to={'/'} />} />
          </Routes>
        </Suspense>
      </div>
    );
  }

  // ________________AUTH SERVICE________________ Todo: ask what is it
  if (isLoggedIn() && onlyAuth) {
    return <AuthService />;
  }

  // ________________KPI________________
  if (isLoggedIn() && kpiFrame && kpiFrame === 'company') {
    return <NonLoginPage />;
  }

  // ________________AUTHORIZED WITHOUT PROJECTS________________
  if (isLoggedIn() && !user?.userInfo?.projects?.length && !user.loading) {
    return (
      <Layout isUserWithProjects={false} isPostLoginPage={isPostLoginPage}>
        <NonAssignedUser handleHelpMenu={onHelpMenu} handleHelpSubMenu={onHelpSubMenu} />
      </Layout>
    );
  }

  // ________________CHECK FOR CORRECT URL________________
  if (urlProject && urlProject[1] && !Object.values(ScreensRoutes).includes(urlProject[1]) && urlProject[0] !== 'invite') {
    dispatch(setShowErrorData({ isPageNotFound: true, pageNotFoundURL: `${DOMAIN_SELF_URL}/${urlProject[0]}/${urlProject[1]}` }));
  }

  // ________________AUTHORIZED________________
  if (isLoggedIn()) {
    if (urlProject[0]) {
      if (location.search.includes('tab=linked')) {
        const newSearch = location.search.replace('tab=linked', 'tab=related-items');
        navigate(location.pathname, { state: { search: newSearch } });
      }
    } else {
      navigate(AuthorizedRoutes.changeCompany);
    }

    return (
      <Layout isUserWithProjects={true} isPostLoginPage={isPostLoginPage}>
        <AuthorizedRouter />
      </Layout>
    );
  }

  // ________________NOT AUTHORIZED________________
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path={'/'}>
          <Route index element={<NonLoginPage />} />
          <Route path={UnauthorizedRoutes.unsubscribe} element={<Unsubscribe />} />
          <Route path={UnauthorizedRoutes.invite} element={<InvitePage />} />
          <Route path={UnauthorizedRoutes.forgotPassword} element={<ForgotPassword />} />
          <Route path={UnauthorizedRoutes.restorePassword} element={<RestorePassword />} />
          <Route path={'*'} element={<NonLoginPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
