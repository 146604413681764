import React, { Component } from 'react';
import { FileModel } from '../../../../../models';
import PhasePlanHelper from '../../../../../helpers/PhasePlanHelper';
import { CustomButton } from '../../../../controls/ButtonComponents';
import { handleGetThemeClass } from '../../../../../helpers/designTokens';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import ChipsRequestType from '../../../../controls/Chips/ChipsRequestType/ChipsRequestType';
import ChipsLOD from '../../../../controls/Chips/ChipsLOD/ChipsLOD';
import { DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import FilterSelectBasis from '../../../../controls/Dropdowns/CustomParts/FilterSelectBasis/FilterSelectBasis';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import cn from 'classnames';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';

import '../../PrevFilePopoverStyles.scss';

interface IProps {
  selectedPrevFile: FileModel;
  selectedRequest: any;
  handleFinalDelivable?: (id: number, is_final: boolean) => void;
  isAccess: boolean;
  isPCD?: boolean;
  isComment?: boolean;
  isReview?: boolean;
  isRequest?: boolean;
  isSubmittal?: boolean;
  isDeliverable?: boolean;
  width: number;
  openPromptStatus: (promptTitle: string, promptMessage: string, promptName: string) => void;
  promptName: string;
  is_promptBtnOk: boolean;
  onClose: () => void;
  isLoading: boolean;
  changeFile: (file: FileModel) => void;
  handleUseFileAsResponse: () => void;
  fileLoading: boolean;
  isAccessToUseFileAsResponse?: boolean;
  isOpenPopup: boolean;
  closePopup: () => void;
  theme: string;
}

interface IState {
  is_final: boolean;
  checkBoxFileId: number | null;
}

class PrevFileHeader extends Component<IProps, IState> {
  state = {
    is_final: this.props.selectedPrevFile.is_final,
    checkBoxFileId: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.promptName === 'finalDelivable' || this.props.promptName === 'notFinalDelivable') &&
      this.props.is_promptBtnOk &&
      this.props.is_promptBtnOk != prevProps.is_promptBtnOk
    ) {
      this.handleFinalDelivable();
    }

    if (prevProps.fileLoading === true && this.props.fileLoading === false) {
      this.setState({
        checkBoxFileId: null,
      });
    }
  }

  handleOpenPopUp = () => {
    this.setState({
      checkBoxFileId: this.props.selectedPrevFile.id,
    });
  };

  handleClosePopUp = () => {
    this.props.closePopup();
    this.setState({
      checkBoxFileId: null,
    });
  };

  handleUseFileAsResponse = () => {
    this.props.handleUseFileAsResponse();
  };

  handleFinalDelivable = () => {
    const { is_final } = this.state;
    const { handleFinalDelivable, selectedPrevFile } = this.props;
    handleFinalDelivable(selectedPrevFile.id, !is_final);
    this.setState({
      is_final: !is_final,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  collectFiles = () => {
    const { isPCD, isRequest, isDeliverable, selectedRequest, isReview, isSubmittal } = this.props;

    if (isReview) {
      return [];
    } else if (isRequest || isPCD || isSubmittal) {
      return selectedRequest?.files || 0;
    } else if (isDeliverable) {
      return selectedRequest.response_files;
    } else {
      return [];
    }
  };

  renderPdftronTitle = () => {
    const { selectedRequest, isPCD, isComment, isSubmittal } = this.props;
    if (isSubmittal) {
      return `Submittal ${
        selectedRequest ? (isComment ? `#${selectedRequest.mf_code} - ${selectedRequest.sf}` : selectedRequest.sf_index) : null
      }`;
    }
    if (isPCD) {
      if (selectedRequest) {
        const textBtn =
          window.innerWidth < 430 || this.props.width !== 95
            ? `${selectedRequest?.drawing?.display_title_shot?.type}`
            : `${selectedRequest.drawing?.display_title?.discipline || ''}${selectedRequest.drawing?.display_title?.type || ''} ${
                selectedRequest.drawing?.display_title?.subject || ''
              }`;
        return `${textBtn} 
            ${!isComment ? `(R${selectedRequest.revision})` : ''}`;
      } else {
        return '';
      }
    } else {
      return `NF ${selectedRequest ? selectedRequest.nf : null}`;
    }
  };

  render() {
    const {
      selectedRequest,
      selectedPrevFile,
      isAccess,
      isPCD,
      isComment,
      isReview,
      isRequest,
      isSubmittal,
      isDeliverable,
      isLoading,
      changeFile,
      isAccessToUseFileAsResponse,
      isOpenPopup,
    } = this.props;
    const { checkBoxFileId } = this.state;

    const themeClass = handleGetThemeClass('b-prevFile', this.props.theme);
    const themeClassPrompt = handleGetThemeClass('containerPdfPrompt', this.props.theme);

    const useAsResponse = () => {
      const isCopiedToResponse = selectedRequest.response_files.map(el => el.parent_id).includes(selectedPrevFile.id);
      const handleCheckNfStatusUseResponeComment = (nfStatus, nfIsDeleted, nfIsDeactivated) => {
        return (
          (nfStatus === 'sent' || nfStatus === 'submitted' || nfStatus === 'declined') &&
          !nfIsDeleted &&
          !nfIsDeactivated &&
          nfStatus !== 'drafted'
        );
      };
      const isAccessNfStatus = handleCheckNfStatusUseResponeComment(
        selectedRequest?.status,
        selectedRequest?.is_deleted,
        selectedRequest?.is_deactivated,
      );

      return (
        <div>
          <div
            className={cn(`${themeClass}_useAsResponseBtn`, {
              ['-disabled']: isCopiedToResponse || !isAccessNfStatus,
            })}
          >
            <CheckBox
              disabled={isCopiedToResponse || !isAccessNfStatus}
              checked={!!checkBoxFileId || isCopiedToResponse}
              onClick={!isCopiedToResponse && isAccessNfStatus ? this.handleOpenPopUp : null}
            />
            <span>Use as response</span>
          </div>

          {checkBoxFileId || isOpenPopup ? (
            <>
              <div className={`${themeClassPrompt}`} />
              <div className={`${themeClassPrompt}_containerPdfPromptBackground`}>
                <div className={`${themeClassPrompt}_messageContainerPdfPrompt`}>
                  <span>Do you want to use this file as a response to NF {selectedRequest.nf}?</span>
                </div>
                <div className={`${themeClassPrompt}_buttonPdfContainer`}>
                  <CustomButton type={'tertiary'} size={'md'} title={'No'} clickHandler={this.handleClosePopUp} />
                  <CustomButton type={'primary'} size={'md'} title={'Yes'} clickHandler={this.handleUseFileAsResponse} />
                </div>
              </div>
            </>
          ) : null}
        </div>
      );
    };

    const renderShowGroupingBasis = (isActive: boolean, disabled: boolean) => {
      return (
        <FilterSelectBasis
          title={`Show more ${this.collectFiles()?.length}`}
          isActive={isActive}
          disabled={disabled}
          customClassName={`${themeClass}_filterSelectWidth`}
        />
      );
    };

    return (
      <div className={`${themeClass}__header`}>
        <div className={`${themeClass}__fileInfo`}>
          <div className={`${themeClass}__requestHeaderTag`}>
            <CardLinkButton
              type={this.props.isPCD ? 'deliverable' : this.props.isSubmittal ? 'submittal' : 'nf'}
              size={'md'}
              title={this.renderPdftronTitle()}
            />
            <span className={`${themeClass}__requestHeaderTag_type`}>
              {(isComment && 'Comments file') ||
                (isSubmittal && isReview && 'Submittal review file') ||
                (isReview && 'Deliverable review file') ||
                (isRequest && !isComment && 'Request file') ||
                (isSubmittal && !isComment && 'Submittal file') ||
                (isPCD &&
                  !isComment &&
                  !isReview &&
                  (this.props.width === 95 || (this.props.width === 55 && this.collectFiles().length === 1)) &&
                  'Deliverable card file') ||
                (isDeliverable && 'Response file')}
            </span>
          </div>
          {!!selectedRequest?.tags?.length && window.innerWidth > 430 && <ChipsRequestType type={selectedRequest?.tags[0]?.title} />}
          {selectedRequest?.milestone && window.innerWidth > 430 && (
            <ChipsLOD type={'filled'} value={PhasePlanHelper.getMileStoneNameByAlias(selectedRequest.milestone?.alias || '')} />
          )}
        </div>
        {this.props.width < 95 && (
          <div className={`${themeClass}__header_rightBlock`} style={isRequest ? { width: 'calc(70%)' } : {}}>
            <div
              className={`${themeClass}__header_file`}
              style={this.props.width === 40 ? (this.collectFiles().length > 1 ? { width: '70%' } : { width: '80%' }) : {}}
            >
              <div className={`${themeClass}__header_file_title`}>{selectedPrevFile?.title}</div>
              {this.collectFiles().length > 1 ? (
                <Select<DropdownItemModel, null, false>
                  isMulti={false}
                  menuItems={this.collectFiles() || []}
                  selectHandler={item => changeFile(item)}
                  isLoading={false}
                  renderCustomBasis={(isActive, disabled) => renderShowGroupingBasis(isActive, disabled)}
                  menuItemSize={'sm'}
                  customSelectClass={`${themeClass}__header_file_showMore`}
                />
              ) : null}
            </div>
            {isRequest && isAccessToUseFileAsResponse && this.props.width !== 40 && useAsResponse()}
            <SystemButton type={'close'} size={'md'} variant={'transparent'} clickHandler={this.handleClose} />
          </div>
        )}

        {this.props.width == 95 && (
          <div className={`${themeClass}__header_rightBlock`}>
            <div
              className={cn(`${themeClass}__header_file`, {
                ['-widthSmallPCD']: window.innerWidth < 430 && this.props.isPCD,
              })}
            >
              <div className={`${themeClass}__header_file_title`}>{selectedPrevFile?.title}</div>
              {this.collectFiles().length > 1 && window.innerWidth > 430 ? (
                <Select<DropdownItemModel, null, false>
                  isMulti={false}
                  menuItems={this.collectFiles() || []}
                  selectHandler={item => changeFile(item)}
                  isLoading={false}
                  renderCustomBasis={(isActive, disabled) => renderShowGroupingBasis(isActive, disabled)}
                  menuItemSize={'sm'}
                  customSelectClass={`${themeClass}__header_file_showMore`}
                  popOverId={'pdfTronPopOver'}
                />
              ) : null}
            </div>
            {isRequest && isAccessToUseFileAsResponse && useAsResponse()}
            <SystemButton type={'close'} size={'md'} variant={'transparent'} clickHandler={this.handleClose} />
          </div>
        )}
      </div>
    );
  }
}

export default PrevFileHeader;
