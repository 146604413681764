import React, { Component } from 'react';
import { DOMAIN_SELF_URL } from '../../../../../../service/links';

class Updates_20230427_2 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <video width="100%" height="auto" src={`${DOMAIN_SELF_URL}/videos/sandbox-widgets.mp4`} controls>
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </div>
        <div className={'unreadWhatsNewText'}>
          <div className={'paragraph'}>
            ❗❗ If you need a custom widget tailored to your specific needs, please contact us through the app or directly via email. We
            will do our best to meet your needs! 😎
          </div>
        </div>
      </>
    );
  }
}

export default Updates_20230427_2;
