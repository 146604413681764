import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KeyValueModel } from '../../../models/key-value.model';
import { FilterModel, GlobalFiltersQueryModel } from '../../../models';
import { convertFilterNameForApi, isEmptyObject } from '../../../helpers/commonHelpers';
import { filterSlice } from '../../../store/filters/filtersReducer';
import { SandBoxCustomTabsPayloadModel, SandBoxTabModel } from '../../../models/sandBox/sandBoxArhitecture';
import { CustomFiltersModel } from '../../../store/request/requestReducer.model';
import { DropdownGroupItem, DropdownItemModel, SortingDataModel } from '../../../models/global';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { useGetThemeClass } from '../../../helpers/designTokens';
import ReactResizeDetector from 'react-resize-detector';
import { GlobalSearchMenu } from '../../controls';
import { TextInputNew } from '../../controls';
import { CodeSelector, SortingField } from '../../controls/Dropdowns/DropdownСomponents';
import { SandBoxRelationSwitcher } from '../SandBox/parts';
import { Select } from '../../controls/Dropdowns/DropdownСomponents';
import { CardLinkButton, FilterButton, SystemButton } from '../../controls/ButtonComponents';
import FilterSelector from '../../controls/Dropdowns/DropdownСomponents/FilterSelector/FilterSelector';
import { difference, isEqual } from 'lodash';
import ChipsFilterOption from '../../controls/Chips/ChipsFilterOption/ChipsFilterOption';
import FiltersHelper from '../../../helpers/FiltersHelper';
import { GetPageFiltersFiltersPayload } from '../../../store/filters/filterReducer.model';
import { getPageFilters } from '../../../store/filters/filtersThunk';
import { FilterSelectBasis } from '../../controls/Dropdowns/CustomParts';
import ChipsColorTag from '../../controls/Chips/ChipsColorTag/ChipsColorTag';
import ConditionSelector from '../../controls/Dropdowns/DropdownСomponents/ConditionSelector/ConditionSelector';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import cn from 'classnames';
import { useClickOutside } from '../../../helpers/commonHooks';
import Placeholder from '../../controls/Placeholder/Placeholder';
import { getShortLink } from '../../../store/globalSettings/settingsThunk';
import { ShortLinkResponse } from '../../../store/globalSettings/settingsReducer.model';
import CustomTooltip from '../../controls/Tooltip/Tooltip';

import './PageFiltersStyles.scss';
import { RouteComponentProps, withRouter } from '../../../navigation/withRouter/withRouter';
import useRouter from '../../../hooks/useRouter/useRouter';

const { saveStoreMoreRequestsFilters, saveStoreRequestsFilters } = filterSlice.actions;

interface DropdownItemModelExt extends DropdownItemModel {
  valueSearch?: string;
}

export interface OnChangeFiltersModel {
  filters?: KeyValueModel<any>;
  newContainsFilter?: any;
  orFilters?: KeyValueModel<any>;
  newOrContainsFilter?: any;
  searchValue?: string;
  sortingInfo?: SortingDataModel;
}

interface GroupedByFiltersModel {
  buildings: string[];
}

interface RenderItemFilterModel extends DropdownItemModel {
  color?: 'default' | 'pink' | 'peach' | 'sand' | 'lime' | 'blue' | 'indigo' | 'purple' | 'gray';
}

interface IProps {
  outerSearchValue?: string;
  setOuterSearchValue?: (search: string) => void;
  storeFilterName:
    | 'need_form'
    | 'deliverable'
    | 'design_document'
    | 'team'
    | 'sandbox_need_form'
    | 'sandbox_deliverable'
    | 'sandbox_report_need_form'
    | 'sandbox_report_deliverable'
    | 'submittal_form_input'
    | 'submittal_form_output';
  hideSaveFiltersButton?: () => void;
  filtersChangeHandler: (data?: GlobalFiltersQueryModel) => void;
  resetFiltersHandler?: () => void;
  heightChangeHandler?: (height: number) => void;
  setFilterCount?: (count: number) => void;
  onChangeGrouping?: (values: string[]) => void;
  showGrouping: boolean;
  showSorting: boolean;
  showBuildingSelector: boolean;
  showFilter?: boolean;
  showControllers?: boolean;
  showSearch?: boolean;
  onChangeSorting: (sorting: SortingDataModel) => void;
  setRelation?: (relation: 'user' | 'party') => void;
  relatedTo?: 'user' | 'party';
  setSandBoxCustomFilterTab?: (
    tabs: SandBoxCustomTabsPayloadModel,
    type: 'request' | 'deliverable',
    customFilters: CustomFiltersModel,
    searchValue?: string,
  ) => void;
  activeSandBoxSubSection?: SandBoxTabModel;
  isBulkEditMode?: boolean;
  sortingOptions?: DropdownItemModel[];
  groupingOptions?: DropdownItemModel[];
  groupedByFilters?: GroupedByFiltersModel;
  selectedBuildingId?: number;
  setSelectedBuildingId?: (buildingId: number) => void;
  codeSelectorHandlerType?: 'need-form' | 'deliverable' | 'content-plan' | 'all-exist' | 'submittal-form';
  initiateResetFilters?: boolean;
  setInitiateResetFilters?: (value: boolean) => void;
  filterSelectorGroups: DropdownGroupItem[];
  filterColumns: FilterModel[];
  showOrFilters: boolean;
  setShowResetButton?: (value: boolean) => void;
  isNotDisplayed?: boolean;
  handleOpenSearchMobile?: () => void;
  handleCloseSearchMobile?: () => void;
  isOpenSearchMobile?: boolean;
  savedFilterNames?: string[];
  defaultFilter?: string;
  nfSections?: SandBoxTabModel[];
  pcdSections?: SandBoxTabModel[];
  isDisableGrouping?: boolean;
  isDisableSearch?: boolean;
  isDisableFilters?: boolean;
  isDisableSorting?: boolean;
  sorting?: SortingDataModel;
  isResetSorting?: boolean;
  handleResetSorting?: (is_sorting: boolean) => void;
  isCollapsedBtn?: boolean;
}

const codeSelectorTypeByFilter = {
  incorporated_task_scope: 'uf',
  ufc: 'uf',
  uf: 'uf',
  mf: 'mf',
  mfc: 'mf',
};

function PageFilters({
  outerSearchValue,
  setOuterSearchValue,
  storeFilterName,
  hideSaveFiltersButton,
  filtersChangeHandler,
  resetFiltersHandler,
  heightChangeHandler,
  setFilterCount,
  onChangeGrouping,
  onChangeSorting,
  showGrouping,
  showSorting,
  showFilter = true,
  showControllers = true,
  showSearch = true,
  showBuildingSelector,
  setRelation,
  relatedTo,
  setSandBoxCustomFilterTab,
  activeSandBoxSubSection,
  isBulkEditMode,
  sortingOptions,
  groupingOptions,
  groupedByFilters,
  selectedBuildingId,
  setSelectedBuildingId,
  codeSelectorHandlerType,
  initiateResetFilters,
  setInitiateResetFilters,
  filterSelectorGroups,
  filterColumns,
  showOrFilters,
  setShowResetButton,
  isNotDisplayed,
  handleOpenSearchMobile,
  handleCloseSearchMobile,
  isOpenSearchMobile,
  savedFilterNames,
  defaultFilter,
  nfSections,
  pcdSections,
  isDisableGrouping,
  isDisableSearch,
  isDisableFilters,
  isDisableSorting,
  sorting,
  isResetSorting,
  handleResetSorting,
  isCollapsedBtn,
}: IProps) {
  const dispatch = useAppDispatch();
  const { location, navigate, urlProject, searchParams } = useRouter();

  const getRequestFiltersPromise = useRef(null);

  const searchRef = useRef();
  const searchMobileRef = useRef();

  useClickOutside(searchMobileRef, handleCloseSearchMobile);

  const themeClass = useGetThemeClass('b-pageFilters');

  const { isMobile } = GetResolution();
  const isDbCreated = useAppSelector(state => state.indexedDbSlice?.isDbCreated);

  const pageFilters = useAppSelector(state => state.filterReducer.pageFilters);
  const project_id = useAppSelector(state => state.userReducer.active_project_id);
  const savedRequestsFilters = useAppSelector(state => state.filterReducer.savedRequestsFilters);
  const savedMoreRequestsFilters = useAppSelector(state => state.filterReducer.savedMoreRequestsFilters);
  const buildingsCollection = useAppSelector(state => state.projectReducer.projectInfo.buildings);
  const savedReportingFilter = useAppSelector(state => state.filterReducer.savedReportingFilter);
  const clientProjectsLoading = useAppSelector(state => state.userReducer.clientProjectsLoading);
  const refreshes = useAppSelector(state => state.projectReducer.refreshes);
  const refreshesLoading = useAppSelector(state => state.projectReducer.refreshesLoading);
  const userFilters = useAppSelector(state => state.filterReducer.userFilters);
  const isFirstNeedListLoading = useAppSelector(state => state.requestReducer.isFirstNeedListLoading);

  const [filtersQuery, setFiltersQuery] = useState<GlobalFiltersQueryModel>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedGroupingValues, setSelectedGroupingValues] = useState<DropdownItemModel[]>([]);
  const [sortingInfo, setSortingInfo] = useState<SortingDataModel>(null);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [orFilters, setOrFilters] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>({});
  const [formDataOrFilters, setFormDataOrFilters] = useState<any>({});
  const [conditions, setConditions] = useState<{ [name: string]: string[] }>({});
  const [orConditions, setOrConditions] = useState<{ [name: string]: string[] }>({});
  const [containsFilter, setContainsFilter] = useState<any>({});
  const [orContainsFilter, setOrContainsFilter] = useState<any>({});
  const [isOrFilter, setIsOrFilter] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string | number>('');
  const [orderDir, setOrderDir] = useState<string>('');

  const [savedFilterFromLocalStorage, setSavedFilterFromLocalStorage] = useState<boolean>(false);
  const [isGlobalSearchMenuOpen, setIsGlobalSearchMenuOpen] = useState<boolean>(false);
  const [selectedFilterSelector, setSelectedFilterSelector] = useState<string[]>();
  // State that contains last getRequestFilters query
  const [canGeneratePage, setCanGeneratePage] = useState<boolean>(false);
  const [prevProjectId, setPrevProjectId] = useState(null);
  const [onSwitchAndFilter, setOnSwitchAndFilter] = useState<string[]>([]);

  const formattedFilters = useMemo(() => {
    return pageFilters[storeFilterName];
  }, [storeFilterName, pageFilters]);

  const filterSelectorOptions = useMemo(() => {
    const formattedForFilterSelectorValues = filters.map(filter => {
      return {
        ...filter,
        value: filter.filterField,
      };
    });

    return formattedForFilterSelectorValues.filter(f => !!f.group);
  }, [filters]);

  const isFiltersDisabled = useMemo(() => {
    return clientProjectsLoading || isDisableSearch || isDisableFilters || isDisableGrouping || isDisableSorting;
  }, [clientProjectsLoading, isDisableSearch, isDisableFilters, isDisableGrouping, isDisableSorting]);

  const selectedFilterSelectorOptions = useMemo(() => {
    return isOrFilter ? orFilters : selectedFilters;
  }, [isOrFilter, orFilters, selectedFilters]);

  const filterSelectorActive = useMemo(() => {
    return selectedFilters?.length > 0 || orFilters?.length > 0;
  }, [isOrFilter, orFilters, selectedFilters]);

  const checkForGetFiltersBodyParams = useMemo(() => {
    return storeFilterName === 'deliverable' ? project_id && (+selectedBuildingId || selectedBuildingId === 0) : project_id;
  }, [storeFilterName, project_id, selectedBuildingId]);

  const buildingsForCurrentProject = useMemo(() => {
    return buildingsCollection?.[project_id] || [];
  }, [buildingsCollection, project_id]);

  const buildingMenuItems = useMemo(() => {
    return buildingsForCurrentProject.map(building => {
      return {
        value: building.id,
        title: building.title,
      };
    });
  }, [buildingsForCurrentProject]);

  const selectedBuildingMenuItem = useMemo(() => {
    return buildingMenuItems.find(building => building.value === +selectedBuildingId);
  }, [selectedBuildingId, buildingMenuItems]);

  const globalSearchMenuType = useMemo(() => {
    if (storeFilterName === 'need_form' || storeFilterName === 'sandbox_need_form' || storeFilterName === 'sandbox_report_need_form') {
      return 'request';
    }

    if (
      storeFilterName === 'deliverable' ||
      storeFilterName === 'sandbox_deliverable' ||
      storeFilterName === 'sandbox_report_deliverable'
    ) {
      return 'deliverable';
    }
  }, [storeFilterName]);

  useEffect(() => {
    if (savedFilterNames) {
      handleSelectedFilter(savedFilterNames);
    }
  }, [savedFilterNames]);

  useEffect(() => {
    if (sorting?.order_by || isResetSorting) {
      if (sorting?.order_by !== orderBy || sorting?.order_dir !== orderDir) {
        handleChangeSorting(sorting);
      }
      handleResetSorting && handleResetSorting(false);
    }
  }, [sorting, isResetSorting]);

  //////// UseEffect FOR REPORTING URL CHANGES
  useEffect(() => {
    const filterUrlNeedList = searchParams.get(`need_form_filter`);
    const filterParamHash = !!Array.from(searchParams.entries()).filter(([key]) => key.includes(`_hash`)).length;

    if (filterParamHash) {
      return;
    }

    const defaultFilterFromUrl = searchParams.get(`default_filter`);
    const defaultLocalStorageData = {
      conditions: {},
      or_conditions: {},
      containsFilter: {},
      or_containsFilter: {},
      formData: {},
      formDataOrFilters: [],
      orFilters: [],
      project_name: urlProject[0],
      searchValue: '',
      selectedFilters: [],
      orderBy: '',
      orderDir: '',
    };

    const oldLocalStorageData = JSON.parse(localStorage.getItem(`${storeFilterName}`)) || {};
    if (Object.keys(oldLocalStorageData || {}).length && oldLocalStorageData.project_name !== urlProject[0]) {
      localStorage.setItem(`${storeFilterName}`, JSON.stringify(defaultLocalStorageData));
    }

    if (defaultFilterFromUrl) {
      return localStorage.setItem(`${storeFilterName}`, JSON.stringify(defaultLocalStorageData));
    }

    let localStorageData;
    if (filterUrlNeedList) {
      localStorageData = { ...defaultLocalStorageData, ...(JSON.parse(localStorage.getItem(`${storeFilterName}`)) || {}) };
    } else {
      localStorageData = JSON.parse(localStorage.getItem(`${storeFilterName}`)) || {};
    }

    if (
      Object.keys(localStorageData || {}).length &&
      localStorageData.project_name === urlProject[0] &&
      (Object.keys(localStorageData?.formData || {}).length ||
        Object.keys(localStorageData?.formDataOrFilters || {}).length ||
        Object.keys(localStorageData?.containsFilter || {}).length ||
        localStorageData?.searchValue ||
        localStorageData?.orderBy ||
        localStorageData?.selectedFilters?.length ||
        localStorageData?.orFilters?.length ||
        Object.keys(localStorageData?.conditions || {}).length)
    ) {
      const moreRequests = { ...localStorageData.formData, ...localStorageData.conditions };
      const selectFilterLocalStorage = Object.keys(moreRequests || {});
      if (selectFilterLocalStorage?.length > 0) {
        handleSelectedFilter(selectFilterLocalStorage);
      }
      dispatch(
        saveStoreMoreRequestsFilters({
          data: Object.keys(moreRequests || {}) || [],
          name: `${storeFilterName}`,
        }),
      );
      dispatch(
        saveStoreMoreRequestsFilters({
          data: localStorageData.conditions || {},
          name: `${storeFilterName}_condition`,
        }),
      );
      dispatch(
        saveStoreRequestsFilters({
          data: localStorageData.containsFilter || {},
          name: `${storeFilterName}_condition`,
        }),
      );
      dispatch(
        saveStoreMoreRequestsFilters({
          name: `${storeFilterName}_orFilters`,
          data: Object.keys({ ...localStorageData.formDataOrFilters, ...localStorageData.orContainsFilter } || {}) || [],
        }),
      );
      dispatch(saveStoreRequestsFilters({ data: localStorageData.orContainsFilter, name: `${storeFilterName}_or_condition` }));
      dispatch(saveStoreMoreRequestsFilters({ data: localStorageData.orConditions, name: `${storeFilterName}_or_condition` }));
      dispatch(
        saveStoreRequestsFilters({
          data: {
            filters: localStorageData.formData || {},
            or_filters: localStorageData.formDataOrFilters || {},
            search: localStorageData.searchValue || '',
            order_by: localStorageData.orderBy || '',
            order_dir: localStorageData.orderDir || '',
            switch_and: localStorageData.switch_and || [],
          },
          name: `${storeFilterName}`,
        }),
      );
    }

    if (savedRequestsFilters[storeFilterName]) {
      setFormData(savedRequestsFilters[storeFilterName].filters || {});
      setFormDataOrFilters(savedRequestsFilters[storeFilterName].or_filters || localStorageData?.formDataOrFilters || {});
      setContainsFilter(savedRequestsFilters[`${storeFilterName}_condition`] || {});
      setOrContainsFilter(savedRequestsFilters[`${storeFilterName}_or_condition`] || {});
      setSearchValue(savedRequestsFilters[storeFilterName].search);
      setOrderBy(savedRequestsFilters[storeFilterName].order_by);
      setOrderDir(savedRequestsFilters[storeFilterName].order_dir);
      setSortingInfo({
        order_by: savedRequestsFilters[storeFilterName].order_by || '',
        order_dir: savedRequestsFilters[storeFilterName].order_dir || '',
      });
    }

    if (savedMoreRequestsFilters[storeFilterName]) {
      setSelectedFilters(savedMoreRequestsFilters[storeFilterName] || []);
      setOrFilters(savedMoreRequestsFilters[`${storeFilterName}_orFilters`] || []);
      setConditions(savedMoreRequestsFilters[`${storeFilterName}_condition`] || []);
      setOrContainsFilter(savedMoreRequestsFilters[`${storeFilterName}_or_condition`] || []);
    }

    if (savedRequestsFilters[storeFilterName] && Object.keys(savedRequestsFilters[storeFilterName].or_filters || {}).length > 0) {
      setIsOrFilter(true);
    }

    initListFilters();
    // setTimeout(() => {
    setSavedFilterFromLocalStorage(true);
    const resultUrl = Array.from(searchParams.entries())
      .filter(([key]) => !key.includes('need_form_filter'))
      .map(([key, value]) => `${key}=${value}`);
    navigate(
      {
        pathname: location.pathname,
        search: `${resultUrl.join('&')}`,
      },
      { replace: true },
    );
    // }, 500);
  }, []);
  ///////// UseEffect FOR REPORTING URL CHANGES

  useEffect(() => {
    if (canGeneratePage) {
      const localStorageData = JSON.parse(localStorage.getItem(`${storeFilterName}`)) || {};
      const defaultLocalStorageData = {
        conditions: {},
        or_conditions: {},
        containsFilter: {},
        or_containsFilter: {},
        formData: {},
        formDataOrFilters: [],
        orFilters: [],
        project_name: urlProject[0],
        searchValue: '',
        selectedFilters: [],
        orderBy: '',
        orderDir: '',
      };

      if (Object.keys(localStorageData || {}).length && localStorageData.project_name !== urlProject[0]) {
        localStorage.setItem(`${storeFilterName}`, JSON.stringify(defaultLocalStorageData));
      }

      if (
        Object.keys(localStorageData || {}).length &&
        localStorageData.project_name === urlProject[0] &&
        (Object.keys(localStorageData?.formData || {}).length ||
          Object.keys(localStorageData?.formDataOrFilters || {}).length ||
          Object.keys(localStorageData?.containsFilter || {}).length ||
          localStorageData?.searchValue ||
          localStorageData?.orderBy ||
          localStorageData?.selectedFilters?.length ||
          localStorageData?.orFilters?.length ||
          Object.keys(localStorageData?.conditions || {}).length)
      ) {
        const moreRequests = { ...localStorageData.formData, ...localStorageData.conditions };
        const selectFilterLocalStorage = Object.keys(moreRequests || {});
        if (selectFilterLocalStorage?.length > 0) {
          handleSelectedFilter(selectFilterLocalStorage);
        }
        dispatch(
          saveStoreMoreRequestsFilters({
            data: Object.keys(moreRequests || {}) || [],
            name: `${storeFilterName}`,
          }),
        );
        dispatch(
          saveStoreMoreRequestsFilters({
            data: localStorageData.conditions || {},
            name: `${storeFilterName}_condition`,
          }),
        );
        dispatch(
          saveStoreRequestsFilters({
            data: localStorageData.containsFilter || {},
            name: `${storeFilterName}_condition`,
          }),
        );
        dispatch(
          saveStoreMoreRequestsFilters({
            name: `${storeFilterName}_orFilters`,
            data: Object.keys({ ...localStorageData.formDataOrFilters, ...localStorageData.orContainsFilter } || {}) || [],
          }),
        );
        dispatch(saveStoreRequestsFilters({ data: localStorageData.orContainsFilter, name: `${storeFilterName}_or_condition` }));
        dispatch(saveStoreMoreRequestsFilters({ data: localStorageData.orConditions, name: `${storeFilterName}_or_condition` }));
        dispatch(
          saveStoreRequestsFilters({
            data: {
              filters: localStorageData.formData || {},
              or_filters: localStorageData.formDataOrFilters || {},
              search: localStorageData.searchValue || '',
              order_by: localStorageData.orderBy || '',
              order_dir: localStorageData.orderDir || '',
              switch_and: localStorageData.switch_and || [],
            },
            name: `${storeFilterName}`,
          }),
        );
      }

      if (savedRequestsFilters[storeFilterName]) {
        setFormData(savedRequestsFilters[storeFilterName].filters || {});
        setFormDataOrFilters(savedRequestsFilters[storeFilterName].or_filters || localStorageData?.formDataOrFilters || {});
        setContainsFilter(savedRequestsFilters[`${storeFilterName}_condition`] || {});
        setOrContainsFilter(savedRequestsFilters[`${storeFilterName}_or_condition`] || {});
        setSearchValue(savedRequestsFilters[storeFilterName].search);
        setOrderBy(savedRequestsFilters[storeFilterName].order_by);
        setOrderDir(savedRequestsFilters[storeFilterName].order_dir);
        setSortingInfo({
          order_by: savedRequestsFilters[storeFilterName].order_by || '',
          order_dir: savedRequestsFilters[storeFilterName].order_dir || '',
        });
      }

      if (savedMoreRequestsFilters[storeFilterName]) {
        setSelectedFilters(savedMoreRequestsFilters[storeFilterName] || []);
        setOrFilters(savedMoreRequestsFilters[`${storeFilterName}_orFilters`] || []);
        setConditions(savedMoreRequestsFilters[`${storeFilterName}_condition`] || []);
        setOrContainsFilter(savedMoreRequestsFilters[`${storeFilterName}_or_condition`] || []);
      }

      if (savedRequestsFilters[storeFilterName] && Object.keys(savedRequestsFilters[storeFilterName].or_filters || {}).length > 0) {
        setIsOrFilter(true);
      }

      initListFilters();
      setTimeout(() => {
        setSavedFilterFromLocalStorage(true);
        setCanGeneratePage(false);
      }, 500);
    }
  }, [canGeneratePage]);

  const checkUrlParams = () => {
    let storeFilterNameNew;
    if (storeFilterName.includes('_report')) {
      storeFilterNameNew = storeFilterName
        .split('_')
        .filter(f => f !== 'report')
        .join('_');
    } else {
      storeFilterNameNew = storeFilterName;
    }

    const filterHash = searchParams.get(`${storeFilterNameNew}_hash`);
    if (filterHash) {
      dispatch(
        getShortLink({
          filter_hash: JSON.parse(filterHash),
          callback: (data: ShortLinkResponse) => {
            if (data.project_id === project_id && data.module === storeFilterNameNew) {
              localStorage.setItem(`${storeFilterName}`, JSON.stringify(data.filters_data));
              if (data.filters_data.building_id) {
                localStorage.setItem('pcd_current_building_id', String(data.filters_data.building_id));
              }
            }
            setCanGeneratePage(true);
          },
        }),
      );
    } else {
      setCanGeneratePage(true);
    }
  };

  useEffect(() => {
    if (project_id && prevProjectId !== project_id) {
      if (storeFilterName === 'need_form') {
        const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));

        if (
          (refreshesLoading?.loaded && (refreshes?.request || !refreshes?.request)) ||
          (needListCashedData && !needListCashedData[project_id] && !needListCashedData[project_id]?.cachedTimestamp)
        ) {
          setPrevProjectId(project_id);
          checkUrlParams();
        }
      } else {
        setPrevProjectId(project_id);
        checkUrlParams();
      }
    }
  }, [project_id, refreshes?.request, refreshesLoading]);

  const initListFilters = () => {
    const filterList =
      (filterColumns as FilterModel[]).filter(
        f => f.filterField !== 'my_commitments_deliverables' && Object.keys(pageFilters[storeFilterName] || {}).includes(f.filterField),
      ) || [];
    const filterList_new =
      (filterColumns as FilterModel[]).filter(f => f.filterField !== 'my_commitments_deliverables' && f.filterField) || [];

    setFilters(filterList_new);
  };

  const getFiltersHandler = () => {
    if (!checkForGetFiltersBodyParams || !selectedFilterSelector?.length) {
      return;
    }

    let data = {
      project_id: +project_id,
      filter_name: storeFilterName,
      filter_aliases: selectedFilterSelector,
    } as GetPageFiltersFiltersPayload;

    if (+selectedBuildingId) {
      data = {
        ...data,
        building_id: selectedBuildingId,
      };
    }

    if (data.filter_name === 'deliverable' && !+selectedBuildingId) return;

    getRequestFiltersPromise.current?.abort();
    getRequestFiltersPromise.current = dispatch(getPageFilters(data));
  };

  const handleSelectedFilter = (newSelectedFilter: string[]) => {
    setSelectedFilterSelector(newSelectedFilter);
  };

  useEffect(() => {
    if (selectedFilterSelector?.length > 0) {
      getFiltersHandler();
    }
  }, [project_id, storeFilterName, selectedBuildingId, selectedFilterSelector]);

  useEffect(() => {
    if (initiateResetFilters && setInitiateResetFilters) {
      const isFilters =
        [
          ...Object.keys(formData || {}),
          ...Object.keys(formDataOrFilters || {}),
          ...Object.keys(containsFilter || {}),
          ...Object.keys(orContainsFilter || {}),
        ].length > 0 ||
        !!searchValue?.length ||
        !!orderBy?.length;
      handleResetFilters(isFilters);
      setInitiateResetFilters(false);
    }
  }, [initiateResetFilters, setInitiateResetFilters]);

  useEffect(() => {
    initListFilters();
  }, [filterColumns]);

  useEffect(() => {
    if (selectedFilters) {
      setFilterCount && setFilterCount(selectedFilters.length);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (setShowResetButton) {
      const showResetButton = !!(selectedFilters?.length || sortingInfo?.order_by || searchValue || orderBy);

      setShowResetButton(showResetButton);
    }
  }, [selectedFilters, searchValue, sortingInfo, orderBy]);

  useEffect(() => {
    const newFormData = savedRequestsFilters[storeFilterName]?.filters || {};
    const savedFilters = savedRequestsFilters[storeFilterName] || {};
    setFormData(newFormData);
    setContainsFilter(savedRequestsFilters[`${storeFilterName}_condition`] || {});
    setOrContainsFilter(savedRequestsFilters[`${storeFilterName}_or_condition`] || {});
    setSelectedFilters(savedMoreRequestsFilters[storeFilterName] || Object.keys(newFormData || []) || []);
    setConditions(savedMoreRequestsFilters[`${storeFilterName}_condition`] || []);
    setOrConditions(savedMoreRequestsFilters[`${storeFilterName}_or_condition`] || []);
    setSearchValue(savedRequestsFilters[storeFilterName]?.search || '');
    setOrderBy(savedRequestsFilters[storeFilterName]?.order_by || '');
    setOrderDir(savedRequestsFilters[storeFilterName]?.order_dir || '');
    setSortingInfo({
      order_by: savedRequestsFilters[storeFilterName]?.order_by || '',
      order_dir: savedRequestsFilters[storeFilterName]?.order_dir || '',
    });
    setOnSwitchAndFilter(savedRequestsFilters[storeFilterName]?.switch_and || []);

    const newFormDataOrFilters = savedRequestsFilters[storeFilterName]?.or_filters || {};
    const newFormOrCondition = savedRequestsFilters[`${storeFilterName}_or_condition`] || {};

    if (Object.keys(newFormDataOrFilters).length > 0) {
      setFormDataOrFilters(newFormDataOrFilters);
      setOrFilters(savedMoreRequestsFilters[`${storeFilterName}_orFilters`] || Object.keys(newFormDataOrFilters || []) || []);
      setIsOrFilter(true);
    } else if (isEmptyObject(savedFilters.filters) && !selectedFilters.length) {
      setFormDataOrFilters({});
      setOrFilters([]);
      setIsOrFilter(false);
    } else if (savedMoreRequestsFilters[`${storeFilterName}_orFilters`]?.length) {
      setOrFilters(savedMoreRequestsFilters[`${storeFilterName}_orFilters`]);
      setIsOrFilter(true);
    } else {
      setOrFilters([]);
      setIsOrFilter(false);
    }
  }, [savedRequestsFilters, savedMoreRequestsFilters, storeFilterName]);

  useEffect(() => {
    if (typeof outerSearchValue === 'string' && outerSearchValue !== searchValue) {
      saveStoreRequestsFilters({
        data: {
          order_by: orderBy || '',
          order_dir: orderDir || '',
          search: outerSearchValue,
        },
        name: storeFilterName,
      });
      setSearchValue(outerSearchValue);
    }
  }, [outerSearchValue]);

  useEffect(() => {
    if (savedFilterFromLocalStorage && project_id && !canGeneratePage && userFilters && isDbCreated) {
      onChangeFilters(null, null, null, null, true);
      setSavedFilterFromLocalStorage(false);
    }
  }, [savedFilterFromLocalStorage, canGeneratePage, userFilters, isDbCreated]);

  useEffect(() => {
    const localStorageData = {
      formData,
      containsFilter,
      orContainsFilter,
      selectedFilters,
      conditions,
      orConditions,
      formDataOrFilters,
      orFilters,
      searchValue,
      orderBy,
      orderDir,
      project_name: urlProject[0],
    };

    if (onSwitchAndFilter.length > 0) {
      localStorageData.switch_and = onSwitchAndFilter;
    } else {
      localStorageData.switch_and = [];
    }

    changeLocalStorageFilters(localStorageData);
  }, [
    formData,
    containsFilter,
    orContainsFilter,
    selectedFilters,
    conditions,
    orConditions,
    formDataOrFilters,
    orFilters,
    searchValue,
    orderBy,
    orderDir,
    onSwitchAndFilter,
  ]);

  useEffect(() => {
    if (!filtersQuery && project_id && storeFilterName) {
      setFiltersQuery({
        filters: {},
        conditions: {},
        search: '',
        order_by: '',
        order_dir: '',
        switch_and: [],
        project_id: +project_id,
        name: storeFilterName,
      });
    }
  }, [project_id, storeFilterName]);

  const handleChangeSearch = (value: string) => {
    dispatch(
      saveStoreRequestsFilters({
        data: {
          order_by: orderBy,
          order_dir: orderDir,
          search: value,
        },
        name: storeFilterName,
      }),
    );
    setSearchValue(value);

    if (setOuterSearchValue) {
      setOuterSearchValue(value);
    }

    handleGlobalSearchMenu('open');
  };

  const handleApplySearch = () => {
    onChangeFilters(null);
  };

  const handleSwitchAndFilter = (filterName: string, operatorFilter: 'AND' | 'OR', isClearAll?: boolean) => {
    const newOnSwitchAndFilter = [...onSwitchAndFilter];
    const newOnSwitchAndFilterIndex = newOnSwitchAndFilter.findIndex(item => item == filterName);
    if (newOnSwitchAndFilterIndex > -1) {
      newOnSwitchAndFilter.splice(newOnSwitchAndFilterIndex, 1);
    } else {
      !isClearAll && newOnSwitchAndFilter.push(filterName);
    }
    setOnSwitchAndFilter(newOnSwitchAndFilter);

    const formTypedData = operatorFilter === 'AND' ? formData : formDataOrFilters;
    let savedFiltersData;
    const newTypeFormData = {
      ...formTypedData,
    };
    if (operatorFilter === 'AND') {
      savedFiltersData = { filters: newTypeFormData, switch_and: newOnSwitchAndFilter };
    } else {
      savedFiltersData = { filters: formData, or_filters: newTypeFormData, switch_and: newOnSwitchAndFilter };
    }

    dispatch(saveStoreRequestsFilters({ data: savedFiltersData, name: storeFilterName }));
  };

  const handleChangeFilterSelect = (values: any[], filterName: string, operatorFilter: 'AND' | 'OR') => {
    const formTypedData = operatorFilter === 'AND' ? formData : formDataOrFilters;
    const setFormTypedData = operatorFilter === 'AND' ? setFormData : setFormDataOrFilters;
    let savedFiltersData;

    const newTypeFormData = {
      ...formTypedData,
      [filterName]: values,
    };

    if (!values.length) {
      handleSwitchAndFilter(filterName, operatorFilter, true);
      delete newTypeFormData[filterName];
    }

    setFormTypedData(newTypeFormData);

    if (operatorFilter === 'AND') {
      savedFiltersData = { filters: newTypeFormData, order_by: orderBy, order_dir: orderDir, search: searchValue };
    } else {
      savedFiltersData = { filters: formData, or_filters: newTypeFormData, order_by: orderBy, order_dir: orderDir, search: searchValue };
    }

    dispatch(saveStoreRequestsFilters({ data: savedFiltersData, name: storeFilterName }));

    if (filterName === 'ufc') {
      handleSelectCondition(filterName, ['TYPE'], operatorFilter);
      handleChangeContains(values[0]?.typeFilter, 'TYPE', filterName, operatorFilter);
    }
  };

  const handleCloseSelect = () => {
    const newData = selectedFilters.reduce((acc, filter) => {
      if (formData[filter]) {
        acc[filter] = formData[filter];
      }

      return acc;
    }, {} as KeyValueModel<any>);

    setFormData(newData);
    const isFiltersNotChanged = isEqual(newData, !formData?.length ? {} : formData);

    if (isFiltersNotChanged) {
      return;
    }

    onChangeFilters({ filters: newData });
  };

  const handleChangeFilters = (values: string[]) => {
    const typeFilters = isOrFilter ? [...orFilters] : [...selectedFilters];
    const setTypeFilters = isOrFilter ? setOrFilters : setSelectedFilters;
    const storeNameFilters = isOrFilter ? `${storeFilterName}_orFilters` : storeFilterName;
    const aliasTypeFilter = isOrFilter ? 'OR' : 'AND';

    const removedFilterValues = difference(typeFilters, values);

    if (removedFilterValues.length) {
      handleRemoveFilter(removedFilterValues[0], aliasTypeFilter);
    }

    setTypeFilters(values);
    dispatch(saveStoreMoreRequestsFilters({ data: values, name: storeNameFilters }));
  };

  const onResetSearch = () => {
    const localStorageData = JSON.parse(localStorage.getItem(`${storeFilterName}`));

    localStorage.setItem(
      `${storeFilterName}`,
      JSON.stringify({
        ...localStorageData,
        searchValue: '',
      }),
    );

    dispatch(saveStoreRequestsFilters({ data: { order_by: orderBy, order_dir: orderDir, search: '' }, name: storeFilterName }));
    setSearchValue('');
    onChangeFilters(null, null, true);
  };

  const handleChangeSelectedBuilding = (item: DropdownItemModel) => {
    setSelectedBuildingId && setSelectedBuildingId(+item.value as number);
  };

  const handleChangeGrouping = (values: DropdownItemModel[]) => {
    setSelectedGroupingValues(values);
    onChangeGrouping && onChangeGrouping(values.map(value => value.value) as string[]);
  };

  const handleChangeSorting = (values: SortingDataModel) => {
    setSortingInfo(values);
    setOrderBy(values?.order_by || '');
    setOrderDir(values?.order_dir || '');
    dispatch(
      saveStoreRequestsFilters({
        data: {
          order_by: values?.order_by,
          order_dir: values?.order_dir,
          search: searchValue,
        },
        name: storeFilterName,
      }),
    );
    onChangeFilters({ sortingInfo: values });
    onChangeSorting && onChangeSorting(values);
  };

  const handleOnCloseFilterSelect = () => {
    onChangeFilters(null);
  };

  const handleGlobalSearchMenu = (action: 'open' | 'close') => {
    const newGlobalSearchMenuOpenState = action === 'open';

    setIsGlobalSearchMenuOpen(newGlobalSearchMenuOpenState);
  };

  const changeLocalStorageFilters = (filters: KeyValueModel<any>) => {
    const localStorageData = JSON.parse(localStorage.getItem(`${storeFilterName}`));
    localStorage.setItem(
      `${storeFilterName}`,
      JSON.stringify({
        ...(localStorageData || {}),
        ...filters,
        building_id:
          storeFilterName === 'deliverable'
            ? +localStorage.getItem('pcd_current_building_id')
            : +selectedBuildingId || selectedBuildingId === 0
            ? filters?.building_id
            : undefined,
      }),
    );
  };

  const onChangeFilters = (
    changeFilters?: OnChangeFiltersModel,
    isResetFilters?: boolean,
    isResetSearch?: boolean,
    condition?: string,
    isFirstRender?: boolean,
    switch_and?: string[],
  ) => {
    if (!project_id) {
      return;
    }

    if (
      !condition &&
      nfSections &&
      pcdSections &&
      (nfSections.map(item => item.id).includes('request_custom') || pcdSections.map(item => item.id).includes('deliverable_custom'))
    ) {
      const tabTypes = ['request', 'deliverable'];

      const newTabs = tabTypes.reduce((acc, tabType) => {
        acc = {
          ...acc,
          [tabType]: {
            id: `${tabType}_custom`,
            title: 'Search results',
            isCustom: true,
            subSections: [
              {
                id: `${tabType}_custom_search`,
                title: 'Search results',
              },
            ],
          },
        };

        return acc;
      }, {});

      const customFilters = tabTypes.reduce((acc, tabType) => {
        acc = {
          ...acc,
          [tabType]: {
            search: isResetSearch ? '' : searchValue,
          },
        };

        return acc;
      }, {});

      setSandBoxCustomFilterTab(newTabs, globalSearchMenuType, customFilters, isResetSearch ? '' : searchValue);
    }

    const newSearchValue = Object.keys(changeFilters || {}).includes('searchValue')
      ? changeFilters?.searchValue
      : isResetSearch
      ? ''
      : isFirstRender
      ? savedRequestsFilters[storeFilterName]?.search === ''
        ? undefined
        : savedRequestsFilters[storeFilterName]?.search
      : searchValue?.trim() || '';
    const newFilters = FiltersHelper.convertFiltersValueObjectsToIds(changeFilters?.filters || formData);
    const newFiltersConditions = FiltersHelper.convertConditionsFilters(changeFilters?.filters || containsFilter);
    const newQuery: GlobalFiltersQueryModel = {
      filters: convertFilterNameForApi(newFilters, filterColumns),
      conditions: newFiltersConditions,
      search: newSearchValue || '',
      order_by: isResetFilters ? '' : changeFilters?.sortingInfo?.order_by || orderBy || '',
      order_dir: isResetFilters ? '' : changeFilters?.sortingInfo?.order_dir || orderDir || '',
      project_id: +project_id,
      name: storeFilterName,
      switch_and: [],
    };

    const localStorageFiltersToSave: { orderBy: string | number; orderDir: string; searchValue: string; building_id?: number } = {
      orderBy: isResetFilters ? '' : changeFilters?.sortingInfo?.order_by || orderBy || '',
      orderDir: isResetFilters ? '' : changeFilters?.sortingInfo?.order_dir || orderDir || '',
      searchValue: newSearchValue,
    };

    if (Object.keys(formDataOrFilters).length > 0) {
      const newOrFilters = FiltersHelper.convertFiltersValueObjectsToIds(changeFilters?.orFilters || formDataOrFilters);
      newQuery.or_filters = convertFilterNameForApi(newOrFilters, filterColumns);
    }

    if (Object.keys(orContainsFilter).length > 0) {
      const newOrFiltersConditions = FiltersHelper.convertConditionsFilters(changeFilters?.newOrContainsFilter || orContainsFilter);
      newQuery.or_conditions = newOrFiltersConditions;
    }

    if (onSwitchAndFilter.length > 0) {
      newQuery.switch_and = switch_and ? switch_and : onSwitchAndFilter;
    }

    if (+selectedBuildingId || selectedBuildingId === 0) {
      newQuery.building_id = +selectedBuildingId;
      localStorageFiltersToSave.building_id = +selectedBuildingId;
    }

    const isFiltersNotChanged = isEqual(newQuery, filtersQuery) && !defaultFilter && !isResetFilters && !isResetSearch && !isFirstRender;
    if (
      isFiltersNotChanged &&
      (!storeFilterName.includes('sandbox') && storeFilterName.includes('need_form') ? !isFirstNeedListLoading : true)
    ) {
      return;
    }

    if (
      !newQuery?.search &&
      isEmptyObject(newQuery?.filters) &&
      isEmptyObject(newQuery?.conditions) &&
      !newQuery?.order_by &&
      !isFirstRender
    ) {
      resetFiltersHandler && resetFiltersHandler();
    }
    setFiltersQuery(newQuery);
    changeLocalStorageFilters(localStorageFiltersToSave);
    if (storeFilterName.includes('sandbox')) {
      filtersChangeHandler && filtersChangeHandler(newQuery, undefined, isFirstRender);
    } else {
      filtersChangeHandler && filtersChangeHandler(newQuery);
    }

    handleGlobalSearchMenu('close');
  };

  const handleResetFilters = (isResetFilter?: boolean) => {
    setSearchValue('');
    setOrderBy('');
    setOrderDir('');
    setOnSwitchAndFilter([]);
    setContainsFilter({});
    setOrContainsFilter({});
    setOrFilters([]);
    setFormDataOrFilters({});
    setIsOrFilter(false);
    setSortingInfo({
      order_by: '',
      order_dir: '',
    });

    onChangeFilters(
      {
        filters: {},
        orFilters: {},
        newContainsFilter: {},
        newOrContainsFilter: {},
        searchValue: '',
        sortingInfo: {
          order_by: '',
          order_dir: '',
        },
      },
      isResetFilter,
    );
    handleChangeGrouping([]);

    dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_condition`, data: [] }));
    dispatch(saveStoreRequestsFilters({ data: {}, name: `${storeFilterName}_condition`, isStrictChange: true }));
    dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_or_condition`, data: [] }));
    dispatch(saveStoreRequestsFilters({ data: {}, name: `${storeFilterName}_or_condition`, isStrictChange: true }));
    dispatch(saveStoreMoreRequestsFilters({ name: storeFilterName, data: [] }));
    dispatch(saveStoreRequestsFilters({ data: {}, name: storeFilterName, isStrictChange: true }));
    dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_orFilters`, data: [] }));
    hideSaveFiltersButton && hideSaveFiltersButton();
  };

  const handleRemoveFilter = async (filterName, operatorFilter) => {
    const newFormData = { ...formData };
    const newSelectedFilters = [...selectedFilters];
    const newContainsFilter = { ...containsFilter };
    const newOrFilters = [...orFilters];
    const newOrContainsFilter = { ...orContainsFilter };
    const newOnSwitchAndFilter = [...onSwitchAndFilter];
    const newOnSwitchAndFilterIndex = newOnSwitchAndFilter.findIndex(item => item == filterName);
    if (newOnSwitchAndFilterIndex > -1) {
      newOnSwitchAndFilter.splice(newOnSwitchAndFilterIndex, 1);
      setOnSwitchAndFilter(newOnSwitchAndFilter);
    }

    if (operatorFilter === 'AND') {
      const removedIndex = newSelectedFilters.findIndex(f => f === filterName);
      if (removedIndex > -1) {
        newSelectedFilters.splice(removedIndex, 1);
      }
      delete newFormData[filterName];
      delete newContainsFilter[filterName];

      setSelectedFilters(newSelectedFilters);
      setFormData(newFormData);
      setContainsFilter(newContainsFilter);

      if (newSelectedFilters.length === 0) {
        setIsOrFilter(false);
        setFormDataOrFilters({});
        setOrFilters([]);
        setContainsFilter({});
        setOrContainsFilter({});

        dispatch(saveStoreRequestsFilters({ data: {}, name: `${storeFilterName}_or_condition` }));
        dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_or_condition`, data: [] }));
      }

      dispatch(saveStoreRequestsFilters({ data: newContainsFilter, name: `${storeFilterName}_condition`, isStrictChange: true }));
      dispatch(
        saveStoreRequestsFilters({
          data: {
            filters: newFormData,
            order_by: orderBy,
            order_dir: orderDir,
            search: searchValue,
            switch_and: newOnSwitchAndFilter,
          },
          name: storeFilterName,
        }),
      );
      dispatch(saveStoreMoreRequestsFilters({ name: storeFilterName, data: newSelectedFilters }));
      dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_condition`, data: newContainsFilter }));
      dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_orFilters`, data: [] }));

      onChangeFilters({ filters: newFormData, newContainsFilter }, null, null, null, null, newOnSwitchAndFilter);

      setConditions(newContainsFilter);
    } else {
      const newFormDataOrFilters = { ...formDataOrFilters };
      const removedIndex = newOrFilters.findIndex(f => f === filterName);
      if (removedIndex > -1) {
        newOrFilters.splice(removedIndex, 1);
      }
      delete newFormDataOrFilters[filterName];
      delete newOrContainsFilter[filterName];

      setFormDataOrFilters(newFormDataOrFilters);
      setOrFilters(newOrFilters);
      setOrContainsFilter(newOrContainsFilter);

      if (newOrFilters.length === 0) {
        setFormDataOrFilters({});
        setOrFilters([]);
        setIsOrFilter(false);
        setOrContainsFilter({});
      }

      dispatch(saveStoreRequestsFilters({ data: newOrContainsFilter, name: `${storeFilterName}_or_condition` }));
      dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_or_condition`, data: newOrContainsFilter }));
      dispatch(
        saveStoreRequestsFilters({
          data: {
            or_filters: newFormDataOrFilters,
            order_by: orderBy,
            order_dir: orderDir,
            search: searchValue,
            switch_and: newOnSwitchAndFilter,
          },
          name: storeFilterName,
        }),
      );
      dispatch(saveStoreMoreRequestsFilters({ name: `${storeFilterName}_orFilters`, data: newOrFilters }));

      onChangeFilters({ orFilters: newOrFilters, newOrContainsFilter }, null, null, null, null, newOnSwitchAndFilter);

      setOrConditions(newOrContainsFilter);
    }
  };

  const handleRemoveDate = async (filterName, filterId) => {
    const newTypeContainsFilter = isOrFilter ? { ...orContainsFilter } : { ...containsFilter };
    const setTypeContainsFilter = isOrFilter ? setOrContainsFilter : setContainsFilter;
    const saveStoreName = isOrFilter ? `${storeFilterName}_or_condition` : `${storeFilterName}_condition`;

    delete newTypeContainsFilter[filterName][filterId];

    if (Object.keys(newTypeContainsFilter[filterName]).length === 0) {
      delete newTypeContainsFilter[filterName];
      setTypeContainsFilter(newTypeContainsFilter);
    } else {
      setContainsFilter(newTypeContainsFilter);
    }

    saveStoreRequestsFilters({ data: newTypeContainsFilter, name: saveStoreName });
  };

  const onChangeHeight = (width?: number, height?: number) => {
    heightChangeHandler && heightChangeHandler(height);
  };

  const handleSelectCondition = (filterName, filtersAlias, filterOperator) => {
    let newTypeConditions = filterOperator === 'OR' ? { ...orConditions } : { ...conditions };
    const setNewTypeConditions = filterOperator === 'OR' ? setOrConditions : setConditions;
    const saveStoreName = filterOperator === 'OR' ? `${storeFilterName}_or_condition` : `${storeFilterName}_condition`;

    compareActiveConditions(filterName, filtersAlias);

    newTypeConditions = {
      ...newTypeConditions,
      [filterName]: filtersAlias,
    };

    dispatch(saveStoreMoreRequestsFilters({ name: saveStoreName, data: newTypeConditions }));

    setNewTypeConditions(newTypeConditions);
  };

  const handleChangeContains = (value, filterId, filterName, filterOperator) => {
    let newTypeContainsFilter = filterOperator === 'OR' ? { ...orContainsFilter } : { ...containsFilter };
    const setTypeContainsFilter = filterOperator === 'OR' ? setOrContainsFilter : setContainsFilter;
    const saveStoreName = filterOperator === 'OR' ? `${storeFilterName}_or_condition` : `${storeFilterName}_condition`;
    const resValue = value;

    newTypeContainsFilter = {
      ...newTypeContainsFilter,
      [filterName]: {
        ...newTypeContainsFilter[filterName],
        [filterId]: resValue,
      },
    };

    dispatch(saveStoreRequestsFilters({ name: saveStoreName, data: newTypeContainsFilter || [] }));

    setTypeContainsFilter(newTypeContainsFilter);
  };

  const compareActiveConditions = (filterName, filtersAlias) => {
    const newTypeContainsFilter = {};
    const typeContainsFilter = isOrFilter ? orContainsFilter : containsFilter;
    const setTypeContainsFilter = isOrFilter ? setOrContainsFilter : setContainsFilter;
    const saveStoreName = isOrFilter ? `${storeFilterName}_or_condition` : `${storeFilterName}_condition`;

    filtersAlias.forEach(f => {
      if (!typeContainsFilter[filterName]) {
        return;
      }
      newTypeContainsFilter[f] = typeContainsFilter[filterName][f];
    });

    const containsTypeFilterState = {
      ...typeContainsFilter,
      [filterName]: newTypeContainsFilter,
    };

    dispatch(saveStoreRequestsFilters({ name: saveStoreName, data: containsTypeFilterState || [] }));

    setTypeContainsFilter(containsTypeFilterState);
  };

  const renderCustomGroupingBasis = (isActive: boolean, disabled: boolean) => {
    let title = '';

    if (isBulkEditMode) {
      title = 'You need to disable edit mode.';
    }

    if (isDisableGrouping) {
      title = 'Almost there: Feature accessible after the database is downloaded.';
    }

    return (
      <CustomTooltip title={title} placement={'top'} enterDelay={500}>
        <FilterButton
          isMobile={isMobile}
          type={'group'}
          isActive={!!selectedGroupingValues?.length}
          isPressed={isActive}
          disabled={disabled}
        />
      </CustomTooltip>
    );
  };

  const renderCustomFilterSwitcherBasis = (isActive: boolean, disabled: boolean, isLoading: boolean, selected: DropdownItemModel) => {
    return <FilterSelectBasis isMobile={isMobile} title={selected?.title || 'Building'} disabled={disabled} isActive={isActive} />;
  };

  const changeOrBtn = () => {
    if (!isOrFilter) {
      setIsOrFilter(!isOrFilter);
    }
  };

  const getSelectedFilterCount = (filterName: string, filterOperator: 'AND' | 'OR') => {
    const formTypeData = filterOperator === 'AND' ? formData : formDataOrFilters;
    const containsTypeFilters = filterOperator === 'AND' ? containsFilter : orContainsFilter;
    const formTypeDataLength = (formTypeData[filterName] && formTypeData[filterName].length) || 0;
    const containsTypeFiltersLength = Object.values(containsTypeFilters[filterName] || {}).filter(f => !!f).length || 0;

    if (filterName === 'hashtags') {
      return containsTypeFiltersLength + formTypeDataLength;
    }

    return containsTypeFiltersLength ? containsTypeFiltersLength : formTypeDataLength;
  };

  const getGroupsByGroupedByFilters = (filterName: string) => {
    if (!groupedByFilters || !buildingsForCurrentProject.length) {
      return null;
    }

    if (groupedByFilters.buildings && groupedByFilters.buildings.includes(filterName)) {
      return [{ value: 0, title: 'No building' }, ...(buildingMenuItems || [])];
    }

    return null;
  };

  const formatFilterValuesByGroups = (filterName: string, filterValues: any[]) => {
    if (!groupedByFilters) {
      return filterValues;
    }

    if (groupedByFilters.buildings && groupedByFilters.buildings.includes(filterName)) {
      return filterValues.map(filterValue => {
        const attachedToFilterValueBuildingId = filterValue.building_id;

        return {
          ...filterValue,
          group: attachedToFilterValueBuildingId || 0,
        };
      });
    }

    return filterValues;
  };

  const formatFilterValuesToSelect = (filterValues: any[]) => {
    if (!filterValues?.length) {
      return [];
    }

    return filterValues.map(filterValue => {
      return {
        ...filterValue,
        value: filterValue.id,
      };
    });
  };

  const renderFilterSelectMenuItem = (item: RenderItemFilterModel, filterName: string) => {
    if (filterName === 'hashtags') {
      return <ChipsColorTag color={item.color || 'default'} value={item.title} />;
    }

    if (
      filterName === 'comment_mention' ||
      filterName === 'incorporated_subtask_authors' ||
      filterName === 'deliverable_author' ||
      filterName === 'watchers' ||
      filterName === 'watcher' ||
      filterName === 'comment_author' ||
      filterName === 'nf_action_by'
    ) {
      return (
        <div className={`${themeClass}_commentItem`}>
          <div className={`${themeClass}_commentItem_title`}>{item?.title}</div>
          {item?.parties && (
            <div className={`${themeClass}_commentItem_companyBlock`}>
              <Placeholder type={'Company'} imagePath={item?.parties[0]?.logo} size={24} />
              <div className={`${themeClass}_commentItem_companyBlock_companyName`}>{item?.parties[0]?.company}</div>
            </div>
          )}
        </div>
      );
    }

    if (
      filterName === 'authors' ||
      filterName === 'users' ||
      filterName === 'response_managers' ||
      filterName === 'commitment_users' ||
      filterName === 'responsible_user' ||
      filterName === 'submitted_by' ||
      filterName === 'distributed_by' ||
      filterName === 'contractor_user'
    ) {
      return (
        <div className={`${themeClass}_commentItem`}>
          <div className={`${themeClass}_commentItem_title`}>{item?.title}</div>
          <div className={`${themeClass}_commentItem_companyBlock`}>
            <div className={`${themeClass}_commentItem_companyBlock_companyName`}>{item?.company}</div>
          </div>
        </div>
      );
    }

    return item.title;
  };

  const renderFilters = (filterValues: string[], filterOperator: 'AND' | 'OR') => {
    if (!project_id) {
      return null;
    }

    return (
      <>
        {filterValues.map(filterName => {
          const filterData = filters.find(f => f.filterField === filterName);
          const groups = getGroupsByGroupedByFilters(filterName);
          const formattedToSelectViewFilters = formatFilterValuesToSelect(formattedFilters?.[filterName] || []);
          const formattedByGroupsValues = formatFilterValuesByGroups(filterName, formattedToSelectViewFilters);
          const totalItemCount = getSelectedFilterCount(filterName, filterOperator);
          const formTypeData = filterOperator === 'AND' ? formData : formDataOrFilters;
          let selectedValues = formTypeData && formTypeData[filterName];

          // Added this logic because when we use saved filters we get array that does not contain object values
          // instead of this we get from BE array of id(string or number value)
          // so decided to map them into such object
          if (selectedValues?.some(item => typeof item !== 'object')) {
            if (filterName === 'hashtags') {
              selectedValues = formattedByGroupsValues.filter(item => selectedValues.find(value => value === item.id));
            } else {
              selectedValues = selectedValues.map(item => {
                return {
                  id: item,
                };
              });
            }
          }

          const shouldRenderMenuItem = ['hashtags', 'comment_mention'].includes(filterName);
          const shouldRenderSelectWithCondition = ['hashtags'].includes(filterName);
          const isConditionFilter = !!filterData?.conditionType;
          const isActiveOverdue =
            filterName === 'due_date' && formTypeData[filterName]?.length > 0 && formTypeData[filterName][0]?.id === 'expired';
          const isSwitchAndButton = !!filterData?.isSwitchAnd;
          const isOnSwitchAndFilter = !!onSwitchAndFilter?.find(item => item === filterName);

          return (
            <>
              {isConditionFilter && (
                <div className={`${themeClass}_selectedFilter`}>
                  <ConditionSelector<DropdownItemModelExt, false>
                    conditionType={filterData.conditionType}
                    menuItems={formattedByGroupsValues}
                    selectHandler={() => null}
                    menuItemTitleRenderer={shouldRenderMenuItem ? item => renderFilterSelectMenuItem(item, filterName) : null}
                    selected={selectedValues || []}
                    menuItemSize={'md'}
                    onMenuClose={handleOnCloseFilterSelect}
                    handleSelectCondition={handleSelectCondition}
                    handleChangeContains={handleChangeContains}
                    containsFilter={filterOperator === 'AND' ? containsFilter : orContainsFilter}
                    filterName={filterName}
                    handleChangeFilterSelect={handleChangeFilterSelect}
                    filterOperator={filterOperator}
                    isActiveOverdue={isActiveOverdue}
                    isHighResolution
                    renderCustomBasis={isActive => {
                      return (
                        <ChipsFilterOption
                          title={filterData?.title}
                          count={totalItemCount}
                          isPressed={isActive}
                          isActive={totalItemCount > 0}
                          isMobile={isMobile}
                          onClickClose={() => {
                            handleRemoveFilter(filterName, filterOperator);
                          }}
                        />
                      );
                    }}
                    renderSelect={() => {
                      if (shouldRenderSelectWithCondition) {
                        return (
                          <Select<DropdownItemModel, DropdownGroupItem, true>
                            isMulti
                            isHeader
                            menuItems={formattedByGroupsValues}
                            selectHandler={values => handleChangeFilterSelect(values, filterName, filterOperator)}
                            menuItemTitleRenderer={shouldRenderMenuItem ? item => renderFilterSelectMenuItem(item, filterName) : null}
                            selected={selectedValues || []}
                            groups={groups}
                            menuGroupingType={'labeled'}
                            menuItemSize={'md'}
                            isMobile={isMobile}
                            customHeaderClass={isMobile ? `${themeClass}_customHeaderClassMobile` : ''}
                            basisPlaceholder={'Select hashtags'}
                            isSwitchAndButton={isSwitchAndButton}
                            isOnSwitchAndFilter={isOnSwitchAndFilter}
                            handleSwitchAndFilter={() => handleSwitchAndFilter(filterName, filterOperator)}
                          />
                        );
                      }
                    }}
                  />
                </div>
              )}
              {!!codeSelectorTypeByFilter[filterName] && !isConditionFilter && (
                <div className={`${themeClass}_selectedFilter`}>
                  <CodeSelector<null, true>
                    isMulti
                    showFooterType={storeFilterName?.includes('need_form') && filterData.isShowType ? 'checkBox-group' : null}
                    codeType={codeSelectorTypeByFilter[filterName]}
                    handleFor={codeSelectorHandlerType}
                    selected={selectedValues || []}
                    selectedType={containsFilter?.ufc?.TYPE}
                    selectHandler={values => handleChangeFilterSelect(values, filterName, filterOperator)}
                    onMenuClose={handleOnCloseFilterSelect}
                    menuItemSize={'md'}
                    selectedBuildingIds={+selectedBuildingId ? [+selectedBuildingId] : null}
                    isMobile={isMobile}
                    isHighResolution
                    renderCustomBasis={isActive => {
                      return (
                        <ChipsFilterOption
                          title={filterData?.title}
                          count={totalItemCount}
                          isPressed={isActive}
                          isActive={totalItemCount > 0}
                          isMobile={isMobile}
                          onClickClose={() => handleRemoveFilter(filterName, filterOperator)}
                        />
                      );
                    }}
                  />
                </div>
              )}
              {!codeSelectorTypeByFilter[filterName] && !isConditionFilter && (
                <div className={`${themeClass}_selectedFilter`}>
                  <Select<DropdownItemModel, DropdownGroupItem, true>
                    isMulti
                    isHeader
                    menuItems={formattedByGroupsValues}
                    selectHandler={values => handleChangeFilterSelect(values, filterName, filterOperator)}
                    menuItemTitleRenderer={true ? item => renderFilterSelectMenuItem(item, filterName) : null}
                    selected={selectedValues || []}
                    groups={groups}
                    menuGroupingType={'labeled'}
                    menuItemSize={'md'}
                    onMenuClose={handleOnCloseFilterSelect}
                    isMobile={isMobile}
                    customHeaderClass={isMobile ? `${themeClass}_customHeaderClassMobile` : ''}
                    isSwitchAndButton={isSwitchAndButton}
                    isOnSwitchAndFilter={isOnSwitchAndFilter}
                    handleSwitchAndFilter={() => handleSwitchAndFilter(filterName, filterOperator)}
                    isHighResolution
                    renderCustomBasis={isActive => {
                      return (
                        <ChipsFilterOption
                          title={filterData?.title}
                          count={totalItemCount}
                          isPressed={isActive}
                          isActive={totalItemCount > 0}
                          isMobile={isMobile}
                          onClickClose={() => handleRemoveFilter(filterName, filterOperator)}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </>
          );
        })}
      </>
    );
  };

  const renderSelectedFilters = () => {
    return (
      <div
        className={cn(`${themeClass}_selectedFilters`, {
          ['-sandboxPaddingLeft']: storeFilterName.includes('sandbox') && !storeFilterName.includes('report'),
          ['-sandboxPaddingLeftSmall']: storeFilterName.includes('sandbox') && !storeFilterName.includes('report') && isCollapsedBtn,
        })}
      >
        {renderFilters(selectedFilters, 'AND')}
        {isOrFilter && orFilters?.length > 0 && <div className={`${themeClass}_orText`}>Or</div>}
        {renderFilters(orFilters, 'OR')}
      </div>
    );
  };

  const renderSearchField = () => {
    let title = '';

    if (isDisableSearch) {
      title = 'Almost there: Feature accessible after the database is downloaded.';
    }

    return (
      <CustomTooltip title={title} placement={'top'} enterDelay={500}>
        <div ref={isMobile ? searchMobileRef : undefined} className={`${isMobile ? `${themeClass}_searchFieldMobile` : ''}`}>
          <TextInputNew
            ref={searchRef}
            isSearch
            type={'on-srf'}
            size={'md'}
            placeholder={'Search'}
            isMobile={isMobile}
            onChange={handleChangeSearch}
            value={searchValue}
            disabled={isFiltersDisabled}
            onReset={onResetSearch}
            isResetBtn={!!searchValue}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleApplySearch();
                isMobile && handleCloseSearchMobile();
              }
            }}
          />
        </div>
      </CustomTooltip>
    );
  };

  const renderSortingField = () => {
    let title = '';

    if (isDisableSorting) {
      title = 'Almost there: Feature accessible after the database is downloaded.';
    }

    return (
      <CustomTooltip title={title} placement={'top'} enterDelay={500}>
        <div className={`${themeClass}_controllers_sortingField`}>
          <SortingField
            disabled={isFiltersDisabled}
            values={sortingOptions || []}
            onSort={handleChangeSorting}
            sortInfo={sortingInfo}
            isHighResolution
          />
        </div>
      </CustomTooltip>
    );
  };

  const renderFiltersField = () => {
    let title = '';

    if (isDisableFilters) {
      title = 'Almost there: Feature accessible after the database is downloaded.';
    }

    return (
      <CustomTooltip title={title} placement={'top'} enterDelay={500}>
        <div className={`${themeClass}_controllers_filterField`}>
          <FilterSelector
            disabled={isFiltersDisabled}
            isActive={filterSelectorActive}
            groupList={filterSelectorGroups}
            values={filterSelectorOptions}
            onClickOrBtn={changeOrBtn}
            isOrFilter={isOrFilter}
            selectedIds={selectedFilterSelectorOptions}
            resetFilters={handleResetFilters}
            selectFilters={handleChangeFilters}
            showOrButton={showOrFilters}
            orButtonActive={!!selectedFilters?.length}
            onMenuClose={handleCloseSelect}
            isMobile={isMobile}
            handleSelectedFilter={handleSelectedFilter}
            selectedFilter={selectedFilterSelector}
            isHighResolution
          />
        </div>
      </CustomTooltip>
    );
  };

  if (isNotDisplayed) {
    return <></>;
  }

  return (
    <>
      <ReactResizeDetector handleHeight skipOnMount refreshMode="throttle" onResize={onChangeHeight} />
      {isOpenSearchMobile ? (
        renderSearchField()
      ) : (
        <>
          {showSearch && (
            <div ref={searchRef} className={cn(`${themeClass}_searchField`, { ['-mobile']: isMobile })}>
              {isMobile ? (
                <SystemButton type={'search'} size={'lg'} variant={'transparent'} isMobile clickHandler={handleOpenSearchMobile} />
              ) : (
                renderSearchField()
              )}
            </div>
          )}
          <>
            {showControllers && (
              <div
                className={cn(`${themeClass}_controllers`, {
                  ['-sandboxPaddingLeftSmall']:
                    storeFilterName.includes('sandbox') &&
                    !storeFilterName.includes('report') &&
                    isCollapsedBtn &&
                    selectedFilters?.length > 0,
                  ['-mobile']: isMobile,
                })}
              >
                {showBuildingSelector && (
                  <div className={`${themeClass}_controllers_buildingSelector`}>
                    <Select<DropdownItemModel, null, false>
                      menuItems={buildingMenuItems}
                      selectHandler={handleChangeSelectedBuilding}
                      selected={selectedBuildingMenuItem}
                      renderCustomBasis={renderCustomFilterSwitcherBasis}
                      menuItemSize={'md'}
                      disabled={!buildingMenuItems.length}
                      isMobile={isMobile}
                      isHighResolution
                    />
                  </div>
                )}
                {storeFilterName.includes('sandbox') && !storeFilterName.includes('report') && setRelation && !isMobile && (
                  <SandBoxRelationSwitcher relatedTo={relatedTo} setRelation={setRelation} />
                )}
                {showSorting && renderSortingField()}
                {showGrouping && (
                  <div className={`${themeClass}_controllers_groupingField`}>
                    <Select<DropdownItemModel, null, true>
                      isMulti
                      menuItems={groupingOptions || []}
                      selectHandler={handleChangeGrouping}
                      selected={selectedGroupingValues}
                      renderCustomBasis={renderCustomGroupingBasis}
                      menuItemSize={'md'}
                      disabled={isBulkEditMode || isFiltersDisabled}
                      isMobile={isMobile}
                      isHighResolution
                    />
                  </div>
                )}
                {showFilter && renderFiltersField()}
              </div>
            )}
          </>
          {!!selectedFilters?.length && renderSelectedFilters()}
        </>
      )}
    </>
  );
}

export default PageFilters;
