import React, { Component } from 'react';
import styles from './slider.scss';
import Slider, { Range, Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from '@material-ui/core/Tooltip';

class CustomSlider extends Component {
  handleSlider = props => {
    const { value, dragging, index, openTooltip, ...restProps } = props;
    let title = '';
    if (dragging || this.props.openTooltip) {
      value === 1 ? (title = this.props.steps?.step1) : null;
      value === 2 ? (title = this.props.steps?.step2) : null;
      value === 3 ? (title = this.props.steps?.step3) : null;
      return (
        <Tooltip title={title} open="true">
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    } else {
      return <Handle {...restProps} />;
    }
  };

  handleStep = step => {
    if (step === 1 || step === 'weekMode') return 1;
    if (step === 2 || step === 'monthMode') return 2;
    if (step === 3 || step === 'yearMode') return 3;
    return 1;
  };

  render() {
    const { onChange, step } = this.props;

    return (
      <div className={'slider'}>
        <img src={require('../../../assets/icons/littleRange.png')} alt="" />
        <Slider min={1} max={3} value={this.handleStep(step)} handle={this.handleSlider} onChange={onChange} />
        <img src={require('../../../assets/icons/bigRange.png')} alt="" />
      </div>
    );
  }
}

export default CustomSlider;
