import React, { FC } from 'react';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { TeamApi } from '../../../service/Api/team/types';
import AvatarComponent from '../../controls/Avatar/AvatarComponent';

import '../Profile/ProfileStyles.scss';
import './ProfileViewStyles.scss';

interface IProps {
  handleClose: () => void;
  userData: TeamApi.User;
}

const ProfileView: FC<IProps> = ({ handleClose, userData }) => {
  const userInfo = userData && userData.profile;
  const userAvatar = userInfo.image;
  const currentCompany = userData.parties?.[0]?.title;

  const profileThemeClass = useGetThemeClass('b-profile');
  const profileViewThemeClass = useGetThemeClass('b-profileView');
  const profilePopoverThemeClass = useGetThemeClass('b-profilePopover');

  return (
    <>
      <div className={`${profilePopoverThemeClass}__header`}>
        <div className={`${profilePopoverThemeClass}__left`}>
          <span className={`${profilePopoverThemeClass}__title`}>Profile</span>
          <span className={`${profilePopoverThemeClass}__role`}>
            {`${userData.roles[0].description} ${currentCompany ? 'at' : ''} ${currentCompany || ''}`}
          </span>
        </div>
        <div className={`${profilePopoverThemeClass}__closeBtn`} onClick={handleClose}>
          <TokenIcon iconName={'close'} size={16} />
        </div>
      </div>
      <div className={profileThemeClass}>
        <div className={`${profileThemeClass}__container -popover`}>
          <div className={`${profileThemeClass}__leftColumn -avatar`}>
            <div>
              <AvatarComponent avatar={userAvatar} isAvatarEdit={false} label={''} />
            </div>
          </div>
          <div className={`${profileViewThemeClass}__rightColumn`}>
            <span className={`${profileThemeClass}__title`}>Personal info</span>
            <div className={`${profileViewThemeClass}__inputsColumn`}>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>First Name</span>
                <span className={`${profileViewThemeClass}__text`}>{userInfo.first_name}</span>
              </div>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>Last Name</span>
                <span className={`${profileViewThemeClass}__text`}>{userInfo.last_name}</span>
              </div>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>Title</span>
                <span className={`${profileViewThemeClass}__text`}>{userInfo.title}</span>
              </div>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>Role</span>
                <span className={`${profileViewThemeClass}__text`}>
                  {`${userData.roles[0].description} ${currentCompany ? `${currentCompany} at` : ''}`}
                </span>
              </div>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>Phone number (M)</span>
                <span className={`${profileViewThemeClass}__text`}>{userInfo.phone}</span>
              </div>
              <div className={`${profileViewThemeClass}__input`}>
                <span className={`${profileViewThemeClass}__label`}>Phone number (O)</span>
                <span className={`${profileViewThemeClass}__text`}>{userInfo.phoneO}</span>
              </div>
            </div>
            <div className={`${profileViewThemeClass}__input -email`}>
              <span className={`${profileViewThemeClass}__label`}>Email</span>
              <span className={`${profileViewThemeClass}__text`}>{userData.email}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileView;
