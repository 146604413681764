import { TeamApi } from '../../service/Api/team/types';
import { actionCreator } from '../configure/actionCreater';
import { KeyValueModel } from '../../models/key-value.model';
import { TitleModel } from '../../models';

type actionCreator<T, P = unknown> = {
  type: T;
  payload: P;
};

export type payloadWithCallback<T, P = unknown> = {
  data: T;
  callback?: (params?: P) => void;
};

export namespace Team {
  export type MemberState = {
    isLoading: boolean;
    data: TeamApi.IMember[];
  };

  export type PartyState = {
    isLoading: boolean;
    data: TeamApi.Party[];
  };

  export type DivisionPayload = {
    divisions: {
      create: {
        title: string;
      }[];
      update: {
        id: number;
        title: string;
      }[];
      delete: {
        id: number;
        title: string;
      }[];
    };
  };

  export type GetPartiesPayload = {
    projectId: number;
    search: string;
  };

  export type GetMembersPayload = TeamApi.GetMembersRequest | undefined;

  export type State = {
    isLoadingDisciplinesRoles: boolean;
    isFirstLoadingMembers: boolean;
    disciplines: TeamApi.IDiscipline[];
    roles: TeamApi.Role[];
    members: MemberState;
    parties: PartyState;
    isLoadingParty: boolean;
    usersTeam: TeamApi.UsersTeam;
    isLoadingPartyUsers: boolean;
    isLoadingGetPartyUsers: boolean;
    isUpdatePartyUsers: boolean;
    isGetListSubdivision: boolean;
    isLink_UnlinkPartyUsers: boolean;
    subdivisions: TeamApi.ListSubdivision[];
    errorsTeamTab: TeamApi.ErrorsTeamTab;
    isLoadingAddParty: false | number;
    isSetContactPoint: boolean;
    filters: KeyValueModel<TitleModel>;
    nfCountForParties: TeamApi.NfCountForPartyData;
    isStandardTemplateCreating: boolean;
    addedPartyId: number;
  };

  export enum Actions {
    FETCH_MEMBERS = 'FETCH_MEMBERS',
    FETCH_MEMBERS_CANCEL = 'FETCH_MEMBERS_CANCEL',
    FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS',
    CREATE_PARTY = 'CREATE_PARTY',
    UPDATE_PARTY = 'UPDATE_PARTY',
    FETCH_DISCIPLINES_ROLES = 'FETCH_DISCIPLINES_ROLES',
    FETCH_DISCIPLINES_ROLES_SUCCESS = 'FETCH_DISCIPLINES_ROLES_SUCCESS',
    GET_COUNT_NF_FOR_PARTY = 'GET_COUNT_NF_FOR_PARTY',
    GET_COUNT_NF_FOR_PARTY_SUCCESS = 'GET_COUNT_NF_FOR_PARTY_SUCCESS',
    UPDATE_CREATE_PARTY_SUCCESS = 'UPDATE_CREATE_PARTY_SUCCESS',
    UPDATE_CREATE_PARTY_FAIL = 'UPDATE_CREATE_PARTY_FAIL',
    GET_PARTY_USERS = 'GET_PARTY_USERS',
    GET_PARTY_USERS_SUCCESS = 'GET_PARTY_USERS_SUCCESS',
    SEARCH_USERS_TEAM = 'SEARCH_USERS_TEAM',
    SEARCH_USERS_TEAM_SUCCESS = 'SEARCH_USERS_TEAM_SUCCESS',
    LINK_USER_TO_PROJECT = 'LINK_USER_TO_PROJECT',
    LINK_USER_TO_PROJECT_SUCCESS = 'LINK_USER_TO_PROJECT_SUCCESS',
    UNLINK_USER_TO_PROJECT = 'UNLINK_USER_TO_PROJECT',
    UNLINK_USER_TO_PROJECT_SUCCESS = 'UNLINK_USER_TO_PROJECT_SUCCESS',
    UNLINK_PARTY_FROM_PROJECT = 'UNLINK_PARTY_FROM_PROJECT',
    UNLINK_PARTY_FROM_PROJECT_SUCCESS = 'UNLINK_PARTY_FROM_PROJECT_SUCCESS',
    CREATE_USER_TO_PARTY = 'CREATE_USER_TO_PARTY',
    UPDATE_USER_TO_PARTY = 'UPDATE_USER_TO_PARTY',
    DELETE_USER_TO_PARTY = 'DELETE_USER_TO_PARTY',
    DELETE_UPDATE_CREATE_USER_SUCCESS = 'DELETE_UPDATE_CREATE_USER_SUCCESS',
    UPDATE_CREATE_USER_FAIL = 'UPDATE_CREATE_USER_FAIL',
    GET_LIST_SUBDIVISION = 'GET_LIST_SUBDIVISION',
    GET_LIST_SUBDIVISION_SUCCESS = 'GET_LIST_SUBDIVISION_SUCCESS',
    CLEAR_LIST_SUBDIVISION = 'CLEAR_LIST_SUBDIVISION',
    GET_PARTIES = 'GET_PARTIES',
    GET_PARTIES_CANCEL = 'GET_PARTIES_CANCEL',
    GET_PARTIES_SUCCESS = 'GET_PARTIES_SUCCESS',
    ADD_PARTY = 'ADD_PARTY',
    ADD_PARTY_SUCCESS = 'ADD_PARTY_SUCCESS',
    SET_ADDED_PARTY_ID = 'SET_ADDED_PARTY_ID',
    SET_CONTACT_POINT_PROJECT = 'SET_CONTACT_POINT_PROJECT',
    SET_CONTACT_POINT_PROJECT_SUCCESS = 'SET_CONTACT_POINT_PROJECT_SUCCESS',
    SET_CONTACT_POINT_PARTY = 'SET_CONTACT_POINT_PARTY',
    SET_CONTACT_POINT_PARTY_SUCCESS = 'SET_CONTACT_POINT_PARTY_SUCCESS',
    SET_TEAM_FILTERS = 'SET_TEAM_FILTERS',
    GET_TEAM_FILTERS = 'GET_TEAM_FILTERS',
    GET_TEAM_FILTERS_SUCCESS = 'GET_TEAM_FILTERS_SUCCESS',
    SET_BUYOUT_DATE = 'SET_BUYOUT_DATE',
    SET_BUYOUT_DATE_SUCCESS = 'SET_BUYOUT_DATE_SUCCESS',
    CREATE_TEMPLATE_PARTIES = 'CREATE_TEMPLATE_PARTIES',
    CREATE_TEMPLATE_PARTIES_SUCCESS = 'CREATE_TEMPLATE_PARTIES_SUCCESS',
    CLEAR_STATE = 'CLEAR_STATE',
  }

  export type FetchDisciplinesRolesPayload = { disciplines: TeamApi.IDiscipline[]; roles: TeamApi.Role[] };

  export type ActionTypes =
    | actionCreator<Actions.CLEAR_STATE>
    | actionCreator<Actions.FETCH_MEMBERS, GetMembersPayload>
    | actionCreator<Actions.FETCH_MEMBERS_CANCEL>
    | actionCreator<Actions.FETCH_MEMBERS_SUCCESS, TeamApi.IMember[]>
    | actionCreator<Actions.CREATE_PARTY, payloadWithCallback<TeamApi.CreatePartyRequest, TeamApi.IMember>>
    | actionCreator<Actions.UPDATE_PARTY, payloadWithCallback<TeamApi.UpdatePartyRequest, TeamApi.IMember>>
    | actionCreator<Actions.UPDATE_CREATE_PARTY_SUCCESS, TeamApi.IMember>
    | actionCreator<Actions.UPDATE_CREATE_PARTY_FAIL, TeamApi.IMember>
    | actionCreator<Actions.FETCH_DISCIPLINES_ROLES>
    | actionCreator<Actions.FETCH_DISCIPLINES_ROLES_SUCCESS, FetchDisciplinesRolesPayload>
    | actionCreator<Actions.GET_COUNT_NF_FOR_PARTY, payloadWithCallback<number>>
    | actionCreator<Actions.GET_COUNT_NF_FOR_PARTY_SUCCESS, TeamApi.NfCountForPartyData>
    | actionCreator<Actions.GET_PARTY_USERS>
    | actionCreator<Actions.GET_PARTY_USERS_SUCCESS, any>
    | actionCreator<Actions.SEARCH_USERS_TEAM>
    | actionCreator<Actions.SEARCH_USERS_TEAM_SUCCESS, TeamApi.SearchUsersTeam>
    | actionCreator<Actions.LINK_USER_TO_PROJECT>
    | actionCreator<Actions.LINK_USER_TO_PROJECT_SUCCESS, any>
    | actionCreator<Actions.UNLINK_PARTY_FROM_PROJECT>
    | actionCreator<Actions.UNLINK_PARTY_FROM_PROJECT_SUCCESS, any>
    | actionCreator<Actions.UNLINK_USER_TO_PROJECT>
    | actionCreator<Actions.UNLINK_USER_TO_PROJECT_SUCCESS, any>
    | actionCreator<Actions.CREATE_USER_TO_PARTY, any>
    | actionCreator<Actions.UPDATE_USER_TO_PARTY, any>
    | actionCreator<Actions.DELETE_USER_TO_PARTY, any>
    | actionCreator<Actions.DELETE_UPDATE_CREATE_USER_SUCCESS, any>
    | actionCreator<Actions.UPDATE_CREATE_USER_FAIL, any>
    | actionCreator<Actions.GET_LIST_SUBDIVISION>
    | actionCreator<Actions.GET_LIST_SUBDIVISION_SUCCESS, any>
    | actionCreator<Actions.CLEAR_LIST_SUBDIVISION, any>
    | actionCreator<Actions.GET_PARTIES, GetPartiesPayload>
    | actionCreator<Actions.GET_PARTIES_CANCEL>
    | actionCreator<Actions.GET_PARTIES_SUCCESS, TeamApi.Party[]>
    | actionCreator<Actions.ADD_PARTY, payloadWithCallback<TeamApi.AddPartyRequest, TeamApi.IMember>>
    | actionCreator<Actions.ADD_PARTY_SUCCESS, TeamApi.IMember>
    | actionCreator<Actions.SET_ADDED_PARTY_ID, number>
    | actionCreator<Actions.SET_CONTACT_POINT_PROJECT>
    | actionCreator<Actions.SET_CONTACT_POINT_PROJECT_SUCCESS, any>
    | actionCreator<Actions.SET_CONTACT_POINT_PARTY>
    | actionCreator<Actions.SET_CONTACT_POINT_PARTY_SUCCESS, any>
    | actionCreator<Actions.SET_TEAM_FILTERS, Record<string, unknown>>
    | actionCreator<Actions.GET_TEAM_FILTERS, any>
    | actionCreator<Actions.GET_TEAM_FILTERS_SUCCESS, any>
    | actionCreator<Actions.SET_BUYOUT_DATE>
    | actionCreator<Actions.SET_BUYOUT_DATE_SUCCESS, any>
    | actionCreator<Actions.CREATE_TEMPLATE_PARTIES, TeamApi.CreateTemplatePayload>
    | actionCreator<Actions.CREATE_TEMPLATE_PARTIES_SUCCESS, TeamApi.IMember[]>;
}
