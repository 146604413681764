import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { searchUFC, searchUFCPopoverResult } from '../../../store/project/projectLogic';

import MenuItem from '@material-ui/core/MenuItem';
import { InputBase, Popover, Tooltip } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { useColoredText } from '../../../hooks/useColoredText';
import Timeout = NodeJS.Timeout;
import useMultiNestedFilter from '../../../hooks/useMultiNestedFilter/useMultiNestedFilter';
import { FilterNestedInterface, FilterNestedType } from '../../../models';
import { SelectFieldValue } from '../NeedsList/new/controls/CodeSelectField';
import { getMainMf, getMfFromParent, searchMForUFCode } from '../../../store/request/requestLogic(HOLD)';
import { TextInputNew } from '../../controls';
import './UfCodeAutocompleteField.scss';

interface IProps {
  type: FilterNestedType;
  customClassNamePaper?: string;
  selectedBuildings?: number[];
  currentValue?: FilterNestedInterface[];
  selectHandler?: (selectedMfCode: FilterNestedInterface[]) => void;
  isAccess: boolean;
  handleOpen_MF_UF?: (isOpen: boolean) => void;
  isNfCard?: boolean;
  isBulkEditMode?: boolean;
  bulkEditState?: { isChanged: boolean; values: SelectFieldValue[] };
  handleDelete?: (item: SelectFieldValue) => void;
  clearAll?: () => void;
  handleSelect?: (item: FilterNestedInterface, selectUfMf?: FilterNestedInterface, changeSelectedItem?: boolean) => void;
  handleClose?: () => void;
  isEditUF?: boolean;
}

const MultiNestedFilter = ({
  type,
  customClassNamePaper,
  selectedBuildings,
  currentValue,
  selectHandler,
  isAccess,
  handleOpen_MF_UF,
  isNfCard,
  isBulkEditMode,
  bulkEditState,
  handleDelete,
  clearAll,
  handleSelect,
  handleClose,
  isEditUF,
}: IProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const [search, setSearch] = useState<string>('');
  const [allElements, setAllElements] = useState<number>(2);
  const [width, setWidth] = useState(0);
  const projectId = useSelector<number>((state: any) => state.userReducer.active_project_id);
  const { renderText } = useColoredText();
  const ref = useRef<HTMLDivElement>();
  const refContainer = useRef<HTMLDivElement>();
  const refEditContainer = useRef<HTMLDivElement>();
  const { options, treeList, setTreeList } = useMultiNestedFilter(type);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const [scheduleRadioState, setScheduleRadioState] = useState<string>('default');
  const [selectUfMf, setSelectUfMf] = useState(null);
  const dispatch = useDispatch();

  let timerHover: Timeout;
  const hovered = status => {
    if (status) {
      timerHover = setTimeout(() => {
        setIsHoverActive(true);
        setAllElements(currentValue?.length);
      }, 200);
    } else {
      clearTimeout(timerHover);
      setIsHoverActive(false);
      countShowElements(width);
    }
  };

  const initData = () => {
    if (type === FilterNestedType.UF) {
      dispatch(
        searchUFC({
          textSearch: 'mainUfc',
          buildingId: selectedBuildings || [],
          projectId: projectId,
        }),
      );
    } else if (type === FilterNestedType.MF) {
      dispatch(
        getMainMf({
          projectId: projectId,
        }),
      );
    }
  };

  useEffect(() => {
    if (projectId) {
      initData();
    }
  }, [projectId]);

  useEffect(() => {
    if (search) {
      handleInputChange(search);
    }
  }, [search]);

  useEffect(() => {
    setTreeList([]);
    return () => {
      setTreeList([]);
    };
  }, []);

  useEffect(() => {
    if (!selectedBuildings?.length) {
      return;
    }
    initData();
  }, [selectedBuildings]);

  const searchResult = (value: number, isLast?: boolean) => {
    if (isLast) {
      return;
    }
    if (type === FilterNestedType.UF) {
      dispatch(
        searchUFCPopoverResult({
          ufcId: value,
          buildingId: selectedBuildings || [],
          projectId: projectId,
        }),
      );
    } else if (type === FilterNestedType.MF) {
      dispatch(
        getMfFromParent({
          mfId: value,
          projectId: projectId,
        }),
      );
    }
  };

  const goBack = () => {
    const projectId = +sessionStorage.getItem('active_project_id');
    const newTreeList = [...treeList];
    newTreeList.splice(newTreeList.length - 1, 1);
    setTreeList(newTreeList);
    if (type === FilterNestedType.UF) {
      if (newTreeList.length) {
        dispatch(
          searchUFCPopoverResult({
            ufcId: newTreeList[newTreeList.length - 1].id,
            buildingId: selectedBuildings || [],
            projectId: projectId,
          }),
        );
      } else {
        dispatch(
          searchUFC({
            textSearch: 'mainUfc',
            buildingId: selectedBuildings || [],
            projectId: projectId,
          }),
        );
      }
    } else if (type === FilterNestedType.MF) {
      if (newTreeList.length) {
        dispatch(
          getMfFromParent({
            mfId: newTreeList[newTreeList.length - 1].id,
            projectId: projectId,
          }),
        );
      } else {
        dispatch(
          getMainMf({
            buildingId: selectedBuildings || [],
            projectId: projectId,
          }),
        );
      }
    }
  };

  const handleClearAll = () => {
    selectHandler([]);
  };

  const renderSubTitle = () => {
    if (type === FilterNestedType.MF) {
      return 'MasterFormat';
    } else if (type === FilterNestedType.UF) {
      return 'UniFormat';
    }
  };

  const getListSize = () => {
    const newWidth = refContainer?.current?.clientWidth;
    countShowElements(newWidth);
    setWidth(newWidth);
  };

  useEffect(() => {
    getListSize();
  }, [currentValue]);

  useEffect(() => {
    window.addEventListener('resize', getListSize);
  }, []);

  useEffect(() => {
    if (!options?.length && treeList && treeList.length !== 0) {
      goBack();
    }
  }, [options]);

  useEffect(() => {
    setSelectUfMf(null);
  }, [bulkEditState?.values]);

  const Menu = () => {
    return (
      <>
        <div className={`b-nestedFilter__menu ${customClassNamePaper || ''}`}>
          {!isBulkEditMode ? (
            <div>
              <TextInputNew isSearch type={'on-bgd'} size={'sm'} placeholder={'Search'} onChange={setSearch} value={search} width={312} />
            </div>
          ) : (
            <div className={'NeedListCodes__inputArea'} ref={refEditContainer}>
              <div className={'NeedListCodes__inputWrapper'}>
                <div className={'NeedListCodes__label'}>{type}</div>
                <InputBase
                  className={'NeedListCodes__input'}
                  placeholder={'Search by digits code or description'}
                  // onFocus={this.onSearchFocus}
                  // onBlur={this.onSearchBlur}
                  onChange={e => setSearch(e.target.value)}
                  // value={state.searchValue}
                />
              </div>
              <div className={'NeedListCodes__selectedList'}>
                {bulkEditState?.values?.length
                  ? bulkEditState.values.map(item => {
                      const tooltipTitle = item.label;
                      const title = item.code || item.viewData.code;
                      return (
                        <Tooltip key={`code-${item.code || item.viewData.code}-${item.id}`} title={tooltipTitle} placement="top">
                          <div className={'NeedListCodes__selectedCode'}>
                            <div
                              className={'b-nestedFilter__multiValue w73'}
                              style={{
                                height: '18px',
                                marginBottom: '2px',
                                background: `linear-gradient(90deg, 
                                ${
                                  item.type === 'reason'
                                    ? '#51905B'
                                    : item.type === 'impacted'
                                    ? '#BB2525'
                                    : item.value === selectUfMf?.value
                                    ? 'var(--color-labelSelect)'
                                    : 'var(--color1)'
                                } 4px,
                                  ${item.value === selectUfMf?.value ? 'var(--color-labelSelect)' : 'var(--color1)'} 4px)`,
                              }}
                              onClick={e => handleOpenEditItem(e, item)}
                            >
                              <div className={'b-nestedFilter__value'} style={{ paddingLeft: '3px' }}>
                                {item.viewData?.code || ''}
                              </div>
                              {isAccess ? (
                                <div className={'b-nestedFilter__cross'} onClick={e => handleDeleteBulkEdit(e, item)}>
                                  <SVG width={6} height={6} src={require('../../../assets/icons/cross2.svg')} fill="#2D384E" />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Tooltip>
                      );
                    })
                  : null}
              </div>
            </div>
          )}
          <div className={'ufTreeHeader'}>
            <div className={'b-nestedFilter__treeContainer ufTreeHeader__treeContainer'}>
              {treeList?.length ? (
                treeList.map((subCode, index) => {
                  return (
                    <div
                      key={subCode.id}
                      className={'ufItem'}
                      onClick={e => {
                        e.stopPropagation();
                        searchResult(subCode.id);
                      }}
                    >
                      <span>{`${subCode.code}`}</span>
                      {treeList?.length - 1 === index ? null : (
                        <span className={'ufItem__arrow'}>
                          <SVG src={require('../../../assets/icons/arrow_right.svg')} />
                        </span>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className={'ufItem'}>
                  <span>{renderSubTitle()}</span>
                </div>
              )}
              {treeList?.length ? (
                <span className={'b-nestedFilter__goBack'} onClick={goBack}>
                  Go back
                </span>
              ) : null}
              {currentValue?.length ? (
                <div className={'b-nestedFilter__clearAll'} onClick={handleClearAll}>
                  Clear all
                </div>
              ) : null}
              {bulkEditState?.values?.length ? (
                <div className={'b-nestedFilter__clearAll'} onClick={clearAll}>
                  Clear all
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const Option = (data: FilterNestedInterface) => {
    const isExists = data.viewData.is_exists;

    let isExistInCurrentValue, isExistInBulkEditState;
    if (!isBulkEditMode) {
      isExistInCurrentValue = currentValue?.map(c => c.value).includes(data.value);
    } else {
      isExistInBulkEditState = bulkEditState?.values?.map(c => c.value).includes(data.value);
    }

    return (
      <MenuItem
        onClick={e => {
          e.stopPropagation();
          searchResult(data.value, !data.viewData.hasNesting);
        }}
        disabled={type === FilterNestedType.UF && !isExists}
        component="div"
        className={`menuItem ${isNfCard ? 'nfCardHover' : ''}`}
      >
        <div
          className={`valueContainer -underline ${type === FilterNestedType.MF ? '-mf' : ''}
         ${isExistInCurrentValue || isExistInBulkEditState ? '-disabled' : ''}
         `}
        >
          <span
            onClick={e => {
              !isBulkEditMode ? handleChange(e, data, isExistInCurrentValue) : handleChangeBulkEdit(e, data, isExistInBulkEditState);
            }}
          >
            {renderText(data.label, search || '')}
          </span>
        </div>
        {data.viewData.hasNesting && (
          <div className={'nestingContainer b-nestedFilter__nestingArrow'}>
            <span className={'nestingContainer__nestingArrow -select-arrow'}>
              <SVG src={require('../../../assets/icons/arrow_right.svg')} />
            </span>
          </div>
        )}
      </MenuItem>
    );
  };

  let timer: Timeout;
  const handleInputChange = (value: string) => {
    const projectId = +sessionStorage.getItem('active_project_id');

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      if (type === FilterNestedType.UF) {
        if (value.length <= 2) {
          await dispatch(
            searchUFC({
              textSearch: 'mainUfc',
              buildingId: selectedBuildings || [],
              projectId: projectId,
            }),
          );
        }

        if (value.length >= 3) {
          await dispatch(
            searchUFC({
              textSearch: value,
              buildingId: selectedBuildings || [],
              projectId: projectId,
            }),
          );
          setTreeList(null);
        }
      } else {
        if (value.length <= 2) {
          await dispatch(
            getMainMf({
              projectId: projectId,
            }),
          );
        }

        if (value.length >= 3) {
          await dispatch(
            searchMForUFCode({
              textSearch: value,
              table: 'mf',
              projectId: projectId,
            }),
          );
        }
      }
      setTreeList(null);
    }, 200);
  };

  const handleRemove = (val, index) => e => {
    e.stopPropagation();
    const selectedCurrentValue = [...currentValue];
    selectedCurrentValue.splice(index, 1);
    selectHandler(selectedCurrentValue);
  };

  const handleClosePopover = () => {
    setMenuIsOpen(false);
    setSearch('');
    setTreeList([]);
    setSelectUfMf(null);
    initData();
    if (isBulkEditMode) {
      handleClose();
      return;
    }
    countShowElements(width);
    handleOpen_MF_UF && handleOpen_MF_UF(false);
    setScheduleRadioState('default');
  };

  const handleChange = (e, data, isExistInCurrentValue) => {
    if (isExistInCurrentValue) {
      return;
    }

    e.stopPropagation();
    if (selectUfMf) {
      const selectedCurrentValue = [...currentValue];
      const index = selectedCurrentValue.findIndex(f => f.value === selectUfMf.value);
      data.type = scheduleRadioState;
      selectedCurrentValue.splice(index, 1, data);
      selectHandler(selectedCurrentValue);
      handleClosePopover();
      return;
    }

    const values = [...currentValue];
    data.type = scheduleRadioState;
    values.push(data);
    selectHandler(values);
  };

  const countShowElements = w => {
    if (currentValue?.length == 0) {
      return;
    }
    if (w > 300) {
      if (currentValue?.length > 2) {
        setAllElements(2);
      } else {
        setAllElements(currentValue?.length);
      }
    } else if (w > 176 && w < 215) {
      if (currentValue?.length > 2) {
        setAllElements(1);
      } else {
        setAllElements(currentValue?.length);
      }
    } else {
      setAllElements(1);
    }
  };

  const handleOpenMenu = () => {
    if (!isAccess) {
      return;
    }
    setMenuIsOpen(true);
    handleOpen_MF_UF && handleOpen_MF_UF(true);
  };

  const handleRadioState = (radioType: string) => {
    if (selectUfMf) {
      if (isBulkEditMode) {
        const changeTypeUF = { ...selectUfMf };
        changeTypeUF.type = radioType;
        handleSelect(changeTypeUF, selectUfMf, true);
        setSelectUfMf(changeTypeUF);
      } else {
        const values = [...currentValue];
        const changeTypeUF = { ...selectUfMf };
        changeTypeUF.type = radioType;
        const findIndex = values.findIndex(item => item.value == changeTypeUF.value);
        values[findIndex] = changeTypeUF;
        selectHandler(values);
        setSelectUfMf(changeTypeUF);
      }
    }
    setScheduleRadioState(radioType);
  };

  const handleOpenEditItem = (e, data) => {
    if (!isAccess) {
      return;
    }

    e.stopPropagation();
    searchResult(data.value, data.viewData.hasOwnProperty('hasNesting') ? !data.viewData.hasNesting : false);
    setTimeout(() => {
      setMenuIsOpen(true);
      handleOpen_MF_UF && handleOpen_MF_UF(true);
      setSelectUfMf(data);
      if (type === FilterNestedType.UF && isEditUF) {
        setScheduleRadioState(data.type);
      }
    }, 200);
  };

  const handleDeleteBulkEdit = (e, item) => {
    e.stopPropagation();
    handleDelete(item);
  };

  const handleChangeBulkEdit = (e, data, isExistInBulkEditState) => {
    if (isExistInBulkEditState) {
      return;
    }

    e.stopPropagation();
    if (selectUfMf) {
      data.type = scheduleRadioState;
      handleSelect(data, selectUfMf, true);
      return;
    }

    data.type = scheduleRadioState;
    handleSelect(data);
  };

  // const handleRadioUF = (radioType: string) => {
  //   if (isBulkEditMode) {
  //     const changeTypeUF = { ...selectUf };
  //     changeTypeUF.type = radioType;
  //     handleSelect(changeTypeUF);
  //     setSelectUf(changeTypeUF);
  //   } else {
  //     const values = [...currentValue];
  //     const changeTypeUF = { ...selectUf };
  //     changeTypeUF.type = radioType;
  //     const findIndex = values.findIndex(item => item.value == changeTypeUF.value);
  //     values[findIndex] = changeTypeUF;
  //     selectHandler(values);
  //     setSelectUf(changeTypeUF);
  //   }
  // };

  return (
    <>
      {!isBulkEditMode ? (
        <>
          <div
            className={`
      b-selectFilter-selected
      sandBox-selected
      w-100
      -infinityHeight
      ${!isAccess ? '-disabled' : ''}
      ${menuIsOpen ? '-active' : ''}
      ${isHoverActive ? 'sandBox-hover-selected' : ''}
      `}
            ref={refEditContainer}
            onClick={handleOpenMenu}
            onMouseEnter={() => hovered(true)}
            onMouseLeave={() => hovered(false)}
          >
            <div className={'b-nestedFilter__container'} ref={refContainer}>
              <div className={'b-container-title'} ref={ref}>
                <div className={`b-nestedFilter__label w18 position-start ml10 ${!currentValue?.length ? '-empty' : ''}`}>{type}</div>
              </div>
              <div className={'b-nestedFilter__tags sandBoxTags'}>
                {currentValue?.length
                  ? currentValue
                      .filter((item, i) => i < allElements || menuIsOpen)
                      .map((value, i) => {
                        return (
                          <Tooltip
                            title={`${value.viewData.code} - ${value.viewData.description || value.viewData.title}`}
                            key={`${type}-tag-${value.value}`}
                          >
                            <div
                              className={'b-nestedFilter__multiValue w117'}
                              style={{
                                background: `linear-gradient(90deg, 
                                ${
                                  value.type === 'reason'
                                    ? '#51905B'
                                    : value.type === 'impacted'
                                    ? '#BB2525'
                                    : value.value === selectUfMf?.value
                                    ? 'var(--color-labelSelect)'
                                    : 'var(--color1)'
                                } 4px,
                                  ${value.value === selectUfMf?.value ? 'var(--color-labelSelect)' : 'var(--color1)'} 4px)`,
                              }}
                              onClick={e => handleOpenEditItem(e, value)}
                            >
                              <div className={'b-nestedFilter__value'} style={{ paddingLeft: '3px' }}>
                                {value.viewData?.code
                                  ? `${value.viewData.code} - ${value.viewData?.description || value.viewData?.title}`
                                  : ''}
                              </div>
                              {isAccess ? (
                                <div className={'b-nestedFilter__cross'} onClick={handleRemove(value, i)}>
                                  <SVG width={6} height={6} src={require('../../../assets/icons/cross2.svg')} fill="#2D384E" />
                                </div>
                              ) : null}
                            </div>
                          </Tooltip>
                        );
                      })
                  : null}
                {currentValue?.length > allElements && allElements < currentValue?.length && !menuIsOpen ? (
                  <div className={'b-nestedFilter__multiValue w17'}>
                    <div className={'b-nestedFilter__value'}>...</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={'arrow_select arrow_select-sandBox'}>
              <SVG src={require('../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
            </div>
          </div>
          <Popover
            id={`nestedFilter-${type}`}
            open={!!menuIsOpen}
            anchorEl={ref.current}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                // @ts-ignore
                width: ref?.current?.clientWidth,
                minWidth: '330px',
                maxHeight: '500px',
                top: '144px !important',
                left: '600px',
              },
              classes: {
                root: 'b-selectFilter-popover',
              },
            }}
          >
            <div>
              {Menu()}
              <ul>
                {options
                  ? options
                      // .filter(f => !currentValue?.map(c => c.value).includes(f.value))
                      .map(item => {
                        return <li key={`${type}-${item.viewData.id}`}>{Option(item)}</li>;
                      })
                  : null}
              </ul>
              {type === FilterNestedType.UF && isEditUF ? (
                <div className={`radioContent__radioItem `}>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('default')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'default'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Default</span>
                  </div>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('reason')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'reason'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Reason</span>
                  </div>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('impacted')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'impacted'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Impacted</span>
                  </div>
                </div>
              ) : null}
            </div>
          </Popover>
        </>
      ) : (
        <>
          <div className={``} ref={ref} style={{ marginTop: '-15px' }} />
          <Popover
            id={`nestedFilter-${type}`}
            open={true}
            anchorEl={ref.current}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                // @ts-ignore
                width: ref?.current?.clientWidth,
                minWidth: '330px',
                maxHeight: '500px',
              },
              classes: {
                root: 'b-selectFilter-popover',
              },
            }}
          >
            <div>
              {Menu()}
              <ul>
                {options
                  ? options
                      // .filter(f => !bulkEditState.values?.map(c => c.value).includes(f.value))
                      .map(item => {
                        return <li key={`${type}-${item.viewData.id}`}>{Option(item)}</li>;
                      })
                  : null}
              </ul>
              {type === FilterNestedType.UF && isEditUF ? (
                <div className={`radioContent__radioItem `}>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('default')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'default'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Default</span>
                  </div>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('reason')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'reason'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Reason</span>
                  </div>
                  <div className={'radioContent__radioLabel'} onClick={() => handleRadioState('impacted')}>
                    <div className="radioContent__radio">
                      <Radio
                        checked={scheduleRadioState === 'impacted'}
                        className={'filter__checkBox'}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        style={{
                          padding: '0',
                        }}
                      />
                    </div>
                    <span className={'radioContent__label'}>Impacted</span>
                  </div>
                </div>
              ) : null}
            </div>
          </Popover>
        </>
      )}
      {/*{isOpenTypeUfFormat && type === FilterNestedType.UF ? (*/}
      {/*  <Popover*/}
      {/*    id={`typeUfFormat-${type}`}*/}
      {/*    open={isOpenTypeUfFormat}*/}
      {/*    anchorEl={refEditContainer.current}*/}
      {/*    onClose={changeCloseTypeUfFormat}*/}
      {/*    anchorOrigin={{*/}
      {/*      vertical: 'bottom',*/}
      {/*      horizontal: 'left',*/}
      {/*    }}*/}
      {/*    transformOrigin={{*/}
      {/*      vertical: 'top',*/}
      {/*      horizontal: 'left',*/}
      {/*    }}*/}
      {/*    PaperProps={{*/}
      {/*      style: {*/}
      {/*        // @ts-ignore*/}
      {/*        width: ref?.current?.clientWidth,*/}
      {/*        minWidth: isBulkEditMode ? '310px' : '336px',*/}
      {/*        height: '86px',*/}
      {/*        marginTop: '2px',*/}
      {/*      },*/}
      {/*      classes: {*/}
      {/*        root: 'b-selectFilter-popover',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div>*/}
      {/*      <div className={'radioContent__head_title'}>*/}
      {/*        <div className={'radioContent__head_title__title'}>Change the type of UniFormat</div>*/}
      {/*      </div>*/}
      {/*      <div className={`radioContent__radioItem `}>*/}
      {/*        <div className={'radioContent__radioLabel'} onClick={() => handleRadioUF('default')}>*/}
      {/*          <div className="radioContent__radio">*/}
      {/*            <Radio*/}
      {/*              checked={selectUf.type === 'default'}*/}
      {/*              className={'filter__checkBox'}*/}
      {/*              inputProps={{*/}
      {/*                'aria-label': 'select all desserts',*/}
      {/*              }}*/}
      {/*              style={{*/}
      {/*                padding: '0',*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <span className={'radioContent__label'}>Default</span>*/}
      {/*        </div>*/}
      {/*        <div className={'radioContent__radioLabel'} onClick={() => handleRadioUF('reason')}>*/}
      {/*          <div className="radioContent__radio">*/}
      {/*            <Radio*/}
      {/*              checked={selectUf.type === 'reason'}*/}
      {/*              className={'filter__checkBox'}*/}
      {/*              inputProps={{*/}
      {/*                'aria-label': 'select all desserts',*/}
      {/*              }}*/}
      {/*              style={{*/}
      {/*                padding: '0',*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <span className={'radioContent__label'}>Reason</span>*/}
      {/*        </div>*/}
      {/*        <div className={'radioContent__radioLabel'} onClick={() => handleRadioUF('impacted')}>*/}
      {/*          <div className="radioContent__radio">*/}
      {/*            <Radio*/}
      {/*              checked={selectUf.type === 'impacted'}*/}
      {/*              className={'filter__checkBox'}*/}
      {/*              inputProps={{*/}
      {/*                'aria-label': 'select all desserts',*/}
      {/*              }}*/}
      {/*              style={{*/}
      {/*                padding: '0',*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <span className={'radioContent__label'}>Impacted</span>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Popover>*/}
      {/*) : null}*/}
    </>
  );
};

export default MultiNestedFilter;
