import { IDrawing } from '../store/project/projectReducer.model';

export const getPCDStatus = revision => {
  if (revision) {
    if (revision.status === 'not_issued_yet' && revision.drafted) {
      return {
        title: 'Not issued yet',
        color: 'transparent',
        border: '1px solid var(--color-noSelect)',
        textColor: 'var(--color-noSelect)',
        colorGrid: 'transparent',
        borderGrid: '1px solid var(--border-pcd-disabled)',
        textColorGrid: 'var(--border-pcd-disabled)',
      };
    }
    if (revision.status === 'not_issued_yet' && !revision.drafted) {
      return {
        title: 'Not issued yet',
        color: 'transparent',
        border: '1px solid var(--color-rdg)',
        textColor: 'var(--color-rdg)',
        colorGrid: 'transparent',
        borderGrid: '1px solid var(--color-rdg)',
        textColorGrid: 'var(--color-rdg)',
      };
    }
    if (revision.status === 'superseded') {
      return {
        title: 'Superseded',
        color: '#838EA1',
        border: '1px solid #838EA1',
        textColor: '#38455E',
        colorGrid: 'var(--background-header)',
        borderGrid: '1px solid var(--background-header)',
        textColorGrid: '#0C1627',
      };
    }
    if (revision.status === 'issued') {
      const pcdTitleColor: string =
        revision.requests && !!revision.requests.find(item => item.validity_status === 'not_verified') ? '#F3A359' : '#2F80ED';
      const DeviverablesModuleColor: string = revision.request_not_validated > 0 ? '#F3A359' : '#2F80ED';
      return {
        title: 'Issued',
        color: `${revision?.requests?.length ? pcdTitleColor : DeviverablesModuleColor}`,
        border: `1px solid ${revision?.requests?.length ? pcdTitleColor : DeviverablesModuleColor}`,
        textColor: '#0C1627',
        colorGrid: `${revision?.requests?.length ? pcdTitleColor : DeviverablesModuleColor}`,
        borderGrid: '1px solid var(--background-header)',
        textColorGrid: '#0C1627',
      };
    }

    return {
      title: 'Not issued yet',
      color: 'transparent',
      border: '1px solid var(--color-noSelect)',
      textColor: 'var(--color-noSelect)',
      colorGrid: 'transparent',
      borderGrid: '1px solid var(--border-pcd-disabled)',
      textColorGrid: 'var(--border-pcd-disabled)',
    };
  }

  return {
    title: 'Not issued yet',
    color: 'transparent',
    border: '1px solid var(--color-noSelect)',
    textColor: 'var(--color-noSelect)',
    colorGrid: 'transparent',
    borderGrid: '1px solid var(--border-pcd-disabled)',
    textColorGrid: 'var(--border-pcd-disabled)',
  };
};

export const getTriggerTooltipText = (nf, incorporationCheck?) => {
  if (!nf.is_trigger) {
    return '';
  }
  const status = nf?.lastStatus;
  const is_incorporated = nf?.is_incorporated ? incorporationCheck : false;

  const tooltipArray = nf.is_trigger.map(action => {
    if (action === 'submitted') {
      if (status === 'sent' || status === 'new') {
        return 'NF was open when deliverable was submitted';
      }
      if (status === 'submitted') {
        return 'NF was open when deliverable was submitted';
      }
      if (status === 'provided') {
        return 'NF was open when deliverable was submitted';
      }
      if (status === 'declined') {
        return 'NF was open when deliverable was submitted';
      }
      if (status === 'accepted' && !is_incorporated) {
        return 'NF was not incorporated on this level when deliverable was submitted';
      }
    }

    if (action === 'changed') {
      return 'Status of NF changed';
    }

    if (action === 'incorporated') {
      return 'NF was incorporated on this level';
    }

    if (action === 'linked') {
      return 'NF was linked';
    }
  });

  return tooltipArray.join(', ');
};

export const detectPcdConditionsHandler = (filterName: string) => {
  switch (filterName) {
    case 'conditions_critical_date':
      return 'critical_date';

    case 'conditions_scheduled_date':
      return 'scheduled_date';

    case 'conditions_issued_date':
      return 'issued_date';

    default:
      return filterName;
  }
};

export const pcdSortRowsByPosition = (a: IDrawing, b: IDrawing) => {
  if (a.disc_position === b.disc_position) {
    if (a.type_position === b.type_position) {
      return a.position - b.position;
    } else {
      return a.type_position - b.type_position;
    }
  } else {
    return a.disc_position - b.disc_position;
  }
};
