import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';
import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { TabItemModel } from '../../../../../models/global';
import { DeliverableCardTabNamesModel, deliverableCardTabs } from '../../constans/constants';
import { IFeedData } from '../../../../../store/comments/commentReducer.model';
import Divider from '../../../../controls/Divider/Divider';
import PCDCard = PCDApi.PCDCard;
import { PCDApi } from '../../../../../service/Api/pcd/types';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { RelatedTabDataModel } from '../../../../../store/relatedItems/relatedItemsSlice.model';

import './TabsContainerStyles.scss';

interface IProps {
  isDragRequestCard: boolean;
  feedData: IFeedData;
  relatedTabData: RelatedTabDataModel;
  openedRequest: PCDCard;
  activeTab: DeliverableCardTabNamesModel;
  onChangeActiveTab: (activeTab: DeliverableCardTabNamesModel) => void;
  isDisable: boolean;
}

const TabsContainer: FC<IProps> = ({
  isDragRequestCard,
  feedData,
  relatedTabData,
  openedRequest,
  activeTab,
  onChangeActiveTab,
  isDisable,
}) => {
  const themeClass = useGetThemeClass('b-cardDeliverable-tabsContainer');
  const { isMobile } = GetResolution();

  const [tabs, setTabs] = useState<TabItemModel[]>([...deliverableCardTabs] || []);

  useEffect(() => {
    renderTabs();
  }, [openedRequest, feedData, relatedTabData]);

  const renderTabs = () => {
    const newTabs = tabs.map(tab => {
      if (tab.id === 'deliverable') {
        return { ...tab, disabled: checkDisable(tab.id as DeliverableCardTabNamesModel) };
      }
      if (tab.id === 'related-items') {
        let counter = 0;
        for (const key in relatedTabData) {
          if (key !== 'document' && key !== 'card') {
            counter += relatedTabData[key].length;
          }
        }

        return { ...tab, count: counter, disabled: checkDisable(tab.id as DeliverableCardTabNamesModel) };
      }
      if (tab.id === 'review') {
        return { ...tab, disabled: checkDisable(tab.id as DeliverableCardTabNamesModel) };
      }
      if (tab.id === 'discussion') {
        return { ...tab, count: feedData?.length || 0, disabled: checkDisable(tab.id as DeliverableCardTabNamesModel) };
      }
      return tab;
    });
    setTabs(newTabs);
  };

  const handleChangeActiveTab = (event: React.SyntheticEvent, tab: string) => {
    const isDisable = checkDisable(tab as DeliverableCardTabNamesModel);

    if (isDisable) {
      return;
    }
    !isDisable && onChangeActiveTab(tab as DeliverableCardTabNamesModel);
  };

  const checkDisable = (type: DeliverableCardTabNamesModel) => {
    return isDisable;
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-sidePeekView']: isDragRequestCard,
      })}
    >
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_tabsGroup`}>
          <TabsBar
            sections={tabs}
            selectedTab={activeTab}
            handleChange={handleChangeActiveTab}
            activeSectionType={'request'}
            isCountersLoading={false}
            isNfCard
            customClass={``}
            isMobile={isMobile}
          />
        </div>
      </div>
      <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-4'} />
    </div>
  );
};

export default TabsContainer;
