import React, { useMemo } from 'react';
import { NavigateFunction, Params, useLocation, useNavigate, useParams, Location, useSearchParams } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const urlProject = useMemo(() => {
      return location.pathname.split('/').filter(f => !!f);
    }, [location.pathname]);

    return (
      <Component {...props} location={location} params={params} navigate={navigate} urlProject={urlProject} searchParams={searchParams} />
    );
  }

  return ComponentWithRouterProp;
}

export interface RouteComponentProps {
  location?: Location<any>;
  navigate?: NavigateFunction;
  params?: Readonly<Params<string>>;
  urlProject?: string[];
  searchParams?: URLSearchParams;
}
