import React, { useMemo, useState } from 'react';
import StatusProject from '../../../../../../controls/Status/StatusProject/StatusProject';
import DropdownSelectMenu from '../../../../../../controls/Dropdowns/DropdownPopovers/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownMenuItem } from '../../../../../../controls/Dropdowns/DropdownParts';

import './ChipsProjectSelectStyle.module.scss';

interface IProps {
  project_status: string;
  handleChangeStatus: (value: string) => void;
  isCreateProject?: boolean;
}

interface selectItemsType {
  value: string;
  title: string;
}
const selectItems = [
  {
    value: 'planning',
    title: 'planning',
  },
  {
    value: 'active',
    title: 'active',
  },
  {
    value: 'paused',
    title: 'paused',
  },
  {
    value: 'archived',
    title: 'archived',
  },
];

const ChipsProjectSelect = ({ project_status, handleChangeStatus, isCreateProject }: IProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);
  const selected = useMemo(() => {
    if (project_status) {
      const findIndex = selectItems.findIndex(item => item.value === project_status);
      return findIndex > -1 ? selectItems[findIndex] : null;
    }
  }, [project_status]);

  const handleSelectItem = (value: string) => {
    handleChangeStatus(value);
    closeMenu();
  };

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const renderMenuItem = (item: selectItemsType) => {
    const title = (
      <div>
        <StatusProject project_status={item.value} />
      </div>
    );
    const isSelected = item.value === selected?.value;

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'sm'}
        disabled={false}
        title={title}
        value={item.value}
        onItemClick={handleSelectItem}
        tooltip={null}
        isSelected={isSelected}
        leftElem={null}
        leftElemClick={null}
        rightElem={null}
        rightElemClick={null}
        customClassName={null}
      />
    );
  };

  return (
    <>
      <div className={'statusProject'} onClick={e => openMenu(e)}>
        <StatusProject project_status={selected ? selected.value : 'Select project status'} isEditable />
      </div>
      <DropdownSelectMenu
        isMenuOpen={isMenuOpen}
        customClassName={null}
        renderItemHeader={null}
        renderItemFooter={null}
        renderMenuItem={renderMenuItem}
        menuItems={selectItems.filter(item => (isCreateProject ? item.value !== 'archived' : true))}
        size={'sm'}
        groups={null}
        groupingType={null}
        anchorEl={anchorEl}
        onOpen={null}
        onClose={closeMenu}
      />
    </>
  );
};

export default ChipsProjectSelect;
