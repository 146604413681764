import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './LinearDeterminateStyles.scss';

interface IProps {
  isActive: boolean;
  styles?: any;
}
// eslint-disable-next-line react/prop-types
export default function LinearDeterminate({ isActive, styles }: IProps) {
  const [progress, setProgress] = useState(0);
  const [viewLine, setViewLine] = useState(false);

  useEffect(() => {
    let timer;
    if (isActive) {
      setViewLine(true);
      let speed = 15;

      // eslint-disable-next-line no-undef
      timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            return 0;
          }
          const val = Math.min(oldProgress + speed, 100);
          const tempSpeed = (100 - val) * ((98 - val) / 100);
          speed = tempSpeed > 15 ? 15 : tempSpeed;
          return val;
        });
      }, 500);
      return () => {
        // eslint-disable-next-line no-undef
        clearInterval(timer);
      };
    }
    if (!isActive) {
      if (progress !== 0 || viewLine) {
        setProgress(100);
        // eslint-disable-next-line no-undef
        clearInterval(timer);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (progress === 100) {
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        setViewLine(false);
        setProgress(0);
      }, 1000);
    }
  }, [progress]);

  const themeClass = useGetThemeClass('b-linearDeterminate');

  return (
    <div style={{ height: '2px', visibility: viewLine ? 'visible' : 'hidden' }}>
      {viewLine && (
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{
            root: `${themeClass}_root`,
            bar: `${themeClass}_bar`,
            barColorPrimary: `${themeClass}_barColorPrimary`,
            colorPrimary: `${themeClass}_colorPrimary`,
          }}
          style={styles}
        />
      )}
    </div>
  );
}
