import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
  FileUploadMultipleResponsePayloadModel,
  FileUploadResponsePayloadModel,
  GetMembersResponseModel,
  GlobalPromptPopUpModel,
  ISettingsFile,
  ISettingsReducer,
  ISettingsStatusLocation,
  ISettingsStatusMember,
  ISettingsStatusParty,
} from './settingsReducer.model';
import { lightTheme } from '../../themes/lightTheme/lightTheme';
import { darkTheme } from '../../themes/darkTheme/darkTheme';
import { filesMultipleUpload, filesUpload, getMembers, getShortLink, useFileAsResponse } from './settingsThunk';
import { FileDeleteModel, FileUploadCleanModel } from '../../models';

// const initialUfcCodeState = {
//   ufcCodeInfo: {},
//   message: null,
//   loading: false,
//   status: null,
// };

const initialLocationState: ISettingsStatusLocation = {
  locationInfo: {},
  message: null,
  loading: false,
  status: null,
};

const initialPartyState: ISettingsStatusParty = {
  message: null,
  loading: false,
  status: null,
  data: null,
};

const initialMembersState: ISettingsStatusMember = {
  message: null,
  loading: false,
  status: null,
  data: [],
};

const initialMemberState: ISettingsStatusMember = {
  message: null,
  loading: false,
  status: null,
  data: [],
};

const initialFilesState: ISettingsFile = {
  request: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  commitment: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  project: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  comment: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  company: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  import: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
  pcd: {
    message: null,
    loading: false,
    status: null,
    data: {},
    errors: 0,
  },
};

const initialGlobalPromptPopUpState: GlobalPromptPopUpModel = {
  isChanged: false,
  isOpenPopUp: false,
  changeUrl: '',
  header: '',
  body: '',
  tertiaryTitle: '',
  primaryTitle: '',
  callback: null,
};

const initialState: ISettingsReducer = {
  locations: initialLocationState,
  PartyState: initialPartyState,
  members: initialMembersState,
  new_member: initialMemberState,
  files: initialFilesState,
  updated_member: initialMemberState,
  theme: localStorage.getItem('theme') ? (localStorage.getItem('theme') === 'light' ? lightTheme : darkTheme) : lightTheme,
  fileLoading: false,
  onlyAuth: false,
  kpiFrame: false,
  isInit: false,
  newMemberLoaded: false,
  overSizeFile: null,
  globalPromptPopUp: initialGlobalPromptPopUpState,
  isFirstRenderWithURLHash: false,
};

export const settingsSlice = createSlice({
  initialState,
  name: 'settingsReducer',
  reducers: {
    deleteFile(state, { payload }: PayloadAction<FileDeleteModel>) {
      const { data, sourse } = payload;
      const currentState = current(state);
      //@ts-ignore
      const currentFileSource = { ...currentState.files[sourse ? sourse : 'commitment'] };
      const currentFileSourceData = Object.values(currentFileSource.data);
      const fileToDeleteIndex = currentFileSourceData.findIndex(file => file.original_name === data);

      currentFileSourceData.splice(fileToDeleteIndex, 1);

      state.files = {
        ...state.files,
        [sourse ? sourse : 'commitment']: {
          ...state.files[sourse ? sourse : 'commitment'],
          data: { ...currentFileSourceData },
        },
      };
    },
    filesUploadClean(state, { payload }: PayloadAction<FileUploadCleanModel>) {
      const { type } = payload;

      state.files = {
        ...state.files,
        [type]: initialFilesState[type],
      };
    },
    setTheme(state, { payload }: PayloadAction<string>) {
      let theme = lightTheme;

      if (payload === 'dark') {
        theme = darkTheme;
      }

      state.theme = theme;
    },
    setOnlyAuth(state, { payload }: PayloadAction<boolean>) {
      state.onlyAuth = payload;
    },
    setKpiFrame(state, { payload }: PayloadAction<boolean>) {
      state.kpiFrame = payload;
    },
    setIsInit(state, { payload }: PayloadAction<boolean>) {
      state.isInit = payload;
    },
    setGlobalPrompPopUp(state, { payload }: PayloadAction<GlobalPromptPopUpModel>) {
      state.globalPromptPopUp = payload;
    },
    setIsFirstHashRender(state) {
      state.isFirstRenderWithURLHash = false;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * useFileAsResponse
       */
      .addCase(useFileAsResponse.pending, state => {
        state.fileLoading = true;
      })
      .addCase(useFileAsResponse.fulfilled, (state, { payload }: PayloadAction<FileUploadResponsePayloadModel>) => {
        state.fileLoading = false;
      })
      .addCase(useFileAsResponse.rejected, (state, { payload }: any) => {
        state.fileLoading = false;
      })
      /**
       * filesUpload
       */
      .addCase(filesUpload.pending, state => {
        state.fileLoading = true;
      })
      .addCase(filesUpload.fulfilled, (state, { payload }: PayloadAction<FileUploadResponsePayloadModel>) => {
        const { response, source } = payload;

        state.fileLoading = false;
        state.files = {
          ...state.files,
          [source]: {
            status: response.status,
            loading: false,
            errors: state.files[source].errors,
            data: {
              ...state.files[source].data,
              [response.data.original_name]: {
                filepath: response.data.filepath,
                original_name: response.data.original_name,
                fullpath: response.data.fullpath,
                ext: response.data.ext,
                size: response.data.size,
                action: 'add',
                isNew: true,
                loaded: true,
              },
            },
          },
        };
      })
      .addCase(filesUpload.rejected, (state, { payload }: any) => {
        const { source, isOversize, file } = payload;

        state.fileLoading = false;
        state.files = {
          ...state.files,
          [source]: {
            ...state.files[source],
            status: false,
            message: 'Load fail',
            loading: false,
            data: { ...state.files[source].data },
          },
        };
        if (isOversize && file) {
          state.overSizeFile = file;
        }
      })
      /**
       * filesMultipleUpload
       */
      .addCase(filesMultipleUpload.pending, state => {
        state.fileLoading = true;
      })
      .addCase(filesMultipleUpload.fulfilled, (state, { payload }: PayloadAction<FileUploadMultipleResponsePayloadModel>) => {
        const { responses, source } = payload;
        const fileData = {};
        responses.map(response => {
          fileData[response.data.original_name] = {
            filepath: response.data.filepath,
            original_name: response.data.original_name,
            fullpath: response.data.fullpath,
            ext: response.data.ext,
            size: response.data.size,
            action: 'add',
            isNew: true,
            loaded: true,
          };
        });

        state.fileLoading = false;
        state.files = {
          ...state.files,
          [source]: {
            status: responses[responses.length - 1].status,
            loading: false,
            errors: state.files[source].errors,
            data: {
              ...state.files[source].data,
              ...fileData,
            },
          },
        };
      })
      .addCase(filesMultipleUpload.rejected, (state, { payload }: any) => {
        const { source, isOversize, file } = payload;

        state.fileLoading = false;
        state.files = {
          ...state.files,
          [source]: {
            ...state.files[source],
            status: false,
            message: 'Load fail',
            loading: false,
            data: { ...state.files[source].data },
          },
        };
        if (isOversize && file) {
          state.overSizeFile = file;
        }
      })
      /**
       * getMembers
       */
      .addCase(getMembers.pending, state => {
        state.members = {
          message: null,
          loading: true,
          status: null,
          data: [],
        };
      })
      .addCase(getMembers.fulfilled, (state, { payload }: PayloadAction<GetMembersResponseModel>) => {
        state.members = {
          ...state.members,
          loading: false,
          data: Object.values(payload.data),
        };
      })
      .addCase(getMembers.rejected, (state, { payload }: any) => {
        state.members = {
          ...state.members,
          loading: false,
          data: [...state.members.data] || [],
        };
      })
      /**
       * getShortLink
       */
      .addCase(getShortLink.pending, state => {
        state.isFirstRenderWithURLHash = false;
      })
      .addCase(getShortLink.fulfilled, state => {
        state.isFirstRenderWithURLHash = true;
      })
      .addCase(getShortLink.rejected, state => {
        state.isFirstRenderWithURLHash = false;
      });
  },
});

export default settingsSlice.reducer;
