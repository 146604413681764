import React from 'react';
import { Select } from '../../Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../models/global';
import { useGetThemeClass } from '../../../../helpers/designTokens';

import './RedmineMilestoneSelectStyles.scss';

interface IProps {
  title: string;
  selectHandler: (DropdownItemModel) => void;
  menuItemSize: 'sm' | 'md';
  selectedItem: any;
  basisPlaceholder: string;
  itemsList: DropdownItemModel[];
  isDisableMinWidth?: boolean;
  disabled?: boolean;
  isHeader?: boolean;
  isLoading?: boolean;
  customBasisFieldClass?: string;
  customMenuItemClass?: string;
}

const RedmineMilestoneSelect: React.FC<IProps> = ({
  title,
  isDisableMinWidth,
  menuItemSize,
  basisPlaceholder,
  selectHandler,
  selectedItem,
  itemsList,
  disabled,
  isHeader,
  isLoading,
  customBasisFieldClass,
  customMenuItemClass,
}) => {
  const themeClass = useGetThemeClass('redmine-milestone-select');

  return (
    <div>
      <div className={`${themeClass}__title`}>{title}</div>
      <Select
        isHeader={isHeader}
        isMulti={false}
        selectHandler={selectHandler}
        menuItems={itemsList}
        selected={selectedItem}
        menuItemSize={menuItemSize}
        basisPlaceholder={basisPlaceholder}
        basisMaxItems={2}
        isDisableMinWidth={isDisableMinWidth}
        disabled={disabled}
        isLoading={isLoading}
        isHideSearch={false}
        menuItemTooltipRenderer={item => item?.title}
        basisTooltip={selectedItem?.title}
        customMenuItemClass={customMenuItemClass}
        customBasisFieldClass={customBasisFieldClass}
        isFullWidth={false}
      />
    </div>
  );
};

export default RedmineMilestoneSelect;
