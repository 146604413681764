import { ColumnConfig } from './types';

export const columnsDesignInput: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'number',
    name: 'Drawing No',
    isHeaderRenderer: true,
    order_column: 'number',
    order_dir: 'asc',
    order_dir_type: 'alphabetically',
    width: 100,
    maxWidth: 100,
  },
  {
    key: 'sheet_title',
    name: 'Sheet name',
    isHeaderRenderer: true,
    order_column: 'title',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 270,
    maxWidth: 270,
  },
  {
    key: 'building',
    name: 'Building',
    isHeaderRenderer: true,
    order_column: 'building_id',
    order_dir: '',
    order_dir_type: 'by number',
    width: 100,
    maxWidth: 100,
  },
  {
    key: 'last_revision',
    name: 'Latest rev',
    width: 80,
    maxWidth: 80,
  },
  {
    key: 'current_set',
    name: 'Current DD set',
    isHeaderRenderer: true,
    order_column: 'current_set',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 117,
    maxWidth: 117,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 250,
    maxWidth: 250,
  },
];

export const columnsDeliverables: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'title',
    name: 'Deliverable title',
    isHeaderRenderer: true,
    order_column: 'title',
    order_dir: 'asc',
    order_dir_type: 'alphabetically',
    width: 250,
    maxWidth: 250,
  },
  {
    key: 'revision_deliverables',
    name: 'Rev',
    width: 56,
    maxWidth: 56,
  },
  {
    key: 'revision_status',
    name: 'Status',
    isHeaderRenderer: true,
    order_column: 'revision_status',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 164,
    maxWidth: 164,
  },
  {
    key: 'level',
    name: 'Level',
    isHeaderRenderer: true,
    order_column: 'level_id',
    order_dir: '',
    order_dir_type: 'by number',
    width: 100,
    maxWidth: 100,
  },
  {
    key: 'building_title',
    name: 'Building',
    isHeaderRenderer: true,
    order_column: 'building_id',
    order_dir: '',
    order_dir_type: 'by number',
    width: 100,
    maxWidth: 100,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 250,
    maxWidth: 250,
  },
];

export const columnsDeliverablesForDeliverables: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'title',
    name: 'Deliverable title',
    isHeaderRenderer: true,
    order_column: 'title',
    order_dir: 'asc',
    order_dir_type: 'alphabetically',
    width: 344,
    maxWidth: 344,
  },
  {
    key: 'revision_deliverables',
    name: 'Rev',
    width: 56,
    maxWidth: 56,
  },
  {
    key: 'revision_status',
    name: 'Status',
    isHeaderRenderer: true,
    order_column: 'revision_status',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 170,
    maxWidth: 170,
  },
  {
    key: 'level',
    name: 'Level',
    isHeaderRenderer: true,
    order_column: 'level_id',
    order_dir: '',
    order_dir_type: 'by number',
    width: 100,
    maxWidth: 100,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 250,
    maxWidth: 250,
  },
];

export const columnsPcdCardLinkedNfsTab: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'nf',
    name: 'NF',
    width: 123,
    maxWidth: 123,
  },
  {
    key: 'subject',
    name: 'Subject',
    width: 170,
    maxWidth: 170,
  },
  {
    key: 'lastStatus',
    name: 'NF status',
    width: 172,
    maxWidth: 172,
  },
  {
    key: 'incorporated',
    name: 'Local incorporation',
    width: 135,
    maxWidth: 135,
  },
  {
    key: 'revision_update_status',
    name: 'Validation alert',
    width: 250,
    maxWidth: 250,
  },
];

export const columnsDeliverableRequest: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'nf',
    name: 'NF',
    isHeaderRenderer: true,
    order_column: 'nf',
    order_dir: 'asc',
    order_dir_type: 'by number',
    width: 123,
    maxWidth: 123,
  },
  {
    key: 'subject',
    name: 'Subject',
    isHeaderRenderer: true,
    order_column: 'specific',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 230,
    maxWidth: 230,
  },
  {
    key: 'lastStatus',
    name: 'NF status',
    isHeaderRenderer: true,
    order_column: 'lastStatus',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 180,
    maxWidth: 180,
  },
  {
    key: 'incorporated_to_locations',
    name: 'Local incorporation',
    width: 135,
    maxWidth: 135,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 250,
    maxWidth: 250,
  },
];

export const columnsRequestTab: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'nf',
    name: 'NF',
    isHeaderRenderer: true,
    order_column: 'nf',
    order_dir: 'asc',
    order_dir_type: 'by number',
    width: 75,
    maxWidth: 75,
  },
  {
    key: 'subject',
    name: 'Subject',
    isHeaderRenderer: true,
    order_column: 'specific',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 394,
    maxWidth: 394,
  },
  {
    key: 'lastStatus',
    name: 'NF status',
    isHeaderRenderer: true,
    order_column: 'lastStatus',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 180,
    maxWidth: 180,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 270,
    maxWidth: 270,
  },
];

export const columnsSubmittalsTab: ColumnConfig[] = [
  {
    key: 'selected_row',
    name: '',
    width: 36,
    maxWidth: 36,
    isHeaderRenderer: true,
    isActiveBox: 0,
  },
  {
    key: 'validity_status',
    name: '',
    width: 36,
    maxWidth: 36,
  },
  {
    key: 'submittal',
    name: 'Submittal',
    isHeaderRenderer: true,
    order_column: 'submittal',
    order_dir: 'asc',
    order_dir_type: 'alphabetically',
    width: 159,
    maxWidth: 159,
  },
  {
    key: 'title',
    name: 'Title',
    isHeaderRenderer: true,
    order_column: 'title',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 243,
    maxWidth: 243,
  },
  {
    key: 'status_submittal',
    name: 'Status',
    isHeaderRenderer: true,
    order_column: 'status_submittal',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 120,
    maxWidth: 120,
  },
  {
    key: 'type_submittal',
    name: 'Type',
    isHeaderRenderer: true,
    order_column: 'type',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 140,
    maxWidth: 140,
  },
  {
    key: 'validation_alert',
    name: 'Validation alert',
    isHeaderRenderer: true,
    order_column: 'validity_alert',
    order_dir: '',
    order_dir_type: 'alphabetically',
    width: 260,
    maxWidth: 260,
  },
];
