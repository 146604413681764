import { SimpleTitleModel, TitleModel } from '../base/base.model';
import { LocationCollectionModel } from '../project/location.model';

export interface RequestCardModel {
  requestHidden: boolean;
  commitmentHidden: boolean;
  deliverableHidden: boolean;
  incorporationHidden: boolean;
  linkedNFSHidden: boolean;
  commentsHidden: boolean;
  historyHidden: boolean;
}

export type NfType = 'nf-request' | 'nf-response' | 'new-response' | 'unlink' | 'review';
export type NfFilterSearchFlagType = 'request-comp' | 'request-disc' | 'request-user' | 'response-comp' | 'response-disc' | 'response-user';
export type ActivationTabType =
  | 'request'
  | 'commitment'
  | 'response'
  | 'incorporation'
  | 'linked'
  | 'discussion'
  | 'history'
  | 'general'
  | 'review'
  | 'related-items';

export type NfFilterLocationFlagType = 'location' | 'building' | 'space';
export enum BuildingsKeyEnum {
  locations = 'locations',
  buildings = 'buildings',
  building_spaces = 'building_spaces',
}

export interface BuildingsCollectionSpaceModel {
  id: number;
  title: string;
  building_id: number;
}

export interface BuildingsCollectionBuildingModel {
  id: number;
  locations_count: number;
  project_id: number;
  title: string;
  units_count: number;
  building_spaces: BuildingsCollectionSpaceModel[];
}

export interface BuildingsCollectionModel {
  [BuildingsKeyEnum.locations]: LocationCollectionModel[];
  [BuildingsKeyEnum.building_spaces]: BuildingsCollectionSpaceModel[];
  [BuildingsKeyEnum.buildings]: BuildingsCollectionBuildingModel[];
}

export interface SelectedRequestCompanyModel {
  discipline: NfFilterSelectedDiscipline | null;
  company: SimpleTitleModel | null;
  user: SimpleTitleModel | null;
}

export interface SelectedRequestCompanyModelPayload {
  discipline?: SimpleTitleModel | null;
  company?: SimpleTitleModel | null;
  user?: SimpleTitleModel | null;
}

export interface ColoredModel extends SimpleTitleModel {
  color: string;
  text_color?: string;
}

export interface NfFilterAdditionalCompanyModel {
  disciplines: ColoredModel[];
  id: number;
  company: string;
  contact_point: ContactPointModel | null;
  roles: TitleModel[];
  work_package: TitleModel[];
}

export interface NfFilterDisciplineModel {
  id: number;
  title: string;
  color: string;
  parties: NfFilterAdditionalCompanyModel[];
  available: boolean;
}

interface ContactPointModel {
  id: number;
  fullname: string;
  image: string | null;
}

export interface NfFilterCompanyModel {
  id: number;
  company: string;
  contact_point: ContactPointModel | null;
  disciplines: ColoredModel[];
  roles: TitleModel[];
  users: NfFilterManagerModel[];
  work_package: TitleModel[];
  available: boolean;
}

export interface NfFilterManagerModel {
  id: number;
  profile: {
    first_name: string;
    image: string | null;
    last_name: string;
    title: string | null;
  };
  disciplines: ColoredModel[];
  parties: NfFilterAdditionalCompanyModel[];
  work_package: TitleModel[];
}

export interface NfFilterSelectedDiscipline {
  id: number;
  title: string;
  color: string;
  text_color?: string;
}

export interface NfCompanySearchInterface {
  disciplines: NfFilterDisciplineModel[];
  parties: NfFilterCompanyModel[];
  managers: NfFilterManagerModel[];
}

export interface ResponseActionMessageHelperPayload {
  prefix: string;
  color: string;
  date: string;
}
