import { StatusModel } from '../models/request/status.model';

export default class StatusNameHelper {
  static getLastUserStatusName = request => {
    const status = request?.lastStatus
      ? request?.lastStatus
      : request?.lastUserStatus?.status
      ? request?.lastUserStatus?.status
      : request?.status;
    if (request?.is_deactivated) {
      return 'Request Deactivated';
    }
    if (status === 'drafted') {
      return 'Request Drafted';
    } else if (status === 'sent' || status === 'new') {
      return 'Request Sent';
    } else if (status === 'submitted') {
      return 'Commitment Submitted';
    } else if (status === 'provided') {
      return 'Response Submitted';
    } else if (status === 'declined') {
      if (request?.parent_id) {
        return 'Response Declined';
      } else {
        return 'Request Re-opened';
      }
    } else if (status === 'accepted') {
      return 'Response Accepted';
    } else {
      return 'Status Missing';
    }
  };

  static getLastUserStatusColor = request => {
    const status = request?.lastUserStatus?.status ? request?.lastUserStatus?.status : request?.lastStatus;

    if (status === 'drafted') {
      return 'var(--color2)';
    } else if (status === 'sent') {
      return 'var(--color2)';
    } else if (status === 'submitted') {
      return 'var(--color2)';
    } else if (status === 'provided') {
      return 'var(--color2)';
    } else if (status === 'declined') {
      if (request?.parent_id) {
        return 'var(--button-decline)';
      } else {
        return 'var(--color2)';
      }
    } else if (status === 'accepted') {
      return '#65C17F';
    } else {
      return 'var(--color1)';
    }
  };

  static getCardStatus = selectedRequest => {
    if (selectedRequest.status === 'drafted') {
      return new StatusModel({ title: 'Drafted', type: 'Request' });
    }
    if (selectedRequest.status === 'sent') {
      return new StatusModel({ title: 'Sent', type: 'Request' });
    }
    if (selectedRequest.status === 'submitted') {
      return new StatusModel({ title: 'Submitted', type: 'Commitment' });
    }
    if (selectedRequest.status === 'provided') {
      return new StatusModel({ title: 'Submitted', type: 'Response' });
    }
    if (selectedRequest.status === 'accepted') {
      return new StatusModel({ title: 'Accepted', type: 'Response' });
    }
    if (selectedRequest.status === 'declined') {
      return new StatusModel({ title: 'Re-opened', type: 'Request' });
    }
    return new StatusModel({ title: 'New', type: '' });
  };
}
