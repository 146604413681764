import React from 'react';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import TokenIcon from '../../../../../controls/TokenIcon/TokenIcon';

import './TypicalLayoutsExpandItemStyles.scss';

interface IProps {
  typicalLayout: any;
  handleTypicalLayoutsExpandItemClick?: (typicalLayout: any) => void;
}

const TypicalLayoutsExpandItem: React.FC<IProps> = ({ typicalLayout, handleTypicalLayoutsExpandItemClick }) => {
  const themeClass = useGetThemeClass('b-typicalLayoutsExpandItem');

  return (
    <div className={`${themeClass}_container`} onClick={() => handleTypicalLayoutsExpandItemClick(typicalLayout)}>
      <div className={`${themeClass}_item`}>
        <div className={`${themeClass}_item_title`}>{typicalLayout.title}</div>
        <div className={`${themeClass}_item_icon`}>
          <TokenIcon iconName={typicalLayout.isItemOpened ? 'chevron-down' : 'chevron-right'} size={12} />
        </div>
      </div>
    </div>
  );
};

export default TypicalLayoutsExpandItem;
