import React, { FC, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ShowMoreLocations from '../../../../controls/ShowMore/ShowMoreLocations/ShowMoreLocations';
import { ColumnToolTip } from '../../../AsideHolder/AsideHolder';
import { ButtonView } from '../../../../controls';
import { BulkEditLocationSelectData } from '../../../../../helpers/NeedListBulkEditHelper';

import './Styles.scss';

interface SelectFieldValue {
  id: number | string;
  title: string;
}

interface IProps {
  data: BulkEditLocationSelectData[];
  values: SelectFieldValue[];
  isMultiple?: boolean;
  atLeastOne?: boolean;
  isLocations?: boolean;
  onClose: (values: SelectFieldValue[], isCommit?: boolean) => void;
}

interface IState {
  values: SelectFieldValue[];
  isChanged: boolean;
}

const MenuProps: any = {
  PaperProps: {
    style: {
      minWidth: 200,
      maxHeight: 400,
      overflowY: 'auto',
      width: 'auto',
      backgroundColor: 'var(--background-main)',
    },
  },
  MenuListProps: {
    style: {
      backgroundColor: 'var(--background-select)',
      '&:hover': {
        backgroundColor: 'var(--background-main)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
};

const SelectLocationField: FC<IProps> = props => {
  const { values, onClose, data, isMultiple, atLeastOne } = props;

  const initialState: IState = {
    values: props.values,
    isChanged: false,
  };

  let [state, setState] = useState<IState>(initialState);

  const handleChange = (item, isChecked: boolean) => {
    let newVals = [...state.values];
    if (isMultiple) {
      if (isChecked) {
        if (!atLeastOne || (atLeastOne && state.values.length > 1)) {
          newVals = state.values.filter(val => val.id !== item.id);
        }
      } else {
        newVals.push(item);
      }
    } else {
      if (!atLeastOne && isChecked) {
        newVals = [];
      } else {
        newVals = [item];
      }
    }

    setState({ ...state, values: newVals, isChanged: true });
  };

  const handleClear = () => {
    setState({ ...state, values: [], isChanged: true });
  };

  const handleClose = () => {
    onClose(state.values, state.isChanged);
  };

  return (
    <>
      <Select
        renderValue={() => <ShowMoreLocations items={values} />}
        multiple
        MenuProps={{ ...MenuProps }}
        onClose={handleClose}
        open={true}
        value={state.values}
      >
        {data.map(item => {
          return (
            <div key={`loc-be-sub-menu${item.title}`}>
              <div className={'NeedListSelectField__menuSubTitle'}>{item.title}</div>
              {item.locations.map(opt => {
                const isChecked = Boolean(state.values.find(item => item.id === opt.id));
                const title = opt.title;
                return (
                  <MenuItem key={`be-sel-i-${opt.id}`} value={opt.id} className={'menuItem'}>
                    <Checkbox onChange={() => handleChange(opt, isChecked)} checked={isChecked} className={'filter__checkBox'} />
                    <ColumnToolTip title={title} placement="top">
                      <span className={'title'}>{title}</span>
                    </ColumnToolTip>
                  </MenuItem>
                );
              })}
            </div>
          );
        })}
        <ButtonView handleClick={handleClear} type={'outline'} title={'Clear'} style={{ margin: '10px auto 0' }} />
      </Select>
    </>
  );
};

SelectLocationField.defaultProps = {
  isMultiple: false,
  atLeastOne: false,
  isLocations: false,
};

export default SelectLocationField;
