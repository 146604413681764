import React, { FC, ReactElement } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import Placeholder from '../Placeholder/Placeholder';
import CustomTooltip from '../Tooltip/Tooltip';
import cn from 'classnames';

import './PlaceholderInfoStyles.module.scss';

const PlaceholderInfo: FC<{
  type: 'Company' | 'Persona';
  imagePath?: string;
  firstName?: string;
  lastName?: string;
  detailInfo: string;
  subInfo?: string | ReactElement;
  size: 24 | 32 | 56;
  tooltip?: string | ReactElement;
  isMobile?: boolean;
  customClassName?: string;
  isTruncateHeader?: boolean;
  isHeader?: boolean;
}> = ({
  type,
  imagePath,
  size,
  firstName,
  lastName,
  detailInfo,
  subInfo,
  isTruncateHeader,
  tooltip,
  isMobile,
  customClassName,
  isHeader,
}) => {
  const themeClass = useGetThemeClass('b-placeholderInfo');

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          [`${customClassName}`]: customClassName,
          ['-mobile']: isMobile,
        })}
      >
        <CustomTooltip customBasisClass={`${themeClass}_tooltipBasis`} title={tooltip || detailInfo || ''} placement={'bottom'}>
          <div className={`${themeClass}_content`}>
            <Placeholder type={type} imagePath={imagePath} size={size} firstName={firstName} lastName={lastName} />

            {isMobile ? (
              <>
                {!isHeader && (
                  <div className={`${themeClass}_detailSubContainer`}>
                    <div className={`${themeClass}_detail ${themeClass}_detail_${size}`}>{detailInfo}</div>
                    <div className={`${themeClass}_sub ${themeClass}_sub_${size}`}>{subInfo}</div>
                  </div>
                )}
              </>
            ) : (
              <div className={`${themeClass}_detailSubContainer`}>
                <div className={`${themeClass}_detail ${themeClass}_detail_${size}`}>{detailInfo}</div>
                <div className={`${themeClass}_sub ${themeClass}_sub_${size} ${isTruncateHeader ? '-headerTrunk' : ''}`}>{subInfo}</div>
              </div>
            )}
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};

export default React.memo(PlaceholderInfo);
