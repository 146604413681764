import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { LinkedItemsShowType, LinkedType } from '../../RelatedItemsTabConstants';
import moment from 'moment';
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;

// currentCard === 'Nf' and relatedTabType === 'request'
const requestOverdue = {
  title: 'Request overdue',
  value: 'requestOverdue',
  isItemOpened: false,
  itemType: 'filterName',
};
const dueDateThanThisNF = {
  title: 'Due date later than this NF',
  value: 'dueDateThanThisNF',
  isItemOpened: false,
  itemType: 'filterName',
};
const nfStatus = {
  title: 'NF status',
  value: 'NfStatus',
  isItemOpened: false,
  itemType: 'filterName',
};
const responseIncorporated = {
  title: 'Response incorporated',
  value: 'responseIncorporated',
  isItemOpened: false,
  itemType: 'filterName',
};
const localIncorporation = {
  title: 'Local incorporation',
  value: 'localIncorporation',
  isItemOpened: false,
  itemType: 'filterName',
};
const validityStatusRequest = {
  title: 'Validity status',
  value: 'validityStatusRequest',
  isItemOpened: false,
  itemType: 'filterName',
};
const dueDateLaterThan2DaysBeforeScheduledDate = {
  title: 'Due date later than 2 days before Scheduled date',
  value: 'dueDateLaterThan2DaysBeforeScheduledDate',
  isItemOpened: false,
  itemType: 'filterName',
};
// currentCard === 'Nf' and relatedTabType === 'deliverable'
const drawingDiscipline = {
  title: 'Drawing discipline',
  value: 'drawingDiscipline',
  isItemOpened: false,
  itemType: 'filterName',
};
const drawingType = { title: 'Drawing type', value: 'drawingType', isItemOpened: false, itemType: 'filterName', data: [] };
const deliverableLocation = {
  title: 'Deliverable location',
  value: 'deliverableLocation',
  isItemOpened: false,
  itemType: 'filterName',
};
const validityStatusDeliverable = {
  title: 'Validity status',
  value: 'validityStatusDeliverable',
  isItemOpened: false,
  itemType: 'filterName',
};
const latestDeliverableRevisionStatus = {
  title: 'Latest deliverable revision status',
  value: 'latestDeliverableRevisionStatus',
  isItemOpened: false,
  itemType: 'filterName',
};
const scheduledDateEarlierThan = {
  title: 'Scheduled date earlier than 2 days before this NF due date',
  value: 'scheduledDateEarlierThan',
  isItemOpened: false,
  itemType: 'filterName',
};
// currentCard === 'Nf' and relatedTabType === 'document'
const disciplineDD = { title: 'Discipline', value: 'disciplineDD', isItemOpened: false, itemType: 'filterName', data: [] };
const latestDdSet = { title: 'Latest DD set', value: 'latestDdSet', isItemOpened: false, itemType: 'filterName', data: [] };
const buildingDD = { title: 'Building', value: 'buildingDD', isItemOpened: false, itemType: 'filterName', data: [] };
const validityStatusDocument = {
  title: 'Validity status',
  value: 'validityStatusDocument',
  isItemOpened: false,
  itemType: 'filterName',
};
// currentCard === 'Nf' and relatedTabType === 'submittal'
const specificationSection = {
  title: 'Specification section',
  value: 'specificationSection',
  isItemOpened: false,
  itemType: 'filterName',
};
const submittalType = { title: 'Submittal type', value: 'submittalType', isItemOpened: false, itemType: 'filterName', data: [] };
const responsibleManagerCompany = {
  title: 'Responsible manager company',
  value: 'responsibleManagerCompany',
  isItemOpened: false,
  itemType: 'filterName',
};
const responsibleManager = {
  title: 'Responsible manager',
  value: 'responsibleManager',
  isItemOpened: false,
  itemType: 'filterName',
};
const responsibleContractorCompany = {
  title: 'Responsible contractor company',
  value: 'responsibleContractorCompany',
  isItemOpened: false,
  itemType: 'filterName',
};
const responsibleContractor = {
  title: 'Responsible contractor',
  value: 'responsibleContractor',
  isItemOpened: false,
  itemType: 'filterName',
};
const submittalStatus = { title: 'Submittal status', value: 'submittalStatus', isItemOpened: false, itemType: 'filterName', data: [] };
const validityStatusSubmittal = {
  title: 'Validity status',
  value: 'validityStatusSubmittal',
  isItemOpened: false,
  itemType: 'filterName',
};

// Common
const requestType = {
  title: 'Request type',
  value: 'requestType',
  isItemOpened: false,
  itemType: 'filterName',
};

const requestDiscipline = {
  title: 'Request discipline',
  value: 'requestDiscipline',
  isItemOpened: false,
  itemType: 'filterName',
};

export const getFiltersConstants = (data: {
  currentCard: 'Nf' | 'Deliverable' | 'Submittal';
  relatedTabType: RelatedItemsTabsType;
  part: LinkedType;
  selectedShowType: LinkedItemsShowType;
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
}) => {
  const { currentCard, relatedTabType, part, selectedShowType, selectedRequest } = data;

  if (currentCard === 'Nf') {
    if (relatedTabType === 'request') {
      if (part === 'predecessor') {
        if (selectedShowType === 'outstanding') {
          return [requestType, requestDiscipline, nfStatus, requestOverdue, dueDateThanThisNF];
        }
        if (selectedShowType === 'accepted') {
          return [requestType, requestDiscipline, responseIncorporated];
        }
      }
      if (part === 'successor') {
        if (selectedShowType === 'outstanding') {
          return [requestType, requestDiscipline, nfStatus, requestOverdue, dueDateThanThisNF];
        }
        if (selectedShowType === 'accepted') {
          return [requestType, requestDiscipline, responseIncorporated];
        }
      }
    }
    if (relatedTabType === 'deliverable') {
      return [
        drawingDiscipline,
        drawingType,
        deliverableLocation,
        validityStatusDeliverable,
        latestDeliverableRevisionStatus,
        scheduledDateEarlierThan,
      ];
    }
    if (relatedTabType === 'document') {
      return [disciplineDD, latestDdSet, buildingDD, validityStatusDocument];
    }
    if (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
          validityStatusSubmittal,
        ];
      }
      if (part === 'successor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
          validityStatusSubmittal,
        ];
      }
    }
  }

  if (currentCard === 'Deliverable') {
    if (relatedTabType === 'request') {
      if (selectedShowType === 'outstanding' || selectedShowType === 'open') {
        return [
          requestType,
          requestDiscipline,
          nfStatus,
          validityStatusRequest,
          responseIncorporated,
          requestOverdue,
          dueDateLaterThan2DaysBeforeScheduledDate,
        ];
      }
      if (selectedShowType === 'all') {
        return [
          requestType,
          requestDiscipline,
          nfStatus,
          validityStatusRequest,
          responseIncorporated,
          localIncorporation,
          requestOverdue,
          dueDateLaterThan2DaysBeforeScheduledDate,
        ];
      }
      if (selectedShowType === 'accepted') {
        return [
          requestType,
          requestDiscipline,
          validityStatusRequest,
          responseIncorporated,
          localIncorporation,
          dueDateLaterThan2DaysBeforeScheduledDate,
        ];
      }
      if (selectedShowType === 'incorporated in this level/typicality') {
        return [requestType, requestDiscipline, validityStatusRequest, responseIncorporated, dueDateLaterThan2DaysBeforeScheduledDate];
      }
      if (selectedShowType === 'accepted, not incorporated in this level/typicality') {
        return [];
      }
    }
    if (relatedTabType === 'deliverable') {
      if (part === 'predecessor') {
        return [validityStatusDeliverable];
      }
      if (part === 'successor') {
        return [];
      }
    }
    if (relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
          validityStatusSubmittal,
        ];
      }
      if (part === 'successor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
        ];
      }
    }
  }

  if (currentCard === 'Submittal') {
    if (relatedTabType === 'request') {
      return [requestType, requestDiscipline, nfStatus, validityStatusRequest, requestOverdue, responseIncorporated];
    }
    if (relatedTabType === 'deliverable') {
      if (part === 'predecessor') {
        return [drawingDiscipline, drawingType, deliverableLocation, validityStatusDeliverable, latestDeliverableRevisionStatus];
      }
      if (part === 'successor') {
        return [drawingDiscipline, drawingType, deliverableLocation, latestDeliverableRevisionStatus];
      }
    }
    if (relatedTabType === 'document') {
      return [disciplineDD, latestDdSet, buildingDD, validityStatusDocument];
    }
    if (relatedTabType === 'submittalInput') {
      if (part === 'predecessor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
        ];
      }
      if (part === 'successor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
        ];
      }
    }
    if (relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        if (selectedRequest?.is_output) {
          return [
            specificationSection,
            submittalType,
            responsibleManagerCompany,
            responsibleManager,
            responsibleContractorCompany,
            responsibleContractor,
            submittalStatus,
            validityStatusSubmittal,
          ];
        }
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
        ];
      }
      if (part === 'successor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleManager,
          responsibleContractorCompany,
          responsibleContractor,
          submittalStatus,
        ];
      }
    }
  }
};

export const getFilteredData = (data, filteringParams, checkIfOverdue?, part?) => {
  return data.filter(item => {
    return filteringParams.every(({ filterName, options }) => {
      return applyFilter(item, filterName, options, checkIfOverdue, part);
    });
  });
};

const applyFilter = (snippet, filterName, options, checkIfOverdue, part) => {
  const checkParam = snippet => {
    if (snippet.flags?.validity_status === 'not_verified') {
      return snippet.flags?.validity_alert;
    }
    return snippet.flags?.validity_status;
  };

  switch (filterName) {
    // request-snippet
    case 'NfStatus':
      return options.includes(snippet.status);
    case 'requestDiscipline':
      return options.includes(snippet.request_discipline?.id ? `requestDiscipline${snippet.request_discipline?.id}` : 'empty_discipline');
    case 'requestOverdue':
      return options.includes(
        // moment(new Date()).diff(snippet.due_date, 'days') > 0 && !snippet.due_date_change_request
        moment(new Date()).isAfter(snippet.due_date) && snippet?.status !== 'accepted' ? 'requestOverdue-true' : 'requestOverdue-false',
      );
    case 'dueDateThanThisNF':
      const overdueData = checkIfOverdue(snippet, part);
      return options.includes(overdueData?.isOverdue ? 'dueDateThanThisNF-true' : 'dueDateThanThisNF-false');
    case 'requestType':
      return options.includes(snippet.tags?.[0]?.id ? `requestType${snippet.tags?.[0]?.id}` : 'empty_request_type');
    case 'responseIncorporated':
      return options.includes(snippet.is_incorporated ? 'responseIncorporated-true' : 'responseIncorporated-false');
    case 'localIncorporation':
      return options.includes(snippet?.flags?.is_incorporated ? 'localIncorporation-true' : 'localIncorporation-false');
    case 'validityStatusRequest':
      return options.includes(checkParam(snippet) || 'empty_validity_status');
    case 'dueDateLaterThan2DaysBeforeScheduledDate':
      return options.includes(
        snippet?.flags?.ovd_nf ? 'dueDateLaterThan2DaysBeforeScheduledDate-true' : 'dueDateLaterThan2DaysBeforeScheduledDate-false',
      );

    // deliverable-snippet
    case 'drawingDiscipline':
      return options.includes(snippet.discipline?.id ? `drawingDiscipline${snippet.discipline?.id}` : 'empty_drawing_discipline');
    case 'drawingType':
      return options.includes(snippet.type?.id ? `drawingType${snippet.type?.id}` : 'empty_drawing_type');
    case 'deliverableLocation':
      return options.includes(snippet?.owner?.id ? `deliverableLocation${snippet?.owner?.id}` : 0);
    case 'validityStatusDeliverable':
      return options.includes(checkParam(snippet) || 'empty_validity_status');
    case 'latestDeliverableRevisionStatus':
      const ucFirst = str => {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
      };
      return options.includes(
        snippet.status === 'not_issued_yet' ? 'Not issued yet' : `${ucFirst(snippet.status)} | ${ucFirst(snippet.review_state)}`,
      );
    case 'scheduledDateEarlierThan':
      return options.includes(snippet.flags?.ovd_nf ? 'scheduledDateEarlierThan-true' : 'scheduledDateEarlierThan-false');

    // submittal-snippet
    case 'specificationSection':
      return options.includes(snippet.mf_code || 'empty_specification_section');
    case 'submittalType':
      return options.includes(snippet.type || 'empty_submittal_type');
    case 'responsibleManagerCompany':
      return options.includes(
        snippet.responsible_party?.id ? `responsibleManagerCompany${snippet.responsible_party?.id}` : 'empty_responsible_company',
      );
    case 'responsibleManager':
      return options.includes(
        snippet.responsible_user?.id ? `responsibleManager${snippet.responsible_user?.id}` : 'empty_responsible_manager',
      );
    case 'responsibleContractorCompany':
      return options.includes(
        snippet.contractor_party?.id ? `responsibleContractorCompany${snippet.contractor_party?.id}` : 'empty_contractor_company',
      );
    case 'responsibleContractor':
      return options.includes(
        snippet.contractor_user?.id ? `responsibleContractor${snippet.contractor_user?.id}` : 'empty_contractor_manager',
      );
    case 'submittalStatus':
      return options.includes(snippet.status?.title || 'empty_submittal_status');
    case 'validityStatusSubmittal':
      return options.includes(checkParam(snippet) || 'empty_validity_status');

    // document-snippet
    case 'disciplineDD':
      return options.includes(snippet.discipline?.id ? `disciplineDD${snippet.discipline?.id}` : 'empty_discipline');
    case 'latestDdSet':
      return options.includes(snippet.current_set || 'empty_latestDdSet');
    case 'buildingDD':
      return options.includes(snippet.building[0]?.id ? `buildingDD${snippet.building[0]?.id}` : 'empty_building');
    // todo validityStatusDocument
    // case 'validityStatusDocument':
    //   return options.includes(checkParam(snippet) || 'empty_validity_status');

    default:
      return false;
  }
};
