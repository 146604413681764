import React from 'react';
import Popover from '@material-ui/core/Popover';
import SVG from 'react-inlinesvg';
import './customPopoverStyles.scss';

class CustomPopover extends React.Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { open, anchorEl, onClose, contentStyles, children, id, title, styles, hideTitle, extraProps, className, titleEl } = this.props;
    const styleEl = titleEl === 'comments' ? { padding: '20px' } : contentStyles;
    return (
      <Popover
        id={id || `id-${Math.random() * 1000}`}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: styles || {},
        }}
        {...extraProps}
      >
        <div className={`b-popover ${className || ''}`}>
          {!hideTitle && (
            <div className={'popoverTitle'}>
              <h6>{title || 'Title'}</h6>
              <div className={'popupActionsBtnClose'} onClick={onClose}>
                <SVG src={require('../../../assets/icons/remove_icon.svg')} />
              </div>
            </div>
          )}
          <div className="popupContent" style={styleEl}>
            {children}
          </div>
        </div>
      </Popover>
    );
  }
}

export default CustomPopover;
