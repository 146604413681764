import React from 'react';
import StatusNFsnippet from '../../Status/StatusNFsnippet/StatusNFsnippet';
import cn from 'classnames';

interface IProps {
  themeClass: string;
  marker: 'new' | 'upd';
  is1100Resolution: boolean;
  isShortView?: boolean;
  isTablet?: boolean;
  isDeliverable?: boolean;
}

const MarkerBlock = ({ themeClass, marker, is1100Resolution, isShortView, isTablet, isDeliverable }: IProps) => {
  return (
    <div className={cn(`${themeClass}_endField`, { ['-isShortView']: isShortView && !isTablet, ['-isDeliverable']: isDeliverable })}>
      {(marker === 'new' || marker === 'upd') && (
        <div className={`${themeClass}_endField_unreadMarker`}>
          <StatusNFsnippet type={marker} size={is1100Resolution && !isDeliverable ? 'sm' : 'lg'} />
        </div>
      )}
    </div>
  );
};

export default MarkerBlock;
