import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import {
  ActivationTabType,
  FileModel,
  MentionModel,
  LocationCollectionModel,
  MfViewDataModel,
  PartyModel,
  RequestBuildingModel,
  RequestDisciplineModel,
  RequestModel,
  RequestUserModel,
  RequestTypeModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
  UfViewDataModel,
  UserModel,
  WatcherModel,
  WatcherGroupModel,
} from '../../../../../models';
import RequestTab from '../../tabs/Request/RequestTab';
import ResponseTab from '../../tabs/Response/ResponseTab';
import RelatedItemsTab from '../../../RelatedItemsTab/RelatedItemsTab';
import { FILE_SIZE_LIMIT } from '../../../../../service/file';
import { IInitialFields } from '../../CardRequest';
import moment from 'moment';
import { deleteSpaces } from '../../../../../helpers/commonHelpers';
import * as validator from '../../../../../service/validator';
import { NeedListRequest } from '../../../../../store/needlist/needListModels';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import { CheckBox, TextInputNew } from '../../../../controls';
import { declineCounter, responseSave } from '../../../../../store/request/requestLogic(HOLD)';
import { useAppSelector, useAppDispatch } from '../../../../../store/configure/configureStore';
import { IConfirmDialogContext, withConfirmDialogContext } from '../../../HighOrderComponents';
import { MilestoneItemModel } from '../../../../../models/phasePlan/milestone.model';
import CommentsContainer from '../../../Comments/CommentsContainer';
import HistoryAction from '../../../../controls/HistoryAction/HistoryAction';
import { uniq } from 'lodash';
import BaseHelper from '../../../../../helpers/BaseHelper';
import { collectMentions, removeMentionByUserId } from '../../../../../helpers/DescriptionFieldHelper/MentionsHelper';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import IncorporationTab from '../../tabs/Incorporation/IncorporationTab';
import { IFeedData } from '../../../../../store/comments/commentReducer.model';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import SegmentedButton from '../../../../controls/ButtonComponents/SegmentedButton/SegmentedButton';
import { changePrivate } from '../../../../../store/common/commonThunk';
import { ImpactModel } from '../../../../controls/ImpactSelect/ImpactSelect';
import Divider from '../../../../controls/Divider/Divider';
import HiddenRelations from '../../../RelatedItemsTab/controls/HiddenRelations/HiddenRelations';
import { handleOpenCardHelper } from '../../../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';
import { HiddenRelationModelNf } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';
import { RouterHelperComponentProps, withRouterHelper } from '../../../../../navigation/withRouterHelper/withRouterHelper';
import RequestTabButtons from '../../tabs/Request/RequestTabButtons';
import ResponseTabButtons from '../../tabs/Response/ResponseTabButtons';

import './BottomCardPartStyles.scss';

const { setIsChangedFinalValue, setCommitmentAction } = requestSlice.actions;

export type RelatedItemsTabsType = 'request' | 'deliverable' | 'document' | 'submittalInput' | 'submittalOutput';

const workingOptions = [
  /*{
    id: 'document',
    title: 'Design drawings',
  },*/
  {
    id: 'request',
    title: 'Need Forms',
  },
  {
    id: 'submittalInput',
    title: 'Input submittals',
    statusType: 'new',
  },
  {
    id: 'deliverable',
    title: 'CL drawings',
  },
  {
    id: 'submittalOutput',
    title: 'Output submittals',
    statusType: 'new',
  },
];

interface BottomCardPartProps extends IConfirmDialogContext, RouterHelperComponentProps {
  selectedRequest: RequestModel & NeedListRequest;
  requestLoading: boolean;
  isAccessToCommitment: boolean;
  isAccess: boolean;
  activeTab: ActivationTabType;
  isDragRequestCard?: boolean;
  isHiddenFields?: boolean;
  isPullRequest: boolean;
  changeAnimationBtn: (nameBtn: string) => void;
  animationBtn: string | null;
  setInitialFields: (obj: any) => void;
  initialFields: IInitialFields;
  buildings: RequestBuildingModel[];
  selectedBuildings: number[];
  buildingSpaces: SimpleTitleModel[];
  buildingSpacesSelected: number[];
  projectDisciplines: RequestDisciplineModel[];
  projectParties: PartyModel[];
  milestones: MilestoneItemModel[];
  activeCommitmentAction: string | null;
  active_project_id: number | string;
  selectedRequestCompany: SelectedRequestCompanyModel;
  selectedResponseCompany: SelectedRequestCompanyModel;
  currentUfcCode: UfViewDataModel[];
  currentMfCode: MfViewDataModel[];
  currentProjectSpecific: string;
  currentDate: string | Date;
  currentPCD: number[];
  hashtags: TagFormModel[];
  isRfi: boolean;
  setIsRfi: (isRfi: boolean) => void;
  predecessorId: number | null;
  add_documents_nfSelected: number[];
  selectRequestId: number | null;
  is_procore_sync_needed: boolean | number;
  tags: RequestTypeModel[];
  selectedTags: RequestTypeModel;
  milestone_id: number;
  isHashTagChanging: boolean;
  setIsHashTagChanging: (isHashTagChanging: boolean) => void;
  isChangedNow: boolean;
  setIsChangedNow: (isChangedNow: boolean) => void;
  onRequestSave: (requestId: number, bodyParams, callback?, isNotFormData?: boolean) => void;
  handleShowConfirmDialog?: (options) => void;
  selectedRequestId: number | boolean;
  isUpdateRequest: boolean;
  setIsUpdateRequest: (isUpdateRequest: boolean) => void;
  openPromptStatus: (
    promptTitle: string,
    promptMessage: string,
    promptName: string,
    promptNameBtnOk: string | null,
    promptNameBtnCancel: string | null,
    isShowAgain: boolean,
  ) => void;
  is_promptPopUpPull: boolean;
  setIs_promptPopUpPull: (is_promptPopUpPull: boolean) => void;
  is_msg_not_show_again: boolean;
  setIsMsgNotShowAgain: (isMsgNotShowAgain: boolean) => void;
  toggleShared: () => void;
  savePromptsAlerts: (promptName: string) => void;
  canUpdate: boolean;
  promptName: string;
  is_promptBtnOk: boolean;
  handleSwitchCalendar: (currentTarget: React.RefObject<any>) => void;
  handleOpenChangeCompany: (currentTarget: React.RefObject<any>) => void;
  requestCurrentDescription: string;
  setRequestCurrentDescription: (text: string) => void;
  isRequestCurrentDescription: boolean;
  setIsRequestCurrentDescription: (isRequestCurrentDescription: boolean) => void;
  userInfo: UserModel;
  currentDescription: string;
  files: FileModel[] | FileList | any;
  animationLoading: boolean;
  onKeyPress: () => void;
  setDescription: (descriptionValue: string, delta: any, source: any, editor: any) => void;
  isUser: boolean;
  onChangeActiveTab: (value: string) => void;
  openRequest: (id: number, isNavToSubtask?: boolean) => void;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => void;
  handleCloseAuthorUser: () => void;
  isOpenDeclinePopup: boolean;
  handleOpenDeclinePopup: (value: boolean) => void;
  handleRequestDecline: (msg: string, saveKeepData: boolean, savePreserveInc: boolean) => void;
  isDueDateDisabled: boolean;
  isPullRequestOpenByAction: boolean;
  setMainErrors: (value: any) => void;
  feedData: IFeedData;
  incorporationHistory: string[];
  levelsSelected: LocationCollectionModel[];
  typicalLayoutsSelected: any[];
  levels: LocationCollectionModel[];
  isSeparateTab: boolean;
  bodyContainerRef: any;
  isCreateRequest: boolean;
  isPrivateChanged: boolean;
  isSkeletonLoading: boolean;
  setIsPrivateChanged: (state: boolean) => void;
  requestWatchers: WatcherModel[];
  requestWatchersGroups: WatcherGroupModel[];
  handleGlobalIncorporation: () => void;
  clImpactsSelected: ImpactModel[];
  submittal_ids: number[];
  activeRelatedItem: RelatedItemsTabsType;
  isDeactivated: boolean;
  previewCard: boolean;
}

export type Mention = {
  id: number;
  email: string;
};

const BottomCardPart: React.FC<BottomCardPartProps> = ({
  activeTab,
  isDragRequestCard,
  isHiddenFields,
  openRequest,
  selectedRequest,
  handleShowConfirmDialog,
  requestLoading,
  isAccessToCommitment,
  isAccess,
  isPullRequest,
  changeAnimationBtn,
  animationBtn,
  setInitialFields,
  initialFields,
  buildings,
  selectedBuildings,
  buildingSpaces,
  buildingSpacesSelected,
  projectDisciplines,
  projectParties,
  milestones,
  activeCommitmentAction,
  active_project_id,
  selectedRequestCompany,
  selectedResponseCompany,
  currentUfcCode,
  currentMfCode,
  currentProjectSpecific,
  currentDate,
  currentPCD,
  hashtags,
  isRfi,
  setIsRfi,
  predecessorId,
  add_documents_nfSelected,
  selectRequestId,
  is_procore_sync_needed,
  tags,
  selectedTags,
  milestone_id,
  isHashTagChanging,
  setIsHashTagChanging,
  isChangedNow,
  setIsChangedNow,
  onRequestSave,
  selectedRequestId,
  isUpdateRequest,
  setIsUpdateRequest,
  openPromptStatus,
  is_promptPopUpPull,
  setIs_promptPopUpPull,
  is_msg_not_show_again,
  setIsMsgNotShowAgain,
  toggleShared,
  savePromptsAlerts,
  canUpdate,
  promptName,
  is_promptBtnOk,
  handleSwitchCalendar,
  handleOpenChangeCompany,
  requestCurrentDescription,
  setRequestCurrentDescription,
  isRequestCurrentDescription,
  setIsRequestCurrentDescription,
  userInfo,
  isUser,
  onChangeActiveTab,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
  isOpenDeclinePopup,
  handleOpenDeclinePopup,
  handleRequestDecline,
  isDueDateDisabled,
  isPullRequestOpenByAction,
  setMainErrors,
  feedData,
  incorporationHistory,
  levelsSelected,
  typicalLayoutsSelected,
  levels,
  isSeparateTab,
  bodyContainerRef,
  isCreateRequest,
  isPrivateChanged,
  setIsPrivateChanged,
  requestWatchers,
  requestWatchersGroups,
  handleGlobalIncorporation,
  clImpactsSelected,
  submittal_ids,
  activeRelatedItem,
  isSkeletonLoading,
  submittal_ids,
  activeRelatedItem,
  isDeactivated,
  routerHelper,
  previewCard,
}) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-cardRequest-bottomCardPart');
  const themeBottomClass = useGetThemeClass('b-cardRequest-requestTab');
  const { is1440Resolution, isMobile } = GetResolution();
  const { getNfParamsFromURL } = useRoutingHelper();

  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo);
  const comments = useAppSelector(state => state.commentReducer.comments);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);
  const watchersGroups = useAppSelector(state => state.requestReducer.watchersGroups);

  const [errors, setErrors] = useState<any>({});
  const [isKeyPress, setIsKeyPress] = useState<boolean>(false);
  const [animationLoading, setAnimationLoading] = useState<boolean>(false);
  const [loadingFilesName, setLoadingFilesName] = useState<SetStateAction<string>[]>([]);
  const [delete_files, setDelete_files] = useState<number[]>(null);
  const [moreLimitFiles, setMoreLimitFiles] = useState<FileModel[] | FileList | any>([]);

  // request states
  const [requestMentions, setRequestMentions] = useState<Mention[]>([]);
  const [requestFiles, setRequestFiles] = useState<FileModel[] | FileList | any>([]);

  // response states
  const [responseCurrentDescription, setResponseCurrentDescription] = useState<string>('');
  const [isResponseCurrentDescription, setIsResponseCurrentDescription] = useState<boolean>(false);
  const [isResponseCurrentDescriptionChanged, setIsResponseCurrentDescriptionChanged] = useState<boolean>(false);
  const [responseMentions, setResponseMentions] = useState<Mention[]>([]);
  const [responseFiles, setResponseFiles] = useState<FileModel[] | FileList | any>([]);

  // discussion states
  const [discussionCurrentDescription, setDiscussionCurrentDescription] = useState<string>('');
  const [isDiscussionCurrentDescription, setIsDiscussionCurrentDescription] = useState<boolean>(false);
  const [discussionMentions, setDiscussionMentions] = useState<Mention[]>([]);

  // decline popup states
  const [declineMsg, setDeclineMsg] = useState<string>('');
  const [declineMsgError, setDeclineMsgError] = useState<boolean>(false);
  const [saveKeepData, setSaveKeepData] = useState<boolean>(false);
  const [prevRequestLoading, setPrevRequestLoading] = useState(false);
  const [savePreserveInc, setSavePreserveInc] = useState<boolean>(false);

  // related items
  const [selectedRelatedItems, setSelectedRelatedItems] = useState<RelatedItemsTabsType>('request');

  useEffect(() => {
    if (!requestLoading && requestLoading !== prevRequestLoading) {
      setAnimationLoading(false);
      setLoadingFilesName([]);
      setDelete_files(null);
    }
    setPrevRequestLoading(requestLoading);
  }, [requestLoading, prevRequestLoading]);

  useEffect(() => {
    if (activeRelatedItem) {
      setSelectedRelatedItems(activeRelatedItem);
    }
  }, [activeRelatedItem]);

  useEffect(() => {
    setMainErrors(errors);
  }, [errors]);

  useEffect(() => {
    setIsRequestCurrentDescription(false);
    setIsResponseCurrentDescription(false);
    setIsDiscussionCurrentDescription(false);

    setIsKeyPress(false);
  }, [selectedRequest]);

  useEffect(() => {
    if (isPrivateChanged) {
      onRequestChangePrivate(1);
      setIsPrivateChanged(false);
    }
  }, [isPrivateChanged]);

  useEffect(() => {
    if (selectedRequest && selectedRequest.files && selectedRequest.files.length) {
      setRequestFiles(selectedRequest.files);
    } else {
      setRequestFiles([]);
    }

    if (selectedRequest && selectedRequest.response_files && selectedRequest.response_files.length) {
      setResponseFiles(selectedRequest.response_files);
    } else {
      setResponseFiles([]);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if ((promptName === 'RequestUpdate' || promptName === 'RequestSend') && is_promptBtnOk) {
      setIsUpdateRequest(false);
      requestSave(1);
    }
    if (promptName === 'requestClickOutSide' && is_promptBtnOk) {
      requestSave(0);
    }
  }, [is_promptBtnOk]);

  useEffect(() => {
    if (selectedRequest && selectedRequest.mentions) {
      setRequestMentions(selectedRequest.mentions.map(mention => ({ id: mention.user_id, email: mention.email })));
    }
    if (selectedRequest && selectedRequest.response_mentions) {
      setResponseMentions(selectedRequest.response_mentions.map(mention => ({ id: mention.user_id, email: mention.email })));
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (isMobile) {
      setSelectedRelatedItems('request');
    }
  }, [isMobile]);

  const changeMentions = (newMentions: { id: number; email: string }[]) => {
    if (activeTab === 'request') {
      setRequestMentions(newMentions);
    }
    if (activeTab === 'response') {
      setResponseMentions(newMentions);
    }
    if (activeTab === 'discussion') {
      setDiscussionMentions(newMentions);
    }
  };

  const setRequestDescription = (descriptionValue: string, delta: any, source: any, editor: any) => {
    if (requestLoading) {
      return;
    }

    if (descriptionValue !== '<p><br></p>') {
      const updatedErrors = { ...errors };
      delete updatedErrors?.desc;
      delete updatedErrors?.files;
      setErrors(updatedErrors);
    }

    if (!isRequestCurrentDescription && requestCurrentDescription) {
      setRequestCurrentDescription(descriptionValue);
      setIsRequestCurrentDescription(true);
    } else {
      setRequestCurrentDescription(descriptionValue);
    }

    if (isKeyPress && editor) {
      changeMentions(collectMentions(editor.getContents()));
    }
  };

  const setResponseDescription = (descriptionValue: string, delta: any, source: any, editor: any) => {
    if (requestLoading) {
      return;
    }

    const isChanged = descriptionValue !== initialFields?.final_desc;

    if (!isResponseCurrentDescription && !responseCurrentDescription && selectedRequest?.response_desc) {
      setResponseCurrentDescription(descriptionValue);
      setIsDiscussionCurrentDescription(true);
      setInitialFields({
        ...(initialFields || {}),
        final_desc: descriptionValue,
      });

      return;
    }

    dispatch(setIsChangedFinalValue(isChanged));

    setResponseCurrentDescription(descriptionValue);
    setIsResponseCurrentDescriptionChanged(isChanged);

    if (isKeyPress) {
      changeMentions(collectMentions(editor.getContents()));
    }
  };

  const onKeyPress = () => {
    setIsKeyPress(true);
  };

  const mentionsAdapter = (mentions: Mention[] | MentionModel[]) => (mentions ? mentions.map(mention => mention.email) : []);

  const validatorObject = {
    required: ['response_desc'],
    requiredOne: [],
  };

  const saveResponseAction = (requestId, bodyParams, callback, isUpdateStatus = false) => {
    dispatch(responseSave({ requestId, bodyParams, callback, isUpdateStatus }));
  };

  const commitmentSave = (isSubmit: boolean, files?: FileModel[] | FileList | any, isDraft?: boolean, filesToDelete?) => {
    let saveObject = {
      response_desc:
        (isResponseCurrentDescriptionChanged ? responseCurrentDescription || '' : selectedRequest && selectedRequest.response_desc) || '',
      mentions: selectedRequest.response_mentions,
      submit: isSubmit ? 1 : 0,
    };

    if (filesToDelete) {
      saveObject['delete_files'] = filesToDelete;
    }
    if (files) {
      saveObject = { ...saveObject, ...files };
    }

    const isStringSpacesOnly = deleteSpaces(saveObject.response_desc) === '<p></p>';

    if (saveObject.response_desc == '<p><br></p>' || isStringSpacesOnly) {
      saveObject.response_desc = '';
    }

    const responseOldMentions = mentionsAdapter(selectedRequest?.response_mentions);

    if (responseMentions.length) {
      saveObject.mentions = Array.from(new Set(mentionsAdapter(responseMentions).concat(responseOldMentions)));
    } else {
      saveObject.mentions = mentionsAdapter(responseOldMentions);
    }

    const requestId = selectedRequest && selectedRequest.id;

    const newMentionIds = responseMentions ? responseMentions.map(mention => parseInt(mention.id, 10)) : [];

    const onAllow = () => {
      const { errors } = validator.validate(validatorObject, saveObject);
      if (Object.values(errors).length && isSubmit) {
        setErrors(errors);
        return;
      }

      saveResponseAction(requestId, saveObject, () => {
        dispatch(setCommitmentAction(''));
      });
    };

    handleConfirmAddMentions(newMentionIds, onAllow);
  };

  const handleMoreLimitFiles = files => {
    setMoreLimitFiles(files);
  };

  const handleFile = (e, isDrag?: boolean, isRestrict?: boolean, name?: string) => {
    if (requestLoading) {
      return;
    }
    let filesEl;
    if (isDrag) {
      if (isRestrict) {
        return;
      }
      filesEl = e;
    } else {
      filesEl = e.target.files;
    }
    if (!filesEl?.length) {
      return;
    }
    const filteredFiles: any = Array.from(filesEl).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    const moreLimitFiles: any = Array.from(filesEl).filter((f: any) => f.size > FILE_SIZE_LIMIT);
    filteredFiles.forEach(f => (f.isNew = true));

    if (activeTab === 'request') {
      if (filteredFiles?.length > 0) {
        setRequestFiles(requestFiles => [...requestFiles, ...filteredFiles]);
        setAnimationLoading(true);
        setLoadingFilesName(
          Array.from(filesEl)
            .filter((f: any) => f.size < FILE_SIZE_LIMIT)
            .map(el => el.name),
        );

        requestSave(selectedRequest?.is_whiteboard || 0, filesEl);
      }
      if (moreLimitFiles?.length > 0) {
        handleMoreLimitFiles(moreLimitFiles);
      }
    }
    if (activeTab === 'response') {
      if (filteredFiles?.length > 0) {
        setResponseFiles(responseFiles => [...responseFiles, ...filteredFiles]);
        setAnimationLoading(true);
        setLoadingFilesName(
          Array.from(filesEl)
            .filter((f: any) => f.size < FILE_SIZE_LIMIT)
            .map(el => el.name),
        );

        commitmentSave(false, { ['upload_files']: Array.from(filesEl).filter((f: any) => f.size < FILE_SIZE_LIMIT) }, true, null);
      }
      if (moreLimitFiles?.length > 0) {
        handleMoreLimitFiles(moreLimitFiles);
      }
    }

    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.desc;
      delete updatedErrors.files;
      return updatedErrors;
    });
  };

  const delSavedFile = async (file_id, isBulk?) => {
    if (activeTab === 'request') {
      setDelete_files(isBulk ? file_id : [file_id]);
      const filteredRequestFiles = isBulk
        ? requestFiles.filter(item => file_id.includes(item.id))
        : requestFiles.filter(item => item.id !== file_id);

      if (filteredRequestFiles?.length) {
        setErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.desc;
          delete updatedErrors.files;
          return updatedErrors;
        });
      }
      requestSave(selectedRequest?.is_whiteboard || 0, null, isBulk ? file_id : [file_id]);
    }

    if (activeTab === 'response') {
      setDelete_files(isBulk ? file_id : [file_id]);
      const filteredResponseFiles = isBulk
        ? responseFiles.filter(item => file_id.includes(item.id))
        : responseFiles.filter(item => item.id !== file_id);
      setResponseFiles(filteredResponseFiles);
      commitmentSave(false, null, true, isBulk ? file_id : [file_id]);
    }
  };

  const handleDownloadAll = e => {
    const downloadAllFiles = activeTab === 'request' ? requestFiles : responseFiles;

    const { project_id } = getNfParamsFromURL();
    e.preventDefault();
    downloadAllFiles.map((file, i) => {
      setTimeout(async () => {
        const fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_req_${file.title}`;
        await BaseHelper.downloadFile(file.url, fileName);
      }, i * 50);
    });
  };

  const handleConfirmDeleteAllNonPrivateEntities = (
    userIds: number[],
    companyIds: number[],
    onAllow: (isValidate) => void,
    onCancel?: () => void,
  ) => {
    if (isRfi) {
      handleShowConfirmDialog({
        title: 'Make request private?',
        message:
          'Making this NF private will remove all watchers/mentions/BICs other than members of request/response companies. Do you want to continue?',
        userIds,
        companyIds,
        onAllow,
        onCancel,
        checkNotRfi: true,
        needUpdateState: false,
        actualValidationFields: {
          actual_request_party_id: selectedRequestCompany?.company?.id,
          actual_response_party_id: selectedResponseCompany?.company?.id,
        },
      });
    } else {
      onAllow();
    }
  };

  const handleConfirmAddMentions = (
    userIds: number[],
    onAllow: (isValidate, additionalSendObjectFields) => void,
    onCancel?: () => void,
  ) => {
    handleShowConfirmDialog({
      title: 'Request private request change?',
      message: 'NF will cease to be private and will be available to others.',
      userIds,
      onAllow,
      onCancel,
      needUpdateState: false,
      actualValidationFields: {
        actual_request_party_id: selectedRequestCompany?.company?.id,
        actual_response_party_id: selectedResponseCompany?.company?.id,
      },
    });
  };

  const requestSavePrompt = (whiteboard: number, files?) => {
    if (localStorage.getItem('RequestSend') === 'yes') {
      requestSave(1);
    } else {
      const saveObject = collectSaveObject(whiteboard, files);
      const validationData = {
        ...saveObject,
        files: (selectedRequest && selectedRequest.files) || files,
      };
      const { errors } = validator.validate(validatorMask, validationData);
      if (Object.values(errors).length && saveObject.is_whiteboard) {
        setErrors(errors);
        return;
      }
      setIs_promptPopUpPull(true);
    }
  };

  const openPromptRequestStatus = (nameBtn: string) => {
    if (nameBtn === 'RequestUpdate') {
      openPromptStatus(
        'Update this NF?',
        'All involved parties will be notified about changes you have made.',
        nameBtn,
        'Yes, update',
        'Keep editing',
        true,
      );
    }
    if (nameBtn === 'RequestSend') {
      requestSavePrompt(1);
    }
    if (nameBtn === 'RequestSend') {
      setIsUpdateRequest(false);
    } else {
      setIsUpdateRequest(true);
    }
  };

  const validatorMask = {
    required: ['response_party_id', 'request_party_id', 'due_date', 'specific'],
    requiredOne: ['desc', 'files', 'upload_files'],
  };

  const collectSaveObject = (whiteboard, files, filesToDelete?: number[]) => {
    const saveObject = {
      project_id: active_project_id || sessionStorage.getItem('active_project_id'),
      request_party_id: selectedRequestCompany?.company?.id || '',
      response_party_id: selectedResponseCompany?.company?.id || '',
      commit: isPullRequestOpenByAction ? 1 : 0,
      ufc_ids:
        currentUfcCode?.map(item => {
          return JSON.stringify({
            id: item.id,
            type: item.type,
          });
        }) ||
        selectedRequest?.ufc_ids ||
        '',
      mfc_ids: currentMfCode?.map(item => item.id) || selectedRequest?.mfc_ids || '',
      desc: requestCurrentDescription || (selectedRequest && selectedRequest.desc) || '',
      specific: currentProjectSpecific || '',

      link: (selectedRequest && selectedRequest.link) || 'link',
      // system: (selectedRequest && selectedRequest.system) || 'system',
      due_date:
        (currentDate && moment(currentDate).format('YYYY-MM-DD')) ||
        (selectedRequest && selectedRequest.due_date && selectedRequest.due_date) ||
        '',
      is_whiteboard: whiteboard ? 1 : 0,
      deliverable_ids: currentPCD && currentPCD,
      hashtags: hashtags
        .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
        .map(hashTag => {
          const tag = {
            name: hashTag.label,
            color: hashTag.color,
            link: hashTag.link,
          };
          return JSON.stringify(tag);
        }),
      cl_impacts: '',
      reset_cl_impacts: 0,
    } as any;

    if (clImpactsSelected.length) {
      const impactsObj = {};

      clImpactsSelected.forEach(item => {
        impactsObj[item.alias] = item.status;
      });

      saveObject.cl_impacts = JSON.stringify(impactsObj);
      delete saveObject.reset_cl_impacts;
    } else {
      saveObject.reset_cl_impacts = 1;
      delete saveObject.cl_impacts;
    }

    if (selectedResponseCompany?.user?.id) {
      saveObject.response_user_id = selectedResponseCompany.user.id;
    }

    if (selectedResponseCompany?.discipline?.id) {
      saveObject.resp_disc_id = selectedResponseCompany.discipline.id;
    }

    if (selectedRequestCompany?.discipline?.id) {
      saveObject.req_disc_id = selectedRequestCompany.discipline.id;
    }

    if (selectedRequestCompany?.user?.id) {
      saveObject.user_id = selectedRequestCompany.user.id;
    }

    if (submittal_ids?.length) {
      saveObject.submittal_ids = submittal_ids;
    }

    saveObject.isPullRequest = isPullRequest;

    if (filesToDelete) {
      saveObject['delete_files'] = filesToDelete;
    }

    if (files) {
      saveObject['upload_files'] = Array.from(files).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    }
    if (predecessorId) {
      saveObject.predecessor_id = predecessorId;
    }

    if (add_documents_nfSelected) {
      saveObject.document_id = add_documents_nfSelected;
    }
    if (selectRequestId) {
      saveObject.is_whiteboard = whiteboard ? 1 : 0;
    }
    if (selectedBuildings) {
      saveObject.buildings = selectedBuildings.map(i => i.id);
    }
    if (buildingSpacesSelected) {
      saveObject.building_spaces = buildingSpacesSelected.map(i => i.id);
    }
    if (levelsSelected) {
      saveObject.levels = levelsSelected.map(i => i.id);
    }
    if (typicalLayoutsSelected) {
      saveObject.typicalLayouts = typicalLayoutsSelected.map(i => i.id);
    }
    if (requestMentions) {
      saveObject.mentions = requestMentions.map(mention => mention.email);
    }

    saveObject.tag_ids = selectedTags ? [selectedTags.id] : selectedRequest?.tags?.map(t => t.id) || [];
    saveObject.milestone_id = milestone_id || selectedRequest?.milestone_id || '';

    const isStringSpacesOnly = deleteSpaces(saveObject.desc) === '<p></p>';

    if (saveObject.desc == '<p><br></p>' || isStringSpacesOnly) {
      saveObject.desc = '';
    }

    if (isCreateRequest) {
      saveObject.is_rfi = isRfi ? 1 : 0;
    }

    return saveObject;
  };

  const requestSave = (whiteboard: number, files?, filesToDelete?: number[]) => {
    const newMentionIds = requestMentions ? requestMentions.map(mention => mention.id) : [];

    const onAllow = (isValidate, additionalSendObjectFields) => {
      if (!isValidate) {
        setIsRfi(false);
      }
      setIsHashTagChanging(false);

      let validationFiles = (selectedRequest && selectedRequest.files) || files;

      if (filesToDelete && filesToDelete.length) {
        validationFiles = validationFiles.filter(file => !filesToDelete?.includes(file.id));
      }

      const saveObject = collectSaveObject(whiteboard, files, filesToDelete);

      const validationData = {
        ...saveObject,
        files: validationFiles,
      };

      const { errors } = validator.validate(validatorMask, validationData);
      if (Object.values(errors).length && saveObject.is_whiteboard) {
        setErrors(errors);
        setRequestFiles(requestFiles?.filter(file => !file.isNew));
        return;
      }
      if (whiteboard === 1) {
        changeAnimationBtn('sendBtn');
      } else {
        changeAnimationBtn('draftBtn');
      }
      setErrors({});

      onRequestSave(
        selectRequestId,
        { ...saveObject, ...(additionalSendObjectFields || {}) },
        () => {
          if (!isPullRequest) {
            setIsChangedNow(false);
          }
          dispatch(setCommitmentAction(''));
        },
        false,
      );

      setRequestFiles([...(selectedRequest?.files || []), ...(files || [])].filter(file => !filesToDelete?.includes(file.id)));
    };

    if (isRfi) {
      handleConfirmAddMentions(newMentionIds, onAllow);
    } else {
      onAllow(true, {});
    }
  };

  const onRequestChangePrivate = (whiteboard: number) => {
    const newMentionIds = requestMentions ? requestMentions.map(mention => mention.id) : [];
    const newResponseMentionIds = responseMentions ? responseMentions.map(mention => mention.id) : [];

    const commentMentions = comments?.comments.reduce((acc, comment) => {
      if (comment.mentions?.length) {
        const commentMentionsIds = comment.mentions.map(mention => mention.user_id);
        acc = [...acc, ...commentMentionsIds];
      }

      return acc;
    }, []);

    const savedAllMentions = selectedRequest?.all_mentions?.map(mention => mention.user_id) || [];

    const allSavedSelectedRequestMentions = uniq([...savedAllMentions, ...commentMentions]) || [];
    const allSelectedRequestMentions = allSavedSelectedRequestMentions
      ? uniq([...newMentionIds, ...allSavedSelectedRequestMentions])
      : newMentionIds;
    const allSelectedResponseMentions = allSavedSelectedRequestMentions
      ? uniq([...newResponseMentionIds, ...allSavedSelectedRequestMentions])
      : newResponseMentionIds;
    const allSelectedRequestResponseMentions = [...allSelectedRequestMentions, ...allSelectedResponseMentions];

    const commentsUserCompanyIds = comments?.ballInCort.reduce(
      (acc, bic) => {
        const targetTypeArray = bic.owner === 'company' ? 'companyIds' : 'userIds';
        acc[targetTypeArray] = [...acc.companyIds, bic.owner_id];

        return acc;
      },
      {
        userIds: [],
        companyIds: [],
      },
    );

    const usersFromGroups = requestWatchersGroups?.flatMap(group => {
      return [...(watchersGroups.find(g => g.id === group.id)?.users || [])];
    });

    const allUsers = [...(requestWatchers?.map(item => item.user) || []), ...(usersFromGroups || [])];
    const watchersUsersIds = [];

    allUsers.forEach(user => {
      if (!watchersUsersIds.some(u => u.id === user.id)) {
        watchersUsersIds.push(user.id);
      }
    });

    const onAllowRequest = (isValidate, additionalSendObjectFields) => {
      setIsHashTagChanging(false);

      const saveObject = collectSaveObject(whiteboard, null);

      const validationData = {
        ...saveObject,
      };

      const { errors } = validator.validate(validatorMask, validationData);
      if (Object.values(errors).length && saveObject.is_whiteboard) {
        setErrors(errors);
        setRequestFiles(requestFiles?.filter(file => !file.isNew));
        return;
      }
      if (whiteboard === 1) {
        changeAnimationBtn('sendBtn');
      } else {
        changeAnimationBtn('draftBtn');
      }
      setErrors({});

      if (
        additionalSendObjectFields &&
        (additionalSendObjectFields.watchers_to_delete ||
          additionalSendObjectFields.bic_to_delete ||
          additionalSendObjectFields.mentions_to_delete)
      ) {
        if (additionalSendObjectFields.mentions_to_delete) {
          saveObject.desc = removeMentionByUserId(additionalSendObjectFields.mentions_to_delete, saveObject.desc);
          setRequestCurrentDescription(removeMentionByUserId(additionalSendObjectFields.mentions_to_delete, saveObject.desc));
        }
        onRequestSave(
          selectRequestId,
          { ...saveObject, ...(additionalSendObjectFields || {}) },
          () => {
            if (!isPullRequest) {
              setIsChangedNow(false);
            }
            dispatch(setCommitmentAction(''));
          },
          false,
        );
      }
      dispatch(changePrivate({ owner_type: 'request', owner_id: selectedRequest?.id, is_private: isRfi }));
    };

    const onAllowResponse = (isValidate, additionalSendObjectFields) => {
      const saveObject = {
        response_desc:
          (isResponseCurrentDescriptionChanged ? responseCurrentDescription || '' : selectedRequest && selectedRequest.response_desc) || '',
        mentions: selectedRequest.response_mentions,
        submit: 0,
      };

      const isStringSpacesOnly = deleteSpaces(saveObject.response_desc) === '<p></p>';

      if (saveObject.response_desc == '<p><br></p>' || isStringSpacesOnly) {
        saveObject.response_desc = '';
      }

      const responseOldMentions = mentionsAdapter(selectedRequest?.response_mentions);

      if (responseMentions.length) {
        saveObject.mentions = Array.from(new Set(mentionsAdapter(responseMentions).concat(responseOldMentions)));
      } else {
        saveObject.mentions = mentionsAdapter(responseOldMentions);
      }

      const requestId = selectedRequest && selectedRequest.id;

      if (
        additionalSendObjectFields &&
        (additionalSendObjectFields.watchers_to_delete ||
          additionalSendObjectFields.bic_to_delete ||
          additionalSendObjectFields.mentions_to_delete)
      ) {
        if (additionalSendObjectFields.mentions_to_delete) {
          saveObject.response_desc = removeMentionByUserId(additionalSendObjectFields.mentions_to_delete, saveObject.response_desc);
        }
        saveResponseAction(requestId, { ...saveObject, ...(additionalSendObjectFields || {}) }, () => {
          dispatch(setCommitmentAction(''));
        });
      }
      dispatch(changePrivate({ owner_type: 'request', owner_id: selectedRequest?.id, is_private: isRfi }));
    };

    const onAllowDeleteNonReqResEntities = isValidate => {
      const additionalSendObjectFields = {
        watchers_to_delete: [],
        bic_to_delete: [],
        mentions_to_delete: [],
        mentions: [],
      };

      if (!isValidate) {
        const requestResponsePartyUserIds = projectParties?.reduce((acc, party) => {
          if (party.id === selectedRequestCompany?.company?.id || party.id === selectedResponseCompany?.company?.id) {
            const usersIds = party.users.map(user => user.id);
            acc = [...acc, ...usersIds];
          }

          return acc;
        }, []);

        const nonRequestResponsePartyCompanyIds = projectParties?.reduce((acc, party) => {
          if (party.id !== selectedRequestCompany?.company?.id && party.id !== selectedResponseCompany?.company?.id) {
            acc = [...acc, party.id];
          }

          return acc;
        }, []);

        additionalSendObjectFields.watchers_to_delete = watchersUsersIds.filter(userId => !requestResponsePartyUserIds.includes(userId));
        additionalSendObjectFields.mentions_to_delete = allSelectedRequestResponseMentions.filter(
          userId => !requestResponsePartyUserIds.includes(userId),
        );

        const requiredMentions = activeTab === 'response' ? responseMentions : requestMentions;
        additionalSendObjectFields.mentions = requiredMentions.reduce((acc, mention) => {
          if (requestResponsePartyUserIds.includes(mention.id)) {
            acc = [...acc, mention.email];
          }

          return acc;
        }, []);

        additionalSendObjectFields.bic_to_delete = comments?.ballInCort.reduce((acc, bic) => {
          const isBicNotReferToReqResPartyCheck =
            bic.owner === 'company'
              ? nonRequestResponsePartyCompanyIds.includes(bic.owner_id)
              : !requestResponsePartyUserIds.includes(bic.owner_id);

          if (isBicNotReferToReqResPartyCheck) {
            acc = [...acc, bic.bic_id];
          }

          return acc;
        }, []);
      }

      Object.keys(additionalSendObjectFields).forEach(field => {
        if (!additionalSendObjectFields[field].length) {
          delete additionalSendObjectFields[field];
        }
      });

      activeTab === 'response'
        ? onAllowResponse(isValidate, additionalSendObjectFields)
        : onAllowRequest(isValidate, additionalSendObjectFields);
    };

    if (isRfi) {
      const WatchersUsersIds = watchersUsersIds ? watchersUsersIds : [];
      const newUserIds = [
        ...allSelectedRequestMentions,
        ...allSelectedResponseMentions,
        ...commentsUserCompanyIds.userIds,
        ...WatchersUsersIds,
      ].filter(item => !!item);
      const newCompanyIds = [...commentsUserCompanyIds.companyIds].filter(item => !!item);
      handleConfirmDeleteAllNonPrivateEntities(newUserIds, newCompanyIds, onAllowDeleteNonReqResEntities, () => setIsRfi(false));
    } else {
      handleConfirmAddMentions(newMentionIds, activeTab === 'response' ? onAllowResponse : onAllowRequest, () => setIsRfi(true));
    }
  };

  const openDeclinePopup = e => {
    e.preventDefault();
    handleOpenDeclinePopup(true);
    setDeclineMsg('');
    setSaveKeepData(false);
    setSavePreserveInc(false);
  };

  const handleCloseDeclinePopup = () => {
    handleOpenDeclinePopup(false);
    setDeclineMsg('');
    setDeclineMsgError(false);
    setSaveKeepData(false);
    setSavePreserveInc(false);
  };

  const changeHandlerDeclineMsg = (value: string) => {
    setDeclineMsg(value);
    setDeclineMsgError(false);
  };

  const changeHandlerKeepData = () => {
    setSaveKeepData(!saveKeepData);
  };

  const changeSavePreserveInc = () => {
    setSavePreserveInc(!savePreserveInc);
  };

  const handleDecline = () => {
    if (declineMsg && declineMsg.trim() && !declineMsgError) {
      changeAnimationBtn('declineDeliverableBtn');
      handleRequestDecline(declineMsg.trim(), saveKeepData, savePreserveInc);
      setResponseCurrentDescription('');
      handleCloseDeclinePopup();

      setTimeout(() => {
        dispatch(declineCounter({ id: selectRequestId }));
      }, 400);
    } else {
      setDeclineMsg('');
      setDeclineMsgError(true);
    }
  };
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const handleChangeRelatedTabType = (id: string) => {
    setSelectedRelatedItems(id as RelatedItemsTabsType);
    setIsChanged(true);
  };

  const renderHistoryAction = (
    type: 'send' | 'updated' | '',
    date: string | Date,
    firstName: string,
    lastName: string,
    userProfile: RequestUserModel,
    isBeforeText: boolean,
    company?: string,
    companyImagePath?: string,
  ) => {
    return (
      <HistoryAction
        type={type}
        date={date}
        firstName={firstName}
        lastName={lastName}
        handleOpenAuthorUser={handleOpenAuthorUser}
        handleCloseAuthorUser={handleCloseAuthorUser}
        userProfile={userProfile}
        isBeforeText={isBeforeText}
        isShowTime={!company}
        company={company}
        companyImagePath={companyImagePath}
      />
    );
  };

  const dialogClass = useGetThemeClass('dialogPopUp');
  const isSidePeekView = isDragRequestCard || is1440Resolution;

  const showFooterHiddenRelations = useMemo(() => {
    if (isDeactivated) {
      return false;
    }

    const hiddenRelations: HiddenRelationModelNf = relatedTabData.card?.hidden_relation || null;

    if (selectedRelatedItems === 'request') {
      return (
        hiddenRelations?.predecessors_unlink?.length ||
        hiddenRelations?.successors_unlink?.length ||
        hiddenRelations?.predecessors_deactivated?.length ||
        hiddenRelations?.successors_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'submittalInput') {
      return (
        hiddenRelations?.submittals_input_predecessor_unlink?.length ||
        hiddenRelations?.submittals_input_successor_unlink?.length ||
        hiddenRelations?.submittals_input_predecessor_deactivated?.length ||
        hiddenRelations?.submittals_input_successor_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'deliverable') {
      return hiddenRelations?.deliverables_unlink?.length || hiddenRelations?.deliverables_deactivated?.length;
    }

    if (selectedRelatedItems === 'submittalOutput') {
      return (
        hiddenRelations?.submittals_output_predecessor_unlink?.length ||
        hiddenRelations?.submittals_output_successor_unlink?.length ||
        hiddenRelations?.submittals_output_predecessor_deactivated?.length ||
        hiddenRelations?.submittals_output_successor_deactivated?.length
      );
    }
  }, [relatedTabData.card?.hidden_relation, selectedRelatedItems]);

  const nfFooterHiddenRelations = {
    request: [
      { predecessors_unlink: 'Unlinked predecessors:' },
      { predecessors_deactivated: 'Deactivated predecessors:' },
      { successors_unlink: 'Unlinked successors:' },
      { successors_deactivated: 'Deactivated successors:' },
    ],
    submittalInput: [
      { submittals_input_predecessor_unlink: 'Unlinked predecessors:' },
      { submittals_input_predecessor_deactivated: 'Deactivated predecessors:' },
      { submittals_input_successor_unlink: 'Unlinked successors:' },
      { submittals_input_successor_deactivated: 'Deactivated successors:' },
    ],

    deliverable: [{ deliverables_unlink: 'Unlinked successors:' }, { deliverables_deactivated: 'Deactivated successors:' }],
    submittalOutput: [
      { submittals_output_predecessor_unlink: 'Unlinked predecessors:' },
      { submittals_output_predecessor_deactivated: 'Deactivated predecessors:' },
      { submittals_output_successor_unlink: 'Unlinked successors:' },
      { submittals_output_successor_deactivated: 'Deactivated successors:' },
    ],
  };

  const openCard = card => {
    handleOpenCardHelper(true, 'Nf', selectedRequest, card, dispatch, routerHelper, 'firstCardBreadcrumbs');
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          ['-sidePeek']: isSidePeekView,
          ['-isHiddenFields']: isHiddenFields,
        })}
      >
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'request' })}>
          <RequestTab
            selectedRequest={selectedRequest}
            requestLoading={requestLoading}
            isAccess={isAccess}
            isDragRequestCard={isSidePeekView}
            activeTab={activeTab}
            isHiddenFields={isHiddenFields}
            isPullRequest={isPullRequest}
            currentDescription={requestCurrentDescription || (selectedRequest && selectedRequest.desc) || ''}
            errors={errors}
            requestFiles={requestFiles}
            requestMoreLimitFiles={moreLimitFiles}
            handleRequestMoreLimitFiles={handleMoreLimitFiles}
            loadingFilesName={loadingFilesName}
            handleFile={handleFile}
            delSavedFile={delSavedFile}
            handleDownloadAll={handleDownloadAll}
            animationLoading={animationLoading}
            onKeyPress={onKeyPress}
            setDescription={setRequestDescription}
            requestSave={requestSave}
            is_promptPopUpPull={is_promptPopUpPull}
            setIs_promptPopUpPull={setIs_promptPopUpPull}
            is_msg_not_show_again={is_msg_not_show_again}
            setIsMsgNotShowAgain={setIsMsgNotShowAgain}
            toggleShared={toggleShared}
            savePromptsAlerts={savePromptsAlerts}
            renderHistoryAction={renderHistoryAction}
            isSkeletonLoading={isSkeletonLoading}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'response' })}>
          <ResponseTab
            isDragRequestCard={isSidePeekView}
            activeTab={activeTab}
            isHiddenFields={isHiddenFields}
            selectedRequest={selectedRequest}
            requestLoading={requestLoading}
            isAccessToCommitment={isAccessToCommitment}
            isAccess={isAccess}
            changeAnimationBtn={changeAnimationBtn}
            animationBtn={animationBtn}
            openPromptStatus={openPromptStatus}
            isUser={isUser}
            errors={errors}
            handleFile={handleFile}
            delSavedFile={delSavedFile}
            handleDownloadAll={handleDownloadAll}
            setDescription={setResponseDescription}
            responseCurrentDescription={responseCurrentDescription}
            setResponseCurrentDescription={setResponseCurrentDescription}
            setIsResponseCurrentDescriptionChanged={setIsResponseCurrentDescriptionChanged}
            isResponseCurrentDescriptionChanged={isResponseCurrentDescriptionChanged}
            responseFiles={responseFiles}
            responseMoreLimitFiles={moreLimitFiles}
            handleResponseMoreLimitFiles={handleMoreLimitFiles}
            animationLoading={animationLoading}
            loadingFilesName={loadingFilesName}
            responseMentions={responseMentions}
            promptName={promptName}
            is_promptBtnOk={is_promptBtnOk}
            onKeyPress={onKeyPress}
            setErrors={setErrors}
            onChangeActiveTab={onChangeActiveTab}
            handleOpenAuthorUser={handleOpenAuthorUser}
            handleCloseAuthorUser={handleCloseAuthorUser}
            openRequest={openRequest}
            openDeclinePopup={openDeclinePopup}
            commitmentSave={commitmentSave}
            renderHistoryAction={renderHistoryAction}
            activeCommitmentAction={activeCommitmentAction}
            isSkeletonLoading={isSkeletonLoading}
          />
        </div>
        <div
          className={cn(`${themeClass}_tabComponent -relatedItems`, {
            ['-active']: activeTab === 'related-items',
            ['-impact']: userInfo.qc_user,
          })}
        >
          {!isMobile && (
            <SegmentedButton
              selectedButtonId={selectedRelatedItems}
              buttons={workingOptions}
              changeHandler={handleChangeRelatedTabType}
              customClassName={isSidePeekView ? `${themeClass}_customClassSegmentedButton` : ''}
            />
          )}
          {isMobile && (
            <Select
              isMulti={false}
              menuItems={workingOptions.filter(
                option => option.id !== 'submittalInput' && option.id !== 'submittalOutput' && option.id !== 'document',
              )}
              selectHandler={selected => {
                handleChangeRelatedTabType(selected.id);
              }}
              selected={workingOptions.find(option => option.id === selectedRelatedItems)}
              menuItemSize={'sm'}
              isMobile={isMobile}
              renderCustomBasis={(isActive, disabled, isLoading, selected) => (
                <div className={`${themeClass}_relatedSelect`}>
                  <span className={`${themeClass}_relatedSelect_title`}>{selected?.title}</span>
                  <TokenIcon iconName={isActive ? 'chevron-up' : 'chevron-down'} size={24} />
                </div>
              )}
            />
          )}
          <RelatedItemsTab
            isDragRequestCard={isSidePeekView}
            selectedRequest={selectedRequest}
            isSeparateTab={isSeparateTab}
            relatedTabType={selectedRelatedItems}
            currentCard={'Nf'}
            isCreateRequest={isCreateRequest}
            isDeactivated={isDeactivated}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            previewCard={previewCard}
          />
          {showFooterHiddenRelations && (
            <div className={`${themeClass}_footer`}>
              <Divider direction={'horizontal'} type={'srf-5'} />
              <div className={`${themeClass}_footer_hiddenRelationsBlock`}>
                {nfFooterHiddenRelations[selectedRelatedItems].map((obj, index) => {
                  const name = Object.keys(obj)[0];
                  return (
                    <HiddenRelations
                      key={`${selectedRelatedItems}-${name}`}
                      type={selectedRelatedItems}
                      label={obj[name]}
                      propertyListName={name}
                      hiddenRelations={relatedTabData?.card?.hidden_relation || null}
                      handleOpenCard={openCard}
                      isMobile={isMobile}
                      isDragRequestCard={isSidePeekView}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'incorporation' })}>
          <IncorporationTab
            isDragRequestCard={isSidePeekView}
            selectedRequest={selectedRequest}
            levelsSelected={levelsSelected}
            typicalLayoutsSelected={typicalLayoutsSelected}
            feedData={feedData}
            incorporationHistory={incorporationHistory}
            buildings={buildings}
            levels={levels}
            userInfo={userInfo}
            isAccess={isAccess}
            bodyContainerRef={bodyContainerRef}
            handleGlobalIncorporation={handleGlobalIncorporation}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'discussion' })}>
          <CommentsContainer
            selectedRequest={selectedRequest}
            type={'request'}
            onChangeActiveTab={onChangeActiveTab}
            isDragCard={isSidePeekView}
            handleShowConfirmDialog={handleShowConfirmDialog}
            activeTab={activeTab}
            bodyContainerRef={bodyContainerRef}
          />
        </div>
      </div>
      <DialogPopUp
        isCard
        open={isOpenDeclinePopup}
        onClose={handleCloseDeclinePopup}
        title={'Decline the response?'}
        hideBackdrop={true}
        maxWidth={'xs'}
        renderModalContent={() => (
          <div className={`${themeClass}__dialogContent`}>
            <span className={`${dialogClass}_content_modalText`}>Please specify the reason of your decision:</span>
            <div className={'textField'} style={{ lineHeight: 0 }}>
              <TextInputNew
                inputType="text"
                type={'on-srf'}
                value={declineMsg || ''}
                onChange={changeHandlerDeclineMsg}
                error={declineMsgError && 'The reason field is required.'}
                autoComplete={'off'}
              />
            </div>
            <div onClick={changeHandlerKeepData} className={`${themeClass}__dialogCheckboxRow`}>
              <CheckBox checked={saveKeepData} />
              <span className={`${dialogClass}_content_modalText`}>Keep declined response description and files</span>
            </div>
            <div onClick={changeSavePreserveInc} className={`${themeClass}__dialogCheckboxRow`}>
              <CheckBox checked={savePreserveInc} />
              <span className={`${dialogClass}_content_modalText`}>Preserve current incorporation progress</span>
            </div>
          </div>
        )}
        secondaryText={'Cancel'}
        handleOnSecondary={handleCloseDeclinePopup}
        primaryText={'Yes, decline'}
        handleOnPrimary={handleDecline}
      />
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'request' })}>
        <RequestTabButtons
          selectedRequest={selectedRequest}
          isAccessToCommitment={isAccessToCommitment}
          changeAnimationBtn={changeAnimationBtn}
          animationBtn={animationBtn}
          selectRequestId={selectRequestId}
          projectInfo={projectInfo}
          selectedRequestCompany={selectedRequestCompany}
          openPromptRequestStatus={openPromptRequestStatus}
          requestSavePrompt={requestSavePrompt}
          canUpdate={canUpdate}
          isDueDateDisabled={isDueDateDisabled}
          isPullRequest={isPullRequest}
          requestLoading={requestLoading}
          requestSave={requestSave}
        />
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'response' })}>
        <ResponseTabButtons
          selectedRequest={selectedRequest}
          isDragRequestCard={isDragRequestCard}
          isAccessToCommitment={isAccessToCommitment}
          requestLoading={requestLoading}
          isUser={isUser}
          responseCurrentDescription={responseCurrentDescription}
          responseFiles={responseFiles}
          isAccess={isAccess}
          openPromptStatus={openPromptStatus}
          onChangeActiveTab={onChangeActiveTab}
          animationBtn={animationBtn}
          openDeclinePopup={openDeclinePopup}
        />
      </div>
    </>
  );
};

export default withRouterHelper(withConfirmDialogContext(BottomCardPart));
