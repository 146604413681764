import { BaseApi } from './baseApi';

class PhasePlanApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getSelectData() {
    const result = await this._get({
      route: 'project/select-data',
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getProjectData(id) {
    return await this._get({
      route: `project/${id}`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async createProject(data) {
    const result = await this._post({
      route: 'project/create',
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
    return result;
  }

  async updateProject(projectId, data) {
    const result = await this._update({
      route: `project/update/${projectId}`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async createBuilding(data) {
    const result = await this._post({
      route: `building/create`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async updateBuilding(data) {
    const result = await this._update({
      route: `building/${data.id}/update`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async deleteBuilding(building_id) {
    const result = await this._delete({
      route: `building/${building_id}/delete`,
      needAuth: true,
      // bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async updateBuildingLocation(data) {
    const result = await this._post({
      route: `phase-plan/update-locations`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async getStep2(projectId) {
    const result = await this._get({
      route: `phase-plan/step-2/${projectId}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getStep3(projectId) {
    const result = await this._get({
      route: `phase-plan/step-3/${projectId}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updateStep2({ projectId, formData }) {
    const result = await this._post({
      route: `phase-plan/save-step-2/${projectId}`,
      needAuth: true,
      bodyParams: { schedule: formData },
      devMode: this.devMode,
    });
    return result;
  }

  async updateStep3(formData) {
    const result = await this._update({
      route: `team/update-parties`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async createCompanyStep3({ formData }) {
    const result = await this._post({
      route: `phase-plan/create-party`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async addDiscipline(formData) {
    const result = await this._post({
      route: `team/add-discipline`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async createDiscipline({ formData, projectId }) {
    const result = await this._post({
      route: `team/create-discipline/${projectId}`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async removeDiscipline(formData) {
    const result = await this._post({
      route: `team/remove-discipline`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async addCompany(formData, with_disabled) {
    const result = await this._post({
      route: `team/add-party?with_disabled=${with_disabled || 0}`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async removeCompany(formData, with_disabled) {
    const result = await this._post({
      route: `team/remove-party?with_disabled=${with_disabled || 0}`,
      needAuth: true,
      bodyParams: formData,
      devMode: this.devMode,
    });
    return result;
  }

  async getPhasePlan(projectId) {
    const result = await this._get({
      route: `phase-plan/${projectId}/get-plan`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updatePhasePlan(data) {
    const result = await this._post({
      route: `phase-plan/${data.project_id}/update`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async updatePartyRole(data) {
    const result = await this._post({
      route: `team/update-party-role`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async savePhasePlanVersion(data) {
    const result = await this._post({
      route: `phase-plan/save-version`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async createPhasePlanField(data) {
    let path = '';
    if (data.table === 'schedules') {
      path = 'plan-schedule';
    } else if (data.table === 'permissions') {
      path = 'document-schedule';
    }
    const result = await this._post({
      route: `${path}/create`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async updatePhasePlanField(data) {
    let path = '';
    if (data.table === 'schedules') {
      path = 'plan-schedule';
    } else if (data.table === 'permissions') {
      path = 'document-schedule';
    }
    const result = await this._update({
      route: `${path}/update`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async deletePhasePlanField(data) {
    let path = '';
    if (data.table === 'schedules') {
      path = 'plan-schedule';
    } else if (data.table === 'permissions') {
      path = 'document-schedule';
    }
    const result = await this._delete({
      route: `${path}/delete`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async updateGMP(data) {
    const result = await this._update({
      route: `phase-plan/update`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async getPrevPhasePlan(id) {
    const result = await this._get({
      route: `phase-plan/${id}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setDriverTasks(data) {
    const result = await this._update({
      route: `plan-schedule/bulk-update`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async switchDisciplines(data) {
    const result = await this._update({
      route: `team/switch-disciplines`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async downloadPhasePlanVersion(data) {
    const result = await this._get({
      route: `phase-plan/download-pdf/${data}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
}

export default new PhasePlanApi({ devMode: false });
