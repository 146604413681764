import React, { Component } from 'react';
import { UserPermissionsModel } from '../../../../../models';

interface IProps {
  openItem: (item: string) => void;
}

interface IState {}

class MoreInformationHelp extends Component<IProps, IState> {
  render() {
    const { openItem } = this.props;

    return (
      <>
        <div className={'popup-content'}>
          <div className={'popup-content__text'}>
            <p style={{ marginBottom: '7px' }}>
              If you still have questions, get more information about how to use Planit Scheduler by referring to:
            </p>
            <ol style={{ marginLeft: '40px' }}>
              <li>
                <p style={{ marginLeft: '4px' }}>
                  <span onClick={() => openItem('what_new')} className={'highlightedWord'}>
                    What’s New
                  </span>{' '}
                  - for information about the latest changes and updates.
                </p>
              </li>
              <li>
                <p style={{ marginLeft: '4px' }}>
                  <span onClick={() => openItem('faq')} className={'highlightedWord'}>
                    FAQ
                  </span>{' '}
                  - for Frequently Asked Questions.
                </p>
              </li>
              <li>
                <p style={{ marginLeft: '4px' }}>
                  <span onClick={() => openItem('get_help')} className={'highlightedWord'}>
                    Contact
                  </span>{' '}
                  Form - for bug reporting, suggestions and questions to the Scheduler Team.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }
}

export default MoreInformationHelp;
