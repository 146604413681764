import React, { useState } from 'react';
import { IMultiAssignedComment } from './MultiAssignedComment.props';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import cn from 'classnames';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';

import './MultiAssignedComment.module.scss';

export const MultiAssignedComment: React.FC<IMultiAssignedComment> = ({
  courts,
  isEditMode = false,
  handleUnAssigmentUser,
  type,
  comment_id,
  comment,
  isCurrentUserInCourts,
  isCurrentUserAccessToUnassign,
  handleUnassignSelectOpen,
  isUnassignSelectOpen,
}) => {
  const themeClass = useGetThemeClass('multiAssignedContainer');

  const unAssignUserStatus = useAppSelector(state => state.commentReducer.unAssignUserStatus);

  const [selectedUsers, setSelectedUsers] = useState([]);

  /**
   * - You are assigned to this comment.
      - You and 1 other is assigned to this comment.
      - You and 2 others are assigned to this comment.
      - 1 user is assigned to this comment.
      - 2 users are assigned to this comment.
   */

  const handleSelectUser = selected => {
    setSelectedUsers(selected);
  };

  const isCourtsOthersPeople = courts.length - 1 !== 0;
  const isYouAndOthersPeopleInCourts = courts.length > 1;
  return (
    <div className={themeClass}>
      {!isEditMode ? (
        <>
          {isCurrentUserInCourts && courts.length === 1 && (
            <>
              {' '}
              <TokenIcon customClass={`${themeClass}_iconBic`} iconName={'at'} size={16} />
              You have been assigned.
            </>
          )}
          {isCurrentUserInCourts &&
            isYouAndOthersPeopleInCourts &&
            `You and ${courts.length - 1} other${courts.length > 2 ? 's' : ''} ${
              courts.length > 2 ? 'are' : 'is'
            } assigned to this comment.`}
          {!isCurrentUserInCourts &&
            `${courts.length} user${isYouAndOthersPeopleInCourts ? 's' : ''} ${
              courts.length >= 2 ? 'are' : 'is'
            } assigned to this comment.`}
          {isCurrentUserAccessToUnassign ? (
            <>
              {courts.length === 1 ? (
                <CustomButton
                  type={'text-plain'}
                  size={'sm'}
                  clickHandler={() => handleUnAssigmentUser(type, comment_id, isCurrentUserInCourts ? false : [courts[0].user_id])}
                  title={'Unassign'}
                  loading={unAssignUserStatus?.loading}
                  customClassName={cn(`${themeClass}_unAssign`, {
                    ['-loading']: unAssignUserStatus?.loading,
                    ['-show']: isUnassignSelectOpen,
                  })}
                />
              ) : (
                <Select
                  isMulti={true}
                  menuItems={courts.map(court => ({ id: court.user_id, title: court.user }))}
                  selectHandler={handleSelectUser}
                  selected={selectedUsers}
                  menuItemSize={'md'}
                  onMenuOpen={() => handleUnassignSelectOpen(comment_id)}
                  customSelectClass={`${themeClass}_select`}
                  customBasisContainerClass={`${themeClass}_selectBtn`}
                  onMenuClose={() => {
                    if (selectedUsers.length) {
                      handleUnAssigmentUser(
                        type,
                        comment_id,
                        selectedUsers.map(user => user.id),
                      );
                      setSelectedUsers([]);
                    }
                    handleUnassignSelectOpen();
                  }}
                  renderCustomBasis={(isActive, disabled, isLoading, selected) => (
                    <CustomButton
                      isActive={isActive}
                      disabled={disabled}
                      type={'text-plain'}
                      size={'sm'}
                      title={'Unassign'}
                      loading={unAssignUserStatus?.loading || isLoading}
                      customClassName={cn(`${themeClass}_unAssign`, {
                        ['-loading']: unAssignUserStatus?.loading,
                        ['-show']: isUnassignSelectOpen,
                      })}
                    />
                  )}
                />
              )}
            </>
          ) : (
            <>
              {isCurrentUserInCourts && (
                <CustomButton
                  type={'text-plain'}
                  size={'sm'}
                  clickHandler={() => handleUnAssigmentUser(type, comment_id, false)}
                  title={'Unassign'}
                  loading={unAssignUserStatus?.loading}
                  customClassName={cn(`${themeClass}_unAssign`, {
                    ['-loading']: unAssignUserStatus?.loading,
                    ['-show']: isUnassignSelectOpen,
                  })}
                />
              )}
            </>
          )}
        </>
      ) : (
        <span>After you reply you will be unassigned from this comment</span>
      )}
    </div>
  );
};
