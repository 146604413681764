import React, { Component } from 'react';

class Update_20220217_3 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0', width: '360px' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_05cb2a5dc008aa405672c3c157649462/images/image1_lY2.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'}>
          There is a possibility to turn off these prompts in notifications settings for more experienced users who already feel comfortable
          with an interface and want to avoid additional clicks.
        </div>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0', width: '600px' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_05cb2a5dc008aa405672c3c157649462/images/image7_WfO.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20220217_3;
