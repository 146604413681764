import React, { Component } from 'react';

class Updates_20230713_2 extends Component {
  render() {
    return (
      <div className={'unreadWhatsNewText'}>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0', display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ display: 'block', width: '192px', height: 'auto' }}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4131b35ac0c45602499a739bba83b2b0/images/image1_4MW.png'}
            alt=""
            width="192"
          />
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          Keep up to date with the communication happening between the project participants in Need Forms and Deliverables. Rest assured
          that you will never miss any comment that might be important to you!
        </div>
      </div>
    );
  }
}

export default Updates_20230713_2;
