import { useMediaQuery } from '@mui/material';

interface IPayload {
  is3840Resolution?: boolean;
  is2560Resolution?: boolean;
  is1440Resolution: boolean;
  is1100Resolution: boolean;
  is639Resolution: boolean;
  is390Resolution?: boolean;
  sizeResolution: string;
  isTablet: boolean;
  isMobile: boolean;
  sizeResolutionSnippet?: string;
}

export const GetResolution = (): IPayload => {
  const is2560Resolution = useMediaQuery('(max-width: 2560px)');
  const is1920Resolution = useMediaQuery('(max-width: 1920px)');
  const is1440Resolution = useMediaQuery('(max-width: 1440px)');
  const is960Resolution = useMediaQuery('(max-width: 960px)');
  const is1100Resolution = useMediaQuery('(max-width: 1100px)');
  const is1366Resolution = useMediaQuery('(max-width: 1366px)');
  const is1024Resolution = useMediaQuery('(max-width: 1024px)');
  const is640Resolution = useMediaQuery('(max-width: 640px)');
  const is639Resolution = useMediaQuery('(max-width: 639px), (max-height: 639px)');
  const is639ResolutionVertical = useMediaQuery('(max-height: 639px)');
  const is390Resolution = useMediaQuery('(max-width: 390px)');
  const isTablet = is1440Resolution || is1100Resolution;
  const isMobile = is639Resolution || is639ResolutionVertical;
  return {
    is2560Resolution,
    is1440Resolution,
    is1100Resolution,
    is639Resolution,
    is390Resolution,
    sizeResolution: is640Resolution
      ? '640'
      : is960Resolution
      ? '960'
      : is1024Resolution
      ? '1024'
      : is1366Resolution
      ? '1366'
      : is1440Resolution
      ? '1440'
      : is1920Resolution
      ? '1920'
      : '1920',
    isTablet,
    isMobile,
  };
};

export const GetResolutionMin = (): IPayload => {
  const is3840Resolution = useMediaQuery('(min-width: 3840px)');
  const is2560Resolution = useMediaQuery('(min-width: 2560px)');
  const is1920Resolution = useMediaQuery('(min-width: 1920px)');
  const is1440Resolution = useMediaQuery('(min-width: 1440px)');
  const is1919Resolution = useMediaQuery('(min-width: 1919px)');
  const is1600Resolution = useMediaQuery('(min-width: 1660px)');
  const is1439Resolution = useMediaQuery('(min-width: 1439px)');
  const is1200Resolution = useMediaQuery('(min-width: 1200px)');
  const is1199Resolution = useMediaQuery('(min-width: 1199px)');
  const is1025Resolution = useMediaQuery('(min-width: 1025px)');
  const is959Resolution = useMediaQuery('(min-width: 959px)');
  const is760Resolution = useMediaQuery('(min-width: 760px)');
  const is960Resolution = useMediaQuery('(min-width: 960px)');
  const is1100Resolution = useMediaQuery('(min-width: 1100px)');
  const is1024Resolution = useMediaQuery('(min-width: 1024px)');
  const is640Resolution = useMediaQuery('(min-width: 640px)');
  const is639Resolution = useMediaQuery('(min-width: 639px)');
  const isTablet = is1440Resolution && is1100Resolution;
  const isMobile = is639Resolution;
  return {
    is3840Resolution,
    is2560Resolution,
    is1440Resolution,
    is1100Resolution,
    is639Resolution,
    sizeResolution: is1920Resolution
      ? '1920'
      : is1440Resolution
      ? '1440'
      : is1024Resolution
      ? '1024'
      : is960Resolution
      ? '960'
      : is640Resolution
      ? '640'
      : 'mobile',
    sizeResolutionSnippet:
      (is1919Resolution || is1600Resolution) && !is1920Resolution
        ? '1024'
        : (is1439Resolution || is1200Resolution) && !is1440Resolution
        ? '1920'
        : (is1199Resolution || is1025Resolution) && !is1440Resolution
        ? '1024'
        : (is959Resolution || is760Resolution) && !is960Resolution
        ? '1024'
        : '',
    isTablet,
    isMobile,
  };
};
