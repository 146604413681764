import React, { Component } from 'react';

class Update_20230306_3 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image7.gif'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20230306_3;
