import React, { useEffect, useState } from 'react';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { LocationCollectionModel, RequestBuildingModel, SimpleTitleModel } from '../../../../../models';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import BuildingItem from '../BuildingItem/BuildingItem';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import LevelExpandItem from '../LevelsSelect/LevelExpandItem/LevelExpandItem';
import BuildingSpacesItem from '../BuildingSpacesItem/BuildingSpacesItem';

import './BuildingSelectStyles.scss';
import TypicalLayoutsExpandItem from '../LevelsSelect/TypicalLayoutsExpandItem/TypicalLayoutsExpandItem';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface BuildingSelectProps<T, G, IsMultiValue extends boolean> {
  isHeader?: boolean;
  isFooter?: boolean;
  selectHandler: (selected: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  menuItemSize: 'sm' | 'md';
  basisPlaceholder: string;
  customBasisFieldClass?: string;
  isFullWidth?: boolean;
  buildings: RequestBuildingModel[];
  levels: LocationCollectionModel[];
  customMenuClass?: string;
  levelsSelected: LocationCollectionModel[];
  typicalLayoutsSelected: any[];
  buildingSpaces: SimpleTitleModel[];
  buildingSpacesSelected: SimpleTitleModel[];
  setBuildingSpacesSelected: (value: SimpleTitleModel[]) => void;
  isOpenMenu?: boolean;
  isMobile?: boolean;
  isHighResolution?: boolean;
  disabled?: boolean;
  onMenuClose?: () => void;
}

function BuildingSelect<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  isHeader,
  isFooter,
  selectHandler,
  selected,
  isLoading,
  customBasisFieldClass,
  basisPlaceholder,
  menuItemSize,
  isFullWidth,
  buildings,
  levels,
  customMenuClass,
  levelsSelected,
  typicalLayoutsSelected,
  buildingSpaces,
  buildingSpacesSelected,
  setBuildingSpacesSelected,
  isOpenMenu,
  isMobile,
  disabled,
  onMenuClose,
  isHighResolution,
}: BuildingSelectProps<T, G, IsMultiValue>) {
  const themeClass = useGetThemeClass('b-buildingSelect');

  const [openedBuildingIds, setOpenedBuildingIds] = useState<number[]>([]);
  const [openedLevelsIds, setOpenedLevelsIds] = useState<string[]>([]);
  const [openedTypicalLayoutsIds, setOpenedTypicalLayoutsIds] = useState<string[]>([]);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [buildingForLevelsSelected, setBuildingForLevelsSelected] = useState<RequestBuildingModel[]>([]);
  const [localSearchValue, setLocalSearchValue] = useState<string>('');

  useEffect(() => {
    if (buildings?.length) {
      const newBuildingForLevelsSelected = [];

      levelsSelected.forEach(level => {
        if (!newBuildingForLevelsSelected?.find(item => item?.id === level.building_id)) {
          const newBuilding = buildings.find(building => building.id === level.building_id);
          newBuilding && newBuildingForLevelsSelected.push(newBuilding);
        }
      });

      typicalLayoutsSelected.forEach(typicalLayout => {
        if (!newBuildingForLevelsSelected?.find(item => item?.id === typicalLayout.building_id)) {
          const newBuilding = buildings.find(building => building.id === typicalLayout.building_id);
          newBuilding && newBuildingForLevelsSelected.push(newBuilding);
        }
      });

      setBuildingForLevelsSelected(newBuildingForLevelsSelected);
    }
  }, [levelsSelected]);

  useEffect(() => {
    if (!buildingForLevelsSelected?.length) {
      setBuildingSpacesSelected([]);
    }
    if (buildingForLevelsSelected?.length) {
      const buildingForLevelsSelectedIds = buildingForLevelsSelected.map(item => item?.id);

      const buildingSpaces = buildingSpacesSelected?.length
        ? buildingSpacesSelected.filter(item => buildingForLevelsSelectedIds.includes(item.building_id))
        : [];
      setBuildingSpacesSelected(buildingSpaces);
    }
  }, [buildingForLevelsSelected]);

  useEffect(() => {
    generateLevelsMenuItems();
  }, [
    levelsSelected,
    typicalLayoutsSelected,
    openedTypicalLayoutsIds,
    openedBuildingIds,
    openedLevelsIds,
    buildingSpacesSelected,
    buildingForLevelsSelected,
    localSearchValue,
  ]);

  const generateLevelsMenuItems = () => {
    const result = [];

    if (buildingForLevelsSelected.length) {
      buildingForLevelsSelected.forEach(building => {
        const isItemOpened: boolean = openedBuildingIds.includes(building?.id);
        if (localSearchValue) {
          const isBuildingIncludeSearchValue = building.locations.some(location => {
            return location.building_spaces.some(building_space => building_space.title.toLowerCase().includes(localSearchValue));
          });

          if (!isBuildingIncludeSearchValue) return;

          result.push({
            ...building,
            itemType: 'building',
            isItemOpened,
          });
        } else {
          result.push({
            ...building,
            itemType: 'building',
            isItemOpened,
          });
        }

        if (isItemOpened) {
          building?.locations.forEach(location => {
            if (levelsSelected.some(item => location.id === item.id)) {
              if (location?.building_spaces?.length) {
                const isLevelOpened: boolean = openedLevelsIds.includes(location.id);

                if (localSearchValue) {
                  const isLevelIncludeSearchValue = location.building_spaces.some(building_space =>
                    building_space.title.toLowerCase().includes(localSearchValue),
                  );

                  if (!isLevelIncludeSearchValue) return;

                  result.push({
                    ...location,
                    itemType: 'LevelExpandItem',
                    isItemOpened: isLevelOpened,
                  });
                } else {
                  result.push({
                    ...location,
                    itemType: 'LevelExpandItem',
                    isItemOpened: isLevelOpened,
                  });
                }

                if (isLevelOpened) {
                  location?.building_spaces.map(building_spaces => {
                    const buildingSpacesItem = buildingSpaces.find(item => item.id === building_spaces.id);
                    const isBuildingSpacesSelected = buildingSpacesSelected.find(item => item.id === building_spaces.id);

                    if (localSearchValue) {
                      const isBuildingSpaceIncludeSearchValue = building_spaces.title.toLowerCase().includes(localSearchValue);

                      if (!isBuildingSpaceIncludeSearchValue) return;

                      result.push({
                        ...buildingSpacesItem,
                        itemType: 'BuildingSpacesItem',
                        isSelected: isBuildingSpacesSelected,
                      });
                    } else {
                      result.push({
                        ...buildingSpacesItem,
                        itemType: 'BuildingSpacesItem',
                        isSelected: isBuildingSpacesSelected,
                      });
                    }
                  });
                }
              }
            }
          });

          building?.typical_layouts.forEach(typicalLayout => {
            if (typicalLayoutsSelected.some(item => typicalLayout.id === item.id)) {
              if (typicalLayout?.locations.some(location => !!location.building_spaces?.length)) {
                const isTypicalLayoutOpened: boolean = openedTypicalLayoutsIds.includes(typicalLayout.id);

                if (localSearchValue) {
                  const isTypicalLayoutIncludeSearchValue = typicalLayout.locations.some(location =>
                    location.building_spaces.some(building_space => building_space.title.toLowerCase().includes(localSearchValue)),
                  );

                  if (!isTypicalLayoutIncludeSearchValue) return;

                  result.push({
                    ...typicalLayout,
                    itemType: 'TypicalLayoutsExpandItem',
                    isItemOpened: isTypicalLayoutOpened,
                  });
                } else {
                  result.push({
                    ...typicalLayout,
                    itemType: 'TypicalLayoutsExpandItem',
                    isItemOpened: isTypicalLayoutOpened,
                  });
                }

                if (isTypicalLayoutOpened) {
                  typicalLayout?.locations.forEach(location => {
                    location.building_spaces.map(building_spaces => {
                      const buildingSpacesItem = buildingSpaces.find(item => item.id === building_spaces.id);
                      const isBuildingSpacesSelected = buildingSpacesSelected.find(item => item.id === building_spaces.id);

                      if (localSearchValue) {
                        const isBuildingSpaceIncludeSearchValue = building_spaces.title.toLowerCase().includes(localSearchValue);

                        if (!isBuildingSpaceIncludeSearchValue) return;

                        result.push({
                          ...buildingSpacesItem,
                          itemType: 'BuildingSpacesItem',
                          isSelected: isBuildingSpacesSelected,
                        });
                      } else {
                        result.push({
                          ...buildingSpacesItem,
                          itemType: 'BuildingSpacesItem',
                          isSelected: isBuildingSpacesSelected,
                        });
                      }
                    });
                  });
                }
              }
            }
          });
        }
      });
    }

    setMenuItems(result);
  };

  const handleBuildingItemClick = (building: PCDApi.PCDListBuildingInterface) => {
    const result = [...openedBuildingIds];

    if (result?.includes(building.id)) {
      result.splice(
        result.findIndex(id => id === building.id),
        1,
      );
    } else {
      result.push(building.id);
    }

    setOpenedBuildingIds(result);
  };

  const handleLevelExpandItemItemClick = (level: LocationCollectionModel) => {
    const result = [...openedLevelsIds];

    if (result?.includes(level.id)) {
      result.splice(
        result.findIndex(id => id === level.id),
        1,
      );
    } else {
      result.push(level.id);
    }

    setOpenedLevelsIds(result);
  };

  const handleBuildingSpaceItemClick = (building: SimpleTitleModel) => {
    const result = [...buildingSpacesSelected];
    const isBuildingSpaceExist = result.some(item => item.id === building.id);

    if (isBuildingSpaceExist) {
      result.splice(
        result.findIndex(item => item.id === building.id),
        1,
      );
    } else {
      result.push({
        building_id: building.building_id,
        id: building.id,
        title: building.title,
      });
    }

    setBuildingSpacesSelected(result);
  };

  const handleTypicalLayoutsExpandItemClick = (typicalLayouts: any) => {
    const result = [...openedTypicalLayoutsIds];

    if (result?.includes(typicalLayouts.id)) {
      result.splice(
        result.findIndex(id => id === typicalLayouts.id),
        1,
      );
    } else {
      result.push(typicalLayouts.id);
    }

    setOpenedTypicalLayoutsIds(result);
  };

  const renderSelectItem = (item: DropdownItemModel) => {
    if (item.itemType === 'building') {
      return (
        <>
          <BuildingItem building={item} handleBuildingItemClick={handleBuildingItemClick} />
        </>
      );
    }

    if (item.itemType === 'LevelExpandItem') {
      return (
        <>
          <LevelExpandItem level={item} handleLevelExpandItemItemClick={handleLevelExpandItemItemClick} />
        </>
      );
    }

    if (item.itemType === 'TypicalLayoutsExpandItem') {
      return (
        <>
          <TypicalLayoutsExpandItem typicalLayout={item} handleTypicalLayoutsExpandItemClick={handleTypicalLayoutsExpandItemClick} />
        </>
      );
    }

    if (item.itemType === 'BuildingSpacesItem') {
      return (
        <>
          <BuildingSpacesItem BuildingSpace={item} handleBuildingSpaceItemClick={handleBuildingSpaceItemClick} />
        </>
      );
    }

    return (
      <>
        <div>{item.title}</div>
      </>
    );
  };

  const handleClearAll = () => {
    setBuildingSpacesSelected([]);
  };
  const handleSelectAll = () => {
    const result = [...buildingSpacesSelected];

    levelsSelected.forEach(level => {
      level.building_spaces.forEach(building_space => {
        const buildingSpaceFromCollection = buildingSpaces.find(buildingSpace => buildingSpace.id === building_space.id);

        if (!result.some(item => item.id === buildingSpaceFromCollection.id)) {
          result.push(buildingSpaceFromCollection);
        }
      });
    });

    setBuildingSpacesSelected(result);
  };

  const handleChangeSearch = searchValue => {
    setLocalSearchValue(searchValue.toLowerCase());
  };

  const handleMenuClose = () => {
    setLocalSearchValue('');
    onMenuClose && onMenuClose();
  };

  return (
    <>
      <Select<T, G, IsMultiValue>
        isMulti={true}
        isCustomMulti={true}
        isHeader={isHeader}
        isFooter={isFooter}
        menuItems={menuItems || []}
        selectHandler={selectHandler}
        selected={selected}
        isLoading={isLoading}
        basisPlaceholder={basisPlaceholder}
        menuItemSize={menuItemSize}
        menuItemTitleRenderer={renderSelectItem}
        customBasisFieldClass={customBasisFieldClass}
        isFullWidth={isFullWidth}
        customMenuClass={customMenuClass}
        disabled={disabled}
        customClearAll={handleClearAll}
        customSelectAll={handleSelectAll}
        isHeaderNoMulti
        isOpenMenu={isOpenMenu}
        onMenuClose={handleMenuClose}
        isMobile={isMobile}
        basisMaxItems={1}
        onChangeSearch={handleChangeSearch}
        isHighResolution={isHighResolution}
      />
    </>
  );
}

export default BuildingSelect;
