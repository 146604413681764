import React, { FC } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import { ActivationTabType, FileModel, RequestModel, RequestUserModel } from '../../../../../models';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { CheckBox } from '../../../../controls';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import FileUploader from '../../../../controls/FileUploader/FileUploader';
import Divider from '../../../../controls/Divider/Divider';

import './RequestTabStyles.scss';

interface IProps {
  selectedRequest: RequestModel;
  requestLoading: boolean;
  isAccess: boolean;
  isDragRequestCard: boolean;
  activeTab: ActivationTabType;
  isHiddenFields: boolean;
  isPullRequest: boolean;
  currentDescription: string;
  errors: any;
  requestFiles: FileModel[] | FileList | any;
  requestMoreLimitFiles: FileModel[] | FileList | any;
  handleRequestMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
  loadingFilesName: string[];
  handleFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  handleDownloadAll: (e: any) => void;
  animationLoading: boolean;
  onKeyPress: () => void;
  setDescription: (descriptionValue: string, delta: any, source: any, editor: any) => void;
  requestSave: (whiteboard: number, files?, filesToDelete?: number[]) => void;
  is_promptPopUpPull: boolean;
  setIs_promptPopUpPull: (is_promptPopUpPull: boolean) => void;
  is_msg_not_show_again: boolean;
  setIsMsgNotShowAgain: (isMsgNotShowAgain: boolean) => void;
  toggleShared: () => void;
  savePromptsAlerts: (promptName: string) => void;
  renderHistoryAction: (
    type: 'send' | 'updated',
    date: string | Date,
    firstName: string,
    lastName: string,
    userProfile: RequestUserModel,
  ) => React.ReactElement;
  // isDueDateDisabled: boolean;
  isSkeletonLoading: boolean;
}

const RequestTab: FC<IProps> = ({
  selectedRequest,
  requestLoading,
  isAccess,
  isDragRequestCard,
  activeTab,
  isHiddenFields,
  isPullRequest,
  errors,
  requestFiles,
  requestMoreLimitFiles,
  handleRequestMoreLimitFiles,
  loadingFilesName,
  handleFile,
  delSavedFile,
  handleDownloadAll,
  animationLoading,
  currentDescription,
  onKeyPress,
  setDescription,
  requestSave,
  is_promptPopUpPull,
  setIs_promptPopUpPull,
  is_msg_not_show_again,
  setIsMsgNotShowAgain,
  toggleShared,
  savePromptsAlerts,
  renderHistoryAction,
  isSkeletonLoading,
}) => {
  const themeClass = useGetThemeClass('b-cardRequest-requestTab');

  const { isMobile } = GetResolution();

  const collectFinalFiles = selectedRequest?.files && requestFiles ? [...selectedRequest?.files, ...requestFiles] : [...requestFiles];

  const handleChange = (value, delta, source, editor) => {
    setDescription && setDescription(value, delta, source, editor);
  };

  const handleChangeFile = (e: any, isDrag?: boolean, isRestrict?: boolean) => {
    handleFile(e, isDrag, isRestrict);
  };

  const isDisableFileList = isPullRequest
    ? false
    : !isAccess || selectedRequest?.status === 'provided' || selectedRequest?.status === 'accepted' || requestLoading;

  const okPromptStatusPull = () => {
    savePromptsAlerts('RequestSend');
    setIs_promptPopUpPull(false);
    setIsMsgNotShowAgain(false);
    requestSave(1);
  };

  const cancelPromptStatusPull = () => {
    savePromptsAlerts('RequestSend');
    setIs_promptPopUpPull(false);
    setIsMsgNotShowAgain(false);
  };

  const closePromptStatusOutPull = () => {
    setIs_promptPopUpPull(false);
    setIsMsgNotShowAgain(false);
  };

  const dialogClass = useGetThemeClass('dialogPopUp');

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-sidePeekView']: isDragRequestCard,
        })}
        style={activeTab !== 'request' ? { display: 'none' } : {}}
      >
        <div className={`${themeClass}_container`}>
          <div className={cn(`${themeClass}_contentBlock`, {})}>
            <div className={`${themeClass}_mainBlock`}>
              {isSkeletonLoading ? (
                <>
                  <CustomSkeleton width={909} height={256} />
                  <CustomSkeleton width={443} height={256} />
                </>
              ) : (
                <>
                  <div className="textQuillEditor">
                    <TextQuillEditor
                      fieldId={`requestTab-${isPullRequest ? 'isPullRequest' : ''}`}
                      showToolbar={!isMobile}
                      value={currentDescription}
                      handleChange={handleChange}
                      placeholder={'Request description'}
                      disabled={
                        isPullRequest
                          ? false
                          : !isAccess || selectedRequest?.status === 'provided' || selectedRequest?.status === 'accepted'
                      }
                      customContainerClassName={cn(`${themeClass}_description`, {
                        ['-isHiddenFields']: !isMobile && isHiddenFields,
                      })}
                      onKeyPress={onKeyPress}
                      error={errors?.desc || null}
                    />
                  </div>
                  {isMobile && <Divider direction={'horizontal'} type={'srf-4'} />}
                  <FileUploader
                    isCard={true}
                    handleChangeFile={handleChangeFile}
                    isCardSidePeek={isDragRequestCard}
                    isHiddenFields={isHiddenFields}
                    collectFinalFiles={collectFinalFiles}
                    isDisableFileList={isDisableFileList}
                    files={requestFiles || []}
                    moreLimitFiles={requestMoreLimitFiles || []}
                    handleMoreLimitFiles={handleRequestMoreLimitFiles}
                    selectedRequest={selectedRequest}
                    source={'R'}
                    cardType={'nf'}
                    isEditable={true}
                    isEditMode={true}
                    delSavedFile={delSavedFile}
                    fileLoading={animationLoading}
                    loadingFilesName={loadingFilesName}
                    handleDownloadAll={handleDownloadAll}
                    customFileUploaderClassName={`${themeClass}_customRequestTabFileUploader${isDragRequestCard ? '_sidePeekView' : ''}`}
                  />
                </>
              )}
            </div>
            {isMobile && <Divider direction={'horizontal'} type={'srf-4'} />}

            {(selectedRequest?.action_dates?.sent?.date || selectedRequest?.action_dates?.update_request?.date) && (
              <div className={`${themeClass}_infoBlock`}>
                {selectedRequest?.action_dates?.sent?.date ? (
                  <div className={`${themeClass}_sentBy`}>
                    {renderHistoryAction(
                      'send',
                      selectedRequest?.action_dates?.sent?.date,
                      selectedRequest?.action_dates?.sent?.user?.profile?.first_name,
                      selectedRequest?.action_dates?.sent?.user?.profile?.last_name,
                      selectedRequest?.action_dates?.sent?.user,
                    )}
                  </div>
                ) : null}
                {selectedRequest?.action_dates?.update_request?.date ? (
                  <div className={`${themeClass}_updatedBy`}>
                    {renderHistoryAction(
                      'updated',
                      selectedRequest?.action_dates?.update_request?.date,
                      selectedRequest?.action_dates?.update_request?.user?.profile?.first_name,
                      selectedRequest?.action_dates?.update_request?.user?.profile?.last_name,
                      selectedRequest?.action_dates?.update_request?.user,
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <DialogPopUp open={is_promptPopUpPull} onClose={closePromptStatusOutPull} hideBackdrop={true} maxWidth={'xs'}>
        <div className={`${dialogClass}_dialogTitle`}>
          <div>Send this NF?</div>
          <div className={`${dialogClass}_closeIcon`} onClick={closePromptStatusOutPull}>
            <SystemButton type={'close'} size={'lg'} variant={'transparent'} />
          </div>
        </div>
        <div className={`${dialogClass}_content`}>
          <span className={`${dialogClass}_content_modalText`}>Response company will be notified and will have 24 hours to react.</span>
          <div onClick={toggleShared} className={`${themeClass}__dialogCheckboxRow`}>
            <CheckBox checked={is_msg_not_show_again} />
            <span className={`${dialogClass}_content_modalText`}>Do not show such messages again</span>
          </div>
        </div>
        <div className={`${dialogClass}_buttonsSection`}>
          <CustomButton clickHandler={cancelPromptStatusPull} type={'secondary'} title={'Keep editing'} size={'md'} />
          <CustomButton clickHandler={okPromptStatusPull} type={'primary'} title={'Yes, send'} size={'md'} />
        </div>
      </DialogPopUp>
    </>
  );
};

export default RequestTab;
