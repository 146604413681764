import React, { ReactChild, useMemo } from 'react';
import Color from 'color';
import SVG from 'react-inlinesvg';
import ChipsCompanyRole from '../../../controls/Chips/ChipsCompanyRole/ChipsCompanyRole';
import { useAppSelector } from '../../../../store/configure/configureStore';
import { ChipsDiscipline, ChipsStandardTag } from '../../../controls/Chips';
import cn from 'classnames';

import './BadgeStyles.scss';

type BadgeProps<T> = {
  item: T;
  colorField?: keyof T;
  type: 'role' | 'discipline' | 'mf';
  render: (item: T) => string | ReactChild;
  onClose?: () => void;
  customClasses?: string;
  width?: number | string;
  isMobile?: boolean;
};

const DEFAULT_COLOR_TAG = '#3091EB';
const MF_COLOR_TAG = '#4F6477';

const getColor = (color, defaultColor, type, theme) => {
  if (type === 'role') {
    return theme === 'dark' ? '#FFFFFF' : '#0C1627';
  } else {
    return Color(color || defaultColor).isLight() ? '#0C1627' : '#FFFFFF';
  }
};

const makeTagStyles = (color, defaultColor, type, theme) => ({
  background: type === 'role' ? 'transparent' : color || defaultColor,
  color: getColor(color, defaultColor, type, theme),
  border: `1px solid ${color || defaultColor}`,
});

const makeTagColorStyles = (color, defaultColor, type, theme) => ({
  color: getColor(color, defaultColor, type, theme),
});

export const Badge = <T extends Record<string, any>>({
  item,
  type,
  colorField,
  render,
  onClose,
  customClasses,
  width,
  isMobile,
}: BadgeProps<T>) => {
  const theme = useAppSelector(state => state.userReducer.userInfo.theme);

  const defaultColor = useMemo(() => {
    switch (type) {
      case 'mf':
        return MF_COLOR_TAG;
      default:
        return DEFAULT_COLOR_TAG;
    }
  }, [type]);

  if (type === 'discipline') {
    return (
      <div className={cn('Badge', { [customClasses]: customClasses, ['-mobile']: isMobile })}>
        <ChipsDiscipline type="filled" color={item.text_color} value={render(item)} isMobile={isMobile} />
      </div>
    );
  }

  if (type === 'mf') {
    return (
      <div className={cn('Badge', { [customClasses]: customClasses, ['-mobile']: isMobile })}>
        <ChipsStandardTag type="filled" color={item.text_color} value={render(item)} isMobile={isMobile} />
      </div>
    );
  }

  return (
    <>
      {type === 'role' ? (
        <div className={cn('Badge', { [customClasses]: customClasses, ['-mobile']: isMobile })}>
          <ChipsCompanyRole color={item.text_color} value={render(item)} onClose={onClose} width={width} isMobile={isMobile} />
        </div>
      ) : (
        <div
          style={makeTagStyles(colorField ? item[colorField] : defaultColor, defaultColor, type, theme)}
          className={cn('Badge', { [customClasses]: customClasses })}
        >
          <div
            className={'Badge__text'}
            style={makeTagColorStyles(colorField ? item[colorField] : defaultColor, defaultColor, type, theme)}
          >
            {render(item)}
          </div>
          {!!onClose && (
            <button type="button" className={'Badge__button'} onClick={onClose}>
              <SVG
                width={5}
                height={5}
                style={{ strokeWidth: '1px', stroke: '#000000' }}
                src={require('../../../../assets/icons/cross2.svg')}
                fill="#2D384E"
              />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export const BadgePlaceholder = () => {
  return (
    <div className={'Badge'}>
      <div className={'Badge__text_placeholder'}>&nbsp;</div>
    </div>
  );
};
