import React, { useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import Calendar from 'react-calendar';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import PopupSelectDayOff from '../PopupSelectDayOff/PopupSelectDayOff';
import moment from 'moment/moment';

import './CustomCalendarStyles.scss';

interface IProps {
  activeStartDate?: Date;
  name?: string;
  handleChange?: (date: string | Date) => void;
  showNavigation?: boolean;
  value: string | Date | null;
  min_due_date?: string | Date;
  max_due_date?: string | Date;
  range?: boolean;
  returnValue?: string;
  tileClassName?: ({ activeStartDate, date, view }: any) => '' | '-p20' | '-p40' | '-p60' | '-p80' | '-p100';
  tileContent?: ({ activeStartDate, date, view }: { activeStartDate: any; date: any; view: any }) => JSX.Element;
  work_week?: number;
  passed?: boolean;
  shadowOff?: boolean;
}

const CustomCalendar = ({
  activeStartDate,
  name,
  value,
  handleChange,
  showNavigation = true,
  min_due_date,
  max_due_date,
  range,
  returnValue,
  tileClassName,
  tileContent,
  work_week,
  passed,
  shadowOff,
}: IProps): JSX.Element => {
  const [is_promptPopUpView, setIsPromptPopUpView] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const themeClass = useGetThemeClass('b-calendar');

  const handleClosePromptPopUp = () => {
    setIsPromptPopUpView(false);
  };

  const onChange = (selectedAtDate: string) => {
    const newValue = moment(selectedAtDate).format('YYYY-MM-DD');

    if (work_week === 5) {
      if (moment(newValue).day() === 0 || moment(newValue).day() === 6) {
        setIsPromptPopUpView(true);
        setPrevValue(newValue);
        return;
      }
    }

    if (work_week === 6) {
      if (moment(newValue).day() === 0) {
        setIsPromptPopUpView(true);
        setPrevValue(newValue);
        return;
      }
    }

    handleChange && handleChange(selectedAtDate);
  };

  const resultValue = () => {
    if (value === 'hideValue') {
      return null;
    }

    if (range) {
      return value;
    }

    const isDateValid = moment(value).isValid();

    return isDateValid && value ? new Date(moment(value).utc(false).toDate()) : new Date();
  };

  return (
    <div className={themeClass}>
      <Calendar
        prevLabel={<SystemButton type={'chevron-left'} variant={'transparent'} size={'sm'} />}
        prev2Label={<SystemButton type={'chevron-double-left'} variant={'transparent'} size={'sm'} />}
        nextLabel={<SystemButton type={'chevron-right'} variant={'transparent'} size={'sm'} />}
        next2Label={<SystemButton type={'chevron-double-right'} variant={'transparent'} size={'sm'} />}
        activeStartDate={activeStartDate || null}
        name={name || ''}
        onChange={onChange}
        calendarType={'US'}
        showNavigation={showNavigation}
        tileClassName={tileClassName}
        tileContent={tileContent}
        value={resultValue()}
        locale={'en'}
        minDate={(min_due_date && new Date(min_due_date)) || ''}
        maxDate={(max_due_date && new Date(max_due_date)) || ''}
        selectRange={range}
        returnValue={returnValue}
        className={cn(``, {
          ['workweek-5']: work_week === 5,
          ['workweek-6']: work_week === 6,
          ['-passed']: passed,
          ['-shadowOff']: shadowOff,
        })}
        minDetail={'decade'}
      />
      <PopupSelectDayOff
        isOpen={is_promptPopUpView}
        onClose={handleClosePromptPopUp}
        handleSelect={handleChange}
        work_week={work_week}
        value={prevValue}
      />
    </div>
  );
};

export default CustomCalendar;
