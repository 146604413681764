import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import purple from '@material-ui/core/colors/purple';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import classesStyles from '../../NotificationsList.scss';
import ShowMoreDescription from './ShowMoreDescription';
import moment from 'moment';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  cssRoot: {
    color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: 'rgb(96, 116, 158)',
    boxShadow: 'none',
    border: 'none',
    textTransform: 'capitalize',
    minWidth: 140,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, .4)',
    },
  },
  dialog: {
    '& .MuiPaper-root-133': {
      background: 'var(--background-main)',
    },
  },
  dialogActions: {
    justifyContent: 'flex-start',
  },

  dialogActionsWide: {
    justifyContent: 'space-between',
  },

  formControl: {
    margin: 0,
    width: '100%',
    display: 'block',
    marginTop: 10,
    '& div[class*="MuiInputBase-root-"]': {
      width: '100%',
    },
  },
  cssTwo: {
    // color: 'rgb(96, 116, 158)',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    border: '1px solid var(--background-grid)',
    textTransform: 'capitalize',
    minWidth: 140,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, .4)',
    },
  },
});

const mqPad = window.matchMedia('(max-width: 768px)');

class NotificationsTableRow extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    hasOverflowingChildren: false,
  };

  componentDidMount() {
    this.setState({
      project_name: sessionStorage.getItem('project_name'),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const hasOverflowingChildren = this.element?.current?.offsetWidth > this.block?.current?.offsetWidth;
    if (hasOverflowingChildren !== this.state.hasOverflowingChildren) {
      this.setState({
        hasOverflowingChildren: hasOverflowingChildren,
      });
    }
  }

  element = React.createRef();
  block = React.createRef();

  render() {
    const { item, handleShowMore, openedNotification } = this.props;
    const { hasOverflowingChildren } = this.state;

    const offset = new Date().getTimezoneOffset();

    return (
      <ListItem
        style={{
          paddingRight: mqPad.matches ? '10px' : '15px',
          paddingLeft: mqPad.matches ? '10px' : '15px',
        }}
      >
        <Grid container spacing={1}>
          <Grid container item xs={12} sm={9} md={8} spacing={1}>
            <Grid item xs={2}>
              <div className={classesStyles.wrapNotificationDate}>
                <div className={`${classesStyles.notificationItem} ${classesStyles.notificationItemFirst}`}>
                  <div className={classesStyles.title}>Date</div>
                  <div className={classesStyles.text}>{` ${moment(item.created_at)
                    .add('minutes', -offset)
                    .format('MM/DD/YYYY hh:mm A')}`}</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={`${classesStyles.notificationItem}`}>
                <div className={classesStyles.title}>Project</div>
                <div className={classesStyles.text}>{item?.project_title}</div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={`${classesStyles.notificationItem}`}>
                <div className={classesStyles.title}>NF</div>
                <div className={classesStyles.text}>{item?.nf}</div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={classesStyles.notificationItem}>
                <div className={classesStyles.title}>Action</div>
                <div
                  className={`
                                ${classesStyles.text} 
                                ${classesStyles.openedCard}
                                ${classesStyles.overflowing}
                                `}
                >
                  {item?.action}
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={classesStyles.notificationItem}>
                <div className={classesStyles.title}>{item?.company_user ? 'Company' : 'System'}</div>
                <div className={classesStyles.text}>{item?.company_user ? item.company_user : ''}</div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={classesStyles.notificationItem}>
                <div className={classesStyles.title}>{item?.first_name || item?.last_name ? 'User' : 'System'}</div>
                <div className={classesStyles.text}>
                  {item?.first_name || item?.last_name ? item?.first_name + ' ' + item?.last_name : ''}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <div className={`${classesStyles.notificationItem} ${classesStyles.notificationItemLast}`}>
              <div className={classesStyles.title}>Description</div>
              <div
                className={`
                ${classesStyles.text} 
                ${classesStyles.openedCard}
                `}
                ref={this.block}
              >
                <ShowMoreDescription lines={1} html={ReactHtmlParser(item?.title)} />
              </div>
            </div>
          </Grid>
        </Grid>
      </ListItem>
    );
  }
}

NotificationsTableRow.propTypes = {
  tableClases: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

NotificationsTableRow.displayName = 'NotificationsTableRow';

export default withStyles(styles)(NotificationsTableRow);
