import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';

import './LinksStyles.module.scss';

interface LinksProps {
  type: 'a' | 'Link';
  value: string | ReactElement;
  to_href: string;
  onClick?: () => void;
  disabled?: boolean;
  target?: string;
  customClassname?: string;
}

const Links: React.FC<LinksProps> = ({ type, value, to_href, onClick, disabled, target = '_blank', customClassname }) => {
  const themeClass = useGetThemeClass('b-links');

  return (
    <>
      <div className={cn(`${themeClass}`, { ['-disabled']: disabled })}>
        {type === 'Link' && (
          <Link to={to_href} className={customClassname} onClick={onClick}>
            <div className={cn(`${themeClass}_link_text`, { ['-disabled']: disabled })}>{value}</div>
          </Link>
        )}
        {type === 'a' && (
          <a href={to_href} className={customClassname} onClick={onClick} target={target} rel="noreferrer">
            <div className={cn(`${themeClass}_link_text`, { ['-disabled']: disabled })}>{value}</div>
          </a>
        )}
      </div>
    </>
  );
};

export default Links;
