import React from 'react';
import Task from './task';
import { WhiteBoardContext } from '../../WhiteBoardContext';
import debounce from 'lodash/debounce';

export default class Column extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedCalculateActiveTaskId = debounce(this.calculateActiveTaskId, 50);
    this.state = {
      activeTaskId: null,
    };
  }

  taskRefs = [];

  onMouseMove = e => {
    const { clientX, clientY } = e;
    this.debouncedCalculateActiveTaskId(clientX, clientY);
  };

  onMouseLeave = e => {
    this.debouncedCalculateActiveTaskId.cancel();
    this.setState({
      activeTaskId: null,
    });
  };

  calculateActiveTaskId = (clientX, clientY) => {
    const { tasks } = this.props;

    const targetTaskIndex = this.taskRefs.findIndex(ref => {
      const rect = ref.current?.getBoundingClientRect();
      if (rect) {
        const { top, left, bottom, right } = rect;
        return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
      }

      return false;
    });

    const activeTaskId = targetTaskIndex !== -1 ? tasks[targetTaskIndex].id : null;

    this.setState({
      activeTaskId,
    });
  };

  render() {
    const {
      styles,
      display,
      mode,
      tasks,
      zoom,
      zoomStyle,
      location,
      requestCompany,
      responseCompany,
      checkRequestInList,
      setMode,
      reset,
      archerEl,
      isNonFullWidth,
    } = this.props;

    if (!this.taskRefs || !this.taskRefs.length) {
      this.taskRefs = tasks.map(task => React.createRef());
    }

    return (
      <div
        className={styles.WhiteBoardtableCell}
        style={{ ...zoomStyle.column }}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        // ref={provided.innerRef}
        // {...provided.droppableProps}
        // {...provided.droppablePlaceholder}0953802684
      >
        {tasks &&
          tasks.map((task, index) => {
            if (index >= 6 && !this.props.isShowAllTasks) {
              return;
            } else {
              return (
                <WhiteBoardContext.Consumer key={`task-${task.id}`}>
                  {data => (
                    <Task
                      active={this.state.activeTaskId === task.id}
                      taskRef={this.taskRefs[index]}
                      styles={styles}
                      task={task}
                      index={index}
                      openRequest={this.props.openRequest}
                      findPredecessor={this.props.findPredecessor}
                      displayPredecessor={this.props.displayPredecessor}
                      displaySuccessors={this.props.displaySuccessors}
                      mouseOutFromRequest={this.props.mouseOutFromRequest}
                      zoomStyle={this.props.zoomStyle}
                      zoom={zoom}
                      display={display}
                      makeBgColor={this.props.makeBgColor}
                      isShowAll={this.props.isShowAll}
                      mode={mode}
                      location={location}
                      responseCompany={responseCompany}
                      requestCompany={requestCompany}
                      checkRequestInList={checkRequestInList}
                      setMode={setMode}
                      reset={reset}
                      onAddRelations={this.props.onAddRelations}
                      onRemoveRelations={this.props.onRemoveRelations}
                      relations={data.relations}
                      availableItems={data.availableItems}
                      archerEl={archerEl}
                    />
                  )}
                </WhiteBoardContext.Consumer>
              );
            }
          })}
        {/*{provided.placeholder}*/}
      </div>
    );
  }
}
