import React, { MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState, useMemo } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import DropdownBasis from '../../DropdownParts/DropdownBasis/DropdownBasis';
import { DropdownItemModel } from '../../../../../models/global';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import DateSelect from '../../../../common/GlobalFilters/DateSelect/DateSelect';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { TextInputNew } from '../../../../controls';
import Popover from '@mui/material/Popover';

import './ConditionSelectorStyles.module.scss';
import { HIGH_RESOLUTION_CLASS } from '../../../../../constants/screenResolutionConstants';

const { setCommitmentAction } = requestSlice.actions;

type dueDateExpiredType = {
  value: string;
  id: string;
  title: string;
};

const TextFilters = [
  {
    value: 'CONTAINS',
    title: 'Text contains',
  },
  {
    value: 'NOT_CONTAINS',
    title: "Text doesn't contain",
  },
  {
    value: 'EXACTLY',
    title: 'Text exactly',
  },
];

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface ConditionSelectorProps<T, IsMultiValue extends boolean> {
  conditionType: 'DATE' | 'TEXT' | 'NUMBER';
  isValuesRemovable?: boolean;
  isHeader?: boolean;
  isFooter?: boolean;
  customSelectClass?: string;
  menuItems: T[];
  selectHandler: (selected: SelectedValue<T, IsMultiValue>, item?: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  customBasisContainerClass?: string;
  customBasisFieldClass?: string;
  basisPlaceholder?: string;
  renderBasisSelectedItem?: (selected: T) => ReactElement | ReactNode;
  renderCustomBasis?: (
    isActive: boolean,
    disabled: boolean,
    isLoading: boolean,
    selected: SelectedValue<T, IsMultiValue>,
  ) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  onChangeSearch?: (search: string) => void;
  customHeaderClass?: string;
  headerAdditionalText?: string;
  footerType?: 'divided-button' | 'radio-group';
  customFooterClass?: string;
  footerButtonClick?: (closeMenu: () => void) => void;
  footerButtonLoading?: boolean;
  footerButtonTitle?: string | ReactElement;
  footerRadioClickHandler?: (value: string | number) => void;
  footerSelectedRadioValue?: string | number;
  menuItemSize: 'sm' | 'md';
  checkMenuItemDisabled?: (item: T) => boolean;
  disabled?: boolean;
  menuItemTitleRenderer?: (item: T) => string | ReactNode;
  menuItemTooltipRenderer?: (item: T) => string | ReactNode;
  menuItemLeftElemRenderer?: (item: T) => ReactNode;
  menuItemLeftElemClick?: (item: T) => void;
  menuItemRightElemRenderer?: (item: T) => ReactNode;
  menuItemRightElemClick?: (item: T) => void;
  menuItemRightTextRenderer?: (item: T) => ReactNode;
  customMenuItemClass?: string;
  customMenuClass?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: MouseEvent) => void;
  onMenuClose?: () => void;
  revertDropdownPositionToTop?: boolean;
  customOpening?: boolean;
  isOpenMenu?: boolean;
  isFullWidth?: boolean;
  isAlwaysPlaceholder?: boolean;
  handleOpenDDManageStatuses?: () => void;
  disableSelectAll?: boolean;
  alignDropdownPositionToLeft: boolean;
  handleChangeContains: (value: string, filterType: string, filterName: string, filterOperator: 'AND' | 'OR') => void;
  handleSelectCondition: (filterName: string, filtersAlias: any, filterOperator: 'AND' | 'OR') => void;
  filterName: string;
  containsFilter;
  handleChangeFilterSelect: (value: dueDateExpiredType[], filterType: string, filterName: string) => void;
  filterOperator: 'AND' | 'OR';
  isActiveOverdue: boolean;
  isHighResolution?: boolean;
  renderSelect: () => React.ReactElement;
}

function ConditionSelector<T extends DropdownItemModel, IsMultiValue extends boolean>({
  conditionType,
  isValuesRemovable,
  isHeader,
  isFooter,
  disableSelectAll,
  customSelectClass,
  menuItems,
  selectHandler,
  selected,
  isLoading,
  customBasisContainerClass,
  customBasisFieldClass,
  basisPlaceholder,
  renderBasisSelectedItem,
  renderCustomBasis,
  basisMaxItems,
  error,
  basisTooltip,
  onChangeSearch,
  customHeaderClass,
  headerAdditionalText,
  footerType,
  customFooterClass,
  footerButtonClick,
  footerButtonLoading,
  footerButtonTitle,
  footerRadioClickHandler,
  footerSelectedRadioValue,
  menuItemSize,
  checkMenuItemDisabled,
  disabled,
  menuItemTitleRenderer,
  menuItemTooltipRenderer,
  menuItemLeftElemRenderer,
  menuItemLeftElemClick,
  menuItemRightElemRenderer,
  menuItemRightElemClick,
  customMenuItemClass,
  customMenuClass,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isOpenMenu,
  revertDropdownPositionToTop,
  customOpening,
  isFullWidth,
  isAlwaysPlaceholder = false,
  menuItemRightTextRenderer,
  handleOpenDDManageStatuses,
  alignDropdownPositionToLeft,
  handleChangeContains,
  filterName,
  containsFilter,
  handleSelectCondition,
  handleChangeFilterSelect,
  filterOperator,
  isActiveOverdue,
  renderSelect,
  isHighResolution,
}: ConditionSelectorProps<T, IsMultiValue>) {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-conditionSelector');
  const themeClassMenu = useGetThemeClass('b-dropdownConditionSelector');
  const themeClassPaper = useGetThemeClass('b-dropdownConditionSelectorPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const selectBasisRef = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedTextFilters, setSelectedTextFilters] = useState<DropdownItemModel>(null);
  const [focusElement, setFocusElement] = useState<string>('');
  const [dateSelect, setDateSelect] = useState(null);
  const [dateGrouping, setDateGrouping] = useState(null);

  useEffect(() => {
    if (customOpening) {
      openMenu && openMenu();
      dispatch(setCommitmentAction(''));
    }
  }, [customOpening]);

  useEffect(() => {
    if (containsFilter) {
      if (containsFilter[filterName] && conditionType === 'TEXT') {
        const keyValue = Object.keys(containsFilter[filterName])[0];
        const item = TextFilters.filter(item => item.value === keyValue)[0];
        !selectedTextFilters && setSelectedTextFilters(item);
      }
      if (containsFilter[filterName] && conditionType === 'DATE') {
        const group = Object.keys(containsFilter[filterName])[0];
        const data = Object.values(containsFilter[filterName])[0];
        setDateSelect(data);
        group && setDateGrouping({ value: group.toLowerCase() });
      } else {
        setDateSelect(null);
      }
    }
  }, [containsFilter]);

  useEffect(() => {
    if (isOpenMenu) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [isOpenMenu]);

  const formattedSelected = useMemo(() => {
    return selected;
  }, [selected]);

  const openMenu = (e?: MouseEvent) => {
    setIsMenuOpen(true);
    onMenuOpen && onMenuOpen(e);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const menuCloseHandler = () => {
    closeMenu();
    onMenuClose && onMenuClose();
  };

  const handleTextFilters = (item: T) => {
    handleSelectCondition(filterName, [String(item.value)], filterOperator);
    setSelectedTextFilters(item);
  };

  const handleApplyFilter = data => {
    setDateSelect(data);
    if (dateGrouping) {
      handleChangeContains(data, dateGrouping.value.toUpperCase(), filterName, filterOperator);
    } else {
      handleSelectCondition(filterName, ['DATE_IS'], filterOperator);
      handleChangeContains(data, 'DATE_IS', filterName, filterOperator);
    }
  };

  const changeGrouping = (group: string) => {
    setDateGrouping(group);
    handleSelectCondition(filterName, [group.value.toUpperCase()], filterOperator);
  };

  const changeActiveOverdue = (isOverdue: boolean) => {
    if (isOverdue) {
      handleChangeFilterSelect([{ value: 'expired', id: 'expired', title: 'expired' }], filterName, filterOperator);
    } else {
      handleChangeFilterSelect([], filterName, filterOperator);
    }
  };

  const renderDefaultBasisItem = (item: T) => {
    return item.title;
  };

  const handleChange = (value: string) => {
    if (conditionType === 'NUMBER') {
      const str = String(value ? Number(value.replace(/\D/, '')) : '');
      handleChangeContains(str, String(selectedTextFilters.value), filterName, filterOperator);
    } else {
      handleChangeContains(value, String(selectedTextFilters.value), filterName, filterOperator);
    }
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          [`${customSelectClass}`]: customSelectClass,
        })}
      >
        <DropdownBasis<T, false>
          isMulti={false}
          mRef={selectBasisRef}
          customClassNameContainer={customBasisContainerClass}
          customClassNameField={customBasisFieldClass}
          selected={formattedSelected}
          placeholder={basisPlaceholder}
          renderSelected={renderBasisSelectedItem ? renderBasisSelectedItem : renderDefaultBasisItem}
          renderCustomBasis={renderCustomBasis}
          basisClickHandler={openMenu}
          isActive={isMenuOpen}
          isLoading={isLoading}
          error={error}
          disabled={disabled}
          tooltip={basisTooltip}
          maxElements={basisMaxItems}
          isAlwaysPlaceholder={isAlwaysPlaceholder}
        />
        <Popover
          open={isMenuOpen}
          anchorEl={selectBasisRef.current}
          onClose={menuCloseHandler}
          classes={{
            paper: cn(`${themeClassCallout} ${themeClassPaper}`, {
              ['-isDropdownOnTop']: revertDropdownPositionToTop,
              [HIGH_RESOLUTION_CLASS]: isHighResolution,
            }),
          }}
          anchorOrigin={{
            vertical: `${revertDropdownPositionToTop ? 'top' : 'bottom'}`,
            horizontal: `${alignDropdownPositionToLeft ? 'right' : 'left'}`,
          }}
          transformOrigin={{
            vertical: `${revertDropdownPositionToTop ? 'bottom' : 'top'}`,
            horizontal: `${alignDropdownPositionToLeft ? 'right' : 'left'}`,
          }}
        >
          <div className={cn(`${themeClassMenu}`, { ['-typeText']: conditionType === 'TEXT' || conditionType === 'NUMBER' })}>
            {conditionType === 'DATE' ? (
              <DateSelect
                dataValue={dateSelect || ''}
                handleApplyFilter={handleApplyFilter}
                changeGrouping={changeGrouping}
                selectedGrouping={dateGrouping?.value}
                changeActiveOverdue={changeActiveOverdue}
                isActiveOverdue={isActiveOverdue}
                isShowOverdue={filterName === 'due_date'}
                menuCloseHandler={menuCloseHandler}
              />
            ) : (
              <div className={`${themeClassMenu}_wrapper`}>
                <Select<DropdownItemModel, null, false>
                  menuItems={TextFilters}
                  basisPlaceholder={'Filter by condition'}
                  selectHandler={handleTextFilters}
                  selected={selectedTextFilters}
                  menuItemSize={'sm'}
                  disabled={false}
                  isHighResolution={isHighResolution}
                />
              </div>
            )}
            {!!selectedTextFilters && (
              <TextInputNew
                type={'on-bgd'}
                onChange={handleChange}
                value={(containsFilter[filterName] && containsFilter[filterName][selectedTextFilters.value]) || ''}
                placeholder={'Enter'}
                isFocus={focusElement === 'text'}
                onFocus={() => setFocusElement('text')}
                onBlur={() => setFocusElement('')}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    menuCloseHandler();
                  }
                }}
              />
            )}
            {renderSelect()}
          </div>
        </Popover>
      </div>
    </>
  );
}

export default ConditionSelector;
