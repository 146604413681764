import React from 'react';
import './ErrorBoundary.scss';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log('ERROR', error);
    // Display fallback UI

    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="main">
          <div className="message">
            <h1>Ooops!</h1>
            <h2>Something went wrong!</h2>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
