import { SubmittalSocketActionI } from '../SocketConstants';
import { submittalSlice } from '../../../../store/submittal/submittalSlice';
import { SpecAndSubsSlice } from '../../../../store/specAndSubs/specAndSubsSlice';
import { requestSlice } from '../../../../store/request/requestReducer(HOLD)';

const {
  socketHandleSubmittalCard,
  socketHandleSubmittalChangePrivate,
  socketHandleSubmittalChangeHotlist,
  socketHandleSubmittalChangeWatchlist,
  socketHandleSubmittalChangeProcore,
} = submittalSlice.actions;
const { socketHandleDeleteRevisionSubmittalCard } = SpecAndSubsSlice.actions;
const { socketSandBoxHotlistState } = requestSlice.actions;

interface SubmittalDataI {
  socketData: SubmittalSocketActionI;
  dispatch: any;
}

export const SubmittalSocketHelper = ({ socketData, dispatch }: SubmittalDataI) => {
  if (socketData.action === 'updated_bic') {
    dispatch(socketHandleSubmittalCard({ card: socketData.data, action: socketData.action }));
  }
  if (socketData.action === 'updated' && socketData.event === 'private') {
    dispatch(socketHandleSubmittalChangePrivate({ data: socketData.data }));
  }
  if (socketData.action === 'updated' && socketData.event === 'watchlist') {
    dispatch(socketHandleSubmittalChangeWatchlist({ data: socketData.data }));
  }
  if (socketData.action === 'updated' && socketData.event === 'procore') {
    dispatch(socketHandleSubmittalChangeProcore({ data: socketData.data }));
  }
  if (socketData.action === 'updated' && socketData.event === 'hotlist') {
    dispatch(
      socketSandBoxHotlistState({
        changedNow: true,
        sectionType: socketData.data_sandbox.is_output ? 'submittalOutput' : 'submittalInput',
      }),
    );
    dispatch(socketHandleSubmittalChangeHotlist({ data: socketData.data }));
  }
  if ((socketData.action === 'updated' || socketData.action === 'updated_watchlist') && !socketData.event) {
    dispatch(socketHandleSubmittalCard({ card: socketData.data, action: socketData.action }));
    dispatch(socketHandleDeleteRevisionSubmittalCard({ card: socketData.data, action: socketData.action }));
  }
  if (socketData.action === 'deleted' && !socketData.event) {
    dispatch(socketHandleDeleteRevisionSubmittalCard({ card: socketData.data, action: socketData.action }));
  }
};
