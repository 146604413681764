import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  addToWatchersGroups,
  bulkEdit,
  changeNfStatusToDelivarable,
  checkIfSnippetInSandBoxSection,
  createNewDueDate,
  createNewResponseCompany,
  createWatchersGroups,
  deactivatedStatus,
  declineCounter,
  deleteHashtag,
  deleteWatchersGroups,
  editHashtag,
  ExportData,
  getFilteredRequest,
  getSandBoxIDsHotList,
  getSandBoxCardIDHotList,
  getMainMf,
  getMainUf,
  getMfFromParent,
  getNeedListAllRequest,
  getNeedListColumnOrder,
  getNfLink,
  getRequestByNf,
  getSandBoxCounters,
  getSandBoxhotListCounters,
  getSandBoxhotListCountersAll,
  getSandBoxData,
  getSandBoxWidgetsHotlistData,
  getSandBoxOuterCounter,
  getSandBoxWidgets,
  getSearchRequestFilters,
  getSuccPredFromRequest,
  getTags,
  getUfFromParent,
  getWatchers,
  getWatchersGroups,
  linkNfPredecessor,
  linkNfSuccessor,
  needList,
  requestDelete,
  requestLoading,
  requestSave,
  requestSetSeen,
  requestUpdatePrivate,
  requestView,
  responseSave,
  searchMForUFCode,
  searchRequest,
  searchUsersRequest,
  searchWatchersRequest,
  sendBugReport,
  setHotList,
  setIncorporated,
  setNeedListColumnOrder,
  singleRequestLoading,
  unlinkNfPredecessor,
  unlinkNfSuccessor,
  updateNewDueDate,
  updateNewResponseCompany,
  updateWatchersGroups,
  whiteBoard,
  whiteBoardFilters,
} from './requestLogic(HOLD)';
import {
  FullRequestModel,
  GetLinkingNfSuggestionResponseModel,
  ICheckIfItemInSectionPayload,
  ICheckIfItemInSectionResponseData,
  IRequestDeactivatePayload,
  IRequestDeletePayload,
  IRequestReducer,
  IRequestSetHotList,
  ISingleRequestLoadingPayload,
  ResponseDataSandBoxModel,
  ResponseSandBox,
  ResponseSandBoxCounters,
  ResponseSandBoxOuterCounter,
  SandBoxAccordionData,
  SandBoxAlertWidgetActionsModel,
  SandBoxCacheModel,
  SandBoxQueryModel,
  SandBoxTabsStateModel,
  SandBoxWidgetsPayloadModel,
  SandBoxWidgetsResponse,
} from './requestReducer.model';
import { NeedListRequest } from '../needlist/needListModels';
import { RequestModel } from '../../models';
import Api from '../../service/api';
import { KeyValueModel } from '../../models/key-value.model';
import { filterArrayByField } from '../../service/IndexedDB/Helper';
import { DatabaseService } from '../../service/IndexedDB/DBConfig';

const initialState: IRequestReducer = {
  requestInfo: {
    data: {},
  },
  loading: false,
  columnOrderLoading: false,
  loadingNeedListTable: false,
  isFirstNeedListLoading: true,
  isFirstSandBoxAllCountersLoading: true,
  isFirstSandBoxCountersLoading: true,
  statusBug: null,
  needList: {
    data: [],
    total: 0,
    filtered: null,
    isNeedToResendRequest: false,
  },
  isNeedListLoadingStatus: false,
  isNeedListGrouped: false,
  saveRequestStatus: false,
  saveCommitmentStatus: false,
  lastUpdateNeedList: {},
  queryParams: {},
  sandBox: {
    accordion_list: [],
    counters: null,
    total: 0,
  } as ResponseDataSandBoxModel,
  sandBoxHotlist: {
    accordion_list: [],
    counters: null,
    total: 0,
  } as ResponseDataSandBoxModel,
  sandBoxIDsHotList: [],
  sandBoxCardIDHotList: [],
  sandBoxCache: {
    user: {
      request: {},
      deliverable: {},
      submittalInput: {},
      submittalOutput: {},
    },
    party: {
      request: {},
      deliverable: {},
      submittalInput: {},
      submittalOutput: {},
    },
  } as SandBoxCacheModel,
  isTimerActiveRequest: false,
  isTimerActiveDeliverable: false,
  sandBoxWidgets: null,
  sandBoxWidgetsLoading: Api.initialStatus,
  isFirstSandBoxWidgetsLoading: true,
  sandBoxActiveAlertSnippetActions: null,
  sandBoxTabsState: null,
  needListFilters: {},
  whiteBoardFilters: null,
  singleRequestLoaded: true,
  tags: [],
  nfTags: [],
  sandBoxFilters: {} as SandBoxQueryModel,
  sandBoxCountersLoading: Api.initialStatus,
  sandBoxAllCountersLoading: Api.initialStatus,
  sandBoxOuterCounterLoading: Api.initialStatus,
  watchersGroups: [],
  linkedRequest: {},
  columnOrder: '',
  declineCounterData: {},
  requestFilterFields: null,
  requestToOpenId: 0,
  requestViewLoading: false,
  idLoadedRequestByNf: 0,
  isLoadingRequestByNf: false,
  nfNumberLoadedRequestByNf: 0,
  needsList: null,
  message: '',
  status: false,
  whiteBoard: null,
  wbLoading: false,
  changedActiveProjectId: 0,
  total: 0,
  isReturnToSandBox: false,
  sendBoxDataLoading: Api.initialStatus,
  sandBoxHotlistDataLoading: Api.initialStatus,
  sandBoxDataFromProjectId: '',
  getRequestLoadingStatus: false,
  ufOrMfCodesLoading: false,
  findUsers: [],
  findWatchers: [],
  mfSearchSuggestions: [],
  projectId: 0,
  requestId: 0,
  currentRequestToOpenId: 0,
  sandBugReportErrors: '',
  requestCardErrors: null,
  is_file: false,
  requestDeleteStatus: 0,
  requestDeleteStatusSuccess: false,
  nfLinkLoading: Api.initialStatus,
  nfUnlinkLoading: Api.initialStatus,
  mfTree: {
    target_sub_mfc: [],
    treeMfc: [],
  },
  ufTree: {
    target_sub_ufc: [],
    treeUfc: [],
  },
  ufSearchSuggestions: [],
  isRequestFiltersMatch: false,
  loading_deactivated_status: false,
  requestLoading: false,
  activeCommitmentAction: null,
  nfCardIsOpen: false,
  nfCardId: null,
  nfLinkCardId: null,
  isNewNfCard: false,
  newNfCardSubmittalInfo: { submittalId: null, is_generate_nf_rfi: 0 },
  nfCardNeedListId: null,
  final_desc: '',
  isChangedFinalValue: false,
  nfLinkingSuggestions: null,
  nfLinkingSuggestionsLoading: Api.initialStatus,
  saveHotlistLoading: Api.initialStatus,
  searchRequestPartyLoading: false,
  socketPrivateState: {
    is_private: 0,
    changedNow: false,
  },
  socketWatchlistState: {
    watchers: null,
    watcher_groups: null,
    changedNow: false,
  },
  socketHotlistState: {
    is_hot_list: false,
    changedNow: false,
  },
  socketSandBoxHotlist: {
    changedNow: false,
    sectionType: '',
  },
  socketDDLRefreshes: {
    changedNow: false,
  },
  socketProcoreState: {
    is_procore_sync_needed: false,
    changedNow: false,
  },
};

export const requestSlice = createSlice({
  initialState: initialState,
  name: 'requestReducer',
  reducers: {
    setNfOpenId(state, { payload }: PayloadAction<number>) {
      state.nfCardId = payload;
      state.nfCardIsOpen = true;
      state.isNewNfCard = false;
      state.newNfCardSubmittalInfo = { submittalId: null, is_generate_nf_rfi: 0 };
    },
    setNewNfCard(state) {
      state.isNewNfCard = true;
      state.nfCardIsOpen = true;
      state.nfCardId = null;
      state.idLoadedRequestByNf = false;
      state.nfNumberLoadedRequestByNf = false;
      state.isLoadingRequestByNf = false;
    },
    setNewNfCardSubmittalId(state, action: PayloadAction<{ submittalId: number | null; is_generate_nf_rfi: 0 | 1 }>) {
      state.newNfCardSubmittalInfo = action.payload;
    },
    setNfLinkOpenId(state, { payload }: PayloadAction<number>) {
      state.nfLinkCardId = payload;
      state.nfCardId = null;
      state.isNewNfCard = false;
      state.nfCardIsOpen = false;
    },
    closeNfCard(state) {
      state.nfCardIsOpen = false;
      state.nfCardId = null;
      state.nfLinkCardId = null;
      state.nfCardNeedListId = null;
      state.newNfCardSubmittalInfo = { submittalId: null, is_generate_nf_rfi: 0 };
    },
    closeNewNfCard(state) {
      state.isNewNfCard = false;
      state.newNfCardSubmittalInfo = { submittalId: null, is_generate_nf_rfi: 0 };
    },
    setFinal_desc(state, { payload }: PayloadAction<string>) {
      state.final_desc = payload;
    },
    setIsNeedListGrouped(state, { payload }: PayloadAction<boolean>) {
      state.isNeedListGrouped = payload;
    },
    setIsChangedFinalValue(state, { payload }: PayloadAction<boolean>) {
      state.isChangedFinalValue = payload;
    },
    setNfNeedListId(state, { payload }: PayloadAction<number>) {
      state.nfCardNeedListId = payload;
    },
    setActiveSandBoxAlertSnippetActions(state, { payload }: PayloadAction<SandBoxAlertWidgetActionsModel>) {
      state.sandBoxActiveAlertSnippetActions = payload;
    },
    setIsNeedListLoadingStatus(state, { payload }: PayloadAction<boolean>) {
      state.isNeedListLoadingStatus = payload;
    },
    setSandBoxTabsState(state, { payload }: PayloadAction<SandBoxTabsStateModel>) {
      const currentState = current(state);

      state.sandBoxTabsState = {
        ...(currentState.sandBoxTabsState || {}),
        ...payload,
      };
    },
    setSandBoxQuery(state, { payload }: PayloadAction<SandBoxQueryModel>) {
      state.sandBoxFilters = payload;
    },
    markSandBoxItemAsViewed(state, { payload }: PayloadAction<{ accordion_alias: string; id: number }>) {
      const currentState = current(state);
      const accordionList = [...currentState.sandBox.accordion_list];
      const changingAccordionIndex = accordionList.findIndex(accordion => accordion.alias === payload.accordion_alias);
      const changingAccordionData = [...accordionList[changingAccordionIndex].data];
      const changingSandBoxItemIndex = changingAccordionData.findIndex(item => item.id === payload.id);

      const changingSandBoxItem = {
        ...changingAccordionData[changingSandBoxItemIndex],
        marker: null,
      };

      changingAccordionData.splice(changingSandBoxItemIndex, 1, changingSandBoxItem);

      const changingAccordion = {
        ...accordionList[changingAccordionIndex],
        data: changingAccordionData,
      };

      accordionList.splice(changingAccordionIndex, 1, changingAccordion);

      state.sandBox.accordion_list = accordionList;
    },
    setSandBoxItemsFromCache(state, { payload }: PayloadAction<SandBoxAccordionData[]>) {
      state.sandBox.accordion_list = payload;
    },
    setIsTimerActiveRequest(state, { payload }: PayloadAction<boolean>) {
      state.isTimerActiveRequest = payload;
    },
    setIsTimerActiveDeliverable(state, { payload }: PayloadAction<boolean>) {
      state.isTimerActiveDeliverable = payload;
    },
    setCommitmentAction(state, { payload }: PayloadAction<string>) {
      state.activeCommitmentAction = payload;
    },
    setNeedListColumnOrderLoading(state) {
      state.columnOrderLoading = true;
    },
    setNeedListColumnOrderLoadingSuccess(state) {
      state.columnOrderLoading = false;
    },
    clearRequestInfoData(state) {
      state.requestInfo.data = {};
    },
    clearRequestID(state) {
      state.requestId = null;
    },

    setRequestToOpen(state, { payload }: PayloadAction<{ id: number }>) {
      state.requestToOpenId = payload?.id || null;
    },
    clearNeedListData(state) {
      state.isFirstNeedListLoading = true;
      state.needList = initialState.needList;
    },
    saveQueryParams(state, { payload }) {
      state.queryParams = payload;
    },
    resetWhiteBoardFilters(state, { payload }) {
      state.whiteBoardFilters = null;
    },
    changeActiveProject(state, { payload }) {
      state.changedActiveProjectId = Number(payload);
    },
    getWatchersSuccess(state, { payload }) {
      const requestId = payload.data && Object.keys(payload.data).length && payload.data[0].request_id;
      if (requestId) {
        state.loading = false;
        state.requestInfo.data[requestId] = { ...state.requestInfo.data[requestId] };
      } else {
        state.loading = false;
      }
    },
    openExistedRequest(state, { payload }: PayloadAction<{ requestId: number; isBackRequest: boolean }>) {
      state.loading = false;
    },
    setIsNeedToResendRequest(state, { payload }: PayloadAction<boolean>) {
      state.needList = { ...state.needList, isNeedToResendRequest: payload };
    },
    allRequestLoadingSucces(state, { payload }) {
      let requestInfoData = {};

      requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };

      payload?.data?.data.map(item => {
        requestInfoData = { ...requestInfoData, [item.id]: item };
      });

      state.requestInfo = payload.data;
      state.requestInfo.data = requestInfoData;
      state.message = payload.message;
      state.status = payload.status;
      state.loading = false;
      state.projectId = payload.projectId;
      state.getRequestLoadingStatus = false;
    },

    singleRequestForProjectLoadingSucces(state, { payload }) {
      let requestInfoData = {};

      requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
      payload.data.isFromSingleLoad = true;
      requestInfoData = { ...requestInfoData, [payload.data.id]: payload.data };

      state.currentRequestToOpenId = null;
      state.requestInfo.data = requestInfoData;
      state.message = payload.message;
      state.status = payload.status;
      state.loading = false;
      state.singleRequestLoaded = true;
      state.getRequestLoadingStatus = false;
      state.requestCardErrors = null;
    },
    allRequestForProjectLoadingSucces(state, { payload }) {
      let requestInfoData = {};

      requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };

      payload?.data?.data.map(item => {
        requestInfoData = { ...requestInfoData, [item.id]: item };
      });

      state.requestInfo = payload.data;
      state.requestInfo.data = requestInfoData;
      state.message = payload.message;
      state.status = payload.status;
      state.loading = false;
    },
    requestSyncList(state, { payload }) {
      const requestInfo = state?.requestInfo?.data ? { ...state?.requestInfo?.data } : {};
      if (payload?.parent?.id) {
        requestInfo[payload.parent.id] = payload.parent;
      }

      state.requestInfo.data = requestInfo;
    },
    socketHandleRequest(state, { payload: { data, action } }) {
      const localStorageData = JSON.parse(localStorage.getItem(`need_form`)) || {};
      if (
        Object.keys(localStorageData || {}).length &&
        (Object.keys(localStorageData?.formData || {}).length ||
          Object.keys(localStorageData?.formDataOrFilters || {}).length ||
          Object.keys(localStorageData?.containsFilter || {}).length ||
          localStorageData?.searchValue ||
          localStorageData?.selectedFilters?.length ||
          localStorageData?.orFilters?.length ||
          Object.keys(localStorageData?.conditions || {}).length)
      ) {
        state.needList = {
          ...state.needList,
          isNeedToResendRequest: true,
        };
        return;
      }

      const changedIndex = state.needList?.data?.findIndex(i => i.id === data?.id);

      const needListData = state.needList?.data ? [...state.needList.data] : [];
      if (typeof changedIndex !== 'number' || changedIndex === -1) {
        const user_id = Number(localStorage.getItem('user_id'));
        if ((!data.is_whiteboard && data.user_id !== user_id) || !state.needList?.data) {
          return state;
        }

        const indexOfNfLessThanUpdated = needListData.findIndex(item => item.nf < data.nf);
        needListData.splice(indexOfNfLessThanUpdated, 0, data);
      } else {
        needListData[changedIndex] = { ...needListData[changedIndex], ...data };
      }

      state.loading = false;

      state.needList = {
        ...state.needList,
        data: needListData,
      };
      state.requestInfo.data[data.id] = { ...data };
    },
    socketHandleNewRequest(state, { payload }) {
      if (!state?.needList?.data) {
        state.needList = {
          ...state.needList,
        };
      }
      const localStorageData = JSON.parse(localStorage.getItem(`need_form`)) || {};
      if (
        Object.keys(localStorageData || {}).length &&
        (Object.keys(localStorageData?.formData || {}).length ||
          Object.keys(localStorageData?.formDataOrFilters || {}).length ||
          Object.keys(localStorageData?.containsFilter || {}).length ||
          localStorageData?.searchValue ||
          localStorageData?.selectedFilters?.length ||
          localStorageData?.orFilters?.length ||
          Object.keys(localStorageData?.conditions || {}).length)
      ) {
        state.needList = {
          ...state.needList,
          isNeedToResendRequest: true,
        };
        return;
      }
      const needListData = [...state.needList.data];
      const indexPredecessor = needListData.findIndex(n => n.id === payload.predecessor_id);
      if (indexPredecessor > -1) {
        needListData[indexPredecessor]?.successors?.push(payload);
        needListData[indexPredecessor].successor_nf = needListData[indexPredecessor]?.successor_nf
          ? needListData[indexPredecessor].successor_nf
          : '' + ` ,${payload.id}`;
      }
      needListData.unshift(payload);
      state.loading = false;

      state.needList = {
        ...state.needList,
        data: needListData,
        total: state.needList.total + 1,
      };
    },
    socketHandleRequestChangePrivate(state, { payload: { data, nf } }) {
      if (nf === data.nf) {
        state.socketPrivateState = { changedNow: true, is_private: data.is_rfi };
        state.requestInfo.data = { ...state.requestInfo.data, [data.id]: { ...data } };
      }
    },
    socketHandleRequestChangeWatchlist(state, { payload: { data, nf } }) {
      if (nf === data.nf) {
        state.socketWatchlistState = { changedNow: true, watchers: data.watchers, watcher_groups: data.watcher_groups };
        state.requestInfo.data = { ...state.requestInfo.data, [data.id]: { ...data } };
      }
    },
    socketHandleRequestChangeHotlist(state, { payload: { data, nf } }) {
      if (nf === data.nf) {
        state.socketHotlistState = { changedNow: true, is_hot_list: data.is_hot_list };
        state.requestInfo.data = { ...state.requestInfo.data, [data.id]: { ...data } };
      }
    },
    socketSandBoxHotlistState(state, { payload: { sectionType } }) {
      state.socketSandBoxHotlist = { changedNow: true, sectionType: sectionType };
    },
    socketDDLRefreshes(state, { payload: { data } }) {
      state.socketDDLRefreshes = { changedNow: true };
    },
    socketHandleRequestChangeProcore(state, { payload: { data, nf } }) {
      if (nf === data.nf) {
        state.socketProcoreState = { changedNow: true, is_procore_sync_needed: data.is_procore_sync_needed };
        state.requestInfo.data = { ...state.requestInfo.data, [data.id]: { ...data } };
      }
    },
    resetSocketRequestPrivateState(state) {
      state.socketPrivateState = { changedNow: false, is_private: 0 };
    },
    resetSocketRequestWatchlistState(state) {
      state.socketWatchlistState = { changedNow: false, watchers: null, watcher_groups: null };
    },
    resetSocketRequestHotlistState(state) {
      state.socketHotlistState = { changedNow: false, is_hot_list: false };
    },
    resetSocketSandBoxHotlistState(state) {
      state.socketSandBoxHotlist = { changedNow: false, sectionType: '' };
    },
    resetSocketDDLRefreshes(state) {
      state.socketDDLRefreshes = { changedNow: false };
    },
    resetSocketRequestProcoreState(state) {
      state.socketProcoreState = { changedNow: false, is_procore_sync_needed: false };
    },
    socketHandleRequestInfo(state, { payload }) {
      const currentState = current(state);
      let requests = { ...currentState.requestInfo?.data };

      requests = {
        ...requests,
        [payload.id]: { ...currentState.requestInfo?.data?.[payload.id], ...payload },
      };

      state.requestInfo.data = requests;
    },
    socketHandleDueDateChangedRequestInfo(state, { payload }) {
      const requests = { ...state.requestInfo?.data };
      const needListData = [...state.needList.data];
      const changedIndex = state.needList?.data?.findIndex(i => i.id == payload?.request_id);
      if (!requests[payload.request.id]) {
        return state;
      }
      if (payload.status === 'new') {
        needListData[changedIndex] = {
          ...needListData[changedIndex],
          due_date_change_request: {
            ...needListData[changedIndex].due_date_change_request,
            new_due_date: payload.new_due_date,
            id: payload.id,
          },
        };
      }
      if (payload.status === 'accepted') {
        requests[payload.request.id] = {
          ...state.requestInfo?.data?.[payload.request.id],
          due_date: payload.new_due_date,
          due_date_change_request: null,
        };
      }
      if (payload.status === 'rejected') {
        requests[payload.request.id] = { ...state.requestInfo?.data?.[payload.request.id], due_date_change_request: null };
      }

      state.requestInfo.data = requests;
    },
    socketHandleDueDateChanged(state, { payload }) {
      const changedIndex = state.needList?.data?.findIndex(i => i.id == payload?.request_id);
      if (typeof changedIndex !== 'number' || changedIndex === -1 || !state.needList?.data) {
        return state;
      }
      const needListData = [...state.needList.data];

      if (payload.status === 'new') {
        needListData[changedIndex] = {
          ...needListData[changedIndex],
          due_date_change_request: {
            ...needListData[changedIndex].due_date_change_request,
            new_due_date: payload.new_due_date,
            id: payload.id,
          },
        };
      }
      if (payload.status === 'accepted') {
        needListData[changedIndex] = { ...needListData[changedIndex], due_date: payload.new_due_date, due_date_change_request: null };
      }
      if (payload.status === 'rejected') {
        needListData[changedIndex] = { ...needListData[changedIndex], due_date_change_request: null };
      }

      const { request_id } = payload;

      state.loading = false;

      state.needList = {
        ...state.needList,
        data: needListData,
      };
      state.requestInfo.data[request_id] = needListData[changedIndex];
    },
    socketHandleRequestCompanyChangedRequestInfo(state, { payload }) {
      const needListData = [...state.needList.data];
      const changedIndex = state.needList?.data?.findIndex(i => i.id == payload?.request_id);
      const requests = { ...state.requestInfo?.data };
      if (!requests[payload.request.id]) {
        return state;
      }
      if (payload.status === 'new') {
        needListData[changedIndex] = {
          ...needListData[changedIndex],
          response_company_change_request: {
            ...needListData[changedIndex].response_company_change_request,
            company: payload.company,
            id: payload.id,
          },
        };
      }
      if (payload.status === 'accepted') {
        requests[payload.request.id] = {
          ...state.requestInfo?.data?.[payload.request.id],
          response_party: payload.company,
          response_company_change_request: null,
        };
      }
      if (payload.status === 'rejected') {
        requests[payload.request.id] = { ...state.requestInfo?.data?.[payload.request.id], response_company_change_request: null };
      }

      state.requestInfo.data = requests;
    },
    socketHandleResponseCompanyChanged(state, { payload }) {
      const changedIndex = state.needList?.data?.findIndex(i => i.id == payload?.request_id);
      if (typeof changedIndex !== 'number' || changedIndex === -1 || !state.needList?.data) {
        return state;
      }
      const needListData = [...state.needList.data];

      if (payload.status === 'new') {
        needListData[changedIndex] = {
          ...needListData[changedIndex],
          response_company_change_request: {
            ...needListData[changedIndex].response_company_change_request,
            company: payload.company,
            id: payload.id,
          },
        };
      }
      if (payload.status === 'accepted') {
        needListData[changedIndex] = {
          ...needListData[changedIndex],
          response_party: payload.company,
          response_company_change_request: null,
        };
      }
      if (payload.status === 'rejected') {
        needListData[changedIndex] = { ...needListData[changedIndex], response_company_change_request: null };
      }
      const { request_id } = payload;

      state.needList = {
        ...state.needList,
        data: needListData,
      };
      state.requestInfo.data[request_id] = needListData[changedIndex];
    },
    socketHandleDeleteItemSandBox(state, { payload: { requestData, sandBoxData, entity, params } }) {
      const currentState = current(state);

      const deleteItemFromList = list => {
        if (!list || !list?.length) {
          return list;
        }

        const accordionList = [...list];
        const changingAccordionIndex = accordionList.findIndex(accordion => accordion?.data.find(nf => nf.id === sandBoxData.id));
        const changingAccordionData = [...(accordionList[changingAccordionIndex]?.data || [])];
        const changingSandBoxItemIndex = changingAccordionData.findIndex(item => item.id === sandBoxData.id);

        changingAccordionData.splice(changingSandBoxItemIndex, 1);

        const changingAccordion = {
          ...accordionList[changingAccordionIndex],
          data: changingAccordionData,
        };

        accordionList.splice(changingAccordionIndex, 1, changingAccordion);
        return accordionList;
      };

      state.sandBox.accordion_list = deleteItemFromList(currentState.sandBox.accordion_list);

      const updateSandBoxCacheData = related_to => {
        const related = related_to === 'user' ? 'user' : 'party';
        const notRelated = related_to === 'user' ? 'party' : 'user';

        if (params?.related_to === 'user') {
          let newSandBoxCache = {
            ...state.sandBoxCache,
            [related]: {
              ...state.sandBoxCache[related],
              [entity]: {
                ...state.sandBoxCache[related][entity],
                [params.filter_by]: {
                  ...state.sandBoxCache[related][entity][params.filter_by],
                  accordion_list: deleteItemFromList(currentState.sandBox.accordion_list),
                },
              },
            },
          };

          if (currentState.sandBoxCache[notRelated][entity][params.filter_by]?.accordion_list?.length) {
            newSandBoxCache = {
              ...newSandBoxCache,
              [notRelated]: {
                ...newSandBoxCache[notRelated],
                [entity]: {
                  ...newSandBoxCache[notRelated][entity],
                  [params.filter_by]: {
                    ...newSandBoxCache[notRelated][entity][params.filter_by],
                    accordion_list: deleteItemFromList(currentState.sandBoxCache[notRelated][entity][params.filter_by]?.accordion_list),
                  },
                },
              },
            };
          }

          state.sandBoxCache = newSandBoxCache;
        }
      };

      updateSandBoxCacheData(params?.related_to);

      if (entity === 'request') {
        const requests = { ...state.requestInfo?.data };

        if (!requests[requestData.id]) {
          return state;
        }
        delete requests[requestData.id];

        state.requestInfo.data = requests;
      }
    },
    socketHandleDeleteRequestNeedList(state, { payload }) {
      const currentState = current(state);
      const changedIndex = state.needList?.data?.findIndex(i => i.id == payload?.id);
      if (typeof changedIndex !== 'number' || changedIndex === -1 || !state.needList?.data) {
        return state;
      }
      const needListData = [...currentState.needList.data];
      if (changedIndex + 1) {
        needListData.splice(changedIndex, 1);
      }

      state.loading = false;
      state.needList = {
        ...state.needList,
        data: needListData,
        total: needListData.length,
      };
    },
    watcherAddRequest(state, { payload }) {
      const requests = { ...state.requestInfo?.data };
      if (!requests[payload?.data?.request_id]) {
        return state;
      }

      state.requestInfo.data = requests;
    },
    clearSearchWatchersSuccess(state) {
      state.findWatchers = [];
    },
    sandBoxReturnToMainPage(state, { payload }) {
      state.isReturnToSandBox = payload.status;
    },
    clearNeedListQueryParams(state) {
      state.queryParams = {};
    },
    clearNeedListFilters(state) {
      state.isFirstNeedListLoading = true;
      state.needListFilters = {};
      state.needList = initialState.needList;
      state.queryParams = {};
    },
    clearCounters(state) {
      const currentState = current(state);

      state.sandBox = {
        ...currentState.sandBox,
        counters: null,
      };
    },
    addItemsToNeedList(state, { payload }) {
      const currentState = current(state);
      const { items, isLastPage, projectId } = payload;
      const needListData = currentState?.needList?.data && [...currentState?.needList?.data];
      const needListFiltered = currentState?.needList?.filtered && [...currentState?.needList?.filtered];

      // IF isLastPage and filtered exist, when all data came from WebSockets use it to update store.
      // Otherwise, just add items from WS.
      items?.map(item => {
        needListData.push(item);
      });

      if (isLastPage) {
        needListData.forEach(item => {
          DatabaseService.addItem({ item, storeName: `NEEDLIST`, projectId });
        });
      }

      if (isLastPage && needListFiltered) {
        state.needList = {
          ...state.needList,
          data: filterArrayByField(needListData, needListFiltered, 'id'),
          filtered: null,
        };
        state.loading = false;
        state.loadingNeedListTable = false;
        state.isFirstNeedListLoading = false;
      } else {
        state.needList = {
          ...state.needList,
          data: needListData,
        };
      }
    },
    resetSandboxLoadings(state) {
      state.isFirstSandBoxAllCountersLoading = true;
      state.isFirstSandBoxWidgetsLoading = true;
      state.isFirstSandBoxCountersLoading = true;
    },
    handleRequestChangeWatchlist(state, { payload }) {
      state.socketWatchlistState = { changedNow: true, watchers: payload.watchers, watcher_groups: payload.watcher_groups };
    },
  },
  extraReducers: builder =>
    builder
      /**
       * singleRequestLoading
       */
      .addCase(singleRequestLoading.pending, (state, { meta }: PayloadAction<null, string, { arg: ISingleRequestLoadingPayload }>) => {
        state.isLoadingRequestByNf = true;
        state.idLoadedRequestByNf = false;
        state.nfNumberLoadedRequestByNf = false;
      })
      .addCase(singleRequestLoading.fulfilled, (state, { payload }) => {
        state.idLoadedRequestByNf = payload.data.id;
        state.nfNumberLoadedRequestByNf = payload.data.nf;
        state.isLoadingRequestByNf = false;
      })
      .addCase(singleRequestLoading.rejected, (state, { payload, error }) => {
        state.idLoadedRequestByNf = false;
        state.nfNumberLoadedRequestByNf = false;
        state.isLoadingRequestByNf = false;
      })
      /**
       * getRequestByNf
       */
      .addCase(getRequestByNf.pending, (state, { payload }) => {
        state.isLoadingRequestByNf = true;
        state.idLoadedRequestByNf = false;
        state.nfNumberLoadedRequestByNf = false;
      })
      .addCase(getRequestByNf.fulfilled, (state, { payload }) => {
        state.idLoadedRequestByNf = payload.data.id;
        state.nfNumberLoadedRequestByNf = payload.data.nf;
        state.isLoadingRequestByNf = false;
      })
      .addCase(getRequestByNf.rejected, (state, { payload, error }) => {
        state.idLoadedRequestByNf = false;
        state.nfNumberLoadedRequestByNf = false;
        state.isLoadingRequestByNf = false;
      })
      /**
       * requestLoading
       */
      .addCase(requestLoading.pending, (state, { payload }) => {
        return state;
      })
      .addCase(requestLoading.fulfilled, (state, { payload }) => {
        return state;
      })
      .addCase(requestLoading.rejected, (state, { payload, error }) => {
        state.projectId = null;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
        state.getRequestLoadingStatus = false;
      })
      /**
       * requestSave
       */
      .addCase(requestSave.pending, (state, { meta }) => {
        state.loading = false;
        state.status = false;
        state.saveRequestStatus = false;
        state.saveCommitmentStatus = false;
        state.requestId = null;
        state.requestNf = null;
        state.sentRequestStatus = null;
        state.requestLoading = true;
        state.currentRequestToOpenId = meta.arg.requestId;
      })
      .addCase(requestSave.fulfilled, (state, { payload }) => {
        // const { requestInfo } = state;

        let sentRequestStatus = payload.data.status;
        if (payload.isNew) {
          sentRequestStatus = 'newRequest';
        }
        if (payload.isNew && !payload.data.is_whiteboard) {
          sentRequestStatus = 'newDraft';
        }
        if (!payload.isNew && !payload.data.is_whiteboard) {
          sentRequestStatus = 'draft';
        }

        state.sentRequestStatus = sentRequestStatus;
        state.status = payload.status;
        state.saveRequestStatus = true;
        state.message = payload.message;
        state.loading = false;
        state.requestNf = payload.data.nf;
        state.requestInfo.data[payload.data.id] = payload.data;
        if (payload.isCommitPull) {
          state.requestId = payload.response.parent.id;
        } else {
          state.requestId = payload.data.id;
        }

        state.requestLoading = false;
        //instead of requestUpdateNeedList
        state.lastUpdateNeedList = payload.data;
        state.requestCardErrors = null;
      })
      .addCase(requestSave.rejected, (state, { payload }) => {
        state.status = payload.status;
        state.saveRequestStatus = false;
        state.message = payload.message;
        state.loading = false;
        state.requestLoading = false;
        state.requestCardErrors = payload?.data?.errors;
      })
      /**
       * requestDelete
       */
      .addCase(requestDelete.pending, (state, { meta }: PayloadAction<null, string, { arg: IRequestDeletePayload }>) => {
        const currentState = current(state);
        const requests = { ...currentState.requestInfo?.data };
        const { requestId, page, accordion } = meta.arg;

        if (requests && Object.values(requests)?.length) {
          delete requests[requestId];
        }

        if (page === 'sandbox') {
          const accordionList = [...currentState.sandBox.accordion_list];
          const changingAccordionIndex = accordionList.findIndex(accordionData => accordionData.alias === accordion);
          const changingAccordionData = [...accordionList[changingAccordionIndex].data];
          const changingSandBoxItemIndex = changingAccordionData.findIndex(item => item.id === requestId);

          changingAccordionData.splice(changingSandBoxItemIndex, 1);

          const changingAccordion = {
            ...accordionList[changingAccordionIndex],
            data: changingAccordionData,
          };

          accordionList.splice(changingAccordionIndex, 1, changingAccordion);

          state.sandBox.accordion_list = accordionList;
        }

        state.loading = true;
        state.requestDeleteStatus = meta.arg.requestId;
        state.requestDeleteStatusSuccess = false;
        state.requestInfo.data = requests;
      })
      .addCase(requestDelete.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.requestDeleteStatus = false;
        state.requestDeleteStatusSuccess = true;
      })
      .addCase(requestDelete.rejected, state => {
        state.loading = false;
        state.requestDeleteStatusSuccess = false;
      })
      /**
       * requestUpdatePrivate
       */
      .addCase(requestUpdatePrivate.pending, state => {
        return state;
      })
      .addCase(requestUpdatePrivate.fulfilled, (state, { payload }) => {
        // const { requestInfo } = state;

        state.requestInfo.data[payload.data[0].id].is_rfi = payload.data[0].is_rfi;
        // return {
        //   ...state,
        //   requestInfo: {
        //     ...requestInfo,
        //     data: {
        //       ...requestInfo.data,
        //       [payload.data[0].id]: {
        //         ...requestInfo.data[payload.data[0].id],
        //         is_rfi: payload.data[0].is_rfi,
        //       },
        //     },
        //   },
        // };
      })
      .addCase(requestUpdatePrivate.rejected, state => {
        return state;
      })
      /**
       * setHotList
       */
      .addCase(setHotList.pending, (state, { meta }: PayloadAction<IRequestSetHotList>) => {
        state.saveHotlistLoading = Api.requestStatus;
      })
      .addCase(setHotList.fulfilled, (state, { payload, meta }) => {
        state.saveHotlistLoading = Api.successStatus;
      })
      .addCase(setHotList.rejected, state => {
        state.saveHotlistLoading = Api.failStatus;
        return state;
      })
      /**
       * responseSave
       */
      .addCase(responseSave.pending, (state, { meta }) => {
        state.saveCommitmentStatus = false;
        state.requestLoading = true;
        state.currentRequestToOpenId = meta.arg.requestId;
      })
      .addCase(responseSave.fulfilled, (state, { payload }) => {
        //Remove not user keys from Object
        const request = { ...payload.data };
        delete payload.data;
        // delete payload.data.user;
        const { requestInfo } = state;
        if (requestInfo?.data?.[request.id]) {
          const currentRequest = requestInfo.data[request.id];

          state.status = payload.status;
          state.message = payload.message;
          state.loading = false;
          state.requestInfo.data[request.id] = { ...currentRequest, ...request };
          state.requestNf = currentRequest.nf;
          state.requestLoading = false;
          state.is_file = payload.response.is_file == '1';
          state.currentRequestToOpenId = null;
        } else {
          state.status = payload.status;
          state.message = payload.message;
          state.loading = false;
          state.requestLoading = false;
        }
        state.saveCommitmentStatus = true;
        state.lastUpdateNeedList = payload.data;
      })
      .addCase(responseSave.rejected, (state, { payload }) => {
        state.saveCommitmentStatus = false;
        state.requestLoading = false;
        state.loading = false;
        state.status = payload.status;
        state.message = payload.message;
      })
      /**
       * bulkEdit
       */
      .addCase(bulkEdit.pending, state => {
        return state;
      })
      .addCase(bulkEdit.fulfilled, (state, { payload }: PayloadAction<NeedListRequest[]>) => {
        const requests = [];
        const ids = payload.map(updatedReq => updatedReq.id);
        state.needList.data.forEach(request => {
          if (ids.includes(request.id)) {
            const updatedReq = payload.find(item => item.id === request.id);
            requests.push(updatedReq);
          } else {
            requests.push(request);
          }
        });

        state.needList = {
          ...state.needList,
          data: requests,
        };
      })
      .addCase(bulkEdit.rejected, state => {
        return state;
      })
      /**
       * needList
       */
      .addCase(needList.pending, state => {
        const needsListData = state.needList.data.length ? [...state.needList.data] : [];

        state.loading = true;
        state.needList = {
          ...state.needList,
          data: needsListData,
        };
        state.loadingNeedListTable = true;
      })
      .addCase(needList.fulfilled, (state, { payload }) => {
        const needListData = [...payload?.response?.items];
        const newFiltered = payload?.response?.filtered || null;

        state.needList = {
          data: needListData,
          total: payload?.response?.total,
          filtered: newFiltered,
        };

        // If needList request with filters we are waiting for all data from webSockets to cache it.
        // And in the end we will use filteredIds to display it. (Search for 'addItemsToNeedList' action)
        if (newFiltered) {
          state.loading = true;
          state.loadingNeedListTable = true;
          state.isFirstNeedListLoading = true;
        } else {
          state.loading = false;
          state.loadingNeedListTable = false;
          state.isFirstNeedListLoading = false;
        }

        state.message = payload.message;
        state.status = payload.status;
      })
      .addCase(needList.rejected, (state, { meta, payload }) => {
        if (meta.aborted) {
          return state;
        }
        // state.message = error.message;
        // state.status = !!error.code;
        state.loading = false;
        state.loadingNeedListTable = false;

        state.isFirstNeedListLoading = false;
      })
      /**
       * getNeedListColumnOrder
       */
      .addCase(getNeedListColumnOrder.pending, state => {
        const columnOrder = state.columnOrder.length ? state.columnOrder : '';

        state.columnOrder = columnOrder;
        // return {
        //   ...state,
        //   columnOrder: columnOrder,
        // };
      })
      .addCase(getNeedListColumnOrder.fulfilled, (state, { payload }) => {
        const columnOrder = payload?.data?.setting === 'there is no data' ? state.columnOrder : payload.data.setting;

        state.columnOrder = columnOrder;
        //   return {
        //   ...state,
        //   columnOrder: columnOrder,
        // };
      })
      .addCase(getNeedListColumnOrder.rejected, state => {
        return state;
      })
      /**
       * setNeedListColumnOrder
       */
      .addCase(setNeedListColumnOrder.pending, (state, { meta }: PayloadAction<null, string, { arg: { columnOrder: string } }>) => {
        state.columnOrder = meta.arg.columnOrder;
        // return {
        //   ...state,
        //   columnOrder: payload.columnOrder,
        // };
      })
      .addCase(setNeedListColumnOrder.fulfilled, (state, { payload }) => {
        return state;
      })
      .addCase(setNeedListColumnOrder.rejected, state => {
        return state;
      })
      /**
       * getTags
       */
      .addCase(getTags.pending, (state, { payload }) => {
        return state;
      })
      .addCase(getTags.fulfilled, (state, { payload }) => {
        state.tags = Object.values(payload.data);
        // return {
        //   ...state,
        //   tags: Object.values(payload.data),
        // };
      })
      .addCase(getTags.rejected, state => {
        return state;
      })
      /**
       * getSandBoxWidgetsHotlistData
       */
      .addCase(getSandBoxWidgetsHotlistData.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        state.sandBoxHotlistDataLoading = Api.requestStatus;
        state.sandBoxHotlist.accordion_list = [];
        //state.sandBoxFilters = meta.arg;
      })
      .addCase(
        getSandBoxWidgetsHotlistData.fulfilled,
        (
          state,
          { payload }: PayloadAction<{ response: ResponseSandBox; filter_by: string; related_to: string; queryData: KeyValueModel<{}> }>,
        ) => {
          const { response } = payload;

          state.sandBoxHotlist.accordion_list = response.data.accordion_list;
          state.sandBoxHotlistDataLoading = Api.successStatus;
        },
      )
      .addCase(getSandBoxWidgetsHotlistData.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxHotlistDataLoading = Api.failStatus;
      })
      /**
       * getSandBoxData
       */
      .addCase(getSandBoxData.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        state.sendBoxDataLoading = Api.requestStatus;
        state.sandBox.accordion_list = [];
        state.sandBoxFilters = meta.arg;
      })
      .addCase(
        getSandBoxData.fulfilled,
        (
          state,
          { payload }: PayloadAction<{ response: ResponseSandBox; filter_by: string; related_to: string; queryData: KeyValueModel<{}> }>,
        ) => {
          const { filter_by, related_to, response, queryData, hash } = payload;

          state.sandBox.accordion_list = response.data.accordion_list;
          state.sendBoxDataLoading = Api.successStatus;
          delete queryData.local_storage;
          delete queryData.filters_hash;

          //TODO sandboxCache new logic
          const section = filter_by.split('_')[0];

          state.sandBoxCache = {
            ...state.sandBoxCache,
            [related_to]: {
              ...state.sandBoxCache[related_to],
              [section]: {
                ...state.sandBoxCache[related_to][section],
                [filter_by]: {
                  accordion_list: response.data.accordion_list,
                  timeStamp: new Date(),
                  queryData: queryData,
                  hash: hash,
                },
              },
            },
          };
        },
      )
      .addCase(getSandBoxData.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sendBoxDataLoading = Api.failStatus;
      })
      /**
       * checkIfSnippetInSandBoxSection
       */
      .addCase(
        checkIfSnippetInSandBoxSection.pending,
        (state, { meta }: PayloadAction<any, string, { arg: ICheckIfItemInSectionPayload }>) => {
          return state;
        },
      )
      .addCase(
        checkIfSnippetInSandBoxSection.fulfilled,
        (state, { payload: { sandBoxItem, check_accordion_list, params } }: PayloadAction<ICheckIfItemInSectionResponseData>) => {
          const currentState = current(state);

          const section = params.filter_by.split('_')[0];

          const currentTabName = currentState.sandBoxFilters.filter_by === params.filter_by;
          const currentRelatedTo = currentState.sandBoxFilters.related_to === params.related_to;

          const accordionListFromCache = currentState.sandBoxCache[params.related_to][section][params.filter_by].accordion_list;

          const accordionList = [
            ...((currentTabName && currentRelatedTo ? currentState.sandBox.accordion_list : accordionListFromCache) || []),
          ];

          check_accordion_list.forEach(acc => {
            const isExist = !!accordionList.find(item => item.alias === acc.alias);

            if (!isExist) {
              accordionList.unshift({
                ...acc,
                data: [],
              });
            }
          });

          const newAccordionList = accordionList.reduce((acc, accordion) => {
            const currentAccordionData = [...accordion.data];
            const isExistInCheck = !!check_accordion_list.find(accItem => accItem.alias === accordion.alias);
            const sandBoxItemIndexInAccordion = currentAccordionData.findIndex(item => item.id === sandBoxItem.id);
            const isSandBoxItemExistInAccordion = sandBoxItemIndexInAccordion !== -1;

            if (!isExistInCheck && isSandBoxItemExistInAccordion) {
              currentAccordionData.splice(sandBoxItemIndexInAccordion, 1);
            }

            if (isExistInCheck && isSandBoxItemExistInAccordion) {
              const replaceSandBoxItem = {
                ...(sandBoxItem || {}),
                is_hot_list:
                  typeof sandBoxItem.is_hot_list === 'boolean'
                    ? sandBoxItem?.is_hot_list
                    : currentAccordionData[sandBoxItemIndexInAccordion].is_hot_list,
              };
              currentAccordionData.splice(sandBoxItemIndexInAccordion, 1, replaceSandBoxItem);
            }

            if (isExistInCheck && !isSandBoxItemExistInAccordion) {
              currentAccordionData.unshift(sandBoxItem);
            }

            return [...acc, { ...accordion, data: currentAccordionData }];
          }, []);

          if (currentTabName && currentRelatedTo) {
            state.sandBox.accordion_list = newAccordionList.filter(accordion => accordion.data.length);
          }

          state.sandBoxCache = {
            ...state.sandBoxCache,
            [params.related_to]: {
              ...state.sandBoxCache[params.related_to],
              [section]: {
                ...state.sandBoxCache[params.related_to][section],
                [params.filter_by]: {
                  ...state.sandBoxCache[params.related_to][section][params.filter_by],
                  accordion_list: newAccordionList.filter(accordion => accordion.data.length),
                },
              },
            },
          };
        },
      )
      .addCase(checkIfSnippetInSandBoxSection.rejected, (state, { meta }) => {
        return state;
      })
      /**
       * getSandBoxCounters
       */
      .addCase(getSandBoxCounters.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        if (!meta.arg) {
          return state;
        }

        state.sandBoxCountersLoading = Api.requestStatus;
      })
      .addCase(getSandBoxCounters.fulfilled, (state, { payload }: PayloadAction<ResponseSandBoxCounters>) => {
        const currentState = current(state);
        const { counter_outer, ...data } = payload.data;

        state.sandBox = {
          ...currentState.sandBox,
          counters: {
            ...(currentState.sandBox.counters || {}),
            ...(data || {}),
          },
          total: counter_outer,
        };
        state.sandBoxCountersLoading = Api.successStatus;
        state.isFirstSandBoxCountersLoading = false;
      })
      .addCase(getSandBoxCounters.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxCountersLoading = Api.failStatus;
        state.isFirstSandBoxCountersLoading = false;
      })
      /**
       * getSandBoxhotListCounters
       */
      .addCase(getSandBoxhotListCounters.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        if (!meta.arg) {
          return state;
        }

        state.sandBoxCountersLoading = Api.requestStatus;
      })
      .addCase(getSandBoxhotListCounters.fulfilled, (state, { payload }: PayloadAction<ResponseSandBoxCounters>) => {
        const currentState = current(state);
        const { counter_outer, ...data } = payload.data;

        state.sandBoxHotlist = {
          ...currentState.sandBoxHotlist,
          counters: {
            ...(currentState.sandBoxHotlist.counters || {}),
            ...(data || {}),
          },
          total: counter_outer,
        };
        state.sandBoxCountersLoading = Api.successStatus;
      })
      .addCase(getSandBoxhotListCounters.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxCountersLoading = Api.failStatus;
      })
      /**
       * getSandBoxhotListCountersAll
       */
      .addCase(getSandBoxhotListCountersAll.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        if (!meta.arg) {
          return state;
        }

        state.sandBoxCountersLoading = Api.requestStatus;
      })
      .addCase(getSandBoxhotListCountersAll.fulfilled, (state, { payload }: PayloadAction<ResponseSandBoxCounters>) => {
        const currentState = current(state);
        const { counter_outer, ...data } = payload.data;

        state.sandBoxHotlist = {
          ...currentState.sandBoxHotlist,
          counters: {
            ...(currentState.sandBoxHotlist.counters || {}),
            ...(data || {}),
          },
          total: counter_outer,
        };
        state.sandBoxAllCountersLoading = Api.successStatus;
        state.isFirstSandBoxAllCountersLoading = false;
      })
      .addCase(getSandBoxhotListCountersAll.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxAllCountersLoading = Api.failStatus;
        state.isFirstSandBoxAllCountersLoading = false;
      })
      /**
       * getSandBoxOuterCounter
       */
      .addCase(getSandBoxOuterCounter.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxQueryModel }>) => {
        state.sandBoxOuterCounterLoading = Api.requestStatus;
      })
      .addCase(getSandBoxOuterCounter.fulfilled, (state, { payload }: PayloadAction<ResponseSandBoxOuterCounter>) => {
        const currentState = current(state);
        const { counter_outer } = payload.data;

        state.sandBox = {
          ...currentState.sandBox,
          total: counter_outer,
        };
        state.sandBoxOuterCounterLoading = Api.successStatus;
      })
      .addCase(getSandBoxOuterCounter.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxOuterCounterLoading = Api.failStatus;
      })
      /**
       * getSandBoxWidgets
       */
      .addCase(getSandBoxWidgets.pending, (state, { meta }: PayloadAction<any, string, { arg: SandBoxWidgetsPayloadModel }>) => {
        state.sandBoxWidgetsLoading = Api.requestStatus;
      })
      .addCase(getSandBoxWidgets.fulfilled, (state, { payload }: PayloadAction<SandBoxWidgetsResponse>) => {
        const currentState = current(state);
        let sandBoxWidgets = { ...(currentState.sandBoxWidgets || {}) };

        Object.entries(payload.data.widgets).forEach(widget => {
          sandBoxWidgets = {
            ...sandBoxWidgets,
            [widget[0]]: widget[1],
          };
        });

        state.sandBoxWidgets = sandBoxWidgets;
        state.sandBoxWidgetsLoading = Api.successStatus;
        state.isFirstSandBoxWidgetsLoading = false;
      })
      .addCase(getSandBoxWidgets.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.sandBoxWidgetsLoading = Api.failStatus;
        state.isFirstSandBoxWidgetsLoading = false;
      })
      /**
       * requestSetSeen
       */
      .addCase(requestSetSeen.pending, state => {
        return state;
      })
      .addCase(requestSetSeen.fulfilled, (state, { payload }) => {
        state.message = payload.message;
      })
      .addCase(requestSetSeen.rejected, state => {
        return state;
      })
      /**
       * ExportData
       */
      .addCase(ExportData.pending, state => {
        state.loading = true;
      })
      .addCase(ExportData.fulfilled, state => {
        state.loading = false;
      })
      .addCase(ExportData.rejected, (state, { error }) => {
        state.message = error.message;
        state.loading = false;
      })
      /**
       * whiteBoard
       */
      .addCase(whiteBoard.pending, state => {
        state.sandBoxDataFromProjectId = sessionStorage.getItem('active_project_id');

        state.whiteBoard = null;
        state.loading = true;
        state.wbLoading = true;
      })
      .addCase(whiteBoard.fulfilled, (state, { payload }) => {
        state.whiteBoard = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
        state.wbLoading = false;
        state.wbLoading = false;
      })
      .addCase(whiteBoard.rejected, (state, { payload, meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.whiteBoard = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
        state.wbLoading = false;
      })
      /**
       * whiteBoardFilters
       */
      .addCase(whiteBoardFilters.pending, state => {
        state.loading = true;
      })
      .addCase(whiteBoardFilters.fulfilled, (state, { payload }) => {
        state.whiteBoardFilters = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.wbLoading = false;
        state.loading = false;
      })
      .addCase(whiteBoardFilters.rejected, (state, { payload, meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.whiteBoard = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
        state.wbLoading = false;
      })
      /**
       * getWatchers
       */
      .addCase(getWatchers.pending, state => {
        return state;
      })
      .addCase(getWatchers.fulfilled, (state, { payload }) => {
        const requestId = payload.data && Object.keys(payload.data).length && payload.data[0].request_id;
        if (requestId) {
          state.loading = false;
          state.requestInfo.data[requestId] = { ...state.requestInfo.data[requestId] };
          // return {
          //   ...state,
          //   loading: false,
          //   requestInfo: {
          //     ...state.requestInfo,
          //     data: {
          //       ...state.requestInfo.data,
          //       [requestId]: {
          //         ...state.requestInfo.data[requestId],
          //         // watchers: Object.keys(action.payload.data).map(item => action.payload.data[item].user_id),
          //       },
          //     },
          //   },
          // };
        } else {
          state.loading = false;

          // return {
          //   ...state,
          //   loading: false,
          // }
        }
      })
      .addCase(getWatchers.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * getWatchersGroups
       */
      .addCase(getWatchersGroups.pending, state => {
        state.loading = true;
      })
      .addCase(getWatchersGroups.fulfilled, (state, { payload }) => {
        state.watchersGroups = payload.response.resource;
        state.loading = false;
      })
      .addCase(getWatchersGroups.rejected, (state, { payload }) => {
        state.loading = false;
      })
      /**
       * createWatchersGroups
       */
      .addCase(createWatchersGroups.pending, state => {
        state.loading = true;
      })
      .addCase(createWatchersGroups.fulfilled, (state, { payload }) => {
        state.watchersGroups = payload.response.resource;
        state.loading = false;
      })
      .addCase(createWatchersGroups.rejected, (state, { payload }) => {
        state.loading = false;
      })
      /**
       * deleteWatchersGroups
       */
      .addCase(deleteWatchersGroups.pending, state => {
        state.loading = true;
      })
      .addCase(deleteWatchersGroups.fulfilled, (state, { payload }) => {
        state.watchersGroups = payload.response.resource;
        state.loading = false;
      })
      .addCase(deleteWatchersGroups.rejected, (state, { payload }) => {
        state.loading = false;
      })
      /**
       * updateWatchersGroups
       */
      .addCase(updateWatchersGroups.pending, state => {
        state.loading = true;
      })
      .addCase(updateWatchersGroups.fulfilled, (state, { payload }) => {
        state.watchersGroups = payload.response.resource;
        state.loading = false;
      })
      .addCase(updateWatchersGroups.rejected, (state, { payload }) => {
        state.loading = false;
      })
      /**
       * addToWatchersGroups
       */
      .addCase(addToWatchersGroups.pending, state => {
        return state;
      })
      .addCase(addToWatchersGroups.fulfilled, (state, { payload }) => {
        return state;
      })
      .addCase(addToWatchersGroups.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * searchWatchersRequest
       */
      .addCase(searchWatchersRequest.pending, state => {
        state.loading = true;
      })
      .addCase(searchWatchersRequest.fulfilled, (state, { payload }) => {
        state.findWatchers = payload?.response?.resource || [];
        state.loading = false;
      })
      .addCase(searchWatchersRequest.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * createNewDueDate
       */
      .addCase(createNewDueDate.pending, state => {
        return state;
      })
      .addCase(createNewDueDate.fulfilled, (state, { payload }) => {
        if (!state?.requestInfo?.data) {
          return state;
        }
        const requests = { ...state.requestInfo?.data };
        const due_date_change_request = { ...payload };
        delete due_date_change_request.request;
        const request = { ...payload.request, due_date_change_request: due_date_change_request };
        requests[payload.request.id] = { ...requests[payload.request.id], ...request };

        state.requestInfo.data = requests;
        // return {
        //   ...state,
        //   requestInfo: {
        //     ...state.requestInfo,
        //     data: requests,
        //   },
        // };
      })
      .addCase(createNewDueDate.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * createNewResponseCompany
       */
      .addCase(createNewResponseCompany.pending, state => {
        return state;
      })
      .addCase(createNewResponseCompany.fulfilled, (state, { payload }) => {
        if (!state?.requestInfo?.data) {
          return state;
        }
        const requests = { ...state.requestInfo?.data };
        requests[payload.id] = payload;

        state.requestInfo.data = requests;
        // return {
        //   ...state,
        //   requestInfo: {
        //     ...state.requestInfo,
        //     data: requests,
        //   },
        // };
      })
      .addCase(createNewResponseCompany.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * updateNewResponseCompany
       */
      .addCase(updateNewResponseCompany.pending, state => {
        return state;
      })
      .addCase(updateNewResponseCompany.fulfilled, (state, { payload }) => {
        if (!state?.requestInfo?.data) {
          return state;
        }
        const requests = { ...state.requestInfo?.data };
        requests[payload.id] = payload;

        const needListData = state?.needList?.data ? [...state.needList.data] : [];
        const findIndex = needListData.findIndex(n => n.id === payload.id);
        if (findIndex + 1) {
          needListData[findIndex] = payload;
        }

        state.requestInfo.data = requests;
        state.needList = {
          ...state.needList,
          data: needListData,
        };
      })
      .addCase(updateNewResponseCompany.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * updateNewDueDate
       */
      .addCase(updateNewDueDate.pending, state => {
        return state;
      })
      .addCase(updateNewDueDate.fulfilled, (state, { payload }) => {
        if (!state?.requestInfo?.data) {
          return state;
        }
        const requests = { ...state.requestInfo?.data };
        // const due_date_change_request = {...payload};
        // delete due_date_change_request.request;
        const request = { ...payload.request, due_date_change_request: null };
        requests[payload.request.id] = { ...requests[payload.request.id], ...request };

        state.requestInfo.data = requests;
        // return {
        //   ...state,
        //   requestInfo: {
        //     ...state.requestInfo,
        //     data: requests,
        //   },
        // };
      })
      .addCase(updateNewDueDate.rejected, (state, { payload }) => {
        return state;
      })
      /**
       * sendBugReport
       */
      .addCase(sendBugReport.pending, state => {
        state.statusBug = null;
        state.loading = true;
        state.sandBugReportErrors = null;
        // return {
        //   ...state,
        //   statusBug: null,
        //   loading: true,
        //   sandBugReportErrors: null,
        // };
      })
      .addCase(sendBugReport.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.statusBug = payload.status;
        state.loading = false;
        state.sandBugReportErrors = null;
        // return {
        //   ...state,
        //   message: payload.message,
        //   statusBug: payload.status,
        //   loading: false,
        //   sandBugReportErrors: null,
        // };
      })
      .addCase(sendBugReport.rejected, (state, { payload }) => {
        state.message = payload.message;
        state.sandBugReportErrors = payload.errors;
        state.statusBug = null;
        state.loading = false;
        // return {
        //   ...state,
        //   message: payload.message,
        //   sandBugReportErrors: payload.errors,
        //   statusBug: null,
        //   loading: false,
        // };
      })
      /**
       * searchUsersRequest
       */
      .addCase(searchUsersRequest.pending, state => {
        state.loading = true;
      })
      .addCase(searchUsersRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.findUsers = payload.data;
      })
      .addCase(searchUsersRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.findUsers = [];
      })
      /**
       * linkNfPredecessor
       */
      .addCase(linkNfPredecessor.pending, state => {
        state.nfLinkLoading = Api.requestStatus;
      })
      .addCase(linkNfPredecessor.fulfilled, (state, { payload }: PayloadAction<FullRequestModel>) => {
        let requestInfoData = {};

        requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
        requestInfoData = { ...requestInfoData, [payload.id]: payload };
        state.nfLinkLoading = Api.successStatus;
        state.requestInfo.data = requestInfoData;
      })
      .addCase(linkNfPredecessor.rejected, state => {
        state.nfLinkLoading = Api.failStatus;
      })
      /**
       * unlinkNfPredecessor
       */
      .addCase(unlinkNfPredecessor.pending, state => {
        state.nfUnlinkLoading = Api.requestStatus;
      })
      .addCase(unlinkNfPredecessor.fulfilled, (state, { payload }: PayloadAction<FullRequestModel>) => {
        let requestInfoData = {};

        requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
        requestInfoData = { ...requestInfoData, [payload.id]: payload };
        state.nfUnlinkLoading = Api.successStatus;
        state.requestInfo.data = requestInfoData;
      })
      .addCase(unlinkNfPredecessor.rejected, state => {
        state.nfUnlinkLoading = Api.failStatus;
      })
      /**
       * linkNfSuccessor
       */
      .addCase(linkNfSuccessor.pending, state => {
        state.nfLinkLoading = Api.requestStatus;
      })
      .addCase(linkNfSuccessor.fulfilled, (state, { payload }: PayloadAction<FullRequestModel>) => {
        let requestInfoData = {};

        requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
        requestInfoData = { ...requestInfoData, [payload.id]: payload };
        state.nfLinkLoading = Api.successStatus;
        state.requestInfo.data = requestInfoData;
      })
      .addCase(linkNfSuccessor.rejected, state => {
        state.nfLinkLoading = Api.failStatus;
      })
      /**
       * unlinkNfSuccessor
       */
      .addCase(unlinkNfSuccessor.pending, state => {
        state.nfUnlinkLoading = Api.requestStatus;
      })
      .addCase(unlinkNfSuccessor.fulfilled, (state, { payload }: PayloadAction<FullRequestModel>) => {
        let requestInfoData = {};

        requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
        requestInfoData = { ...requestInfoData, [payload.id]: payload };
        state.nfUnlinkLoading = Api.successStatus;
        state.requestInfo.data = requestInfoData;
      })
      .addCase(unlinkNfSuccessor.rejected, (state, { payload }) => {
        state.nfUnlinkLoading = Api.failStatus;
      })
      /**
       * getNfLink
       */
      .addCase(getNfLink.pending, state => {
        state.nfLinkingSuggestionsLoading = Api.requestStatus;
      })
      .addCase(getNfLink.fulfilled, (state, { payload }: PayloadAction<GetLinkingNfSuggestionResponseModel>) => {
        const currentState = current(state);
        const { relation_type, suggestions } = payload;

        state.nfLinkingSuggestionsLoading = Api.successStatus;
        state.nfLinkingSuggestions = {
          ...currentState.nfLinkingSuggestions,
          [relation_type]: suggestions,
        };
      })
      .addCase(getNfLink.rejected, (state, { payload }) => {
        state.nfLinkingSuggestionsLoading = Api.failStatus;
      })
      /**
       * requestView
       */
      .addCase(requestView.pending, (state, { meta }: PayloadAction<null, string, { arg: { id: number } }>) => {
        state.requestViewLoading = true;
      })
      .addCase(requestView.fulfilled, (state, { payload }) => {
        state.requestViewLoading = false;
      })
      .addCase(requestView.rejected, (state, { payload }) => {
        state.requestViewLoading = false;
      })
      /**
       * setIncorporated
       */
      .addCase(setIncorporated.pending, state => {
        return state;
      })
      .addCase(setIncorporated.fulfilled, state => {
        return state;
      })
      .addCase(setIncorporated.rejected, state => {
        return state;
      })
      /**
       * getFilteredRequest
       */
      .addCase(getFilteredRequest.pending, (state, { meta }) => {
        state.singleRequestLoaded = false;
        state.currentRequestToOpenId = meta.arg.id;
      })
      .addCase(getFilteredRequest.fulfilled, (state, { payload }) => {
        state.singleRequestLoaded = true;
        state.isRequestFiltersMatch = payload.response.match;
        state.is_file = false;
      })
      .addCase(getFilteredRequest.rejected, state => {
        state.singleRequestLoaded = true;
        state.isRequestFiltersMatch = false;
      })
      /**
       * getNeedListAllRequest
       */
      .addCase(getNeedListAllRequest.pending, state => {
        return state;
      })
      .addCase(getNeedListAllRequest.fulfilled, (state, { payload }) => {
        return state;
      })
      .addCase(getNeedListAllRequest.rejected, state => {
        return state;
      })
      /**
       * getSuccPredFromRequest
       */
      .addCase(getSuccPredFromRequest.pending, state => {
        state.linkedRequest = {};
      })
      .addCase(getSuccPredFromRequest.fulfilled, (state, { payload }) => {
        state.linkedRequest = payload;
      })
      .addCase(getSuccPredFromRequest.rejected, state => {
        return state;
      })
      /**
       * editHashtag
       */
      .addCase(editHashtag.pending, state => {
        state.loading = true;
      })
      .addCase(editHashtag.fulfilled, state => {
        state.loading = false;
      })
      .addCase(editHashtag.rejected, state => {
        state.loading = false;
      })
      /**
       * deleteHashtag
       */
      .addCase(deleteHashtag.pending, state => {
        state.loading = true;
      })
      .addCase(deleteHashtag.fulfilled, (state, { payload }) => {
        const allTags = payload;

        const filteredArray = [];

        allTags.map(r => {
          filteredArray.push({
            label: r.name,
            value: r.id,
            color: r.color,
            link: r.link,
          });
        });

        state.loading = false;
        state.nfTags = filteredArray;
      })
      .addCase(deleteHashtag.rejected, state => {
        state.loading = false;
      })
      /**
       * deactivatedStatus
       */
      .addCase(deactivatedStatus.pending, (state, { meta }: PayloadAction<null, string, { arg: IRequestDeactivatePayload }>) => {
        const currentState = current(state);
        const { request_id, accordion, action } = meta.arg;
        const { sandBoxFilters } = currentState;

        if (accordion) {
          const accordionList = [...currentState.sandBox.accordion_list];
          const changingAccordionIndex = accordionList.findIndex(accordionData => accordionData.alias === accordion);
          const changingAccordionData = [...accordionList[changingAccordionIndex].data];
          const changingSandBoxItemIndex = changingAccordionData.findIndex(item => item.id === request_id);

          const changingSandBoxItem = {
            ...changingAccordionData[changingSandBoxItemIndex],
            is_deactivated: action === 'deactivate',
          };

          if (action === 'activate' && sandBoxFilters.filter_by === 'request_myNfs_deactivated') {
            changingAccordionData.splice(changingSandBoxItemIndex, 1);
          } else {
            changingAccordionData.splice(changingSandBoxItemIndex, 1, changingSandBoxItem);
          }

          const changingAccordion = {
            ...accordionList[changingAccordionIndex],
            data: changingAccordionData,
          };

          accordionList.splice(changingAccordionIndex, 1, changingAccordion);

          state.sandBox.accordion_list = accordionList;
        }
        state.loading_deactivated_status = true;
      })
      .addCase(deactivatedStatus.fulfilled, (state, { payload }) => {
        state.loading_deactivated_status = false;
      })
      .addCase(deactivatedStatus.rejected, state => {
        state.loading_deactivated_status = false;
      })
      /**
       * declineCounter
       */
      .addCase(declineCounter.pending, state => {
        state.loading = true;
      })
      .addCase(declineCounter.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.declineCounterData = payload.response.resource.declineCounter;
      })
      .addCase(declineCounter.rejected, state => {
        state.loading = false;
      })
      /**
       * getMainMf
       */
      .addCase(getMainMf.pending, state => {
        state.ufOrMfCodesLoading = true;
      })
      .addCase(getMainMf.fulfilled, (state, { payload }) => {
        state.ufOrMfCodesLoading = false;
        state.mfSearchSuggestions = payload.mainMfc;
      })
      .addCase(getMainMf.rejected, state => {
        //TODO need test cancel
        state.ufOrMfCodesLoading = false;
      })
      /**
       * changeNfStatusToDelivarable
       */
      .addCase(changeNfStatusToDelivarable.pending, state => {
        state.loading = true;
      })
      .addCase(changeNfStatusToDelivarable.fulfilled, (state, { payload }: PayloadAction<RequestModel>) => {
        let requestInfoData = {};
        requestInfoData = state.requestInfo.data && { ...state.requestInfo.data };
        requestInfoData = { ...requestInfoData, [payload.id]: payload };
        state.linkStatus = true;
        state.requestInfo.data = requestInfoData;
        state.loading = false;
      })
      .addCase(changeNfStatusToDelivarable.rejected, state => {
        state.loading = false;
      })
      /**
       * getMfFromParent
       */
      .addCase(getMfFromParent.pending, state => {
        state.ufOrMfCodesLoading = true;
      })
      .addCase(getMfFromParent.fulfilled, (state, { payload }) => {
        state.ufOrMfCodesLoading = false;
        state.mfTree = payload;
      })
      .addCase(getMfFromParent.rejected, state => {
        state.ufOrMfCodesLoading = false;
      })
      /**
       * getMainUf
       */
      .addCase(getMainUf.pending, state => {
        state.ufOrMfCodesLoading = true;
      })
      .addCase(getMainUf.fulfilled, (state, { payload }) => {
        state.ufOrMfCodesLoading = false;
        state.ufSearchSuggestions = payload;
      })
      .addCase(getMainUf.rejected, state => {
        state.ufOrMfCodesLoading = false;
      })
      /**
       * getUfFromParent
       */
      .addCase(getUfFromParent.pending, state => {
        state.ufOrMfCodesLoading = true;
      })
      .addCase(getUfFromParent.fulfilled, (state, { payload }) => {
        state.ufOrMfCodesLoading = false;
        state.ufTree = payload;
      })
      .addCase(getUfFromParent.rejected, state => {
        state.ufOrMfCodesLoading = false;
      })
      /**
       * searchMForUFCode
       */
      .addCase(searchMForUFCode.pending, state => {
        state.ufOrMfCodesLoading = true;
      })
      .addCase(searchMForUFCode.fulfilled, (state, { payload }) => {
        const { data, table } = payload;

        if (table === 'uf') {
          state.ufSearchSuggestions = data.search;
        } else {
          state.mfSearchSuggestions = data.search;
        }

        state.ufOrMfCodesLoading = false;
      })
      .addCase(searchMForUFCode.rejected, state => {
        state.ufOrMfCodesLoading = false;
      })
      /**
       * searchRequest
       */
      .addCase(searchRequest.pending, state => {
        state.searchRequestPartyLoading = true;
        return state;
      })
      .addCase(searchRequest.fulfilled, state => {
        state.searchRequestPartyLoading = false;
        return state;
      })
      .addCase(searchRequest.rejected, state => {
        state.searchRequestPartyLoading = false;
        return state;
      })
      /**
       * getSearchRequestFilters
       */
      .addCase(getSearchRequestFilters.pending, state => {
        state.requestFilterFields = [];
      })
      .addCase(getSearchRequestFilters.fulfilled, (state, { payload }) => {
        state.requestFilterFields = payload;
      })
      .addCase(getSearchRequestFilters.rejected, state => {
        return state;
      })
      /**
       * getSandBoxIDsHotList
       */
      .addCase(getSandBoxIDsHotList.pending, state => {
        state.sandBoxIDsHotList = [];
      })
      .addCase(getSandBoxIDsHotList.fulfilled, (state, { payload }) => {
        state.sandBoxIDsHotList = payload.response.identifier_matches;
      })
      .addCase(getSandBoxIDsHotList.rejected, state => {
        return state;
      })
      /**
       * getSandBoxCardIDHotList
       */
      .addCase(getSandBoxCardIDHotList.pending, state => {
        state.sandBoxCardIDHotList = [];
      })
      .addCase(getSandBoxCardIDHotList.fulfilled, (state, { payload }) => {
        state.sandBoxCardIDHotList = payload.response.identifier_matches;
      })
      .addCase(getSandBoxCardIDHotList.rejected, state => {
        return state;
      }),
});

export default requestSlice.reducer;
