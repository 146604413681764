import React from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import moment from 'moment/moment';
import cn from 'classnames';
import { convertToLocalTimezone } from '../../../helpers/commonHelpers';

import './DateRowStyles.scss';

interface Props {
  date: string | Date;
  showTime?: boolean;
  isOffset?: boolean;
  isMobile?: boolean;
}

const DateRow: React.FC<Props> = ({ date, showTime, isOffset = true, isMobile }) => {
  const themeClass = useGetThemeClass('b-date-row');

  if (!date) {
    return <></>;
  }

  return (
    <div
      className={cn(`${themeClass} ${themeClass}_container`, {
        ['-mobile']: isMobile,
      })}
    >
      {isOffset ? (
        <>
          <div className={`${themeClass}_date`}>
            <div className={`${themeClass}_label`}>{convertToLocalTimezone(moment(date)).format('MMM')}</div>
            <div className={`${themeClass}_label`}>{convertToLocalTimezone(moment(date)).format('DD, YYYY')}</div>
          </div>
          {showTime && (
            <>
              <div className={`${themeClass}_time`}>
                <div className={`${themeClass}_label`}>{convertToLocalTimezone(moment(date)).format('hh:mm')}</div>
                <div className={`${themeClass}_label`}>{convertToLocalTimezone(moment(date)).format('A')}</div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={`${themeClass}_date`}>
            <div className={`${themeClass}_label`}>{moment(date).format('MMM')}</div>
            <div className={`${themeClass}_label`}>{moment(date).format('DD, YYYY')}</div>
          </div>
          {showTime && (
            <>
              <div className={`${themeClass}_time`}>
                <div className={`${themeClass}_label`}>{moment(date).format('hh:mm')}</div>
                <div className={`${themeClass}_label`}>{moment(date).format('A')}</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DateRow;
