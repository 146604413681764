import { TabItemModel } from '../../../../models/global';

export type DeliverableCardTabNamesModel = 'deliverable' | 'related-items' | 'review' | 'discussion';

export type SubmitButtonActions = 'RevisionCreate' | 'RevisionSubmit' | 'RevisionUpdate' | 'DraftRevision';

export const deliverableCardTabsNames: DeliverableCardTabNamesModel[] = ['deliverable', 'related-items', 'review', 'discussion'];

export const deliverableCardTabs: TabItemModel<DeliverableCardTabNamesModel>[] = [
  {
    title: 'Deliverable',
    id: 'deliverable',
    count: 0,
  },
  {
    title: 'Related items',
    id: 'related-items',
    count: 0,
  },
  {
    title: 'Review',
    id: 'review',
    count: 0,
  },
  {
    title: 'Discussion / History',
    id: 'discussion',
    count: 0,
  },
];
