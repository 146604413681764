import { BaseApi } from './baseApi';

class RedmineApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async setRedmineId(bodyParams) {
    const result = await this._post({ route: 'redmine/sync', bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }
}

export default new RedmineApi({ devMode: false });
