import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { DrawerProps } from '@material-ui/core/Drawer/Drawer';

import './SidePanel.module.scss';

interface ISidePanelProps extends DrawerProps {
  direction: 'left' | 'top' | 'right' | 'bottom';
  handleCloseDrawer: (e: Event) => void;
}

const SidePanel = ({
  direction,
  open,
  handleCloseDrawer,
  BackdropProps,
  children,
  transitionDuration,
  onBackdropClick,
  PaperProps,
}: ISidePanelProps) => {
  const themeClass = useGetThemeClass('sidePanel');

  return (
    <Drawer
      anchor={direction}
      open={open}
      onClose={handleCloseDrawer}
      disableEnforceFocus
      BackdropProps={BackdropProps}
      PaperProps={PaperProps}
      transitionDuration={transitionDuration}
      disableBackdropClick
      onBackdropClick={onBackdropClick}
      classes={{ paper: `${themeClass}_paper` }}
    >
      {children}
    </Drawer>
  );
};

export default SidePanel;
