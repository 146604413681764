import React, { useMemo, useState, RefObject, useEffect, useRef, MutableRefObject } from 'react';
import SandBoxHotlistTable from './parts/SandBoxHotlistTable/SandBoxHotlistTable';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { SandBoxTabModel } from '../../../../../models/sandBox/sandBoxArhitecture';
import { sandBoxHotlistSections, AllSectionType } from '../../SandBoxConstants/SandBoxConstants';
import { KeyValueModel } from '../../../../../models/key-value.model';
import {
  getSandBoxWidgetsHotlistData,
  getSandBoxhotListCounters,
  getSandBoxhotListCountersAll,
} from '../../../../../store/request/requestLogic(HOLD)';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../../models/global';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';

import './SandBoxHotlistStyles.scss';

const { resetSocketSandBoxHotlistState } = requestSlice.actions;

interface DropdownItemModelExt extends DropdownItemModel {
  count?: number;
}

interface IProps {
  project_id: number;
  openRequest: (id: number, nf: number, accordionAlias: string, isMarked: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  openSubmittal: (id: string, accordionAlias?: string, isMarked?: boolean) => void;
  relatedTo: 'party' | 'user';
  isAllHeight: boolean;
  isMobile?: boolean;
  pageHeaderRef: MutableRefObject<HTMLDivElement>;
  user_id: number;
}

const SandBoxHotlist = ({
  project_id,
  openRequest,
  openDeliverable,
  openSubmittal,
  relatedTo,
  isAllHeight,
  isMobile,
  pageHeaderRef,
  user_id,
}: IProps) => {
  const dispatch = useAppDispatch();

  const sandBoxCountersLoading = useAppSelector(state => state.requestReducer.sandBoxCountersLoading);
  const sandBoxAllCountersLoading = useAppSelector(state => state.requestReducer.sandBoxAllCountersLoading);
  const sandBoxCounters = useAppSelector(state => state.requestReducer.sandBoxHotlist.counters);
  const sandBoxData = useAppSelector(state => state.requestReducer.sandBoxHotlist.accordion_list);
  const isFirstSandBoxAllCountersLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxAllCountersLoading);
  const isFirstSandBoxCountersLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxCountersLoading);
  const socketSandBoxHotlist = useAppSelector(state => state.requestReducer.socketSandBoxHotlist);

  const isFirstHotlistLoading = isFirstSandBoxCountersLoading || isFirstSandBoxAllCountersLoading;

  const [accordionStates, setAccordionStates] = useState<KeyValueModel<boolean> | null>(null);
  const [accordionRefs, setAccordionRefs] = useState<KeyValueModel<RefObject<HTMLElement>> | null>(null);
  const [isAllAccCollapsed, setIsAllAccCollapsed] = useState<boolean>(false);
  const [hotlistSection, setHotlistSection] = useState<SandBoxTabModel[]>(sandBoxHotlistSections);
  const [hotlistSubSection, setHotlistSubSection] = useState<SandBoxTabModel[]>();
  const [selectedSubSection, setSelectedSubSection] = useState<DropdownItemModelExt>({
    value: 'request_hotList_impactingDeliverables',
    title: 'Need Forms',
    count: 0,
  });
  const [selectedSection, setSelectedSection] = useState<DropdownItemModelExt>({ value: 'request_hotList', title: 'Need Forms', count: 0 });
  const [activeSectionType, setActiveSectionType] = useState<AllSectionType>('request');
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const themeClass = useGetThemeClass('b-sandBox-widget-hotlist');

  const getSandBoxWidgetsHotlistDataPromise = useRef(null);
  const getSandBoxCountersHotlistPromise = useRef(null);

  const loadAllCountersSectionData = () => {
    const queryData = {
      filters: {},
      or_filters: {},
      conditions: {},
      custom_filters: {},
      or_conditions: {},
      order_by: '',
      order_dir: '',
      deliverable_filters: {},
      project_id: project_id,
      search: '',
      related_to: relatedTo,
      filter_by: 'request_hotList',
    };

    dispatch(getSandBoxhotListCountersAll(queryData));
  };

  const loadSubSectionData = (SubSection: string, isLoadingWidget: boolean, isLoadCounter: boolean) => {
    const queryData = {
      filters: {},
      or_filters: {},
      conditions: {},
      custom_filters: {},
      or_conditions: {},
      order_by: '',
      order_dir: '',
      deliverable_filters: {},
      project_id: project_id,
      search: '',
      related_to: relatedTo,
      filter_by: SubSection,
    };

    if (isLoadingWidget) {
      getSandBoxWidgetsHotlistDataPromise.current?.abort();
      getSandBoxWidgetsHotlistDataPromise.current = dispatch(getSandBoxWidgetsHotlistData(queryData));
    }
    if (isLoadCounter) {
      queryData.entity = getSectionParendType(SubSection);
      getSandBoxCountersHotlistPromise.current?.abort();
      getSandBoxCountersHotlistPromise.current = dispatch(getSandBoxhotListCounters(queryData));
    }
  };

  useEffect(() => {
    if (project_id) {
      loadAllCountersSectionData();
    }
  }, [project_id, relatedTo, user_id]);

  const allSectionsApplyCount = sections => {
    return sections.map(item => {
      let all_section_count = 0;
      const newAllSections = item.sections.map(section => {
        const newSubSections =
          section.subSections?.map(subSection => {
            return {
              ...subSection,
              count: sandBoxCounters[subSection.id] ? sandBoxCounters[subSection.id] : null,
            };
          }) || null;
        all_section_count = all_section_count + (sandBoxCounters[section.id] ? sandBoxCounters[section.id] : 0);
        return {
          ...section,
          subSections: newSubSections ? newSubSections : section.subSections,
          count: sandBoxCounters[section.id] ? sandBoxCounters[section.id] : null,
        };
      });

      return {
        ...item,
        sections: newAllSections,
        count: all_section_count,
      };
    });
  };

  /*useEffect(() => {
    if (socketSandBoxHotlist.changedNow) {
      const SelectSection = getSectionParendType(selectedSubSection.value);
      const isChange = socketSandBoxHotlist.sectionType === SelectSection;
      if (isChange) {
        selectSubSection(selectedSubSection.value);
      }
      dispatch(resetSocketSandBoxHotlistState());
    }
  }, [socketSandBoxHotlist]);*/

  useEffect(() => {
    if (socketSandBoxHotlist.changedNow) {
      const SelectSection = getSectionParendType(selectedSubSection.value);
      const isChange = socketSandBoxHotlist.sectionType === SelectSection;
      if (isChange) {
        selectSubSection(selectedSubSection.value);
      }
      dispatch(resetSocketSandBoxHotlistState());
    }
  }, [socketSandBoxHotlist]);

  useEffect(() => {
    if (sandBoxCounters && sandBoxAllCountersLoading.loaded) {
      const newAllSections = allSectionsApplyCount(hotlistSection);
      setHotlistSection(newAllSections);
    }
  }, [sandBoxCounters, sandBoxAllCountersLoading]);

  useEffect(() => {
    if (sandBoxAllCountersLoading.loaded) {
      setIsFirstLoad(true);
    }
  }, [sandBoxAllCountersLoading]);

  useEffect(() => {
    if (sandBoxData && sandBoxData?.length) {
      const newAccordionRefs = sandBoxData.reduce((acc, accordion) => {
        const isAccordionExist = accordionRefs && Object.keys(accordionRefs).includes(accordion.alias);

        acc[accordion.alias] = isAccordionExist ? accordionRefs[accordion.alias] : React.createRef();

        return acc;
      }, {});

      const newAccordionStates = sandBoxData.reduce((acc, accordion) => {
        const isAccordionExist = accordionStates && Object.keys(accordionStates).includes(accordion.alias);

        acc[accordion.alias] = isAccordionExist ? accordionStates[accordion.alias] : true;

        return acc;
      }, {});

      const isAllAccordionClosed = Object.values(newAccordionStates).every(state => !state);

      setIsAllAccCollapsed(isAllAccordionClosed);
      setAccordionStates(newAccordionStates);
      setAccordionRefs(newAccordionRefs);
    }
  }, [sandBoxData]);

  const changeAccordionCollapse = () => {
    const newCollapseState = !isAllAccCollapsed;

    const newAccordionStates = Object.keys(accordionStates).reduce((acc, accordionName) => {
      acc[accordionName] = !newCollapseState;

      return acc;
    }, {});

    setAccordionStates(newAccordionStates);
    setIsAllAccCollapsed(newCollapseState);
  };

  const collapseButtonTitle = useMemo(() => {
    return isAllAccCollapsed ? 'Open all' : 'Collapse all';
  }, [isAllAccCollapsed]);

  useEffect(() => {
    if (hotlistSection && sandBoxAllCountersLoading.loaded) {
      const hotlist_Sections = hotlistSection.flatMap(item => item.sections);
      const SubSection = hotlist_Sections.find(section => section.id === selectedSection.value).subSections;
      const dataSection = SubSection ? SubSection.filter(item => item.count) : [];
      setHotlistSubSection(dataSection);

      const isChangeSelectedSubSection = !selectedSubSection || !dataSection.find(item => item.id === selectedSubSection.value);

      if (isChangeSelectedSubSection) {
        const fistSubSelection =
          dataSection.length > 0
            ? {
                value: dataSection[0].id,
                title: dataSection[0].title,
                count: dataSection[0].count,
              }
            : null;
        setSelectedSubSection(fistSubSelection);
        if (fistSubSelection) {
          loadSubSectionData(String(fistSubSelection.value), true, false);
        } else {
          loadSubSectionData(String(SubSection[0].id), true, false);
        }
      } else {
        loadSubSectionData(String(selectedSubSection.value), true, false);
      }
    }
  }, [hotlistSection]);

  useEffect(() => {
    if (hotlistSection && hotlistSection[0]?.count && isFirstLoad) {
      const indx = getIndexSectionTitle();
      const firstSection = hotlistSection[0].sections[indx];
      if (firstSection) {
        const select_obj = {
          value: firstSection.id,
          title: firstSection.title,
          count: firstSection.count,
        };
        setIsFirstLoad(false);
        handleSelectedSubSection(select_obj);
      }
    }
  }, [hotlistSection[0]?.count]);

  const handleAccordion = (alias: string) => {
    const newAccordionStates = {
      ...accordionStates,
      [alias]: !accordionStates[alias],
    };
    const isAllAccordionClosed = Object.values(newAccordionStates).every(state => !state);

    setIsAllAccCollapsed(isAllAccordionClosed);
    setAccordionStates(newAccordionStates);
  };

  const getSectionType = (sectionId: string) => {
    if (sectionId && sectionId.includes('request')) {
      return 'request_hotList';
    }

    if (sectionId && sectionId.includes('deliverable')) {
      return 'deliverable_hotList';
    }

    if (sectionId && sectionId.includes('submittalInput')) {
      return 'submittalInput_hotList';
    }

    if (sectionId && sectionId.includes('submittalOutput')) {
      return 'submittalOutput_hotList';
    }
  };

  const getSectionParendType = (sectionId: string) => {
    if (sectionId && sectionId.includes('request')) {
      return 'request';
    }

    if (sectionId && sectionId.includes('deliverable')) {
      return 'deliverable';
    }

    if (sectionId && sectionId.includes('submittalInput')) {
      return 'submittalInput';
    }

    if (sectionId && sectionId.includes('submittalOutput')) {
      return 'submittalOutput';
    }
  };

  const selectSubSection = (subSectionId: string) => {
    if (hotlistSubSection) {
      const SelectedSubSection = hotlistSubSection.find(item => item.id === subSectionId);
      const dataSection = {
        value: SelectedSubSection.id,
        title: SelectedSubSection.title,
        count: SelectedSubSection.count,
      };
      setSelectedSubSection(dataSection);
      loadSubSectionData(SelectedSubSection.id, false, true);
    }
  };

  const handleChangeTabBar = (event: React.SyntheticEvent, tabName: string) => {
    selectSubSection(tabName);
  };

  const handleSelectedSubSection = (item: DropdownItemModelExt) => {
    const hotlist_Sections = hotlistSection.flatMap(item => item.sections);
    const SubSection = hotlist_Sections.find(section => getSectionType(item.value).includes(section.id));
    const dataParendSection = {
      value: SubSection.id,
      title: SubSection.title,
    };
    setSelectedSubSection(null);
    setHotlistSubSection(SubSection.subSections);
    setSelectedSection(dataParendSection);
    setActiveSectionType(getSectionParendType(dataParendSection.value));
    setAccordionStates(null);
    setAccordionRefs(null);
    loadSubSectionData('', false, true);
  };

  const renderSingleSelectBasisItem = (item: DropdownItemModelExt) => {
    const SelectSection = hotlistSection[0].sections.filter(section => section.id === item.value)[0];
    const Count = SelectSection.count ? SelectSection.count : 0;
    return (
      <div className={`${themeClass}_selectSection_selectItem`}>
        <div>{item.title}</div>
        <div>{Count}</div>
      </div>
    );
  };
  const renderSingleSelectItem = (item: DropdownItemModelExt) => {
    const Count = item.count ? item.count : 0;
    return (
      <div className={`${themeClass}_selectSection_item`}>
        <div>{item.title}</div>
        <div>{Count}</div>
      </div>
    );
  };

  const getIndexSectionTitle = () => {
    if (hotlistSection[0].count === 0) {
      return 0;
    }
    const indx = hotlistSection[0].sections.findIndex(item => item.count > 0);
    if (indx > -1) {
      return hotlistSection[0].sections.findIndex(item => item.count > 0);
    } else {
      return 0;
    }
  };

  const sectionTitle = useMemo(() => {
    return hotlistSection[0].sections.map(item => {
      const obj = {
        value: item.id,
        title: item.title,
        count: item.count,
      };
      return obj;
    });
  }, [hotlistSection]);

  // if (isFirstHotlistLoading) {
  //   return (
  //     <CustomSkeleton
  //       width={'100%'}
  //       height={`calc(100svh - 48px - ${pageHeaderRef.current?.getBoundingClientRect()?.height}px - 20px - 24px - 344px)`}
  //     />
  //   );
  // }

  return (
    <div className={`${themeClass}_widgetContainer`}>
      <div className={`${themeClass}_titleSettings`}>
        <div className={`${themeClass}_titleSettings_left`}>
          <div className={`${themeClass}_titleSettings_left_iconTitle`}>
            <div className={`${themeClass}_titleSettings_left_iconTitle_icon`}>
              <TokenIcon iconName={'flame'} size={16} />
            </div>
            <div className={`${themeClass}_titleSettings_left_iconTitle_title`}>Hotlist</div>
            <div className={`${themeClass}_indicator`}>
              <TokenIcon iconName={'ellipse-220'} size={4} />
            </div>
          </div>
          <div className={`${themeClass}_selectSection`}>
            <Select<DropdownItemModelExt, null, false>
              isMulti={false}
              menuItems={sectionTitle}
              selectHandler={item => handleSelectedSubSection(item)}
              selected={selectedSection}
              renderBasisSelectedItem={renderSingleSelectBasisItem}
              menuItemSize={'md'}
              menuItemTitleRenderer={renderSingleSelectItem}
              customBasisFieldClass={`${themeClass}_selectedCustomMenuClass`}
            />
          </div>
        </div>
        {false && (
          <div className={`${themeClass}_titleSettings_right`}>
            <SystemButton tooltip={''} type={'information'} size={'md'} variant={'transparent'} />
          </div>
        )}
      </div>
      <div className={`${themeClass}__tabTabelContainer`}>
        {hotlistSubSection?.length > 0 ? (
          <div className={`b-sandBox-sandBoxHotlist__headerContainer ${themeClass}__headerContainer ${isMobile ? '-mobile' : ''}`}>
            {hotlistSubSection ? (
              <div className={'b-sandBox-sandBoxHotlist__headerContainer__maxTabs'}>
                <TabsBar
                  relatedTo={relatedTo}
                  sections={hotlistSubSection}
                  selectedTab={selectedSubSection?.value}
                  handleChange={handleChangeTabBar}
                  activeSectionType={activeSectionType}
                  isCountersLoading={!sandBoxCounters && sandBoxCountersLoading.loading}
                  isMobile={isMobile}
                />
              </div>
            ) : null}
            <div className={'b-sandBox-sandBoxHotlist__contentButtons'}>
              {isMobile ? (
                collapseButtonTitle === 'Collapse all' ? (
                  <SystemButton
                    type={'collapse-mobile'}
                    size={'md'}
                    variant={'transparent'}
                    isMobile
                    clickHandler={changeAccordionCollapse}
                  />
                ) : (
                  <SystemButton
                    type={'expand-mobile'}
                    size={'md'}
                    variant={'transparent'}
                    isMobile
                    clickHandler={changeAccordionCollapse}
                  />
                )
              ) : (
                <CustomButton
                  type={'text-plain'}
                  size={isMobile ? 'md' : 'xs'}
                  clickHandler={changeAccordionCollapse}
                  title={collapseButtonTitle}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ height: '40px' }}></div>
        )}
        <SandBoxHotlistTable
          sandBoxData={sandBoxData}
          openRequest={openRequest}
          openDeliverable={openDeliverable}
          openSubmittal={openSubmittal}
          accordionStates={accordionStates}
          accordionRefs={accordionRefs}
          handleAccordion={handleAccordion}
          sandBoxHotlistSectionType={getSectionParendType(String(selectedSection.value))}
          isMobile={isMobile}
          sectionTitle={selectedSection.title}
          isAllHeight={isAllHeight}
        />
      </div>
    </div>
  );
};

export default SandBoxHotlist;
