export const collectMentions = contents => {
  const mentions = [];

  contents.ops.forEach(o => {
    if (o.insert && o.insert.mention) {
      mentions.push({ id: Number(o.insert.mention.id), email: o.insert.mention.target });
    }
  });

  return mentions.filter((value, index, self) => self.findIndex(_value => _value.id === value.id) === index);
};

export const removeMentionByUserId = (userIds: number[], description: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = description;

  const mentionSpans = tempDiv.querySelectorAll('span.mention');

  mentionSpans.forEach(span => {
    const dataId = Number(span.getAttribute('data-id'));

    if (userIds.includes(dataId)) {
      span.parentNode.removeChild(span);
    }
  });

  return tempDiv.innerHTML;
};
