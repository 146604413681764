import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { IHistoryItemProps } from './HistoryItem.props';
import cn from 'classnames';
import { useGetThemeClass } from '../../../helpers/designTokens';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import { useAppSelector } from '../../../store/configure/configureStore';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import copy from 'clipboard-copy';
import { useClickOutside } from '../../../helpers/commonHooks';
import { convertToLocalTimezone } from '../../../helpers/commonHelpers';
import StatusNF from '../../controls/Status/StatusNF/StatusNF';
import ChipsHistory from '../../controls/Chips/ChipsHistory/ChipsHistory';

import './historyStyles.scss';

const HistoryItem: React.FC<IHistoryItemProps> = ({ historyItem, iconsTextsColor, offset, type }) => {
  const projectId = +useAppSelector(state => state.userReducer.active_project_id);

  const { isMobile } = GetResolution();

  const generalThemeClass = useGetThemeClass(`b-history`);
  const themeClassSVGHistory = useGetThemeClass('actionSVGHistory');

  const [coordinates, setCoordinates] = useState<any>(null);
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const tooltipRef = useRef();

  const findIndex = iconsTextsColor.findIndex(value => value.alias == historyItem.alias);
  const iconTextColor = findIndex > -1 ? iconsTextsColor[findIndex] : null;
  const is_action_show = historyItem.action.includes('<a href=');
  const action_text = is_action_show ? historyItem.action.slice(0, historyItem.action.indexOf('<a href=')) : historyItem.action;
  const tooltip_text = is_action_show
    ? historyItem.action
        .slice(historyItem.action.indexOf('data-text=') + 11, historyItem.action.indexOf('Show') - 2)
        .replaceAll('.', '.&nbsp;')
    : '';
  const show_text = historyItem.alias == 'requestEdited_requestDescription' ? 'Show change' : 'Show';

  const authorProfile = useMemo(() => {
    return historyItem?.user?.profile || null;
  }, [historyItem?.user?.profile]);

  const viewProfile = () => {
    setIsViewProfile(true);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const handleCloseUserPopup = () => {
    setCoordinates(null);
  };

  const handleCloseUserPopupFixed = () => {
    setSelectedUser(null);
    setCoordinates(null);
  };

  const handleOpenUserPopup = (e, user) => {
    setCoordinates(e.currentTarget);
    setSelectedUser(user);
  };
  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const isWhiteIconsHandler = (alias: string): boolean => {
    if (alias.includes('requestEdited_')) {
      return true;
    } else {
      switch (alias) {
        case 'requestAddedToWatchList':
          return true;
        case 'requestRemovedFromWatchList':
          return true;
        case 'requestAddedToWatchListGroup':
          return true;
        case 'newRequestComment':
          return true;
        case 'requestView':
          return true;
        case 'requestDeactivated':
          return true;
        case 'requestActivated':
          return true;
        case 'requestSetPrivate':
          return true;
        case 'requestSetPublic':
          return true;
        case 'newDeliverable_file':
          return true;
        case 'nfLinkedPredecessor':
          return true;
        case 'nfLinkedSuccessor':
          return true;
        case 'nfUnlinkedPredecessor':
          return true;
        case 'nfUnlinkedSuccessor':
          return true;
        case 'pullRequestCreated':
          return true;
        case 'linkedPullRequest':
          return true;
        case 'requestDeactivatedSecondaryCourt':
          return true;
        case 'requestReplyCourt':
          return true;
        case 'requestUnassignedCourts':
          return true;
        case 'requestDeleted':
          return true;
        default:
          return false;
      }
    }
  };

  const getIcon = (component: 'Status' | 'ChipsHistory', icon_type: string, icon_color: string) => {
    if (component === 'Status') {
      return (
        <div className={`${generalThemeClass}__statusIcon`}>
          <StatusNF status={icon_type} isHiddenText />
        </div>
      );
    }
    if (component === 'ChipsHistory') {
      return <ChipsHistory type={icon_type} color={icon_color} isHiddenText />;
    }
    return;
  };

  useClickOutside(tooltipRef, handleCloseTooltip);

  return (
    <>
      <div key={historyItem.id} className={`${generalThemeClass} ${isMobile ? '-mobile' : ''} ${generalThemeClass}__comment`}>
        <div className={`${generalThemeClass}__header`}>
          <div className={`${generalThemeClass}__dateContainer`}>
            <span className={`${generalThemeClass}__text`}>{` ${convertToLocalTimezone(moment(historyItem.created_at))
              .add('minutes')
              .format('ll')}`}</span>
            <span className={`${generalThemeClass}__text`}>{` ${convertToLocalTimezone(moment(historyItem.created_at)).format(
              'hh:mm A',
            )}`}</span>
          </div>
          <div className={`${generalThemeClass}__userProfile`} onMouseEnter={e => handleOpenUserPopup(e, historyItem?.user)}>
            <PlaceholderInfo
              type={'Persona'}
              firstName={authorProfile?.first_name}
              lastName={authorProfile?.last_name}
              detailInfo={`${authorProfile?.first_name} ${authorProfile?.last_name}`}
              imagePath={authorProfile?.image}
              isMobile={isMobile}
              size={24}
            />
          </div>
          <div className={cn(`${generalThemeClass}__description`, { ['-emptyIcon']: !iconTextColor })}>
            {iconTextColor ? (
              <div className={'actionTextHistory'}>
                {iconTextColor?.component ? getIcon(iconTextColor.component, iconTextColor.icon_type, iconTextColor?.icon_color) : null}
                <span className={`${generalThemeClass}__text`}>{`${action_text}`}&nbsp;</span>
              </div>
            ) : (
              //if not have icon
              <span className={`${generalThemeClass}__text`}>{` ${action_text}`}&nbsp;</span>
            )}
            {is_action_show && (
              <CustomTooltip
                isInteractive
                open={isTooltipOpen}
                componentsProps={{ popper: { ref: tooltipRef } }}
                title={
                  <div className={`${generalThemeClass}__tooltip`}>
                    {ReactHtmlParser(tooltip_text)}
                    <TokenIcon
                      customClass={`${generalThemeClass}__historyBlock_icon`}
                      iconName={'copy'}
                      size={12}
                      clickHandler={() => copy(tooltip_text.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' '))}
                    />
                  </div>
                }
                customTooltipClass={`historyBlock`}
                onClick={handleOpenTooltip}
              >
                <span className={`${generalThemeClass}__text -decor`}>{show_text}</span>
              </CustomTooltip>
            )}
          </div>
        </div>
      </div>
      <div className={`${generalThemeClass}__divider`}>
        <span>.</span>
      </div>
      {!!selectedUser && !!coordinates && (
        <PopupProfile
          anchorEl={coordinates}
          user={selectedUser}
          viewProfile={viewProfile}
          closeTeamUser={handleCloseUserPopup}
          closeFixedTooltip={handleCloseUserPopupFixed}
        />
      )}
      {!!selectedUser && isViewProfile && (
        <ProfilePopover open={isViewProfile} onClose={viewProfileClose} type={'view'} user_id={selectedUser.id} projectId={projectId} />
      )}
    </>
  );
};

export default HistoryItem;
