import React from 'react';
import Highlighter from 'react-highlight-words';

export const useColoredText = (searchByInit?: string) => {
  const renderText = (text: string, search?: string) => {
    return (
      <Highlighter
        highlightClassName="b-selectFilter-colored"
        searchWords={[searchByInit || search]}
        autoEscape={true}
        textToHighlight={text}
      />
    );
  };

  return {
    renderText,
  };
};
