import { BaseApi } from './baseApi';

class ReportingApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getReportingHeader(bodyParams, projectId) {
    if (!projectId) {
      return false;
    }
    const result = await this._post({
      route: `report/${projectId}/get-report-header`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getReportingTable(bodyParams, projectId) {
    if (!projectId) {
      return false;
    }
    const result = await this._post({
      route: `report/${projectId}/get-report-table`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getReportingForCompany(bodyParams, projectId, partyId) {
    if (!projectId || !partyId) {
      return false;
    }
    const result = await this._post({
      route: `/report/${projectId}/${partyId}/get-report-company`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getReportingForUser(bodyParams, projectId, user_id) {
    if (!projectId || !user_id) {
      return false;
    }
    const result = await this._post({
      route: `/report/${projectId}/${user_id}/get-report-user`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
}

export default new ReportingApi({ devMode: false });
