import React from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';

import './FooterStyles.scss';

interface FooterProps {
  renderFooterContent?: (footerThemeClass: string) => React.ReactNode;
  isSidePeekView: boolean;
}

const Footer: React.FC<FooterProps> = ({ renderFooterContent, isSidePeekView }) => {
  const themeClass = useGetThemeClass('b-cardRequest-footer');

  return (
    <div className={cn(themeClass, { ['-sidePeek']: isSidePeekView })}>
      {renderFooterContent ? <div className={`${themeClass}_content`}>{renderFooterContent(`${themeClass}_footerContent`)}</div> : null}
    </div>
  );
};

export default Footer;
