import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';
import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { TabItemModel } from '../../../../../models/global';
import { SubmittalCardTabNamesModel, submittalTabs } from '../../constants/submittalTabs';
import { IFeedData } from '../../../../../store/comments/commentReducer.model';
import Divider from '../../../../controls/Divider/Divider';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import { CustomButton } from '../../../../controls/ButtonComponents';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';
import DateRow from '../../../../controls/DateRow/DateRow';
import { useAppDispatch } from '../../../../../store/configure/configureStore';
import { RelatedTabDataModel } from '../../../../../store/relatedItems/relatedItemsSlice.model';

import './TabsContainerStyles.scss';

interface IProps {
  isDragRequestCard: boolean;
  feedData: IFeedData;
  relatedTabData: RelatedTabDataModel;
  openedRequest: ISubmittal;
  requestLoading: boolean;
  activeTab: SubmittalCardTabNamesModel;
  onChangeActiveTab: (activeTab: SubmittalCardTabNamesModel) => void;
  isDisable: boolean;
  isAccess: boolean;
  isDeactivated: boolean;
  handleCloseAndDistribute: () => void;
  isPreviousRevisionDeactivated: boolean;
  isAnyReturnedReviews: boolean;
}

const TabsContainer: FC<IProps> = ({
  isDragRequestCard,
  feedData,
  relatedTabData,
  openedRequest,
  activeTab,
  onChangeActiveTab,
  isDisable,
  requestLoading,
  isDeactivated,
  handleCloseAndDistribute,
  isPreviousRevisionDeactivated,
  isAnyReturnedReviews,
}) => {
  const themeClass = useGetThemeClass('b-cardRequest-tabsContainer');
  const { is1440Resolution, isMobile } = GetResolution();

  const [tabs, setTabs] = useState<TabItemModel[]>([...submittalTabs] || []);

  useEffect(() => {
    if (openedRequest?.id || isDisable) {
      renderTabs();
    }
  }, [openedRequest, feedData, relatedTabData]);

  const renderCloseAndDistribute = () => {
    if (isDragRequestCard || openedRequest?.status.alias !== 'open') return null;
    if (
      ((openedRequest?.review_state === 'approved' && isAnyReturnedReviews) || openedRequest?.review_state !== 'approved') &&
      activeTab !== 'review'
    )
      return null;

    return (
      <CustomButton
        type={openedRequest?.review_state === 'approved' && !isAnyReturnedReviews ? 'primary' : 'tertiary'}
        size={'md'}
        title={'Close & Distribute'}
        clickHandler={handleCloseAndDistribute}
        loading={requestLoading}
        disabled={requestLoading || isDeactivated || isPreviousRevisionDeactivated}
      />
    );
  };

  const renderTabs = () => {
    const newTabs = tabs.map(tab => {
      if (tab.id === 'general') {
        return { ...tab, disabled: checkDisable(tab.id as SubmittalCardTabNamesModel) };
      }
      if (tab.id === 'review') {
        return { ...tab, disabled: checkDisable(tab.id as SubmittalCardTabNamesModel) };
      }
      if (tab.id === 'related-items') {
        let counter = 0;
        for (const key in relatedTabData) {
          if (key !== 'document' && key !== 'card') {
            counter += relatedTabData[key].length;
          }
        }

        return { ...tab, count: counter, disabled: checkDisable(tab.id as SubmittalCardTabNamesModel) };
      }
      if (tab.id === 'discussion') {
        return { ...tab, count: feedData?.length || 0, disabled: checkDisable(tab.id as SubmittalCardTabNamesModel) };
      }
      return tab;
    });
    setTabs(newTabs);
  };

  const handleChangeActiveTab = (event: React.SyntheticEvent, tab: string) => {
    const isDisable = checkDisable(tab as SubmittalCardTabNamesModel);

    if (isDisable) {
      return;
    }
    !isDisable && onChangeActiveTab(tab as SubmittalCardTabNamesModel);
  };

  const checkDisable = (type: SubmittalCardTabNamesModel) => {
    return isDisable && type !== 'general';
  };

  const isSidePeekView = isDragRequestCard || is1440Resolution;

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-sidePeekView']: isSidePeekView,
      })}
    >
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_tabsGroup`}>
          <TabsBar
            sections={tabs}
            selectedTab={activeTab}
            handleChange={handleChangeActiveTab}
            activeSectionType={'request'}
            isCountersLoading={false}
            isNfCard
            customClass={``}
            isMobile={isMobile}
          />
        </div>
        {renderCloseAndDistribute()}
        {activeTab === 'review' && openedRequest?.status.alias === 'closed' && !isDragRequestCard && (
          <div className={`${themeClass}_info`}>
            <span className={`${themeClass}_info_text`}>Distributed by</span>
            <PlaceholderInfo
              size={24}
              type={'Persona'}
              isMobile={isMobile}
              imagePath={openedRequest?.distributed_by?.profile?.image}
              detailInfo={`${openedRequest?.distributed_by?.profile?.first_name} ${openedRequest?.distributed_by?.profile?.last_name}`}
              firstName={openedRequest?.distributed_by?.profile?.first_name}
              lastName={openedRequest?.distributed_by?.profile?.last_name}
            />
            <DateRow date={openedRequest?.distributed_date} isMobile={isMobile} showTime />
          </div>
        )}
      </div>
      <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-4'} />
    </div>
  );
};

export default TabsContainer;
