import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Popover } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { TextInputNew } from '../../controls';
import './SelectFilterStyles.scss';
import {
  ColoredModel,
  NfCompanySearchInterface,
  NfFilterAdditionalCompanyModel,
  NfFilterCompanyModel,
  NfFilterDisciplineModel,
  NfFilterManagerModel,
  NfFilterSearchFlagType,
  NfType,
  OptionModel,
  RequestModel,
  SelectedRequestCompanyModel,
} from '../../../models';

import { useColoredText } from '../../../hooks/useColoredText';
import ButtonView from '../ButtonComponents/ButtonView/ButtonView';
import SandBoxDescription from '../../common/SandBoxDescriptionField/SandBoxDescription';
import DialogActions from '@material-ui/core/DialogActions';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { getResponsePartySelect } from '../../../helpers/RequestHelper';
import Timeout = NodeJS.Timeout;
import {
  createNewResponseCompany,
  getSearchRequestFilters,
  searchRequest,
  updateNewResponseCompany,
} from '../../../store/request/requestLogic(HOLD)';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontFamily: 'var(--font-general)',
    maxWidth: 520,
    color: 'var(--background-tooltip-text)',
    fontSize: '14px',
    padding: '5px 9px',
    backgroundColor: 'var(--background-tooltip)',
    fontWeigh: 'var(--font-weight)',
    position: 'relative',
    right: '5px',
    top: '75px',
    borderRadius: '2px',
    overflow: 'visible',
  },
  popper: {
    opacity: 1,
  },
}))(Tooltip);

interface IProps {
  type: NfType;
  onSelectCompany: (selected: SelectedRequestCompanyModel, isReset?: boolean) => void;
  selectedProps: SelectedRequestCompanyModel | null;
  error?: boolean;
  selectedRequest?: RequestModel;
  isAccess?: boolean;
  isAccessToCommitment?: boolean;
  isPullRequest?: boolean;
  isHideRequestTypeName?: boolean;
  isNfCard?: boolean;
  isChangeResponsePartyActionActive?: boolean;
  setCommitmentAction?: (action: string) => void;
  setIsChangedNow?: (isChanged: boolean) => void;
}

let timer: Timeout;
export const SelectFilter = ({
  type,
  onSelectCompany,
  selectedProps,
  error,
  selectedRequest,
  isAccess,
  isAccessToCommitment,
  isPullRequest,
  isHideRequestTypeName,
  isNfCard,
  isChangeResponsePartyActionActive,
  setCommitmentAction,
  setIsChangedNow,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState<string>('');
  const [openedPopup, setOpenedPopup] = useState<NfFilterSearchFlagType | null>(null);
  const dispatch = useDispatch();
  const requestFilterFields: OptionModel[] | null = useSelector((state: any) => state.requestReducer.requestFilterFields);
  const activeProjectId: string = useSelector((state: any) => state.userReducer.active_project_id);
  const [groupFilters, setGroupFilters] = useState<NfCompanySearchInterface>(null);
  const { renderText } = useColoredText(search);
  const [confirmChangeCompany, setConfirmChangeCompany] = useState<SelectedRequestCompanyModel | null>(null);
  const [changeCompanyReceived, setChangeCompanyReceived] = useState<boolean | null>(null);
  const currentRef = useRef();
  const userInfo = useSelector((state: any) => state.userReducer.userInfo);
  const [isDeclinePopover, setIsDeclinePopover] = useState<any | null>(null);
  const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '1' || key === '4' || key === '5').length;
  const isCompanyUserOrManager = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '2' || key === '3').length;
  const isSentRequestToChange = !!selectedRequest?.response_company_change_request && type === 'nf-response';
  const [declineMsg, setDeclineMsg] = useState<string>('');
  const [isDisableField, setIsDisableField] = useState<boolean | null>(null);
  const [selected, setSelected] = useState(selectedProps);

  useEffect(() => {
    if (isChangeResponsePartyActionActive) {
      setOpenedPopup('comp');
      setAnchorEl(currentRef.current);
    }
  }, [isChangeResponsePartyActionActive]);

  useEffect(() => {
    if (!requestFilterFields) {
      dispatch(getSearchRequestFilters({}));
    }
  }, [requestFilterFields]);

  useEffect(() => {
    if (selectedRequest?.parent_id) {
      setIsDisableField(true);
      return;
    }
    const checkIsDisableField = () => {
      if (type === 'nf-response' && isAccessToCommitment && selectedRequest?.lastUserStatus?.status !== 'provided') {
        return false;
      }

      if (!!isAccess === false) {
        return true;
      }
      if (type === 'nf-request') {
        return (!isAdmin && userInfo.user_parties?.length <= 1) || selectedRequest?.lastUserStatus?.status === 'accepted';
      }

      if (type === 'nf-response') {
        // debugger;
        if (!selectedRequest?.lastUserStatus) {
          return false;
        }
        if (selectedRequest.lastUserStatus.status === 'provided' || selectedRequest.lastUserStatus.status === 'accepted') {
          return true;
        }
      }
    };
    setIsDisableField(checkIsDisableField());
  }, [selectedRequest, isAccess]);

  useEffect(() => {
    setSelected(selectedProps);
  }, [selectedProps]);

  const onSearchRequest = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      let requestType = '';
      const isUserComp = openedPopup === 'user_comp';
      if (type === 'nf-request') {
        requestType = 'request';
      } else if (type === 'nf-response') {
        requestType = 'response';
      } else if (type === 'new-response') {
        requestType = 'response';
      }
      const body: any = {
        project_id: Number(activeProjectId),
        search_flags: isUserComp ? ['user'] : [openedPopup],
        text: search || undefined,
        type: requestType,
      };
      if (isUserComp) {
        setOpenedPopup(null);
        setGroupFilters(null);
      }

      if ((openedPopup === 'comp' || openedPopup === 'user' || isUserComp) && selected?.discipline?.id) {
        body.discipline_id = selected.discipline.id;
      }

      if ((openedPopup === 'disc' || openedPopup === 'user' || isUserComp) && selected?.company?.id) {
        body.party_ids = [selected.company.id];
      }
      dispatch(
        searchRequest({
          ...body,
          callback: (res, err) => {
            if (res) {
              if ((openedPopup === 'user' || isUserComp) && res.managers.length) {
                const selectedUserIndex = res.managers.findIndex(m => m.id === selected?.user?.id);
                if (selectedUserIndex >= 0) {
                  const result = { ...res };
                  const selectedUser = result.managers.splice(selectedUserIndex, 1);
                  result.managers.unshift(...selectedUser);
                  setGroupFilters(result);
                  return;
                }
              }
              setGroupFilters(res);
            }
          },
        }),
      );
    }, 400);
  };

  useEffect(() => {
    if (!openedPopup) {
      return;
    }
    onSearchRequest();
  }, [search, openedPopup]);

  const handleChangeAssign = (selected: SelectedRequestCompanyModel, isClearAll?: boolean) => {
    setSearch('');
    setSelected(selected);
  };

  useEffect(() => {
    if (
      !openedPopup &&
      type === 'nf-response' &&
      ((!isAccess && isAccessToCommitment) || isAdmin) &&
      !!selectedRequest &&
      selectedRequest.lastUserStatus.status !== 'drafted' &&
      selectedProps.company?.id !== selected.company?.id
    ) {
      setConfirmChangeCompany(selected);
      return;
    }
    if (!openedPopup && selected !== selectedProps) {
      onSelectCompany(selected);
    }
  }, [openedPopup]);

  const changeCompany = (selected: SelectedRequestCompanyModel) => {
    onSelectCompany(selected);
    setConfirmChangeCompany(null);
    setSearch(null);
    // if (!selected?.company) {
    //   setOpenedPopup('comp');
    // } else if (!selected.discipline) {
    //   setOpenedPopup('disc');
    // } else if (!selected.user) {
    //   setOpenedPopup('user');
    // } else {
    //   setOpenedPopup(null);
    // }
  };

  const handleOpenSelect = (type: NfFilterSearchFlagType, isDisable?) => {
    if (isDisable || isSentRequestToChange) {
      return;
    }

    setOpenedPopup(type);
    setAnchorEl(currentRef.current);
    // setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    if (!selected?.user) {
      const findContactPointUser = groupFilters?.disciplines
        ?.filter(f => f.available)?.[0]
        ?.parties?.find(p => p.id === selected?.company?.id)?.contact_point;
      if (findContactPointUser) {
        const newSelected = {
          ...selected,
          user: {
            id: findContactPointUser.id,
            title: findContactPointUser.fullname,
          },
        };
        handleChangeAssign(newSelected);
      }
    }
    setAnchorEl(null);
    setOpenedPopup(null);
    setIsDeclinePopover(null);
    setDeclineMsg('');
    setChangeCompanyReceived(null);
    setSearch('');
    if (isChangeResponsePartyActionActive) {
      setCommitmentAction('');
    }
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const renderCompany = () => {
    const selectCompany = (company: NfFilterCompanyModel) => (e: any) => {
      e.stopPropagation();
      const newSelected = {
        discipline: selected?.discipline || null,
        company: {
          id: company.id,
          title: company.company,
        },
        user: company.contact_point
          ? {
              id: company?.contact_point.id,
              title: company?.contact_point?.fullname,
            }
          : null,
      };

      if (!company.available) {
        newSelected.discipline = null;
        newSelected.user = null;
      }

      if (company.disciplines?.length === 1 && !newSelected.discipline) {
        newSelected.discipline = {
          id: company.disciplines[0].id,
          title: company.disciplines[0].title,
          color: company.disciplines[0].color,
        };
        newSelected.user = company.contact_point
          ? {
              id: company.contact_point?.id,
              title: company.contact_point?.fullname,
            }
          : null;
        handleChangeAssign(newSelected);
        setOpenedPopup('user');
        return;
      }
      if (!company.disciplines?.length && !newSelected.user) {
        newSelected.user = company.contact_point
          ? {
              id: company.contact_point?.id,
              title: company.contact_point?.fullname,
            }
          : null;
      }

      if (!newSelected?.discipline && company.disciplines.length > 1) {
        handleChangeAssign(newSelected);
        setOpenedPopup('disc');
        return;
      }
      handleChangeAssign(newSelected);
      if (!selectedRequest || selectedRequest.lastUserStatus.status === 'drafted' || type === 'nf-response' || type === 'nf-request') {
        if (newSelected?.discipline || !company.disciplines.length) {
          setOpenedPopup('user');
        } else {
          setOpenedPopup('disc');
        }
      }
    };

    const selectCompanyDiscipline = (company: NfFilterCompanyModel, discipline: ColoredModel) => (e: any) => {
      e.stopPropagation();
      const selected = {
        discipline: {
          id: discipline.id,
          title: discipline.title,
          color: discipline.color,
        },
        company: {
          id: company.id,
          title: company.company,
        },
        user: company.contact_point
          ? {
              id: company.contact_point?.id,
              title: company.contact_point?.fullname,
            }
          : null,
      };

      handleChangeAssign(selected);

      setOpenedPopup('user');
    };
    const userSelect = (company: NfFilterCompanyModel, user: NfFilterManagerModel) => e => {
      e.stopPropagation();
      const newSelected = {
        discipline: null,
        company: {
          id: company.id,
          title: company.company,
        },
        user: {
          id: user.id,
          title: `${user.profile.first_name} ${user.profile.last_name}`,
        },
      };

      if (!company.disciplines.length || company.disciplines.length === 1) {
        newSelected.discipline = company.disciplines.length
          ? {
              id: company.disciplines[0].id,
              title: company.disciplines[0].title,
            }
          : null;
        handleChangeAssign(newSelected);
        setOpenedPopup(null);
        return;
      }

      handleChangeAssign(newSelected);
      setOpenedPopup('disc');
    };
    const userDisciplineSelect = (company: NfFilterCompanyModel, user: NfFilterManagerModel, discipline: ColoredModel) => e => {
      e.stopPropagation();
      const selected = {
        discipline: {
          id: discipline.id,
          title: discipline.title,
          color: discipline.color,
        },
        company: {
          id: company.id,
          title: company.company,
        },
        user: {
          id: user.id,
          title: `${user.profile.first_name} ${user.profile.last_name}`,
        },
      };
      handleChangeAssign(selected);
    };

    if (!groupFilters?.parties?.length) {
      return null;
    }

    const dividerIndex = groupFilters.parties.findIndex(d => !d.available);

    if (search) {
      return groupFilters.parties.map((company, index) => {
        const isSelected = company.id === selected?.company?.id;
        return (
          <div key={`company-${company.id}`}>
            {dividerIndex === index ? <div className={'divider'} /> : null}
            <div className={`b-selectFilter-group-item ${isSelected ? '-selected' : ''}`} onClick={selectCompany(company)}>
              <div className={'b-selectFilter-group-title'}>{renderText(company.company)}</div>
              {company.disciplines &&
                company.disciplines.map((discipline, i) => {
                  return (
                    <span
                      key={`company-${company.id}-${discipline.id}`}
                      className={'b-selectFilter-group-text -selectable'}
                      onClick={selectCompanyDiscipline(company, discipline)}
                    >
                      {`${discipline.title}${company.disciplines.length > i + 1 ? ', ' : ''}`}
                    </span>
                  );
                })}
              {company.disciplines.length && company.work_package.length ? <span className={'separator'}>-</span> : null}
              {company.work_package &&
                company.work_package.map((wp, i) => {
                  return (
                    <span key={`company-${company.id}-wp-${wp.id}`} className={'b-selectFilter-group-text'}>
                      {`${wp.title}${company.work_package.length > i + 1 ? ', ' : ''}`}
                    </span>
                  );
                })}
            </div>

            {company.users &&
              company.users.map(user => {
                return (
                  <div
                    key={`company-${company.id}-user-${user.id}`}
                    className={'b-selectFilter-group-item -additional'}
                    onClick={userSelect(company, user)}
                  >
                    <div className={'avatar'}>
                      {user.profile.image ? <img className={'b-header__userAva__img'} src={user.profile.image} /> : null}
                    </div>
                    <div className={'info-user'}>
                      <div className={'info-title'}>
                        <span className={'name'}>{`${user.profile.first_name} ${user.profile.last_name}`}</span>
                        {user.id === company.contact_point?.id ? <span className={'contact-point'}>Contact point</span> : null}
                      </div>
                      <div className={'info-text'}>
                        {user.disciplines?.length
                          ? user.disciplines.map((discipline, i) => {
                              return (
                                <span
                                  key={`company-${company.id}-user-${user.id}-discipline-${discipline.id}`}
                                  className={'b-selectFilter-group-text -selectable'}
                                  onClick={userDisciplineSelect(company, user, discipline)}
                                >
                                  {discipline.title}
                                  {user.disciplines.length > i + 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : null}
                        {user.work_package?.length ? <span className={'separator'}>-</span> : null}
                        {user.work_package?.length
                          ? user.work_package.map((wp, i) => {
                              return (
                                <span key={`company-${company.id}-user-${user.id}-wp-${wp.id}`} className={'text'}>
                                  {wp.title}
                                  {user.work_package.length > i + 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      });
    }
    return groupFilters.parties.map((company, index) => {
      const isSelected = company.id === selected?.company?.id;
      return (
        <div key={`company-${company.id}`}>
          {dividerIndex === index ? <div className={'divider'} /> : null}
          <div
            className={`b-selectFilter-group-item ${isSelected ? '-selected' : ''} ${isNfCard ? 'nfCardHover' : ''}`}
            onClick={selectCompany(company)}
          >
            <div className={'b-selectFilter-group-title'}>{renderText(company.company)}</div>
            {company.disciplines &&
              company.disciplines.map((discipline, i) => {
                return (
                  <span
                    key={`company-${company.id}-${discipline.id}`}
                    className={'b-selectFilter-group-text -selectable'}
                    onClick={selectCompanyDiscipline(company, discipline)}
                  >
                    {renderText(`${discipline.title}${company.disciplines.length > i + 1 ? ', ' : ''}`)}
                  </span>
                );
              })}
            {company.disciplines.length && company.roles.length ? <span className={'separator'}>-</span> : null}
            {company.roles &&
              company.roles.map((role, i) => {
                return (
                  <span key={`company-${company.id}-wp-${role.id}`} className={'b-selectFilter-group-text'}>
                    {renderText(`${role.title}${company.roles.length > i + 1 ? ', ' : ''}`)}
                  </span>
                );
              })}
            {company.work_package.length ? <span className={'separator'}>-</span> : null}
            {company.work_package &&
              company.work_package.map((wp, i) => {
                return (
                  <span key={`company-${company.id}-wp-${wp.id}`} className={'b-selectFilter-group-text'}>
                    {renderText(`${wp.title}${company.work_package.length > i + 1 ? ', ' : ''}`)}
                  </span>
                );
              })}
          </div>
        </div>
      );
    });
  };

  const renderDiscipline = () => {
    const mainSelect = (discipline: NfFilterDisciplineModel) => e => {
      e.stopPropagation();
      const newSelected = {
        discipline: {
          id: discipline.id,
          title: discipline.title,
          color: discipline.color,
        },
        company: selected?.company || null,
        user: selected?.user || null,
      };

      if (!discipline.available) {
        newSelected.company = null;
        newSelected.user = null;
      }
      // debugger;

      if (!newSelected?.company && discipline.parties.length === 1) {
        newSelected.company = {
          id: discipline.parties[0].id,
          title: discipline.parties[0].company,
        };
        newSelected.user = discipline.parties[0].contact_point
          ? {
              id: discipline.parties[0].contact_point.id,
              title: discipline.parties[0].contact_point.fullname,
            }
          : null;
        setOpenedPopup('user');
        handleChangeAssign(newSelected);
        return;
      }
      if ((!newSelected?.company && discipline.parties?.length > 1) || !discipline.available) {
        handleChangeAssign(newSelected);
        setOpenedPopup('comp');
        return;
      }
      handleChangeAssign(newSelected);
      if (newSelected.company && !selected.user) {
        const findSelectedCompany = discipline.parties.find(p => p.id === newSelected.company.id);

        newSelected.user = findSelectedCompany?.contact_point
          ? {
              id: findSelectedCompany.contact_point?.id,
              title: findSelectedCompany.contact_point?.fullname,
            }
          : null;
        setOpenedPopup('user');
      } else if (!newSelected.company) {
        setOpenedPopup('comp');
      } else {
        setOpenedPopup(null);
      }
    };

    const subSelect = (discipline: NfFilterDisciplineModel, company: NfFilterAdditionalCompanyModel) => e => {
      e.stopPropagation();
      const newSelected = {
        discipline: {
          id: discipline.id,
          title: discipline.title,
          color: discipline.color,
        },
        company: {
          id: company.id,
          title: company.company,
        },
        user: selected?.user || null,
      };
      if (!discipline.available) {
        newSelected.user = company.contact_point
          ? {
              id: company.contact_point?.id,
              title: company.contact_point?.fullname,
            }
          : null;
      }
      handleChangeAssign(newSelected);
      setOpenedPopup('user');
    };

    if (!groupFilters?.disciplines?.length) {
      return null;
    }

    const dividerIndex = groupFilters.disciplines.findIndex(d => !d.available);

    if (search) {
      return groupFilters.disciplines.map((discipline, index) => {
        const isSelected = discipline.id === selected?.discipline?.id;
        return (
          <div key={`discipline-${discipline.id}`}>
            {dividerIndex === index ? <div className={'divider'} /> : null}
            <div className={`b-selectFilter-group-item -discipline-count ${isSelected ? '-selected' : ''}`}>
              <div className={'discipline-color'} style={{ backgroundColor: discipline.color }} />
              <div className={'b-selectFilter-group-title'} onClick={mainSelect(discipline)}>
                {renderText(`${discipline.title} (${discipline.parties.length || 0})`)}
              </div>
            </div>

            {discipline.parties &&
              discipline.parties.map(company => {
                return (
                  <div
                    key={`discipline-${discipline.id}-user-${company.id}`}
                    onClick={subSelect(discipline, company)}
                    className={'b-selectFilter-group-item -additional'}
                  >
                    {/*<div className={'avatar'}>*/}
                    {/*  {user.profile.image ?*/}
                    {/*    <img className={'b-header__userAva__img'} src={user.profile.image} /> : null}*/}
                    {/*</div>*/}
                    <div className={'info-user'}>
                      <div className={'info-title'}>
                        <span className={'name'}>{company.company}</span>
                      </div>
                      <div className={'info-text'}>
                        {company.roles
                          ? company.roles.map((role, i) => {
                              return (
                                <span
                                  key={`discipline-${discipline.id}-company-${company.id}-role-${role.id}`}
                                  className={'b-selectFilter-group-text'}
                                >
                                  {role.title}
                                  {company.roles.length > i + 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : null}
                        {company.work_package?.length ? <span className={'separator'}>-</span> : null}
                        {company.work_package?.length
                          ? company.work_package.map((wp, i) => {
                              return (
                                <span key={`discipline-${discipline.id}-company-${company.id}-wp-${wp.id}`} className={'text'}>
                                  {wp.title}
                                  {company.work_package.length > i + 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      });
    }

    return groupFilters.disciplines.map((discipline, index) => {
      const isSelected = discipline.id === selected?.discipline?.id;
      return (
        <div key={`discipline-${discipline.id}`}>
          {dividerIndex === index ? <div className={'divider'} /> : null}
          <div
            className={`b-selectFilter-group-item ${isSelected ? '-selected' : ''} ${isNfCard ? 'nfCardHover' : ''}`}
            onClick={mainSelect(discipline)}
          >
            <div className={'discipline-color'} style={{ backgroundColor: discipline.color }} />
            <div className={'b-selectFilter-group-title'}>{renderText(discipline.title)}</div>
            <span className={'b-selectFilter-group-text'}>{discipline.parties.length} companies</span>
          </div>
        </div>
      );
    });
  };

  const renderUser = () => {
    const selectedProps = selected;
    const mainSelect = (user: NfFilterManagerModel) => e => {
      e.stopPropagation();
      const selected = {
        discipline: null,
        company: {
          id: selectedProps?.company?.id || user.parties[0].id,
          title: selectedProps?.company?.title || user.parties[0].company,
        },
        user: {
          id: user.id,
          title: `${user.profile.first_name} ${user.profile.last_name}`,
        },
      };
      if (user.parties[0].disciplines?.length === 1 && !selectedProps?.discipline) {
        selected.discipline = {
          id: user.parties[0].disciplines[0].id,
          title: user.parties[0].disciplines[0].title,
          color: user.parties[0].disciplines[0].color,
        };
      }

      if (selectedProps?.discipline) {
        selected.discipline = selectedProps.discipline;
      }
      handleChangeAssign(selected);
      if (user.parties[0].disciplines?.length > 1 && !selectedProps?.discipline) {
        setGroupFilters(null);
        setOpenedPopup('disc');
        return;
      }
      setGroupFilters(null);
      setOpenedPopup('user_comp');
    };

    if (!groupFilters?.managers?.length) {
      return null;
    }
    return groupFilters.managers.map((user, index) => {
      const isSelected = user.id === selected?.user?.id;
      return (
        <div key={`user-${user.id}`}>
          <div
            className={`b-selectFilter-group-item ${isSelected ? '-selected' : ''} ${isNfCard ? 'nfCardHover' : ''}`}
            onClick={mainSelect(user)}
          >
            <div className={'b-selectFilter-group-title'}>
              {renderText(`${user.profile.first_name} ${user.profile.last_name}`)}
              <div>
                {user.work_package &&
                  user.work_package.map((wp, i) => {
                    return (
                      <span key={`user-${user.id}-wp-${wp.id}`} className={'b-selectFilter-group-text'}>
                        {`${wp.title}${user.work_package.length > i + 1 ? ', ' : ''}`}
                      </span>
                    );
                  })}
              </div>
            </div>
            {user.parties &&
              user.parties.map((company, i) => {
                return (
                  <span key={`user-${user.id}-${company.id}`} className={'b-selectFilter-group-text'}>
                    {company.company}
                    {i < user.parties.length - 1 ? `, ` : null}
                  </span>
                );
              })}
            {user.disciplines.length ? <span className={'separator'}>-</span> : null}
            {user.disciplines &&
              user.disciplines.map((discipline, i) => {
                return (
                  <span key={`user-${user.id}-discipline-${discipline.id}`} className={'b-selectFilter-group-text'}>
                    {`${discipline.title}${user.disciplines.length > i + 1 ? ', ' : ''}`}
                  </span>
                );
              })}
          </div>
          {index === 0 && isSelected ? <div className={'divider'} /> : null}
        </div>
      );
    });
  };

  const getRequestTypeName = () => {
    if (type === 'nf-request') {
      return 'Request party';
    }
    if (type === 'nf-response' || type === 'new-response') {
      return 'Response party';
    }
  };

  const handleOpenDeclinePopover = (e: any) => {
    setIsDeclinePopover(e.currentTarget);
  };

  const sendChangeResponseCompany = () => {
    setIsChangedNow && setIsChangedNow(false);
    dispatch(
      createNewResponseCompany({
        request_id: Number(selectedRequest.id),
        response_party_id: confirmChangeCompany.company.id,
        resp_disc_id: confirmChangeCompany?.discipline?.id || null,
        response_user_id: confirmChangeCompany?.user?.id || null,
        commit: isChangeResponsePartyActionActive ? 1 : 0,
        callback: () => {
          setCommitmentAction('');
        },
      }),
    );
    setOpenedPopup(null);
    setConfirmChangeCompany(null);
  };

  const handleChangeCompanyReceived = () => {
    if (isDisableField) {
      return;
    }
    if (type !== 'nf-response' || !isSentRequestToChange || !isAccess) {
      return;
    }
    setAnchorEl(currentRef.current);
    setChangeCompanyReceived(true);
  };

  const handleNewCompany = (status: string) => {
    dispatch(
      updateNewResponseCompany({
        status: status,
        id: selectedRequest?.response_company_change_request?.id,
        declined_comment: declineMsg || undefined,
        callback: () => {
          setChangeCompanyReceived(null);
          setOpenedPopup(null);
          setIsDeclinePopover(null);
          setDeclineMsg('');
        },
      }),
    );
  };

  const handleClear = () => {
    // const selectedCache = {
    //   discipline: openedPopup === 'disc' && selected.discipline ? null : selected.discipline,
    //   company: openedPopup === 'comp' && selected.company ? null : selected.company,
    //   user: openedPopup === 'user' && selected.user ? null : selected.user,
    // };

    const selectedCache = {
      discipline: null,
      company: null,
      user: null,
    };

    handleChangeAssign(selectedCache, true);

    dispatch(
      searchRequest({
        project_id: Number(activeProjectId),
        search_flags: [openedPopup],
        callback: (res, err) => {
          if (res) {
            setGroupFilters(res);
          }
        },
      }),
    );
  };

  const handleCloseDeclinePopup = () => {
    setIsDeclinePopover(null);
    setDeclineMsg('');
  };

  // const checkViewClearSelect = (openedPopup: NfFilterSearchFlagType): boolean => {
  //   switch (openedPopup) {
  //     case 'comp':
  //       if (selected?.company) return true;
  //
  //     case 'disc':
  //       if (selected?.discipline) return false;
  //
  //     case 'user':
  //       if (selected?.user) return false;
  //
  //     default:
  //       return false;
  //   }
  // };

  const isDisableUserField = () => {
    if (selectedRequest?.parent_id) {
      return true;
    }

    if (type === 'nf-response' && isAccessToCommitment && selectedRequest?.lastUserStatus?.status !== 'provided') {
      return false;
    }

    if (!!isAccess === false) {
      return true;
    }
    if (type === 'nf-request') {
      return (
        (!isAdmin && !userInfo.user_parties?.find(c => c.id === selected?.company?.id)) ||
        selectedRequest?.lastUserStatus?.status === 'accepted'
      );
    }

    if (type === 'nf-response') {
      // debugger;
      if (!selectedRequest?.lastUserStatus) {
        return false;
      }
      if (selectedRequest.lastUserStatus.status === 'provided' || selectedRequest.lastUserStatus.status === 'accepted') {
        return true;
      }
    }
  };

  // const isCheckViewClearSelect = checkViewClearSelect(openedPopup);

  const getNameOfSelected = (): string => {
    if (openedPopup === 'disc' && selected?.company) {
      return `Disciplines available for ${selected.company.title} company`;
      // return 'Selected discipline';
    }
    if (openedPopup === 'comp' && selected?.discipline) {
      return `Companies available for ${selected.discipline.title} discipline`;
      // return 'Selected company';
    }
    if (openedPopup === 'user' && selected?.user) {
      return 'Selected user';
    }
  };

  const handleResetCompany = () => {
    onSelectCompany(getResponsePartySelect(selectedRequest), true);
    setConfirmChangeCompany(null);

    if (isChangeResponsePartyActionActive) {
      setCommitmentAction('');
    }
  };

  return (
    <>
      <div
        className={`b-selectFilter 
        ${isSentRequestToChange ? '-existRequest' : ''}
        ${isDisableField ? '-disabled' : ''}
        ${type === 'unlink' ? '-unlink' : ''}
        `}
        ref={currentRef}
        onClick={handleChangeCompanyReceived}
      >
        {!isHideRequestTypeName ? (
          <HtmlTooltip
            enterDelay={500}
            leaveDelay={0}
            title={
              localStorage.getItem('show_tooltips') === 'no'
                ? ''
                : type === 'nf-request'
                ? 'Party managing this NF'
                : 'Party responding to this NF'
            }
            placement={'top'}
          >
            <span className={`b-selectFilter-type`}>{getRequestTypeName()}</span>
          </HtmlTooltip>
        ) : null}

        <div className={`b-selectFilter-items ${error ? '-error' : ''}`}>
          <HtmlTooltip
            enterDelay={500}
            leaveDelay={0}
            title={
              localStorage.getItem('show_tooltips') === 'no'
                ? ''
                : type === 'nf-request'
                ? 'Request discipline'
                : selectedRequest?.status === 'provided'
                ? 'Response submitted, need to Accept or Decline it first'
                : 'Response discipline'
            }
            placement={'top'}
          >
            <div
              className={cn(
                'b-selectFilter-selected',
                // 'discipline',
                openedPopup === 'disc' ? '-active' : '',
                selected?.discipline?.id ? '-selected' : '',
                isDisableUserField() ? '-disabled' : '',
              )}
              onClick={() => handleOpenSelect('disc', isDisableUserField())}
            >
              {selected?.discipline?.id ? (
                <div className={'discipline-color'} style={{ backgroundColor: selected.discipline.color }} />
              ) : (
                ''
              )}
              <span className={'selectedValues'}>{selected?.discipline?.title || 'Discipline'}</span>
              <div className={'arrow_select'}>
                <SVG src={require('../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
              </div>
            </div>
          </HtmlTooltip>
          <HtmlTooltip
            enterDelay={500}
            leaveDelay={0}
            title={
              localStorage.getItem('show_tooltips') === 'no'
                ? ''
                : type === 'nf-request'
                ? 'Request company'
                : selectedRequest?.status === 'provided'
                ? 'Response submitted, need to Accept or Decline it first'
                : 'Response company'
            }
            placement={'top'}
          >
            <div
              className={`
            b-selectFilter-selected
            company
            ${openedPopup === 'comp' ? '-active' : ''} 
            ${selected?.company ? '-selected' : ''} 
            ${isDisableField ? '-disabled' : ''}
            ${error && '-error'}
            `}
              onClick={() => handleOpenSelect('comp', isDisableField)}
            >
              <span className={'selectedValues'}>{selected?.company?.title || 'Company'}</span>
              <div className={'arrow_select'}>
                <SVG src={require('../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
              </div>
            </div>
          </HtmlTooltip>
          <HtmlTooltip
            enterDelay={500}
            leaveDelay={0}
            title={
              localStorage.getItem('show_tooltips') === 'no'
                ? ''
                : type === 'nf-request'
                ? 'Request manager'
                : selectedRequest?.status === 'provided'
                ? 'Response submitted, need to Accept or Decline it first'
                : 'Response manager'
            }
            placement={'top'}
          >
            <div
              className={`
          b-selectFilter-selected 
          user
          ${openedPopup === 'user' ? '-active' : ''} 
          ${selected?.user ? '-selected' : ''} 
          ${isDisableUserField() ? '-disabled' : ''}`}
              onClick={() => handleOpenSelect('user', isDisableUserField())}
            >
              <span className={'selectedValues'}>{selected?.user?.title || 'Manager'}</span>
              <div className={'arrow_select'}>
                <SVG src={require('../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
              </div>
            </div>
          </HtmlTooltip>
        </div>
        {/* {error ? <span className="error">Please select the company</span> : null} */}
      </div>
      <Popover
        id={`select-${type}`}
        open={!!openedPopup && (openedPopup === 'disc' || openedPopup === 'comp' || openedPopup === 'user')}
        anchorEl={anchorEl}
        onClose={handleCloseSelect}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: currentRef?.current?.clientWidth,
          },
          classes: {
            root: 'b-selectFilter-popover',
          },
        }}
      >
        <div className={'b-selectFilter-menu'}>
          {type === 'nf-request' && openedPopup === 'disc' && isCompanyUserOrManager ? null : (
            <div>
              <TextInputNew
                isSearch
                type={'on-bgd'}
                size={'sm'}
                placeholder={'Search'}
                onChange={handleChangeSearch}
                value={search}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                wrapperClassName={'textInputNew_select'}
              />
            </div>
          )}
          <div className={'b-selectFilter-groups'}>
            <div className={`b-selectFilter-clearAll`}>
              <div className={'selectedItem'}>{getNameOfSelected()}</div>
              {/*{isCheckViewClearSelect && (*/}
              {/*  <div className={'selectedBlock'} onClick={handleClear}>*/}
              {/*    <span>Clear selection</span>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            {openedPopup === 'disc' ? <div className={'b-selectFilter-group'}>{renderDiscipline()}</div> : null}
            {openedPopup === 'comp' ? <div className={'b-selectFilter-group'}>{renderCompany()}</div> : null}
            {openedPopup === 'user' ? <div className={'b-selectFilter-group'}>{renderUser()}</div> : null}
          </div>
        </div>
      </Popover>

      <Popover
        id={`select-${type}`}
        open={!!changeCompanyReceived}
        anchorEl={anchorEl}
        onClose={handleCloseSelect}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: currentRef?.current?.clientWidth,
          },
          classes: {
            root: 'b-selectFilter-popover',
          },
        }}
      >
        <div className={'b-selectFilter-menu'}>
          <div className={'b-selectFilter-changeCompany'}>
            <p>Requested the change of the response party</p>
            <p className={'b-selectFilter-dialog-text'}>
              from &nbsp;
              <mark className={'mr-2 ml-2'}>
                {selectedRequest?.response_discipline?.title || ''}
                {selectedRequest?.response_party ? ' - ' : ''}
                {selectedRequest?.response_party?.company || ''}
                {selectedRequest?.response_user?.profile ? ' - ' : ''}
                {`${selectedRequest?.response_user?.profile?.first_name || ''} ${
                  selectedRequest?.response_user?.profile?.last_name || ''
                }` || ''}
              </mark>
              &nbsp; to &nbsp;
              <mark className={'mr-2 ml-2'}>
                {selectedRequest?.response_company_change_request?.response_discipline?.title || ''}
                {selectedRequest?.response_company_change_request?.company ? ' - ' : ''}
                {selectedRequest?.response_company_change_request?.company?.company || ''}
                {selectedRequest?.response_company_change_request?.response_user?.profile ? ' - ' : ''}
                {`${selectedRequest?.response_company_change_request?.response_user?.profile.first_name || ''} ${
                  selectedRequest?.response_company_change_request?.response_user?.profile.last_name || ''
                }`}
              </mark>
            </p>
            <div className={'b-selectFilter-dialog-btns'}>
              <ButtonView
                type={'primal'}
                title={`Accept`}
                handleClick={() => handleNewCompany('accepted')}
                height={'20px'}
                style={{ marginRight: '10px' }}
                disabled={
                  isPullRequest ? false : !isAccess || selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided'
                }
                width={'70px'}
              />
              <ButtonView type={'decline'} title={`Decline`} handleClick={handleOpenDeclinePopover} height={'20px'} width={'70px'} />
            </div>
          </div>
        </div>
      </Popover>

      <Popover
        id={'decline-reason'}
        open={!!isDeclinePopover}
        anchorEl={isDeclinePopover}
        onClose={handleCloseDeclinePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          transform: 'translate(4px, -88px)',
          marginTop: '5px',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#263042',
          },
        }}
      >
        <div className={'dialogContent dialogContent__declineReason'}>
          <span className={'text'} style={{ fontSize: '14px', textTransform: 'initial', opacity: 1 }}>
            Decline reason
          </span>
          <SandBoxDescription changeHandler={text => setDeclineMsg(text)} currentValue={declineMsg || ''} />
          <DialogActions style={{ margin: 0, padding: '5px 0', alignItems: 'center', background: 'var(--background-grid)' }}>
            <div style={{ display: 'flex' }}>
              <ButtonView type={'decline'} title={`Decline`} handleClick={() => handleNewCompany('rejected')} height={'20px'} />
              <ButtonView
                type={'outline'}
                title={'Cancel'}
                handleClick={handleCloseDeclinePopup}
                height={'20px'}
                style={{
                  marginLeft: '5px',
                }}
              />
            </div>
          </DialogActions>
        </div>
      </Popover>
      {confirmChangeCompany ? (
        <Dialog
          open={!!confirmChangeCompany}
          onClose={() => setConfirmChangeCompany(null)}
          aria-labelledby="form-dialog-title Pull request modal"
          maxWidth="md"
          fullWidth={true}
          // classes={{ paperScrollPaper: classes.paperScrollPaper }}
          style={{
            backgroundColor: 'transparent',
            height: '100%',
          }}
          PaperProps={{
            style: {
              overflowY: 'inherit',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            className: 'b-selectFilter-modalPaper',
          }}
        >
          <div className={'b-selectFilter-dialog'}>
            <div className={'b-selectFilter-dialog-header'}>Response party change</div>
            <div className={'b-selectFilter-dialog-main'}>
              <div className={'b-selectFilter-dialog-content'}>
                <p className={'b-selectFilter-dialog-text'}>You are changing a Response Party to:</p>
                <span className={'b-selectFilter-dialog-company'}>
                  {confirmChangeCompany.company?.title}
                  {confirmChangeCompany.discipline ? ' - ' : ''}
                  {confirmChangeCompany.discipline?.title || ''}
                  {confirmChangeCompany.user ? ' - ' : ''}
                  {confirmChangeCompany.user?.title || ''}
                </span>
                <p className={'b-selectFilter-dialog-text'}>
                  Do you want to <mark>send a change request</mark> that has to be approved by responsible person?
                </p>
              </div>
              <div className={'b-selectFilter-dialog-btns-confirm'}>
                {isAdmin && !isChangeResponsePartyActionActive ? (
                  <ButtonView
                    type={'primal'}
                    title={`Change now`}
                    handleClick={() => changeCompany(confirmChangeCompany)}
                    disabled={false}
                    height={'30px'}
                    width={120}
                  />
                ) : null}
                {selectedRequest?.status !== 'accepted' ? (
                  <ButtonView
                    type={'primal'}
                    title={`Send request`}
                    handleClick={sendChangeResponseCompany}
                    disabled={false}
                    height={'30px'}
                    width={120}
                  />
                ) : null}
                <ButtonView type={'outline'} title={`Cancel`} handleClick={handleResetCompany} height={'30px'} width={120} />
              </div>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  );
};
