import React from 'react';
import PropTypes from 'prop-types';
import WhiteBoardtableRow from './WhiteBoardtableRow/WhiteBoardtableRow';
import WhiteBoardtableHead from './WhiteBoardtableHead/WhiteBoardtableHead';

import compose from 'recompose/compose';
import { connector } from './connector';
import { initialData, initialRangeData, getDaysRange, getMonthRange, getWeeksRange } from './WhiteBoardtableRow/dnd/initialData';

import LoaderView from '../../controls/Loader/LoaderView';

import scss_styles from './WhileBoardStyle.scss';
import moment from 'moment';
// import { ArcherContainer } from 'react-archer';
import './whiteBoardTable.scss';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Popover from '@material-ui/core/Popover';
import { WhiteBoardContext } from './WhiteBoardContext';
import FiltersListPopover from '../../controls/FiltersListPopover/FiltersListPopover';
import Checkbox from '@material-ui/core/Checkbox';
import { ResetButton } from '../../controls';
import ScrollContainer from 'react-indiana-drag-scroll';
import WhiteBoardRequestDataHelper from '../../../helpers/WhiteBoardRequestDataHelper';
import CustomSlider from '../../controls/Slider/Slider';
import SVG from 'react-inlinesvg';
import { FilterMiniList } from '../FilterMiniList/FilterMiniList';
import NeedListRequestDataHelper from '../../../helpers/NeedListRequestDataHelper';
import BaseHelper from '../../../helpers/BaseHelper';
import LinearDeterminate from '../../controls/LinearDeterminate/LinearDeterminate';
import WipPage from '../WIPPage/WIPPage';

const ELEMENTS_IN_ROW = 25;

class WhiteBoard extends React.Component {
  state = {
    availableItems: {},
    isPopoverOpen: false,
    data: initialData(moment().format('YYYY'), moment().format('MM'), 'monthMode'),
    mode: 'monthMode',
    zoom: 1,
    isShowAll: false,
    currentYear: parseInt(moment().format('YYYY'), 10),
    currentMonth: parseInt(moment().format('MM'), 10),
    login_user: {},
    selectedFilters: {
      display: { title: 'NF status', value: 'NF status' },
      requestCompany: { title: 'Request company', value: 'Request company' },
      responseCompany: { title: 'Response company', value: 'Response company' },
      location: { title: 'Level', value: 'Level' },
    },
    requestProcess: [
      'Request drafted',
      'Request sent',
      'Commitment submitted',
      'Response Submitted',
      'Response accepted',
      'Request re-opened',
    ],
    reset: false,
    relations: {},
    fullWidth: false,
    // refWB: React.createRef(),
    showHiddenElements: [],
    rangeElementsLogic: {
      rangeElements: [],
    },
    refs: {},
    tempMode: 'monthMode',
    openFilters: false,
  };

  zoomStyle = {
    header: {
      columnWidth: 158,
    },
    request: {
      column: {
        width: 158,
      },
      task: {
        height: 24,
        width: '100%',
      },
      yearTask: {
        height: 24,
        width: '100%',
      },
      row: {
        height: 158,
        minHeight: 158,
      },
    },
  };

  months = [
    { title: 'January', value: '1' },
    { title: 'February', value: '2' },
    { title: 'March', value: '3' },
    { title: 'April', value: '4' },
    { title: 'May', value: '5' },
    { title: 'June', value: '6' },
    { title: 'July', value: '7' },
    { title: 'August', value: '8' },
    { title: 'September', value: '9' },
    { title: 'October', value: '10' },
    { title: 'November', value: '11' },
    { title: 'December', value: '12' },
  ];

  years = [
    { title: this.state.currentYear - 1, value: this.state.currentYear - 1 },
    { title: this.state.currentYear, value: this.state.currentYear },
    { title: this.state.currentYear + 1, value: this.state.currentYear + 1 },
  ];

  tableRef = React.createRef();
  tableWrapperRef = React.createRef();
  todayRef = React.createRef();
  rowRef = React.createRef();
  archerEl = React.createRef();
  whiteboardRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getWhiteBoardFilters();
    // this.props.getWhiteBoard(this.prepareDateFilterParam());
    this.props.loadProjectData();
    window.addEventListener('scroll', this.zoomBlockFixed);
    document.body.style.overflow = 'hidden';
  }

  componentDidUpdate(previusProps, previusState) {
    if (this.whiteboardRef.current && !this.fullscreenHandler) {
      this.fullscreenHandler = true;
      this.whiteboardRef.current.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          this.setState({ fullScreenOpen: true });
        } else {
          this.setState({ fullScreenOpen: false });
        }
      });
    }
    if (this.state.reset !== previusState.reset && this.state.reset) {
      this.setState({ reset: false });
    }
    // if (this.props.location !== previusProps.location) {
    //   this.props.location.state && this.setState({
    //     display: this.props.location.state.display,
    //   });
    // }

    if (this.props.userData.userInfo && Object.keys(this.props.userData.userInfo).length && !Object.keys(this.state.login_user).length) {
      this.setState({
        login_user: {
          id: parseInt(this.props.userData.user_id),
          role: this.props.userData.userInfo.roles,
        },
      });
    }

    if (
      +this.props.userData.active_project_id !== +previusProps.userData.active_project_id ||
      this.state.currentYear !== previusState.currentYear ||
      this.state.currentMonth !== previusState.currentMonth ||
      (this.state.mode !== previusState.mode && this.props.whiteBoardFilters)
    ) {
      // setTimeout(() => {
      this.props.getWhiteBoardFilters();
      // this.props.getWhiteBoard(this.prepareDateFilterParam());
      // this.props.loadProjectData();
      this.resetPosition();
      this.scrollEventRange(true);
      // }, 2000);
    }

    if (this.props.whiteBoardFilters !== previusProps.whiteBoardFilters && this.props.whiteBoardFilters) {
      this.setState({
        filters: this.props.whiteBoardFilters,
      });
      this.scrollEventRange(true);
      this.props.getWhiteBoard(this.prepareDateFilterParam());
    }

    if (this.state.zoom !== previusState.zoom) {
      const tableWidth = this.rowRef.current.clientWidth;
      const tableHeight = this.tableWrapperRef.current.clientHeight;
      const scrollLeft = (tableWidth / 100) * this.tableCenterPercentX - this.tableRef.current.clientWidth / 2;
      const scrollTop = (tableHeight / 100) * this.tableCenterPercentY - this.tableRef.current.clientHeight / 2;
      this.tableRef.current.scrollLeft = scrollLeft;
      this.tableRef.current.scrollTop = scrollTop;
    }

    if (this.state.zoom === 1 && !this.state.fullWidth) {
      const screenWidth = this.tableRef && this.tableRef.current ? this.tableRef.current.clientWidth : 0;
      const tableWidth = this.rowRef && this.rowRef.current ? this.rowRef.current.clientWidth : 0;

      if (tableWidth < screenWidth) {
        this.setState({ fullWidth: true });
      }
    }
    if (
      Object.keys(this.props.requestData.whiteBoard || {}).length &&
      this.props.requestData.whiteBoard !== previusProps.requestData.whiteBoard
    ) {
      const disciplines = [{ value: 'all', title: 'All', isChecked: true }];
      Object.values(this.props.requestData.whiteBoard).map(discipline => {
        disciplines.push({
          title: discipline.title,
          value: discipline.id,
          isChecked: true,
        });
      });

      this.setState({
        filters: {
          ...this.props.whiteBoardFilters,
          disciplines: !this.state.filters?.disciplines ? disciplines : [...this.state.filters.disciplines],
        },
      });
    }
    if (this.state.data && this.state.data !== previusState.data) {
      if (this.state.mode === 'monthMode') {
        const refs =
          this.state.data?.monthViewSizes?.reduce((acc, value) => {
            acc[moment(value.date).format('YYYY-MMM')] = React.createRef();
            return acc;
          }, {}) || {};

        this.setState({
          refs,
        });
      } else if (this.state.mode === 'weekMode') {
        const refs =
          this.state.data?.weekSizes?.reduce((acc, value) => {
            acc[moment(value.date).format('YYYY-MM-DD')] = React.createRef();
            return acc;
          }, {}) || {};

        this.setState({
          refs,
        });
      }
    }
    if (this.state.refs && previusState.refs && this.state.refs !== previusState.refs) {
      if (this.state.refs[this.state.navigateToMonth]?.current && this.state.navigateToMonth) {
        setTimeout(() => {
          this.state.refs[this.state.navigateToMonth]?.current?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
          });
          this.setState({
            navigateToMonth: null,
            navigate: false,
          });
          this.scrollEventRange();
        }, 3000);
      }
    }
  }

  onAddRelations = relations => {
    this.setState(state => {
      const newRelations = { ...state.relations };
      relations.forEach(value => {
        newRelations[value] = newRelations[value] ? newRelations[value] + 1 : 1;
      });

      return {
        relations: newRelations,
      };
    });
  };

  onRemoveRelations = relations => {
    this.setState(state => {
      const newRelations = { ...state.relations };

      relations.forEach(value => {
        if (newRelations[value] > 1) {
          newRelations[value]--;
        } else {
          delete newRelations[value];
        }
      });

      return {
        relations: newRelations,
      };
    });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.zoomBlockFixed);
    document.body.style.overflow = 'auto';
    this.props.resetFilters();
  }

  zoomBlockFixed() {
    const zoomBlock = document.querySelector('.b-whiteBoard__zoomBlock');
    if (window.pageYOffset > 200) {
      zoomBlock.classList.add('-fixed');
    } else {
      zoomBlock.classList.remove('-fixed');
    }
  }

  findPredecessor = (requestId, successors) => {
    if (successors && successors.length) {
      // successors.map(i => {
      //   i.predecessor_id
      // });
      requestId = successors[0].predecessor_id;
    }
    this.setState({
      displayPredecessor: [requestId],
      displaySuccessors: successors,
    });
  };

  mouseOutFromRequest = () => {
    this.setState({
      displayPredecessor: null,
      displaySuccessors: null,
    });
  };

  getScrollPosition = () => {
    const { zoom } = this.state;
    // this.tableRef.current.scrollBy(300, 300);
    const screenWidth = this.tableRef.current.clientWidth;
    const screenHeight = this.tableRef.current.clientHeight;
    const tableWidth = this.rowRef.current.clientWidth;
    const tableHeight = this.tableWrapperRef.current.clientHeight;
    const centerWidth = screenWidth / 2;
    const centerHeight = screenHeight / 2;
    const { scrollLeft, scrollTop } = this.tableRef.current;
    const tableCenterPercentX = ((centerWidth + scrollLeft) / tableWidth) * 100;
    const tableCenterPercentY = ((centerHeight + scrollTop) / tableHeight) * 100;
    this.tableCenterPercentX = tableCenterPercentX;
    this.tableCenterPercentY = tableCenterPercentY;

    if (zoom === 1) {
      this.tableRowWidth = this.rowRef.current.clientWidth;
    }
  };

  zoomUp = () => {
    const { zoom, mode } = this.state;
    // const table = document.querySelector('.b-whiteBoardTable');
    this.getScrollPosition();
    setTimeout(() => {
      this.scrollEventRange();
    }, 2000);
    this.setState(
      {
        zoom: mode === 'monthMode' ? zoom + 2 : zoom + 7,
      },
      () => this.archerEl.current.refreshScreen(),
    );
  };

  zoomDown = () => {
    const table = document.querySelector('.b-whiteBoardTable');
    if (table) {
      table.scrollBy(-300, 0);
    }
    setTimeout(() => {
      this.scrollEventRange();
    }, 2000);
    const { zoom, mode } = this.state;
    this.setState(
      {
        zoom: mode === 'monthMode' ? zoom - 2 : zoom - 7,
      },
      () => this.archerEl.current.refreshScreen(),
    );
  };

  yearUp = () => {
    this.setState({
      currentYear: this.state.currentYear + 1,
    });
  };

  yearDown = () => {
    this.setState({
      currentYear: this.state.currentYear - 1,
    });
  };

  monthUp = () => {
    let month = this.state.currentMonth + 1;
    if (month === 13) {
      month = 1;
      this.setState({
        currentYear: this.state.currentYear + 1,
      });
    }
    this.setState({
      currentMonth: month,
    });
  };

  monthDown = () => {
    let month = this.state.currentMonth - 1;
    if (month === 0) {
      month = 12;
      this.setState({
        currentYear: this.state.currentYear - 1,
      });
    }
    this.setState({
      currentMonth: month,
    });
  };

  prepareDateFilterParam = (mode = this.state.mode) => {
    let columnData = {};

    const abc = moment(new Date()).diff(this.props.whiteBoardFilters?.last_date, 'M');
    if (mode === 'yearMode') {
      columnData = initialData(`${this.state.currentYear}`, `${this.state.currentMonth}`, 'yearMode');
      const monthFullData = getMonthRange(
        this.props.whiteBoardFilters?.first_date ? this.props.whiteBoardFilters.first_date : new Date(),
        abc > 0 ? new Date() : this.props.whiteBoardFilters?.last_date,
      );
      if (monthFullData) {
        columnData = monthFullData;
      }
    }

    if (mode === 'monthMode') {
      const weekFullData = getWeeksRange(
        this.props.whiteBoardFilters?.first_date ? this.props.whiteBoardFilters.first_date : new Date(),
        abc > 0 ? new Date() : this.props.whiteBoardFilters?.last_date,
      );

      if (weekFullData) {
        columnData = weekFullData;
      }
    }

    if (mode === 'weekMode') {
      const startDate = this.props.whiteBoardFilters?.first_date ? this.props.whiteBoardFilters.first_date : new Date();
      // const months = moment(this.props.whiteBoardFilters?.last_date).diff(moment(startDate), 'month');
      let endDate = this.props.whiteBoardFilters?.last_date;
      // let endDate = this.props.whiteBoardFilters?.last_date && months < 24 ? this.props.whiteBoardFilters.last_date : moment(startDate).add(24, 'month').format('YYYY-MM-DD');

      endDate = moment(endDate).endOf('month').format('YYYY-MM-DD');

      const monthFullData = getDaysRange(startDate, endDate);
      const weekFullData = getWeeksRange(startDate, endDate);

      if (monthFullData) {
        columnData = monthFullData;
        columnData.weekOrder = weekFullData;
      }
    }

    this.setState({
      ...this.state,
      data: columnData,
    });

    this.scrollEventRange();
    return {
      // start_due_date, end_due_date
    };
  };

  resetPosition = () => {
    try {
      this.tableRef.current.scrollLeft = 0;
    } catch (err) {}
  };

  setMode = async (mode, e) => {
    e && e.stopPropagation();
    let tempMode = '';
    mode === 1 || mode === 'weekMode' ? (tempMode = 'weekMode') : null;
    mode === 2 || mode === 'monthMode' ? (tempMode = 'monthMode') : null;
    mode === 3 || mode === 'yearMode' ? (tempMode = 'yearMode') : null;

    await this.setState({
      tempMode: tempMode,
    });
    setTimeout(() => {
      this.setState({
        // mode,
        isShowAll: false,
        zoom: 1,
        relations: {},
        rangeElementsLogic: {
          ...this.state.rangeElementsLogic,
          paddingRight: 0,
        },
        // data: monthFullData,
        mode: tempMode,
      });

      this.scrollEventRange(true);
      this.resetPosition();
      setTimeout(() => {
        this.scrollEventRange(true);
      }, 2000);
      this.prepareDateFilterParam(mode);
    }, 200);

    // const abc = moment(new Date()).diff(this.props.whiteBoardFilters?.last_date, 'M');
    // const monthFullData = getDaysRange(this.props.whiteBoardFilters?.first_date ? this.props.whiteBoardFilters.first_date : new Date(), abc > 0 ? new Date() : this.props.whiteBoardFilters?.last_date);

    // this.scrollEventRange(true);
  };

  onMonth = (index, date) => {
    if (this.state.mode === 'yearMode') {
      this.setState({
        currentMonth: index + 1,
        mode: 'monthMode',
        zoom: 1,
        navigateToMonth: date,
        navigate: true,
      });
    } else if (this.state.mode === 'monthMode') {
      this.setState({
        currentMonth: index,
        mode: 'weekMode',
        zoom: 1,
        navigateToMonth: this.state.data?.columns[date]?.start,
        navigate: true,
      });
    }
  };

  handleOpenSelect = e => {
    this.setState({
      anchorEl: e.currentTarget,
      isOpenedSelect: true,
    });
  };

  handleCloseSelect = () => {
    this.setState({
      anchorEl: null,
      isOpenedSelect: false,
    });
  };

  handleChangeSelect = value => {
    if (this.state.mode === 'monthMode') {
      this.setState({ currentMonth: value, isOpenedSelect: false, zoom: 1 });
    } else {
      this.setState({ currentYear: value, isOpenedSelect: false, zoom: 1 });
    }
  };

  handleOpenFilterList = type => e => {
    this.setState({
      isFilterListOpen: type,
      anchorElFilter: e.currentTarget,
    });
  };

  handleCloseFilterList = () => {
    this.setState({
      isFilterListOpen: false,
      anchorElFilter: null,
    });
  };

  handleChange = (item, type) => () => {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [type]: {
          ...this.state.selectedFilters[type],
          value: item,
        },
      },
      isFilterListOpen: false,
    });
  };

  handleCheckDiscipline = (value, type) => () => {
    const filters = { ...this.state.filters };
    if (value === 'all') {
      const valueAll = filters[type][0].isChecked;
      filters[type].map(f => {
        f.isChecked = !valueAll;
        return f;
        // value === f.value
      });
      // item.isChecked = !item.isChecked;
    } else {
      const item = filters[type].find(f => value === f.value);
      item.isChecked = !item.isChecked;
      filters[type][0].isChecked = false;
    }
    this.setState({
      filters: filters,
    });
  };

  checkRequestInList = id => {
    // eslint-disable-next-line prefer-destructuring
    const whiteBoard = Object.values(this.props.requestData.whiteBoard);
    const tempWhiteBoard = [];
    // eslint-disable-next-line prefer-destructuring
    const startViewDate = this.state.rangeElementsLogic.showRangeDate[0];
    // eslint-disable-next-line prefer-destructuring
    const endViewDate = this.state.rangeElementsLogic.showRangeDate[1];
    if (whiteBoard && whiteBoard.length) {
      whiteBoard.map(item => {
        tempWhiteBoard.push(...item.response_party_requests);
        // if (moment(item.due_date).isBefore(endViewDate) && moment(item.due_date).isAfter(startViewDate)) {
        // }
      });
    }
    if (tempWhiteBoard && tempWhiteBoard.length) {
      const item = tempWhiteBoard.find(item => +item.nf === +id);
      if (
        item &&
        moment(item.due_date).isBefore(endViewDate)
        // && moment(item.due_date).isAfter(startViewDate)
      ) {
        return item;
      } else {
        return false;
      }
    }
    return false;
  };

  resetLinks = () => {
    this.setState({ reset: true, relations: {} });
  };

  updateAvailableItems = (index, newAvailableItems) => {
    this.setState(state => ({
      availableItems: {
        ...state.availableItems,
        [index]: newAvailableItems,
      },
    }));
  };

  showCustomElement = element => {
    const index = moment(element.due_date).diff(this.props.whiteBoardFilters?.first_date, 'days');
    const isIndex = this.state.showHiddenElements.find(f => f == index);
    if (!isIndex) {
      this.setState({
        showHiddenElements: [...this.state.showHiddenElements, index],
      });
    }
    return true;
  };

  scrollEventRange = isInit => {
    const requestStyle = this.zoomStyle.request;
    const { zoom } = this.state;

    const zoomStylesCalc = {
      header: {
        width: this.zoomStyle.header.width * zoom,
        columnWidth: requestStyle.column.width * zoom,
      },
      request: {
        column: {
          width: requestStyle.column.width * zoom,
        },
        task: {
          height: requestStyle.task.height * zoom,
          width: requestStyle.task.width * zoom,
          // fontSize: requestStyle.task.fontSize + zoom ,
        },
        yearTask: {
          height: requestStyle.yearTask.height * zoom,
          width: requestStyle.yearTask.width * zoom,
          fontSize: requestStyle.yearTask.fontSize < 11 && requestStyle.yearTask.fontSize + zoom - 1,
          margin: requestStyle.yearTask.margin * zoom,
        },
        row: {
          height: requestStyle.row.height * zoom,
          width: zoom > 1 ? this.tableRowWidth * zoom : 'auto',
          minHeight: requestStyle.row.minHeight,
        },
      },
    };

    let fullWidth =
      Object.values(this.state?.data?.columns ? this.state.data.columns : {}).length *
        this.zoomStyle.request.column.width *
        this.state.zoom +
      147;
    const zoomStyles = (this.zoomStyles =
      JSON.stringify(zoomStylesCalc) === JSON.stringify(this.zoomStyles) ? this.zoomStyles : zoomStylesCalc);

    const width = zoomStyles.request.column.width;
    setTimeout(() => {
      const showCountElements = Math.ceil(this.tableRef?.current?.clientWidth / width);

      const aroundTotalCount = Math.ceil(fullWidth / width);
      const scrolledElements = Math.ceil(this.tableRef?.current?.scrollLeft / width);

      const paddingLeft = scrolledElements * width - width * ELEMENTS_IN_ROW;
      let paddingRight = aroundTotalCount * width - (showCountElements + scrolledElements) * width - width - fullWidth;

      if (showCountElements === aroundTotalCount || paddingRight < 0) {
        paddingRight = 0;
      }

      if (this.props.whiteBoardFilters) {
        // if (paddingLeft < 980) {
        //   debugger
        // }
        // debugger
        this.setState({
          rangeElementsLogic: {
            rangeElements: [
              scrolledElements - ELEMENTS_IN_ROW < 0 ? 0 : scrolledElements - ELEMENTS_IN_ROW,
              showCountElements + scrolledElements + ELEMENTS_IN_ROW,
            ],
            paddingLeft: paddingLeft < 0 ? 0 : paddingLeft,
            // paddingLeft: (diff + 10) * 49,
            paddingRight,
            showRangeDate: [
              moment(this.props.whiteBoardFilters.first_date)
                .add(scrolledElements - ELEMENTS_IN_ROW < 0 ? 0 : scrolledElements - ELEMENTS_IN_ROW, 'days')
                .format('YYYY-MM-DD'),
              moment(this.props.whiteBoardFilters.first_date)
                .add(showCountElements + scrolledElements + ELEMENTS_IN_ROW, 'days')
                .format('YYYY-MM-DD'),
            ],
          },
        });
      }
    });
    if (isInit) {
      const diff = Math.abs(moment(new Date()).diff(moment(this.props?.whiteBoardFilters?.first_date), 'days'));
      if (this.tableRef?.current?.scrollLeft) {
        setTimeout(() => {
          this.tableRef.current.scrollLeft = diff * 158;
        }, 0);
      }
      this.scroll();
    }
  };

  tableScrolledTop;
  timer;
  scroll = e => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (
      this.tableScrolledTop === this.tableRef?.current?.scrollTop &&
      (this.state.rangeElementsLogic.paddingLeft === 0
        ? ELEMENTS_IN_ROW * 158 - 158 < this.tableRef?.current?.scrollLeft
        : this.state.rangeElementsLogic.paddingLeft > this.tableRef?.current?.scrollLeft ||
          this.state.rangeElementsLogic.paddingLeft + 158 * 50 < this.tableRef?.current?.scrollLeft)
    ) {
      this.timer = setTimeout(() => {
        this.scrollEventRange();
      }, 50);
    }

    this.tableScrolledTop = this.tableRef.current?.scrollTop;
  };

  handleResetFilter = () => {
    this.setState({
      selectedFilters: {
        requestCompany: { ...this.state.selectedFilters.requestCompany, value: 'Request company' },
        responseCompany: { ...this.state.selectedFilters.responseCompany, value: 'Response company' },
        location: { ...this.state.selectedFilters.location, value: 'Level' },
        display: { ...this.state.selectedFilters.display, value: 'NF status' },
      },
      isFilterReset: true,
    });
    setTimeout(() => {
      this.setState({
        isFilterReset: false,
      });
    }, 0);
  };

  applyFiltersFromCard = filters => {
    const { requestCompany, responseCompany, location } = WhiteBoardRequestDataHelper.getFiltersDataFromCard(filters, this.state.filters);
    this.setState({
      selectedFilters: {
        requestCompany: { ...this.state.selectedFilters.requestCompany, value: requestCompany },
        responseCompany: { ...this.state.selectedFilters.responseCompany, value: responseCompany },
        location: { ...this.state.selectedFilters.location, value: location },
        display: { ...this.state.selectedFilters.display },
      },
    });
  };

  fullWidthTable = () => {
    this.whiteboardRef.current.requestFullscreen();
  };

  exitFullWidthTable = () => {
    document.exitFullscreen();
  };

  toggleOpenFilters = () => {
    this.setState(state => {
      return { openFilters: !state.openFilters };
    });
  };

  applyFilters = field => {
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [field]: {
          ...this.state.selectedFilters[field],
          value: 'All',
        },
      },
    });
  };

  getFiltersFields = () => {
    const { selectedFilters } = this.state;
    let fields = Object.keys(selectedFilters).reduce((result, key) => {
      let selValue = selectedFilters[key].value;
      if (
        selValue === 'All' ||
        selValue === 'NF status' ||
        selValue === 'Request company' ||
        selValue === 'Response company' ||
        selValue === 'Level'
      ) {
        return { ...result };
      } else {
        return { ...result, [key]: [{ value: selValue, ...selectedFilters[key] }] };
      }
    }, {});
    // Object.keys(fields).map()
    // const selectedFilters = {filters: {display: [{value: display}], location: [{value: location, checked: true}], requestCompany: [{value: requestCompany}], responseCompany: [{value: responseCompany}]}};
    return { filters: fields };
  };

  replaceNumberWeeks = num => {
    const numAlias = {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
      6: '6th',
    };
    return numAlias[num];
  };

  getBuildingsLevels = () => {
    const { locations, buildings } = this.props;
    const { filters } = this.state;

    const buildingsObj = Object.values(locations || {})
      ?.filter(el => Object.keys(filters?.locations)?.find(id => id === el.id))
      .reduce((buildings, level) => {
        buildings[level.building_id] = buildings[level.building_id] || [];
        buildings[level.building_id].push(level);

        return buildings;
      }, {});

    return Object.keys(buildingsObj).map(buildId => {
      return { title: buildings?.find(building => building.id == buildId)?.title, levels: BaseHelper.sort(buildingsObj[buildId], 'title') };
    });
  };

  render() {
    const {
      requestLoading,
      ufcCode,
      locations,
      projectParties,
      saveRequestAction,
      saveResponseAction,
      fileUpload,
      filesData,
      requestData,
      wbLoading,
    } = this.props;

    const { zoom, mode, currentYear, currentMonth, data, filters, tempMode, fullScreenOpen, navigate, isFilterReset } = this.state;
    const { display, location, requestCompany, responseCompany } = this.state.selectedFilters;

    const rangeElementsLogic = { ...this.state.rangeElementsLogic };
    rangeElementsLogic.rangeElements = [...rangeElementsLogic?.rangeElements, ...this.state.showHiddenElements];
    const whiteBoard = requestData.whiteBoard && Object.values(requestData.whiteBoard);

    const requestStyle = this.zoomStyle.request;

    const zoomStylesCalc = {
      header: {
        width: this.zoomStyle.header.width * zoom,
        columnWidth: requestStyle.column.width * zoom,
      },
      request: {
        column: {
          width: requestStyle.column.width * zoom,
        },
        task: {
          height: requestStyle.task.height * zoom,
          width: requestStyle.task.width * zoom,
          // fontSize: requestStyle.task.fontSize + zoom ,
        },
        yearTask: {
          height: requestStyle.yearTask.height * zoom,
          width: requestStyle.yearTask.width * zoom,
          fontSize: requestStyle.yearTask.fontSize < 11 && requestStyle.yearTask.fontSize + zoom - 1,
          margin: requestStyle.yearTask.margin * zoom,
        },
        row: {
          height: requestStyle.row.height * zoom,
          width: zoom > 1 ? this.tableRowWidth * zoom : 'auto',
          minHeight: requestStyle.row.minHeight,
        },
      },
    };

    const zoomStyles = (this.zoomStyles =
      JSON.stringify(zoomStylesCalc) === JSON.stringify(this.zoomStyles) ? this.zoomStyles : zoomStylesCalc);
    let fullWidth =
      Object.values(this.state?.data?.columns ? this.state.data.columns : {}).length *
        this.zoomStyle.request.column.width *
        this.state.zoom +
      147;
    let fullWidthYearMode = Object.values(this.state?.data?.columns ? this.state.data.columns : {}).length * 139;

    const isNonFullWidth = fullWidth <= this.tableRef?.current?.clientWidth && this.state.zoom === 1;
    fullWidth < this.tableRef?.current?.clientWidth ? (fullWidth = this.tableRef?.current?.clientWidth) : null;

    if (true) return <WipPage type={'whiteboard'} />;

    if (!this.props.whiteBoardFilters) {
      return <></>;
    }
    return (
      <div className="b-whiteBoard" ref={this.whiteboardRef}>
        <div className={'linearProgress'}>
          <LinearDeterminate isActive={wbLoading || isFilterReset} />
        </div>
        <div className="b-whiteBoard__header b-table__wrapMainTitle">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 className="b-whiteBoard__title b-table__mainTitle" style={{ margin: '13px 0 8px 0' }}>
              Whiteboard
            </h1>
            <div className={'filters_header_item'} style={{ margin: '0 0 0 15px' }}>
              <FilterMiniList selectedFilters={this.getFiltersFields()} onChange={this.applyFilters} isWhiteBoard />
            </div>
          </div>
          <div className="b-whiteBoard__wrapFilter">
            {(this.state.openFilters || window.innerWidth < 767 || window.innerWidth < 980) && (
              <div className="b-whiteBoard__btnBlock">
                <FiltersListPopover height={12}>
                  {/*<ResetButton*/}
                  {/*  title={'Reset all links'}*/}
                  {/*  handleClick={() => this.resetLinks()}*/}
                  {/*/>*/}
                  <div className="filter__wrapContent">
                    <FormControl variant="outlined">
                      <div className="filter__filter">
                        <div
                          className="filter__btn"
                          aria-describedby={'whiteBoard-nfStatus'}
                          onClick={this.handleOpenFilterList('nfStatus')}
                        >
                          <span className={'filter__mainTitle'}>{display.value}</span>
                          <span className={`filter__arrow`}>
                            <SVG src={require('../../../assets/images/arrow_down.svg')} alt="#" />
                          </span>
                        </div>
                        <Popover
                          id={'nfStatus'}
                          open={this.state.isFilterListOpen === 'nfStatus'}
                          anchorEl={this.state.anchorElFilter}
                          onClose={this.handleCloseFilterList}
                          disablePortal={true}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div className="filter__listFilters">
                            <div
                              onClick={this.handleChange('All', 'display')}
                              className="filter__item"
                              style={
                                display.value === 'All' || display.value === 'NF status' ? { background: 'var(--background-grid)' } : null
                              }
                            >
                              <span>All</span>
                            </div>
                            {this.state.requestProcess.map((item, i) => {
                              return (
                                <div
                                  key={item}
                                  onClick={this.handleChange(item, 'display')}
                                  className="filter__item"
                                  style={display.value === item ? { background: 'var(--background-grid)' } : null}
                                >
                                  <span>{item}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Popover>
                      </div>
                    </FormControl>
                  </div>
                  <div className="filter__wrapContent">
                    <FormControl variant="outlined">
                      <div className="filter__filter">
                        <div
                          className="filter__btn"
                          aria-describedby={'whiteBoard-request'}
                          onClick={this.handleOpenFilterList('request_company')}
                        >
                          <span className={'filter__mainTitle'}>{requestCompany.value || 'Request company'}</span>
                          <span className={`filter__arrow`}>
                            <SVG src={require('../../../assets/images/arrow_down.svg')} alt="#" />
                          </span>
                        </div>
                        <Popover
                          id={'request_company'}
                          open={this.state.isFilterListOpen === 'request_company'}
                          anchorEl={this.state.anchorElFilter}
                          onClose={this.handleCloseFilterList}
                          disablePortal={true}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div className="filter__listFilters">
                            <div
                              onClick={this.handleChange('All', 'requestCompany')}
                              className="filter__item"
                              style={
                                requestCompany.value === 'All' || requestCompany.value === 'Request company'
                                  ? { background: 'var(--background-grid)' }
                                  : null
                              }
                            >
                              <span>All</span>
                            </div>
                            {filters &&
                              filters.request_parties &&
                              Object.values(filters.request_parties) &&
                              Object.values(filters.request_parties).map((item, i) => {
                                return (
                                  <div
                                    key={`filterParties-${i}`}
                                    onClick={this.handleChange(item, 'requestCompany')}
                                    className="filter__item"
                                    style={requestCompany.value === item ? { background: 'var(--background-grid)' } : null}
                                  >
                                    <span>{item}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </Popover>
                      </div>
                    </FormControl>
                  </div>
                  <div className="filter__wrapContent">
                    <FormControl variant="outlined">
                      <div className="filter__filter">
                        <div
                          className="filter__btn"
                          aria-describedby={'whiteBoard-response'}
                          onClick={this.handleOpenFilterList('response_company')}
                        >
                          <span className={'filter__mainTitle'}>{responseCompany.value || 'Response company'}</span>
                          <span className={`filter__arrow`}>
                            <SVG src={require('../../../assets/images/arrow_down.svg')} alt="#" />
                          </span>
                        </div>
                        <Popover
                          id={'response_company'}
                          open={this.state.isFilterListOpen === 'response_company'}
                          anchorEl={this.state.anchorElFilter}
                          disablePortal={true}
                          onClose={this.handleCloseFilterList}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div className="filter__listFilters">
                            <div
                              onClick={this.handleChange('All', 'responseCompany')}
                              className="filter__item"
                              style={
                                responseCompany.value === 'All' || responseCompany.value === 'Response company'
                                  ? { background: 'var(--background-grid)' }
                                  : null
                              }
                            >
                              <span>All</span>
                            </div>
                            {filters &&
                              filters.response_parties &&
                              Object.values(filters.response_parties) &&
                              Object.values(filters.response_parties).map((item, i) => {
                                return (
                                  <div
                                    key={`filterResponseParties-${i}`}
                                    onClick={this.handleChange(item, 'responseCompany')}
                                    className="filter__item"
                                    style={responseCompany.value === item ? { background: 'var(--background-grid)' } : null}
                                  >
                                    <span>{item}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </Popover>
                      </div>
                    </FormControl>
                  </div>
                  <div className="filter__wrapContent">
                    <FormControl variant="outlined">
                      <div className="filter__filter">
                        <div
                          className="filter__btn"
                          aria-describedby={'whiteBoard-locations'}
                          onClick={this.handleOpenFilterList('location')}
                        >
                          <span className={'filter__mainTitle'}>{location.value || 'Level'}</span>
                          <span className={`filter__arrow`}>
                            <SVG src={require('../../../assets/images/arrow_down.svg')} alt="#" />
                          </span>
                        </div>
                        <Popover
                          id={'location'}
                          open={this.state.isFilterListOpen === 'location'}
                          anchorEl={this.state.anchorElFilter}
                          onClose={this.handleCloseFilterList}
                          disablePortal={true}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <div className="filter__listFilters">
                            <div
                              onClick={this.handleChange('All', 'location')}
                              className="filter__item"
                              style={
                                location.value === 'All' || location.value === 'Level' ? { background: 'var(--background-grid)' } : null
                              }
                            >
                              <span>All</span>
                            </div>
                            {filters && filters.locations && Object.values(filters.locations).length
                              ? this.getBuildingsLevels().map(level => (
                                  <>
                                    <div className={'filter__subtitle'}>{level.title}</div>
                                    {level?.levels.map((it, i) => (
                                      <div
                                        key={`location-${i}`}
                                        onClick={this.handleChange(it.title, 'location')}
                                        className="filter__item"
                                        style={location.value === it.title ? { background: 'var(--background-grid)' } : null}
                                      >
                                        <span>{it.title}</span>
                                      </div>
                                    ))}
                                  </>
                                ))
                              : null}
                          </div>
                        </Popover>
                      </div>
                    </FormControl>
                  </div>
                  <ResetButton title={'Reset filters'} height={30} handleClick={this.handleResetFilter} />
                </FiltersListPopover>
              </div>
            )}
            {!(window.innerWidth < 767 || window.innerWidth < 980) && (
              <button className={`b-whiteBoard__btn -active b-whiteBoard__btn_short`} onClick={this.toggleOpenFilters}>
                Filters
              </button>
            )}
          </div>
        </div>
        <div className={`b-whiteBoard__modeSlider`}>
          <CustomSlider
            steps={{ step1: 'Week view', step2: 'Month view', step3: 'Year view' }}
            onChange={this.setMode}
            openTooltip={true}
            step={mode}
          />
        </div>
        <div className={`b-whiteBoard__btnFull`} onClick={fullScreenOpen ? this.exitFullWidthTable : this.fullWidthTable}>
          <SVG src={require('../../../assets/icons/btnFull.svg')} />
          {/*<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*  <path d="M11 1H20V10" stroke="#8BA1B6" stroke-linecap="round" stroke-linejoin="round"/>*/}
          {/*  <path d="M10 19H1V10" stroke="#8BA1B6" stroke-linecap="round" stroke-linejoin="round"/>*/}
          {/*</svg>*/}
        </div>

        <div className={`l-whiteBoardTable b-table__container ${fullScreenOpen ? 'l-whiteBoardTable__full' : ''}`}>
          <ScrollContainer
            onScroll={this.scroll}
            onEndScroll={this.scroll}
            className={`b-whiteBoardTable indiana-scroll-container ${mode === 'monthMode' ? 'b-whiteBoardTable__monthView' : ''} ${
              mode === 'yearMode' ? 'b-whiteBoardTable__yearView' : ''
            } ${fullScreenOpen ? 'b-whiteBoardTable__full' : ''}`}
            hideScrollbars={false}
            innerRef={this.tableRef}
            component={'div'}
          >
            <div
            // ref={this.tableRef}
            >
              <div>
                {mode === 'monthMode' && (
                  <div
                    className="b-whiteBoard__tableArrowBlockWrapper"
                    style={{ width: `${fullWidth}px`, backgroundColor: 'var(--background-sheet)' }}
                  >
                    <div className="b-whiteBoard__tableArrowDisciplineHead">
                      <span>Commitment discipline</span>
                      <SVG
                        src={
                          filters?.['disciplines']?.filter(f => f.isChecked)?.length
                            ? require('../../../assets/icons/filter_icon_active_2.svg')
                            : require('../../../assets/icons/filter_icon.svg')
                        }
                        alt="#"
                        aria-describedby={'disciplines'}
                        onClick={this.handleOpenFilterList('disciplines')}
                      />
                    </div>
                    {this.state.data?.monthViewSizes?.map((date, index) => {
                      let formattedDate = `${moment(date.date).format('MMMM')}, ${moment(date.date).format('YYYY')}`;
                      if (date.nextMonth) {
                        const formattedNextMonthDate = `${moment(date.nextMonth).format('MMMM')}, ${moment(date.nextMonth).format('YYYY')}`;
                        formattedDate = `${formattedDate} - ${formattedNextMonthDate}`;
                      }

                      return (
                        <div
                          key={`monthViewDate-${formattedDate || index}`}
                          className={`b-whiteBoard__tableArrowBlock ${navigate ? 'l-whiteBoardTable__hidden' : ''}`}
                          style={{
                            backgroundColor: !this.state.data?.monthViewSizes?.length ? 'var(--background-sheet)' : '',
                            minWidth: `${date.countDays * this.zoomStyle.header.columnWidth - 1}px`,
                            width: `${date.countDays * this.zoomStyle.header.columnWidth - 1}px`,
                          }}
                        >
                          <div
                            className="b-whiteBoard__tableArrowBlockText"
                            // onClick={this.handleOpenSelect}
                            ref={this.state.refs[moment(date.date).format('YYYY-MMM')]}
                          >
                            <span>{formattedDate}</span>
                            {/*{moment(`${currentYear}-${currentMonth}`, 'YYYY-MM').format('MMMM')}, {currentYear}*/}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {mode === 'weekMode' && (
                  <div
                    className="b-whiteBoard__tableArrowBlockWrapper"
                    style={{ width: `${fullWidth}px`, backgroundColor: 'var(--background-sheet)' }}
                  >
                    <div className="b-whiteBoard__tableArrowDisciplineHead">
                      <span>Commitment discipline</span>
                      <SVG
                        src={
                          filters?.['disciplines']?.filter(f => f.isChecked)?.length
                            ? require('../../../assets/icons/filter_icon_active_2.svg')
                            : require('../../../assets/icons/filter_icon.svg')
                        }
                        alt="#"
                        aria-describedby={'disciplines'}
                        onClick={this.handleOpenFilterList('disciplines')}
                      />
                    </div>
                    {this.state.data?.weekSizes?.map((date, index) => {
                      let formattedDate = `${moment(date.date).format('MMMM')}, ${moment(date.date).format('YYYY')}`;
                      if (date.nextMonth) {
                        const formattedNextMonthDate = `${moment(date.nextMonth).format('MMMM')}, ${moment(date.nextMonth).format('YYYY')}`;
                        formattedDate = `${formattedDate} - ${formattedNextMonthDate}`;
                      }

                      return (
                        <div
                          key={`monthViewDate-${index}`}
                          className={`b-whiteBoard__tableArrowBlock ${navigate ? 'l-whiteBoardTable__hidden' : ''}`}
                          style={{
                            backgroundColor: !this.state.data?.monthViewSizes?.length ? 'var(--background-sheet)' : '',
                            minWidth: `${date.countDays * this.zoomStyle.header.columnWidth - 1}px`,
                            width: `${date.countDays * this.zoomStyle.header.columnWidth - 1}px`,
                          }}
                        >
                          <div
                            className="b-whiteBoard__tableArrowBlockText"
                            // onClick={this.handleOpenSelect}
                            ref={this.state.refs[moment(date.date).format('YYYY-MM-DD')]}
                          >
                            <span>
                              {this.replaceNumberWeeks(this.state.data?.weekOrder?.columnOrder[index]?.replace(/.+-/, ''))} {`week`},{' '}
                              {formattedDate}
                            </span>
                            {/*{moment(`${currentYear}-${currentMonth}`, 'YYYY-MM').format('MMMM')}, {currentYear}*/}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {mode === 'yearMode' && (
                  <div
                    className="b-whiteBoard__tableArrowBlockWrapper"
                    style={{ width: `${fullWidth}px`, backgroundColor: 'var(--background-sheet)' }}
                  >
                    <div className="b-whiteBoard__tableArrowDisciplineHead">
                      <span>Commitment discipline</span>
                      <SVG
                        src={
                          filters?.['disciplines']?.filter(f => f.isChecked)?.length
                            ? require('../../../assets/icons/filter_icon_active_2.svg')
                            : require('../../../assets/icons/filter_icon.svg')
                        }
                        alt="#"
                        aria-describedby={'disciplines'}
                        onClick={this.handleOpenFilterList('disciplines')}
                      />
                    </div>
                    <div
                      className="b-whiteBoard__tableArrowBlock"
                      style={{
                        backgroundColor: !this.state.data?.monthViewSizes?.length ? 'var(--background-sheet)' : '',
                        borderBottom: !this.state.data?.monthViewSizes?.length ? '1px solid #1d2230' : '',
                        height: !this.state.data?.monthViewSizes?.length ? '39px' : '',
                      }}
                    >
                      {this.state.data?.monthViewSizes?.map((date, index) => {
                        return (
                          <div
                            key={`yearViewDate-${date.date || index}`}
                            className="b-whiteBoard__tableArrowBlock"
                            style={{
                              width: `${date.countDays * this.zoomStyle.header.columnWidth - 1}px`,
                            }}
                          >
                            <div
                              className="b-whiteBoard__tableArrowBlockText"
                              // onClick={this.handleOpenSelect}
                            >
                              <span>{`${date.date ? moment(date.date).format('YYYY') : ''}`}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <Popover
                  id={'disciplines'}
                  open={this.state.isFilterListOpen === 'disciplines'}
                  anchorEl={this.state.anchorElFilter}
                  onClose={this.handleCloseFilterList}
                  disablePortal={true}
                  style={{ maxHeight: '1000px' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className="b-whiteBoard__popoverColumns">
                    {filters?.disciplines &&
                      filters.disciplines.map((item, i) => {
                        return (
                          <div
                            key={`disciplines-${item.value}`}
                            onClick={this.handleCheckDiscipline(item.value, 'disciplines')}
                            className="popoverColumnItem"
                            style={requestCompany === item ? { background: '#2d323f' } : null}
                          >
                            <Checkbox checked={item.isChecked} />
                            <span>{item.title}</span>
                          </div>
                        );
                      })}
                  </div>
                </Popover>
                <WhiteBoardtableHead
                  zoomStyle={zoomStyles}
                  columnOrder={data.columnOrder}
                  columns={data.columns}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                  mode={mode}
                  onMonth={this.onMonth}
                  fullWidth={this.state.fullWidth}
                  todayRef={this.todayRef}
                  isNonFullWidth={isNonFullWidth}
                  zoom={zoom}
                  rangeElementsLogic={rangeElementsLogic}
                  fullTableWidth={fullWidth}
                />
                <div
                  ref={this.tableWrapperRef}
                  style={{
                    width:
                      mode === 'monthMode'
                        ? `${fullWidth}px`
                        : isNonFullWidth
                        ? fullWidthYearMode === 0
                          ? 'auto'
                          : this.state.filters?.['disciplines']?.find(f => f.isChecked === true)
                          ? 'max-content'
                          : `${fullWidthYearMode + 100}px`
                        : // `calc(${this.tableRef?.current?.scrollWidth}px)`
                        // 'auto'fullWidthYearMode
                        this.state.filters?.['disciplines']?.find(f => f.isChecked === true)
                        ? 'max-content'
                        : `${fullWidthYearMode + 100}px`,
                    minHeight: 'calc(100svh - 220px)',
                    backgroundPosition: '1px -9px',
                  }}
                  className={'b-whiteBoard__wrapContentRow'}
                >
                  {/*<ArcherContainer strokeColor='white' ref={this.archerEl}>*/}
                  <div className={'b-whiteBoard__wrapContentImageBg'}>
                    <WhiteBoardContext.Provider value={{ relations: this.state.relations, availableItems: this.state.availableItems }}>
                      {whiteBoard &&
                        whiteBoard.map((discipline, index) => {
                          const item = this.state.filters?.['disciplines']?.find(d => d.value === discipline.id);
                          if (!item?.isChecked) {
                            return <></>;
                          }
                          return (
                            <WhiteBoardtableRow
                              index={index}
                              updateAvailableItems={this.updateAvailableItems}
                              key={`discipline-${discipline.id}`}
                              columnsData={data}
                              rowData={discipline}
                              saveRequestAction={saveRequestAction}
                              saveResponseAction={saveResponseAction}
                              fileUpload={fileUpload}
                              filesData={filesData}
                              ufcCode={ufcCode}
                              locations={locations}
                              projectParties={projectParties}
                              findPredecessor={this.findPredecessor}
                              displayPredecessor={this.state.displayPredecessor}
                              displaySuccessors={this.state.displaySuccessors}
                              mouseOutFromRequest={this.mouseOutFromRequest}
                              zoomStyle={zoomStyles.request}
                              zoom={zoom}
                              display={display}
                              mode={mode}
                              isShowAll={this.state.isShowAll}
                              login_user={this.state.login_user}
                              rowRef={this.rowRef}
                              location={location}
                              responseCompany={responseCompany}
                              requestCompany={requestCompany}
                              checkRequestInList={this.checkRequestInList}
                              setMode={this.setMode}
                              reset={this.state.reset}
                              loadRequest={this.props.loadRequest}
                              requestInfo={this.props.requestInfo}
                              onAddRelations={this.onAddRelations}
                              onRemoveRelations={this.onRemoveRelations}
                              archerEl={this.archerEl}
                              fullWidth={this.state.fullWidth}
                              isNonFullWidth={isNonFullWidth}
                              fullTableWidth={fullWidth}
                              rangeElementsLogic={rangeElementsLogic}
                              filters={filters}
                              applyFiltersFromCard={this.applyFiltersFromCard}
                            />
                          );
                        })}
                    </WhiteBoardContext.Provider>
                  </div>
                  {/*</ArcherContainer>*/}
                </div>

                <Popover
                  id={`${this.state.isOpenedSelect}`}
                  open={this.state.isOpenedSelect}
                  anchorEl={this.state.anchorEl}
                  disablePortal={true}
                  onClose={this.handleCloseSelect}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div style={{ backgroundColor: '#5d678d', width: '115px', textAlign: 'center' }}>
                    {
                      <MenuItem style={{ pointerEvents: 'none', borderBottom: '1px solid rgba(255, 255, 255, 0.1)', padding: '3px' }}>
                        <ListItemText style={{ textAlign: 'center', padding: '0' }}>
                          <Typography style={{ fontSize: '11px' }}>{mode === 'monthMode' ? 'Select Month' : 'Select Year'}</Typography>
                        </ListItemText>
                      </MenuItem>
                    }
                    {this.state.isOpenedSelect &&
                      mode === 'monthMode' &&
                      this.months.map(item => {
                        return (
                          <div key={`monthMode-${item.value}`} className={'NeedListSelectItemFilter'}>
                            <MenuItem value={item.value} onClick={() => this.handleChangeSelect(item.value)} style={{ padding: '3px' }}>
                              <ListItemText style={{ textAlign: 'center', padding: '0', textTransform: 'uppercase' }}>
                                <Typography style={{ fontSize: '11px' }}>{item.title}</Typography>
                              </ListItemText>
                            </MenuItem>
                          </div>
                        );
                      })}
                    {this.state.isOpenedSelect &&
                      mode === 'yearMode' &&
                      this.years.map(item => {
                        return (
                          <div key={`yearMode-${item.id}`} className={'NeedListSelectItemFilter'}>
                            <MenuItem value={item.value} onClick={() => this.handleChangeSelect(item.value)} style={{ padding: '3px' }}>
                              <ListItemText style={{ textAlign: 'center', padding: '0' }}>
                                <Typography style={{ fontSize: '11px' }}>{item.title}</Typography>
                              </ListItemText>
                            </MenuItem>
                          </div>
                        );
                      })}
                  </div>
                </Popover>
              </div>
            </div>
          </ScrollContainer>
        </div>
      </div>
    );
  }
}

WhiteBoard.displayName = 'WhiteBoard';

WhiteBoard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connector)(WhiteBoard);
