import React, { useEffect, useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import CustomTooltip from '../Tooltip/Tooltip';
import CheckBox from '../CheckBox/CheckBox';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import StatusIncorporation from '../Status/StatusIncorporation/StatusIncorporation';
import PlaceholderInfo from '../PlaceholderInfo/PlaceholderInfo';
import DateField from '../DateField/DateField';
import {
  NfFilterDisciplineModel,
  RequestBuildingModel,
  RequestModel,
  SelectedRequestCompanyModel,
  UfMfValueModel,
  UserModel,
} from '../../../models';
import Divider from '../Divider/Divider';
import CustomButton from '../ButtonComponents/CustomButton/CustomButton';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import moment from 'moment/moment';
import TextInputNew from '../TextInputNew/TextInputNew';
import PartySelector from '../PartySelector/PartySelector';
import { IncorporationTaskModel, UpdateTaskModel } from '../../../store/incorporation/incorporationReducer.model';
import { CodeSelector } from '../Dropdowns/DropdownСomponents';
import { checkedItemsModel } from '../../common/CardRequest/tabs/Incorporation/IncorporationTab';
import AccessHelper from '../../../helpers/AccessHelper';
import RedmineIncorporation from '../RedmineIncorporation/RedmineIncorporation';

import './IncorporationMainSnippetView.module.scss';
import './IncorporationMainSnippetEdit.module.scss';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;
type mainTaskEditTemplateErrors = 'company' | 'uf_ids';

interface IProps<T> {
  data: IncorporationTaskModel;
  isDragRequestCard: boolean;
  isSizeResolution640?: boolean;
  isEditMode?: boolean;
  work_week?: number;
  selectParty: (item: SelectedRequestCompanyModel) => void;
  errors: mainTaskEditTemplateErrors[];
  incorporationListHaveScroll?: boolean;
  handleExpandItem: (id: number) => void;
  openedItems: number[];
  handleCheckTask: (data: IncorporationTaskModel) => void;
  checkedTasks: checkedItemsModel;
  handleChangeTaskStatus: (id: number, type: 'incorporated' | 'discarded') => void;
  handleChangeUfCode: (id: number, fieldName: string, selected: SelectedValue<UfMfValueModel, true>) => void;
  handleChangeNote: (value: string | number) => void;
  handleDeleteItem: (type: 'task' | 'subTask', id: number) => void;
  userInfo: UserModel;
  isAccess: boolean;
  isDeclinedNfCard: boolean;
  isMobile: boolean;
  editableList: UpdateTaskModel[];
  selectedRequest: RequestModel;
  buildings: RequestBuildingModel[];
  handleSaveEditableList: () => void;
}

const statusTitle = {
  not_started: 'Incorporation not started',
  in_progress: 'Incorporation in progress',
  completed: 'Incorporation completed',
  not_required: 'Incorporation not required',
};

function IncorporationMainSnippet<T>({
  data,
  isDragRequestCard,
  isSizeResolution640 = false,
  isEditMode = false,
  selectParty,
  handleChangeNote,
  errors,
  incorporationListHaveScroll,
  handleExpandItem,
  openedItems,
  handleCheckTask,
  checkedTasks,
  handleChangeTaskStatus,
  handleChangeUfCode,
  handleDeleteItem,
  userInfo,
  isAccess,
  isDeclinedNfCard,
  isMobile = false,
  editableList,
  selectedRequest,
  handleSaveEditableList,
  buildings,
}: IProps<T>) {
  const themeClassView = useGetThemeClass('incorporation-main-snippet-view');
  const themeClassEdit = useGetThemeClass('incorporation-main-snippet-edit');
  const { sizeResolution, isTablet } = GetResolution();

  const [isHover, setIsHover] = useState(false);
  const [focusInputName, setFocusInputName] = useState<'note' | ''>('');
  const [currentUserId, setCurrentUserId] = useState(null);

  const [first_name, last_name] = data?.user_title?.split(' ') || ['', ''];

  useEffect(() => {
    if (isEditMode && !!data?.selectedParty?.company?.id && !currentUserId) {
      setCurrentUserId(data?.selectedParty?.company?.id);
      return;
    }
    if (!isEditMode) {
      setCurrentUserId(null);
    }
  }, [isEditMode]);

  const isAccessToEditIncorporation = AccessHelper.isAccessToEditIncorporation(userInfo, isEditMode ? currentUserId : data?.party_id);

  const renderDateBlockContent = () => {
    return (
      <div className={`${themeClassView}_dateBlock`}>
        {!isMobile && <div className={`${themeClassView}_title`}>Due date</div>}
        <DateField
          selectedDate={data?.due_date ? moment(data?.due_date).format('MM/DD/YYYY') : ''}
          isSentRequestToChange={false}
          isOpenCalendar={false}
          isDiffDueScheduledDate={false}
          info={false}
          disabled={true}
          isBefore={false}
          error={false}
          valueIsDate={!!data?.due_date}
          isDisableBorder={true}
        />
      </div>
    );
  };

  const renderScopeBlockContent = () => {
    return (
      <div className={`${themeClassView}_scopeBlock`}>
        <div className={`${themeClassView}_title`}>Scope</div>
        <div className={`${themeClassView}_scopeBlock_selector`}>
          <CustomTooltip
            title={(data?.uf?.length && data.uf.length > 2 && data?.uf.map(item => item.code).join(', ')) || ''}
            placement={'top'}
          >
            <CodeSelector
              isMulti={true}
              codeType={'uf'}
              isValuesRemovable={false}
              selected={data?.uf}
              selectHandler={() => null}
              basisPlaceholder={''}
              menuItemSize={'md'}
              basisMaxItems={3}
              handleFor={'content-plan'}
              disabled={true}
              disableRightElem={true}
            />
          </CustomTooltip>
        </div>
      </div>
    );
  };

  const renderAccordion = () => {
    return (
      <SystemButton
        type={openedItems?.includes(data.id) ? 'chevron-down' : 'chevron-right'}
        variant={'transparent'}
        size={'md'}
        clickHandler={() => handleExpandItem(data.id)}
        isMobile={isMobile}
      />
    );
  };

  const renderCheckBox = () => {
    return (
      <div className={`${themeClassView}_checkBox`}>
        {(((isMobile || isHover) && !!data?.subtasks.filter(item => item.status === 'pending').length) ||
          !!checkedTasks[data?.id]?.length) && (
          <CheckBox
            isTripleState
            important={
              (!!checkedTasks[data?.id]?.length &&
                (checkedTasks[data?.id]?.length === data?.subtasks?.filter(item => item.status === 'pending').length ? 2 : 1)) ||
              0
            }
            onClick={() => handleCheckTask(data)}
            disabled={isDeclinedNfCard || !(isAccessToEditIncorporation || isAccess)}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {!isEditMode ? (
        <CustomTooltip title={''} placement={'top'}>
          <div
            className={cn(`${themeClassView}`, {
              ['-disabled']: false,
              ['-sidePeek']: isDragRequestCard || isTablet,
              ['-sidePeek640']: !isMobile && isSizeResolution640,
              ['-scroll']: incorporationListHaveScroll,
            })}
            // onMouseEnter={() => setIsHover(true)}
            // onMouseLeave={() => setIsHover(false)}
            // onTouchStart={() => setIsHover(true)}
            // onTouchEnd={() => setIsHover(false)}
          >
            <div className={`${themeClassView}_statusBlock`}>
              {isMobile && (
                <div className={`${themeClassView}_statusBlock_left`}>
                  {renderAccordion()}
                  {renderCheckBox()}
                </div>
              )}
              {!isMobile && renderAccordion()}
              {!isMobile && renderCheckBox()}

              <StatusIncorporation
                status={data?.status}
                label={statusTitle[data?.status]}
                isVisibleLabel={!(isDragRequestCard || isTablet)}
                isDragRequestCard={isDragRequestCard || isTablet}
                tooltip={isDragRequestCard || isTablet ? statusTitle[data?.status] : ''}
                counter={
                  data?.status === 'in_progress'
                    ? `(${data.subtasks.filter(item => item.status !== 'pending').length}/${data.subtasks?.length})`
                    : ''
                }
              />
            </div>
            {isMobile && renderDateBlockContent()}
            {isMobile && renderScopeBlockContent()}
            <div className={`${themeClassView}_assigneeBlock`}>
              {!isDragRequestCard && !isTablet && <div className={`${themeClassView}_title`}>Assignee</div>}
              <div className={`${themeClassView}_userInfo`}>
                {first_name && (
                  <div className={`user`}>
                    <PlaceholderInfo
                      type={'Persona'}
                      firstName={first_name}
                      lastName={last_name}
                      detailInfo={`${data?.user_title || ''}`}
                      imagePath={data?.user_image}
                      size={24}
                      isMobile={isMobile}
                    />
                  </div>
                )}
                <div className={`company`}>
                  <PlaceholderInfo
                    type={'Company'}
                    firstName={data?.party_title || ''}
                    lastName={data?.party_title || ''}
                    detailInfo={data?.party_title || ''}
                    size={24}
                    imagePath={data?.party_image || ''}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className={`${themeClassView}_date_scope`}>
                {renderDateBlockContent()}
                {renderScopeBlockContent()}
                <RedmineIncorporation
                  data={data}
                  buildings={buildings}
                  editableList={editableList}
                  handleChangeUfCode={handleChangeUfCode}
                  errors={errors}
                  selectedRequest={selectedRequest}
                  handleSaveEditableList={handleSaveEditableList}
                />
              </div>
            )}
            <div className={`${themeClassView}_note_buttons`}>
              <SystemButton tooltip={data?.note || 'empty note'} type={'information'} size={'md'} variant={'transparent'} />
              <Divider direction={'vertical'} type={'srf-4'} customClassname={`${themeClassView}_divider`} />
              {isDragRequestCard || isTablet ? (
                <SystemButton
                  tooltip={'Discard'}
                  type={'dash-square'}
                  size={'md'}
                  variant={'transparent'}
                  disabled={
                    isDeclinedNfCard ||
                    !(isAccessToEditIncorporation || isAccess) ||
                    data?.status === 'not_required' ||
                    data?.status === 'completed'
                  }
                  clickHandler={() => handleChangeTaskStatus(data.id, 'discarded')}
                />
              ) : (
                <CustomButton
                  type={'text-plain'}
                  size={'md'}
                  title={'Discard'}
                  disabled={
                    isDeclinedNfCard ||
                    !(isAccessToEditIncorporation || isAccess) ||
                    data?.status === 'not_required' ||
                    data?.status === 'completed'
                  }
                  clickHandler={() => handleChangeTaskStatus(data.id, 'discarded')}
                />
              )}
              {isDragRequestCard || isTablet ? (
                <SystemButton
                  tooltip={'Incorporate'}
                  type={'plus-square'}
                  size={'md'}
                  variant={'transparent'}
                  disabled={
                    isDeclinedNfCard ||
                    !(isAccessToEditIncorporation || isAccess) ||
                    data?.status === 'not_required' ||
                    data?.status === 'completed'
                  }
                  clickHandler={() => handleChangeTaskStatus(data.id, 'incorporated')}
                />
              ) : checkedTasks && checkedTasks[data?.id]?.length ? (
                <CustomButton
                  type={'primary'}
                  size={'md'}
                  title={'Incorporate'}
                  disabled={
                    isDeclinedNfCard ||
                    !(isAccessToEditIncorporation || isAccess) ||
                    data?.status === 'not_required' ||
                    data?.status === 'completed'
                  }
                  clickHandler={() => handleChangeTaskStatus(data.id, 'incorporated')}
                />
              ) : (
                <CustomButton
                  type={'text-plain'}
                  size={'md'}
                  title={'Incorporate'}
                  disabled={
                    isDeclinedNfCard ||
                    !(isAccessToEditIncorporation || isAccess) ||
                    data?.status === 'not_required' ||
                    data?.status === 'completed'
                  }
                  clickHandler={() => handleChangeTaskStatus(data.id, 'incorporated')}
                />
              )}
            </div>
          </div>
        </CustomTooltip>
      ) : (
        <>
          <CustomTooltip title={''} placement={'top'}>
            <div
              className={cn(`${themeClassEdit}`, {
                ['-disabled']: false,
                ['-sidePeek']: isDragRequestCard || isTablet,
                ['-sidePeek640']: isSizeResolution640,
                ['-scroll']: incorporationListHaveScroll,
              })}
            >
              <div className={`${themeClassEdit}_status_user`}>
                <SystemButton
                  type={openedItems?.includes(data.id) ? 'chevron-down' : 'chevron-right'}
                  variant={'transparent'}
                  size={'md'}
                  clickHandler={() => handleExpandItem(data.id)}
                />
                <StatusIncorporation
                  status={data?.status}
                  label={statusTitle[data?.status]}
                  isVisibleLabel={false}
                  isDragRequestCard={isDragRequestCard || isTablet}
                  tooltip={isSizeResolution640 ? statusTitle[data?.status] : sizeResolution !== '640' ? '' : statusTitle[data?.status]}
                />

                <div className={`${themeClassEdit}_assigneeBlock`}>
                  <div className={`${themeClassEdit}_title`}>Assignee</div>
                  <PartySelector
                    selected={data?.selectedParty}
                    handleSelect={item => selectParty(item)}
                    isDisableSelect={!(isAccessToEditIncorporation || isAccess)}
                    errors={(!data?.selectedParty?.company?.id && errors) || null}
                    customPartyContainerClassName={`${themeClassEdit}_assigneeBlock_selector`}
                    customSelectBasisFieldClass={`${themeClassEdit}_assigneeBlock_selector_selectField`}
                  />
                </div>
              </div>
              <div className={`${themeClassEdit}_dateBlock`}>
                {/*{(data.status === 'in_progress' || data.status === 'not_started') && (*/}
                <>
                  <DateField
                    selectedDate={data?.due_date ? moment(data?.due_date).format('MM/DD/YYYY') : ''}
                    isSentRequestToChange={false}
                    isOpenCalendar={false}
                    isDiffDueScheduledDate={false}
                    info={false}
                    disabled={true}
                    isBefore={false}
                    error={false}
                    valueIsDate={!!data?.due_date}
                    isDisableBorder={true}
                  />
                  {!data?.due_date && (data?.status === 'in_progress' || data?.status === 'not_started') && (
                    <SystemButton
                      tooltip={'The due date will be calculated automatically after saving'}
                      type={'information'}
                      size={'md'}
                      variant={'transparent'}
                    />
                  )}
                </>
                {/*)}*/}
              </div>
              <div className={`${themeClassEdit}_scopeBlock`}>
                <div className={`${themeClassEdit}_title`}>Scope</div>
                <div className={`${themeClassEdit}_scopeBlock_selector`}>
                  <CustomTooltip
                    title={(data?.uf_ids?.length && data.uf_ids.length > 2 && data?.uf_ids.map(item => item.code).join(', ')) || ''}
                    placement={'top'}
                  >
                    <CodeSelector
                      isMulti={true}
                      codeType={'uf'}
                      isValuesRemovable
                      selected={data?.uf_ids}
                      selectHandler={items => handleChangeUfCode(data.id, 'uf_ids', items)}
                      error={!data?.uf_ids?.length && !!errors?.includes('uf_ids')}
                      basisPlaceholder={`Select UniFormat`}
                      menuItemSize={'md'}
                      basisMaxItems={2}
                      handleFor={'content-plan'}
                      disabled={!(isAccessToEditIncorporation || isAccess)}
                    />
                  </CustomTooltip>
                </div>
              </div>
              <div className={`${themeClassEdit}_deleteIcon`}>
                <SystemButton
                  type={'delete'}
                  size={'md'}
                  variant={'transparent'}
                  clickHandler={() => handleDeleteItem('task', data.id)}
                  disabled={!(isAccessToEditIncorporation || isAccess)}
                />
              </div>
              <div className={`${themeClassEdit}_noteBlock`}>
                <div className={`${themeClassEdit}_title`}>Note</div>
                <div className={`${themeClassEdit}_noteBlock_input`}>
                  <TextInputNew
                    type={'on-bgd'}
                    placeholder={'Please incorporate into CL model'}
                    onChange={value => handleChangeNote(value)}
                    value={data?.note}
                    error={''}
                    inputType={'text'}
                    isFocus={focusInputName === 'note'}
                    onFocus={() => setFocusInputName('note')}
                    onBlur={() => setFocusInputName('')}
                    disabled={!(isAccessToEditIncorporation || isAccess)}
                  />
                </div>
              </div>
            </div>
          </CustomTooltip>
        </>
      )}
    </>
  );
}

export default IncorporationMainSnippet;
