import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { useGetThemeClass } from '../../../helpers/designTokens';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import Divider from '../../controls/Divider/Divider';
import { BuildingModel, FileModel, FileUploadedModel, SimpleTitleModel } from '../../../models';
import { FILE_SIZE_LIMIT, getFileSizeName } from '../../../service/file';
import { isEmptyObject } from '../../../helpers/commonHelpers';
import { ucFirst } from '../../../helpers/commonHelpers';
import {
  constructionDriverDownloadFile,
  getBuildingTemplate,
  getContentPlanTemplate,
  getCurrentProjectTemplate,
  getSpecificationTemplate,
  importBuildingSpaces,
  importConstructionDriver,
  importContentPlan,
  importNeedList,
  importSpecification,
  validateBuildingSpacesImport,
  validateConstructionDriverImport,
  validateContentPlanImport,
  validateNeedListImport,
  validateSpecificationImport,
} from '../../../store/common/commonThunk';
import { commonSlice } from '../../../store/common/commonReducer';
import { filesUpload } from '../../../store/globalSettings/settingsThunk';
import { settingsSlice } from '../../../store/globalSettings/settingsSlice';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
const { deleteFile, filesUploadClean } = settingsSlice.actions;
const { clearImportResult } = commonSlice.actions;
import { getSpecTableData, getSpecTableListActive } from '../../../store/specAndSubs/specAndSubsThunks';

import './DataImportStyles.scss';

interface IProps {
  importName: string;
  isImportOpen: boolean;
  closeImportPopUp: () => void;
  importType: string;
  building?: BuildingModel<SimpleTitleModel[]>;
  project_id?: number;
  getImportUFCData?: () => void;
  getDataAfterImport?: () => void;
}

const DataImport: FC<IProps> = ({
  importName,
  isImportOpen,
  closeImportPopUp,
  importType,
  building,
  project_id,
  getImportUFCData,
  getDataAfterImport,
}) => {
  const dispatch = useAppDispatch();

  const themeClass = useGetThemeClass('b-dataImport');

  const filesData = useAppSelector(state => state.settingsReducer.files);
  const fileLoading = useAppSelector(state => state.settingsReducer.fileLoading);
  const validation = useAppSelector(state => state.commonReducer.validation);
  const importLoading = useAppSelector(state => state.commonReducer.loading);
  const importErrorMessageData = useAppSelector(state => state.commonReducer.message);

  const [file, setFile] = useState<FileUploadedModel | null>(null);
  const [errorList, setErrorList] = useState<string[]>(null);
  const [warningList, setWarningList] = useState<string[]>(null);
  const [importErrorMessage, setImportErrorMessage] = useState<string>('');
  const [animationBtn, setAnimationBtn] = useState<string>('');
  const [animationLoading, setAnimationLoading] = useState<boolean>(false);

  const fileInputRef = useRef(null);

  const handleGetCurrentProjectTemplate = () => {
    const nowDate = new Date().toISOString().slice(0, 19);
    const project_name = sessionStorage.getItem('project_name');

    if (animationLoading) {
      return;
    }

    if (importType === 'needs-list') {
      const id = Number(sessionStorage.getItem('active_project_id'));
      dispatch(getCurrentProjectTemplate({ id: id, filename: `need_list_project_${project_name}_import_template_${nowDate}.xlsx` }));
    }
    if (importType === 'building-spaces') {
      dispatch(
        getBuildingTemplate({
          filename: `building_project_${project_name}_import_template_${nowDate}.xlsx`,
          id: building.id,
        }),
      );
    }
    if (importType === 'content-plan') {
      dispatch(
        getContentPlanTemplate({
          filename: `content_plan_project_${project_name}_import_template_${nowDate}.xlsx`,
          id: building,
        }),
      );
    }
    if (importType === 'specification') {
      const id = Number(sessionStorage.getItem('active_project_id'));
      dispatch(
        getSpecificationTemplate({
          filename: `specification_project_${project_name}_import_template_${nowDate}.xlsx`,
          id: id,
        }),
      );
    }

    if (importType === 'construction-driver') {
      dispatch(
        constructionDriverDownloadFile({
          filename: `construction-schedule-drivers_project_${project_name}_import_template_${nowDate}.xlsx`,
          buildingId: building,
        }),
      );
    }

    setAnimationBtn('template');
    setAnimationLoading(true);
  };

  const handleSendImportData = () => {
    if (animationLoading) {
      return;
    }

    if (importType === 'needs-list') {
      dispatch(importNeedList(validation.filepath));
    }
    if (importType === 'building-spaces') {
      dispatch(
        importBuildingSpaces({
          filepath: validation.filepath,
          id: building.id,
        }),
      );
    }
    if (importType === 'content-plan') {
      dispatch(
        importContentPlan({
          filepath: validation.filepath,
          id: building,
        }),
      );
    }
    if (importType === 'specification') {
      dispatch(importSpecification(validation.filepath));
    }

    if (importType === 'construction-driver') {
      dispatch(importConstructionDriver({ data: validation.filepath, buildingId: building }));
    }

    setAnimationBtn('import');
    setAnimationLoading(true);
  };

  const handleFile = async (e: Event) => {
    const files: FileModel[] = Array.from(e.target.files).filter(f => f?.size < FILE_SIZE_LIMIT);

    if (animationLoading || !files || isEmptyObject(files)) {
      return;
    }

    dispatch(filesUploadClean({ type: 'import' }));
    dispatch(clearImportResult());

    setFile(null);
    setErrorList(null);
    setWarningList(null);
    setImportErrorMessage('');

    files &&
      files.map(i => {
        dispatch(filesUpload({ file: i, source: 'import' }));
      });
    fileInputRef.current.value = '';

    setAnimationBtn('fileUploadBtn');
    setAnimationLoading(true);
  };

  const delFile = filename => {
    dispatch(deleteFile({ data: filename, sourse: 'import' }));
    dispatch(clearImportResult());

    setFile(null);
    setErrorList(null);
    setWarningList(null);
    setImportErrorMessage('');
    setAnimationBtn('fileDelBtn');
  };

  const handleValidateFile = () => {
    if (animationLoading) {
      return;
    }

    if (!isEmptyObject(file)) {
      if (importType === 'needs-list') {
        dispatch(validateNeedListImport(file));
      }
      if (importType === 'building-spaces') {
        dispatch(
          validateBuildingSpacesImport({
            data: file,
            id: building.id,
          }),
        );
      }
      if (importType === 'content-plan') {
        dispatch(
          validateContentPlanImport({
            data: file,
            id: building,
          }),
        );
      }
      if (importType === 'specification') {
        dispatch(validateSpecificationImport(file));
      }

      if (importType === 'construction-driver') {
        dispatch(validateConstructionDriverImport({ data: file, buildingId: building }));
      }

      setAnimationBtn('validation');
      setAnimationLoading(true);
    }
  };

  const handleCloseImportPopUp = () => {
    dispatch(clearImportResult());
    dispatch(filesUploadClean({ type: 'import' }));
    closeImportPopUp && closeImportPopUp();

    setFile(null);
    setErrorList(null);
    setWarningList(null);
    setImportErrorMessage('');

    setAnimationLoading(false);
    setAnimationBtn('');
  };

  const handleOpenFileUpload = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (filesData.import && filesData.import.data) {
      let files = Object.values(filesData.import.data);
      files = files.map(file => {
        return {
          ...file,
          name: file.original_name,
          file_path: file.filepath,
          file_name: file.original_name,
        };
      });

      if (files.length > 1) {
        delFile(files[0].file_name);
        setFile(files[1]);
      }
      setFile(files[0]);
    }
  }, [filesData]);

  useEffect(() => {
    if (validation && isImportOpen) {
      if (validation.message) {
        setImportErrorMessage(validation.message);
      } else {
        const formatList = list => {
          return Object.entries(list).reduce((accumulator, listString) => {
            const stringNumber = listString[0];
            const listStrings = Object.entries(listString[1]).map(listCell => {
              return `${listCell[1]} (${listCell[0]}${stringNumber})`;
            });
            return [...accumulator, ...listStrings];
          }, []);
        };

        const errors = formatList(validation.validator);
        const warnings = formatList(validation.warning);

        setErrorList(errors);
        setWarningList(warnings);
        setAnimationBtn('');
      }
    }
  }, [validation]);

  useEffect(() => {
    if (!importLoading && importErrorMessageData === 'advance' && isImportOpen) {
      if (importType === 'building-spaces') {
        dispatch(getProjectById(project_id));
      }
      if (importType === 'content-plan') {
        getImportUFCData();
      }
      if (importType === 'specification') {
        const active_project_id = Number(sessionStorage.getItem('active_project_id'));
        if (active_project_id) {
          dispatch(getSpecTableData({ project_id: active_project_id, code_affiliation: 'mf' }));
          dispatch(getSpecTableListActive({ project_id: active_project_id, code_affiliation: 'mf' }));
        }
      }
      if (importType === 'construction-driver') {
        getDataAfterImport();
      }

      dispatch(filesUploadClean({ type: 'import' }));
      closeImportPopUp();
      dispatch(clearImportResult());

      setFile(null);
      setErrorList(null);
      setWarningList(null);
    }
  }, [importErrorMessageData]);

  useEffect(() => {
    if (!importLoading) {
      setTimeout(() => {
        setAnimationLoading(false);
      }, 500);
    }
  }, [importLoading]);

  useEffect(() => {
    if (file && !fileLoading) {
      setAnimationLoading(false);
    }
  }, [file]);

  useEffect(() => {
    if (!animationLoading) {
      if (animationBtn === 'fileUploadBtn') {
        handleValidateFile();
      }
    }
  }, [animationLoading]);

  return (
    <>
      <DialogPopUp open={isImportOpen} onClose={handleCloseImportPopUp} isOverlay={true} isHighResolution>
        <div className={`${themeClass}`}>
          <div className={`${themeClass}_header`}>
            <div className={`${themeClass}_header_title`}>Import custom data - {importName}</div>
            <div className={'svg_icon'}>
              <TokenIcon iconName={'close'} size={16} clickHandler={handleCloseImportPopUp} />
            </div>
          </div>
          <div className={`${themeClass}_container`}>
            <div className={`${themeClass}_btn_load_container`}>
              <CustomButton
                icon={<TokenIcon iconName={'download'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={handleGetCurrentProjectTemplate}
                title={'Download a file template'}
                iconClass={`${themeClass}_text-plain`}
                loading={animationBtn === 'template' && animationLoading}
              />
              <div className={`${themeClass}_divider_btns`}>
                <Divider direction={'vertical'} type={'srf-5'} />
              </div>
              <CustomButton
                icon={<TokenIcon iconName={'upload'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={handleOpenFileUpload}
                title={'Upload file'}
                iconClass={`${themeClass}_text-plain`}
                disabled={animationBtn === 'fileUploadBtn' && animationLoading}
                loading={animationBtn === 'fileUploadBtn' && animationLoading}
              />
              <input
                ref={fileInputRef}
                accept={'.xlsx, .xls, .csv'}
                style={{ display: 'none' }}
                id={`raised-button-file-data-import`}
                type="file"
                onChange={handleFile}
                disabled={false}
              />
            </div>
            {!isEmptyObject(file) && (
              <div className={`${themeClass}_fileItemBlock`}>
                <div className={`${themeClass}_fileItemBlock_fileRow`}>
                  <div className={`leftContent`}>
                    <TokenIcon iconName={'file-xls'} size={16} />
                    <div className={'title_container'}>
                      <CustomTooltip
                        enterDelay={300}
                        leaveDelay={0}
                        title={file?.name || ''}
                        placement={'top'}
                        customBasisClass={`fileTitle`}
                      >
                        <div className={'fileTitle'}>{file?.name}</div>
                      </CustomTooltip>
                    </div>
                  </div>
                  <div className={`rightContent`}>
                    <div className={'fileSize'}>{getFileSizeName(file.size)}</div>
                    {file?.name && (
                      <a className={'removeBox'} target="_blank" rel="noopener noreferrer">
                        <SystemButton type={'delete'} variant={'transparent'} size={'md'} clickHandler={() => delFile(file.name)} />
                      </a>
                    )}
                  </div>
                </div>
                <>
                  {validation ? (
                    validation?.valid ? (
                      <div className={`${themeClass}_validation`}>
                        <TokenIcon iconName={'check'} size={20} />
                        <div className={`${themeClass}_validation_label -success`}>File Validated Succesfully</div>
                      </div>
                    ) : (
                      <div className={`${themeClass}_validation_label -danger`}>Validation Failed</div>
                    )
                  ) : null}
                </>
              </div>
            )}
            {validation && !validation?.valid && (
              <div className={`${themeClass}_errorsField`}>
                {importErrorMessage && (
                  <div className={'importError'}>
                    <span>{ucFirst(importErrorMessage)}</span>
                  </div>
                )}
                {file && warningList
                  ? warningList.map((warning, index) => {
                      return (
                        <div className={'importWarning'} key={`warning-${index}`}>
                          <span>{warning}</span>
                        </div>
                      );
                    })
                  : null}
                {file && errorList
                  ? errorList.map((error, index) => {
                      return (
                        <div className={'importError'} key={`error-${index}`}>
                          <span>{error}</span>
                        </div>
                      );
                    })
                  : null}
              </div>
            )}
          </div>
          <div className={`${themeClass}_button_group`}>
            <CustomButton
              type={'primary'}
              size={'md'}
              clickHandler={handleSendImportData}
              title={'Import'}
              disabled={!validation?.valid || importLoading}
              loading={animationBtn === 'import' ? animationLoading : false}
            />
          </div>
        </div>
      </DialogPopUp>
    </>
  );
};

export default React.memo(DataImport);
