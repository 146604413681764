import React, { Component } from 'react';

class Update_20220217_1 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_05cb2a5dc008aa405672c3c157649462/images/image4_1gb.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_05cb2a5dc008aa405672c3c157649462/images/image2.png'}
            alt=""
          />
        </div>
        <div className={'paragraph'}>
          The Content plan is an advanced functionality that will be available for Project administrators only.
        </div>
      </>
    );
  }
}

export default Update_20220217_1;
