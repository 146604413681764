import { BaseApi } from './baseApi';

class CommonApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getUfCodes(getParams) {
    return await this._get({ route: 'get-ufcodes', getParams: getParams, needAuth: true, devMode: this.devMode });
  }

  async getMfCodes(getParams) {
    getParams.limit = 50;
    return await this._get({
      route: `mfs`,
      getParams: getParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getConstructionCodes(bodyParams) {
    return await this._post({
      route: `uni-master-forms`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  async getFile(url) {
    return await this._getFile(url);
  }

  async getFilePost(url, body) {
    return await this._getFilePost(url, body);
  }

  async importPDFTronAnnot(id, request_id) {
    return await this._get({
      route: `/file/${id}/get-xml?request_id=${request_id}`,
      needAuth: true,
      devMode: this.devMode,
    });
  }
  async pdfAnnotAction(data) {
    return await this._post({
      route: `/file/sync-xml`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }
  async getRequestsFilters(data) {
    return await this._post({
      route: `/request/filters`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async validateNeedListImport(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-need-list-validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }
  async validateSpecificationImport(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-content-plan-mf-validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async validateConstructionDriverImport(data) {
    return await this._post({
      route: `/construction-driver/validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async validateBuildingSpacesImport(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-building-spaces-validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async validateContentPlanImport(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-content-plan-validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async validateDDLImport(data, id) {
    return await this._post({
      route: `/ddl/${id}/validate`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async importNeedList(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-need-list-inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }
  async importSpecification(data, id) {
    return await this._post({
      route: `/import-export/${id}/import-content-plan-mf-inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }
  async importConstructionDriver(data) {
    return await this._post({
      route: `/construction-driver/inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async importBuildingSpaces(data, buildingId) {
    return await this._post({
      route: `/import-export/${buildingId}/import-building-spaces-inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async importContentPlan(data, buildingId) {
    return await this._post({
      route: `/import-export/${buildingId}/import-content-plan-inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async importDDL(data, id) {
    return await this._post({
      route: `/ddl/${id}/inlet`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: data,
    });
  }

  async getListSetDDL(id) {
    return await this._get({
      route: `/ddl/${id}/list-set`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async changePrivate(bodyParams) {
    return await this._post({
      route: `/set-private`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }
}

export default new CommonApi({ devMode: false });
