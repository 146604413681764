import React, { useContext } from 'react';
import { ConfirmDialogContext } from './context';
import { IConfirmDialogContext } from './types';

export const withConfirmDialogContext = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>,
): React.ComponentType<P & IConfirmDialogContext> => {
  const WithContextComponent = (props: P) => {
    const contextProps = useContext(ConfirmDialogContext);
    return <Component {...props} {...contextProps} />;
  };

  return WithContextComponent;
};
