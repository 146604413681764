import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import { ISUbmittalStatus } from '../../../../models/submittals/submittals.model';

import './StatusSubmittalStyles.module.scss';

const StatusSubmittal: FC<{
  status: ISUbmittalStatus;
  reviewState?: 'pending' | 'approved' | 'returned';
  isShowHover?: boolean;
  isActive?: boolean;
  isMobile?: boolean;
}> = ({ status, isShowHover, isActive, isMobile, reviewState }) => {
  const themeClass = useGetThemeClass('b-status_SUBMITTAL');

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-disabled']: status?.title === 'Deactivated',
          ['-showHover']: isShowHover,
          ['-isActive']: isActive,
        })}
      >
        <div
          className={cn({
            [`${themeClass}_sys-informative`]: status?.alias === 'open' && reviewState === 'approved' && status?.title !== 'Deactivated',
            [`${themeClass}_sys-warning`]: status?.alias === 'open' && reviewState === 'pending' && status?.title !== 'Deactivated',
            [`${themeClass}_sys-danger`]: status?.alias === 'open' && reviewState === 'returned' && status?.title !== 'Deactivated',
            [`${themeClass}_tertiary-gray`]: status?.alias === 'draft' || status?.title === 'Deactivated',
            [`${themeClass}_sys-success`]: status?.alias === 'closed' && status?.title !== 'Deactivated',
          })}
        >
          <TokenIcon iconName={'status'} size={isMobile ? 16 : 12} />
        </div>
        <div
          className={cn('label_text', {
            ['-mobile']: isMobile,
          })}
        >
          {status?.title}
        </div>
      </div>
    </>
  );
};

export default React.memo(StatusSubmittal);
