import React from 'react';
import { FilesListStyles } from './FilesListStyles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import FileIcon, { defaultStyles } from 'react-file-icon';
import { Dialog } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { pdfTronSupportedTypes } from '../../../constants/pdfTronSupportedTypes';
import BaseHelper from '../../../helpers/BaseHelper';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import SinglePagePDFViewer from '../../controls/Preview/SinglePagePDFViewer';
import CustomTooltip from '../Tooltip/Tooltip';
import FileHelper from '../../../helpers/FileHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import SVG from 'react-inlinesvg';
import { commonSlice } from '../../../store/common/commonReducer';
import { withRouter } from '../../../navigation/withRouter/withRouter';
import { withRouterHelper } from '../../../navigation/withRouterHelper/withRouterHelper';

export const CircularProgressDownload = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-4px',
  },
  colorPrimary: {
    color: '#FFF !important;',
  },
})(CircularProgress);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class FilesListView extends React.Component {
  state = {
    selectedPrevFile: null,
  };

  fileHandler = async (route, name) => {
    const { selectedRequest, sourse } = this.props;
    const { project_id } = this.props.routerHelper.getNfParamsFromURL();
    await this.setState({ fileLoaded: true, fileName: name });
    const fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_${FileHelper.getSourceName(sourse)}_${name}`;
    await BaseHelper.downloadFile(route, fileName);
    await this.setState({ fileLoaded: false, fileName: '' });
  };

  openPrevFile = (file, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { isEditable, openPdftron, selectedRequest, isAccess, sourse, isThumbnailView, subSource } = this.props;

    if (!file.id) {
      return null;
    }

    if (isEditable) {
      openPdftron({
        file,
        selectedRequest,
        isRequest: sourse === 'R',
        isDeliverable: sourse === 'FC',
        isComment: subSource === 'comment',
        isPCD: sourse === 'PCD',
        isAccess: isAccess && !(window.innerWidth < 767 || window.innerWidth < 1024),
        isThumbnailView: isThumbnailView,
      });
    } else {
      if (file.file) {
        this.setState({
          selectedPrevFile: file,
          isPDF: pdfTronSupportedTypes.find(f => f === file.ext.toUpperCase()),
        });
      } else {
        this.setState({
          selectedPrevFile: file.fullpath || URL.createObjectURL(file),
          isPDF: file.fullpath ? false : file.type.indexOf('pdf') + 1,
        });
      }
    }
  };

  // handleFullScreen = file => {
  //   this.setState({
  //     selectedPrevFileFullScreen: true,
  //     fileLoaded: false,
  //     pdfWasLoaded: false,
  //     anchorPrevFile: null,
  //   });
  // };

  handleCloseSelectedPrevFile = () => {
    this.setState({
      isPDF: false,
      selectedPrevFile: null,
    });
  };

  render() {
    const { files, classes, type, disabled, isNoFullWidth, isEditable, fullSizeList, isThumbnailView, selectedRequest, location } =
      this.props;
    const { isPDF } = this.state;
    return (
      <ul className={`${isThumbnailView ? 'containerImgPdf' : classes.FileList}`} style={{ width: fullSizeList ? '100%' : null }}>
        {isThumbnailView &&
          files.map((file, i) => (
            <li key={`file-${file.id}-${i}`} style={{ listStyleType: 'none' }}>
              <div onClick={e => this.openPrevFile(file, e)}>
                {file?.ext.includes('png') || file?.ext.includes('jpg') || file?.ext.includes('jpeg') ? (
                  <img className={'boxImg'} src={file.file} />
                ) : file?.ext.includes('pdf') ? (
                  <div className={'react-pdf__Page__canvas'}>
                    <SinglePagePDFViewer pdf={file.file} />
                  </div>
                ) : null}
              </div>
            </li>
          ))}
        {!isThumbnailView &&
          files.map((file, i) => (
            <li
              key={`file-${file.id}-${i}`}
              className={cn(classes.FileListItem, {
                [classes.FileListItemPrimary]: type === 'primary',
                [classes.FileListItemSecondary]: type === 'secondary',
              })}
              style={{ width: isNoFullWidth ? 'auto' : '100%' }}
            >
              <a
                href={`${location.pathname}?${selectedRequest?.nf ? `nf=${selectedRequest?.nf}&` : ''}fileid=${file?.id || ''}`}
                onClick={e => this.openPrevFile(file, e)}
                style={{ display: 'flex', width: '90%', cursor: 'pointer' }}
              >
                <span className={classes.icon}>
                  <FileIcon
                    extension={file?.ext || file?.name?.split('.')?.[file?.name?.split('.')?.length - 1]}
                    {...defaultStyles?.[file?.ext ? file.ext : file?.name?.split('.')?.[file?.name?.split('.')?.length - 1]]}
                  />
                </span>
                <span className={classes.fileName}>{file.name || file.title}</span>
              </a>

              {file.name && !file.title && !disabled && (
                <a target="_blank" rel="noopener noreferrer" onClick={() => this.props.delFile(file.name)}>
                  <ClearIcon className={classes.iconClose} />
                </a>
              )}
              {!file.name && file.title && (
                <div className={classes.FileListIcons}>
                  <CustomTooltip title={'Download original attachment'} placement={'top'}>
                    <a
                      style={{ cursor: 'pointer' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.fileHandler(file.file, file.title)}
                    >
                      <div style={{ position: 'relative' }}>
                        {this.state.fileLoaded && this.state.fileName === file.title ? (
                          <SVG
                            src={require('../../../assets/images/downloadFile.svg')}
                            className={classes.iconClose}
                            style={{ width: '12px', height: '12px' }}
                          />
                        ) : (
                          <CloudDownload className={classes.iconClose} />
                        )}
                        {this.state.fileLoaded && this.state.fileName === file.title && <CircularProgressDownload size={12} />}
                      </div>
                    </a>
                  </CustomTooltip>
                  {this.props.delSavedFile && !disabled && (
                    <a target="_blank" rel="noopener noreferrer" onClick={() => this.props.delSavedFile(file.id)}>
                      <ClearIcon className={classes.iconClose} />
                    </a>
                  )}
                </div>
              )}
            </li>
          ))}
        {!isThumbnailView && !isEditable && this.state.selectedPrevFile?.id ? (
          <Dialog open={true} onClose={this.handleCloseSelectedPrevFile} TransitionComponent={Transition} maxWidth={'xl'}>
            <div style={{ position: 'relative' }}>
              <img src={this.state.selectedPrevFile.file} style={{ objectFit: 'contain', width: '100%' }} alt="file" />
            </div>
          </Dialog>
        ) : null}
      </ul>
    );
  }
}
const { openPdftron } = commonSlice.actions;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openPdftron,
    },
    dispatch,
  );

export default withRouterHelper(withRouter(connect(null, mapDispatchToProps)(withStyles(FilesListStyles)(FilesListView))));
