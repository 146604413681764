import { DeliverableSocketActionI } from '../SocketConstants';
import { pcdSlice } from '../../../../store/pcd/pcdSlice';
import { projectSlice } from '../../../../store/project/projectReducer';
import { requestSlice } from '../../../../store/request/requestReducer(HOLD)';

const { socketHandlePcdCard, socketHandleDeliverableChangeWatchlist, socketHandleDeliverableChangeHotlist } = pcdSlice.actions;
const { socketHandlePcdMain } = projectSlice.actions;
const { socketSandBoxHotlistState } = requestSlice.actions;

interface DeliverableDataI {
  socketData: DeliverableSocketActionI;
  dispatch: any;
  urlProject: string[];
  pcdId: number;
}

export const handleDeliverableSocket = ({ socketData, dispatch, urlProject, pcdId }: DeliverableDataI) => {
  if (
    (socketData.action === 'created' || socketData.action === 'updated' || socketData.action === 'deleted') &&
    urlProject[1] === 'output-data' &&
    urlProject[2] === 'deliverables' &&
    !socketData.event
  ) {
    dispatch(socketHandlePcdMain({ data: socketData.data_pcd, action: socketData.action }));
  }
  if (socketData.action === 'updated' && socketData.event === 'watchlist') {
    dispatch(socketHandleDeliverableChangeWatchlist({ data: socketData.data, pcdId }));
  }
  if (socketData.action === 'updated' && socketData.event === 'hotlist') {
    dispatch(socketSandBoxHotlistState({ changedNow: true, sectionType: 'deliverable' }));
    dispatch(socketHandleDeliverableChangeHotlist({ data: socketData.data }));
  }
  if ((socketData.action === 'updated' || socketData.action === 'updated_watchlist') && !socketData.event) {
    dispatch(socketHandlePcdCard({ card: socketData.data, action: socketData.action }));
  }
  if (socketData.action === 'updated_bic') {
    dispatch(socketHandlePcdCard({ card: socketData.data, action: socketData.action }));
  }
};
