import React, { FC, useEffect, useState } from 'react';
import Step4View from './Step4ViewNew';
import { BuildingModel, ContentPlanUFCColumnModel, LocationModel } from '../../../../../models';
import {
  getUfcData,
  getUfcListActive,
  getUFCVersions,
  postUFCData,
  searchUFC,
  searchUFCResult,
} from '../../../../../store/project/projectLogic';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { EditorProps, FormatterProps } from 'react-data-grid';
import ToggleSwitch from '../../../../controls/ToggleSwitch/ToggleSwitch';
import StatusUniformat from '../../../../controls/Status/StatusUniformat/StatusUniformat';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';

import './Step4ContainerNewStyles.scss';

interface IProps {
  exitEditMode: () => void;
}

const Step4ContainerNew: FC<IProps> = ({ exitEditMode }) => {
  const themeClass = useGetThemeClass('b-step4ContainerNew');

  const dispatch = useAppDispatch();

  const project = useAppSelector(state => state.phasePlan.projectData);
  const ufcBuildingsData = useAppSelector(state => state.projectReducer.ufcBuildingsData);
  const ufcListActiveData = useAppSelector(state => state.projectReducer.ufcListActiveData);
  const isLoading = useAppSelector(state => state.projectReducer.loading);

  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState<boolean>(false);
  const [currentBuilding, setCurrentBuilding] = useState<BuildingModel | null>();
  const [currentListMap, setCurrentListMap] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [locations, setLocations] = useState<LocationModel[]>(project.buildings ? project.buildings[0]?.locations : []);
  const [isImportDataOpen, setIsImportDataOpen] = useState<boolean>(false);
  const [expdandedRowsIds, setExpdandedRowsIds] = useState<number[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [enable, setEnable] = useState<number[]>([]);
  const [disable, setDisable] = useState<number[]>([]);
  const [updateLevels, setUpdateLevels] = useState<any>({});
  const [basedOnValue, setBasedOnValue] = useState<string>('');
  const [isBasedOnPopUpOpen, setIsBasedOnPopUpOpen] = useState<boolean>(false);

  const getUFColumns = (): any[] => {
    const defaultColumns = [
      {
        key: 'swither',
        name: '',
        width: 50,
        maxWidth: 50,
        resizable: false,
        sortable: false,
        frozen: true,
        formatter: (p: FormatterProps<ContentPlanUFCColumnModel, any>) => renderSwitherFormatter(p),
      },
      {
        key: 'uniformat',
        name: 'UniFormat',
        width: 380,
        minWidth: 380,
        editable: false,
        resizable: true,
        frozen: true,
        headerCellClass: 'rdg-cell-header',
        formatter: (p: FormatterProps<ContentPlanUFCColumnModel, any>) => renderUniFormat(p),
      },
    ];

    const levels = isLoading
      ? []
      : locations?.map(level => {
          return {
            key: level.id,
            name: level.title,
            width: 70,
            minWidth: 70,
            maxWidth: 110,
            resizable: true,
            formatter: (p: FormatterProps<ContentPlanUFCColumnModel, any>) => renderLevelColumnsEditor(p, level.id),
          };
        });

    return defaultColumns.concat(levels);
  };

  useEffect(() => {
    if (project?.id) {
      dispatch(getUfcData({ project_id: project.id, code_affiliation: 'uf' }));
      dispatch(getUfcListActive({ project_id: project.id, code_affiliation: 'uf' }));
      dispatch(getUFCVersions({ project_id: project.id, code_affiliation: 'uf' }));
    }
  }, [project]);

  useEffect(() => {
    if (project?.buildings) {
      setCurrentBuilding(project.buildings ? project.buildings[0] : null);
      setLocations(project.buildings ? project.buildings[0]?.locations : null);
      setColumns([]);
      setRows([]);
    }
  }, [project]);

  useEffect(() => {
    if (ufcBuildingsData && currentListMap) {
      setColumns(getUFColumns());
    }
  }, [ufcBuildingsData, expdandedRowsIds.length, currentListMap, currentBuilding]);

  useEffect(() => {
    if (ufcBuildingsData && currentListMap) {
      const ufcData = Object.values(ufcBuildingsData);
      const expandLevelValue = 0;

      const newRows = ufcData.map(item => {
        const row = {
          id: item.id,
          expanded: expdandedRowsIds.includes(item.id),
          expandLevelValue,
          code: item.code,
          ufTitle: item.title,
          rowTitle: `${item.code} - ${item.title}`,
          rows: handleGenerateGroupData(item.sub_ufc, expandLevelValue, !!currentListMap[item.id]?.active),
          isActive: !!currentListMap[item.id]?.active,
        };

        return row;
      });

      const result = [];
      generateRows(newRows, result);

      setRows(result);
    }
  }, [ufcBuildingsData, expdandedRowsIds.length, currentListMap, currentBuilding]);

  useEffect(() => {
    if (ufcListActiveData && currentBuilding?.id) {
      setCurrentListMap(
        ufcListActiveData?.buildings ? ufcListActiveData?.buildings[currentBuilding?.id] : ufcListActiveData[currentBuilding?.id],
      );
    }
  }, [currentBuilding, ufcListActiveData]);

  useEffect(() => {
    if (!searchValue && project.id) {
      dispatch(getUfcData({ project_id: project.id, code_affiliation: 'uf' }));
    }
  }, [searchValue, project]);

  const handleGenerateGroupData = (array: any[], expandLevelValue, isActiveParent) => {
    if (!!array?.length) {
      return array.map(item => {
        const expandLevelValueNew = expandLevelValue + 1;

        const isActive = !isActiveParent ? false : !!currentListMap[item.id]?.active;

        return {
          id: item.id,
          expanded: expdandedRowsIds.includes(item.id),
          expandLevelValue: expandLevelValueNew,
          code: item.code,
          ufTitle: item.title,
          rowTitle: `${item.code} - ${item.title}`,
          rows: handleGenerateGroupData(item.sub_ufc, expandLevelValueNew, isActive),
          isActive,
        };
      });
    } else {
      return [];
    }
  };

  const generateRows = (array: any, result) => {
    array.forEach(item => {
      result.push(item);
      if (!!item.expanded && !!item.rows.length) {
        generateRows(item.rows, result);
      }
    });
  };

  const renderSwitherFormatter = (p: FormatterProps<ContentPlanUFCColumnModel, any>) => {
    const { row } = p;
    const { id } = row;
    return (
      <div>
        <ToggleSwitch isSelectedToggle={!!p.row.isActive} handleSelect={() => handleSwitcherSelect(id)} disabled={false} type={'toggle'} />
      </div>
    );
  };

  const renderLevelColumnsEditor = (p: EditorProps<ContentPlanUFCColumnModel, any>, levelId) => {
    const { row } = p;
    const { id } = row;

    return (
      <div onClick={e => handleUfStatusClick(e, id, levelId)} className={`${themeClass}_ufStatusContainer`}>
        {currentListMap[id]?.items[levelId] ? <StatusUniformat status={'included'} /> : <StatusUniformat status={'excluded'} />}
      </div>
    );
  };

  const renderUniFormat = (p: EditorProps<ContentPlanUFCColumnModel, any>) => {
    const { row } = p;
    const { rowTitle, id, expanded, rows, expandLevelValue } = row;

    return (
      <div style={{ paddingLeft: `${24 * expandLevelValue}px` }} className={`${themeClass}_ufContainer`} onClick={() => handleUfClick(id)}>
        {!!rows.length && (
          <div
            className={cn(`${themeClass}_ufContainer_arrow`, {
              '-expanded': expanded,
            })}
          >
            <TokenIcon iconName={expanded ? 'chevron-down' : 'chevron-right'} size={12} />
          </div>
        )}
        <div
          className={cn(`${themeClass}_ufContainer_text`, {
            '-lastCode': !rows.length,
          })}
        >
          {rowTitle}
        </div>
      </div>
    );
  };

  const handleUfStatusClick = (e, ufId: number, levelId) => {
    e.preventDefault();
    e.stopPropagation();

    const result = JSON.parse(JSON.stringify(currentListMap));

    result[ufId].items[levelId] = !result[ufId]?.items[levelId];

    const newUpdateLevels = JSON.parse(JSON.stringify(updateLevels));

    if (!newUpdateLevels[ufId]) {
      newUpdateLevels[ufId] = {};
    }
    newUpdateLevels[ufId][levelId] = result[ufId]?.items[levelId];

    setUpdateLevels(newUpdateLevels);
    setCurrentListMap(result);
  };
  const handleSwitcherSelect = (ufId: number) => {
    const result = JSON.parse(JSON.stringify(currentListMap));

    if (result[ufId].active) {
      if (enable.includes(ufId)) {
        const enableNew = [...enable];
        enableNew.splice(
          enableNew.findIndex(item => item === ufId),
          1,
        );
        setEnable(enableNew);
      }
      if (!disable.includes(ufId)) {
        setDisable([...disable, ufId]);
      }
    } else {
      if (disable.includes(ufId)) {
        const disableNew = [...disable];
        disableNew.splice(
          disableNew.findIndex(item => item === ufId),
          1,
        );
        setDisable(disableNew);
      }
      if (!enable.includes(ufId)) {
        setEnable([...enable, ufId]);
      }
    }

    result[ufId].active = !result[ufId]?.active;

    setCurrentListMap(result);
  };
  const handleUfClick = (id: number) => {
    const result = [...expdandedRowsIds];

    if (result.includes(id)) {
      result.splice(
        expdandedRowsIds.findIndex(item => item === id),
        1,
      );
    } else {
      result.push(id);
    }

    setExpdandedRowsIds(result);
  };
  const handleSetCurrentBuilding = item => {
    const buildingIndex = project.buildings.findIndex(building => building.id === item.id);

    setCurrentBuilding(item);
    setLocations(project.buildings[buildingIndex].locations);
  };

  const handleChangeSearch = (text: string) => {
    setSearchValue(text);
  };

  const handleOpenDataImport = () => {
    setIsImportDataOpen(true);
  };

  const handleCloseDataImport = () => {
    setIsImportDataOpen(false);
  };

  const handleGetImportUFCData = () => {
    dispatch(getUfcData({ project_id: project?.id, code_affiliation: 'uf' }));
    dispatch(getUfcListActive({ project_id: project.id, code_affiliation: 'uf' }));
  };

  const handleSearchUFC = () => {
    dispatch(
      searchUFC({
        search: searchValue,
        project_id: project.id,
        building_id: currentBuilding.id,
        code_affiliation: 'uf',
      }),
    );

    handleOpenUFCSearchPopover();
  };

  const handleSearchUFCResult = (id: number) => {
    dispatch(
      searchUFCResult({
        ufcId: id,
        project_id: project.id,
        building_id: currentBuilding.id,
        code_affiliation: 'uf',
      }),
    );

    handleCloseUFCSearchPopover();
  };

  const handleOpenUFCSearchPopover = () => {
    setIsSearchPopoverOpen(true);
  };

  const handleCloseUFCSearchPopover = () => {
    setIsSearchPopoverOpen(false);
  };

  const handleSetBasedOnValue = (text: string) => {
    setBasedOnValue(text);
  };

  const openBasedOnPopUp = () => {
    setIsBasedOnPopUpOpen(true);
  };
  const closeBasedOnPopUp = () => {
    setIsBasedOnPopUpOpen(false);
  };

  const handleSaveUFContents = () => {
    const saveObject = {
      project_id: project?.id,
      code_affiliation: 'uf',
      building_id: currentBuilding?.id,
      enable,
      disable,
      update_levels: updateLevels,
      based_on: basedOnValue,
    };

    setEnable([]);
    setDisable([]);
    setUpdateLevels({});
    setIsBasedOnPopUpOpen(false);

    dispatch(
      postUFCData({
        saveObject: saveObject,
        callback: () => {
          dispatch(getUfcData({ project_id: project.id, code_affiliation: 'uf' }));
          dispatch(getUfcListActive({ project_id: project.id, code_affiliation: 'uf' }));
          dispatch(getUFCVersions({ project_id: project.id, code_affiliation: 'uf' }));
        },
      }),
    );
  };

  return (
    <>
      <Step4View
        buildings={project.buildings}
        currentBuilding={currentBuilding}
        setCurrentBuilding={handleSetCurrentBuilding}
        handleChangeSearch={handleChangeSearch}
        searchValue={searchValue}
        isImportDataOpen={isImportDataOpen}
        handleOpenDataImport={handleOpenDataImport}
        handleCloseDataImport={handleCloseDataImport}
        handleGetImportUFCData={handleGetImportUFCData}
        columns={columns}
        rows={rows}
        searchUFCResult={handleSearchUFCResult}
        searchUFC={handleSearchUFC}
        isSearchPopoverOpen={isSearchPopoverOpen}
        handleCloseUFCSearchPopover={handleCloseUFCSearchPopover}
        basedOnValue={basedOnValue}
        handleSetBasedOnValue={handleSetBasedOnValue}
        handleSaveUFContents={handleSaveUFContents}
        isBasedOnPopUpOpen={isBasedOnPopUpOpen}
        openBasedOnPopUp={openBasedOnPopUp}
        closeBasedOnPopUp={closeBasedOnPopUp}
        exitEditMode={exitEditMode}
      />
    </>
  );
};

export default Step4ContainerNew;
