import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import CustomTooltip from '../Tooltip/Tooltip';
import { DropdownItemModel } from '../../../models/global';
import { useGetThemeClass } from '../../../helpers/designTokens';
import {
  NfCompanySearchInterface,
  NfFilterCompanyModel,
  NfFilterDisciplineModel,
  NfFilterManagerModel,
  NfFilterSearchFlagType,
  SearchRequestBody,
  SelectedRequestCompanyModel,
} from '../../../models';
import PartyRenderSelect from './PartyRenderSelect';
import { searchRequest } from '../../../store/request/requestLogic(HOLD)';
import Timeout = NodeJS.Timeout;
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import DialogPopUp from '../DialogPopUp/DialogPopUp';
import { CheckBox } from '../index';
import { setPromptsAlerts } from '../../../store/user/userThunk';

import './PartySelectorStyles.scss';

interface IProps {
  type?: 'response' | 'request';
  selected: SelectedRequestCompanyModel;
  handleSelect?: (item: SelectedRequestCompanyModel) => void;
  responseSelectRef?: MutableRefObject<any>;
  handleChangeCompanyReceived?: () => void;
  getSelectTooltipText?: (selectType: 'request' | 'response', selectValue: 'discipline' | 'company' | 'user') => string;
  handleClosePopup?: () => void;
  customOpenedPopupType?: NfFilterSearchFlagType | null;
  isDisableSelect?: boolean;
  isDisableDisciplineAndUserSelect?: boolean;
  isChangePartyActionActive?: boolean;
  customPartyContainerClassName?: string;
  customSelectBasisContainerClass?: string;
  customSelectBasisFieldClass?: string;
  toolTipClassName?: string;
  errors?: string[];
  setCustomOpenenPopupType?: (type: string) => void;
  isMobile?: boolean;
  isLoading?: boolean;
  onClickOutside?: () => void;
  customNFCondition?: boolean;
  onSelectDiscipline?: (item) => void;
  onSelectCompany?: (item) => void;
  onSelectUser?: (item) => void;
  isUnlink?: boolean;
  isHighResolution?: boolean;
  companyId?: number;
}

const PartySelector: FC<IProps> = ({
  selected,
  handleSelect,
  handleChangeCompanyReceived,
  handleClosePopup,
  getSelectTooltipText,
  isDisableSelect,
  isDisableDisciplineAndUserSelect,
  responseSelectRef,
  customOpenedPopupType,
  setCustomOpenenPopupType,
  isChangePartyActionActive,
  errors,
  customPartyContainerClassName,
  customSelectBasisContainerClass,
  customSelectBasisFieldClass,
  toolTipClassName,
  type,
  isMobile,
  isLoading,
  onClickOutside,
  customNFCondition,
  onSelectDiscipline,
  onSelectCompany,
  onSelectUser,
  isUnlink,
  companyId,
  isHighResolution,
}) => {
  const searchRequestPartyLoading = useAppSelector<boolean>(state => state.requestReducer.searchRequestPartyLoading);

  const [openedPopupType, setOpenedPopupType] = useState(null);
  const [groupedPartyItems, setGroupedPartyItems] = useState<NfCompanySearchInterface>(null);
  const [selectPartyItems, setSelectPartyItems] = useState<NfCompanySearchInterface>(null);
  const [isLoadData, setIsLoadData] = useState<boolean>(false);
  const [isInactiveUserPopup, setIsInactiveUserPopup] = useState<boolean>(false);
  const [inactiveUserSelected, setInactiveUserSelected] = useState(null);
  const [isInactiveNotShowAgain, setIsInactiveNotShowAgain] = useState<boolean>(false);

  const activeProjectId: string = useSelector((state: any) => state.userReducer.active_project_id);
  const dispatch = useAppDispatch();

  const themeClass = useGetThemeClass('b-partySelector');

  let timer: Timeout;
  const searchRequestPromise = useRef();

  useEffect(() => {
    setGroupedPartyItems({
      disciplines: selectPartyItems?.disciplines?.map(item => ({ ...item, group: item?.available ? 'aboveLine' : 'underLine' })),
      parties: selectPartyItems?.parties
        ?.map(item => ({ ...item, group: item?.available ? 'aboveLine' : 'underLine' }))
        .filter(item => !(isUnlink && companyId === item.id)),
      managers: selectPartyItems?.managers?.map(item => {
        if (selected?.user?.id === item?.id) {
          return { ...item, group: 'aboveLine' };
        } else {
          return { ...item, group: 'underLine' };
        }
      }),
    });
  }, [selectPartyItems]);

  const selectGroups = [
    {
      value: 'aboveLine',
    },
    {
      value: 'underLine',
    },
  ];

  const onSearchParty = (search: string, openedPopup: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    if (search) {
      setIsLoadData(true);
    }

    const isUserComp = openedPopup === 'user_comp';

    const body: SearchRequestBody = {
      project_id: Number(activeProjectId),
      search_flags: isUserComp ? ['user'] : [openedPopup],
      text: search || undefined,
      type: type,
    };

    if ((openedPopup === 'comp' || openedPopup === 'user' || isUserComp) && selected?.discipline?.id) {
      body.discipline_id = selected.discipline.id;
    }

    if ((openedPopup === 'disc' || openedPopup === 'user' || isUserComp) && selected?.company?.id) {
      body.party_ids = [selected.company.id];
    }
    searchRequestPromise.current?.abort();
    searchRequestPromise.current = dispatch(
      searchRequest({
        ...body,
        callback: res => {
          if (res) {
            if ((openedPopup === 'user' || isUserComp) && res.managers.length) {
              const selectedUserIndex = res.managers.findIndex(m => m.id === selected?.user?.id);
              if (selectedUserIndex >= 0) {
                const result = { ...res };
                const selectedUser = result.managers.splice(selectedUserIndex, 1);
                result.managers.unshift(...selectedUser);
                setSelectPartyItems(result);
                return;
              }
            }
            setSelectPartyItems(res);
          }
        },
      }),
    );
  };

  const savePromptsAlerts = (promptName: string) => {
    if (localStorage.getItem(promptName) === 'yes') {
      return;
    }
    const promptNameList: { [key: string]: string } = JSON.parse(localStorage.getItem('promptNameList') || '{}');
    localStorage.setItem(promptName, isInactiveNotShowAgain ? 'yes' : 'no');
    promptNameList[promptName] = localStorage.getItem(promptName) || '';

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    dispatch(setPromptsAlerts(JSON.stringify(promptNameList)));
  };

  const handleCloseInactiveUserPopup = () => {
    savePromptsAlerts('inactiveUserPopup');
    setIsInactiveUserPopup(false);
    setInactiveUserSelected(null);
  };

  const handleOpenInactiveUserPopup = user => {
    if (localStorage.getItem('inactiveUserPopup') === 'yes') {
      handleSelectUser(user);
    } else {
      setIsInactiveUserPopup(true);
      setInactiveUserSelected(user);
    }
  };

  const toggleShowStatusPopup = () => {
    setIsInactiveNotShowAgain(prevState => !prevState);
  };

  const handleSelectDiscipline = (discipline: NfFilterDisciplineModel) => {
    setIsLoadData(true);
    const newSelected: SelectedRequestCompanyModel = {
      discipline: {
        ...discipline,
      },
      company: selected?.company || null,
      user: selected?.user || null,
    };

    if (!discipline.available) {
      newSelected.company = null;
      newSelected.user = null;
    }

    if (!newSelected?.company && discipline.parties.length === 1) {
      newSelected.company = {
        ...discipline.parties[0],
        id: discipline.parties[0].id,
        title: discipline.parties[0].company,
      };
      newSelected.user = discipline.parties[0].contact_point
        ? {
            ...discipline.parties[0].contact_point,
            id: discipline.parties[0].contact_point.id,
            title: `${discipline.parties[0]?.contact_point?.first_name} ${discipline.parties[0]?.contact_point?.last_name}`,
            profile: {
              first_name: discipline.parties[0]?.contact_point?.first_name,
              last_name: discipline.parties[0]?.contact_point?.last_name,
              image: discipline.parties[0]?.contact_point?.image,
            },
          }
        : null;

      setOpenedPopupType(null);
      setCustomOpenenPopupType && setCustomOpenenPopupType(null);
    }

    handleSelect && handleSelect(newSelected);
    onSelectDiscipline && onSelectDiscipline(discipline);

    if ((!newSelected?.company && discipline.parties?.length > 1) || !discipline.available) {
      setOpenedPopupType(`${type}-comp`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-comp`);
      return;
    }
    if (newSelected.company && !selected.user) {
      const findSelectedCompany = discipline.parties.find(p => p.id === newSelected.company.id);

      newSelected.user = findSelectedCompany?.contact_point
        ? {
            ...findSelectedCompany.contact_point,
            id: findSelectedCompany.contact_point?.id,
            title: `${findSelectedCompany?.contact_point?.first_name} ${findSelectedCompany?.contact_point?.last_name}`,
            profile: {
              first_name: findSelectedCompany?.contact_point?.first_name,
              last_name: findSelectedCompany?.contact_point?.last_name,
              image: findSelectedCompany?.contact_point?.image,
            },
          }
        : null;
      setOpenedPopupType(`${type}-user`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-user`);
    } else if (!newSelected.company) {
      setOpenedPopupType(`${type}-comp`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-comp`);
    } else {
      setOpenedPopupType(null);
      setCustomOpenenPopupType && setCustomOpenenPopupType(null);
    }
  };

  const handleSelectCompany = (company: NfFilterCompanyModel) => {
    setIsLoadData(true);
    const newSelected = {
      discipline: selected?.discipline || null,
      company: {
        ...company,
        id: company.id,
        title: company.company,
      },
      user: company.contact_point
        ? {
            ...company?.contact_point,
            id: company?.contact_point.id,
            title: `${company?.contact_point?.first_name} ${company?.contact_point?.last_name}`,
            profile: {
              first_name: company?.contact_point?.first_name,
              last_name: company?.contact_point?.last_name,
              image: company?.contact_point?.image,
            },
          }
        : null,
    };

    if (!company.available) {
      newSelected.discipline = null;
      newSelected.user = null;
    }

    handleSelect && handleSelect(newSelected);
    onSelectCompany && onSelectCompany(company);

    if (company.disciplines?.length === 1 && !newSelected.discipline) {
      newSelected.discipline = {
        id: company.disciplines[0].id,
        title: company.disciplines[0].title,
        text_color: company.disciplines[0].text_color,
      };
      newSelected.user = company.contact_point
        ? {
            ...company.contact_point,
            id: company.contact_point?.id,
            title: `${company?.contact_point?.first_name} ${company?.contact_point?.last_name}`,
            profile: {
              first_name: company?.contact_point?.first_name,
              last_name: company?.contact_point?.last_name,
              image: company?.contact_point?.image,
            },
          }
        : null;

      setOpenedPopupType(`${type}-user`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-user`);
      return;
    }
    if (!company.disciplines?.length && !newSelected.user) {
      newSelected.user = company.contact_point
        ? {
            ...company?.contact_point,
            id: company.contact_point?.id,
            title: `${company?.contact_point?.first_name} ${company?.contact_point?.last_name}`,
            profile: {
              first_name: company?.contact_point?.first_name,
              last_name: company?.contact_point?.last_name,
              image: company?.contact_point?.image,
            },
          }
        : null;
      setOpenedPopupType(`${type}-user`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-user`);
      return;
    }

    if (!newSelected?.discipline && company.disciplines.length > 1) {
      setOpenedPopupType(`${type}-disc`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-disc`);
      return;
    }
    if (customNFCondition) {
      if (newSelected?.discipline || !company.disciplines.length) {
        setOpenedPopupType(`${type}-user`);
        setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-user`);
      } else {
        setOpenedPopupType(`${type}-disc`);
        setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-disc`);
      }
      return;
    }

    if (company.users?.length) {
      setOpenedPopupType(`${type}-user`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-user`);
      return;
    }

    setOpenedPopupType(null);
    setCustomOpenenPopupType && setCustomOpenenPopupType(null);
  };

  const handleSelectUser = (user: NfFilterManagerModel) => {
    const newSelected = {
      discipline: null,
      company: {
        ...(selected?.company || user.parties[0]),
        id: selected?.company?.id || user.parties[0].id,
        title: selected?.company?.title || user.parties[0].company,
      },
      user: {
        ...user,
        id: user.id,
        title: `${user.profile.first_name} ${user.profile.last_name}`,
      },
    };

    if (user.parties[0].disciplines?.length === 1 && !selected?.discipline) {
      newSelected.discipline = {
        id: user.parties[0].disciplines[0].id,
        title: user.parties[0].disciplines[0].title,
        text_color: user.parties[0].disciplines[0].text_color,
      };
    }

    if (selected?.discipline) {
      newSelected.discipline = selected.discipline;
    }

    const isEmptyDiscipline = user.parties[0].disciplines?.length > 1 && !newSelected?.discipline;

    handleSelect && handleSelect(newSelected);
    onSelectUser && onSelectUser(user);

    if (isEmptyDiscipline) {
      setOpenedPopupType(`${type}-disc`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-disc`);
      return;
    }

    setOpenedPopupType(null);
    setCustomOpenenPopupType && setCustomOpenenPopupType(null);
  };

  const handleMenuOpen = (fieldType: string) => {
    if (openedPopupType !== `${type}-${fieldType}` || isLoadData) {
      onSearchParty('', fieldType);
      setOpenedPopupType(`${type}-${fieldType}`);
      setCustomOpenenPopupType && setCustomOpenenPopupType(`${type}-${fieldType}`);
    }
    if (isLoadData) {
      setIsLoadData(false);
    }
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        [`${customPartyContainerClassName}`]: customPartyContainerClassName,
      })}
      ref={responseSelectRef}
      onClick={handleChangeCompanyReceived}
    >
      <PartyRenderSelect<DropdownItemModel, null, false>
        type={'discipline'}
        isMulti={false}
        isHeader
        isFooter
        headerAdditionalText={selected?.company?.title ? `Disciplines available for ${selected.company.title} company` : ''}
        menuItems={groupedPartyItems?.disciplines || []}
        menuGroupingType={'divided'}
        groups={selectGroups}
        selectHandler={item => handleSelectDiscipline(item, type)}
        selected={selected?.discipline}
        isLoading={(customOpenedPopupType || openedPopupType) === `${type}-disc` && (searchRequestPartyLoading || isLoading)}
        basisPlaceholder={'Select discipline'}
        footerButtonClick={() => null}
        menuItemSize={'md'}
        customBasisContainerClass={`${customSelectBasisContainerClass}`}
        customBasisFieldClass={`${customSelectBasisFieldClass}`}
        onMenuOpen={() => handleMenuOpen('disc')}
        onMenuClose={handleClosePopup ? handleClosePopup : null}
        isOpenMenu={(customOpenedPopupType || openedPopupType) === `${type}-disc`}
        error={!!errors?.includes('discipline')}
        onChangeSearch={search => onSearchParty(search, 'disc')}
        disabled={type === 'request' ? isDisableDisciplineAndUserSelect : isDisableSelect}
        basisTooltip={getSelectTooltipText ? getSelectTooltipText(type, 'discipline') : ''}
        basisTooltipClassName={`${toolTipClassName}`}
        isMobile={isMobile}
        onClickOutside={onClickOutside}
        isHighResolution={isHighResolution}
      />
      <PartyRenderSelect<DropdownItemModel, null, false>
        type={'company'}
        isMulti={false}
        isHeader
        isFooter
        headerAdditionalText={selected?.discipline?.title ? `Companies available for ${selected.discipline.title} discipline` : ''}
        menuItems={groupedPartyItems?.parties || []}
        menuGroupingType={'divided'}
        groups={selectGroups}
        selectHandler={item => handleSelectCompany(item, type)}
        selected={selected?.company}
        isLoading={(customOpenedPopupType || openedPopupType) === `${type}-comp` && (searchRequestPartyLoading || isLoading)}
        basisPlaceholder={'Select company'}
        menuItemSize={'md'}
        customBasisContainerClass={`${customSelectBasisContainerClass}`}
        customBasisFieldClass={`${customSelectBasisFieldClass}`}
        onMenuOpen={() => handleMenuOpen('comp')}
        onMenuClose={handleClosePopup ? handleClosePopup : null}
        isOpenMenu={(customOpenedPopupType || openedPopupType) === `${type}-comp`}
        onChangeSearch={search => onSearchParty(search, 'comp')}
        disabled={isDisableSelect}
        error={!!errors?.includes('company')}
        customOpening={isChangePartyActionActive}
        basisTooltip={getSelectTooltipText ? getSelectTooltipText(type, 'company') : ''}
        basisTooltipClassName={`${toolTipClassName}`}
        isMobile={isMobile}
        onClickOutside={onClickOutside}
        isHighResolution={isHighResolution}
      />
      <PartyRenderSelect<DropdownItemModel, null, false>
        type={'user'}
        isMulti={false}
        isHeader
        isFooter
        headerAdditionalText={selected?.user?.id ? 'Selected user' : ''}
        menuItems={groupedPartyItems?.managers || []}
        menuGroupingType={'divided'}
        groups={selectGroups}
        selectHandler={item => {
          if (item?.is_active) {
            handleSelectUser(item);
          } else {
            handleOpenInactiveUserPopup(item);
          }
        }}
        selected={selected?.user}
        isLoading={(customOpenedPopupType || openedPopupType) === `${type}-user` && (searchRequestPartyLoading || isLoading)}
        basisPlaceholder={'Select person'}
        menuItemSize={'md'}
        customBasisContainerClass={`${customSelectBasisContainerClass}`}
        customBasisFieldClass={`${customSelectBasisFieldClass}`}
        onMenuOpen={() => handleMenuOpen('user')}
        onMenuClose={handleClosePopup ? handleClosePopup : null}
        isOpenMenu={(customOpenedPopupType || openedPopupType) === `${type}-user`}
        error={!!errors?.includes('user')}
        onChangeSearch={search => onSearchParty(search, 'user')}
        disabled={type === 'request' ? isDisableDisciplineAndUserSelect : isDisableSelect}
        basisTooltip={getSelectTooltipText ? getSelectTooltipText(type, 'user') : ''}
        basisTooltipClassName={`${toolTipClassName}`}
        isMobile={isMobile}
        onClickOutside={onClickOutside}
        isHighResolution={isHighResolution}
        customMenuItemClass={`${themeClass}_menuItem`}
      />
      <DialogPopUp
        open={isInactiveUserPopup}
        onClose={handleCloseInactiveUserPopup}
        dividedHeader
        title={'Assign a user?'}
        primaryText={'Yes, assign'}
        secondaryText={'Discard'}
        handleOnPrimary={() => {
          handleCloseInactiveUserPopup();
          handleSelectUser(inactiveUserSelected);
        }}
        handleOnSecondary={handleCloseInactiveUserPopup}
        paperMaxWidth={'500px'}
        renderModalContent={() => (
          <div className={`${themeClass}_dialogContent`}>
            <span className={`${themeClass}_dialogText`}>
              You are going to assign <span className={`-bold`}>Inactive</span> user. Do you want to proceed?
            </span>
            <div onClick={toggleShowStatusPopup} className={`${themeClass}_dialogCheckboxRow`}>
              <CheckBox checked={isInactiveNotShowAgain} />
              <span className={`${themeClass}_dialogText`}>Do not show such messages again</span>
            </div>
          </div>
        )}
      />
    </div>
  );
};
export default PartySelector;
