import { useDrag, useDrop } from 'react-dnd';
import { useCombinedRefs } from '../../../../../store/useCombinedRefs';
import React, { useState } from 'react';
import { HeaderRendererProps } from 'react-data-grid';
import { NeedListColumn } from '../../NeedListModels';
import { switchColumns } from '../../NeedListConstants/NeedListConstants';
import cn from 'classnames';
import { SortingDataModel } from '../../../../../models/global';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import ActionButton from '../../../../controls/ButtonComponents/ActionButton/ActionButton';

interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
  sorting: SortingDataModel;
  currentPlanitColumn: NeedListColumn;
  sortingField?: string;
  onDropTargetHover: (item, column: NeedListColumn) => void;
}

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  column,
  onSort,
  sorting,
  currentPlanitColumn,
  sortingField,
  onDropTargetHover,
}: DraggableHeaderRendererProps<R>) {
  const themeClass = useGetThemeClass('data-grid-basic');
  const [timeoutResolved, setTimeoutResolved] = useState(false);
  const [cordinates, setCordinates] = useState(null);

  const [{ isDragging, canDrag, draggedItem, clientOffset }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    end: (item, monitor) => {
      onDropTargetHover(null, null);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
      draggedItem: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
    }),
  });

  const [{ isActive, dropTarget }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
      onDropTargetHover(null, null);
    },
    collect: monitor => ({
      isActive: monitor.isOver() && monitor.canDrop(),
      dropTarget: currentPlanitColumn,
    }),
  });

  if (isActive) {
    setTimeout(() => {
      setTimeoutResolved(true);
      setCordinates(clientOffset);
    }, 0);
    if (timeoutResolved) {
      if (cordinates === clientOffset) {
        onDropTargetHover(draggedItem, dropTarget);
        setTimeoutResolved(false);
        setCordinates(clientOffset);
      }
    }
  }

  if (column.key === switchColumns.DELETE) {
    return (
      <div className={`${themeClass}__draggableHeaderInner`}>
        <span className={`${themeClass}__draggableHeaderInner_text`}>{column.name}</span>
      </div>
    );
  }

  if (column.key === switchColumns.PRIVATE) {
    return (
      <div className={`${themeClass}__draggableHeaderInner`}>
        <ActionButton type={'not-privat'} />
      </div>
    );
  }

  if (column.key === switchColumns.NF) {
    return (
      <div
        onClick={() => onSort(true)}
        className={`${sorting.order_by && sorting.order_by === sortingField ? 'sorted' : ''} ${themeClass}__draggableHeaderInner`}
      >
        <div style={{ height: '16px', width: '16px', backgroundColor: 'transparent', marginRight: '6px' }}></div>
        <span className={`${themeClass}__draggableHeaderInner_text`}>{column.name}</span>
        {sorting.order_by && sorting.order_by === sortingField && (
          <div className={'arrowContainer'}>
            <TokenIcon iconName={sorting.order_dir === 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
          </div>
        )}
      </div>
    );
  }

  if (!column.sortable) {
    return (
      <div
        className={`${themeClass}__draggableHeaderInner`}
        ref={useCombinedRefs(drag, drop)}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <span className={`${themeClass}__draggableHeaderInner_text`}>{column.name}</span>
      </div>
    );
  }

  return (
    <div
      ref={useCombinedRefs(drag, drop)}
      onClick={e => onSort(true)}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className={cn({ ['sorted']: sorting.order_by && sorting.order_by === sortingField }, `${themeClass}__draggableHeaderInner`)}
    >
      <span className={`${themeClass}__draggableHeaderInner_text`}>{column.name}</span>
      {sorting.order_by && sorting.order_by === sortingField && (
        <div className={'arrowContainer'}>
          <TokenIcon iconName={sorting.order_dir === 'asc' ? 'arrow-up' : 'arrow-down'} size={16} />
        </div>
      )}
    </div>
  );
}
