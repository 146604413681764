import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { errorsSlice } from '../../../store/errors/errorsSlice';
import { useGetThemeClass } from '../../../helpers/designTokens';
import {
  ButtonsText,
  IncorrectCompany,
  IncorrectProject,
  NoProjectAssigned,
  PageNotFoundErrorObj,
  ServerErrorObj,
} from './ErrorsPopUpConstants';
import { getSandBoxData } from '../../../store/request/requestLogic(HOLD)';
import { initialQueryState } from '../../common/SandBox/SandBox';
import { useAppSelector } from '../../../store/configure/configureStore';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './ErrorsPopUpStyles.scss';

interface IProps {
  isShowPopUp: boolean;
  description: string;
  title: string;
  statusCode: number;
  isSeparateTab: boolean;
  isPageNotFound: boolean;
  pageNotFoundURL: string;
}

const ErrorsPopUp: FC<IProps> = ({ isShowPopUp, description, title, statusCode, isSeparateTab, isPageNotFound, pageNotFoundURL }) => {
  const dispatch = useDispatch();
  const { redirectToTeam, redirectToProject, redirectToCommandCenter, removeUrlHash, setUrlHash } = useRoutingHelper();
  const active_project_id = useAppSelector(state => state.userReducer.active_project_id);

  const [isHandleCallbackNavigation, setIsHandleCallbackNavigation] = useState({ hash: null, isHandle: false });

  const themeClass = useGetThemeClass('b-errors-popup');
  const isIncorrectProject: boolean = title === IncorrectProject;
  const isIncorrectCompany: boolean = title === IncorrectCompany;
  const isNoProjectAssigned: boolean = title === NoProjectAssigned;
  const isServerError: boolean = statusCode === 500;
  const isShowSecondaryBtn: boolean = isIncorrectProject || isServerError || isIncorrectCompany || isPageNotFound;

  useEffect(() => {
    if (isHandleCallbackNavigation.isHandle) {
      if (isHandleCallbackNavigation.hash) {
        setUrlHash(isHandleCallbackNavigation.hash);
      } else {
        removeUrlHash();
      }
      setIsHandleCallbackNavigation({ hash: null, isHandle: false });
    }
  }, [isHandleCallbackNavigation.isHandle]);

  const handleClose = (): void => {
    if (isSeparateTab) {
      return;
    }

    if (isIncorrectCompany) {
      redirectToTeam();
    }

    dispatch(deleteErrorData());
  };

  const handleCloseSecondaryBtn = (): void => {
    dispatch(deleteErrorData());
    if (isServerError) {
      window.location.reload();
      return;
    }
    if (isIncorrectCompany) {
      redirectToProject();
      return;
    }

    if (isPageNotFound) {
      redirectToProject();
      return;
    }

    redirectToCommandCenter();
  };
  const handleClosePrimaryBtn = (): void => {
    dispatch(deleteErrorData());
    if (isIncorrectProject) {
      redirectToProject();
      return;
    }
    if (isIncorrectCompany) {
      redirectToTeam();
      return;
    }

    const sandboxUrlActiveTab = new URLSearchParams(location.search).get(`activeTab`);

    dispatch(
      getSandBoxData({
        ...initialQueryState,
        filter_by: sandboxUrlActiveTab ? JSON.parse(sandboxUrlActiveTab) : initialQueryState.filter_by,
        project_id: active_project_id,
        callbackNavigation: hash => {
          setIsHandleCallbackNavigation({ hash, isHandle: true });
        },
      }),
    );
    removeUrlHash();
    redirectToCommandCenter();
  };

  const getSecondaryBtnText = (): string => {
    if (isServerError) {
      return ServerErrorObj.secondaryBtnText;
    }
    if (isPageNotFound) {
      return PageNotFoundErrorObj.secondaryBtnText;
    }
    if (isIncorrectCompany) {
      return ButtonsText.goToProjectList;
    }

    return ButtonsText.goToSandbox;
  };

  const getPrimaryBtnText = (): string => {
    if (isIncorrectProject) {
      return ButtonsText.goToAvailableProjects;
    }
    if (isIncorrectCompany) {
      return ButtonsText.goToTeamSettings;
    }

    return ButtonsText.goToSandbox;
  };

  const getPopUpTitleText = (): string => {
    if (isServerError) {
      return ServerErrorObj.title;
    }

    if (isPageNotFound) {
      return PageNotFoundErrorObj.title;
    }

    return title;
  };

  const getPopUpDescriptionText = (): string => {
    if (isServerError) {
      return ServerErrorObj.description;
    }

    if (isPageNotFound) {
      return `${PageNotFoundErrorObj.description}${pageNotFoundURL} ?`;
    }

    return description;
  };

  return (
    <>
      <DialogPopUp
        open={isShowPopUp && !isNoProjectAssigned}
        onClose={handleClose}
        title={getPopUpTitleText()}
        modalText={getPopUpDescriptionText()}
        secondaryText={isShowSecondaryBtn && !isNoProjectAssigned ? getSecondaryBtnText() : null}
        handleOnSecondary={isShowSecondaryBtn && !isNoProjectAssigned ? handleCloseSecondaryBtn : null}
        primaryText={!isNoProjectAssigned ? getPrimaryBtnText() : null}
        handleOnPrimary={!isNoProjectAssigned ? handleClosePrimaryBtn : null}
        isHideRemoveIcon={isSeparateTab}
      />
    </>
  );
};

const mapStateToProps = ({ errorsReducer }) => ({
  isShowPopUp: errorsReducer.isShowPopUp,
  description: errorsReducer.description,
  title: errorsReducer.title,
  statusCode: errorsReducer.statusCode,
  isPageNotFound: errorsReducer.isPageNotFound,
  pageNotFoundURL: errorsReducer.pageNotFoundURL,
});

const { setShowErrorData, deleteErrorData } = errorsSlice.actions;

const mapDispatchToProps = dispatch => bindActionCreators({ setShowErrorData }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ErrorsPopUp);
