import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../controls/DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { DropdownItemModel, TabItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import ReasonsGrid from './parts/ReasonsGrid/ReasonsGrid';
import { RequestModel } from '../../../../../models';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { PlaneControlDeliverable } from '../../../../../store/pcd/types';
import RevisionReasonPayload = PlaneControlDeliverable.RevisionReasonPayload;
import RevisionReasonFieldsDataModel = PlaneControlDeliverable.RevisionReasonFieldsDataModel;
import { isEqual } from 'lodash';
import { updatePcdReasons } from '../../../../../store/pcd/pcdThunk';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import cn from 'classnames';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { DeliverableSnippetModel } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import Divider from '../../../../controls/Divider/Divider';
import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';

import './ReasonsStyles.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  selectedRequest: PCDCard;
  setReasonsPopUpData: (data: RevisionReasonPayload) => void;
  openDraftPromptPopUp: () => void;
  isReadOnly: boolean;
  isUpdateReasonsPopUp: boolean;
}

type ReasonTabModel = 'linked-nfs' | 'related-deliverables';

const reasonOptions: DropdownItemModel[] = [
  { value: 'Declined_by_Reviewer', title: 'Declined by Reviewer' },
  { value: 'New_design_input_received', title: 'New design input received' },
  { value: 'New_NFs_linked', title: 'New predecessor NFs/Deliverables/Submittals linked' },
  { value: 'Linked_NFs_changed', title: 'Linked predecessor NFs/Deliverables/Submittals changed' },
  { value: 'New_revision_of_predecessor_deliverables_issued', title: 'New revision of predecessor Deliverables issued' },
  { value: 'Other…', title: 'Other…' },
];

const reasonTabs: TabItemModel<ReasonTabModel>[] = [
  {
    title: 'Linked NFs',
    id: 'linked-nfs',
    count: 0,
  },
  {
    title: 'Related deliverables',
    id: 'related-deliverables',
    count: 0,
  },
];

const Reasons = ({
  open,
  onClose,
  selectedRequest,
  setReasonsPopUpData,
  openDraftPromptPopUp,
  isReadOnly,
  isUpdateReasonsPopUp,
}: IProps) => {
  const dispatch = useAppDispatch();

  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-reasonsPopup');

  // General states
  const [initialReasonsFields, setInitialReasonsFields] = useState<RevisionReasonFieldsDataModel>();
  const [reasonsFields, setReasonsFields] = useState<RevisionReasonFieldsDataModel>();
  const [activeTab, setActiveTab] = useState<ReasonTabModel>('linked-nfs');

  const [isReasonCommentChanged, setIsReasonCommentChanged] = useState<boolean>(false);

  // Grid state
  const [nfsList, setNfsList] = useState<RequestModel[]>(null);
  const [deliverablesList, setDeliverablesList] = useState<DeliverableSnippetModel[]>(null);

  // Confirmation states
  const [isOpenConfirmationExit, setIsOpenConfirmationExit] = useState<boolean>(false);

  const headerText = useMemo(() => {
    if (isMobile) {
      return (
        <>
          {`Provide the reason for`}
          <br />
          {`creating a new revision`}
        </>
      );
    }

    return 'Provide the reason for creating a new revision';
  }, [isMobile]);

  const causedNfs = useMemo(() => {
    if (selectedRequest && Array.isArray(selectedRequest.caused_nfs) && selectedRequest.caused_nfs.length) {
      const caused_nfs_requests = selectedRequest.requests.filter((request: RequestModel) =>
        selectedRequest.caused_nfs.includes(request.id),
      );
      const caused_nfs_request_deactivation = selectedRequest.request_deactivation.filter((deact: RequestModel) =>
        selectedRequest.caused_nfs.includes(deact.id),
      );
      const caused_nfs_request_unlink = selectedRequest.request_unlink.filter((unlink: RequestModel) =>
        selectedRequest.caused_nfs.includes(unlink.id),
      );
      return [...caused_nfs_requests, ...caused_nfs_request_deactivation, ...caused_nfs_request_unlink];
    }

    return [];
  }, [selectedRequest]);

  const causedDeliverables = useMemo(() => {
    if (
      selectedRequest &&
      !!relatedTabData.predecessor.length &&
      Array.isArray(selectedRequest.caused_deliverables) &&
      selectedRequest.caused_deliverables.length
    ) {
      const caused_deliverables_items =
        (relatedTabData.predecessor.length &&
          relatedTabData.predecessor.filter((del: DeliverableSnippetModel) => selectedRequest.caused_deliverables.includes(del.id))) ||
        [];

      //TODO in next sprint -> logic for deactivation/linked items
      // const caused_deliverables_items_deactivation = selectedRequest.deliverables_deactivation.filter((deact: DeliverableSnippetModel) =>
      //   selectedRequest.caused_deliverables.includes(deact.id),
      // );
      // const caused_deliverables_items_unlink = selectedRequest.deliverables_unlink.filter((unlink: DeliverableSnippetModel) =>
      //   selectedRequest.caused_deliverables.includes(unlink.id),
      // );
      // return [...caused_deliverables_items, ...caused_deliverables_items_deactivation, ...caused_deliverables_items_unlink];
      return [...caused_deliverables_items];
    }

    return [];
  }, [selectedRequest, relatedTabData.predecessor]);

  const isFieldsChanged = useMemo(() => {
    const selectedReasons = selectedRequest?.reason?.map(reasonValue => reasonOptions.find(option => option.value === reasonValue)) || [];
    const causedNfsIds = causedNfs?.length ? causedNfs.map(nf => nf.id) : [];
    const causedDeliverablesIds = causedDeliverables?.length ? causedDeliverables.map(del => del.id) : [];

    const notNull =
      selectedReasons.length > 0 ||
      selectedRequest?.reason_comment ||
      Array.from(new Set(causedNfsIds)).length > 0 ||
      Array.from(new Set(causedDeliverablesIds)).length > 0;
    if (notNull) {
      return true;
    }

    if (initialReasonsFields && reasonsFields) {
      return !isEqual(reasonsFields, initialReasonsFields) && reasonsFields.reason?.length;
    }
  }, [initialReasonsFields, reasonsFields]);

  useEffect(() => {
    const selectedReasons = selectedRequest?.reason?.map(reasonValue => reasonOptions.find(option => option.value === reasonValue)) || [];
    const causedNfsIds = causedNfs?.length ? causedNfs.map(nf => nf.id) : [];
    const causedDeliverablesIds = causedDeliverables?.length ? causedDeliverables.map(del => del.id) : [];

    const initialReasonsFieldsData: RevisionReasonFieldsDataModel = {
      id: selectedRequest?.id,
      reason_comment: selectedRequest?.reason_comment || '',
      reason: selectedReasons,
      caused_nfs: new Set(causedNfsIds),
      caused_deliverables: new Set(causedDeliverablesIds),
      parent_id: selectedRequest?.parent_id || selectedRequest?.id,
    };

    setInitialReasonsFields(initialReasonsFieldsData);
    setReasonsFields(initialReasonsFieldsData);
  }, [selectedRequest?.id, selectedRequest?.reason_comment, selectedRequest?.reason, causedNfs, causedDeliverables]);

  useEffect(() => {
    let newNfsList: RequestModel[] = [];
    let newDeliverablesList: DeliverableSnippetModel[] = [];

    if (isReadOnly) {
      newNfsList = sortNfsList(causedNfs.length ? causedNfs : []);
      newDeliverablesList = sortDeliverablesList(causedDeliverables.length ? causedDeliverables : []);
    } else {
      if (selectedRequest?.requests.length || selectedRequest?.request_deactivation.length || selectedRequest?.request_unlink.length) {
        newNfsList = sortNfsList([
          ...(selectedRequest?.requests || []),
          ...(selectedRequest.request_deactivation || []),
          ...(selectedRequest.request_unlink || []),
        ]);
      }
      //TODO unlink/deactivated - next sprint
      // if (relatedTabData.predecessor?.length || selectedRequest?.deliverables_deactivation.length || selectedRequest?.deliverables_unlink.length) {
      if (relatedTabData.predecessor?.length) {
        newDeliverablesList = sortDeliverablesList([...((relatedTabData.predecessor as DeliverableSnippetModel[]) || [])]);
        //TODO unlink/deactivated - next sprint
        // newDeliverablesList = sortDeliverablesList([
        //   ...(relatedTabData.predecessor || []),
        //   ...(selectedRequest.deliverables_deactivation || []),
        //   ...(selectedRequest.deliverables_unlink || []),
        // ]);
      }
    }

    setNfsList(newNfsList);
    setDeliverablesList(newDeliverablesList);
  }, [selectedRequest?.requests, relatedTabData?.predecessor]);

  const sortNfsList = (list: RequestModel[]): RequestModel[] => {
    const notVerifiedArr = [];
    const notValidArr = [];
    const validArr = [];
    const unlinkedArr = [];
    const deactivatedArr = [];
    list.forEach(item => {
      if (item?.validity_status === 'not_verified') {
        notVerifiedArr.push(item);
      }
      if (item?.validity_status === 'not_valid') {
        notValidArr.push(item);
      }
      if (item?.validity_status === 'valid') {
        validArr.push(item);
      }
      if (item?.validity_status === 'unlinked') {
        unlinkedArr.push(item);
      }
      if (item?.validity_status === 'deactivated') {
        deactivatedArr.push(item);
      }
    });

    return [...notVerifiedArr, ...notValidArr, ...unlinkedArr, ...deactivatedArr, ...validArr];
  };

  const sortDeliverablesList = (list: DeliverableSnippetModel[]): DeliverableSnippetModel[] => {
    const notVerifiedArr = [];
    const notValidArr = [];
    const validArr = [];
    const unlinkedArr = [];
    const deactivatedArr = [];
    list.forEach(item => {
      if (item?.flags?.validity_status === 'not_verified') {
        notVerifiedArr.push(item);
      }
      if (item?.flags?.validity_status === 'not_valid') {
        notValidArr.push(item);
      }
      if (item?.flags?.validity_status === 'valid') {
        validArr.push(item);
      }
      //TODO add from backend validity status unlinked/deactivated OR another fields is_unlinked/is_deactivated
      // if (item.validity_status === 'unlinked') {
      //   unlinkedArr.push(item);
      // }
      // if (item.validity_status === 'deactivated') {
      //   deactivatedArr.push(item);
      // }
    });

    // return [...notVerifiedArr, ...notValidArr, ...unlinkedArr, ...deactivatedArr, ...validArr];
    return [...notVerifiedArr, ...notValidArr, ...unlinkedArr, ...deactivatedArr, ...validArr];
  };

  const handleChangeReasonsFields = (value, fieldName: 'reason_comment' | 'reason' | 'caused_nfs' | 'caused_deliverables') => {
    const newReasonsFields = {
      ...reasonsFields,
      [fieldName]: value,
    };

    if (!isReasonCommentChanged && fieldName === 'reason_comment' && reasonsFields.reason_comment) {
      setInitialReasonsFields(newReasonsFields);
      setIsReasonCommentChanged(true);
    }

    setReasonsFields(newReasonsFields);
  };

  const handleCloseReasons = () => {
    if (isFieldsChanged && !isReadOnly) {
      setIsOpenConfirmationExit(true);
    } else {
      onClose && onClose();
    }
  };

  const handleSaveBtn = (): void => {
    const reason: string[] = reasonsFields.reason.map(item => item.value as string) || [];
    const reason_comment = reasonsFields.reason_comment || '';
    const caused_nfs = Array.from(reasonsFields.caused_nfs);
    const caused_deliverables = Array.from(reasonsFields.caused_deliverables);

    if (isUpdateReasonsPopUp) {
      dispatch(
        updatePcdReasons({
          ...reasonsFields,
          caused_nfs,
          caused_deliverables,
          reason,
        }),
      );
      onClose && onClose();
    } else {
      setReasonsPopUpData({
        caused_nfs,
        caused_deliverables,
        reason,
        reason_comment,
      });

      openDraftPromptPopUp();
    }
  };

  const handleConfirmExit = () => {
    onClose && onClose();
    setIsOpenConfirmationExit(false);
  };

  const renderReasonSelectedItem = (selectedItem: DropdownItemModel) => {
    const isLast = reasonsFields?.reason?.at(-1).value === selectedItem.value;

    return `${selectedItem.title}${isLast ? '' : ','}`;
  };

  const handleChangeActiveTab = (event: React.SyntheticEvent, tabName: string) => {
    if (activeTab !== tabName) {
      setActiveTab(tabName as ReasonTabModel);
    }
  };

  return (
    <>
      <Modal isCard open={open} onClose={handleCloseReasons}>
        <div
          className={cn(themeClass, {
            ['-mobile']: isMobile,
          })}
        >
          <div className={`${themeClass}_header`}>
            <span className={`${themeClass}_header_title`}>{headerText}</span>
            <SystemButton isMobile={isMobile} clickHandler={onClose} type={'close'} size={'lg'} variant={'transparent'} />
          </div>
          <div className={`${themeClass}_content`}>
            <div className={`${themeClass}_reasonsSelect`}>
              <span className={`${themeClass}_label`}>Reason for new revision:</span>
              <Select<DropdownItemModel, null, true>
                isMulti
                menuItems={reasonOptions}
                selectHandler={selected => handleChangeReasonsFields(selected, 'reason')}
                selected={reasonsFields?.reason}
                isLoading={false}
                menuItemSize={'md'}
                renderBasisSelectedItem={renderReasonSelectedItem}
                isMobile={isMobile}
                customMenuItemClass={`${themeClass}_select`}
                disabled={isReadOnly}
              />
            </div>
            <div className={`${themeClass}_textNote`}>
              <span className={`${themeClass}_label`}>Additional text note:</span>
              <TextQuillEditor
                fieldId={'reasonsPopup'}
                value={reasonsFields?.reason_comment}
                handleChange={value => handleChangeReasonsFields(value, 'reason_comment')}
                placeholder={isReadOnly ? '' : 'Leave a comment'}
                disabled={isReadOnly}
                customEditorClassName={`${themeClass}_textNote_editor`}
              />
            </div>
            <div className={`${themeClass}_nfTable`}>
              <span className={`${themeClass}_label`}>Items that caused new revision:</span>
              <TabsBar sections={reasonTabs} selectedTab={activeTab} handleChange={handleChangeActiveTab} isCountersLoading={false} />
              <Divider customClassname={`${themeClass}_divider`} direction={'horizontal'} type={'srf-5'} />
              {activeTab === 'linked-nfs' && (
                <ReasonsGrid
                  type={'nf'}
                  nfsList={nfsList}
                  isReadOnly={isReadOnly}
                  selectedRows={reasonsFields?.caused_nfs}
                  setSelectedRows={rows => handleChangeReasonsFields(rows, 'caused_nfs')}
                  reasonsFields={reasonsFields}
                />
              )}
              {activeTab === 'related-deliverables' && (
                <ReasonsGrid
                  type={'deliverable'}
                  deliverablesList={deliverablesList}
                  isReadOnly={isReadOnly}
                  selectedRows={reasonsFields?.caused_deliverables}
                  setSelectedRows={rows => handleChangeReasonsFields(rows, 'caused_deliverables')}
                  reasonsFields={reasonsFields}
                />
              )}
            </div>
            <div className={`${themeClass}_buttonContainer`}>
              <CustomButton
                isMobile={isMobile}
                type={'tertiary'}
                size={'md'}
                clickHandler={handleCloseReasons}
                title={isReadOnly ? 'Exit' : 'Cancel'}
                customClassName={`${themeClass}_buttonContainer_button`}
                customTooltipBasisClassName={`${themeClass}_buttonContainer_buttonTooltipBasis`}
              />
              {!isReadOnly ? (
                <CustomButton
                  isMobile={isMobile}
                  disabled={!isFieldsChanged}
                  type={'primary'}
                  size={'md'}
                  clickHandler={handleSaveBtn}
                  title={'Save'}
                  customClassName={`${themeClass}_buttonContainer_button`}
                  customTooltipBasisClassName={`${themeClass}_buttonContainer_buttonTooltipBasis`}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpenConfirmationExit}
        onClose={() => setIsOpenConfirmationExit(false)}
        title={'Quit new revision creation?'}
        modalText={
          'Leaving this window will discard any information you provided. A new\n revision of the Deliverable will not be created.\n\n Do you want to discard changes?'
        }
        primaryText={'Yes, discard'}
        tertiaryText={'Cancel'}
        customSecondaryType={'tertiary'}
        handleOnPrimary={handleConfirmExit}
        handleOnTertiary={() => setIsOpenConfirmationExit(false)}
      />
    </>
  );
};

export default Reasons;
