import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterNestedInterface, FilterNestedType } from '../../models';

function useMultiNestedFilter(entityName: FilterNestedType) {
  const [options, setOptions] = useState<FilterNestedInterface[]>([]);
  const [treeList, setTreeList] = useState<any[]>([]);

  let codes;
  let tree;
  let subCollectionList;
  let selectedTree;

  if (entityName === FilterNestedType.MF) {
    codes = useSelector((state: any) => state.requestReducer.mfSearchSuggestions);
    tree = useSelector((state: any) => state.requestReducer.mfTree);
    subCollectionList = 'target_sub_mfc';
    selectedTree = 'treeMfc';
  } else if (entityName === FilterNestedType.UF) {
    codes = useSelector((state: any) => state.projectReducer.ufcSearchSuggestions);
    tree = useSelector((state: any) => state.projectReducer.ufcTreeList);
    subCollectionList = 'target_sub_ufc';
    selectedTree = 'treeUfc';
  }

  useEffect(() => {
    if (codes) {
      const options = codes.map(mfCode => ({
        value: mfCode.id,
        label: `${mfCode.code} - ${mfCode.title}`,
        type: 'default',
        viewData: mfCode,
      }));

      setOptions(options);
    }
  }, [codes]);

  useEffect(() => {
    if (tree && tree[subCollectionList] && tree[selectedTree] && tree) {
      const options = tree[subCollectionList].map(ufCode => ({
        value: ufCode.id,
        label: `${ufCode.code} - ${ufCode.title}`,
        type: 'default',
        viewData: ufCode,
      }));
      setOptions(options);
      setTreeList(tree[selectedTree]);
    }
  }, [tree]);

  return {
    options,
    treeList,
    setTreeList,
  };
}

export default useMultiNestedFilter;
