import React, { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import cn from 'classnames';
import TokenIcon from '../TokenIcon/TokenIcon';
import FilesListViewTokens from '../FilesListViewTokens/FilesListViewTokens';
import CustomButton from '../ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { FileModel, RequestModel } from '../../../models';
import PCDCard = PCDApi.PCDCard;
import { PCDApi } from '../../../service/Api/pcd/types';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import { ISubmittal } from '../../../models/submittals/submittals.model';
import { PopupMoreLimitFiles } from '../PopupMoreLimitFiles/PopupMoreLimitFiles';

import './FileUploaderStyles.scss';

interface IProps {
  isCard?: boolean;
  handleChangeFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  handleMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
  isCardSidePeek?: boolean;
  isHiddenFields?: boolean;
  collectFinalFiles: FileModel[];
  isDisableFileList?: boolean;
  isShowMoreFiles?: boolean;
  files: FileModel[] | FileList | any;
  moreLimitFiles: FileModel[] | FileList | any;
  selectedRequest: PCDCard | RequestModel | ISubmittal;
  source: 'R' | 'C' | 'FC' | 'PCD' | 'Comment' | 'Sub';
  cardType: 'nf' | 'pcd' | 'submittal';
  isEditable: boolean;
  isEditMode: boolean;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  fileLoading?: boolean;
  loadingFilesName: string[];
  handleDownloadAll: (e: any) => void;
  isReview?: boolean;
  isSubmittalCard?: boolean;
  validateCard: (isSubmit: boolean) => any;
  isCreateCard: boolean;
  customFileUploaderClassName?: string;
}

const FileUploader = ({
  isCard,
  handleChangeFile,
  handleMoreLimitFiles,
  isCardSidePeek,
  isHiddenFields,
  collectFinalFiles,
  isDisableFileList,
  isShowMoreFiles,
  files,
  moreLimitFiles,
  selectedRequest,
  source,
  cardType,
  isEditable,
  isEditMode,
  delSavedFile,
  fileLoading,
  loadingFilesName,
  handleDownloadAll,
  isReview,
  isSubmittalCard,
  validateCard,
  isCreateCard,
  customFileUploaderClassName,
}: IProps) => {
  const { is1440Resolution, isMobile } = GetResolution();

  const fileInputRef = useRef(null);
  const fileOpenMoreLimitRef = useRef<HTMLDivElement>();

  const themeClass = useGetThemeClass('b-fileUploader');
  const themeClassFiles = useGetThemeClass('b-fileList');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const [isDeleteCheckedItems, setIsDeleteCheckedItems] = useState(false);
  const [checkedFilesId, setCheckedFilesId] = useState([]);

  const fileInputId = Math.ceil(Math.random() * 1000);

  const handleFileChange = (e: any, isDrag?: boolean, isRestrict?: boolean) => {
    if (isCreateCard) {
      const errors = validateCard(false);

      if (!Object.keys(errors).length) {
        handleChangeFile(e, isDrag, isRestrict);

        fileInputRef.current.value = '';
      }
    } else {
      handleChangeFile(e, isDrag, isRestrict);

      fileInputRef.current.value = '';
    }
  };

  const handleDeleteCheckedFiles = status => {
    if (!status) {
      setCheckedFilesId([]);
    }
    setIsDeleteCheckedItems(status);
  };

  const handleCloseMoreLimitFiles = () => {
    handleMoreLimitFiles([]);
  };

  return (
    <div
      className={cn(`${themeClass} ${themeClassFiles}`, {
        ['-mobile']: isMobile,
      })}
      ref={fileOpenMoreLimitRef}
    >
      <FileDrop onDrop={files => handleFileChange(files, true, false)}>
        <div
          className={cn(`${themeClassFiles}_verticalListTab`, {
            ['-isCardList']: isCard,
            ['-isSubmittalCard']: isSubmittalCard,
            ['-sidePeekView']: isCardSidePeek || is1440Resolution,
            ['-isHiddenFields']: !isMobile && isHiddenFields,
            ['-isFilesInSidePeek']: (isCardSidePeek || is1440Resolution) && collectFinalFiles.length > 0,
            ['-disabled']: isDisableFileList,
            ['-mobile']: isMobile,
          })}
          style={isShowMoreFiles ? { height: 'auto' } : {}}
        >
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            id={`button-file-request-${fileInputId}`}
            multiple
            type="file"
            onChange={e => handleFileChange(e)}
            onClick={e => {
              if (isCreateCard) {
                const errors = validateCard(false);

                if (Object.keys(errors).length) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }
            }}
            disabled={isDisableFileList}
          />
          {!collectFinalFiles?.length && !isMobile ? (
            <label className={'empty'} htmlFor={`button-file-request-${fileInputId}`}>
              {isDisableFileList ? (
                <span>There are no uploaded files</span>
              ) : (
                <>
                  <TokenIcon iconName={'upload'} size={isMobile ? 24 : 20} />
                  <span>Upload files</span>
                </>
              )}
            </label>
          ) : null}
          {!collectFinalFiles?.length && isMobile ? (
            <label className={'empty'} htmlFor={`button-file-request-${fileInputId}`}>
              {isDisableFileList ? (
                <span>There are no uploaded files</span>
              ) : (
                <CustomButton
                  type={'text-plain'}
                  size={'sm'}
                  title={'Upload files'}
                  isMobile={isMobile}
                  icon={<TokenIcon iconName={'upload'} size={isMobile ? 24 : 20} />}
                  clickHandler={() => fileInputRef.current.click()}
                />
              )}
            </label>
          ) : null}
          {collectFinalFiles && (
            <FilesListViewTokens
              files={files}
              type={'verticalList'}
              cardType={cardType}
              isEditable={isEditable}
              delSavedFile={delSavedFile}
              source={source}
              disabled={isDisableFileList}
              selectedRequest={selectedRequest || null}
              isEditMode={isEditMode}
              fileLoading={fileLoading}
              loadingFilesName={loadingFilesName}
              totalViewFiles={files?.length}
              isCardSidePeek={isCardSidePeek}
              isDeleteCheckedItems={isDeleteCheckedItems}
              handleDeleteCheckedFiles={handleDeleteCheckedFiles}
              setCheckedFilesId={setCheckedFilesId}
              isReview={isReview}
              isSubmittalCard={isSubmittalCard}
            />
          )}
          {collectFinalFiles?.length ? (
            <div
              className={cn('fileUploaderFooter', {
                ['-mobile']: isMobile,
              })}
            >
              {!isDisableFileList ? (
                <CustomButton
                  type={'text-plain'}
                  size={isMobile ? 'md' : 'xs'}
                  icon={<TokenIcon iconName={'attachment'} size={isMobile ? 20 : 16} />}
                  iconClass={'attachFileIcon'}
                  title={'Attach files'}
                  disabled={isDisableFileList}
                  isMobile={isMobile}
                  clickHandler={() => fileInputRef.current.click()}
                />
              ) : null}
              <div className={'rightBlock'}>
                {!isDisableFileList ? (
                  <div>
                    {!isMobile ? (
                      <CustomButton
                        type={'text-plain'}
                        size={'xs'}
                        clickHandler={() => handleDeleteCheckedFiles(true)}
                        title={'Delete'}
                        disabled={isDisableFileList || !checkedFilesId.length}
                      />
                    ) : null}
                    {isMobile && !!checkedFilesId.length ? (
                      <SystemButton
                        type={'delete'}
                        variant={'transparent'}
                        isMobile={isMobile}
                        size={'md'}
                        clickHandler={() => handleDeleteCheckedFiles(true)}
                      />
                    ) : null}
                  </div>
                ) : null}
                {collectFinalFiles?.length ? (
                  <>
                    {!isMobile ? (
                      <CustomButton type={'text-plain'} size={'xs'} clickHandler={handleDownloadAll} title={'Download All'} />
                    ) : null}
                    {isMobile ? (
                      <SystemButton
                        type={'download'}
                        variant={'transparent'}
                        size={'md'}
                        clickHandler={handleDownloadAll}
                        isMobile={isMobile}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </FileDrop>
      {moreLimitFiles?.length > 0 && (
        <PopupMoreLimitFiles
          isOpenMoreLimitFiles={moreLimitFiles?.length > 0}
          anchorEl={fileOpenMoreLimitRef.current}
          handleCloseMoreLimitFiles={handleCloseMoreLimitFiles}
          customClassName={customFileUploaderClassName}
        />
      )}
    </div>
  );
};

export default FileUploader;
