import { BaseApi } from '../baseApi';
import { ConstructorParams } from '../pcd/types';
import { GetLinkingItemsModel, GetRelationTabModel, SetValidityStatus } from '../../../store/relatedItems/relatedItemsSlice.model';

class RelatedItemsApi extends BaseApi {
  devMode: boolean;

  constructor({ baseURL, devMode }: ConstructorParams) {
    super(baseURL);
    this.devMode = devMode;
  }

  getRelationTab(bodyParams: GetRelationTabModel) {
    return this._post({
      route: `relation/get-relation-tab`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  linkRelationItem(bodyParams: GetRelationTabModel) {
    return this._post({
      route: `relation/link-relation`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  unlinkRelationItem(bodyParams: GetRelationTabModel) {
    return this._post({
      route: `relation/unlink-relation`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  getLinkingItems(bodyParams: GetLinkingItemsModel) {
    return this._post({
      route: `relation/get-relation-items`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  setValidityStatus(bodyParams: SetValidityStatus) {
    return this._post({
      route: `relation/set-validity-status`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }
}

export default new RelatedItemsApi({ devMode: false });
