export const columnTooltipsTextArr = [
  //General statistics
  { key: 'bic', tooltipText: 'Number of Need Forms in the court of the party excluding drafts and private NFs' },
  { key: 'bic_to_commit', tooltipText: 'Number of Need Forms party needs to respond to' },
  { key: 'bic_to_provide', tooltipText: 'Number of Need Forms party has provided commitment to' },
  { key: 'bic_to_accept', tooltipText: 'Number of Need Form responses which party needs to accept or decline' },
  { key: 'bic_to_resubmit', tooltipText: 'Number of Need Form responses provided by the party which had been declined' },
  {
    key: 'bic_due_date',
    tooltipText: 'Number of Need Forms that contain due date change request and require party to accept or decline it',
  },
  {
    key: 'resp_company_change',
    tooltipText: 'Number of Need Forms that contain response party change request and require party to accept or decline it',
  },
  { key: 'avg_court_time', tooltipText: 'Average time that Need Forms spend in the court of the party' },
  //Requester statistics
  { key: 'requester_total', tooltipText: 'Number of Need Forms in which user/company is assigned as the request party' },
  {
    key: 'requester_sent',
    tooltipText: `Number of Need Forms in status <i>Sent</i>  in which user/company is assigned as the request party`,
  },
  {
    key: 'requester_provided',
    tooltipText: 'Number of Need Forms in status <i>Response provided</i> in which user/company is assigned as the request party',
  },
  {
    key: 'requester_declined',
    tooltipText: 'Number of Need Forms in status <i>Declined</i> in which user/company is assigned as the request party',
  },
  {
    key: 'requester_accepted',
    tooltipText: 'Number of Need Forms in status <i>Accepted</i> in which user/company is assigned as the request party',
  },
  {
    key: 'requester_declined_rate',
    tooltipText:
      'The percentage of Need Forms in status <i>Declined</i> in which user/company is assigned as a request party compared to all Need Forms where response was either accepted or declined',
  },
  { key: 'requester_deactivated', tooltipText: 'Number of deactivated Need Forms in which user/company is assigned as the request party' },
  {
    key: 'requester_avg_open_time',
    tooltipText:
      'Average time between sending a Need Form and accepting it’s response, calculated for all Need Forms in which user/company is assigned as the request party',
  },
  { key: 'requester_deactivated_rate', tooltipText: 'The percentage of deactivated' },
  {
    key: 'requester_avg_ovd_time',
    tooltipText: 'Average time a Need Form in which user/company is assigned as a request party remains open past its due date',
  },
  //Responder statistics
  { key: 'responder_total', tooltipText: 'Number of Need Forms in which user/company is assigned as the response party' },
  {
    key: 'responder_sent',
    tooltipText: 'Number of Need Forms in status <i>Sent</i> in which user/company is assigned as the response party',
  },
  {
    key: 'responder_submitted',
    tooltipText: 'Number of Need Forms with commitments provided in which user/company is assigned as the response party',
  },
  {
    key: 'responder_provided',
    tooltipText: 'Number of Need Forms in status <i>Response</i> provided in which user/company is assigned as the response party',
  },
  {
    key: 'responder_declined',
    tooltipText: 'Number of Need Forms in status <i>Reopened</i> in which user/company is assigned as the response party',
  },
  {
    key: 'responder_accepted',
    tooltipText: 'Number of Need Forms in status <i>Response</i> accepted in which user/company is assigned as the response party',
  },
  {
    key: 'responder_declined_rate',
    tooltipText:
      'The percentage of Need Forms in status <i>Declined</i> in which user/company is assigned as a response party compared to all Need Forms where response was either accepted or declined',
  },
  {
    key: 'responder_revision_count',
    tooltipText:
      'Average number of Need Form revisions created when response is declined for NFs in which user/company is assigned as a response party. The higher the number the more times a response party had to reupload the response.',
  },
  { key: 'responder_deactivated', tooltipText: 'Number of deactivated Need Forms in which user/company is assigned as the response party' },
  {
    key: 'responder_avg_response_time',
    tooltipText: 'Average time between sending and providing response to a Need Form in which user/company is assigned as a response party',
  },
  {
    key: 'responder_avg_ovd_time',
    tooltipText: 'Average time a Need Form in which user/company is assigned as a response party remains open past its due date',
  },
  // Deliverable statistics
  { key: 'deliverable_court', tooltipText: 'Number of Deliverables in which user/company is assigned as a Responsible Party' },
  { key: 'deliverable_review', tooltipText: 'Number of Deliverables in which user/company is assigned as a Review Party' },
  { key: 'review_approved', tooltipText: 'Number of positive Deliverable reviews provided by the user/company' },
  { key: 'review_declined', tooltipText: 'Number of negative Deliverable reviews provided by the user/company' },
  { key: 'review_pending', tooltipText: 'Number of Deliverable reviews required and not provided yet by the user/company' },
];

export const BackgroundValues: string[] = [
  'rgba(89, 105, 137, 0.5)',
  'rgba(114, 129, 158, 0.5)',
  'rgba(141, 154, 178, 0.5)',
  'rgba(158, 172, 194, 0.5)',
];
