export const designDocumentLogSelectorGroups = [{ title: 'General', value: 'general' }];

export const submittalsSelectorGroups = [
  { title: 'General', value: 'general' },
  { title: 'Parties', value: 'parties' },
  { title: 'Actions and dates', value: 'actions and dates' },
  { title: 'Review', value: 'review' },
  { title: 'Locations', value: 'locations' },
  { title: 'Discussion', value: 'discussion' },
  { title: 'Plan & Control', value: 'plan' },
];

export const submittalsSortingOptions = [
  { value: 'spec', title: 'Spec section' },
  { value: 'created_at', title: 'Date created' },
  { value: 'submit_due_date', title: 'Submit by date' },
  { value: 'submitted_date', title: 'Submitted date' },
  { value: 'required_due_date', title: 'Required on site date' },
  { value: 'review_required_due_date', title: 'Required approval date' },
  { value: 'distributed_date', title: 'Distributed date' },
  { value: 'status', title: 'Status' },
  { value: 'type', title: 'Type' },
  { value: 'discipline_contractor', title: 'Responsible contractor discipline' },
  { value: 'party_contractor', title: 'Responsible contractor company' },
  { value: 'discipline_manager', title: 'Responsible manager discipline' },
  { value: 'party_manager', title: 'Responsible manager company' },
  { value: 'hashtag', title: 'Tags' },
];

export const submittalGroupByFilters = {
  buildings: ['level', 'building_spaces', 'typical_layout', 'related_deliverable'],
};

/*
 * title - Column name
 * isChecked - Hide\show column
 * value - Property for switch hide\show column
 * width - Column width
 * filterField - Property for get filter from filters collection
 * searchField - Column search field
 * exportField - For export column like: PDF
 * sortingField - Column sorting
 * editFieldName - For edit field
 * filteredField - Send filter property to API for filtration
 * */

export const designDocumentsColumns = [
  {
    title: 'Discipline',
    isChecked: true,
    value: 'disciplines',
    width: 150,
    filterField: 'disciplines',
    searchField: 'disciplines',
    exportField: 'disciplines',
    sortingField: 'disciplines',
    editFieldName: 'disciplines',
    filteredField: 'disciplines',
    position: 1,
    group: 'general',
  },
  {
    title: 'Revision number',
    isChecked: true,
    value: 'revision',
    width: 150,
    filterField: 'revision',
    searchField: 'revision',
    exportField: 'revision',
    sortingField: 'revision',
    editFieldName: 'revision',
    filteredField: 'revision',
    position: 2,
    group: 'general',
    conditionType: 'NUMBER',
  },
  {
    title: 'Revision date',
    isChecked: true,
    value: 'revision_date',
    width: 150,
    filterField: 'revision_date',
    searchField: 'revision_date',
    exportField: 'revision_date',
    sortingField: 'revision_date',
    editFieldName: 'revision_date',
    filteredField: 'revision_date',
    position: 3,
    group: 'general',
    conditionType: 'DATE',
  },
  {
    title: 'Current set',
    isChecked: true,
    value: 'current_set',
    width: 150,
    filterField: 'current_set',
    searchField: 'current_set',
    exportField: 'current_set',
    sortingField: 'current_set',
    editFieldName: 'current_set',
    filteredField: 'current_set',
    position: 3,
    group: 'general',
  },
  {
    title: 'Type of set',
    isChecked: true,
    value: 'type_set',
    width: 150,
    filterField: 'type_set',
    searchField: 'type_set',
    exportField: 'type_set',
    sortingField: 'type_set',
    editFieldName: 'type_set',
    filteredField: 'type_set',
    position: 3,
    group: 'general',
  },
  {
    title: 'Included in set',
    isChecked: true,
    value: 'included_in_set',
    width: 150,
    filterField: 'included_in_set',
    searchField: 'included_in_set',
    exportField: 'included_in_set',
    sortingField: 'included_in_set',
    editFieldName: 'included_in_set',
    filteredField: 'included_in_set',
    position: 3,
    group: 'general',
  },
  {
    title: 'Status in latest set',
    isChecked: true,
    value: 'status',
    width: 150,
    filterField: 'status',
    searchField: 'status',
    exportField: 'status',
    sortingField: 'status',
    editFieldName: 'status',
    filteredField: 'status',
    position: 3,
    group: 'general',
  },
  {
    title: 'Assigned NFs',
    isChecked: true,
    value: 'assigned_nf',
    width: 150,
    filterField: 'assigned_nf',
    searchField: 'assigned_nf',
    exportField: 'assigned_nf',
    sortingField: 'assigned_nf',
    editFieldName: 'assigned_nf',
    filteredField: 'assigned_nf',
    position: 3,
    group: 'general',
  },
  {
    title: 'NF Validation',
    isChecked: true,
    value: 'validation_nf',
    width: 150,
    filterField: 'validation_nf',
    searchField: 'validation_nf',
    exportField: 'validation_nf',
    sortingField: 'validation_nf',
    editFieldName: 'validation_nf',
    filteredField: 'validation_nf',
    position: 3,
    group: 'general',
  },
  {
    title: 'Building',
    isChecked: true,
    value: 'buildings',
    width: 150,
    filterField: 'buildings',
    searchField: 'buildings',
    exportField: 'buildings',
    sortingField: 'buildings',
    editFieldName: 'buildings',
    filteredField: 'buildings',
    position: 3,
    group: 'general',
  },
];

export const submittalsGridColumns = [
  {
    filterField: 'mf',
    title: 'Spec section',
    group: 'general',
  },
  {
    key: 'sf_index',
    name: 'SF',
    width: 172,
    frozen: true,
    sortingField: 'spec',

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'spec_section',
    name: 'Spec section',
    width: 172,
    editable: true,
    onlyEditable: true,
  },
  {
    key: 'version',
    name: 'Rev',
    width: 41,
    minWidth: 41,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'title',
    name: 'Title',
    width: 217,
    filterField: 'title',
    title: 'Title',
    group: 'general',
    conditionType: 'TEXT',
    position: 4,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'description',
    name: 'Description',
    width: 150,
    filterField: 'description',
    title: 'Description',
    group: 'general',
    conditionType: 'TEXT',

    hideShowGroup: 'general',
    isDefaultVisible: false,
  },
  {
    key: 'files',
    name: 'Attachments',
    width: 250,
    filterField: 'file',
    title: 'Attachments',
    group: 'general',
    conditionType: 'TEXT',

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'type',
    name: 'Type',
    width: 163,
    title: 'Type',
    group: 'general',
    editable: true,
    sortingField: 'type',

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'status',
    name: 'Status',
    width: 162,
    filterField: 'statuses',
    title: 'Status',
    group: 'general',
    sortingField: 'status',
    position: 3,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_contractor_discipline',
    name: 'Responsible contractor discipline',
    width: 250,
    filterField: 'contractor_discipline',
    title: 'Responsible contractor discipline',
    group: 'parties',
    editable: true,
    sortingField: 'discipline_contractor',

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_contractor_company',
    name: 'Responsible contractor company',
    width: 250,
    filterField: 'contractor_party',
    title: 'Responsible contractor company',
    group: 'parties',
    editable: true,
    sortingField: 'party_contractor',

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_contractor',
    name: 'Responsible contractor',
    width: 250,
    filterField: 'contractor_user',
    title: 'Responsible contractor',
    group: 'parties',
    editable: true,

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_manager_discipline',
    name: 'Responsible manager discipline',
    width: 250,
    filterField: 'responsible_discipline',
    title: 'Responsible manager discipline',
    group: 'parties',
    editable: true,
    sortingField: 'discipline_manager',

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_manager_company',
    name: 'Responsible manager company',
    width: 250,
    filterField: 'responsible_party',
    title: 'Responsible manager company',
    group: 'parties',
    editable: true,
    sortingField: 'party_manager',

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'responsible_manager',
    name: 'Responsible manager',
    width: 250,
    filterField: 'responsible_user',
    title: 'Responsible manager',
    group: 'parties',
    editable: true,

    hideShowGroup: 'parties',
    isDefaultVisible: true,
  },
  {
    key: 'submit_due_date',
    name: 'Submit by',
    width: 132,
    filterField: 'submit_due_date',
    title: 'Submit by',
    group: 'actions and dates',
    conditionType: 'DATE',
    editable: true,
    sortingField: 'submit_due_date',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'submitted_by',
    name: 'Submitted by',
    width: 158,
    filterField: 'submitted_by',
    title: 'Submitted by',
    group: 'actions and dates',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'submitted_date',
    name: 'Submitted date',
    width: 132,
    filterField: 'submitted_date',
    title: 'Submitted date',
    group: 'actions and dates',
    conditionType: 'DATE',
    sortingField: 'submitted_date',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'review_required_due_date',
    name: 'Required approval date',
    width: 150,
    filterField: 'approval_date',
    title: 'Required approval date',
    group: 'actions and dates',
    conditionType: 'DATE',
    editable: true,
    sortingField: 'review_required_due_date',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: false,
  },
  {
    key: 'required_due_date',
    name: 'Required on site date',
    width: 150,
    filterField: 'site_date',
    title: 'Required on site date',
    group: 'actions and dates',
    conditionType: 'DATE',
    editable: true,
    sortingField: 'required_due_date',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: false,
  },
  {
    key: 'courts',
    name: 'Ball-in-Court',
    width: 206,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'distributed_date',
    name: 'Distributed date',
    width: 132,
    filterField: 'distributed_date',
    title: 'Distributed date',
    group: 'actions and dates',
    conditionType: 'DATE',
    sortingField: 'distributed_date',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'distributed_by',
    name: 'Distributed by',
    width: 158,
    filterField: 'distributed_by',
    title: 'Distributed by',
    group: 'actions and dates',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'buildings',
    name: 'Buildings',
    width: 200,
    editable: true,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'locations',
    name: 'Locations',
    width: 200,
    editable: true,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'building_spaces',
    name: 'Building spaces',
    width: 200,
    editable: true,

    hideShowGroup: 'general',
    isDefaultVisible: true,
  },
  {
    key: 'documents',
    name: 'Design documents',
    width: 200,

    hideShowGroup: 'Plan & Control',
    isDefaultVisible: false,
  },
  {
    key: 'requests',
    name: 'Need forms',
    width: 200,

    hideShowGroup: 'Plan & Control',
    isDefaultVisible: false,
  },
  {
    key: 'deliverables',
    name: 'Deliverables',
    width: 200,

    hideShowGroup: 'Plan & Control',
    isDefaultVisible: false,
  },
  {
    key: 'date_created',
    name: 'Date created',
    width: 132,
    filterField: 'date_created',
    title: 'Date created',
    group: 'actions and dates',
    conditionType: 'DATE',
    sortingField: 'created_at',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: true,
  },
  {
    key: 'created_by',
    name: 'Created by',
    width: 200,
    filterField: 'created_by',
    title: 'Created by',
    group: 'actions and dates',

    hideShowGroup: 'actions and dates',
    isDefaultVisible: false,
  },
  {
    key: 'private',
    name: 'Private',
    width: 50,
    minWidth: 50,
    filterField: 'private',
    title: 'Private',
    group: 'general',
    editable: true,

    hideShowGroup: 'general',
    isDefaultVisible: false,
  },
  {
    key: 'hashtags',
    name: 'Tags',
    width: 200,
    filterField: 'hashtags',
    title: 'Tags',
    group: 'general',
    conditionType: 'TEXT',
    editable: true,
    isSwitchAnd: true,
    hideShowGroup: 'general',
    isDefaultVisible: false,
    sortingField: 'hashtag',
  },
  {
    key: 'cl_impacts',
    name: 'CL impact',
    width: 200,
    minWidth: 200,
    title: 'CL impact',
    group: 'plan',
    editable: true,

    hideShowGroup: 'Plan & Control',
    isDefaultVisible: true,
  },
  // ------------------ ADDITIONAL FILTERS -------------------
  // LOCATIONS
  {
    filterField: 'buildings',
    title: 'Buildings',
    group: 'locations',
  },
  {
    filterField: 'level',
    title: 'Level',
    group: 'locations',
  },
  {
    filterField: 'typical_layout',
    title: 'Typical Layout',
    group: 'locations',
  },
  {
    filterField: 'building_spaces',
    title: 'Building space',
    group: 'locations',
  },
  //DISCUSSION
  {
    filterField: 'comment_date',
    title: 'Comment date',
    group: 'discussion',
    conditionType: 'DATE',
  },
  {
    filterField: 'comment_author',
    title: 'Comment author',
    group: 'discussion',
  },
  {
    filterField: 'comment_mention',
    title: 'Comment mentions',
    group: 'discussion',
  },
  {
    filterField: 'bic_user',
    title: 'BIC (manager)',
    group: 'discussion',
  },
  {
    filterField: 'bic_party',
    title: 'BIC (company)',
    group: 'discussion',
  },
  {
    filterField: 'watcher',
    title: 'Watchers',
    group: 'discussion',
  },
  // REVIEW
  {
    filterField: 'review_discipline',
    title: 'Review party discipline',
    group: 'review',
  },
  {
    filterField: 'review_party',
    title: 'Review party company',
    group: 'review',
  },
  {
    filterField: 'review_user',
    title: 'Review party manager',
    group: 'review',
  },
  {
    filterField: 'review_due_date',
    title: 'Review due date',
    group: 'review',
    conditionType: 'DATE',
  },
  {
    filterField: 'reviewed_date',
    title: 'Reviewed date',
    group: 'review',
    conditionType: 'DATE',
  },
  {
    filterField: 'review_by',
    title: 'Reviewed by',
    group: 'review',
  },
  {
    filterField: 'review_response',
    title: 'Review response',
    group: 'review',
  },
  // PLAN
  {
    filterField: 'related_need_form',
    title: 'Related Need Forms',
    group: 'plan',
  },
  {
    filterField: 'related_deliverable',
    title: 'Related Deliverables',
    group: 'plan',
  },
  {
    filterField: 'cl_impact_yes',
    title: 'Systems affected',
    group: 'plan',
  },
  {
    filterField: 'cl_impact_no',
    title: 'Systems not affected',
    group: 'plan',
  },
  {
    filterField: 'cl_impact_not_reviewed',
    title: 'Systems not reviewed',
    group: 'plan',
  },
  {
    filterField: 'my_hot_list',
    title: 'Marked by me',
    group: 'plan',
  },
  {
    filterField: 'company_hot_list',
    title: 'Marked by my company',
    group: 'plan',
  },
];
