import React from 'react';
import SVG from 'react-inlinesvg';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Carousel from 'react-elastic-carousel';
import './ProjectGridStyle.scss';
interface IState {
  is_PopUpImage: boolean;
  handleClickItem: any;
  itemLength: number;
  itemIndex: number;
}
class ProjectGrid extends React.Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      is_PopUpImage: false,
      handleClickItem: null,
      itemLength: 0,
      itemIndex: 0,
    };
  }

  handleClickOpenImage = (item, index) => {
    if (item.renderings.length > 1) {
      this.setState({
        is_PopUpImage: true,
        handleClickItem: item,
        itemLength: item.renderings.length,
        itemIndex: index + 1,
      });
    }
  };

  closeDialog = () => {
    this.setState({
      is_PopUpImage: false,
      handleClickItem: null,
    });
  };

  handleChooseThumbnail = file => {
    if (file.is_final === 0 && this.props.isAdmin) {
      const formData = {
        main_image: {
          id: file.id ? file.id : 0,
          filepath: file.id ? '' : file.filepath,
        },
      };

      const project_id = this.state.handleClickItem.id;
      const selected_client_id = sessionStorage.getItem('selected_client_id');

      this.props.updateProject({ id: project_id, formData: formData });
      this.props.getClientsProjects({ clientId: +selected_client_id });
    }
    this.setState({
      is_PopUpImage: false,
      handleClickItem: null,
    });
  };

  render() {
    const { items, isAdmin, handleClickOpenEdit, projectTypes, currentClient } = this.props;

    const projectType = item => {
      if (item.project_type_id) {
        return projectTypes?.find(type => type.id === item.project_type_id).title;
      } else {
        return null;
      }
    };

    return (
      <div className={'b-project__gridContainer'}>
        {items &&
          items.map((item, i) => {
            const main_image = item.renderings?.find(image => image.is_final);

            return (
              <div className={`grid ${i < 7 ? 'gridBorder' : ''}`} key={item.id}>
                {main_image ? (
                  <div
                    className={`image ${item.renderings.length > 1 ? 'cursorImage' : ''}`}
                    style={{
                      backgroundImage: `url(${main_image.file})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    onClick={() => this.handleClickOpenImage(item, i)}
                  />
                ) : (
                  <div
                    className={`image ${item.renderings.length > 1 ? 'cursorImage' : ''}`}
                    onClick={() => this.handleClickOpenImage(item, i)}
                  >
                    <img src={require('../../../../assets/icons/projectPlug.svg')} alt="" />
                  </div>
                )}
                <div className={'gridContent'}>
                  <span className={'title'} onClick={() => this.props.selectProject(item.id)}>
                    {item?.title}
                  </span>
                  <span className={'text'}>{projectType(item)}</span>
                  <div className={'settings'}>
                    <span>{currentClient?.title}</span>
                    {isAdmin && (
                      <div className={'settings__wrapIcon'} onClick={() => handleClickOpenEdit(item)}>
                        <SVG className={`b-header__icon`} src={require('../../../../assets/icons/settings.svg')} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        <Dialog
          open={this.state.is_PopUpImage}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="false"
          PaperProps={{
            className: 'modalPaper',
            style: {
              maxWidth: '1110px',
              maxHeight: '410px',
              position: 'absolute',
              top: `${253 + Math.floor(this.state.itemIndex / 7) * 100}px`,
              left: `${this.state.itemIndex - 7 * Math.floor(this.state.itemIndex / 7) <= 3 ? '90px' : 'auto'}`,
              right: `${this.state.itemIndex - 7 * Math.floor(this.state.itemIndex / 7) <= 3 ? 'auto' : '0'}`,
              display: 'block',
            },
          }}
        >
          <DialogContent className="dialogContent" style={{ backgroundColor: 'var(--background-carousel)' }}>
            <Carousel itemsToShow={3} showArrows={this.state.itemLength > 3 ? true : false}>
              {this.state.handleClickItem?.renderings &&
                this.state.handleClickItem.renderings.map(file => {
                  const isChosen = file.is_final == 1;
                  return (
                    <div
                      className={`fileGalleryGrid__item ${isChosen ? '-chosenItem' : ''} ${isAdmin ? 'fileGalleryGrid__itemAdmin' : ''}`}
                      //onClick={() => this.handleChooseThumbnail(file)}
                      key={file.id}
                      style={{
                        backgroundImage: `url(${file.fullpath || file.file})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                  );
                })}
            </Carousel>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ProjectGrid;
