export const updateMilestone = (milestoneId: string | number, userAPIKey: string, body) => {
  const result = fetch(`${process.env.RM_BASE_URL}/versions/${milestoneId}.json`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': userAPIKey,
    },
    redirect: 'follow',
    body: JSON.stringify(body),
  });

  return result;
};

export const updateMilestoneDueDate = (milestoneId: string | number, userAPIKey: string, date) => {
  if (!milestoneId && !userAPIKey) return;
  const body = {
    version: {
      due_date: date,
    },
  };
  updateMilestone(milestoneId, userAPIKey, body);
};
