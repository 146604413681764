import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/configure/configureStore';
import DialogPopUp from '../../../../../../controls/DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import { CustomButton, SystemButton } from '../../../../../../controls/ButtonComponents';
import TokenIcon from '../../../../../../controls/TokenIcon/TokenIcon';
import { CodeSelector, Select } from '../../../../../../controls/Dropdowns/DropdownСomponents';
import { Badge } from '../../../../../TeamTab/Badge';
import { getListDisciplines } from '../../../../../../../store/project/projectLogic';
import { BuildingModel, LocationModel, SimpleTitleModel, TypicalLayoutModel } from '../../../../../../../models';

import './TypicalEditorStyles.scss';

interface IProps {
  locations: LocationModel[];
  typical_layouts: TypicalLayoutModel[];
  updateTypical: (ranges) => void;
  isTypicalLayout: boolean;
  handleCloseTypicalLayout: () => void;
  building: BuildingModel<SimpleTitleModel[]>;
}

const TypicalEditor: FC<IProps> = ({ typical_layouts, updateTypical, isTypicalLayout, handleCloseTypicalLayout, building, locations }) => {
  const { updateLocationBuildingStatus } = useAppSelector(state => state.phasePlan);

  const [range, setRange] = useState([]);
  const [errors, setErrors] = useState({});
  const [disciplines, setDisciplines] = useState([]);

  const containerRef = useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();

  const themeClass = useGetThemeClass('b-typicalEditor');

  useEffect(() => {
    if (typical_layouts?.length) {
      setRange(transformToRange(typical_layouts));
    }
  }, [typical_layouts, locations]);

  useEffect(() => {
    if (updateLocationBuildingStatus) {
      handleCloseTypicalLayout();
    }
  }, [updateLocationBuildingStatus]);

  useEffect(() => {
    if (isTypicalLayout) {
      dispatch(getListDisciplines()).then(res => {
        setDisciplines([...Object.values(res.payload)]);
      });
    }
  }, [isTypicalLayout]);

  const transformToRange = ranges => {
    const range = [];
    locations.length &&
      ranges.map(r => {
        if (r.start_layout_id && r.end_layout_id) {
          range.push({
            id: r.id,
            start_position: locations.findIndex(l => l.id === r.start_layout_id),
            end_position: locations.findIndex(l => l.id === r.end_layout_id),
            discipline: r.discipline || null,
            uf: r.uf || null,
          });
        } else {
          range.push({
            id: r.id,
            start_position: locations.findIndex(l => l.position === r.start_position),
            end_position: locations.findIndex(l => l.position === r.end_position),
            discipline: r.discipline || null,
            uf: r.uf || null,
          });
        }
      });
    return range;
  };

  const handleChangeSelect = (name, index) => async selected => {
    const rangeCopy = [...range];
    if (name === 'uf' || name === 'discipline') {
      rangeCopy[index][name] = selected;
    } else {
      const item = locations.findIndex(l => l.position === selected.position);
      rangeCopy[index][name] = item;
    }
    await setRange(rangeCopy);
    await setErrors(prevState => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: false,
      },
    }));
    if (rangeCopy[index].start_position > -1 && rangeCopy[index].end_position > -1) {
      const ranges = collectTypicalLayouts();
      updateTypical(ranges);
    }
  };

  const collectTypicalLayouts = () => {
    const ranges = [];
    range.map(r => {
      ranges.push({
        id: r.id || null,
        start_position: locations?.[r.start_position]?.position,
        end_position: locations?.[r.end_position]?.position,
        discipline: r.discipline || null,
        uf: r.uf || null,
      });
    });
    return ranges;
  };

  const handleAddRange = () => {
    const rangeCopy = [...range];
    rangeCopy.push({});
    setRange(rangeCopy);
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
      }
    }, 200);
  };

  const removeTypicalLayout = index => () => {
    const rangeCopy = [...range];
    rangeCopy.splice(index, 1);
    updateTypical(rangeCopy);
    setRange(rangeCopy);
  };

  const renderModalContent = () => (
    <div className={themeClass}>
      <div className={`${themeClass}__column`} ref={containerRef}>
        {range.length
          ? range.map((r, i) => {
              const endLocations = new Map();
              [...locations]
                .filter(loc => loc.id)
                .splice(r.start_position, locations.length)
                .map(l => {
                  endLocations.set(l.position, l);
                });
              let endLocationsData = [];
              endLocations.forEach(i => endLocationsData.push(i));

              let stopEnv = null;
              if (endLocationsData.length) {
                let lastEnv = null;
                for (let i = 0; i <= endLocationsData.length - 1; i++) {
                  if (endLocationsData[i]?.position - 1 !== lastEnv && !stopEnv) {
                    stopEnv = i;
                  }
                  lastEnv = endLocationsData[i]?.position;
                }
              }
              stopEnv ? (endLocationsData = endLocationsData.splice(0, stopEnv)) : null;
              return (
                <div key={i} className={`${themeClass}__row`}>
                  <div className={`${themeClass}__item`}>
                    <Select
                      isMulti={false}
                      menuItems={locations.filter(loc => loc.id)}
                      selectHandler={handleChangeSelect('start_position', i)}
                      selected={locations[r.start_position]}
                      menuItemSize={'md'}
                      error={errors[i]?.start_position}
                      basisPlaceholder={'From'}
                    />
                  </div>
                  <div className={`${themeClass}__item`}>
                    <Select
                      isMulti={false}
                      menuItems={[...endLocationsData]}
                      selectHandler={handleChangeSelect('end_position', i)}
                      selected={locations[r.end_position]}
                      menuItemSize={'md'}
                      disabled={!(locations[r.start_position]?.position >= 0)}
                      error={errors[i]?.end_position}
                      basisPlaceholder={'To'}
                    />
                  </div>
                  <div className={`${themeClass}__item`}>
                    <CodeSelector
                      isMulti={false}
                      codeType={'uf'}
                      handleFor={'content-plan'}
                      selected={r.uf}
                      selectHandler={handleChangeSelect('uf', i)}
                      basisPlaceholder={`Uniformat`}
                      menuItemSize={'md'}
                      basisMaxItems={5}
                    />
                  </div>
                  <div className={`${themeClass}__item`}>
                    <Select
                      basisPlaceholder="Discipline"
                      isMulti={false}
                      selected={r.discipline}
                      menuItems={disciplines}
                      menuItemTitleRenderer={item => <Badge item={item} type={'discipline'} render={item => item.title} />}
                      renderBasisSelectedItem={item => <Badge item={item} type={'discipline'} render={item => item.title} />}
                      selectHandler={handleChangeSelect('discipline', i)}
                      menuItemSize="md"
                    />
                  </div>
                  <SystemButton type={'delete'} size={'md'} variant={'transparent'} clickHandler={removeTypicalLayout(i)} />
                </div>
              );
            })
          : null}
      </div>
      <CustomButton
        type={'text-plain'}
        size={'md'}
        title={'Add Range'}
        clickHandler={handleAddRange}
        iconClass={`${themeClass}__btnIcon`}
        icon={<TokenIcon iconName={'plus'} size={16} />}
      />
    </div>
  );

  if (!isTypicalLayout) {
    return <></>;
  }

  return (
    <DialogPopUp
      open={isTypicalLayout}
      onClose={handleCloseTypicalLayout}
      title={'Typical layouts'}
      dividedHeader
      secondaryText={'Cancel'}
      paperMaxWidth={'842px'}
      customClassname={`${themeClass}__dialog`}
      handleOnSecondary={handleCloseTypicalLayout}
      renderModalContent={renderModalContent}
    />
  );
};
export default TypicalEditor;
