import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { UserReducerModel } from './userReducerModel';
import Api from '../../service/api';
import {
  createClient,
  forgotPassword,
  getClientFromProject,
  getClientsProjects,
  getPromptsAlerts,
  getUserProfile,
  inviteUser,
  login,
  logout,
  resetPassword,
  setProjectNotificationSettings,
  setPromptsAlerts,
  setUserBanner,
  setUserSeenReleaseNotes,
  setUserSeenReleaseSandboxTour,
  unsubscribeReleaseUser,
  updateClient,
  updateUser,
  userInfo,
  watcherAdd,
  watcherRemove,
} from './userThunk';
import {
  CreateClientResponseModel,
  ForgotPasswordResponse,
  GetClientFromProjectResponseModel,
  GetClientProjectsResponseModel,
  GetPromptsAlertsResponseModel,
  GetUserProfileResponseModel,
  ProjectSimpleModel,
  UpdateUserPayloadModel,
  UserResponseModel,
} from '../../models';
import { BaseResponse, ErrorResponseModel } from '../../models/Api/response.model';

const initialState: UserReducerModel = {
  userInfo: {
    id: 0,
    email: '',
    first_name: '',
    image: '',
    is_notification: 0,
    is_seen_banner: 0,
    last_name: '',
    new_notifications_count: 0,
    parties: [],
    phone: '',
    projects: [],
    roles: [],
    seen_tutorial: 0,
    seen_whatsnew: 0,
    is_only_my: 0,
    is_only_discipline: 0,
    title: '',
    whats_new_unread: 0,
    user_parties: [],
    user_partie: '',
    is_seen_release_notes: 0,
    is_seen_sandbox_tour: 0,
    clear_cache: 0,
    theme: (localStorage.getItem('theme') as 'dark' | 'light') || 'light',
    visibility_flags: {
      pcd: 'hide',
      ddl: 'hide',
      plan: 'hide',
    },
  },
  user_id: +localStorage.getItem('user_id') || '',
  active_project_id: 0,
  selected_client_id: '',
  message: '',
  loading: false,
  status: null,
  notifications_count: localStorage.getItem('notifications_count') ? +localStorage.getItem('notifications_count') : 0,
  userIPv4: null,
  clientProjects: [],
  clients: [],
  clientProjectsLoading: false,
  isFirstClientProjectLoading: true,
  isGetClientFromProject: false,
  isSetDefaultColumnOrder: false,
  setDefaultColumnLoading: false,
  promptsAlerts: '',
  promptsAlertsLoading: false,
  avatar: '',
  projectSettingsStatus: Api.initialStatus,
  //invite_data
  invite_data: null,
  update_data: {
    message: '',
    status: '',
    errors: '',
  },
  clientError: null,
  forgot_data: null,
  reset_data: '',
  //unsubscribe
  unsubscribeReleaseUserMSG: '',
  unsubscribeReleaseUserStatus: '',
  unsubscribeReleaseUserLoading: false,
  user_profile: null,
  is_get_user_profile: false,
};

export const userSlice = createSlice({
  initialState,
  name: 'userReducer',
  reducers: {
    incrementNotificationsCounter(state) {
      state.notifications_count = state.notifications_count + 1;
    },
    setActiveProject(state, { payload }: PayloadAction<number>) {
      let active_project_id = state.active_project_id;

      if ((payload || payload === 0) && typeof payload === 'number') {
        sessionStorage.setItem('active_project_id', payload.toString());
        const selectedProject = state.clientProjects.find(project => project.id === Number(payload));
        if (selectedProject) {
          sessionStorage.setItem('project_name', payload ? selectedProject.code : 'no-projects-yet');
        }
        active_project_id = payload;
      }

      state.active_project_id = active_project_id;
      if (payload === undefined && active_project_id === null) {
        sessionStorage.setItem('active_project_id', '0');
        state.active_project_id = 0;
      }
    },
    watcherAddClean(state) {
      state.loading = false;
      state.status = true;
    },
    addedNewProject(state, { payload }: PayloadAction<BaseResponse<ProjectSimpleModel>>) {
      const projects = [...state.userInfo.projects];
      projects.push(payload.data);

      state.userInfo = {
        ...state.userInfo,
        projects: projects,
      };
    },
    getIPv4(state, { payload }: PayloadAction<string>) {
      state.userIPv4 = payload;
    },
    reduceNewsCount(state) {
      const user = { ...state.userInfo };
      user.whats_new_unread = user.whats_new_unread - 1 < 0 ? 0 : user.whats_new_unread - 1;

      state.userInfo = user;
    },
    zeroNewsCount(state) {
      const user = { ...state.userInfo };
      user.whats_new_unread = 0;

      state.userInfo = user;
    },
    updateUserInfo(state, { payload }: PayloadAction<UserResponseModel>) {
      state.userInfo = payload;
    },
    setToDefault(state, { payload }: PayloadAction<UserResponseModel>) {
      state.userInfo = {
        id: 0,
        email: '',
        first_name: '',
        image: '',
        is_notification: 0,
        is_seen_banner: 0,
        last_name: '',
        new_notifications_count: 0,
        parties: [],
        phone: '',
        projects: [],
        roles: [],
        seen_tutorial: 0,
        seen_whatsnew: 0,
        is_only_my: 0,
        is_only_discipline: 0,
        title: '',
        whats_new_unread: 0,
        user_parties: [],
        user_partie: '',
        is_seen_release_notes: 0,
        is_seen_sandbox_tour: 0,
        clear_cache: 0,
        theme: (localStorage.getItem('theme') as 'dark' | 'light') || 'light',
        visibility_flags: {
          pcd: 'hide',
          ddl: 'hide',
          plan: 'hide',
        },
      };
      state.user_id = '';
      state.active_project_id = 0;
      state.selected_client_id = '';
      state.message = '';
      state.loading = false;
      state.status = null;
      state.notifications_count = localStorage.getItem('notifications_count') ? +localStorage.getItem('notifications_count') : 0;
      state.userIPv4 = null;
      state.clientProjects = [];
      state.clients = [];
      state.clientProjectsLoading = false;
      state.isFirstClientProjectLoading = true;
      state.isGetClientFromProject = false;
      state.isSetDefaultColumnOrder = false;
      state.setDefaultColumnLoading = false;
      state.promptsAlerts = '';
      state.promptsAlertsLoading = false;
      state.avatar = '';
      state.projectSettingsStatus = Api.initialStatus;
      //invite_data
      state.invite_data = null;
      state.update_data = { message: '', status: '', errors: '' };
    },
    setDefaultColumnOrder(state, { payload }: PayloadAction<boolean>) {
      if (payload) {
        state.isSetDefaultColumnOrder = payload;
        state.setDefaultColumnLoading = payload;
      } else {
        state.isSetDefaultColumnOrder = payload;
      }
    },
    changeUserAvatar(state, { payload }: PayloadAction<string>) {
      state.avatar = payload;
    },
    clearMessage(state) {
      state.message = '';
      state.status = null;
    },
    clearClientError(state) {
      state.clientError = null;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * login
       */
      .addCase(login.pending, state => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }: PayloadAction<UserResponseModel>) => {
        const role_id = Object.keys(payload.data.roles)[0];
        localStorage.setItem('user_id', String(payload.data.id));
        localStorage.setItem('isPro', role_id);
        localStorage.setItem('notifications_count', String(payload.data.notifications_count));
        localStorage.setItem('theme', payload.data.theme);
        /*if (payload.data.first_login === 1) {
          localStorage.setItem('inviteUser', 'invite');
        }*/

        state.user_id = payload.data.id;
        state.notifications_count = payload.data.notifications_count;
        state.userInfo = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(login.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.message = payload.data.message;
        state.status = payload.status;
        state.loading = false;
      })
      /**
       * logout
       */
      .addCase(logout.pending, state => {
        return state;
      })
      .addCase(logout.fulfilled, state => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('active_project_id');
        sessionStorage.removeItem('project_name');
        localStorage.removeItem('notifications_count');
        localStorage.removeItem('isShowNews1');
        localStorage.removeItem('sandbox');
        localStorage.removeItem('needsList');
        localStorage.removeItem('pcd');
        localStorage.removeItem('ddl');

        return {
          ...initialState,
          user_id: null,
          active_project_id: null,
          notifications_count: null,
          selected_client_id: null,
        };
      })
      .addCase(logout.rejected, state => {
        return state;
      })
      /**
       * userInfo
       */
      .addCase(userInfo.pending, state => {
        state.loading = true;
      })
      .addCase(userInfo.fulfilled, (state, { payload }: PayloadAction<UserResponseModel>) => {
        const role_id = Object.keys(payload.data.roles)[0];
        localStorage.setItem('user_id', String(payload.data.id));
        localStorage.setItem('isPro', role_id);
        localStorage.setItem('notifications_count', String(payload.data.notifications_count));
        localStorage.setItem('theme', payload.data.theme);

        state.user_id = payload.data.id;
        state.notifications_count = payload.data.notifications_count;
        state.userInfo = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(userInfo.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      /**
       * getClientsProjects
       */
      .addCase(getClientsProjects.pending, state => {
        state.clientProjectsLoading = true;
      })
      .addCase(getClientsProjects.fulfilled, (state, { payload }: PayloadAction<GetClientProjectsResponseModel>) => {
        if (payload?.url_project) {
          sessionStorage.setItem('active_project_id', payload.url_project);
        }

        state.clientProjects = payload?.data?.projects;
        state.clients = payload?.data?.clients;
        state.clientProjectsLoading = false;
        state.isFirstClientProjectLoading = false;
        state.selected_client_id = payload?.selected_client_id;
      })
      .addCase(getClientsProjects.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.isFirstClientProjectLoading = false;
        state.clientProjectsLoading = false;
      })
      /**
       * inviteUser
       */
      .addCase(inviteUser.pending, state => {
        state.loading = true;
      })
      .addCase(inviteUser.fulfilled, (state, { payload }: PayloadAction<UserResponseModel>) => {
        const role_id = payload.data.roles ? Object.keys(payload.data.roles)[0] : 0;
        localStorage.setItem('user_id', String(payload.data.id));
        localStorage.setItem('isPro', String(role_id));
        localStorage.setItem('notifications_count', String(payload.data.notifications_count));
        localStorage.setItem('theme', payload.data.theme);
        /*localStorage.setItem('inviteUser', 'invite');*/

        state.user_id = payload.data.id;
        state.notifications_count = payload.data.notifications_count;
        state.userInfo = payload.data;
        // @ts-ignore
        state.invite_data = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(inviteUser.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.message = payload.data?.errors?.password[0] || payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      /**
       * forgotPassword
       */
      .addCase(forgotPassword.pending, state => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }: PayloadAction<ForgotPasswordResponse>) => {
        // @ts-ignore
        state.forgot_data = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      /**
       * resetPassword
       */
      .addCase(resetPassword.pending, state => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }: PayloadAction<UserResponseModel>) => {
        // @ts-ignore
        state.reset_data = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      /**
       * updateUser
       */
      .addCase(updateUser.pending, (state, { meta }) => {
        const currentState = current(state);

        if (meta.arg?.theme) {
          state.userInfo = {
            ...currentState.userInfo,
            theme: meta.arg?.theme,
          };
        }
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, state => {
        // @ts-ignore
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.loading = false;
        state.update_data = {
          message: payload.data?.message,
          status: payload.data?.status,
          errors: payload.data?.errors,
        };
      })
      /**
       * watcherAdd
       */
      .addCase(watcherAdd.pending, state => {
        state.loading = true;
      })
      .addCase(watcherAdd.fulfilled, state => {
        // @ts-ignore
        state.loading = false;
        state.status = 'watching';
      })
      .addCase(watcherAdd.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.loading = false;
        state.status = false;
      })
      /**
       * setUserBanner
       */
      .addCase(setUserBanner.pending, state => {
        state.loading = true;
      })
      .addCase(setUserBanner.fulfilled, state => {
        // @ts-ignore
        state.loading = false;
      })
      .addCase(setUserBanner.rejected, state => {
        state.loading = false;
      })
      /**
       * setUserSeenReleaseNotes
       */
      .addCase(setUserSeenReleaseNotes.pending, state => {
        state.loading = true;
      })
      .addCase(setUserSeenReleaseNotes.fulfilled, state => {
        // @ts-ignore
        state.loading = false;
        state.userInfo.is_seen_release_notes = 1;
      })
      .addCase(setUserSeenReleaseNotes.rejected, state => {
        state.loading = false;
        state.isSetDefaultColumnOrder = true;
      })
      /**
       * setUserSeenReleaseSandboxTour
       */
      .addCase(setUserSeenReleaseSandboxTour.pending, state => {
        state.loading = true;
      })
      .addCase(setUserSeenReleaseSandboxTour.fulfilled, state => {
        // @ts-ignore
        state.loading = false;
        state.userInfo.is_seen_sandbox_tour = 1;
      })
      .addCase(setUserSeenReleaseSandboxTour.rejected, state => {
        state.loading = false;
      })
      /**
       * getClientFromProject
       */
      .addCase(getClientFromProject.pending, state => {
        state.isGetClientFromProject = true;
      })
      .addCase(getClientFromProject.fulfilled, (state, { payload }: PayloadAction<GetClientFromProjectResponseModel>) => {
        if (payload.data.project_id) {
          sessionStorage.removeItem('active_project_id');
          sessionStorage.setItem('active_project_id', payload.data.project_id?.toString());
        }
        // else {
        //   sessionStorage.removeItem('active_project_id');
        //   sessionStorage.setItem('active_project_id', '0');
        // }

        state.isGetClientFromProject = false;
        state.active_project_id = payload.data.project_id ? payload.data.project_id : state.active_project_id;
      })
      .addCase(getClientFromProject.rejected, state => {
        state.isGetClientFromProject = false;
      })
      /**
       * createClient
       */
      .addCase(createClient.pending, state => {
        state.clientProjectsLoading = true;
      })
      .addCase(createClient.fulfilled, (state, { payload }: PayloadAction<CreateClientResponseModel>) => {
        state.clients = payload.data.clients;
        state.userInfo.clients = payload.data.clients;
        state.clientProjectsLoading = false;
        state.clientError = null;
      })
      .addCase(createClient.rejected, (state, action) => {
        state.clientProjectsLoading = false;
        state.clientError = action.payload.message;
      })
      /**
       * updateClient
       */
      .addCase(updateClient.pending, state => {
        state.clientProjectsLoading = true;
      })
      .addCase(updateClient.fulfilled, (state, { payload }: PayloadAction<CreateClientResponseModel>) => {
        state.clients = payload.data.clients;
        state.userInfo.clients = payload.data.clients;
        state.clientProjectsLoading = false;
        state.clientError = null;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.clientProjectsLoading = false;
        state.clientError = action.payload.message;
      })
      /**
       * getPromptsAlerts
       */
      .addCase(getPromptsAlerts.pending, state => {
        state.promptsAlertsLoading = true;
      })
      .addCase(getPromptsAlerts.fulfilled, (state, { payload }: PayloadAction<GetPromptsAlertsResponseModel>) => {
        state.promptsAlertsLoading = false;
        state.promptsAlerts = payload.data.settingPromptsAlerts;
      })
      .addCase(getPromptsAlerts.rejected, state => {
        state.promptsAlertsLoading = false;
      })
      /**
       * setPromptsAlerts
       */
      .addCase(setPromptsAlerts.pending, (state, { meta }: PayloadAction<null, string, { arg: string }>) => {
        state.promptsAlerts = meta.arg;
      })
      .addCase(setPromptsAlerts.fulfilled, state => {
        return state;
      })
      .addCase(setPromptsAlerts.rejected, state => {
        return state;
      })
      /**
       * unsubscribeReleaseUser
       */
      .addCase(unsubscribeReleaseUser.pending, state => {
        state.unsubscribeReleaseUserLoading = true;
      })
      .addCase(unsubscribeReleaseUser.fulfilled, state => {
        state.unsubscribeReleaseUserLoading = false;
        state.unsubscribeReleaseUserMSG = `Thanks, you've successfully unsubscribed from our release notes email. The description of the latest features and updates will always be available in 'Help>What's new?' menu.`;
        state.unsubscribeReleaseUserStatus = 'SUCCESS';
      })
      .addCase(unsubscribeReleaseUser.rejected, state => {
        state.unsubscribeReleaseUserLoading = false;
        state.unsubscribeReleaseUserMSG = "Didn't unsubscribe";
        state.unsubscribeReleaseUserStatus = 'FAIL';
      })
      /**
       * setProjectNotificationSettings
       */
      .addCase(setProjectNotificationSettings.pending, state => {
        state.projectSettingsStatus = Api.requestStatus;
      })
      .addCase(setProjectNotificationSettings.fulfilled, state => {
        state.projectSettingsStatus = Api.successStatus;
      })
      .addCase(setProjectNotificationSettings.rejected, state => {
        state.projectSettingsStatus = Api.failStatus;
      })
      /**
       * getUserProfile
       */
      .addCase(getUserProfile.pending, state => {
        state.is_get_user_profile = true;
        state.user_profile = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }: PayloadAction<GetUserProfileResponseModel>) => {
        state.is_get_user_profile = false;
        state.user_profile = payload.data;
      })
      .addCase(getUserProfile.rejected, state => {
        state.is_get_user_profile = false;
      });
  },
});

export default userSlice.reducer;
