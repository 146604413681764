import moment from 'moment';
import { KeyValueModel } from '../models/key-value.model';
import { SimpleTitleModel } from '../models';

export default class FiltersHelper {
  static resetFilters = filters => {
    Object.keys(filters).map(filterKey => {
      filters[filterKey].map(f => (f.isChecked = false));
    });
    return filters;
  };

  static collectFilter = (filter, title, value, isAll = false) => {
    // eslint-disable-next-line no-undef
    const collection = new Map();
    const keys = Object.keys(filter);
    if (isAll) {
      collection.set('all', {
        title: 'All',
        value: 'all',
        isChecked: false,
      });
    }
    keys.map(f => {
      const collectionValue = {
        title: filter[f][title],
        value: filter[f][value],
        isChecked: false,
      };
      collection.set(f, collectionValue);
    });
    return [...collection.values()];
  };

  static getFilteredNames = filter => {
    const filterArray = filter ? Object.values(filter).filter(f => f.isChecked) : [];
    if (filterArray.length) {
      const res = filterArray.map((f, index) => {
        return `${f.title} ${filterArray.length !== index + 1 ? ', ' : ''}`;
      });
      return res.join('');
    } else {
      return '';
    }
  };

  static getDateNames = date => {
    if (date?.length) {
      const res = date.map((d, index) => {
        return `${moment(d).format('MM-DD')} ${date.length !== index + 1 ? ' – ' : ''}`;
      });
      return res.join('');
    }
    if (date) {
      return `${moment(date).format('MM-DD')}`;
    }
    if (!date) {
      return '';
    }
  };

  static checkIsExistResetFilter = filters => {
    if (!filters) {
      return false;
    }
    let isExistFilters = false;
    Object.keys(filters).map(name => {
      if (filters[name].find(f => f.isChecked)) {
        return (isExistFilters = true);
      }
    });
    return isExistFilters;
  };

  static convertFilterFromApi = (filtersFromApi, queryFilters) => {
    const filters = {};
    for (const key in filtersFromApi) {
      filters[key] = key === 'due_date' ? { filter: [] } : [];
      const keyInto = key;

      // for (const value in filtersFromApi[keyInto]) {
      //   let filterField = keyInto;
      //   if (keyInto === 'tags') {
      //     filterField = 'tag_ids';
      //   }
      //   let isChecked = false;
      //   if (queryFilters) {
      //     if (filterField === 'due_date') {
      //       console.log('queryFilters', queryFilters, queryFilters?.due_date);
      //       debugger
      //       let valueId;
      //       if (queryFilters.due_date?.length) {
      //         valueId = queryFilters?.due_date?.filter?.find(item => item == value);
      //       } else {
      //         valueId = queryFilters?.due_date?.filter?.find(item => item == value);
      //       }
      //       if (typeof(valueId) !== "undefined" && valueId !== null) {
      //         isChecked = true;
      //       }
      //     } else {
      //       const valueId = queryFilters[filterField]?.find(item => item == value);
      //       if (typeof(valueId) !== "undefined" && valueId !== null) {
      //         isChecked = true;
      //       }
      //     }
      //   }
      //   const data = {
      //     id: value,
      //     value: filtersFromApi[keyInto][value],
      //     isChecked: !!queryFilters && !!isChecked,
      //   };
      //   (keyInto === 'due_date' ? filters[keyInto].filter : filters[keyInto]).push(data);
      // }
    }
    if (queryFilters?.created_at) {
      filters['created_at'] = queryFilters.created_at;
    }
    // if (queryFilters?.due_date) {
    //   filters['due_date'] = queryFilters.due_date;
    // }
    return filters;
  };

  static getRequestFiltersForApi = (selectedFilters, startDate, endDate) => {
    const query_params = {};
    const filters = {};
    if (selectedFilters.filters) {
      Object.keys(selectedFilters.filters || {}).map(key => {
        filters[key] = selectedFilters?.filters[key].map(f => f?.id || f?.value || f);
      });
    }
    if (Object.values(selectedFilters?.filters || {}).length) {
      query_params.filters = filters;
    }
    if (Object.values(selectedFilters?.searches || {}).length) {
      query_params.searches = selectedFilters.searches;
    }
    if (startDate && endDate) {
      query_params.start_due_date = startDate;
      query_params.end_due_date = endDate;
    }
    return query_params;
  };

  static convertFiltersValueObjectsToIds = (filters: KeyValueModel<SimpleTitleModel[]>) => {
    return Object.keys(filters).reduce((acc, filterName) => {
      if (filters[filterName].length) {
        acc = {
          ...acc,
          [filterName]: filters[filterName].map(f => (f?.id || f?.id === 0 ? f?.id : f)),
        };
      }
      return acc;
    }, {} as KeyValueModel<(string | number)[]>);
  };

  static convertConditionsFilters = (filters: KeyValueModel<SimpleTitleModel[]>) => {
    return Object.keys(filters).reduce((acc, filterName) => {
      const value = Object.values(filters[filterName]);
      const isValue = value.length ? !!value[0] : false;
      if (isValue) {
        acc = {
          ...acc,
          [filterName]: filters[filterName],
        };
      }
      return acc;
    }, {} as KeyValueModel<(string | number)[]>);
  };
}
