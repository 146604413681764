import React, { FC, useEffect, useState } from 'react';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../../../models/global';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { RequestModel, SelectedRequestCompanyModel } from '../../../../../models';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useAppDispatch } from '../../../../../store/configure/configureStore';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';

const { setCommitmentAction } = requestSlice.actions;

const initialActions = [
  {
    value: 'provide_response',
    title: 'Provide response rightaway',
    tooltip:
      'Choose this option if you have all the information necessary to provide a response. You will be redirected to the response tab where you can submit your response.',
    disabled: false,
  },
  {
    value: 'commit_now',
    title: 'Commit now',
    tooltip: 'Choose this option if you can deliver necessary information by the specified due date.',
    disabled: false,
  },
  {
    value: 'commit_with_due_date',
    title: 'Commit with due date change',
    tooltip:
      'Choose this option if you can provide a response by a date other than specified in the request. You will be asked to suggest a new due date.',
    disabled: false,
  },
  {
    value: 'commit_with_pull_request',
    title: 'Commit with a pull request',
    tooltip:
      'Choose this option if you need to create another request to provide the response. You will be asked to create and send a new NF.',

    disabled: false,
  },
  {
    value: 'redirect_to_party',
    title: 'Redirect NF to different party',
    tooltip:
      'Choose this option if you should not be responsible for providing the response. You will be asked to pick a new response party.',
    disabled: false,
  },
  {
    value: 'commit_with_submittal_resolution',
    title: 'Commit with submittal resolution',
    tooltip:
      'Choose this option if you expect the future Submittal to provide the answer once it’s submitted. You will be asked to draft a Submittal card.',
    disabled: false,
  },
];

interface IProps {
  selectedRequest: RequestModel;
  selectRequestId: number | null;
  isPullRequest: boolean;
  isDueDateDisabled: boolean;
  requestSave: (whiteboard: number, files?, filesToDelete?: number[]) => void;
  requestLoading: boolean;
  isAccessToCommitment: boolean;
  selectedRequestCompany: SelectedRequestCompanyModel;
  canUpdate: boolean;
  animationBtn: string | null;
  projectInfo;
  requestSavePrompt: (whiteboard: number, files?) => void;
  openPromptRequestStatus: (nameBtn: string) => void;
}

const RequestTabButtons: FC<IProps> = ({
  selectedRequestCompany,
  canUpdate,
  projectInfo,
  requestSavePrompt,
  animationBtn,
  selectedRequest,
  selectRequestId,
  isPullRequest,
  isDueDateDisabled,
  requestSave,
  requestLoading,
  isAccessToCommitment,
  openPromptRequestStatus,
}) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-cardRequest-requestTab');
  const showCommitmentButton = selectedRequest?.status === 'sent' || selectedRequest?.status === 'submitted';
  const { isMobile } = GetResolution();

  const [actions, setActions] = useState(initialActions);

  useEffect(() => {
    setActions(prevState => {
      return prevState.map(item => {
        if (item.value === 'commit_with_due_date') {
          return { ...item, disabled: isDueDateDisabled };
        }

        if (item.value === 'commit_with_pull_request') {
          return { ...item, disabled: isMobile };
        }

        return item;
      });
    });
  }, [isDueDateDisabled, isMobile]);

  const saveDraft = () => {
    if (!selectedRequest?.is_whiteboard) {
      requestSave(0);
    }
  };

  const renderCustomButtonBasis = () => {
    return (
      <CustomButton
        type={'primary'}
        size={'md'}
        title={'Commit'}
        disabled={!selectedRequest?.status || requestLoading || !!selectedRequest?.is_deactivated || !isAccessToCommitment}
        customClassName={`${themeClass}_buttonsGroup_button`}
        tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Options for providing commitment'}
        isMobile={isMobile}
      />
    );
  };
  const renderRightIcon = (item): JSX.Element => {
    return (
      <CustomTooltip title={item.tooltip} placement={'bottom'}>
        <div className={`${themeClass}_icon`}>
          <TokenIcon iconName={'information'} size={16} />
        </div>
      </CustomTooltip>
    );
  };

  const handleCommitmentAction = (action: string) => {
    if (action === 'commit_with_due_date' && isDueDateDisabled) {
      return;
    }
    dispatch(setCommitmentAction(action));
  };

  const renderUpdateBtnTooltip = () => {
    if ((!canUpdate && selectedRequest?.is_whiteboard) || requestLoading || !selectedRequestCompany?.company) {
      return '';
    } else {
      if (localStorage.getItem('show_tooltips') === 'no') {
        return '';
      } else {
        if (selectRequestId && selectedRequest?.is_whiteboard) {
          return 'Apply changes to NF';
        } else {
          return 'Save this NF and send it to the Response party';
        }
      }
    }
  };

  const renderDraftBtnTooltip = () => {
    if (localStorage.getItem('show_tooltips') === 'no') {
      return '';
    } else {
      if (!selectRequestId) {
        return 'Save NF as a draft. It will be possible to send it later';
      } else {
        return 'Apply changes to NF';
      }
    }
  };

  return (
    <div className={`${themeClass}_buttonsBlock`}>
      <div className={`${themeClass}_buttonsGroup`}>
        {(!selectedRequest?.is_whiteboard || !selectRequestId || isPullRequest) && (
          <CustomButton
            type={'tertiary'}
            size={'md'}
            clickHandler={saveDraft}
            title={!selectRequestId ? 'Save draft' : 'Update draft'}
            loading={requestLoading && animationBtn === 'draftBtn'}
            disabled={requestLoading || !projectInfo?.parties}
            customClassName={`${themeClass}_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
            tooltip={renderDraftBtnTooltip()}
            isMobile={isMobile}
          />
        )}
        <CustomButton
          type={selectedRequest?.author?.profile && !isPullRequest && showCommitmentButton ? 'tertiary' : 'primary'}
          size={'md'}
          clickHandler={
            isPullRequest
              ? () => requestSavePrompt(1)
              : () => openPromptRequestStatus(selectRequestId && selectedRequest?.is_whiteboard ? 'RequestUpdate' : 'RequestSend')
          }
          title={selectRequestId && selectedRequest?.is_whiteboard ? 'Update' : 'Send'}
          loading={requestLoading && animationBtn === 'sendBtn'}
          disabled={
            isPullRequest ? false : (!canUpdate && !!selectedRequest?.is_whiteboard) || requestLoading || !selectedRequestCompany?.company
          }
          customClassName={`${themeClass}_buttonsGroup_button`}
          customTooltipBasisClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
          tooltip={renderUpdateBtnTooltip()}
          isMobile={isMobile}
        />
        {selectedRequest?.author?.profile && !isPullRequest && showCommitmentButton ? (
          <Select<DropdownItemModel, null, false>
            isMulti={false}
            menuItems={actions}
            customMenuClass={`${themeClass}_commitDropdown`}
            selectHandler={item => handleCommitmentAction(item.value)}
            selected={null}
            renderCustomBasis={renderCustomButtonBasis}
            menuItemRightElemRenderer={renderRightIcon}
            menuItemSize={'md'}
            disabled={!selectedRequest?.status || requestLoading || !!selectedRequest?.is_deactivated || !isAccessToCommitment}
            menuItemTooltipRenderer={() => ''}
            revertDropdownPositionToTop={true}
            toolTipClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RequestTabButtons;
