import Color from 'color';

export default class ColorHelper {
  public static bgColorSetDarken = (hex: string, ratio: number = 0) => {
    if (!hex) {
      return '#fff';
    }
    const color = Color(hex);
    return color.darken(ratio);
  };
  /*
   * name example: var(--color)
   * */
  public static getCssVariable = (name: string): string => {
    try {
      return document.body.style.getPropertyValue(name.split('(')[1].split(')')[0]);
    } catch (err) {
      console.log(err);
      return '#fff';
    }
  };
  public static setCssVariable = (name: string, color: string): void => {
    document.body.style.setProperty(name, color);
  };
}
