import React, { FC } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './NonAssignedUserStyles.scss';

interface IProps {
  handleHelpMenu: (data: { helpState: boolean }) => void;
  handleHelpSubMenu: (data: string) => void;
}

const NonAssignedUser: FC<IProps> = ({ handleHelpMenu, handleHelpSubMenu }) => {
  const themeClass = useGetThemeClass('b-nonAssignedUser');

  const handleOpenSupport = () => {
    handleHelpMenu({ helpState: true });
    handleHelpSubMenu('get_help');
  };

  return (
    <div className={`${themeClass}`}>
      <div className={`${themeClass}__container`}>
        <div className={`${themeClass}__container_first`}>You haven't been assigned to any project yet. </div>
        <div className={`${themeClass}__container_second`}>
          Please{' '}
          <span onClick={handleOpenSupport} className={`${themeClass}__container_second_contact`}>
            contact
          </span>{' '}
          your admin.
        </div>
      </div>
    </div>
  );
};

export default NonAssignedUser;
