import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRelatedSliceModel, RelatedTabResponseModel } from './relatedItemsSlice.model';
import Api from '../../service/api';
import { getRelationTab, linkRelationItem, unlinkRelationItem, setValidityStatus } from './relatedItemsThunk';

const initialState: IRelatedSliceModel = {
  isRelationTabLoading: Api.initialStatus,
  isLinkingRelationItemLoading: Api.initialStatus,
  relatedTabData: {
    request: [],
    deliverable: [],
    document: [],
    submittal: [],
    predecessor: [],
    successor: [],
    card: {
      request_deactivation: [],
      request_unlink: [],
      revisions: [],
    },
  },
};

export const relatedItemsSlice = createSlice({
  name: 'relatedItems',
  initialState,
  reducers: {
    clearRelatedItemsTab() {
      return initialState;
    },
  },
  extraReducers: builder => {
    /**
     * getRelationTab
     */
    builder.addCase(getRelationTab.pending, state => {
      state.isRelationTabLoading = Api.requestStatus;
    });
    builder.addCase(getRelationTab.fulfilled, (state, { payload }: PayloadAction<RelatedTabResponseModel>) => {
      state.isRelationTabLoading = Api.successStatus;
      state.relatedTabData = payload.resource;
    });
    builder.addCase(getRelationTab.rejected, (state, { error }) => {
      state.isRelationTabLoading = Api.failStatus;
    });
    /**
     * linkRelationItem
     */
    builder.addCase(linkRelationItem.pending, state => {
      state.isLinkingRelationItemLoading = Api.requestStatus;
    });
    builder.addCase(linkRelationItem.fulfilled, (state, { payload }: PayloadAction<RelatedTabResponseModel>) => {
      state.isLinkingRelationItemLoading = Api.successStatus;
      state.relatedTabData = payload.resource;
    });
    builder.addCase(linkRelationItem.rejected, (state, { error }) => {
      state.isLinkingRelationItemLoading = Api.failStatus;
    });
    /**
     * unLinkRelationItem
     */
    builder.addCase(unlinkRelationItem.pending, state => {
      state.isLinkingRelationItemLoading = Api.requestStatus;
    });
    builder.addCase(unlinkRelationItem.fulfilled, (state, { payload }: PayloadAction<RelatedTabResponseModel>) => {
      state.isLinkingRelationItemLoading = Api.successStatus;
      state.relatedTabData = payload.resource;
    });
    builder.addCase(unlinkRelationItem.rejected, (state, { error }) => {
      state.isLinkingRelationItemLoading = Api.failStatus;
    });
    /**
     * setValidityStatus
     */
    builder.addCase(setValidityStatus.pending, state => {
      state.isRelationTabLoading = Api.requestStatus;
    });
    builder.addCase(setValidityStatus.fulfilled, (state, { payload }: PayloadAction<RelatedTabResponseModel>) => {
      state.isRelationTabLoading = Api.successStatus;
      state.relatedTabData = payload;
    });
    builder.addCase(setValidityStatus.rejected, (state, { error }) => {
      state.isRelationTabLoading = Api.failStatus;
    });
  },
});

export default relatedItemsSlice.reducer;
