import React from 'react';
import SVG from 'react-inlinesvg';
import { InputBase } from '@material-ui/core';
import MultiSelectField from '../../CreateProject/parts/SelectField/MultiSelectField';
import { SelectFieldStyles } from '../../RequestCard/parts/SandBoxSelectField/SandBoxSelectFieldStyles';
import { withStyles } from '@material-ui/core/styles';
import DateSelect from '../../GlobalFilters/DateSelect/DateSelect';
import { DATE_FILTERS_ALIAS } from '../../GlobalFilters/GlobalFiltersConstants/GlobalFiltersConstants';
import CustomTooltip from '../../../controls/Tooltip/Tooltip';
import ReactResizeDetector from 'react-resize-detector';
import '../../GlobalFilters/GlobalFiltersStyles.scss';

const dateFilter = [
  {
    title: 'Newest',
    value: 'desc',
  },
  {
    title: 'Oldest',
    value: 'asc',
  },
];
export const constFilters = {
  MORE: 'more',
};
const constFiltersName = {
  [constFilters.MORE]: 'More filters',
};

const stylesMultiSelectField = {
  ...SelectFieldStyles(),
  formControlRoot: {
    height: '30px',
    borderRadius: '2px',
  },
  disableShrink: {},
  labelShrink: {
    transform: 'translate(20px, 9px) scale(1) !important',
  },
  labelRoot: {
    transform: 'translate(20px, 9px) scale(1) !important',
    color: 'var(--color)',
    fontFamily: 'var(--font-general)',
    fontSize: '12px',
    fontWeight: 'var(--font-weight)',
  },
  multiSelectRoot: {
    height: '30px',
  },
};

export const MultiSelectFieldFilter = withStyles(stylesMultiSelectField)(MultiSelectField);
class NotificationFiltersView extends React.Component {
  render() {
    const {
      filters,
      filtersField,
      notificationsInfo,
      selectedFilters,
      conditions,
      containsFilter,
      searchValue,
      formData,
      handleChangeSearch,
      handleChangeMore,
      handleChangeSelect,
      handleCloseSelect,
      handleReadAll,
      handleRemoveFilter,
      handleChangeContains,
      handleSelectCondition,
      conditionFilters,
      onChangeFilters,
      handleResetFilter,
      onChangeHeight,
    } = this.props;

    return (
      <>
        <ReactResizeDetector handleHeight skipOnMount refreshMode="throttle" onResize={onChangeHeight} />
        <div className={'constFilters'} style={{ margin: '1px 0 0 3px' }}>
          {/*<div className="readAll" style={{ margin: '0 8px 0 -10px' }}>*/}
          {/*  <ResetButton*/}
          {/*    title={'Read all'}*/}
          {/*    style={{*/}
          {/*      margin: '5px 0 0 0',*/}
          {/*    }}*/}
          {/*    height={30}*/}
          {/*    width={74}*/}
          {/*    handleClick={handleReadAll}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={'countLabel'} style={{ marginRight: '8px' }}>
            {notificationsInfo.total} items
          </div>
          <CustomTooltip title={'Search bar is temporary deactivated for Activity menu'} placement="top" key={'Search_Activity'}>
            <div className={'sandBoxSearchStyle'} style={{ cursor: 'default' }}>
              <SVG src={require('../../../../assets/icons/search.svg')} />
              <span style={{ fontSize: '12px', fontFamily: 'var(--font-general)', color: 'var(--color-secondary)', fontWeight: 500 }}>
                Search
              </span>
              {/*<InputBase
                    placeholder={'Search'}
                    onChange={handleChangeSearch}
                    value={searchValue}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        onChangeFilters();
                      }
                    }}
                    fullWidth
                    disabled
                  />*/}
            </div>
          </CustomTooltip>
          <div className={'sandBoxSelect more'}>
            <MultiSelectFieldFilter
              label={'More filters'}
              name={constFilters.MORE}
              values={filtersField}
              selectHandler={handleChangeMore}
              selectedIds={selectedFilters}
              onClose={handleCloseSelect}
              clear={handleResetFilter}
              searchable
              showClearBtn
            />
          </div>
          {selectedFilters &&
            selectedFilters.map(filterName => {
              const selectedFilter = filtersField.find(f => f.filterField === filterName);
              const filterType = filterName === 'date' ? 'DATE' : filterName === 'description' ? 'TEXT' : null;

              let conditionFilter = (
                <div className={'sandBoxSelect__select'}>
                  <MultiSelectFieldFilter
                    name={'value'}
                    label={!conditions[filterName]?.length ? 'Filter by condition' : ''}
                    values={conditionFilters[filterType]}
                    selectHandler={e => handleSelectCondition(filterName, e)}
                    selectedIds={conditions[filterName]}
                    hideTooltip
                    customClasses={'sandBoxSelect__condition-filter'}
                  />
                </div>
              );

              const selectedCondition = [];

              if (conditions[filterName] && conditions[filterName].length) {
                if (filterType && filterType === 'TEXT') {
                  conditions[filterName].forEach(filterId => {
                    selectedCondition.push(
                      <div className={'sandBoxSelect__input'}>
                        <InputBase
                          placeholder={conditionFilters[filterType].find(f => f.id === filterId).title}
                          onChange={e => handleChangeContains(e.target.value, filterId, filterName)}
                          value={(containsFilter[filterName] && containsFilter[filterName][filterId]) || ''}
                          className={'input-simple'}
                          fullWidth
                        />
                      </div>,
                    );
                  });
                }
                if (filterType && filterType === 'DATE') {
                  conditions[filterName].forEach(filterId => {
                    const value = (containsFilter[filterName] && containsFilter[filterName][filterId]) || '';
                    selectedCondition.push(
                      <DateSelect
                        dataValue={value}
                        handleApplyFilter={data => handleChangeContains(data, filterId, filterName)}
                        label={conditionFilters[filterType].find(f => f.id === filterId).title}
                        range={filterId === DATE_FILTERS_ALIAS.RANGE}
                      />,
                    );
                  });
                }
              }

              if (!filterType) {
                conditionFilter = null;
              }

              const totalItemCount =
                ((formData[filterName] && formData[filterName].length) || 0) +
                Object.values(containsFilter[filterName] || {}).filter(f => !!f).length;

              return (
                <div
                  key={`active-filter-${filterName}`}
                  className={`sandBoxSelect ${totalItemCount ? 'sandBoxSelect__selected' : ''}`}
                  style={{ width: '180px' }}
                >
                  <MultiSelectFieldFilter
                    label={
                      <div className={'sandBoxSelect__label'}>
                        <span>{selectedFilter?.title || ''}</span>
                        {totalItemCount ? <span>({totalItemCount})</span> : null}
                      </div>
                    }
                    name={filterName}
                    values={filters[filterName] || []}
                    selectHandler={e => handleChangeSelect(e, filterName)}
                    selectedIds={formData[filterName] || []}
                    onClose={handleCloseSelect}
                    searchable
                    showClearBtn
                    showSelectAll
                    clearItemFromLabel
                    customClasses={totalItemCount ? 'yellowSelectedItems' : ''}
                    clearFromLabel={() => handleRemoveFilter(filterName)}
                    condition={conditionFilter}
                    selectedCondition={selectedCondition}
                  />
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

export default NotificationFiltersView;
