import React, { Component } from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import highlightedWords from './GlossaryHighlightedWords';

enum WidthSize {
  NameCell = '20%',
  DescriptionCell = '80%',
}

class WhatIsNfHelp extends Component {
  render() {
    return (
      <>
        <div className={'popup-content'}>
          <div className={'popup-content__text'} style={{ margin: '0 0 30px 2px' }}>
            <p>
              NF (Need Form) is an unique object containing communication information between teams within the project. The NF was designed
              to enable clear and organized workflow between all entities included in the design and construction process.
            </p>
          </div>
          <div className={'popup-content__text'} style={{ margin: '0 0 5px 0' }}>
            <p>Each NF consists of 6 sections:</p>
          </div>
          <div className={'popup-content__tableContainer'}>
            <Table>
              <TableBody>
                <TableRow className={'popup-content__tableRow'}>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Request</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          It is a part of an NF that specifies the need in the process. It contains title and description of requested
                          information, person requesting, company and person that is expected to provide the response, the date before which
                          response is required as well as other useful information about request. In order to successfully fill a Request
                          and submit an NF, {highlightedWords[32].component} must be chosen, Request Subject and Description (alternatively
                          attachment) must be specified.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Response</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          Response is a part of an NF in which Response party provides a concluding response to an original request by
                          completing Response description or Response attachment.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Incorporation</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          This section allows to mark finalized stages of NF incorporation to the CL model. Incorporation stage can be
                          specified by Affected levels, and UniFormat codes.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Linked NFs</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          It allows to create Predecessor/Successor relation between open NF and others already created.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>Discussion</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          It is a part in which users are allowed to post comments to NF and mention other users.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.NameCell }}
                  >
                    <div className={'nameCell'}>
                      <Typography children={<span className={'popup-content__text'}>History</span>} />
                    </div>
                  </TableCell>
                  <TableCell
                    variant="body"
                    align="left"
                    className={'mui-table__cell popup-content__tableCell'}
                    style={{ width: WidthSize.DescriptionCell }}
                  >
                    <Typography
                      children={
                        <span className={'popup-content__text'}>
                          This section displays the history of all actions performed on selected NF.
                        </span>
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to create a Request?
            </p>
            <p>
              To create a Request, you need to open the New NF module by choosing a plus icon on the left sidebar. A pop-up window with
              predefined data fields will appear. Fill all the required fields such as {highlightedWords[32].component}, Request Subject and
              Description (alternatively Request Files). Click Send to finalize the creation process. You are welcome to enter the other
              remaining fields. The more detailed an NF becomes, the more positive impact on the information workflow it has.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to create a Draft NF?
            </p>
            <p>
              If you don’t want to send the Request straightaway, you are welcome to save it as a draft version by clicking the Save Draft
              button in the bottom right corner of the Request submenu in the NF Card. A Draft NF will be created and available in the
              Sandbox and Needs List modules for further editing. The response company, if filled, will not be notified, however.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to edit an existing NF?
            </p>
            <p>
              If there’s a need to modify an already created NF, you need to select it in any of the following modules: Needs List,
              Whiteboard or Sandbox. Based on the User Roles assigned, certain fields are allowed to be modified while others might remain
              inactive since editing them requires a higher level of permissions.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to commit to a Request?
            </p>
            <p>
              After opening an appropriate NF, click the Commit… button. Select the option that best describes the commitment you want to
              make. You can choose from: provide your response right away, commit now, commit conditionally, redirect the request to a
              different party.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to adjust an NF Due Date?
            </p>
            <p>
              If your Commitment requires a change of the NF Due Date, you are able to do so. Click on the Due date field, select the date
              you require and confirm by clicking Send request. Your request has to be approved by the Request party.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to redirect a Request to another company?
            </p>
            <p>
              If you think that a Request you received suits better to another Project Company, you are able to redirect it. Click on the
              Response company field, select the company and manager you require and confirm by clicking Send request. Your request has to
              be approved by the Request party.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to get supplementary information before responding to the request?
            </p>
            <p>
              If you need more information from another entity to be provided before committing or responding to the request, raise the
              issue in the Discussion tab of the NF card. You can mention other project users to get their attention.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to submit a Response?
            </p>
            <p>
              After opening an appropriate NF, go to the Response tab and provide description in Final response text field. Alternatively,
              attach supplementary {highlightedWords[3].component} files. Click on the Submit button to forward your response to the Request
              party.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to accept or decline a Response?
            </p>
            <p>
              After the {highlightedWords[3].component} is submitted, the Request party users are prompted to review it and as a result
              either accept or decline it. The appropriate buttons are located in the Response tab of the NF Card. If you choose to decline
              the Response, you need to enter a decline reason that will be visible for the{' '}
              <span className={'highlightedWord'}>{highlightedWords[15].component}</span>.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> What are the Predecessors and Successors?
            </p>
            <p>
              Predecessors and Successors are NFs that have been linked to other NFs based on the requirements of the request. If a response
              of an NF depends on another NF’s response, the former becomes the Successor and the latter Predecessor in this relation.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to link NFs?
            </p>
            <p>
              You can create Predecessors - Successors dependencies between NFs using the Linked NFs tab in the NF Card. To create such a
              relation, choose the appropriate field and enter NF’s number or part of its subject, select it and save. Multiple NFs can be
              chosen at once. Predecessors - Successor relation can also be achieved by creating a {highlightedWords[16].component}.
            </p>
          </div>
          <div className={'popup-content__text paragraphs'}>
            <p>
              <b>&#8226;</b> How to comment on NFs?
            </p>
            <p>
              Planit Scheduler provides the ability to discuss topics related to specific NF in a form of comments. Once the NF is sent, go
              to the Discussion tab to start the discussion!
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default WhatIsNfHelp;
