import { TabItemModel } from '../../../../models/global';
import { ActivationTabType } from '../../../../models';

export type SubmittalCardTabNamesModel = 'general' | 'review' | 'related-items' | 'discussion';
export const SubmittalCardTabs = ['discussion', 'review', 'related-items', 'general'];

export const submittalTabs: TabItemModel<ActivationTabType>[] = [
  {
    title: 'General information',
    id: 'general',
    count: 0,
  },
  {
    title: 'Review',
    id: 'review',
    count: 0,
  },
  {
    title: 'Related items',
    id: 'related-items',
    count: 0,
  },
  {
    title: 'Discussion / History',
    id: 'discussion',
    count: 0,
  },
];
