import React, { ReactElement, ReactNode } from 'react';
import './DropdownMenuItemsStyles.scss';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import CustomTooltip from '../../../Tooltip/Tooltip';
import CheckBox from '../../../CheckBox/CheckBox';
import cn from 'classnames';

interface DropdownMenuItemProps {
  size: 'sm' | 'md';
  disabled?: boolean;
  title: string | ReactNode;
  value: string | number;
  itemId: string | number;
  isSelected?: boolean;
  onItemClick?: (value: string | number, itemId: string | number, e) => void;
  isMulti?: boolean;
  tooltip?: string | ReactNode | ReactElement;
  leftElem?: ReactNode;
  leftElemClick?: (value: string | number, itemId: string | number) => void;
  rightElem?: ReactNode;
  rightElemClick?: (value: string | number, itemId: string | number) => void;
  rightText?: ReactNode;
  customClassName?: string;
  isFullWidth?: boolean;
  isMobile?: boolean;
  isCustomMulti?: boolean;
  isActiveCheckBoxForDisabledItems?: boolean;
}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  size,
  disabled,
  title,
  value,
  itemId,
  onItemClick,
  tooltip,
  isMulti,
  isSelected,
  leftElem,
  leftElemClick,
  rightElem,
  rightElemClick,
  rightText,
  customClassName,
  isFullWidth,
  isMobile,
  isCustomMulti,
  isActiveCheckBoxForDisabledItems = false,
}) => {
  const themeClass = useGetThemeClass('b-dropdownMenuItem');

  const onMenuItemClick = e => {
    e.stopPropagation();

    if (disabled) return;

    onItemClick && onItemClick(value, itemId, e);
  };

  const onRightElemClick = e => {
    e.stopPropagation();

    rightElemClick && rightElemClick(value, itemId);
  };

  const onLeftElemClick = e => {
    e.stopPropagation();

    leftElemClick && leftElemClick(value, itemId);
  };

  return (
    <>
      <CustomTooltip title={tooltip || ''} placement={'bottom'}>
        <div
          className={cn(`${themeClass}`, {
            [`-${size}`]: size,
            [`-disabled`]: disabled,
            [`-selected`]: isSelected,
            [`${customClassName}`]: customClassName,
            ['-isFullWidth']: isFullWidth,
            [`-mobile`]: isMobile,
          })}
          onClick={onMenuItemClick}
        >
          {isMulti && !isCustomMulti && <CheckBox checked={isSelected || isActiveCheckBoxForDisabledItems} isMobile={isMobile} />}
          {leftElem && (
            <div className={`${themeClass}_icon -left`} onClick={onLeftElemClick}>
              {leftElem}
            </div>
          )}
          <div
            className={cn(`${themeClass}_title`, {
              ['-isFullWidth']: isFullWidth,
              [`-mobile`]: isMobile,
            })}
          >
            {title}
          </div>
          {rightElem ? (
            <div className={`${themeClass}_icon -right`} onClick={onRightElemClick}>
              {rightElem}
            </div>
          ) : null}
          {rightText && <div className={`${themeClass}_text -right`}>{rightText}</div>}
        </div>
      </CustomTooltip>
    </>
  );
};

export default DropdownMenuItem;
