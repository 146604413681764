type iconsChartConfig = {
  name: string;
  icon_path: string;
};

export const iconsChart: iconsChartConfig[] = [
  {
    name: 'PPI value',
    icon_path: require('../../../../../../assets/icons/PPI_value.svg'),
  },
  /*{
    name: 'Created NF count',
    icon_path: require('../../../../../../assets/icons/created_NF_count.svg'),
  },
  {
    name: 'RFI',
    icon_path: require('../../../../../../assets/icons/RFI.svg'),
  },
  {
    name: 'CL Input Checklist',
    icon_path: require('../../../../../../assets/icons/cl_input_checklist.svg'),
  },
  {
    name: 'Design Constraint',
    icon_path: require('../../../../../../assets/icons/design_constraint.svg'),
  },
  {
    name: 'Standard request',
    icon_path: require('../../../../../../assets/icons/standard_request.svg'),
  },*/
  {
    name: 'Design Document set release',
    icon_path: require('../../../../../../assets/icons/ddl_set.svg'),
  },
];

export const ColorsLevels: string[] = [
  '#8F16C0',
  '#EBCE2B',
  '#96CDE6',
  '#FF6B00',
  '#BA1C30',
  '#C0BD7F',
  '#5FA641',
  '#D485B2',
  '#4076B4',
  '#DF8461',
  '#E0A11B',
  '#92AE31',
  '#A52481',
  '#F22515',
  '#E56262',
  '#A73131',
  '#82A4EB',
  '#013985',
  '#F09E70',
  '#BC6B3E',
  '#85A1DB',
  '#002556',
  '#F9CA84',
  '#CBA56D',
  '#5E739E',
  '#F3A359',
  '#FF0000',
  '#2FB400',
  '#0075FF',
  '#FF7A00',
  '#00BDA6',
  '#BD00FF',
  '#6AA071',
  '#B6B77F',
  '#EF8C54',
  '#8F16C0',
  '#EBCE2B',
  '#96CDE6',
  '#FF6B00',
  '#BA1C30',
  '#C0BD7F',
  '#5FA641',
  '#D485B2',
  '#4076B4',
  '#DF8461',
  '#E0A11B',
  '#92AE31',
  '#A52481',
  '#F22515',
  '#E56262',
  '#A73131',
  '#82A4EB',
  '#013985',
  '#F09E70',
  '#BC6B3E',
  '#85A1DB',
  '#002556',
  '#F9CA84',
  '#CBA56D',
  '#5E739E',
  '#F3A359',
  '#FF0000',
  '#2FB400',
  '#0075FF',
  '#FF7A00',
  '#00BDA6',
  '#BD00FF',
  '#6AA071',
  '#B6B77F',
  '#EF8C54',
  '#8F16C0',
  '#EBCE2B',
  '#96CDE6',
  '#FF6B00',
  '#BA1C30',
  '#C0BD7F',
  '#5FA641',
  '#D485B2',
  '#4076B4',
  '#DF8461',
  '#E0A11B',
  '#92AE31',
  '#A52481',
  '#F22515',
];
