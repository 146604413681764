import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserReducerModel } from '../user/userReducerModel';
import { INotificationResponse, INotificationsFilter, INotificationsListPayload } from './notificationsReducer.model';
import { notificationsSlice } from './notificationsReducer';
import { NotificationsApi } from '../../service/Api/Api';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

export const getNotificationsList = createAsyncThunk<INotificationResponse, INotificationsListPayload>(
  'notificationsReducer/getNotificationsList',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { userReducer } = getState() as { userReducer: UserReducerModel };
    const { active_project_id: projectId, selected_client_id: clientId } = userReducer;

    try {
      delete payload.project_id;
      delete payload.isNew;

      const response = await NotificationsApi.getNotificationsList({ projectId, clientId, ...payload });

      return response.navigationData;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const readAll = createAsyncThunk('notificationsReducer/readAll', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await NotificationsApi.readAll();
    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getNotificationsFilters = createAsyncThunk<INotificationsFilter>(
  'notificationsReducer/getNotificationsFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await NotificationsApi.getNotificationFilters();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
