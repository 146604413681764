import { actionCreater } from '../configure/actionCreater';

//get Report
export const GET_REPORTING = 'GET_REPORTING';
export const getReporting = actionCreater(GET_REPORTING);

export const GET_REPORTING_CANCEL = 'GET_REPORTING_CANCEL';
export const getReportingCancel = actionCreater(GET_REPORTING_CANCEL);

export const GET_REPORTING_SUCCESS = 'GET_REPORTING_SUCCESS';
export const getReportingSuccess = actionCreater(GET_REPORTING_SUCCESS);

export const GET_REPORTING_FAIL = 'GET_REPORTING_FAIL';
export const getReportingFail = actionCreater(GET_REPORTING_FAIL);

export const GET_REPORTING_FOR_COMPANY = 'GET_REPORTING_FOR_COMPANY';
export const getReportingForCompany = actionCreater(GET_REPORTING_FOR_COMPANY);

export const GET_REPORTING_FOR_COMPANY_CANCEL = 'GET_REPORTING_FOR_COMPANY_CANCEL';
export const getReportingForCompanyCancel = actionCreater(GET_REPORTING_FOR_COMPANY_CANCEL);

export const GET_REPORTING_FOR_COMPANY_SUCCESS = 'GET_REPORTING_FOR_COMPANY_SUCCESS';
export const getReportingForCompanySuccess = actionCreater(GET_REPORTING_FOR_COMPANY_SUCCESS);

export const GET_REPORTING_FOR_COMPANY_FAIL = 'GET_REPORTING_FOR_COMPANY_FAIL';
export const getReportingForCompanyFail = actionCreater(GET_REPORTING_FOR_COMPANY_FAIL);

export const GET_REPORTING_FOR_USER = 'GET_REPORTING_FOR_USER';
export const getReportingForUser = actionCreater(GET_REPORTING_FOR_USER);

export const GET_REPORTING_FOR_USER_CANCEL = 'GET_REPORTING_FOR_USER_CANCEL';
export const getReportingForUserCancel = actionCreater(GET_REPORTING_FOR_USER_CANCEL);

export const GET_REPORTING_FOR_USER_SUCCESS = 'GET_REPORTING_FOR_USER_SUCCESS';
export const getReportingForUserSuccess = actionCreater(GET_REPORTING_FOR_USER_SUCCESS);

export const GET_REPORTING_FOR_USER_FAIL = 'GET_REPORTING_FOR_USER_FAIL';
export const getReportingForUserFail = actionCreater(GET_REPORTING_FOR_USER_FAIL);
