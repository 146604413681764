import React, { FC, useEffect, useRef, useState } from 'react';
import { DataGridHandle } from 'react-data-grid';
import DraggleScroll from '../../../NeedsList/new/controls/DraggbleScroll';
import SVG from 'react-inlinesvg';
import { CheckBox } from '../../../../controls';
import './ProfileGridStyles.scss';
import { DataGridBasic } from '../../../DataGridBasic';
import cn from 'classnames';

type dataType = {
  alias: string;
  description: string;
  group: string;
  important: boolean;
  notification: string;
  subgroup: string;
};

const ProfileGrid: FC<{
  data: dataType[];
  themeClass: string;
  handleSelectNotifications: (notifications: string[]) => void;
  isPopover?: boolean;
}> = ({ data, themeClass, handleSelectNotifications, isPopover }) => {
  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);
  const [rowsOrig, setRowsOrig] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [selectChangeBox, setSelectChangeBox] = useState(null);
  const firstColumnRef = useRef<DataGridHandle>();

  const onSetVisibleRows = p => {
    const r = { ...p };
    setSelectRow(r);
  };

  const getIconArrow = p => {
    const is_visible = p.row.level === 1 || p.row.level === 2;
    if (!is_visible) {
      return null;
    }

    return (
      <>
        {p.row.is_sub_visible ? (
          <div className={'arrow_active_notification'}>
            <SVG src={require('../../../../../assets/icons/arrow_down_icon.svg')} />
          </div>
        ) : (
          <div className={'arrow_no_active_notification'}>
            <SVG src={require('../../../../../assets/icons/arrow_right_icon.svg')} />
          </div>
        )}
      </>
    );
  };

  const getClassNameNotification = (level: number) => {
    const str_pl = level === 1 ? '-pl_main' : level === 2 ? '-pl_sub' : '-pl_detail';
    return `notification ${str_pl}`;
  };

  const renderNotificationFormatter = p => {
    return (
      <>
        <div
          className={`${getClassNameNotification(p.row.level)} ${p.row.level < 3 ? '-cursor_pointer' : ''} ${setCellClass(p.row)}`}
          onClick={() => onSetVisibleRows(p.row)}
        >
          {getIconArrow(p)}
          <div className={`${p.row.level < 3 ? 'notification__notification_text' : 'notification__notification_text_item'}`}>
            {p.row.notification}
          </div>
        </div>
      </>
    );
  };

  const renderImportantFormatter = p => {
    return (
      <div className={`important-cell ${setCellClass(p.row)}`}>
        <CheckBox isTripleState important={p.row.important} onClick={() => handleChangeBox(p.row)} />
      </div>
    );
  };

  const renderDescriptionFormatter = p => {
    return (
      <>
        <div className={`description ${setCellClass(p.row)}`}>
          <div className={'description__description_text'}>{p.row.description}</div>
        </div>
      </>
    );
  };

  const renderHeaderCell = p => {
    return (
      <div className={`${themeClass}__tableHeaderCell__container`}>
        <div className={`${themeClass}__tableHeaderCell__container__header_text`}>{p.column.name}</div>
      </div>
    );
  };

  const setCellClass = p => {
    if (p.level === 3) {
      return '';
    }
    return 'group-row';
  };

  const handleChangeBox = data => {
    setSelectChangeBox({ ...data });
  };

  useEffect(() => {
    if (selectChangeBox) {
      const newRows = [...rowsOrig];
      if (selectChangeBox.level < 3) {
        const allRowsGroup = newRows.map(item => {
          if (
            (selectChangeBox.level === 1 && item.group && item.group === selectChangeBox.group) ||
            (selectChangeBox.level === 2 && item.subgroup && item.subgroup === selectChangeBox.subgroup)
          ) {
            const obj = { ...item };
            obj.important = selectChangeBox.important < 2 ? 2 : 0;
            return obj;
          } else {
            return item;
          }
        });
        if (selectChangeBox.level === 2) {
          const findIndex = allRowsGroup.findIndex(value => value.group === selectChangeBox.group && value.level === 1);
          if (selectChangeBox.important === 0) {
            const obj = { ...allRowsGroup[findIndex] };
            obj.important = 1;
            allRowsGroup[findIndex] = obj;
          } else {
            const all_rows_sub_group = allRowsGroup.filter(
              value => value.group === selectChangeBox.group && value.level === 2 && value.important > 0,
            );
            if (all_rows_sub_group.length === 0) {
              const obj = { ...allRowsGroup[findIndex] };
              obj.important = 0;
              allRowsGroup[findIndex] = obj;
            }
          }
        }
        setRowsOrig(allRowsGroup);
        const rows_is_visible = allRowsGroup.filter(item => item.is_visible);
        setRows(rows_is_visible);
        const notification = allRowsGroup.filter(item => item.important && item.level === 3).map(item => item.alias);
        handleSelectNotifications(notification);
      }
      if (selectChangeBox.level === 3) {
        const findIndex = newRows.findIndex(
          value => value.notification === selectChangeBox.notification && value.group === selectChangeBox.group,
        );
        const findIndexSubgroup = selectChangeBox.subgroup
          ? newRows.findIndex(value => value.subgroup === selectChangeBox.subgroup && value.level === 2)
          : -1;
        const findIndexGroup = newRows.findIndex(value => value.group === selectChangeBox.group && value.level === 1);
        const all_rows_group = newRows.filter(item => item.group === selectChangeBox.group && item.level === 3);
        const all_rows_group_is_important = all_rows_group.filter(item => item.important);
        const all_rows_sub_group = selectChangeBox.subgroup
          ? newRows.filter(item => item.subgroup === selectChangeBox.subgroup && item.level === 3)
          : [];
        const all_rows_sub_group_is_important = all_rows_sub_group.filter(item => item.important);

        if (selectChangeBox.important === 0) {
          const obj = { ...newRows[findIndex] };
          obj.important = 2;
          newRows[findIndex] = obj;
          if (findIndexSubgroup > -1) {
            const obj_sub = { ...newRows[findIndexSubgroup] };
            obj_sub.important = all_rows_sub_group.length === all_rows_sub_group_is_important.length + 1 ? 2 : 1;
            newRows[findIndexSubgroup] = obj_sub;
          }
          const obj_group = { ...newRows[findIndexGroup] };
          obj_group.important = all_rows_group.length === all_rows_group_is_important.length + 1 ? 2 : 1;
          newRows[findIndexGroup] = obj_group;
        } else {
          const obj = { ...newRows[findIndex] };
          obj.important = 0;
          newRows[findIndex] = obj;
          if (findIndexSubgroup > -1) {
            const obj_sub = { ...newRows[findIndexSubgroup] };
            obj_sub.important = all_rows_sub_group_is_important.length - 1 > 0 ? 1 : 0;
            newRows[findIndexSubgroup] = obj_sub;
          }
          const obj_group = { ...newRows[findIndexGroup] };
          obj_group.important = all_rows_group_is_important.length - 1 > 0 ? 1 : 0;
          newRows[findIndexGroup] = obj_group;
        }

        setRowsOrig(newRows);
        const rows_is_visible = newRows.filter(item => item.is_visible);
        setRows(rows_is_visible);
        const notification = newRows.filter(item => item.important && item.level === 3).map(item => item.alias);
        handleSelectNotifications(notification);
      }
    }
  }, [selectChangeBox]);

  useEffect(() => {
    const all_columns = [
      {
        key: 'notification',
        name: 'Notifications',
        width: 262,
        minWidth: 262,
        resizable: true,
        formatter: p => renderNotificationFormatter(p),
        headerRenderer: p => renderHeaderCell(p),
        headerCellClass: `${themeClass}__tableHeaderCell`,
      },
      {
        key: 'important',
        name: 'Important',
        width: 77,
        minWidth: 77,
        resizable: true,
        formatter: p => renderImportantFormatter(p),
        headerRenderer: p => renderHeaderCell(p),
        headerCellClass: `${themeClass}__tableHeaderCell`,
      },
      {
        key: 'description',
        name: 'Description',
        width: 795,
        minWidth: 795,
        resizable: true,
        formatter: p => renderDescriptionFormatter(p),
        headerRenderer: p => renderHeaderCell(p),
        headerCellClass: `${themeClass}__tableHeaderCell`,
      },
    ];

    setColumns(all_columns);
  }, [themeClass]);

  useEffect(() => {
    if (selectRow) {
      if (selectRow.level === 1) {
        const allRowsGroup = rowsOrig.map(item => {
          if (item.group === selectRow.group) {
            const obj = { ...item };
            if (obj.level === 1) {
              obj.is_sub_visible = !selectRow.is_sub_visible;
            }
            if (obj.level === 2) {
              obj.is_visible = !selectRow.is_sub_visible;
            }
            if (obj.level === 3) {
              obj.is_visible = !selectRow.is_sub_visible ? obj.is_sub_visible : false;
            }
            return obj;
          } else {
            return item;
          }
        });
        setRowsOrig(allRowsGroup);
        const allRowsGroupVisible = allRowsGroup.filter(item => item.is_visible);
        setRows(allRowsGroupVisible);
      }

      if (selectRow.level === 2) {
        const allRowsGroup = rowsOrig.map(item => {
          if (item.subgroup && item.subgroup === selectRow.subgroup) {
            const obj = { ...item };
            if (obj.level === 2) {
              obj.is_sub_visible = !selectRow.is_sub_visible;
            }
            if (obj.level === 3) {
              obj.is_visible = !selectRow.is_sub_visible;
              obj.is_sub_visible = !selectRow.is_sub_visible;
            }
            return obj;
          } else {
            return item;
          }
        });
        setRowsOrig(allRowsGroup);
        const allRowsGroupVisible = allRowsGroup.filter(item => item.is_visible);
        setRows(allRowsGroupVisible);
      }
    }
  }, [selectRow]);

  useEffect(() => {
    const allRowsGroup = [];
    const allGroup = Array.from(new Set(data.map(item => item.group)));
    allGroup.forEach(group_row => {
      const all_rows_group = data.filter(item => item.group === group_row);
      const all_rows_group_is_important = all_rows_group.filter(item => item.important);
      const obj_row = {
        alias: '',
        notification: group_row,
        important: all_rows_group_is_important.length > 0 ? (all_rows_group.length === all_rows_group_is_important.length ? 2 : 1) : 0,
        description: '',
        level: 1,
        group: group_row,
        subgroup: null,
        is_visible: true,
        is_sub_visible: true,
      };
      allRowsGroup.push(obj_row);
      const allSubGroup = Array.from(new Set(data.filter(item => item.group === group_row).map(item => item.subgroup)));
      allSubGroup.forEach(subGroup => {
        const all_rows_sub_group = data.filter(item => item.subgroup === subGroup);
        const all_rows_sub_group_is_important = all_rows_sub_group.filter(item => item.important);
        const obj_row = {
          alias: '',
          notification: subGroup,
          important:
            all_rows_sub_group_is_important.length > 0 ? (all_rows_sub_group.length === all_rows_sub_group_is_important.length ? 2 : 1) : 0,
          description: '',
          level: 2,
          group: group_row,
          subgroup: subGroup,
          is_visible: true,
          is_sub_visible: true,
        };
        subGroup && allRowsGroup.push(obj_row);
        const allRowsSubGroup = data.filter(item => item.subgroup === subGroup && item.group === group_row);
        allRowsSubGroup.forEach(detail => {
          const obj_row = {
            alias: detail.alias,
            notification: detail.notification,
            important: detail.important ? 2 : 0,
            description: detail.description,
            level: 3,
            group: detail.group,
            subgroup: detail.subgroup,
            is_visible: true,
            is_sub_visible: true,
          };
          allRowsGroup.push(obj_row);
        });
      });
    });
    setRows(allRowsGroup);
    setRowsOrig(allRowsGroup);
  }, [data]);

  return (
    <div className={themeClass}>
      {firstColumnRef?.current?.element && rows?.length > 0 && (
        <>
          <DraggleScroll element={firstColumnRef.current.element} />
        </>
      )}
      <div className={cn(`${themeClass}__container`, { ['-popover']: isPopover })}>
        <DataGridBasic
          ref={firstColumnRef}
          columns={columns || []}
          rows={rows || []}
          headerRowHeight={36}
          rowHeight={40}
          className={`${themeClass}__table`}
          resizable
        />
      </div>
    </div>
  );
};

export default React.memo(ProfileGrid);
