import darkImg from '../../assets/images/skeleton/wb__disciplines__bg.jpg';
import darkCellImg from '../../assets/images/skeleton/cell.jpg';
import darkDateCellImg from '../../assets/images/skeleton/wb__date__bg.jpg';

export const darkTheme = {
  colors: {
    background: {
      grid: '#2D384E', // btn border | btn background | table border | SandBox
      header: '#1F2B3F',
      sheet: '#182234', // Popup header|
      main: '#0C1627', // Popup body| main background | fields
      alterMain: '#3A475F', // Pcd popup body
      color1: '#596989', // Request header |
      color2: '#F3A359', // Commitment header | NF | GMP
      color3: '#5E739E', // SandBox active
      color4: '#2D384E', // SandBox DUE UPD NEW
      color5: '#B4BDCF', // Reporting table
      border: '1px solid #2D384E',
      tour: '#38455E',
      isDeactivated: '#182234', // SandBox Request card add deactivated status
      carousel: '#182234', // Carousel in ProjectGrid
      arrow: '#202B3F', // Carousel arrow in ProjectGrid
      hashtag: '#D2D7DF', // hashtag default background color
      selectFilterDisabled: '#0C1627',
      reportingHeader: 'rgba(34, 48, 77, 1)',
    },
    sandBox: {
      graphs: {
        alertList: {
          background: '#152138',
        },
        alertSnippet: {
          background: '#2D384E',
          headText: '#FFFFFF',
          midText: '#B3C3D3',
        },
        heatmap: {
          text: '#FFFFFF',
          arrowBackground: '#2D384E',
          tooltip: {
            text: '#152138',
            background: '#FFFFFF',
          },
        },
      },
      relationSwitcher: {
        active: {
          label: '#E28A23',
          default: '#54381F',
          hover: '#5F3F1F',
          pressed: '#6A4520',
          disabled: '#282222',
          border: '1px solid #E28A23',
        },
        plain: {
          label: '#596989',
          default: '#1D273C',
          hover: '#273249',
          pressed: '#2B374F',
          disabled: '#141D30',
        },
      },
      tooltip: {
        background: '#152138',
      },
      tab: {
        selected: '#FFFFFF',
        defaultText: '#8D9AB2',
        hover: 'rgba(89, 105, 137, 0.12)',
      },
      section: {
        active: 'rgba(89, 105, 137, 0.24)',
        hover: 'rgba(89, 105, 137, 0.16)',
        textHover: '#DFE1E5',
      },
      contentSwitcher: {
        hover: 'rgba(89, 105, 137, 0.16)',
      },
      table: {
        scrollBarThumb: '#22304D',
        scrollBarTrack: '#0B1324',
        borderColor: '#22304D',
      },
      accordion: {
        arrow: '#596989',
        badge: 'rgba(89, 105, 137, 0.24)',
      },
      snippet: {
        activeBackground: '#152138',
        activeHoverBackground: '#202D45',
        icon: '#8D9AB2',
        fadedSectionIcon: '#596989',
        hover: 'rgba(89, 105, 137, 0.16)',
        focus: 'rgba(89, 105, 137, 0.24)',
        focusBorder: '1.2px solid #596989',
        filter: 'drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.2))',
      },
      text: {
        label: '#8D9AB2', // SandBox label
        text: '#8BA1B6', // SandBox text ( responsible party )
        text2: '#DFE1E6',
        headlineText: '#DFE1E5', // text of the headlines
        tabText: '#8BA1B6', // SandBox tab text
      },
    },
    popup: {
      header: '#2D384E',
      content: '#1F2B3F',
    },
    text: {
      main: '#ECEFF2',
      secondary: '#B3C3D3',
      noSelect: '#8BA1B6', // Labels | No selected
      colorSelect: '#ECEFF2', // SandBox text
      colorNoSelect: '#8BA1B6', // SandBox text
      helpMenuColor: '#B3C3D3',
      labelSelect: '#8BA1B6', // Nf card labels
      labelSelected: '#f1f4f8', // Nf card label selected
    },
    buttons: {
      default: '#2D384E',
      hoverMenu: '#182234',
      activeMenu: '#2D384E',
      defaultMenuText: '#ECEFF2',
      hoverMenuText: '#8BA1B6',
      activeMenuText: '#ECEFF2',
      deactivated: '#38455E',
    },
    buttons2: {
      unactive: '#1F2B3F',
      active: '#596989',
      hover: '#F3A359',
      unactiveText: '#596989',
      activeText: '#ECEFF2',
      hoverText: '#ECEFF2',
    },
    btnAction: {
      disable: '#2D384E',
      disableText: '#8BA1B6',
      default: '#F3A359',
      defaultText: '#0C1627',
      hover: '#FFBC71',
      hoverText: '#1F2B3F',
    },
    btnDefault: {
      disable: '#2D384E',
      disableText: '#8BA1B6',
      default: '#8BA1B6',
      defaultText: '#0C1627',
      hover: '#B3C3D3',
      hoverText: '#0C1627',
    },
    btnOutlined: {
      disable: '#1F2B3F',
      disableBorder: '1px solid #4A586C',
      disableText: '#8BA1B6',
      default: '#182234',
      defaultBorder: '1px solid #B3C3D3',
      defaultText: '#B3C3D3',
      hover: '#B3C3D3',
      hoverBorder: '1px solid #B3C3D3',
      hoverText: '#B3C3D3',
    },
    generalButton: {
      default: 'transparent',
      defaultBorder: '1px solid #2D384E',
      hover: '#1F2B3F',
      hoverBorder: '1px solid #1F2B3F',
      defaultText: '#ECEFF2',
      hoverText: '#8BA1B6',
    },
    buttonAccept: {
      default: '#596989',
      defaultText: '#ECEFF2',
      hover: '#8BA1B6',
      hoverText: '#ECEFF2',
      disable: '#2D384E',
      disableText: '#596989',
    },
    buttonCommit: {
      hover: '#3A475F',
    },
    buttonDecline: {
      default: '#A92D2D',
      defaultText: '#ECEFF2',
      hover: '#F3A359',
      hoverText: '#ECEFF2',
      disable: '#2D384E',
      disableText: '#596989',
    },
    buttonPullRequest: {
      default: 'transparent',
      defaultBorder: '1px solid #B3C3D3',
      defaultText: '#ECEFF2',
      hover: 'transparent',
      hoverBorder: '1px solid #F3A359',
      hoverText: '#F3A359',
      disable: 'transparent',
      disableBorder: 'border: 1px solid #596989',
      alternateBorder: '1px solid #596989',
      disableText: '#596989',
    },
    outlinedButton: {
      default: 'transparent',
      defaultBorder: '1px solid #8BA1B6',
      disableBorder: '1px solid #1F2B3F',
      hover: '#1F2B3F',
      disabled: '#1F2B3F',
      defaultText: '#ECEFF2',
      hoverText: '#ECEFF2',
      disabledText: '#596989',
    },
    orange: {
      default: '#F3A359',
      defaultText: '#ECEFF2',
      hover: '#F8C596',
      hoverText: '#1F2B3F',
      disable: '#1F2B3F',
      disableText: '#2D384E',
    },
    pourButton: {
      default: '#1F2B3F',
      defaultText: '#ECEFF2',
      hover: '#596989',
      hoverText: '#ECEFF2',
      disable: '#1F2B3F',
      disableText: '#596989',
    },
    commentButton: {
      default: '#2D384E',
      defaultText: '#ECEFF2',
      hover: '#596989',
      hoverText: '#ECEFF2',
      disable: '#1F2B3F',
      disableText: '#596989',
    },
    buildingButton: {
      default: '#2D384E',
      defaultText: '#ECEFF2',
      hover: '#596989',
      hoverText: '#ECEFF2',
      disable: '#2D384E',
      disableText: '#596989',
    },
    showHideRequestButton: {
      defaultText: '#B3C3D3',
      hoverText: '#ECEFF2',
    },
    cancelButton: {
      default: 'transparent',
      defaultBorder: '1px solid #596989',
      disableBorder: '1px solid #2D384E',
      hover: '#2D384E',
      disabled: 'transparent',
      defaultText: '#ECEFF2',
      hoverText: '#ECEFF2',
      disabledText: '#596989',
    },
    tags: {
      tag1: '#778EA4',
      tag2: '#55738E',
      tag3: '#365C7E',
      color: '#ECEFF2',
    },
    notificationBell: {
      counter: '#a00639',
      today: '#65C17F',
    },
    phasePlan: {
      lod350: '#8A98B5',
      lod325: '#6B7A99',
      lod300: '#596989',
      lod290: '#475779',
      lod200: '#354669',
      permit: '#4684BA',
      construction: '#98A2BA',
      color: '#ECEFF2',
    },
    permissions: {
      color1: '#263C5C',
      color2: '#3A5676',
      color3: '#517597',
      color4: '#7392AF',
    },
    driverMode: {
      mainDriver: '#F3A359',
      selectedDriver: '#F8C596',
      possibleDriver: '#5E739E',
    },
    switcherThemeIcon: {
      sun: '#8FA6BB',
      moon: '#E5E9ED',
    },
    rest: {
      predecessorColor: '#8BA1B6',
      predecessorBackground: '#2D384E',
    },
    select: {
      color: '#8BA1B6',
      background: '#1F2B3F',
      backgroundHover: '#2D384E',
      hoverText: '#ECEFF2',
      backgroundActive: '#0C1627',
    },
    calendarPCD: {
      disabledBorder: '#3A475F',
    },
    navIcons: {
      active: '#B3C3D3',
      hover: '#8BA1B6',
      default: '#5F6875',
    },
    logo: {
      part1: '#FFFFFF',
      part2: '#596989',
      text: '#FFFFFF',
    },
    dropDown: {
      default: '#2D384E',
      hover: '#F3A359',
      active: '#B3C3D3',
    },
    selectedFilterDropDown: {
      default: '#3A475F',
      hover: '#4A586C',
    },
    multiSelect: {
      defaultText: '#ECEFF2',
      hoveredText: '#F3A359',
    },
    multiSelectSearch: {
      default: '#182234',
    },
    tooltip: {
      default: '#2D384E',
      defaultText: '#FFFFFF',
    },
    checkbox: {
      default: '#596989',
    },
    // CustomButton for more files
    fileButtons: {
      showMore: '#F3A359',
      attachFile: '#8BA1B6',
    },
    svgIcons: {
      color: '#ECEFF2',
      arrowColor: '#8BA1B6',
      removeIconBackground: '#1F2B3F',
      removeIconColor: '#8BA1B6',
    },
    textField: {
      label: '#8BA1B6',
      border: '#2D384E',
      font: '#ECEFF2',
      error: '#FF0000',
    },
    teamAvatar: {
      font: '#8BA1B6',
      border: '#1F2B3F',
    },
    requestFilters: {
      default: '#38455E',
      hover: '#F3A359',
      active: '#F3A359',
    },
  },
  fonts: {
    general: 'Proxima Nova',
    menuItemSize: '12px',
    weight: '400',
  },
  images: {},
  svgImages: {
    settings: {
      default: '#5F6875',
      hover: '#F3A359',
      active: '#8BA1B6',
    },
    requestNavigation: {
      default: '#FFFFFF',
      hover: '#F3A359',
      disable: '#2D384E',
    },
  },
  skeletons: {
    wbDisciplines: `url("${darkImg}")`,
    wbTableCell: `url("${darkCellImg}")`,
    wbDateCell: `url("${darkDateCellImg}")`,
  },
};
