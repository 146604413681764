import React, { FC } from 'react';
import Popover from '@mui/material/Popover';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { IRequestWatchersGroup } from '../../../store/request/requestReducer.model';
import { SystemButton } from '../../controls/ButtonComponents';
import Divider from '../../controls/Divider/Divider';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { RequestUserModel } from '../../../models';

import './WatchlistGroupPreviewStyles.scss';

interface IProps {
  isOpen: boolean;
  onClose: (isEdit: boolean) => void;
  anchorEl: Element;
  group: IRequestWatchersGroup;
  handleEditGroup: (e, group: IRequestWatchersGroup, isPreview: boolean) => void;
  handleCloseAuthorUser: () => void;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => void;
}

const WatchlistGroupPreview: FC<IProps> = ({
  isOpen,
  onClose,
  anchorEl,
  group,
  handleEditGroup,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
}) => {
  const themeClass = useGetThemeClass('b-watchlistGroupPreview');
  const { isMobile } = GetResolution();

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{
        paper: themeClass,
      }}
    >
      <div className={`${themeClass}_header`}>
        <div className={`${themeClass}_header_left`}>
          <span className={`${themeClass}_header_left_title`}>{group?.title}</span>
          <span className={`${themeClass}_header_left_count`}>{group?.users?.length} participants</span>
        </div>
        <SystemButton
          type={'edit'}
          size={'md'}
          variant={'transparent'}
          clickHandler={e => {
            handleEditGroup(e, group, true);
            onClose(true);
          }}
          isMobile={isMobile}
        />
      </div>
      <Divider direction={'horizontal'} type={'srf-4'} />
      <div className={`${themeClass}_list`}>
        {group?.users?.map(user => {
          return (
            <div key={`user-${user.id}`} className={`${themeClass}_list_item`}>
              <div
                className={`${themeClass}_list_item_wrapper`}
                onMouseEnter={e => handleOpenAuthorUser(e, user)}
                onMouseLeave={e => handleCloseAuthorUser()}
              >
                <PlaceholderInfo
                  type={'Persona'}
                  firstName={user?.profile?.first_name}
                  lastName={user?.profile?.last_name}
                  imagePath={user?.profile?.image}
                  detailInfo={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
                  size={24}
                  isMobile={isMobile}
                />
              </div>
              <div className={`${themeClass}_list_item_company`}>{user?.parties?.[0]?.company}</div>
            </div>
          );
        })}
      </div>
    </Popover>
  );
};

export default WatchlistGroupPreview;
