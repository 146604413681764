import React, { FC, useState } from 'react';
import { TextInputNew } from '../index';
import { SystemButton } from '../ButtonComponents';
import { isLoggedIn } from '../../../service/auth';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';

interface IProps {
  label: string;
  value: string;
  type: string;
  errorMsg: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  logout?: () => void;
  handleLogin: () => void;
  labelHidden?: boolean;
  theme: 'light' | 'dark';
}

const TextInputView: FC<IProps> = ({
  label,
  onChange,
  value,
  errorMsg,
  disabled,
  logout,
  handleLogin,
  labelHidden,
  type = 'text',
  theme,
}) => {
  const [showPassword, setShowPassword] = useState(type);

  const themeClass = useGetThemeClass('TextInput', theme);
  const { isMobile } = GetResolution();

  const isError = errorMsg.length;

  const handleShowPassword = () => {
    setShowPassword(prevState => (prevState === 'text' ? 'password' : 'text'));
  };

  return (
    <div className={`${themeClass}__container ${label === 'Password' ? '-password' : ''}`}>
      <TextInputNew
        label={labelHidden ? null : label}
        value={value}
        onChange={onChange}
        placeholder={label === 'Email' ? 'Enter email' : 'Enter password'}
        type={'on-bgd'}
        inputType={showPassword}
        customIconClass={`${themeClass}__isWatch`}
        customIcon={
          type === 'password' ? (
            <SystemButton
              type={showPassword === 'password' ? 'deactivate' : 'activate'}
              size={'md'}
              variant={'transparent'}
              isMobile={isMobile}
            />
          ) : null
        }
        onClickIcon={handleShowPassword}
        disabled={disabled}
        onKeyPress={e => {
          if (e.key === 'Enter' && handleLogin) {
            e.preventDefault();
            handleLogin();
          }
        }}
        theme={theme}
        name={showPassword}
        isMobile={isMobile}
      />
      {isLoggedIn() && type === 'login' ? (
        <span className={`${themeClass}__logOut`} onClick={logout}>
          Log Out
        </span>
      ) : null}
      {isError ? <div className={`${themeClass}__errorMsg`}>{errorMsg}</div> : null}
    </div>
  );
};
export default TextInputView;
