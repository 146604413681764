import React, { Component } from 'react';

class Update_20230306_1 extends Component {
  render() {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <table width="600px" cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <td width="45%" valign="center" align="right" style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999' }}>
                  <p style={{ marginRight: '8px' }}>
                    <b>Provide response right away</b>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image2.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                  </p>
                </td>
                <td
                  width="55%"
                  valign="top"
                  align="left"
                  style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999', borderRight: '1px solid #999999' }}
                >
                  <p>
                    <i>User gets redirected to the Response tab to input Response description and upload files.</i>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="45%" valign="center" align="right" style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999' }}>
                  <p style={{ marginRight: '8px' }}>
                    <b>Commit now</b>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image4.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                  </p>
                </td>
                <td
                  width="55%"
                  valign="top"
                  align="left"
                  style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999', borderRight: '1px solid #999999' }}
                >
                  <p>
                    <i>NF status gets changed to ‘Commitment submitted’. </i>
                    <br />
                    <i>Response party commits to provide a Response within a due date specified by the Request party.</i>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="45%" valign="center" align="right" style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999' }}>
                  <p style={{ marginRight: '8px' }}>
                    <b>Commit with due date change</b>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image4.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                    <span>+</span>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image1.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                  </p>
                </td>
                <td
                  width="55%"
                  valign="top"
                  align="left"
                  style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999', borderRight: '1px solid #999999' }}
                >
                  <p>
                    <i>
                      User gets redirected to the ‘Request due date change’ procedure, and specifies a realistic due date to get approved by
                      the Request party. Once approved, NF status changed to ‘Commitment submitted. Response party commits to provide a
                      response with an updated due date.
                    </i>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="45%" valign="center" align="right" style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999' }}>
                  <p style={{ marginRight: '8px' }}>
                    <b>Commit with pull request</b>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image4.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                    <span>+</span>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image10.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                  </p>
                </td>
                <td
                  width="55%"
                  valign="top"
                  align="left"
                  style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999', borderRight: '1px solid #999999' }}
                >
                  <p>
                    <i>
                      User gets redirected to a ‘Pull request’ form. After the Pull request is sent, the status of original NF gets changed
                      to ‘Commitment submitted’. Response party commits to provide a response with an updated due date.
                    </i>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  width="45%"
                  valign="center"
                  align="right"
                  style={{ borderTop: '1px solid #999999', borderLeft: '1px solid #999999', borderBottom: '1px solid #999999' }}
                >
                  <p style={{ marginRight: '8px' }}>
                    <b>Redirect to a different party</b>
                    <span style={{ display: 'inline-block' }}>
                      <a target="_blank">
                        <img
                          src="https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image5.png"
                          alt=""
                          style={{ display: 'block' }}
                          width="16"
                        />
                      </a>
                    </span>
                  </p>
                </td>
                <td width="55%" valign="top" align="left" style={{ border: '1px solid #999999' }}>
                  <p>
                    <i>User gets redirected to a standard ‘Response party change’ procedure.</i>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={'paragraph'} style={{ padding: '15px 0 0 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_9d442713d5ec2443d645be5af33d17b2/images/image11_1Ni.gif'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20230306_1;
