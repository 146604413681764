import React from 'react';
import BuildingContainer from '../Building/BuildingContainer';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';

import './Step2ViewStyles.scss';

function Step2View({
  buildings,
  deleteBuilding,
  project,
  handleChangeBuildingFormData,
  createBuilding,
  handleAddBuilding,
  getProjectStatus,
  exitEditMode,
}) {
  const themeClass = useGetThemeClass('b-step2View');

  return (
    <div className={'b-createProject__step2'}>
      <div className={`${themeClass}__wrapper`}>
        {buildings.map((building, index) => {
          return (
            <div key={`buildings-${index}`} className={`${themeClass}__container`}>
              <BuildingContainer
                building={building}
                deleteBuilding={deleteBuilding(building, index)}
                project={project}
                index={index}
                handleChangeBuildingFormData={handleChangeBuildingFormData(index)}
                createBuilding={createBuilding}
              />
            </div>
          );
        })}
        <div className={`${themeClass}__footer`}>
          <CustomButton
            type={'text-plain'}
            size={'md'}
            clickHandler={handleAddBuilding}
            title={'Add building'}
            disabled={getProjectStatus.loading}
            iconClass={`${themeClass}__btnIcon`}
            icon={<TokenIcon iconName={'plus'} size={16} />}
          />
          <CustomButton type={'tertiary'} size={'md'} clickHandler={exitEditMode} title={'Exit edit mode'} />
        </div>
      </div>
    </div>
  );
}

export default Step2View;
