import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';
import CustomTooltip from '../../Tooltip/Tooltip';
import { GetResolution } from '../../../../helpers/ScreenResolution/GetResolution';

import './StatusIncorporationStyles.module.scss';

const StatusIncorporation: FC<{
  status: 'not_required' | 'not_started' | 'in_progress' | 'completed' | 'pending' | 'incorporated' | 'discarded';
  isVisibleLabel?: boolean;
  tooltip?: string;
  label?: string;
  isDragRequestCard?: boolean;
  size?: 'md';
  counter?: string;
}> = ({ status, isVisibleLabel = false, tooltip, label, isDragRequestCard, size, counter }) => {
  const themeClass = useGetThemeClass('b-statusIncorporation');
  const { isTablet } = GetResolution();

  return (
    <>
      <CustomTooltip title={tooltip || ''} placement={'bottom'}>
        <div
          className={cn(`${themeClass}`, {
            [`-gap8`]: isVisibleLabel,
            ['-sidePeek']: isDragRequestCard || isTablet,
          })}
        >
          <div
            className={cn(`${themeClass}_svg_icon`, {
              [`${themeClass}_sys-success`]: status === 'completed' || status === 'incorporated',
              [`${themeClass}_in-progress`]: status === 'in_progress',
              [`${themeClass}_not`]:
                status === 'not_started' || status === 'not_required' || status === 'discarded' || status === 'pending',
            })}
          >
            {status === 'completed' || status === 'incorporated' ? (
              <TokenIcon iconName={'check'} size={12} />
            ) : status === 'in_progress' ? (
              <TokenIcon iconName={'overflow-menu'} size={12} />
            ) : status === 'not_started' || status === 'pending' ? (
              <TokenIcon iconName={'dash'} size={12} />
            ) : status === 'not_required' || status === 'discarded' ? (
              <TokenIcon iconName={'close'} size={12} />
            ) : null}
          </div>
          {isVisibleLabel && (
            <div
              className={cn(`label_text`, {
                [`-${size}`]: size,
              })}
            >
              {`${label} ${counter || ''}` || ''}
            </div>
          )}
        </div>
      </CustomTooltip>
    </>
  );
};

export default React.memo(StatusIncorporation);
