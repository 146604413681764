import React, { MutableRefObject, ReactElement, useEffect, useRef } from 'react';
import { useClickOutside } from '../../../helpers/commonHooks';
import { useGetThemeClass } from '../../../helpers/designTokens';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import CopyToClipboard from '../../controls/CopyClickboard/CopyToClikboard';
import { RequestUserModel } from '../../../models';
import Popover from '@mui/material/Popover';

import './PopupProfileStyles.module.scss';

interface IProps {
  styles?: { top: number; left: number };
  user: RequestUserModel;
  viewProfile: () => void;
  closeTeamUser: (e) => void;
  closeFixedTooltip: (e) => void;
  anchorEl: Element;
}

export const PopupProfile = ({ styles, user, viewProfile, closeTeamUser, closeFixedTooltip, anchorEl }: IProps) => {
  const themeClass = useGetThemeClass('b-popupProfile');
  const ref = useRef<HTMLDivElement>();
  useClickOutside(ref, closeFixedTooltip);

  const getOrigin = (refEl: MutableRefObject<HTMLDivElement>): 'top' | 'bottom' => {
    if (refEl.current) {
      const windowHeight = window.innerHeight;
      const popupHeight = refEl.current.getBoundingClientRect().height;
      const anchorVerticalPosition = anchorEl.getBoundingClientRect().y;

      if (windowHeight - 100 < anchorVerticalPosition + popupHeight) {
        return 'bottom';
      } else {
        return 'top';
      }
    } else {
      return 'top';
    }
  };

  const verticalOrigin = getOrigin(ref);

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: verticalOrigin === 'top' ? 'bottom' : 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: verticalOrigin, horizontal: 'left' }}
      elevation={0}
      classes={{ paper: `${themeClass}__container ${verticalOrigin === 'bottom' ? '-origin_top' : '-origin_bottom'}` }}
      onClose={closeTeamUser}
    >
      <div ref={ref} className={`${themeClass}`} style={styles} onMouseLeave={e => closeTeamUser(e)}>
        <div className={`${themeClass}_content`}>
          <PlaceholderInfo
            type={'Persona'}
            firstName={user?.profile?.first_name}
            lastName={user?.profile?.last_name}
            detailInfo={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
            subInfo={`${(user?.roles && user.roles[0]?.description) || ''}`}
            imagePath={user?.profile?.image ? `${user?.profile?.image}` : null}
            size={56}
          />
          <div className={`${themeClass}_content_info`}>
            {user?.parties &&
              user?.parties.map(item => {
                return (
                  <PlaceholderInfo
                    type={'Company'}
                    firstName={item?.company}
                    lastName={''}
                    subInfo={item?.acronym}
                    detailInfo={item?.company}
                    imagePath={item?.logo}
                    size={24}
                    key={item?.id}
                  />
                );
              })}
            <div className={`${themeClass}_detail`}>
              <div className={'label_text'}>{user?.profile?.title}</div>
              <div className={`${themeClass}_detail_email`}>
                <div className={'label_text'}>{user?.email}</div>
                <CopyToClipboard>
                  {({ copy }) => <TokenIcon iconName={'copy'} size={12} clickHandler={() => copy(user?.email ? user.email : '')} />}
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div className={`${themeClass}_btnContainer`}>
          <CustomButton type={'secondary'} size={'sm'} clickHandler={viewProfile} title={'View profile'} />
        </div>
      </div>
    </Popover>
  );
};
