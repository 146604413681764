import React, { FC, useEffect, useState } from 'react';
import { TextInputNew } from '../../../../../../../controls';
import { Select } from '../../../../../../../controls/Dropdowns/DropdownСomponents';
import { useAppSelector } from '../../../../../../../../store/configure/configureStore';
import { BuildingModel, SimpleTitleModel } from '../../../../../../../../models';

interface IProps {
  col: any;
  isSelect?: boolean;
  isMulti?: boolean;
  building?: BuildingModel<SimpleTitleModel[]>;
  onChange: (field) => void;
}

let cachedValue = '';

const BasicEditor: FC<IProps> = ({ col, onChange, isSelect, isMulti, building }) => {
  const { phasePlanSelect } = useAppSelector(state => state.phasePlan);
  const [value, setValue] = useState(isSelect ? (isMulti ? [] : null) : '');

  if (!building || !col || !Object.keys(phasePlanSelect).length || !onChange) {
    return null;
  }

  const values = () => {
    if (col.column.key === 'building_spaces') {
      return building.building_spaces || [];
    } else {
      return phasePlanSelect[col.column.key];
    }
  };

  const getSelectedItems = () => {
    if (col.column.key === 'building_spaces') {
      return (
        (building.building_spaces &&
          building.building_spaces.filter(f => col.row[col.column.key] && col.row[col.column.key].find(r => r.id === f.id))) ||
        []
      );
    } else {
      return phasePlanSelect[col.column.key].filter(f => col.row[col.column.key] && col.row[col.column.key].find(r => r.id === f.id));
    }
  };

  const handleChange = value => {
    setValue(value);
  };

  useEffect(() => {
    cachedValue = value;
  }, [value]);

  useEffect(() => {
    if (isSelect && Object.keys(phasePlanSelect).length) {
      if (isMulti) {
        const selectedItems = getSelectedItems();
        setValue(selectedItems);
      } else {
        setValue(
          phasePlanSelect[col.column.key === 'occupancy_type_id' ? 'occupancy_types' : col.column.key].find(
            f => f.id === col.row[col.column.key],
          ),
        );
      }
    } else {
      setValue(col.row[col.column.key]);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (cachedValue) {
        if (isSelect) {
          if (isMulti) {
            onChange({ id: col.row.id || col.row.custom_id, [col.column.key]: cachedValue });
          } else {
            onChange({ id: col.row.id || col.row.custom_id, [col.column.key]: cachedValue.id });
          }
        } else {
          onChange({ id: col.row.id || col.row.custom_id, [col.column.key]: cachedValue });
        }
      }
    };
  }, []);

  if (isSelect && !isMulti) {
    return (
      <Select
        isMulti={false}
        isOpenMenu
        menuItems={phasePlanSelect[col.column.key === 'occupancy_type_id' ? 'occupancy_types' : col.column.key] || []}
        selectHandler={sel => setValue(sel)}
        selected={value}
        menuItemSize={'md'}
      />
    );
  }

  if (isSelect && isMulti) {
    return (
      <Select
        isMulti={true}
        isOpenMenu
        isHeader
        menuItems={values() || []}
        selectHandler={sel => setValue(sel)}
        selected={value}
        menuItemSize={'md'}
      />
    );
  }

  return <TextInputNew isFocus autoFocus type={'on-bgd'} inputType={'text'} value={value || ''} onChange={handleChange} />;
};
export default BasicEditor;
