import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';
import { statusNFType, statusNFConst } from '../../../../constants/cardTypeConstants';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';

import './StatusNFStyles.module.scss';

const StatusNF: FC<{
  status: statusNFType;
  statusTimeline?: 'past' | 'current' | 'future';
  isShowHover?: boolean;
  isActive?: boolean;
  isMobile?: boolean;
  isHiddenText?: boolean;
}> = ({ status, statusTimeline, isShowHover, isActive, isMobile, isHiddenText }) => {
  const themeClass = useGetThemeClass('b-status_NF');

  const getStatusName = status => {
    if (status === statusNFConst.submitted || status === statusNFConst.submitted_under) {
      return 'Commitment submitted';
    } else if (status === statusNFConst.drafted) {
      return 'Request drafted';
    } else if (status === statusNFConst.sent || status === statusNFConst.new) {
      return 'Request sent';
    } else if (status === statusNFConst.deactivated) {
      return 'Request deactivated';
    } else if (status === statusNFConst.provided) {
      return 'Response submitted';
    } else if (status === statusNFConst.declined) {
      return 'Response declined';
    } else if (status === statusNFConst.reOpened) {
      return 'Request Re-opened';
    } else if (status === statusNFConst.accepted) {
      return 'Response accepted';
    } else if (status === statusNFConst.closed) {
      return 'Closed';
    }
  };

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-disabled']: status === statusNFConst.deactivated,
          ['-showHover']: isShowHover,
          ['-isActive']: isActive,
        })}
      >
        <div
          className={cn({
            [`${themeClass}_sys-informative`]:
              status === statusNFConst.submitted ||
              status === statusNFConst.sent ||
              status === statusNFConst.new ||
              status === statusNFConst.reOpened ||
              status === statusNFConst.provided,
            [`${themeClass}_sys-warning`]: status === statusNFConst.submitted_under,
            [`${themeClass}_tertiary-gray`]: status === statusNFConst.drafted || status === statusNFConst.deactivated,
            [`${themeClass}_sys-success`]: status === statusNFConst.accepted || status === statusNFConst.closed,
            [`${themeClass}_sys-danger`]: status === statusNFConst.declined,
            [`${themeClass}_on-bgd-srf-3`]: statusTimeline === 'past',
            [`${themeClass}_on-bgd-srf-4`]: statusTimeline === 'future',
          })}
        >
          <TokenIcon iconName={'status'} size={12} />
        </div>
        {!isHiddenText && (
          <div
            className={cn('label_text', {
              ['-past']: statusTimeline === 'past',
              ['-future']: statusTimeline === 'future',
              ['-mobile']: isMobile,
            })}
          >
            {getStatusName(status)}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(StatusNF);
