import React from 'react';
import { IConfirmDialogProps } from './types';
import DialogPopUp from '../../../controls/DialogPopUp/DialogPopUp';

export const ConfirmDialog = ({ isOpen, title, message, onAllow, onCancel, onClose }: IConfirmDialogProps): JSX.Element => {
  const handleCancel = () => {
    onClose();
    if (onCancel) onCancel();
  };

  const handleAllow = () => {
    onClose();
    onAllow(false);
  };

  return (
    <DialogPopUp
      open={isOpen}
      onClose={handleCancel}
      title={title}
      hideBackdrop={true}
      maxWidth={'xs'}
      tertiaryText={'Cancel'}
      customSecondaryType={'tertiary'}
      handleOnTertiary={handleCancel}
      primaryText={'Confirm'}
      handleOnPrimary={handleAllow}
      modalText={message}
    />
  );
};
