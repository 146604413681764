import React, { lazy, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthorizedRoutes, getOutputDataPage, getSandBoxNew, UnauthorizedRoutes } from '../routes';
import { useAppSelector } from '../../store/configure/configureStore';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import useRouter from '../../hooks/useRouter/useRouter';
import { Unsubscribe } from '../../components/pages';

const NeedsList = lazy(() => import('../../components/common/NeedsList/NeedsList'));
const Project = lazy(() => import('../../components/common/Project/ProjectComponent'));
const Profile = lazy(() => import('../../components/common/Profile/Profile'));
const WhiteBoard = lazy(() => import('../../components/common/WhiteBoard/WhiteBoard'));
const Reporting = lazy(() => import('../../components/common/Reporting/ReportingContainer'));
const NotificationsList = lazy(() => import('../../components/common/NotificationsList/NotificationsList'));
const PhasePlan = lazy(() => import('../../components/common/PhasePlan/PhasePlanContainer'));
const SandBox = lazy(() => import('../../components/common/SandBox/SandBox'));
const InputDataPage = lazy(() => import('../../components/common/InputDataPage/InputDataPage'));
const OutputDataPage = lazy(() => import('../../components/common/OutputDataPage/OutputDataPage'));
const TeamPage = lazy(() => import('../../components/pages/TeamPage/TeamPage'));
const TeamEditCompanyPage = lazy(() => import('../../components/pages/TeamEditCompanyPage/TeamEditCompanyPage'));
const ComponentCheckPage = lazy(() => import('../../components/pages/ComponentCheckPage/ComponentCheckPage'));
const CreateProjectContainer = lazy(() => import('../../components/common/CreateProject/CreateProjectContainer'));
const NonLoginPage = lazy(() => import('../../components/pages/NonLoginedPage/LoginPageContainer'));

const AuthorizedRouter = () => {
  const { urlProject } = useRouter();
  const { isProjectAccess } = usePermissions();

  // User slice
  const user = useAppSelector(state => state.userReducer);

  const isUserInfoEmpty = useMemo(() => {
    return user?.userInfo.id === 0;
  }, [user?.userInfo?.id]);

  const isAdmin = useMemo(() => {
    return isUserInfoEmpty || isProjectAccess;
  }, [isUserInfoEmpty, isProjectAccess]);

  const isActiveUser = localStorage.getItem('isPro') === '1' || localStorage.getItem('isPro') === '4';
  const projectName = sessionStorage.getItem('project_name') || urlProject[0];

  return (
    <Routes>
      {/* -------------------------------------------------------
                            PHASE PLAN
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.phasePlan} element={<PhasePlan />} />
      {/* -------------------------------------------------------
                            NEEDS LIST
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.needsList} element={<NeedsList />} />
      {/* -------------------------------------------------------
                            COMMAND CENTER
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.commandCenter} element={<SandBox />} />
      <Route path={AuthorizedRoutes.commandCenterRequest} element={<SandBox />} />
      <Route path={AuthorizedRoutes.sandBoxOld} element={<Navigate to={getSandBoxNew(projectName)} />} />
      <Route path={AuthorizedRoutes.sandBoxOldRequest} element={<Navigate to={getSandBoxNew(projectName)} />} />
      {/* -------------------------------------------------------
                            CARD SEPARATE TAB
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.card} element={<div></div>} />
      {/* -------------------------------------------------------
                            CREATE/EDIT PROJECT
          ------------------------------------------------------- */}
      {(isAdmin || isActiveUser) && (
        <>
          <Route path={AuthorizedRoutes.createProjectIdSheet} element={<CreateProjectContainer />} />
          <Route path={AuthorizedRoutes.createProjectId} element={<CreateProjectContainer />} />
          <Route path={AuthorizedRoutes.createProject} element={<CreateProjectContainer />} />
        </>
      )}
      {/* -------------------------------------------------------
                            WHITE BOARD
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.whiteBoard} element={<WhiteBoard />} />
      {/* -------------------------------------------------------
                            REPORTING
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.reporting} element={<Reporting />} />
      {/* -------------------------------------------------------
                            INPUT DATA
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.inputData} element={<InputDataPage />} />
      {/* -------------------------------------------------------
                            OUTPUT DATA
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.outputData} element={<OutputDataPage />} />
      <Route path={AuthorizedRoutes.pcDeliverables} element={<Navigate to={getOutputDataPage(projectName, 'deliverables')} />} />
      <Route path={AuthorizedRoutes.pcDeliverablesOld} element={<Navigate to={getOutputDataPage(projectName, 'deliverables')} />} />
      {/* -------------------------------------------------------
                            TEAM
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.team} element={<TeamPage />} />
      <Route path={AuthorizedRoutes.teamEditCompany} element={<TeamEditCompanyPage />} />
      {/* -------------------------------------------------------
                            PROJECTS
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.project} element={<Project />} />
      {/* -------------------------------------------------------
                            PROFILE
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.profile} element={<Profile />} />
      {/* -------------------------------------------------------
                            NOTIFICATIONS
          ------------------------------------------------------- */}
      {isAdmin && <Route path={AuthorizedRoutes.notificationsList} element={<NotificationsList />} />}
      {/* -------------------------------------------------------
                            COMPONENTS PAGE
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.componentCheckPage} element={<ComponentCheckPage />} />
      {/* -------------------------------------------------------
                            CHANGE COMPANY
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.changeCompany} element={<NonLoginPage />} />
      {/* -------------------------------------------------------
                            CHANGE PROJECT
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.changeProject} element={<NonLoginPage />} />
      {/* -------------------------------------------------------
                            UNSUBSCRIBE
          ------------------------------------------------------- */}
      <Route path={AuthorizedRoutes.unsubscribe} element={<Unsubscribe />} />
      {/* -------------------------------------------------------
                            OTHER URLS
          ------------------------------------------------------- */}
      <Route path={'*'} element={<Navigate to={projectName ? getSandBoxNew(projectName) : AuthorizedRoutes.changeCompany} />} />
    </Routes>
  );
};

export default AuthorizedRouter;
