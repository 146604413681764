import { CustomButton } from '../../../../controls/ButtonComponents';
import React from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import { SubmittalCardTabNamesModel } from '../../constants/submittalTabs';

interface IProps {
  selectedRequest: ISubmittal;
  isDragRequestCard: boolean;
  submittalLoading: boolean;
  saveSubmittal: (isSubmit: boolean) => void;
  onCreateNewRevision: () => void;
  isAnyReturnedReviews: boolean;
  handleCloseAndDistribute: () => void;
  isUpdateDraftButtonDisabled: boolean;
  isCreateSubmittal: boolean;
  activeTab: SubmittalCardTabNamesModel;
  isDeactivated: boolean;
  isPreviousRevisionDeactivated: boolean;
}

const GeneralButtons = ({
  selectedRequest,
  isDragRequestCard,
  submittalLoading,
  saveSubmittal,
  onCreateNewRevision,
  isAnyReturnedReviews,
  handleCloseAndDistribute,
  isUpdateDraftButtonDisabled,
  isCreateSubmittal,
  activeTab,
  isDeactivated,
  isPreviousRevisionDeactivated,
}: IProps) => {
  const themeClass = useGetThemeClass('b-cardSubmittal-generalTab');
  const { isMobile } = GetResolution();

  const renderDraftBtnTooltip = () => {
    if (localStorage.getItem('show_tooltips') === 'no') {
      return '';
    } else {
      if (!selectedRequest?.id) {
        return 'Save Submittal as a draft. It will be possible to send it later';
      } else {
        return 'Apply changes to Submittal';
      }
    }
  };

  const renderCloseAndDistribute = () => {
    if (!isDragRequestCard || selectedRequest?.status.alias !== 'open') return null;
    if (
      ((selectedRequest?.review_state === 'approved' && isAnyReturnedReviews) || selectedRequest?.review_state !== 'approved') &&
      activeTab !== 'review'
    )
      return null;

    return (
      <CustomButton
        type={selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews ? 'primary' : 'tertiary'}
        size={'md'}
        title={'Close & Distribute'}
        clickHandler={handleCloseAndDistribute}
        loading={submittalLoading}
        disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
      />
    );
  };

  return (
    <div className={`${themeClass}_buttonsBlock`}>
      <div className={`${themeClass}_buttonsGroup`}>
        {selectedRequest?.status?.alias !== 'closed' && (
          <CustomButton
            type={'tertiary'}
            size={'md'}
            clickHandler={() => saveSubmittal(false)}
            title={isCreateSubmittal ? 'Save draft' : 'Update'}
            loading={submittalLoading}
            disabled={isUpdateDraftButtonDisabled}
            customClassName={`${themeClass}_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
            tooltip={renderDraftBtnTooltip()}
            isMobile={isMobile}
          />
        )}
        {selectedRequest?.status?.alias !== 'draft' && !isCreateSubmittal && !selectedRequest?.parent_id && (
          <CustomButton
            type={
              selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews && selectedRequest.status.alias === 'open'
                ? 'secondary'
                : 'primary'
            }
            size={'md'}
            clickHandler={onCreateNewRevision}
            title={'Create new revision'}
            customClassName={`${themeClass}_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
            isMobile={isMobile}
            loading={submittalLoading}
            disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
          />
        )}
        {renderCloseAndDistribute()}
        {(isCreateSubmittal || selectedRequest?.status?.alias === 'draft') && (
          <CustomButton
            type={'primary'}
            size={'md'}
            title={'Submit'}
            clickHandler={() => saveSubmittal(true)}
            customClassName={`${themeClass}_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonsGroup_tooltipBasis`}
            isMobile={isMobile}
            loading={submittalLoading}
            disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
          />
        )}
      </div>
    </div>
  );
};

export default GeneralButtons;
