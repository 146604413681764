import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, PageHeading2, Logo } from '../../controls/index';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../navigation/routes';
import { handleGetThemeClass } from '../../../helpers/designTokens';
import { CustomButton } from '../../controls/ButtonComponents';

import '../NonLoginedPage/NonLoginedPageStyles.scss';

class RestorePasswordView extends React.Component {
  static propTypes = {
    //handlers
    handleChangeField: PropTypes.func.isRequired,
    handleLogin: PropTypes.func.isRequired,
    //data
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const themeClass = handleGetThemeClass('b-authorization-loginPage', localStorage.getItem('theme') || 'light');
    const { handleChangeField, user, handleLogin, loading, message, status, error } = this.props;

    return (
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_topInfo`}>
          <div className={`${themeClass}_logoContainer`}>
            <Logo isFull themeProp={localStorage.getItem('theme') || 'light'} />
            <p className={`${themeClass}_logoContainer__header`}>Plan & Control App</p>
          </div>
          {!status || error ? <span className={`${themeClass}_loginError`}>{error || message}</span> : null}
          <div className={`${themeClass}_form`}>
            <div className={`${themeClass}_form__heade`}>
              <PageHeading2 title="Restore password" theme={localStorage.getItem('theme') || 'light'} />
            </div>

            <TextInput
              label="Setup password"
              value={user.password}
              onChange={handleChangeField('password')}
              type="password"
              errorMsg=""
              theme={localStorage.getItem('theme') || 'light'}
              handleLogin={handleLogin}
            />
            <TextInput
              label="Confirm password"
              value={user.confirmPassword}
              onChange={handleChangeField('confirmPassword')}
              type="password"
              errorMsg=""
              theme={localStorage.getItem('theme') || 'light'}
              handleLogin={handleLogin}
            />
            <div className={`${themeClass}_loginBtn`}>
              <CustomButton
                type={'primary'}
                size={'md'}
                title={'Reset password'}
                disabled={loading || !user.password || !user.confirmPassword}
                loading={loading}
                clickHandler={handleLogin}
              />
            </div>
            <div className={`${themeClass}_backToSignIn`}>
              <Link to={ROUTES.loginP}>
                <CustomButton type={'text-plain'} size={'xs'} title={'Back to Sign in'} />
              </Link>
            </div>
          </div>
        </div>
        <div className={`${themeClass}_bottomInfo`}>
          <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
          {/*<span className={`${themeClass}_bottomInfo_textInfo`}>Privacy Policy</span>*/}
        </div>
      </div>
    );
  }
}

export default RestorePasswordView;
