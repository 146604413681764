import { KeyValueModel } from '../models/key-value.model';

interface ThemeModel {
  colors: any;
  fonts: KeyValueModel<string>;
  images: KeyValueModel<string>;
  skeletons: KeyValueModel<string>;
  svgImages: KeyValueModel<KeyValueModel<string>>;
}

export const initTheme = (theme: ThemeModel) => {
  // Background
  document.body.style.setProperty('--background-grid', theme.colors.background.grid);
  document.body.style.setProperty('--background-header', theme.colors.background.header);
  document.body.style.setProperty('--background-sheet', theme.colors.background.sheet);
  document.body.style.setProperty('--background-main', theme.colors.background.main);
  document.body.style.setProperty('--background-alterMain', theme.colors.background.alterMain);
  document.body.style.setProperty('--background-border', theme.colors.background.border);
  document.body.style.setProperty('--background-tour', theme.colors.background.tour);
  document.body.style.setProperty('--background-isDeactivated', theme.colors.background.isDeactivated);
  document.body.style.setProperty('--background-carousel', theme.colors.background.carousel);
  document.body.style.setProperty('--background-arrow', theme.colors.background.arrow);
  document.body.style.setProperty('--background-hashtag', theme.colors.background.hashtag);
  document.body.style.setProperty('--background-selectFilterDisabled', theme.colors.background.selectFilterDisabled);
  document.body.style.setProperty('--background-reportingHeader', theme.colors.background.reportingHeader);

  // Planit colors
  document.body.style.setProperty('--color1', theme.colors.background.color1);
  document.body.style.setProperty('--color2', theme.colors.background.color2);
  document.body.style.setProperty('--color3', theme.colors.background.color3);
  document.body.style.setProperty('--color4', theme.colors.background.color4);
  document.body.style.setProperty('--color5', theme.colors.background.color5);

  // New SandBox
  document.body.style.setProperty('--sandbox-tooltip-background', theme.colors.sandBox.tooltip.background);

  document.body.style.setProperty('--sandbox-graphs-alertList-background', theme.colors.sandBox.graphs.alertList.background);

  document.body.style.setProperty('--sandbox-graphs-alertSnippet-background', theme.colors.sandBox.graphs.alertSnippet.background);
  document.body.style.setProperty('--sandbox-graphs-alertSnippet-headText', theme.colors.sandBox.graphs.alertSnippet.headText);
  document.body.style.setProperty('--sandbox-graphs-alertSnippet-midText', theme.colors.sandBox.graphs.alertSnippet.midText);

  document.body.style.setProperty('--sandbox-graphs-heatmap-text', theme.colors.sandBox.graphs.heatmap.text);
  document.body.style.setProperty('--sandbox-graphs-heatmap-arrowBackground', theme.colors.sandBox.graphs.heatmap.arrowBackground);
  document.body.style.setProperty('--sandbox-graphs-heatmap-tooltip-background', theme.colors.sandBox.graphs.heatmap.tooltip.background);
  document.body.style.setProperty('--sandbox-graphs-heatmap-tooltip-text', theme.colors.sandBox.graphs.heatmap.tooltip.text);

  document.body.style.setProperty('--sandbox-relationSwitcher-plain-label', theme.colors.sandBox.relationSwitcher.plain.label);
  document.body.style.setProperty('--sandbox-relationSwitcher-plain-default', theme.colors.sandBox.relationSwitcher.plain.default);
  document.body.style.setProperty('--sandbox-relationSwitcher-plain-hover', theme.colors.sandBox.relationSwitcher.plain.hover);
  document.body.style.setProperty('--sandbox-relationSwitcher-plain-pressed', theme.colors.sandBox.relationSwitcher.plain.pressed);
  document.body.style.setProperty('--sandbox-relationSwitcher-plain-disabled', theme.colors.sandBox.relationSwitcher.plain.disabled);

  document.body.style.setProperty('--sandbox-relationSwitcher-active-label', theme.colors.sandBox.relationSwitcher.active.label);
  document.body.style.setProperty('--sandbox-relationSwitcher-active-default', theme.colors.sandBox.relationSwitcher.active.default);
  document.body.style.setProperty('--sandbox-relationSwitcher-active-hover', theme.colors.sandBox.relationSwitcher.active.hover);
  document.body.style.setProperty('--sandbox-relationSwitcher-active-pressed', theme.colors.sandBox.relationSwitcher.active.pressed);
  document.body.style.setProperty('--sandbox-relationSwitcher-active-disabled', theme.colors.sandBox.relationSwitcher.active.disabled);
  document.body.style.setProperty('--sandbox-relationSwitcher-active-border', theme.colors.sandBox.relationSwitcher.active.border);

  document.body.style.setProperty('--sandbox-tab-selected', theme.colors.sandBox.tab.selected);
  document.body.style.setProperty('--sandbox-tab-defaultText', theme.colors.sandBox.tab.defaultText);
  document.body.style.setProperty('--sandbox-tab-hover', theme.colors.sandBox.tab.hover);

  document.body.style.setProperty('--sandbox-section-hover', theme.colors.sandBox.section.hover);
  document.body.style.setProperty('--sandbox-section-active', theme.colors.sandBox.section.active);
  document.body.style.setProperty('--sandbox-section-hoverText', theme.colors.sandBox.section.textHover);

  document.body.style.setProperty('--sandbox-contentSwitcher-hover', theme.colors.sandBox.contentSwitcher.hover);

  document.body.style.setProperty('--sandbox-requestSnippet-activeBackground', theme.colors.sandBox.snippet.activeBackground);
  document.body.style.setProperty('--sandbox-requestSnippet-activeHoverBackground', theme.colors.sandBox.snippet.activeHoverBackground);
  document.body.style.setProperty('--sandbox-requestSnippet-icon', theme.colors.sandBox.snippet.icon);
  document.body.style.setProperty('--sandbox-requestSnippet-sectionIcon', theme.colors.sandBox.snippet.fadedSectionIcon);
  document.body.style.setProperty('--sandbox-requestSnippet-hover', theme.colors.sandBox.snippet.hover);
  document.body.style.setProperty('--sandbox-requestSnippet-focus', theme.colors.sandBox.snippet.focus);
  document.body.style.setProperty('--sandbox-requestSnippet-focus-border', theme.colors.sandBox.snippet.focusBorder);
  document.body.style.setProperty('--sandbox-requestSnippet-hover-shadow', theme.colors.sandBox.snippet.filter);

  document.body.style.setProperty('--sandbox-accordion-arrow', theme.colors.sandBox.accordion.arrow);
  document.body.style.setProperty('--sandbox-accordion-badge', theme.colors.sandBox.accordion.badge);

  document.body.style.setProperty('--sandbox-table-scroll-thumb', theme.colors.sandBox.table.scrollBarThumb);
  document.body.style.setProperty('--sandbox-table-scroll-track', theme.colors.sandBox.table.scrollBarTrack);
  document.body.style.setProperty('--sandbox-table-borderColor', theme.colors.sandBox.table.borderColor);

  document.body.style.setProperty('--color-sandBoxLabel', theme.colors.sandBox.text.label);
  document.body.style.setProperty('--color-sandBox-text', theme.colors.sandBox.text.text);
  document.body.style.setProperty('--color-sandBox-text2', theme.colors.sandBox.text.text2);
  document.body.style.setProperty('--color-sandBox-headlineText', theme.colors.sandBox.text.headlineText);
  document.body.style.setProperty('--color-sandBox-tabText', theme.colors.sandBox.text.tabText);

  // Text
  document.body.style.setProperty('--color', theme.colors.text.main);
  document.body.style.setProperty('--color-rdg', theme.colors.text.main);
  document.body.style.setProperty('--color-secondary', theme.colors.text.secondary);
  document.body.style.setProperty('--color-noSelect', theme.colors.text.noSelect);
  document.body.style.setProperty('--color-colorSelect', theme.colors.text.colorSelect);
  document.body.style.setProperty('--color-colorNoSelect', theme.colors.text.colorNoSelect);
  document.body.style.setProperty('--color-helpMenuColor', theme.colors.text.helpMenuColor);
  document.body.style.setProperty('--color-labelSelect', theme.colors.text.labelSelect);
  document.body.style.setProperty('--color-labelSelected', theme.colors.text.labelSelected);

  // CustomButton
  document.body.style.setProperty('--button-default-menu', theme.colors.buttons.default);
  document.body.style.setProperty('--button-hover-menu', theme.colors.buttons.hoverMenu);
  document.body.style.setProperty('--button-active-menu', theme.colors.buttons.activeMenu);
  document.body.style.setProperty('--button-default-menu-text', theme.colors.buttons.defaultMenuText);
  document.body.style.setProperty('--button-hover-menu-text', theme.colors.buttons.hoverMenuText);
  document.body.style.setProperty('--button-active-menu-text', theme.colors.buttons.activeMenuText);
  document.body.style.setProperty('--button-deactivated', theme.colors.buttons.deactivated);

  document.body.style.setProperty('--notification-today', theme.colors.notificationBell.today);
  document.body.style.setProperty('--notification-counter', theme.colors.notificationBell.counter);

  // CustomButton 2nd
  document.body.style.setProperty('--button2-unactive', theme.colors.buttons2.unactive);
  document.body.style.setProperty('--button2-active', theme.colors.buttons2.active);
  document.body.style.setProperty('--button2-hover', theme.colors.buttons2.hover);
  document.body.style.setProperty('--button2-unactive-text', theme.colors.buttons2.unactiveText);
  document.body.style.setProperty('--button2-active-text', theme.colors.buttons2.hoverText);
  document.body.style.setProperty('--button2-hover-text', theme.colors.buttons2.activeText);

  // CustomButton Accept
  document.body.style.setProperty('--button-accept', theme.colors.buttonAccept.default);
  document.body.style.setProperty('--button-accept-text', theme.colors.buttonAccept.defaultText);
  document.body.style.setProperty('--button-accept-hover', theme.colors.buttonAccept.hover);
  document.body.style.setProperty('--button-accept-hover-text', theme.colors.buttonAccept.hoverText);
  document.body.style.setProperty('--button-accept-disable', theme.colors.buttonAccept.disable);
  document.body.style.setProperty('--button-accept-disable-text', theme.colors.buttonAccept.disableText);

  // CustomButton Commit
  document.body.style.setProperty('--button-commit-hover', theme.colors.buttonCommit.hover);

  // CustomButton Decline
  document.body.style.setProperty('--button-decline', theme.colors.buttonDecline.default);
  document.body.style.setProperty('--button-decline-text', theme.colors.buttonDecline.defaultText);
  document.body.style.setProperty('--button-decline-hover', theme.colors.buttonDecline.hover);
  document.body.style.setProperty('--button-decline-hover-text', theme.colors.buttonDecline.hoverText);
  document.body.style.setProperty('--button-decline-disable', theme.colors.buttonDecline.disable);
  document.body.style.setProperty('--button-decline-disable-text', theme.colors.buttonDecline.disableText);

  // CustomButton PullRequest
  document.body.style.setProperty('--button-pullRequest', theme.colors.buttonPullRequest.default);
  document.body.style.setProperty('--button-pullRequest-border', theme.colors.buttonPullRequest.defaultBorder);
  document.body.style.setProperty('--button-pullRequest-text', theme.colors.buttonPullRequest.defaultText);
  document.body.style.setProperty('--button-pullRequest-hover', theme.colors.buttonPullRequest.hover);
  document.body.style.setProperty('--button-pullRequest-hover-border', theme.colors.buttonPullRequest.hoverBorder);
  document.body.style.setProperty('--button-pullRequest-hover-text', theme.colors.buttonPullRequest.hoverText);
  document.body.style.setProperty('--button-pullRequest-disable', theme.colors.buttonPullRequest.disable);
  document.body.style.setProperty('--button-pullRequest-disable-border', theme.colors.buttonPullRequest.disableBorder);
  document.body.style.setProperty('--button-pullRequest-alternate-border', theme.colors.buttonPullRequest.alternateBorder);
  document.body.style.setProperty('--button-pullRequest-disable-text', theme.colors.buttonPullRequest.disableText);

  // CustomButton Outlined
  document.body.style.setProperty('--button-outlined', theme.colors.outlinedButton.default);
  document.body.style.setProperty('--button-outlined-border', theme.colors.outlinedButton.defaultBorder);
  document.body.style.setProperty('--button-outlined-border-disabled', theme.colors.outlinedButton.disableBorder);
  document.body.style.setProperty('--button-outlined-hover', theme.colors.outlinedButton.hover);
  document.body.style.setProperty('--button-outlined-disabled', theme.colors.outlinedButton.disabled);
  document.body.style.setProperty('--button-outlined-text', theme.colors.outlinedButton.defaultText);
  document.body.style.setProperty('--button-outlined-hover-text', theme.colors.outlinedButton.hoverText);
  document.body.style.setProperty('--button-outlined-disabled-text', theme.colors.outlinedButton.disabledText);

  // CustomButton Canel
  document.body.style.setProperty('--button-cancel', theme.colors.cancelButton.default);
  document.body.style.setProperty('--button-cancel-border', theme.colors.cancelButton.defaultBorder);
  document.body.style.setProperty('--button-cancel-border-disabled', theme.colors.cancelButton.disableBorder);
  document.body.style.setProperty('--button-cancel-hover', theme.colors.cancelButton.hover);
  document.body.style.setProperty('--button-cancel-disabled', theme.colors.cancelButton.disabled);
  document.body.style.setProperty('--button-cancel-text', theme.colors.cancelButton.defaultText);
  document.body.style.setProperty('--button-cancel-hover-text', theme.colors.cancelButton.hoverText);
  document.body.style.setProperty('--button-cancel-disabled-text', theme.colors.cancelButton.disabledText);

  // CustomButton Action Request
  document.body.style.setProperty('--button-request-action', theme.colors.btnAction.default);
  document.body.style.setProperty('--button-request-action-text', theme.colors.btnAction.defaultText);
  document.body.style.setProperty('--button-request-action-hover', theme.colors.btnAction.hover);
  document.body.style.setProperty('--button-request-action-hover-text', theme.colors.btnAction.hoverText);
  document.body.style.setProperty('--button-request-action-disable', theme.colors.btnAction.disable);
  document.body.style.setProperty('--button-request-action-disable-text', theme.colors.btnAction.disableText);

  // CustomButton Default Request
  document.body.style.setProperty('--button-request-default', theme.colors.btnDefault.default);
  document.body.style.setProperty('--button-request-default-text', theme.colors.btnDefault.defaultText);
  document.body.style.setProperty('--button-request-default-hover', theme.colors.btnDefault.hover);
  document.body.style.setProperty('--button-request-default-hover-text', theme.colors.btnDefault.hoverText);
  document.body.style.setProperty('--button-request-default-disable', theme.colors.btnDefault.disable);
  document.body.style.setProperty('--button-request-default-disable-text', theme.colors.btnDefault.disableText);

  // CustomButton Outline Request
  document.body.style.setProperty('--button-request-outline', theme.colors.btnOutlined.default);
  document.body.style.setProperty('--button-request-outline-text', theme.colors.btnOutlined.defaultText);
  document.body.style.setProperty('--button-request-outline-border', theme.colors.btnOutlined.defaultBorder);
  document.body.style.setProperty('--button-request-outline-hover', theme.colors.btnOutlined.hover);
  document.body.style.setProperty('--button-request-outline-hover-text', theme.colors.btnOutlined.hoverText);
  document.body.style.setProperty('--button-request-outline-hover-border', theme.colors.btnOutlined.hoverBorder);
  document.body.style.setProperty('--button-request-outline-disable', theme.colors.btnOutlined.disable);
  document.body.style.setProperty('--button-request-outline-disable-text', theme.colors.btnOutlined.disableText);
  document.body.style.setProperty('--button-request-outline-border-disabled', theme.colors.btnOutlined.disableBorder);

  // CustomButton orange
  document.body.style.setProperty('--button-orange', theme.colors.orange.default);
  document.body.style.setProperty('--button-orange-hover', theme.colors.orange.hover);
  document.body.style.setProperty('--button-orange-disable', theme.colors.orange.disable);
  document.body.style.setProperty('--button-orange-text', theme.colors.orange.defaultText);
  document.body.style.setProperty('--button-orange-hover-text', theme.colors.orange.hoverText);
  document.body.style.setProperty('--button-orange-disable-text', theme.colors.orange.disableText);

  // CustomButton pour
  document.body.style.setProperty('--button-pour', theme.colors.pourButton.default);
  document.body.style.setProperty('--button-pour-text', theme.colors.pourButton.defaultText);
  document.body.style.setProperty('--button-pour-hover', theme.colors.pourButton.hover);
  document.body.style.setProperty('--button-pour-hover-text', theme.colors.pourButton.hoverText);
  document.body.style.setProperty('--button-pour-disable', theme.colors.pourButton.disable);
  document.body.style.setProperty('--button-pour-disable-text', theme.colors.pourButton.disableText);

  // CustomButton general
  document.body.style.setProperty('--button-default', theme.colors.generalButton.default);
  document.body.style.setProperty('--button-default-border', theme.colors.generalButton.defaultBorder);
  document.body.style.setProperty('--button-default-hover', theme.colors.generalButton.hover);
  document.body.style.setProperty('--button-default-hover-border', theme.colors.generalButton.hoverBorder);
  document.body.style.setProperty('--button-default-text', theme.colors.generalButton.defaultText);
  document.body.style.setProperty('--button-default-hover-text', theme.colors.generalButton.hoverText);

  // CustomButton comment
  document.body.style.setProperty('--button-comment', theme.colors.commentButton.default);
  document.body.style.setProperty('--button-comment-text', theme.colors.commentButton.defaultText);
  document.body.style.setProperty('--button-comment-hover', theme.colors.commentButton.hover);
  document.body.style.setProperty('--button-comment-hover-text', theme.colors.commentButton.hoverText);
  document.body.style.setProperty('--button-comment-disable', theme.colors.commentButton.disable);
  document.body.style.setProperty('--button-comment-disable-text', theme.colors.commentButton.disableText);

  // CustomButton link
  document.body.style.setProperty('--button-link-text', theme.colors.orange.default);
  document.body.style.setProperty('--button-link-hover-text', theme.colors.orange.hover);
  document.body.style.setProperty('--button-link-disable-text', theme.colors.commentButton.disableText);

  // CustomButton building
  document.body.style.setProperty('--button-building', theme.colors.buildingButton.default);
  document.body.style.setProperty('--button-building-text', theme.colors.buildingButton.defaultText);
  document.body.style.setProperty('--button-building-hover', theme.colors.buildingButton.hover);
  document.body.style.setProperty('--button-building-hover-text', theme.colors.buildingButton.hoverText);
  document.body.style.setProperty('--button-building-disable', theme.colors.buildingButton.disable);
  document.body.style.setProperty('--button-building-disable-text', theme.colors.buildingButton.disableText);

  // CustomButton show hide
  document.body.style.setProperty('--button-show-hide-text', theme.colors.showHideRequestButton.defaultText);
  document.body.style.setProperty('--button-show-hide-hover-text', theme.colors.showHideRequestButton.hoverText);

  // File Buttons
  document.body.style.setProperty('--button-show-more-files', theme.colors.fileButtons.showMore);
  document.body.style.setProperty('--button-attach-files', theme.colors.fileButtons.attachFile);

  //Phase Plan
  document.body.style.setProperty('--phasePlan-lod350', theme.colors.phasePlan.lod350);
  document.body.style.setProperty('--phasePlan-lod325', theme.colors.phasePlan.lod325);
  document.body.style.setProperty('--phasePlan-lod300', theme.colors.phasePlan.lod300);
  document.body.style.setProperty('--phasePlan-lod290', theme.colors.phasePlan.lod290);
  document.body.style.setProperty('--phasePlan-lod200', theme.colors.phasePlan.lod200);
  document.body.style.setProperty('--phasePlan-permit', theme.colors.phasePlan.permit);
  document.body.style.setProperty('--phasePlan-construction', theme.colors.phasePlan.construction);
  document.body.style.setProperty('--phasePlan-color', theme.colors.phasePlan.color);

  // Permissions
  document.body.style.setProperty('--phasePlan-permit1', theme.colors.permissions.color1);
  document.body.style.setProperty('--phasePlan-permit2', theme.colors.permissions.color2);
  document.body.style.setProperty('--phasePlan-permit3', theme.colors.permissions.color3);
  document.body.style.setProperty('--phasePlan-permit4', theme.colors.permissions.color4);

  // Driver
  document.body.style.setProperty('--phasePlan-mainDriver', theme.colors.driverMode.mainDriver);
  document.body.style.setProperty('--phasePlan-selectedDriver', theme.colors.driverMode.selectedDriver);
  document.body.style.setProperty('--phasePlan-possibleDriver', theme.colors.driverMode.possibleDriver);

  //Popup
  document.body.style.setProperty('--background-popupHeader', theme.colors.popup.header);
  document.body.style.setProperty('--background-popupContent', theme.colors.popup.content);

  // Switcher theme icon
  document.body.style.setProperty('--background-sun', theme.colors.switcherThemeIcon.sun);
  document.body.style.setProperty('--background-moon', theme.colors.switcherThemeIcon.moon);

  // Select
  document.body.style.setProperty('--background-select', theme.colors.select.background);
  document.body.style.setProperty('--background-select-text', theme.colors.select.color);
  document.body.style.setProperty('--background-select-hover', theme.colors.select.backgroundHover);
  document.body.style.setProperty('--background-select-hover-text', theme.colors.select.hoverText);
  document.body.style.setProperty('--background-select-active', theme.colors.select.backgroundActive);

  // Rest
  document.body.style.setProperty('--background-predecessor-text', theme.colors.rest.predecessorColor);
  document.body.style.setProperty('--background-predecessor', theme.colors.rest.predecessorBackground);

  // Calendar PCD Card

  document.body.style.setProperty('--border-pcd-disabled', theme.colors.calendarPCD.disabledBorder);

  // Tags
  document.body.style.setProperty('--background-tag1', theme.colors.tags.tag1);
  document.body.style.setProperty('--background-tag2', theme.colors.tags.tag2);
  document.body.style.setProperty('--background-tag3', theme.colors.tags.tag3);
  document.body.style.setProperty('--tag-color', theme.colors.tags.color);

  // MultiSelect
  document.body.style.setProperty('--background-multiSelect-text', theme.colors.multiSelect.defaultText);
  document.body.style.setProperty('--background-multiSelect-hover-text', theme.colors.multiSelect.hoveredText);

  // Global search
  document.body.style.setProperty('--background-multiSelectSearch', theme.colors.multiSelectSearch.default);

  // Tooltip
  document.body.style.setProperty('--background-tooltip', theme.colors.tooltip.default);
  document.body.style.setProperty('--background-tooltip-text', theme.colors.tooltip.defaultText);

  // Checkbox
  document.body.style.setProperty('--background-checkbox', theme.colors.checkbox.default);

  //Nav icons
  document.body.style.setProperty('--navIcon-active', theme.colors.navIcons.active);
  document.body.style.setProperty('--navIcon-hover', theme.colors.navIcons.hover);
  document.body.style.setProperty('--navIcon-default', theme.colors.navIcons.default);
  document.body.style.setProperty('--logo-part1', theme.colors.logo.part1);
  document.body.style.setProperty('--logo-part2', theme.colors.logo.part2);
  document.body.style.setProperty('--logo-text', theme.colors.logo.text);

  //Svg icons
  document.body.style.setProperty('--svg-color', theme.colors.svgIcons.color);
  document.body.style.setProperty('--svg-arrowColor', theme.colors.svgIcons.arrowColor);
  document.body.style.setProperty('--svg-removeIcon-background', theme.colors.svgIcons.removeIconBackground);
  document.body.style.setProperty('--svg-removeIcon-color', theme.colors.svgIcons.removeIconColor);

  // DropDown
  document.body.style.setProperty('--select-border', theme.colors.dropDown.default);
  document.body.style.setProperty('--select-border-hover', theme.colors.dropDown.hover);
  document.body.style.setProperty('--select-border-active', theme.colors.dropDown.active);

  // Selected Filter DropDown
  document.body.style.setProperty('--selectedFilterDropDown', theme.colors.selectedFilterDropDown.default);
  document.body.style.setProperty('--selectedFilterDropDown-hover', theme.colors.selectedFilterDropDown.hover);

  //Fonts
  document.body.style.setProperty('--font-general', theme.fonts.general);
  document.body.style.setProperty('--font-menu-size', theme.fonts.menuItemSize);
  document.body.style.setProperty('--font-weight', theme.fonts.weight);

  // Images
  document.body.style.setProperty('--icon-settings', theme.svgImages.settings.default);
  document.body.style.setProperty('--icon-settings-hover', theme.svgImages.settings.hover);
  document.body.style.setProperty('--icon-settings-active', theme.svgImages.settings.active);

  document.body.style.setProperty('--icon-request-navigation', theme.svgImages.requestNavigation.default);
  document.body.style.setProperty('--icon-request-navigation-hover', theme.svgImages.requestNavigation.hover);
  document.body.style.setProperty('--icon-request-navigation-disable', theme.svgImages.requestNavigation.disable);

  // Skeletons
  document.body.style.setProperty('--wb-skeleton-discipline-bg', theme.skeletons.wbDisciplines);
  document.body.style.setProperty('--wb-skeleton-tableCell-bg', theme.skeletons.wbTableCell);
  document.body.style.setProperty('--wb-skeleton-dateCell-bg', theme.skeletons.wbDateCell);

  // TextField
  document.body.style.setProperty('--text-field-label', theme.colors.textField.label);
  document.body.style.setProperty('--text-field-border', theme.colors.textField.border);
  document.body.style.setProperty('--text-field-font', theme.colors.textField.font);
  document.body.style.setProperty('--text-field-error', theme.colors.textField.error);

  // Team avatar
  document.body.style.setProperty('--team-avatar-border', theme.colors.teamAvatar.border);
  document.body.style.setProperty('--team-avatar-font', theme.colors.teamAvatar.font);

  // Request filter
  document.body.style.setProperty('--request-filter', theme.colors.requestFilters.default);
  document.body.style.setProperty('--request-filter-hover', theme.colors.requestFilters.hover);
  document.body.style.setProperty('--request-filter-active', theme.colors.requestFilters.active);
};
