import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import CommentEditor from './parts/CommentEditor/CommentEditor';
import { ButtonView } from '../../controls';
import SVG from 'react-inlinesvg';
import { Popover, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { roles } from '../../../navigation/Router';
import { ICommentsViewProps } from './CommentsView.props';
import { useDispatch } from 'react-redux';
import { RequestModel, UserModel } from '../../../models';
import type { PCDApi } from '../../../service/Api/pcd/types';
import { ICommentData, IFeedData } from '../../../store/comments/commentReducer.model';
import { isCommentHelper } from '../../../store/comments/commentsReducer';
import HistoryItem from '../History/HistoryItem';
import { getIconsTextsColor } from '../History/contants';
import { CommentTools } from './parts/CommentTools/CommentTools';
import { useGetThemeClass } from '../../../helpers/designTokens';
import FilesListViewTokens from '../../controls/FilesListViewTokens/FilesListViewTokens';
import AccordionFeedTab from '../../controls/AccordionFeedTab/AccordionFeedTab';
import { MultiAssignedComment } from './parts/MultiAssigmedComment/MultiAssignedComment';
import ToggleSwitch from '../../controls/ToggleSwitch/ToggleSwitch';
import CheckBox from '../../controls/CheckBox/CheckBox';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import CustomSkeleton from '../../controls/CustomSkeleton/CustomSkeleton';
import { useAppSelector } from '../../../store/configure/configureStore';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { SystemButton } from '../../controls/ButtonComponents';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import { convertToLocalTimezone } from '../../../helpers/commonHelpers';
import AccessHelper from '../../../helpers/AccessHelper';

import './commentStyles.scss';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'var(--font-general)',
    maxWidth: 480,
    color: 'var(--background-tooltip-text)',
    fontSize: '14px',
    padding: '5px 9px',
    backgroundColor: 'var(--background-tooltip)',
    fontWeigh: 'var(--font-weight)',
    position: 'relative',
    right: '5px',
    top: '75px',
    borderRadius: '2px',
    overflow: 'visible',
  },
  popper: {
    opacity: 1,
  },
}))(Tooltip);
const CommentsView: React.FC<ICommentsViewProps> = ({
  // comments,
  feed,
  sorting,
  whatShow,
  type,
  getFeedStatus,
  createCommentStatus,
  handleResetCreateStatus,
  addBallInCourtStatus,
  responseCommentsIds,
  isNavigateToDiscussion,
  handleGoToNavigate,
  deleteCommentStatus,
  closeRemoveComment,
  setDisplayFilter,
  changeSort,
  handleOpenCommentForm,
  handleClearCommentForm,
  isOpenCommentForm,
  closeCommentForm,
  text,
  files,
  handleFile,
  delFile,
  delSavedFile,
  handleChange,
  sendComment,
  handleEditComment,
  handleDeleteComment,
  isEditComment,
  isOpenRemovePopup,
  acceptRemoveComment,
  userInfo,
  isAdmin,
  openPrevFile,
  canComment,
  selectedRequest,
  handleReplyComment,
  isReplyComment,
  updateCommentStatus,
  changeMentions,
  handleProcoreFileLink,
  fileLoading,
  commentFormType,
  sendBallInCourt,
  handleReplyBIC,
  isReplyBic,
  isEditBallInCourt,
  handleEditBallInCourt,
  editBallInCourtStatus,
  responseComment,
  handleUseResponseComment,
  onChangeActiveTab,
  projectParties,
  isDragCard,
  animationBtn,
  handleFiltersFeed,
  activeTab,
  bicIndicatorScrollToCommentId,
  setBicIndicatorScrollToCommentId,
  handleUnAssigmentUser,
  filterItemId,
  setFilterItemId,
  removeIsNewStatus,
  setIsManualFilter,
  isManualFilter,
  isCollapseRequestFields,
  isOpenFilterSelect,
  setIsOpenFilterSelect,
  isImportantFilter,
  setIsImportantFilter,
  bodyContainerRef,
  setIsNavigateFromSandbox,
}): JSX.Element => {
  const dispatch = useDispatch();

  const commentContainer = useRef(null);
  const buttonContainer = useRef(null);
  const targetComment = useRef(null);
  const targetNewReply = useRef(null);

  const themePopupMoreLimitFilesClass = useGetThemeClass('b-comment-popupMoreLimitFiles');

  const { isMobile } = GetResolution();

  const [discussionItems, setDiscussionItems] = useState<IFeedData>([]);
  const [removableComment, setRemovableComment] = useState<any>({});
  const [showNewStatus, setShowNewStatus] = useState<boolean>(false);
  const [showBicStatus, setShowBicStatus] = useState<boolean>(false);
  const [accordionOpenedId, setAccordionOpenedId] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [isNewReply, setIsNewReply] = useState(false);
  const [isFirstRendered, setIsFirstRendered] = useState(true);
  const [coordinates, setCoordinates] = useState<any>(null);
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUnassignSelectOpen, setIsUnassignSelectOpen] = useState<number | null>(null);

  // Comments filters popover mobile
  const [filtersAnchor, setFiltersAnchor] = useState(null);
  const { isFirstFeedLoading, isNavigateFromSandbox } = useAppSelector(state => state.commentReducer);
  const projectId = +useAppSelector(state => state.userReducer.active_project_id);

  const viewProfile = () => {
    setIsViewProfile(true);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const handleCloseUserPopup = e => {
    setCoordinates(null);
  };

  const handleCloseUserPopupFixed = e => {
    setSelectedUser(null);
    setCoordinates(null);
  };

  const handleOpenUserPopup = (e, user) => {
    setCoordinates(e.currentTarget);
    setSelectedUser(user);
  };

  const handleOpenAccordion = index => {
    setAccordionOpenedId(accordionOpenedId.includes(index) ? accordionOpenedId.filter(i => i !== index) : [...accordionOpenedId, index]);
  };

  const handleOpenAllAccordions = () => {
    setAccordionOpenedId(discussionItems.map((item, idx) => idx));
    setIsAllExpanded(true);
  };

  const handleCloseAllAccordions = () => {
    setAccordionOpenedId([]);
    setIsAllExpanded(false);
  };

  const handleOpenFiltersPopover = (e: MouseEvent) => {
    setFiltersAnchor(e.currentTarget);
  };

  const handleCloseFiltersPopover = () => {
    setFiltersAnchor(null);
  };

  useEffect(() => {
    if (discussionItems.length && isFirstRendered) {
      handleOpenAllAccordions();
      setIsFirstRendered(false);
    }
  }, [discussionItems]);

  useEffect(() => {
    if (isNewReply) {
      setTimeout(() => {
        setIsNewReply(false);
      }, 500);
    }
  }, [isNewReply]);

  const nodesComment = [];
  let timer;
  /**
   * For History item
   */

  const offset = new Date().getTimezoneOffset();

  useEffect(() => {
    if (isNavigateToDiscussion || bicIndicatorScrollToCommentId) {
      const scrollNode = nodesComment
        .filter(el => el)
        .find(el => {
          if (bicIndicatorScrollToCommentId) {
            displayBic();
            return bicIndicatorScrollToCommentId === +el.attributes[0].value;
          } else {
            let parentCheck = false;
            if (el.attributes[0]) {
              parentCheck = responseCommentsIds[0] === +el.attributes[0].value;
            }

            if (!parentCheck) {
              const childrenNodes = [...el.children];
              let childrenCheck = false;

              for (let i = 0; i < childrenNodes.length; i++) {
                const currentChild = childrenNodes[i];

                if (i > 0 && responseCommentsIds[0] === +currentChild.attributes[0].value) {
                  return (childrenCheck = true);
                }
              }

              return childrenCheck;
            } else {
              return parentCheck;
            }
          }
        });

      if (scrollNode) {
        setTimeout(() => {
          scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 200);

        if (bicIndicatorScrollToCommentId) {
          setTimeout(() => {
            dispatch(setBicIndicatorScrollToCommentId(null));
          }, 1500);
        } else {
          dispatch(handleGoToNavigate(false));
        }
      }
    }

    if (deleteCommentStatus.loaded) {
      setTimeout(() => {
        closeRemoveComment();
      }, 1000);
    }

    if (feed || sorting || whatShow) {
      // setFilterItemId('all');
      getDiscussionItems();
    }
    if (createCommentStatus.loaded || addBallInCourtStatus.loaded) {
      displayNewStatus();
    }
  }, [
    isNavigateToDiscussion,
    createCommentStatus,
    addBallInCourtStatus,
    deleteCommentStatus,
    feed,
    sorting,
    whatShow,
    bicIndicatorScrollToCommentId,
  ]);

  useEffect(() => {
    //Scroll bottom if activeTab is discussion
    if (!isNavigateToDiscussion && !bicIndicatorScrollToCommentId && discussionItems.length && !isManualFilter) {
      if (localStorage.getItem('resizableCard') === 'small') {
        const scrollContainer = document.querySelector('#scrollableDivRequest');
        scrollContainer?.scrollTo({ left: 0, top: sorting === 'asc' ? scrollContainer.scrollHeight : 0, behavior: 'smooth' });
      } else {
        if (isManualFilter && filterItemId !== 'history') {
          handleFiltersFeed();
          setFilterItemId('');
          commentContainer.current.scrollTo({
            left: 0,
            top: sorting === 'asc' ? commentContainer.current.scrollHeight : 0,
            behavior: 'smooth',
          });
        } else {
          commentContainer.current.scrollTo({
            left: 0,
            top: sorting === 'asc' ? commentContainer.current.scrollHeight : 0,
            behavior: 'smooth',
          });
        }

        return () => {
          setIsManualFilter(false);
        };
      }
    }
  }, [discussionItems.length, sorting]);

  useEffect(() => {
    if (activeTab === 'discussion' && !isManualFilter && !bicIndicatorScrollToCommentId) {
      handleFiltersFeed();
      setFilterItemId('');
      commentContainer.current.scrollTo({
        left: 0,
        top: sorting === 'asc' ? commentContainer.current.scrollHeight : 0,
        behavior: 'smooth',
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (targetNewReply.current) {
      targetNewReply.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [targetNewReply.current]);

  useEffect(() => {
    if (isNavigateFromSandbox.state && discussionItems.length) {
      if (isNavigateFromSandbox.related === 'user') {
        const mentionedCommentId = selectedRequest?.secondary_courts?.find(item => item?.user_id === userInfo?.id)?.comment_id;

        const scrollNode = nodesComment
          .filter(el => el)
          .find(el => {
            return mentionedCommentId === +el.attributes[0].value;
          });

        if (scrollNode) {
          setTimeout(() => {
            scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            dispatch(setIsNavigateFromSandbox({ state: false, related: 'user' }));
          }, 200);
        }
      } else {
        const partyIds = userInfo.parties.map(item => item.id);
        const mentionedCommentIds = selectedRequest?.secondary_courts
          ? selectedRequest.secondary_courts
              .filter(item => partyIds.includes(item.party_id))
              .map(item => item.comment_id)
              .sort((a, b) => (a > b ? -1 : 1))
          : [];
        const mentionedCommentId = mentionedCommentIds.length ? mentionedCommentIds[0] : null;

        const scrollNode = nodesComment
          .filter(el => el)
          .find(el => {
            return mentionedCommentId === +el.attributes[0].value;
          });

        if (scrollNode) {
          setTimeout(() => {
            scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            dispatch(setIsNavigateFromSandbox({ state: false, related: 'user' }));
          }, 200);
        }
      }
    }
  }, [isNavigateFromSandbox.state, discussionItems.length]);

  useEffect(() => {
    if (getFeedStatus.loaded && createCommentStatus.loaded) {
      //Scroll bottom if create new comment

      setTimeout(() => {
        if (isMobile) {
          bodyContainerRef?.current?.scrollTo({
            top: bodyContainerRef?.current?.scrollHeight,
            behavior: 'smooth',
          });
        } else {
          commentContainer.current.scrollTo({
            left: 0,
            top: sorting === 'asc' ? commentContainer.current.scrollHeight : 0,
            behavior: 'smooth',
          });
        }
      }, 500);

      dispatch(handleResetCreateStatus());
    }
  }, [getFeedStatus, createCommentStatus]);

  useEffect(() => {
    //global close editor

    const handledCloseEditForm = e => {
      const composed = e.composedPath();

      if (!composed.some((el: any) => el.dataset?.closeim) && !isOpenCommentForm) {
        handleOpenCommentForm('comment');
        document.body.removeEventListener('click', handledCloseEditForm);
      }
    };

    const handledEscapeClose = e => {
      if (e.key === 'Escape' && isOpenCommentForm) {
        handleOpenCommentForm('comment');

        document.body.removeEventListener('keydown', handledEscapeClose, false);
        e.stopPropagation();
      }
    };

    if (isOpenCommentForm) {
      document.body.addEventListener('click', handledCloseEditForm);
      document.body.addEventListener('keydown', handledEscapeClose, false);
    }

    return () => {
      document.body.removeEventListener('click', handledCloseEditForm);
      document.body.removeEventListener('keydown', handledEscapeClose, false);
    };
  }, [isOpenCommentForm]);

  const getDiscussionItems = () => {
    //TODO ???
    // const items = feed ? feed : [];
    let sortedItems;

    // if (type === 'request') {
    //   if (whatShow === 'all') {
    //     items = comments.comments && comments.ballInCort ? comments.comments.concat(comments.ballInCort) : [];
    //   } else if (whatShow === 'onlyComments') {
    //     items = comments.comments ? comments.comments : [];
    //   } else if (whatShow === 'onlyBallInCourts') {
    //     items = comments.ballInCort ? comments.ballInCort : [];
    //   }
    // } else {
    //   items = comments.comments ? comments.comments : [];
    // }
    // items = feed ? feed : [];

    if (sorting && sorting === 'asc') {
      sortedItems = [...feed].sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at));
    } else if (sorting && sorting === 'desc') {
      sortedItems = [...feed].sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    }

    //   this.setState({
    //     discussionItems: sortedItems,
    //   });
    // setDiscussionItems(feed);
    setDiscussionItems(sortedItems);
  };

  const scrollToTarget = () => {
    setTimeout(() => {
      buttonContainer?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 100);
  };

  const handleSetDisplayFilter = filter => {
    setDisplayFilter(filter);
    scrollToTarget();
  };

  const handleSetSorting = () => {
    changeSort();
  };

  const displayNewStatus = () => {
    if (timer) {
      clearTimeout(timer);
    }

    setShowNewStatus(true);

    timer = setTimeout(async () => {
      setShowNewStatus(false);
    }, 4000);
  };

  const displayBic = () => {
    if (timer) {
      clearTimeout(timer);
    }

    setShowBicStatus(true);

    timer = setTimeout(async () => {
      setShowBicStatus(false);
    }, 2000);
  };

  /**
   * Pre JSX logic
   */

  //   const fullData = comments.comments && comments.ballInCort ? comments.comments.concat(comments.ballInCort) : [];
  const isCommentEditorDisabled =
    createCommentStatus.loading ||
    updateCommentStatus.loading ||
    selectedRequest?.is_deactivated ||
    selectedRequest?.parent?.id ||
    fileLoading ||
    addBallInCourtStatus.loading ||
    editBallInCourtStatus.loading;

  const handleCheckNfStatusUseResponeComment = (nfStatus: string, nfIsDeleted: number, nfIsDeactivated: number): boolean => {
    if ((nfStatus === 'sent' || nfStatus === 'submitted' || nfStatus === 'declined') && !nfIsDeleted && !nfIsDeactivated) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckUserRoleUseResponseComment = (
    selectedRequest: RequestModel | PCDApi.PCDCard,
    userInfo: UserModel,
    rolesConstants: typeof roles,
    comment,
  ) => {
    if (
      Object.keys(userInfo.roles).includes(rolesConstants.ADMIN) ||
      Object.keys(userInfo.roles).includes(rolesConstants.SUPER_ADMIN) ||
      Object.keys(userInfo.roles).includes(rolesConstants.WORKFLOW_MANEGER)
    ) {
      return true;
    } else {
      // COMPANY_MANEGER || USER
      const checkeEqual = nfPartyId => {
        return userInfo.parties.reduce((acc, company) => {
          if (company.id === nfPartyId) {
            acc = true;
          }

          return acc;
        }, false);
      };

      const isResponse = checkeEqual(selectedRequest?.response_party_id);

      if (!isResponse) {
        const isRequest = checkeEqual(selectedRequest?.request_party_id);

        if (isRequest) {
          if (projectParties.parties) {
            const company = Object.values(projectParties.parties)[0].find(company => company.id === selectedRequest?.response_party_id);

            return company?.users.reduce((acc, user) => {
              if (user.id === comment.author.id) {
                // console.log('ok true');
                acc = true;
              }
              return acc;
            }, false);
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const handleUnassignSelectOpen = (commentId?: number) => {
    setIsUnassignSelectOpen(commentId || null);
  };

  const isAccessNfStatus = handleCheckNfStatusUseResponeComment(
    selectedRequest?.status,
    selectedRequest?.is_deleted,
    selectedRequest?.is_deactivated,
  );
  /**
   * Render comments (need types props)
   */
  const generalThemeClass = useGetThemeClass('b-comment');
  const themeClassHeaderText = useGetThemeClass('b-comment__text');
  const themeClassCustomEditor = useGetThemeClass('-customEditor');
  const themeClassTypeIcon = useGetThemeClass('b-comment__typeIcon');
  const themeClassCommentWrapper = useGetThemeClass('b-comment__wrapper');

  const commentRender = (
    comment: ICommentData,
    i: number,
    viewText: string,
    isSubComment: boolean,
    isLastSubComment?: boolean,
  ): JSX.Element => {
    const isAccessUserRole = handleCheckUserRoleUseResponseComment(selectedRequest, userInfo, roles, comment);
    const isCurrentUserInCourts = comment.courts.find(user => user.user_id === userInfo?.id);
    const isCurrentUserAccessToUnassign = AccessHelper.isAccessToUnassignComment(userInfo);

    return (
      <div className={themeClassCommentWrapper}>
        {isSubComment && (
          <div className={cn('b-comment__verticalLine', { ['is_lastSubComment']: isLastSubComment })}>
            <p style={{ opacity: 0, pointerEvents: 'none' }}>.</p>
          </div>
        )}
        <div
          className={`
        b-comment__comment
        ${comment.id === bicIndicatorScrollToCommentId && showBicStatus ? '-showBic' : ''}
        ${
          (isEditComment && isEditComment?.id === comment.id) ||
          (isReplyComment && isReplyComment?.id === comment.id) ||
          (isReplyBic && isReplyBic?.bic_id === comment?.bic_id) ||
          (isEditBallInCourt && isEditBallInCourt?.bic_id === comment?.bic_id)
            ? 'b-comment__noHover'
            : ''
        }
        ${selectedRequest?.is_deactivated || selectedRequest?.parent?.id ? 'b-comment__deactivated' : ''}
        `}
        >
          <div className={'b-comment__header'}>
            <div className={'b-comment__userInfo'}>
              <div className={'b-comment__userInfo__dateContainer'}>
                <span className={`${themeClassHeaderText}`}>{`${convertToLocalTimezone(moment(comment.created_at)).format('ll')}`}</span>
                <span className={`${themeClassHeaderText} b-comment__time`}>{`${convertToLocalTimezone(moment(comment.created_at)).format(
                  'hh:mm A',
                )}`}</span>
              </div>
              <div className={'b-comment__userInfo__userProfile'} onMouseEnter={e => handleOpenUserPopup(e, comment?.author)}>
                <PlaceholderInfo
                  type={'Persona'}
                  firstName={comment?.author?.profile?.first_name}
                  lastName={comment?.author?.profile?.last_name}
                  detailInfo={`${comment?.author?.profile?.first_name} ${comment?.author?.profile?.last_name}`}
                  imagePath={comment?.author?.profile?.image}
                  isMobile={isMobile}
                  size={24}
                />
              </div>
              <div className="b-comment-staticWrapper">
                <div className={`${themeClassTypeIcon} ${comment.type === 'comment' ? '-comment' : '-ballInCourt'}`}>
                  <SVG
                    src={require(!isSubComment
                      ? '../../../assets/icons/dialogCloud-New.svg'
                      : '../../../assets/icons/replyStaticIcon-New.svg')}
                  />
                </div>
                <span className={`${themeClassHeaderText} -addedTextStatic`}>
                  {!isSubComment ? 'Added a comment' : ' Replied to a comment'}
                </span>
              </div>
            </div>
            {comment.courts.length && !comment.deleted_at ? (
              <MultiAssignedComment
                comment={comment}
                courts={comment.courts}
                handleUnAssigmentUser={handleUnAssigmentUser}
                type={type}
                comment_id={comment.id}
                isCurrentUserInCourts={isCurrentUserInCourts}
                isCurrentUserAccessToUnassign={isCurrentUserAccessToUnassign}
                handleUnassignSelectOpen={handleUnassignSelectOpen}
                isUnassignSelectOpen={isUnassignSelectOpen === comment.id}
              />
            ) : null}

            <CommentTools
              comment={comment}
              handleDeleteComment={handleDeleteComment}
              handleEditComment={handleEditComment}
              handleReplyComment={handleReplyComment}
              isAdmin={isAdmin}
              isSubComment={isSubComment}
              selectedRequest={selectedRequest}
              userId={userInfo.id}
              //UseResponseProps
              handleUseResponseComment={handleUseResponseComment}
              isAccessNfStatus={isAccessNfStatus}
              isAccessUserRole={isAccessUserRole}
              isOpenCommentForm={isOpenCommentForm}
              isEditComment={isEditComment}
              isReplyComment={isReplyComment}
              isUnassignSelectOpen={isUnassignSelectOpen === comment.id}
            />
          </div>
          {(isEditComment && isSubComment === !!isEditComment?.isEditParent && isEditComment?.id === comment.id) ||
          (isEditComment && !isSubComment && !isEditComment?.isEditParent && isEditComment?.id === comment.id) ||
          (isEditBallInCourt &&
            comment.type === 'ballInCort' &&
            isEditBallInCourt &&
            isEditBallInCourt?.bic_id === comment.bic_id &&
            canComment) ? (
            <>
              <div className={`b-comment__body ${generalThemeClass}__body`} dangerouslySetInnerHTML={{ __html: viewText }}></div>

              {comment.files && (
                <div className={`b-comment__attachments`}>
                  <FilesListViewTokens
                    files={comment.files}
                    type={'horizontalList'}
                    isEditable={true}
                    source={type === 'request' ? 'R' : type === 'submittal' ? 'Sub' : 'PCD'}
                    subSource={'comment'}
                    disabled={true}
                    delSavedFile={delSavedFile}
                    selectedRequest={selectedRequest}
                    isSubComment={isSubComment}
                    typeSize={'sm'}
                    isAllExpanded={isAllExpanded}
                    showAccordion
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {comment.text && comment.text !== '<p><br></p>' && (
                <div className={`b-comment__body ${generalThemeClass}__body -edit`} dangerouslySetInnerHTML={{ __html: viewText }}></div>
              )}

              {comment.files && (
                <div className={`b-comment__attachments`}>
                  <FilesListViewTokens
                    files={comment.files}
                    type={'horizontalList'}
                    isEditable={true}
                    source={type === 'request' ? 'R' : type === 'submittal' ? 'Sub' : 'PCD'}
                    subSource={'comment'}
                    disabled={true}
                    delSavedFile={delSavedFile}
                    selectedRequest={selectedRequest}
                    isSubComment={isSubComment}
                    typeSize={'sm'}
                    isAllExpanded={isAllExpanded}
                    showAccordion
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const commentRenderEdit = (comment: ICommentData, i: number, viewText: string, isSubComment: boolean): JSX.Element => {
    // console.log('RENDER COMMENT EDITOR', comment);
    // console.log('text in currentValue', text);
    return (
      <>
        {(isEditComment && isSubComment === !!isEditComment?.isEditParent && isEditComment?.id === comment.id) ||
        (isEditComment && !isSubComment && !isEditComment?.isEditParent && isEditComment?.id === comment.id) ||
        (isEditBallInCourt &&
          comment.type === 'ballInCort' &&
          isEditBallInCourt &&
          isEditBallInCourt?.bic_id === comment.bic_id &&
          canComment) ? (
          <CommentEditor
            changeHandler={handleChange}
            currentValue={text}
            files={files}
            handleFile={handleFile}
            delFile={delFile}
            delSavedFile={delSavedFile}
            source="Comment"
            disabled={isCommentEditorDisabled}
            handleSendComment={sendComment}
            handleCloseComment={closeCommentForm}
            text={comment.text || comment.message}
            openPrevFile={openPrevFile}
            changeMentions={changeMentions}
            commentStatus={createCommentStatus.loading || updateCommentStatus.loading}
            fileLoading={fileLoading}
            commentFormType={comment.type}
            sendBallInCourt={sendBallInCourt}
            isEditBallInCourt={isEditBallInCourt}
            isEditComment
            selectedRequest={selectedRequest}
            type={type}
            isSubComment={isSubComment}
            isMobile={isMobile}
            themePopupMoreLimitFilesClass={themePopupMoreLimitFilesClass}
          />
        ) : null}
        {(isReplyComment && isSubComment === !!isReplyComment?.isReplyParent && isReplyComment?.id === comment.id) ||
        (isReplyComment && !isSubComment && !isReplyComment?.isReplyParent && isReplyComment?.id === comment.id) ||
        (isReplyBic && comment.type === 'ballInCort' && comment?.bic_id && isReplyBic?.bic_id === comment?.bic_id) ? (
          // <div className={'b-comment__descriptionContainer'}>
          //   <div className={'editorTypeIcon'}>
          //     <SVG
          //         src={require(comment.type === 'comment' ?
          //             '../../../../../assets/icons/dialogCloud.svg'
          //             :
          //             '../../../../../assets/icons/ball-In-Court.svg')}
          //     />
          //   </div>

          <CommentEditor
            changeHandler={handleChange}
            currentValue={''}
            files={files}
            handleFile={handleFile}
            delFile={delFile}
            delSavedFile={delSavedFile}
            source="Comment"
            disabled={isCommentEditorDisabled}
            handleSendComment={sendComment}
            handleCloseComment={closeCommentForm}
            changeMentions={changeMentions}
            openPrevFile={openPrevFile}
            commentStatus={createCommentStatus.loading || updateCommentStatus.loading}
            fileLoading={fileLoading}
            commentFormType={comment.type}
            sendBallInCourt={sendBallInCourt}
            isReplyBic={isReplyBic}
            isReplyComment
            isMobile={isMobile}
            themePopupMoreLimitFilesClass={themePopupMoreLimitFilesClass}
          />
        ) : null}
      </>
    );
  };

  const getViewText = comment => {
    let viewText = comment.text || comment.message || '';
    if (comment.mentions?.length) {
      comment.mentions.map(m => {
        viewText = viewText.replace(`[~</span>${m.email}]`, `</span>${m.first_name} ${m.last_name}`);
      });
    }
    return viewText;
  };

  const targetCommentIndex = sorting === 'asc' ? 0 : discussionItems.length - 1;
  /**
   * Main return
   */
  const itemsFeedFilter = [
    { id: 'all', title: 'All' },
    { id: 'comments', title: 'Comments' },
    { id: 'history', title: 'History' },
    { id: 'important', title: 'Important' },
  ];

  const itemsExpandMenu = [
    { id: 'collapse', title: 'Collapse All' },
    { id: 'expand', title: 'Expand All' },
  ];

  const themeClassMainContainer = useGetThemeClass('b-comment-mainContainer');
  const themeClassCommentCreate = useGetThemeClass('commentCreate') as 'commentCreate-dark' | 'commentCreate-light';
  const themeClassSubComment = useGetThemeClass('b-comment__subComment');
  const themeClassCommentIndicator = useGetThemeClass('b-comment-commentIndicator');
  const themeClassSelectFilterWrap = useGetThemeClass('selectFilterWrap_feed');
  const themeClassSelectFilterPopover = useGetThemeClass('b-selectFeedFilter-popover');
  const themeClassDivider = useGetThemeClass('b-comment__divider');
  const themeClassSortingArrow = useGetThemeClass('sortingCommentsArrow');
  const themeClassActionsContainer = useGetThemeClass('b-comment-actionsContainer');
  const themeClassDialog = useGetThemeClass('b-comment-dialog');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassFiltersPopoverContent = useGetThemeClass('b-filtersPopoverContent');
  const dialogClass = useGetThemeClass('dialogPopUp');

  return (
    <>
      <div className={cn(`${themeClassMainContainer}`, { ['-mobile']: isMobile })}>
        <div className={cn(themeClassActionsContainer, { ['-mobile']: isMobile })}>
          {isMobile ? (
            <SystemButton
              type={'configuration'}
              size={'md'}
              variant={'transparent'}
              isMobile={isMobile}
              clickHandler={handleOpenFiltersPopover}
            />
          ) : null}
          {!isMobile ? (
            <div className={`${themeClassActionsContainer}__left`}>
              <div className={`${themeClassActionsContainer}__filter`}>
                <span className={`${themeClassActionsContainer}__text`}>Important only</span>
                <ToggleSwitch
                  isSelectedToggle={!isImportantFilter}
                  handleSelect={() => {
                    setFilterItemId(isImportantFilter ? 'all' : 'important');
                    setIsImportantFilter(!isImportantFilter);
                  }}
                  disabled={false}
                  type={'switcher'}
                />
                <span className={`${themeClassActionsContainer}__text`}>All</span>
              </div>
              <div className={`${themeClassActionsContainer}__checkboxes`}>
                <div className={`${themeClassActionsContainer}__filter`}>
                  <CheckBox
                    checked={whatShow === 'comment'}
                    onClick={() => {
                      setFilterItemId(whatShow === 'comment' ? 'all' : 'comment');
                      handleFiltersFeed();
                      setDisplayFilter(whatShow === 'comment' ? '' : 'comment');
                    }}
                  />
                  <span className={`${themeClassActionsContainer}__text`}>Show comments only</span>
                </div>
                <div className={`${themeClassActionsContainer}__filter`}>
                  <CheckBox
                    checked={whatShow === 'history'}
                    onClick={() => {
                      setFilterItemId(whatShow === 'history' ? 'all' : 'history');
                      handleFiltersFeed();
                      setDisplayFilter(whatShow === 'history' ? '' : 'history');
                    }}
                  />
                  <span className={`${themeClassActionsContainer}__text`}>Show history only</span>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={cn(`${themeClassActionsContainer}__right`, {
              ['-hidden']: feed && feed.length <= 1,
            })}
          >
            <CustomButton
              type={'text-plain'}
              size={'sm'}
              clickHandler={handleSetSorting}
              title={sorting === 'asc' ? 'Oldest' : 'Newest'}
              isMobile={isMobile}
              icon={<TokenIcon customClass={`${themeClassSortingArrow} -${sorting}`} iconName={'arrow-down'} size={isMobile ? 20 : 12} />}
            />
            <CustomButton
              type={'text-plain'}
              size={'sm'}
              isMobile={isMobile}
              clickHandler={isAllExpanded ? handleCloseAllAccordions : handleOpenAllAccordions}
              title={isAllExpanded ? 'Collapse all' : 'Expand all'}
            />
          </div>
        </div>

        <div
          className={cn(`b-comment ${generalThemeClass}`, {
            ['declined']: selectedRequest?.lastUserStatus?.status === 'declined',
            ['isCardSideMode']: isDragCard,
            ['isScrollCommentsInView']: commentContainer.current?.scrollHeight > commentContainer.current?.clientHeight,
            ['-mobile']: isMobile,
          })}
          ref={commentContainer}
        >
          <div className={'b-comment__comments'} onClick={e => handleProcoreFileLink(e)}>
            {isFirstFeedLoading ? (
              <div className={`b-comment__skeletonColumn`}>
                <CustomSkeleton width={'100%'} height={24} />
                <CustomSkeleton width={'100%'} height={24} />
                <CustomSkeleton width={'100%'} height={24} />
                <CustomSkeleton width={'100%'} height={24} />
                <CustomSkeleton width={'100%'} height={24} />
              </div>
            ) : (
              <>
                {discussionItems &&
                  discussionItems.map((comment, i) => {
                    if (isCommentHelper(comment)) {
                      const viewText = getViewText(comment);
                      const isNew = comment.sub_comments.find(sub => sub.isNew);

                      if (isNew?.id && !isNewReply) {
                        handleOpenAccordion(i);
                        setIsAllExpanded(false);
                        setIsNewReply(true);
                        dispatch(removeIsNewStatus(isNew.id));
                      }

                      // const isNewComment = (i === targetCommentIndex) && ((new Date().getTime() - new Date(comment.created_at).getTime()) <= 10000);

                      return (
                        <>
                          <div
                            data-id={comment.id}
                            key={`${comment.id || comment.bic_id}`}
                            style={{ width: '100%' }}
                            ref={element => {
                              nodesComment[i] = element;
                              if (i === targetCommentIndex) {
                                return (targetComment.current = element);
                              } else {
                                return null;
                              }
                            }}
                          >
                            <div className={'b-comment__container'}>
                              <div className={themeClassCommentIndicator}>
                                <span>.</span>
                              </div>
                              <div className={'b-comment__content'}>
                                {commentRender(comment, i, viewText, false)}
                                {comment?.sub_comments?.length ? (
                                  <div className={'b-comment__replies'}>
                                    <AccordionFeedTab
                                      accordionOpened={accordionOpenedId}
                                      openAccordion={handleOpenAccordion}
                                      isAllExpanded={isAllExpanded}
                                      count={comment?.sub_comments?.length}
                                      type={'reply'}
                                      index={i}
                                    />
                                    {accordionOpenedId.includes(i) ? (
                                      <div className={`b-comment__subCommentsContainer`}>
                                        {comment.sub_comments.map((subComment, subIndex, subArr) => {
                                          const viewText = getViewText(subComment);
                                          const isLastSubComment = subArr.length - 1 === subIndex;

                                          return (
                                            <div
                                              ref={ref => {
                                                if (isNew) {
                                                  targetNewReply.current = ref;
                                                }
                                              }}
                                              data-id={subComment.id}
                                              key={`${subComment.id}`}
                                              className={themeClassSubComment}
                                            >
                                              {commentRender(subComment, subIndex, viewText, true, isLastSubComment)}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className={themeClassDivider}>
                            <span>.</span>
                          </div>
                        </>
                      );
                    } else {
                      const historyItem = comment;

                      return (
                        <HistoryItem
                          key={historyItem.id}
                          type={type}
                          historyItem={historyItem}
                          offset={offset}
                          iconsTextsColor={getIconsTextsColor(type)}
                        />
                      );
                    }
                  })}
              </>
            )}
          </div>
          <div
            className={`
        ${isDragCard ? 'b-comment__wrapper__wrapper_isDrag' : `b-comment__wrapper`}`}
          >
            <div
              className={`${isDragCard ? 'b-comment__buttonContainer isDrag_flex_direction' : 'b-comment__buttonContainer'}`}
              ref={buttonContainer}
            ></div>
            {type === 'request' ? <></> : null}
          </div>

          <div data-closeim className={'createCommentEditorContainer'}>
            <div className="btns">
              {type === 'request' ? <></> : null}
              <HtmlTooltip
                enterDelay={500}
                leaveDelay={0}
                title={
                  localStorage.getItem('show_tooltips') === 'no'
                    ? ''
                    : !selectedRequest?.is_deactivated || !selectedRequest?.parent?.id
                    ? `Add new comment to this ${type === 'request' ? 'NF' : 'Deliverable'}`
                    : ''
                }
                placement={'top'}
              >
                <div>
                  <ButtonView
                    handleClick={() => handleOpenCommentForm('comment')}
                    type={isOpenCommentForm ? `${themeClassCommentCreate} isOpenEditor` : themeClassCommentCreate}
                    title={''}
                    disabled={
                      (type === 'submittal' ? selectedRequest?.status?.title === 'Deactivated' : selectedRequest?.is_deactivated) ||
                      selectedRequest?.parent?.id
                    }
                    isComment
                    isOpenCommentForm={isOpenCommentForm}
                    isMobile={isMobile}
                  />
                </div>
              </HtmlTooltip>
            </div>
            <div className={cn('b-comment', themeClassCustomEditor)}>
              {(isOpenCommentForm || isReplyComment || isEditComment) && (
                <CommentEditor
                  changeHandler={handleChange}
                  currentValue={'' || text}
                  files={files}
                  handleFile={handleFile}
                  delFile={delFile}
                  delSavedFile={delSavedFile}
                  source="Comment"
                  disabled={isCommentEditorDisabled}
                  handleSendComment={sendComment}
                  handleCloseComment={closeCommentForm}
                  changeMentions={changeMentions}
                  openPrevFile={openPrevFile}
                  commentStatus={createCommentStatus.loading || updateCommentStatus.loading}
                  fileLoading={fileLoading}
                  commentFormType={commentFormType}
                  sendBallInCourt={sendBallInCourt}
                  handleClearCommentForm={handleClearCommentForm}
                  type={type}
                  //props if reply
                  isReplyBic={isReplyBic}
                  isReplyComment={isReplyComment}
                  //props if edit
                  isEditComment={isEditComment}
                  text={isEditComment?.comment?.text || isEditComment?.comment?.message}
                  isEditBallInCourt={isEditBallInCourt}
                  selectedRequest={selectedRequest}
                  isSubComment={isEditComment?.comment?.sub_comments?.length !== 0 ? true : false}
                  //comment.sub_comments
                  getViewText={getViewText}
                  isMobile={isMobile}
                  themePopupMoreLimitFilesClass={themePopupMoreLimitFilesClass}
                />
              )}
            </div>
          </div>
          <Popover
            id={'comments-filters'}
            open={!!filtersAnchor}
            anchorEl={filtersAnchor}
            onClose={handleCloseFiltersPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              paper: `${themeClassCallout} ${themeClassPaper}`,
            }}
          >
            <div className={themeClassFiltersPopoverContent}>
              <div className={`${themeClassFiltersPopoverContent}_row`}>
                <span className={`${themeClassFiltersPopoverContent}_label`}>Important only</span>
                <ToggleSwitch
                  isSelectedToggle={!isImportantFilter}
                  handleSelect={() => {
                    setFilterItemId(isImportantFilter ? 'all' : 'important');
                    setIsImportantFilter(!isImportantFilter);
                  }}
                  disabled={false}
                  type={'switcher'}
                />
                <span className={`${themeClassFiltersPopoverContent}_label`}>All</span>
              </div>
              <div className={`${themeClassFiltersPopoverContent}_row`}>
                <CheckBox
                  checked={whatShow === 'comment'}
                  isMobile
                  onClick={() => {
                    setFilterItemId(whatShow === 'comment' ? 'all' : 'comment');
                    handleFiltersFeed();
                    setDisplayFilter(whatShow === 'comment' ? '' : 'comment');
                  }}
                />
                <span className={`${themeClassFiltersPopoverContent}_label`}>Show comments only</span>
              </div>
              <div className={`${themeClassFiltersPopoverContent}_row`}>
                <CheckBox
                  checked={whatShow === 'history'}
                  isMobile
                  onClick={() => {
                    setFilterItemId(whatShow === 'history' ? 'all' : 'history');
                    handleFiltersFeed();
                    setDisplayFilter(whatShow === 'history' ? '' : 'history');
                  }}
                />
                <span className={`${themeClassFiltersPopoverContent}_label`}>Show history only</span>
              </div>
            </div>
          </Popover>
          <DialogPopUp
            open={isOpenRemovePopup}
            onClose={closeRemoveComment}
            title={'Delete the comment?'}
            hideBackdrop={true}
            maxWidth={'xs'}
            handleOnSecondary={closeRemoveComment}
            secondaryText={'Cancel'}
            handleOnPrimary={acceptRemoveComment}
            primaryText={'Yes, delete'}
            renderModalContent={() => (
              <>
                {removableComment.is_response ? (
                  <span className={`${dialogClass}_content_modalText`}>
                    This comment has been used as <span className={`${themeClassDialog}__marked`}>NF response</span>.{' '}
                    <p>Are you sure you want to delete it?</p>
                    <br />
                    <p>It will no longer be available.</p>
                  </span>
                ) : (
                  <span className={`${dialogClass}_content_modalText`}>It will no longer be available.</span>
                )}
              </>
            )}
          />
          <DialogPopUp
            open={!!Object.keys(responseComment).length}
            onClose={handleUseResponseComment}
            title={'Use comment as NF response?'}
            hideBackdrop={true}
            maxWidth={'xs'}
            handleOnSecondary={handleUseResponseComment}
            secondaryText={'Select more'}
            handleOnPrimary={() => {
              onChangeActiveTab('response');
              handleUseResponseComment(null);
            }}
            primaryText={'Go to Response'}
            renderModalContent={() => (
              <>
                <span className={`${dialogClass}_content_modalText`}>
                  Selected comment and attached files have been copied to the Response section. Please note that you can choose more
                  comments to be used within the response. <br />
                  <p>
                    <br />
                    Go to the Response tab to submit selected comments as{' '}
                    <span className={`${themeClassDialog}__marked`}>
                      NF#{selectedRequest?.nf} {selectedRequest?.version ? `(${selectedRequest?.version})` : ''}
                    </span>{' '}
                    response!
                  </p>
                </span>
              </>
            )}
          />
          {!!selectedUser && !!coordinates && (
            <PopupProfile
              anchorEl={coordinates}
              user={selectedUser}
              viewProfile={viewProfile}
              closeTeamUser={handleCloseUserPopup}
              closeFixedTooltip={handleCloseUserPopupFixed}
            />
          )}
          {!!selectedUser && isViewProfile && (
            <ProfilePopover open={isViewProfile} onClose={viewProfileClose} type={'view'} user_id={selectedUser.id} projectId={projectId} />
          )}
        </div>
      </div>
    </>
  );
};

export default CommentsView;
