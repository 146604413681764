import React, { FC } from 'react';
import { Select } from '../Dropdowns/DropdownСomponents';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { ChipsDiscipline } from '../Chips';
import TokenIcon from '../TokenIcon/TokenIcon';
import cn from 'classnames';
import CustomTooltip from '../Tooltip/Tooltip';
import { useAppSelector } from '../../../store/configure/configureStore';
import { CustomButton } from '../ButtonComponents';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';

import './ImpactSelectStyles.scss';

export interface ImpactModel {
  alias: string;
  title: string;
  status: 1 | 0 | null;
  bulkStatus?: 'Affected' | 'Not affected' | 'Not reviewed' | 'Various';
}

interface IProps {
  type: 'Need Form' | 'Submittal' | 'Need Forms' | 'Submittals';
  items: ImpactModel[];
  onResetAll?: () => void;
  onChange?: (item: ImpactModel, status: 0 | 1 | null) => void;
  loading: boolean;
  isBulkEdit?: boolean;
  onMenuClose?: () => void;
  onlyView?: boolean;
  isHighResolution?: boolean;
}

const ImpactSelect: FC<IProps> = ({ items, type, onResetAll, onChange, loading, isBulkEdit, onlyView, onMenuClose, isHighResolution }) => {
  const themeClass = useGetThemeClass('b-impactSelect');

  const { isMobile } = GetResolution();

  const active_project_id = useAppSelector(state => state.userReducer?.active_project_id);
  const allClImpacts = useAppSelector(state => state.projectReducer.projectInfo?.cl_impacts?.[active_project_id]);

  const allItems: ImpactModel[] =
    allClImpacts &&
    Object.keys(allClImpacts).map(alias => {
      return items.find(item => item.alias === alias) || { alias, title: allClImpacts[alias], status: null };
    });

  const renderLeftSide = (item: ImpactModel) => {
    return (
      <div
        className={cn(`${themeClass}_item_left`, {
          ['-disabled']: item.status === 0,
        })}
      >
        <ChipsDiscipline type={'filled'} color={'gray'} value={item.title} isMobile={isMobile} />
        <span className={`${themeClass}_item_left_title`}>{item.alias.toUpperCase()}</span>
        {item.status === null && !item.bulkStatus && (
          <CustomTooltip title={`Is the system affected by this ${type}?`} placement={'top'}>
            <TokenIcon iconName={'attention'} size={20} customClass={`${themeClass}_item_left_icon`} />
          </CustomTooltip>
        )}
        {item.bulkStatus && (
          <div className={cn(`${themeClass}_item_left_warn`, { ['-warning']: item.bulkStatus === 'Various' })}>
            {item.bulkStatus === 'Various' && (
              <TokenIcon iconName={'not-verified-triangle'} size={12} customClass={`${themeClass}_item_left_icon`} />
            )}
            {item.bulkStatus}
          </div>
        )}
      </div>
    );
  };

  return (
    <Select<ImpactModel, null, true>
      isMulti={true}
      isCustomMulti
      menuItems={allItems || []}
      selected={allItems?.filter(item => item.status)}
      menuItemSize={'md'}
      selectHandler={null}
      headerAdditionalText={`Please select if the systems are affected by this ${type}`}
      isHeader
      isHideSearch
      onMenuClose={onMenuClose}
      isOpenMenu={isBulkEdit && !onlyView}
      isMobile={isMobile}
      basisPlaceholder={`Select systems affected by this ${type}`}
      isLoading={loading}
      onResetAll={onResetAll}
      customSelectClass={themeClass}
      customMenuClass={`${themeClass}_menu`}
      customMenuItemClass={`${themeClass}_item`}
      renderBasisSelectedItem={item => (
        <ChipsDiscipline isMobile={isMobile} type={'filled'} color={'gray'} value={item.alias.toUpperCase()} />
      )}
      isFooter={items.some(item => item.bulkStatus === 'Various')}
      footerType={'warning'}
      footerWarningText={`Affected systems are not the same for all selected ${type}. Changes for selected systems will overwrite them.`}
      menuItemTitleRenderer={() => <></>}
      menuItemLeftElemRenderer={renderLeftSide}
      menuItemRightElemRenderer={item => (
        <div className={`${themeClass}_item_right`}>
          <CustomButton
            type={item.status === 1 ? 'text-activated' : 'text-plain'}
            size={'sm'}
            title={'Yes'}
            clickHandler={() => onChange(item, item.status === 1 ? null : 1)}
            isMobile={isMobile}
          />
          <CustomButton
            type={item.status === 0 ? 'text-activated' : 'text-plain'}
            size={'sm'}
            title={'No'}
            clickHandler={() => onChange(item, item.status === 0 ? null : 0)}
            isMobile={isMobile}
          />
        </div>
      )}
      isHighResolution={isHighResolution}
    />
  );
};

export default ImpactSelect;
