import React, { FC, useMemo } from 'react';
import { DropdownItemModel } from '../../../../../../models/global';
import { Select } from '../../../../../controls/Dropdowns/DropdownСomponents';

import './SelectGroupingChartStyle.scss';

const groupingCategories = [
  { id: 1, value: 1, title: 'Default grouping' },
  { id: 2, value: 2, title: 'By type' },
  { id: 3, value: 3, title: 'By status' },
  { id: 4, value: 4, title: 'By discipline' },
  { id: 5, value: 5, title: 'By level' },
];

const SelectGroupingChart: FC<{
  selectGroup: string;
  handleGroup: (selectGroup: string) => void;
  sizeResolution?: string;
}> = ({ selectGroup, handleGroup, sizeResolution }) => {
  const handleStatusCloseSelect = selectGroup => {
    handleGroup(selectGroup.title);
  };

  const selected = useMemo(() => {
    const findIndex = groupingCategories.findIndex(item => item.title === (selectGroup || 'Default grouping'));
    return groupingCategories[findIndex];
  }, [selectGroup]);

  return (
    <>
      <div className={`grouping_categories_select`}>
        <Select<DropdownItemModel, null, false>
          isMulti={false}
          menuItems={groupingCategories}
          selectHandler={selectGroup => handleStatusCloseSelect(selectGroup)}
          selected={selected}
          menuItemSize={'md'}
          menuItemTitleRenderer={null}
          customBasisFieldClass={sizeResolution === '640' ? 'renderCustomBasis640' : null}
        />
      </div>
    </>
  );
};

export default React.memo(SelectGroupingChart);
