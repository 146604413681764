import React, { Component } from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';

enum WidthSize {
  NameCell = '20%',
  DescriptionCell = '80%',
}

class PlanitModulesHelp extends Component {
  render() {
    return (
      <>
        <div className={'popup-content'}>
          <div className={'popup-content__text'} style={{ margin: '0 0 30px 2px' }}>
            <p>
              Planit Scheduler modules help to create, organize and visualize project-related information. There are several modules
              available in Planit Scheduler, all of them accessible through the toolbar located on the left side of a screen.
            </p>
          </div>
          <Table>
            <TableBody>
              <TableRow className={'popup-content__tableRow'}>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.NameCell }}
                >
                  <div className={'nameCell'}>
                    <Typography children={<span className={'popup-content__text'}>Needs List</span>} />
                  </div>
                </TableCell>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.DescriptionCell }}
                >
                  <Typography
                    children={
                      <span className={'popup-content__text'}>
                        It presents a comprehensive register of all requests and commitments submitted by users in a certain project. Data
                        here is presented in a form of sheet with adjustable columns and search/filtering capabilities. Batch editing is
                        possible in this module.
                      </span>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.NameCell }}
                >
                  <div className={'nameCell'}>
                    <Typography children={<span className={'popup-content__text'}>Sandbox</span>} />
                  </div>
                </TableCell>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.DescriptionCell }}
                >
                  <Typography
                    children={
                      <span className={'popup-content__text'}>
                        This module automatically categorizes NFs by relevance and ownership/assignment and allows for simultaneous browsing
                        and editing of single items.
                      </span>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.NameCell }}
                >
                  <Typography children={<span className={'popup-content__text'}>White Board</span>} />
                </TableCell>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.DescriptionCell }}
                >
                  <Typography
                    children={
                      <span className={'popup-content__text'}>
                        In this module NFs are presented in reference to a calendar-like timeline.
                      </span>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.NameCell }}
                >
                  <Typography children={<span className={'popup-content__text'}>Reporting</span>} />
                </TableCell>
                <TableCell
                  variant="body"
                  align="left"
                  className={'mui-table__cell popup-content__tableCell'}
                  style={{ width: WidthSize.DescriptionCell }}
                >
                  <Typography
                    children={<span className={'popup-content__text'}>It provides a set of performance dashboards and reports.</span>}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
}

export default PlanitModulesHelp;
