import React, { MouseEvent, useMemo } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import './FilterButtonStyles.scss';
import TokenIcon from '../../TokenIcon/TokenIcon';
import CustomButton from '../CustomButton/CustomButton';
import cn from 'classnames';

interface CustomButtonProps {
  isActive?: boolean;
  isPressed: boolean;
  type: 'filter' | 'sort' | 'group';
  clickHandler?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  isMobile?: boolean;
  isHiddenTitle?: boolean;
  customSize?: boolean;
  tooltip?: string;
}

const FilterButton: React.FC<CustomButtonProps> = ({
  isPressed,
  isActive,
  type,
  clickHandler,
  disabled,
  loading,
  isMobile,
  isHiddenTitle,
  customSize = false,
  tooltip = '',
}) => {
  const themeClass = useGetThemeClass('b-filterButton');

  const leftIcon = useMemo(() => {
    const iconClass = cn(`${themeClass}_icon`, { [`-active`]: isActive });
    const size = isMobile ? 20 : 16;

    if (type === 'filter') {
      return <TokenIcon customClass={iconClass} iconName={'filter'} size={size} />;
    }

    if (type === 'sort') {
      return <TokenIcon customClass={iconClass} iconName={'sort'} size={size} />;
    }

    if (type === 'group') {
      return <TokenIcon customClass={iconClass} iconName={'group'} size={size} />;
    }
  }, [themeClass, type, isActive, isMobile]);

  const typeTitle = useMemo(() => {
    if (type === 'filter') {
      return 'Filter';
    }

    if (type === 'sort') {
      return 'Sort';
    }

    if (type === 'group') {
      return 'Group';
    }
  }, [type]);

  return (
    <>
      <CustomButton
        clickHandler={clickHandler}
        type={isActive ? 'filter-activated' : 'filter-plain'}
        size={customSize ? 'customSize' : 'md'}
        title={isHiddenTitle ? '' : typeTitle}
        icon={leftIcon}
        loading={loading}
        disabled={disabled}
        isActive={isPressed}
        isMobile={isMobile}
        isHiddenTitle={isHiddenTitle}
        tooltip={tooltip}
      />
    </>
  );
};

export default FilterButton;
