import React from 'react';

const milestone = (
  <>
    <p style={{ marginBottom: '7px' }}>
      A point on the timeline that represents a set of conditions that must be satisfied to release further work. LOD Milestones are an
      inseparable part of the pull planning process and enable teams to prioritize the most critical tasks and figure out how different
      tasks are connected toward the final goals. Their arrangement can be previewed in the Phase Plan module.
    </p>
    <p>Planit Scheduler workflow comprises the following project LOD Milestones:</p>
    <ul style={{ marginLeft: '30px' }}>
      <li>
        <p>Permit Phase</p>
      </li>
      <li>
        <p>Buy-Out Design Assist/Design Build - corresponds to LOD200</p>
      </li>
      <li>
        <p>Buy-Out (Include Second Tier Subcontractors) - corresponds to LOD 290</p>
      </li>
      <li>
        <p>GMP (Guaranteed Maximum Price) Development - commonly corresponds to LOD 290</p>
      </li>
      <li>
        <p>Coordination - corresponds to LOD 300</p>
      </li>
      <li>
        <p>Fabrication/Material Procurement - corresponds to LOD 325</p>
      </li>
      <li>
        <p>Construction Start Ready - corresponds to LOD 350</p>
      </li>
    </ul>
  </>
);

const requestType = (
  <>
    <p style={{ marginBottom: '7px' }}>
      There is a list of predefined types of request that you can choose from while working with an NF. It expresses the main intent of NFs.
    </p>
    <ul style={{ marginLeft: '30px' }}>
      <li>
        <p style={{ marginBottom: '5px' }}>
          RFI (Request For Information) - a type of request generated by any team member to specify, clarify or request design information.
        </p>
      </li>
      <li>
        <p style={{ marginBottom: '5px' }}>
          Private RFI - an RFI with enhanced visibility settings. It is presented in the Needs List and Sandbox of only Request and Response
          companies.
        </p>
      </li>
      <li>
        <p style={{ marginBottom: '5px' }}>
          CL Drawing (Coordinated Layout Drawing) - a type used for requesting a sign-off from multiple parties. It is intended for any
          documents or deliverables to be reviewed and signed off on. This request type is used for CL Drawings, Pre-Pour Check Drawings and
          other fully coordinated documents.
        </p>
      </li>
      <li>
        <p style={{ marginBottom: '5px' }}>
          Design Constraint - a type of request generated by any team member in the event of missing or unclear design information having an
          impact on other system’s design. The vast majority of Design Constraints are commonly generated by the Planit team as a result of
          the LOD290 Review process.
        </p>
      </li>
      <li>
        <p style={{ marginBottom: '5px' }}>
          CL Input Checklist - a type of request used for fabrication details and product cut sheets required to coordinate the layout.
        </p>
      </li>
      <li>
        <p style={{ marginBottom: '5px' }}>
          Standard Request - a type of request for the general needs of project team members in the design pull planning process. If none of
          the types listed above meets your requirements, use this type instead.
        </p>
      </li>
    </ul>
  </>
);

const glossaryContents = [
  {
    title: 'A',
    id: 0,
    isClosed: false,
    headers: [],
  },
  {
    title: 'B',
    id: 1,
    isClosed: false,
    headers: [],
  },
  {
    title: 'C',
    id: 2,
    isClosed: false,
    headers: [
      {
        title: 'Commitment Author',
        text: 'A response discipline member who submitted the commitment.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'Commitment',
        text: 'A response company input describing the commitment. It can be a commitment description itself, since it is not mandatory to attach files when submitting a commitment.',
        id: 1,
        isClosed: true,
      },
      {
        title: 'Comments',
        text: 'A supplementary section of an NF card that is used for discussion between request and response parties when commitment can not be submitted or submitted without additional clarification.',
        id: 2,
        isClosed: true,
      },
    ],
  },
  {
    title: 'D',
    id: 3,
    isClosed: false,
    headers: [
      {
        title: 'Response',
        text: 'A definite input made by a response company that fully clarifies the request. It can be a final description itself, since it is not mandatory to attach any files.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'E',
    id: 4,
    isClosed: false,
    headers: [],
  },
  {
    title: 'F',
    id: 5,
    isClosed: false,
    headers: [],
  },
  {
    title: 'G',
    id: 6,
    isClosed: false,
    headers: [
      {
        title: 'GMP (Guaranteed Maximum Price)',
        text: 'A standard project management tool for keeping costs low. In the construction industry, GMP refers to the highest amount of labor, materials and profit costs the contractor can charge the customer. GMP contracts work best for projects with detailed specifications and scope of work. It can be mutually beneficial for both parties.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'GMP Line',
        text: 'The specific point in time, when the Guaranteed Maximum Price should be established. It is represented by the thick orange vertical line in the Phase Plan module.',
        id: 1,
        isClosed: true,
      },
    ],
  },
  {
    title: 'H',
    id: 7,
    isClosed: false,
    headers: [
      {
        title: 'Hashtag ',
        text: 'Since the predefined request types list may be insufficient, you can supply your NFs with additional information by using hashtags. This allows users to quickly add keywords, as well as group and filter items by them in the Needs List.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'History',
        text: 'A section of an NF card that keeps track of all the actions that occurred on both Request or Commitment Cards.',
        id: 1,
        isClosed: true,
      },
    ],
  },
  {
    title: 'I',
    id: 8,
    isClosed: false,
    headers: [
      {
        title: 'Incorporation',
        text: 'Section of NF where information about progress of incorporation can be reported.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'J',
    id: 9,
    isClosed: false,
    headers: [],
  },
  {
    title: 'K',
    id: 10,
    isClosed: false,
    headers: [],
  },
  {
    title: 'L',
    id: 11,
    isClosed: false,
    headers: [
      {
        title: 'LOD Milestone',
        text: milestone,
        id: 0,
        isClosed: true,
      },
      {
        title: 'LOD (Level Of Development)',
        text: 'The level of completeness/reliability to which a model element (a portion of the Building Information Model representing a component, system or assembly within a building) is developed.',
        id: 1,
        isClosed: true,
      },
    ],
  },
  {
    title: 'M',
    id: 12,
    isClosed: false,
    headers: [],
  },
  {
    title: 'N',
    id: 13,
    isClosed: false,
    headers: [
      {
        title: 'Needs List',
        text: 'Presents a comprehensive register of all requests and commitments submitted by users in a form of adjustable spreadsheet.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'NF (Card)',
        text: '(Need Form) an information request with a unique number that remains unchanged through the whole project timeline. The NF user interface consists of a Request card and a Commitment card. NFs were created to enable clear and organized information flow between all parties included in the design and construction process.',
        id: 1,
        isClosed: true,
      },
      {
        title: 'NF Author',
        text: 'A user that created an NF Request',
        id: 2,
        isClosed: true,
      },
      {
        title: 'NF owner',
        text: 'Any person or organization that has a right to edit this NF Request information.',
        id: 3,
        isClosed: true,
      },
      {
        title: 'NF recipient',
        text: 'Any person or organization that has a right to fill in Commitment/Response information of this NF.',
        id: 4,
        isClosed: true,
      },
    ],
  },
  {
    title: 'O',
    id: 14,
    isClosed: false,
    headers: [],
  },
  {
    title: 'P',
    id: 15,
    isClosed: false,
    headers: [
      {
        title: 'Pull Request',
        text: 'An information request created in cases when providing a response to a certain NF requires having another request responded beforehand. In order to create a Pull Request, you need to submit an initial commitment first.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'Q',
    id: 16,
    isClosed: false,
    headers: [],
  },
  {
    title: 'R',
    id: 17,
    isClosed: false,
    headers: [
      {
        title: 'Request (Card), Commitment (Card)',
        text: 'User interface elements with predefined data fields containing a need of a Request Company as well as the willingness of a Response Company to provide it within a certain deadline - NF Due Date. Can be accessed from Needs List, Whiteboard or Sandbox modules.',
        id: 0,
        isClosed: true,
      },
      {
        title: 'Reporting',
        text: 'Scheduler module which provides a set of performance dashboards and reports.',
        id: 1,
        isClosed: true,
      },
      {
        title: 'Request type',
        text: requestType,
        id: 2,
        isClosed: true,
      },
      {
        title: 'Request Author',
        text: 'A request company member who created a request.',
        id: 3,
        isClosed: true,
      },
      {
        title: 'Request Company',
        text: 'A company whose members have created the request.',
        id: 4,
        isClosed: true,
      },
      {
        title: 'Response Company',
        text: 'A response discipline company whose members are supposed to submit a commitment to a certain request. Can be modified by the Request Manager until the response is in the ‘accepted’ status.',
        id: 5,
        isClosed: true,
      },
      {
        title: 'Request Discipline',
        text: 'A project discipline whose company has created the NF.',
        id: 6,
        isClosed: true,
      },
      {
        title: 'Response Discipline',
        text: 'A project team discipline whose companies are supposed to submit a commitment to a certain request. Can be modified by the Request Manager until the response is in the ‘accepted’ status.',
        id: 7,
        isClosed: true,
      },
      {
        title: 'Request Manager',
        text: 'A request company member who has been assigned by the request author to track a certain request.',
        id: 8,
        isClosed: true,
      },
      {
        title: 'Response Manager',
        text: 'A response company member assigned to be responsible for committing a particular type of request. Can be modified by the Request Manager until the response is in the ‘accepted’ status.',
        id: 9,
        isClosed: true,
      },
    ],
  },
  {
    title: 'S',
    id: 18,
    isClosed: false,
    headers: [
      {
        title: 'Sandbox',
        text: 'A primary user interface to control NFs categorized according to relevance. It allows simultaneous browsing and editing of NFs',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'T',
    id: 19,
    isClosed: false,
    headers: [],
  },
  {
    title: 'U',
    id: 20,
    isClosed: false,
    headers: [
      {
        title: 'UF (UniFormat), MF (MasterFormat)',
        text: 'Standardized classification codes assigned to an NF in a searchable drop-down list. Not mandatory to send and submit a request.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'V',
    id: 21,
    isClosed: false,
    headers: [],
  },
  {
    title: 'W',
    id: 22,
    isClosed: false,
    headers: [
      {
        title: 'Whiteboard',
        text: 'Scheduler module which locates requests and commitments on a timeline, grouped by a discipline.',
        id: 0,
        isClosed: true,
      },
    ],
  },
  {
    title: 'X',
    id: 23,
    isClosed: false,
    headers: [],
  },
  {
    title: 'Y',
    id: 24,
    isClosed: false,
    headers: [],
  },
  {
    title: 'Z',
    id: 25,
    isClosed: false,
    headers: [],
  },
];

export default glossaryContents;
