import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { PCDApi } from '../../../../../service/Api/pcd/types';

interface RadioGroupItem {
  value: string | number;
  title: string;
}

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface PcdRenderSelectProps<T, G, IsMultiValue extends boolean> {
  type: 'discipline' | 'company' | 'person' | 'building' | 'levels' | 'building spaces' | 'design input' | 'deliverables';
  isMulti: IsMultiValue;
  isValuesRemovable?: boolean;
  isHeader?: boolean;
  isFooter?: boolean;
  customSelectClass?: string;
  menuItems: T[];
  groups?: G[];
  selectHandler: (selected: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  customBasisContainerClass?: string;
  customBasisFieldClass?: string;
  basisPlaceholder: string;
  renderBasisSelectedItem?: (selected: T) => ReactElement | ReactNode;
  renderCustomBasis?: (isActive: boolean, disabled: boolean) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  onChangeSearch?: (search: string) => void;
  customHeaderClass?: string;
  headerAdditionalText?: string;
  footerType?: 'divided-button' | 'radio-group';
  customFooterClass?: string;
  footerButtonClick?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  footerButtonTitle?: string | ReactElement;
  footerRadioGroup?: RadioGroupItem[];
  footerRadioClickHandler?: (value: string | number) => void;
  footerSelectedRadioValue?: string | number;
  menuItemSize: 'sm' | 'md';
  checkMenuItemDisabled?: (item: T) => boolean;
  disabled?: boolean;
  menuItemTitleRenderer?: (item: T) => string | ReactNode;
  menuItemTooltipRenderer?: (item: T) => string | ReactNode;
  menuItemLeftElemRenderer?: (item: T) => ReactNode;
  menuItemLeftElemClick?: (item: T) => void;
  menuItemRightElemRenderer?: (item: T) => ReactNode;
  menuItemRightElemClick?: (item: T) => void;
  menuItemRightTextRenderer?: (item: T) => ReactNode;
  customMenuItemClass?: string;
  customMenuClass?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onMenuClose?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  isOpenMenu?: boolean;
  customOpening?: boolean;
  handleBuildingItemClick?: (building: PCDApi.PCDListBuildingInterface) => void;
  handleDrawingItemClick?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  handleDeliverablesItemClick?: (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => void;
  openedBuildingIds?: number[];
  openedDrawingIds?: number[];
  handleOpenLinkPopover: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface[]) => void;
  isFullWidth?: boolean;
  handleSelectedDrawingDelete?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  getBuildingsTitle?: (building_ids: number[]) => string;
  handleOpenDDManageStatuses?: () => void;
  handleOpenDDItem?: (item: DropdownItemModel) => void;
  handleRemoveDDItem?: (item: DropdownItemModel) => void;
  isAccess?: boolean;
  isAccessToRemove?: boolean;
  disableSelectAll?: boolean;
  handleDrawingCheckBoxClick?: (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  isMobile: boolean;
}

function PcdRenderSelect<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  type,
  isMulti,
  isValuesRemovable,
  isHeader,
  isFooter,
  disableSelectAll,
  customSelectClass,
  menuItems,
  groups,
  selectHandler,
  selected,
  isLoading,
  customBasisContainerClass,
  customBasisFieldClass,
  basisPlaceholder,
  renderBasisSelectedItem,
  renderCustomBasis,
  basisMaxItems,
  error,
  basisTooltip,
  onChangeSearch,
  customHeaderClass,
  headerAdditionalText,
  footerType,
  customFooterClass,
  footerButtonClick,
  footerButtonTitle,
  footerRadioGroup,
  footerRadioClickHandler,
  footerSelectedRadioValue,
  menuItemSize,
  checkMenuItemDisabled,
  disabled,
  menuItemTitleRenderer,
  menuItemTooltipRenderer,
  menuItemLeftElemRenderer,
  menuItemLeftElemClick,
  menuItemRightElemRenderer,
  menuItemRightElemClick,
  menuItemRightTextRenderer,
  customMenuItemClass,
  customMenuClass,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isOpenMenu,
  customOpening,
  handleBuildingItemClick,
  handleDrawingItemClick,
  handleDeliverablesItemClick,
  openedBuildingIds,
  openedDrawingIds,
  handleOpenLinkPopover,
  isFullWidth,
  handleSelectedDrawingDelete,
  getBuildingsTitle,
  handleOpenDDManageStatuses,
  handleOpenDDItem,
  handleRemoveDDItem,
  isAccess,
  isAccessToRemove,
  handleDrawingCheckBoxClick,
  isMobile,
}: PcdRenderSelectProps<T, G, IsMultiValue>) {
  const themeClass = useGetThemeClass('b-pcdRenderSelect');

  const renderSelectItem = (item: DropdownItemModel) => {
    if (type === 'discipline') {
      return (
        <>
          <div className={`${themeClass}_text`}>{item.title}</div>
        </>
      );
    }

    return item.title;
  };

  const renderSelectBasisItem = (item: DropdownItemModel) => {
    if (type === 'discipline') {
      return (
        <>
          <div className={`${themeClass}_text`}>{item.title}</div>
        </>
      );
    }

    return item.title;
  };

  return (
    <>
      <Select<T, G, IsMultiValue>
        isMulti={isMulti}
        isHeader={isHeader}
        isFooter={isFooter}
        disableSelectAll={disableSelectAll}
        menuItems={menuItems}
        groups={groups}
        menuGroupingType={menuGroupingType}
        selectHandler={selectHandler}
        selected={selected}
        headerAdditionalText={headerAdditionalText}
        isLoading={isLoading}
        basisPlaceholder={basisPlaceholder}
        renderBasisSelectedItem={renderSelectBasisItem}
        footerButtonClick={footerButtonClick}
        footerButtonTitle={footerButtonTitle}
        footerType={footerType}
        menuItemSize={menuItemSize}
        menuItemTitleRenderer={renderSelectItem}
        customBasisFieldClass={customBasisFieldClass}
        error={error}
        onChangeSearch={onChangeSearch}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isOpenMenu={isOpenMenu}
        disabled={disabled}
        customOpening={customOpening}
        isFullWidth={isFullWidth}
        menuItemLeftElemRenderer={menuItemLeftElemRenderer}
        menuItemRightTextRenderer={menuItemRightTextRenderer}
        customMenuItemClass={customMenuItemClass}
        customMenuClass={customMenuClass}
        handleOpenDDManageStatuses={handleOpenDDManageStatuses}
        basisMaxItems={basisMaxItems}
        basisTooltip={basisTooltip}
        isMobile={isMobile}
      />
    </>
  );
}

export default PcdRenderSelect;
