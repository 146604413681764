import React, { FC, useEffect, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';
import Popover from '@material-ui/core/Popover';
import { NLMfModel } from '../../../../store/needlist/needListModels';
import CustomTooltip from '../../Tooltip/Tooltip';
import ReactResizeDetector from 'react-resize-detector';

import './ShowMoreCodesStyles.scss';

interface IProps {
  items: NLMfModel[];
  isThumbnailView: boolean;
}

const ShowMoreCodes: FC<IProps> = ({ items, isThumbnailView }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isNotContain, setIsNotContain] = useState(false);

  const themeClass = useGetThemeClass('showMoreCodes');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const containerRef = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    if (!isThumbnailView && items?.length > 1) {
      setIsNotContain(true);
    } else {
      if (containerRef.current) {
        const blockHeight = containerRef.current.offsetHeight;
        const elementHeight = elementRef.current.offsetHeight;

        if (blockHeight < elementHeight * items?.length - 16) {
          setIsNotContain(true);
        }
      }
    }
  }, []);

  const changeView = () => {
    setIsShowMore(!isShowMore);
  };

  if (!items.length) {
    return null;
  }

  const handleResize = (width: number, height: number) => {
    let elementsWidth = 0;
    containerRef.current.querySelectorAll('.Badge').forEach(el => {
      elementsWidth += el.getBoundingClientRect().width;
    });
    if (width - 16 > elementsWidth + 13) {
      // 16px - padding; 13px - show more button
      setIsNotContain(false);
    } else {
      setIsNotContain(true);
    }
  };

  const renderItems = [...items];

  if (isNotContain && isThumbnailView) {
    renderItems.splice(1);
  }

  return (
    <>
      <ReactResizeDetector targetRef={containerRef} handleWidth={true} refreshMode={'throttle'} onResize={handleResize} />
      <div className={cn(`${themeClass}_container`, { '-isThumbnailView': isThumbnailView })} ref={containerRef}>
        <div className={`${themeClass}_container_codesBlock`}>
          {renderItems.map((item, index) => {
            const isLast = index + 1 === renderItems.length;
            return (
              <div className={`Badge`} key={`pCode-${item.id}`}>
                <CustomTooltip placement={'top'} title={`${item.code} - ${item.title}`}>
                  <span className={`${themeClass}_container_codesBlock_text`} ref={elementRef}>
                    {item.code}
                    {isLast ? '' : ','}
                  </span>
                </CustomTooltip>
              </div>
            );
          })}
        </div>
        {isNotContain && (
          <span onClick={changeView} className={`${themeClass}_container_showMoreLink`}>
            ...
          </span>
        )}
      </div>
      <Popover
        open={!!isShowMore}
        anchorEl={containerRef.current}
        onClose={changeView}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={`${themeClass}_popUp`}>
          {items.map(item => {
            return (
              <CustomTooltip placement={'top'} key={`pCode-${item.id}`} title={`${item.code} - ${item.title}`}>
                <div className={`${themeClass}_popUp_text`}> {item.code}</div>
              </CustomTooltip>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default ShowMoreCodes;
