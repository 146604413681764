import React, { FC } from 'react';
import Step5View from './Step5View';

interface IProps {
  step: number;
  redirectToStep: (step: number) => void;
}

const Step5Container: FC<IProps> = () => {
  return <Step5View />;
};
export default Step5Container;
