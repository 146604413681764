import React, { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { useClickOutside } from '../../../../../helpers/commonHooks';
import glossaryContents from '../helpContents/GlossaryContents';

const GlossarySearch = ({ scrollToHeader }) => {
  const initialState = {
    anchorEl: null,
    popOverVisible: false,
    selectedIds: [],
    searchVal: '',
    requests: null,
    isLinkingLoading: false,
    openRequestId: null,
  };

  const [state, setState] = useState(initialState);

  const onChangeInput = ({ currentTarget }) => {
    let popOverVisible = false;
    let anchorEl = null;
    if (currentTarget.value) {
      popOverVisible = true;
      anchorEl = currentTarget;
    }
    setState({ ...state, anchorEl, popOverVisible, searchVal: currentTarget.value });
  };

  const closePopOver = () => {
    setState({
      ...state,
      popOverVisible: false,
      anchorEl: null,
      searchVal: '',
    });
  };

  const handleSelect = (mainHeadId, subHeadId) => {
    scrollToHeader(mainHeadId, subHeadId);
  };

  const compRef = useRef();
  useClickOutside(compRef, closePopOver);

  const renderPopper = () => {
    if (state.searchVal.length) {
      const letter = glossaryContents.find(item => item.title?.toLowerCase() === state.searchVal.trim()[0]?.toLowerCase());
      const termins = letter?.headers?.filter(item => `${item.title}`?.toLowerCase().search(state.searchVal?.toLowerCase()) !== -1) || [];
      if (termins.length && termins.length > 0) {
        return (
          <Popper
            id={`transitions-popper=glossarySearch`}
            style={{ zIndex: 1000, borderRadius: '2px', position: 'absolute' }}
            anchorEl={state.anchorEl}
            open={state.popOverVisible}
            placement="top-start"
            disablePortal={true}
            modifiers={{
              flip: { enabled: true },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
          >
            <Paper>
              <div className={'glossarySearch__popover'}>
                <div className={'glossarySearch__popoverContent'}></div>
                {termins.map(termin => {
                  return (
                    <div key={termin.id} className={'glossarySearch__popoverItem'} onClick={() => scrollToHeader(letter.id, termin.id)}>
                      <span>{termin.title}</span>
                    </div>
                  );
                })}
              </div>
            </Paper>
          </Popper>
        );
      }
    }
  };

  const renderC = () => {
    return (
      <div ref={compRef} style={{ marginLeft: '20px' }}>
        <div className={'glossarySearch__searchInputContainer'}>
          <input
            disabled={false}
            value={state.searchVal}
            onChange={onChangeInput}
            className={`glossarySearch__searchInput`}
            type="text"
            placeholder={'Search'}
            aria-describedby={`transitions-popper=glossarySearch`}
          />
          <SVG src={require('../../../../../assets/icons/search.svg')} />
        </div>
        {renderPopper()}
      </div>
    );
  };

  return renderC();
};

export default GlossarySearch;
