import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { CustomButton } from '../../../../controls/ButtonComponents';
import { Popover } from '@material-ui/core';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import Divider from '../../../../controls/Divider/Divider';
import DateField from '../../../../controls/DateField/DateField';
import moment from 'moment';
import RedmineMilestone from '../../../../controls/RedmineMilestones/RedmineMilestone';
import { getRedmineAssignedMilestone, getRedmineProjects } from '../../../../../store/redmine/redmineThunk';
import { redmineSlice } from '../../../../../store/redmine/redmineSlice';
import { withSnackbar } from 'notistack';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';

import './AllDatesStyles.scss';

const { removeAssignedMilestone, clearMilestoneError } = redmineSlice.actions;

interface IProps {
  criticalDateValue: string | Date;
  scheduleDateValue: string | Date;
  selectedRequest: PCDCard;
  errors: unknown;
  enqueueSnackbar: any;
}

const AllDates: React.FC<IProps> = ({ criticalDateValue, errors, scheduleDateValue, selectedRequest, enqueueSnackbar }) => {
  const themeClass = useGetThemeClass('b-allDatesContent');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const project = useAppSelector(state => state.phasePlan.projectData);
  const redmineData = useAppSelector(state => state.redmineReducer);
  const pcdInfo = useAppSelector(state => state.pcdReducer.pcdInfo);
  const pcdID = useAppSelector(state => state.pcdReducer.lastPcdCardId);
  const cardLoading = useAppSelector(state => state.pcdReducer.pcdInfoLoading);

  const [datesPopoverAnchor, setDatePopoverAnchor] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!userInfo.redmine_key) return;
    if (!project?.redmine || project?.redmine?.length === 0) return;
    const args = { rmApiKey: userInfo.redmine_key, projectList: project?.redmine };
    dispatch(getRedmineProjects(args));
  }, [userInfo.redmine_key, project?.redmine]);

  useEffect(() => {
    dispatch(removeAssignedMilestone());
    if (!pcdInfo?.[pcdID]?.redmine.length || !userInfo.redmine_key) return;
    dispatch(getRedmineAssignedMilestone({ milestoneId: pcdInfo?.[pcdID]?.redmine?.[0], rmApiKey: userInfo.redmine_key }));
  }, [pcdID, pcdInfo, userInfo.redmine_key]);

  useEffect(() => {
    if (redmineData.milestoneError) {
      enqueueSnackbar(`Redmine milestone: ${redmineData.milestoneError.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      dispatch(clearMilestoneError());
    }
  }, [redmineData.milestoneError]);

  const openPopover = e => {
    setDatePopoverAnchor(e.currentTarget);
  };

  const closePopover = () => {
    setDatePopoverAnchor(null);
  };

  return (
    <>
      <CustomButton type={'text-plain'} size={'md'} title={'Show all dates'} clickHandler={openPopover} loading={cardLoading} />
      <Popover
        open={!!datesPopoverAnchor}
        onClose={closePopover}
        anchorEl={datesPopoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div className={themeClass}>
          <div className={`${themeClass}__title`}>
            <div>Construction schedule driver</div>
            <CustomTooltip
              title={
                <div>
                  Construction activity driving <br />
                  the deliverable issuance schedule
                </div>
              }
              customBasisClass={`${themeClass}__tooltip`}
            >
              <TokenIcon iconName={'information'} size={16} customClass={`${themeClass}__info-icon`} />
            </CustomTooltip>
          </div>
          <div className={`${themeClass}__date-container`}>
            <div className={`${themeClass}__date-title`}>
              {selectedRequest?.construction_driver?.title ? selectedRequest.construction_driver.title : 'Construction driver'}
            </div>
            <DateField
              selectedDate={
                selectedRequest?.construction_driver?.date
                  ? moment(selectedRequest.construction_driver.date).format('MM/DD/YYYY')
                  : 'mm/dd/yyyy'
              }
              isSentRequestToChange={false}
              isOpenCalendar={false}
              isDiffDueScheduledDate={false}
              info={false}
              disabled={true}
              isBefore={false}
              valueIsDate={true}
              isDisableBorder
              isHideCalendar
            />
          </div>
          <Divider direction={'horizontal'} type={'srf-5'} customClassname={`${themeClass}_divider`} />
          <div className={`${themeClass}__dates-container`}>
            {userInfo?.is_general && (
              <div className={`${themeClass}__date-container`}>
                <div className={`${themeClass}__date-title`}>
                  <div>Critical Path date</div>
                  <CustomTooltip
                    title={
                      <div>
                        Final deadline for issuing the deliverable <br />
                        without disrupting the project schedule
                      </div>
                    }
                    customBasisClass={`${themeClass}__tooltip`}
                  >
                    <TokenIcon iconName={'information'} size={16} customClass={`${themeClass}__info-icon`} />
                  </CustomTooltip>
                </div>
                <div className={`${themeClass}__prior-and-date`}>
                  <div className={`${themeClass}__priorDays`}>{selectedRequest?.critical_days} days prior</div>
                  <DateField
                    selectedDate={criticalDateValue ? moment(criticalDateValue).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                    isSentRequestToChange={false}
                    isOpenCalendar={false}
                    isDiffDueScheduledDate={false}
                    info={false}
                    disabled={true}
                    isBefore={false}
                    error={errors.critical_date}
                    valueIsDate={true}
                    isDisableBorder
                    isHideCalendar
                  />
                </div>
              </div>
            )}
            <div className={`${themeClass}__date-container`}>
              <div className={`${themeClass}__date-title`}>
                <div>Scheduled date</div>
                <CustomTooltip title={<div>Planned issuance date of the deliverable</div>} customBasisClass={`${themeClass}__tooltip`}>
                  <TokenIcon iconName={'information'} size={16} customClass={`${themeClass}__info-icon`} />
                </CustomTooltip>
              </div>
              <div className={`${themeClass}__prior-and-date`}>
                <div className={`${themeClass}__priorDays`}>{selectedRequest?.scheduled_days} days prior</div>
                <DateField
                  selectedDate={scheduleDateValue ? moment(scheduleDateValue).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                  isSentRequestToChange={false}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={true}
                  isBefore={false}
                  error={errors.scheduled_date}
                  valueIsDate={true}
                  isDisableBorder
                  isHideCalendar
                />
              </div>
            </div>
          </div>
          {userInfo?.qc_user && (
            <>
              <Divider direction={'horizontal'} type={'srf-5'} customClassname={`${themeClass}_divider`} />
              {userInfo?.redmine_key && project?.redmine?.length && redmineData.projectsLoaded ? (
                <RedmineMilestone />
              ) : (
                <div className={`${themeClass}__date-title`}>Check Redmine connection data</div>
              )}
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default withSnackbar(AllDates);
