import React, { useMemo } from 'react';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { SubmitButtonActions } from '../../constans/constants';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;

interface IProps {
  selectedRequest: PCDCard;
  animationBtn: string | null;
  isCreateSubmitButtonDisabled: boolean;
  isRevisionUpdateDraftButtonDisabled: boolean;
  handlePCDCardData: (nameBtn: SubmitButtonActions) => void;
  isRevisionSubmitted: boolean;
}

const DeliverableButtons = ({
  selectedRequest,
  animationBtn,
  isCreateSubmitButtonDisabled,
  isRevisionUpdateDraftButtonDisabled,
  handlePCDCardData,
  isRevisionSubmitted,
}: IProps) => {
  const themeClass = useGetThemeClass('b-cardDeliverable-deliverableTab');
  const pcdInfoLoading = useAppSelector<boolean>(state => state.pcdReducer.pcdInfoLoading);

  const { isMobile } = GetResolution();

  const submitCreateTooltipText = useMemo(() => {
    const isLastRevisionSubmitted = selectedRequest && selectedRequest.revisions.at(-1)?.is_whiteboard;

    if (selectedRequest && !selectedRequest.critical_date) {
      return 'To submit a deliverable it must be scheduled in project settings menu.';
    }

    if (selectedRequest && selectedRequest.is_whiteboard && !isLastRevisionSubmitted) {
      return 'To create new revision last version of this revision should be submitted.';
    }

    return '';
  }, [selectedRequest]);

  return (
    <div className={`${themeClass}_buttonsBlock`}>
      <CustomButton
        type={'tertiary'}
        size={'md'}
        clickHandler={() => handlePCDCardData(isRevisionSubmitted ? 'RevisionUpdate' : 'DraftRevision')}
        title={'Update revision'}
        loading={animationBtn === 'draftBtn' && pcdInfoLoading}
        disabled={isRevisionUpdateDraftButtonDisabled}
        customClassName={`${themeClass}_buttonsBlock_button`}
        customTooltipBasisClassName={`${themeClass}_buttonsBlock_tooltipBasis`}
        isMobile={isMobile}
      />
      <CustomButton
        type={'primary'}
        size={'md'}
        clickHandler={() => handlePCDCardData(isRevisionSubmitted ? 'RevisionCreate' : 'RevisionSubmit')}
        title={isRevisionSubmitted ? 'Create New Revision' : 'Issue'}
        loading={animationBtn === 'sendBtn' && pcdInfoLoading}
        disabled={isCreateSubmitButtonDisabled}
        tooltip={submitCreateTooltipText}
        customClassName={`${themeClass}_buttonsBlock_button`}
        customTooltipBasisClassName={`${themeClass}_buttonsBlock_tooltipBasis`}
        isMobile={isMobile}
      />
    </div>
  );
};

export default DeliverableButtons;
