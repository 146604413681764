import { Popover } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import CustomCalendar from '../../../../../../controls/CustomCalendar/CustomCalendar';

class ProjectCalendar extends React.Component<any, any> {
  state = {
    is_promptPopUpView: false,
    prevValue: '',
  };

  selectAtDateHandlerSingle = (date, changeNow) => {
    const { formData, updateProject, selectAtDateHandlerSingle, typeOfOpenedCalendar, work_week } = this.props;
    const requestObject = {
      [typeOfOpenedCalendar]: moment(date).format('YYYY-MM-DD'),
    };

    const newValue = moment(date).format('YYYY-MM-DD');

    if (changeNow === true) {
      this.setState({
        is_promptPopUpView: false,
      });
      if (updateProject) {
        updateProject({
          id: formData.id,
          formData: requestObject,
        });
      }
      selectAtDateHandlerSingle(date);
      return;
    }

    if (work_week === 5) {
      if (moment(newValue).day() === 0 || moment(newValue).day() === 6) {
        this.setState({
          is_promptPopUpView: true,
          prevValue: date,
        });
      } else {
        if (updateProject) {
          updateProject({
            id: formData.id,
            formData: requestObject,
          });
        }
        selectAtDateHandlerSingle(date);
      }
    }

    if (work_week === 6) {
      if (moment(newValue).day() === 0) {
        this.setState({
          is_promptPopUpView: true,
          prevValue: date,
        });
      } else {
        if (updateProject) {
          updateProject({
            id: formData.id,
            formData: requestObject,
          });
        }
        selectAtDateHandlerSingle(date);
      }
    }

    if (work_week === 7 || work_week === 0) {
      if (updateProject) {
        updateProject({
          id: formData.id,
          formData: requestObject,
        });
      }
      selectAtDateHandlerSingle(date);
    }
  };

  closePromptPopUp = () => {
    this.setState({
      is_promptPopUpView: false,
    });
  };

  render() {
    const { typeOfOpenedCalendar, anchorEl, handleCloseCalendar, selectAtDateHandler, formData, work_week } = this.props;
    const { is_promptPopUpView, prevValue } = this.state;

    return (
      <>
        <Popover
          id={`${'kickOffDate'}`}
          open={!!typeOfOpenedCalendar}
          anchorEl={anchorEl}
          onClose={handleCloseCalendar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <>
            {typeOfOpenedCalendar === 'target_start' && (
              <CustomCalendar
                handleChange={this.selectAtDateHandlerSingle}
                value={formData?.target_start ? new Date(formData.target_start) : new Date()}
                work_week={work_week}
              />
            )}
            {typeOfOpenedCalendar === 'target_to' && (
              <CustomCalendar
                handleChange={this.selectAtDateHandlerSingle}
                value={formData?.target_to ? new Date(formData.target_to) : new Date()}
                work_week={work_week}
              />
            )}
          </>
        </Popover>
      </>
    );
  }
}

export default ProjectCalendar;
