import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import React from 'react';

const highlightedWords = [
  {
    title: 'Commitment Author',
    component: (
      <CustomTooltip title={'A response discipline member who submitted the commitment'} placement="top">
        <span className={'highlightedWord'}> Commitment Author</span>
      </CustomTooltip>
    ),
    id: 0,
  },
  {
    title: 'Commitment',
    component: (
      <CustomTooltip
        title={
          'A response company input describing the commitment. It can be a commitment description itself, since it is not mandatory to attach files when submitting a commitment.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Commitment</span>
      </CustomTooltip>
    ),
    id: 1,
  },
  {
    title: 'Comments',
    component: (
      <CustomTooltip
        title={
          'A supplementary section of an NF card that is used for discussion between request and response parties when commitment can not be submitted or submitted without additional clarification.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Comments</span>
      </CustomTooltip>
    ),
    id: 2,
  },
  {
    title: 'Response',
    component: (
      <CustomTooltip
        title={
          'A definite input made by a response company that fully clarifies the request. It can be a final description itself, since it is not mandatory to attach any files.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Response</span>
      </CustomTooltip>
    ),
    id: 3,
  },
  {
    title: 'GMP (Guaranteed Maximum Price)',
    component: (
      <CustomTooltip
        title={
          'A standard project management tool for keeping costs low. In the construction industry, GMP refers to the highest amount of labor, materials and profit costs the contractor can charge the customer. GMP contracts work best for projects with detailed specifications and scope of work. It can be mutually beneficial for both parties.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> GMP (Guaranteed Maximum Price)</span>
      </CustomTooltip>
    ),
    id: 4,
  },
  {
    title: 'GMP Line',
    component: (
      <CustomTooltip
        title={
          'The specific point in time, when the Guaranteed Maximum Price should be established. It is represented by the thick orange vertical line in the Phase Plan module.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> GMP Line</span>
      </CustomTooltip>
    ),
    id: 5,
  },
  {
    title: 'Hashtag',
    component: (
      <CustomTooltip
        title={
          'Since the predefined request types list may be insufficient, you can supply your NFs with additional information by using hashtags. This allows users to quickly add keywords, as well as group and filter items by them in the Needs List.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Hashtag</span>
      </CustomTooltip>
    ),
    id: 6,
  },
  {
    title: 'History',
    component: (
      <CustomTooltip
        title={'A section of an NF card that keeps track of all the actions that occurred on both Request or Commitment Cards.'}
        placement="top"
      >
        <span className={'highlightedWord'}> History</span>
      </CustomTooltip>
    ),
    id: 7,
  },
  {
    title: 'Incorporation',
    component: (
      <CustomTooltip title={'Section of NF where information about progress of incorporation can be reported.'} placement="top">
        <span className={'highlightedWord'}> Incorporation</span>
      </CustomTooltip>
    ),
    id: 8,
  },
  {
    title: 'LOD Milestone',
    component: (
      <CustomTooltip
        title={
          'A point on the timeline that represents a set of conditions that must be satisfied to release further work. LOD Milestones are an inseparable part of the pull planning process and enable teams to prioritize the most critical tasks and figure out how different tasks are connected toward the final goals. Their arrangement can be previewed in the Phase Plan module.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> LOD Milestone</span>
      </CustomTooltip>
    ),
    id: 9,
  },
  {
    title: 'LOD (Level Of Development)',
    component: (
      <CustomTooltip
        title={
          'The level of completeness/reliability to which a model element (a portion of the Building Information Model representing a component, system or assembly within a building) is developed.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> LOD (Level Of Development)</span>
      </CustomTooltip>
    ),
    id: 10,
  },
  {
    title: 'Needs List',
    component: (
      <CustomTooltip
        title={'Presents a comprehensive register of all requests and commitments submitted by users in a form of adjustable spreadsheet.'}
        placement="top"
      >
        <span className={'highlightedWord'}> Needs List</span>
      </CustomTooltip>
    ),
    id: 11,
  },
  {
    title: 'NF (Card)',
    component: (
      <CustomTooltip
        title={
          '(Need Form) an information request with a unique number that remains unchanged through the whole project timeline. The NF user interface consists of a Request card and a Commitment card. NFs were created to enable clear and organized information flow between all parties included in the design and construction process.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> NF (Card)</span>
      </CustomTooltip>
    ),
    id: 12,
  },
  {
    title: 'NF Author',
    component: (
      <CustomTooltip title={'A user that created an NF Request'} placement="top">
        <span className={'highlightedWord'}> NF Author</span>
      </CustomTooltip>
    ),
    id: 13,
  },
  {
    title: 'NF owner',
    component: (
      <CustomTooltip title={'Any person or organization that has a right to edit this NF Request information.'} placement="top">
        <span className={'highlightedWord'}> NF owner</span>
      </CustomTooltip>
    ),
    id: 14,
  },
  {
    title: 'NF recipient',
    component: (
      <CustomTooltip
        title={'Any person or organization that has a right to fill in Commitment/Response information of this NF.'}
        placement="top"
      >
        <span className={'highlightedWord'}> NF recipient</span>
      </CustomTooltip>
    ),
    id: 15,
  },
  {
    title: 'Pull Request',
    component: (
      <CustomTooltip
        title={
          'An information request created in cases when providing a response to a certain NF requires having another request responded beforehand. In order to create a Pull Request, you need to submit an initial commitment first.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Pull Request</span>
      </CustomTooltip>
    ),
    id: 16,
  },
  {
    title: 'Request (Card)',
    component: (
      <CustomTooltip
        title={
          'User interface element with predefined data fields containing a need of a Request Company as well as the willingness of a Response Company to provide it within a certain deadline - NF Due Date. Can be accessed from Needs List, Whiteboard or Sandbox modules.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Request</span>
      </CustomTooltip>
    ),
    id: 17,
  },
  {
    title: 'Commitment (Card)',
    component: (
      <CustomTooltip
        title={
          'User interface element with predefined data fields containing a need of a Request Company as well as the willingness of a Response Company to provide it within a certain deadline - NF Due Date. Can be accessed from Needs List, Whiteboard or Sandbox modules.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Commitment</span>
      </CustomTooltip>
    ),
    id: 18,
  },
  {
    title: 'Reporting',
    component: (
      <CustomTooltip title={'Scheduler module which provides a set of performance dashboards and reports.'} placement="top">
        <span className={'highlightedWord'}> Reporting</span>
      </CustomTooltip>
    ),
    id: 19,
  },
  {
    title: 'Request type',
    component: (
      <CustomTooltip
        title={'Type of request that you can choose while working with an NF. It expresses the main intent of NFs.'}
        placement="top"
      >
        <span className={'highlightedWord'}> Request type</span>
      </CustomTooltip>
    ),
    id: 20,
  },
  {
    title: 'Request Author',
    component: (
      <CustomTooltip title={'A request company member who created a request.'} placement="top">
        <span className={'highlightedWord'}> Request Author</span>
      </CustomTooltip>
    ),
    id: 21,
  },
  {
    title: 'Request Company',
    component: (
      <CustomTooltip title={'A company whose members have created the request.'} placement="top">
        <span className={'highlightedWord'}> Request Company</span>
      </CustomTooltip>
    ),
    id: 22,
  },
  {
    title: 'Response Company',
    component: (
      <CustomTooltip
        title={
          'A response discipline company whose members are supposed to submit a commitment to a certain request. Can be modified by the Request Manager until the NF is in the ‘accepted’ status.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Response Company</span>
      </CustomTooltip>
    ),
    id: 23,
  },
  {
    title: 'Request Discipline',
    component: (
      <CustomTooltip title={'A project discipline whose company has created the NF.'} placement="top">
        <span className={'highlightedWord'}> Request Discipline</span>
      </CustomTooltip>
    ),
    id: 24,
  },
  {
    title: 'Response Discipline',
    component: (
      <CustomTooltip
        title={
          'A project team discipline whose companies are supposed to submit a commitment to a certain request. Can be modified by the Request Manager until the NF is in the ‘accepted’ status.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Response Discipline</span>
      </CustomTooltip>
    ),
    id: 25,
  },
  {
    title: 'Request Manager',
    component: (
      <CustomTooltip
        title={'A request company member who has been assigned by the request author to track a certain request.'}
        placement="top"
      >
        <span className={'highlightedWord'}> Request Manager</span>
      </CustomTooltip>
    ),
    id: 26,
  },
  {
    title: 'Response Manager',
    component: (
      <CustomTooltip
        title={
          'A response company member assigned to be responsible for committing a particular type of request. Can be modified by the Request Manager until the NF is in the ‘accepted’ status.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Response Manager</span>
      </CustomTooltip>
    ),
    id: 27,
  },
  {
    title: 'Sandbox',
    component: (
      <CustomTooltip
        title={
          'A primary user interface to control NFs categorized according to relevance. It allows simultaneous browsing and editing of NFs'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Sandbox</span>
      </CustomTooltip>
    ),
    id: 28,
  },
  {
    title: 'UF (UniFormat)',
    component: (
      <CustomTooltip title={''} placement="top">
        <span className={'highlightedWord'}> </span>
      </CustomTooltip>
    ),
    id: 29,
  },
  {
    title: 'MF (MasterFormat)',
    component: (
      <CustomTooltip
        title={
          'Standardized classification code assigned to an NF in a searchable drop-down list. Not mandatory to send and submit a request.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> </span>
      </CustomTooltip>
    ),
    id: 30,
  },
  {
    title: 'Whiteboard',
    component: (
      <CustomTooltip
        title={'Scheduler module which locates requests and commitments on a timeline, grouped by a discipline.'}
        placement="top"
      >
        <span className={'highlightedWord'}> Whiteboard</span>
      </CustomTooltip>
    ),
    id: 31,
  },
  {
    title: 'Response Party',
    component: (
      <CustomTooltip
        title={
          'A response discipline company whose members are supposed to submit a commitment to a certain request. Can be modified by the Request Manager until the response is in the ‘accepted’ status.'
        }
        placement="top"
      >
        <span className={'highlightedWord'}> Response Party</span>
      </CustomTooltip>
    ),
    id: 32,
  },
];

export default highlightedWords;
