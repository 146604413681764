import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { isEmptyObject } from '../../../helpers/commonHelpers';
import {
  deactivatedStatus,
  getNeedListColumnOrder,
  getSandBoxCounters,
  getSandBoxData,
  getWatchers,
  requestDelete,
  requestView,
  singleRequestLoading,
} from '../../../store/request/requestLogic(HOLD)';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { pcdSlice } from '../../../store/pcd/pcdSlice';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { isEqual } from 'lodash';
import { KeyValueModel } from '../../../models/key-value.model';
import { GlobalFiltersQueryModel } from '../../../models';
import { SandBoxCustomTabsPayloadModel, SandBoxTabModel } from '../../../models/sandBox/sandBoxArhitecture';
import { AllSectionType, sandBoxAllSections } from './SandBoxConstants/SandBoxConstants';
import { RequestsList, SandBoxGraphs } from './parts/index';
import SubmenuSectionsNew from '../../controls/SubmenuSectionsNew/SubmenuSectionsNew';
import {
  CustomFiltersModel,
  SandBoxNfSnippetModel,
  SandBoxQueryModel,
  SandBoxSortingStateModel,
} from '../../../store/request/requestReducer.model';
import { ISubmittalUpdateModel, SubmittalModel } from '../../../models/submittals/submittals.model';
import { supportSlice } from '../../../store/support/supportSlice';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import PageHeader from '../../controls/PageHeader/PageHeader';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import { GetResolution, GetResolutionMin } from '../../../helpers/ScreenResolution/GetResolution';
import SubmenuSelect from '../../controls/SubmenuSelect/SubmenuSelect';
import moment from 'moment/moment';
import PageFilters from '../PageFilters/PageFilters';
import {
  deliverableGroupedByFilters,
  deliverableSelectorGroups,
  deliverableSortingOptions,
  list_columns as pcd_columns,
} from '../PCDeliverablesMain/constants';
import {
  list_columns as needList_columns,
  needsListGroupedByFilters,
  needsListGroupingOptions,
  needsListSelectorGroups,
  needsListSortingOptions,
} from '../NeedsList/NeedListConstants/NeedListConstants';
import {
  submittalGroupByFilters,
  submittalsGridColumns,
  submittalsSelectorGroups,
  submittalsSortingOptions,
} from '../DesignDocumentsLog/constants';
import { filterSlice } from '../../../store/filters/filtersReducer';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { SandBoxRelationSwitcher } from './parts';
import { commentSlice } from '../../../store/comments/commentsReducer';
import MixpanelHelper from '../../../service/Mixpanel/Mixpanel';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { submittalSave } from '../../../store/submittal/submittalThunk';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';
import { TextInputNew } from '../../controls';
import { TextQuillEditor } from '../../controls/TextQuillEditor';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './SandBoxStyle.scss';

const { markSandBoxItemAsViewed, setSandBoxQuery, setNfOpenId, setSandBoxTabsState, setSandBoxItemsFromCache, clearCounters } =
  requestSlice.actions;
const { setPcdOpenId } = pcdSlice.actions;
const { handleRunSandBoxQuickTour } = supportSlice.actions;
const { clearBreadcrumbs } = breadcrumbsSlice.actions;
const { setIsNavigateFromSandbox } = commentSlice.actions;
const { saveStoreRequestsFilters } = filterSlice.actions;

interface IProps {
  openedFrom?: string;
  report_party_id?: number;
  report_user_id?: number;
  headerName?: string;
}

interface SandBoxFiltersTypesDataModel {
  need_form: boolean;
  deliverable: boolean;
  submittalInput: boolean;
  submittalOutput: boolean;
}

type SandBoxQueryWithFilters = SandBoxQueryModel & GlobalFiltersQueryModel;

export const initialQueryState = {
  filters: {},
  or_filters: {},
  conditions: {},
  custom_filters: {},
  or_conditions: {},
  order_by: '',
  order_dir: '',
  deliverable_filters: {
    filters: {},
    or_filters: {},
    conditions: {},
    or_conditions: {},
    switch_and: [],
  },
  submittal_input_filters: {
    filters: {},
    or_filters: {},
    conditions: {},
    or_conditions: {},
    switch_and: [],
  },
  submittal_output_filters: {
    filters: {},
    or_filters: {},
    conditions: {},
    or_conditions: {},
    switch_and: [],
  },
  search: '',
  related_to: 'user',
  filter_by: 'request_inMyCourt_sentToMe',
  switch_and: [],
};

const initialSorting = {
  request: {
    order_by: '',
    order_dir: '',
  },
  deliverable: {
    order_by: '',
    order_dir: '',
  },
  submittalInput: {
    order_by: '',
    order_dir: '',
  },
  submittalOutput: {
    order_by: '',
    order_dir: '',
  },
};

const initialResetFiltersState = {
  need_form: false,
  deliverable: false,
  submittalInput: false,
  submittalOutput: false,
};

const SandBox: React.FC<IProps> = ({ report_party_id, report_user_id, openedFrom, headerName }) => {
  const dispatch = useAppDispatch();
  const {
    setUrlNF,
    setUrlHash,
    setUrlSubmittal,
    setUrlPCD,
    removeUrlHash,
    setUrlCommandCenterTab,
    setUrlHeatmapFilter,
    removeUrlHeatmapFilter,
  } = useRoutingHelper();
  const { urlProject, searchParams } = useRouter();

  const sandBoxCache = useAppSelector(state => state.requestReducer.sandBoxCache);
  const sandBoxTabsState = useAppSelector(state => state.requestReducer.sandBoxTabsState);
  const active_project_id = useAppSelector(state => state.userReducer.active_project_id);
  const columnOrder = useAppSelector(state => state.requestReducer.columnOrder);
  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const sandBoxCounters = useAppSelector(state => state.requestReducer.sandBox.counters);
  const sandBoxData = useAppSelector(state => state.requestReducer.sandBox.accordion_list);
  const sandBoxFilters = useAppSelector(state => state.requestReducer.sandBoxFilters);
  const lastPcdCardId = useAppSelector(state => state.pcdReducer.lastPcdCardId);
  const isFirstSandBoxWidgetsLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxWidgetsLoading);
  const isFirstSandBoxCountersLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxCountersLoading);
  const isFirstSandBoxAllCountersLoading = useAppSelector(state => state.requestReducer.isFirstSandBoxAllCountersLoading);
  const reportingData = useAppSelector(state => state.reportingReducer.reportingData);
  const loadingTabel = useAppSelector(state => state.reportingReducer.loadingTabel);

  const isSandBoxFirstLoading = isFirstSandBoxWidgetsLoading || isFirstSandBoxCountersLoading || isFirstSandBoxAllCountersLoading;

  const { is1100Resolution, isTablet } = GetResolution();
  const { sizeResolution, sizeResolutionSnippet } = GetResolutionMin();
  const isMobile = sizeResolution === 'mobile';

  const themeClass = useGetThemeClass('b-sandBox');
  const themeClassPopUpRequestCustomWidget = useGetThemeClass('b-popupRequestCustomWidget');

  const activeSectionId = useMemo(() => {
    const activeTabUrl = searchParams.get(`activeTab`);
    if (activeTabUrl) {
      const activeSubTab = JSON.parse(activeTabUrl);
      const activeTab = activeSubTab.split('_');
      if (activeTab.includes('heatmap')) {
        return activeTab[0] + '_' + activeTab[1] + '_' + activeTab[2];
      } else {
        return activeTab[0] + '_' + activeTab[1];
      }
    } else {
      return 'request_inMyCourt';
    }
  }, [searchParams]);

  const activeSubSectionId = useMemo(() => {
    const activeTabUrl = searchParams.get(`activeTab`);
    if (activeTabUrl) {
      return JSON.parse(activeTabUrl);
    } else {
      return 'request_inMyCourt_sentToMe';
    }
  }, [searchParams]);

  const [isRightBtn, setIsRightBtn] = useState<boolean>(false);
  const [isHandleCallbackNavigation, setIsHandleCallbackNavigation] = useState({ hash: null, isHandle: false });
  const [isHandleHeatMapUrl, setIsHandleHeatMapUrl] = useState({ url: null, isHandle: false });

  // Selected SandBox item
  const [selectedItem, setSelectedItem] = useState<{ type: 'nf' | 'deliverable' | 'submittal'; id: number }>(null);

  const [generalSearchValue, setGeneralSearchValue] = useState<string>(null);
  const [isSearchValue, setIsSearchValue] = useState<boolean>(false);
  const [isClickResetBtn, setIsClickResetBtn] = useState<boolean>(false);

  // SandBoxFiltersQuery
  const [query, setQuery] = useState<SandBoxQueryWithFilters>(sandBoxFilters || initialQueryState);
  const [initiateResetFilters, setInitiateResetFilters] = useState<SandBoxFiltersTypesDataModel>(initialResetFiltersState);
  const [showResetButtonNf, setShowResetButtonNf] = useState<boolean>(false);
  const [showResetButtonDeliverable, setShowResetButtonDeliverable] = useState<boolean>(false);
  const [savedNfFilters, setSavedNfFilters] = useState<GlobalFiltersQueryModel>(null);
  const [savedDeliverableFilters, setSavedDeliverableFilters] = useState<GlobalFiltersQueryModel>(null);
  const [savedSubmittalInputFilters, setSavedSubmittalInputFilters] = useState<GlobalFiltersQueryModel>(null);
  const [savedSubmittalOutputFilters, setSavedSubmittalOutputFilters] = useState<GlobalFiltersQueryModel>(null);

  const [isOpenSearchMobile, setIsOpenSearchMobile] = useState(false);
  const [isClickHeatmap, setIsClickHeatmap] = useState<boolean>(false);

  const [filterHeight, setFilterHeight] = useState<number>(null);
  const [isCollapsedBtn, setIsCollapsedBtn] = useState<boolean>(false);
  const [isOpenRequestCustomWidget, setIsOpenRequestCustomWidget] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [focusElement, setFocusElement] = useState<string | null>(null);

  // General Sorting state
  const [generalSorting, setGeneralSorting] = useState<SandBoxSortingStateModel>(initialSorting);

  // SandBox section's states
  const [allSections, setAllSections] = useState<SandBoxTabModel[]>(sandBoxAllSections);

  // Accordion states
  const [accordionStates, setAccordionStates] = useState<KeyValueModel<boolean> | null>(null);
  const [accordionRefs, setAccordionRefs] = useState<KeyValueModel<RefObject<HTMLElement>> | null>(null);

  // Content view switchers
  const [isAllAccCollapsed, setIsAllAccCollapsed] = useState<boolean>(false);
  const [isShortView, setIsShortView] = useState<boolean>(false);
  const [isClickShortView, setClickShortView] = useState<boolean>(false);

  // Deactivation states
  const [isDeactivationPopUpOpen, setIsDeactivationPopUpOpen] = useState<boolean>(false);
  const [deactivationItem, setDeactivationItem] = useState<{
    action: 'activate' | 'deactivate';
    snippetData: SandBoxNfSnippetModel | SubmittalModel;
    accordion: string;
  }>();

  // Remove nf states
  const [isOpenRemovePopup, setIsOpenRemovePopUp] = useState<boolean>(false);
  const [nfToRemove, setNfToRemove] = useState<{ nf: SandBoxNfSnippetModel; accordion: string }>(null);

  const getFilter = (type: AllSectionType) => {
    if (type === 'request') {
      return setSavedNfFilters;
    }
    if (type === 'deliverable') {
      return setSavedDeliverableFilters;
    }
    if (type === 'submittalInput') {
      return setSavedSubmittalInputFilters;
    }
    if (type === 'submittalOutput') {
      return setSavedSubmittalOutputFilters;
    }
  };

  const storageName = useMemo(() => {
    if (openedFrom === 'report') {
      return 'sandbox_report';
    }

    return 'sandbox';
  }, [openedFrom]);

  const activeSection = useMemo(() => {
    if (activeSectionId) {
      const all_Sections = allSections.flatMap(item => item.sections);
      return all_Sections.find(section => section.id === activeSectionId);
    }
  }, [activeSectionId, allSections]);

  const activeSubSection = useMemo(() => {
    if (activeSectionId && activeSection) {
      return activeSection.subSections.find(subSection => subSection.id === activeSubSectionId);
    }
  }, [activeSection, activeSubSectionId]);

  const getSectionType = (sectionId: string) => {
    if (sectionId && sectionId.includes('request')) {
      return 'request';
    }

    if (sectionId && sectionId.includes('deliverable')) {
      return 'deliverable';
    }

    if (sectionId && sectionId.includes('submittalInput')) {
      return 'submittalInput';
    }

    if (sectionId && sectionId.includes('submittalOutput')) {
      return 'submittalOutput';
    }
  };

  const getSectionTypeLocalStorage = (sectionId: string, isReport: boolean, searchValue: string) => {
    if (searchValue) {
      if (sectionId && sectionId.includes('request')) {
        if (isReport) {
          return ['sandbox_report_deliverable', 'sandbox_report_submittal_form_output', 'sandbox_report_submittal_form_input'];
        } else {
          return ['sandbox_deliverable', 'sandbox_submittal_form_output', 'sandbox_submittal_form_input'];
        }
      }
      if (sectionId && sectionId.includes('deliverable')) {
        if (isReport) {
          return ['sandbox_report_submittal_form_output', 'sandbox_report_need_form', 'sandbox_report_submittal_form_input'];
        } else {
          return ['sandbox_submittal_form_output', 'sandbox_need_form', 'sandbox_submittal_form_input'];
        }
      }

      if (sectionId && sectionId.includes('submittalInput')) {
        if (isReport) {
          return ['sandbox_report_deliverable', 'sandbox_report_submittal_form_output', 'sandbox_report_need_form'];
        } else {
          return ['sandbox_deliverable', 'sandbox_submittal_form_output', 'sandbox_need_form'];
        }
      }

      if (sectionId && sectionId.includes('submittalOutput')) {
        if (isReport) {
          return ['sandbox_report_deliverable', 'sandbox_report_need_form', 'sandbox_report_submittal_form_input'];
        } else {
          return ['sandbox_deliverable', 'sandbox_need_form', 'sandbox_submittal_form_input'];
        }
      }
    } else {
      if (isReport) {
        return [
          'sandbox_report_deliverable',
          'sandbox_report_submittal_form_output',
          'sandbox_report_need_form',
          'sandbox_report_submittal_form_input',
        ];
      } else {
        return ['sandbox_deliverable', 'sandbox_submittal_form_output', 'sandbox_need_form', 'sandbox_submittal_form_input'];
      }
    }
  };

  const activeSectionType = useMemo(() => {
    if (activeSectionId) {
      return getSectionType(activeSectionId);
    }
  }, [activeSectionId]);

  const getSandBoxDataPromise = useRef(null);
  const getSandBoxCountersPromise = useRef(null);
  const loadRequestPromise = useRef(null);
  const pageHeaderRef = useRef(null);

  useEffect(() => {
    MixpanelHelper.track('Sandbox module visited');
  }, []);

  useEffect(() => {
    setIsRightBtn(sizeResolution === '1440');
  }, [sizeResolution]);

  useEffect(() => {
    if (openedFrom !== 'report' && !isClickShortView) {
      if (
        (sizeResolution === '1024' && !isRightBtn && window.innerWidth >= 1150) ||
        (sizeResolution === '1440' && (!isRightBtn || window.innerWidth >= 1700)) ||
        sizeResolution === '1920'
      ) {
        setIsShortView(true);
      } else {
        setIsShortView(false);
      }
    }
  }, [sizeResolution, isRightBtn, isClickShortView]);

  useEffect(() => {
    if (activeSectionId?.split?.('_')?.[1]) {
      onChangeParentSection(
        activeSectionId.split('_')?.[2] === 'heatmap' ? activeSectionId.split('_')?.[2] : activeSectionId.split('_')?.[1],
      );
    }
  }, [activeSectionId?.split?.('_')?.[1]]);

  useEffect(() => {
    const related_to = localStorage.getItem(`${storageName}_related_to`) || 'user';

    let queryData = { ...query };

    if (related_to) {
      queryData = {
        ...queryData,
        related_to,
      };
    }

    if (columnOrder.length === 0) {
      dispatch(getNeedListColumnOrder());
    }

    setQuery(queryData);
  }, []);

  useEffect(() => {
    if (!query?.search && generalSearchValue) {
      hideSectionSearch(false, true);
    }
    if (!query?.search && !generalSearchValue && generalSearchValue !== null) {
      hideSectionSearch(true, false);
    }
    setIsClickResetBtn(false);
    setIsSearchValue(!!generalSearchValue);
  }, [!generalSearchValue]);

  useEffect(() => {
    if (isHandleCallbackNavigation.isHandle) {
      if (isHandleCallbackNavigation.hash) {
        setUrlHash(isHandleCallbackNavigation.hash);
      } else {
        removeUrlHash();
      }
      setIsHandleCallbackNavigation({ hash: null, isHandle: false });
    }
  }, [isHandleCallbackNavigation.isHandle]);

  useEffect(() => {
    if (isHandleHeatMapUrl.isHandle) {
      if (isHandleHeatMapUrl.url) {
        setUrlHeatmapFilter(isHandleHeatMapUrl.url);
      } else {
        removeUrlHeatmapFilter();
      }
      setIsHandleHeatMapUrl({ url: null, isHandle: false });
    }
  }, [isHandleHeatMapUrl.isHandle]);

  const sectionsReportApplyCount = sections => {
    return sections.filter(
      item =>
        item.id === 'request_hotList' ||
        item.id === 'request_inMyCourt' ||
        item.id === 'request_discussions' ||
        item.id === 'request_myNfs' ||
        item.id === 'deliverable_hotList' ||
        item.id === 'deliverable_inMyCourt' ||
        item.id === 'deliverable_discussions' ||
        item.id === 'deliverable_myDeliverables',
    );
  };

  const allSectionsReportApply = sections => {
    return sections.filter(item => item.id === 'inMyCourt' || item.id === 'mentions' || item.id === 'myItems');
  };

  const sectionsApplyCount = sections => {
    return sections.map(section => {
      const newSubSections =
        section.subSections?.map(subSection => {
          return {
            ...subSection,
            count: sandBoxCounters[subSection.id] ? sandBoxCounters[subSection.id] : null,
          };
        }) || null;

      return {
        ...section,
        subSections: newSubSections ? newSubSections : section.subSections,
        count: sandBoxCounters[section.id] ? sandBoxCounters[section.id] : null,
      };
    });
  };

  const allSectionsApplyCount = sections => {
    return sections.map(item => {
      let all_section_count = 0;
      const newAllSections = item.sections.map(section => {
        const newSubSections =
          section.subSections?.map(subSection => {
            return {
              ...subSection,
              count: sandBoxCounters?.[subSection.id] ? sandBoxCounters[subSection.id] : null,
            };
          }) || null;
        all_section_count = all_section_count + (sandBoxCounters?.[section.id] ? sandBoxCounters[section.id] : 0);
        return {
          ...section,
          subSections: newSubSections ? newSubSections : section.subSections,
          count: sandBoxCounters?.[section.id] ? sandBoxCounters[section.id] : null,
        };
      });

      return {
        ...item,
        sections: newAllSections,
        count: all_section_count,
      };
    });
  };

  const formatAllSectionsByBelongingToPlanitCompany = sections => {
    const userCanSeeQualityControl = userInfo.qc_user;
    if (userCanSeeQualityControl) {
      return sections;
    }
    return sections.filter(item => item.id !== 'qualityControl');
  };

  useEffect(() => {
    if (query) {
      dispatch(setSandBoxQuery(query));
    }
  }, [query]);

  useEffect(() => {
    if (userInfo.id && userInfo.is_seen_release_notes && !userInfo.is_seen_sandbox_tour && openedFrom !== 'report') {
      dispatch(handleRunSandBoxQuickTour(true));
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      const filterAllSections =
        openedFrom == 'report' ? allSectionsReportApply(allSections) : formatAllSectionsByBelongingToPlanitCompany(allSections);
      const newAllSectionsReport = allSectionsReportApply(filterAllSections);

      const newAllSections =
        openedFrom == 'report' ? allSectionsApplyCount(newAllSectionsReport) : allSectionsApplyCount(filterAllSections);

      if (searchParams.get(`activeTab`)) {
        const newAllSectionsArray = newAllSections.map(item => {
          const obj = { ...item };
          if (activeSubSectionId.includes(item.id)) {
            if (activeSubSectionId.includes('heatmap')) {
              obj.isHideSection = false;
            }
            obj.typeSystemButton = 'chevron-down';
          } else {
            obj.typeSystemButton = 'chevron-right';
          }
          return obj;
        });

        if (activeSubSectionId.includes('heatmap')) {
          const type = activeSubSectionId.split('_')[0];
          const newTabs = {
            [type]: {
              id: `${type}_custom`,
              title: 'My heatmap',
              isCustom: true,
              subSections: [
                {
                  id: `${type}_custom_heatmap`,
                  title: 'My heatmap',
                },
              ],
            },
          };

          const customFiltersUrl = searchParams.get(`custom_filters`);
          setTimeout(() => {
            handleSetCustomFilterTab(newTabs, type, JSON.parse(customFiltersUrl));
          }, 1700);
        }
        setTimeout(() => {
          setAllSections(newAllSectionsArray);
        }, 100);
      } else {
        if (activeSubSectionId) {
          const activeSectionIdNew = activeSectionId || 'request_inMyCourt';
          const newAllSectionsArray = newAllSections.map(item => {
            const obj = { ...item };
            if (activeSectionIdNew.includes(item.id)) {
              obj.typeSystemButton = 'chevron-down';
            } else {
              obj.typeSystemButton = 'chevron-right';
            }
            return obj;
          });
          setTimeout(() => {
            setAllSections(newAllSectionsArray);
          }, 100);
        }

        setUrlCommandCenterTab(activeSubSectionId || 'request_inMyCourt_sentToMe', true);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (sandBoxCounters) {
      const filterAllSections =
        openedFrom == 'report' ? allSectionsReportApply(allSections) : formatAllSectionsByBelongingToPlanitCompany(allSections);
      const newAllSectionsReport = allSectionsReportApply(filterAllSections);

      const newAllSections =
        openedFrom == 'report' ? allSectionsApplyCount(newAllSectionsReport) : allSectionsApplyCount(filterAllSections);

      const customSearchCount = newAllSections.find(item => item.id === 'customSearch')?.count;

      if (customSearchCount && isSearchValue) {
        const couterCustomSearch = newAllSections.map(item => {
          const obj = { ...item };
          if (obj.id === 'customSearch') {
            obj.typeSystemButton = 'chevron-down';
            obj.isHideSection = false;
            const subSection = obj.sections.filter(section => section.id === activeSectionId.split('_')[0] + '_custom')[0] || null;
            const first_section = obj.sections.filter(section => section.count)[0];
            selectSection(subSection?.id || first_section?.id, !!activeSectionId);
          } else {
            obj.typeSystemButton = 'chevron-right';
          }
          return obj;
        });
        setAllSections(couterCustomSearch);
      } else {
        setAllSections(newAllSections);
      }
      setIsSearchValue(false);
    }
  }, [sandBoxCounters]);

  useEffect(() => {
    if (selectedItem && selectedItem.type === 'nf') {
      dispatch(getWatchers(+selectedItem.id));
      dispatch(setNfOpenId(selectedItem.id));
    }
  }, [selectedItem]);

  useEffect(() => {
    if (active_project_id) {
      const localStorageDataNfs = JSON.parse(localStorage.getItem(`${storageName}_need_form`));
      const related_to =
        openedFrom !== 'report' ? localStorage.getItem(`${storageName}_related_to`) || 'user' : report_user_id ? 'user' : 'party';
      const initialSearchValue = localStorageDataNfs?.project_name === urlProject[0] ? localStorageDataNfs?.searchValue || '' : '';

      const hasLocalStorageFiltersNfs =
        Object.keys(localStorageDataNfs || {}).length &&
        localStorageDataNfs.project_name === urlProject[0] &&
        (Object.keys(localStorageDataNfs?.formData || {}).length ||
          Object.keys(localStorageDataNfs?.formDataOrFilters || {}).length ||
          Object.keys(localStorageDataNfs?.containsFilter || {}).length ||
          localStorageDataNfs?.selectedFilters?.length ||
          localStorageDataNfs?.orFilters?.length ||
          Object.keys(localStorageDataNfs?.conditions || {}).length);

      const queryData = {
        ...initialQueryState,
        custom_filters: sandBoxFilters.custom_filters || {},
        related_to: related_to,
        filter_by: activeSubSectionId,
        search: initialSearchValue,
        project_id: active_project_id,
      };

      if (openedFrom === 'report') {
        if (report_user_id) {
          queryData.user_id = report_user_id;
        } else {
          queryData.party_id = report_party_id;
        }
      }

      // Nf filters
      if (!hasLocalStorageFiltersNfs) {
        getSandBoxCountersPromise.current?.abort();
        getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));
      }

      setUrlCommandCenterTab(activeSubSectionId || 'request_inMyCourt_sentToMe', true);
      setQuery(queryData);
    }
  }, [active_project_id]);

  useEffect(() => {
    if (sandBoxData && sandBoxData?.length) {
      const newAccordionRefs = sandBoxData.reduce((acc, accordion) => {
        const isAccordionExist = accordionRefs && Object.keys(accordionRefs).includes(accordion.alias);

        acc[accordion.alias] = isAccordionExist ? accordionRefs[accordion.alias] : React.createRef();

        return acc;
      }, {});

      const newAccordionStates = sandBoxData.reduce((acc, accordion) => {
        const isAccordionExist = accordionStates && Object.keys(accordionStates).includes(accordion.alias);

        acc[accordion.alias] = isAccordionExist ? accordionStates[accordion.alias] : true;

        return acc;
      }, {});

      const isAllAccordionClosed = Object.values(newAccordionStates).every(state => !state);

      setIsAllAccCollapsed(isAllAccordionClosed);
      setAccordionStates(newAccordionStates);
      setAccordionRefs(newAccordionRefs);
    }
  }, [sandBoxData]);

  useEffect(() => {
    if (!lastPcdCardId) {
      closeDeliverableHandler();
    }
  }, [lastPcdCardId]);

  useEffect(() => {
    if (savedNfFilters) {
      const nfQuery = {
        filters: query.filters || {},
        or_filters: query.or_filters || {},
        conditions: query.conditions || {},
        or_conditions: query.or_conditions || {},
      };

      const isNfQueryEqual = isEqual(nfQuery, savedNfFilters);

      if (isNfQueryEqual) {
        return;
      }

      const newQuery = {
        ...query,
        ...savedNfFilters,
      };

      setQuery(newQuery);
    }
  }, [savedNfFilters]);

  useEffect(() => {
    if (savedDeliverableFilters) {
      const deliverableQuery = {
        filters: query.deliverable_filters?.filters || {},
        or_filters: query.deliverable_filters?.or_filters || {},
        conditions: query.deliverable_filters?.conditions || {},
        or_conditions: query.deliverable_filters?.or_conditions || {},
      };

      const isDeliverableQueryEqual = isEqual(deliverableQuery, savedDeliverableFilters);

      if (isDeliverableQueryEqual) {
        return;
      }

      const newQuery = {
        ...query,
        deliverable_filters: savedDeliverableFilters,
      };

      setQuery(newQuery);
    }
  }, [savedDeliverableFilters]);

  const setLocalStorageFiltersSearchValueAllTab = (ActiveSubSection: string, searchValue: string) => {
    const localStorageTab = getSectionTypeLocalStorage(ActiveSubSection, false, searchValue);
    const localStorageTabReport = getSectionTypeLocalStorage(ActiveSubSection, true, searchValue);
    if (openedFrom == 'report') {
      localStorageTabReport.forEach(tab => {
        const tabFilters = JSON.parse(localStorage.getItem(tab));
        localStorage.setItem(
          tab,
          JSON.stringify({
            ...tabFilters,
            searchValue: searchValue,
          }),
        );
        dispatch(saveStoreRequestsFilters({ data: { search: searchValue }, name: tab, isStrictChange: true }));
      });
    } else {
      localStorageTab.forEach(tab => {
        const tabFilters = JSON.parse(localStorage.getItem(tab));
        localStorage.setItem(
          tab,
          JSON.stringify({
            ...tabFilters,
            searchValue: searchValue,
          }),
        );
        dispatch(saveStoreRequestsFilters({ data: { search: searchValue }, name: tab, isStrictChange: true }));
      });
    }
  };

  const getCustomFiltersFromSearch = (searchValue: string) => {
    if (query.custom_filters) {
      const newCustomFilters = Object.keys(query.custom_filters).reduce(
        (acc, typeName) => {
          const hasSearchField = Object.keys(query.custom_filters[typeName]).includes('search');

          if (hasSearchField) {
            acc = {
              ...acc,
              [typeName]: {
                search: searchValue,
              },
            };
          }

          return acc;
        },
        { ...query.custom_filters },
      );

      return {
        custom_filters: newCustomFilters,
      };
    }

    return {};
  };

  const handleRightBtn = (isVisible: boolean) => {
    setIsRightBtn(isVisible);
  };

  const handleAccordion = (alias: string) => {
    const newAccordionStates = {
      ...accordionStates,
      [alias]: !accordionStates[alias],
    };
    const isAllAccordionClosed = Object.values(newAccordionStates).every(state => !state);

    setIsAllAccCollapsed(isAllAccordionClosed);
    setAccordionStates(newAccordionStates);
  };

  const onChangeFilters = (filters: SandBoxQueryWithFilters, type: AllSectionType, isFirstRender?) => {
    const additionalFields = getCustomFiltersFromSearch(filters.search);
    const setSavedTypeFilters = getFilter(type);
    if (isClickResetBtn) {
      setIsClickResetBtn(false);
      return;
    }
    const newFilters = {
      filters: filters.filters || {},
      or_filters: filters.or_filters || {},
      conditions: filters.conditions || {},
      or_conditions: filters.or_conditions || {},
    };

    setSavedTypeFilters(newFilters);

    let newActiveSubSectionId;
    if (!activeSubSectionId.includes('custom_search') && filters.search) {
      newActiveSubSectionId = activeSubSectionId.split('_')[0] + '_custom_search';
    }

    let queryData = {
      ...query,
      search: filters.search || '',
      filter_by: newActiveSubSectionId || activeSubSectionId,
      related_to: query.related_to,
      order_by: filters.order_by || query.order_by,
      order_dir: filters.order_dir || query.order_dir,
      project_id: filters.project_id || 0,
    };

    const customFiltersUrl = searchParams.get(`custom_filters`);
    if (customFiltersUrl) {
      queryData = { ...queryData, custom_filters: JSON.parse(customFiltersUrl) };
    } else {
      queryData = { ...queryData, ...(additionalFields || {}) };
    }

    if (type === 'request') {
      queryData = {
        ...queryData,
        ...newFilters,
        switch_and: filters.switch_and || [],
        deliverable_filters: query.deliverable_filters,
      };
    }
    if (type === 'deliverable') {
      queryData = {
        ...queryData,
        deliverable_filters: {
          ...(queryData.deliverable_filters || {}),
          ...newFilters,
          switch_and: filters.switch_and || [],
        },
      };
    }

    if (type === 'submittalInput') {
      queryData = {
        ...queryData,
        deliverable_filters: query.deliverable_filters,
        submittal_output_filters: query.submittal_output_filters,
        submittal_input_filters: {
          ...(queryData.submittal_input_filters || {}),
          ...newFilters,
          switch_and: filters.switch_and || [],
        },
      };
    }

    if (type === 'submittalOutput') {
      queryData = {
        ...queryData,
        deliverable_filters: query.deliverable_filters,
        submittal_input_filters: query.submittal_input_filters,
        submittal_output_filters: {
          ...(queryData.submittal_output_filters || {}),
          ...newFilters,
          switch_and: filters.switch_and || [],
        },
      };
    }

    if (openedFrom === 'report') {
      if (report_user_id) {
        queryData.user_id = report_user_id;
      } else {
        queryData.party_id = report_party_id;
      }
    }

    const isFiltersNotChanged = isEqual(queryData, query) && !isFirstRender;
    if (isFiltersNotChanged || type !== activeSectionType) {
      return;
    }
    checkSandBoxCache(activeSubSectionId, queryData, loadSubSectionData);

    // if (isSearchValue || !filters.search || (query.search && filters.search !== query.search)) {
    //   dispatch(clearCounters());
    //   getSandBoxCountersPromise.current?.abort();
    //   getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));
    // }
    if (generalSearchValue !== filters.search) {
      setGeneralSearchValue(filters.search);
      setIsSearchValue(!!filters.search);
    }

    if (!isEqual({ ...queryData, filter_by: undefined }, { ...query, filter_by: undefined })) {
      // dispatch(clearCounters());
      getSandBoxCountersPromise.current?.abort();
      getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));
    }

    filters.search && setLocalStorageFiltersSearchValueAllTab(activeSubSectionId, filters.search);
  };

  const handleSort = (sorting: { order_by: string; order_dir: string }) => {
    setGeneralSorting({
      ...generalSorting,
      [activeSectionType]: sorting,
    });
  };

  const handleCollapsedBtn = (isCollapsed: boolean) => {
    setIsCollapsedBtn(isCollapsed);
  };

  const onChangeFiltersHeight = (height: number) => {
    setFilterHeight(height);
  };

  const handleResetFilters = () => {
    const isRequestTab = activeSectionType === 'request';
    const newFilterBy = activeSubSectionId;
    const resetInitiateType = isRequestTab ? 'need_form' : activeSectionType;
    const setSavedTypeFilters = getFilter(activeSectionType);
    setIsClickHeatmap(false);
    const newFilters = {
      filters: {},
      or_filters: {},
      conditions: {},
      or_conditions: {},
      switch_and: [],
    };

    let newQueryFilters;

    if (isRequestTab) {
      newQueryFilters = {
        ...newFilters,
      };
    } else {
      newQueryFilters = {
        deliverable_filters: { ...newFilters },
        submittal_input_filters: { ...newFilters },
        submittal_output_filters: { ...newFilters },
      };
    }
    setIsClickResetBtn(true);
    if (query.search) {
      hideSectionSearch(true, false);
    }
    setSavedTypeFilters(newFilters);

    const queryData = {
      ...query,
      ...newQueryFilters,
      search: '',
      order_by: '',
      order_dir: '',
      filter_by: newFilterBy,
      custom_filters: {},
      project_id: active_project_id,
    };

    setGeneralSearchValue('');
    setLocalStorageFiltersSearchValueAllTab(activeSubSectionId, '');

    setInitiateResetFilters({
      ...initiateResetFilters,
      [resetInitiateType]: true,
    });

    const isFiltersNotChanged = isEqual(queryData, query);

    if (isFiltersNotChanged) {
      return;
    }

    setGeneralSorting({
      ...generalSorting,
      [activeSectionType]: {
        order_by: '',
        order_dir: '',
      },
    });

    if (!isEmptyObject(query?.custom_filters)) {
      const AllSections = [...allSections];
      const newAllSections = AllSections.map(item => {
        const obj = { ...item };
        if (item.id === 'heatmap' || item.id === 'customSearch') {
          obj.isHideSection = true;
          obj.typeSystemButton = 'chevron-right';
        }
        if (item.id === 'inMyCourt') {
          obj.typeSystemButton = 'chevron-down';

          setUrlCommandCenterTab('request_inMyCourt_sentToMe');
        } else {
          obj.typeSystemButton = 'chevron-right';
        }
        return obj;
      });

      setAllSections(newAllSections);
      queryData.filter_by = 'request_inMyCourt_sentToMe';
    }

    if (query.search) {
      queryData.filter_by = 'request_inMyCourt_sentToMe';
    }

    setQuery({
      ...queryData,
    });

    getSandBoxDataPromise.current?.abort();
    getSandBoxDataPromise.current = dispatch(
      getSandBoxData({
        ...queryData,
        callbackNavigation: hash => {
          setIsHandleCallbackNavigation({ hash, isHandle: true });
        },
      }),
    );

    dispatch(clearCounters());
    getSandBoxCountersPromise.current?.abort();
    getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));
  };

  const openRequestHandler = (id: number, nf: number, accordionAlias?: string, isMarked?: boolean) => {
    const openTab = () => {
      if (activeSectionId === 'request_discussions') return 'discussion';

      if (activeSectionId === 'request_incorporation') return 'incorporation';

      return 'request';
    };

    setUrlNF(String(nf), openTab());
    dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
    dispatch(dispatch(setIsNavigateFromSandbox({ state: true, related: query.related_to })));

    if (isMarked && accordionAlias) {
      dispatch(
        markSandBoxItemAsViewed({
          accordion_alias: accordionAlias,
          id: id,
        }),
      );
    }

    setSelectedItem({ type: 'nf', id: id });

    dispatch(requestView({ id }));

    loadRequestPromise.current?.abort();
    loadRequestPromise.current = dispatch(singleRequestLoading({ requestId: id }));
  };

  const openDeliverableHandler = (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => {
    setUrlPCD(id, buildingId, activeSectionId === 'deliverable_discussions' ? 'discussion' : 'deliverable');
    setSelectedItem({ type: 'deliverable', id: id });

    dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));

    if (isMarked && accordionAlias) {
      dispatch(
        markSandBoxItemAsViewed({
          accordion_alias: accordionAlias,
          id: id,
        }),
      );
    }

    dispatch(dispatch(setIsNavigateFromSandbox({ state: true, related: query.related_to })));
    dispatch(
      setPcdOpenId({
        id,
        buildingId,
      }),
    );
  };

  const openSubmittalHandler = (id: string, accordionAlias?: string, isMarked?: boolean) => {
    setUrlSubmittal(id, 'general');
    setSelectedItem({ type: 'submittal', id: +id });

    dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));

    if (isMarked && accordionAlias) {
      dispatch(
        markSandBoxItemAsViewed({
          accordion_alias: accordionAlias,
          id: +id,
        }),
      );
    }

    dispatch(dispatch(setIsNavigateFromSandbox({ state: true, related: query.related_to })));
  };

  const handleDeactivate = () => {
    if (deactivationItem?.snippetData.nf) {
      dispatch(
        deactivatedStatus({
          request_id: deactivationItem.snippetData.id,
          action: deactivationItem.action,
          accordion: deactivationItem.accordion,
          callback: () => {
            getSandBoxCountersPromise.current?.abort();
            getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(query));
          },
        }),
      );
    }
    if (deactivationItem?.snippetData.sf_index) {
      const bodyParams: ISubmittalUpdateModel = {
        mf_id: deactivationItem.snippetData.mf_id,
        status: deactivationItem.action === 'deactivate' ? 'deactivated' : deactivationItem.snippetData?.status?.alias,
        type_id: deactivationItem.snippetData?.type.id,
      };
      dispatch(
        submittalSave({
          submittalId: deactivationItem.snippetData.id,
          bodyParams,
          isNotFormData: false,
          callback: () => {
            getSandBoxCountersPromise.current?.abort();
            getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(query));
          },
        }),
      );
    }
    setDeactivationItem(null);
    setIsDeactivationPopUpOpen(false);
  };

  const handleDeactivationPopUp = (
    snippetData: SandBoxNfSnippetModel | SubmittalModel,
    action: 'activate' | 'deactivate',
    accordion: string,
  ) => {
    setDeactivationItem({ action, snippetData, accordion });
    setIsDeactivationPopUpOpen(true);
  };

  const handleDeleteDraftPopUp = (nf: SandBoxNfSnippetModel, accordion: string) => {
    setNfToRemove({ nf, accordion });
    setIsOpenRemovePopUp(true);
  };

  const cancelDeactivatedStatus = e => {
    setIsDeactivationPopUpOpen(false);
    setDeactivationItem(null);
  };

  const closeDeliverableHandler = () => {
    setSelectedItem(null);
  };

  const closeRemoveNf = () => {
    setIsOpenRemovePopUp(false);
    setNfToRemove(null);
  };

  const acceptRemoveNf = () => {
    closeRemoveNf();
    dispatch(
      requestDelete({
        requestId: nfToRemove.nf.id,
        page: 'sandbox',
        accordion: nfToRemove.accordion,
        callback: () => {
          getSandBoxCountersPromise.current?.abort();
          getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(query));
        },
      }),
    );
  };

  const checkSandBoxCache = (sectionId, queryData, loadData) => {
    const section = sectionId.split('_')[0];

    if (queryData.related_to in sandBoxCache) {
      if (section in sandBoxCache[queryData.related_to]) {
        const isEqualQueryParams = isEqual(queryData, sandBoxCache[queryData.related_to][section][sectionId]?.queryData);

        if (sectionId in sandBoxCache[queryData.related_to][section] && isEqualQueryParams) {
          const timeNow = moment();
          const timeStamp = moment(sandBoxCache[queryData.related_to][section][sectionId].timeStamp);
          const difference = timeNow.diff(timeStamp, 'seconds');

          if (difference >= 600) {
            return loadData(queryData);
          } else {
            dispatch(setSandBoxItemsFromCache(sandBoxCache[queryData.related_to][section][sectionId].accordion_list));
            setQuery(queryData);
            const urlHash = sandBoxCache[queryData.related_to][section][sectionId]?.hash;
            setIsHandleCallbackNavigation({ hash: urlHash, isHandle: true });
            return;
          }
        }
      }
    }

    return loadData(queryData);
  };

  const ActiveSectionName = useMemo(() => {
    const activeSectionType = allSections.filter(item => item.sections.find(section => section.id === activeSectionId));
    return query.related_to === 'user' ? activeSectionType[0].title_to_me : activeSectionType[0].title_to_company;
  }, [activeSectionId]);

  const ActiveSubSectionName = useMemo(() => {
    const activeSectionType = allSections.filter(item => item.sections.find(section => section.id === activeSectionId));
    const activeSubSectionType = activeSectionType.flatMap(item => item.sections).find(section => section.id === activeSectionId);
    return activeSubSectionType.title;
  }, [activeSectionId]);

  const selectSection = (sectionId: string, isClick?: boolean, isReplace?: boolean) => {
    const all_Sections = allSections.flatMap(item => item.sections);
    const firstSubsectionId = all_Sections.find(section => section.id === sectionId)?.subSections?.[0].id || '';
    const newSectionType = getSectionType(sectionId);

    const sendData = {
      ...query,
      filter_by: firstSubsectionId,
      order_by: generalSorting[newSectionType].order_by,
      order_dir: generalSorting[newSectionType].order_dir,
    };

    setUrlCommandCenterTab(firstSubsectionId, isReplace);
    setGeneralSearchValue(query.search);

    if (!isClick) {
      checkSandBoxCache(firstSubsectionId, sendData, loadSubSectionData);
    }
  };

  const selectSubSection = (subSectionId: string, shouldSetSectionId?: boolean) => {
    const all_Sections = allSections.flatMap(item => item.sections);
    const sectionId = all_Sections.find(section => subSectionId.includes(section.id))?.id;
    const newSectionType = getSectionType(sectionId);

    const sendData = {
      ...query,
      filter_by: subSectionId,
      order_by: generalSorting[newSectionType].order_by,
      order_dir: generalSorting[newSectionType].order_dir,
    };

    setUrlCommandCenterTab(subSectionId);
    setGeneralSearchValue(query.search);

    checkSandBoxCache(subSectionId, sendData, loadSubSectionData);
  };

  const loadSubSectionData = (queryData: SandBoxQueryWithFilters) => {
    const { callback, ...data } = queryData;
    if (queryData?.project_id) {
      getSandBoxDataPromise.current?.abort();
      getSandBoxDataPromise.current = dispatch(
        getSandBoxData({
          ...queryData,
          callbackNavigation: hash => {
            setIsHandleCallbackNavigation({ hash, isHandle: true });
          },
        }),
      );
      setQuery({ ...data });
    }
  };

  const handleChangeAccordionCollapse = () => {
    const newCollapseState = !isAllAccCollapsed;

    const newAccordionStates = Object.keys(accordionStates).reduce((acc, accordionName) => {
      acc[accordionName] = !newCollapseState;

      return acc;
    }, {});

    setAccordionStates(newAccordionStates);
    setIsAllAccCollapsed(newCollapseState);
  };

  const handleChangeNfView = () => {
    setIsShortView(!isShortView);
    setClickShortView(true);
  };

  const handleSetRelatedTo = (relation: 'user' | 'party') => {
    const queryData = {
      ...query,
      related_to: relation,
    };

    dispatch(clearCounters());
    getSandBoxCountersPromise.current?.abort();
    getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));

    setQuery(queryData);
    localStorage.setItem(`${storageName}_related_to`, relation);

    checkSandBoxCache(queryData.filter_by, queryData, loadSubSectionData);
  };

  const handleSetCustomFilterTab = (
    tabs: SandBoxCustomTabsPayloadModel,
    type: 'request' | 'submittalInput' | 'deliverable' | 'submittalOutput',
    customFilters: CustomFiltersModel,
    searchValue?: string | undefined,
  ) => {
    const newSearchValue = searchValue !== undefined ? searchValue : customFilters[type].search || query.search;

    const filterAllSections =
      openedFrom == 'report' ? allSectionsReportApply(allSections) : formatAllSectionsByBelongingToPlanitCompany(allSections);
    const newAllSectionsReport = allSectionsReportApply(filterAllSections);

    const AllSections = openedFrom == 'report' ? allSectionsApplyCount(newAllSectionsReport) : allSectionsApplyCount(filterAllSections);

    const newAllSections = AllSections.map(item => {
      const obj = { ...item };
      if (item.id === 'heatmap') {
        obj.isHideSection = false;
        let indx = 0;
        if (type === 'request') {
          indx = 0;
        }
        if (type === 'submittalInput') {
          indx = 1;
        }
        if (type === 'deliverable') {
          indx = 2;
        }
        if (type === 'submittalOutput') {
          indx = 3;
        }
        selectSection(obj.sections[indx].id);
        obj.typeSystemButton = 'chevron-down';
      } else {
        obj.typeSystemButton = 'chevron-right';
      }
      return obj;
    });

    setAllSections(newAllSections);

    dispatch(
      setSandBoxTabsState({
        custom_tabs: {
          ...(sandBoxTabsState?.custom_tabs || {}),
          ...tabs,
        },
      }),
    );

    const newCustomFilters = {
      ...(query.custom_filters || {}),
      ...customFilters,
    };

    const queryData = {
      ...query,
      filter_by: searchValue !== '' ? tabs[type].subSections[0].id : activeSubSectionId,
      custom_filters: newCustomFilters,
      search: newSearchValue,
      project_id: active_project_id,
    };

    setIsHandleHeatMapUrl({ url: JSON.stringify(newCustomFilters), isHandle: true });
    setIsClickHeatmap(true);
    loadSubSectionData({
      ...queryData,
      callback: () => {
        // dispatch(clearCounters());
        getSandBoxCountersPromise.current?.abort();
        getSandBoxCountersPromise.current = dispatch(getSandBoxCounters(queryData));
      },
    });
  };

  const shouldShowResetButton = useMemo(() => {
    if (activeSectionType === 'request') {
      return (
        query?.search || !isEmptyObject(query?.filters || {}) || !isEmptyObject(query?.custom_filters?.request || {}) || showResetButtonNf
      );
    } else {
      return (
        query?.search ||
        !isEmptyObject(query?.deliverable_filters?.filters || {}) ||
        !isEmptyObject(query?.custom_filters?.deliverable || {}) ||
        !isEmptyObject(query?.custom_filters?.submittalInput || {}) ||
        !isEmptyObject(query?.custom_filters?.submittalOutput || {}) ||
        showResetButtonDeliverable
      );
    }
  }, [query, showResetButtonNf, showResetButtonDeliverable, activeSectionType]);

  const handleCloseSearchMobile = () => {
    setIsOpenSearchMobile(false);
  };

  const handleOpenSearchMobile = () => {
    setIsOpenSearchMobile(true);
  };

  const handleCloseRequestCustomWidget = () => {
    setIsOpenRequestCustomWidget(false);
  };

  const handleOpenRequestCustomWidget = () => {
    setIsOpenRequestCustomWidget(true);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleSetFocus = focusElement => {
    setFocusElement(focusElement);
  };

  const handleChangeDescription = (value: string) => {
    setDescription(value);
  };

  const onClickParentSection = (id: string) => {
    const AllSections = [...allSections];
    AllSections.forEach(item => {
      const obj = { ...item };
      if (item.id === id) {
        if (obj.typeSystemButton === 'chevron-right') {
          const sectionId = obj.sections[0].id;

          const all_Sections = allSections.flatMap(item => item.sections);
          const firstSubsectionId = all_Sections.find(section => section.id === sectionId)?.subSections?.[0].id || '';

          setUrlCommandCenterTab(firstSubsectionId);
        }
      }
    });
  };

  const onChangeParentSection = (id: string) => {
    const AllSections = [...allSections];
    const newAllSections = AllSections.map(item => {
      const obj = { ...item };
      if (item.id === id) {
        if (obj.typeSystemButton === 'chevron-down') {
          obj.typeSystemButton = 'chevron-right';
        } else {
          obj.typeSystemButton = 'chevron-down';
          selectSection(obj.sections[0].id, false, true);
        }
      } else {
        obj.typeSystemButton = 'chevron-right';
      }
      return obj;
    });
    setAllSections(newAllSections);
  };

  const hideSectionSearch = (hideSection: boolean, isCounter: boolean) => {
    if (openedFrom !== 'report') {
      const AllSections = [...allSections];
      const newAllSections = AllSections.map(item => {
        const obj = { ...item };
        if (item.id === 'customSearch') {
          if (isCounter) {
            obj.isHideSection = obj.count ? false : hideSection;
            obj.typeSystemButton = hideSection ? 'chevron-right' : obj.typeSystemButton;
          } else {
            obj.isHideSection = hideSection;
            obj.typeSystemButton = hideSection ? 'chevron-right' : 'chevron-down';
          }
        }
        if (item.id === 'inMyCourt' && hideSection && !isCounter) {
          obj.typeSystemButton = 'chevron-down';
          selectSection(obj.sections[0].id);
        } else {
          obj.typeSystemButton = 'chevron-right';
        }
        return obj;
      });
      setAllSections(newAllSections);
    }
  };

  const buttonType = useMemo(() => {
    if ((sizeResolution === '1024' || sizeResolution === '960' || sizeResolution === '640') && !isMobile) {
      if (isRightBtn) {
        return 'chevron-double-left';
      } else {
        return 'chevron-double-right';
      }
    } else {
      return '';
    }
  }, [isTablet, isRightBtn, sizeResolution]);

  const getDeactivationTitle = () => {
    if (deactivationItem?.snippetData.nf) {
      if (deactivationItem?.action === 'activate') {
        return `Activate NF# ${deactivationItem?.snippetData?.nf}?`;
      } else {
        return `Deactivate NF# ${deactivationItem?.snippetData?.nf}?`;
      }
    }
    if (deactivationItem?.snippetData.sf_index) {
      if (deactivationItem?.action === 'activate') {
        return `Activate Submittal ${deactivationItem?.snippetData?.sf_index}?`;
      } else {
        return `Deactivate Submittal ${deactivationItem?.snippetData?.sf_index}?`;
      }
    }
  };

  const getDeactivationModalText = () => {
    if (deactivationItem?.snippetData.nf) {
      if (deactivationItem?.action === 'activate') {
        return "This NF will become activated, you'll be able to deactivate it back.";
      } else {
        return "This NF will become deactivated, you'll be able to activate it back.";
      }
    }
    if (deactivationItem?.snippetData.sf_index) {
      if (deactivationItem?.action === 'activate') {
        return "This Submittal will become activated, you'll be able to deactivate it back.";
      } else {
        return "This Submittal will become deactivated, you'll be able to activate it back.";
      }
    }
  };

  const getActiveSectionName = () => {
    return (
      <div className={`${themeClass}_titleSectionContainer`}>
        <div className={`${themeClass}_titleSection`}>{ActiveSectionName}</div>
        <div className={`${themeClass}_indicator`}>
          <TokenIcon iconName={'ellipse-220'} size={4} />
        </div>
        <div className={`${themeClass}_titleSubSection`}>{ActiveSubSectionName}</div>
      </div>
    );
  };

  return (
    <>
      <div className={'b-sandBox'}>
        <div className={'b-sandBox__headerWrapper'}>
          <PageHeader
            ref={pageHeaderRef}
            isDivided
            isOpenSearchMobile={isOpenSearchMobile}
            customClass={'b-sandBox__headerWrapper_header'}
            title={openedFrom === 'report' ? headerName : 'Command center'}
            renderLeftSide={() => (
              <>
                {storageName.includes('sandbox') && !storageName.includes('report') && !isMobile && (
                  <SandBoxRelationSwitcher relatedTo={query.related_to} setRelation={handleSetRelatedTo} />
                )}
              </>
            )}
            customRightSideClass={`b-sandBox__headerWrapper_header_rightSide`}
            renderRightSide={() => (
              <>
                {false /*&& openedFrom !== 'report'*/ && (
                  <div className={`b-sandBox__headerWrapper_header_rightSide_textPlainBtn`}>
                    <CustomButton
                      type={'text-plain'}
                      size={'sm'}
                      clickHandler={handleOpenRequestCustomWidget}
                      title={'Request custom widget'}
                      isMobile={isMobile}
                    />
                  </div>
                )}
                {buttonType && openedFrom !== 'report' && (
                  <div className={`b-sandBox__headerWrapper_header_rightSide_chevronDoubleBtn`}>
                    <SystemButton
                      type={buttonType}
                      variant={'transparent'}
                      size={'md'}
                      clickHandler={() => handleRightBtn(buttonType === 'chevron-double-right')}
                    />
                  </div>
                )}
              </>
            )}
          />
          {((buttonType === 'chevron-double-right' && sizeResolution === '640') || isMobile) && (
            <>
              <div className={isMobile ? 'submenuSelect390' : 'submenuSelect640'}>
                <SubmenuSelect
                  allSections={allSections}
                  activeSectionId={activeSectionId}
                  selectSection={selectSection}
                  relatedTo={query.related_to}
                  isMobile={isMobile}
                  openedFrom={openedFrom}
                  report_user_id={report_user_id}
                />
                {isMobile && <SandBoxRelationSwitcher relatedTo={query.related_to} setRelation={handleSetRelatedTo} isMobile={isMobile} />}
              </div>
            </>
          )}
        </div>
        <div className={`${themeClass}_widgets`}>
          {(buttonType === 'chevron-double-right' ||
            (buttonType === '' && openedFrom !== 'report') ||
            (openedFrom === 'report' && reportingData?.header_data && !loadingTabel)) && (
            <>
              {/*{isSandBoxFirstLoading ? (*/}
              {/*  <CustomSkeleton*/}
              {/*    width={'100%'}*/}
              {/*    height={`calc(100svh - 48px - ${pageHeaderRef.current?.getBoundingClientRect()?.height}px - 20px)`}*/}
              {/*  />*/}
              {/*) : (*/}
              <div className={`${themeClass}_widgetSandbox`}>
                <PageHeader
                  ref={pageHeaderRef}
                  isDivided
                  isOpenSearchMobile={isOpenSearchMobile}
                  customClass={'b-sandBox__headerWrapper_headerSandBox'}
                  title={
                    openedFrom === 'report' ? (
                      headerName
                    ) : (
                      <div className={cn(`${themeClass}_titleSectionContainerPage`, { [`-collapsed`]: isCollapsedBtn })}>
                        <TokenIcon iconName={'flow'} size={16} customClass={`${themeClass}_iconFlow`} />
                        <div className={`${themeClass}_titleSectionPage`}>Flow</div>
                      </div>
                    )
                  }
                  renderLeftSide={() => (
                    <>
                      {activeSectionType === 'request' && (
                        <PageFilters
                          isNotDisplayed={activeSectionType !== 'request'}
                          storeFilterName={`${storageName}_need_form`}
                          filtersChangeHandler={(filters, type, isFirstRender) => onChangeFilters(filters, 'request', isFirstRender)}
                          resetFiltersHandler={handleResetFilters}
                          heightChangeHandler={onChangeFiltersHeight}
                          onChangeSorting={handleSort}
                          initiateResetFilters={initiateResetFilters.need_form}
                          setInitiateResetFilters={value => setInitiateResetFilters({ ...initiateResetFilters, need_form: value })}
                          filterSelectorGroups={needsListSelectorGroups}
                          sortingOptions={needsListSortingOptions}
                          groupingOptions={needsListGroupingOptions}
                          groupedByFilters={needsListGroupedByFilters}
                          codeSelectorHandlerType={'need-form'}
                          filterColumns={needList_columns}
                          setShowResetButton={value => setShowResetButtonNf(value)}
                          activeSandBoxSubSection={activeSubSection}
                          outerSearchValue={generalSearchValue}
                          setOuterSearchValue={setGeneralSearchValue}
                          showOrFilters
                          showSorting={!isMobile}
                          isOpenSearchMobile={isOpenSearchMobile}
                          handleOpenSearchMobile={handleOpenSearchMobile}
                          handleCloseSearchMobile={handleCloseSearchMobile}
                          handleResetSearch={handleResetFilters}
                          isCollapsedBtn={isCollapsedBtn}
                        />
                      )}
                      {activeSectionType === 'deliverable' && (
                        <PageFilters
                          isNotDisplayed={activeSectionType !== 'deliverable'}
                          storeFilterName={`${storageName}_deliverable`}
                          filtersChangeHandler={(filters, type, isFirstRender) => onChangeFilters(filters, 'deliverable', isFirstRender)}
                          resetFiltersHandler={handleResetFilters}
                          heightChangeHandler={onChangeFiltersHeight}
                          onChangeSorting={handleSort}
                          initiateResetFilters={initiateResetFilters.deliverable}
                          setInitiateResetFilters={value => setInitiateResetFilters({ ...initiateResetFilters, deliverable: value })}
                          filterSelectorGroups={deliverableSelectorGroups}
                          groupedByFilters={deliverableGroupedByFilters}
                          sortingOptions={deliverableSortingOptions}
                          codeSelectorHandlerType={'deliverable'}
                          filterColumns={pcd_columns}
                          selectedBuildingId={0}
                          setShowResetButton={value => setShowResetButtonDeliverable(value)}
                          activeSandBoxSubSection={activeSubSection}
                          outerSearchValue={generalSearchValue}
                          setOuterSearchValue={setGeneralSearchValue}
                          showOrFilters
                          showSorting={!isMobile}
                          isOpenSearchMobile={isOpenSearchMobile}
                          handleOpenSearchMobile={handleOpenSearchMobile}
                          handleCloseSearchMobile={handleCloseSearchMobile}
                          isCollapsedBtn={isCollapsedBtn}
                        />
                      )}
                      {activeSectionType === 'submittalInput' && (
                        <PageFilters
                          isNotDisplayed={activeSectionType !== 'submittalInput'}
                          storeFilterName={`${storageName}_submittal_form_input`}
                          filtersChangeHandler={(filters, type, isFirstRender) => onChangeFilters(filters, 'submittalInput', isFirstRender)}
                          resetFiltersHandler={handleResetFilters}
                          heightChangeHandler={onChangeFiltersHeight}
                          onChangeSorting={handleSort}
                          initiateResetFilters={initiateResetFilters.submittalInput}
                          setInitiateResetFilters={value => setInitiateResetFilters({ ...initiateResetFilters, submittalInput: value })}
                          filterSelectorGroups={submittalsSelectorGroups}
                          groupedByFilters={submittalGroupByFilters}
                          sortingOptions={submittalsSortingOptions}
                          codeSelectorHandlerType={'submittal-form'}
                          filterColumns={submittalsGridColumns}
                          selectedBuildingId={0}
                          setShowResetButton={value => setShowResetButtonDeliverable(value)}
                          activeSandBoxSubSection={activeSubSection}
                          outerSearchValue={generalSearchValue}
                          setOuterSearchValue={setGeneralSearchValue}
                          showOrFilters
                          showSorting={!isMobile}
                          isOpenSearchMobile={isOpenSearchMobile}
                          handleOpenSearchMobile={handleOpenSearchMobile}
                          handleCloseSearchMobile={handleCloseSearchMobile}
                          isCollapsedBtn={isCollapsedBtn}
                        />
                      )}
                      {activeSectionType === 'submittalOutput' && (
                        <PageFilters
                          isNotDisplayed={activeSectionType !== 'submittalOutput'}
                          storeFilterName={`${storageName}_submittal_form_output`}
                          filtersChangeHandler={(filters, type, isFirstRender) =>
                            onChangeFilters(filters, 'submittalOutput', isFirstRender)
                          }
                          resetFiltersHandler={handleResetFilters}
                          heightChangeHandler={onChangeFiltersHeight}
                          onChangeSorting={handleSort}
                          initiateResetFilters={initiateResetFilters.submittalOutput}
                          setInitiateResetFilters={value => setInitiateResetFilters({ ...initiateResetFilters, submittalOutput: value })}
                          filterSelectorGroups={submittalsSelectorGroups}
                          groupedByFilters={submittalGroupByFilters}
                          sortingOptions={submittalsSortingOptions}
                          codeSelectorHandlerType={'submittal-form'}
                          filterColumns={submittalsGridColumns}
                          selectedBuildingId={0}
                          setShowResetButton={value => setShowResetButtonDeliverable(value)}
                          activeSandBoxSubSection={activeSubSection}
                          outerSearchValue={generalSearchValue}
                          setOuterSearchValue={setGeneralSearchValue}
                          showOrFilters
                          showSorting={!isMobile}
                          isOpenSearchMobile={isOpenSearchMobile}
                          handleOpenSearchMobile={handleOpenSearchMobile}
                          handleCloseSearchMobile={handleCloseSearchMobile}
                          isCollapsedBtn={isCollapsedBtn}
                        />
                      )}
                    </>
                  )}
                  customRightSideClass={`b-sandBox__headerWrapper_headerSandBox_rightSide`}
                  renderRightSide={() => (
                    <>
                      {isCollapsedBtn && getActiveSectionName()}
                      {shouldShowResetButton && !isMobile ? (
                        <div className={`b-sandBox__headerWrapper_headerSandBox_rightSide_resetBtn`}>
                          <CustomButton
                            type={'tertiary'}
                            size={'sm'}
                            title={'Reset'}
                            clickHandler={() => {
                              handleResetFilters();
                              setIsHandleHeatMapUrl({ url: null, isHandle: true });
                            }}
                          />
                        </div>
                      ) : null}
                      {false && (
                        <div className={`b-sandBox__headerWrapper_headerSandBox_rightSide_infoBtn`}>
                          <SystemButton tooltip={''} type={'information'} size={'md'} variant={'transparent'} />
                        </div>
                      )}
                    </>
                  )}
                />
                <div className={'b-sandBox__mainContainer'}>
                  {sizeResolution !== '640' && !isMobile && (
                    <SubmenuSectionsNew
                      allSections={allSections}
                      activeSectionId={activeSectionId}
                      selectSection={selectSection}
                      openedFrom={openedFrom}
                      relatedTo={query.related_to}
                      report_user_id={report_user_id}
                      onClickParentSection={onClickParentSection}
                      isCollapsedBtn={isCollapsedBtn}
                      handleCollapsedBtn={handleCollapsedBtn}
                    />
                  )}
                  <div className={'b-sandBox__mainContainer_subContainer'}>
                    {((sizeResolution !== '1024' && sizeResolution !== '960' && sizeResolution !== '640' && !isMobile) ||
                      (sizeResolution === '1024' && !isRightBtn) ||
                      (sizeResolution === '960' && !isRightBtn) ||
                      (sizeResolution === '640' && !isRightBtn) ||
                      (isMobile && !isRightBtn)) && (
                      <RequestsList
                        activeSection={activeSection}
                        selectSubSection={selectSubSection}
                        selectedSubSection={activeSubSectionId}
                        changeAccordionCollapse={handleChangeAccordionCollapse}
                        setIsAllAccCollapsed={setIsAllAccCollapsed}
                        isAllAccCollapsed={isAllAccCollapsed}
                        changeNfView={handleChangeNfView}
                        isShortView={isShortView}
                        filterHeight={filterHeight}
                        openRequest={openRequestHandler}
                        openDeliverable={openDeliverableHandler}
                        openSubmittal={openSubmittalHandler}
                        accordionStates={accordionStates}
                        accordionRefs={accordionRefs}
                        handleAccordion={handleAccordion}
                        handleDeactivationPopUp={handleDeactivationPopUp}
                        handleDeleteDraftPopUp={handleDeleteDraftPopUp}
                        relatedTo={query.related_to}
                        openedFrom={openedFrom}
                        report_user_id={report_user_id}
                        activeSectionType={activeSectionType}
                        userInfo={userInfo}
                        sizeResolution={sizeResolution}
                        is1100Resolution={is1100Resolution}
                        isTablet={openedFrom !== 'report' && !isMobile ? isTablet : null}
                        isRightBtn={openedFrom !== 'report' ? isRightBtn : null}
                        handleRightBtn={openedFrom !== 'report' ? handleRightBtn : null}
                        sizeResolutionSnippet={sizeResolutionSnippet}
                        isMobile={isMobile}
                        pageHeaderRef={pageHeaderRef}
                        isCollapsedBtn={isCollapsedBtn}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/*)}*/}
            </>
          )}
          {openedFrom !== 'report' && (isRightBtn || sizeResolution === '1920') && !isMobile ? (
            <SandBoxGraphs
              filterHeight={filterHeight}
              relatedTo={query.related_to}
              openRequest={openRequestHandler}
              openDeliverable={openDeliverableHandler}
              openSubmittal={openSubmittalHandler}
              selectSubSection={selectSubSection}
              activeSectionId={activeSectionId}
              setCustomFilterTab={handleSetCustomFilterTab}
              sizeResolution={sizeResolution}
              isMobile={isMobile}
              pageHeaderRef={pageHeaderRef}
              user_id={userInfo?.id}
            />
          ) : null}
          {isOpenRemovePopup ? (
            <DialogPopUp
              open={isOpenRemovePopup}
              onClose={closeRemoveNf}
              isOverlay={true}
              title={`Delete draft NF# ${nfToRemove?.nf.nf}?`}
              modalText={'The NF will no longer be listed in the Sand Box and its running number will be skipped.'}
              customSecondaryType={'tertiary'}
              tertiaryText={'Cancel'}
              handleOnTertiary={closeRemoveNf}
              primaryText={'Yes, delete'}
              handleOnPrimary={acceptRemoveNf}
            />
          ) : null}
          <DialogPopUp
            open={isDeactivationPopUpOpen}
            onClose={cancelDeactivatedStatus}
            isOverlay={true}
            title={getDeactivationTitle()}
            modalText={getDeactivationModalText()}
            customSecondaryType={'tertiary'}
            tertiaryText={'Cancel'}
            handleOnTertiary={cancelDeactivatedStatus}
            primaryText={deactivationItem?.action === 'activate' ? 'Yes, activate' : 'Yes, deactivate'}
            handleOnPrimary={handleDeactivate}
          />
          {isOpenRequestCustomWidget && (
            <DialogPopUp isCard open={isOpenRequestCustomWidget} onClose={handleCloseRequestCustomWidget} isOverlay={true}>
              <div className={`${themeClassPopUpRequestCustomWidget}`}>
                <div className={`${themeClassPopUpRequestCustomWidget}_header`}>
                  <div className={`${themeClassPopUpRequestCustomWidget}_header_title`}>Request custom widget</div>
                  <div className={`${themeClassPopUpRequestCustomWidget}_header_closeIcon`} onClick={handleCloseRequestCustomWidget}>
                    <SystemButton type={'close'} size={'lg'} variant={'transparent'} isMobile={isMobile} />
                  </div>
                </div>
                <div className={`${themeClassPopUpRequestCustomWidget}_container`}>
                  <div className={`${themeClassPopUpRequestCustomWidget}_container_title`}>
                    Place for important messages. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua.
                  </div>
                  <div className={`${themeClassPopUpRequestCustomWidget}_container_video`}>
                    <video width="100%" height="auto" src={null} controls>
                      <p>Your browser doesn't support HTML5 video.</p>
                    </video>
                  </div>
                  <div className={`${themeClassPopUpRequestCustomWidget}_container_footer`}>
                    <div className={`${themeClassPopUpRequestCustomWidget}_container_footer_email`}>
                      <TextInputNew
                        value={email}
                        type={'on-bgd'}
                        size={'md'}
                        onChange={handleChangeEmail}
                        isFocus={focusElement === 'Email'}
                        onFocus={() => handleSetFocus('Email')}
                        onBlur={() => handleSetFocus('')}
                        placeholder={`Your email`}
                      />
                    </div>
                    <div className={`${themeClassPopUpRequestCustomWidget}_container_footer_text_container`}>
                      <TextQuillEditor
                        fieldId={'test1'}
                        showToolbar
                        value={description || ''}
                        handleChange={handleChangeDescription}
                        placeholder={'Describe your proposition'}
                        disabled={false}
                      />
                    </div>
                    <div className={`${themeClassPopUpRequestCustomWidget}_container_footer_button_group`}>
                      <div className={`${themeClassPopUpRequestCustomWidget}_container_footer_button_group_addFilesBtn`}>
                        <CustomButton
                          type={'text-plain'}
                          size={'md'}
                          title={'Add files'}
                          icon={<TokenIcon iconName={'plus'} size={12} />}
                          iconClass={`${themeClassPopUpRequestCustomWidget}_container_footer_button_group_addFilesBtn_addIcon`}
                        />
                      </div>
                      <div className={`${themeClassPopUpRequestCustomWidget}_container_footer_button_group_right_actions`}>
                        <CustomButton type={'tertiary'} size={'md'} clickHandler={handleCloseRequestCustomWidget} title={'Cancel'} />
                        <CustomButton type={'primary'} size={'md'} clickHandler={null} title={'Send request'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPopUp>
          )}
        </div>
      </div>
    </>
  );
};

export default SandBox;
