import { IncorporationTaskModel } from '../store/incorporation/incorporationReducer.model';

export const globalIncorporationStatusText = (incorporationList: IncorporationTaskModel[]) => {
  if (!incorporationList || incorporationList.length === 0) {
    return { status: '', text: '' };
  }

  const statuses = incorporationList.map(item => item.status);

  const statusTexts = {
    not_required: 'Incorporation not required!',
    not_started: 'Incorporation process has not yet started!',
    in_progress: 'Some incorporation tasks have not yet been finished!',
    completed: 'All incorporation tasks have been finished!',
  };

  const allSameStatus = (status: string) => statuses.every(item => item === status);

  if (allSameStatus('not_started')) {
    return { status: 'not_started', text: statusTexts.not_started };
  } else if (allSameStatus('not_required')) {
    return { status: 'not_required', text: statusTexts.not_required };
  } else if (
    allSameStatus('completed') ||
    (statuses.includes('completed') && !statuses.includes('in_progress') && !statuses.includes('not_started'))
  ) {
    return { status: 'completed', text: statusTexts.completed };
  } else if (statuses.includes('not_started') || statuses.includes('in_progress')) {
    return { status: 'in_progress', text: statusTexts.in_progress };
  }

  return { status: '', text: '' };
};
