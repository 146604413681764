import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAsideReducer } from './asideReducer.model';

const initialState: IAsideReducer = {
  productTourIndex: null,
  isAsideMenuOpen: false,
  activeModule: '',
  isAsideMobileOpen: false,
};

export const asideSlice = createSlice({
  initialState,
  name: 'asideReducer',
  reducers: {
    setProductTourIndex(state, { payload }: PayloadAction<null | number>) {
      state.productTourIndex = payload;
    },
    setAsideMenuState(state, { payload }: PayloadAction<boolean>) {
      state.isAsideMenuOpen = payload;
    },
    setActiveModule(state, { payload }: PayloadAction<{ module: string }>) {
      state.activeModule = payload.module;
    },
    setAsideMenuMobileState(state, { payload }: PayloadAction<boolean>) {
      state.isAsideMobileOpen = payload;
    },
  },
});

export default asideSlice.reducer;
