import React, { useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { DOMAIN_KPI_URL, DOMAIN_SELF_URL } from '../../../../../service/links';
import Popover from '@material-ui/core/Popover';
import { IClientModel } from '../../../../../store/user/userReducerModel';
import { handleGetThemeClass } from '../../../../../helpers/designTokens';
import * as ROUTES from '../../../../../navigation/routes';
import { Link } from 'react-router-dom';
import { logout } from '../../../../../store/user/userThunk';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';

import './OverflowMenuStyles.scss';
import useRouter from '../../../../../hooks/useRouter/useRouter';

interface IProps {
  containerClassName: string;
  currentClient: IClientModel;
  openSupport: () => void;
  isAdmin: boolean;
  activePage: string;
}

const OverflowMenu = ({ containerClassName, currentClient, openSupport, isAdmin, activePage }: IProps) => {
  const dispatch = useAppDispatch();
  const { navigate } = useRouter();

  const buttonRef = useRef();

  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');
  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const unReadNotificationCount = useAppSelector(state => state.notificationsReducer.unReadNotificationCount);

  const projectName = sessionStorage.getItem('project_name');
  const themeClass = handleGetThemeClass('b-domains', theme);

  const appLogoSvg = useMemo(() => {
    return <SVG src={require(`../../../../../assets/icons/planit_logo_${theme}.svg`)} alt="site-logo" />;
  }, [theme]);

  const kpiLogoSvg = useMemo(() => {
    return <SVG src={require(`../../../../../assets/icons/kpi_logo_${theme}.svg`)} alt="site-logo" />;
  }, [theme]);

  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);

  const handleClickOnItem = (type: 'app' | 'kpi' | 'activity' | 'support' | 'logout') => {
    if (type === 'support') {
      openSupport();
    }

    if (type === 'logout') {
      dispatch(
        logout({
          callback: () => {
            navigate('/');
          },
        }),
      );
    }

    setIsOverflowMenuOpen(false);
  };

  const showBadge = useMemo(() => {
    return (userInfo?.whats_new_unread || unReadNotificationCount) && userInfo?.projects?.length && window.innerWidth <= 640;
  }, [userInfo, unReadNotificationCount]);

  return (
    <>
      <CustomTooltip title={'More'} placement={'bottom'}>
        <div ref={buttonRef} className={containerClassName} onClick={() => setIsOverflowMenuOpen(true)}>
          <div className={`${containerClassName}_iconCont`}>
            <TokenIcon iconName={'apps-menu'} size={24} />
            {showBadge ? <div className={`${containerClassName}_iconCont_notification`} /> : null}
          </div>
        </div>
      </CustomTooltip>
      <Popover
        id={'domain-popover'}
        open={!!isOverflowMenuOpen}
        onClose={() => setIsOverflowMenuOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={themeClass}>
          <div className={`${themeClass}_moduleLinks`}>
            <a onClick={() => handleClickOnItem('app')} href={`${DOMAIN_SELF_URL}/${projectName}`} target="_blank" rel="noreferrer">
              <div className={`${themeClass}_moduleLinks_linkItem`}>
                <div className={`${themeClass}_moduleLinks_linkItem_container`}>
                  <div className={`${themeClass}_moduleLinks_linkItem_container_icon`}>{appLogoSvg}</div>
                  <div className={`${themeClass}_moduleLinks_linkItem_container_text`}>
                    <span>Scheduler</span>
                  </div>
                </div>
              </div>
            </a>
            <a
              href={`${DOMAIN_KPI_URL}/${projectName}`}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleClickOnItem('kpi')}
              className={`item`}
            >
              <div className={`${themeClass}_moduleLinks_linkItem`}>
                <div className={`${themeClass}_moduleLinks_linkItem_container`}>
                  <div className={`${themeClass}_moduleLinks_linkItem_container_icon`}>{kpiLogoSvg}</div>
                  <div className={`${themeClass}_moduleLinks_linkItem_container_text`}>
                    <span>KPI</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {window.innerWidth <= 640 && (
            <>
              <div className={`${themeClass}_divider`} />
              <div className={`${themeClass}_utilities`}>
                {isAdmin && userInfo?.projects?.length ? (
                  <Link onClick={() => handleClickOnItem('activity')} to={ROUTES.getNotificationsList(projectName)}>
                    <div
                      className={`${themeClass}_utilities_linkItem ${
                        activePage === 'notifications-list' && userInfo?.projects?.length ? '-active' : ''
                      } `}
                    >
                      <div className={`${themeClass}_utilities_linkItem_container`}>
                        <div className={`${themeClass}_utilities_linkItem_container_icon`}>
                          <SVG src={require('../../../../../assets/icons/new_activity_icon.svg')} />
                        </div>
                        <div className={`${themeClass}_utilities_linkItem_container_text`}>
                          <span>Activity</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : null}
                <div onClick={() => handleClickOnItem('support')} className={`${themeClass}_utilities_linkItem`}>
                  <div className={`${themeClass}_utilities_linkItem_container`}>
                    <div className={`${themeClass}_utilities_linkItem_container_icon`}>
                      <SVG src={require('../../../../../assets/icons/new_support_icon.svg')} />
                    </div>
                    <div className={`${themeClass}_utilities_linkItem_container_text`}>
                      <span>Support</span>
                    </div>
                  </div>
                </div>
                <Link to={''} onClick={() => handleClickOnItem('logout')}>
                  <div className={`${themeClass}_utilities_linkItem`}>
                    <div className={`${themeClass}_utilities_linkItem_container`}>
                      <div className={`${themeClass}_utilities_linkItem_container_icon`}>
                        <SVG src={require('../../../../../assets/icons/new-logout.svg')} />
                      </div>
                      <div className={`${themeClass}_utilities_linkItem_container_text`}>
                        <span>Logout</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default OverflowMenu;
