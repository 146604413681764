import { createSelector } from 'reselect';

// import { RootStateGlobal } from '../configure/configureStore';

export const getLoaderMembers = state => state.team.members.isLoading;

export const getFilters = state => state.team.filters;

export const getMembers = state => state.team.members.data;

export const getMember = (id: number) =>
  createSelector([getMembers], members => (members?.length && members.find(member => member.id === id)) || null);

export const getRoles = state => state.team.roles;

export const getAddedPartyId = state => state.team.addedPartyId;

export const getDisciplines = state => state.team.disciplines;

export const getLoaderParty = state => state.team.isLoadingParty;

export const getLoaderDisciplinesRolesData = state => state.team.isLoadingDisciplinesRoles;

export const getCountNfsForParty = state => state.team.nfCountForParties;

export const getUsersTeam = state => state.team.usersTeam;

export const getUpdatingPartyUsers = state => state.team.isUpdatePartyUsers;

export const getLoaderPartyUsers = state => state.team.isLoadingPartyUsers;

export const getLoaderGetPartyUsers = state => state.team.isLoadingGetPartyUsers;

export const getSetContactPoint = state => state.team.isSetContactPoint;

export const getLink_UnlinkPartyUsers = state => state.team.isLink_UnlinkPartyUsers;

export const getErrorsTeamTab = state => state.team.errorsTeamTab;

export const getListSubdivision = state => state.team.subdivisions;

export const getLoaderParties = state => state.team.parties.isLoading;

export const standardTemplateCreating = state => state.team.isStandardTemplateCreating;

const _getParties = state => state.team.parties.data;

export const getParties = createSelector([getMembers, _getParties], (members, parties) => {
  if (parties?.length && members?.length) {
    return parties.filter(party => members?.length && members.findIndex(member => member.id === party.id) == -1);
  } else {
    return parties;
  }
});

export const getAddPartyLoading = state => state.team.isLoadingAddParty;
