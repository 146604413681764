import { useLocation, Location, useNavigate, NavigateFunction, useParams, Params, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

interface ReturnProps {
  urlProject: string[];
  location: Location;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
  searchParams: URLSearchParams;
}

export default function useRouter(): ReturnProps {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const urlProject = useMemo(() => {
    return location.pathname.split('/').filter(f => !!f);
  }, [location.pathname]);

  return { location, urlProject, navigate, params, searchParams };
}
