import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { INfUseResponseProps } from './NfUseResponse.props';
import CheckBox from '../../../../controls/CheckBox/CheckBox';

export const NfUseResponse: React.FC<INfUseResponseProps> = ({ handleUseResponseComment, comment, isAccessNfStatus }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!comment.is_response);
  }, [comment.is_response]);

  const handleChangeUseResponse = () => {
    setChecked(!checked);
    handleUseResponseComment(comment.id);
  };

  return (
    <>
      <CheckBox disabled={!isAccessNfStatus} checked={checked} onClick={handleChangeUseResponse} />
      <span
        style={isAccessNfStatus ? { cursor: 'pointer' } : { cursor: 'default' }}
        onClick={isAccessNfStatus ? handleChangeUseResponse : null}
      >
        Use{comment.is_response ? 'd' : ''} as NF response
      </span>
    </>
  );
};
