import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { GetSelectDataResponse, InitPhasePlanDataModel, IPhasePlanReducer } from './phasePlanReducer.model';
import Api from '../../service/api';
import {
  BuildingSaveFormApi,
  CreateUpdateBuildingResponse,
  DeletePhasePlanFieldResponseModel,
  GetPhasePlanResponseModel,
  GetProjectById,
  PermissionModel,
  PhasePlanChangeTablePayloadModel,
  PhasePlanFieldResponseModel,
  PhasePlanModel,
  ProjectCreateResponseModel,
  ProjectModel,
  ScheduleModel,
  SelectDataModel,
  SetDriverTasksResponseModel,
  UpdateBuildingLocationsPayloadModel,
  UpdateBuildingLocationsResponse,
} from '../../models';
import {
  createBuilding,
  createPhasePlanField,
  createProject,
  deleteBuilding,
  deletePhasePlanField,
  getPhasePlan,
  getPhasePlanSelectData,
  getPrevPhasePlan,
  getProjectById,
  savePhasePlanVersion,
  setDriverTasks,
  updateBuilding,
  updateBuildingLocation,
  updateGMP,
  updatePhasePlan,
  updatePhasePlanField,
  updateProject,
} from './phasePlanThunk';
import PhasePlanHelper from '../../helpers/PhasePlanHelper';
import { ErrorResponseModel } from '../../models/Api/response.model';

const initialState: IPhasePlanReducer = {
  getPhasePlanSelectStatus: Api.initialStatus,
  getProjectStatus: Api.initialStatus,
  createProjectStatus: Api.initialStatus,
  updateProjectStatus: Api.initialStatus,
  createBuildingStatus: Api.initialStatus,
  updateBuildingStatus: Api.initialStatus,
  deleteBuildingStatus: Api.initialStatus,
  updateLocationBuildingStatus: Api.initialStatus,
  getStep2Status: Api.initialStatus,
  getStep3Status: Api.initialStatus,
  updateStep2Status: Api.initialStatus,
  createCompanyStatus: Api.initialStatus,
  createDisciplineStatus: Api.initialStatus,
  addDisciplineStatus: Api.initialStatus,
  removeCompanyStatus: Api.initialStatus,
  addCompanyStatus: Api.initialStatus,
  removeDisciplineStatus: Api.initialStatus,
  updatePhasePlanStatus: Api.initialStatus,
  createMemberStatus: Api.initialStatus,
  unlinkMemberStatus: Api.initialStatus,
  phasePlanVersionStatus: Api.initialStatus,
  phasePlanDownloadStatus: Api.initialStatus,
  phasePlanPrevStatus: Api.initialStatus,
  phasePlanFieldStatus: Api.initialStatus,
  getPhasePlanStatus: Api.initialStatus,
  phasePlanUpdateFieldStatus: Api.initialStatus,
  updatePartyRoleStatus: Api.initialStatus,
  updateStep3Status: Api.initialStatus,
  phasePlanSelect: {} as SelectDataModel,
  projectData: {} as ProjectModel,
  projectErrors: {},
  buildingsErrors: {},
  step2Data: [],
  step3Data: [],
  phasePlan: {} as PhasePlanModel,
  errorsMember: null,
  buildingId: null,
  deleteBuildingRequest: {} as any,
  phasePlanErrors: {},
  getPhasePlanError: null,
  initData: {} as InitPhasePlanDataModel,
  actionFields: [],
  currentStateIndex: 0,
  activeTabProject: 0,
  isCreateProject: false,
  createStep3Errors: {},
  updateStep2Errors: {},
};

export const phasePlanSlice = createSlice({
  initialState,
  name: 'phasePlanReducer',
  reducers: {
    resetBuildingErrors(state) {
      state.deleteBuildingStatus = Api.successStatus;
      state.buildingsErrors = {};
    },
    phasePlanChangeTable(state, { payload }: PayloadAction<PhasePlanChangeTablePayloadModel>) {
      const phasePlan = { ...state.phasePlan };

      phasePlan[payload.table] = payload.data;

      state.phasePlan = phasePlan;
    },
    clearProjectData(state) {
      state.projectData = {
        ...initialState.projectData,
      };
    },
    setActiveTabProject(state, { payload }: PayloadAction<number>) {
      state.activeTabProject = payload;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getPhasePlanSelectData
       */
      .addCase(getPhasePlanSelectData.pending, state => {
        state.getPhasePlanSelectStatus = Api.requestStatus;
      })
      .addCase(getPhasePlanSelectData.fulfilled, (state, { payload }: PayloadAction<GetSelectDataResponse>) => {
        state.getPhasePlanSelectStatus = Api.successStatus;
        state.phasePlanSelect = payload.data.data;
      })
      .addCase(getPhasePlanSelectData.rejected, (state, { payload }: any) => {
        state.getPhasePlanSelectStatus = Api.failStatus;
      })
      /**
       * createProject
       */
      .addCase(createProject.pending, state => {
        state.createProjectStatus = Api.requestStatus;
      })
      .addCase(createProject.fulfilled, (state, { payload }: PayloadAction<ProjectCreateResponseModel>) => {
        state.createProjectStatus = Api.successStatus;
        state.projectData = payload.data;
        state.isCreateProject = true;
      })
      .addCase(createProject.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.createProjectStatus = Api.failStatus;
        state.projectErrors = payload.data.errors;
      })
      /**
       * createProject
       */
      .addCase(updateProject.pending, state => {
        state.updateProjectStatus = Api.requestStatus;
      })
      .addCase(updateProject.fulfilled, (state, { payload }: PayloadAction<ProjectCreateResponseModel>) => {
        state.updateProjectStatus = Api.successStatus;
        state.projectData = payload.data;
      })
      .addCase(updateProject.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.updateProjectStatus = Api.failStatus;
        state.projectErrors = payload.data.errors;
      })
      /**
       * getProjectById
       */
      .addCase(getProjectById.pending, state => {
        state.getProjectStatus = Api.requestStatus;
        state.projectData = {} as ProjectModel;
      })
      .addCase(getProjectById.fulfilled, (state, { payload }: PayloadAction<GetProjectById>) => {
        state.getProjectStatus = Api.successStatus;
        state.projectData = payload.data?.data;
      })
      .addCase(getProjectById.rejected, (state, { payload }: any) => {
        state.getProjectStatus = Api.failStatus;
        state.projectErrors = payload?.data?.errors;
      })
      /**
       * createBuilding
       */
      .addCase(createBuilding.pending, (state, { meta }: PayloadAction<null, string, { arg: BuildingSaveFormApi }>) => {
        state.createBuildingStatus = Api.requestStatus;
        state.buildingId = meta.arg.id;
        state.buildingsErrors = {};
      })
      .addCase(createBuilding.fulfilled, (state, { payload }: PayloadAction<CreateUpdateBuildingResponse>) => {
        const projectData = { ...state.projectData };
        projectData.buildings.push(payload.data);

        state.createBuildingStatus = Api.successStatus;
        state.buildingId = null;
        state.projectData = projectData;
      })
      .addCase(createBuilding.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.createBuildingStatus = Api.failStatus;
        state.buildingId = null;
        state.projectErrors = {
          [state.buildingId]: {
            errors: payload.data.message,
          },
        };
      })
      /**
       * updateBuilding
       */
      .addCase(updateBuilding.pending, state => {
        state.updateBuildingStatus = Api.requestStatus;
        state.buildingsErrors = {};
      })
      .addCase(updateBuilding.fulfilled, (state, { payload }: PayloadAction<CreateUpdateBuildingResponse>) => {
        const projectData = { ...state.projectData } as ProjectModel;

        if (projectData.buildings?.length) {
          const changedBuildingIndex = projectData.buildings.findIndex(b => b.id === payload.data.id);
          if (changedBuildingIndex + 1 > 0) {
            projectData.buildings[changedBuildingIndex] = payload.data;
          }
        } else {
          projectData.buildings = [payload.data];
        }

        state.updateBuildingStatus = Api.successStatus;
        state.projectData = projectData;
      })
      .addCase(updateBuilding.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.updateBuildingStatus = Api.failStatus;
        state.projectErrors = {
          [state.buildingId]: {
            errors: payload.data.message,
          },
        };
      })
      /**
       * deleteBuilding
       */
      .addCase(
        deleteBuilding.pending,
        (state, { meta }: PayloadAction<number, string, { arg: { buildingId: number; projectId: number } }>) => {
          state.deleteBuildingStatus = Api.requestStatus;
          state.buildingsErrors = {};
          state.deleteBuildingRequest = meta.arg.buildingId;
        },
      )
      .addCase(deleteBuilding.fulfilled, state => {
        const projectData = { ...state.projectData };
        const building = projectData.buildings.findIndex(b => b.id === state.deleteBuildingRequest);
        if (building + 1 > 0) {
          projectData.buildings.splice(building, 1);
        }

        state.deleteBuildingStatus = Api.successStatus;
        state.deleteBuildingRequest = null;
        state.projectData = projectData;
      })
      .addCase(deleteBuilding.rejected, (state, { payload }: PayloadAction<ErrorResponseModel>) => {
        state.deleteBuildingStatus = Api.failStatus;
      })
      /**
       * updateBuildingLocation
       */
      .addCase(
        updateBuildingLocation.pending,
        (state, { meta }: PayloadAction<null, string, { arg: UpdateBuildingLocationsPayloadModel }>) => {
          state.updateLocationBuildingStatus = Api.requestStatus;
          state.buildingId = meta.arg.building_id;
        },
      )
      .addCase(updateBuildingLocation.fulfilled, (state, { payload }: PayloadAction<UpdateBuildingLocationsResponse>) => {
        const projectData = { ...state.projectData };

        const changedBuildingIndex = projectData.buildings.findIndex(b => b.id === payload.data.data.id);
        projectData.buildings[changedBuildingIndex] = payload.data.data;

        state.updateLocationBuildingStatus = Api.successStatus;
        state.projectData = projectData;
      })
      .addCase(updateBuildingLocation.rejected, (state, { payload }: any) => {
        state.updateLocationBuildingStatus = Api.failStatus;
      })
      /**
       * getPhasePlan
       */
      .addCase(getPhasePlan.pending, state => {
        state.getPhasePlanStatus = Api.requestStatus;
        state.phasePlanErrors = {};
      })
      .addCase(getPhasePlan.fulfilled, (state, { payload }: PayloadAction<GetPhasePlanResponseModel>) => {
        const permissions = payload.data.permissions.map(s => {
          return { ...s };
        });
        const schedules = payload.data.schedules.map(s => {
          return { ...s };
        });

        state.getPhasePlanStatus = Api.successStatus;
        state.phasePlan = payload.data;
        state.initData = { permissions, schedules };
      })
      .addCase(getPhasePlan.rejected, (state, { payload }: any) => {
        state.phasePlan = {
          ...initialState.phasePlan,
        };
        state.getPhasePlanStatus = Api.failStatus;
        state.getPhasePlanError = payload.data;
      })
      /**
       * updatePhasePlan
       */
      .addCase(updatePhasePlan.pending, state => {
        state.getPhasePlanStatus = Api.requestStatus;
        state.updatePhasePlanStatus = Api.requestStatus;
        state.phasePlanErrors = {};
      })
      .addCase(updatePhasePlan.fulfilled, (state, { payload }: PayloadAction<GetPhasePlanResponseModel>) => {
        const permissions = payload.data.permissions.map(s => {
          return { ...s };
        });
        const schedules = payload.data.schedules.map(s => {
          return { ...s };
        });

        state.getPhasePlanStatus = Api.successStatus;
        state.updatePhasePlanStatus = Api.successStatus;
        state.phasePlan = payload.data;
        state.initData = { permissions, schedules };
      })
      .addCase(updatePhasePlan.rejected, (state, { payload }: any) => {
        state.phasePlan = {
          ...initialState.phasePlan,
        };
        state.getPhasePlanStatus = Api.failStatus;
        state.updatePhasePlanStatus = Api.failStatus;
        state.getPhasePlanError = payload.data;
      })
      /**
       * savePhasePlanVersion
       */
      .addCase(savePhasePlanVersion.pending, state => {
        state.phasePlanVersionStatus = Api.requestStatus;
      })
      .addCase(savePhasePlanVersion.fulfilled, (state, { payload }: PayloadAction<GetPhasePlanResponseModel>) => {
        state.phasePlanVersionStatus = Api.successStatus;
        state.phasePlan = payload.data;
      })
      .addCase(savePhasePlanVersion.rejected, (state, { payload }: any) => {
        state.phasePlanVersionStatus = Api.failStatus;
      })
      /**
       * createPhasePlanField
       */
      .addCase(createPhasePlanField.pending, state => {
        state.phasePlanFieldStatus = Api.requestStatus;
      })
      .addCase(
        createPhasePlanField.fulfilled,
        (state, { payload }: PayloadAction<PhasePlanFieldResponseModel<ScheduleModel | PermissionModel>>) => {
          let { initData } = state;

          if (initData[payload.table]) {
            const tableData = [...initData[payload.table]];

            tableData.splice(payload.data.position, 0, payload.data);

            initData = {
              ...initData,
              [payload.table]: tableData,
            };
          }

          state.phasePlanFieldStatus = Api.successStatus;
          state.initData = initData;
          state.phasePlan = {
            ...state.phasePlan,
            [payload.table]: [...initData[payload.table]].map((s, index) => {
              return { ...s, position: index };
            }),
          };
        },
      )
      .addCase(createPhasePlanField.rejected, (state, { payload }: any) => {
        state.phasePlanFieldStatus = Api.failStatus;
      })
      /**
       * updatePhasePlanField
       */
      .addCase(updatePhasePlanField.pending, state => {
        state.phasePlanUpdateFieldStatus = Api.requestStatus;
      })
      .addCase(
        updatePhasePlanField.fulfilled,
        (state, { payload }: PayloadAction<PhasePlanFieldResponseModel<ScheduleModel | PermissionModel>>) => {
          const currentState = current(state);
          let { initData } = currentState;

          if (initData[payload.table]) {
            const findIndex = initData[payload.table].findIndex(f => f.id === payload.data.id);
            if (findIndex > -1) {
              //D&D detected
              if (findIndex !== payload.data.position) {
                let items = PhasePlanHelper.reorderArray(initData[payload.table], findIndex, payload.data.position) as ScheduleModel[];

                items = items.map((s: ScheduleModel, index) => {
                  return { ...s, position: index };
                });

                initData = {
                  ...initData,
                  [payload.table]: items,
                };
              } else {
                const indexData = [...initData[payload.table]];
                indexData.splice(findIndex, 1, { ...payload.data });
                initData = {
                  ...initData,
                  [payload.table]: indexData,
                };
              }
            }
          }

          state.phasePlanUpdateFieldStatus = Api.successStatus;
          state.initData = initData;
          state.phasePlan = {
            ...state.phasePlan,
            [payload.table]: [...initData[payload.table]].map((s, index) => {
              return { ...s, position: index };
            }),
          };
        },
      )
      .addCase(updatePhasePlanField.rejected, (state, { payload }: any) => {
        state.phasePlanUpdateFieldStatus = Api.failStatus;
      })
      /**
       * deletePhasePlanField
       */
      .addCase(deletePhasePlanField.pending, state => {
        state.phasePlanFieldStatus = Api.requestStatus;
      })
      .addCase(
        deletePhasePlanField.fulfilled,
        (state, { payload }: PayloadAction<DeletePhasePlanFieldResponseModel<ScheduleModel | PermissionModel>>) => {
          let { initData } = state;

          if (initData[payload.table]) {
            const findIndex = initData[payload.table].findIndex(f => f.id === payload.response.resource);
            if (findIndex > -1) {
              const tableData = [...initData[payload.table]];

              tableData.splice(findIndex, 1);

              initData = {
                ...initData,
                [payload.table]: tableData,
              };
            }
          }

          state.phasePlanFieldStatus = Api.successStatus;
          state.initData = initData;
          state.phasePlan = {
            ...state.phasePlan,
            [payload.table]: [...initData[payload.table]].map((s, index) => {
              return { ...s, position: index };
            }),
          };
        },
      )
      .addCase(deletePhasePlanField.rejected, (state, { payload }: any) => {
        state.phasePlanFieldStatus = Api.failStatus;
      })
      /**
       * updateGMP
       */
      .addCase(updateGMP.pending, state => {
        state.phasePlanDownloadStatus = Api.requestStatus;
      })
      .addCase(
        updateGMP.fulfilled,
        (state, { payload }: PayloadAction<DeletePhasePlanFieldResponseModel<ScheduleModel | PermissionModel>>) => {
          const { phasePlan } = state;
          phasePlan.gmp = payload.data.gmp;

          state.phasePlanDownloadStatus = Api.successStatus;
          state.phasePlan = phasePlan;
        },
      )
      .addCase(updateGMP.rejected, (state, { payload }: any) => {
        state.phasePlanDownloadStatus = Api.failStatus;
      })
      /**
       * getPrevPhasePlan
       */
      .addCase(getPrevPhasePlan.pending, state => {
        state.phasePlanPrevStatus = Api.requestStatus;
      })
      .addCase(getPrevPhasePlan.fulfilled, (state, { payload }: PayloadAction<GetPhasePlanResponseModel>) => {
        const data = { ...payload.data };

        delete data.versions;

        state.phasePlanPrevStatus = Api.successStatus;
        state.phasePlan = {
          ...state.phasePlan,
          ...data,
        };
      })
      .addCase(getPrevPhasePlan.rejected, (state, { payload }: any) => {
        state.phasePlanPrevStatus = Api.failStatus;
      })
      /**
       * setDriverTasks
       */
      .addCase(setDriverTasks.pending, state => {
        return state;
      })
      .addCase(setDriverTasks.fulfilled, (state, { payload }: PayloadAction<SetDriverTasksResponseModel>) => {
        const currentState = current(state);
        let { initData } = currentState;
        const newSchedules = [...initData.schedules];

        payload.response.resource.map(d => {
          newSchedules.splice(d.position, 1, d);

          initData = {
            ...initData,
            schedules: newSchedules,
          };
        });

        state.initData = initData;
        state.phasePlan = {
          ...currentState.phasePlan,
          ['schedules']: [...initData.schedules].map((s, index) => {
            return { ...s, position: index };
          }),
        };
      })
      .addCase(setDriverTasks.rejected, (state, { payload }: any) => {
        return state;
      });
  },
});

export default phasePlanSlice.reducer;
