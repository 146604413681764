import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { ISubmittalSliceModel } from './submittalSlice.model';
import {
  createSubmittalRevision,
  editSubmittalReviews,
  getSubmittalCard,
  getSubmittalTags,
  submittalSave,
  updateReviewSubmittal,
  getSubmittalsExport,
} from './submittalThunk';
import Api from '../../service/api';
import { IRequestSetHotList } from '../request/requestReducer.model';
import { ISubmittal, SubmittalSocketUpdateResponse } from '../../models/submittals/submittals.model';
import { PlaneControlDeliverable } from '../pcd/types';

const initialState: ISubmittalSliceModel = {
  submittalCardIsOpen: false,
  submittalInfo: null,
  resizableSubmittalCardPart: 'full',
  isCardLoading: false,
  isCardFirstLoading: true,
  isCardSaveLoading: Api.initialStatus,
  saveHotlistLoading: Api.initialStatus,
  availableTags: [],
  isTagsLoading: false,
  isNewSubmittal: false,
  newSubmittalTag: null,
  editSubmittalReviewsLoading: false,
  updateReviewSubmittalLoading: false,
  updateReviewSubmittalId: null,
  socketPrivateState: {
    is_private: 0,
    changedNow: false,
  },
  socketWatchlistState: {
    watchers: null,
    watcher_groups: null,
    changedNow: false,
  },
  socketHotlistState: {
    is_hot_list: false,
    changedNow: false,
  },
  socketProcoreState: {
    is_procore_sync_needed: false,
    changedNow: false,
  },
  is_submittals_export: false,
};

export const submittalSlice = createSlice({
  name: 'submittal',
  initialState,
  reducers: {
    openSubmittalCard(state) {
      state.submittalCardIsOpen = true;
      state.isNewSubmittal = false;
    },
    openNewSubmittalCard(state, action) {
      state.submittalCardIsOpen = true;
      state.isNewSubmittal = true;
      state.submittalInfo = null;
      state.newSubmittalTag = action.payload;
    },
    closeSubmittalCard(state) {
      state.submittalCardIsOpen = false;
      state.isNewSubmittal = false;
      state.newSubmittalTag = null;
    },
    closeNewSubmittalCard(state) {
      state.isNewSubmittal = false;
      state.newSubmittalTag = null;
    },
    handleResizableSubmittalCardPart(state, { payload }: PayloadAction<'full' | 'small'>) {
      state.resizableSubmittalCardPart = payload;
    },
    socketHandleSubmittalCard(state, { payload }: PayloadAction<SubmittalSocketUpdateResponse>) {
      state.submittalInfo = payload.card;
    },
    socketHandleSubmittalChangePrivate(state, { payload: { data } }) {
      if (data.id === state.submittalInfo.id) {
        state.socketPrivateState = { changedNow: true, is_private: data.private };
      }
    },
    resetSocketSubmittalPrivateState(state) {
      state.socketPrivateState = { changedNow: false, is_private: 0 };
    },
    socketHandleSubmittalChangeWatchlist(state, { payload: { data } }) {
      if (data.id === state.submittalInfo.id) {
        state.socketWatchlistState = { changedNow: true, watchers: data.watchers, watcher_groups: data.watcher_groups };
        state.submittalInfo = { ...data };
      }
    },
    socketHandleSubmittalChangeHotlist(state, { payload: { data } }) {
      if (data.id === state.submittalInfo.id) {
        state.socketHotlistState = { changedNow: true, is_hot_list: data.is_hot_list };
        state.submittalInfo = { ...data };
      }
    },
    socketHandleSubmittalChangeProcore(state, { payload: { data } }) {
      state.socketProcoreState = { changedNow: true, is_procore_sync_needed: data.is_procore_sync_needed };
      state.submittalInfo = { ...data };
    },
    resetSocketSubmittalWatchlistState(state) {
      state.socketWatchlistState = { changedNow: false, watchers: null, watcher_groups: null };
    },
    resetSocketSubmittalHotlistState(state) {
      state.socketHotlistState = { changedNow: false, is_hot_list: false };
    },
    resetSocketSubmittalProcoreState(state) {
      state.socketProcoreState = { changedNow: false, is_procore_sync_needed: false };
    },
    handleSubmittalChangeWatchlist(state, { payload }) {
      state.socketWatchlistState = { changedNow: true, watchers: payload.watchers, watcher_groups: payload.watcher_groups };
    },
  },
  extraReducers: builder => {
    // Get submittal card
    builder.addCase(getSubmittalCard.pending, (state, { meta }) => {
      const currentState = current(state);
      if (currentState.submittalInfo?.id !== meta.arg.id) {
        state.submittalInfo = null;
        state.isCardFirstLoading = true;
      }
      state.isCardLoading = true;
    });
    builder.addCase(getSubmittalCard.fulfilled, (state, action) => {
      state.isCardLoading = false;
      state.submittalInfo = action.payload;
      state.isCardFirstLoading = false;
    });
    builder.addCase(getSubmittalCard.rejected, state => {
      state.isCardLoading = false;
      state.isCardFirstLoading = false;
    });
    // Get submittal Tags
    builder.addCase(getSubmittalTags.pending, state => {
      state.isTagsLoading = true;
    });
    builder.addCase(getSubmittalTags.fulfilled, (state, action) => {
      state.isTagsLoading = false;
      state.availableTags = action.payload;
    });
    builder.addCase(getSubmittalTags.rejected, state => {
      state.isTagsLoading = false;
    });
    // Save submittal card OR create new
    builder.addCase(submittalSave.pending, state => {
      state.isCardLoading = true;
      state.isCardSaveLoading = Api.requestStatus;
    });
    builder.addCase(submittalSave.fulfilled, (state, action) => {
      state.isCardLoading = false;
      state.isCardSaveLoading = Api.successStatus;
      state.submittalInfo = action.payload.data;
      state.isNewSubmittal = false;
    });
    builder.addCase(submittalSave.rejected, state => {
      state.isCardLoading = false;
      state.isCardSaveLoading = Api.failStatus;
    });
    // editSubmittalReviews
    builder.addCase(editSubmittalReviews.pending, state => {
      state.editSubmittalReviewsLoading = true;
      state.isCardSaveLoading = Api.requestStatus;
    });
    builder.addCase(editSubmittalReviews.fulfilled, (state, { payload }: PayloadAction<ISubmittal>) => {
      state.submittalInfo = payload;
      state.editSubmittalReviewsLoading = false;
      state.isCardSaveLoading = Api.successStatus;
    });
    builder.addCase(editSubmittalReviews.rejected, state => {
      state.editSubmittalReviewsLoading = false;
      state.isCardSaveLoading = Api.failStatus;
    });
    // updateReviewSubmittal
    builder.addCase(
      updateReviewSubmittal.pending,
      (state, { meta }: PayloadAction<null, string, { arg: PlaneControlDeliverable.UpdateReviewPCD }>) => {
        state.updateReviewSubmittalLoading = true;
        state.updateReviewSubmittalId = meta.arg.data.id;
        state.isCardSaveLoading = Api.requestStatus;
      },
    );
    builder.addCase(updateReviewSubmittal.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
      state.submittalInfo = payload.card as ISubmittal;
      state.updateReviewSubmittalLoading = false;
      state.updateReviewSubmittalId = null;
      state.isCardSaveLoading = Api.successStatus;
    });
    builder.addCase(updateReviewSubmittal.rejected, state => {
      state.updateReviewSubmittalLoading = false;
      state.updateReviewSubmittalId = null;
      state.isCardSaveLoading = Api.failStatus;
    });
    // Create new revision
    builder.addCase(createSubmittalRevision.pending, state => {
      state.isCardLoading = true;
      state.isCardSaveLoading = Api.requestStatus;
    });
    builder.addCase(createSubmittalRevision.fulfilled, (state, action) => {
      state.isCardLoading = false;
      state.submittalInfo = action.payload;
      state.isCardSaveLoading = Api.successStatus;
    });
    builder.addCase(createSubmittalRevision.rejected, state => {
      state.isCardLoading = false;
      state.isCardSaveLoading = Api.failStatus;
    });
    // getSubmittalsExport
    builder.addCase(getSubmittalsExport.pending, state => {
      state.is_submittals_export = true;
    });
    builder.addCase(getSubmittalsExport.fulfilled, state => {
      state.is_submittals_export = false;
    });
    builder.addCase(getSubmittalsExport.rejected, state => {
      state.is_submittals_export = false;
    });
  },
});

export default submittalSlice.reducer;
