import React from 'react';
import { SnackbarProvider } from 'notistack';
import SVG from 'react-inlinesvg';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: '50px !important',
  },
  base: {
    paddingRight: '20px',
    minHeight: '35px',
    margin: '5px',
    '& div': {
      width: '100%',
      padding: '8px 25px',
    },
  },
  variantSuccess: {
    backgroundColor: '#43a047 !important',
  },
  variantError: {
    backgroundColor: '#d32f2f !important',
  },
  variantInfo: {
    backgroundColor: '#2979ff !important',
  },
  variantWarning: {
    backgroundColor: '#ffa000 !important',
  },
};

export const SnackbarProviderContent = ({ classes, children }) => {
  return (
    <SnackbarProvider
      iconVariant={{
        success: <SVG src={require('../../../assets/icons/successIcon.svg')} />,
        error: '',
        warning: <SVG src={require('../../../assets/icons/successIcon.svg')} />,
        info: <SVG src={require('../../../assets/icons/successIcon.svg')} />,
      }}
      classes={{
        base: classes.base,
        root: classes.root,
        variantSuccess: classes.variantSuccess,
        variantInfo: classes.variantInfo,
        variantWarning: classes.variantWarning,
        variantError: classes.variantError,
      }}
    >
      <div>{children}</div>
    </SnackbarProvider>
  );
};

export const SnackbarProviderStyles = withStyles(styles)(SnackbarProviderContent);

export const snackBarSockets = (msg, color, enqueueSnackbar, errors) => {
  let status = 'success';
  switch (color) {
    case 'blue':
      status = 'info';
      break;
    case 'yellow':
      status = 'warning';
      break;
    case 'red':
      status = 'error';
      break;
    case 'green':
      status = 'success';
      break;
    default:
      break;
  }
  if (!errors || !errors.length) {
    return enqueueSnackbar(msg, {
      variant: status,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  }
  errors.forEach(({ message, variant }) => {
    enqueueSnackbar(message, { variant });
  });
};
