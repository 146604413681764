import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import { CodeSelector } from '../../../../controls/Dropdowns/DropdownСomponents';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import TextInputNew from '../../../../controls/TextInputNew/TextInputNew';
import HashtagsEllipsis from '../../../Hashtags/HashtagsEllipsis';
import {
  ActivationTabType,
  IDeliverables,
  LocationCollectionModel,
  MfViewDataModel,
  NfFilterSearchFlagType,
  RequestBuildingModel,
  RequestModel,
  RequestTypeModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
  UfViewDataModel,
} from '../../../../../models';
import { MfCodeListItemsInterface, SubmittalListItemsInterface } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import CardRenderSelect from '../../controls/CardRenderSelect/CardRenderSelect';
import { createNewResponseCompany, updateNewResponseCompany } from '../../../../../store/request/requestLogic(HOLD)';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { IDocumentListDocuments } from '../../../../../store/documents/documentReducer.model';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import Popover from '@material-ui/core/Popover';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import { getListPCD } from '../../../../../store/pcd/pcdThunk';
import CardRenderDesignInput from '../../controls/CardRenderDesignInput/CardRenderDesignInput';
import StatusNameHelper from '../../../../../helpers/StatusNameHelper';
import Links from '../../../../controls/Links/Links';
import ManageStatuses from '../../controls/ManageStatuses/ManageStatuses';
import PartySelector from '../../../../controls/PartySelector/PartySelector';
import { MilestoneItemModel } from '../../../../../models/phasePlan/milestone.model';
import LevelsSelect from '../../controls/LevelsSelect/LevelsSelect';
import BuildingSelect from '../../controls/BuildingSelect/BuildingSelect';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import { MiniLoader } from '../../../../controls/MiniLoader';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { DeliverableCardTabNamesModel } from '../../../CardPcd/constans/constants';
import ChipsDiscipline from '../../../../controls/Chips/ChipsDiscipline/ChipsDiscipline';
import { setValidityStatus, getLinkingItems } from '../../../../../store/relatedItems/relatedItemsThunk';
import { getFeed } from '../../../../../store/comments/commentsThunk';
import ImpactSelect, { ImpactModel } from '../../../../controls/ImpactSelect/ImpactSelect';
import { RelatedItemsTabsType } from '../BottomCardPart/BottomCardPart';
import useRouter from '../../../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';
import { handleSetBreadcrumbs } from '../../../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';

import './TopCardPartStyles.scss';

const { setCommitmentAction } = requestSlice.actions;

interface TopCardPartProps {
  isDragRequestCard: boolean;
  selectedRequest: RequestModel;
  disabled?: boolean;
  selectedRequestCompany: SelectedRequestCompanyModel;
  selectedResponseCompany: SelectedRequestCompanyModel;
  isAccessToCommitment: boolean;
  isAdmin: boolean;
  isAccess: boolean;
  errors: any;
  setErrors: (value: any) => void;
  currentProjectSpecific: string;
  handleChangeSpecific: (value: string) => void;
  buildingSpacesSelected: SimpleTitleModel[];
  buildings: RequestBuildingModel[];
  selectedBuildings: RequestBuildingModel[];
  setBuildingsSelected: (value: RequestBuildingModel[]) => void;
  currentUfcCode: UfViewDataModel;
  selectUfCodeHandler: (e: React.SyntheticEvent) => void;
  currentMfCode: MfViewDataModel;
  selectMfCodeHandler: (e: React.SyntheticEvent) => void;
  handleUpdateMultiSelectState: (data: number | number[], stateName: string) => void;
  buildingSpaces: SimpleTitleModel[];
  listDocuments: IDocumentListDocuments[];
  list_documents_no_nf: IDocumentListDocuments[];
  add_documents_nfSelected: number[];
  hashtags: TagFormModel[];
  handleChangeHashtags: (e: React.SyntheticEvent) => void;
  setSelectedRequestCompany: (e: React.SyntheticEvent) => void;
  setSelectedResponseCompany: (e: React.SyntheticEvent) => void;
  isHiddenFields: boolean;
  handleChangeShowMoreFields: () => void;
  isChangeResponsePartyActionActive?: boolean;
  handleChangedNow?: (value: boolean) => void;
  isPullRequest?: boolean;
  currentDate?: string | Date;
  setCurrentRespPartyId?: (value: number) => void;
  onRequestSave: (requestId: number, bodyParams, callback?, isNotFormData?) => void;
  handleChangeRevision: (id: number) => void;
  isHandleSelectDraw: boolean;
  set_status_nf_loading: boolean;
  isAccessToRequestEdit: boolean;
  list_documents_loading: boolean;
  setCurrentPCD: (value: number[]) => void;
  selectedMilestone: MilestoneItemModel;
  selectedTags: RequestTypeModel;
  setMainErrors: (value: any) => void;
  isCreateRequest: boolean;
  canUpdate: boolean;
  levels: LocationCollectionModel[];
  typicalLayouts: any[];
  setLevelsSelected: (value: LocationCollectionModel[]) => void;
  levelsSelected: LocationCollectionModel[];
  setTypicalLayoutsSelected: (value: any) => void;
  typicalLayoutsSelected: any[];
  clImpactsSelected: ImpactModel[];
  setClImpactsSelected: (items: ImpactModel[]) => void;
  setBuildingSpacesSelected: (value: SimpleTitleModel[]) => void;
  isCompanyManagerOrUser: boolean;
  isMobile: boolean;
  updatedResponseCompany: SelectedRequestCompanyModel;
  is_procore_sync_needed: number | boolean;
  handleCloseAllNotificationPopups: () => void;
  isAllNotificationPopupsClosed: boolean;
  isSkeletonLoading: boolean;
  setIsDeliverablesPopUpOpened: (value: boolean) => void;
  canUpdateTopCard: boolean;
  canUpdateDescription: boolean;
  handleSelectedSubmittal: (type: 'input' | 'output', MfWithSelectedSf: number[]) => void;
}

const TopCardPart: React.FC<TopCardPartProps> = ({
  selectedRequest,
  isDragRequestCard,
  selectedRequestCompany,
  selectedResponseCompany,
  isAccessToCommitment,
  isAdmin,
  isAccess,
  errors,
  setErrors,
  currentProjectSpecific,
  handleChangeSpecific,
  buildingSpacesSelected,
  buildings,
  selectedBuildings,
  setBuildingsSelected,
  currentUfcCode,
  selectUfCodeHandler,
  currentMfCode,
  selectMfCodeHandler,
  handleUpdateMultiSelectState,
  buildingSpaces,
  listDocuments,
  list_documents_no_nf,
  add_documents_nfSelected,
  hashtags,
  handleChangeHashtags,
  setSelectedRequestCompany,
  setSelectedResponseCompany,
  isHiddenFields,
  handleChangeShowMoreFields,
  isChangeResponsePartyActionActive,
  handleChangedNow,
  isPullRequest,
  currentDate,
  setCurrentRespPartyId,
  onRequestSave,
  handleChangeRevision,
  isHandleSelectDraw,
  set_status_nf_loading,
  isAccessToRequestEdit,
  list_documents_loading,
  setCurrentPCD,
  selectedMilestone,
  selectedTags,
  setMainErrors,
  isCreateRequest,
  canUpdate,
  levels,
  typicalLayouts,
  setLevelsSelected,
  levelsSelected,
  setTypicalLayoutsSelected,
  typicalLayoutsSelected,
  setBuildingSpacesSelected,
  isCompanyManagerOrUser,
  isMobile = false,
  updatedResponseCompany,
  is_procore_sync_needed,
  handleCloseAllNotificationPopups,
  isAllNotificationPopupsClosed,
  setClImpactsSelected,
  clImpactsSelected,
  setIsDeliverablesPopUpOpened,
  canUpdateTopCard,
  canUpdateDescription,
  handleSelectedSubmittal,
  isSkeletonLoading,
}) => {
  const { setUrlActiveRelatedItem, setUrlPCD, returnUrlDeliverablesCardTab } = useRoutingHelper();
  const { urlProject, searchParams } = useRouter();
  const themeClass = useGetThemeClass('b-cardRequest-topCardPart');
  const iconThemeClass = useGetThemeClass('buttonIconClass');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const dot_icon = <TokenIcon iconName={'dot'} size={4} customClass={`${themeClass}_change_response_company_body_partyBlock_dotIcon`} />;

  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const pcdDeliverablesListLoading = useAppSelector(state => state.pcdReducer.pcdDeliverablesListLoading);
  const projectId = useAppSelector(state => state.userReducer.active_project_id);
  const idRequestLoading = useAppSelector(state => state.requestReducer.loading);
  const projectInfoLoading = useAppSelector(state => state.projectReducer.projectInfoLoading);
  const isLoadingRequestByNf = useAppSelector(state => state.requestReducer.isLoadingRequestByNf);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const responceSelectRef = useRef();
  const notVerifiedItemsPopupRef = useRef<HTMLDivElement>();
  const { is1440Resolution } = GetResolution();

  const dispatch = useDispatch();
  const [focusElement, setFocusElement] = useState('');

  const [isDisableRequestSelect, setIsDisableRequestSelect] = useState<boolean | null>(null);
  const [isDisableDisciplineAndUserSelect, setIsDisableDisciplineAndUserSelect] = useState<boolean | null>(null);
  const [isDisableResponseSelect, setIsDisableResponseSelect] = useState<boolean | null>(null);

  const [confirmChangeCompany, setConfirmChangeCompany] = useState<boolean>(false);
  const [openedPopupType, setOpenedPopupType] = useState<NfFilterSearchFlagType | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [changeCompanyReceived, setChangeCompanyReceived] = useState<boolean>(null);
  const [declineMsg, setDeclineMsg] = useState<string>('');
  const [isDeclinePopover, setIsDeclinePopover] = useState<boolean>(false);
  const [showRequestParty, setShowRequestParty] = useState<boolean>(true);
  const [showResponseParty, setShowResponseParty] = useState<boolean>(true);
  // submittal Dropdown
  const [fullMfsInput, setFullMfsInput] = useState<MfCodeListItemsInterface[]>([]);
  const [submittalsInputData, setSubmittalsInputData] = useState<MfCodeListItemsInterface[]>([]);
  const [openedMfInputIds, setOpenedMfInputIds] = useState<number[]>([]);
  const [selectedSfInputIds, setSelectedSfInputIds] = useState<number[]>([]);
  const [submittalsInputLoading, setSubmittalsInputLoading] = useState<boolean>(false);
  const [submittalInputSelected, setSubmittalInputSelected] = useState<SubmittalListItemsInterface[]>([]);

  const [fullMfsOutput, setFullMfsOutput] = useState<MfCodeListItemsInterface[]>([]);
  const [submittalsOutputData, setSubmittalsOutputData] = useState<MfCodeListItemsInterface[]>([]);
  const [openedMfOutputIds, setOpenedMfOutputIds] = useState<number[]>([]);
  const [selectedSfOutputIds, setSelectedSfOutputIds] = useState<number[]>([]);
  const [submittalsOutputLoading, setSubmittalsOutputLoading] = useState<boolean>(false);
  const [submittalOutputSelected, setSubmittalOutputSelected] = useState<SubmittalListItemsInterface[]>([]);

  // Deliverables select and popups states
  const [fullBuildings, setFullBuildings] = useState<PCDApi.PCDListBuildingInterface[]>([]);
  const [deliverablesData, setDeliverablesData] = useState<PCDApi.PCDListBuildingInterface[]>([]);
  const [openedBuildingIds, setOpenedBuildingIds] = useState<number[]>([]);
  const [openedDrawingIds, setOpenedDrawingIds] = useState<number[]>([]);
  const [selectedDeliverablesIds, setSelectedDeliverablesIds] = useState<number[]>([]);
  const [drawingsWithSelectedDeliverables, setDrawingsWithSelectedDeliverables] = useState<number[]>([]);

  const [isNotVerifiedItemsPopup, setIsNotVerifiedItemsPopup] = useState<boolean>(false);
  const [isOpenDeliverablesList, setIsOpenDeliverablesList] = useState(false);
  const [isOpenLinksPopover, setIsOpenLinksPopover] = useState<boolean>(false);
  const [linksPopoverValue, setLinksPopoverValue] = useState<PCDApi.PCDListDrawingInterface | null>(false);
  const [deliverablesLinksRef, setDeliverablesLinksRef] = useState<HTMLDivElement | null>(null);
  const [localSearchValue, setLocalSearchValue] = useState<string>('');

  const isSentRequestToChange = !!selectedRequest?.response_company_change_request;
  const isSidePeekView = isDragRequestCard || is1440Resolution;

  const selectedBuildingsIds = useMemo(() => {
    return selectedBuildings?.map(building => building.id) || [];
  }, [selectedBuildings]);

  useEffect(() => {
    const newDeliverablesIds = selectedRequest?.deliverables?.map(deliverable => deliverable.id) ?? [];
    setSelectedDeliverablesIds(newDeliverablesIds);
    getSubmittalInputData();
  }, [selectedRequest]);

  useEffect(() => {
    setShowRequestParty(!isMobile);
    setShowResponseParty(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (selectedRequest?.parent_id) {
      setIsDisableRequestSelect(true);
      setIsDisableDisciplineAndUserSelect(true);
      setIsDisableResponseSelect(true);
      return;
    }

    setIsDisableRequestSelect(checkIsDisableField('request'));
    setIsDisableDisciplineAndUserSelect(checkIsDisableDisciplineAndUserSelect('request'));
    setIsDisableResponseSelect(checkIsDisableField('response'));
  }, [selectedRequest, isAccess, isSentRequestToChange]);

  useEffect(() => {
    if (
      ((!isAccess && isAccessToCommitment) || isAdmin) &&
      !!selectedRequest &&
      selectedRequest.lastUserStatus.status !== 'drafted' &&
      selectedResponseCompany?.company?.id !== updatedResponseCompany?.company?.id
    ) {
      setConfirmChangeCompany(true);
      return;
    }
  }, [selectedResponseCompany]);

  useEffect(() => {
    if (isAllNotificationPopupsClosed) {
      handleCloseNotVerifiedItemsPopup();
    }
  }, [isAllNotificationPopupsClosed]);

  useEffect(() => {
    const result = [];

    if (fullMfsInput?.length) {
      fullMfsInput.forEach(mf => {
        const isMfOpened = openedMfInputIds.includes(mf.id);

        if (localSearchValue) {
          if (
            mf.submittals.some(
              sf => sf.sf_index?.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue),
            )
          ) {
            result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
          }
        } else {
          result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
        }

        if (isMfOpened) {
          mf.submittals.forEach(sf => {
            const selectedSf = selectedSfInputIds.includes(sf.id);

            if (localSearchValue) {
              if (sf.sf_index.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue)) {
                result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
              }
            } else {
              result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
            }
          });
        }
      });
    }
    setSubmittalsInputData(result);
  }, [fullMfsInput, openedMfInputIds, selectedSfInputIds, localSearchValue]);

  useEffect(() => {
    const result = [];
    if (fullMfsInput?.length) {
      fullMfsInput.forEach(mf => {
        mf.submittals.forEach(sf => {
          const isMfHaveSelectedSf = selectedSfInputIds.includes(sf.id);

          if (isMfHaveSelectedSf) {
            result.push(sf);
          }
        });
      });
    }
    const ids = result.map(sf => sf.id);
    handleSelectedSubmittal('input', ids);
    setSubmittalInputSelected(result);
  }, [fullMfsInput, selectedSfInputIds]);

  useEffect(() => {
    const result = [];

    if (fullMfsOutput?.length) {
      fullMfsOutput.forEach(mf => {
        const isMfOpened = openedMfOutputIds.includes(mf.id);

        if (localSearchValue) {
          if (
            mf.submittals.some(
              sf => sf.sf_index?.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue),
            )
          ) {
            result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
          }
        } else {
          result.push({ ...mf, itemType: 'mfCode', isItemOpened: isMfOpened });
        }

        if (isMfOpened) {
          mf.submittals.forEach(sf => {
            const selectedSf = selectedSfOutputIds.includes(sf.id);

            if (localSearchValue) {
              if (sf.sf_index.toLowerCase().includes(localSearchValue) || sf.title?.toLowerCase().includes(localSearchValue)) {
                result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
              }
            } else {
              result.push({ ...sf, itemType: 'sf', isItemOpened: isMfOpened, selected: selectedSf });
            }
          });
        }
      });
    }
    setSubmittalsOutputData(result);
  }, [fullMfsOutput, openedMfOutputIds, selectedSfOutputIds, localSearchValue]);

  useEffect(() => {
    const result = [];
    if (fullMfsOutput?.length) {
      fullMfsOutput.forEach(mf => {
        mf.submittals.forEach(sf => {
          const isMfHaveSelectedSf = selectedSfOutputIds.includes(sf.id);

          if (isMfHaveSelectedSf) {
            result.push(sf);
          }
        });
      });
    }
    const ids = result.map(sf => sf.id);
    handleSelectedSubmittal('output', ids);
    setSubmittalOutputSelected(result);
  }, [fullMfsOutput, selectedSfOutputIds]);

  // Generate select data
  useEffect(() => {
    const result = [];

    if (fullBuildings?.length) {
      fullBuildings.forEach(building => {
        const isBuildingOpened = openedBuildingIds.includes(building.id);

        if (localSearchValue) {
          if (building.drawings.some(drawing => drawing.title.toLowerCase().includes(localSearchValue))) {
            result.push({ ...building, itemType: 'building', isItemOpened: isBuildingOpened });
          }
        } else {
          result.push({ ...building, itemType: 'building', isItemOpened: isBuildingOpened });
        }

        if (isBuildingOpened) {
          building.drawings.forEach(drawing => {
            const isDrawingOpened = openedDrawingIds.includes(drawing.id);
            const selectedDeliverables = drawing.deliverables.filter(deliverable => selectedDeliverablesIds.includes(deliverable.id));
            let selectedDrawingValue;

            if (selectedDeliverables.length === drawing.deliverables.length) {
              selectedDrawingValue = 2;
            } else if (selectedDeliverables.length >= 1) {
              selectedDrawingValue = 1;
            } else {
              selectedDrawingValue = 0;
            }

            if (localSearchValue) {
              if (drawing.title.toLowerCase().includes(localSearchValue)) {
                result.push({ ...drawing, itemType: 'drawing', isItemOpened: isDrawingOpened, selected: selectedDrawingValue });
              }
            } else {
              result.push({ ...drawing, itemType: 'drawing', isItemOpened: isDrawingOpened, selected: selectedDrawingValue });
            }

            if (isDrawingOpened) {
              drawing.deliverables.forEach(deliverable => {
                const isDeliverableSelected = selectedDeliverablesIds.includes(deliverable.id);
                result.push({ ...deliverable, itemType: 'deliverable', isItemOpened: isDrawingOpened, selected: isDeliverableSelected });
              });
            }
          });
        }
      });
    }

    setDeliverablesData(result);
  }, [fullBuildings, openedBuildingIds, openedDrawingIds, selectedDeliverablesIds, localSearchValue]);

  const handleChangeSearch = searchValue => {
    setLocalSearchValue(searchValue.toLowerCase());
  };

  // Generate select selected value
  useEffect(() => {
    const result = [];

    if (fullBuildings?.length) {
      fullBuildings.forEach(building => {
        building.drawings.forEach(drawing => {
          const isDrawingHaveSelectedDeliverable = drawing.deliverables.find(deliverable =>
            selectedDeliverablesIds.includes(deliverable.id),
          );

          if (isDrawingHaveSelectedDeliverable) {
            result.push(drawing);
          }
        });
      });
    }

    setDrawingsWithSelectedDeliverables(result);
  }, [fullBuildings, selectedDeliverablesIds]);

  useEffect(() => {
    setCurrentPCD(selectedDeliverablesIds);
  }, [selectedDeliverablesIds]);

  useEffect(() => {
    if (projectId) {
      // getPCDHandler([]);
      dispatch(
        getListPCD({
          project_id: Number(projectId),
          callback: res => {
            setFullBuildings(res.buildings);
            // getPCDHandler(res.buildings);
          },
        }),
      );
    }
  }, [projectId]);

  useEffect(() => {
    if (
      !idRequestLoading &&
      selectedRequest &&
      selectedRequest.deliverables.filter(
        item =>
          item.validity_status === 'not_verified' &&
          item.is_whiteboard === 1 &&
          item.superseded === 0 &&
          relatedTabData.deliverable?.findIndex(d => d.id === item.id) > -1,
      ).length
    ) {
      setTimeout(() => {
        setIsNotVerifiedItemsPopup(true);
      }, 500);
    }
  }, [selectedRequest, relatedTabData]);

  useEffect(() => {
    if (isChangeResponsePartyActionActive) {
      setOpenedPopupType('response-comp');
      setAnchorEl(responceSelectRef.current);
    }
  }, [isChangeResponsePartyActionActive]);

  const handleDrawingCheckBoxClick = (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    e.stopPropagation();

    const result = [...selectedDeliverablesIds];

    const { selected, deliverables } = drawing;
    if (selected === 2) {
      deliverables.forEach(deliverable => {
        const deliverableIndex = result.findIndex(item => item === deliverable.id);
        if ((deliverableIndex || deliverableIndex === 0) && deliverableIndex !== -1) {
          result.splice(deliverableIndex, 1);
        }
      });
    }

    if (selected === 1) {
      deliverables.forEach(deliverable => {
        if (!result.includes(deliverable.id)) {
          result.push(deliverable.id);
        }
      });
    }

    if (selected === 0) {
      deliverables.forEach(deliverable => {
        result.push(deliverable.id);
      });
    }

    setSelectedDeliverablesIds(result);
  };

  const getSubmittalInputData = () => {
    if (selectedRequest) {
      setSubmittalsInputLoading(true);

      dispatch(
        getLinkingItems({
          project_id: selectedRequest.project_id,
          owner_id: selectedRequest?.parent_id || selectedRequest.id,
          owner_type: 'request',
          sync_owner_type: 'submittalInput',
          callback: data => {
            transformSubmittalsList('submittalInput', data);
            getSubmittalOutputData();
          },
        }),
      );
    }
  };

  const getSubmittalOutputData = () => {
    if (selectedRequest) {
      setSubmittalsOutputLoading(true);

      dispatch(
        getLinkingItems({
          project_id: selectedRequest.project_id,
          owner_id: selectedRequest?.parent_id || selectedRequest.id,
          owner_type: 'request',
          sync_owner_type: 'submittalOutput',
          callback: data => {
            transformSubmittalsList('submittalOutput', data);
          },
        }),
      );
    }
  };

  const transformSubmittalsList = (type: 'submittalOutput' | 'submittalInput', data) => {
    let newData = [];
    const newSelectedData = [];

    data.length &&
      data.forEach(item => {
        const mfCode = newData.find(mf => mf.id === item.mf_id);

        if (mfCode) {
          const sf = mfCode.submittals.find(sf => sf.id === item.id);
          if (!sf) {
            mfCode.submittals.push({ ...item, name: `${item.mf_code} - ${item.mf_title}` });
          }
        } else {
          newData.push({
            is_locked: 0,
            id: item.mf_id,
            title: `${item.mf_code} - ${item.mf_title}`,
            submittals: [{ ...item, name: `${item.mf_code} - ${item.mf_title}` }],
          });
        }
      });

    if (newData.length) {
      newData = newData.map(mf => {
        const submittals = mf.submittals.map(sf => {
          const obj = { ...sf };
          obj.is_locked = 0;
          if (sf.is_locked) {
            newSelectedData.push(sf.id);
          }
          return obj;
        });
        return { ...mf, is_locked: 0, submittals: submittals };
      });
    }
    if (type === 'submittalInput') {
      setSubmittalsInputLoading(false);
      setSelectedSfInputIds(newSelectedData);
      setFullMfsInput(newData);
    } else {
      setSubmittalsOutputLoading(false);
      setSelectedSfOutputIds(newSelectedData);
      setFullMfsOutput(newData);
    }
  };

  const handleOpenLinkPopover = (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    setIsOpenLinksPopover(true);
    setLinksPopoverValue(drawing);
    setDeliverablesLinksRef(e.currentTarget);
  };

  const handleCloseLinkPopover = () => {
    setIsOpenLinksPopover(false);
    setLinksPopoverValue(null);
    setDeliverablesLinksRef(null);
  };

  const handleBuildingItemClick = (building: PCDApi.PCDListBuildingInterface) => {
    const result = [...openedBuildingIds];

    if (result?.includes(building.id)) {
      result.splice(
        result.findIndex(id => id === building.id),
        1,
      );
    } else {
      result.push(building.id);
    }

    setOpenedBuildingIds(result);
  };

  const handleDrawingItemClick = (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    const result = [...openedDrawingIds];

    if (result?.includes(drawing.id)) {
      result.splice(
        result.findIndex(id => id === drawing.id),
        1,
      );
    } else {
      result.push(drawing.id);
    }

    setOpenedDrawingIds(result);
  };
  const handleDeliverablesItemClick = (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    const result = [...selectedDeliverablesIds];

    if (result?.includes(deliverable.id)) {
      result.splice(
        result.findIndex(id => id === deliverable.id),
        1,
      );
    } else {
      result.push(deliverable.id);
    }

    setSelectedDeliverablesIds(result);
  };

  const handleSelectedDrawingDelete = (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => {
    event.stopPropagation();

    const result = [...selectedDeliverablesIds];

    drawing.deliverables.forEach(deliverable => {
      if (result.includes(deliverable.id)) {
        result.splice(
          result.findIndex(item => item.id === deliverable.id),
          1,
        );
      }
    });

    setSelectedDeliverablesIds(result);
  };

  const checkIsDisableField = type => {
    if (type === 'response' && isSentRequestToChange) {
      return true;
    }
    if (type === 'response' && isAccessToCommitment && selectedRequest?.lastUserStatus?.status !== 'provided') {
      return false;
    }

    if (!!isAccess === false) {
      return true;
    }
    if (type === 'request') {
      return (!isAdmin && userInfo.user_parties?.length <= 1) || selectedRequest?.lastUserStatus?.status === 'accepted';
    }

    if (type === 'response') {
      if (!selectedRequest?.lastUserStatus) {
        return false;
      }
      if (selectedRequest.lastUserStatus.status === 'provided' || selectedRequest.lastUserStatus.status === 'accepted') {
        return true;
      }
    }
  };

  const checkIsDisableDisciplineAndUserSelect = type => {
    if (!!isAccess === false) {
      return true;
    }
    if (type === 'request') {
      return (
        (!isAdmin && !userInfo.user_parties?.find(c => c.id === selectedRequestCompany?.company?.id)) ||
        selectedRequest?.lastUserStatus?.status === 'accepted'
      );
    }
  };

  const getSelectTooltipText = (selectType: 'request' | 'response', selectValue: 'discipline' | 'company' | 'user') => {
    const ResponseSubmitedText = 'Response submitted, need to Accept or Decline it first';

    if (localStorage.getItem('show_tooltips') === 'no') {
      return '';
    }
    if (selectType === 'request' && selectValue === 'discipline') {
      return 'Request discipline';
    }

    if (selectType === 'request' && selectValue === 'company') {
      return 'Request company';
    }

    if (selectType === 'request' && selectValue === 'user') {
      return 'Request manager';
    }

    if (selectType === 'response' && selectValue === 'discipline') {
      return selectedRequest?.status === 'provided' ? ResponseSubmitedText : 'Response discipline';
    }

    if (selectType === 'response' && selectValue === 'company') {
      return selectedRequest?.status === 'provided' ? ResponseSubmitedText : 'Response company';
    }

    if (selectType === 'response' && selectValue === 'user') {
      return selectedRequest?.status === 'provided' ? ResponseSubmitedText : 'Response manager';
    }

    return localStorage.getItem('show_tooltips') === 'no'
      ? ''
      : selectType === 'request'
      ? 'Request discipline'
      : selectedRequest?.status === 'provided'
      ? 'Response submitted, need to Accept or Decline it first'
      : 'Response discipline';
  };

  const handleOpenDeliverablesList = (): void => {
    setIsOpenDeliverablesList(true);

    if (isNotVerifiedItemsPopup) {
      setIsNotVerifiedItemsPopup(false);
    }
  };

  const handleCloseNotVerifiedItemsPopup = (): void => {
    setIsNotVerifiedItemsPopup(false);
    handleCloseAllNotificationPopups();
  };

  const handleCloseDeliverablesList = (): void => {
    setIsOpenDeliverablesList(false);
  };

  const handleChangeNfStatus = (deliverableList: IDeliverables[]) => {
    const saveObj = {
      project_id: Number(sessionStorage.getItem('active_project_id')),
      owner_id: selectedRequest?.parent_id || selectedRequest.id,
      owner_type: 'request',
      sync_owner_type: 'deliverable',
    };

    const not_verified = deliverableList.filter(item => item.validity_status === 'not_verified');
    const not_valid = deliverableList.filter(item => item.validity_status === 'not_valid');
    const valid = deliverableList.filter(item => item.validity_status === 'valid');

    if (not_verified.length) {
      saveObj.not_verified = not_verified.map(item => item.id);
    }
    if (not_valid.length) {
      saveObj.not_valid = not_valid.map(item => item.id);
    }
    if (valid.length) {
      saveObj.valid = valid.map(item => item.id);
    }
    handleCloseDeliverablesList();
    dispatch(
      setValidityStatus({
        validityStatusData: saveObj,
        callback: () => {
          dispatch(getFeed({ owner_id: selectedRequest?.parent_id || selectedRequest.id, type: 'request' }));
        },
      }),
    );
  };

  const handleChangeImpacted = (item: ImpactModel, status: 0 | 1 | null) => {
    if (status === null) {
      setClImpactsSelected(clImpactsSelected.filter(impact => impact.alias !== item.alias));
    } else {
      setClImpactsSelected(
        clImpactsSelected.some(impact => impact.alias === item.alias)
          ? clImpactsSelected.map(impact => (impact.alias === item.alias ? { ...impact, status } : impact))
          : [...clImpactsSelected, { ...item, status }],
      );
    }
  };

  const sendChangeResponseCompany = () => {
    handleChangedNow && handleChangedNow(false);
    dispatch(
      createNewResponseCompany({
        request_id: Number(selectedRequest.id),
        response_party_id: selectedResponseCompany?.company?.id,
        resp_disc_id: selectedResponseCompany?.discipline?.id || null,
        response_user_id: selectedResponseCompany?.user?.id || null,
        commit: isChangeResponsePartyActionActive ? 1 : 0,
        callback: () => {
          dispatch(setCommitmentAction(''));
        },
      }),
    );
    setOpenedPopupType(null);
    setConfirmChangeCompany(false);
  };

  const onSelectCompany = (selected: SelectedRequestCompanyModel, isReset?: boolean) => {
    const errorsObj = { ...(errors || {}) };

    delete errorsObj?.response_party_id;
    setSelectedResponseCompany(selected);
    setErrors(errorsObj);

    if (selectedRequest?.id && selectedRequest?.status !== 'drafted' && !isReset) {
      const saveObject = {
        due_date: currentDate || selectedRequest.due_date || '',
        desc: selectedRequest.desc || '',
        specific: selectedRequest.specific || '',
        request_party_id: selectedRequest?.request_party_id || '',
        response_party_id: selected.company?.id || '',
        is_whiteboard: 1,
      } as any;

      if (selected.user?.id) {
        saveObject.response_user_id = selected.user.id;
      }

      if (selected.discipline?.id) {
        saveObject.resp_disc_id = selected.discipline.id;
      }

      if (selectedRequest.req_disc_id) {
        saveObject.req_disc_id = selectedRequest.req_disc_id;
      }

      if (selectedRequest.user?.id) {
        saveObject.user_id = selectedRequest.user.id;
      }
      if (selectedRequest?.milestone_id) {
        saveObject.milestone_id = selectedRequest?.milestone_id;
      }
      if (!isPullRequest) {
        handleChangedNow(true);
      }

      onRequestSave(selectedRequest?.id, saveObject);
    }
  };

  const handleUpdateTopCard = () => {
    const submittal_input_selected = submittalInputSelected.map(item => item.id);
    const submittal_output_selected = submittalOutputSelected.map(item => item.id);
    const submittal_ids = Array.from(new Set([...submittal_input_selected, ...submittal_output_selected]));
    if (selectedRequest?.id) {
      const saveObject = {
        project_id: projectId || '',
        user_id: selectedRequestCompany.user.id || selectedRequest.user.id,
        request_party_id: selectedRequestCompany?.company?.id || selectedRequest?.request_party_id,
        req_disc_id: selectedRequestCompany?.discipline?.id || selectedRequest?.req_disc_id,
        resp_disc_id: selectedResponseCompany?.discipline?.id || selectedRequest?.resp_disc_id,
        response_party_id: selectedResponseCompany?.company?.id || selectedRequest?.response_party_id,
        response_user_id: selectedResponseCompany?.user?.id || selectedRequest?.response_user_id,
        specific: currentProjectSpecific || selectedRequest?.specific,
        ufc_ids:
          currentUfcCode?.map(item => {
            return {
              id: item.id,
              type: item.type,
            };
          }) || selectedRequest?.ufc_ids,
        mfc_ids: currentMfCode?.map(item => item.id) || selectedRequest?.mfc_ids,
        document_id: add_documents_nfSelected || selectedRequest?.document_id,
        buildings: selectedBuildings?.map(item => item.id) || selectedRequest?.buildings,
        building_spaces: buildingSpacesSelected?.map(item => item.id) || selectedRequest?.building_spaces,
        hashtags:
          hashtags
            .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
            .map(hashTag => {
              const tag = {
                name: hashTag.label,
                color: hashTag.color,
                link: hashTag.link,
              };
              return JSON.stringify(tag);
            }) ||
          selectedRequest?.hashtags
            .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
            .map(hashTag => {
              const tag = {
                name: hashTag.label,
                color: hashTag.color,
                link: hashTag.link,
              };
              return JSON.stringify(tag);
            }),
        deliverable_ids: selectedDeliverablesIds || selectedRequest?.deliverable_ids,
        submittal_ids: submittal_ids,
        is_whiteboard: selectedRequest?.status === 'drafted' ? 0 : 1,
        milestone_id: selectedMilestone?.id || selectedRequest?.milestone_id,
        due_date: currentDate || selectedRequest?.due_date,
        tag_ids: selectedTags?.id ? [selectedTags?.id] : selectedRequest?.tags,
        levels: levelsSelected?.map(i => i.id) || selectedRequest?.levels,
        typicalLayouts: typicalLayoutsSelected?.map(i => i.id) || selectedRequest?.typicalLayouts,
        is_procore_sync_needed: is_procore_sync_needed,
        cl_impacts: '',
        reset_cl_impacts: 0,
      };

      if (clImpactsSelected.length) {
        const impactsObj = {};

        clImpactsSelected.forEach(item => {
          impactsObj[item.alias] = item.status;
        });

        saveObject.cl_impacts = JSON.stringify(impactsObj);
        delete saveObject.reset_cl_impacts;
      } else {
        saveObject.reset_cl_impacts = 1;
        delete saveObject.cl_impacts;
      }

      onRequestSave(selectedRequest?.id, saveObject, null, true);
    }
  };

  const handleResetCompany = () => {
    onSelectCompany(updatedResponseCompany, true);
    setConfirmChangeCompany(false);

    if (isChangeResponsePartyActionActive) {
      dispatch(setCommitmentAction(''));
    }
  };

  const handleClickOutside = () => {
    setOpenedPopupType(null);
  };

  const handleChangeCompanyReceived = () => {
    if (!isSentRequestToChange || !isAccess) {
      return;
    }
    setAnchorEl(responceSelectRef.current);
    setChangeCompanyReceived(true);
  };

  const handleNewCompany = (status: string) => {
    dispatch(
      updateNewResponseCompany({
        status: status,
        id: selectedRequest?.response_company_change_request?.id,
        declined_comment: declineMsg || undefined,
        callback: () => {
          setChangeCompanyReceived(null);
          setIsDeclinePopover(false);
          setDeclineMsg('');
        },
      }),
    );
  };

  const handleOpenDeclinePopover = (e: any) => {
    setIsDeclinePopover(e.currentTarget);
  };

  const handleCloseDeclinePopup = () => {
    setIsDeclinePopover(false);
    setDeclineMsg('');
  };

  const openPcdCard = (card, buildingId) => {
    localStorage.getItem('resizableCard') === 'full' && localStorage.setItem('hideGlobalPCDCard', '1');
    const isUrlPCDMain = urlProject && urlProject[1] === 'deliverables';
    const tabParam = searchParams.get('tab') as DeliverableCardTabNamesModel | ActivationTabType;
    setUrlPCD(card.id, buildingId);
    const nextCard = { id: card.id, title: card.title || '', buildingId, toCard: 'Deliverable' };
    if (!isUrlPCDMain) {
      if (localStorage.getItem('resizableCard') === 'small') {
        localStorage.setItem('hideSecondSmallNf', '1');
      }
      handleSetBreadcrumbs(false, 'Nf', selectedRequest, nextCard, dispatch, 'firstCardBreadcrumbs', tabParam);
    } else {
      localStorage.setItem('hideSecondSmallNf', '0');
      // handleChangeRevision(card.id);
      handleSetBreadcrumbs(false, 'Deliverable', selectedRequest, nextCard, dispatch, 'firstCardBreadcrumbs', tabParam);
    }

    handleCloseLinkPopover();
  };

  const getSetStatus = (status: string, type?: string) => {
    if (type === 'designInput') {
      switch (status) {
        case 'valid': {
          return <TokenIcon iconName={'validDDL'} size={16} />;
        }
        case 'new_valid': {
          return <TokenIcon iconName={'1st-valid'} size={16} />;
        }
        case 'not_valid': {
          return <TokenIcon iconName={'void'} size={16} />;
        }
        case 'not_verified': {
          return <TokenIcon iconName={'not-verified-triangle'} size={16} />;
        }
        case 'ignore': {
          return <TokenIcon iconName={'cancel-right'} size={16} />;
        }
        default:
          return null;
      }
    }
    switch (status) {
      case 'valid': {
        return <TokenIcon iconName={'valid'} size={16} />;
      }
      case 'new_valid': {
        return <TokenIcon iconName={'valid'} size={16} />;
      }
      case 'not_valid': {
        return <TokenIcon iconName={'not-valid'} size={16} />;
      }
      case 'not_verified': {
        return <TokenIcon iconName={'not-verified-triangle'} size={16} />;
      }
      default:
        return null;
    }
  };

  const changeCompany = (selected: SelectedRequestCompanyModel) => {
    onSelectCompany(selected);
    setConfirmChangeCompany(false);
  };

  const getsubmittalsInputItems = () => {
    return (
      submittalsInputData?.map(item => {
        const isDisabled = !!item.is_deactivated;
        const toolTipText = item.message;
        return {
          ...item,
          disabled: isDisabled,
          tooltip: isDisabled ? 'Submittal has been deactivated' : toolTipText,
        };
      }) || []
    );
  };

  const getsubmittalsOutputItems = () => {
    return (
      submittalsOutputData?.map(item => {
        const isDisabled = !!item.is_deactivated;
        const toolTipText = item.message;
        return {
          ...item,
          disabled: isDisabled,
          tooltip: isDisabled ? 'Submittal has been deactivated' : toolTipText,
        };
      }) || []
    );
  };

  const handleMfItemClick = (mf: MfCodeListItemsInterface) => {
    const result = [...openedMfInputIds];
    if (result?.includes(mf.id)) {
      result.splice(
        result.findIndex(id => id === mf.id),
        1,
      );
    } else {
      result.push(mf.id);
    }
    setOpenedMfInputIds(result);
  };

  const handleMfItemOutputClick = (mf: MfCodeListItemsInterface) => {
    const result = [...openedMfOutputIds];
    if (result?.includes(mf.id)) {
      result.splice(
        result.findIndex(id => id === mf.id),
        1,
      );
    } else {
      result.push(mf.id);
    }
    setOpenedMfOutputIds(result);
  };

  const handleSfItemClick = (event: React.SyntheticEvent, sf: SubmittalListItemsInterface) => {
    event.stopPropagation();

    if (sf.disabled) {
      return;
    }
    const result = [...selectedSfInputIds];

    if (result?.includes(sf.id)) {
      result.splice(
        result.findIndex(id => id === sf.id),
        1,
      );
      if (sf.reflect_input === 1) {
        deleteReflectInput(sf, 'submittalOutput', 'delete');
      }
    } else {
      if (sf.reflect_input === 1) {
        deleteReflectInput(sf, 'submittalOutput', 'push');
      }
      result.push(sf.id);
    }
    setSelectedSfInputIds(result);
  };

  const handleSfItemOutputClick = (event: React.SyntheticEvent, sf: SubmittalListItemsInterface) => {
    event.stopPropagation();

    if (sf.disabled) {
      return;
    }
    const result = [...selectedSfOutputIds];

    if (result?.includes(sf.id)) {
      result.splice(
        result.findIndex(id => id === sf.id),
        1,
      );
      if (sf.reflect_input === 1) {
        deleteReflectInput(sf, 'submittalInput', 'delete');
      }
    } else {
      if (sf.reflect_input === 1) {
        deleteReflectInput(sf, 'submittalInput', 'push');
      }
      result.push(sf.id);
    }
    setSelectedSfOutputIds(result);
  };

  const handlePopUpClose = () => {
    setLocalSearchValue('');
  };

  const renderPartyTitle = (type: 'request' | 'response') => {
    if (type === 'request') {
      return isMobile ? (
        <div className={`${themeClass}_requestParty_mobile`}>
          <div className={`${themeClass}_requestParty_mobile_title`} onClick={() => setShowRequestParty(!showRequestParty)}>
            <SystemButton
              type={showRequestParty ? 'chevron-down' : 'chevron-right'}
              variant={'transparent'}
              size={'md'}
              isMobile={isMobile}
            />
            Request party
          </div>
          {selectedRequestCompany?.company?.title && (
            <div className={`${themeClass}_requestParty_mobile_company`}>
              <PlaceholderInfo
                type={'Company'}
                firstName={selectedRequestCompany?.company?.title || ''}
                lastName={selectedRequestCompany?.company?.title || ''}
                detailInfo={selectedRequestCompany?.company?.title || ''}
                size={24}
                imagePath={
                  selectedRequestCompany?.company?.renderings?.file || selectedRequestCompany?.company?.renderings?.[0]?.file || ''
                }
                isMobile={isMobile}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${themeClass}_title`}>Request party</div>
      );
    }

    if (type === 'response') {
      return isMobile ? (
        <div className={`${themeClass}_responseParty_mobile`}>
          <div className={`${themeClass}_responseParty_mobile_title`} onClick={() => setShowResponseParty(!showResponseParty)}>
            <SystemButton
              type={showResponseParty ? 'chevron-down' : 'chevron-right'}
              variant={'transparent'}
              size={'md'}
              isMobile={isMobile}
            />
            Response party
          </div>
          {selectedResponseCompany?.company?.title && (
            <div className={`${themeClass}_responseParty_mobile_company`}>
              <PlaceholderInfo
                type={'Company'}
                firstName={selectedResponseCompany?.company?.title || ''}
                lastName={selectedResponseCompany?.company?.title || ''}
                detailInfo={selectedResponseCompany?.company?.title || ''}
                size={24}
                imagePath={
                  selectedResponseCompany?.company?.renderings?.file || selectedResponseCompany?.company?.renderings?.[0]?.file || ''
                }
                isMobile={isMobile}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`${themeClass}_title -responsePartyTitle`}>Response party</div>
      );
    }
  };

  const renderShowMoreButton = () => {
    return (
      <CustomButton
        icon={<TokenIcon iconName={isHiddenFields ? 'plus' : 'dash'} size={isMobile ? 20 : 12} />}
        type={'text-plain'}
        size={isMobile ? 'xs' : 'md'}
        clickHandler={handleChangeShowMoreFields}
        title={isHiddenFields ? 'Show more fields' : 'Show less fields'}
        iconClass={`${iconThemeClass}_text-plain`}
        isMobile={isMobile}
      />
    );
  };

  const handleSelectedSubmittalDelete = (event: React.SyntheticEvent, sf: SubmittalListItemsInterface, type: string) => {
    event.stopPropagation();

    if (type === 'submittalInput') {
      const result = [...selectedSfInputIds];

      if (result?.includes(sf.id)) {
        result.splice(
          result.findIndex(id => id === sf.id),
          1,
        );
        if (sf.reflect_input === 1) {
          deleteReflectInput(sf, 'submittalOutput', 'delete');
        }
      }
      setSelectedSfInputIds(result);
    }

    if (type === 'submittalOutput') {
      const result = [...selectedSfOutputIds];

      if (result?.includes(sf.id)) {
        result.splice(
          result.findIndex(id => id === sf.id),
          1,
        );
        if (sf.reflect_input === 1) {
          deleteReflectInput(sf, 'submittalInput', 'delete');
        }
      }
      setSelectedSfOutputIds(result);
    }
  };

  const deleteReflectInput = (sf: SubmittalListItemsInterface, type: string, operator: 'push' | 'delete') => {
    if (type === 'submittalInput' && operator === 'delete') {
      const result = [...selectedSfInputIds];

      if (result?.includes(sf.id)) {
        result.splice(
          result.findIndex(id => id === sf.id),
          1,
        );
      }
      setSelectedSfInputIds(result);
    }

    if (type === 'submittalInput' && operator === 'push') {
      const result = [...selectedSfInputIds];

      if (!result?.includes(sf.id)) {
        result.push(sf.id);
      }
      setSelectedSfInputIds(result);
    }

    if (type === 'submittalOutput' && operator === 'delete') {
      const result = [...selectedSfOutputIds];

      if (result?.includes(sf.id)) {
        result.splice(
          result.findIndex(id => id === sf.id),
          1,
        );
      }
      setSelectedSfOutputIds(result);
    }

    if (type === 'submittalOutput' && operator === 'push') {
      const result = [...selectedSfOutputIds];

      if (!result?.includes(sf.id)) {
        result.push(sf.id);
      }
      setSelectedSfOutputIds(result);
    }
  };

  const deleteReflectAll = (submittal: SubmittalListItemsInterface[], type: string, operator: 'push' | 'delete') => {
    if (type === 'submittalInput' && operator === 'delete') {
      const result = [...selectedSfInputIds];
      submittal.forEach(sf => {
        if (result?.includes(sf.id)) {
          result.splice(
            result.findIndex(id => id === sf.id),
            1,
          );
        }
      });
      setSelectedSfInputIds(result);
    }

    if (type === 'submittalInput' && operator === 'push') {
      const result = [...selectedSfInputIds];
      submittal.forEach(sf => {
        if (!result?.includes(sf.id)) {
          result.push(sf.id);
        }
      });
      setSelectedSfInputIds(result);
    }

    if (type === 'submittalOutput' && operator === 'delete') {
      const result = [...selectedSfOutputIds];
      submittal.forEach(sf => {
        if (result?.includes(sf.id)) {
          result.splice(
            result.findIndex(id => id === sf.id),
            1,
          );
        }
      });
      setSelectedSfOutputIds(result);
    }

    if (type === 'submittalOutput' && operator === 'push') {
      const result = [...selectedSfOutputIds];
      submittal.forEach(sf => {
        if (!result?.includes(sf.id)) {
          result.push(sf.id);
        }
      });
      setSelectedSfOutputIds(result);
    }
  };

  const sfInputCustomSelectAll = () => {
    const result = [];
    const push_sf = [];

    submittalsInputData.forEach(item => {
      item?.submittals?.forEach(sf => {
        if (
          localSearchValue &&
          !sf.sf_index.toLowerCase().includes(localSearchValue) &&
          !sf.title.toLowerCase().includes(localSearchValue)
        ) {
          return;
        }

        if (!sf.is_locked) {
          result.push(sf.id);
        }

        if (sf.reflect_input === 1) {
          push_sf.push(sf);
        }
      });
    });
    deleteReflectAll(push_sf, 'submittalOutput', 'push');
    setSelectedSfInputIds(result);
  };

  const sfOutputCustomSelectAll = () => {
    const result = [];
    const push_sf = [];

    submittalsOutputData.forEach(item => {
      item?.submittals?.forEach(sf => {
        if (
          localSearchValue &&
          !sf.sf_index.toLowerCase().includes(localSearchValue) &&
          !sf.title.toLowerCase().includes(localSearchValue)
        ) {
          return;
        }

        if (!sf.is_locked) {
          result.push(sf.id);
        }

        if (sf.reflect_input === 1) {
          push_sf.push(sf);
        }
      });
    });
    deleteReflectAll(push_sf, 'submittalInput', 'push');
    setSelectedSfOutputIds(result);
  };

  const customClearAll = (type: 'input' | 'output') => {
    if (type === 'input') {
      const del_sf = [];
      submittalInputSelected.forEach(sf => {
        if (sf.reflect_input === 1) {
          del_sf.push(sf);
        }
      });
      deleteReflectAll(del_sf, 'submittalOutput', 'delete');
      setSelectedSfInputIds([]);
    } else {
      const del_sf = [];
      submittalOutputSelected.forEach(sf => {
        if (sf.reflect_input === 1) {
          del_sf.push(sf);
        }
      });
      deleteReflectAll(del_sf, 'submittalInput', 'delete');
      setSelectedSfOutputIds([]);
    }

    handleSelectedSubmittal(type, []);
  };

  const deliverablesCustomSelectAll = () => {
    const result = [];

    deliverablesData.forEach(item => {
      item?.drawings?.forEach(drawing => {
        if (localSearchValue && !drawing.title.toLowerCase().includes(localSearchValue)) return;

        drawing?.deliverables?.forEach(deliverable => {
          result.push(deliverable.id);
        });
      });
    });

    setSelectedDeliverablesIds(result);
  };

  const onChangeActiveTab = (activeRelatedItems: RelatedItemsTabsType) => {
    const nf = searchParams.get('nf');
    const nfTab = 'related-items';
    setUrlActiveRelatedItem(nf, nfTab, activeRelatedItems);
  };

  const renderModalContent = () => {
    return (
      <>
        <div className={`${themeClass}_change_response_company_body`}>
          <div className={`${themeClass}_change_response_company_body_infoParty`}>
            <div className={`${themeClass}_change_response_company_body_text`}>You are trying to change Response Party to:</div>
            <div className={`${themeClass}_change_response_company_body_partyBlock`}>
              {selectedResponseCompany?.discipline?.title && (
                <ChipsDiscipline
                  type={'filled'}
                  color={selectedResponseCompany.discipline.text_color}
                  value={selectedResponseCompany.discipline.title}
                />
              )}
              {selectedResponseCompany?.discipline?.title && dot_icon}
              <PlaceholderInfo
                type={'Company'}
                detailInfo={selectedResponseCompany?.company?.title || ''}
                imagePath={
                  (selectedResponseCompany?.company?.renderings?.length && selectedResponseCompany?.company?.renderings[0]?.file) || null
                }
                size={24}
              />
              {selectedResponseCompany?.user?.profile?.first_name && dot_icon}
              {selectedResponseCompany?.user?.profile?.first_name && (
                <PlaceholderInfo
                  type={'Persona'}
                  firstName={selectedResponseCompany?.user?.profile?.first_name || ''}
                  lastName={selectedResponseCompany?.user?.profile?.last_name || ''}
                  imagePath={selectedResponseCompany?.user?.profile?.image}
                  detailInfo={`${selectedResponseCompany?.user?.profile?.first_name || ''} ${
                    selectedResponseCompany?.user?.profile?.last_name || ''
                  }`}
                  isMobile={isMobile}
                  size={24}
                />
              )}
            </div>
          </div>
          <div className={`${themeClass}_change_response_company_body_text`}>
            If you send request, it has to be approved by the responsible person.
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-sidePeekView']: isSidePeekView,
        })}
      >
        <div className={`${themeClass}_mainContainer`}>
          <div className={cn(`${themeClass}_topBlock`, { ['-hiddenFields']: isMobile && isHiddenFields })}>
            <div
              className={cn(`${themeClass}_requestParty`, {
                ['-sidePeekView']: isSidePeekView,
                ['-accordion']: isMobile && !showRequestParty,
              })}
            >
              {renderPartyTitle('request')}
              {showRequestParty && (
                <PartySelector
                  type={'request'}
                  selected={isSkeletonLoading ? null : selectedRequestCompany}
                  handleSelect={item => setSelectedRequestCompany(item)}
                  getSelectTooltipText={getSelectTooltipText}
                  isDisableSelect={isDisableRequestSelect}
                  isDisableDisciplineAndUserSelect={isDisableDisciplineAndUserSelect}
                  errors={errors.request_party_id ? ['company'] : []}
                  customSelectBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                  customPartyContainerClassName={cn(`${themeClass}_requestParty_body`, {
                    ['-sidePeekView']: isSidePeekView,
                  })}
                  isMobile={isMobile}
                  isLoading={isSkeletonLoading}
                  onClickOutside={handleClickOutside}
                  customNFCondition={!selectedRequest || selectedRequest.lastUserStatus.status === 'drafted'}
                  onSelectCompany={() => {
                    setMainErrors({ ...errors, request_party_id: '' });
                  }}
                />
              )}
            </div>
            <div
              className={cn(`${themeClass}_responseParty`, {
                ['-sidePeekView']: isSidePeekView,
                ['-accordion']: isMobile && !showResponseParty,
              })}
            >
              {renderPartyTitle('response')}
              {showResponseParty && (
                <PartySelector
                  type={'response'}
                  selected={isSkeletonLoading ? null : selectedResponseCompany}
                  handleSelect={item => setSelectedResponseCompany(item)}
                  isSentRequestToChange={isSentRequestToChange}
                  isAdmin={isAdmin}
                  responseSelectRef={responceSelectRef}
                  handleChangeCompanyReceived={handleChangeCompanyReceived}
                  getSelectTooltipText={getSelectTooltipText}
                  isDisableSelect={isDisableResponseSelect}
                  isChangePartyActionActive={isChangeResponsePartyActionActive}
                  errors={errors.response_party_id ? ['company'] : []}
                  customPartyContainerClassName={cn(`${themeClass}_responseParty_body`, {
                    ['-isChangeCompanyReceived']: isSentRequestToChange && isAdmin,
                    ['-sidePeekView']: isSidePeekView,
                  })}
                  customOpenedPopupType={openedPopupType}
                  setCustomOpenenPopupType={setOpenedPopupType}
                  customSelectBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                  isMobile={isMobile}
                  isLoading={isSkeletonLoading}
                  onClickOutside={handleClickOutside}
                  customNFCondition={!selectedRequest || selectedRequest.lastUserStatus.status === 'drafted'}
                  onSelectCompany={() => {
                    setMainErrors({ ...errors, request_party_id: '' });
                  }}
                />
              )}
            </div>
            <div className={`${themeClass}_subject`}>
              <div className={`${themeClass}_title`}>Subject</div>
              <CustomTooltip
                enterDelay={1000}
                enterNextDelay={1000}
                leaveDelay={0}
                title={
                  localStorage.getItem('show_tooltips') === 'no'
                    ? ''
                    : currentProjectSpecific
                    ? ''
                    : selectedRequest == null || selectedRequest?.lastUserStatus?.status === 'drafted'
                    ? 'Short title of the NF'
                    : ''
                }
                placement={'top'}
              >
                <div className={`${themeClass}_subject_body`}>
                  <TextInputNew
                    type={'on-bgd'}
                    placeholder={'Input subject'}
                    onChange={handleChangeSpecific}
                    value={isSkeletonLoading ? '' : currentProjectSpecific || ''}
                    isFocus={focusElement === 'Input subject'}
                    onClickIcon={() => null}
                    onFocus={() => setFocusElement('Input subject')}
                    onBlur={() => setFocusElement('')}
                    error={errors.specific}
                    disabled={!isAccess || (isCompanyManagerOrUser && selectedRequest?.status === 'accepted')}
                    isMobile={isMobile}
                    customIcon={
                      <div className={`${themeClass}_subjectLoader ${isSkeletonLoading ? '' : '-hidden'}`}>
                        <MiniLoader size={'xs'} circleClasses={`${themeClass}_subjectLoader_circle`} />
                      </div>
                    }
                  />
                </div>
              </CustomTooltip>
            </div>
            <div
              className={cn(`${themeClass}_location`, {
                ['-hide']: isMobile && isHiddenFields,
              })}
            >
              <div className={`${themeClass}_title`}>Location</div>
              <div className={`${themeClass}_location_body`}>
                <CustomTooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  leaveDelay={0}
                  title={localStorage.getItem('show_tooltips') === 'no' ? '' : buildings.length > 0 ? '' : 'Buildings affected by this NF'}
                  placement={'top'}
                  customBasisClass={`${themeClass}_tooltip`}
                >
                  <CardRenderSelect<DropdownItemModel, null, true>
                    type={'building'}
                    isMulti={true}
                    isHeader
                    isFooter
                    menuItems={buildings}
                    selectHandler={item => handleUpdateMultiSelectState(item, 'building')}
                    selected={isSkeletonLoading ? null : selectedBuildings}
                    isLoading={isSkeletonLoading}
                    basisPlaceholder={'Select building'}
                    menuItemSize={'md'}
                    basisMaxItems={1}
                    customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                    disabled={isPullRequest ? false : !isAccess}
                    isMobile={isMobile}
                  />
                </CustomTooltip>
                <CustomTooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  leaveDelay={0}
                  title={
                    localStorage.getItem('show_tooltips') === 'no' ? '' : levelsSelected.length > 0 ? '' : 'Levels affected by this NF'
                  }
                  placement={'top'}
                  customBasisClass={`${themeClass}_tooltip`}
                >
                  <LevelsSelect<DropdownItemModel, DropdownGroupItem, true>
                    isHeader
                    isFooter
                    selectHandler={null}
                    selected={isSkeletonLoading ? [] : [...levelsSelected, ...typicalLayoutsSelected]}
                    isLoading={isSkeletonLoading}
                    basisPlaceholder={'Select levels'}
                    menuItemSize={'md'}
                    customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                    isFullWidth
                    buildings={buildings}
                    levels={levels}
                    typicalLayouts={typicalLayouts}
                    selectedBuildings={selectedBuildings}
                    setBuildingsSelected={setBuildingsSelected}
                    setLevelsSelected={setLevelsSelected}
                    levelsSelected={levelsSelected}
                    setTypicalLayoutsSelected={setTypicalLayoutsSelected}
                    typicalLayoutsSelected={typicalLayoutsSelected}
                    disabled={isPullRequest || levelsSelected.length === 0 ? false : !isAccess}
                    isMobile={isMobile}
                  />
                </CustomTooltip>
                <CustomTooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  leaveDelay={0}
                  title={
                    localStorage.getItem('show_tooltips') === 'no'
                      ? ''
                      : buildingSpaces.length > 0
                      ? ''
                      : 'No building spaces available. Populate them in Project Settings > Buildings & Locations'
                  }
                  placement={'top'}
                  customBasisClass={`${themeClass}_tooltip`}
                >
                  <BuildingSelect<DropdownItemModel, DropdownGroupItem, true>
                    isHeader
                    isFooter
                    selectHandler={null}
                    selected={isSkeletonLoading ? null : buildingSpacesSelected}
                    isLoading={isSkeletonLoading}
                    basisPlaceholder={'Select building spaces'}
                    menuItemSize={'md'}
                    customBasisFieldClass={isSidePeekView ? `${themeClass}_sidePeekWidth` : ''}
                    isFullWidth
                    buildings={buildings}
                    levels={levels}
                    levelsSelected={levelsSelected}
                    typicalLayoutsSelected={typicalLayoutsSelected}
                    disabled={isPullRequest ? false : !isAccess}
                    buildingSpaces={buildingSpaces}
                    buildingSpacesSelected={buildingSpacesSelected}
                    setBuildingSpacesSelected={setBuildingSpacesSelected}
                    isMobile={isMobile}
                  />
                </CustomTooltip>
              </div>
            </div>
          </div>
          {!isHiddenFields ? (
            <div className={`${themeClass}_bottomBlock`}>
              <div className={`${themeClass}_ufMf`}>
                <div className={`${themeClass}_ufMfBlock`}>
                  <div className={`${themeClass}_ufMfTitle`}>UF</div>
                  <div className={`${themeClass}_ufMfBody`}>
                    <CustomTooltip
                      enterDelay={1000}
                      enterNextDelay={1000}
                      leaveDelay={0}
                      title={
                        localStorage.getItem('show_tooltips') === 'no'
                          ? ''
                          : currentUfcCode?.length > 0
                          ? ''
                          : 'UniFormat codes associated with the request'
                      }
                      placement={'top'}
                    >
                      <CodeSelector
                        isMulti
                        showFooterType={'radio-group'}
                        codeType={'uf'}
                        isValuesRemovable
                        isValuesEditable
                        selected={isSkeletonLoading ? null : currentUfcCode}
                        selectHandler={selectUfCodeHandler}
                        selectedBuildingIds={selectedBuildingsIds?.length ? selectedBuildingsIds : null}
                        basisPlaceholder={`Select UniFormat`}
                        menuItemSize={'md'}
                        basisMaxItems={2}
                        handleFor={'content-plan'}
                        disabled={isPullRequest ? false : !isAccess}
                        isMobile={isMobile}
                        isNfCard
                      />
                    </CustomTooltip>
                  </div>
                </div>
                <div className={`${themeClass}_ufMfBlock`}>
                  <div className={`${themeClass}_ufMfTitle`}>MF</div>
                  <div className={`${themeClass}_ufMfBody`}>
                    <CustomTooltip
                      enterDelay={1000}
                      enterNextDelay={1000}
                      leaveDelay={0}
                      title={
                        localStorage.getItem('show_tooltips') === 'no'
                          ? ''
                          : currentMfCode?.length > 0
                          ? ''
                          : 'MasterFormat codes associated with the request'
                      }
                      placement={'top'}
                    >
                      <CodeSelector
                        isMulti
                        codeType={'mf'}
                        isValuesRemovable
                        isValuesEditable
                        selected={isSkeletonLoading ? null : currentMfCode}
                        selectHandler={selectMfCodeHandler}
                        selectedBuildingIds={selectedBuildingsIds?.length ? selectedBuildingsIds : null}
                        basisPlaceholder={`Select MasterFormat`}
                        menuItemSize={'md'}
                        basisMaxItems={2}
                        handleFor={'content-plan'}
                        disabled={isPullRequest ? false : !isAccess}
                        isMobile={isMobile}
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
              <div className={`${themeClass}_designInput`}>
                <div className={`${themeClass}_title -link`} onClick={() => onChangeActiveTab('document')}>
                  Design drawings
                </div>
                <div className={`${themeClass}_body`}>
                  <CardRenderDesignInput
                    selectedRequestId={selectedRequest?.id}
                    isHandleSelectDraw={isHandleSelectDraw}
                    listDocumentsProps={listDocuments}
                    listDocumentsNoNfProps={list_documents_no_nf}
                    set_status_nf_loading={set_status_nf_loading || isSkeletonLoading}
                    isAccess={isAccessToRequestEdit || isAdmin}
                    isAccessToRemove={isAccess}
                    statusRequest={selectedRequest && StatusNameHelper.getLastUserStatusName(selectedRequest)}
                    selectedRequest={selectedRequest}
                    list_documents_loading={list_documents_loading}
                    buildings={buildings}
                    selectedBuildings={selectedBuildings}
                    add_documents_nf={add_documents_nfSelected || []}
                    getSetStatus={getSetStatus}
                    disabled={!isAccess}
                    isMobile={isMobile}
                    isSidePeekView={isSidePeekView}
                    handleCloseAllNotificationPopups={handleCloseAllNotificationPopups}
                    isAllNotificationPopupsClosed={isAllNotificationPopupsClosed}
                  />
                </div>
              </div>
              <div className={`${themeClass}_submittalInput`}>
                <div className={`${themeClass}_title -link`} onClick={() => onChangeActiveTab('submittalInput')}>
                  Input submittals
                </div>
                <div className={`${themeClass}_body`}>
                  <CardRenderSelect<DropdownItemModel, null, true>
                    type={'submittalInput'}
                    isMulti
                    isCustomMulti={true}
                    isHeader
                    isFooter
                    menuItems={getsubmittalsInputItems()}
                    selected={submittalInputSelected}
                    selectHandler={null}
                    isLoading={submittalsInputLoading}
                    menuItemSize={'md'}
                    basisMaxItems={3}
                    handleMfItemClick={handleMfItemClick}
                    handleSubmittalsItemClick={handleSfItemClick}
                    isFullWidth={true}
                    isHeaderNoMulti={true}
                    isExpandOnHover={true}
                    customClearAll={() => customClearAll('input')}
                    customSelectAll={() => sfInputCustomSelectAll()}
                    isMobile={isMobile}
                    onChangeSearch={handleChangeSearch}
                    onMenuClose={() => handlePopUpClose()}
                    customMenuClass={`${themeClass}_submittalSelectWidth`}
                    customClassPaperName={`${themeClass}_submittalPaper`}
                    isActiveCheckBoxForDisabledItems={true}
                    basisPlaceholder={'Select Input submittals'}
                    handleSelectedSubmittalDelete={handleSelectedSubmittalDelete}
                    basisTooltip={'Input submittals related to this NF'}
                    disabled={!isAccess}
                  />
                </div>
              </div>
              <div className={`${themeClass}_submittalOutput`}>
                <div className={`${themeClass}_title -link -submittalOutputTitle`} onClick={() => onChangeActiveTab('submittalOutput')}>
                  Output submittals
                </div>
                <div className={`${themeClass}_body`}>
                  <CardRenderSelect<DropdownItemModel, null, true>
                    type={'submittalOutput'}
                    isMulti
                    isCustomMulti={true}
                    isHeader
                    isFooter
                    menuItems={getsubmittalsOutputItems()}
                    selected={submittalOutputSelected}
                    selectHandler={null}
                    isLoading={submittalsOutputLoading}
                    menuItemSize={'md'}
                    basisMaxItems={3}
                    handleMfItemClick={handleMfItemOutputClick}
                    handleSubmittalsItemClick={handleSfItemOutputClick}
                    isFullWidth={true}
                    isHeaderNoMulti={true}
                    isExpandOnHover={true}
                    customClearAll={() => customClearAll('output')}
                    customSelectAll={() => sfOutputCustomSelectAll()}
                    isMobile={isMobile}
                    onChangeSearch={handleChangeSearch}
                    onMenuClose={() => handlePopUpClose()}
                    customMenuClass={`${themeClass}_submittalSelectWidth`}
                    customClassPaperName={`${themeClass}_submittalPaper`}
                    isActiveCheckBoxForDisabledItems={true}
                    basisPlaceholder={'Select Output submittals'}
                    handleSelectedSubmittalDelete={handleSelectedSubmittalDelete}
                    basisTooltip={'Output submittals related to this NF'}
                    disabled={!isAccess}
                  />
                </div>
              </div>
              <div className={`${themeClass}_tags`}>
                <div className={`${themeClass}_tagsTitle`}>Tags</div>
                <div
                  className={cn(`${themeClass}_tagsBody`, {
                    ['-isMobile']: isMobile,
                  })}
                >
                  <CustomTooltip
                    enterDelay={1000}
                    enterNextDelay={1000}
                    leaveDelay={0}
                    title={localStorage.getItem('show_tooltips') === 'no' ? '' : hashtags?.length > 0 ? '' : 'Tags for labeling NFs'}
                    placement={'top'}
                  >
                    <div style={{ width: '100%' }}>
                      {isSkeletonLoading ? (
                        <div className={`${themeClass}_skeletonTags`}>
                          <CustomSkeleton width={50} height={24} />
                          <CustomSkeleton width={80} height={24} />
                          <CustomSkeleton width={70} height={24} />
                        </div>
                      ) : (
                        <HashtagsEllipsis
                          isAccess={isAccess}
                          onChangeHashtags={handleChangeHashtags}
                          tags={hashtags}
                          isHashTagChanging={false}
                          isSandBox={false}
                        />
                      )}
                    </div>
                  </CustomTooltip>
                </div>
              </div>
              <div className={`${themeClass}_deliverables`}>
                <div className={`${themeClass}_title -link`} onClick={() => onChangeActiveTab('deliverable')}>
                  CL drawings
                </div>
                <CustomTooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  leaveDelay={0}
                  title={
                    localStorage.getItem('show_tooltips') === 'no' || selectedDeliverablesIds?.length
                      ? ''
                      : 'Deliverables related to this NF'
                  }
                  placement={'top'}
                >
                  <div className={`${themeClass}_body`} ref={notVerifiedItemsPopupRef}>
                    <CardRenderSelect<DropdownItemModel, null, null>
                      type={'deliverables'}
                      isMulti={true}
                      isCustomMulti={true}
                      isHeader
                      isFooter
                      menuItems={deliverablesData}
                      selected={isSkeletonLoading ? null : drawingsWithSelectedDeliverables}
                      selectHandler={null}
                      isLoading={!!pcdDeliverablesListLoading || !!isSkeletonLoading || !!isLoadingRequestByNf}
                      basisPlaceholder={'Select CL drawings'}
                      footerType={'divided-button'}
                      menuItemSize={'md'}
                      basisMaxItems={3}
                      customMenuClass={`${themeClass}_parentSelectWidth`}
                      handleBuildingItemClick={handleBuildingItemClick}
                      handleDrawingItemClick={handleDrawingItemClick}
                      handleDeliverablesItemClick={handleDeliverablesItemClick}
                      openedBuildingIds={openedBuildingIds}
                      openedDrawingIds={openedDrawingIds}
                      footerButtonClick={() => handleOpenDeliverablesList()}
                      footerButtonTitle={'Manage statuses'}
                      handleOpenLinkPopover={handleOpenLinkPopover}
                      isFullWidth={true}
                      handleSelectedDrawingDelete={handleSelectedDrawingDelete}
                      handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
                      isHeaderNoMulti={true}
                      isExpandOnHover={true}
                      customClearAll={() => setSelectedDeliverablesIds([])}
                      customSelectAll={() => deliverablesCustomSelectAll()}
                      disabled={!isAccess}
                      selectType={drawingsWithSelectedDeliverables?.length ? 'deliverables' : ''}
                      isMobile={isMobile}
                      onChangeSearch={handleChangeSearch}
                      selectedDeliverablesIds={selectedDeliverablesIds}
                      deliverablesCustomClassName={`${themeClass}_deliverablesCustomClassName`}
                      onMenuOpen={() => setIsDeliverablesPopUpOpened(true)}
                      onMenuClose={() => setIsDeliverablesPopUpOpened(false)}
                    />
                  </div>
                </CustomTooltip>
              </div>
              {userInfo.qc_user ? (
                <div className={`${themeClass}_impact`}>
                  <div className={`${themeClass}_title`}>CL impact</div>
                  <div className={`${themeClass}_body -impact`}>
                    <ImpactSelect
                      items={isSkeletonLoading ? [] : clImpactsSelected}
                      onChange={handleChangeImpacted}
                      loading={projectInfoLoading.loading || isSkeletonLoading}
                      type={'Need Form'}
                      onResetAll={() => {
                        setClImpactsSelected([]);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={`${themeClass}_impact -empty`}></div>
              )}
            </div>
          ) : null}
        </div>
        <div className={`${themeClass}_subContainer`}>
          {renderShowMoreButton()}
          {isCreateRequest || isPullRequest ? null : (
            <CustomTooltip
              enterDelay={500}
              leaveDelay={0}
              title={canUpdateDescription ? 'Click Update at the bottom of the card to save changes' : ''}
              placement={'top'}
            >
              <CustomButton
                type={'tertiary'}
                size={'sm'}
                clickHandler={() => handleUpdateTopCard()}
                title={'Update'}
                disabled={!canUpdateTopCard || idRequestLoading || !selectedRequestCompany?.company}
                isMobile={isMobile}
              />
            </CustomTooltip>
          )}
        </div>
      </div>

      {confirmChangeCompany && !openedPopupType?.length && (
        <DialogPopUp
          open={!!confirmChangeCompany && !openedPopupType?.length}
          onClose={() => handleResetCompany()}
          aria-labelledby="form-dialog-title Change Response Company modal"
          isOverlay={true}
          title={'Send a change request?'}
          renderModalContent={renderModalContent}
          primaryText={'Send request'}
          handleOnPrimary={sendChangeResponseCompany}
          customTertiaryType={!(isAdmin && !isChangeResponsePartyActionActive) ? 'primary' : 'tertiary'}
          secondaryText={isAdmin && !isChangeResponsePartyActionActive ? 'Change now' : null}
          handleOnSecondary={isAdmin && !isChangeResponsePartyActionActive ? () => changeCompany(selectedResponseCompany) : null}
          customSecondaryType={!(isAdmin && !isChangeResponsePartyActionActive) ? 'tertiary' : 'text-plain'}
          tertiaryText={'Cancel'}
          handleOnTertiary={handleResetCompany}
          customClassname={`${themeClass}_dialogPopUp`}
        />
      )}
      {isOpenLinksPopover && (
        <Popover
          id={'deliverables-links'}
          open={!!isOpenLinksPopover}
          anchorEl={deliverablesLinksRef}
          onClose={handleCloseLinkPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
        >
          <div className={`${themeClass}_deliverablesLinks`}>
            <div className={`${themeClass}_deliverablesLinks_header`}>Open Deliverable card:</div>
            <div className={`${themeClass}_deliverablesLinks_content`}>
              {linksPopoverValue?.deliverables?.length &&
                linksPopoverValue?.deliverables?.map(deliverable => {
                  if (selectedDeliverablesIds.includes(deliverable.id)) {
                    return (
                      <Links
                        type={'a'}
                        value={deliverable.title}
                        onClick={e => {
                          e.preventDefault(), e.stopPropagation(), openPcdCard(deliverable, linksPopoverValue.id);
                        }}
                        to_href={returnUrlDeliverablesCardTab(deliverable)}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </Popover>
      )}

      <Popover
        id={'decline-reason'}
        open={!!isDeclinePopover}
        anchorEl={isDeclinePopover}
        onClose={handleCloseDeclinePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={`${themeClass}_declinePopup`}>
          <div className={`${themeClass}_declinePopup_inputSection`}>
            <TextInputNew
              type={'on-bgd'}
              placeholder={'Enter a decline reason'}
              onChange={setDeclineMsg}
              value={declineMsg || ''}
              label={'Decline reason'}
              isFocus={focusElement === 'Decline reason'}
              onClickIcon={() => null}
              onFocus={() => setFocusElement('Decline reason')}
              onBlur={() => setFocusElement('')}
            />
          </div>
          <div className={`${themeClass}_declinePopup_buttonsSection`}>
            <CustomButton type={'text-plain'} size={'md'} clickHandler={handleCloseDeclinePopup} title={'Cancel'} />
            <CustomButton type={'decline'} size={'md'} clickHandler={() => handleNewCompany('rejected')} title={'Decline'} />
          </div>
        </div>
      </Popover>

      <Popover
        id={'not-verified'}
        open={isMobile || isHiddenFields || !notVerifiedItemsPopupRef?.current ? false : !!isNotVerifiedItemsPopup}
        anchorEl={notVerifiedItemsPopupRef?.current}
        onClose={handleCloseNotVerifiedItemsPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div className={`${themeClass}_notVerifiedPopup`}>
          <div className={`${themeClass}_notVerifiedPopup_messageBlock`}>
            <span className={`${themeClass}_notVerifiedPopup_messageBlock_title`}>
              Latest NF updates might have impacted assigned Deliverables
            </span>
            <span className={`${themeClass}_notVerifiedPopup_messageBlock_text`}>Please verify the statuses.</span>
          </div>
          <div className={`${themeClass}_notVerifiedPopup_btnContainer`}>
            <CustomButton
              type={'primary'}
              size={'md'}
              clickHandler={() => handleOpenDeliverablesList()}
              title={'Review'}
              disabled={!isAccess}
            />
          </div>
        </div>
      </Popover>

      {isOpenDeliverablesList && (
        <ManageStatuses
          type={'deliverables'}
          popUpIsOpen={isOpenDeliverablesList}
          currentValue={selectedDeliverablesIds}
          handleClose={handleCloseDeliverablesList}
          getSetStatus={getSetStatus}
          handleSaveList={handleChangeNfStatus}
          relatedTabData={relatedTabData}
        />
      )}

      <Popover
        id={`select-${openedPopupType}`}
        open={!!changeCompanyReceived}
        anchorEl={anchorEl}
        onClose={() => setChangeCompanyReceived(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: `${themeClassCallout} ${themeClassPaper}`,
        }}
      >
        <div className={`${themeClass}_requestedTheChangePopup`}>
          <div className={`${themeClass}_requestedTheChangePopup_userSection`}>
            <div className={`${themeClass}_requestedTheChangePopup_userSection_title`}>Requested the change of the response party</div>
            <div className={`${themeClass}_requestedTheChangePopup_userSection_item`}>
              <span className={`${themeClass}_requestedTheChangePopup_userSection_item_text`}>from</span>
              <PlaceholderInfo
                type={'Company'}
                detailInfo={selectedRequest?.response_party?.company || ''}
                imagePath={selectedRequest?.response_party?.renderings?.file}
                size={24}
              />
              <PlaceholderInfo
                type={'Persona'}
                firstName={`${selectedRequest?.response_user?.profile?.first_name || ''}`}
                lastName={`${selectedRequest?.response_user?.profile?.last_name || ''}`}
                detailInfo={`${selectedRequest?.response_user?.profile?.first_name || ''} ${
                  selectedRequest?.response_user?.profile?.last_name || ''
                }`}
                imagePath={selectedRequest?.response_user?.profile?.image}
                size={24}
              />
            </div>
            <div className={`${themeClass}_requestedTheChangePopup_userSection_item`}>
              <span className={`${themeClass}_requestedTheChangePopup_userSection_item_text`}>to</span>
              <PlaceholderInfo
                type={'Company'}
                detailInfo={selectedRequest?.response_company_change_request?.company?.company || ''}
                imagePath={selectedRequest?.response_company_change_request?.company?.renderings?.file}
                size={24}
              />
              {selectedRequest?.response_company_change_request?.response_user ? (
                <PlaceholderInfo
                  type={'Persona'}
                  firstName={`${selectedRequest?.response_company_change_request?.response_user?.profile.first_name || ''}`}
                  lastName={`${selectedRequest?.response_company_change_request?.response_user?.profile.last_name || ''}`}
                  imagePath={`${selectedRequest?.response_company_change_request?.response_user?.profile.image || ''}`}
                  detailInfo={`${selectedRequest?.response_company_change_request?.response_user?.profile?.first_name || ''} ${
                    selectedRequest?.response_company_change_request?.response_user?.profile?.last_name || ''
                  }`}
                  size={24}
                />
              ) : null}
            </div>
          </div>
          <div className={`${themeClass}_requestedTheChangePopup_buttonsSection`}>
            <CustomButton
              type={'accept'}
              size={'md'}
              clickHandler={() => handleNewCompany('accepted')}
              title={'Accept'}
              disabled={
                isPullRequest ? false : !isAccess || selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided'
              }
            />
            <CustomButton type={'decline'} size={'md'} clickHandler={handleOpenDeclinePopover} title={'Decline'} />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default TopCardPart;
