import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import Placeholder from '../../../../controls/Placeholder/Placeholder';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { BreadcrumbType } from '../../ReportingView';
import { Breadcrumbs } from '../../../../controls/Breadcrumbs';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';

import './ReportingProfileStyle.scss';

const ReportingProfile: FC<{
  is_user: boolean;
  loading: boolean;
  isVisibleProfile: boolean;
  sizeResolution: string;
  data;
  breadcrumbs: BreadcrumbType[];
  selectedUser: string;
  selectedCompanyName: string;
  breadcrumbsSelect: BreadcrumbType;
  handleSelectBreadcrumbs: (item: BreadcrumbType) => void;
}> = ({
  is_user,
  loading,
  isVisibleProfile,
  sizeResolution,
  data,
  breadcrumbs,
  selectedUser,
  selectedCompanyName,
  breadcrumbsSelect,
  handleSelectBreadcrumbs,
}) => {
  const themeClass = useGetThemeClass('b-reportingProfile');

  const getJoined = () => {
    const joined = data?.joined ? moment(data.joined).format('DD MMMM YYYY') : null;
    return joined;
  };
  const getParticipating = () => {
    const projects = data?.projects > 1 || data?.projects === 0 ? `${data?.projects} projects` : `${data?.projects} project`;
    return projects;
  };
  const getCreated = () => {
    const created_nfs = data?.created_nfs ? `${data?.created_nfs} NFs` : '0 NFs';
    return created_nfs;
  };
  const getUserCount = () => {
    const users = data?.users > 1 || data?.users === 0 ? `${data?.users} users` : `${data?.users} user`;
    return users;
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        [`-empty${sizeResolution}`]: sizeResolution === '1024' || sizeResolution === '960',
      })}
    >
      {breadcrumbs && selectedCompanyName && (
        <Breadcrumbs<BreadcrumbType>
          breadcrumbs={breadcrumbs}
          breadcrumbOnClick={handleSelectBreadcrumbs}
          isBreadcrumbLoading={false}
          selectedBreadcrumb={breadcrumbsSelect}
        />
      )}
      {isVisibleProfile && (
        <>
          <div>
            {selectedUser ? (
              <div className={`${themeClass}_user-company`}>
                <div className={'first_text'}> {selectedUser} </div>
                <TokenIcon iconName={'dot'} size={12} />
                <div className={'second_text'}> {selectedCompanyName} </div>
              </div>
            ) : (
              <div className={`${themeClass}_name_company`}> {selectedCompanyName} </div>
            )}
          </div>
          <div className={`${themeClass}_content`}>
            {!is_user ? (
              <Placeholder type={'Company'} size={144} imagePath={data?.renderings?.file ? data.renderings.file : null} />
            ) : (
              <Placeholder type={'Persona'} firstName={data?.first_name} lastName={data?.last_name} size={144} imagePath={data?.image} />
            )}
            <div className={`${themeClass}_content_right`}>
              <div className="row_container">
                <div className={'label'}>Joined: </div>
                <div className={'text'}>{!loading && getJoined()}</div>
              </div>
              <div className="row_container">
                <div className={'label'}>Participating in : </div>
                <div className={'text'}>{!loading && getParticipating()}</div>
              </div>
              <div className="row_container">
                <div className={'label'}>Created: </div>
                <div className={'text'}>{!loading && getCreated()}</div>
              </div>
              {!is_user && (
                <div className="row_container">
                  <div className={'label'}>User count: </div>
                  <div className={'text'}>{!loading && getUserCount()}</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ReportingProfile);
