export const styles = theme => ({
  root: {
    overflowX: 'auto',
    // padding: '0 10px',
    backgroundColor: 'var(--background-main)',
  },
  table: {
    minWidth: 700,
    tableLayout: 'fixed',
    marginTop: '120px',
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: '#2b3141',
      borderTop: '2px solid #2b3141',
    },
    '&:nth-of-type(odd)': {
      borderTop: '2px solid #363953',
      backgroundColor: '#363953',
    },
  },
  tableHead: {
    backgroundColor: 'var(--background-header)',
    textTransform: 'uppercase',
    fontWeight: 'var(--font-weight)',
  },
  tableCells: {
    fontFamily: 'var(--font-general)',
    width: '20%',
    '&:hover': {
      cursor: 'pointer',
      '& .projectCell-ToogleEditModePanel': {
        opacity: 1,
      },
    },
    '& p': {
      wordBreak: 'break-word',
      fontFamily: 'var(--font-general)',
    },
    '& .projectCell-ToogleEditModePanel': {
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'inline-block',
      opacity: 0,
      zIndex: 668,
      '& svg': {
        color: '#e2cb0d',
      },
    },
    borderRight: 'var(--background-border)',
    color: 'var(--color)',
    fontSize: `${theme.spacing.unit * 1.5}px`,
    padding: `${theme.spacing.unit * 0.5}px`,
    verticalAlign: 'middle',
    position: 'relative',
    minWidth: '100px',
  },
  tableGenDescCellOdd: {
    borderTop: '2px solid #4a5664',
    backgroundColor: '#4a5664',
  },
  tableGenDescCellEven: {
    borderTop: '2px solid #415154',
    backgroundColor: '#415154',
  },
  tableUniFCodeCellOdd: {
    borderTop: '2px solid #4e587e',
    backgroundColor: '#4e587e',
  },
  tableUniFCodeCellEven: {
    borderTop: '2px solid #465370',
    backgroundColor: '#465370',
  },
  tableCommitCellOdd: {
    borderTop: '2px solid #4a3b56',
    backgroundColor: '#4a3b56',
  },
  tableCommitCellEven: {
    borderTop: '2px solid #413344',
    backgroundColor: '#413344',
  },
  tableCellsTextArea: {},
  fixedHeader: {
    display: 'table',
    position: 'fixed',
    right: 0,
    // width: '85%',
    top: '154px',
    zIndex: 2,
  },
  scrollToTop: {
    position: 'fixed',
    right: '2%',
    bottom: '60px',
    borderRadius: '50%',
    backgroundColor: '#e1e1d2',
    height: '40px',
    cursor: 'pointer',
    zIndex: '2',
    // border: '1px #fff solid',
  },
  topButton: {
    width: '40px',
    height: '40px',
    color: '#68759c',
  },
  wrapNotificationCards: {
    padding: '0 10px',
  },
});
