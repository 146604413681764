import React, { ReactElement, ReactNode, RefObject, useRef } from 'react';
import SystemButton from '../../ButtonComponents/SystemButton/SystemButton';
import cn from 'classnames';
import { ItemsCounter } from '../../ItemsСounter';
import Divider from '../../Divider/Divider';
import { KeyValueModel } from '../../../../models/key-value.model';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { useVirtualizer } from '@tanstack/react-virtual';
import ContainerListItemSnippet from './ContainerListItemSnippet';

interface IProps<T> {
  accordion: T & any;
  renderItem: (item: T, alias: string) => ReactElement | ReactNode;
  accordionStates: KeyValueModel<boolean> | null;
  accordionRefs: KeyValueModel<RefObject<HTMLElement>>;
  handleAccordion: (alias: string) => void;
  isMobile?: boolean;
  isShortView?: boolean;
  tableRef: RefObject<HTMLElement>;
}

function ContainedListItem<T>({
  accordion,
  renderItem,
  accordionStates,
  accordionRefs,
  handleAccordion,
  isMobile,
  tableRef,
  isShortView,
}: IProps<T>) {
  const themeClass = useGetThemeClass('b-containedList');

  const parentRef = useRef<HTMLDivElement>();

  const rowVirtualizer = useVirtualizer({
    count: accordion.data?.length,
    getScrollElement: () => tableRef?.current,
    estimateSize: () => 33,
    getItemKey: index => accordion.data[index].id,
    scrollMargin: parentRef.current?.offsetTop - 41 ?? 0,
    overscan: 5,
  });

  return (
    <div className={`${themeClass}_table_group`} ref={parentRef}>
      <div
        className={`${themeClass}_table_group_accordion`}
        ref={accordionRefs[accordion.alias]}
        onClick={() => handleAccordion(accordion.alias)}
      >
        <div className={`${themeClass}_table_group_accordion_arrow_container`}>
          {accordionStates[accordion.alias] ? (
            <SystemButton type={'chevron-down'} variant={'transparent'} size={'md'} isMobile={isMobile} />
          ) : (
            <SystemButton type={'chevron-right'} variant={'transparent'} size={'md'} isMobile={isMobile} />
          )}
          <div className={cn(`${themeClass}_table_group_accordion_label_text`, { ['-mobile']: isMobile })}>{accordion.title}</div>
        </div>
        <ItemsCounter value={accordion.data?.length > 99 ? '99+' : accordion.data?.length} size={isMobile ? 'mobile' : 'sm'} />
      </div>
      <div className={cn(`${themeClass}_table_group_container`, { ['-none_display']: accordionStates[accordion.alias] })}>
        <Divider direction={'horizontal'} type={'srf-5'} />
      </div>
      <div
        style={{
          position: 'relative',
          height: rowVirtualizer.getTotalSize(),
        }}
        className={cn(`${themeClass}_table_group_container`, { ['-none_display']: !accordionStates[accordion.alias] })}
      >
        <Divider direction={'horizontal'} type={'srf-5'} />
        {rowVirtualizer.getVirtualItems().map(virtualItem => {
          return (
            <ContainerListItemSnippet
              key={virtualItem.key}
              renderItem={renderItem}
              virtualItem={virtualItem}
              accordion={accordion}
              offset={parentRef.current?.offsetTop - 41}
              isShortView={isShortView}
              virtualizer={rowVirtualizer}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ContainedListItem;
