import { GlobalFiltersQueryModel, ProjectSimpleModel } from '../../models';
import moment from 'moment';
import { randomString } from 'react-inlinesvg/lib/helpers';
import { DatabaseService } from './DBConfig';
import { indexedDbSlice } from '../../store/indexedDb/indexedDb';

const { setField } = indexedDbSlice.actions;

export const saveNeedListCashingTime = (activeProjectId: number): void => {
  const isNeedListOutdated = JSON.parse(sessionStorage.getItem('isNeedListOutdated'));
  if (isNeedListOutdated) return;
  const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));

  const newNeedListCashedData = { ...needListCashedData };

  newNeedListCashedData[activeProjectId] = { cachedTimestamp: new Date() };
  localStorage.setItem('needListCashedData', JSON.stringify(newNeedListCashedData));
};

export const isNeedListCached = (activeProjectId: number): boolean => {
  const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));

  if (needListCashedData && needListCashedData[activeProjectId]) {
    return !!needListCashedData[activeProjectId]?.cachedTimestamp;
  }

  return false;
};

export const clearNeedListCacheProfile = (projects: ProjectSimpleModel[]): void => {
  localStorage.removeItem('needListCashedData');
  sessionStorage.removeItem('isNeedListLoadingStatus');
  projects.forEach(project => {
    DatabaseService.clearData({ storeName: 'NEEDLIST', projectId: project.id });
  });
};

export const clearNeedListCacheLogOut = async dispatch => {
  localStorage.removeItem('needListCashedData');
  sessionStorage.removeItem('isNeedListLoadingStatus');
  dispatch && dispatch(setField({ field: 'isDbCreated', value: false }));

  await DatabaseService.handleLogout();
};

export const getIsFilters = (data: GlobalFiltersQueryModel): boolean => {
  const { filters, or_filters, or_conditions, search, order_by, conditions, default_filter } = data;

  if (filters && Object.keys(filters)?.length) return true;
  if (or_filters && Object.keys(or_filters)?.length) return true;
  if (or_conditions && Object.keys(or_conditions)?.length) return true;
  if (conditions && Object.keys(conditions)?.length) return true;
  if (search && search.length) return true;
  if (order_by && order_by !== 'nf') return true;
  if (default_filter && default_filter.length) return true;

  return false;
};

export const checkIsDateAfter = (firstDate: string, secondDate: string): boolean => {
  return moment(firstDate).isAfter(secondDate);
};

export const generateAndSetHash = (): string => {
  const hash = randomString(15);
  sessionStorage.setItem('needListHash', hash);

  return hash;
};

export function filterArrayByField(arrayOfObjects, fieldArray, field) {
  const objectLookup = {};
  arrayOfObjects.forEach(obj => {
    objectLookup[obj[field]] = obj;
  });

  return fieldArray.map(fieldValue => objectLookup[fieldValue]).filter(Boolean);
}

export function updateMainArray(mainArray, updatedItemsArray, wasDeleted, projectId) {
  const newArray = [...mainArray];
  const updateArray = updatedItemsArray.reverse();
  // Iterate over updated items
  updateArray.length &&
    updateArray.forEach(updatedItem => {
      // Find the index of the item in the main array
      const index = newArray.findIndex(item => item.id === updatedItem.id);

      if (index > -1) {
        // If the item exists in the main array, update it
        newArray[index] = { ...updatedItem };
        DatabaseService.updateById({
          newValue: updatedItem,
          key: updatedItem.id,
          storeName: `NEEDLIST`,
          projectId: projectId,
        });
      } else {
        // If the item doesn't exist in the main array, add it to the beginning
        newArray.unshift(updatedItem);
        DatabaseService.addItem({ item: updatedItem, storeName: `NEEDLIST`, projectId: projectId });
      }
    });
  wasDeleted.length &&
    wasDeleted.forEach(deletedItemId => {
      // Find the index of the item in the main array
      const deletedIndex = newArray.findIndex(item => item.id === deletedItemId);

      newArray.splice(deletedIndex, 1);
      DatabaseService.deleteById({ itemId: deletedItemId, storeName: `NEEDLIST`, projectId: projectId });
    });

  sessionStorage.setItem('isNeedListOutdated', JSON.stringify(false));
  saveNeedListCashingTime(projectId);

  return newArray;
}
