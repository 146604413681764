export type statusRevisionType = 'issued' | 'superseded' | 'not_issued_yet';
export type reviewStateType = 'pending' | 'approved' | 'returned';
export type validationType = 'valid' | 'no_action_taken' | 'void' | 'not valid' | 'not verified';
export type statusNFType =
  | 'submitted'
  | 'submitted under'
  | 'drafted'
  | 'new'
  | 'sent'
  | 'deactivated'
  | 'provided'
  | 'declined'
  | 'accepted'
  | 'closed';
export type drawingType = 'Included with changes' | 'Included without changes' | 'Excluded' | 'Not included';

export const statusRevision = {
  issued: 'issued',
  superseded: 'superseded',
  not_issued_yet: 'not_issued_yet',
};
export const reviewState = {
  pending: 'pending',
  approved: 'approved',
  returned: 'returned',
};
export const validationConst = {
  valid: 'valid',
  not_valid: 'not valid',
  not_verified: 'not verified',
};
export const statusNFConst = {
  submitted: 'submitted',
  submitted_under: 'submitted under',
  drafted: 'drafted',
  new: 'new',
  sent: 'sent',
  deactivated: 'deactivated',
  provided: 'provided',
  declined: 'declined',
  accepted: 'accepted',
  reOpened: 'Re-opened',
  closed: 'closed',
};
export const drawingConst = {
  included_with_changes: 'Included with changes',
  included_without_changes: 'Included without changes',
  excluded: 'Excluded',
  not_included: 'Not included',
};
