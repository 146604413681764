// Fields for "Hide/Show columns"
export const switchColumns = {
  ALL: 'ALL',
  REQUEST_DISCIPLINE: 'REQUEST_DISCIPLINE',
  LEVELS: 'LEVELS',
  TYPICAL_LAYOUTS: 'TYPICAL LAYOUTS',
  BUILDING_SPACES: 'BUILDING SPACES',
  BUILDING: 'BUILDING',
  RESPONSE_COMPANY: 'RESPONSE_COMPANY',
  REQUEST_COMPANY: 'REQUEST_COMPANY',
  PREDECESSORS: 'PREDECESSORS',
  PROJECT_SPECIFIC_DESCRIPTION: 'PROJECT_SPECIFIC_DESCRIPTION',
  NAME: 'NAME',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  RESPONSE_MANAGER: 'RESPONSE_MANAGER',
  EMAIL: 'EMAIL',
  NF: 'NF',
  SOURCE: 'SOURCE',
  INCORPORATED: 'INCORPORATED',
  INCORPORATION_TASKS: 'INCORPORATION_TASKS',
  INCORPORATION_SUBTASKS: 'INCORPORATION_SUBTASKS',
  INCORPORATED_TASK_ASSIGNEE: 'INCORPORATED_TASK_ASSIGNEE',
  INCORPORATED_TASK_DUE_DATES: 'INCORPORATED_TASK_DUE_DATES',
  INCORPORATED_TASK_LEVELS: 'INCORPORATED_TASK_LEVELS',
  INCORPORATED_TASK_SCOPE: 'INCORPORATED_TASK_SCOPE',
  INCORPORATED_TASK_STATUS: 'INCORPORATED_TASK_STATUS',
  INCORPORATED_SUBTASK_AUTHORS: 'INCORPORATED_SUBTASK_AUTHORS',
  INCORPORATED_SUBTASK_COMPLETED_DATES: 'INCORPORATED_SUBTASK_COMPLETED_DATES',
  DESCRIPTION: 'DESCRIPTION',
  SUCCESSOR: 'SUCCESSOR',
  COMMITMENT_STATUS: 'COMMITMENT_STATUS',
  COMMITMENT_FINAL: 'COMMITMENT_FINAL',
  NF_STATUS: 'NF_STATUS',
  DUE_DATE: 'DUE_DATE',
  CREATED: 'CREATED',
  UFC: 'UFC',
  MFC: 'MFC',
  EDIT: 'EDIT',
  REQUEST_FILES: 'REQUEST_FILES',
  RESPONSE_DISCIPLINE: 'RESPONSE_DISCIPLINE',
  MILESTONE: 'MILESTONE',
  DESIGN_DOCUMENT_LOG: 'DESIGN_DOCUMENT_LOG',
  HASHTAGS: 'HASHTAGS',
  COMMITMENT_FINAL_FILE: 'COMMITMENT_FINAL_FILE',
  DELETE: 'DELETE',
  NF_ACTION_BY: 'NF_ACTION_BY',
  NF_ACTION_DATE: 'NF_ACTION_DATE',
  DELIVERABLE_AUTHOR: 'DELIVERABLE_AUTHOR',
  COMMENTS: 'COMMENTS',
  COMMENT_AUTHOR: 'COMMENT_AUTHOR',
  COMMENT_DATE: 'COMMENT_DATE',
  COMMENT_MENTION: 'COMMENT_MENTION',
  MY_REQUEST: 'MY_REQUEST',
  BALL_IN_COURT: 'BALL_IN_COURT',
  BALL_IN_COURT_MANAGER: 'BALL_IN_COURT_MANAGER',
  BALL_IN_COURT_PARTY: 'BALL_IN_COURT_PARTY',
  PRIVATE: 'PRIVATE',
  RESPONSE_PARTY_CHANGE: 'RESPONSE_PARTY_CHANGE',
  DUE_DATE_CHANGE: 'due_date_change',
  WATCHERS: 'WATCHERS',
  DELIVERABLES: 'DELIVERABLES',
  SUBMITTALS_OUTPUT: 'SUBMITTALS_OUTPUT',
  SUBMITTALS_INPUT: 'SUBMITTALS_INPUT',
  REQUEST_SENT: 'REQUEST_SENT',
  COMMITMENT_SUBMITTED: 'COMMITMENT_SUBMITTED',
  RESPONSE_SUBMITTED: 'RESPONSE_SUBMITTED',
  RESPONSE_ACCEPTED: 'RESPONSE_ACCEPTED',
  RESPONSE_DECLINED: 'RESPONSE_DECLINED',
  GEOMETRIES: 'GEOMETRIES',
  CL_IMPACTS: 'CL_IMPACTS',
};

// Fields for get filters from API
export const filterFields = {
  LEVELS: 'levels',
  TYPICAL_LAYOUTS: 'typicalLayouts',
  BUILDING_SPACES: 'building_spaces',
  BUILDING: 'buildings',
  REQUEST_DISCIPLINE: 'request_disciplines',
  RESPONSE_COMPANY: 'response_party',
  REQUEST_COMPANY: 'request_party',
  PREDECESSORS: 'successors',
  PROJECT_SPECIFIC_DESCRIPTION: 'request_description',
  NAME: 'authors',
  REQUEST_MANAGER: 'users',
  RESPONSE_MANAGER: 'response_managers',
  EMAIL: 'commitment_users',
  COMMITMENT_STATUS: 'commitment_status',
  NF: 'nf',
  DESCRIPTION: 'subject',
  SUCCESSOR: 'predecessors',
  COMMITMENT_FINAL: 'final_deliverable',
  DUE_DATE: 'due_date',
  REQUEST_FILE: 'request_files',
  CREATED: 'created_at',
  UFC: 'ufc',
  MFC: 'mf',
  RESPONSE_DISCIPLINE: 'response_disciplines',
  SOURCE: 'tags',
  INCORPORATED: 'incorporated',
  INCORPORATED_TASK_ASSIGNEE: 'incorporated_task_assignee',
  INCORPORATED_TASK_DUE_DATES: 'incorporated_task_due_dates',
  INCORPORATED_TASK_LEVELS: 'incorporated_task_levels',
  INCORPORATED_TASK_SCOPE: 'incorporated_task_scope',
  INCORPORATED_TASK_STATUS: 'incorporated_task_status',
  INCORPORATED_SUBTASK_AUTHORS: 'incorporated_subtask_authors',
  INCORPORATED_SUBTASK_COMPLETED_DATES: 'incorporated_subtask_completed_dates',
  NF_STATUS: 'nf_status',
  HASHTAGS: 'hashtags',
  MILESTONE: 'milestones',
  DESIGN_DOCUMENT_LOG: 'design_documents_log',
  COMMITMENT_FINAL_FILE: 'deliverable_files',
  NF_ACTION_BY: 'nf_action_by',
  NF_ACTION_DATE: 'nf_action_date',
  DELIVERABLE_AUTHOR: 'deliverable_author',
  COMMENTS: 'comments',
  COMMENT_DATE: 'comment_date',
  COMMENT_AUTHOR: 'comment_author',
  COMMENT_MENTION: 'comment_mention',
  MY_REQUEST: 'my_request',
  BALL_IN_COURT: 'ball_in_court',
  BALL_IN_COURT_MANAGER: 'bic_manager',
  BALL_IN_COURT_PARTY: 'bic_party',
  PRIVATE: 'private',
  RESPONSE_PARTY_CHANGE: 'response_party_change',
  DUE_DATE_CHANGE: 'due_date_change',
  WATCHERS: 'watchers',
  DELIVERABLES: 'pcd_deliverables',
  SUBMITTALS_OUTPUT: 'submittals_output',
  SUBMITTALS_INPUT: 'submittals_input',
  REQUEST_SENT: 'date_status_sent',
  COMMITMENT_SUBMITTED: 'date_status_submitted',
  RESPONSE_SUBMITTED: 'date_status_response',
  RESPONSE_ACCEPTED: 'date_status_accepted',
  RESPONSE_DECLINED: 'date_status_declined',
  GEOMETRIES: 'geometries',
  CL_IMPACTS_YES: 'cl_impact_yes',
  CL_IMPACTS_NO: 'cl_impact_no',
  CL_IMPACTS_NOT_REVIEWED: 'cl_impact_not_reviewed',
  NF_PLANIT: 'nf_planit_worked',
  MY_HOT_LIST: 'my_hot_list',
  COMPANY_HOT_LIST: 'company_hot_list',
};

// Request fields for search by column
export const searchFields = {
  LEVELS: 'levels',
  TYPICAL_LAYOUTS: 'typicalLayouts',
  BUILDING_SPACES: 'building_spaces',
  BUILDING: 'building',
  REQUEST_DISCIPLINE: 'request_discipline',
  RESPONSE_COMPANY: 'response_party',
  REQUEST_COMPANY: 'request_party',
  PREDECESSORS: 'successors',
  PROJECT_SPECIFIC_DESCRIPTION: 'project_specific',
  NAME: 'author',
  REQUEST_MANAGER: 'user',
  RESPONSE_MANAGER: 'response_manager',
  EMAIL: 'commitment_author',
  COMMITMENT_STATUS: 'commitment_status',
  NF: 'nf',
  DESCRIPTION: 'project_specific_description',
  SUCCESSOR: 'predecessors',
  COMMITMENT_FINAL: 'final_desc',
  DUE_DATE: 'due_date',
  CREATED: 'created_at',
  UFC: 'ufc',
  MFC: 'mfc',
  RESPONSE_DISCIPLINE: 'response_discipline',
  SOURCE: 'tag',
  INCORPORATED: 'incorporated',
  INCORPORATED_TASK_ASSIGNEE: 'incorporated_task_assignee',
  INCORPORATED_TASK_LEVELS: 'incorporated_task_levels',
  INCORPORATED_TASK_SCOPE: 'incorporated_task_scope',
  INCORPORATED_SUBTASK_AUTHORS: 'incorporated_subtask_authors',
  REQUEST_FILE: 'request_file',
  COMMITMENT_FILE: 'commitment_file',
  HASHTAGS: 'hashtags',
  MILESTONE: 'milestone',
  DESIGN_DOCUMENT_LOG: 'design_documents_log',
  NF_STATUS: 'nf_status',
  COMMITMENT_FINAL_FILE: 'commitment_final_file',
  NF_ACTION_BY: 'nf_action_by',
  NF_ACTION_DATE: 'nf_action_date',
  DELIVERABLE_AUTHOR: 'deliverable_author',
  COMMENTS: 'comments',
  COMMENT_DATE: 'comment_date',
  COMMENT_AUTHOR: 'comment_author',
  COMMENT_MENTION: 'comment_mention',
  MY_REQUEST: 'my_request',
  BALL_IN_COURT: 'ball_in_court',
  BALL_IN_COURT_MANAGER: 'bic_manager',
  BALL_IN_COURT_PARTY: 'bic_party',
  PRIVATE: 'private',
  RESPONSE_PARTY_CHANGE: 'response_party_change',
  DUE_DATE_CHANGE: 'due_date_change',
  WATCHERS: 'watchers',
  DELIVERABLES: 'deliverables',
  SUBMITTALS_OUTPUT: 'submittals_output',
  SUBMITTALS_INPUT: 'submittals_input',
  REQUEST_SENT: 'date_status_sent',
  COMMITMENT_SUBMITTED: 'date_status_submitted',
  RESPONSE_SUBMITTED: 'date_status_response',
  RESPONSE_ACCEPTED: 'date_status_accepted',
  RESPONSE_DECLINED: 'date_status_declined',
  GEOMETRIES: 'geometries',
};

export const needsListSelectorGroups = [
  { title: 'General', value: 'general' },
  { title: 'Parties', value: 'parties' },
  { title: 'Actions and dates', value: 'actions_dates' },
  { title: 'Response', value: 'response' },
  { title: 'Locations', value: 'levels' },
  { title: 'Discussion', value: 'discussion' },
  { title: 'Incorporation', value: 'incorporation' },
  { title: 'Plan and control', value: 'plan_control' },
];

export const needsListSortingOptions = [
  { value: 'nf', title: 'Number' },
  { value: 'created_at', title: 'Creation date' },
  { value: 'due_date', title: 'Due date' },
  { value: 'nf_status', title: 'Status' },
  { value: 'tag', title: 'Request type' },
  { value: 'request_discipline', title: 'Request discipline' },
  { value: 'request_party', title: 'Request company' },
  { value: 'response_discipline', title: 'Response discipline' },
  { value: 'response_party', title: 'Response company' },
  { value: 'ufc', title: 'UniFormat' },
  { value: 'mfc', title: 'MasterFormat' },
  { value: 'hashtags', title: 'Tags' },
  { value: 'milestone', title: 'LOD milestone' },
  { value: 'building_spaces', title: 'Building spaces' },
  { value: 'levels', title: 'Levels' },
  { value: 'typicalLayouts', title: 'Typical Layouts' },
];

export const needsListGroupingOptions = [
  { value: 'REQUEST_DISCIPLINE', title: 'Request discipline' },
  { value: 'REQUEST_COMPANY', title: 'Request company' },
  { value: 'REQUEST_MANAGER', title: 'Request manager' },
  { value: 'RESPONSE_DISCIPLINE', title: 'Response discipline' },
  { value: 'RESPONSE_COMPANY', title: 'Response company' },
  { value: 'RESPONSE_MANAGER', title: 'Response manager' },
  { value: 'SOURCE', title: 'Request type' },
  { value: 'NF_STATUS', title: 'Status' },
  { value: 'DUE_DATE', title: 'Due date' },
];

export const needsListGroupedByFilters = {
  buildings: ['levels', 'typicalLayouts', 'building_spaces', 'incorporated_task_levels', 'pcd_deliverables'],
};

export const defaultNeedListQueryObject = {
  sorting: {
    order_by: '',
    order_dir: '',
  },
  conditions: {},
  filters: {},
  or_filters: {},
  search: '',
  page: '',
};

/*
 * title - Column name
 * isChecked - Hide\show column
 * value - Property for switch hide\show column
 * width - Column width
 * filterField - Property for get filter from filters collection
 * searchField - Column search field
 * exportField - For export column like: PDF
 * sortingField - Column sorting
 * editFieldName - For edit field
 * filteredField - Send filter property to API for filtration
 * */
export const list_columns = [
  {
    title: 'All',
    isChecked: false,
    value: switchColumns.ALL,
    width: 32,
    position: 0,
  },
  {
    title: 'NF',
    isChecked: true,
    value: switchColumns.NF,
    width: 72,
    searchField: searchFields.NF,
    exportField: 'nf',
    sortingField: 'nf',
    position: 1,
    conditionType: 'TEXT',
  },
  {
    title: 'Request type',
    isChecked: true,
    value: switchColumns.SOURCE,
    width: 165,
    minWidth: 165,
    filterField: filterFields.SOURCE,
    searchField: searchFields.SOURCE,
    exportField: 'tag',
    sortingField: 'tag',
    editFieldName: 'tag_ids',
    filteredField: 'tags',
    position: 2,
    group: 'general',
  },
  {
    title: 'Revit objects',
    isChecked: false,
    value: switchColumns.GEOMETRIES,
    width: 40,
    minWidth: 40,
    filterField: filterFields.GEOMETRIES,
    searchField: searchFields.GEOMETRIES,
    exportField: 'geometries',
    sortingField: 'geometries',
    filteredField: 'geometries',
    position: 2,
    group: 'plan_control',
  },
  {
    title: 'Status',
    isChecked: true,
    value: switchColumns.NF_STATUS,
    filterField: filterFields.NF_STATUS,
    width: 150,
    minWidth: 120,
    exportField: 'nf_status',
    filteredField: 'nf_status',
    sortingField: 'nf_status',
    position: 3,
    group: 'general',
  },
  {
    title: 'Last action by',
    isChecked: true,
    value: switchColumns.NF_ACTION_BY,
    filterField: filterFields.NF_ACTION_BY,
    width: 200,
    minWidth: 120,
    exportField: 'nf_action_by',
    sortingField: 'nf_action_by',
    filteredField: 'nf_action_by',
    position: 1,
    group: 'actions_dates',
  },
  {
    title: 'Last action date',
    isChecked: true,
    value: switchColumns.NF_ACTION_DATE,
    filterField: filterFields.NF_ACTION_DATE,
    width: 190,
    minWidth: 120,
    exportField: 'nf_action_date',
    filteredField: 'nf_action_date',
    position: 2,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Private',
    isChecked: false,
    value: switchColumns.PRIVATE,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    filterField: filterFields.PRIVATE,
    searchField: searchFields.PRIVATE,
    exportField: 'private',
    sortingField: 'private',
    filteredField: 'private',
    position: 7,
    group: 'general',
  },
  {
    title: 'Due date change',
    isChecked: false,
    value: switchColumns.DUE_DATE_CHANGE,
    width: 100,
    minWidth: 120,
    filterField: filterFields.DUE_DATE_CHANGE,
    searchField: searchFields.DUE_DATE_CHANGE,
    exportField: 'due_date_change',
    sortingField: 'due_date_change',
    filteredField: 'due_date_change',
    position: 8,
    group: 'actions_dates',
  },
  {
    title: 'UniFormat',
    isChecked: true,
    value: switchColumns.UFC,
    width: 200,
    minWidth: 200,
    filterField: filterFields.UFC,
    searchField: searchFields.UFC,
    exportField: 'ufc',
    sortingField: 'ufc',
    editFieldName: 'ufc_ids',
    filteredField: 'ufc',
    position: 5,
    isShowType: true,
    group: 'general',
  },
  {
    title: 'MasterFormat',
    isChecked: true,
    value: switchColumns.MFC,
    width: 140,
    minWidth: 140,
    filterField: filterFields.MFC,
    searchField: searchFields.MFC,
    exportField: 'mf',
    sortingField: 'mfc',
    editFieldName: 'mfc_ids',
    filteredField: 'mf',
    position: 6,
    group: 'general',
  },
  {
    title: 'Response incorporated',
    isChecked: true,
    value: switchColumns.INCORPORATED,
    minWidth: 165,
    width: 165,
    maxWidth: 165,
    filterField: filterFields.INCORPORATED,
    searchField: searchFields.INCORPORATED,
    exportField: 'incorporated',
    sortingField: 'incorporated',
    filteredField: 'incorporated',
    editFieldName: 'is_incorporated',
    position: 1,
    group: 'incorporation',
  },
  {
    title: 'Incorporation tasks',
    isChecked: true,
    value: switchColumns.INCORPORATION_TASKS,
    width: 240,
    minWidth: 240,
    exportField: 'incorporation_tasks',
    position: 9,
    group: 'incorporation',
  },
  {
    title: 'Incorporation subtasks',
    isChecked: true,
    value: switchColumns.INCORPORATION_SUBTASKS,
    width: 200,
    minWidth: 200,
    exportField: 'incorporation_subtasks',
    position: 10,
    group: 'incorporation',
  },
  {
    title: 'Incorporation task assignee',
    isChecked: false,
    value: switchColumns.INCORPORATED_TASK_ASSIGNEE,
    width: 190,
    minWidth: 120,
    filterField: filterFields.INCORPORATED_TASK_ASSIGNEE,
    searchField: searchFields.INCORPORATED_TASK_ASSIGNEE,
    exportField: 'incorporated_task_assignee',
    sortingField: 'incorporated_task_assignee',
    position: 2,
    group: 'incorporation',
  },
  {
    title: 'Incorporation task due date',
    isChecked: false,
    value: switchColumns.INCORPORATED_TASK_DUE_DATES,
    filterField: filterFields.INCORPORATED_TASK_DUE_DATES,
    width: 120,
    minWidth: 120,
    position: 3,
    conditionType: 'DATE',
    group: 'incorporation',
  },
  {
    title: 'Incorporation task scope',
    isChecked: false,
    value: switchColumns.INCORPORATED_TASK_SCOPE,
    width: 200,
    minWidth: 200,
    filterField: filterFields.INCORPORATED_TASK_SCOPE,
    searchField: searchFields.INCORPORATED_TASK_SCOPE,
    exportField: 'incorporated_task_scope',
    sortingField: 'incorporated_task_scope',
    editFieldName: 'incorporated_task_scope',
    filteredField: 'incorporated_task_scope',
    position: 5,
    group: 'incorporation',
  },
  {
    title: 'Incorporation task level',
    isChecked: false,
    value: switchColumns.INCORPORATED_TASK_LEVELS,
    width: 140,
    minWidth: 140,
    filterField: filterFields.INCORPORATED_TASK_LEVELS,
    searchField: searchFields.INCORPORATED_TASK_LEVELS,
    exportField: 'incorporated_task_levels',
    sortingField: 'incorporated_task_levels',
    editFieldName: 'incorporated_task_levels',
    filteredField: 'incorporated_task_levels',
    position: 4,
    group: 'incorporation',
  },
  {
    title: 'Incorporation task status',
    isChecked: false,
    value: switchColumns.INCORPORATED_TASK_STATUS,
    filterField: filterFields.INCORPORATED_TASK_STATUS,
    width: 150,
    minWidth: 120,
    exportField: 'incorporated_task_status',
    filteredField: 'incorporated_task_status',
    sortingField: 'incorporated_task_status',
    position: 6,
    group: 'incorporation',
  },
  {
    title: 'Incorporation record author',
    isChecked: false,
    value: switchColumns.INCORPORATED_SUBTASK_AUTHORS,
    width: 190,
    minWidth: 120,
    filterField: filterFields.INCORPORATED_SUBTASK_AUTHORS,
    searchField: searchFields.INCORPORATED_SUBTASK_AUTHORS,
    exportField: 'incorporated_subtask_authors',
    sortingField: 'incorporated_subtask_authors',
    position: 7,
    group: 'incorporation',
  },
  {
    title: 'Incorporation record date',
    isChecked: false,
    value: switchColumns.INCORPORATED_SUBTASK_COMPLETED_DATES,
    filterField: filterFields.INCORPORATED_SUBTASK_COMPLETED_DATES,
    width: 120,
    minWidth: 120,
    position: 8,
    conditionType: 'DATE',
    group: 'incorporation',
  },
  {
    title: 'LOD milestone',
    isChecked: false,
    value: switchColumns.MILESTONE,
    width: 110,
    minWidth: 120,
    filterField: filterFields.MILESTONE,
    searchField: searchFields.MILESTONE,
    exportField: 'milestone',
    sortingField: 'milestone',
    editFieldName: 'milestone_id',
    filteredField: 'milestones',
    position: 2,
    group: 'plan_control',
  },
  {
    title: 'Design documents',
    isChecked: true,
    value: switchColumns.DESIGN_DOCUMENT_LOG,
    width: 200,
    minWidth: 120,
    filterField: filterFields.DESIGN_DOCUMENT_LOG,
    searchField: searchFields.DESIGN_DOCUMENT_LOG,
    exportField: 'design_documents_log',
    sortingField: 'design_documents_log',
    editFieldName: 'document_id',
    filteredField: 'design_documents_log',
    position: 1,
    group: 'plan_control',
  },
  {
    title: 'Building',
    isChecked: true,
    value: switchColumns.BUILDING,
    width: 140,
    minWidth: 140,
    filterField: filterFields.BUILDING,
    searchField: searchFields.BUILDING,
    exportField: 'building',
    sortingField: 'buildings',
    editFieldName: 'buildings',
    filteredField: 'buildings',
    position: 1,
    group: 'levels',
  },
  {
    title: 'Levels',
    isChecked: true,
    value: switchColumns.LEVELS,
    width: 140,
    minWidth: 140,
    filterField: filterFields.LEVELS,
    searchField: searchFields.LEVELS,
    exportField: 'level',
    sortingField: 'levels',
    editFieldName: 'locations',
    filteredField: 'levels',
    position: 2,
    group: 'levels',
  },
  {
    title: 'Typical Layouts',
    isChecked: true,
    value: switchColumns.TYPICAL_LAYOUTS,
    width: 140,
    minWidth: 140,
    filterField: filterFields.TYPICAL_LAYOUTS,
    searchField: searchFields.TYPICAL_LAYOUTS,
    exportField: 'typicalLayout',
    sortingField: 'typicalLayouts',
    editFieldName: 'locations',
    filteredField: 'typicalLayouts',
    position: 2,
    group: 'levels',
  },
  {
    title: 'Building space',
    isChecked: true,
    value: switchColumns.BUILDING_SPACES,
    width: 190,
    minWidth: 190,
    filterField: filterFields.BUILDING_SPACES,
    searchField: searchFields.BUILDING_SPACES,
    exportField: 'building_space',
    sortingField: 'building_spaces',
    editFieldName: 'building_spaces',
    filteredField: 'building_spaces',
    position: 3,
    group: 'levels',
  },
  {
    title: 'Tags',
    isChecked: true,
    value: switchColumns.HASHTAGS,
    width: 160,
    minWidth: 120,
    filterField: filterFields.HASHTAGS,
    searchField: searchFields.HASHTAGS,
    exportField: 'hashtags',
    sortingField: 'hashtags',
    editFieldName: 'hashtags',
    filteredField: 'hashtags',
    position: 9,
    isSwitchAnd: true,
    group: 'general',
    conditionType: 'TEXT',
  },
  {
    title: 'Subject',
    isChecked: true,
    value: switchColumns.DESCRIPTION,
    width: 200,
    minWidth: 120,
    filterField: filterFields.DESCRIPTION,
    searchField: searchFields.DESCRIPTION,
    exportField: 'project_specific_description',
    sortingField: 'project_specific_description',
    position: 4,
    group: 'general',
    conditionType: 'TEXT',
  },
  {
    title: 'Request discipline',
    isChecked: true,
    value: switchColumns.REQUEST_DISCIPLINE,
    width: 200,
    minWidth: 120,
    filterField: filterFields.REQUEST_DISCIPLINE,
    searchField: searchFields.REQUEST_DISCIPLINE,
    exportField: 'request_discipline',
    sortingField: 'request_discipline',
    editFieldName: 'req_disc_id',
    filteredField: 'request_disciplines',
    position: 1,
    group: 'parties',
  },
  {
    title: 'Request company',
    isChecked: true,
    value: switchColumns.REQUEST_COMPANY,
    width: 200,
    maxWidth: 200,
    mixWidth: 200,
    filterField: filterFields.REQUEST_COMPANY,
    searchField: searchFields.REQUEST_COMPANY,
    exportField: 'request_party',
    sortingField: 'request_party',
    editFieldName: 'request_party_id',
    filteredField: 'request_party',
    position: 2,
    group: 'parties',
  },
  {
    title: 'Request author',
    isChecked: false,
    value: switchColumns.NAME,
    width: 160,
    minWidth: 120,
    filterField: filterFields.NAME,
    searchField: searchFields.NAME,
    exportField: 'author',
    sortingField: 'author',
    filteredField: 'authors',
    position: 4,
    group: 'parties',
  },
  {
    title: 'Request manager',
    isChecked: false,
    value: switchColumns.REQUEST_MANAGER,
    width: 160,
    minWidth: 120,
    filterField: filterFields.REQUEST_MANAGER,
    searchField: searchFields.REQUEST_MANAGER,
    exportField: 'user',
    sortingField: 'user',
    editFieldName: 'user_id',
    filteredField: 'users',
    position: 3,
    group: 'parties',
  },
  {
    title: 'Request description',
    isChecked: true,
    value: switchColumns.PROJECT_SPECIFIC_DESCRIPTION,
    width: 200,
    minWidth: 120,
    filterField: filterFields.PROJECT_SPECIFIC_DESCRIPTION,
    searchField: searchFields.PROJECT_SPECIFIC_DESCRIPTION,
    exportField: 'project_specific',
    sortingField: 'project_specific',
    position: 23,
    group: 'general',
    conditionType: 'TEXT',
  },
  {
    title: 'Request files',
    isChecked: true,
    value: switchColumns.REQUEST_FILES,
    width: 250,
    minWidth: 250,
    filterField: filterFields.REQUEST_FILE,
    searchField: searchFields.REQUEST_FILE,
    exportField: 'request_file',
    position: 24,
    group: 'general',
    conditionType: 'TEXT',
  },
  {
    title: 'Request created',
    isChecked: true,
    value: switchColumns.CREATED,
    width: 160,
    minWidth: 120,
    filterField: filterFields.CREATED,
    searchField: searchFields.CREATED,
    exportField: 'created_at',
    sortingField: 'created_at',
    position: 3,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Due date',
    isChecked: true,
    value: switchColumns.DUE_DATE,
    width: 120,
    minWidth: 120,
    filterField: filterFields.DUE_DATE,
    searchField: searchFields.DUE_DATE,
    exportField: 'due_date',
    sortingField: 'due_date',
    editFieldName: 'due_date',
    position: 4,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Response discipline',
    isChecked: true,
    value: switchColumns.RESPONSE_DISCIPLINE,
    width: 190,
    minWidth: 120,
    filterField: filterFields.RESPONSE_DISCIPLINE,
    searchField: searchFields.RESPONSE_DISCIPLINE,
    exportField: 'response_discipline',
    sortingField: 'response_discipline',
    editFieldName: 'resp_disc_id',
    filteredField: 'response_disciplines',
    position: 5,
    group: 'parties',
  },
  {
    title: 'Response company',
    isChecked: true,
    value: switchColumns.RESPONSE_COMPANY,
    width: 190,
    minWidth: 120,
    filterField: filterFields.RESPONSE_COMPANY,
    searchField: searchFields.RESPONSE_COMPANY,
    exportField: 'response_party',
    sortingField: 'response_party',
    editFieldName: 'response_party_id',
    filteredField: 'response_party',
    position: 6,
    group: 'parties',
  },
  {
    title: 'Response manager',
    isChecked: false,
    value: switchColumns.RESPONSE_MANAGER,
    width: 190,
    minWidth: 120,
    filterField: filterFields.RESPONSE_MANAGER,
    searchField: searchFields.RESPONSE_MANAGER,
    exportField: 'response_manager',
    sortingField: 'response_manager',
    editFieldName: 'response_user_id',
    filteredField: 'response_managers',
    position: 7,
    group: 'parties',
  },
  {
    title: 'Commitment author',
    isChecked: false,
    value: switchColumns.EMAIL,
    width: 190,
    minWidth: 120,
    filterField: filterFields.EMAIL,
    searchField: searchFields.EMAIL,
    exportField: 'commitment_author',
    sortingField: 'email',
    position: 3,
    group: 'response',
  },
  {
    title: 'Commitment status',
    isChecked: false,
    value: switchColumns.COMMITMENT_STATUS,
    width: 190,
    minWidth: 120,
    filterField: filterFields.COMMITMENT_STATUS,
    searchField: searchFields.COMMITMENT_STATUS,
    exportField: 'commitment_status',
    sortingField: 'commitment_status',
    position: 4,
    group: 'response',
  },
  {
    title: 'Response description',
    isChecked: false,
    value: switchColumns.COMMITMENT_FINAL,
    width: 200,
    minWidth: 120,
    filterField: filterFields.COMMITMENT_FINAL,
    searchField: searchFields.COMMITMENT_FINAL,
    exportField: 'final_desc',
    sortingField: 'final_desc',
    position: 4,
    group: 'response',
    conditionType: 'TEXT',
  },
  {
    title: 'Response files',
    isChecked: false,
    value: switchColumns.COMMITMENT_FINAL_FILE,
    width: 240,
    minWidth: 120,
    searchField: searchFields.COMMITMENT_FINAL_FILE,
    filterField: filterFields.COMMITMENT_FINAL_FILE,
    exportField: 'commitment_final_file',
    position: 5,
    group: 'response',
    conditionType: 'TEXT',
  },
  {
    title: 'Response author',
    isChecked: false,
    value: switchColumns.DELIVERABLE_AUTHOR,
    filterField: filterFields.DELIVERABLE_AUTHOR,
    width: 200,
    minWidth: 120,
    filteredField: 'deliverable_author',
    exportField: 'deliverable_author',
    sortingField: 'deliverable_author',
    position: 6,
    group: 'response',
  },
  {
    title: 'Comments',
    isChecked: true,
    value: switchColumns.COMMENTS,
    width: 350,
    minWidth: 120,
    filteredField: 'comments',
    exportField: 'comments',
    sortingField: 'comments',
    position: 1,
  },
  {
    title: 'Predecessor',
    isChecked: false,
    value: switchColumns.PREDECESSORS,
    width: 100,
    minWidth: 120,
    searchField: searchFields.PREDECESSORS,
    exportField: 'successors',
    sortingField: 'successor',
    position: 10,
    conditionType: 'TEXT',
  },
  {
    title: 'Successors',
    isChecked: false,
    value: switchColumns.SUCCESSOR,
    width: 120,
    minWidth: 120,
    searchField: searchFields.SUCCESSOR,
    exportField: 'predecessors',
    sortingField: 'predecessors',
    position: 11,
    conditionType: 'TEXT',
  },
  {
    title: 'Comment date',
    isChecked: false,
    value: switchColumns.COMMENT_DATE,
    filterField: filterFields.COMMENT_DATE,
    width: 120,
    minWidth: 120,
    position: 39,
    group: 'discussion',
    conditionType: 'DATE',
  },
  {
    title: 'Comment author',
    isChecked: false,
    value: switchColumns.COMMENT_AUTHOR,
    filterField: filterFields.COMMENT_AUTHOR,
    width: 200,
    minWidth: 120,
    position: 40,
    group: 'discussion',
  },
  {
    title: 'Comment mentions',
    isChecked: false,
    value: switchColumns.COMMENT_MENTION,
    filterField: filterFields.COMMENT_MENTION,
    width: 140,
    minWidth: 120,
    position: 41,
    group: 'discussion',
  },
  {
    title: 'My request',
    isChecked: false,
    value: switchColumns.MY_REQUEST,
    width: 100,
    minWidth: 120,
    position: 42,
  },
  {
    title: 'BIC (manager)',
    isChecked: true,
    value: switchColumns.BALL_IN_COURT_MANAGER,
    width: 170,
    minWidth: 120,
    filterField: filterFields.BALL_IN_COURT_MANAGER,
    searchField: searchFields.BALL_IN_COURT_MANAGER,
    exportField: 'bic_manager',
    sortingField: 'bic_manager',
    filteredField: 'bic_manager',
    position: 43,
    group: 'discussion',
  },
  {
    title: 'BIC (company)',
    isChecked: true,
    value: switchColumns.BALL_IN_COURT_PARTY,
    width: 170,
    minWidth: 120,
    filterField: filterFields.BALL_IN_COURT_PARTY,
    searchField: searchFields.BALL_IN_COURT_PARTY,
    exportField: 'bic_party',
    sortingField: 'bic_party',
    filteredField: 'bic_party',
    position: 43,
    group: 'discussion',
  },
  {
    title: 'Watchers',
    isChecked: true,
    value: switchColumns.WATCHERS,
    width: 200,
    minWidth: 120,
    filterField: filterFields.WATCHERS,
    searchField: searchFields.WATCHERS,
    exportField: 'watchers',
    sortingField: 'watchers',
    filteredField: 'watchers',
    position: 44,
    isSwitchAnd: true,
    group: 'discussion',
  },
  {
    title: 'Deliverables',
    isChecked: true,
    value: switchColumns.DELIVERABLES,
    width: 200,
    minWidth: 120,
    filterField: filterFields.DELIVERABLES,
    searchField: searchFields.DELIVERABLES,
    exportField: 'deliverables',
    sortingField: 'deliverables',
    filteredField: 'pcd_deliverables',
    position: 45,
    group: 'plan_control',
  },
  {
    title: 'Successor submittals',
    isChecked: true,
    value: switchColumns.SUBMITTALS_OUTPUT,
    width: 180,
    minWidth: 120,
    filterField: filterFields.SUBMITTALS_OUTPUT,
    searchField: searchFields.SUBMITTALS_OUTPUT,
    exportField: 'submittals_output',
    sortingField: 'submittals_output',
    filteredField: 'submittals_output',
    position: 45,
    group: 'plan_control',
  },
  {
    title: 'Predecessor submittals',
    isChecked: true,
    value: switchColumns.SUBMITTALS_INPUT,
    width: 180,
    minWidth: 120,
    filterField: filterFields.SUBMITTALS_INPUT,
    searchField: searchFields.SUBMITTALS_INPUT,
    exportField: 'submittals_input',
    sortingField: 'submittals_input',
    filteredField: 'submittals_input',
    position: 45,
    group: 'plan_control',
  },
  {
    title: 'Request sent',
    isChecked: true,
    value: switchColumns.REQUEST_SENT,
    width: 170,
    minWidth: 120,
    filterField: filterFields.REQUEST_SENT,
    searchField: searchFields.REQUEST_SENT,
    exportField: 'date_status_sent',
    sortingField: 'date_status_sent',
    filteredField: 'date_status_sent',
    position: 46,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Commitment submitted',
    isChecked: true,
    value: switchColumns.COMMITMENT_SUBMITTED,
    width: 170,
    minWidth: 120,
    filterField: filterFields.COMMITMENT_SUBMITTED,
    searchField: searchFields.COMMITMENT_SUBMITTED,
    exportField: 'date_status_submitted',
    sortingField: 'date_status_submitted',
    filteredField: 'date_status_submitted',
    position: 46,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Response submitted',
    isChecked: true,
    value: switchColumns.RESPONSE_SUBMITTED,
    width: 170,
    minWidth: 120,
    filterField: filterFields.RESPONSE_SUBMITTED,
    searchField: searchFields.RESPONSE_SUBMITTED,
    exportField: 'date_status_response',
    sortingField: 'date_status_response',
    filteredField: 'date_status_response',
    position: 47,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Response accepted',
    isChecked: true,
    value: switchColumns.RESPONSE_ACCEPTED,
    width: 170,
    minWidth: 120,
    filterField: filterFields.RESPONSE_ACCEPTED,
    searchField: searchFields.RESPONSE_ACCEPTED,
    exportField: 'date_status_accepted',
    sortingField: 'date_status_accepted',
    filteredField: 'date_status_accepted',
    position: 48,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Response declined',
    isChecked: true,
    value: switchColumns.RESPONSE_DECLINED,
    width: 170,
    minWidth: 120,
    filterField: filterFields.RESPONSE_DECLINED,
    searchField: searchFields.RESPONSE_DECLINED,
    exportField: 'date_status_declined',
    sortingField: 'date_status_declined',
    filteredField: 'date_status_declined',
    position: 49,
    group: 'actions_dates',
    conditionType: 'DATE',
  },
  {
    title: 'Response company change',
    isChecked: false,
    value: switchColumns.RESPONSE_PARTY_CHANGE,
    width: 120,
    minWidth: 120,
    filterField: filterFields.RESPONSE_PARTY_CHANGE,
    searchField: searchFields.RESPONSE_PARTY_CHANGE,
    exportField: 'response_party_change',
    sortingField: 'response_party_change',
    filteredField: 'response_party_change',
    position: 8,
    group: 'parties',
  },
  {
    title: 'CL impact',
    isChecked: true,
    value: switchColumns.CL_IMPACTS,
    exportField: 'cl_impacts',
    width: 200,
    editFieldName: 'cl_impacts',
  },
  {
    value: 'Systems affected',
    title: 'Systems affected',
    filterField: filterFields.CL_IMPACTS_YES,
    filteredField: filterFields.CL_IMPACTS_YES,
    position: 50,
    group: 'plan_control',
  },
  {
    value: 'Systems not affected',
    title: 'Systems not affected',
    filterField: filterFields.CL_IMPACTS_NO,
    filteredField: filterFields.CL_IMPACTS_NO,
    position: 51,
    group: 'plan_control',
  },
  {
    value: 'Systems not reviewed',
    title: 'Systems not reviewed',
    filterField: filterFields.CL_IMPACTS_NOT_REVIEWED,
    filteredField: filterFields.CL_IMPACTS_NOT_REVIEWED,
    position: 52,
    group: 'plan_control',
  },
  {
    value: 'NFs Planit worked with',
    title: 'NFs Planit worked with',
    filterField: filterFields.NF_PLANIT,
    filteredField: filterFields.NF_PLANIT,
    conditionType: 'DATE',
    position: 53,
    group: 'plan_control',
  },
  {
    title: 'Marked by me',
    filterField: filterFields.MY_HOT_LIST,
    position: 53,
    group: 'plan_control',
  },
  {
    title: 'Marked by my company',
    filterField: filterFields.COMPANY_HOT_LIST,
    position: 54,
    group: 'plan_control',
  },
];

export const commitementStatusFilter = [
  {
    id: 'new',
    title: 'New',
    value: 'new',
    isChecked: false,
  },
  {
    id: 'inprogress',
    title: 'Submitted',
    value: 'inprogress',
    isChecked: false,
  },
  {
    id: 'declined',
    title: 'Declined',
    value: 'declined',
    isChecked: false,
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 'completed',
    isChecked: false,
  },
  {
    id: 'accepted',
    title: 'Accepted',
    value: 'accepted',
    isChecked: false,
  },
];
export const requestStatusFilter = [
  {
    id: 'new',
    title: 'Sent',
    value: 'new',
    isChecked: false,
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 'completed',
    isChecked: false,
  },
  {
    id: 'inprogress',
    title: 'Commited',
    value: 'inprogress',
    isChecked: false,
  },
  {
    id: 'draft',
    title: 'Draft',
    value: 'draft',
    isChecked: false,
  },
];

export const nfStatusFilters = [
  {
    title: 'Request drafted',
    value: 'draft',
    id: 'draft',
    isChecked: false,
  },
  {
    title: 'Request sent',
    value: 'new',
    id: 'new',
    isChecked: false,
  },
  {
    title: 'Commitment submitted',
    value: 'committed',
    id: 'committed',
    isChecked: false,
  },
  {
    title: 'Response Submitted',
    value: 'provided',
    id: 'provided',
    isChecked: false,
  },
  {
    title: 'Response accepted',
    value: 'accepted',
    id: 'accepted',
    isChecked: false,
  },
  {
    title: 'Request re-opened',
    value: 'reopened',
    id: 'reopened',
    isChecked: false,
  },
];
