import React from 'react';

interface IProps {
  element: Element;
  setIsDraggingActive?: (state: boolean) => boolean;
}

interface IState {
  isScrolling: boolean;
}

class DraggleScroll extends React.Component<IProps, IState> {
  state = {
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
    clientY: 0,
    scrollY: 0,
  };

  componentDidMount(): void {
    const { element, setIsDraggingActive } = this.props;

    element.addEventListener('mousedown', (e: any) => {
      e.stopPropagation();
      let isHeader = false;
      const path = e.path || (e.composedPath && e.composedPath());
      path.forEach(f => {
        if (
          f?.classList?.contains('data-grid-basic-dark__draggableHeaderInner') ||
          f?.classList?.contains('data-grid-basic-light__draggableHeaderInner')
        ) {
          isHeader = true;
          return;
        }
      });
      if (isHeader) {
        return;
      }
      this.setState({ isScrolling: true });
      setIsDraggingActive && setIsDraggingActive(true);
      this.setState({
        scrollX: element.scrollLeft,
        scrollY: element.scrollTop,
        clientX: e.clientX,
        clientY: e.clientY,
      });
      element.classList.add('-cursorDrag');
    });

    element.addEventListener('mouseup', (e: any) => {
      e.stopPropagation();
      this.setState({ isScrolling: false });
      setIsDraggingActive && setIsDraggingActive(false);
      setTimeout(() => {
        element.classList.remove('-cursorDrag');
      }, 200);
    });

    element.addEventListener('mousemove', this.onMouseMove);
    element.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount() {
    const { element, setIsDraggingActive } = this.props;
    this.props.element.removeEventListener('mouseup', (e: any) => {
      e.stopPropagation();
      this.setState({ isScrolling: false });
      setIsDraggingActive && setIsDraggingActive(false);
      this.setState({
        scrollX: element.scrollLeft,
        scrollY: element.scrollTop,
        clientX: e.clientX,
        clientY: e.clientY,
      });
      setTimeout(() => {
        element.classList.remove('-cursorDrag');
      }, 200);
    });
    this.props.element.removeEventListener('mousemove', this.onMouseMove);
    this.props.element.removeEventListener('mouseleave', this.onMouseLeave);
  }

  onMouseLeave = () => {
    const { element, setIsDraggingActive } = this.props;
    if (this.state.isScrolling) {
      this.setState({ isScrolling: false });
      setIsDraggingActive && setIsDraggingActive(false);
      element.classList.remove('-cursorDrag');
    }
  };

  onMouseMove = (e: any) => {
    e.stopPropagation();
    const { isScrolling } = this.state;

    const { clientX, scrollX, scrollY, clientY } = this.state;

    if (isScrolling) {
      this.setState({
        scrollX: scrollX + (clientX - e.clientX),
        scrollY: scrollY + (clientY - e.clientY),
        clientX: e.clientX,
        clientY: e.clientY,
      });

      this.props.updateRefPosition(scrollX, scrollY);
    }
  };

  render() {
    return null;
  }
}

export default DraggleScroll;
