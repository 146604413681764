import React, { FC, useEffect, useRef, useState } from 'react';
import { BuildingModel, ContentPlanUFCColumnModel } from '../../../../../models';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { TextInputNew } from '../../../../controls';
import { DataGridBasic } from '../../../DataGridBasic';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import DataImport from '../../../../controls/DataImport/DataImport';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import Popover from '@material-ui/core/Popover';
import { getUFCVersionData } from '../../../../../store/project/projectLogic';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import Divider from '../../../../controls/Divider/Divider';

import './Step4ViewStyles.scss';

interface IProps {
  currentBuilding: BuildingModel;
  setCurrentBuilding: (item: BuildingModel) => void;
  buildings: BuildingModel[];
  searchValue: string;
  handleChangeSearch: (e: React.SyntheticEvent) => void;
  isImportDataOpen: boolean;
  handleOpenDataImport: () => void;
  handleCloseDataImport: () => void;
  handleGetImportUFCData: () => void;
  columns: any;
  rows: ContentPlanUFCColumnModel[];
  searchUFCResult: (id: number) => void;
  searchUFC: () => void;
  handleCloseUFCSearchPopover: () => void;
  isSearchPopoverOpen: boolean;
  basedOnValue: string;
  handleSetBasedOnValue: (e: React.SyntheticEvent) => void;
  handleSaveUFContents: () => void;
  isBasedOnPopUpOpen: boolean;
  openBasedOnPopUp: () => void;
  closeBasedOnPopUp: () => void;
  exitEditMode: () => void;
}

const Step4View: FC<IProps> = ({
  currentBuilding,
  setCurrentBuilding,
  buildings,
  handleChangeSearch,
  searchValue,
  isImportDataOpen,
  handleOpenDataImport,
  handleCloseDataImport,
  handleGetImportUFCData,
  columns,
  rows,
  searchUFCResult,
  searchUFC,
  handleCloseUFCSearchPopover,
  isSearchPopoverOpen,
  basedOnValue,
  handleSetBasedOnValue,
  handleSaveUFContents,
  isBasedOnPopUpOpen,
  openBasedOnPopUp,
  closeBasedOnPopUp,
  exitEditMode,
}) => {
  const dispatch = useAppDispatch();
  const ufcSearchRef = useRef();

  const themeClass = useGetThemeClass('b-step4View');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const ufcSearchSuggestions = useAppSelector(state => state.projectReducer.ufcSearchSuggestions);
  const ufcVersions = useAppSelector(state => state.projectReducer.ufcVersions);
  const loading = useAppSelector(state => state.projectReducer.loading);
  const isSaveDataLoading = useAppSelector(state => state.projectReducer.isSaveDataLoading);

  const [currentVersion, setCurrentVersion] = useState<any | null>(null);

  useEffect(() => {
    if (ufcVersions?.length) {
      setCurrentVersion(ufcVersions[ufcVersions.length - 1]);
    }
  }, [ufcVersions]);

  const handleChangeVersion = item => {
    setCurrentVersion(item);
    dispatch(getUFCVersionData(item.id));
  };

  return (
    <div className={`${themeClass}`}>
      {buildings?.length ? (
        <div className={`${themeClass}_container`}>
          <div className={`${themeClass}_container_header`}>
            <div className={`${themeClass}_container_header_buildingSelect`}>
              <Select<DropdownItemModel, null, false>
                isMulti={false}
                isHeader
                menuItems={buildings || []}
                selectHandler={item => setCurrentBuilding(item)}
                selected={currentBuilding}
                menuItemSize={'md'}
                basisPlaceholder={''}
                menuItemTitleRenderer={item => {
                  return <div className={`${themeClass}_selectText`}>{item.title}</div>;
                }}
                renderBasisSelectedItem={item => {
                  return <div className={`${themeClass}_selectText`}>{item.title}</div>;
                }}
                customBasisFieldClass={`${themeClass}_selectFieldClass`}
              />
            </div>
            <div className={`${themeClass}_container_header_searchField`}>
              <TextInputNew
                ref={ufcSearchRef}
                isSearch
                type={'on-srf'}
                size={'md'}
                placeholder={'Search'}
                onChange={handleChangeSearch}
                value={searchValue}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    searchUFC();
                  }
                }}
              />
            </div>
            <div className={`${themeClass}_container_header_importBtn`}>
              <CustomButton
                icon={<TokenIcon iconName={'upload'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={() => handleOpenDataImport()}
                title={'Import from file'}
                iconClass={`${themeClass}_icon`}
              />
            </div>
            <div className={`${themeClass}_container_header_versionsSelect`}>
              <Select<DropdownItemModel, null, false>
                isMulti={false}
                isHeader
                menuItems={ufcVersions || []}
                selectHandler={item => handleChangeVersion(item)}
                selected={currentVersion}
                menuItemSize={'md'}
                basisPlaceholder={'Select version'}
                isLoading={!!loading}
                menuItemTitleRenderer={item => {
                  return <div className={`${themeClass}_selectText`}>{item.title}</div>;
                }}
                renderBasisSelectedItem={item => {
                  return <div className={`${themeClass}_selectText`}>{item.title}</div>;
                }}
              />
            </div>
          </div>
          <div className={`${themeClass}_container_tableContainer`} style={{ height: `calc(100svh - 340px)` }}>
            <DataGridBasic
              columns={columns || []}
              rows={rows || []}
              headerRowHeight={36}
              rowHeight={52}
              resizable={true}
              isCustomGrouping
              customGropingRows={rows}
            />
          </div>
          <div className={`${themeClass}_container_footer`}>
            <Divider direction={'horizontal'} type={'srf-5'} />
            <div className={`${themeClass}_container_footer_bottomPart`}>
              <CustomButton type={'tertiary'} size={'md'} clickHandler={exitEditMode} title={'Exit edit mode'} />
              <CustomButton
                type={'primary'}
                size={'md'}
                clickHandler={() => openBasedOnPopUp()}
                title={'Save'}
                disabled={isSaveDataLoading}
              />
            </div>
          </div>
          <Popover
            id={'content-plan-search'}
            open={isSearchPopoverOpen}
            anchorEl={ufcSearchRef.current}
            onClose={() => handleCloseUFCSearchPopover()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: `${themeClassCallout} ${themeClassPaper}`,
            }}
          >
            <div className={`${themeClass}_ufListPopover`}>
              {(!ufcSearchSuggestions || ufcSearchSuggestions?.length < 1) && (
                <div>
                  <span className={`${themeClass}_ufListPopover_text`}>No options</span>
                </div>
              )}
              {ufcSearchSuggestions &&
                ufcSearchSuggestions.map(ufc => {
                  return (
                    <div key={ufc.id} onClick={() => searchUFCResult(ufc.id)} className={`${themeClass}_ufListPopover_item`}>
                      <span className={`${themeClass}_ufListPopover_text`}>{`${ufc.code} ${ufc.title}`}</span>
                    </div>
                  );
                })}
            </div>
          </Popover>
        </div>
      ) : (
        <div className={`${themeClass}_containerNoBuilding`}>Create building first</div>
      )}

      <DialogPopUp open={isBasedOnPopUpOpen} maxWidth={'sm'} title={'Save Content Plan'}>
        <div className={`${themeClass}_savePopUp`}>
          <div className={`${themeClass}_savePopUp_header`}>Save Content Plan</div>
          <div className={`${themeClass}_savePopUp_inputBlock`}>
            <TextInputNew type={'on-srf'} size={'md'} placeholder={'Based on'} onChange={handleSetBasedOnValue} value={basedOnValue} />
          </div>
          <div className={`${themeClass}_savePopUp_buttonContainer`}>
            <CustomButton
              type={'secondary'}
              size={'md'}
              clickHandler={() => closeBasedOnPopUp()}
              title={'Cancel'}
              disabled={isSaveDataLoading}
            />
            <CustomButton
              type={'primary'}
              size={'md'}
              clickHandler={() => handleSaveUFContents()}
              title={'Save'}
              disabled={isSaveDataLoading}
            />
          </div>
        </div>
      </DialogPopUp>
      <DataImport
        isImportOpen={isImportDataOpen}
        closeImportPopUp={handleCloseDataImport}
        importName={'Content plan'}
        importType={'content-plan'}
        building={currentBuilding?.id}
        getImportUFCData={handleGetImportUFCData}
      />
    </div>
  );
};

export default Step4View;
