import React from 'react';
import './phasePlan.scss';
import Paper from '@material-ui/core/Paper';
import SchedulePlan from './parts/SchedulePlan/SchedulePlan';
import TablePlanContainer from './parts/TablePlan/TablePlanContainer';
import { OutlinedInput, Popover } from '@material-ui/core';
import PopUpView from '../../controls/PopUp/PopUpView';
import moment from 'moment';
import ProjectCalendar from '../CreateProject/parts/Step1/parts/ProjectCalendar/ProjectCalendar';
import { createGlobalStyle } from 'styled-components';
import PhasePlanDownload from './parts/PhasePlanDownload/PhasePlanDownload';
import ScrollContainer from 'react-indiana-drag-scroll';
import { styles } from './PhasePlanStyles';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import SVG from 'react-inlinesvg';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import { ButtonView } from '../../controls';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'var(--font-general)',
    maxWidth: 320,
    color: 'var(--background-tooltip-text)',
    fontSize: '14px',
    padding: '5px 9px',
    backgroundColor: 'var(--background-tooltip)',
    fontWeigh: 'var(--font-weight)',
    position: 'relative',
    right: '5px',
    top: '90px',
    borderRadius: '2px',
    overflow: 'visible',
  },
  popper: {
    opacity: 1,
  },
}))(Tooltip);
class PhasePlanView extends React.Component<any, any> {
  private tableRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.state = {
      x: 0,
      y: 0,
    };
  }

  handleMouseMove(event) {
    this.setState({
      x: event.x,
      y: event.y,
    });
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  render() {
    const {
      isOpenCollapse,
      handleCollapse,
      wrapTableRef,
      editableTableRef,
      handleEditRow,
      handelSavePhasePlan,
      isSavePhasePlan,
      handelAcceptSavePhasePlan,
      handelDeclineSavePhasePlan,
      isAdmin,
      editMode,
      handleCancelChanges,
      handleOpenCalendar,
      errors,
      anchorEl,
      handleCloseCalendar,
      selectAtDateHandler,
      selectAtDateHandlerSingle,
      formData,
      typeOfOpenedCalendar,
      handleCoordinationDriver,
      coordinationDriverMode,
      selectedDrivers,
      driverTasks,
      getPhasePlanStatus,
      getPhasePlanError,
      applyCoordinationDriver,
      successApplyDriverChangesDate,
      setDateGMP,
      gmp,
      legendItemHover,
      hoverLegendMilestone,
      handleChangePhasePlanName,
      handleOpenDownloadMenu,
      anchorElDownload,
      handleCloseDownloadMenu,
      versions,
      onChangeIsDrag,
      isDrag,
      saveToStack,
      handleUndo,
      handleRedo,
      past,
      currentStateIndex,
      updateProject,
      handlePrevVersion,
      currentPrevVersion,
      handleReturnToCurrentVersion,
      handleOpenSettings,
      isSettingsOpen,
      anchorElSettings,
      handleCloseSettings,
      handleEditPhasePlan,
      isSavingPhasePlan,
      isActivePhasePlan,
    } = this.props;

    const GlobalStyle = createGlobalStyle`
      body {
        cursor: url(${require('../../../assets/icons/cursor_05@1x.png')}) 12 12, auto !important;
        
        input {
        cursor: url(${require('../../../assets/icons/cursor_05@1x.png')}) 12 12, auto !important;
        }
      }
    `;

    let coordinationDriverText = '';

    driverTasks.length === 0 ? (coordinationDriverText = `Choose coordination driver`) : '';
    driverTasks.length === 1 ? (coordinationDriverText = `Choose coordination driven`) : '';
    driverTasks.length > 1 ? (coordinationDriverText = ``) : '';

    const isExistScrollTableY =
      this.props.editableTableRef?.current?.scrollHeight - 15 > this.props.editableTableRef?.current?.clientHeight;
    const isExistScrollTableX = this.props.editableTableRef?.current?.scrollWidth > this.props.editableTableRef?.current?.clientWidth;

    let coordinationBtnType = 'default';
    if (coordinationDriverMode) {
      coordinationBtnType = 'orange';
    }
    if (driverTasks.length > 1) {
      coordinationBtnType = 'primal';
    }
    return (
      <div className="mainEditor">
        {coordinationDriverMode && driverTasks.length <= 1 && (
          <div
            style={{
              left: this.state.x,
              top: this.state.y,
            }}
            className={'mouseTextTooltip'}
          >
            {coordinationDriverText}
          </div>
        )}
        {coordinationDriverMode && <GlobalStyle />}
        <div className={'b-phasePlan__title b-table__wrapMainTitle'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 className={'b-table__mainTitle'} style={{ margin: '13px 0 6px 0' }}>
              Phase plan
            </h1>
            {getPhasePlanStatus?.fail && <span className={'error'}>{getPhasePlanError?.message || ''}</span>}
            {versions?.[0]?.title && isActivePhasePlan ? (
              <div className={'versionTitle'} onClick={handleOpenDownloadMenu}>
                {currentPrevVersion?.title || versions[0].title}
              </div>
            ) : null}
            {currentPrevVersion && (
              <span className={'save'} onClick={handleReturnToCurrentVersion}>
                Return to current version
              </span>
            )}
            {!currentPrevVersion && editMode && (
              <div className={'undoRedoBtns b-table__mainTitle'}>
                <div className={`undo ${currentStateIndex === 0 ? 'disabledSVG' : ''}`} onClick={handleUndo}>
                  <CustomTooltip title={`${currentStateIndex !== 0 ? 'Undo' : ''}`} placement={'bottom'}>
                    <span>
                      <SVG src={require('../../../assets/icons/undo.svg')} />
                    </span>
                  </CustomTooltip>
                </div>
                <div className={`redo ${currentStateIndex >= past.length ? 'disabledSVG' : ''}`} onClick={handleRedo}>
                  <CustomTooltip title={`${currentStateIndex < past.length ? 'Redo' : ''}`} placement={'bottom'}>
                    <span>
                      <SVG src={require('../../../assets/icons/undo.svg')} />
                    </span>
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
          <div className={'b-phasePlan__actionsBtn'}>
            {editMode && !currentPrevVersion && (
              <ButtonView
                type={coordinationBtnType as any}
                title={driverTasks.length > 1 ? 'Apply' : 'Coordination driver'}
                handleClick={handleCoordinationDriver}
                height={'30px'}
                width={'100%'}
              />
            )}
            {editMode && !currentPrevVersion && (
              <ButtonView
                type={'default'}
                title={'Exit edit mode'}
                handleClick={handleEditPhasePlan}
                height={'30px'}
                width={'100%'}
                style={{ margin: '0 10px 0 10px' }}
              />
            )}
            {isActivePhasePlan ? (
              <div
                className={`b-createProject__projectForm ${
                  errors.target_to || errors.target_start ? 'b-createProject__projectFormErrors' : ''
                }`}
              >
                <HtmlTooltip title={'To change the date, go to Edit Mode.'} placement={'top'}>
                  <div
                    className={'b-createProject__projectFormItem'}
                    onClick={editMode ? handleOpenCalendar('target_start') : () => void 0}
                  >
                    <div className={'b-createProject__label'}>Kick-off date:</div>
                    <div className={'b-createProject__text'}>
                      <div className={`b-createProject__datePicker ${errors.target_start ? 'b-createProject__datePickerError' : ''}`}>
                        <span>{formData?.['target_start'] ? moment(formData['target_start']).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
                        {editMode && !currentPrevVersion && <SVG src={require('../../../assets/icons/calendar.svg')} />}
                      </div>
                      <span className={'b-createProject__errorText'}>{errors.target_start}</span>
                    </div>
                  </div>
                </HtmlTooltip>
                <HtmlTooltip title={'To change the date, go to Edit Mode.'} placement={'top'}>
                  <div className={'b-createProject__projectFormItem'} onClick={editMode ? handleOpenCalendar('target_to') : () => void 0}>
                    <div className={'b-createProject__label'}>Project completion date:</div>
                    <div className={'b-createProject__text'}>
                      <div className={`b-createProject__datePicker ${errors.target_to ? 'b-createProject__datePickerError' : ''}`}>
                        <span>{formData?.['target_to'] ? moment(formData['target_to']).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
                        {editMode && !currentPrevVersion && <SVG src={require('../../../assets/icons/calendar.svg')} />}
                      </div>
                      <span className={'b-createProject__errorText'}>{errors.target_to}</span>
                    </div>
                  </div>
                </HtmlTooltip>
                <div className={'b-wrapIcon'} onClick={handleOpenSettings}>
                  <SVG
                    className={`b-header__icon ${isSettingsOpen ? 'b-header__icon_active' : ''}`}
                    src={require('../../../assets/icons/settings.svg')}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={'b-phasePlan'}>
          <ScrollSync horizontal={false}>
            <Paper style={styles.mainPaper as any}>
              <TablePlanContainer
                editableTableRef={editableTableRef}
                wrapTableRef={wrapTableRef}
                isOpenCollapse={isOpenCollapse}
                handleEditRow={handleEditRow}
                tableRef={this.tableRef}
                isAdmin={isAdmin}
                editMode={coordinationDriverMode || currentPrevVersion ? false : editMode}
                handleCancelChanges={handleCancelChanges}
                coordinationDriverMode={coordinationDriverMode}
                selectedDrivers={selectedDrivers}
                applyCoordinationDriver={applyCoordinationDriver}
                successApplyDriverChangesDate={successApplyDriverChangesDate}
                handleCollapse={handleCollapse}
                isExistScrollX={isExistScrollTableX}
                saveToStack={saveToStack}
                currentStateIndex={currentStateIndex}
                past={past}
                handleUndo={handleUndo}
                handleRedo={handleRedo}
                isActivePhasePlan={isActivePhasePlan}
              />
              <div className={'b-phasePlan__SchedulePlan'}>
                <ScrollSyncPane>
                  <ScrollContainer
                    className="scroll-container"
                    hideScrollbars={false}
                    component={`div`}
                    style={{
                      height: 'calc(100svh - 100px - 43px)',
                      overflowY: `${isExistScrollTableY ? 'auto' : 'hidden'}` as any,
                      overflow: `${isDrag ? 'hidden' : 'auto'}`,
                      backgroundColor: 'var(--background-main)',
                    }}
                    ignoreElements={'.b-phasePlan__scheduleDnDBlock'}
                  >
                    <SchedulePlan
                      handleCollapse={handleCollapse}
                      isOpenCollapse={isOpenCollapse}
                      wrapTableRef={wrapTableRef}
                      editableTableRef={editableTableRef}
                      setDateGMP={setDateGMP}
                      gmp={gmp}
                      editMode={coordinationDriverMode || currentPrevVersion ? false : editMode}
                      legendItemHover={legendItemHover}
                      hoverLegendMilestone={hoverLegendMilestone}
                      onChangeIsDrag={onChangeIsDrag}
                      isActivePhasePlan={isActivePhasePlan}
                    />
                  </ScrollContainer>
                </ScrollSyncPane>
              </div>
            </Paper>
          </ScrollSync>
          <ProjectCalendar
            typeOfOpenedCalendar={typeOfOpenedCalendar}
            anchorEl={anchorEl}
            handleCloseCalendar={handleCloseCalendar}
            selectAtDateHandler={selectAtDateHandler}
            formData={formData}
            selectAtDateHandlerSingle={selectAtDateHandlerSingle}
            updateProject={updateProject}
          />
          <PhasePlanDownload
            anchorEl={anchorElDownload}
            handleClose={handleCloseDownloadMenu}
            items={versions}
            handlePrevVersion={handlePrevVersion}
          />
          <PopUpView
            open={isSavePhasePlan}
            onClose={handelDeclineSavePhasePlan}
            maxWidth={'md'}
            title={'Save Phase Plan'}
            onSubmit={handelAcceptSavePhasePlan}
            disableBtn={true}
          >
            <div className={'containerPrompt'}>
              <div>
                <OutlinedInput
                  labelWidth={0}
                  name="phasePlan"
                  id="phasePlanText"
                  className={`b-createProject__input`}
                  placeholder={'Based on'}
                  onChange={handleChangePhasePlanName}
                />
              </div>
              <div className={'savePhasePlanBtnContainer'}>
                <ButtonView
                  handleClick={handelAcceptSavePhasePlan}
                  type={'primal'}
                  title={'Ok'}
                  style={{ margin: '0px 5px' }}
                  width={'140px'}
                  isAnimation={true}
                  isLoading={isSavingPhasePlan}
                  titleChanging={'Save'}
                  titleSuccess={'Saved'}
                />
                <ButtonView
                  handleClick={handelDeclineSavePhasePlan}
                  type={'cancel'}
                  title={'Cancel'}
                  style={{ margin: '0px 5px' }}
                  width={'140px'}
                />
              </div>
            </div>
          </PopUpView>
          <Popover
            id={'need-list-settings'}
            open={isSettingsOpen}
            anchorEl={anchorElSettings}
            onClose={handleCloseSettings}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className={'filter__listFilters'} style={{ borderRadius: '5px' }}>
              {!editMode && isAdmin && (
                <div className={'filter__item'} style={{ paddingLeft: '20px' }} onClick={handleEditPhasePlan}>
                  <span>Edit</span>
                </div>
              )}
              {editMode && isAdmin && (
                <div className={'filter__item'} style={{ paddingLeft: '20px' }} onClick={handleEditPhasePlan}>
                  <span>Exit edit menu</span>
                </div>
              )}
              {isAdmin && (
                <div className={'filter__item'} style={{ paddingLeft: '20px' }} onClick={handelSavePhasePlan}>
                  <span>Save</span>
                </div>
              )}
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

export default PhasePlanView;
