import React, { ReactElement, MouseEvent } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import CustomTooltip, { CustomTooltipProps } from '../../../Tooltip/Tooltip';
import './FilterSelectBasisStyles.scss';
import cn from 'classnames';
import TokenIcon from '../../../TokenIcon/TokenIcon';

interface FilterSelectBasisProps {
  title: string | ReactElement;
  clickHandler?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  tooltip?: string;
  tooltipProps?: CustomTooltipProps;
  isActive?: boolean;
  customClassName?: string;
  isMobile?: boolean;
  size?: 'sm';
}

const FilterSelectBasis: React.FC<FilterSelectBasisProps> = ({
  title,
  clickHandler,
  disabled,
  tooltip,
  tooltipProps,
  isActive,
  customClassName,
  isMobile,
  size,
}) => {
  const themeClass = useGetThemeClass('b-filterSelectBasis');

  const handleClick = (e: MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }

    clickHandler && clickHandler(e);
  };

  return (
    <>
      <CustomTooltip title={tooltip || ''} placement={'bottom'} {...(tooltipProps || {})}>
        <div
          onClick={handleClick}
          className={cn(`${themeClass}`, {
            ['-disabled']: disabled,
            ['-active']: isActive,
            [`-mobile`]: isMobile,
            [`${customClassName}`]: customClassName,
            [`-${size}`]: size,
          })}
        >
          <span className={cn(`${themeClass}_title`, { [`-mobile`]: isMobile })}>{title}</span>
          <TokenIcon customClass={`${themeClass}_icon`} iconName={'chevron-down'} size={isMobile ? 24 : 16} />
        </div>
      </CustomTooltip>
    </>
  );
};

export default FilterSelectBasis;
