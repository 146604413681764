import React, { useEffect, useMemo, useState } from 'react';
import Socket from 'socket.io-client';
import { WEB_SOCKET_DOMAIN } from '../../../service/links';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { isNeedListCached, saveNeedListCashingTime } from '../../../service/IndexedDB/Helper';
import { userSlice } from '../../../store/user/userSlice';
import { commentSlice } from '../../../store/comments/commentsReducer';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { snackBarSockets } from '../SnackBarSockets/SnackBarSockets';
import { checkIfSnippetInSandBoxSection, getSandBoxWidgets } from '../../../store/request/requestLogic(HOLD)';
import { logout } from '../../../store/user/userThunk';
import { isLoggedIn } from '../../../service/auth';
import { handleRequestSocket } from './SocketHelper/RequestSocketHelper';
import { handleDeliverableSocket } from './SocketHelper/DeliverableSocketHelper';
import { SubmittalSocketHelper } from './SocketHelper/SubmittalSocketHelper';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';
import { withSnackbar } from 'notistack';

const { updateUserInfo } = userSlice.actions;

const {
  socketCreateComments,
  socketUpdateComments,
  socketReplyComments,
  socketDeleteComments,
  socketCreateBallInCourt,
  socketEditBallInCourt,
} = commentSlice.actions;

const {
  socketHandleDueDateChangedRequestInfo,
  socketHandleDueDateChanged,
  socketHandleRequestCompanyChangedRequestInfo,
  socketHandleResponseCompanyChanged,
  addItemsToNeedList,
  setIsNeedListLoadingStatus,
} = requestSlice.actions;

const { setToDefault } = userSlice.actions;

const SocketComponent = ({ enqueueSnackbar }) => {
  const dispatch = useAppDispatch();
  const { getPCDParamsFromURL, getNfParamsFromURL } = useRoutingHelper();
  const { urlProject, searchParams, navigate } = useRouter();

  const activeProjectId = useAppSelector(state => state.userReducer.active_project_id);
  const userData = useAppSelector(state => state.userReducer);
  const sandBoxFilters = useAppSelector(state => state.requestReducer.sandBoxFilters);
  const sandBoxCache = useAppSelector(state => state.requestReducer.sandBoxCache);
  const alertList = useAppSelector(state => state.requestReducer.sandBoxWidgets?.alerts);

  const [socket, setSocket] = useState(null);
  const [socketNotifications, setSocketNotifications] = useState(null);
  const [socketSnackbars, setSocketSnackbars] = useState(null);
  const [socketSystemAlerts, setSocketSystemAlerts] = useState(null);

  const [pendingAction, setPendingAction] = useState<any[]>([]);
  const [isPendingAction, setIsPendingAction] = useState<boolean>(false);

  const onlyAuth = useMemo(() => {
    const isIframe = window.self !== window.parent;
    const onlyAuth = searchParams.get('onlyauth');
    return isIframe && !!onlyAuth ? onlyAuth : false;
  }, [searchParams]);

  // useEffect(() => {
  //   if (prevParam !== currentParam) {
  //     // Perform necessary actions when parameter in local storage changes
  //     // For example, update component state
  //     this.setState({ param: currentParam });
  //     setParam()
  //     unsubscribeSockets();
  //     initializeSockets();
  //   }
  // }, [param]);

  useEffect(() => {
    if (!isPendingAction && pendingAction?.length) {
      const { nf } = getNfParamsFromURL();
      pendingAction.forEach(data => {
        if (data.entity_class === 'request') {
          handleRequestSocket({
            socketData: data,
            setIsPendingAction,
            isNeedListLoadingStatus: false,
            isPendingAction: false,
            addPendingAction,
            isNLCached: true,
            dispatch,
            activeProjectId,
            isAllowToAddNf: isAllowToAddNf(data?.data?.is_whiteboard, data?.data?.author?.parties),
            nf,
          });
        }
      });
    }
  }, [pendingAction, isPendingAction]);

  useEffect(() => {
    if (activeProjectId && userData?.userInfo && isLoggedIn() && Number(activeProjectId) && !onlyAuth) {
      unsubscribeSockets();
      initializeSockets();
    }
    if (userData?.userInfo && !isLoggedIn()) {
      unsubscribeSockets();
    }
  }, [userData, activeProjectId]);

  // useEffect(() => {
  //   if (userData?.userInfo && isLoggedIn() && !onlyAuth) {
  //     unsubscribeSockets();
  //     initializeSockets();
  //   }
  // }, [userData, activeProjectId]);

  const addPendingAction = actionData => {
    setPendingAction([...pendingAction, actionData]);
  };

  const initializeSockets = () => {
    const newSocket = new Socket(WEB_SOCKET_DOMAIN, {
      query: {
        token: 'Bearer ' + localStorage.getItem('token'),
        channel: 'channel_' + localStorage.getItem('user_id'),
      },
    });
    setSocket(newSocket);

    newSocket.on('newNotification', ({ data }) => {
      const { module } = data;

      console.log('newNotification', data);

      if (module === 'team') {
        const { data: payloadData } = data;
        dispatch(updateUserInfo(payloadData));
      }

      if (module === 'needList') {
        const { data: payloadData, page, pages, hash } = data;
        const isLastPage = page === pages;

        if (hash !== sessionStorage.getItem('needListHash')) return;

        //Add nfs to redux
        // if isLastPage cache data to DB
        dispatch(addItemsToNeedList({ items: payloadData, isLastPage, projectId: activeProjectId }));

        if (isLastPage) {
          saveNeedListCashingTime(activeProjectId);
          sessionStorage.setItem('isNeedListLoadingStatus', JSON.stringify(false));
          dispatch(setIsNeedListLoadingStatus(false));

          const { nf } = getNfParamsFromURL();

          pendingAction?.length &&
            pendingAction.forEach(data => {
              if (data.entity_class === 'request') {
                handleRequestSocket({
                  socketData: data,
                  setIsPendingAction,
                  isNeedListLoadingStatus: false,
                  isPendingAction: false,
                  addPendingAction,
                  isNLCached: true,
                  dispatch,
                  activeProjectId,
                  isAllowToAddNf: isAllowToAddNf(data?.data?.is_whiteboard, data?.data?.author?.parties),
                  nf,
                });
              }
            });
        }
      }
      // Obsoleted logic should be deleted
      // this.props.setUnreadNotificationCount(data.notifications_new);
      // this.props.socketHandleNotification(data);
    });

    const newSocketSnackbars = new Socket(WEB_SOCKET_DOMAIN, {
      query: {
        token: 'Bearer ' + localStorage.getItem('token'),
        channel: `snackbar_channel_${userData?.userInfo?.id}`,
      },
    });
    setSocketSnackbars(newSocketSnackbars);

    newSocketSnackbars.on('snackbar', ({ data }) => {
      console.log('snackbar', data);
      snackBarSockets(data.text, data.color, enqueueSnackbar);
    });

    const newSocketNotifications = new Socket(WEB_SOCKET_DOMAIN, {
      query: {
        token: 'Bearer ' + localStorage.getItem('token'),
        channel: `refresh_channel_${activeProjectId}`,
      },
    });
    setSocketNotifications(newSocketNotifications);

    const newSocketSystemAlerts = new Socket(WEB_SOCKET_DOMAIN, {
      query: {
        token: 'Bearer ' + localStorage.getItem('token'),
        channel: `system_alerts_${userData?.userInfo?.id}`,
      },
    });
    setSocketSystemAlerts(newSocketSystemAlerts);

    newSocketSystemAlerts.on('newNotificationAlert', ({ data }) => {
      const { module, action } = data;

      if (module === 'user') {
        if (action === 'logout') {
          dispatch(
            logout({
              callback: () => {
                navigate('/');
              },
              isSocket: true,
            }),
          );
          dispatch(setToDefault());
        }
      }
    });

    newSocketNotifications.on('refresh', ({ data }) => {
      const selectRequestId = sessionStorage.getItem('isRq');
      const alertListFull = alertList && alertList.feed.concat(alertList.remind_later);
      const alertItemToRefresh = alertListFull?.find(alert => alert.action[`${data.entity_class}_id`] === data.entity_id);
      const related_to = localStorage.getItem('sandbox_related_to') || 'user';

      const isNeedListLoadingStatus = JSON.parse(sessionStorage.getItem('isNeedListLoadingStatus'));
      const isNLCached = isNeedListCached(activeProjectId);

      console.log('refresh', data);

      if (activeProjectId && alertItemToRefresh) {
        const widgets = {
          related_to: related_to,
          widgets: [
            {
              alias: 'alerts',
              options: {
                types: ['overdueRevision', 'requiredReview', 'userMentioned', 'bicOverloaded'],
              },
            },
          ],
        };

        dispatch(getSandBoxWidgets(widgets));
      }

      if (data.entity_class === 'request') {
        const { nf } = getNfParamsFromURL();
        handleRequestSocket({
          socketData: data,
          setIsPendingAction,
          isNeedListLoadingStatus,
          isPendingAction,
          addPendingAction,
          isNLCached,
          dispatch,
          activeProjectId,
          isAllowToAddNf: isAllowToAddNf(data?.data?.is_whiteboard, data?.data?.author?.parties),
          nf,
        });
      }

      if (data.entity_class === 'deliverable') {
        const { pcdId } = getPCDParamsFromURL();
        handleDeliverableSocket({ socketData: data, dispatch, urlProject, pcdId });
      }

      if (data.entity_class === 'submittal') {
        SubmittalSocketHelper({ socketData: data, dispatch });
      }

      if (
        (data.action === 'created' ||
          data.action === 'updated' ||
          data.action === 'deleted' ||
          data.action === 'delete' ||
          data.action === 'view') &&
        (data.event === 'hotlist' || !data.event)
      ) {
        if (
          data.action === 'created' ||
          data.action === 'updated' ||
          data.action === 'view' ||
          data.action === 'deleted' ||
          data.action === 'delete'
        ) {
          const get_entity_class = () => {
            if (data.entity_class === 'submittal') {
              if (data.data_sandbox.is_output) {
                return 'submittalOutput';
              } else {
                return 'submittalInput';
              }
            } else {
              return data.entity_class;
            }
          };
          const cacheArrayParams = Object.keys(sandBoxCache).reduce((result, related_to) => {
            if (sandBoxCache[related_to][get_entity_class()] && Object.keys(sandBoxCache[related_to][get_entity_class()]).length > 0) {
              Object.keys(sandBoxCache[related_to][get_entity_class()]).map(filter_by => {
                result.push({
                  ...sandBoxFilters,
                  filter_by: filter_by,
                  related_to: related_to,
                });
              });
            }
            return result;
          }, []);

          const newData = { ...data.data_sandbox };

          if (data.action === 'view') {
            if (data.data_sandbox.marker) {
              newData.marker = null;
              newData.is_hot_list = data.data.is_hot_list;

              cacheArrayParams.map(params => {
                dispatch(
                  checkIfSnippetInSandBoxSection({ sandBoxItem: newData, entity: get_entity_class(), sandBoxFiltersParams: params }),
                );
              });
            }
          } else {
            if (data?.data?.is_hot_list !== data?.data_sandbox?.is_hot_list) {
              newData.is_hot_list = data?.data?.is_hot_list;
            }

            cacheArrayParams.map(params => {
              dispatch(checkIfSnippetInSandBoxSection({ sandBoxItem: newData, entity: get_entity_class(), sandBoxFiltersParams: params }));
            });
          }
        }
      }
      if ((data.action === 'created' || data.action === 'updated') && data.entity_class === 'responsecompanychangerequest') {
        dispatch(socketHandleResponseCompanyChanged(data.data));
        dispatch(socketHandleRequestCompanyChangedRequestInfo(data.data));
      }
      if ((data.action === 'created' || data.action === 'updated') && data.entity_class === 'duedatechangerequest') {
        dispatch(socketHandleDueDateChanged(data.data));
        dispatch(socketHandleDueDateChangedRequestInfo(data.data));
      }
      if (data.action === 'created' && data.entity_class === 'comment') {
        if (userData?.userInfo?.id !== data.data.author.id && selectRequestId == data.data.owner_id) {
          dispatch(socketCreateComments(data.data));
        }
      }
      if ((data.action === 'updated' || data.action === 'reply_updated') && data.entity_class === 'comment') {
        if (userData?.userInfo?.id !== data.responsible_user && selectRequestId == data.data.owner_id) {
          dispatch(socketUpdateComments(data.data));
        }
      }
      if (data.action === 'reply_created' && data.entity_class === 'comment') {
        if (userData?.userInfo?.id !== data.data.author.id && selectRequestId == data.data.owner_id) {
          dispatch(socketReplyComments(data.data));
        }
      }
      if ((data.action === 'deleted' || data.action === 'reply_deleted') && data.entity_class === 'comment') {
        if (selectRequestId == data.data.entity_id) {
          dispatch(socketDeleteComments(data.data));
        }
      }
      if (data.action === 'created' && data.entity_class === 'ballincourt') {
        if (userData?.userInfo?.id !== data.data.author.id && selectRequestId == data.data.entity_id) {
          dispatch(socketCreateBallInCourt(data.data));
        }
      }
      if (data.action === 'updated' && data.entity_class === 'ballincourt') {
        if (userData?.userInfo?.id !== data.data.author.id && selectRequestId == data.data.entity_id) {
          dispatch(socketEditBallInCourt(data.data));
        }
      }
    });
  };

  const unsubscribeSockets = () => {
    // Clean up function

    if (socket) {
      socket.close();
    }
    if (socketNotifications) {
      socketNotifications.close();
    }
    if (socketSnackbars) {
      socketSnackbars.close();
    }
    if (socketSystemAlerts) {
      socketSystemAlerts.close();
    }
  };

  const isAllowToAddNf = (is_whiteboard, createdNfParties) => {
    // Function to check if an item from array1 exists in array2
    function itemExistsInArray(item, array) {
      return array.some(element => element.id === item.id && element.name === item.name);
    }

    // Check if any item from array1 exists in array2
    function checkIfExists(array1, array2) {
      for (const item of array1) {
        if (itemExistsInArray(item, array2)) {
          return true; // Return true if a matching item is found
        }
      }
      return false; // Return false if no matching item is found
    }

    const isSuperAdmin = '4' in userData?.userInfo?.roles;
    const isAdmin = '1' in userData?.userInfo?.roles;
    const isManager = '5' in userData?.userInfo?.roles;

    if (isSuperAdmin || isAdmin || isManager) return true;

    if (is_whiteboard === 0 && !checkIfExists(userData?.userInfo?.parties, createdNfParties)) {
      return false;
    }

    return true;
  };

  return <></>;
};

export default withSnackbar(SocketComponent);
