import React, { FC } from 'react';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import cn from 'classnames';

import './GroupingCellStyles.module.scss';

const GroupingCell: FC<any> = ({ row, renderGroupingCellTitle, getChipsColor, isHiddenKeyTitle = false }) => {
  const padding = 24;
  const themeClass = useGetThemeClass('groupingCell');

  const isEmptyState = row?.rows.length === 0 || (row?.rows.length === 1 && row?.rows[0].code);

  return (
    <div
      style={{ paddingLeft: `${row?.depthLevel ? `${row?.depthLevel * padding}px` : ''}` }}
      className={cn(`${themeClass}_container`, { '-emptyState': isEmptyState })}
    >
      <span
        className={cn(`${themeClass}_arrow`, {
          '-expanded': row.expanded,
          '-emptyState': isEmptyState,
        })}
      >
        <TokenIcon iconName={'chevron-right'} size={12} />
      </span>
      {renderGroupingCellTitle ? (
        renderGroupingCellTitle(row.key, row.value, getChipsColor(row.key, row), row.rows?.length, themeClass, row)
      ) : (
        <div className={`${themeClass}_text`}>
          {!isHiddenKeyTitle && <span>{row?.key.split('_').join(' ')}: </span>}
          <span className={`${themeClass}_childRows`}>
            {row?.value} ({row?.rows.length})
          </span>
        </div>
      )}
    </div>
  );
};

export default GroupingCell;
