import React, { ReactElement, ReactNode, useEffect, useState, useRef } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import { RequestUserModel, WatcherGroupModel, WatcherModel } from '../../../../../models';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { IRequestFindWatchersToGroup, IRequestWatchersGroup } from '../../../../../store/request/requestReducer.model';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';

import './DropdownPopoverWatchlistStyles.module.scss';

type GroupVisibleType = {
  watchers: { is_visible: boolean };
  groups: { is_visible: boolean };
};

interface DropdownPopoverWatchlistProps {
  size: 'sm' | 'md';
  menuItems: RequestUserModel[];
  findWatchersItems: IRequestFindWatchersToGroup[];
  watcherSelected: WatcherModel[];
  groupItems: IRequestWatchersGroup[];
  linkedGroups: WatcherGroupModel[];
  customClassName?: string;
  renderItemHeader: () => ReactElement | ReactNode;
  renderItemFooter?: () => ReactElement | ReactNode;
  renderMenuItem: (item: RequestUserModel) => ReactElement | ReactNode;
  renderMenuGroupItem: (item: WatcherGroupModel) => ReactElement | ReactNode;
  anchorEl: ReactNode;
  anchorElsearch: HTMLDivElement;
  onOpen?: (event?: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onClose?: (event?: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onSelectClose?: (type: 'watcher' | 'group') => void;
  isMenuOpen: boolean;
  searchValue: string;
  handleCreateGroup: (isEdit: boolean) => void;
  handleSelectItem: (selected: RequestUserModel[]) => void;
  handleSelectGroup: (selected: IRequestWatchersGroup[]) => void;
  handleEditGroup: (e: Event, item: WatcherGroupModel, isPreview: boolean) => void;
  handleDeleteGroup: (e: Event, item: WatcherGroupModel) => void;
  handleCloseAuthorUser: () => void;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => void;
}

function DropdownPopoverWatchlist({
  customClassName,
  renderItemFooter,
  menuItems,
  findWatchersItems,
  watcherSelected,
  groupItems,
  linkedGroups,
  renderMenuItem,
  renderMenuGroupItem,
  anchorEl,
  onOpen,
  onClose,
  onSelectClose,
  isMenuOpen,
  handleCreateGroup,
  handleSelectItem,
  handleSelectGroup,
  handleEditGroup,
  handleDeleteGroup,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
}: DropdownPopoverWatchlistProps) {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-dropdownSearchPopoverWatchlist');
  const themeClassMenu = useGetThemeClass('b-dropdownPopoverWatchlist');
  const themeClassPaper = useGetThemeClass('b-dropdownPopoverWatchlistPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const GroupVisible = {
    watchers: { is_visible: true },
    groups: { is_visible: true },
  };
  const [groupVisible, setGroupVisible] = useState<GroupVisibleType>(GroupVisible);

  useEffect(() => {
    if (isMenuOpen) {
      onOpen && onOpen();

      const GroupVisible = {
        watchers: { is_visible: true },
        groups: { is_visible: true },
      };
      setGroupVisible(GroupVisible);
    }
  }, [isMenuOpen]);

  const handleAccordion = nameGroup => {
    const v = { ...groupVisible };
    if (nameGroup === 'watchers') {
      v['watchers'].is_visible = !v['watchers'].is_visible;
    }
    if (nameGroup === 'groups') {
      v['groups'].is_visible = !v['groups'].is_visible;
    }
    setGroupVisible(v);
  };

  return (
    <>
      <Popover
        open={!!isMenuOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        onOpen={onOpen}
        classes={{
          paper: cn(`${themeClassCallout} ${themeClassPaper}`, {
            ['-mobile']: isMobile,
          }),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          className={cn(`${themeClassMenu}`, {
            [`${customClassName}`]: customClassName,
            [`-mobile`]: isMobile,
          })}
        >
          <div className={`${themeClassMenu}_itemList`}>
            <div className={`${themeClassMenu}_itemList_group`}>
              <div className={`${themeClassMenu}_itemList_group_name -between`}>
                <div className={`${themeClassMenu}_itemList_group_name_second_group`} onClick={() => handleAccordion('watchers')}>
                  {groupVisible.watchers.is_visible ? (
                    <SystemButton type={'chevron-down'} variant={'transparent'} size={'md'} />
                  ) : (
                    <SystemButton type={'chevron-right'} variant={'transparent'} size={'md'} />
                  )}
                  <div className={`${themeClassMenu}_itemList_group_name_title`}>Watchers</div>
                </div>
                <Select<RequestUserModel, unknown, true>
                  isMulti={true}
                  menuItems={findWatchersItems.map(item => ({
                    ...item,
                    title: `${item?.profile?.first_name} ${item?.profile?.last_name}`,
                  }))}
                  selectHandler={selected => handleSelectItem(selected)}
                  onMenuClose={() => onSelectClose('watcher')}
                  selected={findWatchersItems.filter(item => watcherSelected?.some(watcher => watcher.user_id === item.id))}
                  menuItemSize={'md'}
                  renderCustomBasis={() => <SystemButton type={'plus'} size={'md'} variant={'transparent'} />}
                  menuItemLeftElemRenderer={item => (
                    <div className={`${themeClass}_watchersMenuItem`}>
                      <div onMouseEnter={e => handleOpenAuthorUser(e, item)} onMouseLeave={e => handleCloseAuthorUser()}>
                        <PlaceholderInfo
                          type={'Persona'}
                          firstName={item?.profile?.first_name}
                          lastName={item?.profile?.last_name}
                          imagePath={item?.profile?.image}
                          detailInfo={`${item?.profile?.first_name} ${item?.profile?.last_name}`}
                          size={24}
                        />
                      </div>
                      <div className={`${themeClass}_company_name`}> {item?.parties?.map(item => item?.company).join(', ')} </div>
                    </div>
                  )}
                  menuItemTitleRenderer={() => ''}
                  isHeader
                />
              </div>
              {groupVisible.watchers.is_visible && (
                <>
                  {menuItems?.length > 0 ? (
                    menuItems?.map(menuItem => {
                      return <>{renderMenuItem(menuItem)}</>;
                    })
                  ) : (
                    <div className={`${themeClassMenu}_empty`}>
                      <span>There are no watchers for this NF. Add first one by clicking “+”.</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={`${themeClassMenu}_itemList_group`}>
              <div className={`${themeClassMenu}_itemList_group_name -between`}>
                <div className={`${themeClassMenu}_itemList_group_name_second_group`} onClick={() => handleAccordion('groups')}>
                  {groupVisible.groups.is_visible ? (
                    <SystemButton type={'chevron-down'} variant={'transparent'} size={'md'} />
                  ) : (
                    <SystemButton type={'chevron-right'} variant={'transparent'} size={'md'} />
                  )}
                  <div className={`${themeClassMenu}_itemList_group_name_title`}>Watcher groups</div>
                </div>
                <Select<IRequestWatchersGroup, unknown, true>
                  isMulti={true}
                  menuItems={groupItems}
                  selectHandler={selected => handleSelectGroup(selected)}
                  selected={linkedGroups}
                  menuItemSize={'md'}
                  onMenuClose={() => onSelectClose('group')}
                  customMenuItemClass={cn(`${themeClassMenu}_select_item`)}
                  renderCustomBasis={() => <SystemButton type={'plus'} size={'md'} variant={'transparent'} />}
                  menuItemRightElemRenderer={item => (
                    <div className={`${themeClassMenu}_select_item_right`}>
                      <SystemButton
                        type={'edit'}
                        size={'md'}
                        variant={'transparent'}
                        clickHandler={e => {
                          handleEditGroup(e, item, false);
                        }}
                      />
                      <SystemButton
                        type={'delete'}
                        size={'md'}
                        variant={'transparent'}
                        clickHandler={e => {
                          handleDeleteGroup(e, item.id);
                        }}
                      />
                    </div>
                  )}
                  isHeader
                  isFooter
                  footerType={'divided-button'}
                  footerButtonTitle={'Add new group'}
                  footerButtonClick={() => handleCreateGroup(false)}
                  footerIcon={<TokenIcon iconName={'plus'} size={12} customClass={`${themeClassMenu}_select_footer_icon`} />}
                />
              </div>
              {groupVisible.groups.is_visible && (
                <div className={`${themeClassMenu}_itemList_group_listGroup`}>
                  {linkedGroups?.length > 0 ? (
                    linkedGroups?.map(groupItem => {
                      return <>{renderMenuGroupItem(groupItem)}</>;
                    })
                  ) : (
                    <div className={`${themeClassMenu}_empty`}>
                      <span>There are no watcher groups for this NF. Create new group or add existing by clicking “+”.</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {renderItemFooter ? <>{renderItemFooter()}</> : null}
        </div>
      </Popover>
    </>
  );
}

export default DropdownPopoverWatchlist;
