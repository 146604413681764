import React, { ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TokenIcon from '../../TokenIcon/TokenIcon';
import SVG from 'react-inlinesvg';
import cn from 'classnames';
import Timeout = NodeJS.Timeout;

import './ButtonStyles.scss';

export const CircularProgressBtn = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  colorPrimary: {
    color: '#FFFFFF',
  },
})(CircularProgress);

interface IProps {
  type:
    | 'primal'
    | 'outline'
    | 'decline'
    | 'commit'
    | 'default'
    | 'orange'
    | 'pour'
    | 'comment'
    | 'commentCreate-dark'
    | 'commentCreate-light'
    | 'commentAdd'
    | 'accept'
    | 'pullRequest'
    | 'build'
    | 'cancel'
    | 'moreFilters'
    | 'sorting'
    | 'allMembers'
    | 'status'
    | 'link'
    | 'request-default'
    | 'request-default-fill'
    | 'request-action'
    | 'request-outlined'
    | 'show-hide-outlined'
    | 'addZone'
    | 'reviewers'
    | 'deliverable'
    | 'outdate'
    | 'current'
    | 'buildings'
    | 'ddSelect'
    | 'relationSwitcher_plain'
    | 'relationSwitcher_active';
  title: string | ReactElement;
  handleClick: (e: Event) => void;
  height?: string | number;
  width?: string | number;
  disabled?: boolean;
  tooltip?: string;
  style?: any;
  styleAnimation?: any;
  id?: any;
  className?: string;
  plus?: boolean;
  moreFilters?: boolean;
  sorting?: boolean;
  isAnimation?: boolean;
  isLoading?: boolean;
  titleChanging?: string;
  titleSuccess?: string;
  numberBtn?: string;
  colorBtn?: string;
  selectRequestId?: number;
  isComment?: boolean;
  isCommentAdd?: boolean;
  isOpenCommentForm?: boolean;
  isBIC?: boolean;
  isDeliverable?: boolean;
  isBuildings?: boolean;
  icon?: string;
  isAttachFileBtn?: boolean;
  isRibbon?: boolean;
  isShowRibbonSet?: boolean;
  themeClassActiveRibbon?: string;
  isMobile?: boolean;
}

interface IState {
  titleBtn: string;
  titleBtnChanging: string;
  titleBtnSuccess: string;
  isSuccess: boolean;
  isSuccessBtn: boolean;
  isAnimationBtn: boolean;
  isLoadingBtn: boolean;
  colorBtn?: string;
}
class ButtonView extends React.Component<IProps, IState> {
  state = {
    titleBtn: '',
    titleBtnChanging: '',
    titleBtnSuccess: '',
    isSuccess: false,
    isSuccessBtn: false,
    isAnimationBtn: false,
    isLoadingBtn: false,
    colorBtn: '',
  };

  timer: Timeout;
  componentDidUpdate(prevProps, prevState) {
    //TODO temp fix for new attachFile styles (!this.props.isAttachFileBtn &&)
    if (!this.props.isAttachFileBtn && this.props.isAnimation && !this.state.titleBtn) {
      this.setState({
        titleBtn: this.props.title?.toString(),
        titleBtnChanging: this.props.titleChanging,
        titleBtnSuccess: this.props.titleSuccess,
        colorBtn: this.props.colorBtn,
      });
    }
    if (!this.props.isAnimation && this.props.isAnimation !== prevProps.isAnimation && this.state.isAnimationBtn) {
      this.setState({
        isAnimationBtn: false,
      });
    }
    if (this.props.isAnimation && prevProps.selectRequestId && this.props.selectRequestId !== prevProps.selectRequestId) {
      this.clearAnimation();
      setTimeout(() => {
        this.clearAnimation();
      }, 0);
      clearTimeout(this.timer);
    }
    if (
      this.props.isAnimation &&
      this.props.titleChanging != prevProps.titleChanging &&
      this.props.numberBtn === 'second' &&
      prevProps.numberBtn != 'first'
    ) {
      this.setState({
        titleBtn: '',
      });
    }
    if (this.props.isAnimation && this.props.isLoading && !this.state.isSuccessBtn) {
      this.setState({
        isSuccess: !this.props.isLoading,
        isLoadingBtn: true,
        isSuccessBtn: true,
        isAnimationBtn: true,
      });
    }
    if (this.props.isAnimation && !this.props.isLoading && prevProps.isLoading) {
      this.setState({
        titleBtn: this.props.title?.toString(),
        isSuccessBtn: true,
        isAnimationBtn: true,
      });
      this.timer = setTimeout(() => {
        this.clearAnimation();
      }, 4000);
    }
    if (this.props.disabled && this.props.disabled !== prevProps.disabled) {
      this.timer = setTimeout(() => {
        this.clearAnimation();
      }, 4000);
      clearTimeout(this.timer);
    }
  }

  clearAnimation = () => {
    this.setState({
      isSuccessBtn: false,
      isAnimationBtn: false,
      titleBtn: '',
    });
  };

  handleClick = e => {
    const { handleClick, disabled, isLoading } = this.props;
    const { isAnimationBtn, isSuccessBtn } = this.state;
    e.preventDefault();
    if (isAnimationBtn && !isLoading && isSuccessBtn) {
      return;
    }

    if (disabled) {
      return void 0;
    }

    handleClick && handleClick(e);
  };

  render() {
    const {
      title,
      height,
      width,
      disabled,
      tooltip,
      style,
      styleAnimation,
      id,
      className,
      type,
      plus,
      moreFilters,
      sorting,
      isLoading,
      isComment,
      isCommentAdd,
      isOpenCommentForm,
      isBIC,
      isDeliverable,
      isBuildings,
      icon,
      isAttachFileBtn,
      isRibbon,
      isShowRibbonSet,
      themeClassActiveRibbon,
      isMobile,
    } = this.props;

    const { isSuccessBtn, isAnimationBtn, titleBtnSuccess, titleBtnChanging, colorBtn } = this.state;

    let renderTitle = title || '';
    if (plus) {
      renderTitle = (
        <span className={'addRange'}>
          <span className={'plus'}></span>
          <span>{title}</span>
        </span>
      );
    }
    if (moreFilters || sorting || isBuildings) {
      renderTitle = (
        <div className={'containerProgressSelect'}>
          <div className={'titleBox'}>
            <span className={'icon'}>
              <SVG
                src={
                  isBuildings
                    ? require('../../../../assets/icons/building_icon_filters.svg')
                    : sorting
                    ? require('../../../../assets/icons/sorting_icon.svg')
                    : require('../../../../assets/icons/new_filter_icon.svg')
                }
              />
            </span>
            <span className={'addRange'}>
              <span>{title}</span>
            </span>
          </div>
          <div className={'arrow_select'}>
            <SVG src={require('../../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
          </div>
        </div>
      );
    }
    if (isDeliverable) {
      renderTitle = (
        <div className={'containerProgressSelect'}>
          <div className={'titleBox'}>
            <span className={'addRange'}>
              <span>{title}</span>
            </span>
          </div>
          <div className={'arrow_select'}>
            <SVG src={require('../../../../assets/icons/arrow_select.svg')} className={`vectorSuccessProgress`} />
          </div>
        </div>
      );
    }
    if (isAnimationBtn && isLoading) {
      renderTitle = (
        <div
          className={cn('containerProgress', { attachBtnProgress: isAttachFileBtn })}
          style={{
            ...styleAnimation,
          }}
        >
          {icon ? (
            <div className={'circularProgressAbsolute'}>
              <CircularProgressBtn size={16} />
            </div>
          ) : (
            <div className={'circularProgress'}>
              <CircularProgressBtn size={16} />
            </div>
          )}
          <span className={'addRange'}>
            <span>{titleBtnChanging}</span>
          </span>
        </div>
      );
    }

    if (isAnimationBtn && !isLoading && isSuccessBtn) {
      renderTitle = (
        <div
          className={cn('containerProgress', { attachBtnProgress: isAttachFileBtn })}
          style={{
            ...styleAnimation,
          }}
        >
          <div className={`successCircularProgress ${icon ? 'absolute' : ''}`}>
            <SVG src={require('../../../../assets/images/vectorSuccessProgress.svg')} className={`vectorSuccessProgress`} />
          </div>
          <span className={'addRange'}>
            <span>{titleBtnSuccess}</span>
          </span>
        </div>
      );
    }

    const getBtnClass = (colorBtn: string) => {
      if (colorBtn === 'red') {
        return 'buttonView__btnSuccessRed';
      }
      if (colorBtn === 'orange') {
        return 'buttonView__btnSuccessOrange';
      }
      return !isCommentAdd ? 'buttonView__btnSuccess' : 'buttonView__btnSuccessComment';
    };

    return (
      <Tooltip disableFocusListener title={tooltip ? tooltip : ''}>
        {/*<div className={type && `btn-${type}` || 'btn-primal'}>*/}
        <button
          id={id || `${type || 'primal'}-${Math.ceil(Math.random() * 1000)}`}
          className={`buttonView buttonView_${type}
                          ${isShowRibbonSet && themeClassActiveRibbon}
                          ${disabled && !isAnimationBtn && !isCommentAdd ? '-bw-disabled' : ''}
                          ${disabled && !isAnimationBtn && isCommentAdd ? '-createDisabled' : ''}
                          ${className || ''}
                          ${isAnimationBtn ? 'buttonView__btnProgress' : ''}
                          ${isAnimationBtn && isLoading && !isCommentAdd ? 'buttonView__btnLoading' : ''}
                          ${isAnimationBtn && isLoading && isCommentAdd && 'buttonView__btnLoadingComment'}
                          ${isAnimationBtn && !isLoading && isSuccessBtn ? getBtnClass(colorBtn) : ''}
                          ${isMobile ? '-mobile' : ''}`}
          onClick={this.handleClick}
          style={
            !isComment
              ? {
                  width: width ? width : '100px',
                  height: height ? height : '32px',
                  cursor: disabled ? 'inherit' : 'pointer',
                  ...style,
                }
              : {}
          }
        >
          {isBIC && <SVG src={require('../../../../assets/icons/BIC.svg')} />}
          {isComment && !isOpenCommentForm && !isCommentAdd && <TokenIcon iconName={'comment-2'} size={isMobile ? 32 : 24} />}
          {isComment && isOpenCommentForm && <TokenIcon iconName={'close'} size={24} />}
          {isAttachFileBtn && <SVG src={require('../../../../assets/icons/attachFileNew.svg')} />}
          {isRibbon && <SVG src={require('../../../../assets/icons/ribbonIcon.svg')} />}
          {icon && (
            <SVG
              className={'buttonView__icon'}
              style={disabled ? { opacity: '0.5' } : {}}
              src={require(`../../../../assets/icons/${icon}`)}
            />
          )}
          {renderTitle}
        </button>
      </Tooltip>
    );
  }
}

export default ButtonView;
