import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Scatter,
  Brush,
  LabelList,
  Tooltip,
  ReferenceLine,
  Label,
} from 'recharts';
import moment from 'moment';
import { ColorsLevels } from './contants';

type BarsItemsType = {
  color: string | null;
  title: string;
  total: number;
};

type BarsType = {
  date: string;
  items: BarsItemsType[];
};

const DesignDocumentSetShape = (props: any): JSX.Element => {
  const text = `translate(${props.cx}, ${props.cy - 3}) rotate(45)`;
  if (props.cy) {
    return (
      <g>
        <path
          fill="#BB2525"
          width="9.0270333367641"
          height="9.0270333367641"
          cx={props.cx}
          cy={props.cy - 3}
          x={props.x}
          y={props.y}
          transform={text}
          d="M-4,-4h8v8h-8Z"
        ></path>
      </g>
    );
  } else {
    return <g></g>;
  }
};

const ReportingComposedChart: FC<{
  header_data: any;
  isAllCompanies: boolean;
  loading: boolean;
  timePeriodName: string;
  getBarChartList: (list: any) => void;
}> = ({ header_data, isAllCompanies, loading, timePeriodName, getBarChartList }) => {
  const [barChartLabels, setBarChartLabels] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [barMax, setBarMax] = useState<number>(null);
  const [isBarMax, setIsBarMax] = useState<boolean>(false);
  const [isDDSet, setIsDDSet] = useState<boolean>(false);

  useEffect(() => {
    if (isAllCompanies) {
      const allBars = header_data.bars.flatMap(item => item?.items);
      const allBarsTitle = Array.from(new Set(allBars.map(item => item.title)));
      const allUniqBars = [];
      allBarsTitle.forEach((title, index) => {
        const findIndex = allBars.findIndex(item => item.title == title);
        const objBars = {
          title: allBars[findIndex].title,
          color: allBars[findIndex].color || ColorsLevels[index],
        };
        allUniqBars.push(objBars);
      });
      setBarChartLabels(allUniqBars);
      getBarChartList(allUniqBars);
      const allBarsData = header_data.bars;
      const allBarsDataNoItems = allBarsData.map(item => {
        const obj = {
          date: item.date,
        };
        item.items?.forEach &&
          item.items.forEach(value => {
            obj[value.title] = value.total;
          });
        return obj;
      });
      const allBars_allDDSet = [...allBarsDataNoItems];
      setBarChartData(allBars_allDDSet);
      setIsDDSet(false);
      setBarMax(null);
      setIsBarMax(false);
    } else {
      const allUniqBars = [
        { title: 'Requester', color: '#A92D2D' },
        { title: 'Responder', color: '#51905B' },
      ];
      setBarChartLabels(allUniqBars);
      getBarChartList(allUniqBars);
      const allBarsData = header_data.bars;
      const allBarsDataNoItems = allBarsData.map(item => {
        const obj = {
          date: item.date,
        };
        if (item.items.requester > 0) {
          obj.Requester = item.items.requester;
        }
        if (item.items.responder > 0) {
          obj.Responder = item.items.responder;
        }
        return obj;
      });
      const allBars_allDDSet = [...allBarsDataNoItems];

      setBarChartData(allBars_allDDSet);
      setIsDDSet(false);
      setBarMax(null);
      setIsBarMax(false);
    }
  }, [header_data]);

  useEffect(() => {
    if (isBarMax) {
      const all_dd_set_u = Array.from(new Set(header_data.dd_sets.map(item => moment(new Date(item.date)).format('YYYY-MM-DD'))));
      const all_dd_set = header_data.dd_sets.map(item => {
        const somedate = new Date(item.date);
        const obj = {
          date: moment(somedate).format('YYYY-MM-DD'),
          dd: barMax,
          set: item.title,
        };
        return obj;
      });
      const all_dd_sets = [];
      all_dd_set_u.forEach(date => {
        const all_sets_date = all_dd_set.filter(item => item.date == date);
        const obj = {
          date: date,
          dd: barMax,
          set: all_sets_date[all_sets_date.length - 1].set,
        };
        all_dd_sets.push(obj);
      });

      const all_ppi_log_all_bars =
        barChartData.length > 0
          ? barChartData.map(item => {
              const findIndex = header_data.ppi_log.findIndex(value => value.date == item.date);
              if (findIndex > -1) {
                const obj = { ...item };
                obj.ppi_log_value = header_data.ppi_log[findIndex].value * barMax;
                return obj;
              } else {
                return item;
              }
            })
          : barChartData;

      all_ppi_log_all_bars.sort((a, b) => new Date(a.date) - new Date(b.date));
      all_dd_sets.forEach(item => {
        const findIndexDDSet = all_ppi_log_all_bars.findIndex(value => value.date == item.date);
        if (findIndexDDSet > -1) {
          const obj = all_ppi_log_all_bars[findIndexDDSet];
          obj.dd = item.dd;
          obj.set = item.set;
          all_ppi_log_all_bars[findIndexDDSet] = obj;
        } else {
          const findDDSet = all_ppi_log_all_bars.filter(value => value.date < item.date);
          const prev_date = findDDSet?.length > 0 ? findDDSet[findDDSet.length - 1] : null;
          const findIndexDate = prev_date ? all_ppi_log_all_bars.findIndex(value => value.date == prev_date.date) + 1 : 0;
          const obj = all_ppi_log_all_bars[findIndexDate];
          obj.dd = item.dd;
          obj.set = item.set;
          all_ppi_log_all_bars[findIndexDate] = obj;
        }
      });

      const allBars_allDDSet = [...all_ppi_log_all_bars];
      allBars_allDDSet.sort((a, b) => new Date(a.date) - new Date(b.date));

      setBarChartData(allBars_allDDSet);
      setTimeout(() => {
        setIsDDSet(true);
      }, 1000);
    }
  }, [isBarMax]);

  const getBarMax = (tick: any) => {
    if (!barMax) {
      setBarMax(tick);
      setIsBarMax(true);
    }
    return tick;
  };

  const getFormatDate = (tick: any) => {
    if (timePeriodName === 'Last year' || timePeriodName === 'All history') {
      return moment(tick).format('MMMM `YY');
    } else {
      return moment(tick).format('MM/DD/YYYY');
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${moment(label).format('MM/DD/YYYY')}`}</p>
          {payload.map((item, index) => (
            <p className="desc">{`${payload[index].name === 'ppi_log_value' ? 'PPI' : payload[index].name} : ${
              payload[index].name === 'ppi_log_value'
                ? header_data.ppi_log.find(item => item.date === payload[index].payload.date).value
                : payload[index].value
            }`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width={500} height={300}>
      <ComposedChart
        width={495}
        height={300}
        data={barChartData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        style={{ left: '-20px' }}
      >
        <CartesianGrid strokeDasharray="2 2" stroke="#596989" />
        <XAxis dataKey="date" tickFormatter={getFormatDate} stroke="#8BA1B6" strokeWidth={2}>
          <Label
            value="Low PPI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Normal PPI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;High PPI"
            angle={-90}
            position={{ x: 415, y: -210 }}
            className={'label_text_composed'}
          />
        </XAxis>
        {isDDSet ? (
          <YAxis domain={[0, barMax]} stroke="#8BA1B6" strokeWidth={2}>
            <Label value="NFs sent" angle={-90} position={{ x: 22, y: 70 }} className={'label_text_composed'} />
          </YAxis>
        ) : (
          <YAxis tickFormatter={getBarMax} stroke="#8BA1B6" strokeWidth={2}>
            <Label value="NFs sent" angle={-90} position={{ x: 22, y: 70 }} className={'label_text_composed'} />
          </YAxis>
        )}
        <Tooltip content={<CustomTooltip />} />
        {!loading && barChartLabels
          ? isDDSet
            ? barChartLabels.map(item => {
                return <Bar key={item.title} dataKey={item.title} stackId={isAllCompanies ? 'all' : item.title} fill={item.color} />;
              })
            : barChartLabels.map(item => {
                return (
                  <Bar
                    key={item.title}
                    dataKey={item.title}
                    stackId={isAllCompanies ? 'all' : item.title}
                    fill={item.color}
                    fillOpacity={0}
                  />
                );
              })
          : null}
        {!loading && isDDSet ? (
          <Scatter dataKey="dd" fill="#BB2525" shape={<DesignDocumentSetShape />}>
            <LabelList dataKey="set" position="top" fill="#BB2525" />
          </Scatter>
        ) : null}
        {!loading && barChartData && isDDSet
          ? barChartData
              .filter(item => item.dd)
              .map(item => {
                const refX = item.date;
                return <ReferenceLine key={item.set} isFront x={refX} stroke="#BB2525" strokeDasharray="2 2" />;
              })
          : null}
        {!loading && isDDSet ? <Line connectNulls type="monotone" dataKey="ppi_log_value" stroke="#66C17F" strokeWidth={2} /> : null}
        <Brush dataKey="date" height={24} stroke="#8BA1B6" fill="var(--background-main)" tickFormatter={getFormatDate} fontSize={12} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default React.memo(ReportingComposedChart);
