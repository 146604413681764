import React, { RefObject, useRef } from 'react';
import { useAppSelector } from '../../../../../../../store/configure/configureStore';
import SVG from 'react-inlinesvg';
import { KeyValueModel } from '../../../../../../../models/key-value.model';
import { SandBoxAccordionData } from '../../../../../../../store/request/requestReducer.model';
import Snippet from '../../../../../../controls/Snippet/Snippet';
import ContainedList from '../../../../../../controls/ContainedList/ContainedList';
import { AllSectionType } from '../../../../SandBoxConstants/SandBoxConstants';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import cn from 'classnames';

import './SandBoxHotlistTableStyles.scss';

interface IProps {
  sandBoxData: SandBoxAccordionData[];
  openRequest: (id: number, nf: number, accordionAlias: string, isMarked: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  openSubmittal: (id: string, accordionAlias?: string, isMarked?: boolean) => void;
  accordionStates: KeyValueModel<boolean> | null;
  accordionRefs: KeyValueModel<RefObject<HTMLElement>>;
  handleAccordion: (alias: string) => void;
  sandBoxHotlistSectionType: AllSectionType;
  isMobile?: boolean;
  sectionTitle?: string;
  isAllHeight?: boolean;
}

const SandBoxHotlistTable = ({
  sandBoxData,
  openRequest,
  accordionStates,
  accordionRefs,
  handleAccordion,
  openDeliverable,
  openSubmittal,
  sandBoxHotlistSectionType,
  isMobile,
  sectionTitle,
  isAllHeight,
}: IProps) => {
  const theme = useAppSelector(state => state.userReducer.userInfo.theme);
  const sandBoxHotlistDataLoading = useAppSelector(state => state.requestReducer.sandBoxHotlistDataLoading);

  const themeClass = useGetThemeClass('b-sandBoxHotlistTable');
  const tableRef = useRef();

  return (
    <div
      ref={tableRef}
      className={cn(`${themeClass}__table`, {
        [`-isLoading`]: sandBoxHotlistDataLoading?.loading,
        ['-isAllHeight']: isAllHeight,
        ['-isSkeletons']: sandBoxData?.length && sandBoxHotlistDataLoading?.loading,
      })}
    >
      <div className={`${themeClass}__table_table_item`}>
        <ContainedList<SandBoxAccordionData>
          data={sandBoxData}
          accordionStates={accordionStates}
          accordionRefs={accordionRefs}
          handleAccordion={handleAccordion}
          isLoading={sandBoxHotlistDataLoading?.loading}
          tableRef={tableRef}
          isMobile={isMobile}
          maxCountSkeleton={15}
          renderItem={(item, alias) => {
            return (
              <Snippet
                accordionAlias={alias}
                key={item.id}
                openRequest={snippetData => openRequest(snippetData.id, snippetData.nf, alias, !!snippetData.marker)}
                openDeliverable={snippetData => openDeliverable(snippetData.id, snippetData.building_id, alias, !!snippetData.marker)}
                openSubmittal={snippetData => openSubmittal(snippetData.id, alias, !!snippetData.marker)}
                snippetData={item}
                sandBoxHotlistSectionType={sandBoxHotlistSectionType}
              />
            );
          }}
        />
      </div>
      {sandBoxData && sandBoxHotlistDataLoading.loaded && !sandBoxData?.length ? (
        <div className={`${themeClass}__table_empty`}>
          <div className={`${themeClass}__table_empty_container`}>
            <div className={`${themeClass}__table_empty_placeholderImage`}>
              {theme === 'dark' ? (
                <SVG src={require('../../../../../../../assets/images/sandBox/empty-state-widget-hotlist-dark.svg')} />
              ) : (
                <SVG src={require('../../../../../../../assets/images/sandBox/empty-state-widget-hotlist-light.svg')} />
              )}
            </div>
            <div className={`${themeClass}__table_empty_textContainer`}>
              <div className={`${themeClass}__table_empty_textContainer_text`}>
                <div className={`${themeClass}__table_empty_textContainer_text_paragraph`}>
                  <div className={`${themeClass}__table_empty_textContainer_text_paragraph_title`}>Well done!</div>
                  <div className={`${themeClass}__table_empty_textContainer_text_paragraph_title`}>You have no {sectionTitle} in</div>
                  <div className={`${themeClass}__table_empty_textContainer_text_paragraph_title`}>your hotlist.</div>
                </div>
                <div className={`${themeClass}__table_empty_textContainer_text_paragraph_title`}>Keep up the excellent work!</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SandBoxHotlistTable;
