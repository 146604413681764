import { IReportingReducer } from './reportingReducer.model';
import Api from '../../service/api';
import { IUserReporting, ReportingDataModel } from '../../models/reporting/reporting.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReportingHeader, getReportingTable, getReportingForCompany, getReportingForUser } from './reportingThunk';

const initialState: IReportingReducer = {
  getReportingStatus: Api.initialStatus,
  reportingData: {} as ReportingDataModel,
  reportingDataTabel: {},
  loading: false,
  loadingTabel: false,
  isFirstTableLoading: true,
  isFirstLoading: true,
  reportingDataUser: {} as IUserReporting,
};

export const reportingSlice = createSlice({
  initialState,
  name: 'reportingReducer',
  reducers: {
    resetReportingFirstLoading(state) {
      state.isFirstTableLoading = true;
      state.isFirstLoading = true;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getReportingHeader
       */
      .addCase(getReportingHeader.pending, state => {
        state.getReportingStatus = Api.requestStatus;
        state.loading = true;
      })
      .addCase(getReportingHeader.fulfilled, (state, { payload }: PayloadAction<ReportingDataModel>) => {
        state.getReportingStatus = Api.successStatus;
        state.reportingData = payload;
        state.loading = false;
        state.isFirstLoading = false;
      })
      .addCase(getReportingHeader.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.getReportingStatus = Api.failStatus;
        state.loading = false;
        state.isFirstLoading = false;
      })
      /**
       * getReportingTable
       */
      .addCase(getReportingTable.pending, state => {
        state.getReportingStatus = Api.requestStatus;
        state.loadingTabel = true;
      })
      .addCase(getReportingTable.fulfilled, (state, { payload }: PayloadAction<ReportingDataModel>) => {
        state.getReportingStatus = Api.successStatus;
        state.reportingDataTabel = payload;
        state.loadingTabel = false;
        state.isFirstTableLoading = false;
      })
      .addCase(getReportingTable.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.getReportingStatus = Api.failStatus;
        state.loadingTabel = false;
        state.isFirstTableLoading = false;
      })
      /**
       * changePCDCardStatus
       */
      .addCase(getReportingForCompany.pending, state => {
        state.getReportingStatus = Api.requestStatus;
        state.loading = true;
      })
      .addCase(getReportingForCompany.fulfilled, (state, { payload }: PayloadAction<ReportingDataModel>) => {
        state.getReportingStatus = Api.successStatus;
        state.reportingData = payload;
        state.loading = false;
      })
      .addCase(getReportingForCompany.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.getReportingStatus = Api.failStatus;
        state.loading = false;
      })
      /**
       * changePCDCardStatus
       */
      .addCase(getReportingForUser.pending, state => {
        state.getReportingStatus = Api.requestStatus;
        state.loading = true;
      })
      .addCase(getReportingForUser.fulfilled, (state, { payload }: PayloadAction<ReportingDataModel>) => {
        state.getReportingStatus = Api.successStatus;
        state.reportingDataUser = payload;
        state.loading = false;
      })
      .addCase(getReportingForUser.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.getReportingStatus = Api.failStatus;
        state.loading = false;
      });
  },
});

export default reportingSlice.reducer;
