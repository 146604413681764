import React, { ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import MiniLoader from '../MiniLoader/MiniLoader';
import CustomButton from '../ButtonComponents/CustomButton/CustomButton';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import ReactHtmlParser from 'react-html-parser/src';

import './DialogPopUp.module.scss';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';

interface IModalProps extends DialogProps {
  modalText?: string;
  renderModalContent?: () => ReactElement;
  isTitleTag?: boolean;
  isHideRemoveIcon?: boolean;
  primaryText?: string;
  secondaryText?: string;
  tertiaryText?: string;
  handleOnPrimary?: (e: Event) => void;
  handleOnSecondary?: (e: Event) => void;
  handleOnTertiary?: (e: Event) => void;
  loadingOnPrimary?: boolean;
  customPrimaryType?: string;
  customSecondaryType?: string;
  customTertiaryType?: string;
  loadingOnSecondary?: boolean;
  loadingOnTertiary?: boolean;
  isLoading?: boolean;
  isOverlay?: boolean;
  paperMaxWidth?: string;
  customClassname?: string;
  customRootClassname?: string;
  dividedHeader?: boolean;
  customContainerClassName?: string;
  primaryTextDisabled?: boolean;
  secondaryTextDisabled?: boolean;
  isCard?: boolean;
  isHighResolution?: boolean;
  isMobilePage?: boolean;
  isHighResolution?: boolean;
}

const DialogPopUp = ({
  open,
  onClose,
  title,
  isTitleTag,
  isOverlay = true,
  isHideRemoveIcon,
  modalText,
  renderModalContent,
  primaryText,
  secondaryText,
  tertiaryText,
  handleOnPrimary,
  handleOnSecondary,
  handleOnTertiary,
  loadingOnPrimary,
  customPrimaryType,
  loadingOnSecondary,
  loadingOnTertiary,
  isLoading,
  children,
  paperMaxWidth,
  customClassname,
  customRootClassname,
  dividedHeader,
  customTertiaryType,
  customSecondaryType,
  customContainerClassName,
  primaryTextDisabled = false,
  secondaryTextDisabled = false,
  isCard,
  isHighResolution,
  isMobilePage,
  isHighResolution,
  ...props
}: IModalProps) => {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('dialogPopUp');

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      maxWidth={false}
      disableEnforceFocus
      PaperProps={{
        style: {
          maxWidth: paperMaxWidth,
        },
      }}
      className={cn(`${themeClass}`, { ['-overlay']: isOverlay, [`${customRootClassname}`]: customRootClassname })}
      classes={{
        root: `${themeClass}_root`,
        paper: cn(`${themeClass}_paper`, {
          ['-maxWidth']: (children || renderModalContent) && paperMaxWidth,
          ['-mobile']: isMobile,
          ['highResolution']: isHighResolution,
          ['-mobilePage']: isMobilePage,
          ['highResolution']: isHighResolution,
          ['-cardMobile']: isCard && isMobile,
          [`${customClassname}`]: customClassname,
        }),
        container: customContainerClassName || '',
      }}
      BackdropProps={{
        invisible: true,
        hideBackdrop: true,
      }}
      {...(props || {})}
    >
      {children ? (
        children
      ) : (
        <>
          {title && (
            <div className={cn(`${themeClass}_dialogTitle`, { ['-divided']: dividedHeader, ['-mobile']: isMobile })}>
              {isTitleTag ? <div className={`${themeClass}_isTitleTag`}>{ReactHtmlParser(title)}</div> : <div>{title}</div>}
              <div className={cn(`${themeClass}_closeIcon`, { ['hide']: !!isHideRemoveIcon })} onClick={onClose}>
                <SystemButton type={'close'} size={'lg'} variant={'transparent'} isMobile={isMobile} />
              </div>
            </div>
          )}
          <div className={cn(`${themeClass}_content`, { ['-divided']: dividedHeader, ['-mobile']: isMobile, ['-no_title']: !title })}>
            {isLoading ? (
              <div className={'downloadBoxLoading'}>
                <MiniLoader size={'sm'} circleClasses={`${themeClass}_loader_circle`} />
              </div>
            ) : (
              <>
                {renderModalContent ? <>{renderModalContent()}</> : <span className={`${themeClass}_content_modalText`}>{modalText}</span>}
              </>
            )}
          </div>
          <div className={cn(`${themeClass}_buttonsSection`, { ['-divided']: dividedHeader, ['-mobile']: isMobile })}>
            {tertiaryText && handleOnTertiary && (
              <CustomButton
                loading={loadingOnSecondary}
                size={'md'}
                type={customSecondaryType ? customSecondaryType : 'text-plain'}
                clickHandler={handleOnTertiary}
                title={tertiaryText}
                isMobile={isMobile}
              />
            )}
            {secondaryText && handleOnSecondary && (
              <CustomButton
                loading={loadingOnTertiary}
                size={'md'}
                type={customTertiaryType ? customTertiaryType : 'tertiary'}
                clickHandler={handleOnSecondary}
                title={secondaryText}
                isMobile={isMobile}
                disabled={secondaryTextDisabled}
              />
            )}
            {primaryText && handleOnPrimary && (
              <CustomButton
                loading={loadingOnPrimary}
                size={'md'}
                type={customPrimaryType ? customPrimaryType : 'primary'}
                clickHandler={handleOnPrimary}
                title={primaryText}
                isMobile={isMobile}
                disabled={primaryTextDisabled}
              />
            )}
          </div>
        </>
      )}
    </Dialog>
  );
};

export default DialogPopUp;
