import { BaseApi } from './baseApi';
import { createIncorporation } from '../../store/incorporation/incorporationThunk';

class RequestApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getRequest(getParams) {
    getParams = {
      ...getParams,
      ipp: 50,
      //ipp: 10,
    };
    const result = await this._get({ route: 'requests', getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getSingleRequest(requestId) {
    const result = await this._get({ route: `request/${requestId}`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getRequestByNf(project_id, nf) {
    const result = await this._get({ route: `request/project/${project_id}/nf/${nf}`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getAllRequest({ projectId, orderFields, order, page }) {
    const allRequests = {};
    const getParams = {};

    if (!isNaN(+projectId) && projectId > 0) {
      getParams.project_id = projectId;
      if (orderFields === 'created_at' || orderFields === 'system') {
        getParams.order_by = orderFields;
        if (order === 'asc' || order === 'desc') {
          getParams.order_dir = order;
        } else {
          getParams.order_dir = 'asc';
        }
      }
      if (!isNaN(+page) && page > 0) {
        getParams.page = page;
      }
      let requestListPerPage = await this.getRequest(getParams);
      if (requestListPerPage.status === false) {
        return {
          status: requestListPerPage.status,
          message: requestListPerPage.message,
        };
      }
      const { per_page, total, data } = requestListPerPage.data;
      data.forEach(item => (allRequests[item.id] = item));
      const iterationCount = Math.ceil(total / per_page);
      if (!getParams.page && iterationCount > 1) {
        for (let currentRequestPage = 2; currentRequestPage <= iterationCount; ++currentRequestPage) {
          getParams.page = currentRequestPage;
          requestListPerPage = await this.getRequest(getParams);
          if (requestListPerPage.status === false) {
            return {
              status: requestListPerPage.status,
              message: requestListPerPage.message,
            };
          }
          const { data } = requestListPerPage.data;
          data.forEach(item => (requestListPerPage[item.id] = item));
        }
      }
      return {
        data: allRequests,
        status: requestListPerPage.status,
        message: requestListPerPage.message,
        projectId: getParams.project_id || null,
      };
    }

    return {
      data: {},
      status: false,
      message: 'No project id',
      projectId: null,
    };
  }

  async createRequest(bodyParams, files, isNotFormData) {
    const result = await this._post({
      route: 'request/create',
      bodyParams,
      needAuth: true,
      formData: isNotFormData ? false : true,
      devMode: this.devMode,
      files: files,
    });
    return result;
  }

  async responseSave(requestId, bodyParams, files) {
    const result = await this._post({
      route: `request/${requestId}/response`,
      bodyParams,
      needAuth: true,
      formData: true,
      devMode: this.devMode,
      files: files,
    });
    return result;
  }

  async updateStatus(requestId, bodyParams) {
    const result = await this._post({
      route: `request/${requestId}/update-status`,
      bodyParams,
      needAuth: true,
      formData: true,
      devMode: this.devMode,
    });
    return result;
  }

  async deleteRequest(requestId) {
    return await this._post({ route: `request/${requestId}/delete`, needAuth: true, devMode: this.devMode });
  }

  async updateRequest(bodyParams, selectRequestId, files, isNotFormData) {
    if (!selectRequestId) {
      return false;
    }
    const result = await this._post({
      route: `request/${selectRequestId}/update`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
      formData: isNotFormData ? false : true,
      files: files,
    });
    return result;
  }

  async updateRequestPrivate(bodyParams, requestId) {
    const result = await this._post({
      route: `request/${requestId}/set-private`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
      formData: true,
    });
    return result;
  }

  async setHotList(entity, bodyParams) {
    const result = await this._post({
      route: `sandbox-set-hotlist-${entity}`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
      formData: true,
    });
    return result;
  }

  async getAvailableBulkEditFields(bodyParams, projectId) {
    if (!projectId || !bodyParams) {
      return false;
    }
    const result = await this._post({
      route: `request/${projectId}/get-available-fields`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async bulkEdit(bodyParams, projectId) {
    if (!projectId || !bodyParams) {
      return false;
    }
    const result = await this._post({
      route: `request/${projectId}/bulk-edit`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getNeedList(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };

    const result = await this._post({ route: 'needlist', bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getNeedListColumnOrder() {
    const result = await this._get({ route: 'need-list/get-column-settings', needAuth: true, devMode: this.devMode });
    return result;
  }

  async setNeedListColumnOrder(bodyParams) {
    bodyParams = {
      ...bodyParams,
    };
    const result = await this._post({
      route: 'need-list/set-column-settings',
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  // async getNeedListFilters(getParams) {
  //   getParams = {
  //     ...getParams,
  //   };
  //   const result = await this._get({route: 'needlist-filters', getParams: getParams, needAuth: true, devMode: this.devMode});
  //   return result;
  // }

  async getTags(getParams) {
    getParams = {
      ...getParams,
    };
    const result = await this._get({ route: 'tags', getParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getSandBox(getParams) {
    const result = await this._post({ route: `sandbox`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getSandBoxWidgetsHotlistData(getParams) {
    const result = await this._post({ route: `sandbox-widgets-hotlist`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async checkIfSnippetInSandBoxSection(getParams) {
    const result = await this._post({ route: `sandbox-check`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getSandBoxCounters(getParams) {
    getParams = {
      ...getParams,
    };

    const result = await this._post({ route: `sandbox-counter`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    // const result = await this._post({route: 'request/create', bodyParams, needAuth: true, devMode: this.devMode});
    return result;
  }

  async getSandBoxhotListCounters(getParams) {
    getParams = {
      ...getParams,
    };
    const result = await this._post({ route: `sandbox-counter-hotList`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getSandBoxhotListCountersAll(getParams) {
    getParams = {
      ...getParams,
    };
    const result = await this._post({ route: `sandbox-all-counter-hotList`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  // async getSandBoxOuterCounter(getParams) {
  //   getParams = {
  //     ...getParams,
  //   };
  //
  //   const result = await this._post({ route: `sandbox-counter-outer `, bodyParams: getParams, needAuth: true, devMode: this.devMode });
  //   // const result = await this._post({route: 'request/create', bodyParams, needAuth: true, devMode: this.devMode});
  //   return result;
  // }

  async getSandBoxWidgets(getParams) {
    return await this._post({ route: `sandbox-widgets`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
  }

  async postponeSandBoxAlert(getParams) {
    return await this._post({ route: `sandbox-widgets/alerts/create`, bodyParams: getParams, needAuth: true, devMode: this.devMode });
  }

  async deleteFromPostponedSandBoxAlerts(sandbox_alert_id) {
    return await this._post({ route: `sandbox-widgets/alerts/${sandbox_alert_id}/delete`, needAuth: true, devMode: this.devMode });
  }

  async setSeenRequest(getParams) {
    getParams = {
      ...getParams,
      ipp: 40,
    };
    // if (getParams.filter_by === 'watch_list') {
    //
    // }
    const result = await this._post({
      route: `notification/set-seen-sandbox`,
      bodyParams: getParams,
      needAuth: true,
      devMode: this.devMode,
    });
    // const result = await this._post({route: 'request/create', bodyParams, needAuth: true, devMode: this.devMode});
    return result;
  }

  async getWhiteBoard(getParams) {
    getParams = {
      ...getParams,
      // ipp: 300,
    };
    const result = await this._get({
      route: 'whiteboardRequests',
      getParams: getParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getWhiteBoardFilters(getParams) {
    getParams = {
      ...getParams,
    };
    const result = await this._get({
      route: 'whiteboard-filters',
      getParams: getParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getWatchers(requestId) {
    return await this._get({ route: `request/${requestId}/watcher-list`, needAuth: true, devMode: this.devMode });
  }

  async getWatchersGroups(projectId) {
    return await this._get({ route: `watcher/groups/${projectId}`, needAuth: true, devMode: this.devMode });
  }

  async watchersGroupsCreate(bodyParams) {
    return this._post({ route: `watcher/group/create`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async watchersGroupsRemove(bodyParams, id) {
    return this._delete({ route: `watcher/group/${id}/delete`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async watchersGroupsUpdate(bodyParams, id) {
    return this._update({ route: `watcher/group/${id}/update`, bodyParams: bodyParams, needAuth: true, devMode: this.devMode });
  }

  async watchersGroupsAddToWatchers(type, bodyParams) {
    return this._post({
      route: `watcher/group/${type}/sync-to-watchers`,
      bodyParams: bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async getUserWatchers(data) {
    return await this._get({ route: `user/watch-list`, getParams: data, needAuth: true, devMode: this.devMode });
  }

  async searchWatchers(bodyParams) {
    const result = await this._post({
      route: `watcher/search-in-project`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async createNewDueDate(bodyParams) {
    const result = await this._post({
      route: `duedate-change-request/create`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setSyncProcore(id, bodyParams) {
    const result = await this._post({
      route: `request/${id}/set-sync-procore`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async createNewResponseCompany(bodyParams) {
    const result = await this._post({
      route: `response-company-change-request/create`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updateNewDueDate(bodyParams) {
    const result = await this._post({
      route: `duedate-change-request/${bodyParams.id}/update`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updateResponseCompany(bodyParams) {
    const result = await this._post({
      route: `response-company-change-request/${bodyParams.id}/update`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async sendBugReport(bodyParams) {
    const result = await this._post({
      route: `send-bug-report`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async searchUsers(bodyParams) {
    const result = await this._post({
      route: `user/search-in-project`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getComments(type, bodyParams) {
    const result = await this._post({
      route: `${type || 'request'}/get-comments`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async getFeed(type, bodyParams) {
    const result = await this._post({
      route: `${type || 'request'}/feed`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getBallInCourtList(projectId) {
    const result = await this._get({
      route: `ball-in-court/${projectId}/get-list`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async createComments(type, bodyParams) {
    const result = await this._post({
      route: `${type || 'request'}/create-comment`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async addBallInCourt(bodyParams) {
    const result = await this._post({
      route: `request/ball-in-court-add`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updateComments(type, bodyParams) {
    const result = await this._update({
      route: `${type || 'request'}/update-comment`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async unAssigmentUser(type, bodyParams) {
    const result = await this._post({
      route: `${type || 'request'}/deactivate-court`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async editBallInCourt(bodyParams) {
    const result = await this._update({
      route: `request/ball-in-court-edit`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async deleteComments(type, bodyParams) {
    const result = await this._delete({
      route: `${type || 'request'}/delete-comment`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async linkNfPredecessor(bodyParams) {
    const result = await this._post({
      route: `link_predecessor`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async unlinkNfPredecessor(bodyParams) {
    const result = await this._post({
      route: `unlink_predecessor`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async linkNfSuccessor(bodyParams) {
    const result = await this._post({
      route: `link_successor`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async unlinkNfSuccessor(bodyParams) {
    const result = await this._post({
      route: `unlink_successor`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async getNfLink(id, relation_type) {
    const result = await this._get({
      route: `requests-for-link/${id}?relation_type=${relation_type}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async getHistory(type, bodyParams) {
    const result = await this._post({
      route: `${type || 'request'}/history`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }
  async getIncorporation(id) {
    const result = await this._get({
      route: `incorporation/${id}/get-incorporation`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async setGlobalIncorporation(id, bodyParams) {
    const result = await this._post({
      route: `incorporation/${id}/global-incorporation`,
      needAuth: true,
      bodyParams,
      devMode: this.devMode,
    });
    return result;
  }
  async createIncorporation(id, bodyParams) {
    const result = await this._post({
      route: `incorporation/${id}/create-incorporation`,
      needAuth: true,
      bodyParams,
      devMode: this.devMode,
    });
    return result;
  }
  async updateTasks(id, bodyParams) {
    const result = await this._put({
      route: `incorporation/${id}/update-tasks`,
      needAuth: true,
      bodyParams,
      devMode: this.devMode,
    });
    return result;
  }
  async updateSubTaskStatus(id, bodyParams) {
    const result = await this._put({
      route: `incorporation/${id}/update-subtask-status`,
      needAuth: true,
      bodyParams,
      devMode: this.devMode,
    });
    return result;
  }
  async setIncorporation(bodyParams) {
    const result = await this._post({
      route: `incorporation`,
      needAuth: true,
      bodyParams,
      devMode: this.devMode,
    });
    return result;
  }
  async requestView({ id }) {
    const result = await this._get({
      route: `request/${id}/view`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getQuickTour() {
    const result = await this._get({
      route: `quick-tour`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getWhatNews() {
    const result = await this._get({
      route: `whats-new`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setReadNewsStatus(id) {
    const result = await this._post({
      route: `whats-new/set-seen/${id}`,
      needAuth: true,
      bodyParams: {},
      devMode: this.devMode,
    });
    return result;
  }

  async setReadAllNewsStatus() {
    const result = await this._post({
      route: `whats-new/seen-all`,
      needAuth: true,
      bodyParams: {},
      devMode: this.devMode,
    });
    return result;
  }

  async setSeenGettingStartedStatus(id) {
    const result = await this._post({
      route: `tutorials/hide`,
      needAuth: true,
      bodyParams: {},
      devMode: this.devMode,
    });
    return result;
  }
  async setIncorporated(bodyParams) {
    const result = await this._post({
      route: `request/set-incorporated`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }
  async getFilteredRequest(url, bodyParams) {
    const result = await this._post({
      route: `request/${url ? url : 'needlist'}/switcher/${bodyParams.id}`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }
  async getNeedListAllRequestIds(bodyParams) {
    const result = await this._post({
      route: `needlist/all-ids`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }
  async getSuccPredFromRequest(id) {
    const result = await this._get({
      route: `request/${id}/successors-predecessors`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
  async deactivatedStatus(bodyParams) {
    const result = await this._post({
      route: `request/${bodyParams.request_id}/${bodyParams.action}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async editHashtag(id, bodyParams) {
    const result = await this._post({
      route: `hashtags/${id}/edit`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async deleteHashtag(id) {
    const result = await this._post({
      route: `hashtags/${id}/delete`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async declineCounter(bodyParams) {
    const result = await this._post({
      route: `request/needlist/switcher/${bodyParams.id}`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getMainMf(bodyParams) {
    const result = await this._post({
      route: `${bodyParams.isPCDFilters ? 'pcd' : 'needlist'}/filters-mf-main`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async changeNfStatusToDelivarable({ owner_id, data }) {
    const result = await this._post({
      route: `pcd/set-status-deliverable`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: { owner_id, data },
    });
    return result;
  }

  async getMfFromParent(bodyParams) {
    const result = await this._post({
      route: `${bodyParams.isPCDFilters ? 'pcd' : 'needlist'}/filters-mf-parent`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getMainUf(bodyParams) {
    const result = await this._post({
      route: `${bodyParams.isPCDFilters ? 'pcd' : 'needlist'}/filters-uf-main`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getUfFromParent(bodyParams) {
    const result = await this._post({
      route: `${bodyParams.isPCDFilters ? 'pcd' : 'needlist'}/filters-uf-parent`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async searchRequest(bodyParams) {
    const result = await this._post({
      route: `search-request-party`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getSearchRequestFilters() {
    const result = await this._get({
      route: `get-list-search-item`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async searchMForUFCode(bodyParams) {
    console.log('bodyParams searchMForUFCode', bodyParams);
    const result = await this._post({
      route: `${bodyParams.isPCDFilters ? 'pcd' : 'needlist'}/filters-mf-uf-search`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getSandBoxIDsHotList(bodyParams) {
    const result = await this._post({
      route: `sandbox-id-items-hotList`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }

  async getSandBoxCardIDHotList(bodyParams) {
    const result = await this._post({
      route: `sandbox-id-card-hotList`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
    return result;
  }
}

export default new RequestApi({ devMode: false });
