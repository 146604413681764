import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { LinkedItemsShowType, LinkedType } from '../../RelatedItemsTabConstants';
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';
import { ISubmittal } from '../../../../../models/submittals/submittals.model';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;

// currentCard === 'Nf' and relatedTabType === 'request'
const requestType = { title: 'Group by request type', value: 'requestType' };
const requestDiscipline = { title: 'Group by request discipline', value: 'requestDiscipline' };
const nfStatus = { title: 'Group by NF status', value: 'nfStatus' };
const BicCompany = { title: 'Group by BIC company', value: 'BicCompany' };
// currentCard === 'Nf' and relatedTabType === 'deliverable'
const drawingDiscipline = { title: 'Group by drawing discipline', value: 'drawingDiscipline' };
const drawingType = { title: 'Group by drawing type', value: 'drawingType' };
const deliverableLocation = { title: 'Group by deliverable location', value: 'deliverableLocation' };
// currentCard === 'Nf' and relatedTabType === 'document'
const disciplineDD = { title: 'Group by discipline', value: 'disciplineDD' };
const latestDdSet = { title: 'Group by latest DD set', value: 'latestDdSet' };
const buildingDD = { title: 'Group by building', value: 'buildingDD' };
// currentCard === 'Nf' and relatedTabType === 'submittal'
const specificationSection = { title: 'Group by specification section', value: 'specificationSection' };
const submittalType = { title: 'Group by submittal type', value: 'submittalType' };
const responsibleManagerCompany = { title: 'Group by responsible manager company', value: 'responsibleManagerCompany' };
const responsibleContractorCompany = { title: 'Group by responsible contractor company', value: 'responsibleContractorCompany' };
const submittalStatus = { title: 'Group by submittal status', value: 'submittalStatus' };

// currentCard === 'Deliverable' and relatedTabType === 'request'
const responseCompany = { title: 'Group by response company', value: 'responseCompany' };
const revisionNfWasLinkedTo = { title: 'Group by deliverable revision NF was linked to', value: 'revisionNfWasLinkedTo' };

// Common
const validityStatus = { title: 'Group by validity status', value: 'validityStatus' };

export const getGroupingConstants = (data: {
  currentCard: 'Nf' | 'Deliverable' | 'Submittal';
  relatedTabType: RelatedItemsTabsType;
  part: LinkedType;
  selectedShowType: LinkedItemsShowType;
  selectedRequest: FullRequestModel | ISubmittal | PCDCard;
}) => {
  const { currentCard, relatedTabType, part, selectedShowType, selectedRequest } = data;

  if (currentCard === 'Nf') {
    if (relatedTabType === 'request') {
      if (part === 'predecessor') {
        if (selectedShowType === 'outstanding') {
          return [requestType, requestDiscipline, nfStatus, BicCompany];
        }
        if (selectedShowType === 'accepted') {
          return [requestType, requestDiscipline];
        }
        if (selectedShowType === 'deactivated') {
          return [requestType, requestDiscipline];
        }
      }
      if (part === 'successor') {
        if (selectedShowType === 'outstanding') {
          return [requestType, requestDiscipline, nfStatus, BicCompany];
        }
        if (selectedShowType === 'accepted') {
          return [requestType, requestDiscipline];
        }
        if (selectedShowType === 'deactivated') {
          return [requestType, requestDiscipline];
        }
      }
    }
    if (relatedTabType === 'deliverable') {
      return [drawingDiscipline, drawingType, deliverableLocation, validityStatus];
    }
    if (relatedTabType === 'document') {
      return [disciplineDD, latestDdSet, buildingDD, validityStatus];
    }
    if (relatedTabType === 'submittalInput' || relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleContractorCompany,
          submittalStatus,
          validityStatus,
        ];
      }
      if (part === 'successor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleContractorCompany,
          submittalStatus,
          validityStatus,
        ];
      }
    }
  }

  if (currentCard === 'Deliverable') {
    if (relatedTabType === 'request') {
      if (selectedShowType === 'outstanding' || selectedShowType === 'open' || selectedShowType === 'all') {
        return [BicCompany, revisionNfWasLinkedTo, requestType, requestDiscipline, nfStatus, validityStatus];
      }
      if (selectedShowType === 'accepted' || selectedShowType === 'incorporated in this level/typicality') {
        return [BicCompany, revisionNfWasLinkedTo, requestType, requestDiscipline, validityStatus];
      }
      if (selectedShowType === 'accepted, not incorporated in this level/typicality') {
        return [];
      }
    }
    if (relatedTabType === 'deliverable') {
      if (part === 'predecessor') {
        return [validityStatus];
      }
      if (part === 'successor') {
        return [];
      }
    }
    if (relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        return [
          specificationSection,
          submittalType,
          responsibleManagerCompany,
          responsibleContractorCompany,
          submittalStatus,
          validityStatus,
        ];
      }
      if (part === 'successor') {
        return [specificationSection, submittalType, responsibleManagerCompany, responsibleContractorCompany, submittalStatus];
      }
    }
  }

  if (currentCard === 'Submittal') {
    if (relatedTabType === 'request') {
      return [requestType, requestDiscipline, nfStatus, validityStatus, BicCompany];
    }
    if (relatedTabType === 'deliverable') {
      if (part === 'predecessor') {
        return [drawingDiscipline, drawingType, deliverableLocation, validityStatus];
      }
      if (part === 'successor') {
        return [drawingDiscipline, drawingType, deliverableLocation];
      }
    }
    if (relatedTabType === 'document') {
      return [disciplineDD, latestDdSet, buildingDD, validityStatus];
    }
    if (relatedTabType === 'submittalInput') {
      if (part === 'predecessor') {
        return [specificationSection, submittalType, responsibleManagerCompany, responsibleContractorCompany, submittalStatus];
      }
      if (part === 'successor') {
        return [specificationSection, submittalType, responsibleManagerCompany, responsibleContractorCompany, submittalStatus];
      }
    }
    if (relatedTabType === 'submittalOutput') {
      if (part === 'predecessor') {
        if (selectedRequest?.is_output) {
          return [
            specificationSection,
            submittalType,
            responsibleManagerCompany,
            responsibleContractorCompany,
            submittalStatus,
            validityStatus,
          ];
        }
        return [specificationSection, submittalType, responsibleManagerCompany, responsibleContractorCompany, submittalStatus];
      }
      if (part === 'successor') {
        return [specificationSection, submittalType, responsibleManagerCompany, responsibleContractorCompany, submittalStatus];
      }
    }
  }
};

export const getGroupingSnippetValue = (snippet, key: string): string | null => {
  switch (key) {
    //DD
    case 'disciplineDD':
      if (snippet?.discipline && snippet?.discipline?.title) {
        return snippet?.discipline.title;
      } else {
        return `Not specified`;
      }
    case 'latestDdSet':
      if (snippet?.current_set) {
        return snippet?.current_set;
      } else {
        return `Not specified`;
      }
    // case 'buildingDD':
    //   if (snippet.building[0].title) {
    //     return snippet.building[0].title;
    //   } else {
    //     return `Not specified`;
    //   }

    //NF
    case 'requestType':
      if (snippet?.tags?.length && snippet?.tags[0] && snippet?.tags[0]?.title) {
        return snippet?.tags[0]?.title;
      } else {
        return `Not specified`;
      }
    case 'requestDiscipline':
      if (snippet?.request_discipline && snippet?.request_discipline.title) {
        return snippet?.request_discipline.title;
      } else {
        return `Not specified`;
      }
    case 'nfStatus':
      if (snippet?.status) {
        return snippet.status;
      } else {
        return `Not specified`;
      }
    case 'BicCompany':
      if (snippet?.court?.title) {
        return snippet?.court?.title;
      } else {
        return `Not specified`;
      }
    case 'responseCompany':
      if (snippet?.response_party?.company) {
        return snippet?.response_party?.company;
      } else {
        return `Not specified`;
      }
    case 'revisionNfWasLinkedTo':
      if (typeof snippet?.flags?.revision === 'number') {
        return `R${snippet?.flags?.revision}:`;
      } else {
        return `Not specified`;
      }

    //Submittal
    case 'specificationSection':
      if (snippet?.mf_code) {
        return snippet?.mf_code;
      } else {
        return `Not specified`;
      }
    case 'submittalType':
      if (snippet?.type) {
        return snippet?.type;
      } else {
        return `Not specified`;
      }
    case 'responsibleManagerCompany':
      if (snippet?.responsible_party?.company) {
        return snippet?.responsible_party?.company;
      } else {
        return `Not specified`;
      }
    case 'responsibleContractorCompany':
      if (snippet?.contractor_party?.company) {
        return snippet?.contractor_party?.company;
      } else {
        return `Not specified`;
      }
    case 'submittalStatus':
      if (snippet?.status?.title) {
        return snippet?.status?.title;
      } else {
        return `Not specified`;
      }

    //Deliverable
    case 'drawingDiscipline':
      if (snippet?.discipline?.title) {
        return snippet?.discipline?.title;
      } else {
        return `Not specified`;
      }
    case 'drawingType':
      if (snippet?.type?.title) {
        return snippet?.type?.title;
      } else {
        return `Not specified`;
      }
    case 'deliverableLocation':
      if (snippet?.owner?.title) {
        return `${snippet?.building?.title} %sort% ${snippet?.owner?.title} (${snippet?.building?.title})`;
      } else {
        return `Not specified`;
      }

    //Common
    case 'validityStatus':
      if (snippet?.flags?.validity_status) {
        return snippet?.flags?.validity_status;
      } else {
        return `Not specified`;
      }

    case 'validity_alert':
      if (snippet?.flags?.validity_alert && snippet?.flags?.validity_status === 'not_verified') {
        return snippet?.flags?.validity_alert;
      } else {
        return `Not specified`;
      }

    default:
      return null;
  }
};

export function sortGroupingObject(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => sortGroupingObject(item));
  }

  const customSortOrder = ['not_verified', 'not_valid', 'valid'];

  const sortedKeys = Object.keys(obj).sort((a, b) => {
    // Get the index of each key in the custom sort order
    const indexA = customSortOrder.indexOf(a);
    const indexB = customSortOrder.indexOf(b);

    // If both keys are in the custom sort order, sort them accordingly
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one key is in the custom sort order, prioritize it
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    // Otherwise, sort alphabetically
    return a.localeCompare(b);
  });

  const sortedObj = {};
  sortedKeys.forEach(key => {
    if (key.includes('%sort%')) {
      const new_key = key.slice(key.indexOf('%sort%') + 7);
      sortedObj[new_key] = sortGroupingObject(obj[key]);
    } else {
      sortedObj[key] = sortGroupingObject(obj[key]);
    }
  });
  return sortedObj;
}
