import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { TextInputNew } from '../../controls';
import { CustomButton } from '../../controls/ButtonComponents';
import { Popover } from '@material-ui/core';
import RedmineApi from '../../../service/Api/redmineApi';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { withSnackbar } from 'notistack';
import ApplicationCard from '../ApplicationCard/ApplicationCard';

import './RedmineIntegrationItemStyles.scss';

interface IProps {
  enqueueSnackbar: any;
}

const RedmineIntegrationItem: React.FC<IProps> = ({ enqueueSnackbar }) => {
  const themeClass = useGetThemeClass('b-renderIntItem');

  const project = useAppSelector(state => state.phasePlan.projectData);
  const user_data = useAppSelector(state => state.userReducer);

  const [redmineIdentifiers, setRedmineIdentifiers] = useState<string[]>(['']);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [focusElement, setFocusElement] = useState<string | null>(null);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [projectError, setProjectError] = useState([null]);
  const [isConnected, setIsConnecter] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const snackbarConfig = variant => {
    return {
      variant: variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    };
  };

  useEffect(() => {
    const { redmine } = project;
    if (!redmine?.length) {
      setRedmineIdentifiers(['']);
      setIsConnecter(false);
      return;
    }
    if (redmine) {
      setRedmineIdentifiers(redmine);
      setIsConnecter(true);
    }
    return;
  }, [project]);

  useEffect(() => {
    if (!user_data.userInfo?.redmine_key) return;
    const errors = [];
    redmineIdentifiers.map(item => {
      if (!item) return;
      fetch(`${process.env.RM_BASE_URL}/projects/${item}.json`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'X-Redmine-API-Key': user_data.userInfo.redmine_key,
        },
        redirect: 'follow',
      })
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(item => {
          errors.push({ item: item.project.identifier, error: null });
          setProjectError([...errors]);
        })
        .catch(err => {
          if (err.message === 'Unauthorized') {
            errors.push({ item: item, error: 'Redmine key is not correct' });
            setProjectError([...errors]);
            return;
          }
          errors.push({ item: item, error: 'Check Redmine identifier' });
          setProjectError([...errors]);
        });
    });
  }, [user_data.userInfo?.redmine_key, redmineIdentifiers, setProjectError]);

  const handleSetFocus = focusElement => {
    setFocusElement(focusElement);
  };

  const addNewInput = () => {
    const _redmInd = [...redmineIdentifiers];
    _redmInd.push('');
    setRedmineIdentifiers(_redmInd);
  };

  const removeInputRow = () => {
    const _redmInd = [...redmineIdentifiers];
    _redmInd.pop();
    setRedmineIdentifiers(_redmInd);
  };

  const removeIdentifier = async i => {
    if (redmineIdentifiers.length === 1) return;

    const newArray = [...redmineIdentifiers];
    newArray.splice(i, 1);
    setRedmineIdentifiers(newArray);
  };

  const handleChangeInput = (i, input) => {
    const newArray = [...redmineIdentifiers];
    newArray[i] = input;
    setRedmineIdentifiers(newArray);
  };

  const isMoreThanOneRow = redmineIdentifiers.length > 1;

  const setRedmineIdentifier = async () => {
    if (!user_data.userInfo?.redmine_key) return;
    if (!projectError.every(element => element.error === null)) return;
    try {
      setIsSaveLoading(true);
      if (project.redmine?.length) {
        const dataRemove = {
          owner_id: project.id,
          owner_type: 'project',
          redmine_ids: project.redmine,
          action: 'remove',
        };
        await RedmineApi.setRedmineId(dataRemove);
        setIsOpenPopover(null);
      }

      if (redmineIdentifiers[0].length) {
        const data = {
          owner_id: project.id,
          owner_type: 'project',
          redmine_ids: redmineIdentifiers.filter(el => el),
          action: 'add',
        };
        await RedmineApi.setRedmineId(data);
        setIsOpenPopover(null);
      }
      enqueueSnackbar(`Redmine identifiers have been updated`, snackbarConfig('success'));
    } catch (error) {
      enqueueSnackbar(`Something went wrong, please try again`, snackbarConfig('error'));
    }
    setIsSaveLoading(false);
    dispatch(getProjectById(project.id));
    setRedmineIdentifiers(redmineIdentifiers);
    setIsConnecter(true);
  };

  const renderInsidePopover = () => {
    return (
      <div className={`${themeClass}__popover`}>
        {redmineIdentifiers.map((item, i) => (
          <TextInputNew
            key={i}
            label={'Redmine identifier'}
            value={item}
            type={'on-bgd'}
            onChange={input => {
              handleChangeInput(i, input);
            }}
            size={'md'}
            id={`${i}_row`}
            isFocus={focusElement === `${i}_row`}
            onFocus={() => handleSetFocus(`${i}_row`)}
            onBlur={() => handleSetFocus('')}
            placeholder={'Enter Redmine project identifier'}
            customIconClass={`${themeClass}__isWatch`}
            customIcon={isMoreThanOneRow ? <TokenIcon iconName={'close'} size={20} /> : null}
            onClickIcon={() => removeIdentifier(i)}
            error={
              projectError?.find(errItem => {
                return errItem?.item === item;
              })?.error
            }
          />
        ))}
        <div className={`${themeClass}__btnBlock`}>
          <CustomButton type={'text-plain'} title={'Remove row'} size={'sm'} clickHandler={removeInputRow} disabled={!isMoreThanOneRow} />
          <CustomButton type={'text-plain'} title={'Add row'} size={'sm'} clickHandler={addNewInput} />
          <CustomButton
            type={'tertiary'}
            title={'Save'}
            size={'md'}
            clickHandler={setRedmineIdentifier}
            loading={isSaveLoading}
            disabled={!projectError.every(element => element?.error === null)}
            tooltip={!user_data.userInfo?.redmine_key ? 'Please set Redmine API key' : 'Save'}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <ApplicationCard
        isConnected={isConnected}
        title={'Redmine'}
        logo={'redmine-logo'}
        handleOnClick={() => setIsOpenPopover(true)}
        isModalOpen={isOpenPopover}
        handleModalClose={() => setIsOpenPopover(false)}
        renderModalContent={renderInsidePopover}
      />
    </div>
  );
};

export default withSnackbar(RedmineIntegrationItem);
