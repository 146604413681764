import Slider from 'rc-slider';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { useGetThemeClass } from '../../../helpers/designTokens';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import { CustomButton } from '../../controls/ButtonComponents';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';

import './AvatarImageEditorStyles.scss';

interface IProps {
  isOpenEditorPopup: boolean;
  handleCloseEditorPopup: () => void;
  imageForEdit: string | File;
  avatarScale: number;
  avatarRotation: number;
  editorPosition: any;
  handlePositionChange: (position: any) => void;
  handleSlider: (props: any) => void;
  onSliderChange: (value: number) => void;
  rotateAvatar: (e: Event, direction: string) => void;
  onAvatarSave: (editorRef: any) => void;
  width?: number;
  height?: number;
  borderRadius: number;
  title?: string;
}

export const AvatarImageEditor = ({
  isOpenEditorPopup,
  handleCloseEditorPopup,
  imageForEdit,
  avatarScale,
  avatarRotation,
  editorPosition,
  handlePositionChange,
  handleSlider,
  onSliderChange,
  rotateAvatar,
  onAvatarSave,
  width,
  height,
  borderRadius,
  title,
}: IProps) => {
  const editorRef = useRef<AvatarEditor>();

  const themeClass = useGetThemeClass('b-avatarEditor');

  useEffect(() => {
    document.addEventListener('wheel', editorZoom);

    return () => document.removeEventListener('wheel', editorZoom);
  }, [isOpenEditorPopup, avatarScale]);

  const editorZoom = e => {
    if (isOpenEditorPopup) {
      let scale;

      if (e.deltaY < 0 && avatarScale < 2) {
        // Zoom in
        scale = avatarScale + 0.1;
      } else if (e.deltaY > 0 && avatarScale > 0) {
        // Zoom out
        scale = avatarScale - 0.1;
      }

      if (scale || scale === 0) {
        onSliderChange(scale);
      }
    }
  };

  const saveAvatar = () => {
    onAvatarSave && onAvatarSave(editorRef);
  };

  return (
    <>
      <DialogPopUp open={isOpenEditorPopup} onClose={handleCloseEditorPopup} title={'Avatar Editor'} hideBackdrop={true}>
        <div className={`${themeClass}`}>
          <div className={`${themeClass}_header`}>
            <div className={`${themeClass}_header_title`}>{title || 'Avatar Editor'}</div>
            <div className={`${themeClass}_closeIcon`} onClick={handleCloseEditorPopup}>
              <SystemButton type={'close'} size={'lg'} variant={'transparent'} />
            </div>
          </div>
          <div className={`${themeClass}__editorPopupContent`}>
            <div className={'avatarEditorContainer'}>
              <div className={'editor'}>
                <AvatarEditor
                  ref={editorRef}
                  image={imageForEdit}
                  showGrid
                  width={width || 150}
                  height={height || 150}
                  border={20}
                  scale={avatarScale}
                  borderRadius={borderRadius}
                  rotate={avatarRotation}
                  position={editorPosition}
                  onPositionChange={handlePositionChange}
                  crossOrigin={'anonymous'}
                />
              </div>
              <div className={'editorSettings'}>
                <div className={'sliderAvatar'}>
                  <span className={'editorSettingsLabel'}>Zoom</span>
                  <div className={'sliderContainer'}>
                    <TokenIcon customClass={'sliderIcon'} iconName={'dash'} size={24} />
                    <Slider
                      min={0}
                      max={2}
                      value={avatarScale}
                      defaultValue={1}
                      step={0.1}
                      handle={handleSlider}
                      onChange={onSliderChange}
                    />
                    <TokenIcon customClass={'sliderIcon'} iconName={'plus'} size={24} />
                  </div>
                </div>
                <div className={'rotationSettings'}>
                  <span className={'editorSettingsLabel'}>Rotate</span>
                  <div className={'rotationSettings__buttons'}>
                    <SystemButton
                      type={'rotate-image-left'}
                      size={'lg'}
                      variant={'transparent'}
                      clickHandler={e => rotateAvatar(e, 'left')}
                    />
                    <SystemButton
                      type={'rotate-image-right'}
                      size={'lg'}
                      variant={'transparent'}
                      clickHandler={e => rotateAvatar(e, 'right')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'buttonContainer'}>
              <CustomButton type={'primary'} size={'md'} title={'Save'} clickHandler={saveAvatar} />
            </div>
          </div>
        </div>
      </DialogPopUp>
    </>
  );
};
