import React from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import { MiniLoader } from '../MiniLoader';

import './ItemsСounter.module.scss';

interface IItemsCounterProps {
  value: number | string;
  size: 'sm' | 'md' | 'mobile';
  isTextLabel?: boolean;
  labelText?: string;
  isFilled?: boolean;
  color?: 'primary' | 'nf';
  isLoading?: boolean;
  isHideLabelWhileLoading?: boolean;
}

const ItemsCounter: React.FC<IItemsCounterProps> = ({
  value,
  size,
  isTextLabel,
  labelText,
  isFilled,
  color,
  isLoading,
  isHideLabelWhileLoading = false,
}) => {
  const themeClass = useGetThemeClass('counter');

  const isHideLabel = isLoading && isHideLabelWhileLoading;

  return (
    <div
      className={cn(`${themeClass}`, {
        ['-filled']: isFilled,
        ['-sm']: size === 'sm',
        ['-md']: size === 'md',
        ['-mobile']: size === 'mobile',
      })}
    >
      {isLoading ? (
        <div className={`${themeClass}_loader_container`}>
          <MiniLoader circleClasses={`${themeClass}_loader`} size={'xs'} />
        </div>
      ) : (
        <span className={cn(`${themeClass}_value`, { ['-primary']: color === 'primary' }, { ['-nf']: color === 'nf' })}>{value}</span>
      )}
      {isTextLabel && !isFilled && !isHideLabel && <span className={`${themeClass}_label`}>{labelText ? labelText : 'items'}</span>}
    </div>
  );
};

export default ItemsCounter;
