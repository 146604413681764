import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, PageHeading2, Logo } from '../../controls/index';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../navigation/routes';
import { handleGetThemeClass } from '../../../helpers/designTokens';
import { CustomButton } from '../../controls/ButtonComponents';

import '../NonLoginedPage/NonLoginedPageStyles.scss';

class ForgotPasswordView extends React.Component {
  static propTypes = {
    //handlers
    handleChangeField: PropTypes.func.isRequired,
    handleLogin: PropTypes.func.isRequired,
    handleBlurField: PropTypes.func.isRequired,
    clearMessage: PropTypes.func.isRequired,
    //data
    loading: PropTypes.bool.isRequired,
    theme: PropTypes.string.isRequired,
  };

  render() {
    const themeClass = handleGetThemeClass('b-authorization-loginPage', this.props.theme || 'light');
    const { handleChangeField, email, handleLogin, loading, message, status, clearMessage } = this.props;

    const getTheme = () => {
      return this.props.theme || localStorage.getItem('theme') || 'light';
    };

    return (
      <div className={`${themeClass}_container`}>
        <div className={`${themeClass}_topInfo`}>
          <div className={`${themeClass}_logoContainer`}>
            <Logo isFull themeProp={getTheme()} />
            <p className={`${themeClass}_logoContainer__header`}>Plan & Control App</p>
          </div>
          {!loading && !status ? <span className={`${themeClass}_loginError`}>{message}</span> : null}
          <div className={`${themeClass}_form`}>
            <PageHeading2 title="Restoring access" theme={this.props.theme} />

            <TextInput
              label="Email"
              value={email}
              onChange={handleChangeField}
              errorMsg={''}
              theme={getTheme()}
              handleLogin={handleLogin}
              labelHidden
            />
            <div className={`${themeClass}_loginBtn`}>
              <CustomButton
                type={'primary'}
                size={'md'}
                title={'Reset password'}
                disabled={loading || !email}
                loading={loading}
                clickHandler={handleLogin}
              />
            </div>
            <div className={`${themeClass}_backToSignIn`} onClick={clearMessage}>
              <Link to={ROUTES.loginP}>
                <CustomButton type={'text-plain'} size={'xs'} title={'Back to Sign in'} />
              </Link>
            </div>
          </div>
        </div>
        <div className={`${themeClass}_bottomInfo`}>
          <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
          {/*<span className={`${themeClass}_bottomInfo_textInfo`}>Privacy Policy</span>*/}
        </div>
      </div>
    );
  }
}

export default ForgotPasswordView;
