import React, { FC, useEffect, useRef, useState } from 'react';
import { DataGridBasic } from '../../common/DataGridBasic';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './SkeletonGridStyles.scss';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import ReactResizeDetector from 'react-resize-detector';

const SkeletonGrid = () => {
  const [rowWidth, setRowWidth] = useState(252);

  const [rowsCount, setRowsCount] = useState(25);
  const [columnsCount, setColumnsCount] = useState(7);

  const rows = [...Array(rowsCount)].map((_, idx) => ({ id: idx, skeleton: true }));
  const columns = [...Array(columnsCount)].map((_, idx) => ({
    key: `skeleton-${idx}`,
    name: '',
    width: rowWidth,
    formatter: () => <CustomSkeleton width={'100%'} height={30} />,
    headerRenderer: () => <CustomSkeleton width={'100%'} height={30} />,
  }));

  const themeClass = useGetThemeClass('b-skeletonGrid');

  const tableRef = useRef<HTMLDivElement>(null);
  const handleChangeRowWidth = (width, height) => {
    setRowsCount(Math.floor(height / 44));
    if (width > 3400) {
      setColumnsCount(13);
      setRowWidth(width / 13);
    } else if (width > 2900) {
      setColumnsCount(12);
      setRowWidth(width / 12);
    } else if (width > 2600) {
      setColumnsCount(11);
      setRowWidth(width / 11);
    } else if (width > 2300) {
      setColumnsCount(10);
      setRowWidth(width / 10);
    } else if (width > 2000) {
      setColumnsCount(9);
      setRowWidth(width / 9);
    } else if (width > 1700) {
      setColumnsCount(8);
      setRowWidth(width / 8);
    } else if (width > 1440) {
      setColumnsCount(7);
      setRowWidth(width / 7);
    } else if (width > 1024) {
      setColumnsCount(6);
      setRowWidth(width / 6);
    } else if (width > 960) {
      setColumnsCount(5);
      setRowWidth(width / 5);
    } else if (width > 768) {
      setColumnsCount(4);
      setRowWidth(width / 4);
    } else if (width > 639) {
      setColumnsCount(3);
      setRowWidth(width / 3);
    } else {
      setColumnsCount(2);
      setRowWidth(width / 2);
    }
  };

  return (
    <>
      <ReactResizeDetector targetRef={tableRef} handleWidth refreshMode="throttle" onResize={handleChangeRowWidth} />
      <div className={themeClass} ref={tableRef}>
        <DataGridBasic rows={rows} columns={columns} customHeaderRenderer isLoading rowHeight={44} />
      </div>
    </>
  );
};
export default SkeletonGrid;
