import React, { Component } from 'react';
import PopUpView from '../../../../controls/PopUp/PopUpView';
import { UserPermissionsModel } from '../../../../../models';
import faqContents from '../helpContents/FaqContents';
import SVG from 'react-inlinesvg';
import GlossarySearch from '../help_controls/GlossarySearch';
import ButtonView from '../../../../controls/ButtonComponents/ButtonView/ButtonView';

interface IProps {
  open: boolean;
  close: () => void;
}

interface IState {
  faqHelpContents: UserPermissionsModel[];
}

class FAQ extends Component<IProps, IState> {
  state = {
    faqHelpContents: faqContents,
  };

  hideMainHeader = (id: number) => {
    const hide = state => {
      const newState = state;
      newState[id].isClosed = !state[id].isClosed;
      return newState;
    };

    this.setState(prev => ({
      faqHelpContents: hide(prev.faqHelpContents),
    }));
  };

  hideSubHeader = (mainHeaderId: number, subHeaderId: number) => {
    const hide = state => {
      const newState = state;
      newState[mainHeaderId].headers[subHeaderId].isClosed = !state[mainHeaderId].headers[subHeaderId].isClosed;
      return newState;
    };

    this.setState(prev => ({
      faqHelpContents: hide(prev.faqHelpContents),
    }));
  };

  collapseAll = () => {
    const collapse = state => {
      const newState = state;
      newState.forEach(item => {
        item.headers.forEach(header => {
          header.isClosed = true;
        });
      });
      return newState;
    };

    this.setState(prev => ({
      faqHelpContents: collapse(prev.faqHelpContents),
    }));
  };

  render() {
    const { faqHelpContents } = this.state;

    const { open, close } = this.props;

    return (
      <PopUpView
        open={open}
        onClose={close}
        title={`FAQ`}
        maxWidth="sm"
        className={'library-popup'}
        disableBtn={true}
        customButton={
          <div className={'collapseAll'}>
            <ButtonView
              type={'outline'}
              title={'Collapse All'}
              handleClick={this.collapseAll}
              width={'100px'}
              height={'30px'}
              disabled={false}
            />
          </div>
        }
        customButtonStyle={{ justifyContent: 'space-between' }}
        customPaperClass={'FAQContainer'}
      >
        <div className={'tutorialPopupContent'} style={{ padding: '0px', minHeight: '550px' }}>
          <div className={'popup-content'} style={{ margin: '0px' }}>
            {faqHelpContents.map(mainHeader => {
              return (
                <>
                  <div className={'popup-content__accordion'} style={{ cursor: 'default' }} key={mainHeader.id}>
                    <div className={'popup-content__accordionContent'}>
                      {/*<SVG*/}
                      {/*  src={require('../../../../../assets/icons/arrow_right.svg')}*/}
                      {/*  className='popup-content__accordionArrow'*/}
                      {/*  // style={faqHelpContents[mainHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}*/}
                      {/*  // onClick={() => this.hideMainHeader(mainHeader.id)}*/}
                      {/*/>*/}
                      <span
                        // onClick={() => this.hideMainHeader(mainHeader.id)}
                        className={'popup-content__text library-headerText'}
                        style={{ cursor: 'default' }}
                      >
                        {mainHeader.title}
                      </span>
                    </div>
                  </div>
                  <div
                  // style={faqHelpContents[mainHeader.id].isClosed ? {display:'none'} : {}}
                  >
                    {mainHeader.headers.map(subHeader => {
                      const mainHeaderItem = faqHelpContents[mainHeader.id].headers;
                      return (
                        <>
                          <div
                            className={'popup-content__accordion -subHeader'}
                            key={subHeader.id}
                            onClick={() => this.hideSubHeader(mainHeader.id, subHeader.id)}
                          >
                            <div className={'popup-content__accordionContent -subHeader'}>
                              <SVG
                                src={require('../../../../../assets/icons/arrow_right.svg')}
                                className="popup-content__accordionArrow"
                                style={mainHeaderItem[subHeader.id].isClosed ? { transform: 'rotate(0deg)' } : {}}
                              />
                              <span className={'popup-content__text'}>{subHeader.title}</span>
                            </div>
                          </div>
                          <div
                            className={'popup-content__text library-text'}
                            style={mainHeaderItem[subHeader.id].isClosed ? { display: 'none' } : {}}
                          >
                            <p>{subHeader.text}</p>
                          </div>
                          <div className={'library-borderline'} />
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </PopUpView>
    );
  }
}

export default FAQ;
