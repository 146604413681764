import React from 'react';
import List from '@material-ui/core/List';

class EmptyData extends React.Component {
  render() {
    return (
      <List component="nav" style={{ width: '100%' }}>
        <div className={'emptyData__wrapText'}>
          <span className={'emptyData__text'}>No Results</span>
        </div>
      </List>
    );
  }
}

export default EmptyData;
