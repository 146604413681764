import React, { useState } from 'react';
import { SandBoxTabModel } from '../../../models/sandBox/sandBoxArhitecture';
import { useAppSelector } from '../../../store/configure/configureStore';
import SubmenuNew from '../SubmenuNew/SubmenuNew';
import TokenIcon from '../TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../helpers/designTokens';
import Divider from '../Divider/Divider';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import cn from 'classnames';

import './SubmenuSectionsNewStyle.module.scss';

interface IProps {
  allSections: SandBoxTabModel[];
  activeSectionId: string;
  selectSection: (sectionId: string, isClick: boolean) => void;
  openedFrom: string;
  isCollapsedBtn: boolean;
  handleCollapsedBtn: (isCollapsed: boolean) => void;
  relatedTo?: 'user' | 'party';
  report_user_id?: number;
  onClickParentSection?: (id: string) => void;
}

const SubmenuSectionsNew = ({
  allSections,
  activeSectionId,
  selectSection,
  openedFrom,
  isCollapsedBtn,
  handleCollapsedBtn,
  relatedTo,
  report_user_id,
  onClickParentSection,
}: IProps) => {
  const sandBoxCountersLoading = useAppSelector(state => state.requestReducer.sandBoxCountersLoading);
  const sandBoxCounters = useAppSelector(state => state.requestReducer.sandBox.counters);
  const themeClass = useGetThemeClass('b-sandBox-sections');
  const themeIconClass = useGetThemeClass('b-submenuNew');
  const [hoveredSectionId, setHoveredSectionId] = useState<string>('');

  const getTitle = (Sections: SandBoxTabModel) => {
    if (openedFrom === 'report') {
      if (Sections?.title_report) {
        if (report_user_id) {
          return Sections?.title_report.user;
        } else {
          return Sections?.title_report.company;
        }
      }
    }
    if (relatedTo === 'user' || report_user_id) {
      return Sections?.title_to_me;
    } else {
      return Sections?.title_to_company;
    }
  };

  const getSubTitle = (Sections: SandBoxTabModel) => {
    return Sections.title;
  };

  const getIcon = (nameIcon: string, nameClass: string) => {
    return (
      <div className={`${themeIconClass}_container_icon ${themeIconClass}_${nameClass}`}>
        <TokenIcon iconName={nameIcon} size={16} />
      </div>
    );
  };

  const getItemsSection = (Sections: SandBoxTabModel[], isExpanded: boolean) => {
    return (
      <div className={cn(`${themeClass}__sectionContainer_table_container -items`, { ['-expanded']: isExpanded })}>
        {Sections.map(section => {
          const isHovered = section.id === hoveredSectionId;
          const isCustom = section.isCustom;
          const isSwitchIcons = isHovered && isCustom;

          return (
            <div
              className={`${themeClass}__sectionContainer_table_item`}
              key={section.id}
              onMouseEnter={() => setHoveredSectionId(section.id)}
              onMouseLeave={() => setHoveredSectionId('')}
            >
              <SubmenuNew
                disabled={section.disabled}
                title={getSubTitle(section)}
                type={isCollapsedBtn ? 'collapsed' : 'expanded'}
                isParent={false}
                count={section.count}
                isSelected={activeSectionId === section.id}
                isSwitchIcons={isSwitchIcons}
                onClick={() => (activeSectionId === section.id ? null : selectSection(section.id, true))}
                leftElem={section.icon && getIcon(section.icon, section.iconNameClass)}
                isCountersLoading={!sandBoxCounters && sandBoxCountersLoading.loading}
                isHovered={isHovered}
                openedFrom={openedFrom}
                isSelectGroup={true}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      id={'sandBoxSections-tourBlock'}
      className={cn(`${themeClass}`, {
        ['-report']: openedFrom === 'report',
        [`-collapsed`]: isCollapsedBtn,
      })}
    >
      <div className={cn(`${themeClass}__sectionContainer`, { ['-report']: openedFrom === 'report' })}>
        <div id={'sandBoxContainerTable-tourBlock'} className={`${themeClass}__sectionContainer_table`}>
          {allSections
            .filter(item => (item.isHideSection ? false : true))
            .map(section => {
              const isHovered = section.id === hoveredSectionId;
              const isCustom = section.isCustom;
              const isSwitchIcons = isHovered && isCustom;
              return (
                <div className={`${themeClass}__sectionContainer_table_container`} key={`section-${section.id}`}>
                  <div
                    className={`${themeClass}__sectionContainer_table_item`}
                    onMouseEnter={() => setHoveredSectionId(section.id)}
                    onMouseLeave={() => setHoveredSectionId('')}
                  >
                    <SubmenuNew
                      title={getTitle(section)}
                      type={isCollapsedBtn ? 'collapsed' : 'expanded'}
                      isParent
                      count={section.count}
                      typeSystemButton={section.typeSystemButton}
                      isSelected={activeSectionId === section.id}
                      isSwitchIcons={isSwitchIcons}
                      onClick={() => onClickParentSection(section.id)}
                      leftElem={section.icon && getIcon(section.icon, section.iconNameClass)}
                      isCountersLoading={!sandBoxCounters && sandBoxCountersLoading.loading}
                      isHovered={isHovered}
                      openedFrom={openedFrom}
                      isSelectGroup={section.typeSystemButton === 'chevron-down'}
                    />
                  </div>
                  {getItemsSection(section.sections, section.typeSystemButton === 'chevron-down')}
                </div>
              );
            })}
        </div>
      </div>
      {openedFrom !== 'report' && (
        <div
          className={cn(`${themeClass}_footerSection`, {
            [`-expanded`]: !isCollapsedBtn,
            [`-collapsed`]: isCollapsedBtn,
          })}
        >
          <Divider direction={'horizontal'} type={'srf-5'} />
          {isCollapsedBtn ? (
            <SystemButton
              type={'chevron-double-right'}
              variant={'transparent'}
              size={'md'}
              clickHandler={() => handleCollapsedBtn(false)}
            />
          ) : (
            <SystemButton type={'chevron-double-left'} variant={'transparent'} size={'md'} clickHandler={() => handleCollapsedBtn(true)} />
          )}
        </div>
      )}
    </div>
  );
};

export default SubmenuSectionsNew;
