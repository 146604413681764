import React, { Component } from 'react';

class Update_20220119_5 extends Component {
  render() {
    return (
      <>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0' }}>
          <img
            className={'fullImage'}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_c8ea15edd02a34b94e723bab54a55987/images/image4.png'}
            alt=""
          />
        </div>
      </>
    );
  }
}

export default Update_20220119_5;
