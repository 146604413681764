import requestApi from '../../../../service/Api/requestApi';

export const mentionModule = {
  allowedChars: /^[A-Za-z\s]*$/,
  mentionDenotationChars: ['@', '[~'],
  isolateCharacter: true,

  onSelect: (item, insertItem) => {
    item.value = item.target + ']';
    insertItem(item);
  },
  source: async function (searchTerm, renderList) {
    const projectId = +sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : {};
    projectParam = { ...projectParam, ...{ search: searchTerm } };
    const res = await requestApi.searchUsers(projectParam);
    if (res.status !== true) {
      return;
    }

    const values = Object.values(res.data).map((user: any) => {
      return {
        id: user.id,
        value: `<div class="ql-mention-list-container-item">
                                      <img src='${
                                        user.profile.image ? user.profile.image : require('../../../../assets/icons/avatar.svg')
                                      }' alt="" />
                                      <span>${user.profile.first_name} ${
          user.profile.last_name
        } <span class="ql-mention-list-container-item-dash">&nbsp;-&nbsp;</span> <br class="ql-mention-list-container-item-br"> ${
          user.email
        }</span>
                                      </div>`,
        denotationChar: '[~',
        target: user.email,
      };
    });
    renderList(values, searchTerm);
  },
};

export function addMention() {
  //@ts-ignore
  const cursorPosition = this.quill.getSelection().index;
  //@ts-ignore
  this.quill.insertText(cursorPosition, ' @');
  //@ts-ignore
  this.quill.setSelection(cursorPosition + 2);
}
