import { connect } from 'react-redux';
import { projectSlice } from '../../../store/project/projectReducer';
import { filesUpload } from '../../../store/globalSettings/settingsThunk';
import { responseSave, requestSave, singleRequestLoading, whiteBoard, whiteBoardFilters } from '../../../store/request/requestLogic(HOLD)';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';

const { projectLoading } = projectSlice.actions;
const { resetWhiteBoardFilters } = requestSlice.actions;
let whiteBoardPromise;
let whiteBoardFiltersPromise;
let loadRequestPromise;

const mapDispatchToProps = dispatch => ({
  getWhiteBoard(payload = {}) {
    whiteBoardPromise?.abort();
    whiteBoardPromise = dispatch(whiteBoard(payload));
  },

  getWhiteBoardFilters(payload = {}) {
    whiteBoardFiltersPromise?.abort();
    whiteBoardFiltersPromise = dispatch(whiteBoardFilters(payload));
  },

  loadProjectData() {
    dispatch(projectLoading());
  },

  fileUpload(files) {
    files.forEach(file => {
      dispatch(filesUpload(file));
    });
  },

  loadRequest(requestId) {
    loadRequestPromise?.abort();
    loadRequestPromise = dispatch(singleRequestLoading({ requestId }));
  },

  saveResponseAction(commitmentId, bodyParams) {
    dispatch(responseSave({ commitmentId, bodyParams, page: 'whiteboard' }));
  },
  resetFilters() {
    dispatch(resetWhiteBoardFilters());
  },
});

const mapStateToProps = state => ({
  requestData: state.requestReducer,
  requestLoading: state.requestReducer.loading,
  requestInfo: state.requestReducer.requestInfo.data,
  userData: state.userReducer,
  locations: locationsSelector(state),
  projectParties: partiesSelector(state),
  filesData: state.settingsReducer.files,
  whiteBoardFilters: addMonth(state),
  buildings: buildingSelector(state),
  wbLoading: state.requestReducer.wbLoading,
});

const locationsSelector = state => {
  const activeProject = state.userReducer.active_project_id;
  const projectLocation = state.projectReducer.projectInfo.locations;

  if (projectLocation) {
    const projectLocations = state.projectReducer.projectInfo.locations[activeProject]
      ? state.projectReducer.projectInfo.locations[activeProject]
      : {};
    if (Object.keys(projectLocations).length) {
      return projectLocations;
    }
    return state.settingsReducer.locationInfo;
  }
};

const addMonth = state => {
  const res = state.requestReducer.whiteBoardFilters;
  // if (state.requestReducer?.whiteBoardFilters) {
  //   if (moment(state.requestReducer.whiteBoardFilters.last_date).format('YYYY') === moment(state.requestReducer.whiteBoardFilters.first_date).format('YYYY')) {
  //     res.last_date = moment(state.requestReducer.whiteBoardFilters.last_date).add(1, 'year');
  //   } else {
  //     debugger
  //     res.last_date = moment(state.requestReducer.whiteBoardFilters.last_date).add(1, 'M');
  //   }
  // }
  return res;
};

const partiesSelector = state => {
  const activeProject = state.userReducer.active_project_id;
  const projectParties = state.projectReducer.projectInfo.parties;
  if (projectParties) {
    return projectParties[activeProject];
  }
};

const buildingSelector = state => {
  const activeProject = +state.userReducer.active_project_id;
  const projectBuildings = state.projectReducer.projectInfo.buildings;
  if (projectBuildings) {
    const buildings = state.projectReducer.projectInfo?.buildings[activeProject];
    return buildings && buildings.length ? [...buildings] : [];
  }
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
