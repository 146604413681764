import { PermissionModel, ScheduleModel } from '../models';
import moment from 'moment';

export default class PhasePlanHelper {
  public static isExistFields = (milestoneRow: ScheduleModel | PermissionModel) => {
    return !!(
      milestoneRow['construction_duration'] ||
      milestoneRow['construction_end'] ||
      milestoneRow['construction_start'] ||
      milestoneRow['lod200_start'] ||
      milestoneRow['lod290_duration'] ||
      milestoneRow['lod290_start'] ||
      milestoneRow['lod300_duration'] ||
      milestoneRow['lod300_start'] ||
      milestoneRow['lod325_duration'] ||
      milestoneRow['lod325_start'] ||
      milestoneRow['lod350_duration'] ||
      milestoneRow['lod350_start'] ||
      milestoneRow['approval_date'] ||
      milestoneRow['desc'] ||
      milestoneRow['durations'] ||
      milestoneRow['submission_date'] ||
      milestoneRow['tied_to']
    );
  };
  public static reorderArray = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  public static getMileStoneName = (milestoneAlias: string) => {
    switch (milestoneAlias) {
      case 'lod200_start': {
        return 'LOD 200';
      }
      case 'lod290_start': {
        return 'LOD 290';
      }
      case 'lod300_start': {
        return 'LOD 300';
      }
      case 'lod325_start': {
        return 'LOD 325';
      }
      case 'lod350_start': {
        return 'LOD 350';
      }
      default: {
        return '';
      }
    }
  };
  public static convertDateToRequest = formData => {
    formData.construction_start ? (formData.construction_start = moment(formData.construction_start).format('YYYY-MM-DD')) : null;
    formData.construction_end ? (formData.construction_end = moment(formData.construction_end).format('YYYY-MM-DD')) : null;
    formData.submission_date ? (formData.submission_date = moment(formData.submission_date).format('YYYY-MM-DD')) : null;
    formData.approval_date ? (formData.approval_date = moment(formData.approval_date).format('YYYY-MM-DD')) : null;
    formData.lod200_start ? (formData.lod200_start = moment(formData.lod200).format('YYYY-MM-DD')) : null;
    formData.lod290_start ? (formData.lod290_start = moment(formData.lod290_start).format('YYYY-MM-DD')) : null;
    formData.lod300_start ? (formData.lod300_start = moment(formData.lod300_start).format('YYYY-MM-DD')) : null;
    formData.lod325_start ? (formData.lod325_start = moment(formData.lod325_start).format('YYYY-MM-DD')) : null;
    formData.lod350_start ? (formData.lod350_start = moment(formData.lod350_start).format('YYYY-MM-DD')) : null;
    return formData;
  };

  public static getMileStoneNameByAlias = alias => {
    if (alias === 'lod350') {
      return 'LOD 350';
    }
    if (alias === 'lod325') {
      return 'LOD 325';
    }
    if (alias === 'lod300') {
      return 'LOD 300';
    }
    if (alias === 'lod290') {
      return 'LOD 290';
    }
    if (alias === 'lod200') {
      return 'LOD 200';
    }
    return '';
  };
}
