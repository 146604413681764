import React, { Component } from 'react';
import purple from '@material-ui/core/colors/purple';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withSnackbar } from 'notistack';
import * as validator from '../../../../service/validator';
import TextField from '@material-ui/core/TextField';
import FilesListView from '../../../controls/FilesList/FilesListView';
import PopUpView from '../../../controls/PopUp/PopUpView';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonView from '../../../controls/ButtonComponents/ButtonView/ButtonView';
import { getDisciplines, updateLinkParty } from '../../../../store/project/projectLogic';
import { sendBugReport } from '../../../../store/request/requestLogic(HOLD)';
import { filesUpload } from '../../../../store/globalSettings/settingsThunk';
import { settingsSlice } from '../../../../store/globalSettings/settingsSlice';
const { deleteFile, filesUploadClean } = settingsSlice.actions;

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  cancelButton: {
    marginLeft: '10px',
    color: 'var(--button-default-menu-text)',
    backgroundColor: 'inherit',
    border: '1px solid var(--background-grid) !important',
    '&:hover': {
      color: 'var(--button-hover-menu-text) !important',
      backgroundColor: 'var(--background-grid) !important',
      border: '1px solid transparent !important',
    },
  },
  root: {
    border: 'var(--background-border)',
    // borderRadius: '4px',
    height: '30px',
    borderRadius: '3px',
    padding: '0 7px',

    '& fieldset': {
      borderColor: 'red',
    },
  },
  AttachBtn: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--background-select-text)',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'none',
    padding: '0 8px 5px 8px',
  },
  textarea: {
    height: '250px',
  },
  selectRoot: {
    background: 'var(--background-main)',
  },
  supportSelect: {
    width: '100%',
    marginTop: '5px',
    // border: 'var(--background-border)',
  },
  cssRoot: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'var(--background-grid)',
    boxShadow: 'none',
    border: 'none',
    // border: '1px solid rgba(255,255,255, .6)',
    textTransform: 'capitalize',
    minWidth: 140,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, .4)',
    },
  },
  dialog: {
    '& .MuiPaper-root-140': {
      background: 'var(--background-main)',
    },
  },
  dialogActions: {
    justifyContent: 'flex-start',
  },

  dialogActionsWide: {
    justifyContent: 'space-between',
  },

  formControl: {
    margin: 0,
    width: '100%',
    display: 'flex',
    marginTop: 5,
    border: 'var(--background-border)',
    borderRadius: '3px',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      color: 'var(--color)',
      padding: '0',
      paddingLeft: '10px',
      minHeight: '30px',
    },
  },
  descriptionWrap: {
    border: 'var(--background-border)',
    borderRadius: '4px',

    '& fieldset': {
      border: 'none',
    },
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  select: {
    marginRight: '20px',
    width: '40%',
  },
  cssTwo: {
    // color: 'rgb(96, 116, 158)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // border: 'none',

    border: '1px solid var(--background-grid)',
    textTransform: 'capitalize',
    minWidth: 140,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, .4)',
    },
  },
  focused: {
    '& fieldset': {
      borderColor: 'var(--background-grid) !important',
      borderWidth: '1px',
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      overflowY: 'auto',
      width: 'auto',
      backgroundColor: 'var(--background-main)',
    },
  },
  MenuListProps: {
    style: {
      paddingBottom: '0px',
      backgroundColor: 'var(--background-table-header)',
      '&:hover': {
        backgroundColor: 'var(--background-main)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

class DialogSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        type: '',
        subject: '',
        description: '',
      },
      saveData: {},
      open: false,
      role: '',
      name: 'hai',
      labelWidth: 0,
      notification: '',
      selectedDiscipline: '',
      projectCompanies: [],
      loading: false,
      errors: {},
      files: [],
      isDialogSupportAnimeBtn: false,
    };
  }

  validator = {
    required: ['subject', 'description', 'type'],
  };

  handleSubmit = () => {
    const { formData } = this.state;
    const { errors } = validator.validate(this.validator, formData);
    this.setState({ errors: errors });
    // if (this.state.files.length === 0) {
    //   return this._validatorReport('you have to upload at least 1 image','error');
    // }
    // this.state.files.forEach(file => {
    //   this.props.filesUpload({file, source: 'commitment'});
    //   });
    if (!Object.keys(errors).length) {
      let data = {
        ...this.state.formData,
        files: this.state.files,
      };
      this.props.sendBugReport(data);
      this.props.filesUploadClean({ type: 'commitment' });
      this.setState({
        isDialogSupportAnimeBtn: true,
      });
    }
  };

  closeForm = () => {
    this.setState({
      formData: {
        email: '',
        subject: '',
        type: '',
        description: '',
        files: [],
      },
      errors: {},
      mainErrorMsg: '',
    });
    this.props.close();
  };

  closeThankPopup = () => {
    this.props.close();
  };

  handleChange = event => {
    const { errors } = validator.validate(this.validator, { ...this.state.formData, [event.target.name]: event.target.value });
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
      errors: {},
      enableButton: !Object.keys(errors).length ? true : false,
    });
  };

  _validatorReport(text, status, errors) {
    const { enqueueSnackbar } = this.props;
    if (!errors || !errors.length) {
      return enqueueSnackbar(text, { variant: status });
    }
    errors.forEach(({ message, variant }) => {
      enqueueSnackbar(message, { variant });
    });
  }

  handleFile = e => {
    if (this.state.formData.length === 5) {
      this._validatorReport('you can send a maximum of 5 files', 'error');
      return;
    }
    const files = Array.from(e.target.files);
    files &&
      files.map(i => {
        if (i.type.split('/')[0] !== 'image') {
          return;
        }
        this.props.filesUpload({ file: i, source: 'commitment' });
      });
  };

  delFile = filename => {
    this.setState({
      ...this.state,
      files: this.state.files.filter(item => item.name !== filename),
    });
    this.props.deleteFile({ data: filename });
  };

  componentDidUpdate(prevProps) {
    if (this.props.statusBug && this.props.statusBug !== prevProps.statusBug) {
      this.setState({
        open: false,
        loading: false,
        isDialogSupportAnimeBtn: false,
      });
      // this._validatorReport('Your message has been successfully sent', 'success');
      setTimeout(() => {
        this.props.close();
      }, 2000);
    }

    if (this.props.filesData !== prevProps.filesData && this.props.filesData.commitment && this.props.filesData.commitment.data) {
      let files = Object.values(this.props.filesData.commitment.data);
      files = files.map(file => {
        return {
          ...file,
          name: file.original_name,
          file_path: file.filepath,
          file_name: file.original_name,
        };
      });
      this.setState({
        ...this.state,
        files: files,
      });
    }

    if (this.props?.sandBugReportErrors && this.props?.sandBugReportErrors !== prevProps.sandBugReportErrors) {
      this.setState({
        mainErrorMsg: Object.values(this.props?.sandBugReportErrors)?.[0]?.[0],
      });
    }
  }

  close = () => {
    this.setState({
      mainErrorMsg: '',
    });
    this.props.close();
  };

  render() {
    const { classes } = this.props;

    const rowStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    };

    const labelStyles = {
      width: '46%',
      boxSizing: 'border-box',
      color: 'var(--color-noSelect)',
      fontFamily: 'var(--font-general), sans-serif',
      marginBottom: 10,
    };
    const labelTextStyles = {
      marginLeft: '10px',
      color: 'var(--background-select-text)',
    };
    const descriptionlabelStyles = {
      width: '100%',
      boxSizing: 'border-box',
      color: 'var(--background-select-text)',
      fontFamily: 'var(--font-general), sans-serif',
      marginBottom: 10,
    };
    const popupButtons = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      marginTop: '10px',
    };
    const { errors } = this.state;

    return (
      <>
        <PopUpView
          open={this.props.open}
          onClose={this.close}
          title={`Share feedback`}
          maxWidth={'sm'}
          className={'sectionPopupContent'}
          disableBtn={true}
          hideRemoveIcon={true}
          customPaperClass={'ShareFeedbackContainer'}
        >
          <span className={'error'}>{this.state.mainErrorMsg}</span>
          <form>
            <div style={rowStyles} className="form-row">
              <label style={labelStyles}>
                <span style={labelTextStyles}>Subject</span>
                <FormControl variant="outlined" className={classes.formControl}>
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="subject"
                    id="subject"
                    value={this.state.formData.subject}
                    onChange={this.handleChange}
                    error={errors['subject']}
                    inputProps={{ autocomplete: 'off' }}
                    // disabled={!this.state.formData.type}
                  />
                </FormControl>
                {errors['subject'] && (
                  <span className={'error'} style={{ marginTop: '5px', display: 'inline-block' }}>
                    {errors['subject']}
                  </span>
                )}
              </label>
              <label style={labelStyles}>
                <span style={labelTextStyles}>Type of feedback</span>
                <FormControl className={`${classes.supportSelect}`}>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={this.state.formData.type}
                    onChange={this.handleChange}
                    name={'type'}
                    MenuProps={MenuProps}
                    className={'select aside__select feedbackSelect'}
                    classes={{
                      root: classes ? classes.root : '',
                    }}
                  >
                    <MenuItem className={'menuItem'} value={'bug'}>
                      Report a bug
                    </MenuItem>
                    <MenuItem className={'menuItem'} value={'feedback'}>
                      General feedback
                    </MenuItem>
                    <MenuItem className={'menuItem'} value={'improvement'}>
                      Suggest improvement
                    </MenuItem>
                    <MenuItem className={'menuItem'} value={'question'}>
                      General question
                    </MenuItem>
                    <MenuItem className={'menuItem'} value={'other'}>
                      Other
                    </MenuItem>
                  </Select>
                  {errors['type'] && (
                    <span className={'error'} style={{ marginTop: '5px', display: 'inline-block' }}>
                      {errors['type']}
                    </span>
                  )}
                </FormControl>
              </label>
            </div>
            <div style={rowStyles} className="form-row">
              <div className={classes.head}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`raised-button-file-${this.props.source}`}
                  multiple
                  type="file"
                  onChange={this.handleFile}
                  // disabled={!this.state.formData.type}
                />
              </div>
              <label style={descriptionlabelStyles}>
                <span style={labelTextStyles}>Description</span>
                <FormControl variant="outlined" className={`${classes.formControl} ${classes.descriptionWrap}`}>
                  <TextField
                    labelWidth={this.state.labelWidth}
                    name="description"
                    id="standard-multiline-static"
                    value={this.state.formData.description}
                    onChange={this.handleChange}
                    multiline
                    error={errors['description']}
                    variant="outlined"
                  />
                  <div
                    className={`${classes.attachFile} ${this.props.source === 'C' ? 'b-requestCommitment__commitmentAttachFile' : ''} ${
                      this.props.source === 'Comment' ? 'commentAttachFile' : ''
                    }`}
                  >
                    <input
                      //accept="image/*"
                      style={{ display: 'none' }}
                      id={`raised-button-file-{${this.props.source}`}
                      multiple
                      type="file"
                      onChange={this.props.handleFile || this.handleFile}
                    />
                    <div className={'b-comment__filesBlock'}>
                      {this.state.files && (
                        <div className={'b-comment__fileListEdit'}>
                          <FilesListView
                            files={this.state.files}
                            delFile={this.delFile}
                            delSavedFile={this.props.delSavedFile}
                            source={this.props.source}
                            disabled={false}
                            isNoFullWidth={true}
                          />
                        </div>
                      )}
                      <label
                        style={{ display: 'flex', paddingRight: '5px', justifyContent: 'flex-end' }}
                        htmlFor={`raised-button-file-{${this.props.source}`}
                      >
                        <span className={classes.AttachBtn}>Attach file</span>
                      </label>
                    </div>
                  </div>
                </FormControl>
                {errors['description'] && (
                  <span className={'error'} style={{ marginTop: '5px', display: 'inline-block' }}>
                    {errors['description']}
                  </span>
                )}
              </label>
            </div>
            <div style={popupButtons}>
              <ButtonView
                type={'primal'}
                title={'Save'}
                handleClick={this.handleSubmit}
                width={'120px'}
                height={'30px'}
                disabled={false}
                isAnimation={true}
                isLoading={this.state.isDialogSupportAnimeBtn}
                titleChanging={'Send'}
                titleSuccess={'Sent'}
              />
              <ButtonView
                title={'Cancel'}
                handleClick={this.close}
                width={'120px'}
                height={'30px'}
                disabled={false}
                className={classes.cancelButton}
              />
            </div>
          </form>
        </PopUpView>
      </>
    );
  }
}

const mapStateToProps = ({ projectReducer, settingsReducer, requestReducer }) => ({
  projectCompanies: projectReducer.projectInfo.parties,
  allCompanies: settingsReducer.members.data,
  parties_status: projectReducer.projectInfo.parties_status,
  allDisciplines: projectReducer.allDisciplines,
  statusBug: requestReducer.statusBug,
  filesData: settingsReducer.files,
  sandBugReportErrors: requestReducer.sandBugReportErrors,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDisciplines,
      updateLinkParty,
      sendBugReport,
      filesUpload,
      deleteFile,
      filesUploadClean,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(DialogSupport)));
