import styles from './ResetButton.scss';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export default class ResetButton extends React.Component {
  render() {
    const { title, handleClick, height, width, disabled, tooltip, style, id } = this.props;
    return (
      <div className={styles.ResetButton}>
        <Tooltip disableFocusListener title={tooltip ? tooltip : ''}>
          <div className={`${styles.holderWrapButton} ${styles.resetButton}`}>
            <Button
              id={id || `resetBtn-${Math.ceil(Math.random() * 1000)}`}
              className={`${styles.button} ${disabled ? styles.disabled : ''}`}
              onClick={!disabled ? handleClick : () => {}}
              style={{
                marginTop: '0px',
                padding: '4px 0px',
                width: width ? width : '100px',
                height: height ? height : '20px',
                borderRadius: '4px',
                cursor: disabled ? 'inherit' : 'pointer',
                ...style,
              }}
            >
              <span>{title}</span>
            </Button>
          </div>
        </Tooltip>
      </div>
    );
  }
}
