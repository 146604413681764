import React, { useMemo, RefObject, MutableRefObject } from 'react';
import { SandBoxTabModel } from '../../../../../models/sandBox/sandBoxArhitecture';
import RequestsListTable from './parts/RequestsListTable/RequestsListTable';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import { KeyValueModel } from '../../../../../models/key-value.model';
import { SandBoxNfSnippetModel } from '../../../../../store/request/requestReducer.model';
import { SubmittalModel } from '../../../../../models/submittals/submittals.model';
import { UserModel } from '../../../../../models';
import { TabsBar } from '../../../../controls/TabsBarParts/TabsBar';
import ContentSwitcher from '../../../../controls/ButtonComponents/ContentSwitcher/ContentSwitcher';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';

import './RequestListStyles.scss';

interface IProps {
  activeSection: SandBoxTabModel;
  selectedSubSection: string;
  selectSubSection: (tabName: string) => void;
  changeAccordionCollapse: () => void;
  isAllAccCollapsed: boolean;
  changeNfView: () => void;
  isShortView: boolean;
  setIsAllAccCollapsed: (state: boolean) => void;
  filterHeight: number;
  openRequest: (id: number, nf: number, accordionAlias: string, isMarked: boolean) => void;
  openDeliverable: (id: number, buildingId: number, accordionAlias?: string, isMarked?: boolean) => void;
  openSubmittal: (id: string, accordionAlias?: string, isMarked?: boolean) => void;
  accordionStates: KeyValueModel<boolean> | null;
  accordionRefs: KeyValueModel<RefObject<HTMLElement>>;
  handleAccordion: (alias: string) => void;
  handleDeactivationPopUp: (
    snippetData: SandBoxNfSnippetModel | SubmittalModel,
    action: 'activate' | 'deactivate',
    accordion: string,
  ) => void;
  handleDeleteDraftPopUp: (nf: SandBoxNfSnippetModel, accordion) => void;
  relatedTo: string;
  openedFrom: string;
  report_user_id: number;
  activeSectionType: 'request' | 'deliverable';
  userInfo: UserModel;
  sizeResolution: string;
  is1100Resolution: boolean;
  isTablet: boolean;
  isRightBtn: boolean;
  handleRightBtn: (isVisible: boolean) => void;
  sizeResolutionSnippet: string;
  isMobile?: boolean;
  pageHeaderRef: MutableRefObject<HTMLDivElement>;
  isCollapsedBtn: boolean;
}

const stylesForLoader = {
  root: {
    height: '1px !important',
    maxHeight: '1px !important',
    margin: '0 !important',
    padding: '0 !important',
    width: '100% !important',
  },
  colorPrimary: {
    backgroundColor: 'var(--sandbox-table-borderColor) !important',
  },
};

const RequestsList = ({
  activeSection,
  selectedSubSection,
  selectSubSection,
  changeAccordionCollapse,
  setIsAllAccCollapsed,
  isAllAccCollapsed,
  changeNfView,
  isShortView,
  filterHeight,
  openRequest,
  openDeliverable,
  openSubmittal,
  accordionStates,
  accordionRefs,
  handleAccordion,
  handleDeactivationPopUp,
  handleDeleteDraftPopUp,
  relatedTo,
  openedFrom,
  report_user_id,
  activeSectionType,
  userInfo,
  sizeResolution,
  is1100Resolution,
  isTablet,
  isRightBtn,
  sizeResolutionSnippet,
  isMobile,
  pageHeaderRef,
  isCollapsedBtn,
}: IProps) => {
  const sendBoxDataLoading = useAppSelector(state => state.requestReducer.sendBoxDataLoading);
  const sandBoxCountersLoading = useAppSelector(state => state.requestReducer.sandBoxCountersLoading);
  const sandBoxCounters = useAppSelector(state => state.requestReducer.sandBox.counters);

  const themeClass = useGetThemeClass('b-sandBox-requestsList');

  const subSections = useMemo(() => {
    if (activeSection) {
      return activeSection.subSections;
    }

    return [];
  }, [activeSection]);

  const collapseButtonTitle = useMemo(() => {
    return isAllAccCollapsed ? 'Open all' : 'Collapse all';
  }, [isAllAccCollapsed]);

  const nfViewIcon = useMemo(() => {
    return isShortView ? <ContentSwitcher type={'column-relaxed'} size={'md'} /> : <ContentSwitcher type={'column-tight'} size={'md'} />;
  }, [isShortView]);

  const handleChangeTabBar = (event: React.SyntheticEvent, tabName: string) => {
    selectSubSection(tabName);
  };

  const getWidth = width => {
    if (openedFrom === 'report') {
      if (width > 959) {
        return { width: window.innerWidth - 600 };
      } else if (width < 730 && width > 640) {
        return { width: 420 };
      } else if (width === 640) {
        return { width: 350 };
      } else {
        return { width: 550 };
      }
    }
    if (isCollapsedBtn) {
      if (width === 1440) {
        return { width: 490 };
      }
      if (width >= 1300 && width < 1440) {
        return { width: window.innerWidth - 450 };
      }
      if (width >= 1000 && width < 1300) {
        return { width: window.innerWidth - 400 };
      }
      if (width < 1000) {
        return { width: window.innerWidth - 330 };
      } else {
        return { width: window.innerWidth - 1000 };
      }
    }
    if (width === 1600) {
      return { width: 450 };
    }
    if (width === 1440) {
      return { width: 420 };
    }
    if (width <= 960) {
      return {};
    }

    if (width > 1920) {
      return { width: '82%' };
    } else if (width > 1750 && width <= 1920) {
      return { width: window.innerWidth - 1160 };
    } else if (width > 1600 && width < 1750) {
      return { width: window.innerWidth - 1130 };
    } else if (width > 1550 && width < 1600) {
      return { width: window.innerWidth - 1210 };
    } else if (width > 1440 && width < 1550) {
      return { width: window.innerWidth - 1170 };
    } else if (width > 1025 && width < 1440) {
      return { width: window.innerWidth - 620 };
    } else {
      return { width: 550 };
    }
  };
  const widthBar = getWidth(window.innerWidth);

  return (
    <div
      id={'sandBoxRequestsList-tourBlock'}
      className={cn(`${themeClass} b-sandBox-requestsList`, {
        ['-report']: openedFrom === 'report',
        ['-allContainer']: !isRightBtn && openedFrom !== 'report' && !isCollapsedBtn,
        ['-allContainerCollapsed']: isCollapsedBtn,
      })}
      //style={{ width: '100%' }}
    >
      <div
        id={'sandBoxRequestsListHeader-tourBlock'}
        className={`b-sandBox-requestsList__headerContainer ${themeClass}__headerContainer ${isMobile ? '-mobile' : ''}`}
      >
        {subSections.length ? (
          <div
            className={cn('b-sandBox-requestsList__headerContainer__maxTabs', {
              ['-isCollapsedBtn']: isCollapsedBtn,
              ['-report']: openedFrom === 'report',
            })}
            style={widthBar}
          >
            <TabsBar
              relatedTo={relatedTo}
              sections={subSections}
              selectedTab={selectedSubSection}
              handleChange={handleChangeTabBar}
              activeSectionType={activeSectionType}
              isCountersLoading={!sandBoxCounters && sandBoxCountersLoading.loading}
              isMobile={isMobile}
              openedFrom={openedFrom}
            />
          </div>
        ) : null}
        <div className={'b-sandBox-requestsList__contentButtons'}>
          {isMobile ? (
            collapseButtonTitle === 'Collapse all' ? (
              <SystemButton type={'collapse-mobile'} size={'md'} variant={'transparent'} isMobile clickHandler={changeAccordionCollapse} />
            ) : (
              <SystemButton type={'expand-mobile'} size={'md'} variant={'transparent'} isMobile clickHandler={changeAccordionCollapse} />
            )
          ) : (
            <CustomButton
              type={'text-plain'}
              size={isMobile ? 'md' : 'xs'}
              clickHandler={changeAccordionCollapse}
              title={collapseButtonTitle}
            />
          )}
          {(sizeResolution === '1024' && !isRightBtn && window.innerWidth >= 1150) ||
          (sizeResolution === '1440' && (!isRightBtn || window.innerWidth >= 1700)) ||
          sizeResolution === '1920' ? (
            isShortView ? (
              <ContentSwitcher type={'column-relaxed'} size={'md'} clickHandler={changeNfView} />
            ) : (
              <ContentSwitcher type={'column-tight'} size={'md'} clickHandler={changeNfView} />
            )
          ) : null}
        </div>
      </div>
      <RequestsListTable
        isShortView={isShortView}
        filterHeight={filterHeight}
        openRequest={openRequest}
        openDeliverable={openDeliverable}
        openSubmittal={openSubmittal}
        accordionStates={accordionStates}
        accordionRefs={accordionRefs}
        handleAccordion={handleAccordion}
        handleDeactivationPopUp={handleDeactivationPopUp}
        handleDeleteDraftPopUp={handleDeleteDraftPopUp}
        openedFrom={openedFrom}
        report_user_id={report_user_id}
        userInfo={userInfo}
        sizeResolution={sizeResolution}
        is1100Resolution={is1100Resolution}
        isTablet={isTablet}
        isRightBtn={isRightBtn}
        sizeResolutionSnippet={sizeResolutionSnippet}
        isMobile={isMobile}
        pageHeaderRef={pageHeaderRef}
        relatedTo={relatedTo}
      />
    </div>
  );
};

export default RequestsList;
