import React, { Component } from 'react';
import CustomPopover from '../CustomPopover/CustomPopover';
import TruncateMarkup from 'react-truncate-markup';
import { ResetButton } from '../index';

class ShowMoreMarkup extends Component<any, any> {
  state = {
    expanded: false,
    truncated: false,
  };

  handleTruncate = truncated => {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
      });
    }
  };

  toggleLines = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  closeShowMore = () => {
    this.setState({
      expanded: false,
    });
  };

  render() {
    const { children, lines, title, refComponent, styles } = this.props;

    const { expanded } = this.state;

    return (
      <div style={{ paddingRight: '25px' }}>
        <TruncateMarkup
          lines={lines || 3}
          ellipsis={<ResetButton title={'All filters'} handleClick={this.toggleLines} height={12} style={{ marginLeft: '10px' }} />}
          onTruncate={this.handleTruncate}
        >
          {children}
        </TruncateMarkup>
        <CustomPopover
          open={expanded}
          onClose={this.closeShowMore}
          title={title || 'Show more'}
          anchorEl={refComponent.current}
          styles={styles}
          hideTitle
        >
          <div style={{ width: '500px', overflowWrap: 'anywhere' }}>{children}</div>
        </CustomPopover>
      </div>
    );
  }
}

export default ShowMoreMarkup;
