import React, { FC, useEffect, useState } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { Logo, TextInput } from '../../controls';
import { isLoggedIn } from '../../../service/auth';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../navigation/routes';
import { CustomButton, SystemButton } from '../../controls/ButtonComponents';
import MenuItem from '@material-ui/core/MenuItem';
import { usePermissions } from '../../../hooks/usePermissions/usePermissions';
import EditCompanyPopup from '../../common/EditCompanyPopup/EditCompanyPopup';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import PlaceholderInfo from '../../controls/PlaceholderInfo/PlaceholderInfo';
import { getProjectDetails } from '../../../store/project/projectLogic';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { teamSlice } from '../../../store/team/teamSlice';
import { userSlice } from '../../../store/user/userSlice';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { filterSlice } from '../../../store/filters/filtersReducer';
import { commonSlice } from '../../../store/common/commonReducer';
import { reportingSlice } from '../../../store/reporting/reportingSlice';
import { documentsSlice } from '../../../store/documents/documentsReducer';
import { SpecAndSubsSlice } from '../../../store/specAndSubs/specAndSubsSlice';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './NonLoginedPageStyles.scss';

const { clearTeamState } = teamSlice.actions;
const { setActiveProject, clearMessage, setToDefault } = userSlice.actions;
const { clearNeedListData, clearNeedListFilters } = requestSlice.actions;
const { hardResetFiltersReducer } = filterSlice.actions;
const { clearRequestsFilters } = commonSlice.actions;
const { resetReportingFirstLoading } = reportingSlice.actions;
const { resetDocumentsFirstMainLoading } = documentsSlice.actions;
const { clearSubmittalsData, clearSpecificationData } = SpecAndSubsSlice.actions;
const { changeActiveProject } = requestSlice.actions;

interface IProps {
  handleChangeField: (type: 'email' | 'password') => (value: string) => void;
  user: any;
  handleLogin: () => void;
  handleLogout: () => void;
  selectClient: (value: number) => void;
  loading: boolean;
  clientProjectsLoading: boolean;
  message: string;
  status: string | number | boolean;
  clients: any;
  user_data: any;
  isClientReviewEmail: boolean;
  theme: string;
  handleUserShort: (data: { withoutUserData: boolean }) => void;
}

const LoginPageViewNew: FC<IProps> = ({
  handleChangeField,
  user,
  handleLogin,
  handleLogout,
  selectClient,
  loading,
  clientProjectsLoading,
  message,
  status,
  clients,
  user_data,
  isClientReviewEmail,
  theme,
  handleUserShort,
}) => {
  const dispatch = useAppDispatch();
  const { isSuperAdmin } = usePermissions();
  const { setUrlChangeCompany, getIsChangeProject } = useRoutingHelper();
  const { navigate } = useRouter();
  const themeClass = useGetThemeClass('b-authorization-loginPage');
  const { isMobile } = GetResolution();

  const [isEditPopup, setIsEditPopup] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);

  const selected_client_id = useAppSelector(state => state.userReducer.selected_client_id);
  const clientProjects = useAppSelector(state => state.userReducer.clientProjects);

  const selectedClient = clients?.find(client => client.id === selected_client_id);
  const isChangeProject = getIsChangeProject();
  const isAccess = isSuperAdmin;
  const menuItems = isChangeProject ? clientProjects || [] : clients;

  const [loginToken, setLoginToken] = useState('');

  const handleStorageChange = event => {
    if (event.key === 'token') {
      setLoginToken(event.newValue);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isChangeProject && !clients?.length && !clientProjectsLoading) {
      setUrlChangeCompany();
    }
  }, [isChangeProject, clientProjectsLoading, clients]);

  useEffect(() => {
    if (loginToken) {
      handleUserShort({ withoutUserData: true });
    }
  }, [loginToken]);

  useEffect(() => {
    if (!isLoggedIn() && user_data?.userInfo?.id && user_data?.userInfo?.id !== 0) {
      dispatch(setToDefault());
    }
  }, [user_data]);

  const getTheme = (): 'light' | 'dark' => {
    return theme || localStorage.getItem('theme') || ('light' as any);
  };

  const handleOpenEditCompany = (e: Event, companyId: boolean) => {
    e.stopPropagation();
    setEditingCompany(clients.find(client => client.id === companyId));
    setIsEditPopup(true);
  };

  const handleCloseEditCompany = () => {
    setEditingCompany(null);
    setIsEditPopup(false);
  };

  const handleChangeCompany = () => {
    setUrlChangeCompany();
  };

  const selectProject = value => {
    dispatch(clearNeedListData());
    dispatch(clearSubmittalsData());
    dispatch(clearSpecificationData());
    dispatch(clearTeamState());
    dispatch(setActiveProject(value));
    dispatch(hardResetFiltersReducer());
    dispatch(changeActiveProject(value));
    dispatch(getProjectDetails(value));
    dispatch(getProjectById(value));
    dispatch(clearNeedListFilters());
    dispatch(clearRequestsFilters());
    dispatch(resetReportingFirstLoading());
    dispatch(resetDocumentsFirstMainLoading());

    const code = clientProjects.find(project => project.id === value)?.code;

    navigate(`/${code}`);
  };

  return (
    <div className={`${themeClass}_container`}>
      <div className={cn(`${themeClass}_topInfo`, { ['-logo']: !(!isClientReviewEmail && clients && clients.length) })}>
        <div className={`${themeClass}_logoContainer`}>
          <Logo isFull themeProp={getTheme()} />
          <p className={`${themeClass}_logoContainer__header`}>Plan & Control App</p>
        </div>
        {!isLoggedIn() && !!status && <span className={`${themeClass}_loginError`}>{message}</span>}
        <div className={cn(`${themeClass}_form`, { ['-logo']: !(!isClientReviewEmail && clients && clients.length) })}>
          {isLoggedIn() ? (
            <div className={`${themeClass}_signedInContainer`}>
              <div className={`${themeClass}_signedInContainer_title`}>Welcome back, {user_data.userInfo.first_name}!</div>
              <div className={`${themeClass}_signedInContainer_subTitle`}>
                You signed in as <b>{user_data.userInfo.email}</b>
              </div>
            </div>
          ) : (
            <TextInput
              label="Email"
              value={isLoggedIn() ? user_data.userInfo.email : user.email}
              onChange={handleChangeField('email')}
              type="login"
              errorMsg={''}
              logout={handleLogout}
              disabled={!!isLoggedIn()}
              handleLogin={handleLogin}
              theme={getTheme()}
            />
          )}

          {!isLoggedIn() ? (
            <>
              <div className={`${themeClass}_passwordBlock`}>
                <TextInput
                  label="Password"
                  value={user.password}
                  onChange={handleChangeField('password')}
                  type="password"
                  errorMsg=""
                  handleLogin={handleLogin}
                  theme={getTheme()}
                />
                {!isMobile && (
                  <div
                    className={`${themeClass}_forgotPassword`}
                    onClick={() => {
                      dispatch(clearMessage);
                    }}
                  >
                    <Link to={ROUTES.forgotPassword}>
                      <CustomButton type={'text-plain'} size={'xs'} title={'Forgot password?'} />
                    </Link>
                  </div>
                )}
                <div className={`${themeClass}_loginBtn`}>
                  <CustomButton
                    type={'primary'}
                    size={'md'}
                    title={'Sign in'}
                    loading={loading}
                    disabled={loading || !user.password || isLoggedIn() ? !user_data.userInfo.email : !user.email}
                    clickHandler={handleLogin}
                    isMobile={isMobile}
                  />
                </div>
                {isMobile && (
                  <div className={`${themeClass}_forgotPassword`} onClick={clearMessage}>
                    <Link to={ROUTES.forgotPassword}>
                      <CustomButton type={'text-plain'} size={'xs'} isMobile={isMobile} title={'Forgot password?'} />
                    </Link>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {!isClientReviewEmail && !!clients && !!clients.length && (
                <>
                  {isChangeProject && selectedClient && (
                    <div className={`${themeClass}_companyRow`}>
                      <div className={`${themeClass}_companyRow_left`}>
                        <label className={`${themeClass}_textLabel`}>Company</label>
                        <PlaceholderInfo
                          size={24}
                          type={'Company'}
                          tooltip={selectedClient.title}
                          detailInfo={selectedClient.title}
                          imagePath={selectedClient.renderings?.[0]?.file}
                          isMobile={isMobile}
                        />
                      </div>
                      <CustomButton
                        type={'text-plain'}
                        size={'xs'}
                        title={'Change'}
                        clickHandler={handleChangeCompany}
                        isMobile={isMobile}
                      />
                    </div>
                  )}
                  <div className={`${themeClass}_headRow`}>
                    <label className={`${themeClass}_textLabel`}>{isChangeProject ? 'Select project' : 'Choose the company'}</label>
                    {isAccess && !isChangeProject && !isMobile && (
                      <SystemButton
                        type={'plus'}
                        size={'md'}
                        variant={'transparent'}
                        clickHandler={e => {
                          handleOpenEditCompany(e, null);
                        }}
                      />
                    )}
                  </div>
                  <ul className={cn(`${themeClass}_clientsList`, { ['-projectSelect']: isChangeProject })}>
                    {menuItems.map(({ id, title, status }) => {
                      return (
                        <MenuItem
                          key={`company-${id}`}
                          className={`${themeClass}_menuItemClients menuItemClients clientsList clients__popover__menuItem`}
                          value={id}
                          onClick={e => (isChangeProject ? selectProject(+e.currentTarget.value) : selectClient(+e.currentTarget.value))}
                          disableRipple
                          disabled={clientProjectsLoading}
                        >
                          <div className={`${themeClass}_menuItem aside__selectOnLoginPage__clientName`}>
                            <em className={status === 'archived' ? `${themeClass}_menuItem_archivedTitle -whiteSpace` : ''}>{title}</em>
                            {status === 'archived' && (
                              <em className={status === 'archived' ? `${themeClass}_menuItem_archivedTitle` : ''}>Archived</em>
                            )}
                            {isAccess && !isChangeProject && !isMobile && (
                              <SystemButton
                                customClass={`${themeClass}_menuItemClients_edit`}
                                type={'settings'}
                                size={'md'}
                                variant={'transparent'}
                                clickHandler={e => {
                                  handleOpenEditCompany(e, id);
                                }}
                              />
                            )}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </ul>
                  <span className={`${themeClass}_signOut`} onClick={handleLogout}>
                    <CustomButton type={'text-plain'} size={'xs'} title={'Sign out'} clickHandler={handleLogout} isMobile={isMobile} />
                  </span>
                </>
              )}
              <EditCompanyPopup isOpen={isEditPopup} onClose={handleCloseEditCompany} editingCompany={editingCompany} />
            </>
          )}
        </div>
      </div>
      <div className={`${themeClass}_bottomInfo`}>
        <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
      </div>
    </div>
  );
};

export default LoginPageViewNew;
