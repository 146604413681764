import { createSlice } from '@reduxjs/toolkit';
import { IIndexedDbSliceModel } from './indexedDbSlice.model';

const initialState: IIndexedDbSliceModel = {
  isDbCreated: false,
  error: null,
  thisDb: null,
};

export const indexedDbSlice = createSlice({
  name: 'indexedDb',
  initialState,
  reducers: {
    setField(state, { payload }) {
      const { field, value } = payload;
      state[field] = value;
    },
  },
});

export default indexedDbSlice.reducer;
