import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import React, { Component, RefObject } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FileModel, RequestModel, UserModel } from '../../../models';
import commonApi from '../../../service/Api/commonApi';
import convert from 'xml-js';
import { WEB_SOCKET_DOMAIN } from '../../../service/links';
import Socket from 'socket.io-client';
import PopUpView from '../../controls/PopUp/PopUpView';
import { bindActionCreators } from 'redux';
import PrevFileHeader from '../PrevFilePopover/parts/PrevFileHeader/PrevFileHeader';
import Draggable from 'react-draggable';
import { PCDApi } from '../../../service/Api/pcd/types';
import { Resizable } from 'react-resizable';
import ButtonView from '../../controls/ButtonComponents/ButtonView/ButtonView';
import Checkbox from '@material-ui/core/Checkbox';
import LinearDeterminate from '../../controls/LinearDeterminate/LinearDeterminate';
import PDFTronGenerateStylesForWebpack from './PDFTronGenerateStylesForWebpack';
import { commonSlice } from '../../../store/common/commonReducer';
import { responseSave } from '../../../store/request/requestLogic(HOLD)';
import { setPromptsAlerts } from '../../../store/user/userThunk';
import { getHistory } from '../../../store/history/historyThunk';
import { useFileAsResponse } from '../../../store/globalSettings/settingsThunk';
import AccessHelper from '../../../helpers/AccessHelper';
import { pcdHistoryRecordSend } from '../../../store/pcd/pcdThunk';
import Timeout = NodeJS.Timeout;
import PCDCard = PCDApi.PCDCard;
import { handleGetThemeClass } from '../../../helpers/designTokens';
import { RouteComponentProps, withRouter } from '../../../navigation/withRouter/withRouter';
import { RouterHelperComponentProps, withRouterHelper } from '../../../navigation/withRouterHelper/withRouterHelper';

import './PDFTronOptimizedStyles.scss';

const serializer = new XMLSerializer();

interface SaveXMLModel {
  annotation_id: string;
  annots: string;
  action: 'add' | 'modify' | 'delete';
}

export interface PdfTronData {
  file: {
    file: FileModel;
  };
  selectedRequest: RequestModel | PCDCard;
  isPCD?: boolean;
  isComment?: boolean;
  isReview?: boolean;
  isRequest?: boolean;
  isDeliverable?: boolean;
  isSubmittal?: boolean;
  isAccess: boolean;
  isThumbnailView?: boolean;
  openFromRequest?: boolean;
}

interface IProps extends RouteComponentProps, RouterHelperComponentProps {
  userInfo: UserModel;
  pdftronData: PdfTronData;
  // actions
  closePdftron: () => void;
  openPdftron: (pdfTronData: PdfTronData) => void;
  responseSave: (data: any) => void;
  getHistory: ({ owner_id: number }) => void;
  setPromptsAlerts: (data: string) => void;
  useFileAsResponse: ({ id: number, is_annotation: boolean, callback: any }) => void;
  fileLoading: boolean;
  pcdHistoryRecordSend: ({ type, deliverableId, fileId }: PCDApi.PCDHistoryRecordInterface) => boolean;
}

interface IState {
  isModalOpen: boolean;
  instance: WebViewerInstance | null;
  socketPDF: Socket | null;
  isNewFileCopying: boolean;
  finishLoadLib: boolean;
  isLoading: boolean;
  width: number;
  promptName: string;
  promptTitle: string;
  promptMessage: string;
  is_promptPopUpView: boolean;
  is_promptBtnOk: boolean;
  is_msg_not_show_again: boolean;
  is_resizable_pdfTron: boolean;
  isOpenPopup: boolean;
  popupWasOpened: boolean;
  holdData: any;
}

class PdfTronOptimized extends Component<IProps, IState> {
  viewerEl: RefObject<HTMLDivElement>;
  instance: WebViewerInstance;
  socketPDF = null;

  constructor(props) {
    super(props);
    this.viewerEl = React.createRef();
  }

  state = {
    isModalOpen: false,
    instance: null,
    socketPDF: null,
    isNewFileCopying: false,
    finishLoadLib: false,
    isLoading: false,
    width: 95,
    promptName: null,
    promptTitle: null,
    promptMessage: null,
    is_promptPopUpView: false,
    is_promptBtnOk: false,
    is_msg_not_show_again: false,
    is_resizable_pdfTron: false,
    isOpenPopup: false,
    popupWasOpened: false,
    holdData: null,
  };

  initDownloadButton = (UI: any, dataElement: string, isEmpty: boolean, Core: any) => {
    return {
      type: 'actionButton',
      img: 'icon-header-download',
      title: 'Download',
      label: window.innerWidth < 767 || isEmpty ? '' : 'Download',
      className: `pdfTron__download`,
      style: { width: !isEmpty ? '78px' : '' },
      onClick: async () => {
        const { pdftronData, pcdHistoryRecordSend } = this.props;
        if (this.props.pdftronData) {
          const { project_id } = this.props.routerHelper.getNfParamsFromURL();
          const { selectedRequest, file } = pdftronData;
          const titleArray = file.title.split('.');
          titleArray.pop();
          titleArray.join('.');
          const fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_${titleArray.join('.')}`;
          const { annotationManager } = Core;
          const xfdfString = await annotationManager.exportAnnotations();

          UI.downloadPdf({
            filename: fileName,
            xfdfString,
            flags: Core.SaveOptions.LINEARIZED,
          });
          if (!selectedRequest.nf) {
            pcdHistoryRecordSend && pcdHistoryRecordSend({ type: 'downloadedTron', deliverableId: selectedRequest.id, fileId: file.id });
          }
        }
      },
      dataElement: dataElement,
    };
  };

  componentDidMount() {
    WebViewer(
      {
        path: '/webviewer_8_9_0',
        licenseKey:
          'Planit Inc. (planit-inc.com):OEM:Planit Scheduler::B+:AMS(20230909):1FB5C6420497F60AF360B13AC9A2537860616F0CC7102ABAD5140B631CBF6EAE029A31F5C7',
        disabledElements: ['thumbnailControl', 'documentControl', 'toolbarGroup-Edit', 'freeHandToolGroupButton', 'downloadButton'],
        css: '/css/pdfTronStyles.css',
        enableMeasurement: true,
      },
      this.viewerEl.current as HTMLElement,
    ).then((instance: WebViewerInstance) => {
      const { UI, Core } = instance;
      UI.setTheme(this.props.userInfo.theme || localStorage.getItem('theme'));
      this.instance = instance;
      UI.setHeaderItems(header => {
        header.getHeader('default').get('searchButton').insertBefore(this.initDownloadButton(UI, 'downloadHeaderButton', false, Core));
        header.getHeader('default').get('searchButton').insertBefore(this.initDownloadButton(UI, 'downloadHeaderButton-empty', true, Core));
        this.setState({
          finishLoadLib: true,
        });
      });
      if (window.innerWidth < 430) {
        UI.disableElements(['downloadHeaderButton-empty', 'toolbarGroup-Forms', 'themeChangeButton', 'ribbons']);
      } else {
        UI.disableElements(['downloadHeaderButton-empty', 'toolbarGroup-Forms', 'themeChangeButton']);
      }
    });
  }

  socketTimer: Timeout;
  async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (prevProps.userInfo.theme && prevProps.userInfo.theme !== this.props.userInfo.theme) {
      const { UI } = this.instance;
      UI.setTheme(this.props.userInfo.theme);
    }
    if (
      (this.props.pdftronData && this.instance && prevProps.pdftronData !== this.props.pdftronData) ||
      (this.props.pdftronData && this.state.finishLoadLib !== prevState.finishLoadLib)
    ) {
      const {
        UI: { loadDocument, disableElements },
        Core: { documentViewer, annotationManager },
      } = this.instance;

      const {
        userInfo,
        pdftronData: { file, selectedRequest, isDeliverable },
      } = this.props;

      const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles || {}).filter(key => key === '4').length;
      loadDocument(file.file);

      const isAccepted = selectedRequest?.lastUserStatus?.status === 'accepted' || (isDeliverable && !selectedRequest.is_whiteboard);

      if (isAccepted) {
        annotationManager.enableReadOnlyMode();
      } else {
        annotationManager.disableReadOnlyMode();
      }
      this.setState(prevState => ({
        ...prevState,
        isModalOpen: true,
      }));

      this.socketPDF = new Socket(WEB_SOCKET_DOMAIN, {
        query: {
          token: 'Bearer ' + localStorage.getItem('token'),
          channel: `pdftron_channel`,
        },
      });

      this.socketPDF.on(`pdfTronNotification_${file.id}`, async ({ data }) => {
        if (data.user_id === userInfo.id) {
          return;
        }

        data.annots.map(async annot => {
          const annotation = convert.json2xml(annot.annots, {
            compact: true,
            ignoreComment: true,
            spaces: 0,
          });
          const annotations = await annotationManager.importAnnotCommand(annotation);
          await annotationManager.drawAnnotationsFromList(annotations);
        });
      });

      annotationManager.setCurrentUser(`${userInfo.first_name} ${userInfo.last_name}`);

      if (isAdmin) {
        annotationManager.promoteUserToAdmin();
      } else {
        annotationManager.demoteUserFromAdmin();
      }

      documentViewer.addEventListener(
        'keyDown',
        async ({ key }) => {
          if (key === 'Escape') {
            this.props.routerHelper.removeParams();
            this.handleClose();
          }
        },
        { once: true },
      );

      documentViewer.addEventListener(
        'documentLoaded',
        async () => {
          this.setState({
            isLoading: true,
          });
          annotationManager.setCustomAppearanceHandler(user => {
            return user;
          });
        },
        { once: true },
      );

      documentViewer.addEventListener(
        'annotationsLoaded',
        async () => {
          const pdfTronAnnot = await commonApi.importPDFTronAnnot(file.id, selectedRequest.id);
          const jsonToXml = convert.json2xml(pdfTronAnnot.data.xml, {
            compact: true,
            ignoreComment: true,
            spaces: 0,
          });

          await annotationManager.importAnnotations(jsonToXml);
          this.setState({
            isLoading: false,
          });
        },
        { once: true },
      );

      annotationManager.addEventListener('annotationChanged', this.handleAnnotationChanged);

      // Method for setting permission
      // annotationManager.setPermissionCheckCallback((author, annotation) => { });
    }
    if (this.state.isModalOpen && this.state.isModalOpen !== prevState.isModalOpen) {
      const nfParams = this.props.routerHelper.getNfParamsFromURL();
      const pcdParams = this.props.routerHelper.getPCDParamsFromURL();
      const submittalParams = this.props.routerHelper.getSubmittalParamsFromURL();
      if (nfParams.nf || pcdParams.pcdId || submittalParams.submittalId) {
        this.setState({
          width: localStorage.getItem('resizableCard') === 'small' ? 40 : 95,
          is_resizable_pdfTron: localStorage.getItem('resizableCard') === 'small' ? true : false,
        });
      }
    }

    const prevNfParam = new URLSearchParams(prevProps.location.search).get('nf');
    const newNfParam = new URLSearchParams(location.search).get('nf');

    const prevPcdParam = new URLSearchParams(prevProps.location.search).get('deliverable');
    const newPcdParam = new URLSearchParams(location.search).get('deliverable');

    const prevSubmittalParam = new URLSearchParams(prevProps.location.search).get('submittal');
    const newSubmittalParam = new URLSearchParams(location.search).get('submittal');

    if (
      (!newNfParam && newNfParam !== prevNfParam) ||
      (!newPcdParam && newPcdParam !== prevPcdParam) ||
      (!newSubmittalParam && newSubmittalParam !== prevSubmittalParam)
    ) {
      this.handleClose();
    }
  }

  isUserInResponseParty = () => {
    const { pdftronData, userInfo } = this.props;
    return pdftronData?.isRequest && AccessHelper.isUserExistsInResponseParty(userInfo, pdftronData?.selectedRequest);
  };

  isCopiedToResponse = () => {
    const { pdftronData } = this.props;
    return pdftronData?.isRequest && pdftronData.selectedRequest?.response_files.map(el => el.parent_id).includes(pdftronData.file.id);
  };

  handleAnnotationChanged = async (annotations, action, info) => {
    const { annotationManager } = this.instance.Core;
    // If annotation change is from import, return
    if (info.imported || this.state.isOpenPopup) {
      return;
    }
    const xfdfStringCommand = await annotationManager.exportAnnotCommand();
    const parser = new DOMParser();
    const commandData = parser.parseFromString(xfdfStringCommand, 'text/xml');
    const addedAnnots = commandData.getElementsByTagName('add')[0];
    const modifiedAnnots = commandData.getElementsByTagName('modify')[0];
    const deletedAnnots = commandData.getElementsByTagName('delete')[0];

    const body: SaveXMLModel[] = [];
    // List of added annotations
    addedAnnots.childNodes.forEach(child => {
      const data = this.collectAnnotation(child, 'add', info);
      if (data) {
        body.push(data);
      }
    });

    // List of modified annotations
    modifiedAnnots.childNodes.forEach(child => {
      const data = this.collectAnnotation(child, 'modify', info);
      if (data) {
        body.push(data);
      }
    });

    // List of deleted annotations
    deletedAnnots.childNodes.forEach(child => {
      const data = this.collectAnnotation(child, 'delete', info);
      if (data) {
        body.push(data);
      }
    });

    this.sendAnnotationChange(body);
  };

  convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    if (action === 'add') {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
    } else if (action === 'modify') {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
    } else if (action === 'delete') {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
    }
    xfdfString += `</xfdf>`;
    return xfdfString;
  };

  collectAnnotation = (annotation, action, info): SaveXMLModel | null => {
    if (annotation.nodeType !== annotation.TEXT_NODE) {
      const annotationString = serializer.serializeToString(annotation);
      const data: SaveXMLModel = {
        annotation_id: annotation.getAttribute('name'),
        annots: JSON.parse(
          convert.xml2json(this.convertToXfdf(annotationString, action), {
            compact: true,
            spaces: 0,
          }),
        ),
        action: action,
      };

      if (this.isUserInResponseParty() && !this.isCopiedToResponse()) {
        this.setState({
          holdData: data,
        });

        if (!this.state.popupWasOpened && this.props.pdftronData.selectedRequest.status !== 'drafted') {
          if (info.isUndoRedo) {
            return;
          }
          this.setState({
            isOpenPopup: true,
            popupWasOpened: true,
            holdData: data,
          });
          return;
        }
      }

      return data;
    }
  };

  sendAnnotationChange = async (data: SaveXMLModel[]) => {
    const {
      pdftronData: { file },
    } = this.props;

    if (!data?.length) {
      return;
    }

    const body = {
      file_id: file.id,
      annots: data,
    };

    await commonApi.pdfAnnotAction(body);
  };

  closePopup = async () => {
    this.state.holdData && (await this.sendAnnotationChange([this.state.holdData]));
    this.setState({
      isOpenPopup: false,
      holdData: null,
    });
  };

  handleClose = () => {
    const { closePdftron } = this.props;

    if (this.socketPDF) {
      this.socketPDF.close();
      this.socketPDF = null;
    }
    this.setState({
      isModalOpen: false,
      isOpenPopup: false,
      popupWasOpened: false,
      holdData: null,
    });

    closePdftron();
  };

  handleUseFileAsResponse = () => {
    const { pdftronData, openPdftron } = this.props;

    this.props.useFileAsResponse({
      id: pdftronData.file.id,
      is_annotation: !!this.state.isOpenPopup,
      callback: async response => {
        openPdftron({
          file: response.data,
          isPCD: false,
          isSubmittal: false,
          isComment: false,
          isReview: false,
          isRequest: false,
          isDeliverable: true,
          selectedRequest: {
            ...pdftronData.selectedRequest,
            response_files: [...pdftronData.selectedRequest.response_files, response.data],
          },
          isAccess: pdftronData.isAccess,
          openFromRequest: true,
        });

        if (this.state.isOpenPopup) {
          const body = {
            file_id: response.data.id,
            annots: [this.state.holdData],
          };
          await commonApi.pdfAnnotAction(body);
        }

        this.setState({
          isOpenPopup: false,
          popupWasOpened: false,
          holdData: null,
        });
      },
    });
  };

  handleFinalDelivable = (id, is_final) => {
    const { responseSave, pdftronData } = this.props;

    const newData = {
      update_files: JSON.stringify([
        {
          id: id,
          is_final: is_final,
        },
      ]),
    };
    responseSave({
      requestId: pdftronData.selectedRequest.id,
      bodyParams: newData,
    });
  };

  onClick = e => {
    if (e.target.className === 'react-resizable-handle react-resizable-handle-nw') {
      if (this.state.width == 95) {
        this.instance.UI.disableElements(['downloadHeaderButton']);
        this.instance.UI.enableElements(['downloadHeaderButton-empty']);
        this.setState({ width: 55 });
      }
      if (this.state.width == 55) {
        this.instance.UI.disableElements(['downloadHeaderButton']);
        this.instance.UI.enableElements(['downloadHeaderButton-empty']);
        this.setState({ width: 40 });
      }
      if (this.state.width == 40) {
        this.instance.UI.disableElements(['downloadHeaderButton-empty']);
        this.instance.UI.enableElements(['downloadHeaderButton']);
        this.setState({ width: 95 });
      }
      this.setState({
        is_resizable_pdfTron: false,
      });
    }
  };
  onDrag = e => {
    return e.which !== 0;
  };

  changePromptBtn = () => {
    this.savePromptsAlerts(this.state.promptName);

    this.setState({
      is_promptBtnOk: true,
      is_promptPopUpView: false,
      promptTitle: null,
      promptMessage: null,
    });
  };

  openPromptStatus = (promptTitle, promptMessage, promptName) => {
    this.setState({
      is_promptBtnOk: false,
      is_promptPopUpView: localStorage.getItem(promptName) !== 'yes',
      promptTitle: promptTitle,
      promptMessage: promptMessage,
      promptName: promptName,
      is_msg_not_show_again: false,
    });

    if (localStorage.getItem(promptName) === 'yes') {
      setTimeout(() => this.changePromptBtn(), 400);
    }
  };

  cancelPromptStatus = () => {
    this.savePromptsAlerts(this.state.promptName);

    this.setState({
      is_promptBtnOk: false,
      is_promptPopUpView: false,
      promptTitle: null,
      promptMessage: null,
      promptName: null,
    });
  };

  closePromptStatusOut = () => {
    this.setState({
      is_promptBtnOk: false,
      is_promptPopUpView: false,
      promptTitle: null,
      promptMessage: null,
      promptName: null,
    });
  };

  savePromptsAlerts = (promptName: string) => {
    if (localStorage.getItem(promptName) === 'yes') {
      return;
    }
    const promptNameList = JSON.parse(localStorage.getItem('promptNameList')) || {};
    localStorage.setItem(promptName, this.state.is_msg_not_show_again ? 'yes' : 'no');
    promptNameList[promptName] = localStorage.getItem(promptName);

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    this.props.setPromptsAlerts(JSON.stringify(promptNameList));
  };

  toggleShared = () => {
    this.setState({
      is_msg_not_show_again: !this.state.is_msg_not_show_again,
    });
  };

  changeFile = (file: FileModel) => {
    const { pdftronData, openPdftron } = this.props;

    openPdftron({
      file: file,
      isPCD: pdftronData.isPCD,
      isComment: pdftronData.isComment,
      isReview: pdftronData.isReview,
      isRequest: pdftronData.isRequest,
      isSubmittal: pdftronData.isSubmittal,
      isDeliverable: pdftronData.isDeliverable,
      selectedRequest: pdftronData.selectedRequest,
      isAccess: pdftronData.isAccess,
    });
  };

  getStyleWidth = isContainer => {
    const { is_resizable_pdfTron } = this.state;
    if (is_resizable_pdfTron && !isContainer) {
      return '-is_Drag';
    }
    if (!is_resizable_pdfTron && isContainer) {
      return '';
    }
    if (this.state.width == 95) {
      return '-m95';
    }
    if (this.state.width == 55) {
      return '-m55';
    }
    if (this.state.width == 40) {
      return isContainer ? '-m0' : '-m40';
    }
  };

  pdfTronWindow = () => {
    const { pdftronData } = this.props;
    const { isNewFileCopying, promptName, is_promptBtnOk, is_promptPopUpView, promptTitle, is_msg_not_show_again, isLoading } = this.state;

    return (
      <div className={`pdfTronOptimized__window ${this.getStyleWidth(false)}`}>
        {pdftronData && (
          <>
            <div className={'linearProgressPrevFile'}>
              <LinearDeterminate isActive={isLoading} />
            </div>
            <PrevFileHeader
              handleFinalDelivable={pdftronData.isDeliverable ? this.handleFinalDelivable : null}
              isRequest={pdftronData.isRequest}
              isPCD={pdftronData.isPCD}
              isComment={pdftronData.isComment}
              isReview={pdftronData.isReview}
              isDeliverable={pdftronData.isDeliverable}
              isSubmittal={pdftronData.isSubmittal}
              isAccess={pdftronData.isAccess}
              selectedPrevFile={pdftronData.file}
              selectedRequest={pdftronData.selectedRequest}
              width={this.state.width}
              openPromptStatus={this.openPromptStatus}
              promptName={promptName}
              is_promptBtnOk={is_promptBtnOk}
              onClose={this.handleClose}
              isLoading={isLoading}
              changeFile={this.changeFile}
              handleUseFileAsResponse={this.handleUseFileAsResponse}
              fileLoading={this.props.fileLoading}
              isAccessToUseFileAsResponse={this.isUserInResponseParty()}
              isOpenPopup={this.state.isOpenPopup}
              closePopup={this.closePopup}
              theme={this.props.userInfo.theme || localStorage.getItem('theme')}
            />
          </>
        )}
        <div className="webviewer" ref={this.viewerEl as RefObject<HTMLDivElement>} />
        <PopUpView
          isLoading={isNewFileCopying}
          customStyles={{ zIndex: 100000000000 }}
          open={is_promptPopUpView}
          onClose={this.closePromptStatusOut}
          title={promptTitle}
          maxWidth={'xs'}
          hideBackdrop={true}
          disableBtn={true}
        >
          <div className={'containerPrompt messageContainerPrompt'}>{this.state.promptMessage}</div>
          <div onClick={this.toggleShared} className="checkboxShared">
            <Checkbox checked={is_msg_not_show_again} className={'itemCheckBox'} />
            <span className={'checkboxSharedText'}>Do not show such messages again</span>
          </div>
          <div className={'buttonContainer'}>
            <ButtonView handleClick={this.cancelPromptStatus} type={'cancel'} title={'Cancel'} style={{ width: '140px' }} />
            <ButtonView handleClick={this.changePromptBtn} type={'primal'} title={'Yes, transfer'} style={{ width: '140px' }} />
          </div>
        </PopUpView>
      </div>
    );
  };

  render() {
    const { isModalOpen, is_resizable_pdfTron } = this.state;
    const theme = this.props.userInfo.theme || localStorage.getItem('theme');
    const themeClass = handleGetThemeClass('b-prevFile', theme);

    const mainClassPdfTronViewer = this.viewerEl.current?.childNodes[0]?.contentDocument?.getElementsByClassName('App') || [];

    if (mainClassPdfTronViewer.length) {
      if (
        !(mainClassPdfTronViewer[0].classList[1] === 'App_light' && theme === 'light') &&
        !(mainClassPdfTronViewer[0].classList[1] === 'App_dark' && theme === 'dark')
      ) {
        mainClassPdfTronViewer[0].classList.remove('App_light', 'App_dark');
        mainClassPdfTronViewer[0].classList.add(`App_${theme}`);
      }
    }

    return (
      <div
        className={`pdfTronOptimized__container ${!isModalOpen ? '-hidden' : ''} ${this.getStyleWidth(true)} ${
          is_resizable_pdfTron ? '-is_resizable_pdfTron' : ''
        }`}
      >
        <PDFTronGenerateStylesForWebpack />
        {window.innerWidth < 767 || 'ontouchstart' in window ? (
          this.pdfTronWindow()
        ) : (
          <Draggable handle={`.${themeClass}__header`} onDrag={this.onDrag}>
            <Resizable className="box" onClick={this.onClick} resizeHandles={['nw']}>
              {this.pdfTronWindow()}
            </Resizable>
          </Draggable>
        )}
        {!is_resizable_pdfTron ? <div className={`pdfTronOptimized__back`} onClick={this.handleClose} /> : null}
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, commonReducer, settingsReducer }) => ({
  userInfo: userReducer.userInfo,
  pdftronData: commonReducer.pdftronData,
  fileLoading: settingsReducer.fileLoading,
});
const { closePdftron, openPdftron } = commonSlice.actions;
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePdftron,
      openPdftron,
      responseSave,
      getHistory,
      setPromptsAlerts,
      useFileAsResponse,
      pcdHistoryRecordSend,
    },
    dispatch,
  );

export default withRouterHelper(withRouter(connect(mapStateToProps, mapDispatchToProps)(PdfTronOptimized)));
