import { BaseApi } from './baseApi';

class ProjectApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getDisciplines(getParams) {
    const rezult = await this._get({ route: `team/get-disciplines`, getParams: getParams, needAuth: true, devMode: this.devMode });
    return rezult;
  }

  async createProject(bodyParams) {
    const result = await this._post({ route: 'project/create', bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async updateProject(bodyParams, projectId) {
    const result = await this._post({ route: `project/${projectId}/update`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getAllusersCompany(search, party_id) {
    return await this._get({ route: `users?party_id=${party_id}&search=${search}`, needAuth: true, devMode: this.devMode });
  }

  async savePhasePlan(bodyParams, projectId) {
    return await this._post({ route: `project/${projectId}/set-plan`, bodyParams, needAuth: true, devMode: this.devMode });
  }

  async updateLinkParty(bodyParams, projectId) {
    const rezult = await this._post({ route: `project/${projectId}/link-party`, bodyParams, needAuth: true, devMode: this.devMode });
    return rezult;
  }

  async createTypical(data) {
    const result = await this._post({
      route: `building/${data.id}/create-typical`,
      needAuth: true,
      bodyParams: data,
      devMode: this.devMode,
    });
    return result;
  }

  async getProjectRefreshes(projectId) {
    const result = await this._get({ route: `project/refreshes/${projectId}`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getProjectDetails(projectId) {
    const result = await this._get({ route: `project/details/${projectId}`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getUfcData(bodyParams) {
    const result = await this._post({ route: `/content-plan/get-all`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }
  async getUfcListActive(bodyParams) {
    const result = await this._post({ route: `/content-plan/list-active`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getUFCVersionData(versionID) {
    const result = await this._get({ route: `/content-plan/${versionID}/get-data-version`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getUFCVersions(bodyParams) {
    const result = await this._post({ route: `/content-plan/get-versions`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async postUFCData(bodyParams) {
    const result = await this._post({ route: `/content-plan/update-format`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async searchUFC(bodyParams) {
    const result = await this._post({ route: `/content-plan/search`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async searchUFCResult(bodyParams) {
    const result = await this._post({ route: `/content-plan/result-from-parent-new`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getPCDeliverables(projectID, buildingID) {
    const result = await this._get({ route: `/pcd/${projectID}/${buildingID}/project-pcd`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getPCDMain(bodyParams, projectID, buildingID) {
    const result = await this._post({
      route: `/pcd/${projectID}/${buildingID}/main-pcd`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getDrivers(bodyParams) {
    const { buildingId } = bodyParams;
    const result = await this._get({
      route: `/construction-driver/${buildingId}/get-drivers`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async saveDrivers(bodyParams) {
    const result = await this._post({
      route: `/construction-driver/bulk-update-driver-date`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getDriversListVersions(bodyParams) {
    const { buildingId } = bodyParams;
    const result = await this._get({
      route: `/construction-driver/${buildingId}/get-list-versions`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async getDriversVersion(bodyParams) {
    const { buildingId, versionId } = bodyParams;
    const result = await this._get({
      route: `/construction-driver/${buildingId}/get-drivers-${versionId}-version`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setPCDCalendarUpdate(bodyParams, id) {
    const result = await this._post({ route: `/location/${id}/update`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async setPCDAreaCreate(bodyParams) {
    const result = await this._post({ route: '/area/create', bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async setPCDAreaUpdate(bodyParams, id) {
    const result = await this._update({ route: `/area/${id}/update`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async setPCDDriverUpdate(bodyParams, id) {
    const result = await this._update({
      route: `/construction-driver/${id}/update-driver`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setPCDAreaDelete(id) {
    const result = await this._delete({ route: `/area/${id}/delete`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async updateDrawingDays(bodyParams, id) {
    const result = await this._post({ route: `/drawing/${id}/update-drawing-days`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async bulkUpdateDrawingDays(bodyParams, id) {
    const result = await this._post({ route: `/drawing/bulk-update-drawing-days`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async createPCD(bodyParams) {
    const result = await this._post({ route: `/pcd/create`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async createMultiplePCD(bodyParams) {
    const result = await this._post({ route: `/pcd/multiple-create`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async bulkCreateMultiplePCD(bodyParams) {
    const result = await this._post({ route: `/pcd/bulk-multiple-create`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async deliverablePCD(bodyParams, deliverable) {
    const result = await this._post({ route: `/pcd/${deliverable}/update-schedule`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async setSelectZonePCD(bodyParams, drawing) {
    const result = await this._post({ route: `/drawing/${drawing}/area/create`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async updateDrawingReviewsPCD(bodyParams, drawing) {
    const result = await this._post({
      route: `/drawing/${drawing}/update-drawing-reviews`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async bulkUpdateDrawingReviewsPCD(bodyParams, drawing) {
    const result = await this._post({
      route: `/drawing/bulk-update-drawing-reviews`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async createDrawingForBuilding(building) {
    const result = await this._get({ route: `drawing/${building}/create-drawing-for-building`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getDrawingReviewsPCD(bodyParams) {
    const result = await this._post({ route: `/drawing/check`, bodyParams, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getListDrawings(building) {
    const result = await this._get({ route: `/drawing/${building}/get-list-drawings`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async updateDrawingsList(bodyParams) {
    const result = await this._post({
      route: `drawing/update-drawings-list`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async deleteDrawing(drawing) {
    const result = await this._delete({ route: `/drawing/${drawing}/delete-drawing`, needAuth: true, devMode: this.devMode });
    return result;
  }

  async getListDDisciplines() {
    const result = await this._get({ route: `/building/list-disciplines`, needAuth: true, devMode: this.devMode });
    return result;
  }
}

export default new ProjectApi({ devMode: false });
