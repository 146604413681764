import React, { FC, useEffect, useRef, useState } from 'react';
import { BuildingModel, LocationModel, ProjectModel } from '../../../../../models';
import { KeyValueModel } from '../../../../../models/key-value.model';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import BuildingView from './BuildingView';

interface IProps {
  building: BuildingModel<any>;
  handleChangeBuildingFormData: (building: BuildingModel<number[]>) => void;
  createBuilding: (building: BuildingModel<number[]>) => void;
  index: number;
  deleteBuilding: () => void;
  project: ProjectModel;
}

const BuildingContainer: FC<IProps> = ({
  deleteBuilding: deleteBuildingFunc,
  building,
  createBuilding,
  handleChangeBuildingFormData,
  project,
  index,
}) => {
  const gridRef = useRef(null);

  const [formData, setFormData] = useState<BuildingModel<number[]>>({});
  const [phasePlanSelected, setPhasePlanSelected] = useState({
    construction_types: [],
    foundation_types: [],
    exterior_types: [],
    occupancy_type_id: [],
  });
  const [errors, setErrors] = useState({});
  const [countNewFloors, setCountNewFloors] = useState<{
    title: string;
    count: string;
  }>({
    title: 'Level',
    count: '1',
  });
  const [isOpenLocation, setIsOpenLocation] = useState(null);
  const [isRemoveBuilding, setIsRemoveBuilding] = useState(null);
  const [isCreateBuildingAnimeBtn, setIsCreateBuildingAnimeBtn] = useState(false);
  const [isDeleteBuildingAnimeBtn, setIsDeleteBuildingAnimeBtn] = useState(false);

  const {
    phasePlanSelect,
    createProjectStatus,
    updateProjectStatus,
    createBuildingStatus,
    updateBuildingStatus,
    deleteBuildingStatus,
    buildingsErrors,
  } = useAppSelector(state => state.phasePlan);

  const initForm = () => {
    const foundation_types = [];
    if (building?.foundation_types?.length) {
      building.foundation_types.map(type => {
        if (type?.id) {
          foundation_types.push(type.id);
        } else {
          foundation_types.push(type);
        }
      });
    }
    setFormData({
      ...building,
      foundation_types: foundation_types,
      typical_layouts: collectTypicalLayouts(building),
    });
  };

  useEffect(() => {
    initForm();
    if (phasePlanSelect?.construction_types) {
      const phasePlanSelectCopy = { ...phasePlanSelect };
      const construction_types = [];
      phasePlanSelectCopy.construction_types.map(select => {
        construction_types.push({
          ...select,
          title: `[${select.iso_code}] ${select.title} (${select.type})`,
        });
        return select;
      });
      phasePlanSelectCopy.construction_types = construction_types;
      setPhasePlanSelected(phasePlanSelectCopy);
    }
  }, [building, phasePlanSelect]);

  useEffect(() => {
    if (buildingsErrors[formData.id]) {
      setErrors(buildingsErrors[formData.id].errors);
    }
  }, [buildingsErrors]);

  useEffect(() => {
    if (createBuildingStatus.loading) {
      setTimeout(() => {
        setIsCreateBuildingAnimeBtn(false);
      }, 3000);
    }
  }, [createBuildingStatus.loading]);

  useEffect(() => {
    if (updateBuildingStatus.loading) {
      setIsCreateBuildingAnimeBtn(false);
    }
  }, [updateBuildingStatus.loading]);

  useEffect(() => {
    setIsDeleteBuildingAnimeBtn(false);
  }, [deleteBuildingStatus]);

  const handleChangeValue = name => e => {
    let value = e.target.value;
    if (name === 'locations_count') {
      if (+e.target.value < 0) {
        value = 0;
      } else if (+e.target.value > 999) {
        value = 999;
      } else {
        value = +e.target.value;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    handleChangeBuildingFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeSelect = name => value => {
    setFormData({
      ...formData,
      [name]: value,
    });
    handleChangeBuildingFormData({
      ...formData,
      [name]: value,
    });
    resetError(name);
  };

  const collectTypicalLayouts = formDataCopy => {
    const range = [];
    formDataCopy &&
      formDataCopy.typical_layouts.map(r => {
        if (!r.is_default) {
          if (r.start_layout_id && r.end_layout_id) {
            range.push({
              id: r.id,
              start_position: formDataCopy.locations.findIndex(l => l.id === r.start_layout_id),
              end_position: formDataCopy.locations.findIndex(l => l.id === r.end_layout_id),
              discipline: r.discipline || null,
              uf: r.ufc || null,
            });
          } else {
            range.push({
              id: r.id,
              start_position: formDataCopy.locations.findIndex(l => l.position === r.start_position),
              end_position: formDataCopy.locations.findIndex(l => l.position === r.end_position),
              discipline: r.discipline || null,
              uf: r.ufc || null,
            });
          }
        }
      });
    return range;
  };

  const collectUpdateTypicalLayouts = formDataCopy => {
    const range = [];
    formDataCopy &&
      formDataCopy.typical_layouts.map(r => {
        if (!r.is_default) {
          if (r.start_layout_id && r.end_layout_id) {
            range.push({
              id: r.id,
              start_position: formDataCopy.locations.findIndex(l => l.id === r.start_layout_id),
              end_position: formDataCopy.locations.findIndex(l => l.id === r.end_layout_id),
              discipline_id: r.discipline?.id || null,
              ufc_id: r.uf?.id || null,
            });
          } else {
            range.push({
              id: r.id,
              start_position: formDataCopy.locations.findIndex(l => l.position === r.start_position),
              end_position: formDataCopy.locations.findIndex(l => l.position === r.end_position),
              discipline_id: r.discipline?.id || null,
              ufc_id: r.uf?.id || null,
            });
          }
        }
      });
    return range;
  };

  const handleCreateBuilding = () => {
    setIsCreateBuildingAnimeBtn(true);

    createBuilding({
      ...formData,
      typical_layouts: collectUpdateTypicalLayouts(formData),
    });
  };

  const deleteBuilding = () => {
    setIsRemoveBuilding(true);
  };
  const deleteBuildingDecline = () => {
    setIsRemoveBuilding(false);
  };
  const deleteBuildingAccept = () => {
    setIsDeleteBuildingAnimeBtn(true);
    deleteBuildingFunc();
    setIsRemoveBuilding(false);
  };

  const resetError = field => {
    setErrors({
      ...errors,
      [field]: false,
    });
  };

  const handleChangeNumber = name => res => {
    let value = res.floatValue;
    if (name === 'locations_count') {
      if (res.floatValue < 0) {
        value = 0;
      } else if (res.floatValue > 100) {
        value = 100;
      } else {
        value = res.floatValue;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    resetError(name);
  };

  const onChangeNewFloors = (name, value) => {
    setCountNewFloors({
      ...countNewFloors,
      [name]: value,
    });
  };

  const handleAddFloors = async () => {
    const addedLocations = [];
    for (let i = 0; i < Number(countNewFloors.count); i++) {
      if (formData.locations.length === 0) {
        const locationFields = {
          title: `${countNewFloors.title} ${i}`,
          position: formData.locations.length + i,
          custom_id: Math.ceil(Math.random() * 1000),
        };
        addedLocations.push(new LocationModel(locationFields));
      } else {
        const locationFields = {
          title: `${countNewFloors.title} ${formData.locations.length + i}`,
          position: formData.locations.length + i,
          custom_id: Math.ceil(Math.random() * 1000),
        };
        addedLocations.push(new LocationModel(locationFields));
      }
    }
    await setCountNewFloors({
      title: 'Level',
      count: '1',
    });
    setFormData({
      ...formData,
      locations: [...(formData.locations || []), ...addedLocations],
    });
    setTimeout(() => {
      gridRef.current.scrollToRow(formData.locations.length + 1);
    }, 200);
  };

  const changeLocations = async (locations, index) => {
    let sortedLocations = JSON.parse(JSON.stringify(locations));
    if (sortedLocations.length) {
      sortedLocations.map((item, index) => (item.position = index));
    }

    await setFormData({
      ...formData,
      locations: sortedLocations,
    });
    if (index) {
      gridRef.current.selectCell({ idx: 1, rowIdx: index }, true);
    }
  };

  const changeTypicalLayout = async typical_layouts => {
    await setFormData({
      ...formData,
      typical_layouts: typical_layouts,
    });
  };

  const isDisabledButtons = createProjectStatus.loading || updateProjectStatus.loading || createBuildingStatus.loading;

  return (
    <>
      <BuildingView
        handleChangeValue={handleChangeValue}
        formData={formData}
        handleCreateBuilding={handleCreateBuilding}
        deleteBuilding={deleteBuilding}
        phasePlanSelect={phasePlanSelected}
        handleChangeSelect={handleChangeSelect}
        deleteBuildingAccept={deleteBuildingAccept}
        deleteBuildingDecline={deleteBuildingDecline}
        isRemoveBuilding={isRemoveBuilding}
        isDisabledButtons={isDisabledButtons}
        handleChangeNumber={handleChangeNumber}
        errors={errors}
        countNewFloors={countNewFloors}
        onChangeNewFloors={onChangeNewFloors}
        handleAddFloors={handleAddFloors}
        changeLocations={changeLocations}
        changeTypicalLayout={changeTypicalLayout}
        gridRef={gridRef}
        project={project}
        isCreateBuildingAnimeBtn={isCreateBuildingAnimeBtn}
        isDeleteBuildingAnimeBtn={isDeleteBuildingAnimeBtn}
      />
    </>
  );
};
export default BuildingContainer;
