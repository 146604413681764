import { BaseApi } from './baseApi';

class NotificationsApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }

  async getNotifications(params) {
    let bodyParams = {};
    // if (isNaN(+pageNumber)) {
    //   return false;
    // }
    bodyParams = {
      ...params,
      ipp: 50,

      // page: pageNumber,
      project_id: sessionStorage.getItem('active_project_id'),
    };
    const rezult = await this._post({
      route: 'notification/list',
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return rezult;
  }

  async getNotificationFilters() {
    const active_project_id = sessionStorage.getItem('active_project_id');
    const rezult = await this._get({
      route: `notification/${active_project_id}/filters`,
      needAuth: true,
      devMode: this.devMode,
    });
    return rezult;
  }

  async readAll() {
    const rezult = await this._get({
      route: 'notification/read-all',
      needAuth: true,
      devMode: this.devMode,
    });
    return rezult;
  }

  async getNotificationsList(params) {
    let notificationsListPerPage = await this.getNotifications(params);
    if (notificationsListPerPage.status === false) {
      return {
        status: notificationsListPerPage.status,
        message: notificationsListPerPage.message,
      };
    }
    const navigationData = { ...notificationsListPerPage.data };
    delete navigationData.data;
    return {
      data: notificationsListPerPage.data.data,
      navigationData: navigationData,
      status: notificationsListPerPage.status,
      message: notificationsListPerPage.message,
    };
  }
}

export default new NotificationsApi({ devMode: false });
