import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import styles from './LogoStyles.scss';
import { useAppSelector } from '../../../store/configure/configureStore';

interface LogoProps {
  isFull: boolean;
  themeProp?: 'light' | 'dark';
}

const LogoView: React.FC<LogoProps> = ({ isFull, themeProp }) => {
  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  const logoSvg = useMemo(() => {
    if (isFull) {
      return <SVG src={require(`../../../assets/icons/planit_logo_${themeProp || theme}_full.svg`)} alt="site-logo" />;
    } else {
      return <SVG src={require(`../../../assets/icons/planit_logo_${themeProp || theme}.svg`)} alt="site-logo" />;
    }
  }, [isFull, theme, themeProp]);

  return (
    <div href="#" className={`${styles.Logo__link}`} id={'planitLogo-tourBlock'}>
      {logoSvg}
    </div>
  );
};

export default LogoView;
