import React, { FC } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import PageHeader from '../../controls/PageHeader/PageHeader';
import { useAppSelector } from '../../../store/configure/configureStore';

import './WIPPageStyles.scss';

interface IProps {
  type: 'phase-plan' | 'whiteboard';
}

const WipPage: FC<IProps> = ({ type }) => {
  const themeClass = useGetThemeClass('b-wipPage');
  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  return (
    <div className={themeClass}>
      <PageHeader title={type === 'phase-plan' ? 'Phase Plan' : 'Whiteboard'} />
      <div className={`${themeClass}_content`}>
        <div className={`${themeClass}_subtitle`}>We still working on new awesome design 🎨</div>
        <div className={`${themeClass}_text`}>You will receive an email when it is ready. Stay tuned!</div>
      </div>
      <div className={`${themeClass}_image`}>
        <img src={require(`../../../assets/images/${type}-wip-${theme}.svg`)} alt="" />
      </div>
    </div>
  );
};
export default WipPage;
