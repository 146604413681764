import { BaseApi } from './baseApi';

class FilterApi extends BaseApi {
  constructor({ baseURL, devMode }) {
    super(baseURL);
    this.devMode = devMode;
  }
  async getFilter(project_id, pageName) {
    const result = await this._get({
      route: `${pageName}/user-filters/${project_id}`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async setFilter(bodyParams, pageName) {
    const result = await this._post({
      route: `${pageName}/user-filter`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async deleteFilter(id, pageName) {
    const result = await this._post({
      route: `${pageName}/user-filter/${id}/delete`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  async updateFilter(id, bodyParams, pageName) {
    const result = await this._post({
      route: `${pageName}/user-filter/${id}`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  /**
   * Page Filters
   */
  async getPageFilters(bodyParams) {
    const result = await this._post({
      route: `filters`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  /**
   * PC&D Filters
   */
  async getPCDFilters(project_id, bodyParams) {
    const result = await this._post({
      route: `pcd/${project_id}/filters`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }

  /**
   * PC&D Filters sandbox
   */

  async getDeliverableSandboxFilters(project_id) {
    const result = await this._get({
      route: `sandbox/${project_id}/filters-pcd`,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
}

export default new FilterApi({ devMode: false });
