import React, { useRef, useState } from 'react';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { HiddenRelationModel } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { useClickOutside } from '../../../../../helpers/commonHooks';

import './HiddenRelationsStyles.scss';

interface IProps {
  key: string;
  type: RelatedItemsTabsType;
  label: string;
  propertyListName: string;
  hiddenRelations: HiddenRelationModel | null;
  handleOpenCard?: (card) => void;
  isMobile: boolean;
  isDragRequestCard: boolean;
}

const HiddenRelations: React.FC<IProps> = ({
  key,
  type,
  label,
  propertyListName,
  hiddenRelations,
  handleOpenCard,
  isMobile,
  isDragRequestCard,
}) => {
  const themeClass = useGetThemeClass('b-HiddenRelations');

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef();

  const list = hiddenRelations?.[propertyListName];

  const hideElementsAfter = isDragRequestCard ? 2 : 5;
  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  useClickOutside(tooltipRef, handleCloseTooltip);

  const renderNfItem = () => {
    const isShowButton = list.length > hideElementsAfter;

    return (
      <div className={`${themeClass}`} key={key}>
        <div className={`${themeClass}_container`}>
          <span className={`${themeClass}_container_header`}>{label}</span>
          {list.slice(0, isShowButton ? hideElementsAfter : list.length).map(nf => {
            return (
              <div key={nf.nf} className={`${themeClass}_container_item`}>
                <CardLinkButton
                  type={'nf'}
                  size={'sm'}
                  title={`NF ${nf.nf}`}
                  clickHandler={() => handleOpenCard({ ...nf, snippet: 'request' })}
                />
              </div>
            );
          })}
          {isShowButton && (
            <CustomTooltip
              isInteractive
              open={isTooltipOpen}
              componentsProps={{ popper: { ref: tooltipRef } }}
              title={
                <div className={`${themeClass}_tooltip`}>
                  {list.map(nf => {
                    return (
                      <div key={`tooltipItem-${nf.nf}`} className={`${themeClass}_container_item`}>
                        <CardLinkButton
                          type={'nf'}
                          size={'sm'}
                          title={`NF ${nf.nf}`}
                          clickHandler={() => {
                            handleCloseTooltip();
                            setTimeout(() => {
                              handleOpenCard({ ...nf, snippet: 'request' });
                            }, 0);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              }
              customTooltipClass={`${themeClass}_tooltipBlock`}
              onClick={handleOpenTooltip}
            >
              <span className={`${themeClass}_showButton`}>Show more</span>
            </CustomTooltip>
          )}
        </div>
      </div>
    );
  };

  const getIconType = (value: string): string => {
    if (value === 'CL-RFI') {
      return 'cl-rfi';
    } else if (value === 'QC-D') {
      return 'qc';
    } else if (value === 'SO') {
      return 'sign-off';
    } else if (value === 'SD') {
      return 'shop-drawings';
    } else {
      return 'deliverables';
    }
  };

  const renderDeliverableItem = () => {
    const isShowButton = list.length > hideElementsAfter;

    return (
      <div className={`${themeClass}`} key={key}>
        <div className={`${themeClass}_container`}>
          <span className={`${themeClass}_container_header`}>{label}</span>
          {list.slice(0, isShowButton ? hideElementsAfter : list.length).map(del => {
            return (
              <div key={del.id} className={`${themeClass}_container_item`}>
                <CardLinkButton
                  type={'deliverable'}
                  size={'sm'}
                  icon={() => <TokenIcon iconName={getIconType(del?.type?.title)} size={16} />}
                  title={`${del?.short_title || del?.title || 'Title'}`}
                  tooltip={del?.title || ''}
                  clickHandler={() => handleOpenCard({ ...del, snippet: 'deliverable' })}
                />
              </div>
            );
          })}
          {isShowButton && (
            <CustomTooltip
              isInteractive
              open={isTooltipOpen}
              componentsProps={{ popper: { ref: tooltipRef } }}
              title={
                <div className={`${themeClass}_tooltip`}>
                  {list.map(del => {
                    return (
                      <div key={`tooltipItem-${del.id}`} className={`${themeClass}_container_item`}>
                        <CardLinkButton
                          type={'deliverable'}
                          size={'sm'}
                          icon={() => <TokenIcon iconName={getIconType(del?.type?.title)} size={16} />}
                          title={`${del?.short_title || del?.title || 'Title'}`}
                          tooltip={del?.title || ''}
                          clickHandler={() => {
                            handleCloseTooltip();
                            setTimeout(() => {
                              handleOpenCard({ ...del, snippet: 'deliverable' });
                            }, 0);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              }
              customTooltipClass={`${themeClass}_tooltipBlock`}
              onClick={handleOpenTooltip}
            >
              <span className={`${themeClass}_showButton`}>Show more</span>
            </CustomTooltip>
          )}
        </div>
      </div>
    );
  };

  const renderSubmittalItem = () => {
    const isShowButton = list.length > hideElementsAfter;

    return (
      <div className={`${themeClass}`} key={key}>
        <div className={`${themeClass}_container`}>
          <span className={`${themeClass}_container_header`}>{label}</span>
          {list.slice(0, isShowButton ? hideElementsAfter : list.length).map(sf => {
            return (
              <div key={sf.id} className={`${themeClass}_container_item`}>
                <CardLinkButton
                  type={'submittal'}
                  size={'sm'}
                  title={`${sf.title}`}
                  customClassName={`${themeClass}_cardLinkButtonTitle`}
                  clickHandler={() => handleOpenCard({ ...sf, snippet: 'submittal' })}
                />
              </div>
            );
          })}
          {isShowButton && (
            <CustomTooltip
              isInteractive
              open={isTooltipOpen}
              componentsProps={{ popper: { ref: tooltipRef } }}
              title={
                <div className={`${themeClass}_tooltip`}>
                  {list.map(sf => {
                    return (
                      <div key={`tooltipItem-${sf.id}`} className={`${themeClass}_container_item`}>
                        <CardLinkButton
                          type={'submittal'}
                          size={'sm'}
                          title={`${sf.title}`}
                          customClassName={`${themeClass}_cardLinkButtonTitle`}
                          clickHandler={() => {
                            handleCloseTooltip();
                            setTimeout(() => {
                              handleOpenCard({ ...sf, snippet: 'submittal' });
                            }, 0);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              }
              customTooltipClass={`${themeClass}_tooltipBlock`}
              onClick={handleOpenTooltip}
            >
              <span className={`${themeClass}_showButton`}>Show more</span>
            </CustomTooltip>
          )}
        </div>
      </div>
    );
  };

  if (!list?.length) {
    return null;
  }

  return (
    <>
      {type === 'request' && renderNfItem()}
      {type === 'deliverable' && renderDeliverableItem()}
      {type === 'submittalInput' && renderSubmittalItem()}
      {type === 'submittalOutput' && renderSubmittalItem()}
    </>
  );
};

export default HiddenRelations;
