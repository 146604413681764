import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { IConfirmDialogContext, withConfirmDialogContext } from '../../../HighOrderComponents';
import { DeliverableCardTabNamesModel, SubmitButtonActions } from '../../constans/constants';
import { OverdueStateNames, PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { FileDataModel, FileModel, RequestModel, RequestUserModel } from '../../../../../models';
import HistoryAction from '../../../../controls/HistoryAction/HistoryAction';
import DeliverableTab from '../../tabs/Deliverable/Deliverable';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { KeyValueModel } from '../../../../../models/key-value.model';
import ReviewTab from '../../tabs/Review/Review';
import CommentsContainer from '../../../Comments/CommentsContainer';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import SegmentedButton from '../../../../controls/ButtonComponents/SegmentedButton/SegmentedButton';
import RelatedItemsTab from '../../../RelatedItemsTab/RelatedItemsTab';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { CustomButton } from '../../../../controls/ButtonComponents';
import Divider from '../../../../controls/Divider/Divider';
import HiddenRelations from '../../../RelatedItemsTab/controls/HiddenRelations/HiddenRelations';
import { handleOpenCardHelper } from '../../../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';
import { HiddenRelationModelDeliverable } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import { RouteComponentProps, withRouter } from '../../../../../navigation/withRouter/withRouter';
import DeliverableButtons from '../../tabs/Deliverable/DeliverableButtons';

import './BottomCardPartStyles.scss';
import { RouterHelperComponentProps, withRouterHelper } from '../../../../../navigation/withRouterHelper/withRouterHelper';

interface BottomCardPartProps extends RouteComponentProps, RouterHelperComponentProps, IConfirmDialogContext {
  selectedRequest: PCDCard;
  currentDescription: string;
  setDescription: (descriptionValue: string, delta?: any, source?: any, editor?: any) => void;
  isPCDFieldsDisabled: boolean;
  activeTab: DeliverableCardTabNamesModel;
  isDragRequestCard: boolean;
  handleOpenReasonsPopUp: (isUpdateReasonsPopUp: boolean) => void;
  isCreateRevision: boolean;
  collectSaveObject: (whiteboard: number, files: FileDataModel[]) => void;
  errors: KeyValueModel<string>;
  animationBtn: string;
  handleDownloadAll: (e: MouseEvent) => void;
  handleFile: (e: any, isDrag?: boolean, isRestrict?: boolean) => void;
  delSavedFile: (file_id: number | number[], isBulk?: boolean) => void;
  selectedFiles: FileModel[];
  loadingFilesName: string[];
  requestSave: (whiteboard: number, files?: FileDataModel[]) => void;
  requestSavePrompt: (whiteboard: number, files?) => void;
  changeMentions: (newMentions: { id: number; email: string }[]) => void;
  closePromptStatusOut: () => void;
  submitVisiblePopUp: number;
  submitDeliverableP1: RequestModel[] | null;
  submitDeliverableP2: RequestModel[] | null;
  submitDeliverableP3: RequestModel[] | null;
  isMsgNotShowAgain: boolean;
  toggleShared: () => void;
  cancelPromptStatus: () => void;
  closeDraftPromptStatusOut: () => void;
  isPromptPopUpDraft: boolean;
  isCreateSubmitButtonDisabled: boolean;
  isRevisionUpdateDraftButtonDisabled: boolean;
  isRevisionSubmitted: boolean;
  handlePCDCardData: (nameBtn: SubmitButtonActions) => void;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => void;
  handleCloseAuthorUser: () => void;
  isAccessToEditPCDCard: boolean;
  handleShowConfirmDialog?: () => void;
  onChangeActiveTab: (activeTab: DeliverableCardTabNamesModel) => void;
  isNextRevisionDrafted: boolean;
  nextRevisionObject: PCDCard;
  isHiddenFields: boolean;
  isSeparateTab: boolean;
  moreLimitFiles: FileModel[] | FileList | any;
  handleMoreLimitFiles: (files: FileModel[] | FileList | any) => void;
}

const workingOptions = [
  {
    id: 'request',
    title: 'Need Forms',
  },
  {
    id: 'submittalInput',
    title: 'Input submittals',
    statusType: 'new',
  },
  {
    id: 'deliverable',
    title: 'CL drawings',
  },
  {
    id: 'submittalOutput',
    title: 'Output submittals',
    statusType: 'new',
  },
];

const BottomCardPart: React.FC<BottomCardPartProps> = ({
  selectedRequest,
  isPCDFieldsDisabled,
  activeTab,
  isDragRequestCard,
  handleOpenReasonsPopUp,
  handleShowConfirmDialog,
  isCreateRevision,
  currentDescription,
  setDescription,
  errors,
  animationBtn,
  handleDownloadAll,
  handleFile,
  delSavedFile,
  selectedFiles,
  loadingFilesName,
  changeMentions,
  isCreateSubmitButtonDisabled,
  isRevisionUpdateDraftButtonDisabled,
  isRevisionSubmitted,
  handlePCDCardData,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
  isAccessToEditPCDCard,
  onChangeActiveTab,
  isNextRevisionDrafted,
  nextRevisionObject,
  isHiddenFields,
  isSeparateTab,
  moreLimitFiles,
  handleMoreLimitFiles,
  routerHelper,
}) => {
  const dispatch = useAppDispatch();
  const { is1440Resolution, isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-cardDeliverable-bottomCardPart');
  const reviewThemeClass = useGetThemeClass('b-cardPcd-reviewTab');

  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const [selectedRelatedItems, setSelectedRelatedItems] = useState<RelatedItemsTabsType>('request');
  const [isEditReviewMode, setIsEditReviewMode] = useState<boolean>(false);

  const renderHistoryAction = (
    type: OverdueStateNames,
    date: string | Date,
    firstName: string,
    lastName: string,
    userProfile: RequestUserModel,
    isShowTime: boolean,
  ) => {
    return (
      <HistoryAction
        type={type}
        date={date}
        firstName={firstName}
        lastName={lastName}
        handleOpenAuthorUser={handleOpenAuthorUser}
        handleCloseAuthorUser={handleCloseAuthorUser}
        userProfile={userProfile}
        isShowTime={isShowTime}
      />
    );
  };

  const handleRelatedItems = (id: string) => {
    setSelectedRelatedItems(id as RelatedItemsTabsType);
  };

  const isSidePeekView = isDragRequestCard || is1440Resolution;

  const showFooterHiddenRelations = useMemo(() => {
    const hiddenRelations: HiddenRelationModelDeliverable = relatedTabData.card?.hidden_relation || null;

    if (selectedRelatedItems === 'request') {
      return hiddenRelations?.requests_unlink?.length || hiddenRelations?.requests_deactivated?.length;
    }

    if (selectedRelatedItems === 'deliverable') {
      return (
        hiddenRelations?.predecessors_unlink?.length ||
        hiddenRelations?.predecessors_deactivated?.length ||
        hiddenRelations?.successors_unlink?.length ||
        hiddenRelations?.successors_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'submittalOutput') {
      return (
        hiddenRelations?.submittals_output_predecessor_unlink?.length ||
        hiddenRelations?.submittals_output_successor_unlink?.length ||
        hiddenRelations?.submittals_output_predecessor_deactivated?.length ||
        hiddenRelations?.submittals_output_successor_deactivated?.length
      );
    }
  }, [relatedTabData.card?.hidden_relation, selectedRelatedItems]);

  const deliverableFooterHiddenRelations = {
    request: [{ requests_unlink: 'Unlinked predecessors:' }, { requests_deactivated: 'Deactivated predecessors:' }],
    deliverable: [
      { predecessors_unlink: 'Unlinked predecessors:' },
      { predecessors_deactivated: 'Deactivated predecessors:' },
      { successors_unlink: 'Unlinked successors:' },
      { successors_deactivated: 'Deactivated successors:' },
    ],
    submittalOutput: [
      { submittals_output_predecessor_unlink: 'Unlinked predecessors:' },
      { submittals_output_predecessor_deactivated: 'Deactivated predecessors:' },
      { submittals_output_successor_unlink: 'Unlinked successors:' },
      { submittals_output_successor_deactivated: 'Deactivated successors:' },
    ],
  };

  const openCard = card => {
    handleOpenCardHelper(true, 'Deliverable', selectedRequest, card, dispatch, routerHelper, 'firstCardBreadcrumbs');
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          ['-sidePeek']: isSidePeekView,
        })}
      >
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'deliverable' })}>
          <DeliverableTab
            selectedRequest={selectedRequest}
            isCreateRevision={isCreateRevision}
            isPCDFieldsDisabled={isPCDFieldsDisabled}
            isDragRequestCard={isSidePeekView}
            activeTab={activeTab}
            currentDescription={currentDescription}
            setDescription={setDescription}
            errors={errors}
            animationBtn={animationBtn}
            selectedFiles={selectedFiles}
            loadingFilesName={loadingFilesName}
            handleFile={handleFile}
            delSavedFile={delSavedFile}
            handleDownloadAll={handleDownloadAll}
            changeMentions={changeMentions}
            renderHistoryAction={renderHistoryAction}
            isCreateSubmitButtonDisabled={isCreateSubmitButtonDisabled}
            isRevisionUpdateDraftButtonDisabled={isRevisionUpdateDraftButtonDisabled}
            isRevisionSubmitted={isRevisionSubmitted}
            handlePCDCardData={handlePCDCardData}
            isNextRevisionDrafted={isNextRevisionDrafted}
            nextRevisionObject={nextRevisionObject}
            handleOpenReasonsPopUp={handleOpenReasonsPopUp}
            isHiddenFields={isHiddenFields}
            moreLimitFiles={moreLimitFiles}
            handleMoreLimitFiles={handleMoreLimitFiles}
          />
        </div>
        <div
          className={cn(`${themeClass}_tabComponent -relatedItems`, {
            ['-active']: activeTab === 'related-items',
            ['-hideFooter']:
              selectedRelatedItems !== 'request' &&
              !(
                (selectedRelatedItems === 'submittalOutput' ||
                  selectedRelatedItems === 'submittalInput' ||
                  selectedRelatedItems === 'deliverable') &&
                !isCreateRevision &&
                isRevisionSubmitted
              ),
          })}
        >
          <SegmentedButton selectedButtonId={selectedRelatedItems} buttons={workingOptions} changeHandler={handleRelatedItems} />
          <RelatedItemsTab
            isDragRequestCard={isSidePeekView}
            selectedRequest={selectedRequest}
            isSeparateTab={isSeparateTab}
            relatedTabType={selectedRelatedItems}
            currentCard={'Deliverable'}
            isCreateRequest={false}
            isAccess={isAccessToEditPCDCard}
            isCreateSubmitButtonDisabled={isCreateSubmitButtonDisabled}
            handleOpenReasonsPopUp={handleOpenReasonsPopUp}
            isCreateRevision={isCreateRevision}
            handleRelatedItems={handleRelatedItems}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'review' })}>
          <ReviewTab
            selectedRequest={selectedRequest}
            isAccess={isAccessToEditPCDCard}
            isDragRequestCard={isSidePeekView}
            handleOpenReasonsPopUp={handleOpenReasonsPopUp}
            isCreateRevision={isCreateRevision}
            isCreateSubmitButtonDisabled={isCreateSubmitButtonDisabled}
            onChangeEditMode={setIsEditReviewMode}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'discussion' })}>
          <CommentsContainer
            selectedRequest={selectedRequest}
            type={'deliverable'}
            onChangeActiveTab={onChangeActiveTab}
            isDragCard={isSidePeekView}
            handleShowConfirmDialog={handleShowConfirmDialog}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'related-items' })}>
        {(selectedRelatedItems === 'request' || selectedRelatedItems === 'submittalOutput' || selectedRelatedItems === 'deliverable') &&
        !isCreateRevision &&
        isRevisionSubmitted ? (
          <div className={`${themeClass}_footer`}>
            <Divider direction={'horizontal'} type={'srf-5'} />
            <div className={`${themeClass}_footer_block`}>
              {showFooterHiddenRelations && (
                <div className={`${themeClass}_footer_hiddenRelationsBlock`}>
                  {deliverableFooterHiddenRelations[selectedRelatedItems].map((obj, index) => {
                    const name = Object.keys(obj)[0];
                    return (
                      <HiddenRelations
                        key={`${selectedRelatedItems}-${name}`}
                        type={selectedRelatedItems}
                        label={obj[name]}
                        propertyListName={name}
                        hiddenRelations={relatedTabData?.card?.hidden_relation || null}
                        handleOpenCard={openCard}
                        isMobile={isMobile}
                        isDragRequestCard={isSidePeekView}
                      />
                    );
                  })}
                </div>
              )}
              <div className={`${themeClass}_footer_buttonContainer`}>
                <CustomButton
                  type={'primary'}
                  size={'md'}
                  title={'Create New Revision'}
                  clickHandler={() => handleOpenReasonsPopUp(false)}
                  disabled={isCreateSubmitButtonDisabled}
                  isMobile={isMobile}
                  customClassName={`${themeClass}_footer_buttonContainer_button`}
                  customTooltipBasisClassName={`${themeClass}_footer_buttonContainer_tooltipBasis`}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'deliverable' })}>
        <DeliverableButtons
          selectedRequest={selectedRequest}
          animationBtn={animationBtn}
          isCreateSubmitButtonDisabled={isCreateSubmitButtonDisabled}
          isRevisionUpdateDraftButtonDisabled={isRevisionUpdateDraftButtonDisabled}
          handlePCDCardData={handlePCDCardData}
          isRevisionSubmitted={isRevisionSubmitted}
        />
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'review' })}>
        {!isCreateRevision && isRevisionSubmitted ? (
          <div className={`${reviewThemeClass}_footer`}>
            <CustomButton
              type={'primary'}
              size={'md'}
              title={'Create New Revision'}
              clickHandler={() => handleOpenReasonsPopUp(false)}
              disabled={isCreateSubmitButtonDisabled || isEditReviewMode}
              isMobile={isMobile}
              customClassName={`${reviewThemeClass}_footer_button`}
              customTooltipBasisClassName={`${reviewThemeClass}_footer_buttonTooltip`}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withRouterHelper(withRouter(withConfirmDialogContext(BottomCardPart)));
