import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { API_URI, DOMAIN_URI } from '../../../service/links';
import { components, StylesConfig } from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import { useColoredText } from '../../../hooks/useColoredText';
import { useClickOutside } from '../../../helpers/commonHooks';
import Popover from '@material-ui/core/Popover';
import { TagFormModel, TagModel } from '../../../models';
import { useSelector } from 'react-redux';
import Timeout = NodeJS.Timeout;
import { deleteHashtag, editHashtag } from '../../../store/request/requestLogic(HOLD)';
import ChipsColorTag from '../../controls/Chips/ChipsColorTag/ChipsColorTag';
import { useGetThemeClass } from '../../../helpers/designTokens';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import ColorPicker from '../../controls/ColorPicker/ColorPicker';
import CustomButton from '../../controls/ButtonComponents/CustomButton/CustomButton';
import Divider from '../../controls/Divider/Divider';
import cn from 'classnames';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';

import './HashtahsStyles.scss';

interface IProps {
  isAccess: boolean;
  tags: TagFormModel[];
  onChangeHashtags: (tags: TagFormModel[]) => void;
  deleteHashtag: (data) => void;
  editHashtag: (data) => void;
  isHashTagChanging: boolean;
  isSandBox: boolean;
  isOpenProps: boolean;
  onClose: () => void;
  isMenuOpen?: boolean;
  disabled?: boolean;
  isNeedListEditMode?: boolean;
}

const Placeholder = (props: any) => {
  return <components.Placeholder className={'placeholder'} {...props} />;
};

const Control = (props: any) => {
  const themeClass = useGetThemeClass('b-hashtags');
  return (
    <components.Control
      className={cn(`${themeClass}__control`, {
        ['-selectOpened']: props.menuIsOpen,
      })}
      {...props}
    />
  );
};

const Input = (props: any) => {
  return <components.Input className={'input'} {...props} />;
};

const CustomValueContainer = props => {
  return (
    //@ts-ignore
    <components.ValueContainer className="valueContainer" {...props}>
      {React.Children.map(props.children, child => {
        return child;
      })}
    </components.ValueContainer>
  );
};

const HashtagsEllipsis: React.FC<IProps> = ({
  isAccess,
  onChangeHashtags,
  tags,
  deleteHashtag,
  editHashtag,
  isHashTagChanging,
  isSandBox,
  isOpenProps,
  onClose,
  isMenuOpen,
  disabled,
  isNeedListEditMode,
}) => {
  const allTags = useSelector<any>(state => state.requestReducer.nfTags) as TagModel[];
  const themeClass = useGetThemeClass('b-hashtags');

  const [inputValue, setInputValue] = useState<string>('');
  const projectId = +sessionStorage.getItem('active_project_id');
  const [tagsOption, setTagOption] = useState<any[]>([]);
  const { renderText } = useColoredText();
  const [shouldDisplayPicker, setShouldDisplayPicker] = useState<boolean | null>(null);
  const [editedHashtag, setEditedHashtag] = useState(null);
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState<TagFormModel | null>(null);
  const [isOpenLinkPopover, setIsOpenLinkPopover] = useState<TagFormModel | null>(null);
  const [linkPopoverMode, setLinkPopoverMode] = useState<string | null>(null);
  const [linkValue, setLinkValue] = useState<string | null>(null);
  const [value, setValue] = useState<TagFormModel[]>([]);
  const [isOpenEditHashtag, setIsOpenEditHashtag] = useState<boolean | null>(null);
  const hashtagRef = useRef();
  const refInput = useRef<HTMLInputElement>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const isCacheTag = useMemo(() => editedHashtag?.value, [editedHashtag]);
  const tagValueRef = useRef(null);
  const tagValueContRef = useRef(null);
  const [indexWidth, setIndexWidth] = useState<number>(0);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const [isIndicatorsContainer, setIsIndicatorsContainer] = useState<boolean>(false);
  const [isCloseTags, setIsCloseTags] = useState<boolean>(false);
  const [isEditedHashtag, setIsEditedHashtag] = useState(isNeedListEditMode || false);

  let timerHover: Timeout;
  const hovered = status => {
    if (!isEditedHashtag) {
      if (status) {
        timerHover = setTimeout(() => {
          setIsHoverActive(true);
        }, 200);
      } else {
        clearTimeout(timerHover);
        setIsHoverActive(false);
        showElements(value);
      }
    }
  };

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      getTags('');
    }
  }, [isDelete, projectId]);

  useEffect(() => {
    if (value) {
      localStorage.setItem('TEMP_SELECTED_HASHTAGS', JSON.stringify(value));
    }
  }, [value]);

  useEffect(() => {
    if (!editedHashtag && isIndicatorsContainer) {
      setIsIndicatorsContainer(false);
      setIsHoverActive(false);
      showElements(value);
      return;
    }
    if (!editedHashtag) {
      return;
    }
    const findIndex = value.findIndex(f => f.value === editedHashtag.value);

    if (findIndex >= 0) {
      const array = [...(value || [])];
      array[findIndex] = {
        ...value[findIndex],
        ...editedHashtag,
      };
      onChangeHashtags(array);
    }
  }, [editedHashtag, isIndicatorsContainer]);

  useEffect(() => {
    if (isCloseTags) {
      setIsCloseTags(false);
      setIsHoverActive(false);
      showElements(value);
    }
  }, [isCloseTags]);

  useEffect(() => {
    setValue(tags);
    showElementsNoWidth(tags);
  }, [tags]);

  useEffect(() => {
    if (isOpenProps) {
      setLinkValue(editedHashtag.label);
      setIsOpenEditHashtag(true);
    }
  }, [isOpenProps]);

  const handleChange = (newValue: TagFormModel[]) => {
    setValue(newValue);
    onChangeHashtags(newValue);
  };

  const showElementsNoWidth = arr => {
    if (arr?.length > 0) {
      const countTags = isSandBox ? 3 : 5;
      setIndexWidth(countTags);
    }
  };

  const showElements = arr => {
    if (arr?.length > 0) {
      showElementsNoWidth(arr);
    }
  };

  const handleOpenLinkPopover = (target, tag, mode) => {
    setIsOpenLinkPopover(tag);
    setLinkPopoverMode(mode);
    setLinkValue(editedHashtag?.link || tag?.link || '');
  };

  useEffect(() => {
    if (tagValueRef.current) {
      const handledDisableSelected = e => {
        const composed = e.composedPath();

        if (!composed.some((el: any) => el.dataset?.closeim)) {
          document.body.removeEventListener('click', handledDisableSelected);
        }
      };

      document.body.addEventListener('click', handledDisableSelected);
    }
  }, [tagValueRef.current]);

  const MultiValue = (props: any) => {
    if (editedHashtag?.value) {
      setIsIndicatorsContainer(true);
    }
    if (props.selectProps.menuIsOpen || editedHashtag?.value || isHoverActive || isEditedHashtag) {
      return (
        <div className={`${themeClass}__editBox`}>
          <div
            ref={ref => {
              if (editedHashtag?.value === props.data.value) {
                tagValueRef.current = ref;
              }
            }}
            className={cn(`multiValue ${props.data.color ? getTextColor(props.data.color) : 'default'}`, {
              ['-selected']: editedHashtag?.value === props.data.value,
            })}
          >
            <components.MultiValue {...props}>
              <div className={'label'}>
                <div className={'label_text'}>{props.data.label || props.children || props.data.name || ''}</div>
              </div>
            </components.MultiValue>
          </div>
          {editedHashtag?.value === props.data.value && IndicatorsContainer(props)}
        </div>
      );
    }
    if (indexWidth && props.index === indexWidth && !isEditedHashtag) {
      return (
        <div className={`${themeClass}__editBox`} ref={tagValueContRef}>
          <div
            ref={ref => {
              if (editedHashtag?.value === props.data.value) {
                tagValueRef.current = ref;
              }
            }}
            className={'multiValue default'}
          >
            <div className={'label'}>
              <div className={'label_text'}>...</div>
            </div>
          </div>
        </div>
      );
    } else if (indexWidth && props.index < indexWidth) {
      return (
        <div
          className={`${themeClass}__editBox`}
          ref={tagValueContRef}
          style={{
            display: indexWidth && props.index > indexWidth && !props.selectProps.menuIsOpen ? 'none' : 'block',
          }}
        >
          <div
            ref={ref => {
              if (editedHashtag?.value === props.data.value) {
                tagValueRef.current = ref;
              }
            }}
            className={cn(`multiValue ${props.data.color ? getTextColor(props.data.color) : 'default'}`, {
              ['-selected']: editedHashtag?.value === props.data.value,
            })}
          >
            <components.MultiValue {...props}>
              <div className={'label'}>
                <div className={'label_text'}>{props.data.label || props.children || props.data.name || ''}</div>
              </div>
            </components.MultiValue>
          </div>
          {editedHashtag?.value === props.data.value && IndicatorsContainer(props)}
        </div>
      );
    } else {
      return null;
    }
  };

  const stopOpeningMenu = (e, props) => {
    e.stopPropagation();
    props.selectProps.onMenuClose();
  };

  const MultiValueLabel = props => {
    const handleMultiValueClick = (e: any) => {
      stopOpeningMenu(e, props);
      if (props.data.is_auto === 0) setEditedHashtag(props.data);
      setIndexWidth(0);
    };

    return (
      <>
        {props.data.link ? (
          <div
            className={`${themeClass} tagLink`}
            onMouseDown={(e: any) => stopOpeningMenu(e, props)}
            onClick={e => handleOpenLinkPopover(e.currentTarget, props.data, 'link')}
          >
            <TokenIcon iconName={'link'} size={16} />
          </div>
        ) : null}
        <div onMouseDown={(e: any) => handleMultiValueClick(e)}>
          <components.MultiValueLabel {...props} />
        </div>
      </>
    );
  };

  const MultiValueRemove = (props: any) => {
    if (props.data.is_auto === 1 || (!isEditedHashtag && !editedHashtag?.value)) {
      return null;
    }

    return (
      <div className={`${themeClass}__valueRemove`}>
        <div
          className={'btn_close'}
          onClick={() => {
            console.log('remove');
            handleDeleteSelectedTag(props.data);
          }}
        >
          <TokenIcon iconName={'close'} size={12} />
        </div>
      </div>
    );
  };

  const Option = (props: any) => {
    const new_props = { ...props };
    new_props.isFocused = false;

    return (
      <MenuItem
        buttonRef={new_props.innerRef}
        selected={new_props.isFocused}
        disabled={false}
        component="div"
        style={{
          fontWeight: new_props.isSelected ? 500 : 400,
        }}
        className={`${themeClass} menuItem nfCardHover`}
        {...new_props.innerProps}
      >
        <div className={`valueContainer`}>
          <ChipsColorTag color={new_props.data.color || 'default'} value={new_props.data.label} />
        </div>
      </MenuItem>
    );
  };

  const getTextColor = color => {
    if (
      color == 'pink' ||
      color == 'peach' ||
      color == 'sand' ||
      color == 'lime' ||
      color == 'blue' ||
      color == 'indigo' ||
      color == 'purple' ||
      color == 'gray'
    ) {
      return color;
    } else {
      return 'default';
    }
  };

  const handleChangeColor = color => {
    if (isCacheTag) {
      editHashtag({
        id: editedHashtag.value,
        data: {
          project_id: projectId,
          id: editedHashtag.value,
          color: color,
        },
      });
    }

    setEditedHashtag({
      ...editedHashtag,
      color: color,
    });
  };

  const handleSaveChangeTitle = () => {
    if (isCacheTag) {
      editHashtag({
        id: editedHashtag.value,
        data: {
          project_id: projectId,
          id: editedHashtag.value,
          name: linkValue,
        },
      });
    }

    setEditedHashtag({
      ...editedHashtag,
      label: linkValue,
    });
    handleCloseEditPopover();
  };

  const handleSaveChangeLink = (isDelLink?: boolean, tag?: TagFormModel) => {
    if (isCacheTag || tag) {
      editHashtag({
        id: tag ? tag.value : editedHashtag.value,
        data: {
          project_id: projectId,
          id: tag ? tag.value : editedHashtag.value,
          link: isDelLink ? null : linkValue,
        },
      });
    }

    setEditedHashtag({
      ...(tag || editedHashtag),
      link: isDelLink ? '' : linkValue,
    });
    handleCloseLinkPopover();
  };

  const ColorPickerTag = () => {
    useClickOutside(hashtagRef, () => setShouldDisplayPicker(false));

    return (
      <div
        className={cn(`tool -colorPicker`, { ['-activeTool']: shouldDisplayPicker })}
        onClick={() => setShouldDisplayPicker(!shouldDisplayPicker)}
      >
        <CustomTooltip title={'Fill color'} placement={'bottom'}>
          <TokenIcon iconName={'fill-color'} size={16} />
        </CustomTooltip>
      </div>
    );
  };

  const handleConfirmChanges = () => {
    setEditedHashtag(null);
    setIsEditedHashtag(false);
  };

  const openEditHashtagPopover = (e: any) => {
    e.stopPropagation();
    setLinkValue(editedHashtag.label);
    setIsOpenEditHashtag(true);
  };

  const handleDeleteTags = () => {
    if (isCacheTag) {
      deleteHashtag({
        id: editedHashtag.value,
      });
      setIsDelete(true);
    }
    handleDeleteSelectedTag(editedHashtag);
  };

  const IndicatorsContainer = props => {
    if (!isAccess) {
      return null;
    }
    return (
      <div
        className={`${themeClass}__tools`}
        onMouseDown={(e: any) => stopOpeningMenu(e, props)}
        style={editedHashtag ? {} : { display: 'none' }}
      >
        <div className={`${themeClass}__tools_group`}>
          <ColorPickerTag />
          <div className={cn(`tool`, { ['-activeTool']: isOpenEditHashtag })} onClick={openEditHashtagPopover}>
            <CustomTooltip title={'Edit text'} placement={'bottom'}>
              <TokenIcon iconName={'edit-text'} size={16} />
            </CustomTooltip>
          </div>
          <div
            className={cn(`tool`, { ['-activeTool']: isOpenLinkPopover })}
            onClick={e => handleOpenLinkPopover(e.currentTarget, editedHashtag, 'link')}
          >
            <CustomTooltip title={'Link'} placement={'bottom'}>
              <TokenIcon iconName={'link'} size={16} />
            </CustomTooltip>
          </div>
        </div>
        <div className={'divider'}>
          <Divider direction={'vertical'} type={'srf-5'} />
        </div>
        <div className={`${themeClass}__tools_group`}>
          <div className={`tool`} onClick={handleConfirmChanges}>
            <CustomTooltip title={'Check'} placement={'bottom'}>
              <TokenIcon iconName={'check'} size={16} />
            </CustomTooltip>
          </div>
          <div
            className={cn(`tool`, { ['-activeTool']: isOpenRemovePopup })}
            onClick={() => {
              setIsOpenRemovePopup(editedHashtag);
            }}
          >
            <CustomTooltip title={'Trash'} placement={'bottom'}>
              <TokenIcon iconName={'trash'} size={16} />
            </CustomTooltip>
          </div>
        </div>
      </div>
    );
  };

  const getTags = (inputValue: string) => {
    return new Promise<any[]>(resolve => {
      fetch(`${DOMAIN_URI}${API_URI}hashtags?project_id=${projectId}&search=${encodeURI(inputValue)}`)
        .then(res => {
          return res;
        })
        .then(res => res.json())
        .then(result => {
          const filteredArray = [];
          result.data.resource.map(r => {
            filteredArray.push({
              label: r.name,
              value: r.id,
              color: r.color,
              link: r.link,
              is_auto: r.is_auto,
            });
          });

          setTagOption(filteredArray);
          resolve(filteredArray);
        });
    });
  };

  const promiseOptions = (inputValue: string) => {
    return getTags(inputValue);
  };

  const Menu = (props: any) => {
    return (
      <components.Menu {...props} className={`${themeClass}__menu`}>
        {props.children}
      </components.Menu>
    );
  };

  const handleInputChange = value => {
    if (!value) {
      getTags('');
      return;
    }

    setInputValue(value);
  };

  const handleChangeLink = ({ target }) => {
    const value = target.value;
    setLinkValue(value);
  };

  const handleEditLink = (tag: TagFormModel) => {
    setLinkValue(tag.link);
    setLinkPopoverMode('edit');
    setIsOpenLinkPopover(tag);
    setEditedHashtag(tag);
    setTimeout(() => {
      refInput.current.select();
      refInput.current.focus();
    }, 100);
  };

  const handleRemoveLink = (tag: TagFormModel) => {
    handleSaveChangeLink(true, tag);
  };

  const handleCloseLinkPopover = () => {
    setLinkPopoverMode(null);
    setIsOpenLinkPopover(null);
    setShouldDisplayPicker(null);
    setIsOpenEditHashtag(null);
    setLinkValue('');
  };

  const handleCloseEditPopover = () => {
    setIsOpenLinkPopover(null);
    setIsOpenEditHashtag(null);
    setLinkValue('');
  };

  const handleCloseRemovePopUp = () => {
    setIsOpenRemovePopup(null);
  };

  const handleChangeName = ({ target }) => {
    setLinkValue(target.value);
  };

  const handleDeleteSelectedTag = (tag: TagFormModel) => {
    const findIndex = value.findIndex(f => f.value === tag?.value);
    if (findIndex >= 0) {
      const array = [...value];
      array.splice(findIndex, 1);
      setValue(array);
      onChangeHashtags(array);
      setIsOpenRemovePopup(null);
    }
    setEditedHashtag(null);
  };

  const selectComponents = {
    Control: Control,
    Menu: Menu,
    Option: Option,
    Placeholder: Placeholder,
    MultiValue: MultiValue,
    DropdownIndicator: null,
    ValueContainer: CustomValueContainer,
    MultiValueLabel: MultiValueLabel,
    MultiValueRemove: MultiValueRemove,
    IndicatorsContainer: () => <></>,
    Input: Input,
  };

  const customStyles: StylesConfig = {
    control: (_, { menuIsOpen }) => ({
      border: menuIsOpen ? '1px solid var(--color2)' : '1px solid transparent',
      ':hover': {
        border: menuIsOpen && '1px solid var(--color2)',
      },
    }),
  };

  useClickOutside(hashtagRef, () => {
    if (editedHashtag) {
      setEditedHashtag(null);
    }
    setIsCloseTags(true);
    setIsEditedHashtag(false);
  });

  return (
    <div data-closeim>
      <div ref={hashtagRef} onMouseEnter={() => hovered(true)} onMouseLeave={() => hovered(false)}>
        <AsyncCreatableSelect
          styles={customStyles}
          components={selectComponents}
          // inputValue={inputValue}
          isClearable
          isMulti
          onChange={handleChange as any}
          onInputChange={handleInputChange}
          placeholder="Add or select tags"
          defaultOptions={allTags.length && isDelete ? allTags : tagsOption}
          loadOptions={promiseOptions}
          value={value}
          isDisabled={(editedHashtag && isHashTagChanging) || disabled}
          onMenuOpen={() => {
            setIsEditedHashtag(true);
            setIsDelete(false);
          }}
          // onMenuClose={onClose ? () => onClose() : null}
          closeMenuOnSelect={false}
          menuIsOpen={isMenuOpen}
          onMenuScrollToTop={null}
          autoFocus={false}
        />
        <Popover
          data-closeim
          id={'hashtag-link'}
          open={!!isOpenLinkPopover}
          anchorEl={hashtagRef.current}
          onClose={handleCloseLinkPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              height: 'auto',
              background: 'transparent',
              boxShadow: 'none',
              borderRadius: 0,
            },
          }}
        >
          {isOpenLinkPopover && (
            <div className={`${themeClass}_linkEditor`}>
              {linkPopoverMode === 'link' && (editedHashtag?.link || isOpenLinkPopover?.link) ? (
                <>
                  <a
                    rel="noopener noreferrer"
                    href={isOpenLinkPopover?.link}
                    target="_blank"
                    className={`${themeClass}_linkEditor_input -link`}
                  >
                    {isOpenLinkPopover?.link}
                  </a>
                  <div className={`${themeClass}_linkEditor_btn`}>
                    <CustomButton
                      size={'sm'}
                      type={'text-plain'}
                      clickHandler={() => handleEditLink(isOpenLinkPopover)}
                      title={'Edit'}
                      disabled={!isAccess}
                    />
                    <CustomButton
                      size={'sm'}
                      type={'text-plain'}
                      clickHandler={() => handleRemoveLink(isOpenLinkPopover)}
                      title={'Remove'}
                      disabled={!isAccess}
                    />
                  </div>
                </>
              ) : (
                <>
                  <input
                    value={linkValue}
                    onChange={handleChangeLink}
                    placeholder={'Type or paste URL'}
                    className={`${themeClass}_linkEditor_input`}
                    type="text"
                    ref={refInput}
                  />
                  <CustomButton
                    title={'Save'}
                    disabled={!linkValue}
                    size={'sm'}
                    type={'text-plain'}
                    clickHandler={() => {
                      setIsOpenLinkPopover(null);
                      handleSaveChangeLink();
                    }}
                  />
                </>
              )}
            </div>
          )}
        </Popover>
        <Popover
          data-closeim
          id={'colorPicker'}
          open={!!shouldDisplayPicker}
          anchorEl={hashtagRef.current}
          onClose={() => setShouldDisplayPicker(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              height: 'auto',
              background: 'transparent',
              boxShadow: 'none',
              borderRadius: 0,
            },
          }}
        >
          <ColorPicker type={'colorTags'} onChangeColor={handleChangeColor} />
        </Popover>
        <Popover
          data-closeim
          id={'editPopover'}
          open={!!isOpenEditHashtag}
          anchorEl={hashtagRef.current}
          onClose={handleCloseEditPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              marginTop: '4px',
              height: 'auto',
              background: 'transparent',
              boxShadow: 'none',
              borderRadius: 0,
            },
          }}
        >
          <div className={`${themeClass}_linkEditor`}>
            <input
              value={linkValue}
              onChange={handleChangeName}
              placeholder={'Enter tag name'}
              className={`${themeClass}_linkEditor_input`}
              type="text"
              ref={refInput}
            />
            <CustomButton
              title={'Save'}
              disabled={!linkValue}
              size={'sm'}
              type={'text-plain'}
              clickHandler={() => {
                setIsOpenEditHashtag(null);
                handleSaveChangeTitle();
              }}
            />
          </div>
        </Popover>
        <DialogPopUp
          open={!!isOpenRemovePopup}
          onClose={handleCloseRemovePopUp}
          isOverlay={true}
          title={'Delete tag?'}
          modalText={'A you sure you want to delete this tag? It will no longer be available.'}
          customSecondaryType={'tertiary'}
          tertiaryText={'Cancel'}
          handleOnTertiary={handleCloseRemovePopUp}
          primaryText={'Yes, delete'}
          handleOnPrimary={handleDeleteTags}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ editHashtag, deleteHashtag }, dispatch);

export default connect(null, mapDispatchToProps)(HashtagsEllipsis);
