import { FilterModel, SimpleTitleModel } from '../models';
import { KeyValueModel } from '../models/key-value.model';
import moment from 'moment';
import { isEmpty, isEqual, xorWith, size } from 'lodash';

export const isEmptyObject = (obj: {}) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const ucFirst = str => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const deleteSpaces = (str: string) => {
  if (!str) return '';

  return str.split(' ').join('');
};

export const convertFilterNameForApi = (filters: KeyValueModel<(string | number)[]>, filterColumns: FilterModel[]) => {
  const newFormData = {};
  Object.keys(filters || {}).map(data => {
    const findData = filterColumns.find(d => d.filterField === data);
    if (findData?.filteredField) {
      newFormData[findData.filteredField] = filters[data];
    } else {
      newFormData[data] = filters[data];
    }
  });
  return newFormData;
};

export const handleRightDateFormat = (item: string) => {
  const formats = ['DD/MM/YYYY', 'YYYY-MM-DD'];
  const isDate = moment(item, formats, true).isValid();

  if (isDate) {
    return moment(Date.parse(item)).format('DD/MM/YYYY');
  } else {
    return item;
  }
};

export const isArrayEqual = function (x, y) {
  const isSameSize = size(x) === size(y);
  return isSameSize && isEmpty(xorWith(x, y, isEqual));
};

export const convertToLocalTimezone = (date: moment.Moment): moment.Moment => {
  const offset = new Date().getTimezoneOffset();

  return date.add('minutes', -offset);
};
