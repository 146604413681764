import React from 'react';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import CheckBox from '../../../../../controls/CheckBox/CheckBox';
import { ChipsDiscipline } from '../../../../../controls/Chips';
import ChipsStandardTag from '../../../../../controls/Chips/ChipsStandardTag/ChipsStandardTag';

import './TypicalLayoutsItemStyles.scss';

interface IProps {
  typicalLayout: any;
  handleTypicalLayoutsItemClick?: (typicalLayout: any) => void;
}

const TypicalLayoutsItem: React.FC<IProps> = ({ typicalLayout, handleTypicalLayoutsItemClick }) => {
  const themeClass = useGetThemeClass('b-typicalLayoutsItem');

  return (
    <div className={`${themeClass}_container`} onClick={() => handleTypicalLayoutsItemClick(typicalLayout)}>
      <div className={`${themeClass}_item`}>
        <div className={`${themeClass}_item_firstPart`}>
          <div className={`${themeClass}_item_firstPart_checkBox`}>
            <CheckBox checked={typicalLayout.isSelected} onClick={null} />
          </div>
          <div className={`${themeClass}_item_firstPart_title`}>{typicalLayout.title}</div>
        </div>
        <div className={`${themeClass}_item_chipsPart`}>
          {typicalLayout.ufc && (
            <div className={`${themeClass}_item_chipsPart_ufc`}>
              <ChipsStandardTag value={`${typicalLayout.ufc.code}`} />
            </div>
          )}
          {typicalLayout.discipline && (
            <ChipsDiscipline type={'filled'} color={typicalLayout.discipline.text_color} value={typicalLayout.discipline.title} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TypicalLayoutsItem;
