import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import Divider from '../../../Divider/Divider';
import { MiniLoader } from '../../../MiniLoader';
import Links from '../../../Links/Links';
import { sortBy } from 'lodash';
import { HIGH_RESOLUTION_CLASS } from '../../../../../constants/screenResolutionConstants';

import './DropdownSelectMenuStyles.scss';

interface DropdownSelectMenuProps<T, G> {
  size: 'sm' | 'md';
  menuItems: T[];
  groups?: G[];
  customClassName?: string;
  renderItemHeader: () => ReactElement | ReactNode;
  renderItemFooter?: () => ReactElement | ReactNode;
  renderMenuItem: (item: T) => ReactElement | ReactNode;
  anchorEl: ReactNode;
  onClose?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void;
  isMenuOpen: boolean;
  isLoading: boolean;
  groupingType?: 'labeled' | 'divided';
  revertDropdownPositionToTop?: boolean;
  handleOpenDDManageStatuses?: () => void;
  alignDropdownPositionToLeft: boolean;
  popOverId?: string;
  isMobile?: boolean;
  customClassPaperName?: string;
  noSort?: boolean;
  onClickOutside?: () => void;
  isHighResolution?: boolean;
  sortingValue?: string;
  isHighResolution?: boolean;
}

function DropdownSelectMenu<T extends DropdownItemModel, G extends DropdownGroupItem>({
  customClassName,
  renderItemHeader,
  renderItemFooter,
  menuItems,
  groups,
  groupingType,
  renderMenuItem,
  anchorEl,
  onClose,
  isMenuOpen,
  isLoading,
  size,
  revertDropdownPositionToTop,
  handleOpenDDManageStatuses,
  alignDropdownPositionToLeft,
  popOverId,
  customClassPaperName,
  isMobile,
  noSort,
  onClickOutside,
  isHighResolution,
  sortingValue,
  isHighResolution,
}: DropdownSelectMenuProps<T, G>) {
  const themeClassMenu = useGetThemeClass('b-dropdownSelectMenu');
  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');

  const isGroupingActive = useMemo(() => {
    return groups?.length;
  }, [groups]);

  const handleClose = (e: React.SyntheticEvent) => {
    onClose && onClose(e);
    onClickOutside && onClickOutside();
  };

  return (
    <>
      <Popover
        id={popOverId || ''}
        open={!!isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableEnforceFocus
        classes={{
          paper: cn(`${themeClassCallout} ${themeClassPaper}`, {
            ['-isDropdownOnTop']: revertDropdownPositionToTop,
            ['-mobile']: isMobile,
            [`${customClassPaperName}`]: customClassPaperName,
          }),
        }}
        className={cn({ [HIGH_RESOLUTION_CLASS]: isHighResolution })}
        anchorOrigin={{
          vertical: `${revertDropdownPositionToTop ? 'top' : 'bottom'}`,
          horizontal: `${alignDropdownPositionToLeft ? 'right' : 'left'}`,
        }}
        transformOrigin={{
          vertical: `${revertDropdownPositionToTop ? 'bottom' : 'top'}`,
          horizontal: `${alignDropdownPositionToLeft ? 'right' : 'left'}`,
        }}
      >
        <div
          className={cn(`${themeClassMenu}`, {
            [`${customClassName}`]: customClassName,
            [`-mobile`]: isMobile,
          })}
        >
          {renderItemHeader ? <>{renderItemHeader()}</> : null}
          <div className={`${themeClassMenu}_listContainer`}>
            <div
              className={cn(`${themeClassMenu}_itemList`, {
                [`-grouped`]: isGroupingActive && groupingType === 'labeled',
                [`-divided`]: isGroupingActive && groupingType === 'divided',
                [`-loading`]: isLoading,
                [`-mobile`]: isMobile,
              })}
            >
              {menuItems?.length ? (
                <>
                  {isGroupingActive ? (
                    <>
                      {groups?.length &&
                        groups.map((groupItem, index, arr) => {
                          const itemsFromGroup = noSort
                            ? menuItems.filter(menuItem => menuItem.group === groupItem.value)
                            : sortBy(
                                menuItems.filter(menuItem => menuItem.group === groupItem.value),
                                sortingValue || 'title',
                              );
                          const isLastGroup = index == arr.length - 1;

                          if (!itemsFromGroup.length) {
                            return null;
                          }

                          return (
                            <>
                              <div className={`${themeClassMenu}_itemList_group`}>
                                {groupingType === 'labeled' ? (
                                  <div className={cn(`${themeClassMenu}_itemList_group_title`, { [`-mobile`]: isMobile })}>
                                    <span>{groupItem.title}</span>
                                    {handleOpenDDManageStatuses && groupItem.title === 'Assigned Design Documents' && (
                                      <Links type={'a'} value={'(manage statuses)'} onClick={handleOpenDDManageStatuses} to_href={null} />
                                    )}
                                  </div>
                                ) : null}
                                <div className={`${themeClassMenu}_itemList_group_list`}>
                                  {itemsFromGroup.map(groupedItem => {
                                    return <>{renderMenuItem(groupedItem)}</>;
                                  })}
                                </div>
                                {groupingType === 'divided' && !isLastGroup ? <Divider direction={'horizontal'} type={'srf-4'} /> : null}
                              </div>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {menuItems.map(menuItem => {
                        return <>{renderMenuItem(menuItem)}</>;
                      })}
                    </>
                  )}
                </>
              ) : null}
              {!menuItems?.length ? (
                <div
                  className={cn(`${themeClassMenu}_empty`, {
                    [`-${size}`]: size,
                  })}
                >
                  <span>No items</span>
                </div>
              ) : null}
            </div>
            {isLoading ? <MiniLoader size={size} circleClasses={`${themeClassMenu}_loadingItem_loaderCircle`} /> : null}
          </div>
          {renderItemFooter ? <>{renderItemFooter()}</> : null}
        </div>
      </Popover>
    </>
  );
}

export default DropdownSelectMenu;
