import React, { useRef, useEffect, useState, useMemo } from 'react';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { useClickOutside } from '../../../../../helpers/commonHooks';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import StatusNF from '../../../../controls/Status/StatusNF/StatusNF';
import Divider from '../../../../controls/Divider/Divider';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';
import DateRow from '../../../../controls/DateRow/DateRow';
import cn from 'classnames';

import './RequestStatusesStyles.scss';

type RequestStatusesType = { type: string; title: string; active: boolean; current: boolean };

interface IProps {
  selectedRequest: any;
  hoverStatuses: (isHoverStatuses: boolean) => void;
  isShowStatuses: boolean;
  headerRef: any;
  isDragRequestCard: boolean;
  isDropdown?: boolean;
}

const RequestStatuses = ({ selectedRequest, isDropdown, hoverStatuses, isShowStatuses, headerRef, isDragRequestCard }: IProps) => {
  const [statusesCollection, setStatusesCollection] = useState<RequestStatusesType[]>(null);
  const themeClass = useGetThemeClass('b-requestStatuses');
  const themeClassDropdown = useGetThemeClass('b-requestStatusesDropdown');
  const ref = useRef();
  const setIndicators = (current: number, ...indicators) => {
    const StatusesCollection = [
      { type: 'Request', title: 'drafted', active: indicators[0], current: current === 1 },
      { type: 'Request', title: 'sent', active: indicators[1], current: current === 2 },
      { type: 'Commitment', title: 'submitted', active: indicators[2], current: current === 3 },
      { type: 'Response', title: 'provided', active: indicators[3], current: current === 4 },
      { type: 'Response', title: 'accepted', active: indicators[4], current: current === 5 },
      { type: 'Response', title: 'declined', active: indicators[5], current: current === 6 },
      { type: 'NF', title: 'deactivated', active: indicators[6], current: current === 7 },
    ];
    if (selectedRequest?.status === 'declined' && current == 2) {
      StatusesCollection[1].title = 'Re-opened';
    }
    setStatusesCollection(StatusesCollection);
  };

  useEffect(() => {
    if (selectedRequest) {
      getStatuses(selectedRequest);
    }
  }, [selectedRequest]);

  const getStatuses = selectedRequest => {
    if (selectedRequest.status === 'drafted') {
      setIndicators(1, true, false, false, false, false, false);
    }
    if (selectedRequest.status === 'sent') {
      setIndicators(2, true, true, false, false, false, false);
    }
    if (selectedRequest.status === 'submitted') {
      setIndicators(3, true, true, true, false, false, false);
    }
    if (selectedRequest.status === 'provided') {
      setIndicators(4, true, true, true, true, false, false);
    }
    if (selectedRequest.status === 'accepted') {
      setIndicators(5, true, true, true, true, true, false);
    }
    if (selectedRequest.status === 'declined') {
      if (selectedRequest.parent_id) {
        setIndicators(6, true, true, true, true, true, true);
      } else {
        setIndicators(2, true, true, false, false, false, false);
      }
    }
    if (selectedRequest?.is_deactivated === 1) {
      setIndicators(7, false, false, false, false, false, false, true);
    }
  };

  const handleHoverStatuses = () => {
    hoverStatuses(true);
  };

  const handleUnHoverStatuses = () => {
    hoverStatuses(false);
  };

  useClickOutside(ref, handleUnHoverStatuses);

  const customPosition = useMemo(() => {
    const coordinates = headerRef?.current?.getBoundingClientRect();
    if (isDropdown) {
      return {
        top: coordinates?.y + 30,
        left: coordinates?.x,
      };
    } else {
      return {
        top: isDragRequestCard ? coordinates?.y - 110 : coordinates?.y - 40,
        left: isDragRequestCard ? 0 : coordinates?.x,
      };
    }
  }, [headerRef]);

  return (
    <>
      {isShowStatuses && (
        <div
          onMouseEnter={() => (isDropdown ? void 0 : handleHoverStatuses())}
          onMouseLeave={() => (isDropdown ? void 0 : handleUnHoverStatuses())}
          style={customPosition}
          className={cn({
            [`${themeClass}`]: !isDropdown,
            [`${themeClassDropdown}`]: isDropdown,
          })}
          ref={ref}
        >
          <div
            className={cn({
              [`${themeClass}_statusBlock`]: !isDropdown,
              [`${themeClassDropdown}_statusContainer`]: isDropdown,
            })}
          >
            {statusesCollection &&
              statusesCollection
                .filter(item => {
                  if (selectedRequest?.is_deactivated === 0) {
                    return item.type !== 'NF';
                  } else {
                    return true;
                  }
                })
                .map((item: RequestStatusesType, index: number, arr) => {
                  const isDeclinedAccepted =
                    item.title === 'accepted' && selectedRequest?.status === 'declined' && selectedRequest?.date_status_accepted?.length;
                  const title = item.title === 'Re-opened' ? 'declined' : item.title;
                  const author = selectedRequest?.requestStatuses?.[title]?.info?.profile;
                  const isAuthor = !!selectedRequest?.requestStatuses?.[title]?.info;
                  const date = selectedRequest?.requestStatuses?.[title]?.date;
                  const isTip = !!(author || date);
                  const statusTimeline = item.active ? (item.current || isDeclinedAccepted ? 'current' : 'past') : 'future';
                  const isShowLine = index < arr.length - 1;
                  return (
                    <>
                      {!isDropdown ? (
                        <div
                          className={`${themeClass}_statusBlock_statusContainer`}
                          key={item.title}
                          style={{ display: !selectedRequest && index === 6 ? 'none' : '' }}
                        >
                          <CustomTooltip
                            title={
                              isTip ? (
                                <>
                                  {isAuthor && (
                                    <PlaceholderInfo
                                      type={'Persona'}
                                      firstName={author?.first_name}
                                      lastName={author?.last_name}
                                      detailInfo={`${author?.first_name} ${author?.last_name}`}
                                      imagePath={`${author?.image}`}
                                      size={24}
                                    />
                                  )}
                                  <DateRow date={date} showTime isOffset />
                                </>
                              ) : (
                                ''
                              )
                            }
                            type={'ddl'}
                            showArrow={false}
                            customTooltipClass={`${themeClass} -statusTimeline`}
                            placement={'bottom-start'}
                            key={item.title}
                          >
                            <div className={'itemContainer'}>
                              {index > 0 && (
                                <div className={'itemContainerDivider'}>
                                  <Divider direction={'horizontal'} type={'srf-4'} />
                                </div>
                              )}
                              <StatusNF status={item.title} statusTimeline={statusTimeline} isShowHover />
                            </div>
                          </CustomTooltip>
                        </div>
                      ) : (
                        <>
                          <div
                            className={`${themeClassDropdown}_statusRow`}
                            key={item.title}
                            style={{ display: !selectedRequest && index === 6 ? 'none' : '' }}
                          >
                            <div className={`${themeClassDropdown}_columnStatusNF`}>
                              <StatusNF status={item.title} statusTimeline={statusTimeline} />
                            </div>
                            <div
                              className={cn(`${themeClassDropdown}_columnDateRow`, {
                                ['-opacity50']: statusTimeline === 'past',
                                ['-opacity20']: statusTimeline === 'future',
                              })}
                            >
                              <DateRow date={date} showTime isOffset />
                            </div>
                            <div
                              className={cn(`${themeClassDropdown}_columnPlaceholderInfo`, {
                                ['-opacity50']: statusTimeline === 'past',
                                ['-opacity20']: statusTimeline === 'future',
                              })}
                            >
                              {isAuthor && (
                                <PlaceholderInfo
                                  type={'Persona'}
                                  firstName={author?.first_name}
                                  lastName={author?.last_name}
                                  detailInfo={`${author?.first_name} ${author?.last_name}`}
                                  imagePath={`${author?.image}`}
                                  size={24}
                                />
                              )}
                            </div>
                          </div>
                          {isShowLine && (
                            <div
                              className={`${themeClassDropdown}_statusRow -dividerVertical`}
                              key={item.title + 'line'}
                              style={{ display: !selectedRequest && index === 6 ? 'none' : '' }}
                            >
                              <Divider direction={'vertical'} type={'srf-4'} />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
          </div>
          {!isDropdown && (
            <div className={`${themeClass}_endLine`}>
              <Divider direction={'horizontal'} type={'srf-4'} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RequestStatuses;
