import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import RestorePasswordView from './RestorePasswordView';
import queryString from 'query-string';
import * as ROUTES from '../../../navigation/routes';
import { Navigate } from 'react-router-dom';
import { resetPassword } from '../../../store/user/userThunk';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { withRouter } from '../../../navigation/withRouter/withRouter';

class RestorePasswordComponent extends React.Component {
  static propTypes = {
    //actions
    // login: PropTypes.func.isRequired,
    // loading: PropTypes.bool.isRequired,
  };

  state = {
    user: {
      password: '',
      confirmPassword: '',
      token: queryString?.parse(this.props.location?.search)?.token || '',
    },
    redirect: false,
    error: '',
  };

  handleChangeField = field => value => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        [field]: value,
      },
    });
  };

  handleLogin = () => {
    let { user } = this.state;
    const { loading } = this.props;

    let err = false;
    if (user.password === user.confirmPassword) {
      this.setState({ error: '' });
      delete user.confirmPassword;
      if (!err || !loading) {
        this.props.resetPassword(user);
      }
    } else {
      this.setState({ error: 'Passwords are not equal' });
    }
  };

  decline = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { user } = this.state;
    const { loading, message, status } = this.props;
    const { token, redirect, error } = this.state;
    return (
      <>
        {redirect && <Navigate to={ROUTES.loginP} />}
        {status && (
          <DialogPopUp open={true} onClose={this.props.decline} modalText={message} primaryText={'Ok'} handleOnPrimary={this.decline} />
        )}
        <RestorePasswordView
          user={user}
          handleChangeField={this.handleChangeField}
          handleLogin={this.handleLogin}
          loading={loading}
          message={message}
          status={status}
          restoreToken={token}
          error={error}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userReducer }) => ({
  loading: userReducer.loading,
  errors: userReducer.reset_data,
  message: userReducer.message,
  status: userReducer.status,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RestorePasswordComponent));
