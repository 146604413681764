import moment from 'moment/moment';

function getRangeOfWeeks(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start).add(7, key).startOf(key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfWeeks(next, end, key, arr.concat(next));
}

function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start).add(1, key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfDates(next, end, key, arr.concat(next));
}

function getWeeks(year) {
  const begin = moment(year).startOf('year').day(0);
  const end = moment(year).endOf('year').day(0).subtract(1, 'days');
  return getRangeOfWeeks(begin, end, 'days');
}

function getMonthDateRange(year, month) {
  const startDate = moment(`${year}-${month}`, 'YYYY-MM');
  const endDate = moment(startDate).endOf('month');
  return { start: startDate, end: endDate };
}

function getDays(year, month) {
  const begin = moment(`${year}-${month}`, 'YYYY-MM').startOf('month');
  const end = moment(`${year}-${month}`, 'YYYY-MM').endOf('month');

  return getRangeOfDates(begin, end, 'days');
}

export function initColumns(year, month, mode) {
  let columns = {};
  let columnOrder = [];
  let tasks = {};
  if (mode === 'yearMode') {
    // const weeks = getWeeks(year);
    // const months = moment.monthsShort();
    // months.map((date, i) => {
    // columnOrder.push(date.format('ww'));
    const startMonth = moment(`${year}-${month}-01`).format('MM/DD/YY');
    const endMonth = moment(`${year}-${month}-01`).endOf('month').format('MM/DD/YY');
    const date = moment(startMonth).format('MMM');
    columns = {
      ...columns,
      [`${year}-${date}`]: {
        id: `${year}-${date}`,
        start: startMonth,
        end: endMonth,
        taskIds: [],
      },
    };
    columnOrder.push(`${year}-${date}`);
    // })
  }
  // if (mode === 'weekMode') {
  //   const days = getWeeks(year, month);
  //   days.map(date => {
  //     // columns = {
  //     //   ...columns,
  //     //   [date.format('YYYY-MM-DD')]: {
  //     //     id: date.format('YYYY-MM-DD'),
  //     //     date: date.format('YYYY-MM-DD'),
  //     //     taskIds: [],
  //     //     moment: date,
  //     //   },
  //     // };
  //     // const startMonth = getMonthDateRange(year, i).start.format('MM/DD/YY');
  //     // const endMonth = getMonthDateRange(year, i).end.format('MM/DD/YY');
  //     columns = {
  //       ...columns,
  //       [`${year}-${date}`]: {
  //         id: `${year}-${date}`,
  //         // start: startMonth,
  //         // end: endMonth,
  //         taskIds: [],
  //       },
  //     };
  //     columnOrder.push(date.format('YYYY-MM-DD'));
  //   });
  // }
  if (mode === 'monthMode') {
    const days = getDays(year, month);
    days.map(date => {
      columns = {
        ...columns,
        [date.format('YYYY-MM-DD')]: {
          id: date.format('YYYY-MM-DD'),
          date: date.format('YYYY-MM-DD'),
          taskIds: [],
          moment: date,
        },
      };
      columnOrder.push(date.format('YYYY-MM-DD'));
    });
  }
  return { columns, columnOrder };
}

export const initialData = (year, month, mode) => {
  const { columns, columnOrder } = initColumns(year, month, mode);

  return {
    tasks: {},
    columns,
    columnOrder,
  };
};

export const getDaysRange = (startDate, endDate) => {
  if (startDate && endDate) {
    const abs = Math.abs(moment(endDate).diff(moment(startDate).clone().startOf('month'), 'month')) + 1;
    let date = startDate;
    const dates = {
      columnOrder: [],
      columns: {},
      tasks: {},
      monthViewSizes: [],
    };

    for (let i = 0; i < abs; i++) {
      const countDays = Math.abs(moment(date).diff(moment(date), 'days'));
      const data = initialData(moment(date).format('YYYY'), moment(date).format('MM'), 'monthMode');
      dates.columnOrder = [...dates.columnOrder, ...data.columnOrder];
      dates.columns = { ...dates.columns, ...data.columns };
      date = moment(date).add(1, 'M').format('YYYY-MM');
    }

    const weeks = [];
    let currDate = moment(startDate).clone().startOf('month');

    do {
      let endOfWeek = currDate.clone().endOf('week');

      weeks.push({
        date: currDate.format('YYYY-MM-DD'),
        nextMonth: currDate.format('MM') !== endOfWeek.format('MM') ? endOfWeek.format('YYYY-MM') : undefined,
        countDays: endOfWeek.weekday() - currDate.weekday() + 1,
      });

      currDate = endOfWeek.clone().add(1, 'day');
    } while (currDate <= moment(endDate));

    dates.weekSizes = weeks;

    // dates.columns = Object.create(dates.columns);
    // dates.columns = Object.assign({}, dates.columns);
    return dates;
  }

  return undefined;
};

function calcWeeksInMonth(month) {
  month = moment(month, 'YYYY-MM-DD');

  const first = month.day() == 0 ? 6 : month.day() - 1;
  let day = 7 - first;

  const last = month.daysInMonth();
  const count = (last - day) / 7;

  const weeks = [];
  weeks.push([1, day]);
  for (let i = 0; i < count; i++) {
    weeks.push([day + 1, Math.min((day += 7), last)]);
  }
  let countWeeks = 0;
  weeks.map(w => (w[1] - w[0] >= 4 ? countWeeks++ : null));
  return countWeeks;
}

function getMondays(date) {
  var monday = moment(date).startOf('month').day('Monday');
  if (monday.date() > 7) monday.add(7, 'd');
  var month = monday.month();
  let counter = 0;
  while (month === monday.month()) {
    counter++;
    monday.add(7, 'd');
  }
  return counter;
}

export function getWeekIndexForDate(date) {
  const startOfWeek = date.clone().startOf('week');
  const endOfWeek = date.clone().endOf('week');

  if (startOfWeek.format('MM') !== endOfWeek.format('MM')) {
    return `${startOfWeek.format('YYYY-MM')}-${endOfWeek.format('YYYY-MM')}-1`;
  }

  const startOfMonth = date.clone().startOf('month');
  let startOfMonthWeek = startOfMonth.week();
  if (startOfMonth.format('MM') !== startOfMonth.clone().startOf('week').format('MM')) {
    startOfMonthWeek++;
  }

  return `${date.format('YYYY-MM')}-${date.week() - startOfMonthWeek + 1}`;
}

export function getNumWeeksForMonth(year, month) {
  const startOfMonthWeek = moment(`${year}-${month}`).startOf('month').week();

  const endOfMonth = moment(`${year}-${month}`).endOf('month');
  let endOfMonthWeek = endOfMonth.week();
  if (endOfMonthWeek == 1) {
    endOfMonthWeek = endOfMonth.subtract(1, 'week').week() + 1;
  }

  return endOfMonthWeek - startOfMonthWeek + 1;
}

export function getWeekStartEnd(date) {
  const fromDate = moment(date).clone().startOf('week');
  let toDate = moment(date).clone().endOf('week');

  if (fromDate.format('MM') !== toDate.format('MM')) {
    toDate = toDate.endOf('month');
  }

  return {
    fromDate,
    toDate,
  };
}

export const getWeeksRange = (startDate, endDate) => {
  if (startDate && endDate) {
    const initStartDate = moment(startDate).format('YYYY-MM');
    let finishDate = moment(endDate).endOf('month');
    // let abs = Math.abs(moment(endDate).diff(moment(initStartDate).clone().startOf('month'), 'month'));

    const dates = {
      columnOrder: [],
      columns: {},
      tasks: {},
      monthViewSizes: [],
    };
    // if (abs > 24) {
    //   finishDate = moment(initStartDate).add(24,'month');
    // }

    const currDate = moment(initStartDate);
    let counter = 1;
    let currMonth = currDate.format('YYYY-MM');

    while (currDate <= finishDate) {
      if (currDate.format('YYYY-MM') === currMonth) {
        const endOfWeek = moment(currDate).endOf('week');
        let id;

        if (endOfWeek.format('YYYY-MM') === currMonth) {
          id = `${currMonth}-${counter}`;
        } else {
          id = `${currMonth}-${endOfWeek.format('YYYY-MM')}-${1}`;
          dates.monthViewSizes.push(
            {
              date: currMonth,
              countDays: counter - 1,
            },
            {
              date: `${currMonth}`,
              countDays: 1,
              nextMonth: endOfWeek.format('YYYY-MM'),
            },
          );
          currMonth = endOfWeek.format('YYYY-MM');
          counter = 0;
        }

        dates.columnOrder.push(id);
        dates.columns[id] = {
          id,
          start: moment(currDate).format('YYYY-MM-DD'),
          end: endOfWeek.format('YYYY-MM-DD'),
          taskIds: [],
        };
      } else {
        dates.monthViewSizes.push({ date: currMonth, countDays: counter - 1 });
        currMonth = currDate.format('YYYY-MM');
        counter = 1;
        continue;
      }

      currDate.endOf('week').add(1, 'day');
      counter++;
    }

    return dates;
  }

  return undefined;
};
export const getMonthRange = (startDate, endDate) => {
  if (startDate && endDate) {
    // const months = Math.abs(moment(endDate).diff(moment(startDate).clone().startOf('month'), 'month'));
    // let abs = Math.ceil(moment(endDate).diff(moment(startDate), 'month') / 12) + 1;

    let date = startDate;
    const dates = {
      columnOrder: [],
      columns: {},
      tasks: {},
      monthViewSizes: [],
    };

    const currDate = moment(startDate);
    // if (months < 12) {
    //   endDate = moment(startDate).add(12, 'month');
    // }
    // if (months > 24) {
    //   endDate = moment(startDate).add(24, 'month');
    // }
    let currYear = currDate.format('YYYY');
    let countMonthInYear = 0;

    while (currDate <= moment(endDate)) {
      if (currYear === currDate.format('YYYY')) {
        countMonthInYear++;
      } else {
        dates.monthViewSizes.push({ date: currYear, countDays: countMonthInYear });
        currYear = currDate.format('YYYY');
        countMonthInYear = 1;
      }

      const data = initialData(currDate.format('YYYY'), currDate.format('MM'), 'yearMode');
      dates.columnOrder = [...dates.columnOrder, ...data.columnOrder];
      dates.columns = { ...dates.columns, ...data.columns };

      currDate.add(1, 'month');
    }

    if (countMonthInYear !== 0) {
      dates.monthViewSizes.push({ date: currYear, countDays: countMonthInYear });
    }

    return dates;
  }

  return undefined;
};

// export default initialData;
