import { createSlice, PayloadAction, createAsyncThunk, current } from '@reduxjs/toolkit';
import Api from '../../service/api';
import { IFilterPayload, IFilterReducer, ITestData, IUserFilter, PageFilterModel, PageFiltersResponse } from './filterReducer.model';

import axios from 'axios';
import { deleteUserFilters, getUserFilters, setUserFilters, updateUserFilters, getPCDFilters, getPageFilters } from './filtersThunk';
import { KeyValueModel } from '../../models/key-value.model';

const initialState: IFilterReducer = {
  getNeedListFilterStatus: Api.initialStatus,
  setNeedListFilterStatus: Api.initialStatus,
  deleteNeedListFilterStatus: Api.initialStatus,
  updateNeedListFilterStatus: Api.initialStatus,
  savedRequestsFilters: {
    need_form: null,
    deliverable: null,
    design_document: null,
    team: null,
    sandbox_need_form: null,
    sandbox_deliverable: null,
    sandbox_report_need_form: null,
    sandbox_report_deliverable: null,
  },
  savedMoreRequestsFilters: {
    need_form: null,
    deliverable: null,
    design_document: null,
    team: null,
    sandbox_need_form: null,
    sandbox_deliverable: null,
    sandbox_report_need_form: null,
    sandbox_report_deliverable: null,
  },
  pcdSubFilters: {},
  loadingPCD: false,
  pageFilters: {
    need_form: null,
    deliverable: null,
    design_document: null,
    team: null,
    sandbox_need_form: null,
    sandbox_deliverable: null,
    sandbox_report_need_form: null,
    sandbox_report_deliverable: null,
  },
  pageFiltersLoadingStatus: Api.initialStatus,
  savedReportingFilter: {},
  userFilters: {
    filters: [],
    shared_filters: [],
  },
  testLoading: false,
  testData: null,
  testError: '',
};

export const fetchTodos = createAsyncThunk('filterReducer/fetchTodos', async (value, thunkAPI) => {
  try {
    const response = await axios.get('https://jsonplaceholder.typicode.com/todos/2');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const filterSlice = createSlice({
  initialState,
  name: 'filterReducer',
  reducers: {
    saveStoreRequestsFilters(state, { payload }: PayloadAction<IFilterPayload>) {
      const currentState = current(state);
      state.savedRequestsFilters[payload.name] = payload.isStrictChange
        ? payload.data
        : { ...currentState.savedRequestsFilters[payload.name], ...payload.data };
    },

    saveStoreMoreRequestsFilters(state, { payload }: PayloadAction<any>) {
      state.savedMoreRequestsFilters[payload.name] = payload.data;
    },

    removeMoreRequestFilters(state, { payload }: PayloadAction<any>) {
      const currentSelectedFilters: string[] = current(state).savedMoreRequestsFilters[payload.name];
      // delete currentSelectedFilters[payload.filterName];
      state.savedMoreRequestsFilters[payload.name] = currentSelectedFilters.filter(f => f !== payload.filterName);
    },

    hardResetGlobalFilters(state) {
      state.savedRequestsFilters = initialState.savedRequestsFilters;
      state.savedMoreRequestsFilters = initialState.savedMoreRequestsFilters;

      localStorage.removeItem('sandbox');
      localStorage.removeItem('needsList');
      localStorage.removeItem('deliverable');
      localStorage.removeItem('pcd');
      localStorage.removeItem('ddl');
    },

    setReportingFilters(state, { payload }: PayloadAction<any>) {
      state.savedReportingFilter = payload;
    },
    hardResetFiltersReducer(state) {
      localStorage.removeItem('sandbox');
      localStorage.removeItem('needsList');
      localStorage.removeItem('deliverable');
      localStorage.removeItem('pcd');
      localStorage.removeItem('ddl');

      return initialState;
    },

    removeStoreRequestFilters(state, { payload }: PayloadAction<any>) {
      const currentSelectedFilters: string[] = current(state).savedRequestsFilters[payload.name];

      delete currentSelectedFilters[payload.filterName];

      state.savedRequestsFilters[payload.name] = currentSelectedFilters.filter(f => f !== payload.filterName);
    },
  },
  extraReducers: builder => {
    builder
      /**
       * Test
       */
      .addCase(fetchTodos.pending, state => {
        state.testLoading = true;
      })
      .addCase(fetchTodos.fulfilled, (state, { payload }: PayloadAction<ITestData>) => {
        state.testLoading = false;
        state.testData = payload;
      })
      .addCase(fetchTodos.rejected, (state, { payload }) => {
        state.testError = 'ERROR';
      })
      /**
       * getUserFilters
       */
      .addCase(getUserFilters.pending, state => {
        state.getNeedListFilterStatus = Api.requestStatus;
      })
      .addCase(getUserFilters.fulfilled, (state, { payload }: PayloadAction<IUserFilter>) => {
        state.userFilters = payload;
        state.getNeedListFilterStatus = Api.successStatus;
      })
      .addCase(getUserFilters.rejected, (state, { error }) => {
        state.getNeedListFilterStatus = Api.failStatus;
      })

      /**
       * setUserFilters
       */
      .addCase(setUserFilters.pending, state => {
        state.setNeedListFilterStatus = Api.requestStatus;
      })
      .addCase(setUserFilters.fulfilled, (state, { payload }: PayloadAction<IUserFilter>) => {
        state.userFilters = payload;
        state.setNeedListFilterStatus = Api.successStatus;
      })
      .addCase(setUserFilters.rejected, state => {
        state.setNeedListFilterStatus = Api.failStatus;
      })
      /**
       * deleteUserFilters
       */
      .addCase(deleteUserFilters.pending, state => {
        state.deleteNeedListFilterStatus = Api.requestStatus;
      })
      .addCase(deleteUserFilters.fulfilled, (state, { payload }: PayloadAction<IUserFilter>) => {
        state.userFilters = payload;
        state.deleteNeedListFilterStatus = Api.successStatus;
      })
      .addCase(deleteUserFilters.rejected, state => {
        state.deleteNeedListFilterStatus = Api.failStatus;
      })
      /**
       * updateUserFilters
       */
      .addCase(updateUserFilters.pending, state => {
        state.deleteNeedListFilterStatus = Api.requestStatus;
      })
      .addCase(updateUserFilters.fulfilled, (state, { payload }: PayloadAction<IUserFilter>) => {
        state.userFilters = payload;
        state.deleteNeedListFilterStatus = Api.successStatus;
      })
      .addCase(updateUserFilters.rejected, state => {
        state.deleteNeedListFilterStatus = Api.failStatus;
      })
      /**
       * getPCDFilters
       */
      .addCase(getPCDFilters.pending, state => {
        state.loadingPCD = true;
      })
      .addCase(getPCDFilters.fulfilled, (state, { payload }: PayloadAction<KeyValueModel<any[]>>) => {
        state.pcdSubFilters = payload;
        state.loadingPCD = false;
      })
      .addCase(getPCDFilters.rejected, state => {
        state.loadingPCD = false;
      })
      /**
       * getPageFilters
       */
      .addCase(getPageFilters.pending, state => {
        state.pageFiltersLoadingStatus = Api.requestStatus;
      })
      .addCase(getPageFilters.fulfilled, (state, { payload: { filterName, filters } }: PayloadAction<PageFiltersResponse>) => {
        const currentState = current(state);
        const addFilter = currentState.pageFilters[filterName]
          ? { [filterName]: { ...currentState.pageFilters[filterName], ...filters } }
          : { [filterName]: filters };
        state.pageFilters = {
          ...currentState.pageFilters,
          ...addFilter,
        };
        state.pageFiltersLoadingStatus = Api.successStatus;
      })
      .addCase(getPageFilters.rejected, state => {
        state.pageFiltersLoadingStatus = Api.failStatus;
      });
  },
});

export default filterSlice.reducer;
