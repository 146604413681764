import { Quill } from 'react-quill';
import QuillAutoDetectUrl from 'quill-auto-detect-url';
import Color from 'color';
import Mention from 'quill-mention';

const Link = Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);

    value = this.sanitize(value);
    node.setAttribute('href', value);
    if (value.includes('drive.google.com')) {
      node.setAttribute('data-status', 'new');
    }
    if (value.startsWith('www.')) {
      node.setAttribute('href', value.replace('www.', 'http://'));
    }
    return node;
  }
}

Quill.register(MyLink);
Quill.register('modules/autoDetectUrl', QuillAutoDetectUrl);
Quill.register(Mention);

const clearThemeColors = (node, delta) => {
  const Delta = Quill.import('delta');
  return delta.compose(
    new Delta().retain(delta.length(), {
      background: false,
    }),
  );
};

const clearTextColor = (node, delta) => {
  const Delta = Quill.import('delta');
  const theme = localStorage.getItem('theme');

  const availableColors = ['red', 'orange', 'yellow', 'lime', 'blue', 'violet', 'purple', 'gray'];

  const wrongThemeColor =
    node.style.color !== Color.rgb(theme === 'dark' ? '#22304d' : '#dfe1e5').toString() && !availableColors.includes(node.style.color);

  return delta.compose(
    new Delta().retain(delta.length(), {
      color: !wrongThemeColor ? node.style.color : false,
    }),
  );
};

export const basicModules = {
  autoDetectUrl: {
    urlRegularExpression: /(https?:\/\/|www\.)[\w-.]+\.[\w-.]+[\S]+/i,
  },
  clipboard: {
    matchers: [
      [Node.ELEMENT_NODE, clearThemeColors],
      [['span', 'strong', 'em'], clearTextColor],
    ],
  },
};
