import { createAsyncThunk } from '@reduxjs/toolkit';
import SubmittalsApi from '../../service/Api/submittals/submittalsApi';
import { SubmittalModel, SubmittalsMFType } from '../../models/submittals/submittals.model';
import { errorsSlice } from '../errors/errorsSlice';
import {
  IGetAllResponse,
  IGetListActiveResponse,
  IGetListSubmittalsResponse,
  IGetSpecificationDataPayload,
  IGetSubmittalsColumnSettingsResponse,
  IUpdateSubmittalsBulkEditsResponse,
} from './specAndSubs.model';
import { GlobalFiltersQueryModel } from '../../models';
import { settingsSlice } from '../globalSettings/settingsSlice';

const { setShowErrorData } = errorsSlice.actions;
const { setIsFirstHashRender } = settingsSlice.actions;

export const getListSubmittals = createAsyncThunk<
  IGetListSubmittalsResponse,
  { queryModel: GlobalFiltersQueryModel; callbackNavigation: (hash?: string) => void }
>('specAndSubs/getListSubmittals', async ({ callbackNavigation, queryModel }, { dispatch, rejectWithValue, getState }) => {
  try {
    const currentState = getState();
    const isFirstRenderWithURLHash = currentState?.settingsReducer?.isFirstRenderWithURLHash;
    const filterHash = new URLSearchParams(location.search).get(`submittal_form_${queryModel.type}_hash`);
    const generateUrlWithFiltersHash = hash => {
      if (isFirstRenderWithURLHash) {
        dispatch(setIsFirstHashRender());
      }
      if (typeof hash === 'string') {
        callbackNavigation(hash);
      } else {
        callbackNavigation();
      }
    };

    const projectParam = {
      ...queryModel,
      local_storage: JSON.parse(localStorage.getItem(`submittal_form_${queryModel.type}`)),
    };
    if (isFirstRenderWithURLHash && filterHash) {
      projectParam.filters_hash = `submittal_form_${queryModel.type}_hash=${filterHash}`;
    }

    const response: {
      response: {
        resource: SubmittalModel[];
        specifications: SubmittalsMFType[];
        filters_hash?: string | boolean;
      };
    } = await SubmittalsApi.getListSubmittalsForms(projectParam);

    generateUrlWithFiltersHash(response?.response?.filters_hash);

    return { type: queryModel.type, ...response.response };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    rejectWithValue(error.data);
  }
});

export const getSubmittalsColumnSettings = createAsyncThunk<IGetSubmittalsColumnSettingsResponse>(
  'specAndSubs/getSubmittalsColumnSettings',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response: {
        response: {
          setting: string;
        };
      } = await SubmittalsApi.getSubmittalsColumnSettings();

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      rejectWithValue(error.data);
    }
  },
);

export const setSubmittalsColumnSettings = createAsyncThunk<IGetSubmittalsColumnSettingsResponse, string>(
  'specAndSubs/setSubmittalsColumnSettings',
  async (columnSettings, { dispatch, rejectWithValue }) => {
    try {
      return await SubmittalsApi.setSubmittalsColumnSettings({ setting: columnSettings });
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      rejectWithValue(error.data);
    }
  },
);

export const updateSubmittalsBulkEdit = createAsyncThunk<
  IUpdateSubmittalsBulkEditsResponse,
  { data: { [key: string]: any }; callback?: () => void; type: 'input' | 'output' }
>('specAndSubs/updateSubmittalsBulkEdit', async ({ data, callback, type }, { dispatch, rejectWithValue }) => {
  try {
    const response = await SubmittalsApi.updateBulkEdit(data);

    callback && callback();

    return { ...response, type };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    rejectWithValue(error.data);
  }
});

export const getSpecTableData = createAsyncThunk<IGetAllResponse, IGetSpecificationDataPayload>(
  'specAndSubs/getSpecTableData',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await SubmittalsApi.getSpecTableData(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSpecTableListActive = createAsyncThunk<IGetListActiveResponse, IGetSpecificationDataPayload>(
  'specAndSubs/getSpecTableListActive',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await SubmittalsApi.getSpecTableListActive(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
