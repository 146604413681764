import { useMemo } from 'react';
import { useAppSelector } from '../../store/configure/configureStore';

export const useGetThemeClass = (styleClass: string, themeProp?: 'light' | 'dark') => {
  const theme = themeProp || useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  return useMemo(() => {
    if (theme === 'dark') {
      return styleClass + '-dark';
    } else {
      return styleClass + '-light';
    }
  }, [theme, styleClass]);
};
