import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import ContentSwitcher from '../../../../controls/ButtonComponents/ContentSwitcher/ContentSwitcher';
import OverflowMenuButton from '../../../../controls/ButtonComponents/OverflowMenuButton/OverflowMenuButton';
import ActionButton from '../../../../controls/ButtonComponents/ActionButton/ActionButton';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import ChipsLOD from '../../../../controls/Chips/ChipsLOD/ChipsLOD';
import Divider from '../../../../controls/Divider/Divider';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { LoginUserModel, RequestModel, WatcherGroupModel, WatcherModel } from '../../../../../models';
import { CardBreadcrumbs } from '../../../../common/CardBreadcrumbs';
import Popover from '@material-ui/core/Popover';
import Watchlist from '../../../../common/WatchingBtn/Watchlist';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { setHotList } from '../../../../../store/request/requestLogic(HOLD)';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import './HeaderStyles.scss';
import ChipsRevisionTag from '../../../../controls/Chips/ChipsRevisionTag/ChipsRevisionTag';
import StatusRevision from '../../../../controls/Status/StatusRevision/StatusRevision';
import DropdownDownloadList from '../../../../controls/DropdownDownloadList/DropdownDownloadList';
import { DropdownItemModel } from '../../../../../models/global';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import { DropdownMenuItem } from '../../../../controls/Dropdowns/DropdownParts';
import { RevisionsList } from './parts/RevisionsList/RevisionsList';
import { API_URI, DOMAIN_URI } from '../../../../../service/links';
import commonApi from '../../../../../service/Api/commonApi';
import FileHelper from '../../../../../helpers/FileHelper';
import fileDownload from 'js-file-download';
import cn from 'classnames';
import { BICorMentionSelect } from '../../../../controls/BICorMentionSelect/BICorMentionSelect';
import OvalButton from '../../../../controls/ButtonComponents/OvalButton/OvalButton';
import { DeliverableCardTabNamesModel } from '../../constans/constants';
import ReactResizeDetector from 'react-resize-detector';
import { pcdSlice } from '../../../../../store/pcd/pcdSlice';

const { resetSocketDeliverableHotlistState } = pcdSlice.actions;

const overflowMenuSelectItems = [
  {
    id: 'trash',
    title: 'Remove revision',
  },
  {
    id: 'download',
    title: 'Download PDF',
  },
];

interface DropdownItemModelExt extends DropdownItemModel {
  code?: string;
  alias?: string;
}

interface IProps {
  isDragRequestCard: boolean;
  selectedRequest: PCDCard;
  isCreateRevision: boolean;
  handleChangeCardView: () => void;
  requestHeadClose: () => void;
  breadcrumbs: PCDCard[] | RequestModel[] | null;
  selectRequestHandler: (id: number, isNavToSubtask?: boolean) => void;
  handleChangeRevision: (id: number, buildingId?: number) => void;
  isSeparateTab: boolean;
  openDeletePopup: () => void;
  login_user: LoginUserModel;
  onChangeActiveTab: (tab: DeliverableCardTabNamesModel) => void;
  pcdWatchers: WatcherModel[] | null;
  pcdWatchersGroups: WatcherGroupModel[] | null;
}

const Header: FC<IProps> = ({
  isDragRequestCard,
  selectedRequest,
  isCreateRevision,
  handleChangeCardView,
  requestHeadClose,
  breadcrumbs,
  selectRequestHandler,
  handleChangeRevision,
  isSeparateTab,
  openDeletePopup,
  login_user,
  onChangeActiveTab,
  pcdWatchers,
  pcdWatchersGroups,
}) => {
  const dispatch = useAppDispatch();
  const saveHotlistLoading = useAppSelector(state => state.requestReducer.saveHotlistLoading);
  const pcdInfoLoading = useAppSelector(state => state.pcdReducer.pcdInfoLoading);
  const socketHotlistState = useAppSelector(state => state.pcdReducer.socketHotlistState);
  const sandBoxCardIDHotList = useAppSelector(state => state.requestReducer.sandBoxCardIDHotList);

  const [isOpenedRevisions, setIsOpenedRevisions] = useState(null);
  const [isHotList, setIsHotList] = useState<boolean>(false);
  const [isHotListManually, setIsHotListManually] = useState<boolean>(false);

  const [isMenuDownloadListOpen, setIsMenuDownloadListOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRevisionIndex, setCurrentRevisionIndex] = useState<number | ''>('');

  // Building and level popover
  const [buildingLevelPopoverAnchor, setBuildingLevelPopoverAnchor] = useState(null);

  const isAccessToDeleteIssuedRevision = useMemo(() => {
    return login_user && login_user.role && !!Object.keys(login_user.role).filter(key => key === '1' || key === '4').length;
  }, [login_user]);

  const isAdmin = useMemo(() => {
    return login_user && login_user.role && !!Object.keys(login_user.role).filter(key => key === '1' || key === '4' || key === '5').length;
  }, [login_user]);

  const nextRevision = useMemo(() => {
    return selectedRequest && selectedRequest.revisions && selectedRequest.revisions[selectedRequest.revision + 1];
  }, [selectedRequest]);

  const filteredOverflowMenuItems = useMemo(() => {
    const allowDelete =
      (!isCreateRevision && selectedRequest?.revision && selectedRequest?.status === 'not_issued_yet' && isAdmin) ||
      (selectedRequest?.status === 'issued' && !nextRevision && isAccessToDeleteIssuedRevision && selectedRequest?.revision !== 0);

    if (allowDelete) {
      return overflowMenuSelectItems;
    } else {
      return overflowMenuSelectItems.filter(item => item.id !== 'trash');
    }
  }, [isAccessToDeleteIssuedRevision, isAdmin, nextRevision, selectedRequest, isCreateRevision]);

  const { is1440Resolution, isMobile } = GetResolution();
  const showSmall = isDragRequestCard || is1440Resolution;

  const resolutionName = useMemo(() => {
    if (isMobile) {
      return 'mobile';
    }

    if (showSmall) {
      return 'small';
    }

    return 'full';
  }, [showSmall, isMobile]);

  const themeClass = `${useGetThemeClass('b-header-card-deliverable')}-${resolutionName}`;
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  const truncateBreadcrumbsNumber = useMemo(() => {
    if (isMobile) {
      return 3;
    }

    if (isDragRequestCard || is1440Resolution) {
      return 2;
    }

    return 3;
  }, [isMobile, isDragRequestCard, is1440Resolution]);

  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (selectedRequest) {
      const isIDsHotList = sandBoxCardIDHotList.find(value => value === selectedRequest.id);
      if (isIDsHotList) {
        setIsHotListManually(true);
      }
      setIsHotList(selectedRequest.is_hot_list);
    }
  }, [selectedRequest, sandBoxCardIDHotList]);

  useEffect(() => {
    if (socketHotlistState.changedNow) {
      setIsHotList(socketHotlistState.is_hot_list);
      dispatch(resetSocketDeliverableHotlistState());
    }
  }, [socketHotlistState.changedNow]);

  const handleSetHotList = e => {
    e.stopPropagation();
    e.preventDefault();

    const project_id = +sessionStorage.getItem('active_project_id');

    dispatch(
      setHotList({
        project_id,
        entity_ids: [selectedRequest.id],
        entity: selectedRequest.nf ? 'request' : 'deliverable',
        action: isHotList ? 'remove' : 'add',
        accordion_alias: null,
      }),
    );
    setIsHotList(prevState => !prevState);
  };

  const handleMenuDownloadListOpen = e => {
    setAnchorEl(e.currentTarget);
    setIsMenuDownloadListOpen(true);
  };

  const closeMenuDownloadList = () => {
    setIsMenuDownloadListOpen(false);
    setAnchorEl(null);
  };

  const handleRevisionsPopover = e => {
    setIsOpenedRevisions(isOpenedRevisions ? null : e?.currentTarget || null);
  };

  const downloadRevision = async (revision: PCDCard) => {
    const url = new URL(`${DOMAIN_URI}${API_URI}export/${revision.id}/deliverable-pdf`);
    const response = await commonApi.getFile(url.href);
    const fileName = FileHelper.getFileName(response);
    fileDownload(response.data, fileName);
  };

  const getIconType = (value: string): string => {
    if (value === 'CL-RFI') {
      return 'cl-rfi';
    } else if (value === 'QC-D') {
      return 'qc';
    } else if (value === 'SO') {
      return 'sign-off';
    } else if (value === 'SD') {
      return 'shop-drawings';
    } else {
      return 'deliverables';
    }
  };

  const handleChangeOverflowMenuSelect = (value: string, itemId: string) => {
    if (value === 'download') {
      if (selectedRequest?.revisions?.length) {
        setIsOpenedRevisions(isOpenedRevisions ? null : anchorEl || null);
        return;
      }
      downloadRevision(selectedRequest);
    } else {
      openDeletePopup && openDeletePopup();
      closeMenuDownloadList();
    }
  };

  const renderDownloadListItem = (item: DropdownItemModelExt) => {
    const itemDropdown = (
      <>
        <div
          className={cn(`${themeClass}_overflowMenu`, {
            ['-delete']: item.id === 'trash',
          })}
        >
          <TokenIcon iconName={item.id} size={16} customClass={`${themeClass}_overflowMenu_svg_icon`} />
          <div className={`${themeClass}_overflowMenu_label`}> {item.title} </div>
        </div>
      </>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'md'}
        disabled={false}
        title={itemDropdown}
        value={item.id}
        onItemClick={handleChangeOverflowMenuSelect}
        tooltip={null}
        isSelected={!!anchorEl && isOpenedRevisions && item.id === 'download'}
        leftElem={null}
        leftElemClick={null}
        rightElem={null}
        rightElemClick={null}
      />
    );
  };

  const renderLinkButtonContent = () => {
    return (
      <>
        <CardLinkButton
          type={'deliverable'}
          size={'md'}
          icon={() => <TokenIcon iconName={getIconType(selectedRequest?.drawing?.type?.title)} size={16} />}
          title={`${selectedRequest?.drawing?.display_title_shot?.type || 'Title'}`}
          tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Deliverable name'}
        />
        <ChipsRevisionTag
          clickHandler={handleRevisionsPopover}
          processStatus={selectedRequest?.status}
          value={`R${currentRevisionIndex || 0}`}
          isInvalid={
            !!selectedRequest?.request_not_validated &&
            selectedRequest?.status !== 'superseded' &&
            selectedRequest?.status !== 'not_issued_yet'
          }
          isRedLabel={
            selectedRequest?.status === 'issued' && selectedRequest?.request_not_validated === 0 && selectedRequest?.request_outdated > 0
          }
        />
      </>
    );
  };

  const renderBreadcrumbs = () => {
    return (
      <>
        {!is1440Resolution && !isSeparateTab && breadcrumbs?.length > 0 ? <Divider direction={'vertical'} type={'srf-4'} /> : null}
        {!isSeparateTab ? (
          <CardBreadcrumbs
            breadcrumbs={breadcrumbs}
            selectedRequest={selectedRequest}
            openRequest={selectRequestHandler}
            isRequestLoading={pcdInfoLoading}
            changeRevision={handleChangeRevision}
            setNfLinkOpenId={null}
            isPcdCardPopUp={false}
            isMobile={isMobile}
            isSidePeek={isDragRequestCard || is1440Resolution}
            isTruncated
            truncateAfterNumber={truncateBreadcrumbsNumber}
          />
        ) : null}
      </>
    );
  };

  const renderCardStatus = () => {
    return (
      <div className={`${themeClass}_cardStatus`}>
        <StatusRevision status={selectedRequest?.status} review_state={selectedRequest?.review_state} isVisibleLabel />
      </div>
    );
  };

  const renderWatchlist = () => {
    return (
      <Watchlist
        type={'deliverable'}
        watchers={pcdWatchers || []}
        requestId={selectedRequest?.id}
        disabled={false}
        project_id={selectedRequest?.project_id}
        pcdParentId={selectedRequest?.parent_id}
        linkedGroups={pcdWatchersGroups || []}
      />
    );
  };

  const renderBuildingButtons = () => {
    return (
      <div className={`${themeClass}_buildingWatchers_buildingBtns`}>
        {typicalLayoutTitle.buildingName && <ChipsLOD type={'filled'} value={typicalLayoutTitle.buildingName} />}
        {typicalLayoutTitle.locationName && <ChipsLOD type={'filled'} value={typicalLayoutTitle.locationName} />}
        {selectedRequest?.drawing?.area && <ChipsLOD type={'filled'} value={selectedRequest?.drawing?.area?.title} />}
      </div>
    );
  };

  const renderBuildingsAndWatchers = () => {
    return (
      <div className={`${themeClass}_buildingWatchers`}>
        {isMobile ? (
          <OverflowMenuButton
            clickHandler={e => setBuildingLevelPopoverAnchor(e.currentTarget)}
            size={'md'}
            tooltip={'Building and level'}
          />
        ) : null}
        {!isMobile ? renderBuildingButtons() : null}
        {(!showSmall || isMobile) && renderWatchlist()}
      </div>
    );
  };

  const statusTagTooltipText = useMemo(() => {
    if (selectedRequest && !!selectedRequest?.requests.filter(item => item.validity_status === 'not_verified').length) {
      return 'There are linked NFs that require verification';
    }

    return '';
  }, [selectedRequest]);

  useEffect(() => {
    if (isCreateRevision) {
      setCurrentRevisionIndex(selectedRequest && selectedRequest?.revisions.at(-1).revision + 1);
    }
  }, [isCreateRevision]);

  useEffect(() => {
    if (selectedRequest?.revision || selectedRequest?.revision === 0) {
      setCurrentRevisionIndex(selectedRequest?.revision);
    }
  }, [selectedRequest]);

  const typicalLayoutTitle = useMemo(() => {
    if (selectedRequest) {
      const buildingName = `${selectedRequest?.building?.title || ''}`;
      let locationName;

      if (selectedRequest?.owner_type === 'location') {
        locationName = `${selectedRequest?.owner?.title || ''}`;
      } else {
        locationName = `${selectedRequest?.owner?.name || ''}`;
      }

      return { buildingName, locationName };
    }

    return { buildingName: '', locationName: '' };
  }, [selectedRequest]);

  const breadcrumbsActive = useMemo(() => {
    return !!breadcrumbs?.length;
  }, [breadcrumbs?.length]);

  return (
    <>
      <ReactResizeDetector targetRef={headerRef} handleHeight skipOnMount refreshMode="throttle" />
      <div className={`${themeClass}`} ref={headerRef}>
        <div
          className={cn(`${themeClass}_container`, {
            ['-hasBreadcrumbs']: breadcrumbsActive,
          })}
        >
          <div className={`${themeClass}_switcher`}>
            {!is1440Resolution && !isSeparateTab ? (
              <>
                {showSmall ? (
                  <ContentSwitcher type={'central-peek'} size={'sm'} clickHandler={handleChangeCardView} />
                ) : (
                  <ContentSwitcher type={'right-peek'} size={'sm'} clickHandler={handleChangeCardView} />
                )}
              </>
            ) : null}
            {!isMobile && breadcrumbsActive ? renderBreadcrumbs() : null}
            {isMobile ? renderLinkButtonContent() : null}
          </div>
          <div className={`${themeClass}_action`}>
            <div className={`${themeClass}_selectedFilter`}>
              {selectedRequest?.bic?.length ? (
                <BICorMentionSelect
                  showBasisTitle={!isDragRequestCard && !isMobile}
                  selectedRequest={selectedRequest}
                  type={'deliverable'}
                  forOpen={'bic'}
                />
              ) : (
                <OvalButton showTitle={!isDragRequestCard && !isMobile} disabled title={'Unassigned'} type={'bic-unassigned'} />
              )}
            </div>
            <div className={`${themeClass}_selectedFilter`}>
              {selectedRequest?.secondary_courts?.length ? (
                <BICorMentionSelect
                  selectedRequest={selectedRequest}
                  type={'deliverable'}
                  forOpen={'mention'}
                  onChangeActiveTab={onChangeActiveTab}
                  showBasisTitle={!isDragRequestCard && !isMobile}
                />
              ) : (
                <OvalButton showTitle={!isDragRequestCard && !isMobile} disabled title={'No mentions'} type={'mention-unassigned'} />
              )}
            </div>
            {isHotListManually && <ActionButton type={'hotlisted'} clickHandler={null} isMobile={isMobile} disabled />}
            {isHotList ? (
              <ActionButton isMobile={isMobile} type={'starred'} clickHandler={saveHotlistLoading.loading ? void 0 : handleSetHotList} />
            ) : (
              <ActionButton
                isMobile={isMobile}
                type={'not-starred'}
                clickHandler={saveHotlistLoading.loading ? void 0 : handleSetHotList}
              />
            )}
            <div onClick={handleMenuDownloadListOpen}>
              <OverflowMenuButton size={'sm'} tooltip={''} />
            </div>
            <DropdownDownloadList<DropdownItemModelExt>
              isMenuOpen={isMenuDownloadListOpen}
              customClassName={null}
              renderItemHeader={null}
              renderItemFooter={null}
              renderMenuItem={renderDownloadListItem}
              menuItems={filteredOverflowMenuItems}
              size={'md'}
              anchorEl={anchorEl}
              onOpen={null}
              onClose={closeMenuDownloadList}
            />
            {!isSeparateTab && (
              <SystemButton isMobile={isMobile} type={'close'} size={'md'} variant={'transparent'} clickHandler={e => requestHeadClose()} />
            )}
          </div>
          {!isMobile ? (
            <div className={`${themeClass}_linkBtn`}>
              {renderLinkButtonContent()}
              {!showSmall && (
                <div className={`${themeClass}_statusContainer`}>
                  <StatusRevision
                    status={selectedRequest?.status}
                    review_state={selectedRequest?.review_state}
                    tooltip={statusTagTooltipText}
                    isVisibleLabel
                  />
                </div>
              )}
            </div>
          ) : null}
          {!isMobile && showSmall && renderCardStatus()}
          {!isMobile && renderBuildingsAndWatchers()}
          {isMobile ? (
            <div className={`${themeClass}_mobileSecondRow`}>
              {renderCardStatus()}
              {renderBuildingsAndWatchers()}
            </div>
          ) : null}
          {showSmall && !isMobile && <div className={`${themeClass}_actionBtn`}>{renderWatchlist()}</div>}
          {isMobile && breadcrumbsActive ? <div className={`${themeClass}_breadcrumbs`}>{renderBreadcrumbs()}</div> : null}
        </div>
        {isOpenedRevisions && (
          <Popover
            id={'revisions'}
            open={!!isOpenedRevisions}
            anchorEl={isOpenedRevisions}
            onClose={handleRevisionsPopover}
            classes={{
              paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClass}_revisionsPaper`, {
                ['-mobile']: isMobile,
                ['-download']: anchorEl,
              }),
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: anchorEl ? 'right' : 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: anchorEl ? 'right' : 'left',
            }}
          >
            <RevisionsList
              type={'deliverable'}
              selectedRequest={selectedRequest}
              changeRevision={handleChangeRevision}
              forOpen={isMenuDownloadListOpen ? 'download' : 'normal'}
            />
          </Popover>
        )}
        <Popover
          id={'discipline-role'}
          open={!!buildingLevelPopoverAnchor}
          anchorEl={buildingLevelPopoverAnchor}
          onClose={() => setBuildingLevelPopoverAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
        >
          <div className={`${themeClass}_buildingLevelContent`}>
            {typicalLayoutTitle.buildingName && (
              <div className={`${themeClass}_buildingLevelContent_item`}>
                <ChipsLOD type={'filled'} value={typicalLayoutTitle.buildingName} />
              </div>
            )}
            {typicalLayoutTitle.locationName && (
              <div className={`${themeClass}_buildingLevelContent_item`}>
                <ChipsLOD type={'filled'} value={typicalLayoutTitle.locationName} />
              </div>
            )}
            {selectedRequest?.drawing?.area && (
              <div className={`${themeClass}_buildingLevelContent_item`}>
                <ChipsLOD type={'filled'} value={selectedRequest?.drawing?.area?.title} />
              </div>
            )}
          </div>
        </Popover>
        <div className={`${themeClass}_line_deliverable`}></div>
      </div>
    </>
  );
};

export default React.memo(Header);
