import { createLogic } from 'redux-logic';
import teamApi from '../../service/Api/team/teamApi';
import settingsApi from '../../service/Api/settingsApi';
import { TeamApi } from '../../service/Api/team/types';
import { payloadWithCallback, Team } from './types';
import {
  fetchDisciplinesRolesSuccess,
  fetchMembersSuccess,
  getPartyUsersSuccess,
  searchTeamUsersSuccess,
  linkUserToProjectSuccess,
  unlinkUserToProjectSuccess,
  getPartiesSuccess,
  addPartySuccess,
  unlinkPartySuccess,
  getCountNfForPartySuccess,
  getPartiesCancel,
  fetchMembersCancel,
} from './actions';
import { teamActions } from '.';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TitleModel, UserModel, UserPayloadModel } from '../../models';
import { KeyValueModel } from '../../models/key-value.model';
import { ErrorResponseModel } from '../../models/Api/response.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

const getMembersLogic = createLogic<any, Team.GetMembersPayload>({
  type: Team.Actions.FETCH_MEMBERS,
  latest: true,
  process({ action: { payload }, getState }, dispatch, done) {
    const state: any = getState();

    const { active_project_id } = state.userReducer;
    const callback = payload?.callback;
    const projectId = active_project_id || Number(sessionStorage.getItem('active_project_id'));
    const filtersParams = payload || {};

    if (filtersParams?.callback) {
      delete filtersParams?.callback;
    }

    teamApi
      .getMembers({ projectId, ...filtersParams })
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }

        if (callback) {
          callback();
        }
        dispatch(fetchMembersSuccess(response.data.team_tab));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+projectId === +currentActiveProjectId) {
          dispatch(fetchMembersCancel());
        }
        done();
      });
  },
});

export const fetchMembers = createAsyncThunk<TeamApi.IMember[], Team.GetMembersPayload>(
  'teamReducer/fetchMembers',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state: any = getState();

      const { active_project_id } = state.userReducer;
      const callback = payload?.callback;
      const projectId = payload.projectId || active_project_id || Number(sessionStorage.getItem('active_project_id'));
      const filtersParams = payload || {};

      if (filtersParams?.callback) {
        delete filtersParams?.callback;
      }

      const response = await teamApi.getMembers({ projectId, ...filtersParams });

      if (callback) {
        callback();
      }

      return response.data.team_tab;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const createPartyLogic = createLogic<any, payloadWithCallback<TeamApi.CreatePartyRequest & Team.DivisionPayload, TeamApi.IMember>>({
  type: Team.Actions.CREATE_PARTY,
  latest: true,
  process(
    {
      action: {
        payload: {
          data: { divisions, ...data },
          callback,
        },
      },
    },
    dispatch,
    done,
  ) {
    teamApi
      .createParty(data)
      .then(async response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        const projectId = Number(sessionStorage.getItem('active_project_id'));

        const createDivisionRequest = divisions.create.map(
          division =>
            new Promise(resolve => {
              teamApi
                .createSubdivision({
                  party_id: response.data.party.id,
                  title: division.title,
                  project_id: projectId,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        const updateDivisionRequest = divisions.update.map(
          division =>
            new Promise(resolve => {
              teamApi
                .updateSubdivision({
                  party_id: response.data.party.id,
                  subdivision_id: division.id,
                  title: division.title,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        const deleteDivisionRequest = divisions.delete.map(
          division =>
            new Promise(resolve => {
              teamApi
                .deleteSubdivision({
                  party_id: response.data.party.id,
                  subdivision_id: division.id,
                  project_id: projectId,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        await Promise.all([...createDivisionRequest, ...updateDivisionRequest, ...deleteDivisionRequest]);

        //@ts-ignore
        dispatch(teamActions.createUpdatePartySuccess({ data: response.data.party, action: 'create', temp_party_id: data?.temp_party_id }));
        if (callback) {
          callback(response.data.party);
        }
      })
      .catch(err => {
        dispatch(teamActions.createUpdatePartyFail(err));
        console.log(err);
      })
      .then(() => done());
  },
});

export const createParty = createAsyncThunk<
  TeamApi.CreatePartyResponsePayload,
  payloadWithCallback<TeamApi.CreatePartyRequest & Team.DivisionPayload, TeamApi.IMember>
>('teamReducer/createParty', async (payload, { dispatch, rejectWithValue }) => {
  const {
    data: { divisions, ...data },
    callback,
  } = payload;

  try {
    const response = await teamApi.createParty(data);
    const projectId = Number(sessionStorage.getItem('active_project_id'));

    const createDivisionRequest = divisions.create.map(
      division =>
        new Promise(resolve => {
          teamApi
            .createSubdivision({
              party_id: response.data.party.id,
              title: division.title,
              project_id: projectId,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    const updateDivisionRequest = divisions.update.map(
      division =>
        new Promise(resolve => {
          teamApi
            .updateSubdivision({
              party_id: response.data.party.id,
              subdivision_id: division.id,
              title: division.title,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    const deleteDivisionRequest = divisions.delete.map(
      division =>
        new Promise(resolve => {
          teamApi
            .deleteSubdivision({
              party_id: response.data.party.id,
              subdivision_id: division.id,
              project_id: projectId,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    await Promise.all([...createDivisionRequest, ...updateDivisionRequest, ...deleteDivisionRequest]);

    if (callback) {
      callback(response.data.party);
    }

    return { data: response.data.party, action: 'create', temp_party_id: data?.temp_party_id };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const updatePartyLogic = createLogic<any, payloadWithCallback<TeamApi.UpdatePartyRequest & Team.DivisionPayload, TeamApi.IMember>>({
  type: Team.Actions.UPDATE_PARTY,
  latest: true,
  process(
    {
      action: {
        payload: {
          data: { divisions, ...data },
          callback,
        },
      },
    },
    dispatch,
    done,
  ) {
    teamApi
      .updateParty(data)
      .then(async response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        const projectId = Number(sessionStorage.getItem('active_project_id'));

        const createDivisionRequest = divisions.create.map(
          division =>
            new Promise(resolve => {
              teamApi
                .createSubdivision({
                  party_id: response.data.party.id,
                  title: division.title,
                  project_id: projectId,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        const updateDivisionRequest = divisions.update.map(
          division =>
            new Promise(resolve => {
              teamApi
                .updateSubdivision({
                  party_id: response.data.party.id,
                  subdivision_id: division.id,
                  title: division.title,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        const deleteDivisionRequest = divisions.delete.map(
          division =>
            new Promise(resolve => {
              teamApi
                .deleteSubdivision({
                  party_id: response.data.party.id,
                  subdivision_id: division.id,
                  project_id: projectId,
                })
                .then(result => resolve(result))
                .catch(error => resolve(error));
            }),
        );

        await Promise.all([...createDivisionRequest, ...updateDivisionRequest, ...deleteDivisionRequest]);

        dispatch(
          //@ts-ignore
          teamActions.getListSubdivision({
            party_id: response.data.party.id,
          }),
        );
        //@ts-ignore
        dispatch(teamActions.createUpdatePartySuccess({ data: response.data.party, action: 'update' }));
        if (callback) {
          callback(response.data.party);
        }
      })
      .catch(err => {
        dispatch(teamActions.createUpdatePartyFail(err));
        console.log(err);
      })
      .then(() => done());
  },
});

export const updateParty = createAsyncThunk<
  TeamApi.UpdatePartyResponsePayload,
  payloadWithCallback<TeamApi.UpdatePartyRequest & Team.DivisionPayload, TeamApi.IMember>
>('teamReducer/updateParty', async (payload, { dispatch, rejectWithValue }) => {
  const {
    data: { divisions, ...data },
    callback,
  } = payload;

  try {
    const response = await teamApi.updateParty(data);
    const projectId = Number(sessionStorage.getItem('active_project_id'));

    const createDivisionRequest = divisions.create.map(
      division =>
        new Promise(resolve => {
          teamApi
            .createSubdivision({
              party_id: response.data.party.id,
              title: division.title,
              project_id: projectId,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    const updateDivisionRequest = divisions.update.map(
      division =>
        new Promise(resolve => {
          teamApi
            .updateSubdivision({
              party_id: response.data.party.id,
              subdivision_id: division.id,
              title: division.title,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    const deleteDivisionRequest = divisions.delete.map(
      division =>
        new Promise(resolve => {
          teamApi
            .deleteSubdivision({
              party_id: response.data.party.id,
              subdivision_id: division.id,
              project_id: projectId,
            })
            .then(result => resolve(result))
            .catch(error => resolve(error));
        }),
    );

    await Promise.all([...createDivisionRequest, ...updateDivisionRequest, ...deleteDivisionRequest]);

    dispatch(
      //@ts-ignore
      getListSubdivision({
        party_id: response.data.party.id,
      }),
    );

    if (callback) {
      callback(response.data.party);
    }

    return { data: response.data.party, action: 'update' };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const unlinkPartyFromProjectLogic = createLogic<any, TeamApi.LinkOrUnlinkPartyProject>({
  type: Team.Actions.UNLINK_PARTY_FROM_PROJECT,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { party_id } = payload;
    teamApi
      .unlinkPartyFromProject(payload)
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(unlinkPartySuccess({ party_id, data: result.data.party }));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const unlinkParty = createAsyncThunk<TeamApi.LinkOrUnlinkPartyPayload, TeamApi.LinkOrUnlinkPartyProject>(
  'teamReducer/unlinkParty',
  async (payload, { dispatch, rejectWithValue }) => {
    const { party_id } = payload;
    try {
      const response = await teamApi.unlinkPartyFromProject(payload);

      return { party_id, data: response.data.party };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getDisciplinesRolesLogic = createLogic({
  type: Team.Actions.FETCH_DISCIPLINES_ROLES,
  latest: true,
  process(_, dispatch, done) {
    teamApi
      .getDisciplinesRoles()
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        const { disciplines, roles } = response.data;
        dispatch(fetchDisciplinesRolesSuccess({ disciplines, roles }));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => done());
  },
});

export const fetchDisciplinesRoles = createAsyncThunk<Team.FetchDisciplinesRolesPayload, null>(
  'teamReducer/fetchDisciplinesRoles',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.getDisciplinesRoles();

      const { disciplines, roles } = response.data;

      return { disciplines, roles };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getCountNfForPartyLogic = createLogic<any, payloadWithCallback<TeamApi.GetPartyUsers>>({
  type: Team.Actions.GET_COUNT_NF_FOR_PARTY,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { data, callback } = payload;

    teamApi
      .getCountNfToParty(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }

        if (callback) {
          callback();
        }
        dispatch(getCountNfForPartySuccess(response.data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => done());
  },
});

export const getCountNfForParty = createAsyncThunk<TeamApi.NfCountForPartyData, payloadWithCallback<TeamApi.GetPartyUsers>>(
  'teamReducer/getCountNfForParty',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const response = await teamApi.getCountNfToParty(data);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getPartyUsersLogic = createLogic<any, TeamApi.GetPartyUsers>({
  type: Team.Actions.GET_PARTY_USERS,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { project_id, party_id } = payload;
    teamApi
      .getPartyUsers({ project_id, party_id })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(getPartyUsersSuccess(result.data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const getPartyUsers = createAsyncThunk<TeamApi.UsersTeam, TeamApi.GetPartyUsers>(
  'teamReducer/getPartyUsers',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, party_id } = payload;
    try {
      const projectId = project_id || +sessionStorage.getItem('active_project_id');

      const response = await teamApi.getPartyUsers({ project_id: projectId, party_id });

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const searchUsersTeamLogic = createLogic<any, TeamApi.SearchUsersTeam>({
  type: Team.Actions.SEARCH_USERS_TEAM,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { project_id, party_id, text } = payload;
    teamApi
      .searchUsersTeam({ project_id, party_id, text })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(searchTeamUsersSuccess(result.data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const searchTeamUsers = createAsyncThunk<TeamApi.UsersTeam, TeamApi.SearchUsersTeam>(
  'teamReducer/searchTeamUsers',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, party_id, text } = payload;
    try {
      const response = await teamApi.searchUsersTeam({ project_id, party_id, text });

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const linkUserToProjectLogic = createLogic<any, TeamApi.LinkOrUnlinkUserProject>({
  type: Team.Actions.LINK_USER_TO_PROJECT,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { project_id, party_id, users, callback } = payload;
    teamApi
      .linkUserToProject({ project_id, party_id, users })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        if (callback) {
          callback();
        }
        dispatch(linkUserToProjectSuccess(result.data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const linkUserToProject = createAsyncThunk<TeamApi.UsersTeam, TeamApi.LinkOrUnlinkUserProject>(
  'teamReducer/linkUserToProject',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, party_id, users, callback } = payload;
    try {
      const response = await teamApi.linkUserToProject({ project_id, party_id, users });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const unlinkUserToProjectLogic = createLogic<any, TeamApi.LinkOrUnlinkUserProject>({
  type: Team.Actions.UNLINK_USER_TO_PROJECT,
  latest: true,
  process({ action: { payload } }, dispatch, done) {
    const { project_id, party_id, users, callback } = payload;
    teamApi
      .unlinkUserToProject({ project_id, party_id, users })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        if (callback) {
          callback();
        }
        dispatch(unlinkUserToProjectSuccess(result.data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const unlinkUserToProject = createAsyncThunk<TeamApi.UsersTeam, TeamApi.LinkOrUnlinkUserProject>(
  'teamReducer/unlinkUserToProject',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, party_id, users, callback } = payload;
    try {
      const response = await teamApi.unlinkUserToProject({ project_id, party_id, users });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const createUserLogic = createLogic<any, TeamApi.CreateUserResponse>({
  type: Team.Actions.CREATE_USER_TO_PARTY,
  latest: true,
  process({ action }, dispatch, done) {
    const { data, callback } = action.payload;
    teamApi
      .createUser(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        if (callback) {
          callback();
        }
        dispatch(teamActions.createUpdateDeleteUserSuccess(response));
      })
      .catch(err => {
        dispatch(teamActions.updateCreateUserFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const createUserToParty = createAsyncThunk<
  { create: string },
  payloadWithCallback<UserPayloadModel>,
  {
    rejectValue: ErrorResponseModel;
  }
>('teamReducer/createUserToParty', async (payload, { dispatch, rejectWithValue }) => {
  const { data, callback } = payload;
  try {
    const response = await teamApi.createUser(data);

    if (callback) {
      callback();
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const updateUserLogic = createLogic<any, payloadWithCallback<UserPayloadModel>>({
  type: Team.Actions.UPDATE_USER_TO_PARTY,
  latest: true,
  process({ action }, dispatch, done) {
    const { data, callback } = action.payload;
    let updateUserAction;
    updateUserAction = async () => {
      if (data.image_to_upload) {
        const fileUploadResult = await settingsApi.filesUpload(data.image_to_upload);
        if (fileUploadResult.status) {
          data.image = fileUploadResult.data.filepath;
        }
      }
      const updateUserResult = await teamApi.updateUser(data);
      if (updateUserResult.status === false || updateUserResult.status === 400) {
        throw updateUserResult;
      }
      if (callback) {
        callback();
      }
      dispatch(teamActions.createUpdateDeleteUserSuccess());
    };
    updateUserAction()
      .catch(err => {
        console.log(err);
        dispatch(teamActions.updateCreateUserFail(err));
      })
      .then(() => done());
  },
});

export const updateUserToParty = createAsyncThunk<
  { update: string },
  payloadWithCallback<UserPayloadModel>,
  {
    rejectValue: ErrorResponseModel;
  }
>('teamReducer/updateUserToParty', async (payload, { dispatch, rejectWithValue }) => {
  const { data, callback } = payload;

  try {
    if (data.image_to_upload) {
      const fileUploadResult = await settingsApi.filesUpload(data.image_to_upload);
      if (fileUploadResult.status) {
        data.image = fileUploadResult.data.filepath;
      }
    }
    const updateUserResult = await teamApi.updateUser(data);

    if (updateUserResult.status === false || updateUserResult.status === 400) {
      throw updateUserResult;
    }

    if (callback) {
      callback();
    }

    return updateUserResult.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const deleteUserLogic = createLogic<any, TeamApi.DeleteUserResponse>({
  type: Team.Actions.DELETE_USER_TO_PARTY,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    teamApi
      .deleteUser(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.createUpdateDeleteUserSuccess(response));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const deleteUserToParty = createAsyncThunk<{ delete: string }, { user_id: number }>(
  'teamReducer/deleteUserToParty',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.deleteUser(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getListSubdivisionLogic = createLogic<any, TeamApi.ListSubdivisionResponse>({
  type: Team.Actions.GET_LIST_SUBDIVISION,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    teamApi
      .getListSubdivision(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.getListSubdivisionSuccess(response.data.subdivisions));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const getListSubdivision = createAsyncThunk<TeamApi.ListSubdivision[], { party_id: number }>(
  'teamReducer/getListSubdivision',
  async (payload, { dispatch, rejectWithValue }) => {
    const data = payload;
    try {
      const response = await teamApi.getListSubdivision(data);

      return response.data.subdivisions;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getPartiesLogic = createLogic<any, Team.GetPartiesPayload>({
  type: Team.Actions.GET_PARTIES,
  latest: true,
  cancelType: Team.Actions.GET_PARTIES_CANCEL,
  process({ action: { payload } }, dispatch, done) {
    const projectId = payload.projectId;

    teamApi
      .getParties(payload)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }

        dispatch(getPartiesSuccess(response.data.parties));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+projectId === +currentActiveProjectId) {
          dispatch(getPartiesCancel());
        }
        done();
      });
  },
});

export const getParties = createAsyncThunk<TeamApi.Party[], Team.GetPartiesPayload>(
  'teamReducer/getParties',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.getParties(payload);

      return response.data.parties;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const addPartyLogic = createLogic<any, payloadWithCallback<TeamApi.AddPartyRequest, TeamApi.IMember>>({
  type: Team.Actions.ADD_PARTY,
  latest: true,
  process(
    {
      action: {
        payload: { data, callback },
      },
    },
    dispatch,
    done,
  ) {
    teamApi
      .addParty(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }

        dispatch(addPartySuccess({ party: response.data.party, temp_party_id: data.temp_party_id }));
        if (callback) {
          callback(response.data.party);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => done());
  },
});

export const addParty = createAsyncThunk<TeamApi.AddCompanySuccessPayload, payloadWithCallback<TeamApi.AddPartyRequest, TeamApi.IMember>>(
  'teamReducer/addParty',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const response = await teamApi.addParty(data);

      if (callback) {
        callback(response.data.party);
      }

      return { party: response.data.party, temp_party_id: data.temp_party_id };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setContactPointProjectLogic = createLogic<any, TeamApi.UserResponse>({
  type: Team.Actions.SET_CONTACT_POINT_PROJECT,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    teamApi
      .setContactPointProject(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.setContactPointProjectSuccess(response));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const setContactPointProject = createAsyncThunk<TeamApi.UsersTeam, TeamApi.SetContactPointProjectPayloadModel>(
  'teamReducer/setContactPointProject',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.setContactPointProject(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setContactPointPartyLogic = createLogic<any, TeamApi.UserResponse>({
  type: Team.Actions.SET_CONTACT_POINT_PARTY,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    teamApi
      .setContactPointParty(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.setContactPointPartySuccess(response));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const setContactPointParty = createAsyncThunk<TeamApi.UsersTeam, TeamApi.SetContactPointProjectPayloadModel>(
  'teamReducer/setContactPointParty',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.setContactPointParty(payload);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getTeamFiltersLogic = createLogic({
  type: Team.Actions.GET_TEAM_FILTERS,
  latest: true,
  process({ getState }, dispatch, done) {
    const state: any = getState();
    const { active_project_id } = state.userReducer;
    const projectId = active_project_id || Number(sessionStorage.getItem('active_project_id'));

    teamApi
      .getFilters({ projectId })
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(teamActions.getFiltersSuccess(result.data));
      })

      .catch(err => {
        console.log(err);
      })

      .then(() => {
        done();
      });
  },
});

export const getFilters = createAsyncThunk<KeyValueModel<TitleModel>, null>(
  'teamReducer/getFilters',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { active_project_id } = state.userReducer;
      const projectId = active_project_id || Number(sessionStorage.getItem('active_project_id'));

      const response = await teamApi.getFilters({ projectId });

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setBuyoutDateLogic = createLogic<any, TeamApi.SetBuyoutDateRequest>({
  type: Team.Actions.SET_BUYOUT_DATE,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    teamApi
      .setBuyoutDate(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.setBuyoutDateSuccess(data));
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const setBuyoutDate = createAsyncThunk<TeamApi.SetBuyoutDateRequest, TeamApi.SetBuyoutDateRequest>(
  'teamReducer/setBuyoutDate',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await teamApi.setBuyoutDate(payload);

      return payload;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const createTemplatePartiesLogic = createLogic<any, TeamApi.CreateTemplatePayload>({
  type: Team.Actions.CREATE_TEMPLATE_PARTIES,
  latest: true,
  process({ action }, dispatch, done) {
    const { project_id, callback } = action.payload;
    teamApi
      .createTemplateParties({ project_id })
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(teamActions.createTemplatePartiesSuccess(response.data.team_tab));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        done();
      });
  },
});

export const createTemplateParties = createAsyncThunk<TeamApi.IMember[], TeamApi.CreateTemplatePayload>(
  'teamReducer/createTemplateParties',
  async (payload, { dispatch, rejectWithValue }) => {
    const { project_id, callback } = payload;
    try {
      const response = await teamApi.createTemplateParties({ project_id });

      if (callback) {
        callback();
      }

      return response.data.team_tab;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export default [
  // getMembersLogic,
  // createPartyLogic,
  // updatePartyLogic,
  // unlinkPartyFromProjectLogic,
  // getDisciplinesRolesLogic,
  // getCountNfForPartyLogic,
  // getPartyUsersLogic,
  // searchUsersTeamLogic,
  // linkUserToProjectLogic,
  // unlinkUserToProjectLogic,
  // createUserLogic,
  // updateUserLogic,
  // deleteUserLogic,
  // getListSubdivisionLogic,
  // getPartiesLogic,
  // addPartyLogic,
  // setContactPointProjectLogic,
  // setContactPointPartyLogic,
  // getTeamFiltersLogic,
  // setBuyoutDateLogic,
  // createTemplatePartiesLogic,
];
