import React from 'react';
import { Logo } from '../../controls/index';
import SVG from 'react-inlinesvg';
import { handleGetThemeClass } from '../../../helpers/designTokens';

import './NonLoginedPageStyles.scss';

class PlugView extends React.Component {
  state = {
    open: false,
  };

  handleOpenSupport = e => {
    e.preventDefault();
    this.setState({ open: true });
  };
  handleCloseSupport = () => {
    this.setState({ open: false });
  };

  render() {
    const getTheme = () => {
      return localStorage.getItem('theme') || 'light';
    };
    const themeClass = handleGetThemeClass('b-PlugView', localStorage.getItem('theme') || 'light');

    return (
      <div className={`${themeClass}`}>
        <Logo isFull themeProp={getTheme()} />
        <SVG src={require(`../../../assets/images/plug-view-${getTheme()}.svg`)} />
        <div className={`${themeClass}_info`}>
          <div className={`${themeClass}_info_title`}>Maintenance mode</div>
          <div className={`${themeClass}_info_container`}>
            <div className={`${themeClass}_info_date_text`}>
              <div>April 24, Wednesday</div>
              <div>02:00 AM - 05:00 AM EST</div>
            </div>
            <div className={`${themeClass}_info_text`}>
              <div>Dear user, we apologize for the inconvenience.</div>
              <div>The application will be off for technical maintenance. </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlugView;
