import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import Step2View from './Step2View';
import { BuildingModel, BuildingSaveFormApi, ProjectModel } from '../../../../../models';
import { getListDrawings, getPCDeliverables, getProjectDetails, getUFCVersions } from '../../../../../store/project/projectLogic';
import { createBuilding, deleteBuilding, updateBuilding } from '../../../../../store/phasePlan/phasePlanThunk';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { resetBuildingErrors } from '../../../../../store/phasePlan/phasePlanActions';
import { pcdSlice } from '../../../../../store/pcd/pcdSlice';

const { setLastPcdCardBuildingId } = pcdSlice.actions;

interface IProps {
  exitEditMode: () => void;
  containerRef: MutableRefObject<HTMLDivElement>;
}

const Step2Container: FC<IProps> = ({ containerRef, exitEditMode }) => {
  const { getProjectStatus, projectData, createBuildingStatus } = useAppSelector(state => state.phasePlan);

  const [buildings, setBuildings] = useState<BuildingModel<any>[]>(projectData.buildings || []);
  const [project, setProject] = useState<ProjectModel>(projectData || null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.values(projectData).length) {
      setProject(projectData);
      setBuildings(projectData.buildings || []);
    }
  }, [projectData]);

  useEffect(() => {
    if (createBuildingStatus.loaded) {
      const isExistNonCreatedBuilding = buildings.findIndex(b => !b.id);
      if (isExistNonCreatedBuilding + 1 > 0) {
        setBuildings(projectData.buildings);
      }
    }
  }, [createBuildingStatus.loaded]);

  const createBuildingFunc = (formData: BuildingModel<number[]>) => {
    formData.project_id = project.id;
    const formDatApi = new BuildingSaveFormApi(formData);
    if (formDatApi?.id) {
      dispatch(updateBuilding(formDatApi));
    } else {
      dispatch(createBuilding(formDatApi));
    }
    dispatch(getUFCVersions({ project_id: project.id, code_affiliation: 'uf' }));
  };

  const removeBuilding = (building, index) => () => {
    const buildingsCopy = [...buildings];
    if (building.id) {
      dispatch(deleteBuilding({ buildingId: building.id, projectId: project.id }));

      const currentBuildingLocalStorage = localStorage.getItem('pcd_current_building_id');
      if (+currentBuildingLocalStorage === building.id) {
        if (project.buildings?.[0]?.id === building.id) {
          localStorage.setItem('pcd_current_building_id', String(project.buildings?.[1]?.id));
          dispatch(setLastPcdCardBuildingId(project.buildings?.[1]?.id));
        } else {
          localStorage.setItem('pcd_current_building_id', String(project.buildings?.[0]?.id));
          dispatch(setLastPcdCardBuildingId(project.buildings[0]?.id));
        }
      }
    }

    buildingsCopy.splice(+index, 1);

    dispatch(resetBuildingErrors());
    setBuildings(buildingsCopy);
  };

  const handleChangeBuildingFormData = index => formData => {
    const buildingsCopy = [...buildings];
    buildingsCopy[index] = formData;
    setBuildings(buildingsCopy);
  };

  const handleAddBuilding = () => {
    const newBuildings = [...buildings];
    newBuildings.push(
      new BuildingModel({
        title: `Building ${buildings.length + 1}`,
      }),
    );
    setBuildings(newBuildings);
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  return (
    <Step2View
      createBuilding={createBuildingFunc}
      handleChangeBuildingFormData={handleChangeBuildingFormData}
      deleteBuilding={removeBuilding}
      buildings={buildings}
      project={project}
      handleAddBuilding={handleAddBuilding}
      getProjectStatus={getProjectStatus}
      exitEditMode={exitEditMode}
    />
  );
};
export default Step2Container;
