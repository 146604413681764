import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import EmptyStatePlaceholder from '../../../../controls/EmptyStatePlaceholder/EmptyStatePlaceholder';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { LocationCollectionModel, RequestBuildingModel, RequestModel, UserModel } from '../../../../../models';
import { createIncorporation, updateSubTaskStatus, updateTasks } from '../../../../../store/incorporation/incorporationThunk';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import CustomAccordion from '../../../../controls/CustomAccordion/CustomAccordion';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import IncorporationMainSnippet from '../../../../controls/IncorporationMainSnippet/IncorporationMainSnippet';
import { useCheckIfElementHaveScroll } from '../../../../../helpers/commonHooks';
import IncorporationFloorSnippet from '../../../../controls/IncorporationFloorSnippet/IncorporationFloorSnippet';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { IFeedData } from '../../../../../store/comments/commentReducer.model';
import PlaceholderInfo from '../../../../controls/PlaceholderInfo/PlaceholderInfo';
import { IncorporationTaskModel, UpdateSubTaskModel, UpdateTaskModel } from '../../../../../store/incorporation/incorporationReducer.model';
import StatusIncorporation from '../../../../controls/Status/StatusIncorporation/StatusIncorporation';
import { globalIncorporationStatusText } from '../../../../../helpers/IncorporationHelper';
import { isArrayEqual, isEmptyObject } from '../../../../../helpers/commonHelpers';
import * as validator from '../../../../../service/validator';
import { KeyValueNumberModel } from '../../../../../models/key-value.model';
import { CodeSelector } from '../../../../controls/Dropdowns/DropdownСomponents';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import AccessHelper from '../../../../../helpers/AccessHelper';
import ReactHtmlParser from 'react-html-parser';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import moment from 'moment';
import { CheckBox } from '../../../../controls';
import LevelsSelect from '../../controls/LevelsSelect/LevelsSelect';

import './IncorporationTabStyles.scss';

export interface checkedItemsModel {
  [key: number]: number[];
}

const validationPattern = {
  required: ['uf_ids', 'party_id'],
  requiredOne: [],
};

const validationPatternSubTask = {
  required: ['location_id'],
  requiredOne: [],
};

const mainTaskTemplate: UpdateTaskModel = {
  id: 0,
  status: 'not_started',
  uf_ids: null,
  due_date: '',
  selectedParty: {
    discipline: null,
    company: null,
    user: null,
  },

  note: '',
  isNew: true,
  subtasks: [],
};

const subTaskTemplate: UpdateSubTaskModel = {
  id: 0,
  location_id: null,
  due_date: '',
  status: 'pending',
  completed_at: null,
  author: null,
  isNew: true,
  selectedLevel: null,
};

interface IncorporationTabProps {
  isDragRequestCard?: boolean;
  levelsSelected: LocationCollectionModel[];
  typicalLayoutsSelected: any[];
  selectedRequest: RequestModel;
  feedData: IFeedData;
  incorporationHistory: string[];
  buildings: RequestBuildingModel[];
  levels: LocationCollectionModel[];
  userInfo: UserModel;
  isAccess: boolean;
  bodyContainerRef: MutableRefObject<HTMLDivElement>;
  handleGlobalIncorporation: () => void;
}

const IncorporationTab: React.FC<IncorporationTabProps> = ({
  isDragRequestCard,
  levelsSelected,
  typicalLayoutsSelected,
  selectedRequest,
  feedData,
  incorporationHistory,
  buildings,
  levels,
  userInfo,
  isAccess,
  bodyContainerRef,
  handleGlobalIncorporation,
}) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-cardRequest-incorporationTab');
  const themeHistoryClass = useGetThemeClass('b-incorporationHistory');
  const dialogClass = useGetThemeClass('dialogPopUp');
  const { sizeResolution, isTablet, isMobile } = GetResolution();
  const offset = new Date().getTimezoneOffset();

  const active_project_id = useAppSelector(state => state.userReducer?.active_project_id);
  const allLevels = useAppSelector(state => state.projectReducer?.projectInfo?.levels?.[active_project_id]);
  const allBuildings = useAppSelector(state => state?.projectReducer?.projectInfo?.buildings?.[active_project_id]);
  const allTypicalLayouts = useAppSelector(state => state.projectReducer.projectInfo?.typical_layouts?.[active_project_id]);

  const isGlobalIncorporated = useAppSelector(state => state.incorporationReducer.isGlobalIncorporated);
  const globalIncorporationLoading = useAppSelector(state => state.incorporationReducer.globalIncorporationLoading);
  const incorporationStatus = useAppSelector(state => state.incorporationReducer.incorporationStatus);
  const incorporationListData = useAppSelector(state => state.incorporationReducer.incorporationList);
  const { status: globalStatus, text: globalStatusText } = globalIncorporationStatusText(incorporationListData);

  const incorporationListRef = useRef(null);

  const [isOpenCreateIncorporationPopUp, setIsOpenCreateIncorporationPopUp] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isOpenedIncorporations, setIsOpenedIncorporations] = useState<boolean>(true);
  const [isOpenedHistory, setIsOpenedHistory] = useState<boolean>(false);
  const [openedItems, setOpenedItems] = useState<number[]>([]);
  const [checkedTasks, setCheckedTasks] = useState<checkedItemsModel>({});
  const [editableList, setEditableList] = useState<UpdateTaskModel[]>(null);
  const [initialEditableList, setInitialEditableList] = useState<UpdateTaskModel[]>(null);
  const [editableListErrors, setEditableListErrors] = useState<KeyValueNumberModel<string[]> | null>(null);
  const [editableListErrorsSubTasks, setEditableListErrorsSubTasks] = useState<KeyValueNumberModel<string[]> | null>(null);
  const [selectedCustomTaskLevels, setSelectedCustomTaskLevels] = useState<any>([]);
  const [selectedCustomTaskTypicalLayouts, setSelectedCustomTaskTypicalLayouts] = useState<any>([]);
  const [selectedCustomTaskUfs, setSelectedCustomTaskUfs] = useState<any>(null);
  const [selectedNewSubTaskLevels, setSelectedNewSubTaskLevels] = useState<any>([]);
  const [selectedNewSubTaskTypicalLayouts, setSelectedNewSubTaskTypicalLayouts] = useState<any>([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState([]);
  const [isTaskStatusChangePopupOpen, setIsTaskStatusChangePopupOpen] = useState(false);
  const [isTaskStatusNotShowAgain, setIsTaskStatusNotShowAgain] = useState(false);
  const [taskStatusChangeType, setTaskStatusChangeType] = useState(null);
  const [taskStatusChangeId, setTaskStatusChangeId] = useState(null);
  const [isSaveEditPopupOpen, setIsSaveEditPopupOpen] = useState(false);

  const handleOpenAccordion = (type: 'incorporations' | 'history') => {
    const isOpenedAccordion = type === 'incorporations' ? isOpenedIncorporations : isOpenedHistory;
    const openAccordion = type === 'incorporations' ? setIsOpenedIncorporations : setIsOpenedHistory;
    openAccordion(!isOpenedAccordion);
  };

  const incorporationListHaveScroll = useCheckIfElementHaveScroll(incorporationListRef);
  const incorporationItems = useMemo(() => {
    return incorporationListData || [];
  }, [incorporationListData]);

  const generateEditableList = () => {
    if (incorporationListData?.length) {
      const listForEditing = [];

      incorporationListData.forEach(mainTask => {
        const data: UpdateTaskModel = {
          id: mainTask.id || null,
          status: mainTask.status,
          uf_ids: [...mainTask.uf] || null,
          due_date: mainTask.due_date || null,
          selectedParty: {
            discipline: mainTask.discipline_id
              ? {
                  id: mainTask.discipline_id,
                  title: mainTask.discipline_title,
                  text_color: mainTask.discipline_text_color,
                }
              : null,
            company: mainTask.party_id
              ? {
                  id: mainTask.party_id,
                  title: mainTask.party_title,
                  image: mainTask.party_image,
                }
              : null,
            user: mainTask.user_id
              ? {
                  id: mainTask.user_id,
                  title: mainTask.user_title,
                  image: mainTask.user_image,
                }
              : null,
          },
          note: mainTask.note || '',
          subtasks: mainTask.subtasks.map(item => {
            return {
              id: item.id || null,
              location_id: item.level_id || null,
              due_date: item.due_date || null,
              status: item.status,
              completed_at: item.completed_at,
              author: item.author,
              building_title: item.building_title,
              selectedLevel: {
                id: item.level_id || null,
                title: item.level_title || null,
                building_id: item.building_id,
                value: item.level_id || null,
              },
            };
          }),
        };

        listForEditing.push(data);
      });

      setEditableList(listForEditing);
      setInitialEditableList(listForEditing);
    } else {
      setEditableList(null);
      setInitialEditableList(null);
    }
  };

  const handleAddIncorporatedItem = () => {
    setIsEditMode(true);
    addNewTask(true);
  };

  const hasIncorporations = useMemo(() => {
    generateEditableList();
    return !!incorporationListData?.length;
  }, [incorporationItems]);

  const handleCloseEditMode = () => {
    setIsEditMode(false);
    setEditableListErrors(null);
    setEditableListErrorsSubTasks(null);
    setOpenedItems([]);
    generateEditableList();
  };

  const handleOpenTaskStatusPopup = () => {
    if (localStorage.getItem('requestIncorporationChangeStatusPopup') === 'no') {
      handleChangeTaskStatusPopup(taskStatusChangeId, taskStatusChangeType);
    } else {
      setIsTaskStatusChangePopupOpen(true);
    }
  };

  const handleCloseTaskStatusPopup = () => {
    localStorage.setItem('requestIncorporationChangeStatusPopup', isTaskStatusNotShowAgain ? 'no' : 'yes');
    setIsTaskStatusChangePopupOpen(false);
  };

  const toggleShowStatusPopup = () => {
    setIsTaskStatusNotShowAgain(prevState => !prevState);
  };

  const isEditListChanged = useMemo(() => {
    if (editableList && initialEditableList) {
      const sortedEditArray = [...editableList].sort((a, b) => a.id - b.id);
      const sortedInitialEditArray = [...initialEditableList].sort((a, b) => a.id - b.id);

      return !isArrayEqual(sortedEditArray, sortedInitialEditArray);
    }
  }, [initialEditableList, editableList]);

  const isRemovedDiscardedOrIncorporatedTasks = useMemo(() => {
    if (!editableList || !initialEditableList) {
      return false;
    }

    let isRemoved = false;

    for (const task of initialEditableList) {
      if (task.status === 'completed' || task.status === 'not_required') {
        if (!editableList.some(editedTask => editedTask.id === task.id)) {
          isRemoved = true;
          break;
        }
      }

      for (const subTask of task.subtasks) {
        if (subTask.status === 'incorporated' || subTask.status === 'discarded') {
          const editedTask = editableList.find(editedTask => editedTask.id === task.id);
          if (!editedTask || !editedTask.subtasks.some(editedSubTask => editedSubTask.id === subTask.id)) {
            isRemoved = true;
            break;
          }
        }
      }

      if (isRemoved) {
        break;
      }
    }

    return isRemoved;
  }, [initialEditableList, editableList]);

  const handleSaveEditableList = () => {
    const errorsObject = {};
    const errorSubTasks = {};

    const data = editableList.map(mainTask => {
      const ufsArray = mainTask.uf_ids?.map(item => item.id);
      const errorsObjectSubTasks = {};

      const subTasks = mainTask.subtasks.map(subTask => {
        const validationDataSubTask = {
          id: subTask.isNew ? 0 : subTask.id,
          location_id: subTask.selectedLevel?.id || null,
          due_date: subTask.due_date || null,
        };

        const { errors: subTaskErrors } = validator.validate(validationPatternSubTask, validationDataSubTask);
        const subErrorsKeys = Object.keys(subTaskErrors);
        if (!isEmptyObject(subTaskErrors)) {
          errorsObjectSubTasks[subTask.id] = subErrorsKeys;
        }

        return validationDataSubTask;
      });

      const validationData = {
        id: mainTask.isNew ? 0 : mainTask.id,
        uf_ids: ufsArray?.length ? ufsArray : null,
        party_id: mainTask.selectedParty?.company?.id || null,
        user_id: mainTask.selectedParty?.user?.id || null,
        discipline_id: mainTask.selectedParty?.discipline?.id || null,
        note: mainTask.note,
        subtasks: subTasks,
      };

      const { errors: mainTaskErrors } = validator.validate(validationPattern, validationData);

      const errorsKeys = Object.keys(mainTaskErrors);
      const partyErrorIndex = errorsKeys.findIndex(key => key === 'party_id');
      if (partyErrorIndex !== -1) {
        errorsKeys.splice(partyErrorIndex, 1, 'company');
      }

      if (!isEmptyObject(mainTaskErrors)) {
        errorsObject[mainTask.id] = errorsKeys;
      }

      if (!isEmptyObject(errorsObjectSubTasks)) {
        errorSubTasks[mainTask.id] = errorsObjectSubTasks;
      }

      return validationData;
    });

    if (!isEmptyObject(errorsObject) || !isEmptyObject(errorSubTasks)) {
      setEditableListErrors(errorsObject);
      setEditableListErrorsSubTasks(errorSubTasks);
      return;
    }

    dispatch(
      updateTasks({
        id: selectedRequest.id,
        tasks: data,
        callback: () => {
          setIsEditMode(false);
          setEditableListErrors(null);
          setEditableListErrorsSubTasks(null);
          handleCloseSaveEditPopup();
        },
      }),
    );
  };

  const handleOpenSaveEditPopup = () => {
    setIsSaveEditPopupOpen(true);
  };

  const handleCloseSaveEditPopup = () => {
    setIsSaveEditPopupOpen(false);
  };

  const handleSaveCustomIncorporation = () => {
    const allLocationIds = selectedCustomTaskLevels
      .map(obj => obj.id)
      .concat(selectedCustomTaskTypicalLayouts.flatMap(obj => obj.locations.map(loc => loc.id)));

    const location_ids = Array.from(new Set(allLocationIds));

    const data = {
      completed: true,
      uf_ids: selectedCustomTaskUfs.map(item => item.id),
      location_ids,
      party_id: userInfo.parties[0]?.id || null,
      user_id: userInfo.id || null,
      discipline_id: null,
      note: '',
    };

    dispatch(
      createIncorporation({
        id: selectedRequest.id,
        data,
        callback: () => {
          handleCancelCustomIncorporation();
        },
      }),
    );
  };

  const handleCancelCustomIncorporation = () => {
    setIsOpenCreateIncorporationPopUp(false);
    setSelectedCustomTaskUfs(null);
    setSelectedCustomTaskLevels([]);
    setSelectedCustomTaskTypicalLayouts([]);
  };

  const generateLevelsGroups = () => {
    const groupValue = [];
    const buildingsData = buildings.reduce((acc, item) => {
      acc[item.id] = item.title;
      return acc;
    }, {});

    if (levels.length) {
      levels.forEach(item => {
        if (!groupValue.includes(item.building_id)) {
          groupValue.push(item.building_id);
        }
      });
    }
    return groupValue.map(item => {
      return {
        value: item,
        title: buildingsData[item] || '',
      };
    });
  };

  const generateLevelsMenuItems = () => {
    return levels?.map(item => {
      return {
        ...item,
        group: item.building_id,
      };
    });
  };

  const handleExpandItem = id => {
    let array = [...openedItems];

    if (array.includes(id)) {
      array = array.filter(item => item !== id);
      setOpenedItems(array);
    } else {
      array.push(id);
      setOpenedItems(array);
    }
  };

  const handleCheckTask = (data: IncorporationTaskModel) => {
    setCheckedTasks(prevCheckedTasks => {
      const updatedCheckedTasks = { ...prevCheckedTasks };

      if (updatedCheckedTasks[data.id]) {
        delete updatedCheckedTasks[data.id];
      } else {
        updatedCheckedTasks[data.id] = data.subtasks.filter(item => item.status === 'pending').map(item => item.id);
      }

      return updatedCheckedTasks;
    });
  };

  const handleCheckSubTask = (parentTaskId: number | string, subTaskId: number | string) => {
    setCheckedTasks(prevCheckedTasks => {
      const updatedCheckedTasks = { ...prevCheckedTasks };

      if (updatedCheckedTasks[parentTaskId]?.includes(subTaskId)) {
        const checkedSubTasks = updatedCheckedTasks[parentTaskId].filter(item => item !== subTaskId);
        if (checkedSubTasks.length) {
          updatedCheckedTasks[parentTaskId] = checkedSubTasks;
        } else {
          delete updatedCheckedTasks[parentTaskId];
        }
      } else {
        updatedCheckedTasks[parentTaskId] = [...(updatedCheckedTasks[parentTaskId] || []), subTaskId];
      }

      return updatedCheckedTasks;
    });
  };

  const handleChangeTaskStatus = (mainTaskId: number | string, type: 'incorporated' | 'discarded') => {
    if (checkedTasks[mainTaskId]?.length) {
      const { [mainTaskId]: removedTask, ...restCheckedTasks } = checkedTasks;
      const data = {
        subtask_ids: removedTask,
        status: type,
      };
      setCheckedTasks(restCheckedTasks);
      dispatch(updateSubTaskStatus({ id: selectedRequest.id, data }));
    } else {
      setTaskStatusChangeId(mainTaskId);
      setTaskStatusChangeType(type);
      handleOpenTaskStatusPopup();
    }
  };

  const handleChangeTaskStatusPopup = (mainTaskId: number | string, type: 'incorporated' | 'discarded') => {
    const mainTask = editableList.find(item => item.id === mainTaskId);
    const subtaskIds = mainTask?.subtasks?.filter(subtask => subtask.status === 'pending').map(subtask => subtask.id) || [];

    const data = {
      subtask_ids: subtaskIds,
      status: type,
    };

    dispatch(
      updateSubTaskStatus({
        id: selectedRequest.id,
        data,
        callback: () => {
          handleCloseTaskStatusPopup();
        },
      }),
    );
  };

  const handleChangeSubTaskStatus = (mainTaskId: number | string, subTaskId: number | string, type: 'incorporated' | 'discarded') => {
    const data = {
      subtask_ids: [subTaskId],
      status: type,
    };

    setCheckedTasks(prevCheckedTasks => {
      const updatedCheckedTasks = { ...prevCheckedTasks };

      if (updatedCheckedTasks[mainTaskId]) {
        updatedCheckedTasks[mainTaskId] = updatedCheckedTasks[mainTaskId].filter(item => item !== subTaskId);
        if (updatedCheckedTasks[mainTaskId].length === 0) {
          delete updatedCheckedTasks[mainTaskId];
        }
      }

      dispatch(updateSubTaskStatus({ id: selectedRequest.id, data }));
      return updatedCheckedTasks;
    });
  };

  const handleChange = (id: number | string, fieldName: string, value, type?: 'party') => {
    const newEditableList = [...editableList];
    const taskIndex = editableList.findIndex(item => item.id === id);
    let taskObject = { ...editableList[taskIndex] };

    if (type === 'party') {
      taskObject = {
        ...taskObject,
        selectedParty: { ...value },
      };

      newEditableList.splice(taskIndex, 1, taskObject);
      setEditableList(newEditableList);
    } else {
      setEditableList(prevList => {
        return prevList.map(task => {
          if (id === task.id) {
            const updatedTask = { ...task };
            const fieldUpdates = {
              uf_ids: value,
              note: value,
            };

            return { ...updatedTask, [fieldName]: fieldUpdates[fieldName] };
          }
          return task;
        });
      });
    }
  };

  const updateNestedValue = (
    list: UpdateTaskModel[],
    parentTaskId: number | string,
    subTaskId: number | string,
    updateField: 'selectedLevel' | 'due_date',
    updateValue: any,
  ) => {
    return list.map(task => {
      if (parentTaskId === task.id) {
        const updatedSubtasks = task.subtasks.map(sub => {
          if (subTaskId === sub.id) {
            const selectedLevel = updateField === 'selectedLevel' ? updateValue : sub.selectedLevel;
            return {
              ...sub,
              [updateField]: updateValue,
              building_title: buildings.find(building => building.id === selectedLevel?.building_id)?.title || '',
            };
          }
          return sub;
        });
        return { ...task, subtasks: updatedSubtasks };
      }
      return task;
    });
  };

  const handleChangeLevel = (parentTaskId: number | string, subTaskId: number | string, item: number | number[]) => {
    const newEditableList = updateNestedValue(editableList, parentTaskId, subTaskId, 'selectedLevel', item);
    setEditableList(newEditableList);
  };

  const handleChangeDueDate = (parentTaskId: number | string, subTaskId: number | string, date: string) => {
    const newEditableList = updateNestedValue(editableList, parentTaskId, subTaskId, 'due_date', date);
    setEditableList(newEditableList);
  };

  const handleDeleteItem = (type: 'task' | 'subTask', id: number | string, parentTaskId?: number | string) => {
    setEditableList(prevList => {
      if (type === 'task') {
        return prevList.filter(task => task.id !== id);
      }

      const updatedList = prevList.map(task => {
        if (task.id === parentTaskId) {
          const updatedSubtasks = task.subtasks.filter(sub => sub.id !== id);
          return { ...task, subtasks: updatedSubtasks };
        }
        return task;
      });

      const isEmptySubTaskList = updatedList.some(task => task.id === parentTaskId && task.subtasks.length === 0);
      if (isEmptySubTaskList) {
        return updatedList.filter(task => task.id !== parentTaskId);
      }

      return updatedList;
    });
  };

  const createMainTaskTemplate = () => {
    const newItemsLength = editableList?.filter(item => item.isNew)?.length;

    let subTasks = [];
    if (levelsSelected?.length || typicalLayoutsSelected?.length) {
      levelsSelected?.forEach((level, index) => {
        const subItem = {
          id: `subTemplateLevel-${(newItemsLength || 0) + 1}-${index + 1}`,
          location_id: level.id,
          due_date: '',
          status: 'pending',
          completed_at: null,
          building_title: buildings.find(building => building.id === level.building_id)?.title || '',
          author: null,
          isNew: true,
          selectedLevel: { ...level, value: level.id },
        };

        subTasks.push(subItem);
      });

      typicalLayoutsSelected?.forEach(typical => {
        typical.locations.forEach((location, index) => {
          const level = allLevels.find(l => l.id === (location?.id || location));

          if (level) {
            const subItem = {
              id: `subTemplateTypical-${(newItemsLength || 0) + 1}-${index + 1}`,
              location_id: level.id,
              due_date: '',
              status: 'pending',
              completed_at: null,
              building_title: buildings.find(building => building.id === level.building_id)?.title || '',
              author: null,
              isNew: true,
              selectedLevel: { ...level, value: level.id },
            };

            subTasks.push(subItem);
          }
        });
      });
    } else {
      subTasks = [
        {
          id: `subTemplate-${(newItemsLength || 0) + 1}-1`,
          location_id: null,
          due_date: '',
          status: 'pending',
          completed_at: null,
          building_title: '',
          author: null,
          isNew: true,
          selectedLevel: null,
        },
      ];
    }

    return {
      ...mainTaskTemplate,
      id: `template-${(newItemsLength || 0) + 1}`,
      subtasks: subTasks,
    };
  };

  const addNewTask = (isFirstTask?: boolean) => {
    const editTemplate = createMainTaskTemplate();
    // @ts-ignore
    setEditableList([...(editableList || []), editTemplate]);
    if (isFirstTask) {
      setInitialEditableList([editTemplate]);
    }

    handleExpandItem(editTemplate.id);

    setTimeout(() => {
      if (isDragRequestCard || isTablet) {
        if (bodyContainerRef.current) {
          bodyContainerRef.current.scrollTo({ top: incorporationListRef.current.scrollHeight, behavior: 'smooth' });
        }
      } else {
        if (incorporationListRef.current) {
          incorporationListRef.current.scrollTo({ top: incorporationListRef.current.scrollHeight, behavior: 'smooth' });
        }
      }
    }, 200);
  };

  const createSubTaskTemplate = (parentTaskId: number | string, level, index: number, isTypical: boolean) => {
    let newItemsLength;
    editableList?.forEach(item => {
      if (parentTaskId === item.id) {
        newItemsLength = item.subtasks.filter(sub => sub.isNew).length;
      }
    });

    return {
      ...subTaskTemplate,
      location_id: level.id,
      selectedLevel: level,
      building_title: buildings.find(building => building.id === level.building_id)?.title || '',
      id: `subTemplate-${isTypical ? 'typical' : 'level'}-${(newItemsLength || 0) + 1}-${index}`,
    };
  };

  const addNewSubTask = (parentTaskId: number | string) => {
    const editSubTemplateLevels = selectedNewSubTaskLevels.map((level, index) => createSubTaskTemplate(parentTaskId, level, index, false));
    const editSubTemplateTypicals = selectedNewSubTaskTypicalLayouts.flatMap(typical =>
      typical.locations.map((level, index) => createSubTaskTemplate(parentTaskId, level, index, true)),
    );

    setEditableList(prevList => {
      return prevList.map(task => {
        if (task.id === parentTaskId) {
          return { ...task, subtasks: [...task.subtasks, ...editSubTemplateLevels, ...editSubTemplateTypicals] };
        }
        return task;
      });
    });

    setSelectedNewSubTaskLevels([]);
    setSelectedNewSubTaskTypicalLayouts([]);
  };

  const incorporationData = isEditMode ? editableList : incorporationListData;

  const isDeclinedNfCard = selectedRequest?.status === 'declined';

  const renderEmptyContainer = () => {
    return (
      <div className={`${themeClass}_emptyStateContainer`}>
        <div className={`${themeClass}_emptyStateContainer_placeholderBlock`}>
          <EmptyStatePlaceholder
            type={
              selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided'
                ? 'nf-card-incorporation-default'
                : 'nf-card-incorporation-secondary'
            }
            customClassName={cn(`${themeClass}_emptyStateContainer_image`, {
              ['-isMobile']: isMobile,
            })}
          />
          <div className={`${themeClass}_emptyStateContainer_placeholderBlock_infoBlock`}>
            <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock`}>
              {selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided' ? (
                <>
                  <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_title`}>Incorporation</div>
                  <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_text`}>
                    There are no incorporation tasks
                  </div>
                  <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_text`}>
                    Start with configuring the incorporation tasks or save an incorporated item {isMobile ? 'on desktop' : 'right away'}
                  </div>
                </>
              ) : (
                <>
                  <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_title`}>Incorporation</div>
                  <div className={`${themeClass}_emptyStateContainer_placeholderBlock_textBlock_text`}>
                    The incorporation process begins with the submission of the NF response.
                  </div>
                </>
              )}
            </div>
            {(selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided') && !isMobile && (
              <div className={`${themeClass}_emptyStateContainer_placeholderBlock_actionButtons`}>
                <CustomButton
                  type={'primary'}
                  size={'md'}
                  clickHandler={handleAddIncorporatedItem}
                  title={'Set up incorporation'}
                  disabled={isMobile || incorporationStatus.loading}
                />
                <CustomButton
                  type={'tertiary'}
                  size={'md'}
                  clickHandler={() => setIsOpenCreateIncorporationPopUp(true)}
                  title={'Add incorporated item'}
                  disabled={isMobile || incorporationStatus.loading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const incorporationHistoryData = useMemo(() => {
    if (!incorporationHistory.length) {
      return [];
    }
    return feedData.filter(item => incorporationHistory.findIndex(value => value === item.alias) > -1);
  }, [feedData, incorporationHistory]);

  const renderGlobalStatusIncorporation = () => {
    return (
      <div>
        {!isGlobalIncorporated && (
          <StatusIncorporation status={globalStatus} label={globalStatusText} isVisibleLabel={true} isDragRequestCard={false} size={'md'} />
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          [`-sidePeek`]: isDragRequestCard || isTablet,
        })}
      >
        <div className={`${themeClass}_tab`}>
          {hasIncorporations || isEditMode ? (
            <div className={`${themeClass}_container`} ref={incorporationListRef}>
              {(isTablet || isDragRequestCard) && (
                <div className={`${themeClass}_buttonGroup`}>
                  {!isMobile && renderGlobalStatusIncorporation()}
                  {isMobile && !isGlobalIncorporated && (
                    <StatusIncorporation
                      status={globalStatus}
                      label={globalStatusText}
                      isVisibleLabel={true}
                      isDragRequestCard={false}
                      size={'md'}
                    />
                  )}
                  <div className={`${themeClass}_buttonGroup_globalInc`}>
                    {isGlobalIncorporated ? (
                      <CustomButton
                        type={'text-plain'}
                        size={'md'}
                        clickHandler={handleGlobalIncorporation}
                        title={'Clear incorporation'}
                        loading={globalIncorporationLoading.loading}
                        disabled={isDeclinedNfCard}
                        customClassName={`${themeClass}_buttonGroup_button`}
                        customTooltipBasisClassName={`${themeClass}_buttonGroup_tooltipBasis`}
                        isMobile={isMobile}
                      />
                    ) : (
                      <CustomButton
                        type={globalStatus === 'completed' ? 'primary' : isMobile ? 'tertiary' : 'text-plain'}
                        size={'md'}
                        clickHandler={handleGlobalIncorporation}
                        title={'Complete incorporation'}
                        loading={globalIncorporationLoading.loading}
                        disabled={isDeclinedNfCard}
                        customClassName={`${themeClass}_buttonGroup_button`}
                        customTooltipBasisClassName={`${themeClass}_buttonGroup_tooltipBasis`}
                        isMobile={isMobile}
                      />
                    )}
                  </div>
                </div>
              )}
              <div
                className={cn(`${themeClass}_incorporationBlock`, {
                  [`-scroll`]: incorporationListHaveScroll,
                })}
              >
                <div className={`${themeClass}_incorporationBlock_header`}>
                  <CustomAccordion
                    accordionOpened={isOpenedIncorporations}
                    openAccordion={() => handleOpenAccordion('incorporations')}
                    label={'Incorporations'}
                    isMobile={isMobile}
                  />
                  {!isMobile && (
                    <div className={`${themeClass}_incorporationBlock_buttonsBlock`}>
                      {isOpenedIncorporations ? (
                        isEditMode ? (
                          <>
                            <CustomButton type={'text-plain'} size={'md'} clickHandler={handleCloseEditMode} title={'Cancel'} />
                            <CustomButton
                              type={'primary'}
                              size={'md'}
                              loading={incorporationStatus.loading}
                              clickHandler={isRemovedDiscardedOrIncorporatedTasks ? handleOpenSaveEditPopup : handleSaveEditableList}
                              title={'Save'}
                              disabled={!isEditListChanged}
                            />
                          </>
                        ) : (
                          <CustomButton
                            type={'text-plain'}
                            size={'md'}
                            clickHandler={() => setIsEditMode(true)}
                            disabled={selectedRequest?.status === 'declined'}
                            title={'Edit incorporation tasks'}
                            icon={<TokenIcon iconName={'settings'} size={16} />}
                            iconClass={`${themeClass}_incorporationBlock_settingsIcon`}
                          />
                        )
                      ) : null}
                    </div>
                  )}
                </div>
                {isOpenedIncorporations && (
                  <div className={`${themeClass}_incorporationList`}>
                    {incorporationData?.map(mainTask => {
                      return (
                        <div key={mainTask.id} className={`${themeClass}_incorporationItem`}>
                          <IncorporationMainSnippet
                            data={mainTask}
                            buildings={buildings}
                            isDragRequestCard={isDragRequestCard}
                            isSizeResolution640={sizeResolution === '640'}
                            isEditMode={isEditMode}
                            selectParty={selected => handleChange(mainTask.id, 'selectedParty', selected, 'party')}
                            handleChangeNote={value => handleChange(mainTask.id, 'note', value)}
                            errors={editableListErrors?.[mainTask.id]}
                            incorporationListHaveScroll={incorporationListHaveScroll}
                            handleExpandItem={handleExpandItem}
                            openedItems={openedItems}
                            handleCheckTask={handleCheckTask}
                            checkedTasks={checkedTasks}
                            handleChangeTaskStatus={handleChangeTaskStatus}
                            handleChangeUfCode={handleChange}
                            handleDeleteItem={handleDeleteItem}
                            userInfo={userInfo}
                            isAccess={isAccess}
                            isDeclinedNfCard={isDeclinedNfCard}
                            isMobile={isMobile}
                            editableList={editableList}
                            selectedRequest={selectedRequest}
                            handleSaveEditableList={handleSaveEditableList}
                          />
                          {openedItems.includes(mainTask.id) && (
                            <div
                              className={cn(`${themeClass}_incorporationSubItem`, {
                                ['-isEditMode']: isEditMode,
                              })}
                            >
                              {mainTask.subtasks?.length &&
                                mainTask.subtasks.map(subTask => {
                                  return (
                                    <IncorporationFloorSnippet
                                      key={subTask.id}
                                      parentTask={mainTask}
                                      data={subTask}
                                      isEdit={isEditMode}
                                      isDragRequestCard={isDragRequestCard}
                                      isSizeResolution640={sizeResolution === '640'}
                                      checkedSubTask={!!checkedTasks[mainTask.id]?.includes(subTask.id)}
                                      incorporationListHaveScroll={incorporationListHaveScroll}
                                      handleCheckSubTask={handleCheckSubTask}
                                      handleChangeSubTaskStatus={handleChangeSubTaskStatus}
                                      menuItems={generateLevelsMenuItems()}
                                      groups={generateLevelsGroups()}
                                      handleChangeLevel={handleChangeLevel}
                                      handleChangeDueDate={handleChangeDueDate}
                                      handleDeleteItem={handleDeleteItem}
                                      errors={editableListErrorsSubTasks?.[mainTask.id]?.[subTask.id]}
                                      userInfo={userInfo}
                                      isAccess={isAccess}
                                      isDeclinedNfCard={isDeclinedNfCard}
                                      isMobile={isMobile}
                                    />
                                  );
                                })}
                              {isEditMode && (
                                <div className={`${themeClass}_addSubTask`}>
                                  <LevelsSelect<DropdownItemModel, DropdownGroupItem, true>
                                    isHeader
                                    isFooter
                                    selectHandler={null}
                                    selected={[...selectedNewSubTaskLevels, ...selectedNewSubTaskTypicalLayouts]}
                                    basisPlaceholder={'Select levels'}
                                    menuItemSize={'md'}
                                    isFullWidth
                                    buildings={allBuildings || []}
                                    levels={allLevels}
                                    typicalLayouts={allTypicalLayouts}
                                    selectedBuildings={buildings}
                                    setBuildingsSelected={() => null}
                                    setLevelsSelected={setSelectedNewSubTaskLevels}
                                    levelsSelected={selectedNewSubTaskLevels}
                                    setTypicalLayoutsSelected={setSelectedNewSubTaskTypicalLayouts}
                                    typicalLayoutsSelected={selectedNewSubTaskTypicalLayouts}
                                    isLoading={false}
                                    onLevelMenuClose={() => addNewSubTask(mainTask.id)}
                                    renderCustomBasis={(isActive, disabled, isLoading) => (
                                      <CustomButton
                                        type={'text-plain'}
                                        size={'sm'}
                                        disabled={disabled}
                                        isActive={isActive}
                                        loading={isLoading}
                                        title={'Add subtask'}
                                        icon={<TokenIcon iconName={'plus'} size={12} />}
                                        iconClass={`${themeClass}_incorporationBlock_addIcon`}
                                      />
                                    )}
                                    disabled={
                                      !(
                                        AccessHelper.isAccessToEditIncorporation(userInfo, mainTask.selectedParty?.company?.id || null) ||
                                        isAccess
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {isOpenedIncorporations && !isMobile && (
                  <div className={`${themeClass}_incorporationBlock_addNew`}>
                    <CustomButton
                      type={'text-plain'}
                      size={'md'}
                      clickHandler={isEditMode ? addNewTask : () => setIsOpenCreateIncorporationPopUp(true)}
                      disabled={isDeclinedNfCard || (isEditMode && !isAccess) || incorporationStatus.loading}
                      title={isEditMode ? 'Add task' : 'Create incorporated task'}
                      icon={<TokenIcon iconName={'plus'} size={16} />}
                      iconClass={`${themeClass}_incorporationBlock_addIcon`}
                    />
                  </div>
                )}
              </div>
              <div className={`${themeClass}_historyBlock`}>
                <CustomAccordion
                  accordionOpened={isOpenedHistory}
                  openAccordion={() => handleOpenAccordion('history')}
                  label={'History'}
                  isMobile={isMobile}
                />
                {isOpenedHistory && (
                  <div className={`${themeClass}_history`}>
                    <div className={`${themeClass}_history_container`}>
                      {incorporationHistoryData.map(historyItem => {
                        const handleOpenTooltip = () => {
                          setIsTooltipOpen(prevState => (prevState.includes(historyItem.id) ? prevState : [...prevState, historyItem.id]));
                        };

                        const handleCloseTooltip = () => {
                          setIsTooltipOpen(prevState =>
                            prevState.includes(historyItem.id) ? prevState.filter(i => i !== historyItem.id) : prevState,
                          );
                        };

                        let timeout;
                        const is_action_show = historyItem.action.includes('<a href=');
                        const action_text = is_action_show
                          ? historyItem.action.slice(0, historyItem.action.indexOf('<a href='))
                          : historyItem.action;
                        const tooltip_text = is_action_show
                          ? historyItem.action
                              .slice(historyItem.action.indexOf('data-text=') + 11, historyItem.action.indexOf('Show') - 2)
                              .replaceAll('.', '.&nbsp;')
                          : '';
                        return (
                          <>
                            <div
                              key={historyItem.id}
                              className={`${themeHistoryClass} ${isMobile ? '-mobile' : ''} ${themeHistoryClass}__comment`}
                            >
                              <div className={`${themeHistoryClass}__header`}>
                                <div className={`${themeHistoryClass}__dateContainer`}>
                                  <span className={`${themeHistoryClass}__text`}>{` ${moment(historyItem.created_at)
                                    .add('minutes', -offset)
                                    .format('ll')}`}</span>
                                  <span className={`${themeHistoryClass}__text`}>{` ${moment(historyItem.created_at)
                                    .add('minutes', -offset)
                                    .format('hh:mm A')}`}</span>
                                </div>
                                <div className={`${themeHistoryClass}__userProfile`}>
                                  <PlaceholderInfo
                                    type={'Persona'}
                                    firstName={historyItem?.user?.profile.first_name}
                                    lastName={historyItem?.user?.profile.last_name}
                                    detailInfo={`${historyItem?.user?.profile.first_name} ${historyItem?.user?.profile.last_name}`}
                                    imagePath={`${historyItem?.user?.profile.image}`}
                                    isMobile={isMobile}
                                    size={24}
                                  />
                                </div>
                                <div className={`${themeHistoryClass}__description`}>
                                  <span className={`${themeHistoryClass}__text`}>{` ${action_text}`}&nbsp;</span>
                                  {is_action_show && (
                                    <CustomTooltip
                                      open={isTooltipOpen.includes(historyItem.id)}
                                      title={ReactHtmlParser(tooltip_text)}
                                      customTooltipClass={`historyBlock`}
                                      onClick={handleOpenTooltip}
                                      onMouseEnter={() => !isMobile && (timeout = setTimeout(handleOpenTooltip, 1000))}
                                      onMouseLeave={() => clearTimeout(timeout)}
                                      onClose={handleCloseTooltip}
                                    >
                                      <span className={`${themeHistoryClass}__text -decor`}>Show</span>
                                    </CustomTooltip>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={`${themeHistoryClass}__divider`}>
                              <span>.</span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {incorporationStatus.loading ? (
                <div className={`${themeClass}_skeletonContainer`}>
                  <CustomSkeleton width={'100%'} height={48} />
                  <CustomSkeleton width={'100%'} height={48} />
                  <CustomSkeleton width={'100%'} height={48} />
                </div>
              ) : (
                renderEmptyContainer()
              )}
            </>
          )}
        </div>
      </div>
      <DialogPopUp
        open={isOpenCreateIncorporationPopUp}
        aria-labelledby="Create incorporated task"
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        hideBackdrop={false}
        onBackdropClick={handleCancelCustomIncorporation}
        onEscapeKeyDown={handleCancelCustomIncorporation}
        onClose={handleCancelCustomIncorporation}
        customClassname={`${themeClass}_createTRPopUp`}
        primaryText={'Incorporate'}
        handleOnPrimary={handleSaveCustomIncorporation}
        primaryTextDisabled={
          !(selectedCustomTaskUfs?.length && (selectedCustomTaskLevels?.length || selectedCustomTaskTypicalLayouts?.length))
        }
        secondaryText={'Cancel'}
        handleOnSecondary={handleCancelCustomIncorporation}
        title={'Create incorporated task'}
        renderModalContent={() => (
          <div className={`${themeClass}_createIncorporationPopUp_container_content`}>
            <div className={`${themeClass}_createIncorporationPopUp_container_content_select`}>
              <div className={`${themeClass}_createIncorporationPopUp_container_content_select_text`}>Scope</div>
              <CodeSelector
                isMulti={true}
                codeType={'uf'}
                isValuesRemovable
                isValuesEditable
                selected={selectedCustomTaskUfs}
                selectHandler={setSelectedCustomTaskUfs}
                basisPlaceholder={`Select UniFormat`}
                menuItemSize={'md'}
                basisMaxItems={6}
                handleFor={'content-plan'}
              />
            </div>
            <div className={`${themeClass}_createIncorporationPopUp_container_content_select`}>
              <div className={`${themeClass}_createIncorporationPopUp_container_content_select_text`}>Levels</div>
              <LevelsSelect<DropdownItemModel, DropdownGroupItem, true>
                isHeader
                isFooter
                selectHandler={null}
                selected={[...selectedCustomTaskLevels, ...selectedCustomTaskTypicalLayouts]}
                basisPlaceholder={'Select levels'}
                menuItemSize={'md'}
                isFullWidth
                buildings={allBuildings || []}
                levels={allLevels}
                typicalLayouts={allTypicalLayouts}
                selectedBuildings={buildings}
                setBuildingsSelected={() => null}
                setLevelsSelected={setSelectedCustomTaskLevels}
                levelsSelected={selectedCustomTaskLevels}
                setTypicalLayoutsSelected={setSelectedCustomTaskTypicalLayouts}
                typicalLayoutsSelected={selectedCustomTaskTypicalLayouts}
                isLoading={false}
                disabled={false}
              />
            </div>
          </div>
        )}
      />
      <DialogPopUp
        open={isTaskStatusChangePopupOpen}
        onClose={handleCloseTaskStatusPopup}
        title={`${taskStatusChangeType === 'incorporated' ? 'Incorporate' : 'Discard incorporation'} task on all levels?`}
        renderModalContent={() => (
          <>
            <span className={`${dialogClass}_content_modalText`}>All nested subtasks will be affected</span>
            <div onClick={toggleShowStatusPopup} className={`${themeClass}_dialogCheckboxRow`}>
              <CheckBox checked={isTaskStatusNotShowAgain} />
              <span className={`${dialogClass}_content_modalText`}>Do not show such messages again</span>
            </div>
          </>
        )}
        secondaryText={'Cancel'}
        customSecondaryType={'tertiary'}
        handleOnSecondary={handleCloseTaskStatusPopup}
        primaryText={`Yes, ${taskStatusChangeType === 'incorporated' ? 'incorporate' : 'discard'}`}
        handleOnPrimary={() => handleChangeTaskStatusPopup(taskStatusChangeId, taskStatusChangeType)}
      />
      <DialogPopUp
        open={isSaveEditPopupOpen}
        onClose={handleCloseSaveEditPopup}
        title={'Remove completed tasks/subtasks?'}
        modalText={
          "You are trying to remove at least 1 task/subtask that has already been incorporated/discarded. You won't be able to recover it."
        }
        secondaryText={'Discard changes'}
        customSecondaryType={'tertiary'}
        handleOnSecondary={handleCloseSaveEditPopup}
        primaryText={'Yes, remove'}
        handleOnPrimary={handleSaveEditableList}
        loadingOnPrimary={incorporationStatus.loading}
      />
    </>
  );
};

export default IncorporationTab;
