import React, { FC } from 'react';
import { TagModel } from '../../../../../models';
import ChipsColorTag from '../../../Chips/ChipsColorTag/ChipsColorTag';
import TokenIcon from '../../../TokenIcon/TokenIcon';

interface IProps {
  data: TagModel[];
  elementRef?: any;
  themeClass?: string;
  handleOpenLinkPopover?: () => void;
}

const HashTags: FC<IProps> = ({ data, elementRef, themeClass, handleOpenLinkPopover }) => {
  return (
    <div className={`${themeClass}_container_hashTagsBlock`}>
      {data.map(item => {
        return (
          <div className={`Badge`}>
            <ChipsColorTag
              key={`moreLocation-1-${item.id}`}
              color={item.color}
              value={item.name || item.label}
              ref={elementRef}
              onClickIcon={item.link ? e => handleOpenLinkPopover(e, item) : null}
              customIcon={item.link ? <TokenIcon iconName={'link'} size={16} /> : null}
            />
          </div>
        );
      })}
    </div>
  );
};

export default HashTags;
