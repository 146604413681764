import { MilestoneLegendModel } from '../models/phasePlan/milestone.model';
import { KeyValueModel } from '../models/key-value.model';

export const milestoneLevels: KeyValueModel<MilestoneLegendModel> = {
  gmp: {
    label: 'GMP',
    color: 'var(--color2)',
    value: 290,
    isShowLegend: true,
    alias: 'gmp',
  },
  lod350: {
    label: 'Construction Start Ready',
    color: 'var(--phasePlan-lod350)',
    value: 350,
    isShowLegend: true,
    alias: 'lod350',
  },
  lod325: {
    label: 'Fabrication/Material Procurement',
    color: 'var(--phasePlan-lod325)',
    value: 325,
    isShowLegend: true,
    alias: 'lod325',
  },
  lod300: {
    label: 'Coordination',
    color: 'var(--phasePlan-lod300)',
    value: 300,
    isShowLegend: true,
    alias: 'lod300',
  },
  lod290: {
    label: 'Buy Out (Include 2nd Tier Subs)',
    color: 'var(--phasePlan-lod290)',
    value: 290,
    isShowLegend: true,
    alias: 'lod290',
  },
  lod200: {
    label: 'Buy Out Design assist/Design Build',
    color: 'var(--phasePlan-lod200)',
    value: 200,
    isShowLegend: true,
    alias: 'lod200',
  },
  construction: {
    label: 'Construction',
    color: 'var(--phasePlan-construction)',
    value: null,
    isShowLegend: false,
    alias: 'construction',
  },
  permit: {
    label: 'Permit Phase',
    color: 'var(--phasePlan-permit)',
    value: null,
    isShowLegend: true,
    alias: 'permit',
  },
  beforeProject: {
    label: 'Before start task',
    color: 'var(--background-main)',
    value: null,
    isShowLegend: false,
    alias: 'beforeProject',
  },
};
export enum MilestoneKeys {
  gmp = 'gmp',
  lod350 = 'lod350',
  lod325 = 'lod325',
  lod300 = 'lod300',
  lod290 = 'lod290',
  lod200 = 'lod200',
  construction = 'construction',
  permit = 'permit',
  beforeProject = 'beforeProject',
}

export const scheduleColumns = [
  {
    id: 'ufc_id',
    label: 'UF',
    type: 'select',
    color: 'var(--background-grid)',
    disable: false,
    collection: 'ufc',
  },
  {
    id: 'title',
    label: 'Construction / document schedule',
    type: 'input',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'construction_start',
    label: 'Construction start date',
    type: 'datePicker',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'construction_end',
    label: 'Construction end date',
    type: 'datePicker',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'lod350_duration',
    label: 'Procurement lead time (weeks)',
    type: 'numberInput',
    color: milestoneLevels[MilestoneKeys.lod350].color,
    disable: false,
  },
  {
    id: 'lod350_start',
    label: 'LOD 325 ready',
    type: 'datePicker',
    color: milestoneLevels[MilestoneKeys.lod325].color,
    disable: false,
  },
  {
    id: 'lod325_duration',
    label: 'Coordination Duration (weeks)',
    type: 'numberInput',
    color: milestoneLevels[MilestoneKeys.lod325].color,
    disable: false,
  },
  {
    id: 'lod325_start',
    label: 'LOD 300 Ready',
    type: 'datePicker',
    color: milestoneLevels[MilestoneKeys.lod300].color,
    disable: false,
  },
  {
    id: 'lod300_duration',
    label: 'LOD 300 duration (weeks)',
    type: 'numberInput',
    color: milestoneLevels[MilestoneKeys.lod300].color,
    disable: false,
  },
  {
    id: 'lod300_start',
    label: 'LOD 290 ready',
    type: 'datePicker',
    color: milestoneLevels[MilestoneKeys.lod290].color,
    disable: false,
  },
  {
    id: 'lod290_duration',
    label: 'LOD 290 duration (weeks)',
    type: 'numberInput',
    color: milestoneLevels[MilestoneKeys.lod290].color,
    disable: false,
  },
  {
    id: 'lod290_start',
    label: 'LOD 200 ready',
    type: 'datePicker',
    color: milestoneLevels[MilestoneKeys.lod200].color,
    disable: false,
  },
  {
    id: 'edit',
    label: 'Edit',
    type: 'edit',
    color: 'var(--background-grid)',
    disable: false,
  },
];

export const permitColumns = [
  {
    id: 'title',
    label: 'Design\\Permit\\CL Schedule',
    type: 'input',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'submission_date',
    label: 'Start date',
    type: 'datePicker',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'durations',
    label: 'Duration (weeks)',
    type: 'numberInput',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'approval_date',
    label: 'End date',
    type: 'datePicker',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'color',
    label: 'Change color',
    type: 'colorSelect',
    color: 'var(--background-grid)',
    disable: false,
  },
  {
    id: 'edit',
    label: 'Edit',
    type: 'edit',
    color: 'var(--background-grid)',
    disable: false,
  },
];
