import React, { FC } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';

import './ReportingTotalStyle.scss';

const ReportingTotal: FC<{
  open_NFs?: number;
  court_nfs?: number;
  overdue_NFs: number;
  overdue_deliverables?: number;
  avg_reaction_time?: string;
  isAllCompanies: boolean;
  isHorizontal?: boolean;
}> = ({ open_NFs, court_nfs, overdue_NFs, overdue_deliverables, avg_reaction_time, isAllCompanies, isHorizontal }) => {
  const themeClass = useGetThemeClass('b-reportingTotal');
  return (
    <div className={cn(`${themeClass}`, { ['-horizontal']: isHorizontal })}>
      {isAllCompanies ? (
        <div className={'rep-totalContent'}>
          <div className={'rep-total_text'}>{open_NFs || 0}</div>
          <div className={'rep-total_name'}>Open NFs</div>
        </div>
      ) : (
        <div className={'rep-totalContent'}>
          <div className={'rep-total_text'}>{court_nfs || 0}</div>
          <div className={'rep-total_name'}>NFs in the court</div>
        </div>
      )}
      <div className={'rep-totalContent'}>
        <div className={'rep-total_text'}>{overdue_NFs || 0}</div>
        <div className={'rep-total_name'}>Overdue NFs</div>
      </div>
      {isAllCompanies ? (
        <div className={'rep-totalContent'}>
          <div className={'rep-total_text'}>{overdue_deliverables || 0}</div>
          <div className={'rep-total_name'}>Overdue deliverables</div>
        </div>
      ) : (
        <div className={'rep-totalContent'}>
          <div className={'rep-total_text'}>{avg_reaction_time || 0}</div>
          <div className={'rep-total_name'}>Average reaction time</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ReportingTotal);
