import { useAppSelector } from '../../store/configure/configureStore';
import { UsePermissionsReturn } from './types';

export const usePermissions = (): UsePermissionsReturn => {
  const userData = useAppSelector(state => state.userReducer.userInfo);

  return {
    isSuperAdmin: '4' in userData?.roles,
    isAdmin: '1' in userData?.roles,
    isManager: '5' in userData?.roles,
    isUser: '3' in userData?.roles,

    isProjectAccess: '4' in userData?.roles || '1' in userData?.roles,
    isManagerAccess: '4' in userData?.roles || '1' in userData?.roles || '5' in userData?.roles,
  };
};
