import { BaseApi } from '../baseApi';

import { ConstructorParams, TeamApi, TeamFiltersResponseModel, UserResponse } from './types';
import { KeyValueModel } from '../../../models/key-value.model';
import { TitleModel } from '../../../models';

class TeamApi extends BaseApi {
  devMode: boolean;

  constructor({ baseURL, devMode }: ConstructorParams) {
    super(baseURL);
    this.devMode = devMode;
  }

  getMembers({ projectId, ...filters }: TeamApi.GetMembersRequest): Promise<TeamApi.GetMembersResponse> {
    return this._post({
      route: `team/${projectId}/get-members`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: filters,
    });
  }

  getParties({ projectId, search }: TeamApi.GetPartiesRequest): Promise<TeamApi.GetPartiesResponse> {
    return this._get({
      route: `team/${projectId}/get-list-parties/${encodeURIComponent(search)}`,
      needAuth: true,
    });
  }

  createParty(data: TeamApi.CreatePartyRequest | TeamApi.CreateTemplatePartyRequest): Promise<TeamApi.CreatePartyResponse> {
    return this._post({
      route: `team/create-party`,
      needAuth: true,
      bodyParams: data,
    });
  }

  updateParty({ partyId, ...data }: TeamApi.UpdatePartyRequest): Promise<TeamApi.CreatePartyResponse> {
    return this._update({
      route: `team/${partyId}/update-party`,
      needAuth: true,
      bodyParams: data,
    });
  }

  unlinkPartyFromProject(bodyParams) {
    return this._delete({
      route: `team/remove-party`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  getDisciplinesRoles(): Promise<TeamApi.GetDisciplinesRolesResponse> {
    return this._get({
      route: `team/get-list-disciplines-roles`,
      needAuth: true,
    });
  }

  getCountNfToParty({ party_id, project_id }): Promise<TeamApi.NfCountForPartyDataResponse> {
    return this._get({
      route: `team/${project_id}/${party_id}/get-list-count-nf-pcd-for-party`,
      needAuth: true,
    });
  }

  searchUsersTeam(bodyParams): Promise<TeamApi.UserResponse> {
    return this._post({
      route: `team/user-search`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  getPartyUsers(bodyParams): Promise<TeamApi.UserResponse> {
    return this._get({
      route: `team/${bodyParams.project_id}/${bodyParams.party_id}/get-party-users`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  linkUserToProject(bodyParams): Promise<TeamApi.UserResponse> {
    return this._post({
      route: `team/link-user-project`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  unlinkUserToProject(bodyParams): Promise<TeamApi.UserResponse> {
    return this._post({
      route: `team/unlink-user-project`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams,
    });
  }

  async createUser(data): Promise<TeamApi.CreateUserResponse> {
    const result = await this._post({ route: 'user/create', bodyParams: data, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async updateUser(data): Promise<TeamApi.UpdateUserResponse> {
    const result = await this._post({ route: 'user/update', bodyParams: data, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  async deleteUser(data): Promise<TeamApi.DeleteUserResponse> {
    const result = await this._delete({ route: 'user/delete', bodyParams: data, needAuth: true, devMode: this.devMode });
    return {
      status: result.status,
      data: result.data,
      message: result.message,
    };
  }

  getListSubdivision(bodyParams): Promise<TeamApi.ListSubdivisionResponse> {
    return this._get({
      route: `team/${bodyParams.party_id}/get-list-subdivision`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  createSubdivision({ party_id, ...data }: TeamApi.CreateSubdivisionRequest): Promise<void> {
    return this._post({
      route: `team/${party_id}/create-subdivision`,
      needAuth: true,
      bodyParams: data,
    });
  }

  updateSubdivision({ party_id, ...data }: TeamApi.UpdateSubdivisionRequest): Promise<void> {
    return this._update({
      route: `team/${party_id}/update-subdivision`,
      needAuth: true,
      bodyParams: data,
    });
  }

  deleteSubdivision({ party_id, ...data }: TeamApi.DeleteSubdivisionRequest): Promise<void> {
    return this._delete({
      route: `team/${party_id}/delete-subdivision`,
      needAuth: true,
      bodyParams: data,
    });
  }

  addParty(data: TeamApi.AddPartyRequest): Promise<TeamApi.AddPartyResponse> {
    return this._post({
      route: `team/add-party`,
      needAuth: true,
      bodyParams: data,
    });
  }

  setContactPointProject(bodyParams): Promise<TeamApi.UserResponse> {
    return this._get({
      route: `team/${bodyParams.project}/${bodyParams.party}/${bodyParams.user}/set-contact-point-project`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  setContactPointParty(bodyParams): Promise<TeamApi.UserResponse> {
    return this._get({
      route: `team/${bodyParams.project}/${bodyParams.party}/${bodyParams.user}/set-contact-point-party`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  getFilters({ projectId }: TeamApi.GetFiltersRequest): Promise<TeamApi.TeamFiltersResponseModel> {
    return this._get({
      route: `team/${projectId}/filters`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  setBuyoutDate(data: TeamApi.SetBuyoutDateRequest): Promise<any> {
    return this._put({
      route: `team/edit-planned-actual-buyout-date`,
      needAuth: true,
      bodyParams: data,
    });
  }

  createTemplateParties(data: TeamApi.CreateTemplatePayload): Promise<TeamApi.GetMembersResponse> {
    return this._post({
      route: `team/create-template-parties`,
      needAuth: true,
      bodyParams: data,
    });
  }
}

export default new TeamApi({ devMode: false });
