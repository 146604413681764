import { connect } from 'react-redux';
import { getCurrentProjectSpecialReport } from '../../../store/common/commonThunk';
// import // getNotificationsFilters,
// // getNotificationsList,
// // readAll,
// // setNotificationsSeen,
// '../../../store/notifications/notificationsActions';
import { getNotificationsList, readAll, getNotificationsFilters } from '../../../store/notifications/notificationsThunk';
import { getProjectDetails } from '../../../store/project/projectLogic';
import { projectSlice } from '../../../store/project/projectReducer';
// import { getCurrentProjectSpecialReport } from '../../../store/common/commonActions';
import { singleRequestLoading } from '../../../store/request/requestLogic(HOLD)';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';

const { setNfOpenId } = requestSlice.actions;

const { projectLoading } = projectSlice.actions;
const mapDispatchToProps = dispatch => ({
  getNotificationsList(payload) {
    dispatch(getNotificationsList(payload));
  },
  getNotificationsFilters() {
    dispatch(getNotificationsFilters());
  },
  loadProjectData(payload = {}) {
    dispatch(projectLoading());
  },
  readAll() {
    dispatch(readAll());
  },
  getProjectDetails(projectId) {
    dispatch(getProjectDetails(projectId));
  },
  getCurrentProjectSpecialReport(data) {
    dispatch(getCurrentProjectSpecialReport(data));
  },
  setNfOpenId(payload) {
    dispatch(setNfOpenId(payload));
  },
  singleRequestLoading(payload) {
    dispatch(singleRequestLoading(payload));
  },
});
const mapStateToProps = state => ({
  allNotifications: state.notificationsReducer,
  userData: state.userReducer,
  notificationsLoading: state.notificationsReducer.loading,
  notificationFilters: state.notificationsReducer.notificationFilters,
});

export const connector = connect(mapStateToProps, mapDispatchToProps);

const notificationsSelector = state => {
  return state.notificationsReducer.notificationsInfo.notifications;
};
