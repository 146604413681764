export const redmineSystemsList: { value: string; label: string; title: string }[] = [
  { value: 'Structure', label: 'Structure', title: 'Structure' },
  { value: 'Exterior', label: 'Exterior', title: 'Exterior' },
  { value: 'Interior', label: 'Interior', title: 'Interior' },
  { value: '3D Coordination', label: '3D Coordination', title: '3D Coordination' },
  { value: 'Framing', label: 'Framing', title: 'Framing' },
  { value: 'HVAC', label: 'HVAC', title: 'HVAC' },
  { value: 'Electrical', label: 'Electrical', title: 'Electrical' },
  { value: 'Plumbing', label: 'Plumbing', title: 'Plumbing' },
  { value: 'FP', label: 'FP', title: 'FP' },
  { value: 'Site Utilities', label: 'Site Utilities', title: 'Site Utilities' },
  { value: 'Landscape', label: 'Landscape', title: 'Landscape' },
  { value: 'BD', label: 'BD', title: 'BD' },
  { value: 'TOQ', label: 'TOQ', title: 'TOQ' },
  { value: 'Logistics', label: 'Logistics', title: 'Logistics' },
  { value: 'LOD Compliance Report', label: 'LOD Compliance Report', title: 'LOD Compliance Report' },
  { value: 'Excavation', label: 'Excavation', title: 'Excavation' },
  { value: 'Document Processing', label: 'Document Processing', title: 'Document Processing' },
  { value: 'Visualization', label: 'Visualization', title: 'Visualization' },
  { value: '4D', label: '4D', title: '4D' },
  { value: 'Misc', label: 'Misc', title: 'Misc' },
];
