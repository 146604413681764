import { LocationModel, SimpleTitleModel } from '..';

export class BuildingModel<T = FoundationTypeModel> {
  id: number;
  foundation_types: T;
  locations: LocationModel[];
  locations_count: number;
  project_id: number;
  title: string;
  typical_layouts: TypicalLayoutsModel[];
  units_count: number;
  building_spaces: SimpleTitleModel[];

  constructor(props?) {
    this.foundation_types = props.foundation_types || [];
    this.locations = props.locations || [];
    this.locations_count = props.locations_count || null;
    this.project_id = props.project_id || null;
    this.title = props.title || null;
    this.typical_layouts = props.typical_layouts || [];
    this.units_count = props.units_count || null;
    this.building_spaces = props.building_spaces || null;
  }
}

export interface FoundationTypeModel {
  code: string;
  description: string;
  id: number;
}

export interface TypicalLayoutsModel {
  start_layout_id: number;
  end_layout_id: number;
  building_id: number;
  end_position: number;
  id: number;
  name: string;
  start_position: number;
}
