import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { SandBoxNfSnippetModel } from '../../../store/request/requestReducer.model';
import { UserModel } from '../../../models';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { setHotList } from '../../../store/request/requestLogic(HOLD)';
import ActionButton from '../ButtonComponents/ActionButton/ActionButton';
import CardLinkButton from '../ButtonComponents/CardLinkButton/CardLinkButton';
import IdentificatoryButton from '../ButtonComponents/IdentificatoryButton/IdentifiatoryButton';
import moment from 'moment';
import AccessHelper from '../../../helpers/AccessHelper';
import { useGetThemeClass } from '../../../helpers/designTokens';
import ChipsRequestType from '../Chips/ChipsRequestType/ChipsRequestType';
import ChipsLOD from '../Chips/ChipsLOD/ChipsLOD';
import StatusNF from '../Status/StatusNF/StatusNF';
import cn from 'classnames';
import CustomTooltip from '../Tooltip/Tooltip';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';
import MarkerBlock from './Parts/MarkerBlock';
import EndField from './Parts/EndField';
import StatusRevision from '../Status/StatusRevision/StatusRevision';
import ChipsRevisionTag from '../Chips/ChipsRevisionTag/ChipsRevisionTag';
import DateField from '../DateField/DateField';
import TokenIcon from '../TokenIcon/TokenIcon';
import { OverflowMenuButton } from '../ButtonComponents';
import Popover from '@mui/material/Popover';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import OvalButton from '../ButtonComponents/OvalButton/OvalButton';
import StatusSubmittal from '../Status/StatusSubmittal/StatusSubmittal';
import { RelatedItemsTabsType } from '../../common/CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { AllSectionType } from '../../common/SandBox/SandBoxConstants/SandBoxConstants';
import { SubmittalModel } from '../../../models/submittals/submittals.model';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import '../../../../designTokens/scss/_variablesDesignTokes.scss';
import './Snippet.module.scss';

interface IProps<T> {
  isShortView?: boolean;
  //TODO separate interface logic on high level component to make snippetData similar for different cases (Sandbox snippet, Reqest)
  snippetData: T | null;
  openRequest?: (snippetData: T) => void;
  openDeliverable?: (snippetData: T) => void;
  openSubmittal?: (snippetData: T) => void;
  handleOpenCard?: (snippetData: T) => void;
  accordionAlias?: string;
  handleDeactivationPopUp?: (
    snippetData: SandBoxNfSnippetModel | SubmittalModel,
    action: 'activate' | 'deactivate',
    accordion: string,
  ) => void;
  handleDeleteDraftPopUp?: (nf: SandBoxNfSnippetModel, accordion) => void;
  openedFrom?: string;
  userInfo?: UserModel;
  is1100Resolution?: boolean;
  isTablet?: boolean;
  isPortableCard?: boolean;
  handleUnlink?: (item) => void;
  toolTipTitle?: string;
  isDDLog?: boolean;
  is1920full?: boolean;
  sizeContainer?: string;
  isDisabledSnippet?: boolean;
  validityStatusIcon?: ReactElement | null;
  incorporationIcon?: ReactElement | null;
  isTriggerStyle?: string | null;
  showDateField?: boolean;
  dateFieldToolTip?: string;
  showWatchIcon?: boolean;
  watchToolTip?: string;
  showDeactivate?: string;
  isPcdCardLinkedNfsTab?: boolean;
  relatedTabType?: RelatedItemsTabsType;
  sandBoxHotlistSectionType?: AllSectionType;
  currentCard?: string;
  isDragRequestCard?: boolean;
  part?: 'predecessor' | 'successor';
  isDeactivated?: boolean;
}

function Snippet<T>({
  isShortView,
  snippetData,
  openRequest,
  accordionAlias,
  openDeliverable,
  openSubmittal,
  handleOpenCard,
  handleDeactivationPopUp,
  handleDeleteDraftPopUp,
  openedFrom,
  userInfo,
  is1100Resolution,
  isTablet,
  isPortableCard,
  toolTipTitle,
  handleUnlink,
  isDDLog,
  is1920full,
  sizeContainer,
  isDisabledSnippet,
  validityStatusIcon,
  incorporationIcon,
  isTriggerStyle,
  showDateField = true,
  dateFieldToolTip,
  showWatchIcon,
  watchToolTip,
  showDeactivate,
  isPcdCardLinkedNfsTab = false,
  relatedTabType,
  sandBoxHotlistSectionType,
  currentCard,
  isDragRequestCard,
  part,
  isDeactivated,
}: IProps<T>) {
  const dispatch = useAppDispatch();
  const { returnUrlDeliverablesCardTab, returnUrlNfCardTab, returnUrlSubmittalCardTab } = useRoutingHelper();

  const { isMobile } = GetResolution();

  const [isHover, setIsHover] = useState(false);
  const [isIndicatorsMenuOpen, setIsIndicatorsMenuOpen] = useState(null);
  const [isSubjectExpanded, setIsSubjectExpanded] = useState<boolean>(false);
  const [isHotList, setIsHotList] = useState<boolean>(false);
  const isAccessToEditDeactivation = AccessHelper.isUserExistsInRequestParty(userInfo, snippetData);
  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  const timeout = useRef(null);

  useEffect(() => {
    setIsHotList(snippetData.is_hot_list);
  }, [snippetData.is_hot_list]);

  const handleOpenIndicatorsMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsIndicatorsMenuOpen(e.currentTarget);
  };
  const handleCloseIndicatorsMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsIndicatorsMenuOpen(null);
  };

  const handleSubjectMouseEnter = () => {
    timeout.current = setTimeout(() => {
      setIsSubjectExpanded(true);
    }, 500);
  };

  const handleSubjectMouseLeave = () => {
    clearTimeout(timeout.current);
    setIsSubjectExpanded(false);
  };

  const handleOpenItem = e => {
    e.stopPropagation();
    e.preventDefault();

    if (relatedTabType) {
      handleOpenCard(snippetData);
    } else {
      if (snippetData.sf_index) {
        openSubmittal(snippetData);
      } else if (snippetData.nf) {
        openRequest(snippetData);
      } else {
        openDeliverable(snippetData);
      }
    }
  };

  const handleSetHotList = e => {
    e.stopPropagation();
    e.preventDefault();

    const project_id = +sessionStorage.getItem('active_project_id');

    dispatch(
      setHotList({
        project_id,
        entity_ids: [snippetData.id],
        entity: snippetData.sf_index ? 'submittal' : snippetData.nf ? 'request' : 'deliverable',
        action: isHotList ? 'remove' : 'add',
        accordion_alias: accordionAlias,
      }),
    );
    setIsHotList(prevState => !prevState);
  };

  const handleEndField = e => {
    e.stopPropagation();
    e.preventDefault();

    if (snippetData.status === 'drafted') {
      handleDeleteDraftPopUp(snippetData, accordionAlias);
    } else {
      const action = snippetData.is_deactivated ? 'activate' : 'deactivate';
      handleDeactivationPopUp(snippetData, action, accordionAlias);
    }
  };

  const milestoneTitle = useMemo(() => {
    if (snippetData && snippetData.milestone_code) {
      return snippetData.milestone_code.split('.')[0];
    }

    return '';
  }, [snippetData]);

  const isShowSnippetNarrow = useMemo(() => {
    if (currentCard) {
      return true;
    }
    if (sandBoxHotlistSectionType) {
      return true;
    }
    return false;
  }, [currentCard, sandBoxHotlistSectionType]);

  const companyString = useMemo(() => {
    if (snippetData && snippetData.request_party_title && snippetData.response_party_title) {
      return `${snippetData.request_party_title} → ${snippetData.response_party_title}`;
    }

    if (snippetData && snippetData.request_party_title && !snippetData.response_party_title) {
      return `${snippetData.request_party_title}`;
    }

    return '';
  }, [snippetData]);

  const responsiblePartyString = useMemo(() => {
    if (snippetData?.responsible_contractor?.contractor_party && snippetData?.responsible_contractor?.contractor_user) {
      return `${snippetData.responsible_contractor.contractor_party.company} - 
      ${snippetData.responsible_contractor.contractor_user.profile.first_name} 
      ${snippetData.responsible_contractor.contractor_user.profile.last_name}`;
    }

    if (snippetData?.responsible_contractor?.contractor_user) {
      return `${snippetData.responsible_contractor.contractor_user.profile.title}`;
    }

    if (snippetData && snippetData.responsible_party_title && !snippetData.responsible_user_title) {
      return `${snippetData.responsible_party_title}`;
    }

    if (snippetData && snippetData.responsible_party_title && snippetData.responsible_user_title) {
      return `${snippetData.responsible_party_title} - ${snippetData.responsible_user_title}`;
    }

    if (snippetData && snippetData.responsible_party_title && !snippetData.responsible_user_title) {
      return `${snippetData.responsible_party_title}`;
    }

    return '';
  }, [snippetData]);

  const snippetStyleState = useMemo(() => {
    if (snippetData.is_deactivated) {
      return '-deactivated';
    }

    if (snippetData.marker) {
      return '-notOpened';
    }

    return '';
  }, [snippetData]);

  const statusDateColor = useMemo(() => {
    if (snippetData?.nf) {
      let tooltip = '';
      const isAfterDueDate = moment(new Date()).isAfter(snippetData.due_date) && snippetData?.status !== 'accepted';
      const overdueDaysCounter = snippetData?.status !== 'accepted' ? moment(new Date()).diff(snippetData.due_date, 'days') : 0;

      if (overdueDaysCounter > 0 && !snippetData.due_date_change_request) {
        tooltip = `Request overdue for ${overdueDaysCounter} ${overdueDaysCounter > 1 ? `days` : 'day'}`;
      }

      return { isWarning: !!snippetData.due_date_change_request, isCritical: !!isAfterDueDate, tooltip: tooltip };
    }

    if ((snippetData?.revision || !!snippetData.snippet) && snippetData?.flags?.last_overdue_state) {
      return {
        isWarning: snippetData.flags.last_overdue_state === 'warning',
        isCritical: snippetData.flags.last_overdue_state === 'critical',
      };
    }

    if ((snippetData?.revision || !!snippetData.snippet) && snippetData.overdue_state) {
      return { isWarning: snippetData.overdue_state === 'warning', isCritical: snippetData.overdue_state === 'critical' };
    }
  }, [snippetData]);

  const deliverableSnippetDate = useMemo(() => {
    if (snippetData && snippetData.status === 'not_issued_yet' && snippetData.scheduled_date) {
      return { date: snippetData.scheduled_date, postfix: 'scheduled' };
    }

    if (snippetData && snippetData.issued_date) {
      return { date: snippetData.issued_date, postfix: 'issued' };
    }

    return '';
  }, [snippetData]);

  const themeClass = sizeContainer
    ? isShortView
      ? useGetThemeClass('snippet')
      : `${useGetThemeClass('snippet')}-${sizeContainer}`
    : useGetThemeClass('snippet');
  const themeBasisTooltipClass = useGetThemeClass('snippetBasisTooltip');
  const isShowDate = sizeContainer === '' ? false : true;

  const getSetStatus = (status: string, clickHandler?: () => void) => {
    switch (status) {
      case 'valid': {
        return (
          <TokenIcon
            iconName={'valid'}
            size={isMobile ? 20 : 16}
            clickHandler={e => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler && clickHandler();
            }}
          />
        );
      }
      case 'not_valid': {
        return (
          <TokenIcon
            iconName={'not-valid'}
            size={isMobile ? 20 : 16}
            clickHandler={e => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler && clickHandler();
            }}
          />
        );
      }
      case 'not_verified': {
        return (
          <TokenIcon
            iconName={'not-verified-triangle'}
            size={isMobile ? 20 : 16}
            clickHandler={e => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler && clickHandler();
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const getIconType = (value: string): string => {
    if (value === 'CL-RFI') {
      return 'cl-rfi';
    } else if (value === 'QC-D') {
      return 'qc';
    } else if (value === 'SO') {
      return 'sign-off';
    } else if (value === 'SD') {
      return 'shop-drawings';
    } else {
      return 'deliverables';
    }
  };

  const getNameClassSnippet = (currentCard: string) => {
    if (currentCard === 'Submittal') {
      return 'needFormsSubmittal';
    }
    if (currentCard === 'Deliverable') {
      return 'needFormsDeliverable';
    }
    return 'needForms';
  };

  const handleOpenItemCards = e => {
    if (typeof e.target?.parentElement?.className === 'string' && !e.target?.parentElement?.className?.includes('Button')) {
      handleOpenItem(e);
    }
  };

  const getSnippetNF = () => {
    if (sandBoxHotlistSectionType) {
      return (
        <div
          className={cn(`${themeClass}_relatedItems -widgetHotlist ${snippetStyleState}`, {
            [`-sidePeek`]: isDragRequestCard,
            ['-deactivated']: snippetData?.is_deactivated || isDisabledSnippet,
            [`${isTriggerStyle}`]: !!isTriggerStyle,
          })}
        >
          <a
            className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)} -widgetHotlist`}
            href={returnUrlNfCardTab(snippetData)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenItemCards(e);
            }}
          >
            <div className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)}_starredArea`}>
              {isHotList ? (
                <ActionButton type={'starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              ) : (
                <ActionButton type={'not-starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              )}
            </div>
            <div className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)}_requestType`}>
              <CustomTooltip title={snippetData?.request_type_title || ''} placement={'bottom'}>
                <ChipsRequestType isMobile={isMobile} type={snippetData?.request_type_title} isNotShowText />
              </CustomTooltip>
            </div>
            <div className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)}_nfButton`}>
              <a onClick={handleOpenItem} href={returnUrlNfCardTab(snippetData)}>
                <CardLinkButton
                  clickHandler={!snippetData?.is_deactivated && handleOpenItem}
                  type={'nf'}
                  size={'sm'}
                  title={`NF ${snippetData.nf}`}
                  isMobile={isMobile}
                />
              </a>
            </div>
            <CustomTooltip title={snippetData.title || ''} placement={'bottom'}>
              <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_specific`}>
                <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_specific_textData`}>{snippetData.title}</div>
              </div>
            </CustomTooltip>
            <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_statusNfandRevisionTag`}>
              <StatusNF status={snippetData.status || snippetData.lastStatus} />
              {snippetData?.is_incorporated ? (
                <OvalButton
                  title={'Response incorporated'}
                  type={'incorporation'}
                  clickHandler={null}
                  showTitle={false}
                  customClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_iconContainer`}
                  customIconClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_icon`}
                  tooltip={'NF response incorporation process was completed'}
                />
              ) : (
                <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_emptyContainer`} />
              )}
            </div>
            <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_rightInfo`}>
              {showDateField ? (
                <DateField
                  selectedDate={moment(snippetData.due_date).format('MM/DD/YYYY')}
                  isSentRequestToChange={statusDateColor.isWarning}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={false}
                  isBefore={isPcdCardLinkedNfsTab || statusDateColor.isCritical}
                  error={false}
                  valueIsDate={true}
                  isDisableBorder={true}
                  isHideDate={true}
                  tooltip={statusDateColor?.tooltip || ''}
                  isMobile={isMobile}
                  isSnippet
                />
              ) : (
                <div />
              )}
            </div>
          </a>
        </div>
      );
    }

    return (
      <div
        className={cn(`${themeClass}_relatedItems ${snippetStyleState}`, {
          [`-sidePeek`]: isDragRequestCard,
          ['-deactivated']: snippetData?.is_deactivated || isDisabledSnippet,
          [`${isTriggerStyle}`]: !!isTriggerStyle,
        })}
      >
        <a
          className={cn(`${themeClass}_${getNameClassSnippet(currentCard)}`, { ['-expanded']: isSubjectExpanded })}
          href={returnUrlNfCardTab(snippetData)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenItemCards(e);
          }}
        >
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_emptyArea`}>
            <CustomTooltip title={snippetData.request_discipline?.title || ''} placement={'bottom'}>
              <div
                className={`${themeClass}_${getNameClassSnippet(currentCard)}_emptyArea_blockArea -${
                  snippetData.request_discipline?.text_color || ''
                }`}
              />
            </CustomTooltip>
          </div>
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_requestType`}>
            <CustomTooltip title={snippetData?.tags[0]?.title || ''} placement={'bottom'}>
              <ChipsRequestType isMobile={isMobile} type={snippetData?.tags[0]?.title} isNotShowText />
            </CustomTooltip>
          </div>
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_nfButton`}>
            <a onClick={handleOpenItem} href={returnUrlNfCardTab(snippetData)}>
              <CardLinkButton
                clickHandler={!snippetData?.is_deactivated && handleOpenItem}
                type={'nf'}
                size={isMobile ? 'md' : 'sm'}
                title={`NF ${snippetData.nf}`}
                isMobile={isMobile}
              />
            </a>
          </div>
          {(currentCard === 'Submittal' || currentCard === 'Deliverable') && (
            <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_statusTriangle`}>
              {validityStatusIcon ? validityStatusIcon : getSetStatus(snippetData?.flags?.validity_status)}
            </div>
          )}
          <CustomTooltip
            title={snippetData.specific || ''}
            className={`${themeClass}_${getNameClassSnippet(currentCard)}_specific_tooltip`}
            placement={'bottom'}
          >
            <div
              className={`${themeClass}_${getNameClassSnippet(currentCard)}_specific`}
              onMouseEnter={handleSubjectMouseEnter}
              onMouseLeave={handleSubjectMouseLeave}
            >
              <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_specific_textData`}>{snippetData.specific}</div>
            </div>
          </CustomTooltip>
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_statusNfandRevisionTag`}>
            <StatusNF status={snippetData.status || snippetData.lastStatus} isMobile={isMobile} />
            {!isMobile && snippetData.is_incorporated ? (
              <OvalButton
                title={'Response incorporated'}
                type={'incorporation'}
                clickHandler={null}
                showTitle={false}
                customClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_iconContainer -incorporated`}
                customIconClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_icon`}
                tooltip={'NF response incorporation process was completed'}
              />
            ) : (
              <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_emptyContainer`} />
            )}
            {currentCard === 'Deliverable' &&
              (snippetData.flags.is_incorporated ? (
                <OvalButton
                  title={'Response incorporated'}
                  type={'incorporated-to-locations'}
                  customClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_iconContainer -incorporated-locally`}
                  clickHandler={null}
                  showTitle={false}
                  tooltip={'NF response was incorporated in all Deliverable-specific locations'}
                />
              ) : (
                <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_emptyContainer`} />
              ))}
          </div>
          {isMobile && !!snippetData.is_incorporated && (
            <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_incorporated`}>
              <OvalButton
                title={'Response incorporated'}
                type={'incorporation'}
                clickHandler={null}
                showTitle={false}
                customClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_iconContainer`}
                customIconClassName={`${themeClass}_${getNameClassSnippet(currentCard)}_icon`}
                tooltip={'NF response incorporation process was completed'}
                isMobile={isMobile}
              />
            </div>
          )}
          {currentCard === 'Deliverable' && (
            <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_revisionTag`}>
              <CustomTooltip
                title={`NF has been linked since revision ${
                  typeof snippetData?.revision_nf === 'number'
                    ? snippetData.revision_nf
                    : snippetData?.revision || snippetData?.flags?.revision
                }`}
                placement={'bottom'}
              >
                <ChipsRevisionTag
                  processStatus={'issued'}
                  isSubmittal
                  value={`R${
                    typeof snippetData?.revision_nf === 'number'
                      ? snippetData.revision_nf
                      : snippetData?.revision || snippetData?.flags?.revision
                  }`}
                />
              </CustomTooltip>
            </div>
          )}
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_rightInfo`}>
            {showDateField ? (
              <DateField
                selectedDate={moment(snippetData.due_date).format('MM/DD/YYYY')}
                isSentRequestToChange={statusDateColor.isWarning}
                isOpenCalendar={false}
                isDiffDueScheduledDate={false}
                info={false}
                disabled={false}
                isBefore={isPcdCardLinkedNfsTab || statusDateColor.isCritical}
                error={false}
                valueIsDate={true}
                isDisableBorder={true}
                isHideDate={true}
                tooltip={statusDateColor?.tooltip || ''}
                isMobile={isMobile}
                isSnippet
              />
            ) : (
              <div />
            )}
            {showWatchIcon &&
              (currentCard === 'Deliverable' ? (
                <CustomTooltip title={watchToolTip} placement={'bottom'}>
                  <div className={`${themeClass}_watchIcon relatedSnippet`}>
                    <TokenIcon iconName={'stopwatch-crash'} size={isMobile ? 20 : 16} />
                  </div>
                </CustomTooltip>
              ) : (
                <CustomTooltip title={watchToolTip} placement={'bottom'}>
                  <div className={`${themeClass}_watchIcon relatedSnippet`}>
                    <TokenIcon iconName={'stopwatch'} size={isMobile ? 20 : 16} />
                  </div>
                </CustomTooltip>
              ))}
            {showDeactivate && <TokenIcon iconName={'deactivate'} size={isMobile ? 20 : 16} />}
          </div>
          <div className={`${themeClass}_${getNameClassSnippet(currentCard)}_unlink`}>
            {handleUnlink && (
              <SystemButton
                clickHandler={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleUnlink(e);
                }}
                type={'unlink'}
                size={'md'}
                variant={'transparent'}
                disabled={isDisabledSnippet || isDeactivated}
                isMobile={isMobile}
                tooltip={'Click to unlink'}
              />
            )}
          </div>
        </a>
      </div>
    );
  };

  const getSnippetDeliverables = () => {
    if (sandBoxHotlistSectionType) {
      return (
        <a
          className={cn(`${themeClass}_relatedItems -widgetHotlist`, {
            [`-sidePeek`]: isDragRequestCard,
          })}
          href={returnUrlDeliverablesCardTab(snippetData)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenItemCards(e);
          }}
        >
          <div className={`${themeClass}_deliverables ${snippetStyleState} -widgetHotlist`}>
            <div className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)}_starredArea`}>
              {isHotList ? (
                <ActionButton type={'starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              ) : (
                <ActionButton type={'not-starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              )}
            </div>
            <div className={`${themeClass}_deliverables_deliverableButton`}>
              <CardLinkButton
                type={'deliverable'}
                size={'sm'}
                icon={() => <TokenIcon iconName={getIconType(snippetData?.type)} size={16} />}
                title={`${snippetData.type}`}
                customClassName={`${themeClass}_cardLinkButtonDeliverablesTitle`}
                clickHandler={handleOpenItem}
              />
            </div>
            <div className={`${themeClass}_deliverables_revisionTag`}>
              <ChipsRevisionTag processStatus={snippetData.status} value={snippetData.revision} />
            </div>
            <CustomTooltip title={snippetData.title || ''} placement={'bottom'}>
              <div className={`${themeClass}_deliverables_middleTitle -widgetHotlist`}>
                <div className={`${themeClass}_deliverables_middleTitle_specific`}>{snippetData.title}</div>
              </div>
            </CustomTooltip>
            <div className={`${themeClass}_deliverables_deliverableStatus -widgetHotlist`}>
              <StatusRevision showTextLabelAsTooltip status={snippetData.status} review_state={snippetData.review_state} isVisibleLabel />
            </div>
            <CustomTooltip title={snippetData?.location_title} placement={'bottom'}>
              <div className={`${themeClass}_deliverables_disciplineLocation`}>
                <div className={`${themeClass}_deliverables_disciplineLocation_location`}>{snippetData?.location_title}</div>
              </div>
            </CustomTooltip>
          </div>
        </a>
      );
    }
    return (
      <a
        className={cn(`${themeClass}_relatedItems`, {
          [`-sidePeek`]: isDragRequestCard,
        })}
        href={returnUrlDeliverablesCardTab(snippetData)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleOpenItemCards(e);
        }}
      >
        <div className={`${themeClass}_deliverables ${snippetStyleState}`}>
          <div className={`${themeClass}_deliverables_emptyArea`}>
            <CustomTooltip title={snippetData.discipline?.title || ''} placement={'bottom'}>
              <div className={`${themeClass}_deliverables_emptyArea_blockArea -${snippetData.discipline?.text_color || ''}`} />
            </CustomTooltip>
          </div>
          <div className={`${themeClass}_deliverables_deliverableButton`}>
            <CardLinkButton
              type={'deliverable'}
              size={isMobile ? 'md' : 'sm'}
              icon={() => <TokenIcon iconName={getIconType(snippetData?.type?.title)} size={16} />}
              title={`${snippetData.type?.title}`}
              customClassName={`${themeClass}_cardLinkButtonDeliverablesTitle`}
              clickHandler={handleOpenItem}
              isMobile={isMobile}
            />
          </div>
          {currentCard === 'Deliverable' ? (
            <div className={`${themeClass}_deliverables_revisionTag`}>
              <ChipsRevisionTag
                processStatus={snippetData.flags.last_status}
                value={`R${snippetData.flags.last_revision}`}
                isMobile={isMobile}
              />
            </div>
          ) : (
            <div className={`${themeClass}_deliverables_revisionTag`}>
              <ChipsRevisionTag processStatus={snippetData.status} value={`R${snippetData.revision}`} isMobile={isMobile} />
            </div>
          )}
          <div className={`${themeClass}_deliverables_statusTriangle`}>
            {validityStatusIcon ? validityStatusIcon : getSetStatus(snippetData?.flags?.validity_status)}
          </div>
          {currentCard === 'Deliverable' ? (
            <CustomTooltip
              title={snippetData.flags.last_title || ''}
              className={`${themeClass}_deliverables_middleTitle_tooltip`}
              placement={'bottom'}
            >
              <div className={`${themeClass}_deliverables_middleTitle`}>
                <div className={`${themeClass}_deliverables_middleTitle_specific`}>{snippetData.flags.last_title}</div>
              </div>
            </CustomTooltip>
          ) : (
            <CustomTooltip
              title={snippetData.title || ''}
              className={`${themeClass}_deliverables_middleTitle_tooltip`}
              placement={'bottom'}
            >
              <div className={`${themeClass}_deliverables_middleTitle`}>
                <div className={`${themeClass}_deliverables_middleTitle_specific`}>{snippetData.title}</div>
              </div>
            </CustomTooltip>
          )}
          {currentCard === 'Deliverable' ? (
            <div className={`${themeClass}_deliverables_deliverableStatus`}>
              <StatusRevision
                showTextLabelAsTooltip
                status={snippetData.flags.last_status}
                review_state={snippetData.flags.last_review_state}
                isMobile={isMobile}
              />
              {snippetData.flags?.is_hard === 1 ? (
                <CustomTooltip title={'Hard linkage (created in deliverables configuration)'} placement={'bottom'}>
                  <div className={`${themeClass}_deliverables_deliverableStatus_lockIcon`}>
                    <TokenIcon iconName={'link-locked'} size={16} />
                  </div>
                </CustomTooltip>
              ) : (
                <div className={`${themeClass}_deliverables_deliverableStatus_lockIcon`} />
              )}
            </div>
          ) : (
            <div className={`${themeClass}_deliverables_deliverableStatus`}>
              <StatusRevision
                showTextLabelAsTooltip
                status={snippetData.status}
                review_state={snippetData.review_state}
                isMobile={isMobile}
              />
            </div>
          )}
          <CustomTooltip
            title={
              snippetData?.owner?.title ? `${snippetData?.owner?.title || ''} (${snippetData?.building?.title || 'Empty building'})` : ''
            }
            placement={'bottom'}
            className={`${themeClass}_deliverables_disciplineLocation_tooltip`}
          >
            <div className={`${themeClass}_deliverables_disciplineLocation`}>
              <div className={`${themeClass}_deliverables_disciplineLocation_location`}>
                {snippetData?.owner?.title} ({snippetData?.building?.title})
              </div>
            </div>
          </CustomTooltip>
          <div
            className={cn(`${themeClass}_deliverables_dateContainer`, {
              ['-submittal']: currentCard === 'Submittal',
            })}
          >
            {snippetData.flags.ovd_nf && currentCard !== 'Deliverable' ? (
              <CustomTooltip title={'Deliverable Scheduled date is threatened by request due date'} placement={'bottom'}>
                <TokenIcon
                  customClass={`${themeClass}_deliverables_dateContainer_icon`}
                  iconName={'stopwatch-crash'}
                  size={isMobile ? 20 : 16}
                />
              </CustomTooltip>
            ) : (
              currentCard === 'Deliverable' && (
                <DateField
                  selectedDate={moment(deliverableSnippetDate.date).format('MM/DD/YYYY')}
                  isSentRequestToChange={!!statusDateColor?.isWarning}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={false}
                  isBefore={!!statusDateColor?.isCritical}
                  error={false}
                  valueIsDate={true}
                  isDisableBorder={true}
                  isHideDate={true}
                  tooltip={
                    statusDateColor?.isCritical && part
                      ? `Scheduled date is ${part === 'predecessor' ? 'later' : 'earlier'} than that of this deliverable`
                      : statusDateColor?.isWarning
                      ? 'Deliverable Scheduled date is threatened by request due date'
                      : ''
                  }
                />
              )
            )}
          </div>
          <div className={`${themeClass}_deliverables_unlink`}>
            {handleUnlink && (!snippetData?.flags?.is_hard || snippetData.flags.is_hard === 0) && (
              <SystemButton
                clickHandler={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleUnlink(e);
                }}
                type={'unlink'}
                size={'md'}
                variant={'transparent'}
                disabled={isDisabledSnippet || isDeactivated}
                isMobile={isMobile}
                tooltip={'Click to unlink'}
              />
            )}
          </div>
        </div>
      </a>
    );
  };

  const getSnippetSubmittals = () => {
    if (sandBoxHotlistSectionType) {
      return (
        <a
          className={cn(`${themeClass}_relatedItems -widgetHotlist`, { [`-sidePeek`]: isDragRequestCard })}
          href={returnUrlSubmittalCardTab(snippetData)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpenItemCards(e);
          }}
        >
          <div className={`${themeClass}_submittals ${snippetStyleState} -widgetHotlist`}>
            <div className={`${themeClass}_${getNameClassSnippet(sandBoxHotlistSectionType)}_starredArea`}>
              {isHotList ? (
                <ActionButton type={'starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              ) : (
                <ActionButton type={'not-starred'} clickHandler={handleSetHotList} isMobile={isMobile} />
              )}
            </div>
            <div className={`${themeClass}_submittals_requestType`}>
              <CustomTooltip title={snippetData?.type?.title || ''} placement={'bottom'}>
                <ChipsRequestType isMobile={isMobile} type={snippetData?.type?.title || ''} isNotShowText />
              </CustomTooltip>
            </div>
            <div className={`${themeClass}_submittals_nfButton`}>
              <CardLinkButton
                type={'submittal'}
                size={'sm'}
                title={`#${snippetData?.mf_code} - ${snippetData?.sf} (R${snippetData?.version})`}
                customClassName={`${themeClass}_cardLinkButtonTitle`}
                clickHandler={handleOpenItem}
              />
            </div>
            <CustomTooltip title={snippetData.title || ''} placement={'bottom'}>
              <div className={`${themeClass}_submittals_middleTitle`}>
                <div className={`${themeClass}_submittals_middleTitle_specific`}>{snippetData?.title}</div>
              </div>
            </CustomTooltip>
            <div className={`${themeClass}_submittals_statusNf`}>
              <StatusSubmittal status={snippetData?.status} reviewState={snippetData?.review_state} />
            </div>
          </div>
        </a>
      );
    }

    return (
      <a
        className={cn(`${themeClass}_relatedItems`, { [`-sidePeek`]: isDragRequestCard })}
        href={returnUrlSubmittalCardTab(snippetData)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleOpenItemCards(e);
        }}
      >
        <div className={`${themeClass}_submittals ${snippetStyleState}`}>
          <div className={`${themeClass}_submittals_requestType`}>
            <CustomTooltip title={snippetData?.type || ''} placement={'bottom'}>
              <ChipsRequestType isMobile={isMobile} type={snippetData?.type || ''} isNotShowText />
            </CustomTooltip>
          </div>
          <div className={`${themeClass}_submittals_nfButton`}>
            <CardLinkButton
              type={'submittal'}
              size={'sm'}
              title={`#${snippetData?.mf_code} - ${snippetData?.sf} (R${snippetData?.version})`}
              customClassName={`${themeClass}_cardLinkButtonTitle`}
              clickHandler={handleOpenItem}
            />
          </div>
          <div className={`${themeClass}_submittals_statusTriangle`}>
            {validityStatusIcon ? validityStatusIcon : getSetStatus(snippetData?.flags?.validity_status)}
          </div>
          <div className={`${themeClass}_submittals_middleTitle`}>
            <CustomTooltip
              title={snippetData.title || ''}
              placement={'bottom'}
              customBasisClass={`${themeClass}_submittals_customBasisClassWidth`}
            >
              <div className={`${themeClass}_submittals_middleTitle_specific`}>{snippetData?.title}</div>
            </CustomTooltip>
            {snippetData?.reflect_input === 1 && (
              <CustomTooltip title={'Output submittal reflected as input data'} placement={'top'}>
                <div className={`${themeClass}_submittals_middleTitle_icon`}>
                  <TokenIcon iconName={'mirror'} size={16} />
                </div>
              </CustomTooltip>
            )}
          </div>
          <div className={`${themeClass}_submittals_statusNf`}>
            <StatusSubmittal status={snippetData?.status} reviewState={snippetData?.review_state} />
          </div>
          <div className={`${themeClass}_submittals_unlink`}>
            {handleUnlink && (
              <SystemButton
                clickHandler={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleUnlink(e);
                }}
                type={'unlink'}
                size={'md'}
                variant={'transparent'}
                disabled={isDisabledSnippet || isDeactivated}
                isMobile={isMobile}
                tooltip={'Click to unlink'}
              />
            )}
          </div>
        </div>
      </a>
    );
  };

  return (
    <>
      {(relatedTabType && snippetData.snippet === 'request') || sandBoxHotlistSectionType === 'request' ? getSnippetNF() : null}
      {(relatedTabType && snippetData.snippet === 'deliverable') || sandBoxHotlistSectionType === 'deliverable'
        ? getSnippetDeliverables()
        : null}
      {(relatedTabType && snippetData.snippet === 'submittal') || sandBoxHotlistSectionType?.includes('submittal')
        ? getSnippetSubmittals()
        : null}
      {relatedTabType && snippetData.snippet === 'document' ? (
        <div className={cn(`${themeClass}_relatedItems`, { [`-sidePeek`]: isDragRequestCard })}>
          <div className={`${themeClass}_ddl ${snippetStyleState}`}>
            <div className={`${themeClass}_ddl_middleTitle`}>
              <div className={`${themeClass}_ddl_middleTitle_specific`}>{snippetData.title}</div>
            </div>
            <div className={`${themeClass}_ddl_statusTriangle`}>{getSetStatus(snippetData?.flags?.validity_status)}</div>
            <div className={`${themeClass}_ddl_levelBuilding`}>
              <div className={`${themeClass}_ddl_levelBuilding_title`}>
                <CustomTooltip title={(snippetData.building?.length && snippetData.building[0]?.title) || ''} placement={'bottom'}>
                  {(snippetData.building?.length && snippetData.building[0]?.title) || ''}
                </CustomTooltip>
              </div>
            </div>
            <div className={`${themeClass}_ddl_location`}>
              <div className={`${themeClass}_ddl_location_title`}>{snippetData.discipline?.title || ''}</div>
            </div>
            <div className={`${themeClass}_ddl_revisionTag`}>
              <ChipsRevisionTag processStatus={'issued'} isSubmittal value={`R${snippetData.revision}`} />
            </div>
            <div className={`${themeClass}_ddl_nameSet`}>
              <div className={`${themeClass}_ddl_nameSet_title`}>{snippetData.current_set || ''}</div>
            </div>
            <div className={`${themeClass}_ddl_unlink`}>
              {handleUnlink && (
                <SystemButton
                  clickHandler={handleUnlink}
                  type={'unlink'}
                  size={'md'}
                  variant={'transparent'}
                  disabled={isDisabledSnippet || isDeactivated}
                  isMobile={isMobile}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {snippetData.nf && isPortableCard && !isShowSnippetNarrow ? (
        <CustomTooltip customBasisClass={`${themeBasisTooltipClass}_portable`} title={toolTipTitle} placement={'top-start'}>
          <div
            className={cn(`${themeClass}_portable ${snippetStyleState}`, {
              ['-deactivated']: snippetData?.is_deactivated || isDisabledSnippet,
              [`${isTriggerStyle}`]: !!isTriggerStyle,
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={{
              background: `linear-gradient(90deg, ${
                snippetData.req_dis_color || (theme === 'dark' ? `rgb(34, 48, 77)` : `rgb(240, 240, 240)`)
              } 4px, ${theme === 'dark' ? `rgb(34, 48, 77)` : `rgb(240, 240, 240)`} 4px)`,
            }}
          >
            <div className={`${themeClass}_topPart`}>
              <div className={`${themeClass}_topPart_nfAndStatus`}>
                <a onClick={handleOpenItem} href={returnUrlNfCardTab(snippetData)}>
                  <CardLinkButton
                    clickHandler={!snippetData?.is_deactivated && handleOpenItem}
                    type={'nf'}
                    size={'sm'}
                    title={`NF ${snippetData.nf}`}
                    isMobile={isMobile}
                  />
                </a>
                <StatusNF status={snippetData.status || snippetData.lastStatus} />
                {snippetData.revision_nf || snippetData.revision ? (
                  <ChipsRevisionTag
                    processStatus={'issued'}
                    value={`R${typeof snippetData.revision_nf === 'number' ? snippetData.revision_nf : snippetData.revision}`}
                  />
                ) : null}
              </div>
              <div className={`${themeClass}_topPart_icons`}>
                {incorporationIcon && incorporationIcon}
                {showWatchIcon && (
                  <CustomTooltip title={watchToolTip} placement={'bottom'}>
                    <div className={`${themeClass}_watchIcon`}>
                      <TokenIcon iconName={'stopwatch'} size={isMobile ? 20 : 16} />
                    </div>
                  </CustomTooltip>
                )}
                {showDeactivate && <TokenIcon iconName={'deactivate'} size={isMobile ? 20 : 16} />}
                {showDateField ? (
                  <DateField
                    selectedDate={moment(snippetData.due_date).format('MM/DD/YYYY')}
                    isSentRequestToChange={statusDateColor.isWarning}
                    isOpenCalendar={false}
                    isDiffDueScheduledDate={false}
                    info={false}
                    disabled={false}
                    isBefore={isPcdCardLinkedNfsTab || statusDateColor.isCritical}
                    error={false}
                    valueIsDate={true}
                    isDisableBorder={true}
                    isHideDate={true}
                    tooltip={dateFieldToolTip || ''}
                    isMobile={isMobile}
                  />
                ) : null}
                {validityStatusIcon ? validityStatusIcon : null}
                {handleUnlink && isHover && (
                  <SystemButton
                    clickHandler={handleUnlink}
                    type={'unlink'}
                    size={'md'}
                    variant={'transparent'}
                    disabled={isDisabledSnippet || isDeactivated}
                    isMobile={isMobile}
                  />
                )}
              </div>
            </div>
            <div className={`${themeClass}_bottomPart`}>
              <ChipsRequestType isMobile={isMobile} type={snippetData?.tags[0]?.title} />
              <div className={`${themeClass}_bottomPart_title`}>{snippetData.specific}</div>
            </div>
          </div>
        </CustomTooltip>
      ) : null}
      {snippetData.nf && isDDLog && !isShowSnippetNarrow ? (
        <div className={`${themeClass}_link`} onClick={e => handleOpenItem(e)}>
          <div
            className={cn(`${themeClass}_container ${snippetStyleState}`, {
              ['-isDDLog']: isDDLog,
              ['-is1920']: is1920full,
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <div className={cn(`${themeClass}_activity`, { ['-isDDLog']: isDDLog })}>
              <div className={`${themeClass}_activity_requestType`}>
                <ChipsRequestType type={snippetData.request_type_title} />
              </div>
            </div>
            <div className={cn(`${themeClass}_nfButton`, { ['-isDDLog']: isDDLog })}>
              <CardLinkButton type={'nf'} size={'sm'} title={`NF ${snippetData.nf}`} />
            </div>
            <div className={cn(`${themeClass}_middlePart`, { ['-isDDLog']: isDDLog })}>
              <div className={cn(`${themeClass}_middlePart_titleParts`, { ['-isDDLog']: isDDLog })}>
                <div className={cn(`${themeClass}_middlePart_titleBlock`, { ['-isDDLog']: isDDLog })}>
                  <div className={`${themeClass}_middlePart_specific`}>{snippetData.title}</div>
                </div>
                <div className={`${themeClass}_middlePart_company`}>{companyString}</div>
              </div>
            </div>
            {!('ontouchstart' in window) ? (
              <div className={cn(`${themeClass}_statusDate`, { ['-isDDLog']: isDDLog })}>
                <StatusNF status={snippetData?.is_deactivated ? 'deactivated' : snippetData.status} />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {snippetData.nf && !isPortableCard && !isDDLog && !isShowSnippetNarrow ? (
        <a
          className={`${themeClass}_link`}
          onClick={e => {
            e.stopPropagation();
            handleOpenItem(e);
          }}
          href={openedFrom !== 'report' ? returnUrlNfCardTab(snippetData) : null}
        >
          <div
            className={cn(`${themeClass}_container ${snippetStyleState}`, {
              ['-isShortView']: isShortView && !isTablet,
              ['-nfSnippet']: snippetData.nf,
              ['-is1920']: is1920full,
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <div className={cn(`${themeClass}_activity`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={`${themeClass}_activity_requestType`}>
                {/*!isMobile && <div style={{ height: '16px', width: '16px', background: 'transparent' }}></div>*/}
                {snippetData.no_change_hot_list ? (
                  <ActionButton type={'hotlisted'} clickHandler={null} isMobile={isMobile} disabled />
                ) : (
                  <div style={{ height: '20px', width: '20px', background: 'transparent' }}></div>
                )}
                {isHotList ? (
                  <ActionButton type={'starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                ) : (
                  <ActionButton type={'not-starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                )}
                <ChipsRequestType type={snippetData.request_type_title} isMobile={isMobile} />
              </div>
              {(!isShortView || isMobile) && (
                <>
                  <div className={cn(`${themeClass}_activity_indicators -desktop`, { ['-mobile']: isMobile })}>
                    {snippetData.have_successors ? <IdentificatoryButton type={'linked-nfs'} isMobile={isMobile} /> : null}
                    {snippetData.have_predecessors ? <IdentificatoryButton type={'linked-predecessors'} isMobile={isMobile} /> : null}
                    {snippetData.have_comment ? <IdentificatoryButton type={'comments'} isMobile={isMobile} /> : null}
                    {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} isMobile={isMobile} /> : null}
                    {isMobile && snippetData?.marker && (
                      <MarkerBlock marker={snippetData.marker} is1100Resolution={true} themeClass={themeClass} />
                    )}
                  </div>
                  <div
                    className={cn(`${themeClass}_activity_menuBtn`, {
                      ['-hidden']:
                        (!snippetData.have_successors &&
                          !snippetData.have_predecessors &&
                          !snippetData.have_comment &&
                          !snippetData.have_attachment) ||
                        isMobile,
                    })}
                  >
                    <OverflowMenuButton size={'sm'} clickHandler={handleOpenIndicatorsMenu} />
                  </div>
                  <Popover
                    open={!!isIndicatorsMenuOpen}
                    onClose={handleCloseIndicatorsMenu}
                    anchorEl={isIndicatorsMenuOpen}
                    classes={{
                      paper: `${themeClass}_activity_indicators_paper`,
                    }}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <div className={`${themeClass}_activity_indicators`}>
                      {snippetData.have_successors ? <IdentificatoryButton type={'linked-nfs'} /> : null}
                      {snippetData.have_predecessors ? <IdentificatoryButton type={'linked-predecessors'} /> : null}
                      {snippetData.have_comment ? <IdentificatoryButton type={'comments'} /> : null}
                      {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} /> : null}
                    </div>
                  </Popover>
                </>
              )}
            </div>
            <div className={cn(`${themeClass}_nfButton`, { ['-isShortView']: isShortView && !isTablet })}>
              <CardLinkButton type={'nf'} size={'sm'} title={`NF ${snippetData.nf}`} isMobile={isMobile} />
            </div>
            {(isTablet || sizeContainer === '1024') && !isShortView && milestoneTitle && (
              <div className={`${themeClass}_middlePart_lod`}>
                <ChipsLOD type={'text'} value={`[${milestoneTitle}]`} isMobile={isMobile} />
              </div>
            )}
            <div className={cn(`${themeClass}_middlePart`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={cn(`${themeClass}_middlePart_titleParts`, { ['-isShortView']: isShortView && !isTablet })}>
                <div className={cn(`${themeClass}_middlePart_titleBlock`, { ['-isShortView']: isShortView && !isTablet })}>
                  <div className={`${themeClass}_middlePart_specific`}>{snippetData.title}</div>
                  {!isShortView && !isTablet && sizeContainer !== '1024' && (
                    <div className={`${themeClass}_middlePart_lod`}>
                      {milestoneTitle && <ChipsLOD type={'text'} value={`[${milestoneTitle}]`} />}
                    </div>
                  )}
                </div>
                <div className={`${themeClass}_middlePart_company`}>
                  <span>{snippetData.request_party_title}</span>
                  {snippetData.response_party_title && <TokenIcon iconName={'arrow-right'} size={isMobile ? 16 : 12} />}
                  {snippetData.response_party_title && <span>{snippetData.response_party_title}</span>}
                </div>
              </div>
              {((sizeContainer === '960' && !snippetData.marker) ||
                (sizeContainer !== '1024' && is1100Resolution && !snippetData.marker)) && (
                <EndField
                  handleEndField={handleEndField}
                  is1100Resolution={is1100Resolution}
                  themeClass={themeClass}
                  marker={snippetData.marker}
                  status={snippetData.status}
                  isAccessToEditDeactivation={isAccessToEditDeactivation}
                  isDeactivated={snippetData.is_deactivated}
                  isHover={isHover}
                  openedFrom={openedFrom}
                  isMobile={isMobile}
                  customClassName={`${themeClass}_snippetEndField`}
                />
              )}
            </div>
            <div className={cn(`${themeClass}_statusDate`, { ['-isShortView']: isShortView && !isTablet })}>
              <StatusNF status={snippetData?.is_deactivated ? 'deactivated' : snippetData.status} isMobile={isMobile} />
              <div className={`${themeClass}_statusDate_dateContainer`}>
                <DateField
                  selectedDate={moment(snippetData.due_date).format('MM/DD/YYYY')}
                  isSentRequestToChange={statusDateColor.isWarning}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={false}
                  isBefore={statusDateColor.isCritical}
                  error={false}
                  valueIsDate={!!snippetData.due_date}
                  isDisableBorder={true}
                  isHideDate={!!isShowDate || isShortView}
                  isMobile={isMobile}
                />
              </div>
            </div>
            {sizeContainer && !isMobile && <MarkerBlock marker={snippetData.marker} is1100Resolution={true} themeClass={themeClass} />}
            {((!isMobile && !sizeContainer) || (sizeContainer === '1024' && !snippetData.marker)) && (
              <EndField
                handleEndField={handleEndField}
                is1100Resolution={is1100Resolution}
                themeClass={themeClass}
                marker={snippetData.marker}
                status={snippetData.status}
                isAccessToEditDeactivation={isAccessToEditDeactivation}
                isDeactivated={snippetData.is_deactivated}
                isHover={isHover}
                openedFrom={openedFrom}
                customClassName={`${themeClass}_snippetEndField`}
              />
            )}
          </div>
        </a>
      ) : null}
      {(snippetData.revision || !!snippetData.snippet) && !isPcdCardLinkedNfsTab && isPortableCard && !isShowSnippetNarrow ? (
        <CustomTooltip customBasisClass={`${themeBasisTooltipClass}_portable`} title={toolTipTitle} placement={'top-start'}>
          <div
            className={cn(`${themeClass}_portable ${snippetStyleState}`, {
              ['-deactivated']: snippetData?.is_deactivated,
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <div className={`${themeClass}_topPart`}>
              <div className={`${themeClass}_topPart_linkAndTag`}>
                <a onClick={handleOpenItem} href={returnUrlDeliverablesCardTab(snippetData)}>
                  <CardLinkButton type={'deliverable'} size={'sm'} title={`${snippetData.type}`} />
                </a>
                <ChipsRevisionTag
                  processStatus={snippetData.status}
                  value={snippetData.snippet ? `R${snippetData.last_revision}` : snippetData.revision}
                />
              </div>
              <div className={`${themeClass}_topPart_icons`}>
                <DateField
                  selectedDate={moment(snippetData.due_date).format('MM/DD/YYYY')}
                  isSentRequestToChange={statusDateColor.isWarning}
                  isOpenCalendar={false}
                  isDiffDueScheduledDate={false}
                  info={false}
                  disabled={false}
                  isBefore={statusDateColor.isCritical}
                  error={false}
                  valueIsDate={true}
                  isDisableBorder={true}
                  isHideDate={true}
                />
                {handleUnlink && isHover && (
                  <SystemButton
                    clickHandler={() => handleUnlink(snippetData)}
                    type={'unlink'}
                    size={'md'}
                    variant={'transparent'}
                    disabled={isDeactivated}
                  />
                )}
              </div>
            </div>
            <div className={`${themeClass}_bottomPart`}>
              <StatusRevision
                isVisibleLabel
                status={snippetData.snippet ? snippetData.last_status : snippetData.status}
                review_state={snippetData.review_state}
              />
              <div className={`${themeClass}_bottomPart_location`}>{snippetData.location_title}</div>
            </div>
          </div>
        </CustomTooltip>
      ) : null}
      {(snippetData.revision || !!snippetData.snippet) && !isPortableCard && !isShowSnippetNarrow ? (
        <a
          className={`${themeClass}_link`}
          onClick={e => handleOpenItem(e)}
          href={openedFrom !== 'report' ? returnUrlDeliverablesCardTab(snippetData) : null}
        >
          <div
            className={cn(`${themeClass}_container ${snippetStyleState}`, {
              ['-isShortView']: isShortView && !isTablet,
              ['-deliverableSnippet']: snippetData?.revision || !!snippetData.snippet,
              ['-is1920']: is1920full,
            })}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
          >
            <div className={cn(`${themeClass}_firstPart`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={`${themeClass}_firstPart_fireBtn`}>
                {/*!isMobile && <div style={{ height: '16px', width: '16px', background: 'transparent' }}></div>*/}
                {snippetData.no_change_hot_list ? (
                  <ActionButton type={'hotlisted'} clickHandler={null} isMobile={isMobile} disabled />
                ) : (
                  <div style={{ height: '20px', width: '20px', background: 'transparent' }}></div>
                )}
                {isHotList ? (
                  <ActionButton type={'starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                ) : (
                  <ActionButton type={'not-starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                )}
                <CardLinkButton type={'deliverable'} size={'sm'} title={`${snippetData.type}`} isMobile={isMobile} />
              </div>
              {(!isShortView || isTablet || isMobile) && (
                <>
                  <div className={cn(`${themeClass}_firstPart_indicators -desktop`, { ['-mobile']: isMobile })}>
                    {snippetData.have_linked_nfs ? <IdentificatoryButton type={'linked-nfs'} isMobile={isMobile} /> : null}
                    {snippetData.have_related_deliverables ? (
                      <IdentificatoryButton type={'related-deliverables'} isMobile={isMobile} />
                    ) : null}
                    {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} isMobile={isMobile} /> : null}
                    {snippetData.have_comments ? <IdentificatoryButton type={'comments'} isMobile={isMobile} /> : null}
                    {snippetData.have_outstanding_nfs ? <IdentificatoryButton type={'outstanding-nfs'} isMobile={isMobile} /> : null}
                    {isMobile && snippetData?.marker && (
                      <MarkerBlock marker={snippetData.marker} is1100Resolution={true} themeClass={themeClass} />
                    )}
                  </div>
                  <div
                    className={cn(`${themeClass}_activity_menuBtn`, {
                      ['-hidden']:
                        (!snippetData.have_linked_nfs &&
                          !snippetData.have_related_deliverables &&
                          !snippetData.have_attachment &&
                          !snippetData.have_comments &&
                          !snippetData.have_outstanding_nfs) ||
                        isMobile,
                    })}
                  >
                    <OverflowMenuButton size={'sm'} clickHandler={handleOpenIndicatorsMenu} />
                  </div>
                  <Popover
                    open={!!isIndicatorsMenuOpen}
                    onClose={handleCloseIndicatorsMenu}
                    anchorEl={isIndicatorsMenuOpen}
                    classes={{
                      paper: `${themeClass}_activity_indicators_paper`,
                    }}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <div className={`${themeClass}_activity_indicators`}>
                      {snippetData.have_linked_nfs ? <IdentificatoryButton type={'linked-nfs'} /> : null}
                      {snippetData.have_related_deliverables ? <IdentificatoryButton type={'related-deliverables'} /> : null}
                      {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} /> : null}
                      {snippetData.have_comments ? <IdentificatoryButton type={'comments'} /> : null}
                      {snippetData.have_outstanding_nfs ? <IdentificatoryButton type={'outstanding-nfs'} /> : null}
                    </div>
                  </Popover>
                </>
              )}
            </div>
            <div className={cn(`${themeClass}_revisionLink`, { ['-isShortView']: isShortView && !isTablet })}>
              <ChipsRevisionTag
                processStatus={snippetData.status}
                value={snippetData.snippet ? `R${snippetData.last_revision}` : snippetData.revision}
              />
            </div>
            <div className={cn(`${themeClass}_middleTitle`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={`${themeClass}_middleTitle_specific`}>{snippetData.title}</div>
              {(!isShortView || isTablet) && (
                <div className={`${themeClass}_middleTitle_respParty`}>
                  {snippetData.responsible_party_title && `Responsible party: ${responsiblePartyString}`}
                </div>
              )}
            </div>
            {(!isShortView || isTablet) && (
              <div className={cn(`${themeClass}_disciplineLocation`)}>
                <div className={`${themeClass}_disciplineLocation_discipline`}>{snippetData.responsible_discipline_title}</div>
                <div className={`${themeClass}_disciplineLocation_location`}>{snippetData.location_title}</div>
              </div>
            )}
            <div className={cn(`${themeClass}_delStatusDate`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={`${themeClass}_delStatusDate_deliverableStatus`}>
                <StatusRevision
                  isVisibleLabel
                  status={snippetData.snippet ? snippetData.last_status : snippetData.status}
                  review_state={snippetData.review_state}
                />
              </div>
              <div className={cn(`${themeClass}_delStatusDate_dateContainer`, { ['-isShortView']: isShortView && !isTablet })}>
                <>
                  <DateField
                    selectedDate={moment(deliverableSnippetDate.date).format('MM/DD/YYYY')}
                    isSentRequestToChange={statusDateColor.isWarning}
                    isOpenCalendar={false}
                    isDiffDueScheduledDate={false}
                    info={false}
                    disabled={false}
                    isBefore={statusDateColor.isCritical}
                    error={false}
                    valueIsDate={true}
                    isDisableBorder={true}
                  />
                  <div className={cn(`${themeClass}_delStatusDate_dateContainer_date`, { ['-isShortView']: isShortView && !isTablet })}>
                    <div className={`${themeClass}_delStatusDate_dateContainer_date_postfix`}>{deliverableSnippetDate.postfix}</div>
                  </div>
                </>
              </div>
            </div>
            {!snippetData.snippet && openedFrom !== 'report' && !isMobile ? (
              <MarkerBlock
                marker={snippetData.marker}
                is1100Resolution={true}
                themeClass={themeClass}
                isShortView={isShortView}
                isTablet={isTablet}
                isDeliverable
              />
            ) : (
              <div className={`${themeClass}_endField`}>
                {!!snippetData.snippet && handleUnlink && (
                  <div className={`${themeClass}_unlink`}>
                    <SystemButton
                      clickHandler={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleUnlink(snippetData);
                      }}
                      type={'unlink'}
                      size={'md'}
                      variant={'transparent'}
                      disabled={isDeactivated}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </a>
      ) : null}
      {(snippetData.sf_index || !!snippetData.snippet) && !isPortableCard && !isShowSnippetNarrow ? (
        <a
          className={`${themeClass}_link`}
          onClick={e => handleOpenItem(e)}
          href={openedFrom !== 'report' ? returnUrlSubmittalCardTab(snippetData) : null}
        >
          <div
            className={cn(`${themeClass}_container ${snippetStyleState}`, {
              ['-isShortView']: isShortView && !isTablet,
              ['-submittalSnippet']: snippetData?.sf_index || !!snippetData.snippet,
              ['-is1920']: is1920full,
            })}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
          >
            <div className={cn(`${themeClass}_leftPart`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={`${themeClass}_leftPart_fireButton`}>
                {/*!isMobile && <div style={{ height: '16px', width: '16px', background: 'transparent' }}></div>*/}
                {snippetData.no_change_hot_list ? (
                  <ActionButton type={'hotlisted'} clickHandler={null} isMobile={isMobile} disabled />
                ) : (
                  <div style={{ height: '20px', width: '20px', background: 'transparent' }}></div>
                )}
                {isHotList ? (
                  <ActionButton type={'starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                ) : (
                  <ActionButton type={'not-starred'} clickHandler={openedFrom !== 'report' ? handleSetHotList : null} isMobile={isMobile} />
                )}
                {!isMobile ? (
                  <>
                    <SystemButton type={snippetData.is_output ? 'output-data' : 'input-data'} size={'md'} variant={'transparent'} />
                    <ChipsRequestType
                      type={snippetData.type.title}
                      isTrimLabel
                      customClassname={`${themeClass}_leftPart_fireButton_chipsRequestTypeWidth`}
                    />
                  </>
                ) : (
                  <CardLinkButton type={'submittal'} size={'sm'} title={snippetData.sf_index} clickHandler={null} />
                )}
              </div>
              {(!isShortView || isTablet || isMobile) && (
                <>
                  <div className={cn(`${themeClass}_leftPart_indicators -desktop`, { ['-mobile']: isMobile })}>
                    {snippetData.have_linked_nfs ? <IdentificatoryButton type={'linked-nfs'} isMobile={isMobile} /> : null}
                    {snippetData.have_related_deliverables ? (
                      <IdentificatoryButton type={'related-deliverables'} isMobile={isMobile} />
                    ) : null}
                    {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} isMobile={isMobile} /> : null}
                    {snippetData.have_comments ? <IdentificatoryButton type={'comments'} isMobile={isMobile} /> : null}
                    {snippetData.have_outstanding_nfs ? <IdentificatoryButton type={'outstanding-nfs'} isMobile={isMobile} /> : null}
                    {isMobile && snippetData?.marker && (
                      <MarkerBlock marker={snippetData.marker} is1100Resolution={true} themeClass={themeClass} />
                    )}
                  </div>
                  <div
                    className={cn(`${themeClass}_activity_menuBtn`, {
                      ['-hidden']: (!snippetData.have_attachment && !snippetData.have_comments) || isMobile,
                    })}
                  >
                    <OverflowMenuButton size={'sm'} clickHandler={handleOpenIndicatorsMenu} />
                  </div>
                  <Popover
                    open={!!isIndicatorsMenuOpen}
                    onClose={handleCloseIndicatorsMenu}
                    anchorEl={isIndicatorsMenuOpen}
                    classes={{
                      paper: `${themeClass}_activity_indicators_paper`,
                    }}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <div className={`${themeClass}_activity_indicators`}>
                      {snippetData.have_linked_nfs ? <IdentificatoryButton type={'linked-nfs'} /> : null}
                      {snippetData.have_related_deliverables ? <IdentificatoryButton type={'related-deliverables'} /> : null}
                      {snippetData.have_attachment ? <IdentificatoryButton type={'attachments'} /> : null}
                      {snippetData.have_comments ? <IdentificatoryButton type={'comments'} /> : null}
                      {snippetData.have_outstanding_nfs ? <IdentificatoryButton type={'outstanding-nfs'} /> : null}
                    </div>
                  </Popover>
                </>
              )}
            </div>
            {!isMobile && (
              <div className={cn(`${themeClass}_submittalBtn`, { ['-isShortView']: isShortView && !isTablet })}>
                <CardLinkButton type={'submittal'} size={'sm'} title={snippetData.sf_index} clickHandler={null} />
              </div>
            )}
            <div className={cn(`${themeClass}_middleInfo`, { ['-isShortView']: isShortView && !isTablet })}>
              <div className={cn(`${themeClass}_middleInfo_specific`, { ['-isShortView']: isShortView && !isTablet })}>
                {snippetData.title}
              </div>
              {isShortView ? (
                <div className={cn(`${themeClass}_middleInfo_respParty`, { ['-isShortView']: isShortView && !isTablet })}>
                  {responsiblePartyString && `${responsiblePartyString}`}
                </div>
              ) : (
                <div className={`${themeClass}_middleInfo_respParty`}>
                  {responsiblePartyString && `Responsible contractor: ${responsiblePartyString}`}
                </div>
              )}
            </div>
            <div className={cn(`${themeClass}_rightInfo`, { ['-isShortView']: isShortView && !isTablet })}>
              {isMobile && (
                <div className={`${themeClass}_rightInfo_submittalRequestType`}>
                  <ChipsRequestType
                    type={snippetData.type.title}
                    isTrimLabel
                    customClassname={`${themeClass}_leftPart_fireButton_chipsRequestTypeWidth`}
                  />
                </div>
              )}
              <div className={`${themeClass}_rightInfo_submittalStatus`}>
                <StatusSubmittal status={snippetData?.status} reviewState={snippetData?.review_state} />
              </div>
            </div>
            <div
              className={cn(`${themeClass}_endField`, {
                [`-isSubmittal`]: !snippetData?.marker,
                [`-isSubmittalMarker`]: snippetData?.marker,
              })}
            >
              {sizeContainer === '960' && snippetData?.marker && (
                <MarkerBlock marker={snippetData.marker} is1100Resolution={true} themeClass={themeClass} />
              )}
              {(!sizeContainer || sizeContainer === '1024') && (
                <EndField
                  handleEndField={handleEndField}
                  is1100Resolution={false}
                  themeClass={themeClass}
                  marker={snippetData.marker}
                  status={snippetData.status}
                  isAccessToEditDeactivation={isAccessToEditDeactivation}
                  isDeactivated={snippetData?.status?.title === 'Deactivated'}
                  isHover={isHover}
                  openedFrom={openedFrom}
                  customClassName={`${themeClass}_snippetEndField`}
                />
              )}
            </div>
          </div>
        </a>
      ) : null}
    </>
  );
}

export default Snippet;
