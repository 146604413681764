import { SandBoxTabModel } from '../../../../models/sandBox/sandBoxArhitecture';

export type AllSectionType = 'request' | 'deliverable' | 'submittalInput' | 'submittalOutput';

export const sandBoxHotlistSections: SandBoxTabModel[] = [
  {
    id: 'hotList',
    title_to_me: `Hotlist`,
    title_to_company: `Hotlist`,
    icon: 'flame',
    iconNameClass: 'sandbox_inMyCourt_icon',
    sections: [
      {
        id: 'request_hotList',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_hotList_impactingDeliverables',
            title: 'Impacting deliverables',
          },
          {
            id: 'request_hotList_timeCritical',
            title: 'Time critical',
          },
        ],
      },
      {
        id: 'submittalInput_hotList',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_hotList_timeCritical',
            title: 'Time critical',
          },
        ],
      },
      {
        id: 'deliverable_hotList',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_hotList_timeCritical',
            title: 'Time critical',
          },
        ],
      },
      {
        id: 'submittalOutput_hotList',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_hotList_timeCritical',
            title: 'Time critical',
          },
        ],
      },
    ],
  },
];

export const sandBoxAllSections: SandBoxTabModel[] = [
  {
    id: 'inMyCourt',
    title_to_me: `In my court`,
    title_to_company: `In my company's court`,
    title_report: {
      company: `In company's court`,
      user: `In user's court`,
    },
    icon: 'bic',
    iconNameClass: 'sandbox_inMyCourt_icon',
    typeSystemButton: 'chevron-down',
    sections: [
      {
        id: 'request_inMyCourt',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_inMyCourt_sentToMe',
            title_to_me: 'Sent to me',
            title_to_company: 'Sent to my company',
            title_report: {
              company: `Sent to the company`,
              user: `Sent to the user`,
            },
          },
          {
            id: 'request_inMyCourt_sentByMe',
            title_to_me: 'Sent by me',
            title_to_company: 'Sent by my company',
            title_report: {
              company: `Sent by the company`,
              user: `Sent by the user`,
            },
          },
          {
            id: 'request_inMyCourt_changeRequests',
            title: 'Change requests',
          },
          {
            id: 'request_inMyCourt_drafts',
            title: 'Drafts',
          },
        ],
      },
      {
        id: 'submittalInput_inMyCourt',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_inMyCourt_pendingReview',
            title: 'Pending review',
          },
          {
            id: 'submittalInput_inMyCourt_revisionSubmit',
            title: 'Revision to submit',
          },
          {
            id: 'submittalInput_inMyCourt_completedReview',
            title: 'Review completed',
          },
          {
            id: 'submittalInput_inMyCourt_submittedRevisions',
            title: 'Submitted revisions w/o reviewers',
          },
        ],
      },
      {
        id: 'deliverable_inMyCourt',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_inMyCourt_pendingReview',
            title: 'Pending review',
          },
          {
            id: 'deliverable_inMyCourt_myDeliverables',
            title: 'Deliverables to issue in 30 days',
          },
          {
            id: 'deliverable_inMyCourt_newRevisions',
            title: 'New revisions to issue',
          },
        ],
      },
      {
        id: 'submittalOutput_inMyCourt',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_inMyCourt_pendingReview',
            title: 'Pending review',
          },
          {
            id: 'submittalOutput_inMyCourt_revisionSubmit',
            title: 'Revision to submit',
          },
          {
            id: 'submittalOutput_inMyCourt_completedReview',
            title: 'Review completed',
          },
          {
            id: 'submittalOutput_inMyCourt_submittedRevisions',
            title: 'Submitted revisions w/o reviewers',
          },
        ],
      },
    ],
  },
  {
    id: 'mentions',
    title_to_me: `Mentions`,
    title_to_company: `Mentions`,
    icon: 'at',
    iconNameClass: 'sandbox_mentions_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_mentions',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_mentions_assignedTo',
            title_to_me: 'Assigned to me',
            title_to_company: 'Assigned to my company',
            title_report: {
              company: `Assigned to the company`,
              user: `Assigned to the user`,
            },
          },
          {
            id: 'request_mentions_assignedBy',
            title_to_me: 'Assigned by me',
            title_to_company: 'Assigned by my company',
            title_report: {
              company: `Assigned by the company`,
              user: `Assigned by the user`,
            },
          },
        ],
      },
      {
        id: 'submittalInput_mentions',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_mentions_assignedTo',
            title_to_me: 'Assigned to me',
            title_to_company: 'Assigned to my company',
            title_report: {
              company: `Assigned to the company`,
              user: `Assigned to the user`,
            },
          },
          {
            id: 'submittalInput_mentions_assignedBy',
            title_to_me: 'Assigned by me',
            title_to_company: 'Assigned by my company',
            title_report: {
              company: `Assigned by the company`,
              user: `Assigned by the user`,
            },
          },
        ],
      },
      {
        id: 'deliverable_mentions',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_mentions_assignedTo',
            title_to_me: 'Assigned to me',
            title_to_company: 'Assigned to my company',
            title_report: {
              company: `Assigned to the company`,
              user: `Assigned to the user`,
            },
          },
          {
            id: 'deliverable_mentions_assignedBy',
            title_to_me: 'Assigned by me',
            title_to_company: 'Assigned by my company',
            title_report: {
              company: `Assigned by the company`,
              user: `Assigned by the user`,
            },
          },
        ],
      },
      {
        id: 'submittalOutput_mentions',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_mentions_assignedTo',
            title_to_me: 'Assigned to me',
            title_to_company: 'Assigned to my company',
            title_report: {
              company: `Assigned to the company`,
              user: `Assigned to the user`,
            },
          },
          {
            id: 'submittalOutput_mentions_assignedBy',
            title_to_me: 'Assigned by me',
            title_to_company: 'Assigned by my company',
            title_report: {
              company: `Assigned by the company`,
              user: `Assigned by the user`,
            },
          },
        ],
      },
    ],
  },
  {
    id: 'validationEngine',
    title_to_me: `Validation Engine`,
    title_to_company: `Validation Engine`,
    icon: 'settings',
    iconNameClass: 'sandbox_stroke_all_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_validationEngine',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_validationEngine_requiringValidityVerification',
            title_to_me: 'Requiring validity verification',
            title_to_company: 'Requiring validity verification',
          },
          {
            id: 'request_validationEngine_impactingOther',
            title_to_me: 'Impacting other',
            title_to_company: 'Impacting other',
          },
          {
            id: 'request_validationEngine_outdated',
            title_to_me: 'Outdated',
            title_to_company: 'Outdated',
          },
        ],
      },
      {
        id: 'submittalInput_validationEngine',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_validationEngine_impactingOther',
            title_to_me: 'Impacting other',
            title_to_company: 'Impacting other',
          },
        ],
      },
      {
        id: 'deliverable_validationEngine',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_validationEngine_requiringValidityVerification',
            title_to_me: 'Requiring validity verification',
            title_to_company: 'Requiring validity verification',
          },
          {
            id: 'deliverable_validationEngine_impactingOther',
            title_to_me: 'Impacting other',
            title_to_company: 'Impacting other',
          },
          {
            id: 'deliverable_validationEngine_outdated',
            title_to_me: 'Outdated',
            title_to_company: 'Outdated',
          },
        ],
      },
      {
        id: 'submittalOutput_validationEngine',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_validationEngine_requiringValidityVerification',
            title_to_me: 'Requiring validity verification',
            title_to_company: 'Requiring validity verification',
          },
          {
            id: 'submittalOutput_validationEngine_impactingOther',
            title_to_me: 'Impacting other',
            title_to_company: 'Impacting other',
          },
          {
            id: 'submittalOutput_validationEngine_outdated',
            title_to_me: 'Outdated',
            title_to_company: 'Outdated',
          },
        ],
      },
    ],
  },
  {
    id: 'qualityControl',
    title_to_me: `Quality Control`,
    title_to_company: `Quality Control`,
    icon: 'quality-control',
    iconNameClass: 'sandbox_qualityControl_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_qualityControl',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_qualityControl_incomplete',
            title: 'Incomplete',
          },
          {
            id: 'request_qualityControl_overdueDrafts',
            title: 'Overdue drafts',
          },
          {
            id: 'request_qualityControl_clImpact',
            title: 'CL impact review',
          },
        ],
      },
      {
        id: 'submittalInput_qualityControl',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_qualityControl_incomplete',
            title: 'Incomplete',
          },
          {
            id: 'submittalInput_qualityControl_clImpact',
            title: 'CL impact review',
          },
        ],
      },
      {
        id: 'deliverable_qualityControl',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_qualityControl_incomplete',
            title: 'Incomplete',
          },
        ],
      },
      {
        id: 'submittalOutput_qualityControl',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_qualityControl_incomplete',
            title: 'Incomplete',
          },
          {
            id: 'submittalOutput_qualityControl_clImpact',
            title: 'CL impact review',
          },
        ],
      },
    ],
  },
  {
    id: 'incorporation',
    title_to_me: `Incorporation`,
    title_to_company: `Incorporation`,
    icon: 'check-broken',
    iconNameClass: 'sandbox_incorporation_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_incorporation',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_incorporation_pending',
            title: 'Tasks pending incorporation',
          },
          {
            id: 'request_incorporation_incorporated',
            title: 'To complete incorporation',
          },
          {
            id: 'request_incorporation_notRequiring',
            title: 'Tasks not requiring incorporation',
          },
        ],
      },
    ],
  },
  {
    id: 'starred',
    title_to_me: `Starred`,
    title_to_company: `Starred`,
    icon: 'star',
    iconNameClass: 'sandbox_star_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_starred',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_starred_labeledManually',
            title_to_me: 'Starred',
            title_to_company: 'Starred',
          },
        ],
      },
      {
        id: 'submittalInput_starred',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_starred_labeledManually',
            title_to_me: 'Starred',
            title_to_company: 'Starred',
          },
        ],
      },
      {
        id: 'deliverable_starred',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_starred_labeledManually',
            title_to_me: 'Starred',
            title_to_company: 'Starred',
          },
        ],
      },
      {
        id: 'submittalOutput_starred',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_starred_labeledManually',
            title_to_me: 'Starred',
            title_to_company: 'Starred',
          },
        ],
      },
    ],
  },
  {
    id: 'myItems',
    title_to_me: `My items`,
    title_to_company: `My company's items`,
    title_report: {
      company: `Company's items`,
      user: `User's items`,
    },
    icon: 'flex-columns',
    iconNameClass: 'sandbox_stroke_all_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_myItems',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_myItems_assignedToMe',
            title_to_me: 'Assigned to me',
            title_to_company: 'Assigned to my company',
            title_report: {
              company: `Assigned to the company`,
              user: `Assigned to the user`,
            },
          },
          {
            id: 'request_myItems_myRequest',
            title_to_me: 'My requests',
            title_to_company: `My company's requests`,
            title_report: {
              company: `Company's requests`,
              user: `User's requests`,
            },
          },
          {
            id: 'request_myItems_myWatchlist',
            title_to_me: 'My watchlist',
            title_to_company: `My company's watchlist`,
            title_report: {
              company: `Company's watchlist`,
              user: `User's watchlist`,
            },
          },
          {
            id: 'request_myItems_commentsTo',
            title_to_me: 'Comments to me',
            title_to_company: 'Comments to my company',
            title_report: {
              company: `Comments to company`,
              user: `Comments to user`,
            },
          },

          {
            id: 'request_myItems_commentsBy',
            title_to_me: `My comments`,
            title_to_company: 'My company’s comments',
            title_report: {
              company: `Company's comments`,
              user: `User's comments`,
            },
          },
          {
            id: 'request_myItems_allAnswers',
            title_to_me: `All my answers`,
            title_to_company: `All my company's answers`,
            title_report: {
              company: `Company's answers`,
              user: `User's answers`,
            },
          },
          {
            id: 'request_myItems_allAnswersTo',
            title_to_me: `All answers to me`,
            title_to_company: 'All answers to my company',
            title_report: {
              company: `All answers to company`,
              user: `All answers to user`,
            },
          },
          {
            id: 'request_myItems_createdByMe',
            title_to_me: 'Created by me',
            title_to_company: 'Created by my company',
            title_report: {
              company: `Created by the company`,
              user: `Created by the user`,
            },
          },
          {
            id: 'request_myItems_clashingScheduledDates',
            title: 'Clashing scheduled dates',
          },
          {
            id: 'request_myItems_deactivated',
            title: 'Deactivated',
          },
        ],
      },
      {
        id: 'submittalInput_myItems',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_myItems_myReviews',
            title_to_me: 'My reviews',
            title_to_company: `My company's reviews`,
            title_report: {
              company: `Company's reviews`,
              user: `User's reviews`,
            },
          },
          {
            id: 'submittalInput_myItems_myContractor',
            title_to_me: 'I am responsible contractor',
            title_to_company: `My company is responsible contractor`,
            title_report: {
              company: `Company is responsible contractor`,
              user: `User is responsible contractor`,
            },
          },
          {
            id: 'submittalInput_myItems_myManager',
            title_to_me: 'I am responsible manager',
            title_to_company: 'My company is responsible manager',
            title_report: {
              company: `Company is responsible manager`,
              user: `User is responsible manager`,
            },
          },
          {
            id: 'submittalInput_myItems_myWatchlist',
            title_to_me: 'My watchlist',
            title_to_company: `My company's watchlist`,
            title_report: {
              company: `Company's watchlist`,
              user: `User's watchlist`,
            },
          },
          {
            id: 'submittalInput_myItems_commentsTo',
            title_to_me: 'Comments to me',
            title_to_company: 'Comments to my company',
            title_report: {
              company: `Comments to company`,
              user: `Comments to user`,
            },
          },
          {
            id: 'submittalInput_myItems_commentsBy',
            title_to_me: `My comments`,
            title_to_company: 'My company’s comments',
            title_report: {
              company: `Company's comments`,
              user: `User's comments`,
            },
          },
          {
            id: 'submittalInput_myItems_allAnswers',
            title_to_me: `All my answers`,
            title_to_company: `All my company's answers`,
            title_report: {
              company: `Company's answers`,
              user: `User's answers`,
            },
          },
          {
            id: 'submittalInput_myItems_allAnswersTo',
            title_to_me: `All answers to me`,
            title_to_company: 'All answers to my company',
            title_report: {
              company: `All answers to company`,
              user: `All answers to user`,
            },
          },
          {
            id: 'submittalInput_myItems_createdByMe',
            title_to_me: 'Created by me',
            title_to_company: 'Created by my company',
            title_report: {
              company: `Created by the company`,
              user: `Created by the user`,
            },
          },
          {
            id: 'submittalInput_myItems_deactivated',
            title: 'Deactivated',
          },
        ],
      },
      {
        id: 'deliverable_myItems',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_myItems_myReviews',
            title_to_me: 'My reviews',
            title_to_company: `My company's reviews`,
            title_report: {
              company: `Company's reviews`,
              user: `User's reviews`,
            },
          },
          {
            id: 'deliverable_myItems_myResponsibilities',
            title_to_me: 'My responsibilities',
            title_to_company: `My company's responsibilities`,
            title_report: {
              company: `Company's responsibilities`,
              user: `User's responsibilities`,
            },
          },
          {
            id: 'deliverable_myItems_myWatchlist',
            title_to_me: 'My watchlist',
            title_to_company: `My company's watchlist`,
            title_report: {
              company: `Company's watchlist`,
              user: `User's watchlist`,
            },
          },
          {
            id: 'deliverable_myItems_commentsTo',
            title_to_me: 'Comments to me',
            title_to_company: 'Comments to my company',
            title_report: {
              company: `Comments to company`,
              user: `Comments to user`,
            },
          },
          {
            id: 'deliverable_myItems_allAnswers',
            title_to_me: `All my answers`,
            title_to_company: `All my company's answers`,
            title_report: {
              company: `Company's answers`,
              user: `User's answers`,
            },
          },
          {
            id: 'deliverable_myItems_allAnswersTo',
            title_to_me: `All answers to me`,
            title_to_company: 'All answers to my company',
            title_report: {
              company: `All answers to company`,
              user: `All answers to user`,
            },
          },
          {
            id: 'deliverable_myItems_commentsBy',
            title_to_me: `My comments`,
            title_to_company: `My company's comments`,
            title_report: {
              company: `Company's comments`,
              user: `User's comments`,
            },
          },
          {
            id: 'deliverable_myItems_clashingDueDates',
            title: 'Clashing due dates',
          },
        ],
      },
      {
        id: 'submittalOutput_myItems',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_myItems_myReviews',
            title_to_me: 'My reviews',
            title_to_company: `My company's reviews`,
            title_report: {
              company: `Company's reviews`,
              user: `User's reviews`,
            },
          },
          {
            id: 'submittalOutput_myItems_myContractor',
            title_to_me: 'I am responsible contractor',
            title_to_company: `My company is responsible contractor`,
            title_report: {
              company: `Company is responsible contractor`,
              user: `User is responsible contractor`,
            },
          },
          {
            id: 'submittalOutput_myItems_myManager',
            title_to_me: 'I am responsible manager',
            title_to_company: 'My company is responsible manager',
            title_report: {
              company: `Company is responsible manager`,
              user: `User is responsible manager`,
            },
          },
          {
            id: 'submittalOutput_myItems_myWatchlist',
            title_to_me: 'My watchlist',
            title_to_company: `My company's watchlist`,
            title_report: {
              company: `Company's watchlist`,
              user: `User's watchlist`,
            },
          },
          {
            id: 'submittalOutput_myItems_commentsTo',
            title_to_me: 'Comments to me',
            title_to_company: 'Comments to my company',
            title_report: {
              company: `Comments to company`,
              user: `Comments to user`,
            },
          },
          {
            id: 'submittalOutput_myItems_commentsBy',
            title_to_me: `My comments`,
            title_to_company: 'My company’s comments',
            title_report: {
              company: `Company's comments`,
              user: `User's comments`,
            },
          },
          {
            id: 'submittalOutput_myItems_allAnswers',
            title_to_me: `All my answers`,
            title_to_company: `All my company's answers`,
            title_report: {
              company: `Company's answers`,
              user: `User's answers`,
            },
          },
          {
            id: 'submittalOutput_myItems_allAnswersTo',
            title_to_me: `All answers to me`,
            title_to_company: 'All answers to my company',
            title_report: {
              company: `All answers to company`,
              user: `All answers to user`,
            },
          },
          {
            id: 'submittalOutput_myItems_createdByMe',
            title_to_me: `Created by me`,
            title_to_company: 'Created by my company',
            title_report: {
              company: `Created by company`,
              user: `Created by user`,
            },
          },
          {
            id: 'submittalOutput_myItems_deactivated',
            title: 'Deactivated',
          },
        ],
      },
    ],
  },
  {
    id: 'newUpdated',
    title_to_me: `Updates`,
    title_to_company: `Updates`,
    icon: 'updates',
    iconNameClass: 'sandbox_stroke_all_icon',
    typeSystemButton: 'chevron-right',
    sections: [
      {
        id: 'request_newUpdated',
        title: 'Need Forms',
        subSections: [
          {
            id: 'request_newUpdated_newItems',
            title: 'New items',
          },
          {
            id: 'request_newUpdated_addedFiles',
            title: 'Added files',
          },
          {
            id: 'request_newUpdated_statusChange',
            title: 'Status change',
          },
          {
            id: 'request_newUpdated_updatedFields',
            title: 'Updated fields',
          },
        ],
      },
      {
        id: 'submittalInput_newUpdated',
        title: 'Input submittals',
        subSections: [
          {
            id: 'submittalInput_newUpdated_reviewResponse',
            title_to_me: 'Review response saved',
            title_to_company: `Review response saved`,
          },
          {
            id: 'submittalInput_newUpdated_newSubmittal',
            title_to_me: 'Newly submitted',
            title_to_company: `Newly submitted`,
          },
          {
            id: 'submittalInput_newUpdated_addedFiles',
            title_to_me: 'Files added',
            title_to_company: 'Files added',
          },
          {
            id: 'submittalInput_newUpdated_statusChange',
            title_to_me: 'Status changed',
            title_to_company: 'Status changed',
          },
          {
            id: 'submittalInput_newUpdated_updatedFields',
            title_to_me: 'Fields updated',
            title_to_company: 'Fields updated',
          },
        ],
      },
      {
        id: 'deliverable_newUpdated',
        title: 'CL drawings',
        subSections: [
          {
            id: 'deliverable_newUpdated_reviewChange',
            title: 'Review updated',
          },
          {
            id: 'deliverable_newUpdated_newIssuedDeliverables',
            title: 'Newly issued',
          },
          {
            id: 'deliverable_newUpdated_addedFiles',
            title: 'Added files',
          },
          {
            id: 'deliverable_newUpdated_statusChange',
            title: 'Status changed',
          },
          {
            id: 'deliverable_newUpdated_updatedFields',
            title: 'Updated fields',
          },
        ],
      },
      {
        id: 'submittalOutput_newUpdated',
        title: 'Output submittals',
        subSections: [
          {
            id: 'submittalOutput_newUpdated_reviewResponse',
            title_to_me: 'Review response saved',
            title_to_company: `Review response saved`,
          },
          {
            id: 'submittalOutput_newUpdated_newSubmittal',
            title_to_me: 'Newly submitted',
            title_to_company: `Newly submitted`,
          },
          {
            id: 'submittalOutput_newUpdated_addedFiles',
            title_to_me: 'Files added',
            title_to_company: 'Files added',
          },
          {
            id: 'submittalOutput_newUpdated_statusChange',
            title_to_me: 'Status changed',
            title_to_company: 'Status changed',
          },
          {
            id: 'submittalOutput_newUpdated_updatedFields',
            title_to_me: 'Fields updated',
            title_to_company: 'Fields updated',
          },
        ],
      },
    ],
  },
  {
    id: 'customSearch',
    title_to_me: `Search results`,
    title_to_company: `Search results`,
    icon: 'search-results',
    iconNameClass: 'sandbox_stroke_all_icon',
    typeSystemButton: 'chevron-right',
    isHideSection: true,
    sections: [
      {
        id: `request_custom`,
        title: 'Need Forms',
        subSections: [
          {
            id: `request_custom_search`,
            title: 'Search results',
          },
        ],
      },
      {
        id: `submittalInput_custom`,
        title: 'Input submittals',
        subSections: [
          {
            id: `submittalInput_custom_search`,
            title: 'Search results',
          },
        ],
      },
      {
        id: `deliverable_custom`,
        title: 'CL drawings',
        subSections: [
          {
            id: `deliverable_custom_search`,
            title: 'Search results',
          },
        ],
      },
      {
        id: `submittalOutput_custom`,
        title: 'Output submittals',
        subSections: [
          {
            id: `submittalOutput_custom_search`,
            title: 'Search results',
          },
        ],
      },
    ],
  },
  {
    id: 'heatmap',
    title_to_me: `Heatmap`,
    title_to_company: `Heatmap`,
    icon: 'search-results',
    iconNameClass: 'sandbox_stroke_all_icon',
    typeSystemButton: 'chevron-right',
    isHideSection: true,
    sections: [
      {
        id: `request_custom_heatmap`,
        title: 'Need Forms',
        subSections: [
          {
            id: `request_custom_heatmap`,
            title: 'Heatmap',
          },
        ],
      },
      {
        id: `submittalInput_custom_heatmap`,
        title: 'Input submittals',
        subSections: [
          {
            id: `submittalInput_custom_heatmap`,
            title: 'Heatmap',
          },
        ],
      },
      {
        id: `deliverable_custom_heatmap`,
        title: 'CL drawings',
        subSections: [
          {
            id: `deliverable_custom_heatmap`,
            title: 'Heatmap',
          },
        ],
      },
      {
        id: `submittalOutput_custom_heatmap`,
        title: 'Output submittals',
        subSections: [
          {
            id: `submittalOutput_custom_heatmap`,
            title: 'Heatmap',
          },
        ],
      },
    ],
  },
];
