import React from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import ChipsStandardTag from '../../../../controls/Chips/ChipsStandardTag/ChipsStandardTag';

import './DeliverablesItemStyles.scss';

interface IProps {
  deliverable: PCDApi.PCDListDrawingInterface;
  handleDeliverablesItemClick?: (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => void;
  isActiveCheckBoxForDisabledItems?: boolean;
}

const DeliverablesItem: React.FC<IProps> = ({ deliverable, handleDeliverablesItemClick, isActiveCheckBoxForDisabledItems }) => {
  const themeClass = useGetThemeClass('b-deliverablesItem');

  return (
    <>
      <div className={`${themeClass}_item`} onClick={e => handleDeliverablesItemClick(e, deliverable)}>
        <CheckBox checked={deliverable.selected || isActiveCheckBoxForDisabledItems} />
        <ChipsStandardTag value={deliverable.title} isAlignTextLeft />
      </div>
    </>
  );
};

export default DeliverablesItem;
