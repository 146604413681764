import React, { useState } from 'react';
import TokenIcon from '../TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../helpers/designTokens';
import DropdownSelectMenu from '../../controls/Dropdowns/DropdownPopovers/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownMenuItem } from '../../controls/Dropdowns/DropdownParts';
import cn from 'classnames';

import './BreadcrumbsStyles.module.scss';

interface IBreadcrumbsProps<T> {
  breadcrumbs: T[] | null;
  isCardPopUp?: boolean;
  isPcdCardPopUp?: boolean;
  isBackToCurrentRevision?: boolean;
  breadcrumbOnClick: (breadcrumb: T) => void;
  handleBackToCurrentOnClick?: () => void;
  isBreadcrumbLoading?: boolean;
  selectedBreadcrumb?: T;
  isSidePeek?: boolean;
  isMobile?: boolean;
  isTruncated?: boolean;
  truncateAfterNumber?: number;
}

function Breadcrumbs<T extends { id: string | number }>({
  breadcrumbs,
  isPcdCardPopUp,
  isBackToCurrentRevision,
  breadcrumbOnClick,
  handleBackToCurrentOnClick,
  isBreadcrumbLoading,
  selectedBreadcrumb,
  isSidePeek,
  isMobile,
  isTruncated,
  truncateAfterNumber,
}: IBreadcrumbsProps<T>) {
  const themeClass = useGetThemeClass('breadcrumbs');

  const [isBreadcrumbOpen, setIsBreadcrumbOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);

  const getBreadcrumbTitle = breadcrumb => {
    return breadcrumb.title || breadcrumb.code;
  };

  const handleBreadcrumbOnClick = breadcrumb => {
    breadcrumbOnClick(breadcrumb);
  };

  const handleBreadcrumbSelect = value => {
    const getBreadcrumb = breadcrumbs.find(item => item.id === value);
    handleBreadcrumbOnClick(getBreadcrumb);
    closeBreadcrumb();
  };

  const renderMenuItem = (breadcrumb: T) => {
    const isActive = breadcrumb.id === selectedBreadcrumb?.id && !isBreadcrumbLoading;
    return (
      <DropdownMenuItem
        isMulti={false}
        size={'md'}
        title={getBreadcrumbTitle(breadcrumb)}
        value={breadcrumb.id}
        onItemClick={handleBreadcrumbSelect}
        isSelected={isActive}
        customClassName={'breadcrumbClassName'}
        isMobile={isMobile}
      />
    );
  };

  const openBreadcrumb = e => {
    setAnchorEl(e.currentTarget);
    setIsBreadcrumbOpen(true);
  };

  const closeBreadcrumb = () => {
    setAnchorEl(null);
    setIsBreadcrumbOpen(false);
  };

  const getBreadcrumbsTruncated = (breadcrumbs: T[]) => {
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    const isActiveLast = lastBreadcrumb.id === selectedBreadcrumb?.id && !isBreadcrumbLoading;
    const getBreadcrumbs = breadcrumbs.filter((item, index) => index < breadcrumbs.length - 1);
    const selectBreadcrumbList = getBreadcrumbs.find(item => item.id === selectedBreadcrumb?.id);
    return (
      <>
        <div className={`${themeClass}__item -short`}>
          <div
            className={cn(`${themeClass}__breadcrumbsTruncated`, {
              ['-active']: isBreadcrumbOpen,
              ['-selectBreadcrumb']: selectBreadcrumbList,
            })}
            onClick={e => openBreadcrumb(e)}
          >
            ...
          </div>
          <DropdownSelectMenu
            isMenuOpen={isBreadcrumbOpen}
            renderItemHeader={null}
            renderItemFooter={null}
            renderMenuItem={renderMenuItem}
            menuItems={getBreadcrumbs}
            size={'md'}
            anchorEl={anchorEl}
            onClose={closeBreadcrumb}
            isMobile={isMobile}
          />
        </div>
        <div
          className={cn(`${themeClass}__item `, {
            ['-active']: isActiveLast,
            ['-disabled']: isBreadcrumbLoading,
            ['-mobile']: isMobile,
            ['-sidePeek']: isSidePeek,
          })}
          onClick={() => handleBreadcrumbOnClick(lastBreadcrumb)}
        >
          <div className={cn(`${themeClass}__arrow`)}>
            <TokenIcon iconName={'chevron-right'} size={isMobile ? 16 : 12} />
          </div>
          <span>{getBreadcrumbTitle(lastBreadcrumb) || ''}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${themeClass}`}>
        {breadcrumbs.length ? (
          <>
            {isTruncated && breadcrumbs.length > (truncateAfterNumber || 3)
              ? getBreadcrumbsTruncated(breadcrumbs)
              : breadcrumbs.map((breadcrumb, index) => {
                  const isActive = breadcrumb.id === selectedBreadcrumb?.id && !isBreadcrumbLoading;
                  return (
                    <div
                      key={breadcrumb.id}
                      className={cn(`${themeClass}__item `, {
                        ['-active']: isActive,
                        ['-disabled']: isBreadcrumbLoading,
                        ['-mobile']: isMobile,
                        ['-sidePeek']: isSidePeek,
                      })}
                      onClick={() => handleBreadcrumbOnClick(breadcrumb)}
                    >
                      {!!index && (
                        <div className={cn(`${themeClass}__arrow`)}>
                          <TokenIcon iconName={'chevron-right'} size={isMobile ? 16 : 12} />
                        </div>
                      )}
                      <span>{getBreadcrumbTitle(breadcrumb) || ''}</span>
                    </div>
                  );
                })}
          </>
        ) : (
          <>
            {isPcdCardPopUp && isBackToCurrentRevision && (
              <div className={`${themeClass}__item`} onClick={handleBackToCurrentOnClick}>
                <span className={`${themeClass}__arrow`}>
                  <TokenIcon iconName={'chevron-left'} size={isMobile ? 16 : 12} />
                </span>
                <span>Back to current revision</span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Breadcrumbs;
