import React from 'react';
import Popover from '@material-ui/core/Popover';
import cn from 'classnames';
import { useGetThemeClass } from '../../../helpers/designTokens';

import './PopupMoreLimitFilesStyles.module.scss';

interface IProps {
  isOpenMoreLimitFiles: boolean;
  anchorEl: any;
  handleCloseMoreLimitFiles: () => void;
  customClassName?: string;
}

export const PopupMoreLimitFiles = ({ isOpenMoreLimitFiles, anchorEl, handleCloseMoreLimitFiles, customClassName }: IProps) => {
  const themeClass = useGetThemeClass('b-popupMoreLimitFiles');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');

  return (
    <Popover
      id={'setDate'}
      open={isOpenMoreLimitFiles}
      anchorEl={anchorEl}
      onClose={handleCloseMoreLimitFiles}
      classes={{
        paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClass}_moreLimitFilePaper`, {
          [`${customClassName}`]: customClassName,
        }),
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={`${themeClass}_moreLimitFile ${themeClass}_moreLimitFile_title`}>
        The file you are trying to upload exceeds the maximum size limit (200 MB)
      </div>
    </Popover>
  );
};
