import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import MixpanelHelper from './service/Mixpanel/Mixpanel';
import { isLoggedIn } from './service/auth';
import { getClientFromProject, logout, userInfo } from './store/user/userThunk';
import { useAppDispatch, useAppSelector } from './store/configure/configureStore';
import useRouter from './hooks/useRouter/useRouter';
import publicIp from 'public-ip';
import { getIPv4, setActiveProject } from './store/user/userActions';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { useGetThemeClass } from './helpers/designTokens';
import { initTheme } from './themes/initTheme';
import { isEmptyObject } from './helpers/commonHelpers';
import { GetResolution } from './helpers/ScreenResolution/GetResolution';
import useRoutingHelper from './hooks/useRoutingHelper/useRoutingHelper';
import { changeActiveProject } from './store/request/requestActions';
import { getProjectDetails, getProjectRefreshes } from './store/project/projectLogic';
import { getProjectById } from './store/phasePlan/phasePlanThunk';
import { snackBarSockets } from './components/common/SnackBarSockets/SnackBarSockets';
import PdfTronOptimized from './components/common/PdfTronOptimized/PdfTronOptimized';
import { settingsSlice } from './store/globalSettings/settingsSlice';
import { StylesProvider } from '@material-ui/styles';
import { createGenerateClassName } from '@material-ui/core';
import SocketComponent from './components/common/SocketControlComponent/SocketComponent';
import IndexedDbComponent from './components/common/IndexedDbComponent/IndexedDbComponent';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './helpers/quillConfig';

const { setKpiFrame, setOnlyAuth, setTheme, setIsInit } = settingsSlice.actions;

const styles = {
  snack: {
    position: 'absolute',
    height: 50,
    bottom: 70,
    left: 10,
    backgroundColor: 'red',
  },
};

interface IProps extends InjectedNotistackProps {
  children: ReactNode;
}

const App: FC<IProps> = ({ enqueueSnackbar, children }) => {
  const dispatch = useAppDispatch();
  const { isMobile } = GetResolution();
  const { location, navigate, searchParams, urlProject } = useRouter();
  const { setUrlChangeCompany } = useRoutingHelper();
  const themeClass = useGetThemeClass('b-scroll');

  const user = useAppSelector(state => state.userReducer);
  const overSizeFile = useAppSelector(state => state.settingsReducer.overSizeFile);
  const theme = useAppSelector(state => state.settingsReducer.theme);
  const isAppInit = useAppSelector(state => state.settingsReducer.isInit);
  const activeProjectId = useAppSelector(state => state.userReducer.active_project_id);
  const membersLoading = useAppSelector(state => state.team.members.isLoading);
  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo);
  const projectInfoLoading = useAppSelector(state => state.projectReducer.projectInfoLoading);

  // Change this value to true for enable plug view
  const isPlugView = false;

  const onlyAuth = useMemo(() => {
    const isIframe = window.self !== window.parent;
    const onlyAuth = searchParams.get('onlyauth');
    return isIframe && !!onlyAuth ? onlyAuth : false;
  }, [searchParams]);

  const kpiFrame = useMemo(() => {
    const isIframe = window.self !== window.parent;
    const isKpiFrame = searchParams.get('kpiframe');
    return isIframe && !!isKpiFrame ? isKpiFrame : false;
  }, [searchParams]);

  const isPdftronEnabled = useMemo(() => {
    return !(kpiFrame || onlyAuth);
  }, [kpiFrame, onlyAuth]);

  const isProjectExistInClients: boolean = useMemo(() => {
    return user?.userInfo?.projects?.some(project => project.code === urlProject[0]) || false;
  }, [user, urlProject]);

  const isProjectInfoEmpty = useMemo(() => {
    const notEmptyArray = Object.values(projectInfo).filter(item => {
      return !isEmptyObject(item);
    });
    return isEmptyObject(notEmptyArray);
  }, [projectInfo]);

  useEffect(() => {
    MixpanelHelper.init();
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(userInfo());
    }
  }, []);

  useEffect(() => {
    if (!onlyAuth) {
      if (isLoggedIn() && location.pathname === '/') {
        sessionStorage.removeItem('project_name');
      }

      if (isLoggedIn() && location.pathname.includes('/invite')) {
        dispatch(
          logout({
            callback: () => {
              navigate('/');
            },
          }),
        );
      }

      if (isLoggedIn() && !membersLoading) {
        initProjectUrl();
      }
    }
  }, []);

  useEffect(() => {
    if (isPlugView) {
      handlePublicIp();
    }
  }, [isPlugView]);

  useEffect(() => {
    bootstrapApp(onlyAuth, kpiFrame);
  }, []);

  useEffect(() => {
    if (
      isLoggedIn() &&
      urlProject[0] &&
      urlProject[0] !== 'change-company' &&
      urlProject[0] !== 'change-project' &&
      urlProject[0] !== 'no-projects-yet' &&
      !activeProjectId &&
      !user.isGetClientFromProject &&
      user
    ) {
      if (isProjectExistInClients) {
        dispatch(getClientFromProject({ code: urlProject[0] }));
      } else if (user.userInfo?.projects?.length && user.userInfo?.projects[0]?.code) {
        dispatch(getClientFromProject({ code: user?.userInfo?.projects[0]?.code }));
      }
    }
  }, [user, activeProjectId, urlProject]);

  useEffect(() => {
    if (
      isLoggedIn() &&
      urlProject[0] &&
      urlProject[0] === 'no-projects-yet' &&
      !activeProjectId &&
      !user.isGetClientFromProject &&
      user &&
      !user?.userInfo?.clients?.length
    ) {
      setUrlChangeCompany();
    }
  }, [user, activeProjectId, urlProject]);

  useEffect(() => {
    if (urlProject[1] !== 'project-settings' && isLoggedIn() && !onlyAuth) {
      const active_project_id = sessionStorage.getItem('active_project_id');
      const project_name = sessionStorage.getItem('project_name');
      if (urlProject[0] !== project_name) {
        initProjectUrl();
      }
      if (!active_project_id || !project_name) {
        initProjectUrl();
      }
    }
  }, [urlProject, onlyAuth]);

  useEffect(() => {
    if (projectInfoLoading.fail) {
      const currentProjectId = user?.clientProjects?.[0]?.id;

      if (currentProjectId) {
        dispatch(setActiveProject(currentProjectId));
        dispatch(changeActiveProject(currentProjectId));
      }
    }
  }, [projectInfoLoading]);

  useEffect(() => {
    if (activeProjectId) {
      dispatch(getProjectRefreshes(activeProjectId));
    }
  }, [activeProjectId]);

  useEffect(() => {
    if ((activeProjectId && !projectInfoLoading.loading) || (activeProjectId && isProjectInfoEmpty && projectInfoLoading.fail)) {
      dispatch(getProjectDetails(activeProjectId));
      dispatch(getProjectById(activeProjectId));
    }
  }, [activeProjectId, isProjectInfoEmpty]);

  useEffect(() => {
    if (isLoggedIn() && !onlyAuth) {
      initThemStyles();
      dispatch(setTheme(user?.userInfo?.theme));
    }
  }, [user?.userInfo]);

  useEffect(() => {
    if (overSizeFile) {
      snackBarSockets('The file must be less than 100MB', 'red', enqueueSnackbar);
    }
  }, [overSizeFile]);

  useEffect(() => {
    initThemStyles();
  }, [theme]);

  const handlePublicIp = async () => {
    const publicIP = await publicIp.v4();
    console.log('publicIP', publicIP);
    dispatch(getIPv4(publicIP));
  };

  const bootstrapApp = (onlyAuth, kpiFrame) => {
    initThemStyles();

    dispatch(setIsInit(true));
    if (onlyAuth) {
      dispatch(setOnlyAuth(onlyAuth));
    }
    if (kpiFrame) {
      dispatch(setKpiFrame(kpiFrame));
    }
  };

  const initThemStyles = () => {
    const currentTheme = user.userInfo?.theme || localStorage.getItem('theme') || 'light';

    if (currentTheme === 'dark') {
      document.body.classList.remove('b-scroll-light');
    }

    if (currentTheme === 'light') {
      document.body.classList.remove('b-scroll-dark');
    }

    document.body.classList.add(`${themeClass}`);

    initTheme(theme);
  };

  const initProjectUrl = () => {
    const projects = [...user.userInfo.projects];
    const projects_user = user?.clientProjects;
    let projectName = '';
    let isRenameProjectName = false;
    const urlProjectCount = urlProject.length;
    if (!projectName) {
      if (urlProject[0] === 'no-projects-yet') {
        projectName = 'no-projects-yet';
      } else if (urlProject[0] === 'change-company') {
        projectName = 'change-company';
      } else if (urlProject[0] === 'change-project') {
        projectName = 'change-project';
      } else {
        projectName = projects.find(p => p.code === urlProject[0])?.code;
      }
      isRenameProjectName = false;
    }
    if (!projectName) {
      if (projects_user?.[0]?.id === 0) {
        projectName = 'no-projects-yet';
      } else {
        projectName = projects_user?.[0]?.code;
      }
      isRenameProjectName = true;
    }
    if (!projectName) {
      if (urlProject[0] === 'unsubscribe') {
        projectName = 'unsubscribe';
        const hash_from_url = searchParams.get('user');
        if (!hash_from_url) {
          projectName = 'no-projects-yet';
          isRenameProjectName = true;
        } else {
          isRenameProjectName = false;
        }
      }
    }
    if (!projectName) {
      return;
    }
    if (urlProject[1] !== 'project-settings') {
      urlProject[0] = projectName;
      // If no Access to project redirect to command-center
      if (urlProject[1] === 'card') {
        urlProject[1] = 'command-center';
      }
    } else {
      urlProject[0] = projectName;
      urlProject[1] = 'needs-list';
    }
    if (isRenameProjectName) {
      sessionStorage.setItem('project_name', urlProject[0]);
      const resultUrl = Array.from(searchParams.entries())
        .filter(([key]) => key.includes(`_hash`) || key.includes('default_filter') || key.includes('activeTab'))
        .map(([key, value]) => `${key}=${value}`);

      navigate({
        pathname: `/${urlProject.join('/')}${urlProjectCount == 0 ? (isMobile ? '/command-center' : '/projects') : ''}`,
        search: `${resultUrl.join('&')}`,
      });
    }
  };

  if (!isAppInit) {
    return null;
  }

  const generateClassName = createGenerateClassName({
    productionPrefix: 'c',
    disableGlobal: true,
  });

  return (
    <div>
      <StylesProvider generateClassName={generateClassName}>{children}</StylesProvider>
      {isPdftronEnabled ? <PdfTronOptimized /> : null}
      <SocketComponent />
      <IndexedDbComponent />
    </div>
  );
};

export default withSnackbar(withStyles(styles)(App));
