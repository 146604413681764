export type iconsTextsColorConfig = {
  alias: string;
  component: 'Status' | 'ChipsHistory';
  icon_type: string;
  icon_color?: string;
  text_color: string;
};

export const getIconsTextsColor = (type: `request` | `submittal`): iconsTextsColorConfig[] => {
  return [
    {
      alias: `${type}_drafted`,
      component: 'Status',
      icon_type: 'drafted',
      text_color: `var(--color-secondary)`,
    },
    {
      alias: `${type}_sent`,
      component: 'Status',
      icon_type: 'sent',
      text_color: `#7594D6`,
    },
    {
      alias: `${type}_view`,
      component: 'ChipsHistory',
      icon_type: 'check-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },

    {
      alias: `${type}_deleted`,
      component: 'ChipsHistory',
      icon_type: 'trash',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_setPrivate`,
      component: 'ChipsHistory',
      icon_type: 'privat',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_setPublic`,
      component: 'ChipsHistory',
      icon_type: 'public',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_addedToWatchListGroup`,
      component: 'ChipsHistory',
      icon_type: 'watchlist',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_addedToWatchList`,
      component: 'ChipsHistory',
      icon_type: 'watchlist',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_removedFromWatchList`,
      component: 'ChipsHistory',
      icon_type: 'watchlist',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedType`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedLodMilestone`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedDueDate`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_dueDateChangeRequested`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-warning',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_dueDateChangeAccepted`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-success',
      text_color: `#66C17F`,
    },
    {
      alias: `${type}_dueDateChangeDeclined`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-danger',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}DueDateAlert`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-danger',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}_requestOverdue`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-danger',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}_editedSubject`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedRequestManager`,
      component: 'ChipsHistory',
      icon_type: 'user-check',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },

    {
      alias: `${type}_editedRequestCompany`,
      component: 'ChipsHistory',
      icon_type: 'company',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedRequestDiscipline`,
      component: 'ChipsHistory',
      icon_type: 'discipline',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedResponseManager`,
      component: 'ChipsHistory',
      icon_type: 'user-check',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedResponseCompany`,
      component: 'ChipsHistory',
      icon_type: 'company',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedResponseDiscipline`,
      component: 'ChipsHistory',
      icon_type: 'discipline',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_responseCompanyChangeRequested`,
      component: 'ChipsHistory',
      icon_type: 'user-right',
      icon_color: 'sys-warning',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_responseCompanyChangeAccepted`,
      component: 'ChipsHistory',
      icon_type: 'user-right',
      icon_color: 'sys-success',
      text_color: `#66C17F`,
    },
    {
      alias: `${type}_responseCompanyChangeDeclined`,
      component: 'ChipsHistory',
      icon_type: 'user-right',
      icon_color: 'sys-danger',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}Edited_building`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_buildingAdd`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_buildingRemove`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_levels`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_levelsAdd`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_levelsRemove`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}Edited_buildingSpaces`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_buildingSpacesAdd`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_buildingSpacesRemove`,
      component: 'ChipsHistory',
      icon_type: 'building-lod',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_document`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_documentAdd`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_documentRemove`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_deliverable`,
      component: 'ChipsHistory',
      icon_type: 'deliverables',
      icon_color: 'primary',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_deliverableAdd`,
      component: 'ChipsHistory',
      icon_type: 'link',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_deliverableRemove`,
      component: 'ChipsHistory',
      icon_type: 'link-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_hashtag`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_hashtagAdd`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_hashtagDeleted`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_hashtagEdited`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_hashtagRemove`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_editedDescription`,
      component: 'ChipsHistory',
      icon_type: 'for-record',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_newCommitSubmittalResolution`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_pulledFromNF`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `file_request_upload`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'on-bgd-srf-2',
      text_color: `#7594D6`,
    },
    {
      alias: `file_request_delete`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'on-bgd-srf-2',
      text_color: `#7594D6`,
    },
    {
      alias: `file_request_markedUp`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'on-bgd-srf-2',
      text_color: `#7594D6`,
    },
    {
      alias: `newDeliverable`,
      component: 'Status',
      icon_type: 'provided',
      text_color: `var(--color)`,
    },
    {
      alias: `newDeliverable_file`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `file_request_reuseTron`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'sys-informative',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_linkedPredecessor`,
      component: 'ChipsHistory',
      icon_type: 'link',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_linkedSuccessor`,
      component: 'ChipsHistory',
      icon_type: 'link',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_unlinkedPredecessor`,
      component: 'ChipsHistory',
      icon_type: 'link-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_unlinkedSuccessor`,
      component: 'ChipsHistory',
      icon_type: 'link-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_submittalAdd`,
      component: 'ChipsHistory',
      icon_type: 'link',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_submittalRemove`,
      component: 'ChipsHistory',
      icon_type: 'link-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `pcdEdited_addedComment`,
      component: 'ChipsHistory',
      icon_type: 'comment-2',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_BallInCourtAdded`,
      component: 'ChipsHistory',
      icon_type: 'bic',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_deactivated`,
      component: 'ChipsHistory',
      icon_type: 'deactivate',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_activated`,
      component: 'ChipsHistory',
      icon_type: 'activate',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },

    {
      alias: `${type}_pullRequestCreated`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_linkedPullRequest`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_responseProvided`,
      component: 'Status',
      icon_type: 'provided',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_responseDeclined`,
      component: 'Status',
      icon_type: 'declined',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}_responseAccepted`,
      component: 'Status',
      icon_type: 'accepted',
      text_color: `#66C17F`,
    },
    {
      alias: `${type}_UFAdd`,
      component: 'ChipsHistory',
      icon_type: 'uf-mf',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_UFRemove`,
      component: 'ChipsHistory',
      icon_type: 'uf-mf',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_MFAdd`,
      component: 'ChipsHistory',
      icon_type: 'uf-mf',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_MFRemove`,
      component: 'ChipsHistory',
      icon_type: 'uf-mf',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `file_request_responseUpload`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'sys-informative',
      text_color: `var(--color)`,
    },
    {
      alias: `file_request_responseDelete`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'sys-informative',
      text_color: `var(--color)`,
    },
    {
      alias: `file_request_responseMarkedUp`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'sys-informative',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_reuseFileTron`,
      component: 'ChipsHistory',
      icon_type: 'attachment',
      icon_color: 'sys-informative',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_incorporation`,
      component: 'Status',
      icon_type: 'drafted',
      text_color: `var(--color-secondary)`,
    },
    {
      alias: `${type}IncorporationAll`,
      component: 'Status',
      icon_type: 'drafted',
      text_color: `var(--color-secondary)`,
    },
    {
      alias: `${type}IncorporationCleared`,
      component: 'Status',
      icon_type: 'drafted',
      text_color: `var(--color-secondary)`,
    },
    {
      alias: `${type}_commentAdded`,
      component: 'ChipsHistory',
      icon_type: 'comment-2',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_commentReply`,
      component: 'ChipsHistory',
      icon_type: 'comment-2',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_commentDelete`,
      component: 'ChipsHistory',
      icon_type: 'trash',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },

    {
      alias: `${type}_newCommit`,
      component: 'Status',
      icon_type: 'submitted',
      text_color: `#F3A359`,
    },
    {
      alias: `${type}_newCommitDueDateChangeAccepted`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-success',
      text_color: `#66C17F`,
    },
    {
      alias: `${type}_newCommitDueDateChangeRequested`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-warning',
      text_color: `#F3A359`,
    },
    {
      alias: `${type}_newCommitDueDateChangeDeclined`,
      component: 'ChipsHistory',
      icon_type: 'calendar',
      icon_color: 'sys-danger',
      text_color: `#ED5147`,
    },
    {
      alias: `${type}_newCommitPullRequest`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `#F3A359`,
    },
    {
      alias: `newCommitPullRequestCreated`,
      component: 'ChipsHistory',
      icon_type: 'pull',
      icon_color: 'on-bgd-srf-2',
      text_color: `#ED5147`,
    },
    {
      alias: `newRequestComment`,
      component: 'ChipsHistory',
      icon_type: 'comment-2',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `nfUnlinked`,
      component: 'ChipsHistory',
      icon_type: 'link-broken',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_applyCourt`,
      component: 'ChipsHistory',
      icon_type: 'bic',
      icon_color: 'primary',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_deactivatedSecondaryCourt`,
      component: 'ChipsHistory',
      icon_type: 'bic',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_replyCourt`,
      component: 'ChipsHistory',
      icon_type: 'bic',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
    {
      alias: `${type}_unassignedCourts`,
      component: 'ChipsHistory',
      icon_type: 'bic',
      icon_color: 'on-bgd-srf-2',
      text_color: `var(--color)`,
    },
  ];
};
