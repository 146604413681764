import Api from '../../service/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryReducer, IHistoryReducerHistoryContent } from './historyReducer.model';
import { getHistory } from './historyThunk';
import { GetHistoryPayloadModel, GetHistoryResponseModel } from '../../models/history/history.model';

const initialHistoryObject: IHistoryReducerHistoryContent = {
  data: [],
  current_page: 0,
  first_page_url: '',
  from: 0,
  last_page: 0,
  last_page_url: '',
  next_page_url: '',
  path: '',
  per_page: 0,
  prev_page_url: '',
  to: 0,
  total: 0,
};

const initialState: IHistoryReducer = {
  getHistoryStatus: Api.initialStatus,
  history: {
    deliverable: initialHistoryObject,
    request: initialHistoryObject,
  },
  isNewRequest: false,
};

export const incorporationSlice = createSlice({
  initialState,
  name: 'historyReducer',
  reducers: {
    clearHistory(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getHistory
       */
      .addCase(getHistory.pending, (state, { meta }: PayloadAction<null, string, { arg: GetHistoryPayloadModel }>) => {
        const type = meta.arg.type || 'request';
        let history = state?.history?.[type].data?.length ? [...state?.history?.[type].data] : [];
        if (!meta.arg.page || meta.arg.page === 1 || meta.arg.isNew) {
          history = [];
        }

        state.getHistoryStatus = Api.requestStatus;
        state.isNewRequest = !!meta.arg.isNew;
        state.history = {
          ...state.history,
          [type]: {
            ...state.history[type],
            data: history,
          },
        };
      })
      .addCase(getHistory.fulfilled, (state, { payload }: PayloadAction<GetHistoryResponseModel>) => {
        const history =
          payload.data.current_page > 1 ? [...state.history[payload.type || 'request'].data, ...payload.data.data] : payload.data.data;

        state.getHistoryStatus = Api.successStatus;
        state.isNewRequest = false;
        state.history = {
          ...state.history,
          [payload.type || 'request']: {
            ...payload.data,
            data: history,
          },
        };
      })
      .addCase(getHistory.rejected, state => {
        state.getHistoryStatus = Api.failStatus;
        state.isNewRequest = false;
      });
  },
});

export default incorporationSlice.reducer;
