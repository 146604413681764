import React, { Component } from 'react';
import LoginPageView from './LoginPageView';

class LoginPage extends Component {
  state = {
    email: null,
    password: '',
  };

  textFieldChangeHandle = name => value => {
    this.setState(prevState => ({
      [name]: prevState[name] + value,
    }));
  };

  render() {
    const { email, password } = this.state;
    return (
      <LoginPageView
        login={() => console.log('submit')}
        textFiealdChangeAction={this.textFieldChangeHandle}
        passwFieldValue={password}
        emailFieldValue={email}
      />
    );
  }
}

export default LoginPage;
