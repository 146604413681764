import { createLogic } from 'redux-logic';

import settingsApi from '../../service/Api/settingsApi';
import { ProjectApi } from '../../service/Api/Api';
import { FILE_SIZE_LIMIT } from '../../service/file';
import * as settingsTypes from './settingsActions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FileUploadModel, useFileAsResponsePayloadModel } from '../../models';
import { FileUploadResponsePayloadModel, GetMembersResponseModel, ShortLinkPayload, ShortLinkResponse } from './settingsReducer.model';
import { errorsSlice } from '../errors/errorsSlice';
import submittalsApi from '../../service/Api/submittals/submittalsApi';
// import { RootStateGlobal } from '../configure/configureStore';
const { setShowErrorData } = errorsSlice.actions;

// TODO delete unused old team tab logic
const savePartyLogic = createLogic({
  type: settingsTypes.PARTY_SAVE,
  latest: true,
  process({ action }, dispatch, done) {
    const { data } = action.payload;
    data.project_id = Number(sessionStorage.getItem('active_project_id'));
    settingsApi
      .postParty(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(settingsTypes.PartySaveSucces(response));
        dispatch(settingsTypes.getMembers());
      })
      .catch(err => {
        dispatch(settingsTypes.PartySaveFail(err));
      })
      .then(() => done());
  },
});

// TODO delete unused old team tab logic
const updatePartyLogic = createLogic({
  type: settingsTypes.PARTY_UPDATE,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    settingsApi
      .postPartyUpdate(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(settingsTypes.PartyUpdateSuccess(response));
        dispatch(settingsTypes.getMembers());
      })
      .catch(err => {
        dispatch(settingsTypes.PartyUpdatFail(err));
      })
      .then(() => done());
  },
});

// TODO delete unused old team tab logic
const getMembersLogic = createLogic({
  type: settingsTypes.GET_MEMBERS,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const state = getState();
    const { userInfo, active_project_id: projectId } = state.userReducer;
    const companyId = userInfo?.parties && userInfo.parties.id;
    // const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '1').length;
    let request = {
      projectId: action?.payload?.projectId || projectId || Number(sessionStorage.getItem('active_project_id')),
      companyId: !projectId && companyId,
    };
    if (action.payload && action.payload.isAll) {
      request = {};
    }
    settingsApi
      .getMembers(request)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(settingsTypes.getMembersSucces(response));
        // dispatch(projectTypes.getProjectSuccess({ response, projectId }));
      })
      .catch(err => {
        dispatch(settingsTypes.getMembersFail(err));
      })
      .then(() => done());
  },
});

export const getMembers = createAsyncThunk<GetMembersResponseModel>(
  'settingsReducer/getMembers',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const { userInfo, active_project_id: projectId } = state.userReducer;
    const companyId = userInfo?.parties && userInfo.parties.id;

    const request = {
      projectId: projectId || Number(sessionStorage.getItem('active_project_id')),
      companyId: !projectId && companyId,
    };
    try {
      const response = await settingsApi.getMembers(request);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

// TODO delete unused old team tab logic
const addMemberLogic = createLogic({
  type: settingsTypes.ADD_MEMBER,
  latest: true,
  process({ action }, dispatch, done) {
    const { data } = action.payload;
    let updateMember;
    if (data.user_id) {
      updateMember = settingsApi.updateMember(data);
    } else {
      data.project_id = Number(sessionStorage.getItem('active_project_id'));
      updateMember = settingsApi.postMember(data);
    }
    updateMember
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        if (data.user_id) {
          dispatch(settingsTypes.updateMemberSuccess(response));
        } else {
          dispatch(settingsTypes.addMemberSuccess(response));
        }
        // dispatch(settingsTypes.getMembers());
      })
      .catch(err => {
        dispatch(settingsTypes.addMemberFail(err));
      })
      .then(() => done());
  },
});

// TODO delete unused old team tab logic
const delMemberLogic = createLogic({
  type: settingsTypes.DELETE_MEMBER,
  latest: true,
  process({ action }, dispatch, done) {
    const data = { user_id: action.payload.user_id };
    let updateMember = settingsApi.deleteMember(data);
    updateMember
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(settingsTypes.delMemberSuccess(response));
        dispatch(settingsTypes.getMembers());
      })
      .catch(err => {
        dispatch(settingsTypes.delMemberFail(err));
      })
      .then(() => done());
  },
});

// TODO delete unused old team tab logic
const updateDisciplineLogic = createLogic({
  type: settingsTypes.DISCIPLINE_UPDATE,
  latest: true,
  process({ action }, dispatch, done) {
    const { data } = action.payload;
    settingsApi
      .postDisciplineUpdate(data)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        dispatch(settingsTypes.DisciplineUpdateSucces(response));
        dispatch(settingsTypes.getMembers());
      })
      .catch(err => {
        dispatch(settingsTypes.DisciplineUpdateFail(err));
      })
      .then(() => done());
  },
});

// const filesUpload = createLogic({
//   type: settingsTypes.FILES_UPLOAD,
//   //latest: true,
//   process({ action }, dispatch, done) {
//     const {files, source} = action.payload;
//     //if file size more then 10MB
//     if (files.size > FILE_SIZE_LIMIT) {
//       dispatch(settingsTypes.fileUploadOverSize({files}));
//     } else {
//       settingsApi.filesUpload(files)
//         .then(response => {
//           if(response.status === false || response.status === 400) {
//             dispatch(settingsTypes.filesUploadFail({response, source,files}));
//             throw(response);
//           }
//           dispatch(settingsTypes.filesUploadSuccess({response, source}));
//         })
//         .catch(err => {
//           done();
//         })
//         .then(()=> done());
//     }
//   },
// });

const filesUploadLogic = createLogic({
  type: settingsTypes.FILES_UPLOAD,
  //latest: true,
  process({ action }, dispatch, done) {
    const { file, source, callback } = action.payload;
    //if file size more then 10MB
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch(settingsTypes.fileUploadOverSize({ file }));
    } else {
      settingsApi
        .filesUpload(file)
        .then(response => {
          if (response.status === false || response.status === 400) {
            dispatch(settingsTypes.filesUploadFail({ response, source, file }));
            throw response;
          }

          if (callback) {
            callback();
          }

          dispatch(settingsTypes.filesUploadSuccess({ response, source }));
        })
        .catch(err => {
          done();
        })
        .then(() => done());
    }
  },
});

export const filesUpload = createAsyncThunk<FileUploadResponsePayloadModel, FileUploadModel>(
  'settingsReducer/filesUpload',
  async (payload, { dispatch, rejectWithValue }) => {
    const { file, source, callback } = payload;
    try {
      //if file size more then 200MB
      if (file.size > FILE_SIZE_LIMIT) {
        return rejectWithValue({ source, file, isOversize: true });
      }

      const response = await settingsApi.filesUpload(file);

      if (callback) {
        callback();
      }

      return { response, source };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue({ source, file, isOversize: false });
    }
  },
);

export const filesMultipleUpload = createAsyncThunk<FileUploadResponsePayloadModel, FileUploadModel>(
  'settingsReducer/filesMultipleUpload',
  async (payload, { dispatch, rejectWithValue }) => {
    const { files, source, callback } = payload;
    try {
      const links = [];
      files.map((file, fIndex) => {
        //if file size more then 200MB
        if (file.size > FILE_SIZE_LIMIT) {
          return rejectWithValue({ source, file, isOversize: true });
        }
        links.push(settingsApi.filesUpload(file));
      });
      const responses = await Promise.all(links);
      if (callback) {
        callback();
      }
      return { responses: responses, source };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue({ source, files, isOversize: false });
    }
  },
);

export const useFileAsResponse = createAsyncThunk<FileUploadResponsePayloadModel, useFileAsResponsePayloadModel>(
  'settingsReducer/useFileAsResponse',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, is_annotation, callback } = payload;

    try {
      const response = await settingsApi.useFileAsResponse(id, is_annotation);

      if (callback) {
        callback(response);
      }

      return response;
    } catch (e) {
      console.log('error - copy file to response ', e);
      // return rejectWithValue({ source, file, isOversize: false });
    }
  },
);

export const getShortLink = createAsyncThunk<ShortLinkResponse, ShortLinkPayload>(
  'settingsReducer/getShortLink',
  async (payload, { dispatch, rejectWithValue }) => {
    const { filter_hash, callback } = payload;
    try {
      const response = await settingsApi.getShortLink({ filter_hash });

      if (callback) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

// TODO delete unused old team tab logic
const updateLinkUser = createLogic({
  type: settingsTypes.UPDATE_LINK_USER,
  process({ action, getState }, dispatch, done) {
    const data = action.payload;
    settingsApi
      .updateLinkUser(data)
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(settingsTypes.updateLinkUserSuccess(result));
      })
      .catch(err => {})
      .then(() => {
        done();
      });
  },
});

// TODO delete unused old team tab logic
const updateUnlinkUser = createLogic({
  type: settingsTypes.UPDATE_UNLINK_USER,
  process({ action, getState }, dispatch, done) {
    const data = action.payload;
    settingsApi
      .updateUnlinkUser(data)
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(settingsTypes.updateUnlinkUserSuccess(result));
      })
      .catch(err => {})
      .then(() => {
        done();
      });
  },
});

export default [
  // delMemberLogic,
  // savePartyLogic,
  // getMembersLogic,
  // addMemberLogic,
  // updateDisciplineLogic,
  // updatePartyLogic,
  // filesUploadLogic,
  // updateLinkUser,
  // updateUnlinkUser,
];
