import React, { Component } from 'react';

class Updates_20230713_3 extends Component {
  render() {
    return (
      <div className={'unreadWhatsNewText'}>
        <div className={'paragraph'} style={{ padding: '0 0 15px 0', display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ display: 'block', width: '443px', height: 'auto' }}
            src={'https://pmexpd.stripocdn.email/content/guids/CABINET_4131b35ac0c45602499a739bba83b2b0/images/image3_jqI.png'}
            alt=""
            width="443"
          />
        </div>
      </div>
    );
  }
}

export default Updates_20230713_3;
