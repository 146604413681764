import React from 'react';
import { LocationCollectionModel } from '../../../../../../models';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import CheckBox from '../../../../../controls/CheckBox/CheckBox';

import './LevelItemStyles.scss';

interface IProps {
  level: LocationCollectionModel;
  handleLevelItemClick?: (level: LocationCollectionModel) => void;
}

const LevelItem: React.FC<IProps> = ({ level, handleLevelItemClick }) => {
  const themeClass = useGetThemeClass('b-levelItem');

  return (
    <div className={`${themeClass}_container`} onClick={() => handleLevelItemClick(level)}>
      <div className={`${themeClass}_item`}>
        <div className={`${themeClass}_item_checkBox`}>
          <CheckBox checked={level.isSelected} onClick={null} />
        </div>
        <div className={`${themeClass}_item_title`}>{level.title}</div>
      </div>
    </div>
  );
};

export default LevelItem;
