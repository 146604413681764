import { actionCreater } from '../configure/actionCreater';

//Get data for select values
export const GET_PHASE_PLAN_SELECT_DATA = 'GET_PHASE_PLAN_SELECT_DATA';
export const getPhasePlanSelectData = actionCreater(GET_PHASE_PLAN_SELECT_DATA);

export const GET_PHASE_PLAN_SELECT_DATA_SUCCESS = 'GET_PHASE_PLAN_SELECT_DATA_SUCCESS';
export const getPhasePlanSelectDataSuccess = actionCreater(GET_PHASE_PLAN_SELECT_DATA_SUCCESS);

export const GET_PHASE_PLAN_SELECT_DATA_FAIL = 'GET_PHASE_PLAN_SELECT_DATA_FAIL';
export const getPhasePlanSelectDataFail = actionCreater(GET_PHASE_PLAN_SELECT_DATA_FAIL);

//Create project
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const createProject = actionCreater(CREATE_PROJECT);

export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const createProjectSuccess = actionCreater(CREATE_PROJECT_SUCCESS);

export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export const createProjectFail = actionCreater(CREATE_PROJECT_FAIL);

//Update project
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const updateProject = actionCreater(UPDATE_PROJECT);

export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const updateProjectSuccess = actionCreater(UPDATE_PROJECT_SUCCESS);

export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const updateProjectFail = actionCreater(UPDATE_PROJECT_FAIL);

//Get project by ID
export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID';
export const getProjectById = actionCreater(GET_PROJECT_BY_ID);

export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const getProjectByIdSuccess = actionCreater(GET_PROJECT_BY_ID_SUCCESS);

export const GET_PROJECT_BY_ID_FAIL = 'GET_PROJECT_BY_ID_FAIL';
export const getProjectByIdFail = actionCreater(GET_PROJECT_BY_ID_FAIL);

//Create building
export const CREATE_BUILDING = 'CREATE_BUILDING';
export const createBuilding = actionCreater(CREATE_BUILDING);

export const CREATE_BUILDING_SUCCESS = 'CREATE_BUILDING_SUCCESS';
export const createBuildingSuccess = actionCreater(CREATE_BUILDING_SUCCESS);

export const CREATE_BUILDING_FAIL = 'CREATE_BUILDING_FAIL';
export const createBuildingFail = actionCreater(CREATE_BUILDING_FAIL);

//Update building
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const updateBuilding = actionCreater(UPDATE_BUILDING);

export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';
export const updateBuildingSuccess = actionCreater(UPDATE_BUILDING_SUCCESS);

export const UPDATE_BUILDING_FAIL = 'UPDATE_BUILDING_FAIL';
export const updateBuildingFail = actionCreater(UPDATE_BUILDING_FAIL);

//Delete building
export const DELETE_BUILDING = 'DELETE_BUILDING';
export const deleteBuilding = actionCreater(DELETE_BUILDING);

export const RESET_BUILDING_ERRORS = 'RESET_BUILDING_ERRORS';
export const resetBuildingErrors = actionCreater(RESET_BUILDING_ERRORS);

export const DELETE_BUILDING_SUCCESS = 'DELETE_BUILDING_SUCCESS';
export const deleteBuildingSuccess = actionCreater(DELETE_BUILDING_SUCCESS);

export const DELETE_BUILDING_FAIL = 'DELETE_BUILDING_FAIL';
export const deleteBuildingFail = actionCreater(DELETE_BUILDING_FAIL);

//Update building locations
export const UPDATE_BUILDING_LOCATION = 'UPDATE_BUILDING_LOCATION';
export const updateBuildingLocation = actionCreater(UPDATE_BUILDING_LOCATION);

export const UPDATE_BUILDING_LOCATION_SUCCESS = 'UPDATE_BUILDING_LOCATION_SUCCESS';
export const updateBuildingLocationSuccess = actionCreater(UPDATE_BUILDING_LOCATION_SUCCESS);

export const UPDATE_BUILDING_LOCATION_FAIL = 'UPDATE_BUILDING_LOCATION_FAIL';
export const updateBuildingLocationFail = actionCreater(UPDATE_BUILDING_LOCATION_FAIL);

//Get step 2 data
export const GET_STEP_2 = 'GET_STEP_2';
export const getStep2 = actionCreater(GET_STEP_2);

export const GET_STEP_2_SUCCESS = 'GET_STEP_2_SUCCESS';
export const getStep2Success = actionCreater(GET_STEP_2_SUCCESS);

export const GET_STEP_2_FAIL = 'GET_STEP_2_FAIL';
export const getStep2Fail = actionCreater(GET_STEP_2_FAIL);

//Get step 3 data
export const GET_STEP_3 = 'GET_STEP_3';
export const getStep3 = actionCreater(GET_STEP_3);

export const GET_STEP_3_SUCCESS = 'GET_STEP_3_SUCCESS';
export const getStep3Success = actionCreater(GET_STEP_3_SUCCESS);

export const GET_STEP_3_FAIL = 'GET_STEP_3_FAIL';
export const getStep3Fail = actionCreater(GET_STEP_3_FAIL);

//Update step 2 data
export const UPDATE_STEP_2 = 'UPDATE_STEP_2';
export const updateStep2 = actionCreater(UPDATE_STEP_2);

export const UPDATE_STEP_2_SUCCESS = 'UPDATE_STEP_2_SUCCESS';
export const updateStep2Success = actionCreater(UPDATE_STEP_2_SUCCESS);

export const UPDATE_STEP_2_FAIL = 'UPDATE_STEP_2_FAIL';
export const updateStep2Fail = actionCreater(UPDATE_STEP_2_FAIL);

//Create company for step 3
export const CREATE_COMPANY_STEP_3 = 'CREATE_COMPANY_STEP_3';
export const createCompanyStep3 = actionCreater(CREATE_COMPANY_STEP_3);

export const CREATE_COMPANY_STEP_3_SUCCESS = 'CREATE_COMPANY_STEP_3_SUCCESS';
export const createCompanyStep3Success = actionCreater(CREATE_COMPANY_STEP_3_SUCCESS);

export const CREATE_COMPANY_STEP_3_FAIL = 'CREATE_COMPANY_STEP_3_FAIL';
export const createCompanyStep3Fail = actionCreater(CREATE_COMPANY_STEP_3_FAIL);

//Add discipline on step 3
export const ADD_DISCIPLINE = 'ADD_DISCIPLINE';
export const addDiscipline = actionCreater(ADD_DISCIPLINE);

export const ADD_DISCIPLINE_SUCCESS = 'ADD_DISCIPLINE_SUCCESS';
export const addDisciplineSuccess = actionCreater(ADD_DISCIPLINE_SUCCESS);

export const ADD_DISCIPLINE_FAIL = 'ADD_DISCIPLINE_FAIL';
export const addDisciplineFail = actionCreater(ADD_DISCIPLINE_FAIL);

//Create discipline on step 3
export const CREATE_DISCIPLINE = 'CREATE_DISCIPLINE';
export const createDiscipline = actionCreater(CREATE_DISCIPLINE);

export const CREATE_DISCIPLINE_SUCCESS = 'CREATE_DISCIPLINE_SUCCESS';
export const createDisciplineSuccess = actionCreater(CREATE_DISCIPLINE_SUCCESS);

export const CREATE_DISCIPLINE_FAIL = 'CREATE_DISCIPLINE_FAIL';
export const createDisciplineFail = actionCreater(CREATE_DISCIPLINE_FAIL);

//Remove discipline on step 3
export const REMOVE_DISCIPLINE = 'REMOVE_DISCIPLINE';
export const removeDiscipline = actionCreater(REMOVE_DISCIPLINE);

export const REMOVE_DISCIPLINE_SUCCESS = 'REMOVE_DISCIPLINE_SUCCESS';
export const removeDisciplineSuccess = actionCreater(REMOVE_DISCIPLINE_SUCCESS);

export const REMOVE_DISCIPLINE_FAIL = 'REMOVE_DISCIPLINE_FAIL';
export const removeDisciplineFail = actionCreater(REMOVE_DISCIPLINE_FAIL);

//Add company on step 3
export const ADD_COMPANY = 'ADD_COMPANY';
export const addCompany = actionCreater(ADD_COMPANY);

export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const addCompanySuccess = actionCreater(ADD_COMPANY_SUCCESS);

export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const addCompanyFail = actionCreater(ADD_COMPANY_FAIL);

//Remove company on step 3
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const removeCompany = actionCreater(REMOVE_COMPANY);

export const REMOVE_COMPANY_SUCCESS = 'REMOVE_COMPANY_SUCCESS';
export const removeCompanySuccess = actionCreater(REMOVE_COMPANY_SUCCESS);

export const REMOVE_COMPANY_FAIL = 'REMOVE_COMPANY_FAIL';
export const removeCompanyFail = actionCreater(REMOVE_COMPANY_FAIL);

// Update step 3
export const UPDATE_STEP_3 = 'UPDATE_STEP_3';
export const updateStep3 = actionCreater(UPDATE_STEP_3);

export const UPDATE_STEP_3_SUCCESS = 'UPDATE_STEP_3_SUCCESS';
export const updateStep3Success = actionCreater(UPDATE_STEP_3_SUCCESS);

export const UPDATE_STEP_3_FAIL = 'UPDATE_STEP_3_FAIL';
export const updateStep3Fail = actionCreater(UPDATE_STEP_3_FAIL);

// Hide\Show disciplines
export const SWITCH_DISCIPLINES = 'SWITCH_DISCIPLINES';
export const switchDiscipline = actionCreater(SWITCH_DISCIPLINES);

export const SWITCH_DISCIPLINES_SUCCESS = 'SWITCH_DISCIPLINES_SUCCESS';
export const switchDisciplineSuccess = actionCreater(SWITCH_DISCIPLINES_SUCCESS);

export const SWITCH_DISCIPLINES_FAIL = 'SWITCH_DISCIPLINES_FAIL';
export const switchDisciplineFail = actionCreater(SWITCH_DISCIPLINES_FAIL);

//Get phase plan
export const GET_PHASE_PLAN = 'GET_PHASE_PLAN';
export const getPhasePlan = actionCreater(GET_PHASE_PLAN);

export const GET_PHASE_PLAN_SUCCESS = 'GET_PHASE_PLAN_SUCCESS';
export const getPhasePlanSuccess = actionCreater(GET_PHASE_PLAN_SUCCESS);

export const GET_PHASE_PLAN_FAIL = 'GET_PHASE_PLAN_FAIL';
export const getPhasePlanFail = actionCreater(GET_PHASE_PLAN_FAIL);

export const PHASE_PLAN_CHANGE_TABLE = 'PHASE_PLAN_CHANGE_TABLE';
export const phasePlanChangeTable = actionCreater(PHASE_PLAN_CHANGE_TABLE);

//Update phasePlan
export const UPDATE_PHASE_PLAN = 'UPDATE_PHASE_PLAN';
export const updatePhasePlan = actionCreater(UPDATE_PHASE_PLAN);

export const UPDATE_PHASE_PLAN_SUCCESS = 'UPDATE_PHASE_PLAN_SUCCESS';
export const updatePhasePlanSuccess = actionCreater(UPDATE_PHASE_PLAN_SUCCESS);

export const UPDATE_PHASE_PLAN_FAIL = 'UPDATE_PHASE_PLAN_FAIL';
export const updatePhasePlanFail = actionCreater(UPDATE_PHASE_PLAN_FAIL);

//Update phasePlan
export const SAVE_PHASE_PLAN_VERSION = 'SAVE_PHASE_PLAN_VERSION';
export const savePhasePlanVersion = actionCreater(SAVE_PHASE_PLAN_VERSION);

export const SAVE_PHASE_PLAN_VERSION_SUCCESS = 'SAVE_PHASE_PLAN_VERSION_SUCCESS';
export const savePhasePlanVersionSuccess = actionCreater(SAVE_PHASE_PLAN_VERSION_SUCCESS);

export const SAVE_PHASE_PLAN_VERSION_FAIL = 'SAVE_PHASE_PLAN_VERSION_FAIL';
export const savePhasePlanVersionFail = actionCreater(SAVE_PHASE_PLAN_VERSION_FAIL);

// Create Phase Plan field
export const CREATE_PHASE_PLAN_FIELD = 'CREATE_PHASE_PLAN_FIELD';
export const createPhasePlanField = actionCreater(CREATE_PHASE_PLAN_FIELD);

export const CREATE_PHASE_PLAN_FIELD_SUCCESS = 'CREATE_PHASE_PLAN_FIELD_SUCCESS';
export const createPhasePlanFieldSuccess = actionCreater(CREATE_PHASE_PLAN_FIELD_SUCCESS);

export const CREATE_PHASE_PLAN_FIELD_FAIL = 'CREATE_PHASE_PLAN_FIELD_FAIL';
export const createPhasePlanFieldFail = actionCreater(CREATE_PHASE_PLAN_FIELD_FAIL);

// Update Phase Plan field
export const UPDATE_PHASE_PLAN_FIELD = 'UPDATE_PHASE_PLAN_FIELD';
export const updatePhasePlanField = actionCreater(UPDATE_PHASE_PLAN_FIELD);

export const UPDATE_PHASE_PLAN_FIELD_SUCCESS = 'UPDATE_PHASE_PLAN_FIELD_SUCCESS';
export const updatePhasePlanFieldSuccess = actionCreater(UPDATE_PHASE_PLAN_FIELD_SUCCESS);

export const UPDATE_PHASE_PLAN_FIELD_FAIL = 'UPDATE_PHASE_PLAN_FIELD_FAIL';
export const updatePhasePlanFieldFail = actionCreater(UPDATE_PHASE_PLAN_FIELD_FAIL);

// Delete Phase Plan field
export const DELETE_PHASE_PLAN_FIELD = 'DELETE_PHASE_PLAN_FIELD';
export const deletePhasePlanField = actionCreater(DELETE_PHASE_PLAN_FIELD);

export const DELETE_PHASE_PLAN_FIELD_SUCCESS = 'DELETE_PHASE_PLAN_FIELD_SUCCESS';
export const deletePhasePlanFieldSuccess = actionCreater(DELETE_PHASE_PLAN_FIELD_SUCCESS);

export const DELETE_PHASE_PLAN_FIELD_FAIL = 'DELETE_PHASE_PLAN_FIELD_FAIL';
export const deletePhasePlanFieldFail = actionCreater(DELETE_PHASE_PLAN_FIELD_FAIL);

//Update phasePlan
export const DOWNLOAD_PHASE_PLAN_VERSION = 'DOWNLOAD_PHASE_PLAN_VERSION';
export const downloadPhasePlanVersion = actionCreater(DOWNLOAD_PHASE_PLAN_VERSION);

export const DOWNLOAD_PHASE_PLAN_VERSION_SUCCESS = 'DOWNLOAD_PHASE_PLAN_VERSION_SUCCESS';
export const downloadPhasePlanVersionSuccess = actionCreater(DOWNLOAD_PHASE_PLAN_VERSION_SUCCESS);

export const DOWNLOAD_PHASE_PLAN_VERSION_FAIL = 'DOWNLOAD_PHASE_PLAN_VERSION_FAIL';
export const downloadPhasePlanVersionFail = actionCreater(DOWNLOAD_PHASE_PLAN_VERSION_FAIL);

//Remove construction permission
export const UPDATE_PARTY_ROLE = 'UPDATE_PARTY_ROLE';
export const updatePartyRole = actionCreater(UPDATE_PARTY_ROLE);

export const UPDATE_PARTY_ROLE_SUCCESS = 'UPDATE_PARTY_ROLE_SUCCESS';
export const updatePartyRoleSuccess = actionCreater(UPDATE_PARTY_ROLE_SUCCESS);

export const UPDATE_PARTY_ROLE_FAIL = 'UPDATE_PARTY_ROLE_FAIL';
export const updatePartyRoleFail = actionCreater(UPDATE_PARTY_ROLE_FAIL);

//Update GMP
export const UPDATE_GMP = 'UPDATE_GMP';
export const updateGMP = actionCreater(UPDATE_GMP);

export const UPDATE_GMP_SUCCESS = 'UPDATE_GMP_SUCCESS';
export const updateGMPSuccess = actionCreater(UPDATE_GMP_SUCCESS);

export const UPDATE_GMP_FAIL = 'UPDATE_GMP_FAIL';
export const updateGMPFail = actionCreater(UPDATE_GMP_FAIL);

// Get prev phase plan
export const GET_PREV_PHASE_PLAN = 'GET_PREV_PHASE_PLAN';
export const getPrevPhasePlan = actionCreater(GET_PREV_PHASE_PLAN);

export const GET_PREV_PHASE_PLAN_SUCCESS = 'GET_PREV_PHASE_PLAN_SUCCESS';
export const getPrevPhasePlanSuccess = actionCreater(GET_PREV_PHASE_PLAN_SUCCESS);

export const GET_PREV_PHASE_PLAN_FAIL = 'GET_PREV_PHASE_PLAN_FAIL';
export const getPrevPhasePlanFail = actionCreater(GET_PREV_PHASE_PLAN_FAIL);

// Set Driver
export const SET_DRIVER_TASKS = 'SET_DRIVER_TASKS';
export const setDriverTasks = actionCreater(SET_DRIVER_TASKS);

export const SET_DRIVER_TASKS_SUCCESS = 'SET_DRIVER_TASKS_SUCCESS';
export const setDriverTasksSuccess = actionCreater(SET_DRIVER_TASKS_SUCCESS);

export const SET_DRIVER_TASKS_FAIL = 'SET_DRIVER_TASKS_FAIL';
export const setDriverTasksFail = actionCreater(SET_DRIVER_TASKS_FAIL);

export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';
export const clearProjectData = actionCreater(CLEAR_PROJECT_DATA);

export const SET_ACTIVE_TAB_PROJECT = 'SET_ACTIVE_TAB_PROJECT';
export const setActiveTabProject = actionCreater(SET_ACTIVE_TAB_PROJECT);
