import { createLogic } from 'redux-logic';
import { ReportingApi } from '../../service/Api/Api';
import * as reportingTypes from './reportingActions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ReportingDataModel,
  ReportingForCompanyRequestModel,
  ReportingForUserRequestModel,
  ReportingRequestModel,
  ReportingRequestTableModel,
} from '../../models/reporting/reporting.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

const getReportingLogic = createLogic({
  type: reportingTypes.GET_REPORTING,
  latest: true,
  cancelType: reportingTypes.GET_REPORTING_CANCEL,
  process({ action }, dispatch, done) {
    const { active_project_id, filters } = action.payload;
    ReportingApi.getReportingHeader(filters || {}, active_project_id)
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(reportingTypes.getReportingSuccess(result));
      })
      .catch(err => {
        dispatch(reportingTypes.getReportingFail(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+active_project_id === +currentActiveProjectId) {
          dispatch(reportingTypes.getReportingCancel());
        }
        done();
      });
  },
});

export const getReportingHeader = createAsyncThunk<ReportingDataModel, ReportingRequestModel>(
  'reportingReducer/getReportingHeader',
  async (payload, { dispatch, rejectWithValue }) => {
    const { active_project_id, start_date, end_date, group_by, party_id, user_id } = payload;
    try {
      const bodyParams = {
        start_date: start_date,
        end_date: end_date,
        group_by: group_by,
      };
      if (party_id) {
        bodyParams.party_id = party_id;
      }
      if (user_id) {
        bodyParams.user_id = user_id;
      }
      const response = await ReportingApi.getReportingHeader(bodyParams, active_project_id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getReportingTable = createAsyncThunk<ReportingDataModel, ReportingRequestTableModel>(
  'reportingReducer/getReportingTable',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { active_project_id, party_id, user_id, start_date, end_date } = payload;
      const bodyParams = {};
      if (party_id) {
        bodyParams.party_id = party_id;
      }
      if (user_id) {
        bodyParams.user_id = user_id;
      }
      if (start_date) {
        bodyParams.start_date = start_date;
      }
      if (end_date) {
        bodyParams.end_date = end_date;
      }
      const response = await ReportingApi.getReportingTable(bodyParams, active_project_id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getReportingForCompanyLogic = createLogic({
  type: reportingTypes.GET_REPORTING_FOR_COMPANY,
  latest: true,
  cancelType: reportingTypes.GET_REPORTING_FOR_COMPANY_CANCEL,
  process({ action }, dispatch, done) {
    const { active_project_id, partyId, filters } = action.payload;
    ReportingApi.getReportingForCompany(filters || {}, active_project_id, partyId)
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(reportingTypes.getReportingForCompanySuccess(result));
      })
      .catch(err => {
        dispatch(reportingTypes.getReportingForCompanyFail(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+active_project_id === +currentActiveProjectId) {
          dispatch(reportingTypes.getReportingForCompanyCancel());
        }
        done();
      });
  },
});

export const getReportingForCompany = createAsyncThunk<ReportingDataModel, ReportingForCompanyRequestModel>(
  'reportingReducer/getReportingForCompany',
  async (payload, { dispatch, rejectWithValue }) => {
    const { active_project_id, partyId, filters } = payload;
    try {
      const response = await ReportingApi.getReportingForCompany(filters || {}, active_project_id, partyId);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getReportingForUserLogic = createLogic({
  type: reportingTypes.GET_REPORTING_FOR_USER,
  latest: true,
  cancelType: reportingTypes.GET_REPORTING_FOR_USER_CANCEL,
  process({ action }, dispatch, done) {
    const { active_project_id, user_id } = action.payload;
    ReportingApi.getReportingForUser({}, active_project_id, user_id)
      .then(result => {
        if (result.status !== true) {
          throw result;
        }
        dispatch(reportingTypes.getReportingForUserSuccess(result));
      })
      .catch(err => {
        dispatch(reportingTypes.getReportingForUserFail(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+active_project_id === +currentActiveProjectId) {
          dispatch(reportingTypes.getReportingForUserCancel());
        }
        done();
      });
  },
});

export const getReportingForUser = createAsyncThunk<ReportingDataModel, ReportingForUserRequestModel>(
  'reportingReducer/getReportingForUser',
  async (payload, { dispatch, rejectWithValue }) => {
    const { active_project_id, user_id } = payload;
    try {
      const response = await ReportingApi.getReportingForUser({}, active_project_id, user_id);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export default [
  // getReportingLogic,
  // getReportingForCompanyLogic,
  // getReportingForUserLogic,
];
