import { PlaneControlDeliverable } from './types';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
  changeNfValidityStatus,
  changePCDCardStatus,
  createPCDCard,
  editDeliverableReviewsPCD,
  getListPCD,
  getPCDCard,
  linkDeliverableToDeliverable,
  linkDeliverableToNf,
  unLinkDeliverableToDeliverable,
  unLinkDeliverableToNf,
  updatePCDCard,
  updatePcdReasons,
  updateReviewPCD,
} from './pcdThunk';
import { PCDApi } from '../../service/Api/pcd/types';

const initialState: PlaneControlDeliverable.State = {
  pcdInfo: {},
  pcdInfoLoading: false,
  pcdDeliverablesListLoading: false,
  lastPcdCardId: null,
  lastPcdCardBuildingId: null,
  pcdLinkingListSuggestions: null,
  pcdFiltersData: null,
  editDeliverableReviewsPCDLoading: false,
  updateReviewPCDLoading: false,
  updateReviewPCDId: null,
  filters_for_related_pcd: null,
  pcdCardIsOpen: false,
  check_due_date: null,
  socketWatchlistState: {
    watchers: null,
    watcher_groups: null,
    changedNow: false,
  },
  socketHotlistState: {
    is_hot_list: false,
    changedNow: false,
  },
};

export const pcdSlice = createSlice({
  initialState,
  name: 'pcdReducer',
  reducers: {
    setPcdOpenId(state, { payload }: PayloadAction<PlaneControlDeliverable.GetCardPayload>) {
      state.lastPcdCardId = payload.id;
      state.lastPcdCardBuildingId = payload.buildingId;
      state.pcdFiltersData = payload.filtersData;
      state.pcdCardIsOpen = true;
    },
    closePcdCard(state) {
      state.pcdCardIsOpen = false;
      state.lastPcdCardId = null;
    },
    socketHandlePcdCard(state, { payload }: PayloadAction<PlaneControlDeliverable.SocketUpdateResponse>) {
      const currentState = current(state);
      const { card, action } = payload;

      let pcdInfo = { ...currentState.pcdInfo };

      if (action === 'updated_bic' && card.revisions?.length) {
        const cardRevisionIds = card.revisions.map(revision => revision.id);
        const cardsIdsToUpdate = [card.id, ...cardRevisionIds];

        cardsIdsToUpdate.forEach(id => {
          if (pcdInfo[id]) {
            pcdInfo = {
              ...pcdInfo,
              [id]: {
                ...pcdInfo[id],
                secondary_courts: card.secondary_courts,
              },
            };
          }
        });
      } else {
        if (action === 'updated_watchlist' && card.revisions?.length) {
          const cardRevisionIds = card.revisions.map(revision => revision.id);
          const cardsIdsToUpdate = [card.id, ...cardRevisionIds];

          cardsIdsToUpdate.forEach(id => {
            if (pcdInfo[id]) {
              pcdInfo = {
                ...pcdInfo,
                [id]: {
                  ...pcdInfo[id],
                  watchers: card.watchers,
                },
              };
            }
          });
        } else {
          if (!pcdInfo[card.id]) {
            return state;
          }

          pcdInfo = {
            ...state.pcdInfo,
            [card.id]: card,
          };
        }
      }

      state.pcdInfo = pcdInfo;
    },
    socketHandleDeliverableChangeWatchlist(state, { payload: { data, pcdId } }) {
      if (pcdId === data.id) {
        state.socketWatchlistState = { changedNow: true, watchers: data.watchers, watcher_groups: data.watcher_groups };
        state.pcdInfo = { ...state.pcdInfo, [data.id]: { ...data } };
      }
    },
    socketHandleDeliverableChangeHotlist(state, { payload: { data, pcdId } }) {
      if (pcdId === data.id) {
        state.socketHotlistState = { changedNow: true, is_hot_list: data.is_hot_list };
        state.pcdInfo = { ...state.pcdInfo, [data.id]: { ...data } };
      }
    },
    resetSocketDeliverableWatchlistState(state) {
      state.socketWatchlistState = { changedNow: false, watchers: null, watcher_groups: null };
    },
    resetSocketDeliverableHotlistState(state) {
      state.socketHotlistState = { changedNow: false, is_hot_list: false };
    },
    setLastPcdCardBuildingId(state, action) {
      state.lastPcdCardBuildingId = action.payload;
    },
    handleDeliverableChangeWatchlist(state, { payload }) {
      state.socketWatchlistState = { changedNow: true, watchers: payload.watchers, watcher_groups: payload.watcher_groups };
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getPCDCard
       */
      .addCase(getPCDCard.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(getPCDCard.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        if (state.pcdCardIsOpen) {
          const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };
          const pcdRelatedFilters = { ...state.filters_for_related_pcd, [payload.card.id]: payload.filters_for_related_pcd };

          state.pcdInfo = pcdInfo;
          state.pcdInfoLoading = false;
          state.lastPcdCardId = payload.isPreview ? state.lastPcdCardId : payload.card.id;
          state.lastPcdCardBuildingId = payload.isPreview ? state.lastPcdCardBuildingId : payload.card.building_id;
          state.filters_for_related_pcd = pcdRelatedFilters;
        } else {
          state.pcdInfoLoading = false;
          state.lastPcdCardId = 0;
          state.lastPcdCardBuildingId = 0;
        }
      })
      .addCase(getPCDCard.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
        state.lastPcdCardId = 0;
        state.lastPcdCardBuildingId = 0;
      })
      /**
       * updatePCDCard
       */
      .addCase(updatePCDCard.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(updatePCDCard.fulfilled, (state, { payload }: PayloadAction<PCDApi.PCDCard>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.id]: payload };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.id;
        state.lastPcdCardBuildingId = payload.building_id;
      })
      .addCase(updatePCDCard.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * createPCDCard
       */
      .addCase(createPCDCard.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(createPCDCard.fulfilled, (state, { payload }: PayloadAction<PCDApi.PCDCard>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.id]: payload };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.id;
        state.lastPcdCardBuildingId = payload.building_id;
      })
      .addCase(createPCDCard.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * linkDeliverableToNf
       */
      .addCase(linkDeliverableToNf.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(linkDeliverableToNf.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.card.id;
        state.lastPcdCardBuildingId = payload.card.building_id;
        state.check_due_date = payload.check_due_date;
      })
      .addCase(linkDeliverableToNf.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * unlinkDeliverableToNf
       */
      .addCase(unLinkDeliverableToNf.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(unLinkDeliverableToNf.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.card.id;
        state.lastPcdCardBuildingId = payload.card.building_id;
      })
      .addCase(unLinkDeliverableToNf.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * changeNfValidityStatus
       */
      .addCase(changeNfValidityStatus.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(changeNfValidityStatus.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.card.id;
        state.lastPcdCardBuildingId = payload.card.building_id;
      })
      .addCase(changeNfValidityStatus.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * linkDeliverableToDeliverable
       */
      .addCase(linkDeliverableToDeliverable.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(linkDeliverableToDeliverable.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };
        const pcdRelatedFilters = { ...state.filters_for_related_pcd, [payload.card.id]: payload.filters_for_related_pcd };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.card.id;
        state.lastPcdCardBuildingId = payload.card.building_id;
        state.filters_for_related_pcd = pcdRelatedFilters;
      })
      .addCase(linkDeliverableToDeliverable.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * unLinkDeliverableToDeliverable
       */
      .addCase(unLinkDeliverableToDeliverable.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(
        unLinkDeliverableToDeliverable.fulfilled,
        (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
          const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };
          const pcdRelatedFilters = { ...state.filters_for_related_pcd, [payload.card.id]: payload.filters_for_related_pcd };

          state.pcdInfo = pcdInfo;
          state.pcdInfoLoading = false;
          state.lastPcdCardId = payload.card.id;
          state.lastPcdCardBuildingId = payload.card.building_id;
          state.filters_for_related_pcd = pcdRelatedFilters;
        },
      )
      .addCase(unLinkDeliverableToDeliverable.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * getListPCD
       */
      .addCase(getListPCD.pending, state => {
        state.pcdDeliverablesListLoading = true;
      })
      .addCase(getListPCD.fulfilled, (state, { payload }: any) => {
        state.pcdDeliverablesListLoading = false;
      })
      .addCase(getListPCD.rejected, (state, { error }) => {
        state.pcdDeliverablesListLoading = false;
      })
      /**
       * editDeliverableReviewsPCD
       */
      .addCase(editDeliverableReviewsPCD.pending, state => {
        state.editDeliverableReviewsPCDLoading = true;
      })
      .addCase(editDeliverableReviewsPCD.fulfilled, (state, { payload }: PayloadAction<PCDApi.PCDCard>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.id]: payload };

        state.lastPcdCardId = payload.id;
        state.lastPcdCardBuildingId = payload.building_id;
        state.pcdInfo = pcdInfo;
        state.editDeliverableReviewsPCDLoading = false;
      })
      .addCase(editDeliverableReviewsPCD.rejected, (state, { error }) => {
        state.editDeliverableReviewsPCDLoading = false;
      })
      /**
       * updateReviewPCD
       */
      .addCase(
        updateReviewPCD.pending,
        (state, { meta }: PayloadAction<null, string, { arg: PlaneControlDeliverable.UpdateReviewPCD }>) => {
          state.updateReviewPCDLoading = true;
          state.updateReviewPCDId = meta.arg.data.id;
        },
      )
      .addCase(updateReviewPCD.fulfilled, (state, { payload }: PayloadAction<PlaneControlDeliverable.PCDCardWithFilters>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.card.id]: payload.card };

        state.lastPcdCardId = payload.card.id;
        state.lastPcdCardBuildingId = payload.card.building_id;
        state.pcdInfo = pcdInfo;
        state.updateReviewPCDLoading = false;
        state.updateReviewPCDId = null;
      })
      .addCase(updateReviewPCD.rejected, (state, { error }) => {
        state.updateReviewPCDLoading = false;
        state.updateReviewPCDId = null;
      })
      /**
       * changePCDCardStatus
       */
      .addCase(changePCDCardStatus.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(changePCDCardStatus.fulfilled, (state, { payload }: PayloadAction<PCDApi.PCDCard>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.id]: payload };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.id;
        state.lastPcdCardBuildingId = payload.building_id;
      })
      .addCase(changePCDCardStatus.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      })
      /**
       * changePCDCardStatus
       */
      .addCase(updatePcdReasons.pending, state => {
        state.pcdInfoLoading = true;
      })
      .addCase(updatePcdReasons.fulfilled, (state, { payload }: PayloadAction<PCDApi.PCDCard>) => {
        const pcdInfo = { ...state.pcdInfo, [payload.id]: payload };

        state.pcdInfo = pcdInfo;
        state.pcdInfoLoading = false;
        state.lastPcdCardId = payload.id;
        state.lastPcdCardBuildingId = payload.building_id;
      })
      .addCase(updatePcdReasons.rejected, (state, { error }) => {
        state.pcdInfoLoading = false;
      });
  },
});

export default pcdSlice.reducer;
