import React, { FC, useState, useEffect, useRef, ReactNode, ReactElement } from 'react';
import ReactHtmlParser from 'react-html-parser';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { DataGridBasic } from '../../../DataGridBasic';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import { IDeliverables } from '../../../../../models';
import PCDCard = PCDApi.PCDCard;
import cn from 'classnames';
import {
  columnsDesignInput,
  columnsDeliverables,
  columnsDeliverablesForDeliverables,
  columnsPcdCardLinkedNfsTab,
  columnsSubmittalsTab,
  columnsRequestTab,
  columnsDeliverableRequest,
} from './parts/contants';
import BasicFormatter from './parts/BasicFormatter';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import {
  DeliverableSnippetModel,
  DocumentSnippetModel,
  RequestSnippetModel,
  SubmittalSnippetModel,
} from '../../../../../store/relatedItems/relatedItemsSlice.model';
import { FullRequestModel } from '../../../../../store/request/requestReducer.model';

import './ManageStatusesStyles.scss';

type TransferListType = {
  current_set: string;
  exist: boolean;
  id: number;
  number: string;
  title: string;
  condition: string;
  status: string | null;
};

type TRelatedTabData = {
  request: RequestSnippetModel[] | [];
  deliverable: DeliverableSnippetModel[] | [];
  document: DocumentSnippetModel[] | [];
  submittal: SubmittalSnippetModel[] | [];
  predecessor?: RequestSnippetModel[] | DeliverableSnippetModel[] | SubmittalSnippetModel[] | [];
  successor?: RequestSnippetModel[] | DeliverableSnippetModel[] | SubmittalSnippetModel[] | [];
};

type SortColumnType = {
  order_column: string;
  order_dir: string;
  isNumber: boolean;
};

const ManageStatuses: FC<{
  type:
    | 'designInput'
    | 'deliverables'
    | 'pcdCardLinkedNfsTab'
    | 'Nf deliverable'
    | 'Nf document'
    | 'Nf submittalInput'
    | 'Nf submittalOutput'
    | 'Submittal request'
    | 'Submittal deliverable'
    | 'Submittal document'
    | 'Submittal submittalOutput'
    | 'Deliverable request'
    | 'Deliverable deliverable'
    | 'Deliverable submittalOutput';
  popUpIsOpen: boolean;
  designDocumentsList?: TransferListType[];
  handleClose: () => void;
  handleSaveList: (itemList) => void;
  getSetStatus?: (status: string) => ReactNode;
  relatedTabData?: TRelatedTabData;
  currentValue?: number[];
  requestsList?: any[];
  openedRequest?: PCDCard;
  handleOpenCard?: (request: FullRequestModel) => void;
  customClassname?: string;
  part?: string;
}> = ({
  type,
  popUpIsOpen,
  designDocumentsList,
  handleClose,
  getSetStatus,
  handleSaveList,
  currentValue,
  requestsList,
  openedRequest,
  handleOpenCard,
  customClassname,
  relatedTabData,
  part,
}) => {
  const themeClass = useGetThemeClass('b-manageStatuses');

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [itemList, setItemList] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isRowChanged, setIsRowChanged] = useState(false);

  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);
  const [rowsOrig, setRowsOrig] = useState(null);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [checkBoxRow, setCheckBoxRow] = useState(null);
  const [checkBoxAllRow, setCheckBoxAllRow] = useState(null);
  const [onSortColumn, setOnSortColumn] = useState<SortColumnType>(null);
  const [isGroupingRowIdExpand, setIsGroupingRowIdExpand] = useState<boolean>(false);

  const [itemTabData, setItemTabData] = useState(null);

  const outPopupRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setIsGroupingRowIdExpand(true);
  }, []);

  useEffect(() => {
    const select_columns = getColumns();
    setColumns(select_columns);

    if (type === 'Nf deliverable') {
      setItemTabData(relatedTabData.deliverable);
    }
    if (type === 'Submittal deliverable') {
      setItemTabData(relatedTabData[`deliverable_${part}`]);
    }
    if (type === 'Submittal request') {
      setItemTabData(relatedTabData[`request_${part}`]);
    }
    if (type === 'Deliverable request') {
      setItemTabData(relatedTabData.request);
    }
    if (type === 'Submittal document' || type === 'Nf document') {
      setItemTabData(relatedTabData.document);
    }
    if (type === 'Nf submittalInput') {
      setItemTabData(relatedTabData[`submittalInput_${part}`]);
    }
    if (type === 'Nf submittalOutput') {
      setItemTabData(relatedTabData[`submittalOutput_${part}`]);
    }
    if (type === 'Deliverable submittalOutput') {
      setItemTabData(relatedTabData[`submittalOutput_${part}`]);
    }
    if (type === 'Submittal submittalOutput') {
      setItemTabData(relatedTabData[`${part}`]);
    }
    if (type === 'Deliverable deliverable') {
      setItemTabData(relatedTabData.predecessor);
    }
  }, [relatedTabData]);

  const getStatusPosition = validity_status => {
    if (validity_status === 'not_verified') {
      return 1;
    }
    if (validity_status === 'not_valid') {
      return 2;
    }
    if (validity_status === 'valid') {
      return 3;
    }
    if (validity_status === 'new_valid') {
      return 4;
    }
    if (validity_status === 'ignore') {
      return 5;
    }
    return 0;
  };

  useEffect(() => {
    if (itemTabData) {
      const rowsAll = [];
      if (type === 'Nf deliverable' || type === 'Submittal deliverable') {
        itemTabData.forEach(item => {
          const obj = {
            id: item.id,
            title: item.title,
            revision: item.revision,
            validity_status: item.flags?.validity_status,
            validity_alert: item.flags?.validity_alert,
            position_status: getStatusPosition(item.flags?.validity_status),
            level: item.owner.title,
            building: item.building,
            process_status: item.status,
            review_state: item.review_state,
            snippet: item.snippet,
            level_id: item?.owner?.id,
            building_id: item?.owner?.building_id,
            revision_status: item?.status + ' ' + item?.review_state,
          };
          obj.isActive = false;
          rowsAll.push(obj);
        });
      }
      if (type === 'Deliverable deliverable') {
        itemTabData.forEach(item => {
          const obj = {
            id: item.id,
            title: item.flags.last_title,
            revision: item.flags.last_revision,
            validity_status: item.flags?.validity_status,
            validity_alert: item.flags?.validity_alert,
            position_status: getStatusPosition(item.flags?.validity_status),
            level: item.owner.title,
            process_status: item.flags.last_status,
            review_state: item.flags.last_review_state,
            snippet: item.snippet,
            level_id: item?.owner?.id,
            revision_status: item?.status + ' ' + item?.review_state,
          };
          obj.isActive = false;
          rowsAll.push(obj);
        });
        rowsAll.sort((a, b) => sortData(a.title, b.title, false, true));
      }
      if (type === 'Submittal request' || type === 'Deliverable request') {
        itemTabData.forEach(item => {
          const obj = {
            id: item.id,
            specific: item.specific,
            nf: item.nf,
            validity_status: item.flags.validity_status,
            validity_alert: item.flags.validity_alert,
            position_status: getStatusPosition(item.flags.validity_status),
            lastStatus: item.status,
            is_incorporated: type === 'Deliverable request' ? item.flags.is_incorporated : null,
            is_deactivated: item?.is_deactivated,
            snippet: item.snippet,
          };
          obj.isActive = false;
          rowsAll.push(obj);
        });
        rowsAll.sort((a, b) => sortData(a.nf, b.nf, true, true));
      }
      if (type === 'Submittal document' || type === 'Nf document') {
        itemTabData.forEach(item => {
          const obj = {
            id: item.id,
            title: item.title,
            number: item.number,
            revision: item.revision,
            validity_status: item.flags?.validity_status,
            position_status: getStatusPosition(item.flags?.validity_status),
            current_set: item.current_set,
            building: item.building.map(item => item.title).join(', '),
            snippet: item.snippet,
          };
          obj.isActive = false;
          rowsAll.push(obj);
        });
      }
      if (
        type === 'Nf submittalInput' ||
        type === 'Nf submittalOutput' ||
        type === 'Deliverable submittalOutput' ||
        type === 'Submittal submittalOutput'
      ) {
        itemTabData.forEach(item => {
          const obj = {
            id: item.id,
            title: item.title,
            validity_status: item.flags.validity_status,
            validity_alert: item.flags.validity_alert,
            position_status: getStatusPosition(item.flags.validity_status),
            for_card: item.for_card,
            mf_code: item.mf_code,
            review_state: item.review_state,
            sf: item.sf,
            type: item.type,
            version: item.version,
            status: item.status,
            snippet: item.snippet,
            submittal: `#${item.mf_code} - ${item?.sf} (R${item?.version})`,
            status_submittal: item.status.title,
          };
          obj.isActive = false;
          rowsAll.push(obj);
        });
        rowsAll.sort((a, b) => sortData(a.submittal, b.submittal, false, true));
      }
      rowsAll.sort((a, b) => a.position_status - b.position_status);
      setRowsOrig(rowsAll);
      setRows(rowsAll);
    }
  }, [itemTabData]);

  useEffect(() => {
    if (type === 'designInput') {
      if (designDocumentsList) {
        const newRows = designDocumentsList?.map(item => {
          const obj = { ...item };
          obj.building = item.building_ids.map(build => build.title).join(', ');
          obj.validity_status = item.status;
          obj.position_status = getStatusPosition(item.status);
          obj.validity_alert = item.alert;
          obj.building_id = item.building_ids?.length ? item.building_ids.map(build => build.id)[0] : 0;
          return obj;
        });
        newRows.sort((a, b) => sortData(a.number, b.number, false, true));
        newRows.sort((a, b) => a.position_status - b.position_status);
        setItemList(newRows);
      }
    }

    if (type === 'deliverables') {
      if (currentValue) {
        const newDelList = [];
        currentValue.forEach(deliverableId => {
          let preselectedDel: IDeliverables;
          if (relatedTabData && relatedTabData.deliverable) {
            preselectedDel = relatedTabData.deliverable.find(item => item.id === deliverableId);
          }
          if (preselectedDel) {
            newDelList.push({
              ...preselectedDel,
              level: preselectedDel?.owner?.title,
              validity_status: preselectedDel?.flags?.validity_status,
              validity_alert: preselectedDel?.flags?.validity_alert,
              process_status: preselectedDel?.status,
              review_state: preselectedDel?.review_state,
              level_id: preselectedDel?.owner?.id,
              building_id: preselectedDel?.owner?.building_id,
              position_status: getStatusPosition(preselectedDel?.flags?.validity_status),
              revision_status: preselectedDel?.status + ' ' + preselectedDel?.review_state,
            });
          }
        });
        newDelList.sort((a, b) => a.position_status - b.position_status);
        setItemList(newDelList);
      }
    }

    if (type === 'pcdCardLinkedNfsTab') {
      if (requestsList) {
        const newRequestsList = requestsList.map(item => {
          const obj = { ...item };
          obj.position_status = getStatusPosition(item.validity_status);
          return obj;
        });
        const newRequestsListPos = [];
        const uniqPos = Array.from(new Set(newRequestsList.map(item => item.position_status)));
        uniqPos.sort((a, b) => a - b);
        uniqPos.forEach(pos => {
          const posArr = newRequestsList.filter(item => item.position_status === pos);
          posArr.sort((a, b) => a.nf - b.nf);
          posArr.forEach(item => {
            newRequestsListPos.push(item);
          });
        });
        setItemList(newRequestsListPos);
      }
    }
  }, [designDocumentsList, currentValue, relatedTabData, requestsList]);

  const handleClickCheckBox = row => {
    setIsGroupingRowIdExpand(false);
    setCheckBoxRow(row);
  };

  const handleClickCheckBoxHeader = () => {
    setIsGroupingRowIdExpand(false);
    setCheckBoxAllRow(true);
  };

  const renderHeaderCell = p => {
    if (p.column.key === 'selected_row') {
      return (
        <div>
          <CheckBox isTripleState important={p.column.isActiveBox || 0} onClick={handleClickCheckBoxHeader} />
        </div>
      );
    }

    return (
      <div
        className={`${themeClass}_manageHeaderCell`}
        onClick={() => handleOnSort(p.column.order_column, p.column?.order_dir, p.column?.order_dir_type)}
      >
        {p.column.name}
        {p.column?.order_dir && (
          <div className={`${themeClass}_manageHeaderCell_arrowContainer`}>
            <TokenIcon iconName={p.column.order_dir == 'asc' ? 'arrow-down' : 'arrow-up'} size={16} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (checkBoxAllRow) {
      const result = rows?.length ? [...rows] : [];
      const header = columns?.length ? [...columns] : [];
      const rowActive = result.filter(item => item.isActive);
      const columnsBoxActive = header.findIndex(item => item.key == 'selected_row');
      if (!rowActive.length) {
        header[columnsBoxActive].isActiveBox = 2;
        const newRows = result.map(item => {
          const obj = { ...item };
          obj.isActive = true;
          return obj;
        });
        setColumns(header);
        setRows(newRows);
        setCheckedItems(newRows);
      } else {
        header[columnsBoxActive].isActiveBox = 0;
        const newRows = result.map(item => {
          const obj = { ...item };
          obj.isActive = false;
          return obj;
        });
        setColumns(header);
        setRows(newRows);
        setCheckedItems([]);
      }
      setCheckBoxAllRow(false);
    }
  }, [checkBoxAllRow]);

  useEffect(() => {
    if (checkBoxRow) {
      const result = rows?.length ? [...rows] : [];
      const findIndex = result.findIndex(item => item.id === checkBoxRow.id);
      const header = columns?.length ? [...columns] : [];
      const columnsBoxActive = header.findIndex(item => item.key == 'selected_row');
      if (findIndex > -1) {
        result[findIndex].isActive = !result[findIndex].isActive;
      }
      const rowActive = result.filter(item => item.isActive);
      header[columnsBoxActive].isActiveBox = result.length === rowActive.length ? 2 : !rowActive.length ? 0 : 1;
      setColumns(header);
      setRows(result);
      setCheckBoxRow(null);
      setCheckedItems(rowActive);
    }
  }, [checkBoxRow]);

  const getSelectedColumns = () => {
    if (type === 'designInput') {
      return columnsDesignInput;
    }
    if (type === 'deliverables') {
      return columnsDeliverables;
    }
    if (type === 'pcdCardLinkedNfsTab') {
      return columnsPcdCardLinkedNfsTab;
    }
    if (type === 'Nf deliverable' || type === 'Submittal deliverable') {
      return columnsDeliverables;
    }
    if (type === 'Deliverable deliverable') {
      return columnsDeliverablesForDeliverables;
    }
    if (type === 'Submittal request') {
      return columnsRequestTab;
    }
    if (type === 'Deliverable request') {
      return columnsDeliverableRequest;
    }
    if (type === 'Submittal document' || type === 'Nf document') {
      return columnsDesignInput;
    }
    if (
      type === 'Nf submittalInput' ||
      type === 'Nf submittalOutput' ||
      type === 'Deliverable submittalOutput' ||
      type === 'Submittal submittalOutput'
    ) {
      return columnsSubmittalsTab;
    }
    return [];
  };

  const getColumns = () => {
    const result = [];
    const selectColumns = getSelectedColumns();
    selectColumns.forEach(col => {
      const resultingColumn = {
        key: col.key,
        name: col.name,
        resizable: false,
        formatter: props => (
          <BasicFormatter
            {...props}
            col={col}
            handleOpenCard={handleOpenCard}
            openedRequest={openedRequest}
            handleClickCheckBox={handleClickCheckBox}
            type={type}
          />
        ),
      };

      if (col.width) {
        resultingColumn.width = col.width;
      }
      if (col.minWidth) {
        resultingColumn.minWidth = col.minWidth;
      }
      if (col.maxWidth) {
        resultingColumn.maxWidth = col.maxWidth;
      }
      if (col.isHeaderRenderer) {
        resultingColumn.headerRenderer = p => renderHeaderCell(p);
      }
      if (col.order_column) {
        resultingColumn.order_column = col.order_column;
      }
      if (col.order_column) {
        resultingColumn.order_dir = col.order_dir;
      }
      if (col.order_column) {
        resultingColumn.order_dir_type = col.order_dir_type;
      }
      if (col?.isActiveBox) {
        resultingColumn.isActiveBox = col.isActiveBox;
      }

      result.push(resultingColumn);
    });

    return result;
  };

  const handleOnSort = (order_column, order_dir, order_dir_type) => {
    setIsGroupingRowIdExpand(false);
    if (!order_dir_type) {
      return;
    }
    const getOrderDir = orderDir => {
      if (orderDir === 'asc') {
        return 'desc';
      }
      if (orderDir === 'desc') {
        return '';
      }
      if (orderDir === '') {
        return 'asc';
      }
    };
    const obj = {
      order_column: order_column,
      order_dir: getOrderDir(order_dir),
      isNumber: order_dir_type === 'by number' ? true : false,
    };
    setOnSortColumn(obj);
  };

  const sortData = (a, b, isNumber, isAsc) => {
    if (isNumber) {
      return isAsc ? a - b : b - a;
    } else {
      const nameA = a ? a.toLowerCase() : '';
      const nameB = b ? b.toLowerCase() : '';
      if ((isAsc && nameA < nameB) || (!isAsc && nameB < nameA)) return -1;
      if ((isAsc && nameA > nameB) || (!isAsc && nameB < nameA)) return 1;
      return 0;
    }
  };

  useEffect(() => {
    if (!rows) {
      return;
    }
    const sortRows = [...rows];
    const sortColumns = [...columns].map(item => {
      const obj = { ...item };
      if (onSortColumn.order_column !== item.order_column && item.order_dir_type) {
        obj.order_dir = '';
      }
      if (onSortColumn.order_column === item.order_column) {
        obj.order_dir = onSortColumn.order_dir;
      }
      return obj;
    });

    if (onSortColumn?.order_dir !== '') {
      sortRows.sort((a, b) =>
        sortData(a[onSortColumn.order_column], b[onSortColumn.order_column], onSortColumn.isNumber, onSortColumn?.order_dir === 'asc'),
      );
      sortRows.sort((a, b) => a.position_status - b.position_status);
      setRows(sortRows);
    } else {
      setRows(rowsOrig);
    }
    setColumns(sortColumns);
  }, [onSortColumn]);

  useEffect(() => {
    const select_columns = getColumns();
    if (type === 'designInput' || type === 'deliverables' || type === 'pcdCardLinkedNfsTab') {
      setColumns(select_columns);
    }
  }, [itemList]);

  useEffect(() => {
    if (itemList) {
      const rowsAll = [];
      itemList.forEach(item => {
        const obj = { ...item };
        obj.isActive = false;
        rowsAll.push(obj);
      });
      setRowsOrig(rowsAll);
      setRows(rowsAll);
    }
  }, [itemList]);

  useEffect(() => {
    const arr = [];
    selectedRows.forEach(item => arr.push(item));
    setEditingRowIds(arr);
  }, [selectedRows]);

  const handleChangeNewStatus = status => {
    const allRows = [...rows];
    const newRows = allRows.map(item => {
      if (item.isActive) {
        return { ...item, validity_status: status, position_status: getStatusPosition(status) };
      }
      return item;
    });
    const checkedItemsCopy = newRows.filter(item => item.isActive);
    setCheckedItems(checkedItemsCopy);
    setIsRowChanged(true);
    newRows.sort((a, b) => a.position_status - b.position_status);
    setRows(newRows);
  };

  const rowKeyGetter = row => {
    return row.id;
  };

  const renderHeaderTitle = () => {
    if (type === 'designInput') {
      return 'Verify impact of <b>Drawings changes</b> on <b>this Need Form</b>';
    }
    if (type === 'deliverables') {
      return 'Verify impact of this NF on Deliverables';
    }
    if (type === 'pcdCardLinkedNfsTab') {
      return 'Verify impact of <b>Need Forms changes</b> on <b>this Deliverable</b>';
    }
    if (type === 'Deliverable request') {
      return 'Verify impact of <b>Need Forms changes</b> on <b>this Deliverable</b>';
    }
    if (type === 'Deliverable deliverable') {
      return 'Verify impact of <b>other Deliverables</b> on <b>this Deliverable</b>';
    }
    if (type === 'Deliverable submittalOutput') {
      return 'Verify impact of <b>Submittals changes</b> on <b>this Deliverable</b>';
    }
    if (type === 'Submittal submittalOutput') {
      return 'Verify impact of <b>other Submittals</b> on <b>this Submittal</b>';
    }
    if (type === 'Nf deliverable') {
      return 'Verify impact of <b>this Need Form</b> on <b>Deliverables</b>';
    }
    if (type === 'Nf document') {
      return 'Verify impact of Design Documents changes on this Need Form';
    }
    if (type === 'Nf submittalInput') {
      return 'Verify impact of <b>Submittals changes</b> on <b>this Need Form</b>';
    }
    if (type === 'Nf submittalOutput') {
      return 'Verify impact of <b>this Need Forms</b> on <b>Submittals</b>';
    }
    if (type === 'Submittal request') {
      return 'Verify impact of <b>Need Forms changes</b> on <b>this Submittal</b>';
    }
    if (type === 'Submittal deliverable') {
      return 'Verify impact of <b>selected Deliverables</b> on <b>this Submittal</b>';
    }
    if (type === 'Submittal document') {
      return 'Verify impact of Design Documents changes on this Submittal';
    }
  };

  const renderActionText = () => {
    if (type === 'designInput') {
      return 'Mark <b>this Need Form</b> per <b>selected Drawings</b> as:';
    }
    if (type === 'deliverables') {
      return 'Mark selected Deliverables per this NF as:';
    }
    if (type === 'pcdCardLinkedNfsTab') {
      return 'Mark <b>this Deliverable</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Deliverable request') {
      return 'Mark <b>this Deliverable</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Deliverable deliverable') {
      return 'Mark <b>this Deliverable</b> per <b>selected Deliverables</b> as:';
    }
    if (type === 'Deliverable submittalOutput') {
      return 'Mark <b>this Deliverable</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Submittal submittalOutput') {
      return 'Mark <b>this Submittal</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Nf deliverable') {
      return 'Mark <b>selected Deliverables</b> per <b>this Need Form</b> as:';
    }
    if (type === 'Nf document') {
      return 'Mark this NF per selected Design Documents as:';
    }
    if (type === 'Nf submittalInput') {
      return 'Mark <b>this Need Form</b> per <b>selected Submittals</b> as:';
    }
    if (type === 'Nf submittalOutput') {
      return 'Mark <b>selected Submittals</b> per <b>this Need Form</b> as:';
    }
    if (type === 'Submittal request') {
      return 'Mark <b>this Submittal</b> per <b>selected Need Forms</b> as:';
    }
    if (type === 'Submittal deliverable') {
      return 'Mark <b>this Submittal</b> per <b>selected Deliverables</b> as:';
    }
    if (type === 'Submittal document') {
      return 'Mark this Submittal per selected Design Documents as:';
    }
  };

  const renderToolTip = (isACTUAL: boolean) => {
    if (type === 'deliverables' || type === 'Nf deliverable') {
      if (isACTUAL) {
        return 'Mark Deliverables as ACTUAL if they’re consistent with the information contained in this Need Form.';
      } else {
        return 'Mark Deliverables as OUTDATED if they’re no longer consistent with the information contained in this Need Form.';
      }
    }
    if (type === 'Nf document') {
      if (isACTUAL) {
        return 'Mark Drawings as ACTUAL if this Need Form is consistent with their latest versions.';
      } else {
        return 'Mark Drawings as OUTDATED if this Need Form is no longer consistent with their latest version.';
      }
    }
    if (type === 'designInput') {
      if (isACTUAL) {
        return 'Mark Drawings as VALID if the new design documents set did not impact this Need Form.';
      } else {
        return 'Mark Drawings as VOID if the new design documents set made this Need Form outdated.';
      }
    }
    if (type === 'Nf submittalInput' || type === 'Nf submittalOutput') {
      if (isACTUAL) {
        return 'Mark Submittals as ACTUAL if this Need Form is consistent with the information contained in them.';
      } else {
        return 'Mark Submittals as OUTDATED if this Need Form is no longer consistent with the information contained in them.';
      }
    }
    if (type === 'Deliverable request' || type === 'pcdCardLinkedNfsTab') {
      if (isACTUAL) {
        return 'Mark Need Forms as ACTUAL if this Deliverable is consistent with the information contained in them.';
      } else {
        return 'Mark Need Forms as OUTDATED if this Deliverable is no longer consistent with the information contained in them.';
      }
    }
    if (type === 'Deliverable deliverable') {
      if (isACTUAL) {
        return 'Mark this successor Deliverable as ACTUAL if it is consistent with the information contained in selected predecessor Deliverables.';
      } else {
        return 'Mark this successor Deliverable as OUTDATED if it is no longer consistent with the information contained in selected predecessor Deliverables.';
      }
    }
    if (type === 'Deliverable submittalOutput') {
      if (isACTUAL) {
        return 'Mark Submittals as ACTUAL if this Deliverable is consistent with the information contained in them.';
      } else {
        return 'Mark Submittals as OUTDATED if this Deliverable is no longer consistent with the information contained in them.';
      }
    }
    if (type === 'Submittal submittalOutput') {
      if (isACTUAL) {
        return 'Mark this successor Submittal as ACTUAL if it is consistent with the information contained in selected predecessor Submittals.';
      } else {
        return 'Mark this successor Submittal as OUTDATED if it is no longer consistent with the information contained in selected predecessor Submittals.';
      }
    }
    if (type === 'Submittal request') {
      if (isACTUAL) {
        return 'Mark this Submittal as ACTUAL if it is consistent with the information contained in selected predecessor Need Forms.';
      } else {
        return 'Mark this Submittal as OUTDATED if it is no longer consistent with the information contained in selected predecessor Need Forms.';
      }
    }
    if (type === 'Submittal deliverable') {
      if (isACTUAL) {
        return 'Mark this Submittal as ACTUAL if it is consistent with the information contained in selected predecessor Deliverables.';
      } else {
        return 'Mark this Submittal as OUTDATED if it is no longer consistent with the information contained in selected predecessor Deliverables.';
      }
    }
    if (type === 'Submittal document') {
      if (isACTUAL) {
        return 'Mark Drawings as ACTUAL if this Submittal is consistent with the information contained in them.';
      } else {
        return 'Mark Drawings as OUTDATED if this Submittal is no longer consistent with the information contained in them.';
      }
    }
    if (type === 'Submittal request') {
      if (isACTUAL) {
        return 'Mark Need Forms as ACTUAL if they’re consistent with the information contained in this Submittal.';
      } else {
        return 'Mark Need Forms as OUTDATED if they’re no longer consistent with the information contained in this Submittal.';
      }
    }
    if (type === 'Submittal deliverable') {
      if (isACTUAL) {
        return 'Mark Deliverables as ACTUAL if they’re consistent with the information contained in this Submittal.';
      } else {
        return 'Mark Deliverables as OUTDATED if they’re no longer consistent with the information contained in this Submittal.';
      }
    }
    if (type === 'Submittal document') {
      if (isACTUAL) {
        return 'Mark Drawings as ACTUAL if this Submittal is consistent with the information contained in them.';
      } else {
        return 'Mark Drawings as OUTDATED if this Submittal is no longer consistent with the information contained in them.';
      }
    }
    if (type === 'Submittal request') {
      if (isACTUAL) {
        return 'Mark Need Forms as ACTUAL if they’re consistent with the information contained in this Submittal.';
      } else {
        return 'Mark Need Forms as OUTDATED if they’re no longer consistent with the information contained in this Submittal.';
      }
    }
    if (type === 'Submittal deliverable') {
      if (isACTUAL) {
        return 'Mark Deliverables as ACTUAL if they’re consistent with the information contained in this Submittal.';
      } else {
        return 'Mark Deliverables as OUTDATED if they’re no longer consistent with the information contained in this Submittal.';
      }
    }
    if (type === 'Submittal document') {
      if (isACTUAL) {
        return 'Mark Drawings as ACTUAL if this Submittal is consistent with the information contained in them.';
      } else {
        return 'Mark Drawings as OUTDATED if this Submittal is no longer consistent with the information contained in them.';
      }
    }
  };

  const getGroupingRowValue = (row, columnKey): string | null => {
    switch (columnKey) {
      case 'validity_status':
        if (row?.validity_status === 'not_verified') {
          return 'not_verified';
        }
        if (row?.validity_status === 'not_valid') {
          return type === 'designInput' ? 'void' : 'not_valid';
        }
        if (row?.validity_status === 'new_valid') {
          return 'valid';
        }
        if (row?.validity_status === 'ignore') {
          return 'ignore';
        }
        if (row?.validity_status === 'valid') {
          return 'valid';
        } else {
          return `not status`;
        }

      default:
        return null;
    }
  };

  return (
    <>
      <DialogPopUp
        open={!!popUpIsOpen}
        aria-labelledby="Manage statuses"
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
        hideBackdrop={false}
        onBackdropClick={handleClose}
        onEscapeKeyDown={handleClose}
        customClassname={`${themeClass}_dialogPopUp`}
      >
        <div
          className={cn(`${themeClass}`, {
            [`${customClassname}`]: customClassname,
          })}
          ref={outPopupRef}
        >
          <div className={`${themeClass}_headerBlock`}>
            <div className={`${themeClass}_headerTitle`}>{ReactHtmlParser(renderHeaderTitle())}</div>
            <div className={`${themeClass}_closeIcon`} onClick={handleClose}>
              <SystemButton type={'close'} size={'md'} variant={'transparent'} />
            </div>
          </div>
          <div
            className={cn(`${themeClass}_table`, {
              ['pcdCardLinkedNfsTab']: type === 'pcdCardLinkedNfsTab',
            })}
          >
            <DataGridBasic
              rowKeyGetter={rowKeyGetter}
              columns={columns || []}
              rows={rows || []}
              headerRowHeight={32}
              rowHeight={32}
              editingRowIds={editingRowIds}
              isAccess={true}
              // setEditingRowsAction={({ editingRowIds }) => handleSetSelectedRows(editingRowIds)}
              onRowsChange={setRows}
              isBulkEditMode={false}
              isDeletable={false}
              maxWidthEditColumn={32}
              isManageStatusesPopUp={true}
              selectedGroupingValues={['validity_status']}
              getGroupingRowValue={getGroupingRowValue}
              customHeaderRenderer={true}
              setGroupingRowIdExpand={isGroupingRowIdExpand && ['validity_status__not_verified']}
            />
          </div>
          <div className={`${themeClass}_actionContainer`}>
            <div className={`${themeClass}_actionContainer_title`}>{ReactHtmlParser(renderActionText())}</div>
            <div className={`${themeClass}_actionContainer_info`}>
              {type === 'designInput' && (
                <CustomButton
                  icon={<TokenIcon iconName={'cancel-right'} size={16} />}
                  type={'text-plain'}
                  size={'md'}
                  clickHandler={() => handleChangeNewStatus('ignore')}
                  title={'Ignore'}
                  disabled={!checkedItems.length}
                />
              )}
              <CustomButton
                icon={<TokenIcon iconName={type === 'designInput' ? 'validDDL' : 'valid'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={() => handleChangeNewStatus('valid')}
                title={type === 'designInput' ? 'Valid' : 'Actual'}
                disabled={!checkedItems.length}
                tooltip={renderToolTip(true)}
              />
              <CustomButton
                icon={<TokenIcon iconName={type === 'designInput' ? 'void' : 'not-valid'} size={16} />}
                type={'text-plain'}
                size={'md'}
                clickHandler={() => handleChangeNewStatus('not_valid')}
                title={type === 'designInput' ? 'Void' : 'Outdated'}
                disabled={!checkedItems.length}
                tooltip={renderToolTip(false)}
              />
            </div>
          </div>
          <div className={`${themeClass}_footerBlock`}>
            <CustomButton type={'tertiary'} size={'md'} clickHandler={handleClose} title={'Cancel'} />
            <CustomButton type={'primary'} size={'md'} clickHandler={() => handleSaveList(rows)} title={'Save'} disabled={!isRowChanged} />
          </div>
        </div>
      </DialogPopUp>
    </>
  );
};

export default React.memo(ManageStatuses);
