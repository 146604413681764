import React from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import CheckBox from '../../../../controls/CheckBox/CheckBox';
import ChipsStandardTag from '../../../../controls/Chips/ChipsStandardTag/ChipsStandardTag';
import { SubmittalListItemsInterface } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';

import './SubmittalsItemStyles.scss';

interface IProps {
  submittal: SubmittalListItemsInterface;
  handleSubmittalsItemClick?: (event: React.SyntheticEvent, submittal: SubmittalListItemsInterface) => void;
  isActiveCheckBoxForDisabledItems?: boolean;
}

const SubmittalsItem: React.FC<IProps> = ({ submittal, handleSubmittalsItemClick, isActiveCheckBoxForDisabledItems }) => {
  const themeClass = useGetThemeClass('b-submittalsItem');

  return (
    <>
      <div className={`${themeClass}_item`} onClick={e => handleSubmittalsItemClick(e, submittal)}>
        <CheckBox checked={submittal.selected || isActiveCheckBoxForDisabledItems} />
        <ChipsStandardTag value={`${submittal.sf_index || ''} ${submittal.title ? `- ${submittal.title}` : ''}`} isAlignTextLeft />
        {submittal?.reflect_input === 1 && (
          <CustomTooltip title={'Output submittal reflected as input data'} placement={'top'}>
            <div className={`${themeClass}_item_icon`}>
              <TokenIcon iconName={'mirror'} size={16} />
            </div>
          </CustomTooltip>
        )}
      </div>
    </>
  );
};

export default SubmittalsItem;
