import { Popover } from '@material-ui/core';
import React from 'react';
import ArrowDownload from '@material-ui/icons/ArrowDownwardRounded';
import connect from 'react-redux/es/connect/connect';
import { API_URI, DOMAIN_URI } from '../../../../../service/links';
import fileDownload from 'js-file-download';
import commonApi from '../../../../../service/Api/commonApi';
import Preview from '../../../../controls/Preview/Preview';
import LoaderView from '../../../../controls/Loader/LoaderView';
import SVG from 'react-inlinesvg';
import FileHelper from '../../../../../helpers/FileHelper';

class PhasePlanDownload extends React.Component<any, any> {
  state = {
    selectedPrevFile: null,
    fileLoaded: false,
  };

  handleDownloadVersion = (id: number, title: string, event) => {
    event.stopPropagation();
    const url: URL = new URL(`${DOMAIN_URI}${API_URI}phase-plan/download-pdf/${id}`);
    this.setState({ fileLoaded: true });
    commonApi.getFile(url.href).then(response => {
      this.setState({ fileLoaded: false });
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);
    });
  };

  handleOpenPreview = (id: number, title: string) => {
    const url: URL = new URL(`${DOMAIN_URI}${API_URI}phase-plan/download-pdf/${id}`);
    this.setState({ fileLoaded: true });
    commonApi.getFile(url.href).then(response => {
      this.setState({
        selectedPrevFile: URL.createObjectURL(response.data),
        isPDF: response.data.type.indexOf('pdf') + 1,
        fileLoaded: false,
      });
    });
  };

  handleClosePreview = () => {
    this.setState({
      selectedPrevFile: null,
      isPDF: null,
    });
  };

  handlePrevVersion = (e, item) => {
    e.stopPropagation();
    this.props.handlePrevVersion(item);
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.phasePlanDownloadStatus.loaded && this.props.phasePlanDownloadStatus !== prevProps.phasePlanDownloadStatus) {
      this.props.handleClose();
    }
  }

  render() {
    const { anchorEl, handleClose, items } = this.props;

    const { selectedPrevFile, fileLoaded } = this.state;

    return (
      <>
        {fileLoaded && <LoaderView />}
        <Popover
          id={`${'kickOffDate'}`}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={'filter__listFilters'}>
            {items?.length &&
              items.map(item => {
                return (
                  <div
                    key={item.id}
                    className={'filter__itemCheckBox b-phasePlan__filterItem'}
                    onClick={e => this.handleOpenPreview(item.id, item.title)}
                  >
                    <div>
                      <ArrowDownload onClick={e => this.handleDownloadVersion(item.id, item.title, e)} />
                      {item.title}
                    </div>
                    <span onClick={e => this.handlePrevVersion(e, item)}>
                      <SVG src={require('../../../../../assets/icons/undo.svg')} />
                    </span>
                  </div>
                );
              })}
          </div>
          <Preview selectedPrevFile={selectedPrevFile} onClose={this.handleClosePreview} isPDF />
        </Popover>
      </>
    );
  }
}

const mapStateToProps = ({ phasePlan }) => {
  return {
    phasePlanDownloadStatus: phasePlan.phasePlanDownloadStatus,
  };
};

export default connect(mapStateToProps, null)(PhasePlanDownload);
