import React from 'react';
import { ICardBreadcrumbsProps } from './CardBreadcrumbs.props';
import { useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../controls/Breadcrumbs';
import { PCDApi } from '../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { ActivationTabType, RequestModel } from '../../../models';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import { DeliverableCardTabNamesModel } from '../CardPcd/constans/constants';
import { ISubmittal } from '../../../models/submittals/submittals.model';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';
import useRouter from '../../../hooks/useRouter/useRouter';

const CardBreadcrumbs = ({
  breadcrumbs,
  selectedRequest,
  openRequest,
  isRequestLoading,
  isCurrentRevision,
  changeRevision,
  deactivateRevisionCreation,
  setNfLinkOpenId,
  isPcdCardPopUp,
  isPullRequest,
  closePullRequest,
  isMobile,
  isSidePeek,
  isTruncated,
  truncateAfterNumber,
  previewCard,
}: ICardBreadcrumbsProps) => {
  const dispatch = useDispatch();
  const { searchParams } = useRouter();
  const { setUrlNF, setUrlPCD, setUrlSubmittal, setUrlPreviewNF } = useRoutingHelper();
  const isBackToCurrentRevision: boolean = selectedRequest && !isCurrentRevision;
  const lastRevisionId = selectedRequest?.revisions?.length && selectedRequest.revisions.at(-1).id;

  const switchCard = breadcrumb => {
    if (isPullRequest) {
      closePullRequest();
    }

    if (breadcrumb.id !== selectedRequest.id && !isRequestLoading) {
      const { clearBreadcrumbs, updateActiveTabBreadcrumbs } = breadcrumbsSlice.actions;
      const tabParam = searchParams.get('tab') as DeliverableCardTabNamesModel | ActivationTabType;
      const typeBreadcrumbs = previewCard ? 'previewCardBreadcrumbs' : 'firstCardBreadcrumbs';

      if (breadcrumbs?.length > 1) {
        dispatch(updateActiveTabBreadcrumbs({ typeBreadcrumbs, data: { id: selectedRequest?.id, tab: tabParam } }));
      }

      if (breadcrumb.breadcrumbType === 'nf') {
        if (previewCard) {
          setUrlPreviewNF(String(breadcrumb.nf), String(breadcrumb.id), breadcrumb?.activeTab || 'request');
        } else {
          setUrlNF(String(breadcrumb.nf), breadcrumb?.activeTab || 'request');
        }

        breadcrumbs?.length === 1 && dispatch(clearBreadcrumbs({ typeBreadcrumbs }));
      }

      if (breadcrumb.breadcrumbType === 'pcd') {
        setUrlPCD(breadcrumb.id, breadcrumb.buildingId, breadcrumb?.activeTab || 'deliverable');
      }

      if (breadcrumb.breadcrumbType === 'submittal') {
        setUrlSubmittal(breadcrumb.id, breadcrumb?.activeTab || 'general');
      }
    }
  };

  const handleBackToCurrentOnClick = () => {
    changeRevision(lastRevisionId);
    deactivateRevisionCreation();
  };

  return (
    <>
      <Breadcrumbs<PCDCard | RequestModel | ISubmittal>
        breadcrumbs={breadcrumbs}
        isPcdCardPopUp={isPcdCardPopUp}
        isBackToCurrentRevision={isBackToCurrentRevision}
        breadcrumbOnClick={switchCard}
        handleBackToCurrentOnClick={handleBackToCurrentOnClick}
        isBreadcrumbLoading={isRequestLoading}
        selectedBreadcrumb={selectedRequest}
        isSidePeek={isSidePeek}
        isMobile={isMobile}
        isTruncated={isTruncated}
        truncateAfterNumber={truncateAfterNumber}
      />
    </>
  );
};

export default CardBreadcrumbs;
