import React, { FC } from 'react';
import { requestSlice } from '../../../../store/request/requestReducer(HOLD)';
import TokenIcon from '../../../controls/TokenIcon/TokenIcon';
import { useGetThemeClass } from '../../../../helpers/designTokens';
import { useAppDispatch, useAppSelector } from '../../../../store/configure/configureStore';
import { GetResolution } from '../../../../helpers/ScreenResolution/GetResolution';
import useRoutingHelper from '../../../../hooks/useRoutingHelper/useRoutingHelper';

import '../ListItem/AsideListItemStyles.scss';

const { setNewNfCard } = requestSlice.actions;

interface IProps {
  onClick: () => void;
}

const GlobalNewRequestCard: FC<IProps> = ({ onClick }) => {
  const dispatch = useAppDispatch();
  const { isMobile } = GetResolution();
  const themeClass = useGetThemeClass('b-aside-list-item');
  const { removeParams } = useRoutingHelper();

  const userData = useAppSelector(state => state.userReducer.userInfo);

  const handleOpenCreateRequest = () => {
    if (!userData?.projects?.length) {
      return;
    }

    removeParams();
    dispatch(setNewNfCard());
    onClick();
  };

  return (
    <div className={`${themeClass}_wrapBtnRequest`}>
      <div
        className={`${themeClass}_btnRequest ${!userData?.projects?.length ? '-disabled' : ''}`}
        id="newNF-tourBlock"
        onClick={handleOpenCreateRequest}
      >
        {isMobile ? <TokenIcon iconName={'plus'} size={32} /> : <span>New NF</span>}
      </div>
    </div>
  );
};

export default GlobalNewRequestCard;
