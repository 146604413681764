import Api from '../../service/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISupportReducer, ISupportWhatNew } from './supportReducer.model';
import { getQuickTour, getWhatNews, setReadAllNewsStatus, setReadNewsStatus } from './supportThunk';
import { KeyValueNumberModel } from '../../models/key-value.model';

const initialState: ISupportReducer = {
  getGettingStartedStatus: Api.initialStatus,
  getWhatNewsStatus: Api.initialStatus,
  getQuickTourStatus: Api.initialStatus,
  gettingStarted: {} /**is RIP */,
  getWhatNews: {},
  helpMenuState: false,
  helpSubMenuToOpen: '',
  loadReadStatus: false,
  isRunQuickTourNewUser: false,
  isRunSandboxQuickTour: false,
};

export const supportSlice = createSlice({
  initialState,
  name: 'supportReducer',
  reducers: {
    handleHelpMenu(state, { payload }: PayloadAction<{ helpState: boolean }>) {
      const helpState = payload ? payload.helpState : !state.helpMenuState;

      state.helpMenuState = helpState;
    },
    handleHelpSubMenu(state, { payload }: PayloadAction<string>) {
      state.helpSubMenuToOpen = payload;
    },
    handleRunQuickTourNewUser(state, { payload }: PayloadAction<boolean>) {
      state.isRunQuickTourNewUser = payload;
    },
    handleRunSandBoxQuickTour(state, { payload }: PayloadAction<boolean>) {
      state.isRunSandboxQuickTour = payload;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getQuickTour
       */
      .addCase(getQuickTour.pending, state => {
        state.getQuickTourStatus = Api.requestStatus;
      })
      .addCase(getQuickTour.fulfilled, (state, { payload }: PayloadAction<null>) => {
        state.getQuickTourStatus = Api.successStatus;
      })
      .addCase(getQuickTour.rejected, (state, { error }) => {
        state.getQuickTourStatus = Api.failStatus;
      })
      /**
       * getWhatNews
       */
      .addCase(getWhatNews.pending, state => {
        state.getWhatNewsStatus = Api.requestStatus;
        state.getWhatNews = {};
      })
      .addCase(getWhatNews.fulfilled, (state, { payload }: PayloadAction<KeyValueNumberModel<ISupportWhatNew>>) => {
        state.getWhatNewsStatus = Api.successStatus;
        state.getWhatNews = payload;
      })
      .addCase(getWhatNews.rejected, (state, { error }) => {
        state.getWhatNewsStatus = Api.failStatus;
        state.getWhatNews = {};
      })
      /**
       * setReadNewsStatus
       */
      .addCase(setReadNewsStatus.pending, state => {
        state.loadReadStatus = true;
      })
      .addCase(setReadNewsStatus.fulfilled, (state, { payload }: PayloadAction<ISupportWhatNew>) => {
        const getWhatNews = { ...state.getWhatNews };
        const newsIndex = Object.values(getWhatNews).findIndex(f => f.id === payload.id);
        if (newsIndex + 1) {
          getWhatNews[newsIndex] = { ...getWhatNews[newsIndex], ...payload };
        }

        state.loadReadStatus = false;
        state.getWhatNews = getWhatNews;
      })
      .addCase(setReadNewsStatus.rejected, (state, { error }) => {
        state.loadReadStatus = false;
      })
      /**
       * setReadAllNewsStatus
       */
      .addCase(setReadAllNewsStatus.pending, state => {
        state.loadReadStatus = true;
      })
      .addCase(setReadAllNewsStatus.fulfilled, state => {
        state.loadReadStatus = false;
      })
      .addCase(setReadAllNewsStatus.rejected, (state, { error }) => {
        state.loadReadStatus = false;
      });
  },
});

export default supportSlice.reducer;
