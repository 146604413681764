export const getFileSizeName = size => {
  if (size <= 1024) {
    return `${size} B`;
  }
  if (size > 1025 && size < 1024 * 1024) {
    return `${Math.floor(size / 1024)} KB`;
  }
  if (size > 1024 * 1024 && size < 1024 * 1024 * 1024) {
    return `${Math.floor(size / (1024 * 1024))} MB`;
  }
};

export const FILE_SIZE_LIMIT = 2e8;
