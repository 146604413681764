export class StatusModel {
  public title;
  public type;
  public active;
  public current;

  constructor(props) {
    this.title = props.title;
    this.type = props.type;
    this.active = props.active;
    this.current = props.current;
  }
}
