import React, { ChangeEvent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import PhasePlanView from './PhasePlanView';
import { UserReducerModel } from '../../../store/user/userReducerModel';
import {
  CreatePhasePlanFieldPayload,
  DeletePhasePlanFieldPayloadModel,
  PermissionModel,
  PhasePlanModel,
  PhasePlanUpdateModel,
  ProjectUpdatePayloadModel,
  SavePhasePlanVersionPayloadModel,
  ScheduleModel,
  SetDriverTasksPayloadModel,
  StatusModel,
  UpdateGMPPayloadModel,
} from '../../../models';
import moment from 'moment';
import LoaderView from '../../controls/Loader/LoaderView';
import { SelectedFieldModel } from './parts/TablePlan/TablePlanContainer';
import { MilestoneLegendModel } from '../../../models/phasePlan/milestone.model';
import LinearDeterminate from '../../controls/LinearDeterminate/LinearDeterminate';
import { getModuleVisibility } from '../../../helpers/ModuleVisibilityHelper';
import {
  createPhasePlanField,
  deletePhasePlanField,
  getPhasePlan,
  getPhasePlanSelectData,
  getPrevPhasePlan,
  savePhasePlanVersion,
  setDriverTasks,
  updateGMP,
  updatePhasePlan,
  updatePhasePlanField,
  updateProject,
} from '../../../store/phasePlan/phasePlanThunk';
import { phasePlanSlice } from '../../../store/phasePlan/phasePlanSlice';
import { savePhasePlan } from '../../../store/project/projectLogic';
import WipPage from '../WIPPage/WIPPage';
const { phasePlanChangeTable } = phasePlanSlice.actions;

interface IProps {
  activeProject: number;
  getPhasePlan: ({ projectId: number }) => {};
  getPhasePlanSelectData: () => void;
  userData: UserReducerModel;
  updatePhasePlan: (data: PhasePlanUpdateModel) => void;
  phasePlanData: PhasePlanModel;
  updatePhasePlanStatus: StatusModel;
  phasePlanPrevStatus: StatusModel;
  projectErrors: any;
  phasePlanChangeTable: any;
  phasePlanErrors: any;
  getPhasePlanStatus: StatusModel;
  getPhasePlanError: any;
  savePhasePlanVersion: (data: SavePhasePlanVersionPayloadModel) => void;
  phasePlanVersionStatus: StatusModel;
  initData: any;
  createPhasePlanField: (data: CreatePhasePlanFieldPayload) => void;
  updatePhasePlanField: (data: PermissionModel | ScheduleModel) => void;
  deletePhasePlanField: (data: DeletePhasePlanFieldPayloadModel) => void;
  updateGMP: (data: UpdateGMPPayloadModel) => void;
  updateProject: (data: ProjectUpdatePayloadModel) => void;
  getPrevPhasePlan: (id: number) => void;
  setDriverTasks: (data: SetDriverTasksPayloadModel[]) => void;
}

interface IState {
  isSavePhasePlan: boolean;
  isOpenCollapse: boolean;
  scrolledSide: string;
  editMode: boolean;
  anchorEl: any;
  formData: any;
  typeOfOpenedCalendar?: string;
  errors?: any;
  phasePlanErrors?: any;
  coordinationDriverMode: boolean;
  driverTasks: ScheduleModel[];
  applyCoordinationDriver: boolean;
  gmp: Date;
  hoverLegendMilestone: string;
  title: string;
  anchorElDownload: any;
  isDrag: boolean;
  past: StackActionModel[];
  currentStateIndex: number;
  currentPrevVersion: Object;
  isSettingsOpen: boolean;
  anchorElSettings: any;
  isSavingPhasePlan: boolean;
}

interface StackActionModel {
  data: any;
  selectedField: SelectedFieldModel;
}

class PhasePlanContainer extends React.Component<IProps, IState> {
  state = {
    wrapTableRef: React.createRef(),
    editableTableRef: React.createRef(),
    scrolledSide: 'schedule',
    isOpenCollapse: true,
    isSavePhasePlan: false,
    editMode: false,
    anchorEl: null,
    errors: {},
    typeOfOpenedCalendar: null,
    formData: {},
    phasePlanErrors: {},
    coordinationDriverMode: false,
    driverTasks: [],
    applyCoordinationDriver: false,
    gmp: null,
    hoverLegendMilestone: null,
    title: '',
    anchorElDownload: null,
    isDrag: false,
    past: [],
    currentStateIndex: 0,
    currentPrevVersion: null,
    isSettingsOpen: false,
    anchorElSettings: null,
    isSavingPhasePlan: false,
  };

  componentDidMount() {
    const { activeProject } = this.props;
    activeProject && this.props.getPhasePlan({ projectId: +activeProject });
    this.props.getPhasePlanSelectData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeProject } = this.props;
    if (this.props.activeProject != prevProps.activeProject && activeProject) {
      this.props.getPhasePlan({ projectId: +activeProject });
    }
    if (this.props.updatePhasePlanStatus.loaded && this.props.updatePhasePlanStatus !== prevProps.updatePhasePlanStatus) {
      this.setState({
        editMode: false,
      });
    }
    if (this.props.projectErrors !== prevProps.projectErrors) {
      this.setState({
        errors: this.props.projectErrors,
      });
    }
    if (this.props.phasePlanData !== prevProps.phasePlanData) {
      this.setState({
        formData: this.props.phasePlanData.project,
        gmp: this.props.phasePlanData.gmp,
      });
    }
    if (this.props.phasePlanVersionStatus.loaded && this.props.phasePlanVersionStatus !== prevProps.phasePlanVersionStatus) {
      this.handelDeclineSavePhasePlan();
    }
    if (this.props.phasePlanVersionStatus.loaded && this.props.phasePlanVersionStatus !== prevProps.phasePlanVersionStatus) {
      this.handelDeclineSavePhasePlan();
    }
  }

  handleCollapse = () => {
    this.setState({
      isOpenCollapse: !this.state.isOpenCollapse,
    });
  };

  handelSavePhasePlan = () => {
    this.setState({ isSavePhasePlan: true });
  };

  handelAcceptSavePhasePlan = () => {
    this.setState({ isSavingPhasePlan: true });
    this.props.savePhasePlanVersion({
      title: this.state.title,
      project_id: this.props.phasePlanData.project_id,
    });
  };

  handelDeclineSavePhasePlan = () => {
    this.setState({
      isSavingPhasePlan: false,
    });
    setTimeout(() => {
      this.setState({
        isSavePhasePlan: false,
      });
    }, 1500);
  };

  handleEditPhasePlan = () => {
    const { editMode } = this.state;
    this.handleCloseSettings();
    this.setState({
      editMode: !editMode,
      coordinationDriverMode: false,
      isSettingsOpen: false,
    });
  };

  handleConfirmedCancel = () => {
    const { activeProject } = this.props;
    this.props.getPhasePlan({ projectId: +activeProject });
    this.setState({
      editMode: false,
      coordinationDriverMode: false,
      driverTasks: [],
    });
  };

  handleConfirmChanges = () => {
    this.props.updatePhasePlan({
      schedules: this.props.phasePlanData.schedules
        .filter(f => !f.isMainBlock)
        .map((d, i) => {
          d.position = i;
          return d;
        }),
      permissions: this.props.phasePlanData.permissions
        .filter(f => !f.isMainBlock)
        .map((d, i) => {
          d.position = i;
          return d;
        }),
      target_start: this.props.phasePlanData.project.target_start,
      target_to: this.props.phasePlanData.project.target_to,
      project_id: this.props.phasePlanData.project_id,
      gmp: this.state.gmp,
    });
  };

  handleOpenCalendar = type => e => {
    const isAdmin: boolean =
      this.props.userData?.userInfo?.roles &&
      !!Object.keys(this.props.userData.userInfo.roles).filter(key => key === '1' || key === '4').length;
    if (!type || !isAdmin) {
      return;
    }
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      typeOfOpenedCalendar: type,
    });
  };

  handleCloseCalendar = () => {
    this.setState({
      anchorEl: null,
      typeOfOpenedCalendar: null,
    });
  };

  selectAtDateHandler = selectedAtDate => {
    const { typeOfOpenedCalendar } = this.state;
    if (!typeOfOpenedCalendar) {
      return;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [typeOfOpenedCalendar]: selectedAtDate,
      },
    });
  };

  selectAtDateHandlerSingle = selectedAtDate => {
    const { typeOfOpenedCalendar } = this.state;
    if (!typeOfOpenedCalendar) {
      return;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [typeOfOpenedCalendar]: selectedAtDate,
      },
    });
    const phasePlanData = { ...this.props.phasePlanData };

    const phasePlanDataProject = {
      ...phasePlanData.project,
      [typeOfOpenedCalendar]: moment(selectedAtDate).format('YYYY-MM-DD'),
    };

    this.props.phasePlanChangeTable({ table: 'project', data: phasePlanDataProject });
    this.handleCloseCalendar();
  };

  handleCoordinationDriver = () => {
    const { driverTasks, coordinationDriverMode } = this.state;
    this.setState({
      driverTasks: !coordinationDriverMode ? driverTasks : [],
      coordinationDriverMode: !coordinationDriverMode,
      applyCoordinationDriver: true,
    });
  };

  successApplyDriverChangesDate = () => {
    this.setState({
      applyCoordinationDriver: false,
    });
  };

  selectedDrivers = driverTasks => {
    this.setState({
      driverTasks: driverTasks,
    });
  };

  setDateGMP = async date => {
    const selectedField = {
      table: 'gmp',
      action: 'gmp',
    };
    const { phasePlanData } = this.props;
    await this.saveToStack({
      fromField: this.state.gmp,
      to: date,
      selectedField: selectedField,
    });
    this.props.updateGMP({
      id: phasePlanData.id,
      gmp: date,
    });
    await this.setState({
      gmp: date,
    });
  };

  legendItemHover = (keyLevel: string) => {
    this.setState({
      hoverLegendMilestone: keyLevel,
    });
  };

  handleChangePhasePlanName = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleOpenDownloadMenu = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      anchorElDownload: e.currentTarget,
    });
  };

  handleCloseDownloadMenu = () => {
    this.setState({
      anchorElDownload: null,
    });
  };
  onChangeIsDrag = (isDrag: boolean) => {
    this.setState({
      isDrag: isDrag,
    });
  };

  saveToStack = ({ fromField, to, selectedField }) => {
    const { past, currentStateIndex } = this.state;
    const { initData } = this.props;
    // if possible detect changes
    try {
      // Compare 2 objects
      if (JSON.stringify(initData[selectedField.table][selectedField.row]) === JSON.stringify(to)) {
        return;
      }
    } catch (err) {
      console.log(err);
    }

    const pastData = [...past];
    // max 20 items
    // if (pastData.length > 20) {
    //   pastData.splice(0, 1);
    // }
    // remove items from the stack if the action is after undo
    pastData.splice(currentStateIndex, past.length);
    // help in recording the previous result
    if (
      pastData.length &&
      selectedField.action !== 'gmp' &&
      pastData[currentStateIndex - 1] &&
      pastData[currentStateIndex - 1].selectedField.action !== 'gmp'
    ) {
      pastData[currentStateIndex - 1].to =
        initData[pastData[currentStateIndex - 1].selectedField.table][pastData[currentStateIndex - 1].selectedField.row];
    }

    pastData.push({ selectedField, fromField: fromField, to: to });
    this.setState({
      past: pastData,
      currentStateIndex:
        // max 20 items
        // pastData.length > 20 ? currentStateIndex :
        currentStateIndex + 1,
    });
  };

  handleUndo = () => {
    const { past, currentStateIndex } = this.state;
    const { initData, phasePlanData, setDriverTasks } = this.props;
    if (currentStateIndex <= 0) {
      return;
    }
    const data = { ...past[currentStateIndex !== 0 ? currentStateIndex - 1 : 0] };
    const status = data.selectedField.action;
    const formData = { ...data };

    if (status === 'update') {
      if (formData.fromField?.construction_start) {
        formData.fromField = {
          ...formData.fromField,
          construction_start: moment(formData.fromField.construction_start).format('YYYY-MM-DD'),
        };
      }
      if (formData.fromField?.construction_end) {
        formData.fromField = {
          ...formData.fromField,
          construction_end: moment(formData.fromField.construction_end).format('YYYY-MM-DD'),
        };
      }

      formData.fromField = {
        ...formData.fromField,
        table: data.selectedField.table,
      };

      this.props.updatePhasePlanField({ ...formData.fromField });
    }

    if (status === 'gmp') {
      this.props.updateGMP({
        id: phasePlanData.id,
        gmp: data.fromField,
      });
      this.setState({
        gmp: data.fromField,
      });
    }

    if (status === 'delete') {
      this.props.createPhasePlanField({
        plan_id: phasePlanData.id,
        table: data.selectedField.table,
        project_id: phasePlanData.project_id,
        ...data.fromField,
      });
    }

    if (status === 'd&d') {
      formData.fromField = {
        ...formData.fromField,
        table: data.selectedField.table,
      };

      this.props.updatePhasePlanField(formData.fromField);
    }
    if (status === 'driver') {
      formData.fromField = {
        ...formData.fromField,
        table: data.selectedField.table,
      };

      setDriverTasks(formData.fromField);
    }

    this.setState({
      currentStateIndex: currentStateIndex !== 0 ? currentStateIndex - 1 : 0,
    });
  };

  handleRedo = () => {
    const { past, currentStateIndex } = this.state;
    const { initData, phasePlanData, setDriverTasks } = this.props;
    if (currentStateIndex >= past.length) {
      return;
    }
    const data = { ...past[currentStateIndex !== 0 ? currentStateIndex : 0] };
    const status = data.selectedField.action;
    const formData = { ...data };

    if (status === 'update') {
      formData.to = {
        ...formData.fromField,
        construction_start: moment(formData.to.construction_start).format('YYYY-MM-DD'),
      };

      formData.to = {
        ...formData.fromField,
        construction_end: moment(formData.to.construction_end).format('YYYY-MM-DD'),
      };

      formData.to = {
        ...formData.fromField,
        table: data.selectedField.table,
      };

      this.props.updatePhasePlanField({ ...formData.to });
    }
    if (status === 'create') {
      this.props.createPhasePlanField({
        project_id: this.props.phasePlanData.project_id,
        plan_id: this.props.phasePlanData.id,
        position: data.selectedField.row,
        table: data.selectedField.table,
      });
    }
    if (status === 'delete') {
      this.props.deletePhasePlanField({
        id: initData[data.selectedField.table][data.selectedField.row].id,
        table: data.selectedField.table,
      });
    }
    if (status === 'gmp') {
      this.props.updateGMP({
        id: phasePlanData.id,
        gmp: data.to,
      });
      this.setState({
        gmp: data.to,
      });
    }
    if (status === 'd&d') {
      formData.to = {
        ...formData.fromField,
        table: data.selectedField.table,
      };

      this.props.updatePhasePlanField(formData.to);
    }
    if (status === 'driver') {
      setDriverTasks(formData.to);
    }
    this.setState({
      currentStateIndex: currentStateIndex === past.length ? currentStateIndex : currentStateIndex + 1,
    });
  };

  handlePrevVersion = item => {
    const { getPrevPhasePlan } = this.props;
    getPrevPhasePlan(item.id);
    this.setState({
      currentPrevVersion: item,
      anchorElDownload: null,
    });
  };

  handleReturnToCurrentVersion = () => {
    const { activeProject, getPhasePlan } = this.props;
    getPhasePlan({ projectId: +activeProject });
    this.setState({
      currentPrevVersion: null,
    });
  };

  handleOpenSettings = event => {
    this.setState({
      isSettingsOpen: true,
      anchorElSettings: event.currentTarget,
    });
  };

  handleCloseSettings = () => {
    this.setState({
      isSettingsOpen: null,
      anchorElSettings: null,
    });
  };

  render() {
    const {
      isOpenCollapse,
      wrapTableRef,
      editableTableRef,
      isSavePhasePlan,
      editMode,
      errors,
      typeOfOpenedCalendar,
      anchorEl,
      formData,
      coordinationDriverMode,
      driverTasks,
      applyCoordinationDriver,
      gmp,
      hoverLegendMilestone,
      anchorElDownload,
      isDrag,
      past,
      currentStateIndex,
      currentPrevVersion,
      isSettingsOpen,
      anchorElSettings,
      isSavingPhasePlan,
    } = this.state;

    const { phasePlanData, updateProject, initData, userData } = this.props;

    const partyIds = userData?.userInfo?.parties?.length ? userData?.userInfo?.parties.map(item => item.id) : [];
    const generalContractorArray = [4, 481, 44, 68, 97, 266, 321, 338, 352, 366, 458, 1464, 1470, 2204];

    const isGeneralContractor = partyIds.length ? partyIds.filter(el => generalContractorArray.includes(el)) : [];

    const isAdmin: boolean =
      userData?.userInfo?.roles &&
      !!Object.keys(userData.userInfo.roles).filter(key => key === '1' || key === '4').length &&
      !!isGeneralContractor.length;

    return (
      <>
        {isAdmin ? (
          <>
            <div className={'linearProgress'}>
              <LinearDeterminate isActive={this.props.getPhasePlanStatus?.loading} />
            </div>
            <PhasePlanView
              handleCollapse={this.handleCollapse}
              isOpenCollapse={isOpenCollapse}
              wrapTableRef={wrapTableRef}
              editableTableRef={editableTableRef}
              handelSavePhasePlan={this.handelSavePhasePlan}
              handelAcceptSavePhasePlan={this.handelAcceptSavePhasePlan}
              handelDeclineSavePhasePlan={this.handelDeclineSavePhasePlan}
              isSavePhasePlan={isSavePhasePlan}
              isAdmin={isAdmin}
              handleEditPhasePlan={this.handleEditPhasePlan}
              editMode={editMode}
              handleConfirmedCancel={this.handleConfirmedCancel}
              handleConfirmChanges={this.handleConfirmChanges}
              handleOpenCalendar={this.handleOpenCalendar}
              errors={errors}
              formData={formData}
              typeOfOpenedCalendar={typeOfOpenedCalendar}
              anchorEl={anchorEl}
              handleCloseCalendar={this.handleCloseCalendar}
              selectAtDateHandler={this.selectAtDateHandler}
              selectAtDateHandlerSingle={this.selectAtDateHandlerSingle}
              handleCoordinationDriver={this.handleCoordinationDriver}
              coordinationDriverMode={coordinationDriverMode}
              selectedDrivers={this.selectedDrivers}
              driverTasks={driverTasks}
              getPhasePlanStatus={this.props.getPhasePlanStatus}
              getPhasePlanError={this.props.getPhasePlanError}
              applyCoordinationDriver={applyCoordinationDriver}
              successApplyDriverChangesDate={this.successApplyDriverChangesDate}
              setDateGMP={this.setDateGMP}
              gmp={gmp}
              legendItemHover={this.legendItemHover}
              hoverLegendMilestone={hoverLegendMilestone}
              handleChangePhasePlanName={this.handleChangePhasePlanName}
              handleOpenDownloadMenu={this.handleOpenDownloadMenu}
              anchorElDownload={anchorElDownload}
              handleCloseDownloadMenu={this.handleCloseDownloadMenu}
              versions={phasePlanData?.versions}
              onChangeIsDrag={this.onChangeIsDrag}
              isDrag={isDrag}
              saveToStack={this.saveToStack}
              handleUndo={this.handleUndo}
              handleRedo={this.handleRedo}
              currentStateIndex={currentStateIndex}
              past={past}
              updateProject={updateProject}
              handlePrevVersion={this.handlePrevVersion}
              currentPrevVersion={currentPrevVersion}
              handleReturnToCurrentVersion={this.handleReturnToCurrentVersion}
              handleOpenSettings={this.handleOpenSettings}
              isSettingsOpen={isSettingsOpen}
              anchorElSettings={anchorElSettings}
              handleCloseSettings={this.handleCloseSettings}
              isSavingPhasePlan={isSavingPhasePlan}
              isActivePhasePlan={true}
            />
          </>
        ) : (
          <WipPage type={'phase-plan'} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ phasePlan, userReducer, settingsReducer }) => {
  return {
    phasePlanData: phasePlan.phasePlan,
    phasePlanLoading: phasePlan.loading,
    userData: userReducer,
    activeProject: userReducer.active_project_id,
    updatePhasePlanStatus: phasePlan.updatePhasePlanStatus,
    projectErrors: phasePlan.projectErrors,
    getPhasePlanStatus: phasePlan.getPhasePlanStatus,
    getPhasePlanError: phasePlan.getPhasePlanError,
    phasePlanVersionStatus: phasePlan.phasePlanVersionStatus,
    phasePlanErrors: phasePlan.phasePlanErrors,
    initData: phasePlan.initData,
    updatedRow: phasePlan.updatedRow,
    phasePlanPrevStatus: phasePlan.phasePlanPrevStatus,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      savePhasePlan,
      getPhasePlan,
      getPhasePlanSelectData,
      updatePhasePlan,
      phasePlanChangeTable,
      savePhasePlanVersion,
      createPhasePlanField,
      updatePhasePlanField,
      deletePhasePlanField,
      updateGMP,
      updateProject,
      getPrevPhasePlan,
      setDriverTasks,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PhasePlanContainer);
