import React, { ReactElement, ReactNode, RefObject, useRef } from 'react';
import { KeyValueModel } from '../../../models/key-value.model';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton';
import ContainedListItem from './ContainedListItem/ContainedListItem';

import './ContainedListStyles.module.scss';

interface IProps<T> {
  data: T[];
  renderItem: (item: T, alias: string) => ReactElement | ReactNode;
  accordionStates: KeyValueModel<boolean> | null;
  accordionRefs: KeyValueModel<RefObject<HTMLElement>>;
  handleAccordion: (alias: string) => void;
  isLoading?: boolean;
  isMobile?: boolean;
  isShortView?: boolean;
  customSkeletonClassname?: string;
  maxCountSkeleton?: number;
  tableRef: RefObject<HTMLElement>;
}

function ContainedList<T>({
  data,
  renderItem,
  accordionStates,
  accordionRefs,
  handleAccordion,
  isLoading,
  tableRef,
  isMobile,
  isShortView,
  customSkeletonClassname,
  maxCountSkeleton,
}: IProps<T>) {
  const themeClass = useGetThemeClass('b-containedList');

  const getSkeletons = () => {
    const skeletons = [];
    const CountSkeleton = maxCountSkeleton || 10;
    for (let i = 0; i < CountSkeleton; i++) {
      skeletons.push(i);
    }
    return skeletons.map(item => {
      return (
        <div
          className={cn(`${themeClass}_skeletonColumn_item`, {
            [`${customSkeletonClassname}`]: customSkeletonClassname,
          })}
          key={item}
        >
          <CustomSkeleton width={'100%'} height={32} />
        </div>
      );
    });
  };

  return (
    <>
      {data?.length && accordionStates && accordionRefs ? (
        data.map(accordion => {
          return (
            <ContainedListItem<T>
              key={accordion.alias}
              accordion={accordion}
              renderItem={renderItem}
              accordionStates={accordionStates}
              accordionRefs={accordionRefs}
              handleAccordion={handleAccordion}
              tableRef={tableRef}
              isShortView={isShortView}
            />
          );
        })
      ) : (
        <>{isLoading && <div className={`${themeClass}_skeletonColumn`}>{getSkeletons()}</div>}</>
      )}
    </>
  );
}

export default ContainedList;
