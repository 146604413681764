import { createAsyncThunk } from '@reduxjs/toolkit';
import requestApi from '../../service/Api/requestApi';
import { KeyValueNumberModel } from '../../models/key-value.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

export const getAvailableBulkEditFields = createAsyncThunk<KeyValueNumberModel<string>, { request_ids: number[] }>(
  'needsListReducer/getAvailableBulkEditFields',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const { request_ids } = payload;

      const response = await requestApi.getAvailableBulkEditFields({ request_ids: request_ids }, projectId);

      return Object.values(response.data);
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
