import React, { ChangeEvent, forwardRef, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../helpers/designTokens';
import TokenIcon from '../TokenIcon/TokenIcon';
import SystemButton from '../ButtonComponents/SystemButton/SystemButton';

import './TextInputNewStyles.scss';

interface TextInputNewProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isSearch?: boolean;
  value: string | number;
  onChange: (value: string | number) => void;
  size?: 'sm' | 'md';
  type: 'on-bgd' | 'on-srf';
  label?: string;
  error?: string;
  disabled?: boolean;
  isFocus?: boolean;
  customIcon?: ReactNode;
  customIconClass?: string;
  onClickIcon?: (func: any) => void;
  width?: number | string;
  inputType?: 'text' | 'number' | 'password';
  isMobile?: boolean;
  isConfiguration?: boolean;
  isResetBtn?: boolean;
  onConfiguration?: () => void;
  customClassName?: string;
  customFieldInputClassName?: string;
  onReset?: () => void;
  theme?: 'light' | 'dark';
  autoComplete?: string;
}

const TextInputNew = forwardRef<HTMLInputElement, TextInputNewProps>(function TextInputNew(
  {
    isSearch,
    onChange,
    value,
    size,
    type,
    label,
    error,
    isFocus,
    customIcon,
    customIconClass,
    onClickIcon,
    width,
    disabled,
    inputType,
    isMobile,
    isConfiguration,
    isResetBtn,
    onConfiguration,
    customClassName,
    customFieldInputClassName,
    onReset,
    theme,
    autoComplete,
    ...inputProps
  },
  ref,
) {
  const themeClass = useGetThemeClass(isSearch ? 'b-textSearch' : 'b-textInput', theme);
  const styleWidth = width ? { width: width } : {};

  const searchIcon = useMemo(() => {
    let sizeName: string = size;
    const sizesInterpreter = {
      sm: 12,
      md: 16,
      mobile: 24,
    };

    if (isMobile) {
      sizeName = 'mobile';
    }

    return <TokenIcon iconName={'search'} size={sizesInterpreter[sizeName]} />;
  }, [type]);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'number') {
      const filteredValue = Number(e.target.value.replace(/d/, ''));
      const newValue = filteredValue >= 0 ? filteredValue : 0;

      onChange(newValue);
      return;
    }

    onChange(e.target.value);
  };

  const handleResetValue = () => {
    onChange('');
    onReset && onReset();
  };

  return (
    <>
      {isSearch ? (
        <div
          className={cn(`${themeClass} ${themeClass}_${type} -${size}`, {
            [`-mobile`]: isMobile,
            [`${customClassName}`]: customClassName,
          })}
          style={styleWidth}
        >
          <div className={`${themeClass}_icon ${themeClass}_${type}_icon`}>{searchIcon}</div>
          <input
            value={value}
            onChange={handleChangeValue}
            ref={ref}
            className={cn(`${themeClass}_input`, `${themeClass}_${type}_input`)}
            disabled={disabled}
            {...(inputProps || {})}
            name={'searchField'}
            autoComplete="off"
          />
          {isConfiguration && (
            <SystemButton type={'configuration'} size={'lg'} variant={'transparent'} clickHandler={onConfiguration} isMobile={isMobile} />
          )}
          {isResetBtn && (
            <SystemButton
              type={'close'}
              size={isMobile ? 'lg' : 'md'}
              variant={'transparent'}
              clickHandler={handleResetValue}
              isMobile={isMobile}
            />
          )}
        </div>
      ) : (
        <div className={cn(`${themeClass}`, { ['-isDisabled']: disabled })} style={styleWidth}>
          {!!label && <label className={`${themeClass}_label`}>{label}</label>}
          <div
            className={cn(`${themeClass}_inputContainer`, {
              [`${customClassName}`]: customClassName,
            })}
          >
            <div
              className={cn(`${themeClass}_field_${type}`, {
                [`-mobile`]: isMobile,
                ['-isFocus']: isFocus,
                ['-isError']: error,
                [`${customFieldInputClassName}`]: customFieldInputClassName,
              })}
              style={styleWidth}
            >
              <input
                value={value}
                onChange={handleChangeValue}
                ref={ref}
                className={cn(`${themeClass}_field_${type}_input`, { [`-mobile`]: isMobile })}
                type={inputType}
                {...(inputProps || {})}
                name={inputType}
                autoComplete={autoComplete || 'on'}
                disabled={disabled}
              />
              {onClickIcon ? (
                <div className={cn(`${themeClass}_field_${type}_icon ${customIconClass}`, { [`-mobile`]: isMobile })} onClick={onClickIcon}>
                  {customIcon}
                </div>
              ) : null}
            </div>
            {!!error && <span className={`${themeClass}_error`}>{error}</span>}
          </div>
        </div>
      )}
    </>
  );
});

export default React.memo(TextInputNew);
