import React, { FC, useCallback, useState } from 'react';
import GaugeChart from '../../../../GaugeChart';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReportingTotal from '../../ReportingTotal/ReportingTotal';
import TokenIcon from '../../../../../controls/TokenIcon/TokenIcon';
import cn from 'classnames';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'var(--font-general)',
    maxWidth: 500,
    color: 'var(--background-tooltip-text)',
    fontSize: '14px',
    padding: '5px 9px',
    backgroundColor: 'var(--background-tooltip)',
    fontWeigh: 'var(--font-weight)',
    position: 'relative',
    left: '0px',
    top: '-5px',
    borderRadius: '2px',
    overflow: 'visible',
  },
  popper: {
    opacity: 1,
  },
}))(Tooltip);

const ReportingGaugeChart: FC<{
  isAllCompanies: boolean;
  first_percent: number;
  second_percent: number;
  themeClass: string;
  project_title: string;
  isVisibleChart: boolean;
  sizeResolution: string;
  open_NFs?: number;
  court_nfs?: number;
  overdue_NFs: number;
  overdue_deliverables?: number;
  avg_reaction_time?: string;
}> = ({
  isAllCompanies,
  first_percent,
  second_percent,
  themeClass,
  project_title,
  open_NFs,
  court_nfs,
  overdue_NFs,
  overdue_deliverables,
  avg_reaction_time,
  isVisibleChart,
  sizeResolution,
}) => {
  return (
    <div className={`${themeClass}__topPies`}>
      <div className={'projectName'}></div>
      <div className={isVisibleChart && (sizeResolution === '1366' || sizeResolution === '1024') ? 'topPies_TotalCenter' : 'topPies_Total'}>
        {isAllCompanies && isVisibleChart && (
          <div className={'pie'}>
            <div className="pie__holder">
              {isAllCompanies && (
                <>
                  <HtmlTooltip
                    title={
                      <div key={`title`} className={`b-declinedContainer`}>
                        <div>
                          The current PPI is <span style={{ color: 'red', fontWeight: 'bold' }}>low</span>, this might indicate inefficient
                          communication patterns.
                        </div>
                        <div>
                          <u>Recommendation:</u>
                        </div>
                        <div>
                          Overdue NFs have to be reviewed and closed as soon as possible, the check for multiple NFs on the same topic or
                          NFs with unrealistic due dates required.
                        </div>
                      </div>
                    }
                    placement={'bottom-start'}
                  >
                    <div className="topPiesLableLow">
                      <label htmlFor="Low" className="topPiesLable">
                        Low
                      </label>
                    </div>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <div key={`title`} className={`b-declinedContainer`}>
                        <div>
                          The PPI is <span style={{ color: 'grey', fontWeight: 'bold' }}>normal</span>, the main communication flow is
                          relatively efficient. However, some additional 'housekeeping' may be required.
                        </div>
                        <div>
                          <u>Recommendation:</u>
                        </div>
                        <div>Check the overdue NFs, Accept/Decline provided deliverables, validate if the due dates are realistic.</div>
                      </div>
                    }
                    placement={'bottom-start'}
                  >
                    <div className="topPiesLableNormal">
                      <label htmlFor="Normal" className="topPiesLable">
                        Normal
                      </label>
                    </div>
                  </HtmlTooltip>
                  <HtmlTooltip
                    title={
                      <div key={`title`} className={`b-declinedContainer`}>
                        <div>
                          The PPI is <span style={{ color: 'green', fontWeight: 'bold' }}>high</span>, the communication flow is efficient.
                        </div>
                        <div>NFs are reacted appropriately as they appear, no project delays expected.</div>
                      </div>
                    }
                    placement={'bottom-start'}
                  >
                    <div className="topPiesLableHigh">
                      <label htmlFor="High" className="topPiesLable">
                        High
                      </label>
                    </div>
                  </HtmlTooltip>
                </>
              )}
              <GaugeChart
                id="gauge-chart"
                percent={second_percent}
                first_percent={first_percent}
                nrOfLevels={5}
                arcWidth={0.05}
                hideText={true}
                needleColor="#8BA1B6"
                animate={false}
                colors={['#A92D2D', '#814F55', '#596989', '#539581', '#66C17F']}
                style={{ width: 467, height: 154 }}
                needleBaseColor={localStorage.getItem('theme') === 'dark' ? '#0b1324' : '#f7f7f7'}
              />
            </div>
            <div className={`${themeClass}__topPies pie_title_container`}>
              <div className={`${themeClass}__topPies pie_title`}>Project progression index</div>
              <TokenIcon customClass={`${themeClass}__topPies pie_pie_icon`} iconName={'status'} size={16} />
            </div>
          </div>
        )}
        {isVisibleChart && (
          <ReportingTotal
            open_NFs={open_NFs}
            court_nfs={court_nfs}
            overdue_NFs={overdue_NFs}
            overdue_deliverables={overdue_deliverables}
            avg_reaction_time={avg_reaction_time}
            isAllCompanies={isAllCompanies}
            isHorizontal={isVisibleChart && (sizeResolution === '1366' || sizeResolution === '1024')}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ReportingGaugeChart);
