import React, { FC } from 'react';
import { Logo, PageHeading2, TextInput } from '../../controls/index';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { CustomButton } from '../../controls/ButtonComponents';

import './NonLoginedPageStyles.scss';

interface IProps {
  handleChangeField: (filed: string) => (value: string) => void;
  user: any;
  handleLogin: () => void;
  loading: boolean;
  message: string;
  status: string | number | boolean;
  error: string;
  passwordError: string;
  confirmPasswordError: string;
}

const InvitePageView: FC<IProps> = ({
  handleChangeField,
  user,
  handleLogin,
  loading,
  message,
  status,
  error,
  passwordError,
  confirmPasswordError,
}) => {
  const themeClass = useGetThemeClass('b-authorization-loginPage');

  return (
    <div className={`${themeClass}_container`}>
      <div className={`${themeClass}_topInfo`}>
        <div className={`${themeClass}_logoContainer`}>
          <Logo isFull themeProp={(localStorage.getItem('theme') || 'light') as 'dark' | 'light'} />
          <p className={`${themeClass}_logoContainer__header`}>Plan & Control App</p>
        </div>
        {status === 400 || !status || error ? <span className={`${themeClass}_loginError`}>{error || message}</span> : null}
        <div className={`${themeClass}_form -logo`}>
          <PageHeading2 title="Welcome to Planit app" theme={localStorage.getItem('theme') || 'light'} />

          <TextInput
            label="Setup password"
            value={user.password}
            onChange={handleChangeField('password')}
            type="password"
            errorMsg={passwordError || ''}
            theme={(localStorage.getItem('theme') || 'light') as 'dark' | 'light'}
            handleLogin={handleLogin}
          />
          <TextInput
            label="Confirm password"
            value={user.confirmPassword}
            onChange={handleChangeField('confirmPassword')}
            type="password"
            errorMsg={confirmPasswordError || ''}
            theme={(localStorage.getItem('theme') || 'light') as 'dark' | 'light'}
            handleLogin={handleLogin}
          />
          <div className={`${themeClass}_inviteBtn`}>
            <CustomButton
              size={'md'}
              clickHandler={handleLogin}
              type={`primary`}
              title={'Sign in'}
              disabled={loading || !user.password || !user.confirmPassword}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className={`${themeClass}_bottomInfo`}>
        <span className={`${themeClass}_bottomInfo_textInfo`}>Planit Inc., {new Date().getFullYear()}</span>
      </div>
    </div>
  );
};

export default InvitePageView;
