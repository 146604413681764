import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
// import { setNotificationFilters } from '../../../../store/notifications/notificationsActions';
import NotificationFiltersView from './NotificationFiltersView';
import FiltersHelper from '../../../../helpers/FiltersHelper';
import { notificationsSlice } from '../../../../store/notifications/notificationsReducer';

export const notificationFilterView = {
  DATE: 'DATE',
  ACTIONS: 'ACTIONS',
  PROJECTS: 'PROJECTS',
};

export const requestStatusFilters = [];

export const commitementStatusFilter = [];

class NotificationFiltersContainer extends React.Component {
  state = {
    filters: {},
    filtersField: [
      // { title: 'Project', filterField: 'projects' },
      { title: 'Action', filterField: 'actions' },
      { title: 'Request Company', filterField: 'request_company' },
      { title: 'Response Company', filterField: 'response_company' },
      { title: 'User', filterField: 'users' },
      { title: 'Date', filterField: 'date' },
      { title: 'Description', filterField: 'description' },
    ],
    conditionFilters: {
      DATE: [
        {
          title: 'Date is',
          id: 'DATE_IS',
        },
        {
          title: 'Range',
          id: 'RANGE',
        },
        {
          title: 'Date before',
          id: 'BEFORE',
        },
        {
          title: 'Date after',
          id: 'AFTER',
        },
      ],
      TEXT: [
        {
          title: 'Text contains',
          id: 'CONTAINS',
        },
        {
          title: "Text doesn't contain",
          id: 'NOT_CONTAINS',
        },
        {
          title: 'Text exactly',
          id: 'EXACTLY',
        },
      ],
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notificationFilters !== prevProps.notificationFilters) {
      const dt = this.props.notificationFilters.date.map(item => {
        return { id: item.idd, title: item.title };
      });
      this.setState({
        filters: {
          actions: [...this.props.notificationFilters.actions],
          projects: [...this.props.notificationFilters.projects],
          request_company: [...this.props.notificationFilters.request_company],
          response_company: [...this.props.notificationFilters.response_company],
          users: [...this.props.notificationFilters.users],
          date: [...dt],
          description: { id: 0, title: '(Blanks)' },
        },
      });
    }
  }

  onChangeHeight = (width, height) => {
    const { onChangeFiltersHeight } = this.props;
    onChangeFiltersHeight && onChangeFiltersHeight(height);
  };

  render() {
    return (
      <NotificationFiltersView
        filters={this.state.filters}
        filtersField={this.state.filtersField}
        getNotificationsList={this.props.getNotificationsList}
        notificationsInfo={this.props.notificationsInfo}
        selectedFilters={this.props.selectedFilters}
        conditions={this.props.conditions}
        containsFilter={this.props.containsFilter}
        formData={this.props.formData}
        searchValue={this.props.searchValue}
        handleChangeSearch={this.props.handleChangeSearch}
        handleChangeMore={this.props.handleChangeMore}
        handleChangeSelect={this.props.handleChangeSelect}
        handleCloseSelect={this.props.handleCloseSelect}
        handleReadAll={this.props.handleReadAll}
        handleRemoveFilter={this.props.handleRemoveFilter}
        handleChangeContains={this.props.handleChangeContains}
        handleSelectCondition={this.props.handleSelectCondition}
        conditionFilters={this.state.conditionFilters}
        onChangeFilters={this.props.onChangeFilters}
        handleResetFilter={this.props.handleResetFilter}
        onChangeHeight={this.onChangeHeight}
      />
    );
  }
}

const mapStateToProps = ({ projectReducer, settingsReducer, sandBoxReducer, notificationsReducer }) => {
  return {
    notificationFilters: notificationsReducer.notificationFilters,
    notificationsInfo: notificationsReducer.notificationsInfo,
  };
};
const { setNotificationFilters } = notificationsSlice.actions;
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNotificationFilters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFiltersContainer);
