export class ProfileHelper {
  static getEmptyPromptNameList = () => {
    const promptNameList = {
      requestHeadClose: 'yes',
      requestClickOutSide: 'yes',
      pcdHeadClose: 'yes',
      pcdClickOutSide: 'yes',
      RequestUpdate: 'yes',
      commitmentSubmit: 'yes',
      commitmentUpdate: 'yes',
      deliverableComplete: 'yes',
      RequestSend: 'yes',
      RequestSendPCD: 'yes',
      DraftRevision: 'yes',
      finalDelivable: 'yes',
      notFinalDelivable: 'yes',
      dueDateChangeBtn: 'yes',
      changeCompanyBtn: 'yes',
      pcdScheduleDriverChanging: 'no',
      pcdScheduleDriverResetting: 'no',
      pcdTimeAdvanceChanging: 'no',
      pcdTimeAdvanceResetting: 'no',
      pcdAllLevels: 'no',
      pcdResponsibleReview: 'no',
      pcdDeactivating: 'no',
      pcdBuildingZone: 'no',
      pcdSelectedZone: 'no',
      pcdDeliverableList: 'no',
      pcdCardCreate: 'no',
      pcdCardEdit: 'no',
      pcdDriverGroup: 'no',
      profileSettings: 'no',
      inactiveUserPopup: 'yes',
    };

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    localStorage.setItem('requestHeadClose', 'yes');
    localStorage.setItem('requestClickOutSide', 'yes');
    localStorage.setItem('pcdHeadClose', 'yes');
    localStorage.setItem('pcdClickOutSide', 'yes');
    localStorage.setItem('RequestUpdate', 'yes');
    localStorage.setItem('commitmentSubmit', 'yes');
    localStorage.setItem('commitmentUpdate', 'yes');
    localStorage.setItem('deliverableComplete', 'yes');
    localStorage.setItem('RequestSend', 'yes');
    localStorage.setItem('RequestSendPCD', 'yes');
    localStorage.setItem('DraftRevision', 'yes');
    localStorage.setItem('finalDelivable', 'yes');
    localStorage.setItem('notFinalDelivable', 'yes');
    localStorage.setItem('dueDateChangeBtn', 'yes');
    localStorage.setItem('changeCompanyBtn', 'yes');
    localStorage.setItem('pcdScheduleDriverChanging', 'no');
    localStorage.setItem('pcdScheduleDriverResetting', 'no');
    localStorage.setItem('pcdTimeAdvanceChanging', 'no');
    localStorage.setItem('pcdTimeAdvanceResetting', 'no');
    localStorage.setItem('pcdAllLevels', 'no');
    localStorage.setItem('pcdResponsibleReview', 'no');
    localStorage.setItem('pcdDeactivating', 'no');
    localStorage.setItem('pcdBuildingZone', 'no');
    localStorage.setItem('pcdSelectedZone', 'no');
    localStorage.setItem('pcdDeliverableList', 'no');
    localStorage.setItem('pcdCardCreate', 'no');
    localStorage.setItem('pcdCardEdit', 'no');
    localStorage.setItem('pcdDriverGroup', 'no');
    localStorage.setItem('profileSettings', 'no');
    localStorage.setItem('inactiveUserPopup', 'yes');

    return JSON.stringify(promptNameList);
  };

  static getPromptNameList = () => {
    const promptNameList = {
      requestHeadClose: 'no',
      requestClickOutSide: 'no',
      pcdHeadClose: 'no',
      pcdClickOutSide: 'no',
      RequestUpdate: 'no',
      commitmentSubmit: 'no',
      commitmentUpdate: 'no',
      deliverableComplete: 'no',
      RequestSend: 'no',
      RequestSendPCD: 'no',
      DraftRevision: 'no',
      finalDelivable: 'no',
      notFinalDelivable: 'no',
      dueDateChangeBtn: 'no',
      changeCompanyBtn: 'no',
      pcdScheduleDriverChanging: 'no',
      pcdScheduleDriverResetting: 'no',
      pcdTimeAdvanceChanging: 'no',
      pcdTimeAdvanceResetting: 'no',
      pcdAllLevels: 'no',
      pcdResponsibleReview: 'no',
      pcdDeactivating: 'no',
      pcdBuildingZone: 'no',
      pcdSelectedZone: 'no',
      pcdDeliverableList: 'no',
      pcdCardCreate: 'no',
      pcdCardEdit: 'no',
      pcdDriverGroup: 'no',
      profileSettings: 'no',
      inactiveUserPopup: 'no',
    };

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    localStorage.setItem('requestHeadClose', 'no');
    localStorage.setItem('requestClickOutSide', 'no');
    localStorage.setItem('pcdHeadClose', 'no');
    localStorage.setItem('pcdClickOutSide', 'no');
    localStorage.setItem('RequestUpdate', 'no');
    localStorage.setItem('commitmentSubmit', 'no');
    localStorage.setItem('commitmentUpdate', 'no');
    localStorage.setItem('deliverableComplete', 'no');
    localStorage.setItem('RequestSend', 'no');
    localStorage.setItem('RequestSendPCD', 'no');
    localStorage.setItem('DraftRevision', 'no');
    localStorage.setItem('finalDelivable', 'no');
    localStorage.setItem('notFinalDelivable', 'no');
    localStorage.setItem('dueDateChangeBtn', 'no');
    localStorage.setItem('changeCompanyBtn', 'no');
    localStorage.setItem('pcdScheduleDriverChanging', 'no');
    localStorage.setItem('pcdScheduleDriverResetting', 'no');
    localStorage.setItem('pcdTimeAdvanceChanging', 'no');
    localStorage.setItem('pcdTimeAdvanceResetting', 'no');
    localStorage.setItem('pcdAllLevels', 'no');
    localStorage.setItem('pcdResponsibleReview', 'no');
    localStorage.setItem('pcdDeactivating', 'no');
    localStorage.setItem('pcdBuildingZone', 'no');
    localStorage.setItem('pcdSelectedZone', 'no');
    localStorage.setItem('pcdDeliverableList', 'no');
    localStorage.setItem('pcdCardCreate', 'no');
    localStorage.setItem('pcdCardEdit', 'no');
    localStorage.setItem('pcdDriverGroup', 'no');
    localStorage.setItem('profileSettings', 'no');
    localStorage.setItem('inactiveUserPopup', 'no');

    return JSON.stringify(promptNameList);
  };
}
