import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import ContentSwitcher from '../../../../controls/ButtonComponents/ContentSwitcher/ContentSwitcher';
import OvalButton from '../../../../controls/ButtonComponents/OvalButton/OvalButton';
import OverflowMenuButton from '../../../../controls/ButtonComponents/OverflowMenuButton/OverflowMenuButton';
import ActionButton from '../../../../controls/ButtonComponents/ActionButton/ActionButton';
import SystemButton from '../../../../controls/ButtonComponents/SystemButton/SystemButton';
import CardLinkButton from '../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import StatusNF from '../../../../controls/Status/StatusNF/StatusNF';
import ChipsRequestType from '../../../../controls/Chips/ChipsRequestType/ChipsRequestType';
import ChipsLOD from '../../../../controls/Chips/ChipsLOD/ChipsLOD';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import Divider from '../../../../controls/Divider/Divider';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import {
  RequestModel,
  RequestTypeModel,
  RequestUserModel,
  ActivationTabType,
  WatcherModel,
  WatcherGroupModel,
  BreadcrumbModel,
} from '../../../../../models';
import { DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import { MilestoneItemModel } from '../../../../../models/phasePlan/milestone.model';
import { BICorMentionSelect } from '../../../../controls/BICorMentionSelect/BICorMentionSelect';
import SandBoxDate from '../../../RequestCard/parts/SandBoxDateField/SandBoxDate';
import { NeedListRequest } from '../../../../../store/needlist/needListModels';
import StatusNameHelper from '../../../../../helpers/StatusNameHelper';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import RequestStatuses from '../../../../common/RequestCard/parts/RequestStatuses/RequestStatuses';
import { CardBreadcrumbs } from '../../../../common/CardBreadcrumbs';
import DateRow from '../../../../controls/DateRow/DateRow';
import { CommitmentVersions } from './parts/CommitmentVersions/CommitmentVersions';
import { RequestVersions } from '../../../RequestCard/parts/RequestVersions/RequestVersions';
import Popover from '@material-ui/core/Popover';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import Watchlist from '../../../../common/WatchingBtn/Watchlist';
import { deactivatedStatus, setHotList, updateNewDueDate, updateNewResponseCompany } from '../../../../../store/request/requestLogic(HOLD)';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import fileDownload from 'js-file-download';
import { API_URI, DOMAIN_URI } from '../../../../../service/links';
import commonApi from '../../../../../service/Api/commonApi';
import FileHelper from '../../../../../helpers/FileHelper';
import DropdownDownloadList from '../../../../controls/DropdownDownloadList/DropdownDownloadList';
import { DropdownMenuItem } from '../../../../controls/Dropdowns/DropdownParts';
import { PopupCreatedBy } from '../../../../controls/PopupCreatedBy/PopupCreatedBy';
import moment from 'moment';
import { TextInputNew } from '../../../../controls';
import CustomSkeleton from '../../../../controls/CustomSkeleton/CustomSkeleton';
import cn from 'classnames';
import { requestSlice } from '../../../../../store/request/requestReducer(HOLD)';
import GeometryObjects from '../../../GeometryObjects/GeometryObjects';
import { breadcrumbsSlice } from '../../../../../store/breadcrumbs/breadcrumbsReducer';
import { incorporationSlice } from '../../../../../store/incorporation/incorporationSlice';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';

import './HeaderStyles.module.scss';

const { resetSocketRequestHotlistState } = requestSlice.actions;
const { setNewBreadcrumbs } = breadcrumbsSlice.actions;
const { changeGlobalIncorporation } = incorporationSlice.actions;

const overflowMenuSelect = [
  {
    id: 'watchlist',
    title: 'Watchlist',
  },
  {
    id: 'visibleNF',
    title: 'visibleNF',
  },
  {
    id: 'download',
    title: 'Download PDF',
  },
];

interface DropdownItemModelExt extends DropdownItemModel {
  code?: string;
  titleLOD?: string;
  alias?: string;
}

interface MilestoneItemModelExt extends MilestoneItemModel {
  value?: number;
  titleLOD?: string;
}

interface IProps {
  isDragRequestCard: boolean;
  tags: RequestTypeModel[];
  selectedTags: RequestTypeModel;
  milestones: MilestoneItemModel[];
  selectedMilestone: MilestoneItemModel;
  selectedRequest: RequestModel & NeedListRequest;
  clickHandler: () => void;
  handleChangeRequestTypeTag: (item: RequestTypeModel) => void;
  handleChangeLOD: (item: MilestoneItemModel) => void;
  selectAtDateHandler: (selectedAtDate: string) => void;
  currentDate: string;
  isDueDateDisabled: boolean;
  errors;
  isUserInRequestParty: boolean;
  isAuthorOfTheRequest: boolean;
  isCreateRequest: boolean;
  isPullRequest: boolean;
  promptName: string;
  is_promptBtnOk: boolean;
  requestToNewDueDate: (newDueDate: string) => void;
  min_due_date: string;
  max_due_date: string;
  work_week: number;
  activeCommitmentAction: string;
  setCommitmentAction: (data: string) => void;
  currentPCD: number[];
  isShowDueDatePopup: boolean;
  requestHeadClose: () => void;
  breadcrumbs: BreadcrumbModel[] | null;
  selectRequestHandler: (id: number, isNavToSubtask?: boolean) => void;
  clearBreadcrumbs: () => void;
  requestLoading: boolean;
  getRequestLoadingStatus: boolean;
  handleChangeRevision: (id: number) => void;
  originalRequestId: number;
  previewCard: boolean;
  isSeparateTab: boolean;
  isRfi: boolean;
  handleChangePrivate: () => void;
  changeCommitmentVersion: (id: number) => void;
  is_procore_sync_needed: number | boolean;
  isProcorConfirmPopUpOpen: boolean;
  handleOpenProcorConfirmPopUp: () => void;
  handleCloseProcorConfirmPopUp: () => void;
  handleChangeProcoreSyncStatus: () => void;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => void;
  handleCloseAuthorUser: () => void;
  listPCD;
  handleCopyDataFromOriginal: () => void;
  isPullRequestDataToCopyFormOriginal: boolean;
  parentSelectedRequest: RequestModel;
  closePullRequest: () => void;
  onChangeActiveTab: (activeTab: ActivationTabType) => void;
  isAccess: boolean;
  isSkeletonLoading: boolean;
  isAccessEdit: boolean;
  requestWatchers: WatcherModel[] | null;
  requestWatchersGroups: WatcherGroupModel[] | null;
  handleCloseAllNotificationPopups: () => void;
  isAllNotificationPopupsClosed: boolean;
  isDeliverablesPopUpOpened: boolean;
  activeTab: string;
}

const Header: FC<IProps> = ({
  isDragRequestCard,
  tags,
  selectedTags,
  milestones,
  selectedMilestone,
  selectedRequest,
  clickHandler,
  handleChangeRequestTypeTag,
  handleChangeLOD,
  selectAtDateHandler,
  currentDate,
  isDueDateDisabled,
  errors,
  isUserInRequestParty,
  isAuthorOfTheRequest,
  isCreateRequest,
  isPullRequest,
  promptName,
  is_promptBtnOk,
  requestToNewDueDate,
  min_due_date,
  max_due_date,
  work_week,
  activeCommitmentAction,
  setCommitmentAction,
  currentPCD,
  isShowDueDatePopup,
  requestHeadClose,
  breadcrumbs,
  selectRequestHandler,
  clearBreadcrumbs,
  requestLoading,
  getRequestLoadingStatus,
  handleChangeRevision,
  originalRequestId,
  previewCard,
  isSeparateTab,
  isRfi,
  handleChangePrivate,
  changeCommitmentVersion,
  isProcorConfirmPopUpOpen,
  is_procore_sync_needed,
  handleOpenProcorConfirmPopUp,
  handleCloseProcorConfirmPopUp,
  handleChangeProcoreSyncStatus,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
  listPCD,
  handleCopyDataFromOriginal,
  isPullRequestDataToCopyFormOriginal,
  parentSelectedRequest,
  closePullRequest,
  onChangeActiveTab,
  isAccess,
  isAccessEdit,
  requestWatchers,
  requestWatchersGroups,
  handleCloseAllNotificationPopups,
  isAllNotificationPopupsClosed,
  isDeliverablesPopUpOpened,
  activeTab,
  isSkeletonLoading,
  activeTab,
}) => {
  const dispatch = useAppDispatch();
  const { setUrlNF, getIsNeedListFromURL } = useRoutingHelper();

  const saveHotlistLoading = useAppSelector(state => state.requestReducer.saveHotlistLoading);
  const isLoadingRequestByNf = useAppSelector(state => state.requestReducer.isLoadingRequestByNf);
  const socketHotlistState = useAppSelector(state => state.requestReducer.socketHotlistState);
  const sandBoxCardIDHotList = useAppSelector(state => state.requestReducer.sandBoxCardIDHotList);

  const isNeedListLoadingStatus = useAppSelector(state => state.requestReducer.loadingNeedListTable);
  const needListData = useAppSelector(state => state.requestReducer.needList.data);
  const isNeedListGrouped = useAppSelector(state => state.requestReducer.isNeedListGrouped);

  const [isShowStatuses, setIsShowStatuses] = useState<boolean>(false);
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const [isHoverStatuses, setIsHoverStatuses] = useState<boolean>(false);
  const [isAlwaysShowStatuses, setIsAlwaysShowStatuses] = useState<boolean>(false);
  const [isOpenMilestones, setIsOpenMilestones] = useState<boolean>(false);
  const [isOpenRequestType, setIsOpenRequestType] = useState<boolean>(false);
  const [isMenuDownloadListOpen, setIsMenuDownloadListOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenedVersions, setIsOpenedVersions] = useState(null);
  const [isOpenedCommitmentVersions, setIsOpenedCommitmentVersions] = useState(null);
  const [openedCommitmentVersions, setOpenedCommitmentVersions] = useState<string>('');
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [isOpenCreateBy, setIsOpenCreateBy] = useState<boolean>(false);
  const [isHotList, setIsHotList] = useState<boolean>(false);
  const [isHotListManually, setIsHotListManually] = useState<boolean>(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<HTMLDivElement>(null);
  const [isDeclinePopover, setIsDeclinePopover] = useState<HTMLDivElement>(null);
  const [declineMsg, setDeclineMsg] = useState<string>('');
  const [declineField, setDeclineField] = useState<string>('');
  const [isWatchlistMobileOpen, setIsWatchlistMobileOpen] = useState<Event | null>(null);

  const { is1440Resolution, isMobile } = GetResolution();
  const showSmall = isDragRequestCard || is1440Resolution;

  const isNeedListModule = getIsNeedListFromURL();

  const resolutionName = useMemo(() => {
    if (isMobile) {
      return 'mobile';
    }

    if (showSmall) {
      return 'small';
    }

    return 'full';
  }, [showSmall, isMobile]);

  const truncateBreadcrumbsNumber = useMemo(() => {
    if (isMobile) {
      return 3;
    }

    if (isDragRequestCard || is1440Resolution) {
      return 2;
    }

    return 3;
  }, [isMobile, isDragRequestCard, is1440Resolution]);

  const filteredOverflowMenuItems = useMemo(() => {
    const allowDeactivate = !(selectedRequest?.status === 'drafted' || !isAccessEdit);
    const allowWatchlist = isMobile;

    return overflowMenuSelect.filter(item => {
      if (item.id === 'download') {
        return true;
      } else if (item.id === 'watchlist') {
        return allowWatchlist;
      } else if (item.id === 'visibleNF') {
        return allowDeactivate;
      } else {
        return false;
      }
    });
  }, [overflowMenuSelect, selectedRequest, isAccessEdit]);

  const themeClass = `${useGetThemeClass('b-header-card-request')}-${resolutionName}`;

  const themeClassPopUpDeactivation = useGetThemeClass('b-popupDeactivation');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassPaper = useGetThemeClass('b-dropdownDownloadListPaper');
  const themeClassDueChange = useGetThemeClass('b-popover-due-date-change');
  const themeClassDueChangeDecline = useGetThemeClass('b-popover-due-date-change-decline');
  const dialogClass = useGetThemeClass('dialogPopUp');

  const chevron_down_icon = <TokenIcon iconName={'chevron-down'} size={16} />;
  const created_icon = <TokenIcon iconName={'created'} size={16} />;
  const isDraftedNf = selectedRequest?.status === 'drafted';

  // Deactivation states
  const [isDeactivationPopUpOpen, setIsDeactivationPopUpOpen] = useState<boolean>(false);
  const [deactivationItem, setDeactivationItem] = useState<{
    action: 'activate' | 'deactivate';
    id: number;
    nf: number;
  }>();

  const headerRef = useRef<HTMLDivElement>();
  const headerStatusNFRef = useRef<HTMLDivElement>();
  const headerCentralStatusNFRef = useRef<HTMLDivElement>();
  const refCreatedBy = useRef<HTMLDivElement>();
  const overflowMenuRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (selectedRequest) {
      const isIDsHotList = sandBoxCardIDHotList.find(value => value === selectedRequest.id);
      if (isIDsHotList) {
        setIsHotListManually(true);
      }
      setIsHotList(selectedRequest.is_hot_list);
    }
  }, [selectedRequest, sandBoxCardIDHotList]);

  useEffect(() => {
    if (selectedRequest) {
      dispatch(changeGlobalIncorporation(selectedRequest.is_incorporated ? 1 : 0));
    }
  }, [selectedRequest?.is_incorporated]);

  useEffect(() => {
    if (socketHotlistState.changedNow) {
      setIsHotList(socketHotlistState.is_hot_list);
      dispatch(resetSocketRequestHotlistState());
    }
  }, [socketHotlistState.changedNow]);

  const handleChangeRequestTypeSelect = value => {
    handleChangeRequestTypeTag(value);
  };

  const handleChangeLODSelect = value => {
    const selectedLOD = milestones.find(item => item.id === value.id);
    handleChangeLOD(selectedLOD);
  };

  const handleSetHotList = e => {
    e.stopPropagation();
    e.preventDefault();

    const project_id = +sessionStorage.getItem('active_project_id');

    dispatch(
      setHotList({
        project_id,
        entity_ids: [selectedRequest.id],
        entity: selectedRequest.nf ? 'request' : 'deliverable',
        action: isHotList ? 'remove' : 'add',
        accordion_alias: null,
      }),
    );
    setIsHotList(prevState => !prevState);
  };

  const downloadRequestCard = async (type: 'pdf' | 'zip', id) => {
    const url = new URL(`${DOMAIN_URI}${API_URI}export/${type}/request/${id}`);
    try {
      const response = await commonApi.getFile(url.href);
      setFileLoading(false);
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);
    } catch (e) {
      setFileLoading(false);
    }
  };

  const handleOpenMobileWatchlist = () => {
    setIsWatchlistMobileOpen({ currentTarget: overflowMenuRef?.current });
  };

  const handleCloseMobileWatchlist = () => {
    setIsWatchlistMobileOpen(null);
  };

  const handleChangeOverflowMenuSelect = (value: string, itemId: string) => {
    if (value === 'download') {
      if (selectedRequest?.versions?.length || selectedRequest?.parent_id) {
        setIsOpenedCommitmentVersions(isOpenedCommitmentVersions ? null : anchorEl || null);
        setOpenedCommitmentVersions('Overflow');
        return;
      }
      downloadRequestCard('pdf', selectedRequest.id);
    } else if (value === 'visibleNF') {
      const action = selectedRequest.is_deactivated ? 'activate' : 'deactivate';
      handleDeactivationPopUp(selectedRequest.id, selectedRequest.nf, action);
    } else if (value === 'watchlist') {
      handleOpenMobileWatchlist();
    }
    closeMenuDownloadList();
  };

  const handleOpenRequestType = () => {
    setIsOpenRequestType(true);
    setIsOpenMilestones(false);
  };

  const handleCloseRequestType = () => {
    setIsOpenRequestType(false);
    setIsOpenMilestones(false);
  };

  const renderSingleSelectRequestType = (item: DropdownItemModelExt) => {
    return (
      <>
        <ChipsRequestType type={item.title} isMobile={isMobile} />
      </>
    );
  };

  const handleShowStatuses = () => {
    setIsShowStatuses(true);
    setIsDropdown(false);
  };

  const handleHideStatuses = () => {
    setIsShowStatuses(false);
    setIsDropdown(false);
  };

  const handleHoverStatuses = (isHoverStatuses: boolean) => {
    setIsHoverStatuses(isHoverStatuses);
    setIsShowStatuses(false);
  };

  const handleOpenStatuses = () => {
    setIsShowStatuses(true);
    setIsDropdown(true);
  };

  const handleSwitchAlwaysShowStatuses = () => {
    setIsAlwaysShowStatuses(!isAlwaysShowStatuses);
  };

  const renderSingleSelectLod = (item: DropdownItemModelExt) => {
    return (
      <>
        <ChipsLOD type={'filled'} value={item.code} />
        <div className={`${themeClass}_selectTextLod`}> {item.titleLOD} </div>
      </>
    );
  };

  const handleVersionsPopover = e => {
    if (selectedRequest?.versions?.length || selectedRequest?.parent_id) {
      setIsOpenedCommitmentVersions(isOpenedCommitmentVersions ? null : e?.currentTarget || null);
      !isOpenedCommitmentVersions && showSmall && setOpenedCommitmentVersions('CardLinkButton');
      return;
    }
    setIsOpenedVersions(isOpenedVersions ? null : e?.currentTarget || null);
  };

  const handleCommitmentVersionsPopover = e => {
    setIsOpenedCommitmentVersions(isOpenedCommitmentVersions ? null : e?.currentTarget || null);
    isOpenedCommitmentVersions && setOpenedCommitmentVersions('');
  };

  const getTagTypeName = alias => {
    if (alias === 'pdc') {
      return 'Design constraint';
    } else if (alias === 'input_checklist') {
      return 'CL input checklist';
    } else if (alias === 'for_record') {
      return 'For record';
    } else if (alias === 'planit_rfi') {
      return 'RFI';
    } else if (alias === 'change_request') {
      return 'Standard request';
    } else if (alias === 'submit_cut_sheets') {
      return 'Submittals/cut sheets';
    }
  };

  const renderSingleSelectOverflowMenu = (item: DropdownItemModelExt) => {
    const id = item.id === 'visibleNF' ? (selectedRequest?.is_deactivated ? 'activate' : 'deactivate') : item.id;
    const title = item.id === 'visibleNF' ? (selectedRequest?.is_deactivated ? 'Activate' : 'Deactivate') : item.title;

    return (
      <div className={`${themeClass}_overflowMenu`}>
        <TokenIcon iconName={id} size={16} customClass={`${themeClass}_overflowMenu_svg_icon`} />
        <div className={`${themeClass}_overflowMenu_label`}> {title} </div>
      </div>
    );
  };

  const handleDeactivateNf = () => {
    dispatch(
      deactivatedStatus({
        request_id: deactivationItem.id,
        action: deactivationItem.action,
        callback: setDeactivationItem(null),
      }),
    );
    setIsDeactivationPopUpOpen(false);
  };

  const handleDeactivationPopUp = (id: number, nf: number, action: 'activate' | 'deactivate') => {
    setDeactivationItem({ action, id, nf });
    setIsDeactivationPopUpOpen(true);
  };

  const cancelDeactivatedStatus = e => {
    setIsDeactivationPopUpOpen(false);
    setTimeout(() => {
      setDeactivationItem(null);
    }, 200);
  };

  const handleMenuDownloadListOpen = e => {
    setAnchorEl(e.currentTarget);
    setIsMenuDownloadListOpen(true);
  };

  const closeMenuDownloadList = () => {
    setIsMenuDownloadListOpen(false);
    setAnchorEl(null);
  };

  const renderMenuItem = (item: DropdownItemModelExt) => {
    const id = item.id === 'visibleNF' ? (selectedRequest?.is_deactivated ? 'activate' : 'deactivate') : item.id;
    const title = item.id === 'visibleNF' ? (selectedRequest?.is_deactivated ? 'Activate' : 'Deactivate') : item.title;

    const itemDropdown = (
      <>
        <div className={`${themeClass}_overflowMenu`}>
          <TokenIcon iconName={id} size={16} customClass={`${themeClass}_overflowMenu_svg_icon`} />
          <div className={`${themeClass}_overflowMenu_label`}> {title} </div>
        </div>
      </>
    );

    return (
      <DropdownMenuItem
        isMulti={false}
        size={'md'}
        disabled={false}
        title={itemDropdown}
        value={item.id}
        onItemClick={handleChangeOverflowMenuSelect}
        tooltip={null}
        isSelected={!!anchorEl && isOpenedCommitmentVersions && item.id === 'download'}
        leftElem={null}
        leftElemClick={null}
        rightElem={null}
        rightElemClick={null}
      />
    );
  };

  const handleOpenAuthor = () => {
    setIsOpenCreateBy(true);
  };

  const handleCloseAuthor = () => {
    setIsOpenCreateBy(false);
  };

  const handleOpenConfirmPopup = e => {
    setIsOpenConfirmPopup(isOpenConfirmPopup ? null : e.currentTarget);
  };

  const changeHandlerDeclineMsg = (value: string) => {
    setDeclineMsg(value);
  };

  const handleNewDueDate = (status: string) => () => {
    dispatch(
      updateNewDueDate({
        status: status,
        id: selectedRequest?.due_date_change_request?.id,
        declined_comment: declineMsg,
      }),
    );
  };

  const handleOpenDeclinePopover = (field?: string) => e => {
    setIsDeclinePopover(isDeclinePopover ? null : e?.currentTarget || null);
    setDeclineField(field);
    setDeclineMsg('');
  };

  const handleNewCompany = (status: string) => () => {
    dispatch(
      updateNewResponseCompany({
        status: status,
        id: selectedRequest?.response_company_change_request?.id,
        declined_comment: declineMsg,
        callback: null,
      }),
    );
  };

  const handleSendDecline = () => {
    if (declineField === 'company') {
      handleNewCompany('rejected')();
    }
    if (declineField === 'dueDate') {
      handleNewDueDate('rejected')();
    }
  };

  const handleClosePopovers = () => {
    setIsDeclinePopover(null);
    setIsOpenConfirmPopup(null);
  };

  const handleNavigate = (to: 'down' | 'up' | 'full-up' | 'full-down') => () => {
    const currentIndex = needListData.findIndex(card => card.id === (selectedRequest.parent_id || selectedRequest.id));

    let cardToNavigate;

    if (to === 'down' || to === 'up') {
      cardToNavigate = needListData[to === 'down' ? currentIndex + 1 : currentIndex - 1];
    } else {
      cardToNavigate = needListData[to === 'full-down' ? needListData.length - 1 : 0];
    }

    if (breadcrumbs?.length) {
      const newBreadcrumbs: BreadcrumbModel[] =
        (breadcrumbs.length === 2 && breadcrumbs.some(card => card.id === cardToNavigate.id)) || breadcrumbs.length === 1
          ? []
          : breadcrumbs
              .filter(card => card.id !== cardToNavigate.id)
              .map(card => {
                if (card.id === (selectedRequest.parent_id || selectedRequest.id)) {
                  return { id: cardToNavigate.id, title: `NF ${cardToNavigate.nf}`, nf: cardToNavigate.nf, breadcrumbType: 'nf' };
                } else {
                  return { ...card };
                }
              });

      dispatch(setNewBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs', array: newBreadcrumbs }));
    }
    setUrlNF(String(cardToNavigate.nf), activeTab);
  };

  useEffect(() => {
    if (!selectedRequest?.due_date_change_request?.new_due_date) {
      handleClosePopovers();
    }
  }, [selectedRequest?.due_date_change_request?.new_due_date]);

  const milestonesData = useMemo(() => {
    if (milestones) {
      const data = milestones.map(item => {
        const objLOD: MilestoneItemModelExt = { ...item };
        objLOD.titleLOD = item.title;
        objLOD.value = item.id;
        objLOD.title = `${item.code}. ${item.title}`;
        return objLOD;
      });
      return data;
    } else {
      return [];
    }
  }, [milestones]);

  const selectedMilestoneData = useMemo(() => {
    if (selectedMilestone) {
      const objLOD: MilestoneItemModelExt = { ...selectedMilestone };
      objLOD.titleLOD = selectedMilestone.title;
      objLOD.value = selectedMilestone.id;
      objLOD.title = `${selectedMilestone.code}. ${selectedMilestone.title}`;
      return objLOD;
    } else {
      return null;
    }
  }, [selectedMilestone]);

  const renderBreadcrumbs = () => {
    return (
      <>
        {!is1440Resolution && !previewCard && !isSeparateTab ? <Divider direction={'vertical'} type={'srf-4'} /> : null}
        {!previewCard || !isSeparateTab ? (
          <CardBreadcrumbs
            breadcrumbs={breadcrumbs}
            selectedRequest={selectedRequest || (isPullRequest && parentSelectedRequest)}
            originalRequestId={originalRequestId}
            openRequest={selectRequestHandler}
            clearBreadcrumbs={clearBreadcrumbs}
            isRequestLoading={requestLoading || getRequestLoadingStatus}
            changeRevision={handleChangeRevision}
            isPullRequest={isPullRequest}
            closePullRequest={closePullRequest}
            setNfLinkOpenId={null}
            isPcdCardPopUp={false}
            isMobile={isMobile}
            isSidePeek={isDragRequestCard || is1440Resolution}
            isTruncated
            truncateAfterNumber={truncateBreadcrumbsNumber}
            previewCard={previewCard}
          />
        ) : null}
      </>
    );
  };

  const renderNavigationArrows = () => {
    const isUpDisabled = needListData?.[0]?.id === selectedRequest?.id;
    const isDownDisabled = needListData?.[needListData.length - 1]?.id === selectedRequest?.id;

    return (
      <>
        <Divider direction={'vertical'} type={'srf-4'} />
        <div className={`${themeClass}_arrows`}>
          <div className={`${themeClass}_arrows_side`}>
            <SystemButton
              type={'chevron-double-up'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isNeedListLoadingStatus || !selectedRequest || isUpDisabled || isNeedListGrouped}
              clickHandler={handleNavigate('full-up')}
            />
            <SystemButton
              type={'chevron-up'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isNeedListLoadingStatus || !selectedRequest || isUpDisabled || isNeedListGrouped}
              clickHandler={handleNavigate('up')}
            />
          </div>
          <div className={`${themeClass}_arrows_side`}>
            <SystemButton
              type={'chevron-down'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isNeedListLoadingStatus || !selectedRequest || isDownDisabled || isNeedListGrouped}
              clickHandler={handleNavigate('down')}
            />
            <SystemButton
              type={'chevron-double-down'}
              size={'md'}
              variant={'transparent'}
              disabled={!!isNeedListLoadingStatus || !selectedRequest || isDownDisabled || isNeedListGrouped}
              clickHandler={handleNavigate('full-down')}
            />
          </div>
        </div>
      </>
    );
  };

  const renderNfButtonContent = () => {
    return (
      <div className={`${themeClass}_nfBtn`}>
        {isSkeletonLoading ? (
          <CustomSkeleton width={isMobile ? 150 : 350} height={28} />
        ) : (
          <>
            <CardLinkButton
              type={'nf'}
              size={'md'}
              isMobile={isMobile}
              title={`NF ${selectedRequest ? selectedRequest.nf : ''} ${
                selectedRequest?.versions?.length || selectedRequest?.parent_id ? '(' + selectedRequest.version + ')' : ''
              }`}
              tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Need Form number'}
              clickHandler={e => (isCreateRequest || isPullRequest ? null : handleVersionsPopover(e))}
            />
            {!showSmall && (
              <div
                className={`${themeClass}_statusNFContainer`}
                ref={headerCentralStatusNFRef}
                onMouseEnter={() => handleOpenStatuses()}
                onMouseLeave={() => handleHideStatuses()}
                onClick={() => {
                  handleShowStatuses();
                  handleSwitchAlwaysShowStatuses();
                }}
              >
                <CustomTooltip
                  enterDelay={500}
                  leaveDelay={0}
                  title={localStorage.getItem('show_tooltips') === 'no' ? '' : 'NF status'}
                  placement={'bottom'}
                >
                  <div>
                    {selectedRequest && (
                      <StatusNF
                        status={
                          selectedRequest?.is_deactivated === 1
                            ? 'deactivated'
                            : selectedRequest.status === 'declined' && !selectedRequest.parent_id
                            ? 'Re-opened'
                            : selectedRequest?.status
                        }
                        isShowHover
                        isActive={isAlwaysShowStatuses}
                      />
                    )}
                  </div>
                </CustomTooltip>
              </div>
            )}
            {!isMobile && (
              <div className={'history_row_created'} ref={refCreatedBy} onMouseEnter={handleOpenAuthor} onMouseLeave={handleCloseAuthor}>
                {selectedRequest?.created_at && (
                  <div className={'container_dateRow'}>
                    {created_icon}
                    <DateRow date={selectedRequest?.created_at} />
                  </div>
                )}
                {isOpenCreateBy && (
                  <PopupCreatedBy
                    user={selectedRequest?.author}
                    create_date={selectedRequest?.created_at}
                    refCreatedBy={refCreatedBy}
                    forShowSmall={showSmall}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderDueDateContent = () => {
    if (isMobile) {
      return (
        <SandBoxDate
          saveChangeHandler={selectAtDateHandler}
          valueDate={currentDate || (selectedRequest && selectedRequest.due_date)}
          disabled={isDueDateDisabled}
          error={errors?.due_date}
          isUserInRequestParty={isUserInRequestParty}
          isAuthorOfTheRequest={isAuthorOfTheRequest}
          isCreateRequest={isCreateRequest}
          isPullRequest={isPullRequest}
          selectedRequest={selectedRequest}
          currentDate={currentDate}
          promptName={promptName}
          is_promptBtnOk={is_promptBtnOk}
          requestToNewDueDate={requestToNewDueDate}
          min_due_date={min_due_date}
          max_due_date={max_due_date}
          isNfCard={true}
          work_week={work_week}
          activeCommitmentAction={activeCommitmentAction}
          setCommitmentAction={setCommitmentAction}
          currentPCD={currentPCD}
          listPCD={listPCD}
          statusRequest={selectedRequest && StatusNameHelper.getLastUserStatusName(selectedRequest)}
          isShowDueDatePopup={isShowDueDatePopup}
          handleOpenConfirmPopup={handleOpenConfirmPopup}
          isHideTitle={true}
          isHideDate={true}
          isMobile={isMobile}
          handleCloseAllNotificationPopups={handleCloseAllNotificationPopups}
          isAllNotificationPopupsClosed={isAllNotificationPopupsClosed}
          isDeliverablesPopUpOpened={isDeliverablesPopUpOpened}
          isDisableBorder
        />
      );
    }

    return (
      <>
        {isSkeletonLoading ? (
          <CustomSkeleton width={167} height={28} />
        ) : (
          <SandBoxDate
            saveChangeHandler={selectAtDateHandler}
            valueDate={currentDate || (selectedRequest && selectedRequest.due_date)}
            disabled={isDueDateDisabled}
            error={errors?.due_date}
            isUserInRequestParty={isUserInRequestParty}
            isAuthorOfTheRequest={isAuthorOfTheRequest}
            isCreateRequest={isCreateRequest}
            isPullRequest={isPullRequest}
            selectedRequest={selectedRequest}
            currentDate={currentDate}
            promptName={promptName}
            is_promptBtnOk={is_promptBtnOk}
            requestToNewDueDate={requestToNewDueDate}
            min_due_date={min_due_date}
            max_due_date={max_due_date}
            isNfCard={true}
            work_week={work_week}
            activeCommitmentAction={activeCommitmentAction}
            setCommitmentAction={setCommitmentAction}
            currentPCD={currentPCD}
            listPCD={listPCD}
            statusRequest={selectedRequest && StatusNameHelper.getLastUserStatusName(selectedRequest)}
            isShowDueDatePopup={isShowDueDatePopup}
            handleOpenConfirmPopup={handleOpenConfirmPopup}
            handleCloseAllNotificationPopups={handleCloseAllNotificationPopups}
            isAllNotificationPopupsClosed={isAllNotificationPopupsClosed}
            isDeliverablesPopUpOpened={isDeliverablesPopUpOpened}
          />
        )}
      </>
    );
  };

  const breadcrumbsActive = useMemo(() => {
    return !!breadcrumbs?.length;
  }, [breadcrumbs?.length]);

  const renderWatchlist = () => {
    return (
      <Watchlist
        type={'request'}
        watchers={requestWatchers || []}
        requestId={selectedRequest?.id}
        disabled={selectedRequest?.is_deactivated == 1 || selectedRequest?.parent_id || isCreateRequest}
        project_id={selectedRequest?.project_id}
        isPrivate={isRfi}
        isMenuCustomOpenAnchor={isWatchlistMobileOpen}
        onCloseWatchlist={handleCloseMobileWatchlist}
        linkedGroups={requestWatchersGroups || []}
      />
    );
  };

  return (
    <>
      <div className={`${themeClass}`} ref={headerRef}>
        <div
          className={cn(`${themeClass}_container`, {
            ['-hasBreadcrumbs']: breadcrumbsActive,
          })}
        >
          <div className={cn(`${themeClass}_switcher`, { ['-requestDataToCopy']: isPullRequest && !isPullRequestDataToCopyFormOriginal })}>
            {!isSeparateTab &&
              !previewCard &&
              !is1440Resolution &&
              (showSmall ? (
                <ContentSwitcher type={'central-peek'} size={'sm'} clickHandler={clickHandler} />
              ) : (
                <ContentSwitcher
                  type={'right-peek'}
                  size={'sm'}
                  clickHandler={() => {
                    setIsAlwaysShowStatuses(false);
                    clickHandler();
                  }}
                />
              ))}
            {!isSeparateTab &&
              !isCreateRequest &&
              !isPullRequest &&
              !previewCard &&
              !isMobile &&
              isNeedListModule &&
              renderNavigationArrows()}
            {!isMobile && breadcrumbsActive ? renderBreadcrumbs() : null}
            {isMobile ? renderNfButtonContent() : null}
            {isMobile && <div className={`${themeClass}_dueDateMobile`}>{renderDueDateContent()}</div>}
            {isPullRequest && (
              <>
                <Divider direction={'vertical'} type={'srf-4'} customClassname={`${themeClass}_pullRequest_divider`} />
                <div className={`${themeClass}_pullRequest`}>
                  <CustomTooltip
                    enterDelay={500}
                    leaveDelay={0}
                    title={showSmall ? `Pull request from NF ${parentSelectedRequest?.nf}` : ''}
                    placement={'bottom'}
                  >
                    <TokenIcon iconName={'pull'} size={12} />
                  </CustomTooltip>
                  {!showSmall && (
                    <div className={`${themeClass}_pullRequest_label`}>{`Pull request from NF ${parentSelectedRequest?.nf}`}</div>
                  )}
                </div>
                <CustomButton
                  type={'text-plain'}
                  size={'xs'}
                  clickHandler={handleCopyDataFromOriginal}
                  title={isPullRequestDataToCopyFormOriginal ? 'Clear data' : 'Copy data from original request'}
                  loading={false}
                  disabled={false}
                />
              </>
            )}
          </div>
          <div className={`${themeClass}_action`}>
            {isSkeletonLoading ? (
              <>
                <CustomSkeleton width={110} height={20} sx={{ borderRadius: 16 }} />
                <CustomSkeleton width={110} height={20} sx={{ borderRadius: 16 }} />
                <CustomSkeleton width={110} height={20} sx={{ borderRadius: 16 }} />
              </>
            ) : (
              <>
                <div>
                  {!!selectedRequest?.is_incorporated && (
                    <OvalButton
                      showTitle={!isDragRequestCard}
                      title={'Response incorporated'}
                      type={'incorporation'}
                      tooltip={isDragRequestCard ? 'Response incorporated' : ''}
                      clickHandler={() => onChangeActiveTab('incorporation')}
                    />
                  )}
                </div>
                <div>
                  {selectedRequest?.bic_manager_id ? (
                    <BICorMentionSelect
                      showBasisTitle={!isDragRequestCard && !isMobile && !isPullRequest}
                      selectedRequest={selectedRequest}
                      type={'request'}
                      forOpen={'bic'}
                      onChangeActiveTab={null}
                    />
                  ) : (
                    <OvalButton
                      showTitle={!isDragRequestCard && !isMobile && !isPullRequest}
                      disabled
                      title={'Unassigned'}
                      type={'bic-unassigned'}
                    />
                  )}
                </div>
                <div>
                  {selectedRequest?.secondary_courts?.length ? (
                    <BICorMentionSelect
                      showBasisTitle={!isDragRequestCard && !isMobile && !isPullRequest}
                      selectedRequest={selectedRequest}
                      type={'request'}
                      forOpen={'mention'}
                      onChangeActiveTab={onChangeActiveTab}
                    />
                  ) : (
                    <OvalButton
                      showTitle={!isDragRequestCard && !isMobile && !isPullRequest}
                      disabled
                      title={'No mentions'}
                      type={'mention-unassigned'}
                    />
                  )}
                </div>
              </>
            )}

            {isHotListManually && (
              <ActionButton
                type={'hotlisted'}
                clickHandler={null}
                isMobile={isMobile}
                tooltip={'Item became hot due to system rules. You cannot unmark it manually'}
                disabled
              />
            )}
            {isHotList ? (
              <ActionButton
                type={'starred'}
                clickHandler={saveHotlistLoading.loading ? void 0 : handleSetHotList}
                disabled={isCreateRequest}
                isMobile={isMobile}
              />
            ) : (
              <ActionButton
                type={'not-starred'}
                clickHandler={saveHotlistLoading.loading || isCreateRequest ? void 0 : handleSetHotList}
                disabled={isCreateRequest}
                isMobile={isMobile}
              />
            )}
            {isMobile && renderWatchlist()}
            <div ref={overflowMenuRef} onClick={e => (isCreateRequest ? null : handleMenuDownloadListOpen(e))}>
              <OverflowMenuButton size={isMobile ? 'md' : 'sm'} tooltip={''} disabled={isDraftedNf || isCreateRequest} />
            </div>
            <DropdownDownloadList<DropdownItemModelExt>
              isMenuOpen={isMenuDownloadListOpen}
              customClassName={null}
              renderItemHeader={null}
              renderItemFooter={null}
              renderMenuItem={renderMenuItem}
              menuItems={filteredOverflowMenuItems}
              size={'md'}
              anchorEl={anchorEl}
              onOpen={null}
              onClose={closeMenuDownloadList}
            />
            {!isSeparateTab && (
              <SystemButton isMobile={isMobile} type={'close'} size={'md'} variant={'transparent'} clickHandler={e => requestHeadClose()} />
            )}
          </div>
          {!isMobile && renderNfButtonContent()}
          {showSmall && (
            <div
              className={`${themeClass}_statusNf`}
              ref={headerStatusNFRef}
              onMouseEnter={handleOpenStatuses}
              onMouseLeave={handleHideStatuses}
            >
              <CustomTooltip
                enterDelay={500}
                leaveDelay={0}
                title={localStorage.getItem('show_tooltips') === 'no' ? '' : 'NF status'}
                placement={'bottom'}
              >
                <div>
                  {selectedRequest && (
                    <StatusNF
                      status={
                        selectedRequest?.is_deactivated === 1
                          ? 'deactivated'
                          : selectedRequest.status === 'declined' && !selectedRequest.parent_id
                          ? 'Re-opened'
                          : selectedRequest?.status
                      }
                      isShowHover
                      isActive={isShowStatuses}
                      isMobile={isMobile}
                    />
                  )}
                </div>
              </CustomTooltip>
            </div>
          )}
          <div className={`${themeClass}_request`}>
            {isSkeletonLoading ? (
              <>
                <CustomSkeleton width={163} height={28} />
              </>
            ) : (
              <div className={`${themeClass}_selectedFilter`}>
                <CustomTooltip
                  enterDelay={500}
                  leaveDelay={0}
                  title={
                    localStorage.getItem('show_tooltips') === 'no'
                      ? ''
                      : selectedTags !== null || isOpenRequestType
                      ? ''
                      : 'Specify the Request intent by choosing one of predefined types'
                  }
                  placement={'bottom'}
                >
                  <Select<DropdownItemModelExt, null, false>
                    isMulti={false}
                    menuItems={tags}
                    selectHandler={values => handleChangeRequestTypeSelect(values)}
                    selected={selectedTags}
                    menuItemSize={'md'}
                    onMenuOpen={handleOpenRequestType}
                    onMenuClose={handleCloseRequestType}
                    menuItemTitleRenderer={renderSingleSelectRequestType}
                    renderCustomBasis={isActive => {
                      return (
                        <ChipsRequestType
                          type={selectedTags?.title || 'Select request type'}
                          isPressed={isActive}
                          customIcon={chevron_down_icon}
                          customIconClass={'selectIcon'}
                          onClickIcon={null}
                          isMobile={isMobile}
                        />
                      );
                    }}
                    disabled={!(isAccess || isPullRequest)}
                    isMobile={isMobile}
                  />
                </CustomTooltip>
              </div>
            )}
            {/*{!isMobile &&*/}
            {/*  (isSkeletonLoading ? (*/}
            {/*    <CustomSkeleton width={100} height={28} />*/}
            {/*  ) : (*/}
            {/*    <div className={`${themeClass}_selectedFilter`}>*/}
            {/*      <CustomTooltip*/}
            {/*        enterDelay={500}*/}
            {/*        leaveDelay={0}*/}
            {/*        title={*/}
            {/*          localStorage.getItem('show_tooltips') === 'no'*/}
            {/*            ? ''*/}
            {/*            : selectedMilestone !== null || isOpenMilestones*/}
            {/*            ? ''*/}
            {/*            : 'Link the Need Form with a Phase Plan milestone.'*/}
            {/*        }*/}
            {/*        placement={'bottom'}*/}
            {/*      >*/}
            {/*        <Select<DropdownItemModelExt, null, false>*/}
            {/*          isMulti={false}*/}
            {/*          isHeader*/}
            {/*          menuItems={milestonesData}*/}
            {/*          selectHandler={values => handleChangeLODSelect(values)}*/}
            {/*          selected={selectedMilestoneData}*/}
            {/*          menuItemSize={'md'}*/}
            {/*          onMenuOpen={handleOpenMilestones}*/}
            {/*          onMenuClose={handleCloseMilestones}*/}
            {/*          menuItemTitleRenderer={renderSingleSelectLod}*/}
            {/*          renderCustomBasis={isActive => {*/}
            {/*            return (*/}
            {/*              <ChipsLOD*/}
            {/*                type={'filled'}*/}
            {/*                value={selectedMilestoneData?.code || 'Milestone'}*/}
            {/*                isPressed={isActive}*/}
            {/*                customIcon={chevron_down_icon}*/}
            {/*                customIconClass={'selectIcon'}*/}
            {/*                onClickIcon={null}*/}
            {/*              />*/}
            {/*            );*/}
            {/*          }}*/}
            {/*          disabled={!(isAccess || isPullRequest)}*/}
            {/*        />*/}
            {/*      </CustomTooltip>*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {!showSmall && (
              <div className={'actionsBtnDueDate'}>
                {!showSmall && renderDueDateContent()}
                {!showSmall && (
                  <div className={'actionBtnContainer'}>
                    <GeometryObjects geometries={selectedRequest?.geometries} />
                    <ActionButton
                      type={isRfi ? 'privat' : 'not-privat'}
                      clickHandler={e => handleChangePrivate()}
                      disabled={!isAccess || !!selectedRequest?.parent_id}
                    />
                    {!isMobile && renderWatchlist()}
                    <ActionButton type={is_procore_sync_needed ? 'sync' : 'not-sync'} clickHandler={handleOpenProcorConfirmPopUp} />
                  </div>
                )}
              </div>
            )}
          </div>
          {showSmall && !isMobile && (
            <div className={`${themeClass}_actionBtn`}>
              {!isMobile && renderDueDateContent()}
              <GeometryObjects geometries={selectedRequest?.geometries} />
              <ActionButton
                type={isRfi ? 'privat' : 'not-privat'}
                clickHandler={e => handleChangePrivate()}
                disabled={!isAccess || !!selectedRequest?.parent_id}
              />
              <Watchlist
                type={'request'}
                watchers={requestWatchers || []}
                requestId={selectedRequest?.id}
                disabled={selectedRequest?.is_deactivated == 1 || selectedRequest?.parent_id || isCreateRequest}
                project_id={selectedRequest?.project_id}
                isPrivate={isRfi}
                linkedGroups={selectedRequest?.watcher_groups}
              />
              <ActionButton type={is_procore_sync_needed ? 'sync' : 'not-sync'} clickHandler={handleOpenProcorConfirmPopUp} />
            </div>
          )}
          {isMobile && breadcrumbsActive ? <div className={`${themeClass}_breadcrumbs`}>{renderBreadcrumbs()}</div> : null}
        </div>
        <div className={`${themeClass}_line_nf`}></div>
      </div>
      {(isShowStatuses || isHoverStatuses || isAlwaysShowStatuses) && (
        <RequestStatuses
          selectedRequest={selectedRequest}
          hoverStatuses={handleHoverStatuses}
          isShowStatuses={isShowStatuses || isHoverStatuses || isAlwaysShowStatuses}
          headerRef={isDropdown ? (showSmall ? headerStatusNFRef : headerCentralStatusNFRef) : headerRef}
          isDragRequestCard={isDragRequestCard}
          isDropdown={isDropdown}
        />
      )}
      {isOpenedVersions && (
        <Popover
          id={'decline-reason'}
          open={!!isOpenedVersions}
          anchorEl={isOpenedVersions}
          onClose={handleVersionsPopover}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={`${themeClass}_dialogContentDownload`}>
            <RequestVersions version={selectedRequest} oneVersion={true} />
          </div>
        </Popover>
      )}
      {isOpenedCommitmentVersions && (
        <Popover
          id={'decline-reason'}
          open={!!isOpenedCommitmentVersions}
          anchorEl={isOpenedCommitmentVersions}
          onClose={handleCommitmentVersionsPopover}
          classes={{
            paper: cn(`${themeClassCallout} ${themeClassPaper} ${themeClass}_revisionsPaper`, {
              ['-mobile']: isMobile,
              ['-download']: anchorEl,
            }),
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: anchorEl ? 'right' : 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: anchorEl ? 'right' : 'left',
          }}
        >
          <CommitmentVersions
            selectedRequest={selectedRequest}
            changeVersion={changeCommitmentVersion}
            forOpen={openedCommitmentVersions}
          />
        </Popover>
      )}
      <DialogPopUp
        isCard
        open={isProcorConfirmPopUpOpen}
        onClose={handleCloseProcorConfirmPopUp}
        isOverlay={false}
        paperMaxWidth={'661px'}
        title={'This NF will become synchronized with Procore'}
        dividedHeader
        customSecondaryType={'tertiary'}
        tertiaryText={'Cancel'}
        handleOnTertiary={handleCloseProcorConfirmPopUp}
        primaryText={'Okay'}
        handleOnPrimary={handleChangeProcoreSyncStatus}
        renderModalContent={() => <div className={`${dialogClass}_content_modalText`}>You’ll be able to turn synchronization off.</div>}
      />
      {isDeactivationPopUpOpen && (
        <DialogPopUp isCard open={isDeactivationPopUpOpen} onClose={cancelDeactivatedStatus} isOverlay={true}>
          <div className={`${themeClassPopUpDeactivation}`}>
            <div className={`${themeClassPopUpDeactivation}_header`}>
              <div className={`${themeClassPopUpDeactivation}_header_title`}>
                {deactivationItem?.action === 'activate'
                  ? `Activate NF# ${deactivationItem?.nf}?`
                  : `Deactivate NF# ${deactivationItem?.nf}?`}
              </div>
            </div>
            <div className={`${themeClassPopUpDeactivation}_container`}>
              {deactivationItem?.action === 'activate' ? (
                <div className={'dialog-text'}>This NF will become activated, you'll be able to deactivate it back.</div>
              ) : (
                <div className={'dialog-text'}>This NF will become deactivated, you'll be able to activate it back.</div>
              )}
            </div>
            <div className={`${themeClassPopUpDeactivation}_button_group`}>
              <CustomButton type={'tertiary'} size={'md'} clickHandler={cancelDeactivatedStatus} title={'Cancel'} />
              <CustomButton
                type={'primary'}
                size={'md'}
                clickHandler={handleDeactivateNf}
                title={deactivationItem?.action === 'activate' ? 'Yes, activate' : 'Yes, deactivate'}
              />
            </div>
          </div>
        </DialogPopUp>
      )}
      {isOpenConfirmPopup && (
        <Popover
          id={'decline-reason'}
          open={!!isOpenConfirmPopup}
          anchorEl={isOpenConfirmPopup}
          onClose={handleOpenConfirmPopup}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={`${themeClassDueChange}`}>
            <div className={`${themeClassDueChange}_header_title`}>Requested the due date change</div>
            <div className={`${themeClassDueChange}_dialog`}>
              <div className={`${themeClassDueChange}_dialog_text`}>from</div>
              <div className={`${themeClassDueChange}_dialog_text text_warning`}>
                {moment(selectedRequest.due_date).format('MM/DD/YYYY')}
              </div>
              <div className={`${themeClassDueChange}_dialog_text`}>to</div>
              <div className={`${themeClassDueChange}_dialog_text text_warning`}>
                {selectedRequest?.due_date_change_request?.new_due_date
                  ? ' ' + moment(selectedRequest.due_date_change_request.new_due_date).format('MM/DD/YYYY')
                  : ''}
              </div>
            </div>
          </div>
          <div className={`${themeClassDueChange}_button_group`}>
            <CustomButton type={'accept'} size={'md'} clickHandler={() => handleNewDueDate('accepted')()} title={'Accept'} />
            <CustomButton type={'decline'} size={'md'} clickHandler={e => handleOpenDeclinePopover('dueDate')(e)} title={'Decline'} />
          </div>
        </Popover>
      )}
      {isDeclinePopover && (
        <Popover
          id={'decline-reason'}
          open={!!isDeclinePopover}
          anchorEl={isDeclinePopover}
          onClose={handleOpenDeclinePopover()}
          classes={{
            paper: `${themeClassCallout} ${themeClassPaper}`,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            transform: 'translate(36px, -100px)',
          }}
        >
          <div className={`${themeClassDueChangeDecline}`}>
            <div className={`${themeClassDueChangeDecline}_dialog`}>
              <TextInputNew
                type={'on-bgd'}
                size={'sm'}
                label={'Decline reason'}
                placeholder={'Enter a decline reason'}
                onChange={changeHandlerDeclineMsg}
                value={declineMsg || ''}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                width={320}
              />
            </div>
          </div>
          <div className={`${themeClassDueChangeDecline}_button_group`}>
            <CustomButton type={'text-plain'} size={'md'} clickHandler={e => handleOpenDeclinePopover()(e)} title={'Cancel'} />
            <CustomButton type={'decline'} size={'md'} clickHandler={handleSendDecline} title={'Decline'} disabled={!declineMsg} />
          </div>
        </Popover>
      )}
    </>
  );
};

export default React.memo(Header);
