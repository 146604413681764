import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PopUpView from '../../../../controls/PopUp/PopUpView';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import whatsNew from '../NewsContents';
import { isEmptyObject } from '../../../../../helpers/commonHelpers';
import { string } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { setReadNewsStatus, setReadAllNewsStatus } from '../../../../../store/support/supportThunk';

class WhatNews extends Component {
  state = {
    whatsNewContents: whatsNew,
    statuses: [],
    isOpenIMG: false,
    imgSRC: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.whatNews && this.props.whatNews !== prevProps.whatNews && !this.props.getWhatNewsStatus.loading) {
      this.setState({
        statuses: Object.values(this.props.whatNews),
      });
    }
  }

  handleSeen = (id, notification_id) => {
    const seen = state => {
      const newState = state;

      newState[id].isComponentOpen = !state[id].isComponentOpen;
      return newState;
    };

    this.setReadStatus(id, notification_id);

    this.setState(prev => ({
      whatsNewContents: seen(prev.whatsNewContents),
    }));
  };

  handleReadAll = () => {
    this.props.setReadAllNewsStatus();
  };

  setReadStatus = (id, notification_id, e) => {
    const statusIndex = this.state.statuses.findIndex(f => f.id === notification_id);

    if (!this.state.statuses[statusIndex]?.is_read) {
      this.props.setReadNewsStatus(notification_id);
    }
    /*if (e?.target.tagName === 'IMG') {
        this.setState({
          isOpenIMG: true,
          imgSRC: e.target.src,
        });
    }*/
  };

  onCloseIMG = () => {
    this.setState({
      isOpenIMG: false,
    });
  };

  render() {
    const { whatsNewContents, statuses, isOpenIMG, imgSRC } = this.state;

    const { open, close } = this.props;
    const items = whatsNewContents;

    return (
      <>
        <PopUpView
          open={open}
          onClose={close}
          title={`What's new`}
          maxWidth={'md'}
          disableBtn={true}
          className={'library-popup sectionPopupContent whatsNewPopUp'}
          customPaperClass={'whatsNewContainer'}
        >
          <div className={'popup-content'} style={{ margin: '0px' }}>
            <div className={'b-whatNews__readAllContainer'}>
              <span className={'b-whatNews__readAll'} onClick={this.handleReadAll}>
                Read all
              </span>
            </div>
            <div className={'b-whatNews'}>
              {!isEmptyObject(statuses) &&
                items.map(item => {
                  const statusIndex = statuses.findIndex(f => f.id === item.notification_id);
                  return (
                    <>
                      <div key={item.id} className={'b-whatNews__item'} onClick={e => this.setReadStatus(item.id, item.notification_id, e)}>
                        <div className={`b-whatNews__date ${statuses[statusIndex]?.is_read ? '' : '-unread'}`}>
                          {moment(item.date).format('DD MMM, YYYY')}
                        </div>
                        <div className={`b-whatNews__title ${statuses[statusIndex]?.is_read ? '' : '-unread'}`}>{item.title}</div>
                        <div className={'whatsNewContent'}>
                          <div className={`${statuses[statusIndex]?.is_read ? '' : 'unreadWhatsNewText'}`}>{item.text}</div>
                          <div style={item.isComponentOpen ? {} : { display: 'none' }}>{item.component}</div>
                        </div>
                        {item.component ? (
                          <span onClick={() => this.handleSeen(item.id, item.notification_id)} className={'b-whatNews__showMoreNews'}>
                            {item.isComponentOpen ? 'Show Less' : 'Show More'}
                          </span>
                        ) : null}
                      </div>
                      <div className={'library-borderline'} />
                    </>
                  );
                })}
            </div>
          </div>
        </PopUpView>
        {false && (
          <Dialog
            open={isOpenIMG}
            onClose={this.onCloseIMG}
            maxWidth="false"
            PaperProps={{
              className: 'modalPaper',
              style: {
                maxWidth: '1500px',
              },
            }}
          >
            <div>
              <img style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover' }} src={imgSRC} alt="" />
            </div>
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ supportReducer, userReducer, asideReducer }) => ({
  userData: userReducer,
  whatNews: supportReducer.getWhatNews,
  getWhatNewsStatus: supportReducer.getWhatNewsStatus,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setReadNewsStatus,
      setReadAllNewsStatus,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(WhatNews);
