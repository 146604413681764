import React, { useEffect, useState } from 'react';
import { CustomButton } from '../ButtonComponents';
import DialogPopUp from '../DialogPopUp/DialogPopUp';
import { IncorporationTaskModel, UpdateTaskModel } from '../../../store/incorporation/incorporationReducer.model';
import CustomTooltip from '../Tooltip/Tooltip';
import { CodeSelector, Select } from '../Dropdowns/DropdownСomponents';
import { RequestBuildingModel, RequestModel, UfMfValueModel } from '../../../models';
import DateField from '../DateField/DateField';
import { Popover } from '@material-ui/core';
import CustomCalendar from '../CustomCalendar/CustomCalendar';
import moment from 'moment';
import { useAppSelector } from '../../../store/configure/configureStore';
import { useGetThemeClass } from '../../../helpers/designTokens';
import RedmineApi from '../../../service/Api/redmineApi';
import { withSnackbar } from 'notistack';
import { redmineTaskTrackers } from './parts/redmineTrackers';
import TokenIcon from '../TokenIcon/TokenIcon';
import { redmineSystemsList } from './parts/redmineSystems';

import './RedmineIncorporationStyles.scss';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;
type mainTaskEditTemplateErrors = 'company' | 'uf_ids';

interface IProps {
  data: IncorporationTaskModel;
  editableList: UpdateTaskModel[];
  handleChangeUfCode: (id: number, fieldName: string, selected: SelectedValue<UfMfValueModel, true>) => void;
  errors: mainTaskEditTemplateErrors[];
  handleSaveEditableList: () => void;
  selectedRequest: RequestModel;
  buildings: RequestBuildingModel[];
  enqueueSnackbar?: () => void;
}

const RedmineIncorporation = ({
  handleSaveEditableList,
  data,
  editableList,
  handleChangeUfCode,
  errors,
  buildings,
  selectedRequest,
  enqueueSnackbar,
}: IProps) => {
  const themeClass = useGetThemeClass('redmine-task-creation');

  const user_data = useAppSelector(state => state.userReducer);
  const project = useAppSelector(state => state.phasePlan.projectData);

  const today = new Date();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [redmineDueDate, setRedmineDueDate] = useState<string>(moment(today).format('YYYY-MM-DD'));
  const [isOpenCalendar, setIsOpenCalendar] = useState<EventTarget | null>(null);
  const [selectedRmSystem, setSelectedRmSystem] = useState<{ value: string; label: string; title: string }[] | null>(null);
  const [isLoadingRmTask, setIsLoadingRmTask] = useState<boolean>(false);
  const [rmProjectsList, setRmProjectsList] = useState<string[] | null>(null);
  const [selectedRmProject, setSelectedRmProject] = useState<string[] | null>(null);
  const [rmUserId, setRmUserId] = useState<string>('');
  const [isRmProjectListLoading, setIsRmProjectListLoading] = useState<boolean>(false);
  const [isAssignTaskLoading, setIsAssignTaskLoading] = useState<boolean>(false);
  const [isRedmineTaskAssigned, setIsRedmineTaskAssigned] = useState<boolean>(false);
  const [isRmTaskShouldBeCreated, setIsRmTaskShouldBeCreated] = useState<boolean>(false);
  const [assignTask, setAssignTask] = useState<{ id: number; title: string; value: string } | null>(null);
  const [isWorkflowDecided, setIsWorkflowDecided] = useState<boolean>(false);
  const [redmineTask, setRedmineTask] = useState<string[]>(['']);
  const [statusRedmineTask, setStatusRedmineTask] = useState(null);
  const [isLoadingRedmineTaskStasus, setIsLoadingRedmineTaskStatus] = useState<boolean>(false);
  const [selectedRmTracker, setSelectedRmTracker] = useState<{ rm_id: number; title: string; value: string } | null>(null);
  const [isTasksLoading, setIsTasksLoading] = useState<boolean>(false);
  const [projectTasksList, setProjectTasksList] = useState<{ id: number; title: string; value: string }[]>([]);
  const [systemError, setSystemError] = useState<string>(null);
  const [projectError, setProjectError] = useState<string>(null);
  const [taskAssignError, setTaskAssignError] = useState<string>(null);
  const [isRedmineKeyOk, setIsRedmineKeyOk] = useState<boolean>(false);

  const snackbarConfig = variant => {
    return {
      variant: variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    };
  };

  const errMessage = () => {
    enqueueSnackbar(`Something went wrong, please try again`, snackbarConfig('error'));
  };

  useEffect(() => {
    if (!user_data.userInfo.redmine_key) return;

    setIsRmProjectListLoading(true);
    let rmProjectListUpd = [];
    project.redmine?.map(item => {
      fetch(`${process.env.RM_BASE_URL}/projects/${item}.json`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'X-Redmine-API-Key': user_data.userInfo.redmine_key,
        },
        redirect: 'follow',
      })
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(item => {
          const rmProjectData = { id: item.project.id, item: item.project.identifier, title: item.project.name };
          rmProjectListUpd = [...rmProjectListUpd, rmProjectData];
          setRmProjectsList(rmProjectListUpd);
          setIsRedmineKeyOk(true);
        })
        .catch(err => {
          if (err.message === 'Unauthorized') return;
          errMessage();
        })
        .finally(() => {
          setIsRmProjectListLoading(false);
        });
    });
  }, [project.redmine, user_data.userInfo.redmine_key, setRmProjectsList]);

  useEffect(() => {
    if (!user_data.userInfo.redmine_key) return;

    fetch(`${process.env.RM_BASE_URL}/my/account.json`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Redmine-API-Key': user_data.userInfo.redmine_key,
      },
      redirect: 'follow',
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        const { user } = data;
        setRmUserId(user.id);
        setIsRedmineKeyOk(true);
      })
      .catch(err => {
        if (err.message === 'Unauthorized') return;
        errMessage();
      });
  }, [user_data.userInfo.redmine_key, setRmUserId]);

  useEffect(() => {
    const { redmine } = data;
    if (redmine?.length) {
      setRedmineTask(redmine);
      getRedmineTaskStatus(redmine);
      setIsRedmineTaskAssigned(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      rmProjectsList?.length === 1
        ? !isRmTaskShouldBeCreated && isWorkflowDecided
        : selectedRmProject && !isRmTaskShouldBeCreated && isWorkflowDecided
    ) {
      fetch(
        `${process.env.RM_BASE_URL}/issues.json?project_id=${
          rmProjectsList?.length === 1 ? rmProjectsList[0].item : selectedRmProject.item
        }&tracker_id=${
          selectedRmTracker ? selectedRmTracker.rm_id : redmineTaskTrackers.find(item => item.title === 'iRFI Incorporation').rm_id
        }`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'X-Redmine-API-Key': user_data.userInfo.redmine_key, //user api_key
          },
          redirect: 'follow',
        },
      )
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(data => {
          setIsTasksLoading(true);
          const { issues } = data;
          if (issues.length === 0) {
            return;
          }
          const tasksList = issues.map(item => {
            return { id: item.id, value: `#${item.id}: ${item.subject}`, title: `#${item.id}: ${item.subject}` };
          });
          setProjectTasksList(tasksList);
          setIsRedmineKeyOk(true);
        })
        .catch(err => {
          if (err.message === 'Unauthorized') return;
        })
        .finally(() => {
          setIsTasksLoading(false);
        });
    }
  }, [selectedRmProject, isRmTaskShouldBeCreated, isWorkflowDecided, selectedRmTracker, redmineTaskTrackers]);

  const getRedmineTaskStatus = taskId => {
    if (!user_data.userInfo.redmine_key) return;

    setIsLoadingRedmineTaskStatus(true);
    fetch(`${process.env.RM_BASE_URL}/issues/${taskId}.json`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Redmine-API-Key': user_data.userInfo.redmine_key,
      },
      redirect: 'follow',
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        const { issue } = data;
        const neededData = { status: issue.status, done_ratio: issue.done_ratio };
        setStatusRedmineTask(neededData);
        setIsLoadingRedmineTaskStatus(false);
        setIsRedmineKeyOk(true);
      })
      .catch(err => {
        setIsLoadingRedmineTaskStatus(false);
        if (err.message === 'Unauthorized') return;
        errMessage();
      });
  };

  const handleColorChangeOnStatus = status => {
    if (!redmineTask) {
      return 'status-grey';
    }
    switch (status) {
      case 'New':
      case 'Open':
        return 'status-purple';
      case 'In process':
        return 'status-yellow';
      case 'On-Hold':
        return 'status-blue';
      case 'QA Required':
        return 'status-orange';
      case 'Returned':
      case 'Excluded from the budget':
        return 'status-red';
      case 'QA Incorporated':
      case 'Done':
      case 'Closed':
        return 'status-green';
      default:
        return 'status-grey';
    }
  };

  const handleSaveResponse = async response => {
    const redmineIds = {
      owner_id: data.id,
      owner_type: 'incorporationTask',
      redmine_ids: [`${response}`],
      action: 'add',
    };
    await RedmineApi.setRedmineId(redmineIds);
    setRedmineTask([`${response}`]);
  };

  const result = editableList.find(({ id }) => {
    return id === data.id;
  });

  const isPlanitUser = () => {
    const domen = user_data.userInfo.email.split('@');
    if (domen.filter(item => item === 'planit-inc.com')) {
      return true;
    }
    return false;
  };

  const showButton = isPlanitUser() && result?.status !== 'not_required';

  const handleOpenModal = () => {
    setSelectedRmProject(null);
    setSelectedRmSystem(null);
    setIsModalOpen(true);
  };

  const changeDate = e => {
    const selectedDate = moment(e).format('YYYY-MM-DD');
    setRedmineDueDate(selectedDate);
  };

  const handleEmptyInput = () => {
    if (!selectedRmSystem) {
      setSystemError('System cannot be blank');
    }
    if (!selectedRmProject) {
      setProjectError('Project cannot be blank');
    }
    enqueueSnackbar('All fields should be filled', snackbarConfig('error'));
    return;
  };

  const createTask = () => {
    const subjectUF: string = result.uf_ids
      .map(({ code, description }) => {
        return code + ' ' + description;
      })
      .join(', ');

    const subtaskLevels = data.subtasks.map(({ building_id, building_title, level_id, level_title, level_position }) => {
      return { building_id, building_title, level_id, level_title, level_position };
    });

    const findBuildings = subtaskLevels
      .map(({ building_id, building_title }) => {
        return { building_id, building_title };
      })
      .filter((item, index, array) => array.findIndex(itemInner => item.building_id === itemInner.building_id) === index);

    const onlyBuilding = [];
    findBuildings.forEach(item => {
      onlyBuilding.push({
        [item.building_id]: subtaskLevels.filter(({ building_id }) => {
          return building_id === item.building_id;
        }),
      });
    });

    let levelString = '';

    const rangedLevels = (acc, inner, index, arr) => {
      const help = index > arr.length - 1 ? 0 : arr[index + 1]?.level_position;
      return (
        acc +
        `${index === 0 ? inner.level_title : ''}` +
        `${
          inner.level_position - help !== -1 && index !== 0
            ? `${
                inner.level_position - help < -1 ||
                (inner.level_position - help !== inner.level_position &&
                  inner.level_position === arr[index - 1].level_position + 1 &&
                  !arr[index + 1]?.level_title)
                  ? (!acc.includes(`${inner.level_title}`) ? ` - ${inner.level_title}` : '') +
                    `${
                      index !== arr.legth - 1 && inner.level_position - help !== -1 && index !== 0 && !!arr[index + 1]?.level_title
                        ? `, ${arr[index + 1]?.level_title}`
                        : ''
                    }`
                  : index !== arr.length - 1
                  ? `, ${inner.level_title}`
                  : ''
              }`
            : ''
        }`
      );
    };

    if (buildings?.length > 1) {
      findBuildings.map(({ building_id, building_title }) => {
        onlyBuilding.map(({ [building_id]: item }, index) => {
          if (!item) return;
          item.sort((a, b) => a.level_position - b.level_position);
          const range = item.reduce(rangedLevels, '');
          levelString = levelString + `${building_title} (${range}${onlyBuilding.length - 1 === index ? ')' : '); '}`;
        });
      });
    }
    if (buildings?.length <= 1) {
      levelString = subtaskLevels.sort((a, b) => a.level_position - b.level_position).reduce(rangedLevels, '');
    }
    let subjectLevels = '';
    if (findBuildings.length <= 1) subjectLevels = levelString.length > 100 ? 'Several levels' : levelString;
    if (findBuildings.length > 1) subjectLevels = levelString.length > 100 ? 'Several Buildings (Several levels)' : levelString;

    if (!selectedRmProject || !selectedRmSystem) {
      handleEmptyInput();
      return;
    }
    setIsLoadingRmTask(true);
    const issueRedmine = {
      issue: {
        project_id: `${selectedRmProject.item}`,
        tracker_id: selectedRmTracker.rm_id,
        subject: `${subjectUF}, Update as per NF#${selectedRequest.nf} - ${subjectLevels}`, //affectedLevels === '-' ? '' : affectedLevels
        due_date: redmineDueDate,
        description: 'Task created via Scheduler create task tool',
        priority_id: 2,
        assigned_to_id: rmUserId,
        custom_fields: [
          { id: 223, name: 'Affected Levels', value: levelString },
          {
            id: 103,
            name: 'System',
            value: `${selectedRmSystem.value}`,
          },
          {
            id: 8,
            name: 'Closed NFs',
            value: `"NF#${selectedRequest.nf}":${process.env.DOMAIN_SELF_URL}/${selectedRequest.project.code}/card?nf=${selectedRequest.nf}&tab=incorporation`,
          },
        ],
      },
    };
    fetch(`${process.env.RM_BASE_URL}/issues.json`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'X-Redmine-API-Key': user_data.userInfo.redmine_key,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(issueRedmine),
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        const { issue } = data;
        handleSaveResponse(issue.id);
        setRedmineTask([`${issue.id}`]);
        setIsRedmineTaskAssigned(true);
        getRedmineTaskStatus(issue.id);
        enqueueSnackbar(`Task #${issue.id} has been succesfully created`, snackbarConfig('success'));
      })
      .catch(err => {
        errMessage();
      })
      .finally(() => {
        setIsLoadingRmTask(false);
        setIsWorkflowDecided(false);
      });
  };

  const handleSubmit = () => {
    handleSaveEditableList();
    createTask();
  };

  const handleRemoveRedmineTask = async taskId => {
    const redmineIds = {
      owner_id: data.id,
      owner_type: 'incorporationTask',
      redmine_ids: [`${taskId}`],
      action: 'remove',
    };
    await RedmineApi.setRedmineId(redmineIds);
    setRedmineTask(['']);
    setAssignTask('');
    setIsRedmineTaskAssigned(false);
    setStatusRedmineTask(null);
    enqueueSnackbar(`Task #${taskId} has been succesfully unassigned`, snackbarConfig('success'));
  };

  const handleAssignTask = () => {
    if (!selectedRmProject) {
      setProjectError('Project cannot be blank');
      enqueueSnackbar('Project should be selected', snackbarConfig('error'));
      return;
    }
    if (!assignTask) {
      setTaskAssignError('Task should be selected');
      enqueueSnackbar('Task should be selected', snackbarConfig('error'));
      return;
    }
    getRedmineTaskStatus(assignTask.id);
    setIsAssignTaskLoading(true);
    fetch(`${process.env.RM_BASE_URL}/issues/${assignTask.id}.json`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Redmine-API-Key': user_data.userInfo.redmine_key,
      },
      redirect: 'follow',
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        const closedNfs = data.issue.custom_fields.find(item => {
          return item.id === 8;
        });
        const containThisNf = closedNfs.value.includes(selectedRequest.nf);
        if (containThisNf) return;
        const taskSubject = data.issue.subject.split(' ');
        const oldNF = taskSubject.findLast(item => item.includes('NF#'));
        if (oldNF) {
          const nfIndex = taskSubject.indexOf(oldNF);
          const newNFs = `${oldNF}, NF#${selectedRequest.nf}`;
          taskSubject.splice(nfIndex, 1, newNFs);
        }
        const newClosedNfsValue = `${closedNfs.value}, "NF#${selectedRequest.nf}":${process.env.DOMAIN_SELF_URL}/${selectedRequest.project.code}/card?nf=${selectedRequest.nf}&tab=incorporation`;
        const newClosedNfs = { ...closedNfs, value: newClosedNfsValue };

        const taskUpdate = { issue: { subject: taskSubject.join(' '), custom_fields: [newClosedNfs] } };

        return fetch(`${process.env.RM_BASE_URL}/issues/${assignTask.id}.json`, {
          method: 'PUT',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'X-Redmine-API-Key': user_data.userInfo.redmine_key,
          },
          redirect: 'follow',
          body: JSON.stringify(taskUpdate),
        });
      })
      .then(() => {
        enqueueSnackbar(`Task #${assignTask.id} has been succesfully assigned`, snackbarConfig('success'));
        setIsAssignTaskLoading(false);
      })
      .catch(err => {
        errMessage();
        setIsAssignTaskLoading(false);
      })
      .finally(() => {
        handleSaveResponse(assignTask.id);
        setIsRedmineTaskAssigned(true);
        setIsWorkflowDecided(false);
      });
  };

  const handleDefaultRedmineTracker = nfType => {
    switch (nfType) {
      case 'Standard request':
        setSelectedRmTracker(redmineTaskTrackers.find(item => item.title === 'Modeling'));
        break;

      default:
        setSelectedRmTracker(redmineTaskTrackers.find(item => item.title === 'iRFI Incorporation'));
        break;
    }
  };

  const initialInputRender = () => {
    return (
      <>
        {!user_data.userInfo.redmine_key && <div className={`${themeClass}_title`}>Please set your Redmine API key to proceed</div>}
        {user_data.userInfo.redmine_key && (
          <>
            {isRedmineTaskAssigned ? (
              <div>
                {redmineTask.map((item, id) => {
                  return (
                    <div className={`${themeClass}__taskContainer`} key={`${item}-${id}`}>
                      <a href={`${process.env.RM_BASE_URL}/issues/${item}`} target="_blank" rel="noreferrer">
                        <CustomButton
                          type={'tertiary'}
                          size={'md'}
                          title={`Task: ${item} | Status: ${statusRedmineTask?.status.name} | Done: ${statusRedmineTask?.done_ratio}%`}
                          loading={isLoadingRedmineTaskStasus}
                        />
                      </a>
                      <div className={`${themeClass}__btnContainer`}>
                        <CustomButton
                          type={'text-plain'}
                          size={'xs'}
                          title={'Refresh'}
                          clickHandler={() => getRedmineTaskStatus(item)}
                          loading={isLoadingRedmineTaskStasus}
                        />
                        <CustomButton
                          type={'text-plain'}
                          size={'xs'}
                          title={'Unassign task'}
                          clickHandler={() => handleRemoveRedmineTask(item)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              !isWorkflowDecided && (
                <div className={`${themeClass}__decideContainer`}>
                  <CustomButton
                    type={'tertiary'}
                    size={'sm'}
                    title={'I want to create Redmine task'}
                    clickHandler={() => {
                      setIsRmTaskShouldBeCreated(true);
                      setIsWorkflowDecided(true);
                      setSelectedRmProject(rmProjectsList?.length === 1 ? rmProjectsList[0] : null);
                    }}
                  />
                  <CustomButton
                    type={'tertiary'}
                    size={'sm'}
                    title={'I want to assign Redmine task'}
                    clickHandler={() => {
                      setIsRmTaskShouldBeCreated(false);
                      setIsWorkflowDecided(true);
                      setSelectedRmProject(rmProjectsList?.length === 1 ? rmProjectsList[0] : null);
                    }}
                    loading={isLoadingRedmineTaskStasus && !!redmineTask}
                  />
                </div>
              )
            )}
            {isRmTaskShouldBeCreated && isWorkflowDecided && (
              <div className={`${themeClass}`}>
                <div>
                  <div className={`${themeClass}_title`}>Scope</div>
                  <CustomTooltip
                    title={(result?.uf_ids?.length && result?.uf_ids.length > 2 && result?.uf_ids.map(item => item.code).join(', ')) || ''}
                    placement={'top'}
                  >
                    <CodeSelector
                      isMulti={true}
                      codeType={'uf'}
                      isValuesRemovable
                      selected={result?.uf_ids}
                      selectHandler={items => handleChangeUfCode(result?.id, 'uf_ids', items)}
                      error={!result?.uf_ids?.length && !!errors?.includes('uf_ids')}
                      basisPlaceholder={`Select UniFormat`}
                      menuItemSize={'md'}
                      basisMaxItems={2}
                      handleFor={'content-plan'}
                      disabled={false}
                    />
                  </CustomTooltip>
                </div>
                <div>
                  <div className={`${themeClass}_title`}>Redmine task due date</div>
                  <DateField
                    selectedDate={redmineDueDate ? moment(redmineDueDate).format('MM/DD/YYYY') : ''}
                    valueIsDate={!!redmineDueDate}
                    isSentRequestToChange={false}
                    clickHandler={e => setIsOpenCalendar(e.currentTarget)}
                    isOpenCalendar={false}
                    isDiffDueScheduledDate={false}
                    info={false}
                    disabled={false}
                    isBefore={false}
                    error={false}
                  />
                </div>
                <div>
                  <div className={`${themeClass}_title`}>System</div>
                  <Select
                    isMulti={false}
                    selectHandler={e => {
                      setSelectedRmSystem(e);
                      setSystemError(null);
                    }}
                    menuItems={redmineSystemsList}
                    selected={selectedRmSystem}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your system'}
                    basisMaxItems={2}
                    error={systemError}
                  />
                </div>
                <div>
                  <div className={`${themeClass}_title`}>Task type</div>
                  <Select
                    isMulti={false}
                    selectHandler={e => {
                      setSelectedRmTracker(e);
                    }}
                    menuItems={redmineTaskTrackers}
                    selected={selectedRmTracker ? selectedRmTracker : handleDefaultRedmineTracker(selectedRequest?.tags?.[0]?.title)}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your project'}
                    basisMaxItems={2}
                    isLoading={isRmProjectListLoading}
                  />
                </div>
                <div>
                  <div className={`${themeClass}_title`}>Redmine project</div>
                  <Select
                    isMulti={false}
                    selectHandler={e => {
                      setSelectedRmProject(e);
                      setProjectError(null);
                    }}
                    menuItems={rmProjectsList}
                    selected={rmProjectsList?.length === 1 ? rmProjectsList[0] : selectedRmProject}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your project'}
                    basisMaxItems={2}
                    isLoading={isRmProjectListLoading}
                    error={projectError}
                    disabled={rmProjectsList?.length === 1}
                    menuItemTooltipRenderer={item => item?.title}
                    customMenuItemClass={`${themeClass}__menuItem`}
                    customBasisFieldClass={`${themeClass}__menuBasis`}
                    basisTooltip={selectedRmProject?.title}
                    isDisableMinWidth={false}
                    isFullWidth={false}
                  />
                </div>
                <Popover
                  open={!!isOpenCalendar}
                  onClose={() => setIsOpenCalendar(null)}
                  anchorEl={isOpenCalendar as HTMLElement}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <CustomCalendar handleChange={changeDate} value={redmineDueDate} min_due_date={today} />
                </Popover>
                <CustomButton
                  type={'tertiary'}
                  title={'Create Redmine task'}
                  size={'sm'}
                  clickHandler={handleSubmit}
                  loading={isLoadingRmTask}
                />
              </div>
            )}
            {!isRmTaskShouldBeCreated && isWorkflowDecided && (
              <div className={`${themeClass}`}>
                <div>
                  <div className={`${themeClass}_title`}>Redmine project</div>
                  <Select
                    isMulti={false}
                    selectHandler={e => {
                      setSelectedRmProject(e);
                      setProjectError(null);
                      setAssignTask(null);
                    }}
                    menuItems={rmProjectsList}
                    selected={rmProjectsList?.length === 1 ? rmProjectsList[0] : selectedRmProject}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your project'}
                    basisMaxItems={2}
                    isLoading={isRmProjectListLoading}
                    error={projectError}
                    disabled={rmProjectsList?.length === 1}
                    menuItemTooltipRenderer={item => item?.title}
                    customMenuItemClass={`${themeClass}__menuItem`}
                    customBasisFieldClass={`${themeClass}__menuBasis`}
                    basisTooltip={selectedRmProject?.title}
                    isDisableMinWidth={false}
                    isFullWidth={false}
                  />
                </div>
                <div>
                  <div className={`${themeClass}_title`}>Task type</div>
                  <Select
                    isMulti={false}
                    selectHandler={e => {
                      setProjectTasksList([]);
                      setSelectedRmTracker(e);
                      setAssignTask(null);
                    }}
                    menuItems={redmineTaskTrackers}
                    selected={selectedRmTracker ? selectedRmTracker : redmineTaskTrackers.find(item => item.title === 'iRFI Incorporation')}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your project'}
                    basisMaxItems={2}
                    isLoading={isRmProjectListLoading}
                  />
                </div>
                <div>
                  <div className={`${themeClass}_title`}>Select Task</div>
                  <Select
                    isHeader={true}
                    isMulti={false}
                    selectHandler={e => {
                      setTaskAssignError(null);
                      setAssignTask(e);
                    }}
                    menuItems={projectTasksList}
                    selected={assignTask}
                    menuItemSize={'md'}
                    basisPlaceholder={'Select your task'}
                    basisMaxItems={2}
                    isLoading={isTasksLoading}
                    error={taskAssignError}
                    menuItemTooltipRenderer={item => item?.title}
                    customMenuItemClass={`${themeClass}__menuItem`}
                    customBasisFieldClass={`${themeClass}__menuBasis`}
                    basisTooltip={assignTask?.title}
                    isDisableMinWidth={false}
                    isFullWidth={false}
                  />
                </div>
                <CustomButton
                  type={'tertiary'}
                  size={'sm'}
                  title={'Assign task'}
                  clickHandler={handleAssignTask}
                  loading={isAssignTaskLoading}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const handleTooltip = () => {
    if (!user_data.userInfo.redmine_key) {
      return 'You should set Redmine API key first';
    }
    if (!project.redmine?.length) {
      return 'Enter Redmine project identifier in the Integration tab';
    }
    if (!isRedmineKeyOk) {
      return 'Redmine key is not correct';
    }
    if (user_data.userInfo.redmine_key && statusRedmineTask?.status.name) {
      if (statusRedmineTask?.status.name === 'In process') {
        return `${statusRedmineTask?.status.name} - ${statusRedmineTask?.done_ratio}%`;
      }
      return `${statusRedmineTask?.status.name}`;
    }
    if (user_data.userInfo.redmine_key && !statusRedmineTask?.status.name) {
      return 'Task is not assigned';
    }
  };

  return (
    <>
      <div className={`${themeClass}__btnContainer`}>
        {showButton && (
          <CustomButton
            type={'text-plain'}
            title={''}
            size={'sm'}
            clickHandler={() => handleOpenModal()}
            disabled={!(isRedmineKeyOk && !!project.redmine?.length && !!user_data.userInfo.redmine_key)}
            loading={isRmProjectListLoading && isLoadingRedmineTaskStasus}
            icon={
              <TokenIcon iconName={'redmine-icon'} size={12} customClass={`${handleColorChangeOnStatus(statusRedmineTask?.status.name)}`} />
            }
            customClassName={`${themeClass}__buttonOpenModal`}
            tooltip={handleTooltip()}
          />
        )}
      </div>
      <DialogPopUp
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(!isModalOpen);
          setIsWorkflowDecided(false);
          setProjectTasksList([]);
          setSelectedRmTracker(null);
          setAssignTask(null);
        }}
        title={'Redmine task'}
        renderModalContent={() => initialInputRender()}
      />
    </>
  );
};

export default withSnackbar(RedmineIncorporation);
