import React, { Component } from 'react';

class WhatIsPlanitHelp extends Component {
  render() {
    return (
      <>
        <div className={'popup-content'}>
          <div className={'popup-content__text'}>
            <p className={'shiftedText'} style={{ marginBottom: '10px' }}>
              Planit Scheduler is a tool that helps to improve cross-team communication and align installation-, fabrication-,
              coordination-, procurement- and design-related processes and deliverables. Planit Scheduler facilitates commitment scheduling
              and provides the interface that lets project teams manage and track the commitments and responses in a centralized lean
              management platform.
            </p>
            <p className={'shiftedText'}>
              The core of the Planit Scheduler tool is an NF item. Users create numerous NF items by sending out Requests. These are being
              responded to by the assigned parties through providing Commitments and submitting Responses. The goal is to have no open NF
              items in the project by the time the construction starts.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default WhatIsPlanitHelp;
