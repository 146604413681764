import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface IProps {
  selectedPrevFile: string;
  onClose: () => void;
  classes: any;
  isPDF?: boolean;
}

const styles = {
  paperFullScreenMax: {
    margin: '50px',
    height: '90vh',
  },
};

class Preview extends Component<IProps> {
  render() {
    const { selectedPrevFile, onClose, classes, isPDF } = this.props;

    return (
      <>
        {isPDF ? (
          <Dialog fullScreen open={!!selectedPrevFile} onClose={onClose} classes={{ paperFullScreen: classes.paperFullScreenMax }}>
            <div
              style={{
                position: 'relative',
                height: '90vh',
                overflow: 'hidden',
              }}
            >
              <Iframe
                url={`${selectedPrevFile}`}
                width={'100%'}
                height={'100%'}
                allow="fullscreen"
                id={`preview-${selectedPrevFile}`}
                position="relative"
              />
            </div>
          </Dialog>
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(Preview);
