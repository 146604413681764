import { redmineAPIAxios } from './redmineInstance';

export const getProjectInfo = async (rmApiKey: string, projectList: string[]) => {
  if (!rmApiKey || !projectList?.length) return [];

  const redmineProjectsList = await Promise.all(
    projectList.map(async item => {
      const response = await redmineAPIAxios.get(`/projects/${item}.json`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'X-Redmine-API-Key': rmApiKey,
        },
      });
      const project = response.data;
      return {
        id: project.project.id,
        item: project.project.identifier,
        value: project.project.name,
        title: project.project.name,
      };
    }),
  );

  return redmineProjectsList;
};

export const getMilestonesInfo = async (projectId: string | number, rmApiKey: string) => {
  if (!projectId) return null;
  const response = await redmineAPIAxios.get(`/projects/${projectId}/versions.json`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': rmApiKey,
    },
  });
  const { versions } = response.data;
  const milestoneList = await Promise.all(
    versions.map(elem => {
      return { id: elem.id, value: elem.name, title: elem.name, project: elem.project };
    }),
  );
  return milestoneList;
};

export const getAssignedMilestone = async (milestoneId: string | number, rmApiKey: string) => {
  const response = await redmineAPIAxios.get(`/versions/${milestoneId}.json`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': rmApiKey,
    },
  });
  const { version } = response.data;
  const assignedMilestone = {
    id: version.id,
    name: version.name,
    due_date: version.due_date,
    project: version.project,
  };
  return assignedMilestone;
};

export const createMilestone = async (rmApiKey: string, projectId: string | number, body: {}) => {
  const response = await redmineAPIAxios.post(`/projects/${projectId}/versions.json`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': rmApiKey,
    },
  });
  const { version } = response.data;
  const createdMilestone = {
    id: version.id,
    name: version.name,
    due_date: version.due_date,
    project: version.project,
  };
  return createdMilestone;
};

export const removeAssignedMilestone = async (milestoneId: string | number, rmApiKey: string) => {
  const body = {
    version: {
      status: 'closed',
      custom_fields: [
        {
          id: 225,
          name: 'Scheduler Deliverable',
          value: 'Revision has been removed from Scheduler',
        },
        {
          id: 226,
          name: 'Deliverable status',
          value: 'Not issued yet',
        },
      ],
    },
  };
  await redmineAPIAxios.put(`/versions/${milestoneId}.json`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': rmApiKey,
    },
  });
};

export const issuedMilestone = async (milestoneId: string | number, rmApiKey: string) => {
  const body = {
    version: {
      status: 'closed',
      custom_fields: [
        {
          id: 226,
          name: 'Deliverable status',
          value: 'Issued',
        },
      ],
    },
  };
  await redmineAPIAxios.put(`/versions/${milestoneId}.json`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Redmine-API-Key': rmApiKey,
    },
  });
};
