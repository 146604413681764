import React, { FC, useState, useEffect, useRef } from 'react';
import { DataGridHandle } from 'react-data-grid';
import { DataGridBasic } from '../../../../../DataGridBasic';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import { NfValidationStatuses, RequestModel } from '../../../../../../../models';
import StatusNameHelper from '../../../../../../../helpers/StatusNameHelper';
import StatusNFValidation from '../../../../../../controls/Status/StatusNFValidation/StatusNFValidation';
import { PlaneControlDeliverable } from '../../../../../../../store/pcd/types';
import RevisionReasonFieldsDataModel = PlaneControlDeliverable.RevisionReasonFieldsDataModel;
import { DeliverableSnippetModel } from '../../../../../../../store/relatedItems/relatedItemsSlice.model';
import CustomTooltip from '../../../../../../controls/Tooltip/Tooltip';
import ChipsRevisionTag from '../../../../../../controls/Chips/ChipsRevisionTag/ChipsRevisionTag';
import StatusRevision from '../../../../../../controls/Status/StatusRevision/StatusRevision';

import './ReasonsGridStyles.scss';

interface IRow {
  building: string;
  default_title: string;
  id: number;
  level: string;
  parent_id: number;
  revision: number;
  shot_title: string;
  title: string;
  validity_status: NfValidationStatuses;
}

const ReasonsGrid: FC<{
  type: 'nf' | 'deliverable';
  nfsList?: RequestModel[];
  deliverablesList?: DeliverableSnippetModel[];
  selectedRows: Set<number>;
  setSelectedRows: (selectedRows: Set<number>) => void;
  isReadOnly: boolean;
  reasonsFields: RevisionReasonFieldsDataModel;
}> = ({ type, nfsList, deliverablesList, selectedRows, setSelectedRows, isReadOnly, reasonsFields }) => {
  const themeClass = useGetThemeClass('b-reasonsGrid');

  const lastRowRef = useRef<DataGridHandle>();

  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState<IRow[] | null>(null);
  const [editingRowIds, setEditingRowIds] = useState([]);

  useEffect(() => {
    setEditingRowIds(Array.from(selectedRows));
  }, [selectedRows]);

  const handleChangeSelectedRows = selectedIds => {
    setSelectedRows(new Set(selectedIds.editingRowIds));
  };

  // formatter for Nf table
  const renderNfFormatter = p => {
    return <div className={`${themeClass}__cellText`}>{`NF#${p.row.nf}`}</div>;
  };

  const renderSubjectFormatter = p => {
    return <div className={`${themeClass}__cellText`}>{p.row.specific || ''}</div>;
  };

  const renderNfStatusFormatter = p => {
    return <div className={`${themeClass}__cellText`}>{StatusNameHelper.getLastUserStatusName(p.row)}</div>;
  };

  const getValidationIcon = (validityStatus: NfValidationStatuses) => {
    let iconName;

    if (validityStatus === 'not_valid') {
      iconName = 'outdated';
    }

    if (validityStatus === 'valid') {
      iconName = 'valid-fill';
    }

    if (validityStatus === 'not_verified') {
      iconName = 'not-verified';
    }

    return <StatusNFValidation type={iconName} size={'m'} />;
  };

  const renderNfValidityStatusFormatter = p => {
    const unlinkedOrDeactivatedTitle =
      p.row.validity_status === 'unlinked' ? 'NF unlinked' : p.row.validity_status === 'deactivated' ? 'NF deactivated' : '';

    return (
      <div className={`${themeClass}__cellText -validity`}>
        {unlinkedOrDeactivatedTitle ? `${unlinkedOrDeactivatedTitle}` : getValidationIcon(p.row.validity_status)}
      </div>
    );
  };

  // formatter for Deliverable table
  const renderDeliverableTitleFormatter = p => {
    return (
      <CustomTooltip
        title={p.row.flags.last_title || p.row.title || ''}
        placement={'top-start'}
        customBasisClass={`${themeClass}__tooltipCell`}
      >
        <div className={`${themeClass}__cellText`}>{p.row.flags.last_title || p.row.title || ''}</div>
      </CustomTooltip>
    );
  };

  const renderRevisionFormatter = p => {
    return <ChipsRevisionTag processStatus={p.row.flags.last_status} value={`R${p.row.flags.last_revision}`} />;
  };

  const renderRevisionStatusFormatter = p => {
    return <StatusRevision status={p.row.flags.last_status} review_state={p.row.flags.last_review_state} isVisibleLabel />;
  };

  const renderDeliverableValidityStatusFormatter = p => {
    //TODO unlink/deactivated deliverables
    // const unlinkedOrDeactivatedTitle =
    //   p.row.validity_status === 'unlinked' ? 'unlinked' : p.row.validity_status === 'deactivated' ? 'deactivated' : '';

    return (
      <div className={`${themeClass}__cellText -validity`}>
        {/*TODO*/}
        {/*{unlinkedOrDeactivatedTitle ? `${unlinkedOrDeactivatedTitle}` : getValidationIcon(p.row.validity_status)}*/}
        {getValidationIcon(p.row.flags.validity_status)}
      </div>
    );
  };

  useEffect(() => {
    let columns = [];
    if (type === 'nf') {
      columns = [
        {
          key: 'nf',
          name: 'NF',
          width: 100,
          formatter: p => renderNfFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'subject',
          name: 'Subject',
          width: 207,
          formatter: p => renderSubjectFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'lastStatus',
          name: 'NF status',
          width: 168,
          formatter: p => renderNfStatusFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'validity_status',
          name: 'Validity status',
          width: 115,
          maxWidth: 115,
          formatter: p => renderNfValidityStatusFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
      ];
    }

    if (type === 'deliverable') {
      columns = [
        {
          key: 'title',
          name: 'Deliverable title',
          width: 260,
          formatter: p => renderDeliverableTitleFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'revision_deliverables',
          name: 'Rev',
          width: 56,
          maxWidth: 56,
          formatter: p => renderRevisionFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'revision_status',
          name: 'Status',
          width: 160,
          maxWidth: 160,
          formatter: p => renderRevisionStatusFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
        {
          key: 'validity_status',
          name: 'Validity status',
          width: 115,
          maxWidth: 115,
          formatter: p => renderDeliverableValidityStatusFormatter(p),
          headerCellClass: `${themeClass}__headerCell`,
        },
      ];
    }

    setColumns(columns);
  }, [nfsList, deliverablesList, themeClass, reasonsFields, isReadOnly]);

  useEffect(() => {
    if (type === 'nf') {
      if (nfsList) {
        const rowsAll = [];
        nfsList.forEach(item => {
          rowsAll.push(item);
        });
        setRows(rowsAll);
      }
      return;
    }

    if (type === 'deliverable') {
      if (deliverablesList) {
        const rowsAll = [];
        deliverablesList.forEach(item => {
          rowsAll.push(item);
        });
        setRows(rowsAll);
      }
      return;
    }
  }, [nfsList, deliverablesList]);

  const rowKeyGetter = row => {
    return row.id;
  };

  return (
    <div className={themeClass}>
      <DataGridBasic
        tabelOutRef={lastRowRef}
        rowKeyGetter={rowKeyGetter}
        columns={columns || []}
        rows={rows || []}
        headerRowHeight={36}
        rowHeight={40}
        editingRowIds={editingRowIds}
        setEditingRowsAction={handleChangeSelectedRows}
        onRowsChange={setRows}
        customHeaderRenderer
        isBulkEditMode
        isDeletable={false}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default React.memo(ReasonsGrid);
