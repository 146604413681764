import { commonSlice } from './../common/commonReducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createLogic } from 'redux-logic';
import * as requestTypes from './requestActions';
import requestApi from '../../service/Api/requestApi';
import commonApi from '../../service/Api/commonApi';
import { API_URI, DOMAIN_URI } from '../../service/links';
import {
  FullRequestModel,
  GetLinkingNfSuggestionModel,
  GetLinkingNfSuggestionResponseModel,
  ICheckIfItemInSectionPayload,
  ICheckIfItemInSectionResponseData,
  ICreateDueDatePayload,
  IGetMainMfPayload,
  ILinkNfPayload,
  IRequestDeactivatePayload,
  IRequestDeletePayload,
  IRequestLoadingRejected,
  IRequestMfTree,
  IRequestSaveRejected,
  IRequestSetHotList,
  IRequestSyncProcorePayload,
  IRequestUfTree,
  IRequestUpdatePrivatePayload,
  ISearchWatchersRequestPayload,
  ISendBugReportPayload,
  ISingleRequestLoadingPayload,
  ISingleRequestLoadingRejected,
  IUnlinkNfPayload,
  IUpdateNewResponseCompanyPayload,
  PostponeSandBoxAlertModel,
  ResponseSandBox,
  ResponseSandBoxCounters,
  ResponseSandBoxOuterCounter,
  SandBoxQueryModel,
  SandBoxWidgetsPayloadModel,
  SandBoxWidgetsResponse,
  SandBoxIDsHotList,
} from './requestReducer.model';
import { requestSlice } from './requestReducer(HOLD)';
import { getComments, getFeed } from '../comments/commentsThunk';
import { RequestToNewResponseCompany } from '../../components/common/RequestCard/parts/RequestCardPart';
import { IncorporateFormData } from '../../components/common/RequestCard/RequestCard';
import { FileModel, MfRequestPayload, RequestModel, UfMfSearchResponsePayload, UfMfValueModel, UfRequestPayload } from '../../models';
import { needsListSlice } from '../needlist/needsListSlice';
import { userSlice } from '../user/userSlice';
import { settingsSlice } from '../globalSettings/settingsSlice';
import { NeedListRequest } from '../needlist/needListModels';
import { BaseResponse } from '../../models/Api/response.model';
import { PlaneControlDeliverable } from '../pcd/types';
import { errorsSlice } from '../errors/errorsSlice';
import { RootState } from '../configure/typesReducer';
import FileHelper from '../../helpers/FileHelper';
import { KeyValueModel } from '../../models/key-value.model';
import { incorporationSlice } from '../incorporation/incorporationSlice';
import { getIncorporation } from '../incorporation/incorporationThunk';
import { DatabaseService } from '../../service/IndexedDB/DBConfig';

import {
  checkIsDateAfter,
  filterArrayByField,
  generateAndSetHash,
  getIsFilters,
  saveNeedListCashingTime,
  updateMainArray,
} from '../../service/IndexedDB/Helper';
import { nfsToCacheImmediately } from '../../service/IndexedDB/Constants';
import { submittalSlice } from '../submittal/submittalSlice';
import { pcdSlice } from '../pcd/pcdSlice';
import pcdApi from '../../service/Api/pcd/pcdApi';

const fileDownload = require('js-file-download');
//RR - requestReducer
const { singleRequestForProjectLoadingSucces } = commonSlice.actions;
const { filesUploadClean, setIsFirstHashRender } = settingsSlice.actions;
const { bulkEditLoading, bulkEditSuccess } = needsListSlice.actions;
const { setShowErrorData } = errorsSlice.actions;
const { changeGlobalIncorporation } = incorporationSlice.actions;
const handleRequestChangeWatchlist = requestSlice?.actions?.handleRequestChangeWatchlist;
const { handleSubmittalChangeWatchlist } = submittalSlice.actions;
const { handleDeliverableChangeWatchlist } = pcdSlice.actions;

export const singleRequestLoading = createAsyncThunk<
  any,
  ISingleRequestLoadingPayload,
  {
    rejectValue: ISingleRequestLoadingRejected;
  }
>('requestReducer/singleRequestLoading', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { singleRequestForProjectLoadingSucces: singleRequestForProjectLoadingSucces_RR } = requestSlice.actions;

    const response = await requestApi.getSingleRequest(payload.requestId);

    dispatch(singleRequestForProjectLoadingSucces_RR(response));
    dispatch(singleRequestForProjectLoadingSucces(response));
    dispatch(changeGlobalIncorporation(response.data.is_incorporated));

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getRequestByNf = createAsyncThunk<any, { nf: number; projectId: number; project_name: string; page: number | string }>(
  'requestReducer/getRequestByNf',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId, nf, project_name, page } = payload;

    const { allRequestLoadingSucces, singleRequestForProjectLoadingSucces: singleRequestForProjectLoadingSucces_RR } = requestSlice.actions;
    const { setShowErrorData } = errorsSlice.actions;
    const { setActiveProject } = userSlice.actions;

    if (projectId && page !== 'notifications-list') {
      dispatch(setActiveProject(projectId));
    }

    if (project_name) {
      sessionStorage.setItem('project_name', project_name);
    }

    try {
      const response = await requestApi.getRequestByNf(projectId, nf);
      if (response.data.status === false || response.data.status === 400) {
        throw response;
      }

      if (!projectId) {
        dispatch(allRequestLoadingSucces(response));
      } else {
        dispatch(singleRequestForProjectLoadingSucces_RR(response));
        dispatch(singleRequestForProjectLoadingSucces(response));
        dispatch(changeGlobalIncorporation(response.data.is_incorporated));
        // dispatch(requestTypes.getRequestByNfSuccess(response));
        return response;
      }
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

//TODO need types requestLoading with reject and imports
export const requestLoading = createAsyncThunk<
  any,
  any,
  {
    rejectValue: IRequestLoadingRejected;
  }
>('requestReducer/requestLoading', async (payload, { dispatch, rejectWithValue }) => {
  const projectId = sessionStorage.getItem('active_project_id');
  let projectParam = projectId > 0 ? { project_id: projectId } : '';
  projectParam = { ...projectParam, ...payload };
  const { allRequestLoadingSucces, allRequestForProjectLoadingSucces } = requestSlice.actions;
  try {
    const response = await requestApi.getRequest(projectParam);
    if (!projectId) {
      dispatch(allRequestLoadingSucces(response));
    } else {
      dispatch(allRequestForProjectLoadingSucces(response));
    }
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const requestSave = createAsyncThunk<
  any,
  any,
  {
    rejectValue: BaseResponse<IRequestSaveRejected>;
  }
>('requestReducer/requestSave', async (payload, { dispatch, rejectWithValue, getState }) => {
  const projectId = sessionStorage.getItem('active_project_id');
  let { bodyParams, isNotFormData } = payload;
  const { callback, requestId, callbackNavigation } = payload;

  const isPullRequest = bodyParams.isPullRequest;
  const isCommit = bodyParams.commit;

  const shouldRefreshComments = bodyParams.mentions_to_delete || bodyParams.bic_to_delete;

  delete bodyParams.isPullRequest;
  const upload_files = bodyParams['upload_files'] || null;
  bodyParams = { ...bodyParams, project_id: projectId };

  const { requestSyncList, setNewNfCardSubmittalId } = requestSlice.actions;

  let response;

  try {
    const links = [];
    const files = FileHelper.separateByMaxSize(upload_files);
    const state = getState();
    const { currentRequestToOpenId, newNfCardSubmittalInfo } = state.requestReducer;
    if (!requestId) {
      if (newNfCardSubmittalInfo.submittalId) {
        bodyParams.submittal_ids = [newNfCardSubmittalInfo.submittalId];
        bodyParams.is_generate_nf_rfi = newNfCardSubmittalInfo.is_generate_nf_rfi;
      }
      if (files && files.length) {
        // Create NF
        const createdNf = await requestApi.createRequest(
          {
            ...bodyParams,
            upload_files: files[0],
          },
          files[0],
          isNotFormData,
        );
        response = { ...createdNf };
        files.shift();
        // Update NF after creating
        files.map((f, fIndex) =>
          links.push(
            requestApi.updateRequest(
              {
                ...bodyParams,
                upload_files: f,
                quietly: fIndex !== files.length - 1 ? 1 : 0,
              },
              createdNf.data.id,
              f,
              isNotFormData,
            ),
          ),
        );
      } else {
        links.push(requestApi.createRequest(bodyParams, upload_files, isNotFormData));
      }
    } else {
      if (files && files.length) {
        const responses = await Promise.all(
          files.map(
            async (f, fIndex) =>
              await requestApi.updateRequest(
                { ...bodyParams, upload_files: f, quietly: fIndex !== files.length - 1 ? 1 : 0 },
                requestId,
                f,
                isNotFormData,
              ),
          ),
        );
        response = responses[responses.length - 1];
      } else {
        response = await requestApi.updateRequest(bodyParams, requestId, upload_files, isNotFormData);
      }
    }

    const responses = await Promise.all(links);

    if (responses?.length) {
      response = responses[responses.length - 1];
    }

    const responseCheck =
      !currentRequestToOpenId || isPullRequest
        ? !response.data.status
        : !response.data.status || currentRequestToOpenId !== response.response.resource.id;

    if (responseCheck || response.status === false || response.status === 400) {
      throw response;
    } else {
      !requestId ? (response.isNew = true) : null;

      response?.data?.nf && callbackNavigation && callbackNavigation(response?.data?.nf);

      dispatch(requestSyncList(response.response));

      if (shouldRefreshComments && requestId) {
        dispatch(getComments({ owner_id: requestId, all: true, type: 'request' }));
      }

      dispatch(filesUploadClean({ type: 'request' }));
      dispatch(setNewNfCardSubmittalId({ submittalId: null, is_generate_nf_rfi: 0 }));

      if (callback) {
        callback();
      }

      return { ...response, isCommitPull: isCommit && isPullRequest };
    }
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const responseSave = createAsyncThunk<
  any,
  any,
  {
    rejectValue: IRequestSaveRejected;
  }
>('requestReducer/responseSave', async (payload, { dispatch, rejectWithValue, getState }) => {
  const { requestId, callback, isUpdateStatus } = payload;
  let { bodyParams } = payload;
  const projectId = sessionStorage.getItem('active_project_id');

  const upload_files = bodyParams['upload_files'];
  bodyParams = { ...bodyParams, project_id: projectId };

  try {
    let response;
    const files = FileHelper.separateByMaxSize(upload_files as unknown as FileModel[]);

    if (isUpdateStatus) {
      response = await requestApi.updateStatus(requestId, bodyParams);
      dispatch(getIncorporation({ id: requestId }));
      dispatch(changeGlobalIncorporation(response.data.is_incorporated));
    } else {
      if (files && files.length) {
        const responses = await Promise.all(
          files.map((f, fIndex) =>
            requestApi.responseSave(requestId, { ...bodyParams, upload_files: f, quietly: fIndex !== files.length - 1 ? 1 : 0 }, f),
          ),
        );
        response = responses[responses.length - 1];
      } else {
        response = await requestApi.responseSave(requestId, bodyParams, upload_files);
      }
    }

    const state = getState();
    const { currentRequestToOpenId } = state.requestReducer;

    if (!response.data.status || currentRequestToOpenId !== response.data.id) {
      throw response;
    } else {
      dispatch(filesUploadClean({ type: 'commitment' }));
      dispatch(
        getFeed({
          owner_id: response.data.id,
          type: 'request',
        }),
      );

      if (callback) {
        callback();
      }

      return response;
    }
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const requestDelete = createAsyncThunk<IRequestDeletePayload, IRequestDeletePayload>(
  'requestReducer/requestDelete',
  async (payload, { dispatch, rejectWithValue }) => {
    const { requestId, page, accordion, callback } = payload;
    try {
      const response = await requestApi.deleteRequest(requestId);

      if (!response.status) {
        throw response;
      }

      if (callback) {
        callback();
      }

      return { id: requestId, page, accordion };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const requestUpdatePrivate = createAsyncThunk<any, IRequestUpdatePrivatePayload>(
  'requestReducer/requestUpdatePrivate',
  async (payload, { dispatch, rejectWithValue }) => {
    const { requestId, bodyParams, callback, needUpdateState } = payload;
    try {
      const response = await requestApi.updateRequestPrivate(bodyParams, requestId);

      callback();

      if (needUpdateState) {
        return response;
      }
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setHotList = createAsyncThunk<any, IRequestSetHotList>(
  'requestReducer/setHotList',
  async (payload, { dispatch, rejectWithValue }) => {
    const { entity, accordion_alias, ...data } = payload;

    try {
      const response = await requestApi.setHotList(entity, data);

      dispatch(getFeed({ owner_id: data.entity_ids[0], type: entity }));

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const bulkEdit = createAsyncThunk<NeedListRequest[], null>(
  'requestReducer/bulkEdit',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(bulkEditLoading());
      const projectId = sessionStorage.getItem('active_project_id');
      const state = getState();
      const { editingRowIds, bulkEditData } = state.needList;
      const bodyParams = {
        request_ids: editingRowIds,
        ...bulkEditData,
      };

      const response = await requestApi.bulkEdit(bodyParams, projectId);

      dispatch(bulkEditSuccess());

      return Object.values(response.data);
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getNeedListLogic = createLogic({
  type: requestTypes.NEED_LIST,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam = { ...projectParam, ...action.payload };
    if (projectParam.status === 'active') {
      delete projectParam.status;
    }
    requestApi
      .getNeedList(projectParam)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.needListSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.needListFaile(err));
      })
      .then(() => {
        done();
      });
  },
});

export const needList = createAsyncThunk<any, any>(
  'requestReducer/needList',
  async ({ queryModel, callbackNavigation }, { dispatch, rejectWithValue, getState }) => {
    const { setIsNeedListLoadingStatus } = requestSlice.actions;
    try {
      const state = getState();
      const isFirstRenderWithURLHash = state?.settingsReducer?.isFirstRenderWithURLHash;
      const filterHash = new URLSearchParams(location.search).get(`need_form_hash`);

      const generateUrlWithFiltersHash = hash => {
        if (isFirstRenderWithURLHash) {
          dispatch(setIsFirstHashRender());
        }
        if (typeof hash === 'string') {
          callbackNavigation && callbackNavigation(hash, false);
        } else {
          callbackNavigation && callbackNavigation(null, false);
        }
      };

      const { order_by, order_dir } = queryModel;
      const isFilters = getIsFilters(queryModel);
      const isNfSorting = (order_by && order_by === 'nf') || !order_by;
      const projectId = +sessionStorage.getItem('active_project_id');
      const requestUpdatingTime = state.projectReducer?.refreshes?.request;
      const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));

      if (!needListCashedData || !needListCashedData[projectId]) {
        sessionStorage.setItem('isNeedListLoadingStatus', JSON.stringify(true));
        dispatch(setIsNeedListLoadingStatus(true));
      }

      // Get list from DB, cache and last updating module timeStamp
      const getAll = await DatabaseService.getAll({ storeName: `NEEDLIST`, projectId, dispatch });
      const dataFromCash = isNfSorting
        ? Object.values(getAll).sort((a, b) => (order_dir === 'desc' || !order_dir ? b.nf - a.nf : a.nf - b.nf))
        : Object.values(getAll);

      //Generate needList request payload
      const hash = generateAndSetHash();
      const only_ids = isFilters && dataFromCash?.length ? 1 : 0;
      const bodyParams = {
        hash,
        only_ids,
        project_id: +projectId,
        ...queryModel,
        local_storage: JSON.parse(localStorage.getItem(`need_form`)),
      };
      if (isFirstRenderWithURLHash) {
        bodyParams.filters_hash = `need_form_hash=${filterHash}`;
      }

      // If DB list exist was update during this period than request updated nfs
      if (
        !!needListCashedData &&
        !!needListCashedData[projectId] &&
        checkIsDateAfter(requestUpdatingTime, needListCashedData[projectId]?.cachedTimestamp) &&
        dataFromCash?.length
      ) {
        if (isFilters) {
          const response = await requestApi.getNeedList({
            hash,
            project_id: +projectId,
            ...queryModel,
            isFiltersWithoutCashedData: 1,
            local_storage: JSON.parse(localStorage.getItem(`need_form`)),
            filters_hash: isFirstRenderWithURLHash ? `need_form_hash=${filterHash}` : undefined,
            refresh_database_date: needListCashedData[projectId]?.cachedTimestamp,
          });

          generateUrlWithFiltersHash(response?.response?.filters_hash);

          const updatedData = updateMainArray(dataFromCash, response.response.items, response.response.was_deleted, projectId);
          const filteredArray = filterArrayByField(updatedData, response.response.filtered, 'id');

          return {
            response: {
              items: filteredArray,
              total: filteredArray.length,
            },
          };
        } else {
          const response = await requestApi.getNeedList({
            ...bodyParams,
            refresh_database_date: needListCashedData[projectId]?.cachedTimestamp,
          });

          const updatedData = updateMainArray(dataFromCash, response.response.items, response.response.was_deleted, projectId);

          return {
            response: {
              items: updatedData,
              total: updatedData.length,
            },
          };
        }
      }

      // If DB list exist and no update during this period than use cashing list
      if (
        !!needListCashedData &&
        !!needListCashedData[projectId] &&
        checkIsDateAfter(needListCashedData[projectId]?.cachedTimestamp, requestUpdatingTime) &&
        dataFromCash?.length
      ) {
        let filteredNfIds = null;

        // If DB list exist and isFilters=true get filtered nfs ids and get them from db
        if (isFilters) {
          filteredNfIds = await requestApi.getNeedList(bodyParams);
          generateUrlWithFiltersHash(filteredNfIds?.response?.filters_hash);
        }

        if (!isFilters) {
          callbackNavigation && callbackNavigation(null, true);
        }

        const responseData = filteredNfIds?.response?.items
          ? filterArrayByField(dataFromCash, filteredNfIds?.response?.items, 'id')
          : [...dataFromCash];

        return { response: { items: responseData, total: responseData.length } };
      }

      // First case isFilters=true and no cashed data, we get filtered nfs ids and all needList data from WebSockets to cache it
      // Loading is true until we will receive all data to cache it, and get from this list nfs to show using (filtered nfs ids)
      // Second default flow
      let defaultResponse;
      if (isFilters) {
        defaultResponse = await requestApi.getNeedList({
          hash,
          project_id: +projectId,
          ...queryModel,
          isFiltersWithoutCashedData: 1,
          local_storage: JSON.parse(localStorage.getItem(`need_form`)),
          filters_hash: isFirstRenderWithURLHash ? `need_form_hash=${filterHash}` : undefined,
        });

        generateUrlWithFiltersHash(defaultResponse?.response?.filters_hash);

        if (defaultResponse.response.allTotal <= nfsToCacheImmediately) {
          [...defaultResponse.response.items].forEach(item => {
            DatabaseService.addItem({ item, storeName: `NEEDLIST`, projectId, dispatch });
          });
          saveNeedListCashingTime(projectId);
          sessionStorage.setItem('isNeedListLoadingStatus', JSON.stringify(false));
          dispatch(setIsNeedListLoadingStatus(false));

          const responseData = filterArrayByField(defaultResponse.response.items, defaultResponse.response.filtered, 'id');

          return { response: { items: responseData, total: responseData.length } };
        }

        return defaultResponse;
      } else {
        defaultResponse = await requestApi.getNeedList(bodyParams);

        // If total nf less than 350, WebSockets won't be sent so we need to cache it immediately
        if (defaultResponse.response.total <= nfsToCacheImmediately) {
          [...defaultResponse.response.items].forEach(item => {
            DatabaseService.addItem({ item, storeName: `NEEDLIST`, projectId, dispatch });
          });
          saveNeedListCashingTime(projectId);
          sessionStorage.setItem('isNeedListLoadingStatus', JSON.stringify(false));
          dispatch(setIsNeedListLoadingStatus(false));
        }
        generateUrlWithFiltersHash(defaultResponse?.response?.filters_hash);

        return defaultResponse;
      }
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getNeedListColumnOrderLogic = createLogic({
  type: requestTypes.GET_NEED_LIST_COLUMN_ORDER,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .getNeedListColumnOrder()
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.needListColumnOrderSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.needListColumnOrderFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getNeedListColumnOrder = createAsyncThunk<BaseResponse<{ setting: string }>>(
  'requestReducer/getNeedListColumnOrder',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      return await requestApi.getNeedListColumnOrder();
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setNeedListColumnOrderLogic = createLogic({
  type: requestTypes.SET_NEED_LIST_COLUMN_ORDER,
  latest: true,
  process({ action }, dispatch, done) {
    const { columnOrder } = action.payload;

    requestApi
      .setNeedListColumnOrder({ setting: columnOrder })
      .then(response => {
        if (response.status === false) {
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const setNeedListColumnOrder = createAsyncThunk<any, { columnOrder: string }>(
  'requestReducer/setNeedListColumnOrder',
  async (payload, { dispatch, rejectWithValue }) => {
    const { columnOrder } = payload;
    try {
      const response = await requestApi.setNeedListColumnOrder({ setting: columnOrder });

      return { columnOrder };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _getTags = createLogic({
  type: requestTypes.GET_TAGS,
  latest: true,
  process({ action }, dispatch, done) {
    // const projectId = sessionStorage.getItem('active_project_id');
    // let projectParam = projectId > 0 ? {project_id: projectId} : '';
    requestApi
      .getTags()
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        // dispatch(requestTypes.needListRequestData(response));
        dispatch(requestTypes.getTagsSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.needListFaile(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getTags = createAsyncThunk<any, void>('requestReducer/getTags', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await requestApi.getTags();
    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const getSandBoxDataLogic = createLogic({
  type: requestTypes.GET_SAND_BOX_DATA,
  latest: true,
  cancelType: requestTypes.GET_SAND_BOX_DATA_CANCEL,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    if (!action.payload.order_dir && !action.payload.order_by) {
      action.payload.order_dir = 'desc';
      action.payload.order_by = 'nf';
    }
    projectParam = { ...projectParam, ...action.payload };
    requestApi
      .getSandBox(projectParam)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.getSandBoxDataSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.needListFaile(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (projectId === currentActiveProjectId) {
          dispatch(requestTypes.getSandBoxDataCancel());
        }
        done();
      });
  },
});

export const getSandBoxWidgetsHotlistData = createAsyncThunk<
  { response: ResponseSandBox; related_to: string; filter_by: string; queryData: KeyValueModel<{}> },
  SandBoxQueryModel
>('requestReducer/getSandBoxWidgetsHotlistData', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { callback, search, related_to, filter_by, ...data } = { ...(payload || {}) };

    const projectParam = {
      ...(data || {}),
      search: search?.trim() || '',
      related_to,
      filter_by,
    };

    const response = await requestApi.getSandBoxWidgetsHotlistData(projectParam);

    if (callback) {
      callback();
    }

    const queryData = { ...projectParam };

    return { response, related_to, filter_by, queryData };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getSandBoxData = createAsyncThunk<
  { response: ResponseSandBox; related_to: string; filter_by: string; queryData: KeyValueModel<{}> },
  SandBoxQueryModel
>('requestReducer/getSandBoxData', async (payload, { dispatch, rejectWithValue, getState }) => {
  try {
    const { callback, callbackNavigation, search, related_to, filter_by, user_id, party_id, ...data } = { ...(payload || {}) };

    const currentState = getState();
    const isFirstRenderWithURLHash = currentState?.settingsReducer?.isFirstRenderWithURLHash;
    const generateUrlWithFiltersHash = hash => {
      if (isFirstRenderWithURLHash) {
        dispatch(setIsFirstHashRender());
      }
      if (typeof hash === 'string') {
        callbackNavigation && callbackNavigation(hash);
      } else {
        callbackNavigation && callbackNavigation();
      }
    };

    const sandBoxFilterBy = filter_by.split('_')[0];
    let localStorageTitle;
    if (sandBoxFilterBy.includes('request')) {
      if (user_id || party_id) {
        localStorageTitle = `sandbox_report_need_form`;
      } else {
        localStorageTitle = `sandbox_need_form`;
      }
    }
    if (sandBoxFilterBy.includes('deliverable')) {
      if (user_id || party_id) {
        localStorageTitle = `sandbox_report_deliverable`;
      } else {
        localStorageTitle = `sandbox_deliverable`;
      }
    }
    if (sandBoxFilterBy.includes('submittalInput')) {
      if (user_id || party_id) {
        localStorageTitle = `sandbox_report_submittal_form_input`;
      } else {
        localStorageTitle = `sandbox_submittal_form_input`;
      }
    }
    if (sandBoxFilterBy.includes('submittalOutput')) {
      if (user_id || party_id) {
        localStorageTitle = `sandbox_report_submittal_form_output`;
      } else {
        localStorageTitle = `sandbox_submittal_form_output`;
      }
    }
    const filterHash = new URLSearchParams(location.search).get(`${localStorageTitle}_hash`);

    const projectParam = {
      ...(data || {}),
      search: search?.trim() || '',
      related_to,
      filter_by,
      local_storage: JSON.parse(localStorage.getItem(`${localStorageTitle}`)) || undefined,
    };
    if (user_id) {
      projectParam.user_id = user_id;
    }
    if (party_id) {
      projectParam.party_id = party_id;
    }

    if (isFirstRenderWithURLHash && filterHash) {
      projectParam.filters_hash = `${localStorageTitle}_hash=${filterHash}`;
    }

    const response = await requestApi.getSandBox(projectParam);

    generateUrlWithFiltersHash(response?.response?.filters_hash);

    if (callback) {
      callback();
    }

    const queryData = { ...projectParam };

    return { response, related_to, filter_by, queryData, hash: response?.response?.filters_hash };
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const getSandBoxCounters = createAsyncThunk<ResponseSandBoxCounters, SandBoxQueryModel>(
  'requestReducer/getSandBoxCounters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, search, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id,
        ...(data || {}),
        search: search?.trim() || '',
      };

      const { setIsTimerActiveRequest, setIsTimerActiveDeliverable } = requestSlice.actions;

      if (data.entity !== 'request' && data.entity !== 'deliverable') {
        dispatch(setIsTimerActiveRequest(false));
        dispatch(setIsTimerActiveDeliverable(false));
      }
      if (data.entity === 'request') {
        dispatch(setIsTimerActiveRequest(false));
      }
      if (data.entity === 'deliverable') {
        dispatch(setIsTimerActiveDeliverable(false));
      }

      const response = await requestApi.getSandBoxCounters(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxhotListCounters = createAsyncThunk<ResponseSandBoxCounters, SandBoxQueryModel>(
  'requestReducer/getSandBoxhotListCounters',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id: project_id,
        related_to: data.related_to,
        entity: data.entity,
      };

      const { setIsTimerActiveRequest, setIsTimerActiveDeliverable } = requestSlice.actions;

      if (data.entity !== 'request' && data.entity !== 'deliverable') {
        dispatch(setIsTimerActiveRequest(false));
        dispatch(setIsTimerActiveDeliverable(false));
      }
      if (data.entity === 'request') {
        dispatch(setIsTimerActiveRequest(false));
      }
      if (data.entity === 'deliverable') {
        dispatch(setIsTimerActiveDeliverable(false));
      }

      const response = await requestApi.getSandBoxhotListCounters(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxhotListCountersAll = createAsyncThunk<ResponseSandBoxCounters, SandBoxQueryModel>(
  'requestReducer/getSandBoxhotListCountersAll',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id: project_id,
        related_to: data.related_to,
        entity: data.entity,
      };

      const { setIsTimerActiveRequest, setIsTimerActiveDeliverable } = requestSlice.actions;

      if (data.entity !== 'request' && data.entity !== 'deliverable') {
        dispatch(setIsTimerActiveRequest(false));
        dispatch(setIsTimerActiveDeliverable(false));
      }
      if (data.entity === 'request') {
        dispatch(setIsTimerActiveRequest(false));
      }
      if (data.entity === 'deliverable') {
        dispatch(setIsTimerActiveDeliverable(false));
      }

      const response = await requestApi.getSandBoxhotListCountersAll(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxIDsHotList = createAsyncThunk<any, SandBoxIDsHotList>(
  'requestReducer/getSandBoxIDsHotList',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id: project_id,
        related_to: data.related_to,
        entity: data.entity,
        check_ids: data.check_ids,
      };

      const response = await requestApi.getSandBoxIDsHotList(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxCardIDHotList = createAsyncThunk<any, SandBoxIDsHotList>(
  'requestReducer/getSandBoxCardIDHotList',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id: project_id,
        related_to: data.related_to,
        entity: data.entity,
        check_ids: data.check_ids,
      };

      const response = await requestApi.getSandBoxCardIDHotList(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxOuterCounter = createAsyncThunk<ResponseSandBoxOuterCounter, SandBoxQueryModel>(
  'requestReducer/getSandBoxOuterCounter',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = { ...(payload || {}) };
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id,
        related_to: 'user',
        ...(data || {}),
      };

      const response = await requestApi.getSandBoxOuterCounter(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getSandBoxWidgets = createAsyncThunk<SandBoxWidgetsResponse, SandBoxWidgetsPayloadModel>(
  'requestReducer/getSandBoxWidgets',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const project_id = +sessionStorage.getItem('active_project_id');

      const projectParam = {
        project_id,
        ...(payload || {}),
      };

      const response = await requestApi.getSandBoxWidgets(projectParam);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const postponeSandBoxAlert = createAsyncThunk<BaseResponse, PostponeSandBoxAlertModel>(
  'requestReducer/postponeSandBoxAlert',
  async (payload, { dispatch, rejectWithValue }) => {
    const project_id = +sessionStorage.getItem('active_project_id');
    const { callback, ...data } = payload;
    try {
      const projectParam = {
        project_id,
        ...(data || {}),
      };

      const response = await requestApi.postponeSandBoxAlert(projectParam);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteFromPostponedSandBoxAlerts = createAsyncThunk<BaseResponse, { id: number; callback?: any }>(
  'requestReducer/deleteFromPostponedSandBoxAlerts',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, callback } = payload;
    try {
      const response = await requestApi.deleteFromPostponedSandBoxAlerts(id);

      if (callback) {
        callback();
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const setSeenRequestLogic = createLogic({
  type: requestTypes.REQUEST_SET_SEEN,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam.order_dir = 'desc';
    projectParam.order_by = 'nf';
    projectParam = { ...projectParam, ...action.payload };
    requestApi
      .setSeenRequest(projectParam)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.requestSetSeenSuccess({ response, ...action.payload }));
      })
      .catch(err => {
        dispatch(requestTypes.requestSetSeenFailure(err));
      })
      .then(() => {
        done();
      });
  },
});

export const requestSetSeen = createAsyncThunk<any, any>(
  'requestReducer/requestSetSeen',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      let projectParam = projectId > 0 ? { project_id: projectId } : '';
      projectParam.order_dir = 'desc';
      projectParam.order_by = 'nf';
      projectParam = { ...projectParam, ...payload };

      const response = await requestApi.setSeenRequest(projectParam);

      return { response, ...payload };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const exportDataLogic = createLogic({
  type: requestTypes.EXPORT_DATA,
  latest: true,
  process({ action }, dispatch, done) {
    const { link, filename, filters, searches, search, ...payload } = action.payload;
    const url = new URL(`${DOMAIN_URI}${API_URI}${link}`);
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam = { ...projectParam, ...payload };
    delete projectParam.status;
    // for (let [key, value] of Object.entries(projectParam)) {
    //   url.searchParams.set(key, value);
    // }

    commonApi
      .getFilePost(url, { ...projectParam, filters, searches, search, project_id: projectId })
      .then(response => {
        fileDownload(response.data, filename);
        dispatch(requestTypes.ExportDataSuccess());
      })
      .catch(err => {
        dispatch(requestTypes.ExportDataFaile(err));
      })
      .then(() => done());
  },
});

export const ExportData = createAsyncThunk<any, any>(
  'requestReducer/ExportData',
  async (_payload, { dispatch, rejectWithValue, getState }) => {
    const { link, filters, searches, search, extension, ...payload } = _payload;
    try {
      const currentState = getState();
      const { selected_client_id } = currentState.userReducer;
      const nowDate = new Date().toISOString().slice(0, 19);

      const url = new URL(`${DOMAIN_URI}${API_URI}${link}`);
      const projectId = sessionStorage.getItem('active_project_id');
      let projectParam = projectId > 0 ? { project_id: projectId } : '';
      projectParam = { ...projectParam, ...payload };
      delete projectParam.status;
      const response = await commonApi.getFilePost(url, { ...projectParam, filters, searches, search, project_id: projectId });
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);
      return { response, ...payload };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getWhiteBoardLogic = createLogic({
  type: requestTypes.WHITE_BOARD,
  latest: true,
  cancelType: requestTypes.WHITE_BOARD_CANCEL,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam = { ...projectParam, ...action.payload };
    requestApi
      .getWhiteBoard(projectParam)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.whiteBoardSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.whiteBoardFaile(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (projectId === currentActiveProjectId) {
          dispatch(requestTypes.whiteBoardCancel());
        }
        done();
      });
  },
});

export const whiteBoard = createAsyncThunk<
  any,
  any,
  {
    rejectValue: { data: any; message: string; status: boolean };
  }
>('requestReducer/whiteBoard', async (payload, { dispatch, rejectWithValue }) => {
  const projectId = sessionStorage.getItem('active_project_id');
  let projectParam = projectId > 0 ? { project_id: projectId } : '';
  projectParam = { ...projectParam, ...payload };

  try {
    const response = await requestApi.getWhiteBoard(projectParam);
    // const currentActiveProjectId = sessionStorage.getItem('active_project_id');
    //TODO ???
    // if (projectId === currentActiveProjectId) {
    //   dispatch(requestTypes.whiteBoardCancel());
    // }
    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const getWhiteBoardFiltersLogic = createLogic({
  type: requestTypes.WHITE_BOARD_FILTERS,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam = { ...projectParam, ...action.payload };
    requestApi
      .getWhiteBoardFilters(projectParam)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.whiteBoardFiltersSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.whiteBoardFiltersFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const whiteBoardFilters = createAsyncThunk<any, any, { rejectValue: { data: any; message: string; status: boolean } }>(
  'requestReducer/whiteBoardFilters',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectId = sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    projectParam = { ...projectParam, ...payload };

    try {
      const response = await requestApi.getWhiteBoardFilters(projectParam);
      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getWatchersLogic = createLogic({
  type: requestTypes.GET_WATCHERS,
  latest: true,
  process({ action }, dispatch, done) {
    const requestId = action.payload;
    requestApi
      .getWatchers(requestId)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.getWatchersSuccess(response));
      })
      .catch(err => {
        console.log('getWatchersLogic error', err);
      })
      .then(() => {
        done();
      });
  },
});

export const getWatchers = createAsyncThunk<any, number>('requestReducer/getWatchers', async (payload, { dispatch, rejectWithValue }) => {
  const requestId = payload;
  try {
    const response = await requestApi.getWatchers(requestId);

    return response;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const getWatchersGroupsLogic = createLogic({
  type: requestTypes.GET_WATCHERS_GROUPS,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = action.payload || +sessionStorage.getItem('active_project_id');
    requestApi
      .getWatchersGroups(projectId)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.getWatchersGroupsSuccess(response));
      })
      .catch(err => {
        console.log('getWatchersGroupsLogic error', err);
        dispatch(requestTypes.getWatchersGroupsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getWatchersGroups = createAsyncThunk<any, string>(
  'requestReducer/getWatchersGroups',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectId = payload || +sessionStorage.getItem('active_project_id');
    try {
      const response = await requestApi.getWatchersGroups(projectId);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const createWatchersGroupsLogic = createLogic({
  type: requestTypes.CREATE_WATCHERS_GROUPS,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = +sessionStorage.getItem('active_project_id');
    const data = action.payload;
    requestApi
      .watchersGroupsCreate({ project_id: projectId, ...data })
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.createWatchersGroupsSuccess(response));
      })
      .catch(err => {
        console.log('createWatchersGroupsLogic error', err);
        dispatch(requestTypes.createWatchersGroupsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const createWatchersGroups = createAsyncThunk<any, any>(
  'requestReducer/createWatchersGroups',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectId = +sessionStorage.getItem('active_project_id');
    try {
      const response = await requestApi.watchersGroupsCreate({ project_id: projectId, ...payload });

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const deleteWatchersGroupsLogic = createLogic({
  type: requestTypes.DELETE_WATCHERS_GROUPS,
  latest: true,
  process({ action }, dispatch, done) {
    const id = action.payload;
    requestApi
      .watchersGroupsRemove({ group_id: id }, id)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.deleteWatchersGroupsSuccess(response));
      })
      .catch(err => {
        console.log('deleteWatchersGroupsLogic error', err);
        dispatch(requestTypes.deleteWatchersGroupsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const deleteWatchersGroups = createAsyncThunk<any, any>(
  'requestReducer/deleteWatchersGroups',
  async (payload, { dispatch, rejectWithValue }) => {
    const id = payload;
    try {
      const response = await requestApi.watchersGroupsRemove({ group_id: id }, id);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const updateWatchersGroupsLogic = createLogic({
  type: requestTypes.UPDATE_WATCHERS_GROUPS,
  latest: true,
  process({ action }, dispatch, done) {
    const { saveData, id } = action.payload;
    requestApi
      .watchersGroupsUpdate(saveData, id)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.updateWatchersGroupsSuccess(response));
      })
      .catch(err => {
        console.log('updateWatchersGroupsLogic error', err);
        dispatch(requestTypes.updateWatchersGroupsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const updateWatchersGroups = createAsyncThunk<any, any>(
  'requestReducer/updateWatchersGroups',
  async (payload, { dispatch, rejectWithValue }) => {
    const { saveData, id } = payload;
    try {
      const response = await requestApi.watchersGroupsUpdate(saveData, id);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const addToWatchersGroupsLogic = createLogic({
  type: requestTypes.ADD_TO_WATCHERS_GROUPS,
  latest: true,
  process({ action }, dispatch, done) {
    const data = action.payload;
    requestApi
      .watchersGroupsAddToWatchers(data)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.addToWatchersGroupsSuccess(response));
      })
      .catch(err => {
        console.log('addToWatchersGroupsLogic error', err);
        dispatch(requestTypes.addToWatchersGroupsFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const addToWatchersGroups = createAsyncThunk<any, any>(
  'requestReducer/addToWatchersGroups',
  async (payload, { dispatch, rejectWithValue }) => {
    const { group_ids, owner_id, project_id, type, callback } = payload;
    try {
      const response = await requestApi.watchersGroupsAddToWatchers(type, { owner_id, project_id, group_ids });

      dispatch(getFeed({ owner_id, type }));

      if (callback) {
        callback();
      }

      switch (type) {
        case 'request':
          return dispatch(handleRequestChangeWatchlist({ watcher_groups: response?.response?.resource }));
        case 'deliverable':
          return dispatch(handleDeliverableChangeWatchlist({ watcher_groups: response?.response?.resource }));
        case 'submittal':
          return dispatch(handleSubmittalChangeWatchlist({ watcher_groups: response?.response?.resource }));
        default:
          break;
      }

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const searchWatchersLogic = createLogic({
  type: requestTypes.SEARCH_WATCHERS_REQUEST,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = +sessionStorage.getItem('active_project_id');
    let projectParam = projectId > 0 ? { project_id: projectId } : '';
    if (action.payload.isAddToGroup) {
      projectParam = {
        ...projectParam,
        search: action.payload.search,
        all: 1,
        limit: action.payload.limit,
        request_id: action.payload.request_id,
      };
    } else {
      projectParam = { ...projectParam, ...action.payload };
    }
    requestApi
      .searchWatchers(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.searchWatchersFail(response.data));
          throw response;
        }
        if (action.payload.isAddToGroup) {
          dispatch(requestTypes.searchWatchersAddToGroupSuccess(response));
        } else {
          dispatch(requestTypes.searchWatchersSuccess(response));
        }
      })
      .catch(err => {
        dispatch(requestTypes.searchWatchersFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const searchWatchersRequest = createAsyncThunk<any, ISearchWatchersRequestPayload>(
  'requestReducer/searchWatchersRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = payload.project_id || +sessionStorage.getItem('active_project_id');
      const projectParam = {
        project_id: projectId,
        search: payload.search,
        all: 1,
        limit: payload.limit,
      };

      return await requestApi.searchWatchers(projectParam);
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _createNewDueDate = createLogic({
  type: requestTypes.CREATE_NEW_DUE_DATE,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    requestApi
      .createNewDueDate(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.createNewDueDateFail(response.data));
          throw response;
        }
        dispatch(requestTypes.createNewDueDateSuccess(response.data));
      })
      .catch(err => {
        dispatch(requestTypes.createNewDueDateFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const createNewDueDate = createAsyncThunk<any, ICreateDueDatePayload>(
  'requestReducer/createNewDueDate',
  async (payload, { dispatch, rejectWithValue }) => {
    const { callback, ...projectParam } = payload;
    try {
      const response = await requestApi.createNewDueDate(projectParam);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _createNewResponseCompany = createLogic({
  type: requestTypes.CREATE_NEW_RESPONSE_COMPANY,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    requestApi
      .createNewResponseCompany(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.createNewResponseCompanyFail(response.data));
          throw response;
        }
        dispatch(requestTypes.createNewResponseCompanySuccess(response.data));
      })
      .catch(err => {
        dispatch(requestTypes.createNewResponseCompanyFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const createNewResponseCompany = createAsyncThunk<any, RequestToNewResponseCompany>(
  'requestReducer/createNewResponseCompany',
  async (payload, { dispatch, rejectWithValue }) => {
    const { callback, ...projectParam } = payload;
    try {
      const response = await requestApi.createNewResponseCompany(projectParam);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _updateNewResponseCompany = createLogic({
  type: requestTypes.UPDATE_NEW_RESPONSE_COMPANY,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    requestApi
      .updateResponseCompany(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.updateNewResponseCompanyFail(response.data));
          throw response;
        }
        dispatch(requestTypes.updateNewResponseCompanySuccess(response.data));
        action.payload.callback && action.payload.callback();
      })
      .catch(err => {
        dispatch(requestTypes.updateNewResponseCompanyFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const updateNewResponseCompany = createAsyncThunk<any, IUpdateNewResponseCompanyPayload>(
  'requestReducer/updateNewResponseCompany',
  async (payload, { dispatch, rejectWithValue }) => {
    const { callback, ...projectParam } = payload;
    try {
      const response = await requestApi.updateResponseCompany(projectParam);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _updateNewDueDate = createLogic({
  type: requestTypes.UPDATE_NEW_DUE_DATE,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    requestApi
      .updateNewDueDate(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.updateNewDueDateFail(response.data));
          throw response;
        }
        dispatch(requestTypes.updateNewDueDateSuccess(response.data));
        // dispatch(requestTypes.createNewDueDateSuccess(response.data));
      })
      .catch(err => {
        dispatch(requestTypes.updateNewDueDateFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const updateNewDueDate = createAsyncThunk<any, any>(
  'requestReducer/updateNewDueDate',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectParam = payload;
    try {
      const response = await requestApi.updateNewDueDate(projectParam);

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const sendBugReportLogic = createLogic({
  type: requestTypes.SEND_BUG_REPORT,
  latest: true,
  process({ action }, dispatch, done) {
    const projectParam = action.payload;
    requestApi
      .sendBugReport(projectParam)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.sendBugReportFail(response.data));
          throw response;
        }
        dispatch(requestTypes.sendBugReportSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.sendBugReportFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const sendBugReport = createAsyncThunk<any, ISendBugReportPayload, { rejectValue: { message: string; errors: any } }>(
  'requestReducer/sendBugReport',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectParam = payload;

    try {
      const response = await requestApi.sendBugReport(projectParam);
      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const searchUsersRequest = createAsyncThunk<any, any>(
  'requestReducer/searchUsersRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = +sessionStorage.getItem('active_project_id');
      let projectParam = projectId > 0 ? { project_id: projectId } : '';
      projectParam = { ...projectParam, ...payload };

      const response = await requestApi.searchUsers(projectParam);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const linkNfPredecessor = createAsyncThunk<FullRequestModel, ILinkNfPayload>(
  'requestReducer/linkNfPredecessor',
  async (payload, { dispatch, rejectWithValue }) => {
    const { predecessors, request_id, callback } = payload;
    try {
      const data = { predecessors, request_id };
      const { requestSyncList } = requestSlice.actions;
      const response = await requestApi.linkNfPredecessor(data);
      dispatch(requestSyncList(response.response));
      callback && callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const unlinkNfPredecessor = createAsyncThunk<FullRequestModel, IUnlinkNfPayload>(
  'requestReducer/unlinkNfPredecessor',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const { requestSyncList } = requestSlice.actions;
      const response = await requestApi.unlinkNfPredecessor(data);

      dispatch(requestSyncList(response.response));
      callback && callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const linkNfSuccessor = createAsyncThunk<FullRequestModel, ILinkNfPayload>(
  'requestReducer/linkNfSuccessor',
  async (payload, { dispatch, rejectWithValue }) => {
    const { successors, request_id, callback } = payload;
    try {
      const data = { successors, request_id };
      const { requestSyncList } = requestSlice.actions;
      const response = await requestApi.linkNfSuccessor(data);
      dispatch(requestSyncList(response.response));
      callback && callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const unlinkNfSuccessor = createAsyncThunk<FullRequestModel, IUnlinkNfPayload>(
  'requestReducer/unlinkNfSuccessor',
  async (payload, { dispatch, rejectWithValue }) => {
    const { data, callback } = payload;
    try {
      const { requestSyncList } = requestSlice.actions;
      const response = await requestApi.unlinkNfSuccessor(data);
      dispatch(requestSyncList(response.response));
      callback && callback();

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getNfLink = createAsyncThunk<GetLinkingNfSuggestionResponseModel, GetLinkingNfSuggestionModel>(
  'requestReducer/getNfLink',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { requestId, relation_type, callback } = payload;

      const response = await requestApi.getNfLink(requestId, relation_type);

      callback && callback(Object.values(response.data));

      return { relation_type, suggestions: Object.values(response.data) };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const requestView = createAsyncThunk<any, { id: number }>(
  'requestReducer/requestView',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await requestApi.requestView(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const _setIncorporated = createLogic({
  type: requestTypes.SET_INCORPORATED,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .setIncorporated(action.payload)
      .then(response => {
        if (response.status === true || response.status === 200) {
          dispatch(requestTypes.setIncorporatedSuccess(response));
          done();
        } else {
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const setIncorporated = createAsyncThunk<any, IncorporateFormData>(
  'requestReducer/setIncorporated',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await requestApi.setIncorporated(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getFilteredRequestLogic = createLogic({
  type: requestTypes.GET_FILTERED_REQUEST,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    if (!projectId) {
      return;
    }
    action.payload.project_id = projectId;
    requestApi
      .getFilteredRequest(action.payload.urlPageForRequestFilter, action.payload)
      .then(response => {
        if (response.status === false || response.status === 400) {
          dispatch(requestTypes.getFilteredRequestFail(response.data));
          throw response;
        }
        // Set for detect which request was getting with filters
        // response.data.isFilteredRequest = true;
        // response.response.resource.isFilteredRequest = true;

        dispatch(requestTypes.getFilteredRequestSuccess(response));
        dispatch(requestTypes.singleRequestForProjectLoadingSucces(response));
        dispatch(singleRequestForProjectLoadingSucces(response));
      })
      .catch(err => {
        dispatch(requestTypes.getFilteredRequestSuccess(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getFilteredRequest = createAsyncThunk<any, any>(
  'requestReducer/getFilteredRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    const projectId = sessionStorage.getItem('active_project_id');
    try {
      const { singleRequestForProjectLoadingSucces: singleRequestForProjectLoadingSucces_RR } = requestSlice.actions;

      if (!projectId) {
        return;
      }
      payload.project_id = projectId;

      const response = await requestApi.getFilteredRequest(payload.urlPageForRequestFilter, payload);
      dispatch(singleRequestForProjectLoadingSucces_RR(response));
      dispatch(singleRequestForProjectLoadingSucces(response));

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
const getNeedListAllRequestIdsLogic = createLogic({
  type: requestTypes.GET_NEED_LIST_ALL_IDS,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .getNeedListAllRequestIds(action.payload)
      .then(response => {
        if (response.status === true || response.status === 200) {
          dispatch(requestTypes.getNeedListAllRequestSuccess(response));
          done();
        } else {
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const getNeedListAllRequest = createAsyncThunk<any, any>(
  'requestReducer/getNeedListAllRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.getNeedListAllRequestIds(payload);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getSuccPredFromRequestLogic = createLogic({
  type: requestTypes.GET_SUCC_PRED__FROM_REQUEST,
  latest: true,
  process({ action }, dispatch, done) {
    requestApi
      .getSuccPredFromRequest(action.payload)
      .then(response => {
        if (response.status === true || response.status === 200) {
          dispatch(requestTypes.getSuccPredFromRequestSuccess(response.data));
          done();
        } else {
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const getSuccPredFromRequest = createAsyncThunk<any, number>(
  'requestReducer/getSuccPredFromRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.getSuccPredFromRequest(payload);
      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const editHashtagLogic = createLogic({
  type: requestTypes.EDIT_HASHTAG,
  latest: true,
  process({ action }, dispatch, done) {
    const { id, data, callback } = action.payload;
    requestApi
      .editHashtag(id, data)
      .then(response => {
        if (response.status === true || response.status === 200) {
          dispatch(requestTypes.editHashtagSuccess(response.data));
          if (callback) {
            callback();
          }
          done();
        } else {
          dispatch(requestTypes.editHashtagFail(response.data));
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const editHashtag = createAsyncThunk<any, any>('requestReducer/editHashtag', async (payload, { dispatch, rejectWithValue }) => {
  const { id, data, callback } = payload;

  try {
    const response = await requestApi.editHashtag(id, data);

    if (callback) {
      callback();
    }

    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const deleteHashtagLogic = createLogic({
  type: requestTypes.DELETE_HASHTAG,
  latest: true,
  process({ action }, dispatch, done) {
    const { id, callback } = action.payload;
    requestApi
      .deleteHashtag(id)
      .then(response => {
        if (response.status === true || response.status === 200) {
          dispatch(requestTypes.deleteHashtagSuccess(response.response.resource));
          if (callback) {
            callback();
          }
          done();
        } else {
          dispatch(requestTypes.deleteHashtagFail(response.response.resource));
          throw response;
        }
      })
      .catch(err => {
        done();
      })
      .then(() => {
        done();
      });
  },
});

export const deleteHashtag = createAsyncThunk<any, any>('requestReducer/deleteHashtag', async (payload, { dispatch, rejectWithValue }) => {
  const { id, callback } = payload;
  try {
    const response = await requestApi.deleteHashtag(id);

    if (callback) {
      callback();
    }

    return response.response.resource;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

const deactivatedStatusLogic = createLogic({
  type: requestTypes.DEACTIVATED_STATUS,
  latest: true,
  process({ action }, dispatch, done) {
    const { data, callback } = action.payload;

    const request_id = data.id;
    const statusRequest = data.is_deactivated == 0 ? 'deactivated' : 'activate';
    // const callback = action.payload.

    const sendData = { request_id, statusRequest };
    requestApi
      .deactivatedStatus(sendData)
      .then(response => {
        if (response.status === false || response.status === 400) {
          throw response;
        }
        const response_data = { id: response.data[0].id, is_deactivated: response.data[0].is_deactivated };
        dispatch(requestTypes.deactivatedStatusSuccess(response_data));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.deactivatedStatusFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const deactivatedStatus = createAsyncThunk<any, IRequestDeactivatePayload>(
  'requestReducer/deactivatedStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    const { request_id, action, callback } = payload;
    try {
      const sendData = { request_id, action };

      const response = await requestApi.deactivatedStatus(sendData);

      if (callback) {
        callback();
      }

      return sendData;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setSyncProcore = createAsyncThunk<any, IRequestSyncProcorePayload>(
  'requestReducer/deactivatedStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    const { request_id, is_procore_sync_needed, callback } = payload;
    try {
      const sendData = { is_procore_sync_needed };

      const response = await requestApi.setSyncProcore(request_id, sendData);

      if (callback) {
        callback();
      }

      return sendData;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const declineCounterLogic = createLogic({
  type: requestTypes.DECLINE_COUNTER,
  latest: true,
  process({ action }, dispatch, done) {
    const projectId = sessionStorage.getItem('active_project_id');
    const data = {
      id: action.payload.id,
      project_id: projectId,
      urlPageForRequestFilter: 'needlist',
    };

    requestApi
      .declineCounter(data)
      .then(response => {
        if (response.status === false) {
          throw response;
        }
        dispatch(requestTypes.declineCounterSuccess(response));
      })
      .catch(err => {
        dispatch(requestTypes.declineCounterFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const declineCounter = createAsyncThunk<any, any>(
  'requestReducer/declineCounter',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectId = sessionStorage.getItem('active_project_id');
      const data = {
        id: payload.id,
        project_id: projectId,
        urlPageForRequestFilter: 'needlist',
      };

      const response = await requestApi.declineCounter(data);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getMainMfLogic = createLogic({
  type: requestTypes.GET_MAIN_MF,
  latest: true,
  cancelType: requestTypes.GET_MAIN_MF_CANCEL,
  process({ action, getState }, dispatch, done) {
    const { projectId, type, callback } = action.payload;

    const sendData = {
      projectId: projectId,
    };

    if (type) {
      sendData.type = type;
    }

    requestApi
      .getMainMf(sendData)
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.getMainMfSuccess(result.data));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.getMainMfFail(err));
      })
      .then(() => {
        const currentActiveProjectId = sessionStorage.getItem('active_project_id');

        if (+projectId === +currentActiveProjectId) {
          dispatch(requestTypes.getMainMfCancel());
        }
        done();
      });
  },
});

export const getMainMf = createAsyncThunk<any, IGetMainMfPayload>(
  'requestReducer/getMainMf',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId, type, isPCDFilters, callback } = payload;

    try {
      const sendData: any = {
        projectId,
        isPCDFilters,
      };

      if (type) {
        sendData.type = type;
      }

      const response = await requestApi.getMainMf(sendData);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const changeNfStatusToDelivarable = createAsyncThunk<RequestModel, PlaneControlDeliverable.ChangeNfValidityStatus>(
  'requestReducer/changeNfStatusToDelivarable',
  async (payload, { dispatch, rejectWithValue }) => {
    const { owner_id, data, callback } = payload;
    try {
      const response = await requestApi.changeNfStatusToDelivarable({ owner_id, data });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getMfFromParentLogic = createLogic({
  type: requestTypes.GET_MF_FROM_PARENT,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { projectId, mfId, callback } = action.payload;

    requestApi
      .getMfFromParent({ projectId, mfId })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.getMfFromParentSuccess(result.data));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.getMfFromParentFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getMfFromParent = createAsyncThunk<IRequestMfTree, MfRequestPayload>(
  'requestReducer/getMfFromParent',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId, mfId, isPCDFilters, callback } = payload;

    try {
      const response = await requestApi.getMfFromParent({ projectId, mfId, isPCDFilters });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getMainUfLogic = createLogic({
  type: requestTypes.GET_MAIN_UF,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { projectId, type, callback } = action.payload;

    const sendData = {
      projectId: projectId,
    };

    if (type) {
      sendData.type = type;
    }

    requestApi
      .getMainUf(sendData)
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.getMainUfSuccess(result.data));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.getMainUfFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getMainUf = createAsyncThunk<UfMfValueModel[], UfRequestPayload>(
  'requestReducer/getMainUf',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId, type, callback, isPCDFilters } = payload;

    try {
      const sendData: any = {
        projectId,
        isPCDFilters,
      };

      if (type) {
        sendData.type = type;
      }

      const response = await requestApi.getMainUf(sendData);

      if (callback) {
        callback();
      }

      return response.data.mainUfc;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getUfFromParentLogic = createLogic({
  type: requestTypes.GET_UF_FROM_PARENT,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { projectId, ufcId, callback } = action.payload;

    requestApi
      .getUfFromParent({ projectId, ufcId })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.getUfFromParentSuccess(result.data));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.getUfFromParentFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const getUfFromParent = createAsyncThunk<IRequestUfTree, UfRequestPayload>(
  'requestReducer/getUfFromParent',
  async (payload, { dispatch, rejectWithValue }) => {
    const { projectId, ufcId, isPCDFilters, callback } = payload;

    try {
      const response = await requestApi.getUfFromParent({ projectId, ufcId, isPCDFilters });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const searchRequestLogic = createLogic({
  type: requestTypes.SEARCH_REQUEST,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { project_id, search_flags, text, callback, type, party_ids, discipline_id } = action.payload;

    requestApi
      .searchRequest({ project_id, search_flags, party_ids, discipline_id, text, type })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        if (callback) {
          callback(result.response.resource, null);
        }
      })
      .catch(err => {
        if (callback) {
          callback(null, err);
        }
      })
      .then(() => {
        done();
      });
  },
});

export const searchRequest = createAsyncThunk<any, any>(
  'requestReducer/searchRequest',
  async (payload, { rejectWithValue, signal, dispatch }) => {
    const { project_id, search_flags, text, callback, type, party_ids, discipline_id } = payload;

    try {
      const response = await requestApi.searchRequest({ project_id, search_flags, party_ids, discipline_id, text, type });

      if (callback && !signal.aborted) {
        callback(response.response.resource, null);
      }
      return response;
    } catch (error) {
      if (callback) {
        callback(null, error);
      }
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const getRequestFilterLogic = createLogic({
  type: requestTypes.GET_SEARCH_REQUEST_FILTERS,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { callback, params } = action.payload;

    requestApi
      .getSearchRequestFilters(params)
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.getSearchRequestFiltersSuccess(result.response.resource));
        if (callback) {
          callback(result.response.resource, null);
        }
      })
      .catch(err => {
        if (callback) {
          callback(null, err);
        }
      })
      .then(() => {
        done();
      });
  },
});

export const getSearchRequestFilters = createAsyncThunk<any, any>(
  'requestReducer/getSearchRequestFilters',
  async (payload, { rejectWithValue, dispatch }) => {
    const { callback, params } = payload;

    try {
      const response = await requestApi.getSearchRequestFilters(params);
      if (callback) {
        callback(response.response.resource, null);
      }
      return response.response.resource;
    } catch (error) {
      if (callback) {
        callback(null, error);
      }
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

const searchMForUFCodeLogic = createLogic({
  type: requestTypes.SEARCH_MF_OR_UF_CODE,
  latest: true,
  process({ action, getState }, dispatch, done) {
    const { projectId, textSearch, table, callback } = action.payload;

    requestApi
      .searchMForUFCode({ projectId, textSearch, table })
      .then(result => {
        if (result.status === false) {
          throw result;
        }
        dispatch(requestTypes.searchMForUFCodeSuccess({ data: result.data, table }));
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        dispatch(requestTypes.searchMForUFCodeFail(err));
      })
      .then(() => {
        done();
      });
  },
});

export const searchMForUFCode = createAsyncThunk<UfMfSearchResponsePayload, MfRequestPayload | UfRequestPayload>(
  'requestReducer/searchMForUFCode',
  async (payload, { rejectWithValue, dispatch }) => {
    const { projectId, textSearch, table, callback } = payload;
    try {
      const response = await requestApi.searchMForUFCode({ projectId, textSearch, table });

      if (callback) {
        callback();
      }

      return { data: response.data, table };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const checkIfSnippetInSandBoxSection = createAsyncThunk<ICheckIfItemInSectionResponseData, ICheckIfItemInSectionPayload>(
  'requestReducer/checkIfSnippetInSandBoxSection',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { sandBoxItem, entity, sandBoxFiltersParams } = payload;
    const currentState = getState() as RootState;
    const { isTimerActiveRequest, isTimerActiveDeliverable } = currentState.requestReducer;
    const project_id = +sessionStorage.getItem('active_project_id');

    const params = {
      check_id: sandBoxItem.id,
      project_id,
      ...sandBoxFiltersParams,
    };
    const { setIsTimerActiveRequest, setIsTimerActiveDeliverable } = requestSlice.actions;

    const setTimerActiveFunction = entity => {
      if (!entity) {
        return;
      }

      const isTimerActive = entity === 'request' ? isTimerActiveRequest : isTimerActiveDeliverable;

      if (!isTimerActive) {
        const setIsTimerActiveType = entity === 'request' ? setIsTimerActiveRequest : setIsTimerActiveDeliverable;
        dispatch(setIsTimerActiveType(true));
        const timer = Math.floor(Math.random() * (50000 - 30000 + 1)) + 30000;

        setTimeout(() => {
          const { isTimerActiveRequest, isTimerActiveDeliverable } = getState().requestReducer;
          const isTimerActive = entity === 'request' ? isTimerActiveRequest : isTimerActiveDeliverable;

          if (isTimerActive) {
            dispatch(getSandBoxCounters({ entity, ...sandBoxFiltersParams }));
          }
        }, timer);
      }
    };

    try {
      setTimerActiveFunction(entity);
      const timer = Math.floor(Math.random() * (5000 + 1));

      const result: any = await new Promise(resolve => {
        setTimeout(async () => {
          const response = await requestApi.checkIfSnippetInSandBoxSection(params);

          resolve(response);
        }, timer);
      });

      const { check_accordion_list, marker } = result.data;

      return { check_accordion_list, sandBoxItem: { ...sandBoxItem, marker }, params };
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
export default [
  // getRequestLogic,
  // saveRequestLogic,
  // deleteRequestLogic,
  // saveCommitmentLogic,
  // getNeedListLogic,
  // getWhiteBoardLogic,
  // exportDataLogic,
  // getSingleRequestLogic,
  // sendBugReportLogic,
  // getRequestByNf,
  // getSandBoxDataLogic,
  // createNewDueDate,
  // updateNewDueDate,
  // getUserWatchersLogic,
  // createNewResponseCompany,
  // updateNewResponseCompany,
  // getWhiteBoardFiltersLogic,
  // setSeenRequestLogic,
  // getTags,
  // searchUsersLogic,
  // linkNfPredecessorLogic,
  // getNfLinkLogic,
  // requestView,
  // setIncorporated,
  // getFilteredRequestLogic,
  // getAvailableBulkEditFieldsLogic,
  // bulkEditLogic,
  // searchWatchersLogic,
  // getWatchersGroupsLogic,
  // createWatchersGroupsLogic,
  // deleteWatchersGroupsLogic,
  // updateWatchersGroupsLogic,
  // addToWatchersGroupsLogic,
  // getNeedListAllRequestIdsLogic,
  // unlinkNfPredecessorLogic,
  // linkNfSuccessorLogic,
  // unlinkNfSuccessorLogic,
  // getSuccPredFromRequestLogic,
  // getNeedListColumnOrderLogic,
  // setNeedListColumnOrderLogic,
  // deactivatedStatusLogic,
  // editHashtagLogic,
  // deleteHashtagLogic,
  // updateRequestPrivate,
  // declineCounterLogic,
  // getMainMfLogic,
  // getMfFromParentLogic,
  // getMainUfLogic,
  // getUfFromParentLogic,
  // searchMForUFCodeLogic,
  // searchRequestLogic,
  // getRequestFilterLogic,
];
