import React, { useEffect, useState } from 'react';
import { DatabaseService } from '../../../service/IndexedDB/DBConfig';
import { checkIsDateAfter, clearNeedListCacheProfile } from '../../../service/IndexedDB/Helper';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { indexedDbSlice } from '../../../store/indexedDb/indexedDb';

const { setIsNeedListLoadingStatus } = requestSlice.actions;
const { setField } = indexedDbSlice.actions;

const IndexedDbComponent = () => {
  const dispatch = useAppDispatch();

  // const [param, setParam] = useState<any>(null);
  // const [isNeedListCacheOutdatedChecked, setIsNeedListCacheOutdatedChecked] = useState<boolean>(false);
  const [isDbRecreate, setIsDbRecreate] = useState<boolean>(false);

  const activeProjectId = useAppSelector(state => state.userReducer.active_project_id);
  const userData = useAppSelector(state => state.userReducer);
  const refreshes = useAppSelector(state => state.projectReducer?.refreshes);
  const isDbCreated = useAppSelector(state => state.indexedDbSlice?.isDbCreated);

  const [prevUserData, setPrevUserData] = useState(null);
  const [prevIsDbRecreate, setPrevIsDbRecreate] = useState(false);

  useEffect(() => {
    dispatch(setField({ field: 'isDbCreated', value: false }));

    // const storedParam = localStorage.getItem('token');

    // if (storedParam) {
    //   setParam(storedParam);
    // }

    // Listen for storage events
    // window.addEventListener('storage', handleStorageChange);

    return () => {
      // Clean up event listeners when component unmounts
      // window.removeEventListener('storage', handleStorageChange);
      dispatch(setField({ field: 'isDbCreated', value: false }));
    };
  }, []); // Empty dependency array means this effect runs once after the first render

  useEffect(() => {
    // Update the previous state to the current value
    setPrevUserData(userData);

    // Your other useEffect logic here
  }, [userData, prevUserData]); // Make sure to include valueFromRedux and prevState in the dependency array

  useEffect(() => {
    // Update the previous state to the current value
    setPrevIsDbRecreate(prevIsDbRecreate);

    // Your other useEffect logic here
  }, [isDbRecreate, prevIsDbRecreate]); // Make sure to include valueFromRedux and prevState in the dependency array

  useEffect(() => {
    // DB outdated checked
    const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));
    if (needListCashedData && needListCashedData[activeProjectId]) {
      const isNeedListOutDated = checkIsDateAfter(refreshes?.request, needListCashedData[activeProjectId]?.cachedTimestamp);
      sessionStorage.setItem('isNeedListOutdated', JSON.stringify(isNeedListOutDated));
    }
    // setIsNeedListCacheOutdatedChecked(true);
  }, [refreshes]);

  useEffect(() => {
    // DB initialization or updating
    (async () => {
      if (
        userData?.userInfo?.projects?.length &&
        (userData?.userInfo?.projects?.length !== prevUserData?.userInfo?.projects?.length || isDbRecreate !== prevIsDbRecreate)
      ) {
        const projects = [...userData.userInfo.projects];
        let db = null;
        if (!isDbCreated) {
          db = await DatabaseService.initDb({ projects, dispatch });
        }
        const dBObjectStoreLength = db?.objectStoreNames?.length;
        const userProjectsLength = userData?.userInfo?.projects?.length;

        if (dBObjectStoreLength !== userProjectsLength) {
          setIsDbRecreate(true);
        }

        if (isDbRecreate && dBObjectStoreLength !== userProjectsLength) {
          await DatabaseService.handleProjectChange({ newProjects: projects, dispatch });
          setIsDbRecreate(false);
        }
      }
    })();
  }, [userData, isDbRecreate, isDbCreated]);

  useEffect(() => {
    // DB clearing
    if (userData?.userInfo?.clear_cache) {
      clearNeedListCacheProfile([...userData.userInfo.projects]);
      dispatch(setIsNeedListLoadingStatus(false));
    }
  }, [userData?.userInfo?.clear_cache]);

  useEffect(() => {
    // DB outdated checked
    const needListCashedData = JSON.parse(localStorage.getItem('needListCashedData'));
    if (needListCashedData && needListCashedData[activeProjectId]) {
      const isNeedListOutDated = checkIsDateAfter(refreshes?.request, needListCashedData[activeProjectId]?.cachedTimestamp);
      sessionStorage.setItem('isNeedListOutdated', JSON.stringify(isNeedListOutDated));
    }
    // setIsNeedListCacheOutdatedChecked(true);
  }, [refreshes]);

  // const handleStorageChange = event => {
  //   // Check if the changed key is the one we are interested in
  //   if (event.key === 'token') {
  //     // Update the state with the new parameter value
  //     setParam({ param: event.newValue });
  //   }
  // };

  return <></>;
};

export default IndexedDbComponent;
