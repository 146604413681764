import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { IWithConfirmDialogProps, withConfirmDialog, withConfirmDialogContext } from '../HighOrderComponents';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { commonSlice } from '../../../store/common/commonReducer';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import * as validator from '../../../service/validator';
import {
  BreadcrumbModel,
  FileDataModel,
  FileModel,
  LoginUserModel,
  ProjectInfoModel,
  RequestBuildingModel,
  RequestInfoModel,
  RequestModel,
  RequestUserModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
  TitleModel,
  UfMfValueModel,
  UserModel,
} from '../../../models';
import { PCDApi } from '../../../service/Api/pcd/types';
import { PlaneControlDeliverable } from '../../../store/pcd/types';
import { KeyValueModel, KeyValueNumberModel } from '../../../models/key-value.model';
import { SandBoxAlertWidgetActionsModel } from '../../../store/request/requestReducer.model';
import { dataSelector } from '../../../selectors';
import { projectSlice } from '../../../store/project/projectReducer';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { createPCDCard, deleteDeliverablePCD, getPCDCard, pcdHistoryRecordSend, updatePCDCard } from '../../../store/pcd/pcdThunk';
import { setPromptsAlerts } from '../../../store/user/userThunk';
import FiltersHelper from '../../../helpers/FiltersHelper';
import { getFilteredRequest, requestView, singleRequestLoading, getSandBoxCardIDHotList } from '../../../store/request/requestLogic(HOLD)';
import AccessHelper from '../../../helpers/AccessHelper';
import { DeliverableCardTabNamesModel, deliverableCardTabsNames, SubmitButtonActions } from './constans/constants';
import { isEmptyObject } from '../../../helpers/commonHelpers';
import BottomCardPart from './mainParts/BottomCardPart/BottomCardPart';
import Header from './mainParts/Header/Header';
import TabsContainer from './mainParts/TabsContainer/TabsContainer';
import Modal from '../../controls/DialogPopUp/DialogPopUp';
import TopCardPartPcd from './mainParts/TopCardPartPcd/TopCardPartPcd';
import { Mention } from '../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import isEqual from 'lodash/isEqual';
import { FILE_SIZE_LIMIT } from '../../../service/file';
import BaseHelper from '../../../helpers/BaseHelper';
import StatusNameHelper from '../../../helpers/StatusNameHelper';
import CheckBox from '../../controls/CheckBox/CheckBox';
import CardLinkButton from '../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import { DropdownItemModel } from '../../../models/global';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import CardRequestGlobal from '../CardRequestGlobal/CardRequestGlobal';
import Reasons from './controls/Reasons/Reasons';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import PCDCard = PCDApi.PCDCard;
import RevisionReasonPayload = PlaneControlDeliverable.RevisionReasonPayload;
import Timeout = NodeJS.Timeout;
import { pcdSlice } from '../../../store/pcd/pcdSlice';
import { createRedmineMilestone, issuedRedmineMilestone, removeRedmineAssignedMilestone } from '../../../store/redmine/redmineThunk';
import moment from 'moment';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

import './CardPcdStyles.scss';

const { handleResizablePCCardPart } = commonSlice.actions;
const { setNewBreadcrumbs } = breadcrumbsSlice.actions;
const { projectLoading } = projectSlice.actions;
const { setRequestToOpen, setActiveSandBoxAlertSnippetActions } = requestSlice.actions;
const { resetSocketDeliverableWatchlistState } = pcdSlice.actions;

const validatorPattern = {
  required: ['scheduled_date'],
  requiredOne: ['desc', 'files'],
};

const validatorPatternForTopCard = {
  required: [],
  requiredOne: [],
};

interface SelectedFiltersModel {
  filters: KeyValueModel<any>;
  searches: KeyValueModel<string>;
}

interface IProps extends IWithConfirmDialogProps {
  pcdInfo: {
    [prop: number]: PCDCard;
  };
  selectRequestIdProps: number;
  active_project_id: string;
  userInfo: UserModel;
  requestStatus: boolean;
  requestInfo: RequestInfoModel;
  handleSelectedRequest?: (requestId: number, nf?: number, startDate?: string) => void;
  startDate?: string;
  endDate?: string;
  updatePCDCard: (id: number, parent_id: number, requestData: any, callback: any) => void;
  createPCDCard: (requestData: any, callback: any) => void;
  urlPageForRequestFilter?: string;
  getFilteredRequest: (data: any) => void;
  projectInfo: ProjectInfoModel;
  projectData: any;
  buildingsCollection: KeyValueNumberModel<RequestBuildingModel[]>;
  buildingSpacesCollection: KeyValueNumberModel<SimpleTitleModel[]>;
  closePcd: (isRequestClickOutSide?: boolean) => void;
  singleRequestLoaded: boolean;
  pcdInfoLoading: boolean;
  requestClickOutSide: boolean;
  closeRequestClickOutSide: () => void;
  escapeKeyDown: boolean;
  closeRequestEscapeKeyDown: () => void;
  setPromptsAlerts: (data: string) => void;
  breadcrumbs: BreadcrumbModel[] | null;
  lastPcdCardId: number;
  requestView: ({ id: number }) => void;
  sandBoxActiveAlertSnippetActions: SandBoxAlertWidgetActionsModel;
  setActiveSandBoxAlertSnippetActions: (payload: SandBoxAlertWidgetActionsModel) => void;
  isDragRequestCard?: boolean;
  isUrlPCDCardTab: boolean;
  isSeparateTab: boolean;
  handleResizablePCCardPart: (data: string) => void;
}

let timer: Timeout;

function CardPcd({
  selectRequestIdProps,
  isDragRequestCard,
  urlPageForRequestFilter,
  handleSelectedRequest,
  startDate,
  endDate,
  closePcd,
  requestClickOutSide,
  closeRequestClickOutSide,
  escapeKeyDown,
  closeRequestEscapeKeyDown,
  isUrlPCDCardTab,
  isSeparateTab,
}: IProps) {
  const dispatch = useAppDispatch();
  const { urlProject, location, searchParams } = useRouter();
  const { setUrlCommandCenter, setUrlPCD, setUrlDeliverables, getNfParamsFromURL, getPCDParamsFromURL, returnUrlNfCardTab } =
    useRoutingHelper();
  const themeClass = useGetThemeClass('b-cardPcd');

  const { isMobile, is1440Resolution } = GetResolution();

  const getPcdCardPromise = useRef(null);

  const pcdInfo = useAppSelector<{
    [prop: number]: PCDCard;
  }>(state => state.pcdReducer.pcdInfo);
  const pcdInfoLoading = useAppSelector<boolean>(state => state.pcdReducer.pcdInfoLoading);
  const requestInfo = useAppSelector<RequestInfoModel>(state => dataSelector(state.requestReducer.requestInfo));
  const userInfo = useAppSelector<UserModel>(state => state.userReducer.userInfo);
  const active_project_id = useAppSelector<string>(state => state.userReducer.active_project_id);
  const singleRequestLoaded = useAppSelector<boolean>(state => state.requestReducer.singleRequestLoaded);
  const projectInfo = useAppSelector<ProjectInfoModel>(state => state.projectReducer.projectInfo);
  const breadcrumbs = useAppSelector<BreadcrumbModel[] | null>(state => state.breadcrumbsReducer.firstCardBreadcrumbs);
  const lastPcdCardId = useAppSelector<number>(state => state.pcdReducer.lastPcdCardId);
  const projectData = useAppSelector<any>(state => state.phasePlan.projectData);
  const sandBoxActiveAlertSnippetActions = useAppSelector<SandBoxAlertWidgetActionsModel>(
    state => state.requestReducer.sandBoxActiveAlertSnippetActions,
  );
  const socketWatchlistState = useAppSelector(state => state.pcdReducer.socketWatchlistState);
  const socketHotlistState = useAppSelector(state => state.pcdReducer.socketHotlistState);
  const check_due_date = useAppSelector<any>(state => state.pcdReducer.check_due_date);
  const feedData = useAppSelector(state => state.commentReducer.feed.feedData);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  const redmineData = useAppSelector(state => state.redmineReducer);

  const [login_user, setLogin_user] = useState<LoginUserModel>({} as LoginUserModel);
  const [id, setId] = useState<number>(null);
  const [selectedFiltersStatus, setSelectedFiltersStatus] = useState<SelectedFiltersModel>(null);
  const [originalRequestId, setOriginalRequestId] = useState<number>(null);
  const [selectedRequest, setSelectedRequest] = useState<PCDCard>(null);
  const [selectRequestId, setSelectRequestId] = useState<number>(null);
  const [documentSets, setDocumentSets] = useState<TitleModel[]>([]);
  const [animationBtn, setAnimationBtn] = useState<string>(null);
  const [promptName, setPromptName] = useState<string>(null);
  const [promptTitle, setPromptTitle] = useState<string>(null);
  const [promptMessage, setPromptMessage] = useState<string>(null);
  const [promptNameBtnSave, setPromptNameBtnSave] = useState<string>(null);
  const [promptNameBtnCancel, setPromptNameBtnCancel] = useState<string>(null);
  const [promptNameBtnOk, setPromptNameBtnOk] = useState<string>(null);
  const [isPromptPopUpView, setIsPromptPopUpView] = useState<boolean>(false);
  const [isPromptBtnOk, setIsPromptBtnOk] = useState<boolean>(false);
  const [isMsgNotShowAgain, setIsMsgNotShowAgain] = useState<boolean>(false);
  const [isPromptPopUpClose, setIsPromptPopUpClose] = useState<boolean>(false);
  const [canUpdateSaveBtn, setCanUpdateSaveBtn] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<DeliverableCardTabNamesModel>('deliverable');
  const [isCreateRevision, setIsCreateRevision] = useState<boolean>(false);
  const [revisionParentId, setRevisionParentId] = useState<number>(0);
  const [isOpenedReasonsPopUp, setIsOpenedReasonsPopUp] = useState<boolean>(false);
  const [isUpdateReasonsPopUp, setIsUpdateReasonsPopUp] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [draft, setDraft] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [submit, setSubmit] = useState<any>(null);
  const [isRevisionUpdate, setIsRevisionUpdate] = useState<boolean>(false);
  const [canUpdateTopCard, setCanUpdateTopCard] = useState<boolean>(false);
  const [canUpdateDescription, setCanUpdateDescription] = useState<boolean>(false);

  // Header state
  const [pcdWatchers, setPcdWatchers] = useState(null);
  const [pcdWatchersGroups, setPcdWatchersGroups] = useState(null);

  // Pcd top card states
  const [initialFields, setInitialFields] = useState<any>(null);
  const [criticalPathDate, setCriticalPathDate] = useState<string | Date>(null);
  const [scheduledDate, setScheduledDate] = useState<string | Date>(null);
  const [selectedDocumentSet, setSelectedDocumentSet] = useState<DropdownItemModel | number>(null);
  const [currentUfcCode, setCurrentUfcCode] = useState<UfMfValueModel[]>(null);
  const [currentMfCode, setCurrentMfCode] = useState<UfMfValueModel[]>(null);
  const [pcdTitle, setPcdTitle] = useState<string>('');
  const [selectedResponsibleParty, setSelectedResponsibleParty] = useState<SelectedRequestCompanyModel | null>(null);
  const [errors, setErrors] = useState<KeyValueModel<string>>({});
  const [isCurrentDescription, setIsCurrentDescription] = useState<boolean>(false);
  const [isInitialFieldsGenerated, setIsInitialFieldsGenerated] = useState<boolean>(false);
  const [selectedHashtags, setSelectedHashtags] = useState<TagFormModel[]>([] as TagFormModel[]);

  // Pcd Deliverable Tab states
  const [selectedFiles, setSelectedFiles] = useState<FileModel[]>([]);
  const [currentDescription, setCurrentDescription] = useState<string>('');
  const [mentions, setMentions] = useState<Mention[]>([]);

  const [prevRequestLoading, setPrevRequestLoading] = useState(false);
  const [loadingFilesName, setLoadingFilesName] = useState<SetStateAction<string>[]>([]);
  const [moreLimitFiles, setMoreLimitFiles] = useState<FileModel[] | FileList | any>([]);

  // Delete deliverable logic states
  const [isDeleteDeliverablePopUpOpen, setIsDeleteDeliverablePopUpOpen] = useState<boolean>(false);
  const [isDeleteDeliverableLoading, setIsDeleteDeliverableLoading] = useState<boolean>(false);

  // Reasons pop up states
  const [reasonsPopUpData, setReasonsPopUpData] = useState<RevisionReasonPayload>();

  // Submit states
  const [submitVisiblePopUp, setSubmitVisiblePopUp] = useState<number | null>();
  const [submitDeliverableP1, setSubmitDeliverableP1] = useState<RequestModel[] | null>();
  const [submitDeliverableP2, setSubmitDeliverableP2] = useState<RequestModel[] | null>();
  const [submitDeliverableP3, setSubmitDeliverableP3] = useState<RequestModel[] | null>();
  const [isPromptPopUpPull, setIsPromptPopUpPull] = useState<boolean>(false);
  const [isPromptPopUpDraft, setIsPromptPopUpDraft] = useState<boolean>(false);

  // Opened Request
  const [openedNfId, setOpenedNfId] = useState<number | null>();
  const [openedNfNumber, setOpenedNfNumber] = useState<number | null>();

  // User profile
  const [isOpenAuthorUser, setIsOpenAuthorUser] = useState<boolean>(false);
  const [coordinatesAuthor, setCoordinatesAuthor] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState<RequestUserModel>(null);
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);

  // Content switcher
  const [isHiddenFields, setIsHiddenFields] = useState<boolean>(false);

  const isAdmin = useMemo(() => {
    return userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '1' || key === '4').length;
  }, [userInfo.roles]);

  const isAccessToEditPCDCard =
    selectedRequest && selectedRequest.is_deactivated == 1 ? false : AccessHelper.isAccessToEditPCDCard(userInfo, selectedRequest);

  const isCurrentRevision = useMemo(() => {
    if (selectedRequest) {
      return selectedRequest.revisions?.length && selectedRequest.id === selectedRequest.revisions.at(-1).id;
    }

    return false;
  }, [selectedRequest]);

  const isPCDFieldsDisabled = useMemo(() => {
    return !isAccessToEditPCDCard || !selectedRequest || pcdInfoLoading || selectedRequest?.status === 'superseded' || !isCurrentRevision;
  }, [isAccessToEditPCDCard, selectedRequest, pcdInfoLoading, isCurrentRevision]);

  const isLastRevisionSubmitted = useMemo(() => {
    return selectedRequest && selectedRequest.revisions.at(-1)?.is_whiteboard;
  }, [selectedRequest]);

  const isCreateSubmitButtonDisabled = useMemo(() => {
    return (
      pcdInfoLoading ||
      (selectedRequest && !selectedRequest?.critical_date) ||
      (selectedRequest?.is_whiteboard && !isLastRevisionSubmitted) ||
      !isCurrentRevision ||
      !isAccessToEditPCDCard
    );
  }, [pcdInfoLoading, selectedRequest, isLastRevisionSubmitted, isCurrentRevision, isAccessToEditPCDCard]);

  const isRevisionSubmitted = useMemo(() => {
    return selectedRequest && selectedRequest?.is_whiteboard && !isCreateRevision;
  }, [selectedRequest, isCreateRevision]);

  const isRevisionUpdateDraftButtonDisabled = useMemo(() => {
    return (!canUpdateTopCard && !canUpdateDescription) || pcdInfoLoading || !isCurrentRevision;
  }, [isRevisionSubmitted, canUpdateTopCard, canUpdateDescription, pcdInfoLoading, isCurrentRevision]);

  const isRevisionUpdateDraftButtonDisabledTopCard = useMemo(() => {
    return !canUpdateTopCard || canUpdateDescription || pcdInfoLoading || !isCurrentRevision;
  }, [isRevisionSubmitted, canUpdateTopCard, canUpdateDescription, pcdInfoLoading, isCurrentRevision]);

  const nextRevisionObject: PCDCard = useMemo(() => {
    return selectedRequest && selectedRequest.revisions && selectedRequest.revisions[selectedRequest.revision + 1];
  }, [selectedRequest]);

  const isNextRevisionDrafted: boolean = useMemo(() => {
    return (
      selectedRequest && nextRevisionObject && nextRevisionObject.status === 'not_issued_yet' && nextRevisionObject.is_whiteboard === 0
    );
  }, [selectedRequest, nextRevisionObject]);

  const savedHashtags: TagFormModel[] = useMemo(() => {
    return (selectedRequest?.hashtags || []).map(tag => {
      return {
        label: tag.name,
        value: tag.id,
        color: tag.color,
        link: tag.link,
        is_auto: tag.is_auto,
        text_color: tag.text_color,
      };
    });
  }, [selectedRequest?.hashtags]);

  useEffect(() => {
    initFields();
    initPCDFields();
  }, []);

  useEffect(() => {
    if (socketWatchlistState.changedNow) {
      socketWatchlistState.watchers && setPcdWatchers(socketWatchlistState.watchers);
      socketWatchlistState.watcher_groups && setPcdWatchersGroups(socketWatchlistState.watcher_groups);
      dispatch(resetSocketDeliverableWatchlistState());
    }
  }, [socketWatchlistState.changedNow]);

  useEffect(() => {
    if (selectedRequest) {
      setPcdWatchers(selectedRequest.watchers);
      setPcdWatchersGroups(selectedRequest.watcher_groups);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (isCreateRevision) {
      initFields();
      setIsInitialFieldsGenerated(true);
    }
  }, [isCreateRevision]);

  useEffect(() => {
    if (isCreateRevision && isInitialFieldsGenerated) {
      requestSave(0);
      setIsInitialFieldsGenerated(false);
    }
  }, [isCreateRevision, isInitialFieldsGenerated]);

  useEffect(() => {
    if (promptName === 'pcdClickOutSide' && isPromptBtnOk) {
      requestSave(0);
    }
  }, [promptName, isPromptBtnOk]);

  useEffect(() => {
    if (selectedRequest) {
      getSubmitVisible(selectedRequest?.requests);
      getRequests(selectedRequest?.requests);
      initPCDFields();

      setSelectedFiles(selectedRequest.files || []);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if ((promptName === 'RevisionUpdate' || promptName === 'RevisionSubmit') && isPromptBtnOk) {
      requestSave(1);
    }
  }, [promptName, isPromptBtnOk]);

  useEffect(() => {
    if (initialFields) {
      const isTopCardChanged = checkIsTopCardChanged();
      const isDescriptionChanged = checkIsDescriptionChanged();

      if (isTopCardChanged && !canUpdateTopCard) {
        setCanUpdateTopCard(true);
      } else if (!isTopCardChanged && canUpdateTopCard) {
        setCanUpdateTopCard(false);
      }

      if (isDescriptionChanged && !canUpdateDescription) {
        setCanUpdateDescription(true);
      } else if (!isDescriptionChanged && canUpdateDescription) {
        setCanUpdateDescription(false);
      }
    }
  });

  useEffect(() => {
    initDataFromProject();
    setSelectRequestId(selectRequestIdProps);
    setLogin_user({
      id: +userInfo.id,
      role: userInfo.roles,
    });

    const tabParam = searchParams.get('tab') as DeliverableCardTabNamesModel;
    const buildingId = searchParams.get('building') as DeliverableCardTabNamesModel;
    const pcdId = searchParams.get('deliverable') as DeliverableCardTabNamesModel;

    if (pcdId && buildingId) {
      if (deliverableCardTabsNames.includes(tabParam)) {
        setActiveTab(tabParam);
        setUrlPCD(String(pcdId), String(buildingId), tabParam);
      } else {
        setActiveTab('deliverable');
        setUrlPCD(String(pcdId), String(buildingId));
      }
    }
  }, []);

  useEffect(() => {
    const newTabParam = searchParams.get('tab') as DeliverableCardTabNamesModel;

    if (newTabParam) {
      if (deliverableCardTabsNames.includes(newTabParam)) {
        setActiveTab(newTabParam);
      } else {
        setActiveTab('deliverable');
      }
    }
  }, [location]);

  useEffect(() => {
    if (sandBoxActiveAlertSnippetActions && sandBoxActiveAlertSnippetActions.deliverable_tab) {
      if (sandBoxActiveAlertSnippetActions.deliverable_tab === 'discussion' && activeTab !== 'discussion') {
        onChangeActiveTab('discussion');
      }

      if (sandBoxActiveAlertSnippetActions.deliverable_tab === 'reviews' && activeTab !== 'review') {
        onChangeActiveTab('review');
      }

      setActiveSandBoxAlertSnippetActions(null);
    }
  }, [sandBoxActiveAlertSnippetActions]);

  useEffect(() => {
    if (requestClickOutSide) {
      if (selectRequestId && canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('pcdClickOutSide') !== 'yes');
        setPromptTitle('Discard changes?');
        setPromptMessage('Leaving this deliverable card will discard any unsaved changes.');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, discard');
        setPromptName('pcdClickOutSide');
        setIsMsgNotShowAgain(false);
        setPromptNameBtnSave(null);

        if (localStorage.getItem('pcdClickOutSide') === 'yes') {
          closePcd(requestClickOutSide);
        }
      } else if (canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('pcdClickOutSide') !== 'yes');
        setPromptTitle('Quit deliverable card creation?');
        setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
        setPromptNameBtnSave('Save draft');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, quit');
        setPromptName('pcdClickOutSide');
        setIsMsgNotShowAgain(false);

        if (localStorage.getItem('pcdClickOutSide') === 'yes') {
          closePcd(requestClickOutSide);
        }
      }
      if (!canUpdateSaveBtn) {
        closePcd(requestClickOutSide);
      }
      closeRequestClickOutSide();
    }
  }, [requestClickOutSide, selectRequestId, canUpdateSaveBtn]);

  useEffect(() => {
    if (escapeKeyDown) {
      closeRequestEscapeKeyDown();
      requestHeadClose();
    }
  }, [escapeKeyDown]);

  useEffect(() => {
    setSelectRequestId(selectRequestIdProps);
    dispatch(getSandBoxCardIDHotList({ related_to: 'user', entity: 'deliverable', check_ids: [selectRequestIdProps] }));
  }, [selectRequestIdProps]);

  useEffect(() => {
    setLogin_user({
      id: +userInfo.id,
      role: userInfo.roles,
    });
  }, [userInfo]);

  useEffect(() => {
    dispatch(projectLoading());
  }, [active_project_id]);

  useEffect(() => {
    if (
      ((!selectedRequest && pcdInfo?.[selectRequestIdProps]) || selectedRequest !== pcdInfo?.[selectRequestId]) &&
      pcdInfo &&
      !socketWatchlistState.changedNow &&
      !socketHotlistState.changedNow
    ) {
      setSelectedRequest(pcdInfo[selectRequestIdProps]);
      setIsCreateRevision(false);
      if (pcdInfo[selectRequestIdProps]?.parent_id) {
        sessionStorage.setItem('isRq', String(pcdInfo[selectRequestIdProps]?.parent_id));
      } else {
        sessionStorage.setItem('isRq', String(pcdInfo[selectRequestIdProps]?.id));
      }
    }
  }, [selectedRequest, pcdInfo]);

  useEffect(() => {
    if (pcdInfo && pcdInfo[selectRequestId] && !pcdInfoLoading && !socketWatchlistState.changedNow && !socketHotlistState.changedNow) {
      setSelectedRequest(pcdInfo[selectRequestId]);

      if (handleSelectedRequest) {
        handleSelectedRequest(requestInfo.data[selectRequestId].id, null, startDate);
      }
    }
  }, [selectRequestId, pcdInfo, pcdInfoLoading]);

  useEffect(() => {
    if (
      originalRequestId &&
      selectRequestId !== originalRequestId &&
      requestInfo?.data &&
      requestInfo?.data[selectRequestId] &&
      singleRequestLoaded &&
      !socketWatchlistState.changedNow &&
      !socketHotlistState.changedNow
    ) {
      setSelectedRequest(requestInfo.data[selectRequestId]);
    }
  }, [originalRequestId, selectRequestId, requestInfo, singleRequestLoaded]);

  useEffect(() => {
    initDataFromProject();
  }, [projectInfo, selectedRequest]);

  useEffect(() => {
    if (breadcrumbs && isEmptyObject(breadcrumbs)) {
      setOriginalRequestId(null);
    }
  }, [breadcrumbs]);

  useEffect(() => {
    if (lastPcdCardId) {
      initDataFromProject();
      getPcdCardPromise.current?.abort();
      getPcdCardPromise.current = dispatch(
        getPCDCard({
          id: lastPcdCardId,
          urlProject,
        }),
      );
      setSelectRequestId(lastPcdCardId);
    }

    return () => {
      getPcdCardPromise.current?.abort();
    };
  }, [lastPcdCardId]);

  useEffect(() => {
    if (check_due_date) {
      if (check_due_date?.submit?.length > 0) {
        setIsSubmit(true);
        setSubmit(check_due_date?.submit);
      }
    }
  }, [check_due_date]);

  useEffect(() => {
    if (!pcdInfoLoading && pcdInfoLoading !== prevRequestLoading) {
      setLoadingFilesName([]);
    }
    setPrevRequestLoading(pcdInfoLoading);
  }, [pcdInfoLoading]);

  const checkIsTopCardChanged = () => {
    const initFields = { ...initialFields };

    delete initFields.currentDescription;
    delete initFields.selectedFiles;

    return Object.keys(initFields).some(fieldKey => {
      const checkingStateValue = fieldKey ? eval(fieldKey) : null;

      return !isEqual(checkingStateValue == '<p><br></p>' ? null : checkingStateValue, initFields[fieldKey]);
    });
  };

  const checkIsDescriptionChanged = () => {
    return !isEqual(currentDescription === '<p><br></p>' ? null : currentDescription, initialFields.currentDescription);
  };

  const requestHeadClose = () => {
    if (!canUpdateSaveBtn) {
      setIsPromptBtnOk(true);
      setIsPromptPopUpClose(false);
      setPromptTitle(null);
      setPromptMessage(null);
      setPromptNameBtnCancel(null);
      setPromptNameBtnOk(null);
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      closePcd();
      returnToDelivarablesModule();

      if (isUrlPCDCardTab && isSeparateTab) {
        setUrlCommandCenter();
      }
    }

    if (selectRequestId && canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('pcdClickOutSide') !== 'yes');
      setPromptTitle('Discard changes?');
      setPromptMessage('Leaving this deliverable card will discard any unsaved changes.');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, discard');
      setPromptName('pcdHeadClose');
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      if (localStorage.getItem('pcdHeadClose') === 'yes') {
        closePcd();
        returnToDelivarablesModule();

        if (isUrlPCDCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    } else if (canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('pcdClickOutSide') !== 'yes');
      setPromptTitle('Quit deliverable card creation?');
      setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
      setPromptNameBtnSave('Save draft');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, quit');
      setPromptName('pcdHeadClose');
      setIsMsgNotShowAgain(false);

      if (localStorage.getItem('pcdHeadClose') === 'yes') {
        closePcd();
        returnToDelivarablesModule();

        if (isUrlPCDCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    }
  };

  const returnToDelivarablesModule = () => {
    if (isUrlPCDCardTab && isSeparateTab) {
      setUrlDeliverables();
    }
  };

  const designDocumentSelector = (): TitleModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const document_sets = projectInfo.document_sets?.[activeProject];
    return document_sets || [];
  };

  const initDataFromProject = () => {
    const documentSets = designDocumentSelector();

    setDocumentSets(documentSets);
  };

  const selectRequestHandler = (id: number) => {
    const isUrlPCDMain = urlProject && urlProject[1] === 'deliverables';

    if (!isUrlPCDMain) {
      dispatch(requestView({ id }));
      dispatch(singleRequestLoading({ requestId: id, unSetNewRequestProject: true }));
    }

    if (handleSelectedRequest) {
      handleSelectedRequest(id);
    }

    dispatch(setRequestToOpen({ id }));
  };

  const closeRequestYesStatus = () => {
    savePromptsAlerts(promptName);

    if (id) {
      selectedFilteredRequestStatus(id, selectedFiltersStatus);
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    } else {
      closePcd();
    }

    setIsPromptBtnOk(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
  };

  const closeRequestNoStatus = () => {
    savePromptsAlerts(promptName);

    if (id) {
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    }

    setIsPromptBtnOk(true);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
  };

  const closeRequestNoStatusOut = () => {
    if (id) {
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    }

    setIsPromptBtnOk(true);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
  };

  const selectedFilteredRequestStatus = (id, selectedFilters) => {
    dispatch(
      getFilteredRequest({
        id,
        urlPageForRequestFilter,
        ...FiltersHelper.getRequestFiltersForApi(selectedFilters, startDate, endDate),
      }),
    );
    handleSelectedRequest && handleSelectedRequest(id, null, startDate);
  };

  const savePromptsAlerts = (promptName: string) => {
    if (localStorage.getItem(promptName) === 'yes') {
      return;
    }
    const promptNameList = JSON.parse(localStorage.getItem('promptNameList')) || {};
    localStorage.setItem(promptName, isMsgNotShowAgain ? 'yes' : 'no');
    promptNameList[promptName] = localStorage.getItem(promptName);

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    dispatch(setPromptsAlerts(JSON.stringify(promptNameList)));
  };

  const changePromptSaveBtn = () => {
    savePromptsAlerts('pcdClickOutSide');

    setIsPromptBtnOk(true);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName('pcdClickOutSide');
  };

  const onChangeActiveTab = (activeTab: DeliverableCardTabNamesModel) => {
    const { pcdId, buildingId } = getPCDParamsFromURL();
    setUrlPCD(pcdId, buildingId, activeTab);
  };

  const handleChangeRevision = (id: number, buildingId?: number) => {
    const updateBreadcrumbs = card => {
      dispatch(
        setNewBreadcrumbs({
          typeBreadcrumbs: 'firstCardBreadcrumbs',
          array: breadcrumbs.map(item => {
            if (item.id === selectedRequest.id) {
              return {
                id: card.id,
                title: `${card.title} (R${card.revision})`,
                breadcrumbType: 'pcd',
              };
            } else {
              return { ...item };
            }
          }),
        }),
      );
    };

    if (id && buildingId) {
      if (!pcdInfoLoading) {
        const tabParam = searchParams.get('tab') as DeliverableCardTabNamesModel;

        setActiveTab(tabParam);
        setUrlPCD(String(id), String(buildingId), tabParam);
        dispatch(
          getPCDCard({
            id: id,
            callback: updateBreadcrumbs,
            urlProject,
          }),
        );
      }
    } else {
      if (!pcdInfoLoading) {
        dispatch(
          getPCDCard({
            id: id,
            callback: updateBreadcrumbs,
            urlProject,
          }),
        );
      }
      setSelectRequestId(id);
    }
  };

  const activateRevisionCreation = parentId => {
    setIsCreateRevision(true);
    setRevisionParentId(parentId);
  };

  const deactivateRevisionCreation = () => {
    setIsCreateRevision(false);
    setRevisionParentId(0);
  };

  const handleOpenReasonsPopUp = (isUpdateReasonsPopUp: boolean) => {
    setIsOpenedReasonsPopUp(true);
    setIsUpdateReasonsPopUp(isUpdateReasonsPopUp);
  };

  const handleCloseReasonsPopUp = () => {
    setIsOpenedReasonsPopUp(false);
    setIsUpdateReasonsPopUp(false);
  };

  const handleDraftGotIt = () => {
    setIsDraft(false);
  };

  const handleSubmitGotIt = () => {
    setIsSubmit(false);
  };

  const handleResizableCard = () => {
    const rw = localStorage.getItem('resizablePCCard') === 'small' ? 'full' : 'small';
    localStorage.setItem('resizablePCCard', rw);
    dispatch(handleResizablePCCardPart(rw));
  };

  const openDeletePopUp = () => {
    setIsDeleteDeliverablePopUpOpen(true);
  };

  const closeDeletePopUp = () => {
    setIsDeleteDeliverablePopUpOpen(false);
  };

  const handleDeleteDeliverable = () => {
    setIsDeleteDeliverableLoading(true);
    //start - change redmine milestone on scheduler deliverable remove
    if (userInfo?.redmine_key && redmineData.assignedMilestone) {
      dispatch(removeRedmineAssignedMilestone({ milestoneId: selectedRequest?.redmine?.[0], rmApiKey: userInfo?.redmine_key }));
    }
    //end
    dispatch(
      deleteDeliverablePCD({
        project_id: selectedRequest.project_id,
        deliverable_id: selectedRequest.id,
        callback: () => {
          setUrlPCD(selectedRequest.revisions.at(-2).id, selectedRequest.revisions.at(-2).building_id, activeTab || 'deliverable');
          dispatch(
            setNewBreadcrumbs({
              typeBreadcrumbs: 'firstCardBreadcrumbs',
              array: breadcrumbs.map(item => {
                if (item.id === selectedRequest.id) {
                  return {
                    id: selectedRequest.revisions.at(-2).id,
                    buildingId: selectedRequest.revisions.at(-2).building_id,
                    title: `${selectedRequest.revisions.at(-2).title} (R${selectedRequest.revisions.at(-2).revision})`,
                    breadcrumbType: 'pcd',
                  };
                } else {
                  return { ...item };
                }
              }),
            }),
          );
          setAnimationBtn('');
          setIsDeleteDeliverableLoading(false);
          closeDeletePopUp();
        },
      }),
    );
  };

  const initFields = () => {
    if (!selectedRequest) {
      return void 0;
    }

    const initialFields = {
      currentDescription: '',
      pcdTitle: selectedRequest?.title || '',
      selectedFiles: selectedRequest?.files || [],
      currentUfcCode: selectedRequest.ufc || null,
      currentMfCode: selectedRequest.mf || null,
      mentions: [],
      selectedDocumentSet: null,
      selectedHashtags: savedHashtags,
      scheduledDate: selectedRequest?.scheduled_date || null,
      criticalPathDate: null,
      selectedResponsibleParty: selectedRequest
        ? {
            company: selectedRequest.responsible_party
              ? {
                  ...selectedRequest.responsible_party,
                  id: selectedRequest.responsible_party.id,
                  title: selectedRequest.responsible_party.company,
                }
              : null,
            discipline: selectedRequest.responsible_disc
              ? {
                  id: selectedRequest.responsible_disc.id,
                  title: selectedRequest.responsible_disc?.title || '',
                  text_color: selectedRequest.responsible_disc?.text_color || '',
                }
              : null,
            user: selectedRequest.responsible_user
              ? {
                  ...selectedRequest.responsible_user,
                  id: selectedRequest.responsible_user.id,
                  title: `${selectedRequest.responsible_user.profile.first_name} ${selectedRequest.responsible_user.profile.last_name}`,
                }
              : null,
          }
        : null,
    };

    setInitialFields(initialFields);
    setCriticalPathDate(initialFields.criticalPathDate);
    setScheduledDate(initialFields.scheduledDate);
    setSelectedDocumentSet(initialFields.selectedDocumentSet);
    setSelectedHashtags(initialFields.selectedHashtags);
    setCurrentUfcCode(initialFields.currentUfcCode);
    setCurrentMfCode(initialFields.currentMfCode);
    setCurrentDescription(initialFields.currentDescription);
    setPcdTitle(initialFields.pcdTitle);
    setSelectedResponsibleParty(initialFields.selectedResponsibleParty);
    setErrors({});
    setIsCurrentDescription(false);
  };

  const initPCDFields = () => {
    if (!selectedRequest) {
      return void 0;
    }

    const initialFields = {
      criticalPathDate: selectedRequest.critical_date || null,
      scheduledDate: selectedRequest.scheduled_date || null,
      selectedFiles: selectedRequest?.files || [],
      selectedDocumentSet: selectedRequest.document_set,
      selectedHashtags: savedHashtags,
      currentUfcCode: selectedRequest.ufc || null,
      currentMfCode: selectedRequest.mf || null,
      currentDescription: selectedRequest.desc,
      mentions: [],
      pcdTitle: selectedRequest.title,
      selectedResponsibleParty: {
        company: selectedRequest.responsible_party
          ? {
              ...selectedRequest.responsible_party,
              id: selectedRequest.responsible_party.id,
              title: selectedRequest.responsible_party.company,
            }
          : null,
        discipline: selectedRequest.responsible_disc
          ? {
              id: selectedRequest.responsible_disc.id,
              title: selectedRequest.responsible_disc?.title || '',
              text_color: selectedRequest.responsible_disc?.text_color || '',
            }
          : null,
        user: selectedRequest.responsible_user
          ? {
              ...selectedRequest.responsible_user,
              id: selectedRequest.responsible_user.id,
              title: `${selectedRequest.responsible_user.profile.first_name} ${selectedRequest.responsible_user.profile.last_name}`,
            }
          : null,
      },
    };

    setInitialFields(initialFields);
    setCriticalPathDate(initialFields.criticalPathDate);
    setScheduledDate(initialFields.scheduledDate);
    setSelectedDocumentSet(initialFields.selectedDocumentSet);
    setSelectedHashtags(initialFields.selectedHashtags);
    setCurrentUfcCode(initialFields.currentUfcCode);
    setCurrentMfCode(initialFields.currentMfCode);
    setCurrentDescription(initialFields.currentDescription);
    setMentions(initialFields.mentions);
    setPcdTitle(initialFields.pcdTitle);
    setSelectedResponsibleParty(initialFields.selectedResponsibleParty);
    setErrors({});
    setIsCurrentDescription(false);
  };

  const collectSaveObject = (whiteboard, files, filesToDelete?: number[], isTopCardUpdate: boolean) => {
    const saveObject = {
      ufc_ids:
        (!currentUfcCode && !isCreateRevision && selectedRequest && selectedRequest.ufc.map(item => item.id)) ||
        currentUfcCode.map(item => item.id) ||
        '',
      mfc_ids:
        (!currentMfCode && !isCreateRevision && selectedRequest && selectedRequest.mf.map(item => item.id)) ||
        currentMfCode.map(item => item.id) ||
        '',
      responsible_party_id: selectedResponsibleParty.company?.id || '',

      responsible_user_id: selectedResponsibleParty.user?.id || '',

      responsible_disc_id: selectedResponsibleParty.discipline?.id || '',

      document_set_id:
        (isCreateRevision ? selectedDocumentSet?.id : selectedDocumentSet?.id || (selectedRequest && selectedRequest.document_set_id)) || 0,

      desc: (isCreateRevision ? currentDescription : currentDescription || (selectedRequest && selectedRequest.desc)) || '',

      title: pcdTitle,

      scheduled_date:
        (isCreateRevision
          ? scheduledDate
          : scheduledDate || (selectedRequest && selectedRequest.scheduled_date && selectedRequest.scheduled_date)) || '',
      is_whiteboard: whiteboard ? 1 : 0,

      hashtags: selectedHashtags
        .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
        .map(hashTag => {
          const tag = {
            name: hashTag.label,
            color: hashTag.color,
            link: hashTag.link,
          };
          return JSON.stringify(tag);
        }),
    } as any;

    if (filesToDelete && !isTopCardUpdate) {
      saveObject['delete_files'] = filesToDelete;
    }

    if (!saveObject.responsible_party_id) {
      delete saveObject.responsible_party_id;
    }

    if (!saveObject.responsible_user_id) {
      delete saveObject.responsible_user_id;
    }

    if (!saveObject.responsible_disc_id) {
      delete saveObject.responsible_disc_id;
    }

    if (files && !isTopCardUpdate) {
      saveObject['upload_files'] = Array.from(files).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    }
    if (selectRequestId) {
      saveObject.is_whiteboard = whiteboard ? 1 : 0;
    }
    if (mentions) {
      saveObject.mentions = mentions.map(mention => mention.email);
    }

    if (saveObject.desc == '<p><br></p>' || isTopCardUpdate) {
      saveObject.desc = '';
    }

    if (submitVisiblePopUp) {
      const ids =
        submitVisiblePopUp == 1
          ? submitDeliverableP1.map(item => item.id)
          : submitVisiblePopUp == 2
          ? submitDeliverableP2.map(item => item.id)
          : submitDeliverableP1.concat(submitDeliverableP2).map(item => item.id);

      saveObject.link_request_warning = ids;
    }

    if (!saveObject.scheduled_date) {
      delete saveObject.scheduled_date;
    }

    if (!saveObject.desc || isTopCardUpdate) {
      delete saveObject.desc;
    }

    if (!saveObject.document_set_id) {
      delete saveObject.document_set_id;
    }

    return saveObject;
  };

  const getSubmitVisible = (requests: RequestModel[]) => {
    const submit_deliverable =
      requests?.length > 0
        ? Array.from(
            new Set(
              requests.map(request => {
                const status = request?.lastStatus;

                const is_incorporated = selectedRequest && !(request.outstanding && request.incorporation !== 1);
                if (request.is_deactivated) {
                  return null;
                }
                if (status === 'sent' || status === 'new') {
                  return 1;
                } else if (status === 'submitted') {
                  return 1;
                } else if (status === 'provided') {
                  return 1;
                } else if (status === 'declined') {
                  return 1;
                } else if (status === 'accepted' && !is_incorporated) {
                  return 2;
                } else if (status === 'drafted') {
                  return 3;
                }
              }),
            ),
          ).filter(f => !!f)
        : [0];

    const isDrafted = submit_deliverable.findIndex(item => item == 3) > -1;
    const isNotVisible = submit_deliverable.length === 0 && requests.length > 0 ? 9 : null;
    const submit_visible = isDrafted ? 3 : submit_deliverable.length > 1 ? 12 : submit_deliverable[0];

    setSubmitVisiblePopUp(Number(submit_visible || isNotVisible));
  };

  const getRequests = (requests: RequestModel[]) => {
    const submit_deliverable_p1 = requests
      .map(request => {
        const status = request?.lastStatus;
        if (request.is_deactivated) {
          return null;
        }
        if (status === 'sent' || status === 'new') {
          return request;
        } else if (status === 'submitted') {
          return request;
        } else if (status === 'provided') {
          return request;
        } else if (status === 'declined') {
          return request;
        } else {
          return null;
        }
      })
      .filter(f => !!f);

    const submit_deliverable_p2 = requests
      .map(request => {
        const status = request?.lastStatus;

        const is_incorporated = selectedRequest && !(request.outstanding && request.incorporation !== 1);
        if (request.is_deactivated) {
          return null;
        }
        if (status === 'accepted' && !is_incorporated) {
          return request;
        } else {
          return null;
        }
      })
      .filter(f => !!f);

    const submit_deliverable_p3 = requests
      .map(request => {
        const status = request?.lastStatus;
        if (request.is_deactivated) {
          return null;
        }
        if (status === 'drafted') {
          return request;
        } else {
          return null;
        }
      })
      .filter(f => !!f);

    setSubmitDeliverableP1(submit_deliverable_p1);
    setSubmitDeliverableP2(submit_deliverable_p2);
    setSubmitDeliverableP3(submit_deliverable_p3);
  };

  const requestSave = (whiteboard: number, files?: FileDataModel[], filesToDelete?: number[], isTopCardUpdate?: boolean) => {
    const saveObject = collectSaveObject(whiteboard, files, filesToDelete, isTopCardUpdate);

    const validationData = {
      ...saveObject,
      files: (selectedRequest && selectedRequest.files) || files,
    };
    const { errors } = validator.validate(isTopCardUpdate ? validatorPatternForTopCard : validatorPattern, validationData);

    if (Object.values(errors).length && saveObject.is_whiteboard && !files) {
      setErrors(errors);
      return;
    }
    if (whiteboard === 1) {
      if (promptName === 'RevisionUpdate') {
        setAnimationBtn('draftBtn');
      } else {
        setAnimationBtn('sendBtn');
      }
    } else {
      setAnimationBtn('draftBtn');
    }

    if (isCreateRevision) {
      dispatch(
        createPCDCard({
          data: { ...saveObject, ...reasonsPopUpData, parent_id: revisionParentId },
          callback: response => {
            deactivateRevisionCreation();
            setAnimationBtn('');
            //start create redmine milestone
            if (userInfo?.redmine_key && redmineData?.assignedMilestone) {
              const milestoneBody = {
                version: {
                  name: `${response.data.owner.position.toString().padStart(3, '0')}. ${response.data.title} (R${response.data.revision})`,
                  due_date: response.data?.scheduled_date ? moment(response.data.scheduled_date).format('YYYY-MM-DD') : null,
                  custom_fields: [
                    {
                      id: 225,
                      name: 'Scheduler Deliverable',
                      value: `"${response.data.title} (R${response.data.revision})":${process.env.DOMAIN_SELF_URL}/${projectData.code}/card?building=${response.data.building_id}&deliverable=${response.data.id}&tab=deliverable`,
                    },
                    {
                      id: 226,
                      name: 'Deliverable status',
                      value: 'Not issued yet',
                    },
                  ],
                },
              };
              dispatch(
                createRedmineMilestone({
                  rmApiKey: userInfo.redmine_key,
                  projectId: redmineData.assignedMilestone.project.id,
                  body: milestoneBody,
                  pcdID: response.data.id,
                }),
              );
            }
            //end create redmine milestone
            setUrlPCD(response.data.id, response.data.building_id, activeTab || 'deliverable');
            dispatch(getPCDCard({ id: response.data.id }));
            dispatch(
              setNewBreadcrumbs({
                typeBreadcrumbs: 'firstCardBreadcrumbs',
                array: breadcrumbs.map(item => {
                  if (item.id === selectedRequest.id) {
                    return {
                      ...item,
                      id: response.data.id,
                      title: `${response.data.title} (R${response.data.revision})`,
                    };
                  } else {
                    return { ...item };
                  }
                }),
              }),
            );
          },
        }),
      );
    } else {
      if (whiteboard === 1 && userInfo?.redmine_key && redmineData?.assignedMilestone) {
        dispatch(issuedRedmineMilestone({ milestoneId: redmineData.assignedMilestone.id, rmApiKey: userInfo.redmine_key }));
      }
      dispatch(
        updatePCDCard({
          deliverable_id: selectRequestId,
          deliverable_parent_id: selectedRequest?.parent_id,
          data: saveObject,
          callback: () => {
            deactivateRevisionCreation();
            setAnimationBtn('');
            dispatch(
              setNewBreadcrumbs({
                typeBreadcrumbs: 'firstCardBreadcrumbs',
                array: breadcrumbs.map(item => {
                  if (item.id === selectedRequest.id) {
                    return {
                      ...item,
                      title: `${saveObject.title} (R${selectedRequest.revision})`,
                    };
                  } else {
                    return { ...item };
                  }
                }),
              }),
            );
          },
        }),
      );
    }
  };

  const requestSaveRevisionUpdate = (is_clear: boolean) => {
    const saveObject = collectSaveObject(1, null, null);
    saveObject.is_clear = is_clear ? 1 : 0;
    setAnimationBtn('draftBtn');
    dispatch(
      updatePCDCard({
        deliverable_id: selectRequestId,
        deliverable_parent_id: selectedRequest?.parent_id,
        data: saveObject,
        callback: () => {
          deactivateRevisionCreation();
          setAnimationBtn('');
          closePromptRevisionUpdate();
          dispatch(
            setNewBreadcrumbs({
              typeBreadcrumbs: 'firstCardBreadcrumbs',
              array: breadcrumbs.map(item => {
                if (item.id === selectedRequest.id) {
                  return {
                    ...item,
                    title: `${saveObject.title} (R${selectedRequest.revision})`,
                  };
                } else {
                  return { ...item };
                }
              }),
            }),
          );
        },
      }),
    );
  };

  const requestSavePrompt = (whiteboard: number, files?) => {
    if (localStorage.getItem('RevisionSubmit') === 'yes' && submitVisiblePopUp === 0) {
      requestSave(1);
    } else {
      const saveObject = collectSaveObject(whiteboard, files);
      const validationData = {
        ...saveObject,
        files: (selectedRequest && selectedRequest.files) || files,
      };
      const { errors } = validator.validate(validatorPattern, validationData);

      if (Object.values(errors).length && saveObject.is_whiteboard) {
        setErrors(errors);
        return;
      }

      if (submitVisiblePopUp === 9) {
        requestSave(1);
      } else {
        setIsPromptPopUpPull(!(localStorage.getItem('RequestSendPCD') === 'yes' && submitVisiblePopUp === 0));

        if (localStorage.getItem('RequestSendPCD') === 'yes' && submitVisiblePopUp === 0) {
          requestSave(1);
        }
      }
    }
  };

  const changeMentions = (newMentions: { id: number; email: string }[]) => {
    setMentions(newMentions);
  };

  const setDescription = (descriptionValue: string, delta?: any, source?: any, editor?: any) => {
    if (pcdInfoLoading) {
      return;
    }

    if (!isCurrentDescription && currentDescription) {
      setIsCurrentDescription(true);
      setCurrentDescription(descriptionValue);
      return;
    }

    setCurrentDescription(descriptionValue);
  };

  const handleMoreLimitFiles = files => {
    setMoreLimitFiles(files);
  };

  const handleFile = (e, isDrag?, isRestrict?) => {
    if (!isCurrentRevision || pcdInfoLoading) {
      return;
    }

    let filesEl;
    if (isDrag) {
      if (isRestrict) {
        return;
      }
      filesEl = e;
    } else {
      filesEl = e.target.files;
    }

    setAnimationBtn(null);

    const files: any = Array.from(filesEl).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    const moreLimitFiles: any = Array.from(filesEl).filter((f: any) => f.size > FILE_SIZE_LIMIT);

    files.map(f => (f.isNew = true));
    if (files?.length > 0) {
      requestSave(!isCreateRevision ? selectedRequest?.is_whiteboard || 0 : 0, filesEl);

      setSelectedFiles([...(selectedFiles || []), ...files]);
      setLoadingFilesName(
        Array.from(filesEl)
          .filter((f: any) => f.size < FILE_SIZE_LIMIT)
          .map(el => el.name),
      );
    }
    if (moreLimitFiles?.length > 0) {
      handleMoreLimitFiles(moreLimitFiles);
    }

    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.desc;
      delete updatedErrors.files;
      return updatedErrors;
    });
  };

  const delSavedFile = async (file_id, isBulk?) => {
    const filteredSelectedFiles = isBulk
      ? selectedFiles.filter(item => file_id.includes(item.id))
      : selectedFiles.filter(item => item.id !== file_id);

    if (filteredSelectedFiles?.length) {
      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors.desc;
        delete updatedErrors.files;
        return updatedErrors;
      });
    }
    setAnimationBtn(null);

    requestSave(selectedRequest?.is_whiteboard || 0, null, isBulk ? file_id : [file_id]);
  };

  const handleDownloadAll = (e: MouseEvent) => {
    const downloadAllFiles = [...(selectedFiles || [])];

    const { project_id } = getNfParamsFromURL();

    e.preventDefault();
    downloadAllFiles.map((file, i) => {
      setTimeout(async () => {
        const fileName = `${project_id}${selectedRequest?.nf ? `_nf#${selectedRequest.nf}` : ''}_req_${file.title}`;
        await BaseHelper.downloadFile(file.url, fileName);

        if (!selectedRequest.nf) {
          dispatch(pcdHistoryRecordSend({ type: 'downloaded', deliverableId: selectedRequest.id, fileId: file.id }));
        }
      }, i * 50);
    });
  };

  const changePromptBtn = () => {
    savePromptsAlerts(promptName);

    setIsPromptBtnOk(true);
    setIsPromptPopUpView(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
  };

  const closePromptStatusOut = () => {
    setIsPromptPopUpPull(false);
    setIsMsgNotShowAgain(false);
  };

  const cancelPromptStatus = () => {
    savePromptsAlerts('RequestSendPCD');
    closePromptStatusOut();
  };

  const cancelPopUpView = () => {
    savePromptsAlerts(promptName);
    closePopUpView();
  };

  const closePopUpView = () => {
    setIsPromptBtnOk(false);
    setIsPromptPopUpView(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
  };

  const openDraftPromptPopUp = () => {
    setIsPromptPopUpDraft(true);
  };

  const closeDraftPromptStatusOut = () => {
    setIsPromptPopUpDraft(false);
    setIsMsgNotShowAgain(false);
  };

  const toggleShared = () => {
    setIsMsgNotShowAgain(!isMsgNotShowAgain);
  };

  const okDraftPromptStatus = () => {
    const parentRevisionId = selectedRequest?.revisions[0]?.id;

    activateRevisionCreation(parentRevisionId);
    savePromptsAlerts('DraftRevision');
    handleCloseReasonsPopUp();
    closeDraftPromptStatusOut();
  };

  const okPromptStatus = () => {
    savePromptsAlerts('RequestSendPCD');
    closePromptStatusOut();
    requestSave(1);
  };

  const getUserStatusName = request => {
    const status = request?.lastStatus;
    const statusName = status === 'declined' ? 'Request re-opened' : StatusNameHelper.getLastUserStatusName(request);
    return statusName;
  };

  const handleOpenNf = request => e => {
    e.stopPropagation();
    e.preventDefault();

    setOpenedNfId(request.id);
    setOpenedNfNumber(request.nf);
  };

  const closeNf = () => {
    setOpenedNfId(null);
    setOpenedNfNumber(null);
  };

  const handleSaveRevisionUpdate = (is_clear: boolean) => {
    requestSaveRevisionUpdate(is_clear);
  };

  const handlePCDCardData = (nameBtn: SubmitButtonActions) => {
    if (nameBtn === 'RevisionCreate') {
      handleOpenReasonsPopUp(false);
    }

    if (nameBtn === 'RevisionSubmit') {
      requestSavePrompt(1);
    }

    if (nameBtn === 'RevisionUpdate') {
      setIsRevisionUpdate(true);
    }

    if (nameBtn === 'DraftRevision') {
      if (!isCreateRevision) {
        return requestSave(0);
      }
    }
  };

  const handleOpenAuthorUser = (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => {
    const target = e.currentTarget;
    timer = setTimeout(() => {
      setIsOpenAuthorUser(true);
      setCoordinatesAuthor(target);
      setSelectedAuthor(user);
    }, 500);
  };

  const handleCloseAuthorUser = () => {
    if (timer && !isOpenAuthorUser) {
      clearTimeout(timer);
      return;
    }
    if (timer) {
      return;
    }
    handleCloseAuthorUserFromProfile();
  };

  const handleCloseAuthorUserFromProfile = () => {
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
    setSelectedAuthor(null);
  };

  const viewProfile = () => {
    setIsViewProfile(true);
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const handleContentSwitcher = () => {
    setIsHiddenFields(!isHiddenFields);
  };

  const handleChangeHashtags = (hashtags: TagFormModel[]) => {
    setSelectedHashtags(hashtags);
  };

  const renderListOfItemsToOpen = (items: RequestModel[], showStatusOfNf?: boolean) => {
    return (
      <div className={`${themeClass}_promptModalContent_itemsToOpenList`}>
        {(items?.length > 0 &&
          items?.map(request => {
            return (
              <div className={`${themeClass}_promptModalContent_itemsToOpenList_item`} key={`itemToOpen-${request.nf}`}>
                <a target={'_blank'} href={returnUrlNfCardTab(request)} rel="noreferrer">
                  <CardLinkButton type={'nf'} size={'md'} title={`NF ${request?.nf}`} />
                </a>
                {showStatusOfNf ? (
                  <span className={`${themeClass}_promptModalContent_itemsToOpenList_item_text`}>({getUserStatusName(request)})&nbsp;</span>
                ) : null}
              </div>
            );
          })) ||
          ''}
      </div>
    );
  };

  const renderPopUpPullContent = () => {
    let renderContent;

    if (submitVisiblePopUp === 0) {
      renderContent = (
        <>
          <div className={`${themeClass}_promptModalContent_messageItem`}>Revision will be issued and will become public.</div>
          <div className={`${themeClass}_promptModalContent_checkboxItem`}>
            <CheckBox checked={isMsgNotShowAgain} onClick={toggleShared} />
            <span className={`${themeClass}_promptModalContent_checkboxItem_text`}>Do not show such messages again</span>
          </div>
        </>
      );
    }

    if (submitVisiblePopUp === 1) {
      renderContent = (
        <>
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>There are&nbsp;</span>
            <span className={`${themeClass}_promptModalContent_primaryText`}>open NFs&nbsp;</span>
            <span>linked to this deliverable card:</span>
          </div>
          {renderListOfItemsToOpen(submitDeliverableP1, true)}
          <div className={`${themeClass}_promptModalContent_messageItem`}>Do you want to issue the deliverable anyway?</div>
        </>
      );
    }

    if (submitVisiblePopUp === 2) {
      renderContent = (
        <>
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>There are accepted NFs linked to this deliverable card that&nbsp;</span>
            <span className={`${themeClass}_promptModalContent_primaryText`}>have not been incorporated&nbsp;</span>
            <span>on the associated levels:</span>
          </div>
          {renderListOfItemsToOpen(submitDeliverableP2, false)}
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>You will need to verify their impact on this Deliverable.</span>
          </div>
          <div className={`${themeClass}_promptModalContent_messageItem`}>Do you want to issue the deliverable anyway?</div>
        </>
      );
    }

    if (submitVisiblePopUp === 3) {
      renderContent = (
        <>
          <div className={`${themeClass}_promptModalContent_messageItem`}>There are draft NFs linked to this deliverable card:</div>
          {renderListOfItemsToOpen(submitDeliverableP3, false)}
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>Please send them or unlink in order to proceed.</span>
          </div>
        </>
      );
    }

    if (submitVisiblePopUp === 12) {
      renderContent = (
        <>
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>There are&nbsp;</span>
            <span className={`${themeClass}_promptModalContent_primaryText`}>open NFs&nbsp;</span>
            <span>linked to this deliverable card:</span>
          </div>
          {renderListOfItemsToOpen(submitDeliverableP1, true)}
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>There are accepted NFs linked to this deliverable card that&nbsp;</span>
            <span className={`${themeClass}_promptModalContent_primaryText`}>have not been incorporated&nbsp;</span>
            <span>on the associated levels:</span>
          </div>
          {renderListOfItemsToOpen(submitDeliverableP2, false)}
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>You will need to verify their impact on this Deliverable.</span>
          </div>
          <div className={`${themeClass}_promptModalContent_messageItem`}>
            <span>Do you want to issue the deliverable anyway?</span>
          </div>
        </>
      );
    }

    return <div className={`${themeClass}_promptModalContent`}>{renderContent}</div>;
  };

  const renderPopUpDraftContent = () => {
    return (
      <div className={`${themeClass}_promptModalContent`}>
        <div className={`${themeClass}_promptModalContent_messageItem`}>
          Draft of the next revision will be saved. You will be able to remove it, unless issued.
        </div>
        <div className={`${themeClass}_promptModalContent_checkboxItem`}>
          <CheckBox checked={isMsgNotShowAgain} onClick={toggleShared} />
          <span className={`${themeClass}_promptModalContent_checkboxItem_text`}>Do not show such messages again</span>
        </div>
      </div>
    );
  };

  const renderPopUpRevisionUpdate = () => {
    return (
      <div className={`${themeClass}_promptModalContent`}>
        <div className={`${themeClass}_promptModalContent_messageItem`}>
          All involved parties will be notified about changes you have made.
        </div>
        <div>
          <span className={`${themeClass}_promptModalContent_messageItem -no-inline-block`}>Do you want to&nbsp;</span>
          <span className={`${themeClass}_promptModalContent_primaryText -no-inline-block`}>update the deliverable card only&nbsp;</span>
          <span className={`${themeClass}_promptModalContent_messageItem -no-inline-block`}>
            ? If changes you apply are significant, you may additionally want to&nbsp;
          </span>
          <span className={`${themeClass}_promptModalContent_primaryText -no-inline-block`}>clear the Reviewers statuses&nbsp;</span>
          <span className={`${themeClass}_promptModalContent_messageItem -no-inline-block`}>
            and update the list of open NFs linked to the deliverable when submitted.
          </span>
        </div>
      </div>
    );
  };

  const handleScheduledDate = (reason: string, scheduledDate: string | Date) => {
    const saveObject = collectSaveObject(selectedRequest?.is_whiteboard, null, null);
    saveObject.scheduled_date = scheduledDate;
    saveObject.reason = reason;
    dispatch(
      updatePCDCard({
        deliverable_id: selectRequestId,
        deliverable_parent_id: selectedRequest?.parent_id,
        data: saveObject,
      }),
    );
  };

  const renderPopUpPromptContent = () => {
    return (
      <div className={`${themeClass}_promptModalContent`}>
        <div className={`${themeClass}_promptModalContent_messageItem`}>{promptMessage}</div>
        <div className={`${themeClass}_promptModalContent_checkboxItem`}>
          <CheckBox checked={isMsgNotShowAgain} onClick={toggleShared} />
          <span className={`${themeClass}_promptModalContent_checkboxItem_text`}>Do not show such messages again</span>
        </div>
      </div>
    );
  };

  const closePromptRevisionUpdate = () => {
    setIsRevisionUpdate(false);
  };

  const promptPopUpPullTitle = useMemo(() => {
    if (submitVisiblePopUp === 0) {
      return 'Issue this revision?';
    }

    if (submitVisiblePopUp === 3) {
      return 'You can’t issue the deliverable!';
    }

    return 'Issue the deliverable?';
  }, [submitVisiblePopUp]);

  const isSidePeekView = isDragRequestCard || is1440Resolution;

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-centralPeek']: !isSidePeekView && !isSeparateTab && !isMobile,
          ['-sidePeek']: isSidePeekView,
          ['-isSeparateTab']: isSeparateTab,
          ['-deliverableTab']: activeTab === 'deliverable',
        })}
      >
        <Header
          isDragRequestCard={isDragRequestCard}
          handleChangeCardView={handleResizableCard}
          selectedRequest={selectedRequest}
          requestHeadClose={requestHeadClose}
          breadcrumbs={breadcrumbs}
          selectRequestHandler={selectRequestHandler}
          isSeparateTab={isSeparateTab}
          isCreateRevision={isCreateRevision}
          handleChangeRevision={handleChangeRevision}
          openDeletePopup={openDeletePopUp}
          login_user={login_user}
          onChangeActiveTab={onChangeActiveTab}
          pcdWatchers={pcdWatchers}
          pcdWatchersGroups={pcdWatchersGroups}
        />
        <div className={`${themeClass}_bodyContainer`}>
          <TopCardPartPcd
            isDragRequestCard={isDragRequestCard}
            selectedRequest={selectedRequest}
            isPCDFieldsDisabled={isPCDFieldsDisabled}
            isAccess={isAccessToEditPCDCard}
            documentSets={documentSets}
            isCreateRevision={isCreateRevision}
            work_week={projectData.work_week || 7}
            criticalPathDate={criticalPathDate}
            scheduledDate={scheduledDate}
            setScheduledDate={setScheduledDate}
            selectedDocumentSet={selectedDocumentSet}
            setSelectedDocumentSet={setSelectedDocumentSet}
            currentUfcCode={currentUfcCode}
            setCurrentUfcCode={setCurrentUfcCode}
            currentMfCode={currentMfCode}
            setCurrentMfCode={setCurrentMfCode}
            pcdTitle={pcdTitle}
            setPcdTitle={setPcdTitle}
            selectedResponsibleParty={selectedResponsibleParty}
            setSelectedResponsibleParty={setSelectedResponsibleParty}
            errors={errors}
            isHiddenFields={isHiddenFields}
            handleContentSwitcher={handleContentSwitcher}
            hashtags={selectedHashtags}
            handleChangeHashtags={handleChangeHashtags}
            handleScheduledDate={handleScheduledDate}
            requestSave={requestSave}
            isRevisionUpdateDraftButtonDisabledTopCard={isRevisionUpdateDraftButtonDisabledTopCard}
            canUpdateDescription={canUpdateDescription}
          />
          <TabsContainer
            isDragRequestCard={isDragRequestCard}
            feedData={feedData}
            relatedTabData={relatedTabData}
            openedRequest={selectedRequest || null}
            activeTab={activeTab}
            onChangeActiveTab={onChangeActiveTab}
            isDisable={false}
          />
          <BottomCardPart
            selectedRequest={selectedRequest}
            currentDescription={currentDescription}
            setDescription={setDescription}
            isPCDFieldsDisabled={isPCDFieldsDisabled}
            requestLoading={pcdInfoLoading}
            isAccessToEditPCDCard={isAccessToEditPCDCard}
            activeTab={activeTab}
            isDragRequestCard={isDragRequestCard}
            handleOpenReasonsPopUp={handleOpenReasonsPopUp}
            isCreateRevision={isCreateRevision}
            errors={errors}
            animationBtn={animationBtn}
            handleDownloadAll={handleDownloadAll}
            selectedFiles={selectedFiles}
            loadingFilesName={loadingFilesName}
            handleFile={handleFile}
            delSavedFile={delSavedFile}
            requestSave={requestSave}
            requestSavePrompt={requestSavePrompt}
            changeMentions={changeMentions}
            closePromptStatusOut={closePromptStatusOut}
            submitVisiblePopUp={submitVisiblePopUp}
            submitDeliverableP1={submitDeliverableP1}
            submitDeliverableP2={submitDeliverableP2}
            submitDeliverableP3={submitDeliverableP3}
            isMsgNotShowAgain={isMsgNotShowAgain}
            toggleShared={toggleShared}
            cancelPromptStatus={cancelPromptStatus}
            closeDraftPromptStatusOut={closeDraftPromptStatusOut}
            isPromptPopUpDraft={isPromptPopUpDraft}
            isCreateSubmitButtonDisabled={isCreateSubmitButtonDisabled}
            isRevisionUpdateDraftButtonDisabled={isRevisionUpdateDraftButtonDisabled}
            isRevisionSubmitted={isRevisionSubmitted}
            handlePCDCardData={handlePCDCardData}
            handleOpenAuthorUser={handleOpenAuthorUser}
            onChangeActiveTab={onChangeActiveTab}
            handleCloseAuthorUser={handleCloseAuthorUser}
            isNextRevisionDrafted={isNextRevisionDrafted}
            nextRevisionObject={nextRevisionObject}
            isHiddenFields={isHiddenFields}
            isSeparateTab={isSeparateTab}
            moreLimitFiles={moreLimitFiles}
            handleMoreLimitFiles={handleMoreLimitFiles}
          />
        </div>
      </div>
      <Reasons
        open={isOpenedReasonsPopUp}
        onClose={handleCloseReasonsPopUp}
        selectedRequest={selectedRequest}
        setReasonsPopUpData={setReasonsPopUpData}
        openDraftPromptPopUp={openDraftPromptPopUp}
        isReadOnly={!!nextRevisionObject && !isNextRevisionDrafted}
        isUpdateReasonsPopUp={isUpdateReasonsPopUp}
      />
      {isOpenAuthorUser && (
        <PopupProfile
          anchorEl={coordinatesAuthor}
          user={selectedAuthor}
          viewProfile={viewProfile}
          closeTeamUser={handleCloseAuthorUserFromProfile}
          closeFixedTooltip={handleCloseAuthorUserFromProfile}
        />
      )}
      {isViewProfile && (
        <ProfilePopover
          open={isViewProfile}
          onClose={viewProfileClose}
          type={isAdmin ? 'edit' : 'view'}
          projectId={selectedRequest?.project_id}
          user_id={selectedAuthor.id}
        />
      )}
      <CardRequestGlobal
        isSeparateTab={isSeparateTab}
        openRequestSubId={openedNfId}
        openRequestSubNf={openedNfNumber}
        requestCloseHandler={closeNf}
      />
      <Modal
        open={isDeleteDeliverablePopUpOpen}
        onClose={closeDeletePopUp}
        title={'Remove this revision?'}
        modalText={"The data included in this card will be lost. You won't be able to recover it."}
        primaryText={'Yes, remove'}
        tertiaryText={'Close'}
        customSecondaryType={'tertiary'}
        handleOnPrimary={handleDeleteDeliverable}
        handleOnTertiary={closeDeletePopUp}
        loadingOnPrimary={isDeleteDeliverableLoading}
        isCard
      />
      <Modal
        open={isPromptPopUpPull}
        onClose={closePromptStatusOut}
        title={promptPopUpPullTitle}
        renderModalContent={() => renderPopUpPullContent()}
        primaryText={submitVisiblePopUp !== 3 ? 'Issue anyway' : ''}
        tertiaryText={'Keep editing'}
        customSecondaryType={'tertiary'}
        handleOnPrimary={okPromptStatus}
        handleOnTertiary={cancelPromptStatus}
        isCard
        customClassname={`${themeClass}_issueDeliverable`}
      />
      <Modal
        open={isPromptPopUpDraft}
        onClose={closeDraftPromptStatusOut}
        title={'Save draft of the next revision?'}
        renderModalContent={() => renderPopUpDraftContent()}
        primaryText={'Yes, save'}
        tertiaryText={'Keep editing'}
        customSecondaryType={'tertiary'}
        handleOnPrimary={okDraftPromptStatus}
        handleOnTertiary={closeDraftPromptStatusOut}
        isCard
      />
      <Modal
        open={isRevisionUpdate}
        onClose={closePromptRevisionUpdate}
        title={'Update this Revision?'}
        renderModalContent={() => renderPopUpRevisionUpdate()}
        primaryText={'Update only'}
        secondaryText={'Update and clear'}
        tertiaryText={'Keep editing'}
        customSecondaryType={'text-plain'}
        customTertiaryType={'tertiary'}
        handleOnPrimary={() => handleSaveRevisionUpdate(false)}
        handleOnSecondary={() => handleSaveRevisionUpdate(true)}
        handleOnTertiary={closePromptRevisionUpdate}
        isCard
      />
      <Modal
        open={isPromptPopUpView}
        onClose={closePopUpView}
        title={promptTitle}
        renderModalContent={() => renderPopUpPromptContent()}
        primaryText={promptNameBtnOk}
        tertiaryText={promptNameBtnCancel}
        customSecondaryType={'tertiary'}
        handleOnPrimary={changePromptBtn}
        handleOnTertiary={cancelPopUpView}
        isCard
      />
      <Modal
        open={isPromptPopUpClose}
        onClose={closeRequestNoStatusOut}
        title={promptTitle}
        renderModalContent={() => renderPopUpPromptContent()}
        primaryText={promptNameBtnOk}
        secondaryText={promptNameBtnSave}
        tertiaryText={promptNameBtnCancel}
        customSecondaryType={promptNameBtnSave ? 'text-plain' : 'tertiary'}
        handleOnPrimary={closeRequestYesStatus}
        handleOnTertiary={closeRequestNoStatus}
        handleOnSecondary={changePromptSaveBtn}
        isCard
      />
      <Modal
        open={isDraft}
        onClose={handleDraftGotIt}
        title={'NF due date changed'}
        primaryText={'Got it'}
        dividedHeader
        handleOnPrimary={handleDraftGotIt}
        isCard
        renderModalContent={() => (
          <div className={`${themeClass}_draftDialog`}>
            <p className={`${themeClass}_draftDialog_text`}>
              Due dates of the following draft NFs were changed to allow resolving them 2 days before the Scheduled date of this
              Deliverable:
            </p>
            <div className={`${themeClass}_draftDialog_column`}>
              {isDraft &&
                !!draft &&
                draft.map(item => {
                  return (
                    <div className={`${themeClass}_draftDialog_item`} key={item.request_id}>
                      <a onClick={handleOpenNf(item)} href={returnUrlNfCardTab(item)}>
                        <CardLinkButton type={'nf'} size={'md'} title={`NF ${item?.nf}`} />
                      </a>
                      <div className={`${themeClass}_draftDialog_itemData`}>
                        <div className={`${themeClass}_draftDialog_date -from`}>{item.date_from}</div>
                        <div className={`${themeClass}_draftDialog_icon`}>
                          <TokenIcon iconName={'arrow-right'} size={isMobile ? 20 : 16} />
                        </div>
                        <div className={`${themeClass}_draftDialog_date -to`}>{item.date_to}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      />
      <Modal
        open={isSubmit}
        onClose={handleSubmitGotIt}
        title={'NF due date alert'}
        primaryText={'Got it'}
        dividedHeader
        handleOnPrimary={handleSubmitGotIt}
        isCard
        renderModalContent={() => (
          <div className={`${themeClass}_draftDialog`}>
            <p className={`${themeClass}_draftDialog_text`}>
              Due dates of the following NFs are later than 2 days before the Scheduled date of this Deliverable. We recommend changing them
              as follows:
            </p>
            <div className={`${themeClass}_draftDialog_column`}>
              {isSubmit &&
                !!submit &&
                submit.map(item => {
                  return (
                    <div className={`${themeClass}_draftDialog_item`} key={item.request_id}>
                      <div className={`${themeClass}_draftDialog_text`}>NF#{item.nf}:</div>
                      <div className={`${themeClass}_draftDialog_itemData`}>
                        <div className={`${themeClass}_draftDialog_date -from`}>{item.date_from}</div>
                        <div className={`${themeClass}_draftDialog_icon`}>
                          <TokenIcon iconName={'arrow-right'} size={16} />
                        </div>
                        <div className={`${themeClass}_draftDialog_date -to`}>{item.date_to}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      />
    </>
  );
}

export default withConfirmDialog<IProps>(withConfirmDialogContext(CardPcd));
