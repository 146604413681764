import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { getRequestByNf } from '../../../store/request/requestLogic(HOLD)';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import { pcdSlice } from '../../../store/pcd/pcdSlice';
import Draggable from 'react-draggable';
import { useGetThemeClass } from '../../../helpers/designTokens';
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import CardRequest from '../CardRequest/CardRequest';
import { RequestModel } from '../../../models';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { commentSlice } from '../../../store/comments/commentsReducer';
import { incorporationSlice } from '../../../store/incorporation/incorporationSlice';
import cn from 'classnames';
import { DeliverableCardTabNamesModel } from '../CardPcd/constans/constants';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';
import useRouter from '../../../hooks/useRouter/useRouter';

import './CardRequestGlobalStyles.scss';

const { setRequestToOpen, closeNfCard, closeNewNfCard } = requestSlice.actions;
const { clearBreadcrumbs } = breadcrumbsSlice.actions;
const { closePcdCard } = pcdSlice.actions;
const { clearFeed } = commentSlice.actions;
const { clearIncorporation } = incorporationSlice.actions;

interface IProps {
  isUrlNfCardTab?: boolean;
  isUrlPCDCardTab?: boolean;
  isSeparateTab?: boolean;
  openRequestSubId?: number;
  openRequestSubNf?: number;
  requestOpenHandler?: (linkCardId: number, requestNf: number, title: string) => void;
  requestCloseHandler?: () => void;
  pullRequestOpen?: boolean;
  predecessorIdProps?: number | null;
  parentSelectedRequest?: RequestModel;
  isPullRequestOpenByAction?: boolean;
}

type CardTypes = 'global' | 'sub' | 'pull-request';

function CardRequestGlobal({
  isUrlNfCardTab,
  isSeparateTab,
  openRequestSubId,
  openRequestSubNf,
  requestOpenHandler,
  requestCloseHandler,
  pullRequestOpen,
  predecessorIdProps,
  parentSelectedRequest,
  isPullRequestOpenByAction = false,
}: IProps) {
  const dispatch = useAppDispatch();
  const cartRef = useRef();
  const { setUrlNF, getNfParamsFromURL } = useRoutingHelper();
  const { location, searchParams } = useRouter();

  const themeClass = useGetThemeClass('b-globalCardRequest');
  const { is1440Resolution } = GetResolution();

  const requestId = useAppSelector(state => state.requestReducer.requestId);
  const idLoadedRequestByNf = useAppSelector(state => state.requestReducer.idLoadedRequestByNf);
  const requestInfo = useAppSelector(state => state.requestReducer.requestInfo);
  const requestToOpenId = useAppSelector(state => state.requestReducer.requestToOpenId);
  const nfCardId = useAppSelector(state => state.requestReducer.nfCardId);
  const isNewNfCard = useAppSelector(state => state.requestReducer.isNewNfCard);
  const resizableCardPart = useAppSelector(state => state.commonReducer.resizableCardPart);
  const pcdCardIsOpen = useAppSelector(state => state.pcdReducer.pcdCardIsOpen);
  const lastPcdCardId = useAppSelector(state => state.pcdReducer.lastPcdCardId);
  const breadcrumbs = useAppSelector(state => state.breadcrumbsReducer.firstCardBreadcrumbs);

  const [requestIsOpen, setRequestIsOpen] = useState<boolean | number>(false);
  const [requestClickOutSide, setRequestClickOutSide] = useState<boolean>(false);
  const [escapeKeyDown, setEscapeKeyDown] = useState<boolean>(false);
  const [isVisibleNf, setIsVisibleNf] = useState(false);
  const [prevRequestId, setPrevRequestId] = useState(null);

  useEffect(() => {
    const params = getNfParamsFromURL();
    if (params?.nf) {
      if (!pullRequestOpen) {
        dispatch(getRequestByNf({ projectId: params.project_id, nf: params.nf }));
      }
    }

    if (localStorage.getItem('resizableCard') === 'small') {
      document.addEventListener('keydown', upHandler);
    }

    return () => {
      if (localStorage.getItem('resizableCard') === 'small') {
        document.removeEventListener('keydown', upHandler);
      }
    };
  }, []);

  useEffect(() => {
    const newNfParam = searchParams.get('nf');

    if (newNfParam) {
      const params = getNfParamsFromURL();
      if (!pullRequestOpen) {
        dispatch(getRequestByNf({ projectId: params.project_id, nf: params.nf }));
      }
    } else {
      setRequestIsOpen(null);
    }
  }, [location]);

  useEffect(() => {
    const newNfParam = searchParams.get('nf');
    const buildingId = searchParams.get('building') as DeliverableCardTabNamesModel;
    const pcdId = searchParams.get('deliverable') as DeliverableCardTabNamesModel;
    const isPCDCard = buildingId && pcdId;
    const isSubmittalCard = !!searchParams.get('submittal');

    if (newNfParam === null && !!requestIsOpen && !isNewNfCard) {
      setRequestIsOpen(null);
      dispatch(closeNfCard());
      if (!isUrlNeedList && !isPCDCard && !isSubmittalCard) {
        dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
      }
    }
  }, [location, requestIsOpen, isNewNfCard]);

  useEffect(() => {
    if (requestIsOpen && requestId && requestId !== prevRequestId) {
      const selectedRequest: any = Object.values(requestInfo?.data ? requestInfo.data : {}).find((r: any) => r.id === requestId);
      const params = getNfParamsFromURL();
      setUrlNF(selectedRequest.nf, params?.nfTab || 'request');
      setRequestIsOpen(requestId);
      dispatch(closeNewNfCard());
    }
    setPrevRequestId(requestId);
  }, [requestIsOpen, requestId]);

  useEffect(() => {
    if (idLoadedRequestByNf) {
      setRequestIsOpen(idLoadedRequestByNf);
    }
  }, [idLoadedRequestByNf]);

  useEffect(() => {
    const hideSecondSmallNf = localStorage.getItem('hideSecondSmallNf') === '1';

    if (requestToOpenId && (localStorage.getItem('resizableCard') === 'full' || hideSecondSmallNf)) {
      setRequestIsOpen(requestToOpenId);
      dispatch(setRequestToOpen(null));
    }
  }, [requestToOpenId]);

  useEffect(() => {
    if (isNewNfCard) {
      if (requestIsOpen) {
        closeRequestClickOutSide();
      } else {
        setRequestIsOpen(isNewNfCard);
      }
    }
  }, [isNewNfCard]);

  useEffect(() => {
    if (nfCardId) {
      if (!isVisibleNf) {
        setIsVisibleNf(true);
        setRequestIsOpen(nfCardId);
      } else {
        if (requestIsOpen) {
          isVisibleNf && closeRequestClickOutSide();
        } else {
          setRequestIsOpen(nfCardId);
        }
      }
    }
  }, [nfCardId]);

  useEffect(() => {
    if (requestIsOpen) {
      const url = location.pathname.split('/').filter(f => !!f);

      if (url[1] !== 'deliverables' || isNewNfCard) {
        setIsVisibleNf(true);
      }
    }
  }, [requestIsOpen]);

  useEffect(() => {
    if (pcdCardIsOpen || lastPcdCardId) {
      if (pcdCardIsOpen) {
        setIsVisibleNf(!breadcrumbs.length ? isVisibleNf : false);
      } else {
        setIsVisibleNf(true);
        !breadcrumbs.length && closeRequest();
      }
    }
  }, [pcdCardIsOpen, lastPcdCardId, breadcrumbs]);

  useEffect(() => {
    if (resizableCardPart) {
      if (localStorage.getItem('resizableCard') === 'small') {
        document.addEventListener('keydown', upHandler);
      } else {
        document.removeEventListener('keydown', upHandler);
      }
    }
  }, [resizableCardPart]);

  const upHandler = ({ key }) => {
    if (key === 'Escape') {
      closeRequestEscapeKeyDown();
    }
  };

  const closeRequest = () => {
    const urlProject = location.pathname.split('/').filter(f => !!f);
    if (!requestIsOpen && isNewNfCard) {
      setRequestIsOpen(true);
    }
    dispatch(closeNfCard());
    localStorage.setItem('hideGlobalPCDCard', '0');
    const isUrlPCDMainS = urlProject && urlProject[1] === 'deliverables';
    localStorage.getItem('resizableCard') === 'small' && isUrlPCDMainS && dispatch(closePcdCard());

    dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
    dispatch(clearFeed());
    dispatch(clearIncorporation());
    localStorage.setItem('hideSecondSmallNf', '0');
  };

  const closeRequestClickOutSide = () => {
    if (!is_rw) {
      setRequestClickOutSide(true);
    }
  };

  const closeRequestClickOutSideR = () => {
    if (!is_rw) {
      setRequestClickOutSide(false);
    }
  };

  const closeRequestEscapeKeyDown = () => {
    setEscapeKeyDown(true);
  };
  const closeRequestEscapeKeyDownR = () => {
    setEscapeKeyDown(false);
  };

  const is_rw = localStorage.getItem('resizableCard') === 'small' && !isSeparateTab;
  const urlProject = location.pathname.split('/').filter(f => !!f);
  const isUrlNeedList = urlProject && urlProject[1] === 'needs-list';

  return (
    <>
      {pullRequestOpen && !isSeparateTab && (localStorage.getItem('resizableCard') === 'small' || is1440Resolution) && (
        <Draggable disabled={true} position={{ x: 0, y: 0 }} onDrag={null}>
          <div className={`${themeClass}_sidePeek`} ref={cartRef}>
            <CardRequest
              isPullRequest={true}
              selectRequestId={null}
              selectRequestNf={null}
              closeRequest={requestCloseHandler}
              requestClickOutSide={requestClickOutSide}
              closeRequestClickOutSide={closeRequestClickOutSideR}
              escapeKeyDown={escapeKeyDown}
              closeRequestEscapeKeyDown={closeRequestEscapeKeyDownR}
              isDragRequestCard={is_rw || is1440Resolution}
              predecessorIdProps={predecessorIdProps}
              parentSelectedRequest={parentSelectedRequest}
              isPullRequestOpenByAction={isPullRequestOpenByAction}
            />
          </div>
        </Draggable>
      )}
      {pullRequestOpen && ((!is1440Resolution && localStorage.getItem('resizableCard') === 'full') || isSeparateTab) && (
        <DialogPopUp
          open={!!pullRequestOpen}
          aria-labelledby="Pull Request Card"
          disableBackdropClick
          disableEnforceFocus
          hideBackdrop={false}
          classes={{
            paper: cn(`${themeClass}_paper`, {
              ['-isSeparateTabSub']: isSeparateTab,
            }),
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              closeRequestEscapeKeyDown();
            }
          }}
          onBackdropClick={closeRequestClickOutSide}
          onEscapeKeyDown={closeRequestEscapeKeyDown}
        >
          <CardRequest
            isPullRequest={true}
            selectRequestId={null}
            selectRequestNf={null}
            closeRequest={requestCloseHandler}
            requestClickOutSide={requestClickOutSide}
            closeRequestClickOutSide={closeRequestClickOutSideR}
            escapeKeyDown={escapeKeyDown}
            closeRequestEscapeKeyDown={closeRequestEscapeKeyDownR}
            predecessorIdProps={predecessorIdProps}
            parentSelectedRequest={parentSelectedRequest}
            isPullRequestOpenByAction={isPullRequestOpenByAction}
            isDragRequestCard={is_rw || is1440Resolution}
          />
        </DialogPopUp>
      )}
    </>
  );
}

export default CardRequestGlobal;
