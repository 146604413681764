import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import GeneralTab from '../../tabs/General/GeneralTab';
import { ISubmittal, ISubmittalUpdateModel, SubmittalUserModel } from '../../../../../models/submittals/submittals.model';
import { SubmittalCardTabNamesModel } from '../../constants/submittalTabs';
import { FILE_SIZE_LIMIT } from '../../../../../service/file';
import BaseHelper from '../../../../../helpers/BaseHelper';
import CommentsContainer from '../../../Comments/CommentsContainer';
import {
  FileModel,
  LocationCollectionModel,
  MfViewDataModel,
  RequestBuildingModel,
  RequestTypeModel,
  RequestUserModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
} from '../../../../../models';
import RelatedItemsTab from '../../../RelatedItemsTab/RelatedItemsTab';
import SegmentedButton from '../../../../controls/ButtonComponents/SegmentedButton/SegmentedButton';
import { RelatedItemsTabsType } from '../../../CardRequest/mainParts/BottomCardPart/BottomCardPart';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import ReviewTab from '../../tabs/Review/Review';
import { CustomButton } from '../../../../controls/ButtonComponents';
import { ImpactModel } from '../../../../controls/ImpactSelect/ImpactSelect';
import Divider from '../../../../controls/Divider/Divider';
import HiddenRelations from '../../../RelatedItemsTab/controls/HiddenRelations/HiddenRelations';
import { handleOpenCardHelper } from '../../../../../helpers/OpenCardWithBreadcrumbs/OpenCardWithBreadcrumbs';
import { HiddenRelationModelSubmittal } from '../../../../../store/relatedItems/relatedItemsSlice.model';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';
import GeneralButtons from '../../tabs/General/GeneralButtons';

import './BottomCardPartStyles.scss';
import { RouterHelperComponentProps, withRouterHelper } from '../../../../../navigation/withRouterHelper/withRouterHelper';

interface BottomCardPartProps extends RouterHelperComponentProps {
  activeTab: SubmittalCardTabNamesModel;
  isDragRequestCard?: boolean;
  selectedRequest: ISubmittal;
  submittalLoading: boolean;
  isAccess: boolean;
  currentDescription: string;
  files: FileModel[] | FileList | any;
  setDescription: (descriptionValue: string, delta: any, source: any, editor: any) => void;
  isHiddenFields: boolean;
  onChangeActiveTab: (activeTab: SubmittalCardTabNamesModel) => void;
  handleShowConfirmDialog?: (options) => void;
  bodyContainerRef?: MutableRefObject<HTMLDivElement>;
  onSubmittalSave: (submittalId: number, bodyParams: ISubmittalUpdateModel, callback?: () => void, isNotFormData?: boolean) => void;
  saveSubmittal: (isSubmit: boolean) => void;
  isCreateSubmittal: boolean;
  isSeparateTab: boolean;
  validateCard: (isSubmit: boolean) => any;
  isAccessToEditCard: boolean;
  isDeactivated: boolean;
  onCreateNewRevision: () => void;
  mf_selected: MfViewDataModel;
  tags_selected: RequestTypeModel;
  handleOpenAuthorUser: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, user: RequestUserModel | SubmittalUserModel) => void;
  handleCloseAuthorUser: () => void;
  title_selected: string;
  submit_due_date_selected: string;
  required_due_date_selected: string;
  responsible_contractor_selected: SelectedRequestCompanyModel;
  responsible_manager_selected: SelectedRequestCompanyModel;
  buildings_selected: RequestBuildingModel[];
  locations_selected: LocationCollectionModel[];
  typicalLayouts_selected: any[];
  building_spaces_selected: SimpleTitleModel[];
  hashtags_selected: TagFormModel[];
  private_selected: 0 | 1;
  handleCloseAndDistribute: () => void;
  cardErrors: any;
  isPreviousRevisionDeactivated: boolean;
  setCardErrors: (errors: any) => void;
  isAnyReturnedReviews: boolean;
  clImpactsSelected: ImpactModel[];
  isUpdateDraftButtonDisabled: boolean;
}

export type Mention = {
  id: number;
  email: string;
};

const workingOptionsInput = [
  {
    id: 'request',
    title: 'Need Forms',
  },
  {
    id: 'submittalInput',
    title: 'Input submittals',
    statusType: 'new',
  },
  {
    id: 'submittalOutput',
    title: 'Output submittals',
    statusType: 'new',
  },
];

const workingOptionsOutput = [
  {
    id: 'request',
    title: 'Need Forms',
  },
  {
    id: 'deliverable',
    title: 'CL drawings',
  },
  {
    id: 'submittalOutput',
    title: 'Output submittals',
    statusType: 'new',
  },
];

const BottomCardPart: React.FC<BottomCardPartProps> = ({
  activeTab,
  isDragRequestCard,
  selectedRequest,
  submittalLoading,
  isAccess,
  currentDescription,
  setDescription,
  files,
  isHiddenFields,
  onSubmittalSave,
  saveSubmittal,
  isCreateSubmittal,
  onChangeActiveTab,
  handleShowConfirmDialog,
  bodyContainerRef,
  isSeparateTab,
  validateCard,
  isAccessToEditCard,
  isDeactivated,
  onCreateNewRevision,
  tags_selected,
  mf_selected,
  handleCloseAuthorUser,
  handleOpenAuthorUser,
  building_spaces_selected,
  hashtags_selected,
  buildings_selected,
  locations_selected,
  responsible_contractor_selected,
  required_due_date_selected,
  submit_due_date_selected,
  responsible_manager_selected,
  typicalLayouts_selected,
  title_selected,
  private_selected,
  handleCloseAndDistribute,
  cardErrors,
  isPreviousRevisionDeactivated,
  setCardErrors,
  isAnyReturnedReviews,
  clImpactsSelected,
  isUpdateDraftButtonDisabled,
  routerHelper,
}) => {
  const dispatch = useAppDispatch();
  const themeClass = useGetThemeClass('b-cardSubmittal-bottomCardPart');
  const reviewThemeClass = useGetThemeClass('b-cardSubmittal-reviewTab');
  const { is1440Resolution, isMobile } = GetResolution();
  const { getNfParamsFromURL } = useRoutingHelper();

  const { isCardSaveLoading } = useAppSelector(state => state.submittalSlice);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);

  // general states
  const [generalFiles, setGeneralFiles] = useState<FileModel[] | FileList | any>([]);
  const [animationLoading, setAnimationLoading] = useState<boolean>(false);
  const [loadingFilesName, setLoadingFilesName] = useState<string[]>([]);
  const [selectedRelatedItems, setSelectedRelatedItems] = useState<RelatedItemsTabsType>('request');
  const [prevSubmittalLoading, setPrevSubmittalLoading] = useState(false);
  const [moreLimitFiles, setMoreLimitFiles] = useState<FileModel[] | FileList | any>([]);

  const isSidePeekView = isDragRequestCard || is1440Resolution;

  useEffect(() => {
    if (!isCardSaveLoading.loading) {
      setGeneralFiles(files);
    }
  }, [files, isCardSaveLoading]);

  useEffect(() => {
    if (!isCardSaveLoading.loading && isCardSaveLoading.loading !== prevSubmittalLoading) {
      setAnimationLoading(false);
      setLoadingFilesName([]);
    }
    setPrevSubmittalLoading(isCardSaveLoading.loading);
  }, [isCardSaveLoading, prevSubmittalLoading]);

  const handleFile = (e, isDrag?: boolean, isRestrict?: boolean, name?: string) => {
    if (submittalLoading) {
      return;
    }
    let filesEl;
    if (isDrag) {
      if (isRestrict) {
        return;
      }
      filesEl = e;
    } else {
      filesEl = e.target.files;
    }
    if (!filesEl?.length) {
      return;
    }
    const filteredFiles: any = Array.from(filesEl).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    const moreLimitFiles: any = Array.from(filesEl).filter((f: any) => f.size > FILE_SIZE_LIMIT);
    filteredFiles.forEach(f => (f.isNew = true));

    if (filteredFiles?.length > 0) {
      setAnimationLoading(true);
      setLoadingFilesName(
        Array.from(filesEl)
          .filter((f: any) => f.size < FILE_SIZE_LIMIT)
          .map(el => el.name || el.title),
      );

      submittalSave(filesEl);
    }
    if (moreLimitFiles?.length > 0) {
      handleMoreLimitFiles(moreLimitFiles);
    }
  };

  const setSubmittalDescription = (descriptionValue: string, delta: any, source: any, editor: any) => {
    if (submittalLoading) {
      return;
    }

    if (descriptionValue !== '<p><br></p>') {
      const updatedErrors = { ...cardErrors };
      delete updatedErrors?.description;
      delete updatedErrors?.files;
      setCardErrors(updatedErrors);
    }

    setDescription(descriptionValue, delta, source, editor);
  };

  const handleDownloadAll = e => {
    const { project_id } = getNfParamsFromURL();
    e.preventDefault();
    generalFiles.map((file, i) => {
      setTimeout(async () => {
        const fileName = `${project_id}${selectedRequest?.id ? `_submittal#${selectedRequest.id}` : ''}_general_${file.title}`;
        await BaseHelper.downloadFile(file.file, fileName);
      }, i * 50);
    });
  };

  const delSavedFile = async (file_id, isBulk?) => {
    submittalSave(null, isBulk ? file_id : [file_id]);
  };

  const collectSaveObject = (files, filesToDelete?: number[]) => {
    const saveObject: ISubmittalUpdateModel = {
      mf_id: mf_selected?.id,
      type_id: tags_selected?.id,
      description: currentDescription || '',
      status: selectedRequest?.status?.alias || 'draft',
      title: title_selected || '',
      submit_due_date: submit_due_date_selected,
      required_due_date: required_due_date_selected,
      contractor_discipline_id: responsible_contractor_selected?.discipline?.id,
      contractor_party_id: responsible_contractor_selected?.company?.id,
      contractor_user_id: responsible_contractor_selected?.user?.id,
      responsible_discipline_id: responsible_manager_selected?.discipline?.id,
      responsible_party_id: responsible_manager_selected?.company?.id,
      responsible_user_id: responsible_manager_selected?.user?.id,
      buildings: buildings_selected.map(building => building.id) || [],
      locations: locations_selected.map(location => location.id) || [],
      typicalLayouts: typicalLayouts_selected.map(typical => typical.id) || [],
      buildingSpaces: building_spaces_selected.map(space => space.id) || [],
      hashtags: hashtags_selected
        .filter(hashTag => hashTag.is_auto === 0 || hashTag.is_auto === undefined)
        .map(hashTag => {
          const tag = {
            name: hashTag.label,
            color: hashTag.color,
            link: hashTag.link,
          };
          return JSON.stringify(tag);
        }),
    };

    if (clImpactsSelected.length) {
      const impactsObj = {};

      clImpactsSelected.forEach(item => {
        impactsObj[item.alias] = item.status;
      });

      saveObject.cl_impacts = JSON.stringify(impactsObj);
      delete saveObject.reset_cl_impacts;
    } else {
      saveObject.reset_cl_impacts = 1;
      delete saveObject.cl_impacts;
    }

    if (filesToDelete) {
      saveObject['delete_files'] = filesToDelete;
    }

    if (files) {
      saveObject['upload_files'] = Array.from(files).filter((f: any) => f.size < FILE_SIZE_LIMIT);
    }

    if (saveObject.description == '<p><br></p>') {
      saveObject.description = '';
    }

    if (!saveObject.description) {
      delete saveObject.description;
    }

    if (!saveObject.title) {
      delete saveObject.title;
    }

    if (!saveObject.responsible_party_id) {
      delete saveObject.responsible_party_id;
    }

    if (!saveObject.responsible_user_id) {
      delete saveObject.responsible_user_id;
    }

    if (!saveObject.responsible_discipline_id) {
      delete saveObject.responsible_discipline_id;
    }

    if (!saveObject.contractor_party_id) {
      delete saveObject.contractor_party_id;
    }

    if (!saveObject.contractor_user_id) {
      delete saveObject.contractor_user_id;
    }

    if (!saveObject.contractor_discipline_id) {
      delete saveObject.contractor_discipline_id;
    }

    if (!saveObject.type_id) {
      delete saveObject.type_id;
    }

    if (!saveObject.submit_due_date) {
      delete saveObject.submit_due_date;
    }

    if (!saveObject.required_due_date) {
      delete saveObject.required_due_date;
    }

    if (isCreateSubmittal) {
      saveObject.private = private_selected;
    }

    return saveObject;
  };

  const submittalSave = (files?, filesToDelete?: number[]) => {
    const saveObject = collectSaveObject(files, filesToDelete);

    onSubmittalSave(selectedRequest?.id, { ...saveObject }, null, false);
    setGeneralFiles([...(selectedRequest?.files || []), ...(files || [])].filter(file => !filesToDelete?.includes(file.id)));
  };

  const renderCloseAndDistribute = () => {
    if (!isDragRequestCard || selectedRequest?.status.alias !== 'open') return null;
    if (
      ((selectedRequest?.review_state === 'approved' && isAnyReturnedReviews) || selectedRequest?.review_state !== 'approved') &&
      activeTab !== 'review'
    )
      return null;

    return (
      <CustomButton
        type={selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews ? 'primary' : 'tertiary'}
        size={'md'}
        title={'Close & Distribute'}
        clickHandler={handleCloseAndDistribute}
        loading={submittalLoading}
        disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
      />
    );
  };

  const handleRelatedItems = (id: string) => {
    setSelectedRelatedItems(id as RelatedItemsTabsType);
  };

  const handleMoreLimitFiles = files => {
    setMoreLimitFiles(files);
  };

  const showFooterHiddenRelations = useMemo(() => {
    if (isDeactivated) {
      return false;
    }

    const hiddenRelations: HiddenRelationModelSubmittal = relatedTabData.card?.hidden_relation || null;

    if (selectedRelatedItems === 'request') {
      return (
        hiddenRelations?.requests_predecessor_unlink?.length ||
        hiddenRelations?.requests_predecessor_deactivated?.length ||
        hiddenRelations?.requests_successor_unlink?.length ||
        hiddenRelations?.requests_successor_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'deliverable') {
      return (
        hiddenRelations?.deliverables_predecessor_unlink?.length ||
        hiddenRelations?.deliverables_predecessor_deactivated?.length ||
        hiddenRelations?.deliverables_successor_unlink?.length ||
        hiddenRelations?.deliverables_successor_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'submittalInput') {
      return (
        hiddenRelations?.predecessors_unlink?.length ||
        hiddenRelations?.successors_unlink?.length ||
        hiddenRelations?.predecessors_deactivated?.length ||
        hiddenRelations?.successors_deactivated?.length
      );
    }

    if (selectedRelatedItems === 'submittalOutput') {
      if (selectedRequest?.is_output) {
        return (
          hiddenRelations?.predecessors_unlink?.length ||
          hiddenRelations?.successors_unlink?.length ||
          hiddenRelations?.predecessors_deactivated?.length ||
          hiddenRelations?.successors_deactivated?.length
        );
      }
      return hiddenRelations?.successors_output_unlink?.length || hiddenRelations?.successors_output_deactivated?.length;
    }
  }, [relatedTabData.card?.hidden_relation, selectedRelatedItems]);

  const submittalFooterHiddenRelations = {
    request: [
      { requests_predecessor_unlink: 'Unlinked predecessors:' },
      { requests_predecessor_deactivated: 'Deactivated predecessors:' },
      { requests_successor_unlink: 'Unlinked successors:' },
      { requests_successor_deactivated: 'Deactivated successors:' },
    ],
    deliverable: [
      { deliverables_predecessor_unlink: 'Unlinked predecessors:' },
      { deliverables_predecessor_deactivated: 'Deactivated predecessors:' },
      { deliverables_successor_unlink: 'Unlinked successors:' },
      { deliverables_successor_deactivated: 'Deactivated successors:' },
    ],
    submittalInput: [
      { predecessors_unlink: 'Unlinked predecessors:' },
      { predecessors_deactivated: 'Deactivated predecessors:' },
      { successors_unlink: 'Unlinked successors:' },
      { successors_deactivated: 'Deactivated successors:' },
    ],
    submittalOutput: selectedRequest?.is_output
      ? [
          { predecessors_unlink: 'Unlinked predecessors:' },
          { predecessors_deactivated: 'Deactivated predecessors:' },
          { successors_unlink: 'Unlinked successors:' },
          { successors_deactivated: 'Deactivated successors:' },
        ]
      : [{ successors_output_unlink: 'Unlinked successors:' }, { successors_output_deactivated: 'Deactivated successors:' }],
  };

  const openCard = card => {
    handleOpenCardHelper(true, 'Submittal', selectedRequest, card, dispatch, routerHelper, 'firstCardBreadcrumbs');
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          ['-sidePeek']: isSidePeekView,
          ['-isHiddenFields']: isHiddenFields,
        })}
      >
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'general' })}>
          <GeneralTab
            selectedRequest={selectedRequest}
            submittalLoading={submittalLoading}
            isAccess={isAccess}
            isDragRequestCard={isSidePeekView}
            activeTab={activeTab}
            currentDescription={currentDescription || (selectedRequest && selectedRequest.description) || ''}
            setDescription={setSubmittalDescription}
            files={generalFiles}
            loadingFilesName={loadingFilesName}
            handleDownloadAll={handleDownloadAll}
            delSavedFile={delSavedFile}
            handleFile={handleFile}
            isHiddenFields={isHiddenFields}
            animationLoading={animationLoading}
            saveSubmittal={saveSubmittal}
            isCreateSubmittal={isCreateSubmittal}
            validateCard={validateCard}
            isDeactivated={isDeactivated}
            onCreateNewRevision={onCreateNewRevision}
            handleCloseAuthorUser={handleCloseAuthorUser}
            handleOpenAuthorUser={handleOpenAuthorUser}
            cardErrors={cardErrors}
            isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
            moreLimitFiles={moreLimitFiles}
            handleMoreLimitFiles={handleMoreLimitFiles}
            isAnyReturnedReviews={isAnyReturnedReviews}
            handleCloseAndDistribute={handleCloseAndDistribute}
            isUpdateDraftButtonDisabled={isUpdateDraftButtonDisabled}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent -relatedItems`, { ['-active']: activeTab === 'related-items' })}>
          <SegmentedButton
            selectedButtonId={selectedRelatedItems}
            buttons={selectedRequest?.is_output ? workingOptionsOutput : workingOptionsInput}
            changeHandler={handleRelatedItems}
          />
          <RelatedItemsTab
            isDragRequestCard={isSidePeekView}
            selectedRequest={selectedRequest}
            isSeparateTab={isSeparateTab}
            relatedTabType={selectedRelatedItems}
            currentCard={'Submittal'}
            isCreateRequest={isCreateSubmittal}
            isDeactivated={isDeactivated}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'review' })}>
          <ReviewTab
            selectedRequest={selectedRequest}
            isAccess={isAccessToEditCard}
            isDragRequestCard={isSidePeekView}
            isDeactivated={isDeactivated}
            onCreateNewRevision={onCreateNewRevision}
            isCreateSubmittal={isCreateSubmittal}
            isHiddenFields={isHiddenFields}
            handleCloseAndDistribute={handleCloseAndDistribute}
            isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
            isAnyReturnedReviews={isAnyReturnedReviews}
          />
        </div>
        <div className={cn(`${themeClass}_tabComponent`, { ['-active']: activeTab === 'discussion' })}>
          <CommentsContainer
            selectedRequest={selectedRequest}
            type={'submittal'}
            onChangeActiveTab={onChangeActiveTab}
            isDragCard={isSidePeekView}
            handleShowConfirmDialog={handleShowConfirmDialog}
            activeTab={activeTab}
            bodyContainerRef={bodyContainerRef}
          />
        </div>
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'general' })}>
        <GeneralButtons
          selectedRequest={selectedRequest}
          isDragRequestCard={isDragRequestCard}
          submittalLoading={submittalLoading}
          saveSubmittal={saveSubmittal}
          onCreateNewRevision={onCreateNewRevision}
          isAnyReturnedReviews={isAnyReturnedReviews}
          handleCloseAndDistribute={handleCloseAndDistribute}
          isUpdateDraftButtonDisabled={isUpdateDraftButtonDisabled}
          isCreateSubmittal={isCreateSubmittal}
          activeTab={activeTab}
          isDeactivated={isDeactivated}
          isPreviousRevisionDeactivated={isPreviousRevisionDeactivated}
        />
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'related-items' })}>
        {selectedRequest?.status?.alias !== 'draft' && !isCreateSubmittal && (
          <div className={`${themeClass}_footer`}>
            <Divider direction={'horizontal'} type={'srf-5'} />
            <div className={`${themeClass}_footer_block`}>
              <div className={`${themeClass}_footer_hiddenRelationsBlock`}>
                {showFooterHiddenRelations &&
                  submittalFooterHiddenRelations[selectedRelatedItems].map((obj, index) => {
                    const name = Object.keys(obj)[0];
                    return (
                      <HiddenRelations
                        key={`${selectedRelatedItems}-${name}`}
                        type={selectedRelatedItems}
                        label={obj[name]}
                        propertyListName={name}
                        hiddenRelations={relatedTabData?.card?.hidden_relation || null}
                        handleOpenCard={openCard}
                        isMobile={isMobile}
                        isDragRequestCard={isSidePeekView}
                      />
                    );
                  })}
              </div>
              <div className={`${themeClass}_related_footer`}>
                {renderCloseAndDistribute()}
                {!selectedRequest?.parent_id && (
                  <CustomButton
                    type={
                      selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews && selectedRequest.status.alias === 'open'
                        ? 'secondary'
                        : 'primary'
                    }
                    size={'md'}
                    clickHandler={onCreateNewRevision}
                    title={'Create new revision'}
                    customClassName={`${themeClass}_related_button`}
                    customTooltipBasisClassName={`${themeClass}_related_button_tooltipBasis`}
                    loading={submittalLoading}
                    disabled={submittalLoading || isDeactivated}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={cn(`${themeClass}_buttonsSticky`, { ['-active']: activeTab === 'review' })}>
        <div className={`${reviewThemeClass}_footer`}>
          {selectedRequest?.status.alias === 'open' && isDragRequestCard && (
            <CustomButton
              type={selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews ? 'primary' : 'tertiary'}
              size={'md'}
              title={'Close & Distribute'}
              loading={submittalLoading}
              disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
              clickHandler={handleCloseAndDistribute}
            />
          )}
          {selectedRequest?.status?.alias !== 'draft' && !isCreateSubmittal && !selectedRequest?.parent_id && (
            <CustomButton
              type={
                selectedRequest?.review_state === 'approved' && !isAnyReturnedReviews && selectedRequest.status.alias === 'open'
                  ? 'secondary'
                  : 'primary'
              }
              size={'md'}
              clickHandler={onCreateNewRevision}
              title={'Create new revision'}
              customClassName={`${reviewThemeClass}_buttonsGroup_button`}
              customTooltipBasisClassName={`${reviewThemeClass}_buttonsGroup_tooltipBasis`}
              isMobile={isMobile}
              loading={submittalLoading}
              disabled={submittalLoading || isDeactivated || isPreviousRevisionDeactivated}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouterHelper(BottomCardPart);
