import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import Calendar from 'react-calendar';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import SelectField from '../../../CreateProject/parts/SelectField/SelectField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { permitColumns, scheduleColumns } from '../../../../../constants/phasePlanColumns';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import { ColumnToolTip } from '../../../AsideHolder/AsideHolder';
import { ScrollSyncPane } from 'react-scroll-sync';
import SVG from 'react-inlinesvg';

import asideHolderStyles from '../../../AsideHolder/AsideHolderStyles.scss';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  height: '22px',
  backgroundColor: 'var(--background-sheet)',
  background: 'var(--background-sheet)',
  borderBottom: 'var(--background-border)',
  zIndex: 0,

  ...(isDragging && {
    background: 'var(--background-sheet)',
    backgroundColor: 'var(--background-sheet)',
  }),
});

class TablePlanView extends React.Component {
  constructor(props) {
    super(props);
    this.permitInputs = {};
    this.scheduleInputs = {};
    this.numberInputs = {};
  }

  render() {
    const {
      isOpenCollapse,
      handleChangeValue,
      schedules,
      permissions,
      handleChangeNumber,
      handleOpenCalendar,
      anchorEl,
      indexOpenedRowCalendar,
      handleCloseCalendar,
      selectAtDateHandler,
      selectAtDateHandlerSingle,
      editableTableRef,
      idOpenedColumnCalendar,
      handleRemoveRow,
      removeRow,
      handleCloseRemoving,
      handleRemoveMilestone,
      handleAddMilestone,
      calendarOpenedTable,
      phasePlanSelect,
      handleChangeSelect,
      tableRef,
      editMode,
      onDragEnd,
      errors,
      getMaxCalendarDate,
      getMinCalendarDate,
      coordinationDriverMode,
      selectRowForDriver,
      driverTasks,
      handleCollapse,
      isExistScrollX,
      handleExcelNav,
      selectedField,
      handleDblClick,
      handleChangeDate,
      handleOpenListColor,
      handleCloseListColor,
      anchorListColor,
      handleSelectColor,
      phasePlanFieldStatus,
      handleCloseSelect,
    } = this.props;

    const isExistScroll = editableTableRef.current?.scrollWidth > editableTableRef.current?.clientWidth;
    let valueOpenedCalendar = null;

    if (calendarOpenedTable === 'schedules') {
      valueOpenedCalendar = schedules?.[indexOpenedRowCalendar]?.[idOpenedColumnCalendar.id]
        ? new Date(schedules[indexOpenedRowCalendar][idOpenedColumnCalendar.id])
        : new Date();
    }

    if (calendarOpenedTable === 'permissions') {
      valueOpenedCalendar = permissions?.[indexOpenedRowCalendar]?.[idOpenedColumnCalendar.id]
        ? new Date(permissions[indexOpenedRowCalendar][idOpenedColumnCalendar.id])
        : new Date();
    }

    let maxScheduleLengthText = 0;

    // Object.values(this.permitInputs).map(input =>  {
    //   if (input) {
    //     maxScheduleLengthText < +input.scrollWidth ? maxScheduleLengthText = +input.scrollWidth : null;
    //   }
    // });
    Object.values(this.scheduleInputs).map(input => {
      if (input) {
        maxScheduleLengthText < +input.scrollWidth ? (maxScheduleLengthText = +input.scrollWidth) : null;
      }
    });

    const columns = isOpenCollapse
      ? scheduleColumns
          .map(f => {
            if (f.id === 'title' || f.id === 'edit' || f.id === 'construction_end' || f.id === 'construction_start' || f.id === 'ufc_id') {
              return f;
            }
          })
          .filter(f => !!f)
      : scheduleColumns;

    const permitColumnsFiltered = isOpenCollapse
      ? permitColumns
          .map(f => {
            if (f.id === 'title' || f.id === 'edit' || f.id === 'approval_date' || f.id === 'submission_date') {
              return f;
            }
          })
          .filter(f => !!f)
      : permitColumns;
    return (
      <div ref={tableRef} className={`b-phasePlan__table ${isOpenCollapse ? 'b-phasePlan__tableOpen' : 'b-phasePlan__tableClose'}`}>
        <ScrollSyncPane>
          <div
            className={`EditableTable ${isExistScroll ? 'EditableTable__scroll' : ''}`}
            style={{
              overflowX: 'scroll',
              // overflowX: isExistScrollX ? 'scroll' : 'hidden',
            }}
            ref={editableTableRef}
            // onMouseEnter={this.onMouseEnter}
            // onScroll={this.onScroll}
          >
            <div className={'b-phasePlan__wrappedTables'}>
              <Table
                aria-label="sticky table"
                style={{ height: schedules?.length ? schedules.length * 23 + 61 : 'auto', position: 'relative', zIndex: 2 }}
              >
                <TableHead className={'b-phasePlan__tableHead'}>
                  <TableRow style={{ height: '61px' }} className={`b-phasePlan__rowHead b-phasePlan__rowHeadHidingField`}>
                    {columns.map((column, index) => {
                      if (index === 1) {
                        return (
                          <TableCell
                            key={column.id}
                            style={{ backgroundColor: column.color, width: maxScheduleLengthText }}
                            className={`mui-table__head`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                      if (column.type === 'select') {
                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              backgroundColor: column.color,
                              width: '40px',
                            }}
                            className={`mui-table__head`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                      if (column.type === 'edit' && editMode) {
                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              backgroundColor: column.color,
                              width: column.type === 'edit' ? '44px' : '',
                            }}
                            className={`mui-table__head`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      } else if (column.type !== 'edit') {
                        return (
                          <TableCell
                            key={column.id}
                            style={{ backgroundColor: column.color }}
                            className={`mui-table__head ${column.type === 'edit' ? 'b-phasePlan__stickEdit' : ''}`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={'schedules'} direction="vertical">
                    {provided => {
                      return (
                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                          {schedules.length
                            ? schedules.map((row, rowIndex) => {
                                return (
                                  <Draggable
                                    key={`phasePlanScheduleD&D-${rowIndex}`}
                                    draggableId={`id-${rowIndex}`}
                                    index={rowIndex}
                                    isDragDisabled={row.isMainBlock || !editMode}
                                  >
                                    {(provided, snapshot, e) => {
                                      let isDriverTask = null;
                                      if (coordinationDriverMode) {
                                        isDriverTask = driverTasks.findIndex(d => d.id === row.id) + 1;
                                      }
                                      return (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          className={`b-phasePlan__rowBody
                                  ${editMode ? 'b-phasePlan__editRow' : ''}
                                  ${selectedField?.row === rowIndex ? 'b-phasePlan__rowSelected' : ''}`}
                                          onClick={e => selectRowForDriver(row, rowIndex, e)}
                                        >
                                          {columns.map((column, i) => {
                                            const value = row[column.id];
                                            const isCurrentTable = selectedField?.table === 'schedules';
                                            const isEditField =
                                              isCurrentTable &&
                                              selectedField.editField &&
                                              selectedField?.row === rowIndex &&
                                              selectedField?.column === i;
                                            const isDisabled =
                                              !coordinationDriverMode &&
                                              (!isCurrentTable ||
                                                !editMode ||
                                                row.isMainBlock ||
                                                !(
                                                  selectedField.editField &&
                                                  selectedField?.row === rowIndex &&
                                                  selectedField?.column === i
                                                ));
                                            const cellClass = `${editMode ? 'b-phasePlan__fieldReadonly' : ''}
                                                     ${
                                                       isCurrentTable && selectedField?.row === rowIndex && selectedField?.column === i
                                                         ? 'b-phasePlan__fieldSelected'
                                                         : ''
                                                     }
                                                     ${isCurrentTable && isEditField ? 'b-phasePlan__fieldEdited' : ''}`;
                                            if (column.type === 'input') {
                                              let tooltip = '';
                                              const isPossibleForDriverSelect =
                                                row.lod350_start &&
                                                driverTasks?.[0]?.lod350_start &&
                                                moment(row.lod350_start).diff(driverTasks?.[0]?.lod350_start, 'days') >= 0;
                                              if (errors?.[`schedules.${row.position}.${column.id}`]?.[0]) {
                                                tooltip = errors?.[`schedules.${row.position}.${column.id}`][0];
                                              }
                                              if (isDriverTask === 1 && column.id === 'title') {
                                                tooltip = 'Driver';
                                              }
                                              if (isDriverTask !== 1 && isPossibleForDriverSelect) {
                                                tooltip = 'Driven';
                                              }
                                              if (driverTasks.length && !isPossibleForDriverSelect) {
                                                tooltip = `Driven tasks can't have earlier coordination start date then Driver task.`;
                                              }
                                              if (row.isMainBlock) {
                                                tooltip = ``;
                                              }
                                              if (isEditField) {
                                                setTimeout(() => {
                                                  this.scheduleInputs[rowIndex]?.focus();
                                                }, 0);
                                              }
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`mui-table__cell
                                                            ${
                                                              errors?.[`schedules.${row.position}.${column.id}`]
                                                                ? 'mui-table__cellError'
                                                                : ''
                                                            }
                                                            ${isDriverTask && column.id === 'title' ? 'activeDriverTask' : ''}
                                                            ${isDriverTask === 1 && column.id === 'title' ? 'activeDriverTaskMain' : ''}
                                                            ${
                                                              isPossibleForDriverSelect && column.id === 'title'
                                                                ? 'activeDriverTaskPossible'
                                                                : ''
                                                            }
                                                            ${cellClass}
                                                            `}
                                                  style={{ width: maxScheduleLengthText }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(e, rowIndex, i, columns.length, schedules.length, 'schedules', column.id)
                                                  }
                                                >
                                                  <CustomTooltip
                                                    title={tooltip || ''}
                                                    placement={'top-end'}
                                                    open={tooltip === 'Driver' ? true : null}
                                                  >
                                                    <div style={{ paddingLeft: !row.isMainBlock ? '0px' : '' }}>
                                                      <OutlinedInput
                                                        name={`${column.id}`}
                                                        labelWidth={0}
                                                        autoComplete={'off'}
                                                        className={`b-createProject__input`}
                                                        value={value || ''}
                                                        onChange={handleChangeValue(column.id, rowIndex, 'schedules')}
                                                        disabled={isDisabled}
                                                        fullWidth={true}
                                                        inputRef={input => (this.scheduleInputs[rowIndex] = input)}
                                                      />
                                                    </div>
                                                  </CustomTooltip>
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'select') {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                        mui-table__cell
                                        ${errors?.[`schedules.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                        ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                        ${cellClass}
                                        `}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(e, rowIndex, i, columns.length, schedules.length, 'schedules', column.id)
                                                  }
                                                  style={{ width: '60px', padding: '0px' }}
                                                >
                                                  {!row.isMainBlock ? (
                                                    <Tooltip
                                                      title={
                                                        errors?.[`schedules.${row.position}.${column.id}`]?.[0]
                                                          ? errors?.[`schedules.${row.position}.${column.id}`]?.[0]
                                                          : ``
                                                      }
                                                    >
                                                      <div>
                                                        <SelectField
                                                          name={column.id}
                                                          className={'b-createProject__input'}
                                                          id={`outlined-${column.id}`}
                                                          values={phasePlanSelect[column.collection]}
                                                          selectHandler={handleChangeSelect(column.id, rowIndex, 'schedules', i)}
                                                          selectedId={value}
                                                          disabled={isDisabled}
                                                          style={{ maxHeight: 22 }}
                                                          controlOpen={{
                                                            open: isEditField,
                                                            onClose: handleCloseSelect,
                                                          }}
                                                          fieldView={isEditField ? 'description' : `title`}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : (
                                                    ''
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'numberInput') {
                                              if (isEditField) {
                                                setTimeout(() => {
                                                  this.numberInputs[`schedules-${column.id}-${rowIndex}`].focus();
                                                }, 0);
                                              }
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                 mui-table__cell
                                                 ${errors?.[`schedules.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                 ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                                  ${cellClass}
                                                 `}
                                                  style={{ display: 'flex', alignItems: 'center' }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(e, rowIndex, i, columns.length, schedules.length, 'schedules', column.id)
                                                  }
                                                >
                                                  <Tooltip
                                                    title={
                                                      errors?.[`schedules.${row.position}.${column.id}`]?.[0]
                                                        ? errors?.[`schedules.${row.position}.${column.id}`][0]
                                                        : ``
                                                    }
                                                  >
                                                    <NumberFormat
                                                      thousandSeparator={true}
                                                      className={`b-createProject__autoComplete ${
                                                        !editMode ? 'b-phasePlan__autoCompleteDisabled' : ''
                                                      }`}
                                                      onValueChange={handleChangeNumber(column.id, rowIndex, 'schedules')}
                                                      value={value || ''}
                                                      disabled={isDisabled}
                                                      getInputRef={input =>
                                                        (this.numberInputs[`schedules-${column.id}-${rowIndex}`] = input)
                                                      }
                                                    />
                                                  </Tooltip>
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'edit' && editMode) {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`mui-table__cell ${
                                                    errors?.[`schedules.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''
                                                  }`}
                                                  style={{ maxWidth: '40px', width: '40px' }}
                                                >
                                                  {!row.isMainBlock && (
                                                    <div className={'b-phasePlan__wrapEdit'}>
                                                      <div className={'project_disciplines'}>
                                                        <div className={'wrapDisciplineActions'}>
                                                          <span
                                                            className={`addCompany ${phasePlanFieldStatus?.loading ? 'disabledSVG' : ''}`}
                                                          >
                                                            <img
                                                              src={require('../../../../../assets/images/plus.png')}
                                                              alt="#"
                                                              onClick={handleAddMilestone('schedules', rowIndex)}
                                                            />
                                                          </span>
                                                          <span
                                                            className={`remove ${phasePlanFieldStatus?.loading ? 'disabledSVG' : ''}`}
                                                            onClick={handleRemoveRow(row, rowIndex, 'schedules')}
                                                          >
                                                            <SVG
                                                              className={'removeIcon'}
                                                              src={require('../../../../../assets/icons/trash.svg')}
                                                              alt="#"
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'datePicker') {
                                              if (isEditField) {
                                                setTimeout(() => {
                                                  this.numberInputs[`schedules-${column.id}-${rowIndex}`]?.focus();
                                                }, 0);
                                              }
                                              const disabledDate =
                                                (!editMode || isDisabled) &&
                                                !(driverTasks?.[0]?.id === row.id && column.id === 'lod325_start');
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                 mui-table__cell
                                                 ${errors?.[`schedules.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                 ${row.disable ? 'mui-table__cellDisable' : ''}
                                                 ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                                  ${cellClass}
                                                 `}
                                                  style={{ display: 'flex', alignItems: 'center' }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(e, rowIndex, i, columns.length, schedules.length, 'schedules', column.id)
                                                  }
                                                >
                                                  {!row.isMainBlock && (
                                                    <Tooltip
                                                      title={
                                                        errors?.[`schedules.${row.position}.${column.id}`]?.[0]
                                                          ? errors?.[`schedules.${row.position}.${column.id}`][0]
                                                          : ``
                                                      }
                                                    >
                                                      <div
                                                        className={`b-createProject__datePicker
                                            ${disabledDate ? 'b-createProject__disabledDatePicker' : ''}
                                            `}
                                                        style={{ cursor: editMode ? 'pointer' : '' }}
                                                        onClick={e => {
                                                          if (!disabledDate) {
                                                            e.stopPropagation();
                                                          }
                                                        }}
                                                      >
                                                        {
                                                          !row.isMainBlock && (
                                                            <NumberFormat
                                                              thousandSeparator={false}
                                                              className={`b-createProject__autoComplete`}
                                                              onValueChange={handleChangeDate(column, rowIndex, 'schedules', !disabledDate)}
                                                              value={
                                                                schedules?.[rowIndex][column.id]
                                                                  ? moment(schedules[rowIndex][column.id]).format('MM/DD/YYYY')
                                                                  : 'mm/dd/yyyy'
                                                              }
                                                              // getInputRef={input => !column.disable ? this[`${rowIndex}-${column.id === 'construction_end' ? 3 : 1}`] = input : null}
                                                              placeholder={'mm/dd/yyyy'}
                                                              format="##/##/####"
                                                              mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
                                                              disabled={disabledDate}
                                                              getInputRef={input =>
                                                                (this.numberInputs[`schedules-${column.id}-${rowIndex}`] = input)
                                                              }
                                                            />
                                                          )
                                                          // <span>{schedules?.[rowIndex][column.id] ? moment(schedules[rowIndex][column.id]).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
                                                        }
                                                        {!row.isMainBlock && (
                                                          <span
                                                            className={'calendarIcon'}
                                                            onClick={handleOpenCalendar(rowIndex, column, 'schedules', isDisabled)}
                                                          >
                                                            {editMode && <SVG src={require('../../../../../assets/icons/calendar.svg')} />}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </Tooltip>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            : null}
                          {provided.placeholder}
                        </tbody>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </Table>
              <Table aria-label="sticky table" className={'b-phasePlan__permissionTable'} style={{ position: 'relative', zIndex: 1 }}>
                <TableHead className={'b-phasePlan__tableHead'}>
                  <TableRow
                    style={{ height: '61px' }}
                    className={`b-phasePlan__rowHead b-phasePlan__rowHeadHidingField ${isOpenCollapse ? 'b-phasePlan__openRowHead' : ''}`}
                  >
                    {permitColumnsFiltered.map((column, index) => {
                      if (index === 0) {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              backgroundColor: column.color,
                              width: isOpenCollapse ? maxScheduleLengthText : maxScheduleLengthText + 60,
                              height: '60px',
                            }}
                            className={`mui-table__head`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                      if (column.type === 'edit' && editMode) {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              backgroundColor: column.color,
                              width: column.type === 'edit' ? '44px' : '',
                              height: '60px',
                            }}
                            className={`mui-table__head ${column.type === 'edit' ? 'b-phasePlan__stickEdit' : ''}`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      } else if (column.type !== 'edit') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ backgroundColor: column.color, height: '60px' }}
                            className={`mui-table__head`}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={'permissions'} direction="vertical">
                    {provided => {
                      return (
                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                          {permissions.length
                            ? permissions.map((row, rowIndex) => {
                                return (
                                  <Draggable
                                    key={`phasePlanPermitD&D-${rowIndex}`}
                                    draggableId={`id-${rowIndex}`}
                                    index={rowIndex}
                                    isDragDisabled={row.isMainBlock || !editMode}
                                  >
                                    {(provided, snapshot, e) => {
                                      return (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          className={`b-phasePlan__rowBody ${isOpenCollapse ? 'b-phasePlan__openRowBody' : ''} ${
                                            editMode ? 'b-phasePlan__editRow' : ''
                                          }`}
                                        >
                                          {permitColumnsFiltered.map((column, i) => {
                                            const value = row[column.id];
                                            const isCurrentTable = selectedField?.table === 'permissions';
                                            const isEditField =
                                              isCurrentTable &&
                                              selectedField.editField &&
                                              selectedField?.row === rowIndex &&
                                              selectedField?.column === i;
                                            const isDisabled =
                                              !isCurrentTable ||
                                              !editMode ||
                                              row.isMainBlock ||
                                              !(selectedField.editField && selectedField?.row === rowIndex && selectedField?.column === i);
                                            const cellClass = `${editMode ? 'b-phasePlan__fieldReadonly' : ''}
                                                     ${
                                                       isCurrentTable && selectedField?.row === rowIndex && selectedField?.column === i
                                                         ? 'b-phasePlan__fieldSelected'
                                                         : ''
                                                     }
                                                     ${isCurrentTable && isEditField ? 'b-phasePlan__fieldEdited' : ''}`;
                                            if (column.type === 'input') {
                                              if (isEditField) {
                                                setTimeout(() => {
                                                  this.permitInputs[rowIndex].focus();
                                                }, 0);
                                              }
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                   mui-table__cell
                                                   ${errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                   ${cellClass}`}
                                                  style={{ width: isOpenCollapse ? maxScheduleLengthText : maxScheduleLengthText + 60 }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  <Tooltip
                                                    title={
                                                      errors?.[`permissions.${row.position}.${column.id}`]?.[0]
                                                        ? errors?.[`permissions.${row.position}.${column.id}`][0]
                                                        : ``
                                                    }
                                                  >
                                                    <div style={{ paddingLeft: !row.isMainBlock ? '0px' : '' }}>
                                                      <OutlinedInput
                                                        name={`${column.id}`}
                                                        labelWidth={0}
                                                        autoComplete={'off'}
                                                        className={`b-createProject__input`}
                                                        value={value || ''}
                                                        onChange={handleChangeValue(column.id, rowIndex, 'permissions')}
                                                        disabled={isDisabled}
                                                        // inputProps={{ autocomplete: 'off'}}
                                                        inputRef={input => (this.permitInputs[rowIndex] = input)}
                                                      />
                                                    </div>
                                                  </Tooltip>
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'inputDesc') {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                   mui-table__cell ${
                                                     errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''
                                                   }
                                                   ${cellClass}`}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  <Tooltip
                                                    title={
                                                      errors?.[`permissions.${row.position}.${column.id}`]?.[0]
                                                        ? errors?.[`permissions.${row.position}.${column.id}`][0]
                                                        : ``
                                                    }
                                                  >
                                                    <div style={{ paddingLeft: !row.isMainBlock ? '0px' : '' }}>
                                                      <OutlinedInput
                                                        name={`${column.id}`}
                                                        labelWidth={0}
                                                        autoComplete={'off'}
                                                        className={`b-createProject__input`}
                                                        value={value || ''}
                                                        onChange={handleChangeValue(column.id, rowIndex, 'permissions')}
                                                        disabled={isDisabled}
                                                        // inputProps={{ autocomplete: 'off'}}
                                                      />
                                                    </div>
                                                  </Tooltip>
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'numberInput') {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                          mui-table__cell
                                          ${errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                          ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                          ${cellClass}
                                          `}
                                                  style={{ display: 'flex', alignItems: 'center' }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  <Tooltip
                                                    title={
                                                      errors?.[`permissions.${row.position}.${column.id}`]?.[0]
                                                        ? errors?.[`permissions.${row.position}.${column.id}`][0]
                                                        : ``
                                                    }
                                                  >
                                                    <NumberFormat
                                                      thousandSeparator={false}
                                                      className={`b-createProject__autoComplete ${
                                                        !editMode ? 'b-phasePlan__autoCompleteDisabled' : ''
                                                      }`}
                                                      onValueChange={handleChangeNumber(column.id, rowIndex, 'permissions')}
                                                      value={value || ''}
                                                      disabled={isDisabled}
                                                    />
                                                  </Tooltip>
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'select') {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                  mui-table__cell
                                                  ${errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                  ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                                  ${cellClass}`}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  {!row.isMainBlock ? (
                                                    <Tooltip
                                                      title={
                                                        errors?.[`permissions.${row.position}.${column.id}`]?.[0]
                                                          ? errors?.[`permissions.${row.position}.${column.id}`][0]
                                                          : ``
                                                      }
                                                    >
                                                      <div>
                                                        <SelectField
                                                          name={column.id}
                                                          className={'b-createProject__input'}
                                                          id={`outlined-${column.id}`}
                                                          values={phasePlanSelect[column.collection]}
                                                          selectHandler={handleChangeSelect(column.id, rowIndex, 'permissions')}
                                                          selectedId={value}
                                                          disabled={isDisabled}
                                                          style={{ maxHeight: 22 }}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : null}
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'colorSelect') {
                                              // Auto open select
                                              // if (isEditField) {
                                              //   handleOpenListColor(this.colorRef);
                                              // }
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                  mui-table__cell
                                                  ${errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                  ${row.isMainBlock ? 'b-phasePlan__emptyField' : ''}
                                                  ${cellClass}`}
                                                  onDoubleClick={handleOpenListColor}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  {!row.isMainBlock ? (
                                                    <div className={'project_disciplines colors'}>
                                                      <span onClick={isDisabled || !editMode ? () => {} : handleOpenListColor}>
                                                        <SVG src={require('../../../../../assets/icons/pouring.svg')} />
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'edit' && editMode) {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`mui-table__cell ${
                                                    errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''
                                                  } ${column.type === 'edit' ? 'b-phasePlan__stickEdit' : ''}`}
                                                  style={{
                                                    maxWidth: '40px',
                                                    width: '40px',
                                                    borderLeft: !isOpenCollapse ? '1px solid #40466a' : '',
                                                  }}
                                                >
                                                  {!row.isMainBlock && (
                                                    <div className={'b-phasePlan__wrapEdit'}>
                                                      <div className={'project_disciplines'}>
                                                        <div className={'wrapDisciplineActions'}>
                                                          <span
                                                            className={`addCompany ${phasePlanFieldStatus?.loading ? 'disabledSVG' : ''}`}
                                                          >
                                                            <img
                                                              src={require('../../../../../assets/images/plus.png')}
                                                              alt="#"
                                                              onClick={handleAddMilestone('permissions', rowIndex)}
                                                            />
                                                          </span>
                                                          <span
                                                            className={`remove ${phasePlanFieldStatus?.loading ? 'disabledSVG' : ''}`}
                                                            onClick={handleRemoveRow(row, rowIndex, 'permissions')}
                                                          >
                                                            <SVG
                                                              className={'removeIcon'}
                                                              src={require('../../../../../assets/icons/trash.svg')}
                                                              alt="#"
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            if (column.type === 'datePicker') {
                                              return (
                                                <TableCell
                                                  key={column.id}
                                                  className={`
                                                   mui-table__cell
                                                   ${errors?.[`permissions.${row.position}.${column.id}`] ? 'mui-table__cellError' : ''}
                                                   ${row.disable ? 'mui-table__cellDisable' : ''}
                                                   ${cellClass}`}
                                                  style={{ display: 'flex', alignItems: 'center' }}
                                                  onDoubleClick={handleDblClick}
                                                  onClick={e =>
                                                    handleExcelNav(
                                                      e,
                                                      rowIndex,
                                                      i,
                                                      permitColumnsFiltered.length,
                                                      permissions.length,
                                                      'permissions',
                                                      column.id,
                                                    )
                                                  }
                                                >
                                                  {!row.isMainBlock && (
                                                    <Tooltip
                                                      title={
                                                        errors?.[`permissions.${row.position}.${column.id}`]?.[0]
                                                          ? errors?.[`permissions.${row.position}.${column.id}`][0]
                                                          : ``
                                                      }
                                                    >
                                                      <div
                                                        className={`b-createProject__datePicker
                                            ${
                                              (!editMode || isDisabled) &&
                                              !(driverTasks?.[0]?.id === row.id && column.id === 'lod325_start')
                                                ? 'b-createProject__disabledDatePicker'
                                                : ''
                                            }
                                            `}
                                                        style={{ cursor: editMode ? 'pointer' : '' }}
                                                      >
                                                        {
                                                          !row.isMainBlock && (
                                                            <NumberFormat
                                                              thousandSeparator={false}
                                                              className={`b-createProject__autoComplete`}
                                                              onValueChange={handleChangeDate(column, rowIndex, 'permissions')}
                                                              value={
                                                                permissions?.[rowIndex][column.id]
                                                                  ? moment(permissions[rowIndex][column.id]).format('MM/DD/YYYY')
                                                                  : 'mm/dd/yyyy'
                                                              }
                                                              // getInputRef={input => !column.disable ? this[`${rowIndex}-${column.id === 'construction_end' ? 3 : 1}`] = input : null}
                                                              placeholder={'mm/dd/yyyy'}
                                                              format="##/##/####"
                                                              mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
                                                              disabled={isDisabled}
                                                              getInputRef={input =>
                                                                (this.numberInputs[`permissions-${column.id}-${rowIndex}`] = input)
                                                              }
                                                            />
                                                          )
                                                          // <span>{schedules?.[rowIndex][column.id] ? moment(schedules[rowIndex][column.id]).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>
                                                        }
                                                        {!row.isMainBlock && (
                                                          <span
                                                            className={'calendarIcon'}
                                                            onClick={handleOpenCalendar(rowIndex, column, 'permissions', isDisabled)}
                                                          >
                                                            {editMode && <SVG src={require('../../../../../assets/icons/calendar.svg')} />}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </Tooltip>
                                                  )}
                                                  {/*{!row.isMainBlock && <Tooltip*/}
                                                  {/*  title={errors?.[`permissions.${row.position}.${column.id}`]?.[0] ? errors?.[`permissions.${row.position}.${column.id}`][0] : ``}>*/}
                                                  {/*  <div*/}
                                                  {/*    className={`b-createProject__datePicker ${!editMode ? 'b-createProject__disabledDatePicker' : ''}`}*/}
                                                  {/*    onClick={handleOpenCalendar(rowIndex, column, 'permissions', editMode)}*/}
                                                  {/*    style={{cursor: editMode ? 'pointer' : ''}}*/}
                                                  {/*  >*/}
                                                  {/*    {!row.isMainBlock && <span>{permissions?.[rowIndex][column.id] ? moment(permissions[rowIndex][column.id]).format('MM/DD/YYYY') : 'mm/dd/yyyy'}</span>}*/}
                                                  {/*  </div>*/}
                                                  {/*</Tooltip>}*/}
                                                </TableCell>
                                              );
                                            }
                                          })}
                                        </tr>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            : null}
                          {provided.placeholder}
                        </tbody>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </Table>
              <div style={{ height: isExistScrollX ? '33px' : '33px' }} className={'b-phasePlan__separateTable'}>
                <ColumnToolTip title={isOpenCollapse ? 'Expand' : 'Collapse'} placement="top">
                  <div
                    className={`${asideHolderStyles.aside__wrapCollapseBtn} ${
                      Object.values(errors).length && isOpenCollapse ? 'b-phasePlan__collapseError' : ''
                    }`}
                    onClick={handleCollapse}
                  >
                    <SVG
                      src={require('../../../../../assets/icons/double-arrow.svg')}
                      className={!isOpenCollapse ? asideHolderStyles.aside__openedMenu : asideHolderStyles.aside__closedMenu}
                      alt="#"
                    />
                  </div>
                </ColumnToolTip>
              </div>
            </div>
          </div>
        </ScrollSyncPane>
        <Dialog
          open={!!removeRow}
          onClose={handleCloseRemoving}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="xs"
          className="dialogPopup"
          style={{ padding: '0 10px' }}
          PaperProps={{
            rounded: { borderRadius: '0px' },
          }}
        >
          <DialogContent className="dialogContent" style={{ backgroundColor: '#2b3141' }}>
            <div className={'b-phasePlan__removingItemDialog'}>
              <span>Are you sure that you want to delete this task?</span>
            </div>
            <div className={'b-phasePlan__removingItemActions'}>
              <span className={'yes'} onClick={() => handleRemoveMilestone()}>
                Yes
              </span>
              <span className={'no'} onClick={handleCloseRemoving}>
                No
              </span>
            </div>
          </DialogContent>
        </Dialog>
        <Popover
          id={`${'kickOffDate'}`}
          open={!!indexOpenedRowCalendar}
          anchorEl={anchorEl}
          onClose={handleCloseCalendar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div>
            <div className={'b-createProject__calendar'}>
              {
                <Calendar
                  onChange={selectAtDateHandler}
                  calendarType={'US'}
                  value={new Date(valueOpenedCalendar) != 'Invalid Date' ? valueOpenedCalendar : new Date()}
                  locale={'en'}
                  name={'construction_start'}
                  onClickDay={selectAtDateHandlerSingle}
                  minDate={getMinCalendarDate()}
                  maxDate={getMaxCalendarDate()}
                />
              }
            </div>
          </div>
        </Popover>
        <Popover
          id={`listColor`}
          open={!!anchorListColor}
          anchorEl={anchorListColor}
          onClose={handleCloseListColor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={'b-phasePlan__listColor'}>
            {['#254A7E', '#3966A3', '#6291D1', '#85ABDE'].map((color, index) => {
              return (
                <div
                  key={`color-${color}`}
                  className={'colorBlock'}
                  style={{ backgroundColor: color }}
                  onClick={() => handleSelectColor(index)}
                ></div>
              );
            })}
          </div>
        </Popover>
      </div>
    );
  }
}

export default TablePlanView;
