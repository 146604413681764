import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import CreateProjectView from './CreateProjectView';
import { ProjectModel, UserDataModel, UserModel } from '../../../models';
import { getModuleVisibility } from '../../../helpers/ModuleVisibilityHelper';
import { getProjectDetails } from '../../../store/project/projectLogic';
import * as ROUTES from '../../../navigation/routes';
import { getPhasePlanSelectData, getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { phasePlanSlice } from '../../../store/phasePlan/phasePlanSlice';
import { RouteComponentProps, withRouter } from '../../../navigation/withRouter/withRouter';
const { clearProjectData } = phasePlanSlice.actions;

interface IProps extends RouteComponentProps {
  getPhasePlanSelectData: () => void;
  getProjectById: (id: number) => void;
  clearProjectData: () => void;
  project: ProjectModel;
  getProjectDetails: (project_id: number) => void;
  userInfo: UserModel;
  active_project_id: number;
  userData: UserDataModel;
}

interface IState {
  step: number;
}

class CreateProjectContainer extends React.Component<IProps, IState> {
  state = {
    step: 1,
  };

  componentDidMount() {
    const { urlProject } = this.props;

    this.props.getPhasePlanSelectData();
    if (Number.isInteger(+urlProject[2]) && urlProject[1] === 'project-settings') {
      this.props.getProjectById(+urlProject[2]);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const { active_project_id, urlProject, navigate } = this.props;
    const stepUrl = urlProject[3] ? this.getStepUrl(urlProject[3]) : null;

    if (stepUrl && this.state.step !== stepUrl) {
      this.setState({
        step: stepUrl,
      });
    }
    if (active_project_id && active_project_id !== prevProps.active_project_id) {
      this.props.getProjectById(+active_project_id);
      if (urlProject[1] == 'project-settings') {
        urlProject[2] = String(active_project_id);
        navigate({
          pathname: `/${urlProject.join('/')}`,
          search: '',
        });
      }
    }
    if (stepUrl && this.state.step !== stepUrl) {
      this.setState({
        step: stepUrl,
      });
    }
  }

  exitEditMode = () => {
    const { getProjectDetails, navigate } = this.props;
    const activeProjectId = Number(sessionStorage.getItem('active_project_id'));

    getProjectDetails(activeProjectId);
    navigate(ROUTES.getProject(sessionStorage.getItem('project_name')));
  };

  nameSheetUrl = (project_id, step) => {
    const { navigate } = this.props;
    const nameSheet = [
      { id: 1, name: 'general' },
      { id: 2, name: 'buildings' },
      { id: 4, name: 'content-plan' },
      { id: 5, name: 'integrations' },
    ];
    const nameFindIndex = nameSheet.findIndex(item => item.id == step);
    const NameSheet = nameFindIndex !== -1 ? nameSheet[nameFindIndex].name : nameSheet[0].name;

    navigate(ROUTES.getCreateProjectIdSheet(sessionStorage.getItem('project_name'), project_id, NameSheet));
  };

  getStepUrl = Name => {
    const nameSheet = [
      { id: 1, name: 'general' },
      { id: 2, name: 'buildings' },
      { id: 4, name: 'content-plan' },
      { id: 5, name: 'integrations' },
    ];
    const nameFindIndex = nameSheet.findIndex(item => item.name == Name);
    const SheetId = nameFindIndex !== -1 ? nameSheet[nameFindIndex].id : nameSheet[0].id;
    return SheetId;
  };

  redirectToStep = step => {
    const { project } = this.props;
    if (!project.id) {
      return;
    }

    this.nameSheetUrl(project.id, step);

    if (step === 5 && project.buildings.length < 1) {
      return;
    }

    this.setState({
      step: step,
    });
  };

  render() {
    const { step } = this.state;
    const { project, userInfo, active_project_id } = this.props;

    const isActivePCD = userInfo.id || getModuleVisibility('pcd', userInfo, +active_project_id);

    return (
      <CreateProjectView
        step={step}
        project={project}
        redirectToStep={this.redirectToStep}
        isActivePCD={isActivePCD}
        exitEditMode={this.exitEditMode}
      />
    );
  }
}

const mapStateToProps = ({ phasePlan, userReducer }) => {
  return {
    project: phasePlan.projectData,
    userInfo: userReducer.userInfo,
    userData: userReducer,
    active_project_id: userReducer.active_project_id,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPhasePlanSelectData,
      getProjectById,
      clearProjectData,
      getProjectDetails,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateProjectContainer));
