import { SimpleTitleModel } from '..';

export class LocationModel {
  below_grade: number;
  building_id: number;
  building_spaces: SimpleTitleModel[] = [];
  exterior_types: SimpleTitleModel[] = [];
  footage: number;
  id: number;
  is_substructure: number;
  construction_types: SimpleTitleModel[] = [];
  occupancy_type_id: number;
  position: number;
  project_id: number;
  title: string;
  typical_layout_id: number;
  custom_id: number;
  // selected occupancy
  occupancy_types?: number;

  constructor(props) {
    this.below_grade = props.below_grade || null;
    this.building_id = props.building_id || null;
    this.building_spaces = props.building_spaces || [];
    this.exterior_types = props.exterior_types || [];
    this.construction_types = props.construction_types || [];
    this.footage = props.footage || null;
    this.is_substructure = props.is_substructure || null;
    this.occupancy_type_id = props.occupancy_type_id || null;
    this.position = props.position || 0;
    this.project_id = props.project_id || null;
    this.title = props.title || null;
    this.typical_layout_id = props.typical_layout_id || null;
    this.custom_id = props.custom_id || null;
  }
}

export interface TypicalLayoutModel {
  building_id: number;
  end_position: number;
  id: number;
  is_default: number;
  name: string;
  start_position: number;
}

export interface LocationCollectionModel {
  id: number;
  building_id: number;
  building_spaces_ids: number[];
  title: string;
  position?: number;
  value?: number;
}
