import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { PdfTronData } from '../../components/common/PdfTronOptimized/PdfTronOptimized';
import { ConstructionCodesRequestPayload, ValidationModel } from '../../models';
import { NeedListRequest } from '../needlist/needListModels';
import {
  constructionDriverDownloadFile,
  getBuildingTemplate,
  getConstructionCodes,
  getContentPlanTemplate,
  getCurrentProjectSpecialReport,
  getCurrentProjectTemplate,
  getDDLTemplate,
  getListSetDDL,
  getMfCodes,
  getRequestsFilters,
  getSpecificationTemplate,
  getUfCodes,
  importBuildingSpaces,
  importConstructionDriver,
  importContentPlan,
  importDDL,
  importNeedList,
  importSpecification,
  validateBuildingSpacesImport,
  validateConstructionDriverImport,
  validateContentPlanImport,
  validateDDLImport,
  validateNeedListImport,
  validateSpecificationImport,
} from './commonThunk';
import { ConstructionCodesResponsePayload, ICommonReducer, IGetRequestsPayload, IMfPayload, IUfPayload } from './commonReducer.model';
import Api from '../../service/api';

const initialState: ICommonReducer = {
  ufCodes: null,
  mfCodes: null,
  constructionCodes: null,
  constructionCodesTree: null,
  constructionCodesLoading: Api.initialStatus,
  constructionCodesLoadingType: null,
  message: '',
  loading: false,
  importDDL_loading: false,
  status: null,
  lastUpdateTracking: null,
  pdftronData: null,
  requestsFilters: {},
  validation: null,
  import: false,
  list_set_data: {
    list_set: [],
  },
  resizableCardPart: '',
  resizablePCCardPart: '',
  changeActiveTabFromPdfTron: false,
};

export const commonSlice = createSlice({
  initialState,
  name: 'commonReducer',
  reducers: {
    clearRequestsFilters(state) {
      state.requestsFilters = initialState.requestsFilters;
    },
    clearImportResult(state) {
      state.validation = null;
      state.message = '';
    },
    handleResizableCardPart(state, { payload }: PayloadAction<string>) {
      state.resizableCardPart = payload;
    },
    handleResizablePCCardPart(state, { payload }: PayloadAction<string>) {
      state.resizablePCCardPart = payload;
    },
    openPdftron(state, { payload }: PayloadAction<PdfTronData>) {
      if (payload.openFromRequest) {
        state.changeActiveTabFromPdfTron = true;
      }
      state.pdftronData = payload;
    },
    setChangeActiveTabFromPdfTron(state) {
      state.changeActiveTabFromPdfTron = false;
    },
    closePdftron(state) {
      state.pdftronData = null;
    },
    singleRequestForProjectLoadingSucces(state, { payload }: PayloadAction<NeedListRequest>) {
      state.lastUpdateTracking = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getUfCodes
       */
      .addCase(getUfCodes.pending, state => {
        state.loading = true;
      })
      .addCase(getUfCodes.fulfilled, (state, { payload }: PayloadAction<IUfPayload>) => {
        state.ufCodes = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(getUfCodes.rejected, (state, { payload }) => {
        /**
         * state.ufCodes = payload.data;
           state.message = payload.message;
           state.status = payload.status;
         */
        state.ufCodes = initialState.ufCodes;
        state.message = initialState.message;
        state.status = initialState.status;
        state.loading = false;
      })
      /**
       * getMfCodes
       */
      .addCase(getMfCodes.pending, state => {
        state.loading = true;
      })
      .addCase(getMfCodes.fulfilled, (state, { payload }: PayloadAction<IMfPayload>) => {
        state.mfCodes = payload.data;
        state.message = payload.message;
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(getMfCodes.rejected, state => {
        state.loading = false;
      })
      /**
       * getConstructionCodes
       */
      .addCase(getConstructionCodes.pending, (state, { meta }: PayloadAction<null, string, { arg: ConstructionCodesRequestPayload }>) => {
        state.constructionCodesLoading = Api.requestStatus;
        state.constructionCodesLoadingType = meta.arg.table;
      })
      .addCase(getConstructionCodes.fulfilled, (state, { payload }: PayloadAction<ConstructionCodesResponsePayload>) => {
        const currentState = current(state);

        state.constructionCodes = {
          ...currentState.constructionCodes,
          [payload.type]: payload.target,
        };
        state.constructionCodesTree = {
          ...currentState.constructionCodesTree,
          [payload.type]: payload.tree,
        };
        state.constructionCodesLoading = Api.successStatus;
      })
      .addCase(getConstructionCodes.rejected, (state, { payload }) => {
        state.constructionCodesLoading = Api.failStatus;
      })
      /**
       * getRequestsFilters
       */
      .addCase(getRequestsFilters.pending, state => {
        state.loading = true;
      })
      .addCase(getRequestsFilters.fulfilled, (state, { payload }: PayloadAction<IGetRequestsPayload>) => {
        const copyFilters = JSON.parse(JSON.stringify(payload.response.data));
        state.requestsFilters[payload.name] = copyFilters;
      })
      .addCase(getRequestsFilters.rejected, state => {
        state.loading = false;
      })
      /**
       * getCurrentProjectTemplate
       */
      .addCase(getCurrentProjectTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getCurrentProjectTemplate.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getCurrentProjectTemplate.rejected, state => {
        state.loading = false;
      })
      /**
       * getSpecificationTemplate
       */
      .addCase(getSpecificationTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getSpecificationTemplate.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getSpecificationTemplate.rejected, state => {
        state.loading = false;
      })
      /**
       * constructionDriverDownloadFile
       */
      .addCase(constructionDriverDownloadFile.pending, state => {
        state.loading = true;
      })
      .addCase(constructionDriverDownloadFile.fulfilled, state => {
        state.loading = false;
      })
      .addCase(constructionDriverDownloadFile.rejected, state => {
        state.loading = false;
      })
      /**
       * getBuildingTemplate
       */
      .addCase(getBuildingTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getBuildingTemplate.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getBuildingTemplate.rejected, state => {
        state.loading = false;
      })
      /**
       * getContentPlanTemplate
       */
      .addCase(getContentPlanTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getContentPlanTemplate.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getContentPlanTemplate.rejected, state => {
        state.loading = false;
      })
      /**
       * getDDLTemplate
       */
      .addCase(getDDLTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getDDLTemplate.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getDDLTemplate.rejected, state => {
        state.loading = false;
      })
      /**
       * getCurrentProjectSpecialReport
       */
      .addCase(getCurrentProjectSpecialReport.pending, state => {
        state.loading = true;
      })
      .addCase(getCurrentProjectSpecialReport.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getCurrentProjectSpecialReport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateNeedListImport
       */
      .addCase(validateNeedListImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateNeedListImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateNeedListImport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateSpecificationImport
       */
      .addCase(validateSpecificationImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateSpecificationImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateSpecificationImport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateConstructionDriverImport
       */
      .addCase(validateConstructionDriverImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateConstructionDriverImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateConstructionDriverImport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateBuildingSpacesImport
       */
      .addCase(validateBuildingSpacesImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateBuildingSpacesImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateBuildingSpacesImport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateContentPlanImport
       */
      .addCase(validateContentPlanImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateContentPlanImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateContentPlanImport.rejected, state => {
        state.loading = false;
      })
      /**
       * validateDDLImport
       */
      .addCase(validateDDLImport.pending, state => {
        state.loading = true;
      })
      .addCase(validateDDLImport.fulfilled, (state, { payload }: PayloadAction<ValidationModel>) => {
        state.validation = payload;
        state.loading = false;
      })
      .addCase(validateDDLImport.rejected, state => {
        state.loading = false;
      })
      /**
       * importNeedList
       */
      .addCase(importNeedList.pending, state => {
        state.loading = true;
      })
      .addCase(importNeedList.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(importNeedList.rejected, state => {
        state.loading = false;
      })
      /**
       * importSpecification
       */
      .addCase(importSpecification.pending, state => {
        state.loading = true;
      })
      .addCase(importSpecification.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(importSpecification.rejected, state => {
        state.loading = false;
      })
      /**
       * importConstructionDriver
       */
      .addCase(importConstructionDriver.pending, state => {
        state.loading = true;
      })
      .addCase(importConstructionDriver.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(importConstructionDriver.rejected, state => {
        state.loading = false;
      })
      /**
       * importBuildingSpaces
       */
      .addCase(importBuildingSpaces.pending, state => {
        state.loading = true;
      })
      .addCase(importBuildingSpaces.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(importBuildingSpaces.rejected, state => {
        state.loading = false;
      })
      /**
       * importContentPlan
       */
      .addCase(importContentPlan.pending, state => {
        state.loading = true;
      })
      .addCase(importContentPlan.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
      })
      .addCase(importContentPlan.rejected, state => {
        state.loading = false;
      })
      /**
       * importDDL
       */
      .addCase(importDDL.pending, state => {
        state.loading = true;
        state.importDDL_loading = true;
        state.import = false;
      })
      .addCase(importDDL.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.message = payload;
        state.loading = false;
        state.importDDL_loading = false;
        state.import = true;
      })
      .addCase(importDDL.rejected, state => {
        state.loading = false;
        state.importDDL_loading = false;
        state.import = false;
      })
      /**
       * getListSetDDL
       */
      .addCase(getListSetDDL.pending, state => {
        state.loading = true;
      })
      .addCase(getListSetDDL.fulfilled, (state, { payload }) => {
        state.list_set_data.list_set = payload;
        state.loading = false;
      })
      .addCase(getListSetDDL.rejected, state => {
        state.loading = false;
      });
  },
});

export default commonSlice.reducer;
