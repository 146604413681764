import React from 'react';
import cn from 'classnames';
import CustomButton from '../../../../controls/ButtonComponents/CustomButton/CustomButton';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { useAppSelector } from '../../../../../store/configure/configureStore';
import { FileModel, RequestModel } from '../../../../../models';
import { GetResolution } from '../../../../../helpers/ScreenResolution/GetResolution';
import { useDispatch } from 'react-redux';
import { commentSlice } from '../../../../../store/comments/commentsReducer';

const { handleGoToNavigate } = commentSlice.actions;

interface IProps {
  selectedRequest: RequestModel;
  isDragRequestCard: boolean;
  isAccessToCommitment: boolean;
  requestLoading: boolean;
  isUser: boolean;
  responseCurrentDescription: string;
  responseFiles: FileModel[] | FileList | any;
  isAccess: boolean;
  openPromptStatus: (
    promptTitle: string,
    promptMessage: string,
    promptName: string,
    promptNameBtnOk: string,
    promptNameBtnCancel: string,
    is_show_again: boolean,
  ) => void;
  onChangeActiveTab: (value: string) => void;
  animationBtn: string | null;
  openDeclinePopup: (e: React.SyntheticEvent) => void;
}

const ResponseTabButtons = ({
  selectedRequest,
  isDragRequestCard,
  isAccessToCommitment,
  requestLoading,
  isUser,
  responseCurrentDescription,
  responseFiles,
  isAccess,
  openPromptStatus,
  onChangeActiveTab,
  animationBtn,
  openDeclinePopup,
}: IProps) => {
  const dispatch = useDispatch();
  const { sizeResolution, isMobile } = GetResolution();
  const showSmall = isDragRequestCard || sizeResolution === '640';

  const themeClass = showSmall
    ? `${useGetThemeClass('b-cardRequest-responseTab')}-small`
    : `${useGetThemeClass('b-cardRequest-responseTab')}-full`;

  const responseCommentsIds = useAppSelector(state => state.commentReducer.responseCommentsIds);

  const isAccessToSubmitWithResponseDescFromComment =
    selectedRequest?.comments?.length && responseCommentsIds.length && !!selectedRequest.comments.filter(f => f.is_response).length;
  const isDisableCompleteBtn =
    !selectedRequest?.id ||
    (!isAccessToCommitment && !isAccessToSubmitWithResponseDescFromComment) ||
    selectedRequest?.status === 'accepted' ||
    (isUser && !isAccessToCommitment && !isAccessToSubmitWithResponseDescFromComment) ||
    requestLoading ||
    selectedRequest?.status === 'provided' ||
    selectedRequest?.parent_id ||
    // needs at least 1 file or 1 field
    !((responseCurrentDescription && responseCurrentDescription != '<p><br></p>') || responseFiles.length);

  const isDisableAcceptBtn = !(isAccess && selectedRequest?.status === 'provided');

  const isDisableDeclineBtn =
    !(isAccess && (selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided')) || !!selectedRequest?.parent_id;

  const requestCompleteStatus = (nameBtn: string) => {
    if (nameBtn === 'acceptedBtn') {
      openPromptStatus(
        'Accept the response?',
        'Response company will be notified about the decision. You can decline the Response later on if necessary.',
        nameBtn,
        'Yes, accept',
        'Cancel',
        false,
      );
    }
  };

  const deliverableCompleteStatus = nameBtn => {
    if (nameBtn === 'deliverableComplete') {
      openPromptStatus(
        'Submit the Response?',
        'Requesting party will need to ‘Accept’ or ‘Decline’ your response.',
        nameBtn,
        'Yes, submit',
        'Cancel',
        true,
      );
    }
  };

  return (
    <div className={cn(`${themeClass}_buttonContainer`, [{ '-isCommentSection': true }])}>
      <div className={`${themeClass}_buttonContainer_section`}>
        {selectedRequest?.has_response_comments && responseCommentsIds?.length !== 0 && (
          <div className={`${themeClass}_buttonContainer_commentSection`}>
            <span className={`${themeClass}_buttonContainer_commentSection_text`}>Response was made from comment </span>
            <CustomButton
              type={'text-activated'}
              size={'sm'}
              clickHandler={() => {
                dispatch(handleGoToNavigate(true));
                onChangeActiveTab('discussion');
              }}
              title={'(Click to navigate to the discussion)'}
            />
          </div>
        )}
      </div>
      <div
        className={cn(`${themeClass}_buttonContainer_buttonsGroup`, {
          ['-acceptDecline']: selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided',
        })}
      >
        {selectedRequest?.status === 'provided' && (
          <CustomButton
            type={'accept'}
            size={'md'}
            clickHandler={() => requestCompleteStatus('acceptedBtn')}
            title={'Accept'}
            loading={animationBtn === 'acceptDeliverableBtn' && requestLoading}
            tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Accept submitted response'}
            tooltipProps={{ placement: 'top' }}
            disabled={isDisableAcceptBtn}
            customClassName={`${themeClass}_buttonContainer_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonContainer_buttonsGroup_tooltipBasis`}
            isMobile={isMobile}
          />
        )}
        {(selectedRequest?.status === 'accepted' || selectedRequest?.status === 'provided') && (
          <CustomButton
            type={'decline'}
            size={'md'}
            clickHandler={e => openDeclinePopup(e)}
            title={'Decline'}
            loading={animationBtn === 'declineDeliverableBtn' && requestLoading}
            tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Decline submitted response'}
            tooltipProps={{ placement: 'top' }}
            disabled={isDisableDeclineBtn}
            customClassName={`${themeClass}_buttonContainer_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonContainer_buttonsGroup_tooltipBasis`}
            isMobile={isMobile}
          />
        )}
        {selectedRequest?.status !== 'provided' && selectedRequest?.status !== 'accepted' && (
          <CustomButton
            type={'primary'}
            size={'md'}
            clickHandler={() => deliverableCompleteStatus('deliverableComplete')}
            title={'Submit'}
            isLoading={requestLoading}
            tooltip={localStorage.getItem('show_tooltips') === 'no' ? '' : 'Submit response to this request'}
            tooltipProps={{ placement: 'top' }}
            disabled={isDisableCompleteBtn}
            customClassName={`${themeClass}_buttonContainer_buttonsGroup_button`}
            customTooltipBasisClassName={`${themeClass}_buttonContainer_buttonsGroup_tooltipBasis`}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default ResponseTabButtons;
