import React, { FC } from 'react';
import { FormatterProps } from 'react-data-grid';
import { ManageStatusesColumn, ManageStatusesRow } from './types';
import { useGetThemeClass } from '../../../../../../helpers/designTokens';
import TokenIcon from '../../../../../controls/TokenIcon/TokenIcon';
import CustomTooltip from '../../../../../controls/Tooltip/Tooltip';
import StatusNameHelper from '../../../../../../helpers/StatusNameHelper';
import { PCDApi } from '../../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import StatusIncorporation from '../../../../../controls/Status/StatusIncorporation/StatusIncorporation';
import { getTriggerTooltipText } from '../../../../../../helpers/PCDHelper';
import GroupingCell from '../../../../DataGridBasic/GroupingCell/GroupingCell';
import CheckBox from '../../../../../controls/CheckBox/CheckBox';
import ChipsRevisionTag from '../../../../../controls/Chips/ChipsRevisionTag/ChipsRevisionTag';
import StatusRevision from '../../../../../controls/Status/StatusRevision/StatusRevision';
import StatusNF from '../../../../../controls/Status/StatusNF/StatusNF';
import CardLinkButton from '../../../../../controls/ButtonComponents/CardLinkButton/CardLinkButton';
import StatusSubmittal from '../../../../../controls/Status/StatusSubmittal/StatusSubmittal';
import ChipsRequestType from '../../../../../controls/Chips/ChipsRequestType/ChipsRequestType';
import OvalButton from '../../../../../controls/ButtonComponents/OvalButton/OvalButton';
import { FullRequestModel } from '../../../../../../store/request/requestReducer.model';

import '../ManageStatusesStyles.scss';

interface IProps extends FormatterProps<ManageStatusesRow> {
  col: ManageStatusesColumn;
  type:
    | 'designInput'
    | 'deliverables'
    | 'pcdCardLinkedNfsTab'
    | 'Nf deliverable'
    | 'Nf document'
    | 'Nf submittal'
    | 'Submittal request'
    | 'Submittal deliverable'
    | 'Submittal document'
    | 'Deliverable request'
    | 'Deliverable deliverable'
    | 'Deliverable submittal';
  fieldName?: string;
  openedRequest?: PCDCard;
  handleOpenCard?: (request: FullRequestModel) => void;
  handleClickCheckBox?: (data) => void;
}

const BasicFormatter: FC<IProps> = props => {
  const { column, row, openedRequest, handleOpenCard, handleClickCheckBox, type } = props;

  const themeClass = useGetThemeClass('b-manageStatuses');

  const getSetStatus = (status: string) => {
    if (type === 'designInput') {
      switch (status) {
        case 'valid': {
          return <TokenIcon iconName={'validDDL'} size={16} />;
        }
        case 'new_valid': {
          return <TokenIcon iconName={'1st-valid'} size={16} />;
        }
        case 'not_valid': {
          return <TokenIcon iconName={'void'} size={16} />;
        }
        case 'ignore': {
          return <TokenIcon iconName={'cancel-right'} size={16} />;
        }
        case 'not_verified': {
          return <TokenIcon iconName={'not-verified-triangle'} size={16} />;
        }
        default:
          return null;
      }
    }
    switch (status) {
      case 'valid': {
        return <TokenIcon iconName={'valid'} size={16} />;
      }
      case 'new_valid': {
        return <TokenIcon iconName={'valid'} size={16} />;
      }
      case 'not_valid': {
        return <TokenIcon iconName={'not-valid'} size={16} />;
      }
      case 'not_verified': {
        return <TokenIcon iconName={'not-verified-triangle'} size={16} />;
      }
      default:
        return null;
    }
  };

  const getNameGroup = (status: string) => {
    switch (status) {
      case 'valid': {
        return type === 'designInput' ? 'Valid' : 'Actual';
      }
      case 'not_valid': {
        return 'Outdated';
      }
      case 'void': {
        return 'Void';
      }
      case 'not_verified': {
        return 'Requiring verification';
      }
      case 'ignore': {
        return 'Ignore';
      }
      default:
        return 'No status';
    }
  };

  const render = () => {
    if (row?.groupId && column.key === 'selected_row') {
      const titleGroup = { ...row };
      titleGroup.value = getNameGroup(row.groupingValue);
      return <GroupingCell row={titleGroup} isHiddenKeyTitle />;
    }
    if (column.key === 'selected_row') {
      return <CheckBox checked={row?.isActive} onClick={e => handleClickCheckBox(row)} disabled={row.status === 'new_valid'} />;
    }
    if (column.key === 'status') {
      return getSetStatus(row.validity_status || '');
    }
    if (column.key === 'number') {
      return (
        <CustomTooltip title={row.number || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text`}>{row.number}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'sheet_title') {
      return (
        <CustomTooltip title={row.title || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text ${themeClass}_cell`}>{row.title}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'discipline') {
      return <div className={`${themeClass}_text`}>{row?.discipline}</div>;
    }
    if (column.key === 'revision') {
      return <ChipsRevisionTag processStatus={row.validity_status} value={`R${row.revision}`} />;
    }
    if (column.key === 'revision_deliverables') {
      return (
        <ChipsRevisionTag
          processStatus={row.process_status}
          value={`R${row.revision}`}
          clickHandler={() => handleOpenCard && handleOpenCard(row)}
          customClassName={`${themeClass}_chipsRevisionTagCursor`}
        />
      );
    }
    if (column.key === 'last_revision') {
      return <ChipsRevisionTag processStatus={'not_issued_yet'} value={`R${row.revision}`} />;
    }
    if (column.key === 'revision_status') {
      return <StatusRevision status={row.process_status} review_state={row.review_state} isVisibleLabel />;
    }
    if (column.key === 'current_set') {
      return <div className={`${themeClass}_text`}>{row?.current_set}</div>;
    }
    if (column.key === 'validity_status') {
      return getSetStatus(row.validity_status || '');
    }
    if (column.key === 'title') {
      return (
        <CustomTooltip title={row.title || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text -title`}>{row.title || ''}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'submittal') {
      const newRow = { ...row, sf_index: row.submittal };
      return <CardLinkButton type={'submittal'} size={'sm'} title={row.submittal} clickHandler={() => handleOpenCard(newRow)} />;
    }
    if (column.key === 'status_submittal') {
      return row?.status && <StatusSubmittal status={row?.status} reviewState={row?.review_state} />;
    }
    if (column.key === 'type_submittal') {
      return <ChipsRequestType type={row.type} />;
    }
    if (column.key === 'level') {
      return (
        <CustomTooltip title={row.level || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text`}>{row.level || ''}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'validation_alert') {
      return (
        <CustomTooltip title={row.validity_alert || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text ${themeClass}_cell`}>{row.validity_alert || ''}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'building') {
      return (
        <CustomTooltip title={row.building || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_text ${themeClass}_cell`}>{row.building || ''}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'building_title') {
      return <div className={`${themeClass}_text`}>{row.building?.title || ''}</div>;
    }
    if (column.key === 'nf') {
      const newRow = !row?.snippet ? { ...row, snippet: 'request' } : row;
      return (
        <CardLinkButton
          clickHandler={() => !row?.is_deactivated && handleOpenCard(newRow)}
          type={'nf'}
          size={'sm'}
          title={`NF ${row.nf}`}
        />
      );
    }
    if (column.key === 'subject') {
      return (
        <CustomTooltip title={row.specific || ''} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_cell`}>{row.specific || ''}</div>
        </CustomTooltip>
      );
    }
    if (column.key === 'lastStatus') {
      return (
        <div className={`${themeClass}_cell`}>
          <StatusNF status={row.lastStatus} />
        </div>
      );
    }
    if (column.key === 'incorporated') {
      return (
        <div className={`${themeClass}_incorporationIcon`}>
          {!!row.is_incorporated && (
            <OvalButton title={'Response incorporated'} type={'incorporated-to-locations'} clickHandler={null} showTitle={false} />
          )}
        </div>
      );
    }
    if (column.key === 'incorporated_to_locations') {
      return (
        <div className={`${themeClass}_incorporationIcon`}>
          {!!row.is_incorporated && <OvalButton title={''} type={'incorporated-to-locations'} clickHandler={null} showTitle={false} />}
        </div>
      );
    }
    if (column.key === 'revision_update_status') {
      const status: string = getTriggerTooltipText(row, !!row.is_incorporated) || '';

      return (
        <CustomTooltip title={status} placement={'top-start'} customBasisClass={`${themeClass}_cell`}>
          <div className={`${themeClass}_cell`}>{status}</div>
        </CustomTooltip>
      );
    }
  };

  return <>{render()}</>;
};

export default React.memo(BasicFormatter);
