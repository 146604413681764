import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KeyValueModel } from '../../../models/key-value.model';
import { IWithConfirmDialogProps, withConfirmDialog, withConfirmDialogContext } from '../HighOrderComponents';
import {
  ActivationTabType,
  FileModel,
  LocationCollectionModel,
  LoginUserModel,
  MfViewDataModel,
  PartyModel,
  RequestBuildingModel,
  RequestDisciplineModel,
  RequestFileModel,
  RequestModel,
  RequestTypeModel,
  RequestUserModel,
  SelectedRequestCompanyModel,
  SimpleTitleModel,
  TagFormModel,
  UfViewDataModel,
} from '../../../models';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import { MilestoneItemModel } from '../../../models/phasePlan/milestone.model';
import { commonSlice } from '../../../store/common/commonReducer';
import { breadcrumbsSlice } from '../../../store/breadcrumbs/breadcrumbsReducer';
import { projectSlice } from '../../../store/project/projectReducer';
import { documentsSlice } from '../../../store/documents/documentsReducer';
import {
  createNewDueDate,
  getFilteredRequest,
  getTags,
  requestSave,
  requestView,
  responseSave,
  setSyncProcore,
  singleRequestLoading,
  getSandBoxCardIDHotList,
} from '../../../store/request/requestLogic(HOLD)';
import { getListPCD } from '../../../store/pcd/pcdThunk';
import { getIncorporation, setGlobalIncorporation } from '../../../store/incorporation/incorporationThunk';
import { requestSlice } from '../../../store/request/requestReducer(HOLD)';
import { getProjectById } from '../../../store/phasePlan/phasePlanThunk';
import { setPromptsAlerts } from '../../../store/user/userThunk';
import { commentSlice } from '../../../store/comments/commentsReducer';
import { isEmptyObject } from '../../../helpers/commonHelpers';
import { NeedListRequest } from '../../../store/needlist/needListModels';
import AccessHelper from '../../../helpers/AccessHelper';
import { TeamApi } from '../../../service/Api/team/types';
import FiltersHelper from '../../../helpers/FiltersHelper';
import TopCardPart from './mainParts/TopCardPart/TopCardPart';
import Header from './mainParts/Header/Header';
import { getResponsePartySelect } from '../../../helpers/RequestHelper';
import { getDDLlistDocuments, getDDLlistDocumentsNoNF } from '../../../store/documents/documentsThunk';
import TabsContainer from './mainParts/TabsContainer/TabsContainer';
import BottomCardPart from './mainParts/BottomCardPart/BottomCardPart';
import { formatDateISO } from '../../../service/date';
import { PopupProfile } from '../../controls/PopupProfile/PopupProfile';
import moment from 'moment';
import Footer from './mainParts/Footer/Footer';
import HistoryAction from '../../controls/HistoryAction/HistoryAction';
import isEqual from 'lodash/isEqual';
import CardRequestGlobal from '../CardRequestGlobal/CardRequestGlobal';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import Timeout = NodeJS.Timeout;
import DialogPopUp from '../../controls/DialogPopUp/DialogPopUp';
import { CustomButton } from '../../controls/ButtonComponents';
import { CheckBox } from '../../controls';
import SystemButton from '../../controls/ButtonComponents/SystemButton/SystemButton';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import CustomTooltip from '../../controls/Tooltip/Tooltip';
import { ImpactModel } from '../../controls/ImpactSelect/ImpactSelect';
import { RouteComponentProps, withRouter } from '../../../navigation/withRouter/withRouter';
import { CodeSelector } from '../../controls/Dropdowns/DropdownСomponents';
import { DropdownItemModel } from '../../../models/global';
import { Select } from '../../controls/Dropdowns/DropdownСomponents';
import ChipsRequestType from '../../controls/Chips/ChipsRequestType/ChipsRequestType';
import TokenIcon from '../../controls/TokenIcon/TokenIcon';
import { getSubmittalTags, submittalSave } from '../../../store/submittal/submittalThunk';
import DateField from '../../controls/DateField/DateField';
import Popover from '@material-ui/core/Popover';
import CustomCalendar from '../../controls/CustomCalendar/CustomCalendar';
import PartySelector from '../../controls/PartySelector/PartySelector';
import { ISubmittalUpdateModel } from '../../../models/submittals/submittals.model';

import './CardRequestStyles.scss';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

const { openPdftron, handleResizableCardPart, setChangeActiveTabFromPdfTron } = commonSlice.actions;
const { setBreadcrumbs, clearBreadcrumbs } = breadcrumbsSlice.actions;
const { projectLoading } = projectSlice.actions;
const { clearDocumentsNF, clearListDocuments } = documentsSlice.actions;
const {
  setCommitmentAction,
  setActiveSandBoxAlertSnippetActions,
  resetSocketRequestProcoreState,
  resetSocketRequestWatchlistState,
  resetSocketRequestPrivateState,
} = requestSlice.actions;
const { handleSaveResponseCommentId } = commentSlice.actions;

interface SelectedFiltersModel {
  filters: KeyValueModel<any>;
  searches: KeyValueModel<string>;
}

interface DropdownItemModelExt extends DropdownItemModel {
  code?: string;
  alias?: string;
}

type dataTypeModel = {
  id: number;
  title: string;
  alias: string;
  value?: number;
  position?: number;
};

let timer: Timeout;

export interface IInitialFields {
  requestCurrentDescription: string;
  buildings: number[];
  building_spaces: number[];
  levels: LocationCollectionModel[];
  typicalLayouts: any[];
  currentProjectSpecific: string;
  currentDate: string | Date;
  hashtags: TagFormModel[];
  is_procore_sync_needed: boolean | number;
  tags: RequestTypeModel[];
  milestoneSelected: MilestoneItemModel;
  milestone_id: number;
  currentUfcCode: UfViewDataModel[];
  currentMfCode: MfViewDataModel[];
  isRfi: boolean;
  add_documents_nf: number[];
  selectedRequestCompany: SelectedRequestCompanyModel | null;
  selectedResponseCompany: SelectedRequestCompanyModel | null;
  currentPCD: number[];
  cl_impacts: ImpactModel[];
  submittal_input: number[];
  submittal_output: number[];
}

interface IProps extends IWithConfirmDialogProps {
  selectRequestId: number | null;
  selectRequestNf?: number;
  isSandBox?: boolean;
  selectedFilters?: SelectedFiltersModel;
  handleSelectedRequest?: (requestId: number, nf?: number, startDate?: string) => void;
  startDate?: string;
  endDate?: string;
  urlPageForRequestFilter?: string;
  closeRequest: () => void;
  isCreateRequest?: boolean;
  requestClickOutSide: boolean;
  closeRequestClickOutSide: () => void;
  escapeKeyDown: boolean;
  closeRequestEscapeKeyDown: () => void;
  previewCard?: boolean;
  isDragRequestCard?: boolean;
  cancelLastNfRequest?: () => void;
  isSeparateTab?: boolean;
  isUrlNfCardTab?: boolean;
  isPullRequest?: boolean;
  add_documents_nf?: number[];
  login_user: LoginUserModel;
  handleShowConfirmDialog?: (options) => void;
  predecessorIdProps: number | null;
  parentSelectedRequest: RequestModel;
  isPullRequestOpenByAction?: boolean;
}

interface IState {
  isPullRequestDataToCopyFormOriginal: boolean;
  tags: RequestTypeModel;
  previewIsOpen: boolean;
  selectedRequest: RequestModel & NeedListRequest;
  selectedRequestId: number | boolean;
  id: number;
  handleChangedDate: string;
  isChangedNow: boolean;
  loginUser: LoginUserModel;
  selectedFiltersStatus: SelectedFiltersModel;
  pullRequestOpen: boolean;
  originalRequestId: number;
  // Open\close request card elements
  predecessorId: number;
  requestIsOpen: boolean;
  fileLoading: boolean;
  buildings: RequestBuildingModel[];
  building_spaces: SimpleTitleModel[];
  projectDisciplines: RequestDisciplineModel[];
  levels: LocationCollectionModel[];
  typicalLayouts: any[];
  projectParties: PartyModel[];
  anchorEl: HTMLElement | null;
  milestones: MilestoneItemModel[];
  animationBtn: string;
  promptName: string;
  promptTitle: string;
  promptMessage: string;
  promptNameBtnSave: string;
  promptNameBtnCancel: string;
  promptNameBtnOk: string;
  is_promptPopUpView: boolean;
  is_promptPopUpPull: boolean;
  is_promptBtnOk: boolean;
  is_msg_not_show_again: boolean;
  is_show_again: boolean;
  is_promptPopUpClose: boolean;
  canUpdateSaveBtn: boolean;
  activeTab: ActivationTabType;
  isOpenDeclinePopup: boolean;
  isOpenCalendar: React.RefObject<any> | null;
  isOpenChangeResponseCompany: React.RefObject<any> | null;
  headerCount: KeyValueModel<number> | null;
  isUpdateUserProfile: boolean;
  isHandleSelectDraw: boolean;
  isUpdateRequest: boolean;
  files: FileModel[] | FileList | any;
  errors: any;
  isHiddenFields: boolean;
  requestCurrentDescription: string;
  isRequestCurrentDescription: boolean;
  listPCD: any[];
  isProcorConfirmPopUpOpen: boolean;
  isOpenAuthorUser: boolean;
  authorCompanyId: number;
  isViewProfile: boolean;
  selectedAuthor: RequestUserModel;
  isHashTagChanging: boolean;
  canUpdateTopCard: boolean;
  canUpdateDescription: boolean;
  isCreateRequestState: boolean;
  isCreatePullRequest: boolean;
  activeRelatedItem: string;
}

const NfCardTabs = ['discussion', 'related-items', 'response', 'incorporation', 'request'];

function CardRequest({
  isDragRequestCard,
  selectRequestId,
  selectRequestNf,
  isSandBox,
  selectedFilters,
  handleSelectedRequest,
  startDate,
  endDate,
  urlPageForRequestFilter,
  closeRequest,
  isCreateRequest,
  requestClickOutSide,
  closeRequestClickOutSide,
  escapeKeyDown,
  closeRequestEscapeKeyDown,
  previewCard,
  cancelLastNfRequest,
  isSeparateTab,
  isUrlNfCardTab,
  isPullRequest,
  login_user,
  handleShowConfirmDialog,
  predecessorIdProps,
  parentSelectedRequest,
  isPullRequestOpenByAction = false,
}: IProps) {
  const dispatch = useAppDispatch();
  const { urlProject, location, searchParams } = useRouter();
  const { setUrlCommandCenter, setUrlNF, getNfParamsFromURL, removeUrlPreviewNF } = useRoutingHelper();
  const themeClass = useGetThemeClass('b-cardRequest');
  const { isMobile, is1440Resolution } = GetResolution();

  const loadRequestPromise = useRef(null);
  const getFilteredRequestPromise = useRef(null);
  const bodyContainerRef = useRef(null);

  const partiesCollection = useAppSelector(state => state.projectReducer.projectInfo.parties);
  const levelsCollection = useAppSelector(state => state.projectReducer.projectInfo.levels);
  const typicalLayoutsCollection = useAppSelector(state => state.projectReducer.projectInfo.typical_layouts);
  const locationsCollection = useAppSelector(state => state.projectReducer.projectInfo.locations);
  const buildingsCollection = useAppSelector(state => state.projectReducer.projectInfo.buildings);
  const buildingSpacesCollection = useAppSelector(state => state.projectReducer.projectInfo.building_spaces);
  const requestInfo = useAppSelector(state => state.requestReducer.requestInfo);
  const requestTags = useAppSelector(state => state.requestReducer.tags);
  const userInfo = useAppSelector(state => state.userReducer.userInfo);
  const requestStatus = useAppSelector(state => state.requestReducer.saveRequestStatus);
  const socketPrivateState = useAppSelector(state => state.requestReducer.socketPrivateState);
  const socketWatchlistState = useAppSelector(state => state.requestReducer.socketWatchlistState);
  const socketProcoreState = useAppSelector(state => state.requestReducer.socketProcoreState);
  const socketHotlistState = useAppSelector(state => state.requestReducer.socketHotlistState);
  const active_project_id = useAppSelector(state => state.userReducer.active_project_id);
  const singleRequestLoaded = useAppSelector(state => state.requestReducer.singleRequestLoaded);
  const projectInfo = useAppSelector(state => state.projectReducer.projectInfo);
  const getRequestLoadingStatus = useAppSelector(state => state.requestReducer.getRequestLoadingStatus);
  const requestLoading = useAppSelector(state => state.requestReducer.requestLoading);
  const incorporationList = useAppSelector(state => state.incorporationReducer.incorporationList);
  const breadcrumbs = useAppSelector(state =>
    previewCard ? state.breadcrumbsReducer.previewCardBreadcrumbs : state.breadcrumbsReducer.firstCardBreadcrumbs,
  );
  const isUpdatePartyUsers = useAppSelector(state => state.team.isUpdatePartyUsers);
  const incorporationStatus = useAppSelector(state => state.incorporationReducer.incorporationStatus);
  const project = useAppSelector(state => state.phasePlan.projectData);
  const pcdInfoLoading = useAppSelector(state => state.pcdReducer.pcdInfoLoading);
  const list_documents_loading = useAppSelector(state => state.documentsReducer.list_documents_loading);
  const activeCommitmentAction = useAppSelector(state => state.requestReducer.activeCommitmentAction);
  const newNfCardSubmittalInfo = useAppSelector(state => state.requestReducer.newNfCardSubmittalInfo);
  const sandBoxActiveAlertSnippetActions = useAppSelector(state => state.requestReducer.sandBoxActiveAlertSnippetActions);
  const changeActiveTabFromPdfTron = useAppSelector(state => state.commonReducer.changeActiveTabFromPdfTron);
  const requestCardErrors = useAppSelector(state => state.requestReducer.requestCardErrors);
  const add_documents_nf = useAppSelector(state => state.documentsReducer.add_documents_nf);
  const original_doc_id = useAppSelector(state => state.documentsReducer.original_doc_id);
  const listDocuments = useAppSelector(state => state.documentsReducer.list_documents);
  const list_documents_no_nf = useAppSelector(state => state.documentsReducer.list_documents_no_nf);
  const set_status_nf_loading = useAppSelector(state => state.documentsReducer.set_status_nf_loading);
  const feedData = useAppSelector(state => state.commentReducer.feed.feedData);
  const { relatedTabData } = useAppSelector(state => state.relatedItemsSlice);
  const incorporationHistory = useAppSelector(state => state.commentReducer.feed.incorporation_history);
  const isGlobalIncorporated = useAppSelector(state => state.incorporationReducer.isGlobalIncorporated);
  const isLoadingRequestByNf = useAppSelector(state => state.requestReducer.isLoadingRequestByNf);
  const { availableTags } = useAppSelector(state => state.submittalSlice);
  const projectData = useAppSelector(state => state.phasePlan.projectData);

  const [isPullRequestDataToCopyFormOriginal, setIsPullRequestDataToCopyFormOriginal] =
    useState<IState['isPullRequestDataToCopyFormOriginal']>(false);
  const [selectedRequest, setSelectedRequest] = useState<IState['selectedRequest']>(null);
  const [selectedRequestId, setSelectedRequestId] = useState<IState['selectedRequestId']>(null);
  const [id, setId] = useState<IState['id']>(null);
  const [handleChangedDate, setHandleChangedDate] = useState<IState['handleChangedDate']>('');
  const [isChangedNow, setIsChangedNow] = useState<IState['isChangedNow']>(false);
  const [loginUser, setLoginUser] = useState<IState['loginUser']>({} as LoginUserModel);
  const [selectedFiltersStatus, setSelectedFiltersStatus] = useState<IState['selectedFiltersStatus']>(null);
  const [tags_selected, setTags_selected] = useState<IState['tags']>(null);
  const [pullRequestOpen, setPullRequestOpen] = useState<IState['pullRequestOpen']>(false);
  const [originalRequestId, setOriginalRequestId] = useState<IState['originalRequestId']>(null);
  const [predecessorId, setPredecessorId] = useState<IState['predecessorId']>(predecessorIdProps || null);
  const [requestIsOpen, setRequestIsOpen] = useState<IState['requestIsOpen']>(null);
  const [buildings, setBuildings] = useState<IState['buildings']>([]);
  const [levels, setLevels] = useState<IState['levels']>([]);
  const [typicalLayouts, setTypicalLayouts] = useState<IState['typicalLayouts']>([]);
  const [buildingSpaces, setBuildingSpacesProps] = useState<IState['building_spaces']>([]);
  const [projectDisciplines, setProjectDisciplines] = useState<IState['projectDisciplines']>([]);
  const [projectParties, setProjectParties] = useState<IState['projectParties']>([]);
  const [milestones, setMilestones] = useState<IState['milestones']>([]);
  const [animationBtn, setAnimationBtn] = useState<IState['animationBtn']>(null);
  const [promptName, setPromptName] = useState<IState['promptName']>(null);
  const [promptTitle, setPromptTitle] = useState<IState['promptTitle']>(null);
  const [promptMessage, setPromptMessage] = useState<IState['promptMessage']>(null);
  const [promptNameBtnSave, setPromptNameBtnSave] = useState<IState['promptNameBtnSave']>(null);
  const [promptNameBtnCancel, setPromptNameBtnCancel] = useState<IState['promptNameBtnCancel']>(null);
  const [promptNameBtnOk, setPromptNameBtnOk] = useState<IState['promptNameBtnOk']>(null);
  const [is_promptPopUpView, setIsPromptPopUpView] = useState<IState['is_promptPopUpView']>(false);
  const [is_promptPopUpPull, setIs_promptPopUpPull] = useState<IState['is_promptPopUpPull']>(false);
  const [is_promptBtnOk, setIsPromptBtnOk] = useState<IState['is_promptBtnOk']>(false);
  const [is_msg_not_show_again, setIsMsgNotShowAgain] = useState<IState['is_msg_not_show_again']>(false);
  const [is_show_again, setIsShowAgain] = useState<IState['is_show_again']>(false);
  const [is_promptPopUpClose, setIsPromptPopUpClose] = useState<IState['is_promptPopUpClose']>(false);
  const [canUpdateSaveBtn, setCanUpdateSaveBtn] = useState<IState['canUpdateSaveBtn']>(false);
  const [activeTab, setActiveTab] = useState<IState['activeTab']>('request');
  const [activeRelatedItem, setActiveRelatedItem] = useState<IState['activeRelatedItem']>('');
  const [isOpenDeclinePopup, setIsOpenDeclinePopup] = useState<IState['isOpenDeclinePopup']>(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState<IState['isOpenCalendar']>(null);
  const [isOpenChangeResponseCompany, setIsOpenChangeResponseCompany] = useState<IState['isOpenChangeResponseCompany']>(null);
  const [isUpdateUserProfile, setIsUpdateUserProfile] = useState<IState['isUpdateUserProfile']>(false);
  const [isHandleSelectDraw, setIsHandleSelectDraw] = useState<IState['isHandleSelectDraw']>(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState<IState['isUpdateRequest']>(false);
  const [isHiddenFields, setIsHiddenFields] = useState<IState['isHiddenFields']>(false);
  const [listPCD, setListPCD] = useState<IState['listPCD']>([]);
  const [isProcorConfirmPopUpOpen, setIsProcorConfirmPopUpOpen] = useState<IState['isProcorConfirmPopUpOpen']>(false);
  const [isOpenAuthorUser, setIsOpenAuthorUser] = useState<IState['isOpenAuthorUser']>(false);
  const [coordinatesAuthor, setCoordinatesAuthor] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState<IState['selectedAuthor']>(null);
  const [isViewProfile, setIsViewProfile] = useState<IState['isViewProfile']>(false);
  const [isPrivateChanged, setIsPrivateChanged] = useState<boolean>(false);
  const [isOpenCommitSubmittalPopup, setIsOpenCommitSubmittalPopup] = useState<boolean>(false);
  const [mf_selected, setMf_selected] = useState<MfViewDataModel>({ id: 820, code: '03.30.00', title: 'Cast-in-Place Concrete' });
  const [tagsSubmittal_selected, setTagsSubmittal_selected] = useState<dataTypeModel>({
    id: 7,
    title: 'Product Data',
    alias: 'product_data',
  });
  const [submit_due_date_selected, setSubmit_due_date_selected] = useState<string>(null);
  const [openedCalendarType, setOpenedCalendarType] = useState<boolean>(false);
  const [responsible_contractor_selected, setResponsible_contractor_selected] = useState<SelectedRequestCompanyModel>({
    discipline: null,
    company: null,
    user: null,
  });

  const [isHashTagChanging, setIsHashTagChanging] = useState<IState['isHashTagChanging']>(false);
  const [canUpdateTopCard, setCanUpdateTopCard] = useState<IState['canUpdateTopCard']>(false);
  const [canUpdateDescription, setCanUpdateDescription] = useState<IState['canUpdateDescription']>(false);
  const [isCreateRequestState, setIsCreateRequestState] = useState<IState['isCreateRequestState']>(false);
  const [isCreatePullRequest, setIsCreatePullRequest] = useState<IState['isCreateRequestState']>(false);
  const [requestCurrentDescription_selected, setRequestCurrentDescription_selected] = useState<IState['requestCurrentDescription']>('');
  const [isRequestCurrentDescription, setIsRequestCurrentDescription] = useState<IState['isRequestCurrentDescription']>(false);
  const [prevRequestLoading, setPrevRequestLoading] = useState(false);
  const [prevGetRequestLoadingStatus, setPrevGetRequestLoadingStatus] = useState(false);
  const [prevActiveCommitmentAction, setPrevActiveCommitmentAction] = useState('');
  const [isAllNotificationPopupsClosed, setIsAllNotificationPopupsClosed] = useState(false);
  const [isDeliverablesPopUpOpened, setIsDeliverablesPopUpOpened] = useState(false);
  const [isOpenGlobalIncorporation, setIsOpenGlobalIncorporation] = useState<boolean>(false);
  const [isIgnoreDDLinGlobalIncorporation, setIsIgnoreDDLinGlobalIncorporation] = useState<boolean>(false);

  // states set in initialFields function

  const [errors, setErrors] = useState<IState['errors']>({} as any);

  // initialFields states
  const [initialFields, setInitialFields] = useState<IInitialFields>(undefined);
  const [buildings_selected, setBuildings_selected] = useState<IInitialFields['buildings']>([]);
  const [levels_selected, setLevels_selected] = useState<IInitialFields['levels']>([]);
  const [typicalLayouts_selected, setTypicalLayouts_selected] = useState<IInitialFields['typicalLayouts']>([]);
  const [building_spaces_selected, setBuilding_spaces_selected] = useState<IInitialFields['building_spaces']>([]);
  const [currentProjectSpecific_selected, setCurrentProjectSpecific_selected] = useState<IInitialFields['currentProjectSpecific']>('');
  const [currentDate_selected, setCurrentDate_selected] = useState<IInitialFields['currentDate']>(null);
  const [hashtags_selected, setHashtags_selected] = useState<IInitialFields['hashtags']>([] as TagFormModel[]);
  const [is_procore_sync_needed_selected, setIs_procore_sync_needed_selected] = useState<IInitialFields['is_procore_sync_needed']>(0);
  const [tags, setTags] = useState<IInitialFields['tags']>([] as RequestTypeModel[]);
  const [milestone_id_selected, setMilestone_id_selected] = useState<IInitialFields['milestone_id']>(null);
  const [milestoneSelected_selected, setMilestoneSelected_selected] = useState<IInitialFields['milestoneSelected']>(null);
  const [currentUfcCode_selected, setCurrentUfcCode_selected] = useState<IInitialFields['currentUfcCode']>(null);
  const [currentMfCode_selected, setCurrentMfCode_selected] = useState<IInitialFields['currentMfCode']>(null);
  const [isRfi_selected, setIsRfi_selected] = useState<IInitialFields['isRfi']>(false);
  const [add_documents_nf_selected, setAdd_documents_nf_selected] = useState<IInitialFields['add_documents_nf']>([]);
  const [selectedRequestCompany_selected, setSelectedRequestCompany_selected] = useState<IInitialFields['selectedRequestCompany']>({
    discipline: null,
    company: null,
    user: null,
  });
  const [selectedResponseCompany_selected, setSelectedResponseCompany_selected] = useState<IInitialFields['selectedResponseCompany']>({
    discipline: null,
    company: null,
    user: null,
  });
  const [cl_impacts_selected, setCl_impacts_selected] = useState<IInitialFields['cl_impacts']>([]);
  const [currentPCD_selected, setCurrentPCD_selected] = useState<IInitialFields['currentPCD']>([]);
  // submittal Dropdown
  const [submittal_input_selected, setSubmittal_input_selected] = useState<number[]>([]);
  const [submittal_output_selected, setSubmittal_output_selected] = useState<number[]>([]);

  const [updatedResponseCompany, setUpdatedResponseCompany] = useState(null);
  const [requestWatchers, setRequestWatchers] = useState(null);
  const [requestWatchersGroups, setRequestWatcherGroups] = useState(null);

  const submitDateRef = useRef(null);

  const themeClassPaper = useGetThemeClass('b-dropdownSelectMenuPaper');
  const themeClassCallout = useGetThemeClass('b-modalCallout');
  const themeClassSubmittalPopup = useGetThemeClass('b-commitSubmittalPopup');

  const isSkeletonLoading = useMemo(() => {
    const params = getNfParamsFromURL();
    return isLoadingRequestByNf || (!previewCard && !isCreateRequest && !isPullRequest && selectedRequest?.nf !== params.nf);
  }, [isLoadingRequestByNf, previewCard, selectedRequest?.nf]);

  useEffect(() => {
    const nf = searchParams.get('nf');
    const nfTab = searchParams.get('tab');

    if (nf) {
      if (NfCardTabs.includes(nfTab)) {
        setActiveTab(nfTab as ActivationTabType);
      } else {
        setActiveTab('request');
        setUrlNF(String(nf));
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getSubmittalTags());
  }, []);

  useEffect(() => {
    if (isMobile && !isHiddenFields) {
      setTimeout(() => {
        setIsHiddenFields(true);
      }, 700);
    }
  }, [isMobile]);

  useEffect(() => {
    if (currentMfCode_selected?.length) {
      setMf_selected(currentMfCode_selected[0]);
    } else {
      setMf_selected(null);
    }
  }, [currentMfCode_selected]);

  useEffect(() => {
    const nfTab = searchParams.get('tab');
    const nfActiveRelatedItem = searchParams.get('active-related-item');

    if (NfCardTabs.includes(nfTab)) {
      setActiveTab(nfTab as ActivationTabType);
      if (nfActiveRelatedItem) {
        setActiveRelatedItem(nfActiveRelatedItem);
      } else {
        setActiveRelatedItem('');
      }
    } else {
      setActiveTab('request');
      setActiveRelatedItem('');
    }
  }, [location]);

  useEffect(() => {
    if (isCreateRequest) {
      setActiveTab('request');
    }
  }, []);

  useEffect(() => {
    if (newNfCardSubmittalInfo.submittalId) {
      setTags_selected(tags.find(tag => tag.alias === (newNfCardSubmittalInfo.is_generate_nf_rfi ? 'planit_rfi' : 'input_checklist')));
    }
  }, [isCreateRequest, newNfCardSubmittalInfo.submittalId, tags]);

  useEffect(() => {
    dispatch(getTags());
    initDataFromProject();

    if (selectRequestId && !isSandBox && !isCreateRequest) {
      if (selectedFilters) {
        selectedFilteredRequest(selectRequestId, selectedFilters);
      } else {
        selectRequestHandler(selectRequestId);
      }
    }

    setSelectedRequestId(selectRequestId);
    setLoginUser({
      id: +userInfo.id,
      role: userInfo.roles,
      parties: userInfo.parties,
    });

    if (active_project_id && isEmptyObject(project)) {
      dispatch(getProjectById(+active_project_id));
    }

    sessionStorage.setItem('isRq', String(selectRequestId));
  }, []);

  useEffect(() => {
    initRequestField();
  }, [selectedRequest]);

  useEffect(() => {
    if (socketPrivateState.changedNow) {
      setIsRfi_selected(!!socketPrivateState.is_private);
      dispatch(resetSocketRequestPrivateState());
    }
  }, [socketPrivateState.changedNow]);

  useEffect(() => {
    if (socketWatchlistState.changedNow) {
      socketWatchlistState.watchers && setRequestWatchers(socketWatchlistState.watchers);
      socketWatchlistState.watcher_groups && setRequestWatcherGroups(socketWatchlistState.watcher_groups);
      dispatch(resetSocketRequestWatchlistState());
    }
  }, [socketWatchlistState.changedNow]);

  useEffect(() => {
    if (socketProcoreState.changedNow) {
      setIs_procore_sync_needed_selected(socketProcoreState.is_procore_sync_needed);
      dispatch(resetSocketRequestProcoreState());
    }
  }, [socketProcoreState.changedNow]);

  useEffect(() => {
    if (selectedRequest?.id) {
      dispatch(getSandBoxCardIDHotList({ related_to: 'user', entity: 'request', check_ids: [selectedRequest.id] }));
    }
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (selectedRequest) {
      setRequestWatchers(selectedRequest.watchers);
      setRequestWatcherGroups(selectedRequest.watcher_groups);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (active_project_id) {
      dispatch(
        getListPCD({
          project_id: Number(active_project_id),
          callback: res => {
            setListPCD(res.buildings);
          },
        }),
      );
    }
  }, [active_project_id]);

  useEffect(() => {
    if (sandBoxActiveAlertSnippetActions && sandBoxActiveAlertSnippetActions.request_tab) {
      if (sandBoxActiveAlertSnippetActions.request_tab === 'discussion' && activeTab !== 'discussion') {
        onChangeActiveTab('discussion');
      }

      dispatch(setActiveSandBoxAlertSnippetActions(null));
    }
  }, [sandBoxActiveAlertSnippetActions]);

  useEffect(() => {
    if (activeCommitmentAction) {
      if (activeCommitmentAction === 'provide_response') {
        onChangeActiveTab('response');
      }

      if (activeCommitmentAction === 'commit_with_pull_request') {
        relCommitment(selectedRequest?.id);
        setPrevActiveCommitmentAction('');
      }

      if (
        (!activeCommitmentAction &&
          prevActiveCommitmentAction === 'commit_with_pull_request' &&
          selectedRequest.commit_action?.action === 'pull_request') ||
        activeCommitmentAction === 'provide_response'
      ) {
        onChangeActiveTab('response');
      }

      if (activeCommitmentAction === 'commit_now') {
        handleShowConfirmDialog({
          title: 'Commit now?',
          message: 'Are you sure you want to commit now?',
          onAllow: () => commitNow(),
          onCancel: () => dispatch(setCommitmentAction('')),
          shouldNotValidate: true,
          needUpdateState: false,
        });
      }

      if (activeCommitmentAction === 'commit_with_submittal_resolution') {
        if (currentMfCode_selected?.length) {
          setMf_selected(currentMfCode_selected[0]);
        } else {
          setMf_selected(null);
        }
        setTagsSubmittal_selected({
          id: 7,
          title: 'Product Data',
          alias: 'product_data',
        });
        setSubmit_due_date_selected(null);
        setResponsible_contractor_selected({
          discipline: null,
          company: null,
          user: null,
        });
        setIsOpenCommitSubmittalPopup(true);
      }

      setPrevActiveCommitmentAction(activeCommitmentAction);
    }
  }, [activeCommitmentAction]);

  const commitNow = () => {
    const selectedRequest = requestInfo.data[selectRequestId];
    const data = { status: 'submitted', project_id: selectedRequest.project_id };

    dispatch(
      responseSave({
        requestId: selectedRequest.id,
        bodyParams: data,
        callback: () => {
          dispatch(setCommitmentAction(''));
          onChangeActiveTab('response');
        },
        isUpdateStatus: true,
      }),
    );
  };

  useEffect(() => {
    if (promptName === 'acceptedBtn' && is_promptBtnOk) {
      handleRequestComplete();
    }
  }, [promptName, is_promptBtnOk]);

  useEffect(() => {
    if (requestClickOutSide) {
      if (selectRequestId && canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('requestClickOutSide') !== 'yes');
        setPromptTitle('Discard changes?');
        setPromptMessage('Leaving this Need Form will discard any unsaved changes.');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, discard');
        setPromptName('requestClickOutSide');
        setIsShowAgain(true);
        setIsMsgNotShowAgain(false);
        setPromptNameBtnSave(null);

        if (localStorage.getItem('requestClickOutSide') === 'yes') {
          handleCloseRequest(requestClickOutSide);
        }

        if (localStorage.getItem('requestClickOutSide') !== 'no') {
          dispatch(clearDocumentsNF());
        }
      } else if (canUpdateSaveBtn) {
        setIsPromptBtnOk(false);
        setIsPromptPopUpClose(localStorage.getItem('requestClickOutSide') !== 'yes');
        setPromptTitle('Quit NF creation?');
        setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
        setPromptNameBtnSave('Save draft');
        setPromptNameBtnCancel('Keep editing');
        setPromptNameBtnOk('Yes, quit');
        setPromptName('requestClickOutSide');
        setIsShowAgain(true);
        setIsMsgNotShowAgain(false);

        if (localStorage.getItem('requestClickOutSide') === 'yes') {
          handleCloseRequest(requestClickOutSide);
        }
      }

      if (!canUpdateSaveBtn) {
        handleCloseRequest(requestClickOutSide);
      }

      closeRequestClickOutSide();
    }
  }, [requestClickOutSide, selectRequestId, canUpdateSaveBtn]);

  useEffect(() => {
    if (escapeKeyDown) {
      closeRequestEscapeKeyDown();
      requestHeadClose();
    }
  }, [escapeKeyDown]);

  useEffect(() => {
    if (
      (!requestLoading && requestLoading !== prevRequestLoading) ||
      (!getRequestLoadingStatus && getRequestLoadingStatus !== prevGetRequestLoadingStatus)
    ) {
      changeAnimationBtn('');
    }

    setPrevRequestLoading(requestLoading);
    setPrevGetRequestLoadingStatus(getRequestLoadingStatus);
  }, [requestLoading, getRequestLoadingStatus]);

  useEffect(() => {
    if (selectedRequest) {
      openPDFTronByParams(selectedRequest);
    }
  }, [selectedRequest]);

  useEffect(() => {
    setSelectedRequestId(selectRequestId);
    setTags_selected(null);
  }, [selectRequestId]);

  useEffect(() => {
    if (userInfo) {
      setLoginUser({
        id: +userInfo.id,
        role: userInfo.roles,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (requestStatus) {
      setPullRequestOpen(false);
    }
  }, [requestStatus]);

  useEffect(() => {
    dispatch(projectLoading());
    getPartiesLocations();
  }, [active_project_id]);

  useEffect(() => {
    if (requestInfo?.data?.[selectRequestId]?.response_party_id === selectedResponseCompany_selected?.company?.id) {
      requestInfo?.data?.[selectRequestId] && setUpdatedResponseCompany(getResponsePartySelect(requestInfo.data[selectRequestId]));
    }
  }, [requestInfo.data]);

  useEffect(() => {
    selectedRequest && setUpdatedResponseCompany(getResponsePartySelect(selectedRequest));
  }, [selectedRequest]);

  useEffect(() => {
    if (
      partiesCollection &&
      partiesCollection[active_project_id] &&
      ((!selectedRequest && requestInfo?.data?.[selectRequestId]) || selectedRequest !== requestInfo?.data?.[selectRequestId]) &&
      requestInfo.data &&
      !isPullRequest &&
      !socketPrivateState.changedNow &&
      !socketWatchlistState.changedNow &&
      !socketProcoreState.changedNow &&
      !socketHotlistState.changedNow
    ) {
      if (selectedRequest && requestInfo?.data[selectRequestId] && isChangedNow) {
        setHandleChangedDate((requestInfo.data[selectRequestId] && requestInfo.data[selectRequestId]?.due_date) || '');
      } else {
        setSelectedRequest(requestInfo.data[selectRequestId]);
        const selectedRequestType = tags ? tags[0] : selectedRequest?.tags[0] || null;
        setTags_selected(selectedRequestType);
        setHandleChangedDate('');
      }
    }
  }, [partiesCollection, requestInfo.data, selectRequestId, selectedRequest, active_project_id, requestTags]);

  useEffect(() => {
    if (requestTags && requestTags?.length) {
      const tagsWithOutCLDrawing = requestTags.length ? requestTags.filter(f => f.alias !== 'cl_rfi') : [];
      setTags(tagsWithOutCLDrawing);
    } else {
      if (!requestInfo.data && requestTags.length) {
        const tagsWithOutCLDrawing = requestTags.length ? requestTags.filter(f => f.alias !== 'cl_rfi') : [];
        setTags(tagsWithOutCLDrawing);
      }
    }
  }, [requestTags]);

  useEffect(() => {
    if (
      selectRequestId &&
      selectedRequest?.id !== selectRequestId &&
      requestInfo?.data &&
      requestInfo?.data[selectRequestId] &&
      !isPullRequest &&
      !socketPrivateState.changedNow &&
      !socketWatchlistState.changedNow &&
      !socketProcoreState.changedNow &&
      !socketHotlistState.changedNow
    ) {
      openPDFTronByParams(requestInfo.data[selectRequestId]);
      setSelectedRequest(requestInfo.data[selectRequestId]);
      setTags_selected(null);

      if (handleSelectedRequest) {
        handleSelectedRequest(requestInfo.data[selectRequestId].id, (previewCard && selectRequestNf) || null, startDate);
      }
    }
  }, [requestInfo?.data, selectRequestId, selectedRequest]);

  useEffect(() => {
    if (
      requestInfo?.data &&
      requestInfo?.data[selectRequestId] &&
      singleRequestLoaded &&
      requestInfo?.data?.[selectRequestId] !== selectedRequest &&
      isUpdateUserProfile &&
      !isPullRequest &&
      !socketPrivateState.changedNow &&
      !socketWatchlistState.changedNow &&
      !socketProcoreState.changedNow &&
      !socketHotlistState.changedNow
    ) {
      setSelectedRequest(requestInfo.data[selectRequestId]);
      setTags_selected(null);
      setIsUpdateUserProfile(false);
    }
  }, [requestInfo?.data, selectRequestId, singleRequestLoaded, isUpdateUserProfile, selectedRequest]);

  useEffect(() => {
    if (projectInfo) {
      initDataFromProject();
    }
  }, [projectInfo]);

  useEffect(() => {
    if (isUpdatePartyUsers) {
      openRequest(selectRequestId);
      setIsUpdateUserProfile(true);
    }
  }, [isUpdatePartyUsers]);

  useEffect(() => {
    if (active_project_id) {
      dispatch(getProjectById(+active_project_id));
    }
  }, [active_project_id]);

  useEffect(() => {
    if (changeActiveTabFromPdfTron) {
      onChangeActiveTab('response');
      dispatch(setChangeActiveTabFromPdfTron());
    }
  }, [changeActiveTabFromPdfTron]);

  useEffect(() => {
    if (userInfo && (isCreateRequest || isPullRequest)) {
      initFields();
    }
  }, [userInfo, isCreateRequest]);

  useEffect(() => {
    setErrors({
      ...errors,
      ...requestCardErrors,
    });
  }, [requestCardErrors]);

  useEffect(() => {
    if (isCreateRequest) {
      if (userInfo?.user_parties) {
        let selectedRequestCompany;
        const currentProject = userInfo?.projects?.find(project => project.code == urlProject[0]) || selectedRequest?.project;
        if (userInfo?.user_parties?.length && projectInfo?.parties && currentProject && Object.values(projectInfo?.parties).length) {
          const projectPartyIds = projectInfo?.parties[currentProject.id]?.map(c => c.id) || [];
          const userCompany = userInfo.user_parties.find(c => projectPartyIds.includes(c.id));
          const parties = projectInfo?.parties[currentProject.id]?.find(f => f.id === userCompany?.id);
          selectedRequestCompany = {
            discipline: parties?.project_disciplines[0]
              ? {
                  id: parties?.project_disciplines[0].id,
                  title: parties?.project_disciplines[0].title,
                  text_color: parties?.project_disciplines[0].text_color,
                }
              : null,
            company: userCompany
              ? {
                  ...userCompany,
                  id: userCompany.id,
                  title: userCompany.company,
                }
              : null,
            user: {
              ...userInfo,
              id: userInfo.id,
              title: `${userInfo?.first_name} ${userInfo?.last_name}`,
            },
          };
        }

        setInitialFields(prevState => ({
          ...prevState,
          selectedRequestCompany: selectedRequestCompany,
        }));
        setSelectedRequestCompany_selected(selectedRequestCompany);
      }
    }
  }, [projectInfo.parties, isCreateRequest]);

  useEffect(() => {
    if (add_documents_nf?.join('') !== original_doc_id?.join('')) {
      setAdd_documents_nf_selected(add_documents_nf);
      setIsHandleSelectDraw(add_documents_nf.join('') !== original_doc_id.join(''));
    } else {
      setAdd_documents_nf_selected(original_doc_id);
      setInitialFields(prevState => ({
        ...prevState,
        add_documents_nf: original_doc_id,
      }));
      setAdd_documents_nf_selected(original_doc_id);
    }
  }, [add_documents_nf]);

  useEffect(() => {
    if (buildings?.length === 1) {
      setBuildings_selected([buildings[0]]);
    }
  }, [buildings]);

  useEffect(() => {
    if (!isUpdateRequest) {
      initRequestField();
    }
    if (selectedRequest?.id && !list_documents_loading && !canUpdateTopCard) {
      dispatch(getDDLlistDocuments({ project_id: selectedRequest.project_id, selectedRequestId: selectedRequest?.id }));
    }

    if (selectedRequest?.id && !incorporationStatus.loading && !isCreateRequest) {
      dispatch(getIncorporation({ id: selectedRequest.id }));
    }

    return () => {
      dispatch(clearListDocuments());
    };
  }, [selectedRequest]);

  useEffect(() => {
    if (initialFields) {
      if (buildings?.length === 1) {
        delete initialFields.buildings;
      }

      const isTopCardChanged = checkIsTopCardChanged();
      const isDescriptionChanged = checkIsDescriptionChanged();

      if (isTopCardChanged && !canUpdateTopCard) {
        setCanUpdateTopCard(true);
      } else if (!isTopCardChanged && canUpdateTopCard) {
        setCanUpdateTopCard(false);
        setIsHandleSelectDraw(false);
      }

      if (isDescriptionChanged && !canUpdateDescription) {
        setCanUpdateDescription(true);
      } else if (!isDescriptionChanged && canUpdateDescription) {
        setCanUpdateDescription(false);
      }

      if (!isPullRequest) {
        if (canUpdateTopCard) {
          setUpdateSaveBtn(true);
        } else {
          setUpdateSaveBtn(false);
        }
      }
    }
  });

  useEffect(() => {
    if (selectedRequest?.id) {
      setCanUpdateTopCard(false);
      setCanUpdateDescription(false);
      setIsHandleSelectDraw(false);
      setIsCreateRequestState(false);

      !isPullRequest && setUpdateSaveBtn(false);
    }
  }, [selectedRequest?.id]);

  useEffect(() => {
    if (isCreateRequest && !isCreateRequestState) {
      setIsCreateRequestState(true);

      dispatch(getDDLlistDocumentsNoNF());
    }
  }, [isCreateRequest, isCreateRequestState]);

  useEffect(() => {
    if (isPullRequest && !isCreatePullRequest) {
      setIsCreatePullRequest(true);
      dispatch(getDDLlistDocumentsNoNF());
    }
  }, [isPullRequest, isCreatePullRequest]);

  const initFields = () => {
    const isAdmin = userInfo.roles && !!Object.keys(userInfo.roles).filter(key => key === '4').length;
    if (isCreateRequest) {
      if (buildings?.length === 1) {
        setBuildings_selected([buildings[0]]);
      }
    }

    const getHashtags: TagFormModel[] = (selectedRequest?.hashtags || []).map(tag => {
      return {
        label: tag.name,
        value: tag.id,
        color: tag.color,
        link: tag.link,
        is_auto: tag.is_auto,
        text_color: tag.text_color,
      };
    });

    const currentProject = userInfo.projects?.find(project => project.code == urlProject[0]) || selectedRequest?.project;

    const result = {
      requestCurrentDescription: '',
      buildings: [],
      building_spaces: [],
      levels: [],
      typicalLayouts: [],
      currentProjectSpecific: '',
      hashtags: isCreateRequest ? [] : getHashtags,
      is_procore_sync_needed: 0,
      tags: null,
      milestone_id: null,
      currentUfcCode: null,
      currentMfCode: null,
      currentDate: null,
      isRfi: false,
      add_documents_nf: [],
      selectedRequestCompany: {
        discipline: null,
        company: null,
        user: null,
      },
      selectedResponseCompany: {
        discipline: null,
        company: null,
        user: null,
      },
      currentPCD: [],
      cl_impacts: [],
      submittal_input: [],
      submittal_output: [],
    };

    if (!userInfo?.user_parties || !(isCreateRequest || isPullRequest) || isAdmin) {
      setInitialFields(result);
      setRequestCurrentDescription_selected(result.requestCurrentDescription);
      setBuildings_selected(result.buildings);
      setBuilding_spaces_selected(result.building_spaces);
      setLevels_selected(result.levels);
      setTypicalLayouts_selected(result.typicalLayouts);
      setCurrentProjectSpecific_selected(result.currentProjectSpecific);
      setCurrentDate_selected(result.currentDate);
      setHashtags_selected(result.hashtags);
      setIs_procore_sync_needed_selected(result.is_procore_sync_needed);
      setTags_selected(result.tags);
      setMilestoneSelected_selected(null);
      setMilestone_id_selected(result.milestone_id);
      setCurrentUfcCode_selected(result.currentUfcCode);
      setCurrentMfCode_selected(result.currentMfCode);
      setIsRfi_selected(result.isRfi);
      setAdd_documents_nf_selected(result.add_documents_nf);
      setSelectedRequestCompany_selected(selectedRequestCompany_selected);
      setSelectedResponseCompany_selected(result.selectedResponseCompany);
      setCurrentPCD_selected(result.currentPCD);
      setCl_impacts_selected(result.cl_impacts);
      setIsRequestCurrentDescription(false);
      setErrors({});
      return;
    }

    if (userInfo?.user_parties && projectInfo?.parties) {
      let selectedRequestCompany;
      if (userInfo?.user_parties?.length && currentProject && Object.values(projectInfo?.parties).length) {
        const projectPartyIds = projectInfo?.parties[currentProject.id]?.map(c => c.id) || [];
        const userCompany = userInfo.user_parties.find(c => projectPartyIds.includes(c.id));
        const userCompanyData = projectInfo?.parties[currentProject.id]?.find(f => f.id === userCompany?.id);
        selectedRequestCompany = {
          discipline:
            userCompany && userCompanyData?.project_disciplines[0]
              ? {
                  id: userCompanyData?.project_disciplines[0].id,
                  title: userCompanyData?.project_disciplines[0].title,
                  text_color: userCompanyData?.project_disciplines[0].text_color,
                }
              : null,
          company: userCompany
            ? {
                ...userCompany,
                id: userCompany.id,
                title: userCompany.company,
              }
            : null,
          user: userCompany
            ? {
                ...userInfo,
                id: userInfo.id,
                title: `${userInfo?.first_name} ${userInfo?.last_name}`,
              }
            : null,
        };
      }

      setInitialFields({
        ...result,
        selectedRequestCompany: selectedRequestCompany,
      });
      setRequestCurrentDescription_selected(result.requestCurrentDescription);
      setBuildings_selected(result.buildings);
      setBuilding_spaces_selected(result.building_spaces);
      setLevels_selected(result.levels);
      setTypicalLayouts_selected(result.typicalLayouts);
      setCurrentProjectSpecific_selected(result.currentProjectSpecific);
      setCurrentDate_selected(result.currentDate);
      setHashtags_selected(result.hashtags);
      setIs_procore_sync_needed_selected(result.is_procore_sync_needed);
      setTags_selected(result.tags);
      setMilestoneSelected_selected(null);
      setMilestone_id_selected(result.milestone_id);
      setCurrentUfcCode_selected(result.currentUfcCode);
      setCurrentMfCode_selected(result.currentMfCode);
      setIsRfi_selected(result.isRfi);
      setAdd_documents_nf_selected(result.add_documents_nf);
      setSelectedRequestCompany_selected(selectedRequestCompany);
      setSelectedResponseCompany_selected(result.selectedResponseCompany);
      setCurrentPCD_selected(result.currentPCD);
      setCl_impacts_selected(result.cl_impacts);
      setIsRequestCurrentDescription(false);
      setErrors({});
    }
  };

  const checkIsTopCardChanged = () => {
    const initFields = { ...initialFields };

    delete initFields.requestCurrentDescription;

    return (
      Object.keys(initFields).some(fieldKey => {
        const variableMapping: { [key: string]: any } = {};
        variableMapping[fieldKey] = fieldKey ? eval(`${fieldKey}_selected`) : null; // todo

        if (fieldKey === 'currentDate') {
          return !isEqual(variableMapping[fieldKey], handleChangedDate ? handleChangedDate : initFields[fieldKey]);
        } else if (fieldKey === 'submittal_input' || fieldKey === 'submittal_output') {
          const init_submittal = initFields[fieldKey].sort();
          const change_submittal = variableMapping[fieldKey].sort();
          return !isEqual(init_submittal, change_submittal);
        } else if (fieldKey === 'selectedResponseCompany' && isChangedNow) {
          return !isEqual(variableMapping[fieldKey], variableMapping[fieldKey]);
        } else if (fieldKey === 'isRfi') {
          return false;
        } else if (fieldKey === 'is_procore_sync_needed') {
          return false;
        } else {
          return !isEqual(variableMapping[fieldKey] == '<p><br></p>' ? null : variableMapping[fieldKey], initFields[fieldKey]);
        }
      }) || isHashTagChanging
    );
  };

  const checkIsDescriptionChanged = () => {
    return !isEqual(
      requestCurrentDescription_selected === '<p><br></p>' ? null : requestCurrentDescription_selected,
      initialFields.requestCurrentDescription,
    );
  };

  const handleCopyDataFromOriginal = () => {
    const selectedRequest: RequestModel = {
      ...parentSelectedRequest,
      nf: null,
      files: [],
      due_date: parentSelectedRequest.due_date,
      is_whiteboard: 0,
    };
    if (isPullRequestDataToCopyFormOriginal) {
      initFields();
    } else {
      initRequestField(selectedRequest);
    }
    setIsPullRequestDataToCopyFormOriginal(!isPullRequestDataToCopyFormOriginal);
  };

  const initRequestField = (parentSelectedRequest?: RequestModel) => {
    const selectedNf = selectedRequest || parentSelectedRequest;
    if (!selectedNf) {
      return;
    }

    const getHashtags: TagFormModel[] = (selectedNf.hashtags || []).map(tag => {
      return {
        label: tag.name,
        value: tag.id,
        color: tag.color,
        link: tag.link,
        is_auto: tag.is_auto,
        text_color: tag.text_color,
      };
    });

    const result = {
      requestCurrentDescription: selectedNf.desc || null,
      buildings: selectedNf.buildings?.length ? selectedNf.buildings : buildings_selected?.length === 1 ? [buildings_selected[0]] : [],
      building_spaces: selectedNf.buildings?.length && selectedNf.building_spaces?.length ? selectedNf.building_spaces : [],
      levels: selectedNf.buildings?.length && selectedNf.levels?.length ? selectedNf.levels : [],
      typicalLayouts: selectedNf.typicalLayouts?.length ? selectedNf.typicalLayouts : [],
      currentProjectSpecific: selectedNf.specific || '',
      currentDate: handleChangedDate || selectedNf.due_date || null,
      hashtags: getHashtags,
      is_procore_sync_needed: selectedNf.is_procore_sync_needed || 0,
      tags: selectedNf?.tags[0] || null,
      milestone_id: selectedNf.milestone_id || null,
      milestoneSelected: selectedNf.milestone_id ? selectedNf?.milestone : null,
      currentUfcCode: selectedNf.ufc || null,
      currentMfCode: selectedNf.mf || null,
      isRfi: !!selectedNf?.is_rfi || false,
      add_documents_nf: add_documents_nf_selected || selectedNf?.documents?.map(document => document.id) || null,
      submittal_input: Object.values(selectedNf.submittal_input)?.map(submittal => submittal.id) || [],
      submittal_output: Object.values(selectedNf.submittal_output)?.map(submittal => submittal.id) || [],
      selectedRequestCompany: {
        company: selectedNf.request_party_id
          ? {
              ...selectedNf.request_party,
              id: selectedNf.request_party_id,
              title: selectedNf.request_party.company,
            }
          : null,
        discipline: selectedNf.req_disc_id
          ? {
              id: selectedNf.req_disc_id,
              title: selectedNf.request_discipline?.title || '',
              text_color: selectedNf.request_discipline?.text_color || '',
            }
          : null,
        user: selectedNf.user
          ? {
              ...selectedNf.user,
              id: selectedNf.user.id,
              title: `${selectedNf.user.profile.first_name} ${selectedNf.user.profile.last_name}`,
            }
          : null,
      },
      selectedResponseCompany: getResponsePartySelect(selectedNf),
      currentPCD: selectedNf.deliverables?.map(item => item.id),
      cl_impacts: selectedNf.cl_impacts,
    };

    if (parentSelectedRequest) {
      delete result.selectedRequestCompany;
      delete result.selectedResponseCompany;
    }

    setInitialFields(result);
    setRequestCurrentDescription_selected(result.requestCurrentDescription);
    setBuildings_selected(result.buildings);
    setLevels_selected(result.levels);
    setTypicalLayouts_selected(result.typicalLayouts);
    setBuilding_spaces_selected(result.building_spaces);
    setCurrentProjectSpecific_selected(result.currentProjectSpecific);
    setCurrentDate_selected(result.currentDate);
    setHashtags_selected(result.hashtags);
    setIs_procore_sync_needed_selected(result.is_procore_sync_needed);
    setTags_selected(result.tags);
    setMilestoneSelected_selected(result.milestoneSelected);
    setMilestone_id_selected(result.milestone_id);
    setCurrentUfcCode_selected(result.currentUfcCode);
    setCurrentMfCode_selected(result.currentMfCode);
    setIsRfi_selected(result.isRfi);
    setAdd_documents_nf_selected(result.add_documents_nf);
    result.selectedRequestCompany && setSelectedRequestCompany_selected(result.selectedRequestCompany);
    result.selectedResponseCompany && setSelectedResponseCompany_selected(result.selectedResponseCompany);
    setCurrentPCD_selected(result.currentPCD);
    setCl_impacts_selected(result.cl_impacts);
    setIsRequestCurrentDescription(false);
    setErrors({});
    setIsAllNotificationPopupsClosed(false);
  };

  const loadRequest = (requestId: number, unSetNewRequestProject: boolean, page?: string) => {
    loadRequestPromise.current?.abort();
    loadRequestPromise.current = dispatch(singleRequestLoading({ requestId, unSetNewRequestProject, page }));
  };

  const handleGetFilteredRequest = (payload: KeyValueModel<any>) => {
    getFilteredRequestPromise.current?.abort();
    getFilteredRequestPromise.current = dispatch(getFilteredRequest(payload));
  };

  const handleSelectedSubmittal = (type: 'input' | 'output', MfWithSelectedSf: number[]) => {
    const newSelect = [...MfWithSelectedSf];
    if (type === 'input') {
      setSubmittal_input_selected(newSelect);
    } else {
      setSubmittal_output_selected(newSelect);
    }
  };

  const handleChangedNow = (isChanged: boolean) => {
    setIsChangedNow(isChanged);
    isChanged && setIsUpdateUserProfile(true);
  };

  const keepEditingRequest = () => {
    setIsPromptBtnOk(false);
    setIsPromptPopUpView(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
    setIsShowAgain(false);
  };

  const openPDFTronByParams = (selectedRequest: RequestModel) => {
    const params = getNfParamsFromURL();

    if (params?.file_id) {
      const deliverableFiles = selectedRequest.response_files || [];

      const fileRequest: RequestFileModel | null = selectedRequest.files?.find(f => f.id === params.file_id) || null;
      const fileDeliverable: RequestFileModel | null = deliverableFiles.find(f => f.id === params.file_id) || null;
      if (fileRequest || fileDeliverable) {
        const isAccessToRequest = AccessHelper.isAccessToRequestEdit(userInfo, selectedRequest);
        const isAccessToCommitment = AccessHelper.isAccessToCommitmentEdit(userInfo, selectedRequest);

        const isRequest = !!fileRequest;
        const isDeliverable = !!fileDeliverable;

        dispatch(
          openPdftron({
            file: fileRequest || fileDeliverable,
            isRequest: isRequest,
            isDeliverable: isDeliverable,
            selectedRequest: selectedRequest,
            isAccess: isDeliverable ? isAccessToCommitment : isAccessToRequest,
          }),
        );
      }
    }
  };

  const initDataFromProject = () => {
    const buildings = buildingSelector();
    const levels = levelsSelector();
    const typicalLayouts = typicalLayoutsSelector();
    const building_spaces = buildingSpacesSelector();
    const projectDisciplines = disciplinesSelector();
    const { projectParties } = getPartiesLocations();
    const milestones = milestonesSelector();

    setBuildings_selected(prevState => {
      const copyState = prevState?.map(item => item.id) || [];
      const newBuildingsList =
        buildings?.reduce((acc, item) => {
          if (copyState.includes(item.id)) {
            acc.push(item);
          }
          return acc;
        }, []) || [];

      return newBuildingsList;
    });

    setTypicalLayouts_selected(prevState => {
      const copyState = prevState?.map(item => item.id) || [];
      const newTypicalLayouts =
        typicalLayouts?.reduce((acc, item) => {
          if (copyState.includes(item.id)) {
            acc.push(item);
          }
          return acc;
        }, []) || [];

      return newTypicalLayouts;
    });

    setBuildings(buildings);
    setLevels(levels);
    setTypicalLayouts(typicalLayouts);
    setBuildingSpacesProps(building_spaces);
    setProjectDisciplines(projectDisciplines);
    setProjectParties(projectParties);
    setMilestones(milestones);
  };

  const getPartiesLocations = (): { locations: LocationCollectionModel[]; projectParties: PartyModel[] } => {
    const activeProject = selectedRequest?.project_id || active_project_id;

    const locations = (): LocationCollectionModel[] => {
      const projectLocations = locationsCollection?.[activeProject] || {};

      return Object.values(projectLocations) as LocationCollectionModel[];
    };

    const parties = (): TeamApi.IMember[] => {
      return partiesCollection?.[activeProject] || ({} as TeamApi.IMember[]);
    };
    return { locations: locations(), projectParties: parties() };
  };

  const openRequest = (requestId: number) => {
    loadRequest(requestId, true);
  };

  const handleRequestComplete = () => {
    const selectedRequest = requestInfo.data[selectRequestId || 0];

    const data = { status: 'accepted', project_id: selectedRequest.project_id };
    dispatch(
      responseSave({
        requestId: selectedRequest.id,
        bodyParams: data,
        callback: () => {
          dispatch(setCommitmentAction(''));
          dispatch(getIncorporation({ id: selectRequestId }));
        },
        isUpdateStatus: true,
      }),
    );
  };

  const handleRequestDecline = (msg: string, saveKeepData: boolean, savePreserveInc: boolean) => {
    const selectedRequest = requestInfo.data[selectRequestId || 0];

    const dataCommitment = {
      status: 'declined',
      declined_comment: msg,
      keep_data: saveKeepData ? 1 : 0,
      preserve_inc: savePreserveInc ? 1 : 0,
    };

    dispatch(
      responseSave({
        requestId: selectedRequest.id,
        bodyParams: dataCommitment,
        callback: () => {
          dispatch(setCommitmentAction(''));
          dispatch(handleSaveResponseCommentId(undefined));
        },
        isUpdateStatus: true,
      }),
    );

    setTimeout(() => {
      dispatch(getIncorporation({ id: selectRequestId })); ////////??????
    }, 500);
  };

  const selectRequestHandler = (id: number, isNavToSubtask?: boolean) => {
    const getOriginalRequestId = () => {
      if (isNavToSubtask && !originalRequestId) {
        return selectedRequest?.id || null;
      }
      return originalRequestId;
    };

    const currentOriginalRequestId = getOriginalRequestId();

    dispatch(requestView({ id }));
    const pageUrl = urlProject[1];

    if (handleSelectedRequest) {
      handleSelectedRequest(id, (previewCard && selectRequestNf) || null);
    }

    if (!getRequestLoadingStatus) {
      loadRequest(id, true, pageUrl);
    }

    setSelectedRequestId(id);
    setOriginalRequestId(currentOriginalRequestId);
  };

  const handleChangeRequestTypeTag = (item: RequestTypeModel) => {
    setTags_selected(item);
  };

  const handleChangeLOD = (item: MilestoneItemModel) => {
    setMilestoneSelected_selected(item);
    setMilestone_id_selected(item.id);
  };

  const selectedFilteredRequest = (id: number, selectedFilters: any) => {
    if (canUpdateSaveBtn) {
      setId(id);
      setSelectedFiltersStatus(selectedFilters);
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('requestHeadClose') === 'yes' ? false : true);
      setPromptTitle('Discard changes?');
      setPromptMessage('Leaving this Need Form will discard any unsaved changes.');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, discard');
      setPromptName('requestHeadClose');
      setIsShowAgain(true);
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      if (!(localStorage.getItem('requestHeadClose') === 'yes')) {
        return;
      }
    }

    dispatch(requestView({ id }));
    handleGetFilteredRequest({
      id,
      urlPageForRequestFilter: urlPageForRequestFilter || 'needlist',
      ...FiltersHelper.getRequestFiltersForApi(selectedFilters, startDate, endDate),
    });
    dispatch(clearDocumentsNF());
    handleSelectedRequest && handleSelectedRequest(id, (previewCard && selectRequestNf) || null, startDate);
  };

  const relCommitment = (id: number) => {
    setPredecessorId(id);
    setRequestIsOpen(!!(requestIsOpen || selectRequestId));
    setPullRequestOpen(true);
    dispatch(setCommitmentAction(''));
    if (!breadcrumbs.length) {
      dispatch(
        setBreadcrumbs({
          typeBreadcrumbs: 'firstCardBreadcrumbs',
          array: [{ id: selectedRequest.id, title: `NF ${selectedRequest.nf}`, nf: selectedRequest.nf, breadcrumbType: 'nf' }],
        }),
      );
    }
  };

  const closePullRequest = () => {
    setPullRequestOpen(false);
    if (breadcrumbs.length === 1) {
      dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'firstCardBreadcrumbs' }));
    }
  };

  const disciplinesSelector = (): TeamApi.IDiscipline[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const disciplines = projectInfo.disciplines?.[activeProject];

    return disciplines || [];
  };

  const buildingSelector = (): RequestBuildingModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const buildings = buildingsCollection?.[activeProject];
    return buildings || [];
  };

  const levelsSelector = (): LocationCollectionModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const levels = levelsCollection?.[activeProject];
    return levels || [];
  };

  const typicalLayoutsSelector = (): any[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const typicalLayouts = typicalLayoutsCollection?.[activeProject];
    return typicalLayouts || [];
  };

  const buildingSpacesSelector = (): SimpleTitleModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const buildings = buildingSpacesCollection?.[activeProject];
    return buildings || [];
  };

  const milestonesSelector = (): MilestoneItemModel[] => {
    const activeProject = selectedRequest?.project_id || active_project_id;
    const milestones = projectInfo.milestones?.[activeProject];
    return milestones || [];
  };

  const handleCloseRequest = (isRequestClickOutSide?: boolean) => {
    dispatch(setCommitmentAction(''));
    dispatch(clearDocumentsNF());

    if (isSeparateTab) {
      return;
    }

    if (previewCard || isPullRequest) {
      closeRequest(isRequestClickOutSide);
      return;
    }

    closeRequest(isRequestClickOutSide);
  };

  const changeAnimationBtn = (nameBtn: string) => {
    setAnimationBtn(nameBtn);
  };

  const handleCloseAllNotificationPopups = () => {
    setIsAllNotificationPopupsClosed(true);
  };

  const changePromptBtn = () => {
    savePromptsAlerts(promptName);

    setIsPromptBtnOk(true);
    setIsPromptPopUpView(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setIsShowAgain(false);
  };

  const openPromptStatus = (
    promptTitle: string,
    promptMessage: string,
    promptName: string,
    promptNameBtnOk: string | null,
    promptNameBtnCancel: string | null,
    isShowAgain: boolean,
  ) => {
    setIsPromptBtnOk(false);
    setIsPromptPopUpView(localStorage.getItem(promptName) !== 'yes');
    setPromptTitle(promptTitle);
    setPromptMessage(promptMessage);
    setPromptNameBtnCancel(promptNameBtnCancel);
    setPromptNameBtnOk(promptNameBtnOk);
    setPromptName(promptName);
    setIsShowAgain(isShowAgain);
    setIsMsgNotShowAgain(false);

    if (localStorage.getItem(promptName) === 'yes') {
      setTimeout(() => changePromptBtn(), 400);
    }
  };

  const cancelPromptStatus = () => {
    savePromptsAlerts(promptName);

    setIsPromptBtnOk(false);
    setIsPromptPopUpView(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
    setIsShowAgain(false);
  };

  const closePromptStatusOut = () => {
    setIsPromptBtnOk(false);
    setIsPromptPopUpView(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setPromptName(null);
    setIsShowAgain(false);
  };

  const savePromptsAlerts = (promptName: string) => {
    if (localStorage.getItem(promptName) === 'yes') {
      return;
    }
    const promptNameList: { [key: string]: string } = JSON.parse(localStorage.getItem('promptNameList') || '{}');
    localStorage.setItem(promptName, is_msg_not_show_again ? 'yes' : 'no');
    promptNameList[promptName] = localStorage.getItem(promptName) || '';

    localStorage.setItem('promptNameList', JSON.stringify(promptNameList));
    dispatch(setPromptsAlerts(JSON.stringify(promptNameList)));
  };

  const toggleShared = () => {
    setIsMsgNotShowAgain(!is_msg_not_show_again);
  };

  const requestHeadClose = () => {
    if (previewCard) {
      removeUrlPreviewNF();
      dispatch(clearBreadcrumbs({ typeBreadcrumbs: 'previewCardBreadcrumbs' }));
      return;
    }

    if (!canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(false);
      setPromptTitle(null);
      setPromptMessage(null);
      setPromptNameBtnCancel(null);
      setPromptNameBtnOk(null);
      setIsMsgNotShowAgain(false);
      setPromptNameBtnSave(null);

      handleCloseRequest();

      if (isUrlNfCardTab && isSeparateTab) {
        setUrlCommandCenter();
      }
    }

    if (selectRequestId && canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('requestHeadClose') !== 'yes');
      setPromptTitle('Discard changes?');
      setPromptMessage('Leaving this Need Form will discard any unsaved changes.');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, discard');
      setPromptName('requestHeadClose');
      setPromptNameBtnSave(null);
      setIsShowAgain(true);
      setIsMsgNotShowAgain(false);

      if (localStorage.getItem('requestHeadClose') !== 'no') {
        dispatch(clearDocumentsNF());
      }

      if (localStorage.getItem('requestHeadClose') === 'yes') {
        handleCloseRequest();

        if (isUrlNfCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    } else if (canUpdateSaveBtn) {
      setIsPromptBtnOk(false);
      setIsPromptPopUpClose(localStorage.getItem('requestHeadClose') !== 'yes');
      setPromptTitle('Quit NF creation?');
      setPromptMessage('Changes you have made will not be saved unless you save them as a draft.');
      setPromptNameBtnSave('Save draft');
      setPromptNameBtnCancel('Keep editing');
      setPromptNameBtnOk('Yes, quit');
      setPromptName('requestHeadClose');
      setIsShowAgain(true);
      setIsMsgNotShowAgain(false);

      if (localStorage.getItem('requestHeadClose') === 'yes') {
        handleCloseRequest();

        if (isUrlNfCardTab && isSeparateTab) {
          setUrlCommandCenter();
        }
      }
    }
  };

  const closeRequestYesStatus = () => {
    savePromptsAlerts(promptName);

    setIsPromptBtnOk(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setIsMsgNotShowAgain(false);
    setPromptNameBtnSave(null);

    dispatch(clearDocumentsNF());

    if (id) {
      selectedFilteredRequestStatus(id, selectedFiltersStatus);
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    } else {
      handleCloseRequest();
    }
  };

  const closeRequestNoStatus = () => {
    savePromptsAlerts(promptName);
    cancelLastNfRequest();
    if (id) {
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    }

    setIsPromptBtnOk(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setIsMsgNotShowAgain(false);
    setPromptNameBtnSave(null);
  };

  const closeRequestNoStatusOut = () => {
    if (id) {
      setId(null);
      setSelectedFiltersStatus(null);
      setCanUpdateSaveBtn(false);
    }

    setIsPromptBtnOk(false);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setIsMsgNotShowAgain(false);
    setPromptNameBtnSave(null);
  };

  const selectedFilteredRequestStatus = (id: number, selectedFilters: any) => {
    dispatch(requestView({ id }));
    handleGetFilteredRequest({
      id,
      urlPageForRequestFilter,
      ...FiltersHelper.getRequestFiltersForApi(selectedFilters, startDate, endDate),
    });
    handleSelectedRequest && handleSelectedRequest(id, (previewCard && selectRequestNf) || null, startDate);
  };

  const changePromptSaveBtn = () => {
    savePromptsAlerts('requestClickOutSide');

    setIsPromptBtnOk(true);
    setIsPromptPopUpClose(false);
    setPromptTitle(null);
    setPromptMessage(null);
    setPromptNameBtnCancel(null);
    setPromptNameBtnOk(null);
    setIsShowAgain(false);
    setPromptName('requestClickOutSide');
  };

  const setUpdateSaveBtn = (canUpdate: boolean) => {
    setCanUpdateSaveBtn(canUpdate);
  };

  const onChangeActiveTab = (activeTab: ActivationTabType) => {
    const nf = searchParams.get('nf');
    setUrlNF(String(nf), activeTab);
  };

  const handleOpenDeclinePopup = (isOpen: boolean) => {
    setIsOpenDeclinePopup(isOpen);
  };

  const handleChangeCommitmentVersion = (id: number) => {
    selectedFilteredRequest(id, {});
  };

  const handleResizableCard = () => {
    const rw = localStorage.getItem('resizableCard') === 'small' ? 'full' : 'small';
    localStorage.setItem('resizableCard', rw);
    dispatch(handleResizableCardPart(rw));
  };

  const handleChangeSpecific = (value: string) => {
    setCurrentProjectSpecific_selected(value);
    delete errors.specific;
    setErrors(errors);
  };

  const selectUfCodeHandler = selectedUfCode => {
    setCurrentUfcCode_selected(selectedUfCode);
  };

  const selectMfCodeHandler = selectedMfCode => {
    setCurrentMfCode_selected(selectedMfCode);
  };

  const handleUpdateMultiSelectState = (data: number | number[], stateName: string) => {
    if (!data) {
      return;
    }

    if (stateName === 'building') {
      setBuildings_selected(data);
    }
  };

  const selectAtDateHandler = selectedAtDate => {
    delete errors.due_date;

    if (currentDate_selected === selectedAtDate) {
      return;
    }

    setCurrentDate_selected(selectedAtDate);
    setErrors(errors);

    if (selectRequestId && selectedRequest.status !== 'drafted') {
      const saveObject = {
        due_date: selectedAtDate,
        desc: selectedRequest.desc || '',
        specific: selectedRequest.specific || '',
        request_party_id: selectedRequest?.request_party_id || '',
        response_party_id: selectedResponseCompany_selected?.company?.id || selectedRequest?.response_party_id || '',
        is_whiteboard: 1,
      } as any;

      if (selectedResponseCompany_selected?.user?.id) {
        saveObject.response_user_id = selectedResponseCompany_selected?.user?.id;
      }

      if (selectedResponseCompany_selected?.discipline?.id) {
        saveObject.resp_disc_id = selectedResponseCompany_selected?.discipline?.id;
      }

      if (selectedRequest.req_disc_id) {
        saveObject.req_disc_id = selectedRequest.req_disc_id;
      }

      if (selectedRequest.user?.id) {
        saveObject.user_id = selectedRequest.user.id;
      }
      if (selectedRequest?.milestone_id) {
        saveObject.milestone_id = selectedRequest?.milestone_id;
      }
      if (!isPullRequest) {
        setIsChangedNow(true);
      }
      onRequestSave(selectRequestId, saveObject);
    }
  };

  const getDueDateDisabled = () => {
    const canEditDueDate = AccessHelper.isAccessToDueDateEdit(userInfo, selectedRequest);
    if (isPullRequest) {
      return false;
    }

    if (
      !canEditDueDate ||
      selectedRequest?.status === 'provided' ||
      selectedRequest?.status === 'accepted' ||
      !!selectedRequest?.parent_id ||
      requestLoading
    ) {
      return true;
    }

    return false;
  };

  const requestToNewDueDate = (newDueDate: string) => {
    if (!isPullRequest) {
      setIsChangedNow(false);
    }
    const isCommit = activeCommitmentAction === 'commit_with_due_date';

    const newDateData = {
      request_id: Number(selectedRequest.id),
      new_due_date: formatDateISO(newDueDate),
      commit: isCommit ? 1 : 0,
      callback: () => {
        setCommitmentAction('');

        if (isCommit) {
          onChangeActiveTab('response');
        }
      },
    };

    changeAnimationBtn('dueDateChangeBtn');

    dispatch(createNewDueDate(newDateData));
  };

  const handleGlobalIncorporation = () => {
    setIsIgnoreDDLinGlobalIncorporation(true);
    setIsOpenGlobalIncorporation(true);
  };

  const handleChangeGlobalIncorporation = () => {
    dispatch(
      setGlobalIncorporation({
        id: selectedRequest.id,
        global_status: !isGlobalIncorporated,
        document_ignore: isGlobalIncorporated ? false : isIgnoreDDLinGlobalIncorporation,
      }),
    );
    setIsOpenGlobalIncorporation(false);
    setIsIgnoreDDLinGlobalIncorporation(false);
  };

  const handleChangeHashtags = (hashtags: TagFormModel[]) => {
    setHashtags_selected(hashtags);
  };

  const isUser = useMemo(() => {
    return loginUser.role && !!Object.keys(loginUser.role).filter(key => key === '2' || key === '3' || key === '5').length;
  }, [loginUser.role]);

  const isAdmin = useMemo(() => {
    return loginUser.role && !!Object.keys(loginUser.role).filter(key => key === '1' || key === '4' || key === '5').length;
  }, [loginUser.role]);

  const isAccessToRequest = useMemo(() => {
    return selectedRequest && selectedRequest?.is_deactivated === 1 ? false : AccessHelper.isAccessToRequestEdit(userInfo, selectedRequest);
  }, [selectedRequest, userInfo]);

  const isAccessToCommitment = useMemo(() => {
    return selectedRequest && selectedRequest?.is_deactivated === 1
      ? false
      : AccessHelper.isAccessToCommitmentEdit(userInfo, selectedRequest);
  }, [selectedRequest, userInfo]);

  const isDeactivated = selectedRequest?.is_deactivated === 1;

  const isAccessToRequestEdit = useMemo(() => {
    return AccessHelper.isAccessToRequestEdit(userInfo, selectedRequest);
  }, [selectedRequest, userInfo]);

  const isCompanyManagerOrUser = useMemo(() => {
    return loginUser.role && !!Object.keys(loginUser.role).filter(key => key === '2' || key === '3').length;
  }, [loginUser.role]);

  const isDueDateDisabled = useMemo(() => {
    return getDueDateDisabled();
  }, [selectedRequest, userInfo]);

  const isUserInRequestParty = useMemo(() => {
    return AccessHelper.isUserExistsInRequestParty(userInfo, selectedRequest);
  }, [selectedRequest, userInfo]);

  const isAuthorOfTheRequest = useMemo(() => {
    return selectedRequest && userInfo && userInfo.id === selectedRequest.author?.id;
  }, [selectedRequest, userInfo]);

  const handleChangeShowMoreFields = () => {
    setIsHiddenFields(!isHiddenFields);
  };

  const isShowUserDeliverables = () => {
    if (login_user?.parties?.length > 0 && currentPCD_selected && listPCD) {
      const arrAllRows = [];
      listPCD?.forEach(item => {
        arrAllRows.push(item.drawings ? item.drawings : []);
      });
      const drawingsRows = arrAllRows.flatMap(item => item);
      const drawingsRowsAll = drawingsRows.flatMap(item => item.deliverables).filter(item => item);
      const drawings = drawingsRowsAll
        .filter(item => currentPCD_selected.findIndex(value => value === item.id) > -1)
        .map(item => item.responsible_party_id)
        .filter(f => !!f);
      const isAccessUserDeliverables = login_user?.parties
        .filter(item => drawings.findIndex(value => value === item.id) > -1)
        .filter(f => !!f);
      return isAccessUserDeliverables?.length > 0;
    }
    return false;
  };

  const isShowDueDatePopup = useMemo(() => {
    return !getDueDateDisabled() || isShowUserDeliverables();
  }, [selectedRequest, userInfo]);

  const handleChangePrivate = () => {
    if (selectedRequest?.parent_id) {
      return;
    }
    if (!isCreateRequest) {
      setIsPrivateChanged(true);
    }
    setIsRfi_selected(!isRfi_selected);
  };

  const handleOpenProcorConfirmPopUp = () => {
    if (userInfo?.can_integrate && selectedRequest?.procore_project && !selectedRequest?.parent_id) {
      setIsProcorConfirmPopUpOpen(true);
    }
  };

  const handleCloseProcorConfirmPopUp = () => {
    setIsProcorConfirmPopUpOpen(false);
  };

  const handleChangeProcoreSyncStatus = () => {
    dispatch(setSyncProcore({ request_id: selectedRequest?.id, is_procore_sync_needed: is_procore_sync_needed_selected === 1 ? 0 : 1 }));
    setIsProcorConfirmPopUpOpen(false);
  };

  const handleOpenAuthorUser = (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel) => {
    const target = e.currentTarget;
    timer = setTimeout(() => {
      setIsOpenAuthorUser(true);
      setCoordinatesAuthor(target);
      setSelectedAuthor(user);
    }, 500);
  };

  const handleCloseAuthorUser = () => {
    if (timer && !isOpenAuthorUser) {
      clearTimeout(timer);
      return;
    }
    if (timer) {
      return;
    }
    handleCloseAuthorUserFromProfile();
  };

  const handleCloseAuthorUserFromProfile = () => {
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
    setSelectedAuthor(null);
  };

  const viewProfile = () => {
    setIsViewProfile(true);
    setIsOpenAuthorUser(false);
    setCoordinatesAuthor(null);
  };

  const viewProfileClose = () => {
    setIsViewProfile(false);
  };

  const onRequestSave = (requestId, bodyParams, callback?, isNotFormData?) => {
    dispatch(
      requestSave({
        requestId,
        bodyParams,
        callback,
        isNotFormData,
        callbackNavigation: nf => {
          setUrlNF(nf);
        },
      }),
    );
  };

  const handleSwitchCalendar = (currentTarget: React.RefObject<any>) => {
    setIsOpenCalendar(isOpenCalendar ? null : currentTarget);
  };

  const handleOpenChangeCompany = currentTarget => {
    setIsOpenChangeResponseCompany(isOpenChangeResponseCompany ? null : currentTarget);
  };

  const setMainErrors = errors => {
    setErrors(errors);
  };

  const handleCloseGlobalIncorporation = () => {
    setIsOpenGlobalIncorporation(false);
    setIsIgnoreDDLinGlobalIncorporation(false);
  };

  const handleIgnoreDDLinGlobalIncorporation = () => {
    setIsIgnoreDDLinGlobalIncorporation(!isIgnoreDDLinGlobalIncorporation);
  };

  const closeCommitSubmittalPopup = () => {
    dispatch(setCommitmentAction(''));
    setIsOpenCommitSubmittalPopup(false);
  };

  const handleChangeSubmittalSelect = (tag: RequestTypeModel) => {
    setTagsSubmittal_selected(tag);
  };

  const handleOpenCalendar = () => {
    setOpenedCalendarType(true);
  };

  const handleCloseCalendar = () => {
    setOpenedCalendarType(false);
  };

  const selectAtDateHandlerSubmittal = (date: string) => {
    setSubmit_due_date_selected(moment(date).format('YYYY-MM-DD'));
    handleCloseCalendar();
  };

  const saveSubmittalRequest = () => {
    const saveObject: ISubmittalUpdateModel = {
      buildingSpaces: [],
      buildings: [],
      contractor_discipline_id: responsible_contractor_selected?.discipline?.id,
      contractor_party_id: responsible_contractor_selected?.company?.id,
      contractor_user_id: responsible_contractor_selected?.user?.id,
      hashtags: [],
      locations: [],
      mf_id: mf_selected?.id,
      private: 0,
      status: 'draft',
      submit_due_date: submit_due_date_selected,
      type_id: tagsSubmittal_selected.id,
      typicalLayouts: [],
      nf_id: selectedRequest.id,
    };
    onSubmittalSave(null, saveObject, null, true);
  };

  const onSubmittalSave = (submittalId: number, bodyParams: ISubmittalUpdateModel, callback?: () => void, isNotFormData?: boolean) => {
    setIsOpenCommitSubmittalPopup(false);
    dispatch(setCommitmentAction(''));
    dispatch(
      submittalSave({
        submittalId,
        bodyParams,
        callback: () => {
          dispatch(getFeed({ owner_id: selectedRequest?.parent_id || selectedRequest.id, type: 'request' }));
        },
        isNotFormData,
      }),
    );
  };

  const renderSingleSelectRequestType = (item: DropdownItemModelExt) => {
    return (
      <>
        <ChipsRequestType type={item.title} isMobile={isMobile} />
      </>
    );
  };

  const renderFooterContent = (footerContentClass: string) => {
    const version =
      (selectedRequest?.parent_id && selectedRequest.parent.versions.filter(item => item.id === selectedRequest?.id)[0]) || [];

    const [first_name, last_name] = selectedRequest?.lastUserStatus?.author ? selectedRequest?.lastUserStatus?.author.split(' ') : ['', ''];

    if (activeTab !== 'request' && activeTab !== 'response') {
      return <></>;
    }

    const getTitleText = () => {
      let result = 'Reason: ';

      if (selectedRequest?.parent_id) {
        result += `"${selectedRequest?.decline_reason || ''}"`;
      } else {
        result += `"${
          selectedRequest?.versions?.at(-1)?.action_dates.decline.decline_reason ||
          (!!selectedRequest?.declineCounter?.list?.length && selectedRequest.declineCounter.list?.at(-1)?.reason) ||
          ''
        }"`;
      }

      return result;
    };

    return (
      <>
        {selectedRequest?.lastUserStatus?.status === 'declined' && !selectedRequest?.parent_id ? (
          <>
            <HistoryAction
              type={''}
              infoMessage={'Previous revision was declined by'}
              date={selectedRequest.lastUserStatus?.date}
              firstName={first_name}
              lastName={last_name}
              handleOpenAuthorUser={() => null}
              isSidePeekView={isSidePeekView}
            />
            <CustomTooltip enterDelay={1000} enterNextDelay={1000} leaveDelay={0} title={getTitleText()} placement={'top'} isOverflowHidden>
              <div className={cn(footerContentClass, { ['-sidePeek']: isSidePeekView })}>{getTitleText()}</div>
            </CustomTooltip>
          </>
        ) : null}
        {selectedRequest?.parent_id ? (
          <>
            <HistoryAction
              type={'declined'}
              date={version.action_dates.decline.date}
              firstName={version.action_dates.decline.user.profile.first_name}
              lastName={version.action_dates.decline.user.profile.last_name}
              handleOpenAuthorUser={() => null}
              isSidePeekView={isSidePeekView}
            />
            <CustomButton
              type={'text-activated'}
              size={'sm'}
              clickHandler={() => handleChangeCommitmentVersion(selectedRequest.parent_id)}
              title={'Go to the latest revision'}
            />
            <CustomTooltip enterDelay={1000} enterNextDelay={1000} leaveDelay={0} title={getTitleText()} placement={'top'} isOverflowHidden>
              <div className={cn(footerContentClass, { ['-sidePeek']: isSidePeekView })}>{getTitleText()}</div>
            </CustomTooltip>
          </>
        ) : null}
      </>
    );
  };

  const dialogClass = useGetThemeClass('dialogPopUp');
  const isSidePeekView = isDragRequestCard || is1440Resolution;

  const submittal_ids = useMemo(() => {
    const all_ids = Array.from(new Set([...submittal_input_selected, ...submittal_output_selected]));
    return all_ids;
  }, [submittal_input_selected, submittal_output_selected]);

  return (
    <>
      <div
        className={cn(`${themeClass}`, {
          ['-centralPeek']: !isSidePeekView && !isSeparateTab && !isMobile,
          ['-sidePeek']: isSidePeekView,
          ['-isSeparateTab']: isSeparateTab,
        })}
      >
        <Header
          isDragRequestCard={isSidePeekView}
          tags={tags}
          selectedTags={tags_selected}
          milestones={milestones}
          selectedMilestone={milestoneSelected_selected}
          clickHandler={handleResizableCard}
          selectedRequest={selectedRequest}
          handleChangeRequestTypeTag={handleChangeRequestTypeTag}
          handleChangeLOD={handleChangeLOD}
          selectAtDateHandler={selectAtDateHandler}
          currentDate={currentDate_selected}
          isDueDateDisabled={isDueDateDisabled}
          errors={errors}
          isUserInRequestParty={isUserInRequestParty}
          isAuthorOfTheRequest={isAuthorOfTheRequest}
          isCreateRequest={isCreateRequest}
          isPullRequest={isPullRequest}
          promptName={promptName}
          is_promptBtnOk={is_promptBtnOk}
          min_due_date={moment(project?.target_start).format('YYYY-MM-DD')}
          max_due_date={moment(project?.target_to).format('YYYY-MM-DD')}
          work_week={project?.work_week || 7}
          activeCommitmentAction={activeCommitmentAction}
          setCommitmentAction={setCommitmentAction}
          currentPCD={currentPCD_selected}
          listPCD={listPCD}
          isShowDueDatePopup={isShowDueDatePopup}
          requestToNewDueDate={requestToNewDueDate}
          requestHeadClose={requestHeadClose}
          breadcrumbs={breadcrumbs}
          selectRequestHandler={selectRequestHandler}
          requestLoading={requestLoading}
          getRequestLoadingStatus={getRequestLoadingStatus}
          originalRequestId={originalRequestId}
          previewCard={previewCard}
          isSeparateTab={isSeparateTab}
          isRfi={isRfi_selected}
          handleChangePrivate={handleChangePrivate}
          changeCommitmentVersion={handleChangeCommitmentVersion}
          is_procore_sync_needed={is_procore_sync_needed_selected}
          isProcorConfirmPopUpOpen={isProcorConfirmPopUpOpen}
          handleOpenProcorConfirmPopUp={handleOpenProcorConfirmPopUp}
          handleCloseProcorConfirmPopUp={handleCloseProcorConfirmPopUp}
          handleChangeProcoreSyncStatus={handleChangeProcoreSyncStatus}
          handleOpenAuthorUser={handleOpenAuthorUser}
          handleCloseAuthorUser={handleCloseAuthorUser}
          handleCopyDataFromOriginal={handleCopyDataFromOriginal}
          isPullRequestDataToCopyFormOriginal={isPullRequestDataToCopyFormOriginal}
          parentSelectedRequest={parentSelectedRequest}
          closePullRequest={handleCloseRequest}
          onChangeActiveTab={onChangeActiveTab}
          isAccess={isAccessToRequest}
          isAccessEdit={isAccessToRequestEdit}
          requestWatchers={requestWatchers}
          requestWatchersGroups={requestWatchersGroups}
          handleCloseAllNotificationPopups={handleCloseAllNotificationPopups}
          isAllNotificationPopupsClosed={isAllNotificationPopupsClosed}
          isDeliverablesPopUpOpened={isDeliverablesPopUpOpened}
          isSkeletonLoading={isSkeletonLoading}
          activeTab={activeTab}
        />
        <div className={`${themeClass}_bodyContainer`} ref={bodyContainerRef}>
          <TopCardPart
            selectedRequest={selectedRequest}
            isDragRequestCard={isSidePeekView}
            selectedRequestCompany={selectedRequestCompany_selected}
            selectedResponseCompany={selectedResponseCompany_selected}
            isAccessToCommitment={isAccessToCommitment}
            isAdmin={isAdmin}
            isAccess={isAccessToRequest}
            errors={errors}
            setErrors={setErrors}
            currentProjectSpecific={currentProjectSpecific_selected}
            handleChangeSpecific={handleChangeSpecific}
            buildingSpacesSelected={building_spaces_selected}
            buildings={buildings}
            selectedBuildings={buildings_selected}
            setBuildingsSelected={setBuildings_selected}
            currentUfcCode={currentUfcCode_selected}
            selectUfCodeHandler={selectUfCodeHandler}
            currentMfCode={currentMfCode_selected}
            selectMfCodeHandler={selectMfCodeHandler}
            handleUpdateMultiSelectState={handleUpdateMultiSelectState}
            buildingSpaces={buildingSpaces}
            listDocuments={listDocuments}
            list_documents_no_nf={list_documents_no_nf}
            add_documents_nfSelected={add_documents_nf_selected}
            hashtags={hashtags_selected}
            handleChangeHashtags={handleChangeHashtags}
            setSelectedRequestCompany={setSelectedRequestCompany_selected}
            setSelectedResponseCompany={setSelectedResponseCompany_selected}
            isHiddenFields={isHiddenFields}
            handleChangeShowMoreFields={handleChangeShowMoreFields}
            isChangeResponsePartyActionActive={activeCommitmentAction === 'redirect_to_party'}
            onRequestSave={onRequestSave}
            handleChangedNow={handleChangedNow}
            isPullRequest={isPullRequest}
            currentDate={currentDate_selected}
            isHandleSelectDraw={isHandleSelectDraw}
            set_status_nf_loading={set_status_nf_loading}
            isAccessToRequestEdit={isAccessToRequestEdit}
            list_documents_loading={list_documents_loading}
            setCurrentPCD={setCurrentPCD_selected}
            selectedMilestone={milestoneSelected_selected}
            selectedTags={tags_selected}
            setMainErrors={setMainErrors}
            isCreateRequest={isCreateRequest}
            levels={levels}
            typicalLayouts={typicalLayouts}
            setLevelsSelected={setLevels_selected}
            levelsSelected={levels_selected}
            setTypicalLayoutsSelected={setTypicalLayouts_selected}
            typicalLayoutsSelected={typicalLayouts_selected}
            setBuildingSpacesSelected={setBuilding_spaces_selected}
            isCompanyManagerOrUser={isCompanyManagerOrUser}
            isMobile={isMobile}
            updatedResponseCompany={updatedResponseCompany}
            is_procore_sync_needed={is_procore_sync_needed_selected}
            handleCloseAllNotificationPopups={handleCloseAllNotificationPopups}
            isAllNotificationPopupsClosed={isAllNotificationPopupsClosed}
            clImpactsSelected={cl_impacts_selected}
            setClImpactsSelected={setCl_impacts_selected}
            setIsDeliverablesPopUpOpened={setIsDeliverablesPopUpOpened}
            canUpdateTopCard={canUpdateTopCard && !canUpdateDescription}
            canUpdateDescription={canUpdateDescription}
            handleSelectedSubmittal={handleSelectedSubmittal}
            selectedSubmittalInput={submittal_input_selected}
            isSkeletonLoading={isSkeletonLoading}
          />
          <TabsContainer
            isDragRequestCard={isSidePeekView}
            incorporationList={incorporationList}
            feedData={feedData}
            relatedTabData={relatedTabData}
            openedRequest={selectedRequest && Object.values(selectedRequest)?.length ? selectedRequest : null}
            relCommitment={relCommitment}
            requestLoading={requestLoading}
            activeTab={activeTab}
            onChangeActiveTab={onChangeActiveTab}
            isDisable={isPullRequest || isCreateRequest}
            isPullRequest={isPullRequest}
            isAccess={isAccessToRequestEdit}
            handleGlobalIncorporation={handleGlobalIncorporation}
          />
          <BottomCardPart
            activeTab={activeTab}
            onChangeActiveTab={onChangeActiveTab}
            isDragRequestCard={isSidePeekView}
            isHiddenFields={isHiddenFields}
            openRequest={selectRequestHandler}
            selectedRequest={selectedRequest && Object.values(selectedRequest)?.length ? selectedRequest : null}
            requestLoading={requestLoading}
            isAccessToCommitment={isAccessToCommitment}
            isAccess={isAccessToRequest}
            isPullRequest={isPullRequest}
            setMainErrors={setMainErrors}
            changeAnimationBtn={changeAnimationBtn}
            animationBtn={animationBtn}
            initialFields={initialFields}
            setInitialFields={setInitialFields}
            buildings={buildings}
            selectedBuildings={buildings_selected}
            buildingSpaces={buildingSpaces}
            buildingSpacesSelected={building_spaces_selected}
            projectDisciplines={projectDisciplines}
            projectParties={projectParties}
            milestones={milestones}
            activeCommitmentAction={activeCommitmentAction}
            active_project_id={active_project_id}
            selectedRequestCompany={selectedRequestCompany_selected}
            selectedResponseCompany={selectedResponseCompany_selected}
            currentUfcCode={currentUfcCode_selected}
            currentMfCode={currentMfCode_selected}
            currentProjectSpecific={currentProjectSpecific_selected}
            currentDate={currentDate_selected}
            currentPCD={currentPCD_selected}
            hashtags={hashtags_selected}
            isRfi={isRfi_selected}
            setIsRfi={setIsRfi_selected}
            predecessorId={predecessorId}
            add_documents_nfSelected={add_documents_nf_selected}
            selectRequestId={selectRequestId}
            selectedRequestId={selectedRequestId}
            is_procore_sync_needed={is_procore_sync_needed_selected}
            tags={tags}
            selectedTags={tags_selected}
            milestone_id={milestone_id_selected}
            isHashTagChanging={isHashTagChanging}
            setIsHashTagChanging={setIsHashTagChanging}
            isChangedNow={isChangedNow}
            setIsChangedNow={setIsChangedNow}
            onRequestSave={onRequestSave}
            isUpdateRequest={isUpdateRequest}
            setIsUpdateRequest={setIsUpdateRequest}
            openPromptStatus={openPromptStatus}
            is_promptPopUpPull={is_promptPopUpPull}
            setIs_promptPopUpPull={setIs_promptPopUpPull}
            is_msg_not_show_again={is_msg_not_show_again}
            setIsMsgNotShowAgain={setIsMsgNotShowAgain}
            toggleShared={toggleShared}
            savePromptsAlerts={savePromptsAlerts}
            canUpdate={canUpdateTopCard || canUpdateDescription}
            promptName={promptName}
            is_promptBtnOk={is_promptBtnOk}
            handleSwitchCalendar={handleSwitchCalendar}
            handleOpenChangeCompany={handleOpenChangeCompany}
            requestCurrentDescription={requestCurrentDescription_selected}
            setRequestCurrentDescription={setRequestCurrentDescription_selected}
            isRequestCurrentDescription={isRequestCurrentDescription}
            setIsRequestCurrentDescription={setIsRequestCurrentDescription}
            userInfo={userInfo}
            isUser={isUser}
            handleOpenAuthorUser={handleOpenAuthorUser}
            handleCloseAuthorUser={handleCloseAuthorUser}
            isOpenDeclinePopup={isOpenDeclinePopup}
            handleOpenDeclinePopup={handleOpenDeclinePopup}
            handleRequestDecline={handleRequestDecline}
            isDueDateDisabled={isDueDateDisabled}
            isPullRequestOpenByAction={isPullRequestOpenByAction}
            feedData={feedData}
            incorporationHistory={incorporationHistory}
            levelsSelected={levels_selected}
            typicalLayoutsSelected={typicalLayouts_selected}
            levels={levels}
            isSeparateTab={isSeparateTab}
            bodyContainerRef={bodyContainerRef}
            isCreateRequest={isCreateRequest}
            setIsPrivateChanged={setIsPrivateChanged}
            isPrivateChanged={isPrivateChanged}
            requestWatchers={requestWatchers}
            requestWatchersGroups={requestWatchersGroups}
            handleGlobalIncorporation={handleGlobalIncorporation}
            clImpactsSelected={cl_impacts_selected}
            submittal_ids={submittal_ids}
            activeRelatedItem={activeRelatedItem}
            isSkeletonLoading={isSkeletonLoading}
            isDeactivated={isDeactivated}
            previewCard={previewCard}
          />
          <Footer renderFooterContent={renderFooterContent} isSidePeekView={isSidePeekView} />
        </div>
      </div>
      <DialogPopUp isCard open={is_promptPopUpView} onClose={closePromptStatusOut} title={promptTitle} hideBackdrop={true} maxWidth={'xs'}>
        <div className={`${dialogClass}_dialogTitle`}>
          <div>{promptTitle}</div>
          <div className={`${dialogClass}_closeIcon`} onClick={closePromptStatusOut}>
            <SystemButton type={'close'} size={'lg'} variant={'transparent'} />
          </div>
        </div>
        <div className={`${dialogClass}_content`}>
          <span className={`${dialogClass}_content_modalText`}>{promptMessage}</span>
          {is_show_again && (
            <div onClick={toggleShared} className={`${themeClass}__dialogCheckboxRow`}>
              <CheckBox checked={is_msg_not_show_again} />
              <span className={`${dialogClass}_content_modalText`}>Do not show such messages again</span>
            </div>
          )}
        </div>
        <div className={`${dialogClass}_buttonsSection`}>
          <CustomButton clickHandler={cancelPromptStatus} type={'tertiary'} title={promptNameBtnCancel} size={'md'} />
          <CustomButton clickHandler={changePromptBtn} type={'primary'} title={promptNameBtnOk} size={'md'} />
        </div>
      </DialogPopUp>
      <DialogPopUp
        isCard
        open={is_promptPopUpClose}
        onClose={closeRequestNoStatusOut}
        title={promptTitle}
        hideBackdrop={true}
        maxWidth={'xs'}
      >
        <div className={`${dialogClass}_dialogTitle`}>
          <div>{promptTitle}</div>
          <div className={`${dialogClass}_closeIcon`} onClick={closeRequestNoStatusOut}>
            <SystemButton type={'close'} size={'lg'} variant={'transparent'} />
          </div>
        </div>
        <div className={`${dialogClass}_content`}>
          <span className={`${dialogClass}_content_modalText`}>{promptMessage}</span>
          {is_show_again && (
            <div onClick={toggleShared} className={`${themeClass}__dialogCheckboxRow`}>
              <CheckBox checked={is_msg_not_show_again} />
              <span className={`${dialogClass}_content_modalText`}>Do not show such messages again</span>
            </div>
          )}
        </div>
        <div className={`${dialogClass}_buttonsSection`}>
          {promptNameBtnSave ? (
            <>
              <CustomButton clickHandler={changePromptSaveBtn} type={'secondary'} title={promptNameBtnSave} size={'md'} />
              <CustomButton clickHandler={keepEditingRequest} type={'tertiary'} title={promptNameBtnCancel} size={'md'} />
              <CustomButton clickHandler={closeRequestYesStatus} type={'primary'} title={promptNameBtnOk} size={'md'} />
            </>
          ) : (
            <>
              <CustomButton clickHandler={closeRequestNoStatus} type={'tertiary'} title={promptNameBtnCancel} size={'md'} />
              <CustomButton clickHandler={closeRequestYesStatus} type={'primary'} title={promptNameBtnOk} size={'md'} />
            </>
          )}
        </div>
      </DialogPopUp>
      <DialogPopUp
        isCard
        open={isOpenCommitSubmittalPopup}
        onClose={closeCommitSubmittalPopup}
        title={'Commit with submittal resolution'}
        hideBackdrop={true}
        maxWidth={'xs'}
      >
        <div className={`${themeClassSubmittalPopup}_dialog_container`}>
          <div className={`${themeClassSubmittalPopup}_dialog_callout`}>
            <div className={`${themeClassSubmittalPopup}_dialog_header`}>
              <div className={`${themeClassSubmittalPopup}_dialog_header_title`}>Commit with submittal resolution</div>
              <div className={`${themeClassSubmittalPopup}_dialog_header_closeIcon`} onClick={closeCommitSubmittalPopup}>
                <SystemButton type={'close'} size={'md'} variant={'transparent'} />
              </div>
            </div>
            <div className={`${themeClassSubmittalPopup}_dialog_content`}>
              <div className={`${themeClassSubmittalPopup}_dialog_content_subtext_block`}>
                <div className={`${themeClassSubmittalPopup}_dialog_content_string_subtext`}>
                  Required input cannot be provided rightaway. Request company should wait until the appropriate Input submittal is
                  submitted. The system will create an Input submittal placeholder. Select the required data of the expected Input
                  submittal:
                </div>
              </div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_spec_section`}>
                <div className={`${themeClassSubmittalPopup}_dialog_content_string_subtext -column`}>Spec section:</div>
                <div
                  className={`${themeClassSubmittalPopup}_dialog_content_spec_section_label_text ${themeClassSubmittalPopup}_dialog_content_string_subtext`}
                >
                  {mf_selected?.code ? `#${mf_selected?.code}` : '-'}
                </div>
                <div className={`${themeClassSubmittalPopup}_dialog_content_spec_section_code_selector`}>
                  <CodeSelector
                    isMulti={false}
                    codeType={'mf'}
                    isValuesRemovable
                    isValuesEditable
                    selected={mf_selected}
                    selectHandler={setMf_selected}
                    selectedBuildingIds={buildings_selected?.length ? buildings_selected : null}
                    basisPlaceholder={`Select /--/`}
                    menuItemSize={'md'}
                    basisMaxItems={2}
                    handleFor={'specification'}
                    renderBasisSelectedItem={item => item.title}
                    error={errors?.mf_id}
                    disabled={isDeactivated}
                  />
                </div>
              </div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_subtext_block`}>
                <div className={`${themeClassSubmittalPopup}_dialog_content_string_subtext -column`}>Submittal type:</div>
                <Select<DropdownItemModelExt, null, false>
                  isMulti={false}
                  menuItems={availableTags}
                  selectHandler={values => handleChangeSubmittalSelect(values)}
                  selected={tagsSubmittal_selected}
                  menuItemSize={'md'}
                  menuItemTitleRenderer={renderSingleSelectRequestType}
                  renderCustomBasis={isActive => {
                    return (
                      <ChipsRequestType
                        type={tagsSubmittal_selected?.title || 'Select request type'}
                        isPressed={isActive}
                        customIcon={<TokenIcon iconName={'chevron-down'} size={16} />}
                        customIconClass={'selectIcon'}
                        onClickIcon={null}
                        isMobile={isMobile}
                        error={errors?.type_id}
                      />
                    );
                  }}
                  disabled={!isAdmin || isDeactivated}
                />
              </div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_subtext_block`}>
                <div className={`${themeClassSubmittalPopup}_dialog_content_string_subtext -column`}>Submit by date:</div>
                <DateField
                  selectedDate={submit_due_date_selected ? moment(submit_due_date_selected).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
                  isSentRequestToChange={false}
                  isOpenCalendar={openedCalendarType}
                  isDiffDueScheduledDate={false}
                  info={false}
                  valueIsDate={true}
                  dateFieldRef={submitDateRef}
                  clickHandler={handleOpenCalendar}
                  disabled={isDeactivated}
                />
                <Popover
                  open={!!openedCalendarType}
                  onClose={handleCloseCalendar}
                  anchorEl={submitDateRef?.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  classes={{
                    paper: `${themeClassCallout} ${themeClassPaper}`,
                  }}
                >
                  <div>
                    {!!openedCalendarType && (
                      <>
                        <CustomCalendar
                          handleChange={date => selectAtDateHandlerSubmittal(date)}
                          value={submit_due_date_selected}
                          name={'formData'}
                          work_week={projectData.work_week || 7}
                        />
                      </>
                    )}
                  </div>
                </Popover>
              </div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_subtext_block`}>
                <div className={`${themeClassSubmittalPopup}_dialog_content_string_subtext -column`}>Responsible contractor:</div>
                <div>
                  <PartySelector
                    type={'request'}
                    selected={responsible_contractor_selected}
                    handleSelect={item => setResponsible_contractor_selected(item)}
                    customPartyContainerClassName={cn(`${themeClass}_topBlock_responsibleContractorContainer`, {
                      ['-sidePeekView']: isSidePeekView,
                    })}
                    isDisableSelect={isDeactivated}
                  />
                </div>
              </div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_subtext_empty_block`}></div>
              <div className={`${themeClassSubmittalPopup}_dialog_content_button_group`}>
                <CustomButton clickHandler={closeCommitSubmittalPopup} type={'tertiary'} title={'Cancel'} size={'md'} />
                <CustomButton
                  clickHandler={saveSubmittalRequest}
                  type={'primary'}
                  title={'Commit with submittal resolution'}
                  size={'md'}
                  disabled={
                    !(submit_due_date_selected && (responsible_contractor_selected.discipline || responsible_contractor_selected.company))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </DialogPopUp>
      {isOpenAuthorUser && (
        <PopupProfile
          anchorEl={coordinatesAuthor}
          user={selectedAuthor}
          viewProfile={viewProfile}
          closeTeamUser={handleCloseAuthorUserFromProfile}
          closeFixedTooltip={handleCloseAuthorUserFromProfile}
        />
      )}
      {isViewProfile && (
        <ProfilePopover
          open={isViewProfile}
          onClose={viewProfileClose}
          type={isAdmin ? 'edit' : 'view'}
          projectId={selectedRequest?.project_id}
          user_id={selectedAuthor.id}
        />
      )}
      {pullRequestOpen && (
        <CardRequestGlobal
          pullRequestOpen={pullRequestOpen}
          requestCloseHandler={closePullRequest}
          predecessorIdProps={predecessorId}
          parentSelectedRequest={selectedRequest}
          isPullRequestOpenByAction={prevActiveCommitmentAction === 'commit_with_pull_request'}
          isSeparateTab={isSeparateTab}
        />
      )}
      {isOpenGlobalIncorporation && (
        <DialogPopUp
          isCard
          open={isOpenGlobalIncorporation}
          onClose={handleCloseGlobalIncorporation}
          aria-labelledby="form-dialog-title GlobalIncorporation"
          isOverlay={true}
          title={isGlobalIncorporated ? 'Clear Need Form incorporation?' : 'Complete Need Form incorporation?'}
          renderModalContent={() => (
            <>
              <div className={`${dialogClass}_content_modalText`}>
                {isGlobalIncorporated
                  ? "'Response incorporated' label will be removed."
                  : "'Response incorporated' label will be applied. All involved parties will be notified."}
              </div>
              {!isGlobalIncorporated && (
                <div onClick={handleIgnoreDDLinGlobalIncorporation} className={`${themeClass}__dialogCheckboxRow`}>
                  <CheckBox checked={isIgnoreDDLinGlobalIncorporation} />
                  <span className={`${dialogClass}_content_modalText`}>Ignore further validation for this NF against Design drawings</span>
                </div>
              )}
            </>
          )}
          secondaryText={'Cancel'}
          handleOnSecondary={handleCloseGlobalIncorporation}
          primaryText={isGlobalIncorporated ? 'Yes, clear' : 'Yes, complete'}
          handleOnPrimary={handleChangeGlobalIncorporation}
        />
      )}
    </>
  );
}

export default withConfirmDialog<IProps>(withConfirmDialogContext(CardRequest));
