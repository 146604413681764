import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { descriptionFieldStyles } from '../../../SandBoxDescriptionField/SandBoxDescriptionFieldStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import requestApi from '../../../../../service/Api/requestApi';
import { FILE_SIZE_LIMIT } from '../../../../../service/file';
import SVG from 'react-inlinesvg';
import { basicModules } from '../../../../../helpers/quillConfig';
import { isEmptyObject } from '../../../../../helpers/commonHelpers';
import BallInCourtSearch from '../BallInCourtSearch/BallInCourtSearch';
import { getBallInCourtList } from '../../../../../store/comments/commentsThunk';
import { searchUsersRequest } from '../../../../../store/request/requestLogic(HOLD)';
import { FileDrop } from 'react-file-drop';
import { handleGetThemeClass } from '../../../../../helpers/designTokens';
import FilesListViewTokens from '../../../../controls/FilesListViewTokens/FilesListViewTokens';
import { CommentTarget } from '../CommentTarget';
import { RibbonComponent } from '../../../../controls/TextQuillEditor/parts/RibbonComponent';
import { TextQuillEditor } from '../../../../controls/TextQuillEditor';
import cn from 'classnames';
import { CustomButton, SystemButton } from '../../../../controls/ButtonComponents';
import { PopupMoreLimitFiles } from '../../../../controls/PopupMoreLimitFiles/PopupMoreLimitFiles';
import CommentEditorMobile from '../CommentEditorMobile/CommentEditorMobile';

function addMention() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, ' @');
  this.quill.setSelection(cursorPosition + 2);
}

class CommentEditor extends Component {
  state = {
    files: this.props.files || [],
    data: '',
    findUsers: {},
    text: this.props.text ? this.props.text : '',
    animationLoading: false,
    isBallInCourtOpen: false,
    searchValue: '',
    ballInCourtItemsList: null,
    chosenItem: null,
    isShowRibbonSet: false,
    quillEditorRef: null,
    loadingFilesName: [],
    moreLimitFiles: [],
  };

  typicalPhrases = ['Please review the responses.', 'Please validate the commitment.', 'Please incorporate the submitted responses.'];
  uploadFileRef = React.createRef();
  ballInCourtBall = React.createRef();
  commentEditor = React.createRef();
  fileOpenMoreLimitRef = React.createRef();

  quillEditor = null;

  componentDidMount() {
    const { getBallInCourtList, commentFormType, isReplyBic, isEditBallInCourt } = this.props;

    //TODO old scroll logic
    // this.scrollToTarget();
    const shouldNotOpenPopover = !isEditBallInCourt && !isReplyBic;

    if (commentFormType === 'ballInCort') {
      getBallInCourtList();
      shouldNotOpenPopover && this.handleOpenBallInCourtPopover(this.ballInCourtBall.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { getBallInCourtList, commentFormType, ballInCourtList, isReplyBic, isEditBallInCourt } = this.props;

    const { searchValue, chosenItem, ballInCourtItemsList, text, quillEditorRef } = this.state;

    if (quillEditorRef !== prevState.quillEditorRef) {
      this.quillEditor = quillEditorRef.getEditor();
    }
    if (this.props.findUsers !== prevProps.findUsers) {
      this.setState({
        findUsers: this.props.findUsers,
      });
    }
    if (this.props.files !== prevProps.files) {
      this.setState({
        files: this.props.files,
      });
    }
    if (!this.props.fileLoading && this.props.fileLoading !== prevProps.fileLoading) {
      setTimeout(() => {
        this.setState({
          animationLoading: false,
          loadingFilesName: [],
        });
      }, 500);
    }

    if (
      ballInCourtList &&
      ballInCourtList.users &&
      ballInCourtList.companies &&
      (ballInCourtList.users !== prevProps.ballInCourtList.users || ballInCourtList.companies !== prevProps.ballInCourtList.companies)
    ) {
      this.setState({
        ballInCourtItemsList: ballInCourtList.users.concat(ballInCourtList.companies),
      });
    }

    if (chosenItem && text !== prevState.text) {
      let insertName;

      if (chosenItem.user_id) {
        insertName = `@${chosenItem.first_name} ${chosenItem.last_name} `;
      } else if (chosenItem.company && chosenItem.id) {
        insertName = `@${chosenItem.company} `;
      }

      if (text.indexOf(insertName) === -1 && !isReplyBic && !isEditBallInCourt) {
        this.setState({
          chosenItem: null,
        });
      }
    }

    if (
      ballInCourtList &&
      ballInCourtList.users &&
      ballInCourtList.companies &&
      ((searchValue && searchValue !== prevState.searchValue) || (chosenItem && chosenItem !== prevState.chosenItem))
    ) {
      const items = ballInCourtList.users.concat(ballInCourtList.companies);
      const filteredItems =
        items &&
        items.filter(item => {
          return (
            `${item.first_name} ${item.last_name}`.toLowerCase().search(searchValue.toLowerCase()) !== -1 ||
            `${item.company}`.toLowerCase().search(searchValue.toLowerCase()) !== -1
          );
        });

      const chosenItemIndex =
        chosenItem &&
        filteredItems.findIndex(item => {
          if (item.user_id) {
            return item.user_id === chosenItem.user_id;
          } else {
            return item.id === chosenItem.id;
          }
        });

      if ((chosenItemIndex || chosenItemIndex === 0) && filteredItems) {
        filteredItems.splice(chosenItemIndex, 1);
      }

      this.setState({
        ballInCourtItemsList: filteredItems,
      });
    }

    if (isReplyBic && !chosenItem && ballInCourtItemsList?.length > 0) {
      const insertName = `@${isReplyBic.owner.profile.first_name} ${isReplyBic.owner.profile.last_name} `;
      const setIndex = insertName.length;

      const chosenUser = {
        user_id: isReplyBic.owner.id,
        first_name: isReplyBic.owner.profile.first_name,
        last_name: isReplyBic.owner.profile.last_name,
      };

      this.quillEditor.clipboard.dangerouslyPasteHTML(
        0,
        `<span style="color: #F3A359">${insertName}</span> <span style="color: var(--color)"> </span>`,
      );
      this.quillEditor.setSelection(setIndex + 2);

      this.setState({
        chosenItem: chosenUser,
      });
    }

    if (
      isEditBallInCourt &&
      ballInCourtItemsList &&
      ballInCourtList &&
      ballInCourtList.users &&
      ballInCourtList.companies &&
      !chosenItem &&
      ballInCourtItemsList?.length
    ) {
      const items = ballInCourtList.users.concat(ballInCourtList.companies);

      const bicTarget = items.find(item => {
        if (isEditBallInCourt.bic.owner === 'user') {
          return item.user_id === isEditBallInCourt.bic.owner_id;
        } else if (isEditBallInCourt.bic.owner === 'company') {
          return item.id === isEditBallInCourt.bic.owner_id;
        }
      });

      let chosenItem;

      if (isEditBallInCourt.bic.owner === 'user') {
        chosenItem = {
          user_id: isEditBallInCourt.owner_id,
          first_name: bicTarget.first_name,
          last_name: bicTarget.last_name,
        };
      } else if (isEditBallInCourt.bic.owner === 'company') {
        chosenItem = {
          id: isEditBallInCourt.owner_id,
          company: bicTarget.company,
        };
      }

      this.setState({
        chosenItem: chosenItem,
      });
    }
  }

  handleSetQuillEditorRef = ref => {
    this.setState({ quillEditorRef: ref });
  };

  handleFile = files => {
    this.setState({
      files: [...this.state.files, ...files],
    });
  };

  collectMentions = contents => {
    const mentions = [];

    contents.ops.forEach(o => {
      if (o.insert && o.insert.mention) {
        mentions.push({ id: o.insert.mention.id, email: o.insert.mention.target });
      }
    });

    return mentions.filter((value, index, self) => self.findIndex(_value => _value.id === value.id) === index);
  };

  handleChange = (value, delta, source, editor) => {
    this.setState({ text: value });
    this.props.changeHandler(value);
    this.props.changeMentions(this.collectMentions(editor.getContents()));
  };

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@', '[~'],
    isolateCharacter: true,
    defaultMenuOrientation: 'top',
    onSelect: (item, insertItem) => {
      item.value = item.target + ']';
      insertItem(item);
      // this.props.selectedMention(item);
    },
    source: async function (searchTerm, renderList, mentionChar) {
      const projectId = +sessionStorage.getItem('active_project_id');
      let projectParam = projectId > 0 ? { project_id: projectId } : '';
      projectParam = { ...projectParam, ...{ search: searchTerm } };
      let res = await requestApi.searchUsers(projectParam);
      if (res.status !== true) {
        return;
      }

      let values = Object.values(res.data).map(user => {
        return {
          id: user.id,
          value: `<div class="ql-mention-list-container-item">
                                      <img src='${
                                        user.profile.image ? user.profile.image : require('../../../../../assets/icons/avatar.svg')
                                      }' alt="" />
                                      <span>${user.profile.first_name} ${
            user.profile.last_name
          } <span class="ql-mention-list-container-item-dash">&nbsp;-&nbsp;</span> <br class="ql-mention-list-container-item-br"> ${
            user.email
          }</span>
                                      </div>`,
          denotationChar: '[~',
          target: user.email,
        };
      });
      renderList(values, searchTerm);
      // if (values.length !== 0) {
      // }
    },
  };

  modules = {
    ...basicModules,
    toolbar: {
      container: `#toolbar-${this.props.source}`,
      handlers: {
        addMention: addMention,
        // link: linkHandler,
      },
    },
    mention: this.props.commentFormType !== 'ballInCort' ? this.mentionModule : null,
  };

  openPrevFile = file => {
    this.props.openPrevFile(file);
  };

  handleSendCommentBtn = e => {
    const { animationLoading, chosenItem, text } = this.state;
    const { sendBallInCourt, handleSendComment, commentFormType, isEditBallInCourt, files } = this.props;

    const textCheck = !text || text.split(' ').join('') === '<p></p>' || text === '<p><br></p>';
    const filesCheck = !files || isEmptyObject(files);

    if (animationLoading || (textCheck && filesCheck)) {
      return;
    }

    this.setState({
      animationBtn: 'commentBtn',
      animationLoading: true,
    });
    setTimeout(() => {
      if (commentFormType !== 'ballInCort') {
        handleSendComment();
      } else if (!isEditBallInCourt && commentFormType === 'ballInCort') {
        sendBallInCourt(chosenItem?.user_id, chosenItem?.id);
      } else if (isEditBallInCourt && commentFormType === 'ballInCort') {
        sendBallInCourt(chosenItem?.user_id, chosenItem?.id, isEditBallInCourt.bic_id);
      }

      this.setState({
        animationLoading: false,
      });
    }, 1000);
  };

  handleAddFile = (e, isDrag, isRestrict) => {
    let filesEl;

    if (isDrag) {
      if (isRestrict) {
        return;
      }
      filesEl = e;
    } else {
      filesEl = e.target.files;
    }
    const files = Array.from(filesEl).filter(f => f?.size < FILE_SIZE_LIMIT);
    const moreLimitFiles = Array.from(filesEl).filter(f => f.size > FILE_SIZE_LIMIT);

    this.setState({
      moreLimitFiles: moreLimitFiles,
    });

    if (!files || isEmptyObject(files)) {
      return;
    }

    this.setState({
      animationBtn: 'fileUploadBtn',
      animationLoading: true,
      loadingFilesName: Array.from(filesEl).map(el => el.name),
    });

    this.props.handleFile(files) || this.handleFile(files);

    this.uploadFileRef.current.value = '';
  };

  handleOpenFileUpload = () => {
    this.uploadFileRef.current.click();
  };

  handleShowTextRibbonSets = () => {
    const { isShowRibbonSet } = this.state;
    this.setState({ isShowRibbonSet: !isShowRibbonSet });
  };

  handleOpenBallInCourtPopover = target => {
    const { isEditBallInCourt, isReplyBic } = this.props;

    if (isEditBallInCourt || isReplyBic) {
      return;
    }

    this.setState({
      isBallInCourtOpen: true,
      anchorEl: target,
    });
  };

  handleCloseBallInCourtPopover = () => {
    this.setState({
      isBallInCourtOpen: false,
      anchorEl: null,
      searchValue: '',
    });
  };

  handleSearchUserOrCompany = value => {
    this.setState({
      searchValue: value,
    });
  };

  handleChooseUserOrCompany = item => {
    const { chosenItem, text } = this.state;
    let insertName;

    if (item.user_id) {
      insertName = `@${item.first_name} ${item.last_name} `;
    } else if (item.company && item.id) {
      insertName = `@${item.company} `;
    }

    if (chosenItem) {
      let nameToDelete;

      if (chosenItem.user_id) {
        nameToDelete = `@${chosenItem.first_name} ${chosenItem.last_name} `;
      } else if (chosenItem.company && chosenItem.id) {
        nameToDelete = `@${chosenItem.company} `;
      }

      this.setState({
        chosenItem: item,
        text: text.replace(`${nameToDelete}`, `${insertName}`),
      });
    } else {
      const setIndex = insertName.length;

      this.setState({
        chosenItem: item,
      });

      this.quillEditor.clipboard.dangerouslyPasteHTML(
        0,
        `<span style="color: #F3A359">${insertName}</span> <span style="color: var(--color)"> </span>`,
      );
      this.quillEditor.setSelection(setIndex + 2);
    }
    this.handleCloseBallInCourtPopover();
  };

  handleAddTemplatePhrase = phrase => {
    const { text } = this.state;
    const selection = this.quillEditor.getSelection(true)?.index;

    if (selection) {
      this.quillEditor.insertText(selection, `${phrase}`);
    } else {
      this.quillEditor.insertText(text.length, `${phrase}`);
    }
  };

  handleDeleteChosenItem = () => {
    const { chosenItem, text } = this.state;

    let insertName;

    if (chosenItem.user_id) {
      insertName = `@${chosenItem.first_name} ${chosenItem.last_name} `;
    } else if (chosenItem.company && chosenItem.id) {
      insertName = `@${chosenItem.company} `;
    }

    this.setState({
      text: text.replace(`${insertName}`, ''),
      chosenItem: null,
      searchValue: '',
    });
  };

  scrollToTarget = settings => {
    this.commentEditor?.current?.scrollIntoView(
      settings
        ? settings
        : {
            behavior: 'smooth',
            block: 'end',
            // inline: 'center',
          },
    );
  };

  handleCloseMoreLimitFiles = () => {
    this.setState({
      moreLimitFiles: [],
    });
  };

  render() {
    const {
      classes,
      changeHandler,
      currentValue,
      disabled,
      isPullRequest,
      handleSendComment,
      handleCloseComment,
      commentStatus,
      commentFormType,
      ballInCourtList,
      isEditBallInCourt,
      isReplyBic,
      files,
      isReplyComment,
      isEditComment,
      handleClearCommentForm,
      getViewText,
      type,
      isMobile,
      themePopupMoreLimitFilesClass,
    } = this.props;

    const { animationLoading, animationBtn, moreLimitFiles } = this.state;

    const { searchValue, isBallInCourtOpen, anchorEl, ballInCourtItemsList, text, chosenItem, isShowRibbonSet } = this.state;
    const themeClass = handleGetThemeClass('commentAdd', this.props.theme);
    const themeClassCommentBottomContainer = handleGetThemeClass('commentBottomContainer', this.props.theme);
    const themeClassActiveRibbon = handleGetThemeClass('-activeRibbon', this.props.theme);

    const shouldDisableSendCommentButton = () => {
      const textCheck = !text || text.split(' ').join('') === '<p></p>' || text === '<p><br></p>' || text === '<p><br></p><p><br></p>';
      const filesCheck = !files || isEmptyObject(files);

      if (commentFormType === 'ballInCort' && !chosenItem) {
        return true;
      }
      if (textCheck && filesCheck) {
        return true;
      }
      return false;
    };

    const sendCommentButtonDisabled = shouldDisableSendCommentButton();

    const getCommentSectionText = () => {
      if (type === 'request') {
        return 'NF';
      }

      if (type === 'deliverable') {
        return 'Deliverable';
      }

      if (type === 'submittal') {
        return 'Submittal';
      }
    };

    return (
      <>
        {commentFormType === 'ballInCort' && (
          <div className={`ballInCourtAnchor`}>
            <span
              className={'ballInCourtAnchor__icon'}
              ref={this.ballInCourtBall}
              onClick={e => this.handleOpenBallInCourtPopover(e.currentTarget)}
            >
              <SVG src={require('../../../../../assets/icons/ball-In-Court.svg')} />
            </span>
            <BallInCourtSearch
              searchValue={searchValue}
              searchUserOrCompany={this.handleSearchUserOrCompany}
              ballInCourtItemsList={ballInCourtItemsList}
              chooseUserOrCompany={this.handleChooseUserOrCompany}
              chosenItem={chosenItem}
              deleteChosenItem={this.handleDeleteChosenItem}
              disabled={isEditBallInCourt || isReplyBic}
            />
          </div>
        )}
        {!isMobile && (
          <FileDrop onDrop={files => this.handleAddFile(files, true, disabled)}>
            <div
              ref={this.commentEditor}
              className={cn('commentEditor', {
                ['-showRibbon']: isShowRibbonSet,
              })}
            >
              {(isReplyComment?.comment || isEditComment?.comment) && (
                <CommentTarget
                  comment={isReplyComment?.comment || isEditComment?.comment}
                  isEditComment={!!isEditComment}
                  onClearTarget={handleClearCommentForm}
                  getViewText={getViewText}
                />
              )}
              <div className={'commentEditor_textEditor'}>
                <TextQuillEditor
                  stopFocus
                  handleSetQuillEditorRef={this.handleSetQuillEditorRef}
                  value={text || currentValue}
                  handleChange={this.handleChange}
                  modules={this.modules}
                  placeholder={`Leave comment to this ${getCommentSectionText()}`}
                  externalFieldId={'Comment'}
                  commentSection
                />
              </div>
              <div
                className={cn('commentEditor_filesList', {
                  ['-active']: this.props.files.length,
                })}
              >
                <input
                  ref={this.uploadFileRef}
                  style={{ display: 'none' }}
                  id={`${isPullRequest ? `pull-raised-button-file-${this.props.source}` : `raised-button-file-${this.props.source}`}`}
                  multiple
                  type="file"
                  onChange={this.handleAddFile}
                  disabled={disabled}
                />
                {this.props.files.length ? (
                  <FilesListViewTokens
                    files={this.props.files}
                    type={'horizontalList'}
                    isEditable={true}
                    delFile={this.props.delFile}
                    delSavedFile={this.props.delSavedFile}
                    source={this.props.type ? (this.props.type === 'request' ? 'R' : 'PCD') : 'Comment'}
                    subSource={'comment'}
                    disabled={false}
                    selectedRequest={this.props.selectedRequest}
                    isSubComment={this.props.isSubComment}
                    isEditMode={true}
                    typeSize={'sm'}
                    fileLoading={this.props.fileLoading}
                    loadingFilesName={this.state.loadingFilesName}
                  />
                ) : null}
              </div>
              <div
                className={cn(themeClassCommentBottomContainer, {
                  ['-expanded']: isShowRibbonSet,
                })}
              >
                <div
                  className={cn(`${themeClassCommentBottomContainer}_ribbonWrapper`, {
                    ['-visible']: isShowRibbonSet || isMobile,
                  })}
                >
                  <RibbonComponent fieldId={'Comment'} disabled={this.props.disabled} />
                  {isMobile ? (
                    <SystemButton
                      type={'attachment'}
                      size={'md'}
                      variant={'transparent'}
                      clickHandler={this.handleOpenFileUpload}
                      tooltip={'Attach file'}
                      isMobile={isMobile}
                    />
                  ) : null}
                </div>
                <div className={`${themeClassCommentBottomContainer}_buttonsContainer`} ref={this.fileOpenMoreLimitRef}>
                  <input
                    ref={this.uploadFileRef}
                    style={{ display: 'none' }}
                    id={`${isPullRequest ? `pull-raised-button-file-${this.props.source}` : `raised-button-file-${this.props.source}`}`}
                    multiple
                    type="file"
                    onChange={this.handleAddFile}
                    disabled={disabled}
                  />
                  <div className={cn('b-comment__filesBlockBtns')}>
                    {!isMobile ? (
                      <SystemButton
                        type={'edit-ribbon'}
                        size={'md'}
                        variant={'transparent'}
                        clickHandler={this.handleShowTextRibbonSets}
                        tooltip={'Show toolbar'}
                        customClass={cn(`${themeClassCommentBottomContainer}_ribbonController`, {
                          ['-active']: isShowRibbonSet,
                        })}
                        isMobile={isMobile}
                      />
                    ) : null}
                    {!isMobile && commentFormType !== 'ballInCort' && (
                      <SystemButton
                        type={'attachment'}
                        size={'md'}
                        variant={'transparent'}
                        clickHandler={this.handleOpenFileUpload}
                        tooltip={'Attach file'}
                        isMobile={isMobile}
                      />
                    )}
                    {this.props.source === 'Comment' ? (
                      <>
                        {(isEditComment || isReplyComment) && (
                          <CustomButton
                            type={'tertiary'}
                            size={'sm'}
                            title={'Cancel'}
                            clickHandler={handleCloseComment}
                            disabled={disabled || sendCommentButtonDisabled}
                            isMobile={isMobile}
                            customClassName={'b-comment__filesBlockBtns_button'}
                            customTooltipBasisClassName={'b-comment__filesBlockBtns_buttonTooltipBasis'}
                          />
                        )}
                        <CustomButton
                          type={'primary'}
                          size={'sm'}
                          title={this.props.text || isEditComment ? 'Update' : 'Comment'}
                          clickHandler={this.handleSendCommentBtn}
                          disabled={disabled || sendCommentButtonDisabled}
                          loading={animationLoading && animationBtn === 'commentBtn'}
                          isMobile={isMobile}
                          customClassName={'b-comment__filesBlockBtns_button'}
                          customTooltipBasisClassName={'b-comment__filesBlockBtns_buttonTooltipBasis'}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FileDrop>
        )}
        {moreLimitFiles?.length > 0 && (
          <PopupMoreLimitFiles
            isOpenMoreLimitFiles={moreLimitFiles?.length > 0}
            anchorEl={this.fileOpenMoreLimitRef.current}
            handleCloseMoreLimitFiles={this.handleCloseMoreLimitFiles}
            customClassName={themePopupMoreLimitFilesClass}
          />
        )}
        {isMobile && (
          <CommentEditorMobile
            isOpen
            handleClose={handleCloseComment}
            type={isReplyComment ? 'reply' : isEditComment ? 'edit' : 'add'}
            handleOpenFileUpload={this.handleOpenFileUpload}
            handleSend={this.handleSendCommentBtn}
            loading={animationLoading && animationBtn === 'commentBtn'}
            disabled={disabled || sendCommentButtonDisabled}
          >
            <FileDrop onDrop={files => this.handleAddFile(files, true, disabled)}>
              <div
                ref={this.commentEditor}
                className={cn('commentEditor', {
                  ['-showRibbon']: isShowRibbonSet,
                })}
              >
                {(isReplyComment?.comment || isEditComment?.comment) && (
                  <div className={'commentTarget'}>
                    <CommentTarget
                      comment={isReplyComment?.comment || isEditComment?.comment}
                      isEditComment={!!isEditComment}
                      onClearTarget={handleClearCommentForm}
                      getViewText={getViewText}
                    />
                  </div>
                )}
                <div className={'commentEditor_textEditor'}>
                  <TextQuillEditor
                    // stopFocus
                    // autoFocus
                    handleSetQuillEditorRef={this.handleSetQuillEditorRef}
                    value={text || currentValue}
                    handleChange={this.handleChange}
                    modules={this.modules}
                    placeholder={`Leave comment to this ${getCommentSectionText()}`}
                    externalFieldId={'Comment'}
                    commentSection
                  />
                </div>
                <div
                  className={cn('commentEditor_filesList', {
                    ['-active']: this.props.files.length,
                  })}
                >
                  <input
                    ref={this.uploadFileRef}
                    style={{ display: 'none' }}
                    id={`${isPullRequest ? `pull-raised-button-file-${this.props.source}` : `raised-button-file-${this.props.source}`}`}
                    multiple
                    type="file"
                    onChange={this.handleAddFile}
                    disabled={disabled}
                  />
                  {this.props.files.length ? (
                    <FilesListViewTokens
                      files={this.props.files}
                      type={'horizontalList'}
                      isEditable={true}
                      delFile={this.props.delFile}
                      delSavedFile={this.props.delSavedFile}
                      source={this.props.type ? (this.props.type === 'request' ? 'R' : 'PCD') : 'Comment'}
                      subSource={'comment'}
                      disabled={false}
                      selectedRequest={this.props.selectedRequest}
                      isSubComment={this.props.isSubComment}
                      isEditMode={true}
                      typeSize={'sm'}
                    />
                  ) : null}
                </div>
                <div
                  className={cn(themeClassCommentBottomContainer, {
                    ['-expanded']: isShowRibbonSet,
                  })}
                >
                  <div
                    className={cn(`${themeClassCommentBottomContainer}_ribbonWrapper`, {
                      ['-visible']: isShowRibbonSet || isMobile,
                    })}
                  >
                    <RibbonComponent fieldId={'Comment'} disabled={this.props.disabled} />
                  </div>
                  <div className={`${themeClassCommentBottomContainer}_buttonsContainer`} ref={this.fileOpenMoreLimitRef}>
                    <input
                      ref={this.uploadFileRef}
                      style={{ display: 'none' }}
                      id={`${isPullRequest ? `pull-raised-button-file-${this.props.source}` : `raised-button-file-${this.props.source}`}`}
                      multiple
                      type="file"
                      onChange={this.handleAddFile}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </FileDrop>
          </CommentEditorMobile>
        )}
      </>
    );
  }
}
//

const mapStateToProps = ({ requestReducer, commentReducer, userReducer }) => {
  return {
    findUsers: requestReducer.findUsers,
    ballInCourtList: commentReducer.ballInCourtList,
    getBallInCourtListStatus: commentReducer.getBallInCourtListStatus,
    theme: userReducer.userInfo.theme || localStorage.getItem('theme'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchUsersRequest,
      getBallInCourtList,
    },
    dispatch,
  );

export default withStyles(descriptionFieldStyles)(connect(mapStateToProps, mapDispatchToProps)(CommentEditor));

CommentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
};

CommentEditor.displayName = 'CommentEditor';
