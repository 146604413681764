import React, { forwardRef } from 'react';
import { useGetThemeClass } from '../../../helpers/designTokens';
import { ItemsCounter } from '../ItemsСounter';
import cn from 'classnames';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { HIGH_RESOLUTION_CLASS } from '../../../constants/screenResolutionConstants';

import './PageHeaderStyles.scss';

interface IProps {
  title: string | React.ReactNode;
  counter?: number;
  children?: React.ReactNode;
  customClass?: string;
  isDivided?: boolean;
  renderLeftSide?: () => React.ReactNode;
  customLeftSideClass?: string;
  renderRightSide?: () => React.ReactNode;
  customRightSideClass?: string;
  isOpenSearchMobile?: boolean;
  isLoading?: boolean;
  showCounter?: boolean;
  isHighResolution?: boolean;
  rightStyles?: React.CSSProperties;
}

const PageHeader: React.FC<IProps> = forwardRef<HTMLDivElement, IProps>(
  (
    {
      title,
      counter,
      children,
      customClass,
      isDivided,
      renderLeftSide,
      customLeftSideClass,
      renderRightSide,
      customRightSideClass,
      isOpenSearchMobile,
      isLoading,
      showCounter = false,
      isHighResolution,
      rightStyles,
    },
    ref,
  ) => {
    const themeClass = useGetThemeClass('b-pageHeader');
    const { isMobile } = GetResolution();

    return (
      <div
        className={cn(themeClass, {
          [`${customClass}`]: customClass,
          [`-divided`]: isDivided,
          ['-mobile']: isMobile,
          ['-searchMobileOpen']: isOpenSearchMobile,
          [HIGH_RESOLUTION_CLASS]: isHighResolution,
        })}
        ref={ref}
      >
        {isDivided && renderRightSide && renderRightSide ? (
          <>
            <div
              className={cn(`${themeClass}_leftSide`, {
                [`${customLeftSideClass}`]: customLeftSideClass,
              })}
            >
              <div className={`${themeClass}_titleContainer`}>
                <div className={`${themeClass}_title`}>{title}</div>
                {showCounter && <ItemsCounter value={counter || 0} isTextLabel size={'md'} isLoading={isLoading} isHideLabelWhileLoading />}
              </div>
              {renderLeftSide()}
            </div>
            {children}
            <>
              <div
                className={cn(`${themeClass}_rightSide`, {
                  [`${customRightSideClass}`]: customRightSideClass,
                })}
                style={rightStyles || {}}
              >
                {renderRightSide()}
              </div>
            </>
          </>
        ) : (
          <>
            <div className={`${themeClass}_titleContainer`}>
              <div className={`${themeClass}_title`}>{title}</div>
              {showCounter && <ItemsCounter value={counter || 0} isTextLabel size={'md'} isLoading={isLoading} />}
            </div>
            {children}
          </>
        )}
      </div>
    );
  },
);

export default PageHeader;
