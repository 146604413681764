import requestApi from '../../service/Api/requestApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateIncorporationModel,
  GetIncorporationModel,
  GlobalIncorporationResponse,
  IncorporationResponseModel,
  SetGlobalIncorporationModel,
  SetIncorporationModel,
  UpdateIncorporationListModel,
  UpdateSubTaskStatusModel,
} from './incorporationReducer.model';
import { errorsSlice } from '../errors/errorsSlice';
const { setShowErrorData } = errorsSlice.actions;

export const getIncorporation = createAsyncThunk<IncorporationResponseModel, GetIncorporationModel>(
  'incorporationReducer/getIncorporation',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id } = payload;
    try {
      const response = await requestApi.getIncorporation(id);

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setGlobalIncorporation = createAsyncThunk<GlobalIncorporationResponse, SetGlobalIncorporationModel>(
  'incorporationReducer/setGlobalIncorporation',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, global_status, document_ignore, callback } = payload;

      const response = await requestApi.setGlobalIncorporation(id, { global_status: global_status, document_ignore: document_ignore });

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createIncorporation = createAsyncThunk<IncorporationResponseModel, CreateIncorporationModel>(
  'incorporationReducer/createIncorporation',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, data, callback } = payload;

      const response = await requestApi.createIncorporation(id, data);

      if (callback) {
        callback();
      }

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateTasks = createAsyncThunk<IncorporationResponseModel, UpdateIncorporationListModel>(
  'incorporationReducer/updateTasks',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, tasks, callback } = payload;

      const response = await requestApi.updateTasks(id, { tasks });

      if (callback) {
        callback();
      }

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
export const updateSubTaskStatus = createAsyncThunk<IncorporationResponseModel, UpdateSubTaskStatusModel>(
  'incorporationReducer/updateSubTaskStatus',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { id, data, callback } = payload;

      const response = await requestApi.updateSubTaskStatus(id, data);

      if (callback) {
        callback();
      }

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const setIncorporation = createAsyncThunk<IncorporationResponseModel, SetIncorporationModel>(
  'incorporationReducer/setIncorporation',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback } = payload;
      const response = await requestApi.setIncorporation(JSON.stringify(payload));

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export default [
  // getIncorporationLogic,
  // setIncorporationLogic,
];
