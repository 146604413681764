import React, { useState } from 'react';
import CustomTooltip from '../../../../../../controls/Tooltip/Tooltip';
import { useGetThemeClass } from '../../../../../../../helpers/designTokens';
import ChipsRevisionTag from '../../../../../../controls/Chips/ChipsRevisionTag/ChipsRevisionTag';
import PlaceholderInfo from '../../../../../../controls/PlaceholderInfo/PlaceholderInfo';
import DateRow from '../../../../../../controls/DateRow/DateRow';
import cn from 'classnames';
import { PCDApi } from '../../../../../../../service/Api/pcd/types';
import PCDCard = PCDApi.PCDCard;
import { API_URI, DOMAIN_URI } from '../../../../../../../service/links';
import commonApi from '../../../../../../../service/Api/commonApi';
import FileHelper from '../../../../../../../helpers/FileHelper';
import fileDownload from 'js-file-download';
import SystemButton from '../../../../../../controls/ButtonComponents/SystemButton/SystemButton';
import { GetResolution } from '../../../../../../../helpers/ScreenResolution/GetResolution';
import { ISubmittal } from '../../../../../../../models/submittals/submittals.model';

import './RevisionsListStyles.scss';

interface IProps {
  type: 'deliverable' | 'submittal';
  selectedRequest: PCDCard | ISubmittal;
  changeRevision: (id: number, buildingId?: number) => void;
  forOpen?: 'normal' | 'download';
}

export const RevisionsList = ({ selectedRequest, changeRevision, forOpen, type }: IProps) => {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-revisionsList');

  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileId, setFileId] = useState<number | null>(null);

  const handleChangeRevision = revision => {
    if (selectedRequest.id === revision.id) {
      return;
    }

    changeRevision(revision.id, revision?.building_id);
  };

  const downloadRevision = async (revision: PCDCard) => {
    setFileId(revision.id);

    let cardDownloadUrl = '';
    if (type === 'deliverable') cardDownloadUrl = 'deliverable-pdf';
    if (type === 'submittal') cardDownloadUrl = 'submittal-pdf';

    const url = new URL(`${DOMAIN_URI}${API_URI}export/${revision.id}/${cardDownloadUrl}`);
    setFileLoading(true);
    try {
      const response = await commonApi.getFile(url.href);
      setFileLoading(false);
      setFileId(null);
      const fileName = FileHelper.getFileName(response);
      fileDownload(response.data, fileName);
    } catch (e) {
      setFileLoading(false);
      setFileId(null);
    }
  };

  const renderDate = (revision: PCDCard & ISubmittal) => {
    return <DateRow isMobile={isMobile} date={revision[type === 'deliverable' ? 'issued_date' : 'submitted_date']} showTime={true} />;
  };

  const renderProfileInfo = (revision: PCDCard) => {
    return (
      <>
        <PlaceholderInfo
          type={'Persona'}
          firstName={revision.author?.profile?.first_name}
          lastName={revision.author?.profile?.last_name}
          imagePath={revision.author?.profile?.image}
          detailInfo={`${revision.author?.profile?.first_name} ${revision.author?.profile?.last_name}`}
          isMobile={isMobile}
          size={24}
        />
      </>
    );
  };

  return (
    <div
      className={cn(`${themeClass}`, {
        [`-mobile`]: isMobile,
      })}
    >
      {forOpen == 'download' ? null : (
        <div className={`${themeClass}_header`}>
          <div className={`${themeClass}_header_group`}>
            <div className={'headline'}>Revision</div>
            <div className={`headline ${!isMobile ? '-author' : ''}`}>{isMobile ? 'Author, Issued date' : 'Author'}</div>
          </div>
          {!isMobile ? <div className={'headline'}>{type === 'deliverable' ? 'Issued date' : 'Submit date'}</div> : null}
        </div>
      )}
      <div className={`${themeClass}_container_items`}>
        {selectedRequest?.revisions
          ?.filter(item =>
            forOpen === 'download'
              ? item[type === 'deliverable' ? 'revision' : 'version'] === selectedRequest?.[type === 'deliverable' ? 'revision' : 'version']
              : true,
          )
          .map(revision => {
            const isRevisionDownloading = fileLoading && fileId === revision.id;

            return (
              <div
                key={revision.id}
                className={cn(`${themeClass}_listItem`, {
                  [`-mobile`]: isMobile,
                  ['-gap16']: forOpen == 'download',
                })}
                onClick={forOpen === 'download' ? void 0 : () => handleChangeRevision(revision)}
              >
                <div className={`${themeClass}_rowItem`}>
                  <div className={cn(`${themeClass}_column`, { ['-gap16']: forOpen == 'download' })}>
                    <CustomTooltip placement="bottom" title={`Revision ${revision[type === 'deliverable' ? 'revision' : 'version']}`}>
                      <div
                        className={cn(`${themeClass}_column_revisionNumber`, {
                          ['-short']: forOpen === 'download',
                        })}
                      >
                        <ChipsRevisionTag
                          processStatus={revision.status}
                          value={`R${revision[type === 'deliverable' ? 'revision' : 'version']}`}
                          isInvalid={
                            !!revision?.request_not_validated && revision?.status !== 'superseded' && revision?.status !== 'not_issued_yet'
                          }
                          isSubmittal={type === 'submittal'}
                        />
                      </div>
                    </CustomTooltip>
                    {forOpen === 'download' ? null : (
                      <>
                        {revision[type === 'deliverable' ? 'issued_date' : 'submitted_date'] && revision.author ? (
                          <>
                            {isMobile ? (
                              <div className={`${themeClass}_column_profileInfoDate`}>
                                {renderProfileInfo(revision)}
                                <div className={`${themeClass}_column_profileInfoDate_date`}>{renderDate(revision)}</div>
                              </div>
                            ) : (
                              <>
                                <div className={`${themeClass}_column_profileInfo`}>{renderProfileInfo(revision)}</div>
                                {renderDate(revision)}
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className={`${themeClass}_buttonContainer`}>
                  <SystemButton
                    loading={isRevisionDownloading}
                    clickHandler={() => downloadRevision(revision)}
                    type={'download'}
                    size={'md'}
                    variant={'transparent'}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            );
          }) || null}
      </div>
    </div>
  );
};
