import React from 'react';
import cn from 'classnames';
import { useGetThemeClass } from '../../../helpers/designTokens';
import DateRow from '../DateRow/DateRow';
import PlaceholderInfo from '../PlaceholderInfo/PlaceholderInfo';
import { RequestUserModel } from '../../../models';

import './HistoryActionStyles.scss';
import { GetResolution } from '../../../helpers/ScreenResolution/GetResolution';
import { SubmittalUserModel } from '../../../models/submittals/submittals.model';

interface Props {
  type: 'send' | 'updated' | 'accepted' | 'declined' | 'normal' | 'critical' | 'warning' | '';
  date: Date | string;
  firstName: string;
  lastName?: string;
  infoMessage?: string;
  handleOpenAuthorUser?: (e: React.MouseEvent<HTMLDivElement>, user: RequestUserModel | SubmittalUserModel) => void;
  handleCloseAuthorUser?: () => void;
  userProfile?: RequestUserModel | SubmittalUserModel;
  isBeforeText?: boolean;
  isShowTime?: boolean;
  company?: string;
  userImagePath?: string;
  companyImagePath?: string;
  isSidePeekView?: boolean;
}

const HistoryAction: React.FC<Props> = ({
  type,
  date,
  firstName,
  lastName,
  infoMessage,
  handleOpenAuthorUser,
  handleCloseAuthorUser,
  userProfile,
  isBeforeText,
  isShowTime = true,
  userImagePath,
  company,
  companyImagePath,
  isSidePeekView,
}) => {
  const { isMobile } = GetResolution();

  const themeClass = useGetThemeClass('b-history-action');

  const renderLabel = () => {
    if (infoMessage) {
      return infoMessage;
    }
    if (type === 'send') {
      return 'Sent by';
    }
    if (type === 'updated') {
      return 'Updated by';
    }
    if (type === 'accepted') {
      return 'Accepted by';
    }
    if (type === 'declined') {
      return 'Declined by';
    }
    if (type === 'normal' || type === 'warning' || type === 'critical') {
      return 'Issued by';
    }
    return '';
  };

  return (
    <div className={cn(`${themeClass}_container`, { ['-sidePeek']: isSidePeekView })}>
      <div
        className={cn(`${themeClass}_label`, {
          ['-accepted']: type === 'accepted',
          ['-declined']: type === 'declined',
          ['-warning']: type === 'warning',
          ['-critical']: type === 'critical',
        })}
      >
        {renderLabel()}
      </div>
      <div className={`${themeClass}_infoBlock`}>
        <div
          className={cn(`${themeClass}_personaContainer`, {
            ['userProfile']: userProfile,
          })}
          onMouseEnter={e => handleOpenAuthorUser && handleOpenAuthorUser(e, userProfile)}
          onMouseLeave={() => handleCloseAuthorUser && handleCloseAuthorUser()}
        >
          <PlaceholderInfo
            type={'Persona'}
            firstName={firstName}
            lastName={lastName}
            detailInfo={`${firstName} ${lastName}`}
            imagePath={userProfile?.profile?.image || userImagePath}
            size={24}
            isMobile={isMobile}
          />
        </div>
        {company && <PlaceholderInfo type={'Company'} detailInfo={company} imagePath={companyImagePath} size={24} isMobile={isMobile} />}
        <div className={`${themeClass}_DateContainer`}>
          {isBeforeText && <div className={`${themeClass}_DateContainer_beforeText`}>Before</div>}
          <DateRow date={date} isMobile={isMobile} showTime={isShowTime} />
        </div>
      </div>
    </div>
  );
};

export default HistoryAction;
