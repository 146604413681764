import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import LocationEditorContainer from './parts/LocationEditor/LocationEditorContainer';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import { CustomButton, SystemButton } from '../../../../controls/ButtonComponents';
import DialogPopUp from '../../../../controls/DialogPopUp/DialogPopUp';
import { BuildingModel, ProjectModel } from '../../../../../models';

import './BuildingStyles.scss';

interface IProps {
  handleChangeValue: (e: any) => void;
  formData: BuildingModel<number[]>;
  handleCreateBuilding: () => void;
  deleteBuilding: () => void;
  deleteBuildingAccept: () => void;
  deleteBuildingDecline: () => void;
  isRemoveBuilding: boolean;
  countNewFloors: { title: string; count: string };
  onChangeNewFloors: (e: any) => void;
  handleAddFloors: () => Promise<void>;
  changeLocations: (locations: any[], index: number) => Promise<void>;
  isDisabledButtons: boolean;
  changeTypicalLayout: (typical_layouts) => void;
  gridRef: MutableRefObject<any>;
  project: ProjectModel;
  isCreateBuildingAnimeBtn: boolean;
  isDeleteBuildingAnimeBtn: boolean;
}

const BuildingView: FC<IProps> = ({
  handleChangeValue,
  formData,
  handleCreateBuilding,
  deleteBuilding,
  deleteBuildingAccept,
  deleteBuildingDecline,
  isRemoveBuilding,
  countNewFloors,
  onChangeNewFloors,
  handleAddFloors,
  changeLocations,
  isDisabledButtons,
  changeTypicalLayout,
  gridRef,
  project,
  isCreateBuildingAnimeBtn,
  isDeleteBuildingAnimeBtn,
}) => {
  const themeClass = useGetThemeClass('b-buildingView');
  const [isExpanded, setIsExpanded] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<number>(0);

  useEffect(() => {
    if (Object.keys(formData).length && counterRef.current === 0) {
      setIsExpanded(!formData.id);
      counterRef.current += 1;
    }
  }, [formData]);
  const handleExpand = () => setIsExpanded(prevState => !prevState);

  return (
    <div className={themeClass}>
      <div className={`${themeClass}__container`}>
        <div className={`${themeClass}__header`}>
          <div className={`${themeClass}__headerLeft`}>
            <div className={`${themeClass}__headerMain`}>
              <SystemButton
                type={isExpanded ? 'chevron-down' : 'chevron-right'}
                size={'md'}
                variant={'transparent'}
                clickHandler={handleExpand}
              />
              <div className={`${themeClass}__headerTitle`}>
                <span ref={titleRef}>{formData?.title}</span>
                <input
                  style={{ width: titleRef?.current?.getBoundingClientRect().width || 85 }}
                  type="text"
                  value={formData?.title}
                  onChange={handleChangeValue('title')}
                />
              </div>
            </div>
            <span className={`${themeClass}__headerFloors`}>{(formData.locations && formData.locations.length) || 0} floors</span>
          </div>
          {isExpanded && (
            <div className={`${themeClass}__headerRight`}>
              <SystemButton type={'delete'} size={'lg'} variant={'transparent'} clickHandler={deleteBuilding} />
              <div className={`${themeClass}__update`}>
                <CustomButton
                  size={'sm'}
                  type={'secondary'}
                  title={formData.id ? 'Update' : 'Create'}
                  clickHandler={handleCreateBuilding}
                  disabled={isDisabledButtons || !formData.locations?.length || !formData.title.trim()}
                  loading={isCreateBuildingAnimeBtn}
                />
              </div>
            </div>
          )}
        </div>
        <div className={`${themeClass}__grid ${!isExpanded ? '-collapsed' : ''}`}>
          {formData.locations && (
            <LocationEditorContainer
              building_name={formData.title}
              locations={formData.locations}
              typical_layouts={formData.typical_layouts}
              building={formData}
              changeLocations={changeLocations}
              changeTypicalLayout={changeTypicalLayout}
              gridRef={gridRef}
              project={project}
              countNewFloors={countNewFloors}
              onChangeNewFloors={onChangeNewFloors}
              handleAddFloors={handleAddFloors}
            />
          )}
        </div>
      </div>
      <DialogPopUp
        open={isRemoveBuilding}
        onClose={deleteBuildingDecline}
        title={'Remove building'}
        modalText={`This action will remove building: "${formData.title}"`}
        primaryText={'Remove'}
        handleOnPrimary={deleteBuildingAccept}
        loadingOnPrimary={isDeleteBuildingAnimeBtn}
        secondaryText={'Cancel'}
        handleOnSecondary={deleteBuildingDecline}
      />
    </div>
  );
};

export default BuildingView;
