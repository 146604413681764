import React, { useState, useEffect, FC } from 'react';
import LoginPageView from './LoginPageView';
import { PostTypes } from '../../../hooks/usePostMessage';
import { isLoggedIn } from '../../../service/auth';
import { getClientsProjects, login, logout, userInfo } from '../../../store/user/userThunk';
import { useAppDispatch, useAppSelector } from '../../../store/configure/configureStore';
import useRouter from '../../../hooks/useRouter/useRouter';
import useRoutingHelper from '../../../hooks/useRoutingHelper/useRoutingHelper';

const LoginPageContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { urlProject, navigate } = useRouter();
  const { setUrlNoProjects, setUrlChangeProject, setUrlChangeCompany } = useRoutingHelper();

  // User slice
  const user_data = useAppSelector(state => state.userReducer);
  const clients = useAppSelector(state => state.userReducer.userInfo?.clients);
  const clientProjectsLoading = useAppSelector(state => state.userReducer.clientProjectsLoading);
  const status = useAppSelector(state => state.userReducer.status);
  const message = useAppSelector(state => state.userReducer.message);
  const loading = useAppSelector(state => state.userReducer.loading);
  const theme = useAppSelector(state => state.userReducer.userInfo.theme) || localStorage.getItem('theme');

  // Settings slice
  const kpiFrame = useAppSelector(state => state.settingsReducer.kpiFrame);

  const [user, setUser] = useState({ email: '', password: '' });
  const [isClientReviewEmail, setIsClientReviewEmail] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('keydown', handlePressEnter);
    return () => {
      document.removeEventListener('keydown', handlePressEnter);
    };
  }, []);

  useEffect(() => {
    if (urlProject[0] && urlProject[1] !== 'project-settings' && !sessionStorage.getItem('project_name') && user_data.user_id) {
      const allProjectsUser = [...user_data.userInfo.projects];
      const findIndex = allProjectsUser.findIndex(item => item.code === urlProject[0]);
      if (findIndex > -1) {
        const selectedClientId = user_data.userInfo.projects[findIndex].client_id;
        const projectName = user_data.userInfo.projects[findIndex].code;
        sessionStorage.setItem('project_name', projectName);
        selectClientReviewEmail(selectedClientId);
        setIsClientReviewEmail(true);
      }
    }
  }, [user_data, urlProject]);

  const handleChangeField = field => value => {
    setUser(prevUser => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleLogin = () => {
    if (!loading) {
      dispatch(login(user));
    }
  };

  const handleUserShort = data => {
    if (!loading) {
      dispatch(userInfo(data));
    }
  };

  const handlePressEnter = ({ key }) => {
    if (key === 'Enter' && !isLoggedIn()) {
      handleLogin();
    }
  };

  const handleLogout = () => {
    if (kpiFrame) {
      localStorage.removeItem('token');
    }
    dispatch(
      logout({
        callback: () => {
          navigate('/');
        },
      }),
    );
  };

  const selectClient = value => {
    dispatch(
      getClientsProjects({
        clientId: value,
        callback: data => {
          if (kpiFrame === 'company') {
            const messageToString = JSON.stringify({
              type: PostTypes.ON_COMPANY_SELECT,
              data: {
                selected_client_id: value,
              },
            });
            window.parent.postMessage(messageToString, '*');
          }

          if (data.projects.some(project => project.id === 0)) {
            setUrlNoProjects();
          } else {
            setUrlChangeProject();
          }
        },
      }),
    );
  };

  const selectClientReviewEmail = value => {
    const projectName = sessionStorage.getItem('project_name');
    dispatch(
      getClientsProjects({
        clientId: value,
        callback: () => {
          setTimeout(() => {
            navigate({
              pathname: `/${projectName}/needs-list`,
              search: '',
            });
          }, 4);
        },
      }),
    );
  };

  return (
    <LoginPageView
      user={user}
      clients={clients || []}
      user_data={user_data}
      handleChangeField={handleChangeField}
      handleLogin={handleLogin}
      handleLogout={handleLogout}
      selectClient={selectClient}
      loading={loading}
      clientProjectsLoading={clientProjectsLoading}
      message={message}
      status={status}
      isClientReviewEmail={isClientReviewEmail}
      theme={theme}
      handleUserShort={handleUserShort}
    />
  );
};

export default LoginPageContainer;
