import { ContentPlanPayloadModel } from '../../models';
import { IProjectInfo, IProjectReducer, UpdateRefreshesValueModel } from './projectReducer.model';

import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  getDisciplines,
  updateLinkParty,
  getUsersCompany,
  savePhasePlan,
  getUFCVersions,
  getUFCVersionData,
  postUFCData,
  searchUFC,
  searchUFCResult,
  searchUFCPopoverResult,
  getProjectDetails,
  getPCDeliverables,
  getPCDMain,
  setPCDCalendarUpdate,
  setPCDAreaCreate,
  setPCDAreaUpdate,
  setPCDAreaDelete,
  updateDrawingDays,
  bulkUpdateDrawingDays,
  createMultiplePCD,
  bulkCreateMultiplePCD,
  deliverablePCD,
  setSelectZonePCD,
  updateDrawingReviewsPCD,
  getDrawingReviewsPCD,
  bulkUpdateDrawingReviewsPCD,
  createDrawingForBuilding,
  getListDrawings,
  updateDrawingsList,
  deleteDrawing,
  createPCD,
  getUfcData,
  getUfcListActive,
  createTypical,
  getProjectRefreshes,
  getDrivers,
  saveDrivers,
  getDriversListVersions,
  getDriversVersion,
} from './projectLogic';
import Api from '../../service/api';
import { PCDSocketHandleModel } from '../../service/Api/pcd/types';

const initialState: IProjectReducer = {
  refreshes: null,
  refreshesLoading: null,
  projectInfo: {
    parties: null,
    disciplines: null,
    buildings: null,
    building_spaces: null,
    milestones: null,
    document_sets: null,
    parties_status: false,
  },
  projectInfoLoading: Api.initialStatus,
  allDisciplines: [],
  companyLinksUpdate: {
    message: null,
    loading: false,
    status: null,
  },

  userLinksUpdate: {
    message: null,
    loading: false,
    status: null,
  },

  phasePlan: null,

  lastUFCId: null,
  ufcBuildingsData: null,
  ufcListActiveData: null,
  ufcVersions: null,
  ufcSearchSuggestions: null,
  message: null,
  loading: false,
  status: null,
  get_PCDeliverables: null,
  get_PCDMain: null,
  isFirstPCDLoading: true,
  loading_PCD: false,
  loading_PCDMain: false,
  updating_calendar: false,
  is_update_drawing_days: false,
  deliverableCreatePCD: false,
  isAreaLoading: false,
  isDriverLoading: false,
  isAreaCreate: false,
  isAreaUpdate: false,
  isAreaDelete: false,
  isUpdateDrawingReviewsPCD: false,
  isCreateDrawingTemplateLoading: false,
  isSetSelectZonePCD: false,
  projectDataLoaded: false,
  error: false,
  isSaveDataLoading: false,
  ufcTreeList: null,
  isGetDrawingReviewsPCD: false,
  reviewsPCD: null,
  responsePCD: null,
  isUpdateDrawingsList: false,
  isDeleteDrawing: false,
  isGetDrawingTemplateLoading: false,
  verificationDates: [],
  driversData: null,
  driversListVersions: null,
  loadingDriversListVersions: false,
};

export const projectSlice = createSlice({
  initialState,
  name: 'projectReducer',
  reducers: {
    projectLoading(state) {
      state.loading = true;
      state.projectDataLoaded = false;
    },
    socketHandlePcdMain(state, { payload }: PayloadAction<PCDSocketHandleModel>) {
      const currentState = current(state);
      const { action, data } = payload;
      const drawings = [...(currentState.get_PCDMain?.drawings || [])];
      const changingDrawingIndex = drawings.findIndex(drawing => drawing.id === data.drawing_id);

      if (changingDrawingIndex === -1) {
        return state;
      }

      const changingDrawingDeliverables = [...drawings[changingDrawingIndex].deliverables];

      if (action === 'created') {
        changingDrawingDeliverables.push(data);
      }

      if (action === 'updated' || action === 'deleted') {
        const changingDrawingDeliverableIndex = changingDrawingDeliverables.findIndex(deliverable => deliverable.id === data.id);

        if (changingDrawingDeliverableIndex === -1) {
          return state;
        }

        if (action === 'updated') {
          changingDrawingDeliverables.splice(changingDrawingDeliverableIndex, 1, data);
        }

        if (action === 'deleted') {
          changingDrawingDeliverables.splice(changingDrawingDeliverableIndex, 1);
        }
      }

      const changingDrawing = {
        ...drawings[changingDrawingIndex],
        deliverables: changingDrawingDeliverables,
      };

      drawings.splice(changingDrawingIndex, 1, changingDrawing);

      state.get_PCDMain = {
        ...currentState.get_PCDMain,
        drawings: drawings,
      };
    },
    clearPCDTableData(state) {
      state.isFirstPCDLoading = true;
      state.get_PCDMain = null;
    },
    clearVerificationDates(state) {
      state.verificationDates = [];
    },
    isDownloading(state, { payload }: PayloadAction<boolean>) {
      state.loading_PCDMain = payload;
    },
    updateRefreshesValue(state, { payload }: PayloadAction<UpdateRefreshesValueModel>) {
      const { key, value } = payload;
      state.refreshes[key] = value;
    },
    leaveDriversPopUp(state, { payload }: PayloadAction<UpdateRefreshesValueModel>) {
      state.driversData = null;
      state.driversListVersions = null;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getDisciplines
       */
      .addCase(getDisciplines.pending, state => {
        state.loading = true;
      })
      .addCase(getDisciplines.fulfilled, (state, { payload }) => {
        state.allDisciplines = payload;
        state.loading = false;
      })
      .addCase(getDisciplines.rejected, state => {
        state.loading = false;
      })
      /**
       * updateLinkParty
       */
      .addCase(updateLinkParty.pending, state => {
        state.companyLinksUpdate.loading = true;
        state.companyLinksUpdate.message = null;
        state.companyLinksUpdate.status = null;
      })
      .addCase(updateLinkParty.fulfilled, (state, { payload }) => {
        state.companyLinksUpdate = payload;
        state.loading = false;
      })
      .addCase(updateLinkParty.rejected, state => {
        state.loading = false;
      })
      /**
       * createTypical
       */
      .addCase(createTypical.pending, state => {
        state.loading = true;
      })
      .addCase(createTypical.fulfilled, (state, { payload }) => {
        const currentState = current(state);
        const newTypicalLayouts = JSON.parse(JSON.stringify(state.projectInfo.typical_layouts));
        const itemForBuildings = JSON.parse(JSON.stringify(payload.data[0]));
        const itemForTypicalLayouts = JSON.parse(JSON.stringify(payload.data[0]));
        delete itemForBuildings['building_spaces'];
        delete itemForTypicalLayouts['locations'];

        newTypicalLayouts[payload.projectId] = [...currentState.projectInfo.typical_layouts[payload.projectId], itemForTypicalLayouts];

        const newBuildings = JSON.parse(JSON.stringify(state.projectInfo.buildings));
        const buildingsArray =
          newBuildings[payload.projectId]?.map(building => {
            if (building.id === itemForBuildings.building_id) {
              const newBuilding = JSON.parse(JSON.stringify(building));
              return { ...newBuilding, typical_layouts: [...newBuilding.typical_layouts, itemForBuildings] };
            }
            return building;
          }) || [];

        const projectInfo = {
          ...state.projectInfo,
          buildings: { [payload.projectId]: buildingsArray },
          typical_layouts: newTypicalLayouts,
        } as IProjectInfo;

        state.projectInfo = projectInfo;
      })
      .addCase(createTypical.rejected, state => {
        state.loading = false;
      })
      /**
       * getUsersCompany
       */
      .addCase(getUsersCompany.pending, state => {
        state.loading = true;
      })
      .addCase(getUsersCompany.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getUsersCompany.rejected, state => {
        state.loading = false;
      })
      /**
       * savePhasePlan
       */
      .addCase(savePhasePlan.pending, state => {
        state.loading = true;
      })
      .addCase(savePhasePlan.fulfilled, (state, { payload }) => {
        const ufcRows: ContentPlanPayloadModel[] = payload.ufc.map(ufc => {
          return {
            id: ufc.id,
            uniFormatCode: ufc.code,
            sub_ufc: [],
            name: ufc.title,
            path: [ufc.id],
            hasNesting: ufc.hasNesting,
          };
        });

        state.loading = false;
        state.contentPlanUFC = ufcRows;
        state.lastUFCId = 0;
      })
      .addCase(savePhasePlan.rejected, state => {
        state.loading = false;
      })
      /**
       * getUfcData
       */
      .addCase(getUfcData.pending, state => {
        state.loading = true;
        state.ufcBuildingsData = null;
      })
      .addCase(getUfcData.fulfilled, (state, { payload }) => {
        const { ufc } = payload;

        state.ufcBuildingsData = ufc;
        state.loading = false;
      })
      .addCase(getUfcData.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading = false;
      })
      /**
       * getUfcOrMfcListActive
       */
      .addCase(getUfcListActive.pending, state => {
        state.loading = true;
        state.ufcListActiveData = null;
      })
      .addCase(getUfcListActive.fulfilled, (state, { payload }) => {
        const { buildings } = payload;

        state.ufcListActiveData = payload;
        state.loading = false;
      })
      .addCase(getUfcListActive.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading = false;
      })
      /**
       * getUFCVersions
       */
      .addCase(getUFCVersions.pending, state => {
        state.loading = true;
        state.ufcVersions = null;
      })
      .addCase(getUFCVersions.fulfilled, (state, { payload }) => {
        const { versions } = payload;

        state.ufcVersions = versions;
        state.loading = false;
      })
      .addCase(getUFCVersions.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading = false;
      })
      /**
       * getUFCVersionData
       */
      .addCase(getUFCVersionData.pending, state => {
        state.loading = true;
      })
      .addCase(getUFCVersionData.fulfilled, (state, { payload }) => {
        const { buildings } = payload;

        state.ufcListActiveData = buildings;
        state.loading = false;
      })
      .addCase(getUFCVersionData.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading = false;
      })
      /**
       * postUFCData
       */
      .addCase(postUFCData.pending, state => {
        state.loading = true;
        state.isSaveDataLoading = true;
      })
      .addCase(postUFCData.fulfilled, (state, { payload }) => {
        state.message = payload.message;
        state.loading = false;
        state.isSaveDataLoading = false;
      })
      .addCase(postUFCData.rejected, state => {
        state.loading = false;
        state.isSaveDataLoading = false;
      })
      /**
       * searchUFC
       */
      .addCase(searchUFC.pending, state => {
        state.loading = true;
      })
      .addCase(searchUFC.fulfilled, (state, { payload }) => {
        state.ufcSearchSuggestions = payload.searchUfc;
        state.loading = false;
      })
      .addCase(searchUFC.rejected, state => {
        state.loading = false;
      })
      /**
       * searchUFCResult
       */
      .addCase(searchUFCResult.pending, state => {
        state.loading = true;
      })
      .addCase(searchUFCResult.fulfilled, (state, { payload }) => {
        const { data, ufcId } = payload;
        state.ufcBuildingsData = data.ufc;
        state.lastUFCId = ufcId;
        state.loading = false;
      })
      .addCase(searchUFCResult.rejected, state => {
        state.loading = false;
      })
      /**
       * searchUFCPopoverResult
       */
      .addCase(searchUFCPopoverResult.pending, state => {
        state.loading = true;
      })
      .addCase(searchUFCPopoverResult.fulfilled, (state, { payload }) => {
        state.ufcTreeList = payload;
        state.loading = false;
      })
      .addCase(searchUFCPopoverResult.rejected, state => {
        state.loading = false;
      })
      /**
       * getProjectDetails
       */
      .addCase(getProjectDetails.pending, state => {
        state.projectInfoLoading = Api.requestStatus;
      })
      .addCase(getProjectDetails.fulfilled, (state, { payload }) => {
        // eslint-disable-next-line no-case-declarations
        const parties = { ...state.projectInfo.parties };
        // eslint-disable-next-line no-case-declarations
        const buildings = { ...state.projectInfo.buildings };
        // eslint-disable-next-line no-case-declarations
        const building_spaces = { ...state.projectInfo.building_spaces };
        // eslint-disable-next-line no-case-declarations
        // const disciplines = {...state.projectInfo.disciplines};
        // eslint-disable-next-line no-case-declarations
        const milestones = { ...state.projectInfo.milestones };

        const document_sets = { ...state.projectInfo.document_sets };
        const levels = { ...state.projectInfo.levels };
        const typical_layouts = { ...state.projectInfo.typical_layouts };
        const cl_impacts = { ...state.projectInfo.cl_impacts };

        const project = payload;

        parties[project.id] = project.parties;
        buildings[project.id] = project.buildings;
        building_spaces[project.id] = project.building_spaces;
        // disciplines[project.id] = project.disciplines;
        milestones[project.id] = project.milestones;
        document_sets[project.id] = project.document_sets;
        levels[project.id] = project.levels;
        typical_layouts[project.id] = project.typical_layouts;
        cl_impacts[project.id] = project.cl_impacts;

        const projectInfo = {
          ...state.projectInfo,
          parties: parties,
          buildings: buildings,
          building_spaces: building_spaces,
          // disciplines: disciplines,
          milestones: milestones,
          sandbox_counters: payload.sandbox_counters,
          document_sets: document_sets,
          levels: levels,
          typical_layouts: typical_layouts,
          cl_impacts: cl_impacts,
        } as IProjectInfo;

        state.projectInfo = projectInfo;
        state.projectInfoLoading = Api.successStatus;
      })
      .addCase(getProjectDetails.rejected, state => {
        state.projectInfoLoading = Api.failStatus;
      })
      /**
       * getProjectRefreshes
       */
      .addCase(getProjectRefreshes.pending, state => {
        state.refreshesLoading = Api.requestStatus;
      })
      .addCase(getProjectRefreshes.fulfilled, (state, { payload }) => {
        state.refreshes = payload;
        state.refreshesLoading = Api.successStatus;
      })
      .addCase(getProjectRefreshes.rejected, state => {
        state.refreshesLoading = Api.failStatus;
      })
      /**
       * getPCDeliverables
       */
      .addCase(getPCDeliverables.pending, state => {
        //state.get_PCDeliverables = null;
        state.loading_PCD = true;
      })
      .addCase(getPCDeliverables.fulfilled, (state, { payload }) => {
        state.get_PCDeliverables = payload;
        state.loading_PCD = false;
        state.isFirstPCDLoading = false;
      })
      .addCase(getPCDeliverables.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.loading_PCD = false;
        state.isFirstPCDLoading = false;
      })
      /**
       * getPCDMain
       */
      .addCase(getPCDMain.pending, state => {
        state.loading_PCDMain = true;
      })
      .addCase(getPCDMain.fulfilled, (state, { payload }) => {
        state.get_PCDMain = payload;
        state.loading_PCDMain = false;
      })
      .addCase(getPCDMain.rejected, state => {
        state.loading_PCDMain = false;
      })
      /**
       * getDrivers
       */
      .addCase(getDrivers.pending, state => {
        state.loadingGetDrivers = true;
      })
      .addCase(getDrivers.fulfilled, (state, { payload }) => {
        state.driversData = payload;
        state.loadingGetDrivers = false;
      })
      .addCase(getDrivers.rejected, state => {
        state.loadingGetDrivers = false;
      })
      /**
       * saveDrivers
       */
      .addCase(saveDrivers.pending, state => {
        state.loadingGetDrivers = true;
      })
      .addCase(saveDrivers.fulfilled, (state, { payload }) => {
        state.driversData = payload;
        state.loadingGetDrivers = false;
      })
      .addCase(saveDrivers.rejected, state => {
        state.loadingGetDrivers = false;
      })
      /**
       * getDriversListVersions
       */
      .addCase(getDriversListVersions.pending, state => {
        state.loadingDriversListVersions = true;
      })
      .addCase(getDriversListVersions.fulfilled, (state, { payload }) => {
        state.driversListVersions = payload;
        state.loadingDriversListVersions = false;
      })
      .addCase(getDriversListVersions.rejected, state => {
        state.loadingDriversListVersions = false;
      })
      /**
       * getDriversVersion
       */
      .addCase(getDriversVersion.pending, state => {
        state.loadingGetDrivers = false;
      })
      .addCase(getDriversVersion.fulfilled, (state, { payload }) => {
        state.driversData = payload;
        state.loadingGetDrivers = false;
      })
      .addCase(getDriversVersion.rejected, state => {
        state.loadingGetDrivers = false;
      })
      /**
       * setPCDCalendarUpdate
       */
      .addCase(setPCDCalendarUpdate.pending, state => {
        state.updating_calendar = true;
      })
      .addCase(setPCDCalendarUpdate.fulfilled, (state, { payload }) => {
        state.updating_calendar = false;
        if (Object.keys(payload).length && !payload.hasOwnProperty('id')) {
          const verificationDates = [];
          for (let title in payload) {
            const levels = [];
            if (Object.keys(payload[title]).length) {
              Object.values(payload[title]).map((el, index) => {
                if (el !== false) {
                  levels.push({ title: Object.keys(payload[title])[index], dates: el });
                }
              });
            }
            if (levels.length) {
              verificationDates.push({ [title]: levels });
            }
          }
          state.verificationDates = verificationDates;
        } else {
          state.verificationDates = [];
        }
      })
      .addCase(setPCDCalendarUpdate.rejected, state => {
        state.updating_calendar = false;
      })
      /**
       * setPCDAreaCreate
       */
      .addCase(setPCDAreaCreate.pending, state => {
        state.isAreaCreate = true;
      })
      .addCase(setPCDAreaCreate.fulfilled, (state, { payload }) => {
        state.isAreaCreate = false;
      })
      .addCase(setPCDAreaCreate.rejected, state => {
        state.isAreaCreate = false;
      })
      /**
       * setPCDAreaUpdate
       */
      .addCase(setPCDAreaUpdate.pending, state => {
        state.isAreaUpdate = true;
      })
      .addCase(setPCDAreaUpdate.fulfilled, (state, { payload }) => {
        state.isAreaUpdate = false;
      })
      .addCase(setPCDAreaUpdate.rejected, state => {
        state.isAreaUpdate = false;
      })
      /**
       * setPCDAreaDelete
       */
      .addCase(setPCDAreaDelete.pending, state => {
        state.isAreaDelete = true;
      })
      .addCase(setPCDAreaDelete.fulfilled, (state, { payload }) => {
        state.isAreaDelete = false;
      })
      .addCase(setPCDAreaDelete.rejected, state => {
        state.isAreaDelete = false;
      })
      /**
       * updateDrawingDays
       */
      .addCase(updateDrawingDays.pending, state => {
        state.is_update_drawing_days = true;
      })
      .addCase(updateDrawingDays.fulfilled, (state, { payload }) => {
        state.is_update_drawing_days = false;
        if (Object.keys(payload).length && !payload.hasOwnProperty('id')) {
          const verificationDates = [];
          for (let title in payload) {
            const levels = [];
            if (Object.keys(payload[title]).length) {
              Object.values(payload[title]).map((el, index) => {
                if (el !== false) {
                  levels.push({ title: Object.keys(payload[title])[index], dates: el });
                }
              });
            }
            if (levels.length) {
              verificationDates.push({ [title]: levels });
            }
          }
          state.verificationDates = verificationDates;
        } else {
          state.verificationDates = [];
        }
      })
      .addCase(updateDrawingDays.rejected, state => {
        state.is_update_drawing_days = false;
      })
      /**
       * bulkUpdateDrawingDays
       */
      .addCase(bulkUpdateDrawingDays.pending, state => {
        state.is_update_drawing_days = true;
      })
      .addCase(bulkUpdateDrawingDays.fulfilled, (state, { payload }) => {
        state.is_update_drawing_days = false;
        if (Object.keys(payload).length && !payload.hasOwnProperty('id')) {
          const verificationDates = [];
          for (let i in payload) {
            if (!payload[i].hasOwnProperty('id')) {
              for (let title in payload[i]) {
                const levels = [];
                if (Object.keys(payload[i][title]).length) {
                  Object.values(payload[i][title]).map((el, index) => {
                    if (el !== false) {
                      levels.push({ title: Object.keys(payload[i][title])[index], dates: el });
                    }
                  });
                }
                if (levels.length) {
                  verificationDates.push({ [title]: levels });
                }
              }
            }
          }

          state.verificationDates = verificationDates;
        } else {
          state.verificationDates = [];
        }
      })
      .addCase(bulkUpdateDrawingDays.rejected, state => {
        state.is_update_drawing_days = false;
      })
      /**
       * createPCD
       */
      .addCase(createPCD.pending, state => {
        state.deliverableCreatePCD = true;
      })
      .addCase(createPCD.fulfilled, (state, { payload }) => {
        state.deliverableCreatePCD = false;
      })
      .addCase(createPCD.rejected, state => {
        state.deliverableCreatePCD = false;
      })
      /**
       * createMultiplePCD
       */
      .addCase(createMultiplePCD.pending, state => {
        state.deliverableCreatePCD = true;
      })
      .addCase(createMultiplePCD.fulfilled, (state, { payload }) => {
        state.deliverableCreatePCD = false;
        if (Object.keys(payload).length && !payload.hasOwnProperty('id') && !Object.values(payload)[0].hasOwnProperty('id')) {
          const verificationDates = [];
          for (let title in payload) {
            const levels = [];
            if (Object.keys(payload[title]).length) {
              Object.values(payload[title]).map((el, index) => {
                if (el !== false) {
                  levels.push({ title: Object.keys(payload[title])[index], dates: el });
                }
              });
            }
            if (levels.length) {
              verificationDates.push({ [title]: levels });
            }
          }
          state.verificationDates = verificationDates;
        } else {
          state.verificationDates = [];
        }
      })
      .addCase(createMultiplePCD.rejected, state => {
        state.deliverableCreatePCD = false;
      })
      /**
       * bulkCreateMultiplePCD
       */
      .addCase(bulkCreateMultiplePCD.pending, state => {
        state.deliverableCreatePCD = true;
      })
      .addCase(bulkCreateMultiplePCD.fulfilled, (state, { payload }) => {
        state.deliverableCreatePCD = false;
        if (Object.keys(payload).length && !payload.hasOwnProperty('id')) {
          const verificationDates = [];
          for (let i in payload) {
            const object = payload[i];
            if (!payload[i].hasOwnProperty('id') && !(object && object.length && object[0]?.hasOwnProperty('id'))) {
              for (let title in payload[i]) {
                const levels = [];
                if (Object.keys(payload[i][title]).length) {
                  Object.values(payload[i][title]).map((el, index) => {
                    if (el !== false) {
                      levels.push({ title: Object.keys(payload[i][title])[index], dates: el });
                    }
                  });
                }
                if (levels.length) {
                  verificationDates.push({ [title]: levels });
                }
              }
            }
          }

          state.verificationDates = verificationDates;
        } else {
          state.verificationDates = [];
        }
      })
      .addCase(bulkCreateMultiplePCD.rejected, state => {
        state.deliverableCreatePCD = false;
      })
      /**
       * deliverablePCD
       */
      .addCase(deliverablePCD.pending, state => {
        state.deliverableCreatePCD = true;
      })
      .addCase(deliverablePCD.fulfilled, (state, { payload }) => {
        state.deliverableCreatePCD = false;
      })
      .addCase(deliverablePCD.rejected, state => {
        state.deliverableCreatePCD = false;
      })
      /**
       * setSelectZonePCD
       */
      .addCase(setSelectZonePCD.pending, state => {
        state.isSetSelectZonePCD = true;
      })
      .addCase(setSelectZonePCD.fulfilled, (state, { payload }) => {
        state.isSetSelectZonePCD = false;
      })
      .addCase(setSelectZonePCD.rejected, state => {
        state.isSetSelectZonePCD = false;
      })
      /**
       * updateDrawingReviewsPCD
       */
      .addCase(updateDrawingReviewsPCD.pending, state => {
        state.isUpdateDrawingReviewsPCD = true;
      })
      .addCase(updateDrawingReviewsPCD.fulfilled, (state, { payload }) => {
        state.isUpdateDrawingReviewsPCD = false;
      })
      .addCase(updateDrawingReviewsPCD.rejected, state => {
        state.isUpdateDrawingReviewsPCD = false;
      })
      /**
       * getDrawingReviewsPCD
       */
      .addCase(getDrawingReviewsPCD.pending, state => {
        state.isGetDrawingReviewsPCD = true;
      })
      .addCase(getDrawingReviewsPCD.fulfilled, (state, { payload }) => {
        state.isGetDrawingReviewsPCD = false;
        state.reviewsPCD = payload;
      })
      .addCase(getDrawingReviewsPCD.rejected, state => {
        state.isGetDrawingReviewsPCD = false;
      })
      /**
       * bulkUpdateDrawingReviewsPCD
       */
      .addCase(bulkUpdateDrawingReviewsPCD.pending, state => {
        state.isUpdateDrawingReviewsPCD = true;
      })
      .addCase(bulkUpdateDrawingReviewsPCD.fulfilled, (state, { payload }) => {
        state.isUpdateDrawingReviewsPCD = false;
      })
      .addCase(bulkUpdateDrawingReviewsPCD.rejected, state => {
        state.isUpdateDrawingReviewsPCD = false;
      })
      /**
       * createDrawingForBuilding
       */
      .addCase(createDrawingForBuilding.pending, state => {
        state.isCreateDrawingTemplateLoading = true;
      })
      .addCase(createDrawingForBuilding.fulfilled, (state, { payload }) => {
        state.isCreateDrawingTemplateLoading = false;
      })
      .addCase(createDrawingForBuilding.rejected, state => {
        state.isCreateDrawingTemplateLoading = false;
      })
      /**
       * getListDrawings
       */
      .addCase(getListDrawings.pending, state => {
        state.responsePCD = null;
        state.isGetDrawingTemplateLoading = true;
      })
      .addCase(getListDrawings.fulfilled, (state, { payload }) => {
        state.responsePCD = payload;
        state.isGetDrawingTemplateLoading = false;
      })
      .addCase(getListDrawings.rejected, (state, { meta }) => {
        if (meta.aborted) {
          return state;
        }
        state.isGetDrawingTemplateLoading = false;
      })
      /**
       * updateDrawingsList
       */
      .addCase(updateDrawingsList.pending, state => {
        state.isUpdateDrawingsList = true;
      })
      .addCase(updateDrawingsList.fulfilled, (state, { payload }) => {
        state.responsePCD = payload;
        state.isUpdateDrawingsList = false;
      })
      .addCase(updateDrawingsList.rejected, state => {
        state.isUpdateDrawingsList = false;
      })
      /**
       * deleteDrawing
       */
      .addCase(deleteDrawing.pending, state => {
        state.isDeleteDrawing = true;
      })
      .addCase(deleteDrawing.fulfilled, (state, { payload }) => {
        state.isDeleteDrawing = false;
      })
      .addCase(deleteDrawing.rejected, state => {
        state.isDeleteDrawing = false;
      });
  },
});

export default projectSlice.reducer;
