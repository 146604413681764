import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IUpdateSubmittalsBulkEditsResponse, SpecAndSubsInitialState } from './specAndSubs.model';
import {
  getListSubmittals,
  getSpecTableData,
  getSpecTableListActive,
  getSubmittalsColumnSettings,
  setSubmittalsColumnSettings,
  updateSubmittalsBulkEdit,
} from './specAndSubsThunks';
import { SubmittalSocketUpdateResponse } from '../../models/submittals/submittals.model';

const initialState: SpecAndSubsInitialState = {
  submittals: {
    submittals_list: null,
    submittals_list_output: null,
    isSubmittalsLoading: false,
    isSubmittalsFirstLoading: true,
    isSubmittalsFirstOutputLoading: true,
    bulkEditData: null,
    bulkEditLoading: false,
    specifications: [],
    columnSettings: [],
    isSorted: false,
  },
  specTableData: null,
  specTableListActive: null,
  isSpecificationLoading: false,
  isSpecificationFirstLoading: true,
  isSpecificationListActiveFirstLoading: true,
};

export const SpecAndSubsSlice = createSlice({
  name: 'specAndSubs',
  initialState,
  reducers: {
    clearSubmittalsData(state) {
      state.submittals.submittals_list = null;
      state.submittals.submittals_list_output = null;
      state.submittals.isSubmittalsFirstLoading = true;
      state.submittals.isSubmittalsFirstOutputLoading = true;
    },
    clearSpecificationData(state) {
      state.specTableData = null;
      state.isSpecificationFirstLoading = true;
      state.isSpecificationListActiveFirstLoading = true;
    },
    socketHandleDeleteRevisionSubmittalCard(state, { payload }: PayloadAction<SubmittalSocketUpdateResponse>) {
      const currentState = current(state);
      const { card, action } = payload;

      const submittalsListInput = [...(currentState.submittals?.submittals_list || [])];
      const submittalsListOutput = [...(currentState.submittals?.submittals_list_output || [])];
      const changingSubmittalInputIndex = submittalsListInput.findIndex(item => item.id === card.id);
      const changingSubmittalOutputIndex = submittalsListOutput.findIndex(item => item.id === card.id);

      if (changingSubmittalInputIndex === -1 && changingSubmittalOutputIndex === -1) {
        return state;
      }

      if (action === 'updated') {
        if (changingSubmittalOutputIndex === -1) {
          submittalsListInput.splice(changingSubmittalInputIndex, 1, card);
        }
        if (changingSubmittalInputIndex === -1) {
          submittalsListOutput.splice(changingSubmittalOutputIndex, 1, card);
        }
      }

      if (action === 'deleted') {
        if (changingSubmittalOutputIndex === -1) {
          submittalsListInput.splice(changingSubmittalInputIndex, 1);
        }
        if (changingSubmittalInputIndex === -1) {
          submittalsListOutput.splice(changingSubmittalOutputIndex, 1);
        }
      }

      state.submittals = {
        ...currentState.submittals,
        submittals_list: submittalsListInput,
        submittals_list_output: submittalsListOutput,
      };
    },
    setSubmittalsBulkEditData(state, action) {
      state.submittals.bulkEditData = action.payload;
    },
    setSubmittalsIsSorted(state, action: PayloadAction<boolean>) {
      state.submittals.isSorted = action.payload;
    },
  },
  extraReducers: builder => {
    // getListSubmittals
    builder.addCase(getListSubmittals.pending, state => {
      state.submittals.isSubmittalsLoading = true;
    });
    builder.addCase(getListSubmittals.fulfilled, (state, action) => {
      if (action.payload.type === 'input') {
        state.submittals.submittals_list = action.payload.resource;
        state.submittals.isSubmittalsFirstLoading = false;
      } else {
        state.submittals.submittals_list_output = action.payload.resource;
        state.submittals.isSubmittalsFirstOutputLoading = false;
      }

      state.submittals.specifications = action.payload.specifications;
      state.submittals.isSubmittalsLoading = false;
    });
    builder.addCase(getListSubmittals.rejected, state => {
      state.submittals.isSubmittalsLoading = false;
      state.submittals.isSubmittalsFirstLoading = false;
      state.submittals.isSubmittalsFirstOutputLoading = false;
    });
    // getSpecTableData
    builder.addCase(getSpecTableData.pending, state => {
      state.isSpecificationLoading = true;
    });
    builder.addCase(getSpecTableData.fulfilled, (state, action) => {
      const { payload } = action;

      state.specTableData = payload.mf;
      state.isSpecificationLoading = false;
      state.isSpecificationFirstLoading = false;
    });
    builder.addCase(getSpecTableData.rejected, state => {
      state.isSpecificationLoading = false;
      state.isSpecificationFirstLoading = false;
    });
    // getSpecTableListActive
    builder.addCase(getSpecTableListActive.pending, state => {
      state.isSpecificationLoading = true;
    });
    builder.addCase(getSpecTableListActive.fulfilled, (state, action) => {
      const { payload } = action;

      state.specTableListActive = payload;
      state.isSpecificationLoading = false;
      state.isSpecificationListActiveFirstLoading = false;
    });
    builder.addCase(getSpecTableListActive.rejected, state => {
      state.isSpecificationLoading = false;
      state.isSpecificationListActiveFirstLoading = false;
    });
    // getSubmittalsColumnSettings
    builder.addCase(getSubmittalsColumnSettings.pending, state => {
      state.submittals.isSubmittalsLoading = true;
    });
    builder.addCase(getSubmittalsColumnSettings.fulfilled, (state, action) => {
      state.submittals.columnSettings = JSON.parse(action.payload.setting);
    });
    builder.addCase(getSubmittalsColumnSettings.rejected, state => {
      state.submittals.isSubmittalsLoading = false;
    });
    // setSubmittalsColumnSettings
    builder.addCase(setSubmittalsColumnSettings.pending, (state, { meta }) => {
      state.submittals.columnSettings = JSON.parse(meta.arg);
    });
    // Bulk Edit save
    builder.addCase(updateSubmittalsBulkEdit.pending, state => {
      state.submittals.bulkEditLoading = true;
    });
    builder.addCase(updateSubmittalsBulkEdit.fulfilled, (state, { payload }: PayloadAction<IUpdateSubmittalsBulkEditsResponse>) => {
      const { data, type } = payload;
      const updatedSubmittals = Object.values(data);
      const currentSubmittals = type === 'input' ? [...state.submittals.submittals_list] : [...state.submittals.submittals_list_output];

      updatedSubmittals.forEach(submittal => {
        const submittalIndex = currentSubmittals.findIndex(item => item.id === submittal.id);
        currentSubmittals.splice(submittalIndex, 1, submittal);
      });

      if (type === 'input') {
        state.submittals.submittals_list = currentSubmittals;
      } else {
        state.submittals.submittals_list_output = currentSubmittals;
      }

      state.submittals.bulkEditLoading = false;
    });
    builder.addCase(updateSubmittalsBulkEdit.rejected, state => {
      state.submittals.bulkEditLoading = false;
    });
  },
});

export default SpecAndSubsSlice.reducer;
