import React, { Component, useRef } from 'react';
import connect from 'react-redux/es/connect/connect';
import SVG from 'react-inlinesvg';
import { DOMAIN_URI } from '../../../../../service/links';
import { ButtonView, ResetButton } from '../../../../controls';
import { renderMedia } from '../../../MediaContent/MediaContent';
import { WhatIsNfHelp, WhatIsPlanitHelp, PlanitModulesHelp, MoreInformationHelp, UserRolesHelp } from '../helpContents/helpContents';
import { useClickOutside } from '../../../../../helpers/commonHooks';

class GettingHelp extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.openedGetStartItem !== prevProps.openedGetStartItem) {
      this.props.handleChangeGettingStarted(this.props.openedGetStartItem);
    }
  }

  render() {
    const { isInitApp, openGetStartPopup, openedGetStartItem, gettingStartedList, closeGetStartPopup, openMoreItem } = this.props;

    return (
      <>
        <div className={'b-helperDrawer__questionSubtitle'}>
          <span>{'Everything you need to know to start working with Planit Scheduler.'}</span>
        </div>
        {!isInitApp &&
          gettingStartedList.map((item, index) => {
            return (
              <div key={item.id} className={`b-helperDrawer__questionItem`} onClick={() => openGetStartPopup(item, index)}>
                <div className={`b-helperDrawer__questionItemTitle ${openedGetStartItem?.id === item.id ? '-openItem' : ''}`}>
                  <span className={'title'}>{item.name}</span>
                </div>
              </div>
            );
          })}
        {openedGetStartItem && (
          <div className={!isInitApp ? 'tutorialPopup' : 'tutorialPopupInit'}>
            <div id="form-dialog-title_tutorial" className="popupTitle">
              <div className={'tutorialPopupTitle'}>
                <div className={'title'}>{openedGetStartItem.title}</div>
                {/*<div className={'subtitle'}>{openedItem.subtitle}</div>*/}
              </div>
              <div className={'popupActionsBtnClose'} onClick={closeGetStartPopup}>
                <SVG src={require('../../../../../assets/icons/cross.svg')} />
              </div>
            </div>
            <div className={'tutorialPopupContent'}>
              {openedGetStartItem.id === 4 ? <MoreInformationHelp openItem={openMoreItem} /> : openedGetStartItem.component}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default GettingHelp;
