import React, { FC, useMemo } from 'react';
import SegmentedButton from '../../../../../controls/ButtonComponents/SegmentedButton/SegmentedButton';

import './SelectTimePeriodStyle.scss';

const timePeriod = [
  { id: 'last_month', title: 'Last month' },
  { id: 'last_quarter', title: 'Last quarter' },
  { id: 'last_year', title: 'Last year' },
  { id: 'all_history', title: 'All history' },
];

const SelectTimePeriod: FC<{
  timePeriodName: string;
  selectData: string;
  selectGroup: string;
  handleTimePeriod: (timePeriodName: string, selectPeriod: string) => void;
  handleGroup: (chart_name: string, selectGroup: string) => void;
}> = ({ timePeriodName, selectData, selectGroup, handleTimePeriod, handleGroup }) => {
  const handlerPeriod = (id: string) => {
    const findIndex = timePeriod.findIndex(item => item.id == id);
    handleTimePeriod(timePeriod[findIndex].title, '');
  };

  const selected = useMemo(() => {
    const findIndex = timePeriod.findIndex(item => item.title == timePeriodName);
    return timePeriod[findIndex].id;
  }, [timePeriodName]);

  return (
    <>
      <div className={`time_categories_select`}>
        <SegmentedButton selectedButtonId={selected} buttons={timePeriod} changeHandler={handlerPeriod} />
      </div>
    </>
  );
};

export default React.memo(SelectTimePeriod);
