import React, { Component } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import './ShowMoreDescription.scss';

class ShowMoreDescription extends Component {
  state = {
    shouldTruncate: true,
    truncated: false,
  };

  toggleLines = event => {
    event?.preventDefault();
    event?.stopPropagation();
    this.setState(state => ({ shouldTruncate: !state.shouldTruncate }));
  };

  handleTruncate = truncated => {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
      });
    }
  };

  renderContent = html => {
    return <div>{html}</div>;
  };
  render() {
    const { html, lines } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <div className="block">
          {this.state.shouldTruncate ? (
            <TruncateMarkup lines={!expanded && lines} onTruncate={this.handleTruncate}>
              {this.renderContent(html)}
            </TruncateMarkup>
          ) : (
            this.renderContent(html)
          )}
          {this.state.truncated && (
            <span>
              <a href="#" onClick={this.toggleLines}>
                <div>
                  {this.state.shouldTruncate ? (
                    <span className={'showMoreDescription__link'}>Show more</span>
                  ) : (
                    <span className={'showMoreDescription__link'}>Show less</span>
                  )}
                </div>
              </a>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ShowMoreDescription;
