import React from 'react';
import './Loader.scss';
import Loader from 'react-loader-spinner';

class LoaderView extends React.Component {
  render() {
    return (
      <div className="loader">
        <Loader type="Oval" color="#00BFFF" height="100" width="100" />
      </div>
    );
  }
}

export default LoaderView;
