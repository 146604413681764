import React from 'react';
import styles from '../WhileBoardStyle.scss';
import moment from 'moment';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Column from './dnd/column';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog } from '@material-ui/core';
import { getWeekIndexForDate, getWeekStartEnd } from './dnd/initialData';
import { withRouterHelper } from '../../../../navigation/withRouterHelper/withRouterHelper';

const hexRgb = require('hex-rgb');

class WhiteBoardtableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.row = React.createRef();
    this.state = {
      dnd: this.props.columnsData,
      isDragDisabled: false,
      requestIsOpen: false,
      isOpen: false,
      showDropArrow: false,
      selectedRequest: null,
    };
  }

  selectedRequestId = null;

  componentDidMount() {
    this.placeRequests(this.handleShowDropBtn);
    // this.setHeightTitleRow();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.columnsData !== prevProps.columnsData) {
      this.setState({
        ...this.state,
        dnd: this.props.columnsData,
      });
    }

    if (prevProps.rowData !== this.props.rowData) {
      setTimeout(() => {
        this.placeRequests(this.handleShowDropBtn);
      }, 500);
    }

    if (prevProps.requestInfo !== this.props.requestInfo && this.props.requestInfo) {
      const request = this.props.requestInfo && this.props.requestInfo[this.selectedRequestId];
      if (!request) {
        return;
      }

      this.setState({
        requestIsOpen: request.id,
        selectedRequest: request,
      });
    }

    if (prevState.isOpen !== this.state.isOpen || this.state.dnd.columns !== prevState.dnd.columns) {
      this.updateAvailableItems();
    }

    // this.setHeightTitleRow();
  }

  updateAvailableItems() {
    const data = Object.values(this.state?.dnd?.columns ? this.state.dnd.columns : {}).reduce((result, column) => {
      const tasksNf = column && column.taskIds ? column?.taskIds.map(taskId => +this.state.dnd.tasks[taskId].nf) : [];
      return result.concat(!this.state.isOpen ? tasksNf.slice(0, 6) : tasksNf);
    }, []);

    this.props.updateAvailableItems(this.props.index, data);
  }

  // setHeightTitleRow = () => {
  //   const row = this.row.current;
  //   const title = row.querySelector('.DisciplineNameBox');
  //   // title.style.height = getComputedStyle(row).height;
  // };
  bgColor = hex => {
    return `rgba(${hexRgb(hex, { format: 'array' }).slice(0, -1)}, 0.4)`;
  };

  fixBgColor = hex => {
    if (!hex) {
      return `linear-gradient(90deg, rgba(250, 250, 250, 0.4) 10px, transparent 10px)`;
    }
    return `linear-gradient(90deg, ${hex} 10px, transparent 10px)`;
  };

  fixBgCardColor = hex => {
    if (!hex) {
      return `var(--background-grid) linear-gradient(90deg, rgba(250, 250, 250, 0.4) 4px, transparent 4px)`;
    }
    return `var(--background-grid) linear-gradient(90deg, ${hex} 4px, transparent 4px)`;
  };

  makeWeekDays = (start, end) => {
    let next = moment(start);
    let week = [];

    while (moment(end) >= next) {
      week.push(next.format('MM/DD/YY'));
      next = next.add(1, 'days');
    }
    return week;
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination && this.props.mode === 'yearMode') {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state.dnd.columns[source.droppableId];
    const finish = this.state.dnd.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        dnd: {
          ...this.state.dnd,
          columns: {
            ...this.state.dnd.columns,
            [newColumn.id]: newColumn,
          },
        },
      };
      this.setState(newState);

      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    this.prevDnd = this.state.dnd;

    const newState = {
      ...this.state,
      dnd: {
        ...this.state.dnd,
        columns: {
          ...this.state.dnd.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      },
      showDragConfirmation: true,
    };
    this.setState(newState);

    if (this.props.mode === 'monthMode') {
      const requestId = draggableId;
      this.due_date = this.state.dnd.columns[destination.droppableId].date;
      this.saveRequest = this.props.saveRequestAction.bind(null, requestId, { due_date: this.due_date });
    }
  };

  onDragConfirm = () => {
    if (this.props.mode === 'monthMode' && this.saveRequest) {
      this.saveRequest();
    }
    this.setState({
      showDragConfirmation: false,
    });
  };

  onDragCancel = () => {
    this.setState({
      dnd: this.prevDnd,
      showDragConfirmation: false,
    });
  };

  placeRequests = cb => {
    //prepare tasks logic
    let requests = {};

    this.props.rowData.response_party_requests?.length &&
      //&& !this.state
      this.props.rowData.response_party_requests.map(item => {
        requests = {
          ...requests,
          [item.id]: item,
        };
      });
    let newStateDnd = this.state.dnd;
    Object.keys(requests).map(key => {
      const dueDate = requests[key].due_date;

      let columnId = null;
      if (this.props.mode === 'yearMode') {
        columnId = moment(dueDate).format('YYYY-MMM');
      }

      if (this.props.mode === 'monthMode') {
        columnId = getWeekIndexForDate(moment(dueDate));
      }
      if (this.props.mode === 'weekMode') {
        columnId = moment(dueDate).format('YYYY-MM-DD');
      }

      if (newStateDnd.columns[columnId]) {
        const newTaskIds = Array.from(newStateDnd.columns[columnId].taskIds);
        //const newTaskIds = [];
        if (!newTaskIds.includes(key)) {
          newTaskIds.push(key);
        }
        return (newStateDnd = {
          ...newStateDnd,
          columns: {
            ...newStateDnd.columns,
            [columnId]: {
              ...newStateDnd.columns[columnId],
              taskIds: newTaskIds,
            },
          },
          tasks: {
            ...newStateDnd.tasks,
            [key]: requests[key],
          },
        });
      }
    });

    this.setState(
      {
        dnd: newStateDnd,
      },
      cb,
    );
  };

  openRequest = (requestIsOpen, nf, date) => {
    if (nf) {
      this.props.routerHelper.setUrlNF(nf);
    }
    let dates = {};
    if (this.props.mode === 'weekMode') {
      dates.startDate = date;
      dates.endDate = date;
    } else if (this.props.mode === 'monthMode') {
      const datesValue = getWeekStartEnd(date);
      dates.startDate = datesValue.fromDate.format('YYYY-MM-DD');
      dates.endDate = datesValue.toDate.format('YYYY-MM-DD');
    } else if (this.props.mode === 'yearMode') {
      dates.startDate = moment(date).startOf('month').format('YYYY-MM-DD');
      dates.endDate = moment(date).endOf('month').format('YYYY-MM-DD');
    }

    this.setState({ requestIsOpen, ...dates });
  };

  closeRequest = () => {
    this.props.routerHelper.removeParams();
    this.setState({ requestIsOpen: false });
    this.selectedRequestId = null;
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleShowDropBtn = () => {
    const showDropArrow = this.state.dnd.columnOrder?.some(columnId => {
      const column = this.state.dnd.columns[columnId];
      const tasks = column && column.taskIds && column.taskIds.map(taskId => this.state.dnd.tasks[taskId]);
      return tasks && tasks.length > 6;
    });

    this.setState({ showDropArrow });
  };

  render() {
    const discipline = this.props.rowData;
    const bg_color = discipline.color && this.bgColor(discipline.color);
    const bgDisciplineRow = { backgroundColor: bg_color };
    const bgDisciplineNameBox = { background: this.fixBgColor(discipline.color) };

    const {
      mode,
      zoomStyle,
      zoom,
      location,
      requestCompany,
      responseCompany,
      checkRequestInList,
      setMode,
      reset,
      requestInfo,
      isNonFullWidth,
      fullTableWidth,
      rangeElementsLogic,
      display,
      filters,
    } = this.props;

    const openRowStyle = this.state.isOpen
      ? {
          ...zoomStyle.row,
          height: 'auto',
        }
      : {};

    const whiteBoardScrollClassName = mode !== 'monthMode' || this.props.fullWidth ? 'WhiteBoardCellScroll--fullWidth' : '';

    return (
      <div ref={this.row}>
        <Dialog
          open={this.state.showDragConfirmation}
          onClose={this.onDragCancel}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="dialogPopup"
        >
          <DialogTitle id="form-dialog-title" className="dialogTitle" style={{ background: 'var(--background-sheet)', fontSize: 14 }}>
            <></>
          </DialogTitle>
          <DialogContent className="dialogContent" style={{ padding: '22px 40px' }}>
            Please confirm new due date &quot;{this.due_date}&quot;
          </DialogContent>
          <DialogActions className="dialogActionsWide" style={{ margin: 0, padding: '10px 0' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <button onClick={this.onDragConfirm} className="buttonPopUp__save">
                Confirm
              </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <button onClick={this.onDragCancel} className="buttonPopUp__cancel">
                Reject
              </button>
            </div>
          </DialogActions>
        </Dialog>
        {/*<div style={{position: 'absolute', zIndex: 1000}}><button onClick={this.onDragConfirm}>Success</button><button onClick={this.onDragCancel}>Cancel</button></div>*/}

        {/*  <div className='DisciplineNameBoxFixed' style={bgDisciplineNameBox}>*/}
        {/*    <span>*/}
        {/*      {discipline.title}*/}
        {/*    </span>*/}
        {/*</div>*/}

        <div
          className="WhiteBoardtableRow WhiteBoardtableBodyRow"
          style={
            this.state.isOpen
              ? {
                  ...zoomStyle.row,
                  height: 'auto',
                }
              : {}
          }
        >
          <div
            className="DisciplineNameBox"
            style={{
              // ...bgDisciplineNameBox,
              height: this.props.fullWidth ? 'auto' : '140px',
            }}
          >
            <div
              className={'DisciplineNameBox__mask'}
              style={{
                ...bgDisciplineNameBox,
              }}
            >
              <span>{discipline.title}</span>
            </div>

            {this.state.showDropArrow && this.toggleDropBtn(this.state.isOpen)}
          </div>
          <div
            className={`WhiteBoardCellScroll ${whiteBoardScrollClassName}`}
            ref={this.props.rowRef}
            style={
              mode === 'yearMode'
                ? {
                    ...zoomStyle.row,
                    ...{
                      width: isNonFullWidth ? '100%' : 'auto',
                    },
                    ...openRowStyle,
                  }
                : {
                    ...zoomStyle.row,
                    // paddingRight: rangeElementsLogic.paddingRight === zoomStyle.column.minWidth ? fullTableWidth - zoomStyle.column.minWidth : rangeElementsLogic.paddingRight,
                    // paddingLeft: rangeElementsLogic.paddingLeft,
                    // width:  isNonFullWidth ? `${zoomStyle.column.minWidth}px` : 'auto',
                    width: isNonFullWidth ? '100%' : 'auto',
                    paddingRight: 0,
                    paddingLeft: rangeElementsLogic.paddingLeft,
                    ...openRowStyle,
                  }
            }
          >
            {this.props.mode === 'monthMode' &&
              this.state.dnd.columnOrder?.map((columnId, index) => {
                const column = this.state.dnd.columns[columnId];
                const tasks = column && column.taskIds && column.taskIds.map(taskId => this.state.dnd.tasks[taskId]);

                const i = index + 1;
                if (!(rangeElementsLogic?.rangeElements[0] < i && rangeElementsLogic?.rangeElements[1] > i)) {
                  return <></>;
                }
                return (
                  <Column
                    styles={styles}
                    key={`monthModeColumn-${index}`}
                    column={column}
                    tasks={tasks}
                    openRequest={this.openRequest}
                    findPredecessor={this.props.findPredecessor}
                    displayPredecessor={this.props.displayPredecessor}
                    displaySuccessors={this.props.displaySuccessors}
                    mouseOutFromRequest={this.props.mouseOutFromRequest}
                    zoomStyle={this.props.zoomStyle}
                    zoom={zoom}
                    display={this.props.display}
                    makeBgColor={this.fixBgCardColor}
                    isShowAll={this.props.isShowAll}
                    mode={this.props.mode}
                    isShowAllTasks={this.state.isOpen}
                    location={location}
                    responseCompany={responseCompany}
                    requestCompany={requestCompany}
                    checkRequestInList={checkRequestInList}
                    setMode={setMode}
                    reset={reset}
                    onAddRelations={this.props.onAddRelations}
                    onRemoveRelations={this.props.onRemoveRelations}
                  />
                );
              })}
            {this.props.mode === 'weekMode' &&
              this.state.dnd.columnOrder?.map((columnId, index) => {
                const column = this.state.dnd.columns[columnId];
                const tasks = column && column.taskIds && column.taskIds.map(taskId => this.state.dnd.tasks[taskId]);
                // console.log('tasks', tasks, columnId, column);

                // if (!(rangeElementsLogic?.rangeElements[0] < i && rangeElementsLogic?.rangeElements[1] > i)) {
                //   return <></>;
                // }
                const i = index + 1;
                if (!(rangeElementsLogic?.rangeElements[0] < i && rangeElementsLogic?.rangeElements[1] > i)) {
                  return <></>;
                }
                return (
                  <Column
                    styles={styles}
                    key={`monthModeColumn-${index}`}
                    column={column}
                    tasks={tasks}
                    openRequest={this.openRequest}
                    findPredecessor={this.props.findPredecessor}
                    displayPredecessor={this.props.displayPredecessor}
                    displaySuccessors={this.props.displaySuccessors}
                    mouseOutFromRequest={this.props.mouseOutFromRequest}
                    zoomStyle={this.props.zoomStyle}
                    zoom={zoom}
                    display={this.props.display}
                    makeBgColor={this.fixBgCardColor}
                    isShowAll={this.props.isShowAll}
                    mode={this.props.mode}
                    isShowAllTasks={this.state.isOpen}
                    location={location}
                    responseCompany={responseCompany}
                    requestCompany={requestCompany}
                    checkRequestInList={checkRequestInList}
                    setMode={setMode}
                    reset={reset}
                    onAddRelations={this.props.onAddRelations}
                    onRemoveRelations={this.props.onRemoveRelations}
                  />
                );
              })}
            {this.props.mode === 'yearMode' &&
              this.state.dnd.columnOrder?.map(columnId => {
                const column = this.state.dnd.columns[columnId];
                const tasks = column.taskIds && column.taskIds.map(taskId => this.state.dnd.tasks[taskId]);
                return (
                  <Column
                    styles={styles}
                    key={`yearModeColumn-${column.id}`}
                    column={column}
                    tasks={tasks}
                    openRequest={this.openRequest}
                    findPredecessor={this.props.findPredecessor}
                    displayPredecessor={this.props.displayPredecessor}
                    mouseOutFromRequest={this.props.mouseOutFromRequest}
                    zoomStyle={this.props.zoomStyle}
                    zoom={zoom}
                    display={this.props.display}
                    makeBgColor={this.fixBgCardColor}
                    isShowAll={this.props.isShowAll}
                    mode={this.props.mode}
                    location={location}
                    responseCompany={responseCompany}
                    requestCompany={requestCompany}
                    checkRequestInList={checkRequestInList}
                    reset={reset}
                    isShowAllTasks={this.state.isOpen}
                    onAddRelations={this.props.onAddRelations}
                    onRemoveRelations={this.props.onRemoveRelations}
                    relations={this.props.relations}
                    archerEl={this.props.archerEl}
                    isNonFullWidth={isNonFullWidth}
                  />
                );
              })}
            {/*</DragDropContext>*/}
          </div>
          <Dialog
            open={this.state.requestIsOpen}
            onClose={this.closeRequest}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            disablePortal={true}
            PaperProps={{
              style: {
                overflowY: 'inherit',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                maxWidth: '100%',
                // minHeight: '90%',
                minHeight: 'min-content',
                maxHeight: 'max-content',
                // alignSelf: 'flex-end',
                width: window.innerWidth < 767 && '100%',
                margin: '0',
                top: !(window.innerWidth < 767) ? '75px' : '0',
                position: 'fixed',
              },
              className: 'modalPaper',
            }}
          >
            {/*<RequestCard*/}
            {/*  selectedFilters={WhiteBoardRequestDataHelper.getFiltersData({ display, responseCompany, requestCompany, location }, filters)}*/}
            {/*  applyFiltersFromCard={this.props.applyFiltersFromCard}*/}
            {/*  urlPageForRequestFilter={'whiteboard'}*/}
            {/*  startDate={this.state.startDate}*/}
            {/*  endDate={this.state.endDate}*/}
            {/*  selectRequestId={this.state.requestIsOpen}*/}
            {/*  closeRequest={this.closeRequest}*/}
            {/*  handleSelectedRequest={this.openRequest}*/}
            {/*  isWhiteBoard={true}*/}
            {/*/> Todo*/}
          </Dialog>
        </div>
      </div>
    );
  }

  toggleDropBtn(isOpen) {
    if (isOpen) {
      return (
        <ExpandLess className="DisciplineRowDropBtn" style={{ color: 'var(--color)', cursor: 'pointer' }} onClick={this.handleClose} />
      );
    }
    return <ExpandMore className="DisciplineRowDropBtn" style={{ color: 'var(--color)', cursor: 'pointer' }} onClick={this.handleOpen} />;
  }
}

WhiteBoardtableRow.displayName = 'WhiteBoardtableRow';

WhiteBoardtableRow.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withRouterHelper(WhiteBoardtableRow);
