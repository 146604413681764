import React, { MouseEvent } from 'react';
import SVG from 'react-inlinesvg';
import { useGetThemeClass } from '../../../helpers/designTokens';
import cn from 'classnames';
import './TokenIconStyles.scss';

export interface TokenIconProps {
  iconName:
    | '1st-valid'
    | '3rd-part-sync'
    | '3rd-part-sync-2'
    | 'activate'
    | 'activity'
    | 'apps-menu'
    | 'alert'
    | 'align-center'
    | 'align-left'
    | 'align-right'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-right'
    | 'arrow-up'
    | 'assets'
    | 'at'
    | 'attachment'
    | 'attention'
    | 'bic'
    | 'bold'
    | 'building-lod'
    | 'burger-menu'
    | 'calendar'
    | 'cancel'
    | 'cancel-right'
    | 'check'
    | 'check-broken'
    | 'check-broken-dash'
    | 'chevron-double-left'
    | 'chevron-double-right'
    | 'chevron-double-up'
    | 'chevron-double-down'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'cl-rfi'
    | 'close'
    | 'close-square'
    | 'column-hide-show'
    | 'column-relaxed'
    | 'column-tight'
    | 'collapse-mobile'
    | 'comment'
    | 'comment-2'
    | 'commit'
    | 'compass'
    | 'company'
    | 'coin-hand'
    | 'configuration'
    | 'copy'
    | 'color-swatch'
    | 'created'
    | 'dash'
    | 'dash-square'
    | 'input-data'
    | 'output-data'
    | 'dot-horizontal'
    | 'deactivate'
    | 'deliverables'
    | 'design-constraint'
    | 'drag-&-drop'
    | 'discipline'
    | 'dot'
    | 'download'
    | 'duplicate'
    | 'edit'
    | 'edit-ribbon'
    | 'edit-text'
    | 'ellipse-220'
    | 'expand-mobile'
    | 'file'
    | 'file-shield'
    | 'file-csv'
    | 'file-eye'
    | 'file-doc'
    | 'file-img'
    | 'file-jpeg'
    | 'file-pdf'
    | 'file-png'
    | 'file-txt'
    | 'file-xls'
    | 'file-zip'
    | 'fill-color'
    | 'filter'
    | 'flame'
    | 'flex-columns'
    | 'flow'
    | 'for-record'
    | 'folder-open'
    | 'grid'
    | 'geometry-objects'
    | 'group'
    | 'incorporate'
    | 'information'
    | 'input-check'
    | 'italics'
    | 'kpi-logo-dark'
    | 'kpi-logo-light'
    | 'link'
    | 'link-broken'
    | 'link-locked'
    | 'list-bullet'
    | 'list-number'
    | 'loading'
    | 'logomark'
    | 'line-chart-up'
    | 'logout'
    | 'mirror'
    | 'needs-list'
    | 'new-valid'
    | 'not-verified'
    | 'no-type'
    | 'notification-all'
    | 'notification-off'
    | 'notification-on'
    | 'outdated'
    | 'overflow-menu'
    | 'peek-center'
    | 'peek-right'
    | 'phase-plan'
    | 'plus'
    | 'plus-square'
    | 'predecessor'
    | 'privat'
    | 'projects'
    | 'public'
    | 'pull'
    | 'pen-tool'
    | 'big-chart-square'
    | 'quality-control'
    | 'redmine-icon'
    | 'relink-file'
    | 'receipt-lines'
    | 'remind-later'
    | 'reply'
    | 'reporting'
    | 'rfi'
    | 'rotate-image-left'
    | 'rotate-image-right'
    | 'sandbox'
    | 'sign-off'
    | 'simplified-rows'
    | 'shop-drawings'
    | 'save'
    | 'successor'
    | 'scheduler-logo-dark'
    | 'scheduler-logo-dark-beta'
    | 'scheduler-logo-dark-full'
    | 'scheduler-logo-dark-full-beta'
    | 'scheduler-logo-light'
    | 'scheduler-logo-light-beta'
    | 'scheduler-logo-light-full'
    | 'scheduler-logo-light-full-beta'
    | 'search'
    | 'search-results'
    | 'settings'
    | 'sort'
    | 'standard-request'
    | 'star'
    | 'status'
    | 'stopwatch'
    | 'stopwatch-crash'
    | 'support'
    | 'team'
    | 'trash'
    | 'underline'
    | 'un-mirror'
    | 'updates'
    | 'upload'
    | 'user-add'
    | 'user-check'
    | 'user-right'
    | 'uf-mf'
    | 'valid'
    | 'validDDL'
    | 'void'
    | 'not-verified-triangle'
    | 'not-valid'
    | 'watchlist'
    | 'whiteboard'
    | 'arrow-diagonal'
    | 'integration'
    | 'qc';
  size: 104 | 88 | 40 | 32 | 24 | 20 | 16 | 12 | 4;
  isClickable?: boolean;
  clickHandler?: (e: MouseEvent) => void;
  customClass?: string;
}

const TokenIcon: React.FC<TokenIconProps> = ({ iconName, size, isClickable, customClass, clickHandler }) => {
  const themeClass = useGetThemeClass('b-tokenIcon');

  const handleClick = (e: MouseEvent) => {
    if (clickHandler) {
      e.stopPropagation();
      clickHandler(e);
    }
  };

  return (
    <>
      <SVG
        onClick={handleClick}
        className={cn(themeClass, {
          ['-clickable']: isClickable || clickHandler,
          [`${customClass}`]: customClass,
        })}
        src={require(`../../../assets/tokenIcons/${iconName}/${iconName}-${size || ''}.svg`)}
      />
    </>
  );
};

export default TokenIcon;
