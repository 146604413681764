import React, { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import cn from 'classnames';
import DropdownBasis from '../../DropdownParts/DropdownBasis/DropdownBasis';
import { DropdownGroupItem } from '../../../../../models/global';
import DropdownSelectMenu from '../../DropdownPopovers/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownFooter, DropdownHeader, DropdownMenuItem } from '../../DropdownParts';
import { uniqBy } from 'lodash';
import { UfMfValueModel } from '../../../../../models';
import { useAppDispatch, useAppSelector } from '../../../../../store/configure/configureStore';
import { getConstructionCodes } from '../../../../../store/common/commonThunk';
import TokenIcon from '../../../TokenIcon/TokenIcon';
import ChipsStandardTag from '../../../Chips/ChipsStandardTag/ChipsStandardTag';
import Timeout = NodeJS.Timeout;

import './CodeSelectorStyles.scss';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface NotPredefinedQuery {
  parent_id: number | null;
  search: string | null;
  callback?: (data: any) => void;
}

interface SelectProps<G, IsMultiValue extends boolean> {
  codeType: 'uf' | 'mf';
  handleFor: 'need-form' | 'deliverable' | 'content-plan' | 'all-exist' | 'specification';
  selectedBuildingIds?: number[] | null;
  isValuesRemovable?: boolean;
  isValuesEditable?: boolean;
  isMulti: IsMultiValue;
  customSelectClass?: string;
  groups?: G[];
  selectHandler: (selected: SelectedValue<UfMfValueModel, IsMultiValue>) => void;
  selected: SelectedValue<UfMfValueModel, IsMultiValue>;
  customBasisContainerClass?: string;
  customBasisFieldClass?: string;
  basisPlaceholder?: string;
  renderBasisSelectedItem?: (selected: UfMfValueModel) => ReactElement | ReactNode;
  renderCustomBasis?: (isActive: boolean, disabled: boolean) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  onChangeSearch?: (search: string) => void;
  customHeaderClass?: string;
  headerAdditionalText?: string;
  customFooterClass?: string;
  showFooterType?: 'radio-group' | 'checkBox-group';
  menuItemSize: 'sm' | 'md';
  checkMenuItemDisabled?: (item: UfMfValueModel) => boolean;
  disabled?: boolean;
  menuItemTitleRenderer?: (item: UfMfValueModel) => string | ReactNode;
  menuItemTooltipRenderer?: (item: UfMfValueModel) => string | ReactNode;
  menuItemLeftElemRenderer?: (item: UfMfValueModel) => ReactNode;
  menuItemLeftElemClick?: (item: UfMfValueModel) => void;
  customMenuItemClass?: string;
  customMenuClass?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onMenuClose?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  isAlwaysPlaceholder?: boolean;
  isMenuOpenProps?: boolean;
  isDisableMinWidth?: boolean;
  disableRightElem?: boolean;
  isMobile?: boolean;
  hideBlanks?: boolean;
  isNfCard?: boolean;
  isHighResolution?: boolean;
  selectedType?: UfMfValueModel['type'][];
}

const codesRadioGroup = [
  { value: 'default', title: 'Default' },
  { value: 'reason', title: 'Reason' },
  { value: 'impacted', title: 'Impacted' },
];

const codesRadioGroupForNfCard = [
  { value: 'impacted', title: 'Impacted' },
  { value: 'reason', title: 'Reason' },
];

const codesCheckBoxGroup = [
  { value: 'reason', title: 'Reason' },
  { value: 'impacted', title: 'Impacted' },
];

function CodeSelector<G extends DropdownGroupItem, IsMultiValue extends boolean>({
  codeType,
  handleFor,
  selectedBuildingIds,
  isValuesEditable,
  isValuesRemovable,
  isMulti,
  customSelectClass,
  groups,
  selectHandler,
  selected,
  customBasisContainerClass,
  customBasisFieldClass,
  basisPlaceholder,
  renderBasisSelectedItem,
  renderCustomBasis,
  basisMaxItems,
  error,
  basisTooltip,
  onChangeSearch,
  customHeaderClass,
  headerAdditionalText,
  customFooterClass,
  showFooterType,
  menuItemSize,
  checkMenuItemDisabled,
  disabled,
  menuItemTitleRenderer,
  menuItemTooltipRenderer,
  menuItemLeftElemRenderer,
  menuItemLeftElemClick,
  customMenuItemClass,
  customMenuClass,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isAlwaysPlaceholder,
  isMenuOpenProps,
  isDisableMinWidth,
  disableRightElem,
  isMobile,
  hideBlanks,
  isNfCard,
  selectedType,
  isHighResolution,
}: SelectProps<G, IsMultiValue>) {
  const dispatch = useAppDispatch();

  let timer: Timeout;

  const getConstructionCodesPromise = useRef(null);

  const themeClass = useGetThemeClass('b-codeSelector');

  const selectBasisRef = useRef();

  const constructionCodes = useAppSelector(state => state.commonReducer.constructionCodes);
  const constructionCodesTree = useAppSelector(state => state.commonReducer.constructionCodesTree);
  const constructionCodesLoading = useAppSelector(state => state.commonReducer.constructionCodesLoading);
  const constructionCodesLoadingType = useAppSelector(state => state.commonReducer.constructionCodesLoadingType);
  const projectId = useAppSelector(state => state.userReducer.active_project_id);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [codeOptions, setCodeOptions] = useState<UfMfValueModel[]>(null);
  const [codesTree, setCodesTree] = useState<UfMfValueModel[]>(null);
  const [editingCode, setEditingCode] = useState<UfMfValueModel>(null);
  const [radioState, setRadioState] = useState<UfMfValueModel['type']>(isNfCard ? 'impacted' : 'default');
  const [checkBoxState, setCheckBoxState] = useState<UfMfValueModel['type'][]>([]);

  const isSelectorCodesLoading = useMemo(() => {
    return constructionCodesLoading.loading && constructionCodesLoadingType === codeType;
  }, [constructionCodesLoading.loading, constructionCodesLoadingType, codeType]);

  useEffect(() => {
    return () => {
      setCodeOptions(null);
      setCodesTree(null);
      setSearchValue('');
    };
  }, []);

  useEffect(() => {
    if (hideBlanks) {
      setCodeOptions(constructionCodes?.[codeType].filter(item => item.code !== 0));
    } else {
      setCodeOptions(constructionCodes?.[codeType]);
    }
  }, [constructionCodes]);

  useEffect(() => {
    if (isMenuOpenProps) {
      openMenu();
    }
  }, [isMenuOpenProps]);

  useEffect(() => {
    setCodesTree(constructionCodesTree?.[codeType]);
  }, [constructionCodesTree]);

  useEffect(() => {
    if (!selectedType?.length) {
      setCheckBoxState([]);
    } else {
      setCheckBoxState(selectedType);
    }
  }, [selectedType]);

  const loadConstructionCodes = (query: NotPredefinedQuery) => {
    getConstructionCodesPromise.current?.abort();
    getConstructionCodesPromise.current = dispatch(
      getConstructionCodes({
        ...query,
        project_id: projectId,
        table: codeType,
        handle_for: handleFor,
        building_ids: selectedBuildingIds || null,
      }),
    );
  };

  const initData = () => {
    loadConstructionCodes({
      parent_id: null,
      search: null,
      callback: () => setIsMenuOpen(true),
    });
  };

  const handleClearState = () => {
    setCodeOptions([]);
    setCodesTree([]);
    setSearchValue('');
    setEditingCode(null);
    setRadioState(isNfCard ? 'impacted' : 'default');
  };

  const openMenu = (e?: MouseEvent) => {
    initData();
  };

  const closeMenu = (event?: Record<string, unknown>) => {
    setIsMenuOpen(false);
    handleClearState();
  };

  const changeSearch = (search: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      loadConstructionCodes({
        parent_id: null,
        search: search?.length > 2 ? search : null,
      });
    }, 200);

    setSearchValue(search);
    onChangeSearch && onChangeSearch(search);
  };

  const handleSelectItem = (value: string | number) => {
    let newSelectedItem = codeOptions.find(item => item.id === value);
    const isExist = newSelectedItem.is_exists;
    const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(newSelectedItem) : !isExist;

    if (isDisabled) {
      return;
    }

    newSelectedItem = { ...newSelectedItem, type: radioState, typeFilter: checkBoxState };

    if (!newSelectedItem?.parents && codesTree && codesTree.length > 0) {
      newSelectedItem = { ...newSelectedItem, parents: codesTree.map(code => code.id) };
    }

    if (isMulti) {
      let newSelectedItems;

      if (selected) {
        newSelectedItems = [...selected];
      } else {
        newSelectedItems = [];
      }

      if (editingCode) {
        const editingItemIndex = newSelectedItems.findIndex(sItem => sItem.id === editingCode.id);
        newSelectedItems.splice(editingItemIndex, 1, newSelectedItem);
      } else {
        const selectedItemIndex = newSelectedItems.findIndex(sItem => sItem.id === value);
        const isItemSelected = selectedItemIndex !== -1;

        if (isItemSelected) {
          newSelectedItems.splice(selectedItemIndex, 1);
        } else {
          newSelectedItems.push(newSelectedItem);
        }
      }

      selectHandler(uniqBy(newSelectedItems, 'id'));
    } else {
      selectHandler(newSelectedItem);
    }

    if (!isMulti || (isMulti && editingCode)) {
      closeMenu();
    }
  };

  const loadCodeNesting = (item: UfMfValueModel, disableNestingCheck?: boolean) => {
    if (!disableNestingCheck) {
      return;
    }

    loadConstructionCodes({
      parent_id: item.id,
      search: null,
    });
  };

  const handleMenuItemLeftElemClick = (value: string | number) => {
    const leftElemClickedItem = codeOptions.find(item => item.id === value);

    menuItemLeftElemClick(leftElemClickedItem);
  };

  const handleMenuItemRightElemClick = (value: string | number) => {
    const rightElemClickedItem = codeOptions.find(item => item.id === value);

    loadCodeNesting(rightElemClickedItem, true);
  };

  const menuOpenHandler = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    onMenuOpen && onMenuOpen(event);
  };

  const menuCloseHandler = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    closeMenu(event);
    onMenuClose && onMenuClose(event);
  };

  const selectAll = () => {
    let allowedItems = codeOptions.filter(item => {
      const isExist = item.is_exists;
      const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(item) : !isExist;

      return !isDisabled;
    });

    allowedItems = allowedItems.map(item => {
      return { ...item, type: radioState, typeFilter: checkBoxState };
    });

    if (codesTree && codesTree.length > 0) {
      allowedItems = allowedItems.map(item => {
        if (!item.parents) {
          return { ...item, parents: codesTree.map(code => code.id) };
        }

        return item;
      });
    }

    const newSelectedItems = uniqBy([...(selected || []), ...allowedItems], 'id');

    selectHandler(newSelectedItems);
  };

  const clearAll = () => {
    selectHandler([]);
  };

  const handleRemove = (value: string | number) => {
    if (!isValuesRemovable) {
      return;
    }

    const selectedCodeIndex = selected.findIndex(item => item.id === value);

    const selectedCurrentValue = [...selected];
    selectedCurrentValue.splice(selectedCodeIndex, 1);
    selectHandler(selectedCurrentValue);
  };

  const handleEdit = (item: UfMfValueModel) => {
    if (!isValuesEditable) {
      return;
    }

    loadCodeNesting(item, true);
    setIsMenuOpen(true);
    setRadioState(item.type);
    setEditingCode(item);
  };

  const handleStopEdit = () => {
    setRadioState('default');
    setEditingCode(null);
  };

  const handleRadioState = (radioValue: UfMfValueModel['type']) => {
    if (editingCode) {
      const newSelected = [...selected];
      const newEditingCode = { ...editingCode, type: radioValue };
      const editingCodeIndex = newSelected.findIndex(item => item.id == newEditingCode.id);

      newSelected.splice(editingCodeIndex, 1, newEditingCode);

      setEditingCode(newEditingCode);
      selectHandler(newSelected);
    }

    setRadioState(radioValue);
  };

  const handleCheckBoxState = (checkBoxValues: UfMfValueModel['type']) => {
    const newcheckBoxState = [...checkBoxState];
    const newcheckBoxStateIndex = newcheckBoxState.findIndex(item => item == checkBoxValues);
    if (newcheckBoxStateIndex > -1) {
      newcheckBoxState.splice(newcheckBoxStateIndex, 1);
    } else {
      newcheckBoxState.push(checkBoxValues);
    }
    const newSelected = [...selected].map(item => {
      const obj = { ...item };
      obj.typeFilter = newcheckBoxState;
      return obj;
    });
    setCheckBoxState(newcheckBoxState);
    selectHandler(newSelected);
  };

  const clickBreadcrumb = (item: UfMfValueModel) => {
    loadCodeNesting(item, true);
  };

  const renderHeader = () => {
    return (
      <DropdownHeader<UfMfValueModel>
        searchValue={searchValue}
        onChangeSearch={changeSearch}
        customClassName={customHeaderClass}
        additionalText={headerAdditionalText}
        onClearAll={isMulti ? clearAll : null}
        onSelectAll={isMulti ? selectAll : null}
        breadcrumbs={codesTree}
        breadcrumbOnClick={clickBreadcrumb}
        selectedBreadcrumb={codesTree?.[codesTree.length - 1]}
        isMobile={isMobile}
        isResetBtn={isMobile && !!searchValue}
      />
    );
  };

  const renderFooter = () => {
    return (
      <DropdownFooter<UfMfValueModel['type']>
        type={'radio-group'}
        onButtonClick={() => null}
        buttonTitle={''}
        radioGroup={isNfCard ? codesRadioGroupForNfCard : codesRadioGroup}
        radioClickHandler={handleRadioState}
        selectedRadioValue={radioState}
        customClassName={customFooterClass}
        isMobile={isMobile}
      />
    );
  };

  const renderFooterFilter = () => {
    return (
      <DropdownFooter<UfMfValueModel['type']>
        type={'checkBox-group'}
        onButtonClick={() => null}
        buttonTitle={''}
        codesCheckBoxGroup={codesCheckBoxGroup}
        checkBoxClickHandler={handleCheckBoxState}
        selectedcheckBoxValues={checkBoxState}
        customClassName={customFooterClass}
        isMobile={isMobile}
        isDisabledBtn={!selected.length}
      />
    );
  };

  const renderMenuItem = (item: UfMfValueModel) => {
    const isExist = item.is_exists;
    const codeTitle = `${item.code} - ${item.title}`;
    const isDisabled = checkMenuItemDisabled ? checkMenuItemDisabled(item) : !isExist;
    const title = menuItemTitleRenderer ? menuItemTitleRenderer(item) : codeTitle;
    const tooltip = menuItemTooltipRenderer ? menuItemTooltipRenderer(item) : null;
    const leftElem = menuItemLeftElemRenderer ? menuItemLeftElemRenderer(item) : null;
    const isSelected = isMulti ? !!selected?.find(sItem => item.id === sItem.id) : item.id === selected?.id;

    return (
      <DropdownMenuItem
        isMulti={isMulti}
        size={menuItemSize}
        disabled={isDisabled}
        title={title}
        value={item.id}
        onItemClick={handleSelectItem}
        tooltip={tooltip}
        isSelected={isSelected}
        leftElem={leftElem}
        leftElemClick={handleMenuItemLeftElemClick}
        rightElem={
          <>
            {item.hasNesting && (
              <span className={`${themeClass}_nestingIcon`}>
                <TokenIcon iconName={'chevron-right'} size={16} isClickable />
              </span>
            )}
          </>
        }
        rightElemClick={handleMenuItemRightElemClick}
        customClassName={customMenuItemClass}
        isMobile={isMobile}
      />
    );
  };

  const renderDefaultBasisItem = (item: UfMfValueModel) => {
    const typeToIndicator = {
      default: null,
      reason: 'blue',
      impacted: 'red',
    };

    if (isMulti) {
      return (
        <ChipsStandardTag
          value={item.code}
          indicator={typeToIndicator[item.type] || ''}
          customIcon={isValuesRemovable && <TokenIcon iconName={'close'} size={12} />}
          clickHandler={() => handleEdit(item)}
          onClickIcon={() => handleRemove(item.id)}
          tooltip={`${item.code} - ${item?.title || item?.description || ''}`}
        />
      );
    }

    return item.code;
  };

  const getRenderItemFooter = () => {
    if (codeType === 'uf') {
      if (showFooterType === 'radio-group') {
        return renderFooter;
      }
      if (showFooterType === 'checkBox-group' && selected) {
        return renderFooterFilter;
      }
      return null;
    }
    return null;
  };

  return (
    <>
      <div
        className={cn(themeClass, {
          [`${customSelectClass}`]: customSelectClass,
        })}
      >
        <DropdownBasis<UfMfValueModel, IsMultiValue>
          isMulti={isMulti}
          mRef={selectBasisRef}
          customClassNameContainer={customBasisContainerClass}
          customClassNameField={customBasisFieldClass}
          selected={selected}
          placeholder={basisPlaceholder}
          renderSelected={renderBasisSelectedItem ? renderBasisSelectedItem : renderDefaultBasisItem}
          renderCustomBasis={renderCustomBasis}
          basisClickHandler={openMenu}
          isActive={isMenuOpen}
          isLoading={!isMenuOpen && isSelectorCodesLoading}
          error={error}
          disabled={disabled}
          tooltip={basisTooltip}
          maxElements={basisMaxItems}
          isAlwaysPlaceholder={isAlwaysPlaceholder}
          isDisableMinWidth={isDisableMinWidth}
          disableRightElem={disableRightElem}
          isMobile={isMobile}
        />
        <DropdownSelectMenu<UfMfValueModel, G>
          isMenuOpen={isMenuOpen}
          isLoading={isSelectorCodesLoading}
          customClassName={customMenuClass}
          renderItemHeader={renderHeader}
          renderItemFooter={getRenderItemFooter()}
          renderMenuItem={renderMenuItem}
          menuItems={codeOptions}
          size={menuItemSize}
          groups={groups}
          groupingType={menuGroupingType}
          anchorEl={selectBasisRef.current}
          onOpen={menuOpenHandler}
          onClose={menuCloseHandler}
          isMobile={isMobile}
          isHighResolution={isHighResolution}
        />
      </div>
    </>
  );
}

export default CodeSelector;
