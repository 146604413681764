import { createSelector } from 'reselect';
import { LocationCollectionModel, RequestBuildingModel } from '../models';

const selector = data => data;

export const dataSelector = createSelector(selector, data => {
  if (data) {
    return data;
  }
  return null;
});

export const levelsSelector = createSelector(selector, ({ projectReducer, userReducer }): LocationCollectionModel[] | null => {
  const activeProject = userReducer.active_project_id;
  if (!activeProject) return null;
  return Object.values(projectReducer.projectInfo.levels[activeProject]);
});

export const typicalLayoutsSelector = createSelector(selector, ({ projectReducer, userReducer }): LocationCollectionModel[] | null => {
  const activeProject = userReducer.active_project_id;
  if (!activeProject) return null;
  return Object.values(projectReducer.projectInfo.typical_layouts[activeProject]);
});

export const buildingSpacesSelector = createSelector(selector, ({ projectReducer, userReducer }) => {
  const activeProject = userReducer.active_project_id;
  if (!activeProject) return null;
  return projectReducer.projectInfo.building_spaces[activeProject];
});

export const buildingsSelector = createSelector(selector, ({ projectReducer, userReducer }): RequestBuildingModel[] | null => {
  const activeProject = userReducer.active_project_id;
  if (!activeProject) return null;
  return projectReducer.projectInfo.buildings[activeProject];
});

export const disciplinesSelector = createSelector(selector, data => {
  const activeProject = data.userReducer.active_project_id;
  const projectdisciplines = data.projectReducer.projectInfo.disciplines;
  if (!activeProject) return null;

  if (projectdisciplines) {
    return projectdisciplines[activeProject];
  }
});

export const milestonesSelector = createSelector(selector, data => {
  const activeProject = data.userReducer.active_project_id;
  if (!activeProject) return null;
  return data.projectReducer.projectInfo.milestones[activeProject];
});

export const codesSelector = createSelector(selector, data => {
  if (data) {
    return Object.values(data);
  }
  return null;
});
