import { BallInCourtModel } from '../../models/request/request.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

import requestApi from '../../service/Api/requestApi';
import * as settingsTypes from '../globalSettings/settingsActions';
import * as historyActions from '../history/historyActions';
import {
  ICommentData,
  ICommentInfo,
  ICommentsGetPayloadData,
  ICommentsCreatePayloadData,
  ICommentsReplyPayloadData,
  ICommentsDeletePayloadData,
  ICommentsUpdatePayloadData,
  IBICPayloadData,
  IBICEditPayload,
  IFeedGetPayloadData,
  IResponseFeedPayload,
  ICommentUnAssigmenttPayload,
} from './commentReducer.model';
import { settingsSlice } from '../globalSettings/settingsSlice';
import { getHistory } from '../history/historyThunk';
import { errorsSlice } from '../errors/errorsSlice';
const { filesUploadClean } = settingsSlice.actions;
const { setShowErrorData } = errorsSlice.actions;

export const getComments = createAsyncThunk<{ data: ICommentInfo; response: { can_comment: boolean } }, ICommentsGetPayloadData>(
  'commentReducer/getComments',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectParam = payload;
      const type = projectParam.type;
      if (projectParam.isNew) {
        delete projectParam.isNew;
        projectParam.ipp = 5;
      }
      if (type) {
        delete projectParam.type;
      }
      const response = await requestApi.getComments(type, projectParam);

      return response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
export const getFeed = createAsyncThunk<IResponseFeedPayload, IFeedGetPayloadData>(
  'commentReducer/getFeed',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const projectParam = payload;
    const type = projectParam.type;
    try {
      const response = await requestApi.getFeed(type, projectParam);

      return response.response;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const createComments = createAsyncThunk<ICommentData, ICommentsCreatePayloadData>(
  'commentReducer/createComments',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { projectParam, callback } = payload;
      const type = projectParam.type;
      if (type) {
        delete projectParam.type;
      }
      const response = await requestApi.createComments(type, projectParam);

      dispatch(getFeed({ owner_id: response.data.owner_id, type }));
      dispatch(filesUploadClean({ type: 'comment' }));

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const updateComments = createAsyncThunk<ICommentData, ICommentsUpdatePayloadData>(
  'commentReducer/updateComments',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectParam = payload;
      const type = projectParam.type;

      if (type) {
        delete projectParam.type;
      }
      const response = await requestApi.updateComments(type, projectParam);

      dispatch(getFeed({ owner_id: response.data.owner_id, type }));

      dispatch(filesUploadClean({ type: 'comment' }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const unAssigmentUser = createAsyncThunk<any, ICommentUnAssigmenttPayload>(
  'commentReducer/unAssigmentUser',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const projectParam = payload;
      const { callback, user_ids } = payload;
      const type = projectParam.type;

      if (type) {
        delete projectParam.type;
      }
      if (!user_ids) {
        delete projectParam.user_ids;
      }
      const response = await requestApi.unAssigmentUser(type, projectParam);

      if (callback) {
        callback();
      }

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const replyComments = createAsyncThunk<ICommentData, ICommentsReplyPayloadData>(
  'commentReducer/replyComments',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const formData = payload;
      const type = formData.type;
      if (type) {
        delete formData.type;
      }
      const response = await requestApi.createComments(type, formData);

      dispatch(getFeed({ owner_id: response.data.owner_id, type }));

      dispatch(filesUploadClean({ type: 'comment' }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const deleteComments = createAsyncThunk<ICommentData, ICommentsDeletePayloadData>(
  'commentReducer/deleteComments',
  async (payload, { dispatch, rejectWithValue }) => {
    const { type, ...data } = payload;
    try {
      const response = await requestApi.deleteComments(type, data);
      dispatch(getFeed({ owner_id: response.data.owner_id, type }));

      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const getBallInCourtList = createAsyncThunk('commentReducer/getBallInCourt', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const projectId = sessionStorage.getItem('active_project_id');
    const response = await requestApi.getBallInCourtList(projectId);
    return response.data;
  } catch (error) {
    dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
    return rejectWithValue(error);
  }
});

export const addBallInCourt = createAsyncThunk<BallInCourtModel, IBICPayloadData>(
  'commentReducer/addBallInCourt',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { projectParam, callback } = payload;
      const response = await requestApi.addBallInCourt(projectParam);
      dispatch(getFeed({ owner_id: response.data.entity_id }));
      if (callback) {
        callback();
      }
      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);

export const editBallInCourt = createAsyncThunk<BallInCourtModel, { data: IBICEditPayload }>(
  'commentReducer/editBallInCourt',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await requestApi.editBallInCourt(payload.data);
      dispatch(getFeed({ owner_id: response.data.entity_id }));
      return response.data;
    } catch (error) {
      dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
