import { BaseApi } from '../baseApi';
import { ConstructorParams } from '../pcd/types';
import { GlobalFiltersQueryModel } from '../../../models';
import { RemoveSubmittalCardPayload } from '../../../models/submittals/submittals.model';

class SubmittalsApi extends BaseApi {
  devMode: boolean;

  constructor({ baseURL, devMode }: ConstructorParams) {
    super(baseURL);
    this.devMode = devMode;
  }

  getListSubmittalsForms(payload: GlobalFiltersQueryModel) {
    return this._post({
      route: `submittal/list-submittal-forms`,
      needAuth: true,
      devMode: this.devMode,
      bodyParams: {
        ...payload,
      },
    });
  }

  getSubmittalsColumnSettings() {
    return this._get({ route: 'submittal/get-column-settings', needAuth: true, devMode: this.devMode });
  }

  setSubmittalsColumnSettings(bodyParams) {
    return this._post({ route: 'submittal/set-column-settings', bodyParams, needAuth: true, devMode: this.devMode });
  }

  getSpecTableData(bodyParams) {
    return this._post({ route: `/content-plan/get-all`, bodyParams, needAuth: true, devMode: this.devMode });
  }
  getSpecTableListActive(bodyParams) {
    return this._post({ route: `/content-plan/list-active`, bodyParams, needAuth: true, devMode: this.devMode });
  }

  getSubmittalsCard(submittalId: number) {
    return this._get({
      route: `submittal/${submittalId}/show-submittal`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  getSubmittalTags() {
    return this._get({
      route: `submittal/get-type`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async updateSubmittal(bodyParams, selectSubmittalId, files, isNotFormData) {
    if (!selectSubmittalId) {
      return false;
    }
    return await this._post({
      route: `submittal/update`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
      formData: !isNotFormData,
      files: files,
    });
  }

  async createSubmittal(bodyParams, files, isNotFormData) {
    return await this._post({
      route: `submittal/create`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
      formData: !isNotFormData,
      files: files,
    });
  }

  async createRevision(submittalId) {
    return await this._post({
      route: `submittal/${submittalId}/create-version`,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async removeSubmittalCard(data: RemoveSubmittalCardPayload) {
    return await this._delete({
      route: `submittal/delete`,
      bodyParams: data,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async updateBulkEdit(bodyParams) {
    return await this._post({
      route: `submittal/update-bulk`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
  }

  async setSyncProcore(id, bodyParams) {
    const result = await this._post({
      route: `submittal/${id}/set-sync-procore`,
      bodyParams,
      needAuth: true,
      devMode: this.devMode,
    });
    return result;
  }
}

export default new SubmittalsApi({ devMode: false });
