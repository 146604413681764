import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import { DropdownGroupItem, DropdownItemModel } from '../../../../../models/global';
import { Select } from '../../../../controls/Dropdowns/DropdownСomponents';
import ChipsDiscipline from '../../../../controls/Chips/ChipsDiscipline/ChipsDiscipline';
import ChipsStandardTag from '../../../../controls/Chips/ChipsStandardTag/ChipsStandardTag';
import { useGetThemeClass } from '../../../../../helpers/designTokens';
import BuildingItem from '../BuildingItem/BuildingItem';
import { PCDApi } from '../../../../../service/Api/pcd/types';
import DrawingItem from '../DrawingItem/DrawingItem';
import DeliverablesItem from '../DeliverablesItem/DeliverablesItem';
import TokenIcon from '../../../../controls/TokenIcon/TokenIcon';
import SubmittalsItem from '../../../RelatedItemsTab/controls/SubmittalsItem/SubmittalsItem';
import {
  FilterNameItemInterface,
  FilterOptionItemInterface,
  MfCodeListItemsInterface,
  SubmittalListItemsInterface,
} from '../../../../../store/relatedItems/relatedItemsSlice.model';
import MfCodeItem from '../../../RelatedItemsTab/controls/MfCodeItem/MfCodeItem';
import CustomTooltip from '../../../../controls/Tooltip/Tooltip';
import FilterNameItem from '../../../RelatedItemsTab/controls/RelatedItemsFiltersSelect/FilterNameItem/FilterNameItem';
import FilterOptionItem from '../../../RelatedItemsTab/controls/RelatedItemsFiltersSelect/FilterOptionItem/FilterOptionItem';

import './CardRenderSelectStyles.scss';

type SingleValue<Option> = Option | null;
type MultiValue<Option> = readonly Option[];
type SelectedValue<Option, IsMultiValue> = IsMultiValue extends boolean ? MultiValue<Option> : SingleValue<Option>;

interface CardRenderSelectProps<T, G, IsMultiValue extends boolean> {
  type: 'building' | 'building spaces' | 'design input' | 'deliverables' | 'submittals' | 'submittalInput' | 'submittalOutput' | 'filter';
  isMulti: IsMultiValue;
  isHeader?: boolean;
  isFooter?: boolean;
  menuItems: T[];
  groups?: G[];
  selectHandler: (selected: SelectedValue<T, IsMultiValue>) => void;
  selected: SelectedValue<T, IsMultiValue>;
  isLoading?: boolean;
  customBasisFieldClass?: string;
  basisPlaceholder: string;
  renderCustomBasis?: (
    isActive: boolean,
    disabled: boolean,
    isLoading: boolean,
    selected: SelectedValue<T, IsMultiValue>,
  ) => ReactElement | ReactNode;
  basisMaxItems?: number;
  error?: string;
  basisTooltip?: string | ReactNode;
  onChangeSearch?: (search: string) => void;
  headerAdditionalText?: string;
  footerType?: 'divided-button' | 'radio-group';
  footerButtonClick?: (e: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  footerButtonTitle?: string | ReactElement;
  footerButtonLoading?: boolean;
  menuItemSize: 'sm' | 'md';
  disabled?: boolean;
  customDisabled?: boolean;
  menuItemLeftElemRenderer?: (item: T) => ReactNode;
  menuItemRightTextRenderer?: (item: T) => ReactNode;
  customMenuItemClass?: string;
  customMenuClass?: string;
  customClassPaperName?: string;
  menuGroupingType?: 'labeled' | 'divided';
  onMenuOpen?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onMenuClose?: (event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  isOpenMenu?: boolean;
  customOpening?: boolean;
  handleBuildingItemClick?: (building: PCDApi.PCDListBuildingInterface) => void;
  handleMfItemClick?: (mfCode: MfCodeListItemsInterface) => void;
  handleFilterNameItemClick?: (filterName: FilterNameItemInterface) => void;
  handleDrawingItemClick?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  handleDeliverablesItemClick?: (event: React.SyntheticEvent, deliverable: PCDApi.PCDListDrawingInterface) => void;
  handleSubmittalsItemClick?: (event: React.SyntheticEvent, submittal: SubmittalListItemsInterface) => void;
  handleFilterOptionItemClick?: (event: React.SyntheticEvent, filterOption: FilterOptionItemInterface) => void;
  handleOpenLinkPopover: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface[]) => void;
  isFullWidth?: boolean;
  handleSelectedDrawingDelete?: (event: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  handleSelectedSubmittalDelete?: (event: React.SyntheticEvent, submittal: SubmittalListItemsInterface, type: string) => void;
  getBuildingsTitle?: (building_ids: number[]) => string;
  handleOpenDDManageStatuses?: () => void;
  handleOpenDDItem?: (item: DropdownItemModel) => void;
  handleRemoveDDItem?: (item: DropdownItemModel) => void;
  isAccess?: boolean;
  isAccessToRemove?: boolean;
  disableSelectAll?: boolean;
  handleDrawingCheckBoxClick?: (e: React.SyntheticEvent, drawing: PCDApi.PCDListDrawingInterface) => void;
  isHeaderNoMulti?: boolean;
  isExpandOnHover?: boolean;
  customClearAll?: () => void;
  isDisableMinWidth?: boolean;
  selectType?: string;
  disableRightElem?: boolean;
  isMobile?: boolean;
  isHighResolution?: boolean;
  isCustomMulti?: boolean;
  customSelectAll?: () => void;
  selectedDeliverablesIds?: number[];
  deliverablesCustomClassName?: string;
  isSelectMenuClose?: boolean;
  isActiveCheckBoxForDisabledItems?: boolean;
  isHideSearch?: boolean;
}

function CardRenderSelect<T extends DropdownItemModel, G extends DropdownGroupItem, IsMultiValue extends boolean>({
  type,
  isMulti,
  isHeader,
  isFooter,
  disableSelectAll,
  menuItems,
  groups,
  selectHandler,
  selected,
  isLoading,
  customBasisFieldClass,
  basisPlaceholder,
  renderCustomBasis,
  basisMaxItems,
  error,
  basisTooltip,
  onChangeSearch,
  headerAdditionalText,
  footerType,
  footerButtonClick,
  footerButtonTitle,
  footerButtonLoading,
  menuItemSize,
  disabled,
  customDisabled,
  menuItemLeftElemRenderer,
  menuItemRightTextRenderer,
  customMenuItemClass,
  customMenuClass,
  customClassPaperName,
  menuGroupingType,
  onMenuOpen,
  onMenuClose,
  isOpenMenu,
  customOpening,
  handleBuildingItemClick,
  handleMfItemClick,
  handleFilterNameItemClick,
  handleDrawingItemClick,
  handleDeliverablesItemClick,
  handleSubmittalsItemClick,
  handleFilterOptionItemClick,
  handleOpenLinkPopover,
  isFullWidth,
  handleSelectedDrawingDelete,
  handleSelectedSubmittalDelete,
  getBuildingsTitle,
  handleOpenDDManageStatuses,
  handleOpenDDItem,
  handleRemoveDDItem,
  isAccess,
  isAccessToRemove,
  handleDrawingCheckBoxClick,
  isHeaderNoMulti,
  isExpandOnHover,
  customClearAll,
  isDisableMinWidth,
  selectType,
  disableRightElem,
  isMobile,
  isCustomMulti,
  customSelectAll,
  selectedDeliverablesIds,
  deliverablesCustomClassName,
  isSelectMenuClose,
  isActiveCheckBoxForDisabledItems,
  isHighResolution,
  isHideSearch,
}: CardRenderSelectProps<T, G, IsMultiValue>) {
  const textClass = useGetThemeClass('b-testTextDark b-testTextDark');
  const themeClass = useGetThemeClass('b-cardRenderSelect');

  const renderSelectItem = (item: DropdownItemModel) => {
    if (type === 'design input') {
      return (
        <>
          <CustomTooltip className={`${themeClass}_tooltip`} title={`${item?.number || ''} - ${item?.title || ''}`}>
            <ChipsDiscipline type={'filled'} color={'additional'} value={`${item?.number || ''} - ${item?.title || ''}`} />
          </CustomTooltip>
          <div className={textClass}>{getBuildingsTitle(item.building_ids)}</div>
        </>
      );
    }

    if (type === 'deliverables') {
      if (item.itemType === 'building') {
        return (
          <>
            <BuildingItem building={item} handleBuildingItemClick={handleBuildingItemClick} />
          </>
        );
      }
      if (item.itemType === 'drawing') {
        return (
          <>
            <DrawingItem
              drawing={item}
              handleDrawingItemClick={handleDrawingItemClick}
              handleDrawingCheckBoxClick={handleDrawingCheckBoxClick}
              isActiveCheckBoxForDisabledItems={isActiveCheckBoxForDisabledItems && !!item.disabled}
            />
          </>
        );
      }
      if (item.itemType === 'deliverable') {
        return (
          <>
            <DeliverablesItem
              deliverable={item}
              handleDeliverablesItemClick={handleDeliverablesItemClick}
              isActiveCheckBoxForDisabledItems={isActiveCheckBoxForDisabledItems && !!item.is_locked}
            />
          </>
        );
      }
    }

    if (type === 'submittals' || type === 'submittalInput' || type === 'submittalOutput') {
      if (item.itemType === 'mfCode') {
        return <MfCodeItem mfCode={item} handleMfItemClick={handleMfItemClick} />;
      }

      if (item.itemType === 'sf') {
        return (
          <>
            <SubmittalsItem
              submittal={item}
              handleSubmittalsItemClick={handleSubmittalsItemClick}
              isActiveCheckBoxForDisabledItems={isActiveCheckBoxForDisabledItems && !!item.is_locked}
            />
          </>
        );
      }
    }

    if (type === 'filter') {
      if (item.itemType === 'filterName') {
        return <FilterNameItem filterNameItem={item as FilterNameItemInterface} handleFilterNameItemClick={handleFilterNameItemClick} />;
      }

      if (item.itemType === 'filterOption') {
        return (
          <FilterOptionItem filterOption={item as FilterOptionItemInterface} handleFilterOptionItemClick={handleFilterOptionItemClick} />
        );
      }
    }

    return item.title;
  };

  const renderSelectBasisItem = (item: DropdownItemModel) => {
    if (type === 'design input') {
      if (isAccess && isAccessToRemove) {
        return (
          <>
            <ChipsStandardTag
              value={item.number}
              leftIcon={menuItemLeftElemRenderer}
              item={item}
              customIcon={<TokenIcon iconName={'close'} size={12} />}
              clickHandler={() => (item.status === 'not_verified' ? handleOpenDDItem(item) : null)}
              onClickIcon={() => handleRemoveDDItem(item)}
              blockStopPropagation={item.status !== 'not_verified'}
            />
          </>
        );
      } else {
        return (
          <>
            <ChipsStandardTag
              value={item.number}
              leftIcon={menuItemLeftElemRenderer}
              item={item}
              clickHandler={() => (item.status === 'not_verified' ? handleOpenDDItem(item) : null)}
              blockStopPropagation={item.status !== 'not_verified'}
            />
          </>
        );
      }
    }

    if (type === 'deliverables') {
      const count_all_deliverables = item?.deliverables?.length;
      const select_deliverables = item?.deliverables.filter(deliverable => selectedDeliverablesIds.includes(deliverable.id));
      const count_select_deliverables = select_deliverables?.length;
      const tooltip_text = select_deliverables.map(t => <div key={t.id}>{t.title}</div>);
      const chips_text =
        count_all_deliverables === count_select_deliverables
          ? `${item.shot_title} (ALL)`
          : count_select_deliverables === 1
          ? select_deliverables[0]?.shot_title
          : `${item.shot_title} (${count_select_deliverables})`;

      return (
        <ChipsStandardTag
          clickHandler={e => handleOpenLinkPopover(e, item)}
          value={chips_text}
          customIcon={disabled ? null : <TokenIcon iconName={'close'} size={12} />}
          onClickIcon={e => handleSelectedDrawingDelete(e, item)}
          tooltip={tooltip_text}
          customClassName={deliverablesCustomClassName}
        />
      );
    }

    if (type === 'submittalInput' || type === 'submittalOutput') {
      return (
        <ChipsStandardTag
          clickHandler={e => handleOpenLinkPopover(e, item)}
          value={item.sf_index_shot}
          customIcon={disabled ? null : <TokenIcon iconName={'close'} size={12} />}
          onClickIcon={e => handleSelectedSubmittalDelete(e, item, type)}
          tooltip={item.tooltip}
          customClassName={deliverablesCustomClassName}
        />
      );
    }

    return item.title;
  };

  return (
    <>
      <Select<T, G, IsMultiValue>
        isMulti={isMulti}
        isHeader={isHeader}
        isFooter={isFooter}
        disableSelectAll={disableSelectAll}
        menuItems={menuItems}
        groups={groups}
        menuGroupingType={menuGroupingType}
        selectHandler={selectHandler}
        selected={selected}
        headerAdditionalText={headerAdditionalText}
        isLoading={isLoading}
        basisPlaceholder={basisPlaceholder}
        renderBasisSelectedItem={type !== 'building' && renderSelectBasisItem}
        footerButtonClick={footerButtonClick}
        footerButtonTitle={footerButtonTitle}
        footerButtonLoading={footerButtonLoading}
        footerType={footerType}
        menuItemSize={menuItemSize}
        menuItemTitleRenderer={renderSelectItem}
        customBasisFieldClass={customBasisFieldClass}
        renderCustomBasis={renderCustomBasis}
        error={error}
        onChangeSearch={onChangeSearch}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        isOpenMenu={isOpenMenu}
        disabled={disabled}
        customDisabled={customDisabled}
        customOpening={customOpening}
        isFullWidth={isFullWidth}
        menuItemLeftElemRenderer={menuItemLeftElemRenderer}
        menuItemRightTextRenderer={menuItemRightTextRenderer}
        customMenuItemClass={customMenuItemClass}
        customMenuClass={customMenuClass}
        customClassPaperName={customClassPaperName}
        handleOpenDDManageStatuses={handleOpenDDManageStatuses}
        basisMaxItems={basisMaxItems}
        basisTooltip={basisTooltip}
        isHeaderNoMulti={isHeaderNoMulti}
        isExpandOnHover={isExpandOnHover}
        customClearAll={customClearAll}
        isDisableMinWidth={isDisableMinWidth}
        selectType={selectType}
        disableRightElem={disableRightElem}
        isMobile={isMobile}
        isCustomMulti={isCustomMulti}
        customSelectAll={customSelectAll}
        isSelectMenuClose={isSelectMenuClose}
        isActiveCheckBoxForDisabledItems={isActiveCheckBoxForDisabledItems}
        isHighResolution={isHighResolution}
        sortingValue={type === 'design input' ? 'sortingValue' : null}
        isHighResolution={isHighResolution}
        isHideSearch={isHideSearch}
      />
    </>
  );
}

export default CardRenderSelect;
