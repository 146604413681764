import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import Joyride from 'react-joyride';
import DialogSupport from '../AsideHolder/DialogSupport/DialogSupport';
import SupportHome from './parts/Breadcrumbs/SupportHome';
import GettingHelp from './parts/Breadcrumbs/GettingHelp';
import WhatNews from './parts/Breadcrumbs/WhatNews';
import FAQ from './parts/Breadcrumbs/FAQ';
import Glossary from './parts/Breadcrumbs/Glossary';
import SidePanel from '../../controls/SidePanel/SidePanel';

import './HelpDrawerStyle.scss';

class HelpDrawerView extends Component {
  render() {
    const {
      handleCloseDrawer,
      open,
      openItem,
      items,
      activeMenu,
      handleReturnHome,
      mainMenu,
      handleCloseSupport,
      joyrideCallback,
      isRunQuickTour,
      handleOpenProductTour,
      steps,
      getHelpers,
      selectedJoyrideItemIndex,
      handleChangeGettingStarted,
      isOpenGettingMenu,
      onBackdropClick,
      openGetStartPopup,
      openedGetStartItem,
      gettingStartedList,
      closeGetStartPopup,
      openMoreItem,
      isRunSandboxQuickTour,
    } = this.props;

    let spotlightValue = {};

    if (selectedJoyrideItemIndex === 3 && !isRunSandboxQuickTour) {
      spotlightValue = { minWidth: '64px', marginTop: '12px' };
    }

    if ((selectedJoyrideItemIndex === 0 || selectedJoyrideItemIndex === 1) && isRunSandboxQuickTour) {
      spotlightValue = { marginLeft: '15px', maxWidth: '190px' };
    }
    return (
      <div className="b-helperDrawer">
        <Joyride
          steps={steps}
          callback={joyrideCallback}
          continuous
          getHelpers={getHelpers}
          disableCloseOnEsc={isRunSandboxQuickTour}
          disableOverlayClose={isRunSandboxQuickTour}
          hideCloseButton={isRunSandboxQuickTour}
          showSkipButton={isRunSandboxQuickTour}
          locale={
            isRunSandboxQuickTour
              ? {
                  last: 'Finish the tour',
                  skip: 'Cancel',
                }
              : {}
          }
          run={isRunQuickTour}
          styles={{
            tooltip: selectedJoyrideItemIndex === 3 && !isRunSandboxQuickTour ? {} : {},
            options: {
              zIndex: 10000,
              arrowColor: '#38455E',
              backgroundColor: 'var(--background-tour)',
              color: 'var(--color)',
              textColor: 'var(--color)',
              primaryColor: '#68759c',
            },
            buttonSkip: isRunSandboxQuickTour
              ? {
                  display: 'block',
                  fontSize: '12px',
                  color: 'var(--button2-active-text)',
                  backgroundColor: '#4A586C',
                  border: '1px solid transparent',
                  borderRadius: '2px',
                  width: '90px',
                  height: '24px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  fontFamily: 'var(--font-general)',
                  fontWeight: 'var(--font-weight)',
                  padding: 0,
                }
              : { fontSize: '11px' },
            buttonNext: {
              color: 'var(--button2-active-text)',
              backgroundColor: 'var(--button2-active)',
              borderRadius: '2px',
              fontSize: '12px',
              border: '1px solid transparent',
              display: 'block',
              width: '90px',
              height: '24px',
              lineHeight: '15px',
              textAlign: 'center',
              fontFamily: 'var(--font-general)',
              fontWeight: 'var(--font-weight)',
              padding: 0,
              '&:hover': {
                backgroundColor: 'var(--button2-hover)',
                color: 'var(--button2-hover-text)',
              },
            },
            buttonBack: {
              display: 'none',
            },
            buttonClose: {
              display: 'none',
            },
            tooltipTitle: {
              textAlign: 'initial',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '400',
              padding: '0px 10px',
            },
            spotlight: spotlightValue,
          }}
          spotlightPadding={selectedJoyrideItemIndex !== 4 ? 0 : 5}
        />
        <SidePanel
          direction={'right'}
          open={open}
          handleCloseDrawer={handleCloseDrawer}
          BackdropProps={{ invisible: isRunQuickTour ? true : !isOpenGettingMenu }}
          PaperProps={{
            style: {
              overflow: 'inherit',
            },
            tabIndex: '4',
            disableEnforceFocus: true,
          }}
          transitionDuration={0}
          disableBackdropClick
          onBackdropClick={onBackdropClick}
        >
          <div className={'b-helperDrawer__container'}>
            <div className={'b-helperDrawer__header'}>
              {activeMenu === 'getting_started' && (
                <div className={'b-helperDrawer__homeContainer'}>
                  <div style={{ margin: '2px 0 0 -20px' }}>
                    <SVG className={'b-helperDrawer__homeIcon'} src={require('../../../assets/icons/request_nav_arrow_left.svg')} />
                  </div>
                  <div>
                    <span className={`b-helperDrawer__home ${!activeMenu ? 'b-helperDrawer__active' : ''}`} onClick={handleReturnHome}>
                      Help
                    </span>
                  </div>
                </div>
              )}
              <div style={{ width: '70%' }}>
                <span className={'b-helperDrawer__title'}>{activeMenu !== 'getting_started' ? 'Help' : mainMenu[activeMenu]?.title}</span>
              </div>
            </div>
            <div className={'b-helperDrawer__listItems'}>
              {activeMenu !== 'getting_started' && (
                <SupportHome items={items} openItem={openItem} handleOpenProductTour={handleOpenProductTour} />
              )}
              {activeMenu === 'getting_started' && (
                <GettingHelp
                  handleOpenProductTour={handleOpenProductTour}
                  handleChangeGettingStarted={handleChangeGettingStarted}
                  openGetStartPopup={openGetStartPopup}
                  openedGetStartItem={openedGetStartItem}
                  gettingStartedList={gettingStartedList}
                  closeGetStartPopup={closeGetStartPopup}
                  openMoreItem={openMoreItem}
                />
              )}
              {activeMenu === 'get_help' && <DialogSupport open={true} close={handleCloseSupport} />}
              {activeMenu === 'glossary' && <Glossary open={true} close={handleReturnHome} />}
              {activeMenu === 'faq' && <FAQ open={true} close={handleReturnHome} />}
              {activeMenu === 'what_new' && <WhatNews open={true} close={handleReturnHome} />}
            </div>
          </div>
        </SidePanel>
      </div>
    );
  }
}

export default HelpDrawerView;
